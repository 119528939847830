import React from "react"
import type { PropertyEditor, IntPropertyValue } from "../types"
import { useUpdatePropertyMutation, UpdatePropertyMutationVariables } from "../../query.hooks"
import { isTypename } from "@st4/graphql"
import { IntEditor } from "~/ui/editors/inteditor"
import { Icon, Regular } from "@st4/icons"

function getInputValue(
  propertyEditor: PropertyEditor<IntPropertyValue>,
  value: number,
): UpdatePropertyMutationVariables["input"] {
  return {
    variantName: propertyEditor.variantName,
    displayName: propertyEditor.displayName,
    id: propertyEditor.id,
    node: { id: propertyEditor.node.id },
    origin: propertyEditor.origin,
    value: { intValue: value },
  }
}

export function PropertyIntEditor({
  propertyEditor,
  languageId,
  refetchProperties,
  refetchOnSave,
}: {
  propertyEditor: PropertyEditor<IntPropertyValue>
  languageId: string
  refetchProperties: () => void
  refetchOnSave?: string[]
}) {
  const { value, isReadonly } = propertyEditor

  const [updateProperty, { data, loading, error }] = useUpdatePropertyMutation({
    variables: {
      input: { ...propertyEditor, value: { intValue: propertyEditor.value?.intValue } },
    },
    refetchQueries: refetchOnSave,
  })

  const submitHandler = async (changedValue: number) => {
    updateProperty({
      variables: {
        input: getInputValue(propertyEditor, changedValue),
        languageId: languageId,
      },
      onCompleted: (_) => {
        refetchProperties()
      },
    })
  }

  let suffixIcon = null

  if (loading) {
    suffixIcon = <Icon component={Regular.SpinnerThird} />
  }

  let errorMessage: string | undefined = undefined

  if (error) {
    errorMessage = error.message
  }
  if (isTypename("Error")(data?.updateProperty)) {
    errorMessage = data?.updateProperty.message
  }

  return (
    <IntEditor
      value={value?.intValue ?? undefined}
      isReadonly={isReadonly}
      suffixIcon={suffixIcon}
      onSubmit={submitHandler}
      errorMessage={errorMessage}
    />
  )
}
