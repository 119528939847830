import { CanDropInformation, DropInsertionType, TreeItem } from "../types"

export const calculateDropInsertionType = (
  ev: React.DragEvent<HTMLDivElement>,
  node: TreeItem,
  treeRef: React.RefObject<HTMLDivElement>,
): DropInsertionType => {
  if (!treeRef.current) throw "Tree not mounted"
  const treeRect = treeRef.current.getBoundingClientRect()
  const { mouseX, mouseY } = { mouseX: ev.clientX - treeRect.x, mouseY: ev.clientY - treeRect.y }
  const iconViewElement = treeRef.current.querySelector(`[data-id="${node.id}"] .tree-node-icon`)
  if (iconViewElement) {
    const { x, y, height } = iconViewElement.getBoundingClientRect()
    if (mouseX > x - treeRect.x) {
      return "INSERT_AS_DESCENDANT"
    } else if (mouseY < y - treeRect.y + height / 2) {
      return "INSERT_AS_PREVIOUS_SIBLING"
    } else {
      return "INSERT_AS_NEXT_SIBLING"
    }
  }
  throw `Tree item not found: ${node.id}`
}

export const checkDrop = (
  ev: React.DragEvent<HTMLDivElement>,
  node: TreeItem,
  treeRef: React.RefObject<HTMLDivElement>,
  canDropInformation: CanDropInformation,
  getParent: (id: string) => string | null,
) => {
  if (
    canDropInformation.type === "loaded" ||
    canDropInformation.type === "updating" ||
    canDropInformation.type === "requestingUpdate"
  ) {
    const dropInsertionType = calculateDropInsertionType(ev, node, treeRef)
    switch (dropInsertionType) {
      case "INSERT_AS_DESCENDANT":
        if (canDropInformation.allowedItems.has(node.id)) {
          return true
        }
        return false
      case "INSERT_AS_NEXT_SIBLING":
      case "INSERT_AS_PREVIOUS_SIBLING": {
        const parent = getParent(node.id)
        if (!parent) {
          return false
        }
        return canDropInformation.allowedItems.has(parent)
      }
      default:
        throw "Unknown Drop insertion type"
    }
  } else {
    return false
  }
}
