import React from "react"
import { Spin } from "antd"
import { useCurrentUserQuery } from "./query.hooks"
import { isTypename } from "@st4/graphql"
import { currentUserVar } from "./CurrentUser"

export function CurrentUserProvider({ children }: { children: React.ReactNode }) {
  const { loading, data, error } = useCurrentUserQuery({ fetchPolicy: "cache-first" }) //queryOptions)

  if (loading) {
    currentUserVar({ state: "loading" })
    return <Spin delay={500} />
  }

  if (error)
    currentUserVar({
      state: "loadError",
      errors: [...(error.networkError ? [error.networkError] : []), ...error.graphQLErrors, ...error.clientErrors],
    })
  else if (!data || !data.me.account)
    currentUserVar({
      state: "loadError",
      errors: [new Error("Received neither error nor data from Server!")],
    })
  else if (isTypename("Error")(data.me.account))
    currentUserVar({
      state: "loadError",
      errors: [new Error(data.me.account.message)],
    })
  else {
    const currentUser = data.me.account.result
    currentUserVar({
      state: "loaded",
      user: {
        type: currentUser.type,
        userName: currentUser.userName,
        displayName: currentUser.displayName,
        displayLanguage: currentUser.displayLanguage,
        groups: currentUser.groups,
        isAbsent: currentUser.isAbsent,
        isDisabled: currentUser.disabled.indexOf("9999") === -1,
        isDeleted: currentUser.isDeleted,
      },
    })
  }

  return <>{children}</>
}
