import { MessageHub } from "@st4/message-hub"
import React, { ReactNode, useContext } from "react"
import { ContentMessage, ContentSelection, ContentTarget } from "../types"

export type PreviewParameterContext = {
  rootNodeId: string
  selection: ContentSelection
  languageId: string
  /** StObjectId of a FilterNode and is used to calculate Callouts */
  filterId?: string
  singleNodeMode?: boolean
  //  setSingleNodeMode?: (value: boolean) => void
  messageHub: MessageHub<ContentMessage>
  refetchOnEditorSave?: string[]
  initialScrollTarget?: ContentTarget
} & (ReadonlyPreviewParameterContext | EditablePreviewParameterContext)

export type ReadonlyPreviewParameterContext = {
  rootNodeId: string
  languageId: string
  readonly: true
}

export type EditablePreviewParameterContext = {
  editedNodes: string[]
  readonly?: never
  nodeContextMenu?: {
    label: React.ReactNode
    key?: React.Key | null
    icon?: React.ReactNode
    onClick: (nodeId: string, treeNodeId: string) => Promise<void>
  }[]
}

const PreviewParameterContextData = React.createContext<PreviewParameterContext | undefined>(undefined)

export function PreviewParameterContextProvider({
  children,
  ...parameterContextProps
}: { children: ReactNode } & PreviewParameterContext) {
  return (
    <PreviewParameterContextData.Provider value={parameterContextProps}>
      {children}
    </PreviewParameterContextData.Provider>
  )
}

export function usePreviewParameterContext() {
  const value = useContext(PreviewParameterContextData)
  if (!value) throw Error("Found no data. Did you forget the <PreviewParameterContextProvider>")
  return value
}

export function isReadonlyPreviewParameterContext(
  context: PreviewParameterContext,
): context is PreviewParameterContext & ReadonlyPreviewParameterContext {
  return !!context.readonly
}
