import * as Content from "./contentComponents/index"
import * as NodeComponents from "./nodeComponents"
import { setStandardComponents } from "./componentRegistry"
import { register as registerXAst } from "../mapping/st4-mapping"
import { register as registerNodes } from "../mapping/nodeMapping"

let bootstrapped = false

export function bootstrapRegistries() {
  if (!bootstrapped) {
    setStandardComponents({ ...Content, ...NodeComponents })
    registerXAst()
    registerNodes()
    bootstrapped = true
  }
}
