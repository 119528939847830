import dayjs from "dayjs"
import "dayjs/locale/de"
import "dayjs/locale/fr"
import "dayjs/locale/ja"
import relativeTime from "dayjs/plugin/relativeTime"
import localeData from "dayjs/plugin/localeData"
import localizedFormat from "dayjs/plugin/localizedFormat"

dayjs.extend(relativeTime)
dayjs.extend(localeData)
dayjs.extend(localizedFormat)

export default dayjs
