import * as React from "react"
import { SVGProps } from "react"

const SvgBorderInner = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="1em" height="1em" {...props}>
    <path d="M416 416a32 32 0 1 1 0 64 32 32 0 1 1 0-64zm0-96a32 32 0 1 1 0 64 32 32 0 1 1 0-64zm-384 0a32 32 0 1 1 0 64 32 32 0 1 1 0-64zm352-160a32 32 0 1 1 64 0 32 32 0 1 1-64 0zM32 128a32 32 0 1 1 0 64 32 32 0 1 1 0-64zm352-64a32 32 0 1 1 64 0 32 32 0 1 1-64 0zM0 64a32 32 0 1 1 64 0 32 32 0 1 1-64 0zm32 352a32 32 0 1 1 0 64 32 32 0 1 1 0-64zM288 64a32 32 0 1 1 64 0 32 32 0 1 1-64 0zm32 352a32 32 0 1 1 0 64 32 32 0 1 1 0-64zM96 64a32 32 0 1 1 64 0 32 32 0 1 1-64 0zm32 352a32 32 0 1 1 0 64 32 32 0 1 1 0-64zm96 64c-13.3 0-24-10.7-24-24V280H24c-13.3 0-24-10.7-24-24s10.7-24 24-24h176V56c0-13.3 10.7-24 24-24s24 10.7 24 24v176h176c13.3 0 24 10.7 24 24s-10.7 24-24 24H248v176c0 13.3-10.7 24-24 24z" />
  </svg>
)

export default SvgBorderInner
