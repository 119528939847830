import { z } from "zod"

type JsonLiteral = z.infer<typeof zodJsonLiteralSchema>
type JsonArray = Json[]
type Json = JsonLiteral | JsonObject | JsonArray

const zodJsonLiteralSchema = z.union([z.string(), z.number(), z.boolean(), z.null()])
const zodJsonSchema: z.ZodType<Json> = z.lazy(() =>
  z.union([zodJsonLiteralSchema, zodJsonArraySchema, zodJsonObjectSchema]),
)
const zodJsonArraySchema = z.array(zodJsonSchema)

export const zodJsonObjectSchema = z.record(zodJsonSchema)
export type JsonObject = { [key: string]: Json }
