import React, { useRef, useState, useEffect } from "react"
import ReactDOM from "react-dom"
import styled from "styled-components"

const ToolTipWrapper = styled.div`
  position: absolute;
  padding: 0.5em;
  // Needs to be higher than Ant Design's drop down menu which uses 1050
  z-index: 4095;
  color: #fff;
  background: #333;
  border-radius: 3px;
  font-weight: normal;
  transform: translate(-50%, 4px);
  &::after {
    /* small arrow */
    content: " ";
    position: absolute;
    bottom: 100%; /* At the top of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent black transparent;
  }
`

const TooltipContent = styled.div`
  position: relative;
  white-space: nowrap;
`

type TooltipProps = {
  className?: string
  hint: React.ReactNode
}

export function Tooltip(props: React.PropsWithChildren<TooltipProps>) {
  const container = useRef<HTMLSpanElement>(null)
  const tooltipPortalRef = useRef<HTMLElement>()
  const [displayTooltip, setDisplayTooltip] = useState(false)
  const [position, setPosition] = useState({ left: 0, top: 0 })

  useEffect(() => {
    const tooltipPortalId = "tooltipPortal"
    let tooltipPortal = document.getElementById(tooltipPortalId)
    if (tooltipPortal === null) {
      tooltipPortal = document.createElement("div")
      tooltipPortal.id = tooltipPortalId
      const body = document.getElementsByTagName("body")[0]
      body.appendChild(tooltipPortal)
    }
    tooltipPortalRef.current = tooltipPortal
  }, [])

  function mouseOver() {
    const rect = container.current && container.current.getBoundingClientRect()
    if (!rect) return
    const top = rect.top + rect.height + 5
    const left = rect.left + rect.width / 2
    setDisplayTooltip(true)
    setPosition({ left, top })
  }

  function onClick() {
    if (shouldBeClosedOnClick()) {
      setDisplayTooltip(false)
    }
  }

  function shouldBeClosedOnClick() {
    let closeIt = false
    React.Children.forEach(props.children, function (child) {
      if (!!child && typeof child === "object" && "props" in child && child.props.onClick !== null) {
        closeIt = true
      }
    })
    return closeIt
  }

  return (
    <span
      ref={container}
      onMouseOut={() => setDisplayTooltip(false)}
      onMouseOver={() => mouseOver()}
      onClick={() => onClick()}
      className={props.className}
    >
      {props.children}
      {tooltipPortalRef.current &&
        (displayTooltip
          ? ReactDOM.createPortal(
              <ToolTipWrapper style={{ left: position.left, top: position.top }}>
                <TooltipContent>{props.hint}</TooltipContent>
              </ToolTipWrapper>,
              tooltipPortalRef.current,
            )
          : ReactDOM.createPortal(<div />, tooltipPortalRef.current))}
    </span>
  )
}
