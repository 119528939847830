import { ApolloError, QueryResult, useApolloClient, useQuery } from "@apollo/client"

import { GET_CONTENT } from "./queries"
import type { GetContentResult, TreeVariables } from "./types"
import { useEffect, useState } from "react"
import { SingleNodeDocument, SingleNodeQuery, SingleNodeQueryVariables } from "./query.hooks"

type Variables = {
  reviewVariant: string
  commentVariant: string
} & TreeVariables

export function useContent(
  stPatternContextId: string,
  aspectSelectorId: string,
  limit: number,
): QueryResult<GetContentResult, Variables> {
  return useQuery<GetContentResult, Variables>(GET_CONTENT, {
    variables: {
      selectedNodes: [stPatternContextId],
      aspectId: aspectSelectorId,
      reviewVariant: "ReviewMetadata",
      contentVariant: "Preview",
      commentVariant: "InformationPool",
      limit: limit,
    },
    errorPolicy: "all",
    fetchPolicy: "cache-and-network",
    pollInterval: 0,
    //Dont use partial Data (apollo default) because otherwise treeNodes will be rendered without nodes
    //This will result in setVisibleNodes getting called with undefined node id
    returnPartialData: false,
  })
}
