import * as React from "react"
import { SVGProps } from "react"

const SvgSt4DataNodeFolderSolid = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1em" height="1em" {...props}>
    <path d="M448 97.3H299.5c-17.9 0-33.3-7.7-46.1-20.5l-25.6-25.6c-12.8-12.8-28.2-20.5-46.1-20.5H64c-35.8 0-64 30.7-64 66.6v320c0 35.8 28.2 64 64 64h384c35.8 0 64-28.2 64-64v-256c0-35.9-28.2-64-64-64zm-64 289.3c0 25.6-56.3 46.1-128 46.1s-128-20.5-128-46.1V338c7.7 7.7 20.5 12.8 30.7 15.4 25.6 10.2 58.9 15.4 97.3 15.4s71.7-5.1 97.3-15.4c12.8-5.1 23-10.2 30.7-15.4v48.6zm0-82c0 25.6-56.3 46.1-128 46.1s-128-20.5-128-46.1v-58.9c7.7 7.7 20.5 12.8 30.7 15.4 25.6 10.2 58.9 15.4 97.3 15.4s71.7-5.1 97.3-15.4c12.8-5.1 23-10.2 30.7-15.4v58.9zm0-92.1c0 25.6-56.3 46.1-128 46.1s-128-20.5-128-46.1v-28.2c0-25.6 56.3-46.1 128-46.1s128 20.5 128 46.1v28.2z" />
  </svg>
)

export default SvgSt4DataNodeFolderSolid
