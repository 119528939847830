import { useState, useEffect } from "react"
/**
 * Observes the size of a element and returns its dimensions (border box) when changed.
 * @param {React.RefObject<HTMLElement>} container - Ref to a HTMLElement which size should be observed.
 * @returns {DOMRectReadOnly} The dimensions of the current element.
 */
export function useResizeObserver(container: HTMLElement | null) {
  const [calculatedDimensions, setCalculatedDimensions] = useState(
    container?.getBoundingClientRect() || new DOMRectReadOnly(),
  )

  function setDivDimensions(container: HTMLElement) {
    // divDimensions only provides limited usable data (sizes of contentbox good for inner dimensions unusable for outer dimensions)
    const divRect = container.getBoundingClientRect()
    setCalculatedDimensions(new DOMRectReadOnly(divRect.left, divRect.top, divRect.width, divRect.height))
  }

  useEffect(() => {
    if (!container) return
    const observer = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        setDivDimensions(container) //contentrect unfortunatley only provides contentbox - size (no border, padding)
      })
    })
    observer.observe(container)
    return () => observer.disconnect()
  }, [container])

  return calculatedDimensions
}
