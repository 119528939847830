import * as React from "react"
import { SVGProps } from "react"

const SvgDiamondExclamation = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1em" height="1em" {...props}>
    <path d="M50.9 249 249 50.9c1.9-1.9 4.4-2.9 7-2.9s5.2 1 7 2.9L461.1 249c1.9 1.9 2.9 4.4 2.9 7s-1 5.2-2.9 7L263 461.1c-1.9 1.9-4.4 2.9-7 2.9s-5.2-1-7-2.9L50.9 263c-1.9-1.9-2.9-4.4-2.9-7s1-5.2 2.9-7zM215 17 17 215C6.1 225.9 0 240.6 0 256s6.1 30.1 17 41l198 198c10.9 10.9 25.6 17 41 17s30.1-6.1 41-17l198-198c10.9-10.9 17-25.6 17-41s-6.1-30.1-17-41L297 17C286.1 6.1 271.4 0 256 0s-30.1 6.1-41 17zm41 111c-13.3 0-24 10.7-24 24v112c0 13.3 10.7 24 24 24s24-10.7 24-24V152c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0-64 0 32 32 0 1 0 64 0z" />
  </svg>
)

export default SvgDiamondExclamation
