import * as React from "react"
import { SVGProps } from "react"

const SvgCompass = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1em" height="1em" {...props}>
    <path d="M256 32a224 224 0 1 1 0 448 224 224 0 1 1 0-448zm0 480a256 256 0 1 0 0-512 256 256 0 1 0 0 512zm92.6-389.1-146.5 48.9c-14.3 4.8-25.6 16-30.4 30.4l-48.8 146.4c-8.3 25 15.5 48.8 40.5 40.5l146.5-48.8c14.3-4.8 25.6-16 30.4-30.4l48.8-146.5c8.3-25-15.5-48.8-40.5-40.5zm10.1 30.4-48.8 146.5c-1.6 4.8-5.3 8.5-10.1 10.1l-146.5 48.8 48.8-146.5c1.6-4.8 5.3-8.5 10.1-10.1l146.5-48.8zM256 280a24 24 0 1 0 0-48 24 24 0 1 0 0 48z" />
  </svg>
)

export default SvgCompass
