import { ContentMessage } from "@st4/content"
import { createTranslationArgs } from "@st4/ui-strings"
import { App } from "antd"
import { useCallback, useEffect, useState } from "react"
import { NotificationInfo } from "~/components/treeActions/types"
import { getEditorMessageKey } from "./editorMessageKeys"
import { v4 as uuidv4 } from "uuid"
import { useTranslation } from "react-i18next"
import { MessageHub } from "@st4/message-hub"
import { LabeledValue } from "@st4/graphql"

export type EditorNotificationProps = {
  nodeId: string
  messageHub: MessageHub<ContentMessage>
}

export function useEditorNotifications({ nodeId, messageHub }: EditorNotificationProps): NotificationInfo[] {
  const [editorNotifications, setEditorNotifications] = useState<NotificationInfo[]>([])
  const [isEditorClosing, setIsEditorClosing] = useState<boolean>(false)
  const { t } = useTranslation()
  const { notification } = App.useApp()

  const handleEditorClosing = useCallback((message: ContentMessage) => {
    if (message.action === "saveAndCloseEditor") {
      setIsEditorClosing(true)
    }
  }, [])

  const handleEditorNotificationsChanged = useCallback(
    (message: ContentMessage) => {
      if (!nodeId) setEditorNotifications([])

      if (message.action === "editorWarningsChanged" && message.payload.warnings) {
        const notifications: NotificationInfo[] = message.payload.warnings.map((warning) => {
          const targetNodeClass = warning.messageArgs?.find((arg) => arg.label === "LinkTargetClass")?.value ?? ""

          return {
            id: uuidv4(),
            nodeId: nodeId,
            title: "",
            description: t(
              getEditorMessageKey(warning.messageKey, targetNodeClass),
              getMessageTranslationArgs(warning.messageArgs ?? []),
            ),
            severity: "warning",
            disableInsert: false,
          }
        })

        setEditorNotifications([...notifications])
      }

      if (message.action === "sendNotificationsOnSave" && message.payload) {
        const targetNodeClass = message.payload.messageArgs.find((arg) => arg.label === "LinkTargetClass")?.value ?? ""

        const notificationInfo: NotificationInfo = {
          id: uuidv4(),
          nodeId: nodeId,
          title: "",
          description: t(
            getEditorMessageKey(message.payload.messageKey, targetNodeClass),
            getMessageTranslationArgs(message.payload.messageArgs ?? []),
          ),
          severity: message.payload.severity,
          disableInsert: false,
        }

        if (editorNotifications.some((notification) => isSameNotification(notification, notificationInfo))) return

        if (isEditorClosing && notification) {
          notificationInfo.severity === "warning"
            ? notification.warning({
                message: notificationInfo.description,
                style: { backgroundColor: "#FFF5D9" },
                duration: 5,
              })
            : notification.error({
                message: notificationInfo.description,
                style: { backgroundColor: "#FFF5D9" },
                duration: 5,
              })
        } else {
          setEditorNotifications([...editorNotifications, notificationInfo])
        }
      }
    },
    [nodeId, t, editorNotifications, isEditorClosing, notification],
  )

  useEffect(() => {
    messageHub.observe(handleEditorNotificationsChanged)
    messageHub.observe(handleEditorClosing)
    return () => {
      messageHub.unobserve(handleEditorNotificationsChanged)
      messageHub.unobserve(handleEditorClosing)
    }
  }, [handleEditorNotificationsChanged, handleEditorClosing, messageHub])

  return editorNotifications
}

function isSameNotification(oldNotification: NotificationInfo, newNotification: NotificationInfo) {
  return (
    oldNotification.title === newNotification.title &&
    oldNotification.severity === newNotification.severity &&
    oldNotification.nodeId === newNotification.nodeId &&
    oldNotification.disableInsert === newNotification.disableInsert &&
    oldNotification.description === newNotification.description
  )
}

function getMessageTranslationArgs(messageArgs: LabeledValue[]) {
  const targetNodeId = messageArgs?.find((arg) => arg.label === "Id")?.value ?? ""
  const titleValue = messageArgs?.find((arg) => arg.label === "Title")?.value
  const targetNodeTitle = titleValue !== "" ? ` "${titleValue}"` : ""
  const translationArgs = [targetNodeId, targetNodeTitle]

  return createTranslationArgs(translationArgs)
}
