import { useUpdateCommentStateMutation } from "./query.hooks"
import type { UpdateCommentStateMutationHookResult, UpdateCommentStateMutationResult } from "./query.hooks"
import type { TextComment, BlockComment, St4Node, ExtractByTypeName } from "@st4/graphql"
import { isTypename } from "@st4/graphql"
import {
  CommentNodeFragment,
  TextContentForCommentFragment,
  TextContentForCommentFragmentDoc,
} from "../CommentList/query.hooks"
import { ReplaceCommentInCommentNode } from "./utilities"

function createInputType(comment: UpdateCommentInput) {
  if (!comment.node) return null
  const content = comment.node.content
  return {
    aspectSelectorId: comment.node.aspectId,
    nodeId: comment.node.id,
    historyNumber: content.historyNumber,
    commentKey: comment.commentKey,
    commentState: comment.state,
  }
}

export type UpdateCommentInput = (Omit<TextComment, "node"> | Omit<BlockComment, "node">) & {
  node: Pick<St4Node, "id" | "aspectId" | "__typename"> & {
    content: Pick<
      ExtractByTypeName<"TextContent" | "TextGroupContent", NonNullable<St4Node["content"]>>,
      "historyNumber" | "id" | "__typename"
    >
  }
}
export function useUpdateCommentStateAndSaveLocal(): [
  (input: UpdateCommentInput) => ReturnType<UpdateCommentStateMutationHookResult[0]>,
  UpdateCommentStateMutationResult,
] {
  const [updateCommentStateMutation, updateCommentStateResult] = useUpdateCommentStateMutation()
  const patchedMutation = (comment: UpdateCommentInput) => {
    const existingComment = comment
    const input = createInputType(comment)
    if (!input || !existingComment) return Promise.reject()
    const optimistic = {
      ...existingComment,
      state: comment.state,
      persistanceState: "LOCAL" as const,
      persistanceAction: "CHANGESTATE" as const,
    }
    return updateCommentStateMutation({
      variables: { input },
      optimisticResponse: { comment: { result: optimistic, __typename: "CommentSuccess" }, __typename: "Mutation" },
      update: (cache, { data }) => {
        if (!data) return
        const contentCacheId = cache.identify({ __typename: "TextContent", id: input.nodeId })
        const contentFromCache = cache.readFragment<TextContentForCommentFragment>({
          id: contentCacheId,
          fragment: TextContentForCommentFragmentDoc,
          fragmentName: "TextContentForComment",
        })
        if (!contentFromCache) return

        const commentIdToUpdate = `${input.nodeId}_${input.historyNumber}_${input.aspectSelectorId}_${input.commentKey}`
        const commentNodeCacheKey = cache.identify({ __typename: "CommentNode", id: commentIdToUpdate })
        if (!commentNodeCacheKey) return

        const existingCommentNode = contentFromCache.comments.find((n) => cache.identify(n) === commentNodeCacheKey)
        if (!existingCommentNode) return

        if (isTypename("Error")(data.comment)) {
          const erroneousComment: CommentNodeFragment["comment"] = {
            ...optimistic,
            persistanceState: "ERROR",
            persistanceAction: "CHANGESTATE",
          }
          ReplaceCommentInCommentNode(cache, commentNodeCacheKey, erroneousComment)
          return
        }
        ReplaceCommentInCommentNode(cache, commentNodeCacheKey, data.comment.result)
      },
    })
  }
  return [patchedMutation, updateCommentStateResult]
}
