import React from "react"
import { CommonTaxonomyTreeSelectProps, TaxonomyDisplayInfo } from "./types"
import { Tooltip, TreeSelect } from "antd"
import { Translate } from "@st4/i18n"
import { keys } from "@st4/ui-strings"
import { MarkAllSelectedNodes } from "./labelModificationFunctions"

export function MultiSelectionTaxonomyTreeSelect({
  saveOrReset,
  treeData,
  isInheritedValue,
  treeSelectProps,
}: CommonTaxonomyTreeSelectProps) {
  const { disabled } = treeSelectProps
  const changeHandler = (value: TaxonomyDisplayInfo[]) => {
    saveOrReset(value)
  }

  let displayTree = treeData
  if (disabled) {
    displayTree = MarkAllSelectedNodes(treeData, treeSelectProps.value)
  }

  return (
    <Tooltip
      title={
        isInheritedValue && !treeSelectProps.open && <Translate>{keys.message.properties.valueInherited}</Translate>
      }
    >
      <span>
        <TreeSelect
          {...treeSelectProps}
          onChange={changeHandler}
          treeCheckable={!disabled}
          treeCheckStrictly={!disabled}
          treeData={displayTree}
          disabled={false}
        />
      </span>
    </Tooltip>
  )
}
