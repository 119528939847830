import React, { useState } from "react"
import { Tooltip, Button } from "antd"
import { NotificationPopover } from "../notificationpopover"

export type ConfirmButtonProps = {
  /** Wird als Tooltip angezeigt. */
  title: string
  confirmMessage?: string
  onClick: () => void
  icon: React.ReactNode
  disabled?: boolean
}

/**
 * Show an icon which requires additional confirmation on click.
 *
 * @param props bla
 * @returns bums
 */
export const ConfirmButton = (props: ConfirmButtonProps) => {
  const [visible, setVisible] = useState(false)
  function confirm(ev: React.MouseEvent<HTMLElement, MouseEvent> | undefined) {
    setVisible(false)
    props.onClick()
    ev?.stopPropagation()
  }
  function cancel() {
    setVisible(false)
  }
  function handleVisibleChange() {
    if (props.confirmMessage) {
      setVisible(true)
    } else {
      confirm(undefined)
    }
  }
  return (
    <NotificationPopover
      content={props.confirmMessage ?? ""}
      open={visible}
      onOpenChange={handleVisibleChange}
      showPrimaryButton={true}
      onClickPrimary={confirm}
      showSecondaryButton={true}
      onClickSecondary={cancel}
      state={"warning"}
      placement={"top"}
    >
      <Tooltip placement="bottom" title={props.title} mouseEnterDelay={0.5}>
        <Button type="text" icon={props.icon} disabled={props.disabled} />
      </Tooltip>
    </NotificationPopover>
  )
}
