import { WatchQueryFetchPolicy } from "@apollo/client"
import { NavigationTreeQuery, useNavigationTreeQuery } from "./query.hooks"
import { useRef } from "react"

export type NavigationTreeDataRequest = {
  variant: string
  aspectId: string
  expandedNodes: string[]
  selectedNodes?: string[]
  ignoreIcons: boolean
  includeNodeClassHierarchy: boolean
}

export function useIndentedNavigationTreeData(
  request: NavigationTreeDataRequest,
  setExpandedNodes: (allExpandedNodes: string[]) => void,
  fetchPolicy?: WatchQueryFetchPolicy,
) {
  const treeDataRef = useRef<NavigationTreeQuery | undefined>(undefined)

  const { data, loading, error } = useNavigationTreeQuery({
    variables: request,
    fetchPolicy: fetchPolicy ?? "cache-first",
    onCompleted: (data) => {
      if (AreDifferent(data.indentedTree.allExpandedNodes, request.expandedNodes)) {
        setExpandedNodes(data.indentedTree.allExpandedNodes)
      }
    },
  })

  if (data) {
    treeDataRef.current = data
  }

  return {
    data: treeDataRef.current?.indentedTree,
    loading,
    error,
  }
}

function AreDifferent(array1: string[], array2: string[]) {
  if (array1.length !== array2.length) return true
  if (array1.length === undefined || array1.length === 0) return false

  const set2 = new Set<string>(array2)

  return array1.entries.length !== set2.entries.length || array1.findIndex((i) => !set2.has(i)) > -1
}
