import type { Message, MessageCallback, MessageHub, MessageTypeFromHub } from "./types"

export function createMessageHub<TMessages extends Message = Message>(): MessageHub<TMessages> {
  let listeners = new Array<MessageCallback<TMessages>>()

  async function sendMessage(msg: TMessages | TMessages["action"], payload?: TMessages["payload"]) {
    let message: TMessages
    if (typeof msg === "string") message = { action: msg, payload: payload } as TMessages
    else message = msg
    await Promise.all(listeners.map((l) => l(message)))
  }

  return {
    sendMessage,
    observe(callback) {
      if (!listeners.some((l) => l === callback)) {
        listeners.push(callback as MessageCallback<TMessages>)
      }
    },
    unobserve(callback) {
      listeners = listeners.filter((l) => l !== callback)
    },
    unobserveAll() {
      listeners = []
    },
  }
}

export type { Message, MessageCallback, MessageHub, MessageTypeFromHub }
export { NotificationProvider, useNotifications } from "./notifications"
export type { NotificationFunctions, ShowConfirm, ShowNotification } from "./notifications"
