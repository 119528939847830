import classNames from "classnames"
import React from "react"
import * as R from "ramda"
import styled, { css } from "styled-components"
import { lighten, modularScale } from "polished"
import PropTypes from "prop-types"
import ThemeManager, { boxedInputStyles } from "@st4/theme-manager"

const inputAttrs = ["defaultValue", "onChange", "name", "type", "accept", "step", "onKeyPress"]

// prettier-ignore
const TextInput_deprecated = styled(function (props) {
		return <label className={classNames(props.className, "TextInput",{'invalid':props.invalidInput })}>
			<input
				className={"TextInput--input"}
				{...R.pick(inputAttrs)(props)}
				defaultValue={props.defaultValue && props.defaultValue._data ? props.defaultValue._data : null}
				disabled={props.readonly}
				pattern={props.pattern}
				placeholder={props.readonly ? "" : props.placeholder}
			/>
			<span className="TextInput--label">{props.label}</span>
		</label>;
	}
)`
    display: block;
    flex: 1;    
    & > span {
      opacity: 0.7;
      display: block;
      font-size: ${modularScale(-1)};
      transform: translateY(-25px);
      transition: all 0.2s cubic-bezier(.3,-0.07,.77,1.2);
    }

    & > input {
      border: none;
      width: 100%;
      ${props=>props.accented?boxedInputStyles:
      css`
        height: 32px;
        border-bottom: 1px solid #ddd;
        background-color: transparent;
      `}
    }

    & > input:focus {
      outline: none;
      border: 1px solid ${props => props.accented ? props.theme.primaryColor : "transparent"};
      ${props => props.accented ? "" : "border-bottom: 1px solid #555"};
      ${props=> props.accented && "box-shadow: 0 0 2px " + props.theme.primaryColor + ";"}
    }        

    input:valid + span,
    input:disabled + span {
      opacity: 1.0;
      transform: translateY(-50px);
    }
    
    &.invalid input:before,
    input:invalid:before {
      display: block;
      content: attr(validationMessage)
    }

    &.invalid input,
    input:invalid{
      border: 1px solid ${props=>props.theme.warningColor};
      box-shadow: 0 0 2px ${props=>props.theme.warningColor};
    }

    input:disabled {
      background-color: initial;
    }

    input:focus + span {
      transform: translateY(-50px);
      opacity: 1.0;
    }
  `

TextInput_deprecated.propTypes = {
  className: PropTypes.string,
  defaultValue: PropTypes.shape({
    _data: PropTypes.string,
    _display: PropTypes.string,
  }),
  onChange: PropTypes.func,
  name: PropTypes.string,
  type: PropTypes.string,
  accept: PropTypes.string,
  step: PropTypes.string,
  onKeyPress: PropTypes.func,
  pattern: PropTypes.string,
  placeholder: PropTypes.string,
  readonly: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
}

export default TextInput_deprecated
