import classNames from "classnames"
import React from "react"
import styled from "styled-components"
import { withTranslation } from "react-i18next"
import PropTypes, { shape } from "prop-types"
import MdCheckbox from "@schema/react-ionicons/components/MdCheckbox"
import MdRadioButtonOn from "@schema/react-ionicons/components/MdRadioButtonOn"
import MdRadioButtonOff from "@schema/react-ionicons/components/MdRadioButtonOff"
import MdSquareOutline from "@schema/react-ionicons/components/MdSquareOutline"
import Button_deprecated from "./Button_deprecated"
import { keys } from "@st4/ui-strings"
const YesNoInput_deprecated = withTranslation()(function (props) {
  const { t } = props

  const onClick = (name, value, evt) => {
    props.onChange({
      target: {
        value,
        name,
      },
    })
  }

  if (props.allowNull !== false) {
    var options = [
      {
        value: {
          _data: "",
          _display: <i>({t(keys.label.general.noValue)})</i>,
        },
      },
      {
        value: {
          _data: "true",
          _display: t(keys.label.general.yes),
        },
      },
      {
        value: {
          _data: "false",
          _display: t(keys.label.general.no),
        },
      },
    ]

    return (
      <div className={classNames(props.className, "YesNoInput-allowNull")}>
        {options.map((option) => (
          <label className="YesNoInput-allowNull--label" key={option.value._data}>
            <Button_deprecated
              round
              flat
              onClick={onClick.bind(null, props.name, option.value._data)}
              className="YesNoInput-allowNull--label--Button"
            >
              {props.defaultValue._data === option.value._data ? <MdRadioButtonOn /> : <MdRadioButtonOff />}
            </Button_deprecated>
            &nbsp;
            {option.value._display}
          </label>
        ))}
      </div>
    )
  }

  const innerButton =
    props.defaultValue._data === "true" ? (
      <Button_deprecated round flat onClick={onClick.bind(null, props.name, "false")} className={props.className}>
        <MdCheckbox />
      </Button_deprecated>
    ) : (
      <Button_deprecated round flat onClick={onClick.bind(null, props.name, "true")} className={props.className}>
        <MdSquareOutline />
      </Button_deprecated>
    )

  if (props.label) {
    return (
      <label className={classNames(props.className, "YesNoInput")}>
        {innerButton}
        {props.label}
      </label>
    )
  } else {
    return innerButton
  }
})

YesNoInput_deprecated.propTypes = {
  className: PropTypes.string,
  defaultValue: shape({
    _data: PropTypes.string,
    _display: PropTypes.string,
  }),
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  allowNull: PropTypes.bool,
  readonly: PropTypes.bool,
  style: PropTypes.any,
  labelStyle: PropTypes.any,
}

export default styled(YesNoInput_deprecated)`
  &.YesNoInput-allowNull {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  & .YesNoInput-allowNull--label {
    display: flex;
    align-items: center;
    cursor: pointer;
    &:focus-within {
      outline: thin dotted;
    }
  }
  & .YesNoInput-allowNull--label--Button {
    margin: 0;
  }
`
