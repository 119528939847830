import * as Types from "@st4/graphql"

import { gql } from "@apollo/client"
import * as Apollo from "@apollo/client"
const defaultOptions = {} as const
export type NodeMetadataQueryVariables = Types.Exact<{
  languageId: Types.Scalars["AspectSelectorId"]
  treeNodeId: Types.Scalars["ID"]
}>

export type NodeMetadataQuery = {
  __typename: "Query"
  node?:
    | { __typename: "CommentNode"; id: string }
    | { __typename: "ExternalSite"; id: string }
    | { __typename: "ST4Class"; id: string }
    | { __typename: "ST4Node"; id: string }
    | {
        __typename: "ST4TreeNode"
        label: string
        id: string
        properties:
          | { __typename: "Error" }
          | {
              __typename: "PropertyListSuccess"
              result: Array<{
                __typename: "Property"
                displayName: string
                displayValue: string
                editor: { __typename: "PropertyEditor"; id: string }
              }>
            }
      }
    | null
}

export type PropertyItemFragment = {
  __typename: "Property"
  displayName: string
  displayValue: string
  editor: { __typename: "PropertyEditor"; id: string }
}

export const PropertyItemFragmentDoc = gql`
  fragment PropertyItem on Property {
    displayName
    displayValue
    editor {
      id
    }
  }
`
export const NodeMetadataDocument = gql`
  query NodeMetadata($languageId: AspectSelectorId!, $treeNodeId: ID!) {
    node(languageId: $languageId, id: $treeNodeId, variant: "Preview") {
      id
      ... on ST4TreeNode {
        label
        properties(variantName: "ReviewMetadata") {
          ... on PropertyListSuccess {
            result {
              ...PropertyItem
            }
          }
        }
      }
    }
  }
  ${PropertyItemFragmentDoc}
`

/**
 * __useNodeMetadataQuery__
 *
 * To run a query within a React component, call `useNodeMetadataQuery` and pass it any options that fit your needs.
 * When your component renders, `useNodeMetadataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNodeMetadataQuery({
 *   variables: {
 *      languageId: // value for 'languageId'
 *      treeNodeId: // value for 'treeNodeId'
 *   },
 * });
 */
export function useNodeMetadataQuery(
  baseOptions: Apollo.QueryHookOptions<NodeMetadataQuery, NodeMetadataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<NodeMetadataQuery, NodeMetadataQueryVariables>(NodeMetadataDocument, options)
}
export function useNodeMetadataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<NodeMetadataQuery, NodeMetadataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<NodeMetadataQuery, NodeMetadataQueryVariables>(NodeMetadataDocument, options)
}
export type NodeMetadataQueryHookResult = ReturnType<typeof useNodeMetadataQuery>
export type NodeMetadataLazyQueryHookResult = ReturnType<typeof useNodeMetadataLazyQuery>
export type NodeMetadataQueryResult = Apollo.QueryResult<NodeMetadataQuery, NodeMetadataQueryVariables>
