import React from "react"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { keys } from "@st4/ui-strings"
import { Typography } from "antd"
import { StyledLinkText } from "../contentComponents"

type ExpandingTextProps = {
  /**
   * 	The number of rows to show while collapsed
   */
  rows?: number
}

const COMMENT_LENGTH_COLLAPSED = 170

export function ExpandingText({ children }: React.PropsWithChildren<ExpandingTextProps>) {
  const { t } = useTranslation()
  const [expanded, setExpanded] = useState(false)
  const expand = () => setExpanded(true)
  const collapse = () => setExpanded(false)

  let shorted = false
  let textToShow = children
  if (typeof textToShow === "string" && textToShow.length > COMMENT_LENGTH_COLLAPSED) {
    const nextWhitespace = textToShow.indexOf(" ", COMMENT_LENGTH_COLLAPSED)
    textToShow = textToShow.slice(0, nextWhitespace <= 0 ? COMMENT_LENGTH_COLLAPSED : nextWhitespace) + " ..."
    shorted = true
  }

  if (!shorted) return <Typography.Paragraph>{textToShow}</Typography.Paragraph>

  if (!expanded)
    return (
      <>
        <Typography.Paragraph>
          {textToShow}
          <StyledLinkText underline={false} onClick={expand}>
            {t(keys.button.general.showMore)}
          </StyledLinkText>
        </Typography.Paragraph>
      </>
    )

  return (
    <>
      <Typography.Paragraph>{children}</Typography.Paragraph>
      <StyledLinkText underline={false} onClick={collapse}>
        {t(keys.button.general.showLess)}
      </StyledLinkText>
    </>
  )
}
