import React from "react"
import { Pagination } from "antd"

const pageSizeOptions = ["10", "20", "50", "100"]

type PagerProps = {
  currentPage: number
  pageSize: number
  totalCount: number
  small?: boolean
  onPageChanged: (newPage: number, pageSize: number) => void
  onPageSizeChanged?: (page: number, newPageSize: number) => void
  showTotal: (total: number, range: [number, number]) => string | React.ReactNode
  dataTestId?: string
}

export function Pager(props: PagerProps) {
  const showSizeChanger = !!props.onPageSizeChanged
  return (
    <Pagination
      current={props.currentPage < 1 ? 1 : props.currentPage}
      pageSize={props.pageSize}
      total={props.totalCount}
      showTotal={props.showTotal}
      size={!props.small ? "default" : "small"}
      showLessItems
      showSizeChanger={showSizeChanger}
      pageSizeOptions={pageSizeOptions}
      onChange={(p, ps) => props.onPageChanged(p, ps || props.pageSize)}
      onShowSizeChange={props.onPageSizeChanged}
      data-testid={props.dataTestId}
    />
  )
}
