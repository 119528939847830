import * as React from "react"
import { SVGProps } from "react"

const SvgCodeBranch = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="1em" height="1em" {...props}>
    <path d="M80 112a32 32 0 1 0 0-64 32 32 0 1 0 0 64zm80-32c0 35.8-23.5 66.1-56 76.3V256c20.1-15.1 45-24 72-24h96c39.8 0 72-32.2 72-72v-3.7c-32.5-10.2-56-40.5-56-76.3 0-44.2 35.8-80 80-80s80 35.8 80 80c0 35.8-23.5 66.1-56 76.3v3.7c0 66.3-53.7 120-120 120h-96c-39.8 0-72 32.2-72 72v3.7c32.5 10.2 56 40.5 56 76.3 0 44.2-35.8 80-80 80S0 476.2 0 432c0-35.8 23.5-66.1 56-76.3V156.3C23.5 146.1 0 115.8 0 80 0 35.8 35.8 0 80 0s80 35.8 80 80zm240 0a32 32 0 1 0-64 0 32 32 0 1 0 64 0zM80 464a32 32 0 1 0 0-64 32 32 0 1 0 0 64z" />
  </svg>
)

export default SvgCodeBranch
