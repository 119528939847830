import * as R from "ramda"
import qs from "qs"
import uuid from "uuid"
import Cookies from "js-cookie"
import React, { PropsWithChildren } from "react"
import styled, { withTheme } from "styled-components"
import { withTranslation, WithTranslation } from "react-i18next"
import MdRemoveCircle from "@schema/react-ionicons/components/MdRemoveCircle"
import { keys } from "@st4/ui-strings"
import bytes from "bytes"
import i18n from "./i18n"

type Instance = {
  actions: Action[]
  fields: Partial<Field>[] | null
  history: any
}

type Action = {
  fields: Partial<Field>[]
}

type DataDisplayPair = {
  _data: string
  _display: string
}

type FieldValue =
  | string
  | {
      display: string | null
      data: string | null
    }

export type MetaData = { key: string; value: any }
type History = {
  states: HistoryState[]
}

type HistoryState = {
  status: {
    oldValue: { display: string; data: string }
    newValue: { display: string; data: string }
  }
  fields: HistoryStateField[]
}

type HistoryStateField = {
  change: {
    oldValue: { display: string; data: string }
    newValue: { display: string; data: string }
  }
}

type Field = {
  id: string
  displayType: boolean
  name: string
  type: string
  value: FieldValue | Record<string, DataDisplayPair> | null
}

type FieldPage = {
  id: string
  fieldPage: { id: string; fieldItems: any[] }
}

export type DisplayValueType =
  | "STREAM"
  | "LIST_OF_STREAM"
  | "DUE_DATE"
  | "START_DATE"
  | "DATE_TIME"
  | "LIST_OF_DATE_TIME"
  | "PASSWORD"

const generateUUID = function (val: any) {
  return {
    [uuid()]: val,
  }
}

export const generateUUIDs = function (UUIDFn: (o: any) => object) {
  return R.reduce(function (memo, val) {
    return R.merge(memo, UUIDFn(val))
  }, {})
}

export function parentPath(string: string) {
  let segments = string.split("/")
  return segments.slice(0, segments.length - 1).reduce((path, seg) => path + "/" + seg)
}

export function mapOf(key: string, objs: Record<string, any>[], fn?: (o: any) => any) {
  let map = fn || ((x) => x)
  return objs.reduce((acc: Record<string, any>, o: Record<string, any>) => ((acc[o[key]] = map(o)), acc), {})
}

export function pick(props: string[], obj: Record<string, any>) {
  return props.reduce((res: Record<string, any>, p) => ((res[p] = obj[p]), res), {})
}

export function isNil(val?: any) {
  return val === undefined || val === null || (val.hasOwnProperty("length") && val.length === 0)
}

export function semiToComma(string: string) {
  return string.trim().replace(/[ \t]*;[ \t]*/g, ", ")
}

export function isListField(field: Field) {
  return field.type.match(/^LIST_OF_/) !== null
}

export const isObject = R.is(Object)

export const isArray = R.is(Array)

export const toArray: (o: any) => any[] = R.compose((o: any) => R.flatten(o), R.of)

export const fieldSeparator = "\uE027"
export const secondSeparator = "\uE028"
export const thirdSeparator = "\uE029"

export function splitBySeparator(val: string) {
  if (val.indexOf(fieldSeparator) !== -1) {
    return val.split(fieldSeparator)
  } else {
    return val
  }
}

export function tryParseJSON(val: any) {
  var result
  try {
    result = JSON.parse(val)
  } catch (e) {
    result = val
  }
  return result
}

export function findByMetadataKey(key: string) {
  return (o: { metaData: MetaData[] | null }) => {
    const metaData = o.metaData || []
    const entry = metaData.find((m) => m.key === key)
    return entry ? entry.value : undefined
  }
}

export const dateFormatInputField = "YYYY-MM-DD"
export const dateFormatFlow = "YYYY-MM-DDTHH:mm:ss.0000000Z"

export const st4IDRegex = /^\d{6,}(\uE027\d{6,})*$/
export const matchesST4ID = R.test(st4IDRegex)

export function createFlowFieldValue(val: any) {
  return {
    data: R.compose(R.map(R.prop("_data")), (o: any) => R.values(o))(val).join(fieldSeparator),
  }
}

export function flowFieldsFromObject<T extends object, K extends keyof T>(instanceid: string, obj: T) {
  return Object.keys(obj).map((f) => ({
    id: f.replace(new RegExp(`^(${instanceid}[_])?`), ""),
    value: createFlowFieldValue(obj[f as K]),
  }))
}

export const hasValue = R.complement(R.isNil)

function fileExtension(filename: string) {
  var match = filename.match(/([.]\S+)?$/)
  var extension = match && match[1]
  return extension || ""
}

type StreamValueOptions = {
  fileName: string
  extension: string
  total: number
  hash: string
  content: string
}

export function buildStreamValue({ fileName, extension, total, hash, content }: StreamValueOptions) {
  return `${uuid()}${secondSeparator}${fileName}${secondSeparator}${extension}${secondSeparator}${total}${secondSeparator}${hash}${secondSeparator}${content}`
}

export function parseStreamValue(_data: string) {
  if (_data === "") return null
  const [uuid, fileName, extension, total, hash, content] = _data.split(secondSeparator)
  return {
    uuid,
    fileName,
    extension,
    total: window.parseInt(total, 10),
    hash,
    content,
  }
}

export function substringAfter(term: string, str: string) {
  const idx = str.indexOf(term)
  if (idx === -1) return ""
  return str.slice(idx + term.length)
}

export function readFileValue(input: { files: Array<File> }) {
  return new Promise<string>(function (resolve, reject) {
    var file = input.files ? input.files[0] : null
    if (!file) reject()
    var reader = new FileReader()
    reader.onload = function ({ target, total }) {
      if (!target || !file || !target.result || typeof target.result !== "string") {
        reject()
      } else {
        const result = target.result
        const extension = fileExtension(file.name)
        const value = buildStreamValue({
          fileName: file.name,
          extension,
          total,
          hash: "",
          content: substringAfter("base64,", result),
        })
        resolve(value)
      }
    }
    reader.onerror = reject
    reader.readAsDataURL(file!)
  })
}

const splitBy = R.invoker(1, "split")

export const extractSelectionItems = R.compose(function (itemsData: string, itemsDisplay: string) {
  function zipper(a: string, b: string) {
    return {
      value: {
        _data: a,
        _display: b,
      },
    }
  }
  return R.zipWith(zipper, splitBy(fieldSeparator, itemsData || ""), splitBy(fieldSeparator, itemsDisplay || ""))
})

export const normalizeField = function (UUIDFn: (o: any) => any, field: any) {
  if (
    field.isList &&
    findByMetadataKey("display-control")(field) !== "ComboBox" &&
    R.isEmpty(field.value.data || "") &&
    R.isEmpty(field.value.display || "")
  ) {
    return {
      ...field,
      value: {},
    }
  }
  const dataValues = splitBy(fieldSeparator, field.value.data || "")
  let displayValues = splitBy(fieldSeparator, field.value.display || "")
  if (displayValues.length < dataValues.length) {
    displayValues = displayValues.concat(R.times(R.always(""), dataValues.length - displayValues.length))
  }
  const zipped = R.zipWith((data, display) => ({ _data: data, _display: display }), dataValues, displayValues)

  return {
    ...field,
    value: generateUUIDs(UUIDFn)(zipped),
  }
}

export function mapFieldChange(field: any) {
  return {
    ...field,
    change: {
      oldValue: normalizeField(generateUUID, { value: field.change.oldValue }).value,
      newValue: normalizeField(generateUUID, { value: field.change.newValue }).value,
    },
  }
}

function dataValuesUnequal(field: HistoryStateField) {
  return field.change.oldValue.data !== field.change.newValue.data
}

function mapState(state: HistoryState) {
  return {
    ...state,
    fields: (state.fields || []).filter(dataValuesUnequal).map(mapFieldChange),
  }
}

export function transformHistoryStates(states: HistoryState[]) {
  return R.compose(R.invoker(0, "reverse"), R.sortBy(R.prop("creationDate")), R.map(mapState))(states)
}

export function transformHistory(history: History) {
  return {
    ...history,
    states: transformHistoryStates(history.states || []),
  }
}

export const filterStreamFields = R.anyPass([R.propEq("type", "STREAM"), R.propEq("type", "LIST_OF_STREAM")])

function applyUUIDFromData(val: any) {
  const data = val._data || ""
  const sv = parseStreamValue(data)
  const obj = sv || ({} as { uuid?: any })
  const id = obj.uuid || uuid()
  return { [id]: val }
}

export function transformFields(fieldDisplayPred: (a: any) => boolean, i: Instance) {
  const [streamFields, nonStreamFields] = R.partition(filterStreamFields)(i.fields || [])
  const nonStreamFieldsMapped = (nonStreamFields || []).map(normalizeField.bind(null, generateUUID))
  const streamFieldsMapped = (streamFields || []).map(normalizeField.bind(null, applyUUIDFromData))
  return {
    ...i,
    fields: [...nonStreamFieldsMapped.filter(fieldDisplayPred), ...streamFieldsMapped.filter(fieldDisplayPred)],
    history: transformHistory(i.history || {}),
  }
}

export const fieldsTransformer = {
  props: ({ data }: { data?: any }) => {
    var fieldFilterPred = (field: { displayType: string }) => !!field.displayType
    var instances = (data.instances || []).map(transformFields.bind(null, fieldFilterPred))
    var instanceInfo = data.instanceInfo ? transformFields(fieldFilterPred, data.instanceInfo) : null
    return {
      data: {
        ...data,
        instances,
        instanceInfo,
      },
    }
  },
}

export const fieldsTransformerAction = function (fieldPages: FieldPage[]) {
  const _fields = R.compose(
    R.flatten,
    R.pluck("fieldItems"),
    R.map(function (fp: FieldPage) {
      return {
        ...fp,
        fieldItems: fp.fieldPage.fieldItems.map((fi) => {
          return {
            ...fi,
            fieldPageId: fp.id || fp.fieldPage.id,
          }
        }),
      }
    }),
  )(fieldPages || [])
  const [actionStreamFields, actionNonStreamFields] = R.partition(filterStreamFields)(_fields || [])
  const actionNonStreamFieldsMapped: any[] = R.map(normalizeField.bind(null, generateUUID), actionNonStreamFields || [])
  const actionStreamFieldsMapped: any[] = R.map(normalizeField.bind(null, applyUUIDFromData), actionStreamFields || [])
  return [...actionNonStreamFieldsMapped, ...actionStreamFieldsMapped]
}

export function b64toBlob(b64Data: string, fileName: string, contentType = "", sliceSize = 512) {
  const byteCharacters = atob(b64Data)
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)

    byteArrays.push(byteArray)
  }

  const blob = new Blob(byteArrays, { type: contentType })
  return URL.createObjectURL(blob)
}

export function getNodeIdFromPatternContext(patternCtx: string) {
  return R.last(patternCtx.split(/\//g))
}

function stObjectToOption(sto: { id: string; name: string }) {
  return {
    value: {
      _data: sto.id,
      _display: sto.name,
    },
  }
}

export const stObjectsToOptions = R.map(stObjectToOption)

function userToOption(user: { id: string; displayName: string }) {
  return {
    value: {
      _data: user.id,
      _display: user.displayName,
    },
  }
}

export const usersToOptions = R.map(userToOption)

export function getLinkedNodes(obj: Partial<Instance>): Field | {} {
  const fields = obj.fields || []
  return fields.find((f) => f.type === "LINKED_NODES") || {}
}

export function getLinkedNodePairs(obj: Partial<Instance>) {
  const linkedNodes = getLinkedNodes(obj)
  if (linkedNodes && (linkedNodes as Field).value && typeof (linkedNodes as Field).value != "string") {
    const value = (linkedNodes as Field).value as Record<string, DataDisplayPair>
    return Object.keys(value)
      .map((k) => value[k])
      .filter((v) => !R.isEmpty(v._data))
  } else {
    return []
  }
}

export function noop() {}

export const isNilOrEmpty = R.anyPass([R.isNil, R.isEmpty])

export function getDisplayValue(val: DataDisplayPair) {
  return val._display || val._data
}

export function getDisplayValues(val: Record<string, any>) {
  return R.reject(
    isNilOrEmpty,
    Object.keys(val).map((k) => getDisplayValue(val[k])),
  )
}

export function getDataValue(val: { _data: any }) {
  return val._data
}

export function getDataValues(val: Record<string, any>) {
  const values = Object.keys(val).map((k) => getDataValue(val[k])) || []
  return R.reject(isNilOrEmpty, values)
}

export function getOrCreateDeviceID() {
  let value = localStorage.getItem("SlimClientDeviceID")
  if (!value) {
    return new Promise<string>((resolve, reject) => {
      const id = uuid()
      localStorage.setItem("SlimClientDeviceID", id)
      resolve(id)
    })
  }
  return Promise.resolve(value)
}

export function unsetDeviceID(value: string) {
  return localStorage.removeItem("SlimClientDeviceID")
}

export function getCookie(key: string) {
  return Cookies.get(key)
}

export function extractAllErrorMessages({ graphQLErrors }: any) {
  return R.map(
    R.cond([
      [R.propSatisfies(R.complement(R.isEmpty), "exceptionMessage"), R.prop("exceptionMessage")],
      [R.always(true), R.prop("message")],
    ]),
  )(graphQLErrors)
}

export function extractAllExceptionTypes({ graphQLErrors }: any) {
  return R.pluck("exceptionType")(graphQLErrors)
}

// Tree Search

type GoalFn<T> = (o: T) => boolean
type ChildrenFn<T> = (state: T) => T[]
type CombinerFn<T> = (children: T[], next: T[]) => T[]
type SearchResult<T> = null | T
export function treeSearch<T>(
  states: T[],
  goalFn: GoalFn<T>,
  childrenFn: ChildrenFn<T>,
  combiner: CombinerFn<T>,
): SearchResult<T> {
  if (states.length === 0) return null
  else if (goalFn(states[0])) return states[0]
  else return treeSearch(combiner(childrenFn(states[0]), states.slice(1)), goalFn, childrenFn, combiner)
}

export const append = Array.prototype.concat.bind([])

export function prepend(a: any[], b: any[]) {
  return append(b, a)
}

export function depthFirstSearch<T>(start: T, goalP: GoalFn<T>, childrenFn: ChildrenFn<T>) {
  return treeSearch([start], goalP, childrenFn, append)
}

export function breadthFirstSearch<T>(start: T, goalFn: GoalFn<T>, childrenFn: ChildrenFn<T>) {
  return treeSearch([start], goalFn, childrenFn, prepend)
}

type Tree = { children: Tree[] }

export function walkTree(fn: (state: Tree) => void, tree: Tree) {
  var goal = (state: Tree) => {
    fn(state)
    return false
  }
  var children = (state: Tree) => state.children || []
  breadthFirstSearch(tree, goal, children)
}

export function findCurrentStatus(o: any) {
  const states: any[] = o?.history?.states || []
  return R.last(states)?.status?.newValue?.data
}

export function getQueryParam(name: string, search: string) {
  return qs.parse(search.replace(/^\?/, ""))[name]
}

export const doubleRegex = "^[-]?([0-9]+([.][0-9]+)?)?$"

export function valueChanged(name: string, initialFields: any, valueNew: any) {
  const oldVal = R.compose(
    R.pluck("_data"),
    (o: Record<string, any>) => R.values(o),
    R.prop("value"),
    R.find(R.propEq("id", name)),
  )(initialFields)
  const newVal = R.compose(R.pluck("_data"), (o: Record<string, any>) => R.values(o))(valueNew)
  return !R.equals(oldVal, newVal)
}

export const limitString = R.curry(function (n, label) {
  return label.length > n ? `${label.slice(0, n - 3)}...` : label
})

function renderDisplayValue(value: any, renderValueFunc: (v: any) => string) {
  return renderValueFunc ? renderValueFunc(value) : value.display
}

type ListDisplayValueProps = PropsWithChildren<WithTranslation> & {
  list: any[]
  className?: string
  renderValue: (v: any) => string
}

function _ListDisplayValue(props: ListDisplayValueProps) {
  const { list, className, renderValue } = props

  return (
    <ul className={className}>
      {list.map((value) => {
        return <li key={value.data}>{renderDisplayValue(value, renderValue)}</li>
      })}
    </ul>
  )
}

// prettier-ignore
const ListDisplayValue = withTranslation()(
    styled(_ListDisplayValue)`padding: 20px;`
)

type DisplayValueProps = PropsWithChildren<WithTranslation> & {
  value: any
  renderValue: (v: any) => string
  t: any
  theme: any
}
export const _DisplayValue = (props: DisplayValueProps) => {
  const { value, renderValue, t } = props
  if (value && value.display) {
    var valueList: any[] = convertToValueList(value)
    if (valueList.length > 1) return <ListDisplayValue list={valueList} renderValue={renderValue} />

    return <div>{renderDisplayValue(value, renderValue)}</div>
  } else {
    return (
      <div title={t(keys.label.general.noValue)}>
        <MdRemoveCircle color={props.theme.disabledColor} title={t(keys.label.general.noValue)} />
      </div>
    )
  }
}

type DisplayValueComponent = (props: DisplayValueProps) => JSX.Element | null
export const DisplayValue: DisplayValueComponent = withTheme(withTranslation()(_DisplayValue))

export function convertToValueList(value: any) {
  var displayValue = value.display
  var dataValue = value.data
  if (displayValue) {
    var displayValueList = displayValue.split("\uE027")
    var dataValueList = dataValue ? dataValue.split("\uE027") : []
    return displayValueList.map((listValue: any, index: number) => ({ display: listValue, data: dataValueList[index] }))
  }
  return []
}

function mapDisplayValue(type: DisplayValueType) {
  return (value: any) => {
    const displayValues = R.map(getDisplayValue)(value)
    switch (type) {
      case "STREAM":
      case "LIST_OF_STREAM":
        if (!value[0]._data) return displayValues
        return displayValues.map(parseStreamValue).map(
          (psv) =>
            `${psv!.fileName} (${bytes(psv!.total, {
              unitSeparator: " ",
              thousandsSeparator: ".",
              decimalPlaces: psv!.total >= 1000000 ? 2 : 0,
            })})`,
        )
      case "DUE_DATE":
      case "START_DATE":
      case "DATE_TIME":
      case "LIST_OF_DATE_TIME":
        return displayValues.map((val) => {
          let datetime = new Date(val)
          return !isNaN(Number(datetime))
            ? datetime.toLocaleString(i18n.language, {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
              })
            : ""
        })
      case "PASSWORD":
        return displayValues.map((v) => "\u2022".repeat(v.length))
      default:
        return displayValues
    }
  }
}

export function mapSingleDisplayValue(type: DisplayValueType, value: DataDisplayPair) {
  const displayValue = getDisplayValue(value)
  switch (type) {
    case "STREAM":
    case "LIST_OF_STREAM":
      const psv = parseStreamValue(displayValue)
      return psv
        ? `${psv!.fileName} (${bytes(psv!.total, {
            unitSeparator: " ",
            thousandsSeparator: ".",
            decimalPlaces: psv!.total >= 1000000 ? 2 : 0,
          })})`
        : ""

    case "DUE_DATE":
    case "START_DATE":
    case "DATE_TIME":
    case "LIST_OF_DATE_TIME":
      let datetime = new Date(displayValue)
      return !isNaN(Number(datetime))
        ? datetime.toLocaleString(i18n.language, {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
          })
        : ""
    case "PASSWORD":
      return "\u2022".repeat(displayValue.length)
    default:
      return displayValue
  }
}

export function getDisplayValuesTransformed(type: DisplayValueType, v: Record<string, any>) {
  return mapDisplayValue(type)(Object.keys(v).map((k) => v[k])).join(" ; ")
}

export function normalizeDisplayName(name: string) {
  return name
    .trim()
    .split(/\s+/)
    .map((s) => s.toLowerCase().normalize("NFKD").replace(/[^\w]/g, ""))
    .join(" ")
}

type ChangeData = {
  oldValue?: string
  newValue?: string
  description: string
}

export function calcHistoryStateLabel({
  assigneeChange,
  statusChange,
}: {
  assigneeChange: ChangeData
  statusChange: ChangeData
}) {
  if (statusChange && statusChange.newValue && statusChange.oldValue) {
    return statusChange
  }
  if (assigneeChange && assigneeChange.newValue && assigneeChange.oldValue) {
    return assigneeChange
  }
}
export const getValidationMessageKeyByRulename = (ruleName: string) => {
  switch (ruleName.toLowerCase()) {
    case "date":
      return keys.message.validation.dateFormat
    case "double":
      return keys.message.validation.numberDouble
    case "noemptyvaluesinlist":
      return keys.message.validation.emptyRow
    case "required":
    case "requiredlist":
    case "noemptyvalues":
      return keys.message.validation.required
    case "validxml":
      return keys.message.validation.xml
    case "mincount":
      return keys.message.validation.minCount
    case "maxcount":
      return keys.message.validation.maxCount
    case "minlength":
      return keys.message.validation.minLength
    case "maxlength":
      return keys.message.validation.maxLength
    case "mindate":
      return keys.message.validation.minDate
    case "maxdate":
      return keys.message.validation.maxDate
    case "minvaluedouble":
    case "minvalueint":
      return keys.message.validation.minValue
    case "maxvaluedouble":
    case "maxvalueint":
      return keys.message.validation.maxValue
    case "matchesregexp":
      return keys.message.validation.format
    case "streammaxsize":
      return keys.message.validation.filesizeToLarge
    case "streamtype":
      return keys.message.validation.fileType
    case "passwordconfirmed":
      return keys.message.validation.passwordConfirmed
    default:
      return "Error"
  }
}
