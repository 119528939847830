import * as React from "react"
import { SVGProps } from "react"

const SvgUserLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1em" height="1em" {...props}>
    <path d="M352 144a96 96 0 1 0-192 0 96 96 0 1 0 192 0zm-240 0a144 144 0 1 1 288 0 144 144 0 1 1-288 0zM49.3 464h413.4c-8.3-54.4-55.3-96-112-96H161.3c-56.7 0-103.6 41.6-112 96zM0 481.3C0 392.2 72.2 320 161.3 320h189.4c89.1 0 161.3 72.2 161.3 161.3 0 17-13.8 30.7-30.7 30.7H30.7C13.8 512 0 498.2 0 481.3z" />
  </svg>
)

export default SvgUserLarge
