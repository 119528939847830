import React from "react"
import styled from "styled-components"
import { useMediaNodeWithAlternatives } from "./hooks"
import type { XastRendererProps } from "../../XASTRenderer"
import { useSt4ToTreeNode } from "../../../utilities/treeUtilities"
import { FilteringTreeNodeWrapper } from "../../FilteringTreeNodeWrapper"
import { isTypename } from "@st4/graphql"
import { Image } from "antd"
import { GetMediaSource } from "@st4/content-tools"
import { Icon, Light } from "@st4/icons"

function _InlineImage(props: XastRendererProps & { className: string }) {
  const src = props.ast.attributes?.src?.value || ""
  const { filteredResult } = useMediaNodeWithAlternatives(src)
  const st4ToTreeNode = useSt4ToTreeNode()
  const treeNode = st4ToTreeNode(filteredResult)

  const imageMediaInfo = filteredResult?.content.media
  // returning null for NoMediaInfo instead of using the empty string GetMediaSource returns for src.
  // This way the inline image doesnt take up any space in that case
  if (!treeNode || !filteredResult || !imageMediaInfo || isTypename("NoMediaInfo")(imageMediaInfo)) return null

  return (
    <FilteringTreeNodeWrapper treeNodeId={treeNode.id} isInline>
      <Image
        className={props.className}
        src={GetMediaSource(imageMediaInfo)}
        // Mask is overwritten so only icon is shown and no text because the text would always overflow
        preview={{ mask: <Icon component={Light.Eye} /> }}
      />
    </FilteringTreeNodeWrapper>
  )
}

// Only supports Images completely, for other types like Callouts or Animations the thumbnail is used as fallback.
export const InlineImage = styled(_InlineImage)`
  max-width: 100%;
  max-height: 2em;
  vertical-align: middle;
`
