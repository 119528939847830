import * as React from "react"
import { SVGProps } from "react"

const SvgArrowDownRight = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" width="1em" height="1em" {...props}>
    <path d="M328 416c13.3 0 24-10.7 24-24V152c0-13.3-10.7-24-24-24s-24 10.7-24 24v182.1L73 103c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l231 231H88c-13.3 0-24 10.7-24 24s10.7 24 24 24h240z" />
  </svg>
)

export default SvgArrowDownRight
