import { isTypename } from "@st4/graphql"

export function GetMediaSource(
  mediaInfo: { thumbnail?: string | null } & (
    | {
        __typename: "NoMediaInfo" | "VideoMediaInfo" | "AnimationMediaInfo"
      }
    | { __typename: "ImageMediaInfo"; src?: string | null }
    | { __typename: "VectorGraphicsMediaInfo"; svg?: string | null }
  ),
) {
  if (isTypename("NoMediaInfo")(mediaInfo)) return ""
  let mediaSrc = mediaInfo?.thumbnail
  if (isTypename("ImageMediaInfo")(mediaInfo) && mediaInfo.src) {
    mediaSrc = mediaInfo.src
  } else if (isTypename("VectorGraphicsMediaInfo")(mediaInfo)) {
    mediaSrc = mediaInfo.svg ?? mediaInfo?.thumbnail
  }
  return mediaSrc ?? ""
}
