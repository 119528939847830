import * as React from "react"
import { SVGProps } from "react"

const SvgTrash = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="1em" height="1em" {...props}>
    <path d="M177.7 32h92.5c5.5 0 10.6 2.8 13.6 7.5L299.1 64H148.9l15.3-24.5c2.9-4.7 8.1-7.5 13.6-7.5zm159.2 32L311 22.6A47.98 47.98 0 0 0 270.3 0h-92.6c-16.5 0-31.9 8.5-40.7 22.6L111.1 64H16C7.2 64 0 71.2 0 80s7.2 16 16 16h18.3l25.5 356.6C62.1 486.1 90 512 123.6 512h200.8c33.6 0 61.4-25.9 63.8-59.4L413.7 96H432c8.8 0 16-7.2 16-16s-7.2-16-16-16h-95.1zm44.8 32-25.4 354.3c-1.2 16.7-15.1 29.7-31.9 29.7H123.6c-16.8 0-30.7-13-31.9-29.7L66.4 96h315.2z" />
  </svg>
)

export default SvgTrash
