import * as React from "react"
import { SVGProps } from "react"

const SvgPrint = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1em" height="1em" {...props}>
    <path d="M112 160V64c0-8.8 7.2-16 16-16h229.5c4.2 0 8.3 1.7 11.3 4.7l26.5 26.5c3 3 4.7 7.1 4.7 11.3V160h48V90.5c0-17-6.7-33.3-18.7-45.3l-26.6-26.5C390.7 6.7 374.5 0 357.5 0H128C92.7 0 64 28.7 64 64v96h48zm16 208h256v96H128v-96zm-16-48c-17.7 0-32 14.3-32 32H48v-96c0-8.8 7.2-16 16-16h384c8.8 0 16 7.2 16 16v96h-32c0-17.7-14.3-32-32-32H112zm320 80h48c17.7 0 32-14.3 32-32V256c0-35.3-28.7-64-64-64H64c-35.3 0-64 28.7-64 64v112c0 17.7 14.3 32 32 32h48v80c0 17.7 14.3 32 32 32h288c17.7 0 32-14.3 32-32v-80z" />
  </svg>
)

export default SvgPrint
