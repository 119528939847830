import * as React from "react"
import { SVGProps } from "react"

const SvgSt4InfoNodeGroupLight = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1em" height="1em" {...props}>
    <path d="M281.6 25.6c0 15.4-10.2 25.6-25.6 25.6S230.4 41 230.4 25.6 240.6 0 256 0s25.6 10.2 25.6 25.6zm-76.8 76.8c0-10.2 7.7-17.9 17.9-17.9H256c10.2 0 17.9 7.7 17.9 17.9v120.3h17.9c7.7 0 15.4 7.7 15.4 15.4 0 10.2-7.7 17.9-17.9 17.9h-66.6c-10.2 0-17.9-7.7-17.9-17.9 0-7.7 7.7-15.4 17.9-15.4h17.9V120.3h-17.9c-10.2 0-17.9-7.7-17.9-17.9zM79.4 220.2c2.6 7.7 0 17.9-7.7 20.5L38.4 256l209.9 94.7c2.6 2.6 5.1 2.6 7.7 2.6s5.1 0 7.7-2.6L473.6 256l-33.3-15.4c-7.7-2.6-12.8-12.8-7.7-20.5 2.6-7.7 12.8-12.8 20.5-7.7l41 17.9c10.2 5.1 17.9 15.4 17.9 25.6s-7.7 20.5-17.9 25.6l-217.6 97.3c-5.1 2.6-12.8 5.1-20.5 5.1s-15.4-2.6-23-5.1L18 281.5c-10.3-5-18-15.3-18-25.5s7.7-20.5 17.9-25.6l41-17.9c7.7-5.1 15.3 0 20.5 7.7zM38.4 384l209.9 94.7c2.6 2.6 5.1 2.6 7.7 2.6s5.1 0 7.7-2.6L473.6 384l-33.3-15.4c-7.7-2.6-12.8-12.8-7.7-20.5 2.6-7.7 12.8-12.8 20.5-7.7l41 17.9c10.2 5.1 17.9 15.4 17.9 25.6s-7.7 20.5-17.9 25.6l-217.6 97.3c-5.1 2.6-12.8 5.1-20.5 5.1s-15.4-2.6-23-5.1L18 409.5c-10.3-5-18-15.3-18-25.5s7.7-20.5 17.9-25.6l41-17.9c7.7-2.6 17.9 0 20.5 7.7s0 17.9-7.7 20.5L38.4 384z" />
  </svg>
)

export default SvgSt4InfoNodeGroupLight
