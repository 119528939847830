import React from "react"
import { Modal } from "antd"
import { Button } from "../Button"

export default function ConfirmDialog_deprecated(props) {
  let {
    closeOnEsc,
    closeOnOutsideClick,
    confirmAction,
    cancelAction,
    isOpened,
    headline,
    content,
    confirmButtonText,
    cancelButtonText,
  } = props
  return (
    <Modal
      closable={closeOnEsc || closeOnOutsideClick || false}
      title={headline}
      open={isOpened}
      footer={[
        <Button onClick={cancelAction} key="cancel">
          {cancelButtonText}
        </Button>,
        <Button primary onClick={confirmAction} key="confirm">
          {confirmButtonText}
        </Button>,
      ]}
    >
      {content}
    </Modal>
  )
}
