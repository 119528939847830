import React from "react"
import { Popconfirm, PopconfirmProps, theme } from "antd"
import { Icon, Regular } from "@st4/icons"

export type NotificationPopoverProps = {
  /**
   * The state for which the popover should be shown.
   * Controls the icon and the color
   */
  state: "success" | "info" | "warning" | "error"
  open?: boolean
  /**
   * Hide the icon representing the state.
   * By default the icon is shown. It is recommended to only hide it when absolutely necessary
   */
  hideIcon?: boolean
  /**
   * Define the default placement of the popover relative to its child.
   * If there isn't enough space the popover will mirror to the opposite direction
   */
  placement: NonNullable<PopconfirmProps["placement"]>
  onOpenChange?: (open: boolean) => void
  content: React.ReactNode
  /**
   * Control whether a primary button is shown
   */
  showPrimaryButton?: boolean
  /**
   * Customize the label of the primary button. The default is translated "OK"
   */
  primaryButtonLabel?: string
  /**
   * Control whether a primary button is shown
   */
  showSecondaryButton?: boolean
  /**
   * Customize the label of the primary button. The default is translated "Cancel"
   */
  secondaryButtonLabel?: string
  onClickPrimary?: (ev: React.MouseEvent<HTMLElement, MouseEvent> | undefined) => void
  onClickSecondary?: (ev: React.MouseEvent<HTMLElement, MouseEvent> | undefined) => void
  children: React.ReactNode
}

export function NotificationPopover({
  state,
  open,
  hideIcon,
  content,
  placement,
  primaryButtonLabel,
  secondaryButtonLabel,
  onClickPrimary,
  onClickSecondary,
  showSecondaryButton,
  showPrimaryButton,
  children,
  onOpenChange,
}: NotificationPopoverProps) {
  const stateColor = useStateColor(state)
  return (
    <Popconfirm
      title={content}
      icon={hideIcon ? null : <ColoredStateIcon state={state} />}
      placement={placement}
      open={!!open}
      overlayInnerStyle={{
        borderLeft: `4px ${placement?.startsWith("right") ? "none" : "solid"} ${stateColor}`,
        borderRight: `4px ${placement?.startsWith("right") ? "solid" : "none"} ${stateColor}`,
      }}
      okText={primaryButtonLabel}
      cancelText={secondaryButtonLabel}
      okButtonProps={showPrimaryButton ? undefined : { style: { display: "none" } }}
      cancelButtonProps={showSecondaryButton ? undefined : { style: { display: "none" } }}
      onConfirm={onClickPrimary}
      onCancel={onClickSecondary}
      onOpenChange={onOpenChange}
    >
      {children}
    </Popconfirm>
  )
}

function ColoredStateIcon({ state }: Pick<NotificationPopoverProps, "state">) {
  const stateColor = useStateColor(state)
  const colorStyle = { color: stateColor }
  switch (state) {
    case "success":
      return <Icon component={Regular.Check} style={colorStyle} />
    case "info":
      return <Icon component={Regular.CircleInfo} style={colorStyle} />
    case "warning":
      return <Icon component={Regular.CircleExclamation} style={colorStyle} />
    case "error":
      return <Icon component={Regular.TriangleExclamation} style={colorStyle} />
  }
}

function useStateColor(state: NotificationPopoverProps["state"]) {
  const { token } = theme.useToken()
  switch (state) {
    case "success":
      return token.colorSuccess
    case "info":
      return token.colorInfo
    case "warning":
      return token.colorWarning
    case "error":
      return token.colorError
  }
}
