import * as React from "react"
import { SVGProps } from "react"

const SvgUnlock = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="1em" height="1em" {...props}>
    <path d="M144 128c0-44.2 35.8-80 80-80 35.6 0 65.8 23.2 76.1 55.4 4.1 12.6 17.6 19.5 30.2 15.5s19.5-17.6 15.5-30.2C329.2 37.2 281 0 224 0 153.3 0 96 57.3 96 128v64H64c-35.3 0-64 28.7-64 64v192c0 35.3 28.7 64 64 64h320c35.3 0 64-28.7 64-64V256c0-35.3-28.7-64-64-64H144v-64zM48 256c0-8.8 7.2-16 16-16h320c8.8 0 16 7.2 16 16v192c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V256z" />
  </svg>
)

export default SvgUnlock
