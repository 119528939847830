import * as React from "react"
import { SVGProps } from "react"

const SvgBookBlank = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="1em" height="1em" {...props}>
    <path d="M0 64C0 28.7 28.7 0 64 0h336c26.5 0 48 21.5 48 48v320c0 20.9-13.4 38.7-32 45.3V480h16c8.8 0 16 7.2 16 16s-7.2 16-16 16H64c-35.3 0-64-28.7-64-64V64zm64 352c-17.7 0-32 14.3-32 32s14.3 32 32 32h320v-64H64zm-32-23.4c9.4-5.4 20.3-8.6 32-8.6h32V32H64c-17.7 0-32 14.3-32 32v328.6zM128 32v352h272c8.8 0 16-7.2 16-16V48c0-8.8-7.2-16-16-16H128z" />
  </svg>
)

export default SvgBookBlank
