import React, { useEffect } from "react"
import { Spin, Card, Form, Input, Button, Row, Col, Alert, Space } from "antd"
import { useTranslation } from "react-i18next"
import LoginBackground from "./LoginBackground"
import { useAuthentication } from "./useAuthentication"
import type { LoginPageProps, AuthenticationState } from "./types"
import { LOGIN_STATE_VAR, isLoggedIn } from "./utils"
import { Translate, keys } from "@st4/i18n"
import { Icon, Regular, Light } from "@st4/icons"

type FormValues = { username: string; password: string; forceLogin: boolean }

export function LoginPage({ language, successUrl, logo, license }: LoginPageProps) {
  const [authState, { login }] = useAuthentication()

  function onFinish(values: FormValues) {
    login(values.username, values.password, language, license, values.forceLogin)
  }

  const shouldRedirect = isLoggedIn(authState)
  return (
    <>
      <LoginBackground />
      {shouldRedirect ? (
        <Redirect target={successUrl} />
      ) : (
        <LoginForm logo={logo} state={authState} onFinish={onFinish} />
      )}
    </>
  )
}

function LoginAlert(props: { state: Extract<AuthenticationState, { state: "loginFailed" }> }) {
  const { state } = props

  const form = Form.useFormInstance<FormValues>()

  useEffect(() => {
    //Reset forceLogin field
    form.setFieldValue("forceLogin", false)
  })

  if (state.cause === "NAMED_LICENSE_LIMIT_REACHED") {
    return (
      <Alert
        description={
          <Space direction="vertical">
            <div style={{ whiteSpace: "pre-line" }}>
              <Translate>{keys.message.error.auth.allNamedLicenseAllocated}</Translate>
            </div>
            <Space direction="horizontal">
              <Button
                size="small"
                key="forceLogin"
                type="primary"
                style={{ width: "100%" }}
                onClick={() => {
                  form.setFieldValue("forceLogin", true)
                  form.submit()
                }}
              >
                <Translate>{keys.button.general.ok}</Translate>
              </Button>
              <Button
                size="small"
                danger
                ghost
                style={{ width: "100%" }}
                key="cancel"
                onClick={() => {
                  form.resetFields()
                  LOGIN_STATE_VAR({ state: "loggedOut" })
                }}
              >
                <Translate>{keys.button.general.cancel}</Translate>
              </Button>
            </Space>
          </Space>
        }
        style={{ display: "flex", borderRadius: 0 }}
        closable={false}
        type="warning"
      />
    )
  }

  return (
    <Alert
      description={
        state.cause === "INVALID_CREDENTIALS" ? (
          <Translate>{keys.message.error.auth.wrongPassword}</Translate>
        ) : (
          state.message
        )
      }
      type="error"
      closable
      style={{ display: "flex", borderRadius: 0 }}
    />
  )
}

function LoginForm({
  state,
  logo,
  onFinish,
}: {
  state: AuthenticationState
  logo?: React.ReactNode
  onFinish: (c: FormValues) => void
}) {
  const { t } = useTranslation()
  const [form] = Form.useForm<FormValues>()
  const disableInputs = state.state === "loginFailed" && state.cause === "NAMED_LICENSE_LIMIT_REACHED"

  useEffect(() => {
    const field = form.getFieldInstance("username")
    if (field) field.focus()
  }, [state, form])

  return (
    <Row justify="center" align="middle" style={{ height: "100vh" }}>
      <Col>
        <Form
          form={form}
          name="login_form"
          className="login-form"
          onFinish={onFinish}
          initialValues={{ forceLogin: false }}
        >
          <Card
            cover={
              <>
                {logo}
                {state.state === "loginFailed" ? <LoginAlert state={state} /> : null}
              </>
            }
            bordered={false}
            style={{ width: "300px", boxShadow: "0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)" }}
          >
            <Spin spinning={state.state === "checking"} delay={500}>
              <Form.Item
                name="username"
                rules={[{ required: true, message: t(keys.message.validation.auth.missingUsername) }]}
              >
                <Input
                  prefix={<Icon component={Light.User} className="site-form-item-icon" />}
                  placeholder={t(keys.label.auth.username)}
                  disabled={disableInputs}
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[{ required: false, message: t(keys.message.validation.auth.missingPassword) }]}
              >
                <Input
                  prefix={<Icon component={Regular.Lock} className="site-form-item-icon" />}
                  type="password"
                  placeholder={t(keys.label.auth.password)}
                  disabled={disableInputs}
                />
              </Form.Item>
              <Form.Item hidden name="forceLogin">
                {/* Used for Forced Login (all named licenses used but user wants to end other sessions.) */}
                <Input type="checkbox" />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  style={{ width: "100%" }}
                  disabled={disableInputs}
                >
                  {t(keys.button.auth.login)}
                </Button>
              </Form.Item>
            </Spin>
          </Card>
        </Form>
      </Col>
    </Row>
  )
}

function Redirect({ target }: { target?: string }) {
  useEffect(() => {
    if (target) window.location.href = target
    else window.location.reload()
  }, [target])
  return null
}

export default LoginPage
