import * as React from "react"
import { SVGProps } from "react"

const SvgRecycle = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1em" height="1em" {...props}>
    <path d="M181.5 40.9C197.7 15.3 226 0 256 0s58.3 15.3 74.5 40.9l62.3 98.1 6.6-31.8c2.7-13 15.4-21.3 28.3-18.7s21.3 15.4 18.7 28.3L427 211.2c-1.4 6.7-5.6 12.5-11.5 15.9s-13.1 4.2-19.6 2l-91.4-30.4c-12.6-4.2-19.4-17.8-15.2-30.3s17.8-19.4 30.3-15.2l32.2 10.7L290 66.6C282.7 55.1 269.8 48 256 48s-26.7 7.1-34 18.6l-21.5 33.9c-7 11-21.4 14.4-32.6 7.7-11.6-6.9-15.1-22-7.9-33.4l21.5-33.9zM439 255.5c11.2-6.7 25.6-3.3 32.6 7.7l26.8 42.2c8.8 13.9 13.5 30 13.6 46.4.3 48.6-39 88.2-87.6 88.2H287.2l24.8 22c9.9 8.8 10.8 24 2 33.9s-24 10.8-33.9 2l-72-64c-5.1-4.6-8.1-11.1-8.1-17.9s2.9-13.4 8.1-17.9l72-64c9.9-8.8 25.1-7.9 33.9 2s7.9 25.1-2 33.9l-24.9 22h137.3c22 0 39.8-17.9 39.6-39.9 0-7.4-2.2-14.7-6.2-21L431 288.9c-7.2-11.4-3.6-26.5 7.9-33.4zm-311.6 3.7-6.7-32.8-66.5 104.7c-4 6.3-6.1 13.6-6.2 21-.1 22 17.7 39.9 39.6 39.9H136c13.3 0 24 10.7 24 24s-10.7 24-24 24H87.6C39 440-.3 400.5 0 351.9c.1-16.4 4.8-32.5 13.6-46.4l65.2-102.7-31.2 10.3c-12.6 4.2-26.2-2.6-30.3-15.2s2.6-26.2 15.2-30.3l91.4-30.4c6.5-2.2 13.6-1.4 19.6 2s10.1 9.2 11.5 15.9l19.4 94.4c2.7 13-5.7 25.7-18.7 28.3s-25.7-5.7-28.3-18.7z" />
  </svg>
)

export default SvgRecycle
