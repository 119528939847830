import { useAppTokensQuery } from "./query.hooks"

type LoadingState = {
  state: "loading"
}
type ReadyState = {
  state: "ready"
  appTokens: { name: string; token: string }[]
}

type State = LoadingState | ReadyState

export function useAppTokenSettings(name?: string): State {
  const { data, loading, error } = useAppTokensQuery({
    variables: {
      name: name,
    },
  })

  if (loading) return { state: "loading" }

  return {
    state: "ready",
    appTokens:
      !!error || !data
        ? []
        : data.configuration.thirdPartyAppTokens.map((t) => ({
            name: t.name,
            token: t.token,
          })),
  }
}

export function isLoading(state: State): state is LoadingState {
  return state.state == "loading"
}

export function isReady(state: State): state is ReadyState {
  return state.state == "ready"
}
