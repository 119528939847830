import React, { useRef } from "react"
import { Input, InputProps, InputRef } from "antd"
import styled from "styled-components"
import { useLanguage } from "@st4/settings"
import { useTranslation } from "react-i18next"
import { keys } from "@st4/ui-strings"
import { Icon, Regular } from "@st4/icons"
import { Tooltip } from "@st4/ui"

const StyledSearchbar = styled(Input)`
  input {
    background: white;
  }
  border-color: ${({ theme }) => theme.lightGrey};
  box-shadow: 0 0 0 0;

  .ant-input::placeholder {
    color: ${({ theme }) => theme.silkGrey};
  }

  .ant-input-suffix {
    color: ${({ theme }) => theme.silverSand};
  }

  .ant-input-suffix:hover {
    color: ${({ theme }) => theme.shuttleGrey};
    cursor: pointer;
  }

  &:hover {
    border-color: ${({ theme }) => theme.lightGrey} !important;

    .ant-input::placeholder {
      color: ${({ theme }) => theme.shuttleGrey};
    }
  }

  width: 400px;
`

export const baseTestId = "quickSearchBox"

type QuickSearchProps = {
  onSearch: (searchTerm: string) => void
  tooltip?: string
} & InputProps

export const QuickSearchBox = function (props: QuickSearchProps) {
  const language = useLanguage()

  const { t } = useTranslation()
  const input = useRef<InputRef>(null)
  const { placeholder, onSearch, tooltip, ...restProps } = props

  function triggerOnSearchEvent() {
    const searchTerm = input.current?.input?.value
    if (searchTerm?.length) props.onSearch(searchTerm)
  }

  const searchIcon = (
    <Icon
      component={Regular.MagnifyingGlass}
      role={"button"}
      aria-label={"searchButton"}
      onClick={triggerOnSearchEvent}
    />
  )
  const searchBox = (
    <StyledSearchbar
      {...restProps}
      data-testid={`${baseTestId}-input`}
      placeholder={placeholder || t(keys.label.search.component.quickSearchBox)}
      onPressEnter={triggerOnSearchEvent}
      suffix={searchIcon}
      type="search"
      inputMode="search"
      enterKeyHint="search"
      ref={input}
    />
  )

  return (
    <div data-testid={baseTestId} style={{ flex: 1, textAlign: "center" }}>
      {!tooltip?.length ? (
        searchBox
      ) : (
        <Tooltip title={tooltip} placement="bottom">
          {searchBox}
        </Tooltip>
      )}
    </div>
  )
}
