import * as React from "react"
import { SVGProps } from "react"

const SvgCircleArrowUpRight = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1em" height="1em" {...props}>
    <path d="M256 464a208 208 0 1 0 0-416 208 208 0 1 0 0 416zm0-464a256 256 0 1 1 0 512 256 256 0 1 1 0-512zm-64 144h152c13.3 0 24 10.7 24 24v160c0 13.3-10.7 24-24 24s-24-10.7-24-24V225.9L185 361c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l135-135H192c-13.3 0-24-10.7-24-24s10.7-24 24-24z" />
  </svg>
)

export default SvgCircleArrowUpRight
