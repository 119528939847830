import { getNewCommentNode, InsertNewCommentNodeIntoContent } from "./utilities"
import { notification } from "antd"
import { useTranslation } from "react-i18next"
import { keys } from "@st4/ui-strings"
import type { AddInlineCommentInput } from "@st4/graphql"
import { isTypename } from "@st4/graphql"
import { TextContentForCommentFragment, TextContentForCommentFragmentDoc } from "../CommentList/query.hooks"
import { useAddInlineCommentToNodeMutation } from "./query.hooks"
import type { AddInlineCommentToNodeMutationHookResult, AddInlineCommentToNodeMutationResult } from "./query.hooks"

/**
 * Creates an optimistic result for the addInlineComment mutation based on its input.
 * @param commentToAdd the input to the comment add location
 * @param node the node to which the comment is added
 */
function createOptimisticResult(commentToAdd: AddInlineCommentInput) {
  const newComment = {
    authorDisplayName: commentToAdd.username,
    value: commentToAdd.commentText,
    editDate: new Date(Date.now()).toISOString(),
    commentKey: `${commentToAdd.username}_99`, //placeholder... TODO: CorrectValue?
    node: {
      id: commentToAdd.nodeId,
      aspectId: commentToAdd.aspectSelectorId,
      __typename: "ST4Node" as const,
      content: {
        id: commentToAdd.nodeId,
        __typename: "TextContent" as const,
        historyNumber: commentToAdd.historyNumber,
      },
    },
    authorUserName: commentToAdd.username,
    color: "",
    commentType: commentToAdd.commentType || null,
    endLocation: commentToAdd.endLocation,
    startLocation: commentToAdd.startLocation,
    modificationVersion: commentToAdd.historyNumber, //TODO: ist das korrekt?
    replyToKey: "",
    sortOrder: 0,
    state: null,
    actions: [],
    persistanceAction: "ADD" as const,
    persistanceState: "LOCAL" as const,
    __typename: "TextComment" as const,
  }

  const result = {
    comment: {
      result: newComment,
      __typename: "CommentSuccess" as const,
    },
    __typename: "Mutation" as const,
  }
  return result
}

/**
 * Returns the mutation which is used to create the new inline comment.
 * Additionaly to executing the result, an optimistic response is saved in the apollo cache.
 *
 * @returns Returns an array
 * The first entry is the funciton wich executes the addInlineCommentMutation
 * The second entry is the addInlineCommentMutation result
 */
export function useAddCommentAndSaveLocal(): [
  (input: AddInlineCommentInput) => ReturnType<AddInlineCommentToNodeMutationHookResult[0]>,
  AddInlineCommentToNodeMutationResult,
] {
  const { t } = useTranslation()
  const [mutation, result] = useAddInlineCommentToNodeMutation()
  const patchedMutation = (input: AddInlineCommentInput) => {
    const optimisticResult = createOptimisticResult(input)

    return mutation({
      variables: {
        input,
      },
      optimisticResponse: optimisticResult,
      update: (cache, { data }) => {
        if (!data) return
        const contentCacheId = cache.identify({ __typename: "TextContent", id: input.nodeId })
        const contentFromCache = cache.readFragment<TextContentForCommentFragment>({
          id: contentCacheId,
          fragment: TextContentForCommentFragmentDoc,
          fragmentName: "TextContentForComment",
        })
        if (!contentFromCache) return

        if (isTypename("Error")(data.comment)) {
          notification["error"]({
            key: data.comment.message,
            message: t(
              IsPermissionDeniedError(data.comment.message)
                ? keys.message.error.commentLegacy.errorRights
                : keys.message.error.generic,
            ),
            style: { backgroundColor: "#FFDDDD" },
            duration: 20,
          })

          console.error("An error occurred while saving the comment!", data.comment.message)
          const erroneousComment = {
            ...optimisticResult.comment.result,
            persistanceState: "ERROR" as const,
            persistanceAction: "ADD" as const,
          }
          const erroneousCommentNode = getNewCommentNode(input.aspectSelectorId, contentFromCache, erroneousComment)
          InsertNewCommentNodeIntoContent(cache, erroneousCommentNode, contentFromCache)
          return
        }
        const newCommentNode = getNewCommentNode(input.aspectSelectorId, contentFromCache, data.comment.result)
        InsertNewCommentNodeIntoContent(cache, newCommentNode, contentFromCache)
      },
    })
  }
  return [patchedMutation, result]
}

function IsPermissionDeniedError(errorMessage: string) {
  return errorMessage.startsWith("Permission denied")
}
