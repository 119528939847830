import * as React from "react"
import { SVGProps } from "react"

const SvgSt4ComponentLight = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1em" height="1em" {...props}>
    <path d="M63 64.3C63 28.3 91.3 0 127.4 0h156.9c12.9 0 25.7 5.1 33.4 12.9l115.8 115.8c10.3 10.3 15.4 20.6 15.4 33.4v285.6c0 36-28.3 64.3-64.3 64.3H127.4c-36 0-64.3-28.3-64.3-64.3V64.3zM415.5 193H302.3c-25.7 0-48.9-20.6-48.9-48.9V30.9h-126c-18 0-30.9 15.4-30.9 30.9v385.9c0 18 15.4 30.9 30.9 30.9h257.3c18 0 30.9-15.4 30.9-30.9V193zm-2.6-38.6L297.2 38.6c-2.6-2.6-5.1-2.6-7.7-5.1v113.2c0 7.7 7.7 15.4 15.4 15.4h113.2c-2.6-5.2-5.2-7.7-5.2-7.7zm-28.3 126c2.6 5.1 0 10.3-2.6 12.9l-23.2 20.6v25.8l23.2 20.6c2.6 2.6 5.1 7.7 2.6 12.9-2.6 5.1-5.1 12.9-7.7 18l-2.6 5.1c-2.6 5.1-7.7 10.3-12.9 15.4-2.6 2.6-7.7 5.1-12.9 2.6L317.6 404c-7.7 5.1-15.4 10.3-23.2 12.9l-7.7 30.9c0 5.1-5.1 7.7-10.3 10.3-5.1 5.1-12.9 5.1-20.6 5.1s-15.4 0-23.2-2.6c-5.1 0-7.7-5.1-10.3-10.3l-7.7-30.9c-7.7-2.6-15.4-7.7-23.2-12.9l-30.9 10.3c-5.1 2.6-10.3 0-12.9-2.6-5.1-5.1-7.7-10.3-12.9-15.4l-2.6-5.1c0-7.7-2.6-12.9-5.1-18-2.6-5.1 0-10.3 2.6-12.9l23.2-20.6v-25.8l-23.2-20.6c-2.6-7.7-2.6-12.9-2.6-15.4 2.6-5.1 5.1-12.9 7.7-18l5.1-5.1c2.6-5.1 7.7-10.3 12.9-15.4 0-5.1 5.1-7.7 10.3-5.1l30.9 10.3c7.7-7.7 15.4-10.3 23.2-15.4l7.7-30.9c0-5.1 5.1-7.7 10.3-10.3 7.7 0 15.4-2.6 23.2-2.6 7.7 0 15.4 0 23.2 2.6 5.1 0 7.7 5.1 10.3 10.3l2.6 30.9c7.7 2.6 15.4 7.7 23.2 12.9l30.9-10.3c5.1-2.6 10.3 0 12.9 2.6 5.1 5.1 7.7 10.3 12.9 15.4l2.6 5.1c2.6 10.2 5.2 15.3 7.7 23zM256 370.5c23.2 0 43.7-18 43.7-43.7s-18-43.7-43.7-43.7c-23.2 0-43.7 18-43.7 43.7s20.5 43.7 43.7 43.7z" />
  </svg>
)

export default SvgSt4ComponentLight
