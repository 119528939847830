import * as React from "react"
import { SVGProps } from "react"

const SvgIndent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="1em" height="1em" {...props}>
    <path d="M0 64c0-8.8 7.2-16 16-16h416c8.8 0 16 7.2 16 16s-7.2 16-16 16H16C7.2 80 0 72.8 0 64zm192 128c0-8.8 7.2-16 16-16h224c8.8 0 16 7.2 16 16s-7.2 16-16 16H208c-8.8 0-16-7.2-16-16zm16 112h224c8.8 0 16 7.2 16 16s-7.2 16-16 16H208c-8.8 0-16-7.2-16-16s7.2-16 16-16zM0 448c0-8.8 7.2-16 16-16h416c8.8 0 16 7.2 16 16s-7.2 16-16 16H16c-8.8 0-16-7.2-16-16zm32-236.2v88.4L85.7 256 32 211.8zm89 31.8c7.8 6.4 7.8 18.3 0 24.7l-94.8 78.1C15.7 355 0 347.6 0 334.1V177.9c0-13.5 15.7-20.9 26.2-12.4l94.8 78.1z" />
  </svg>
)

export default SvgIndent
