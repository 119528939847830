import * as React from "react"
import { SVGProps } from "react"

const SvgSt4FileQuestionRegular = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1em" height="1em" {...props}>
    <path d="m429.3 109.2-90.6-90.5C326.7 6.7 310.5 0 293.5 0H128C92.7 0 64 28.7 64 64v384c0 35.3 28.7 64 64 64h256c35.3 0 64-28.7 64-64V154.5c0-17-6.7-33.3-18.7-45.3zM400 448c0 8.8-7.2 16-16 16H128c-8.8 0-16-7.2-16-16V64c0-8.8 7.2-16 16-16h160v80c0 17.7 14.3 32 32 32h80v288zM202.5 256.3c0-20.3 16.5-36.7 36.7-36.7h18.4c20.3 0 36.7 16.5 36.7 36.7v2.1c0 12.5-6.4 24.2-16.9 30.9l-24.2 15.6c-14.5 9.3-23.2 25.3-23.2 42.5v.9c0 10.2 8.2 18.4 18.4 18.4s18.4-8.2 18.4-18.4v-.8c0-4.7 2.4-9.1 6.3-11.6l24.2-15.6c21-13.6 33.8-36.8 33.8-61.8v-2.1c0-40.6-32.9-73.5-73.5-73.5h-18.4c-40.6 0-73.5 32.9-73.5 73.5 0 10.2 8.2 18.4 18.4 18.4 10.2-.1 18.4-8.4 18.4-18.5zm46 183.7c12.7 0 23-10.3 23-23s-10.3-23-23-23-23 10.3-23 23 10.3 23 23 23z" />
  </svg>
)

export default SvgSt4FileQuestionRegular
