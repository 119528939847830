import { useCallback, useMemo } from "react"
import { IndentedTreeNode, TreeState } from "../types"

export function useNodeParentMap(treeState: TreeState) {
  const nodeParentMap = useMemo(
    () =>
      new Map(
        treeState.treeNodeOrder.map((nodeId) => {
          const parentNodes = new Set<IndentedTreeNode["node"]["id"]>()
          let parentId = treeState.findParent(nodeId)
          while (parentId != null) {
            parentNodes.add(parentId)
            parentId = treeState.findParent(parentId)
          }
          return [nodeId, parentNodes] as const
        }),
      ),
    [treeState],
  )
  return nodeParentMap
}

export function useNodeDescendantTester(treeState: TreeState) {
  const nodeParentMap = useNodeParentMap(treeState)
  const tester = useCallback(
    (parentId, possibleDescendantId) => {
      return nodeParentMap.get(possibleDescendantId)?.has(parentId) ?? false
    },
    [nodeParentMap],
  )
  return tester
}
