import classNames from "classnames"
import React, { Component } from "react"
import ReactDOM from "react-dom"
import styled from "styled-components"
import { ripple, highlight, ThemeProvider } from "@st4/theme-manager"
import PropTypes from "prop-types"

const actionSheetWidth = 300
const actionSheetMargin = 20
const actionSheetMaxHeight = 450
const computeActionSheetMaxHeight = function (props) {
  var bodyRect = document.body.getBoundingClientRect()
  if (!props.top && props.bottom) {
    return Math.min(actionSheetMaxHeight, bodyRect.height - props.bottom - 5)
  }
  return Math.min(actionSheetMaxHeight, bodyRect.height - props.top - 5)
}

let ActionSheet_deprecated

function parentHasClass(el, name) {
  var result = false
  while (el instanceof Node && el.parentElement) {
    result = el.parentElement.classList.contains(name)
    if (result) break
    else el = el.parentElement
  }

  return result
}

function closeActionSheet(ev) {
  if (ActionSheet_deprecated._sheetContainer && !parentHasClass(ev.target, "ActionSheet")) {
    ActionSheet_deprecated.close()
  }
}

class _ActionSheetOption extends Component {
  handleClick = (ev) => {
    if (this.props.onAction) {
      this.props.onAction(ev, this.props.action)
    }
    if (this.props.onClick) {
      this.props.onClick(ev)
    }
  }
  render() {
    return (
      <div className={classNames(this.props.className, "ActionSheetOption")} onClick={this.handleClick}>
        {this.props.action.icon ? this.props.action.icon : null}
        {this.props.action.displayName || this.props.action.name}
      </div>
    )
  }
}

// prettier-ignore
const ActionSheetOption = styled(_ActionSheetOption)`
  ${ripple}
  ${highlight}
  padding: 12px;
  border-top: ${(props) => props.action.startNewGroup ? "1px solid " + props.theme.separatorColor : "0"};
  & .ActionSheetOption--img {
    vertical-align: middle;
    margin-right: 0.5em;
  }
`

class BasicActionSheet extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hidden: true,
      pos: {},
    }
  }

  componentDidMount() {
    document.addEventListener("click", closeActionSheet, true)
    window.addEventListener("resize", closeActionSheet, true)
    setTimeout(() => {
      this.setState((state) => ({ hidden: false }))
    }, 0)
  }
  componentWillUnmount() {
    document.removeEventListener("click", closeActionSheet)
    window.removeEventListener("resize", closeActionSheet)
  }
  handleActionClick = (ev, action) => {
    this.props.onAction(action)
    setTimeout(() => {
      this.setState((state) => ({ hidden: true }))
      setTimeout(() => {
        ActionSheet_deprecated.close()
      }, 100)
    }, 250)
  }
  render() {
    let props = this.props
    var opts = props.options.map((o, ix) => ({
      ...o,
      index: ix,
    }))
    return (
      <div className={`ActionSheet ${props.className} ${this.state.hidden ? "is-hidden" : ""}`}>
        {opts.map((o) => (
          <ActionSheetOption
            className="ActionSheet--ActionSheetOption"
            key={o.index}
            action={o}
            onAction={this.handleActionClick}
          />
        ))}
      </div>
    )
  }
}

// prettier-ignore
ActionSheet_deprecated = styled(BasicActionSheet)`
  cursor: pointer;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  
  background: white;
  position: fixed;
  width: ${actionSheetWidth}px;    
  left: ${props => props.x}px;
  ${props => props.top ? `top: ${props.top}px;` : ""}
  ${props => props.bottom ? `bottom: ${props.bottom}px;` : ""}
  transition: all 0.2s cubic-bezier(0.5,-0.33, 0.51, 1.4);
  transform: scaleY(1.0);
  height: auto;
  overflow: auto;
  max-height: ${props => computeActionSheetMaxHeight(props)}px;
  opacity: 1.0;
  z-index: 1;
  font-size: ${({theme})=> theme.token.fontSize}px;
  &.is-hidden {
    transform: scaleY(0.0);
    opacity: 0;
  }
`

ActionSheet_deprecated.show = function show(props, onAction, context) {
  let ctx = context || document.body
  let sheetContainer = ensureSheetContainer(ctx)
  let pos = props.contextElement ? computePosition(props.contextElement) : null
  if (pos) {
    ReactDOM.render(
      <ThemeProvider>
        <ActionSheet_deprecated {...props} x={pos.x} top={pos.top} bottom={pos.bottom} onAction={onAction} />
      </ThemeProvider>,
      sheetContainer,
    )
  } else {
    ReactDOM.render(
      <ThemeProvider>
        <ActionSheet_deprecated {...props} onAction={onAction} />
      </ThemeProvider>,
      sheetContainer,
    )
  }
  ActionSheet_deprecated._sheetContainer = sheetContainer
}

function computePosition(el) {
  let bodyRect = document.body.getBoundingClientRect()
  let { top, left, right } = el.getBoundingClientRect()
  let bottom = null
  let x = left + (right - left) / 2 + actionSheetMargin
  if (x + actionSheetWidth > bodyRect.width) {
    x = bodyRect.width - actionSheetWidth - actionSheetMargin
  }
  if (x < 0) {
    x = actionSheetMargin
  }
  top += actionSheetMargin
  if (top > bodyRect.height / 2) {
    bottom = bodyRect.height - top + actionSheetMargin
    top = null
  }
  return { x, top, bottom }
}

ActionSheet_deprecated.close = function close() {
  if (ActionSheet_deprecated._sheetContainer) {
    ReactDOM.unmountComponentAtNode(ActionSheet_deprecated._sheetContainer)
    ActionSheet_deprecated._sheetContainer.parentElement.removeChild(ActionSheet_deprecated._sheetContainer)
    ActionSheet_deprecated._sheetContainer = null
  }
}

function ensureSheetContainer(ctx) {
  let container = ctx.querySelector("div#actionSheetContainer")
  if (!container) {
    let el = document.createElement("div")
    el.id = "actionSheetContainer"
    let scripts = ctx.querySelectorAll("script")
    let firstScript = scripts ? scripts[0] : null
    let insertionPoint = firstScript || (document && document.body ? document.body.lastElementChild : null)
    if (insertionPoint) {
      ctx.insertBefore(el, insertionPoint)
    } else {
      ctx.appendChild(el)
    }
    return el
  } else {
    return container
  }
}

ActionSheet_deprecated.propTypes = {
  className: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      displayName: PropTypes.string,
      name: PropTypes.string,
      index: PropTypes.number,
      icon: PropTypes.any,
    }),
  ),
  onAction: PropTypes.func,
  contextElement: PropTypes.any,
}

export default ActionSheet_deprecated
