import React from "react"
import { MenuProps, theme } from "antd"
import styled from "styled-components"
import { Link } from "react-router-dom"
import { Icon, Quanos, Regular } from "@st4/icons"
import { DropdownMenuWithTitle } from "~/ui/dropdownMenuWithTitle"
import { Toolbar } from "~/ui/toolbar"
import { HelpAction } from "./components/HelpAction"
import { SearchArea } from "./components/SearchArea"
import { isLoggedIn, useAuthentication } from "@st4/authentication"
import { Translate, keys } from "@st4/i18n"

const StyledHeadbar = styled.div`
  color: ${({ theme: { token } }) => token.colorText};
  height: 42px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${({ theme: { token } }) => token.colorBgContainer};
  padding-left: 12px;
  padding-right: 20px;
  border-bottom: 1px solid ${({ theme: { token } }) => token.colorBorder};
`

export function Headbar() {
  const { token } = theme.useToken()
  return (
    <StyledHeadbar>
      <Link to="/" style={{ paddingTop: "5px" }}>
        <Quanos style={{ height: "32px", width: "165px", color: token.colorPrimary }} />
      </Link>
      <HeadbarActions />
    </StyledHeadbar>
  )
}

const ActionBar = styled(Toolbar)`
  width: 100%;
  display: flex;
  justify-content: end;
`

function HeadbarActions() {
  const [state, { logout }] = useAuthentication()
  const loggedIn = isLoggedIn(state)
  const { token } = theme.useToken()

  const accountName =
    loggedIn && (state.sessionData.displayname ?? state.sessionData.loginName ?? state.sessionData.username)

  const items: MenuProps["items"] = [
    {
      key: "settings",
      label: (
        <Link to={"/settings"}>
          <Translate>{keys.label.general.settings}</Translate>
        </Link>
      ),
      icon: <Icon component={Regular.Gear} color={token.colorText} aria-label="settings" />,
    },
    {
      key: "logout",
      label: <Translate>{keys.button.auth.logout}</Translate>,
      icon: <Icon component={Regular.ArrowRightFromBracket} color={token.colorText} />,
      onClick: logout,
    },
  ]

  return (
    <ActionBar gutter={16}>
      <SearchArea />
      <DropdownMenuWithTitle
        menu={{ items }}
        arrow={{ pointAtCenter: true }}
        title={
          <Translate
            userName={
              loggedIn && (state.sessionData.displayname ?? state.sessionData.loginName ?? state.sessionData.username)
            }
          >
            {keys.label.account.flyoutTitle}
          </Translate>
        }
      >
        <Icon component={Regular.CircleUser} style={{ fontSize: token.fontSizeXL }} />
      </DropdownMenuWithTitle>
      <HelpAction />
    </ActionBar>
  )
}
