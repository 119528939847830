import React, { useContext } from "react"

type NavigationTarget =
  | {
      type: "hyperlink"
      url: string
    }
  | { type: "node"; nodeId: string; aspectId: string }

export type NavigationContextData = (target: NavigationTarget) => void

const navigationContext = React.createContext<NavigationContextData>(() => {})

export function NavigationContextProvider(props: React.PropsWithChildren<{ onNavigate: NavigationContextData }>) {
  const { Provider } = navigationContext
  return <Provider value={props.onNavigate}>{props.children}</Provider>
}

export function useNavigationContext() {
  const onNavigate = useContext(navigationContext)
  return { onNavigate }
}
