import { SearchOptions, SearchVersion } from "../types"

const WEBAUTHOR_SEARCH_SEARCHOPTIONS = "WebAuthor_Search_SearchOptions"

export function getDefaultSearchOptions(userLanguageId?: any) {
  const neutralLanguageId = "x-aspect-selector:132870"
  return {
    searchKeys: ["Title", "Content"],
    searchLanguages: userLanguageId ? [userLanguageId, neutralLanguageId] : [neutralLanguageId],
    searchVersion: "latest" as SearchVersion,
  }
}

export function loadSearchOptions(userLanguageId?: any) {
  const searchOptions = sessionStorage.getItem(WEBAUTHOR_SEARCH_SEARCHOPTIONS)
  return searchOptions ? (JSON.parse(searchOptions) as SearchOptions) : getDefaultSearchOptions(userLanguageId)
}

export function saveSearchOptions(searchOptions: SearchOptions) {
  const jsonString = JSON.stringify(searchOptions)
  sessionStorage.setItem(WEBAUTHOR_SEARCH_SEARCHOPTIONS, jsonString)
  dispatchEvent(new Event("onSearchOptionsChanged"))
}
