import type { ComponentType } from "react"

const PROPERTY_EDITORS: Map<string, ComponentType<any>> = new Map()

export function overridePropertyEditor(name: string, component: ComponentType<any>) {
  PROPERTY_EDITORS.set(name, component)
}

export function registerPropertyEditor(name: string, component: ComponentType<any>) {
  if (PROPERTY_EDITORS.has(name)) throw `Error: Trying to override standard editor ${name}`
  else return PROPERTY_EDITORS.set(name, component)
}

export function getPropertyEditor(name: string): ComponentType<any> | undefined {
  return PROPERTY_EDITORS.get(name)
}
