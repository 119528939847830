import React, { useCallback, useMemo, useState } from "react"
import styled from "styled-components"
import { TreeViewItemsProps } from "../base"
import { TreeViewNode } from "./treeviewnode"
import { IndentedTreeNode } from "../types"
import { useNodeDescendantTester } from "../base/useNodeParentMap"

export function TreeViewItems({ nodes, treeState, onClick, meatballMenu, createButton }: TreeViewItemsProps) {
  const { changeIsExpanded, onCheckChange } = treeState
  const [hoveredNode, setHoveredNode] = useState<IndentedTreeNode["node"]["id"] | null>(null)
  const nodeDescendantTester = useNodeDescendantTester(treeState)

  function isParentHovered(indentedTreeNode: IndentedTreeNode) {
    return (!!hoveredNode && nodeDescendantTester(hoveredNode, indentedTreeNode.node.id)) ?? false
  }

  return (
    <StyledTreeView>
      {nodes.map((indentedTreeNode) => {
        return (
          <TreeViewNode
            key={indentedTreeNode.node.id}
            item={indentedTreeNode}
            isSelected={indentedTreeNode.node.id === treeState.selected}
            onClick={onClick}
            onHoverChange={(hovered) => {
              hovered ? setHoveredNode(indentedTreeNode.node.id) : setHoveredNode(null)
            }}
            isParentHovered={isParentHovered(indentedTreeNode)}
            isExpanded={treeState.isExpanded(indentedTreeNode.node.id)}
            onExpandChange={changeIsExpanded}
            checkable={!!treeState.checked}
            isChecked={treeState.checked?.includes(indentedTreeNode.node.id) ?? false}
            onCheckChange={onCheckChange}
            meatballMenu={meatballMenu}
            createButton={createButton}
          />
        )
      })}
    </StyledTreeView>
  )
}

const StyledTreeView = styled.div`
  position: relative;
`
