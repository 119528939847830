function _arrayLikeToArray(arr, len) {
    if (len == null || len > arr.length) len = arr.length;
    for(var i = 0, arr2 = new Array(len); i < len; i++)arr2[i] = arr[i];
    return arr2;
}
function _arrayWithHoles(arr) {
    if (Array.isArray(arr)) return arr;
}
function _iterableToArrayLimit(arr, i) {
    var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"];
    if (_i == null) return;
    var _arr = [];
    var _n = true;
    var _d = false;
    var _s, _e;
    try {
        for(_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true){
            _arr.push(_s.value);
            if (i && _arr.length === i) break;
        }
    } catch (err) {
        _d = true;
        _e = err;
    } finally{
        try {
            if (!_n && _i["return"] != null) _i["return"]();
        } finally{
            if (_d) throw _e;
        }
    }
    return _arr;
}
function _nonIterableRest() {
    throw new TypeError("Invalid attempt to destructure non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _slicedToArray(arr, i) {
    return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest();
}
function _unsupportedIterableToArray(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _arrayLikeToArray(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(n);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}
import React, { useState, useRef, useEffect } from "react";
export var useEditorHeight = function(iframeRef, initialHeight) {
    var ref = _slicedToArray(useState(initialHeight), 2), height = ref[0], setHeight = ref[1];
    var intervalRef = useRef();
    useEffect(function() {
        var setHeightFromContent = function setHeightFromContent() {
            if (iframeRef.current) {
                var ref, ref1;
                var endEl = (ref = iframeRef.current.contentWindow) === null || ref === void 0 ? void 0 : (ref1 = ref.document) === null || ref1 === void 0 ? void 0 : ref1.querySelector("documents-list + fds-block, \n          fatal-error,\n          div.index-error-container:not(.invisible) > div > div > div.index-error-content");
                if (endEl) {
                    var size = endEl.offsetTop + endEl.offsetHeight + 50;
                    setHeight(size);
                }
            }
        };
        setHeightFromContent();
        intervalRef.current = setInterval(function() {
            setHeightFromContent();
        }, 200);
        return function() {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    });
    useEffect(function() {
        if (iframeRef.current && iframeRef.current.contentWindow) {
            var ref, ref1;
            var iframe = iframeRef.current;
            var scrollContainer = iframeRef.current.closest(".NodesContainer");
            if (scrollContainer) {
                var refreshToolbarTop = function() {
                    var ref, ref1;
                    var toolbarTop = scrollContainer.getBoundingClientRect().top - iframe.getBoundingClientRect().top - 2;
                    (ref1 = (ref = iframe.contentWindow) === null || ref === void 0 ? void 0 : ref.document.documentElement) === null || ref1 === void 0 ? void 0 : ref1.style.setProperty("--toolbar-top", "".concat(toolbarTop > 0 ? toolbarTop : 0, "px"));
                };
                scrollContainer.addEventListener("scroll", refreshToolbarTop);
                return function() {
                    return scrollContainer.removeEventListener("scroll", refreshToolbarTop);
                };
            }
            (ref1 = (ref = iframe.contentWindow) === null || ref === void 0 ? void 0 : ref.document.documentElement) === null || ref1 === void 0 ? void 0 : ref1.style.setProperty("--toolbar-top", "0px");
        }
    });
    return height;
};
