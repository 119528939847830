import { TooltipProps } from "antd"
import React, { useRef } from "react"
import styled from "styled-components"
import { Tooltip } from "../tooltip"
import { useIsHorizontalOverflow } from "./useIsHorizontalOverflow"

export const OverflowHidingSpan = styled.span`
  display: inline-block;
  white-space: nowrap;
  vertical-align: bottom;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
`

export function OverflowHidingTooltipShowingSpan(props: TooltipProps) {
  const spanRef = useRef<HTMLSpanElement>(null)
  const isOverflow = useIsHorizontalOverflow(spanRef)

  if (isOverflow) {
    return (
      <Tooltip {...props}>
        <OverflowHidingSpan ref={spanRef}>{props.children}</OverflowHidingSpan>
      </Tooltip>
    )
  }

  return <OverflowHidingSpan ref={spanRef}>{props.children}</OverflowHidingSpan>
}
