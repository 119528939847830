import React from "react"
import { MessageHub, NotificationProvider } from "@st4/message-hub"
import { Blade, BladeSize } from "@st4/ui"
import { GenericBladeDefinition, BladeData } from "../../definition/blade"
import { TransitionOptions } from "../../definition/transitionOptions"
import { TransitionInfo } from "../../taskcontext/Screen"
import { TransitionActionbar } from "../Actionbar"
import { BladeBanner, useBladeBannerState } from "./BladeBanner"

type TaskBladeProps = {
  name: string
  componentName: string
  messageHub: MessageHub
  transitionInfo?: TransitionInfo<Extract<TransitionOptions, { type: "blade" }>>
  bladeProps: {
    readonly size: BladeSize
  } & (
    | {
        readonly temporary: true
      }
    | {
        readonly fixed: true
      }
    | {
        readonly collapsed: boolean
      }
  )
  bladeDefinition: GenericBladeDefinition
  projectedProps: BladeData
}

function isCollabsibleProps(
  props: TaskBladeProps["bladeProps"],
): props is Extract<TaskBladeProps["bladeProps"], { collapsed: boolean }> {
  return Object.hasOwn(props, "collapsed")
}

/**
 * Renders the blade component with additional functionality for tasks.
 * @param props
 * @returns
 */
export const TaskBlade = function TaskBlade(props: TaskBladeProps) {
  const {
    name,
    componentName,
    messageHub,
    projectedProps,
    bladeDefinition: Component,
    transitionInfo,
    bladeProps,
  } = props
  const [bannerState, bannerFunctions] = useBladeBannerState()

  const isCollapsed = isCollabsibleProps(bladeProps) && bladeProps.collapsed

  return (
    <NotificationProvider showConfirm={bannerFunctions.showConfirm} showNotification={bannerFunctions.showNotification}>
      <Blade
        key={name}
        {...bladeProps}
        title={Component.title}
        data-blade={componentName}
        data-blade-name={name}
        onCollapse={() => messageHub.sendMessage({ action: "collapseChange", payload: !isCollapsed })}
        onClose={() => messageHub.sendMessage({ action: "closeTemporaryBlade", payload: true })}
      >
        <Component key={name} {...projectedProps} messageHub={messageHub} sendMessage={messageHub.sendMessage} />
        <BladeBanner state={bannerState} />
        {bannerState?.type !== "confirm" && transitionInfo && transitionInfo.transitions.length ? (
          <TransitionActionbar transitionInfo={transitionInfo} />
        ) : null}
      </Blade>
    </NotificationProvider>
  )
}
