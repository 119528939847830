import React from "react"
import styled, { css } from "styled-components"
import { Box, BoxProps } from "./structure"
import { isString } from "../../utilities"
import { TextBlock } from "./textblock"
import type { Node } from "unist"

const getListStyleAttr = (cssListStyle?: ListstyleTypes, xmlListType?: XmlListStyleType): ListstyleTypes => {
  const defaultStyle = "circle"
  if (cssListStyle) return cssListStyle

  if (xmlListType) {
    switch (xmlListType) {
      case "dash":
        return { placeholder: "\u2013" }
      case "disc":
        return "disc"
      case "i":
        return "lower-roman"
      case "I":
        return "upper-roman"
      case "A":
        return "upper-latin"
      case "1":
        return "decimal"
      default:
        break
    }
  }
  return defaultStyle
}

type XmlListStyleType = "dash" | "disc" | "i" | "I" | "A" | "1"
type CssListStyleType = "disc" | "circle" | "lower-roman" | "upper-roman" | "upper-latin" | "lower-latin" | "decimal"
export type ListstyleTypes = CssListStyleType | { placeholder: string }

const ListItemContainer = styled.div<{ listStyle?: string }>`
  && ${TextBlock} {
    padding: 0;
    margin: 0;
  }
  &:before {
    width: 1em;
    margin-left: -4ex;
    margin-right: 0.8ex;
    content: counter(listCounter, disc); //styling für Listenelemente ohne umschließende Liste (Strukturfragment).
    vertical-align: top;
  }
  &.hidenumbers:before {
    content: none;
  }
  &.shownumbers {
    counter-increment: listCounter;
  }
  padding-left: 3ex;
`
const ListItemContent = styled.div`
  display: inline-block;
  width: 100%;
`

export function ListItem(props: React.PropsWithChildren<{ listStyle?: string; ast?: Node }>) {
  const { ast, children, ...rest } = props
  // ListItems which contain a List as first child are "sublists" and should not show listcounters.
  const childrenWithoutWhitespace = ast?.children?.filter((a) => a.nodeType !== "Whitespace") || []
  const isSublist = childrenWithoutWhitespace.length && childrenWithoutWhitespace[0].tagName === "ul"

  return (
    <ListItemContainer className={isSublist ? "hidenumbers" : "shownumbers"} {...rest}>
      <ListItemContent>{children}</ListItemContent>
    </ListItemContainer>
  )
}

const ListElement = styled.div<{ listStyle: ListstyleTypes }>`
  counter-reset: listCounter 0;
  && ${ListItemContainer}.shownumbers:before {
    ${({ listStyle }) =>
      !listStyle || isString(listStyle)
        ? css`
            content: counter(listCounter, ${listStyle}) ${listStyle === "decimal" && '"."'}
              ${listStyle.endsWith("latin") && '")"'}; // styling für umschlossene Listenelemente.
            vertical-align: top;
          `
        : css`
            content: "${listStyle.placeholder}";
            vertical-align: top;
          `}
  }
  && ${Box} {
    margin-top: 0;
    margin-bottom: 0;
  }
`

export const List = (
  props: React.PropsWithChildren<{ ast?: Node; listStyle: ListstyleTypes; component?: unknown } & BoxProps>,
) => {
  const { children, listStyle, ast, component, as, ref, ...boxProps } = props
  return (
    <Box ast={ast} {...boxProps}>
      <ListElement
        listStyle={getListStyleAttr(listStyle, ast?.attributes && (ast?.attributes["type"]?.value as XmlListStyleType))}
      >
        {children}
      </ListElement>
    </Box>
  )
}
