import * as React from "react"
import { SVGProps } from "react"

const SvgAlignJustify = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="1em" height="1em" {...props}>
    <path d="M16 48C7.2 48 0 55.2 0 64s7.2 16 16 16h416c8.8 0 16-7.2 16-16s-7.2-16-16-16H16zM0 192c0 8.8 7.2 16 16 16h416c8.8 0 16-7.2 16-16s-7.2-16-16-16H16c-8.8 0-16 7.2-16 16zm16 112c-8.8 0-16 7.2-16 16s7.2 16 16 16h416c8.8 0 16-7.2 16-16s-7.2-16-16-16H16zm0 128c-8.8 0-16 7.2-16 16s7.2 16 16 16h416c8.8 0 16-7.2 16-16s-7.2-16-16-16H16z" />
  </svg>
)

export default SvgAlignJustify
