import * as React from "react"
import { SVGProps } from "react"

const SvgBook = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="1em" height="1em" {...props}>
    <path d="M64 0C28.7 0 0 28.7 0 64v384c0 35.3 28.7 64 64 64h368c8.8 0 16-7.2 16-16s-7.2-16-16-16h-16v-66.7c18.6-6.6 32-24.4 32-45.3V48c0-26.5-21.5-48-48-48H64zm320 416v64H64c-17.7 0-32-14.3-32-32s14.3-32 32-32h320zM64 384c-11.7 0-22.6 3.1-32 8.6V64c0-17.7 14.3-32 32-32h32v352H64zm64 0V32h272c8.8 0 16 7.2 16 16v320c0 8.8-7.2 16-16 16H128zm48-240c0 8.8 7.2 16 16 16h160c8.8 0 16-7.2 16-16s-7.2-16-16-16H192c-8.8 0-16 7.2-16 16zm0 96c0 8.8 7.2 16 16 16h160c8.8 0 16-7.2 16-16s-7.2-16-16-16H192c-8.8 0-16 7.2-16 16z" />
  </svg>
)

export default SvgBook
