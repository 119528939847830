import { makeVar } from "@apollo/client"
import { log } from "./log"
import type { TaskContext } from "@st4/graphql"
import { DependencyList, useCallback, useEffect } from "react"

export type StateStoreHandler = (
  correlationId: string,
  taskId: TaskContext["id"],
  options: { signal: AbortSignal },
) => Promise<void>

export type StateStoreProcess = {
  running: boolean
  /** Handlers to subscribe to the store process. These will execute in parallel and storing will finish if all handlers resolved successfully */
  handlers: Set<StateStoreHandler>
}

export const reactiveStateStore = makeVar<StateStoreProcess>({
  running: false,
  handlers: new Set<StateStoreHandler>(),
})

export function registerHandler(handleCallback: StateStoreHandler) {
  log("reactive var", "Register handler", handleCallback)
  const storeProcess = reactiveStateStore()
  reactiveStateStore({ ...storeProcess, handlers: storeProcess.handlers.add(handleCallback) })
}

export function removeHandler(handleCallback: StateStoreHandler) {
  log("reactive var", "Remove handler", handleCallback)
  const storeProcess = reactiveStateStore()
  storeProcess.handlers.delete(handleCallback)
  reactiveStateStore(storeProcess)
}

export function setRunning() {
  const storeProcess = reactiveStateStore()
  reactiveStateStore({ ...storeProcess, running: true })
}

export function setDone() {
  const storeProcess = reactiveStateStore()
  reactiveStateStore({ ...storeProcess, running: false })
}

/**
 * Register a handler to be executed during the task state saving process.
 * @param handler This handler will be executed and awaited
 */
export function useStateStoreHandler(handler: StateStoreHandler, deps: DependencyList) {
  // eslint-disable-next-line react-hooks/exhaustive-deps -- we want to cache the handler by ourselfs. The deps are passed to this hook by the caller.
  const handleCallback = useCallback(handler, deps)
  useEffect(() => {
    registerHandler(handleCallback)
    return () => {
      removeHandler(handleCallback)
    }
  }, [handleCallback])
}
