import React from "react"
import { IndentedTreeNode } from "../types"
import { LoadingOutlined, CaretRightOutlined, CaretDownOutlined } from "@ant-design/icons"
import { Spin } from "antd"
import { Icon, Regular } from "@st4/icons"

export type NodeExpandToggleProps = {
  item: IndentedTreeNode
  onExpandChange?: (id: string, newIsExpanded: boolean) => void
  onHover?: (isHovered: boolean) => void
  isExpanded: boolean
  loading?: boolean
}

export function NodeExpandToggle(props: NodeExpandToggleProps) {
  return props.item.hasChildren ? (
    <span
      onClick={() => {
        props.onExpandChange && props.onExpandChange(props.item.node.id, !props.isExpanded)
      }}
      onPointerOver={() => {
        props.onHover?.(true)
      }}
      onPointerLeave={() => {
        props.onHover?.(false)
      }}
    >
      {props.isExpanded ? <CaretDownOutlined style={toggleStyle} /> : <CaretRightOutlined style={toggleStyle} />}
    </span>
  ) : (
    <span>
      {props.loading ? (
        <Spin size="small" delay={500} indicator={<Icon component={Regular.SpinnerThird} />} />
      ) : (
        <CaretRightOutlined style={{ ...toggleStyle, visibility: "hidden" }} />
      )}
    </span>
  )
}

const toggleStyle: React.CSSProperties = {
  width: "12px",
  height: "12px",
  marginLeft: 10,
  marginRight: 5,
  cursor: "pointer",
  verticalAlign: "middle",
  userSelect: "none",
}
