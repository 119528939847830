import {
  CreateTaskMutation,
  SaveTaskStateMutation,
  useCreateTaskMutation as useOriginalCreateTaskMutation,
  useSaveTaskStateMutation as useOriginalSaveTaskStateMutation,
  useCloseTaskMutation as useOriginalCloseTaskMutation,
  CloseTaskMutation,
  RunningTasksDocument,
  useRunningTasksQuery as useOriginalRunningTasksQuery,
  TaskContextFragmentDoc,
  TaskContextFragment,
} from "./query.hooks"
import { getTaskDefinition } from "../taskRegistry"
import {
  closeSingletonTask,
  getOrCreateSingletonTask,
  loadSingletonTaskState,
  useRunningSingletonTasks,
  saveSingletonTaskState,
} from "./singletontasks"
import { FetchResult } from "@apollo/client"
import { ExtractByTypeName } from "@st4/graphql"
import { useCallback } from "react"

export function useCreateTaskWithStateMutation() {
  const [createTaskMutation] = useCreateTaskMutation()
  const [saveTaskMutation] = useSaveTaskStateMutation()

  async function createTaskWithState(data: string, ...parameters: Parameters<typeof useOriginalCreateTaskMutation>) {
    const response = await createTaskMutation(...parameters)
    if (response?.data?.createTask.__typename == "TaskContext") {
      return await saveTaskMutation({
        variables: { id: response.data.createTask.id, data: data },
        refetchQueries: [RunningTasksDocument],
      })
    }
    return response
  }
  return createTaskWithState
}

export function useCreateTaskMutation(...parameters: Parameters<typeof useOriginalCreateTaskMutation>) {
  const [mutation, result] = useOriginalCreateTaskMutation(...parameters)

  async function createRemoteOrLocal(
    ...mutationparams: Parameters<typeof mutation>
  ): Promise<FetchResult<CreateTaskMutation>> {
    const options = mutationparams[0]
    const taskName = options?.variables?.taskdefinitionname
    if (!taskName) return Promise.reject()
    const taskDefinition = getTaskDefinition(taskName)
    if (taskDefinition?.singleton) {
      const localState = getOrCreateSingletonTask(taskName)
      const res = {
        data: {
          createTask: { ...localState, __typename: "TaskContext" } as ExtractByTypeName<
            "TaskContext",
            CreateTaskMutation["createTask"]
          >,
          __typename: "Mutation" as const,
        },
      }
      result.client.refetchQueries({ include: [RunningTasksDocument] })
      return res
    }
    return await mutation(...mutationparams)
  }

  return [createRemoteOrLocal, result] as [typeof createRemoteOrLocal, typeof result]
}

export function useSaveTaskStateMutation(...parameters: Parameters<typeof useOriginalSaveTaskStateMutation>) {
  const [mutation, result] = useOriginalSaveTaskStateMutation(...parameters)

  const saveTaskState = useCallback(
    async function saveTaskState(
      ...mutationparams: Parameters<typeof mutation>
    ): Promise<FetchResult<SaveTaskStateMutation>> {
      const options = mutationparams[0]
      const taskId = options?.variables?.id
      if (!taskId) return Promise.reject()
      const taskDefinition = getTaskDefinition(taskId)
      if (taskDefinition?.singleton) {
        const localState = loadSingletonTaskState(taskId)
        if (!localState) throw new Error("Cannot update singleton task, because no previous state was found!")
        const newState = { ...localState, data: options.variables?.data }
        saveSingletonTaskState(newState)
        const res = {
          data: {
            saveTaskState: { ...newState, __typename: "TaskContext" } as ExtractByTypeName<
              "TaskContext",
              SaveTaskStateMutation["saveTaskState"]
            >,
            __typename: "Mutation" as const,
          },
        }
        result.client.refetchQueries({ include: [RunningTasksDocument] })
        return res
      }
      const optionsWithOptimisticResult: typeof options = {
        ...options,
        optimisticResponse(vars) {
          const frag = result.client.cache.readFragment<TaskContextFragment>({
            fragment: TaskContextFragmentDoc,
            id: result.client.cache.identify({ __typename: "TaskContext", id: vars.id }),
          })
          if (!frag) {
            throw new Error("Cannot find Task in Cache")
          }
          const res = { saveTaskState: { ...frag, data: vars.data }, __typename: "Mutation" as const }
          return res
        },
      }
      return await mutation(optionsWithOptimisticResult)
    },
    [mutation, result.client],
  )

  return [saveTaskState, result] as const
}

export function useCloseTaskMutation(...parameters: Parameters<typeof useOriginalCloseTaskMutation>) {
  const [mutation, result] = useOriginalCloseTaskMutation(...parameters)

  async function closeRemoteOrLocal(
    ...mutationparams: Parameters<typeof mutation>
  ): Promise<FetchResult<CloseTaskMutation>> {
    const options = mutationparams[0]
    const taskId = options?.variables?.id
    if (!taskId) return Promise.reject()
    const taskDefinition = getTaskDefinition(taskId)
    if (taskDefinition?.singleton) {
      const newState = await closeSingletonTask(taskId)
      const res = {
        data: {
          closeTask: { ...newState, __typename: "TaskContext" } as ExtractByTypeName<
            "TaskContext",
            SaveTaskStateMutation["saveTaskState"]
          >,
          __typename: "Mutation" as const,
        },
      }
      result.client.refetchQueries({ include: [RunningTasksDocument] })
      return res
    }
    return await mutation(...mutationparams)
  }

  return [closeRemoteOrLocal, result] as [typeof closeRemoteOrLocal, typeof result]
}

export function useRunningTasksQuery(...parameters: Parameters<typeof useOriginalRunningTasksQuery>) {
  const { loading, data, error } = useOriginalRunningTasksQuery(...parameters)
  const singletonTasks = useRunningSingletonTasks()
  const patchedData = {
    ...data,
    me: {
      ...data?.me,
      runningTasks: [...(data?.me.runningTasks ?? []), ...singletonTasks],
    },
  }
  return { loading, data: patchedData, error }
}
