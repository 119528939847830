import React, { useEffect, useRef, useState } from "react"
import { useScrollToFocusedComment } from "../../annotationFocusState"
import styled from "styled-components"
import { StyledCard, ImageDescriptions } from "./ImageDescriptions"
import type { Node } from "unist"
import type { ST4NodeWithContentName } from "../../../graphql/types"
import { LayoutInfo } from "./LayoutInfo"
import type { ExtractByTypeName } from "@st4/graphql"
import { FullscreenImageModal } from "./FullscreenImageModal"

export const ImageDisplayContainer = styled.div`
  .thumbnail {
    margin: auto;
    padding: 0.5em;
    display: block;
    height: auto;
    min-height: 10vh;
    max-height: 50vh;
    max-width: 100%;
    object-fit: contain;
  }
  .expandable {
    cursor: pointer;
  }
`

const Legend = styled.div<{ isCommented?: boolean; isCommentFocused?: boolean }>`
  &&& .ant-table-title {
    border: 0;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
  }
  &&& .ant-table-container {
    border: 0;
    border-radius: 2px;
  }
  &&& .ant-table-content > table > tbody > tr > td:last-of-type {
    border-right: 0;
  }
  &&& .ant-table-tbody > tr:last-of-type > td {
    border-bottom: 0;
  }
`

async function loadImage(imageSource: string) {
  const authId = localStorage.getItem("SlimClientXAuthId") //TODO: Bessere Möglichkeit finden!
  if (!authId) return ""
  const request = new Request(imageSource, { method: "GET", headers: [["x-auth-id", authId]] })
  const response = await fetch(request)
  const blob = await response.blob()
  return URL.createObjectURL(blob)
}

type ImageMediaProps = {
  title: React.ReactNode
  xastNode?: Node
  legendNode?: Node
  mediaInfo: ExtractByTypeName<
    "ImageMediaInfo",
    NonNullable<ST4NodeWithContentName<"MediaContent">["content"]["media"]>
  >
  mediaNode: ST4NodeWithContentName<"MediaContent" | "MediaGroupContent"> &
    Parameters<typeof ImageDescriptions>[0]["mediaNode"]
  comments: Parameters<ReturnType<typeof useScrollToFocusedComment>[2]["setFocusedAnnotations"]>[0]
  isGroup: boolean
}

export function ImageMedia({ title, xastNode, legendNode, mediaInfo, mediaNode, isGroup, comments }: ImageMediaProps) {
  const { height, width } = mediaInfo
  const imgSrcWithThumbnailFallback = mediaInfo.src ?? mediaInfo.thumbnail ?? undefined

  const [showOverlay, setShowOverlay] = useState(false)
  const [scrollTargetProps, { isFocused, isPrefocused }, { setFocusedAnnotations, setPrefocusedAnnotations }] =
    useScrollToFocusedComment(comments.map(({ comment: { id } }) => id))
  const isCommented = comments.length > 0
  const isCommentHighlighted = isPrefocused || isFocused

  const cover = (
    <ImageDisplayContainer
      {...scrollTargetProps}
      onClick={(e) => {
        // Bubbling is stopped so that Comments are not highlighted by StyledCard Container
        e.stopPropagation()
        setShowOverlay(true)
      }}
    >
      {/* Explicitly set height and width to the dimensions of the fullsize image to avoid relayouting
      when it is asynchrnously loaded */}
      <img
        className="thumbnail expandable"
        src={imgSrcWithThumbnailFallback}
        height={height ?? "auto"}
        width={width ?? "auto"}
      />
    </ImageDisplayContainer>
  )

  const layoutInfo = <LayoutInfo mediaInfo={mediaInfo} xastNode={xastNode}></LayoutInfo>

  return (
    <>
      {showOverlay && imgSrcWithThumbnailFallback && (
        <FullscreenImageModal src={imgSrcWithThumbnailFallback} onClosed={() => setShowOverlay(false)} />
      )}
      <StyledCard
        cover={cover}
        $isGroup={isGroup}
        $isCommented={isCommented}
        $isCommentFocused={isFocused}
        $isCommentPrefocused={isPrefocused}
        // Prefocus comments when hovering over StyledCard Container
        // Keep old comments prefocused if no comments are set
        onClick={() => isCommented && setFocusedAnnotations(comments)}
        onPointerOver={() => {
          isCommented && !isCommentHighlighted && setPrefocusedAnnotations(comments)
        }}
        onPointerLeave={() => {
          isCommented && isCommentHighlighted && setPrefocusedAnnotations([])
        }}
      >
        <Legend isCommented={isCommented} isCommentFocused={isFocused}>
          <ImageDescriptions
            mediaNode={mediaNode}
            title={title}
            legendNode={legendNode}
            isCommented={isCommented}
            isCommentFocused={isFocused}
            isCommentPrefocused={isPrefocused}
            layoutInfo={layoutInfo}
          />
        </Legend>
      </StyledCard>
    </>
  )
}
