import classNames from "classnames"
import React from "react"
import { centeredIcon } from "@st4/theme-manager"
import MdRefresh from "@schema/react-ionicons/components/MdRefresh"

export default function LoadingSpinner_deprecated(props) {
  return (
    <MdRefresh
      className={classNames(props.className, "LoadingSpinner")}
      key="loading"
      style={centeredIcon}
      rotate
      size="50px"
    />
  )
}
