import React from "react"
import { Descriptions } from "antd"
import { groupBy as _groupBy } from "@schema/utils"
import styled from "styled-components"

const StyledList = styled.div`
  width: 100%;
  border-radius: 0;

  // Trick to increase specificity
  &&& .ant-descriptions-item-label {
    // Align label with the header of the collapse panel
    padding: 0.5em 0.5em 0.5em calc(0.5em + 24px);
  }

  &&& .ant-descriptions-item-content {
    padding: 0 0.5em;
    background-color: ${({ theme }) => theme.white};
    border-bottom: 1px solid ${({ theme }) => theme.harpGrey};
  }

  .ant-descriptions-item-label {
    background-color: ${({ theme }) => theme.white};
    color: ${({ theme }) => theme.midnightBlue};
    border-color: ${({ theme }) => theme.white};
    border-bottom: 1px solid ${({ theme }) => theme.harpGrey};
  }

  .ant-input {
    text-overflow: ellipsis;
  }

  .ant-input[disabled] {
    background-color: white;
    color: rgba(0, 0, 0, 0.5);
    text-overflow: ellipsis;
  }

  &&& .ant-descriptions-view > table {
    table-layout: fixed;
  }
`

type ListProps<T> = {
  items: T[]
  renderItem: (item: T) => React.ReactNode
}

/**
 * Display Data
 * The items will be ordered by their original order in the items array
 * List.Item is a wrapper you can use for your items for sensible styling
 * @param items
 * @param renderItem render an item as a React Component
 */
export function List<K extends PropertyKey, T extends Record<K, T[K]>>({ items, renderItem }: ListProps<T>) {
  return (
    <StyledList>
      <StyledDescription bordered size="small" column={1}>
        {items.map((item) => renderItem(item))}
      </StyledDescription>
    </StyledList>
  )
}

const StyledDescription = styled(Descriptions)`
  .ant-descriptions-view {
    border: 0;
  }
`

const ListItem = styled(Descriptions.Item)<any>``

List.Item = ListItem
