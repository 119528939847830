import type { PropertyEditor, TaxonomyPropertyValue } from "../types"
import { useOntologyInfoQuery } from "./query.hooks"
import React from "react"
import { isTypename } from "@st4/graphql"
import { useTaxonomyEditor } from "./taxonomyQueries"
import { TaxonomyEditor } from "~/ui/editors/taxonomyEditor"
import { useMemorizedInitialSelection } from "./initialSelection"
import { TaxTreeErrors } from "./taxTreeErrors"
import { Icon, Regular } from "@st4/icons"

export function PropertyTaxonomyEditor({
  propertyEditor,
  languageId,
  refetchProperties,
  refetchOnSave,
}: {
  propertyEditor: PropertyEditor<TaxonomyPropertyValue>
  languageId: string
  refetchProperties: () => void
  refetchOnSave?: string[]
}) {
  const { data } = useOntologyInfoQuery({
    variables: {
      input: {
        idOfLinkClassToUse: propertyEditor.value?.linkClass.id,
      },
    },
    fetchPolicy: "cache-and-network",
  })

  const isMultiSelect =
    data && isTypename("OntologyInfoSuccess")(data.ontologyInfo) ? data.ontologyInfo.result.multiSelect : true

  const {
    taxTree,
    taxTreeLoading,
    filterCallback,
    taxTreeError,
    refreshTaxTree,
    update,
    updateLoading,
    updateError,
    updateData,
  } = useTaxonomyEditor(
    propertyEditor.value?.taxonomyRootIds ?? [],
    languageId,
    !!propertyEditor.value?.selectLeavesOnly,
    propertyEditor.node.id,
    refetchProperties,
    refetchOnSave,
    propertyEditor.value?.linkClass.id,
  )

  const updateTaxError = isTypename("TaxonomyUpdateError")(updateData?.updateTaxonomy)
    ? updateData?.updateTaxonomy
    : null
  let errorMessage: string | undefined
  if (updateError) {
    errorMessage = updateError.message
  }
  if (updateTaxError) {
    errorMessage = updateTaxError.message
  }

  const suffixIcon = updateLoading ? (
    <Icon component={Regular.SpinnerThird} />
  ) : !propertyEditor.isReadonly ? (
    <Icon component={Regular.NetworkWired} />
  ) : null

  const initialSelection = useMemorizedInitialSelection(
    propertyEditor.value?.links,
    propertyEditor.value?.isInherited,
    taxTree,
    taxTreeLoading,
  )

  return (
    <TaxonomyEditor
      selectedNodes={initialSelection}
      taxonomyTree={taxTree ?? []}
      isReadonly={propertyEditor.isReadonly}
      suffixIcon={suffixIcon}
      onSubmit={update}
      renderDropdown={TaxTreeErrors(taxTreeLoading, taxTreeError, updateTaxError)}
      isInherited={propertyEditor.value?.isInherited}
      taxonomyColor={propertyEditor.value?.color}
      filterCallback={filterCallback}
      refetchTaxTree={refreshTaxTree}
      isMultiSelect={isMultiSelect}
      errorMessage={errorMessage}
    />
  )
}
