import { i18n } from "@schema/utils"
import { SafetyIconInfo, SafetyTypeInfo, SeverityLevelInfo } from "@st4/content-tools"
import React, { useEffect, useMemo, useState } from "react"
import { NotificationInfo } from "../types"
import { Translate, keys } from "@st4/i18n"
import { Checkbox, Select } from "antd"
import styled from "styled-components"
import { v4 as uuidv4 } from "uuid"

type SafetyPoolActionBarProps = {
  safetyTypes: SafetyTypeInfo[]
  safetySeveritys: SeverityLevelInfo[]
  allowedOperations: string[]
  editElementProperties: { key: string; value: any }[] | null
  setNotifications: (notifications: NotificationInfo[]) => void
  setEditorActionMessage: (editorActionMessage: Messages) => void
  setNoDataAvailable: (noDataAvailable: boolean) => void
}

type Messages = {
  action: "insertSafety" | "updateSafety"
  payload: {
    type: string
    severity: string
    optionalIcons: string[]
  }
}

const Container = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  padding: 16px;
`

const OptionContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`

const CheckboxContainer = styled.div`
  flex: 1;
  padding: 16px 0px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-y: auto;
`

const FormfieldsContainer = styled.div`
  flex: 0;
  margin-top: 16px;
`

const FormGroup = styled.fieldset`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`

const Label = styled.div`
  margin-bottom: 8px;
`

const CheckboxItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`

export function SafetyPoolActionBar(props: SafetyPoolActionBarProps) {
  const {
    safetyTypes,
    safetySeveritys,
    allowedOperations,
    editElementProperties,
    setNotifications,
    setEditorActionMessage,
    setNoDataAvailable,
  } = props

  const [selectedType, setSelectedType] = useState<SafetyTypeInfo>(safetyTypes[0])
  const [selectedSeverity, setSelectedSeverity] = useState<SeverityLevelInfo>(safetySeveritys[0])
  const [safetyOptionalIcons, setSafetyOptionalIcons] = useState<SafetyIconInfo[]>([])

  const handleSafetyTypeChange = (value: string) => {
    const selectedType = safetyTypes.find((type) => type.id === value)

    if (selectedType) {
      setSelectedType(selectedType)
      setSafetyOptionalIcons([])
    }
  }

  const handleSafetySeverityChange = (value: string) => {
    const selectedSeverity = safetySeveritys.find((severity) => severity.id === value)

    if (selectedSeverity) {
      setSelectedSeverity(selectedSeverity)
    }
  }

  const handleOptionalIconsChange = (name: string) => {
    const foundIcon = safetyOptionalIcons.find((icon) => icon.iconName === name)

    if (foundIcon) {
      const filteredIcons = safetyOptionalIcons.filter((icon) => icon.iconName !== name) || []
      setSafetyOptionalIcons(filteredIcons)
      return
    }

    const iconToAdd = selectedType?.iconsInfo.find((icon) => icon.iconName === name)
    if (iconToAdd) setSafetyOptionalIcons([...safetyOptionalIcons, iconToAdd])
  }

  useEffect(() => {
    const editorActionMsg: Messages = {
      action: editElementProperties ? "updateSafety" : "insertSafety",
      payload: {
        type: selectedType?.id ?? "",
        severity: selectedSeverity?.id ?? "",
        optionalIcons: safetyOptionalIcons?.map((icon) => icon.iconName ?? "") || [],
      },
    }
    setEditorActionMessage(editorActionMsg)
  }, [editElementProperties, safetyOptionalIcons, selectedSeverity?.id, selectedType?.id, setEditorActionMessage])

  useEffect(() => {
    if (!editElementProperties) return

    const editType = editElementProperties.find((x) => x.key === "type")?.value
    const newSafetyType = safetyTypes.find((type) => type.id === editType)
    if (!newSafetyType) return

    const editSeverity = editElementProperties.find((x) => x.key === "severity")?.value
    const newSeverity = safetySeveritys.find((severity) => severity.id === editSeverity)
    if (!newSeverity) return

    const optionalIcons = editElementProperties.find((x) => x.key === "optionalIcons")?.value ?? []
    const newOptionalIcons = optionalIcons.map((optionalIcon: string) => {
      return newSafetyType?.iconsInfo.find((icon) => icon.iconName === optionalIcon)
    })

    setSafetyOptionalIcons([])
    setSelectedType(newSafetyType)
    setSelectedSeverity(newSeverity)

    if (newOptionalIcons) setSafetyOptionalIcons(newOptionalIcons)
  }, [editElementProperties])

  const notifications = useMemo(() => {
    if (editElementProperties) return []
    return getNotificationInfo(allowedOperations)
  }, [editElementProperties, allowedOperations])

  useEffect(() => {
    if (notifications) setNotifications(notifications)
  }, [notifications, setNotifications])

  useEffect(() => {
    const noDataAvailable = !selectedSeverity || !selectedType
    setNoDataAvailable(noDataAvailable)
  }, [selectedSeverity, selectedType, setNoDataAvailable])

  return (
    <>
      <Container>
        <OptionContainer>
          <FormfieldsContainer>
            <FormGroup>
              <Label>
                <Translate>{keys.label.safety.severity}</Translate>
              </Label>
              <Select
                data-testid="safetypool:safety:severity"
                style={{ width: "100%" }}
                value={selectedSeverity?.id ?? undefined}
                options={safetySeveritys}
                onChange={handleSafetySeverityChange}
                dropdownMatchSelectWidth={false}
                fieldNames={{ label: "displayName", value: "id" }}
              />
            </FormGroup>
            <FormGroup>
              <Label>
                <Translate>{keys.label.safety.type}</Translate>
              </Label>
              <Select
                data-testid="safetypool:safety:type"
                style={{ width: "100%" }}
                value={selectedType?.id ?? undefined}
                options={safetyTypes}
                onChange={handleSafetyTypeChange}
                dropdownMatchSelectWidth={false}
                fieldNames={{ label: "displayName", value: "id" }}
              />
            </FormGroup>
          </FormfieldsContainer>
          <Label>
            <Translate>{keys.label.safety.symbols}</Translate>
          </Label>
          <CheckboxContainer key={selectedType?.id}>
            {selectedType &&
              selectedType.iconsInfo.map((icon) => (
                <CheckboxItem key={`${selectedType.id}+${icon.id}+${icon.iconName}`}>
                  <Checkbox
                    data-testid={`safetypool:safety:icon:${icon.iconName}`}
                    checked={icon.isRequired || safetyOptionalIcons?.some((s) => s.iconName === icon.iconName)}
                    disabled={icon.isRequired}
                    onChange={() => handleOptionalIconsChange(icon.iconName ?? "")}
                  />
                  <img src={icon.src || icon.thumbnail} alt={icon.iconName} width="100" />
                  <div>{icon.iconName}</div>
                </CheckboxItem>
              ))}
          </CheckboxContainer>
        </OptionContainer>
      </Container>
    </>
  )
}

function getNotificationInfo(allowedOperations: string[]): NotificationInfo[] {
  if (allowedOperations && allowedOperations.find((x) => x === "insert-safety")) return []

  return [
    {
      id: uuidv4(),
      nodeId: "",
      title: "",
      description: i18n.t(keys.message.safetyPool.not_possible).toString(),
      severity: "error",
      disableInsert: true,
    },
  ]
}
