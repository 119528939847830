import { useEffect } from "react"

/**
 * Attach a handler to an event triggered on the `window`.
 */
export function useOnBeforeUnload(shouldWarn: () => boolean) {
  useEffect(() => {
    const handle = (ev: BeforeUnloadEvent): void => {
      if (shouldWarn()) {
        ev.preventDefault()
      }
    }
    window.addEventListener("beforeunload", handle)
    return () => window.removeEventListener("beforeunload", handle)
  }, [shouldWarn])
}
