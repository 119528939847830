import * as React from "react"
import { SVGProps } from "react"

const SvgTaskProductVariant = (props: SVGProps<SVGSVGElement>) => (
  <svg
    data-name="Gruppe 4759"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 40.16 30.598"
    {...props}
  >
    <defs>
      <clipPath id="TaskProductVariant_svg__a">
        <path data-name="icon/outlined/application/filter (clip mask)" fill="none" d="M0 0h30.598v30.598H0z" />
      </clipPath>
    </defs>
    <path data-name="icon/outlined/application/filter (clip mask)" fill="none" d="M0 0h30.598v30.598H0z" />
    <g data-name="icon/outlined/application/filter" clipPath="url(#TaskProductVariant_svg__a)">
      <g data-name="icon/outlined/application/filter (clip group)">
        <path data-name="icon/outlined/application/filter (style)" fill="none" d="M0 0h30.598v30.598H0z" />
        <path
          d="M19.219 26.002h-7.841a.955.955 0 0 1-.95-.957v-7.194L3.478 6.036a.95.95 0 0 1 0-.959.939.939 0 0 1 .821-.475h22a.942.942 0 0 1 .822.475.951.951 0 0 1 0 .959L20.17 17.851v7.189a.955.955 0 0 1-.951.962Zm-6.651-6.818v4.662h5.462v-4.662ZM6.356 6.753l5.925 10.022.283.5h5.469l.283-.5 5.926-10.022Z"
          fill="currentColor"
        />
      </g>
    </g>
    <path
      data-name="Pfad 4628"
      d="M34.423 14.343V9.562a.956.956 0 0 0-1.912 0v4.781h-4.782a.956.956 0 1 0 0 1.912h4.781v4.781a.956.956 0 0 0 1.912 0v-4.78h4.781a.956.956 0 0 0 0-1.912h-4.78Z"
      fill="currentColor"
    />
  </svg>
)

export default SvgTaskProductVariant
