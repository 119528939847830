import React from "react"
import { isReadonlyPreviewParameterContext, usePreviewParameterContext } from "../../PreviewParameterContext"
import type { NodeRenderProps } from "../types"
import { ContentWidthLimiting } from "../utils"
import { EditableTextNode } from "./EditableTextNode"
import { ReadonlyTextNode } from "./ReadonlyTextNode"

export function TextNode({ treeNode, node }: NodeRenderProps) {
  const previewParameterContext = usePreviewParameterContext()
  if (isReadonlyPreviewParameterContext(previewParameterContext))
    return (
      <ContentWidthLimiting>
        <ReadonlyTextNode treeNode={treeNode} node={node} />
      </ContentWidthLimiting>
    )

  return (
    <EditableTextNode key={node.id} treeNode={treeNode} node={node} previewParameterContext={previewParameterContext} />
  )
}
