import { Mapping, MappingProps, MappingRule } from "./types"
import { getComponent } from "../components/componentRegistry"
import React from "react"
import { getNodeMapping } from "./mappingRegistry"
import type { St4NodeWithContentFragment } from "../graphql/applicationQueries/query.hooks"

/**
 * Finds the mapping rule for a given node.
 * @param node The node for wich the mapping-rule should be looked up
 * @param mappingRegistry the registry used for the lookup
 * @returns either the matching rule or null if no rule was found.
 */
export function getNodeMappingRule(node: St4NodeWithContentFragment, mappingRegistry: "FULL" | "LOADING") {
  const classHierarchy = node.nodeClass.classHierarchy ?? []
  const nodeMapping = getNodeMapping(mappingRegistry) ?? {}
  const mappingRule = getFirstMappingInHierarchy(node, classHierarchy, nodeMapping)
  return mappingRule
}

export function getMapping<T>(element: T, key: string, mapping: Mapping<T>): MappingRule<T> | null {
  if (!key) return null

  let mappingForTag: MappingRule<T> | MappingRule<T>[] | undefined = mapping[key]
  if (mappingForTag) {
    if (Array.isArray(mappingForTag)) {
      //try to find first mappingRule that matches the Element
      let foundMapping = mappingForTag.find((rule: MappingRule<T>) => {
        if (typeof rule === "string" || !rule.match) return false
        if (typeof rule.match === "function") return rule.match(element)
        return false
      })
      // If no matching rule is found the first rule without a matcher is used
      if (!foundMapping) foundMapping = mappingForTag.find((rule) => !rule.match)
      mappingForTag = foundMapping
    }
    return mappingForTag ?? null
  }
  return null
}

type LookupResult = "hidden" | "notFound"
// getComponent for either direct string mappings or ones using "as"
// returns null when no component could be found or is hidden
export function lookupComponent<T>(mapping: MappingRule<T>): React.ComponentType | LookupResult {
  if (typeof mapping === "string") {
    return getComponent(mapping) ?? "notFound"
  } else if (mapping.hidden) {
    return "hidden"
  } else {
    const as = mapping.as
    const cmp = as && getComponent(as)
    return cmp || "notFound"
  }
}

//Extract Props from mapping, if function is provided instead of props its result will be used
export function extractProps<T>(elem: T, mapping: MappingRule<T> | null | undefined): MappingProps {
  if (typeof mapping === "string" || mapping?.hidden || !mapping?.props) {
    return {}
  }
  return typeof mapping.props === "function" ? mapping.props(elem) : mapping.props
}

// Get mappingRule for first key in a hierarchy that has one
function getFirstMappingInHierarchy<T>(elem: T, hierarchy: string[], mapping: Mapping<T>) {
  for (let i = hierarchy.length - 1; i >= 0; i--) {
    const mappingRule = getMapping(elem, hierarchy[i], mapping)
    if (mappingRule) return mappingRule
  }
  return null
}
