import { ResourceImageDisplay } from "../contentComponents/image/ImageDisplay"
import React from "react"
import type { NodeRenderProps } from "./types"
import { ContentWidthLimiting, NodeWrapper, useIsNodeSelected } from "./utils"
import { isST4NodeWithContent } from "../../graphql/types"

export function ResourceNode({ node, treeNode }: NodeRenderProps) {
  const selected = useIsNodeSelected(treeNode)
  if (!isST4NodeWithContent("MediaContent", "MediaGroupContent")(node))
    throw new Error("Unexpected Content type in Node")
  return (
    <ContentWidthLimiting>
      <NodeWrapper selected={selected}>
        <ResourceImageDisplay title={node.label ?? ""} mediaNode={node} />
      </NodeWrapper>
    </ContentWidthLimiting>
  )
}
