import React from "react"
import { Tag } from "antd"
import styled from "styled-components"
import { keys } from "@st4/ui-strings"
import { useTranslation } from "react-i18next"
import type { ReleaseState } from "@st4/graphql"
import type { St4NodeWithContentFragment } from "../../graphql/applicationQueries/query.hooks"
import { Icon, Regular } from "@st4/icons"

const StyledReleaseStateDisplay = styled.div`
  line-height: 0px;
  .ant-tag {
    font-size: ${({ theme }) => theme.token.fontSizeSM}px;
    line-height: ${({ theme }) => theme.token.lineHeightSM};
    padding: 0 3px;
  }
`

function getReleaseStateColor(state: ReleaseState) {
  switch (state) {
    case "NOT_RELEASED":
    case "OBSOLETE":
      return "red"
    case "RELEASED":
    case "CONTENT_APPROVED":
    case "CONTENT_RELEASED":
    case "RELEASED_AND_CONTENT_APPROVED":
    case "APPROVED":
      return "green"
    default:
      return "gray"
  }
}

export const ReleaseStateDisplay = (props: {
  releaseState: St4NodeWithContentFragment["releaseState"] | undefined
}) => {
  const { t } = useTranslation()
  if (props.releaseState == undefined) {
    return null
  }

  const color = getReleaseStateColor(props.releaseState.state)
  const name = props.releaseState.isImplicitlyReleased
    ? t(keys.label.releasestate.isImplicitlyReleased)
    : props.releaseState.displayName
  return (
    <StyledReleaseStateDisplay>
      <Tag color={color} icon={color == "green" && <Icon component={Regular.Check} />}>
        {name}
      </Tag>
    </StyledReleaseStateDisplay>
  )
}
