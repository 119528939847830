import * as React from "react"
import { SVGProps } from "react"

const SvgSt4InfoNodeRegular = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1em" height="1em" {...props}>
    <path d="M281.7 206.8c0 15.4-10.3 25.7-25.7 25.7s-25.7-10.3-25.7-25.7 10.3-25.7 25.7-25.7 25.7 10.3 25.7 25.7zM204.6 284c0-10.3 7.7-18 18-18H256c10.3 0 18 7.7 18 18v120.9h18c10.3 0 18 7.7 18 18s-7.7 18-18 18h-69.5c-10.3-2.6-18-10.3-18-20.6s7.7-18 18-18h18V299.4h-18c-10.2 0-17.9-7.7-17.9-15.4zm224.7-174.8-90.6-90.5C326.7 6.7 310.5 0 293.5 0H128C92.7 0 64 28.7 64 64v384c0 35.3 28.7 64 64 64h256c35.3 0 64-28.7 64-64V154.5c0-17-6.7-33.3-18.7-45.3zM400 448c0 8.8-7.2 16-16 16H128c-8.8 0-16-7.2-16-16V64c0-8.8 7.2-16 16-16h160v80c0 17.7 14.3 32 32 32h80v288z" />
  </svg>
)

export default SvgSt4InfoNodeRegular
