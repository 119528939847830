import * as React from "react"
import { SVGProps } from "react"

const SvgSt4CellLeftAlignedLight = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="1em" height="1em" {...props}>
    <path d="M384 32H64C28.7 32 0 60.7 0 96v320c0 35.3 28.7 64 64 64h320c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64zm32 384c0 17.7-14.3 32-32 32H64c-17.7 0-32-14.3-32-32V96c0-17.7 14.3-32 32-32h320c17.7 0 32 14.3 32 32v320zm-92.5-48h-199c-8.8 0-16-7.2-16-16s7.2-16 16-16h199c8.8 0 16 7.2 16 16s-7.2 16-16 16zm-86.7-96H124.5c-8.8 0-16-7.2-16-16s7.2-16 16-16h112.3c8.8 0 16 7.2 16 16s-7.2 16-16 16zm49.3-96H124.5c-8.8 0-16-7.2-16-16s7.2-16 16-16h161.6c8.8 0 16 7.2 16 16s-7.2 16-16 16z" />
  </svg>
)

export default SvgSt4CellLeftAlignedLight
