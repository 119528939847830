import { Badge, Dropdown, DropDownProps, Menu } from "antd"
import { TooltipPlacement } from "antd/lib/tooltip"
import React from "react"
import styled, { useTheme } from "styled-components"
import { Tooltip } from "@st4/ui"

type DropdownWithBadgeProps = {
  icon: React.ReactNode
} & DropdownWithIconCommonProps

type DropdownWithStandardIconProps = {
  standardIcon: React.ReactNode
  changedSelectionIcon?: React.ReactNode
} & DropdownWithIconCommonProps

type DropdownWithIconCommonProps = {
  standardSelection: string
} & DropdownBaseProps

type DropdownBaseProps = {
  selectedItem: { label: React.ReactNode; value: string }
  children?: React.ReactNode
  tooltipPlacement?: TooltipPlacement
  items: { label: React.ReactNode; value: string }[]
  menuItemOnClick: (clickedItem: { value: string; label: React.ReactNode }) => void
  componentTranslationKey?: string
  tooltipFromSelectedItem?: boolean
}

export function DropdownWithBadge(props: DropdownWithBadgeProps) {
  const theme = useTheme()

  return (
    <DropdownWithTooltip {...props}>
      <Badge
        dot={props.standardSelection !== props.selectedItem.value}
        color={theme.regularBlue}
        style={{ width: "8px", height: "8px" }}
      >
        <StyledLink>{props.icon}</StyledLink>
      </Badge>
    </DropdownWithTooltip>
  )
}

export function DropdownWithStandardIcon(props: DropdownWithStandardIconProps) {
  return (
    <DropdownWithTooltip {...props}>
      {props.standardSelection === props.selectedItem.value ? (
        <LinkWithDefaultSelection onClick={(e) => e.preventDefault()}>{props.standardIcon}</LinkWithDefaultSelection>
      ) : (
        <LinkWithChangedSelection onClick={(e) => e.preventDefault()}>
          {props.changedSelectionIcon ?? props.standardIcon}
        </LinkWithChangedSelection>
      )}
    </DropdownWithTooltip>
  )
}

function createMenu(
  selectedItem: { label: React.ReactNode; value: string },
  items: { label: React.ReactNode; value: string }[],
  menuItemOnClick: (clickedItem: { value: string; label: React.ReactNode }) => void,
): DropDownProps["menu"] {
  return {
    selectedKeys: [selectedItem.value],
    items: items.map(({ label, value }) => ({
      key: value,
      label: label,
      onClick: () => menuItemOnClick({ value, label }),
    })),
  }
}

function DropdownWithTooltip({
  selectedItem,
  tooltipPlacement,
  componentTranslationKey,
  items,
  menuItemOnClick,
  children,
  tooltipFromSelectedItem,
}: DropdownBaseProps) {
  const menu = createMenu(selectedItem, items, menuItemOnClick)
  const theme = useTheme()

  return (
    <Tooltip
      title={tooltipFromSelectedItem ? selectedItem.label : null}
      color={theme.veryDarkBlue}
      placement={tooltipPlacement}
      translationKey={componentTranslationKey}
    >
      <span>
        <Dropdown trigger={["click"]} menu={menu}>
          {children}
        </Dropdown>
      </span>
    </Tooltip>
  )
}

const LinkWithDefaultSelection = styled.a`
  color: ${({ theme }) => theme.midnightBlue};
`

const LinkWithChangedSelection = styled.a`
  color: ${({ theme }) => theme.darkBlue};
`

const StyledLink = styled.div`
  cursor: pointer;
  color: ${({ theme }) => theme.shuttleGrey};
  &:hover {
    color: ${({ theme }) => theme.tuna};
  }
`
