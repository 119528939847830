import React from "react"
import type { CSSProperties } from "react"
import { TYPES } from "../ast/xast"
import { getComponent } from "./componentRegistry"
import type { Mapping, MappingRule } from "../mapping/types"
import type { Node } from "unist"
import { NodeProvider, TreeNode } from "../contentModel/nodeContext"
import { CommentCollectionProvider } from "./annotationCollection"
import { SimpleErrorBoundary } from "./Errors"
import { DefaultTheme } from "styled-components"
import type { StyledComponent } from "styled-components"
import { extractProps, getMapping, lookupComponent } from "../mapping/mappingUtilities"
import { getTextContentState, TextContentState } from "../contentModel"
import { findSt4NodeInTree } from "../utilities/treeUtilities"
import { FilteringTreeNodeWrapper } from "./FilteringTreeNodeWrapper"
import { St4NodeWithContentFragment } from "../graphql/applicationQueries/query.hooks"

export function renderXAST(
  ast: Node,
  component_mapping: Mapping<Node>,
  root: Node,
  node: St4NodeWithContentFragment,
  treeNodeById: Map<string, TreeNode>,
  treeNodeContext: TreeNode,
  fragments?: TextContentState["fragments"],
  depth = 0,
): JSX.Element | null {
  const { fragments: nodeFragments, parts: nodeParts, comments } = getTextContentState(node)
  fragments = fragments || nodeFragments

  if (ast.data) {
    if (ast.data.fragmentKey) {
      // Assumption: Both structural and normal fragments are in "fragments" field and referenced in XAST with fragmentKey
      const fragmentKey = ast.data.fragmentKey
      const fragmentNode = ast.data.partKey ? nodeParts.get(ast.data.partKey) : fragments.get(fragmentKey)

      if (!fragmentNode) {
        console.error(`No matching fragment found for id '${fragmentKey}'`)
        return null
      }
      const fragmentTreeNode = findSt4NodeInTree(treeNodeById, treeNodeContext, fragmentNode)
      if (!fragmentTreeNode) {
        console.error(`Could not find treenode for fragment id ${fragmentKey}`)
        return null
      }

      // TreeNode needs to be merged with fragment because treeNodes from previewContentModel dont have any content
      const mergedTreeNode = { ...fragmentTreeNode, node: fragmentNode }
      const { comments: fragmentComments } = getTextContentState(fragmentNode)
      return (
        <FilteringTreeNodeWrapper treeNodeId={mergedTreeNode.id} key={`FTNW_${mergedTreeNode.id}`}>
          <NodeProvider node={mergedTreeNode}>
            <CommentCollectionProvider comments={fragmentComments}>
              {renderFundamentalXAST(
                ast,
                component_mapping,
                root,
                fragmentNode,
                depth,
                treeNodeById,
                fragmentTreeNode,
                fragments,
              )}
            </CommentCollectionProvider>
          </NodeProvider>
        </FilteringTreeNodeWrapper>
      )
    }
  }
  return renderFundamentalXAST(ast, component_mapping, root, node, depth, treeNodeById, treeNodeContext, fragments)
}

function renderFundamentalXAST(
  ast: Node,
  component_mapping: Mapping<Node>,
  root: Node,
  node: St4NodeWithContentFragment,
  depth: number,
  treeNodeById: Map<string, TreeNode>,
  treeNodeContext: TreeNode,
  fragments?: TextContentState["fragments"],
): JSX.Element | null {
  const mapping = ast.tagName && getMapping<Node>(ast, ast.tagName, component_mapping)
  switch (ast.type) {
    case TYPES.Root:
      return (
        <React.Fragment key="Root">
          {computeChildren(ast, component_mapping, root, node, depth, treeNodeById, treeNodeContext, fragments)}
        </React.Fragment>
      )
    case TYPES.Element: {
      if (!mapping) {
        // console.warn(`[XAST mapping] no mapping found for ${ast.type} <${ast.tagName}>`)
        return null
      }
      const cmp = lookupComponent(mapping)
      if (cmp === "notFound") {
        // console.warn(`[XAST mapping] unknown mapping component for <${ast.tagName}>`, mapping)
        return null
      } else if (cmp == "hidden") {
        return null
      } else {
        return React.createElement(
          SimpleErrorBoundary,
          {
            message: `Error in: XML: "${ast.tagName}" Component: "${cmp.displayName || cmp.name}"`,
            key: `SEB_${ast.position?.start.offset}_${ast.tagName}`,
          },
          React.createElement(
            cmp,
            computeProps(ast, mapping, cmp),
            computeChildren(ast, component_mapping, root, node, depth, treeNodeById, treeNodeContext, fragments),
          ),
        )
      }
    }
    case TYPES.Text: {
      const Text = getComponent("Text")
      if (!Text) throw "Fundamental component 'Text' not found"
      return ast.value ? (
        <Text xastNode={ast} key={`TXT_${ast.position?.start.offset}`}>
          {ast.value}
        </Text>
      ) : null
    }
    default:
      return null
  }
}

interface XmlProp {
  nodeName: string | undefined
  getAttribute(name: string): string | null
}

interface NodeProps extends ComplexComponentProps, StringComponentProps {
  className?: string
  style?: CSSProperties
}

type NodeType = StyledComponent<(props: NodeProps) => JSX.Element, DefaultTheme, Record<string, unknown>, never>

export interface XastRendererProps {
  key: string
  as: NodeType
  ast: Node
  xml: XmlProp
}

export interface StringComponentProps extends XastRendererProps {
  label: boolean | string
  translationKey: string | undefined
}

export type ComplexComponentProps<T = any> = XastRendererProps &
  T & {
    component:
      | React.ComponentType
      | React.ClassType<any, any, any>
      | string
      | keyof React.ReactSVG
      | keyof React.ReactHTML
  }

function computeProps(
  ast: Node,
  mapping: MappingRule<Node> | null,
  cmp: React.ComponentType,
): ComplexComponentProps | StringComponentProps {
  const baseValues = {
    key: "",
    as: getComponent("Node"),
    ast,
    xml: {
      nodeName: ast.tagName,
      getAttribute(name: string) {
        return ast.attributes ? ast.attributes[name] : null
      },
    },
  }
  if (ast.tagName && ast.position && ast.position.start.offset) {
    baseValues.key = `${ast.tagName}_${ast.position.start.offset}`
  }
  const mappingProps = extractProps(ast, mapping)
  return !mapping || typeof mapping === "string"
    ? {
        ...baseValues,
        label: true,
      }
    : {
        ...baseValues,
        component:
          (mapping.props && typeof mapping.props !== "function" && mapping.props.component) || (cmp as any).target, // TODO target???
        ...mappingProps,
      }
}

function computeChildren(
  ast: Node,
  component_mapping: Mapping<Node>,
  complete_ast: Node,
  node: St4NodeWithContentFragment,
  depth: number,
  treeNodeById: Map<string, TreeNode>,
  treeNodeContext: TreeNode,
  fragments?: TextContentState["fragments"],
) {
  return ast.children
    ? ast.children.map((n) =>
        renderXAST(n, component_mapping, complete_ast, node, treeNodeById, treeNodeContext, fragments, depth + 1),
      )
    : null
}
