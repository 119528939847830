import React from "react"
import {
  SearchFacetsList,
  SearchFacet,
  SearchOptions,
  SearchRefinement,
  useSearchFacetsBySearchTermQuery,
  fromGraphQLSearchFacet,
  toAspectSelectorId,
  toGraphQLRefinement,
} from "@st4/search"
import { SearchQueryOptions } from "@st4/graphql"
import { useAspectId } from "@st4/settings"

type SearchFacetsProps = {
  searchTerm: string
  searchRefinements: SearchRefinement[]
  searchOptions: SearchOptions
  page: number
  pageSize: number
  onSearchRefinementsChanged: (newRefinements: SearchRefinement[]) => void
}

export function SearchFacets_SearchTerm(props: SearchFacetsProps) {
  const languageId = useAspectId()
  const searchLanguages = toAspectSelectorId(props.searchOptions.searchLanguages)
  const options: SearchQueryOptions = {
    paging: {
      page: props.page,
      pageSize: props.pageSize,
    },
    searchVersion: props.searchOptions.searchVersion as string,
    searchLanguages: undefined as string | undefined,
  }
  if (searchLanguages) options.searchLanguages = searchLanguages

  const { data, loading, error } = useSearchFacetsBySearchTermQuery({
    variables: {
      term: props.searchTerm || "",
      keys: props.searchOptions.searchKeys,
      language: languageId as string,
      options: options,
      refinements:
        !!props.searchRefinements && props.searchRefinements.length > 0
          ? props.searchRefinements.map(toGraphQLRefinement)
          : undefined,
    },
  })

  let searchFacets = Array<SearchFacet>()
  let searchFacetsError: { name: string; message: string } | undefined = undefined

  if (!loading && !!data) {
    const graphqlResult = data.searchTerm.searchFacets
    searchFacetsError = error

    if (!searchFacetsError) {
      try {
        searchFacets = graphqlResult.map(fromGraphQLSearchFacet)
      } catch (exc) {
        console.error("Error converting search facets", exc)
        searchFacetsError = exc as Error
      }
    }
  }

  return (
    <SearchFacetsList
      searchTerm={props.searchTerm}
      loading={loading}
      searchFacets={searchFacets}
      selectedRefinements={props.searchRefinements}
      error={searchFacetsError}
      onSearchRefinementsChanged={props.onSearchRefinementsChanged}
    />
  )
}
