import * as React from "react"
import { SVGProps } from "react"

const SvgSt4InsertmetarefRegular = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" width="1em" height="1em" {...props}>
    <path d="M114.4 437.1c0-41.4 33.5-74.9 74.9-74.9h30c8.3 0 15 6.7 15 15s-6.7 15-15 15h-30c-24.8 0-44.9 20.1-44.9 44.9s20.1 44.9 44.9 44.9h30c8.3 0 15 6.7 15 15s-6.7 15-15 15h-30c-41.4 0-74.9-33.5-74.9-74.9zm269.6 0c0 41.4-33.5 74.9-74.9 74.9h-30c-8.3 0-15-6.7-15-15s6.7-15 15-15h30c24.8 0 44.9-20.1 44.9-44.9s-20.1-44.9-44.9-44.9h-30c-8.3 0-15-6.7-15-15s6.7-15 15-15h30c41.4 0 74.9 33.5 74.9 74.9zm-179.7-15h89.9c8.3 0 15 6.7 15 15s-6.7 15-15 15h-89.9c-8.3 0-15-6.7-15-15s6.7-15 15-15zM86.9 464H64c-8.8 0-16-7.2-16-16V64c0-8.8 7.2-16 16-16h160v80c0 17.7 14.3 32 32 32h80v174.7c18.4 4.8 34.9 14.5 48 27.6V154.5c0-17-6.7-33.3-18.7-45.3l-90.6-90.5C262.7 6.7 246.5 0 229.5 0H64C28.7 0 0 28.7 0 64v384c0 35.3 28.7 64 64 64h50.5c-13.1-13.1-22.8-29.6-27.6-48zm12.4-82.6c7.1-11.4 16.3-21.4 27.1-29.4H112v-48h64v28c4.4-.5 8.8-.8 13.3-.8H208V304h64v27.2h32V224c0-17.7-14.3-32-32-32H112c-17.7 0-32 14.3-32 32v128c0 13.2 7.9 24.5 19.3 29.4zM208 224h64v48h-64v-48zm-96 0h64v48h-64v-48z" />
  </svg>
)

export default SvgSt4InsertmetarefRegular
