import type { TypePolicies } from "@apollo/client"
import type { ContentProperty, ContentPropertyConnection } from "@st4/graphql"
import { notEmpty } from "@st4/graphql"

//merge: true behaves like {...old,...new} while considering Cache references and merge policies of subtypes
export const typePolicies: TypePolicies = {
  Query: {
    fields: {
      node: {
        read(_, { args, toReference }) {
          // https://www.apollographql.com/docs/react/caching/advanced-topics/#cache-redirects
          if (!args) return
          if (args.id?.startsWith("x-stobject:"))
            return toReference({
              __typename: "ST4Node",
              id: args.id,
            })
        },
      },
    },
  },
  TextComment: {
    keyFields: (o, ctx) => {
      if (!o.node) return false
      if (!(o.node as any).id) return false
      if (!o.endLocation) return false
      if (!o.commentKey) return false

      return `${o.__typename}:${(o.node as any).id}_${o.startLocation}_${o.endLocation}_${o.commentKey}`
    },
    merge: true,
  },
  BlockComment: {
    keyFields: (o) => {
      if (!o.node) return false
      if (!(o.node as any).id) return false
      if (!o.location) return false
      if (!o.commentKey) return false
      return `${o.__typename}:${(o.node as any).id}_${o.location}_${o.commentKey}`
    },
    merge: true,
  },
  TextContent: {
    merge: true,
  },
  MediaContent: {
    merge: true,
  },
  MediaGroupContent: {
    merge: true,
  },
  VariableTableContent: {
    merge: true,
  },
  LexiconEntryContent: {
    merge: true,
  },
  TextGroupContent: {
    merge: true,
    keyFields: (o) => {
      /*id is actually the id of the first text content, 
      this can cause problems in cases of it being the first text content in several text group contents*/
      if (!o.groupNodeId) return false

      return `${o.groupNodeId}`
    },
  },
  TermContent: {
    merge: true,
  },
  LexiconContent: {
    merge: true,
  },
  ST4Node: {
    merge: true,
    fields: {
      properties: {
        merge(
          existing: Partial<ContentPropertyConnection> | undefined,
          incoming: Partial<ContentPropertyConnection> | undefined,
          options,
        ) {
          if (!existing || !existing.edges?.length) {
            return incoming
          }

          if (!incoming || !incoming.edges?.length) {
            return existing
          }
          const existingMap = new Map(existing.edges.filter(notEmpty).map(({ node }) => [node?.name, node]))
          const incomingMap = new Map(incoming.edges.filter(notEmpty).map(({ node }) => [node?.name, node]))
          for (const [existingKey, existingValue] of existingMap) {
            const incomingValue = incomingMap.get(existingKey)
            if (incomingValue && existingValue)
              existingMap.set(existingKey, options.mergeObjects(existingValue, incomingValue) as ContentProperty)
            else existingMap.delete(existingKey)
          }
          const mergedPropertyEdges = {
            ...options.mergeObjects(existing, incoming),
            edges: [...existingMap.values()].map((v) => ({ node: v, __typename: "ContentPropertyEdge" })),
          }
          return mergedPropertyEdges
        },
      },
    },
  },
  ST4TreeNode: {
    keyFields: (o) => {
      if (!o.id) return false
      //variant could be merged into St4tTeeNode.id on server side
      return `${o.__typename}:${o.id}_${o.variant ?? "noVariant"}`
    },
    merge: true,
  },
  ImageMediaInfo: {
    merge: true,
  },
  VideoMediaInfo: {
    merge: true,
  },
  VectorGraphicsMediaInfo: {
    merge: true,
    keyFields: (o) => {
      return `${o.__typename}:${o.resourceId}`
    },
  },
  VectorGraphicsLabel: { keyFields: false },
  AnimationMediaInfo: {
    merge: true,
  },
  NoMediaInfo: {
    merge: true,
  },
}
