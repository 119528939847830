import { useCallback } from "react"
import { ContextMenuOperationWrapper, ContextMenuOperationWrapperProps } from "../types"
import { useRemoveReuseLinks, useRemoveReuseLinksAllowedLazy } from "./removeLinksFacade"
import { useTranslation } from "react-i18next"
import { keys } from "@st4/ui-strings"
import { useCacheOperations } from "../cacheOperation"

export function useRemoveReuseLinkOperationWrapper({
  onOperationSuccess,
  showNotification,
}: ContextMenuOperationWrapperProps): ContextMenuOperationWrapper {
  const queryRemoveReuseLinksAllowed = useRemoveReuseLinksAllowedLazy()
  const removedLinksSuccessfullyCallback = useCallback(
    (nodeId: string) => onOperationSuccess(nodeId ?? ""),
    [onOperationSuccess],
  )
  const { startJob } = useRemoveReuseLinks(removedLinksSuccessfullyCallback)
  const { t: translate } = useTranslation()
  const { evictContentOfNode, refetchPreviewOutline } = useCacheOperations()

  const handleMenuItemClick = useCallback(
    (nodeId: string) => {
      startJob([nodeId]).then((value) => {
        if (value.error) {
          showNotification("error", translate(keys.label.warning.error), value.error)
        } else {
          evictContentOfNode(nodeId)
          refetchPreviewOutline()
        }
      })
    },
    [startJob, showNotification, translate, evictContentOfNode, refetchPreviewOutline],
  )

  return {
    queryOperationAllowed: queryRemoveReuseLinksAllowed,
    handleMenuItemClick,
  }
}
