import type { Mapping } from "./types"
import type { ST4NodeWithContent } from "../graphql/applicationQueries"
import { registerNodeMapping } from "./mappingRegistry"

const fullMapping: Mapping<ST4NodeWithContent> = {
  TextNode: { as: "TextNode" },
  TextFolder: { as: "TextFolder" },
  BaseNodeClass: { as: "BasicNode" },
  VariableNode: { as: "VariableTableNode" },
  LexiconEntry: { as: "LexiconNode" },
  DataNode: { as: "DataNode" },
  GraficResource: { as: "ResourceNode" },
  GraficGroup: { as: "ResourceNode" },
  TextGroup: { hidden: true, filteredChildren: true },
  TextModuleGroup: { hidden: true, filteredChildren: true },
  DocumentResourceGroup: { hidden: true, filteredChildren: true },
}

const loadingMapping: Mapping<ST4NodeWithContent> = {
  BaseNodeClass: { as: "LoadingNode" },
  TextGroup: { hidden: true },
  TextModuleGroup: { hidden: true },
  DocumentResourceGroup: { hidden: true },
}

export function register() {
  registerNodeMapping("FULL", fullMapping)
  registerNodeMapping("LOADING", loadingMapping)
}
