import React, { useState } from "react"
import styled from "styled-components"
import { Input as AntInput } from "antd"
import { NotificationPopover } from "../notificationpopover"
import { Icon, Regular } from "@st4/icons"

type InputProps = {
  value?: string
  /**
   * Placeholder is shown when there is no input
   */
  placeholder?: string
  readonly?: boolean
  /**
   * Visualisation for value that has not been persisted yet
   */
  dirty?: boolean
  /**
   * Message that explains why the current value is invalid. If specified it will be shown in a popover
   */
  errorMessage?: React.ReactNode
  /**
   * Override default placement of error popover to avoid popover hiding context
   */
  errorPlacement?: React.ComponentProps<typeof NotificationPopover>["placement"]
  /**
   * Icon that visualizes the type of content the input is handling.
   * If there is an error this icon will be replaced in favor of an error icon
   */
  suffixIcon?: React.ReactNode
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void
  onSubmit?: (value: string) => void
}

export function Input({
  value,
  readonly,
  onChange,
  onSubmit,
  errorMessage,
  dirty,
  suffixIcon,
  errorPlacement,
  placeholder,
  onKeyUp,
}: InputProps) {
  const [isFocused, setIsFocused] = useState(false)

  // Consider empty string as "no error" because empty popover makes no sense
  const hasError = !!errorMessage

  return (
    <NotificationPopover
      hideIcon={true}
      open={hasError && isFocused}
      state={"error"}
      placement={errorPlacement ?? "top"}
      content={errorMessage}
    >
      <span>
        <StyledInput
          onFocus={() => setIsFocused(true)}
          onKeyUp={onKeyUp}
          placeholder={placeholder}
          status={hasError ? "error" : undefined}
          value={value}
          title={value}
          onChange={onChange}
          disabled={readonly}
          onPressEnter={(e) => onSubmit && onSubmit(e.currentTarget.value)}
          onBlur={(e) => {
            setIsFocused(false)
            return onSubmit && onSubmit(e.currentTarget.value)
          }}
          $dirty={dirty}
          // Switching from showing no icon to switching an icon (e.g. Error) results in focus loss due to different DOM-Layout
          // to avoid this we always use a invisible string as an icon if no other icon is specified
          suffix={hasError ? <Icon component={Regular.TriangleExclamation} /> : suffixIcon ?? " "}
        />
      </span>
    </NotificationPopover>
  )
}

const StyledInput = styled(AntInput)<{ $dirty?: boolean }>`
  ${({ $dirty, theme }) => $dirty && `background-color: ${theme.token.colorWarningBg};`}

  .ant-input-suffix {
    color: rgba(0, 0, 0, 0.25);
  }
  .ant-input {
    background-color: inherit;
  }
`
