import { registerTaskDefinition } from "@st4/tasks"
import * as taskdefinitions from "./taskdefinitions"

function isTaskDefinitionName(taskName: string): taskName is keyof typeof taskdefinitions {
  return Object.keys(taskdefinitions).includes(taskName)
}

export function getTaskDefinition(name: string) {
  if (isTaskDefinitionName(name)) {
    return taskdefinitions[name]
  } else {
    return null
  }
}

export function getAllTaskDefinitions() {
  return Object.values(taskdefinitions).sort((a, b) => {
    if (a.singleton && !b.singleton) return 1
    if (!a.singleton && b.singleton) return -1
    return 0
  })
}

export function registerTaskDefinitions() {
  Object.values(taskdefinitions).forEach((def) => registerTaskDefinition(def))
}
