import { useMemo } from "react"
import { isTypename, notEmpty } from "@st4/graphql"
import * as Types from "@st4/graphql"
import { TaxonomyDisplayInfo, TreeNode } from "~/ui/editors/taxonomyEditor/types"
import { assureOrderMatchesTreeOrder } from "~/ui/editors/taxonomyEditor/sortSelection"

export function useMemorizedInitialSelection(
  links:
    | Array<
        Omit<Types.NodeRef, "target" | "owner" | "linkClassName" | "class" | "id" | "__typename"> & {
          target?: Types.Maybe<
            | Pick<Types.St4Class, "__typename">
            | Pick<Types.CommentNode, "__typename">
            | Pick<Types.ExternalSite, "__typename">
            | Pick<Types.St4TreeNode, "__typename">
            | Pick<Types.St4Node, "nodeClass" | "id" | "label" | "__typename">
          >
        }
      >
    | undefined,
  isInherited: boolean | undefined,
  tree: TreeNode[] | undefined,
  loading: boolean,
  defaultValue?: TaxonomyDisplayInfo,
): Array<TaxonomyDisplayInfo> {
  return useMemo(
    () =>
      assureOrderMatchesTreeOrder(
        links
          ?.map((l) => l.target)
          .filter(notEmpty)
          .filter(isTypename("ST4Node"))
          .map((t) => ({
            value: t.id,
            label: t.label ?? "",
            closable: !isInherited,
          })) ?? (defaultValue ? [defaultValue] : []),
        tree,
      ),
    [links, loading],
  )
}
