import React from "react"
import JSONTree from "react-json-tree"

const theme = {
  scheme: "schema",
  base00: "transparent",
  base01: "#383830",
  base02: "#49483e",
  base03: "#75715e",
  base04: "#a59f85",
  base05: "#f8f8f2",
  base06: "#f5f4f1",
  base07: "#f9f8f5",
  base08: "#f92672",
  base09: "#fd971f",
  base0A: "#f4bf75",
  base0B: "#e2592e",
  base0C: "#a1efe4",
  base0D: "#009ee3",
  base0E: "#ae81ff",
  base0F: "#cc6633",
}

const Tree = ({ data }) => <JSONTree hideRoot={true} data={data} invertTheme={false} theme={theme} />

export default Tree
