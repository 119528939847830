import { hasTypename, isTypename } from "@st4/graphql"
import type {
  ImageDataFragment,
  ITextContentPreviewFragment,
  LexiconEntryDataFragment,
  LexiconTermContentFragment,
  SafetyConfigurationFragment,
  St4NodeWithContentFragment,
  TextContentWithFragmentsFragment,
  VariableTableContentFragment,
} from "./query.hooks"
import type { TreeNode as NewTreeNode } from "../../contentModel/nodeContext"

/**
 * @deprecated - handmade Types: Migrate to generated Types!
 */
export type GetContentResult = {
  contentOutline: { nodes?: NewTreeNode[]; pageInfo: PageInfo }
  me: User
  configuration: ConfigurationModel
}

/**
 * @deprecated - handmade Types: Migrate to generated Types!
 */
export type ConfigurationModel = {
  safetyModel: SafetyConfigurationFragment
  commentModel: CommentModel
  inlineStyleDefinitions: InlineStyleDefinition[]
  languageXmlValues: LabeledValue[]
}

/**
 * @deprecated - handmade Types: Migrate to generated Types!
 */
export type InlineStyleDefinition = {
  type: string
  displayName: string
  fontFamily: string
  fontStyle: string
  fontWeight: string
  fontSize: string
  textDecoration: string
  verticalAlign: string
  backgroundColor: string
  color: string
}

/**
 * @deprecated - handmade Types: Migrate to generated Types!
 */
export type TreeVariables = {
  selectedNodes: Array<string>
  aspectId: string
  contentVariant: string
  limit: number
}

/**
 * @deprecated - handmade Types: Migrate to generated Types!
 */
export type CommentModel = {
  availableTypes: LabeledValue[]
  availableStates: LabeledValue[]
}

/**
 * @deprecated - handmade Types: Migrate to generated Types!
 */
export type LabeledValue = {
  label: string
  value: string
}

/**
 * @deprecated - handmade Types: Migrate to generated Types!
 */
export type PageInfo = {
  hasNextPage: boolean
  endCursor: string
}

/**
 * @deprecated - handmade Types
 */
export type User = {
  username: string
  displayname: string
  commentColor: string
}
// New Types

type BareST4Node = Pick<St4NodeWithContentFragment, "id" | "__typename" | "label" | "aspectId">

type ContentTypes =
  | ITextContentPreviewFragment
  | VariableTableContentFragment
  | ImageDataFragment
  | LexiconEntryDataFragment
  | LexiconTermContentFragment
  | TextContentWithFragmentsFragment

export type ST4NodeWithContentName<TContentName extends ContentTypes["__typename"]> = BareST4Node & {
  content: Extract<ContentTypes, { __typename: TContentName }>
}

export type ST4NodeWithContent<TContent extends ContentTypes = any> = BareST4Node & {
  content?: TContent
}

export function isST4NodeWithContent<TContentName extends ContentTypes["__typename"][]>(...typeNames: TContentName) {
  function checkContentType(node: ST4NodeWithContent): node is ST4NodeWithContentName<TContentName[number]> {
    if (!node.content || typeof node.content !== "object") return false
    const content = node.content
    if (hasTypename(content)) return typeNames.some((t) => isTypename(t)(content))
    return false
  }

  return checkContentType
}
