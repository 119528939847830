import React from "react"
import { NotificationInfo } from "./types"
import { Blade, NotificationBanner } from "@st4/ui"

export type PoolBladeNotificationsProps = {
  notifications: NotificationInfo[]
}

export function PoolBladeNotifications({ notifications }: PoolBladeNotificationsProps) {
  return (
    <Blade.Infobar style={{ flex: "0 0 0px" }}>
      {notifications.map((notification) => {
        return (
          <NotificationBanner
            key={`${notification.id}-${notification.nodeId}`}
            state={notification.severity}
            title={notification.title}
            description={notification.description}
          />
        )
      })}
    </Blade.Infobar>
  )
}
