import React, { useCallback, useEffect, useState } from "react"
import { Blade, BladeDefinition } from "@st4/tasks"
import { NavigationTreeViewletWithState } from "~/components/navigationtree"
import { useAspectId } from "@st4/settings"
import { keys } from "@st4/ui-strings"
import { Translate } from "@st4/i18n"
import { QuanosBlueButton } from "~/ui/quanosbutton"
import { isTypename } from "~/../../../packages/st4-graphql/lib"
import styled from "styled-components"
import { VariablePoolTable } from "./variablePoolTable"
import { VariableTable } from "@st4/content-tools"
import { NotificationInfo } from "~/components/treeActions/types"
import { PoolBladeNotifications } from "~/components/treeActions/PoolBladeNotifications"
import { v4 as uuidv4 } from "uuid"
import { i18n } from "@schema/utils"

type VariablePoolTreeBladeProps = {
  variant: string
  selectedNode?: {
    id: string
    classHierarchy: string[]
  }
  allowedOperations: string[]
}

type Messages =
  | {
      action: "onNodeSelected"
      payload:
        | {
            id: string
            classHierarchy: string[]
          }
        | undefined
    }
  | {
      action: "onClose"
      payload: any
    }
  | {
      action: "insertVariable"
      payload: any
    }

export type VariableInfo = {
  name: string
  variant: string | null
}

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px;
`

const HorizontalContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
`

const PoolContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  width: 33%;
`

const VerticalLine = styled.div`
  width: 1px;
  background-color: #ccc;
  margin: 0 8px;
`

const ActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  width: 67%;
  flex-grow: 1;
`

const TableContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`

export const VariablePoolTreeBlade: BladeDefinition<VariablePoolTreeBladeProps, Messages> =
  function TreeBladeWithContext(props) {
    const aspectId = useAspectId()
    const [variableTable, setInternalVariableTable] = useState<VariableTable>()
    const [selectedVariable, setInternalSelectedVariable] = useState<VariableInfo>()
    const [notifications, setNotifications] = useState<NotificationInfo[]>([])

    useEffect(() => {
      const notificationInfo = getNotificationInfo(props.allowedOperations, props.selectedNode?.id ?? "")
      if (notificationInfo) setNotifications([...notificationInfo])
    }, [props.allowedOperations, props.selectedNode?.id, setNotifications])

    const setVariableTable = useCallback((newVariableTable: VariableTable) => {
      setInternalVariableTable(newVariableTable)
      setInternalSelectedVariable(undefined)
    }, [])

    const setSelectedVariable = useCallback((variableInfo?: VariableInfo) => {
      setInternalSelectedVariable(variableInfo)
    }, [])

    const insertVariableMsg: Messages = {
      action: "insertVariable",
      payload: {
        linkId: props.selectedNode?.id ?? "",
        variableTable: variableTable,
        selectedVariable: selectedVariable,
      },
    }

    const hasNotificationDisablingInsert = notifications.some((notification) => notification.disableInsert)

    return (
      <>
        <Blade.Content>
          <Container>
            <HorizontalContainer>
              <PoolContainer>
                <NavigationTreeViewletWithState
                  onSelect={(node) =>
                    props.sendMessage({
                      action: "onNodeSelected",
                      payload: node
                        ? {
                            id: node.id,
                            classHierarchy: isTypename("ST4Node")(node.node)
                              ? node.node.nodeClass?.classHierarchy ?? []
                              : [],
                          }
                        : undefined,
                    })
                  }
                  variant={props.variant}
                  aspectId={aspectId}
                  selected={props.selectedNode?.id}
                  includeNodeClassHierarchy
                  fetchPolicy="cache-and-network"
                />
              </PoolContainer>
              <VerticalLine />
              <ActionContainer>
                <TableContainer>
                  {props.selectedNode ? (
                    <VariablePoolTable
                      key={"variable.table." + props.selectedNode.id}
                      selectedNode={props.selectedNode}
                      aspectId={aspectId}
                      setVariableTable={setVariableTable}
                      setSelectedVariable={setSelectedVariable}
                    />
                  ) : (
                    <></>
                  )}
                </TableContainer>
              </ActionContainer>
            </HorizontalContainer>
          </Container>
        </Blade.Content>
        <PoolBladeNotifications notifications={notifications} />
        <Blade.Actionbar>
          <div />
          <QuanosBlueButton
            disabled={!selectedVariable || !variableTable || hasNotificationDisablingInsert}
            onClick={() => {
              props.sendMessage(insertVariableMsg)
            }}
          >
            <Translate>{keys.button.blade.insert}</Translate>
          </QuanosBlueButton>
        </Blade.Actionbar>
      </>
    )
  }

VariablePoolTreeBlade.title = <Translate>{keys.label.variablePoolBlade.title}</Translate>
VariablePoolTreeBlade.size = { S: 18, M: 13, L: 9 }
VariablePoolTreeBlade.reducer = function (previousState, message) {
  switch (message.action) {
    case "onNodeSelected":
      return { ...previousState, selectedNode: message.payload }
  }

  return previousState
}

function getNotificationInfo(allowedOperations: string[], selectedNodeId: string): NotificationInfo[] {
  if (allowedOperations && allowedOperations.find((x) => x === "insert-variable")) return []

  return [
    {
      id: uuidv4(),
      nodeId: selectedNodeId,
      title: "",
      description: i18n.t(keys.message.variablePoolBlade.not_possible).toString(),
      severity: "error",
      disableInsert: true,
    },
  ]
}
