import * as React from "react"
import { SVGProps } from "react"

const SvgSt4ChangeimageLight = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1em" height="1em" {...props}>
    <path d="m277 437.2-11.4 38.6-6.1 20.9c-1.2 4.2-.1 8.7 3 11.8s7.6 4.2 11.7 3l20.9-6.2 38.6-11.4c6.1-1.8 11.7-4.9 16.5-9.1.7-.6 1.3-1.2 2-1.9l150.5-150.4c10.8-10.8 12.2-27.5 4.1-39.8-1.1-1.7-2.5-3.4-4.1-4.9l-19.5-19.5c-12.4-12.4-32.4-12.4-44.8 0L288 418.7c-5.2 5.2-8.9 11.6-11 18.5zm22.8 6.7c.8-2.8 2.2-5.3 4.2-7.5.3-.3.5-.6.8-.9l113-113 30.7 30.7-113 113c-2.3 2.3-5.2 4.1-8.4 5l-11.6 3.4-27.1 8 8-27.1 3.4-11.6zm-78.1 4.1h-71.1l227.8-227.7c3.1-3.1 8.2-3.1 11.3 0l20.3 39.3 10.6-10.6c4.3-4.3 9.2-7.4 14.4-9.6l-22.7-41.7c-15.7-15.6-41-15.6-56.6 0L224 329.4l-67.7-67.7c-15.7-15.6-41-15.6-56.6 0L32 329.4V96c0-17.7 14.3-32 32-32h384c17.7 0 32 14.3 32 32v141.1c8.1 1.7 15.8 5.7 22.1 12l9.9 9.9V96c0-35.3-28.7-64-64-64H64C28.7 32 0 60.7 0 96v320c0 35.3 28.7 64 64 64h125.7l32-32zM64 448c-17.7 0-32-14.3-32-32v-41.4l90.4-90.3c3.1-3.1 8.2-3.1 11.3 0l67.7 67.7-96 96H64zm32-304c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48zm64 0c0 8.8-7.2 16-16 16s-16-7.2-16-16 7.2-16 16-16 16 7.2 16 16zm320 238v34c0 17.7-14.3 32-32 32h-34l-32 32h66c35.3 0 64-28.7 64-64v-66l-32 32z" />
  </svg>
)

export default SvgSt4ChangeimageLight
