import React from "react"
import { isTypename } from "@st4/graphql"
import type { PropertyEditor, MultiStringPropertyValue } from "../types"
import { useUpdatePropertyMutation, UpdatePropertyMutationVariables } from "../../query.hooks"
import { MultiStringEditor } from "~/ui/editors/multistringeditor"

function getInputValue(
  propertyEditor: PropertyEditor<MultiStringPropertyValue>,
  values: string[],
): UpdatePropertyMutationVariables["input"] {
  const input = {
    variantName: propertyEditor.variantName,
    displayName: propertyEditor.displayName,
    id: propertyEditor.id,
    node: { id: propertyEditor.node.id },
    origin: propertyEditor.origin,
    value: { multiStringValue: values },
  }
  return input
}

export function PropertyMultiStringEditor({
  propertyEditor,
  languageId,
  refetchProperties,
  refetchOnSave,
}: {
  propertyEditor: PropertyEditor<MultiStringPropertyValue>
  languageId: string
  refetchProperties: () => void
  refetchOnSave?: string[]
}) {
  const { isReadonly, value } = propertyEditor
  const [updateProperty, { data, error }] = useUpdatePropertyMutation({
    variables: {
      input: { ...propertyEditor, value: { multiStringValue: propertyEditor.value?.stringValues } },
    },
    refetchQueries: refetchOnSave,
  })

  const submitHandler = async (changedValues: string[]) => {
    updateProperty({
      variables: {
        input: getInputValue(propertyEditor, changedValues),
        languageId: languageId,
      },
      onCompleted: (_) => {
        refetchProperties()
      },
    })
  }

  const possibleErrorMessage = error
    ? error?.message
    : isTypename("Error")(data?.updateProperty)
    ? data?.updateProperty?.message
    : undefined

  return (
    <MultiStringEditor
      value={value?.stringValues ?? []}
      isReadOnly={isReadonly}
      onSubmit={submitHandler}
      errorMessage={possibleErrorMessage}
    />
  )
}
