import React from "react"
import {
  BladeProps,
  BladeWithTemporaryBladeProps,
  DataAttributes,
  DefaultBladeProps,
  isBladeWithTemporaryBlade,
  isFixedBlade,
  isTemporaryBlade,
} from "./types"
import styled from "styled-components"
import { ErrorBoundary } from "./errorBoundary"
import { Icon, Regular } from "@st4/icons"
import { DefaultBladeWithTemporaryBladeProps, FixedBladeProps, PersistentBladeProps } from "."

const TRANSITION_DURATION = "200ms"

const Container = styled.section<{
  collapsed: boolean
  size: BladeProps["size"]
  isFixed?: boolean
  temporary?: boolean
}>`
  background: ${({ theme: { token } }) => token.colorBgContainer};
  border: 1px solid ${({ theme: { token } }) => token.colorBorder};
  border-left-width: ${({ temporary, isFixed }) => (temporary || isFixed ? "0px" : "1px")};
  color: ${({ theme: { token } }) => token.colorText};
  border-top-right-radius: ${({ temporary }) => (temporary ? "12px" : "0px")};
  display: flex;
  flex-direction: column;
  transition: all ${TRANSITION_DURATION};
  overflow-x: auto;
  margin-left: ${({ temporary }) => (temporary ? "calc(-1 * var(--grid-gap))" : "0px")};

  @media (width <= ${({ theme: { token } }) => token.screenLGMax}px) {
    grid-column: span ${({ size }) => size.S};
  }
  @media (${({ theme: { token } }) => token.screenLGMax}px < width <= ${({ theme: { token } }) =>
      token.screenXXLMin}px) {
    grid-column: span ${({ size }) => size.M};
  }
  @media (${({ theme: { token } }) => token.screenXXLMin}px < width) {
    grid-column: span ${({ size }) => size.L};
  }

  /* Specificity Boost so collapsed size overrides media queries */
  && {
    ${({ collapsed }) => (collapsed ? `grid-column: span 1;` : ``)}
  }
`

const Title = styled.div<{ collapsed?: boolean; temporary?: boolean }>`
  flex: ${({ collapsed }) => (collapsed ? "1" : "0 0 40px")};
  border-bottom: 1px solid
    ${({ collapsed, theme: { token } }) => (collapsed ? "transparent" : token.colorBorderSecondary)};
  height: 44px;
  display: flex;
  flex-flow: ${({ collapsed }) => (collapsed ? "column-reverse" : "row")};
  align-items: center;
  padding: 12px;
  color: ${({ temporary, theme: { token } }) => (temporary ? token.colorPrimaryText : token.colorText)};
  background-color: ${({ theme: { token } }) => token.colorBgElevated};
  font-weight: 500;
`

const TitleText = styled.span<{ collapsed?: boolean }>`
  flex: 1;
  writing-mode: ${({ collapsed }) => (collapsed ? "vertical-lr" : "horizontal-tb")};
  padding-top: ${({ collapsed }) => (collapsed ? "5px" : "0")};
  font-size: 1.1em;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const CollapseIcon = styled.div<{ collapsed: boolean }>`
  transform: rotate(${({ collapsed }) => (collapsed ? "180deg" : "0deg")});
  transition: transform ${TRANSITION_DURATION};
  flex: 0;
  padding: 5px;
  cursor: pointer;
  color: ${({ theme: { token } }) => token.colorText};
`

const HiddenCollapsed = styled.div<{ collapsed?: boolean }>`
  opacity: ${({ collapsed }) => (collapsed ? 0 : 1)};
  overflow: hidden;
  flex: 1;
  display: ${({ collapsed }) => (collapsed ? "none" : "flex")};
  flex-direction: column;
  transition: opacity ${({ collapsed }) => (collapsed ? "0ms" : TRANSITION_DURATION)};
`

/**
 * Base component for a blade.
 * @param props {BladeProps}
 * @example
 * <Blade size="M" title="My Blade">
 *   <Blade.Filterbar>
 *     <Button>Filter S.th.</Button>
 *   </Blade.Filterbar>
 *
 *   <Blade.Toolbar>
 *     <Button>Do S.th.</Button>
 *   </Blade.Toolbar>
 *
 *   <Blade.Content>
 *     My Content.
 *   </Blade.Content>
 *
 *   <Blade.Actionbar>
 *      <Button>Do S.th.</Button>
 *   </Blade.Actioncar>
 * </Blade>
 */
export function Blade(props: BladeProps) {
  const { title, children } = props
  const isTemporary = isTemporaryBlade(props)
  const isFixed = isFixedBlade(props)
  const isCollapsed = !isFixed && !isTemporary && props.collapsed

  if (isBladeWithTemporaryBlade(props)) {
    return <BladeWithTemporaryBlade {...props} />
  }

  return (
    <Container
      collapsed={isCollapsed}
      size={props.size}
      isFixed={isFixed}
      temporary={isTemporary}
      style={{ marginLeft: 0, borderLeftWidth: 1 }}
      {...getDataAttrs(props)}
    >
      <Title collapsed={isCollapsed} temporary={isTemporary}>
        {isTemporary ? (
          <Icon component={Regular.ArrowRightFromBracket} rotate={180} style={{ marginRight: "8px" }} />
        ) : null}
        <TitleText collapsed={isCollapsed}>{title}</TitleText>
        {isTemporary ? (
          <CollapseIcon collapsed={false} onClick={() => props.onClose?.()}>
            <Icon component={Regular.XmarkLarge} />
          </CollapseIcon>
        ) : isFixed ? null : (
          <CollapseIcon onClick={() => props.onCollapse?.()} collapsed={isCollapsed}>
            <Icon component={Regular.ChevronsLeft} />
          </CollapseIcon>
        )}
      </Title>
      <HiddenCollapsed collapsed={isCollapsed}>
        <ErrorBoundary>{children}</ErrorBoundary>
      </HiddenCollapsed>
    </Container>
  )
}

function getDataAttrs(props: DataAttributes) {
  return Object.keys(props)
    .filter((k) => k.startsWith("data-"))
    .reduce((agg, curr) => ({ ...agg, [curr]: props[curr as keyof DataAttributes] }), {})
}

function BladeWithTemporaryBlade(props: BladeWithTemporaryBladeProps) {
  const { title, children } = props
  const isFixed = isFixedBlade(props)
  const isCollapsed = !isFixed && props.collapsed
  const tempBladeProps = props.temporaryBladeProps
  const temporaryBladeIcon = tempBladeProps.icon

  return (
    <>
      <Container collapsed={isCollapsed} size={props.size} isFixed={isFixed} {...getDataAttrs(props)}>
        <Title collapsed={isCollapsed}>
          <TitleText collapsed={isCollapsed}>{title}</TitleText>
          {isFixed ? null : (
            <CollapseIcon onClick={() => props.onCollapse?.()} collapsed={isCollapsed}>
              <Icon component={Regular.ChevronsLeft} />
            </CollapseIcon>
          )}
        </Title>
        <HiddenCollapsed collapsed={isCollapsed}>
          <ErrorBoundary>{children}</ErrorBoundary>
        </HiddenCollapsed>
      </Container>
      <Container collapsed={false} size={tempBladeProps.size} temporary {...getDataAttrs(tempBladeProps)}>
        <Title temporary>
          {temporaryBladeIcon ? (
            <Icon component={temporaryBladeIcon} style={{ marginRight: "8px" }} />
          ) : (
            <Icon component={Regular.ArrowRightFromBracket} rotate={180} style={{ marginRight: "8px" }} />
          )}
          <TitleText>{tempBladeProps.title}</TitleText>
          <CollapseIcon collapsed={false} onClick={() => props.onTemporaryBladeClose?.()}>
            <Icon component={Regular.XmarkLarge} />
          </CollapseIcon>
        </Title>
        <ErrorBoundary>{tempBladeProps.children}</ErrorBoundary>
      </Container>
    </>
  )
}

const Content = styled.div`
  flex: 1 1 1px;
  overflow: auto;
  position: relative;
`

const Infobar = styled.div`
  flex: 0 0 40px;
`

const Actionbar = styled.div`
  flex: 0 0 40px;
  padding: ${({ theme: { token } }) => `${token.paddingXS}px ${token.paddingSM}px`};
  background: ${({ theme: { token } }) => token.colorFillSecondary};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const Toolbar = styled.div`
  flex: 0 0 40px;
  padding: ${({ theme: { token } }) => `${token.paddingXS}px ${token.paddingSM}px`};
  background: ${({ theme: { token } }) => token.colorFillSecondary};
  border-bottom: 1px solid ${({ theme: { token } }) => token.colorBorderSecondary};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const Filterbar = styled.div`
  flex: 0 0 40px;
  padding: ${({ theme: { token } }) => `${token.paddingXS}px ${token.paddingSM}px`};
  background: ${({ theme: { token } }) => token.colorFillSecondary};
  border-bottom: 1px solid ${({ theme: { token } }) => token.colorBorderSecondary};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

/**
 * Component to render the filter bar inside the blade.
 *
 * This component renders as a flexbox.
 *
 * To comply to the ui-concept the `Blade.Filterbar`
 * should be the first child of `Blade`.
 */
Blade.Filterbar = Filterbar

/**
 * Component to render the toolbar inside the blade.
 *
 * This component renders as a flexbox.
 *
 * To comply to the ui-concept the `Blade.Toolbar`
 * should be following the `Blade.Filterbar` and
 * come beforde `Blade.Content`.
 */
Blade.Toolbar = Toolbar

/**
 * Component to render the content inside the blade.
 *
 * This component will take up all remaining vertical
 * space and show a scrollbar if its contents are larger.
 *
 * To comply to the ui-concept the `Blade.Content` should
 * be preceeded by `Blade.Filterbar` and `Blade.Toolbar`
 * (if applicable).
 */
Blade.Content = Content

/**
 * Component to render the infobar inside the blade.
 *
 * To comply to the ui-concept the `Blade.Infobar`
 * should be preceeded by 'Blade.Content' and
 * followed by 'Blade.Actionbar'
 */
Blade.Infobar = Infobar

/**
 * Component to render the actionbar inside the blade.
 *
 * This component renders as a flexbox.
 *
 * To comply to the ui-concept the `Blade.Actionbar`
 * should be the last child of `Blade`.
 */
Blade.Actionbar = Actionbar
