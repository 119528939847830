import styled from "styled-components"

export const StyledHeader = styled.span`
  white-space: nowrap;
  vertical-align: middle;
  line-height: 1.6em;

  .selectable {
    cursor: pointer;
  }
`
