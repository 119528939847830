import React, { useMemo, useState, useCallback } from "react"
import styled from "styled-components"
import { Spin, Popover } from "antd"
import { HttpLinkMetadataPopup, ST4LinkMetadataPopup } from "../linkMetadata"
import ReactDOM from "react-dom"
import { useFetch } from "../../../utilities/hooks"
import { StyledCard, ImageDescriptions, blockCommentBorderColor } from "./ImageDescriptions"
import type { Node } from "unist"
import type { ReactNode } from "react"
import { useScrollToFocusedComment } from "../../annotationFocusState"
import { useVectorGraphicsSvgQuery } from "./query.hooks"
import type { ExtractByTypeName } from "@st4/graphql"
import { isTypename } from "@st4/graphql"
import { usePreviewParameterContext } from "../../PreviewParameterContext"
import { LayoutInfo } from "./LayoutInfo"
import { ST4NodeWithContentName } from "../../../graphql/applicationQueries"

type LinkTarget = NonNullable<
  NonNullable<
    NonNullable<
      ExtractByTypeName<
        "VectorGraphicsMediaInfo",
        NonNullable<ST4NodeWithContentName<"MediaContent">["content"]["media"]>
      >["labels"]
    >[number]
  >["ref"]
>["target"]

function LinkPopover({ linkElement, target }: { linkElement: Element | null; target: LinkTarget }) {
  if (!linkElement || !target) {
    return null
  }
  const rect = linkElement.getBoundingClientRect()

  console.log(target)
  let info: ReactNode = null
  if (isTypename("ExternalSite")(target) && target.teaserData) {
    info = <HttpLinkMetadataPopup linkData={{ url: target.id }} />
  } else if (isTypename("ST4Node")(target) && target.properties) {
    info = <ST4LinkMetadataPopup linkNode={target} />
  }

  return ReactDOM.createPortal(
    <Popover open content={info}>
      <div
        style={{
          position: "fixed",
          top: `${rect.top}px`,
          left: `${rect.left}px`,
          width: `${rect.width}px`,
          height: `${rect.height}px`,
          border: "1px solid #f0f0f0",
        }}
      />
    </Popover>,
    document.body,
  )
}

const StyledVectorGraphicsMedia = styled.div<{
  $isCommented: boolean
  $isCommentFocused: boolean
  $isCommentPrefocused: boolean
}>`
  height: 500px;
  border: 1px solid
    ${({ theme, $isCommentFocused, $isCommentPrefocused, $isCommented }) =>
      blockCommentBorderColor($isCommented, $isCommentPrefocused, $isCommentFocused, theme)};
  svg {
    border-radius: 3px 3px 0 0;
    height: 100%;
    width: 100%;
  }
`

function handleLinkEvent(
  e: React.MouseEvent<HTMLDivElement>,
  setLinkElement: React.Dispatch<React.SetStateAction<Element | null>>,
) {
  const target = e.target
  if (target instanceof Element) {
    const link = target.closest("a")
    if (link) {
      setLinkElement(link)
    } else {
      setLinkElement(null)
    }
  }
}

function StyledVectorGraphicsView({
  svg,
  mediaInfo,
  isCommented,
  isCommentFocused,
  isCommentPrefocused,
}: {
  svg: string | null
  mediaInfo: ExtractByTypeName<
    "VectorGraphicsMediaInfo",
    NonNullable<ST4NodeWithContentName<"MediaContent">["content"]["media"]>
  >
  isCommented: boolean
  isCommentFocused: boolean
  isCommentPrefocused: boolean
}) {
  const [linkElement, setLinkElement] = useState<Element | null>(null)
  const _svg = useMemo(() => ({ __html: svg || "" }), [svg])
  const onClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.preventDefault()
      handleLinkEvent(e, setLinkElement)
    },
    [setLinkElement],
  )
  const onHover = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      handleLinkEvent(e, setLinkElement)
    },
    [setLinkElement],
  )
  let target = null
  if (linkElement) {
    const targetId = linkElement.getAttribute("xlink:href") || linkElement.getAttribute("data-target") || ""
    const hoveredLabel = mediaInfo.labels?.find((l) => l?.ref?.id === targetId)
    if (isTypename("ST4Node")(hoveredLabel?.ref?.target) || isTypename("ExternalSite")(hoveredLabel?.ref?.target)) {
      target = hoveredLabel?.ref?.target ?? null
    }
  }

  return (
    <>
      <StyledVectorGraphicsMedia
        onClick={onClick}
        onMouseOver={onHover}
        dangerouslySetInnerHTML={_svg}
        $isCommentFocused={isCommentFocused}
        $isCommented={isCommented}
        $isCommentPrefocused={isCommentPrefocused}
      />
      <LinkPopover target={target} linkElement={linkElement} />
    </>
  )
}

type VectorGraphicsMediaProps = {
  title: React.ReactNode
  xastNode?: Node
  legendNode?: Node
  mediaInfo: ExtractByTypeName<
    "VectorGraphicsMediaInfo",
    NonNullable<ST4NodeWithContentName<"MediaContent">["content"]["media"]>
  >
  mediaNode: Parameters<typeof ImageDescriptions>[0]["mediaNode"]
  comments: Parameters<ReturnType<typeof useScrollToFocusedComment>[2]["setFocusedAnnotations"]>[0]
  isGroup: boolean
}

export function VectorGraphicsMedia({
  title,
  xastNode,
  legendNode,
  mediaInfo,
  mediaNode,
  isGroup,
  comments,
}: VectorGraphicsMediaProps) {
  const previewParameterContext = usePreviewParameterContext()
  const { loading, data } = useVectorGraphicsSvgQuery({
    skip: !mediaInfo.resourceId, //don't run query if no resourceId
    variables: {
      resourceId: mediaInfo.resourceId ?? "",
      aspectSelectorId: previewParameterContext.languageId,
      filterId: previewParameterContext.filterId,
    },
  })
  let svgUrl = null
  if (
    isTypename("ST4Node")(data?.node) &&
    isTypename("MediaContent")(data?.node.content) &&
    isTypename("VectorGraphicsMediaInfo")(data?.node.content.media)
  ) {
    svgUrl = data?.node.content.media.svg ?? null
  }
  const [_, fetchedSvg] = useFetch(svgUrl)

  const isCommented = comments.length > 0
  const [scrollTargetProps, { isFocused, isPrefocused }] = useScrollToFocusedComment(
    comments.map(({ comment: { id } }) => id),
  )
  const cover = (
    <StyledVectorGraphicsView
      svg={fetchedSvg}
      mediaInfo={mediaInfo}
      isCommented={isCommented}
      isCommentFocused={isFocused}
      isCommentPrefocused={isPrefocused}
    />
  )
  const layoutInfo = <LayoutInfo mediaInfo={mediaInfo} xastNode={xastNode}></LayoutInfo>

  return (
    <Spin spinning={loading}>
      <StyledCard
        {...scrollTargetProps}
        cover={cover}
        $isGroup={isGroup}
        $isCommented={isCommented}
        $isCommentFocused={isFocused}
        $isCommentPrefocused={isPrefocused}
      >
        <ImageDescriptions
          mediaNode={mediaNode}
          title={title}
          labels={mediaInfo.labels}
          legendNode={legendNode}
          layoutInfo={layoutInfo}
        />
      </StyledCard>
    </Spin>
  )
}
