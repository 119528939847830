import { GenericScopeDefinition } from "../definition/scope"
import { TaskDefinition } from "../definition/task"

// export type RegisteredTaskDefinition = TaskDefinition

const taskDefinitions = new Map<string, TaskDefinition<GenericScopeDefinition>>()

export function registerTaskDefinition(definition: TaskDefinition) {
  // Falls TaskDefinition<ScopeDefinition> irgend wann mal entsprechende
  // Defaults für die Typparameter haben sollte, kann dieses `as` wieder weg.
  taskDefinitions.set(definition.name, definition as TaskDefinition<GenericScopeDefinition>)
}

export function unregisterTaskDefinition(name: string) {
  taskDefinitions.delete(name)
}

export function getTaskDefinition(name: string) {
  return taskDefinitions.get(name)
}

export function getAllTaskDefinitions() {
  return [...taskDefinitions].map((value) => value[1])
}
