import * as React from "react"
import { SVGProps } from "react"

const SvgSt4FileWarningRegular = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1em" height="1em" {...props}>
    <path d="M256 230c6.6 0 11 2.2 15.3 8.8l92 157.8c2.2 4.4 2.2 11 0 17.5-2.2 6.6-8.8 8.8-15.3 8.8H164c-6.6 0-11-4.4-15.3-8.8-2.2-4.4-2.2-11 0-17.5l92-157.8c4.3-4.4 8.7-8.8 15.3-8.8zm0 54.8c-6.6 0-11 4.4-11 11V344c0 6.6 4.4 11 11 11s11-4.4 11-11v-48.2c0-6.6-4.4-11-11-11zm13.1 96.4c0-6.6-6.6-13.1-13.1-13.1-6.6 0-13.1 6.6-13.1 13.1s6.6 13.1 13.1 13.1c6.6 0 13.1-6.5 13.1-13.1zm160.2-272-90.6-90.5C326.7 6.7 310.5 0 293.5 0H128C92.7 0 64 28.7 64 64v384c0 35.3 28.7 64 64 64h256c35.3 0 64-28.7 64-64V154.5c0-17-6.7-33.3-18.7-45.3zM400 448c0 8.8-7.2 16-16 16H128c-8.8 0-16-7.2-16-16V64c0-8.8 7.2-16 16-16h160v80c0 17.7 14.3 32 32 32h80v288z" />
  </svg>
)

export default SvgSt4FileWarningRegular
