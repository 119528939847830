import * as React from "react"
import { SVGProps } from "react"

const SvgCopy = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="1em" height="1em" {...props}>
    <path d="M384 352H224c-17.7 0-32-14.3-32-32V64c0-17.7 14.3-32 32-32h108.1c4.2 0 8.3 1.7 11.3 4.7l67.9 67.9c3 3 4.7 7.1 4.7 11.3V320c0 17.7-14.3 32-32 32zm49.9-270.1-67.8-67.8c-9-9-21.2-14.1-33.9-14.1H224c-35.3 0-64 28.7-64 64v256c0 35.3 28.7 64 64 64h160c35.3 0 64-28.7 64-64V115.9c0-12.7-5.1-24.9-14.1-33.9zM64 128c-35.3 0-64 28.7-64 64v256c0 35.3 28.7 64 64 64h160c35.3 0 64-28.7 64-64v-32h-32v32c0 17.7-14.3 32-32 32H64c-17.7 0-32-14.3-32-32V192c0-17.7 14.3-32 32-32h64v-32H64z" />
  </svg>
)

export default SvgCopy
