import React, { useCallback } from "react"
import styled from "styled-components"
import { usePreviewConfig } from "./PreviewConfig"
import { useSt4ToTreeNode } from "../utilities/treeUtilities"
import type { NodeFilterFunction } from "../ast/types"

export const VisibilityWrapper = styled.div<{ shouldBeVisible: boolean; isInline: boolean }>`
  display: ${(props) => (props.shouldBeVisible ? (props.isInline ? "inline" : "block") : "none")};
`

export function useTreeNodeFilter() {
  const config = usePreviewConfig()
  const treeNodeFilterFactory = useCallback(
    (treeNodeId: string | undefined) => (treeNodeId ? config.getNodeDisplay(treeNodeId) : { visible: false }),
    [config],
  )
  return treeNodeFilterFactory
}

export function useNodeFilter(): NodeFilterFunction {
  const st4NodeToTreeNode = useSt4ToTreeNode()
  const treeNodeFilter = useTreeNodeFilter()
  const filterFunction = useCallback(
    (st4Node) => treeNodeFilter(st4NodeToTreeNode(st4Node)?.id),
    [treeNodeFilter, st4NodeToTreeNode],
  )
  return filterFunction
}

export function FilteringTreeNodeWrapper({
  children,
  treeNodeId,
  isInline = false,
}: {
  children: React.ReactNode
  treeNodeId: string | undefined
  isInline?: boolean
}) {
  const treeNodeFilter = useTreeNodeFilter()
  if (isInline)
    return (
      <span data-scroll-target={treeNodeId}>
        <VisibilityWrapper isInline shouldBeVisible={treeNodeFilter(treeNodeId).visible}>
          {children}
        </VisibilityWrapper>
      </span>
    )

  return (
    <div data-scroll-target={treeNodeId}>
      <VisibilityWrapper isInline={false} shouldBeVisible={treeNodeFilter(treeNodeId).visible}>
        {children}
      </VisibilityWrapper>
    </div>
  )
}
