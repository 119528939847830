import * as React from "react"
import { SVGProps } from "react"

const SvgSt4CreatefragmentLight = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1em" height="1em" {...props}>
    <path d="M225.4 474.5H97.8c-15.3 0-30.6-12.8-30.6-30.6V61.2c0-15.3 12.8-30.6 30.6-30.6h125v112.2c0 28.1 23 48.5 48.5 48.5h112.2v33.2c12.8 2.6 23 7.7 33.2 12.8v-76.5c0-12.8-5.1-23-15.3-33.2L286.6 12.8C278.9 5.1 266.2 0 253.4 0H97.8C62.1 0 34.1 28.1 34.1 63.8v380.1c0 35.7 28.1 63.8 63.8 63.8h163.3c-15.4-10.3-25.6-20.5-35.8-33.2zm33.1-441.3c2.6 2.6 5.1 2.6 7.7 5.1L381 153s2.6 2.6 5.1 7.7H273.8c-7.7 0-15.3-7.7-15.3-15.3V33.2zm183.7 249.9c-53.6-53.6-135.2-53.6-188.8 0s-53.6 135.2 0 188.8 135.2 53.6 188.8 0c51-53.6 51-135.2 0-188.8zM424.3 454c-40.8 40.8-109.7 40.8-150.5 0s-40.8-109.7 0-150.5 109.7-40.8 150.5 0 40.8 107.2 0 150.5zm-147.9-89.2c-7.7 0-12.8 5.1-12.8 12.8s5.1 12.8 12.8 12.8H335v58.7c0 7.7 5.1 12.8 12.8 12.8s12.8-5.1 12.8-12.8v-58.7h58.7c7.7 0 12.8-5.1 12.8-12.8s-5.1-12.8-12.8-12.8h-58.7v-58.7c0-7.7-5.1-12.8-12.8-12.8s-12.8 5.1-12.8 12.8v58.7h-58.6zm-40.8-158.2c-5.1-2.6-12.8-2.6-20.4 0l-96.9 33.2c-12.8 5.1-20.4 17.9-20.4 30.6v122.4c0 12.8 7.7 25.5 20.4 33.2l96.9 35.7c-7.3-12.2-13.4-27.9-17.9-41.3l-61.6-24V297l61.6 25.8 1.5.5c3.7-11.4 7.3-17.7 13.8-27.4l-76.5-28.1 89.3-30.6 33.2 12.8c12.8-7.7 25.5-15.3 38.3-20.4l-61.3-23zm-38.3 116.2 1.5.5c3.7-11.4 7.3-17.7 13.8-27.4" />
  </svg>
)

export default SvgSt4CreatefragmentLight
