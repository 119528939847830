import { Tooltip } from "antd"
import React from "react"
import styled from "styled-components"
import type { Node } from "unist"
import { Typography } from "antd"
import { useTranslation } from "react-i18next"
import { keys } from "@st4/ui-strings"
import { usePreviewContentModel } from "../../contentModel"
import { Icon, Regular } from "@st4/icons"

const { Text } = Typography

type EmphasisStylesProps = {
  fontFamily?: string
  fontStyle?: string
  fontWeight?: string
  fontSize?: string
  textDecoration?: string
  verticalAlign?: string
  backgroundColor?: string
  color?: string
}

const EmphasisStyles = styled.span<EmphasisStylesProps>`
  display: inline;
  white-space: pre-wrap;
  font-family: ${({ fontFamily }) => fontFamily};
  font-style: ${({ fontStyle }) => fontStyle};
  font-weight: ${({ fontWeight }) => fontWeight};
  font-size: ${({ fontSize }) => fontSize};
  text-decoration: ${({ textDecoration }) => textDecoration};
  vertical-align: ${({ verticalAlign }) => verticalAlign};
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ color }) => color};
`
EmphasisStyles.displayName = "EmphasisStyles"
type EmphasisTooltipProps = {
  displayName: string
}
export function EmphasisTooltip(props: React.PropsWithChildren<EmphasisTooltipProps>) {
  const { t } = useTranslation()
  const { children, displayName } = props
  return <Tooltip title={t(keys.label.preview.emphasis, { displayName })}>{children}</Tooltip>
}

type EmphasisDisplayProps = {
  type: string
  displayName?: string
} & EmphasisStylesProps
/**
 * Display styled inline content.
 * @param props
 */
export function EmphasisDisplay(props: React.PropsWithChildren<EmphasisDisplayProps>) {
  const { t } = useTranslation()
  const { type, displayName, children, ...styles } = props
  return (
    <EmphasisTooltip displayName={displayName || type}>
      <span>
        <EmphasisStyles {...styles}>{children}</EmphasisStyles>
      </span>
    </EmphasisTooltip>
  )
}

type EmphasisElementProps = {
  type: string
  ast: Node
}
/**
 * Component to display <emphasis> content using style information from st4.
 * @param props
 */
export function EmphasisElement(props: React.PropsWithChildren<EmphasisElementProps>) {
  const { t } = useTranslation()
  const type = props.ast.attributes?.["type"]?.value ?? ""

  const previewModel = usePreviewContentModel()
  const inlineStyles = (previewModel.state === "ready" && previewModel.configuration.inlineStyleDefinitions) || []
  const emphasisStyles = inlineStyles.find((s) => s.type === type) || {}

  if (!Object.keys(emphasisStyles).length)
    return (
      <Tooltip title={t(keys.message.preview.emphasisNotFound, { type })}>
        <Text type="danger" style={{ whiteSpace: "pre" }}>
          <Icon component={Regular.TriangleExclamation} style={{ fontSize: "0.8em" }} /> {props.children}
        </Text>
      </Tooltip>
    )
  return (
    <EmphasisDisplay type={type} {...emphasisStyles}>
      {props.children}
    </EmphasisDisplay>
  )
}
