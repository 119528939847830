import { ThemeManagerTheme } from "./quanosTheme"
import Colors from "./quanosColors"

const _themes = new Map<string, ThemeManagerTheme>()

export default {
  register(name: string, theme: ThemeManagerTheme) {
    if (!_themes.has(name)) {
      _themes.set(name, theme)
    }
  },
  find(name: string) {
    const theme = _themes.get(name)
    if (theme) {
      return theme
    } else {
      throw `Error: could not find theme ${name}`
    }
  },
  quanosColors: Colors,
}
