import * as React from "react"
import { SVGProps } from "react"

const SvgBorderOuter = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="1em" height="1em" {...props}>
    <path d="M64 80c-8.8 0-16 7.2-16 16v320c0 8.8 7.2 16 16 16h320c8.8 0 16-7.2 16-16V96c0-8.8-7.2-16-16-16H64zM0 96c0-35.3 28.7-64 64-64h320c35.3 0 64 28.7 64 64v320c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zm224 96a32 32 0 1 1 0-64 32 32 0 1 1 0 64zm-64 64a32 32 0 1 1-64 0 32 32 0 1 1 64 0zm64 32a32 32 0 1 1 0-64 32 32 0 1 1 0 64zm128-32a32 32 0 1 1-64 0 32 32 0 1 1 64 0zM224 384a32 32 0 1 1 0-64 32 32 0 1 1 0 64z" />
  </svg>
)

export default SvgBorderOuter
