import { useLanguage } from "@st4/settings"
import { useCreateVersionAllowedLazyQuery, useCreateVersionMutation } from "./query.hooks"
import { isTypename } from "@st4/graphql"
import { useCallback } from "react"
import { useTranslation } from "react-i18next"
import { keys } from "@st4/ui-strings"

export type CreateVersionResult = { newNodeId: string | null; errorMessage: string | null }

export function useCreateVersion() {
  const { languageId } = useLanguage()
  const { t: translate } = useTranslation()
  const [mutate, { loading }] = useCreateVersionMutation()

  const createVersion: (nodeId: string) => Promise<CreateVersionResult> = useCallback(
    (nodeId: string) => {
      return mutate({
        variables: { languageId: languageId, target: nodeId },
        refetchQueries: ["NavigationTree"],
      })
        .then((value) => {
          if (value.errors) {
            return { errorMessage: value.errors.map((error) => error.message).join(", "), newNodeId: null }
          }
          if (!value.data) throw "Data has to be filled if no errors occured"

          if (isTypename("CommandExecutionFailure")(value.data.executeCommand)) {
            return {
              errorMessage:
                value.data.executeCommand.reasons?.join(", ") ??
                translate(keys.message.error.generic) ??
                "Unexpected error",
              newNodeId: null,
            }
          }

          if (!isTypename("CommandExecutionPatternContextSuccess")(value.data.executeCommand))
            throw "Mutation changed without corresponding code changes"
          if (!value.data.executeCommand.result || value.data.executeCommand.result.length == 0)
            throw "Mutation returned insufficient data"

          return {
            errorMessage: null,
            newNodeId: value.data.executeCommand.result[0].toString(),
          }
        })
        .catch((rejectionReason) => {
          console.log(rejectionReason)
          return { errorMessage: translate(keys.message.error.generic), newNodeId: null }
        })
    },
    [languageId, mutate, translate]
  )

  return { createVersion, loading }
}

export function useCreateVersionAllowed() {
  const { languageId } = useLanguage()
  const [query] = useCreateVersionAllowedLazyQuery()

  const queryCreateVersionAllowed = useCallback(
    (nodeId: string) => {
      return query({ variables: { languageId: languageId, target: nodeId }, fetchPolicy: "network-only" })
        .then((value) => {
          if (value.loading) throw "query should be finished before continue occurs"

          return isTypename("AllowedCommand")(value.data?.commands[0])
        })
        .catch((rejectionReason) => {
          console.log(rejectionReason)
          return false
        })
    },
    [languageId, query]
  )

  return queryCreateVersionAllowed
}
