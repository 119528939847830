import React from "react"
import { ConfirmBanner, NotificationBanner } from "@st4/ui"
import { useReducer, useCallback, useEffect } from "react"
import { ShowNotification, ShowConfirm } from "@st4/message-hub"

type BannerDisplay =
  | {
      type: "notification"
      closeNotification: () => void
      bannerProps: React.ComponentProps<typeof NotificationBanner>
    }
  | {
      type: "confirm"
      rejectPromise: () => void
      bannerProps: React.ComponentProps<typeof ConfirmBanner>
    }

type ReducerState = BannerDisplay[]
type ReducerAction =
  | { type: "openNotification"; banner: Extract<BannerDisplay, { type: "notification" }> }
  | { type: "openConfirm"; banner: Extract<BannerDisplay, { type: "confirm" }> }
  | { type: "closeCurrent" }

export function useBladeBannerState() {
  const [stack, dispatch] = useReducer((prevState: ReducerState, action: ReducerAction) => {
    // maybe the handling of multiple banners should/could be improved
    // current handling: Banners are queued and displayed one after another
    switch (action.type) {
      case "openConfirm":
        return [...prevState, action.banner]
      case "openNotification":
        return [...prevState, action.banner]
      case "closeCurrent":
        return prevState.slice(1)
    }
  }, [])

  const currentBanner = stack[0]

  const showNotification = useCallback<ShowNotification>((state, title, description) => {
    dispatch({
      type: "openNotification",
      banner: {
        type: "notification",
        bannerProps: {
          state: state,
          title,
          description,
          onClose() {
            dispatch({ type: "closeCurrent" })
          },
        },
        closeNotification() {
          dispatch({ type: "closeCurrent" })
        },
      },
    })
  }, [])

  const showConfirm = useCallback<ShowConfirm>(
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint -- required, else <TValue> is interpreted as JSX
    <TValue extends unknown>(
      state: "info" | "warning" | "error" | "question",
      title: string,
      description: string,
      primary: { label: React.ReactNode; value: TValue },
      secondary?: { label: React.ReactNode; value: TValue },
    ) => {
      return new Promise<TValue>((resolve, reject) => {
        dispatch({
          type: "openConfirm",
          banner: {
            type: "confirm",
            bannerProps: {
              state,
              title,
              primaryButtonLabel: primary.label,
              onClickPrimary() {
                resolve(primary.value)
                dispatch({ type: "closeCurrent" })
              },
              secondaryButtonLabel: secondary?.label ?? "",
              onClickSecondary() {
                //secondary is optional for state == `info`
                secondary && resolve(secondary.value)
                dispatch({ type: "closeCurrent" })
              },

              description,
            },
            rejectPromise: reject,
          },
        })
      })
    },
    [],
  )

  useEffect(() => {
    if (currentBanner?.type === "notification") {
      // close the notification after 10 seconds
      const handle = setTimeout(() => {
        currentBanner.closeNotification()
      }, 10 * 1000)

      return () => clearTimeout(handle)
    }
    if (currentBanner?.type === "confirm") {
      // reject the promise on unmount
      // Promise-Rejection does nothing if the promise resolved. Since we Resolve, before we change the currentBanner,
      // nothing bad happens even though the rejection is called after button press
      return () => (currentBanner?.type === "confirm" ? currentBanner.rejectPromise() : undefined)
    }
  }, [currentBanner])

  return [currentBanner, { showNotification, showConfirm }] as const
}

export function BladeBanner(props: { state?: ReturnType<typeof useBladeBannerState>[0] }) {
  const { state } = props
  switch (state?.type) {
    case "notification":
      return <NotificationBanner {...state.bannerProps} />
    case "confirm":
      return <ConfirmBanner {...state.bannerProps} />
    default:
      return null
  }
}
