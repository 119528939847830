import type { Node } from "unist"
import type { ColumnGroupType, ColumnsType, ColumnType } from "antd/lib/table"
import type { HeaderAreas, Indexer, KnownTableSize } from "./types"
import React, { useMemo } from "react"
import find from "unist-util-find"
import { filter } from "unist-util-filter"
import { XASTViewer } from "../XASTViewer"
import { TableContainer } from "./TableContainer"

/**
 * Creates the antd table column configuration based on the passed table.
 * @param table The tables Node
 */
function getColumnConfiguration(table: Node, widths: ReturnType<typeof getTableSize>) {
  const body = find(table, { tagName: "tbody" })
  const header = find(table, { tagName: "thead" })
  const row = find(header || body, { tagName: "tr" })
  const columns = row.children
  if (!columns) throw new Error("Cannot determine columns!")

  const columnWidths = getRelativeColumnWidths(widths)

  if (columns && columns[0].tagName === "td") {
    return getColumnsWithoutHeader(columnWidths)
  } else {
    return getColumnsWithHeader(columnWidths, header)
  }
}

/**
 * Calculates the width of the columns in percent.
 * Based on the hsdl-cm/hsdl-percent attributes on the table.
 * @param table The xast of the table with the hsdl-percent/hsdl-cm attributes.
 */
function getRelativeColumnWidths(widths: ReturnType<typeof getTableSize>) {
  const [type, w] = widths
  let widthPercents = new Array<number>()
  if (type === "unknown") {
    return []
  }
  const widthValues = w!.map((w) => w.value) //w cannot be undefined, because we checked for unknown earlier
  if (type === "scaled") {
    const sum = widthValues.reduce((agg, curr) => agg + curr, 0)
    widthPercents = widthValues.map((w) => (w / sum) * 100)
  } else if (type === "fixed") {
    widthPercents = widthValues
  }
  return widthPercents.map((w) => ({ width: `${w}%` }))
}

/**
 * Crates the antd table columns configuration for tables without a header.
 * @param columnwidths Array containing the Widths of all columns
 */
function getColumnsWithoutHeader(columnwidths: Array<{ width: string }>): ColumnsType<Node[]> {
  return columnwidths.map((width, i) => ({
    ...width,
    render: renderCell, // AntD displays an Error warning to return props and children from `render`... But theres currently no way to calculate Cell-Based ColSpan using onCell https://github.com/ant-design/ant-design/issues/33093
    dataIndex: i,
  }))
}

/**
 * Crates the antd table columns configuration for tables with a header.
 * @param columnwidths Array containing the Widths of all columns
 * @param theadXast The thead Node
 */
function getColumnsWithHeader(columnwidths: Array<{ width: string }>, theadXast: Node): ColumnType<Node[]>[] {
  const rows = theadXast.children?.filter((c) => c.tagName === "tr") || []
  const headerRowCount = rows.length
  const headerColCount = columnwidths.length
  const headerAreas: ([number, number] | null)[][] = [...new Array(headerRowCount)].map((_) =>
    [...new Array(headerColCount)].map((_) => null),
  )

  for (let tableRowIdx = 0; tableRowIdx < rows.length; tableRowIdx++) {
    const tableHeadRow = rows[tableRowIdx]
    const tableHeadColumns = tableHeadRow.children?.filter((c) => c.tagName === "th") || []
    for (let tableColumnIdx = 0; tableColumnIdx < tableHeadColumns.length; tableColumnIdx++) {
      const tableHeaderCell = tableHeadColumns[tableColumnIdx]
      const colspan = parseInt(tableHeaderCell.attributes?.colspan?.value || "1")
      const rowspan = parseInt(tableHeaderCell.attributes?.rowspan?.value || "1")
      const currentIndex: [number, number] = [tableRowIdx, tableColumnIdx]
      const areaStartColumn = headerAreas[tableRowIdx].indexOf(null)
      for (let areaRow = tableRowIdx; areaRow < rowspan + tableRowIdx; areaRow++) {
        for (let areaColumn = areaStartColumn; areaColumn < areaStartColumn + colspan; areaColumn++) {
          headerAreas[areaRow][areaColumn] = currentIndex
        }
      }
    }
  }
  // The headerAreas contains a Mapping of Node(indizes) to location
  // |  H1  |  H2  |   Grouped   |  H3  |
  // |      |      |  H4  |  H5  |      |
  //
  //  <tr><th rs=2>H1</th><th rs=2>H2</th><th cs=2>Grouped</th><th rs=2>H3</th></tr>
  //  <tr><th>H4</th><th>H5</th></tr>
  //
  // [       (col)  (col)  (colspan 2 )  (col)
  //  (tr) [ [0,0], [0,1], [0,2], [0,2], [0,3] ] --> The Tuple contains the index of the child inside thead xast (row)
  //  (tr) [ [0,0], [0,1], [1,0], [1,1], [0,3] ]     and the index inside the tr xast (cell).
  // ]
  //
  // It might help to visualize the areas as follows:
  // H1 Grouped Grouped H3
  // H1 H4      H5      H3

  if (!areHeaderAreasValid(headerAreas)) throw new Error("Error during extraction of column Headers!")
  const groupedConfig = createHeaderColumns(headerAreas, theadXast, { index: 0 }, columnwidths)
  return groupedConfig
}

/**
 * Creates the column configuration for tables with headers
 * @param headerAreas The calculated header areas
 * @param thead the thead Node
 * @param dataIndexer A data indexer
 * @param columnwidths The configured widths of the table
 */
function createHeaderColumns(
  headerAreas: HeaderAreas,
  thead: Node,
  dataIndexer: Indexer,
  columnwidths: Array<{ width: string }>,
): ColumnsType<Node[]> {
  if (headerAreas.length == 0) return []
  const columns = headerAreas[0]
  const columnDefs = new Array<ColumnGroupType<Node[]> | ColumnType<Node[]>>()
  for (let colIdx = 0; colIdx < columns.length; colIdx++) {
    const headerColumn = columns[colIdx]
    const xastRow = thead.children && thead.children[headerColumn[0]]
    const xastCellContent = xastRow?.children && xastRow.children[headerColumn[1]]?.children
    const data = {
      selector: headerColumn,
      title: (
        <>
          {(xastCellContent || []).map((ast) => (
            <XASTViewer xast={ast} key={JSON.stringify(ast.position)} />
          ))}
        </>
      ),
    }
    if (columns.filter((p) => p === headerColumn).length > 1) {
      // The row contains more than one area for the current header cell,
      // therefore this header is colspanned.
      const start = colIdx
      const end = columns.lastIndexOf(headerColumn)
      if (headerAreas.length == 1) {
        // The header is spanned but has no children columns.
        // We need to add the header as single column with ColSpan
        // and add additional columns without a header.
        const spannedColumns = end - start
        columnDefs.push({
          ...data,
          render: renderCell,
          dataIndex: dataIndexer.index,
          ...columnwidths[dataIndexer.index],
          colSpan: spannedColumns + 1,
        })
        dataIndexer.index = dataIndexer.index + 1
        ;[...new Array(spannedColumns)].forEach((_) => {
          columnDefs.push({
            ...data,
            colSpan: 0,
            dataIndex: dataIndexer.index,
            ...columnwidths[dataIndexer.index],
            render: renderCell,
          })
          dataIndexer.index = dataIndexer.index + 1
        })
      } else {
        // The header contains children and should be an antd group.

        let areasInsideGroup = headerAreas.map((r) => r.slice(start, end + 1)).slice(1)
        // If the current group spans multiple rows, we remove all additional
        // rows containing the same information as allready processed.
        areasInsideGroup = areasInsideGroup.filter((a) => !a.every((v) => v === headerColumn))

        columnDefs.push({
          children: createHeaderColumns(areasInsideGroup, thead, dataIndexer, columnwidths),
          ...data,
        })
      }
      colIdx = end // skip all areas containing the same header cell
    } else if (headerAreas.every((h) => h[colIdx] === headerColumn)) {
      //check if all areas below contain the same cell
      columnDefs.push({
        ...data,
        render: renderCell,
        dataIndex: dataIndexer.index,
        ...columnwidths[dataIndexer.index],
      })
      dataIndexer.index = dataIndexer.index + 1 //since we added a new, true, column, we increment the data accessor.
    } else if (headerAreas.length > 1) {
      //we got several header rows with no rowspan/colspan in the first row
      const multipleColumnDef: (typeof columnDefs)[number][] = [
        {
          ...data,
          render: renderCell,
          dataIndex: dataIndexer.index,
          ...columnwidths[dataIndexer.index],
        },
      ]

      for (let index = 1; index < headerAreas.length; index++) {
        const column = headerAreas[index]
        const headerColumn = column[colIdx]

        if (headerColumn === headerAreas[index - 1][colIdx]) continue

        const xastRow = thead.children && thead.children[headerColumn[0]]
        const xastCellContent = xastRow?.children && xastRow.children[headerColumn[1]]?.children
        const data = {
          selector: headerColumn,
          title: (
            <>
              {(xastCellContent || []).map((ast) => (
                <XASTViewer xast={ast} key={JSON.stringify(ast.position)} />
              ))}
            </>
          ),
        }

        multipleColumnDef.unshift({
          ...data,
          render: renderCell,
          dataIndex: dataIndexer.index,
          ...columnwidths[dataIndexer.index],
        })
      }

      const headerWithAllRows = multipleColumnDef.reduce<(typeof multipleColumnDef)[number] | null>((agg, cur) => {
        if (agg === null) return cur
        else return { ...cur, children: [agg] }
      }, null)

      if (headerWithAllRows) {
        columnDefs.push(headerWithAllRows)
        dataIndexer.index = dataIndexer.index + 1
      }
    }
  }
  return columnDefs
}

/**
 * Checks if all header areas contain a value.
 * @param headerAreas
 */
function areHeaderAreasValid(headerAreas: ([number, number] | null)[][]): headerAreas is HeaderAreas {
  return headerAreas.every((cells) => cells.every((cell) => cell !== null))
}

/**
 * Renders the Node of the table cell, respecting col- and rowspans
 * @param value The Node of the corresponding `td`
 * @param record The XASTNodes of the whole row
 * @param index The data Index
 */
function renderCell(value: Node, record: Node[], index: number) {
  return {
    props: {
      colSpan: value ? parseInt(value.attributes?.colspan?.value || "1") : 0,
      rowSpan: parseInt(value?.attributes?.rowspan?.value || "1"),
    },
    children: (
      <>{value?.children?.map((cxast) => <XASTViewer xast={cxast} key={JSON.stringify(cxast.position)} />) || null}</>
    ),
  }
}

/**
 * Returns the data of the tables body.
 * @param tbody the tbody Node
 */
function getData(tbody: Node) {
  const rows = tbody.children || []
  return rows.map(({ children: td }) => getDataForRow(td))
}
/**
 * Returns normalized data for table cells.
 * @param tds The tds of the current row
 */

function getDataForRow(tds?: Node[]) {
  return tds || []
}

/**
 * Raturns the columnConfiguration and data array for the passed table container.
 * @param tableContainer The xast node of the table container
 */
export function getColumnsAndTableData(tableContainer: Node, widths: ReturnType<typeof getTableSize>) {
  const table = find(tableContainer, { tagName: "table" })
  const body = find(table, { tagName: "tbody" })
  const header = find(table, { tagName: "thead" })
  const columns = getColumnConfiguration(table, widths)
  const data = getData(body)
  return [columns, data] as [typeof columns, typeof data]
}

/**
 * Returns the configured columnsizes
 * @param tableContainer The xast node of the table container
 */
function getTableSize(tableContainer: Node): [KnownTableSize, { label: string; value: number }[]] | ["unknown"] {
  const table = find(tableContainer, { tagName: "table" })
  const attributes = table?.attributes
  if (!attributes) return ["unknown"]

  const type = attributes["type"]?.value ?? "unknown"
  if (!isKnownTableSize(type)) return ["unknown"]
  const percent = attributes["hsdl-percent"]?.value
  const cm = attributes["hsdl-cm"]?.value
  const values = (percent || cm || "")
    .split(" ")
    .map(parseFloat)
    .filter((v) => !isNaN(v))

  return [type, values.map((v) => ({ label: `${v} ${percent ? "%" : cm ? "cm" : ""}`, value: v }))]
}

function isKnownTableSize(size: string): size is KnownTableSize {
  return ["fixed", "scaled"].includes(size)
}

interface TableMapperProps {
  ast: Node
}

export function TableMapper(props: TableMapperProps) {
  const trimmedAst: Node | null = filter(
    props.ast,
    (node: Node) =>
      !(
        ["tr", "td", "th", "thead", "tbody", "table"].includes(node.parent?.tagName || "") &&
        node.nodeType === "Whitespace"
      ),
  )
  const comments = props.ast.data?.blockcomments || []
  if (!trimmedAst) throw new Error("Error during table extraction from AST.")
  const tableSize = getTableSize(trimmedAst)

  //TODO: dirty fix. empty data suggests incorrect filtering or some other mistake before the xast node gets here. this needs to be fixed in another ticket
  let cols: ColumnsType<Node[]>, data: Node[][] | undefined

  try {
    // eslint-disable-next-line @typescript-eslint/no-extra-semi, no-extra-semi
    ;[cols, data] = useMemo(() => getColumnsAndTableData(trimmedAst, tableSize), [tableSize])
  } catch (error) {
    cols = []
    data = undefined
  }

  const titleXast = find(trimmedAst, { tagName: "table-title" })
  const borderType = trimmedAst.attributes?.type?.value || ""
  const [tableSizeType, columnWidths] = tableSize

  //TODO: dirty fix related to STDM-39462. empty data suggests incorrect filtering or some other mistake before the xast node gets here. this needs to be fixed in another ticket
  if (data) {
    return (
      <TableContainer
        columnWidths={columnWidths || []}
        columns={cols}
        data={data}
        borderType={borderType}
        titleXast={titleXast}
        tableSizeType={tableSizeType}
        comments={comments}
      />
    )
  } else return <></>
}
