import * as React from "react"
import { SVGProps } from "react"

const SvgSt4ProductionCollectionsFolderSolid = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1em" height="1em" {...props}>
    <path d="M163.8 376.3c0 10.2 7.7 17.9 17.9 17.9h145.9v-35.8H181.8c-10.3 0-18 7.7-18 17.9zM448 97.3H299.5c-17.9 0-33.3-7.7-46.1-20.5l-25.6-25.6c-12.8-12.8-28.2-20.5-46.1-20.5H64c-35.8 0-64 30.7-64 66.6v320c0 35.8 28.2 64 64 64h384c35.8 0 64-28.2 64-64v-256c0-35.9-28.2-64-64-64zm-64 243.2c0 10.2-7.7 17.9-17.9 17.9v35.8c10.2 0 17.9 7.7 17.9 17.9s-7.7 17.9-17.9 17.9H181.8c-30.7 0-53.8-25.6-53.8-53.8V194.6c0-30.7 25.6-53.8 53.8-53.8H363.5c10.2 0 17.9 7.7 17.9 17.9v181.8h2.6z" />
  </svg>
)

export default SvgSt4ProductionCollectionsFolderSolid
