function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) {
    try {
        var info = gen[key](arg);
        var value = info.value;
    } catch (error) {
        reject(error);
        return;
    }
    if (info.done) {
        resolve(value);
    } else {
        Promise.resolve(value).then(_next, _throw);
    }
}
function _asyncToGenerator(fn) {
    return function() {
        var self = this, args = arguments;
        return new Promise(function(resolve, reject) {
            var gen = fn.apply(self, args);
            function _next(value) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value);
            }
            function _throw(err) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err);
            }
            _next(undefined);
        });
    };
}
var __generator = this && this.__generator || function(thisArg, body) {
    var f, y, t, g, _ = {
        label: 0,
        sent: function() {
            if (t[0] & 1) throw t[1];
            return t[1];
        },
        trys: [],
        ops: []
    };
    return g = {
        next: verb(0),
        "throw": verb(1),
        "return": verb(2)
    }, typeof Symbol === "function" && (g[Symbol.iterator] = function() {
        return this;
    }), g;
    function verb(n) {
        return function(v) {
            return step([
                n,
                v
            ]);
        };
    }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while(_)try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [
                op[0] & 2,
                t.value
            ];
            switch(op[0]){
                case 0:
                case 1:
                    t = op;
                    break;
                case 4:
                    _.label++;
                    return {
                        value: op[1],
                        done: false
                    };
                case 5:
                    _.label++;
                    y = op[1];
                    op = [
                        0
                    ];
                    continue;
                case 7:
                    op = _.ops.pop();
                    _.trys.pop();
                    continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                        _ = 0;
                        continue;
                    }
                    if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
                        _.label = op[1];
                        break;
                    }
                    if (op[0] === 6 && _.label < t[1]) {
                        _.label = t[1];
                        t = op;
                        break;
                    }
                    if (t && _.label < t[2]) {
                        _.label = t[2];
                        _.ops.push(op);
                        break;
                    }
                    if (t[2]) _.ops.pop();
                    _.trys.pop();
                    continue;
            }
            op = body.call(thisArg, _);
        } catch (e) {
            op = [
                6,
                e
            ];
            y = 0;
        } finally{
            f = t = 0;
        }
        if (op[0] & 5) throw op[1];
        return {
            value: op[0] ? op[1] : void 0,
            done: true
        };
    }
};
import { EditorContentDataForNodeDocument } from "../../graphql/query.hooks";
import { isTypename } from "@st4/graphql";
import { extractContentData } from "../../content/extractContentData";
export function handleGetDocument(data, context) {
    return _handleGetDocument.apply(this, arguments);
}
function _handleGetDocument() {
    _handleGetDocument = _asyncToGenerator(function(data, context) {
        var documentId, ref, xmlParsed, content, fragment, resolvedFragment;
        return __generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    documentId = data.metadata.query["documentId"];
                    if (!documentId) return [
                        2,
                        {
                            status: 404
                        }
                    ];
                    if (context.nodeId === documentId) {
                        ;
                        xmlParsed = new DOMParser().parseFromString(context.xml, "text/xml");
                        if (!xmlParsed.firstElementChild || xmlParsed.firstElementChild.tagName == "parsererror") return [
                            2,
                            {
                                status: 404
                            }
                        ];
                        context.onDocumentLoad();
                        content = prepareDocumentRoot(context.xml, ((ref = xmlParsed.firstElementChild) === null || ref === void 0 ? void 0 : ref.getAttribute("fragment-element")) ? "Structure-Fragment" : "Node");
                        return [
                            2,
                            createSuccessResponse(documentId, content, context.metaData)
                        ];
                    }
                    fragment = context.fragments.find(function(x) {
                        return x.id.endsWith(documentId);
                    });
                    if (fragment) return [
                        2,
                        createSuccessResponse(documentId, prepareDocumentRoot(fragment.xml, fragment.type), {})
                    ];
                    if (!documentId.includes("x-pattern-context")) return [
                        2,
                        {
                            status: 404
                        }
                    ];
                    return [
                        4,
                        resolveFragment(documentId, context)
                    ];
                case 1:
                    resolvedFragment = _state.sent();
                    if (!resolvedFragment) return [
                        2,
                        {
                            status: 404
                        }
                    ];
                    return [
                        2,
                        createSuccessResponse(documentId, prepareDocumentRoot(resolvedFragment.xml, resolvedFragment.type), resolvedFragment.metaData)
                    ];
            }
        });
    });
    return _handleGetDocument.apply(this, arguments);
}
function createSuccessResponse(documentId, content, metaData) {
    return {
        status: 200,
        body: {
            documentId: documentId,
            content: content,
            lock: {
                isLockAcquired: true,
                isLockAvailable: true
            },
            metadata: metaData
        }
    };
}
function prepareDocumentRoot(input, newRoot) {
    newRoot = newRoot.toLowerCase();
    return input.replace("<node", "<".concat(newRoot)).replace("</node>", "</".concat(newRoot, ">"));
}
function resolveFragment(fragmentId, context) {
    return _resolveFragment.apply(this, arguments);
}
function _resolveFragment() {
    _resolveFragment = _asyncToGenerator(function(fragmentId, context) {
        var ref, ref1, ref2, response, contentData;
        return __generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    if (!context.apolloClient) return [
                        2
                    ];
                    return [
                        4,
                        context.apolloClient.query({
                            query: EditorContentDataForNodeDocument,
                            variables: {
                                id: fragmentId,
                                languageId: context.languageId,
                                variant: "InformationPool"
                            }
                        })
                    ];
                case 1:
                    response = _state.sent();
                    contentData = extractContentData(response === null || response === void 0 ? void 0 : response.data);
                    if (!contentData) return [
                        2
                    ];
                    context.addToAdditionalInfo(contentData.images, contentData.fragments);
                    return [
                        2,
                        {
                            xml: contentData.xml,
                            type: isTypename("TextContent")((ref = response.data) === null || ref === void 0 ? void 0 : (ref1 = ref.node) === null || ref1 === void 0 ? void 0 : (ref2 = ref1.node) === null || ref2 === void 0 ? void 0 : ref2.content) ? "Fragment" : "FragmentGroup",
                            metaData: contentData.metaData
                        }
                    ];
            }
        });
    });
    return _resolveFragment.apply(this, arguments);
}
