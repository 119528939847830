import React, { useState } from "react"
import styled from "styled-components"
import {
  IndentationSpacer,
  NodeContent,
  NodeExpandToggle,
  NodeSelectionSpan,
  StyledHeader,
  TreeViewNodeProps,
} from "../base"
import { NodeButtons } from "../base/nodeButtons"
import { NodeCheckbox } from "../base/nodecheckbox"

export const TreeViewNode = React.memo(
  function TreeViewNode(props: TreeViewNodeProps) {
    const { item, onClick, onHoverChange, isParentHovered, isSelected, meatballMenu, createButton } = props
    const [isHover, setIsHover] = useState(false)

    return (
      <div className="TreeViewNode" aria-level={item.indentation + 1}>
        <NodeSelectionSpan
          isSelected={isSelected}
          isHover={isHover}
          isParentHovered={isParentHovered}
          onHoverChange={setIsHover}
          style={{ flexGrow: 1, display: "flex", flexDirection: "row" }}
        >
          <IndentationSpacer indentation={item.indentation} style={{ flexGrow: 0 }} />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              flexWrap: "nowrap",
              flexGrow: 1,
              cursor: "pointer",
            }}
          >
            <StyledHeader data-id={item.node.id} style={{ flexGrow: 1 }}>
              <StyledTreeViewItem>
                <span style={{ display: "grid", grid: "auto / auto 1fr auto" }}>
                  <NodeExpandToggle onHover={onHoverChange} {...props} />
                  <NodeCheckbox {...props} />
                  <NodeContent
                    dropInsertionType={null}
                    id={item.node.id}
                    label={item.node.label}
                    icon={item.node.icon ?? ""}
                    onClick={() => onClick(item.node.id)}
                  />
                </span>
                <NodeButtons
                  node={item.node}
                  createButton={createButton}
                  meatballMenu={meatballMenu}
                  isHover={isHover}
                  isSelected={isSelected}
                />
              </StyledTreeViewItem>
            </StyledHeader>
          </div>
        </NodeSelectionSpan>
      </div>
    )
  },
  (prevProps, nextProps) => {
    return (
      prevProps.checkable === nextProps.checkable &&
      prevProps.isChecked === nextProps.isChecked &&
      prevProps.onHoverChange === nextProps.onHoverChange &&
      prevProps.isParentHovered === nextProps.isParentHovered &&
      prevProps.isSelected === nextProps.isSelected &&
      prevProps.isExpanded === nextProps.isExpanded &&
      prevProps.onExpandChange === nextProps.onExpandChange &&
      prevProps.item.hasChildren === nextProps.item.hasChildren &&
      prevProps.item.node.label === nextProps.item.node.label &&
      prevProps.meatballMenu?.dropdownRenderForNode === nextProps.meatballMenu?.dropdownRenderForNode &&
      prevProps.createButton?.onClick === nextProps.createButton?.onClick
    )
  },
)

const StyledTreeViewItem = styled.span`
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
  display: grid;
  grid: auto / 1fr auto;
  justify-content: space-between;
`
