import * as React from "react"
import { SVGProps } from "react"

const SvgArrowUpAZ = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width="1em" height="1em" {...props}>
    <path d="M416 32c9.1 0 17.4 5.1 21.5 13.3l80 160c5.9 11.9 1.1 26.3-10.7 32.2s-26.3 1.1-32.2-10.7L461 199.6c-1.6.3-3.2.5-4.9.5h-85.3l-13.4 26.7c-5.9 11.9-20.3 16.7-32.2 10.7s-16.7-20.3-10.7-32.2l80-160C398.6 37.1 406.9 32 416 32zm-21.2 120h42.3L416 109.7 394.8 152zM143 39c9.4-9.4 24.6-9.4 33.9 0l96 96c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-55-55V456c0 13.3-10.7 24-24 24s-24-10.7-24-24V113.9L81 169c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l96-96zm209 249h128c9.4 0 18 5.5 21.9 14.2s2.3 18.7-4 25.8l-92.5 104H480c13.3 0 24 10.7 24 24s-10.7 24-24 24H352c-9.4 0-18-5.5-21.9-14.2s-2.3-18.7 4-25.8l92.5-104H352c-13.3 0-24-10.7-24-24s10.7-24 24-24z" />
  </svg>
)

export default SvgArrowUpAZ
