const WEBAUTHOR_SEARCH_PAGESIZE = "WebAutor_Search_PageSize"
const defaultPageSize = 10

export function loadPageSize() {
  const pageSize = localStorage.getItem(WEBAUTHOR_SEARCH_PAGESIZE)
  return pageSize ? parseInt(pageSize) : defaultPageSize
}

export function savePageSize(pageSize: number) {
  localStorage.setItem(WEBAUTHOR_SEARCH_PAGESIZE, pageSize.toString())
  dispatchEvent(new Event("onPageSizeChanged"))
}
