import { makeVar, useReactiveVar } from "@apollo/client"

export type CurrentUser = {
  type: string
  userName: string
  displayName: string
  displayLanguage: string
  groups: { groupName: string }[]
  isAbsent: boolean
  isDisabled: boolean
  isDeleted: boolean
}

type CurrentUserState =
  | { state: "loading"; forceNetwork?: boolean }
  | { state: "loaded"; user: CurrentUser }
  | { state: "loadError"; errors: Error[] }

export const currentUserVar = makeVar<CurrentUserState>({ state: "loading" })

/**
 * Get current user's account data to use outside of a react component
 * @returns Current user's account data
 */
export function getCurrentUser() {
  return currentUserVar()
}

/**
 * Get current user's data to use in a react component that re-renders if the current user's data changes
 * @returns Current user's account data
 */
export function useCurrentUser() {
  return useReactiveVar(currentUserVar)
}
