import { useApolloClient } from "@apollo/client"
import { useEffect, useState } from "react"
import { SettingsQuery, SettingsQueryResult, SettingsDocument } from "./query.hooks"
import { SearchSettingsQuery, SearchSettingsQueryResult, SearchSettingsDocument } from "./query.hooks"
import { makeVar, useReactiveVar } from "@apollo/client"
import { dayjs, i18n } from "@schema/utils"

type SettingsResult = {
  data?: SettingsQueryResult["data"]
  loading: boolean
  error?: SettingsQueryResult["error"]
}

export const SETTINGS_VAR = makeVar<(SettingsQueryResult["data"] & SearchSettingsQueryResult["data"]) | undefined>(
  undefined,
)
const GENERAL_SETTINGS_VAR = makeVar<SettingsQueryResult["data"] | undefined>(undefined)
const SEARCH_SETTINGS_VAR = makeVar<SearchSettingsQueryResult["data"] | undefined>(undefined)

export function useSettings() {
  const settings = useReactiveVar(SETTINGS_VAR)
  return settings
}

export function useSettingsQuery(): SettingsResult {
  const client = useApolloClient()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<SettingsQueryResult["error"] | SearchSettingsQueryResult["error"]>()
  async function initializeWithSettings() {
    const result = await client.query<SettingsQuery>({ query: SettingsDocument })
    if (result.error) {
      console.log(`Error getting Settings: ${result.error.message}`)
      setError(result.error)
      setLoading(false)
    }
    if (result.data) {
      const languageCode = result.data.userConfiguration.guiLanguageCode
      GENERAL_SETTINGS_VAR(result.data)
      await i18n.changeLanguage(languageCode)
      dayjs.locale(languageCode)
    }

    client.cache.watch({
      query: SettingsDocument,
      callback: (diff) => {
        if (diff.complete) {
          GENERAL_SETTINGS_VAR(diff.result)
          updateSettingsVar(diff.result, SEARCH_SETTINGS_VAR())
        }
      },
      optimistic: false,
    })

    return result.data
  }
  async function initializeWithSearchSettings(languageId: any) {
    const result = await client.query<SearchSettingsQuery>({
      query: SearchSettingsDocument,
      variables: { languageId: languageId },
    })
    if (result.error) {
      console.log(`Error getting Search Settings: ${result.error.message}`)
      setError(result.error)
      setLoading(false)
    }
    if (result.data) {
      SEARCH_SETTINGS_VAR(result.data)
    }

    client.cache.watch({
      query: SearchSettingsDocument,
      callback: (diff, lastDiff) => {
        if (diff.complete) {
          SEARCH_SETTINGS_VAR(diff.result)
          updateSettingsVar(GENERAL_SETTINGS_VAR(), diff.result)
        }
      },
      optimistic: false,
    })

    return result.data
  }
  useEffect(() => {
    initializeWithSettings()
      .then(async (generalResult) => {
        const searchResult = await initializeWithSearchSettings(generalResult.userConfiguration.aspectSelectorId)
        return { general: generalResult, search: searchResult }
      })
      .then(({ general, search }) => {
        updateSettingsVar(general, search)
        setLoading(false)
      })
  }, [])
  return loading ? { loading } : { data: SETTINGS_VAR(), error, loading }

  function updateSettingsVar(general?: SettingsQuery, search?: SearchSettingsQuery) {
    const generalSettings = general || GENERAL_SETTINGS_VAR()
    const searchSettings = search || SEARCH_SETTINGS_VAR()

    if (!generalSettings) SETTINGS_VAR(undefined)
    else
      SETTINGS_VAR({
        ...generalSettings,
        configuration: {
          ...generalSettings.configuration,
          searchableDataValues: searchSettings?.configuration.searchableDataValues || [],
          searchableAspects: searchSettings?.configuration.searchableAspects || [],
        },
      })
  }
}
