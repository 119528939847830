import React from "react"
import { Button as AntdButton } from "antd"
import styled from "styled-components"

const ButtonMinWidth = styled.span`
  min-width: 6ex;
`

type ButtonProps = {
  /**
   * (optional) Handler for the click-event.
   */
  onClick?: React.MouseEventHandler
  /**
   * (optional) Set this prop if the button should be displayed as primary.
   */
  primary?: boolean
  /**
   * (optional) An Icon to display in front of the text.
   */
  icon?: React.ReactNode
}

/**
 * A Button component.
 */
export function Button(props: React.PropsWithChildren<ButtonProps>) {
  const { children, onClick, primary, icon } = props
  return (
    <AntdButton type={primary ? "primary" : "default"} onClick={onClick} icon={icon}>
      {children && <ButtonMinWidth>{children}</ButtonMinWidth>}
    </AntdButton>
  )
}
