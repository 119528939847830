import React from "react"
import { Blade, BladeDefinition } from "@st4/tasks"
import { Spin } from "antd"
import { QuanosBlueButton } from "~/ui/quanosbutton"
import { Translate } from "@st4/i18n"
import {
  SearchOptions,
  SearchOptionsForm,
  areSearchOptionsEqual,
  getDefaultSearchOptions,
  loadSearchOptions,
  saveSearchOptions,
} from "@st4/search"
import { useSettings, useLanguage } from "@st4/settings"
import { keys } from "@st4/ui-strings"

const baseTestId = "blade-searchOptions"

type SearchOptionsBladeProps = {
  searchOptions?: SearchOptions
}

type SearchOptionsBladeMessages =
  | {
      action: "resetSearchOptions"
      payload: SearchOptions
    }
  | {
      action: "saveSearchOptions"
      payload: SearchOptions
    }
  | {
      action: "publishSearchOptions"
      payload: SearchOptions
    }

export const SearchOptionsBlade: BladeDefinition<SearchOptionsBladeProps, SearchOptionsBladeMessages> =
  function SearchOptionsBlade(props) {
    const language = useLanguage()
    const settings = useSettings()

    if (!settings) return <Spin delay={500} />

    const languageId: string = language.languageId
    const searchOptions = props.searchOptions || loadSearchOptions(language.languageId)
    const defaultSearchOptions = getDefaultSearchOptions(language.languageId)
    return (
      <>
        <Blade.Toolbar>
          <QuanosBlueButton
            id={`${baseTestId}-button-reset`}
            data-testid={`${baseTestId}-button-reset`}
            disabled={areSearchOptionsEqual(searchOptions, defaultSearchOptions)}
            onClick={resetHandler}
          >
            <Translate>{keys.button.search.blade.searchOptionsBlade.reset}</Translate>
          </QuanosBlueButton>
        </Blade.Toolbar>
        <Blade.Content>
          <SearchOptionsForm
            availableDataValues={settings.configuration.searchableDataValues || []}
            availableLanguages={
              settings.configuration.languageIdList
                .filter((l) => settings.configuration.searchableAspects.includes(l.languageId.split(";")[0]))
                .map((l) => {
                  const current: string = l.languageId
                  return {
                    // remove GUI language ID from aspect selector as it is always identical to the user's GUI language
                    id: languageId.startsWith(current) ? languageId : current.split(";")[0],
                    code: l.language,
                    name: l.languageName,
                  }
                }) || []
            }
            selectedUserLanguage={languageId}
            selectedSearchOptions={searchOptions}
            onSearchKeysChanged={(newSearchKeys) =>
              props.sendMessage({
                action: "saveSearchOptions",
                payload: {
                  ...searchOptions,
                  searchKeys: newSearchKeys,
                },
              })
            }
            onSearchVersionChanged={(newSearchVersion) =>
              props.sendMessage({
                action: "saveSearchOptions",
                payload: {
                  ...searchOptions,
                  searchVersion: newSearchVersion,
                },
              })
            }
            onSearchLanguagesChanged={(newSearchLanguages) =>
              props.sendMessage({
                action: "saveSearchOptions",
                payload: {
                  ...searchOptions,
                  searchLanguages: newSearchLanguages,
                },
              })
            }
          />
        </Blade.Content>
        <Blade.Actionbar>
          <div />
          <QuanosBlueButton
            id={`${baseTestId}-button-choose`}
            data-testid={`${baseTestId}-button-choose`}
            onClick={publishHandler}
          >
            <Translate>{keys.button.general.choose}</Translate>
          </QuanosBlueButton>
        </Blade.Actionbar>
      </>
    )

    function resetHandler() {
      props.sendMessage({
        action: "resetSearchOptions",
        payload: defaultSearchOptions,
      })
    }

    function publishHandler() {
      const opts = props.searchOptions as SearchOptions
      saveSearchOptions(opts)
      props.sendMessage({
        action: "publishSearchOptions",
        payload: opts,
      })
    }
  }
SearchOptionsBlade.title = <Translate>{keys.label.search.blade.searchOptionsBlade.title}</Translate>
SearchOptionsBlade.size = { S: 7, M: 6, L: 4 }
SearchOptionsBlade.reducer = (previousState, message) => {
  switch (message.action) {
    case "resetSearchOptions":
    case "saveSearchOptions":
      return {
        ...previousState,
        searchOptions: message.payload,
      }
    default:
      return previousState
  }
}
