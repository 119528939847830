import { useRef, useEffect } from "react"
import { useOpenEditorSessionForNodeMutation, useCloseEditorSessionMutation } from "@st4/editor"
import { EditorSessionInfo, IMessage, isTypename, WarningMessage } from "@st4/graphql"
import { withOpenEditorSession } from "./editorSessions"
import { useAuthentication } from "@st4/authentication"
import { MessageHub } from "@st4/message-hub"
import { ContentMessage } from "../../../../types"

export function useHandleEditorSession(
  treeNodeId: string,
  languageId: string,
  isEditorInUse: boolean,
  setWarnings: (newWarnings: WarningMessage[]) => void,
  messageHub: MessageHub<ContentMessage>,
) {
  const [openEditorSession, { loading, data, error }] = useOpenEditorSessionForNodeMutation()
  const [closeEditorSession] = useCloseEditorSessionMutation()
  const editorSession = useRef<EditorSessionInfo>()
  let sessionError: IMessage[] = []

  useEffect(() => {
    if (isEditorInUse) return

    openEditorSession({
      variables: {
        selectedTreeNode: treeNodeId,
        languageId: languageId,
        editorName: "Fonto",
      },
    })

    return () => {
      if (!editorSession.current?.id) return

      withOpenEditorSession(() => {
        return closeEditorSession({
          variables: {
            editorSessionId: editorSession.current?.id,
          },
        })
          .then(() => {
            return true
          })
          .catch(() => {
            return false
          })
      }, treeNodeId)
    }
  }, [treeNodeId, isEditorInUse])

  useEditorSessionCloseOnReload(editorSession.current?.id)

  useEffect(() => {
    if (isTypename("EditorSessionSuccess")(data?.openEditorSessionForNode) && data?.openEditorSessionForNode.result) {
      editorSession.current = data?.openEditorSessionForNode.result.editorSessionInfo
      const newWarnings = data?.openEditorSessionForNode.result.warnings ?? []
      setWarnings([...newWarnings])

      messageHub.sendMessage({
        action: "editorWarningsChanged",
        payload: { warnings: newWarnings },
      })
    }
  }, [data?.openEditorSessionForNode, messageHub, setWarnings])

  if (isTypename("EditorSessionError")(data?.openEditorSessionForNode) && data?.openEditorSessionForNode.reasons) {
    sessionError = data?.openEditorSessionForNode.reasons
  }

  return {
    loading: loading,
    editorSession: editorSession.current,
    sessionError: sessionError,
    error: error,
  }
}

export function useEditorSessionCloseOnReload(editorSessionId?: string) {
  const [authData] = useAuthentication()
  useEffect(() => {
    if (authData.state !== "loggedIn") return
    if (!editorSessionId) return

    const onReloadHandler = () => {
      const headers = {
        type: "application/json",
        "x-auth-id": authData.sessionData.authId,
      }
      const body = {
        operationName: "closeEditorSession",
        variables: {
          editorSessionId: editorSessionId,
        },
        query: `mutation closeEditorSession($editorSessionId: StObjectId!) {
              closeEditorSession(editorSessionId: $editorSessionId) {
                ... on CloseEditorSessionSuccess {
                  result {
                    __typename
                    id
                    editorSession(editorType: FONTO) {
                      __typename
                    }
                  }
                __typename
                }
                ... on Error {
                  message
                  __typename
                }
                __typename
              }
             }`,
      }
      const blob = new Blob([JSON.stringify(body)], headers)

      //beforeunload intercepts all normal web request
      //therefor we have to use the send beacon method which queues request in the browser outside of the web page
      //https://developer.mozilla.org/en-US/docs/Web/API/Navigator/sendBeacon
      navigator.sendBeacon("/graphql", blob)
    }
    window.addEventListener("beforeunload", onReloadHandler)

    return () => {
      window.removeEventListener("beforeunload", onReloadHandler)
    }
  }, [authData, editorSessionId])
}
