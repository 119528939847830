import classNames from "classnames"
import React, { Component } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Body_deprecated, Button_deprecated, Header_deprecated, Text_deprecated } from "../../index"

function _TabHead(props) {
  return (
    <Button_deprecated {...props} flat>
      <Text_deprecated>{props.label}</Text_deprecated>
    </Button_deprecated>
  )
}

export const TabHead_deprecated = styled(_TabHead)`
  flex: 1;
  &:hover:before {
    opacity: 0;
  }
  border-radius: 0;
  border-bottom: ${(props) => "2px solid " + (props.selected ? props.theme.primaryColor : "transparent")};
`

function _Tab(props) {
  return <div className={classNames("Tab", props.className)}>{props.children}</div>
}

export const Tab_deprecated = styled(_Tab)`
  flex: 1 0 100%;
`

Tab_deprecated.propTypes = {
  label: PropTypes.string, //.isRequired
}

class Tabs_deprecated extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedIndex: props.selectedIndex || 0,
    }
  }
  selectTab(selectedIndex) {
    this.setState({
      selectedIndex,
    })
  }
  render() {
    const { selectedIndex } = this.state
    const selected = this.props.children[selectedIndex]
    return (
      <div className={classNames(this.props.className, "Tabs")} style={this.props.style}>
        <Header_deprecated>
          {this.props.children.map((tab, idx) => {
            return (
              <TabHead_deprecated
                key={idx}
                selected={this.props.children.indexOf(tab) === selectedIndex}
                onClick={this.selectTab.bind(this, this.props.children.indexOf(tab))}
                {...tab.props}
              />
            )
          })}
        </Header_deprecated>
        <Body_deprecated className="Tabs--Body">{selected}</Body_deprecated>
      </div>
    )
  }
}

export default styled(Tabs_deprecated)`
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  & .Tabs--Body {
    display: flex;
    flex: 1 0 auto;
  }
`
