import * as React from "react"
import { SVGProps } from "react"

const SvgCircleArrowLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1em" height="1em" {...props}>
    <path d="M48 256a208 208 0 1 1 416 0 208 208 0 1 1-416 0zm464 0a256 256 0 1 0-512 0 256 256 0 1 0 512 0zM223 151l-88 88c-9.4 9.4-9.4 24.6 0 33.9l88 88c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47H360c13.3 0 24-10.7 24-24s-10.7-24-24-24H209.9l47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0z" />
  </svg>
)

export default SvgCircleArrowLeft
