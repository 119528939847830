import * as React from "react"
import { SVGProps } from "react"

const SvgArrowDownAZ = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width="1em" height="1em" {...props}>
    <path d="m47 377 96 96c9.4 9.4 24.6 9.4 33.9 0l96-96c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-55 55V56c0-13.3-10.7-24-24-24s-24 10.7-24 24v342.1L81 343c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9zm305-89c-13.3 0-24 10.7-24 24s10.7 24 24 24h74.6l-92.5 104.1c-6.3 7.1-7.8 17.2-4 25.8S342.6 480 352 480h128c13.3 0 24-10.7 24-24s-10.7-24-24-24h-74.6l92.5-104.1c6.3-7.1 7.8-17.2 4-25.8S489.4 288 480 288H352zm64-256c-9.1 0-17.4 5.1-21.5 13.3l-80 160c-5.9 11.9-1.1 26.3 10.7 32.2s26.3 1.1 32.2-10.7l13.4-26.8H456c1.7 0 3.3-.2 4.9-.5l13.6 27.2c5.9 11.9 20.3 16.7 32.2 10.7s16.7-20.3 10.7-32.2l-80-160c-4-8.1-12.3-13.2-21.4-13.2zm-21.2 120 21.2-42.3 21.2 42.3h-42.4z" />
  </svg>
)

export default SvgArrowDownAZ
