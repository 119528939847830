import type { Mapping } from "./types"
import type { Node } from "unist"
import type { ST4NodeWithContent } from "../graphql/types"

const xastMappingRegistry = new Map<string, Mapping<Node>>()

export function registerMapping(key: string, mapping: Mapping<Node>) {
  xastMappingRegistry.set(key, mapping)
}

export function getMapping(key: string) {
  const value = xastMappingRegistry.get(key)
  if (value) return value
  // throw new Error(`Mapping for Key "${key}" not found!`)
  return {}
}

//Only for testing
export function clearMappingForTesting() {
  xastMappingRegistry.clear()
}

const nodeMappingRegistry = new Map<string, Mapping<ST4NodeWithContent>>()

export function registerNodeMapping(key: string, mapping: Mapping<ST4NodeWithContent>) {
  nodeMappingRegistry.set(key, mapping)
}

export function getNodeMapping(key: string) {
  return nodeMappingRegistry.get(key)
}

//Only for testing
export function clearNodeMappingForTesting() {
  nodeMappingRegistry.clear()
}
