import React, { CSSProperties, MouseEventHandler } from "react"
import SVG from "./SVG"
import styled from "styled-components"
import { accentColor } from "@st4/theme-manager"

interface IoniconProps {
  path: string
  className?: string
  style?: CSSProperties
  size?: number | string
  onClick?: MouseEventHandler<SVGElement>
  rotate?: boolean
  shake?: boolean
  beat?: boolean
}
interface IoniconState {
  classNames: Array<string> | string
  animationActive: boolean
}

export function Ionicon(props: IoniconProps) {
  const style = {
    ...props.style,
    fontSize: props.size || "22px",
  }
  return (
    <SVG
      style={style}
      className={props.className}
      width={props.size}
      height={props.size}
      viewBox="0 0 1024 1024"
      onClick={props.onClick}
      rotate={props.rotate ? 1 : 0}
      shake={props.shake ? 1 : 0}
      beat={props.beat ? 1 : 0}
    >
      <path d={props.path} />
    </SVG>
  )
}

Ionicon.defaultProps = {
  // style
  style: {},
  size: "22px",

  // animation
  shake: false,
  beat: false,
  rotate: false,
}

export default styled(Ionicon)`
  fill: ${(props: any) => props.color || accentColor(props)};
  color: ${(props: any) => props.color || accentColor(props)};
`
