import * as React from "react"
import { SVGProps } from "react"

const SvgRightLong = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1em" height="1em" {...props}>
    <path d="M352 184v-49.3c0-3.7 3-6.7 6.7-6.7 1.9 0 3.7.8 5 2.2L476.2 256 363.7 381.8c-1.3 1.4-3.1 2.2-5 2.2-3.7 0-6.7-3-6.7-6.7V328c0-17.7-14.3-32-32-32H48c-8.8 0-16-7.2-16-16v-48c0-8.8 7.2-16 16-16h272c17.7 0 32-14.3 32-32zm154.7 86c3.4-3.8 5.3-8.8 5.3-14s-1.9-10.1-5.3-14L387.5 108.9c-7.3-8.2-17.8-12.9-28.8-12.9-21.4 0-38.7 17.3-38.7 38.7V184H48c-26.5 0-48 21.5-48 48v48c0 26.5 21.5 48 48 48h272v49.3c0 21.4 17.3 38.7 38.7 38.7 11 0 21.5-4.7 28.8-12.9L506.7 270z" />
  </svg>
)

export default SvgRightLong
