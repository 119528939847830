import React from "react"
import { CookiesProvider } from "react-cookie"
import { CurrentUserProvider } from "./currentUser"
import { isLoggedIn, LoginPage } from "."
import type { AuthData } from "./types"
import { useAuthentication } from "./useAuthentication"

type AuthenticationProviderProps = {
  language: string
  logo: React.ReactNode
  license: AuthData["license"]
  successUrl?: string
  children: React.ReactNode
}
export function AuthenticationProvider({ children, logo, language, license, successUrl }: AuthenticationProviderProps) {
  const [authState] = useAuthentication()
  const loggedIn = isLoggedIn(authState)
  return (
    <CookiesProvider>
      {loggedIn ? (
        <CurrentUserProvider>{children}</CurrentUserProvider>
      ) : (
        <LoginPage logo={logo} language={language} successUrl={successUrl} license={license} />
      )}
    </CookiesProvider>
  )
}
