import * as React from "react"
import { SVGProps } from "react"

const SvgSpinnerThird = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1em" height="1em" {...props}>
    <path d="M457 372c11.5 6.6 26.3 2.7 31.8-9.3C503.7 330.2 512 294.1 512 256 512 122.7 410.1 13.2 280 1.1c-13.2-1.2-24 9.6-24 22.9 0 13.3 10.8 23.9 24 25.4C383.5 61.2 464 149.2 464 256c0 29.3-6.1 57.3-17 82.6-5.3 12.2-1.5 26.8 10 33.5z" />
  </svg>
)

export default SvgSpinnerThird
