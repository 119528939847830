import * as Types from "@st4/graphql"

import { gql } from "@apollo/client"
import * as Apollo from "@apollo/client"
const defaultOptions = {} as const
export type CreateTaskMutationVariables = Types.Exact<{
  taskdefinitionname: Types.Scalars["String"]
}>

export type CreateTaskMutation = {
  __typename: "Mutation"
  createTask:
    | {
        __typename: "TaskContext"
        id: string
        name: string
        createdAt: string
        data?: string | null
        closed: boolean
        owner: { __typename: "UserInfo"; username?: string | null }
      }
    | { __typename: "TaskContextError"; id: string; message?: string | null }
    | { __typename: "TaskContextErrorWithoutInstance"; message?: string | null }
}

export type SaveTaskStateMutationVariables = Types.Exact<{
  id: Types.Scalars["ID"]
  data: Types.Scalars["String"]
}>

export type SaveTaskStateMutation = {
  __typename: "Mutation"
  saveTaskState:
    | {
        __typename: "TaskContext"
        id: string
        name: string
        createdAt: string
        data?: string | null
        closed: boolean
        owner: { __typename: "UserInfo"; username?: string | null }
      }
    | { __typename: "TaskContextError"; id: string; message?: string | null }
    | { __typename: "TaskContextErrorWithoutInstance"; message?: string | null }
}

export type ExecuteTaskActionMutationVariables = Types.Exact<{
  id: Types.Scalars["ID"]
  actionName: Types.Scalars["String"]
}>

export type ExecuteTaskActionMutation = {
  __typename: "Mutation"
  executeTaskAction:
    | {
        __typename: "TaskContext"
        id: string
        name: string
        createdAt: string
        data?: string | null
        closed: boolean
        owner: { __typename: "UserInfo"; username?: string | null }
      }
    | { __typename: "TaskContextError"; id: string; message?: string | null }
    | { __typename: "TaskContextErrorWithoutInstance"; message?: string | null }
}

export type CloseTaskMutationVariables = Types.Exact<{
  id: Types.Scalars["ID"]
}>

export type CloseTaskMutation = {
  __typename: "Mutation"
  closeTask:
    | {
        __typename: "TaskContext"
        id: string
        name: string
        createdAt: string
        data?: string | null
        closed: boolean
        owner: { __typename: "UserInfo"; username?: string | null }
      }
    | { __typename: "TaskContextError"; id: string; message?: string | null }
    | { __typename: "TaskContextErrorWithoutInstance"; message?: string | null }
}

export type TaskStateQueryVariables = Types.Exact<{
  id: Types.Scalars["ID"]
}>

export type TaskStateQuery = {
  __typename: "Query"
  taskState?:
    | {
        __typename: "TaskContext"
        id: string
        name: string
        createdAt: string
        data?: string | null
        closed: boolean
        owner: { __typename: "UserInfo"; username?: string | null }
      }
    | { __typename: "TaskContextError"; id: string; message?: string | null }
    | { __typename: "TaskContextErrorWithoutInstance"; message?: string | null }
    | null
}

export type RunningTasksQueryVariables = Types.Exact<{ [key: string]: never }>

export type RunningTasksQuery = {
  __typename: "Query"
  me: {
    __typename: "User"
    runningTasks: Array<{
      __typename: "TaskContext"
      id: string
      name: string
      createdAt: string
      data?: string | null
      closed: boolean
      owner: { __typename: "UserInfo"; username?: string | null }
    }>
  }
}

export type TaskContextFragment = {
  __typename: "TaskContext"
  id: string
  name: string
  createdAt: string
  data?: string | null
  closed: boolean
  owner: { __typename: "UserInfo"; username?: string | null }
}

export const TaskContextFragmentDoc = gql`
  fragment TaskContext on TaskContext {
    id
    name
    owner {
      username
    }
    createdAt
    data
    closed
  }
`
export const CreateTaskDocument = gql`
  mutation CreateTask($taskdefinitionname: String!) {
    createTask(taskdefinitionname: $taskdefinitionname, workflowmodelname: "SerializationBasic") {
      ...TaskContext
      ... on TaskContextError {
        id
        message
      }
      ... on TaskContextErrorWithoutInstance {
        message
      }
    }
  }
  ${TaskContextFragmentDoc}
`
export type CreateTaskMutationFn = Apollo.MutationFunction<CreateTaskMutation, CreateTaskMutationVariables>

/**
 * __useCreateTaskMutation__
 *
 * To run a mutation, you first call `useCreateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTaskMutation, { data, loading, error }] = useCreateTaskMutation({
 *   variables: {
 *      taskdefinitionname: // value for 'taskdefinitionname'
 *   },
 * });
 */
export function useCreateTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateTaskMutation, CreateTaskMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateTaskMutation, CreateTaskMutationVariables>(CreateTaskDocument, options)
}
export type CreateTaskMutationHookResult = ReturnType<typeof useCreateTaskMutation>
export type CreateTaskMutationResult = Apollo.MutationResult<CreateTaskMutation>
export type CreateTaskMutationOptions = Apollo.BaseMutationOptions<CreateTaskMutation, CreateTaskMutationVariables>
export const SaveTaskStateDocument = gql`
  mutation SaveTaskState($id: ID!, $data: String!) {
    saveTaskState(id: $id, data: $data) {
      ...TaskContext
      ... on TaskContextError {
        id
        message
      }
      ... on TaskContextErrorWithoutInstance {
        message
      }
    }
  }
  ${TaskContextFragmentDoc}
`
export type SaveTaskStateMutationFn = Apollo.MutationFunction<SaveTaskStateMutation, SaveTaskStateMutationVariables>

/**
 * __useSaveTaskStateMutation__
 *
 * To run a mutation, you first call `useSaveTaskStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveTaskStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveTaskStateMutation, { data, loading, error }] = useSaveTaskStateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSaveTaskStateMutation(
  baseOptions?: Apollo.MutationHookOptions<SaveTaskStateMutation, SaveTaskStateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SaveTaskStateMutation, SaveTaskStateMutationVariables>(SaveTaskStateDocument, options)
}
export type SaveTaskStateMutationHookResult = ReturnType<typeof useSaveTaskStateMutation>
export type SaveTaskStateMutationResult = Apollo.MutationResult<SaveTaskStateMutation>
export type SaveTaskStateMutationOptions = Apollo.BaseMutationOptions<
  SaveTaskStateMutation,
  SaveTaskStateMutationVariables
>
export const ExecuteTaskActionDocument = gql`
  mutation ExecuteTaskAction($id: ID!, $actionName: String!) {
    executeTaskAction(id: $id, actionName: $actionName) {
      ...TaskContext
      ... on TaskContextError {
        id
        message
      }
      ... on TaskContextErrorWithoutInstance {
        message
      }
    }
  }
  ${TaskContextFragmentDoc}
`
export type ExecuteTaskActionMutationFn = Apollo.MutationFunction<
  ExecuteTaskActionMutation,
  ExecuteTaskActionMutationVariables
>

/**
 * __useExecuteTaskActionMutation__
 *
 * To run a mutation, you first call `useExecuteTaskActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExecuteTaskActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [executeTaskActionMutation, { data, loading, error }] = useExecuteTaskActionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      actionName: // value for 'actionName'
 *   },
 * });
 */
export function useExecuteTaskActionMutation(
  baseOptions?: Apollo.MutationHookOptions<ExecuteTaskActionMutation, ExecuteTaskActionMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ExecuteTaskActionMutation, ExecuteTaskActionMutationVariables>(
    ExecuteTaskActionDocument,
    options,
  )
}
export type ExecuteTaskActionMutationHookResult = ReturnType<typeof useExecuteTaskActionMutation>
export type ExecuteTaskActionMutationResult = Apollo.MutationResult<ExecuteTaskActionMutation>
export type ExecuteTaskActionMutationOptions = Apollo.BaseMutationOptions<
  ExecuteTaskActionMutation,
  ExecuteTaskActionMutationVariables
>
export const CloseTaskDocument = gql`
  mutation CloseTask($id: ID!) {
    closeTask(id: $id) {
      ...TaskContext
      ... on TaskContextError {
        id
        message
      }
      ... on TaskContextErrorWithoutInstance {
        message
      }
    }
  }
  ${TaskContextFragmentDoc}
`
export type CloseTaskMutationFn = Apollo.MutationFunction<CloseTaskMutation, CloseTaskMutationVariables>

/**
 * __useCloseTaskMutation__
 *
 * To run a mutation, you first call `useCloseTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloseTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closeTaskMutation, { data, loading, error }] = useCloseTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCloseTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<CloseTaskMutation, CloseTaskMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CloseTaskMutation, CloseTaskMutationVariables>(CloseTaskDocument, options)
}
export type CloseTaskMutationHookResult = ReturnType<typeof useCloseTaskMutation>
export type CloseTaskMutationResult = Apollo.MutationResult<CloseTaskMutation>
export type CloseTaskMutationOptions = Apollo.BaseMutationOptions<CloseTaskMutation, CloseTaskMutationVariables>
export const TaskStateDocument = gql`
  query TaskState($id: ID!) {
    taskState(id: $id) {
      ...TaskContext
      ... on TaskContextError {
        id
        message
      }
      ... on TaskContextErrorWithoutInstance {
        message
      }
    }
  }
  ${TaskContextFragmentDoc}
`

/**
 * __useTaskStateQuery__
 *
 * To run a query within a React component, call `useTaskStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskStateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTaskStateQuery(baseOptions: Apollo.QueryHookOptions<TaskStateQuery, TaskStateQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<TaskStateQuery, TaskStateQueryVariables>(TaskStateDocument, options)
}
export function useTaskStateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TaskStateQuery, TaskStateQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<TaskStateQuery, TaskStateQueryVariables>(TaskStateDocument, options)
}
export type TaskStateQueryHookResult = ReturnType<typeof useTaskStateQuery>
export type TaskStateLazyQueryHookResult = ReturnType<typeof useTaskStateLazyQuery>
export type TaskStateQueryResult = Apollo.QueryResult<TaskStateQuery, TaskStateQueryVariables>
export const RunningTasksDocument = gql`
  query RunningTasks {
    me {
      runningTasks {
        ...TaskContext
      }
    }
  }
  ${TaskContextFragmentDoc}
`

/**
 * __useRunningTasksQuery__
 *
 * To run a query within a React component, call `useRunningTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useRunningTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRunningTasksQuery({
 *   variables: {
 *   },
 * });
 */
export function useRunningTasksQuery(
  baseOptions?: Apollo.QueryHookOptions<RunningTasksQuery, RunningTasksQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<RunningTasksQuery, RunningTasksQueryVariables>(RunningTasksDocument, options)
}
export function useRunningTasksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RunningTasksQuery, RunningTasksQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<RunningTasksQuery, RunningTasksQueryVariables>(RunningTasksDocument, options)
}
export type RunningTasksQueryHookResult = ReturnType<typeof useRunningTasksQuery>
export type RunningTasksLazyQueryHookResult = ReturnType<typeof useRunningTasksLazyQuery>
export type RunningTasksQueryResult = Apollo.QueryResult<RunningTasksQuery, RunningTasksQueryVariables>
