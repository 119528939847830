import React, { useEffect, useState } from "react"
import type { i18n } from "i18next"
import { Translate, keys } from "@st4/i18n"
import { Input } from "@st4/ui"
import { Icon, Light } from "@st4/icons"
import { useTranslation } from "react-i18next"

function parseFloatFromLocalizedString(value: string) {
  return parseFloat(value.replace(",", ".")) /*parseFloat only recognizes . as decimal designator */
}

function localizeFloat(value: number | null | undefined, i18n: i18n) {
  return value
    ? Intl.NumberFormat(i18n.language, { maximumSignificantDigits: 15, useGrouping: false }).format(value)
    : ""
}

type DoubleEditorProps = {
  value?: number
  isReadonly?: boolean
  suffixIcon?: React.ReactNode
  onSubmit: (value: number) => void
  errorMessage?: string
}

function isDouble(value: string) {
  const regexIsDouble = new RegExp("^[-]{0,1}[0-9]*[.,]{0,1}[0-9]*$")
  return regexIsDouble.test(value)
}

export function DoubleEditor({ value, isReadonly, suffixIcon, onSubmit, errorMessage }: DoubleEditorProps) {
  const { i18n } = useTranslation() // getI18n()

  const originalStringValue = localizeFloat(value, i18n)
  const [inputValue, setInputValue] = useState(originalStringValue)
  const dirty =
    originalStringValue !== localizeFloat(parseFloatFromLocalizedString(inputValue), i18n) ||
    (!isDouble(inputValue) && originalStringValue !== inputValue)

  useEffect(() => {
    setInputValue(localizeFloat(value, i18n))
  }, [value])

  const submitHandler = (value: string) => {
    if (dirty && isDouble(value)) {
      onSubmit(parseFloatFromLocalizedString(value))
    }
  }

  function handleKeyup(ev: React.KeyboardEvent<HTMLInputElement>) {
    if (ev.key === "Escape") {
      setInputValue(originalStringValue)
    }
  }

  if (!suffixIcon && !isReadonly) {
    suffixIcon = <Icon component={Light.Pen} />
  }

  const dirtyAndNoDouble = dirty && !isDouble(inputValue)
  const dirtyAndNoDoubleMessageKey = keys.message.error.inputNotDouble

  return (
    <Input
      errorMessage={
        errorMessage ?? (dirtyAndNoDouble ? <Translate>{dirtyAndNoDoubleMessageKey}</Translate> : undefined)
      }
      dirty={dirty}
      readonly={isReadonly}
      value={inputValue}
      onChange={(e) => setInputValue(e.target.value)}
      onSubmit={() => submitHandler(inputValue)}
      suffixIcon={suffixIcon}
      onKeyUp={handleKeyup}
    />
  )
}
