import type { NodeRenderProps } from "../types"
import React, { useCallback, useRef, useState } from "react"
import { NodeActionsContainer } from "./editor/NodeActionsContainer"
import { PreviewParameterContext, ReadonlyPreviewParameterContext } from "../../PreviewParameterContext"
import { ReadonlyTextNode } from "./ReadonlyTextNode"
import { EditorTextNode } from "./EditorTextNode"
import { ContentWidthLimiting, NodeWithNodeActionsContainerWrapper } from "../utils"
import { EditMode } from "@st4/content-tools"
import { NodeInlineEditorButton } from "./editor/NodeInlineEditorButton"

export function EditableTextNode({
  treeNode,
  node,
  previewParameterContext,
}: NodeRenderProps & {
  previewParameterContext: Exclude<PreviewParameterContext, ReadonlyPreviewParameterContext>
}) {
  const [editorError, setEditorError] = useState<boolean>()
  const initialHeight = useRef(420)
  const currentlyEditingNode = previewParameterContext.editedNodes.includes(node.id)

  const handleOpenEditor = (editMode: EditMode) => {
    previewParameterContext.messageHub.sendMessage({
      action: "editingStarted",
      payload: {
        inplace: editMode === "inplace",
        selection: {
          nodeId: node.id,
          treeNodeId: treeNode.id,
        },
      },
    })
  }
  const handleCloseEditor = () => {
    previewParameterContext.messageHub.sendMessage({
      action: "editingFinished",
      payload: {
        inplace: true,
        selection: {
          nodeId: node.id,
          treeNodeId: treeNode.id,
        },
      },
    })
  }

  const setIsEditorError = useCallback(
    (isError: boolean) => {
      setEditorError(isError)
    },
    [setEditorError],
  )

  if (!currentlyEditingNode) {
    return (
      <ContentWidthLimiting>
        <NodeWithNodeActionsContainerWrapper>
          <NodeActionsContainer
            key={node.id}
            nodeId={node.id}
            treeNodeId={treeNode.id}
            textNodeState="READONLY"
            previewParameters={previewParameterContext}
          >
            <div />
          </NodeActionsContainer>
          <NodeInlineEditorButton
            isError={false}
            treeNode={treeNode}
            handleOpenEditor={handleOpenEditor}
            handleCloseEditor={handleCloseEditor}
          >
            <ReadonlyTextNode treeNode={treeNode} node={node} />
          </NodeInlineEditorButton>
        </NodeWithNodeActionsContainerWrapper>
      </ContentWidthLimiting>
    )
  }

  if (editorError && !previewParameterContext.singleNodeMode) {
    return (
      <ContentWidthLimiting fullWidth={false}>
        <NodeInlineEditorButton
          isError={true}
          treeNode={treeNode}
          handleOpenEditor={handleOpenEditor}
          handleCloseEditor={handleCloseEditor}
        >
          <EditorTextNode
            key={node.id}
            initialHeight={initialHeight}
            node={node}
            treeNode={treeNode}
            editMode={"inplace"}
            setIsEditorError={setIsEditorError}
            previewParameters={previewParameterContext}
          />
        </NodeInlineEditorButton>
      </ContentWidthLimiting>
    )
  }

  return (
    <ContentWidthLimiting fullWidth={previewParameterContext.singleNodeMode}>
      <EditorTextNode
        key={node.id}
        initialHeight={initialHeight}
        node={node}
        treeNode={treeNode}
        editMode={previewParameterContext.singleNodeMode ? "advanced" : "inplace"}
        setIsEditorError={setIsEditorError}
        previewParameters={previewParameterContext}
      />
    </ContentWidthLimiting>
  )
}
