import React from "react"
import { Checkbox } from "antd"

export function CheckboxFacetItem(props: {
  testId: string
  title: string
  value: React.Key
  selectedValue?: React.Key
  onClear: () => void
  onSelect: (key: React.Key) => void
}) {
  if (!props.title || !props.value.toString().length) return <></>

  return (
    <div>
      <Checkbox
        data-testid={`${props.testId}-checkbox`}
        disabled={!!props.selectedValue && props.selectedValue != props.value}
        checked={!!props.selectedValue && props.selectedValue == props.value}
        onChange={(e) => onChange(e.target.checked)}
      >
        <span data-testid={`${props.testId}-label`}>{props.title}</span>
      </Checkbox>
    </div>
  )

  function onChange(checked: boolean) {
    if (checked) props.onSelect(props.value)
    else props.onClear()
  }
}
