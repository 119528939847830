import * as Types from "@st4/graphql"

import { gql } from "@apollo/client"
import * as Apollo from "@apollo/client"
const defaultOptions = {} as const
export type CopyTreesMutationVariables = Types.Exact<{
  input: Types.CopyTreesInput
  languageId?: Types.InputMaybe<Types.Scalars["AspectSelectorId"]>
}>

export type CopyTreesMutation = {
  __typename: "Mutation"
  copyTrees: { __typename: "Error"; message: string } | { __typename: "FinishedJobResponse"; jobId: string }
}

export type CheckCanCopyTreeQueryVariables = Types.Exact<{
  input: Types.CopyTreesInput
  languageId?: Types.InputMaybe<Types.Scalars["AspectSelectorId"]>
}>

export type CheckCanCopyTreeQuery = { __typename: "Query"; copyTreesAllowed: boolean }

export const CopyTreesDocument = gql`
  mutation CopyTrees($input: CopyTreesInput!, $languageId: AspectSelectorId) {
    copyTrees(input: $input, languageId: $languageId) {
      ... on FinishedJobResponse {
        jobId
      }
      ... on Error {
        message
      }
    }
  }
`
export type CopyTreesMutationFn = Apollo.MutationFunction<CopyTreesMutation, CopyTreesMutationVariables>

/**
 * __useCopyTreesMutation__
 *
 * To run a mutation, you first call `useCopyTreesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyTreesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyTreesMutation, { data, loading, error }] = useCopyTreesMutation({
 *   variables: {
 *      input: // value for 'input'
 *      languageId: // value for 'languageId'
 *   },
 * });
 */
export function useCopyTreesMutation(
  baseOptions?: Apollo.MutationHookOptions<CopyTreesMutation, CopyTreesMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CopyTreesMutation, CopyTreesMutationVariables>(CopyTreesDocument, options)
}
export type CopyTreesMutationHookResult = ReturnType<typeof useCopyTreesMutation>
export type CopyTreesMutationResult = Apollo.MutationResult<CopyTreesMutation>
export type CopyTreesMutationOptions = Apollo.BaseMutationOptions<CopyTreesMutation, CopyTreesMutationVariables>
export const CheckCanCopyTreeDocument = gql`
  query CheckCanCopyTree($input: CopyTreesInput!, $languageId: AspectSelectorId) {
    copyTreesAllowed(input: $input, languageId: $languageId)
  }
`

/**
 * __useCheckCanCopyTreeQuery__
 *
 * To run a query within a React component, call `useCheckCanCopyTreeQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckCanCopyTreeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckCanCopyTreeQuery({
 *   variables: {
 *      input: // value for 'input'
 *      languageId: // value for 'languageId'
 *   },
 * });
 */
export function useCheckCanCopyTreeQuery(
  baseOptions: Apollo.QueryHookOptions<CheckCanCopyTreeQuery, CheckCanCopyTreeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CheckCanCopyTreeQuery, CheckCanCopyTreeQueryVariables>(CheckCanCopyTreeDocument, options)
}
export function useCheckCanCopyTreeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CheckCanCopyTreeQuery, CheckCanCopyTreeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CheckCanCopyTreeQuery, CheckCanCopyTreeQueryVariables>(CheckCanCopyTreeDocument, options)
}
export type CheckCanCopyTreeQueryHookResult = ReturnType<typeof useCheckCanCopyTreeQuery>
export type CheckCanCopyTreeLazyQueryHookResult = ReturnType<typeof useCheckCanCopyTreeLazyQuery>
export type CheckCanCopyTreeQueryResult = Apollo.QueryResult<CheckCanCopyTreeQuery, CheckCanCopyTreeQueryVariables>
