import * as React from "react"
import { SVGProps } from "react"

const SvgTaxOptLogOpOr = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 19.5 19.5" {...props}>
    <defs>
      <style>
        {
          ".TaxOpt_LogOp_Or_svg__cls-1{fill:none;stroke:#0050b3;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px}"
        }
      </style>
    </defs>
    <g id="TaxOpt_LogOp_Or_svg__Komponente_35_1" data-name="Komponente 35 \u2013 1" transform="translate(.75 .75)">
      <g id="TaxOpt_LogOp_Or_svg__Gruppe_4767" data-name="Gruppe 4767" transform="translate(-3 -3)">
        <path
          id="TaxOpt_LogOp_Or_svg__Pfad_4636"
          data-name="Pfad 4636"
          className="TaxOpt_LogOp_Or_svg__cls-1"
          d="M21 9.6v10.8a.6.6 0 0 1-.6.6H9.6a.6.6 0 0 1-.6-.6V9.6a.6.6 0 0 1 .6-.6h10.8a.6.6 0 0 1 .6.6Z"
        />
        <path
          id="TaxOpt_LogOp_Or_svg__Pfad_4637"
          data-name="Pfad 4637"
          className="TaxOpt_LogOp_Or_svg__cls-1"
          d="M15 3.6v10.8a.6.6 0 0 1-.6.6H3.6a.6.6 0 0 1-.6-.6V3.6a.6.6 0 0 1 .6-.6h10.8a.6.6 0 0 1 .6.6Z"
        />
      </g>
    </g>
  </svg>
)

export default SvgTaxOptLogOpOr
