import React, { useContext, useMemo } from "react"
import { useTreeNodeFilter } from "../components/FilteringTreeNodeWrapper"
import { mapFrom, notEmpty } from "../utilities"
import type { NodeDisplay } from "../components/PreviewConfig"
import type { TreeNode } from "./nodeContext"
import type { ReadyPreviewContentModel } from "./types"
import { getNodeMappingRule } from "../mapping/mappingUtilities"

type Indentation = {
  /** The indentation level for the item */
  level: number
  /** Indicates if this item should be ignored while calculating the section numbers. */
  ignoreNumbering?: boolean
}

/**
 *  Returns a Map which contains the section number for a given treeNodeId.
 */
export function calculateNumberingForTreeNode(
  treeNodeFilter: (treeNodeId: string | undefined) => NodeDisplay,
  indentations: Array<{ node: TreeNode; indentation: Indentation }>,
) {
  const sections = new Map<string, number[]>()
  let lastSection = new Array<number>()
  for (let idx = 0; idx < indentations.length; idx++) {
    const {
      node,
      indentation: { level, ignoreNumbering },
    } = indentations[idx]

    const previousNodeLevel = lastSection.length
    const currentNodeLevel = level
    if (ignoreNumbering || !treeNodeFilter(node.id).visible) {
      //don't change sections
      continue
    }

    if (currentNodeLevel === previousNodeLevel && currentNodeLevel !== 0 && idx !== 0) {
      // increase last value
      lastSection = [...lastSection.slice(0, currentNodeLevel - 1), lastSection[lastSection.length - 1] + 1]
    } else if (currentNodeLevel > previousNodeLevel) {
      // add one level
      lastSection.push(1)
    } else if (currentNodeLevel < previousNodeLevel) {
      // remove one level and increase last value
      lastSection = lastSection.slice(0, currentNodeLevel || 1)
      lastSection = [...lastSection.slice(0, currentNodeLevel - 1), lastSection[lastSection.length - 1] + 1]
    }

    sections.set(node.id, Array.from(lastSection))
  }
  return sections
}

const numberingContext = React.createContext({
  indentations: new Array<{
    indentation: Indentation
    node: TreeNode
  }>(),
  numberingByTreeNodeId: new Map<string, number[]>(),
})
const CtxProv = numberingContext.Provider

type NumberingProviderProps = {
  treeNodes: TreeNode[]
  previewContentModel: ReadyPreviewContentModel
}

/**
 * Creates an array which contains the indentations for the given treeNodes
 */
export function createIndentationsArray(
  treeNodes: TreeNode[],
  validChildrenById: Map<string, string[]>,
  initialLevel = 0,
) {
  const treeNodesById = mapFrom(treeNodes, (n) => n.id)
  const parentsById = new Map<string, TreeNode>(
    treeNodes.flatMap((tn) => tn.children?.map((c) => [c, tn] as [typeof c, typeof tn])).filter(notEmpty),
  )
  const roots = treeNodes.filter((tn) => !parentsById.has(tn.id))
  const indentationById = new Map<string, Indentation>(roots.map((r) => [r.id, { level: initialLevel }]))
  let work = [...roots.map((r) => r.id)]

  // traverse all treeNodes and create the indentations for the nodes
  while (work.length > 0) {
    const current = work.pop()
    if (!current) break
    const currentTreeNode = treeNodesById.get(current)

    // the following nodes will be ignored in the numbering scheme.
    if (currentTreeNode) {
      // exclude hidden nodes from numbering
      const mappingRule = getNodeMappingRule(currentTreeNode.node, "FULL")
      if (!mappingRule || (typeof mappingRule !== "string" && mappingRule?.hidden)) {
        const indentationForCurrent = indentationById.get(current)
        if (!indentationForCurrent) throw new Error("The indentation for the current node cannot be determined")
        indentationById.set(current, {
          ...indentationForCurrent,
          ignoreNumbering: true,
        })
      }
    }

    if (!currentTreeNode?.children) break

    const indentation = indentationById.get(current)
    if (!indentation) throw new Error("The indentation for the current node cannot be determined")
    const children = validChildrenById.get(currentTreeNode.id)?.filter((child) => treeNodesById.has(child))
    if (!children) continue
    children.forEach((child) => {
      indentationById.set(child, {
        level: indentation.level + (indentation.ignoreNumbering ? 0 : 1), // don't indent children of parents which are ignored in numbering
      })
    })

    work = work.concat(Array.from(children).reverse())
  }

  const indentations = treeNodes.map((n) => ({
    indentation: indentationById.get(n.id) ?? { level: initialLevel },
    node: n,
  }))
  return indentations
}

export function NumberingProvider({
  children,
  treeNodes,
  previewContentModel,
}: React.PropsWithChildren<NumberingProviderProps>) {
  const treeNodeFilter = useTreeNodeFilter()

  const indentations = useMemo(
    () => createIndentationsArray(treeNodes, previewContentModel.validChildrenById),
    [treeNodes, previewContentModel.validChildrenById],
  )

  const numberingByTreeNodeId = useMemo(
    () => calculateNumberingForTreeNode(treeNodeFilter, indentations),
    [indentations, treeNodeFilter],
  )

  const contextData = useMemo(() => ({ indentations, numberingByTreeNodeId }), [indentations, numberingByTreeNodeId])

  return <CtxProv value={contextData}>{children}</CtxProv>
}

/**
 * Gets the section numbering for a given treeNodeId.
 * @param nodeId The treeNodeId for which the section numbers should be returned
 * @returns An array containing the section numbers in order.
 */
export function useNodeNumbering(nodeId: TreeNode["id"]) {
  const ctxState = useContext(numberingContext)
  const { numberingByTreeNodeId } = ctxState
  if (!nodeId) return []
  return numberingByTreeNodeId.get(nodeId) ?? []
}
