import React from "react"
import type { Node } from "unist"
import { ImageDescriptions, StyledCard } from "./ImageDescriptions"
import { useScrollToFocusedComment } from "../../annotationFocusState"
import { ImageDisplayContainer } from "./ImageMedia"
import { LayoutInfo } from "./LayoutInfo"
import type { ExtractByTypeName } from "@st4/graphql"
import { ST4NodeWithContentName } from "../../../graphql/applicationQueries"

type AnimationMediaProps = {
  title: React.ReactNode
  xastNode?: Node
  legendNode?: Node
  mediaInfo: ExtractByTypeName<
    "AnimationMediaInfo" | "VideoMediaInfo",
    NonNullable<ST4NodeWithContentName<"MediaContent">["content"]["media"]>
  >
  mediaNode: Parameters<typeof ImageDescriptions>[0]["mediaNode"]
  comments: { comment: { id: string } }[]
  isGroup: boolean
}

export function AnimationMedia({
  title,
  xastNode,
  legendNode,
  mediaInfo,
  mediaNode,
  isGroup,
  comments,
}: AnimationMediaProps) {
  const { height, width, thumbnail } = mediaInfo
  const isCommented = comments.length > 0
  const [scrollTargetProps, { isFocused, isPrefocused }] = useScrollToFocusedComment(
    comments.map(({ comment: { id } }) => id),
  )

  const displayHeight = !height ? 100 : height
  const displayWidth = !width ? 100 : width

  const cover = (
    <ImageDisplayContainer>
      <img className="thumbnail" src={thumbnail ?? ""} height={displayHeight} width={displayWidth} />
    </ImageDisplayContainer>
  )

  const layoutInfo = <LayoutInfo mediaInfo={mediaInfo} xastNode={xastNode}></LayoutInfo>

  return (
    <div {...scrollTargetProps}>
      <StyledCard
        cover={cover}
        $isGroup={isGroup}
        $isCommented={isCommented}
        $isCommentPrefocused={isPrefocused}
        $isCommentFocused={isFocused}
      >
        <ImageDescriptions mediaNode={mediaNode} title={title} legendNode={legendNode} layoutInfo={layoutInfo} />
      </StyledCard>
    </div>
  )
}
