import React from "react"
import styled from "styled-components"
import { Label } from "./structure"

import type { XastRendererProps } from "../XASTRenderer"
import { notEmpty } from "@st4/graphql"

const InlineList = styled.ul`
  display: inline;
  margin: 0;
  padding: 0 15px 0 0;
  & li {
    padding-left: 15px;
    display: inline-block;
    & i {
      font-style: normal;
    }
    & span {
      font-family: monospace;
      font-weight: bold;
    }
  }
`

/**
 * Simply displays elements and their attributes (optional: filter the attributes to show).
 */
export function InlineWithAttributes(
  props: React.PropsWithChildren<XastRendererProps & { filterAttributes?: string[] }>,
) {
  const limitedAttributes = !!props.filterAttributes && props.filterAttributes.length
  const attributesToUse = limitedAttributes
    ? props.filterAttributes!.map((a) => props.ast.attributes![a])
    : Object.values(props.ast.attributes!)
  const propertyList = attributesToUse.filter(notEmpty).map((a) => {
    return (
      <li key={a.name}>
        <i>{a.name}</i>: <span>{a.value}</span>
      </li>
    )
  })
  return (
    <Inline>
      <Label>{props.ast.tagName}</Label>
      <InlineList>{propertyList}</InlineList>
      {props.children}
    </Inline>
  )
}

export const Inline = styled.span`
  ${Label} {
    font-size: 0.9em;
    display: inline-block;
    margin-right: 5px;
  }
  display: inline;
  border: 1px solid #ddd;
  background: lavender;
  border-radius: 2px;
  padding-left: 3px;
  padding-right: 3px;
`
