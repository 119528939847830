import React from "react"
import { NodeProvider, TreeNode } from "../contentModel/nodeContext"
import type { Mapping } from "../mapping/types"
import type { ST4NodeWithContent } from "../graphql/applicationQueries"
import { usePreviewContentModel } from "../contentModel"
import type { Node } from "unist"
import { XASTViewer } from "./contentComponents"
import type { St4NodeWithContentFragment } from "../graphql/applicationQueries/query.hooks"

interface NodePreviewProps {
  node: St4NodeWithContentFragment
  treeNode: TreeNode
  mapping: Mapping<Node>
}

export default function NodePreview({ node, mapping, treeNode }: NodePreviewProps) {
  return (
    <NodeProvider node={{ ...treeNode, node }}>
      <NodePreviewWithFragments node={node} mapping={mapping} />
    </NodeProvider>
  )
}

function NodePreviewWithFragments({ node }: { mapping: Mapping<Node>; node: ST4NodeWithContent }) {
  const previewContentModel = usePreviewContentModel()
  if (previewContentModel.state !== "ready") return null
  const xast = previewContentModel.xastById.get(node.id)
  if (!xast) return null
  return (
    <div>
      <XASTViewer xast={xast} />
    </div>
  )
}
