import * as React from "react"
import { SVGProps } from "react"

const SvgDatabase = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="1em" height="1em" {...props}>
    <path d="M400 86v88.7c-13.3 7.2-31.6 14.2-54.8 19.9C311.3 203 269.5 208 224 208s-87.3-5-121.2-13.4C79.6 188.9 61.3 182 48 174.7V86l.6-.5c5.3-4.5 15.9-10.7 33.2-16.9C115.9 56.5 166.2 48 224 48s108.1 8.5 142.2 20.6c17.3 6.2 27.8 12.4 33.2 16.9l.6.5zm0 141.5v75.2c-13.3 7.2-31.6 14.2-54.8 19.9C311.3 331 269.5 336 224 336s-87.3-5-121.2-13.4C79.6 316.9 61.3 310 48 302.7v-75.1c13.3 5.3 27.9 9.9 43.3 13.7 38.2 9.3 83.9 14.7 132.7 14.7s94.5-5.4 132.7-14.8c15.4-3.8 30-8.3 43.3-13.7zM48 426v-70.4c13.3 5.3 27.9 9.9 43.3 13.7 38.2 9.3 83.9 14.7 132.7 14.7s94.5-5.4 132.7-14.8c15.4-3.8 30-8.3 43.3-13.7V426l-.6.5c-5.3 4.5-15.9 10.7-33.2 16.9C332.1 455.5 281.8 464 224 464s-108.1-8.5-142.2-20.6C64.5 437.2 54 431 48.6 426.5l-.6-.5zm354.1-2.1s0 .1-.2.2l.1-.2v-.1zm-356.1 0a.3.3 0 1 0 .6-.2.3.3 0 1 0-.6.2zm0-335.8a.3.3 0 1 0 .5.2.3.3 0 1 0-.5-.2zm356-.2a.3.3 0 1 0-.1.6.3.3 0 1 0 .1-.6zM448 432V80c0-44.2-100.3-80-224-80S0 35.8 0 80v352c0 44.2 100.3 80 224 80s224-35.8 224-80z" />
  </svg>
)

export default SvgDatabase
