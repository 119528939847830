import classNames from "classnames"
import React, { PropsWithChildren, MouseEvent } from "react"
import styled, { css, StyledProps } from "styled-components"
import { accentColor, baseColor, ripple } from "@st4/theme-manager"
import { Link } from "react-router-dom"

type SimpleButtonProps = Partial<{
  round: boolean
  flat: boolean
  primary: boolean
  secondary: boolean
}>

const SimpleButton = styled.button<SimpleButtonProps>`
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  ${ripple}
  line-height: 1.42857;
  letter-spacing: 0;
  font-size: ${({ theme }) => theme.token.fontSizeSM}px;
  font-weight: 500;
  margin: ${({ round }) => (round ? "0.5em 0.5em 0.5em 1.0em" : "0.5em")};
  padding: ${({ round }) => (round ? "0.5em" : "0.5em 1em")};
  border: ${(props) =>
    `${!(props.primary || props.secondary) && !props.flat ? "2" : "0"}px solid ` +
    (props.flat ? "transparent" : accentColor(props))};
  border-radius: ${({ round }) => (round ? "50%" : "3px")};
  text-transform: uppercase;
  cursor: pointer;
  background: ${(props) => (props.flat ? "transparent" : baseColor(props))};
  color: ${(props) => (props.flat && (props.primary || props.secondary) ? baseColor(props) : accentColor(props))};
  box-shadow: ${(props) => (!props.flat && (props.primary || props.secondary) ? props.theme.shadows["+1"] : "none")};

  &:hover {
  }

  &::before {
    position: absolute;
    opacity: 0;
    background: ${accentColor};
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    ${(props: SimpleButtonProps) => {
      if (!props.flat && (props.primary || props.secondary))
        return css`
          background: ${({ theme }) => theme.shadows["+2"]};
        `
      return css<SimpleButtonProps>`
        background: ${accentColor};
        color: ${baseColor};
      `
    }}
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    content: " ";
  }

  &:hover::before:not(:disabled) {
    opacity: 0.2;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  }

  &:active,
  &:focus {
    ${(props: StyledProps<SimpleButtonProps>) =>
      (props.flat || !props.primary) &&
      !props.secondary &&
      css<SimpleButtonProps>`
        background: ${baseColor};
        color: ${accentColor};
      `}
    transition: all 0.2s cubic-bezier(.25,.8,.25,1);
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`

function ButtonLogic(props: PropsWithChildren<ButtonProps>) {
  const { to, children, href, className } = props
  if (to)
    return (
      <Link className="Button" style={{ textDecoration: "none", color: "initial" }} to={to}>
        <SimpleButton style={{ flex: 1 }} {...props}>
          {children}
        </SimpleButton>
      </Link>
    )
  else if (href)
    return (
      <a className="Button" style={{ textDecoration: "none", color: "initial" }} href={href}>
        <SimpleButton style={{ flex: 1 }} {...props}>
          {children}
        </SimpleButton>
      </a>
    )
  else return <SimpleButton {...props} className={classNames(className, "Button")} />
}

type ButtonProps = {
  to?: string | { pathname: string; state: any }
  href?: string
  className?: string
  onClick?: (event: MouseEvent) => void
} & Partial<SimpleButtonProps>

const Button_deprecated = styled(ButtonLogic)<ButtonProps>``

export default Button_deprecated
