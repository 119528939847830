import React, { useState } from "react"
import { EllipsisOutlined } from "@ant-design/icons"
import { DropDownProps, Dropdown, MenuProps } from "antd"
import { Tooltip } from "../../../tooltip"
import { useTheme } from "styled-components"
import { Translate, keys } from "@st4/i18n"

export type MeatballMenuButtonProps = {
  iconStyle?: React.CSSProperties
  nodeId?: string
  isDropdownOpen?: boolean
  onDropdownOpenChange?: (isOpen: boolean) => void
  /**
   * For the context menu to be enabled, this and 'onMenuItemClick' have to be defined.
   * Alternatively, if 'dropdownRenderForNode' is defined, the menu will also be enabled.
   */
  onMenuDisplay?: (nodeId: string) => Promise<MenuProps["items"]>
  /**
   * For the context menu to be enabled, this and 'onMenuDisplay' have to be defined.
   * Alternatively, if 'dropdownRenderForNode' is defined, the menu will also be enabled.
   */
  onMenuItemClick?: (nodeId: string, menuKey: string) => void
  /**
   * For the context menu to be enabled, either this or both 'onMenuDisplay' and 'onMenuItemClick' have to be defined
   */
  dropdownRenderForNode?: (nodeId: string, originEntry: React.ReactNode) => React.ReactNode
}

export function MeatballMenuButton(props: MeatballMenuButtonProps) {
  const {
    iconStyle,
    nodeId,
    onMenuDisplay,
    onMenuItemClick,
    dropdownRenderForNode,
    isDropdownOpen,
    onDropdownOpenChange,
  } = props
  const theme = useTheme()
  const [menuItems, setMenuItems] = useState<MenuProps["items"]>([])
  const [isHover, setIsHover] = useState<boolean>(false)
  const isDisabled = !nodeId || ((!onMenuDisplay || !onMenuItemClick) && !dropdownRenderForNode)
  const menu: DropDownProps["menu"] = {
    items: menuItems,
    onClick: handleClick,
  }

  return (
    <span style={{ background: isDropdownOpen ? "rgba(0,0,0,0.05)" : "transparent" }}>
      <Dropdown
        trigger={["click"]}
        menu={menu}
        open={isDropdownOpen}
        onOpenChange={handleOpenChange}
        disabled={isDisabled}
        dropdownRender={(originNode: React.ReactNode) => {
          return (
            <span onMouseLeave={closeDropdown}>
              {dropdownRenderForNode ? dropdownRenderForNode(nodeId ?? "", originNode) : originNode}
            </span>
          )
        }}
      >
        <EllipsisOutlined
          aria-label={"button-more"}
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
          style={{
            fontSize: "18px",
            verticalAlign: "middle",
            marginLeft: "4px",
            marginRight: "4px",
            color: isDisabled ? theme.silverSand : isDropdownOpen || isHover ? theme.midnightBlue : theme.shuttleGrey,
            ...iconStyle,
          }}
        />
      </Dropdown>
    </span>
  )

  function handleOpenChange(isOpen: boolean) {
    onDropdownOpenChange?.(isOpen)

    if (isOpen) {
      if (!nodeId) throw "Unexpected missing id of node"
      if (!onMenuDisplay) return

      onMenuDisplay(nodeId).then((items) => setMenuItems(items))
    }
  }

  function handleClick(menuInfo: { key: string }) {
    if (!nodeId) throw "Unexpected missing id of node"
    if (!onMenuItemClick) throw "Unexpected missing click handler"

    onMenuItemClick(nodeId, menuInfo.key)
    closeDropdown()
  }

  function closeDropdown() {
    onDropdownOpenChange?.(false)
  }
}
