import * as React from "react"
import { SVGProps } from "react"

const SvgSt4TableHeadingRegular = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1em" height="1em" {...props}>
    <path d="M464 160H48c-26.5 0-48 21.5-48 48v256c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48V208c0-26.5-21.5-48-48-48zM232 460H64c-8.8 0-16-5.8-16-12.8V364h184v96zm0-152H48v-96h184v96zm232 139.2c0 7-7.2 12.8-16 12.8H280v-96h184v83.2zm0-139.2H280v-96h184v96zm0-308H48C21.5 0 0 21.5 0 48v16c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48z" />
  </svg>
)

export default SvgSt4TableHeadingRegular
