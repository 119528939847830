import styled from "styled-components"

// prettier-ignore
const Card_deprecated = styled.div`
    box-shadow: ${({theme})=>theme.shadows["+1"]};
    box-sizing: border-box;
    background: ${props => props.theme.backgroundColor};
    width: ${props=>props.width};
`

export default Card_deprecated
