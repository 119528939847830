import type { NodeFilterFunction } from "./types"
import type { Node } from "unist"
import type { TextContentState } from "../contentModel"
import { isTypename } from "@st4/graphql"

type MappingFunc = (ast: Node, textContentState: TextContentState) => boolean

// Register functions to detect dead links by XML tagname. If the function returns true, the element will be removed
const filterMapping: Map<string, MappingFunc> = new Map([
  ["modref", fragmentCleanup],
  ["li", structuralFragmentCleanup],
  ["safety_intermediateresult", structuralFragmentCleanup],
  ["safety_condition", structuralFragmentCleanup],
  ["safety_instruction", structuralFragmentCleanup],
  ["safety_result", structuralFragmentCleanup],
  ["intermediateresult", structuralFragmentCleanup],
  ["condition", structuralFragmentCleanup],
  ["instruction", structuralFragmentCleanup],
  ["result", structuralFragmentCleanup],
  ["image-container", structuralFragmentCleanup],
  ["table-container", structuralFragmentCleanup],
  ["safety", structuralFragmentCleanup],
])

function hasDeadLink(ast: Node, textContentState: TextContentState) {
  if (!ast.tagName) return false
  const filterFunc = filterMapping.get(ast.tagName)
  if (!filterFunc) return false
  return filterFunc(ast, textContentState)
}

function structuralFragmentCleanup(ast: Node, textContentState: TextContentState) {
  const ref = ast.attributes?.["ref"]?.value
  if (!ref) return false
  const fragmentKey = calculateFragmentKey(ref, textContentState, () => ({ visible: true }))
  if (!fragmentKey) return true
  return !textContentState.fragments.has(fragmentKey[0])
}

function fragmentCleanup(ast: Node, textContentState: TextContentState) {
  const modrefSrc = ast.attributes?.["src"]?.value
  if (ast.tagName !== "modref" || !modrefSrc) return false
  const fragmentKey = calculateFragmentKey(modrefSrc, textContentState, () => ({ visible: true }))
  if (!fragmentKey) return true
  return !textContentState.fragments.has(fragmentKey[0])
}

// Filter out dead links from XAst. If deleted Element is the only child of an element its parent will be deleted as well
// <content> element will always be kept
// Does not consider DTD so the above rule might result in invalid structures according to DTD
export function filterXAst(ast: Node, textContentState: TextContentState) {
  return filter(ast, true, (node) => !hasDeadLink(node, textContentState))
}

function isGroupST4Node(node?: {
  __typename: string
  content?: { __typename: string } | null
}): node is { __typename: string; content: { __typename: "TextGroupContent" } } {
  if (!node) return false
  const content = node.content
  return isTypename("TextGroupContent")(content)
}

/**
 * Gets the CompoundId for the fragment inside the `TextContent.fragments` map.
 * @param linkLabel
 * @param textContentState
 * @param nodeFilter
 * @returns An Array with the Combound Id as the first element and optionally the Containing group as second.
 */
export function calculateFragmentKey(
  linkLabel: string,
  textContentState: TextContentState,
  nodeFilter: NodeFilterFunction,
) {
  const initialFragmentKey = `${textContentState.node.id}_${linkLabel}`

  const initialFragment = textContentState.fragments.get(initialFragmentKey)
  if (!initialFragment) {
    console.error(`Couldn't find fragment with key '${initialFragmentKey}'. Available fragments:`, [
      ...textContentState.fragments.keys(),
    ])
    return null
  }

  if (isGroupST4Node(initialFragment)) {
    // Idea: Nicht hardcoded über den Typen, sondern auch über's mapping? Ähnlich dem NodeMapping?
    const correctSubNodeRef = getCorrectGroupNodeRef(initialFragment, nodeFilter)
    return [`${initialFragment.id}_${correctSubNodeRef.id}`, initialFragmentKey]
  } else {
    return [initialFragmentKey]
  }
}

type IdentifiableGroupNode = { content: { children: { id: string; target?: { id: string } | null }[] } }

export function getCorrectGroupNodeRef(node: IdentifiableGroupNode, nodeFilter: NodeFilterFunction) {
  const validChildren = node.content.children.find((child) => child.target && nodeFilter(child.target).visible)
  return validChildren ?? node.content.children[0]
}

// Create a copy of the Tree where all nodes match the given test. If a node does not match the test it will be deleted.
// If a deleted node was the only child of its parent that parent will be removed too if cascade = true. If that parent
// is the only child in its parent it will be removed too. This can be repeated until it reaches <content>. <content>
// will always be kept
function filter(tree: Node, cascade: boolean, test: (node: Node) => boolean): Node {
  const result = preorder(tree)
  if (!result) throw Error("Filtering Xast should not return null because root node must not be filtered")
  return result

  function preorder(node: Node) {
    const children: Node[] = []
    if (!test(node) && canBeDeleted(node)) return null
    if (node.children) {
      for (const child of node.children) {
        const result = preorder(child)
        if (result) {
          children.push(result)
        }
      }
      if (cascade && node.children.length > 0 && children.length === 0) {
        // If node is <content> it cannot be deleted so we just remove all children from <content>
        if (canBeDeleted(node)) {
          return null
        }
        return { ...node, children: [] }
      }
    }
    return { ...node, children }
  }

  function canBeDeleted(node: Node) {
    return node.type !== "root" && node.tagName !== "content"
  }
}
