import { Alert } from "antd"
import styled from "styled-components"

export const QuanosAlert = styled(Alert)`
  background: ${({ theme }) => theme.warningBG};
  height: 100%;
  align-items: center;

  .ant-alert-message {
    color: ${({ theme }) => theme.shuttleGrey};
  }

  .ant-alert-icon {
    color: ${({ theme }) => theme.warning};
    font-size: 18px;
  }
`
