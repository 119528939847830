export default {
  paths: [
    "M511.6 96c-229.6 0-415.6 186.4-415.6 416s186 416 415.6 416c230 0 416.4-186.4 416.4-416s-186.4-416-416.4-416zM512 844.8c-183.8 0-332.8-149-332.8-332.8s149-332.8 332.8-332.8 332.8 149 332.8 332.8-149 332.8-332.8 332.8z",
    "M532.8 304h-62.4v249.6l218.4 131 31.2-51.2-187.2-111v-218.4z",
  ],
  attrs: [
    {
      opacity: 0.9,
    },
    {
      opacity: 0.9,
    },
  ],
  grid: 0,
  tags: ["md-time"],
}
