import React from "react"
import { Spin } from "antd"
import { Alert } from "@schema/styled-ui"
import { SearchComponentProps } from "./SearchResultList"
import { NodeClassFacet, ModifiedByFacet, ModifiedOnFacet, TaxonomyFacet, getRefinementId } from "./facet-renderers"
import { SearchFacet, SearchFacetItem, SearchRefinement } from "../types"
import { useTranslation } from "react-i18next"
import { keys } from "@st4/ui-strings"
import { getResourceByName } from "../helpers"

export const baseTestId = "searchFacetsList"

type SearchFacetsListProps = SearchComponentProps & {
  searchFacets?: SearchFacet[]
  selectedRefinements?: SearchRefinement[]
  onSearchRefinementsChanged: (newRefinements: SearchRefinement[]) => void
}

export function SearchFacetsList(props: SearchFacetsListProps) {
  const { t } = useTranslation()

  if (props.loading)
    return (
      <div style={{ paddingLeft: "8px", paddingRight: "8px" }}>
        <Alert isInfo>
          <Spin size={"small"} />
          &nbsp;
          <span data-testid={`${baseTestId}-info-loading`}>
            {t(keys.message.search.info.searchingForTerm, { searchTerm: props.searchTerm })}
          </span>
        </Alert>
      </div>
    )

  if (props.error)
    return (
      <div style={{ paddingLeft: "8px", paddingRight: "8px" }}>
        <Alert isError>
          <span data-testid={`${baseTestId}-error`}>
            {t(keys.message.search.error.fromException, {
              errorName: props.error.name,
              errorMessage: props.error.message,
            })}
          </span>
        </Alert>
      </div>
    )

  if (!props.searchFacets)
    return (
      <div style={{ paddingLeft: "8px", paddingRight: "8px" }}>
        <Alert isError>
          <span data-testid={`${baseTestId}-error`}>{t(keys.message.search.error.corruptResponse)}</span>
        </Alert>
      </div>
    )

  if (props.searchFacets.length == 0)
    return (
      <div style={{ paddingLeft: "8px", paddingRight: "8px" }}>
        <Alert isWarning>
          <span data-testid={`${baseTestId}-warning-noItems`}>
            {t(keys.message.search.warning.noSearchFacets, { searchTerm: props.searchTerm })}
          </span>
        </Alert>
      </div>
    )

  const refinementMap = createRefinementMap(props.searchFacets)
  return (
    <>
      {props.searchFacets.map((facet) => {
        const title = `${
          facet.title?.length
            ? facet.title
            : t(getResourceByName(keys.label.search.component.searchFacetsList.facetLabels, facet.key))
        } (${facet.count})`
        const key = `${baseTestId}-facet-${facet.key}`
        if (facet.key == "nodeClass_facet")
          return (
            <NodeClassFacet
              key={key}
              facetKey={facet.key}
              title={title}
              availableValues={facet.items}
              selectedValues={props.selectedRefinements
                ?.filter((r) => r.key == facet.key)
                ?.map((r) => getRefinementId(r.key, r.value))}
              onSubmit={(selectedValues) => submitHandler(facet.key, selectedValues)}
            />
          )
        else if (facet.key == "node_md_facet")
          return (
            <ModifiedByFacet
              key={key}
              facetKey={facet.key}
              title={title}
              availableValues={facet.items}
              selectedValue={
                props.selectedRefinements?.find((r) => r.key == facet.key)
                  ? getRefinementId(facet.key, props.selectedRefinements?.find((r) => r.key == facet.key)?.value ?? "")
                  : undefined
              }
              onSubmit={(selectedValue) => submitHandler(facet.key, selectedValue ? [selectedValue] : [])}
            />
          )
        else if (facet.key == "node_mdd_facet")
          return (
            <ModifiedOnFacet
              key={key}
              facetKey={facet.key}
              title={title}
              availableValues={facet.items}
              selectedValue={
                props.selectedRefinements?.find((r) => r.key == facet.key)
                  ? getRefinementId(facet.key, props.selectedRefinements?.find((r) => r.key == facet.key)?.value ?? "")
                  : undefined
              }
              onSubmit={(selectedValue) => submitHandler(facet.key, selectedValue ? [selectedValue] : [])}
            />
          )
        else
          return (
            <TaxonomyFacet
              key={key}
              facetKey={facet.key}
              title={title}
              availableValues={facet.items}
              selectedValues={props.selectedRefinements
                ?.filter((r) => r.key == facet.key)
                ?.map((r) => getRefinementId(r.key, r.value))}
              onSubmit={(selectedValues) => submitHandler(facet.key, selectedValues)}
            />
          )
      })}
    </>
  )

  function submitHandler(facetKey: string, selectedValues: React.Key[]) {
    // always fire event to clear search query if refinements-list is empty
    const newRefinementSelection = buildNewRefinementSelection(
      facetKey,
      selectedValues.map((v) => v.toString()),
    )
    props.onSearchRefinementsChanged(newRefinementSelection)
  }

  function buildNewRefinementSelection(affectedFacet: string, selectedFacetFilters: string[]) {
    const untouchedRefinements = props.selectedRefinements?.filter((r) => r.key != affectedFacet) || []
    const touchedRefinements = selectedFacetFilters
      .filter((filter) => refinementMap.has(filter)) // ensure that map.get() doesn't return undefined
      .map((filter) => refinementMap.get(filter) as SearchRefinement) // use as-operator to ensure proper typing
    const customDate = "node_mdd_facet\u001fcustom\u001f"
    const customDateRefinement = selectedFacetFilters
      .filter((f) => f.indexOf(customDate) == 0)
      .map((filter) => ({ type: 3, key: affectedFacet, label: "custom", value: filter }))

    return [...untouchedRefinements, ...touchedRefinements, ...customDateRefinement]
  }
}

function createRefinementMap(searchFacets: SearchFacet[]) {
  const refinements = new Map<string, SearchRefinement>()

  function createRefinements(facet: SearchFacet, items: SearchFacetItem[] | null) {
    if (!items || !items.length) return

    items.forEach((item) => {
      refinements.set(getRefinementId(facet.key, item.value), {
        type: facet.type,
        key: facet.key,
        label: `${facet.title}:${item.label}`,
        value: item.value,
      })
      createRefinements(facet, item.items)
    })
  }

  searchFacets.forEach((facet) => createRefinements(facet, facet.items))
  return refinements
}
