import React, { ReactNode } from "react"
import { ConfigProvider, Spin } from "antd"
import deDE from "antd/es/locale/de_DE"
import frFR from "antd/es/locale/fr_FR"
import enUS from "antd/es/locale/en_US"
import jaJP from "antd/es/locale/ja_JP"
import { UserOnboarding } from "../useronboarding"
import { getUserLoginName } from "@schema/utils"
import { useSettingsQuery } from "@st4/settings"

type ConfigurationProviderProps = {
  children: ReactNode
}

export function ConfigurationProvider({ children }: ConfigurationProviderProps) {
  const { data, loading } = useSettingsQuery()
  if (loading || !data) {
    return <Spin delay={500} />
  }
  const languageCode = data.userConfiguration.guiLanguageCode
  return (
    <ConfigProvider locale={getAntDLocale(languageCode)}>
      <UserOnboarding languageCode={languageCode} userName={getUserLoginName()} />
      {children}
    </ConfigProvider>
  )
}

function getAntDLocale(languageCode: string) {
  switch (true) {
    case languageCode.startsWith("de"):
      return deDE
    case languageCode.startsWith("fr"):
      return frFR
    case languageCode.startsWith("ja"):
      return jaJP
    default:
      return enUS
  }
}
