import React from "react"
import {
  ContentWidthLimiting,
  NodeActionsContainerPlaceholder,
  NodeWithNodeActionsContainerWrapper,
  NodeWrapper,
} from "./utils"
import { useIsNodeSelected } from "./utils"
import type { NodeRenderProps } from "./types"
import { NodeHeading } from "../contentComponents/NodeHeading"

export function TextFolder({ treeNode }: NodeRenderProps) {
  const selected = useIsNodeSelected(treeNode)

  return (
    <ContentWidthLimiting>
      <NodeWithNodeActionsContainerWrapper>
        <NodeActionsContainerPlaceholder />
        <NodeWrapper selected={selected} style={{ width: "100%" }}>
          <NodeHeading onClick={() => {}} level={1}>
            {treeNode.label}
          </NodeHeading>
        </NodeWrapper>
      </NodeWithNodeActionsContainerWrapper>
    </ContentWidthLimiting>
  )
}
