import * as Types from "@st4/graphql"

import { gql } from "@apollo/client"
import * as Apollo from "@apollo/client"
const defaultOptions = {} as const
export type SafetyModelQueryVariables = Types.Exact<{
  languageId: Types.Scalars["AspectSelectorId"]
}>

export type SafetyModelQuery = {
  __typename: "Query"
  configuration: {
    __typename: "ConfigurationModel"
    safetyModel?: {
      __typename: "SafetyModel"
      safetyTypes: Array<{
        __typename: "SafetyType"
        displayName?: string | null
        id?: string | null
        icons: Array<{
          __typename: "SafetyIcon"
          use?: string | null
          iconName?: string | null
          contentNode?: {
            __typename: "ST4Node"
            id: string
            content?:
              | { __typename: "LexiconContent" }
              | { __typename: "LexiconEntryContent" }
              | {
                  __typename: "MediaContent"
                  media?:
                    | { __typename: "AnimationMediaInfo"; id: string; thumbnail?: string | null }
                    | { __typename: "ImageMediaInfo"; id: string; src?: string | null; thumbnail?: string | null }
                    | { __typename: "NoMediaInfo" }
                    | {
                        __typename: "VectorGraphicsMediaInfo"
                        id: string
                        svg?: string | null
                        thumbnail?: string | null
                        resourceId?: string | null
                      }
                    | { __typename: "VideoMediaInfo"; id: string; thumbnail?: string | null }
                    | null
                }
              | { __typename: "MediaGroupContent" }
              | { __typename: "ProjectContent" }
              | { __typename: "TermContent" }
              | { __typename: "TextContent" }
              | { __typename: "TextGroupContent" }
              | { __typename: "VariableTableContent" }
              | null
          } | null
        }>
      }>
      severityLevels: Array<{ __typename: "SeverityLevel"; id?: string | null; displayName?: string | null }>
    } | null
  }
}

export const SafetyModelDocument = gql`
  query SafetyModel($languageId: AspectSelectorId!) {
    configuration(languageId: $languageId) {
      safetyModel {
        safetyTypes {
          displayName
          id
          icons {
            use
            iconName
            contentNode {
              id
              ... on ST4Node {
                content {
                  ... on MediaContent {
                    media {
                      ... on ImageMediaInfo {
                        id
                        src
                        thumbnail
                      }
                      ... on VectorGraphicsMediaInfo {
                        id
                        svg
                        thumbnail
                        resourceId
                      }
                      ... on AnimationMediaInfo {
                        id
                        thumbnail
                      }
                      ... on VideoMediaInfo {
                        id
                        thumbnail
                      }
                    }
                  }
                }
              }
            }
          }
        }
        severityLevels {
          id
          displayName
        }
      }
    }
  }
`

/**
 * __useSafetyModelQuery__
 *
 * To run a query within a React component, call `useSafetyModelQuery` and pass it any options that fit your needs.
 * When your component renders, `useSafetyModelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSafetyModelQuery({
 *   variables: {
 *      languageId: // value for 'languageId'
 *   },
 * });
 */
export function useSafetyModelQuery(baseOptions: Apollo.QueryHookOptions<SafetyModelQuery, SafetyModelQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SafetyModelQuery, SafetyModelQueryVariables>(SafetyModelDocument, options)
}
export function useSafetyModelLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SafetyModelQuery, SafetyModelQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SafetyModelQuery, SafetyModelQueryVariables>(SafetyModelDocument, options)
}
export type SafetyModelQueryHookResult = ReturnType<typeof useSafetyModelQuery>
export type SafetyModelLazyQueryHookResult = ReturnType<typeof useSafetyModelLazyQuery>
export type SafetyModelQueryResult = Apollo.QueryResult<SafetyModelQuery, SafetyModelQueryVariables>
