import { SafetyInstructionMapper } from "../components/contentComponents"
import { registerMapping } from "./mappingRegistry"
import type { Node } from "unist"
import { keys } from "@st4/ui-strings"
import type { Mapping } from "./types"

const inlineMapping: Mapping<Node> = {
  b: { as: "EmphasisDisplay", props: { fontWeight: "bold", displayName: "<b>" } },
  i: { as: "EmphasisDisplay", props: { fontStyle: "italic", displayName: "<i>" } },
  sup: { as: "EmphasisDisplay", props: { verticalAlign: "super", fontSize: "0.7em", displayName: "<sup>" } },
  sub: { as: "EmphasisDisplay", props: { verticalAlign: "sub", fontSize: "0.7em", displayName: "<sub>" } },
  emphasis: { as: "EmphasisElement" },
}

const proceduralInstructions: Mapping<Node> = {
  "procedural-instructions": { as: "ProceduralInstructionsMapper" },
  // the following mappings are used if used as a structure fragment (part)
  instruction: { as: "SingleProceduralInstructionItem" },
  condition: { as: "SingleProceduralInstructionItem" },
  intermediateresult: { as: "SingleProceduralInstructionItem" },
  result: { as: "SingleProceduralInstructionItem" },
}
const safetyInstructions: Mapping<Node> = {
  safety: { as: "Block", props: { component: SafetyInstructionMapper } },
  // the following mappings are used if used as a structure fragment (part)
  safety_condition: { as: "SingleProceduralInstructionItem" },
  safety_result: { as: "SingleProceduralInstructionItem" },
  safety_intermediateresult: { as: "SingleProceduralInstructionItem" },
  safety_instruction: { as: "SingleProceduralInstructionItem" },
  embedded_safety: { as: "EmbeddedSafetyInstruction" },
}

const listElements: Mapping<Node> = {
  ol: { as: "List" },
  ul: { as: "List" },
  li: { as: "ListItem" },
}

const images: Mapping<Node> = {
  img: { as: "InlineImage" },
  "image-container": { as: "ImageDisplay" },
  "image-title": { as: "ImageTitle" },
}

const hiddenElements: Mapping<Node> = {
  image: { hidden: true },
  safeties: { hidden: true },
}

const tables: Mapping<Node> = {
  "table-container": { as: "TableMapper" },
  table: { as: "Table" },
  thead: { as: "Structure", props: { component: "thead" } },
  th: { as: "TableCell", props: { component: "th" } },
  tbody: { as: "Structure", props: { component: "tbody" } },
  tr: { as: "TableRow" },
  "table-title": { as: "Emphasis", props: { italic: true } },
  td: { as: "TableCell" },
}

const code = {
  codeblock: { as: "Code" },
  code: { as: "Code", props: { inline: true } },
}

const defaultMapping: Mapping<Node> = {
  title: {
    as: "Heading",
    props: {
      withWidgets: true,
      widgetBottomCenter: "Taxonomies",
      widgetTopCenter: "PersonInfo",
      widgetMiddleRight: "ContentReuse",
      level: 1,
    },
  },
  subheading: { as: "Heading", props: { level: 2 } },
  textmodule: "Section",
  "structure-fragment": "Section",
  content: { as: "NodePage", props: { label: false } },
  part: { as: "NodePage", props: { label: false } },
  p: { as: "TextBlock" },
  br: { as: "LineBreak" },
  "page-break": { as: "PageBreak" },
  link: { as: "ST4Link", props: { label: false } },
  A: { as: "HttpLink", props: { label: false } },
  variable: { as: "Variable", props: { label: true } },
  indexentry: { as: "IndexEntryMapper", props: { label: true } },
  margin_text: { as: "Block", props: { label: true, translationKey: keys.label.preview.marginText } },
  ...safetyInstructions,
  consequence: { as: "TextBlock" },
  cause: { as: "TextBlock" },
  tab: { as: "Tab" },
  "meta-ref": { as: "MetaRef" },
  lexiconlink: { as: "LexiconLink" },
  ...code,
  ...images,
  ...listElements,
  ...proceduralInstructions,
  ...inlineMapping,
  ...hiddenElements,
  ...tables,
}

const SIMPLE_MAPPING: Mapping<Node> = {
  content: "Section",
  textmodule: "Section",
  b: "Inline",
  i: "Inline",
  emphasis: "Inline",
  p: "Block",
  link: "Inline",
  img: "InlineImage",
  "image-container": "ImageDisplay",
  "image-title": "ImageTitle",
}

const TREE_MAPPING: Mapping<Node> = {
  content: "Tree",
  textmodule: "Tree",
  b: "Tree",
  i: "Tree",
  emphasis: "Tree",
  p: "Tree",
  link: "Tree",
  img: "Tree",
  "image-container": "Tree",
  "image-title": "Tree",
  title: "Tree",
  comment: "Tree",
  ul: "Tree",
  li: "Tree",
  "procedural-instructions": "Tree",
  conditions: "Tree",
  instructions: "Tree",
  instruction: "Tree",
  A: "Tree",
  br: "Tree",
}

export function register() {
  registerMapping("COMPONENT", defaultMapping)
  registerMapping("SIMPLE", SIMPLE_MAPPING)
  registerMapping("TREE", TREE_MAPPING)
}
