import type { Node } from "unist"
import { visit, EXIT } from "unist-util-visit"

/**
 * Finds the node which encloses the given offset.
 * @param node Node to search
 * @param offset The offset which should lay inside the found node
 */
export function findNodeAtOffsetStart(node: Node, offset: number) {
  const finder = (node: Node) => {
    const inOffset = (node.position?.start.offset || 0) <= offset && (node.position?.end.offset || 0) >= offset
    if (!inOffset) return false // some completely different node

    const isLeaf = !node.children || node.children.length < 1
    if (isLeaf) return true //already a leaf node

    const children = node.children! //children is save
    if (((children[0].position && children[0].position.start.offset) || -1) > offset) return true //the start offset of first child is larger than the current offset (offset lays in the tag itself)
    if ((children[children?.length - 1].position?.end.offset || -1) < offset) return true //the end offset of last child is smaller than the current offset (offset lays in the end tag)
    return false
  }
  let result: [Node, number, Node] | null = null
  visit(
    node,
    (n, idx, parent) => {
      if (finder(n)) {
        result = [n, idx!, parent!]
        return EXIT
      }
    },
    true,
  )
  return result as null | [Node, number, Node]
}

export { flattenTree } from "./flattenTree"
export { flatFilter } from "./flatFilter"
export { mapWithoutEmptyChildren } from "./mapWithoutEmptyChildren"
export { findNodeByTagName, findNodesByTagName } from "./findNodeByTagName"
