import React from "react"
import styled from "styled-components"
import { Box, BoxProps } from "./structure"
import { EmphasisTooltip } from "./Emphasis"

const InlineCode = styled.code`
  display: inline;
  font-family: monospace;
  white-space: pre-wrap;
  overflow-x: auto;
`

const CodeBlock = styled(Box)`
  display: block;
  font-family: monospace;
  white-space: pre-wrap;
  overflow-x: auto;
`

export function Code(props: React.PropsWithChildren<{ inline: boolean } & BoxProps>) {
  const { children, ast } = props
  if (props.inline)
    return (
      <EmphasisTooltip displayName="<code>">
        <span>
          <InlineCode>{children}</InlineCode>
        </span>
      </EmphasisTooltip>
    )
  return (
    <EmphasisTooltip displayName="<codeblock>">
      <div>
        <CodeBlock ast={ast}>{children}</CodeBlock>
      </div>
    </EmphasisTooltip>
  )
}
