import React from "react"
import { Empty } from "antd"
import { useTheme } from "styled-components"
import { TaxonomyTreeQuery, UpdateTaxonomyMutation } from "./query.hooks"
import { keys } from "@st4/ui-strings"
import { ExtractByTypeName } from "@st4/graphql"
import { Translate } from "@st4/i18n"
import { Icon, Regular } from "@st4/icons"

export function TaxTreeErrors(
  taxTreeLoading: boolean,
  taxTreeError: ExtractByTypeName<"TaxonomyTreeInfoError", TaxonomyTreeQuery["taxonomyTree"]> | null | undefined,
  updateTaxError:
    | ExtractByTypeName<"TaxonomyUpdateError", NonNullable<UpdateTaxonomyMutation["updateTaxonomy"]>>
    | null
    | undefined,
) {
  const lockInfo = taxTreeError?.lockInfo ?? updateTaxError?.lockInfo

  return !taxTreeLoading && (taxTreeError || updateTaxError)
    ? () => {
        if (lockInfo && lockInfo.permissionDenied) {
          return (
            <Empty
              description={<Translate>{keys.message.error.permissionDenied}</Translate>}
              image={<ErrorIcon />}
            ></Empty>
          )
        } else if (updateTaxError?.wasModifiedByOtherProcess) {
          return (
            <Empty
              description={<Translate>{keys.message.error.ontologyLinksChanged}</Translate>}
              image={<ErrorIcon />}
            ></Empty>
          )
        } else {
          return (
            <Empty
              description={<Translate lockedBy={lockInfo?.lockedBy}>{keys.message.error.nodeLocked}</Translate>}
              image={<ErrorLockIcon />}
            ></Empty>
          )
        }
      }
    : undefined
}

function ErrorLockIcon() {
  const theme = useTheme()
  return (
    <div style={{ display: "flex", alignItems: "center", height: "100%", justifyContent: "center" }}>
      <Icon component={Regular.Lock} style={{ fontSize: "40px", color: theme.warningColor }} />
    </div>
  )
}

function ErrorIcon() {
  const theme = useTheme()
  return (
    <div style={{ display: "flex", alignItems: "center", height: "100%", justifyContent: "center" }}>
      <Icon component={Regular.TriangleExclamation} style={{ fontSize: "40px", color: theme.warningColor }} />
    </div>
  )
}
