import * as React from "react"
import { SVGProps } from "react"

const SvgSt4InsertmetarefLight = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" width="1em" height="1em" {...props}>
    <path d="M176 280h-64v-48h64v48zm32 52.5V312h64v20.5h32V232c0-17.7-14.3-32-32-32H112c-17.7 0-32 14.3-32 32v168.7s10.5-29.9 32-44V312h64v20.5h32zm64-52.5h-64v-48h64v48zM114.4 437.1c0-41.4 33.5-74.9 74.9-74.9h30c8.3 0 15 6.7 15 15s-6.7 15-15 15h-30c-24.8 0-44.9 20.1-44.9 44.9s20.1 44.9 44.9 44.9h30c8.3 0 15 6.7 15 15s-6.7 15-15 15h-30c-41.4 0-74.9-33.5-74.9-74.9zm269.6 0c0 41.4-33.5 74.9-74.9 74.9h-30c-8.3 0-15-6.7-15-15s6.7-15 15-15h30c24.8 0 44.9-20.1 44.9-44.9s-20.1-44.9-44.9-44.9h-30c-8.3 0-15-6.7-15-15s6.7-15 15-15h30c41.4 0 74.9 33.5 74.9 74.9zm-179.7-15h89.9c8.3 0 15 6.7 15 15s-6.7 15-15 15h-89.9c-8.3 0-15-6.7-15-15s6.7-15 15-15zM369.9 130 254.1 14.1c-9-9-21.2-14.1-33.9-14.1H64C28.7 0 0 28.7 0 64v384c0 35.3 28.7 64 64 64h73c-11.5-8.3-21.1-19.3-27.7-32H64c-17.7 0-32-14.3-32-32V64c0-17.7 14.3-32 32-32h128v112c0 26.5 21.5 48 48 48h112v164.7c12.8 6.8 23.7 16.6 32 28.4V163.9c0-12.7-5.1-24.9-14.1-33.9zM240 160c-8.8 0-16-7.2-16-16V32.5c2.8.7 5.4 2.1 7.4 4.2l115.9 115.9c2.1 2.1 3.5 4.6 4.2 7.4H240z" />
  </svg>
)

export default SvgSt4InsertmetarefLight
