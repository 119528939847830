import React, { useCallback, useRef, useState } from "react"
import { Translate } from "@st4/i18n"
import { useAspectId } from "@st4/settings"
import { Blade, defineMemoizedBlade } from "@st4/tasks"
import { keys } from "@st4/ui-strings"
import { DragDropNavigationTreeViewlet } from "~/components/navigationtree"
import { DragItem, TreeDropInfo, TreeItem, TreeOperation, TreeState } from "@st4/ui"
import { produce } from "immer"
import { useDnd } from "./components/dnd"
import { QuanosAlert } from "~/ui/quanosalert"
import { useContextMenuOperations } from "./components/contextMenu"

type TreeBladeProps = { variant: string; hideNodeAdd: boolean; selectedNode?: string; expanded: string[] }

type Messages =
  | {
      action: "onNodeSelected"
      payload: string
    }
  | {
      action: "onNodeAdd"
      payload: TreeItem
    }
  | {
      action: "onNodeRemoved"
      payload: string
    }
  | {
      action: "onNodeExpandChange"
      payload: { id: string; isExpanded: boolean }
    }
  | {
      action: "onAllExpandedNodesChanged"
      payload: string[]
    }

export const TreeBlade = defineMemoizedBlade<TreeBladeProps, Messages>(
  "Navigation",
  function TreeBladeWithContext(props) {
    const aspectId = useAspectId()
    const { variant, hideNodeAdd, selectedNode, sendMessage, expanded } = props
    const treeState = useRef<TreeState>()
    const { canDropDraggedNodeOnTargets, handleDrop } = useDnd()
    const [errorMessageKey, setErrorMessageKey] = useState<string | null>(null)
    const onNodeRemovedCallback = useCallback(
      (removedNodeId: string) => {
        function selectAdjacentNode(nodeId: string) {
          const nodeToSelect =
            (treeState.current?.findNextSibling(nodeId) ||
              treeState.current?.findPreviousSibling(nodeId) ||
              treeState.current?.findParent(nodeId)) ??
            ""
          sendMessage({ action: "onNodeSelected", payload: nodeToSelect })
        }

        if (treeState.current?.selected === removedNodeId) {
          selectAdjacentNode(removedNodeId)
        }
        sendMessage({ action: "onNodeRemoved", payload: removedNodeId })
      },
      [sendMessage],
    )
    const onSelectCallback = useCallback(
      (node) => sendMessage({ action: "onNodeSelected", payload: node.id }),
      [sendMessage],
    )
    const onNodePathChangedCallback = useCallback(
      (oldNodeId, newNodeId) => {
        if (oldNodeId === selectedNode) {
          sendMessage({ action: "onNodeSelected", payload: newNodeId })
        }
      },
      [sendMessage, selectedNode],
    )
    const onExpandChangeCallback = useCallback(
      (id, isExpanded) => sendMessage({ action: "onNodeExpandChange", payload: { id, isExpanded } }),
      [sendMessage],
    )
    const onAllExpandedNodesChangedCallback = useCallback(
      (allExpandedNodes) => sendMessage({ action: "onAllExpandedNodesChanged", payload: allExpandedNodes }),
      [sendMessage],
    )
    const onDropCallback = useCallback(
      (dropInfo: TreeDropInfo) => handleDrop(dropInfo, treeState, setErrorMessageKey),
      [handleDrop, treeState, setErrorMessageKey],
    )
    const contextMenuFacade = useContextMenuOperations(
      onNodeRemovedCallback,
      onNodePathChangedCallback,
      onExpandChangeCallback,
    )

    const handleCreateClickCallback = useCallback(
      (node: TreeItem) => {
        sendMessage({ action: "onNodeAdd", payload: node })
      },
      [sendMessage],
    )

    async function handleQueryCanDropDraggedItemsOnVisibleNodes(
      draggedItems: DragItem[],
      operation: TreeOperation,
      visibleNodes: string[],
    ) {
      if (draggedItems.length > 1) throw "multi drag not implemented"

      const result = await timeout(async () => {
        return canDropDraggedNodeOnTargets(draggedItems[0].id, visibleNodes, operation, setErrorMessageKey)
      }, visibleNodes.length * 10)

      return new Set(result)
    }

    return (
      <>
        <Blade.Content>
          <DragDropNavigationTreeViewlet
            variant={variant}
            aspectId={aspectId}
            fetchPolicy="network-only"
            stateRef={treeState}
            selected={selectedNode}
            onSelect={onSelectCallback}
            expandedNodes={expanded}
            onExpandChange={onExpandChangeCallback}
            onAllExpandedNodesChanged={onAllExpandedNodesChangedCallback}
            draggable
            onDrop={onDropCallback}
            queryCanDropDraggedItemsOnVisibleNodes={handleQueryCanDropDraggedItemsOnVisibleNodes}
            meatballMenu={{
              onMenuDisplay: contextMenuFacade.createMenuForNode,
              onMenuItemClick: contextMenuFacade.handleMenuItemClick,
            }}
            createButton={
              !hideNodeAdd
                ? {
                    onClick: handleCreateClickCallback,
                    tooltipTitle: keys.description.createNewNode.start,
                  }
                : undefined
            }
          />
        </Blade.Content>
        {errorMessageKey ? (
          <Blade.Infobar>
            <QuanosAlert
              closable
              onClose={() => setErrorMessageKey(null)}
              message={<Translate>{errorMessageKey}</Translate>}
            />
          </Blade.Infobar>
        ) : null}
      </>
    )
  },
)
TreeBlade.title = "Navigation"
TreeBlade.size = { S: 6, M: 5, L: 5 }
TreeBlade.reducer = function (previousState, message) {
  switch (message.action) {
    case "onNodeSelected":
      return { ...previousState, selectedNode: message.payload }
    case "onNodeExpandChange": {
      return produce(previousState, (draft) => {
        if (message.payload.isExpanded) {
          if (!draft.expanded.includes(message.payload.id)) {
            draft.expanded.push(message.payload.id)
          }
        } else {
          draft.expanded = draft.expanded.filter((n) => n !== message.payload.id)
          if (draft.selectedNode !== message.payload.id && draft.selectedNode?.startsWith(message.payload.id)) {
            draft.selectedNode = undefined
          }
        }
      })
    }
    case "onAllExpandedNodesChanged": {
      return { ...previousState, expanded: message.payload }
    }
  }

  return previousState
}

function timeout<T>(fn: () => T, time: number) {
  return new Promise<T>((resolve) => setTimeout(() => resolve(fn()), time))
}
