import { isTypename } from "@st4/graphql";
import { getMetadata } from "./metadata";
export function getContentInfosFromFragment(fragment, metaData) {
    var ref, ref1, ref2, ref3, ref4, ref5;
    if (!fragment) return;
    if (!isTypename("TextContent")((ref = fragment.contentNode) === null || ref === void 0 ? void 0 : ref.content) && !isTypename("TextGroupContent")((ref1 = fragment.contentNode) === null || ref1 === void 0 ? void 0 : ref1.content)) return;
    var ref6;
    var fragmentId = (ref6 = (ref3 = (ref2 = fragment.compoundId) === null || ref2 === void 0 ? void 0 : ref2.split("_")) === null || ref3 === void 0 ? void 0 : ref3.at(-1)) !== null && ref6 !== void 0 ? ref6 : fragment.compoundId;
    if (!fragmentId) return;
    var _xml;
    var fragmentInfo = {
        id: fragmentId,
        type: isTypename("TextContent")((ref4 = fragment.contentNode) === null || ref4 === void 0 ? void 0 : ref4.content) ? "Fragment" : "FragmentGroup",
        xml: (_xml = (ref5 = fragment.contentNode) === null || ref5 === void 0 ? void 0 : ref5.content.xml) !== null && _xml !== void 0 ? _xml : ""
    };
    var contentMetadata = getMetadata(fragment, metaData);
    return {
        fragment: fragmentInfo,
        fragmentImages: contentMetadata === null || contentMetadata === void 0 ? void 0 : contentMetadata.contentImageInfos
    };
}
