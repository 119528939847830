import classNames from "classnames"
import React, { Component } from "react"
import styled from "styled-components"
import { withTranslation } from "react-i18next"
import uuid from "uuid"
import * as R from "ramda"
import { Option, NullOption } from "./Select_deprecated"
import { Button_deprecated, TextInputString_deprecated } from "../../index"
import debounce from "debounce"
import MdSearch from "@schema/react-ionicons/components/MdSearch"
import MdCheckmark from "@schema/react-ionicons/components/MdCheckmark"
import { ripple } from "@st4/theme-manager"
import { keys } from "@st4/ui-strings"
class SelectMulti_deprecated extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selected: props.defaultValue,
      filter: "",
      _dirty: false,
    }
    this.onFilter = debounce(this.onFilter.bind(this), 150)
  }
  onChange(evt) {
    var selecteds = R.compose(
      R.pluck("value"),
      R.filter((option) => option.selected),
    )([...evt.target.options])
    const values = R.compose(
      R.mergeAll,
      R.map((val) => ({
        [uuid()]: {
          _data: val,
          _display: "",
        },
      })),
    )(selecteds)
    this.setState({
      selected: values,
      _dirty: !R.equals(values, this.props.defaultValue),
    })
  }
  onSave(evt) {
    this.props.onSet(this.state.selected, evt)
    this.setState({
      _dirty: false,
    })
  }
  onFilter(evt) {
    this.setState({
      filter: new RegExp(evt.target.value.replace(/^\s*|\s*$/g, ""), "i"),
    })
  }
  render() {
    const { allowNull = true, filterable = false, t } = this.props
    const defaultValue = R.compose(R.pluck("_data"), R.values)(this.state.selected)
    const filterFn = (option) => {
      const matches = option.value._display.match(this.state.filter)
      const isSelected = R.contains(option.value._data, R.compose(R.pluck("_data"), R.values)(this.state.selected))
      return matches || isSelected
    }
    const options = this.state.filter ? this.props.options.filter(filterFn) : this.props.options
    return (
      <div className={classNames("SelectMulti", this.props.className)}>
        {filterable ? (
          <TextInputString_deprecated
            label={
              <span className="SelectMulti--filter--label">
                <MdSearch />
                &nbsp;
                {t(keys.label.general.filter)}
              </span>
            }
            defaultValue={{ _data: "" }}
            onChange={(evt) => {
              evt.persist()
              this.onFilter(evt)
            }}
          />
        ) : null}
        <select
          className="SelectMulti--select"
          multiple
          onChange={this.onChange.bind(this)}
          size={10}
          {...R.pick(["name"])(this.props)}
          value={defaultValue}
        >
          {allowNull ? <NullOption /> : null}
          {options.map((option) => (
            <Option {...option} id={this.props.id} key={option.value._data} />
          ))}
        </select>
        <Button_deprecated onClick={this.onSave.bind(this)} disabled={!this.state._dirty}>
          <MdCheckmark />
          &nbsp;
          {t(keys.button.general.save)}
        </Button_deprecated>
      </div>
    )
  }
}

export default styled(withTranslation()(SelectMulti_deprecated))`
  flex: 1;
  & .SelectMulti--filter--label {
    display: flex;
    align-items: center;
  }
  & .SelectMulti--select {
    display: block;
    flex: 1;
    width: 100%;
    outline: none;
    border-width: 1px;
    border-color: #ddd;
  }
  & .SelectMulti--select:focus {
    border-color: #555;
  }
  & .SelectMulti--select option {
    ${ripple} padding: 0.25em;
    font-size: 1rem;
    font-family: Roboto;
  }
  & .SelectMulti--select option:checked {
    font-weight: bold;
  }
`
