import React, { ReactNode, useEffect, useLayoutEffect, useState, CSSProperties } from "react"

function useDelayUnmount(isVisible: boolean, delayTime: number) {
  const [shouldMount, setShouldMount] = useState(false)
  const [shouldShow, setShouldShow] = useState(false)
  useEffect(() => {
    let timeoutId: number
    if (isVisible && !shouldMount) {
      setShouldMount(true)
    } else if (!isVisible && shouldMount) {
      timeoutId = window.setTimeout(() => setShouldMount(false), delayTime)
      setShouldShow(false)
    }
    return () => clearTimeout(timeoutId)
  }, [isVisible, delayTime, shouldMount])
  useLayoutEffect(() => {
    let timeoutId: number
    if (isVisible && !shouldMount) {
      timeoutId = window.setTimeout(() => setShouldShow(true), 1)
    }
    return () => clearTimeout(timeoutId)
  }, [isVisible])
  return { shouldMount, shouldShow }
}

/**
 * Fades in/out the child component.
 * @param props
 */
export function FadeComponent(props: {
  isVisible: boolean
  children: (style: CSSProperties) => ReactNode
  delay: number
}) {
  const { shouldMount, shouldShow } = useDelayUnmount(props.isVisible, props.delay)
  const mountedStyle = { opacity: 1, transition: `opacity ${props.delay}ms ease-in` }
  const unmountedStyle = { opacity: 0, transition: `opacity ${props.delay}ms ease-in` }

  return <>{shouldMount ? props.children(shouldShow ? mountedStyle : unmountedStyle) : null}</>
}
