import React from "react"
import { getPropertyEditor, registerPropertyEditor } from "@st4/customize-host"
import { PropertyStringEditor } from "~/components/properties/editors/stringeditor"
import { PropertyDateEditor } from "~/components/properties/editors/dateeditor"
import { PropertyTaxonomyEditor } from "~/components/properties/editors/taxonomyeditor"
import { PropertySelectionEditor } from "~/components/properties/editors/selectioneditor"
import { PropertyMultiSelectionEditor } from "~/components/properties/editors/multiselectioneditor"
import { PropertyIntEditor } from "~/components/properties/editors/inteditor"
import { PropertyDoubleEditor } from "~/components/properties/editors/doubleeditor"
import { PropertyDecimalEditor } from "~/components/properties/editors/decimaleditor"
import type * as Types from "./types"
import { PropertyMultiStringEditor } from "./multistringeditor"
import { FallbackEditor } from "~/ui/editors/stringeditor"
import { Property } from "../sensibleTypes"

registerEditors()

export type PropertyEditorProps = {
  propertyEditor: Property["editor"]
  languageId: string
  fallbackDisplayValue: string
  refetchProperties: () => void
  refetchOnSave?: string[]
}

export function PropertyEditor({
  propertyEditor,
  languageId,
  fallbackDisplayValue,
  refetchProperties,
  refetchOnSave,
}: PropertyEditorProps) {
  // Assumption: Editor in registry understands given PropertyValue Type.
  // Typescript cannot check this because registry is runtime
  const Editor =
    getPropertyEditor(propertyEditor.editorType) ?? (() => <FallbackEditor displayValue={fallbackDisplayValue} />)

  return (
    <Editor
      propertyEditor={propertyEditor}
      languageId={languageId}
      key={`${propertyEditor.node}-${propertyEditor.node.id}`}
      refetchOnSave={refetchOnSave}
      refetchProperties={refetchProperties}
    />
  )
}

function registerEditors() {
  registerPropertyEditor("StringEditor", PropertyStringEditor)
  registerPropertyEditor("DateTimeEditor", PropertyDateEditor)
  registerPropertyEditor(
    "SCHEMA.Ontology.Commons.OntologyTypeEditor, SCHEMA.Ontology.Commons.Impl",
    PropertyTaxonomyEditor,
  )
  registerPropertyEditor("OntologyEditor", PropertyTaxonomyEditor) // For inherited taxonomies on content linked nodes
  registerPropertyEditor("SelectionEditor", PropertySelectionEditor)
  registerPropertyEditor("MultiSelectionEditor", PropertyMultiSelectionEditor)
  registerPropertyEditor("Int32Editor", PropertyIntEditor)
  registerPropertyEditor("DoubleEditor", PropertyDoubleEditor)
  registerPropertyEditor("DecimalEditor", PropertyDecimalEditor)
  registerPropertyEditor("MultiStringEditor", PropertyMultiStringEditor)
}
