import * as React from "react"
import { SVGProps } from "react"

const SvgSt4EditVariantRegular = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="1em" height="1em" {...props}>
    <path d="M400 32H250.5c-17 0-33.3 6.7-45.3 18.7l-176 176c-25 25-25 65.5 0 90.5l133.5 133.5c25 25 65.5 25 90.5 0l176-176c12.1-12 18.8-28.2 18.8-45.2V80c0-26.5-21.5-48-48-48zm0 197.5c0 4.2-1.7 8.3-4.7 11.3l-176 176c-6.2 6.2-16.4 6.2-22.6 0L63.2 283.3c-6.2-6.2-6.2-16.4 0-22.6l176-176c3-3 7.1-4.7 11.3-4.7H400v149.5zm-91.8-111.9L285.8 140l60.1 60.1 22.4-22.4c11.6-11.6 11.6-30.3 0-41.9l-18.2-18.3c-11.6-11.5-30.3-11.5-41.9.1zm-32.8 32.8L167.6 258.3c-4.8 4.8-8.3 10.8-10.3 17.3l-16.4 55.6c-1.2 3.9-.1 8.1 2.8 11s7.1 3.9 11 2.8l55.6-16.4c6.5-1.9 12.5-5.5 17.3-10.3l107.9-107.9-60.1-60z" />
  </svg>
)

export default SvgSt4EditVariantRegular
