import * as Types from "@st4/graphql"

import { gql } from "@apollo/client"
import * as Apollo from "@apollo/client"
const defaultOptions = {} as const
export type MetaRefsWithValuesQueryVariables = Types.Exact<{
  selectedNode: Types.Scalars["ID"]
  aspectId: Types.Scalars["AspectSelectorId"]
  variant: Types.Scalars["String"]
}>

export type MetaRefsWithValuesQuery = {
  __typename: "Query"
  node?:
    | { __typename: "CommentNode" }
    | { __typename: "ExternalSite" }
    | { __typename: "ST4Class" }
    | { __typename: "ST4Node" }
    | {
        __typename: "ST4TreeNode"
        id: string
        node?: {
          __typename: "ST4Node"
          id: string
          metadata: Array<{
            __typename: "MetaDataEntry"
            label?: string | null
            systemName: string
            value?: string | null
            values: Array<string>
            users: Array<string>
          }>
          leavingLinks: Array<
            | {
                __typename: "GenericRef"
                target?:
                  | { __typename: "CommentNode" }
                  | { __typename: "ExternalSite" }
                  | { __typename: "ST4Class" }
                  | { __typename: "ST4Node"; id: string; label?: string | null }
                  | { __typename: "ST4TreeNode" }
                  | null
              }
            | {
                __typename: "NodeRef"
                linkClassName: string
                class?: { __typename: "ST4Class"; label?: string | null } | null
                target?:
                  | { __typename: "CommentNode" }
                  | { __typename: "ExternalSite" }
                  | { __typename: "ST4Class" }
                  | { __typename: "ST4Node"; id: string; label?: string | null }
                  | { __typename: "ST4TreeNode" }
                  | null
              }
          >
        } | null
      }
    | null
}

export type FixedMetaRefsQueryVariables = Types.Exact<{
  aspectId: Types.Scalars["AspectSelectorId"]
}>

export type FixedMetaRefsQuery = {
  __typename: "Query"
  configuration: {
    __typename: "ConfigurationModel"
    metaRefPaths: Array<{ __typename: "MetaRefPath"; id: string; displayName?: string | null }>
    ontologyLinkClasses: Array<{ __typename: "ST4Class"; id: string; className: string; label?: string | null }>
  }
}

export const MetaRefsWithValuesDocument = gql`
  query MetaRefsWithValues($selectedNode: ID!, $aspectId: AspectSelectorId!, $variant: String!) {
    node(id: $selectedNode, languageId: $aspectId, variant: $variant) {
      ... on ST4TreeNode {
        id
        node {
          id
          metadata(users: ["Referable"]) {
            __typename
            label
            systemName
            value
            values
            users
          }
          leavingLinks(linkClass: ["ObjectToOntologyLink"]) {
            __typename
            ... on NodeRef {
              linkClassName
              class {
                label
              }
            }
            target {
              ... on ST4Node {
                id
                label
              }
            }
          }
        }
      }
    }
  }
`

/**
 * __useMetaRefsWithValuesQuery__
 *
 * To run a query within a React component, call `useMetaRefsWithValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMetaRefsWithValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMetaRefsWithValuesQuery({
 *   variables: {
 *      selectedNode: // value for 'selectedNode'
 *      aspectId: // value for 'aspectId'
 *      variant: // value for 'variant'
 *   },
 * });
 */
export function useMetaRefsWithValuesQuery(
  baseOptions: Apollo.QueryHookOptions<MetaRefsWithValuesQuery, MetaRefsWithValuesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<MetaRefsWithValuesQuery, MetaRefsWithValuesQueryVariables>(MetaRefsWithValuesDocument, options)
}
export function useMetaRefsWithValuesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MetaRefsWithValuesQuery, MetaRefsWithValuesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<MetaRefsWithValuesQuery, MetaRefsWithValuesQueryVariables>(
    MetaRefsWithValuesDocument,
    options,
  )
}
export type MetaRefsWithValuesQueryHookResult = ReturnType<typeof useMetaRefsWithValuesQuery>
export type MetaRefsWithValuesLazyQueryHookResult = ReturnType<typeof useMetaRefsWithValuesLazyQuery>
export type MetaRefsWithValuesQueryResult = Apollo.QueryResult<
  MetaRefsWithValuesQuery,
  MetaRefsWithValuesQueryVariables
>
export const FixedMetaRefsDocument = gql`
  query FixedMetaRefs($aspectId: AspectSelectorId!) {
    configuration(languageId: $aspectId) {
      metaRefPaths {
        id
        displayName
      }
      ontologyLinkClasses(filterByUsers: ["Referable"]) {
        id
        className
        label
      }
    }
  }
`

/**
 * __useFixedMetaRefsQuery__
 *
 * To run a query within a React component, call `useFixedMetaRefsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFixedMetaRefsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFixedMetaRefsQuery({
 *   variables: {
 *      aspectId: // value for 'aspectId'
 *   },
 * });
 */
export function useFixedMetaRefsQuery(
  baseOptions: Apollo.QueryHookOptions<FixedMetaRefsQuery, FixedMetaRefsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FixedMetaRefsQuery, FixedMetaRefsQueryVariables>(FixedMetaRefsDocument, options)
}
export function useFixedMetaRefsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FixedMetaRefsQuery, FixedMetaRefsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FixedMetaRefsQuery, FixedMetaRefsQueryVariables>(FixedMetaRefsDocument, options)
}
export type FixedMetaRefsQueryHookResult = ReturnType<typeof useFixedMetaRefsQuery>
export type FixedMetaRefsLazyQueryHookResult = ReturnType<typeof useFixedMetaRefsLazyQuery>
export type FixedMetaRefsQueryResult = Apollo.QueryResult<FixedMetaRefsQuery, FixedMetaRefsQueryVariables>
