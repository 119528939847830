import * as React from "react"
import { SVGProps } from "react"

const SvgSt4InfoNodeLight = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1em" height="1em" {...props}>
    <path d="M63.1 64.3c0-36 28.3-64.3 64.3-64.3h156.9c12.9 0 25.7 5.1 33.4 12.9l115.8 115.8c10.3 10.3 15.4 20.6 15.4 33.4v285.5c0 36-28.3 64.3-64.3 64.3H127.4c-36 0-64.3-28.3-64.3-64.3V64.3zm352.4 128.6H302.3c-25.7 0-48.9-20.6-48.9-48.9V30.9h-126c-18 0-30.9 15.4-30.9 30.9v385.9c0 18 15.4 30.9 30.9 30.9h257.2c18 0 30.9-15.4 30.9-30.9V192.9zm-2.6-38.6L297.2 38.6c-2.6-2.6-5.1-2.6-7.7-5.1v113.2c0 7.7 7.7 15.4 15.4 15.4h113.2c-2.6-5.2-5.2-7.8-5.2-7.8zm-131.2 69.5c0 15.4-10.3 25.7-25.7 25.7s-25.7-10.3-25.7-25.7 10.3-25.7 25.7-25.7 25.7 10.3 25.7 25.7zM204.6 301c0-10.3 7.7-18 18-18H256c10.3 0 18 7.7 18 18v120.9h18c10.3 0 18 7.7 18 18s-7.7 18-18 18h-69.5c-10.3-2.6-18-10.3-18-20.6s7.7-18 18-18h18V316.4h-18c-10.2 0-17.9-7.7-17.9-15.4z" />
  </svg>
)

export default SvgSt4InfoNodeLight
