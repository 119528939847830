import { keys } from "@st4/ui-strings"

export function getEditorMessageKey(messageKey: string, nodeClass: string) {
  switch (messageKey) {
    case "EAC1601":
      return keys.message.editor.canEditCheck.warning.NodeInTranslation
    case "LCC0000":
      return getMessageForNodeClass(keys.message.warning.editorSave.frozenLinkExists.default, nodeClass)
    case "LCC0001":
      return getMessageForNodeClass(keys.message.warning.editorSave.floatingLinkExists.default, nodeClass)
    case "LCC0002":
      return getMessageForNodeClass(keys.message.warning.editorSave.frozenAndFloatingLink.default, nodeClass)
    case "LTE0000":
      return keys.message.warning.editorSave.linkForResourceCouldNotBeCreated
    case "LTE0001":
      return keys.message.warning.editorSave.linkForTextModuleCouldNotBeCreated
    case "LTE0002":
      return keys.message.warning.editorSave.linkForVariableCouldNotBeCreated
    case "LTE0003":
      return keys.message.warning.editorSave.linkForMetarefCouldNotBeCreated
    case "RIL0000":
      return keys.message.warning.editorSave.invalidLinkRemovalForTextModuleCausedInvalidXML
    case "ES1000":
      return keys.message.error.editorSave.editorSessionExpired
    case "ES1100":
    case "ES1101":
      return keys.message.error.editorSave.general
    default:
      return ""
  }
}

export function getMessageForNodeClass(defaultTranslationKey: string, nodeClass: string) {
  const translationKey = defaultTranslationKey.replace(":default", "")
  switch (nodeClass) {
    case "GraficResource":
      return `${translationKey}:graphic`
    case "GraficGroup":
      return `${translationKey}:graphicGroup`
    case "VideoResource":
      return `${translationKey}:video`
    case "VectorGraphics":
      return `${translationKey}:callout`
    case "VariableNode":
      return `${translationKey}:variable`
    case "TextModule2":
      return `${translationKey}:fragment`
    case "TextModuleGroup":
      return `${translationKey}:fragmentGroup`
    case "DataType01":
      return `${translationKey}:metaref`
    default:
      return `${defaultTranslationKey}`
  }
}
