import React, { useCallback, useEffect, useMemo, useState } from "react"
import { Empty, Layout, Result } from "antd"
import { ScopeSwitcher, TaskHeader, TaskTitle } from "../components"
import { TaskData, TaskDefinition, getTaskData } from "../definition/task"
import { useApolloClient } from "@apollo/client"
import { useSearchParams } from "react-router-dom"
import { GenericScopeDefinition, getTransition, ScopeDefinition } from "../definition/scope"
import type { TaskContext } from "@st4/graphql"
import { getKeyParts, isTransitionKey, TransitionKey } from "../definition/transition"
import { produce } from "immer"
import { isTransitionOptions } from "../definition/transitionOptions"
import { bladeManagementReducer, getBladeComponents, Screen } from "./Screen"
import { createMessageHub, MessageHub } from "@st4/message-hub"
import { GenericScreenDefitinition } from "../definition/screen"
import { useTaskDataStoreHandler } from "../stateStore"
import { DefaultMessages } from "../definition/blade"
import { useResettableReducer } from "../hooks"
import { initialize, isReducerHelper } from "./reducerUtilities"

export type TaskProps = {
  className?: string
  definition: TaskDefinition<GenericScopeDefinition>
  instanceData?: Pick<TaskContext, "id" | "data">
}

export type TaskInstance = {
  id?: string
  taskData?: TaskData
  scopeDef?: GenericScopeDefinition
  parameters: Record<string, string>
}

function findStartTransition(transitions: ScopeDefinition["transitions"]) {
  return Object.keys(transitions)
    .filter(isTransitionKey)
    .find((k) => isTransitionOptions("start", transitions[k]))
}

function getScreenTransitions(scope: ScopeDefinition, screen: string) {
  return Object.keys(scope.transitions)
    .filter(isTransitionKey)
    .map((k) => ({ name: k, options: scope.transitions[k] }))
    .filter((t) => getKeyParts(t.name).from === screen)
}

type Scope = TaskDefinition<GenericScopeDefinition>["scopes"]
type Screens<TScope extends keyof Scope> = Exclude<Scope[TScope], undefined>["screens"]
type Blades<TScope extends keyof Scope, TScreen extends keyof Screens<TScope>> = Exclude<
  Screens<TScope>[TScreen],
  undefined
>["blades"][number]

type MessageHubsScopeScreen = {
  [scope in keyof Scope]: {
    [screen in keyof Screens<scope>]: {
      [blade in Blades<scope, screen>["name"]]: MessageHub
    }
  }
}

function useTaskReducer(
  taskDefinition: TaskDefinition,
  initialInstanceData: TaskData,
  messageHubs: MessageHubsScopeScreen,
) {
  const reducerFn = useCallback(
    // if the reducer function changes between renders it gets called twice but only the result
    // of the second call will used for the next render. Therefore we need memoize the reducer function.
    function stateReducer(taskData: TaskData, message: DefaultMessages & { sender: string }): TaskData {
      const currentScopeName = taskData.currentScope
      const currentScreenName = taskData.scopes[currentScopeName]?.currentScreen
      if (!currentScreenName) return taskData
      const currentScreenDefinition = taskDefinition.scopes[currentScopeName]?.screens[
        currentScreenName
      ] as GenericScreenDefitinition
      if (!currentScreenDefinition) return taskData
      const { sender, ...originalMessage } = message

      const currentScopeData = taskData.scopes[currentScopeName]
      if (!currentScopeName || !currentScopeData) return taskData
      const currentScreenData = currentScopeData.screens[currentScreenName]
      if (!currentScreenData) return taskData

      let reducedScreenState = currentScreenData
      reducedScreenState = bladeManagementReducer(reducedScreenState, message)
      const blades = getBladeComponents(currentScreenDefinition)
      const senderBlade = blades.get(sender)

      if (senderBlade?.reducer) {
        const bladeRef = currentScreenDefinition.blades.find((b) => b.name === sender)
        const bladeProps =
          bladeRef?.props?.(reducedScreenState.states[sender], reducedScreenState) ??
          reducedScreenState.states[sender] ??
          {}

        const reducedBladeState = senderBlade.reducer(bladeProps, originalMessage) ?? bladeProps
        if (reducedBladeState !== reducedScreenState.states[sender]) {
          reducedScreenState = {
            ...reducedScreenState,
            states: {
              ...reducedScreenState.states,
              [sender]: reducedBladeState,
            },
          }
        }
      }

      if (currentScreenDefinition.reducer) {
        const reducerResult = currentScreenDefinition.reducer(
          reducedScreenState,
          {
            action: `${sender}:${message.action}`,
            payload: message.payload,
          },
          initialize(reducedScreenState),
        )
        if (reducerResult) {
          if (isReducerHelper(reducerResult)) {
            reducedScreenState = reducerResult.create()
          } else {
            reducedScreenState = reducerResult
          }
        }
      }

      if (reducedScreenState === currentScreenData) {
        return taskData
      }
      return {
        ...taskData,
        scopes: {
          ...taskData.scopes,
          [currentScopeName]: {
            ...currentScopeData,
            screens: {
              ...currentScopeData.screens,
              [currentScreenName]: {
                ...reducedScreenState,
              },
            },
          },
        },
      }
    },
    [taskDefinition],
  )

  const [taskState, dispatch, overrideState] = useResettableReducer(reducerFn, initialInstanceData, [
    taskDefinition,
    // we intentionally don't include the initial state here, because it changes every render (contains current screen state)
  ])
  // Registers the reducer at the message hubs
  useEffect(() => {
    const currentScopeName = taskState.currentScope
    const currentScreenName = taskState.scopes[currentScopeName]?.currentScreen
    if (!currentScreenName) return
    const currentScreenDefinition = taskDefinition.scopes[currentScopeName]?.screens[
      currentScreenName
    ] as GenericScreenDefitinition
    if (!currentScreenDefinition) return

    const map = messageHubs[currentScopeName][currentScreenName]
    const handlers = new Map<string, (msg: DefaultMessages) => Promise<void>>()
    if (map) {
      for (const bladeName in map) {
        const handleMessageWithScreenReducer = async (msg: DefaultMessages) => {
          if (!messageHubs) return
          dispatch({ sender: bladeName, ...msg })
          const state = taskState.scopes[currentScopeName]?.screens[currentScreenName]
          if (state)
            await currentScreenDefinition.observe?.[bladeName]?.(
              msg,
              messageHubs[currentScopeName][currentScreenName],
              state,
            )
        }
        handlers.set(bladeName, handleMessageWithScreenReducer)
        map[bladeName].observe(handleMessageWithScreenReducer)
      }
    }

    return () => {
      if (map) {
        for (const bladeName in map) {
          const handler = handlers.get(bladeName)
          if (handler) {
            map[bladeName].unobserve(handler)
          }
        }
      }
    }
  }, [dispatch, messageHubs, taskDefinition.scopes, taskState])

  return [taskState, dispatch, overrideState] as const
}

function useTaskState(taskDefinition: TaskDefinition<GenericScopeDefinition>, initialData?: Pick<TaskContext, "data">) {
  const apolloClient = useApolloClient()
  const [urlParams, _] = useSearchParams()

  const initialTaskData = useMemo(() => getTaskData(taskDefinition, initialData), [taskDefinition, initialData])

  const messageHubs = useMemo(() => {
    const msgHbs: MessageHubsScopeScreen = {}
    for (const scopeName in taskDefinition.scopes) {
      msgHbs[scopeName] = {}
      for (const screenName in taskDefinition.scopes[scopeName]?.screens) {
        const blades = taskDefinition.scopes[scopeName]?.screens[screenName].blades
        msgHbs[scopeName][screenName] = (blades ?? []).reduce((prev, curr) => {
          const name = curr.name
          const messageHub = createMessageHub()

          return { ...prev, [name]: messageHub }
        }, {})
      }
    }
    return msgHbs
  }, [taskDefinition.scopes])

  const taskReducer = useTaskReducer(taskDefinition, initialTaskData, messageHubs)

  const followTransition = useCallback(
    async function followTransition(transition: TransitionKey, additionalData: Record<string, unknown> = {}) {
      const [taskData, __, overrideState] = taskReducer
      if (!taskData) return Promise.reject()
      const scopeDef = taskDefinition.scopes[taskData.currentScope] as GenericScopeDefinition
      const scopeData = taskData.scopes[taskData.currentScope]
      const parameters = Array.from(urlParams.entries()).reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {})
      if (!scopeDef) return Promise.reject()
      if (!scopeData) return Promise.reject()
      const scopeTransition = scopeDef.transitions[transition]
      if (!scopeTransition) {
        return Promise.reject()
      }
      const transitionKeyParts = getKeyParts(transition)
      const from = transitionKeyParts.from
      const fromScreen = scopeDef.screens[from]
      const fromData = scopeData.screens[from]
      const to = transitionKeyParts.to
      const toScreen = scopeDef.screens[to]
      const toData = scopeData.screens[to] ?? toScreen.initialContextValues
      const transitionFn = getTransition(scopeDef, transition)
      try {
        const targetInstanceData = await transitionFn(
          {
            from: fromScreen,
            to: toScreen,
            scopeData: scopeData,
            type: transitionKeyParts.type,
            screens: scopeDef.screens,
            fromData,
            toData,
            additionalData,
          },
          {
            produceFromCurrentValues(transform) {
              return produce(toData, transform)
            },
            produceFromInitialValues(transform) {
              return produce(toScreen.initialContextValues, transform)
            },
            messageHub: messageHubs[taskData.currentScope][from],
            apolloClient,
            parameters,
          },
        )
        overrideState((state) => ({
          ...state,
          scopes: {
            ...state.scopes,
            [state.currentScope]: {
              currentScreen: to,
              screens: {
                ...state.scopes[state.currentScope]?.screens,
                [to]: targetInstanceData,
              },
            },
          },
        }))
      } catch (e) {
        const error = new Error("Error during execution of transition", { cause: e as Error })
        console.error(error)
        return Promise.reject(e)
      }
      return
    },
    [apolloClient, messageHubs, taskDefinition.scopes, taskReducer, urlParams],
  )

  const changeScope = useCallback(
    async function changeScope(scope: string) {
      const [taskData, __, overrideState] = taskReducer
      if (!taskData) return Promise.reject()

      const scopeData = taskData.scopes[taskData.currentScope]
      if (!scopeData) return Promise.reject()
      overrideState((state) => ({
        ...state,
        currentScope: scope,
      }))
    },
    [taskReducer],
  )
  return { messageHubs, taskReducer, followTransition, changeScope }
}

export function Task(props: TaskProps) {
  const {
    messageHubs,
    taskReducer: [taskData],
    followTransition,
    changeScope,
  } = useTaskState(props.definition, props.instanceData)

  useTaskDataStoreHandler(taskData)

  const scopeData = taskData.scopes[taskData.currentScope]
  const scopeDef = props.definition.scopes[taskData.currentScope]
  const currentScreenName = scopeData?.currentScreen ?? ""

  const transitionInfo = useMemo(() => {
    if (scopeDef)
      return {
        followTransition,
        transitions: getScreenTransitions(scopeDef, currentScreenName),
      }
  }, [followTransition, scopeDef, currentScreenName])

  const taskTitle: React.ReactNode =
    typeof props.definition.displayName === "function"
      ? props.definition.displayName(taskData)
      : props.definition.displayName

  if (!props.instanceData) {
    return null
  }

  if (!scopeDef)
    return (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={`Scope named "${taskData.currentScope}" doesn't exist on Task`}
      />
    )

  if (!scopeData) return <Empty />

  const screenData = scopeData.screens[scopeData.currentScreen]
  if (screenData) {
    if (scopeDef) {
      const screenDef = scopeDef.screens[scopeData.currentScreen]
      return (
        //`data-task` uses screenDef because in the past "screens" were called "tasks".
        //Tests should change their checks to `data-screen`. Then `data-task1` should be changed to `data-task`
        <Layout
          data-task={screenDef.name}
          data-task1={props.definition.name}
          data-screen={screenDef.name}
          style={{ height: "100%" }}
        >
          <TaskHeader>
            <TaskTitle>{taskTitle}</TaskTitle>
            <ScopeSwitcher task={props.definition} currentScope={taskData.currentScope} changeScope={changeScope} />
          </TaskHeader>
          <Layout.Content>
            <Screen
              transitionInfo={transitionInfo}
              messageHubs={messageHubs[taskData.currentScope][scopeData.currentScreen]}
              definition={screenDef}
              data={screenData}
            />
          </Layout.Content>
        </Layout>
      )
    }
  } else if (scopeData.currentScreen === "start") {
    const transition = findStartTransition(scopeDef.transitions)
    return transition ? <Transition transition={transition} followTransition={followTransition} /> : null
  }
  return null
}

type TransitionProps = {
  transition: TransitionKey
  followTransition: (transition: TransitionKey) => Promise<void>
}

function Delay(props: React.PropsWithChildren<{ delay: number }>) {
  const [show, setShow] = useState(false)
  useEffect(() => {
    const handle = window.setTimeout(() => setShow(true), props.delay)
    return () => window.clearTimeout(handle)
  })
  if (show) return <>{props.children}</>
  return null
}

function Transition(props: TransitionProps) {
  useEffect(() => {
    props.followTransition(props.transition)
  }, [props])
  return (
    <Delay delay={500}>
      <Result title="Following Transition" />
    </Delay>
  )
}
