import React, { ReactNode, useMemo, useState, useEffect, useCallback, useRef } from "react"
import styled from "styled-components"
import { Button, Card, Tooltip } from "antd"
import { lighten } from "polished"
import { ConfirmButton } from "@st4/ui"
import { Icon, Regular } from "@st4/icons"

import { useRunningTasksQuery, useCloseTaskMutation } from "../taskcontext"
import { getTaskDefinition } from "../taskRegistry"
import { RunningTasksDocument } from "../taskcontext/query.hooks"
import { notEmpty, TaskContext } from "@st4/graphql"
import { Link, useMatch } from "react-router-dom"

import { makeVar, useApolloClient, useReactiveVar } from "@apollo/client"

import { useTranslation } from "react-i18next"
import { sendInsight } from "@st4/insight"
import { GenericScopeDefinition } from "../definition/scope"
import { getTaskData, TaskData, TaskDefinition } from "../definition/task"
import { z } from "zod"
import { keys } from "@st4/i18n"
import { isSingletonTaskContext, useRunningSingletonTasks } from "../taskcontext/singletontasks"

const activeTaskSchema = z.object({
  tasks: z.array(z.string()),
})
type ActiveTasks = z.infer<typeof activeTaskSchema>

const DEFAULT: ActiveTasks = {
  tasks: [],
}

function parseActiveTaskFromStore(string: string | null) {
  return string ? activeTaskSchema.parse(JSON.parse(string)) : null
}

function arrayEquals<T>(a: T[], b: T[]) {
  return Array.isArray(a) && Array.isArray(b) && a.length === b.length && a.every((val, index) => val === b[index])
}

function activeTasksEqual(a: ActiveTasks, b: ActiveTasks | null) {
  return arrayEquals(a.tasks, b?.tasks ?? [])
}

const INITIAL_ACTIVE_TASKS = JSON.parse(
  localStorage.getItem("activetasks") ??
    (localStorage.setItem("activetasks", JSON.stringify(DEFAULT)), JSON.stringify(DEFAULT)),
)
const CURRENT_ACTIVE_TASKS = makeVar<ActiveTasks>(INITIAL_ACTIVE_TASKS)

function setActiveTasks(activeTasks: ActiveTasks) {
  const activeTasksJson = JSON.stringify(activeTasks)
  if (localStorage.getItem("activetasks") !== activeTasksJson) {
    CURRENT_ACTIVE_TASKS(activeTasks)
    localStorage.setItem("activetasks", activeTasksJson)
  }
}

function useLocalStorage() {
  const client = useApolloClient()
  const handleStorageEvent = useCallback(() => {
    const current = CURRENT_ACTIVE_TASKS()
    const stored = parseActiveTaskFromStore(localStorage.getItem("activetasks"))
    if (stored && !activeTasksEqual(current, stored)) {
      CURRENT_ACTIVE_TASKS(stored)
      client.reFetchObservableQueries()
    }
  }, [client])

  useEffect(() => {
    if (!localStorage.getItem("activetasks")) {
      localStorage.setItem("activetasks", JSON.stringify(DEFAULT))
    }
    window.addEventListener("storage", handleStorageEvent)
    return () => {
      window.removeEventListener("storage", handleStorageEvent)
    }
  }, [handleStorageEvent])
}

function useTaskLocationTracking() {
  const match = useMatch("/task/:id")
  const activeTasks = CURRENT_ACTIVE_TASKS()
  const { data, loading } = useRunningTasksQuery()
  const runningTaskIds = useMemo(
    () => new Set(data.me.runningTasks.filter((t) => !isSingletonTaskContext(t) && !t.closed).map((task) => task.id)),
    [data],
  )
  useEffect(() => {
    if (loading) return
    if (!match) {
      setActiveTasks({
        ...pruneActiveTasks(activeTasks, runningTaskIds),
      })
    } else {
      const taskId = match.params.id
      if (taskId && runningTaskIds.has(taskId)) {
        setActiveTasks(
          pruneActiveTasks(
            {
              tasks: activeTasks.tasks.find((id) => taskId === id) ? activeTasks.tasks : [...activeTasks.tasks, taskId],
            },
            runningTaskIds,
          ),
        )
      }
    }
  }, [activeTasks, loading, match, runningTaskIds])

  return [useReactiveVar(CURRENT_ACTIVE_TASKS), match?.params.id ?? null, data.me.runningTasks] as const
}

function pruneActiveTasks(activeTasks: ActiveTasks, runningTaskIds: Set<string>) {
  return {
    ...activeTasks,
    tasks: activeTasks.tasks.filter((id) => runningTaskIds.has(id)),
  }
}

export type TaskbarProps = Record<string, unknown> & {
  fixedTasks?: { name: string }[]
}

export function Taskbar({ fixedTasks, ...props }: TaskbarProps) {
  const { t } = useTranslation()
  const [collapsed, setCollapsed] = useState(true)
  useLocalStorage()
  const [{ tasks }, selectedTask, runningTasks] = useTaskLocationTracking()
  const singletonTasks = useRunningSingletonTasks()

  const taskMap = runningTasks.reduce(
    (map, task) => map.set(task.id, task),
    new Map<string, (typeof runningTasks)[number]>(),
  )

  const activeTasks = [...singletonTasks, ...tasks.map((id) => taskMap.get(id))]
    .filter(notEmpty)
    .filter((t) => !t.closed)
    .filter((task) => !fixedTasks?.length || fixedTasks.every((ft) => ft.name !== task.id))

  return (
    <CollapsableBar title={t(keys.label.tasks.activeTasks)} collapsed={collapsed} onCollapse={setCollapsed}>
      {fixedTasks?.map((task) => {
        const definition = getTaskDefinition(task.name)
        return (
          !!definition && (
            <TaskbarItem
              key={task.name}
              id={task.name}
              definition={definition}
              collapsed={collapsed}
              selected={selectedTask === task.name}
              fixedTask
            />
          )
        )
      })}
      {activeTasks.map((item) => {
        const definition = getTaskDefinition(item.name)
        return (
          !!definition && (
            <TaskbarItem
              key={item.id}
              id={item.id}
              definition={definition}
              collapsed={collapsed}
              selected={item.id === selectedTask}
              taskContext={item}
            />
          )
        )
      })}
    </CollapsableBar>
  )
}

type TaskbarItemProps = {
  id: string
  definition: TaskDefinition<GenericScopeDefinition>
  collapsed: boolean
  selected: boolean
  taskContext?: TaskContext
  fixedTask?: boolean
}

type SelectableButtonProps = {
  className?: string
  icon: ReactNode
  selected: boolean
  id: string
}

function SelectableButton({ className, icon, id }: SelectableButtonProps) {
  return (
    <Link to={`/task/${id}`} style={{ width: "100%", display: "block" }}>
      <Button className={className} type="link" icon={icon} />
    </Link>
  )
}

const TaskbarButton = styled(SelectableButton)`
  color: ${({ selected, theme }) => (selected ? theme.veryDarkBlue : theme.lightGreyishBlue)} !important;
  border-left: 4px solid ${({ theme, selected }) => (selected ? theme.subtleBlue : "transparent")};
  border-radius: 0;
  width: 100% !important;
  height: auto;
  aspect-ratio: 1 / 1;
  transition: 200ms;
  &:hover {
    --ant-primary-color-hover: white !important;
    background-color: ${({ theme, selected }) =>
      selected ? theme.white : lighten(0.15, theme.primaryColor)} !important;
  }
  background-color: ${({ selected, theme }) => (selected ? theme.white : "transparent")} !important;
`

const StyledTaskbarItem = styled.div`
  width: 100%;
`

const TaskbarItemIconContainer = styled.div`
  font-size: 24px;
  padding: 5px;
`

function TaskbarItem({ id, definition, collapsed, selected, taskContext, fixedTask }: TaskbarItemProps) {
  const taskData = getTaskData(definition, taskContext)
  const displayName: React.ReactNode =
    typeof definition.displayName === "function" ? definition.displayName(taskData) : definition.displayName
  const icon = (
    <TaskbarItemIconContainer>
      <Icon
        component={definition.icon}
        width={"24"}
        height="24"
        color={selected ? "#001C36" : "#FFFFFF"}
        style={{ verticalAlign: "center", marginTop: "7px", marginRight: "5px" }}
      />
    </TaskbarItemIconContainer>
  )
  if (collapsed) {
    return (
      <Tooltip title={displayName} placement="right">
        <StyledTaskbarItem>
          <TaskbarButton key={id} id={id} selected={selected} icon={icon} />
        </StyledTaskbarItem>
      </Tooltip>
    )
  }
  return (
    <StyledTaskbarItem>
      <TaskbarItemCard
        key={id}
        id={id}
        name={definition.name}
        selected={selected}
        icon={icon}
        taskData={taskData}
        fixedTask={fixedTask}
      />
    </StyledTaskbarItem>
  )
}

type TaskbarItemCardProps = {
  id: string
  name: string
  icon: ReactNode
  selected: boolean
  taskData: TaskData
  fixedTask?: boolean
}

const CardContent = styled.div`
  display: flex;
  align-items: center;
  margin: 0;
`

const StyledCard = styled.div<{ $selected: boolean }>`
  .ant-card-head {
    background-color: ${({ theme, $selected }) => ($selected ? lighten(0.1, theme.white) : theme.primaryColor)};
  }
  .ant-card-body {
    background-color: ${({ theme, $selected }) => ($selected ? lighten(0.3, theme.white) : theme.primaryColor)};
    border-left: 4px solid ${({ theme, $selected }) => ($selected ? theme.subtleBlue : "transparent")};
    color: ${({ theme, $selected }) => ($selected ? theme.veryDarkBlue : theme.lightGreyishBlue)};
    &:hover {
      --ant-primary-color-hover: white !important;
      background-color: ${({ theme, $selected }) =>
        $selected ? theme.white : lighten(0.15, theme.primaryColor)} !important;
    }
  }
  .ant-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    color: inherit;
  }
`

function TaskbarItemCard({ id, name, icon, selected, taskData, fixedTask }: TaskbarItemCardProps) {
  const { t } = useTranslation()
  const [closeTask] = useCloseTaskMutation({ refetchQueries: [RunningTasksDocument] })
  const definition = getTaskDefinition(name)
  function handleClose() {
    closeTask({ variables: { id: id } }).then(() =>
      sendInsight({ event: "trackCancelled", properties: { name: name } }),
    )
  }
  if (!definition) return null
  const displayName: React.ReactNode =
    typeof definition.displayName === "function" ? definition.displayName(taskData) : definition.displayName
  return (
    <StyledCard className="StyledCard" $selected={selected}>
      <Link to={`/task/${id}`}>
        <Card size="small" bordered={false}>
          <CardContent>
            {icon}
            <span style={{ flex: 1 /*, textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap"  */ }}>
              {displayName}
            </span>
            {!!fixedTask || !selected ? null : (
              <div style={{ flex: 0 }}>
                <ConfirmButton
                  title={t(keys.label.tasks.closeTask)}
                  confirmMessage={t(keys.message.tasks.confirmClose)}
                  icon={
                    <TaskbarItemIconContainer>
                      <Icon
                        component={Regular.Trash}
                        width={24}
                        height={24}
                        style={{ verticalAlign: "center", marginTop: "5px", marginLeft: "5px" }}
                      />
                    </TaskbarItemIconContainer>
                  }
                  onClick={handleClose}
                />
              </div>
            )}
          </CardContent>
        </Card>
      </Link>
    </StyledCard>
  )
}

const TRANSITION_DURATION = "200ms"

const Container = styled.div<{ collapsed: boolean }>`
  position: relative;
  height: 100%;
  width: ${({ collapsed }) => (collapsed ? "54px" : "250px")};
  box-shadow: ${({ theme }) => theme.shadows["+1"]};
  display: flex;
  flex-direction: column;
  transition: width ${TRANSITION_DURATION};
  overflow-x: auto;
  flex: 0 0 100%;
`
const Title = styled.div<{ collapsed?: boolean }>`
  display: flex;
  flex-flow: ${({ collapsed }) => (collapsed ? "column-reverse" : "row")};
  align-items: center;
  white-space: nowrap;
  padding: 8px 12px;
  color: ${({ theme }) => theme.white};
  background-color: ${({
    theme: {
      token: { colorPrimary },
    },
  }) => colorPrimary};
  font-weight: bold;
`

const TitleText = styled.span<{ collapsed?: boolean }>`
  flex: 1;
  writing-mode: ${({ collapsed }) => (collapsed ? "vertical-lr" : "horizontal-tb")};
  padding-top: ${({ collapsed }) => (collapsed ? "5px" : "0")};
  font-size: 1.1em;
`

const CollapseIcon = styled.div<{ collapsed: boolean }>`
  transform: rotate(${({ collapsed }) => (collapsed ? "180deg" : "0deg")});
  transition: transform ${TRANSITION_DURATION};
  flex: 0;
  padding: 5px;
  cursor: pointer;
  font-size: 1.2em;
  color: ${({ theme }) => theme.white};
`

const HiddenCollapsed = styled.div<{ collapsed?: boolean }>`
  width: 100%;
  padding-top: 12px;
  overflow: ${({ collapsed }) => (collapsed ? "hidden" : "auto")};
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({
    theme: {
      token: { colorPrimary },
    },
  }) => colorPrimary};
`

const Footer = styled.div<{ collapsed?: boolean }>`
  display: flex;
  flex-flow: ${({ collapsed }) => (collapsed ? "column-reverse" : "row")};
  align-items: center;
  white-space: nowrap;
  padding: 8px 12px;
  color: ${({ theme }) => theme.white};
  background-color: ${({
    theme: {
      token: { colorPrimary },
    },
  }) => colorPrimary};
  font-weight: bold;
`

type CollapsableBarProps = {
  title: string
  children: ReactNode
  collapsed: boolean
  onCollapse: (collapsed: boolean) => void
}

export function CollapsableBar(props: CollapsableBarProps) {
  const { title, children } = props
  const isCollapsed = props.collapsed
  const { t } = useTranslation()

  const contentRef = useRef<HTMLDivElement>(null)
  const outerRef = useRef<HTMLDivElement>(null)
  const [hasOverflow, setHasOverflow] = useState<boolean>(
    (outerRef.current?.offsetHeight ?? 0) < (contentRef.current?.offsetHeight ?? 0),
  )

  useEffect(() => {
    if (!contentRef.current || !outerRef.current) {
      return
    }

    const obs = new ResizeObserver(() => {
      setHasOverflow((outerRef.current?.offsetHeight ?? 0) < (contentRef.current?.offsetHeight ?? 0))
    })

    obs.observe(outerRef.current)
    obs.observe(contentRef.current)
    return () => obs.disconnect()
  })

  return (
    <Container className="CollapsableBar" collapsed={isCollapsed}>
      <Title collapsed={isCollapsed}>
        <TitleText collapsed={isCollapsed}>{title}</TitleText>
        <CollapseIcon onClick={() => props.onCollapse(!isCollapsed)} collapsed={isCollapsed}>
          <Icon component={Regular.ChevronsLeft} />
        </CollapseIcon>
      </Title>
      <HiddenCollapsed ref={outerRef} collapsed={isCollapsed}>
        <div ref={contentRef} style={{ width: "100%" }}>
          {children}
        </div>
      </HiddenCollapsed>
      {isCollapsed && hasOverflow ? (
        <Footer>
          <Tooltip title={t(keys.button.general.showMore)} placement="right">
            <CollapseIcon onClick={() => props.onCollapse(!isCollapsed)} collapsed={isCollapsed}>
              <Icon component={Regular.EllipsisVertical} />
            </CollapseIcon>
          </Tooltip>
        </Footer>
      ) : null}
    </Container>
  )
}
