export default {
	"button": {
		"general": {
			"reload": "button:general:reload",
			"save": "button:general:save",
			"saveandclose": "button:general:saveandclose",
			"closemessage": "button:general:closemessage",
			"showMore": "button:general:showMore",
			"showLess": "button:general:showLess",
			"saveAndLoginAgain": "button:general:saveAndLoginAgain",
			"ok": "button:general:ok",
			"cancel": "button:general:cancel",
			"edit": "button:general:edit",
			"openEditor": "button:general:openEditor",
			"inlineEdit": "button:general:inlineEdit",
			"next": "button:general:next",
			"open": "button:general:open",
			"back": "button:general:back",
			"delete": "button:general:delete",
			"exportData": "button:general:exportData",
			"yes": "button:general:yes",
			"no": "button:general:no",
			"select": "button:general:select",
			"changeSelection": "button:general:changeSelection",
			"close": "button:general:close",
			"choose": "button:general:choose"
		},
		"preview": {
			"structure": "button:preview:structure",
			"comment": {
				"delete": "button:preview:comment:delete",
				"edit": "button:preview:comment:edit",
				"reply": "button:preview:comment:reply",
				"allRepliesShow": "button:preview:comment:allRepliesShow",
				"allRepliesHide": "button:preview:comment:allRepliesHide",
				"add": "button:preview:comment:add"
			}
		},
		"workflow": {
			"backToTask": "button:workflow:backToTask",
			"backToList": "button:workflow:backToList",
			"executeAction": "button:workflow:executeAction",
			"bringWindowToFront": "button:workflow:bringWindowToFront"
		},
		"auth": {
			"login": "button:auth:login",
			"loginAgain": "button:auth:loginAgain",
			"logout": "button:auth:logout"
		},
		"navigate": {
			"tree": {
				"toRoot": "button:navigate:tree:toRoot",
				"levelUp": "button:navigate:tree:levelUp",
				"levelDown": "button:navigate:tree:levelDown"
			}
		},
		"deleteNode": {
			"start": "button:deleteNode:start"
		},
		"createVersion": "button:createVersion",
		"blade": {
			"nextStep": "button:blade:nextStep",
			"insert": "button:blade:insert",
			"edit": "button:blade:edit",
			"close": "button:blade:close"
		},
		"dashboard": {
			"myAssignedTasks": {
				"listItem": {
					"tooltip": {
						"destroy": "button:dashboard:myAssignedTasks:listItem:tooltip:destroy"
					}
				}
			}
		},
		"search": {
			"blade": {
				"searchFacetsBlade": {
					"clearRefinements": "button:search:blade:searchFacetsBlade:clearRefinements"
				},
				"searchOptionsBlade": {
					"reset": "button:search:blade:searchOptionsBlade:reset"
				}
			}
		},
		"cut": "button:cut",
		"copyTrees": "button:copyTrees",
		"paste": "button:paste",
		"createContentLinks": "button:createContentLinks",
		"reuseTrees": "button:reuseTrees",
		"removeReuseLinks": "button:removeReuseLinks",
		"ap": {
			"userOverview": {
				"addNewUser": "button:ap:userOverview:addNewUser",
				"editProfile": "button:ap:userOverview:editProfile",
				"resetPassword": "button:ap:userOverview:resetPassword",
				"changeMyPassword": "button:ap:userOverview:changeMyPassword",
				"setUserPresent": "button:ap:userOverview:setUserPresent",
				"setUserAbsent": "button:ap:userOverview:setUserAbsent",
				"setMePresent": "button:ap:userOverview:setMePresent",
				"setMeAbsent": "button:ap:userOverview:setMeAbsent",
				"enableUser": "button:ap:userOverview:enableUser",
				"disableUser": "button:ap:userOverview:disableUser",
				"undeleteUser": "button:ap:userOverview:undeleteUser",
				"deleteUser": "button:ap:userOverview:deleteUser",
				"addUsersToGroups": "button:ap:userOverview:addUsersToGroups",
				"removeUsersFromGroups": "button:ap:userOverview:removeUsersFromGroups",
				"enableUsers": "button:ap:userOverview:enableUsers",
				"disableUsers": "button:ap:userOverview:disableUsers",
				"deleteUsers": "button:ap:userOverview:deleteUsers",
				"undeleteUsers": "button:ap:userOverview:undeleteUsers"
			},
			"userDetails": {
				"create": "button:ap:userDetails:create",
				"save": "button:ap:userDetails:save",
				"cancel": "button:ap:userDetails:cancel"
			},
			"changePassword": {
				"changePassword": "button:ap:changePassword:changePassword",
				"cancel": "button:ap:changePassword:cancel"
			},
			"changeGroupAssignments": {
				"addUsersToGroups": "button:ap:changeGroupAssignments:addUsersToGroups",
				"removeUsersFromGroups": "button:ap:changeGroupAssignments:removeUsersFromGroups",
				"cancel": "button:ap:changeGroupAssignments:cancel"
			},
			"generatePassword": "button:ap:generatePassword",
			"typePassword": "button:ap:typePassword",
			"generateOtherPassword": "button:ap:generateOtherPassword",
			"groupOverview": {
				"addNewGroup": "button:ap:groupOverview:addNewGroup",
				"deleteGroup": "button:ap:groupOverview:deleteGroup",
				"undeleteGroup": "button:ap:groupOverview:undeleteGroup"
			},
			"groupForm": {
				"create": "button:ap:groupForm:create",
				"cancel": "button:ap:groupForm:cancel"
			},
			"licenseDetails": {
				"quotaOverview": {
					"contextMenu": {
						"changeQuota": "button:ap:licenseDetails:quotaOverview:contextMenu:changeQuota",
						"rejectQuota": "button:ap:licenseDetails:quotaOverview:contextMenu:rejectQuota"
					},
					"multiContextMenu": {
						"addQuotas": "button:ap:licenseDetails:quotaOverview:multiContextMenu:addQuotas",
						"changeQuotas": "button:ap:licenseDetails:quotaOverview:multiContextMenu:changeQuotas",
						"rejectQuotas": "button:ap:licenseDetails:quotaOverview:multiContextMenu:rejectQuotas"
					}
				}
			}
		}
	},
	"label": {
		"general": {
			"loading": "label:general:loading",
			"check": "label:general:check",
			"id": "label:general:id",
			"reuses": "label:general:reuses",
			"title": "label:general:title",
			"taxonomy": "label:general:taxonomy",
			"noValue": "label:general:noValue",
			"yes": "label:general:yes",
			"no": "label:general:no",
			"aspect": "label:general:aspect",
			"node_other": "label:general:node_other",
			"settings": "label:general:settings",
			"ui": "label:general:ui",
			"name": "label:general:name",
			"status": "label:general:status",
			"sortBy": "label:general:sortBy",
			"ascending": "label:general:ascending",
			"descending": "label:general:descending",
			"dates": "label:general:dates",
			"people": "label:general:people",
			"details": "label:general:details",
			"field_other": "label:general:field_other",
			"field": "label:general:field",
			"field_one": "label:general:field_one",
			"overview": "label:general:overview",
			"history": "label:general:history",
			"type": "label:general:type",
			"selectOrDropFile": "label:general:selectOrDropFile",
			"fileFormat_other": "label:general:fileFormat_other",
			"chooseFile": "label:general:chooseFile",
			"ignore": "label:general:ignore",
			"filter": "label:general:filter",
			"scaling": "label:general:scaling",
			"indexentry": {
				"_": "label:general:indexentry:_",
				"key": "label:general:indexentry:key",
				"subEntryKey": "label:general:indexentry:subEntryKey",
				"sort": "label:general:indexentry:sort"
			},
			"node": "label:general:node",
			"node_one": "label:general:node_one",
			"systemName": "label:general:systemName",
			"value": "label:general:value",
			"creationTime": "label:general:creationTime",
			"modificationTime": "label:general:modificationTime",
			"misc": "label:general:misc",
			"pleaseSelect": "label:general:pleaseSelect",
			"search": "label:general:search",
			"searchEmpty": "label:general:searchEmpty"
		},
		"explore": {
			"header": "label:explore:header"
		},
		"structure": {
			"header": "label:structure:header",
			"source": {
				"title": "label:structure:source:title"
			},
			"target": {
				"title": "label:structure:target:title"
			}
		},
		"screens": {
			"information": "label:screens:information",
			"project": "label:screens:project"
		},
		"projects": {
			"headline": "label:projects:headline"
		},
		"preview": {
			"informationpool": "label:preview:informationpool",
			"layout": "label:preview:layout",
			"imageDimensions": "label:preview:imageDimensions",
			"imageLayoutType": "label:preview:imageLayoutType",
			"imageSlot": {
				"original": "label:preview:imageSlot:original",
				"web": "label:preview:imageSlot:web",
				"print": "label:preview:imageSlot:print"
			},
			"exifCount": "label:preview:exifCount",
			"answerCount": "label:preview:answerCount",
			"answerCount_one": "label:preview:answerCount_one",
			"answerCount_other": "label:preview:answerCount_other",
			"pagebreak": "label:preview:pagebreak",
			"emphasis": "label:preview:emphasis",
			"table": {
				"border_framed": "label:preview:table:border_framed",
				"border_header": "label:preview:table:border_header",
				"border_noframe": "label:preview:table:border_noframe",
				"border_legend": "label:preview:table:border_legend",
				"border": "label:preview:table:border",
				"scaling_fixed": "label:preview:table:scaling_fixed",
				"scaling_scaled": "label:preview:table:scaling_scaled",
				"scaling": "label:preview:table:scaling"
			},
			"lexicon": {
				"tooltipHeadline": "label:preview:lexicon:tooltipHeadline",
				"glossaryEntry": "label:preview:lexicon:glossaryEntry",
				"subjectField": "label:preview:lexicon:subjectField",
				"term": "label:preview:lexicon:term",
				"validity": "label:preview:lexicon:validity",
				"grammaticalGender": "label:preview:lexicon:grammaticalGender"
			},
			"metaref": {
				"staticHeadline": "label:preview:metaref:staticHeadline",
				"ontologyHeadline": "label:preview:metaref:ontologyHeadline",
				"notFound": "label:preview:metaref:notFound",
				"languageHeadline": "label:preview:metaref:languageHeadline",
				"dynamicReference": "label:preview:metaref:dynamicReference"
			},
			"marginText": "label:preview:marginText"
		},
		"generic": {
			"unknown": "label:generic:unknown",
			"language": "label:generic:language",
			"contentLanguage": "label:generic:contentLanguage",
			"guiLanguage": "label:generic:guiLanguage",
			"enterText": "label:generic:enterText",
			"addToSelection": "label:generic:addToSelection",
			"available": "label:generic:available",
			"removeFromSelection": "label:generic:removeFromSelection",
			"selection": "label:generic:selection",
			"saved": "label:generic:saved"
		},
		"hyperlink": {
			"broken": "label:hyperlink:broken"
		},
		"operation": {
			"syncviews": "label:operation:syncviews"
		},
		"widget": {
			"reusetitle": "label:widget:reusetitle",
			"lastChangeBy": "label:widget:lastChangeBy",
			"createdBy": "label:widget:createdBy"
		},
		"widgets": {
			"contentreuses": "label:widgets:contentreuses",
			"directreuses": "label:widgets:directreuses",
			"indirectreuses": "label:widgets:indirectreuses",
			"reuses": "label:widgets:reuses"
		},
		"warning": {
			"error": "label:warning:error",
			"warning": "label:warning:warning",
			"information": "label:warning:information",
			"question": "label:warning:question",
			"notice": "label:warning:notice"
		},
		"comment": {
			"placeholder": {
				"selection": "label:comment:placeholder:selection",
				"reply": "label:comment:placeholder:reply"
			},
			"typeSelection": "label:comment:typeSelection",
			"resizecommentsview": "label:comment:resizecommentsview",
			"comment": "label:comment:comment",
			"comment_one": "label:comment:comment_one",
			"stateSelection": "label:comment:stateSelection",
			"comment_other": "label:comment:comment_other",
			"context": "label:comment:context",
			"replyTo": "label:comment:replyTo",
			"selectedText": "label:comment:selectedText"
		},
		"workflow": {
			"releaseDetails": "label:workflow:releaseDetails",
			"releaseType": "label:workflow:releaseType",
			"subtreeRelease": "label:workflow:subtreeRelease",
			"askForSignatureDialog": "label:workflow:askForSignatureDialog",
			"activity": "label:workflow:activity",
			"initiator": "label:workflow:initiator",
			"assignee": "label:workflow:assignee",
			"assignee_one": "label:workflow:assignee_one",
			"dueDate": "label:workflow:dueDate",
			"lastChangedDate": "label:workflow:lastChangedDate",
			"createDate": "label:workflow:createDate",
			"startDate": "label:workflow:startDate",
			"linkedNodes": "label:workflow:linkedNodes",
			"modelName": "label:workflow:modelName",
			"noNodeSelected": "label:workflow:noNodeSelected",
			"assignee_other": "label:workflow:assignee_other",
			"customColumDefinition_other": "label:workflow:customColumDefinition_other",
			"action_other": "label:workflow:action_other",
			"parent": "label:workflow:parent",
			"subWorkflow_other": "label:workflow:subWorkflow_other",
			"addFieldValue": "label:workflow:addFieldValue",
			"selectNode": "label:workflow:selectNode",
			"fieldValueOriginal": "label:workflow:fieldValueOriginal",
			"fieldValueUpdated": "label:workflow:fieldValueUpdated",
			"urlOpened": "label:workflow:urlOpened",
			"confirmInput": "label:workflow:confirmInput"
		},
		"releasestate": {
			"isImplicitlyReleased": "label:releasestate:isImplicitlyReleased"
		},
		"auth": {
			"username": "label:auth:username",
			"password": "label:auth:password",
			"loggedOut": "label:auth:loggedOut"
		},
		"nodeproperties": "label:nodeproperties",
		"properties": {
			"_": "label:properties:_",
			"inherited": "label:properties:inherited",
			"search": {
				"titles": "label:properties:search:titles"
			}
		},
		"askRequiredMetadataForm": {
			"requiredMetadata": "label:askRequiredMetadataForm:requiredMetadata"
		},
		"createNewNodeForm": {
			"RelativeInsertionPosition": "label:createNewNodeForm:RelativeInsertionPosition",
			"title": "label:createNewNodeForm:title",
			"configId": "label:createNewNodeForm:configId",
			"loading": {
				"configId": "label:createNewNodeForm:loading:configId"
			},
			"error": {
				"configId": "label:createNewNodeForm:error:configId"
			}
		},
		"relativeInsertionPosition": {
			"AFTER": "label:relativeInsertionPosition:AFTER",
			"BEFORE": "label:relativeInsertionPosition:BEFORE",
			"AS_CHILD": "label:relativeInsertionPosition:AS_CHILD"
		},
		"sort": {
			"default": "label:sort:default",
			"alphanumeric": "label:sort:alphanumeric",
			"descendingalphanumeric": "label:sort:descendingalphanumeric"
		},
		"filter": {
			"all": "label:filter:all",
			"filled": "label:filter:filled",
			"unfilled": "label:filter:unfilled"
		},
		"tasks": {
			"activeTasks": "label:tasks:activeTasks",
			"closeTask": "label:tasks:closeTask"
		},
		"chooseProject": {
			"title": "label:chooseProject:title"
		},
		"dashboard": {
			"myAssignedTasks": {
				"listTitle": "label:dashboard:myAssignedTasks:listTitle",
				"listItem": {
					"dueDate": "label:dashboard:myAssignedTasks:listItem:dueDate",
					"state": "label:dashboard:myAssignedTasks:listItem:state"
				}
			}
		},
		"contentBlade": {
			"title": "label:contentBlade:title"
		},
		"content": {
			"advancedEditor": "label:content:advancedEditor"
		},
		"search": {
			"taskDefinition": {
				"showSearchResult": {
					"displayName": "label:search:taskDefinition:showSearchResult:displayName"
				}
			},
			"blade": {
				"searchFacetsBlade": {
					"title": "label:search:blade:searchFacetsBlade:title"
				},
				"searchResultBlade": {
					"title": "label:search:blade:searchResultBlade:title",
					"titleWithSearchTerm": "label:search:blade:searchResultBlade:titleWithSearchTerm"
				},
				"searchOptionsBlade": {
					"title": "label:search:blade:searchOptionsBlade:title"
				}
			},
			"component": {
				"quickSearchBox": "label:search:component:quickSearchBox",
				"searchFacetsList": {
					"facetLabels": {
						"nodeClass_facet": "label:search:component:searchFacetsList:facetLabels:nodeClass_facet",
						"node_md_facet": "label:search:component:searchFacetsList:facetLabels:node_md_facet",
						"node_mdd_facet": "label:search:component:searchFacetsList:facetLabels:node_mdd_facet"
					},
					"facetItemLabels": {
						"other": "label:search:component:searchFacetsList:facetItemLabels:other",
						"me": "label:search:component:searchFacetsList:facetItemLabels:me",
						"today": "label:search:component:searchFacetsList:facetItemLabels:today",
						"yesterday": "label:search:component:searchFacetsList:facetItemLabels:yesterday",
						"lastWeek": "label:search:component:searchFacetsList:facetItemLabels:lastWeek",
						"from": "label:search:component:searchFacetsList:facetItemLabels:from",
						"until": "label:search:component:searchFacetsList:facetItemLabels:until"
					}
				},
				"searchOptionsSwitch": "label:search:component:searchOptionsSwitch",
				"searchOptionsForm": {
					"searchKeys": "label:search:component:searchOptionsForm:searchKeys",
					"searchKey": {
						"imgContent": "label:search:component:searchOptionsForm:searchKey:imgContent",
						"filetype": "label:search:component:searchOptionsForm:searchKey:filetype"
					},
					"searchVersions": "label:search:component:searchOptionsForm:searchVersions",
					"searchVersion": {
						"latest": "label:search:component:searchOptionsForm:searchVersion:latest",
						"approved": "label:search:component:searchOptionsForm:searchVersion:approved",
						"latestApproved": "label:search:component:searchOptionsForm:searchVersion:latestApproved",
						"all": "label:search:component:searchOptionsForm:searchVersion:all"
					},
					"searchLanguages": "label:search:component:searchOptionsForm:searchLanguages",
					"select": {
						"other": "label:search:component:searchOptionsForm:select:other"
					}
				}
			}
		},
		"help": {
			"flyoutTitle": "label:help:flyoutTitle",
			"link": {
				"help": {
					"title": "label:help:link:help:title"
				},
				"privacy": {
					"title": "label:help:link:privacy:title"
				}
			}
		},
		"account": {
			"flyoutTitle": "label:account:flyoutTitle"
		},
		"resourcePoolBlade": {
			"title": "label:resourcePoolBlade:title"
		},
		"resourceTree": {
			"graphicOptions": "label:resourceTree:graphicOptions",
			"graphicType": "label:resourceTree:graphicType",
			"insertLegendTable": "label:resourceTree:insertLegendTable",
			"graphicLabel": "label:resourceTree:graphicLabel"
		},
		"imagetype": {
			"img_50": "label:imagetype:img_50",
			"img_80": "label:imagetype:img_80",
			"img_fixed_column": "label:imagetype:img_fixed_column",
			"img_fixed_textarea": "label:imagetype:img_fixed_textarea",
			"img_margin": "label:imagetype:img_margin",
			"img_original": "label:imagetype:img_original",
			"img_free": "label:imagetype:img_free"
		},
		"crossReferencePoolBlade": {
			"title": "label:crossReferencePoolBlade:title"
		},
		"fragmentPoolBlade": {
			"title": "label:fragmentPoolBlade:title",
			"listType": "label:fragmentPoolBlade:listType"
		},
		"listtype": {
			"ordered": "label:listtype:ordered",
			"unordered": "label:listtype:unordered",
			"secondLevel": "label:listtype:secondLevel"
		},
		"safetyPoolBlade": {
			"title": "label:safetyPoolBlade:title",
			"titleEdit": "label:safetyPoolBlade:titleEdit"
		},
		"safety": {
			"type": "label:safety:type",
			"severity": "label:safety:severity",
			"symbols": "label:safety:symbols"
		},
		"variablePoolBlade": {
			"title": "label:variablePoolBlade:title",
			"referenceType": "label:variablePoolBlade:referenceType"
		},
		"variable": {
			"referenceType": {
				"cell": "label:variable:referenceType:cell",
				"variable": "label:variable:referenceType:variable",
				"nameOfValueSet": "label:variable:referenceType:nameOfValueSet",
				"variableName": "label:variable:referenceType:variableName"
			}
		},
		"metaRefPoolBlade": {
			"title": "label:metaRefPoolBlade:title",
			"metaRef": "label:metaRefPoolBlade:metaRef"
		},
		"ap": {
			"task": {
				"userManagement": {
					"displayName": "label:ap:task:userManagement:displayName"
				},
				"licenseManagement": {
					"displayName": "label:ap:task:licenseManagement:displayName"
				},
				"productionManagement": {
					"displayName": "label:ap:task:productionManagement:displayName"
				}
			},
			"blade": {
				"userOverview": {
					"displayName": "label:ap:blade:userOverview:displayName"
				},
				"userDetails": {
					"displayName": "label:ap:blade:userDetails:displayName"
				},
				"changePassword": {
					"displayName": "label:ap:blade:changePassword:displayName"
				},
				"changeGroupAssignments": {
					"displayName": "label:ap:blade:changeGroupAssignments:displayName"
				},
				"userClaimsOverview": {
					"displayName": "label:ap:blade:userClaimsOverview:displayName"
				},
				"groupOverview": {
					"displayName": "label:ap:blade:groupOverview:displayName"
				},
				"createGroup": {
					"displayName": "label:ap:blade:createGroup:displayName"
				},
				"licenseOverview": {
					"displayName": "label:ap:blade:licenseOverview:displayName"
				},
				"licenseDetails": {
					"displayName": "label:ap:blade:licenseDetails:displayName"
				}
			},
			"userOverview": {
				"withDeleted": "label:ap:userOverview:withDeleted",
				"searchForUser": "label:ap:userOverview:searchForUser",
				"filterByGroupMembership": "label:ap:userOverview:filterByGroupMembership",
				"deselectAllUsers": "label:ap:userOverview:deselectAllUsers",
				"selectAllUsers": "label:ap:userOverview:selectAllUsers",
				"selectedUserCount": "label:ap:userOverview:selectedUserCount",
				"userCount": "label:ap:userOverview:userCount",
				"userType": {
					"db": "label:ap:userOverview:userType:db",
					"ad": "label:ap:userOverview:userType:ad"
				},
				"userIsAbsent": "label:ap:userOverview:userIsAbsent",
				"userIsDisabled": "label:ap:userOverview:userIsDisabled",
				"userIsDeleted": "label:ap:userOverview:userIsDeleted"
			},
			"userDetails": {
				"editUser": "label:ap:userDetails:editUser",
				"showClaims": "label:ap:userDetails:showClaims",
				"field": {
					"userName": "label:ap:userDetails:field:userName",
					"initialPassword": "label:ap:userDetails:field:initialPassword",
					"displayName": "label:ap:userDetails:field:displayName",
					"email": "label:ap:userDetails:field:email",
					"description": "label:ap:userDetails:field:description",
					"displayLanguage": "label:ap:userDetails:field:displayLanguage",
					"groups": "label:ap:userDetails:field:groups",
					"deputies": "label:ap:userDetails:field:deputies",
					"isAbsent": "label:ap:userDetails:field:isAbsent",
					"disabled": "label:ap:userDetails:field:disabled",
					"isDeleted": "label:ap:userDetails:field:isDeleted",
					"created": "label:ap:userDetails:field:created",
					"modified": "label:ap:userDetails:field:modified"
				}
			},
			"changePassword": {
				"field": {
					"generatedPassword": "label:ap:changePassword:field:generatedPassword",
					"newPassword": "label:ap:changePassword:field:newPassword",
					"newPasswordRetyped": "label:ap:changePassword:field:newPasswordRetyped"
				}
			},
			"changeGroupAssignments": {
				"field": {
					"groups": {
						"add": "label:ap:changeGroupAssignments:field:groups:add",
						"remove": "label:ap:changeGroupAssignments:field:groups:remove"
					}
				}
			},
			"userClaims": {
				"field": {
					"type": "label:ap:userClaims:field:type",
					"value": "label:ap:userClaims:field:value"
				}
			},
			"passwordPolicy": {
				"title": "label:ap:passwordPolicy:title",
				"noPasswordPolicy": "label:ap:passwordPolicy:noPasswordPolicy",
				"minLength": "label:ap:passwordPolicy:minLength",
				"minUpperCaseLetters": "label:ap:passwordPolicy:minUpperCaseLetters",
				"minLowerCaseLetters": "label:ap:passwordPolicy:minLowerCaseLetters",
				"minDigits": "label:ap:passwordPolicy:minDigits",
				"minSpecialCharacters": "label:ap:passwordPolicy:minSpecialCharacters"
			},
			"groupOverview": {
				"withDeleted": "label:ap:groupOverview:withDeleted",
				"searchForGroup": "label:ap:groupOverview:searchForGroup",
				"groupIsDeleted": "label:ap:groupOverview:groupIsDeleted"
			},
			"groupForm": {
				"field": {
					"groupName": "label:ap:groupForm:field:groupName"
				}
			},
			"licenseOverview": {
				"userLicensesOnly": "label:ap:licenseOverview:userLicensesOnly"
			},
			"licenseDetails": {
				"licenseInformation": {
					"detailsAbout": "label:ap:licenseDetails:licenseInformation:detailsAbout",
					"available": "label:ap:licenseDetails:licenseInformation:available",
					"usage": "label:ap:licenseDetails:licenseInformation:usage"
				},
				"quotas": "label:ap:licenseDetails:quotas",
				"quotaOverview": {
					"searchForQuotas": "label:ap:licenseDetails:quotaOverview:searchForQuotas",
					"quotaCount": "label:ap:licenseDetails:quotaOverview:quotaCount",
					"selectAllQuotas": "label:ap:licenseDetails:quotaOverview:selectAllQuotas",
					"deselectAllQuotas": "label:ap:licenseDetails:quotaOverview:deselectAllQuotas",
					"selectedQuotasCount": "label:ap:licenseDetails:quotaOverview:selectedQuotasCount",
					"quotasAssigned": "label:ap:licenseDetails:quotaOverview:quotasAssigned",
					"quotasUsed": "label:ap:licenseDetails:quotaOverview:quotasUsed"
				}
			},
			"licenseType": {
				"floating": "label:ap:licenseType:floating",
				"namedUser": "label:ap:licenseType:namedUser"
			},
			"lm": {
				"blade": {
					"addQuotasForm": {
						"displayName": "label:ap:lm:blade:addQuotasForm:displayName"
					},
					"changeQuotasForm": {
						"displayName": "label:ap:lm:blade:changeQuotasForm:displayName"
					}
				},
				"formHeader": {
					"license": "label:ap:lm:formHeader:license",
					"type": "label:ap:lm:formHeader:type",
					"available": "label:ap:lm:formHeader:available",
					"assigned": "label:ap:lm:formHeader:assigned",
					"free": "label:ap:lm:formHeader:free"
				},
				"quotaNumbers": {
					"min": "label:ap:lm:quotaNumbers:min",
					"max": "label:ap:lm:quotaNumbers:max",
					"exact": "label:ap:lm:quotaNumbers:exact"
				},
				"quotaAssignees": {
					"searchByName": "label:ap:lm:quotaAssignees:searchByName",
					"searchByNameOrGroup": "label:ap:lm:quotaAssignees:searchByNameOrGroup",
					"count": {
						"floating": "label:ap:lm:quotaAssignees:count:floating",
						"namedUsers": "label:ap:lm:quotaAssignees:count:namedUsers"
					},
					"deselectAllAssignees": "label:ap:lm:quotaAssignees:deselectAllAssignees",
					"selectAllAssignees": "label:ap:lm:quotaAssignees:selectAllAssignees",
					"selectedCount": "label:ap:lm:quotaAssignees:selectedCount"
				}
			}
		},
		"meatball": {
			"tooltip": "label:meatball:tooltip"
		}
	},
	"message": {
		"error": {
			"generic": "message:error:generic",
			"noLink": "message:error:noLink",
			"nodeRendering": "message:error:nodeRendering",
			"loadingindirectreuses": "message:error:loadingindirectreuses",
			"removeComment": "message:error:removeComment",
			"addComment": "message:error:addComment",
			"changeCommentState": "message:error:changeCommentState",
			"workflow": {
				"commandNotImplemented": "message:error:workflow:commandNotImplemented"
			},
			"auth": {
				"wrongPassword": "message:error:auth:wrongPassword",
				"generic": "message:error:auth:generic",
				"licenseLimit": "message:error:auth:licenseLimit",
				"allNamedLicenseAllocated": "message:error:auth:allNamedLicenseAllocated"
			},
			"noPreview": "message:error:noPreview",
			"commentLegacy": {
				"errorRights": "message:error:commentLegacy:errorRights",
				"errorInlineNotCommentable": "message:error:commentLegacy:errorInlineNotCommentable",
				"errorInlineOverlap": "message:error:commentLegacy:errorInlineOverlap"
			},
			"inputNotInt": "message:error:inputNotInt",
			"inputNotDouble": "message:error:inputNotDouble",
			"inputNotDecimal": "message:error:inputNotDecimal",
			"permissionDenied": "message:error:permissionDenied",
			"nodeLocked": "message:error:nodeLocked",
			"editorSave": {
				"editorSessionExpired": "message:error:editorSave:editorSessionExpired",
				"general": "message:error:editorSave:general"
			},
			"ontologyLinksChanged": "message:error:ontologyLinksChanged"
		},
		"alert": {
			"noContent": "message:alert:noContent"
		},
		"warning": {
			"guiLanguage": "message:warning:guiLanguage",
			"editorSave": {
				"frozenLinkExists": {
					"default": "message:warning:editorSave:frozenLinkExists:default",
					"graphic": "message:warning:editorSave:frozenLinkExists:graphic",
					"graphicGroup": "message:warning:editorSave:frozenLinkExists:graphicGroup",
					"video": "message:warning:editorSave:frozenLinkExists:video",
					"callout": "message:warning:editorSave:frozenLinkExists:callout",
					"variable": "message:warning:editorSave:frozenLinkExists:variable",
					"fragment": "message:warning:editorSave:frozenLinkExists:fragment",
					"fragmentGroup": "message:warning:editorSave:frozenLinkExists:fragmentGroup",
					"metaref": "message:warning:editorSave:frozenLinkExists:metaref"
				},
				"floatingLinkExists": {
					"default": "message:warning:editorSave:floatingLinkExists:default",
					"graphic": "message:warning:editorSave:floatingLinkExists:graphic",
					"graphicGroup": "message:warning:editorSave:floatingLinkExists:graphicGroup",
					"video": "message:warning:editorSave:floatingLinkExists:video",
					"callout": "message:warning:editorSave:floatingLinkExists:callout",
					"variable": "message:warning:editorSave:floatingLinkExists:variable",
					"fragment": "message:warning:editorSave:floatingLinkExists:fragment",
					"fragmentGroup": "message:warning:editorSave:floatingLinkExists:fragmentGroup",
					"metaref": "message:warning:editorSave:floatingLinkExists:metaref"
				},
				"frozenAndFloatingLink": {
					"default": "message:warning:editorSave:frozenAndFloatingLink:default",
					"graphic": "message:warning:editorSave:frozenAndFloatingLink:graphic",
					"graphicGroup": "message:warning:editorSave:frozenAndFloatingLink:graphicGroup",
					"video": "message:warning:editorSave:frozenAndFloatingLink:video",
					"callout": "message:warning:editorSave:frozenAndFloatingLink:callout",
					"variable": "message:warning:editorSave:frozenAndFloatingLink:variable",
					"fragment": "message:warning:editorSave:frozenAndFloatingLink:fragment",
					"fragmentGroup": "message:warning:editorSave:frozenAndFloatingLink:fragmentGroup",
					"metaref": "message:warning:editorSave:frozenAndFloatingLink:metaref"
				},
				"linkForResourceCouldNotBeCreated": "message:warning:editorSave:linkForResourceCouldNotBeCreated",
				"linkForVariableCouldNotBeCreated": "message:warning:editorSave:linkForVariableCouldNotBeCreated",
				"linkForMetarefCouldNotBeCreated": "message:warning:editorSave:linkForMetarefCouldNotBeCreated",
				"linkForTextModuleCouldNotBeCreated": "message:warning:editorSave:linkForTextModuleCouldNotBeCreated",
				"invalidLinkRemovalForTextModuleCausedInvalidXML": "message:warning:editorSave:invalidLinkRemovalForTextModuleCausedInvalidXML"
			}
		},
		"general": {
			"loadingindirectreuses": "message:general:loadingindirectreuses",
			"andMore_neutral": "message:general:andMore_neutral",
			"andMore_neutral_one": "message:general:andMore_neutral_one",
			"andMore_neutral_other": "message:general:andMore_neutral_other",
			"andMore": "message:general:andMore"
		},
		"info": {
			"olddata": "message:info:olddata",
			"changeLanguageRequiresLogin": "message:info:changeLanguageRequiresLogin"
		},
		"confirm": {
			"delete": "message:confirm:delete",
			"cancel": "message:confirm:cancel"
		},
		"preview": {
			"comments": {
				"emptyCollection": "message:preview:comments:emptyCollection",
				"confirmInputAbort": "message:preview:comments:confirmInputAbort"
			},
			"previewLimit": "message:preview:previewLimit",
			"emphasisNotFound": "message:preview:emphasisNotFound",
			"metaref": {
				"ontologyNotSelected": "message:preview:metaref:ontologyNotSelected",
				"dynamicReferenceNotFound": "message:preview:metaref:dynamicReferenceNotFound"
			},
			"resourceNode": {
				"noContent": "message:preview:resourceNode:noContent"
			}
		},
		"validation": {
			"dateFormat": "message:validation:dateFormat",
			"numberDouble": "message:validation:numberDouble",
			"emptyRow": "message:validation:emptyRow",
			"required": "message:validation:required",
			"xml": "message:validation:xml",
			"minCount": "message:validation:minCount",
			"maxCount": "message:validation:maxCount",
			"minLength": "message:validation:minLength",
			"maxLength": "message:validation:maxLength",
			"minDate": "message:validation:minDate",
			"maxDate": "message:validation:maxDate",
			"minValue": "message:validation:minValue",
			"maxValue": "message:validation:maxValue",
			"format": "message:validation:format",
			"filesizeToLarge": "message:validation:filesizeToLarge",
			"fileType": "message:validation:fileType",
			"auth": {
				"missingUsername": "message:validation:auth:missingUsername",
				"missingPassword": "message:validation:auth:missingPassword"
			},
			"passwordConfirmed": "message:validation:passwordConfirmed"
		},
		"workflow": {
			"askForSignature": "message:workflow:askForSignature",
			"instanceChangedNow": "message:workflow:instanceChangedNow",
			"instanceChangedOrInvisible": "message:workflow:instanceChangedOrInvisible",
			"noTasks": "message:workflow:noTasks",
			"invalidField_other": "message:workflow:invalidField_other",
			"changedField": "message:workflow:changedField",
			"changedField_one": "message:workflow:changedField_one",
			"changedField_other": "message:workflow:changedField_other",
			"changeGeneric": "message:workflow:changeGeneric",
			"dataOutdated": "message:workflow:dataOutdated",
			"transactionCancel": "message:workflow:transactionCancel",
			"historyModifiedBy": "message:workflow:historyModifiedBy",
			"urlOpenedInWindow": "message:workflow:urlOpenedInWindow"
		},
		"auth": {
			"loggedOut": "message:auth:loggedOut"
		},
		"properties": {
			"valueInherited": "message:properties:valueInherited"
		},
		"createNewNodeForm": {
			"configId": {
				"error": "message:createNewNodeForm:configId:error"
			}
		},
		"createNewNodeVisualizer": {
			"error": {
				"commandNotImplemented": "message:createNewNodeVisualizer:error:commandNotImplemented"
			},
			"question": {
				"switchContext": "message:createNewNodeVisualizer:question:switchContext"
			}
		},
		"tasks": {
			"confirmClose": "message:tasks:confirmClose"
		},
		"chooseProject": {
			"projectFolderSelected": "message:chooseProject:projectFolderSelected"
		},
		"dashboard": {
			"welcome": {
				"title": "message:dashboard:welcome:title"
			},
			"myAssignedTasks": {
				"error": {
					"loading": "message:dashboard:myAssignedTasks:error:loading"
				},
				"info": {
					"noTasks": "message:dashboard:myAssignedTasks:info:noTasks"
				}
			}
		},
		"editor": {
			"canEditCheck": {
				"error": {
					"InternalError": "message:editor:canEditCheck:error:InternalError",
					"PermissionDenied": "message:editor:canEditCheck:error:PermissionDenied",
					"NodeLocked": "message:editor:canEditCheck:error:NodeLocked",
					"NodeReused": "message:editor:canEditCheck:error:NodeReused",
					"ContentLinked": "message:editor:canEditCheck:error:ContentLinked",
					"AspectNotEditingLanguage": "message:editor:canEditCheck:error:AspectNotEditingLanguage",
					"NodeReleasedCurrentAspect": "message:editor:canEditCheck:error:NodeReleasedCurrentAspect",
					"NodeReleasedOtherAspect": "message:editor:canEditCheck:error:NodeReleasedOtherAspect",
					"NodeDeleted": "message:editor:canEditCheck:error:NodeDeleted"
				},
				"warning": {
					"NodeInTranslation": "message:editor:canEditCheck:warning:NodeInTranslation"
				}
			}
		},
		"search": {
			"info": {
				"searchingForTerm": "message:search:info:searchingForTerm"
			},
			"warning": {
				"noSearchTerm": "message:search:warning:noSearchTerm",
				"noSearchResult": "message:search:warning:noSearchResult",
				"noSearchFacets": "message:search:warning:noSearchFacets"
			},
			"error": {
				"fromException": "message:search:error:fromException",
				"corruptResponse": "message:search:error:corruptResponse"
			}
		},
		"createNewNode": {
			"class": {
				"docuManagerOnly": "message:createNewNode:class:docuManagerOnly",
				"permissionDenied": "message:createNewNode:class:permissionDenied"
			}
		},
		"resourcePoolBlade": {
			"folder": "message:resourcePoolBlade:folder",
			"not_possible": "message:resourcePoolBlade:not_possible",
			"rights": "message:resourcePoolBlade:rights",
			"thumbnail": "message:resourcePoolBlade:thumbnail",
			"noslot": "message:resourcePoolBlade:noslot"
		},
		"crossReferencePoolBlade": {
			"not_possible_cross_reference": "message:crossReferencePoolBlade:not_possible_cross_reference"
		},
		"fragmentPoolBlade": {
			"no_fragment": "message:fragmentPoolBlade:no_fragment",
			"rights": "message:fragmentPoolBlade:rights",
			"recursion": "message:fragmentPoolBlade:recursion",
			"empty": "message:fragmentPoolBlade:empty",
			"empty_group": "message:fragmentPoolBlade:empty_group",
			"not_possible_structure_fragment": "message:fragmentPoolBlade:not_possible_structure_fragment",
			"not_possible_fragment": "message:fragmentPoolBlade:not_possible_fragment"
		},
		"safetyPool": {
			"not_possible": "message:safetyPool:not_possible"
		},
		"variablePoolBlade": {
			"not_possible": "message:variablePoolBlade:not_possible"
		},
		"metaRefPoolBlade": {
			"not_possible": "message:metaRefPoolBlade:not_possible"
		},
		"ap": {
			"error": {
				"userDetails": {
					"noUserGiven": "message:ap:error:userDetails:noUserGiven"
				},
				"userOverview": {
					"noEnabledUsersSelected": "message:ap:error:userOverview:noEnabledUsersSelected",
					"noDisabledUsersSelected": "message:ap:error:userOverview:noDisabledUsersSelected"
				},
				"userClaims": {
					"noPermissions": "message:ap:error:userClaims:noPermissions"
				},
				"licenseOverview": {
					"noData": "message:ap:error:licenseOverview:noData"
				},
				"licenseDetails": {
					"noData": "message:ap:error:licenseDetails:noData",
					"noSelectedLicense": "message:ap:error:licenseDetails:noSelectedLicense"
				}
			},
			"validationError": {
				"userNameRequired": "message:ap:validationError:userNameRequired",
				"displayNameRequired": "message:ap:validationError:displayNameRequired",
				"invalidEmailFormat": "message:ap:validationError:invalidEmailFormat",
				"emptyPassword": "message:ap:validationError:emptyPassword",
				"passwordsNotIdentical": "message:ap:validationError:passwordsNotIdentical",
				"passwordInvalid": "message:ap:validationError:passwordInvalid",
				"NoGroupSelected": "message:ap:validationError:NoGroupSelected",
				"GroupNameRequired": "message:ap:validationError:GroupNameRequired"
			},
			"success": {
				"passwordChanged": "message:ap:success:passwordChanged",
				"setAbsent": "message:ap:success:setAbsent",
				"setPresent": "message:ap:success:setPresent",
				"singleUserEnabled": "message:ap:success:singleUserEnabled",
				"singleUserDisabled": "message:ap:success:singleUserDisabled",
				"singleUserDeleted": "message:ap:success:singleUserDeleted",
				"singleUserUndeleted": "message:ap:success:singleUserUndeleted",
				"userCreated": "message:ap:success:userCreated",
				"userUpdated": "message:ap:success:userUpdated",
				"usersAddedToGroups": "message:ap:success:usersAddedToGroups",
				"usersRemovedFromGroups": "message:ap:success:usersRemovedFromGroups",
				"multiUsersEnabled": "message:ap:success:multiUsersEnabled",
				"multiUsersDisabled": "message:ap:success:multiUsersDisabled",
				"multiUsersDeleted": "message:ap:success:multiUsersDeleted",
				"multiUsersUndeleted": "message:ap:success:multiUsersUndeleted",
				"groupCreated": "message:ap:success:groupCreated",
				"groupDeleted": "message:ap:success:groupDeleted",
				"groupUndeleted": "message:ap:success:groupUndeleted",
				"licenseDetails": {
					"quotasAdded": "message:ap:success:licenseDetails:quotasAdded",
					"quotaChanged": "message:ap:success:licenseDetails:quotaChanged",
					"quotasChanged": "message:ap:success:licenseDetails:quotasChanged",
					"quotaRejected": "message:ap:success:licenseDetails:quotaRejected",
					"quotasRejected": "message:ap:success:licenseDetails:quotasRejected"
				}
			},
			"hasUnsavedChanges": {
				"title": "message:ap:hasUnsavedChanges:title",
				"body": "message:ap:hasUnsavedChanges:body",
				"question": "message:ap:hasUnsavedChanges:question"
			},
			"info": {
				"userClaims": {
					"noUserClaimsFound": "message:ap:info:userClaims:noUserClaimsFound"
				},
				"generatedPassword": "message:ap:info:generatedPassword",
				"licenseDetails": {
					"noQuotas": "message:ap:info:licenseDetails:noQuotas"
				}
			},
			"warning": {
				"licenseDetails": {
					"noSupportedLicenseTypes": "message:ap:warning:licenseDetails:noSupportedLicenseTypes"
				}
			},
			"lm": {
				"warning": {
					"addQuotasForm": {
						"noSupportedLicenseTypes": "message:ap:lm:warning:addQuotasForm:noSupportedLicenseTypes"
					},
					"changeQuotasForm": {
						"noSupportedLicenseTypes": "message:ap:lm:warning:changeQuotasForm:noSupportedLicenseTypes"
					}
				},
				"error": {
					"addQuotasFormBlade": {
						"noData": "message:ap:lm:error:addQuotasFormBlade:noData",
						"noLicense": "message:ap:lm:error:addQuotasFormBlade:noLicense"
					},
					"changeQuotasFormBlade": {
						"noData": "message:ap:lm:error:changeQuotasFormBlade:noData",
						"noLicense": "message:ap:lm:error:changeQuotasFormBlade:noLicense",
						"noQuotas": "message:ap:lm:error:changeQuotasFormBlade:noQuotas"
					}
				},
				"validationError": {
					"NotEnoughFreeLicenses": "message:ap:lm:validationError:NotEnoughFreeLicenses"
				}
			}
		},
		"tree": {
			"dnd": {
				"tryAgain": "message:tree:dnd:tryAgain"
			}
		}
	},
	"description": {
		"image": {
			"width": {
				"50": "description:image:width:50",
				"80": "description:image:width:80",
				"original": "description:image:width:original",
				"fixedColumn": "description:image:width:fixedColumn",
				"fixedTextarea": "description:image:width:fixedTextarea",
				"margin": "description:image:width:margin"
			}
		},
		"hyperlink": {
			"opensInNewWindow": "description:hyperlink:opensInNewWindow"
		},
		"workflow": {
			"historyTab": "description:workflow:historyTab",
			"overviewTab": "description:workflow:overviewTab",
			"action": "description:workflow:action"
		},
		"preview_legacy": {
			"selectedTextConfirm": "description:preview_legacy:selectedTextConfirm"
		},
		"createNewNode": {
			"start": "description:createNewNode:start"
		},
		"createNewNodeForm": {
			"formTitle": "description:createNewNodeForm:formTitle",
			"configId": {
				"error": "description:createNewNodeForm:configId:error"
			},
			"permisson": {
				"error": "description:createNewNodeForm:permisson:error"
			}
		},
		"createNewNodeVisualizer": {
			"error": {
				"commandNotImplemented": "description:createNewNodeVisualizer:error:commandNotImplemented"
			},
			"inProgress": {
				"createNode": "description:createNewNodeVisualizer:inProgress:createNode"
			}
		},
		"search": {
			"component": {
				"searchResultPagination": {
					"totalText": "description:search:component:searchResultPagination:totalText"
				}
			}
		},
		"status": {
			"pageNotFound": "description:status:pageNotFound"
		},
		"task": {
			"loading": "description:task:loading",
			"notFound": "description:task:notFound",
			"deleted": "description:task:deleted"
		}
	}
}