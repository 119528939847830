import * as React from "react"
import { SVGProps } from "react"

const SvgSquareArrowUpLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="1em" height="1em" {...props}>
    <path d="M64 80c-8.8 0-16 7.2-16 16v320c0 8.8 7.2 16 16 16h320c8.8 0 16-7.2 16-16V96c0-8.8-7.2-16-16-16H64zM0 96c0-35.3 28.7-64 64-64h320c35.3 0 64 28.7 64 64v320c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zm288 48c13.3 0 24 10.7 24 24s-10.7 24-24 24h-94.1L329 327c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-135-135V328c0 13.3-10.7 24-24 24s-24-10.7-24-24V168c0-13.3 10.7-24 24-24H288z" />
  </svg>
)

export default SvgSquareArrowUpLeft
