import React from "react"
import { defineScope, defineTransition } from "@st4/tasks"
import { Translate, keys } from "@st4/i18n"
import { editScreen, exploreScreen } from "../screens"
import { Light } from "@st4/icons"
import { EDITORSTATES } from "@st4/content"

export const informationScope = defineScope({
  displayName: <Translate>{keys.label.screens.information}</Translate>,
  transitions: {
    "start-->explore": {
      type: "start",
    },
    "explore-->edit": {
      type: "menu",
      label: <Translate>{keys.label.content.advancedEditor}</Translate>,
      menuName: "editorMenu",
      icon: Light.Pen,
    },
    "edit-->explore": {
      type: "blade",
      label: <Translate>{keys.button.general.close}</Translate>,
      align: "right",
      blade: "editor",
      primary: true,
    },
  },
  screens: {
    explore: exploreScreen,
    edit: editScreen,
  },
})

defineTransition(informationScope, "start-->explore", async (context, { produceFromInitialValues, parameters }) => {
  return produceFromInitialValues((draft) => {
    draft.states.navigationTree.selectedNode = parameters.nodeId
  })
})
// defineTransition(informationScope, "start-->edit", async (context, { produceFromInitialValues, parameters }) => {
//   return produceFromInitialValues((draft) => {
//   })
// })

defineTransition(informationScope, "explore-->edit", async (context, { produceFromInitialValues }) => {
  const nodeId = context.additionalData.nodeId
  const treeNodeId = context.additionalData.treeNodeId

  return produceFromInitialValues((draft) => {
    if (typeof treeNodeId !== "string" || typeof nodeId !== "string") return

    draft.states.editor.rootNodeId = treeNodeId
    draft.states.editor.editedNodes = [nodeId]
    draft.states.editor.selection = {
      nodeId,
      treeNodeId,
    }

    draft.states.fragmentPoolTree.selectedNode = context.toData.states.fragmentPoolTree.selectedNode
    draft.states.resourcePoolTree.selectedNode = context.toData.states.resourcePoolTree.selectedNode
    draft.states.variablePoolTree.selectedNode = context.toData.states.variablePoolTree.selectedNode
  })
})

defineTransition(informationScope, "edit-->explore", (context, { messageHub, produceFromCurrentValues }) => {
  return new Promise((resolve) => {
    const nodeId = context.fromData.states.editor.editedNodes[0]

    const dataFromCurrentValues = produceFromCurrentValues((draft) => {
      draft.states.content.editedNodes = draft.states.content.editedNodes.filter((id) => id != nodeId)
      // set by the blade-reducer when klicking the open editor button
      draft.states.content.singleNodeMode = false
    })

    if (!nodeId || EDITORSTATES().get(nodeId) !== "Running") {
      resolve(dataFromCurrentValues)
      return
    }

    // listen for message: "Editor has saved and can be closed"
    messageHub.editor.observe(function editorClosed(msg) {
      if (
        msg.action === "editingFinished" &&
        msg.payload.selection.nodeId === context.fromData.states.editor.editedNodes[0]
      ) {
        messageHub.editor.unobserve(editorClosed)
        resolve(dataFromCurrentValues)
      }
    })

    messageHub["editor"].sendMessage({
      action: "saveAndCloseEditor",
      payload: { nodeId: context.fromData.states.editor.editedNodes[0] },
    })
  })
})
