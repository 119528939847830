import { ApolloClient } from "@apollo/client"
import { Message, MessageHub } from "@st4/message-hub"
import { GenericScopeDefinition, ScopeData, ScopeDefinition } from "./scope"
import { ScreenData } from "./screen"

/**
 * Provides useful tools to define transitions.
 */
type TransitionHelper<
  TScope extends ScopeDefinition,
  TTransition extends Extract<keyof TScope["transitions"], TransitionKey>,
> = {
  /**
   * Change specific values, based of the screens initial values.
   *
   * {@link https://immerjs.github.io/immer/update-patterns See the immerjs docs for details}
   */
  produceFromInitialValues: (
    transform: (draft: TScope["screens"][ToScreen<TTransition>]["initialContextValues"]) => void,
  ) => TScope["screens"][ToScreen<TTransition>]["initialContextValues"]

  /**
   * Change specific values, based of the screens current values (or initial values if first time on screen)
   *
   * {@link https://immerjs.github.io/immer/update-patterns See the immerjs docs for details}
   */
  produceFromCurrentValues: (
    transform: (draft: TScope["screens"][ToScreen<TTransition>]["initialContextValues"]) => void,
  ) => TScope["screens"][ToScreen<TTransition>]["initialContextValues"]

  /**
   * Can be used to issue graphql requests.
   */
  apolloClient: ApolloClient<unknown>

  /**
   * Contains the values inside the search query url part.
   *
   * _The actual implementation might be subject to change in future versions.
   * **Use with caution!** If in doubt ask the Web Vikings team._
   */
  parameters: Record<string, string | undefined>

  /**
   * Contains the MessageHub for the from screen.
   */
  messageHub: Record<string, MessageHub>
  // TODO: Typisieren auf Blade Ebene... aktuell durch TransitionsMap blockiert, weil dort direkt BaseScreenDefinition angeben ist, welche nicht mehr message-typsiert ist.
  //{ [blade in TScope["screens"][FromScreen<TTransition>]["blades"][number] as blade['name']]: MessageHub }
}

type FromScreen<TString> = TString extends `${infer T}-${string}->${string}` ? T : never
type TransitionType<TString> = TString extends `${string}-${infer T}->${string}` ? T : never
type ToScreen<TString> = TString extends `${string}-${string}->${infer T}` ? T : never

type MatchingTransitionKeysInScope<
  TString extends `${string}-${string}->${string}`,
  TScope extends ScopeDefinition,
> = TString extends `${infer TFrom}-${string}->${infer TTo}`
  ? Extract<keyof TScope["transitions"], `${TFrom}-${string}->${TTo}`>
  : never

type TransitionContext<
  TScope extends ScopeDefinition,
  TTransition extends Extract<keyof TScope["transitions"], TransitionKey>,
> = {
  /** The screen definition of the from screen. */
  from: TScope["screens"][FromScreen<TTransition>]
  /** The screen definition of the target screen. */
  to: TScope["screens"][ToScreen<TTransition>]
  /** The type of the transition `(from)-[type]->(to)`. */
  type: TransitionType<MatchingTransitionKeysInScope<TTransition, TScope>>
  /** All screen definitions of this scope. */
  screens: TScope["screens"]
  /** Complete scope data (contains all screen states). */
  scopeData: ScopeData<TScope>
  /** The data of the from screen of this definition */
  fromData: TScope["screens"][FromScreen<TTransition>]["initialContextValues"]
  /** The data of the to screen of this definition */
  toData: TScope["screens"][ToScreen<TTransition>]["initialContextValues"]
  /** Any data that might be passed to this transition.
   * You are responsible to check if your values are what you expect! */
  additionalData: Record<string, unknown>
}

export type TransitionKey = `${string}-${string}->${string}`

export type GetTransitionScreens<TString> = TString extends `${infer TSource}-${string}->${infer TTarget}`
  ? TSource | TTarget
  : TString extends `*-${string}->${infer TTarget}`
  ? TTarget
  : never

/** Removes the Transition-Type */
export type SimplifiedTransitionKey<TString extends TransitionKey> =
  TString extends `${infer TFrom}-${string}->${infer TTo}` ? `${TFrom}-->${TTo}` : never

export type GenericTransitionFunction = (
  transitionContext: TransitionContext<GenericScopeDefinition, TransitionKey>,
  helper: TransitionHelper<GenericScopeDefinition, TransitionKey>,
) => Promise<ScreenData>

/**
 * @param transitionContext - The context of the current transition: AAAA
 */
export type TransitionFunction<
  TScope extends ScopeDefinition,
  TTransition extends Extract<keyof TScope["transitions"], TransitionKey>,
> = (
  /** The context of the current transition. */
  transitionContext: TransitionContext<TScope, TTransition>,
  helper: TransitionHelper<TScope, TTransition>,
) => Promise<TScope["screens"][ToScreen<TTransition>]["initialContextValues"]>

const KEY_REGEX = /(\w+)-(\w*)->(\w+)/i

export function isTransitionKey(key: string | number | symbol): key is TransitionKey {
  if (typeof key !== "string") return false
  return KEY_REGEX.test(key)
}

export function getKeyParts(key: TransitionKey): {
  from: FromScreen<typeof key>
  type: TransitionType<typeof key>
  to: ToScreen<typeof key>
} {
  const match = KEY_REGEX.exec(key)
  if (!match) throw new Error(`invalid transition key given! ${key}`)
  return {
    from: match[1],
    type: match[2],
    to: match[3],
  }
}
