import React from "react"
import styled from "styled-components"
import Text_deprecated from "./Text_deprecated"

const DescriptionText_deprecated = styled(Text_deprecated)`
  font-size: ${({ theme }) => theme.token.fontSizeSM}px;
  font-weight: normal;
  color: grey;
`

export default DescriptionText_deprecated
