import { useNotifications } from "@st4/message-hub"
import { useCallback } from "react"
import { Translate, keys } from "@st4/i18n"
import React from "react"

export function useConfiguredNotifications() {
  const { showConfirm, showNotification } = useNotifications()

  const showConfiguredConfirm = useCallback(
    (state: "question" | "warning" | "error", title: string, description: string) => {
      return showConfirm(
        state,
        title,
        description,
        { label: <Translate>{keys.button.general.ok}</Translate>, value: true },
        { label: <Translate>{keys.button.general.cancel}</Translate>, value: false }
      )
    },
    [showConfirm]
  )
  const showConfiguredConfirmableNotification = useCallback(
    (title: string, description: string) => {
      return showConfirm("info", title, description, {
        label: <Translate>{keys.button.general.ok}</Translate>,
        value: null,
      })
    },
    [showConfirm]
  )

  return { showConfiguredConfirm, showConfiguredConfirmableNotification, showConfiguredNotification: showNotification }
}
