import * as React from "react"
import { SVGProps } from "react"

const SvgFlagCheckered = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="1em" height="1em" {...props}>
    <path d="M24 0c13.3 0 24 10.7 24 24v28l85-21.2c38.1-9.5 78.3-5.1 113.5 12.5 46.3 23.2 100.8 23.2 147.1 0l9.6-4.8c20.6-10.4 44.8 4.6 44.8 27.6v279.7c0 13.3-8.3 25.3-20.8 30l-34.7 13c-46.2 17.3-97.6 14.6-141.7-7.4-37.9-19-81.3-23.7-122.5-13.4L48 388v100c0 13.3-10.7 24-24 24S0 501.3 0 488V24C0 10.7 10.7 0 24 0zm24 150.7 80-17.4V81.5l-80 20v49.3zm0 49.1v46.9l80-17.4v-46.8l-80 17.4zm0 96v42.7l68.6-17.2c3.8-.9 7.6-1.8 11.4-2.5v-40.3l-80 17.4zm128 18.8c33.1.7 65.9 8.7 96 23.7v-49.8l-55.4-16.3c-13.2-3.9-27-5.1-40.6-3.6v46zm144 37c18.7 1.5 37.7-1.1 55.6-7.8l24.4-9.1V288l-25.7 6c-17.9 4.1-36.1 5.5-54.3 4.3v53.5zm80-113V192l-25.7 6c-17.9 4.1-36.1 5.5-54.3 4.3v47.8c14.5 1.4 29.2.4 43.5-2.9l36.5-8.4zm0-96V92.9c-25.6 10.4-52.8 15.6-80 15.6V154c14.5 1.4 29.2.4 43.5-2.9l36.5-8.4zm-128-39.5c-16.1-3.7-31.9-9.4-47-16.9-15.3-7.7-32.1-11.8-49-12.3v50.6c18.2-1.3 36.5.6 54.1 5.8l41.9 12.2v-39.4zm0 89.5-55.4-16.3c-13.2-3.9-27-5.1-40.6-3.6v47.8c18.2-1.3 36.5.6 54.1 5.8l41.9 12.2v-46z" />
  </svg>
)

export default SvgFlagCheckered
