import type { ST4NodeWithContent } from "../../graphql/applicationQueries"
import { isST4NodeWithContent } from "../../graphql/applicationQueries"
import { useAddCommentAndSaveLocal } from "./commentAddingFunctions"
import { useRemoveCommentAndSaveLocal } from "./commentRemovalFunctions"
import type { UpdateCommentInput } from "./commentUpdateStateFunctions"
import { useUpdateCommentStateAndSaveLocal } from "./commentUpdateStateFunctions"
import { useAddCommentReplyAndSaveLocal } from "./commentReplyAddingFunctions"
import { useUpdateCommentAndSaveLocal } from "./commentUpdateFunctions"
import type { AddInlineCommentInput, AddReplyCommentInput, RemoveCommentInput } from "@st4/graphql"
import { isTypename } from "@st4/graphql"
import type { CommentInfoFieldsFragment } from "../CommentList/query.hooks"

export function useCommentFactory() {
  const [addCommentToNode] = useAddCommentAndSaveLocal()
  const [addReplyToComment] = useAddCommentReplyAndSaveLocal()
  const [updateComment] = useUpdateCommentAndSaveLocal()

  const saveCommentFunc = (comment: Parameters<typeof convertTextCommentToAddCommentInput>[0]) => {
    const input = convertTextCommentToAddCommentInput(comment)
    if (input) {
      return addCommentToNode(input)
    } else {
      return Promise.reject("Invalid input for save comment")
    }
  }

  const addReply = (...[parent, reply]: Parameters<typeof convertTextCommentToAddReplyInput>) => {
    const input = convertTextCommentToAddReplyInput(parent, reply)
    if (input) {
      return addReplyToComment(input)
    } else {
      return Promise.reject("Invalid input for save comment")
    }
  }

  return {
    createComment: saveCommentFunc,
    addReply,
    updateComment,
  }
}

export function useCommentActionsHook() {
  const [removeComment] = useRemoveCommentAndSaveLocal()
  const [updateCommentState] = useUpdateCommentStateAndSaveLocal()

  const removeCommentFunc = (comment: Parameters<typeof convertCommentToRemoveInput>[0]) => {
    const input = convertCommentToRemoveInput(comment)
    if (input) {
      return removeComment(input)
    } else {
      return Promise.reject("Invalid input for remove comment")
    }
  }
  const updateCommentFunc = (comment: Parameters<typeof convertCommentToUpdateInput>[0]) => {
    const input = convertCommentToUpdateInput(comment)
    if (input) {
      return updateCommentState(input)
    } else {
      return Promise.reject("Invalid input for remove comment")
    }
  }
  return {
    RemoveComment: removeCommentFunc,
    UpdateComment: updateCommentFunc,
  }
}

function convertCommentToRemoveInput(comment: CommentInfoFieldsFragment): RemoveCommentInput | null {
  if (!comment.node.content) return null
  const { id, aspectId } = getNodeData(comment.node)
  const textContent = comment.node.content
  if (!isTypename("TextContent", "TextGroupContent")(textContent)) return null
  return {
    aspectSelectorId: aspectId,
    nodeId: id,
    historyNumber: textContent.historyNumber,
    commentKey: comment.commentKey,
  }
}
function convertCommentToUpdateInput(comment: CommentInfoFieldsFragment): UpdateCommentInput | null {
  if (!comment.node.content) return null
  const textContent = comment.node.content
  if (!isTypename("TextContent", "TextGroupContent")(textContent)) return null
  return {
    ...comment,
    node: { ...comment.node, content: textContent },
  }
}

function convertTextCommentToAddCommentInput(comment: CommentInfoFieldsFragment): AddInlineCommentInput | null {
  if (!comment.node.content) return null
  const textContent = comment.node?.content
  if (!isTypename("TextContent", "TextGroupContent")(textContent)) return null
  const textComment = comment
  if (
    !comment.node ||
    !isTypename("TextComment")(textComment) ||
    !textComment.startLocation ||
    textComment.endLocation - textComment.startLocation < 1
  )
    return null
  const { aspectId } = getNodeData(comment.node)
  return {
    aspectSelectorId: aspectId,
    historyNumber: textContent.historyNumber,
    nodeId: textContent.id,
    startLocation: textComment.startLocation,
    endLocation: textComment.endLocation,
    username: comment.authorUserName ?? "UNKNOWN USER",
    commentText: comment.value ?? "",
    color: comment.color,
    commentType: comment.commentType,
  }
}

function getNodeData(node: ST4NodeWithContent) {
  // Fragment group content is structurally equal to normal text content by selecting the first
  // fragment and fill the fields accordingly. However, this poses an issue with the node's id.
  // Comments can't be added to the group node but only to the concrete displayed node.
  if (isST4NodeWithContent("TextGroupContent")(node)) {
    const contentNode = node.content.children.find((cn) => cn.target?.id === node.content.currentNodeId)
    if (!contentNode || !isTypename("ST4Node")(contentNode?.target))
      throw new Error("Did not find contentNode to comment on inside TextModuleGroup")
    return { id: contentNode.target.id, aspectId: contentNode.target.aspectId }
  }
  return { id: node.id, aspectId: node.aspectId }
}

function convertTextCommentToAddReplyInput(
  parent: CommentInfoFieldsFragment,
  reply: { authorUserName: string; value: string; color: string; commentType: string },
): AddReplyCommentInput | null {
  if (!parent.node) return null
  if (!isTypename("TextContent", "TextGroupContent")(parent.node.content)) return null
  return {
    nodeId: parent.node.id,
    aspectSelectorId: parent.node.aspectId,
    historyNumber: parent.node.content.historyNumber,
    parentKey: parent.commentKey,
    username: reply.authorUserName,
    commentText: reply.value,
    color: reply.color,
    commentType: reply.commentType || undefined,
  }
}
