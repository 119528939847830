import React, { useMemo } from "react"
import type { ReactNode } from "react"
import styled from "styled-components"
import {
  calculateNumberingForTreeNode,
  createIndentationsArray,
  useNodeNumbering,
  usePreviewContentModel,
} from "../contentModel"
import { Typography, Spin, Skeleton, Tree } from "antd"
import { useAnnotationFocusStateContext } from "./annotationFocusState"
import { notEmpty } from "../utilities"
import type { ReadyPreviewContentModel } from "../contentModel/types"
import type { TreeNode } from "../contentModel/nodeContext"
import { getNodeMappingRule } from "../mapping/mappingUtilities"

type Key = string | number
type OutlineProps = Record<string, unknown>

const StyledOutline = styled.div`
  border: 1px solid #ddd;
  border-radius: 2px;
  background: white;
  padding: 0.8rem;
  max-height: 100%;
  min-height: 100%;
  overflow: auto;
`

function OutlineLabel({ treeNode }: { treeNode: TreeNode }) {
  const numbering = useNodeNumbering(treeNode.id)
  const { setFocusedNodes } = useAnnotationFocusStateContext()
  return (
    <Typography.Paragraph style={{ cursor: "pointer" }}>
      <span onClick={() => setFocusedNodes([treeNode.node])}>
        {numbering.join(".")} {treeNode.label}
      </span>
    </Typography.Paragraph>
  )
}

export const Outline = (props: OutlineProps) => {
  const contentModel = usePreviewContentModel()
  if (contentModel.state !== "ready")
    return (
      <StyledOutline>
        <Spin />
      </StyledOutline>
    )

  const labels = contentModel.treeNodes.map((tn) => <OutlineLabel key={tn.id} treeNode={tn} />)

  return <StyledOutline>{labels}</StyledOutline>
}

//export default Outline

const StyledOutlineTree = styled.div`
  padding: 0.8rem;
  max-height: 100%;
  min-height: 100%;
  overflow: auto;
`

export const OutlineTree = (props: OutlineProps) => {
  const { setFocusedNodes } = useAnnotationFocusStateContext()
  const contentModel = usePreviewContentModel()
  const treeData = useMemo(() => {
    if (contentModel.state !== "ready") return []
    return generateTreeData(contentModel.treeNodes, contentModel)
  }, [contentModel])

  if (contentModel.state != "ready") {
    return <Skeleton />
  }

  function handleSelect(keys: Key[]) {
    if (contentModel.state === "ready") {
      if (keys.length === 1 && typeof keys[0] === "string") {
        const node = contentModel.treeNodesById.get(keys[0])
        if (node) {
          setFocusedNodes([node])
        }
      }
    }
  }

  return (
    <StyledOutlineTree>
      <Tree
        blockNode={true}
        onSelect={handleSelect}
        defaultExpandedKeys={treeData.map((n) => n.key)}
        treeData={treeData}
      />
    </StyledOutlineTree>
  )
}

type DataNode = {
  key: string
  title: ReactNode
  children: DataNode[]
}

const LabelNumber = styled.span`
  font-weight: 600;
`

const StyledOutlineTreeLabel = styled.div`
  white-space: nowrap;
`

function GetLabel(treeNode: TreeNode, contentModel: ReadyPreviewContentModel) {
  const label = treeNode.label
  if (
    treeNode.children?.length &&
    (treeNode.node.nodeClass.classHierarchy?.includes("TextGroup") ||
      treeNode.node.nodeClass.classHierarchy?.includes("TextModuleGroup"))
  ) {
    const firstChild = contentModel.treeNodesById.get(treeNode.children[0])
    return firstChild?.node.label ?? label
  }
  return label
}

function OutlineTreeLabel({
  treeNode,
  contentModel,
  numbering,
}: {
  treeNode: TreeNode
  contentModel: ReadyPreviewContentModel
  numbering: number[]
}) {
  const label = GetLabel(treeNode, contentModel)
  return (
    <StyledOutlineTreeLabel>
      <LabelNumber>{numbering.join(".")}</LabelNumber> {label}
    </StyledOutlineTreeLabel>
  )
}

function generateTreeData(treeNodes: TreeNode[], contentModel: ReadyPreviewContentModel) {
  const indentations = createIndentationsArray(treeNodes, contentModel.validChildrenById)
  const root = getTreeNodeData(
    treeNodes[0],
    indentations,
    contentModel.treeNodesById,
    contentModel.validChildrenById,
    contentModel,
  )
  return root ? root : []
}
function getTreeNodeData(
  treeNode: TreeNode,
  indentations: ReturnType<typeof createIndentationsArray>,
  nodes: Map<string, TreeNode>,
  validChildrenById: Map<string, string[]>,
  contentModel: ReadyPreviewContentModel,
): DataNode[] {
  const key = treeNode.id

  const indentArray = indentations
  const childrenIds = validChildrenById.get(treeNode.id) ?? []
  const childrenTreeNodes = childrenIds.map((n) => nodes.get(n)).filter(notEmpty)
  const children = childrenTreeNodes.flatMap((n) =>
    getTreeNodeData(n, indentArray, nodes, validChildrenById, contentModel),
  )

  const mappingRule = getNodeMappingRule(treeNode.node, "FULL")
  if (!mappingRule || (typeof mappingRule !== "string" && mappingRule?.hidden)) {
    if (typeof mappingRule !== "string" && mappingRule?.filteredChildren) {
      // don't include this node in the outline tree structure but render it's children
      return children
    } else {
      // don't include this node in the outline tree structure at all
      return []
    }
  }
  return [
    {
      key,
      children,
      title: (
        <OutlineTreeLabel
          treeNode={treeNode}
          numbering={calculateNumberingForTreeNode(() => ({ visible: true }), indentArray).get(key) ?? []}
          contentModel={contentModel}
        />
      ),
    },
  ]
}

export default OutlineTree
