import React, { createContext, useContext } from "react"
import type { St4NodeWithContentFragment } from "../graphql/applicationQueries/query.hooks"
import type { St4TreeNode } from "@st4/graphql"

export type TreeNode = {
  node: St4NodeWithContentFragment
} & Pick<St4TreeNode, "id" | "label" | "icon" | "children">

const TreeNodeContext = createContext<TreeNode | undefined>(undefined)

export function NodeProvider({ node, children }: React.PropsWithChildren<{ node: TreeNode }>) {
  return <TreeNodeContext.Provider value={node}>{children}</TreeNodeContext.Provider>
}

export function useNode() {
  const treeNode = useTreeNode()
  return treeNode.node
}

export function useTreeNode() {
  const treeNode = useContext(TreeNodeContext)
  if (!treeNode) throw new Error("Node Context is empty. Did you forget <NodeProvider>?")
  return treeNode
}
