export default {
	"button": {
		"general": {
			"reload": "Aktualisieren",
			"save": "Speichern",
			"saveandclose": "Speichern und schließen",
			"closemessage": "Meldung schließen",
			"close": "Schließen",
			"showLess": "Weniger anzeigen",
			"showMore": "Mehr anzeigen",
			"saveAndLoginAgain": "Speichern und erneut einloggen",
			"ok": "OK",
			"cancel": "Abbrechen",
			"edit": "Bearbeiten",
			"openEditor": "Im Editor öffnen",
			"inlineEdit": "Kleine Änderungen vornehmen",
			"next": "Weiter",
			"open": "Öffnen",
			"back": "Zurück",
			"delete": "Löschen",
			"exportData": "Daten exportieren",
			"yes": "Ja",
			"no": "Nein",
			"select": "Wählen",
			"changeSelection": "Auswahl ändern",
			"choose": "Wählen"
		},
		"preview": {
			"structure": "Struktur",
			"comment": {
				"delete": "Kommentar löschen",
				"edit": "Kommentar bearbeiten",
				"reply": "Kommentar beantworten",
				"allRepliesHide": "Alle Antworten einklappen",
				"allRepliesShow": "Alle Antworten ausklappen",
				"add": "Kommentar anlegen"
			}
		},
		"workflow": {
			"backToTask": "Zurück zur Aufgabe",
			"backToList": "Zurück zur Aufgabenliste",
			"executeAction": "Aktion ausführen",
			"bringWindowToFront": "Fenster in den Vordergrund"
		},
		"auth": {
			"login": "Login",
			"loginAgain": "Erneut Anmelden",
			"logout": "Abmelden"
		},
		"navigate": {
			"tree": {
				"toRoot": "Zur Wurzelebene navigieren",
				"levelUp": "Eine Ebene aufwärts navigieren",
				"levelDown": "Eine Ebene abwärts navigieren"
			}
		},
		"deleteNode": {
			"start": "In Papierkorb verschieben"
		},
		"createVersion": "Version anlegen",
		"blade": {
			"nextStep": "Nächster Schritt",
			"insert": "Einfügen",
			"edit": "Ändern",
			"close": "Schließen"
		},
		"dashboard": {
			"myAssignedTasks": {
				"listItem": {
					"tooltip": {
						"destroy": "Track löschen"
					}
				}
			}
		},
		"search": {
			"blade": {
				"searchFacetsBlade": {
					"clearRefinements": "Facettenfilter aufheben"
				},
				"searchOptionsBlade": {
					"reset": "Suchoptionen zurücksetzen"
				}
			}
		},
		"cut": "Ausschneiden",
		"copyTrees": "Kopieren",
		"paste": "Einfügen",
		"createContentLinks": "Inhaltsverknüpfen",
		"reuseTrees": "Wiederverwenden",
		"removeReuseLinks": "Wiederverwendung aufheben",
		"ap": {
			"userOverview": {
				"addNewUser": "Neuen Benutzer anlegen",
				"editProfile": "Benutzer bearbeiten",
				"resetPassword": "Passwort zurücksetzen",
				"changeMyPassword": "Passwort ändern",
				"setUserPresent": "Benutzer anwesend setzen",
				"setUserAbsent": "Benutzer abwesend setzen",
				"setMePresent": "Mich anwesend setzen",
				"setMeAbsent": "Mich abwesend setzen",
				"enableUser": "Benutzer aktivieren",
				"disableUser": "Benutzer deaktivieren",
				"undeleteUser": "Löschen rückgängig machen",
				"deleteUser": "Benutzer löschen",
				"addUsersToGroups": "Ausgewählte Benutzer zu Gruppen hinzufügen",
				"removeUsersFromGroups": "Ausgewählte Benutzer aus Gruppen entfernen",
				"enableUsers": "Ausgewählte Benutzer aktivieren",
				"disableUsers": "Ausgewählte Benutzer deaktivieren",
				"deleteUsers": "Ausgewählte Benutzer löschen",
				"undeleteUsers": "Löschen rückgängig machen für ausgewählte Benutzer"
			},
			"userDetails": {
				"create": "Benutzer anlegen",
				"save": "Änderungen speichern",
				"cancel": "Abbrechen"
			},
			"changePassword": {
				"changePassword": "Passwort ändern",
				"cancel": "Abbrechen"
			},
			"changeGroupAssignments": {
				"addUsersToGroups": "Benutzer zu Gruppen hinzufügen",
				"removeUsersFromGroups": "Benutzer aus Gruppen entfernen",
				"cancel": "Abbrechen"
			},
			"generatePassword": "Passwort generieren",
			"typePassword": "Passwort eingeben",
			"generateOtherPassword": "Anderes Passwort generieren",
			"groupOverview": {
				"addNewGroup": "Neue Benutzergruppe anlegen",
				"deleteGroup": "Benutzergruppe löschen",
				"undeleteGroup": "Löschen rückgängig machen"
			},
			"groupForm": {
				"create": "Benutzergruppe anlegen",
				"cancel": "Abbrechen"
			},
			"licenseDetails": {
				"quotaOverview": {
					"contextMenu": {
						"changeQuota": "Kontingent ändern",
						"rejectQuota": "Kontingent entziehen"
					},
					"multiContextMenu": {
						"addQuotas": "Kontingente hinzufügen",
						"changeQuotas": "Kontingente ändern",
						"rejectQuotas": "Kontingente entziehen"
					}
				}
			}
		}
	},
	"label": {
		"general": {
			"loading": "lädt!",
			"check": "Ansehen",
			"id": "Id",
			"reuses": "Verwendungen",
			"title": "Titel",
			"taxonomy": "Taxonomie",
			"noValue": "Leer",
			"yes": "ja",
			"no": "nein",
			"aspect": "Aspekt",
			"node": "Knoten",
			"node_other": "Knoten",
			"settings": "Einstellungen",
			"ui": "Benutzeroberfläche",
			"name": "Name",
			"status": "Zustand",
			"sortBy": "Sortieren",
			"ascending": "Aufsteigend",
			"descending": "Absteigend",
			"dates": "Daten",
			"people": "Personen",
			"details": "Details",
			"field_other": "Felder",
			"field": "Feld",
			"field_one": "Feld",
			"overview": "Übersicht",
			"history": "Historie",
			"type": "Typ",
			"selectOrDropFile": "Wählen Sie eine Datei oder ziehen Sie sie per Drag & Drop hierher.",
			"fileFormat_other": "Dateiformate",
			"chooseFile": "Datei auswählen",
			"ignore": "Ignorieren",
			"filter": "Filter",
			"scaling": "Skalierung",
			"indexentry": {
				"_": "Indexeintrag",
				"key": "Eintrag",
				"subEntryKey": "Untereintrag",
				"sort": "Sortierschlüssel"
			},
			"node_one": "Knoten",
			"systemName": "Systemname",
			"value": "Wert",
			"creationTime": "Erstellungsdatum",
			"modificationTime": "Änderungsdatum",
			"misc": "Sonstiges",
			"pleaseSelect": "Bitte wählen",
			"search": "Suche",
			"searchEmpty": "Suche ergab keine Ergebnisse."
		},
		"explore": {
			"header": "Free Mode"
		},
		"structure": {
			"header": "Strukturieren",
			"source": {
				"title": "Quelle"
			},
			"target": {
				"title": "Ziel"
			}
		},
		"screens": {
			"information": "Informationen",
			"project": "Projekte & Dokumente"
		},
		"projects": {
			"headline": "Projekte"
		},
		"preview": {
			"informationpool": "Informationspool",
			"layout": "Layout",
			"imageDimensions": "Größe",
			"imageLayoutType": "Layouttyp",
			"imageSlot": {
				"original": "Originalgrafik",
				"web": "Web Format",
				"print": "Print Format"
			},
			"exifCount": "{{count}} Elemente",
			"answerCount": "Eine Antwort",
			"answerCount_one": "Eine Antwort",
			"answerCount_other": "{{count}} Antworten",
			"pagebreak": "Seitenumbruch",
			"emphasis": "Auszeichnung: {{displayName}}",
			"table": {
				"border_framed": "Rahmen",
				"border_header": "Kopfzeile",
				"border_noframe": "Ohne Rahmen",
				"border_legend": "Legendentabelle",
				"border": "Rahmen",
				"scaling_fixed": "Skaliert die Tabelle auf die Breite des Textbereichs.",
				"scaling_scaled": "Skaliert die Tabelle entsprechend der Breiten der Tabellenspalten.",
				"scaling": "Tabellenskalierung"
			},
			"lexicon": {
				"tooltipHeadline": "Lexikoneintrag aus \"{{lexiconName}}\"",
				"glossaryEntry": "Glossareintrag",
				"subjectField": "Sachgebiet",
				"term": "Benennung",
				"validity": "Status",
				"grammaticalGender": "Genus"
			},
			"metaref": {
				"staticHeadline": "Referenz auf das Metadatum <0>{{label}}</0>",
				"ontologyHeadline": "Referenz auf die Taxonomie <0>{{label}}</0>",
				"notFound": "Referenz nicht auflösbar!",
				"languageHeadline": "Referenz auf Sprachdatei",
				"dynamicReference": "Kontextabhängige Referenz auf das Metadatum \"{{metadataName}}\""
			},
			"marginText": "Text in Marginalienspalte"
		},
		"generic": {
			"unknown": "Unbekannt",
			"language": "Sprache",
			"contentLanguage": "Inhaltssprache",
			"guiLanguage": "Anzeigesprache",
			"enterText": "Text eingeben...",
			"addToSelection": "Hinzufügen",
			"available": "Verfügbar",
			"removeFromSelection": "Entfernen",
			"selection": "Auswahl",
			"saved": "Gespeichert"
		},
		"hyperlink": {
			"broken": "Kaputter Link"
		},
		"operation": {
			"syncviews": "Ansichten Synchronisieren"
		},
		"widget": {
			"reusetitle": "Verwendungen von „{{nodeLabel}}“",
			"lastChangeBy": "Letzte Änderung von:",
			"createdBy": "Erstellt von:"
		},
		"widgets": {
			"contentreuses": "Inhaltsverknüpfungen",
			"directreuses": "Direkte Verwendungen",
			"indirectreuses": "Indirekte Verwendungen",
			"reuses": "Wiederverwendungen"
		},
		"warning": {
			"error": "Fehler",
			"warning": "Warnung",
			"information": "Information",
			"question": "Frage",
			"notice": "Hinweis"
		},
		"comment": {
			"placeholder": {
				"selection": "Auswahl kommentieren...",
				"reply": "Antwort verfassen..."
			},
			"typeSelection": "Art auswählen",
			"resizecommentsview": "Kommentaransicht skalieren",
			"comment": "Kommentar",
			"comment_one": "Kommentar",
			"stateSelection": "Zustand auswählen",
			"comment_other": "Kommentare",
			"context": "Kommentarkontext",
			"replyTo": "Antwort auf:",
			"selectedText": "Selektierter Text: \"{{selection}}\""
		},
		"workflow": {
			"releaseDetails": "Freigabedetails",
			"releaseType": "Art der Freigabe",
			"subtreeRelease": "Teilbaumfreigabe",
			"askForSignatureDialog": "Freigabe unterschreiben",
			"activity": "Aktivität",
			"initiator": "Auftraggeber",
			"assignee": "Bearbeiter",
			"assignee_one": "Bearbeiter",
			"dueDate": "Enddatum",
			"lastChangedDate": "Geändert am",
			"createDate": "Erstellt am",
			"startDate": "Startdatum",
			"linkedNodes": "Verbundene Knoten",
			"modelName": "Workflow",
			"noNodeSelected": "Kein Knoten ausgewählt",
			"assignee_other": "Bearbeiter",
			"customColumDefinition_other": "Spaltendefinitionen",
			"action_other": "Aktionen",
			"parent": "Übergeordneter Workflow",
			"subWorkflow_other": "Unterworkflows",
			"addFieldValue": "Feldwert hinzufügen",
			"selectNode": "Knoten auswählen",
			"fieldValueOriginal": "Ursprünglicher Wert",
			"fieldValueUpdated": "Aktualisierter Wert",
			"urlOpened": "Link geöffnet",
			"confirmInput": "Eingabe bestätigen:"
		},
		"releasestate": {
			"isImplicitlyReleased": "Indirekt freigegeben"
		},
		"auth": {
			"username": "Benutzername",
			"password": "Passwort",
			"loggedOut": "Abgemeldet"
		},
		"nodeproperties": "Knoteneigenschaften",
		"properties": {
			"_": "Metadaten",
			"inherited": "geerbt",
			"search": {
				"titles": "Suche nach Titeln von Metadaten"
			},
			"readOnlyAccess": "Lesender Zugriff"
		},
		"askRequiredMetadataForm": {
			"requiredMetadata": "Pflichtmetadaten"
		},
		"createNewNodeForm": {
			"RelativeInsertionPosition": "Position",
			"title": "Titel",
			"configId": "Typ",
			"loading": {
				"configId": "Typ"
			},
			"error": {
				"configId": "Typ"
			}
		},
		"relativeInsertionPosition": {
			"AFTER": "Nach dem gewählten Inhalt",
			"BEFORE": "Vor dem gewählten Inhalt",
			"AS_CHILD": "Als untergeordneten Inhalt"
		},
		"sort": {
			"properties": {
				"default": "Standard"
			},
			"default": "Kategorisiert",
			"alphanumeric": "Alphabetisch",
			"descendingalphanumeric": "Alphabetisch absteigend"
		},
		"filter": {
			"all": "Alle Metadaten",
			"filled": "Gefüllte Metadaten",
			"unfilled": "Ungefüllte Metadaten"
		},
		"tasks": {
			"activeTasks": "Aktive Tracks",
			"closeTask": "Track löschen"
		},
		"chooseProject": {
			"title": "Projekt wählen"
		},
		"dashboard": {
			"myAssignedTasks": {
				"listTitle": "Meine Tracks",
				"listItem": {
					"dueDate": "Fällig am: {{dueDate}}",
					"state": "Bearbeitungsstatus: {{state}}"
				}
			}
		},
		"contentBlade": {
			"title": "Inhalte"
		},
		"content": {
			"advancedEditor": "Öffnen im Editor"
		},
		"search": {
			"taskDefinition": {
				"showSearchResult": {
					"displayName": "Suche"
				}
			},
			"blade": {
				"searchFacetsBlade": {
					"title": "Facettenfilter"
				},
				"searchResultBlade": {
					"title": "Suchergebnis",
					"titleWithSearchTerm": "Suchergebnis für \"{{searchTerm}}\""
				},
				"searchOptionsBlade": {
					"title": "Suchoptionen"
				}
			},
			"component": {
				"quickSearchBox": "Wonach suchen Sie?",
				"searchFacetsList": {
					"facetLabels": {
						"nodeClass_facet": "Knotenklasse",
						"node_md_facet": "Zuletzt bearbeitet von",
						"node_mdd_facet": "Zuletzt bearbeitet am"
					},
					"facetItemLabels": {
						"other": "Sonstige",
						"me": "Mir",
						"today": "Heute",
						"yesterday": "Gestern",
						"lastWeek": "Letzte 7 Tage",
						"from": "Von",
						"until": "Bis"
					}
				},
				"searchOptionsSwitch": "Suchoptionen",
				"searchOptionsForm": {
					"searchKeys": "Zu durchsuchende Daten einschränken",
					"searchKey": {
						"imgContent": "Bildinhalte",
						"filetype": "Dateityp"
					},
					"searchVersions": "Zu durchsuchende Versionen einschränken",
					"searchVersion": {
						"latest": "Neueste Version",
						"approved": "Letzte redaktionell freigebene Version",
						"latestApproved": "Neueste und letzte redaktionell freigebene Version",
						"all": "Alle Versionen"
					},
					"searchLanguages": "Zu durchsuchende Sprachen einschränken",
					"select": {
						"other": "Weitere"
					}
				}
			}
		},
		"help": {
			"flyoutTitle": "Info",
			"link": {
				"help": {
					"title": "Hilfe"
				},
				"privacy": {
					"title": "Datenschutz"
				}
			}
		},
		"account": {
			"flyoutTitle": "Benutzerkonto: {{userName}}"
		},
		"resourcePoolBlade": {
			"title": "Ressource referenzieren"
		},
		"resourceTree": {
			"graphicOptions": "Grafikoptionen",
			"graphicType": "Grafiktyp wählen",
			"insertLegendTable": "Legende hinzufügen",
			"graphicLabel": "Grafikbeschriftung"
		},
		"imagetype": {
			"img_original": "Grafikgröße übernehmen",
			"img_fixed_column": "Grafik in Textspalte",
			"img_fixed_textarea": "Grafik über gesamten Satzspiegel",
			"img_margin": "Grafik in Marginalienspalte",
			"img_50": "Feste Breite, schmal",
			"img_80": "Feste Breite, breit",
			"img_free": "Grafik im Textfluss"
		},
		"crossReferencePoolBlade": {
			"title": "Querverweis einfügen"
		},
		"fragmentPoolBlade": {
			"title": "Fragment referenzieren",
			"listType": "Listentyp wählen"
		},
		"listtype": {
			"ordered": "Nummerierte Liste",
			"unordered": "Aufzählung erster Ebene",
			"secondLevel": "Aufzählung zweiter Ebene"
		},
		"safetyPoolBlade": {
			"title": "Warnhinweis einfügen",
			"titleEdit": "Warnhinweis ändern"
		},
		"safety": {
			"type": "Art der Gefahr",
			"severity": "Stufe der Gefahr",
			"symbols": "Symbole"
		},
		"variablePoolBlade": {
			"title": "Variable einfügen",
			"referenceType": "Referenz auf:"
		},
		"variable": {
			"referenceType": {
				"cell": "Zelle",
				"variable": "Variable",
				"nameOfValueSet": "Name der Wertemenge",
				"variableName": "Variablenname"
			}
		},
		"metaRefPoolBlade": {
			"title": "Metadatum referenzieren",
			"metaRef": "Metadatum:"
		},
		"ap": {
			"task": {
				"userManagement": {
					"displayName": "Benutzer verwalten"
				},
				"licenseManagement": {
					"displayName": "Lizenzen verwalten"
				},
				"productionManagement": {
					"displayName": "Produktionen verwalten"
				}
			},
			"blade": {
				"userOverview": {
					"displayName": "Benutzerübersicht"
				},
				"userDetails": {
					"displayName": "Benutzer"
				},
				"changePassword": {
					"displayName": "Passwort ändern"
				},
				"changeGroupAssignments": {
					"displayName": "Gruppenzuweisungen ändern"
				},
				"userClaimsOverview": {
					"displayName": "Benutzerclaims"
				},
				"groupOverview": {
					"displayName": "Gruppenübersicht"
				},
				"createGroup": {
					"displayName": "Neue Benutzergruppe anlegen"
				},
				"licenseOverview": {
					"displayName": "Lizenz-Übersicht"
				},
				"licenseDetails": {
					"displayName": "Lizenz-Details"
				}
			},
			"userOverview": {
				"withDeleted": "Gelöschte Benutzer anzeigen",
				"searchForUser": "Nach Name oder E-Mail-Adresse suchen",
				"filterByGroupMembership": "Nach Gruppenzugehörigkeit filtern",
				"deselectAllUsers": "Benutzerauswahl aufheben",
				"selectAllUsers": "Alle Benutzer auswählen",
				"selectedUserCount": "{{selected}} von {{all}} Benutzern ausgewählt",
				"userCount": "{{count}} Benutzer",
				"userIsAbsent": "Abwesend",
				"userIsDisabled": "Deaktiviert",
				"userIsDeleted": "Gelöscht",
				"userType": {
					"db": "Datenbank-Benutzer",
					"ad": "Active Directory-Benutzer"
				}
			},
			"userDetails": {
				"editUser": "Benutzer bearbeiten",
				"showClaims": "Benutzerclaims anzeigen",
				"field": {
					"userName": "Benutzername",
					"initialPassword": "Initialpasswort",
					"displayName": "Anzeigename",
					"email": "E-Mail-Adresse",
					"description": "Beschreibung",
					"displayLanguage": "Anzeigesprache",
					"groups": "Gruppenzugehörigkeiten",
					"deputies": "Stellvertreter",
					"isAbsent": "Abwesend",
					"disabled": "Deaktiviert",
					"isDeleted": "Gelöscht",
					"created": "Erstellungsdatum",
					"modified": "Änderungsdatum"
				}
			},
			"changePassword": {
				"field": {
					"generatedPassword": "Generiertes Passwort",
					"newPassword": "Neues Passwort",
					"newPasswordRetyped": "Neues Passwort wiederholen"
				}
			},
			"changeGroupAssignments": {
				"field": {
					"groups": {
						"add": "Neue Gruppenzuweisungen",
						"remove": "Zu entfernende Gruppenzuweisungen"
					}
				}
			},
			"userClaims": {
				"field": {
					"type": "Name",
					"value": "Wert"
				}
			},
			"passwordPolicy": {
				"title": "Passwort-Richtlinien",
				"noPasswordPolicy": "Momentan sind keine Passwort-Richtlinien festgelegt",
				"minLength": "Mindeslänge des Passworts: {{length}} Zeichen",
				"minUpperCaseLetters": "Mindestanzahl Großbuchstaben: {{number}}",
				"minLowerCaseLetters": "Mindestanzahl Kleinbuchstaben: {{number}}",
				"minDigits": "Mindestanzahl Ziffern: {{number}}",
				"minSpecialCharacters": "Mindestanzahl Sonderzeichen: {{number}}"
			},
			"groupOverview": {
				"withDeleted": "Gelöschte Benutzergruppen anzeigen",
				"searchForGroup": "Nach Name suchen",
				"groupIsDeleted": "Gelöscht"
			},
			"groupForm": {
				"field": {
					"groupName": "Gruppenname"
				}
			},
			"licenseOverview": {
				"userLicensesOnly": "Nur Benutzerlizenzen anzeigen"
			},
			"licenseDetails": {
				"licenseInformation": {
					"detailsAbout": "Details über",
					"available": "Verfügbare Lizenzen",
					"usage": "Momentan verwendete Lizenzen"
				},
				"quotas": "Lizenz-Kontingente",
				"quotaOverview": {
					"searchForQuotas": "Nach Name suchen",
					"quotaCount": "{{count}} Kontingente",
					"selectAllQuotas": "Alle Kontingente auswählen",
					"deselectAllQuotas": "Kontingentauswahl aufheben",
					"selectedQuotasCount": "{{selected}} von {{all}} Kontingenten ausgewählt",
					"quotaUsage": "Kontingent-Verwendung",
					"quotasAssigned": "Zugewiesene Lizenzen",
					"quotasUsed": "Verwendete Lizenzen"
				}
			},
			"licenseType": {
				"floating": "Mehrbenutzer-Lizenz",
				"namedUser": "Benutzergebundene Lizenz"
			},
			"lm": {
				"blade": {
					"addQuotasForm": {
						"displayName": "Lizenz-Kontingente hinzufügen"
					},
					"changeQuotasForm": {
						"displayName": "Lizenz-Kontingent ändern"
					}
				},
				"formHeader": {
					"license": "Lizenz",
					"type": "Typ",
					"available": "Verfügbar",
					"assigned": "Vergeben",
					"free": "Frei"
				},
				"quotaNumbers": {
					"min": "Mindestanzahl zugewiesener Lizenzen",
					"max": "Höchstanzahl zugewiesener Lizenzen",
					"exact": "Anzahl zugewiesener Lizenzen"
				},
				"quotaAssignees": {
					"searchByName": "Nach Name suchen",
					"searchByNameOrGroup": "Nach Name oder Gruppenmitgliedschaft suchen",
					"count": {
						"floating": "{{count}} Benutzer/Gruppen",
						"namedUsers": "{{count}} Benutzer"
					},
					"deselectAllAssignees": "Auswahl aufheben",
					"selectAllAssignees": "Alle auswählen",
					"selectedCount": "{{selected}} von {{all}} ausgewählt"
				}
			}
		},
		"meatball": {
			"tooltip": "Weitere Aktionen"
		}
	},
	"message": {
		"error": {
			"generic": "Es ist ein Fehler aufgetreten!",
			"noLink": "Link mit dem Label {{linkId}} konnte nicht gefunden werden.",
			"nodeRendering": "Fehler beim Rendern des Nodes!",
			"loadingindirectreuses": "Es ist ein Fehler beim Laden der indirekten Wiederverwendungen aufgetreten.",
			"removeComment": "Fehler beim Löschen des Kommentars.",
			"addComment": "Fehler beim Speichern des Kommentars.",
			"changeCommentState": "Fehler beim Ändern des Zustands.",
			"workflow": {
				"commandNotImplemented": "Aktion {{action}} ist in dieser Anwendung nicht verfügbar."
			},
			"auth": {
				"wrongPassword": "Nutzername oder Passwort ungültig",
				"licenseLimit": "Lizenzlimit erreicht",
				"generic": "Während der Anmeldung ist ein Fehler aufgetreten. Wenden Sie sich bitte an Ihren Administrator.",
				"allNamedLicenseAllocated": "Es scheint, dass Sie bereits in einem anderen Browserfenster oder auf einem anderen Gerät angemeldet sind.\r\n    Möchten Sie mit der Anmeldung fortfahren und die andere Sitzung beenden?"
			},
			"noPreview": "Keine Vorschau verfügbar",
			"commentLegacy": {
				"errorRights": "Der Kommentar konnte nicht erzeugt werden. Sie haben keine Berechtigung Daten zu ändern.",
				"errorInlineNotCommentable": "Element-überlappende Selektionen sind nicht kommentierbar.",
				"errorInlineOverlap": "Der selektierte Text überlappt mit einem bestehenden Inline-Kommentar!"
			},
			"inputNotInt": "Eingabe ist keine Ganzzahl.",
			"inputNotDouble": "Eingabe ist keine valide Gleitkommazahl.",
			"inputNotDecimal": "Eingabe ist keine valide Gleitkommazahl.",
			"permissionDenied": "Sie besitzen nicht genügend Rechte für diese Aktion.",
			"nodeLocked": "Dieses Objekt wird bereits von \"{{lockedBy}}\" bearbeitet. Bitte versuchen Sie es später nochmal.",
			"editorSave": {
				"editorSessionExpired": "Diese Editorsitzung ist nicht mehr gültig. Eventuell haben Sie die Seite in einem anderen Tab geöffnet. Aktualisieren Sie die Seite über F5.",
				"general": "Das Speichern war nicht erfolgreich. Versuchen Sie es erneut oder aktualisieren Sie die Seite über F5."
			},
			"ontologyLinksChanged": "Ein Metadatum des Inhalts wurde zwischenzeitlich geändert. Laden Sie die Seite neu und versuchen Sie es nochmal."
		},
		"alert": {
			"noContent": "Keine Inhalte zum Anzeigen!"
		},
		"warning": {
			"guiLanguage": "Die Änderung der Anzeigesprache gilt nach einer neuen Anmeldung.",
			"editorSave": {
				"frozenLinkExists": {
					"default": "Von dem Knoten{{1}} mit der ID {{0}} wird bereits eine festgelegte Version referenziert. Sie können daher keine Referenz auf die jeweils aktuelle Version anlegen. SCHEMA ST4 referenziert stattdessen erneut die festgelegte Version.",
					"graphic": "Von der Grafik{{1}} mit der ID {{0}} wird bereits eine festgelegte Version referenziert. Sie können daher keine Referenz auf die jeweils aktuelle Version anlegen. SCHEMA ST4 referenziert stattdessen erneut die festgelegte Version.",
					"graphicGroup": "Von der Grafikgruppe{{1}} mit der ID {{0}} wird bereits eine festgelegte Version referenziert. Sie können daher keine Referenz auf die jeweils aktuelle Version anlegen. SCHEMA ST4 referenziert stattdessen erneut die festgelegte Version.",
					"video": "Vom Video{{1}} mit der ID {{0}} wird bereits eine festgelegte Version referenziert. Sie können daher keine Referenz auf die jeweils aktuelle Version anlegen. SCHEMA ST4 referenziert stattdessen erneut die festgelegte Version.",
					"callout": "Von der Callout-Grafik{{1}} mit der ID {{0}} wird bereits eine festgelegte Version referenziert. Sie können daher keine Referenz auf die jeweils aktuelle Version anlegen. SCHEMA ST4 referenziert stattdessen erneut die festgelegte Version.",
					"variable": "Von der Variable{{1}} mit der ID {{0}} wird bereits eine festgelegte Version referenziert. Sie können daher keine Referenz auf die jeweils aktuelle Version anlegen. SCHEMA ST4 referenziert stattdessen erneut die festgelegte Version.",
					"fragment": "Vom Fragment{{1}} mit der ID {{0}} wird bereits eine festgelegte Version referenziert. Sie können daher keine Referenz auf die jeweils aktuelle Version anlegen. SCHEMA ST4 referenziert stattdessen erneut die festgelegte Version.",
					"fragmentGroup": "Von der Fragmentgruppe{{1}} mit der ID {{0}} wird bereits eine festgelegte Version referenziert. Sie können daher keine Referenz auf die jeweils aktuelle Version anlegen. SCHEMA ST4 referenziert stattdessen erneut die festgelegte Version.",
					"metaref": "Von dem Datenknoten{{1}} mit der ID {{0}} wird bereits eine festgelegte Version referenziert. Sie können daher keine Referenz auf die jeweils aktuelle Version anlegen. SCHEMA ST4 referenziert stattdessen erneut die festgelegte Version."
				},
				"floatingLinkExists": {
					"default": "Es existiert bereits eine dynamische Referenz auf die aktuelle Version des Knotens{{1}} mit der ID {{0}}. Sie können daher keine festgelegte Referenz auf die gewählte Version des Knotens anlegen. SCHEMA ST4 referenziert stattdessen erneut die jeweils aktuelle Version des Knotens.",
					"graphic": "Es existiert bereits eine dynamische Referenz auf die aktuelle Version der Grafik{{1}} mit der ID {{0}}. Sie können daher keine festgelegte Referenz auf die gewählte Version der Grafik anlegen. SCHEMA ST4 referenziert stattdessen erneut die jeweils aktuelle Version der Grafik.",
					"graphicGroup": "Es existiert bereits eine dynamische Referenz auf die aktuelle Version der Grafikgruppe{{1}} mit der ID {{0}}. Sie können daher keine festgelegte Referenz auf die gewählte Version der Grafikgruppe anlegen. SCHEMA ST4 referenziert stattdessen erneut die jeweils aktuelle Version der Grafikgruppe.",
					"video": "Es existiert bereits eine dynamische Referenz auf die aktuelle Version des Videos{{1}} mit der ID {{0}}. Sie können daher keine festgelegte Referenz auf die gewählte Version des Videos anlegen. SCHEMA ST4 referenziert stattdessen erneut die jeweils aktuelle Version des Videos.",
					"callout": "Es existiert bereits eine dynamische Referenz auf die aktuelle Version der Callout-Grafik{{1}} mit der ID {{0}}. Sie können daher keine festgelegte Referenz auf die gewählte Version der Callout-Grafik anlegen. SCHEMA ST4 referenziert stattdessen erneut die jeweils aktuelle Version der Callout-Grafik.",
					"variable": "Es existiert bereits eine dynamische Referenz auf die aktuelle Version der Variable{{1}} mit der ID {{0}}. Sie können daher keine festgelegte Referenz auf die gewählte Version der Variable anlegen. SCHEMA ST4 referenziert stattdessen erneut die jeweils aktuelle Version der Variable.",
					"fragment": "Es existiert bereits eine dynamische Referenz auf die aktuelle Version des Fragments{{1}} mit der ID {{0}}. Sie können daher keine festgelegte Referenz auf die gewählte Version des Fragments anlegen. SCHEMA ST4 referenziert stattdessen erneut die jeweils aktuelle Version des Fragments.",
					"fragmentGroup": "Es existiert bereits eine dynamische Referenz auf die aktuelle Version der Fragmentgruppe{{1}} mit der ID {{0}}. Sie können daher keine festgelegte Referenz auf die gewählte Version der Fragmentgruppe anlegen. SCHEMA ST4 referenziert stattdessen erneut die jeweils aktuelle Version der Fragmentgruppe.",
					"metaref": "Es existiert bereits eine dynamische Referenz auf die aktuelle Version des Datenknotens{{1}} mit der ID {{0}}. Sie können daher keine festgelegte Referenz auf die gewählte Version des Datenknotens anlegen. SCHEMA ST4 referenziert stattdessen erneut die jeweils aktuelle Version des Datenknotens."
				},
				"frozenAndFloatingLink": {
					"default": "Sie versuchen sowohl eine festgelegte als auch eine dynamische Referenz zum Knoten{{1}} mit der ID {{0}} anzulegen. Dies ist nicht möglich. Stattdessen wird eine Referenz auf eine festgelegte Version angelegt.",
					"graphic": "Sie versuchen sowohl eine festgelegte als auch eine dynamische Referenz zur Grafik{{1}} mit der ID {{0}} anzulegen. Dies ist nicht möglich. Stattdessen wird eine Referenz auf eine festgelegte Version angelegt.",
					"graphicGroup": "Sie versuchen sowohl eine festgelegte als auch eine dynamische Referenz zur Grafikgruppe{{1}} mit der ID {{0}} anzulegen. Dies ist nicht möglich. Stattdessen wird eine Referenz auf eine festgelegte Version angelegt.",
					"video": "Sie versuchen sowohl eine festgelegte als auch eine dynamische Referenz zum Video{{1}} mit der ID {{0}} anzulegen. Dies ist nicht möglich. Stattdessen wird eine Referenz auf eine festgelegte Version angelegt.",
					"callout": "Sie versuchen sowohl eine festgelegte als auch eine dynamische Referenz zur Callout-Grafik{{1}} mit der ID {{0}} anzulegen. Dies ist nicht möglich. Stattdessen wird eine Referenz auf eine festgelegte Version angelegt.",
					"variable": "Sie versuchen sowohl eine festgelegte als auch eine dynamische Referenz zur Variable{{1}} mit der ID {{0}} anzulegen. Dies ist nicht möglich. Stattdessen wird eine Referenz auf eine festgelegte Version angelegt.",
					"fragment": "Sie versuchen sowohl eine festgelegte als auch eine dynamische Referenz zum Fragment{{1}} mit der ID {{0}} anzulegen. Dies ist nicht möglich. Stattdessen wird eine Referenz auf eine festgelegte Version angelegt.",
					"fragmentGroup": "Sie versuchen sowohl eine festgelegte als auch eine dynamische Referenz zur Fragmentgruppe{{1}} mit der ID {{0}} anzulegen. Dies ist nicht möglich. Stattdessen wird eine Referenz auf eine festgelegte Version angelegt.",
					"metaref": "Sie versuchen sowohl eine festgelegte als auch eine dynamische Referenz zum Datenknoten{{1}} mit der ID {{0}} anzulegen. Dies ist nicht möglich. Stattdessen wird eine Referenz auf eine festgelegte Version angelegt."
				},
				"linkForResourceCouldNotBeCreated": "Der Verweis auf die Ressource mit der ID {{0}} konnte nicht aufgelöst werden. Daher wird die Referenz gelöscht, wenn Sie den Inhalt schließen.",
				"linkForVariableCouldNotBeCreated": "Der Verweis auf die Variable mit der ID {{0}} konnte nicht aufgelöst werden. Daher wird die Referenz gelöscht, wenn Sie den Inhalt schließen.",
				"linkForMetarefCouldNotBeCreated": "Der Verweis auf den Datenknoten mit der ID {{0}} konnte nicht aufgelöst werden. Daher wird die Referenz gelöscht, wenn Sie den Inhalt schließen.",
				"linkForTextModuleCouldNotBeCreated": "Der Verweis auf das Fragment mit der ID {{0}} konnte nicht aufgelöst werden. Daher wird die Wiederverwendung aufgehoben, wenn Sie den Inhalt schließen.",
				"invalidLinkRemovalForTextModuleCausedInvalidXML": "Der Verweis auf das Fragment mit der ID {{0}} konnte nicht aufgelöst werden. Die Wiederverwendung konnte nicht automatisch aufgehoben werden.\r\n    Bitte entfernen sie das Fragment bevor Sie den Inhalt schließen."
			}
		},
		"general": {
			"loadingindirectreuses": "Indirekte Verwendungen geladen...",
			"andMore_neutral": "und {{count}} weiteres",
			"andMore_neutral_one": "und {{count}} weiteres",
			"andMore_neutral_other": "und {{count}} weitere",
			"andMore": "und weitere"
		},
		"info": {
			"olddata": "Die angezeigten Daten könnten veraltet sein.",
			"changeLanguageRequiresLogin": "Das Ändern der Oberflächensprache erfordert, dass Sie sich erneut einloggen müssen."
		},
		"confirm": {
			"delete": "Wirklich löschen?",
			"cancel": "Möchten Sie wirklich abbrechen?"
		},
		"preview": {
			"comments": {
				"emptyCollection": "Keine Kommentare vorhanden.",
				"confirmInputAbort": "Möchten Sie die Erfassung des Kommentars abbrechen?\r\nMit einem Klick auf \"Ja\" geht Ihr eingegebener Kommentar verloren."
			},
			"previewLimit": "Die maximale Anzahl an Knoten wurde erreicht",
			"emphasisNotFound": "Auszeichnung \"{{type}}\" ist nicht definiert.",
			"metaref": {
				"ontologyNotSelected": "Dem Knoten {{node}} wurde keine Taxonomie vom Typ {{taxonomyName}} zugewiesen.",
				"dynamicReferenceNotFound": "Kontextabhängige Referenz auf das Metadatum \"{{metadataName}}\" wurde auf dem Knoten {{node}} nicht gefunden."
			},
			"resourceNode": {
				"noContent": "Hier fehlt Inhalt."
			}
		},
		"validation": {
			"dateFormat": "Ungültiges Datumsformat. Verwenden Sie das Format JJJJ-MM-TT.",
			"numberDouble": "{{value}} ist keine gültige Gleitkommazahl. Verwenden Sie einen Dezimalpunkt.",
			"emptyRow": "Keine leeren Zeilen erlaubt!",
			"required": "Pflichtfeld",
			"xml": "Ungültiges XML",
			"minCount": "Minimale Anzahl: {0}, Aktuelle Anzahl: {1}",
			"maxCount": "Maximale Anzahl: {0}, Aktuelle Anzahl: {1}",
			"minLength": "Minimale Textlänge: {0}, Aktuelle Textlänge: {1}",
			"maxLength": "Maximale Textlänge: {0}, Aktuelle Textlänge: {1}",
			"minDate": "Frühestes Datum: {0} (UTC)",
			"maxDate": "Spätestes Datum: {0} (UTC)",
			"minValue": "Kleinster Wert: {0}",
			"maxValue": "Größter Wert: {0}",
			"format": "Falsches Eingabeformat",
			"filesizeToLarge": "Die Datei ist zu groß. Maximale Dateigröße: {0} MB",
			"fileType": "Dieser Dateityp ist nicht erlaubt. Erlaubt: {0}",
			"auth": {
				"missingUsername": "Bitte geben Sie Ihren Benutzernamen ein",
				"missingPassword": "Bitte geben sie ihr Passwort ein"
			},
			"passwordConfirmed": "Bestätigung stimmt nicht mit dem Passwort überein!"
		},
		"workflow": {
			"askForSignature": "Benutzername und Passwort eingeben, um die Freigabe zu unterschreiben",
			"instanceChangedNow": "Die Aufgabe wurde soeben von {{lastModifiedUser}} bearbeitet. Ihre Änderungen gehen verloren.",
			"instanceChangedOrInvisible": "Der Zustand der Aufgabe wurde geändert und sie ist nicht mehr sichtbar.",
			"noTasks": "Keine offenen Aufgaben",
			"invalidField_other": "Fortsetzen aufgrund invalider Feldwerte nicht möglich",
			"changedField": "Änderung am Feld \"{{fieldName}}\"",
			"changedField_one": "Änderung am Feld \"{{fieldName}}\"",
			"changedField_other": "Änderungen an Feldern [{{count}}]",
			"changeGeneric": "Änderungen am Workflow",
			"dataOutdated": "Diese Workflow-Aufgabe wurde in der Zwischenzeit verändert. Der Übergang konnte nicht ausgeführt werden.",
			"transactionCancel": "Wollen Sie diesen Übergang wirklich abbrechen? Eingegebene Formulardaten werden nicht gespeichert.",
			"historyModifiedBy": "{{user}} hat Änderungen am Workflow vorgenommen.",
			"urlOpenedInWindow": "Der Link zu \"{{url}}\" wurde in einem neuen Fenster geöffnet."
		},
		"auth": {
			"loggedOut": "Sie haben sich abgemeldet."
		},
		"properties": {
			"valueInherited": "Wert von übergeordneten Knoten geerbt."
		},
		"createNewNodeForm": {
			"configId": {
				"error": "Keine Auswahl verfügbar!"
			}
		},
		"createNewNodeVisualizer": {
			"error": {
				"commandNotImplemented": "Nicht implementiert"
			},
			"question": {
				"switchContext": "Wenn Sie den Zielort für den neuen Inhalt ändern, gehen Ihre bisherigen Angaben verloren.\r\n    Wollen Sie dennoch wechseln?"
			}
		},
		"tasks": {
			"confirmClose": "Möchten Sie diesen Track wirklich löschen?"
		},
		"chooseProject": {
			"projectFolderSelected": "Bitte wählen Sie ein Projekt, keinen Ordner."
		},
		"dashboard": {
			"welcome": {
				"title": "Willkommen zurück"
			},
			"myAssignedTasks": {
				"error": {
					"loading": "Fehler beim Laden Ihrer zugewiesenen Tracks."
				},
				"info": {
					"noTasks": "Aktuell haben Sie keine aktiven Tracks."
				}
			}
		},
		"editor": {
			"canEditCheck": {
				"error": {
					"InternalError": "Das hat leider nicht geklappt. Versuchen Sie es später nochmal.",
					"PermissionDenied": "Sie können diesen Inhalt nicht bearbeiten. Ihnen fehlen die erforderlichen Berechtigungen.",
					"NodeLocked": "Dieser Inhalt wird bereits von „{{0}}“ bearbeitet. Versuchen Sie es später nochmal.",
					"NodeReused": "Der Inhalt ist an dieser Stelle wiederverwendet. Daher können Sie den Inhalt nicht direkt bearbeiten. \r\nWechseln Sie in den SCHEMA ST4 Power oder Author Client, um diesen Inhalt zu bearbeiten.",
					"ContentLinked": "Der Inhalt ist an dieser Stelle wiederverwendet. Daher können Sie den Inhalt nicht direkt bearbeiten.\r\nWechseln Sie in den SCHEMA ST4 Power oder Author Client, um diesen Inhalt zu bearbeiten.",
					"AspectNotEditingLanguage": "Sie können den Inhalt in der aktuellen Sprache nicht bearbeiten. Der Inhalt wurde in einer anderen Sprache erfasst. \r\nWechseln Sie in den SCHEMA ST4 Power oder Author Client, um die Inhalte ohne Übersetzungsworkflow bearbeiten zu können.",
					"NodeReleasedCurrentAspect": "Dieser Inhalt ist in der aktuellen Sprache freigegeben. \r\nWechseln Sie in den SCHEMA ST4 Power oder Author Client, um eine neue Version anzulegen. Anschließend können Sie diesen Inhalt bearbeiten.",
					"NodeReleasedOtherAspect": "Dieser Inhalt ist in einer anderen Sprache freigegeben. \r\nWechseln Sie in den SCHEMA ST4 Power oder Author Client, um eine neue Version anzulegen. Anschließend können Sie diesen Inhalt bearbeiten.",
					"NodeDeleted": "Sie haben versucht einen Inhalt zu öffnen. Dieser Inhalt wurde aber bereits endgültig gelöscht.\r\nSchließen Sie die Fehlermeldung und gegebenenfalls den Editor."
				},
				"warning": {
					"NodeInTranslation": "Dieser Inhalt befindet sich gerade in der Übersetzung.\r\nWenn Sie den Inhalt ändern, müssen Sie ihn neu übersetzen lassen."
				}
			}
		},
		"search": {
			"info": {
				"searchingForTerm": "Durchsuche ST4 nach \"{{searchTerm}}\" ..."
			},
			"warning": {
				"noSearchTerm": "Bitte geben Sie erst einen Suchbegriff ein!",
				"noSearchResult": "Die Suche in ST4 nach \"{{searchTerm}}\" führte zu keinen Suchtreffern!",
				"noSearchFacets": "Die Suche in ST4 nach \"{{searchTerm}}\" führte zu keinen Filterfacetten!"
			},
			"error": {
				"fromException": "Fehler bei der Suche in ST4: \"{{errorName}}\" - \"{{errorMessage}}\"",
				"corruptResponse": "Fehler bei der Suche in ST4: \"Keine Daten\" - \"Es wurde weder ein Fehler noch Daten von der GraphQL API empfangen!\""
			}
		},
		"createNewNode": {
			"class": {
				"docuManagerOnly": "Inhalt dieses Typs kann nur in ST4 DocuManager erstellt werden",
				"permissionDenied": "Sie dürfen hier keinen Inhalt dieses Typs erstellen"
			}
		},
		"resourcePoolBlade": {
			"folder": "Wählen Sie eine Ressource, keinen Ordner.",
			"not_possible": "An dieser Stelle können Sie keine Grafik einfügen.\r\nSetzen Sie den Cursor stattdessen in einen Absatz.",
			"rights": "Ihnen fehlen die Rechte zum Einfügen der gewählten Ressource.",
			"thumbnail": "Für die gewählte Ressource existiert keine Thumbnail-Vorschau.",
			"noslot": "In der gewählten Ressource befindet sich keine Grafik."
		},
		"crossReferencePoolBlade": {
			"not_possible_cross_reference": "Sie können den gewählten Querverweis an dieser Stelle nicht einfügen."
		},
		"fragmentPoolBlade": {
			"no_fragment": "Wählen Sie ein Fragment.",
			"rights": "Ihnen fehlen die Rechte zum Einfügen des gewählten Fragments.",
			"recursion": "Das Einfügen dieses Fragments würde eine Endlosschleife erzeugen.",
			"empty": "Das Fragment besitzt keinen Inhalt.",
			"empty_group": "Sie haben einen Gruppenknoten gewählt.\r\nDa dem Gruppenknoten keine Inhalte zugeordnet sind, können Sie ihn nicht einfügen.",
			"not_possible_structure_fragment": "Sie können das gewählte Strukturfragment an dieser Stelle nicht einfügen. \r\nDie Struktur des Inhalts und der Typ des Strukturfragments passen nicht zueinander.",
			"not_possible_fragment": "Sie können das gewählte Fragment an dieser Stelle nicht einfügen.\r\nDie Struktur des Inhalts und das Fragment passen nicht zueinander."
		},
		"safetyPool": {
			"not_possible": "Sie können keinen Warnhinweis an dieser Stelle einfügen. \r\nDie Struktur des Inhalts und der Warnhinweis passen nicht zueinander."
		},
		"variablePoolBlade": {
			"not_possible": "Sie können keine Variable an dieser Stelle einfügen.\r\nDie Struktur des Inhalts und die Variable passen nicht zueinander."
		},
		"metaRefPoolBlade": {
			"not_possible": "Sie können kein Metadatum an dieser Stelle einfügen.\r\nDie Struktur des Inhalts und das Metadatum passen nicht zueinander."
		},
		"ap": {
			"error": {
				"userDetails": {
					"noUserGiven": "Kein Benutzer übergeben"
				},
				"userOverview": {
					"noEnabledUsersSelected": "Keiner der gewählten Benutzer ist aktiviert!",
					"noDisabledUsersSelected": "Keiner der gewählten Benutzer ist deaktiviert!"
				},
				"userClaims": {
					"noPermissions": "Sie sind nicht hinreichend berechtigt, um Benutzerclaims zu sehen!"
				},
				"licenseOverview": {
					"noData": "Weder Fehler noch Daten von der Web-Schnittstelle erhalten! Bitte versuchen Sie es noch einmal oder kontaktieren Sie Ihren Administrator!"
				},
				"licenseDetails": {
					"noSelectedLicense": "Es wurde keine Lizenz ausgewählt!",
					"noData": "Weder Fehler noch Daten von der Web-Schnittstelle erhalten! Bitte versuchen Sie es noch einmal oder kontaktieren Sie Ihren Administrator!"
				}
			},
			"validationError": {
				"userNameRequired": "\"Benutzername\" ist ein Pflichtfeld",
				"displayNameRequired": "\"Anzeigename\" ist ein Pflichtfeld",
				"invalidEmailFormat": "\"E-Mail-Adresse\" entspricht nicht dem gültigen Format",
				"emptyPassword": "Leere Passwörter sind nicht zulässig",
				"passwordsNotIdentical": "Die eingebenen Passwörter sind nicht identisch",
				"passwordInvalid": "Die eingebenen Passwort entspricht nicht den Passwort-Richtlinien",
				"NoGroupSelected": "Keine Gruppe ausgewählt",
				"GroupNameRequired": "\"Gruppenname\" ist ein Pflichtfeld"
			},
			"success": {
				"passwordChanged": "Passwort erfolgreich geändert",
				"setAbsent": "Abwesenheit erfolgreich gespeichert",
				"setPresent": "Anwesenheit erfolgreich gespeichert",
				"singleUserEnabled": "Benutzer erfolgreich aktiviert",
				"singleUserDisabled": "Benutzer erfolgreich deaktiviert",
				"singleUserDeleted": "Benutzer erfolgreich gelöscht",
				"singleUserUndeleted": "Löschung erfolgreich rückgängig gemacht",
				"userCreated": "Neuer Benutzer erfolgreich angelegt",
				"userUpdated": "Änderungen am Benutzer erfolgreich gespeichert",
				"usersAddedToGroups": "Benutzer erfolgeich zu Gruppen hinzugefügt",
				"usersRemovedFromGroups": "Benutzer erfolgreich aus Gruppen entfernt",
				"multiUsersEnabled": "Benutzer erfolgreich aktiviert",
				"multiUsersDisabled": "Benutzer erfolgreich deaktiviert",
				"multiUsersDeleted": "Benutzer erfolgreich gelöscht",
				"multiUsersUndeleted": "Löschungen erfolgreich rückgängig gemacht",
				"groupCreated": "Benutzergruppe erfolgreich angelegt",
				"groupDeleted": "Benutzergruppe erfolgreich gelöscht",
				"groupUndeleted": "Löschung erfolgreich rückgängig gemacht",
				"licenseDetails": {
					"quotasAdded": "Kontingente erfolgreich hinzugefügt",
					"quotaChanged": "Kontingent erfolgreich geändert",
					"quotasChanged": "Kontingente erfolgreich geändert",
					"quotaRejected": "Kontingent erfolgreich entzogen",
					"quotasRejected": "Kontingente erfolgreich entzogen"
				}
			},
			"hasUnsavedChanges": {
				"title": "Ungespeicherte Änderungen",
				"body": "Ihre letzten Änderungen sind nicht gespeichert und gehen verloren.",
				"question": "Sind Sie sicher, dass die Bearbeitung des Benutzerprofils abbrechen wollen?"
			},
			"info": {
				"userClaims": {
					"noUserClaimsFound": "Es konnten keine Claims von Benutzer {{userName}} gefunden werden!"
				},
				"generatedPassword": "Generiertes Passwort: {{password}}",
				"licenseDetails": {
					"noQuotas": "Momentan sind keine Kontigente an Benutzer oder Gruppen zugewiesen!"
				}
			},
			"warning": {
				"licenseDetails": {
					"noSupportedLicenseTypes": "{{name}} ist weder ein Mehrbenuter-Lizenz noch eine benutzerbezogene Lizenz. Es sind keine Detailinformationen verfügbar!"
				}
			},
			"lm": {
				"warning": {
					"addQuotasForm": {
						"noSupportedLicenseTypes": "{{name}} ist weder ein Mehrbenuter-Lizenz noch eine benutzerbezogene Lizenz. Es können keine Lizenz-Kontingente zugewiesen werden!"
					},
					"changeQuotasForm": {
						"noSupportedLicenseTypes": "{{name}} ist weder ein Mehrbenuter-Lizenz noch eine benutzerbezogene Lizenz. Es kann kein Lizenz-Kontingent geändert werden!"
					}
				},
				"error": {
					"addQuotasFormBlade": {
						"noData": "Weder Fehler noch Daten von der Web-Schnittstelle erhalten! Bitte versuchen Sie es noch einmal oder kontaktieren Sie Ihren Administrator!",
						"noLicense": "Es wurde keine Lizenz übergeben, der Lizenzkontingente hinzugefügt werden können!"
					},
					"changeQuotasFormBlade": {
						"noData": "Weder Fehler noch Daten von der Web-Schnittstelle erhalten! Bitte versuchen Sie es noch einmal oder kontaktieren Sie Ihren Administrator!",
						"noLicense": "Es wurde keine Lizenz übergeben, bei der Lizenzkontingente geändert werden können!",
						"noQuotas": "Es wurde kein Lizenzkontingent übergeben, das geändert werden soll!"
					}
				},
				"validationError": {
					"NotEnoughFreeLicenses": "Sie versuchen {{actual}} Lizenzen zuzuweisen, aber es sind nur {{max}} freie Lizenzen übrig!"
				}
			}
		},
		"tree": {
			"dnd": {
				"tryAgain": "Leider ist etwas schief gelaufen, bitte versuchen Sie es noch einmal."
			}
		}
	},
	"description": {
		"image": {
			"width": {
				"50": "Feste Breite, schmal",
				"80": "Feste Breite, breit",
				"fixedColumn": "Grafik in Textspalte",
				"fixedTextarea": "Grafik über gesamten Satzspiegel",
				"margin": "Grafik in Marginalienspalte",
				"original": "Grafikgröße übernehmen"
			}
		},
		"hyperlink": {
			"opensInNewWindow": "Öffnet den Link in einem neuen Tab"
		},
		"workflow": {
			"historyTab": "Die Änderungshistorie dieser Workflow-Aufgabe",
			"overviewTab": "Übersicht über die aktuellen Feldwerte dieser Workflow-Aufgabe",
			"action": "Aktionen, die an dieser Workflow-Aufgabe ausgeführt werden können"
		},
		"preview_legacy": {
			"selectedTextConfirm": "Textauswahl kommentieren"
		},
		"createNewNode": {
			"start": "Inhalt anlegen..."
		},
		"createNewNodeForm": {
			"formTitle": "Inhalt anlegen",
			"configId": {
				"error": "Für die aktuellen Angaben konnte keine Typauswahl geladen werden!"
			},
			"permisson": {
				"error": "Sie besitzen nicht die Berechtigung, um hier neuen Inhalt anzulegen."
			}
		},
		"createNewNodeVisualizer": {
			"error": {
				"commandNotImplemented": "Für das Kommando \"{{commandName}}\" ist keine Oberfläche implementiert!"
			},
			"inProgress": {
				"createNode": "Inhaltserstellung läuft ..."
			}
		},
		"search": {
			"component": {
				"searchResultPagination": {
					"totalText": "{{rangeStart}}-{{rangeEnd}} von ca. {{totalCount}} Treffern"
				}
			}
		},
		"status": {
			"pageNotFound": "Die angeforderte Seite konnte nicht gefunden werden (404)."
		},
		"task": {
			"loading": "Track wird geladen.",
			"notFound": "Der Track existiert nicht.",
			"deleted": "Der Track wurde gelöscht."
		}
	}
}