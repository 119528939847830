import React, { ComponentType, useEffect } from "react"
import type { CreateNewNodeJobResponse } from "../../query.hooks.types"
import styled from "styled-components"
import {
  convertSelectionItemsOfCreateNewNodeConfigForTree,
  isNodeDialogCommandJobResponse,
} from "../../query.hooks.utils"
import { Form, Input, TreeSelect, Skeleton } from "antd"
import { useTranslation } from "react-i18next"
import { keys } from "@st4/ui-strings"
import { AlertError } from "./AlertError"
import { useStepSaveBaseData } from "./hooks/StepSaveBaseData"
import { useStepSaveBaseDataHandler } from "./hooks/StepSaveBaseDataHandler"
import type { StepSaveBaseDataInputValues } from "./types"
import type { ActionHandlerInfo } from "../types"
import { Translate } from "@st4/i18n"
import {
  AskRequiredMetadataOnCreateNewNodeCommandJobResponseFragment,
  CreateNewNodeCommandJobResponseFragment,
} from "../../query.hooks"

type CreateNewNodeFormProps = {
  jobId: string
  contextNodeId: string
  hideTitle: boolean
  hideNodeClassSelection: boolean
  cachedConfigId?: string | null
  onInputChanged: (inputValues: StepSaveBaseDataInputValues) => void
  onActionsChanged: (info: ActionHandlerInfo) => void
  onMutationCompleted: (result: CreateNewNodeJobResponse) => void
}

type PermissionErrorProps = {
  jobId: string
  onActionsChanged: (info: ActionHandlerInfo) => void
  onMutationCompleted: (result: CreateNewNodeJobResponse) => void
}

type FormValues = ComponentType<any> & {
  title: string
  configId: string
}

const StyledForm = styled(Form)`
  padding: 16px;
`

function CreateNewNodeForm(props: CreateNewNodeFormProps) {
  const { hideTitle, hideNodeClassSelection } = props

  const { t } = useTranslation()
  const { loading, data, error, defaultTitle, configId, handleFinish, handleTitleChange, handleConfigIdChange } =
    useStepSaveBaseData(props)
  const [form] = Form.useForm()

  useEffect(() => {
    if (form.getFieldsValue().configId != configId) form.setFieldValue("configId", configId)
  }, [configId, form])

  return (
    <StyledForm<FormValues> layout="vertical" onFinish={handleFinish} form={form}>
      {!hideNodeClassSelection && !loading && !error && configId && (
        <Form.Item
          label={<Translate>{keys.label.createNewNodeForm.configId}</Translate>}
          name="configId"
          rules={[{ required: true }]}
        >
          <TreeSelect
            style={{ width: "100%" }}
            treeData={convertSelectionItemsOfCreateNewNodeConfigForTree(
              data?.selectionOfCreateNewNodeConfigInfo.rootItemIds ?? [],
              data?.selectionOfCreateNewNodeConfigInfo.items ?? [],
              t,
            )}
            showSearch={true}
            treeNodeFilterProp="title"
            dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
            placeholder={t(keys.label.general.pleaseSelect)}
            dropdownMatchSelectWidth={false}
            onChange={(v) => handleConfigIdChange(v.toString())}
          />
        </Form.Item>
      )}

      {!hideNodeClassSelection && !error && (loading || !configId) && (
        <Form.Item
          label={<Translate>{keys.label.createNewNodeForm.loading.configId}</Translate>}
          rules={[{ required: true }]}
        >
          <Skeleton.Input size="large" active={true} />
        </Form.Item>
      )}

      {!hideNodeClassSelection && !loading && error && (
        <Form.Item label={t(keys.label.createNewNodeForm.error.configId)} rules={[{ required: true }]}>
          <AlertError messageKey={keys.description.createNewNodeForm.configId.error} />
        </Form.Item>
      )}

      {!hideTitle && (
        <Form.Item label={<Translate>{keys.label.createNewNodeForm.title}</Translate>} name="title">
          <Input onChange={(e) => handleTitleChange(e.target.value)} placeholder={defaultTitle} autoFocus={true} />
        </Form.Item>
      )}
    </StyledForm>
  )
}

function PermissionError(props: PermissionErrorProps) {
  const { onActionsChanged } = props

  const { handleClose } = useStepSaveBaseDataHandler(props)

  useEffect(
    () =>
      onActionsChanged({
        closeSilent: true,
        onClose: handleClose,
      }),
    [handleClose, onActionsChanged],
  )

  return <AlertError messageKey={keys.description.createNewNodeForm.permisson.error} />
}

export function getterFormCreateNewNodeByCommandInfo(
  commandInfo: CreateNewNodeCommandJobResponseFragment | AskRequiredMetadataOnCreateNewNodeCommandJobResponseFragment,
  cachedConfigId: string | null | undefined,
  onInputChanged: (inputValues: StepSaveBaseDataInputValues) => void,
  onActionbarChanged: (info: ActionHandlerInfo) => void,
  onMutationCompleted: (result: CreateNewNodeJobResponse) => void,
) {
  if (!isNodeDialogCommandJobResponse(commandInfo)) return undefined
  if (commandInfo.commandName != "ShowCreateNewNodeDialog") return undefined

  const canCreateChildNode: boolean = commandInfo.canCreateChildNode

  if (!canCreateChildNode)
    return (
      <PermissionError
        jobId={commandInfo.jobId}
        onActionsChanged={onActionbarChanged}
        onMutationCompleted={onMutationCompleted}
      />
    )

  return (
    <CreateNewNodeForm
      {...commandInfo}
      cachedConfigId={cachedConfigId}
      onInputChanged={onInputChanged}
      onActionsChanged={onActionbarChanged}
      onMutationCompleted={onMutationCompleted}
    />
  )
}
