import React from "react"
import { ListedProperties, ListedPropertiesProps } from "./ListedProperties"
import { SortProperties } from "./sorting"

type SortedPropertiesProps = {
  sortSelection: string
} & ListedPropertiesProps

export function SortedProperties(props: SortedPropertiesProps) {
  const { properties, sortSelection, ...otherProps } = props
  const sortedProperties = SortProperties(properties, sortSelection)
  return <ListedProperties {...otherProps} properties={sortedProperties} />
}
