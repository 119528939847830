import React from "react"
import styled from "styled-components"
import { Tag } from "antd"
import { keys } from "@st4/ui-strings"
import { Translate } from "@st4/i18n"
import { TaxonomyDisplayInfo, TreeNode } from "./types"
import { Icon, Light } from "@st4/icons"

function DisableEntryAndChildren(treeNode: TreeNode): TreeNode {
  return {
    ...treeNode,
    selectable: false,
    checkable: false,
    children: treeNode.children?.map((child) => DisableEntryAndChildren(child)),
  }
}

export function DisableEntries(taxTree: TreeNode[]) {
  return taxTree.map((treeNode) => DisableEntryAndChildren(treeNode))
}

export function MarkInheritedEntries(taxTree: TreeNode[], markValues: boolean, selectedValues: TaxonomyDisplayInfo[]) {
  if (markValues && taxTree) {
    const inheritedValues = selectedValues.map((d) => d.value)
    return taxTree.map((treeNode) => MarkAllInheritedEntries(treeNode, inheritedValues))
  }
  return taxTree
}

export function MarkUnselectableEntries(taxTree: TreeNode[]) {
  return taxTree.map((treeNode) => MarkUnselectableEntry(treeNode))
}

export function MarkUnselectableEntry(treeNode: TreeNode): TreeNode {
  let title: React.ReactNode
  if (treeNode.checkable) {
    title = treeNode.title
  } else if (treeNode.children) {
    title = (
      <NonSelectableSpanWithDefaultCursor aria-readonly={true}>{treeNode.title}</NonSelectableSpanWithDefaultCursor>
    )
  } else {
    title = (
      <NonSelectableSpanWithNotAllowedCursor aria-readonly={true}>
        {treeNode.title}
      </NonSelectableSpanWithNotAllowedCursor>
    )
  }

  return {
    ...treeNode,
    title,
    children: treeNode?.children?.map((x) => MarkUnselectableEntry(x)),
  }
}

export function MarkAllSelectedNodes(taxTree: TreeNode[], selectedValues: TaxonomyDisplayInfo[]) {
  return taxTree.map((treeNode) => MarkSelectedNodes(treeNode, selectedValues))
}

function MarkSelectedNodes(treeNode: TreeNode, selectedValues: TaxonomyDisplayInfo[]): TreeNode {
  let title = treeNode.title

  if (selectedValues.some((selectedNode) => selectedNode.value === treeNode.value)) {
    if (treeNode.selectable) {
      title = (
        <>
          {treeNode.title}
          <Icon component={Light.CircleXmark} style={{ marginLeft: "1ex", color: "red" }} />
        </>
      )
    } else if (treeNode.children) {
      title = (
        <NonSelectableSpanWithDefaultCursorAndNoBackground aria-readonly={true}>
          {treeNode.titleString}
        </NonSelectableSpanWithDefaultCursorAndNoBackground>
      )
    } else {
      title = (
        <NonSelectableSpanWithNotAllowedCursorAndNoBackground aria-readonly={true}>
          {treeNode.titleString}
        </NonSelectableSpanWithNotAllowedCursorAndNoBackground>
      )
    }
  }

  return {
    ...treeNode,
    title,
    children: treeNode.children?.map((x) => MarkSelectedNodes(x, selectedValues)),
  }
}

export function MarkAllInheritedEntries(treeNode: TreeNode, inheritedValues: string[]): TreeNode {
  if (
    inheritedValues.some((selectedValue) => {
      return selectedValue === treeNode.value
    })
  ) {
    return {
      ...treeNode,
      title: (
        <span>
          {treeNode.title}
          <InheritanceTag>
            <Translate>{keys.label.properties.inherited}</Translate>
          </InheritanceTag>
        </span>
      ),
      children: treeNode?.children?.map((x) => MarkAllInheritedEntries(x, inheritedValues)),
    }
  }

  return {
    ...treeNode,
    children: treeNode?.children?.map((x) => MarkAllInheritedEntries(x, inheritedValues)),
  }
}

const NonSelectableSpanWithNotAllowedCursor = styled.span`
  background: ${({ theme }) => theme.white};
  cursor: not-allowed;
  color: ${({ theme }) => theme.disabledColor};
`

const NonSelectableSpanWithDefaultCursor = styled.span`
  background: ${({ theme }) => theme.white};
  cursor: default;
  color: ${({ theme }) => theme.disabledColor};
`

const InheritanceTag = styled(Tag)`
  color: ${({ theme }) => theme.white};
  background: ${({ theme }) => theme.regularBlue};
  margin-left: 1ch;
`

const NonSelectableSpanWithNotAllowedCursorAndNoBackground = styled.span`
  cursor: not-allowed;
  color: ${({ theme }) => theme.disabledColor};
`
const NonSelectableSpanWithDefaultCursorAndNoBackground = styled.span`
  cursor: default;
  color: ${({ theme }) => theme.disabledColor};
`
