import * as React from "react"
import { SVGProps } from "react"

const SvgPaste = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1em" height="1em" {...props}>
    <path d="M160 32c11.6 0 21.3 8.2 23.5 19.2 1.5 7.4 8.1 12.8 15.7 12.8h8.8c8.8 0 16 7.2 16 16v16H96V80c0-8.8 7.2-16 16-16h8.8c7.6 0 14.2-5.4 15.7-12.8 2.2-11 11.9-19.2 23.5-19.2zM64 64h2.7C65 69 64 74.4 64 80v16c0 17.7 14.3 32 32 32h128c17.7 0 32-14.3 32-32V80c0-5.6-1-11-2.7-16h2.7c17.7 0 32 14.3 32 32h32c0-35.3-28.7-64-64-64h-45.4c-9-18.9-28.3-32-50.6-32s-41.6 13.1-50.6 32H64C28.7 32 0 60.7 0 96v288c0 35.3 28.7 64 64 64h128v-32H64c-17.7 0-32-14.3-32-32V96c0-17.7 14.3-32 32-32zm224 416c-17.7 0-32-14.3-32-32V192c0-17.7 14.3-32 32-32h96v56c0 22.1 17.9 40 40 40h56v192c0 17.7-14.3 32-32 32H288zm128-314.7 58.7 58.7H424c-4.4 0-8-3.6-8-8v-50.7zM448 512c35.3 0 64-28.7 64-64V235.9c0-12.7-5.1-24.9-14.1-33.9L438 142.1c-9-9-21.2-14.1-33.9-14.1H288c-35.3 0-64 28.7-64 64v256c0 35.3 28.7 64 64 64h160z" />
  </svg>
)

export default SvgPaste
