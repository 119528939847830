import { isST4NodeWithContent } from "../graphql/types"
import type { NodeFilterFunction } from "../ast/types"
import type { Option, PreviewContentModel, ReadyPreviewContentModel } from "./types"
import type { Node } from "unist"
import { EmptyPreviewContentModel } from "./types"
import { createContext, useContext, useMemo, useRef } from "react"
import { importXAST } from "../ast/importXAST"
import { mapFrom } from "../utilities"
import { VariableTable, transformVariables } from "@st4/content-tools"
import { useContent } from "../graphql/applicationQueries"
import { usePreviewParameterContext } from "../components/PreviewParameterContext"
import { useNodeSelection } from "../components/NodeSelectionContext"
import { isTypename, notEmpty } from "@st4/graphql"
import { St4NodeWithContentFragment } from "../graphql/applicationQueries/query.hooks"
import type { TreeNode } from "./nodeContext"
import { NodeDisplay } from "../components/PreviewConfig"
import { getNodeMappingRule } from "../mapping/mappingUtilities"

export function useContentModelFromQueryResult(
  rootNodeId: string,
  languageId: string,
  result: ReturnType<typeof useContent>,
  getNodeDisplay?: (treeNodeId: string) => NodeDisplay,
) {
  const xastById = useRef(new Map<string, Node>())
  const variableTableById = useRef(new Map<string, VariableTable>())

  const previewContentModel = useMemo(() => {
    const model = getContentModel(
      rootNodeId || "",
      languageId,
      result,
      xastById.current,
      variableTableById.current,
      getNodeDisplay,
    )
    return model
  }, [rootNodeId, result, languageId, getNodeDisplay])
  return previewContentModel
}

export function getContentModel(
  root: string,
  languageId: string,
  result: ReturnType<typeof useContent>,
  xastById: Map<string, Node>,
  variableTableById: Map<string, VariableTable>,
  getNodeDisplay?: (treeNodeId: string) => NodeDisplay,
): PreviewContentModel {
  // Note! Results with errors can still contain valid data - therefore continue.
  if (result.error) {
    console.error(`Error in GraphQL content response:`, result.error)
  }

  if (!result.data || !result.data.contentOutline)
    return result.loading ? { state: "loading" } : EmptyPreviewContentModel

  const languageXmlValues = new Map<string, string>(
    result.data.configuration.languageXmlValues.map(({ label, value }) => [label, value]),
  )

  // Key is TreeNodeId, Value is Map with Key: NodeId value: Comments for node and included fragments
  const importedNodes = new Set<{ id: string; content: { historyNumber: number } }>()
  const treeData = computeTreeNodeData(root, result.data.contentOutline, getNodeDisplay)
  const importSt4Node = importSingleContentNode.bind(
    null,
    xastById,
    variableTableById,
    importedNodes,
    treeData.treeNodesByNodeId,
  )

  // Ready State!
  return {
    state: "ready",
    languageId,
    commentModel: result.data.configuration.commentModel || { availableStates: [], availableTypes: [] },
    contentPageInfo: result.data.contentOutline.pageInfo,
    user: result.data.me,
    configuration: result.data.configuration,
    languageXmlValues,
    xastById,
    variableTableById,
    importSt4Node,
    contentOutlineTree: result.data.contentOutline,
    ...treeData,
  }
}

// These classes already contain the information of all their children and therefore their children should not be shown
const allChildrenIncluded = new Set(["TextModuleGroup", "GraficGroup", "Resource", "TextModule", "TextModule2"])

/**
 * Calculate weather a child should be Included in the rendered Tree. If the content API of a node already has the information
 * in it, like fragments in TextContent, then the fragment must not be rendered again to avoid showing duplicate content
 */
function shouldUseChild(child: TreeNode, parent: TreeNode) {
  const parentHierarchy = parent.node.nodeClass.classHierarchy ?? []
  const childHierarchy = child.node.nodeClass.classHierarchy ?? []
  const parentHierarchySet = new Set(parentHierarchy)
  const childHierarchySet = new Set(childHierarchy)
  if (parentHierarchy.some((className) => allChildrenIncluded.has(className))) return false
  if (parentHierarchySet.has("TextNode")) {
    //Gruppenknoten und Dokumentgruppen unterhalb von Textknoten werden angezeigt
    if (childHierarchySet.has("TextGroup") || childHierarchySet.has("DocumentResourceGroup")) return true
    return (
      childHierarchySet.has("TextNode") &&
      !(childHierarchySet.has("TextModule") || childHierarchySet.has("TextModule2"))
    )
  }
  if (childHierarchySet.has("Document") || childHierarchySet.has("Filter")) return false
  return true
}

export function computeTreeNodeData(
  root: string,
  tree: { nodes?: TreeNode[] },
  getNodeDisplay?: (treeNodeId: string) => NodeDisplay,
) {
  const treeNodesById = mapFrom(tree.nodes ?? [], (n) => n.id)

  const parentById = new Map<string, TreeNode>()
  const previousNodeById = new Map<string, TreeNode>()
  const validChildrenById = new Map<string, string[]>()
  const treeNodesByNodeId = new Map<string, TreeNode>()
  const treeNodes: TreeNode[] = []
  let work = [root]

  while (work.length > 0) {
    const next = work.pop()
    if (!next) break
    const nextNode = treeNodesById.get(next)
    if (!nextNode) break

    if (treeNodes.length > 0) {
      const prev = treeNodes[treeNodes.length - 1]
      previousNodeById.set(next, prev)
    }
    treeNodesByNodeId.set(nextNode.node.id, nextNode)
    treeNodes.push(nextNode)

    // When Tree paging is used nodes might have node IDs as children that are not in the "nodes" field of the tree
    // If request uses "children-first" mode then this should only happen for the last element.
    // Don't process these children that are not in "nodes"

    if (nextNode.children.length) {
      nextNode.children.forEach((child) => {
        parentById.set(child, nextNode)
      })

      const [validChildren, unusedChildnodeIds] = getChildrenToDisplay(nextNode, getNodeDisplay)

      // add the current TreeNode as TreeNodeByNodeId for children which are rendered within this node
      unusedChildnodeIds.forEach((unusedChild) => treeNodesByNodeId.set(unusedChild, nextNode))
      validChildrenById.set(nextNode.id, Array.from(validChildren))
      work = work.concat([...validChildren].reverse())
    }
  }

  /**
   * Returns two sets of children ids. the first are all treenodes which should be rendered, the second those that are
   * not rendered.
   * @param nextNode
   * @param getNodeDisplay
   * @returns A tuple with two sets. The first contains all Iids of child nodes that should be rendered, the second set
   * those that shouldn't be displayed.
   */
  function getChildrenToDisplay(nextNode: TreeNode, getNodeDisplay?: (treeNodeId: string) => NodeDisplay) {
    const mappingRule = getNodeMappingRule(nextNode.node, "FULL")
    const isChildFilteredNode = typeof mappingRule === "object" && !!mappingRule?.filteredChildren

    if (nextNode.children.length === 0) {
      return [new Set<string>(), new Set<string>()]
    }

    if (isChildFilteredNode) {
      const firstIncludedChild = nextNode.children.find((c) => getNodeDisplay?.(c).visible)
      const allChildren = new Set(nextNode.children)
      // fallback to first element will always work because we checked for no-children case before
      const childToDisplay = firstIncludedChild ?? nextNode.children[0] // take the matching or the first child
      allChildren.delete(childToDisplay)
      return [new Set([childToDisplay]), allChildren] as const
    }

    const [validChildren, unusedChildnodeIds] = nextNode.children.reduce(
      ([validChildren, unusedChildnodeIds], child) => {
        const childTreeNode = treeNodesById.get(child)
        if (!childTreeNode) return [validChildren, unusedChildnodeIds]
        if (shouldUseChild(childTreeNode, nextNode)) validChildren.add(child)
        else unusedChildnodeIds.add(childTreeNode.node.id)
        return [validChildren, unusedChildnodeIds]
      },
      [new Set<string>(), new Set<string>()],
    )

    return [validChildren, unusedChildnodeIds] as const
  }

  return {
    treeNodes,
    treeNodesById,
    parentById,
    previousNodeById,
    validChildrenById,
    treeNodesByNodeId,
  }
}

function importSingleContentNode(
  xastById: Map<string, Node>,
  variableTableById: Map<string, VariableTable>,
  importedNodes: Set<{ id: string; content: { historyNumber: number } }>,
  treeNodesByNodeId: Map<string, TreeNode>,
  node: St4NodeWithContentFragment,
  nodeFilter: NodeFilterFunction,
) {
  if (isST4NodeWithContent("TextContent", "TextGroupContent")(node)) {
    // Even if Xast for a node is already imported it must be imported again because its content might have changed
    if (node.content && treeNodesByNodeId.has(node.id)) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- Checked before
      importXAST(node, xastById, nodeFilter, treeNodesByNodeId.get(node.id)!)
      importedNodes.add(node)
    }

    const fragmentsAndParts = [...(node.content.fragments ?? []), ...(node.content.parts ?? [])]
    const fragmentVariableTables =
      fragmentsAndParts
        .map((f) => f.contentNode.content)
        ?.filter(isTypename("TextContent"))
        .flatMap((c) => c?.variableTables?.map((table) => table?.node) ?? [])
        .filter(isTypename("ST4Node")) ?? []

    const nodeVariables =
      node.content.variableTables
        ?.flatMap((ref) => ref?.node)
        .filter(notEmpty)
        .filter(isTypename("ST4Node")) ?? []

    const potentialVariableTables = [...fragmentVariableTables, ...nodeVariables].filter(
      isST4NodeWithContent("VariableTableContent"),
    )

    importVariableTableNodes(potentialVariableTables, variableTableById)
  }
  if (isST4NodeWithContent("VariableTableContent")(node)) importVariableTableNodes([node], variableTableById)
}

// Checks if variable table is already imported in Map and if not inserts parsed XML
function importVariableTableNodes(
  nodes: { id: string; content: { __typename: "VariableTableContent" } & Parameters<typeof transformVariables>[0] }[],
  variableTableById: Map<string, VariableTable>,
) {
  nodes.forEach((node) => {
    if (!variableTableById.has(node.id)) {
      const variableTableSource = transformVariables(node.content)

      variableTableById.set(node.id, variableTableSource)
    }
  })
}

export function getTreeNodeById(model: PreviewContentModel, id: string): Option<TreeNode> {
  if (model.state === "ready") return model.treeNodesById.get(id)
}

export function getParentById(model: PreviewContentModel, id: string): Option<TreeNode> {
  if (model.state === "ready") return model.parentById.get(id)
}

export function getPreviousNode(model: PreviewContentModel, id: string): Option<TreeNode> {
  if (model.state === "ready") return model.previousNodeById.get(id)
}

export function isUnderSameParent(model: PreviewContentModel, id1: string, id2: string) {
  const parent1 = getParentById(model, id1)
  const parent2 = getParentById(model, id2)
  return parent1 && parent2 && parent1.id === parent2.id
}

export function isSiblingOf(model: PreviewContentModel, id1: string, id2: string) {
  if (isUnderSameParent(model, id1, id2)) {
    const prev = getPreviousNode(model, id1)
    if (prev && prev.id === id2) {
      return true
    }
    const prev2 = getPreviousNode(model, id2)
    if (prev2 && prev2.id === id1) {
      return true
    }
  } else return false
}

export const PreviewContentModelContext = createContext<PreviewContentModel>(EmptyPreviewContentModel)

export function usePreviewContentModel() {
  return useContext(PreviewContentModelContext)
}

export function useSelectedTextNodeState(previewContentModel: Pick<ReadyPreviewContentModel, "treeNodes">) {
  const { singleNodeMode } = usePreviewParameterContext()
  const { selectedNode } = useNodeSelection()

  const memo = useMemo(() => {
    let nodes: TreeNode[] = []

    if (!singleNodeMode) nodes = previewContentModel.treeNodes
    else if (selectedNode) nodes = [selectedNode]
    else if (previewContentModel.treeNodes[0]) nodes = [previewContentModel.treeNodes[0]]

    return {
      singleNodeMode,
      nodes,
    }
  }, [singleNodeMode, previewContentModel, selectedNode])

  return memo
}
