import * as React from "react"
import { SVGProps } from "react"

const SvgInboxFull = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1em" height="1em" {...props}>
    <path d="M48 416c0 8.8 7.2 16 16 16h384c8.8 0 16-7.2 16-16v-80h-81.2l-20.9 41.9c-6.8 13.6-20.6 22.1-35.8 22.1H185.9c-15.2 0-29-8.6-35.8-22.1L129.2 336H48v80zM405.6 92.1C403.8 85 397.4 80 390 80H122c-7.3 0-13.7 5-15.5 12.1L57.5 288h76.6c15.2 0 29 8.6 35.8 22.1l20.9 41.9h130.4l20.9-41.9c6.8-13.6 20.6-22.1 35.8-22.1h76.6l-49-195.9zM0 416v-88.1c0-5.2.6-10.4 1.9-15.5l58-231.9C67 52 92.6 32 122 32h268c29.4 0 55 20 62.1 48.5l58 231.9c1.3 5.1 1.9 10.3 1.9 15.5V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64zm184-288h144c13.3 0 24 10.7 24 24s-10.7 24-24 24H184c-13.3 0-24-10.7-24-24s10.7-24 24-24zm-32 80h208c13.3 0 24 10.7 24 24s-10.7 24-24 24H152c-13.3 0-24-10.7-24-24s10.7-24 24-24z" />
  </svg>
)

export default SvgInboxFull
