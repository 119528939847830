import { makeVar, useReactiveVar } from "@apollo/client"
import { DragItem } from "./types"

export type TreeOperation = "MOVE_TREENODE" | "COPY_TREENODE"

/**
  Der DndContextData Typ ist dafür zuständing Daten und Funktionen bereitzustellen um Drag and Drop 
  sowohl in einem einzelnen Baum als auch zwischen verschiedenen Bäumen zu ermöglichen.
  Von daher sind hier keine Informationen zu möglichen DropTargets enthalten, diese sollte
  jeder Baum für sich halten.
  Die Methoden dienen nur dazu den State des Context in der ReactiveVariable zu verändern
  @property draggedItem Das Element an dem gezogen wird
  @property draggedItems Enthält das gezogene sowie alle angehakten Elemente
  @property draggedOverItem Das Element über dem wir uns aktuell befinden
*/
type DndContextData = {
  draggedItem: DragItem | null
  draggedItems: DragItem[]
  draggedOverItem: DragItem | null
  treeOperation: TreeOperation | null
  startDrag: (draggedItem: DragItem, checkedItems: string[], treeOperation: TreeOperation | null) => void
  dragEnter: (enteredItem: DragItem) => void
  endDrag: () => void
  dropDraggedItem: () => void
}

const DEFAULT_DND_INFO: DndContextData = {
  draggedItem: null,
  draggedItems: [],
  draggedOverItem: null,
  treeOperation: null,
  startDrag: startDrag,
  dragEnter: dragEnter,
  endDrag: endDrag,
  dropDraggedItem: dropDraggedItem,
}

const DragAndDropInfoVar = makeVar<DndContextData>(DEFAULT_DND_INFO)

function startDrag(draggedItem: DragItem, checkedItems: string[], treeOperation: TreeOperation | null) {
  const checkedItemSet = new Set(checkedItems)
  checkedItemSet.delete(draggedItem.id)
  DragAndDropInfoVar({
    ...DragAndDropInfoVar(),
    draggedItem: draggedItem,
    draggedItems: [draggedItem, ...Array.from(checkedItemSet).map((id) => ({ id, container: draggedItem.container }))],
    treeOperation,
  })
}

function dragEnter(enteredItem: DragItem) {
  DragAndDropInfoVar({ ...DragAndDropInfoVar(), draggedOverItem: enteredItem })
}

function endDrag() {
  DragAndDropInfoVar({ ...DragAndDropInfoVar(), draggedOverItem: null, draggedItem: null })
}

function dropDraggedItem() {
  DragAndDropInfoVar({ ...DragAndDropInfoVar(), draggedOverItem: null, draggedItem: null })
}

export function useDragAndDrop(): DndContextData {
  return useReactiveVar(DragAndDropInfoVar)
}

export function getDragAndDropInfo() {
  return DragAndDropInfoVar()
}

export * from "./types"
