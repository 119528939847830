import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

// prettier-ignore
const List_deprecated = styled(function(props) {
  return (<div className={`List ${props.className}`}>
    {props.dataArray.map(data => props.renderRow(data))}
  </div>)
})`
  padding: 8px 0;    
`

List_deprecated.propTypes = {
  className: PropTypes.string,
  dataArray: PropTypes.arrayOf(PropTypes.any),
  renderRow: PropTypes.func,
}

export default List_deprecated
