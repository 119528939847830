import {
  useStartCreateNewNodeJobInteractiveMutation,
  useContinueCreateNewNodeJobInteractiveWithUserApplyMutation,
  useContinueCreateNewNodeJobInteractiveWithUserCancelMutation,
  useStartCheckRequiredMetadataOnNodeJobInteractiveMutation,
  useContinueCheckRequiredMetadataOnNodeJobInteractiveMutation,
  useStartFinalizeCreateNewNodeJobMutation,
  useSelectionOfCreateNewNodeConfigInfoLazyQuery,
} from "./query.hooks"
import type { SelectionItemOfCreateNewNodeConfig, CreateNewNodeJobResponse } from "./query.hooks.types"
import { ApolloError } from "@apollo/client"
import { keys } from "@st4/ui-strings"
import { Tooltip } from "@schema/styled-ui"
import { useLanguagesLazyQuery } from "@st4/settings"
import { TreeSelectProps } from "antd"
import React, { useCallback, useEffect } from "react"
import { TFunction } from "i18next"
import { isTypename } from "@st4/graphql"

export function useSelectionOfCreateNewNodeConfigInfo(contextNodeId: string) {
  const [queryLang] = useLanguagesLazyQuery()
  const [query, queryState] = useSelectionOfCreateNewNodeConfigInfoLazyQuery()
  useEffect(() => {
    queryLang().then((value) => {
      query({
        variables: {
          contextNodeId: contextNodeId,
          languageId: value.data?.userConfiguration.guiLanguageId,
        },
      })
    })
  }, [contextNodeId])

  return queryState
}

function handleMutationError(error: ApolloError) {
  console.log(error.message)
}

export function useStartCreateNewNodeJobInteractive(
  onCompleted?: (data: CreateNewNodeJobResponse) => void,
  onError?: (error: ApolloError) => void,
) {
  function handleCompletedInternal(dataInternal: { startCreateNewNodeJobInteractive: CreateNewNodeJobResponse }) {
    onCompleted?.call(undefined, dataInternal.startCreateNewNodeJobInteractive)
  }

  const [exec] = useStartCreateNewNodeJobInteractiveMutation({
    onCompleted: handleCompletedInternal,
    onError: onError ?? handleMutationError,
  })

  const [query] = useLanguagesLazyQuery()

  return (contextNodeId: string, variantName: string) => {
    query().then((value) => {
      exec({
        variables: {
          languageId: value.data?.userConfiguration.contentLanguageId,
          contextNodeId: contextNodeId,
          variantName: variantName,
        },
      })
    }, console.error)
  }
}

export function useContinueCreateNewNodeJobInteractiveWithUserApply(
  onCompleted?: (data: CreateNewNodeJobResponse) => void,
  onError?: (error: ApolloError) => void,
) {
  function handleCompletedInternal(dataInternal: {
    continueCreateNewNodeJobInteractiveWithUserApply: CreateNewNodeJobResponse
  }) {
    onCompleted?.call(undefined, dataInternal.continueCreateNewNodeJobInteractiveWithUserApply)
  }
  const [exec, data] = useContinueCreateNewNodeJobInteractiveWithUserApplyMutation({
    onCompleted: handleCompletedInternal,
    onError: onError ?? handleMutationError,
    refetchQueries: ["NavigationTree"],
  })

  const callback = useCallback(
    (jobId: string, hideTitle: boolean, hideNodeClassSelection: boolean, title: string, configId: string) => {
      {
        exec({
          variables: {
            jobId: jobId,
            hideTitle: hideTitle,
            hideNodeClassSelection: hideNodeClassSelection,
            title: title,
            configId: configId,
          },
        })
      }
    },
    [exec],
  )

  return callback
}

export function useContinueCreateNewNodeJobInteractiveWithUserCancel(
  onCompleted?: (data: CreateNewNodeJobResponse) => void,
  onError?: (error: ApolloError) => void,
) {
  function handleCompletedInternal(dataInternal: {
    continueCreateNewNodeJobInteractiveWithUserCancel: CreateNewNodeJobResponse
  }) {
    onCompleted?.call(undefined, dataInternal.continueCreateNewNodeJobInteractiveWithUserCancel)
  }

  const [exec, data] = useContinueCreateNewNodeJobInteractiveWithUserCancelMutation({
    onCompleted: handleCompletedInternal,
    onError: onError ?? handleMutationError,
  })

  return (jobId: string) => {
    exec({
      variables: {
        jobId: jobId,
      },
    })
  }
}

export function useStartCheckRequiredMetadataOnNodeJobInteractive(
  onCompleted?: (data: CreateNewNodeJobResponse) => void,
  onError?: (error: ApolloError) => void,
) {
  function handleCompletedInternal(dataInternal: {
    startCheckRequiredMetadataOnNodeJobInteractive: CreateNewNodeJobResponse
  }) {
    onCompleted?.call(undefined, dataInternal.startCheckRequiredMetadataOnNodeJobInteractive)
  }

  const [exec, data] = useStartCheckRequiredMetadataOnNodeJobInteractiveMutation({
    onCompleted: handleCompletedInternal,
    onError: onError ?? handleMutationError,
    refetchQueries: ["NavigationTree"],
  })

  return (nodeId: string) => {
    exec({
      variables: {
        nodeId: nodeId,
      },
    })
  }
}

export function useContinueCheckRequiredMetadataOnNodeJobInteractive(
  onCompleted?: (data: CreateNewNodeJobResponse) => void,
  onError?: (error: ApolloError) => void,
) {
  function handleCompletedInternal(dataInternal: {
    continueCheckRequiredMetadataOnNodeJobInteractive: CreateNewNodeJobResponse
  }) {
    onCompleted?.call(undefined, dataInternal.continueCheckRequiredMetadataOnNodeJobInteractive)
  }

  const [exec, data] = useContinueCheckRequiredMetadataOnNodeJobInteractiveMutation({
    onCompleted: handleCompletedInternal,
    onError: onError ?? handleMutationError,
  })

  return (jobId: string) => {
    exec({
      variables: {
        jobId: jobId,
      },
    })
  }
}

export function useStartFinalizeCreateNewNodeJob(
  onCompleted?: (data: CreateNewNodeJobResponse) => void,
  onError?: (error: ApolloError) => void,
) {
  function handleCompletedInternal(dataInternal: { startFinalizeCreateNewNodeJob: CreateNewNodeJobResponse }) {
    onCompleted?.call(undefined, dataInternal.startFinalizeCreateNewNodeJob)
  }

  const [exec, data] = useStartFinalizeCreateNewNodeJobMutation({
    onCompleted: handleCompletedInternal,
    onError: onError ?? handleMutationError,
    refetchQueries: ["NavigationTree"],
  })

  return (createdNodeId: string, canceled: boolean) => {
    exec({
      variables: {
        createdNodeId: createdNodeId,
        canceled: canceled,
      },
    })
  }
}

export function convertSelectionItemsOfCreateNewNodeConfigForTree(
  ids: string[],
  items: SelectionItemOfCreateNewNodeConfig[],
  // This must be passed in because "convertSelectionItemsOfCreateNewNodeConfigForTree" calls
  // itself recursively based on dynamic data while hook calls must be the same across render passes.
  translate: TFunction,
): TreeSelectProps["treeData"] {
  return items
    .filter((i) => ids.includes(i.id))
    .map((i, x) => {
      const children = convertSelectionItemsOfCreateNewNodeConfigForTree(i.children, items, translate)
      const selectable = !i.isStructureItem
      const disabled = selectable && (!i.isEnabled || i.docuManagerOnly)
      return {
        title: disabled ? (
          <Tooltip
            hint={translate(
              i.docuManagerOnly
                ? keys.message.createNewNode.class.docuManagerOnly
                : keys.message.createNewNode.class.permissionDenied,
            )}
          >
            {i.label}
          </Tooltip>
        ) : (
          i.label
        ),
        value: i.id,
        disabled: disabled,
        selectable: selectable,
        isEnabled: i.isEnabled,
        children: children,
      }
    })
}

export const isNodeDialogCommandJobResponse = isTypename("CreateNewNodeCommandJobResponse")
export const isAskRequiredMetadataDialogCommandJobResponse = isTypename(
  "AskRequiredMetadataOnCreateNewNodeCommandJobResponse",
)
export const isFinishedJobResponse = isTypename("CreateNewNodeFinishedJobResponse")
export const isCanceledJobResponse = isTypename("CanceledJobResponse")
export const isFailedJobResponse = isTypename("FailedJobResponse")
