import * as React from "react"
import { SVGProps } from "react"

const SvgTaxOptScopeBase = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 14.311 14.121" {...props}>
    <path
      data-name="Pfad 4643"
      d="M6 12h12.5m0 0-6-6m6 6-6 6"
      transform="translate(-5.25 -4.939)"
      style={{
        fill: "none",
        stroke: "#004fb3",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: "1.5px",
      }}
    />
  </svg>
)

export default SvgTaxOptScopeBase
