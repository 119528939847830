/* eslint-disable */
//@ts-nocheck
import React from "react"
import { useAppTokenSettings, isAppTokenReady } from "@st4/settings"

const appName = "heap"

// Only load heap.io in the current application, ...
// ... if the user didn't decide whether tracking is allowed (doNotTrack = undefined | null) ...
// ... or if the user explicitly opted-in for tracking (doNotTrack = '0')
const canTrack = !window.navigator.doNotTrack || window.navigator.doNotTrack != "1"

export function HeapLoader() {
  const settings = useAppTokenSettings(appName)
  const appToken = isAppTokenReady(settings) && settings.appTokens.find((t) => t.name == appName)
  const canUseApp = canTrack && !!appToken && !!appToken?.token.length
  if (canUseApp) {
    ;(window.heap = window.heap || []),
      (heap.load = function (e, t) {
        ;(window.heap.appid = e), (window.heap.config = t = t || {})
        var r = document.createElement("script")
        ;(r.type = "text/javascript"), (r.async = !0), (r.src = "https://cdn.heapanalytics.com/js/heap-" + e + ".js")
        var a = document.getElementsByTagName("script")[0]
        a.parentNode.insertBefore(r, a)
        for (
          var n = function (e) {
              return function () {
                heap.push([e].concat(Array.prototype.slice.call(arguments, 0)))
              }
            },
            p = [
              "addEventProperties",
              "addUserProperties",
              "clearEventProperties",
              "identify",
              "resetIdentity",
              "removeEventProperty",
              "setEventProperties",
              "track",
              "unsetEventProperty",
            ],
            o = 0;
          o < p.length;
          o++
        )
          heap[p[o]] = n(p[o])
      })
    try {
      heap.load(appToken.token, {
        //disableTextCapture: true,
        //secureCookie: true,
      })
    } catch (error) {
      !!console && console.error("Error initializing heap.io", error)
      return <div data-testid={"heap-error"} />
    }
  }

  return <div data-testid={`heap-${canUseApp ? "on" : "off"}`} />
}

type HeapEvent =
  | { event: "useTrack"; properties: { name: string; blades: string } }
  | { event: "trackCancelled"; properties: { name: string } }
  | { event: "useEditor" }
  | { event: "runSearchQuery" }
  | { event: "useSearchRefinement" }

export function sendEvent(event: HeapEvent) {
  if (!!process?.env?.JEST_WORKER_ID) return // "JEST_WORDER_ID" is only defined when a (non-Ranorex) test is running
  if (!window.heap) !!console && console.warn("heap.io API not loaded!")
  else window.heap.track(event.event, event.properties || {}) // works because of "ts-nocheck"
}
