import { Reducer, DependencyList, Dispatch, useState, useCallback } from "react"

/** A reducer-hook which can be resetted.
 * @param reducer The reducer function executed when dispatch is called
 * @param initialState The initial state.
 * @param deps When the deps change, the state will be `initialState`
 */
export function useResettableReducer<TState, TAction>(
  reducer: Reducer<TState, TAction>,
  initialState: TState,
  deps: DependencyList,
): [TState, Dispatch<TAction>, Dispatch<React.SetStateAction<TState>>] {
  const [currentState, setState] = useState<TState>(initialState)
  const [prevDeps, setPrevDeps] = useState(deps)

  const dispatch = useCallback(
    (value: TAction) => {
      setState((currState) => reducer(currState, value))
    },
    [reducer],
  )

  if (prevDeps.length !== deps.length || !prevDeps.every((_, prevIdx) => prevDeps[prevIdx] === deps[prevIdx])) {
    //reset state to new state, on dependency-change...
    setState(initialState)
    setPrevDeps(deps)

    // Return the passed initial state on dep change. Otherwise `currentState` will be the "old" value
    // for the current render and only update afterwards, causing missmatching state and definition
    return [initialState, dispatch, setState]
  }

  return [currentState, dispatch, setState]
}
