import { ReactNode } from "react"
import { DragItem, TreeOperation, useDragAndDrop } from "../../dnd"
import { CanDropInformation, DropInsertionType, IndentedTreeNode, TreeDropInfo, TreeState } from "../types"
import React from "react"
import { calculateDropInsertionType, checkDrop } from "./dropfunctions"

export type DragItemViewProps = {
  node: IndentedTreeNode
  children: ReactNode
  treeState: TreeState
  visualTreeRootRef: React.RefObject<HTMLDivElement>

  draggable: boolean
  dndcontextdata: ReturnType<typeof useDragAndDrop>
  /** Is called on drag over to calculate all possible drop locations in the currently visible tree.
   *  Should return a set of all possible drop targets in given "visibleNodes" for "draggedItems"
   **/
  queryCanDropDraggedItemsOnVisibleNodes?: (
    draggedItems: DragItem[],
    treeOperation: TreeOperation,
    treeNodeOrder: string[]
  ) => Promise<Set<string>>
  canDropInformation: CanDropInformation
  onCanDropInformationChange: (newCanDropInformaiton: CanDropInformation) => void
  onDropInsertionTypeChange: (newDropInsertionType: DropInsertionType | null) => void
  onDragEnd: () => void
  onDragEnter: (node: IndentedTreeNode) => void
  onDrop?: (dropinfo: TreeDropInfo) => void
}

export function DragItemView({
  node,
  children,
  treeState,
  visualTreeRootRef,

  draggable,
  dndcontextdata,
  queryCanDropDraggedItemsOnVisibleNodes,
  canDropInformation,
  onCanDropInformationChange,
  onDropInsertionTypeChange,
  onDragEnd,
  onDragEnter,
  onDrop,
}: DragItemViewProps) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        flexWrap: "nowrap",
        flexGrow: 1,
      }}
      draggable={draggable}
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onDrop={handleDrop}
      onDragEnd={handleDragEnd}
    >
      {children}
    </div>
  )

  function handleDragStart() {
    dndcontextdata.startDrag(
      { id: node.node.id, container: treeState.container, label: node.node.label, icon: node.node.icon },
      treeState.checked ?? [],
      treeState.treeOperation ?? null
    )
  }

  function handleDragOver(ev: React.DragEvent<HTMLDivElement>) {
    queryAndUpdateCanDropInformation(
      node,
      dndcontextdata,
      treeState,
      canDropInformation,
      onCanDropInformationChange,
      queryCanDropDraggedItemsOnVisibleNodes
    )

    setCanDropCursorEffect(
      node,
      dndcontextdata,
      !queryCanDropDraggedItemsOnVisibleNodes,
      canDropInformation,
      ev,
      visualTreeRootRef,
      treeState
    )

    const dropPosition = calculateDropInsertionType(ev, node.node, visualTreeRootRef)
    if (dropPosition) {
      onDropInsertionTypeChange(dropPosition)
    }
  }

  function handleDragEnter() {
    dndcontextdata.dragEnter({
      id: node.node.id,
      container: treeState.container,
      label: node.node.label,
      icon: node.node.icon,
    })
    onDragEnter(node)
  }

  function handleDrop(ev: React.DragEvent<HTMLDivElement>) {
    const dropInsertionType = calculateDropInsertionType(ev, node.node, visualTreeRootRef)

    if (
      onDrop &&
      dndcontextdata.draggedItem &&
      dndcontextdata.draggedOverItem &&
      dropInsertionType &&
      dndcontextdata.draggedOverItem.container === treeState.container &&
      dndcontextdata.treeOperation
    ) {
      const dropinfo = {
        draggedItems: dndcontextdata.draggedItems,
        draggedOverItem: dndcontextdata.draggedOverItem,
        dropInsertionType,
        treeOperation: dndcontextdata.treeOperation,
      }
      onDrop(dropinfo)
      expandParent(treeState, dropinfo)
      dndcontextdata.dropDraggedItem()
      onCanDropInformationChange({ type: "empty" })
    }
  }

  function handleDragEnd() {
    onDragEnd()
    dndcontextdata.endDrag()
    onDropInsertionTypeChange(null)
    if (canDropInformation.type !== "empty") {
      onCanDropInformationChange({ type: "empty" })
    }
  }
}

function expandParent(treeState: TreeState, dropinfo: TreeDropInfo) {
  const parent = parentOf(treeState, dropinfo)

  if (parent) {
    const parentIsExpanded = treeState.isExpanded(parent)
    if (!parentIsExpanded) {
      setTimeout(() => {
        treeState.changeIsExpanded(parent, !parentIsExpanded)
      }, 0)
    }
  }
}

function parentOf(state: TreeState, info: TreeDropInfo) {
  switch (info.dropInsertionType) {
    case "INSERT_AS_DESCENDANT":
      return info.draggedOverItem.id
    case "INSERT_AS_NEXT_SIBLING":
    case "INSERT_AS_PREVIOUS_SIBLING": {
      return state.findParent(info.draggedOverItem.id)
    }
    default:
      return null
  }
}

function queryAndUpdateCanDropInformation(
  node: IndentedTreeNode,
  dndcontextdata: ReturnType<typeof useDragAndDrop>,
  treeState: TreeState,
  canDropInformation: CanDropInformation,
  setCanDropInformation: (o: CanDropInformation) => void,
  queryCanDropDraggedItemsOnVisibleNodes?: (
    draggedItems: DragItem[],
    treeOperation: TreeOperation,
    treeNodeOrder: string[]
  ) => Promise<Set<string>>
) {
  if (
    dndcontextdata.treeOperation &&
    dndcontextdata.draggedItem &&
    queryCanDropDraggedItemsOnVisibleNodes &&
    (canDropInformation.type === "empty" ||
      canDropInformation.type === "requestingUpdate" ||
      (canDropInformation.type === "loaded" && !canDropInformation.queriedItems.has(node.node.id)))
  ) {
    if (canDropInformation.type === "empty")
      setCanDropInformation({ type: "loading", queriedItems: new Set(treeState.treeNodeOrder) })
    else
      setCanDropInformation({
        type: "updating",
        allowedItems: canDropInformation.allowedItems,
        queriedItems: new Set(treeState.treeNodeOrder),
      })

    queryCanDropDraggedItemsOnVisibleNodes(
      dndcontextdata.draggedItems,
      dndcontextdata.treeOperation,
      treeState.treeNodeOrder
    ).then((items) => {
      setCanDropInformation({ type: "loaded", allowedItems: items, queriedItems: new Set(treeState.treeNodeOrder) })
    })
  }
}

function setCanDropCursorEffect(
  node: IndentedTreeNode,
  dndcontextdata: ReturnType<typeof useDragAndDrop>,
  isQueryUndefined: boolean,
  canDropInformation: CanDropInformation,
  ev: React.DragEvent<HTMLDivElement>,
  visualTreeRootRef: React.RefObject<HTMLDivElement>,
  treeState: TreeState
) {
  if (dndcontextdata.treeOperation) {
    if (isQueryUndefined) {
      // allow drop if no canDrop-Check is defined
      ev.dataTransfer.dropEffect = dndcontextdata.treeOperation === "COPY_TREENODE" ? "copy" : "move"
      ev.preventDefault()
      return
    }
    if (canDropInformation.type === "loading") {
      return
    }
    if (canDropInformation.type === "empty") {
      return
    }

    if (checkDrop(ev, node.node, visualTreeRootRef, canDropInformation, treeState.findParent)) {
      ev.dataTransfer.dropEffect = dndcontextdata.treeOperation === "COPY_TREENODE" ? "copy" : "move"
      ev.preventDefault()
    }
  }
}
