import React from "react"
import styled from "styled-components"
import type { ST4ProvidedModules } from "./types"
import { getPropertyEditor, overridePropertyEditor, registerPropertyEditor } from "./propertyEditorRegistry"
import {
  registerMapping,
  getMapping,
  registerComponent,
  overrideComponent,
  revertComponent,
  getComponent,
} from "@st4/content"

function require(module: string) {
  switch (module) {
    case "react":
      return React
    default:
      console.log(`Could not find external module ${module}`)
  }
}

const providedModules: ST4ProvidedModules = {
  React,
  styled,
  registerMapping,
  getMapping,
  registerComponent,
  overrideComponent,
  revertComponent,
  getComponent,
  getPropertyEditor: getPropertyEditor,
  registerPropertyEditor: registerPropertyEditor,
  overridePropertyEditor: overridePropertyEditor,
}

declare global {
  interface Window {
    ST4: ST4ProvidedModules
    require: any
  }
}

export function integrate() {
  console.log("Integrate provided modules for customization")
  window.ST4 = providedModules
  window.require = require
}
