import * as Types from "@st4/graphql"

import { gql } from "@apollo/client"
import * as Apollo from "@apollo/client"
const defaultOptions = {} as const
export type TreeNodeWithDynamicMetaRefsFragment = {
  __typename: "ST4TreeNode"
  dynamicMetaRefs: Array<{
    __typename: "DynamicMetaRef"
    value?: string | null
    metaRefPath: { __typename: "MetaRefPath"; displayName?: string | null; id: string }
  }>
}

export type MetaRefsQueryVariables = Types.Exact<{
  id: Types.Scalars["ID"]
  languageId: Types.Scalars["AspectSelectorId"]
}>

export type MetaRefsQuery = {
  __typename: "Query"
  node?:
    | { __typename: "CommentNode" }
    | { __typename: "ExternalSite" }
    | { __typename: "ST4Class" }
    | { __typename: "ST4Node" }
    | {
        __typename: "ST4TreeNode"
        dynamicMetaRefs: Array<{
          __typename: "DynamicMetaRef"
          value?: string | null
          metaRefPath: { __typename: "MetaRefPath"; displayName?: string | null; id: string }
        }>
      }
    | null
}

export const TreeNodeWithDynamicMetaRefsFragmentDoc = gql`
  fragment TreeNodeWithDynamicMetaRefs on ST4TreeNode {
    dynamicMetaRefs {
      value
      metaRefPath {
        displayName
        id
      }
    }
  }
`
export const MetaRefsDocument = gql`
  query MetaRefs($id: ID!, $languageId: AspectSelectorId!) {
    node(id: $id, languageId: $languageId, variant: "Preview") {
      ...TreeNodeWithDynamicMetaRefs
    }
  }
  ${TreeNodeWithDynamicMetaRefsFragmentDoc}
`

/**
 * __useMetaRefsQuery__
 *
 * To run a query within a React component, call `useMetaRefsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMetaRefsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMetaRefsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      languageId: // value for 'languageId'
 *   },
 * });
 */
export function useMetaRefsQuery(baseOptions: Apollo.QueryHookOptions<MetaRefsQuery, MetaRefsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<MetaRefsQuery, MetaRefsQueryVariables>(MetaRefsDocument, options)
}
export function useMetaRefsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MetaRefsQuery, MetaRefsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<MetaRefsQuery, MetaRefsQueryVariables>(MetaRefsDocument, options)
}
export type MetaRefsQueryHookResult = ReturnType<typeof useMetaRefsQuery>
export type MetaRefsLazyQueryHookResult = ReturnType<typeof useMetaRefsLazyQuery>
export type MetaRefsQueryResult = Apollo.QueryResult<MetaRefsQuery, MetaRefsQueryVariables>
