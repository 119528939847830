import React, { useContext } from "react"
import { Mapping } from "../mapping/types"
import { getMapping } from "../mapping/mappingRegistry"
import { XASTNode } from "../ast/types"

const MappingContext = React.createContext<Mapping<XASTNode>>(getMapping("COMPONENT"))

export function useMappingContext() {
  return useContext(MappingContext)
}

export function MappingContextProvider({ mapping, children }: React.PropsWithChildren<{ mapping: Mapping<XASTNode> }>) {
  return <MappingContext.Provider value={mapping}>{children}</MappingContext.Provider>
}
