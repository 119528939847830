import gql from "graphql-tag"
import { SafetyConfigurationFragmentDoc } from "./query.hooks"

export const GET_CONTENT = gql`
  query getContent($aspectId: AspectSelectorId!, $selectedNodes: [StPatternContextId!], $contentVariant: String!) {
    contentOutline: tree(
      languageId: $aspectId
      variant: $contentVariant
      selectedNodes: $selectedNodes
      order: CHILDREN_FIRST
    ) {
      pageInfo {
        hasNextPage
      }
      nodes {
        id
        children
        ... on ST4TreeNode {
          children
          label
          variant
        }
        node {
          ... on ST4Node {
            id
            aspectId
            nodeClass {
              classHierarchy
            }
            releaseState {
              displayName
              state
              isImplicitlyReleased
            }
          }
        }
      }
    }
    me {
      username
      displayname
      commentColor
    }
    configuration(languageId: $aspectId) {
      safetyModel {
        ...SafetyConfiguration
      }
      inlineStyleDefinitions {
        type
        displayName
        fontFamily
        fontStyle
        fontWeight
        fontSize
        textDecoration
        verticalAlign
        backgroundColor
        color
      }
      commentModel {
        availableStates {
          label
          value
        }
        availableTypes {
          label
          value
        }
      }
      languageXmlValues {
        label
        value
      }
    }
  }
  ${SafetyConfigurationFragmentDoc}
`
