import Cookies from "js-cookie"
import { makeVar } from "@apollo/client"
import { AuthenticationState, IsAuthstate, SessionData } from "./types"

function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

export function getOrCreateDeviceID() {
  const value = localStorage.getItem("SlimClientDeviceID")
  if (!value) {
    return new Promise<string>((resolve, reject) => {
      const uuid = uuidv4()
      localStorage.setItem("SlimClientDeviceID", uuid)
      resolve(uuid)
    })
  }
  return Promise.resolve(value)
}

function unsetDeviceID(value: string) {
  return localStorage.removeItem("SlimClientDeviceID")
}

function getXAuthId() {
  return localStorage.getItem("SlimClientXAuthId")
}

function setXAuthId(value: string) {
  localStorage.setItem("SlimClientXAuthId", value)
}

function unsetXAuthId() {
  localStorage.removeItem("SlimClientXAuthId")
}
function getUsername() {
  return localStorage.getItem("SlimClientUsername")
}

function setUsername(value: string) {
  localStorage.setItem("SlimClientUsername", value)
}

function unsetUsername() {
  localStorage.removeItem("SlimClientUsername")
}

function getUserDisplayname() {
  return localStorage.getItem("SlimClientUserDisplayname")
}

function setUserDisplayname(value: string) {
  localStorage.setItem("SlimClientUserDisplayname", value)
}

function getGuiAspectId() {
  return localStorage.getItem("SlimClientGuiLanguageAspectId")
}

function setGuiAspectId(guiAspectId: string) {
  localStorage.setItem("SlimClientGuiLanguageAspectId", guiAspectId)
}

function unsetGuiAspectId() {
  localStorage.removeItem("SlimClientGuiLanguageAspectId")
}
function unsetUserDisplayname() {
  localStorage.removeItem("SlimClientUserDisplayname")
}

function getUserLoginName() {
  return localStorage.getItem("SlimClientUserLoginName")
}

function setUserLoginName(value: string) {
  localStorage.setItem("SlimClientUserLoginName", value)
}

function unsetUserLoginName() {
  localStorage.removeItem("SlimClientUserLoginName")
}

export const LOGIN_STATE_VAR = makeVar(getAuthState())

function loginStateUpdate(value: AuthenticationState) {
  switch (value.state) {
    case "loggedOut":
      Cookies.remove("x-auth-id")
      clearSessionData()
      break
    case "loggedIn":
      Cookies.set("x-auth-id", value.sessionData.authId, { path: "/" })
      saveSessionData(value.sessionData)
      break
  }
  LOGIN_STATE_VAR.onNextChange(loginStateUpdate) //onNextChange gets cleaned every change, therefore add again
}
LOGIN_STATE_VAR.onNextChange(loginStateUpdate)

export async function logout() {
  const state = LOGIN_STATE_VAR()
  if (!isLoggedIn(state)) {
    LOGIN_STATE_VAR({ state: "loggedOut" })
    return
  }
  const authId = state.sessionData.authId

  try {
    await fetch("/api/logout", {
      method: "POST",
      headers: {
        ["x-auth-id"]: authId,
      },
      cache: "default",
    })
  } finally {
    LOGIN_STATE_VAR({ state: "loggedOut" })
  }
}

function useLocalStorage(key: string) {
  return [
    (val: string) => localStorage.setItem(key, val),
    () => localStorage.getItem(key),
    () => localStorage.removeItem(key),
  ] as [(val: string) => void, () => string, () => void]
}

function getAuthState(): AuthenticationState {
  const sessionData = loadSessionData()
  if (!sessionData) return { state: "loggedOut" }
  return { state: "loggedIn", sessionData }
}

function loadSessionData(): SessionData | null {
  const authId = getXAuthId()
  const username = getUsername()
  const loginName = getUserLoginName()
  const displayname = getUserDisplayname()
  const guiAspectId = getGuiAspectId()
  if (!authId || !username) return null
  return { authId, username, loginName, displayname, guiAspectId }
}

function saveSessionData(sessionData: SessionData) {
  const { authId, username, loginName, displayname, guiAspectId } = sessionData
  setXAuthId(authId)
  setUsername(username)
  loginName && setUserLoginName(loginName)
  displayname && setUserDisplayname(displayname)
  guiAspectId && setGuiAspectId(guiAspectId)
}

function clearSessionData() {
  window.sessionStorage.clear()
  window.localStorage.clear()
}

export function isLoggedIn(state: AuthenticationState): state is IsAuthstate<"loggedIn"> {
  return state.state == "loggedIn" && !!state.sessionData.authId
}

export function isLoggedOut(state: AuthenticationState): state is IsAuthstate<"loggedOut"> {
  return state.state == "loggedOut"
}
export function isLoginFailed(state: AuthenticationState): state is IsAuthstate<"loginFailed"> {
  return state.state == "loginFailed"
}

export function isChecking(state: AuthenticationState): state is IsAuthstate<"checking"> {
  return state.state == "checking"
}
