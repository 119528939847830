import { useAnnotationFocusStateContext } from "./context"

/**
 * Hook to enable scrolling to the selected comments inside of content
 * @param commentIds The keys of comments at the according content
 * @returns
 */
export function useScrollToFocusedComment(commentIds: string[]) {
  const annotationContext = useAnnotationFocusStateContext()
  const { focusedAnnotations, prefocusedAnnotations } = annotationContext

  const scrollTarget = commentIds.join(";")
  const elementProps = { "data-scroll-target": scrollTarget }

  const state = {
    isFocused: commentIds.some((id) => focusedAnnotations.includes(id)),
    isPrefocused: commentIds.some((id) => prefocusedAnnotations.includes(id)),
  }

  return [elementProps, state, annotationContext] as const
}
