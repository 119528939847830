import * as Types from "@st4/graphql"

import { gql } from "@apollo/client"
import * as Apollo from "@apollo/client"
const defaultOptions = {} as const
export type InsertFragmentCommandQueryVariables = Types.Exact<{
  selectedNode: Types.Scalars["StPatternContextId"]
  contextNode: Types.Scalars["StPatternContextId"]
  aspectId: Types.Scalars["AspectSelectorId"]
  variant: Types.Scalars["String"]
}>

export type InsertFragmentCommandQuery = {
  __typename: "Query"
  commands: Array<
    | { __typename: "AllowedCommand"; command: Types.CommandName; warnings?: Array<string> | null }
    | { __typename: "ForbiddenCommand"; command: Types.CommandName; reasons?: Array<string> | null }
  >
  tree?: {
    __typename: "ST4Tree"
    nodes: Array<{
      __typename: "ST4TreeNode"
      id: string
      node?: {
        __typename: "ST4Node"
        id: string
        metadata: Array<{ __typename: "MetaDataEntry"; systemName: string; value?: string | null }>
      } | null
    }>
  } | null
}

export const InsertFragmentCommandDocument = gql`
  query InsertFragmentCommand(
    $selectedNode: StPatternContextId!
    $contextNode: StPatternContextId!
    $aspectId: AspectSelectorId!
    $variant: String!
  ) {
    commands(
      input: [{ command: INSERT_FRAGMENT }]
      context: [{ source: [$selectedNode], target: $contextNode }]
      languageId: $aspectId
    ) {
      ... on AllowedCommand {
        command
        warnings
      }
      ... on ForbiddenCommand {
        command
        reasons
      }
    }
    tree(selectedNodes: [$selectedNode], languageId: $aspectId, variant: $variant) {
      nodes {
        id
        node {
          id
          metadata(systemNames: ["Content.FragmentElement"]) {
            systemName
            value
          }
        }
      }
    }
  }
`

/**
 * __useInsertFragmentCommandQuery__
 *
 * To run a query within a React component, call `useInsertFragmentCommandQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsertFragmentCommandQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsertFragmentCommandQuery({
 *   variables: {
 *      selectedNode: // value for 'selectedNode'
 *      contextNode: // value for 'contextNode'
 *      aspectId: // value for 'aspectId'
 *      variant: // value for 'variant'
 *   },
 * });
 */
export function useInsertFragmentCommandQuery(
  baseOptions: Apollo.QueryHookOptions<InsertFragmentCommandQuery, InsertFragmentCommandQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<InsertFragmentCommandQuery, InsertFragmentCommandQueryVariables>(
    InsertFragmentCommandDocument,
    options,
  )
}
export function useInsertFragmentCommandLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InsertFragmentCommandQuery, InsertFragmentCommandQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<InsertFragmentCommandQuery, InsertFragmentCommandQueryVariables>(
    InsertFragmentCommandDocument,
    options,
  )
}
export type InsertFragmentCommandQueryHookResult = ReturnType<typeof useInsertFragmentCommandQuery>
export type InsertFragmentCommandLazyQueryHookResult = ReturnType<typeof useInsertFragmentCommandLazyQuery>
export type InsertFragmentCommandQueryResult = Apollo.QueryResult<
  InsertFragmentCommandQuery,
  InsertFragmentCommandQueryVariables
>
