import React, { useState } from "react"
import { DatePicker as AntDatePicker } from "antd"
import { NotificationPopover } from "../notificationpopover"
import dayjs from "dayjs"

type DatePickerProps = {
  value?: dayjs.Dayjs
  onChange: (value: dayjs.Dayjs | null) => void

  readonly?: boolean
  /**
   * Allow the user to clear the date. The value will then be undefined
   */
  allowClear?: boolean
  /**
   * Allow the user to choose the time as well
   */
  showTime?: boolean
  /**
   * Apply custom formatting to the display of the current value
   */
  format?: (date: dayjs.Dayjs) => string
  /**
   * Message that explains why the current value is invalid. If specified it will be shown in a popover
   */
  errorMessage?: string
  /**
   * Override default placement of error popover to avoid popover hiding context
   */
  errorPlacement?: React.ComponentProps<typeof NotificationPopover>["placement"]
  /**
   * Icon that visualizes the type of content the input is handling.
   * If there is an error this icon will be replaced in favor of an error icon
   */
  suffixIcon?: React.ReactNode
  style?: React.CSSProperties
}

export function DatePicker({
  value,
  onChange,
  readonly,
  suffixIcon,
  format,
  showTime,
  allowClear,
  errorPlacement,
  errorMessage,
  style,
}: DatePickerProps) {
  const [isFocused, setIsFocused] = useState(false)
  // Consider empty string as "no error" because empty popover makes no sense
  const hasError = !!errorMessage

  // In case of error the suffix icon is not replaced here like it is for normal inputs
  // This is because the placement of this icon would interfere with the clear button
  // Instead only a red border is shown which should suffice for showing the user that there is an error
  return (
    <NotificationPopover
      open={hasError && isFocused}
      state={"error"}
      placement={errorPlacement ?? "bottom"}
      content={errorMessage}
    >
      <span>
        <AntDatePicker
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          status={hasError ? "error" : undefined}
          style={style}
          allowClear={allowClear}
          showTime={showTime}
          suffixIcon={suffixIcon}
          disabled={readonly}
          inputReadOnly={readonly}
          value={value}
          onChange={onChange}
          format={format}
        />
      </span>
    </NotificationPopover>
  )
}
