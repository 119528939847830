import * as React from "react"
import { SVGProps } from "react"

const SvgNote = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="1em" height="1em" {...props}>
    <path d="M384 80c8.8 0 16 7.2 16 16v224h-80c-17.7 0-32 14.3-32 32v80H64c-8.8 0-16-7.2-16-16V96c0-8.8 7.2-16 16-16h320zM64 480h229.5c17 0 33.3-6.7 45.3-18.7l90.5-90.5c12-12 18.7-28.3 18.7-45.3V96c0-35.3-28.7-64-64-64H64C28.7 32 0 60.7 0 96v320c0 35.3 28.7 64 64 64zm64-120a24 24 0 1 0-48 0 24 24 0 1 0 48 0zm-24-232a24 24 0 1 0 0 48 24 24 0 1 0 0-48zm24 128a24 24 0 1 0-48 0 24 24 0 1 0 48 0z" />
  </svg>
)

export default SvgNote
