import React, { useEffect, useRef, useState } from "react"
import { useMatch, useNavigate } from "react-router"
import { Task } from "../taskcontext/Task"
import { getTaskDefinition } from "../taskRegistry"
import { useTaskStateQuery } from "../taskcontext/query.hooks"
import { useSingletonTask } from "../taskcontext/singletontasks"
import { Result, Spin } from "antd"
import styled from "styled-components"
import { keys, Translate } from "@st4/i18n"
import { isTypename } from "@st4/graphql"
import { LOCAL_TASK_DATA, useStateStore } from "../stateStore"

/** Triggers a perstiting process whenever the task itself changes */
function usePersistedTaskPageDirectWrites(taskId?: string) {
  const stateCounter = useRef(0)
  const { write } = useStateStore(taskId)
  useEffect(() => {
    stateCounter.current = 0
  }, [taskId])

  useEffect(() => {
    let shouldReattach = true
    // attaching writer to `onNextChange` because we don't need to rerender... persisting should just happen asynchronously
    LOCAL_TASK_DATA.onNextChange(function listener(next) {
      if (next.isDirty) {
        write(stateCounter.current++)
      }
      if (shouldReattach) {
        LOCAL_TASK_DATA.onNextChange(listener)
      }
    })
    return () => {
      shouldReattach = false
    }
  }, [write])
}

export function TasksPage() {
  const match = useMatch("/task/:id")
  const taskId = match?.params.id

  usePersistedTaskPageDirectWrites(taskId)

  if (!taskId) return null

  return (
    <>
      <Tasks key={taskId} taskId={taskId} />
    </>
  )
}

const CenteringDiv = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  justify-content: center;
  align-items: center;
`

export const Tasks = React.memo(function Tasks(props: { taskId: string }) {
  const { taskId } = props
  const nav = useNavigate()

  const { loading, data } = useTaskData(taskId)
  const taskState = data?.taskState

  useEffect(() => {
    // return to home screen if task was closed.
    if (isTypename("TaskContext")(taskState) && taskState.closed) {
      nav("/", { replace: true })
    }
  }, [taskState, nav])

  if (!isTypename("TaskContext")(taskState)) {
    if (loading) {
      return (
        <CenteringDiv>
          <Spin delay={500} spinning tip={<Translate>{keys.description.task.loading}</Translate>} size="large">
            {/* Without this div, the `tip` of the spinner whouldn't be displayed. */}
            <div style={{ width: 250, height: 250 }}></div>
          </Spin>
        </CenteringDiv>
      )
    }
    return (
      <CenteringDiv>
        <Result
          status="404"
          title={<Translate>{keys.description.task.notFound}</Translate>}
          extra={
            [
              // <Button onClick={() => nav("/")} key="Dashboard">
              //   Zurück zum Dasboard
              // </Button>,
            ]
          }
        ></Result>
      </CenteringDiv>
    )
  }
  const taskName = taskState.name
  const taskDefinition = getTaskDefinition(taskName)
  if (!taskDefinition) return null
  return <Task definition={taskDefinition} instanceData={taskState} />
})

function useTaskDefinition(id?: string) {
  if (!id) return
  const definition = getTaskDefinition(id)
  return definition
}

function useTaskData(id?: string) {
  const taskDef = useTaskDefinition(id)
  const singletonTask = useSingletonTask(id)
  const gql = useTaskStateQuery({
    variables: { id: id ?? "" },
    // this could be changed to not observe the cache... updates are reflected immediately after the reducer-step
    // cache would only be used if tasks could change serverside.
    fetchPolicy: "cache-and-network",
    skip: !!taskDef?.singleton,
  })
  if (!id || id === ":id") return { loading: false, data: null }
  if (taskDef && taskDef.singleton) {
    return {
      loading: false,
      data: { taskState: singletonTask },
    }
  }
  return gql
}
