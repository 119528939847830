import type { Node } from "unist"
type Option<T> = T | null

/**
 * Create a new tree by mapping all nodes with the given function.
 *
 * This method is the same as {@link https://github.com/syntax-tree/unist-util-map|unist-util-map} but allows to add children to nodes without own children ( `children` property of the source tree is either `null` or an empty array).
 * @param tree - The xastnode to map
 * @param iteratee - A function to apply to each node in the xast
 */
export function mapWithoutEmptyChildren(
  tree: Node,
  iteratee: (node: Node, index: number, parent: Option<Node>) => Node,
) {
  return preorder(tree, 0, null)

  function preorder(node: Node, index: number, parent: Option<Node>) {
    const children = node.children
    const newNode = { ...iteratee(node, index, parent) }

    if (children && children.length) {
      //don't map children if array is empty
      newNode.children = children.map(bound)
    }

    return newNode

    function bound(child: Node, index: number) {
      return preorder(child, index, node)
    }
  }
}
