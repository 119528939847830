import { ContentBlade } from "./contentBlade"
import { TreeBlade } from "./treeBlade"
import { PropertiesBlade } from "./propertiesBlade"
import { ActionBlade } from "./actionBlade"
import { registerComponent } from "@st4/customize-host"
import { SearchResultBlade, SearchFacetsBlade, SearchOptionsBlade } from "./searchBlades"
import { ResourcePoolTreeBlade } from "./resourcePoolTreeBlade"
import { FragmentPoolTreeBlade } from "./fragmentPoolTreeBlade"
import { CrossReferencePoolBlade } from "./crossReferencePoolBlade"
import { SafetyPoolBlade } from "./safetyPoolBlade"
import { EditorBlade } from "./editorBlade"
import { VariablePoolTreeBlade } from "./variablePoolTreeBlade"
import { MetaRefPoolTreeBlade } from "./metaRefPoolTreeBlade"

export function registerBlades() {
  registerComponent("TreeBlade", TreeBlade)
  registerComponent("ContentBlade", ContentBlade)
  registerComponent("PropertiesBlade", PropertiesBlade)
  registerComponent("ActionBlade", ActionBlade)
  registerComponent("SearchResultBlade", SearchResultBlade)
  registerComponent("SearchFacetsBlade", SearchFacetsBlade)
  registerComponent("SearchOptionsBlade", SearchOptionsBlade)
  registerComponent("ResourcePoolTreeBlade", ResourcePoolTreeBlade)
  registerComponent("FragmentPoolTreeBlade", FragmentPoolTreeBlade)
  registerComponent("CrossReferencePoolBlade", CrossReferencePoolBlade)
  registerComponent("VariablePoolTreeBlade", VariablePoolTreeBlade)
  registerComponent("SafetyPoolBlade", SafetyPoolBlade)
  registerComponent("EditorBlade", EditorBlade)
  registerComponent("MetaRefPoolTreeBlade", MetaRefPoolTreeBlade)
}
