import React, { useCallback, useEffect, useState } from "react"
import { Blade, BladeDefinition } from "@st4/tasks"
import { SearchResult_SearchTerm, SearchResult_NoSearchTerm } from "./components"
import {
  SearchOptions,
  SearchOptionsSwitch,
  SearchRefinement,
  SearchResultItemIds,
  loadPageSize,
  loadSearchOptions,
  loadSearchTerm,
  savePageSize,
} from "@st4/search"
import { useLanguage } from "@st4/settings"
import { sendInsight } from "@st4/insight"
import { keys } from "@st4/ui-strings"
import { Translate } from "@st4/i18n"

export const baseTestId = "blade-searchResults"

/*
 * MU, 06.10.2023
 * SearchResultBlade doesn't use props "searchTerm", "searchOptions" and "pageSize" anymore.
 * It takes the values from the according items from the localStorage and the sessionStorage instead.
 * Props are kept to don't break any other current implementation accidentally.
 */

export type SearchBladeProps = {
  searchTerm?: string
  selectedNode?: string
  searchRefinements?: SearchRefinement[]
  searchOptions?: SearchOptions
  page?: number
  pageSize: number
}

type SearchResultBladeProps = SearchBladeProps & {
  isSearchOptionsOpened: boolean
}

type SearchResultBladeMessages =
  | {
      action: "openSearchOptions"
      payload: { open: boolean; options: SearchOptions }
    }
  | {
      action: "onSearchTermChanged"
      payload: string
    }
  | {
      action: "onPageChanged"
      payload: number
    }
  | {
      action: "onNodeSelected"
      payload: string
    }

export const SearchResultBlade: BladeDefinition<SearchResultBladeProps, SearchResultBladeMessages> =
  function SeachResultBlade(props) {
    const language = useLanguage()
    const pageSize = loadPageSize()
    const searchTerm = loadSearchTerm()
    const searchOptions = loadSearchOptions(language.languageId)

    useEffect(() => {
      if (searchTerm?.length) {
        try {
          sendInsight({ event: "runSearchQuery" })
        } catch {
          /* Ignore error from heap.io */
        }
        props.sendMessage({ action: "onSearchTermChanged", payload: searchTerm })
      }
    }, [searchTerm])

    return (
      <>
        <Blade.Toolbar>
          <SearchOptionsSwitch
            checked={props.isSearchOptionsOpened}
            onChange={(checked) =>
              props.sendMessage({ action: "openSearchOptions", payload: { open: checked, options: searchOptions } })
            }
          />
        </Blade.Toolbar>
        <Blade.Content>
          {searchTerm ? (
            <SearchResult_SearchTerm
              searchTerm={searchTerm}
              searchOptions={searchOptions}
              searchRefinements={props.searchRefinements || []}
              page={props.page || 1}
              pageSize={pageSize}
              onPageChanged={(newPage: number) => props.sendMessage({ action: "onPageChanged", payload: newPage })}
              onPageSizeChanged={(newPageSize: number) => {
                savePageSize(newPageSize)
                props.sendMessage({ action: "onPageChanged", payload: 1 })
              }}
              onSearchResultItemSelected={(ids: SearchResultItemIds) => {
                props.sendMessage({ action: "onNodeSelected", payload: ids.patternContextId })
              }}
            />
          ) : (
            <SearchResult_NoSearchTerm />
          )}
        </Blade.Content>
      </>
    )
  }

SearchResultBlade.title = <SearchResultBladeTitle />
SearchResultBlade.size = { S: 9, M: 9, L: 5 }

export function SearchResultBladeTitle() {
  /*
   * MU, 06.10.2023
   * useState - to force SearchResultBladeTitle to re-render when search term changes in sessionStorage
   * useCallback - to memoize handler of "onSearchTermChanged" event (fires when according item in sessionStorage is set)
   * useEffect - to (un)register hander of "onSearchTermChanged" event
   */
  const [searchTerm, setSearchTerm] = useState(loadSearchTerm())
  const handleEvent = useCallback(() => {
    const newSearchTerm = loadSearchTerm()
    if (searchTerm != newSearchTerm) setSearchTerm(newSearchTerm)
  }, [])
  useEffect(() => {
    addEventListener("onSearchTermChanged", handleEvent)
    return () => removeEventListener("onSearchTermChanged", handleEvent)
  }, [])
  return searchTerm?.length ? (
    <Translate searchTerm={searchTerm}>{keys.label.search.blade.searchResultBlade.titleWithSearchTerm}</Translate>
  ) : (
    <Translate>{keys.label.search.blade.searchResultBlade.title}</Translate>
  )
}
