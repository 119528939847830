import * as React from "react"
import { SVGProps } from "react"

const SvgSt4FileQuestionLight = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1em" height="1em" {...props}>
    <path d="M63 64.3C63 28.3 91.3 0 127.4 0h156.9c12.9 0 25.7 5.1 33.4 12.9l115.8 115.8c10.3 10.3 15.4 20.6 15.4 33.4v285.6c0 36-28.3 64.3-64.3 64.3H127.4c-36 0-64.3-28.3-64.3-64.3V64.3zM415.5 193H302.3c-25.7 0-48.9-20.6-48.9-48.9V30.9h-126c-18 0-30.9 15.4-30.9 30.9v385.9c0 18 15.4 30.9 30.9 30.9h257.3c18 0 30.9-15.4 30.9-30.9V193zm-2.6-38.6L297.2 38.6c-2.6-2.6-5.1-2.6-7.7-5.1v113.2c0 7.7 7.7 15.4 15.4 15.4h113.2c-2.6-5.2-5.2-7.7-5.2-7.7zm-194.1 87.4c-10.3 0-18 7.7-18 18s-7.7 18-18 18-18-7.7-18-18c-2.6-28.3 23.2-54 54-54h56.6c41.2 0 72 33.4 72 72 0 30.9-18 56.6-46.3 66.9l-36 15.4v10.3c0 10.3-7.7 18-18 18s-18-7.7-18-18v-10.3c0-15.4 10.3-28.3 23.2-33.4l36-12.9c12.9-5.1 23.2-18 23.2-33.4 0-20.6-15.4-36-36-36h-56.6v-2.6zm25.7 221.3c-12.9 0-23.2-10.3-23.2-23.2s10.3-23.2 23.2-23.2 23.2 10.3 23.2 23.2-10.3 23.2-23.2 23.2z" />
  </svg>
)

export default SvgSt4FileQuestionLight
