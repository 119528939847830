import React from "react"
import { usePreviewContentModel } from "../../contentModel"
import { usePreviewParameterContext } from "../PreviewParameterContext"
import styled, { css } from "styled-components"
import type { TreeNode } from "../../contentModel/nodeContext"
import { getNodeComponent, NodeWrapper } from "./utils"
import { isTypename } from "@st4/graphql"
import { useSingleNodeQueryWithContentImport } from "../../contentModel"

function UnmemoizedGenericNode({ treeNode, className }: { treeNode: TreeNode; className?: string }) {
  const { languageId } = usePreviewParameterContext()
  const { data, loading, error } = useSingleNodeQueryWithContentImport({
    variables: { id: treeNode.node.id, languageId: languageId, reviewVariant: "ReviewMetadata" },
    context: {
      batch: true,
    },
  })

  const contentModel = usePreviewContentModel()

  if (contentModel.state !== "ready")
    throw new Error("ContentModel must always be in ready state when nodes are rendered")

  if (error) return null

  if (loading || !data) {
    const loadingComp = getNodeComponent(treeNode.node, treeNode, "LOADING")
    if (loadingComp === null) return null
    const { component: LoadingComponent, props } = loadingComp
    return <LoadingComponent {...props} />
  }

  if (!isTypename("ST4Node")(data.node))
    throw new Error("Requesting ST4Node must always yield ST4Node and nothing else")

  const nodeComp = getNodeComponent(data.node, treeNode, "FULL")
  if (nodeComp === null) return null
  const { component: NodeComponent, props } = nodeComp
  return (
    <div className={`${className} GenericNode`} data-node-id={treeNode.node.id} data-scroll-target={treeNode.id}>
      <NodeComponent {...props} />
    </div>
  )
}

export const GenericNode = React.memo(UnmemoizedGenericNode)

export const SingleGenericNode = styled(GenericNode)<{ fullHeightDisplay?: boolean }>`
  ${(props) =>
    props.fullHeightDisplay &&
    css`
      min-height: 100%;
      height: 1px; /* Hack to allow children to grow to size of this */
    `}
  iframe.fonto {
    height: 100%;
  }

  ${NodeWrapper} {
    margin-top: 0;
  }
`
SingleGenericNode.displayName = "SimpleGenericNode"
