import React from "react"
import type { TreeItem } from "@st4/ui"
import { NodeIcon } from "@schema/styled-ui"

export type ParentNodeVisualizationProps = { contextNode: TreeItem }

export function ParentNodeVisualization(props: ParentNodeVisualizationProps) {
  return (
    <div>
      <NodeIcon
        className="tree-node-icon"
        draggable={false}
        style={{ marginLeft: "4px", marginRight: "4px" }}
        src={props.contextNode.icon}
      />
      <span>{props.contextNode.label}</span>
    </div>
  )
}
