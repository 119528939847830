import { notEmpty } from "@st4/graphql"
import { useMemo } from "react"
import { getTaskData } from "../definition/task"
import { useRunningTasksQuery } from "../taskcontext"
import { getTaskDefinition } from "../taskRegistry"

/**
 * Gets a collection of tasks of the currently logged in user.
 */
export function useMyTasks() {
  const { loading, data, error } = useRunningTasksQuery()
  const myTasks = useMemo(() => {
    return data?.me.runningTasks?.map(mapRunningTask)?.filter(notEmpty) ?? []
  }, [data])
  return [loading, myTasks, error] as const
}

function mapRunningTask(task: ReturnType<typeof useRunningTasksQuery>["data"]["me"]["runningTasks"][number]) {
  const definition = getTaskDefinition(task.name)
  if (!definition) {
    // Task is on server but we have no local definition -> ignore this task
    return
  }
  if (!definition.resumable) {
    return
  }
  const state = getTaskData(definition, task)

  const displayName = isFunction(definition.displayName) ? definition.displayName(state) : definition.displayName

  const description = isFunction(definition.description) ? definition.description(state) : definition.description

  return {
    id: task.id,
    name: task.name,
    title: displayName,
    description: description,
  }
}

function isFunction<TFunc>(fn?: TFunc): fn is Extract<TFunc, (...any: any) => any> {
  return typeof fn === "function"
}
