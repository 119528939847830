import { makeVar, useReactiveVar } from "@apollo/client"

const helpKey = makeVar<string>("")
let helpStack: string[] = []

/**
 * Gets the current help key
 */
function get(): string {
  return helpKey()
}

/**
 * Sets the given help key as current help key
 * @param newKey New help key
 */
function set(newKey: string): void {
  helpStack.push(newKey)
  helpKey(newKey)
}

/**
 * Resets the current help context to the previous help key
 */
function setPrevious(): void {
  helpStack.pop()
  const previousHelpKey = helpStack.length == 0 ? "" : helpStack[helpStack.length - 1]
  helpKey(previousHelpKey)
}

/**
 * Replaces the current help key with the given help key
 * @param replacement New help key to replace the current help key in the help stack
 */
function replace(replacement: string): void {
  if (helpStack.length === 0) set(replacement)

  helpStack[helpStack.length - 1] = replacement
  helpKey(replacement)
}

/**
 * Clears the stack of the current help context and initializes it with the given help key
 * @param initialKey Initial help key
 */
function reset(initialKey: string): void {
  helpStack = [initialKey]
  helpKey(initialKey)
}

/**
 * Hook to react on updates to the current help context
 * @returns The current help key
 */
function useHelpContext(): string {
  return useReactiveVar(helpKey)
}

export {
  get as getHelpContext,
  set as setHelpContext,
  setPrevious as setToPreviousHelpContext,
  replace as replaceHelpContext,
  reset as resetHelpContext,
  useHelpContext,
}
