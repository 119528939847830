import React from "react"
import type { PropertyEditor, StringPropertyValue } from "../types"
import { useUpdatePropertyMutation, UpdatePropertyMutationVariables } from "../../query.hooks"
import { isTypename } from "@st4/graphql"
import { StringEditor } from "~/ui/editors/stringeditor"
import { Icon, Regular } from "@st4/icons"

function getInputValue(
  propertyEditor: PropertyEditor<StringPropertyValue>,
  value: string,
): UpdatePropertyMutationVariables["input"] {
  const input = {
    variantName: propertyEditor.variantName,
    displayName: propertyEditor.displayName,
    id: propertyEditor.id,
    node: { id: propertyEditor.node.id },
    origin: propertyEditor.origin,
    value: { stringValue: value },
  }

  return input
}

export function PropertyStringEditor({
  propertyEditor,
  languageId,
  refetchProperties,
  refetchOnSave,
}: {
  propertyEditor: PropertyEditor<StringPropertyValue>
  languageId: string
  refetchProperties: () => void
  refetchOnSave?: string[]
}) {
  const { value, isReadonly } = propertyEditor
  const [updateProperty, { data, loading, error }] = useUpdatePropertyMutation({
    variables: {
      input: { ...propertyEditor, value: { stringValue: propertyEditor.value?.stringValue } },
    },
    refetchQueries: refetchOnSave,
  })

  const submitHandler = async (str: string) => {
    updateProperty({
      variables: {
        input: getInputValue(propertyEditor, str),
        languageId: languageId,
      },
      onCompleted: (_) => {
        refetchProperties()
      },
    })
  }

  let suffixIcon = null

  if (loading) {
    suffixIcon = <Icon component={Regular.SpinnerThird} />
  }

  let errorMessage: string | undefined = undefined

  if (error) {
    errorMessage = error.message
  }
  if (isTypename("Error")(data?.updateProperty)) {
    errorMessage = data?.updateProperty.message
  }

  return (
    <StringEditor
      value={value?.stringValue ?? ""}
      suffixIcon={suffixIcon}
      isReadonly={isReadonly}
      onSubmit={submitHandler}
      errorMessage={errorMessage}
    />
  )
}
