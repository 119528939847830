import * as React from "react"
import { SVGProps } from "react"

const SvgSt4StructureFragmentLight = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1em" height="1em" {...props}>
    <path d="M63.1 64.3c0-36 28.3-64.3 64.3-64.3h156.9c12.9 0 25.7 5.1 33.4 12.9l115.8 115.8c10.3 10.3 15.4 20.6 15.4 33.4v285.5c0 36-28.3 64.3-64.3 64.3H127.4c-36 0-64.3-28.3-64.3-64.3V64.3zm352.4 128.6H302.3c-25.7 0-48.9-20.6-48.9-48.9V30.9h-126c-18 0-30.9 15.4-30.9 30.9v385.9c0 18 15.4 30.9 30.9 30.9h257.2c18 0 30.9-15.4 30.9-30.9V192.9zm-2.6-38.6L297.2 38.6c-2.6-2.6-5.1-2.6-7.7-5.1v113.2c0 7.7 7.7 15.4 15.4 15.4h113.2c-2.6-5.2-5.2-7.8-5.2-7.8zm-167.2 54.1c7.7-2.6 12.9-2.6 20.6 0L366.6 247c10.3 5.1 18 15.4 18 25.7v123.5c0 12.9-7.7 23.2-18 25.7l-100.3 38.6c-7.7 2.6-12.9 2.6-20.6 0l-100.3-38.6c-10.3-5.1-18-15.4-18-25.7V272.7c0-12.9 7.7-23.2 18-25.7l100.3-38.6zm10.3 23.1h-2.6l-95.2 36 97.7 38.6 97.7-38.6-97.6-36zM153.1 401.3l90 33.4v-108l-92.6-36v105.5c0 2.5 0 2.5 2.6 5.1zm113.2 33.4 90-33.4c2.6 0 2.6-2.6 2.6-2.6V293.2l-92.6 36v105.5z" />
  </svg>
)

export default SvgSt4StructureFragmentLight
