import * as Types from "@st4/graphql"

import { gql } from "@apollo/client"
import * as Apollo from "@apollo/client"
const defaultOptions = {} as const
export type CreateNewNodeCommandJobResponseFragment = {
  __typename: "CreateNewNodeCommandJobResponse"
  jobId: string
  commandName: string
  contextNodeId: any
  hideTitle: boolean
  hideNodeClassSelection: boolean
  canCreateChildNode: boolean
  contextNode: { __typename: "ST4Node"; id: string }
}

export type AskRequiredMetadataOnCreateNewNodeCommandJobResponseFragment = {
  __typename: "AskRequiredMetadataOnCreateNewNodeCommandJobResponse"
  jobId: string
  commandName: string
  nodeId: any
  node: { __typename: "ST4Node"; id: string }
}

export type CreateNewNodeFinishedJobResponseFragment = {
  __typename: "CreateNewNodeFinishedJobResponse"
  jobId: string
  nodeId: any
  jobType?: string | null
  node: { __typename: "ST4Node"; id: string }
}

export type CanceledJobResponseFragment = { __typename: "CanceledJobResponse"; jobId: string }

export type FailedJobResponseFragment = {
  __typename: "FailedJobResponse"
  jobId: string
  title: string
  message: string
}

export type SelectionOfCreateNewNodeConfigInfoQueryVariables = Types.Exact<{
  contextNodeId: Types.Scalars["StPatternContextId"]
  languageId: Types.Scalars["AspectId"]
}>

export type SelectionOfCreateNewNodeConfigInfoQuery = {
  __typename: "Query"
  selectionOfCreateNewNodeConfigInfo: {
    __typename: "SelectionOfCreateNewNodeConfigInfo"
    rootItemIds: Array<string>
    items: Array<{
      __typename: "SelectionItemOfCreateNewNodeConfig"
      id: string
      label: string
      isStructureItem: boolean
      isEnabled: boolean
      hasRequiredMetadata: boolean
      children: Array<string>
      docuManagerOnly: boolean
      defaultTitle: string
    }>
    selectedItem?: { __typename: "SelectionItemOfCreateNewNodeConfig"; id: string } | null
  }
}

export type StartCreateNewNodeJobInteractiveMutationVariables = Types.Exact<{
  languageId: Types.Scalars["AspectId"]
  contextNodeId: Types.Scalars["StPatternContextId"]
  variantName: Types.Scalars["String"]
}>

export type StartCreateNewNodeJobInteractiveMutation = {
  __typename: "Mutation"
  startCreateNewNodeJobInteractive:
    | {
        __typename: "AskRequiredMetadataOnCreateNewNodeCommandJobResponse"
        jobId: string
        commandName: string
        nodeId: any
        node: { __typename: "ST4Node"; id: string }
      }
    | { __typename: "CanceledJobResponse"; jobId: string }
    | {
        __typename: "CreateNewNodeCommandJobResponse"
        jobId: string
        commandName: string
        contextNodeId: any
        hideTitle: boolean
        hideNodeClassSelection: boolean
        canCreateChildNode: boolean
        contextNode: { __typename: "ST4Node"; id: string }
      }
    | {
        __typename: "CreateNewNodeFinishedJobResponse"
        jobId: string
        nodeId: any
        jobType?: string | null
        node: { __typename: "ST4Node"; id: string }
      }
    | { __typename: "FailedJobResponse"; jobId: string; title: string; message: string }
}

export type ContinueCreateNewNodeJobInteractiveWithUserApplyMutationVariables = Types.Exact<{
  jobId: Types.Scalars["String"]
  hideTitle: Types.Scalars["Boolean"]
  hideNodeClassSelection: Types.Scalars["Boolean"]
  title: Types.Scalars["String"]
  configId: Types.Scalars["String"]
}>

export type ContinueCreateNewNodeJobInteractiveWithUserApplyMutation = {
  __typename: "Mutation"
  continueCreateNewNodeJobInteractiveWithUserApply:
    | {
        __typename: "AskRequiredMetadataOnCreateNewNodeCommandJobResponse"
        jobId: string
        commandName: string
        nodeId: any
        node: { __typename: "ST4Node"; id: string }
      }
    | { __typename: "CanceledJobResponse"; jobId: string }
    | {
        __typename: "CreateNewNodeCommandJobResponse"
        jobId: string
        commandName: string
        contextNodeId: any
        hideTitle: boolean
        hideNodeClassSelection: boolean
        canCreateChildNode: boolean
        contextNode: { __typename: "ST4Node"; id: string }
      }
    | {
        __typename: "CreateNewNodeFinishedJobResponse"
        jobId: string
        nodeId: any
        jobType?: string | null
        node: { __typename: "ST4Node"; id: string }
      }
    | { __typename: "FailedJobResponse"; jobId: string; title: string; message: string }
}

export type ContinueCreateNewNodeJobInteractiveWithUserCancelMutationVariables = Types.Exact<{
  jobId: Types.Scalars["String"]
}>

export type ContinueCreateNewNodeJobInteractiveWithUserCancelMutation = {
  __typename: "Mutation"
  continueCreateNewNodeJobInteractiveWithUserCancel:
    | {
        __typename: "AskRequiredMetadataOnCreateNewNodeCommandJobResponse"
        jobId: string
        commandName: string
        nodeId: any
        node: { __typename: "ST4Node"; id: string }
      }
    | { __typename: "CanceledJobResponse"; jobId: string }
    | {
        __typename: "CreateNewNodeCommandJobResponse"
        jobId: string
        commandName: string
        contextNodeId: any
        hideTitle: boolean
        hideNodeClassSelection: boolean
        canCreateChildNode: boolean
        contextNode: { __typename: "ST4Node"; id: string }
      }
    | {
        __typename: "CreateNewNodeFinishedJobResponse"
        jobId: string
        nodeId: any
        jobType?: string | null
        node: { __typename: "ST4Node"; id: string }
      }
    | { __typename: "FailedJobResponse"; jobId: string; title: string; message: string }
}

export type StartCheckRequiredMetadataOnNodeJobInteractiveMutationVariables = Types.Exact<{
  nodeId: Types.Scalars["StObjectId"]
}>

export type StartCheckRequiredMetadataOnNodeJobInteractiveMutation = {
  __typename: "Mutation"
  startCheckRequiredMetadataOnNodeJobInteractive:
    | {
        __typename: "AskRequiredMetadataOnCreateNewNodeCommandJobResponse"
        jobId: string
        commandName: string
        nodeId: any
        node: { __typename: "ST4Node"; id: string }
      }
    | { __typename: "CanceledJobResponse"; jobId: string }
    | {
        __typename: "CreateNewNodeCommandJobResponse"
        jobId: string
        commandName: string
        contextNodeId: any
        hideTitle: boolean
        hideNodeClassSelection: boolean
        canCreateChildNode: boolean
        contextNode: { __typename: "ST4Node"; id: string }
      }
    | {
        __typename: "CreateNewNodeFinishedJobResponse"
        jobId: string
        nodeId: any
        jobType?: string | null
        node: { __typename: "ST4Node"; id: string }
      }
    | { __typename: "FailedJobResponse"; jobId: string; title: string; message: string }
}

export type ContinueCheckRequiredMetadataOnNodeJobInteractiveMutationVariables = Types.Exact<{
  jobId: Types.Scalars["String"]
}>

export type ContinueCheckRequiredMetadataOnNodeJobInteractiveMutation = {
  __typename: "Mutation"
  continueCheckRequiredMetadataOnNodeJobInteractive:
    | {
        __typename: "AskRequiredMetadataOnCreateNewNodeCommandJobResponse"
        jobId: string
        commandName: string
        nodeId: any
        node: { __typename: "ST4Node"; id: string }
      }
    | { __typename: "CanceledJobResponse"; jobId: string }
    | {
        __typename: "CreateNewNodeCommandJobResponse"
        jobId: string
        commandName: string
        contextNodeId: any
        hideTitle: boolean
        hideNodeClassSelection: boolean
        canCreateChildNode: boolean
        contextNode: { __typename: "ST4Node"; id: string }
      }
    | {
        __typename: "CreateNewNodeFinishedJobResponse"
        jobId: string
        nodeId: any
        jobType?: string | null
        node: { __typename: "ST4Node"; id: string }
      }
    | { __typename: "FailedJobResponse"; jobId: string; title: string; message: string }
}

export type StartFinalizeCreateNewNodeJobMutationVariables = Types.Exact<{
  createdNodeId: Types.Scalars["StObjectId"]
  canceled: Types.Scalars["Boolean"]
}>

export type StartFinalizeCreateNewNodeJobMutation = {
  __typename: "Mutation"
  startFinalizeCreateNewNodeJob:
    | {
        __typename: "AskRequiredMetadataOnCreateNewNodeCommandJobResponse"
        jobId: string
        commandName: string
        nodeId: any
        node: { __typename: "ST4Node"; id: string }
      }
    | { __typename: "CanceledJobResponse"; jobId: string }
    | {
        __typename: "CreateNewNodeCommandJobResponse"
        jobId: string
        commandName: string
        contextNodeId: any
        hideTitle: boolean
        hideNodeClassSelection: boolean
        canCreateChildNode: boolean
        contextNode: { __typename: "ST4Node"; id: string }
      }
    | {
        __typename: "CreateNewNodeFinishedJobResponse"
        jobId: string
        nodeId: any
        jobType?: string | null
        node: { __typename: "ST4Node"; id: string }
      }
    | { __typename: "FailedJobResponse"; jobId: string; title: string; message: string }
}

export const CreateNewNodeCommandJobResponseFragmentDoc = gql`
  fragment CreateNewNodeCommandJobResponse on CreateNewNodeCommandJobResponse {
    jobId
    commandName
    contextNodeId
    contextNode {
      id
    }
    hideTitle
    hideNodeClassSelection
    canCreateChildNode
  }
`
export const AskRequiredMetadataOnCreateNewNodeCommandJobResponseFragmentDoc = gql`
  fragment AskRequiredMetadataOnCreateNewNodeCommandJobResponse on AskRequiredMetadataOnCreateNewNodeCommandJobResponse {
    jobId
    commandName
    nodeId
    node {
      id
    }
  }
`
export const CreateNewNodeFinishedJobResponseFragmentDoc = gql`
  fragment CreateNewNodeFinishedJobResponse on CreateNewNodeFinishedJobResponse {
    jobId
    nodeId
    node {
      id
    }
    jobType
  }
`
export const CanceledJobResponseFragmentDoc = gql`
  fragment CanceledJobResponse on CanceledJobResponse {
    jobId
  }
`
export const FailedJobResponseFragmentDoc = gql`
  fragment FailedJobResponse on FailedJobResponse {
    jobId
    title
    message
  }
`
export const SelectionOfCreateNewNodeConfigInfoDocument = gql`
  query SelectionOfCreateNewNodeConfigInfo($contextNodeId: StPatternContextId!, $languageId: AspectId!) {
    selectionOfCreateNewNodeConfigInfo(input: { contextNodeId: $contextNodeId, languageId: $languageId }) {
      ... on SelectionOfCreateNewNodeConfigInfo {
        rootItemIds
        items {
          id
          label
          isStructureItem
          isEnabled
          hasRequiredMetadata
          children
          docuManagerOnly
          defaultTitle
        }
        selectedItem {
          id
        }
      }
    }
  }
`

/**
 * __useSelectionOfCreateNewNodeConfigInfoQuery__
 *
 * To run a query within a React component, call `useSelectionOfCreateNewNodeConfigInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelectionOfCreateNewNodeConfigInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelectionOfCreateNewNodeConfigInfoQuery({
 *   variables: {
 *      contextNodeId: // value for 'contextNodeId'
 *      languageId: // value for 'languageId'
 *   },
 * });
 */
export function useSelectionOfCreateNewNodeConfigInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    SelectionOfCreateNewNodeConfigInfoQuery,
    SelectionOfCreateNewNodeConfigInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SelectionOfCreateNewNodeConfigInfoQuery, SelectionOfCreateNewNodeConfigInfoQueryVariables>(
    SelectionOfCreateNewNodeConfigInfoDocument,
    options,
  )
}
export function useSelectionOfCreateNewNodeConfigInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SelectionOfCreateNewNodeConfigInfoQuery,
    SelectionOfCreateNewNodeConfigInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SelectionOfCreateNewNodeConfigInfoQuery, SelectionOfCreateNewNodeConfigInfoQueryVariables>(
    SelectionOfCreateNewNodeConfigInfoDocument,
    options,
  )
}
export type SelectionOfCreateNewNodeConfigInfoQueryHookResult = ReturnType<
  typeof useSelectionOfCreateNewNodeConfigInfoQuery
>
export type SelectionOfCreateNewNodeConfigInfoLazyQueryHookResult = ReturnType<
  typeof useSelectionOfCreateNewNodeConfigInfoLazyQuery
>
export type SelectionOfCreateNewNodeConfigInfoQueryResult = Apollo.QueryResult<
  SelectionOfCreateNewNodeConfigInfoQuery,
  SelectionOfCreateNewNodeConfigInfoQueryVariables
>
export const StartCreateNewNodeJobInteractiveDocument = gql`
  mutation StartCreateNewNodeJobInteractive(
    $languageId: AspectId!
    $contextNodeId: StPatternContextId!
    $variantName: String!
  ) {
    startCreateNewNodeJobInteractive(
      input: { languageId: $languageId, contextNodeId: $contextNodeId, variantName: $variantName }
    ) {
      ...CreateNewNodeCommandJobResponse
      ...AskRequiredMetadataOnCreateNewNodeCommandJobResponse
      ...CreateNewNodeFinishedJobResponse
      ...CanceledJobResponse
      ...FailedJobResponse
    }
  }
  ${CreateNewNodeCommandJobResponseFragmentDoc}
  ${AskRequiredMetadataOnCreateNewNodeCommandJobResponseFragmentDoc}
  ${CreateNewNodeFinishedJobResponseFragmentDoc}
  ${CanceledJobResponseFragmentDoc}
  ${FailedJobResponseFragmentDoc}
`
export type StartCreateNewNodeJobInteractiveMutationFn = Apollo.MutationFunction<
  StartCreateNewNodeJobInteractiveMutation,
  StartCreateNewNodeJobInteractiveMutationVariables
>

/**
 * __useStartCreateNewNodeJobInteractiveMutation__
 *
 * To run a mutation, you first call `useStartCreateNewNodeJobInteractiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartCreateNewNodeJobInteractiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startCreateNewNodeJobInteractiveMutation, { data, loading, error }] = useStartCreateNewNodeJobInteractiveMutation({
 *   variables: {
 *      languageId: // value for 'languageId'
 *      contextNodeId: // value for 'contextNodeId'
 *      variantName: // value for 'variantName'
 *   },
 * });
 */
export function useStartCreateNewNodeJobInteractiveMutation(
  baseOptions?: Apollo.MutationHookOptions<
    StartCreateNewNodeJobInteractiveMutation,
    StartCreateNewNodeJobInteractiveMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    StartCreateNewNodeJobInteractiveMutation,
    StartCreateNewNodeJobInteractiveMutationVariables
  >(StartCreateNewNodeJobInteractiveDocument, options)
}
export type StartCreateNewNodeJobInteractiveMutationHookResult = ReturnType<
  typeof useStartCreateNewNodeJobInteractiveMutation
>
export type StartCreateNewNodeJobInteractiveMutationResult =
  Apollo.MutationResult<StartCreateNewNodeJobInteractiveMutation>
export type StartCreateNewNodeJobInteractiveMutationOptions = Apollo.BaseMutationOptions<
  StartCreateNewNodeJobInteractiveMutation,
  StartCreateNewNodeJobInteractiveMutationVariables
>
export const ContinueCreateNewNodeJobInteractiveWithUserApplyDocument = gql`
  mutation ContinueCreateNewNodeJobInteractiveWithUserApply(
    $jobId: String!
    $hideTitle: Boolean!
    $hideNodeClassSelection: Boolean!
    $title: String!
    $configId: String!
  ) {
    continueCreateNewNodeJobInteractiveWithUserApply(
      input: {
        jobId: $jobId
        hideTitle: $hideTitle
        hideNodeClassSelection: $hideNodeClassSelection
        title: $title
        configId: $configId
      }
    ) {
      ...CreateNewNodeCommandJobResponse
      ...AskRequiredMetadataOnCreateNewNodeCommandJobResponse
      ...CreateNewNodeFinishedJobResponse
      ...CanceledJobResponse
      ...FailedJobResponse
    }
  }
  ${CreateNewNodeCommandJobResponseFragmentDoc}
  ${AskRequiredMetadataOnCreateNewNodeCommandJobResponseFragmentDoc}
  ${CreateNewNodeFinishedJobResponseFragmentDoc}
  ${CanceledJobResponseFragmentDoc}
  ${FailedJobResponseFragmentDoc}
`
export type ContinueCreateNewNodeJobInteractiveWithUserApplyMutationFn = Apollo.MutationFunction<
  ContinueCreateNewNodeJobInteractiveWithUserApplyMutation,
  ContinueCreateNewNodeJobInteractiveWithUserApplyMutationVariables
>

/**
 * __useContinueCreateNewNodeJobInteractiveWithUserApplyMutation__
 *
 * To run a mutation, you first call `useContinueCreateNewNodeJobInteractiveWithUserApplyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContinueCreateNewNodeJobInteractiveWithUserApplyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [continueCreateNewNodeJobInteractiveWithUserApplyMutation, { data, loading, error }] = useContinueCreateNewNodeJobInteractiveWithUserApplyMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      hideTitle: // value for 'hideTitle'
 *      hideNodeClassSelection: // value for 'hideNodeClassSelection'
 *      title: // value for 'title'
 *      configId: // value for 'configId'
 *   },
 * });
 */
export function useContinueCreateNewNodeJobInteractiveWithUserApplyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ContinueCreateNewNodeJobInteractiveWithUserApplyMutation,
    ContinueCreateNewNodeJobInteractiveWithUserApplyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ContinueCreateNewNodeJobInteractiveWithUserApplyMutation,
    ContinueCreateNewNodeJobInteractiveWithUserApplyMutationVariables
  >(ContinueCreateNewNodeJobInteractiveWithUserApplyDocument, options)
}
export type ContinueCreateNewNodeJobInteractiveWithUserApplyMutationHookResult = ReturnType<
  typeof useContinueCreateNewNodeJobInteractiveWithUserApplyMutation
>
export type ContinueCreateNewNodeJobInteractiveWithUserApplyMutationResult =
  Apollo.MutationResult<ContinueCreateNewNodeJobInteractiveWithUserApplyMutation>
export type ContinueCreateNewNodeJobInteractiveWithUserApplyMutationOptions = Apollo.BaseMutationOptions<
  ContinueCreateNewNodeJobInteractiveWithUserApplyMutation,
  ContinueCreateNewNodeJobInteractiveWithUserApplyMutationVariables
>
export const ContinueCreateNewNodeJobInteractiveWithUserCancelDocument = gql`
  mutation ContinueCreateNewNodeJobInteractiveWithUserCancel($jobId: String!) {
    continueCreateNewNodeJobInteractiveWithUserCancel(input: { jobId: $jobId }) {
      ...CreateNewNodeCommandJobResponse
      ...AskRequiredMetadataOnCreateNewNodeCommandJobResponse
      ...CreateNewNodeFinishedJobResponse
      ...CanceledJobResponse
      ...FailedJobResponse
    }
  }
  ${CreateNewNodeCommandJobResponseFragmentDoc}
  ${AskRequiredMetadataOnCreateNewNodeCommandJobResponseFragmentDoc}
  ${CreateNewNodeFinishedJobResponseFragmentDoc}
  ${CanceledJobResponseFragmentDoc}
  ${FailedJobResponseFragmentDoc}
`
export type ContinueCreateNewNodeJobInteractiveWithUserCancelMutationFn = Apollo.MutationFunction<
  ContinueCreateNewNodeJobInteractiveWithUserCancelMutation,
  ContinueCreateNewNodeJobInteractiveWithUserCancelMutationVariables
>

/**
 * __useContinueCreateNewNodeJobInteractiveWithUserCancelMutation__
 *
 * To run a mutation, you first call `useContinueCreateNewNodeJobInteractiveWithUserCancelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContinueCreateNewNodeJobInteractiveWithUserCancelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [continueCreateNewNodeJobInteractiveWithUserCancelMutation, { data, loading, error }] = useContinueCreateNewNodeJobInteractiveWithUserCancelMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useContinueCreateNewNodeJobInteractiveWithUserCancelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ContinueCreateNewNodeJobInteractiveWithUserCancelMutation,
    ContinueCreateNewNodeJobInteractiveWithUserCancelMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ContinueCreateNewNodeJobInteractiveWithUserCancelMutation,
    ContinueCreateNewNodeJobInteractiveWithUserCancelMutationVariables
  >(ContinueCreateNewNodeJobInteractiveWithUserCancelDocument, options)
}
export type ContinueCreateNewNodeJobInteractiveWithUserCancelMutationHookResult = ReturnType<
  typeof useContinueCreateNewNodeJobInteractiveWithUserCancelMutation
>
export type ContinueCreateNewNodeJobInteractiveWithUserCancelMutationResult =
  Apollo.MutationResult<ContinueCreateNewNodeJobInteractiveWithUserCancelMutation>
export type ContinueCreateNewNodeJobInteractiveWithUserCancelMutationOptions = Apollo.BaseMutationOptions<
  ContinueCreateNewNodeJobInteractiveWithUserCancelMutation,
  ContinueCreateNewNodeJobInteractiveWithUserCancelMutationVariables
>
export const StartCheckRequiredMetadataOnNodeJobInteractiveDocument = gql`
  mutation StartCheckRequiredMetadataOnNodeJobInteractive($nodeId: StObjectId!) {
    startCheckRequiredMetadataOnNodeJobInteractive(input: { nodeId: $nodeId }) {
      ...CreateNewNodeCommandJobResponse
      ...AskRequiredMetadataOnCreateNewNodeCommandJobResponse
      ...CreateNewNodeFinishedJobResponse
      ...CanceledJobResponse
      ...FailedJobResponse
    }
  }
  ${CreateNewNodeCommandJobResponseFragmentDoc}
  ${AskRequiredMetadataOnCreateNewNodeCommandJobResponseFragmentDoc}
  ${CreateNewNodeFinishedJobResponseFragmentDoc}
  ${CanceledJobResponseFragmentDoc}
  ${FailedJobResponseFragmentDoc}
`
export type StartCheckRequiredMetadataOnNodeJobInteractiveMutationFn = Apollo.MutationFunction<
  StartCheckRequiredMetadataOnNodeJobInteractiveMutation,
  StartCheckRequiredMetadataOnNodeJobInteractiveMutationVariables
>

/**
 * __useStartCheckRequiredMetadataOnNodeJobInteractiveMutation__
 *
 * To run a mutation, you first call `useStartCheckRequiredMetadataOnNodeJobInteractiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartCheckRequiredMetadataOnNodeJobInteractiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startCheckRequiredMetadataOnNodeJobInteractiveMutation, { data, loading, error }] = useStartCheckRequiredMetadataOnNodeJobInteractiveMutation({
 *   variables: {
 *      nodeId: // value for 'nodeId'
 *   },
 * });
 */
export function useStartCheckRequiredMetadataOnNodeJobInteractiveMutation(
  baseOptions?: Apollo.MutationHookOptions<
    StartCheckRequiredMetadataOnNodeJobInteractiveMutation,
    StartCheckRequiredMetadataOnNodeJobInteractiveMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    StartCheckRequiredMetadataOnNodeJobInteractiveMutation,
    StartCheckRequiredMetadataOnNodeJobInteractiveMutationVariables
  >(StartCheckRequiredMetadataOnNodeJobInteractiveDocument, options)
}
export type StartCheckRequiredMetadataOnNodeJobInteractiveMutationHookResult = ReturnType<
  typeof useStartCheckRequiredMetadataOnNodeJobInteractiveMutation
>
export type StartCheckRequiredMetadataOnNodeJobInteractiveMutationResult =
  Apollo.MutationResult<StartCheckRequiredMetadataOnNodeJobInteractiveMutation>
export type StartCheckRequiredMetadataOnNodeJobInteractiveMutationOptions = Apollo.BaseMutationOptions<
  StartCheckRequiredMetadataOnNodeJobInteractiveMutation,
  StartCheckRequiredMetadataOnNodeJobInteractiveMutationVariables
>
export const ContinueCheckRequiredMetadataOnNodeJobInteractiveDocument = gql`
  mutation ContinueCheckRequiredMetadataOnNodeJobInteractive($jobId: String!) {
    continueCheckRequiredMetadataOnNodeJobInteractive(input: { jobId: $jobId }) {
      ...CreateNewNodeCommandJobResponse
      ...AskRequiredMetadataOnCreateNewNodeCommandJobResponse
      ...CreateNewNodeFinishedJobResponse
      ...CanceledJobResponse
      ...FailedJobResponse
    }
  }
  ${CreateNewNodeCommandJobResponseFragmentDoc}
  ${AskRequiredMetadataOnCreateNewNodeCommandJobResponseFragmentDoc}
  ${CreateNewNodeFinishedJobResponseFragmentDoc}
  ${CanceledJobResponseFragmentDoc}
  ${FailedJobResponseFragmentDoc}
`
export type ContinueCheckRequiredMetadataOnNodeJobInteractiveMutationFn = Apollo.MutationFunction<
  ContinueCheckRequiredMetadataOnNodeJobInteractiveMutation,
  ContinueCheckRequiredMetadataOnNodeJobInteractiveMutationVariables
>

/**
 * __useContinueCheckRequiredMetadataOnNodeJobInteractiveMutation__
 *
 * To run a mutation, you first call `useContinueCheckRequiredMetadataOnNodeJobInteractiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContinueCheckRequiredMetadataOnNodeJobInteractiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [continueCheckRequiredMetadataOnNodeJobInteractiveMutation, { data, loading, error }] = useContinueCheckRequiredMetadataOnNodeJobInteractiveMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useContinueCheckRequiredMetadataOnNodeJobInteractiveMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ContinueCheckRequiredMetadataOnNodeJobInteractiveMutation,
    ContinueCheckRequiredMetadataOnNodeJobInteractiveMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ContinueCheckRequiredMetadataOnNodeJobInteractiveMutation,
    ContinueCheckRequiredMetadataOnNodeJobInteractiveMutationVariables
  >(ContinueCheckRequiredMetadataOnNodeJobInteractiveDocument, options)
}
export type ContinueCheckRequiredMetadataOnNodeJobInteractiveMutationHookResult = ReturnType<
  typeof useContinueCheckRequiredMetadataOnNodeJobInteractiveMutation
>
export type ContinueCheckRequiredMetadataOnNodeJobInteractiveMutationResult =
  Apollo.MutationResult<ContinueCheckRequiredMetadataOnNodeJobInteractiveMutation>
export type ContinueCheckRequiredMetadataOnNodeJobInteractiveMutationOptions = Apollo.BaseMutationOptions<
  ContinueCheckRequiredMetadataOnNodeJobInteractiveMutation,
  ContinueCheckRequiredMetadataOnNodeJobInteractiveMutationVariables
>
export const StartFinalizeCreateNewNodeJobDocument = gql`
  mutation StartFinalizeCreateNewNodeJob($createdNodeId: StObjectId!, $canceled: Boolean!) {
    startFinalizeCreateNewNodeJob(input: { createdNodeId: $createdNodeId, canceled: $canceled }) {
      ...CreateNewNodeCommandJobResponse
      ...AskRequiredMetadataOnCreateNewNodeCommandJobResponse
      ...CreateNewNodeFinishedJobResponse
      ...CanceledJobResponse
      ...FailedJobResponse
    }
  }
  ${CreateNewNodeCommandJobResponseFragmentDoc}
  ${AskRequiredMetadataOnCreateNewNodeCommandJobResponseFragmentDoc}
  ${CreateNewNodeFinishedJobResponseFragmentDoc}
  ${CanceledJobResponseFragmentDoc}
  ${FailedJobResponseFragmentDoc}
`
export type StartFinalizeCreateNewNodeJobMutationFn = Apollo.MutationFunction<
  StartFinalizeCreateNewNodeJobMutation,
  StartFinalizeCreateNewNodeJobMutationVariables
>

/**
 * __useStartFinalizeCreateNewNodeJobMutation__
 *
 * To run a mutation, you first call `useStartFinalizeCreateNewNodeJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartFinalizeCreateNewNodeJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startFinalizeCreateNewNodeJobMutation, { data, loading, error }] = useStartFinalizeCreateNewNodeJobMutation({
 *   variables: {
 *      createdNodeId: // value for 'createdNodeId'
 *      canceled: // value for 'canceled'
 *   },
 * });
 */
export function useStartFinalizeCreateNewNodeJobMutation(
  baseOptions?: Apollo.MutationHookOptions<
    StartFinalizeCreateNewNodeJobMutation,
    StartFinalizeCreateNewNodeJobMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<StartFinalizeCreateNewNodeJobMutation, StartFinalizeCreateNewNodeJobMutationVariables>(
    StartFinalizeCreateNewNodeJobDocument,
    options,
  )
}
export type StartFinalizeCreateNewNodeJobMutationHookResult = ReturnType<
  typeof useStartFinalizeCreateNewNodeJobMutation
>
export type StartFinalizeCreateNewNodeJobMutationResult = Apollo.MutationResult<StartFinalizeCreateNewNodeJobMutation>
export type StartFinalizeCreateNewNodeJobMutationOptions = Apollo.BaseMutationOptions<
  StartFinalizeCreateNewNodeJobMutation,
  StartFinalizeCreateNewNodeJobMutationVariables
>
