import styled from "styled-components"

const StickyToolbar_deprecated = styled.nav`
  position: sticky;
  top: 0;
  z-index: 1;
  background: #009ee3;
  display: flex;
  align-items: center;
`

export default StickyToolbar_deprecated
