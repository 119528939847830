import React from "react"
import styled, { DefaultTheme, useTheme } from "styled-components"
import UnstyledInfoIcon from "@schema/react-ionicons/components/IosInformationCircle"
import UnstyledWarningIcon from "@schema/react-ionicons/components/IosWarning"
import UnstyledErrorIcon from "@schema/react-ionicons/components/IosCloseCircle"
import UnstyledQuestionIcon from "@schema/react-ionicons/components/IosHelpCircle"
import { useTranslation } from "react-i18next"
import { keys } from "@st4/ui-strings"

type colorSelectorProps = {
  theme: DefaultTheme
  isError: boolean
  isWarning: boolean
  isQuestion: boolean
}

const selectCorrectColor = ({ theme, isError, isWarning, isQuestion }: colorSelectorProps) =>
  isError
    ? theme.notificationColors.error
    : isWarning
    ? theme.notificationColors.warning
    : isQuestion
    ? theme.notificationColors.question
    : theme.notificationColors.information

const AlertContainer = styled.div<{ theme: { shadows: { "+1": string } } }>`
  box-shadow: ${({ theme }) => theme.shadows["+1"]};
  background: ${({ theme }) => theme.greys["100"]};
  margin: 15px 0;
  display: flex;
  border-radius: 5px;
`

const AlertMessage = styled.div<{ isError: boolean; isWarning: boolean; isInfo: boolean; isQuestion: boolean }>`
  padding: 20px;
  border: 2px solid ${(props) => selectCorrectColor(props).normal};
  border-left: 0;
  width: 100%;
  border-radius: 0 5px 5px 0;
`

const AlertIconContainer = styled.div<{ isError: boolean; isWarning: boolean; isInfo: boolean; isQuestion: boolean }>`
  border: 2px solid ${(props) => selectCorrectColor(props).normal};
  border-right: 0;
  background: ${(props) => selectCorrectColor(props).normal};
  display: flex;
  border-radius: 5px 0 0 5px;
  justify-content: center;
  align-items: center;
  padding: 0 5px;
`

type AlertProps = {
  children: React.ReactNode
  isInfo?: boolean
  isWarning?: boolean
  isError?: boolean
  isQuestion?: boolean
}

// Zeigt eine Nachricht an. Es muss die "schwere" mit angegeben werden!
export function Alert({
  children,
  isInfo = false,
  isWarning = false,
  isError = false,
  isQuestion = false,
}: AlertProps) {
  const Icon = isError
    ? UnstyledErrorIcon
    : isWarning
    ? UnstyledWarningIcon
    : isQuestion
    ? UnstyledQuestionIcon
    : UnstyledInfoIcon
  const theme = useTheme()
  const { t } = useTranslation()
  return (
    <AlertContainer>
      <AlertIconContainer
        isInfo={isInfo}
        isWarning={isWarning}
        isError={isError}
        isQuestion={isQuestion}
        title={
          isError
            ? t(keys.label.warning.error)
            : isWarning
            ? t(keys.label.warning.warning)
            : isQuestion
            ? t(keys.label.warning.question)
            : t(keys.label.warning.information)
        }
      >
        <Icon size="35px" color={theme.greys["100"]} />
      </AlertIconContainer>
      <AlertMessage isInfo={isInfo} isWarning={isWarning} isError={isError} isQuestion={isQuestion}>
        {children}
      </AlertMessage>
    </AlertContainer>
  )
}
