import { useReactiveVar } from "@apollo/client"
import { SETTINGS_VAR } from "./settingsState"

// Provides language code and AspectSelectorId that should be used in queries to the server
export function useLanguage() {
  const data = useReactiveVar(SETTINGS_VAR)

  // Data should have been requested at the root of the app.
  // It must be immediately available and must not be in a loading state
  if (!data) throw new Error("No language context found. Did you forget <LanguageProvider>?")
  return {
    language: data.userConfiguration.contentLanguageCode,
    languageId: data.userConfiguration.aspectSelectorId,
    specificCulture: data.userConfiguration.contentLanguageSpecificCulture,
    guiLanguageCode: data.userConfiguration.guiLanguageCode,
  }
}

export function useAspectId() {
  const language = useLanguage()
  return language.languageId
}
