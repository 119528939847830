import * as React from "react"
import { SVGProps } from "react"

const SvgSt4ProjectsFolderSolid = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1em" height="1em" {...props}>
    <path d="M294.4 184.3h-76.8c-2.6 0-5.1 2.6-5.1 5.1v20.5H297v-23c2.5-2.6 0-2.6-2.6-2.6zm153.6-87H299.5c-17.9 0-33.3-7.7-46.1-20.5l-25.6-25.6c-12.8-12.8-28.2-20.5-46.1-20.5H64c-35.8 0-64 30.7-64 66.6v320c0 35.8 28.2 64 64 64h384c35.8 0 64-28.2 64-64v-256c0-35.9-28.2-64-64-64zm-56.3 281.6c0 17.9-15.4 33.3-33.3 33.3H153.6c-17.9 0-33.3-15.4-33.3-33.3v-69.1h102.4v17.9c0 10.2 7.7 17.9 17.9 17.9h33.3c10.2 0 17.9-7.7 17.9-17.9v-17.9h102.4v69.1h-2.5zm0-84.5H120.3v-51.2c0-17.9 15.4-33.3 33.3-33.3h33.3v-23c0-15.4 12.8-30.7 30.7-30.7h76.8c15.4 0 30.7 12.8 30.7 30.7v20.5h33.3c17.9 0 33.3 15.4 33.3 33.3v53.7z" />
  </svg>
)

export default SvgSt4ProjectsFolderSolid
