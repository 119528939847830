import React from "react"
import { Dropdown, DropDownProps } from "antd"
import styled from "styled-components"

type DropdownMenuWithTitleProps = {
  title: React.ReactNode
} & Omit<DropDownProps, "dropdownRender">

const MenuTitle = styled.div`
  padding: ${({ theme: { token } }) => `${token.paddingContentVertical}px ${token.paddingContentHorizontal}px`};
  font-weight: 500;
`

const DropdownContainer = styled.div`
  background-color: ${({ theme: { token } }) => token.colorBgContainer};
  border-radius: ${({ theme: { token } }) => token.borderRadiusLG};
`

/**
 * A dropdown component which displays a title inside the opening popover.
 */
export function DropdownMenuWithTitle(props: React.PropsWithChildren<DropdownMenuWithTitleProps>) {
  const { children, title, ...dropdownProps } = props

  return (
    <Dropdown
      dropdownRender={(menu) => (
        <DropdownContainer>
          <MenuTitle>{title}</MenuTitle>
          {React.cloneElement(menu as React.ReactElement, { style: { boxShadow: "none" } })}
        </DropdownContainer>
      )}
      {...dropdownProps}
    >
      {children}
    </Dropdown>
  )
}
