import * as Types from "@st4/graphql"

import { gql } from "@apollo/client"
import * as Apollo from "@apollo/client"
const defaultOptions = {} as const
export type MoveToRecycleBinMutationVariables = Types.Exact<{
  input: Types.DeleteNodesInput
  languageId?: Types.InputMaybe<Types.Scalars["AspectSelectorId"]>
}>

export type MoveToRecycleBinMutation = {
  __typename: "Mutation"
  moveToRecycleBin:
    | { __typename: "AskDeleteNodesJobResponse"; jobId: string; title: string; message: string }
    | { __typename: "DeleteNodesErrorJobResponse"; jobId: string; title: string; message: string }
    | { __typename: "Error"; message: string }
    | { __typename: "FinishedJobResponse"; jobId: string }
}

export type ContinueDeleteMutationVariables = Types.Exact<{
  input: Types.ContinueDeleteInput
}>

export type ContinueDeleteMutation = {
  __typename: "Mutation"
  continueDelete:
    | { __typename: "AskDeleteNodesJobResponse" }
    | { __typename: "DeleteNodesErrorJobResponse" }
    | { __typename: "Error"; message: string }
    | { __typename: "FinishedJobResponse"; jobId: string }
}

export type DeleteTreeAllowedQueryVariables = Types.Exact<{
  input: Types.Scalars["StPatternContextId"]
  languageId?: Types.InputMaybe<Types.Scalars["AspectSelectorId"]>
}>

export type DeleteTreeAllowedQuery = { __typename: "Query"; deleteTreeAllowed: boolean }

export const MoveToRecycleBinDocument = gql`
  mutation MoveToRecycleBin($input: DeleteNodesInput!, $languageId: AspectSelectorId) {
    moveToRecycleBin(input: $input, languageId: $languageId) {
      ... on AskDeleteNodesJobResponse {
        jobId
        title
        message
      }
      ... on DeleteNodesErrorJobResponse {
        jobId
        title
        message
      }
      ... on FinishedJobResponse {
        jobId
      }
      ... on Error {
        message
      }
    }
  }
`
export type MoveToRecycleBinMutationFn = Apollo.MutationFunction<
  MoveToRecycleBinMutation,
  MoveToRecycleBinMutationVariables
>

/**
 * __useMoveToRecycleBinMutation__
 *
 * To run a mutation, you first call `useMoveToRecycleBinMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveToRecycleBinMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveToRecycleBinMutation, { data, loading, error }] = useMoveToRecycleBinMutation({
 *   variables: {
 *      input: // value for 'input'
 *      languageId: // value for 'languageId'
 *   },
 * });
 */
export function useMoveToRecycleBinMutation(
  baseOptions?: Apollo.MutationHookOptions<MoveToRecycleBinMutation, MoveToRecycleBinMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<MoveToRecycleBinMutation, MoveToRecycleBinMutationVariables>(
    MoveToRecycleBinDocument,
    options,
  )
}
export type MoveToRecycleBinMutationHookResult = ReturnType<typeof useMoveToRecycleBinMutation>
export type MoveToRecycleBinMutationResult = Apollo.MutationResult<MoveToRecycleBinMutation>
export type MoveToRecycleBinMutationOptions = Apollo.BaseMutationOptions<
  MoveToRecycleBinMutation,
  MoveToRecycleBinMutationVariables
>
export const ContinueDeleteDocument = gql`
  mutation ContinueDelete($input: ContinueDeleteInput!) {
    continueDelete(input: $input) {
      ... on FinishedJobResponse {
        jobId
      }
      ... on Error {
        message
      }
    }
  }
`
export type ContinueDeleteMutationFn = Apollo.MutationFunction<ContinueDeleteMutation, ContinueDeleteMutationVariables>

/**
 * __useContinueDeleteMutation__
 *
 * To run a mutation, you first call `useContinueDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContinueDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [continueDeleteMutation, { data, loading, error }] = useContinueDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useContinueDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<ContinueDeleteMutation, ContinueDeleteMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ContinueDeleteMutation, ContinueDeleteMutationVariables>(ContinueDeleteDocument, options)
}
export type ContinueDeleteMutationHookResult = ReturnType<typeof useContinueDeleteMutation>
export type ContinueDeleteMutationResult = Apollo.MutationResult<ContinueDeleteMutation>
export type ContinueDeleteMutationOptions = Apollo.BaseMutationOptions<
  ContinueDeleteMutation,
  ContinueDeleteMutationVariables
>
export const DeleteTreeAllowedDocument = gql`
  query DeleteTreeAllowed($input: StPatternContextId!, $languageId: AspectSelectorId) {
    deleteTreeAllowed(input: $input, languageId: $languageId)
  }
`

/**
 * __useDeleteTreeAllowedQuery__
 *
 * To run a query within a React component, call `useDeleteTreeAllowedQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeleteTreeAllowedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeleteTreeAllowedQuery({
 *   variables: {
 *      input: // value for 'input'
 *      languageId: // value for 'languageId'
 *   },
 * });
 */
export function useDeleteTreeAllowedQuery(
  baseOptions: Apollo.QueryHookOptions<DeleteTreeAllowedQuery, DeleteTreeAllowedQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<DeleteTreeAllowedQuery, DeleteTreeAllowedQueryVariables>(DeleteTreeAllowedDocument, options)
}
export function useDeleteTreeAllowedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DeleteTreeAllowedQuery, DeleteTreeAllowedQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<DeleteTreeAllowedQuery, DeleteTreeAllowedQueryVariables>(
    DeleteTreeAllowedDocument,
    options,
  )
}
export type DeleteTreeAllowedQueryHookResult = ReturnType<typeof useDeleteTreeAllowedQuery>
export type DeleteTreeAllowedLazyQueryHookResult = ReturnType<typeof useDeleteTreeAllowedLazyQuery>
export type DeleteTreeAllowedQueryResult = Apollo.QueryResult<DeleteTreeAllowedQuery, DeleteTreeAllowedQueryVariables>
