import * as React from "react"
import { SVGProps } from "react"

const SvgCircleDownRight = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1em" height="1em" {...props}>
    <path d="M256 464a208 208 0 1 1 0-416 208 208 0 1 1 0 416zm0-464a256 256 0 1 0 0 512 256 256 0 1 0 0-512zm96 198.6c0-12.5-10.1-22.6-22.6-22.6-6 0-11.8 2.4-16 6.6L280 216l-66.3-66.3c-3.7-3.7-8.6-5.7-13.7-5.7s-10 2-13.7 5.7l-36.7 36.7c-3.6 3.6-5.6 8.5-5.6 13.6s2 10 5.7 13.7L216 280l-33.4 33.4c-4.2 4.2-6.6 10-6.6 16 0 12.5 10.1 22.6 22.6 22.6H336c8.8 0 16-7.2 16-16V198.6z" />
  </svg>
)

export default SvgCircleDownRight
