import * as React from "react"
import { SVGProps } from "react"

const SvgTrash = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="1em" height="1em" {...props}>
    <path d="M177.1 48h93.7c2.7 0 5.2 1.3 6.7 3.6l19 28.4h-145l19-28.4c1.5-2.2 4-3.6 6.7-3.6zm177.1 32-36.7-55.1C307.1 9.4 289.6 0 270.9 0h-93.8c-18.7 0-36.2 9.4-46.6 24.9L93.8 80H24C10.7 80 0 90.7 0 104s10.7 24 24 24h11.6l24 324.7c2.5 33.4 30.3 59.3 63.8 59.3h201.2c33.5 0 61.3-25.9 63.8-59.3l24-324.7H424c13.3 0 24-10.7 24-24s-10.7-24-24-24h-69.8zm10.1 48-23.8 321.2c-.6 8.4-7.6 14.8-16 14.8H123.4c-8.4 0-15.3-6.5-16-14.8L83.7 128h280.6z" />
  </svg>
)

export default SvgTrash
