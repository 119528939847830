import { isTypename } from "@st4/graphql"
import { useLanguage } from "@st4/settings"
import {
  RemoveReuseLinksMutation,
  useRemoveReuseLinksAllowedLazyQuery,
  useRemoveReuseLinksMutation,
} from "./query.hooks"
import { useCallback } from "react"
import { useTranslation } from "react-i18next"
import { keys } from "@st4/ui-strings"

export function useRemoveReuseLinks(handleCompletedSuccessfully: (nodeId: string) => void) {
  const { languageId } = useLanguage()
  const { t: translate } = useTranslation()
  const [removeReuseLinks, { loading }] = useRemoveReuseLinksMutation()

  const handleCompleted = useCallback(
    (data: RemoveReuseLinksMutation, nodeId: string) => {
      if (data && isTypename("RemoveReuseLinksSuccess")(data.removeReuseLinks)) {
        handleCompletedSuccessfully(nodeId)
      }
    },
    [handleCompletedSuccessfully]
  )

  const startJob = useCallback(
    (linksToRemove: string[]) => {
      return removeReuseLinks({
        variables: { input: linksToRemove, languageId: languageId },
        refetchQueries: ["NavigationTree"],
        onCompleted: (data: RemoveReuseLinksMutation) =>
          linksToRemove.forEach((nodeId) => handleCompleted(data, nodeId)),
      })
        .then((value) => {
          const graphqlErrors = value.errors?.map((graphqlError) => graphqlError.toString())?.join("\r\n")
          const st4Error = isTypename("Error")(value.data?.removeReuseLinks)
            ? value.data?.removeReuseLinks.message
            : undefined
          return {
            error: graphqlErrors ?? st4Error,
          }
        })
        .catch((rejectionReason) => {
          console.log(rejectionReason)

          return { error: translate(keys.message.error.generic) }
        })
    },
    [removeReuseLinks, languageId, handleCompleted, translate]
  )

  return {
    startJob,
    loading,
  }
}

export function useRemoveReuseLinksAllowedLazy(): (nodeId: string) => Promise<boolean> {
  const [query] = useRemoveReuseLinksAllowedLazyQuery()

  const getRemoveReuseLinksAllowed = useCallback(
    (nodeId: string) => {
      return query({
        variables: {
          input: [nodeId],
        },
        fetchPolicy: "network-only",
      })
        .then((value) => {
          if (value.loading) throw "query should be finished before continue occurs"

          return value.data?.removeReuseLinksAllowed ?? false
        })
        .catch((rejectionReason) => {
          console.log(rejectionReason)
          return false
        })
    },
    [query]
  )

  return getRemoveReuseLinksAllowed
}
