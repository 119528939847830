import React, { useEffect, useState } from "react"
import { Translate, keys } from "@st4/i18n"
import { Input } from "@st4/ui"
import { Icon, Light } from "@st4/icons"

type DecimalEditorProps = {
  value?: string
  isReadonly?: boolean
  suffixIcon?: React.ReactNode
  onSubmit: (value: string) => void
  errorMessage?: string
}

function isDecimal(value: string) {
  const regexIsDecimal = new RegExp("^[-]{0,1}[0-9]*[.,]{0,1}[0-9]*$")
  return regexIsDecimal.test(value)
}

export function DecimalEditor({ value, isReadonly, suffixIcon, onSubmit, errorMessage }: DecimalEditorProps) {
  const originalValue = value ?? ""
  const [inputValue, setInputValue] = useState(value ?? "")
  const dirty = value != inputValue

  useEffect(() => {
    setInputValue(value ?? "")
  }, [value])

  function handleKeyup(ev: React.KeyboardEvent<HTMLInputElement>) {
    if (ev.key === "Escape") {
      setInputValue(originalValue)
    }
  }

  const submitHandler = (value: string) => {
    if (dirty && isDecimal(value)) onSubmit(value)
  }

  if (!suffixIcon && !isReadonly) {
    suffixIcon = <Icon component={Light.Pen} />
  }

  const dirtyAndNoDecimal = dirty && !isDecimal(inputValue)
  const dirtyAndNoDecimalMessageKey = keys.message.error.inputNotDecimal

  return (
    <Input
      errorMessage={
        errorMessage ?? (dirtyAndNoDecimal ? <Translate>{dirtyAndNoDecimalMessageKey}</Translate> : undefined)
      }
      dirty={dirty}
      readonly={isReadonly}
      value={inputValue}
      onChange={(e) => setInputValue(e.target.value)}
      onSubmit={() => submitHandler(inputValue)}
      suffixIcon={suffixIcon}
      onKeyUp={handleKeyup}
    />
  )
}
