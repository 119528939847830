import React, { useEffect, useRef, useState } from "react"
import { Empty } from "antd"
import { isTypename } from "@st4/graphql"
import { Translate } from "@st4/i18n"
import { keys } from "@st4/ui-strings"
import { Result } from "~/ui/result"
import { CategorizedProperties } from "./CategorizedProperties"
import { ListedProperties } from "./ListedProperties"
import { SortedProperties } from "./SortedProperties"
import { FilterOnDisplayNameWithSearchTerm } from "./filters/SearchFilter"
import { usePropertiesQuery } from "./query.hooks"
import { FilterType } from "./types"

type PropertiesViewletProps = {
  selectedNode: string
  aspectId: string
  sortSelection?: string
  variant?: string
  searchTerm?: string
  filter?: FilterType
  readOnly?: boolean
  refetchOnSave?: string[]
  expandedCategories?: string[]
  onExpand?: (expandedCategories: string[]) => void
}

type ErrorMessage =
  | {
      message: string
    }
  | undefined

export function PropertiesViewlet({
  selectedNode,
  aspectId,
  sortSelection,
  variant,
  searchTerm,
  filter,
  readOnly,
  refetchOnSave,
  expandedCategories,
  onExpand,
}: PropertiesViewletProps) {
  const queryVariables = {
    selectedNode,
    variant: variant ?? "Metadata",
    languageId: aspectId,
    filter: filter,
  }
  const { loading, data, error, refetch } = usePropertiesQuery({
    variables: queryVariables,
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-and-network",
  })
  const [displayLoadingInfo, setDisplayLoadingInfo] = useState(loading)

  function refetchProperties() {
    refetch(queryVariables)
  }

  const nodes = data?.tree?.nodes
  const node = nodes ? nodes[0] : null
  const isNode = isTypename("ST4TreeNode")(node)
  let properties =
    isNode && isTypename("PropertyListSuccess")(node.properties)
      ? node.properties.result.filter((property) => FilterOnDisplayNameWithSearchTerm(property, searchTerm))
      : []

  if (readOnly) {
    properties = properties.map((property) => {
      return { ...property, editor: { ...property.editor, isReadonly: readOnly || property.editor.isReadonly } }
    })
  }

  let combinedError: ErrorMessage
  if (!error) {
    combinedError = isNode && isTypename("Error")(node.properties) ? node.properties : undefined
  } else if (isNode && isTypename("Error")(node.properties)) {
    combinedError = error
    combinedError.message += "Actor error info: " + node.properties.message
  }

  useEffect(() => {
    setDisplayLoadingInfo(true)
  }, [selectedNode])

  if (!loading && displayLoadingInfo) {
    setDisplayLoadingInfo(false) //reset after switch load
  }

  const isSearchingForTerm = searchTerm && searchTerm !== ""
  const defaultSorting = sortSelection === undefined || sortSelection === "default"
  const commonProps = {
    properties: properties,
    languageId: aspectId,
    refetchProperties: refetchProperties,
    refetchOnSave: refetchOnSave,
  }

  let propertiesComponent: React.ReactNode

  if (properties.length == 0 && isSearchingForTerm) {
    propertiesComponent = (
      <Empty
        description={
          <div>
            <Translate>{keys.label.general.searchEmpty}</Translate>
          </div>
        }
        style={{ marginTop: "24px" }}
      />
    )
  } else if (isSearchingForTerm && defaultSorting) {
    propertiesComponent = <ListedProperties {...commonProps} />
  } else if (defaultSorting) {
    propertiesComponent = (
      <CategorizedProperties {...commonProps} expandedCategories={expandedCategories} onExpand={onExpand} />
    )
  } else {
    propertiesComponent = <SortedProperties {...commonProps} sortSelection={sortSelection} />
  }

  if (combinedError) {
    return (
      <Result error={combinedError} errorReason="Getting Properties Failed" loading={displayLoadingInfo}>
        {propertiesComponent}
      </Result>
    )
  } else {
    return <Result loading={displayLoadingInfo}>{propertiesComponent}</Result>
  }
}
