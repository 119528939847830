import { Empty } from "antd"
import React, { useEffect } from "react"
import { Blade, BladeDefinition, BladeSize } from "@st4/tasks"
import { Content, ContentSelection, ContentMessage, ContentTarget } from "@st4/content"
import { useAspectId } from "@st4/settings"
import { getHelpContext, setHelpContext, replaceHelpContext, setToPreviousHelpContext } from "~/hooks/helpContext"
import { Translate, keys } from "@st4/i18n"
import { PoolBladeNotifications } from "~/components/treeActions/PoolBladeNotifications"
import { useEditorNotifications } from "./useEditorNotifications"

type EditorBladeMessage = ContentMessage

type EditorBladeProps = {
  rootNodeId?: string
  selection: ContentSelection
  editedNodes: string[]
  size?: BladeSize
  refetchOnEditorSave?: string[]
  initialScrollTarget?: Array<[string, ContentTarget]>
}

export const EditorBlade: BladeDefinition<EditorBladeProps, EditorBladeMessage> = function ContentBladeWithContext(
  props,
) {
  const aspectId = useAspectId()
  const { rootNodeId, editedNodes, selection, messageHub, initialScrollTarget } = props
  const currentHelpKey = getHelpContext()

  useEffect(() => {
    SetEditorHelp(currentHelpKey, editedNodes.length > 0)
  }, [currentHelpKey, editedNodes.length])

  const notifications = useEditorNotifications({ nodeId: selection?.nodeId ?? "", messageHub })
  const mappedInitialScrollTarget = new Map(initialScrollTarget)

  if (!rootNodeId) {
    return (
      <Blade.Content>
        <Empty />
      </Blade.Content>
    )
  } else {
    return (
      <>
        <Blade.Content>
          <Content
            key={rootNodeId}
            rootNodeId={rootNodeId}
            languageId={aspectId}
            mode="REVIEW"
            disableMetadataDrawer={true}
            collapsibleComments={false}
            initialCommentsCollapsed={true}
            editedNodes={editedNodes}
            singleNodeMode={true}
            selection={selection ?? null}
            messageHub={messageHub}
            refetchOnEditorSave={props.refetchOnEditorSave}
            initialScrollTarget={mappedInitialScrollTarget?.get(rootNodeId)}
          />
        </Blade.Content>
        <PoolBladeNotifications notifications={notifications} />
      </>
    )
  }
}
EditorBlade.title = <Translate>{keys.label.contentBlade.title}</Translate>
EditorBlade.size = (props: EditorBladeProps) => {
  return props.size ?? { S: 18, M: 13, L: 9 }
}
EditorBlade.reducer = function reducer(previousState, message) {
  switch (message.action) {
    case "selectionChanged":
      return {
        ...previousState,
        selection: message.payload,
      }
    case "scrollTargetChanged":
      return {
        ...previousState,
        initialScrollTarget: setInitialScrollTarget(
          previousState.initialScrollTarget,
          previousState.rootNodeId ?? "",
          message.payload.scrollTarget,
        ),
      }
    case "editingFinished":
      return {
        ...previousState,
        singleNodeMode: false,
        selection: message.payload.selection,
        editedNodes:
          previousState.editedNodes.length === 1
            ? []
            : previousState.editedNodes.filter((id) => id != message.payload.selection?.nodeId),
      }
  }

  return previousState
}

function setInitialScrollTarget(
  prevInitialScrollTarget: Array<[string, ContentTarget]> | undefined,
  rootNodeId: string,
  scrollTarget: ContentTarget,
): Array<[string, ContentTarget]> {
  if (!prevInitialScrollTarget) {
    return [[rootNodeId, scrollTarget]]
  }
  const newInitialScrollTarget = [...prevInitialScrollTarget]
  const rootNodeIndex = newInitialScrollTarget.findIndex((target) => target[0] === rootNodeId)
  if (rootNodeIndex >= 0) {
    newInitialScrollTarget[rootNodeIndex] = [rootNodeId, scrollTarget]
  } else {
    newInitialScrollTarget.push([rootNodeId, scrollTarget])
  }
  return newInitialScrollTarget
}

function SetEditorHelp(currentHelpKey: string, nodesInEditMode: boolean) {
  const inplaceHelpKey = "inplaceEditor"
  const singleNodeHelpKey = "singleNodeEditor"
  if ([inplaceHelpKey, singleNodeHelpKey].includes(currentHelpKey)) {
    // current help key refers to any editor help
    if (!nodesInEditMode) setToPreviousHelpContext()
    else if (currentHelpKey === inplaceHelpKey) replaceHelpContext(singleNodeHelpKey)
  } else {
    // current help key doesn't refer to any editor help
    if (nodesInEditMode) setHelpContext(singleNodeHelpKey)
  }
}
