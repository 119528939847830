import { defineScope, defineTransition } from "@st4/tasks"
import { projectsScreen } from "../screens"
import { keys, Translate } from "@st4/i18n"
import React from "react"

export const projectsScope = defineScope({
  displayName: <Translate>{keys.label.screens.project}</Translate>,
  transitions: {
    "start-->projects": {
      type: "start",
    },
  },
  screens: {
    projects: projectsScreen,
  },
})

defineTransition(projectsScope, "start-->projects", async (context) => {
  return context.to.initialContextValues
})
