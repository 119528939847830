import { Empty } from "antd"
import React, { useState } from "react"
import { Blade, defineMemoizedBlade } from "@st4/tasks"
import { PropertiesViewlet } from "~/components/properties"
import { DropdownWithBadge } from "~/components/dropdown"
import { useTranslation } from "react-i18next"
import { keys } from "@st4/ui-strings"
import { FilterType } from "~/components/properties/types"
import { useAspectId } from "@st4/settings"
import { Icon, Regular } from "@st4/icons"
import { Translate } from "@st4/i18n"
import { QuickSearchBox } from "@st4/search"

type PropertiesBladeProps = {
  nodeId?: string
  readOnly?: boolean
  refetchOnSave?: string[]
  expandedCategories?: string[]
}

type SortSelection = {
  label: React.ReactNode
  value: string
}

type FilterSelection = {
  label: React.ReactNode
  value: string
  filter: FilterType
}

type Messages = {
  action: "categoryExpanded"
  payload: string[]
}

export const PropertiesBlade = defineMemoizedBlade<PropertiesBladeProps, Messages>(
  <Translate>{keys.label.properties._}</Translate>,
  function PropertiesBladeWithContext(props) {
    const { nodeId, readOnly, refetchOnSave } = props
    const aspectId = useAspectId()
    const { t: translate } = useTranslation()
    const translatedSortOptions = [
      {
        label: <Translate>{keys.label.sort.default}</Translate>,
        value: "default",
      },
      {
        label: <Translate>{keys.label.sort.alphanumeric}</Translate>,
        value: "alphanumeric",
      },
      {
        label: <Translate>{keys.label.sort.descendingalphanumeric}</Translate>,
        value: "descending-alphanumeric",
      },
    ]

    const translatedFilterOptions: FilterSelection[] = [
      { label: <Translate>{keys.label.filter.all}</Translate>, value: "all", filter: "ALL" },
      { label: <Translate>{keys.label.filter.filled}</Translate>, value: "filled", filter: "FILLED" },
      { label: <Translate>{keys.label.filter.unfilled}</Translate>, value: "unfilled", filter: "UNFILLED" },
    ]

    const [sortSelection, setSortSelection] = useState<SortSelection>(translatedSortOptions[0])
    const [filterSelection, setFilterSelection] = useState<FilterSelection>(translatedFilterOptions[0])
    const [searchTerm, setSearchTerm] = useState<string>("")

    return (
      <>
        <Blade.Filterbar>
          <QuickSearchBox
            placeholder={translate(keys.label.properties.search.titles)}
            allowClear
            onChange={(e) => setSearchTerm(e.target.value)}
            value={searchTerm}
            onSearch={(searchTerm: string) => {
              setSearchTerm(searchTerm)
            }}
            style={{ width: "100%" }}
          />

          <div
            style={{
              alignItems: "center",
              display: "flex",
              marginLeft: "8px",
              marginRight: "8px",
              transform: "translate(0px, 3px)",
            }}
          >
            <DropdownWithBadge
              items={translatedFilterOptions}
              menuItemOnClick={(value) => {
                setFilterSelection(
                  translatedFilterOptions.find((filterSelection) => {
                    return filterSelection.value === value.value
                  }) ?? translatedFilterOptions[0],
                )
              }}
              icon={<Icon component={Regular.Filter} style={{ fontSize: "20px" }} />}
              selectedItem={filterSelection}
              standardSelection={"all"}
              tooltipFromSelectedItem={false}
              componentTranslationKey={keys.label.general.filter}
            />
          </div>

          <div style={{ alignItems: "center", display: "flex", marginLeft: "8px", transform: "translate(0px, 3px)" }}>
            <DropdownWithBadge
              items={translatedSortOptions}
              menuItemOnClick={(value) => setSortSelection(value)}
              selectedItem={sortSelection}
              standardSelection={"default"}
              icon={<Icon component={Regular.ArrowUpAz} style={{ fontSize: "20px" }} />}
              tooltipFromSelectedItem={false}
              componentTranslationKey={keys.label.general.sortBy}
            />
          </div>
        </Blade.Filterbar>
        <Blade.Content>
          {nodeId ? (
            <PropertiesViewlet
              aspectId={aspectId}
              selectedNode={nodeId}
              sortSelection={sortSelection.value}
              searchTerm={searchTerm}
              filter={filterSelection.filter}
              readOnly={readOnly}
              refetchOnSave={refetchOnSave}
              expandedCategories={props.expandedCategories}
              onExpand={(expandedCategories: string[]) => {
                props.sendMessage({ action: "categoryExpanded", payload: expandedCategories })
              }}
            />
          ) : (
            <Empty style={{ marginTop: 24 }} />
          )}
        </Blade.Content>
      </>
    )
  },
)
PropertiesBlade.size = { S: 10, M: 7, L: 5 }
PropertiesBlade.reducer = (previousState, message) => {
  switch (message.action) {
    case "categoryExpanded":
      return {
        ...previousState,
        expandedCategories: message.payload,
      }
  }
  return previousState
}
