import React from "react"
import styled from "styled-components"
import type { CreateNewNodeJobResponse } from "../../query.hooks.types"
import { isAskRequiredMetadataDialogCommandJobResponse } from "../../query.hooks.utils"
import { Form } from "antd"
import { keys } from "@st4/ui-strings"
import { useStepAskRequiredMetadata } from "./hooks/StepAskRequiredMetadata"
import type { ActionHandlerInfo } from "../types"
import { Translate } from "@st4/i18n"

type AskRequiredMetadataFormProps = {
  nodeId: string
  getRequiredPropertyEditorComponent: (nodeId: string) => React.ReactElement
  onActionsChanged: (info: ActionHandlerInfo) => void
  onMutationCompleted: (result: CreateNewNodeJobResponse) => void
}

const StyledForm = styled(Form)`
  padding: 16px;
`

function AskRequiredMetadataForm(props: AskRequiredMetadataFormProps) {
  const { nodeId, getRequiredPropertyEditorComponent } = props
  useStepAskRequiredMetadata(props)

  return (
    <StyledForm layout="vertical">
      <Form.Item label={<Translate>{keys.label.askRequiredMetadataForm.requiredMetadata}</Translate>}>
        {getRequiredPropertyEditorComponent(nodeId)}
      </Form.Item>
    </StyledForm>
  )
}

export function getterFormAskRequiredMetadataByCommandInfo(
  commandInfo: any,
  getRequiredPropertyEditorComponent: (nodeId: string) => React.ReactElement,
  onActionsChanged: (info: ActionHandlerInfo) => void,
  onMutationCompleted: (result: CreateNewNodeJobResponse) => void,
) {
  if (!isAskRequiredMetadataDialogCommandJobResponse(commandInfo)) return undefined
  return (
    <AskRequiredMetadataForm
      nodeId={commandInfo.nodeId}
      getRequiredPropertyEditorComponent={getRequiredPropertyEditorComponent}
      onActionsChanged={onActionsChanged}
      onMutationCompleted={onMutationCompleted}
    />
  )
}
