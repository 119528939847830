import { getSafetyModelConfig } from "@st4/content-tools";
export function extractConfigData(configuration) {
    var safetyModel = configuration.safetyModel;
    var safetyInfos = getSafetyModelConfig(safetyModel);
    var languageXml = {};
    configuration === null || configuration === void 0 ? void 0 : configuration.languageXmlValues.map(function(lang) {
        if (!lang.label || !lang.value) return;
        languageXml[lang.label] = lang.value;
    });
    var inlineStyles = {};
    configuration === null || configuration === void 0 ? void 0 : configuration.inlineStyleDefinitions.map(function(inlineStyle) {
        inlineStyles[inlineStyle.type] = JSON.stringify(inlineStyle);
    });
    return {
        safetyInfo: {
            icons: safetyInfos.safetyIcons,
            safetyTypes: safetyInfos.safetyTypeInfo,
            severityLevels: safetyInfos.severityLevelInfo,
            safetyImageInfo: safetyInfos.safetyImageInfos
        },
        languageXml: languageXml,
        commentModel: configuration === null || configuration === void 0 ? void 0 : configuration.commentModel,
        inlineStyles: inlineStyles
    };
}
