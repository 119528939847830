import * as React from "react"
import { SVGProps } from "react"

const SvgSt4FileTagLight = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1em" height="1em" {...props}>
    <path d="M63 64.3C63 28.3 91.3 0 127.4 0h156.9c12.9 0 25.7 5.1 33.4 12.9l115.8 115.8c10.3 10.3 15.4 20.6 15.4 33.4v285.6c0 36-28.3 64.3-64.3 64.3H127.4c-36 0-64.3-28.3-64.3-64.3V64.3zM415.5 193H302.3c-25.7 0-48.9-20.6-48.9-48.9V30.9h-126c-18 0-30.9 15.4-30.9 30.9v385.9c0 18 15.4 30.9 30.9 30.9h257.3c18 0 30.9-15.4 30.9-30.9V193zm-2.6-38.6L297.2 38.6c-2.6-2.6-5.1-2.6-7.7-5.1v113.2c0 7.7 7.7 15.4 15.4 15.4h113.2c-2.6-5.2-5.2-7.7-5.2-7.7zm-286.2 79.7v87.5c0 10.3 5.1 20.6 10.3 25.7l102.9 102.9c15.4 15.4 38.6 15.4 54 0l79.8-79.8c15.4-15.4 15.4-38.6 0-54L270.8 213.5c-7.7-2.6-18-7.7-28.3-7.7H155c-15.4 0-28.3 12.9-28.3 28.3zm66.9 56.6c-10.3 0-18-7.7-18-18s7.7-18 18-18 18 7.7 18 18-7.7 18-18 18z" />
  </svg>
)

export default SvgSt4FileTagLight
