import React from "react"
import { NavigationTreeViewletWithQuery, NavigationTreeViewletWithQueryProps } from "./navigationtreeviewletwithquery"
import { CoreNavigationTreeViewletProps, CreateButtonProps, DragDropProps, MeatballMenuProps } from "./types"

type DragDropNavigationTreeViewletProps = {
  expandedNodes?: string[]
  includeNodeClassHierarchy?: boolean
} & CoreNavigationTreeViewletProps &
  DragDropProps &
  MeatballMenuProps &
  CreateButtonProps &
  Pick<NavigationTreeViewletWithQueryProps, "onAllExpandedNodesChanged" | "stateRef" | "onExpandChange">

export const DragDropNavigationTreeViewlet = (props: DragDropNavigationTreeViewletProps) => {
  return (
    <NavigationTreeViewletWithQuery
      ignoreIcons={false}
      {...props}
      expandedNodes={props.expandedNodes ?? []}
      includeNodeClassHierarchy={props.includeNodeClassHierarchy ?? false}
    />
  )
}
