import styled from "styled-components"
import Toolbar_deprecated from "./Toolbar_deprecated"

// prettier-ignore
const Header_deprecated = styled(Toolbar_deprecated)`
  padding: 5px  1em;
  background: "#efefef";
  justify-content: space-around;
  margin-bottom: 1em;
`

export default Header_deprecated
