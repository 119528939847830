import React from "react"
import { getterFormCreateNewNodeByCommandInfo } from "./CreateNewNodeForm"
import { getterFormAskRequiredMetadataByCommandInfo } from "./AskRequiredMetadataForm"
import { useCreateNodeProcess } from "./hooks/CreateNodeProcess"
import {
  isNodeDialogCommandJobResponse,
  isAskRequiredMetadataDialogCommandJobResponse,
  isFailedJobResponse,
} from "../../query.hooks.utils"
import { Spin } from "antd"
import { keys } from "@st4/ui-strings"
import { AlertError } from "./AlertError"
import { AlertQuestion } from "./AlertQuestion"
import type { ActionHandlerInfo } from "../types"
import { Translate } from "@st4/i18n"

export type CreateNewNodeProcessVisualizerProps = {
  contextNodeId: string
  getRequiredPropertyEditorComponent: (nodeId: string) => React.ReactElement
  onActionsChanged: (info: ActionHandlerInfo) => void
  onJobStarted?: (jobId: string) => void
  onJobCompleted?: (jobId: string) => void
}

export function CreateNewNodeProcessVisualizer(props: CreateNewNodeProcessVisualizerProps) {
  const { getRequiredPropertyEditorComponent } = props

  const {
    activeResponse,
    shownContextSwitchApplyDialog,
    stepSaveBaseDataInputValues,
    handleStepSaveBaseDataInputValuesChanged,
    handleActionsChanged,
    handleCreateNewNodeMutationCompleted,
  } = useCreateNodeProcess(props)

  if (shownContextSwitchApplyDialog)
    return <AlertQuestion messageKey={keys.message.createNewNodeVisualizer.question.switchContext} />

  // If active result is a command result return form or error alert if the requested
  // command name has no ui implementation
  if (
    activeResponse !== null &&
    (isNodeDialogCommandJobResponse(activeResponse) || isAskRequiredMetadataDialogCommandJobResponse(activeResponse))
  ) {
    const form =
      getterFormCreateNewNodeByCommandInfo(
        activeResponse,
        stepSaveBaseDataInputValues?.configId,
        handleStepSaveBaseDataInputValuesChanged,
        handleActionsChanged,
        handleCreateNewNodeMutationCompleted,
      ) ??
      getterFormAskRequiredMetadataByCommandInfo(
        activeResponse,
        getRequiredPropertyEditorComponent,
        handleActionsChanged,
        handleCreateNewNodeMutationCompleted,
      )

    if (!form) return <AlertError messageKey={keys.message.createNewNodeVisualizer.error.commandNotImplemented} />

    return form
  } else if (activeResponse !== null && isFailedJobResponse(activeResponse)) {
    return <AlertError message={activeResponse.message} />
  }

  // Return a spinner for non ui process steps
  return (
    <Spin
      tip={<Translate>{keys.description.createNewNodeVisualizer.inProgress.createNode}</Translate>}
      style={{ width: "100%" }}
    />
  )
}
