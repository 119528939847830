import { visit } from "unist-util-visit"
import { Node } from "unist"

export function findNodeByTagName(tagName: string, ast: Node) {
  let foundNode = undefined
  visit(ast, (node) => {
    if (node.tagName === tagName) {
      foundNode = node
      return false //aborts further traversing
    }
  })
  return foundNode
}

export function findNodesByTagName(tagName: string, ast: Node) {
  const foundNodes: Node[] = []
  visit(ast, (node) => {
    if (node.tagName === tagName) {
      foundNodes.push(node)
    }
  })
  return foundNodes
}
