import React, { MouseEvent } from "react"
import styled from "styled-components"
import { Modal } from "antd"

const StyledModal = styled(Modal)`
  overflow: hidden;
`

type ModalPopupProps = {
  onClose?: (event: MouseEvent) => void
  onSave?: (event: MouseEvent) => void
  closable?: boolean
  title?: string
  wide?: boolean
}

export function ModalPopup({
  children,
  onClose,
  onSave,
  title,
  wide = false,
  closable = true,
}: React.PropsWithChildren<ModalPopupProps>) {
  return (
    <StyledModal
      closable={!!onClose && closable}
      title={title}
      onCancel={onClose}
      onOk={onSave}
      width={wide ? 960 : 680}
      open
      footer={onSave ? undefined : null}
    >
      {children}
    </StyledModal>
  )
}
