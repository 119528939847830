import * as Types from "@st4/graphql"

import { gql } from "@apollo/client"
import * as Apollo from "@apollo/client"
const defaultOptions = {} as const
export type LanguagesQueryVariables = Types.Exact<{ [key: string]: never }>

export type LanguagesQuery = {
  __typename: "Query"
  configuration: {
    __typename: "ConfigurationModel"
    languageIdList: Array<{
      __typename: "LanguageIdListEntry"
      language: string
      languageName: string
      languageId: any
    }>
  }
  userConfiguration: {
    __typename: "UserConfigurationModel"
    contentLanguageCode: string
    aspectSelectorId: any
    contentLanguageId: any
    guiLanguageId: any
  }
}

export type AppTokensQueryVariables = Types.Exact<{
  name?: Types.InputMaybe<Types.Scalars["String"]>
}>

export type AppTokensQuery = {
  __typename: "Query"
  configuration: {
    __typename: "ConfigurationModel"
    thirdPartyAppTokens: Array<{ __typename: "AppToken"; name: string; token: string }>
  }
}

export type SaveContentLanguageMutationVariables = Types.Exact<{
  languageCode: Types.Scalars["String"]
}>

export type SaveContentLanguageMutation = {
  __typename: "Mutation"
  setContentLanguage: { __typename: "UserConfigurationModel"; contentLanguageCode: string; aspectSelectorId: any }
}

export type SaveGuiLanguageMutationVariables = Types.Exact<{
  languageCode: Types.Scalars["String"]
}>

export type SaveGuiLanguageMutation = {
  __typename: "Mutation"
  saveMyAccount:
    | { __typename: "Error" }
    | { __typename: "UserAccountSuccess"; result: { __typename: "UserAccount"; displayLanguage: string } }
}

export type UserLanguageQueryVariables = Types.Exact<{ [key: string]: never }>

export type UserLanguageQuery = {
  __typename: "Query"
  userConfiguration: {
    __typename: "UserConfigurationModel"
    contentLanguageCode: string
    aspectSelectorId: any
    contentLanguageSpecificCulture?: string | null
  }
}

export type SettingsQueryVariables = Types.Exact<{ [key: string]: never }>

export type SettingsQuery = {
  __typename: "Query"
  configuration: {
    __typename: "ConfigurationModel"
    serverVersion: string
    languageIdList: Array<{
      __typename: "LanguageIdListEntry"
      language: string
      languageName: string
      languageId: any
    }>
    guiLanguages: Array<{ __typename: "LabeledValue"; label: string; value: string }>
  }
  userConfiguration: {
    __typename: "UserConfigurationModel"
    contentLanguageCode: string
    contentLanguageSpecificCulture?: string | null
    aspectSelectorId: any
    contentLanguageId: any
    guiLanguageId: any
    guiLanguageCode: string
    editor: { __typename: "UserEditorConfiguration"; spellCheckerActive: boolean }
  }
}

export type SearchSettingsQueryVariables = Types.Exact<{
  languageId: Types.Scalars["AspectSelectorId"]
}>

export type SearchSettingsQuery = {
  __typename: "Query"
  configuration: {
    __typename: "ConfigurationModel"
    searchableAspects: Array<any>
    searchableDataValues: Array<{
      __typename: "SearchableDataValue"
      internalName: string
      displayName: string
      isUsedByDefault: boolean
    }>
  }
}

export type SetEditorConfigurationMutationVariables = Types.Exact<{
  input: Types.UserEditorConfigurationInput
}>

export type SetEditorConfigurationMutation = {
  __typename: "Mutation"
  setUserEditorConfiguration: {
    __typename: "UserConfigurationModel"
    editor: { __typename: "UserEditorConfiguration"; spellCheckerActive: boolean }
  }
}

export const LanguagesDocument = gql`
  query Languages {
    configuration {
      languageIdList {
        language
        languageName
        languageId
      }
    }
    userConfiguration {
      contentLanguageCode
      aspectSelectorId
      contentLanguageId
      guiLanguageId
    }
  }
`

/**
 * __useLanguagesQuery__
 *
 * To run a query within a React component, call `useLanguagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLanguagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLanguagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useLanguagesQuery(baseOptions?: Apollo.QueryHookOptions<LanguagesQuery, LanguagesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<LanguagesQuery, LanguagesQueryVariables>(LanguagesDocument, options)
}
export function useLanguagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LanguagesQuery, LanguagesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<LanguagesQuery, LanguagesQueryVariables>(LanguagesDocument, options)
}
export type LanguagesQueryHookResult = ReturnType<typeof useLanguagesQuery>
export type LanguagesLazyQueryHookResult = ReturnType<typeof useLanguagesLazyQuery>
export type LanguagesQueryResult = Apollo.QueryResult<LanguagesQuery, LanguagesQueryVariables>
export const AppTokensDocument = gql`
  query AppTokens($name: String) {
    configuration {
      thirdPartyAppTokens(name: $name) {
        name
        token
      }
    }
  }
`

/**
 * __useAppTokensQuery__
 *
 * To run a query within a React component, call `useAppTokensQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppTokensQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppTokensQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useAppTokensQuery(baseOptions?: Apollo.QueryHookOptions<AppTokensQuery, AppTokensQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AppTokensQuery, AppTokensQueryVariables>(AppTokensDocument, options)
}
export function useAppTokensLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AppTokensQuery, AppTokensQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AppTokensQuery, AppTokensQueryVariables>(AppTokensDocument, options)
}
export type AppTokensQueryHookResult = ReturnType<typeof useAppTokensQuery>
export type AppTokensLazyQueryHookResult = ReturnType<typeof useAppTokensLazyQuery>
export type AppTokensQueryResult = Apollo.QueryResult<AppTokensQuery, AppTokensQueryVariables>
export const SaveContentLanguageDocument = gql`
  mutation SaveContentLanguage($languageCode: String!) {
    setContentLanguage(languageCode: $languageCode) {
      contentLanguageCode
      aspectSelectorId
    }
  }
`
export type SaveContentLanguageMutationFn = Apollo.MutationFunction<
  SaveContentLanguageMutation,
  SaveContentLanguageMutationVariables
>

/**
 * __useSaveContentLanguageMutation__
 *
 * To run a mutation, you first call `useSaveContentLanguageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveContentLanguageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveContentLanguageMutation, { data, loading, error }] = useSaveContentLanguageMutation({
 *   variables: {
 *      languageCode: // value for 'languageCode'
 *   },
 * });
 */
export function useSaveContentLanguageMutation(
  baseOptions?: Apollo.MutationHookOptions<SaveContentLanguageMutation, SaveContentLanguageMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SaveContentLanguageMutation, SaveContentLanguageMutationVariables>(
    SaveContentLanguageDocument,
    options,
  )
}
export type SaveContentLanguageMutationHookResult = ReturnType<typeof useSaveContentLanguageMutation>
export type SaveContentLanguageMutationResult = Apollo.MutationResult<SaveContentLanguageMutation>
export type SaveContentLanguageMutationOptions = Apollo.BaseMutationOptions<
  SaveContentLanguageMutation,
  SaveContentLanguageMutationVariables
>
export const SaveGuiLanguageDocument = gql`
  mutation SaveGuiLanguage($languageCode: String!) {
    saveMyAccount(input: { displayLanguage: $languageCode }) {
      ... on UserAccountSuccess {
        result {
          displayLanguage
        }
      }
    }
  }
`
export type SaveGuiLanguageMutationFn = Apollo.MutationFunction<
  SaveGuiLanguageMutation,
  SaveGuiLanguageMutationVariables
>

/**
 * __useSaveGuiLanguageMutation__
 *
 * To run a mutation, you first call `useSaveGuiLanguageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveGuiLanguageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveGuiLanguageMutation, { data, loading, error }] = useSaveGuiLanguageMutation({
 *   variables: {
 *      languageCode: // value for 'languageCode'
 *   },
 * });
 */
export function useSaveGuiLanguageMutation(
  baseOptions?: Apollo.MutationHookOptions<SaveGuiLanguageMutation, SaveGuiLanguageMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SaveGuiLanguageMutation, SaveGuiLanguageMutationVariables>(SaveGuiLanguageDocument, options)
}
export type SaveGuiLanguageMutationHookResult = ReturnType<typeof useSaveGuiLanguageMutation>
export type SaveGuiLanguageMutationResult = Apollo.MutationResult<SaveGuiLanguageMutation>
export type SaveGuiLanguageMutationOptions = Apollo.BaseMutationOptions<
  SaveGuiLanguageMutation,
  SaveGuiLanguageMutationVariables
>
export const UserLanguageDocument = gql`
  query UserLanguage {
    userConfiguration {
      contentLanguageCode
      aspectSelectorId
      contentLanguageSpecificCulture
    }
  }
`

/**
 * __useUserLanguageQuery__
 *
 * To run a query within a React component, call `useUserLanguageQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserLanguageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserLanguageQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserLanguageQuery(
  baseOptions?: Apollo.QueryHookOptions<UserLanguageQuery, UserLanguageQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<UserLanguageQuery, UserLanguageQueryVariables>(UserLanguageDocument, options)
}
export function useUserLanguageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserLanguageQuery, UserLanguageQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<UserLanguageQuery, UserLanguageQueryVariables>(UserLanguageDocument, options)
}
export type UserLanguageQueryHookResult = ReturnType<typeof useUserLanguageQuery>
export type UserLanguageLazyQueryHookResult = ReturnType<typeof useUserLanguageLazyQuery>
export type UserLanguageQueryResult = Apollo.QueryResult<UserLanguageQuery, UserLanguageQueryVariables>
export const SettingsDocument = gql`
  query Settings {
    configuration {
      languageIdList {
        language
        languageName
        languageId
      }
      guiLanguages {
        label
        value
      }
      serverVersion
    }
    userConfiguration {
      contentLanguageCode
      contentLanguageSpecificCulture
      aspectSelectorId
      contentLanguageId
      guiLanguageId
      guiLanguageCode
      editor {
        spellCheckerActive
      }
    }
  }
`

/**
 * __useSettingsQuery__
 *
 * To run a query within a React component, call `useSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSettingsQuery(baseOptions?: Apollo.QueryHookOptions<SettingsQuery, SettingsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SettingsQuery, SettingsQueryVariables>(SettingsDocument, options)
}
export function useSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SettingsQuery, SettingsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SettingsQuery, SettingsQueryVariables>(SettingsDocument, options)
}
export type SettingsQueryHookResult = ReturnType<typeof useSettingsQuery>
export type SettingsLazyQueryHookResult = ReturnType<typeof useSettingsLazyQuery>
export type SettingsQueryResult = Apollo.QueryResult<SettingsQuery, SettingsQueryVariables>
export const SearchSettingsDocument = gql`
  query SearchSettings($languageId: AspectSelectorId!) {
    configuration {
      searchableDataValues(languageId: $languageId) {
        internalName
        displayName
        isUsedByDefault
      }
      searchableAspects
    }
  }
`

/**
 * __useSearchSettingsQuery__
 *
 * To run a query within a React component, call `useSearchSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchSettingsQuery({
 *   variables: {
 *      languageId: // value for 'languageId'
 *   },
 * });
 */
export function useSearchSettingsQuery(
  baseOptions: Apollo.QueryHookOptions<SearchSettingsQuery, SearchSettingsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SearchSettingsQuery, SearchSettingsQueryVariables>(SearchSettingsDocument, options)
}
export function useSearchSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SearchSettingsQuery, SearchSettingsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SearchSettingsQuery, SearchSettingsQueryVariables>(SearchSettingsDocument, options)
}
export type SearchSettingsQueryHookResult = ReturnType<typeof useSearchSettingsQuery>
export type SearchSettingsLazyQueryHookResult = ReturnType<typeof useSearchSettingsLazyQuery>
export type SearchSettingsQueryResult = Apollo.QueryResult<SearchSettingsQuery, SearchSettingsQueryVariables>
export const SetEditorConfigurationDocument = gql`
  mutation SetEditorConfiguration($input: UserEditorConfigurationInput!) {
    setUserEditorConfiguration(input: $input) {
      editor {
        spellCheckerActive
      }
    }
  }
`
export type SetEditorConfigurationMutationFn = Apollo.MutationFunction<
  SetEditorConfigurationMutation,
  SetEditorConfigurationMutationVariables
>

/**
 * __useSetEditorConfigurationMutation__
 *
 * To run a mutation, you first call `useSetEditorConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetEditorConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setEditorConfigurationMutation, { data, loading, error }] = useSetEditorConfigurationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetEditorConfigurationMutation(
  baseOptions?: Apollo.MutationHookOptions<SetEditorConfigurationMutation, SetEditorConfigurationMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SetEditorConfigurationMutation, SetEditorConfigurationMutationVariables>(
    SetEditorConfigurationDocument,
    options,
  )
}
export type SetEditorConfigurationMutationHookResult = ReturnType<typeof useSetEditorConfigurationMutation>
export type SetEditorConfigurationMutationResult = Apollo.MutationResult<SetEditorConfigurationMutation>
export type SetEditorConfigurationMutationOptions = Apollo.BaseMutationOptions<
  SetEditorConfigurationMutation,
  SetEditorConfigurationMutationVariables
>
