import React, { PropsWithChildren, useContext } from "react"
import type { TreeNode } from "../contentModel/nodeContext"

type NodeSelectionContextValue = {
  selectedNode: TreeNode | undefined
  selectNode: (node: TreeNode | undefined) => void
}

const defaultValue: NodeSelectionContextValue = {
  selectedNode: undefined,
  selectNode: () => {},
}

const NodeSelectionContext = React.createContext<NodeSelectionContextValue>(defaultValue)

export function NodeSelectionContextProvider(props: PropsWithChildren<NodeSelectionContextValue>) {
  return <NodeSelectionContext.Provider value={props}>{props.children}</NodeSelectionContext.Provider>
}

export function useNodeSelection() {
  return useContext(NodeSelectionContext)
}
