import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import Text_deprecated from "./Text_deprecated"
import DescriptionText_deprecated from "./DescriptionText_deprecated"

const Caption = styled(Text_deprecated)`
  flex: 3 3 100%;
  border-top: 1px solid ${({ theme }) => theme.separatorColor};
  padding: 0.5em;
  margin-right: 10px;
  text-align: right;
  color: black;
  font-weight: 300;
`
Caption.displayName = "Caption"

const EditorInput = styled.div`
  flex: 1 1 100%;
`
EditorInput.displayName = "EditorInput"

const _LabledInput = ({ className, caption, children, description }) => (
  <div className={className}>
    <Caption>
      {caption}
      {description && (
        <>
          <br /> <DescriptionText_deprecated>{description}</DescriptionText_deprecated>
        </>
      )}
    </Caption>
    <EditorInput>{children}</EditorInput>
  </div>
)

export const LabledInput_deprecated = styled(_LabledInput)`
  padding: 1em;
  margin: 1em;
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  @media (max-width: 500px) {
    margin-left: 0.1em;
    margin-right: 0.1em;
    margin-bottom: 0.5em;
  }
  @media (max-height: 500px) {
    margin-left: 0.1em;
    margin-right: 0.1em;
    margin-bottom: 0.5em;
    flex-basis: calc(100% - 2em);
  }
  flex-basis: calc(50% - 4em);
  @media (min-width: 1280px) {
    flex-basis: calc(33% - 6em);
  }
  min-width: 15em;

  &:focus-within {
    ${Caption} {
      border-top-color: ${({ theme }) => theme.primaryColor};
      font-weight: bold;
    }
  }
`
LabledInput_deprecated.displayName = "LabledInput"
LabledInput_deprecated.propTypes = {
  caption: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
}
