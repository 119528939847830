import React, { useEffect } from "react"
import { useSelectionContext } from "./SelectionContext"
import { ProgressState } from "./types"

function useGlobalKeyboardListener(key: number, callback: () => void) {
  return useEffect(() => {
    const handler = (evt: KeyboardEvent) => {
      evt = evt || window.event
      if (evt.keyCode == key) {
        callback()
      }
    }
    document.addEventListener("keydown", handler)
    return () => document.removeEventListener("keydown", handler)
  }, [key])
}

type UnselectorProps = Record<string, unknown>

/**
 * Adds an eventlistener to the "escape" key which triggers a selection reset.
 * @param param0 The Components Props
 */
export function KeyboardUnselector({ children }: React.PropsWithChildren<UnselectorProps>) {
  const { dispatchUnselect, currentTextSelection } = useSelectionContext()

  useGlobalKeyboardListener(27, () => {
    if (currentTextSelection.progress === ProgressState.Complete) dispatchUnselect()
  })

  return <>{children}</>
}
