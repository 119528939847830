import { useCallback, useRef } from "react"

function getScrollParent(node: (Element & ParentNode) | null): Element {
  const overflowY = node instanceof HTMLElement && window.getComputedStyle(node).overflowY
  const isScrollable = overflowY !== "visible" && overflowY !== "hidden"

  if (!node) {
    return document.body
  } else if (isScrollable && node.scrollHeight >= node.clientHeight) {
    return node
  }

  return getScrollParent(node.parentElement) || document.body
}

/**
 * Scrolls the passed element into a visible area (respects scrollable parents)
 * @param element The element which should be scrolled into visible area
 * @param forceTop Force the scroll to the top of the target element.
 */
export function scrollElementVisible(element: Element, forceTop = false) {
  const boundingRect = element.getBoundingClientRect()
  const scrollParent = getScrollParent(element)
  const parentBoundingRect = scrollParent.getBoundingClientRect()

  const targetFitsIntoView = boundingRect.height <= parentBoundingRect.height

  if (targetFitsIntoView && !forceTop) {
    const elementCenter = boundingRect.top + boundingRect.height * 0.5
    // Element is already in Viewport
    if (elementCenter > parentBoundingRect.top && elementCenter < parentBoundingRect.bottom) return
    const relativeParentCenter = parentBoundingRect.height / 2 - boundingRect.height / 2
    // Scroll the element into the center of the viewport
    scrollParent.scrollBy({ top: elementCenter - relativeParentCenter, behavior: "smooth" })
  } else {
    // Scroll the element to the top of the viewport
    scrollParent.scrollBy({ top: boundingRect.top - parentBoundingRect.top, behavior: "smooth" })
  }
}

/**
 * This hook can be used to scroll an element into the visible area.
 * If the element is already visible, nothing will happen.
 * @param condition The condition to trigger the scrolling. If it is true, the element will be scrolled into view.
 * @example
 * const [shouldCenter, setShouldCenter] = useState(false)
 * const [elementRef, scrollToElement] = useScrollElementVisible(shouldCenter)
 * useEffect(()=>{
 *  if(shouldCenter) scrollToElement()
 * },[shouldCenter])
 * return (
 *   <div>
 *     <p ref={elementRef}> myContent </p>
 *     <button onPress={()=>setShouldCenter(true)}>Center Text in View</button>
 *   </div>
 * )
 * @returns An React.Ref to be used for the Element to center
 */
export function useScrollElementVisible<T extends HTMLElement = HTMLElement>() {
  const elementRef = useRef<T>(null)
  const scrollTo = useCallback((forceTop = false) => {
    if (!elementRef.current) return
    scrollElementVisible(elementRef.current, forceTop)
  }, [])
  return [elementRef, scrollTo] as [typeof elementRef, typeof scrollTo]
}
