import React from "react"
import styled from "styled-components"
import { usePreviewContentModel } from "../../contentModel"
import { useNode } from "../../contentModel/nodeContext"
import { isST4NodeWithContent } from "../../graphql/applicationQueries"
import type { ST4NodeWithContent } from "../../graphql/applicationQueries"
import type { PreviewContentModel } from "../../contentModel/types"
import { Tooltip } from "antd"
import { useTranslation } from "react-i18next"
import { keys } from "@st4/ui-strings"
import { useSt4ToTreeNode } from "../../utilities/treeUtilities"
import { FilteringTreeNodeWrapper } from "../FilteringTreeNodeWrapper"
import type { Node } from "unist"
import { isTypename } from "@st4/graphql"

type VariableProps = {
  ast: Node
}

type VariableRef = {
  linkid: { name: "linkid"; value: string }
  name: { name: "name"; value: string }
  variant?: { name: "variant"; value: string }
}

const StyledVariable = styled.span`
  color: ${({ theme }) => theme.preview.variableColor};
`

type VariableTooltipProps = {
  title: string
  id: string
}

export function VariableTooltip(props: React.PropsWithChildren<VariableTooltipProps>) {
  const { t } = useTranslation()
  const { children, title, id } = props
  const tooltipText = (
    <>
      <div>{t(keys.label.general.title) + ": " + title}</div>
      <div>{t(keys.label.general.id) + ": " + id}</div>
    </>
  )
  return <Tooltip title={tooltipText}>{children}</Tooltip>
}

export function Variable(props: VariableProps) {
  const node = useNode()
  const previewContentModel = usePreviewContentModel()
  const attributes = props.ast.attributes as VariableRef
  const variableTableNode = getVariableNode(attributes, node)
  const st4ToTreeNode = useSt4ToTreeNode()

  if (!isTypename("ST4Node")(variableTableNode)) {
    return <span style={{ backgroundColor: "red", color: "white" }}></span>
  }
  const treeNode = st4ToTreeNode(variableTableNode)
  const varInfo = getVariableInfo(attributes, variableTableNode, previewContentModel)

  if (isError(varInfo)) {
    return <span style={{ backgroundColor: "red", color: "white" }}>{varInfo.error}</span>
  }
  const { label, tableId, tableTitle } = varInfo
  return (
    <FilteringTreeNodeWrapper treeNodeId={treeNode?.id} isInline>
      <VariableTooltip title={tableTitle ?? ""} id={tableId}>
        <StyledVariable>{label}</StyledVariable>
      </VariableTooltip>
    </FilteringTreeNodeWrapper>
  )
}

function isError(varInfo: VariableInfo): varInfo is { error: string } {
  return Object.hasOwnProperty.call(varInfo, "error")
}

type VariableInfo = { error: string } | { label: string; tableId: string; tableTitle?: string | null }

function getVariableNode(attributes: VariableRef, parentNode: ST4NodeWithContent) {
  const tableReferences = isST4NodeWithContent("TextContent", "TextGroupContent")(parentNode)
    ? parentNode.content.variableTables
    : []
  const ref = tableReferences?.find((r) => r?.ref === attributes.linkid.value)
  return ref?.node
}

function getVariableInfo(
  attributes: VariableRef,
  variableTableNode: ST4NodeWithContent | undefined,
  previewContentModel: PreviewContentModel,
): VariableInfo {
  const tableId = variableTableNode?.id
  if (!tableId) {
    return { error: "Variable not found!" }
  }
  const tableTitle = variableTableNode.label
  const idText = tableId.slice(tableId.indexOf(":") + 1)
  const tableSource = previewContentModel.state === "ready" ? previewContentModel.variableTableById.get(tableId) : null
  if (!tableSource) {
    return { label: "loading...", tableId: idText, tableTitle: tableTitle }
  }
  const variant = attributes.variant
  if (attributes.name.value === "0") {
    if (!variant) {
      return { error: "Invalid variable reference!" }
    }
    const header = tableSource.columns.find((c) => c.dataIndex === variant.value)
    if (!header) {
      return { error: "Invalid variable reference!" }
    }
    return { label: header.title, tableId: idText, tableTitle: tableTitle }
  }

  const entryRecord = tableSource.dataSource[tableSource.rows.findIndex((r) => r.id === attributes.name.value)]
  if (!entryRecord) {
    return { error: "Variable not found!" }
  }

  if (!variant) {
    //Variable(Erster Wert der Zeile)
    const column = tableSource.columns[1]
    const entry = entryRecord[column.dataIndex]
    return { label: entry.value, tableId: idText, tableTitle: tableTitle }
  } else if (variant.value === "0") {
    //Variablenname(Zeile)
    return { label: tableSource.rows[entryRecord.rowTitle.rowIndex].title, tableId: idText, tableTitle: tableTitle }
  } else {
    //Zelle(Sowohl name(Zeile) als auch Wertemenge(Spalte) stehen mit ihrer ID drin)
    const column = tableSource.columns.find((c) => c.dataIndex === variant.value)
    if (!column) {
      return { error: "Invalid variable reference!" }
    }
    const entry = entryRecord[column.dataIndex]
    return { label: entry.value, tableId: idText, tableTitle: tableTitle }
  }
}
