import { TaxonomyDisplayInfo, TreeNode } from "./types"

export function assureOrderMatchesTreeOrder(
  values: TaxonomyDisplayInfo[],
  taxonomyTree: TreeNode[] | undefined,
): TaxonomyDisplayInfo[] {
  if (!taxonomyTree) return values
  if (values.length < 2) return values

  const orderedValues: TaxonomyDisplayInfo[] = []

  const flattenedTree: TreeNode[] = flattenTree(taxonomyTree)
  flattenedTree.forEach((treeNode) => {
    const foundValue = values.find((value) => value.value === treeNode.value)
    if (foundValue !== undefined) orderedValues.push(foundValue)
  })

  return orderedValues
}

function flattenTree(rootNodes: TreeNode[], allNodes: TreeNode[] = []): TreeNode[] {
  rootNodes.forEach((treeNode) => {
    allNodes.push(treeNode)
    if (treeNode.children) flattenTree(treeNode.children, allNodes)
  })
  return allNodes
}
