import { useAspectId } from "@st4/settings"
import { useFixedMetaRefsQuery, useMetaRefsWithValuesQuery } from "../query.hooks"
import {
  DynamicMetaRefType,
  MetaRefElement,
  OntologyMetaRefType,
  OntologyMetaRefValueType,
  StaticMetaRefValueType,
} from "../types"
import { isTypename } from "@st4/graphql"

type MetaRefDataProviderProps = {
  selectedNode?: {
    id: string
    classHierarchy: string[]
  }
  isDynamicSelected: boolean
}

type MetaRefDataType = {
  loading: boolean
  metaRefs?: MetaRefElement[]
} | null

export default function useGetMetaRefData(props: MetaRefDataProviderProps): MetaRefDataType {
  const aspectId = useAspectId()
  const { selectedNode, isDynamicSelected } = props

  const { data: fixedData, loading: fixedLoading } = useFixedMetaRefsQuery({
    variables: {
      aspectId: aspectId,
    },
  })

  const { data, loading } = useMetaRefsWithValuesQuery({
    variables: {
      selectedNode: selectedNode?.id ?? "",
      aspectId: aspectId,
      variant: "Preview",
    },
    fetchPolicy: "network-only",
    skip: !selectedNode?.id && !isDynamicSelected,
    errorPolicy: "ignore",
  })

  if (loading || fixedLoading) return { loading: true }

  if (isDynamicSelected) {
    return {
      loading: false,
      metaRefs: getDynamicMetaData(fixedData?.configuration.metaRefPaths as DynamicMetaRefType[]),
    }
  }

  if (!data) return null
  if (!selectedNode?.classHierarchy.find((x) => x === "DataNode")) return null
  if (!isTypename("ST4TreeNode")(data?.node)) return null
  if (!isTypename("ST4Node")(data?.node?.node)) return null

  const treeNodeId = data.node.id

  const ontologyMetaRefs = fixedData?.configuration.ontologyLinkClasses as OntologyMetaRefType[]
  const ontologyMetaRefsWithValue = data.node.node.leavingLinks as OntologyMetaRefValueType[]
  const staticMetaRefs = data.node.node.metadata as StaticMetaRefValueType[]
  return {
    loading: false,
    metaRefs: [
      ...getOntologyMetaData(ontologyMetaRefs, ontologyMetaRefsWithValue, treeNodeId),
      ...getStaticMetaData(staticMetaRefs, treeNodeId),
    ],
  }
}

function getDynamicMetaData(data: DynamicMetaRefType[]): MetaRefElement[] {
  const metaRefs = data.map((metaRef) => {
    return {
      type: "dynamic",
      name: metaRef.id,
      label: metaRef.displayName ?? "",
    }
  })

  return metaRefs
}

function getStaticMetaData(data: StaticMetaRefValueType[], linkId: string): MetaRefElement[] {
  const metaRefs = data.map((metaRef) => {
    return {
      type: "static",
      linkId: linkId,
      name: metaRef.systemName ?? "",
      label: metaRef.label ?? "",
      metaData: [metaRef],
    }
  })

  return metaRefs
}

function getOntologyMetaData(
  ontologyClasses: OntologyMetaRefType[],
  data: OntologyMetaRefValueType[],
  linkId: string,
): MetaRefElement[] {
  const metaRefs: MetaRefElement[] = ontologyClasses.map((linkClass) => {
    return {
      type: "ontology",
      linkId: linkId,
      name: linkClass.className,
      label: linkClass.label ?? "",
      metaData: [],
    }
  })

  data.forEach((metaRef) => {
    const foundOntologyMetaRef = metaRefs.find((x) => x.name === metaRef.linkClassName)
    if (foundOntologyMetaRef) {
      foundOntologyMetaRef.metaData = [...(foundOntologyMetaRef.metaData ?? []), metaRef]
    }
  })

  return metaRefs
}
