import * as React from "react"
import { SVGProps } from "react"

const SvgArrowRightToArc = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1em" height="1em" {...props}>
    <path d="M464 256c0-114.9-93.1-208-208-208-13.3 0-24-10.7-24-24s10.7-24 24-24c141.4 0 256 114.6 256 256S397.4 512 256 512c-13.3 0-24-10.7-24-24s10.7-24 24-24c114.9 0 208-93.1 208-208zM232.3 134.4l112 104c4.9 4.5 7.7 10.9 7.7 17.6s-2.8 13-7.7 17.6l-112 104c-9.7 9-24.9 8.5-33.9-1.3s-8.5-24.9 1.3-33.9l67.2-62.4H24c-13.3 0-24-10.7-24-24s10.7-24 24-24h242.9l-67.2-62.4c-9.7-9-10.3-24.2-1.3-33.9s24.2-10.3 33.9-1.3z" />
  </svg>
)

export default SvgArrowRightToArc
