import { ReactNode } from "react"
import { z } from "zod"
import { BaseScreenDefinition, GenericScreenDefitinition, screenDataSchema } from "./screen"
import {
  GenericTransitionFunction,
  getKeyParts,
  GetTransitionScreens,
  SimplifiedTransitionKey,
  TransitionFunction,
  TransitionKey,
} from "./transition"
import { TransitionOptions } from "./transitionOptions"

const TRANSITIONS_SYMBOL = Symbol("ScopeTransitions")

type TransitionsMap<TTransitions extends TransitionKey> = {
  [k in Exclude<GetTransitionScreens<TTransitions>, "start" | "end">]: BaseScreenDefinition
}

//Fehleranfällig, da der Typ `ScopeDefinition` hier noch mal dupliziert wurde!
//Sollte sich eine Änderung in einem Typen ergeben, muss dieser hier nachgezogen werden.
export type GenericScopeDefinition = {
  displayName: ReactNode
  transitions: Record<TransitionKey, TransitionOptions>
  screens: Record<string, GenericScreenDefitinition>
  [TRANSITIONS_SYMBOL]: Record<SimplifiedTransitionKey<TransitionKey>, any>
}

/**
 * The definition of a scope.
 */
export type ScopeDefinition<
  TTransitionKeys extends TransitionKey = TransitionKey,
  TScreens extends TransitionsMap<TTransitionKeys> = TransitionsMap<TTransitionKeys>,
> = {
  /**
   * The display name of the scope.
   */
  displayName: ReactNode
  /**
   * A map of transitions and their options.
   *
   * The key is a string representing the possible transition, following the pattern
   * `from-type->to`.
   *
   * - `from` and `to` are the names of screens inside this scope.
   * - `type` is optional and can be used to differentiate between multiple transitions with equal `from` and `to`.
   *
   * @example
   * {
   *   "blade1-->blade2": { type: "button", ... },
   *   "blade2-save->blade1": { type: "button", label: "Save", ... },
   *   "blade2-abort->blade1": { type: "button", label: "Abort", ... }
   * }
   */
  transitions: { [k in TTransitionKeys]: TransitionOptions }
  /**
   * A map of all screens inside this scope.
   *
   * The key is the name of the screen (as used inside the transition keys) an the value the screen definition.
   */
  screens: TScreens
  /**
   * @private
   * Contains all transition functions defined for this scope. Add them by using `{@link defineTransition}`
   */
  [TRANSITIONS_SYMBOL]: Record<SimplifiedTransitionKey<TTransitionKeys>, any>
}

export function getTransition(scope: GenericScopeDefinition, transition: TransitionKey): GenericTransitionFunction {
  const keyParts = getKeyParts(transition)
  const transitionfn = scope[TRANSITIONS_SYMBOL][`${keyParts.from}-->${keyParts.to}`]
  if (transitionfn) return transitionfn
  const fn: GenericTransitionFunction = async (context) => {
    return context.scopeData.screens[keyParts.to] ?? context.to.initialContextValues
  }
  return fn
}

export const scopeDataSchema = z.object({
  currentScreen: z.string(),
  screens: z.record(screenDataSchema),
})

export type ScopeData<TScope extends ScopeDefinition | undefined = undefined> = TScope extends ScopeDefinition
  ? z.infer<typeof scopeDataSchema> & {
      screens: { [k in keyof TScope["screens"]]: TScope["screens"][k]["initialContextValues"] }
    }
  : z.infer<typeof scopeDataSchema>

/**
 * This function is used to define a scope.
 * @param definition The scope definition
 */
export function defineScope<
  TTransitionKeys extends TransitionKey,
  TScreens extends TransitionsMap<TTransitionKeys> = TransitionsMap<TTransitionKeys>,
>(definition: Omit<ScopeDefinition<TTransitionKeys, TScreens>, typeof TRANSITIONS_SYMBOL>) {
  return { ...definition, [TRANSITIONS_SYMBOL]: {} }
}

/**
 * This function is used to define a transition and attach it to the scope.
 * @param scope The scope to wich the transition should be attached
 * @param transition The id of the transition only with the `from` and `to` parts of the key (`${from}-->${to}`).
 * The `type` gets passed into your function.
 * @param fn The transition function.
 */
export function defineTransition<
  TScope extends ScopeDefinition,
  TTransition extends SimplifiedTransitionKey<Extract<keyof TScope["transitions"], TransitionKey>>,
>(scope: TScope, transition: TTransition, fn: TransitionFunction<TScope, TTransition>) {
  const transitions = scope[TRANSITIONS_SYMBOL]
  const keyParts = getKeyParts(transition)
  transitions[`${keyParts.from}-->${keyParts.to}`] = fn
  return fn
}
