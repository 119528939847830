import * as React from "react"
import { SVGProps } from "react"

const SvgSt4IntegratefragmentRegular = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1em" height="1em" {...props}>
    <path d="M198.7 464.1h-101c-8.8 0-16-7.2-16-16V64c0-8.8 7.2-16 16-16h160v80c0 17.7 14.3 32 32 32h80v45.7c16.9 2.2 33 6.8 48 13.6v-64.7c0-17-6.7-33.3-18.7-45.3l-90.6-90.5C296.4 6.7 280.2 0 263.2 0H97.7c-35.3 0-64 28.7-64 64v384.1c0 35.3 28.7 64 64 64h143c-16.8-13.3-31-29.5-42-48zm-22.9-87.5c0-31.8 8.7-61.5 23.7-87l-60.2-21.3 87.5-30.9 13.9 4.9c10.7-8.5 22.4-15.7 35-21.5l-38.6-14.2c-5.2-2.6-12.9-2.6-20.6 0L118.8 240c-12.9 5.2-20.6 18-20.6 30.9v123.6c0 12.8 7.7 25.7 20.6 33.4l76.5 28.2c-12.4-23.8-19.5-50.8-19.5-79.5zm76.9-92.7c-52.2 52.2-52.2 136.8 0 188.9 52.2 52.2 136.8 52.2 188.9 0s52.2-136.8 0-188.9-136.7-52.2-188.9 0zm152.5 54.3L365 378.4l40.2 40.2c4.9 4.9 4.9 12.9 0 17.8s-12.9 4.9-17.8 0l-40.2-40.2-40.2 40.2c-4.9 4.9-12.9 4.9-17.8 0s-4.9-12.9 0-17.8l40.2-40.2-40.2-40.2c-4.9-4.9-4.9-12.9 0-17.8s12.9-4.9 17.8 0l40.2 40.2 40.2-40.2c4.9-4.9 12.9-4.9 17.8 0s4.9 12.9 0 17.8z" />
  </svg>
)

export default SvgSt4IntegratefragmentRegular
