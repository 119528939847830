import React from "react"
import styled from "styled-components"
import Dropdown from "./dropdown"
import PropTypes from "prop-types"

const Navbar = (props) => (
  <nav className={props.className}>
    <ul>
      {props.items.map((item) => (
        <li key={item.label}>
          {item.items ? (
            <Dropdown items={item.items} label={item.label} />
          ) : item.href ? (
            <a href={item.href}>{item.label}</a>
          ) : item.component ? (
            React.createElement(item.component, {}, [])
          ) : null}
        </li>
      ))}
    </ul>
  </nav>
)

Navbar.displayName = "Navbar"
Navbar.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.oneOfType([
      /* dropdown */
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        items: PropTypes.array.isRequired,
      }),
      /* Hyperlink */
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        href: PropTypes.string.isRequired,
      }),
      /* component */
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        component: PropTypes.oneOf([PropTypes.string, PropTypes.element]),
      }),
    ]),
  ),
}

const StyledNavbar = styled(Navbar)`
  & > ul {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  & > ul > li {
    display: flex;
  }

  & > ul > li:nth-last-child(2) {
    flex: 1;
    text-align: right;
  }

  a:link,
  a:hover,
  a:visited,
  a:hover {
    text-decoration: none;
    color: white;
  }
`
StyledNavbar.displayName = "StyledNavbar"
StyledNavbar.propTypes = Navbar.propTypes
export default StyledNavbar
export { default as LinkWithIcon } from "./linkWithIcon"
