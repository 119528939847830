import classNames from "classnames"
import React from "react"
import * as R from "ramda"
import styled, { css } from "styled-components"
import PropTypes from "prop-types"
import ThemeManager, { boxedInputStyles } from "@st4/theme-manager"
import { lighten, modularScale } from "polished"

const textareaAttrs = ["onChange", "name", "type"]

// prettier-ignore
const Textarea_deprecated = styled(function(props) {
  return (
    <label className={classNames(props.className, "Textarea", {'invalid':props.invalidInput})}>
      <textarea
        className="Textarea--textarea"
        {...R.pick(textareaAttrs)(props)}
        defaultValue={props.defaultValue._data}
        rows="5"
        disabled={props.readonly}
        pattern={props.pattern}
        placeholder={props.readonly ? "" : props.placeholder}
      />
      <span className="Textarea--label">{props.label}</span>
    </label>
  )
})`
  display: block;
  flex: 1;
  & > span {
    opacity: 0.7;
    display: block;
    font-size: ${modularScale(-1)};
    transform: translateY(-50px);
    transition: all 0.2s cubic-bezier(0.3, -0.07, 0.77, 1.2);
  }

  & > textarea {
    resize: vertical;
    border: none;
    width: 100%;
    height: 5em;
    ${props =>
      props.accented
        ? boxedInputStyles
        : css`
            border-bottom: 1px solid #555;
            background-color: transparent;
          `}
  }

  & > textarea:focus {
    outline: none;
    border: 1px solid ${props => (props.accented ? props.theme.primaryColor : "transparent")};
    ${props => (props.accented ? "" : "border-bottom: 1px solid #555")};
    ${props => props.accented && "box-shadow: 0 0 2px " + props.theme.primaryColor + ";"}
  }

  textarea:valid + span,
  textarea:disabled + span {
    opacity: 1;
    transform: translateY(-110px);
  }
  
  &.invalid textarea,
  textarea:invalid {
    border: 1px solid ${props => props.theme.warningColor};
    box-shadow: 0 0 2px ${props => props.theme.warningColor};
    outline: none;
  }

  textarea:disabled {
    background-color: initial;
  }

  textarea:focus + span {
    transform: translateY(-110px);
    opacity: 1;
  }
`

Textarea_deprecated.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
  readonly: PropTypes.bool,
  pattern: PropTypes.string,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.shape({
    _data: PropTypes.string,
    _display: PropTypes.string,
  }),
  label: PropTypes.string,
}

export default Textarea_deprecated
