import type { Content } from "./contentComponents/types"
import type { ComponentType } from "react"

//Standard components are those that have been initially registered by the preview
//content components can be modified to customize the preview
let STANDARD_COMPONENTS = new Map<string, ComponentType<any>>()
let CONTENT_COMPONENTS = new Map<string, ComponentType<any>>()

export function setStandardComponents(components: Content) {
  STANDARD_COMPONENTS = new Map(Object.entries(components))
  CONTENT_COMPONENTS = STANDARD_COMPONENTS
}

export function overrideComponent(name: string, component: ComponentType<any>) {
  CONTENT_COMPONENTS.set(name, component)
}

export function registerComponent(name: string, component: ComponentType<any>) {
  if (STANDARD_COMPONENTS && Object.keys(STANDARD_COMPONENTS).find((n) => n === name)) {
    throw `Error: Trying to override standard component ${name}`
  } else {
    return CONTENT_COMPONENTS.set(name, component)
  }
}

export function getComponent(name: string): ComponentType<any> | null {
  return CONTENT_COMPONENTS.get(name) || null
}

export function revertComponent(name: string) {
  const cmp = STANDARD_COMPONENTS.get(name)
  if (cmp) {
    return CONTENT_COMPONENTS.set(name, cmp)
  } else {
    throw `Error: There is no standard component with name ${name}`
  }
}

//Only for testing
export function clearComponentsForTesting() {
  STANDARD_COMPONENTS.clear()
  CONTENT_COMPONENTS.clear()
}
