import * as React from "react"
import { SVGProps } from "react"

const SvgSt4InsertimagetitleRegular = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1em" height="1em" {...props}>
    <path d="M224 352v128h256V352H224zm-32 0c0-17.6 14.4-32 32-32h256c17.6 0 32 14.4 32 32v128c0 17.6-14.4 32-32 32H224c-17.6 0-32-14.4-32-32V352zm92 16c4.8 0 9 2.8 11 7.2l32 72c2.7 6.1-.1 13.1-6.1 15.9-6 2.7-13.1 0-15.8-6.1l-2.2-4.9h-37.7l-2.1 4.9c-2.7 6.1-9.8 8.8-15.9 6.1-6-2.7-8.8-9.8-6.1-15.9l32-72c1.9-4.4 6.1-7.2 10.9-7.2zm0 41.6-8.2 18.4h16.4l-8.2-18.4zm60-29.6c0-6.6 5.4-12 12-12h26c16.5 0 30 13.5 30 30 0 4.6-1 9-2.9 12.9 6.6 5.5 10.9 13.8 10.9 23.1 0 16.6-13.5 30-30 30h-34c-6.6 0-12-5.4-12-12v-72zm24 12v12h14c3.3 0 6-2.7 6-6s-2.7-6-6-6h-14zm0 48h22c3.3 0 6-2.7 6-6s-2.7-6-6-6h-22v12zM144 128.5a48 48 0 1 0 0 96 48 48 0 1 0 0-96zm1.5 191.2c0-11.8 4.4-22.5 11.7-30.6-1.7-.4-3.4-.6-5.2-.6-7.8 0-15 3.7-19.5 10.1l-80 112-4.5 6.2V96.5c0-8.8 7.2-16 16-16h384c8.8 0 16 7.2 16 16v177.2h48V96.5c0-35.3-28.7-64-64-64H64c-35.3 0-64 28.7-64 64v320c0 35.3 28.7 64 64 64h81.5V319.7zM304 224.5c-7.4 0-14.4 3.4-19 9.3l-30.8 39.9h99.7l-30.8-39.9c-4.6-5.9-11.7-9.3-19.1-9.3z" />
  </svg>
)

export default SvgSt4InsertimagetitleRegular
