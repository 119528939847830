import React, { createContext, useContext } from "react"

export type NodeDisplay = {
  visible: boolean
}

export type PreviewConfigContextValues = {
  /**
   * Sets a mode for the preview.
   *
   * Supported modes are:
   *
   * - `"REVIEW"`: The preview will be filtered to show only nodes, relevant for a review and will change the layout to be more "readable"
   * - `"CARDS"`: The preview will show all nodes and display them in cards
   */
  mode: "REVIEW" | "CARDS"
  /**
   * If this value is true, the sidebar containing the comments and node information can be collapsed.
   */
  collapsibleComments?: boolean
  /**
   * If this value is true, the sidebar containing the comments is by default collapsed
   */
  initialCommentsCollapsed?: boolean
  /**
   * If this value is true, the metadata drawer is disabled
   */
  disableMetadataDrawer: boolean
  /**
   * Allows filtering out content based on treeNodeId.
   * Currently Supports Root nodes, all fragments and images
   * True means keep the node
   */
  getNodeDisplay: (treeNodeId: string) => NodeDisplay
}

const defaultValue: PreviewConfigContextValues = {
  mode: "REVIEW",
  collapsibleComments: false,
  initialCommentsCollapsed: false,
  disableMetadataDrawer: false,
  getNodeDisplay: () => ({ visible: true }),
}

const PreviewConfigContext = createContext<PreviewConfigContextValues>(defaultValue)

/**
 * Provides configuration settings for the Preview.
 */
export function usePreviewConfig() {
  return useContext(PreviewConfigContext)
}

export function PreviewConfigContextProvider(props: React.PropsWithChildren<PreviewConfigContextValues>) {
  return <PreviewConfigContext.Provider value={props}>{props.children}</PreviewConfigContext.Provider>
}
