import { isTypename } from "@st4/graphql";
import { getContentInfosFromImage } from "./images";
import { getLinkInfo } from "./links";
import { getVariableInfo } from "./variables";
export function getMetadata(textContent, metaData) {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9;
    if (!textContent) return;
    if (!isTypename("TextContent")((ref = textContent.contentNode) === null || ref === void 0 ? void 0 : ref.content) && !isTypename("TextGroupContent")((ref1 = textContent.contentNode) === null || ref1 === void 0 ? void 0 : ref1.content)) return;
    var images = (ref3 = textContent === null || textContent === void 0 ? void 0 : (ref2 = textContent.contentNode) === null || ref2 === void 0 ? void 0 : ref2.content.images) === null || ref3 === void 0 ? void 0 : ref3.map(function(image) {
        return getContentInfosFromImage(image);
    }).filter(function(img) {
        return img;
    });
    (ref5 = textContent === null || textContent === void 0 ? void 0 : (ref4 = textContent.contentNode) === null || ref4 === void 0 ? void 0 : ref4.content.variableTables) === null || ref5 === void 0 ? void 0 : ref5.map(function(variable) {
        getVariableInfo(variable, metaData);
    });
    (ref7 = textContent === null || textContent === void 0 ? void 0 : (ref6 = textContent.contentNode) === null || ref6 === void 0 ? void 0 : ref6.content.links) === null || ref7 === void 0 ? void 0 : ref7.map(function(link) {
        getLinkInfo(link, metaData);
    });
    (ref9 = textContent === null || textContent === void 0 ? void 0 : (ref8 = textContent.contentNode) === null || ref8 === void 0 ? void 0 : ref8.content.linkedDataNodes) === null || ref9 === void 0 ? void 0 : ref9.map(function(linkedDataNode) {
        metaData[linkedDataNode.id] = JSON.stringify(linkedDataNode);
    });
    return {
        content: textContent,
        contentImageInfos: images
    };
}
export function getMetadataFromNode(node) {
    var ref, ref1, ref2, ref3;
    if (!node) return;
    if (!isTypename("TextContent")(node.content) && !isTypename("TextGroupContent")(node.content)) return;
    var metaData = {};
    var images = node === null || node === void 0 ? void 0 : (ref = node.content) === null || ref === void 0 ? void 0 : ref.images.map(function(image) {
        return getContentInfosFromImage(image);
    }).filter(function(img) {
        return img;
    });
    (ref1 = node.content.variableTables) === null || ref1 === void 0 ? void 0 : ref1.map(function(variable) {
        getVariableInfo(variable, metaData);
    });
    (ref2 = node.content.links) === null || ref2 === void 0 ? void 0 : ref2.map(function(link) {
        getLinkInfo(link, metaData);
    });
    (ref3 = node.content.linkedDataNodes) === null || ref3 === void 0 ? void 0 : ref3.map(function(linkedDataNode) {
        metaData[linkedDataNode.id] = JSON.stringify(linkedDataNode);
    });
    return {
        content: node.content,
        contentImageInfos: images,
        contentMetaData: metaData
    };
}
