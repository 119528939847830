import { flattenTree } from "../ast"
import { is } from "unist-util-is"
import React, { useReducer } from "react"

import { TextSelection, SelectionData, ProgressState } from "./types"
import type { Node } from "unist"
import { usePreviewContentModel } from "../contentModel"
import { PreviewContentModel } from "../contentModel/types"

type ActionWithData = { type: "selectionStart" | "selectionChange"; data: SelectionData; selectionAllowed: boolean }
type ActionWithoutData = { type: "selectionEnd" | "selectionUnselect" }

type Action = (ActionWithData | ActionWithoutData) & {
  previewContentModel: PreviewContentModel
}

function getBoundingRect(marker: React.RefObject<HTMLElement>) {
  return marker.current ? marker.current.getBoundingClientRect() : null
}

export function useSelectionReducer() {
  const previewContentModel = usePreviewContentModel()
  const [currentTextSelection, dispatch] = useReducer(selectionHighlightReducer, {
    progress: ProgressState.NoSelection,
  })
  const start = (
    xastNode: Node,
    contentNode: SelectionData["contentNode"],
    marker: React.RefObject<HTMLElement>,
    selectionAllowed: boolean,
    offset: number,
  ) =>
    dispatch({
      type: "selectionStart",
      data: { node: xastNode, contentNode, marker, offset, boundingRect: null },
      selectionAllowed,
      previewContentModel,
    })
  const end = () => dispatch({ type: "selectionEnd", previewContentModel })
  const change = (
    xastNode: Node,
    contentNode: SelectionData["contentNode"],
    marker: React.RefObject<HTMLElement>,
    selectionAllowed: boolean,
    offset: number,
  ) => {
    return dispatch({
      type: "selectionChange",
      data: {
        node: xastNode,
        contentNode,
        marker,
        offset,
        boundingRect: getBoundingRect(marker),
      },
      selectionAllowed,
      previewContentModel,
    })
  }
  const unselect = () => dispatch({ type: "selectionUnselect", previewContentModel })
  return [currentTextSelection, start, end, change, unselect] as [
    typeof currentTextSelection,
    typeof start,
    typeof end,
    typeof change,
    typeof unselect,
  ]
}

function selectionHighlightReducer(state: TextSelection, action: Action): TextSelection {
  const { previewContentModel } = action
  switch (action.type) {
    case "selectionStart": {
      const { contentNode } = action.data
      return {
        origin: {
          node: action.data.node,
          contentNode,
          marker: action.data.marker,
          offset: action.data.offset,
          boundingRect: null,
        },
        start: {
          node: action.data.node,
          contentNode,
          marker: action.data.marker,
          offset: action.data.offset,
          boundingRect: null,
        },
        end: {
          node: action.data.node,
          contentNode,
          marker: action.data.marker,
          offset: action.data.offset,
          boundingRect: null,
        },
        selectionAllowed: action.selectionAllowed,
        progress: ProgressState.Ongoing,
        selectionDirection: "forward",
      }
    }

    case "selectionEnd": {
      if (state.progress !== ProgressState.Ongoing) return state
      const selectionIsEmpty =
        state.end.node.position === state.start.node.position && state.end.offset === state.start.offset
      if (selectionIsEmpty) return { progress: ProgressState.NoSelection }
      return { ...state, progress: ProgressState.Complete }
    }
    case "selectionChange": {
      if (state.progress !== ProgressState.Ongoing)
        throw new Error("selectionChange fired without preceding selectionStart.")
      if (!action.data.offset || !state.origin.offset)
        throw new Error("cannot change the selection, data.offset or state.origin.offset are missing.")
      //if (!state.content || !state.content.length || !state.content[0].content) throw new Error("No content to select")
      const { contentNode } = action.data
      if (previewContentModel?.state !== "ready") return state
      const xast = previewContentModel.xastById.get(contentNode.id)
      if (!xast) return state
      const nodeList = flattenTree(xast)
      const positionInList = nodeList.findIndex((nd) => is(nd, action.data.node))
      const { node } = state.origin
      const originPosition = nodeList.findIndex((nd) => is(nd, node))
      let currentValue = positionInList
      let originValue = originPosition
      if (originPosition === positionInList) {
        currentValue = action.data.offset
        originValue = state.origin.offset
      }

      if (originValue < currentValue) {
        return {
          ...state,
          selectionDirection: "forward",
          start: state.origin,
          end: {
            node: action.data.node,
            contentNode,
            marker: action.data.marker,
            offset: action.data.offset,
            boundingRect: action.data.boundingRect,
          },

          selectionAllowed: action.selectionAllowed,
        }
      } else {
        return {
          ...state,
          selectionDirection: "backward",
          end: state.origin,
          start: {
            node: action.data.node,
            contentNode,
            marker: action.data.marker,
            offset: action.data.offset,
            boundingRect: action.data.boundingRect,
          },

          selectionAllowed: action.selectionAllowed,
        }
      }
    }
    case "selectionUnselect":
      if (state.progress == ProgressState.NoSelection)
        throw new Error("selectionUnselect fired without preceding selection.")

      return {
        progress: ProgressState.NoSelection,
      }
    default:
      throw new Error("Unknown Action.")
  }
}
