import { getNewCommentNode, InsertNewCommentNodeIntoContent, insertNewComment } from "./utilities"
import type {
  ReplyToCommentMutation,
  ReplyToCommentMutationHookResult,
  ReplyToCommentMutationResult,
} from "./query.hooks"
import { useReplyToCommentMutation } from "./query.hooks"
import type { AddReplyCommentInput, ExtractByTypeName } from "@st4/graphql"
import { isTypename } from "@st4/graphql"
import { TextContentForCommentFragment, TextContentForCommentFragmentDoc } from "../CommentList/query.hooks"

/**
 * Creates an optimistic result for the addInlineComment mutation based on its input.
 * @param commentToAdd the input to the comment add location
 * @param node the node to which the comment is added
 */
function createOptimisticResult(commentToAdd: AddReplyCommentInput) {
  const newComment: ExtractByTypeName<"CommentSuccess", ReplyToCommentMutation["comment"]>["result"] = {
    authorDisplayName: commentToAdd.username,
    value: commentToAdd.commentText,
    editDate: new Date(Date.now()).toISOString(),
    commentKey: `${commentToAdd.username}_99`, //placeholder... TODO: CorrectValue?
    node: {
      id: commentToAdd.nodeId,
      aspectId: commentToAdd.aspectSelectorId,
      __typename: "ST4Node" as const,
      content: {
        __typename: "TextContent" as const,
        id: commentToAdd.nodeId,
        historyNumber: commentToAdd.historyNumber,
      },
    },
    authorUserName: commentToAdd.username,
    color: "",
    commentType: commentToAdd.commentType || null,
    location: 0, //Replies are block level comments and contain the same location as their parent
    modificationVersion: commentToAdd.historyNumber, //TODO: ist das korrekt?
    sortOrder: 0,
    replyToKey: commentToAdd.parentKey,
    state: null,
    actions: [],
    persistanceAction: "ADD" as const,
    persistanceState: "LOCAL" as const,
    __typename: "BlockComment" as const,
  }

  const result = {
    comment: {
      result: newComment,
      __typename: "CommentSuccess" as const,
    },
    __typename: "Mutation" as const,
  }
  return result
}

/**
 * Returns the mutation which is used to a new reply to a comment.
 * Additionaly to executing the result, an optimistic response is saved in the apollo cache.
 *
 * @returns Returns an array
 * The first entry is the funciton wich executes the addReplyToComment mutation
 * The second entry is the addReplyToComment mutation result
 */
export function useAddCommentReplyAndSaveLocal(): [
  (input: AddReplyCommentInput) => ReturnType<ReplyToCommentMutationHookResult[0]>,
  ReplyToCommentMutationResult,
] {
  const [mutation, result] = useReplyToCommentMutation()
  const patchedMutation = (input: AddReplyCommentInput) => {
    const optimisticResult = createOptimisticResult(input)
    return mutation({
      variables: {
        input,
      },
      optimisticResponse: optimisticResult,
      update: (cache, { data }) => {
        if (!data) return
        const cacheId = cache.identify({ __typename: "TextContent", id: input.nodeId })
        const contentFromCache = cache.readFragment<TextContentForCommentFragment>({
          id: cacheId,
          fragment: TextContentForCommentFragmentDoc,
          fragmentName: "TextContentForComment",
        })
        if (!contentFromCache) return

        if (isTypename("Error")(data.comment)) {
          console.error("An error occurred while saving the comment!", data.comment.message)
          const erroneousComment = {
            ...optimisticResult.comment.result,
            persistanceState: "ERROR" as const,
            persistanceAction: "ADD" as const,
          }
          const erroneousCommentNode = getNewCommentNode(input.aspectSelectorId, contentFromCache, erroneousComment)
          InsertNewCommentNodeIntoContent(cache, erroneousCommentNode, contentFromCache)
          return
        }

        const newCommentNode = getNewCommentNode(input.aspectSelectorId, contentFromCache, data.comment.result)
        InsertNewCommentNodeIntoContent(cache, newCommentNode, contentFromCache)
      },
    })
  }
  return [patchedMutation, result]
}
