import React, { useState } from "react"
import { Modal, Input, Button, Space, Select } from "antd"
import { isTypename } from "@st4/graphql"
import { AnnotationDisplay } from "./AnnotationDisplay"
import { useTranslation } from "react-i18next"
import { keys } from "@st4/ui-strings"
import { useCommentTypeGuiString } from "../../utilities/commentUtilities"
import { useCommentModel } from "../commentEditor/hooks"
import { useCommentFactory } from "../commentActions"
import type { CommentInfoFieldsFragment } from "./query.hooks"
import { Icon, Regular, Solid } from "@st4/icons"

type CommentEditorProps = {
  annotation: CommentInfoFieldsFragment
  onCancel: () => boolean | void
  onSave: (text: string, state: string | null, type: string | null) => boolean | void
}

function CommentEditor(props: CommentEditorProps) {
  const { annotation, onCancel, onSave } = props
  const { t } = useTranslation()
  const [commentContent, setCommentContent] = useState(annotation.value ?? null)
  const [commentState, setCommentState] = useState(annotation.state ?? null)
  const [commentType, setCommentType] = useState(annotation.commentType ?? null)
  const { availableStates, availableTypes } = useCommentModel()
  return (
    <Space direction="vertical" style={{ display: "flex" }}>
      <Input.TextArea
        autoSize={{ minRows: 4, maxRows: 15 }}
        defaultValue={commentContent ?? ""}
        value={commentContent ?? ""}
        onChange={(e) => setCommentContent(e.target.value)}
        autoFocus
      />
      <div style={{ display: "flex" }}>
        <Select
          allowClear
          onClear={() => setCommentState(null)}
          defaultValue={commentState || undefined}
          style={{ display: "block", flex: 1, marginRight: "8px" }}
          options={availableStates}
          placeholder={t(keys.label.comment.stateSelection)}
          suffixIcon={commentState ? <Icon component={Solid.Flag} /> : <Icon component={Regular.Flag} />}
          onSelect={(v: string) => setCommentState(v)}
        />
        <Select
          allowClear
          onClear={() => setCommentType(null)}
          defaultValue={commentType}
          style={{ display: "block", flex: 1 }}
          options={availableTypes}
          placeholder={t(keys.label.comment.typeSelection)}
          suffixIcon={commentType ? <Icon component={Solid.Tag} /> : <Icon component={Regular.Tag} />}
          onSelect={(v: string) => setCommentType(v)}
        />
      </div>
      <Space align="end">
        <Button
          key="cancel"
          onClick={(evt) => {
            evt.stopPropagation()
            onCancel()
          }}
        >
          {t(keys.button.general.cancel)}
        </Button>

        <Button
          key="save"
          type="primary"
          onClick={(evt) => {
            evt.stopPropagation()
            commentContent && onSave(commentContent, commentState, commentType)
          }}
        >
          {t(keys.button.general.save)}
        </Button>
      </Space>
    </Space>
  )
}

type AnnotationDetailViewProps = {
  /** If the view should be visible (use this instead of unmounting to support animations) */
  visible: boolean
  /** The annotation to show */
  annotation: CommentInfoFieldsFragment
  /** Set this prop if editing should be enabled by default */
  editableInitial?: boolean
  /** this callback gets called before closing the modal */
  onClose: () => boolean | void
  /** this callback gets called after closing the modal */
  afterClose: () => void
}

/**
 * Opens a modal popup containing details about the comment.
 * @param props
 */
export function AnnotationDetailView(props: React.PropsWithChildren<AnnotationDetailViewProps>) {
  const { visible, onClose, annotation, afterClose } = props
  const type = useCommentTypeGuiString(annotation.commentType)
  const { updateComment } = useCommentFactory()

  return (
    <div onClick={(ev) => ev.stopPropagation()}>
      <Modal open={visible} onCancel={onClose} afterClose={afterClose} footer={null}>
        <AnnotationDisplay author={annotation.authorDisplayName ?? ""} type={type} date={annotation.editDate}>
          <CommentEditor
            annotation={annotation}
            onCancel={() => onClose()}
            onSave={(text, state, type) => {
              if (!isTypename("TextContent", "TextGroupContent")(annotation.node.content)) return
              updateComment({
                ...annotation,
                node: { ...annotation.node, content: annotation.node.content },
                state: state,
                commentType: type,
                value: text,
              })
              onClose()
            }}
          />
        </AnnotationDisplay>
      </Modal>
    </div>
  )
}
