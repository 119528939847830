import { Radio } from "antd"
import React, { ReactNode } from "react"

type SegmentButtonProps = {
  options: Option[]
  onChange?: (value: string) => void
  defaultValue?: string
  value?: string
}

type Option = {
  label: ReactNode
  value: string
}

export function SegmentButton({ options, onChange, defaultValue, value }: SegmentButtonProps) {
  return (
    <Radio.Group
      value={value}
      defaultValue={defaultValue}
      onChange={(ev) => {
        if (onChange) {
          onChange(ev.target.value)
        }
      }}
      options={options.map((o) => ({ label: o.label, value: o.value }))}
      optionType="button"
    />
  )
}
