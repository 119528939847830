import * as Types from "@st4/graphql"

import { gql } from "@apollo/client"
import * as Apollo from "@apollo/client"
const defaultOptions = {} as const
export type RemoveReuseLinksMutationVariables = Types.Exact<{
  input: Array<Types.Scalars["StPatternContextId"]> | Types.Scalars["StPatternContextId"]
  languageId?: Types.InputMaybe<Types.Scalars["AspectSelectorId"]>
}>

export type RemoveReuseLinksMutation = {
  __typename: "Mutation"
  removeReuseLinks:
    | { __typename: "Error"; message: string }
    | { __typename: "RemoveReuseLinksSuccess"; result: boolean }
}

export type RemoveReuseLinksAllowedQueryVariables = Types.Exact<{
  input: Array<Types.Scalars["StPatternContextId"]> | Types.Scalars["StPatternContextId"]
  languageId?: Types.InputMaybe<Types.Scalars["AspectSelectorId"]>
}>

export type RemoveReuseLinksAllowedQuery = { __typename: "Query"; removeReuseLinksAllowed: boolean }

export const RemoveReuseLinksDocument = gql`
  mutation RemoveReuseLinks($input: [StPatternContextId!]!, $languageId: AspectSelectorId) {
    removeReuseLinks(input: $input, languageId: $languageId) {
      ... on RemoveReuseLinksSuccess {
        result
      }
      ... on Error {
        message
      }
    }
  }
`
export type RemoveReuseLinksMutationFn = Apollo.MutationFunction<
  RemoveReuseLinksMutation,
  RemoveReuseLinksMutationVariables
>

/**
 * __useRemoveReuseLinksMutation__
 *
 * To run a mutation, you first call `useRemoveReuseLinksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveReuseLinksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeReuseLinksMutation, { data, loading, error }] = useRemoveReuseLinksMutation({
 *   variables: {
 *      input: // value for 'input'
 *      languageId: // value for 'languageId'
 *   },
 * });
 */
export function useRemoveReuseLinksMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveReuseLinksMutation, RemoveReuseLinksMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RemoveReuseLinksMutation, RemoveReuseLinksMutationVariables>(
    RemoveReuseLinksDocument,
    options,
  )
}
export type RemoveReuseLinksMutationHookResult = ReturnType<typeof useRemoveReuseLinksMutation>
export type RemoveReuseLinksMutationResult = Apollo.MutationResult<RemoveReuseLinksMutation>
export type RemoveReuseLinksMutationOptions = Apollo.BaseMutationOptions<
  RemoveReuseLinksMutation,
  RemoveReuseLinksMutationVariables
>
export const RemoveReuseLinksAllowedDocument = gql`
  query RemoveReuseLinksAllowed($input: [StPatternContextId!]!, $languageId: AspectSelectorId) {
    removeReuseLinksAllowed(input: $input, languageId: $languageId)
  }
`

/**
 * __useRemoveReuseLinksAllowedQuery__
 *
 * To run a query within a React component, call `useRemoveReuseLinksAllowedQuery` and pass it any options that fit your needs.
 * When your component renders, `useRemoveReuseLinksAllowedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRemoveReuseLinksAllowedQuery({
 *   variables: {
 *      input: // value for 'input'
 *      languageId: // value for 'languageId'
 *   },
 * });
 */
export function useRemoveReuseLinksAllowedQuery(
  baseOptions: Apollo.QueryHookOptions<RemoveReuseLinksAllowedQuery, RemoveReuseLinksAllowedQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<RemoveReuseLinksAllowedQuery, RemoveReuseLinksAllowedQueryVariables>(
    RemoveReuseLinksAllowedDocument,
    options,
  )
}
export function useRemoveReuseLinksAllowedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RemoveReuseLinksAllowedQuery, RemoveReuseLinksAllowedQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<RemoveReuseLinksAllowedQuery, RemoveReuseLinksAllowedQueryVariables>(
    RemoveReuseLinksAllowedDocument,
    options,
  )
}
export type RemoveReuseLinksAllowedQueryHookResult = ReturnType<typeof useRemoveReuseLinksAllowedQuery>
export type RemoveReuseLinksAllowedLazyQueryHookResult = ReturnType<typeof useRemoveReuseLinksAllowedLazyQuery>
export type RemoveReuseLinksAllowedQueryResult = Apollo.QueryResult<
  RemoveReuseLinksAllowedQuery,
  RemoveReuseLinksAllowedQueryVariables
>
