import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import MdHand from "@schema/react-ionicons/components/MdHand"
import MdCloseCircle from "@schema/react-ionicons/components/MdCloseCircle"
import MdHelpCircle from "@schema/react-ionicons/components/MdHelpCircle"
import MdAlert from "@schema/react-ionicons/components/MdAlert"
import MdWarning from "@schema/react-ionicons/components/MdWarning"
import MdInformationCircle from "@schema/react-ionicons/components/MdInformationCircle"
import { keys } from "@st4/ui-strings"
import { Modal, Spin } from "antd"
import type { TFunction } from "i18next"
import { Button } from "../Button"

function getButtonText(t: TFunction, id: string) {
  switch (id) {
    case "OK":
      return t(keys.button.general.ok)
    case "CANCEL":
      return t(keys.button.general.cancel)
    case "IGNORE":
      return t(keys.label.general.ignore)
    case "YES":
      return t(keys.label.general.yes)
    case "NO":
      return t(keys.label.general.no)
    default:
      return ""
  }
}

function Btn(props: { id: string; primary: boolean; onClick: React.MouseEventHandler }) {
  const { id, primary, onClick } = props
  const { t } = useTranslation()
  return (
    <Button key={id} primary={primary} onClick={onClick}>
      {getButtonText(t, id)}
    </Button>
  )
}

function getButtons(buttons: string[], defaultButton: string, onButtonClick: (result: string) => void) {
  return buttons.map((button) => (
    <Btn id={button} key={button} primary={button === defaultButton} onClick={onButtonClick.bind(null, button)} />
  ))
}

function getIcon(icon: string) {
  switch (icon) {
    case "Hand":
      return <MdHand style={{ verticalAlign: "top", marginRight: "0.5ex" }} />
    case "Stop":
    case "Error":
      return <MdCloseCircle style={{ verticalAlign: "top", marginRight: "0.5ex" }} />
    case "Question":
      return <MdHelpCircle style={{ verticalAlign: "top", marginRight: "0.5ex" }} />
    case "Exclamation":
      return <MdAlert style={{ verticalAlign: "top", marginRight: "0.5ex" }} />
    case "Warning":
      return <MdWarning style={{ verticalAlign: "top", marginRight: "0.5ex" }} />
    case "Asterisk":
    case "Information":
      return <MdInformationCircle style={{ verticalAlign: "top", marginRight: "0.5ex" }} />
    case "None":
    default:
      return null
  }
}

type InfoDialog_deprecatedProps = {
  closeOnEsc?: boolean
  closeOnOutsideClick?: boolean
  isOpened?: boolean
  headline: string
  icon?: string
  content?: React.ReactNode
  buttons?: string[]
  defaultButton?: string

  // If click Handler is a Promise the Buttons cannot be interacted with while
  // one Promise is still running
  onButtonClick: (result: string) => Promise<void> | void
}

export default function InfoDialog_deprecated({
  closeOnEsc,
  closeOnOutsideClick,
  isOpened,
  headline,
  icon,
  content,
  buttons,
  defaultButton,
  onButtonClick,
}: InfoDialog_deprecatedProps) {
  const [loadingButton, setLoadingButton] = useState(false)
  function buttonClickWithLoading(button: string) {
    setLoadingButton(true)
    Promise.resolve(onButtonClick(button)).finally(() => setLoadingButton(false))
  }
  const buttonComponents = getButtons(buttons || [], defaultButton || "", buttonClickWithLoading)

  return (
    <Modal
      closable={closeOnEsc || closeOnOutsideClick || false}
      open={isOpened}
      title={
        <>
          {getIcon(icon || "")}
          {headline}
        </>
      }
      footer={loadingButton ? <Spin /> : buttonComponents}
    >
      {content}
    </Modal>
  )
}
