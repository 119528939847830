import React, { PropsWithChildren } from "react"
import styled from "styled-components"
import ThemeManager from "@st4/theme-manager"
import { useNodeNumbering } from "../../contentModel"
import { useTreeNode } from "../../contentModel/nodeContext"
import { Heading } from "./Heading"
import { useNodeSelection } from "../NodeSelectionContext"
import { ReleaseStateDisplay } from "./ReleaseStateDisplay"

const NodeIcon = styled.img`
  user-select: none;
  margin: 2px 7px 7px 2px;
`

const Title = styled(Heading)`
  display: flex;
  flex-direction: column;

  &:hover {
    color: ${ThemeManager.quanosColors.palettes.blue.softBlue};
    text-decoration: underline;
  }
`

interface NodeHeadingProps {
  level?: 1 | 2
  icon?: string
  onClick: () => void
}

export const NodeHeading = (props: PropsWithChildren<NodeHeadingProps>) => {
  const { selectedNode, selectNode } = useNodeSelection()
  const node = useTreeNode()
  const nodeNumbering = useNodeNumbering(node.id)

  function handleClick() {
    props.onClick()
    if (node) {
      if (selectedNode?.id !== node.id) {
        selectNode(node)
      } else {
        selectNode(undefined)
      }
    }
  }

  return (
    <Title onClick={handleClick}>
      <ReleaseStateDisplay releaseState={node?.node.releaseState} />
      <div>
        {props.icon && <NodeIcon src={props.icon} />}
        {nodeNumbering.join(".")} {props.children}
      </div>
    </Title>
  )
}
