import React, { useEffect, useState } from "react"
import { Blade, BladeDefinition } from "@st4/tasks"
import { useAspectId } from "@st4/settings"
import { keys } from "@st4/ui-strings"
import { Translate } from "@st4/i18n"
import { QuanosBlueButton } from "~/ui/quanosbutton"
import { isTypename } from "~/../../../packages/st4-graphql/lib"
import styled from "styled-components"
import { List, Skeleton } from "antd"
import { NodeIcon } from "@schema/styled-ui"
import { useRelatedTopicLinksForNodeQuery } from "~/blades/crossReferencePoolBlade/query.hooks"
import { CrossReferenceElementType } from "~/blades/crossReferencePoolBlade/types"
import { NotificationInfo } from "~/components/treeActions/types"
import { PoolBladeNotifications } from "~/components/treeActions/PoolBladeNotifications"
import { i18n } from "@schema/utils"
import { v4 as uuidv4 } from "uuid"

type CrossReferencePoolBladeProps = {
  variant: string
  contextNode: string
  allowedOperations: string[]
}

type Messages =
  | {
      action: "onNodeSelected"
      payload: {
        id: string
        classHierarchy: string[]
      }
    }
  | {
      action: "onClose"
      payload: any
    }
  | {
      action: "insertCrossReference"
      payload: {
        linkId: string
        label: string
      }
    }

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

const TextContainer = styled.span`
  margin-left 5px;
`

export const CrossReferencePoolBlade: BladeDefinition<CrossReferencePoolBladeProps, Messages> =
  function BladeWithContext(props) {
    const [selectedItem, setSelectedItem] = useState<CrossReferenceElementType>()
    const aspectId = useAspectId()
    const [notifications, setNotifications] = useState<NotificationInfo[]>([])

    useEffect(() => {
      const notificationInfo = getNotificationInfo(props.allowedOperations, selectedItem?.id ?? "")
      if (notificationInfo) setNotifications([...notificationInfo])
    }, [props.allowedOperations, selectedItem?.id])

    const request = {
      id: props.contextNode,
      languageId: aspectId,
      variant: props.variant,
    }
    const { data, loading, error } = useRelatedTopicLinksForNodeQuery({
      variables: request,
      fetchPolicy: "network-only",
      errorPolicy: "all",
    })

    if (loading) {
      return (
        <div style={{ width: "100%", padding: 30 }}>
          <Skeleton active />
        </div>
      )
    }

    const hasNotificationDisablingInsert = notifications.some((notification) => notification.disableInsert)

    const insertCrossReferenceMsg: Messages = {
      action: "insertCrossReference",
      payload: {
        linkId: selectedItem?.id ?? "",
        label: selectedItem?.label ?? "",
      },
    }

    const handleCrossReferenceItemSelected = (item: CrossReferenceElementType) => {
      setSelectedItem(item)
    }

    if (data && isTypename("ST4Node")(data.node)) {
      const links: CrossReferenceElementType[] = data.node.leavingLinks.map((x) => {
        return {
          id: x.id,
          iconUrl: isTypename("ST4Node")(x.target) ? x.target.icon?.default?.uri ?? "" : "",
          label: isTypename("ST4Node")(x.target) ? x.target.label ?? "" : "",
        }
      })

      return (
        <>
          <Blade.Content>
            <Container>
              <div style={{ flex: 1, overflowY: "auto" }}>
                <List
                  dataSource={links}
                  renderItem={(link) => (
                    <List.Item
                      onClick={() => handleCrossReferenceItemSelected(link)}
                      style={
                        selectedItem?.id === link.id
                          ? { background: "#e6f7ff", cursor: "pointer" }
                          : { cursor: "pointer" }
                      }
                    >
                      <div>
                        <TextContainer>
                          {link.iconUrl ? (
                            <NodeIcon style={{ marginBottom: "2px", marginLeft: "5px" }} src={link.iconUrl} />
                          ) : null}
                        </TextContainer>
                        <TextContainer>{link.label}</TextContainer>
                      </div>
                    </List.Item>
                  )}
                />
              </div>
            </Container>
          </Blade.Content>
          <PoolBladeNotifications notifications={notifications} />
          <Blade.Actionbar>
            <div />
            <QuanosBlueButton
              disabled={hasNotificationDisablingInsert || !selectedItem?.id}
              onClick={() => {
                props.sendMessage(insertCrossReferenceMsg)
              }}
            >
              <Translate>{keys.button.blade.insert}</Translate>
            </QuanosBlueButton>
          </Blade.Actionbar>
        </>
      )
    }

    return <></>
  }

CrossReferencePoolBlade.title = <Translate>{keys.label.crossReferencePoolBlade.title}</Translate>
CrossReferencePoolBlade.size = { S: 5, M: 5, L: 5 }
CrossReferencePoolBlade.reducer = function (previousState, message) {
  switch (message.action) {
    case "onNodeSelected":
      return { ...previousState, selectedNode: message.payload }
  }

  return previousState
}

function getNotificationInfo(allowedOperations: string[], selectedNodeId: string): NotificationInfo[] {
  if (allowedOperations && allowedOperations.find((x) => x === "insert-cross-reference")) return []

  return [
    {
      id: uuidv4(),
      nodeId: selectedNodeId,
      title: "",
      description: i18n.t(keys.message.crossReferencePoolBlade.not_possible_cross_reference).toString(),
      severity: "error",
      disableInsert: true,
    },
  ]
}
