import * as Types from "@st4/graphql"

import { gql } from "@apollo/client"
import * as Apollo from "@apollo/client"
const defaultOptions = {} as const
export type CommandInfoQueryVariables = Types.Exact<{
  input: Array<Types.CommandInput> | Types.CommandInput
  context: Array<Types.CommandContext> | Types.CommandContext
  languageId?: Types.InputMaybe<Types.Scalars["AspectSelectorId"]>
}>

export type CommandInfoQuery = {
  __typename: "Query"
  commands: Array<
    | { __typename: "AllowedCommand"; command: Types.CommandName; warnings?: Array<string> | null }
    | { __typename: "ForbiddenCommand"; command: Types.CommandName; reasons?: Array<string> | null }
  >
}

export type ExecuteCommandMutationVariables = Types.Exact<{
  input: Types.CommandInput
  context: Types.CommandContext
  languageId?: Types.InputMaybe<Types.Scalars["AspectSelectorId"]>
}>

export type ExecuteCommandMutation = {
  __typename: "Mutation"
  executeCommand:
    | { __typename: "CommandExecutionFailure"; command: Types.CommandName; reasons?: Array<string> | null }
    | { __typename: "CommandExecutionPatternContextSuccess"; command: Types.CommandName; result?: Array<any> | null }
    | { __typename: "CommandExecutionSuccess"; command: Types.CommandName }
}

export const CommandInfoDocument = gql`
  query CommandInfo($input: [CommandInput!]!, $context: [CommandContext!]!, $languageId: AspectSelectorId) {
    commands(input: $input, context: $context, languageId: $languageId) {
      __typename
      ... on AllowedCommand {
        command
        warnings
      }
      ... on ForbiddenCommand {
        command
        reasons
      }
    }
  }
`

/**
 * __useCommandInfoQuery__
 *
 * To run a query within a React component, call `useCommandInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommandInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommandInfoQuery({
 *   variables: {
 *      input: // value for 'input'
 *      context: // value for 'context'
 *      languageId: // value for 'languageId'
 *   },
 * });
 */
export function useCommandInfoQuery(baseOptions: Apollo.QueryHookOptions<CommandInfoQuery, CommandInfoQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CommandInfoQuery, CommandInfoQueryVariables>(CommandInfoDocument, options)
}
export function useCommandInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CommandInfoQuery, CommandInfoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CommandInfoQuery, CommandInfoQueryVariables>(CommandInfoDocument, options)
}
export type CommandInfoQueryHookResult = ReturnType<typeof useCommandInfoQuery>
export type CommandInfoLazyQueryHookResult = ReturnType<typeof useCommandInfoLazyQuery>
export type CommandInfoQueryResult = Apollo.QueryResult<CommandInfoQuery, CommandInfoQueryVariables>
export const ExecuteCommandDocument = gql`
  mutation ExecuteCommand($input: CommandInput!, $context: CommandContext!, $languageId: AspectSelectorId) {
    executeCommand(input: $input, context: $context, languageId: $languageId) {
      ... on CommandExecutionSuccess {
        command
      }
      ... on CommandExecutionFailure {
        command
        reasons
      }
      ... on CommandExecutionPatternContextSuccess {
        command
        result
      }
    }
  }
`
export type ExecuteCommandMutationFn = Apollo.MutationFunction<ExecuteCommandMutation, ExecuteCommandMutationVariables>

/**
 * __useExecuteCommandMutation__
 *
 * To run a mutation, you first call `useExecuteCommandMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExecuteCommandMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [executeCommandMutation, { data, loading, error }] = useExecuteCommandMutation({
 *   variables: {
 *      input: // value for 'input'
 *      context: // value for 'context'
 *      languageId: // value for 'languageId'
 *   },
 * });
 */
export function useExecuteCommandMutation(
  baseOptions?: Apollo.MutationHookOptions<ExecuteCommandMutation, ExecuteCommandMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ExecuteCommandMutation, ExecuteCommandMutationVariables>(ExecuteCommandDocument, options)
}
export type ExecuteCommandMutationHookResult = ReturnType<typeof useExecuteCommandMutation>
export type ExecuteCommandMutationResult = Apollo.MutationResult<ExecuteCommandMutation>
export type ExecuteCommandMutationOptions = Apollo.BaseMutationOptions<
  ExecuteCommandMutation,
  ExecuteCommandMutationVariables
>
