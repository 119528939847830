import * as React from "react"
import { SVGProps } from "react"

const SvgCommentExclamation = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1em" height="1em" {...props}>
    <path d="M32 240c0-91.4 93.8-176 224-176s224 84.6 224 176-93.8 176-224 176c-32 0-63-5.9-93.5-16.2-9.9-3.3-20.7-1.8-29.2 4.2-15.4 10.7-31.4 20-48.4 27.4l6.3 14.6-6.4-14.7c-10.5 4.6-21.5 8.6-32.7 11.5.5-.9 1.1-1.8 1.6-2.6C68 416.5 76.3 391 79.4 363.9c1-9.2-2.1-17.7-7.4-23.9-24.5-29.4-40-62.9-40-100zM256 32C114.5 32 0 125.2 0 240c0 46.6 19.6 87.1 47.4 120.5 0 .1.1.1.1.2-2.6 22.6-9.5 43.5-21.3 63.1-6.5 10.8-13.5 20.8-21.7 29-4.5 4.6-5.9 11.5-3.4 17.4s8.3 9.8 14.8 9.8c28.7 0 57.6-8.9 81.6-19.3 19.2-8.4 37.1-18.7 53.9-30.5.2-.1.5-.2.7-.1C185.5 441.3 220 448 256 448c141.5 0 256-93.2 256-208S397.5 32 256 32zm0 80c-8.8 0-16 7.2-16 16v128c0 8.8 7.2 16 16 16s16-7.2 16-16V128c0-8.8-7.2-16-16-16zm24 224a24 24 0 1 0-48 0 24 24 0 1 0 48 0z" />
  </svg>
)

export default SvgCommentExclamation
