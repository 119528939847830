export type SearchOptions = {
  searchKeys: string[]
  searchVersion: SearchVersion
  searchLanguages: any[]
}

export type SearchVersion = "all" | "approved" | "latest" | "latest-approved"

const prefix = "x-aspect-selector:"

function isAspectSelectorId(id: any): id is string {
  if (!id?.length || !id?.indexOf || !id?.split || id.indexOf(prefix) != 0) return false
  return id.split(":")[1].length > 0
}

export function toAspectSelectorId(searchLanguages: any[]) {
  if (!searchLanguages.length) return `${prefix}0`

  return searchLanguages
    .filter(isAspectSelectorId)
    .reduce(
      (aspectSelectorId, currentId) =>
        aspectSelectorId.endsWith(":") || aspectSelectorId.endsWith(";")
          ? aspectSelectorId + currentId.split(":")[1]
          : aspectSelectorId + ";" + currentId.split(":")[1],
      prefix,
    )
}

export function areSearchOptionsEqual(a: SearchOptions, b: SearchOptions) {
  const aSearchKeys = [...a.searchKeys].sort((a, b) => a.localeCompare(b))
  const aSearchLanguages = [...a.searchLanguages].sort((a, b) => a.toString().localeCompare(b.toString()))
  const bSearchKeys = [...b.searchKeys].sort((a, b) => a.localeCompare(b))
  const bSearchLanguages = [...b.searchLanguages].sort((a, b) => a.toString().localeCompare(b.toString()))
  return (
    aSearchKeys.length == bSearchKeys.length &&
    aSearchKeys.reduce((isEqual, currKey, currIdx) => isEqual && currKey == bSearchKeys[currIdx], true) &&
    aSearchLanguages.length == bSearchLanguages.length &&
    aSearchLanguages.reduce((isEqual, currLang, currIdx) => isEqual && currLang === bSearchLanguages[currIdx], true) &&
    a.searchVersion == b.searchVersion
  )
}
