import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react"
import styled, { createGlobalStyle, useTheme } from "styled-components"
import { LoadingNodePreview } from "./LoadingNodePreview"
import type { ReadyPreviewContentModel } from "../contentModel/types"
import { Alert } from "@schema/styled-ui"
import { useTranslation } from "react-i18next"
import { keys } from "@st4/ui-strings"
import { CommentEditor } from "./commentEditor"
import { NumberingProvider, usePreviewContentModel, useSelectedTextNodeState } from "../contentModel"
import { KeyboardUnselector } from "../selection"
import { Button, Drawer, Layout, Tooltip } from "antd"
import { useToggle } from "../utilities/hooks"
import { ContentWidthLimiting, GenericNode } from "./nodeComponents"
import { usePreviewConfig } from "./PreviewConfig"
import { NodeProvider } from "../contentModel/nodeContext"
import { SimpleErrorBoundary } from "./Errors"
import { useNodeSelection } from "./NodeSelectionContext"
import { useContentScroll, useNodePosObserver, useScrollToContent } from "./Scrolling"
import { ApolloError, useApolloClient } from "@apollo/client"
import { NodeMetadata } from "./NodeMetadata"
import { SingleGenericNode } from "./nodeComponents/GenericNode"
import { CommentList, useReplyExpansion } from "./CommentList"
import {
  isReadonlyPreviewParameterContext,
  PreviewParameterContext,
  ReadonlyPreviewParameterContext,
  usePreviewParameterContext,
} from "./PreviewParameterContext"
import { EditorErrorView } from "./nodeComponents/textnode/editor/EditorErrorView"
import { NodeActionsContainer } from "./nodeComponents/textnode/editor/NodeActionsContainer"
import { useVirtualizer } from "@tanstack/react-virtual"
import { Icon, Regular, Light } from "@st4/icons"
import { SingleNodeDocument } from "../graphql/applicationQueries/query.hooks"

const SidebarBottomToolbar = styled.div`
  background: white;
  display: flex;
  flex-direction: row;

  .end {
    flex: 1;
    text-align: right;
  }
`
const SiderWrapper = styled.div`
  .ant-layout-sider {
    border-left: 1px solid ${({ theme }) => theme.greys[300]};
    background: inherit;
    &[aria-expanded="true"] {
      height: 100%;
    }
    .ant-layout-sider-children {
      display: flex;
    }
  }
`

const Content = styled(Layout.Content)`
  display: flex;
`

const CollapseIcon = styled(Regular.ChevronRight)`
  color: ${({ theme }) => theme.greys[600]};
  transition: transform 0.2s;
  transform: rotate(0deg);
  .ant-layout-sider-collapsed & {
    transform: rotate(180deg);
  }
`

function ContentView() {
  const previewContentModel = usePreviewContentModel()
  const { t } = useTranslation()
  const [useWideCommentSection, toggleWideCommentSection] = useToggle()
  const theme = useTheme()
  const { collapsibleComments, initialCommentsCollapsed } = usePreviewConfig()
  const [collapsed, setCollapsed] = useState(initialCommentsCollapsed)
  const { hasExpandedReplies, toggleReplyExpansion } = useReplyExpansion()

  useEffect(() => {
    // Kommentarsektion automatisch ausklappen, wenn Antwort ausgeklappt wird
    if (hasExpandedReplies && !useWideCommentSection) toggleWideCommentSection(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Don't recalculate if WideCommentSection changes it's value
  }, [hasExpandedReplies])

  if (previewContentModel.state === "empty") {
    return <Alert isError>{t(keys.message.alert.noContent)}</Alert>
  }
  if (previewContentModel.state === "loading")
    return (
      <SingleNode className="NodeList loading">
        <LoadingNodePreview active />
      </SingleNode>
    )

  return (
    // Overflow is hidden because drawer animation shows scrollbar for a short moment
    <Layout style={{ overflow: "hidden", flex: 1, maxHeight: "100%", height: "100%", width: "100%" }}>
      <PreviewStyles />
      <CommentEditor key="commentEditor">
        <KeyboardUnselector>
          <Content className="Content">
            <PreviewContent previewContentModel={previewContentModel} />
          </Content>
          <SiderWrapper>
            <Layout.Sider
              theme="light"
              id="CommentSidebar"
              collapsible={collapsibleComments}
              defaultCollapsed={initialCommentsCollapsed}
              aria-expanded={!collapsed}
              reverseArrow
              collapsedWidth={0}
              width={useWideCommentSection ? "40vw" : "25vw"}
              zeroWidthTriggerStyle={{
                // background: "inherit",
                borderTop: `1px solid ${theme.greys[300]}`,
                borderLeft: `1px solid ${theme.greys[300]}`,
                borderBottom: `1px solid ${theme.greys[300]}`,
                width: 17,
                left: -17,
                zIndex: 20,
              }}
              trigger={<CollapseIcon aria-controls="CommentSidebar" aria-label="right" aria-expanded={!collapsed} />}
              onCollapse={setCollapsed}
            >
              {!collapsed && (
                <Sidebar
                  toggleReplyExpansion={toggleReplyExpansion}
                  useWideCommentSection={useWideCommentSection}
                  toggleWideCommentSection={toggleWideCommentSection}
                  commentSidebar={<CommentList />}
                />
              )}
            </Layout.Sider>
          </SiderWrapper>
        </KeyboardUnselector>
      </CommentEditor>
    </Layout>
  )
}

type SidebarProps = {
  commentSidebar: React.ReactNode
  toggleWideCommentSection: (targetValue?: boolean | undefined) => void
  useWideCommentSection: boolean
  toggleReplyExpansion: React.Dispatch<string | boolean | Map<string, boolean>>
}

const MetadataDrawer = styled(Drawer)`
  .ant-drawer-body {
    padding: 0;
  }
`

function Sidebar({
  commentSidebar,
  toggleWideCommentSection,
  useWideCommentSection,
  toggleReplyExpansion,
}: SidebarProps) {
  const { selectedNode, selectNode } = useNodeSelection()
  const { disableMetadataDrawer } = usePreviewConfig()
  const { t } = useTranslation()
  return (
    <>
      <MetadataDrawer
        mask={false}
        open={!disableMetadataDrawer && !!selectedNode}
        title={t(keys.label.nodeproperties)}
        onClose={() => selectNode(undefined)}
        getContainer={false}
        rootStyle={{ position: "absolute" }}
        width="100%"
      >
        {selectedNode ? <NodeMetadata node={selectedNode} /> : null}
      </MetadataDrawer>
      <div style={{ display: "flex", flex: "1", flexDirection: "column", justifyContent: "space-between" }}>
        {commentSidebar}
        <CommentToolbar
          toggleWideCommentSection={toggleWideCommentSection}
          useWideCommentSection={useWideCommentSection}
          toggleReplyExpansion={toggleReplyExpansion}
        />
      </div>
    </>
  )
}

type PreviewContentProps = {
  previewContentModel: ReadyPreviewContentModel
}

const PreviewStyles = createGlobalStyle`
  html {
    // Width of node content in preview list
    --preview-node-width: 780px;
  }
`

const SingleNode = styled.div<{ fullHeightDisplay?: boolean }>`
  ${({ fullHeightDisplay }) => !fullHeightDisplay && "overflow: auto;"}
  flex: 1;
  display: block;
  padding: 0px;
  position: relative;

  & > * {
    flex: 1;
    display: block;
    padding: 0px;
    line-height: ${({ theme }) => theme.token.lineHeight};
  }

  .NodeActionsContainer {
    height: 100%;
  }

  .NodeActions {
    display: none;
  }
`

// Scrollable list that takes full width for itself but limits its children size
// these children are then centered in the available space
const NodeList = styled.div<{ readonly?: boolean }>`
  overflow: auto;
  overflow-anchor: none;
  flex: 1;
  display: block;
  position: relative;
  padding-bottom: 8px;

  & > * {
    padding-left: ${({ readonly }) => (readonly ? "0" : "38px")};
    line-height: ${({ theme }) => theme.token.lineHeight};
  }
`

function PreviewContent({ previewContentModel }: PreviewContentProps) {
  const { t } = useTranslation()
  const { nodes, singleNodeMode } = useSelectedTextNodeState(previewContentModel)
  const previewParameterContext = usePreviewParameterContext()
  const treeLimitReached = previewContentModel.contentPageInfo?.hasNextPage
  const key = nodes.length && nodes[0]?.node.id

  const { initialScrollTarget } = usePreviewParameterContext()
  const scrollToContent = useScrollToContent()
  const initialTarget = useMemo(() => {
    return initialScrollTarget
    // eslint-disable-next-line react-hooks/exhaustive-deps -- keep memo consistent based on initial initialScrollTarget
  }, [singleNodeMode])
  useEffect(() => {
    initialTarget && scrollToContent(initialTarget)
  }, [initialTarget])

  const nodeListRef = useRef<HTMLDivElement>(null)

  const indexByTreeNodeId = useMemo(() => new Map(nodes.map((v, i) => [v.id, i])), [nodes])
  const virtualizer = useVirtualizer({
    count: nodes.length,
    getScrollElement: () => nodeListRef.current,
    estimateSize: () => 250,
    overscan: 2,
    getItemKey: (index) => nodes[index].id,
  })
  const apolloClient = useApolloClient()
  const { languageId } = usePreviewParameterContext()
  const virtualItems = virtualizer.getVirtualItems()
  const scrollToOuterNode = useCallback(
    (id) => {
      // When scrolling is requested we preload nodes above the target before scrolling
      // This prevents the loading of content above the target from pushing our target down
      // Because we always try to scroll our target to the top, this should only make a difference on the last nodes
      // which cannot be aligned to the top
      const treeNodeIndex = indexByTreeNodeId.get(id)
      if (treeNodeIndex !== undefined) {
        // If the target node is already in the visible range then no scrolling (or prefetching) is necessary
        if (
          virtualizer.range &&
          (treeNodeIndex > virtualizer.range.endIndex || treeNodeIndex < virtualizer.range.startIndex)
        ) {
          const treeNodesToBePreloaded = nodes.slice(treeNodeIndex - 5, treeNodeIndex + 1)
          // Query each node separately instead of using MultipleNodes GraphQL-Field
          // This has the benefit of not requesting nodes that have already been requested and are in cache and are in cache
          Promise.all(
            treeNodesToBePreloaded.map((treeNode) =>
              apolloClient.query({
                query: SingleNodeDocument,
                variables: { id: treeNode.node.id, languageId, reviewVariant: "ReviewMetadata" },
                context: { batching: true },
              }),
            ),
          ).finally(() => {
            // Jump to target whether requests succeeded or not.
            // If a request failed the content will not be availible immediately.
            // Instead it will be requested again when it comes into view and scrolling might fail due to resizing
            // of previous nodes
            if (
              virtualizer.range &&
              (treeNodeIndex > virtualizer.range.endIndex || treeNodeIndex < virtualizer.range.startIndex)
            ) {
              virtualizer.scrollToIndex(treeNodeIndex, { align: "start", behavior: "auto" })
            }
          })
        }
      }
    },
    [virtualizer.range, virtualizer.scrollToIndex, indexByTreeNodeId, nodes, languageId, apolloClient],
  )
  useNodePosObserver(nodeListRef, [key, virtualizer.range])
  useContentScroll(scrollToOuterNode)

  // Currently the "editedNodes" in the previewParameterContext identify by ST4NodeId.
  // Until this is changed we pin all treeNodes with an edited St4Node inside.
  const editedTreeNodes = isReadonlyPreviewParameterContext(previewParameterContext)
    ? []
    : nodes.filter((n) => previewParameterContext.editedNodes.includes(n.node.id)).map((n) => n.id)

  const treeNodeIds = previewContentModel.treeNodes.map((treeNode) => treeNode.id)
  if (
    isNodeDeleted(treeNodeIds, previewParameterContext, singleNodeMode ?? false) &&
    !isReadonlyPreviewParameterContext(previewParameterContext)
  ) {
    return (
      <ContentErrorView
        singleNodeMode={singleNodeMode ?? false}
        previewParameterContext={previewParameterContext}
        errorMessage={t(keys.message.editor.canEditCheck.error.NodeDeleted)}
      />
    )
  }
  if (singleNodeMode && nodes.length !== 0) {
    return (
      <NodesContainer
        //NodesContainer required for useNodePosObserver and Fonto Toolbar positioning
        className="SingleNode NodesContainer"
        ref={nodeListRef}
        singleNodeMode={singleNodeMode ?? false}
        fullHeightDisplay={
          !isReadonlyPreviewParameterContext(previewParameterContext) &&
          previewParameterContext.editedNodes.includes(nodes[0].node.id)
        }
      >
        <NodeProvider node={nodes[0]}>
          <SimpleErrorBoundary message={t(keys.message.error.nodeRendering)}>
            <SingleGenericNode
              fullHeightDisplay={
                !isReadonlyPreviewParameterContext(previewParameterContext) &&
                previewParameterContext.editedNodes.includes(nodes[0].node.id)
              }
              treeNode={nodes[0]}
            />
          </SimpleErrorBoundary>
        </NodeProvider>
      </NodesContainer>
    )
  }

  return (
    <NodesContainer
      //NodesContainer required for useNodePosObserver and Fonto Toolbar positioning
      className="SingleNode NodesContainer"
      ref={nodeListRef}
      singleNodeMode={singleNodeMode ?? false}
    >
      <NumberingProvider treeNodes={nodes} previewContentModel={previewContentModel}>
        <div style={{ height: `${virtualizer.getTotalSize()}px`, width: "100%", position: "relative" }}>
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              transform: `translateY(${virtualItems[0]?.start ?? 0}px)`,
            }}
          >
            {virtualItems.map((virtualItem) => {
              if (editedTreeNodes.includes(nodes[virtualItem.index].id))
                return <div key={`${virtualItem.key}_EditorPlaceholder`} style={{ height: virtualItem.size }}></div>
              return (
                <div
                  style={{ display: "flow-root" }}
                  key={virtualItem.key}
                  data-index={virtualItem.index}
                  ref={virtualizer.measureElement}
                >
                  <NodeProvider node={nodes[virtualItem.index]}>
                    <SimpleErrorBoundary message={t(keys.message.error.nodeRendering)}>
                      <GenericNode treeNode={nodes[virtualItem.index]} />
                    </SimpleErrorBoundary>
                  </NodeProvider>
                </div>
              )
            })}
            {/* Fonto Nodes are pinned at the end because their <iframe> would reload when moved in the DOM*/}
            {editedTreeNodes.map((treeNodeId) => {
              const treeNodeIndex = indexByTreeNodeId.get(treeNodeId)
              if (treeNodeIndex === undefined) return null
              const treeNode = nodes[treeNodeIndex]
              const virtualItem = virtualizer.getVirtualItems().find((v) => v.index == treeNodeIndex)
              return (
                <div
                  style={{
                    display: "flow-root",
                    visibility: virtualItem ? "visible" : "hidden",
                    position: "absolute",
                    top: virtualItem ? virtualItem.start - virtualItems[0]?.start ?? 0 : 0,
                    width: "100%",
                  }}
                  key={treeNodeId}
                  data-index={treeNodeIndex}
                  ref={virtualizer.measureElement}
                >
                  <NodeProvider node={treeNode}>
                    <SimpleErrorBoundary message={t(keys.message.error.nodeRendering)}>
                      <GenericNode treeNode={treeNode} />
                    </SimpleErrorBoundary>
                  </NodeProvider>
                </div>
              )
            })}
          </div>
        </div>
      </NumberingProvider>
      {treeLimitReached && <NodeLimitWarning />}
    </NodesContainer>
  )
}

type NodesContainerProps = {
  className: string
  singleNodeMode: boolean
  children: ReactNode
  fullHeightDisplay?: boolean
}

type DivElementRef = React.RefObject<HTMLDivElement> | ((instance: HTMLDivElement | null) => void) | null | undefined

const NodesContainer = React.forwardRef(
  ({ className, singleNodeMode, children, fullHeightDisplay }: NodesContainerProps, ref: DivElementRef) => {
    const previewParameterContext = usePreviewParameterContext()
    if (singleNodeMode) {
      return (
        <SingleNode className={className} ref={ref} fullHeightDisplay={fullHeightDisplay}>
          {children}
        </SingleNode>
      )
    }

    return (
      <NodeList className={className} ref={ref} readonly={isReadonlyPreviewParameterContext(previewParameterContext)}>
        {children}
      </NodeList>
    )
  },
)
NodesContainer.displayName = "NodesContainer"

function NodeLimitWarning() {
  const { t } = useTranslation()
  return <Alert isWarning>{t(keys.message.preview.previewLimit)}</Alert>
}

function isNodeDeleted(
  treeNodeIds: string[],
  previewParameterContext: PreviewParameterContext,
  singleNodeMode: boolean,
) {
  if (isReadonlyPreviewParameterContext(previewParameterContext)) return false

  treeNodeIds = treeNodeIds.map((id) => id.split("/").pop() ?? "") // TODO: AUSBAUEN!!!! KEINE STRING-OPERATIONEN AUF IDs!!!
  const editedNodes = previewParameterContext.editedNodes.map((nodeId) => nodeId.split(":").pop() ?? "") // TODO: AUSBAUEN!!!! KEINE STRING-OPERATIONEN AUF IDs!!!

  const isNodeDeletedSingleNodeMode =
    treeNodeIds.length === 0 && !isReadonlyPreviewParameterContext(previewParameterContext) && editedNodes.length !== 0

  const isNodeDeleted =
    treeNodeIds.length > 0 &&
    !isReadonlyPreviewParameterContext(previewParameterContext) &&
    singleNodeMode &&
    editedNodes.length === 1 &&
    !treeNodeIds.includes(editedNodes[0])

  return isNodeDeletedSingleNodeMode || isNodeDeleted
}

function ContentErrorView(props: {
  singleNodeMode: boolean
  previewParameterContext: Exclude<PreviewParameterContext, ReadonlyPreviewParameterContext>
  errorMessage: string
}) {
  const { singleNodeMode, previewParameterContext, errorMessage } = props

  const errorView = (
    <NodeActionsContainer
      textNodeState={singleNodeMode ? "ERROR_ADVANCED" : "ERROR_INPLACE"}
      previewParameters={previewParameterContext}
      nodeId={""}
      treeNodeId={""}
    >
      <EditorErrorView
        messageHub={previewParameterContext.messageHub}
        sessionError={[]}
        error={new ApolloError({ errorMessage })}
      />
    </NodeActionsContainer>
  )

  if (singleNodeMode)
    return (
      <NodesContainer
        className="SingleNode NodesContainer" //NodesContainer required for useNodePosObserver
        singleNodeMode={singleNodeMode ?? false}
        fullHeightDisplay={true}
      >
        {errorView}
      </NodesContainer>
    )

  return <ContentWidthLimiting>{errorView}</ContentWidthLimiting>
}

type ToolbarProps = {
  toggleWideCommentSection: (targetValue?: boolean | undefined) => void
  useWideCommentSection: boolean
  toggleReplyExpansion: React.Dispatch<string | boolean | Map<string, boolean>>
}

function CommentToolbar({ toggleReplyExpansion, useWideCommentSection, toggleWideCommentSection }: ToolbarProps) {
  const { t } = useTranslation()
  return (
    <SidebarBottomToolbar>
      <Tooltip title={t(keys.label.comment.resizecommentsview)}>
        <Button
          type="text"
          icon={
            useWideCommentSection ? (
              <Icon component={Regular.ChevronsRight} aria-label="double-right" />
            ) : (
              <Icon component={Regular.ChevronsLeft} aria-label="double-left" />
            )
          }
          onClick={() => toggleWideCommentSection()}
        />
      </Tooltip>
      <div className="end">
        <Tooltip title={t(keys.button.preview.comment.allRepliesShow)} placement="topRight">
          <Button
            type="text"
            icon={<Icon component={Light.Eye} />}
            onClick={() => toggleReplyExpansion(true)}
            aria-label="eye"
          />
        </Tooltip>
        <Tooltip title={t(keys.button.preview.comment.allRepliesHide)} placement="topRight">
          <Button
            type="text"
            icon={<Icon component={Light.EyeSlash} />}
            onClick={() => toggleReplyExpansion(false)}
            aria-label="eye-invisible"
          />
        </Tooltip>
      </div>
    </SidebarBottomToolbar>
  )
}

export default ContentView
