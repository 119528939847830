import * as React from "react"
import { SVGProps } from "react"

const SvgLanguage = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" width="1em" height="1em" {...props}>
    <path d="M64 64C28.7 64 0 92.7 0 128v256c0 35.3 28.7 64 64 64h512c35.3 0 64-28.7 64-64V128c0-35.3-28.7-64-64-64H64zm512 48c8.8 0 16 7.2 16 16v256c0 8.8-7.2 16-16 16H320V112h256zm-397.7 63.9 64 144c4.5 10.1-.1 21.9-10.2 26.4s-21.9-.1-26.4-10.2l-8.9-20.1h-73.6l-8.9 20.1c-4.5 10.1-16.3 14.6-26.4 10.2S73.3 330 77.7 319.9l64-144c3.2-7.2 10.4-11.9 18.3-11.9s15.1 4.7 18.3 11.9zM179 276l-19-42.8-19 42.8h38zm277-112c-11 0-20 9-20 20v4h-52c-11 0-20 9-20 20s9 20 20 20h107.1c-7.3 16.7-17.4 31.9-29.8 45l-.5-.5-14.6-14.6c-7.8-7.8-20.5-7.8-28.3 0s-7.8 20.5 0 28.3l12.1 12.1c-5.9 3.6-12.1 6.9-18.5 9.8l-3.6 1.6c-10.1 4.5-14.6 16.3-10.2 26.4s16.3 14.6 26.4 10.2l3.6-1.6c12-5.3 23.4-11.8 34-19.4 4.3 3 8.6 5.8 13.1 8.5l18.9 11.3c9.5 5.7 21.8 2.6 27.4-6.9s2.6-21.8-6.9-27.4l-18.9-11.3c-.9-.5-1.8-1.1-2.7-1.6 17.2-18.8 30.7-40.9 39.6-65.4l1.8-4.5h2c11 0 20-9 20-20s-9-20-20-20h-60v-4c0-11-9-20-20-20z" />
  </svg>
)

export default SvgLanguage
