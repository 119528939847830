import React, { useState } from "react"
import { Tooltip, Typography } from "antd"
import styled, { css } from "styled-components"
import { usePreviewContentModel } from "../../contentModel"
import { useNodeSelection } from "../NodeSelectionContext"
import type { TreeNode } from "../../contentModel/nodeContext"
import { extractProps, getNodeMappingRule, lookupComponent } from "../../mapping/mappingUtilities"
import type { St4NodeWithContentFragment } from "../../graphql/applicationQueries/query.hooks"
import type { NodeRenderProps } from "./types"

export const ContentWidthLimiting = styled.div<{ fullWidth?: boolean }>`
  ${({ fullWidth }) =>
    !fullWidth &&
    css`
      width: var(--preview-node-width);
      padding: 0 5px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 7px;
    `}
  height: 100%;
`

export const NodeWrapper = styled.div<{ selected?: boolean; group?: boolean }>`
  background: white;
  padding: 0 3em;
  display: flow-root; //prevent margin collapse at start and end of node
  z-index: 2;
  ${({ theme, group, selected }) =>
    group
      ? css`
          border: 1px dashed ${selected ? theme.preview.nodeSelection.selectionColor : theme.greys[300]};
          border-radius: 0 0 3px 3px;
          ${selected ? theme.preview.groupNodeHighlightSelected : theme.preview.groupNodeHighlight}
        `
      : css``}
  & & {
    padding: 0;
  }
`

export const NodeActionsContainerPlaceholder = styled.div`
  width: 42px;
`

export const NodeWithNodeActionsContainerWrapper = styled.div`
  display: flex;
  flexdirection: row;
`

export function useIsNodeSelected(node: TreeNode) {
  const { selectedNode } = useNodeSelection()
  return selectedNode?.id === node.id
}

const NodeIcon = styled.img`
  user-select: none;
  margin: 7px;
  height: 100%;
  align-self: center;
`

const Indentation = styled.span<{ level: number }>`
  width: ${({ level }) => level * 16}px;
  flex-shrink: 0;
`
const HeaderWrap = styled.div`
  display: flex;
  width: 100%;
`

function NodeLabel(props: React.PropsWithChildren<unknown>) {
  const [isEllipsis, setIsEllipsis] = useState(false)
  return (
    <Tooltip title={isEllipsis && props.children} style={{ flex: 1 }}>
      <Typography.Text
        ellipsis={{ onEllipsis: setIsEllipsis }}
        style={{ margin: "7px 0", userSelect: "none", whiteSpace: "nowrap" }}
        className="ant-typography-ellipsis-single-line"
      >
        {props.children}
      </Typography.Text>
    </Tooltip>
  )
}

export function NodeHeader(props: {
  icon?: { default?: { uri?: string | null } | null } | null
  title?: string | null
  intendationLevel?: number | null
  node: { id: string }
}) {
  const contentModel = usePreviewContentModel()
  const { selectNode, selectedNode } = useNodeSelection()

  function onClick() {
    if (contentModel.state === "ready" && contentModel.onNodeClick) {
      const realTreeNode = contentModel.treeNodesById.get(props.node.id)
      if (realTreeNode) {
        contentModel.onNodeClick(realTreeNode)
        if (selectedNode?.id !== props.node.id) {
          selectNode(realTreeNode)
        }
      }
    }
  }

  return (
    <HeaderWrap onClick={onClick}>
      <Indentation level={props.intendationLevel || 0} />
      <NodeIcon src={props.icon?.default?.uri ?? ""} />
      <NodeLabel>{props.title}</NodeLabel>
    </HeaderWrap>
  )
}

/**
 * Get the component for the given st4Node and its props.
 *
 * The props will contain those from the mapping as well as a `node` and `treeNode` prop.
 *
 * Returns `null` if no mapping was found or mapped component doesn't exist
 */
export function getNodeComponent(
  node: St4NodeWithContentFragment, // eigentlich werden hier nur die Id und StNodeClassHierarchy benötigt. getFirstMappingInHierarchy braucht aber den gesamten Knoten...
  treeNode: TreeNode,
  mappingRegistry: "FULL" | "LOADING",
): { props: NodeRenderProps; component: React.ComponentType<any> } | null {
  const classHierarchy = node.nodeClass.classHierarchy ?? []
  const mappingRule = getNodeMappingRule(node, mappingRegistry)
  // No mapping implies hidden
  if (!mappingRule) {
    console.warn(`No Mapping could be found for class hierarchy [${classHierarchy}]`)
    return null
  }
  const cmp = lookupComponent(mappingRule)
  if (cmp === "notFound") {
    console.warn(`Mapped Component could not be found for class hierarchy [${classHierarchy}]`)
    return null
  } else if (cmp === "hidden") return null
  const calculatedProps = { ...extractProps(node, mappingRule), ...{ node, treeNode } }
  return { component: cmp, props: calculatedProps }
}
