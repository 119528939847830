import * as React from "react"
import { SVGProps } from "react"

const SvgSt4FileTagRegular = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1em" height="1em" {...props}>
    <path d="M139 221v79.6c0 9.4 4.7 18.7 9.4 23.4l93.7 93.7c14.1 14.1 35.1 14.1 49.2 0l72.6-72.6c14.1-14.1 14.1-35.1 0-49.2l-93.7-93.7c-7-2.3-16.4-7-25.8-7h-79.6c-14.1 0-25.8 11.8-25.8 25.8zm60.8 51.5c-9.4 0-16.4-7-16.4-16.4s7-16.4 16.4-16.4 16.4 7 16.4 16.4-7 16.4-16.4 16.4zm229.5-163.3-90.6-90.5C326.7 6.7 310.5 0 293.5 0H128C92.7 0 64 28.7 64 64v384c0 35.3 28.7 64 64 64h256c35.3 0 64-28.7 64-64V154.5c0-17-6.7-33.3-18.7-45.3zM400 448c0 8.8-7.2 16-16 16H128c-8.8 0-16-7.2-16-16V64c0-8.8 7.2-16 16-16h160v80c0 17.7 14.3 32 32 32h80v288z" />
  </svg>
)

export default SvgSt4FileTagRegular
