import type { CommentNodeFragment, TextContentForCommentFragment } from "../CommentList/query.hooks"
import { ApolloCache } from "@apollo/client"
import { CommentNodeFragmentDoc } from "../CommentList/query.hooks"

/**
 * Insets a comment into the nodes comments
 * @param existingComments The list of existing comments which should receive the new comment
 * @param comment The comment to add to the tree
 * @param textContent The textContent which contains the comments
 */
export function insertNewComment(
  aspectId: string,
  existingComments: CommentNodeFragment[],
  comment: CommentNodeFragment["comment"],
  textContent: TextContentForCommentFragment,
) {
  const commentNode = getNewCommentNode(aspectId, textContent, comment)
  if (!commentNode) return existingComments
  return [...existingComments, commentNode]
}

export function getNewCommentNode(
  aspectId: string,
  textContent: TextContentForCommentFragment,
  comment: CommentNodeFragment["comment"],
): CommentNodeFragment {
  return {
    id: `${textContent.id}_${textContent.historyNumber}_${aspectId}_${comment.commentKey}`,
    comment,
    __typename: "CommentNode" as const,
  }
}

export function InsertNewCommentNodeIntoContent(
  cache: ApolloCache<any>,
  newCommentNode: CommentNodeFragment,
  content: TextContentForCommentFragment,
) {
  const newCommentNodeRef = cache.writeFragment({
    fragment: CommentNodeFragmentDoc,
    fragmentName: "CommentNode",
    data: newCommentNode,
  })

  cache.modify({
    id: cache.identify(content),
    fields: {
      comments(existingCommentRefs) {
        return [...existingCommentRefs, newCommentNodeRef]
      },
    },
  })
}

export function ReplaceCommentInCommentNode(
  cache: ApolloCache<any>,
  commentNodeId: string,
  comment: CommentNodeFragment["comment"],
) {
  cache.modify({
    id: commentNodeId,
    fields: {
      comment() {
        return comment
      },
    },
  })
}
