import React from "react"
import { Spin, MenuProps, theme } from "antd"
import { Icon, Regular } from "@st4/icons"

import { keys } from "@st4/ui-strings"
import { useSettings } from "@st4/settings"
import { useHelpContext } from "~/hooks/helpContext"
import { Translate } from "@st4/i18n"
import { DropdownMenuWithTitle } from "~/ui/dropdownMenuWithTitle"

const privacyLinks = new Map<string, string>([
  ["de", "https://quanos.com/datenschutz/#c9093"],
  ["en", "https://quanos.com/en/privacy-policy/#c9095"],
])

export function HelpAction() {
  const settings = useSettings()
  const helpKey = useHelpContext()
  const { token } = theme.useToken()

  if (!settings) {
    return <Spin delay={500} />
  }
  const version = settings.configuration.serverVersion
  const languageCode = settings.userConfiguration.guiLanguageCode
  const twoCharLanguageCode = (
    !!languageCode.length && languageCode.length > 2 ? languageCode.substring(0, 2) : languageCode
  ).toLocaleLowerCase()
  const [isValid, helpLink] = buildContextHelpLink(helpKey, twoCharLanguageCode, version)
  const privacyLink = privacyLinks.get(twoCharLanguageCode) || privacyLinks.get("de")

  const items: MenuProps["items"] = [
    {
      key: "help",
      label: (
        <a data-testid={"helpLinks-help"} href={helpLink} target={"_blank"} rel="noreferrer">
          <Translate>{keys.label.help.link.help.title}</Translate>
        </a>
      ),
      icon: <Icon component={Regular.ArrowUpRightFromSquare} />,
    },
    {
      key: "privacy",
      label: (
        <a data-testid={"helpLinks-privacy"} href={privacyLink} target={"_blank"} rel="noreferrer">
          <Translate>{keys.label.help.link.privacy.title}</Translate>
        </a>
      ),
      icon: <Icon component={Regular.ArrowUpRightFromSquare} />,
    },
  ]

  return (
    <DropdownMenuWithTitle
      menu={{ items }}
      title={<Translate>{keys.label.help.flyoutTitle}</Translate>}
      arrow={{ pointAtCenter: true }}
      placement="bottomRight"
    >
      <Icon component={Regular.CircleQuestion} style={{ fontSize: token.fontSizeXL }} />
    </DropdownMenuWithTitle>
  )
}

function buildContextHelpLink(
  helpKey: string,
  language: string,
  version: string,
): [isValid: boolean, helpLink: string] {
  const softwareVersion = version
    .split(".")
    .filter((_, i) => i < 3)
    .join(".") // InfoCube expects version without build number
  const helpUrlKey =
    helpKey == "/"
      ? "WebAuthor_area_dashboard_overview"
      : helpKey == "/task/showSearchResult"
      ? "WebAuthor_task_search"
      : helpKey == "/task/explore"
      ? "WebAuthor_area_openSpace"
      : helpKey.match(/\/task\/[0-9]+/)?.length
      ? "WebAuthor_task_createVariantfilter"
      : helpKey == "inplaceEditor"
      ? "WebAuthor_task_editContent"
      : helpKey == "singleNodeEditor"
      ? "WebAuthor_task_fullEditContent"
      : helpKey == "taxonomyOptions"
      ? "webauthor_area_taxonomy_options"
      : ""

  return [
    !!helpUrlKey.length,
    `https://st4doc.infocu.be/go?s=${helpUrlKey}&language=${language}&swversion=${softwareVersion}`,
  ]
}
