import React from "react"
import { ContentWidthLimiting, NodeWrapper } from "./utils"
import { useIsNodeSelected } from "./utils"
import type { NodeRenderProps } from "./types"
import { NodeHeading } from "../contentComponents/NodeHeading"

export function BasicNode({ treeNode }: NodeRenderProps) {
  const selected = useIsNodeSelected(treeNode)

  return (
    <ContentWidthLimiting>
      <NodeWrapper selected={selected}>
        <NodeHeading onClick={() => {}} level={1}>
          {treeNode.label}
        </NodeHeading>
      </NodeWrapper>
    </ContentWidthLimiting>
  )
}
