import React from "react"
import { Pager } from "@schema/styled-ui"
import {
  SearchOptions,
  SearchResultList,
  SearchRefinement,
  SearchResultItem,
  SearchResultItemIds,
  useSearchResultBySearchTermQuery,
  fromGraphQLSearchResultItem,
  toAspectSelectorId,
  toGraphQLRefinement,
} from "@st4/search"
import { SearchQueryOptions } from "@st4/graphql"
import { useAspectId } from "@st4/settings"
import { useTranslation } from "react-i18next"
import { keys } from "@st4/ui-strings"
import { baseTestId } from "../searchResultBlade"

type SearchResultProps = {
  searchTerm: string
  searchRefinements: SearchRefinement[]
  searchOptions: SearchOptions
  page: number
  pageSize: number
  onPageChanged: (newPage: number) => void
  onPageSizeChanged: (newPageSize: number) => void
  onSearchResultItemSelected: (ids: SearchResultItemIds) => void
}

export function SearchResult_SearchTerm(props: SearchResultProps) {
  const { t } = useTranslation()
  const languageId = useAspectId()
  const searchLanguages = toAspectSelectorId(props.searchOptions.searchLanguages)
  const options: SearchQueryOptions = {
    paging: {
      page: props.page,
      pageSize: props.pageSize,
    },
    searchVersion: props.searchOptions.searchVersion as string,
    searchLanguages: undefined as string | undefined,
  }
  if (searchLanguages) options.searchLanguages = searchLanguages

  const { data, loading, error } = useSearchResultBySearchTermQuery({
    variables: {
      term: props.searchTerm,
      language: languageId as string,
      keys: props.searchOptions.searchKeys,
      refinements: props.searchRefinements.length ? props.searchRefinements.map(toGraphQLRefinement) : undefined,
      options: options,
    },
  })

  let searchResultItems = Array<SearchResultItem>()
  let totalSearchResultCount = 0
  let searchResultError: { name: string; message: string } | undefined = undefined

  if (!loading && !!data) {
    const searchResult = data.searchTerm.searchResult
    totalSearchResultCount = searchResult.resultCount
    searchResultError = error

    if (!searchResultError) {
      try {
        searchResultItems = searchResult.items.map(fromGraphQLSearchResultItem)
      } catch (exc) {
        console.error("Error converting search result items", exc)
        searchResultError = exc as Error
      }
    }
  }

  return (
    <>
      {loading ? (
        <></>
      ) : (
        <div data-testid={`${baseTestId}-pager`} style={{ minHeight: "48px", padding: "8px" }}>
          <div style={{ position: "relative", float: "right", whiteSpace: "nowrap" }}>
            <Pager
              currentPage={props.page || 1}
              pageSize={props.pageSize}
              totalCount={totalSearchResultCount}
              small
              onPageChanged={props.onPageChanged}
              onPageSizeChanged={(_, pageSize) => props.onPageSizeChanged(pageSize)}
              showTotal={(total, range) =>
                t(keys.description.search.component.searchResultPagination.totalText, {
                  rangeStart: range[0],
                  rangeEnd: range[1],
                  totalCount: total,
                })
              }
            />
          </div>
        </div>
      )}
      <SearchResultList
        searchTerm={props.searchTerm}
        loading={loading}
        searchResultItems={searchResultItems}
        error={searchResultError}
        onSearchResultItemSelected={props.onSearchResultItemSelected}
      />
    </>
  )
}
