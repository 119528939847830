import * as React from "react"
import { SVGProps } from "react"

const SvgSt4InfoNodeGroupRegular = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1em" height="1em" {...props}>
    <path d="M281.6 25.6c0 15.4-10.2 25.6-25.6 25.6S230.4 41 230.4 25.6 240.6 0 256 0s25.6 10.2 25.6 25.6zm-76.8 76.8c0-10.2 7.7-17.9 17.9-17.9H256c10.2 0 17.9 7.7 17.9 17.9v120.3h17.9c7.7 0 15.4 7.7 15.4 15.4 0 10.2-7.7 17.9-17.9 17.9h-66.6c-10.2 0-17.9-7.7-17.9-17.9 0-7.7 7.7-15.4 17.9-15.4h17.9V120.3h-17.9c-10.2 0-17.9-7.7-17.9-17.9zm45.3 232.4c1 .8 3 1.2 5.9 1.2 2 0 4-.4 5.9-1.2L452 253.3c-4.6-6.9-5.5-16.1-1.5-24 6-11.9 20.4-16.7 32.2-10.8l9.1 4.6c12.4 6.2 20.2 18.8 20.2 32.6 0 14.7-8.7 27.9-22.1 33.6l-209.1 89.6c-8.7 3.4-16.3 5.1-24.8 5.1s-17-1.7-24.8-5.1L22.8 289.6C9 283.7 0 270.1 0 255c0-12.1 5.9-23.5 15.8-30.6L26 217c10.8-7.7 25.8-5.2 33.5 5.6 6.6 9.3 5.7 21.7-2.4 29.9l193 82.3zM59.5 350.6c6.6 9.3 5.7 21.7-2.4 29.9l193 82.3c1 .8 3 1.2 5.9 1.2 2 0 4-.4 5.9-1.2L452 381.3c-4.6-6.9-5.5-16.1-1.5-24 6-11.9 20.4-16.7 32.2-10.8l9.1 4.6c12.4 6.2 20.2 18.8 20.2 32.6 0 14.7-8.7 27.9-22.1 33.6l-209.1 89.6c-8.7 3.4-16.3 5.1-24.8 5.1s-17-1.7-24.8-5.1L22.8 417.6C9 411.7 0 398.1 0 383c0-12.1 5.9-23.5 15.8-30.6L26 345c10.8-7.7 25.8-5.2 33.5 5.6z" />
  </svg>
)

export default SvgSt4InfoNodeGroupRegular
