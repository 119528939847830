import * as React from "react"
import { SVGProps } from "react"

const SvgShareNodes = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="1em" height="1em" {...props}>
    <path d="M448 112c0 44.2-35.8 80-80 80-22.9 0-43.6-9.6-58.1-25l-151 75.5c.8 4.4 1.1 8.9 1.1 13.5s-.4 9.1-1.1 13.5l151 75.5c14.6-15.4 35.2-25 58.1-25 44.2 0 80 35.8 80 80s-35.8 80-80 80-80-35.8-80-80c0-9.7 1.7-19 4.9-27.7l-145.7-72.8c-14.3 22-39 36.5-67.2 36.5-44.2 0-80-35.8-80-80s35.8-80 80-80c28.2 0 52.9 14.5 67.2 36.5l145.7-72.9c-3.2-8.6-4.9-17.9-4.9-27.7 0-44.2 35.8-80 80-80s80 35.8 80 80zM80 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm336-192a48 48 0 1 0-96 0 48 48 0 1 0 96 0zm-48 336a48 48 0 1 0 0-96 48 48 0 1 0 0 96z" />
  </svg>
)

export default SvgShareNodes
