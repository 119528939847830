import * as React from "react"
import { SVGProps } from "react"

const SvgArrowsRotate = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1em" height="1em" {...props}>
    <path d="M496 200c0 13.3-10.7 24-24 24H328c-13.3 0-24-10.7-24-24s10.7-24 24-24h86.1L362 123.9C333.8 95.8 295.7 80 256 80c-72.7 0-135.2 44.1-162 107.1-5.2 12.2-19.3 17.9-31.5 12.7s-17.9-19.3-12.7-31.5C83.9 88.2 163.4 32 256 32c52.5 0 102.8 20.8 139.9 57.9l52.1 52.2V56c0-13.3 10.7-24 24-24s24 10.7 24 24v144zM40 288h112c13.3 0 24 10.7 24 24s-10.7 24-24 24H97.9l52.1 52.1c28.2 28.1 66.3 43.9 106 43.9 72.6 0 135-43.9 161.9-106.8 5.2-12.2 19.3-17.8 31.5-12.6s17.8 19.3 12.6 31.5C427.8 424 348.5 480 256 480c-52.5 0-102.8-20.8-139.9-57.9L64 369.9V424c0 13.3-10.7 24-24 24s-24-10.7-24-24V312c0-13.3 10.7-24 24-24z" />
  </svg>
)

export default SvgArrowsRotate
