import * as React from "react"
import { SVGProps } from "react"

const SvgSquareDashed = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="1em" height="1em" {...props}>
    <path d="M88 32h16c13.3 0 24 10.7 24 24s-10.7 24-24 24H88c-22.1 0-40 17.9-40 40v16c0 13.3-10.7 24-24 24S0 149.3 0 136v-16c0-48.6 39.4-88 88-88zM24 192c13.3 0 24 10.7 24 24v80c0 13.3-10.7 24-24 24S0 309.3 0 296v-80c0-13.3 10.7-24 24-24zm400 0c13.3 0 24 10.7 24 24v80c0 13.3-10.7 24-24 24s-24-10.7-24-24v-80c0-13.3 10.7-24 24-24zm0-32c-13.3 0-24-10.7-24-24v-16c0-22.1-17.9-40-40-40h-16c-13.3 0-24-10.7-24-24s10.7-24 24-24h16c48.6 0 88 39.4 88 88v16c0 13.3-10.7 24-24 24zm24 216v16c0 48.6-39.4 88-88 88h-16c-13.3 0-24-10.7-24-24s10.7-24 24-24h16c22.1 0 40-17.9 40-40v-16c0-13.3 10.7-24 24-24s24 10.7 24 24zm-400 0v16c0 22.1 17.9 40 40 40h16c13.3 0 24 10.7 24 24s-10.7 24-24 24H88c-48.6 0-88-39.4-88-88v-16c0-13.3 10.7-24 24-24s24 10.7 24 24zm136 104c-13.3 0-24-10.7-24-24s10.7-24 24-24h80c13.3 0 24 10.7 24 24s-10.7 24-24 24h-80zM160 56c0-13.3 10.7-24 24-24h80c13.3 0 24 10.7 24 24s-10.7 24-24 24h-80c-13.3 0-24-10.7-24-24z" />
  </svg>
)

export default SvgSquareDashed
