import * as React from "react"
import { SVGProps } from "react"

const SvgEllipsis = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="1em" height="1em" {...props}>
    <path d="M432 256a48 48 0 1 1-96 0 48 48 0 1 1 96 0zm-160 0a48 48 0 1 1-96 0 48 48 0 1 1 96 0zM64 304a48 48 0 1 1 0-96 48 48 0 1 1 0 96z" />
  </svg>
)

export default SvgEllipsis
