import styled from "styled-components"

// prettier-ignore
const Body_deprecated = styled.div`
    box-sizing: border-box;
    padding: 12px;
    flex: 1;
`

export default Body_deprecated
