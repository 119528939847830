import React, { useState, useEffect } from "react"
import { Screen } from "./ui/screen"
import { Row, Col, Spin, Result } from "antd"
import { Outlet, Route, Routes, useLocation, Navigate } from "react-router-dom"
import { Headbar } from "~/components/headbar"
import { Dashboard } from "~/components/dashboard"
import { Taskbar, TasksPage } from "@st4/tasks"
import { Settings } from "@st4/settings"
import { resetHelpContext } from "./hooks/helpContext"
import { explore } from "./taskdefinitions/explore"
import { keys, Translate } from "@st4/i18n"

type AppProps = Record<string, unknown>

function App(props: AppProps) {
  const location = useLocation()
  useEffect(() => {
    resetHelpContext(location.pathname)
  }, [location])

  return (
    <Routes>
      <Route path="/" element={<AppLayout />}>
        <Route key="index" index element={<Navigate to={"/task/explore"} replace />}></Route>
        {/* <Route key="dashboard" index element={<Dashboard />} /> */}
        <Route key="settings" path="/settings" element={<Settings />} />
        <Route key="task" path="/task/:id" element={<TasksPage />} />
        <Route
          path="*"
          element={<Result status={404} title={<Translate>{keys.description.status.pageNotFound}</Translate>} />}
        />
      </Route>
    </Routes>
  )
}

function AppLayout() {
  return (
    <Screen>
      <Headbar />
      <Row style={{ flexGrow: 1, overflow: "hidden" }}>
        <Col style={{ height: "100%" }}>
          <Taskbar fixedTasks={[explore]} />
        </Col>
        <Col flex="1" style={{ overflow: "hidden" }}>
          <Outlet />
        </Col>
      </Row>
    </Screen>
  )
}

export default App
