import * as React from "react"
import { SVGProps } from "react"

const SvgInbox = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1em" height="1em" {...props}>
    <path d="M48 336h81.2l20.9 41.9c6.8 13.6 20.6 22.1 35.8 22.1h140.2c15.1 0 29-8.6 35.8-22.1l20.9-41.9H464v80c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16v-80zm406.5-48h-76.6c-15.1 0-29 8.6-35.8 22.1L321.2 352H190.8l-20.9-41.9c-6.8-13.6-20.6-22.1-35.8-22.1H57.5l49-195.9C108.2 85 114.6 80 122 80h268c7.3 0 13.7 5 15.5 12.1l49 195.9zM0 327.9V416c0 35.3 28.7 64 64 64h384c35.3 0 64-28.7 64-64v-88.1c0-5.2-.6-10.4-1.9-15.5l-58-231.9C445 52 419.4 32 390 32H122c-29.4 0-55 20-62.1 48.5l-58 231.9c-1.3 5-1.9 10.2-1.9 15.5z" />
  </svg>
)

export default SvgInbox
