import React, { useEffect, useMemo, useState } from "react"
import { Select, SelectProps } from "antd"
import styled from "styled-components"
import { SelectOutlined } from "@ant-design/icons"
import { NotificationPopover } from "@st4/ui"
import { WarningIcon } from "../warningIcon"

export type MultiSelectionEditorProps = {
  selectedValues?: string[]
  availableValues: string[]
  isReadonly?: boolean
  isRemovable?: boolean
  onSubmit: (value: string[]) => void
  errorMessage?: string
  /**
   * Override default placement of error popover to avoid popover hiding context
   */
  errorPlacement?: React.ComponentProps<typeof NotificationPopover>["placement"]
} & Pick<SelectProps, "suffixIcon">

export function MultiSelectionEditor(props: MultiSelectionEditorProps) {
  const { selectedValues, availableValues, isReadonly, isRemovable, errorMessage, errorPlacement, onSubmit } = props
  let { suffixIcon } = props
  const readonly = isReadonly ?? false
  const [selection, setSelection] = useState(selectedValues ?? [])
  const [isFocused, setIsFocused] = useState(false)

  const orderedSelection = useMemo(
    () => sortSelectedValuesInSameOrderAsAvailableValues(selection, availableValues),
    [selection, availableValues],
  )
  const availableOptionValues = useMemo(() => {
    if (isRemovable || selection.length != 1) {
      return availableValues.map((value) => convertToSelectOption(value, readonly))
    }
    return availableValues.map((value) => convertToSelectOption(value, readonly, selection[0]))
  }, [isRemovable, selection, availableValues, readonly])

  useEffect(() => {
    setSelection(selectedValues ?? [])
  }, [selectedValues])

  const hasError = !!errorMessage
  if (!suffixIcon && !readonly) suffixIcon = <SelectOutlined />

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const changeHandler = (values: any) => {
    setSelection(values)
    onSubmit(values)
  }

  return (
    <NotificationPopover
      hideIcon={true}
      open={hasError && isFocused}
      state={"error"}
      placement={errorPlacement ?? "top"}
      content={errorMessage}
    >
      <StyledSelect
        status={hasError ? "error" : ""}
        mode="multiple"
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        onChange={changeHandler}
        suffixIcon={hasError ? <WarningIcon /> : suffixIcon ?? " " /*/ TODO: Fontawesome Icon umbau*/}
        allowClear={isRemovable && !readonly}
        value={orderedSelection}
        optionFilterProp="label"
        showArrow={true}
      >
        {availableOptionValues}
      </StyledSelect>
    </NotificationPopover>
  )
}

function sortSelectedValuesInSameOrderAsAvailableValues(selectedValues: string[], availableValues: string[]) {
  return availableValues.filter((value) => selectedValues.includes(value))
}

function convertToSelectOption(value: string, readonly: boolean, disableIfEqual?: string) {
  return (
    <Select.Option
      data-testid="option" // used in jest tests
      key={value}
      value={value}
      disabled={readonly || (disableIfEqual && value === disableIfEqual)}
    >
      {value}
    </Select.Option>
  )
}

const StyledSelect = styled(Select)`
  background-color: "white";
  width: 100%;
  .ant-select {
    background-color: inherit;
    width: inherit;
  }
`
