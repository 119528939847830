import { useState, useCallback, useEffect, useRef } from "react"

/**
 * Returns a countervalue and mehtods to increment and decrement it.
 * @param initialValue The initial count (defaults to 0)
 * @param step The steps to increment/decrement the count
 */
export function useCounter(initialValue = 0, step = 1): [number, () => void, () => void] {
  const [count, setCount] = useState(initialValue)
  const inc = useCallback(() => setCount(count + step), [count, step])
  const dec = useCallback(() => setCount(count - step), [count, step])
  return [count, inc, dec]
}

type UseToggleReturn = [
  /** The current state of the toggle */
  boolean,
  /** Call to change the state of the toggle. Pass a value to set the state. */
  (targetValue?: boolean) => void,
]
/**
 * Returns a boolean state and a method to change it.
 * @param initialValue The initial value of the toggle
 * @returns
 */
export function useToggle(initialValue = false): UseToggleReturn {
  const [value, setValue] = useState(initialValue)
  const switchToggle = useCallback(
    (targetValue?: boolean) => setValue(targetValue !== undefined ? targetValue : !value),
    [value],
  )
  return [value, switchToggle]
}

export function useFetch(url: string | null): [boolean, string | null] {
  const [result, setResult] = useState<string | null>(null)
  const mounted = useRef(false)
  useEffect(() => {
    if (!url) return
    mounted.current = true
    fetch(url)
      .then((r) => r.text())
      .then((text) => {
        if (mounted.current) {
          setResult(text)
        }
      })
    return () => {
      mounted.current = false
    }
  }, [url])

  if (!url) {
    return [false, null]
  }
  return [result == null, result]
}

/**
 * Will return false until val was true once
 * @param val
 */
export function useLatch(val: boolean) {
  const wasTrueRef = useRef(false)
  if (val) wasTrueRef.current = true
  return wasTrueRef.current
}

/**
 * Debounces value changes by a specified timeout.
 * @param val
 * @param timeout
 */
export function useValueDebounce<T>(val: T, timeout: number) {
  const [valueToReturn, setValueToReturn] = useState(val)
  useEffect(() => {
    const handle = window.setTimeout(() => {
      setValueToReturn(() => val)
    }, timeout)
    return () => window.clearTimeout(handle)
  }, [val])
  return valueToReturn
}

export function useIntersectionObserver<TElement extends HTMLElement>() {
  const [isOnScreen, setOnScreen] = useState(false)
  const previousObserver = useRef<IntersectionObserver | null>(null)

  const customRef = useCallback((node: TElement | null) => {
    if (previousObserver.current) {
      previousObserver.current.disconnect()
      previousObserver.current = null
    }

    if (node) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          setOnScreen(entry.isIntersecting)
        },
        {
          root: null,
          rootMargin: "0px",
          threshold: 0.1,
        },
      )

      observer.observe(node)
      previousObserver.current = observer
    }
  }, [])
  return [customRef, isOnScreen] as const
}
