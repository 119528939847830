import * as React from "react"
import { SVGProps } from "react"

const SvgSidebarFlip = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1em" height="1em" {...props}>
    <path d="M64 64c-17.7 0-32 14.3-32 32v320c0 17.7 14.3 32 32 32h224V64H64zm384 0H320v384h128c17.7 0 32-14.3 32-32V96c0-17.7-14.3-32-32-32zm0-32c35.3 0 64 28.7 64 64v320c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96c0-35.3 28.7-64 64-64h384zm-16 64c8.8 0 16 7.2 16 16s-7.2 16-16 16h-64c-8.8 0-16-7.2-16-16s7.2-16 16-16h64zm16 80c0 8.8-7.2 16-16 16h-64c-8.8 0-16-7.2-16-16s7.2-16 16-16h64c8.8 0 16 7.2 16 16zm-16 48c8.8 0 16 7.2 16 16s-7.2 16-16 16h-64c-8.8 0-16-7.2-16-16s7.2-16 16-16h64z" />
  </svg>
)

export default SvgSidebarFlip
