import { visit } from "unist-util-visit"
import { XASTNode } from "../types"

export function flattenTree(tree: XASTNode) {
  if (!tree) return []

  const nodeList = new Array<XASTNode>()
  visit(tree, (node, ancestors) => {
    nodeList.push(node)
  })
  return nodeList
}
