import type { Node } from "unist"
import { map } from "unist-util-map"
import { findNodeAtOffsetStart } from "./utils"
import { parents } from "unist-util-parents"
import type { ExtractByTypeName } from "@st4/graphql"
import { isTypename } from "@st4/graphql"
import type { ITextContentPreviewFragment } from "../graphql/applicationQueries/query.hooks"

function getCommentedNode(foundNode: Node, parent: Node, index: number) {
  if (foundNode.nodeType === "Whitespace" && parent.children?.length) {
    return parent.children[index + 1]
  } else {
    return foundNode
  }
}

type CommentNodeWithBlockComment = Omit<ITextContentPreviewFragment["comments"][number], "comment"> & {
  comment: ExtractByTypeName<"BlockComment", ITextContentPreviewFragment["comments"][number]["comment"]>
}

type commentsSplitResult = {
  blockcomments: Array<{ comment: CommentNodeWithBlockComment; node: Node; treeNode: { id: string } }>
}

function splitComments(node: Node, comments: CommentNodeWithBlockComment[], treeNode: { id: string }) {
  return comments.reduce(
    (agg: commentsSplitResult, comm) => {
      const nodeFindResult = findNodeAtOffsetStart(node, comm.comment.location)
      if (!nodeFindResult) return agg
      const [foundNode, index, parent] = nodeFindResult
      if (["content", "th", "td"].indexOf(foundNode.parent?.tagName || "") >= 0) {
        //according to the dtd these tag can contain block comments as direct children
        const commentedNode = getCommentedNode(foundNode, parent, index)
        return {
          blockcomments: [...agg.blockcomments, { comment: comm, node: commentedNode, treeNode }],
        }
      } else {
        return {
          blockcomments: [...agg.blockcomments],
        }
      }
    },
    {
      blockcomments: [],
    },
  )
}

function mapBlockComment(node: Node, comments: commentsSplitResult["blockcomments"], treeNode: { id: string }): Node {
  const commentsAtElement = comments.filter((com) => com.node == node)

  return {
    ...node,
    data: {
      ...node.data,
      blockcomments: commentsAtElement.map(({ comment }) => ({ comment, treeNode })),
    },
  }
}

function isCommentNodeWithBlockComment(
  node: Pick<ITextContentPreviewFragment["comments"][number], "comment">,
): node is CommentNodeWithBlockComment {
  return isTypename("BlockComment")(node.comment)
}
/**
 * Transforms the passed node to add comments as {data: {blockcomment:[]}} where applicable.
 * @param node The node to transform
 * @param comments A collection of comments
 */
export function addComments(
  node: Node,
  comments: ITextContentPreviewFragment["comments"],
  treeNode: { id: string },
): Node {
  //TODO: Abgleichen auf offizielle XAST Spec
  const withParents = parents(node)
  const typeFilteredComments = comments.filter(isCommentNodeWithBlockComment)
  const { blockcomments } = splitComments(withParents, typeFilteredComments, treeNode)
  const nodeWithCommentsInData = map(withParents, (currentNode) => {
    const xastNode = currentNode
    const nodeWithBlockComment = mapBlockComment(xastNode, blockcomments, treeNode)
    if (nodeWithBlockComment) return nodeWithBlockComment
    return currentNode
  })
  return nodeWithCommentsInData
}
