import { TreeSelect } from "antd"
import React, { useMemo, useState, useEffect, useCallback } from "react"
import { arraysAreEqual } from "./compareFunctions"
import { DisableEntries, MarkInheritedEntries, MarkUnselectableEntries } from "./labelModificationFunctions"
import { assureOrderMatchesTreeOrder } from "./sortSelection"
import { StyledTag } from "./styledTag"
import { CommonTaxonomyTreeSelectProps, TaxonomyDisplayInfo, TreeNode } from "./types"
import { MultiSelectionTaxonomyTreeSelect } from "./multiSelectionTaxonomyTreeSelect"
import { SingleSelectionTaxonomyTreeSelect } from "./singleSelectionTaxonomyTreeSelect"
import { useApolloClient } from "@apollo/client"
import { NotificationPopover } from "@st4/ui"
import { WarningIcon } from "../warningIcon"

export type TaxonomyEditorProps = {
  selectedNodes?: TaxonomyDisplayInfo[]
  taxonomyTree: TreeNode[]
  isReadonly?: boolean
  suffixIcon?: React.ReactNode
  onSubmit: (selection: string[], previousSelection: string[]) => void
  renderDropdown?: () => JSX.Element
  isInherited?: boolean
  taxonomyColor?: string
  filterCallback: (inputValue: string, node?: { value?: number | string }) => boolean
  refetchTaxTree?: () => void
  isMultiSelect: boolean
  errorMessage?: string
  /**
   * Override default placement of error popover to avoid popover hiding context
   */
  errorPlacement?: React.ComponentProps<typeof NotificationPopover>["placement"]
}

export function TaxonomyEditor({
  selectedNodes,
  taxonomyTree,
  isReadonly,
  suffixIcon,
  onSubmit,
  renderDropdown,
  isInherited,
  taxonomyColor,
  filterCallback,
  refetchTaxTree,
  isMultiSelect,
  errorMessage,
  errorPlacement,
}: TaxonomyEditorProps) {
  const apolloClient = useApolloClient()
  const initialSelection = selectedNodes ?? []
  const [selection, setSelection] = useState(initialSelection)
  const [savedSelection, setSavedSelection] = useState(initialSelection)

  const orderedSelection = useMemo(
    () => assureOrderMatchesTreeOrder(selection, taxonomyTree),
    [selection, taxonomyTree],
  )

  const [dropdownVisible, setDropdownVisible] = useState(false)
  const [isFocused, setFocusState] = useState(false)

  useEffect(() => {
    if (dropdownVisible) {
      apolloClient.refetchQueries({ include: ["OntologyInfo"] })
    }
  }, [apolloClient, dropdownVisible])

  if (!arraysAreEqual(savedSelection, initialSelection)) {
    setSavedSelection(initialSelection)
    if (!dropdownVisible) setSelection(initialSelection)
  }

  const isInheritedValue = (isInherited && (arraysAreEqual(selection, initialSelection) || !selection.length)) ?? false

  const saveOrReset = (changedSelection: TaxonomyDisplayInfo[]) => {
    const previousSelection = isInheritedValue ? [] : orderedSelection
    setSelection(assureOrderMatchesTreeOrder(changedSelection, taxonomyTree))

    onSubmit(
      changedSelection.map((n) => n.value),
      previousSelection.map((n) => n.value),
    )
  }

  const displayTree = markTaxonomyTreeNodes(
    taxonomyTree,
    isInheritedValue,
    dropdownVisible,
    initialSelection,
    isReadonly,
  )

  const hasError = !!errorMessage
  const wrappedSuffixIcon = (
    <div
      onClick={() => {
        setDropdownVisible(!dropdownVisible)
      }}
    >
      {hasError ? <WarningIcon /> : suffixIcon}
    </div>
  )
  const onFocus = useCallback(() => {
    setFocusState(true)
  }, [])
  const onBlur = useCallback(() => {
    setFocusState(false)
  }, [])

  const commonTaxonomyTreeSelectProps: CommonTaxonomyTreeSelectProps = {
    saveOrReset,
    treeData: displayTree,
    isInheritedValue: isInheritedValue,

    treeSelectProps: {
      labelInValue: true,
      style: { width: "100%" },
      treeDefaultExpandAll: true,
      filterTreeNode: filterCallback,
      status: hasError ? "error" : undefined,
      virtual: false,
      showArrow: !isReadonly,
      dropdownMatchSelectWidth: 270,
      suffixIcon: wrappedSuffixIcon,
      dropdownRender: renderDropdown,
      value: selection,
      multiple: true, // even true in single select mode, because the initial selection might have multiple values checked
      open: dropdownVisible,
      disabled: isReadonly,
      onBlur,
      onFocus,
      treeNodeLabelProp: "titleString",
      onDropdownVisibleChange: (open) => {
        setDropdownVisible(open)
        if (open && refetchTaxTree) {
          refetchTaxTree()
        }
        if (isInheritedValue) setSelection(open ? [] : initialSelection)
      },
      showCheckedStrategy: TreeSelect.SHOW_ALL,
      tagRender: (p) => (
        <StyledTag $taxColor={taxonomyColor ?? `#000000`} $isInherited={isInheritedValue}>
          {p.label}
        </StyledTag>
      ),
      showSearch: !renderDropdown || !dropdownVisible, // prevent search, when error is shown (but still use the text edit cursor, as long as the dropdown is not visible)
    },
  }
  return (
    <NotificationPopover
      hideIcon={true}
      open={hasError && isFocused}
      state={"error"}
      placement={errorPlacement ?? "top"}
      content={errorMessage}
    >
      {isMultiSelect ? (
        <MultiSelectionTaxonomyTreeSelect {...commonTaxonomyTreeSelectProps} />
      ) : (
        <SingleSelectionTaxonomyTreeSelect {...commonTaxonomyTreeSelectProps} />
      )}
    </NotificationPopover>
  )
}

function markTaxonomyTreeNodes(
  taxonomyTree: TreeNode[],
  isInheritedValue: boolean,
  dropdownVisible: boolean,
  initiallySelectedValues: TaxonomyDisplayInfo[],
  isReadOnly: boolean | undefined,
) {
  let displayTree = MarkInheritedEntries(taxonomyTree, isInheritedValue && dropdownVisible, initiallySelectedValues)

  if (isReadOnly === true) {
    displayTree = DisableEntries(displayTree)
  }

  displayTree = MarkUnselectableEntries(displayTree)

  return displayTree
}
