import React from "react"
import { Column, Entry, Row, VariableTable } from "./types"

export function renderCell(rows: Row[], entry: Entry) {
  const cellContent = entry.columnIndex === 0 ? rows[entry.rowIndex].title : entry.value
  return <>{cellContent}</>
}

function getValuesFromRow(r: Element) {
  return Array.from(r.children)
    .slice(1) //the first element is the title of the row
    .reduce((agg, e) => {
      const sAttr = e.getAttribute("s") // The value inside this cell is repeated 's' times.
      const count = (sAttr && parseInt(sAttr)) || 1
      Array.prototype.push.apply(agg, new Array(count).fill(e.textContent))
      return agg
    }, new Array<string | null>())
}
type VariableTableInput = {
  valueSets: {
    valueSetId: string
    name: string
  }[]
  variables: {
    variableId: string
    name: string
    values: string[]
  }[]
}
export function transformVariables(variableTable: VariableTableInput): VariableTable {
  const headers = variableTable.valueSets.map((vs) => ({ dataIndex: vs.valueSetId, title: vs.name }))
  const rows = variableTable.variables.map((v) => ({ id: v.variableId, title: v.name }))

  const dataSource = variableTable.variables.map((v, rowIdx) => ({
    rowTitle: {
      columnIndex: 0,
      rowIndex: rowIdx,
      value: "",
    },
    ...variableTable.valueSets.reduce(
      (agg, curr, vsIdx) => ({
        ...agg,
        [curr.valueSetId]: {
          columnIndex: vsIdx + 1,
          rowIndex: rowIdx,
          value: v.values?.[vsIdx] ?? "",
        },
      }),
      {},
    ),
  }))
  return {
    dataSource: dataSource,
    columns: [{ dataIndex: "rowTitle", title: "", fixed: "left" }, ...headers],
    rows,
  }
}

export function parseVariables(xml: string): VariableTable {
  const parser = new DOMParser()
  const doc = parser.parseFromString(xml, "application/xml")
  const children = Array.from(doc.children[0].children)

  if (!children?.length)
    return {
      dataSource: [],
      columns: [],
      rows: [],
    }

  const headerRow = children.shift()
  const headers = Array.from(headerRow!.children).map((e) => ({
    dataIndex: e.getAttribute("id"),
    title: e.textContent,
  })) as Column[]

  const parsedRows = children.map((r) => ({
    id: r.getAttribute("id") ?? "Error",
    title: r.children[0].textContent ?? "",
  }))

  const rows = []
  for (let i = 0; i < parsedRows.length; i++) {
    const entry: Record<string, Entry> = {
      rowTitle: {
        columnIndex: 0,
        rowIndex: i,
        value: "",
      },
    }

    const valuesFromRow = getValuesFromRow(children[i])

    for (let j = 0; j < headers.length; j++) {
      const cell: Entry = {
        columnIndex: j + 1,
        rowIndex: i,
        value: valuesFromRow[j] || "",
      }
      entry[headers[j].dataIndex] = cell
    }
    rows.push(entry)
  }

  return {
    dataSource: rows,
    columns: [{ dataIndex: "rowTitle", title: "", fixed: "left" }, ...headers],
    rows: parsedRows,
  }
}
