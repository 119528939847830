function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _objectSpread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _defineProperty(target, key, source[key]);
        });
    }
    return target;
}
function _taggedTemplateLiteral(strings, raw) {
    if (!raw) {
        raw = strings.slice(0);
    }
    return Object.freeze(Object.defineProperties(strings, {
        raw: {
            value: Object.freeze(raw)
        }
    }));
}
function _templateObject() {
    var data = _taggedTemplateLiteral([
        "\n  fragment GraphicContentEditor on IGraphicContent {\n    media {\n      ... on ImageMediaInfo {\n        src\n        thumbnail\n        resourceId\n      }\n      ... on VectorGraphicsMediaInfo {\n        svg\n        thumbnail\n        resourceId\n      }\n      ... on AnimationMediaInfo {\n        id\n        thumbnail\n      }\n      ... on VideoMediaInfo {\n        id\n        thumbnail\n      }\n    }\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _taggedTemplateLiteral([
        "\n  fragment ImageDataEditor on ITextContent {\n    images {\n      id\n      target {\n        ... on ST4Node {\n          content {\n            ...GraphicContentEditor\n          }\n        }\n      }\n    }\n  }\n  ",
        "\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _taggedTemplateLiteral([
        "\n  fragment VariablesDataEditor on ITextContent {\n    variableTables {\n      ref\n      node {\n        ... on ST4Node {\n          id\n          label\n          content {\n            ... on VariableTableContent {\n              xml\n            }\n          }\n        }\n      }\n    }\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _taggedTemplateLiteral([
        "\n  fragment LinkDataEditor on ITextContent {\n    links {\n      ref\n      node {\n        id\n        ... on ST4Node {\n          label\n        }\n      }\n    }\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _taggedTemplateLiteral([
        '\n  fragment LinkedDataNodesDataEditor on ITextContent {\n    linkedDataNodes {\n      id\n      target {\n        id\n        ... on ST4Node {\n          label\n          metadata(users: ["Referable"], systemNames: ["Title"]) {\n            label\n            systemName\n            value\n            values\n            users\n          }\n          linkedOntologyNodes: leavingLinks(linkClass: ["ObjectToOntologyLink"]) {\n            ... on NodeRef {\n              linkClassName\n              class {\n                label\n              }\n            }\n            target {\n              ... on ST4Node {\n                id\n                label\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n'
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _taggedTemplateLiteral([
        "\n  fragment ContentEditor on ITextContent {\n    xml(mode: EDITOR_NODE)\n    ...ImageDataEditor\n    ...VariablesDataEditor\n    ...LinkDataEditor\n    ...LinkedDataNodesDataEditor\n  }\n  ",
        "\n  ",
        "\n  ",
        "\n  ",
        "\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _taggedTemplateLiteral([
        "\n  fragment FragmentDataEditor on ITextContent {\n    fragments {\n      compoundId\n      contentNode {\n        id\n        content {\n          __typename\n          ... on ITextContent {\n            ...ContentEditor\n          }\n        }\n      }\n    }\n  }\n  ",
        "\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
function _templateObject7() {
    var data = _taggedTemplateLiteral([
        "\n  fragment PartDataEditor on ITextContent {\n    parts {\n      ref\n      contentNode {\n        id\n        content {\n          __typename\n          ... on ITextContent {\n            ...ContentEditor\n          }\n        }\n      }\n    }\n  }\n  ",
        "\n"
    ]);
    _templateObject7 = function _templateObject7() {
        return data;
    };
    return data;
}
function _templateObject8() {
    var data = _taggedTemplateLiteral([
        "\n  query EditorConfig($languageId: AspectSelectorId!) {\n    configuration(languageId: $languageId) {\n      safetyModel {\n        safetyTypes {\n          displayName\n          id\n          icons {\n            use\n            iconName\n            contentNode {\n              id\n              ... on ST4Node {\n                content {\n                  ... on MediaContent {\n                    media {\n                      ... on ImageMediaInfo {\n                        id\n                        src\n                        thumbnail\n                      }\n                      ... on VectorGraphicsMediaInfo {\n                        id\n                        svg\n                        thumbnail\n                        resourceId\n                      }\n                      ... on AnimationMediaInfo {\n                        id\n                        thumbnail\n                      }\n                      ... on VideoMediaInfo {\n                        id\n                        thumbnail\n                      }\n                    }\n                  }\n                }\n              }\n            }\n          }\n        }\n        severityLevels {\n          id\n          displayName\n        }\n      }\n      languageXmlValues {\n        label\n        value\n      }\n      commentModel {\n        availableTypes {\n          label\n          value\n        }\n        availableStates {\n          label\n          value\n        }\n      }\n      inlineStyleDefinitions {\n        type\n        displayName\n        backgroundColor\n        color\n        fontFamily\n        fontSize\n        fontStyle\n        fontWeight\n        textDecoration\n        verticalAlign\n      }\n    }\n  }\n"
    ]);
    _templateObject8 = function _templateObject8() {
        return data;
    };
    return data;
}
function _templateObject9() {
    var data = _taggedTemplateLiteral([
        "\n  mutation OpenEditorSessionForNode(\n    $selectedTreeNode: StPatternContextId!\n    $languageId: AspectSelectorId!\n    $editorName: String\n  ) {\n    openEditorSessionForNode(selectedTreeNode: $selectedTreeNode, languageId: $languageId, editorName: $editorName) {\n      ... on EditorSessionSuccess {\n        result {\n          editorSessionInfo {\n            id\n            newVersionLimit\n          }\n          warnings {\n            message\n            messageKey\n            messageArgs {\n              label\n              value\n            }\n          }\n        }\n      }\n      ... on EditorSessionError {\n        reasons {\n          message\n          messageKey\n          messageArgs {\n            label\n            value\n          }\n        }\n      }\n    }\n  }\n"
    ]);
    _templateObject9 = function _templateObject9() {
        return data;
    };
    return data;
}
function _templateObject10() {
    var data = _taggedTemplateLiteral([
        "\n  query EditorContentDataForNode($id: ID!, $languageId: AspectSelectorId!, $variant: String) {\n    node(id: $id, languageId: $languageId, variant: $variant) {\n      ... on ST4TreeNode {\n        __typename\n        dynamicMetaRefs {\n          value\n          metaRefPath {\n            id\n            displayName\n            expression\n          }\n        }\n        node {\n          ... on ST4Node {\n            id\n            content {\n              __typename\n              ... on ITextContent {\n                ...ContentEditor\n                ...FragmentDataEditor\n                ...PartDataEditor\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n  ",
        "\n  ",
        "\n  ",
        "\n"
    ]);
    _templateObject10 = function _templateObject10() {
        return data;
    };
    return data;
}
function _templateObject11() {
    var data = _taggedTemplateLiteral([
        "\n  mutation CloseEditorSession($editorSessionId: StObjectId!) {\n    closeEditorSession(editorSessionId: $editorSessionId) {\n      ... on CloseEditorSessionSuccess {\n        result {\n          __typename\n          id\n          editorSession(editorType: FONTO) {\n            __typename\n          }\n        }\n      }\n      ... on Error {\n        message\n      }\n    }\n  }\n"
    ]);
    _templateObject11 = function _templateObject11() {
        return data;
    };
    return data;
}
function _templateObject12() {
    var data = _taggedTemplateLiteral([
        "\n  mutation SaveEditorNodeContent(\n    $selectedNode: StObjectId!\n    $languageId: AspectSelectorId!\n    $xml: String!\n    $newVersionLimit: String!\n    $isAutoSave: Boolean!\n  ) {\n    saveEditorNodeContent(\n      selectedNode: $selectedNode\n      languageId: $languageId\n      xml: $xml\n      newVersionLimit: $newVersionLimit\n      isAutoSave: $isAutoSave\n    ) {\n      ... on SaveEditorNodeSuccess {\n        result {\n          savedNode {\n            id\n            label\n            content {\n              __typename\n              ... on TextContent {\n                xml(mode: EDITOR_NODE)\n              }\n            }\n          }\n          warnings {\n            messageKey\n            message\n            messageArgs {\n              label\n              value\n            }\n          }\n        }\n      }\n      ... on SaveEditorNodeFailure {\n        reasons {\n          messageKey\n          message\n          messageArgs {\n            label\n            value\n          }\n        }\n      }\n    }\n  }\n"
    ]);
    _templateObject12 = function _templateObject12() {
        return data;
    };
    return data;
}
function _templateObject13() {
    var data = _taggedTemplateLiteral([
        "\n  query ImageByTree(\n    $selectedNodes: [StPatternContextId!]\n    $languageId: AspectSelectorId!\n    $variant: String\n    $limit: Int\n  ) {\n    tree(selectedNodes: $selectedNodes, languageId: $languageId, variant: $variant, limit: $limit) {\n      nodes {\n        id\n        node {\n          __typename\n          ... on ST4Node {\n            id\n            content {\n              ...GraphicContentEditor\n            }\n          }\n        }\n      }\n    }\n  }\n  ",
        "\n"
    ]);
    _templateObject13 = function _templateObject13() {
        return data;
    };
    return data;
}
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
var defaultOptions = {};
export var GraphicContentEditorFragmentDoc = gql(_templateObject());
export var ImageDataEditorFragmentDoc = gql(_templateObject1(), GraphicContentEditorFragmentDoc);
export var VariablesDataEditorFragmentDoc = gql(_templateObject2());
export var LinkDataEditorFragmentDoc = gql(_templateObject3());
export var LinkedDataNodesDataEditorFragmentDoc = gql(_templateObject4());
export var ContentEditorFragmentDoc = gql(_templateObject5(), ImageDataEditorFragmentDoc, VariablesDataEditorFragmentDoc, LinkDataEditorFragmentDoc, LinkedDataNodesDataEditorFragmentDoc);
export var FragmentDataEditorFragmentDoc = gql(_templateObject6(), ContentEditorFragmentDoc);
export var PartDataEditorFragmentDoc = gql(_templateObject7(), ContentEditorFragmentDoc);
export var EditorConfigDocument = gql(_templateObject8());
/**
 * __useEditorConfigQuery__
 *
 * To run a query within a React component, call `useEditorConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditorConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditorConfigQuery({
 *   variables: {
 *      languageId: // value for 'languageId'
 *   },
 * });
 */ export function useEditorConfigQuery(baseOptions) {
    var options = _objectSpread({}, defaultOptions, baseOptions);
    return Apollo.useQuery(EditorConfigDocument, options);
}
export function useEditorConfigLazyQuery(baseOptions) {
    var options = _objectSpread({}, defaultOptions, baseOptions);
    return Apollo.useLazyQuery(EditorConfigDocument, options);
}
export var OpenEditorSessionForNodeDocument = gql(_templateObject9());
/**
 * __useOpenEditorSessionForNodeMutation__
 *
 * To run a mutation, you first call `useOpenEditorSessionForNodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOpenEditorSessionForNodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [openEditorSessionForNodeMutation, { data, loading, error }] = useOpenEditorSessionForNodeMutation({
 *   variables: {
 *      selectedTreeNode: // value for 'selectedTreeNode'
 *      languageId: // value for 'languageId'
 *      editorName: // value for 'editorName'
 *   },
 * });
 */ export function useOpenEditorSessionForNodeMutation(baseOptions) {
    var options = _objectSpread({}, defaultOptions, baseOptions);
    return Apollo.useMutation(OpenEditorSessionForNodeDocument, options);
}
export var EditorContentDataForNodeDocument = gql(_templateObject10(), ContentEditorFragmentDoc, FragmentDataEditorFragmentDoc, PartDataEditorFragmentDoc);
/**
 * __useEditorContentDataForNodeQuery__
 *
 * To run a query within a React component, call `useEditorContentDataForNodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditorContentDataForNodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditorContentDataForNodeQuery({
 *   variables: {
 *      id: // value for 'id'
 *      languageId: // value for 'languageId'
 *      variant: // value for 'variant'
 *   },
 * });
 */ export function useEditorContentDataForNodeQuery(baseOptions) {
    var options = _objectSpread({}, defaultOptions, baseOptions);
    return Apollo.useQuery(EditorContentDataForNodeDocument, options);
}
export function useEditorContentDataForNodeLazyQuery(baseOptions) {
    var options = _objectSpread({}, defaultOptions, baseOptions);
    return Apollo.useLazyQuery(EditorContentDataForNodeDocument, options);
}
export var CloseEditorSessionDocument = gql(_templateObject11());
/**
 * __useCloseEditorSessionMutation__
 *
 * To run a mutation, you first call `useCloseEditorSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloseEditorSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closeEditorSessionMutation, { data, loading, error }] = useCloseEditorSessionMutation({
 *   variables: {
 *      editorSessionId: // value for 'editorSessionId'
 *   },
 * });
 */ export function useCloseEditorSessionMutation(baseOptions) {
    var options = _objectSpread({}, defaultOptions, baseOptions);
    return Apollo.useMutation(CloseEditorSessionDocument, options);
}
export var SaveEditorNodeContentDocument = gql(_templateObject12());
/**
 * __useSaveEditorNodeContentMutation__
 *
 * To run a mutation, you first call `useSaveEditorNodeContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveEditorNodeContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveEditorNodeContentMutation, { data, loading, error }] = useSaveEditorNodeContentMutation({
 *   variables: {
 *      selectedNode: // value for 'selectedNode'
 *      languageId: // value for 'languageId'
 *      xml: // value for 'xml'
 *      newVersionLimit: // value for 'newVersionLimit'
 *      isAutoSave: // value for 'isAutoSave'
 *   },
 * });
 */ export function useSaveEditorNodeContentMutation(baseOptions) {
    var options = _objectSpread({}, defaultOptions, baseOptions);
    return Apollo.useMutation(SaveEditorNodeContentDocument, options);
}
export var ImageByTreeDocument = gql(_templateObject13(), GraphicContentEditorFragmentDoc);
/**
 * __useImageByTreeQuery__
 *
 * To run a query within a React component, call `useImageByTreeQuery` and pass it any options that fit your needs.
 * When your component renders, `useImageByTreeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImageByTreeQuery({
 *   variables: {
 *      selectedNodes: // value for 'selectedNodes'
 *      languageId: // value for 'languageId'
 *      variant: // value for 'variant'
 *      limit: // value for 'limit'
 *   },
 * });
 */ export function useImageByTreeQuery(baseOptions) {
    var options = _objectSpread({}, defaultOptions, baseOptions);
    return Apollo.useQuery(ImageByTreeDocument, options);
}
export function useImageByTreeLazyQuery(baseOptions) {
    var options = _objectSpread({}, defaultOptions, baseOptions);
    return Apollo.useLazyQuery(ImageByTreeDocument, options);
}
