import * as React from "react"
import { SVGProps } from "react"

const SvgSt4CellLeftAlignedRegular = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="1em" height="1em" {...props}>
    <path d="M384 32H64C28.7 32 0 60.7 0 96v320c0 35.3 28.7 64 64 64h320c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64zm16 370.7c0 16.2-13.1 29.3-29.3 29.3H77.3C61.1 432 48 418.9 48 402.7V109.3C48 93.1 61.1 80 77.3 80h293.3c16.2 0 29.3 13.1 29.3 29.3v293.4zM282.8 184h-153c-13.3 0-24-10.7-24-24s10.7-24 24-24h153c13.3 0 24 10.7 24 24s-10.7 24-24 24zm35.4 192H129.8c-13.3 0-24-10.7-24-24s10.7-24 24-24h188.3c13.3 0 24 10.7 24 24s-10.7 24-23.9 24zm-105.5-96h-82.8c-13.3 0-24-10.7-24-24s10.7-24 24-24h82.8c13.3 0 24 10.7 24 24s-10.8 24-24 24z" />
  </svg>
)

export default SvgSt4CellLeftAlignedRegular
