import React, { useEffect, useMemo, useState } from "react"
import { useVariableTableByVariablePoolQuery } from "./query.hooks"
import { isTypename } from "@st4/graphql"
import { Entry, VariableTable, renderCell, transformVariables } from "@st4/content-tools"
import styled from "styled-components"
import { Empty, Select, Skeleton, Table, TableProps } from "antd"
import { VariableInfo } from "."
import { variableType as referenceType } from "./types"
import { Translate, keys } from "@st4/i18n"

const FormGroup = styled.fieldset`
  margin-bottom: 16px;
`

const TextContainer = styled.span`
  margin-right: 8px;
`

const StyledTable = styled(Table as (props: TableProps<Record<string, Entry>>) => JSX.Element)`
  .ant-table-cell:first-child {
    background-color: #fafafa;
    font-weight: 500;
    cursor: auto;
  }
  .ant-table-cell:not(th) {
    min-width: 10em;
    cursor: pointer;
  }
  .ant-table-sticky-scroll {
    display: none;
  }
  & .ant-table-tbody > tr > td {
    white-space: pre;
  }
  .selected {
    background-color: #baf48a !important;
  }
`

type VariablePoolTableProps = {
  selectedNode?: {
    id: string
    classHierarchy: string[]
  }
  aspectId: string
  setVariableTable: (newVaribleTable: VariableTable) => void
  setSelectedVariable: (variableInfo?: VariableInfo) => void
}

export function VariablePoolTable(props: VariablePoolTableProps) {
  const [selectedReferenceType, setSelectedReferenceType] = useState<referenceType>("cell")
  const [selectedCell, setSelectedCell] = useState<Entry>()

  const { selectedNode, aspectId, setVariableTable, setSelectedVariable } = props
  const { data, loading, error } = useVariableTableByVariablePoolQuery({
    variables: {
      selectedNode: selectedNode?.id ?? "",
      aspectId: aspectId,
      variant: "Preview",
    },
    fetchPolicy: "network-only",
    skip: selectedNode?.classHierarchy.at(-1) !== "VariableNode",
  })

  const variableTableSource = useMemo(() => {
    if (
      !isTypename("ST4TreeNode")(data?.node) ||
      !isTypename("ST4Node")(data?.node.node) ||
      !isTypename("VariableTableContent")(data?.node.node.content) ||
      !data?.node.node.content
    )
      return null

    return transformVariables(data?.node.node.content)
  }, [data])

  useEffect(() => {
    if (variableTableSource) {
      setVariableTable(variableTableSource)
    }
  }, [variableTableSource, setVariableTable])

  if (loading) return <Skeleton active />
  if (error) return <div>{error.message}</div>
  if (!variableTableSource) return <Empty />

  const columns = (variableTableSource?.columns ?? []).map((column, columnIndex) => ({
    ...column,
    render: renderCell.bind(null, variableTableSource.rows),
    onCell: (entry: Record<string, Entry>) => {
      const entryByColumnId = getEntryByColumnIndex(entry, columnIndex)
      if (!entryByColumnId) return {}

      if (columnIndex === 0) {
        if (selectedReferenceType !== "variable" && selectedReferenceType !== "variableName") return {}

        return {
          className: selectedCell?.rowIndex === entryByColumnId?.rowIndex ? "selected" : "",
        }
      }

      if (!selectedCell)
        return {
          onClick: () => handleCellClick(entryByColumnId),
        }

      const isSelectedCell =
        entryByColumnId.columnIndex === selectedCell.columnIndex && entryByColumnId.rowIndex === selectedCell.rowIndex

      const isVariableAndSameRowAsSelected =
        selectedReferenceType === "variable" && selectedCell.rowIndex === entryByColumnId.rowIndex

      return {
        onClick: () => handleCellClick(entryByColumnId),
        className: isSelectedCell || isVariableAndSameRowAsSelected ? "selected" : "",
      }
    },
    onHeaderCell: () => {
      if (selectedReferenceType === "nameOfValueSet" && selectedCell?.columnIndex === columnIndex) {
        return {
          className: "selected",
        }
      }
      return {}
    },
  }))

  const handleReferenceTypeChange = (referenceType: referenceType) => {
    setSelectedReferenceType(referenceType)
    handleSetVariable(referenceType, selectedCell)
  }

  const handleCellClick = (entry: Entry) => {
    setSelectedCell(entry)
    handleSetVariable(selectedReferenceType, entry)
  }

  function handleSetVariable(referenceType: referenceType, currentCell?: Entry) {
    if (!variableTableSource || !currentCell) {
      setSelectedVariable()
      return
    }

    const variableInfo = getVariableInfo(variableTableSource, currentCell, referenceType)
    if (!variableInfo) {
      setSelectedVariable()
      return
    }

    setSelectedVariable(variableInfo)
  }

  const variableReferenceTypesList = [
    { value: "cell", label: <Translate>{keys.label.variable.referenceType.cell}</Translate> },
    { value: "variable", label: <Translate>{keys.label.variable.referenceType.variable}</Translate> },
    { value: "nameOfValueSet", label: <Translate>{keys.label.variable.referenceType.nameOfValueSet}</Translate> },
    { value: "variableName", label: <Translate>{keys.label.variable.referenceType.variableName}</Translate> },
  ]

  return (
    <div>
      <FormGroup>
        <TextContainer>
          <Translate>{keys.label.variablePoolBlade.referenceType}</Translate>
        </TextContainer>
        <Select
          data-testid="variablepool:reference:type:selection"
          value={selectedReferenceType}
          options={variableReferenceTypesList}
          onChange={handleReferenceTypeChange}
          dropdownMatchSelectWidth={false}
        />
      </FormGroup>
      <StyledTable
        rowKey={(r) => r.rowTitle.rowIndex}
        dataSource={variableTableSource?.dataSource}
        columns={columns}
        scroll={{ x: true }}
        sticky
        pagination={{ hideOnSinglePage: true }}
      />
    </div>
  )
}

function getEntryByColumnIndex(entry: Record<string, Entry>, columnIndex: number) {
  for (const key in entry) {
    if (entry[key].columnIndex === columnIndex) {
      return entry[key]
    }
  }
  return null
}

function getVariableInfo(
  variableTableSource: VariableTable,
  selectedCell: Entry,
  referenceType: referenceType,
): VariableInfo | null {
  const currentColumn = variableTableSource?.columns[selectedCell.columnIndex]
  const currentRow = variableTableSource?.rows[selectedCell.rowIndex]

  if (!currentColumn || !currentRow) return null

  switch (referenceType) {
    case "cell":
      return { name: currentRow.id, variant: currentColumn.dataIndex }
    case "variable":
      return { name: currentRow.id, variant: null }
    case "nameOfValueSet":
      return { name: "0", variant: currentColumn.dataIndex }
    case "variableName":
      return { name: currentRow.id, variant: "0" }
  }
}
