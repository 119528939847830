import { TreeViewProps } from "./types"
import { useIndentedTreeState } from "./state"
import React, { useCallback } from "react"
import { DraggableTreeViewItems } from "./draggabletreeviewitems"
import { TreeViewItems } from "./treeviewitems"

export function TreeView(props: TreeViewProps) {
  const {
    orderedTreeNodes,
    expandedNodes,
    onSelect,
    selected,
    name,
    treeOperation,
    onExpandChange,
    onCheckChange,
    checkable,
    checked,
    stateRef,
    draggable,
    queryCanDropDraggedItemsOnVisibleNodes,
    onDrop,
    meatballMenu,
    createButton,
  } = props

  const treeState = useIndentedTreeState({
    expandedNodes: expandedNodes,
    selected: selected ?? null,
    container: name,
    treeOperation,
    orderedTreeNodes,
    onExpandChange,
    onCheckChange,
    checked: checkable ? checked ?? [] : undefined,
  })

  if (stateRef) {
    stateRef.current = treeState
  }

  const handleClick = useCallback(
    (id: string) => {
      if (onSelect) {
        onSelect(id)
      }
    },
    [onSelect],
  )

  const commonProps = { nodes: orderedTreeNodes, treeState, onClick: handleClick, meatballMenu, createButton }

  if (!name) throw "No name set"
  return draggable ? (
    <DraggableTreeViewItems
      {...commonProps}
      queryCanDropDraggedItemsOnVisibleNodes={queryCanDropDraggedItemsOnVisibleNodes}
      onDrop={onDrop}
    />
  ) : (
    <TreeViewItems {...commonProps} />
  )
}
