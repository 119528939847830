import React, { useCallback, useState } from "react"
import { Blade, BladeDefinition } from "@st4/tasks"
import { useAspectId } from "@st4/settings"
import { keys } from "@st4/ui-strings"
import { Translate } from "@st4/i18n"
import { QuanosBlueButton } from "~/ui/quanosbutton"
import { SafetyModel } from "@st4/graphql"
import { Skeleton } from "antd"
import { useSafetyModelQuery } from "./query.hooks"
import { getSafetyModelConfig } from "@st4/content-tools"
import { SafetyPoolActionBar } from "~/components/treeActions/safetyAction"
import { NotificationInfo } from "~/components/treeActions/types"
import { PoolBladeNotifications } from "~/components/treeActions/PoolBladeNotifications"

type SafetyPoolBladeProps = {
  allowedOperations: string[]
  editElementProperties: { key: string; value: any }[] | null
}

type Messages =
  | {
      action: "closeTemporaryBlade"
      payload: any
    }
  | {
      action: "insertSafety" | "updateSafety"
      payload: {
        type: string
        severity: string
        optionalIcons: string[]
      }
    }

export const SafetyPoolBlade: BladeDefinition<SafetyPoolBladeProps, Messages> = function BladeWithContext(props) {
  const isEditMode = props.editElementProperties ? true : false

  const titleOfBlade = isEditMode ? keys.label.safetyPoolBlade.titleEdit : keys.label.safetyPoolBlade.title
  SafetyPoolBlade.title = <Translate>{titleOfBlade}</Translate>

  const aspectId = useAspectId()
  const [notifications, setInternalNotifications] = useState<NotificationInfo[]>([])
  const [editorActionMessage, setInternalEditorActionMessage] = useState<Messages>({
    action: isEditMode ? "updateSafety" : "insertSafety",
    payload: {
      type: "",
      severity: "",
      optionalIcons: [],
    },
  })
  const [noDataAvailable, setInternalNoDataAvailable] = useState<boolean>(false)

  const setNotifications = useCallback((newNotifications: NotificationInfo[]) => {
    setInternalNotifications([...newNotifications])
  }, [])

  const setInsertSafetyMessage = useCallback((insertMessage: Messages) => {
    setInternalEditorActionMessage(insertMessage)
  }, [])

  const setNoDataAvailable = useCallback((noDataAvailable: boolean) => {
    setInternalNoDataAvailable(noDataAvailable)
  }, [])

  const hasNotificationDisablingInsert = notifications.some((notification) => notification.disableInsert)

  const { data, loading } = useSafetyModelQuery({
    variables: { languageId: aspectId },
    fetchPolicy: "cache-and-network",
    errorPolicy: "all",
  })

  if (loading) {
    return (
      <div style={{ width: "100%", padding: 30 }}>
        <Skeleton active />
      </div>
    )
  }

  if (!data) return <></>

  const safetyModel: SafetyModel = data.configuration.safetyModel as SafetyModel
  const { severityLevelInfo, safetyTypeInfo } = getSafetyModelConfig(safetyModel)

  if (!severityLevelInfo.length || !safetyTypeInfo.length) return <></>

  return (
    <>
      <Blade.Content>
        <SafetyPoolActionBar
          safetyTypes={safetyTypeInfo}
          safetySeveritys={severityLevelInfo}
          allowedOperations={props.allowedOperations}
          editElementProperties={props.editElementProperties}
          setNotifications={setNotifications}
          setEditorActionMessage={setInsertSafetyMessage}
          setNoDataAvailable={setNoDataAvailable}
        />
      </Blade.Content>
      <PoolBladeNotifications notifications={notifications} />
      <Blade.Actionbar>
        <div />
        <QuanosBlueButton
          disabled={noDataAvailable || hasNotificationDisablingInsert}
          onClick={() => {
            props.sendMessage(editorActionMessage)
          }}
        >
          <Translate>{isEditMode ? keys.button.blade.edit : keys.button.blade.insert}</Translate>
        </QuanosBlueButton>
      </Blade.Actionbar>
    </>
  )
}

SafetyPoolBlade.title = <Translate>{keys.label.safetyPoolBlade.title}</Translate>
SafetyPoolBlade.size = { S: 5, M: 5, L: 5 }
