import React, { useEffect, useRef, useState, CSSProperties } from "react"
import styled, { DefaultTheme } from "styled-components"
import type { Node } from "unist"
import { Space, Table, Tooltip } from "antd"
import { XASTViewer } from "../XASTViewer"
import type { ColumnsType, TableProps } from "antd/lib/table"
import { useTranslation } from "react-i18next"
import { keys } from "@st4/ui-strings"
import type { KnownTableSize } from "./types"
import { FadeComponent } from "@schema/styled-ui"
import { Box } from "../structure"
import { useScrollToFocusedComment } from "../../annotationFocusState"
import { createPortal } from "react-dom"
import { Icon, Regular } from "@st4/icons"

function blockCommentBorderColor(
  isCommented: boolean,
  isCommentPrefocused: boolean,
  isCommentFocused: boolean,
  theme: DefaultTheme,
) {
  return isCommented
    ? isCommentFocused
      ? theme.preview.annotations.focusColor.border
      : isCommentPrefocused
      ? theme.preview.annotations.preColor.border
      : theme.preview.annotations.primaryColor[400]
    : "transparent"
}
const StyledTable = styled(Table)<{
  $isCommented: boolean
  $isCommentFocused: boolean
  $isCommentPrefocused: boolean
}>`
  margin: 1em 0;
  & .ant-table.ant-table-bordered {
    outline: 1px solid
      ${({ theme, $isCommented, $isCommentFocused, $isCommentPrefocused }) =>
        blockCommentBorderColor($isCommented, $isCommentPrefocused, $isCommentFocused, theme)};
    & > .ant-table-container {
      border: ${({ theme }) => theme.preview.table.borders.framed};
      border-right: 0px;
      border-bottom: 0px;
      position: relative;
      & > .ant-table-content > table {
        & > tbody > tr > td,
        & > thead > tr > th {
          border-right: ${({ theme }) => theme.preview.table.borders.framed};
        }
        & > thead > tr:not(:last-child) > th {
          border-bottom: ${({ theme }) => theme.preview.table.borders.framed_head};
        }
      }
    }
    & .ant-table-thead > tr > th {
      border-bottom: ${({ theme }) => theme.preview.table.borders.framed_head};
      background: ${({ theme }) => theme.greys[100]};
    }
    & .ant-table-tbody > tr > td {
      border-bottom: ${({ theme }) => theme.preview.table.borders.framed};
    }
    & > .ant-table-title {
      border: ${({ theme }) => theme.preview.table.borders.framed};
      border-bottom: 0px;
    }
  }
  & .ant-table-title {
    background: ${({ theme, $isCommented, $isCommentFocused, $isCommentPrefocused }) =>
      $isCommented
        ? $isCommentPrefocused
          ? theme.preview.annotations.preColor.background
          : $isCommentFocused
          ? theme.preview.annotations.focusColor.background
          : theme.preview.annotations.primaryColor[200]
        : theme.greys[200]};
  }
  & .ant-table-cell > ${Box} {
    //remove margin from inner boxes
    margin-top: 0;
    margin-bottom: 0;
  }
`

const TableTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  & .title-text {
    flex: 1;
  }
  & .widthInfo {
    text-align: right;
    flex: 0;
  }
`
type TableTitleProps = {
  titleXast: Node
  onWidthOver: () => void
  onWidthOut: () => void
  tableSizeType: KnownTableSize | "unknown"
  columnWidths?: number[]
  borderType?: string
  onPointerOver?: React.PointerEventHandler<HTMLDivElement>
  onPointerLeave?: React.PointerEventHandler<HTMLDivElement>
  onClick?: React.MouseEventHandler<HTMLDivElement>
}

function TableTitle(props: TableTitleProps) {
  const { t } = useTranslation()
  const { titleXast, tableSizeType, borderType, onWidthOut, onWidthOver } = props

  const sizes = t(keys.label.preview.table.scaling, { context: tableSizeType })

  const borderTypeText = `${t(keys.label.general.type)}: ${t(keys.label.preview.table.border, {
    context: borderType,
  })}`

  const tooltipText = `${sizes}\n${borderTypeText}`

  return (
    <>
      <TableTitleContainer
        onPointerOver={props.onPointerOver}
        onPointerLeave={props.onPointerLeave}
        onClick={props.onClick}
      >
        <div className="title-text">
          {titleXast?.children?.map((c) => <XASTViewer key={JSON.stringify(c.position)} xast={c} />)}
        </div>
        <div className="widthInfo">
          <Tooltip
            title={<span style={{ whiteSpace: "pre-wrap" }}>{tooltipText}</span>}
            onOpenChange={(visible) => (visible ? onWidthOver() : onWidthOut())}
            placement="topRight"
          >
            <Space>
              <Icon component={Regular.ArrowsLeftRight} />
              <div>
                {borderType === "noframe" ? (
                  <Icon component={Regular.BorderInner} />
                ) : (
                  <Icon component={Regular.BorderOuter} />
                )}
              </div>
            </Space>
          </Tooltip>
        </div>
      </TableTitleContainer>
    </>
  )
}

const SizeContainer = styled.div`
  opacity: 0;
  position: absolute;
  overflow: hidden;
`

const CellSizeContainer = styled.div`
  z-index: 200;
  top: 0;
  height: 4em;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.preview.table.sizeDisplayBackground};
  position: absolute;
  & .ant-divider-horizontal.ant-divider-with-text::before,
  & .ant-divider-horizontal.ant-divider-with-text::after {
    border-top: 1px solid ${({ theme }) => theme.greys[700]};
  }
`

function SizeOverlay(props: { sizes: string[]; tableRef: React.RefObject<HTMLDivElement>; style: CSSProperties }) {
  const { sizes, tableRef } = props
  const [clientRects, setClientRects] = useState<DOMRect[]>([])
  const [tableTop, setTableTop] = useState(0)
  const [tableRect, setTableRect] = useState<DOMRect | null>(null)

  useEffect(() => {
    if (tableRef.current != null) {
      const table = tableRef.current.getElementsByTagName("table")[0].getBoundingClientRect().top
      setTableTop(table)
      const tRect = tableRef.current.getBoundingClientRect()
      setTableRect(tRect)
      const cellMeasureRow = tableRef.current.getElementsByClassName("ant-table-measure-row")

      const cells = Array.from(cellMeasureRow[0].getElementsByTagName("td"))
      const clientrects = cells.map((c) => c.getBoundingClientRect())

      setClientRects(clientrects)
    }
  }, [tableRef.current])

  const cellMeasures = clientRects.map((clientRect, idx) => (
    <CellSizeContainer
      key={idx}
      style={{
        left: clientRect.left - (tableRect?.left || 0),
        width: clientRect.width - 1,
      }}
    >
      {sizes[idx]}
    </CellSizeContainer>
  ))
  return createPortal(
    <SizeContainer
      style={{
        ...props.style,
        top: tableTop,
        left: tableRect?.left,
        width: tableRect?.width,
      }}
    >
      {cellMeasures}
      <CellSizeContainer style={{ visibility: "hidden", position: "relative" }}>Dummy</CellSizeContainer>
    </SizeContainer>,
    document.body,
  )
}

interface TableContainerProps {
  columns: ColumnsType<Node[]>
  data: Node[][]
  tableSizeType: TableTitleProps["tableSizeType"]
  borderType: string
  titleXast: Node
  columnWidths: { label: string; value: number }[]
  comments?: NonNullable<Node["data"]>["blockcomments"]
}
export function TableContainer(props: TableContainerProps) {
  const { columns, data, borderType, titleXast, columnWidths, tableSizeType } = props
  const [showWidth, setShowWidth] = useState(false)
  const tableRef = useRef<HTMLDivElement>(null)
  const [scrollTargetProps, { isFocused, isPrefocused }, { setFocusedAnnotations, setPrefocusedAnnotations }] =
    useScrollToFocusedComment(props.comments?.map(({ comment: { id } }) => id) ?? [])

  const isCommented = !!props.comments?.length
  return (
    <div ref={tableRef}>
      <FadeComponent isVisible={showWidth} delay={200}>
        {(style) => <SizeOverlay style={style} sizes={columnWidths.map((x) => x.label)} tableRef={tableRef} />}
      </FadeComponent>
      <StyledTable
        {...scrollTargetProps}
        $isCommented={isCommented}
        $isCommentFocused={isFocused}
        $isCommentPrefocused={isPrefocused}
        columns={columns as TableProps<object>["columns"]}
        dataSource={data}
        showHeader={columns.some((col) => col.title)}
        bordered={borderType !== "noframe"}
        pagination={false}
        scroll={{ x: true }}
        rowKey={((record: Node[]) => `Row_${JSON.stringify(record[0].position)}`) as TableProps<object>["rowKey"]}
        title={() => (
          <TableTitle
            titleXast={titleXast}
            borderType={borderType}
            tableSizeType={tableSizeType}
            columnWidths={columnWidths.map((x) => x.value)}
            onWidthOver={() => setShowWidth(true)}
            onWidthOut={() => setShowWidth(false)}
            onClick={() => props.comments?.length && setFocusedAnnotations(props.comments)}
            onPointerOver={() => {
              props.comments?.length && !isFocused && setPrefocusedAnnotations(props.comments)
            }}
            onPointerLeave={() => {
              props.comments?.length && isPrefocused && setPrefocusedAnnotations([])
            }}
          />
        )}
      />
    </div>
  )
}
