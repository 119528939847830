import { FormOutlined } from "@ant-design/icons"
import { Tooltip } from "@st4/ui"
import { keys } from "@st4/ui-strings"
import { Button } from "antd"
import React from "react"
import { TextNodeState } from "../types"

type NodeEditButtonsProps = {
  textNodeState: TextNodeState
  nodeId: string
  treeNodeId: string
  openEditor: (nodeId: string, treeNodeId: string) => Promise<void>
}

export function NodeEditorButton({ textNodeState, openEditor, nodeId, treeNodeId }: NodeEditButtonsProps) {
  const tooltipTitle = getTooltipTitle(textNodeState) ?? ""

  return (
    <Tooltip translationKey={tooltipTitle} placement="right">
      <Button
        type={textNodeState === "READONLY" ? "default" : "primary"}
        onClick={() => {
          openEditor(nodeId, treeNodeId)
        }}
        icon={<FormOutlined />}
        shape="circle"
      />
    </Tooltip>
  )
}

function getTooltipTitle(textNodeState: TextNodeState) {
  switch (textNodeState) {
    case "READONLY":
      return keys.button.general.openEditor
    case "EDIT":
    case "INPLACE_EDIT":
      return keys.button.general.saveandclose
    case "ERROR_ADVANCED":
    case "ERROR_INPLACE":
      return keys.button.general.closemessage
    default:
      // eslint-disable-next-line no-case-declarations
      const _exhaustiveCheck: never = textNodeState
  }
}
