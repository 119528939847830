import * as React from "react"
import { SVGProps } from "react"

const SvgSt4FileMessageQuestionRegular = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1em" height="1em" {...props}>
    <path d="M145 234.6c0-15.5 13.3-28.9 26.6-28.9h166.5c15.5 0 28.9 13.3 28.9 28.9V359c0 15.5-13.3 26.6-26.6 26.6h-62.2l-53.3 40c-2.2 2.2-4.4 2.2-6.7 2.2s-4.4-4.4-4.4-6.7v-35.5h-42.2c-15.5 0-26.6-13.3-26.6-26.6V234.6zm73.3 22.2c-2.2 6.7 0 11.1 6.7 13.3s11.1 0 13.3-6.7c0-2.2 2.2-2.2 2.2-2.2h24.4c4.4 0 6.7 2.2 6.7 6.7 0 2.2-2.2 4.4-2.2 6.7l-20 11.1c-2.2 2.2-4.4 4.4-4.4 8.9v6.7c0 6.7 4.4 11.1 11.1 11.1s11.1-4.4 11.1-11.1l13.3-8.9c8.9-4.4 13.3-13.3 13.3-24.4 0-15.5-13.3-26.6-26.6-26.6h-26.6c-9-.1-17.9 6.6-22.3 15.4zm51 80c0-6.7-6.7-13.3-13.3-13.3s-13.3 6.7-13.3 13.3 6.7 13.3 13.3 13.3 13.3-4.5 13.3-13.3zm160-227.6-90.6-90.5C326.7 6.7 310.5 0 293.5 0H128C92.7 0 64 28.7 64 64v384c0 35.3 28.7 64 64 64h256c35.3 0 64-28.7 64-64V154.5c0-17-6.7-33.3-18.7-45.3zM400 448c0 8.8-7.2 16-16 16H128c-8.8 0-16-7.2-16-16V64c0-8.8 7.2-16 16-16h160v80c0 17.7 14.3 32 32 32h80v288z" />
  </svg>
)

export default SvgSt4FileMessageQuestionRegular
