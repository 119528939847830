import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { lighten, darken } from "polished"
import { Link } from "react-router-dom"

const Icon = styled.span`
  flex: 0 0 auto;
  padding: 12px;
  & svg {
    fill: ${({ theme }) => lighten(0.35, theme.primaryColor)};
    position: relative;
    top: 2px;
  }
`
const Text = styled.span`
  color: black;
  flex: 1 0 auto;
  padding: 12px 12px 12px 0;
  white-space: nowrap;
`

const noop = () => void 0

const LinkWithIcon = ({ className, target, children, icon, replace, onClick = noop }) => {
  const innerContent = (
    <>
      <Icon>{React.createElement(icon, { size: "16px" })}</Icon>
      <Text>{children}</Text>
    </>
  )
  if (!target)
    return (
      <span className={className} onClick={onClick}>
        {innerContent}
      </span>
    )

  return (
    <Link to={target} onClick={onClick} className={className} replace={replace}>
      {innerContent}
    </Link>
  )
}

LinkWithIcon.displayName = "LinkWithIcon"
LinkWithIcon.propTypes = {
  className: PropTypes.string,
  target: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  onClick: PropTypes.func,
  replace: PropTypes.bool,
}

const StyledLinkWithIcon = styled(LinkWithIcon)`
  display: flex;
  width: 100%;
  cursor: pointer;
  &:hover ${Icon} svg {
    fill: ${({ theme }) => theme.primaryColor};
    stroke-width: 1px;
    stroke: ${({ theme }) => darken(0.35, theme.primaryColor)};
  }
`

export default StyledLinkWithIcon
