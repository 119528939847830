import React from "react"
import styled, { DefaultTheme } from "styled-components"

export function NodeSelectionSpan({
  isSelected,
  isHover,
  children,
  onHoverChange,
  style,
  isParentHovered,
}: {
  isSelected: boolean
  isHover: boolean
  children: React.ReactNode
  onHoverChange: (isHover: boolean) => void
  style?: React.CSSProperties
  isParentHovered?: boolean
}) {
  return (
    <StyledNodeSpan
      className={"selectionSpan"}
      isSelected={isSelected}
      isHover={isHover}
      isParentHovered={isParentHovered}
      onMouseEnter={() => onHoverChange(true)}
      onMouseLeave={() => onHoverChange(false)}
      style={style}
    >
      {children}
    </StyledNodeSpan>
  )
}

const StyledNodeSpan = styled.span<{
  isSelected?: boolean
  isHover?: boolean
  isParentHovered?: boolean
  theme: DefaultTheme
}>`
  white-space: nowrap;
  border-radius: 1px;
  color: ${({ theme }) => theme.midnightBlue};
  background-color: ${({ isSelected, isHover, isParentHovered, theme }) =>
    isSelected ? theme.barelyBlue : isHover ? theme.harpGrey : isParentHovered ? "#F4F5F6" : "transparent"};
  line-height: 24px;
  flex-grow: 1;
`
