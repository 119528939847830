import { useCallback } from "react"
import { useTranslation } from "react-i18next"
import { isTypename } from "@st4/graphql"
import { useLanguage } from "@st4/settings"
import { keys } from "@st4/ui-strings"
import { CopyTreesMutationVariables, useCheckCanCopyTreeLazyQuery, useCopyTreesMutation } from "./query.hooks"

export function useCopyTreesJob() {
  const { languageId } = useLanguage()
  const { t: translate } = useTranslation()
  const [copyTrees] = useCopyTreesMutation()

  const startJob = useCallback(
    async (sourceNode: string, targetNode: string): Promise<string | void> => {
      try {
        const copyTreeVariables: CopyTreesMutationVariables = {
          input: { sourceNodes: [sourceNode], targetNode: targetNode },
          languageId,
        }
        const result = await copyTrees({
          variables: copyTreeVariables,
          refetchQueries: ["NavigationTree"],
        })
        if (result.data && isTypename("Error")(result.data.copyTrees)) {
          return result.data.copyTrees.message
        } else if (result.errors) {
          console.log(result.errors)
          return result.errors[0].message
        }
      } catch (rejectionReason) {
        console.log(rejectionReason)
        const errorMessage = translate(keys.message.error.generic)
        return errorMessage
      }
    },
    [copyTrees, languageId, translate]
  )

  return {
    startJob,
  }
}

export function useCopyTreesAllowed(): (sourceNode: string, targetNode: string) => Promise<boolean> {
  const [query] = useCheckCanCopyTreeLazyQuery()

  const copyTreesAllowed = useCallback(
    async (sourceNode: string, targetNode: string) => {
      try {
        const value = await query({
          variables: { input: { sourceNodes: [sourceNode], targetNode: targetNode } },
          fetchPolicy: "network-only",
        })
        if (value.loading) throw "query should be finished before continue occurs"
        return value.data?.copyTreesAllowed ?? false
      } catch (rejectionReason) {
        console.log(rejectionReason)
        return false
      }
    },
    [query]
  )

  return copyTreesAllowed
}
