import * as React from "react"
import { SVGProps } from "react"

const SvgParagraph = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="1em" height="1em" {...props}>
    <path d="M32 192c0-88.4 71.6-160 160-160h240c8.8 0 16 7.2 16 16s-7.2 16-16 16h-48v400c0 8.8-7.2 16-16 16s-16-7.2-16-16V64h-64v400c0 8.8-7.2 16-16 16s-16-7.2-16-16V352h-64c-88.4 0-160-71.6-160-160zm224 128V64h-64c-70.7 0-128 57.3-128 128s57.3 128 128 128h64z" />
  </svg>
)

export default SvgParagraph
