import React, { ReactNode } from "react"
import { Card, Descriptions, Tag } from "antd"
import styled, { DefaultTheme } from "styled-components"
import { XASTViewer } from "../XASTViewer"
import type { Node } from "unist"
import { shade } from "polished"
import { ReleaseStateDisplay } from "../ReleaseStateDisplay"
import type { ST4NodeWithContentName } from "../../../graphql/types"

const StyledImageDescription = styled.div<{
  labels?: Array<unknown>
  legendNode?: Node
  $isCommented: boolean
  $isCommentFocused: boolean
  $isCommentPrefocused: boolean
}>`
  padding-top: 12px;
  border-top: 1px solid
    ${({ theme, $isCommented, $isCommentFocused, $isCommentPrefocused }) =>
      blockCommentBorderColor($isCommented, $isCommentPrefocused, $isCommentFocused, theme)};
  .ant-descriptions-header {
    margin-bottom: ${(props) => (props.labels?.length || props.legendNode ? "20px" : "0px")};
  }
  .ant-descriptions-title {
    flex: 1 1;
  }
`

function StyledTag({ children }: React.PropsWithChildren<unknown>) {
  return (
    <span style={{ minWidth: "4em" }}>
      <Tag color="volcano">{children}</Tag>
    </span>
  )
}

const StyledReleaseTitle = styled.div``

type ImageDescriptionProps = {
  mediaNode: ST4NodeWithContentName<"MediaContent" | "MediaGroupContent"> & {
    releaseState?: TitleWithReleaseStateProps["releaseState"]
  }
  titleNode?: Node
  title: ReactNode
  labels?: { id?: string | null; autoValue?: string | null; text?: string | null }[]
  legendNode?: Node
  isCommented?: boolean
  isCommentFocused?: boolean
  isCommentPrefocused?: boolean
  layoutInfo?: ReactNode
}

export function ImageDescriptions({
  mediaNode,
  title,
  labels,
  legendNode,
  isCommented,
  isCommentFocused,
  isCommentPrefocused,
  layoutInfo,
}: ImageDescriptionProps) {
  const labelElements = labels
    ? labels.map((l) => (
        <Descriptions.Item key={l.id} label={<StyledTag>{l.autoValue}</StyledTag>}>
          {l.text}
        </Descriptions.Item>
      ))
    : null

  return (
    <StyledImageDescription
      labels={labels}
      legendNode={legendNode}
      $isCommentFocused={!!isCommentFocused}
      $isCommentPrefocused={!!isCommentPrefocused}
      $isCommented={!!isCommented}
    >
      <Descriptions
        title={<TitleWithReleaseState title={title} releaseState={mediaNode.releaseState} />}
        extra={layoutInfo}
        colon={false}
        column={2}
      >
        {labelElements}
      </Descriptions>
      {legendNode ? <XASTViewer xast={legendNode} /> : null}
    </StyledImageDescription>
  )
}

type TitleWithReleaseStateProps = {
  title: ReactNode
  releaseState: Parameters<typeof ReleaseStateDisplay>[0]["releaseState"]
}

function TitleWithReleaseState(props: TitleWithReleaseStateProps) {
  return (
    <StyledReleaseTitle>
      <ReleaseStateDisplay releaseState={props.releaseState} />
      {props.title}
    </StyledReleaseTitle>
  )
}

export function blockCommentBackgroundColor(
  isCommented: boolean,
  isCommentPrefocused: boolean,
  isCommentFocused: boolean,
  theme: DefaultTheme,
) {
  return isCommented
    ? isCommentFocused
      ? theme.preview.annotations.focusColor.background
      : isCommentPrefocused
      ? theme.preview.annotations.preColor.background
      : theme.preview.annotations.primaryColor[100]
    : theme.backgroundColor
}

export function blockCommentBorderColor(
  isCommented: boolean,
  isCommentPrefocused: boolean,
  isCommentFocused: boolean,
  theme: DefaultTheme,
) {
  return isCommented
    ? isCommentFocused
      ? theme.preview.annotations.focusColor.border
      : isCommentPrefocused
      ? theme.preview.annotations.preColor.border
      : theme.preview.annotations.primaryColor[400]
    : shade(0.1, theme.backgroundColor)
}

export const StyledCard = styled(Card)<{
  $isGroup?: boolean
  $isCommented: boolean
  $isCommentFocused: boolean
  $isCommentPrefocused: boolean
}>`
  background: ${({ theme, $isCommented, $isCommentFocused, $isCommentPrefocused }) =>
    blockCommentBackgroundColor($isCommented, $isCommentPrefocused, $isCommentFocused, theme)};
  border: 1px solid
    ${({ theme, $isCommented, $isCommentFocused, $isCommentPrefocused }) =>
      blockCommentBorderColor($isCommented, $isCommentPrefocused, $isCommentFocused, theme)};
  border-radius: 3px;
  margin: 1em 0;

  .ant-card-body {
    padding: 12px;
  }

  ${({ $isGroup, theme }) => ($isGroup ? theme.preview.groupNodeHighlight : null)};
`
