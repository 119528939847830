import { z } from "zod"
import { BladeData, bladeDataSchema, BladeDefinition, GetMessages, GetProps } from "./blade"
import { Message, MessageHub } from "@st4/message-hub"
import { DefaultMessages } from "./blade"
import { JsonObject, zodJsonObjectSchema } from "../json"
import type { ReducerHelper } from "../taskcontext/reducerUtilities"

export const screenDataSchema = z.object({
  blades: z.array(z.object({ name: z.string(), collapsed: z.oboolean() })),
  fixedBlade: z.ostring(),
  states: z.object({ screen: zodJsonObjectSchema.optional() }).and(z.record(z.string(), bladeDataSchema).optional()),
})

export type ScreenData = z.infer<typeof screenDataSchema>

type BladeStateMap<TBlades extends BladeReference<string, BladeDefinition>[]> = {
  readonly [blade in TBlades[number] as blade["name"]]: blade["state"]
}

export type ScreenState<
  TBlades extends BladeReference<string, BladeDefinition>[],
  TAdditionalScreenState extends JsonObject | undefined,
> = {
  /** The blades to show on the screen, might be collapsed. */
  readonly blades: {
    /** The name of the blade. */ name: TBlades[number]["name"]
    /** Wether the blade is displayed as collapsed. */ collapsed?: boolean
  }[]
  /** The name of the blade wich should be displayed as a fixed blade. */
  readonly fixedBlade?: string
  /** The state of each blade */
  readonly states: undefined extends TAdditionalScreenState
    ? BladeStateMap<TBlades>
    : BladeStateMap<TBlades> & {
        /** State which isn't part of a blade state can be persisted on this field. */ screen: Exclude<
          TAdditionalScreenState,
          undefined
        >
      }
}

type ReferencedBladeState<TName, TProps, TScreenState extends ScreenData> = {
  /**
   * The name of the compontent. (This is the key of the lookup inside the component registry)
   */
  readonly component: string
  /**
   * The name of the blade as referenced inside this screen.
   *
   * This name is used to identify the blades state and messages.
   */
  readonly name: TName
  /** Whether the blade is temporary.
   * @deprecated This will change in the future, because the way temporary blades work will change.
   */
  readonly temporary?: boolean //TODO: ist das hier richtig?
  /**
   * A function to project the props for this blade.
   * @param {TProps} bladeState the state values of the blade
   * @param {TScreenState} screenState the complete state of the screen.
   * @returns The props for the blade.
   */
  readonly props?: (bladeState: TProps, screenState: TScreenState) => TProps
}

type MappedBladeRef<TBlades extends Array<BladeReference<string, BladeDefinition>>, TScreenState extends ScreenData> = {
  [key in keyof TBlades]: ReferencedBladeState<TBlades[key]["name"], TBlades[key]["state"], TScreenState>
}

export type BaseScreenDefinition = {
  readonly name: string
  readonly icon?: string
  readonly initialContextValues: unknown
}

/**
 * Transforms the BladeReferenceArray into an object containing all composite message actions for all blades
 * and their respective payloads
 */
type BladeMessageMap<TBlades extends { name: string; messages: Message }[]> = {
  // Iterate over the blades, using the `name` as key of mapped type and "save" the current blade
  [blade in TBlades[number] as blade["name"]]: {
    // Iterate over the messages of the blade. Using Blade-Name:Action as key and payload as value
    [msg in blade["messages"] as `${blade["name"]}:${msg["action"]}`]: msg["payload"]
  }
  // Unfold the object, by using only the values to create a union for all blade:action combinations
}[TBlades[number]["name"]]

/**
 * Combines all messages of given blades into namespaced messages
 */
type BladeMessages<
  TBlades extends { name: string; messages: Message }[],
  TBladeMessages = BladeMessageMap<TBlades>,
> = TBladeMessages extends BladeMessageMap<{ name: string; messages: Message }[]>
  ? // removing the conditional type somehow breaks the mappWing...
    {
      [action in keyof TBladeMessages]: {
        action: action
        payload: TBladeMessages[action]
      }
    }[keyof TBladeMessages]
  : never

type Observer<
  TBlades extends BladeReference<string, any>[],
  TAdditionalScreenState extends JsonObject | undefined = undefined,
> = {
  /** Asynchronous function to observe the message hub of the blade with this name. */
  [blade in TBlades[number] as blade["name"]]?: (
    /** The message received by the message hub. */
    message: blade["messages"],
    /** Access to all other message hubs inside this screen, indexed by blade name. */
    messageHubs: { [blade in TBlades[number] as blade["name"]]: MessageHub<blade["messages"]> },
    /** The current state of the screen. */
    state: ScreenState<TBlades, TAdditionalScreenState>,
  ) => Promise<void>
}

/**
 * The Reducerfunction for a screen.
 * @param state The current state of the screen.
 * @param message The received message which may cause a change in state.
 * @returns The new state for the screen.
 */
type Reducer<TBlades extends BladeReference<string, any>[], TAdditionalScreenState extends JsonObject | undefined> = (
  state: ScreenState<TBlades, TAdditionalScreenState>,
  message: BladeMessages<TBlades>,
  helpers: ReducerHelper<TBlades, TAdditionalScreenState, ScreenState<TBlades, TAdditionalScreenState>>,
) =>
  | ScreenState<TBlades, TAdditionalScreenState>
  | ReducerHelper<TBlades, TAdditionalScreenState, ScreenState<TBlades, TAdditionalScreenState>>
  | undefined

/**
 * This type is used to reference a blade inside a screen definition.
 *
 * @template TName - The name of the blade. Used as type parameter to enable static checking on the name.
 * @template {BladeDefinition} TBlade - The type of the blade. Has to extend BladeDefinition
 *
 * _Blades are referenced inside the screen definition only by strings (to use the component registry).
 * That's the reason why we don't have any type infomation. With this `BladeReference` type we can provide the
 * type checker with all required information to infer any required information about the blades
 * (for example messages sent or prop types)._
 */
export type BladeReference<TName extends string, TBlade> = {
  readonly name: TName
  readonly state: GetProps<TBlade>
  readonly messages: GetMessages<TBlade> | DefaultMessages
}

//Fehleranfällig, da der Typ `ScreenDefinition` hier noch mal dupliziert wurde!
//Sollte sich eine Änderung in einem Typen ergeben, muss dieser hier nachgezogen werden.
export type GenericScreenDefitinition = BaseScreenDefinition & {
  blades: {
    name: string
    component: string
    temporary?: boolean
    // `screenState` ist hier problematisch, da dieser in ScreenDefinition "genauer" ist,
    // und da es ein Funktionsparameter ist, ist der Typ nicht Contravariant.
    props?: (bladeState: BladeData, screenState: ScreenData) => BladeData
  }[]
  initialContextValues: ScreenData
  reducer?: (state: ScreenData, message: Message, helper: ReducerHelper) => ScreenData | ReducerHelper | undefined
  observe?: Record<
    string,
    (message: Message, messageHubMap: Record<string, MessageHub>, state: ScreenData) => Promise<void>
  >
}

/**
 * The definition of a screen.
 *
 * @template TBlades - An array of BladeReference types for blades used inside this screen.
 *
 * @template TAdditionalScreenState - Additional state which should be persisted.
 *
 * _You have to create an type based on this by providing the type parameters. This is required because
 * the reference to the blades is dynamic on runtime (compoent registry). By declaring the types
 * of the blades and screen state, we are able to assist you in writing your screen definition._
 *
 * @example
 * type MyScreen = ScreenDefinition<
 *   [
 *     BladeReference<"categories", typeof CategoryBlade>,
 *     BladeReference<"recipeList", typeof RecipeListBlade>,
 *     BladeReference<"recipe", typeof RecipeDetailBlade>
 *   ],
 *   {age: number}
 * >
 *
 * const screen: MyScreen = {
 *   //...
 * }
 */
export type ScreenDefinition<
  TBlades extends BladeReference<string, any>[] = BladeReference<string, any>[],
  TAdditionalScreenState extends JsonObject | undefined = undefined,
> = BaseScreenDefinition & {
  /**
   * The blades which are used inside this screen
   */
  readonly blades: MappedBladeRef<TBlades, ScreenState<TBlades, TAdditionalScreenState>>
  /** The values which will be used to initialize this screen. */
  readonly initialContextValues: ScreenState<TBlades, TAdditionalScreenState>
  /**
   * The reducer receivies messages sent by blades and the state of the task.
   * It is used to mutate the state and return a new state object.
   * After reducing the state the Screen will render with the new state.
   *
   * **Important**: This functions must not cause any sideeffects!
   */
  readonly reducer?: Reducer<TBlades, TAdditionalScreenState>
  /**
   * You may specify observer functions for each blade. These are called whenever a message is
   * sent in the corresponding blade.
   *
   * Observer functions are asynchronous and may cause side effects.
   */
  readonly observe?: Observer<TBlades, TAdditionalScreenState>
}

/**
 * This function is used to define a screen.
 * @param screenDef The definition of the screen.
 */
export function defineScreen<
  TBlades extends BladeReference<string, any>[],
  TAdditionalScreenState extends JsonObject | undefined, //TODO: undefined als default
>(screenDef: ScreenDefinition<TBlades, TAdditionalScreenState>) {
  return screenDef
}
