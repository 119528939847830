import * as React from "react"
import { SVGProps } from "react"

const SvgTurnUp = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" width="1em" height="1em" {...props}>
    <path d="M128 160H70.7c-3.7 0-6.7-3-6.7-6.7 0-1.9.8-3.7 2.2-5L192 35.8l125.8 112.5c1.4 1.3 2.2 3.1 2.2 5 0 3.7-3 6.7-6.7 6.7H256c-17.7 0-32 14.3-32 32v176c0 61.9-50.1 112-112 112H48c-8.8 0-16-7.2-16-16v-32c0-8.8 7.2-16 16-16h64c26.5 0 48-21.5 48-48V192c0-17.7-14.3-32-32-32zM206 5.3c-3.9-3.4-8.8-5.3-14-5.3s-10.1 1.9-14 5.3L44.9 124.5c-8.2 7.3-12.9 17.8-12.9 28.8 0 21.4 17.3 38.7 38.7 38.7H128v176c0 8.8-7.2 16-16 16H48c-26.5 0-48 21.5-48 48v32c0 26.5 21.5 48 48 48h64c79.5 0 144-64.5 144-144V192h57.3c21.4 0 38.7-17.3 38.7-38.7 0-11-4.7-21.5-12.9-28.8L206 5.3z" />
  </svg>
)

export default SvgTurnUp
