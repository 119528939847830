import React, { useState } from "react"
import { Transfer } from "antd"
import type { TransferItem } from "antd/lib/transfer"
import { useTranslation } from "react-i18next"
import { keys } from "@st4/ui-strings"
import uuid from "uuid"

type SelectMultiProps = {
  options: OptionObject[]
  onSet: (p: SelectionObject) => void
  defaultValue: SelectionObject
  allowNull?: boolean
}

type SelectionObject = {
  [id: string]: {
    _data: string
    _display: string
  }
}

type OptionObject = {
  value: {
    _data: string
    _display: string
  }
}

export function SelectMulti(props: SelectMultiProps) {
  const { t } = useTranslation()

  const [targetKeys, setTargetKeys] = useState<string[]>(
    Object.keys(props.defaultValue).map((k) => props.defaultValue[k]._data),
  )
  const [selectedKeys, setSelectedKeys] = useState<string[]>([])
  const [disabled, setDisabled] = useState(false)

  const itemsToShow = new Map<string, TransferItem>()

  const nullOption = {
    _display: "",
    _data: "",
  }

  function selectNullOption() {
    const defaultSelectedItems: SelectMultiProps["defaultValue"] = {}
    defaultSelectedItems[uuid()] = nullOption
    props.onSet(defaultSelectedItems)
  }

  if ((props.allowNull == undefined || props.allowNull) && Object.keys(props.defaultValue).length == 0) {
    selectNullOption()
  }

  for (let i = 0; i < props.options.length; i++) {
    const dataItem: TransferItem = {
      key: props.options[i].value._data,
      title: props.options[i].value._display,
      data: props.options[i].value._data,
    }
    itemsToShow.set(dataItem.key || "", dataItem)
  }

  function handleChange(nextTargetKeys: string[], direction: string, moveKeys: string[]) {
    setTargetKeys(nextTargetKeys)
    const selectedItems: SelectMultiProps["defaultValue"] = {}
    if ((props.allowNull == undefined || props.allowNull) && nextTargetKeys.length === 0) {
      selectNullOption()
    }
    for (const key of nextTargetKeys) {
      const targetItem = itemsToShow.get(key)
      if (!targetItem) continue
      const item = {
        _data: targetItem.data?.toString() || "",
        _display: targetItem.title || "",
      }
      selectedItems[uuid()] = item
    }
    props.onSet(selectedItems)
  }

  function handleSelectChange(sourceSelectedKeys: string[], targetSelectedKeys: string[]) {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys])
  }

  return (
    <>
      <Transfer
        filterOption={(input, option) => (option.title || "").toLowerCase().includes(input.toLowerCase())}
        dataSource={Array.from(itemsToShow.values())}
        showSearch
        showSelectAll
        titles={[t(keys.label.generic.available), t(keys.label.generic.selection)]}
        targetKeys={targetKeys}
        selectedKeys={selectedKeys}
        onChange={handleChange}
        onSelectChange={handleSelectChange}
        render={(item) => item.title || null}
        disabled={disabled}
        listStyle={{
          width: 250,
          height: 300,
        }}
        operations={[t(keys.label.generic.addToSelection), t(keys.label.generic.removeFromSelection)]}
      />
    </>
  )
}
