import React from "react"
import type { DateTimePropertyValue, PropertyEditor } from "../types"
import { UpdatePropertyMutationVariables, useUpdatePropertyMutation } from "../../query.hooks"
import { isTypename } from "@st4/graphql"
import { DateEditor } from "~/ui/editors/dateEditor"
import { Icon, Regular } from "@st4/icons"

function getInputValue(
  propertyEditor: PropertyEditor<DateTimePropertyValue>,
  value: Date | null,
): UpdatePropertyMutationVariables["input"] {
  const input: UpdatePropertyMutationVariables["input"] = {
    variantName: propertyEditor.variantName,
    displayName: propertyEditor.displayName,
    id: propertyEditor.id,
    node: { id: propertyEditor.node.id },
    origin: propertyEditor.origin,
    value: { dateTimeValue: value ?? "0001-01-01T00:00:00.000Z" },
  }
  return input
}

export function PropertyDateEditor({
  propertyEditor,
  languageId,
  refetchProperties,
  refetchOnSave,
}: {
  propertyEditor: PropertyEditor<DateTimePropertyValue>
  languageId: string
  refetchProperties: () => void
  refetchOnSave?: string[]
}) {
  const { value, isReadonly } = propertyEditor
  const [updateProperty, { data, loading, error }] = useUpdatePropertyMutation({
    variables: { input: { ...propertyEditor, value: { stringValue: propertyEditor.value?.dateTimeValue } } },
    refetchQueries: refetchOnSave,
  })
  const submitHandler = (date: Date | null) => {
    updateProperty({
      variables: {
        input: getInputValue(propertyEditor, date),
        languageId: languageId,
      },
      onCompleted: (_) => {
        refetchProperties()
      },
    })
  }

  let suffixIcon = null

  if (loading) {
    suffixIcon = <Icon component={Regular.SpinnerThird} />
  }

  let errorMessage: string | undefined = undefined

  if (error) {
    errorMessage = error.message
  }
  if (isTypename("Error")(data?.updateProperty)) {
    errorMessage = data?.updateProperty.message
  }

  return (
    <DateEditor
      style={{ width: "100%" }}
      value={value?.dateTimeValue}
      isReadonly={isReadonly}
      suffixIcon={suffixIcon}
      onSubmit={submitHandler}
      showTime={true} // Always allows setting the time until we have a way to decide which properties should allow setting the time
      errorMessage={errorMessage}
    />
  )
}
