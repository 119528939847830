import { BladeReference, defineScreen } from "@st4/tasks"
import { SearchFacetsBlade, SearchOptionsBlade, SearchResultBlade } from "~/blades/searchBlades"
import { ContentBlade } from "~/blades/contentBlade"
import { PropertiesBlade } from "~/blades/propertiesBlade"
import { SearchRefinement, SearchOptions, loadPageSize } from "@st4/search"

export const showSearchResultScreen = defineScreen<
  [
    BladeReference<"searchFacets", typeof SearchFacetsBlade>,
    BladeReference<"searchResult", typeof SearchResultBlade>,
    BladeReference<"searchOptions", typeof SearchOptionsBlade>,
    BladeReference<"content", typeof ContentBlade>,
    BladeReference<"properties", typeof PropertiesBlade>,
  ],
  {
    searchTerm: string | null
    searchRefinements: SearchRefinement[]
    searchOptions?: SearchOptions
  }
>({
  name: "showSearchResult",
  icon: "TaskSearch",
  blades: [
    {
      name: "searchFacets",
      component: "SearchFacetsBlade",
      props: (bladeState, taskState) => ({
        // Don't set property "page" (default: 1) -> no reloading of facets when paging through the search results
        ...bladeState,
        searchTerm: taskState.states.screen?.searchTerm ?? bladeState.searchTerm,
        searchRefinements: taskState.states.screen?.searchRefinements ?? bladeState.searchRefinements,
        searchOptions: taskState.states.screen?.searchOptions,
        pageSize: loadPageSize(),
      }),
    },
    {
      name: "searchResult",
      component: "SearchResultBlade",
      props: (bladeState, taskState) => ({
        ...bladeState,
        searchTerm: taskState.states.screen?.searchTerm ?? bladeState.searchTerm,
        searchRefinements: taskState.states.screen?.searchRefinements ?? bladeState.searchRefinements,
        searchOptions: taskState.states.screen?.searchOptions,
        page: !bladeState.page ? 1 : bladeState.page,
        pageSize: loadPageSize(),
      }),
    },
    {
      name: "searchOptions",
      component: "SearchOptionsBlade",
      temporary: true,
      props: (bladeState, taskState) => ({
        ...bladeState,
        searchOptions: bladeState.searchOptions ?? taskState.states.screen?.searchOptions,
      }),
    },
    {
      name: "content",
      component: "ContentBlade",
      props: (contentBladeState, taskState) => ({
        rootNodeId: taskState.states.searchResult?.selectedNode,
        editedNodes: contentBladeState.editedNodes,
        readonly: true,
      }),
    },
    {
      name: "properties",
      component: "PropertiesBlade",
      props: (propertiesBladeState, taskState) => ({
        ...propertiesBladeState,
        nodeId: taskState.states.content?.selection?.treeNodeId ?? taskState.states.searchResult?.selectedNode,
      }),
    },
  ],
  initialContextValues: {
    //fixedBlade: "searchResult", // no fixed blade in this task
    blades: [{ name: "searchFacets", collapsed: true }, { name: "searchResult" }],
    states: {
      searchFacets: { pageSize: loadPageSize() },
      searchResult: { pageSize: loadPageSize(), isSearchOptionsOpened: false },
      searchOptions: {},
      content: {
        editedNodes: [],
        readonly: false,
      },
      properties: {},
      screen: {
        searchTerm: null,
        searchRefinements: Array<SearchRefinement>(),
      },
    },
  },
  reducer(state, message, helper) {
    switch (message.action) {
      case "searchOptions:closeTemporaryBlade": {
        return helper.modifyState((states) => {
          states.searchResult.isSearchOptionsOpened = false
        })
      }
      case "searchOptions:publishSearchOptions":
      case "searchOptions:resetSearchOptions": {
        return helper
          .hideBlade("searchOptions")
          .onlyIf(message.action !== "searchOptions:resetSearchOptions")
          .modifyState((states) => {
            states.searchResult.isSearchOptionsOpened = message.action == "searchOptions:resetSearchOptions"
            if (message.action == "searchOptions:publishSearchOptions") states.screen.searchOptions = message.payload
          })
      }
      case "searchResult:openSearchOptions": {
        return helper
          .moveBlade("searchFacets", 0)
          .moveBlade("searchResult", 1)
          .showBlade("searchOptions") //make shure searchOptions is visible
          .moveBlade("searchOptions", 2) // move it next to search result
          .hideBlade("searchOptions")
          .onlyIf(!message.payload.open) // hide it, if message.payload.open is false
          .modifyState((states) => {
            states.searchResult.isSearchOptionsOpened = message.payload.open
            states.searchOptions.searchOptions = message.payload.options
          })
      }
      case "searchResult:onNodeSelected": {
        // show content- and properties-blade when a search result has been selected ...
        // ... but also make sure the blades are only added once to the task ...
        // ... and collapsed-state of (existing) properties-blade is to be maintained
        return helper
          .showBlade("content")
          .showBlade("properties", { collapsed: true })
          .modifyState((states) => {
            states.searchResult.selectedNode = message.payload
          })
      }
      case "searchResult:onSearchTermChanged": {
        // resetting the search queries to page 1 is intentional here.
        // this message is only meant to be sent if the user entered a different (not empty) search term
        return helper.hideBladesExcept("searchFacets", "searchResult").modifyState((states) => {
          states.searchResult.isSearchOptionsOpened = false
          states.searchResult.page = 1

          states.searchFacets.page = 1
          states.screen.searchTerm = message.payload
          states.screen.searchRefinements = []
        })
      }
      case "searchResult:onPageChanged": {
        // make round trip by message to avoid local blade state but also ensure the according blade prop is updated
        return helper.modifyState((states) => {
          states.searchResult.page = message.payload
        })
      }
      case "searchFacets:onSearchRefined": {
        // hide the preview- and properties-blade when a refinement fiter has been selected as the displayed item will be unselected as well
        // ensure that search result-blade is expanded
        return helper
          .hideBlade("content")
          .hideBlade("properties")
          .expandBlade("searchResult")
          .modifyState((states) => {
            // reset search result page as it might not be available anymore after applying the refinement filter
            states.searchResult.page = 1
            // unselect search result as it might not be available anymore after applying the refinement
            delete states.searchResult.selectedNode

            states.screen.searchRefinements = message.payload
          })
      }
    }
    return state
  },
})
