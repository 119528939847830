import * as React from "react"
import { SVGProps } from "react"

const SvgBookBlank = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="1em" height="1em" {...props}>
    <path d="M88 0C39.4 0 0 39.4 0 88v336h.4c-.3 2.6-.4 5.3-.4 8 0 44.2 35.8 80 80 80h344c13.3 0 24-10.7 24-24s-10.7-24-24-24h-8v-69.4c18.9-9 32-28.3 32-50.6V56c0-30.9-25.1-56-56-56H88zm280 400v64H80c-17.7 0-32-14.3-32-32s14.3-32 32-32h288zM80 352c-11.4 0-22.2 2.4-32 6.7V88c0-22.1 17.9-40 40-40h304c4.4 0 8 3.6 8 8v288c0 4.4-3.6 8-8 8H80z" />
  </svg>
)

export default SvgBookBlank
