import { useTranslation } from "react-i18next"
import { usePreviewContentModel } from "../contentModel"
import { keys } from "@st4/ui-strings"

/**
 * Returns the GUI-String for a given commentType, or a default if no match possible.
 * @param commentType The system name of the commentType
 */
export function useCommentTypeGuiString(commentType?: string | null) {
  const { t } = useTranslation()
  const previewContentModel = usePreviewContentModel()
  const defaultString = t(keys.label.comment.comment)
  if (!commentType) return defaultString
  if (previewContentModel.state !== "ready") return defaultString
  const type = previewContentModel.commentModel.availableTypes.find((t) => t.value === commentType)?.label
  return type || defaultString
}
