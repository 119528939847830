import * as React from "react"
import { SVGProps } from "react"

const SvgSt4InsertimagelegendRegular = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1em" height="1em" {...props}>
    <path d="M206.8 369.9v-68.4h121v68.4h-121zm0 31.6h121v78.9H217.3c-5.8 0-10.5-4.7-10.5-10.5v-68.4zm152.6 78.9v-78.9h121v68.4c0 5.8-4.7 10.5-10.5 10.5H359.4zm121-110.5h-121v-68.4h121v68.4zM217.3 217.3c-23.2 0-42.1 18.9-42.1 42.1v210.5c0 23.2 18.9 42.1 42.1 42.1h252.6c23.2 0 42.1-18.9 42.1-42.1V259.4c0-23.2-18.9-42.1-42.1-42.1H217.3zm-82.1 77.6c-1 1-1.9 2-2.7 3.2l-80 112-4.5 6.2V96c0-8.8 7.2-16 16-16h384c8.8 0 16 7.2 16 16v81.3h48V96c0-35.3-28.7-64-64-64H64C28.7 32 0 60.7 0 96v320c0 35.3 28.7 64 64 64h71.2V294.9zm8.4-166.9a47.6 47.6 0 1 0 0 95.2 47.6 47.6 0 1 0 0-95.2z" />
  </svg>
)

export default SvgSt4InsertimagelegendRegular
