import React from "react"
import { Avatar, Col, List, Row, Spin, Typography } from "antd"
import { Icon, Regular } from "@st4/icons"
import { Alert } from "@schema/styled-ui"
import { SearchResultItem, SearchResultItemIds } from "../types"
import { useTranslation } from "react-i18next"
import { keys } from "@st4/ui-strings"

export const baseTestId = "searchResultList"

export type SearchComponentProps = {
  searchTerm: string
  loading: boolean
  error?: { name: string; message: string }
}

type SearchResultProps = SearchComponentProps & {
  searchResultItems?: SearchResultItem[]
  onSearchResultItemSelected: (ids: SearchResultItemIds) => void
}

export function SearchResultList(props: SearchResultProps) {
  const { t } = useTranslation()

  if (props.loading)
    return (
      <div style={{ paddingLeft: "8px", paddingRight: "8px" }}>
        <Alert isInfo>
          <Spin size={"small"} />
          &nbsp;
          <span data-testid={`${baseTestId}-info-loading`}>
            {t(keys.message.search.info.searchingForTerm, { searchTerm: props.searchTerm })}
          </span>
        </Alert>
      </div>
    )

  if (props.error)
    return (
      <div style={{ paddingLeft: "8px", paddingRight: "8px" }}>
        <Alert isError>
          <span data-testid={`${baseTestId}-error`}>
            {t(keys.message.search.error.fromException, {
              errorName: props.error.name,
              errorMessage: props.error.message,
            })}
          </span>
        </Alert>
      </div>
    )

  if (!props.searchResultItems)
    return (
      <div style={{ paddingLeft: "8px", paddingRight: "8px" }}>
        <Alert isError>
          <span data-testid={`${baseTestId}-error`}>{t(keys.message.search.error.corruptResponse)}</span>
        </Alert>
      </div>
    )

  if (props.searchResultItems.length == 0)
    return (
      <div style={{ paddingLeft: "8px", paddingRight: "8px" }}>
        <Alert isWarning>
          <span data-testid={`${baseTestId}-warning-noItems`}>
            {t(keys.message.search.warning.noSearchResult, { searchTerm: props.searchTerm })}
          </span>
        </Alert>
      </div>
    )

  return (
    <List
      data-testid={`${baseTestId}-list`}
      bordered
      itemLayout={"vertical"}
      loading={props.loading}
      dataSource={props.searchResultItems}
      rowKey={(item) => item.id}
      renderItem={(item) => (
        <SearchResultListItem item={item} onSearchResultItemSelected={props.onSearchResultItemSelected} />
      )}
    />
  )
}

function SearchResultListItem({
  item,
  onSearchResultItemSelected,
}: {
  item: SearchResultItem
  onSearchResultItemSelected: (ids: SearchResultItemIds) => void
}) {
  return (
    <List.Item>
      <Row>
        <Col span={2} style={{ cursor: "pointer" }} onClick={clickHandler} /* Icon */>
          <Avatar
            data-testid={`${baseTestId}-item-${clearString(item.id)}-icon`}
            shape={"square"}
            size={"small"}
            src={item.iconUri}
          />
        </Col>
        <Col span={21} style={{ cursor: "pointer" }} onClick={clickHandler} /* Title */>
          <Typography.Text data-testid={`${baseTestId}-item-${clearString(item.id)}-title`}>
            {item.title}
          </Typography.Text>
        </Col>
        {!item.treeNodeId ? (
          <></>
        ) : (
          <Col span={1} /* Link to Free Mode */>
            <Typography.Link
              data-testid={`${baseTestId}-item-${clearString(item.id)}-link`}
              style={{ float: "right" }}
              href={`/task/explore?nodeId=${item.treeNodeId}`}
              target={"_blank"}
            >
              <Icon component={Regular.ArrowUpRightFromSquare} />
            </Typography.Link>
          </Col>
        )}
      </Row>
      <Row>
        <Col span={2} style={{ cursor: "pointer" }} onClick={clickHandler} /* Free cell below Avatar */ />
        <Col span={21} style={{ cursor: "pointer" }} onClick={clickHandler} /* Path and Highlights */>
          <Typography>
            {!!item.path && (
              <Typography.Paragraph data-testid={`${baseTestId}-item-${clearString(item.id)}-path`} type={"secondary"}>
                {item.path}
              </Typography.Paragraph>
            )}
            <Highlights id={clearString(item.id)} highlights={item.highlights.join(" ... ")} />
          </Typography>
        </Col>
      </Row>
    </List.Item>
  )

  function clearString(text: string) {
    return text.replace("x-stobject:", "")
  }

  function clickHandler() {
    onSearchResultItemSelected({
      stObjectId: item.id,
      patternContextId: item.treeNodeId || "x-pattern-context:/" + clearString(item.id),
    })
  }
}

function Highlights({ id, highlights }: { id: string; highlights: string }) {
  const startsWithHighlight = highlights.trim().indexOf("<b>") == 0
  const highlightParts = highlights
    .trim()
    .split("<b>")
    .flatMap((s) => s.split("</b>"))
    .filter((highlight) => !!highlight?.length)

  return (
    <Typography.Paragraph>
      <span data-testid={`${baseTestId}-item-${id}-highlights`}>
        {highlightParts.map((highlight, index) => (
          <span key={parseInt(id + index)} style={{ fontWeight: fontWeight(index) }}>
            {highlight}
          </span>
        ))}
      </span>
    </Typography.Paragraph>
  )

  function fontWeight(index: number) {
    const isEven = index % 2 == 0
    return startsWithHighlight ? (isEven ? "bold" : "normal") : isEven ? "normal" : "bold"
  }
}
