import React, { useCallback, useEffect, useMemo, useRef } from "react"
import styled from "styled-components"
import { AnnotationCard, EmptyAnnotations, HighlightedAnnotation, LoadingAnnotations } from "./AnnotationCollection"
import { useVirtualizer } from "@tanstack/react-virtual"
import { AnnotationDisplay } from "./AnnotationDisplay"
import { ExpandingText } from "./ExpandingText"
import { useComments } from "./useComments"
import { makeVar, useReactiveVar } from "@apollo/client"
import { useScrollToContent, useVirtualizedCommentScrollWatcher } from "../Scrolling"

const CommentScrollContainer = styled.div`
  overflow: auto;
  height: 100%;
  overflow-anchor: none;
`

const EXPANDED_REPLIES = makeVar(new Map<string, boolean>())

export function useReplyExpansion() {
  const expandedReplies = useReactiveVar(EXPANDED_REPLIES)

  const toggleReplyExpansion = useCallback(
    (currentAnnotation: string | boolean | Map<string, boolean>) => {
      let newReplies = new Map(expandedReplies)
      if (typeof currentAnnotation === "string") {
        if (newReplies.has(currentAnnotation)) newReplies.set(currentAnnotation, !newReplies.get(currentAnnotation))
        else newReplies.set(currentAnnotation, true)
      } else if (typeof currentAnnotation === "boolean") {
        newReplies = new Map([...expandedReplies].map((s) => [s[0], currentAnnotation]))
      } else if (typeof currentAnnotation === typeof new Map<string, boolean>()) {
        newReplies = currentAnnotation
      }
      EXPANDED_REPLIES(newReplies)
    },
    [expandedReplies],
  )

  const hasExpandedReplies = [...expandedReplies.values()].filter((val) => val).length > 0
  return { expandedReplies, toggleReplyExpansion, hasExpandedReplies }
}

export function CommentList() {
  const { textComments, commentsForList, loading } = useComments()
  const { expandedReplies, toggleReplyExpansion } = useReplyExpansion()
  const scrollToNode = useScrollToContent()
  useEffect(() => {
    const allRootIds = textComments.map((c) => c.commentNode.id)
    const currentlyExpanded = EXPANDED_REPLIES()

    EXPANDED_REPLIES(new Map(allRootIds.map((id) => [id, currentlyExpanded.get(id) ?? false])))
  }, [textComments])

  const scrollElementRef = useRef<HTMLDivElement>(null)
  const virtualizer = useVirtualizer({
    count: commentsForList.length,
    getScrollElement: () => scrollElementRef.current,
    overscan: 4,
    estimateSize: () => 150,
    getItemKey: (index) => {
      const comment = commentsForList[index]
      const key = comment.type == "ContentComment" ? comment.commentNode.id : comment.nodeId
      return key
    },
  })
  const indexByCommentIdOrNodeId = useMemo(() => {
    const result = new Map<string, number>()
    for (const [index, comment] of commentsForList.entries()) {
      if (comment.type == "ContentComment") result.set(comment.commentNode.id, index)
      else result.set(comment.nodeId, index)
    }
    return result
  }, [commentsForList])
  const scrollToOuterNode = useCallback(
    (id) => {
      const nodeIndex = indexByCommentIdOrNodeId.get(id)
      if (nodeIndex !== undefined) virtualizer.scrollToIndex(nodeIndex)
    },
    [virtualizer, indexByCommentIdOrNodeId],
  )
  useVirtualizedCommentScrollWatcher(scrollToOuterNode)

  if (loading)
    return (
      <CommentScrollContainer>
        <LoadingAnnotations />
      </CommentScrollContainer>
    )
  if (!commentsForList.length)
    return (
      <CommentScrollContainer>
        <EmptyAnnotations />
      </CommentScrollContainer>
    )

  const virtualItems = virtualizer.getVirtualItems()
  return (
    <CommentScrollContainer ref={scrollElementRef}>
      <div style={{ height: `${virtualizer.getTotalSize()}px`, width: "100%", position: "relative" }}>
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            transform: `translateY(${virtualItems[0]?.start ?? 0}px)`,
          }}
        >
          {virtualItems.map((virtualItem) => {
            const comment = commentsForList[virtualItem.index]

            return (
              <div
                style={{ display: "flow-root" }}
                key={virtualItem.key}
                data-index={virtualItem.index}
                ref={virtualizer.measureElement}
              >
                {comment.type == "NodeComment" ? (
                  <AnnotationCard
                    onClick={() => scrollToNode({ outer: comment.treeNodeId })}
                    style={{ cursor: "pointer" }}
                  >
                    <AnnotationDisplay type={comment.commentType}>
                      <ExpandingText>{comment.value}</ExpandingText>
                    </AnnotationDisplay>
                  </AnnotationCard>
                ) : (
                  <HighlightedAnnotation
                    commentNode={comment.commentNode}
                    treeNode={{ id: comment.treeNodeId }}
                    expandedReplies={expandedReplies}
                    toggleReplyExpansion={toggleReplyExpansion}
                    replies={comment.replies}
                  />
                )}
              </div>
            )
          })}
        </div>
      </div>
    </CommentScrollContainer>
  )
}
