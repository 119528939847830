import { keys } from "@st4/ui-strings"

export function getEditCheckMessageKey(messageKey: string) {
  switch (messageKey) {
    case "EAC0000":
    case "EAC0001":
    case "EAC0002":
    case "EAC1000":
    case "EAC1100":
    case "EAC1200":
    case "EAC1300":
    case "EAC1400":
    case "EAC1500":
    case "EAC1600":
      return keys.message.editor.canEditCheck.error.InternalError
    case "EAC1001":
    case "EAC1101":
      return keys.message.editor.canEditCheck.error.PermissionDenied
    case "EAC1002":
      return keys.message.editor.canEditCheck.error.NodeLocked
    case "EAC1201":
    case "EAC1202":
      return keys.message.editor.canEditCheck.error.NodeReused
    case "EAC1301":
      return keys.message.editor.canEditCheck.error.ContentLinked
    case "EAC1401":
      return keys.message.editor.canEditCheck.error.AspectNotEditingLanguage
    case "EAC1501":
      return keys.message.editor.canEditCheck.error.NodeReleasedCurrentAspect
    case "EAC1502":
      return keys.message.editor.canEditCheck.error.NodeReleasedOtherAspect
    case "EAC1601":
      return keys.message.editor.canEditCheck.warning.NodeInTranslation
    case "EAC2000":
      return keys.message.editor.canEditCheck.error.NodeDeleted
    default:
      return ""
  }
}
