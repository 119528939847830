import * as React from "react"
import { SVGProps } from "react"

const SvgSt4ComponentRegular = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1em" height="1em" {...props}>
    <path d="m429.3 109.2-90.6-90.5C326.7 6.7 310.5 0 293.5 0H128C92.7 0 64 28.7 64 64v384c0 35.3 28.7 64 64 64h256c35.3 0 64-28.7 64-64V154.5c0-17-6.7-33.3-18.7-45.3zM400 448c0 8.8-7.2 16-16 16H128c-8.8 0-16-7.2-16-16V64c0-8.8 7.2-16 16-16h160v80c0 17.7 14.3 32 32 32h80v288zm-30.3-177.8c1.5 4 .2 8.5-3 11.4l-20 18.2c.5 3.8.8 7.8.8 11.7s-.3 7.9-.8 11.7l20 18.2c3.2 2.9 4.4 7.3 3 11.4-2 5.5-4.5 10.8-7.3 15.8l-2.2 3.7c-3 5.1-6.5 9.9-10.2 14.4-2.7 3.3-7.2 4.4-11.3 3.1l-25.7-8.2c-6.2 4.8-13 8.7-20.3 11.7l-5.8 26.3c-.9 4.2-4.2 7.5-8.4 8.2-6.4 1.1-12.9 1.6-19.6 1.6s-13.2-.6-19.6-1.6c-4.2-.7-7.5-4-8.4-8.2l-5.8-26.3c-7.3-3-14.1-7-20.3-11.7l-25.7 8.2c-4.1 1.3-8.6.1-11.3-3.1-3.7-4.5-7.2-9.3-10.2-14.4l-2.2-3.7c-2.8-5.1-5.3-10.3-7.3-15.8-1.5-4-.2-8.5 3-11.4l20-18.2c-.5-3.9-.8-7.8-.8-11.8s.3-7.9.8-11.7l-20-18.2c-3.2-2.9-4.4-7.3-3-11.4 2-5.5 4.5-10.8 7.3-15.8l2.2-3.7c3-5.1 6.5-9.9 10.2-14.4 2.7-3.3 7.2-4.4 11.3-3.1l25.7 8.2c6.2-4.8 13-8.7 20.3-11.7l5.8-26.3c.9-4.2 4.2-7.5 8.4-8.2 6.4-1.1 12.9-1.7 19.6-1.7s13.2.6 19.6 1.6c4.2.7 7.5 4 8.4 8.2l5.8 26.3c7.3 3 14.1 7 20.3 11.7l25.7-8.2c4.1-1.3 8.6-.1 11.3 3.1 3.7 4.5 7.2 9.3 10.2 14.4l2.2 3.7c2.9 5.2 5.3 10.4 7.3 16zM259 348.3c20.4 0 36.9-16.5 36.9-36.9s-16.5-36.9-36.9-36.9-36.9 16.5-36.9 36.9 16.5 36.9 36.9 36.9z" />
  </svg>
)

export default SvgSt4ComponentRegular
