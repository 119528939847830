import { Col, Image, Popover, Row, Table } from "antd"
import { Descriptions } from "@schema/styled-ui"
import React, { useMemo } from "react"
import type { Node } from "unist"
import { useNode } from "../../contentModel/nodeContext"
import { notEmpty } from "../../utilities"
import type { XastRendererProps } from "../XASTRenderer"
import { XASTViewer } from "./XASTViewer"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { keys } from "@st4/ui-strings"
import { GetMediaSource } from "@st4/content-tools"
import { useSt4ToTreeNode } from "../../utilities/treeUtilities"
import { useTreeNodeFilter } from "../FilteringTreeNodeWrapper"
import type { NodeDisplay } from "../PreviewConfig"
import { isST4NodeWithContent } from "../../graphql/types"
import type { ST4NodeWithContentName } from "../../graphql/types"
import { isTypename } from "@st4/graphql"
import { Icon, Regular } from "@st4/icons"

const StyledLexiconLink = styled.span`
  color: ${({ theme }) => theme.preview.lexiconColor};
`
const DescriptionText = styled.div`
  padding: 0 8px;
  margin-bottom: 8px;
`

const ImageCol = styled(Col)`
  display: flex;
  max-width: 15vw;
  justify-content: center;
  align-items: center;
  padding-left: 16px;
`

type LexiconLinkProps = XastRendererProps

export function LexiconLink(props: React.PropsWithChildren<LexiconLinkProps>) {
  const node = useNode()
  const st4ToTreeNode = useSt4ToTreeNode()

  if (!props.ast.children) throw new Error("The passed XAST doesn't have the required structure.")
  if (!isST4NodeWithContent("TextContent", "TextGroupContent")(node))
    throw new Error("Lexicon links can only be displayed inside TextContent.")
  const lexiconEntries = node.content.lexiconEntries
  if (!lexiconEntries) throw new Error("No lexicon entries in content. Missing from query?")
  const attributes = props.ast.attributes
  if (!attributes || !attributes["linkid"]) throw new Error("No linkid attribute found on XAST.")
  const linkId = attributes["linkid"].value
  const entry = lexiconEntries.find((ref) => ref?.id === linkId)

  const treeNode = st4ToTreeNode(entry?.target)
  const treeNodeFilter = useTreeNodeFilter()
  if (!entry || !treeNode || !treeNodeFilter(treeNode.id).visible) {
    // If lexicon is not referenced in subtree from server or is filtered in preview, its content must still be shown.
    return <XASTViewer xast={props.ast.children[0]} />
  }
  const target = entry.target
  if (!isTypename("ST4Node")(target) || !isST4NodeWithContent("LexiconEntryContent")(target)) return null
  const ttprops = getTooltipData(target, st4ToTreeNode, treeNodeFilter)
  return (
    <div data-scroll-target={treeNode.id} style={{ display: "inline" }}>
      <LexiconTextWithTooltip {...ttprops}>
        <StyledLexiconLink>
          <Icon component={Regular.Book} /> <XASTViewer xast={props.ast.children[0]} />
        </StyledLexiconLink>
      </LexiconTextWithTooltip>
    </div>
  )
}

function getTooltipData(
  target: ST4NodeWithContentName<"LexiconEntryContent">,
  st4ToTreeNode: ReturnType<typeof useSt4ToTreeNode>,
  treeNodeFilter: (treeNodeId?: string) => NodeDisplay,
) {
  const nodeContent = target.content
  const descriptionString = nodeContent.descriptionXast
  const description = descriptionString ? JSON.parse(descriptionString) : null
  const termNodes = nodeContent.terms.filter(isTypename("ST4Node"))
  const terms = termNodes
    .map((n) =>
      !isST4NodeWithContent("TermContent")(n)
        ? null
        : {
            label: n.label,
            validity: n.content.validity,
            gramaticalGender: n.content.gramaticalGender,
            editLanguage: n.content.editLanguage,
          },
    )
    .filter(notEmpty)
  const data = {
    description: description?.children[0].children[0] ?? "",
    lexiconName: isTypename("ST4Node")(nodeContent.lexicon) ? nodeContent.lexicon.label : "",
    terms,
    subject: nodeContent.subject,
    sortKey: nodeContent.sortKey,
  }
  const imageNode = nodeContent.image
  if (!isTypename("ST4Node")(imageNode)) return data
  const imageTreeNode = st4ToTreeNode(imageNode)
  const imageisInFilter = treeNodeFilter(imageTreeNode?.id).visible
  if (!imageNode || !imageisInFilter || !isST4NodeWithContent("MediaContent")(imageNode)) return data
  if (!imageNode.content) return data

  const mediaInfo = imageNode.content.media
  if (!mediaInfo || isTypename("NoMediaInfo")(mediaInfo)) return data
  const imageSrc = GetMediaSource(mediaInfo)

  return {
    ...data,
    image: { src: imageSrc, thumbnail: mediaInfo?.thumbnail ?? "" },
  }
}

type LexiconTooltipProps = {
  lexiconName?: string | null
  description: Node
  subject?: string | null
  sortKey?: string | null
  terms: { label?: string | null; validity?: string | null; gramaticalGender?: string | null }[]
  image?: { src?: string | null; thumbnail: string }
}

function LexiconTextWithTooltip(props: React.PropsWithChildren<LexiconTooltipProps>) {
  const { t } = useTranslation()
  const additionalInformation = useMemo(
    () =>
      [
        props.subject ? { label: t(keys.label.preview.lexicon.subjectField), content: props.subject } : null,
        props.sortKey ? { label: t(keys.label.general.indexentry.sort), content: props.sortKey } : null,
      ].filter(notEmpty),
    [props.subject, props.sortKey, t],
  )
  return (
    <Popover
      trigger="hover"
      title={t(keys.label.preview.lexicon.tooltipHeadline, { lexiconName: props.lexiconName })}
      content={
        <Row wrap={false} style={{ maxWidth: "50vw" }}>
          <Col flex={4}>
            {!!props.description && (
              <DescriptionText>
                <XASTViewer xast={props.description} />
              </DescriptionText>
            )}
            {!!additionalInformation.length && (
              <div style={{ marginBottom: 16 }}>
                <Descriptions
                  column={1}
                  size="small"
                  bordered
                  dataSource={additionalInformation}
                  itemStyle={{ padding: 8 }}
                />
              </div>
            )}
            <div>
              {!!props.terms.length && (
                <Table
                  size="small"
                  bordered
                  dataSource={props.terms}
                  pagination={{ hideOnSinglePage: true, pageSize: 5 }}
                  columns={[
                    { title: t(keys.label.preview.lexicon.term), dataIndex: "label", key: "label" },
                    { title: t(keys.label.preview.lexicon.validity), dataIndex: "validity", key: "validity" },
                    {
                      title: t(keys.label.preview.lexicon.grammaticalGender),
                      dataIndex: "gramaticalGender",
                      key: "gramaticalGender",
                    },
                    {
                      title: t(keys.label.generic.language),
                      dataIndex: "editLanguage",
                      key: "editLanguage",
                    },
                  ]}
                />
              )}
            </div>
          </Col>
          {props.image && props.image.src && (
            <ImageCol flex={1}>
              <Image
                src={props.image.src}
                preview={false}
                placeholder={<Image preview={false} src={props.image?.thumbnail} width="100%" />}
              />
            </ImageCol>
          )}
        </Row>
      }
    >
      {props.children}
    </Popover>
  )
}
