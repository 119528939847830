import * as React from "react"
import { SVGProps } from "react"

const SvgOmega = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="1em" height="1em" {...props}>
    <path d="M224 64C118 64 32 150 32 256c0 69.5 36.9 130.4 92.3 164.1 9.4 5.7 15.9 16.1 15.9 27.9 0 17.7-14.3 32-32 32H16c-8.8 0-16-7.2-16-16s7.2-16 16-16h92.2v-.1c-.1-.1-.2-.3-.5-.5C43.1 408.2 0 337.1 0 256 0 132.3 100.3 32 224 32s224 100.3 224 224c0 81.1-43.1 152.1-107.6 191.4-.3.2-.4.3-.5.5v.1H432c8.8 0 16 7.2 16 16s-7.2 16-16 16h-92.2c-17.7 0-32-14.3-32-32 0-11.8 6.5-22.2 15.9-27.9C379.1 386.4 416 325.5 416 256c0-106-86-192-192-192z" />
  </svg>
)

export default SvgOmega
