import React, { useState } from "react"
import { DatePicker } from "@st4/ui"
import dayjs from "dayjs"
import { StringEditor } from "../stringeditor"
import { Icon, Regular } from "@st4/icons"

type DateEditorProps = {
  value?: Date
  isReadonly?: boolean
  suffixIcon?: React.ReactNode
  onSubmit: (value: Date | null) => void
  showTime?: boolean
  errorMessage?: string
  style: React.ComponentProps<typeof DatePicker>["style"]
}

export function DateEditor({
  style,
  value,
  isReadonly,
  suffixIcon,
  onSubmit,
  showTime,
  errorMessage,
}: DateEditorProps) {
  const [inputValue, setInputValue] = useState(value ?? null)
  const [prevValue, setPrevValue] = useState(value)
  if (value !== prevValue) {
    setInputValue(value ?? null)
    setPrevValue(value)
  }

  const dateTimeValue = dayjs(inputValue).isValid() ? dayjs(inputValue) : undefined

  const submitHandler = (value: dayjs.Dayjs | null) => {
    onSubmit(value?.toDate() || null)
    setInputValue(value?.toDate() || null)
  }

  if (!suffixIcon && !isReadonly) {
    suffixIcon = <Icon component={Regular.CalendarDay} />
  }

  if (isReadonly && dateTimeValue === undefined)
    return <StringEditor isReadonly={isReadonly} value="" onSubmit={() => {}} />

  return (
    <DatePicker
      style={style}
      allowClear={true}
      showTime={showTime ?? false}
      format={(date) => date.toDate().toLocaleString()}
      suffixIcon={suffixIcon}
      value={dateTimeValue}
      readonly={isReadonly}
      onChange={submitHandler}
      errorMessage={errorMessage}
    />
  )
}
