import React from "react"
import { useTranslation } from "react-i18next"
import { Tooltip } from "@st4/ui"
import { keys } from "@st4/ui-strings"
import { GroupList } from "~/ui/grouplist"
import { ListedPropertiesProps } from "./ListedProperties"
import { PropertyEditor } from "./editors"

type CategorizedPropertiesProps = ListedPropertiesProps & {
  expandedCategories?: string[]
  onExpand?: (expandedCategories: string[]) => void
}

export function CategorizedProperties({
  properties,
  expandedCategories,
  onExpand,
  ...editorProps
}: CategorizedPropertiesProps) {
  const { t } = useTranslation()
  const miscCategory = t(keys.label.general.misc)
  const withCategory = properties.map((p) => (p.category ? p : { ...p, category: miscCategory }))

  return (
    <GroupList
      items={withCategory}
      groupBy="category"
      expandedKeys={expandedCategories}
      onExpand={onExpand}
      renderItem={(item) => {
        return (
          <GroupList.Item
            key={item.editor.id}
            label={<Tooltip title={item.description}>{item.displayName}</Tooltip>}
            labelStyle={{ overflowWrap: "break-word" }}
          >
            <PropertyEditor {...editorProps} propertyEditor={item.editor} fallbackDisplayValue={item.displayValue} />
          </GroupList.Item>
        )
      }}
    />
  )
}
