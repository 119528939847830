import React from "react"
import { Tooltip } from "@st4/ui"
import { List } from "~/ui/list"
import { PropertyEditor, PropertyEditorProps } from "./editors"
import { Properties } from "./sensibleTypes"

export type ListedPropertiesProps = {
  properties: Properties
} & Pick<PropertyEditorProps, "languageId" | "refetchOnSave" | "refetchProperties">

export function ListedProperties({ properties, ...editorProps }: ListedPropertiesProps) {
  return (
    <List
      items={properties}
      renderItem={(item) => {
        return (
          <List.Item key={item.editor.id} label={<Tooltip title={item.description}>{item.displayName}</Tooltip>}>
            <PropertyEditor {...editorProps} propertyEditor={item.editor} fallbackDisplayValue={item.displayValue} />
          </List.Item>
        )
      }}
    />
  )
}
