import * as Types from "@st4/graphql"

import { gql } from "@apollo/client"
import * as Apollo from "@apollo/client"
const defaultOptions = {} as const
export type St4TreeNodeFragment = {
  __typename: "ST4TreeNode"
  id: string
  label: string
  variant: string
  icon?: { __typename: "IconSet"; default?: { __typename: "TreeIconData"; uri?: string | null } | null } | null
  node?: {
    __typename: "ST4Node"
    id: string
    icon?: { __typename: "IconSet"; default?: { __typename: "TreeIconData"; uri?: string | null } | null } | null
    nodeClass?: { __typename: "ST4Class"; classHierarchy: Array<string> }
  } | null
}

export type NavigationTreeQueryVariables = Types.Exact<{
  variant: Types.Scalars["String"]
  aspectId: Types.Scalars["AspectSelectorId"]
  expandedNodes?: Types.InputMaybe<Array<Types.Scalars["StPatternContextId"]> | Types.Scalars["StPatternContextId"]>
  ignoreIcons: Types.Scalars["Boolean"]
  includeNodeClassHierarchy: Types.Scalars["Boolean"]
  selectedNodes?: Types.InputMaybe<Array<Types.Scalars["StPatternContextId"]> | Types.Scalars["StPatternContextId"]>
}>

export type NavigationTreeQuery = {
  __typename: "Query"
  indentedTree: {
    __typename: "IndentedTree"
    allExpandedNodes: Array<any>
    tree: Array<{
      __typename: "IndentedTreeNode"
      indent: number
      hasChildren: boolean
      treeNode: {
        __typename: "ST4TreeNode"
        id: string
        label: string
        variant: string
        icon?: { __typename: "IconSet"; default?: { __typename: "TreeIconData"; uri?: string | null } | null } | null
        node?: {
          __typename: "ST4Node"
          id: string
          icon?: { __typename: "IconSet"; default?: { __typename: "TreeIconData"; uri?: string | null } | null } | null
          nodeClass?: { __typename: "ST4Class"; classHierarchy: Array<string> }
        } | null
      }
    }>
  }
}

export const St4TreeNodeFragmentDoc = gql`
  fragment ST4TreeNode on ST4TreeNode {
    id
    icon @skip(if: $ignoreIcons) {
      default {
        uri
      }
    }
    label(languageId: $aspectId, variantName: $variant)
    node {
      id
      ... on ST4Node {
        icon @skip(if: $ignoreIcons) {
          default {
            uri
          }
        }
        nodeClass @include(if: $includeNodeClassHierarchy) {
          classHierarchy
        }
      }
    }
    variant
  }
`
export const NavigationTreeDocument = gql`
  query NavigationTree(
    $variant: String!
    $aspectId: AspectSelectorId!
    $expandedNodes: [StPatternContextId!]
    $ignoreIcons: Boolean!
    $includeNodeClassHierarchy: Boolean!
    $selectedNodes: [StPatternContextId!]
  ) {
    indentedTree(
      languageId: $aspectId
      variant: $variant
      expandedNodes: $expandedNodes
      selectedNodes: $selectedNodes
    ) {
      __typename
      ... on IndentedTree {
        tree {
          indent
          hasChildren
          treeNode {
            ...ST4TreeNode
          }
        }
        allExpandedNodes
      }
    }
  }
  ${St4TreeNodeFragmentDoc}
`

/**
 * __useNavigationTreeQuery__
 *
 * To run a query within a React component, call `useNavigationTreeQuery` and pass it any options that fit your needs.
 * When your component renders, `useNavigationTreeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNavigationTreeQuery({
 *   variables: {
 *      variant: // value for 'variant'
 *      aspectId: // value for 'aspectId'
 *      expandedNodes: // value for 'expandedNodes'
 *      ignoreIcons: // value for 'ignoreIcons'
 *      includeNodeClassHierarchy: // value for 'includeNodeClassHierarchy'
 *      selectedNodes: // value for 'selectedNodes'
 *   },
 * });
 */
export function useNavigationTreeQuery(
  baseOptions: Apollo.QueryHookOptions<NavigationTreeQuery, NavigationTreeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<NavigationTreeQuery, NavigationTreeQueryVariables>(NavigationTreeDocument, options)
}
export function useNavigationTreeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<NavigationTreeQuery, NavigationTreeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<NavigationTreeQuery, NavigationTreeQueryVariables>(NavigationTreeDocument, options)
}
export type NavigationTreeQueryHookResult = ReturnType<typeof useNavigationTreeQuery>
export type NavigationTreeLazyQueryHookResult = ReturnType<typeof useNavigationTreeLazyQuery>
export type NavigationTreeQueryResult = Apollo.QueryResult<NavigationTreeQuery, NavigationTreeQueryVariables>
