import { SearchResultItemFragment } from "../graphql/query.hooks"

export type SearchResultItem = {
  id: string
  treeNodeId?: string
  title: string
  path: string
  iconUri: string
  highlights: string[]
}

export type SearchResultItemIds = {
  stObjectId: string
  patternContextId: string
}

export function fromGraphQLSearchResultItem(searchResultItem: SearchResultItemFragment): SearchResultItem {
  if (searchResultItem.node.__typename != "ST4Node")
    throw new Error(
      `Expected search result item to have node type "ST4Node", but found node type "${searchResultItem.node.__typename}"`,
    )

  return {
    id: searchResultItem.id,
    treeNodeId: searchResultItem.treeNodeId,
    title: searchResultItem.node.title ?? "",
    path: searchResultItem.node.properties?.edges?.find((prop) => prop?.node?.name == "path")?.node?.value ?? "",
    iconUri: searchResultItem.node.icon?.default?.uri ?? "",
    highlights: searchResultItem.highlights,
  } as SearchResultItem
}
