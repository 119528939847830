import { TreeNode } from "~/ui/editors/taxonomyEditor/types"
import type { TaxTreeNode } from "./types"

export function buildTree<TtreeNodes extends TaxTreeNode>(
  roots: string[],
  nodes: Array<TtreeNodes>,
  selectLeavesOnly: boolean,
): TreeNode[] {
  const nodeMap = new Map<string, (typeof nodes)[0]>()
  nodes.forEach((node) => {
    nodeMap.set(node.id, node)
  })
  const rootNodes = roots.filter((id) => nodeMap.has(id)).map((id) => nodeMap.get(id)) as TtreeNodes[]
  return rootNodes.map((root) => addChildren(root, nodeMap, selectLeavesOnly))
}

function addChildren<TNode extends TaxTreeNode>(
  node: TNode,
  nodeMap: Map<string, TNode>,
  selectLeavesOnly: boolean,
): TreeNode {
  const transformedNode = {
    title: node.node?.label ?? "",
    value: node.node?.id ?? "",
    key: node.node?.id ?? "",
    checkable: true,
    selectable: true,
    titleString: node.node?.label ?? "",
  }
  if (!node.children?.length) {
    return transformedNode
  }
  const children = node.children.map((cid) =>
    addChildren(
      nodeMap.get(cid) ?? { children: new Array<string>(), id: cid, __typename: "ST4TreeNode" },
      nodeMap,
      selectLeavesOnly,
    ),
  )
  return {
    ...transformedNode,
    children,
    checkable: !selectLeavesOnly,
    selectable: !selectLeavesOnly,
  }
}
