import { useRef, useEffect, useCallback } from "react"
import { StateStoreHandler, reactiveStateStore, setDone, setRunning, useStateStoreHandler } from "./reactiveStateStore"
import { log } from "./log"
import { useSaveTaskStateMutation } from "../taskcontext/query.hooks.withSingeltonTasks"
import { TaskData } from "../definition/task"
import { useOnBeforeUnload } from "../hooks"
import { makeVar } from "@apollo/client"

export const LOCAL_TASK_DATA = makeVar<{
  stateToPersist: string
  lastSavedState: string
  isDirty: boolean
}>({
  stateToPersist: "",
  lastSavedState: "",
  isDirty: false,
})

/**
 * Subscribes to the useStateStoreHandler and will save the passed TaskData.
 * @param taskData
 */
export function useTaskDataStoreHandler(taskData: TaskData) {
  const [saveState] = useSaveTaskStateMutation()

  useEffect(() => {
    //upadate reactive var on new taskData
    const prev = LOCAL_TASK_DATA()
    const stateToPersist = JSON.stringify(taskData)
    LOCAL_TASK_DATA({
      ...prev,
      stateToPersist,
      isDirty: prev.lastSavedState !== stateToPersist,
    })
  }, [taskData])

  useStateStoreHandler(
    async function saveTaskState(correlationId, taskContextId, { signal }) {
      const { isDirty, lastSavedState, stateToPersist } = LOCAL_TASK_DATA()
      const logId = log.bind(null, `${correlationId} useTaskDataStoreHandler`)
      if (!stateToPersist) {
        logId("No state to persist. Skip!")
        return
      }
      if (!lastSavedState) {
        logId("No previous state to compare. Might be first render. Skip!")
        LOCAL_TASK_DATA({
          stateToPersist,
          lastSavedState: stateToPersist,
          isDirty: false,
        })
        return
      }

      if (!isDirty) {
        logId("State unchanged since last persist. Skip!")
        return
      } else if (signal.aborted) {
        logId("Signal aborted. Skip!")
        return
      } else {
        logId("State changed since last persist. Save!")
        // maybe change the way saveState behaves if task isn't singleton... don't trigger graphql mutation immediately?
        await saveState({
          variables: {
            id: taskContextId,
            data: stateToPersist,
          },
          context: {
            fetchOptions: {
              signal,
            },
          },
        })
        LOCAL_TASK_DATA({
          stateToPersist,
          lastSavedState: stateToPersist,
          isDirty: false,
        })
        logId("Saved State. Done!")
      }
    },
    [saveState],
  )

  useOnBeforeUnload(() => LOCAL_TASK_DATA().isDirty)
}

/**
 * This hook will call all save handlers
 * @param interval The interval in which the state should be saved in ms. Values <= 0 disable autosaving
 * @returns
 */
export function useStateStore(taskId: string | undefined) {
  const abortController = useRef(new AbortController())

  const write = useCallback(
    async function write(correlationId: number, intervalAbortSignal?: AbortSignal) {
      const logId = log.bind(null, `${taskId} ${correlationId}`)
      const persState = reactiveStateStore()
      if (!taskId) {
        const error = new Error("Task definition has no ID and therefore no state can be stored!")
        throw error
      }

      if (persState.running) {
        logId(`Another state storing process is running for ${taskId}. Abort previous!`)
        abortController.current?.abort("New state store process replacing current.")
      }

      setRunning()

      logId("Running registered handlers.", persState.handlers)
      abortController.current = new AbortController()
      intervalAbortSignal?.addEventListener("abort", (reason) => {
        abortController.current.abort(reason)
      })
      abortController.current.signal.addEventListener("abort", (reason) => {
        logId(`Abortcontroller for ${String(taskId)} ${Number(correlationId)} canceled.`, reason)
      })
      try {
        const callHandler = async (handle: StateStoreHandler) => {
          logId("Running handler", handle)
          const time = performance.now()
          try {
            await handle(`${taskId} ${correlationId}`, taskId, abortController.current)
            logId(`Handler finished in ${performance.now() - time}`, handle)
          } catch (e) {
            logId(`Error during Handler execution`, handle, e)
            throw e
          }
        }
        const handlerExecution = Array.from(persState.handlers).map(callHandler)
        try {
          await Promise.all(handlerExecution)
          logId("All registered handlers ran successfully.", persState.handlers)
        } catch (e) {
          logId("All registered handlers ran some had errors.", persState.handlers, e)
        }

        setDone()
      } catch (e) {
        logId("All registered handlers ran, some with errors.", persState.handlers)
        setDone()
        throw e
      }
    },
    [taskId],
  )

  return {
    write,
  }
}
