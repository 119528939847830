import styled from "styled-components"
import { Box } from "./structure"
import type { Node } from "unist"

function getAlignmentFromAst(ast?: Node) {
  const type = ast?.attributes?.type
  if (!type) return null
  if (type.value.startsWith("p_table_c")) return "center"
  else if (type.value.startsWith("p_table_l")) return "left"
  else if (type.value.startsWith("p_table_r")) return "right"
  return null
}

export const TextBlock = styled(Box)`
  text-align: ${({ ast }) => getAlignmentFromAst(ast)};
  position: relative;
`
