import React from "react"
import { Popover } from "antd"
import styled from "styled-components"
import { Icon, Regular } from "@st4/icons"

const MetaDataStyle = styled.span<{ error?: boolean }>`
  background: ${({ theme }) => theme.token.colorFillTertiary};
  color: ${({ theme, error }) => (error ? theme.token.colorError : "inherit")};
  border-radius: ${({ theme }) => theme.token.borderRadius}px;
  padding-inline: ${({ theme }) => theme.token.paddingXXS}px;
  font-weight: bold;
`

type MetaRefDisplayProps = {
  isLoading?: boolean
  hasError?: boolean
  tooltipHeadline?: React.ReactNode
  tooltipContent?: React.ReactNode
}

export function MetaRefDisplay(props: React.PropsWithChildren<MetaRefDisplayProps>) {
  const { tooltipHeadline, tooltipContent, children, hasError, isLoading } = props

  const statusIcon = hasError ? (
    <Icon component={Regular.TriangleExclamation} />
  ) : isLoading ? (
    <Icon component={Regular.SpinnerThird} />
  ) : null
  const spacedIcon = statusIcon ? <>{statusIcon} </> : null

  if (!tooltipHeadline && !tooltipContent)
    return (
      <MetaDataStyle error={hasError}>
        {spacedIcon}
        {children}
      </MetaDataStyle>
    )
  return (
    <Popover title={tooltipHeadline} content={tooltipContent}>
      <MetaDataStyle error={hasError}>
        {spacedIcon}
        {children}
      </MetaDataStyle>
    </Popover>
  )
}
