import type { EmbeddedSt4NodeWithContentFragment } from "./types"
import type { ST4NodeWithContent, ST4NodeWithContentName } from "../graphql/types"
import { isST4NodeWithContent } from "../graphql/types"
import { mapFrom } from "../utilities"
import type {
  St4NodeWithContentFragment,
  TextContentWithFragmentsFragment,
} from "../graphql/applicationQueries/query.hooks"

function getFragments(textContent?: ST4NodeWithContent<TextContentWithFragmentsFragment>["content"] | null) {
  return textContent?.fragments ?? []
}

// Contains data from TextContent as maps for quicker access
export type TextContentState = {
  node: St4NodeWithContentFragment
  fragments: Map<string, EmbeddedSt4NodeWithContentFragment>
  parts: Map<string, EmbeddedSt4NodeWithContentFragment>
  comments: ST4NodeWithContentName<"TextContent" | "TextGroupContent">["content"]["comments"]
}

export function getTextContentState(st4Node: St4NodeWithContentFragment): TextContentState {
  const fragments = isST4NodeWithContent("TextContent", "TextGroupContent")(st4Node)
    ? mapFrom(
        getFragments(st4Node.content),
        (e) => e.compoundId,
        (e) => ({ ...e.contentNode, parent: st4Node }),
      )
    : new Map()

  const comments = isST4NodeWithContent("TextContent", "TextGroupContent")(st4Node) ? st4Node.content.comments : []

  const parts = isST4NodeWithContent("TextContent", "TextGroupContent")(st4Node)
    ? mapFrom(
        st4Node.content.parts ?? [],
        (e) => e.ref,
        (e) => ({ ...e.contentNode, parent: st4Node }),
      )
    : new Map()

  return {
    node: st4Node,
    fragments,
    comments,
    parts,
  }
}
