import { useApolloClient } from "@apollo/client"
import { useCallback } from "react"
import { ServerIndentedTreeNode } from "~/components/navigationtree"

export function useCacheOperations() {
  const apolloClient = useApolloClient()

  const removeNodeFromCachedTreeQueries = useCallback(
    (idToRemove: string) => {
      if (idToRemove === "") return

      apolloClient.cache.modify({
        broadcast: true,
        id: "ROOT_QUERY",
        fields: {
          indentedTree: (value) => {
            if (!value.tree) return value
            if (value.tree.length === 0) return value
            if (!value.tree.find((node: ServerIndentedTreeNode) => node.treeNode.id === idToRemove)) return value

            return {
              ...value,
              tree: [...value.tree.filter((node: ServerIndentedTreeNode) => node.treeNode.id !== idToRemove)],
            }
          },
        },
      })
    },
    [apolloClient.cache],
  )

  const evictContentOfNode = useCallback(
    (nodeId: string) => {
      let st4NodeId = nodeId
      if (st4NodeId.startsWith("x-pattern-context")) {
        const splitPatternId = nodeId.split("/")
        st4NodeId = `x-stobject:${splitPatternId[splitPatternId.length - 1]}`
      }

      apolloClient.refetchQueries({
        updateCache: (cache) => cache.evict({ id: `ST4Node:${st4NodeId}`, fieldName: "content" }),
        optimistic: true,
      })
    },
    [apolloClient],
  )

  const refetchPreviewOutline = useCallback(() => {
    apolloClient.refetchQueries({ include: ["getContent"] })
  }, [apolloClient])

  return { removeNodeFromCachedTreeQueries, evictContentOfNode, refetchPreviewOutline }
}
