import * as React from "react"
import { SVGProps } from "react"

const SvgSt4MargintextRegular = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="1em" height="1em" {...props}>
    <path d="M424 88H228.9c-13.3 0-24-10.7-24-24s10.7-24 24-24H424c13.3 0 24 10.7 24 24s-10.7 24-24 24zm24 232c0-13.3-10.7-24-24-24H224c-13.3 0-24 10.7-24 24s10.7 24 24 24h200c13.3 0 24-10.7 24-24zm0-128c0-13.3-10.7-24-24-24H224c-13.3 0-24 10.7-24 24s10.7 24 24 24h200c13.3 0 24-10.7 24-24zm0 256c0-13.3-10.7-24-24-24H224c-13.3 0-24 10.7-24 24s10.7 24 24 24h200c13.3 0 24-10.7 24-24zM156 208V88c0-26.5-21.5-48-48-48H48C21.5 40 0 61.5 0 88v120c0 26.5 21.5 48 48 48h60c26.5 0 48-21.5 48-48z" />
  </svg>
)

export default SvgSt4MargintextRegular
