import React, { ComponentType, SVGProps } from "react"

/**
 * This transition is executed before the first screen is shown.
 */
type StartOption = {
  /**
   * This transition is executed before the first screen is shown.
   */ type: "start"
}

/**
 * This transition is part of a menu with the given name.
 */
type MenuOption = {
  /**
   * This transition is part of a menu with the given name.
   */
  type: "menu"
  /** The label of the menu entry.
   * @example // Simple String
   * "Bearbeiten"
   * @example // Translated String
   * <Translate>{key.foo.bar}</Translate>
   */
  label: React.ReactNode
  /**
   * The name of the menu in wich this transition should be triggerable.
   *
   * To get all options for a given menu, use the `useMenuTranstionOptions(menuName)`-hook
   */
  menuName: string
  icon?: ComponentType<SVGProps<SVGSVGElement>>
}

/**
 * This transition is shown as an action button inisde the given blade.
 */
type BladeActionButtonOption = {
  /**
   * This transition is shown as an action button inisde the given blade.
   */
  type: "blade"
  /** The label of the button.
   * @example // Simple String
   * "Bearbeiten"
   * @example // Translated String
   * <Translate>{key.foo.bar}</Translate>
   */
  label: React.ReactNode
  /**
   * The name of the blade in which this button should be displayed.
   */
  blade: string // TODO: Typisieren nach Blade-Name im TargetScreen
  /** The position where to align the button inside the action bar (default: right) */
  align?: "right" | "left"
  /** Whether the button should be primary (CTA). */
  primary?: boolean
  icon?: ComponentType<SVGProps<SVGSVGElement>>
}

export type TransitionOptions = StartOption | BladeActionButtonOption | MenuOption

/** Typeguard to check if the given transition is of a specific type. */
export function isTransitionOptions<T extends TransitionOptions["type"]>(
  type: T,
  transition: TransitionOptions,
): transition is Extract<TransitionOptions, { type: T }> {
  return transition.type === type
}
