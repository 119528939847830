import { TaxonomyDisplayInfo } from "./types"

export function arraysAreEqual(a: TaxonomyDisplayInfo[], b: TaxonomyDisplayInfo[]): boolean {
  if (a.length != b.length) return false
  return a.every((itm, idx) => entriesAreEqual(itm, b[idx]))
}

export function entriesAreEqual(value1: TaxonomyDisplayInfo, value2: TaxonomyDisplayInfo) {
  return value1.label === value2.label && value1.value === value2.value && value1.closable === value2.closable
}
