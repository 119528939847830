import * as React from "react"
import { SVGProps } from "react"

const SvgSt4FragmentRegular = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1em" height="1em" {...props}>
    <path d="M245.7 186.4c7.7-2.6 15.4-2.6 20.6 0l97.7 36c12.9 5.1 20.6 18 20.6 30.9v123.5c0 12.9-7.7 25.7-20.6 30.9l-97.7 36c-7.7 2.6-15.4 2.6-20.6 0l-97.7-36c-12.9-7.7-20.6-20.6-20.6-33.4V250.7c0-12.9 7.7-25.7 20.6-30.9l97.7-33.4zm10.3 30.8-87.5 30.9L256 279l87.5-30.9-87.5-30.9zm15.4 185.2 79.7-28.3V279l-79.7 28.3v95.1zm157.9-293.2-90.6-90.5C326.7 6.7 310.5 0 293.5 0H128C92.7 0 64 28.7 64 64v384c0 35.3 28.7 64 64 64h256c35.3 0 64-28.7 64-64V154.5c0-17-6.7-33.3-18.7-45.3zM400 448c0 8.8-7.2 16-16 16H128c-8.8 0-16-7.2-16-16V64c0-8.8 7.2-16 16-16h160v80c0 17.7 14.3 32 32 32h80v288z" />
  </svg>
)

export default SvgSt4FragmentRegular
