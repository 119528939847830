export default {
	"button": {
		"general": {
			"reload": "Reload",
			"save": "Save",
			"saveandclose": "Save and close",
			"closemessage": "Close message",
			"showMore": "Show more",
			"showLess": "Show less",
			"saveAndLoginAgain": "Save and log in again",
			"ok": "OK",
			"cancel": "Cancel",
			"edit": "Edit",
			"openEditor": "Open in editor",
			"inlineEdit": "Make small changes",
			"next": "Next",
			"open": "Open",
			"back": "Back",
			"delete": "Delete",
			"exportData": "Export Data",
			"yes": "Yes",
			"no": "No",
			"select": "Select",
			"changeSelection": "Change selection",
			"close": "Close",
			"choose": "Choose"
		},
		"preview": {
			"structure": "Structure",
			"comment": {
				"delete": "Delete comment",
				"edit": "Edit comment",
				"reply": "Reply to comment",
				"allRepliesShow": "Unfold all replies",
				"allRepliesHide": "Collapse all replies",
				"add": "Add comment"
			}
		},
		"workflow": {
			"backToTask": "Back to task",
			"backToList": "Back to workflow task list",
			"executeAction": "Execute Action",
			"bringWindowToFront": "Bring window to front"
		},
		"auth": {
			"login": "Login",
			"loginAgain": "Log in again",
			"logout": "Logout"
		},
		"navigate": {
			"tree": {
				"toRoot": "Navigate to root level",
				"levelUp": "Navigate up",
				"levelDown": "Navigate down"
			}
		},
		"deleteNode": {
			"start": "Move to recycle bin"
		},
		"createVersion": "Create version",
		"blade": {
			"nextStep": "Next Step",
			"insert": "Insert",
			"edit": "Edit",
			"close": "Close"
		},
		"dashboard": {
			"myAssignedTasks": {
				"listItem": {
					"tooltip": {
						"destroy": "Delete Track"
					}
				}
			}
		},
		"search": {
			"blade": {
				"searchFacetsBlade": {
					"clearRefinements": "Clear facet filters"
				},
				"searchOptionsBlade": {
					"reset": "Reset search options"
				}
			}
		},
		"cut": "Cut",
		"copyTrees": "Copy",
		"paste": "Paste",
		"createContentLinks": "Create content links",
		"reuseTrees": "Reuse",
		"removeReuseLinks": "Remove reuse link",
		"ap": {
			"userOverview": {
				"addNewUser": "Create new user",
				"editProfile": "Edit user",
				"resetPassword": "Reset password",
				"changeMyPassword": "Change password",
				"setUserPresent": "Set user being present",
				"setUserAbsent": "Set user being absent",
				"setMePresent": "Set me being present",
				"setMeAbsent": "Set me being absent",
				"enableUser": "Enable user",
				"disableUser": "Disable user",
				"undeleteUser": "Undo user deletion",
				"deleteUser": "Delete user",
				"addUsersToGroups": "Add selected users to groups",
				"removeUsersFromGroups": "Remove selected Users from groups",
				"enableUsers": "Enable selected users",
				"disableUsers": "Disable selected users",
				"deleteUsers": "Delete selected users",
				"undeleteUsers": "Undo deletion of selected users"
			},
			"userDetails": {
				"create": "Create new user",
				"save": "Save changes",
				"cancel": "Cancel"
			},
			"changePassword": {
				"changePassword": "Change password",
				"cancel": "Cancel"
			},
			"changeGroupAssignments": {
				"addUsersToGroups": "Add users to groups",
				"removeUsersFromGroups": "Remove users from groups",
				"cancel": "Cancel"
			},
			"generatePassword": "Generate password",
			"typePassword": "Type password",
			"generateOtherPassword": "Generate another password",
			"groupOverview": {
				"addNewGroup": "Create new user group",
				"deleteGroup": "Delete user group",
				"undeleteGroup": "Undo user group deletion"
			},
			"groupForm": {
				"create": "Create user group",
				"cancel": "Cancel"
			},
			"licenseDetails": {
				"quotaOverview": {
					"contextMenu": {
						"changeQuota": "Change quota",
						"rejectQuota": "Reject quota"
					},
					"multiContextMenu": {
						"addQuotas": "Add quotas",
						"changeQuotas": "Change quotas",
						"rejectQuotas": "Reject quotas"
					}
				}
			}
		}
	},
	"label": {
		"general": {
			"loading": "loading!",
			"check": "Check",
			"id": "Id",
			"reuses": "Reuses",
			"title": "Title",
			"taxonomy": "Taxonomy",
			"noValue": "Empty",
			"yes": "yes",
			"no": "no",
			"aspect": "Aspect",
			"node_other": "Nodes",
			"settings": "Settings",
			"ui": "User Interface",
			"name": "Name",
			"status": "State",
			"sortBy": "Order by",
			"ascending": "Ascending",
			"descending": "Descending",
			"dates": "Dates",
			"people": "People",
			"details": "Details",
			"field_other": "Fields",
			"field": "Field",
			"field_one": "Field",
			"overview": "Overview",
			"history": "History",
			"type": "Type",
			"selectOrDropFile": "Choose a file or drag and drop it here.",
			"fileFormat_other": "File formats",
			"chooseFile": "Choose File",
			"ignore": "Ignore",
			"filter": "Filter",
			"scaling": "Scaling Type",
			"indexentry": {
				"_": "Index entry",
				"key": "Entry",
				"subEntryKey": "Subentry",
				"sort": "Sort key"
			},
			"node": "Node",
			"node_one": "Node",
			"systemName": "System name",
			"value": "Value",
			"creationTime": "Creation Time",
			"modificationTime": "Modification Time",
			"misc": "Misc",
			"pleaseSelect": "Please select",
			"search": "Search",
			"searchEmpty": "Search yielded no results."
		},
		"explore": {
			"header": "Free Mode"
		},
		"structure": {
			"header": "Structure",
			"source": {
				"title": "Source"
			},
			"target": {
				"title": "Target"
			}
		},
		"screens": {
			"information": "Information",
			"project": "Projects & Documents"
		},
		"projects": {
			"headline": "Projects"
		},
		"preview": {
			"informationpool": "Information Pool",
			"layout": "Layout",
			"imageDimensions": "Size",
			"imageLayoutType": "Layout type",
			"imageSlot": {
				"original": "Original graphic",
				"web": "Web format",
				"print": "Print format"
			},
			"exifCount": "{{count}} elements",
			"answerCount": "One reply",
			"answerCount_one": "One reply",
			"answerCount_other": "{{count}} replies",
			"pagebreak": "Page break",
			"emphasis": "Markup: {{displayName}}",
			"table": {
				"border_framed": "Border",
				"border_header": "Headline",
				"border_noframe": "Without border",
				"border_legend": "Legend table",
				"border": "Border",
				"scaling_fixed": "Scales the table across the width of the text area.",
				"scaling_scaled": "Scales the table in accordance with the widths of the table columns.",
				"scaling": "Table scaling"
			},
			"lexicon": {
				"tooltipHeadline": "Lexicon entry from \"{{lexiconName}}\"",
				"glossaryEntry": "Glossary entry",
				"subjectField": "Subject Field",
				"term": "Term",
				"validity": "State",
				"grammaticalGender": "Grammatical Gender"
			},
			"metaref": {
				"staticHeadline": "Reference to metadata <0>{{label}}</0>",
				"ontologyHeadline": "Reference to taxonomy <0>{{label}}</0>",
				"notFound": "Reference couldn't be resolved!",
				"languageHeadline": "Reference to language file",
				"dynamicReference": "Context-dependent reference to the metadata \"{{metadataName}}\""
			},
			"marginText": "Text in margin column"
		},
		"generic": {
			"unknown": "Unknown",
			"language": "Language",
			"contentLanguage": "Content language",
			"guiLanguage": "UI language",
			"enterText": "Enter text...",
			"addToSelection": "Add",
			"available": "Available",
			"removeFromSelection": "Remove",
			"selection": "Selection",
			"saved": "Saved"
		},
		"hyperlink": {
			"broken": "Broken link"
		},
		"operation": {
			"syncviews": "Synchronise Views"
		},
		"widget": {
			"reusetitle": "Reuses of \"{{nodeLabel}}\"",
			"lastChangeBy": "Last change by:",
			"createdBy": "Created by:"
		},
		"widgets": {
			"contentreuses": "Content reuses",
			"directreuses": "Direct Reuses",
			"indirectreuses": "Indirect Reuses",
			"reuses": "Reuses"
		},
		"warning": {
			"error": "Error",
			"warning": "Warning",
			"information": "Information",
			"question": "Question",
			"notice": "Notice"
		},
		"comment": {
			"placeholder": {
				"selection": "Comment on selection...",
				"reply": "Write reply..."
			},
			"typeSelection": "Select Type",
			"resizecommentsview": "Resize comments view",
			"comment": "Comment",
			"comment_one": "Comment",
			"stateSelection": "Select State",
			"comment_other": "Comments",
			"context": "Comment Context",
			"replyTo": "Reply to:",
			"selectedText": "Selected text: \"{{selection}}\""
		},
		"workflow": {
			"releaseDetails": "Release Details",
			"releaseType": "Type of release",
			"subtreeRelease": "Subtree release",
			"askForSignatureDialog": "Sign Release",
			"activity": "Activity",
			"initiator": "Initiator",
			"assignee": "Assignee",
			"assignee_one": "Assignee",
			"dueDate": "Due date",
			"lastChangedDate": "Changed on",
			"createDate": "Creation date",
			"startDate": "Start date",
			"linkedNodes": "Linked nodes",
			"modelName": "Workflow",
			"noNodeSelected": "No node selected",
			"assignee_other": "Assignees",
			"customColumDefinition_other": "Column definitions",
			"action_other": "Actions",
			"parent": "Parent workflow",
			"subWorkflow_other": "Subworkflows",
			"addFieldValue": "Add field value",
			"selectNode": "Select Node",
			"fieldValueOriginal": "Original value",
			"fieldValueUpdated": "Updated value",
			"urlOpened": "Link Opened",
			"confirmInput": "Confirm input:"
		},
		"releasestate": {
			"isImplicitlyReleased": "Indirectly released"
		},
		"auth": {
			"username": "Username",
			"password": "Password",
			"loggedOut": "Logged Out"
		},
		"nodeproperties": "Node Properties",
		"properties": {
			"_": "Metadata",
			"inherited": "inherited",
			"search": {
				"titles": "Search for metadata titles"
			}
		},
		"askRequiredMetadataForm": {
			"requiredMetadata": "Required Metadata"
		},
		"createNewNodeForm": {
			"RelativeInsertionPosition": "Position",
			"title": "Title",
			"configId": "Type",
			"loading": {
				"configId": "Type"
			},
			"error": {
				"configId": "Type"
			}
		},
		"relativeInsertionPosition": {
			"AFTER": "After the selected content",
			"BEFORE": "Before the selected content",
			"AS_CHILD": "As subordinate content"
		},
		"sort": {
			"default": "Categorised",
			"alphanumeric": "Alphabetical",
			"descendingalphanumeric": "Alphabetical descending"
		},
		"filter": {
			"all": "All metadata",
			"filled": "Filled metadata",
			"unfilled": "Unfilled metadata"
		},
		"tasks": {
			"activeTasks": "Active Tracks",
			"closeTask": "Delete Track"
		},
		"chooseProject": {
			"title": "Select project"
		},
		"dashboard": {
			"myAssignedTasks": {
				"listTitle": "My Tracks",
				"listItem": {
					"dueDate": "Due On: {{dueDate}}",
					"state": "Processing Status: {{state}}"
				}
			}
		},
		"contentBlade": {
			"title": "Content"
		},
		"content": {
			"advancedEditor": "Open in editor"
		},
		"search": {
			"taskDefinition": {
				"showSearchResult": {
					"displayName": "Search"
				}
			},
			"blade": {
				"searchFacetsBlade": {
					"title": "Facet filters"
				},
				"searchResultBlade": {
					"title": "Search results",
					"titleWithSearchTerm": "Search results for \"{{searchTerm}}\""
				},
				"searchOptionsBlade": {
					"title": "Search options"
				}
			},
			"component": {
				"quickSearchBox": "What are you looking for?",
				"searchFacetsList": {
					"facetLabels": {
						"nodeClass_facet": "Node Class",
						"node_md_facet": "Last modified by",
						"node_mdd_facet": "Last modified on"
					},
					"facetItemLabels": {
						"other": "Other",
						"me": "Me",
						"today": "Today",
						"yesterday": "Yesterday",
						"lastWeek": "Last 7 days",
						"from": "From",
						"until": "Until"
					}
				},
				"searchOptionsSwitch": "Search options",
				"searchOptionsForm": {
					"searchKeys": "Restrict data to search in",
					"searchKey": {
						"imgContent": "Image Content",
						"filetype": "File Type"
					},
					"searchVersions": "Restrict versions to search in",
					"searchVersion": {
						"latest": "Latest version",
						"approved": "Last released version",
						"latestApproved": "Latest und last released version",
						"all": "All versions"
					},
					"searchLanguages": "Restrict languages to search in",
					"select": {
						"other": "Other"
					}
				}
			}
		},
		"help": {
			"flyoutTitle": "Info",
			"link": {
				"help": {
					"title": "Help"
				},
				"privacy": {
					"title": "Privacy"
				}
			}
		},
		"account": {
			"flyoutTitle": "Account: {{userName}}"
		},
		"resourcePoolBlade": {
			"title": "Reference resource"
		},
		"resourceTree": {
			"graphicOptions": "Graphic settings",
			"graphicType": "Choose graphic type",
			"insertLegendTable": "Add legend",
			"graphicLabel": "Graphic label"
		},
		"imagetype": {
			"img_50": "Fixed width, narrow",
			"img_80": "Fixed width, wide",
			"img_fixed_column": "Graphic in text column",
			"img_fixed_textarea": "Graphic in type area",
			"img_margin": "Graphic in margin column",
			"img_original": "Use original width",
			"img_free": "Graphic within paragraph"
		},
		"crossReferencePoolBlade": {
			"title": "Insert cross reference"
		},
		"fragmentPoolBlade": {
			"title": "Reference fragment",
			"listType": "Choose list type"
		},
		"listtype": {
			"ordered": "Numbered list",
			"unordered": "Bulleted list",
			"secondLevel": "Second-level list"
		},
		"safetyPoolBlade": {
			"title": "Insert safety message",
			"titleEdit": "Edit safety message"
		},
		"safety": {
			"type": "Type of hazard",
			"severity": "Level of hazard",
			"symbols": "Symbols"
		},
		"variablePoolBlade": {
			"title": "Insert variable",
			"referenceType": "Reference to:"
		},
		"variable": {
			"referenceType": {
				"cell": "Cell",
				"variable": "Variable",
				"nameOfValueSet": "Name of value set",
				"variableName": "Variable name"
			}
		},
		"metaRefPoolBlade": {
			"title": "Reference Metadata",
			"metaRef": "Metadata:"
		},
		"ap": {
			"task": {
				"userManagement": {
					"displayName": "Manage Users"
				},
				"licenseManagement": {
					"displayName": "Manage Licenses"
				},
				"productionManagement": {
					"displayName": "Manage Productions"
				}
			},
			"blade": {
				"userOverview": {
					"displayName": "User Overview"
				},
				"userDetails": {
					"displayName": "User"
				},
				"changePassword": {
					"displayName": "Change password"
				},
				"changeGroupAssignments": {
					"displayName": "Change group assignments"
				},
				"userClaimsOverview": {
					"displayName": "User claims"
				},
				"groupOverview": {
					"displayName": "Group Overview"
				},
				"createGroup": {
					"displayName": "Create new user group"
				},
				"licenseOverview": {
					"displayName": "License Overview"
				},
				"licenseDetails": {
					"displayName": "License Details"
				}
			},
			"userOverview": {
				"withDeleted": "Show deleted users",
				"searchForUser": "Search by name of email address",
				"filterByGroupMembership": "Filter by group membership",
				"deselectAllUsers": "Clear user selection",
				"selectAllUsers": "Select all users",
				"selectedUserCount": "{{selected}} of {{all}} users selected",
				"userCount": "{{count}} users",
				"userType": {
					"db": "Database user",
					"ad": "Active Directory user"
				},
				"userIsAbsent": "Absent",
				"userIsDisabled": "Disabled",
				"userIsDeleted": "Deleted"
			},
			"userDetails": {
				"editUser": "Edit user",
				"showClaims": "Show user claims",
				"field": {
					"userName": "User name",
					"initialPassword": "Initial password",
					"displayName": "Display name",
					"email": "Email address",
					"description": "Description",
					"displayLanguage": "Display language",
					"groups": "Group memberships",
					"deputies": "Deputies",
					"isAbsent": "Absent",
					"disabled": "Disabled",
					"isDeleted": "Deleted",
					"created": "Creation date",
					"modified": "Change date"
				}
			},
			"changePassword": {
				"field": {
					"generatedPassword": "Generated password",
					"newPassword": "New password",
					"newPasswordRetyped": "Retype new password"
				}
			},
			"changeGroupAssignments": {
				"field": {
					"groups": {
						"add": "New group assignments",
						"remove": "Group assignments to remove"
					}
				}
			},
			"userClaims": {
				"field": {
					"type": "Name",
					"value": "Value"
				}
			},
			"passwordPolicy": {
				"title": "Password Policy",
				"noPasswordPolicy": "Currently there is no password policy defined",
				"minLength": "Minimum length of the password: {{length}} characters",
				"minUpperCaseLetters": "Minumum number of uppercase letters: {{number}}",
				"minLowerCaseLetters": "Minumum number of lowercase letters: {{number}}",
				"minDigits": "Minumum number of digits: {{number}}",
				"minSpecialCharacters": "Minumum number of special characters: {{number}}"
			},
			"groupOverview": {
				"withDeleted": "Show deleted groups",
				"searchForGroup": "Search by name",
				"groupIsDeleted": "Deleted"
			},
			"groupForm": {
				"field": {
					"groupName": "Group name"
				}
			},
			"licenseOverview": {
				"userLicensesOnly": "Show user licenses only"
			},
			"licenseDetails": {
				"licenseInformation": {
					"detailsAbout": "Details about",
					"available": "Available licenses",
					"usage": "Currently used licenses"
				},
				"quotas": "License Quotas",
				"quotaOverview": {
					"searchForQuotas": "Search by name",
					"quotaCount": "{{count}} quotas",
					"selectAllQuotas": "Select all quotas",
					"deselectAllQuotas": "Clear quota selection",
					"selectedQuotasCount": "{{selected}} of {{all}} quotas selected",
					"quotasAssigned": "Assigned licenses",
					"quotasUsed": "Used licenses"
				}
			},
			"licenseType": {
				"floating": "Concurrent License",
				"namedUser": "Named User License"
			},
			"lm": {
				"blade": {
					"addQuotasForm": {
						"displayName": "Add license quotas"
					},
					"changeQuotasForm": {
						"displayName": "Change license quota"
					}
				},
				"formHeader": {
					"license": "License",
					"type": "Type",
					"available": "Available",
					"assigned": "Assigned",
					"free": "Free"
				},
				"quotaNumbers": {
					"min": "Minimum number of assigned licenses",
					"max": "Maximum number of assigned licenses",
					"exact": "Number of assigned licenses"
				},
				"quotaAssignees": {
					"searchByName": "Search by name",
					"searchByNameOrGroup": "Search by name or group membership",
					"count": {
						"floating": "{{count}} users/groups",
						"namedUsers": "{{count}} users"
					},
					"deselectAllAssignees": "Clear selection",
					"selectAllAssignees": "Select all",
					"selectedCount": "{{selected}} of {{all}} selected"
				}
			}
		},
		"meatball": {
			"tooltip": "More actions"
		}
	},
	"message": {
		"error": {
			"generic": "An error has occurred!",
			"noLink": "Could not find link with label {{linkId}}.",
			"nodeRendering": "An error occurred while rendering the node!",
			"loadingindirectreuses": "An error occurred while loading the indirect reuses.",
			"removeComment": "An error occurred while trying to delete the comment.",
			"addComment": "Error saving the comment.",
			"changeCommentState": "Error while changing the state.",
			"workflow": {
				"commandNotImplemented": "Action {{action}} cannot be performed in this application."
			},
			"auth": {
				"wrongPassword": "Wrong user name or password",
				"generic": "An error occurred during logon. Please contact your administrator.",
				"licenseLimit": "License limit reached",
				"allNamedLicenseAllocated": "It appears you are already logged into another browser window or device. \r\n    Do you want to continue logging in and end the other session?"
			},
			"noPreview": "No preview available",
			"commentLegacy": {
				"errorRights": "Failed to create comment. You have no permissions to change data.",
				"errorInlineNotCommentable": "Element-overlapping selections are not commentable.",
				"errorInlineOverlap": "The selected text overlaps with an existing inline comment!"
			},
			"inputNotInt": "Input is not a valid integer.",
			"inputNotDouble": "Input is not a valid double.",
			"inputNotDecimal": "Input is not a valid decimal.",
			"permissionDenied": "Your rights are not sufficient for this action.",
			"nodeLocked": "This object is already being edited by \"{{lockedBy}}\". Please try again later.",
			"editorSave": {
				"editorSessionExpired": "The current editor session is expired. You may have opened the page in another tab. Refresh the page via F5.",
				"general": "The content could not be saved. Try again or refresh the page via F5."
			},
			"ontologyLinksChanged": "A metadatum of the content has meanwhile been changed. Reload the page and try again."
		},
		"alert": {
			"noContent": "No content to show!"
		},
		"warning": {
			"guiLanguage": "Changes to the UI language apply after a new login.",
			"editorSave": {
				"frozenLinkExists": {
					"default": "A fixed version of the node{{1}} with ID {{0}} is already referenced. Therefore, you cannot create a reference to the current version. Instead, SCHEMA ST4 references the fixed version again.",
					"graphic": "A fixed version of the graphic{{1}} with ID {{0}} is already referenced. Therefore, you cannot create a reference to the current version. Instead, SCHEMA ST4 references the fixed version again.",
					"graphicGroup": "A fixed version of the graphic group{{1}} with ID {{0}} is already referenced. Therefore, you cannot create a reference to the current version. Instead, SCHEMA ST4 references the fixed version again.",
					"video": "A fixed version of the video{{1}} with ID {{0}} is already referenced. Therefore, you cannot create a reference to the current version. Instead, SCHEMA ST4 references the fixed version again.",
					"callout": "A fixed version of the callout graphic{{1}} with ID {{0}} is already referenced. Therefore, you cannot create a reference to the current version. Instead, SCHEMA ST4 references the fixed version again.",
					"variable": "A fixed version of the variable{{1}} with ID {{0}} is already referenced. Therefore, you cannot create a reference to the current version. Instead, SCHEMA ST4 references the fixed version again.",
					"fragment": "A fixed version of the fragment{{1}} with ID {{0}} is already referenced. Therefore, you cannot create a reference to the current version. Instead, SCHEMA ST4 references the fixed version again.",
					"fragmentGroup": "A fixed version of the fragment group{{1}} with ID {{0}} is already referenced. Therefore, you cannot create a reference to the current version. Instead, SCHEMA ST4 references the fixed version again.",
					"metaref": "A fixed version of the data node{{1}} with ID {{0}} is already referenced. Therefore, you cannot create a reference to the current version. Instead, SCHEMA ST4 references the fixed version again."
				},
				"floatingLinkExists": {
					"default": "A dynamic reference to the current version of the node{{1}} with ID {{0}} already exists. Therefore, you cannot create a fixed reference to the selected version of the node. Instead, SCHEMA ST4 references the current version of the node again.",
					"graphic": "A dynamic reference to the current version of the graphic{{1}} with ID {{0}} already exists. Therefore, you cannot create a fixed reference to the selected version of the graphic. Instead, SCHEMA ST4 references the current version of the graphic again.",
					"graphicGroup": "A dynamic reference to the current version of the graphic group{{1}} with ID {{0}} already exists. Therefore, you cannot create a fixed reference to the selected version of the graphic group. Instead, SCHEMA ST4 references the current version of the graphic group again.",
					"video": "A dynamic reference to the current version of the video{{1}} with ID {{0}} already exists. Therefore, you cannot create a fixed reference to the selected version of the video. Instead, SCHEMA ST4 references the current version of the video again.",
					"callout": "A dynamic reference to the current version of the callout graphic{{1}} with ID {{0}} already exists. Therefore, you cannot create a fixed reference to the selected version of the callout graphic. Instead, SCHEMA ST4 references the current version of the callout graphic again.",
					"variable": "A dynamic reference to the current version of the variable{{1}} with ID {{0}} already exists. Therefore, you cannot create a fixed reference to the selected version of the variable. Instead, SCHEMA ST4 references the current version of the variable again.",
					"fragment": "A dynamic reference to the current version of the fragment{{1}} with ID {{0}} already exists. Therefore, you cannot create a fixed reference to the selected version of the fragment. Instead, SCHEMA ST4 references the current version of the fragment again.",
					"fragmentGroup": "A dynamic reference to the current version of the fragment group{{1}} with ID {{0}} already exists. Therefore, you cannot create a fixed reference to the selected version of the fragment group. Instead, SCHEMA ST4 references the current version of the fragment group again.",
					"metaref": "A dynamic reference to the current version of the data node{{1}} with ID {{0}} already exists. Therefore, you cannot create a fixed reference to the selected version of the data node. Instead, SCHEMA ST4 references the current version of the data node again."
				},
				"frozenAndFloatingLink": {
					"default": "You are trying to create both a fixed and a dynamic reference to node{{1}} with ID {{0}}. This is not possible. Instead, a reference to a fixed version is created.",
					"graphic": "You are trying to create both a fixed and a dynamic reference to graphic{{1}} with ID {{0}}. This is not possible. Instead, a reference to a fixed version is created.",
					"graphicGroup": "You are trying to create both a fixed and a dynamic reference to graphic group{{1}} with ID {{0}}. This is not possible. Instead, a reference to a fixed version is created.",
					"video": "You are trying to create both a fixed and a dynamic reference to video{{1}} with ID {{0}}. This is not possible. Instead, a reference to a fixed version is created.",
					"callout": "You are trying to create both a fixed and a dynamic reference to callout graphic{{1}} with ID {{0}}. This is not possible. Instead, a reference to a fixed version is created.",
					"variable": "You are trying to create both a fixed and a dynamic reference to variable{{1}} with ID {{0}}. This is not possible. Instead, a reference to a fixed version is created.",
					"fragment": "You are trying to create both a fixed and a dynamic reference to fragment{{1}} with ID {{0}}. This is not possible. Instead, a reference to a fixed version is created.",
					"fragmentGroup": "You are trying to create both a fixed and a dynamic reference to fragment group{{1}} with ID {{0}}. This is not possible. Instead, a reference to a fixed version is created.",
					"metaref": "You are trying to create both a fixed and a dynamic reference to data node{{1}} with ID {{0}}. This is not possible. Instead, a reference to a fixed version is created."
				},
				"linkForResourceCouldNotBeCreated": "The link to the resource with ID {{0}} could not be resolved. Therefore, the reference will be deleted when you close the content.",
				"linkForVariableCouldNotBeCreated": "The link to the variable with ID {{0}} could not be resolved. Therefore, the reference will be deleted when you close the content.",
				"linkForMetarefCouldNotBeCreated": "The link to the data node with ID {{0}} could not be resolved. Therefore, the reference will be deleted when you close the content.",
				"linkForTextModuleCouldNotBeCreated": "The reference to the fragment with ID {{0}} could not be resolved. Therefore, the reuse will be removed when you close the content.",
				"invalidLinkRemovalForTextModuleCausedInvalidXML": "The reference to the fragment with ID {{0}} could not be resolved. The reuse could not be automatically removed. \r\n    Please remove the fragment before closing the content."
			}
		},
		"general": {
			"loadingindirectreuses": "Loading indirect Reuses...",
			"andMore_neutral": "and {{count}} more",
			"andMore_neutral_one": "and {{count}} more",
			"andMore_neutral_other": "and {{count}} more",
			"andMore": "and more"
		},
		"info": {
			"olddata": "The shown values may be outdated.",
			"changeLanguageRequiresLogin": "Changing the interface language requires you to log in again."
		},
		"confirm": {
			"delete": "Really delete?",
			"cancel": "Do you really want to cancel?"
		},
		"preview": {
			"comments": {
				"emptyCollection": "No comments available.",
				"confirmInputAbort": "Do you want to cancel the entry of the comment?\r\nBy clicking \"Yes\" your comment will be lost."
			},
			"previewLimit": "The maximum amount of nodes was reached",
			"emphasisNotFound": "Markup \"{{type}}\" is not defined.",
			"metaref": {
				"ontologyNotSelected": "The node  {{node}} has no taxonomy of type {{taxonomyName}} assigned.",
				"dynamicReferenceNotFound": "Context-dependent reference to the metadata \"{{metadataName}}\" was not found on node {{node}}"
			},
			"resourceNode": {
				"noContent": "Missing content"
			}
		},
		"validation": {
			"dateFormat": "Invalid date format. Please use format YYYY-MM-DD.",
			"numberDouble": "{{value}} is not a valid double value. Please use a decimal point.",
			"emptyRow": "No empty rows allowed!",
			"required": "Mandatory field",
			"xml": "Invalid XML",
			"minCount": "Minimum count: {0}, current count: {1}",
			"maxCount": "Maximum count: {0}, current count: {1}",
			"minLength": "Minimum length: {0}, current length: {1}",
			"maxLength": "Maximum length: {0}, current length: {1}",
			"minDate": "Earliest date: {0} (UTC)",
			"maxDate": "Latest date: {0} (UTC)",
			"minValue": "Lowest value: {0}",
			"maxValue": "Highest value: {0}",
			"format": "Wrong input format",
			"filesizeToLarge": "This file is too big. Maximum: {0} MB",
			"fileType": "This file type is not allowed. Allowed: {0}",
			"auth": {
				"missingUsername": "Please input your username",
				"missingPassword": "Please input your password"
			},
			"passwordConfirmed": "Confirmation doesn't match the password!"
		},
		"workflow": {
			"askForSignature": "Enter username and password to sign the release",
			"instanceChangedNow": "The task has just been processed by {{lastModifiedUser}}. Your changes will be lost.",
			"instanceChangedOrInvisible": "The state of the task has changed and it is no longer visible.",
			"noTasks": "No open tasks",
			"invalidField_other": "Cannot continue due to invalid field values",
			"changedField": "Field changed: '{{fieldName}}'",
			"changedField_one": "Field changed: '{{fieldName}}'",
			"changedField_other": "Fields changed [{{count}}]",
			"changeGeneric": "Workflow Changes",
			"dataOutdated": "This workflow task has changed in the meantime. The transition could not be executed.",
			"transactionCancel": "Do you really want to cancel the transition? Form data you entered will not be saved.",
			"historyModifiedBy": "{{user}} made changes to the workflow.",
			"urlOpenedInWindow": "The Link to \"{{url}}\" was opened in a new window."
		},
		"auth": {
			"loggedOut": "You've logged off."
		},
		"properties": {
			"valueInherited": "Value inherited from parent node."
		},
		"createNewNodeForm": {
			"configId": {
				"error": "No selection available!"
			}
		},
		"createNewNodeVisualizer": {
			"error": {
				"commandNotImplemented": "Not implemented"
			},
			"question": {
				"switchContext": "If you change the target location for the new content, your previous entries will be lost.\r\n    Do you still want to switch?"
			}
		},
		"tasks": {
			"confirmClose": "Do you really want to delete this track?"
		},
		"chooseProject": {
			"projectFolderSelected": "Please select a project, not a folder."
		},
		"dashboard": {
			"welcome": {
				"title": "Welcome back"
			},
			"myAssignedTasks": {
				"error": {
					"loading": "Error loading your assigned tracks."
				},
				"info": {
					"noTasks": "Currently you don't have any active tracks."
				}
			}
		},
		"editor": {
			"canEditCheck": {
				"error": {
					"InternalError": "Sorry, that didn't work. Try again later.",
					"PermissionDenied": "You can't edit this content. You don't have the required permissions.",
					"NodeLocked": "This content is currently being edited by \"{{0}}\". Try again later.",
					"NodeReused": "The content is reused here. Therefore, you can't edit the content directly. \r\nSwitch to the SCHEMA ST4 Power or Author Client to edit this content.",
					"ContentLinked": "The content is reused here. Therefore, you can't edit the content directly. \r\nSwitch to the SCHEMA ST4 Power or Author Client to edit this content.",
					"AspectNotEditingLanguage": "You can't edit the content in the current language. The content has been created in a different language. \r\nSwitch to the SCHEMA ST4 Power or Author Client to edit the content without translation workflow.",
					"NodeReleasedCurrentAspect": "This content is released in the current language. \r\nSwitch to the SCHEMA ST4 Power or Author Client to create a new version. You can then edit this content.",
					"NodeReleasedOtherAspect": "This content is released in another language. \r\nSwitch to the SCHEMA ST4 Power or Author Client to create a new version. You can then edit this content.",
					"NodeDeleted": "You have tried to open a content. However, this content has been permanently deleted.\r\nClose the content and, if necessary, the editor."
				},
				"warning": {
					"NodeInTranslation": "This content is currently being translated. \r\nIf you change the content, you need to have it translated again."
				}
			}
		},
		"search": {
			"info": {
				"searchingForTerm": "Searching in ST4 for \"{{searchTerm}}\" ..."
			},
			"warning": {
				"noSearchTerm": "Please enter a search term first!",
				"noSearchResult": "Searching in ST4 for \"{{searchTerm}}\" didn't hit any items!",
				"noSearchFacets": "Searching in ST4 for \"{{searchTerm}}\" didn't hit any filter facets!"
			},
			"error": {
				"fromException": "Error while searching in ST4: \"{{errorName}}\" - \"{{errorMessage}}\"",
				"corruptResponse": "Error while searching in ST4: \"No data\" - \"Received neither error nor data from GraphQL API!\""
			}
		},
		"createNewNode": {
			"class": {
				"docuManagerOnly": "Content of this type can only be created in ST4 DocuManager",
				"permissionDenied": "You are not allowed to create content of this type here"
			}
		},
		"resourcePoolBlade": {
			"folder": "Select a resource, not a folder.",
			"not_possible": "You can't insert a graphic at this point. \r\nInstead, place the cursor inside a paragraph.",
			"rights": "You do not have the rights to insert the selected resource.",
			"thumbnail": "There is no thumbnail preview for the selected resource.",
			"noslot": "There is no graphic in the selected resource."
		},
		"crossReferencePoolBlade": {
			"not_possible_cross_reference": "You can't insert the selected cross reference at this point."
		},
		"fragmentPoolBlade": {
			"no_fragment": "Select a fragment.",
			"rights": "You do not have the rights to insert the selected fragment.",
			"recursion": "Inserting this fragment would create an infinite loop.",
			"empty": "The fragment does not have any content.",
			"empty_group": "You have selected a group node.\r\nSince no content is associated with the group node, you can't insert it.",
			"not_possible_structure_fragment": "You can't insert the selected structure fragment at this point. \r\nThe content's structure and the type of the structure fragment don't fit together.",
			"not_possible_fragment": "You can't insert the selected fragment at this point. \r\nThe content's structure and the fragment don't fit together."
		},
		"safetyPool": {
			"not_possible": "You can't insert a safety message at this point. \r\nThe content's structure and the safety message don't fit together."
		},
		"variablePoolBlade": {
			"not_possible": "You can't insert a variable at this point. \r\nThe content's structure and the variable don't fit together."
		},
		"metaRefPoolBlade": {
			"not_possible": "You can't insert a metadata at this point. \r\nThe content's structure and the metadata don't fit together."
		},
		"ap": {
			"error": {
				"userDetails": {
					"noUserGiven": "No user given"
				},
				"userOverview": {
					"noEnabledUsersSelected": "None of the selected users is enabled!",
					"noDisabledUsersSelected": "None of the selected users is disabled!"
				},
				"userClaims": {
					"noPermissions": "You don't have sufficient rights to view user claims!"
				},
				"licenseOverview": {
					"noData": "Received neither error nor data from web interface! Please try again or contact your administrator!"
				},
				"licenseDetails": {
					"noData": "Received neither error nor data from web interface! Please try again or contact your administrator!",
					"noSelectedLicense": "No license has been selected!"
				}
			},
			"validationError": {
				"userNameRequired": "Field \"User name\" is required",
				"displayNameRequired": "Field \"Display name\" is required",
				"invalidEmailFormat": "Field \"email address\" doesn't follow the valid format",
				"emptyPassword": "Empty passwords are not allowed",
				"passwordsNotIdentical": "The given passwords are not identical",
				"passwordInvalid": "The given password doesn't match the password policy",
				"NoGroupSelected": "No group selected",
				"GroupNameRequired": "Field \"Group name\" is required"
			},
			"success": {
				"passwordChanged": "Password has been changed successfully",
				"setAbsent": "Absence saved successfully",
				"setPresent": "Presence saved successfully",
				"singleUserEnabled": "User enabled successfully",
				"singleUserDisabled": "User disabled successfully",
				"singleUserDeleted": "User deleted successfully",
				"singleUserUndeleted": "User deletion undone successfully",
				"userCreated": "New user created successfully",
				"userUpdated": "Changes to the user saved successfully",
				"usersAddedToGroups": "Users added to groups successfully",
				"usersRemovedFromGroups": "Users removed from groups successfully",
				"multiUsersEnabled": "Users enabled successfully",
				"multiUsersDisabled": "Users disabled successfully",
				"multiUsersDeleted": "Users deleted successfully",
				"multiUsersUndeleted": "User deletions undone successfully",
				"groupCreated": "User group created successfully",
				"groupDeleted": "User group deleted successfully",
				"groupUndeleted": "Deletion undone successfully",
				"licenseDetails": {
					"quotasAdded": "Quotas have been added successfully",
					"quotaChanged": "Quota have been changed successfully",
					"quotasChanged": "Quotas have been changed successfully",
					"quotaRejected": "Quota has been rejected successfully",
					"quotasRejected": "Quotas have been rejected successfully"
				}
			},
			"hasUnsavedChanges": {
				"title": "Unsaved changes",
				"body": "Your latest changes aren't saved and will get lost.",
				"question": "Are you sure that you want to cancel editing the user profile?"
			},
			"info": {
				"userClaims": {
					"noUserClaimsFound": "Couldn't find any claims of user {{userName}}!"
				},
				"generatedPassword": "Generated password: {{password}}",
				"licenseDetails": {
					"noQuotas": "Currently there are no quotas assigned to any user or group!"
				}
			},
			"warning": {
				"licenseDetails": {
					"noSupportedLicenseTypes": "{{name}} is neither a concurrent license nor a named user license. There is no detail information available!"
				}
			},
			"lm": {
				"warning": {
					"addQuotasForm": {
						"noSupportedLicenseTypes": "{{name}} is neither a concurrent license nor a named user license. Cannot add any license quotas!"
					},
					"changeQuotasForm": {
						"noSupportedLicenseTypes": "{{name}} is neither a concurrent license nor a named user license. Cannot update any license quota!"
					}
				},
				"error": {
					"addQuotasFormBlade": {
						"noData": "Received neither error nor data from web interface! Please try again or contact your administrator!",
						"noLicense": "No license given where quotas can be added"
					},
					"changeQuotasFormBlade": {
						"noData": "Received neither error nor data from web interface! Please try again or contact your administrator!",
						"noLicense": "No license given where quotas can be updated",
						"noQuotas": "No quota given to update"
					}
				},
				"validationError": {
					"NotEnoughFreeLicenses": "You are trying to assign {{actual}} licenses but there are only {{max}} free licenses left!"
				}
			}
		},
		"tree": {
			"dnd": {
				"tryAgain": "Sorry, something went wrong, please try again."
			}
		}
	},
	"description": {
		"image": {
			"width": {
				"50": "Fixed width, narrow",
				"80": "Fixed width, wide",
				"original": "Use original width",
				"fixedColumn": "Graphic in text column",
				"fixedTextarea": "Graphic in type area",
				"margin": "Graphic in margin column"
			}
		},
		"hyperlink": {
			"opensInNewWindow": "Opens the link in a new tab"
		},
		"workflow": {
			"historyTab": "This workflow task's change history",
			"overviewTab": "Overview of this workflow task's current field values",
			"action": "Actions that can be performed on this workflow task"
		},
		"preview_legacy": {
			"selectedTextConfirm": "Comment selected text"
		},
		"createNewNode": {
			"start": "Create Content..."
		},
		"createNewNodeForm": {
			"formTitle": "Create Content",
			"configId": {
				"error": "No type selection could be loaded for the current data!"
			},
			"permisson": {
				"error": "You don't have the permission to create new content here."
			}
		},
		"createNewNodeVisualizer": {
			"error": {
				"commandNotImplemented": "There is no ui component implemented for the command \"{{commandName}}\"!"
			},
			"inProgress": {
				"createNode": "Content creation in progress ..."
			}
		},
		"search": {
			"component": {
				"searchResultPagination": {
					"totalText": "{{rangeStart}}-{{rangeEnd}} of about {{totalCount}} hits"
				}
			}
		},
		"status": {
			"pageNotFound": "The requested page wasn't found (404)."
		},
		"task": {
			"loading": "Loading track.",
			"notFound": "The track doesn't exist.",
			"deleted": "The track was deleted."
		}
	}
}