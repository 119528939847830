import * as React from "react"
import { SVGProps } from "react"

const SvgSitemap = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width="1em" height="1em" {...props}>
    <path d="M320 80v64h-64V80h64zm-64-48c-26.5 0-48 21.5-48 48v64c0 26.5 21.5 48 48 48h8v40H112c-30.9 0-56 25.1-56 56v32h-8c-26.5 0-48 21.5-48 48v64c0 26.5 21.5 48 48 48h64c26.5 0 48-21.5 48-48v-64c0-26.5-21.5-48-48-48h-8v-32c0-4.4 3.6-8 8-8h152v40h-8c-26.5 0-48 21.5-48 48v64c0 26.5 21.5 48 48 48h64c26.5 0 48-21.5 48-48v-64c0-26.5-21.5-48-48-48h-8v-40h152c4.4 0 8 3.6 8 8v32h-8c-26.5 0-48 21.5-48 48v64c0 26.5 21.5 48 48 48h64c26.5 0 48-21.5 48-48v-64c0-26.5-21.5-48-48-48h-8v-32c0-30.9-25.1-56-56-56H312v-40h8c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48h-64zM48 368h64v64H48v-64zm208 0h64v64h-64v-64zm208 0h64v64h-64v-64z" />
  </svg>
)

export default SvgSitemap
