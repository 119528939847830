import * as React from "react"
import { SVGProps } from "react"

const SvgSt4InsertimagelegendLight = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1em" height="1em" {...props}>
    <path d="M206.8 369.9v-68.4h121v68.4h-121zm0 31.6h121v78.9H217.3c-5.8 0-10.5-4.7-10.5-10.5v-68.4zm152.6 78.9v-78.9h121v68.4c0 5.8-4.7 10.5-10.5 10.5H359.4zm121-110.5h-121v-68.4h121v68.4zM217.3 217.3c-23.2 0-42.1 18.9-42.1 42.1v210.5c0 23.2 18.9 42.1 42.1 42.1h252.6c23.2 0 42.1-18.9 42.1-42.1V259.4c0-23.2-18.9-42.1-42.1-42.1H217.3zm-74.1 256.6v-63.8L105.4 448H64c-17.7 0-32-14.3-32-32v-41.4l90.4-90.3c3.1-3.1 8.2-3.1 11.3 0l9.5 9.5V253c-14.5-6-31.8-3.1-43.5 8.7L32 329.4V96c0-17.7 14.3-32 32-32h384c17.7 0 32 14.3 32 32v89.3h26.2c2 0 3.9.1 5.8.4V96c0-35.3-28.7-64-64-64H64C28.7 32 0 60.7 0 96v320c0 35.3 28.7 64 64 64h79.7c-.3-2-.5-4-.5-6.1zM144 96c-26.5 0-48 21.5-48 48s21.5 48 48 48 48-21.5 48-48-21.5-48-48-48zm0 64c-8.8 0-16-7.2-16-16s7.2-16 16-16 16 7.2 16 16-7.2 16-16 16z" />
  </svg>
)

export default SvgSt4InsertimagelegendLight
