import React, { createContext, useContext, useEffect } from "react"
import type { TextSelection } from "./types"
import type { Node } from "unist"
import { ProgressState } from "./types"
import { useSelectionReducer } from "./reducer"
import { ST4NodeWithContent, ST4NodeWithContentName } from "../graphql/types"

type SelectionContextState = {
  currentTextSelection: TextSelection
  dispatchSelectionStart: (
    node: Node,
    contentNode: ST4NodeWithContent,
    marker: React.RefObject<HTMLElement>,
    selectionValid: boolean,
    offset: number,
  ) => void
  dispatchSelectionChange: (
    node: Node,
    contentNode: ST4NodeWithContent,
    marker: React.RefObject<HTMLElement>,
    selectionValid: boolean,
    offset: number,
  ) => void
  dispatchSelectionEnd: () => void
  dispatchUnselect: () => void
  onHover: (node: Node, contentNode: ST4NodeWithContent) => void
}

const defaultValue: SelectionContextState = {
  currentTextSelection: { progress: ProgressState.NoSelection },
  dispatchSelectionStart: () => {},
  dispatchSelectionChange: () => {},
  dispatchSelectionEnd: () => {},
  dispatchUnselect: () => {},
  onHover: () => {},
}

const SelectionContext = createContext<SelectionContextState>(defaultValue)

type SelectionContextProps = {
  onHover?: (node: Node, contentNode: ST4NodeWithContent) => void
  children: any
}

// used-in: ContentProvider

export function SelectionContextProvider({ children, onHover }: React.PropsWithChildren<SelectionContextProps>) {
  const [currentTextSelection, start, end, change, unselect] = useSelectionReducer()

  return (
    <SelectionContext.Provider
      value={{
        currentTextSelection,
        dispatchSelectionChange: change,
        dispatchSelectionStart: start,
        dispatchSelectionEnd: end,
        dispatchUnselect: unselect,
        onHover: onHover ? onHover : () => {},
      }}
    >
      {children}
    </SelectionContext.Provider>
  )
}

export function useSelectionContext() {
  return useContext(SelectionContext)
}
