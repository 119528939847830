import * as Types from "@st4/graphql"

import { gql } from "@apollo/client"
import * as Apollo from "@apollo/client"
const defaultOptions = {} as const
export type VectorGraphicsSvgQueryVariables = Types.Exact<{
  resourceId: Types.Scalars["ID"]
  aspectSelectorId: Types.Scalars["AspectSelectorId"]
  filterId?: Types.InputMaybe<Types.Scalars["StObjectId"]>
}>

export type VectorGraphicsSvgQuery = {
  __typename: "Query"
  node?:
    | { __typename: "CommentNode" }
    | { __typename: "ExternalSite" }
    | { __typename: "ST4Class" }
    | {
        __typename: "ST4Node"
        content?:
          | { __typename: "LexiconContent" }
          | { __typename: "LexiconEntryContent" }
          | {
              __typename: "MediaContent"
              media?:
                | { __typename: "AnimationMediaInfo" }
                | { __typename: "ImageMediaInfo" }
                | { __typename: "NoMediaInfo" }
                | { __typename: "VectorGraphicsMediaInfo"; resourceId?: string | null; svg?: string | null }
                | { __typename: "VideoMediaInfo" }
                | null
            }
          | { __typename: "MediaGroupContent" }
          | { __typename: "ProjectContent" }
          | { __typename: "TermContent" }
          | { __typename: "TextContent" }
          | { __typename: "TextGroupContent" }
          | { __typename: "VariableTableContent" }
          | null
      }
    | { __typename: "ST4TreeNode" }
    | null
}

export const VectorGraphicsSvgDocument = gql`
  query VectorGraphicsSvg($resourceId: ID!, $aspectSelectorId: AspectSelectorId!, $filterId: StObjectId) {
    node(id: $resourceId, languageId: $aspectSelectorId) {
      ... on ST4Node {
        content {
          ... on MediaContent {
            media {
              ... on VectorGraphicsMediaInfo {
                resourceId
                svg(filterId: $filterId)
              }
            }
          }
        }
      }
    }
  }
`

/**
 * __useVectorGraphicsSvgQuery__
 *
 * To run a query within a React component, call `useVectorGraphicsSvgQuery` and pass it any options that fit your needs.
 * When your component renders, `useVectorGraphicsSvgQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVectorGraphicsSvgQuery({
 *   variables: {
 *      resourceId: // value for 'resourceId'
 *      aspectSelectorId: // value for 'aspectSelectorId'
 *      filterId: // value for 'filterId'
 *   },
 * });
 */
export function useVectorGraphicsSvgQuery(
  baseOptions: Apollo.QueryHookOptions<VectorGraphicsSvgQuery, VectorGraphicsSvgQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<VectorGraphicsSvgQuery, VectorGraphicsSvgQueryVariables>(VectorGraphicsSvgDocument, options)
}
export function useVectorGraphicsSvgLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<VectorGraphicsSvgQuery, VectorGraphicsSvgQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<VectorGraphicsSvgQuery, VectorGraphicsSvgQueryVariables>(
    VectorGraphicsSvgDocument,
    options,
  )
}
export type VectorGraphicsSvgQueryHookResult = ReturnType<typeof useVectorGraphicsSvgQuery>
export type VectorGraphicsSvgLazyQueryHookResult = ReturnType<typeof useVectorGraphicsSvgLazyQuery>
export type VectorGraphicsSvgQueryResult = Apollo.QueryResult<VectorGraphicsSvgQuery, VectorGraphicsSvgQueryVariables>
