import React from "react"
import { isTypename } from "@st4/graphql"
import { DoubleEditor } from "~/ui/editors/doubleEditor"
import { useUpdatePropertyMutation, UpdatePropertyMutationVariables } from "../../query.hooks"
import type { PropertyEditor, DoublePropertyValue } from "../types"
import { Icon, Regular } from "@st4/icons"

function getInputValue(
  propertyEditor: PropertyEditor<DoublePropertyValue>,
  value: number,
): UpdatePropertyMutationVariables["input"] {
  const input = {
    variantName: propertyEditor.variantName,
    displayName: propertyEditor.displayName,
    id: propertyEditor.id,
    node: { id: propertyEditor.node.id },
    origin: propertyEditor.origin,
    value: {
      doubleValue: value,
    },
  }

  return input
}

export function PropertyDoubleEditor({
  propertyEditor,
  languageId,
  refetchProperties,
  refetchOnSave,
}: {
  propertyEditor: PropertyEditor<DoublePropertyValue>
  languageId: string
  refetchProperties: () => void
  refetchOnSave?: string[]
}) {
  const { value, isReadonly } = propertyEditor

  const [updateProperty, { data, loading, error }] = useUpdatePropertyMutation({
    variables: { input: { ...propertyEditor, value: { doubleValue: propertyEditor.value?.doubleValue } } },
    refetchQueries: refetchOnSave,
  })

  const submitHandler = async (changedValue: number) => {
    updateProperty({
      variables: {
        input: getInputValue(propertyEditor, changedValue),
        languageId: languageId,
      },
      onCompleted: (_) => {
        refetchProperties()
      },
    })
  }

  let suffixIcon = null

  if (loading) {
    suffixIcon = <Icon component={Regular.SpinnerThird} />
  }

  let errorMessage: string | undefined = undefined

  if (error) {
    errorMessage = error.message
  }
  if (isTypename("Error")(data?.updateProperty)) {
    errorMessage = data?.updateProperty.message
  }

  return (
    <DoubleEditor
      errorMessage={errorMessage}
      value={value?.doubleValue ?? undefined}
      isReadonly={isReadonly}
      suffixIcon={suffixIcon}
      onSubmit={submitHandler}
    />
  )
}
