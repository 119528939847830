import { CommandContext, CommandInput, InsertionPosition, isTypename } from "@st4/graphql"
import { useCommandInfoLazyQuery, useExecuteCommandMutation } from "./query.hooks"
import { useCallback } from "react"
import { useLanguage } from "@st4/settings"
import {
  CanExecuteForMultipleQueryResult,
  CanExecuteQueryResult,
  IncorrectResultCountErrorResult,
  MoveCommands,
  MoveExecutionResult,
  MultipleSuccessResult,
  PatternContextResult,
  RelativeInsertionPosition,
  SuccessResult,
  UnknownErrorResult,
} from "./types"

function createInsertionPosition(
  idOfTargetNode: string,
  insertionPositionRelativeToTarget: RelativeInsertionPosition
): InsertionPosition | null {
  return insertionPositionRelativeToTarget.relativePosition === "CHILD"
    ? null
    : {
        context: idOfTargetNode,
        relativePosition: insertionPositionRelativeToTarget.relativePosition,
      }
}

export function useMoveCommand(): MoveCommands {
  const [getCommandInfo] = useCommandInfoLazyQuery()
  const [executeCommand] = useExecuteCommandMutation()
  const { languageId } = useLanguage()

  const queryCanMove = useCallback(
    async (
      idOfNodeToMove: string,
      idOfTargetNode: string,
      insertionPositionRelativeToTarget: RelativeInsertionPosition
    ): Promise<CanExecuteQueryResult> => {
      const commandInput: CommandInput = {
        command: "MOVE_NODE",
      }

      let correctTargetNodeId = idOfTargetNode
      if (insertionPositionRelativeToTarget.relativePosition !== "CHILD") {
        correctTargetNodeId = insertionPositionRelativeToTarget.idOfParentOfTargetNode
      }
      const insertionPosition = createInsertionPosition(idOfTargetNode, insertionPositionRelativeToTarget)

      const commandContext: CommandContext = {
        insertionPosition,
        source: [idOfNodeToMove],
        target: correctTargetNodeId,
      }

      return getCommandInfo({
        variables: {
          input: commandInput,
          context: commandContext,
          languageId,
        },
        fetchPolicy: "network-only",
      })
        .then(({ data, error }) => {
          if (error) {
            const errorInfo: UnknownErrorResult = { type: "UNKNOWN_ERROR", messages: [error.message] }
            return errorInfo
          }

          if (!data || !data.commands || data.commands.length < 1) {
            throw "No error in execution of query but got no data"
          }

          const resultInfo: SuccessResult = {
            type: "SUCCESS",
            result: isTypename("AllowedCommand")(data.commands[0]),
          }
          return resultInfo
        })
        .catch((reasons) => {
          console.log(reasons)
          throw "Unhandled error in query can move node"
        })
    },
    [getCommandInfo, languageId]
  )

  const queryCanMoveAsChildForMultipleTargets = useCallback(
    async (idOfNodeToMove: string, idsOfTargetNode: string[]): Promise<CanExecuteForMultipleQueryResult> => {
      const commandInput: CommandInput = {
        command: "MOVE_NODE",
      }
      const commandContexts: CommandContext[] = idsOfTargetNode.map((targetNode) => {
        return {
          insertionPosition: null,
          source: [idOfNodeToMove],
          target: targetNode,
        }
      })

      return getCommandInfo({
        variables: {
          input: commandInput,
          context: commandContexts,
          languageId,
        },
        fetchPolicy: "network-only",
      })
        .then(({ data, error }) => {
          if (error) {
            const errorInfo: UnknownErrorResult = { type: "UNKNOWN_ERROR", messages: [error.message] }
            return errorInfo
          }

          if (!data || !data.commands) {
            throw "No error in execution of query but got no data"
          }

          if (data.commands.length !== idsOfTargetNode.length) {
            const notEnoughResultsInfo: IncorrectResultCountErrorResult = {
              type: "INCORRECT_RESULT_COUNT",
              expected: idsOfTargetNode.length,
              result: data.commands.length,
            }
            return notEnoughResultsInfo
          }

          const resultInfo: MultipleSuccessResult = { type: "MULTIPLE_SUCCESS", result: [] }
          for (let index = 0; index < idsOfTargetNode.length; index++) {
            resultInfo.result.push(isTypename("AllowedCommand")(data.commands[index]))
          }
          return resultInfo
        })
        .catch((reasons) => {
          console.log(reasons)
          throw "Unhandled error in query can move node"
        })
    },
    [getCommandInfo, languageId]
  )

  const executeMove = useCallback(
    async (
      idOfNodeToMove: string,
      idOfTargetNode: string,
      insertionPositionRelativeToTarget: RelativeInsertionPosition,
      refetchQueries?: string[]
    ): Promise<MoveExecutionResult> => {
      let correctTargetNodeId = idOfTargetNode
      if (insertionPositionRelativeToTarget.relativePosition !== "CHILD") {
        correctTargetNodeId = insertionPositionRelativeToTarget.idOfParentOfTargetNode
      }

      const insertionPosition = createInsertionPosition(idOfTargetNode, insertionPositionRelativeToTarget)
      const commandInput: CommandInput = {
        command: "MOVE_NODE",
      }

      const commandContext: CommandContext = {
        insertionPosition,
        source: [idOfNodeToMove],
        target: correctTargetNodeId,
      }

      return executeCommand({ variables: { input: commandInput, context: commandContext, languageId }, refetchQueries })
        .then(({ data, errors }) => {
          if (errors) {
            const errorInfo: UnknownErrorResult = {
              type: "UNKNOWN_ERROR",
              messages: errors.map((graphqlError) => graphqlError.message),
            }
            return errorInfo
          }

          if (!data || !data.executeCommand) {
            throw "No error in execution of mutation but got no data"
          }

          if (isTypename("CommandExecutionPatternContextSuccess")(data.executeCommand)) {
            const patternContextResult: PatternContextResult = {
              type: "PATTERN_CONTEXT",
              result: data.executeCommand.result ?? null,
            }
            return patternContextResult
          }

          const mutationSuccessResult: SuccessResult = {
            type: "SUCCESS",
            result: isTypename("CommandExecutionSuccess")(data.executeCommand),
            messages: isTypename("CommandExecutionFailure")(data.executeCommand)
              ? data.executeCommand.reasons?.map((reason) => reason.toString())
              : undefined,
          }
          return mutationSuccessResult
        })
        .catch((reasons) => {
          console.log(reasons)
          throw "Unhandled error in execute move"
        })
    },
    [executeCommand, languageId]
  )

  return { queryCanMove, queryCanMoveAsChildForMultipleTargets, executeMove }
}
