import React, { useEffect, useState } from "react"
import { Translate } from "@st4/i18n"
import { Input } from "@st4/ui"
import { keys } from "@st4/ui-strings"
import { Icon, Light } from "@st4/icons"

type IntEditorProps = {
  value?: number
  isReadonly?: boolean
  suffixIcon?: React.ReactNode
  onSubmit: (value: number) => void
  errorMessage?: string
}

function isInteger(value: string) {
  const regexIsInteger = new RegExp("^[-]{0,1}[0-9]*$")
  return regexIsInteger.test(value)
}

export function IntEditor({ value, isReadonly, suffixIcon, onSubmit, errorMessage }: IntEditorProps) {
  const originalStringValue = value?.toString() ?? ""
  const [inputValue, setInputValue] = useState(originalStringValue)
  const dirty = originalStringValue != inputValue

  useEffect(() => setInputValue(value?.toString() ?? ""), [value])

  const submitHandler = (value: string) => {
    if (dirty && isInteger(value)) {
      onSubmit(parseInt(value))
    }
  }

  function handleKeyup(ev: React.KeyboardEvent<HTMLInputElement>) {
    if (ev.key === "Escape") {
      setInputValue(originalStringValue)
    }
  }

  if (!suffixIcon && !isReadonly) {
    suffixIcon = <Icon component={Light.Pen} />
  }

  const dirtyAndNoInteger = dirty && !isInteger(inputValue)
  const dirtyAndNoIntegerMessageKey = keys.message.error.inputNotInt

  return (
    <Input
      errorMessage={
        errorMessage ?? (dirtyAndNoInteger ? <Translate>{dirtyAndNoIntegerMessageKey}</Translate> : undefined)
      }
      dirty={dirty}
      readonly={isReadonly}
      value={inputValue}
      onChange={(e) => setInputValue(e.target.value)}
      onSubmit={() => submitHandler(inputValue)}
      suffixIcon={suffixIcon}
      onKeyUp={handleKeyup}
    />
  )
}
