import * as React from "react"
import { SVGProps } from "react"

const SvgBorderAll = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="1em" height="1em" {...props}>
    <path d="M384 80c8.8 0 16 7.2 16 16v136H248V80h136zm16 200v136c0 8.8-7.2 16-16 16H248V280h152zm-200-48H48V96c0-8.8 7.2-16 16-16h136v152zM48 280h152v152H64c-8.8 0-16-7.2-16-16V280zM64 32C28.7 32 0 60.7 0 96v320c0 35.3 28.7 64 64 64h320c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64z" />
  </svg>
)

export default SvgBorderAll
