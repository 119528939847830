import React, { useState, useMemo } from "react"
import { DatePicker, Space } from "antd"
import { CheckboxFacetItem } from "./CheckboxFacetItem"
import { FacetWrapper, getLabel, getRefinementId } from "./Utils"
import { SearchFacetItem } from "../../types"
import { dayjs } from "@schema/utils"
import { useTranslation } from "react-i18next"
import { keys } from "@st4/ui-strings"

type ModifiedOnFacetProps = {
  facetKey: string
  title: string
  availableValues?: SearchFacetItem[] | null
  selectedValue?: React.Key
  onSubmit: (selectedValue?: React.Key) => void
}

export function ModifiedOnFacet(props: ModifiedOnFacetProps) {
  const { t } = useTranslation()
  const [selectedValueFrom, setSelectedValueFrom] = useState<dayjs.Dayjs | null>(
    extractValue("From", props.selectedValue?.toString()),
  )
  const [selectedValueUntil, setSelectedValueUntil] = useState<dayjs.Dayjs | null>(
    extractValue("Until", props.selectedValue?.toString()),
  )
  const selectedValue = useMemo(() => {
    const isValidDateFrom = !!selectedValueFrom && selectedValueFrom.isValid()
    const isValidDateUntil = !!selectedValueUntil && selectedValueUntil.isValid()

    const isCustomValueSet = props.selectedValue?.toString().indexOf("custom") != -1
    const value =
      isValidDateFrom && isValidDateUntil
        ? `${props.facetKey}\u001fcustom\u001f[${selectedValueFrom.format("YYYY-MM-DD")} TO ${selectedValueUntil.format(
            "YYYY-MM-DD",
          )}]`
        : isCustomValueSet
        ? undefined
        : props.selectedValue?.toString()

    if (!props.selectedValue || !value || !props.selectedValue.toString().endsWith(value)) selectHandler(value)

    return value
  }, [props.selectedValue, selectedValueFrom, selectedValueUntil])

  const testId = "modifiedOn"

  return (
    <FacetWrapper testId={testId} title={props.title}>
      {props.availableValues?.map((value) => {
        const itemTestId = `${testId}-${value.label}`
        return (
          <CheckboxFacetItem
            key={itemTestId}
            testId={itemTestId}
            title={`${getLabel(value.label, t)} (${value.count})`}
            value={getRefinementId(props.facetKey, value.value)}
            selectedValue={selectedValue}
            onClear={selectHandler}
            onSelect={selectHandler}
          />
        )
      })}
      <DateRange
        key={`${testId}-custom`}
        testId={`${testId}-custom`}
        disabled={!!selectedValue && !selectedValueFrom && !selectedValueUntil}
        minDate={selectedValueFrom ?? dayjs("1900-01-01")}
        maxDate={selectedValueUntil ?? dayjs(dayjs().format("YYYY-MM-DD"))}
        valueFrom={selectedValueFrom}
        valueUntil={selectedValueUntil}
        onChangeFrom={(date) => setSelectedValueFrom(!date ? null : dayjs(date.format("YYYY-MM-DD")))}
        onChangeUntil={(date) => setSelectedValueUntil(!date ? null : dayjs(date.format("YYYY-MM-DD")))}
      />
    </FacetWrapper>
  )

  function extractValue(part: "From" | "Until", value?: string): dayjs.Dayjs | null {
    if (!value || value.indexOf("custom") == -1) return null
    const splitPart = part == "From" ? 0 : 1
    const bracket = part == "From" ? "[" : "]"
    return dayjs(value.split("\u001f")[2].split(" TO ")[splitPart].replace(bracket, ""))
  }

  function selectHandler(selectedValue?: React.Key) {
    props.onSubmit(selectedValue)
  }
}

function DateRange(props: {
  testId: string
  disabled?: boolean
  minDate: dayjs.Dayjs
  maxDate: dayjs.Dayjs
  valueFrom: dayjs.Dayjs | null
  valueUntil: dayjs.Dayjs | null
  onChangeFrom: (date: dayjs.Dayjs | null) => void
  onChangeUntil: (date: dayjs.Dayjs | null) => void
}) {
  const { t } = useTranslation()
  return (
    <Space direction={"horizontal"} size={8}>
      <Date
        data-testid={`${props.testId}-from`}
        disabled={props.disabled}
        placeholder={t(keys.label.search.component.searchFacetsList.facetItemLabels.from)}
        minDate={props.minDate}
        maxDate={props.maxDate}
        value={props.valueFrom}
        onChange={props.onChangeFrom}
      />
      <Date
        data-testid={`${props.testId}-from`}
        disabled={props.disabled}
        placeholder={t(keys.label.search.component.searchFacetsList.facetItemLabels.until)}
        minDate={props.minDate}
        maxDate={props.maxDate}
        value={props.valueUntil}
        onChange={props.onChangeUntil}
      />
    </Space>
  )
}

function Date(props: {
  disabled?: boolean
  placeholder?: string
  minDate: dayjs.Dayjs
  maxDate: dayjs.Dayjs
  value: dayjs.Dayjs | null
  onChange: (value: dayjs.Dayjs | null, dateString: string) => void
}) {
  return (
    <DatePicker
      allowClear
      bordered
      disabled={props.disabled}
      picker={"date"}
      showTime={false}
      showToday
      disabledDate={(date) => {
        const datePart = dayjs(date.format("YYYY-MM-DD"))
        return datePart.isBefore(props.minDate) || datePart.isAfter(props.maxDate)
      }}
      placeholder={props.placeholder}
      value={props.value}
      onChange={props.onChange}
    />
  )
}
