import React, { useState, useRef, useEffect, useCallback, useMemo } from "react"
import styled from "styled-components"
import { calculateGlyphMap } from "./glyphpositions"
import { TextSelection, ProgressState } from "./types"
import type { Node } from "unist"
import { useSelectionContext } from "./SelectionContext"
import { useNode } from "../contentModel/nodeContext"
import {
  GlyphTuple,
  findSplitpointByEvent,
  splitText,
  isCommentingAllowed,
  sliceTextAtSplitPoints,
  NormalText,
} from "./splitting"
import { useResizeObserver } from "../components/commentEditor/resizeObserverHook"
import { CommentNode, useCommentCollection } from "../components/annotationCollection"
import { useIntersectionObserver, mergeRefs } from "../utilities"
import { isTypename } from "@st4/graphql"

type TextProps = {
  children: string
  xastNode: Node
  className?: string
}

const TextStyling = styled.span`
  position: relative;
  user-select: none;
`

type TextSelectionOptions = {
  xastNode: Node
  text: string
  comments: CommentNode[]
}

type TextSelectionHook = {
  containerRef: React.Ref<HTMLElement>
  textSelectionInProgress: boolean
  currentTextSelection: TextSelection
  handleMouseEvents: (evt: React.PointerEvent<Element> | PointerEvent) => false
}

function useWindowPointerEvent(type: "pointerdown" | "pointerup", thunk: () => void) {
  const handlePointerEvent = useCallback(
    (e: PointerEvent) => {
      if (e.type === type) thunk()
    },
    [thunk],
  )

  useEffect(() => {
    window.addEventListener(type, handlePointerEvent)
    return () => {
      window.removeEventListener(type, handlePointerEvent)
    }
  }, [window])
}

/**
 * Creates an event handler which executes the handler only if a (long) press happens, contradictory to a simple click.
 * @param pressHandler The original handler to use
 */
function usePressEventhandler(pressHandler: (e: React.PointerEvent<Element>) => void) {
  const CLICKTIMEOUT = 150 // after this the pointer down is no longer considered a "click"
  const [mouseIsDown, setMouseIsDown] = useState(false)
  const [timeoutRunning, setTimeoutRunning] = useState(false)
  const originalMouseDownEvent = useRef<React.PointerEvent<Element>>()

  useEffect(() => {
    let handle: number
    if (mouseIsDown)
      handle = window.setTimeout(() => {
        setTimeoutRunning(false)
      }, CLICKTIMEOUT)
    return () => window.clearTimeout(handle)
  }, [mouseIsDown])

  useEffect(() => {
    if (mouseIsDown && !timeoutRunning && originalMouseDownEvent.current) {
      // Der pointer war länger "down" als die konfigurierte "Klickzeit"
      originalMouseDownEvent.current.preventDefault()
      originalMouseDownEvent.current.stopPropagation()
      pressHandler(originalMouseDownEvent.current)
    }
  }, [mouseIsDown, timeoutRunning])

  const mouseDown = (e: React.PointerEvent<Element>) => {
    setTimeoutRunning(true)
    setMouseIsDown(true)
    e.persist()
    originalMouseDownEvent.current = e
  }

  const mouseUp = (e: React.PointerEvent<Element>) => {
    setMouseIsDown(false)
    e.persist()
    if (!timeoutRunning) {
      // Der pointer war länger "down" als die konfigurierte "Klickzeit"
      e.preventDefault()
      e.stopPropagation()
      pressHandler(e)
    }
  }
  return [mouseDown, mouseUp]
}

export function Text({ children, xastNode, className }: TextProps) {
  const allComments = useCommentCollection()
  const comments = useMemo(() => allComments.filter((n) => isTypename("TextComment")(n.comment)), [allComments])
  const { containerRef, currentTextSelection, handleMouseEvents } = useTextSelectionEvents({
    xastNode: xastNode,
    text: children,
    comments,
  })
  const [mouseDown, mouseUp] = usePressEventhandler(handleMouseEvents)
  const node = useNode()

  return (
    <TextStyling
      ref={containerRef}
      onPointerDown={mouseDown}
      onPointerMove={handleMouseEvents}
      onPointerUp={mouseUp}
      className={className}
    >
      <SplittedSegments node={node} xastNode={xastNode} currentTextSelection={currentTextSelection} comments={comments}>
        {transformEntities(children)}
      </SplittedSegments>
    </TextStyling>
  )
}

const CHARACTER_ENTITIES: Record<string, string> = {
  "&amp;": "&\u200b\u200b\u200b\u200b",
  "&apos;": "'\u200b\u200b\u200b\u200b\u200b",
  "&gt;": ">\u200b\u200b\u200b",
  "&lt;": "<\u200b\u200b\u200b",
  "&quot;": '"\u200b\u200b\u200b\u200b\u200b',
}

function transformEntities(string: string) {
  function replacer(substring: string): string {
    const replacement = CHARACTER_ENTITIES[substring]
    return replacement ?? string
  }
  return string.replace(/&(amp|apos|gt|lt|quot);/g, replacer)
}

function getStartingPoint(splitPoint: ReturnType<typeof findSplitpointByEvent>, glyphMap: GlyphTuple[]) {
  const glyph = splitPoint.glyphUnderCursor ?? splitPoint.glyphWithSmallestDelta
  const isLastGlyph = glyph.index >= glyphMap.length - 1 && glyph.index != 0
  //Place cursor on the right of the last glyph (+1) of text, otherwise on the left (+0)
  return glyph.offset + (isLastGlyph ? 1 : 0)
}

function useTextSelectionEvents(options: TextSelectionOptions): TextSelectionHook {
  const [glyphMap, setGlyphMap] = useState<GlyphTuple[]>()
  const containerRef = useRef<HTMLSpanElement>(null)
  const [intersectionCallbackRef, isVisible] = useIntersectionObserver()

  const nodeOffset = {
    start: options.xastNode.position?.start.offset ?? 0,
    location: options.xastNode.position?.end.offset ?? 0,
  }
  const node = useNode()

  const {
    dispatchSelectionChange,
    dispatchSelectionEnd,
    dispatchSelectionStart,
    dispatchUnselect,
    onHover,
    currentTextSelection,
  } = useSelectionContext()
  const textSelectionInProgress = !!currentTextSelection && currentTextSelection.progress === ProgressState.Ongoing
  const size = useResizeObserver(containerRef.current?.parentElement || null)

  useEffect(
    function calcCharacterPositions() {
      if (!containerRef.current || !isVisible || glyphMap?.length) return
      const glyphs = calculateGlyphMap(containerRef.current, options.text, nodeOffset.start)
      setGlyphMap(glyphs)
    },
    [containerRef.current, size, isVisible, glyphMap],
  )
  const selectionEvents = {
    onSetStart: (marker: React.RefObject<HTMLElement>, selectionValid: boolean, point: number) => {
      if (point) dispatchSelectionStart(options.xastNode, node, marker, selectionValid, point)
    },
    onSetEnd: () => {
      dispatchSelectionEnd()
    },
    onSetChange: (marker: React.RefObject<HTMLElement>, selectionValid: boolean, point: number) => {
      if (point) dispatchSelectionChange(options.xastNode, node, marker, selectionValid, point)
    },
    onUnselect: () => {
      dispatchUnselect()
    },
  }

  const handlePointerup = useCallback(() => dispatchSelectionEnd(), [dispatchSelectionEnd])
  useWindowPointerEvent("pointerup", handlePointerup)

  function handleMouseEvents(evt: React.PointerEvent<Element> | PointerEvent): false {
    if (!containerRef.current || !glyphMap) return false
    const getPointFromGlyph = (glyph: { offset: number; index: number }) =>
      currentTextSelection.progress === ProgressState.Ongoing && currentTextSelection.selectionDirection == "forward"
        ? glyph.offset + 1
        : glyph.offset + (glyph.index >= glyphMap.length - 1 ? 1 : 0)

    switch (evt.type) {
      case "pointerdown":
        if (currentTextSelection.progress == ProgressState.NoSelection) {
          const splitPoint = findSplitpointByEvent(evt, containerRef.current, glyphMap)
          const startPoint = getStartingPoint(splitPoint, glyphMap)
          const selectionValid = isCommentingAllowed(nodeOffset, startPoint, startPoint, options.comments)
          selectionEvents.onSetStart(containerRef, selectionValid, startPoint)
        }
        break
      case "pointermove":
        if (currentTextSelection.progress == ProgressState.Ongoing) {
          const splitPoint = findSplitpointByEvent(evt, containerRef.current, glyphMap)
          const pointFromGlyph = getPointFromGlyph(splitPoint.glyphWithSmallestDelta)
          const selectionValid = isCommentingAllowed(
            nodeOffset,
            Math.min(pointFromGlyph, currentTextSelection.start.offset),
            Math.max(pointFromGlyph, currentTextSelection.end.offset),
            options.comments,
          )
          selectionEvents.onSetChange(containerRef, selectionValid, pointFromGlyph)
        } else if (currentTextSelection.progress == ProgressState.NoSelection && evt.buttons === 1) {
          // Selection starts outside of the text container
          const splitPoint = findSplitpointByEvent(evt, containerRef.current, glyphMap)

          const startPoint = getStartingPoint(splitPoint, glyphMap)
          const selectionValid = isCommentingAllowed(nodeOffset, startPoint, startPoint, options.comments)
          selectionEvents.onSetStart(containerRef, selectionValid, startPoint)
        } else {
          onHover(options.xastNode, node)
        }
        break
      case "pointerup":
        if (currentTextSelection.progress == ProgressState.Ongoing) {
          const { start, end } = currentTextSelection
          if (start.marker.current !== end.marker.current || end.offset - start.offset !== 0) {
            selectionEvents.onSetEnd()
          } else {
            selectionEvents.onUnselect()
          }
        }
        break
      default:
    }

    return false
  }

  return {
    containerRef: mergeRefs(containerRef, intersectionCallbackRef),
    textSelectionInProgress,
    currentTextSelection,
    handleMouseEvents,
  }
}

function SplittedSegments({
  xastNode,
  currentTextSelection,
  children,
  node,
  comments,
}: {
  xastNode: Node
  currentTextSelection: TextSelection
  children: string
  node: Parameters<typeof splitText>[3]
  comments: CommentNode[]
}) {
  const textSelectionInProgress = currentTextSelection.progress
  const nodeOffset = { start: xastNode.position?.start.offset ?? 0, location: xastNode.position?.end.offset ?? 0 }
  const validSelection =
    currentTextSelection.progress !== ProgressState.NoSelection && currentTextSelection.selectionAllowed
  const keyPrefix = `Node_${xastNode.position?.start.offset}_${xastNode.position?.end.offset}_`
  const splits = splitText(nodeOffset, currentTextSelection, comments, node)
  return splits.length >= 1 ? (
    sliceTextAtSplitPoints(splits, children, validSelection, textSelectionInProgress, keyPrefix)
  ) : (
    <>
      <NormalText key={keyPrefix + "split_0"}>{children}</NormalText>
    </>
  )
}
