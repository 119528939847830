import { isTypename } from "@st4/graphql";
import { parseVariables } from "@st4/content-tools";
export function getVariableInfo(variable, metaData) {
    if (!variable || !variable.ref || !variable.node || metaData[variable.ref]) return;
    if (!isTypename("ST4Node")(variable.node) || !isTypename("VariableTableContent")(variable.node.content)) return;
    var _xml;
    var variableParsed = parseVariables((_xml = variable.node.content.xml) !== null && _xml !== void 0 ? _xml : "");
    if (!variableParsed) return;
    metaData[variable.ref] = variableParsed;
}
