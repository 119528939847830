import React from "react"
import styled from "styled-components"
import { findNodeByTagName } from "../../../ast/utils"
import { useMediaNodeWithAlternatives } from "./hooks"
import { ImageMedia } from "./ImageMedia"
import { VectorGraphicsMedia } from "./VectorGraphicsMedia"
import type { Node } from "unist"
import { AnimationMedia } from "./AnimationMedia"
import { FilteringTreeNodeWrapper } from "../../FilteringTreeNodeWrapper"
import { useSt4ToTreeNode } from "../../../utilities/treeUtilities"
import { NoMedia } from "./NoMedia"
import type { ST4NodeWithContentName } from "../../../graphql/types"
import type { ReactProp } from "../../../types"
import { XASTViewer } from "../XASTViewer"

export const ImageTitle = styled.span`
  font-weight: bold;
`

function _ImageDisplay(props: React.PropsWithChildren<{ xastNode: Node; titleNode?: Node; legendNode?: Node }>) {
  const { xastNode, titleNode } = props
  const title = titleNode ? <XASTViewer xast={titleNode}></XASTViewer> : ""

  const comments = props.xastNode.data?.blockcomments || []

  const imageLinkId = xastNode?.children?.find((n) => n.tagName == "img")?.attributes?.["src"]?.value ?? ""
  const { filteredResult, alternatives } = useMediaNodeWithAlternatives(imageLinkId)
  const mediaInfo = filteredResult?.content.media

  const isGroup = alternatives.length > 0
  const st4ToTreeNode = useSt4ToTreeNode()
  const treeNode = st4ToTreeNode(filteredResult)
  if (!filteredResult || !mediaInfo || !treeNode) return null

  return (
    <FilteringTreeNodeWrapper treeNodeId={treeNode.id}>
      <VisibleImage
        isGroup={isGroup}
        title={title}
        xastNode={xastNode}
        legendNode={props.legendNode}
        mediaInfo={mediaInfo}
        mediaNode={filteredResult}
        comments={comments}
      />
    </FilteringTreeNodeWrapper>
  )
}

type VisibleImageProps = {
  title: React.ReactNode
  xastNode?: Node
  legendNode?: Node
  mediaInfo: ReactProp<
    typeof AnimationMedia | typeof ImageMedia | typeof NoMedia | typeof VectorGraphicsMedia,
    "mediaInfo"
  >
  mediaNode: ST4NodeWithContentName<"MediaContent" | "MediaGroupContent">
  comments: ReactProp<typeof ImageMedia, "comments"> //Parameters<typeof ImageMedia>[0]["comments"]
  isGroup: boolean
}

function VisibleImage(props: VisibleImageProps) {
  const { mediaInfo, ...otherProps } = props
  switch (mediaInfo.__typename) {
    case "NoMediaInfo":
      return <NoMedia mediaInfo={mediaInfo} {...otherProps} />
    case "ImageMediaInfo":
      return <ImageMedia mediaInfo={mediaInfo} {...otherProps} />
    case "VectorGraphicsMediaInfo":
      return <VectorGraphicsMedia mediaInfo={mediaInfo} {...otherProps} />
    case "AnimationMediaInfo":
      return <AnimationMedia mediaInfo={mediaInfo} {...otherProps} />
    case "VideoMediaInfo":
      //TODO: Zur Zeit wird hier AnimationMedia angezeigt, weil sowohl für Animationen, als auch für Videos nur Thumbnails dargestellt werden.
      return <AnimationMedia mediaInfo={mediaInfo} {...otherProps} />
  }
}

export function ImageDisplay(props: { ast: Node }) {
  const titleNode = findNodeByTagName("image-title", props.ast)
  const legendNode = findNodeByTagName("table-container", props.ast)

  return <_ImageDisplay xastNode={props.ast} titleNode={titleNode} legendNode={legendNode} />
}

export function ResourceImageDisplay({
  title,
  isGroup,
  mediaNode,
}: {
  title: string
  isGroup?: boolean
  mediaNode: ST4NodeWithContentName<"MediaContent" | "MediaGroupContent">
}) {
  if (!mediaNode.content.media) return null
  return (
    <VisibleImage
      isGroup={!!isGroup}
      title={title}
      xastNode={undefined}
      legendNode={undefined}
      mediaInfo={mediaNode.content.media}
      mediaNode={mediaNode}
      comments={[]}
    />
  )
}
