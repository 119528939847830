import React from "react"
import { isTypename, notEmpty } from "@st4/graphql"
import { NavigationTreeDataRequest, useIndentedNavigationTreeData } from "./treequery"
import { TreeView, IndentedTreeNode, TreeViewProps } from "@st4/ui"
import { CoreNavigationTreeViewletProps, ServerIndentedTreeNode } from "./types"

export type NavigationTreeViewletWithQueryProps = CoreNavigationTreeViewletProps & {
  onAllExpandedNodesChanged: (allExpandedNodes: string[]) => void
} & Omit<NavigationTreeProps, "indentedNodes"> &
  Pick<NavigationTreeDataRequest, "ignoreIcons" | "includeNodeClassHierarchy">

export function NavigationTreeViewletWithQuery(props: NavigationTreeViewletWithQueryProps) {
  const { data, error } = useIndentedNavigationTreeData(
    { ...props, selectedNodes: props.selected ? [props.selected] : undefined },
    props.onAllExpandedNodesChanged,
    props.fetchPolicy,
  )

  if (error) {
    return <div>{error.message}</div>
  }

  if (!data) return null

  const indentedNodes = data.tree.filter(notEmpty)

  return <NavigationTree {...props} indentedNodes={indentedNodes} />
}

type NavigationTreeProps = {
  indentedNodes: ServerIndentedTreeNode[]
  variant: string
  aspectId: string
} & Pick<
  TreeViewProps,
  | "queryCanDropDraggedItemsOnVisibleNodes"
  | "onDrop"
  | "draggable"
  | "onExpandChange"
  | "expandedNodes"
  | "stateRef"
  | "selected"
  | "meatballMenu"
  | "createButton"
> &
  Pick<CoreNavigationTreeViewletProps, "onSelect">

function NavigationTree({ indentedNodes, variant, aspectId, onSelect, ...treeViewProps }: NavigationTreeProps) {
  return (
    <TreeView
      {...treeViewProps}
      onSelect={(id: string) => {
        const node = indentedNodes.find((n) => n.treeNode.id === id)?.treeNode
        if (node) {
          onSelect(node)
        }
      }}
      orderedTreeNodes={indentedNodes.map((t) => convertToIndentedTreeNode(t))}
      name={variant}
      treeOperation="MOVE_TREENODE"
    />
  )
}

function convertToIndentedTreeNode(serverIndentedTreeNode: ServerIndentedTreeNode): IndentedTreeNode {
  if (!isTypename("ST4TreeNode")(serverIndentedTreeNode.treeNode)) throw "no valid tree node found"
  if (!serverIndentedTreeNode.treeNode.node?.id) throw "no valid node found"

  return {
    node: {
      id: serverIndentedTreeNode.treeNode.id,
      label: serverIndentedTreeNode.treeNode.label,
      icon: serverIndentedTreeNode.treeNode.icon?.default?.uri ?? undefined,
    },
    indentation: serverIndentedTreeNode.indent,
    hasChildren: serverIndentedTreeNode.hasChildren,
  }
}
