function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _objectSpread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _defineProperty(target, key, source[key]);
        });
    }
    return target;
}
export function insertImage(payload, window) {
    var fontoMessage = JSON.stringify({
        type: "fontoxml-execute-operation",
        operationName: payload.imageType === "img_free" ? "insert-inline-image" : "insert-image",
        data: _objectSpread({}, payload)
    });
    window.focus();
    window.postMessage(fontoMessage);
}
export function insertFragment(payload, window) {
    var fontoMessage = getFontoMessageForFragment(payload);
    window.focus();
    window.postMessage(fontoMessage);
}
export function insertCrossReference(payload, window) {
    var fontoMessage = JSON.stringify({
        type: "fontoxml-execute-operation",
        operationName: "insert-cross-reference",
        data: _objectSpread({}, payload)
    });
    window.focus();
    window.postMessage(fontoMessage);
}
export function insertSafety(payload, window) {
    var fontoMessage = JSON.stringify({
        type: "fontoxml-execute-operation",
        operationName: "insert-safety",
        data: _objectSpread({}, payload)
    });
    window.focus();
    window.postMessage(fontoMessage);
}
export function updateSafety(payload, window) {
    var fontoMessage = JSON.stringify({
        type: "fontoxml-execute-operation",
        operationName: "update-safety",
        data: _objectSpread({}, payload)
    });
    window.focus();
    window.postMessage(fontoMessage);
}
export function insertVariable(payload, window) {
    var fontoMessage = JSON.stringify({
        type: "fontoxml-execute-operation",
        operationName: "add-document-metadata",
        data: {
            key: payload.linkId,
            value: payload.variableTable
        }
    });
    window.focus();
    window.postMessage(fontoMessage);
    var fontoInsertMessage = JSON.stringify({
        type: "fontoxml-execute-operation",
        operationName: "insert-variable",
        data: {
            linkid: payload.linkId,
            name: payload.selectedVariable.name,
            variant: payload.selectedVariable.variant
        }
    });
    window.focus();
    window.postMessage(fontoInsertMessage);
}
export function insertMetaRef(payload, window) {
    if (payload.linkId) {
        var fontoMessage = JSON.stringify({
            type: "fontoxml-execute-operation",
            operationName: "add-document-metadata",
            data: {
                key: payload.linkId,
                value: JSON.stringify(payload.metaData)
            }
        });
        window.focus();
        window.postMessage(fontoMessage);
    }
    var fontoInsertMessage = JSON.stringify({
        type: "fontoxml-execute-operation",
        operationName: "insert-metaref",
        data: {
            type: payload.type,
            name: payload.name,
            linkId: payload.linkId
        }
    });
    window.focus();
    window.postMessage(fontoInsertMessage);
}
export function saveAndCloseEditor(payload, window) {
    var fontoMessage = JSON.stringify({
        type: "fontoxml-execute-operation",
        operationName: "save-and-release-all-locks-and-send-close-signal-to-iframe",
        data: "true",
        correlationId: JSON.stringify({
            operation: "closeEditor",
            nodeId: payload.nodeId
        })
    });
    window.focus();
    window.postMessage(fontoMessage);
}
export function endEditForCmsElement(payload, window) {
    var fontoMessage = JSON.stringify({
        type: "fontoxml-execute-operation",
        operationName: "end-edit-for-cms-element"
    });
    window.focus();
    window.postMessage(fontoMessage);
}
function getFontoMessageForFragment(payload) {
    if (!payload.fragmentType) return JSON.stringify({
        type: "fontoxml-execute-operation",
        operationName: "insert-fragment",
        data: {
            fragmentId: payload.fragmentId
        }
    });
    return JSON.stringify({
        type: "fontoxml-execute-operation",
        operationName: getOperationNameFor(payload.fragmentType),
        data: {
            elementToInsert: payload.fragmentType,
            ref: payload.fragmentId,
            listType: payload.listType
        }
    });
}
function getOperationNameFor(fragmentType) {
    switch(fragmentType){
        case "li":
            return "insert-structure-fragment-list";
        case "condition":
        case "instruction":
        case "intermediateresult":
        case "result":
            return "insert-structure-fragment-procedural-instruction";
        case "safety_condition":
        case "safety_instruction":
        case "safety_intermediateresult":
        case "safety_result":
            return "insert-structure-fragment-measure";
        default:
            return "insert-structure-fragment";
    }
}
