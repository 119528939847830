import * as React from "react"
import { SVGProps } from "react"

const SvgNetworkWired = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" width="1em" height="1em" {...props}>
    <path d="M400 48v96H240V48h160zM240 0c-26.5 0-48 21.5-48 48v96c0 26.5 21.5 48 48 48h56v40H24c-13.3 0-24 10.7-24 24s10.7 24 24 24h112v40H80c-26.5 0-48 21.5-48 48v96c0 26.5 21.5 48 48 48h160c26.5 0 48-21.5 48-48v-96c0-26.5-21.5-48-48-48h-56v-40h272v40h-56c-26.5 0-48 21.5-48 48v96c0 26.5 21.5 48 48 48h160c26.5 0 48-21.5 48-48v-96c0-26.5-21.5-48-48-48h-56v-40h112c13.3 0 24-10.7 24-24s-10.7-24-24-24H344v-40h56c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48H240zM80 368h160v96H80v-96zm480 0v96H400v-96h160z" />
  </svg>
)

export default SvgNetworkWired
