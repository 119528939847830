import { NotificationPopover } from "@st4/ui"
import { Input } from "antd"
import React, { useMemo, useState } from "react"

export type MultiStringEditorProps = {
  value?: string[]
  isReadOnly?: boolean
  onSubmit: (changedValues: string[]) => void
  errorMessage?: string
  /**
   * Override default placement of error popover to avoid popover hiding context
   */
  errorPlacement?: React.ComponentProps<typeof NotificationPopover>["placement"]
}

export function MultiStringEditor({
  value,
  isReadOnly,
  onSubmit,
  errorMessage,
  errorPlacement,
}: MultiStringEditorProps) {
  const [inputValue, setInputValue] = useState(convertToMultiLineString(value))
  const [previousValue, setPreviousValue] = useState(value ?? [])
  const [isFocused, setIsFocused] = useState(false)

  const valueChanged = useMemo(() => !arraysAreEqual(value ?? [], previousValue), [value, previousValue])
  if (valueChanged) {
    setPreviousValue(value ?? [])
    setInputValue(convertToMultiLineString(value))
  }

  const hasError = !!errorMessage
  const dirty = convertToMultiLineString(value) != inputValue
  const { TextArea } = Input

  return (
    <NotificationPopover
      hideIcon={true}
      open={hasError && isFocused}
      state={"error"}
      placement={errorPlacement ?? "top"}
      content={errorMessage}
    >
      <span>
        <TextArea
          value={inputValue}
          status={hasError ? "error" : undefined}
          disabled={isReadOnly}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyUp={handleKeyUp}
          onBlur={handleBlur}
          onFocus={() => setIsFocused(true)}
          autoSize={{ minRows: 1, maxRows: 3 }}
          // style={{ backgroundColor: hasError ? "red" : dirty ? token.colorWarningBg : "white" }}
        />
      </span>
    </NotificationPopover>
  )

  function handleKeyUp(ev: React.KeyboardEvent<HTMLTextAreaElement>) {
    if (ev.key === "Escape") {
      setInputValue(convertToMultiLineString(value))
    }
  }

  function handleBlur() {
    if (dirty) {
      onSubmit(formatToSeparatedStrings(inputValue))
    }
    setIsFocused(false)
  }
}

function convertToMultiLineString(rawValues: string[] | null | undefined) {
  let resultValue = ""
  if (rawValues) {
    resultValue = rawValues.join("\n")
  }

  return resultValue
}

function formatToSeparatedStrings(input: string) {
  const splitString = input.split("\n")

  if (splitString.length == 1 && splitString[0].length == 0) return []

  return splitString
}

function arraysAreEqual(a: string[], b: string[]) {
  if (a.length !== b.length) return false
  return a.every((item, index) => b[index] === item)
}
