import type { NodeFilterFunction } from "./types"
import type { Node } from "unist"
import { parents } from "unist-util-parents"
import { addComments } from "./addComments"
import find from "unist-util-find"
import { mapWithoutEmptyChildren } from "./utils"
import type { TextContentState } from "../contentModel"
import { getTextContentState } from "../contentModel"
import { filterXAst, calculateFragmentKey } from "./filterXast"
import { St4NodeWithContentFragment } from "../graphql/applicationQueries/query.hooks"
import { isTypename } from "@st4/graphql"

type Option<T> = T | null

function addXastToIdMap(idMap: Map<string, Node>, id: string, xast: Node, textContentState: TextContentState) {
  const filtered = filterXAst(xast, textContentState)
  const withParents = parents({ ...filtered, type: "root" })
  idMap.set(id, withParents)
  return withParents
}

// Extend Xast of Node with Comments and Fragments
// Generated XAst of all subnodes and fragments are added to idMap
// returns the extended XAst of the node from the textContentState
export function importXAST(
  contentNode: St4NodeWithContentFragment,
  idMap: Map<string, Node>,
  nodeFilter: NodeFilterFunction,
  treeNode: { id: string },
): Node {
  const textContentState = getTextContentState(contentNode)
  const {
    node: { content },
  } = textContentState

  if (!isTypename("TextContent")(content) && !isTypename("TextGroupContent")(content))
    throw Error("Invalid Content for XAST-Import!!!!!!!!!!!!!!!!!!!!!!!!!")
  const { xast, comments } = content

  const commentsForNode = comments
  if (!xast || !textContentState) return { type: "EMPTY" }

  const rootXast = parseXast(xast)
  const withComments = commentsForNode ? addComments(rootXast, commentsForNode, treeNode) : rootXast
  const filtered = filterXAst(withComments, textContentState)
  const withFragments = importFragments(filtered, idMap, textContentState, nodeFilter, treeNode)
  const withParents = addXastToIdMap(idMap, contentNode.id, withFragments, textContentState)
  return withParents
}

function importFragments(
  ast: Node,
  idMap: Map<string, Node>,
  textContentState: TextContentState,
  nodeFilter: NodeFilterFunction,
  treeNode: { id: string },
): Node {
  return mapWithoutEmptyChildren(
    ast,
    (node) =>
      tryFragmentImport(node, idMap, textContentState, nodeFilter, treeNode) ||
      tryPartImport(node, idMap, textContentState, nodeFilter, treeNode) ||
      node,
  )
}

function tryPartImport(
  ast: Node,
  idMap: Map<string, Node>,
  textContentState: TextContentState,
  nodeFilter: NodeFilterFunction,
  treeNode: { id: string },
): Option<Node> {
  // Structural fragments are contained in "fragments" field of textcontent too
  // The same logic can be used to calculate the correct fragmentKey
  const ref = ast?.attributes?.["ref"]?.value
  if (!ref) return null
  const fragmentKey = calculateFragmentKey(ref, textContentState, nodeFilter)
  if (!fragmentKey) return null
  const part = textContentState.fragments.get(fragmentKey[0])
  if (!part) return null
  if (!nodeFilter(part).visible) return null
  const content = part?.content
  if (!isTypename("TextContent")(content) && !isTypename("TextGroupContent")(content)) return null

  const commentsForNode = content.comments

  const parsed = parseXast(content.xast ?? "") // TODO: Leerer XAST?
  const nodeWithComments = commentsForNode ? addComments(parsed, commentsForNode, treeNode) : parsed
  addXastToIdMap(idMap, part.id, nodeWithComments, textContentState)
  const replacementItem = find(nodeWithComments, (node) => node.tagName == ast.tagName)
  const xast = replacementItem
    ? {
        ...replacementItem,
        data: {
          ...ast.data,
          fragmentKey: fragmentKey[0],
          fragmentGroupKey: fragmentKey[1],
          partKey: ref,
        },
      }
    : null

  return xast
}

function tryFragmentImport(
  ast: Node,
  idMap: Map<string, Node>,
  textContentState: TextContentState,
  nodeFilter: NodeFilterFunction,
  treeNode: { id: string },
): Option<Node> {
  if (ast.tagName !== "modref") return null
  const src = ast?.attributes?.["src"]?.value
  if (!src) return null
  const fragmentKey = calculateFragmentKey(src, textContentState, nodeFilter)
  if (!fragmentKey) return null
  const fragment = textContentState.fragments.get(fragmentKey[0])
  const content = fragment?.content
  if (!fragment || (!isTypename("TextContent")(content) && !isTypename("TextGroupContent")(content))) {
    return null
  }

  const commentsForNode = content.comments

  const node = parseXast(content.xast ?? "") // TODO: Leerer XAST?
  const nodeWithComments = commentsForNode ? addComments(node, commentsForNode, treeNode) : node
  const fragmentNodeState = getTextContentState(fragment)
  const textContentStateForFragment = { ...textContentState, node: fragmentNodeState.node }
  const expandedFragment = importFragments(nodeWithComments, idMap, textContentStateForFragment, nodeFilter, treeNode)

  addXastToIdMap(idMap, fragment.id, expandedFragment, textContentStateForFragment)

  //Don't use the XAST root, but the first child (<content>)
  const fragmentContent = expandedFragment.children && expandedFragment.children[0]
  if (!fragmentContent) {
    console.error(`Fragment XAST has no <content>`, expandedFragment)
    return null
  }

  const xast = {
    ...fragmentContent,
    data: {
      ...ast.data,
      fragmentKey: fragmentKey[0],
      fragmentGroupKey: fragmentKey[1],
    },
  }
  return xast
}

function parseXast(xastString: string): Node {
  return JSON.parse(xastString)
}
