import * as React from "react"
import { SVGProps } from "react"

const SvgEllipsis = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="1em" height="1em" {...props}>
    <path d="M416 256a32 32 0 1 1-64 0 32 32 0 1 1 64 0zm-160 0a32 32 0 1 1-64 0 32 32 0 1 1 64 0zM64 288a32 32 0 1 1 0-64 32 32 0 1 1 0 64z" />
  </svg>
)

export default SvgEllipsis
