import * as React from "react"
import { SVGProps } from "react"

const SvgSt4ChangeimageRegular = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1em" height="1em" {...props}>
    <path d="M144 128a48 48 0 1 0 0 96 48 48 0 1 0 0-96zm304-96H64C28.7 32 0 60.7 0 96v320c0 35.3 28.7 64 64 64h157.7l4.6-15.7v-.2l11.4-38.6v-.4c4.1-13.5 11.4-25.8 21.2-35.6l104.3-104.3-40.2-51.9c-4.5-5.9-11.6-9.3-19-9.3s-14.4 3.4-19 9.3l-83 107.4-30.5-42.7c-4.5-6.3-11.7-10-19.5-10s-15 3.7-19.5 10.1l-80 112-4.5 6.2V96c0-8.8 7.2-16 16-16h384c8.8 0 16 7.2 16 16v122.1c18.1.8 35 8.2 48 21V96c0-35.3-28.7-64-64-64zm0 448c35.3 0 64-28.7 64-64v-34.8L413.1 480H448zm-9.6-211.7-23.9 23.9 64.3 64.3 23.9-23.9c12.4-12.4 12.4-32.4 0-44.8l-19.5-19.5c-12.3-12.4-32.4-12.4-44.8 0zm-35.1 35.1L288 418.7c-5.1 5.1-8.9 11.5-11 18.5l-17.5 59.5c-1.2 4.2-.1 8.7 3 11.8s7.6 4.2 11.7 3l59.5-17.5c7-2.1 13.4-5.8 18.5-11l115.4-115.3-64.3-64.3z" />
  </svg>
)

export default SvgSt4ChangeimageRegular
