import * as React from "react"
import { SVGProps } from "react"

const SvgSt4InsertimagetitleLight = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1em" height="1em" {...props}>
    <path d="M224 351.7v128h256v-128H224zm-32 0c0-17.6 14.4-32 32-32h256c17.6 0 32 14.4 32 32v128c0 17.6-14.4 32-32 32H224c-17.6 0-32-14.4-32-32v-128zm92 16c4.8 0 9 2.8 11 7.2l32 72c2.7 6.1-.1 13.1-6.1 15.9-6 2.7-13.1 0-15.8-6.1l-2.2-4.9h-37.7l-2.1 4.9c-2.7 6.1-9.8 8.8-15.9 6.1-6-2.7-8.8-9.8-6.1-15.9l32-72c1.9-4.4 6.1-7.2 10.9-7.2zm0 41.6-8.2 18.4h16.4l-8.2-18.4zm60-29.6c0-6.6 5.4-12 12-12h26c16.5 0 30 13.5 30 30 0 4.6-1 9-2.9 12.9 6.6 5.5 10.9 13.8 10.9 23.1 0 16.6-13.5 30-30 30h-34c-6.6 0-12-5.4-12-12v-72zm24 12v12h14c3.3 0 6-2.7 6-6s-2.7-6-6-6h-14zm0 48h22c3.3 0 6-2.7 6-6s-2.7-6-6-6h-22v12zm-185.7-152-26-26c-15.7-15.6-41-15.6-56.6 0L32 329.4V96c0-17.7 14.3-32 32-32h384c17.7 0 32 14.3 32 32v169.4l-67.7-67.7c-15.7-15.6-41-15.6-56.6 0l-90 90H311l67.4-67.4c3.1-3.1 8.2-3.1 11.3 0l67.4 67.4H512V96c0-35.3-28.7-64-64-64H64C28.7 32 0 60.7 0 96v320c0 35.3 28.7 64 64 64h96v-32h-9.4l9.4-9.4v-45.2L105.4 448H64c-17.7 0-32-14.3-32-32v-41.4l90.4-90.3c3.1-3.1 8.2-3.1 11.3 0l26.3 26.3c2.7-20.2 22.3-22.9 22.3-22.9zM96 144c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48zm64 0c0 8.8-7.2 16-16 16s-16-7.2-16-16 7.2-16 16-16 16 7.2 16 16z" />
  </svg>
)

export default SvgSt4InsertimagetitleLight
