import { isTypename } from "@st4/graphql"
import { useLanguage } from "@st4/settings"
import { useContinueDeleteMutation, useDeleteTreeAllowedLazyQuery, useMoveToRecycleBinMutation } from "./query.hooks"
import { useApolloClient } from "@apollo/client"
import { useCallback } from "react"
import { useTranslation } from "react-i18next"
import { keys } from "@st4/ui-strings"

type QuestionState = { state: "question"; response: JobMessageResponse }
type ErrorState = { state: "error"; error: Error }
type JobErrorState = { state: "jobError"; response: JobMessageResponse }
type CompletedState = { state: "completed" }

export type JobState = QuestionState | ErrorState | JobErrorState | CompletedState
type JobMessageResponse = { jobId: string; title: string; message: string }
type Error = { message: string }

export function useMoveToRecycleBinJob() {
  const { languageId } = useLanguage()
  const { t: translate } = useTranslation()
  const apolloClient = useApolloClient()
  const [moveToRecycleBin, { loading: loading1 }] = useMoveToRecycleBinMutation()
  const [continueDelete, { loading: loading2 }] = useContinueDeleteMutation()

  const startJob = useCallback(
    (nodesToDelete: string[]) => {
      return moveToRecycleBin({
        variables: { input: { nodesToDelete: nodesToDelete }, languageId: languageId },
      })
        .then(({ data }): JobState => {
          if (data && isTypename("AskDeleteNodesJobResponse")(data?.moveToRecycleBin)) {
            const state: QuestionState = { state: "question", response: data.moveToRecycleBin }
            return state
          } else if (data && isTypename("DeleteNodesErrorJobResponse")(data?.moveToRecycleBin)) {
            const state: JobErrorState = { state: "jobError", response: data.moveToRecycleBin }
            return state
          } else if (data && isTypename("Error")(data?.moveToRecycleBin)) {
            const state: ErrorState = { state: "error", error: { message: data.moveToRecycleBin.message } }
            return state
          }

          throw "Unexpected data"
        })
        .catch((rejectionReason) => {
          console.log(rejectionReason)

          const state: ErrorState = { state: "error", error: { message: translate(keys.message.error.generic) } }
          return state
        })
    },
    [moveToRecycleBin, languageId, translate]
  )

  const confirmJob = useCallback(
    (jobId: string) => {
      return continueDelete({
        variables: { input: { continue: true, jobId, data: "Yes" } },
        awaitRefetchQueries: true,
        refetchQueries: ["NavigationTree"],
        onCompleted: (_) => {
          setTimeout(() => {
            apolloClient.refetchQueries({ include: ["NavigationTree"] })
          }, 1)
        },
      })
        .then(({ data }) => {
          if (data && isTypename("Error")(data.continueDelete)) {
            const state: ErrorState = { state: "error", error: { message: data.continueDelete.message } }
            return state
          }

          const state: CompletedState = { state: "completed" }
          return state
        })
        .catch((rejectionReason) => {
          console.log(rejectionReason)

          const state: ErrorState = { state: "error", error: { message: translate(keys.message.error.generic) } }
          return state
        })
    },
    [continueDelete, apolloClient, translate]
  )

  const cancelJob = useCallback(
    (jobId: string) => {
      continueDelete({
        variables: { input: { continue: false, jobId } },
      }).catch((rejectionReason) => {
        console.log(rejectionReason)
      })
    },
    [continueDelete]
  )

  const confirmError = useCallback(
    (jobId: string) => {
      continueDelete({
        variables: { input: { continue: true, jobId } },
        refetchQueries: ["NavigationTree"],
      }).catch((rejectionReason) => {
        console.log(rejectionReason)
      })
    },
    [continueDelete]
  )

  return {
    startJob,
    confirmJob,
    cancelJob,
    confirmError,
    loading: loading1 || loading2,
  }
}

export function useDeleteTreeAllowedLazy(): (nodeId: string) => Promise<boolean> {
  const { languageId } = useLanguage()
  const [query] = useDeleteTreeAllowedLazyQuery()

  const getDeleteTreeAllowed = useCallback(
    (nodeId: string) => {
      return query({
        variables: {
          input: nodeId ?? "",
          languageId: languageId,
        },
        fetchPolicy: "network-only",
      })
        .then((value) => {
          if (value.loading) throw "query should be finished before continue occurs"

          return value.data?.deleteTreeAllowed ?? false
        })
        .catch((rejectionReason) => {
          console.log(rejectionReason)
          return false
        })
    },
    [languageId, query]
  )

  return getDeleteTreeAllowed
}
