import { SearchRefinement as gqlRefinement } from "@st4/graphql"

export type SearchRefinement = {
  key: string
  type: number
  label: string
  value: string
}

export function toGraphQLRefinement(refinement: SearchRefinement): gqlRefinement {
  return {
    key: refinement.key,
    type: refinement.type,
    value: refinement.value,
  } as gqlRefinement
}
