import React from "react"
import { ConfigProvider, theme as antdTheme } from "antd"
import ThemeManager from "./themeManager"
import { ThemeManagerTheme } from "./quanosTheme"
import { createGlobalStyle, ThemeProvider as StyledComponentsThemeProvider } from "styled-components"
import type { SeedToken } from "antd/es/theme/internal"
import type { MapToken, GlobalToken } from "antd/es/theme/interface"
import { darken, lighten } from "polished"

const GlobalThemeStyles = createGlobalStyle`
  * {
    scrollbar-width: thin;
  }

  &::-webkit-scrollbar {
    width: 8px;
    border-radius: 4px;
    height: 8px;
    background-color: hsl(0deg 0% 0% / 4%);
  }

  &::-webkit-scrollbar:hover {
    background-color: hsl(0deg 0% 0% / 7%);
  }

  &::-webkit-scrollbar-thumb {
    background-color: hsl(0deg 0% 0% / 20%);
    border-radius: 4px;
  }
`

function ThemeProviderWithAntDToken({ children, theme }: React.PropsWithChildren<{ theme: ThemeManagerTheme }>) {
  const { token } = antdTheme.useToken()
  return <StyledComponentsThemeProvider theme={{ ...theme, token }}>{children}</StyledComponentsThemeProvider>
}

function quanosTokenAlgorythmLight(token: SeedToken, derivateToken: MapToken | undefined): MapToken {
  if (!derivateToken) throw "derivateToken not provided! Don't use this algorythm as first algo in theme."
  const themeManagerTheme = ThemeManager.find("defaultTheme")

  // Anpassung der AntD-MapTokens
  return {
    ...derivateToken,
    colorPrimaryBg: themeManagerTheme.verySubtleBlue,
    colorPrimaryBgHover: themeManagerTheme.subtleBlue,
    colorWarningTextActive: darken(0.3, derivateToken.colorWarning),
    colorSuccessTextActive: darken(0.3, derivateToken.colorSuccess),
    colorErrorTextActive: darken(0.15, derivateToken.colorError),
    colorInfoBg: lighten(0.51, derivateToken.colorInfo),
    colorInfoTextActive: darken(0.2, derivateToken.colorInfo),
    colorPrimaryText: themeManagerTheme.regularBlue,
  }
}

/**
 * Provides the configuration for AntD (Tokens) and for styled-components.
 *
 * More infos: https://ant.design/theme-editor and https://ant.design/docs/react/customize-theme
 */
export function ThemeProvider({
  children,
  themeOverrides,
}: React.PropsWithChildren<{ themeOverrides?: Record<string, unknown> }>) {
  const themeManagerTheme = ThemeManager.find("defaultTheme")

  return (
    <>
      <GlobalThemeStyles />
      <ConfigProvider
        theme={{
          algorithm: [antdTheme.defaultAlgorithm, quanosTokenAlgorythmLight],
          token: {
            // Values are from Quanos Design Document
            fontSize: 14,
            fontSizeHeading1: 24,
            fontSizeHeading2: 20,
            fontSizeHeading3: 18,
            fontSizeHeading4: 16,
            fontSizeHeading5: 14,
            // TODO: Anpassungen die vorher Variablen waren auf Tokens umsteFllen.
            colorPrimary: themeManagerTheme.primaryColor,
            borderRadius: 2,
            colorSuccess: themeManagerTheme.success,
            colorWarning: themeManagerTheme.warning,
            colorError: themeManagerTheme.errorColor,
            colorInfo: themeManagerTheme.regularBlue,
            colorText: themeManagerTheme.midnightBlue,
            screenXXLMin: 1920,
            screenXXL: 1920,
            colorBorder: themeManagerTheme.lightGrey,
            colorTextSecondary: themeManagerTheme.osloGrey,
          },
          components: {
            Layout: {
              colorBgHeader: themeManagerTheme.primaryColor,
            },
            Tooltip: {
              colorBgDefault: themeManagerTheme.veryDarkBlue,
            },
            Button: {
              borderRadius: 4,
            },
          },
        }}
      >
        <ThemeProviderWithAntDToken theme={{ ...themeManagerTheme, ...themeOverrides }}>
          {children}
        </ThemeProviderWithAntDToken>
      </ConfigProvider>
    </>
  )
}
