import { SafetyModel, isTypename } from "@st4/graphql"
import { ImageInfo, SafetyIconInfo, SafetyIconsByTypeInfo, SafetyTypeInfo, SeverityLevelInfo } from "./types"
import { GetMediaSource } from "../utilities/mediaInfoUtilities"

export function getSafetyModelConfig(safetyModel: SafetyModel) {
  const safetyTypeInfo: SafetyTypeInfo[] = []
  const safetyIcons: SafetyIconsByTypeInfo[] = []
  const safetyImageInfos: ImageInfo[] = []
  const severityLevelInfo: SeverityLevelInfo[] = []

  safetyModel?.safetyTypes.forEach((safetyType) => {
    const safetyIconInfo: SafetyIconInfo[] = safetyType.icons.map((icon) => {
      const iconInfo: SafetyIconInfo = {
        id: safetyType.id ?? "",
        src: "",
        thumbnail: "",
        iconName: icon.iconName ?? "",
        isRequired: icon.use === "required" ? true : false,
      }
      if (
        isTypename("ST4Node")(icon?.contentNode) &&
        isTypename("MediaContent")(icon.contentNode?.content) &&
        icon.contentNode.content.media
      ) {
        iconInfo.src = GetMediaSource(icon.contentNode?.content.media) ?? ""

        if (!isTypename("NoMediaInfo")(icon.contentNode?.content.media)) {
          iconInfo.thumbnail = icon.contentNode.content.media?.thumbnail ?? ""
        }
      }

      return iconInfo
    })
    const requiredIcons = safetyIconInfo.filter((icon) => icon.isRequired)
    const iconUrls = requiredIcons.map((icon) => icon.src ?? "")
    const iconThumbnails = requiredIcons.map((icon) => icon.thumbnail ?? "")
    safetyIcons.push({ type: safetyType.id ?? "", urls: iconUrls, thumbnails: iconThumbnails })

    safetyIconInfo
      .filter((icon) => !icon.isRequired)
      .map((icon) => {
        safetyImageInfos.push({
          id: icon.iconName ?? "",
          thumbnail: icon.thumbnail,
        })
      })

    safetyTypeInfo.push({
      id: safetyType.id ?? "",
      displayName: (safetyType.displayName || safetyType.id) ?? "",
      iconsInfo: safetyIconInfo,
    })
  })

  safetyModel?.severityLevels.forEach((severityLevel) => {
    severityLevelInfo.push({
      id: severityLevel.id ?? "",
      displayName: (severityLevel.displayName || severityLevel.id) ?? "",
    })
  })

  return { safetyTypeInfo, safetyImageInfos, safetyIcons, severityLevelInfo }
}
