import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import styled from "styled-components"
import { getDragAndDropInfo, useDragAndDrop } from "../../dnd"
import { debounce } from "../../utils"
import { TreeViewItemsProps } from "../base"
import { CanDropInformation, DropInsertionType, IndentedTreeNode } from "../types"
import { DraggableTreeViewNode, DraggableTreeViewNodeProps } from "./draggabletreeviewnode"
import { useNodeDescendantTester } from "../base/useNodeParentMap"

export type DraggableTreeViewItemsProps = TreeViewItemsProps &
  Pick<DraggableTreeViewNodeProps, "onDrop" | "queryCanDropDraggedItemsOnVisibleNodes">

export function DraggableTreeViewItems({
  nodes,
  treeState,
  onClick,
  queryCanDropDraggedItemsOnVisibleNodes,
  onDrop,
  meatballMenu,
  createButton,
}: DraggableTreeViewItemsProps) {
  const [isDragging, setIsDragging] = useState(false)
  const visualTreeRootRef = useRef<HTMLDivElement>(null)
  const draganddrop = useDragAndDrop()
  const [canDropInformation, setCanDropInformation] = useState<CanDropInformation>({ type: "empty" })
  const [dropInsertionType, setDropInsertionType] = useState<DropInsertionType | null>(null)
  const { draggedOverItem, draggedItem, endDrag } = draganddrop
  const { changeIsExpanded, container, isExpanded, checked, onCheckChange } = treeState
  const [hoveredNode, setHoveredNode] = useState<IndentedTreeNode["node"]["id"] | null>(null)
  const nodeDescendantTester = useNodeDescendantTester(treeState)

  const handleMouseUp = useCallback(() => {
    if (draggedItem) {
      setIsDragging(false)
      endDrag()
      setCanDropInformation({ type: "empty" })
    }
  }, [draggedItem, endDrag])

  if (
    isDragging &&
    canDropInformation.type !== "empty" &&
    nodes.findIndex((node) => !canDropInformation.queriedItems.has(node.node.id)) > -1
  ) {
    setCanDropInformation({
      type: "requestingUpdate",
      queriedItems: new Set<string>(nodes.map((node) => node.node.id)),
      allowedItems: canDropInformation.type === "loading" ? new Set<string>() : canDropInformation.allowedItems,
    })
  }

  const dragOverToggleTimeout = useRef<ReturnType<typeof setTimeout>>()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleDragOverToggle = useCallback(
    debounce((node: IndentedTreeNode) => {
      const currentIsExpanded = isExpanded(node.node.id)
      if (node.hasChildren && !currentIsExpanded && dropInsertionType === "INSERT_AS_DESCENDANT") {
        const { draggedOverItem } = getDragAndDropInfo()
        if (draggedOverItem && draggedOverItem?.id === node.node.id) {
          changeIsExpanded(node.node.id, !currentIsExpanded)
        }
      }
    }, 700),
    [dragOverToggleTimeout, getDragAndDropInfo, changeIsExpanded, isExpanded, dropInsertionType],
  )

  useEffect(() => {
    window.addEventListener("mouseup", handleMouseUp)
    return () => {
      window.removeEventListener("mouseup", handleMouseUp)
    }
  }, [handleMouseUp])

  function isParentHovered(indentedTreeNode: IndentedTreeNode) {
    return (!!hoveredNode && nodeDescendantTester(hoveredNode, indentedTreeNode.node.id)) ?? false
  }

  return (
    <StyledTreeView ref={visualTreeRootRef}>
      {nodes.map((indentedTreeNode) => {
        return (
          <DraggableTreeViewNode
            key={indentedTreeNode.node.id}
            item={indentedTreeNode}
            isSelected={indentedTreeNode.node.id === treeState.selected}
            onClick={onClick}
            onHoverChange={(hovered) => {
              hovered ? setHoveredNode(indentedTreeNode.node.id) : setHoveredNode(null)
            }}
            isParentHovered={isParentHovered(indentedTreeNode)}
            isExpanded={isExpanded(indentedTreeNode.node.id)}
            onExpandChange={changeIsExpanded}
            checkable={!!checked}
            isChecked={checked?.includes(indentedTreeNode.node.id) ?? false}
            onCheckChange={onCheckChange}
            treeState={treeState}
            visualTreeRootRef={visualTreeRootRef}
            dndcontextdata={draganddrop}
            canDropInformation={canDropInformation}
            onCanDropInformationChange={setCanDropInformation}
            canDrag={indentedTreeNode.indentation > 0}
            isDraggedItem={draggedItem?.id === indentedTreeNode.node.id}
            isDraggedOverItem={draggedOverItem?.id === indentedTreeNode.node.id}
            onDragEnter={handleDragOverToggle}
            onDragEnd={() => setIsDragging(false)}
            queryCanDropDraggedItemsOnVisibleNodes={queryCanDropDraggedItemsOnVisibleNodes}
            onDrop={onDrop}
            onIsDraggingChange={setIsDragging}
            dropInsertionType={
              draggedOverItem?.container === container && draggedOverItem?.id === indentedTreeNode.node.id
                ? dropInsertionType
                : null
            }
            onDropInsertionTypeChange={setDropInsertionType}
            meatballMenu={meatballMenu}
            createButton={createButton}
          />
        )
      })}
    </StyledTreeView>
  )
}

const StyledTreeView = styled.div`
  position: relative;
`
