import React from "react"
import type { XASTNode } from "../../ast/types"
import { Divider, Popover, Typography } from "antd"
import { useTranslation } from "react-i18next"
import { keys } from "@st4/ui-strings"
import { Icon, Regular } from "@st4/icons"

type IndexEntryMapperProps = {
  ast: XASTNode
}

export function IndexEntryMapper({ ast }: IndexEntryMapperProps) {
  if (!ast.attributes) return null
  const { key, ["key-sort"]: keySort, parent, ["parent-sort"]: parentSort } = ast.attributes

  const hasSubEntry = !!parent
  const entry = hasSubEntry ? parent : key
  const entrySort = hasSubEntry ? parentSort : keySort
  const subEntry = hasSubEntry ? key : undefined
  const subEntrySort = hasSubEntry ? keySort : undefined
  return (
    <IndexEntry
      entry={entry?.value}
      entrySort={entrySort?.value}
      subEntry={subEntry?.value}
      subEntrySort={subEntrySort?.value}
    />
  )
}

type IndexEntryProps = {
  entry?: string
  entrySort?: string
  subEntry?: string
  subEntrySort?: string
}

export function IndexEntry(props: IndexEntryProps) {
  const { t } = useTranslation()
  const { entry, entrySort, subEntry, subEntrySort } = props
  const tooltipContent = (
    <>
      <div>
        <Typography.Text type="secondary">{t(keys.label.general.indexentry.key)}:</Typography.Text> {entry}
      </div>
      {entrySort && (
        <div>
          <Typography.Text type="secondary">{t(keys.label.general.indexentry.sort)}:</Typography.Text> {entrySort}
        </div>
      )}
      {subEntry && (
        <>
          <Divider style={{ margin: "0.5em 0" }} />
          <div>
            <Typography.Text type="secondary">{t(keys.label.general.indexentry.subEntryKey)}:</Typography.Text>{" "}
            {subEntry}
          </div>
          {subEntrySort && (
            <div>
              <Typography.Text type="secondary">{t(keys.label.general.indexentry.sort)}:</Typography.Text>{" "}
              {subEntrySort}
            </div>
          )}
        </>
      )}
    </>
  )
  return (
    <Popover placement="bottomLeft" title={t(keys.label.general.indexentry._)} content={tooltipContent}>
      {" "}
      <Icon component={Regular.InboxFull} />
    </Popover>
  )
}
