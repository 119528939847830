import React from "react"
import { z } from "zod"
import { BladeSize } from "@st4/ui"
import { Message, MessageHub } from "@st4/message-hub"
import { JsonObject, zodJsonObjectSchema } from "../json"

export const bladeDataSchema = zodJsonObjectSchema
export type BladeData = z.infer<typeof bladeDataSchema>

export type DefaultMessages =
  | {
      /** Sent when the blade is collapsed */
      action: "collapseChange"
      /** Indicates whether the blade is now collapsed (true) or opened (false) */
      payload: boolean
    }
  | {
      /** Sent when the temporary blade is closed */
      action: "closeTemporaryBlade"
      /** The "closed" state of the temporary blade */
      payload: true
    }

type BaseProps<TMessages extends Message = Message> = {
  /**
   * A function used to send messages to the message hub.
   * Those messages are passed to the reducer of the blade and the screen.
   */
  sendMessage: MessageHub<TMessages>["sendMessage"]
  /**
   * The message hub for the curren blade.
   * (Could be used to observe for messages)
   */
  messageHub: MessageHub<TMessages>
  /**
   * The key of the blade.
   */
  key?: string
}

type BladeProps<TProps extends JsonObject, TMessages extends Message = Message> = TProps & BaseProps<TMessages>

//Fehleranfällig, da der Typ `BladeDefinition` hier noch mal dupliziert wurde!
//Sollte sich eine Änderung in einem Typen ergeben, muss dieser hier nachgezogen werden.
export type GenericBladeDefinition = React.FC<BaseProps> & {
  reducer?: (previousState: BladeData, message: DefaultMessages) => BladeData | undefined
  title: React.ReactNode
  size?: BladeSize | ((props: BladeData) => BladeSize)
}

/**
 * Type defining a blade.
 * @template TProps The type of the blades props. They have to be JSON-serializable, because they are persisted inside the
 * task.
 * @template TMessages A union type of messages which will be sent from this blade.
 * Those can be handled inside the blades or screens reducer.
 */
export type BladeDefinition<TProps extends JsonObject = JsonObject, TMessages extends Message = Message> = React.FC<
  BladeProps<TProps, TMessages>
> & {
  /**
   * The reducer for the blade.
   * @param state The current state of the blade.
   * @param message The message sent by the blade.
   * @returns The new State for the blade.
   */
  reducer?: (state: TProps, message: TMessages | DefaultMessages) => TProps | undefined
  /** A title for the blade. */
  title: React.ReactNode
  /** The blades size inside the grid. */
  size?: BladeSize | ((props: TProps) => BladeSize)
}

/** Returns the messages of a blade definition */
export type GetMessages<TBlade> = TBlade extends BladeDefinition<any, infer TMessages> ? TMessages : never
/** Returns the props of a blade definition */
export type GetProps<TBlade> = TBlade extends BladeDefinition<infer TProps, any> ? TProps : never

/**
 * Defines a blade.
 * @param comp The react compoenent which renders the blades contents.
 */
export function defineBlade<TBladeProps extends JsonObject, TBladeMessages extends Message>(
  title: React.ReactNode,
  comp: React.FunctionComponent<BladeProps<TBladeProps, TBladeMessages>>,
): BladeDefinition<TBladeProps, TBladeMessages> {
  const Component = comp as BladeDefinition<TBladeProps, TBladeMessages>
  Component.title = title
  return Component
}

/**
 * Defines a blade which is memoized. It only renders if the props are different then the previous render.
 * @param comp The react compoenent which renders the blades contents.
 * @param propsAreEqual (optional) {@link https://react.dev/reference/react/memo#specifying-a-custom-comparison-function compare the passed props},
 *  if they are equal (true) the component won't rerender.
 * @link https://react.dev/reference/react/memo
 */
export function defineMemoizedBlade<TBladeProps extends JsonObject, TBladeMessages extends Message>(
  title: React.ReactNode,
  comp: React.FunctionComponent<BladeProps<TBladeProps, TBladeMessages>>,
  propsAreEqual?: (prevProps: TBladeProps, nextProps: TBladeProps) => boolean,
): BladeDefinition<TBladeProps, TBladeMessages> {
  const memoizedComp = React.memo(comp, propsAreEqual) as React.FunctionComponent<
    BladeProps<TBladeProps, TBladeMessages>
  >
  const Component = defineBlade(title, memoizedComp)
  return Component
}
