import * as React from "react"
import { SVGProps } from "react"

const SvgSt4RemoveReuseLight = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 641 512" width="1em" height="1em" {...props}>
    <path d="M25.9 2.9C19-2.5 8.9-1.3 3.4 5.6s-4.2 17 2.7 22.5l608 480c6.9 5.5 17 4.3 22.5-2.6s4.3-17-2.6-22.5L25.9 2.9zM355.5 216h29c17.7 0 32-14.3 32-32v-49.3c0-3.7 3-6.7 6.7-6.7 1.9 0 3.7.8 5 2.2L540.7 256l-55.6 62.2 25.2 19.8 60.9-68c3.4-3.8 5.3-8.8 5.3-14s-1.9-10.1-5.3-14L452 108.9c-7.3-8.2-17.8-12.9-28.8-12.9-21.4 0-38.7 17.3-38.7 38.7V184h-69.6l40.6 32zm-69.7 80H112.5c-8.8 0-16-7.2-16-16v-48c0-8.8 7.2-16 16-16h71.8l-40.6-32h-31.2c-26.5 0-48 21.5-48 48v48c0 26.5 21.5 48 48 48h213.9l-40.6-32zm98.7 77.8v3.5c0 21.4 17.3 38.7 38.7 38.7 4.2 0 8.3-.7 12.3-2l-51-40.2z" />
  </svg>
)

export default SvgSt4RemoveReuseLight
