import React, { useEffect, useCallback } from "react"
import { SelectionContextProvider } from "../selection"
import { MappingContextProvider } from "./mappingContext"
import { getMapping } from "../mapping/mappingRegistry"
import { ST4NodeWithContent, useContent } from "../graphql/applicationQueries"
import { PreviewContentModelContext, useContentModelFromQueryResult } from "../contentModel"
import type { PreviewContentModel } from "../contentModel/types"
import { EmptyPreviewContentModel } from "../contentModel/types"
import { SimpleErrorBoundary } from "./Errors"
import type { NavigationContextData } from "./navigationContext"
import { NavigationContextProvider } from "./navigationContext"
import { NodeDisplay, PreviewConfigContextProvider, PreviewConfigContextValues } from "./PreviewConfig"
import { PreviewParameterContext, PreviewParameterContextProvider } from "./PreviewParameterContext"
import type { Node } from "unist"
import { FocusStateContextProvider } from "./annotationFocusState"
import { NodeSelectionContextProvider } from "./NodeSelectionContext"
import { ContentTarget, SCROLL_VAR_NODE_ID_TOP_POS } from "./Scrolling"
import type { ContentSelection } from "../types"
import type { TreeNode } from "../contentModel/nodeContext"

export type ContentProviderProps = {
  rootNodeId?: string
  languageId: string
  limit?: number
  onNavigate?: NavigationContextData
  onNodeClick?: (node: TreeNode) => void
  onRootChange?: (node: TreeNode | null) => void
  onHover?: (node: Node, contentNode: ST4NodeWithContent) => void
  mode: PreviewConfigContextValues["mode"]
  disableMetadataDrawer?: boolean
  collapsibleComments?: PreviewConfigContextValues["collapsibleComments"]
  initialCommentsCollapsed?: boolean
  getNodeDisplay?: (treeNodeId: string) => NodeDisplay
  /**
   * This callback gets invoked every time the top most visible node changes (e.g. on scrolling).
   */
  onTopNodeChanged?: (contentTarget: ContentTarget) => void
  scrollTarget?: ContentTarget
  initialScrollTarget?: ContentTarget
  refetchOnEditorSave?: string[]
} & PreviewParameterContext

export function ContentProvider({
  rootNodeId,
  languageId,
  onNavigate,
  onNodeClick,
  onRootChange,
  onHover,
  children,
  mode,
  disableMetadataDrawer,
  collapsibleComments,
  initialCommentsCollapsed,
  getNodeDisplay,
  onTopNodeChanged,
  scrollTarget,
  refetchOnEditorSave,
  ...previewParameterProps
}: React.PropsWithChildren<ContentProviderProps>) {
  return rootNodeId ? (
    <BasicPreviewProvider
      key={rootNodeId}
      onRootChange={onRootChange}
      rootNodeId={rootNodeId}
      languageId={languageId}
      onNodeClick={onNodeClick}
      onNavigate={onNavigate}
      onHover={onHover}
      mode={mode}
      disableMetadataDrawer={disableMetadataDrawer}
      collapsibleComments={collapsibleComments}
      initialCommentsCollapsed={initialCommentsCollapsed}
      getNodeDisplay={getNodeDisplay}
      onTopNodeChanged={onTopNodeChanged}
      scrollTarget={scrollTarget}
      refetchOnEditorSave={refetchOnEditorSave}
      {...previewParameterProps}
    >
      {children}
    </BasicPreviewProvider>
  ) : (
    <BasicPreviewProviderBody
      onRootChange={onRootChange}
      previewContentModel={EmptyPreviewContentModel}
      onNavigate={onNavigate}
      onHover={() => {}}
      selection={null}
      setSelection={() => {}}
    >
      {children}
    </BasicPreviewProviderBody>
  )
}

function BasicPreviewProvider({
  rootNodeId,
  languageId,
  limit,
  onNodeClick,
  onRootChange,
  onNavigate,
  onHover,
  children,
  mode,
  disableMetadataDrawer,
  collapsibleComments,
  initialCommentsCollapsed,
  getNodeDisplay,
  onTopNodeChanged,
  scrollTarget,
  refetchOnEditorSave,
  ...previewParameterProps
}: React.PropsWithChildren<ContentProviderProps> & PreviewParameterContext) {
  const response = useContent(rootNodeId, languageId, limit || 10)
  const previewContentModel = useContentModelFromQueryResult(rootNodeId, languageId, response, getNodeDisplay)

  useEffect(() => {
    if (!onTopNodeChanged) return
    const callback = onTopNodeChanged
    let active = true
    function watcher(target: ContentTarget | null) {
      if (!active) return
      if (target) {
        callback(target)
      }
      SCROLL_VAR_NODE_ID_TOP_POS.onNextChange(watcher)
    }
    SCROLL_VAR_NODE_ID_TOP_POS.onNextChange(watcher)
    return () => {
      active = false
    }
  }, [onTopNodeChanged])

  const nodeDisplayResolver = useCallback(
    (treeNodeId: string) => getNodeDisplay?.(treeNodeId) ?? { visible: true },
    [getNodeDisplay],
  )

  return (
    <PreviewParameterContextProvider
      rootNodeId={rootNodeId}
      languageId={languageId}
      refetchOnEditorSave={refetchOnEditorSave}
      {...previewParameterProps}
    >
      <BasicPreviewProviderBody
        rootNodeId={rootNodeId}
        onRootChange={onRootChange}
        onNavigate={onNavigate}
        onHover={onHover}
        previewContentModel={previewContentModel}
        selection={previewParameterProps.selection}
        setSelection={(selection: ContentSelection) =>
          previewParameterProps.messageHub.sendMessage({ action: "selectionChanged", payload: selection })
        }
      >
        <PreviewConfigContextProvider
          disableMetadataDrawer={!!disableMetadataDrawer}
          mode={mode}
          collapsibleComments={collapsibleComments}
          initialCommentsCollapsed={initialCommentsCollapsed}
          getNodeDisplay={nodeDisplayResolver}
        >
          <FocusStateContextProvider>{children}</FocusStateContextProvider>
        </PreviewConfigContextProvider>
      </BasicPreviewProviderBody>
    </PreviewParameterContextProvider>
  )
}

type Props = {
  rootNodeId?: string
  previewContentModel: PreviewContentModel
  onNavigate?: NavigationContextData
  onRootChange?: (node: TreeNode | null) => void
  onHover?: ContentProviderProps["onHover"]
  selection: ContentSelection | null
  setSelection?: (selection: ContentSelection | null) => void
}

function BasicPreviewProviderBody({
  rootNodeId,
  onNavigate,
  onRootChange,
  onHover,
  children,
  previewContentModel,
  selection,
  setSelection,
}: React.PropsWithChildren<Props>) {
  let selectedNode: TreeNode | undefined = undefined
  function setSelectedNode(node: TreeNode | undefined) {
    if (setSelection) {
      setSelection(
        node
          ? {
              treeNodeId: node?.id,
              nodeId: node?.node.id,
            }
          : null,
      )
    }
  }
  useEffect(() => {
    if (onRootChange) {
      if (!rootNodeId) {
        onRootChange(null)
      } else if (previewContentModel.state === "empty") {
        onRootChange(null)
      } else if (previewContentModel.state === "loading") {
        onRootChange(null)
      } else {
        const rootNode = previewContentModel.treeNodesById.get(rootNodeId)
        if (rootNode) {
          onRootChange(rootNode)
        }
        if (selectedNode && !previewContentModel.treeNodesById.get(selectedNode.id)) {
          setSelectedNode(undefined)
        }
      }
    }
  }, [rootNodeId, previewContentModel.state, selectedNode])

  if (selection && previewContentModel.state === "ready") {
    selectedNode = previewContentModel.treeNodesById.get(selection.treeNodeId)
  }
  const navigateFn = onNavigate ?? ((target: any) => {})

  return (
    <SimpleErrorBoundary>
      <NavigationContextProvider onNavigate={navigateFn}>
        <PreviewContentModelContext.Provider value={previewContentModel}>
          <NodeSelectionContextProvider selectNode={setSelectedNode} selectedNode={selectedNode}>
            <SelectionContextProvider onHover={onHover}>
              <MappingContextProvider mapping={getMapping("COMPONENT")}>{children}</MappingContextProvider>
            </SelectionContextProvider>
          </NodeSelectionContextProvider>
        </PreviewContentModelContext.Provider>
      </NavigationContextProvider>
    </SimpleErrorBoundary>
  )
}
