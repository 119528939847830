import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import CaretDown from "@schema/react-ionicons/components/MdArrowDropdown"
import CaretUp from "@schema/react-ionicons/components/MdArrowDropup"
import { lighten } from "polished"

export const Label = styled.div`
  color: rgba(255, 255, 255, 0.7);
  cursor: pointer;
  text-decoration: none;
  padding: 14px;
  user-select: none;

  &:hover,
  &.open {
    color: rgba(255, 255, 255, 1);
  }
`

const VerticalMenu = styled.ul`
  z-index: 1;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  & li > *:hover {
    background: ${(p) => lighten(0.35, p.theme.primaryColor)};
  }
`

const StyledDropdown = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  position: relative;

  ${Label} {
    position: relative;
    svg {
      position: absolute;
      bottom: 3px;
      left: calc(50% - 5px);
    }

    &.open svg {
      position: absolute;
    }
  }

  ${Label}.open,
  ${Label}:hover {
    background: ${(p) => lighten(0.1, p.theme.primaryColor)};
  }

  ${VerticalMenu} {
    position: absolute;
    right: 0;
    top: 53px;
    border-radius: 3px;
    overflow: hidden;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    background: white;
  }
`

class Dropdown extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showMenu: false,
    }

    this.showMenu = this.showMenu.bind(this)
    this.closeMenu = this.closeMenu.bind(this)
  }

  showMenu(event) {
    event.preventDefault()

    this.setState({ showMenu: true }, () => {
      document.addEventListener("click", this.closeMenu)
    })
  }

  closeMenu() {
    this.setState({ showMenu: false }, () => {
      document.removeEventListener("click", this.closeMenu)
    })
  }
  render() {
    let iconColor = this.state.showMenu ? "white" : lighten(0.1, "#ffffff")
    const { items, label } = this.props
    const { showMenu } = this.state
    return (
      <StyledDropdown>
        <Label className={showMenu ? "open" : "closed"} onClick={this.showMenu}>
          {label}
          {showMenu ? (
            <CaretUp cursor="pointer" color={iconColor} size="12px" />
          ) : (
            <CaretDown cursor="pointer" color={iconColor} size="12px" />
          )}
        </Label>

        {showMenu ? (
          <VerticalMenu>
            {items.map((i, idx) => (
              <li key={idx}>{i}</li>
            ))}
          </VerticalMenu>
        ) : null}
      </StyledDropdown>
    )
  }
}
Dropdown.displayName = "Navbar_Dropdown"
Dropdown.propTypes = {
  label: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.element).isRequired,
}
export default Dropdown
