import React from "react"
import styled from "styled-components"

import LoginLogoSVG from "./loginlogo.svg"
import LogoSVG from "./logo.svg"
import DotsSVG from "./dots.svg"

export const LoginLogo = styled(({ src, className }) => {
  return (
    <div className={className}>
      <div />
    </div>
  )
})`
  background: linear-gradient(41deg, rgba(0, 65, 125, 1) 0%, rgba(0, 65, 125, 1) 30%, rgba(4, 37, 74, 1) 100%);
  height: 55px;
  div {
    width: 100%;
    height: 100%;
    background: url(${LoginLogoSVG});
    background-size: 70%;
    background-position: center;
    background-repeat: no-repeat;
  }
`

export const Logo = styled.div`
  background: url(${LogoSVG});
  background-repeat: no-repeat;
  width: 127px;
  height: 42px;
  background-position: left center;
  background-size: cover;
`
export const LogoSmall = styled(Logo)`
  width: 42px;
`

export const DotsLeft = styled.div`
  background: url(${DotsSVG});
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  background-position: left bottom;
  background-size: 90%;
`
