import * as React from "react"
import { SVGProps } from "react"

const SvgSt4InsertglossaryLight = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1em" height="1em" {...props}>
    <path d="M242.4 437.1c0-41.4 33.5-74.9 74.9-74.9h30c8.3 0 15 6.7 15 15s-6.7 15-15 15h-30c-24.8 0-44.9 20.1-44.9 44.9s20.1 44.9 44.9 44.9h30c8.3 0 15 6.7 15 15s-6.7 15-15 15h-30c-41.4 0-74.9-33.5-74.9-74.9zm269.6 0c0 41.4-33.5 74.9-74.9 74.9h-30c-8.3 0-15-6.7-15-15s6.7-15 15-15h30c24.8 0 44.9-20.1 44.9-44.9s-20.1-44.9-44.9-44.9h-30c-8.3 0-15-6.7-15-15s6.7-15 15-15h30c41.4 0 74.9 33.5 74.9 74.9zm-179.7-15h89.9c8.3 0 15 6.7 15 15s-6.7 15-15 15h-89.9c-8.3 0-15-6.7-15-15s6.7-15 15-15zM237.2 480H176c-8.8 0-16-7.2-16-16v-48h68.9c2.8-11.7 7.8-22.5 14.6-32H160V128h96v242c9.1-8.4 20-14.9 32-19V193.8l39.5 152.4h33l-50.1-193.4 87.3-24.2v.1l56.9 219.2c13.9 2.7 26.7 8.6 37.6 16.9l-63.5-244.5-4-15.5-18-69.2C400.1 10 374.7-5.1 350 1.7l-59.6 16.5c-3.5 1-6.8 2.3-9.9 4C271.9 8.8 257 0 240 0h-64c-12.3 0-23.5 4.6-32 12.2C135.5 4.6 124.3 0 112 0H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h64c12.3 0 23.5-4.6 32-12.2 8.5 7.6 19.7 12.2 32 12.2h64c7.1 0 13.9-1.6 20-4.4-9.3-7.5-17.1-16.9-22.8-27.6zM288 63.5c.3-7.2 5.1-13 10.8-14.6l59.6-16.5c6.6-1.8 14.8 2 17.2 11l14.1 54.2-87.3 24.2-13.8-53.2c-.3-1.3-.5-2.6-.6-3.8v-1.3zM160 48c0-8.8 7.2-16 16-16h64c8.8 0 16 7.2 16 16v48h-96V48zm-32 416c0 8.8-7.2 16-16 16H48c-8.8 0-16-7.2-16-16v-48h96v48zm0-80H32V128h96v256zm0-288H32V48c0-8.8 7.2-16 16-16h64c8.8 0 16 7.2 16 16v48z" />
  </svg>
)

export default SvgSt4InsertglossaryLight
