import { Button, ButtonProps } from "antd"
import React from "react"
import { Icon, Regular } from "@st4/icons"
import ThemeManager from "@st4/theme-manager"

export type QuanosButtonProps = ButtonProps & Record<string, unknown>

export function QuanosBlueButton(props: QuanosButtonProps) {
  const theme = ThemeManager.find("defaultTheme")
  const disabledButtonStyle = {
    background: theme.snowGrey,
    color: theme.silverSand,
    border: theme.lightGrey,
  }
  return (
    <QuanosButton {...props} type={"primary"} style={props.disabled ? disabledButtonStyle : undefined}>
      {props.children}
    </QuanosButton>
  )
}

export function QuanosForwardButton(props: QuanosButtonProps) {
  return (
    <QuanosBlueButton {...props}>
      <span>
        {props.children}
        <span style={{ marginLeft: "8px", fontSize: "16px", verticalAlign: "middle" }}>
          <Icon component={Regular.CircleArrowRight} />
        </span>
      </span>
    </QuanosBlueButton>
  )
}

export function QuanosBackButton(props: QuanosButtonProps) {
  return (
    <QuanosBlueButton {...props}>
      <span>
        {props.children}
        <span style={{ marginLeft: "8px", fontSize: "16px", verticalAlign: "middle" }}>
          <Icon component={Regular.CircleArrowLeft} />
        </span>
      </span>
    </QuanosBlueButton>
  )
}

export function QuanosButton(props: ButtonProps) {
  return <Button {...props}>{props.children}</Button>
}
