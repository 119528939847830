import { TaskContext } from "@st4/graphql"
import { ComponentType, ReactNode, SVGProps } from "react"
import { z } from "zod"
import { isError } from "../hooks/useAsyncHandler"
import { migrate } from "../migration"
import { GenericScopeDefinition, scopeDataSchema, ScopeDefinition } from "./scope"

type SingletonAndVersion =
  | {
      /**
       * Indicating wether instances of this task are considered as singleton. (default: `false`)
       *
       * A singleton task has the following properties:
       *
       * - State is only inside the current browser tab
       * - State will reset on logout/login
       * - Id is the `name` of the task (also in url)
       * - Are allways "startable" by requesting their urls.
       */
      singleton: false
      /**
       * The version of the tasks state. Migrations will execute if this doesn't match the persistate states version.
       */
      taskStateVersion: number
    }
  | {
      /**
       * Indicating wether instances of this task are considered as singleton. (default: `false`)
       *
       * A singleton task has the following properties:
       *
       * - State is only inside the current browser tab
       * - State will reset on logout/login
       * - Id is the `name` of the task (also in url)
       * - Are allways "startable" by requesting their urls.
       */
      singleton: true
    }

/**
 * The type used to define a task.
 */
export type TaskDefinition<TScopeDef extends ScopeDefinition | GenericScopeDefinition = ScopeDefinition> = {
  /**
   * The scopes which are part of this task.
   *
   * Use `defineScope` to get the correct definition.
   */
  scopes: Record<string, TScopeDef | undefined>
  /** The name of the scope which should be shown if this is the first execution of this task. */
  initialScope: keyof TaskDefinition<TScopeDef>["scopes"]
  /** The display name of this task. */
  displayName: ReactNode | ((data: TaskData) => ReactNode)
  /** A description for this task. */
  description?: ReactNode | ((data: TaskData) => ReactNode)
  /**
   * Indicating wether instances are listed in the collection of currently running tasks on the on the dashboard (true).
   *
   */
  //TODO: **Maybe refactor? Feels akward inside the task definition, because only affects the dashboard.**
  resumable: boolean
  /**
   * Indicating if a button to start the task is shown on the dashboard (true).
   *
   */
  //TODO: **Maybe refactor? Feels akward inside the task definition, because only affects the dashboard.**
  manuallyStartable: boolean
  /** A (system) name for the task. */
  name: string
  /** Icon for this task. */
  icon: ComponentType<SVGProps<SVGSVGElement>>
} & SingletonAndVersion

export const taskDataSchema = z.object({
  currentScope: z.string(),
  scopes: z.record(scopeDataSchema.optional()),
  version: z.number(), // Either `taskStateVersion` of task definition or -1 for singleton tasks.
})

export type TaskData = z.infer<typeof taskDataSchema>

/**
 * This function is used to define a task.
 * @param definition The task definition
 */
export function defineTask<TScopeDef extends ScopeDefinition | GenericScopeDefinition = ScopeDefinition>(
  definition: TaskDefinition<TScopeDef>,
): TaskDefinition {
  return definition
}

function mapObject<A extends Record<string, unknown>, B>(obj: A, fn: (key: keyof A, val: A[keyof A]) => B) {
  return Object.keys(obj).reduce((o, key) => {
    return { ...o, [key]: fn(key as keyof A, obj[key as keyof A]) }
  }, {})
}
/**
 * Returns the initial context values of the given task.
 * @param definition The taskdefinition for wich the initial values should be returned.
 * @returns The initial context values of the given task.
 */
export function getInitialContextValues(definition: TaskDefinition): TaskData {
  return {
    version: definition.singleton ? -1 : definition.taskStateVersion,
    currentScope: definition.initialScope,
    scopes: mapObject(definition.scopes, (_, scopeDefinition) => ({
      currentScreen: "start",
      screens: mapObject(
        scopeDefinition?.screens ?? {},
        (__, screenDefinition) => screenDefinition?.initialContextValues,
      ),
    })),
  }
}

/**
 * Parses and returns the TaskData of the given task context.
 * @param taskDefinition The task definition of the task context
 * @param taskContext The (persisted) task ontext of the instance
 * @returns The parsed (and validated) task data of the instance. If undefined was passed or no data exist,
 * the initial values of the task will be returned.
 */
export function getTaskData(taskDefinition: TaskDefinition, taskContext?: Pick<TaskContext, "data">): TaskData {
  const parsedJson = taskContext?.data ? JSON.parse(taskContext?.data) : null
  if (!parsedJson) return getInitialContextValues(taskDefinition)
  const parsedTaskDataCurrentVersion = taskDataSchema.safeParse(parsedJson)
  if (parsedTaskDataCurrentVersion.success) return parsedTaskDataCurrentVersion.data
  if (taskDefinition.singleton) return getInitialContextValues(taskDefinition)
  try {
    if (parsedJson.version !== taskDefinition.taskStateVersion) {
      const migrated = migrate(parsedJson, taskDefinition)
      return migrated
    }
  } catch (e) {
    console.warn(`${isError(e) ? e.message : e} Returning initial task values.`)
    return getInitialContextValues(taskDefinition)
  }
  console.warn("Could not parse task data. Returning initial task values.", parsedTaskDataCurrentVersion.error)
  return getInitialContextValues(taskDefinition)
}
