import classNames from "classnames"
import React from "react"
import TextInput_deprecated from "./TextInput_deprecated"
import PropTypes from "prop-types"
import { doubleRegex } from "@schema/utils"
import ThemeManager from "@st4/theme-manager"

// prettier-ignore
const TextInputDouble_deprecated = function(props) {
  return <TextInput_deprecated
    {...props}
    className={classNames(props.className, "TextInputDouble")}
    type="text"
    pattern={doubleRegex}
    min={Number.MIN_VALUE}
    max={Number.MAX_VALUE}
  />;
}

TextInputDouble_deprecated.propTypes = {
  className: PropTypes.string,
  defaultValue: PropTypes.shape({
    _data: PropTypes.string,
    _display: PropTypes.string,
  }),
  val: PropTypes.shape({
    _data: PropTypes.string,
    _display: PropTypes.string,
  }),
  onChange: PropTypes.func,
  name: PropTypes.string,
  onKeyPress: PropTypes.func,
  pattern: PropTypes.string,
  placeholder: PropTypes.string,
  readonly: PropTypes.bool,
  label: PropTypes.string,
}

export default TextInputDouble_deprecated
