// ------------------- automatic exports -------------------
export {default as keys} from "./keys"
export {default as de} from "./Strings.de"
export {default as fr} from "./Strings.fr"
export {default as ja} from "./Strings.ja"
export {default as en} from "./Strings.en"
// ----------------- end automatic exports -----------------

export function createTranslationArgs(messageArgs) {
  const parameterList = {}

  for (let i in messageArgs) {
    parameterList[i] = messageArgs[i]
  }

  return parameterList
}