import * as Apollo from "@apollo/client"
import {
  SingleNodeQuery,
  SingleNodeQueryVariables,
  useSingleNodeQuery,
} from "../graphql/applicationQueries/query.hooks"
import { usePreviewContentModel } from "./contentModel"
import { useNodeFilter } from "../components/FilteringTreeNodeWrapper"
import { useState } from "react"
import { isTypename } from "@st4/graphql"

export function useSingleNodeQueryWithContentImport(
  baseOptions: Apollo.QueryHookOptions<SingleNodeQuery, SingleNodeQueryVariables>,
): ReturnType<typeof useSingleNodeQuery> {
  const { data, ...rest } = useSingleNodeQuery(baseOptions)
  const contentModel = usePreviewContentModel()
  const nodeFilter = useNodeFilter()
  const [prevData, setPrevData] = useState<SingleNodeQuery | undefined>()

  if (data && contentModel.state === "ready" && isTypename("ST4Node")(data.node) && data !== prevData) {
    contentModel.importSt4Node(data.node, nodeFilter)
    setPrevData(data)
  }
  return { data, ...rest }
}
