import classNames from "classnames"
import React, { PropsWithChildren } from "react"
import * as R from "ramda"
import { Header_deprecated, Text_deprecated } from "../../index"
import styled, { StyledProps } from "styled-components"
import { useTranslation } from "react-i18next"
import { keys } from "@st4/ui-strings"

function ErrorMessageContainer(props: StyledProps<PropsWithChildren<ErrorMessageProps & { className: string }>>) {
  const { t } = useTranslation()
  const { className, errors } = props
  const unifiedErrors = R.is(Array)(errors)
    ? (errors as Array<string | { message: string }>)
    : ([errors] as Array<string>)
  return (
    <div className={classNames(className, "ErrorMessage")}>
      {props.header ? (
        <Header_deprecated className="ErrorMessage--Header">
          <Text_deprecated>{t(keys.label.warning.error)}</Text_deprecated>
        </Header_deprecated>
      ) : null}
      {unifiedErrors.map((error, idx) => (
        <div className="ErrorMessage--error" key={idx}>
          {isString<{ message: string }>(error) ? error : error.message}
        </div>
      ))}
      <div className="ErrorMessage--actions">{props.children}</div>
    </div>
  )
}
function isString<TOther>(val: string | TOther): val is string {
  return typeof val === "string"
}

type ErrorMessageProps = {
  header?: boolean
  errors: string | Array<string | { message: string }>
}

const ErrorMessage_deprecated = styled(ErrorMessageContainer)`
  & .ErrorMessage--header {
    color: ${(props) => props.theme.warningColor};
  }
  border: 2px solid ${(props) => props.theme.warningColor};
  border-radius: 3px;
  padding: 1.5em;
  margin: 1em;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  & > .ErrorMessage--actions {
    display: flex;
  }
`

export default ErrorMessage_deprecated
