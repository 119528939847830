import React, { ComponentProps, ReactElement } from "react"
import { Translate } from "@st4/i18n"
import { Tooltip as AntDTooltip } from "antd"
import type {} from "@st4/theme-manager"

/**
 * The Props
 */
type TooltipProps = ComponentProps<typeof AntDTooltip> & {
  /**
   * The translation key
   */
  translationKey?: string
  children: ReactElement | string
}

/**
 * A Tooltip, rendered by hovering over the passed child, which displays text. Additionally it can
 * be rendered by only passing an translation key.
 */
export function Tooltip(props: TooltipProps) {
  const { translationKey, children, placement, title, align } = props
  const child =
    typeof children === "string" ? (
      <span data-testid={translationKey}>{children}</span>
    ) : (
      React.cloneElement(children, { "data-testid": translationKey })
    )
  return (
    <>
      <AntDTooltip
        title={title ? title : translationKey ? <Translate>{translationKey}</Translate> : null}
        placement={placement}
        align={align}
      >
        {child}
      </AntDTooltip>
    </>
  )
}
