import { notEmpty, TaskContext } from "@st4/graphql"
import { LOGIN_STATE_VAR } from "@st4/authentication"
import { getAllTaskDefinitions, getTaskDefinition } from "../taskRegistry"
import { getInitialContextValues, TaskDefinition } from "../definition/task"
import { useEffect, useMemo, useState } from "react"
import { z } from "zod"

export const SINGLETON_TASK_ID_PREFIX = `singletontask:`

const singletonTaskChangedEventName = "singletonTaskChanged" as const

const taskContextSchema = z.object({
  id: z.string(),
  name: z.string(),
  createdAt: z.string(),
  data: z.string(),
  closed: z.boolean(),
  owner: z.object({ username: z.string(), __typename: z.literal("UserInfo") }),
  __typename: z.literal("TaskContext"),
})

export function loadSingletonTaskState(taskName: string): TaskContext | undefined {
  const definition = getTaskDefinition(taskName)
  if (!definition || !definition.singleton) {
    return
  }
  const fromStorage = sessionStorage.getItem(SINGLETON_TASK_ID_PREFIX + taskName)
  if (!fromStorage) return
  const fromJson = JSON.parse(fromStorage)
  return taskContextSchema.parse(fromJson)
}

declare global {
  interface DocumentEventMap {
    singletonTaskChanged: SingletonTaskEvent
  }
}

interface SingletonTaskEvent extends Event {
  id: string //Id des SingletonTasks
  data: TaskContext
}

export async function saveSingletonTaskState(state: TaskContext) {
  const definition = getTaskDefinition(state.name)
  if (!definition || !definition.singleton) {
    return
  }
  sessionStorage.setItem(SINGLETON_TASK_ID_PREFIX + state.name, JSON.stringify(state))
  return await sendEvent(definition, state)
}

/**
 * Watches for changes on a singleton task. (Returns null if not singleton task)
 * @param taskName The name of the Task (returns null if not valid singleton taks)
 * @returns the value of the task (updates if taskcontext updates)
 */
export function useSingletonTask(taskName?: string): TaskContext | undefined {
  const [task, setTask] = useState<TaskContext | undefined>(taskName ? getOrCreateSingletonTask(taskName) : undefined)

  useEffect(() => {
    const listener = (evt: SingletonTaskEvent) => {
      if (evt.id !== taskName) return
      setTask(() => loadSingletonTaskState(taskName))
    }
    document.addEventListener(singletonTaskChangedEventName, listener)
    return () => document.removeEventListener(singletonTaskChangedEventName, listener)
  }, [taskName])
  return task
}

export function useRunningSingletonTasks() {
  const allTaskDefinitions = useMemo(() => getAllTaskDefinitions().filter((def) => def.singleton), [])

  const [runningLocalSingletonTasks, setRunningLocalSingletonTasks] = useState<TaskContext[]>(() =>
    allTaskDefinitions.map((def) => loadSingletonTaskState(def.name)).filter(notEmpty),
  )

  useEffect(() => {
    const listener = () => {
      const runningTasks = allTaskDefinitions.map((def) => loadSingletonTaskState(def.name)).filter(notEmpty)
      setRunningLocalSingletonTasks(runningTasks)
    }
    document.addEventListener(singletonTaskChangedEventName, listener)
    return () => document.removeEventListener(singletonTaskChangedEventName, listener)
  }, [allTaskDefinitions])

  return runningLocalSingletonTasks
}

export function getOrCreateSingletonTask(taskName?: string) {
  if (!taskName) return
  const definition = getTaskDefinition(taskName)
  if (!definition?.singleton) return
  const existing = loadSingletonTaskState(taskName)
  if (existing) return existing
  const loginState = LOGIN_STATE_VAR()
  const username = loginState.state == "loggedIn" ? loginState.sessionData.username : ""
  const instance = {
    id: taskName,
    name: taskName,
    createdAt: new Date().toISOString(),
    data: JSON.stringify(getInitialContextValues(definition)),
    closed: false,
    owner: { username, __typename: "UserInfo" as const },
    __typename: "TaskContext" as const,
  }
  saveSingletonTaskState(instance)
  return instance
}

export async function closeSingletonTask(id: string) {
  const definition = getTaskDefinition(id)
  if (definition?.singleton) {
    const taskcontext = loadSingletonTaskState(id)
    if (!taskcontext) return
    const closed = { ...taskcontext, closed: true }
    // close the tasks first, then remove it...
    // allows to redirect to dashboard if singleton task is closed
    await saveSingletonTaskState(closed)

    sessionStorage.removeItem(SINGLETON_TASK_ID_PREFIX + definition.name)
    // await sendEvent(definition, closed)
    return closed
  }
}

async function sendEvent(definition: Pick<TaskDefinition, "name">, state: TaskContext) {
  const evt = new Event(singletonTaskChangedEventName, { cancelable: false, bubbles: false }) as SingletonTaskEvent
  evt.id = definition.name
  evt.data = state
  // Without Timeout:
  // Cannot update a component (`Tasks`) while rendering a different component (`Screen`)
  return new Promise<TaskContext>((resolve) => {
    setTimeout(() => document && document.dispatchEvent(evt) && resolve(state), 0)
  })
}

export function runningSingletonTasks() {
  return getAllTaskDefinitions()
    .filter((def) => def.singleton)
    .map((def) => loadSingletonTaskState(def.name))
    .filter(notEmpty)
}

/**
 * Indicates whether the taskContext is part of a singleton task
 * @param taskContext The taskContext to check
 * @returns `true` if the taskContext belongs to a singleton task.
 */
export function isSingletonTaskContext(taskContext: { id: string }) {
  return getAllTaskDefinitions()
    .filter((def) => def.singleton)
    .some((def) => def.name === taskContext.id) // the name of a singleton task is the id of its context
}
