import React, { useMemo } from "react"
import styled from "styled-components"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { keys } from "@st4/ui-strings"
import { usePreviewContentModel } from "../../contentModel"
import { Button } from "antd"
import { AddCommentInput } from "../commentEditor/AddCommentInput"
import { AnnotationDisplay, AnnotationReply } from "./AnnotationDisplay"
import { AnnotationActions } from "./AnnotationActions"
import { ExpandingText } from "./ExpandingText"
import { useToggle } from "../../utilities/hooks"
import { useCommentFactory } from "../commentActions"
import { AnnotationDetailView } from "./annotationDetailView"
import { useCommentTypeGuiString } from "../../utilities/commentUtilities"
import { PersistanceAction } from "@st4/graphql"
import type { CommentInfoFieldsFragment, CommentNodeFragment } from "./query.hooks"
import { Icon, Regular } from "@st4/icons"
import { useReactiveVar } from "@apollo/client"
import { CURRENT_CONTENT_TARGET_INNER } from "../Scrolling"

const IndicatorIconContainer = styled.div`
  position: absolute;
  right: 5px;
  top: 5px;
`

const WarningText = styled.div`
  padding-right: 30px;
  padding-left: 10px;
  padding-top: 10px;
  color: ${({ theme }) => theme.preview.annotations.errorColor["500"]};
  font-size: 12px;
`

function Indicator({ annotation }: { annotation: CommentNodeFragment }) {
  const currentContentTarget = useReactiveVar(CURRENT_CONTENT_TARGET_INNER)
  if (currentContentTarget === annotation.id) {
    return (
      <IndicatorIconContainer>
        <Icon component={Regular.SpinnerThird} spin={true} style={{ color: "hsla(0,0%,0%,0.4)" }} />
      </IndicatorIconContainer>
    )
  }
  if (annotation.comment.persistanceState == "LOCAL") {
    return (
      <IndicatorIconContainer>
        <Icon component={Regular.CloudArrowUp} style={{ color: "hsla(0,0%,0%,0.4)" }} />
      </IndicatorIconContainer>
    )
  }
  if (annotation.comment.persistanceState == "ERROR") {
    return (
      <IndicatorIconContainer>
        <Icon component={Regular.TriangleExclamation} style={{ color: "hsla(0, 100%, 50%, 0.5)" }} />
      </IndicatorIconContainer>
    )
  }
  return null
}

function getErrorMessageKey(persistanceAction?: PersistanceAction | null) {
  if (!persistanceAction) return keys.message.error.generic
  switch (persistanceAction) {
    case "CHANGESTATE":
      return keys.message.error.changeCommentState
    case "ADD":
      return keys.message.error.addComment
    case "REMOVE":
      return keys.message.error.removeComment
    case "EDIT":
    default:
      return keys.message.error.generic
  }
}

function WarningMessage({ annotation }: { annotation: CommentInfoFieldsFragment }) {
  const { t } = useTranslation()
  if (annotation.persistanceState === "ERROR") {
    const errorMessage = t(getErrorMessageKey(annotation.persistanceAction))
    return <WarningText>{errorMessage}</WarningText>
  }
  return null
}

type AnnotationBaseProps = {
  annotation: CommentNodeFragment
  isFocused: boolean
  onPointerOver?: (event: React.PointerEvent<HTMLDivElement>) => void
  onPointerOut?: (event: React.PointerEvent<HTMLDivElement>) => void
}

export type AnnotationPropsWithReplies = AnnotationBaseProps & {
  replies: CommentNodeFragment[]
  onShowRepliesClick: () => void
  showReplies: boolean
}
export type AnnotationProps = AnnotationBaseProps | AnnotationPropsWithReplies

export const Annotation = React.memo(UnmemoizedAnnotation)

function UnmemoizedAnnotation(props: AnnotationProps) {
  const annotation = props.annotation.comment

  const { t } = useTranslation()
  const previewContentModel = usePreviewContentModel()
  const [showReplyEditor, toggleReplyEditor] = useToggle(false)
  const [showDetailView, toggleDetailView] = useToggle(false)
  const [detailViewId, setDetailViewId] = useState(0)
  const onAction = useMemo(
    () => ({
      ReplyToComment: toggleReplyEditor,
      EditComment: toggleDetailView,
    }),
    [toggleReplyEditor, toggleDetailView],
  )
  const { addReply } = useCommentFactory()
  const type = useCommentTypeGuiString(annotation.commentType)
  if (previewContentModel.state !== "ready") return null
  return (
    <div
      id={`${props.annotation.id}_CommentCard`}
      onPointerOver={(e) => props.onPointerOver && props.onPointerOver(e)}
      onPointerOut={(e) => props.onPointerOut && props.onPointerOut(e)}
    >
      <WarningMessage annotation={annotation} />
      <AnnotationDisplay type={type} author={annotation.authorDisplayName ?? ""} date={annotation.editDate}>
        <ExpandingText>{annotation.value}</ExpandingText>
      </AnnotationDisplay>
      <AnnotationActions annotation={annotation} onAction={onAction} />
      {showReplyEditor && (
        <ReplyEditor
          onSave={(text, type) => {
            const reply: Parameters<typeof addReply>[1] = {
              value: text,
              color: previewContentModel.user.commentColor,
              commentType: type || "",
              authorUserName: previewContentModel.user.username,
            }
            !(props as AnnotationPropsWithReplies).showReplies &&
              (props as AnnotationPropsWithReplies).onShowRepliesClick &&
              (props as AnnotationPropsWithReplies).onShowRepliesClick()
            addReply(annotation, reply)
            toggleReplyEditor()
          }}
        />
      )}
      {hasReplies(props) && props.replies.length > 0 && (
        <AnnotationReply>
          <Button
            icon={<Regular.ChevronDown rotate={props.showReplies ? 0 : -90} />}
            type="text"
            block
            style={{ gridColumn: "1 / span 2" }}
            onClick={() => props.onShowRepliesClick()}
          >
            {t(keys.label.preview.answerCount, { count: props.replies.length })}
          </Button>
          {props.showReplies && (
            <div>
              {props.replies.map((reply) => (
                <Annotation key={reply.comment.commentKey} annotation={reply} isFocused={props.isFocused} />
              ))}
            </div>
          )}
        </AnnotationReply>
      )}
      <Indicator annotation={props.annotation} />
      <AnnotationDetailView
        key={`detailView_${detailViewId}`}
        visible={showDetailView}
        editableInitial
        onClose={() => toggleDetailView()}
        afterClose={() => setDetailViewId(detailViewId + 1)}
        annotation={annotation}
      />
    </div>
  )
}

function hasReplies(props: AnnotationProps): props is AnnotationPropsWithReplies {
  return !!(props as any).replies
}

const Wrapper = styled.div`
  margin-bottom: 0;
`

type ReplyEditorProps = {
  onAbort?: (text?: string, type?: string) => void
  onSave: (text: string, type?: string) => void
}
function ReplyEditor(props: ReplyEditorProps) {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <AddCommentInput.WithTypes
        placeholder={t(keys.label.comment.placeholder.reply)}
        maxHeight={350}
        onSave={props.onSave}
      />
    </Wrapper>
  )
}
