import * as Types from "@st4/graphql"

import { gql } from "@apollo/client"
import * as Apollo from "@apollo/client"
const defaultOptions = {} as const
export type CreateVersionAllowedQueryVariables = Types.Exact<{
  languageId?: Types.InputMaybe<Types.Scalars["AspectSelectorId"]>
  target: Types.Scalars["StPatternContextId"]
}>

export type CreateVersionAllowedQuery = {
  __typename: "Query"
  commands: Array<
    | { __typename: "AllowedCommand"; command: Types.CommandName; warnings?: Array<string> | null }
    | { __typename: "ForbiddenCommand"; command: Types.CommandName; reasons?: Array<string> | null }
  >
}

export type CreateVersionMutationVariables = Types.Exact<{
  languageId?: Types.InputMaybe<Types.Scalars["AspectSelectorId"]>
  target: Types.Scalars["StPatternContextId"]
}>

export type CreateVersionMutation = {
  __typename: "Mutation"
  executeCommand:
    | { __typename: "CommandExecutionFailure"; command: Types.CommandName; reasons?: Array<string> | null }
    | { __typename: "CommandExecutionPatternContextSuccess"; command: Types.CommandName; result?: Array<any> | null }
    | { __typename: "CommandExecutionSuccess" }
}

export const CreateVersionAllowedDocument = gql`
  query CreateVersionAllowed($languageId: AspectSelectorId, $target: StPatternContextId!) {
    commands(input: [{ command: CREATE_VERSION }], context: [{ source: [$target] }], languageId: $languageId) {
      __typename
      ... on AllowedCommand {
        command
        warnings
      }
      ... on ForbiddenCommand {
        command
        reasons
      }
    }
  }
`

/**
 * __useCreateVersionAllowedQuery__
 *
 * To run a query within a React component, call `useCreateVersionAllowedQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreateVersionAllowedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreateVersionAllowedQuery({
 *   variables: {
 *      languageId: // value for 'languageId'
 *      target: // value for 'target'
 *   },
 * });
 */
export function useCreateVersionAllowedQuery(
  baseOptions: Apollo.QueryHookOptions<CreateVersionAllowedQuery, CreateVersionAllowedQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CreateVersionAllowedQuery, CreateVersionAllowedQueryVariables>(
    CreateVersionAllowedDocument,
    options,
  )
}
export function useCreateVersionAllowedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CreateVersionAllowedQuery, CreateVersionAllowedQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CreateVersionAllowedQuery, CreateVersionAllowedQueryVariables>(
    CreateVersionAllowedDocument,
    options,
  )
}
export type CreateVersionAllowedQueryHookResult = ReturnType<typeof useCreateVersionAllowedQuery>
export type CreateVersionAllowedLazyQueryHookResult = ReturnType<typeof useCreateVersionAllowedLazyQuery>
export type CreateVersionAllowedQueryResult = Apollo.QueryResult<
  CreateVersionAllowedQuery,
  CreateVersionAllowedQueryVariables
>
export const CreateVersionDocument = gql`
  mutation CreateVersion($languageId: AspectSelectorId, $target: StPatternContextId!) {
    executeCommand(languageId: $languageId, input: { command: CREATE_VERSION }, context: { source: [$target] }) {
      __typename
      ... on CommandExecutionPatternContextSuccess {
        command
        result
      }
      ... on CommandExecutionFailure {
        command
        reasons
      }
    }
  }
`
export type CreateVersionMutationFn = Apollo.MutationFunction<CreateVersionMutation, CreateVersionMutationVariables>

/**
 * __useCreateVersionMutation__
 *
 * To run a mutation, you first call `useCreateVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVersionMutation, { data, loading, error }] = useCreateVersionMutation({
 *   variables: {
 *      languageId: // value for 'languageId'
 *      target: // value for 'target'
 *   },
 * });
 */
export function useCreateVersionMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateVersionMutation, CreateVersionMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateVersionMutation, CreateVersionMutationVariables>(CreateVersionDocument, options)
}
export type CreateVersionMutationHookResult = ReturnType<typeof useCreateVersionMutation>
export type CreateVersionMutationResult = Apollo.MutationResult<CreateVersionMutation>
export type CreateVersionMutationOptions = Apollo.BaseMutationOptions<
  CreateVersionMutation,
  CreateVersionMutationVariables
>
