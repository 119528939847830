import React from "react"
import TextInput_deprecated from "./TextInput_deprecated"
import PropTypes from "prop-types"
import ThemeManager from "@st4/theme-manager"

// prettier-ignore
const TextInputPassword_deprecated = function(props) {
  return <TextInput_deprecated
    className="TextInputPassword"
    {...props}
    type="password"
  />;
}

TextInputPassword_deprecated.propTypes = {
  className: PropTypes.string,
  defaultValue: PropTypes.shape({
    _data: PropTypes.string,
    _display: PropTypes.string,
  }),
  val: PropTypes.shape({
    _data: PropTypes.string,
    _display: PropTypes.string,
  }),
  onChange: PropTypes.func,
  name: PropTypes.string,
  onKeyPress: PropTypes.func,
  pattern: PropTypes.string,
  placeholder: PropTypes.string,
  readonly: PropTypes.bool,
  label: PropTypes.string,
}

export default TextInputPassword_deprecated
