import { Tooltip, TreeSelect } from "antd"
import { CommonTaxonomyTreeSelectProps, TaxonomyDisplayInfo, TreeNode } from "./types"
import React from "react"
import { MarkAllSelectedNodes } from "./labelModificationFunctions"
import { Translate } from "@st4/i18n"
import { keys } from "@st4/ui-strings"

export function SingleSelectionTaxonomyTreeSelect({
  saveOrReset,
  treeData,
  isInheritedValue,
  treeSelectProps,
}: CommonTaxonomyTreeSelectProps) {
  const selectHandler = (_value: TaxonomyDisplayInfo, treeNode: TreeNode) => {
    // due to unknown reasons, _value is of type string when this callback is executed. Therefore, the TaxonomyDisplayInfo object for the selected TreeNode has to be manually created from treeNode
    const taxonomyDisplayInfo: TaxonomyDisplayInfo = {
      value: treeNode.value,
      label: treeNode.titleString ?? "",
      closable: treeNode.checkable,
    }
    saveOrReset([taxonomyDisplayInfo])
  }

  const deselectHandler = (_value: TaxonomyDisplayInfo, treeNode: TreeNode) => {
    // due to unknown reasons, _value is of type string when this callback is executed. Therefore, the deselected value has to be retrieved from treeNode
    const selection = treeSelectProps.value
    saveOrReset(selection.filter((selectedValue) => selectedValue.value !== treeNode.value))
  }

  const displayTree = MarkAllSelectedNodes(treeData, treeSelectProps.value)

  return (
    <Tooltip
      title={
        isInheritedValue && !treeSelectProps.open && <Translate>{keys.message.properties.valueInherited}</Translate>
      }
    >
      <span>
        <TreeSelect
          {...treeSelectProps}
          onSelect={selectHandler}
          onDeselect={deselectHandler}
          treeCheckable={false}
          treeCheckStrictly={false}
          treeData={displayTree}
          disabled={false}
        />
      </span>
    </Tooltip>
  )
}
