import React from "react"
import { JSONTree } from "@schema/styled-ui"

/**
 * If the current environment is production, discard the passed children. Otherwise render them.
 */

export function Debug({ children }: React.PropsWithChildren<any>) {
  if (window.location.search.indexOf("debug=1") >= 0) return children
  if (process.env.NODE_ENV === "production") return null
  return children
}

export function DebugData({ json, children }: React.PropsWithChildren<{ json: any }>) {
  return (
    <Debug>
      {children}
      <br />
      <JSONTree data={json} />
    </Debug>
  )
}
