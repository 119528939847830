import * as React from "react"
import { SVGProps } from "react"

const SvgCircleDownLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1em" height="1em" {...props}>
    <path d="M256 48a208 208 0 1 0 0 416 208 208 0 1 0 0-416zm0 464a256 256 0 1 1 0-512 256 256 0 1 1 0 512zm-96-313.4c0-12.5 10.1-22.6 22.6-22.6 6 0 11.8 2.4 16 6.6L232 216l66.3-66.3c3.6-3.6 8.5-5.7 13.7-5.7s10 2 13.7 5.7l36.7 36.7c3.6 3.6 5.7 8.5 5.7 13.7s-2 10-5.7 13.7L296 280l33.4 33.4c4.2 4.2 6.6 10 6.6 16 0 12.5-10.1 22.6-22.6 22.6H176c-8.8 0-16-7.2-16-16V198.6z" />
  </svg>
)

export default SvgCircleDownLeft
