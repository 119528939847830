import styled from "styled-components"

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px;
`

export const HorizontalContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
`

export const PoolContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  width: 33%;
`

export const VerticalLine = styled.div`
  width: 1px;
  background-color: #ccc;
  margin: 0 8px;
`

export const ActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  width: 67%;
  flex-grow: 1;
`

export const SelectMetaRefContainer = styled.div`
  margin: 8px 0px;
`

export const TreeContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  height: 500px;
`
