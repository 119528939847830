import React, { useContext, useMemo } from "react"

type BannerButton<TValue> = {
  label: React.ReactNode
  /** This value will be returned. */
  value: TValue
}

/**
 * Display a notification.
 * @param state The state of the notification.
 * @param title The title for the notification.
 * @param description (optional) A description, further describeing the notification.
 */
declare function ShowNotificationFn(
  state: "info" | "success" | "warning" | "error",
  title: string,
  description?: string,
): void

export type ShowNotification = typeof ShowNotificationFn

/**
 * Request a confirmation from the user.
 * @param {"info"} state This confirm banner will be with state "info".
 * @param {string} title The title should be short and descriptive.
 * @param {string} description The description should contain enough information to assist in making a proper decision.
 * @param {BannerButton<TValue>} primary Options for primary button.
 * @param {BannerButton<TValue>} [secondary] (optional) Options for secondary button.
 * @async
 * @returns {TValue} The value associated with either `primary` or `secondary`.
 * @throws When confirmation dialog closes without a prior button press.
 *
 * @example
 * try {
 *   const result = await showConfirm(
 *                          "info",
 *                          "Confirm me!",
 *                          "Further describing text",
 *                          { label: "Okay", value: "ok" }
 *                        )
 *   alert(`${result} button pressed`) // result: "ok" or "abort" (based on button.value)
 * catch {
 *   alert(`no confirmation`)
 * }
 */
declare function ShowConfirmFn<TValue>(
  state: "info",
  title: string,
  description: string,
  primary: BannerButton<TValue>,
  secondary?: BannerButton<TValue>,
): Promise<TValue>

/**
 * Request a confirmation from the user.
 * @param {"warning" | "error" | "question"} state The state for the confirm banner.
 * @param {string} title The title should be short and descriptive.
 * @param {string} description The description should contain enough information to assist in making a proper decision.
 * @param {BannerButton<TValue>} primary Options for the primary button.
 * @param {BannerButton<TValue>} secondary Options for secondary button.
 * @async
 * @returns {TValue} The value associated with either `primary` or `secondary`.
 * @throws When confirmation dialog closes without a prior button press.
 *
 * @example
 * try {
 *   const result = await showConfirm(
 *                          "error",
 *                          "Confirm me!",
 *                          "Further describing text",
 *                          { label: "Okay", value: "ok" }, //primary button
 *                          { label: "Abbrechen", value: "abort" } //secondary button
 *                        )
 *   alert(`${result} button pressed`) // result: "ok" or "abort" (based on button.value)
 * catch {
 *   alert(`no confirmation`)
 * }
 */
declare function ShowConfirmFn<TValue>(
  state: "question" | "warning" | "error",
  title: string,
  description: string,
  primary: BannerButton<TValue>,
  secondary: BannerButton<TValue>,
): Promise<TValue>
export type ShowConfirm = typeof ShowConfirmFn

export type NotificationFunctions = {
  /**
   * Display a notification.
   */
  showNotification: ShowNotification
  /**
   * Request a confirmation from the user.\
   * This function is overloaded, because for state "info", the secondary button is optional.
   */
  showConfirm: ShowConfirm
}

const notificationContext = React.createContext<NotificationFunctions>({
  showNotification() {},
  showConfirm() {
    return Promise.resolve()
  },
})

/**
 * Provide a way to display notifications and confirmations.
 */
export function NotificationProvider(props: React.PropsWithChildren<NotificationFunctions>) {
  const fns = useMemo(
    () => ({
      showConfirm: props.showConfirm,
      showNotification: props.showNotification,
    }),
    [props.showConfirm, props.showNotification],
  )
  const { Provider } = notificationContext
  return <Provider value={fns}>{props.children}</Provider>
}

/**
 * Hook to show notifications or request confirmations.
 *
 * The display of those is based on the implemtation using the `NotificationProvider`.
 *
 * @example
 * try {
 *   const result = await showConfirm(
 *                          "error",
 *                          "Confirm me!",
 *                          "Further describing text",
 *                          { label: "Okay", value: "ok" }, //primary button
 *                          { label: "Abbrechen", value: "abort" } //secondary button
 *                        )
 *   alert(`${result} button pressed`) // result: "ok" or "abort" (based on button.value)
 * catch {
 *   alert(`no confirmation`)
 * }
 *
 * @example showNotification("Info", "Info for you!", "Further describing text.")
 */
export function useNotifications(): NotificationFunctions {
  const ctx = useContext(notificationContext)
  return ctx
}
