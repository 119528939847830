import classNames from "classnames"
import React from "react"
import TextInput_deprecated from "./TextInput_deprecated"
import PropTypes from "prop-types"
import ThemeManager from "@st4/theme-manager"

// prettier-ignore
const TextInputString_deprecated = function(props) {
  return <TextInput_deprecated
    className={classNames(props.className, "TextInputString")}
    {...props}
    type="text"
  />;
}

TextInputString_deprecated.propTypes = {
  className: PropTypes.string,
  defaultValue: PropTypes.shape({
    _data: PropTypes.string,
    _display: PropTypes.string,
  }),
  val: PropTypes.shape({
    _data: PropTypes.string,
    _display: PropTypes.string,
  }),
  onChange: PropTypes.func,
  name: PropTypes.string,
  onKeyPress: PropTypes.func,
  pattern: PropTypes.string,
  placeholder: PropTypes.string,
  readonly: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
}

export default TextInputString_deprecated
