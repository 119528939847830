import * as React from "react"
import { SVGProps } from "react"

const SvgNote = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="1em" height="1em" {...props}>
    <path d="M0 96c0-35.3 28.7-64 64-64h320c35.3 0 64 28.7 64 64v224H336c-26.5 0-48 21.5-48 48v112H64c-35.3 0-64-28.7-64-64V96zm402.7 256H448l-32 32-64 64-32 32V368c0-8.8 7.2-16 16-16h66.7zM112 376a24 24 0 1 0-48 0 24 24 0 1 0 48 0zM88 112a24 24 0 1 0 0 48 24 24 0 1 0 0-48zm24 144a24 24 0 1 0-48 0 24 24 0 1 0 48 0z" />
  </svg>
)

export default SvgNote
