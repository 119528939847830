import * as React from "react"
import { SVGProps } from "react"

const SvgArrowUp = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" width="1em" height="1em" {...props}>
    <path d="M209.4 39.4c-4.6-4.7-10.8-7.4-17.4-7.4s-12.8 2.7-17.4 7.4l-168 176c-9.2 9.6-8.8 24.8.8 33.9s24.8 8.8 33.9-.8L168 115.9V456c0 13.3 10.7 24 24 24s24-10.7 24-24V115.9l126.6 132.7c9.2 9.6 24.3 9.9 33.9.8s9.9-24.3.8-33.9l-168-176z" />
  </svg>
)

export default SvgArrowUp
