import React from "react"
import styled from "styled-components"
import { DebugData } from "./debug"
import { Alert, Collapse } from "antd"
import { useTranslation } from "react-i18next"
import { keys } from "@st4/ui-strings"

const PreserveWhitespace = styled.div`
  white-space: pre;
`
type SimpleErrorBoundaryProps = { message?: string; children?: React.ReactNode }
type SimpleErrorBoundaryState = { hasError: false } | { hasError: true; error: Error }

export class SimpleErrorBoundary extends React.Component<SimpleErrorBoundaryProps, SimpleErrorBoundaryState> {
  constructor(props: SimpleErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error }
  }

  render() {
    if (this.state.hasError) return <SimpleError message={this.props.message} error={this.state.error} />
    return this.props.children
  }
}

type SimpleErrorProps = {
  message?: string
  error?: Error
}
/*
If error is provided a collapsible Debug view will be shown.
The collapsible will be labeled with the message and fall back to the errorMessage
If no error is provided only the message will be shown instead of the collapsible
 */
export function SimpleError({ message, error }: SimpleErrorProps) {
  const { t } = useTranslation()
  const description = error ? (
    <Collapse ghost>
      <Collapse.Panel header={message ?? error.message} key="1">
        <PreserveWhitespace>
          <DebugData json={error} />
        </PreserveWhitespace>
      </Collapse.Panel>
    </Collapse>
  ) : (
    <>{message}</>
  )

  return <Alert message={t(keys.message.error.generic)} showIcon description={description} type="error" closable />
}
