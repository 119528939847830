import React, { createContext, useContext } from "react"
import type { CommentInfoFieldsFragment } from "../CommentList/query.hooks"

export type CommentNode = {
  id: string
  comment: CommentInfoFieldsFragment
}

const CommentCollectionContext = createContext<CommentNode[]>([])

export function CommentCollectionProvider(props: React.PropsWithChildren<{ comments: CommentNode[] }>) {
  const { comments, children } = props
  return <CommentCollectionContext.Provider value={comments}>{children}</CommentCollectionContext.Provider>
}

export function useCommentCollection() {
  return useContext(CommentCollectionContext)
}
