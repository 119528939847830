import * as React from "react"
import { SVGProps } from "react"

const SvgSuperscript = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1em" height="1em" {...props}>
    <path d="M464 16c0-5.1-2.5-10-6.6-13S447.9-.8 443 .8l-48 16c-8.4 2.8-12.9 11.9-10.1 20.2s11.9 12.9 20.2 10.1l26.9-9V160h-32c-8.8 0-16 7.2-16 16s7.2 16 16 16h96c8.8 0 16-7.2 16-16s-7.2-16-16-16h-32V16zM16 64C7.2 64 0 71.2 0 80s7.2 16 16 16h39.2L157 256 55.2 416H16c-8.8 0-16 7.2-16 16s7.2 16 16 16h48c5.5 0 10.6-2.8 13.5-7.4L176 285.8l98.5 154.8c2.9 4.6 8 7.4 13.5 7.4h48c8.8 0 16-7.2 16-16s-7.2-16-16-16h-39.2L195 256 296.8 96H336c8.8 0 16-7.2 16-16s-7.2-16-16-16h-48c-5.5 0-10.6 2.8-13.5 7.4L176 226.2 77.5 71.4c-2.9-4.6-8-7.4-13.5-7.4H16z" />
  </svg>
)

export default SvgSuperscript
