import React from "react"
import styled from "styled-components"
import { DropInsertionType } from "../types"
import { OverflowHidingTooltipShowingSpan } from "../../overflow/overflowhidingspan"

type NodeContentProps = {
  id: string
  label: string | undefined | null
  icon?: string
  onClick: (id: string) => void
  dropInsertionType: DropInsertionType | null
}

export function NodeContent(props: NodeContentProps) {
  const { id, label, icon, onClick } = props

  return (
    <span
      onClick={() => onClick(id)}
      style={{
        userSelect: "none",
        grid: "auto / auto 1fr",
        display: "grid",
        alignItems: "center",
        cursor: "default",
      }}
      className={"selectable"}
    >
      {icon ? (
        <NodeIcon
          className="tree-node-icon"
          draggable={false}
          style={{ marginBottom: "2px", marginTop: "2px", marginLeft: "2px", marginRight: "2px" }}
          src={icon}
        />
      ) : null}{" "}
      <NodeLabel dropInsertionType={props.dropInsertionType}>
        <OverflowHidingTooltipShowingSpan
          title={label}
          placement="right"
          mouseLeaveDelay={0}
          align={{ offset: [55, 0] }}
        >
          <span style={{ cursor: "pointer" }}>{label}</span>
        </OverflowHidingTooltipShowingSpan>
      </NodeLabel>
    </span>
  )
}

const NodeLabel = styled.span<{ dropInsertionType: DropInsertionType | null }>`
  user-select: none;
  border-top: 2px solid transparent;
  border-bottom: ${(p) =>
    p.dropInsertionType === "INSERT_AS_DESCENDANT" ? "2px solid blue" : "2px solid transparent"};
  display: grid;
  margin-left: 4px;
  grid: auto / auto;
  align-items: center;
`

const NodeIcon = styled.img`
  user-selectable: none;
  margin: 0px;
`
