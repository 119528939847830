import React from "react"
import { Alert } from "antd"
import styled from "styled-components"

const Wrapper = styled.div`
  padding: 12px 8px;
`

type ErrorBoundaryProps = Record<string, unknown>
type ErrorBoundaryState = {
  error?: Error
  errorInfo?: React.ErrorInfo
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = {}
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    this.setState({ error, errorInfo })
  }

  render() {
    const { children } = this.props
    const { error, errorInfo } = this.state
    const componentStack = errorInfo && errorInfo.componentStack ? errorInfo.componentStack : null
    const errorMessage = (error || "").toString()
    const errorDescription = componentStack
    if (error) {
      return (
        <Wrapper>
          <Alert type="error" message={errorMessage} description={<pre>{errorDescription}</pre>} />
        </Wrapper>
      )
    }

    return children
  }
}
