import React from "react"
import { IndentedTreeNode } from "../types"
import { Checkbox } from "antd"

export type NodeCheckboxProps = {
  item: IndentedTreeNode
  checkable: boolean
  isChecked: boolean
  onCheckChange?: (id: string, isChecked: boolean) => void
}

export function NodeCheckbox(props: NodeCheckboxProps) {
  return props.checkable ? (
    <Checkbox
      onChange={(ev) => {
        if (props.onCheckChange) {
          props.onCheckChange(props.item.node.id, ev.target.checked)
        }
      }}
      style={{ marginLeft: "5px" }}
      checked={props.isChecked}
    />
  ) : null
}
