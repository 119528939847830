import * as React from "react"
import { SVGProps } from "react"

const SvgSt4CreatefragmentRegular = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1em" height="1em" {...props}>
    <path d="M198.7 464.1h-101c-8.8 0-16-7.2-16-16V64c0-8.8 7.2-16 16-16h160v80c0 17.7 14.3 32 32 32h80v45.7c16.9 2.2 33 6.8 48 13.6v-64.7c0-17-6.7-33.3-18.7-45.3l-90.6-90.5C296.4 6.7 280.2 0 263.2 0H97.7c-35.3 0-64 28.7-64 64v384.1c0 35.3 28.7 64 64 64h143c-16.8-13.3-31-29.5-42-48zm148.5-219.3c-73.8 0-133.6 59.8-133.6 133.6S273.4 512 347.2 512s133.6-59.8 133.6-133.6S421 244.8 347.2 244.8zM416.6 391h-56.8v56.8c0 7-5.6 12.6-12.6 12.6s-12.6-5.6-12.6-12.6V391h-56.8c-7 0-12.6-5.6-12.6-12.6s5.6-12.6 12.6-12.6h56.8V309c0-7 5.6-12.6 12.6-12.6s12.6 5.6 12.6 12.6v56.8h56.8c7 0 12.6 5.6 12.6 12.6 0 6.9-5.6 12.6-12.6 12.6zM235.9 206.6c-5.1-2.6-12.8-2.6-20.4 0l-96.9 33.2c-12.8 5.1-20.4 17.9-20.4 30.6v122.4c0 12.8 7.7 25.5 20.4 33.2l74.3 27.4c-5.4-10.8-9.7-22.2-12.8-34.2l-43.8-15.5V297l49.3 19.3c3.4-9 7.5-17.7 12.3-25.9l-61.5-22.5 89.3-30.6 13.3 5.1c10.6-8.5 22.2-15.7 34.6-21.6l-37.7-14.2z" />
  </svg>
)

export default SvgSt4CreatefragmentRegular
