import * as Types from "@st4/graphql"

import { gql } from "@apollo/client"
import * as Apollo from "@apollo/client"
const defaultOptions = {} as const
export type CommentsForNodesQueryVariables = Types.Exact<{
  aspectId: Types.Scalars["AspectSelectorId"]
  nodeIds: Array<Types.Scalars["ID"]> | Types.Scalars["ID"]
  includeNodeComments: Types.Scalars["Boolean"]
}>

export type CommentsForNodesQuery = {
  __typename: "Query"
  nodes: Array<
    | { __typename: "CommentNode" }
    | { __typename: "ExternalSite" }
    | { __typename: "ST4Class" }
    | { __typename: "ST4Node" }
    | {
        __typename: "ST4TreeNode"
        id: string
        node?: {
          __typename: "ST4Node"
          id: string
          aspectId: any
          properties?: {
            __typename: "ContentPropertyConnection"
            edges?: Array<{
              __typename: "ContentPropertyEdge"
              node?: { __typename: "ContentProperty"; displayName: string; name: string; value?: any | null } | null
            } | null> | null
          } | null
          content?:
            | { __typename: "LexiconContent" }
            | { __typename: "LexiconEntryContent" }
            | { __typename: "MediaContent" }
            | { __typename: "MediaGroupContent" }
            | { __typename: "ProjectContent" }
            | { __typename: "TermContent" }
            | {
                __typename: "TextContent"
                id: string
                historyNumber: number
                fragments: Array<{
                  __typename: "TextContentFragment"
                  compoundId: string
                  contentNode: {
                    __typename: "ST4Node"
                    id: string
                    aspectId: any
                    content?:
                      | { __typename: "LexiconContent" }
                      | { __typename: "LexiconEntryContent" }
                      | { __typename: "MediaContent" }
                      | { __typename: "MediaGroupContent" }
                      | { __typename: "ProjectContent" }
                      | { __typename: "TermContent" }
                      | {
                          __typename: "TextContent"
                          id: string
                          historyNumber: number
                          comments: Array<{
                            __typename: "CommentNode"
                            id: string
                            comment:
                              | {
                                  __typename: "BlockComment"
                                  location: number
                                  value?: string | null
                                  authorDisplayName?: string | null
                                  authorUserName?: string | null
                                  commentKey: string
                                  editDate?: any | null
                                  modificationVersion?: number | null
                                  replyToKey?: string | null
                                  sortOrder?: number | null
                                  commentType?: string | null
                                  state?: string | null
                                  color: string
                                  persistanceState?: Types.PersistanceState | null
                                  persistanceAction?: Types.PersistanceAction | null
                                  node: {
                                    __typename: "ST4Node"
                                    aspectId: any
                                    id: string
                                    content?:
                                      | { __typename: "LexiconContent" }
                                      | { __typename: "LexiconEntryContent" }
                                      | { __typename: "MediaContent" }
                                      | { __typename: "MediaGroupContent" }
                                      | { __typename: "ProjectContent" }
                                      | { __typename: "TermContent" }
                                      | { __typename: "TextContent"; id: string; historyNumber: number }
                                      | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                                      | { __typename: "VariableTableContent" }
                                      | null
                                  }
                                  actions: Array<{
                                    __typename: "Action"
                                    name?: string | null
                                    enabled?: boolean | null
                                  }>
                                }
                              | {
                                  __typename: "TextComment"
                                  endLocation: number
                                  startLocation?: number | null
                                  value?: string | null
                                  authorDisplayName?: string | null
                                  authorUserName?: string | null
                                  commentKey: string
                                  editDate?: any | null
                                  modificationVersion?: number | null
                                  replyToKey?: string | null
                                  sortOrder?: number | null
                                  commentType?: string | null
                                  state?: string | null
                                  color: string
                                  persistanceState?: Types.PersistanceState | null
                                  persistanceAction?: Types.PersistanceAction | null
                                  node: {
                                    __typename: "ST4Node"
                                    aspectId: any
                                    id: string
                                    content?:
                                      | { __typename: "LexiconContent" }
                                      | { __typename: "LexiconEntryContent" }
                                      | { __typename: "MediaContent" }
                                      | { __typename: "MediaGroupContent" }
                                      | { __typename: "ProjectContent" }
                                      | { __typename: "TermContent" }
                                      | { __typename: "TextContent"; id: string; historyNumber: number }
                                      | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                                      | { __typename: "VariableTableContent" }
                                      | null
                                  }
                                  actions: Array<{
                                    __typename: "Action"
                                    name?: string | null
                                    enabled?: boolean | null
                                  }>
                                }
                          }>
                        }
                      | {
                          __typename: "TextGroupContent"
                          id: string
                          historyNumber: number
                          comments: Array<{
                            __typename: "CommentNode"
                            id: string
                            comment:
                              | {
                                  __typename: "BlockComment"
                                  location: number
                                  value?: string | null
                                  authorDisplayName?: string | null
                                  authorUserName?: string | null
                                  commentKey: string
                                  editDate?: any | null
                                  modificationVersion?: number | null
                                  replyToKey?: string | null
                                  sortOrder?: number | null
                                  commentType?: string | null
                                  state?: string | null
                                  color: string
                                  persistanceState?: Types.PersistanceState | null
                                  persistanceAction?: Types.PersistanceAction | null
                                  node: {
                                    __typename: "ST4Node"
                                    aspectId: any
                                    id: string
                                    content?:
                                      | { __typename: "LexiconContent" }
                                      | { __typename: "LexiconEntryContent" }
                                      | { __typename: "MediaContent" }
                                      | { __typename: "MediaGroupContent" }
                                      | { __typename: "ProjectContent" }
                                      | { __typename: "TermContent" }
                                      | { __typename: "TextContent"; id: string; historyNumber: number }
                                      | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                                      | { __typename: "VariableTableContent" }
                                      | null
                                  }
                                  actions: Array<{
                                    __typename: "Action"
                                    name?: string | null
                                    enabled?: boolean | null
                                  }>
                                }
                              | {
                                  __typename: "TextComment"
                                  endLocation: number
                                  startLocation?: number | null
                                  value?: string | null
                                  authorDisplayName?: string | null
                                  authorUserName?: string | null
                                  commentKey: string
                                  editDate?: any | null
                                  modificationVersion?: number | null
                                  replyToKey?: string | null
                                  sortOrder?: number | null
                                  commentType?: string | null
                                  state?: string | null
                                  color: string
                                  persistanceState?: Types.PersistanceState | null
                                  persistanceAction?: Types.PersistanceAction | null
                                  node: {
                                    __typename: "ST4Node"
                                    aspectId: any
                                    id: string
                                    content?:
                                      | { __typename: "LexiconContent" }
                                      | { __typename: "LexiconEntryContent" }
                                      | { __typename: "MediaContent" }
                                      | { __typename: "MediaGroupContent" }
                                      | { __typename: "ProjectContent" }
                                      | { __typename: "TermContent" }
                                      | { __typename: "TextContent"; id: string; historyNumber: number }
                                      | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                                      | { __typename: "VariableTableContent" }
                                      | null
                                  }
                                  actions: Array<{
                                    __typename: "Action"
                                    name?: string | null
                                    enabled?: boolean | null
                                  }>
                                }
                          }>
                        }
                      | { __typename: "VariableTableContent" }
                      | null
                  }
                }>
                parts: Array<{
                  __typename: "TextContentPart"
                  ref: string
                  contentNode: {
                    __typename: "ST4Node"
                    id: string
                    aspectId: any
                    content?:
                      | { __typename: "LexiconContent" }
                      | { __typename: "LexiconEntryContent" }
                      | { __typename: "MediaContent" }
                      | { __typename: "MediaGroupContent" }
                      | { __typename: "ProjectContent" }
                      | { __typename: "TermContent" }
                      | {
                          __typename: "TextContent"
                          id: string
                          historyNumber: number
                          comments: Array<{
                            __typename: "CommentNode"
                            id: string
                            comment:
                              | {
                                  __typename: "BlockComment"
                                  location: number
                                  value?: string | null
                                  authorDisplayName?: string | null
                                  authorUserName?: string | null
                                  commentKey: string
                                  editDate?: any | null
                                  modificationVersion?: number | null
                                  replyToKey?: string | null
                                  sortOrder?: number | null
                                  commentType?: string | null
                                  state?: string | null
                                  color: string
                                  persistanceState?: Types.PersistanceState | null
                                  persistanceAction?: Types.PersistanceAction | null
                                  node: {
                                    __typename: "ST4Node"
                                    aspectId: any
                                    id: string
                                    content?:
                                      | { __typename: "LexiconContent" }
                                      | { __typename: "LexiconEntryContent" }
                                      | { __typename: "MediaContent" }
                                      | { __typename: "MediaGroupContent" }
                                      | { __typename: "ProjectContent" }
                                      | { __typename: "TermContent" }
                                      | { __typename: "TextContent"; id: string; historyNumber: number }
                                      | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                                      | { __typename: "VariableTableContent" }
                                      | null
                                  }
                                  actions: Array<{
                                    __typename: "Action"
                                    name?: string | null
                                    enabled?: boolean | null
                                  }>
                                }
                              | {
                                  __typename: "TextComment"
                                  endLocation: number
                                  startLocation?: number | null
                                  value?: string | null
                                  authorDisplayName?: string | null
                                  authorUserName?: string | null
                                  commentKey: string
                                  editDate?: any | null
                                  modificationVersion?: number | null
                                  replyToKey?: string | null
                                  sortOrder?: number | null
                                  commentType?: string | null
                                  state?: string | null
                                  color: string
                                  persistanceState?: Types.PersistanceState | null
                                  persistanceAction?: Types.PersistanceAction | null
                                  node: {
                                    __typename: "ST4Node"
                                    aspectId: any
                                    id: string
                                    content?:
                                      | { __typename: "LexiconContent" }
                                      | { __typename: "LexiconEntryContent" }
                                      | { __typename: "MediaContent" }
                                      | { __typename: "MediaGroupContent" }
                                      | { __typename: "ProjectContent" }
                                      | { __typename: "TermContent" }
                                      | { __typename: "TextContent"; id: string; historyNumber: number }
                                      | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                                      | { __typename: "VariableTableContent" }
                                      | null
                                  }
                                  actions: Array<{
                                    __typename: "Action"
                                    name?: string | null
                                    enabled?: boolean | null
                                  }>
                                }
                          }>
                        }
                      | {
                          __typename: "TextGroupContent"
                          id: string
                          historyNumber: number
                          comments: Array<{
                            __typename: "CommentNode"
                            id: string
                            comment:
                              | {
                                  __typename: "BlockComment"
                                  location: number
                                  value?: string | null
                                  authorDisplayName?: string | null
                                  authorUserName?: string | null
                                  commentKey: string
                                  editDate?: any | null
                                  modificationVersion?: number | null
                                  replyToKey?: string | null
                                  sortOrder?: number | null
                                  commentType?: string | null
                                  state?: string | null
                                  color: string
                                  persistanceState?: Types.PersistanceState | null
                                  persistanceAction?: Types.PersistanceAction | null
                                  node: {
                                    __typename: "ST4Node"
                                    aspectId: any
                                    id: string
                                    content?:
                                      | { __typename: "LexiconContent" }
                                      | { __typename: "LexiconEntryContent" }
                                      | { __typename: "MediaContent" }
                                      | { __typename: "MediaGroupContent" }
                                      | { __typename: "ProjectContent" }
                                      | { __typename: "TermContent" }
                                      | { __typename: "TextContent"; id: string; historyNumber: number }
                                      | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                                      | { __typename: "VariableTableContent" }
                                      | null
                                  }
                                  actions: Array<{
                                    __typename: "Action"
                                    name?: string | null
                                    enabled?: boolean | null
                                  }>
                                }
                              | {
                                  __typename: "TextComment"
                                  endLocation: number
                                  startLocation?: number | null
                                  value?: string | null
                                  authorDisplayName?: string | null
                                  authorUserName?: string | null
                                  commentKey: string
                                  editDate?: any | null
                                  modificationVersion?: number | null
                                  replyToKey?: string | null
                                  sortOrder?: number | null
                                  commentType?: string | null
                                  state?: string | null
                                  color: string
                                  persistanceState?: Types.PersistanceState | null
                                  persistanceAction?: Types.PersistanceAction | null
                                  node: {
                                    __typename: "ST4Node"
                                    aspectId: any
                                    id: string
                                    content?:
                                      | { __typename: "LexiconContent" }
                                      | { __typename: "LexiconEntryContent" }
                                      | { __typename: "MediaContent" }
                                      | { __typename: "MediaGroupContent" }
                                      | { __typename: "ProjectContent" }
                                      | { __typename: "TermContent" }
                                      | { __typename: "TextContent"; id: string; historyNumber: number }
                                      | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                                      | { __typename: "VariableTableContent" }
                                      | null
                                  }
                                  actions: Array<{
                                    __typename: "Action"
                                    name?: string | null
                                    enabled?: boolean | null
                                  }>
                                }
                          }>
                        }
                      | { __typename: "VariableTableContent" }
                      | null
                  }
                }>
                comments: Array<{
                  __typename: "CommentNode"
                  id: string
                  comment:
                    | {
                        __typename: "BlockComment"
                        location: number
                        value?: string | null
                        authorDisplayName?: string | null
                        authorUserName?: string | null
                        commentKey: string
                        editDate?: any | null
                        modificationVersion?: number | null
                        replyToKey?: string | null
                        sortOrder?: number | null
                        commentType?: string | null
                        state?: string | null
                        color: string
                        persistanceState?: Types.PersistanceState | null
                        persistanceAction?: Types.PersistanceAction | null
                        node: {
                          __typename: "ST4Node"
                          aspectId: any
                          id: string
                          content?:
                            | { __typename: "LexiconContent" }
                            | { __typename: "LexiconEntryContent" }
                            | { __typename: "MediaContent" }
                            | { __typename: "MediaGroupContent" }
                            | { __typename: "ProjectContent" }
                            | { __typename: "TermContent" }
                            | { __typename: "TextContent"; id: string; historyNumber: number }
                            | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                            | { __typename: "VariableTableContent" }
                            | null
                        }
                        actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
                      }
                    | {
                        __typename: "TextComment"
                        endLocation: number
                        startLocation?: number | null
                        value?: string | null
                        authorDisplayName?: string | null
                        authorUserName?: string | null
                        commentKey: string
                        editDate?: any | null
                        modificationVersion?: number | null
                        replyToKey?: string | null
                        sortOrder?: number | null
                        commentType?: string | null
                        state?: string | null
                        color: string
                        persistanceState?: Types.PersistanceState | null
                        persistanceAction?: Types.PersistanceAction | null
                        node: {
                          __typename: "ST4Node"
                          aspectId: any
                          id: string
                          content?:
                            | { __typename: "LexiconContent" }
                            | { __typename: "LexiconEntryContent" }
                            | { __typename: "MediaContent" }
                            | { __typename: "MediaGroupContent" }
                            | { __typename: "ProjectContent" }
                            | { __typename: "TermContent" }
                            | { __typename: "TextContent"; id: string; historyNumber: number }
                            | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                            | { __typename: "VariableTableContent" }
                            | null
                        }
                        actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
                      }
                }>
              }
            | {
                __typename: "TextGroupContent"
                id: string
                historyNumber: number
                children: Array<{
                  __typename: "NodeRef"
                  id: string
                  target?:
                    | { __typename: "CommentNode" }
                    | { __typename: "ExternalSite" }
                    | { __typename: "ST4Class" }
                    | {
                        __typename: "ST4Node"
                        id: string
                        aspectId: any
                        content?:
                          | { __typename: "LexiconContent" }
                          | { __typename: "LexiconEntryContent" }
                          | { __typename: "MediaContent" }
                          | { __typename: "MediaGroupContent" }
                          | { __typename: "ProjectContent" }
                          | { __typename: "TermContent" }
                          | {
                              __typename: "TextContent"
                              id: string
                              historyNumber: number
                              fragments: Array<{
                                __typename: "TextContentFragment"
                                contentNode: {
                                  __typename: "ST4Node"
                                  id: string
                                  aspectId: any
                                  content?:
                                    | { __typename: "LexiconContent" }
                                    | { __typename: "LexiconEntryContent" }
                                    | { __typename: "MediaContent" }
                                    | { __typename: "MediaGroupContent" }
                                    | { __typename: "ProjectContent" }
                                    | { __typename: "TermContent" }
                                    | {
                                        __typename: "TextContent"
                                        id: string
                                        historyNumber: number
                                        comments: Array<{
                                          __typename: "CommentNode"
                                          id: string
                                          comment:
                                            | {
                                                __typename: "BlockComment"
                                                location: number
                                                value?: string | null
                                                authorDisplayName?: string | null
                                                authorUserName?: string | null
                                                commentKey: string
                                                editDate?: any | null
                                                modificationVersion?: number | null
                                                replyToKey?: string | null
                                                sortOrder?: number | null
                                                commentType?: string | null
                                                state?: string | null
                                                color: string
                                                persistanceState?: Types.PersistanceState | null
                                                persistanceAction?: Types.PersistanceAction | null
                                                node: {
                                                  __typename: "ST4Node"
                                                  aspectId: any
                                                  id: string
                                                  content?:
                                                    | { __typename: "LexiconContent" }
                                                    | { __typename: "LexiconEntryContent" }
                                                    | { __typename: "MediaContent" }
                                                    | { __typename: "MediaGroupContent" }
                                                    | { __typename: "ProjectContent" }
                                                    | { __typename: "TermContent" }
                                                    | { __typename: "TextContent"; id: string; historyNumber: number }
                                                    | {
                                                        __typename: "TextGroupContent"
                                                        id: string
                                                        historyNumber: number
                                                      }
                                                    | { __typename: "VariableTableContent" }
                                                    | null
                                                }
                                                actions: Array<{
                                                  __typename: "Action"
                                                  name?: string | null
                                                  enabled?: boolean | null
                                                }>
                                              }
                                            | {
                                                __typename: "TextComment"
                                                endLocation: number
                                                startLocation?: number | null
                                                value?: string | null
                                                authorDisplayName?: string | null
                                                authorUserName?: string | null
                                                commentKey: string
                                                editDate?: any | null
                                                modificationVersion?: number | null
                                                replyToKey?: string | null
                                                sortOrder?: number | null
                                                commentType?: string | null
                                                state?: string | null
                                                color: string
                                                persistanceState?: Types.PersistanceState | null
                                                persistanceAction?: Types.PersistanceAction | null
                                                node: {
                                                  __typename: "ST4Node"
                                                  aspectId: any
                                                  id: string
                                                  content?:
                                                    | { __typename: "LexiconContent" }
                                                    | { __typename: "LexiconEntryContent" }
                                                    | { __typename: "MediaContent" }
                                                    | { __typename: "MediaGroupContent" }
                                                    | { __typename: "ProjectContent" }
                                                    | { __typename: "TermContent" }
                                                    | { __typename: "TextContent"; id: string; historyNumber: number }
                                                    | {
                                                        __typename: "TextGroupContent"
                                                        id: string
                                                        historyNumber: number
                                                      }
                                                    | { __typename: "VariableTableContent" }
                                                    | null
                                                }
                                                actions: Array<{
                                                  __typename: "Action"
                                                  name?: string | null
                                                  enabled?: boolean | null
                                                }>
                                              }
                                        }>
                                      }
                                    | {
                                        __typename: "TextGroupContent"
                                        id: string
                                        historyNumber: number
                                        comments: Array<{
                                          __typename: "CommentNode"
                                          id: string
                                          comment:
                                            | {
                                                __typename: "BlockComment"
                                                location: number
                                                value?: string | null
                                                authorDisplayName?: string | null
                                                authorUserName?: string | null
                                                commentKey: string
                                                editDate?: any | null
                                                modificationVersion?: number | null
                                                replyToKey?: string | null
                                                sortOrder?: number | null
                                                commentType?: string | null
                                                state?: string | null
                                                color: string
                                                persistanceState?: Types.PersistanceState | null
                                                persistanceAction?: Types.PersistanceAction | null
                                                node: {
                                                  __typename: "ST4Node"
                                                  aspectId: any
                                                  id: string
                                                  content?:
                                                    | { __typename: "LexiconContent" }
                                                    | { __typename: "LexiconEntryContent" }
                                                    | { __typename: "MediaContent" }
                                                    | { __typename: "MediaGroupContent" }
                                                    | { __typename: "ProjectContent" }
                                                    | { __typename: "TermContent" }
                                                    | { __typename: "TextContent"; id: string; historyNumber: number }
                                                    | {
                                                        __typename: "TextGroupContent"
                                                        id: string
                                                        historyNumber: number
                                                      }
                                                    | { __typename: "VariableTableContent" }
                                                    | null
                                                }
                                                actions: Array<{
                                                  __typename: "Action"
                                                  name?: string | null
                                                  enabled?: boolean | null
                                                }>
                                              }
                                            | {
                                                __typename: "TextComment"
                                                endLocation: number
                                                startLocation?: number | null
                                                value?: string | null
                                                authorDisplayName?: string | null
                                                authorUserName?: string | null
                                                commentKey: string
                                                editDate?: any | null
                                                modificationVersion?: number | null
                                                replyToKey?: string | null
                                                sortOrder?: number | null
                                                commentType?: string | null
                                                state?: string | null
                                                color: string
                                                persistanceState?: Types.PersistanceState | null
                                                persistanceAction?: Types.PersistanceAction | null
                                                node: {
                                                  __typename: "ST4Node"
                                                  aspectId: any
                                                  id: string
                                                  content?:
                                                    | { __typename: "LexiconContent" }
                                                    | { __typename: "LexiconEntryContent" }
                                                    | { __typename: "MediaContent" }
                                                    | { __typename: "MediaGroupContent" }
                                                    | { __typename: "ProjectContent" }
                                                    | { __typename: "TermContent" }
                                                    | { __typename: "TextContent"; id: string; historyNumber: number }
                                                    | {
                                                        __typename: "TextGroupContent"
                                                        id: string
                                                        historyNumber: number
                                                      }
                                                    | { __typename: "VariableTableContent" }
                                                    | null
                                                }
                                                actions: Array<{
                                                  __typename: "Action"
                                                  name?: string | null
                                                  enabled?: boolean | null
                                                }>
                                              }
                                        }>
                                      }
                                    | { __typename: "VariableTableContent" }
                                    | null
                                }
                              }>
                              parts: Array<{
                                __typename: "TextContentPart"
                                contentNode: {
                                  __typename: "ST4Node"
                                  id: string
                                  aspectId: any
                                  content?:
                                    | { __typename: "LexiconContent" }
                                    | { __typename: "LexiconEntryContent" }
                                    | { __typename: "MediaContent" }
                                    | { __typename: "MediaGroupContent" }
                                    | { __typename: "ProjectContent" }
                                    | { __typename: "TermContent" }
                                    | {
                                        __typename: "TextContent"
                                        id: string
                                        historyNumber: number
                                        comments: Array<{
                                          __typename: "CommentNode"
                                          id: string
                                          comment:
                                            | {
                                                __typename: "BlockComment"
                                                location: number
                                                value?: string | null
                                                authorDisplayName?: string | null
                                                authorUserName?: string | null
                                                commentKey: string
                                                editDate?: any | null
                                                modificationVersion?: number | null
                                                replyToKey?: string | null
                                                sortOrder?: number | null
                                                commentType?: string | null
                                                state?: string | null
                                                color: string
                                                persistanceState?: Types.PersistanceState | null
                                                persistanceAction?: Types.PersistanceAction | null
                                                node: {
                                                  __typename: "ST4Node"
                                                  aspectId: any
                                                  id: string
                                                  content?:
                                                    | { __typename: "LexiconContent" }
                                                    | { __typename: "LexiconEntryContent" }
                                                    | { __typename: "MediaContent" }
                                                    | { __typename: "MediaGroupContent" }
                                                    | { __typename: "ProjectContent" }
                                                    | { __typename: "TermContent" }
                                                    | { __typename: "TextContent"; id: string; historyNumber: number }
                                                    | {
                                                        __typename: "TextGroupContent"
                                                        id: string
                                                        historyNumber: number
                                                      }
                                                    | { __typename: "VariableTableContent" }
                                                    | null
                                                }
                                                actions: Array<{
                                                  __typename: "Action"
                                                  name?: string | null
                                                  enabled?: boolean | null
                                                }>
                                              }
                                            | {
                                                __typename: "TextComment"
                                                endLocation: number
                                                startLocation?: number | null
                                                value?: string | null
                                                authorDisplayName?: string | null
                                                authorUserName?: string | null
                                                commentKey: string
                                                editDate?: any | null
                                                modificationVersion?: number | null
                                                replyToKey?: string | null
                                                sortOrder?: number | null
                                                commentType?: string | null
                                                state?: string | null
                                                color: string
                                                persistanceState?: Types.PersistanceState | null
                                                persistanceAction?: Types.PersistanceAction | null
                                                node: {
                                                  __typename: "ST4Node"
                                                  aspectId: any
                                                  id: string
                                                  content?:
                                                    | { __typename: "LexiconContent" }
                                                    | { __typename: "LexiconEntryContent" }
                                                    | { __typename: "MediaContent" }
                                                    | { __typename: "MediaGroupContent" }
                                                    | { __typename: "ProjectContent" }
                                                    | { __typename: "TermContent" }
                                                    | { __typename: "TextContent"; id: string; historyNumber: number }
                                                    | {
                                                        __typename: "TextGroupContent"
                                                        id: string
                                                        historyNumber: number
                                                      }
                                                    | { __typename: "VariableTableContent" }
                                                    | null
                                                }
                                                actions: Array<{
                                                  __typename: "Action"
                                                  name?: string | null
                                                  enabled?: boolean | null
                                                }>
                                              }
                                        }>
                                      }
                                    | {
                                        __typename: "TextGroupContent"
                                        id: string
                                        historyNumber: number
                                        comments: Array<{
                                          __typename: "CommentNode"
                                          id: string
                                          comment:
                                            | {
                                                __typename: "BlockComment"
                                                location: number
                                                value?: string | null
                                                authorDisplayName?: string | null
                                                authorUserName?: string | null
                                                commentKey: string
                                                editDate?: any | null
                                                modificationVersion?: number | null
                                                replyToKey?: string | null
                                                sortOrder?: number | null
                                                commentType?: string | null
                                                state?: string | null
                                                color: string
                                                persistanceState?: Types.PersistanceState | null
                                                persistanceAction?: Types.PersistanceAction | null
                                                node: {
                                                  __typename: "ST4Node"
                                                  aspectId: any
                                                  id: string
                                                  content?:
                                                    | { __typename: "LexiconContent" }
                                                    | { __typename: "LexiconEntryContent" }
                                                    | { __typename: "MediaContent" }
                                                    | { __typename: "MediaGroupContent" }
                                                    | { __typename: "ProjectContent" }
                                                    | { __typename: "TermContent" }
                                                    | { __typename: "TextContent"; id: string; historyNumber: number }
                                                    | {
                                                        __typename: "TextGroupContent"
                                                        id: string
                                                        historyNumber: number
                                                      }
                                                    | { __typename: "VariableTableContent" }
                                                    | null
                                                }
                                                actions: Array<{
                                                  __typename: "Action"
                                                  name?: string | null
                                                  enabled?: boolean | null
                                                }>
                                              }
                                            | {
                                                __typename: "TextComment"
                                                endLocation: number
                                                startLocation?: number | null
                                                value?: string | null
                                                authorDisplayName?: string | null
                                                authorUserName?: string | null
                                                commentKey: string
                                                editDate?: any | null
                                                modificationVersion?: number | null
                                                replyToKey?: string | null
                                                sortOrder?: number | null
                                                commentType?: string | null
                                                state?: string | null
                                                color: string
                                                persistanceState?: Types.PersistanceState | null
                                                persistanceAction?: Types.PersistanceAction | null
                                                node: {
                                                  __typename: "ST4Node"
                                                  aspectId: any
                                                  id: string
                                                  content?:
                                                    | { __typename: "LexiconContent" }
                                                    | { __typename: "LexiconEntryContent" }
                                                    | { __typename: "MediaContent" }
                                                    | { __typename: "MediaGroupContent" }
                                                    | { __typename: "ProjectContent" }
                                                    | { __typename: "TermContent" }
                                                    | { __typename: "TextContent"; id: string; historyNumber: number }
                                                    | {
                                                        __typename: "TextGroupContent"
                                                        id: string
                                                        historyNumber: number
                                                      }
                                                    | { __typename: "VariableTableContent" }
                                                    | null
                                                }
                                                actions: Array<{
                                                  __typename: "Action"
                                                  name?: string | null
                                                  enabled?: boolean | null
                                                }>
                                              }
                                        }>
                                      }
                                    | { __typename: "VariableTableContent" }
                                    | null
                                }
                              }>
                              comments: Array<{
                                __typename: "CommentNode"
                                id: string
                                comment:
                                  | {
                                      __typename: "BlockComment"
                                      location: number
                                      value?: string | null
                                      authorDisplayName?: string | null
                                      authorUserName?: string | null
                                      commentKey: string
                                      editDate?: any | null
                                      modificationVersion?: number | null
                                      replyToKey?: string | null
                                      sortOrder?: number | null
                                      commentType?: string | null
                                      state?: string | null
                                      color: string
                                      persistanceState?: Types.PersistanceState | null
                                      persistanceAction?: Types.PersistanceAction | null
                                      node: {
                                        __typename: "ST4Node"
                                        aspectId: any
                                        id: string
                                        content?:
                                          | { __typename: "LexiconContent" }
                                          | { __typename: "LexiconEntryContent" }
                                          | { __typename: "MediaContent" }
                                          | { __typename: "MediaGroupContent" }
                                          | { __typename: "ProjectContent" }
                                          | { __typename: "TermContent" }
                                          | { __typename: "TextContent"; id: string; historyNumber: number }
                                          | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                                          | { __typename: "VariableTableContent" }
                                          | null
                                      }
                                      actions: Array<{
                                        __typename: "Action"
                                        name?: string | null
                                        enabled?: boolean | null
                                      }>
                                    }
                                  | {
                                      __typename: "TextComment"
                                      endLocation: number
                                      startLocation?: number | null
                                      value?: string | null
                                      authorDisplayName?: string | null
                                      authorUserName?: string | null
                                      commentKey: string
                                      editDate?: any | null
                                      modificationVersion?: number | null
                                      replyToKey?: string | null
                                      sortOrder?: number | null
                                      commentType?: string | null
                                      state?: string | null
                                      color: string
                                      persistanceState?: Types.PersistanceState | null
                                      persistanceAction?: Types.PersistanceAction | null
                                      node: {
                                        __typename: "ST4Node"
                                        aspectId: any
                                        id: string
                                        content?:
                                          | { __typename: "LexiconContent" }
                                          | { __typename: "LexiconEntryContent" }
                                          | { __typename: "MediaContent" }
                                          | { __typename: "MediaGroupContent" }
                                          | { __typename: "ProjectContent" }
                                          | { __typename: "TermContent" }
                                          | { __typename: "TextContent"; id: string; historyNumber: number }
                                          | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                                          | { __typename: "VariableTableContent" }
                                          | null
                                      }
                                      actions: Array<{
                                        __typename: "Action"
                                        name?: string | null
                                        enabled?: boolean | null
                                      }>
                                    }
                              }>
                            }
                          | {
                              __typename: "TextGroupContent"
                              id: string
                              historyNumber: number
                              fragments: Array<{
                                __typename: "TextContentFragment"
                                contentNode: {
                                  __typename: "ST4Node"
                                  id: string
                                  aspectId: any
                                  content?:
                                    | { __typename: "LexiconContent" }
                                    | { __typename: "LexiconEntryContent" }
                                    | { __typename: "MediaContent" }
                                    | { __typename: "MediaGroupContent" }
                                    | { __typename: "ProjectContent" }
                                    | { __typename: "TermContent" }
                                    | {
                                        __typename: "TextContent"
                                        id: string
                                        historyNumber: number
                                        comments: Array<{
                                          __typename: "CommentNode"
                                          id: string
                                          comment:
                                            | {
                                                __typename: "BlockComment"
                                                location: number
                                                value?: string | null
                                                authorDisplayName?: string | null
                                                authorUserName?: string | null
                                                commentKey: string
                                                editDate?: any | null
                                                modificationVersion?: number | null
                                                replyToKey?: string | null
                                                sortOrder?: number | null
                                                commentType?: string | null
                                                state?: string | null
                                                color: string
                                                persistanceState?: Types.PersistanceState | null
                                                persistanceAction?: Types.PersistanceAction | null
                                                node: {
                                                  __typename: "ST4Node"
                                                  aspectId: any
                                                  id: string
                                                  content?:
                                                    | { __typename: "LexiconContent" }
                                                    | { __typename: "LexiconEntryContent" }
                                                    | { __typename: "MediaContent" }
                                                    | { __typename: "MediaGroupContent" }
                                                    | { __typename: "ProjectContent" }
                                                    | { __typename: "TermContent" }
                                                    | { __typename: "TextContent"; id: string; historyNumber: number }
                                                    | {
                                                        __typename: "TextGroupContent"
                                                        id: string
                                                        historyNumber: number
                                                      }
                                                    | { __typename: "VariableTableContent" }
                                                    | null
                                                }
                                                actions: Array<{
                                                  __typename: "Action"
                                                  name?: string | null
                                                  enabled?: boolean | null
                                                }>
                                              }
                                            | {
                                                __typename: "TextComment"
                                                endLocation: number
                                                startLocation?: number | null
                                                value?: string | null
                                                authorDisplayName?: string | null
                                                authorUserName?: string | null
                                                commentKey: string
                                                editDate?: any | null
                                                modificationVersion?: number | null
                                                replyToKey?: string | null
                                                sortOrder?: number | null
                                                commentType?: string | null
                                                state?: string | null
                                                color: string
                                                persistanceState?: Types.PersistanceState | null
                                                persistanceAction?: Types.PersistanceAction | null
                                                node: {
                                                  __typename: "ST4Node"
                                                  aspectId: any
                                                  id: string
                                                  content?:
                                                    | { __typename: "LexiconContent" }
                                                    | { __typename: "LexiconEntryContent" }
                                                    | { __typename: "MediaContent" }
                                                    | { __typename: "MediaGroupContent" }
                                                    | { __typename: "ProjectContent" }
                                                    | { __typename: "TermContent" }
                                                    | { __typename: "TextContent"; id: string; historyNumber: number }
                                                    | {
                                                        __typename: "TextGroupContent"
                                                        id: string
                                                        historyNumber: number
                                                      }
                                                    | { __typename: "VariableTableContent" }
                                                    | null
                                                }
                                                actions: Array<{
                                                  __typename: "Action"
                                                  name?: string | null
                                                  enabled?: boolean | null
                                                }>
                                              }
                                        }>
                                      }
                                    | {
                                        __typename: "TextGroupContent"
                                        id: string
                                        historyNumber: number
                                        comments: Array<{
                                          __typename: "CommentNode"
                                          id: string
                                          comment:
                                            | {
                                                __typename: "BlockComment"
                                                location: number
                                                value?: string | null
                                                authorDisplayName?: string | null
                                                authorUserName?: string | null
                                                commentKey: string
                                                editDate?: any | null
                                                modificationVersion?: number | null
                                                replyToKey?: string | null
                                                sortOrder?: number | null
                                                commentType?: string | null
                                                state?: string | null
                                                color: string
                                                persistanceState?: Types.PersistanceState | null
                                                persistanceAction?: Types.PersistanceAction | null
                                                node: {
                                                  __typename: "ST4Node"
                                                  aspectId: any
                                                  id: string
                                                  content?:
                                                    | { __typename: "LexiconContent" }
                                                    | { __typename: "LexiconEntryContent" }
                                                    | { __typename: "MediaContent" }
                                                    | { __typename: "MediaGroupContent" }
                                                    | { __typename: "ProjectContent" }
                                                    | { __typename: "TermContent" }
                                                    | { __typename: "TextContent"; id: string; historyNumber: number }
                                                    | {
                                                        __typename: "TextGroupContent"
                                                        id: string
                                                        historyNumber: number
                                                      }
                                                    | { __typename: "VariableTableContent" }
                                                    | null
                                                }
                                                actions: Array<{
                                                  __typename: "Action"
                                                  name?: string | null
                                                  enabled?: boolean | null
                                                }>
                                              }
                                            | {
                                                __typename: "TextComment"
                                                endLocation: number
                                                startLocation?: number | null
                                                value?: string | null
                                                authorDisplayName?: string | null
                                                authorUserName?: string | null
                                                commentKey: string
                                                editDate?: any | null
                                                modificationVersion?: number | null
                                                replyToKey?: string | null
                                                sortOrder?: number | null
                                                commentType?: string | null
                                                state?: string | null
                                                color: string
                                                persistanceState?: Types.PersistanceState | null
                                                persistanceAction?: Types.PersistanceAction | null
                                                node: {
                                                  __typename: "ST4Node"
                                                  aspectId: any
                                                  id: string
                                                  content?:
                                                    | { __typename: "LexiconContent" }
                                                    | { __typename: "LexiconEntryContent" }
                                                    | { __typename: "MediaContent" }
                                                    | { __typename: "MediaGroupContent" }
                                                    | { __typename: "ProjectContent" }
                                                    | { __typename: "TermContent" }
                                                    | { __typename: "TextContent"; id: string; historyNumber: number }
                                                    | {
                                                        __typename: "TextGroupContent"
                                                        id: string
                                                        historyNumber: number
                                                      }
                                                    | { __typename: "VariableTableContent" }
                                                    | null
                                                }
                                                actions: Array<{
                                                  __typename: "Action"
                                                  name?: string | null
                                                  enabled?: boolean | null
                                                }>
                                              }
                                        }>
                                      }
                                    | { __typename: "VariableTableContent" }
                                    | null
                                }
                              }>
                              parts: Array<{
                                __typename: "TextContentPart"
                                contentNode: {
                                  __typename: "ST4Node"
                                  id: string
                                  aspectId: any
                                  content?:
                                    | { __typename: "LexiconContent" }
                                    | { __typename: "LexiconEntryContent" }
                                    | { __typename: "MediaContent" }
                                    | { __typename: "MediaGroupContent" }
                                    | { __typename: "ProjectContent" }
                                    | { __typename: "TermContent" }
                                    | {
                                        __typename: "TextContent"
                                        id: string
                                        historyNumber: number
                                        comments: Array<{
                                          __typename: "CommentNode"
                                          id: string
                                          comment:
                                            | {
                                                __typename: "BlockComment"
                                                location: number
                                                value?: string | null
                                                authorDisplayName?: string | null
                                                authorUserName?: string | null
                                                commentKey: string
                                                editDate?: any | null
                                                modificationVersion?: number | null
                                                replyToKey?: string | null
                                                sortOrder?: number | null
                                                commentType?: string | null
                                                state?: string | null
                                                color: string
                                                persistanceState?: Types.PersistanceState | null
                                                persistanceAction?: Types.PersistanceAction | null
                                                node: {
                                                  __typename: "ST4Node"
                                                  aspectId: any
                                                  id: string
                                                  content?:
                                                    | { __typename: "LexiconContent" }
                                                    | { __typename: "LexiconEntryContent" }
                                                    | { __typename: "MediaContent" }
                                                    | { __typename: "MediaGroupContent" }
                                                    | { __typename: "ProjectContent" }
                                                    | { __typename: "TermContent" }
                                                    | { __typename: "TextContent"; id: string; historyNumber: number }
                                                    | {
                                                        __typename: "TextGroupContent"
                                                        id: string
                                                        historyNumber: number
                                                      }
                                                    | { __typename: "VariableTableContent" }
                                                    | null
                                                }
                                                actions: Array<{
                                                  __typename: "Action"
                                                  name?: string | null
                                                  enabled?: boolean | null
                                                }>
                                              }
                                            | {
                                                __typename: "TextComment"
                                                endLocation: number
                                                startLocation?: number | null
                                                value?: string | null
                                                authorDisplayName?: string | null
                                                authorUserName?: string | null
                                                commentKey: string
                                                editDate?: any | null
                                                modificationVersion?: number | null
                                                replyToKey?: string | null
                                                sortOrder?: number | null
                                                commentType?: string | null
                                                state?: string | null
                                                color: string
                                                persistanceState?: Types.PersistanceState | null
                                                persistanceAction?: Types.PersistanceAction | null
                                                node: {
                                                  __typename: "ST4Node"
                                                  aspectId: any
                                                  id: string
                                                  content?:
                                                    | { __typename: "LexiconContent" }
                                                    | { __typename: "LexiconEntryContent" }
                                                    | { __typename: "MediaContent" }
                                                    | { __typename: "MediaGroupContent" }
                                                    | { __typename: "ProjectContent" }
                                                    | { __typename: "TermContent" }
                                                    | { __typename: "TextContent"; id: string; historyNumber: number }
                                                    | {
                                                        __typename: "TextGroupContent"
                                                        id: string
                                                        historyNumber: number
                                                      }
                                                    | { __typename: "VariableTableContent" }
                                                    | null
                                                }
                                                actions: Array<{
                                                  __typename: "Action"
                                                  name?: string | null
                                                  enabled?: boolean | null
                                                }>
                                              }
                                        }>
                                      }
                                    | {
                                        __typename: "TextGroupContent"
                                        id: string
                                        historyNumber: number
                                        comments: Array<{
                                          __typename: "CommentNode"
                                          id: string
                                          comment:
                                            | {
                                                __typename: "BlockComment"
                                                location: number
                                                value?: string | null
                                                authorDisplayName?: string | null
                                                authorUserName?: string | null
                                                commentKey: string
                                                editDate?: any | null
                                                modificationVersion?: number | null
                                                replyToKey?: string | null
                                                sortOrder?: number | null
                                                commentType?: string | null
                                                state?: string | null
                                                color: string
                                                persistanceState?: Types.PersistanceState | null
                                                persistanceAction?: Types.PersistanceAction | null
                                                node: {
                                                  __typename: "ST4Node"
                                                  aspectId: any
                                                  id: string
                                                  content?:
                                                    | { __typename: "LexiconContent" }
                                                    | { __typename: "LexiconEntryContent" }
                                                    | { __typename: "MediaContent" }
                                                    | { __typename: "MediaGroupContent" }
                                                    | { __typename: "ProjectContent" }
                                                    | { __typename: "TermContent" }
                                                    | { __typename: "TextContent"; id: string; historyNumber: number }
                                                    | {
                                                        __typename: "TextGroupContent"
                                                        id: string
                                                        historyNumber: number
                                                      }
                                                    | { __typename: "VariableTableContent" }
                                                    | null
                                                }
                                                actions: Array<{
                                                  __typename: "Action"
                                                  name?: string | null
                                                  enabled?: boolean | null
                                                }>
                                              }
                                            | {
                                                __typename: "TextComment"
                                                endLocation: number
                                                startLocation?: number | null
                                                value?: string | null
                                                authorDisplayName?: string | null
                                                authorUserName?: string | null
                                                commentKey: string
                                                editDate?: any | null
                                                modificationVersion?: number | null
                                                replyToKey?: string | null
                                                sortOrder?: number | null
                                                commentType?: string | null
                                                state?: string | null
                                                color: string
                                                persistanceState?: Types.PersistanceState | null
                                                persistanceAction?: Types.PersistanceAction | null
                                                node: {
                                                  __typename: "ST4Node"
                                                  aspectId: any
                                                  id: string
                                                  content?:
                                                    | { __typename: "LexiconContent" }
                                                    | { __typename: "LexiconEntryContent" }
                                                    | { __typename: "MediaContent" }
                                                    | { __typename: "MediaGroupContent" }
                                                    | { __typename: "ProjectContent" }
                                                    | { __typename: "TermContent" }
                                                    | { __typename: "TextContent"; id: string; historyNumber: number }
                                                    | {
                                                        __typename: "TextGroupContent"
                                                        id: string
                                                        historyNumber: number
                                                      }
                                                    | { __typename: "VariableTableContent" }
                                                    | null
                                                }
                                                actions: Array<{
                                                  __typename: "Action"
                                                  name?: string | null
                                                  enabled?: boolean | null
                                                }>
                                              }
                                        }>
                                      }
                                    | { __typename: "VariableTableContent" }
                                    | null
                                }
                              }>
                              comments: Array<{
                                __typename: "CommentNode"
                                id: string
                                comment:
                                  | {
                                      __typename: "BlockComment"
                                      location: number
                                      value?: string | null
                                      authorDisplayName?: string | null
                                      authorUserName?: string | null
                                      commentKey: string
                                      editDate?: any | null
                                      modificationVersion?: number | null
                                      replyToKey?: string | null
                                      sortOrder?: number | null
                                      commentType?: string | null
                                      state?: string | null
                                      color: string
                                      persistanceState?: Types.PersistanceState | null
                                      persistanceAction?: Types.PersistanceAction | null
                                      node: {
                                        __typename: "ST4Node"
                                        aspectId: any
                                        id: string
                                        content?:
                                          | { __typename: "LexiconContent" }
                                          | { __typename: "LexiconEntryContent" }
                                          | { __typename: "MediaContent" }
                                          | { __typename: "MediaGroupContent" }
                                          | { __typename: "ProjectContent" }
                                          | { __typename: "TermContent" }
                                          | { __typename: "TextContent"; id: string; historyNumber: number }
                                          | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                                          | { __typename: "VariableTableContent" }
                                          | null
                                      }
                                      actions: Array<{
                                        __typename: "Action"
                                        name?: string | null
                                        enabled?: boolean | null
                                      }>
                                    }
                                  | {
                                      __typename: "TextComment"
                                      endLocation: number
                                      startLocation?: number | null
                                      value?: string | null
                                      authorDisplayName?: string | null
                                      authorUserName?: string | null
                                      commentKey: string
                                      editDate?: any | null
                                      modificationVersion?: number | null
                                      replyToKey?: string | null
                                      sortOrder?: number | null
                                      commentType?: string | null
                                      state?: string | null
                                      color: string
                                      persistanceState?: Types.PersistanceState | null
                                      persistanceAction?: Types.PersistanceAction | null
                                      node: {
                                        __typename: "ST4Node"
                                        aspectId: any
                                        id: string
                                        content?:
                                          | { __typename: "LexiconContent" }
                                          | { __typename: "LexiconEntryContent" }
                                          | { __typename: "MediaContent" }
                                          | { __typename: "MediaGroupContent" }
                                          | { __typename: "ProjectContent" }
                                          | { __typename: "TermContent" }
                                          | { __typename: "TextContent"; id: string; historyNumber: number }
                                          | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                                          | { __typename: "VariableTableContent" }
                                          | null
                                      }
                                      actions: Array<{
                                        __typename: "Action"
                                        name?: string | null
                                        enabled?: boolean | null
                                      }>
                                    }
                              }>
                            }
                          | { __typename: "VariableTableContent" }
                          | null
                      }
                    | { __typename: "ST4TreeNode" }
                    | null
                }>
                fragments: Array<{
                  __typename: "TextContentFragment"
                  compoundId: string
                  contentNode: {
                    __typename: "ST4Node"
                    id: string
                    aspectId: any
                    content?:
                      | { __typename: "LexiconContent" }
                      | { __typename: "LexiconEntryContent" }
                      | { __typename: "MediaContent" }
                      | { __typename: "MediaGroupContent" }
                      | { __typename: "ProjectContent" }
                      | { __typename: "TermContent" }
                      | {
                          __typename: "TextContent"
                          id: string
                          historyNumber: number
                          comments: Array<{
                            __typename: "CommentNode"
                            id: string
                            comment:
                              | {
                                  __typename: "BlockComment"
                                  location: number
                                  value?: string | null
                                  authorDisplayName?: string | null
                                  authorUserName?: string | null
                                  commentKey: string
                                  editDate?: any | null
                                  modificationVersion?: number | null
                                  replyToKey?: string | null
                                  sortOrder?: number | null
                                  commentType?: string | null
                                  state?: string | null
                                  color: string
                                  persistanceState?: Types.PersistanceState | null
                                  persistanceAction?: Types.PersistanceAction | null
                                  node: {
                                    __typename: "ST4Node"
                                    aspectId: any
                                    id: string
                                    content?:
                                      | { __typename: "LexiconContent" }
                                      | { __typename: "LexiconEntryContent" }
                                      | { __typename: "MediaContent" }
                                      | { __typename: "MediaGroupContent" }
                                      | { __typename: "ProjectContent" }
                                      | { __typename: "TermContent" }
                                      | { __typename: "TextContent"; id: string; historyNumber: number }
                                      | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                                      | { __typename: "VariableTableContent" }
                                      | null
                                  }
                                  actions: Array<{
                                    __typename: "Action"
                                    name?: string | null
                                    enabled?: boolean | null
                                  }>
                                }
                              | {
                                  __typename: "TextComment"
                                  endLocation: number
                                  startLocation?: number | null
                                  value?: string | null
                                  authorDisplayName?: string | null
                                  authorUserName?: string | null
                                  commentKey: string
                                  editDate?: any | null
                                  modificationVersion?: number | null
                                  replyToKey?: string | null
                                  sortOrder?: number | null
                                  commentType?: string | null
                                  state?: string | null
                                  color: string
                                  persistanceState?: Types.PersistanceState | null
                                  persistanceAction?: Types.PersistanceAction | null
                                  node: {
                                    __typename: "ST4Node"
                                    aspectId: any
                                    id: string
                                    content?:
                                      | { __typename: "LexiconContent" }
                                      | { __typename: "LexiconEntryContent" }
                                      | { __typename: "MediaContent" }
                                      | { __typename: "MediaGroupContent" }
                                      | { __typename: "ProjectContent" }
                                      | { __typename: "TermContent" }
                                      | { __typename: "TextContent"; id: string; historyNumber: number }
                                      | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                                      | { __typename: "VariableTableContent" }
                                      | null
                                  }
                                  actions: Array<{
                                    __typename: "Action"
                                    name?: string | null
                                    enabled?: boolean | null
                                  }>
                                }
                          }>
                        }
                      | {
                          __typename: "TextGroupContent"
                          id: string
                          historyNumber: number
                          comments: Array<{
                            __typename: "CommentNode"
                            id: string
                            comment:
                              | {
                                  __typename: "BlockComment"
                                  location: number
                                  value?: string | null
                                  authorDisplayName?: string | null
                                  authorUserName?: string | null
                                  commentKey: string
                                  editDate?: any | null
                                  modificationVersion?: number | null
                                  replyToKey?: string | null
                                  sortOrder?: number | null
                                  commentType?: string | null
                                  state?: string | null
                                  color: string
                                  persistanceState?: Types.PersistanceState | null
                                  persistanceAction?: Types.PersistanceAction | null
                                  node: {
                                    __typename: "ST4Node"
                                    aspectId: any
                                    id: string
                                    content?:
                                      | { __typename: "LexiconContent" }
                                      | { __typename: "LexiconEntryContent" }
                                      | { __typename: "MediaContent" }
                                      | { __typename: "MediaGroupContent" }
                                      | { __typename: "ProjectContent" }
                                      | { __typename: "TermContent" }
                                      | { __typename: "TextContent"; id: string; historyNumber: number }
                                      | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                                      | { __typename: "VariableTableContent" }
                                      | null
                                  }
                                  actions: Array<{
                                    __typename: "Action"
                                    name?: string | null
                                    enabled?: boolean | null
                                  }>
                                }
                              | {
                                  __typename: "TextComment"
                                  endLocation: number
                                  startLocation?: number | null
                                  value?: string | null
                                  authorDisplayName?: string | null
                                  authorUserName?: string | null
                                  commentKey: string
                                  editDate?: any | null
                                  modificationVersion?: number | null
                                  replyToKey?: string | null
                                  sortOrder?: number | null
                                  commentType?: string | null
                                  state?: string | null
                                  color: string
                                  persistanceState?: Types.PersistanceState | null
                                  persistanceAction?: Types.PersistanceAction | null
                                  node: {
                                    __typename: "ST4Node"
                                    aspectId: any
                                    id: string
                                    content?:
                                      | { __typename: "LexiconContent" }
                                      | { __typename: "LexiconEntryContent" }
                                      | { __typename: "MediaContent" }
                                      | { __typename: "MediaGroupContent" }
                                      | { __typename: "ProjectContent" }
                                      | { __typename: "TermContent" }
                                      | { __typename: "TextContent"; id: string; historyNumber: number }
                                      | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                                      | { __typename: "VariableTableContent" }
                                      | null
                                  }
                                  actions: Array<{
                                    __typename: "Action"
                                    name?: string | null
                                    enabled?: boolean | null
                                  }>
                                }
                          }>
                        }
                      | { __typename: "VariableTableContent" }
                      | null
                  }
                }>
                parts: Array<{
                  __typename: "TextContentPart"
                  ref: string
                  contentNode: {
                    __typename: "ST4Node"
                    id: string
                    aspectId: any
                    content?:
                      | { __typename: "LexiconContent" }
                      | { __typename: "LexiconEntryContent" }
                      | { __typename: "MediaContent" }
                      | { __typename: "MediaGroupContent" }
                      | { __typename: "ProjectContent" }
                      | { __typename: "TermContent" }
                      | {
                          __typename: "TextContent"
                          id: string
                          historyNumber: number
                          comments: Array<{
                            __typename: "CommentNode"
                            id: string
                            comment:
                              | {
                                  __typename: "BlockComment"
                                  location: number
                                  value?: string | null
                                  authorDisplayName?: string | null
                                  authorUserName?: string | null
                                  commentKey: string
                                  editDate?: any | null
                                  modificationVersion?: number | null
                                  replyToKey?: string | null
                                  sortOrder?: number | null
                                  commentType?: string | null
                                  state?: string | null
                                  color: string
                                  persistanceState?: Types.PersistanceState | null
                                  persistanceAction?: Types.PersistanceAction | null
                                  node: {
                                    __typename: "ST4Node"
                                    aspectId: any
                                    id: string
                                    content?:
                                      | { __typename: "LexiconContent" }
                                      | { __typename: "LexiconEntryContent" }
                                      | { __typename: "MediaContent" }
                                      | { __typename: "MediaGroupContent" }
                                      | { __typename: "ProjectContent" }
                                      | { __typename: "TermContent" }
                                      | { __typename: "TextContent"; id: string; historyNumber: number }
                                      | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                                      | { __typename: "VariableTableContent" }
                                      | null
                                  }
                                  actions: Array<{
                                    __typename: "Action"
                                    name?: string | null
                                    enabled?: boolean | null
                                  }>
                                }
                              | {
                                  __typename: "TextComment"
                                  endLocation: number
                                  startLocation?: number | null
                                  value?: string | null
                                  authorDisplayName?: string | null
                                  authorUserName?: string | null
                                  commentKey: string
                                  editDate?: any | null
                                  modificationVersion?: number | null
                                  replyToKey?: string | null
                                  sortOrder?: number | null
                                  commentType?: string | null
                                  state?: string | null
                                  color: string
                                  persistanceState?: Types.PersistanceState | null
                                  persistanceAction?: Types.PersistanceAction | null
                                  node: {
                                    __typename: "ST4Node"
                                    aspectId: any
                                    id: string
                                    content?:
                                      | { __typename: "LexiconContent" }
                                      | { __typename: "LexiconEntryContent" }
                                      | { __typename: "MediaContent" }
                                      | { __typename: "MediaGroupContent" }
                                      | { __typename: "ProjectContent" }
                                      | { __typename: "TermContent" }
                                      | { __typename: "TextContent"; id: string; historyNumber: number }
                                      | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                                      | { __typename: "VariableTableContent" }
                                      | null
                                  }
                                  actions: Array<{
                                    __typename: "Action"
                                    name?: string | null
                                    enabled?: boolean | null
                                  }>
                                }
                          }>
                        }
                      | {
                          __typename: "TextGroupContent"
                          id: string
                          historyNumber: number
                          comments: Array<{
                            __typename: "CommentNode"
                            id: string
                            comment:
                              | {
                                  __typename: "BlockComment"
                                  location: number
                                  value?: string | null
                                  authorDisplayName?: string | null
                                  authorUserName?: string | null
                                  commentKey: string
                                  editDate?: any | null
                                  modificationVersion?: number | null
                                  replyToKey?: string | null
                                  sortOrder?: number | null
                                  commentType?: string | null
                                  state?: string | null
                                  color: string
                                  persistanceState?: Types.PersistanceState | null
                                  persistanceAction?: Types.PersistanceAction | null
                                  node: {
                                    __typename: "ST4Node"
                                    aspectId: any
                                    id: string
                                    content?:
                                      | { __typename: "LexiconContent" }
                                      | { __typename: "LexiconEntryContent" }
                                      | { __typename: "MediaContent" }
                                      | { __typename: "MediaGroupContent" }
                                      | { __typename: "ProjectContent" }
                                      | { __typename: "TermContent" }
                                      | { __typename: "TextContent"; id: string; historyNumber: number }
                                      | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                                      | { __typename: "VariableTableContent" }
                                      | null
                                  }
                                  actions: Array<{
                                    __typename: "Action"
                                    name?: string | null
                                    enabled?: boolean | null
                                  }>
                                }
                              | {
                                  __typename: "TextComment"
                                  endLocation: number
                                  startLocation?: number | null
                                  value?: string | null
                                  authorDisplayName?: string | null
                                  authorUserName?: string | null
                                  commentKey: string
                                  editDate?: any | null
                                  modificationVersion?: number | null
                                  replyToKey?: string | null
                                  sortOrder?: number | null
                                  commentType?: string | null
                                  state?: string | null
                                  color: string
                                  persistanceState?: Types.PersistanceState | null
                                  persistanceAction?: Types.PersistanceAction | null
                                  node: {
                                    __typename: "ST4Node"
                                    aspectId: any
                                    id: string
                                    content?:
                                      | { __typename: "LexiconContent" }
                                      | { __typename: "LexiconEntryContent" }
                                      | { __typename: "MediaContent" }
                                      | { __typename: "MediaGroupContent" }
                                      | { __typename: "ProjectContent" }
                                      | { __typename: "TermContent" }
                                      | { __typename: "TextContent"; id: string; historyNumber: number }
                                      | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                                      | { __typename: "VariableTableContent" }
                                      | null
                                  }
                                  actions: Array<{
                                    __typename: "Action"
                                    name?: string | null
                                    enabled?: boolean | null
                                  }>
                                }
                          }>
                        }
                      | { __typename: "VariableTableContent" }
                      | null
                  }
                }>
                comments: Array<{
                  __typename: "CommentNode"
                  id: string
                  comment:
                    | {
                        __typename: "BlockComment"
                        location: number
                        value?: string | null
                        authorDisplayName?: string | null
                        authorUserName?: string | null
                        commentKey: string
                        editDate?: any | null
                        modificationVersion?: number | null
                        replyToKey?: string | null
                        sortOrder?: number | null
                        commentType?: string | null
                        state?: string | null
                        color: string
                        persistanceState?: Types.PersistanceState | null
                        persistanceAction?: Types.PersistanceAction | null
                        node: {
                          __typename: "ST4Node"
                          aspectId: any
                          id: string
                          content?:
                            | { __typename: "LexiconContent" }
                            | { __typename: "LexiconEntryContent" }
                            | { __typename: "MediaContent" }
                            | { __typename: "MediaGroupContent" }
                            | { __typename: "ProjectContent" }
                            | { __typename: "TermContent" }
                            | { __typename: "TextContent"; id: string; historyNumber: number }
                            | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                            | { __typename: "VariableTableContent" }
                            | null
                        }
                        actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
                      }
                    | {
                        __typename: "TextComment"
                        endLocation: number
                        startLocation?: number | null
                        value?: string | null
                        authorDisplayName?: string | null
                        authorUserName?: string | null
                        commentKey: string
                        editDate?: any | null
                        modificationVersion?: number | null
                        replyToKey?: string | null
                        sortOrder?: number | null
                        commentType?: string | null
                        state?: string | null
                        color: string
                        persistanceState?: Types.PersistanceState | null
                        persistanceAction?: Types.PersistanceAction | null
                        node: {
                          __typename: "ST4Node"
                          aspectId: any
                          id: string
                          content?:
                            | { __typename: "LexiconContent" }
                            | { __typename: "LexiconEntryContent" }
                            | { __typename: "MediaContent" }
                            | { __typename: "MediaGroupContent" }
                            | { __typename: "ProjectContent" }
                            | { __typename: "TermContent" }
                            | { __typename: "TextContent"; id: string; historyNumber: number }
                            | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                            | { __typename: "VariableTableContent" }
                            | null
                        }
                        actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
                      }
                }>
              }
            | { __typename: "VariableTableContent" }
            | null
        } | null
      }
    | null
  >
}

export type TextContentForComment_TextContent_Fragment = {
  __typename: "TextContent"
  id: string
  historyNumber: number
  comments: Array<{
    __typename: "CommentNode"
    id: string
    comment:
      | {
          __typename: "BlockComment"
          location: number
          value?: string | null
          authorDisplayName?: string | null
          authorUserName?: string | null
          commentKey: string
          editDate?: any | null
          modificationVersion?: number | null
          replyToKey?: string | null
          sortOrder?: number | null
          commentType?: string | null
          state?: string | null
          color: string
          persistanceState?: Types.PersistanceState | null
          persistanceAction?: Types.PersistanceAction | null
          node: {
            __typename: "ST4Node"
            aspectId: any
            id: string
            content?:
              | { __typename: "LexiconContent" }
              | { __typename: "LexiconEntryContent" }
              | { __typename: "MediaContent" }
              | { __typename: "MediaGroupContent" }
              | { __typename: "ProjectContent" }
              | { __typename: "TermContent" }
              | { __typename: "TextContent"; id: string; historyNumber: number }
              | { __typename: "TextGroupContent"; id: string; historyNumber: number }
              | { __typename: "VariableTableContent" }
              | null
          }
          actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
        }
      | {
          __typename: "TextComment"
          endLocation: number
          startLocation?: number | null
          value?: string | null
          authorDisplayName?: string | null
          authorUserName?: string | null
          commentKey: string
          editDate?: any | null
          modificationVersion?: number | null
          replyToKey?: string | null
          sortOrder?: number | null
          commentType?: string | null
          state?: string | null
          color: string
          persistanceState?: Types.PersistanceState | null
          persistanceAction?: Types.PersistanceAction | null
          node: {
            __typename: "ST4Node"
            aspectId: any
            id: string
            content?:
              | { __typename: "LexiconContent" }
              | { __typename: "LexiconEntryContent" }
              | { __typename: "MediaContent" }
              | { __typename: "MediaGroupContent" }
              | { __typename: "ProjectContent" }
              | { __typename: "TermContent" }
              | { __typename: "TextContent"; id: string; historyNumber: number }
              | { __typename: "TextGroupContent"; id: string; historyNumber: number }
              | { __typename: "VariableTableContent" }
              | null
          }
          actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
        }
  }>
}

export type TextContentForComment_TextGroupContent_Fragment = {
  __typename: "TextGroupContent"
  id: string
  historyNumber: number
  comments: Array<{
    __typename: "CommentNode"
    id: string
    comment:
      | {
          __typename: "BlockComment"
          location: number
          value?: string | null
          authorDisplayName?: string | null
          authorUserName?: string | null
          commentKey: string
          editDate?: any | null
          modificationVersion?: number | null
          replyToKey?: string | null
          sortOrder?: number | null
          commentType?: string | null
          state?: string | null
          color: string
          persistanceState?: Types.PersistanceState | null
          persistanceAction?: Types.PersistanceAction | null
          node: {
            __typename: "ST4Node"
            aspectId: any
            id: string
            content?:
              | { __typename: "LexiconContent" }
              | { __typename: "LexiconEntryContent" }
              | { __typename: "MediaContent" }
              | { __typename: "MediaGroupContent" }
              | { __typename: "ProjectContent" }
              | { __typename: "TermContent" }
              | { __typename: "TextContent"; id: string; historyNumber: number }
              | { __typename: "TextGroupContent"; id: string; historyNumber: number }
              | { __typename: "VariableTableContent" }
              | null
          }
          actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
        }
      | {
          __typename: "TextComment"
          endLocation: number
          startLocation?: number | null
          value?: string | null
          authorDisplayName?: string | null
          authorUserName?: string | null
          commentKey: string
          editDate?: any | null
          modificationVersion?: number | null
          replyToKey?: string | null
          sortOrder?: number | null
          commentType?: string | null
          state?: string | null
          color: string
          persistanceState?: Types.PersistanceState | null
          persistanceAction?: Types.PersistanceAction | null
          node: {
            __typename: "ST4Node"
            aspectId: any
            id: string
            content?:
              | { __typename: "LexiconContent" }
              | { __typename: "LexiconEntryContent" }
              | { __typename: "MediaContent" }
              | { __typename: "MediaGroupContent" }
              | { __typename: "ProjectContent" }
              | { __typename: "TermContent" }
              | { __typename: "TextContent"; id: string; historyNumber: number }
              | { __typename: "TextGroupContent"; id: string; historyNumber: number }
              | { __typename: "VariableTableContent" }
              | null
          }
          actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
        }
  }>
}

export type TextContentForCommentFragment =
  | TextContentForComment_TextContent_Fragment
  | TextContentForComment_TextGroupContent_Fragment

export type St4NodeForCommentFragment = {
  __typename: "ST4Node"
  id: string
  aspectId: any
  content?:
    | { __typename: "LexiconContent" }
    | { __typename: "LexiconEntryContent" }
    | { __typename: "MediaContent" }
    | { __typename: "MediaGroupContent" }
    | { __typename: "ProjectContent" }
    | { __typename: "TermContent" }
    | {
        __typename: "TextContent"
        id: string
        historyNumber: number
        comments: Array<{
          __typename: "CommentNode"
          id: string
          comment:
            | {
                __typename: "BlockComment"
                location: number
                value?: string | null
                authorDisplayName?: string | null
                authorUserName?: string | null
                commentKey: string
                editDate?: any | null
                modificationVersion?: number | null
                replyToKey?: string | null
                sortOrder?: number | null
                commentType?: string | null
                state?: string | null
                color: string
                persistanceState?: Types.PersistanceState | null
                persistanceAction?: Types.PersistanceAction | null
                node: {
                  __typename: "ST4Node"
                  aspectId: any
                  id: string
                  content?:
                    | { __typename: "LexiconContent" }
                    | { __typename: "LexiconEntryContent" }
                    | { __typename: "MediaContent" }
                    | { __typename: "MediaGroupContent" }
                    | { __typename: "ProjectContent" }
                    | { __typename: "TermContent" }
                    | { __typename: "TextContent"; id: string; historyNumber: number }
                    | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                    | { __typename: "VariableTableContent" }
                    | null
                }
                actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
              }
            | {
                __typename: "TextComment"
                endLocation: number
                startLocation?: number | null
                value?: string | null
                authorDisplayName?: string | null
                authorUserName?: string | null
                commentKey: string
                editDate?: any | null
                modificationVersion?: number | null
                replyToKey?: string | null
                sortOrder?: number | null
                commentType?: string | null
                state?: string | null
                color: string
                persistanceState?: Types.PersistanceState | null
                persistanceAction?: Types.PersistanceAction | null
                node: {
                  __typename: "ST4Node"
                  aspectId: any
                  id: string
                  content?:
                    | { __typename: "LexiconContent" }
                    | { __typename: "LexiconEntryContent" }
                    | { __typename: "MediaContent" }
                    | { __typename: "MediaGroupContent" }
                    | { __typename: "ProjectContent" }
                    | { __typename: "TermContent" }
                    | { __typename: "TextContent"; id: string; historyNumber: number }
                    | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                    | { __typename: "VariableTableContent" }
                    | null
                }
                actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
              }
        }>
      }
    | {
        __typename: "TextGroupContent"
        id: string
        historyNumber: number
        comments: Array<{
          __typename: "CommentNode"
          id: string
          comment:
            | {
                __typename: "BlockComment"
                location: number
                value?: string | null
                authorDisplayName?: string | null
                authorUserName?: string | null
                commentKey: string
                editDate?: any | null
                modificationVersion?: number | null
                replyToKey?: string | null
                sortOrder?: number | null
                commentType?: string | null
                state?: string | null
                color: string
                persistanceState?: Types.PersistanceState | null
                persistanceAction?: Types.PersistanceAction | null
                node: {
                  __typename: "ST4Node"
                  aspectId: any
                  id: string
                  content?:
                    | { __typename: "LexiconContent" }
                    | { __typename: "LexiconEntryContent" }
                    | { __typename: "MediaContent" }
                    | { __typename: "MediaGroupContent" }
                    | { __typename: "ProjectContent" }
                    | { __typename: "TermContent" }
                    | { __typename: "TextContent"; id: string; historyNumber: number }
                    | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                    | { __typename: "VariableTableContent" }
                    | null
                }
                actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
              }
            | {
                __typename: "TextComment"
                endLocation: number
                startLocation?: number | null
                value?: string | null
                authorDisplayName?: string | null
                authorUserName?: string | null
                commentKey: string
                editDate?: any | null
                modificationVersion?: number | null
                replyToKey?: string | null
                sortOrder?: number | null
                commentType?: string | null
                state?: string | null
                color: string
                persistanceState?: Types.PersistanceState | null
                persistanceAction?: Types.PersistanceAction | null
                node: {
                  __typename: "ST4Node"
                  aspectId: any
                  id: string
                  content?:
                    | { __typename: "LexiconContent" }
                    | { __typename: "LexiconEntryContent" }
                    | { __typename: "MediaContent" }
                    | { __typename: "MediaGroupContent" }
                    | { __typename: "ProjectContent" }
                    | { __typename: "TermContent" }
                    | { __typename: "TextContent"; id: string; historyNumber: number }
                    | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                    | { __typename: "VariableTableContent" }
                    | null
                }
                actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
              }
        }>
      }
    | { __typename: "VariableTableContent" }
    | null
}

export type CommentNodeFragment = {
  __typename: "CommentNode"
  id: string
  comment:
    | {
        __typename: "BlockComment"
        location: number
        value?: string | null
        authorDisplayName?: string | null
        authorUserName?: string | null
        commentKey: string
        editDate?: any | null
        modificationVersion?: number | null
        replyToKey?: string | null
        sortOrder?: number | null
        commentType?: string | null
        state?: string | null
        color: string
        persistanceState?: Types.PersistanceState | null
        persistanceAction?: Types.PersistanceAction | null
        node: {
          __typename: "ST4Node"
          aspectId: any
          id: string
          content?:
            | { __typename: "LexiconContent" }
            | { __typename: "LexiconEntryContent" }
            | { __typename: "MediaContent" }
            | { __typename: "MediaGroupContent" }
            | { __typename: "ProjectContent" }
            | { __typename: "TermContent" }
            | { __typename: "TextContent"; id: string; historyNumber: number }
            | { __typename: "TextGroupContent"; id: string; historyNumber: number }
            | { __typename: "VariableTableContent" }
            | null
        }
        actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
      }
    | {
        __typename: "TextComment"
        endLocation: number
        startLocation?: number | null
        value?: string | null
        authorDisplayName?: string | null
        authorUserName?: string | null
        commentKey: string
        editDate?: any | null
        modificationVersion?: number | null
        replyToKey?: string | null
        sortOrder?: number | null
        commentType?: string | null
        state?: string | null
        color: string
        persistanceState?: Types.PersistanceState | null
        persistanceAction?: Types.PersistanceAction | null
        node: {
          __typename: "ST4Node"
          aspectId: any
          id: string
          content?:
            | { __typename: "LexiconContent" }
            | { __typename: "LexiconEntryContent" }
            | { __typename: "MediaContent" }
            | { __typename: "MediaGroupContent" }
            | { __typename: "ProjectContent" }
            | { __typename: "TermContent" }
            | { __typename: "TextContent"; id: string; historyNumber: number }
            | { __typename: "TextGroupContent"; id: string; historyNumber: number }
            | { __typename: "VariableTableContent" }
            | null
        }
        actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
      }
}

export type CommentInfoFields_BlockComment_Fragment = {
  __typename: "BlockComment"
  location: number
  value?: string | null
  authorDisplayName?: string | null
  authorUserName?: string | null
  commentKey: string
  editDate?: any | null
  modificationVersion?: number | null
  replyToKey?: string | null
  sortOrder?: number | null
  commentType?: string | null
  state?: string | null
  color: string
  persistanceState?: Types.PersistanceState | null
  persistanceAction?: Types.PersistanceAction | null
  node: {
    __typename: "ST4Node"
    aspectId: any
    id: string
    content?:
      | { __typename: "LexiconContent" }
      | { __typename: "LexiconEntryContent" }
      | { __typename: "MediaContent" }
      | { __typename: "MediaGroupContent" }
      | { __typename: "ProjectContent" }
      | { __typename: "TermContent" }
      | { __typename: "TextContent"; id: string; historyNumber: number }
      | { __typename: "TextGroupContent"; id: string; historyNumber: number }
      | { __typename: "VariableTableContent" }
      | null
  }
  actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
}

export type CommentInfoFields_TextComment_Fragment = {
  __typename: "TextComment"
  endLocation: number
  startLocation?: number | null
  value?: string | null
  authorDisplayName?: string | null
  authorUserName?: string | null
  commentKey: string
  editDate?: any | null
  modificationVersion?: number | null
  replyToKey?: string | null
  sortOrder?: number | null
  commentType?: string | null
  state?: string | null
  color: string
  persistanceState?: Types.PersistanceState | null
  persistanceAction?: Types.PersistanceAction | null
  node: {
    __typename: "ST4Node"
    aspectId: any
    id: string
    content?:
      | { __typename: "LexiconContent" }
      | { __typename: "LexiconEntryContent" }
      | { __typename: "MediaContent" }
      | { __typename: "MediaGroupContent" }
      | { __typename: "ProjectContent" }
      | { __typename: "TermContent" }
      | { __typename: "TextContent"; id: string; historyNumber: number }
      | { __typename: "TextGroupContent"; id: string; historyNumber: number }
      | { __typename: "VariableTableContent" }
      | null
  }
  actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
}

export type CommentInfoFieldsFragment = CommentInfoFields_BlockComment_Fragment | CommentInfoFields_TextComment_Fragment

export const CommentInfoFieldsFragmentDoc = gql`
  fragment CommentInfoFields on IComment {
    value
    authorDisplayName
    authorUserName
    commentKey
    editDate
    modificationVersion
    replyToKey
    sortOrder
    commentType
    state
    color
    persistanceState @client
    persistanceAction @client
    node {
      id
      ... on ST4Node {
        aspectId
        content {
          ... on ITextContent {
            id
            historyNumber
          }
        }
      }
    }
    actions {
      name
      enabled
    }
    ... on TextComment {
      endLocation
      startLocation
    }
    ... on BlockComment {
      location
    }
  }
`
export const CommentNodeFragmentDoc = gql`
  fragment CommentNode on CommentNode {
    id
    comment {
      ...CommentInfoFields
    }
  }
  ${CommentInfoFieldsFragmentDoc}
`
export const TextContentForCommentFragmentDoc = gql`
  fragment TextContentForComment on ITextContent {
    id
    historyNumber
    comments {
      ...CommentNode
    }
  }
  ${CommentNodeFragmentDoc}
`
export const St4NodeForCommentFragmentDoc = gql`
  fragment ST4NodeForComment on ST4Node {
    id
    aspectId
    content {
      ...TextContentForComment
    }
  }
  ${TextContentForCommentFragmentDoc}
`
export const CommentsForNodesDocument = gql`
  query CommentsForNodes($aspectId: AspectSelectorId!, $nodeIds: [ID!]!, $includeNodeComments: Boolean!) {
    nodes(ids: $nodeIds, languageId: $aspectId, variant: "InformationPool") {
      ... on ST4TreeNode {
        id
        node {
          ... on ST4Node {
            ...ST4NodeForComment
            properties(variant: "ReviewMetadata") @include(if: $includeNodeComments) {
              edges {
                node {
                  displayName
                  name
                  value
                }
              }
            }
            content {
              ... on TextGroupContent {
                children {
                  id
                  target {
                    ...ST4NodeForComment
                    ... on ST4Node {
                      content {
                        ... on ITextContent {
                          fragments {
                            contentNode {
                              ...ST4NodeForComment
                            }
                          }
                          parts {
                            contentNode {
                              ...ST4NodeForComment
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              ... on ITextContent {
                fragments {
                  compoundId
                  contentNode {
                    ...ST4NodeForComment
                  }
                }
                parts {
                  ref
                  contentNode {
                    ...ST4NodeForComment
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${St4NodeForCommentFragmentDoc}
`

/**
 * __useCommentsForNodesQuery__
 *
 * To run a query within a React component, call `useCommentsForNodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommentsForNodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommentsForNodesQuery({
 *   variables: {
 *      aspectId: // value for 'aspectId'
 *      nodeIds: // value for 'nodeIds'
 *      includeNodeComments: // value for 'includeNodeComments'
 *   },
 * });
 */
export function useCommentsForNodesQuery(
  baseOptions: Apollo.QueryHookOptions<CommentsForNodesQuery, CommentsForNodesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CommentsForNodesQuery, CommentsForNodesQueryVariables>(CommentsForNodesDocument, options)
}
export function useCommentsForNodesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CommentsForNodesQuery, CommentsForNodesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CommentsForNodesQuery, CommentsForNodesQueryVariables>(CommentsForNodesDocument, options)
}
export type CommentsForNodesQueryHookResult = ReturnType<typeof useCommentsForNodesQuery>
export type CommentsForNodesLazyQueryHookResult = ReturnType<typeof useCommentsForNodesLazyQuery>
export type CommentsForNodesQueryResult = Apollo.QueryResult<CommentsForNodesQuery, CommentsForNodesQueryVariables>
