import React from "react"
import { Button, Space } from "antd"
import styled from "styled-components"
import { StyledAlert } from "../StyledAlert"
import { Icon, Regular } from "@st4/icons"

type ConfirmBannerProps = OptionalSecondaryButtonConfirmBannerProps | DoubleButtonConfirmBannerProps

type BaseConfirmBannerProps = {
  title: React.ReactNode
  description: React.ReactNode

  primaryButtonLabel: React.ReactNode
  onClickPrimary?: () => void
}

type OptionalSecondaryButtonConfirmBannerProps = BaseConfirmBannerProps & {
  state: "info"
  secondaryButtonLabel?: React.ReactNode
  onClickSecondary?: () => void
}

type DoubleButtonConfirmBannerProps = BaseConfirmBannerProps & {
  state: "warning" | "error" | "question"
  secondaryButtonLabel: React.ReactNode
  onClickSecondary?: () => void
}

export function ConfirmBanner(props: ConfirmBannerProps) {
  //AntD has no question. Instead we use info with a custom icon and colors for questions
  const convertedState = props.state !== "question" ? props.state : "info"
  let convertedIcon
  switch (props.state) {
    case "info":
      convertedIcon = <Icon component={Regular.CircleInfo} />
      break
    case "warning":
      convertedIcon = <Icon component={Regular.CircleExclamation} />
      break
    case "error":
      convertedIcon = <Icon component={Regular.TriangleExclamation} />
      break
    case "question":
      convertedIcon = <Icon component={Regular.CircleQuestion} />
      break
  }
  return (
    <AlertWithQuestion
      state={props.state}
      type={convertedState}
      banner
      message={props.title}
      description={props.description}
      icon={convertedIcon}
      action={
        <Space direction={"horizontal"}>
          {props.secondaryButtonLabel && <Button onClick={props.onClickSecondary}>{props.secondaryButtonLabel}</Button>}
          <Button type={"primary"} onClick={props.onClickPrimary}>
            {props.primaryButtonLabel}
          </Button>
        </Space>
      }
    />
  )
}

const AlertWithQuestion = styled(StyledAlert)<{ state: "info" | "error" | "warning" | "question" }>`
  ${({ theme, state }) => state === "question" && `background-color: ${theme.questionBG};`};

  & .ant-alert-icon {
    ${({ theme, state }) => state === "question" && `color: ${theme.question};`};
  }
`
