import { keys } from "@st4/ui-strings"
import React from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import type { Node } from "unist"
import type { ImageType } from "./types"

export function LayoutInfo(props: { xastNode?: Node; mediaInfo: { width?: number | null; height?: number | null } }) {
  return (
    <LayoutInfoBadge
      imgType={props.xastNode?.attributes?.["type"]?.value as ImageType}
      width={props.mediaInfo.width}
      height={props.mediaInfo.height}
    />
  )
}

const Badge = styled.div`
  height: 35px;
  padding: 5px;
  display: flex;
  gap: 10px;
  justify-content: flex-start;
  align-items: center;
  cursor: default;
  margin: 1px;
`
const ImageMetadataTitle = styled.span`
  display: block;
  font-size: ${({ theme }) => theme.token.fontSize}px;
  font-weight: normal;
`

const BadgeContent = styled.div`
  flex: 1 0 auto;
  font-size: ${({ theme }) => theme.token.fontSizeSM}px;
  font-weight: lighter;
`

function LayoutInfoBadge(props: { imgType?: ImageType; width?: number | null; height?: number | null }) {
  const { t } = useTranslation()
  return (
    <Badge>
      <BadgeContent>
        <ImageMetadataTitle>{t(keys.label.preview.imageDimensions)}</ImageMetadataTitle>
        {props.width && props.height ? props.width + " x " + props.height + "px" : t(keys.label.generic.unknown)}
      </BadgeContent>
      {props.imgType && (
        <BadgeContent>
          <ImageMetadataTitle>{t(keys.label.preview.imageLayoutType)}</ImageMetadataTitle>
          <ImageTypeDescription imgType={props.imgType} />
        </BadgeContent>
      )}
    </Badge>
  )
}

function ImageTypeDescription(props: { imgType: ImageType }) {
  const { t } = useTranslation()
  const imageTypes = {
    img_original: t(keys.description.image.width.original),
    img_fixed_column: t(keys.description.image.width.fixedColumn),
    img_fixed_textarea: t(keys.description.image.width.fixedTextarea),
    img_margin: t(keys.description.image.width.margin),
    img_50: t(keys.description.image.width["50"]),
    img_80: t(keys.description.image.width["80"]),
  }
  return <>{imageTypes[props.imgType]}</>
}
