import * as React from "react"
import { SVGProps } from "react"

const SvgSt4FileWrenchRegular = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1em" height="1em" {...props}>
    <path d="M296.7 344.1c36.4 0 66.3-30 66.3-66.3 0-6.4 0-12.8-2.1-19.3-2.1-4.3-6.4-6.4-10.7-2.1l-32.1 32.1c-2.1 2.1-2.1 2.1-4.3 2.1h-23.5c-4.3 0-6.4-2.1-6.4-6.4v-23.5c0-2.1 0-4.3 2.1-4.3l32.1-32.1c4.3-4.3 2.1-8.6-2.1-10.7-6.4-2.1-12.8-2.1-19.3-2.1-36.4 0-66.3 30-66.3 66.3 0 8.6 2.1 15 4.3 23.5l-74.9 74.9c-8.6 6.4-10.7 12.8-10.7 21.4 0 15 12.8 27.8 27.8 27.8 8.6 0 15-2.1 19.3-8.6l74.9-74.9c8.4 2.2 17 2.2 25.6 2.2zm-113.5 57.8c-6.4 0-10.7-4.3-10.7-10.7s4.3-8.6 10.7-8.6 8.6 4.3 8.6 8.6-4.3 10.7-8.6 10.7zm246.1-292.7-90.6-90.5C326.7 6.7 310.5 0 293.5 0H128C92.7 0 64 28.7 64 64v384c0 35.3 28.7 64 64 64h256c35.3 0 64-28.7 64-64V154.5c0-17-6.7-33.3-18.7-45.3zM400 448c0 8.8-7.2 16-16 16H128c-8.8 0-16-7.2-16-16V64c0-8.8 7.2-16 16-16h160v80c0 17.7 14.3 32 32 32h80v288z" />
  </svg>
)

export default SvgSt4FileWrenchRegular
