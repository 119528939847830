import React, { useState } from "react"
import { Button, Modal } from "antd"
import { areSearchOptionsEqual, SearchOptions } from "../../types"
import { getDefaultSearchOptions } from "../../storage"
import { useLanguage, useSettings } from "@st4/settings"
import { useTranslation } from "react-i18next"
import { keys } from "@st4/ui-strings"
import { SearchOptionsForm } from "."
import { Icon, Regular } from "@st4/icons"

export const baseTestId = "searchBox"

type SearchOptionsButtonProps = {
  searchOptions: SearchOptions
  onSaveOptions: (options: SearchOptions) => void
}

export function SearchOptionsButton(props: SearchOptionsButtonProps) {
  const [showDialog, setShowDialog] = useState(false)

  return (
    <>
      <Button
        name={`${baseTestId}-button-searchOptions`}
        data-testid={`${baseTestId}-button-searchOptions`}
        onClick={() => !showDialog && setShowDialog(true)}
        icon={<Icon component={Regular.SlidersSimple} color={"#001C36"} />}
      />
      {showDialog && <SearchOptionsDialog searchOptions={props.searchOptions} onCloseDialog={closeHandler} />}
    </>
  )

  function closeHandler(options?: SearchOptions) {
    //options == undefined --> dialog has been canceled
    //options != undefined --> dialog has been confirmed
    if (options) props.onSaveOptions(options)
    setShowDialog(false)
  }
}

type SearchOptionsDialogProps = {
  searchOptions: SearchOptions
  onCloseDialog: (options?: SearchOptions) => void
}

function SearchOptionsDialog(props: SearchOptionsDialogProps) {
  const { t } = useTranslation()

  const language = useLanguage()
  const settings = useSettings()
  const defaultSearchOptions = getDefaultSearchOptions(language.languageId)
  const [searchOptions, setSearchOptions] = useState(props.searchOptions)

  const languageId = language.languageId
  return (
    <Modal
      data-testid={`${baseTestId}-dialog-searchOptions`}
      title={t(keys.label.search.blade.searchOptionsBlade.title)}
      centered={true}
      closable={true}
      open={true}
      destroyOnClose={true}
      onCancel={cancelHandler}
      onOk={okHandler}
      footer={[
        <Button
          key={"reset"}
          name={`${baseTestId}-dialog-searchOptions-button-reset`}
          data-testid={`${baseTestId}-dialog-searchOptions-button-reset`}
          onClick={resetHandler}
          disabled={areSearchOptionsEqual(searchOptions, defaultSearchOptions)}
        >
          {t(keys.button.search.blade.searchOptionsBlade.reset)}
        </Button>,
        <Button
          key={"save"}
          name={`${baseTestId}-dialog-searchOptions-button-save`}
          data-testid={`${baseTestId}-dialog-searchOptions-button-save`}
          onClick={okHandler}
          type={"primary"}
        >
          {t(keys.button.general.choose)}
        </Button>,
      ]}
    >
      <SearchOptionsForm
        data-testid={`${baseTestId}-dialog-searchOptions-form`}
        availableDataValues={settings?.configuration.searchableDataValues || []}
        availableLanguages={
          settings?.configuration.languageIdList
            .filter((l) => settings.configuration.searchableAspects.includes(l.languageId.split(";")[0]))
            .map((l) => {
              const current: string = l.languageId
              return {
                // remove GUI language ID from aspect selector as it is always identical to the user's GUI language
                id: languageId.startsWith(current) ? languageId : current.split(";")[0],
                code: l.language,
                name: l.languageName,
              }
            }) || []
        }
        selectedUserLanguage={settings?.userConfiguration?.aspectSelectorId}
        selectedSearchOptions={searchOptions}
        onSearchKeysChanged={(newSearchKeys) => setSearchOptions({ ...searchOptions, searchKeys: newSearchKeys })}
        onSearchLanguagesChanged={(newSearchLanguages) =>
          setSearchOptions({ ...searchOptions, searchLanguages: newSearchLanguages })
        }
        onSearchVersionChanged={(newSearchVersion) =>
          setSearchOptions({ ...searchOptions, searchVersion: newSearchVersion })
        }
      />
    </Modal>
  )

  function okHandler() {
    props.onCloseDialog(searchOptions)
  }

  function cancelHandler() {
    props.onCloseDialog()
  }

  function resetHandler() {
    setSearchOptions(defaultSearchOptions)
  }
}
