import { makeVar, useReactiveVar } from "@apollo/client"
import { TransitionOptions, isTransitionOptions } from "../definition/transitionOptions"
import { TransitionInfo } from "../taskcontext/Screen"

/** Contains all transitions on the current screen. */
export const TRANSITIONINFOVAR = makeVar<TransitionInfo>({
  followTransition() {
    return Promise.reject()
  },
  transitions: [],
})

function isMenuOption(
  transition: TransitionInfo["transitions"][number],
): transition is TransitionInfo<Extract<TransitionOptions, { type: "menu" }>>["transitions"][number] {
  return isTransitionOptions("menu", transition.options)
}

/**
 * Returns all transitions of type "menu", optionally filtered.
 * @param menuName If passed a value, the TransitionOptions will be filtered for this `menuName`. If ommited, all options will be returned.
 * @returns An object which includes all matching transitions and means to follow/execute those.
 */
export function useMenuTranstionOptions(
  menuName?: string,
): TransitionInfo<Extract<TransitionOptions, { type: "menu" }>> {
  const info = useReactiveVar(TRANSITIONINFOVAR)
  const transitions = info.transitions
    .filter(isMenuOption)
    .filter((to) => !menuName || to.options.menuName === menuName)

  return {
    followTransition: info.followTransition,
    transitions,
  }
}
