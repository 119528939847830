import * as React from "react"
import { SVGProps } from "react"

const SvgTaxOptScopeUppertree = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16.311 11.5" {...props}>
    <defs>
      <style>
        {
          ".TaxOpt_Scope_Uppertree_svg__cls-1{fill:none;stroke:#0050b3;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px}"
        }
      </style>
    </defs>
    <g
      id="TaxOpt_Scope_Uppertree_svg__Komponente_36_1"
      data-name="Komponente 36 \u2013 1"
      transform="translate(1.061 .75)"
    >
      <g id="TaxOpt_Scope_Uppertree_svg__Gruppe_4773" data-name="Gruppe 4773" transform="translate(-4.5 -7)">
        <path
          id="TaxOpt_Scope_Uppertree_svg__Pfad_4641"
          data-name="Pfad 4641"
          className="TaxOpt_Scope_Uppertree_svg__cls-1"
          d="M4.5 10.5 8 7l3.5 3.5"
        />
        <path
          id="TaxOpt_Scope_Uppertree_svg__Pfad_4642"
          data-name="Pfad 4642"
          className="TaxOpt_Scope_Uppertree_svg__cls-1"
          d="M8 7v6a4 4 0 0 0 4 4h7"
        />
      </g>
    </g>
  </svg>
)

export default SvgTaxOptScopeUppertree
