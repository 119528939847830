import React, { ReactNode } from "react"
import styled from "styled-components"
import { TextNodeState } from "../types"
import { PreviewParameterContext, ReadonlyPreviewParameterContext } from "../../../PreviewParameterContext"
import { NodeEditorButton } from "./NodeEditorButton"

const RelativeStyledNodeActionsContainerButtons = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 7px;
  padding-right: 7px;
  gap: 4px;
`

const StyledNodeActionsContainer = styled.div`
  display: flex;
`

const StyledNodeActionChildren = styled.div`
  flex: 1;
  max-width: 100%;
`

type NodeActionsContainerProps = {
  nodeId: string
  treeNodeId: string
  textNodeState: TextNodeState
  children: ReactNode
  previewParameters: Exclude<PreviewParameterContext, ReadonlyPreviewParameterContext>
}

function getOpenEditor(previewParameters: Exclude<PreviewParameterContext, ReadonlyPreviewParameterContext>) {
  let openEditor = async (nodeId: string, treeNodeId: string) => {}
  if (previewParameters.nodeContextMenu && previewParameters.nodeContextMenu.length > 0) {
    openEditor = previewParameters.nodeContextMenu[0].onClick
  }

  return openEditor
}

export function NodeActionsContainer({
  textNodeState,
  children,
  previewParameters,
  nodeId,
  treeNodeId,
}: NodeActionsContainerProps) {
  let StyledNodeActionsContainerButtons = null

  if (textNodeState === "READONLY") {
    StyledNodeActionsContainerButtons = (
      <RelativeStyledNodeActionsContainerButtons className="NodeActions">
        <NodeEditorButton
          textNodeState={textNodeState}
          nodeId={nodeId}
          treeNodeId={treeNodeId}
          openEditor={getOpenEditor(previewParameters)}
        />
      </RelativeStyledNodeActionsContainerButtons>
    )
  }
  if (textNodeState === "ERROR_ADVANCED") {
    StyledNodeActionsContainerButtons = <RelativeStyledNodeActionsContainerButtons className="NodeActions" />
  }

  return (
    <StyledNodeActionsContainer className="NodeActionsContainer">
      {StyledNodeActionsContainerButtons}

      <StyledNodeActionChildren>{children}</StyledNodeActionChildren>
    </StyledNodeActionsContainer>
  )
}
