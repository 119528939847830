import React from "react"
import { createGlobalStyle } from "styled-components"
import { DotsLeft } from "./Logo"

const headerBackground = `linear-gradient(41deg, rgba(0,65,125,1) 0%, rgba(0,65,125,1) 30%, rgba(4,37,74,1) 100%)`

const Background = createGlobalStyle`
  body {
    background: ${headerBackground}
  }
`

function LoginBackground() {
  return (
    <>
      <Background />
      <DotsLeft style={{ position: "fixed", width: "100vw", height: "100vh" }} />
    </>
  )
}

export default LoginBackground
