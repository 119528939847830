import * as React from "react"
import { SVGProps } from "react"

const SvgSt4FileWarningLight = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1em" height="1em" {...props}>
    <path d="M63.1 64.3c0-36 28.3-64.3 64.3-64.3h156.9c12.9 0 25.7 5.1 33.4 12.9l115.8 115.8c10.3 10.3 15.4 20.6 15.4 33.4v285.5c0 36-28.3 64.3-64.3 64.3H127.4c-36 0-64.3-28.3-64.3-64.3V64.3zm352.4 128.6H302.3c-25.7 0-48.9-20.6-48.9-48.9V30.9h-126c-18 0-30.9 15.4-30.9 30.9v385.9c0 18 15.4 30.9 30.9 30.9h257.2c18 0 30.9-15.4 30.9-30.9V192.9zm-2.6-38.6L297.2 38.6c-2.6-2.6-5.1-2.6-7.7-5.1v113.2c0 7.7 7.7 15.4 15.4 15.4h113.2c-2.6-5.2-5.2-7.8-5.2-7.8zM256 221.2c7.7 0 12.9 2.6 18 10.3l108 185.2c2.6 5.1 2.6 12.9 0 20.6s-10.3 10.3-18 10.3H148c-7.7 0-12.9-5.1-18-10.3-2.6-5.1-2.6-12.9 0-20.6l108-185.2c5.1-5.1 10.3-10.3 18-10.3zm0 64.3c-7.7 0-12.9 5.1-12.9 12.9V355c0 7.7 5.1 12.9 12.9 12.9 7.7 0 12.9-5.1 12.9-12.9v-56.6c0-7.7-5.2-12.9-12.9-12.9zm15.4 113.2c0-7.7-7.7-15.4-15.4-15.4s-15.4 7.7-15.4 15.4 7.7 15.4 15.4 15.4c7.7.1 15.4-7.7 15.4-15.4z" />
  </svg>
)

export default SvgSt4FileWarningLight
