import React, { useEffect, useMemo } from "react"
import { keys } from "@st4/ui-strings"
import { i18n } from "@schema/utils"
import { isTypename } from "@st4/graphql"
import { Translate } from "@st4/i18n"
import styled from "styled-components"
import { InsertFragmentCommandQuery, useInsertFragmentCommandQuery } from "./query.hooks"
import { NotificationInfo } from "../types"
import { Select } from "antd"
import { listType } from "./types"
import { v4 as uuidv4 } from "uuid"

type FragmentTreeActionBarProps = {
  selectedNode?: {
    id: string
    classHierarchy: string[]
  }
  contextNode: string
  aspectId: string
  variant: string
  allowedOperations: string[]
  setNotifications: (notifications: NotificationInfo[]) => void
  setInsertFragmentMessage: (insertMessage: Messages) => void
  setNoDataAvailable: (noDataAvailable: boolean) => void
}

type InsertFragmentCommandDataRequest = {
  selectedNode: string
  contextNode: string
  aspectId: string
  variant: string
}

type Messages =
  | {
      action: "onNodeSelected"
      payload: {
        id: string
        classHierarchy: string[]
      }
    }
  | {
      action: "onClose"
      payload: any
    }
  | {
      action: "insertFragment"
      payload: {
        fragmentId: string
        fragmentType: string
        listType: string
      }
    }

const Container = styled.div`
  padding: 24px;
  flex: 0;
  border-top: 1px solid #ecedee;
`

const FormfieldsContainer = styled.div`
  flex: 1;
  margin-left: 16px;
`

export function FragmentTreeActionBar(props: FragmentTreeActionBarProps) {
  const {
    selectedNode,
    contextNode,
    aspectId,
    variant,
    allowedOperations,
    setNotifications,
    setInsertFragmentMessage,
    setNoDataAvailable,
  } = props
  const [listType, setListType] = React.useState<listType>("ol")

  const contextNodeId = contextNode.replace("x-stobject:", "x-pattern-context:/")

  const request: InsertFragmentCommandDataRequest = {
    selectedNode: selectedNode?.id ?? "",
    contextNode: contextNodeId,
    aspectId: aspectId!,
    variant: variant,
  }

  const { data, loading } = useInsertFragmentCommandQuery({
    variables: request,
    fetchPolicy: "no-cache",
    errorPolicy: "all",
    skip:
      !selectedNode ||
      (selectedNode.classHierarchy.at(-1) !== "TextModule2" &&
        selectedNode.classHierarchy.at(-1) !== "TextModuleGroup"),
  })

  const fragmentType = getFragmentElement(data)

  const notifications = useMemo(() => {
    return getNotificationInfo({
      data,
      selectedNode,
      allowedOperations,
      fragmentType,
    })
  }, [allowedOperations, data, selectedNode, fragmentType])

  useEffect(() => {
    if (notifications) setNotifications(notifications)
  }, [notifications, setNotifications, selectedNode])

  useEffect(() => {
    const noDataAvailable = loading || !data
    setNoDataAvailable(noDataAvailable)
  }, [data, loading, setNoDataAvailable])

  const handleListTypeChange = (value: listType) => {
    setListType(value)
  }

  useEffect(() => {
    const insertFragmentMsg: Messages = {
      action: "insertFragment",
      payload: {
        fragmentId: selectedNode?.id ?? "",
        fragmentType: fragmentType,
        listType: listType,
      },
    }
    setInsertFragmentMessage(insertFragmentMsg)
  }, [fragmentType, listType, selectedNode?.id, setInsertFragmentMessage])

  const listTypesList = [
    { value: "ol", label: <Translate>{keys.label.listtype.ordered}</Translate> },
    { value: "ul", label: <Translate>{keys.label.listtype.unordered}</Translate> },
    { value: "ul2", label: <Translate>{keys.label.listtype.secondLevel}</Translate> },
  ]

  if (fragmentType !== "li") {
    return <div style={{ display: "none" }}></div>
  }

  return (
    <Container>
      <FormfieldsContainer>
        <fieldset>
          <div>
            <Translate>{keys.label.fragmentPoolBlade.listType}</Translate>
          </div>
          <Select
            data-testid="fragmenttree:list:type:selection"
            style={{ width: "100%" }}
            value={listType}
            options={listTypesList}
            onChange={handleListTypeChange}
            dropdownMatchSelectWidth={false}
          />
        </fieldset>
      </FormfieldsContainer>
    </Container>
  )
}

type getNotificationInfoProps = {
  data: InsertFragmentCommandQuery | undefined
  selectedNode?: {
    id: string
    classHierarchy: string[]
  }
  allowedOperations: string[]
  fragmentType: string
}

function getNotificationInfo(props: getNotificationInfoProps): NotificationInfo[] {
  if (!props.selectedNode) return []

  if (
    props.selectedNode?.classHierarchy.at(-1) !== "TextModule2" &&
    props.selectedNode?.classHierarchy.at(-1) !== "TextModuleGroup"
  ) {
    return [
      {
        id: uuidv4(),
        nodeId: props.selectedNode?.id ?? "",
        title: "",
        description: i18n.t(keys.message.fragmentPoolBlade.no_fragment),
        severity: "info",
        disableInsert: true,
      },
    ]
  }

  if (!isOperationAllowed(props.fragmentType, props.allowedOperations)) {
    return [
      {
        id: uuidv4(),
        nodeId: props.selectedNode?.id ?? "",
        title: "",
        description: i18n.t(
          props.fragmentType
            ? keys.message.fragmentPoolBlade.not_possible_structure_fragment
            : keys.message.fragmentPoolBlade.not_possible_fragment,
        ),
        severity: "error",
        disableInsert: true,
      },
    ]
  }

  const commandInfos = props.data?.commands
  let disableInsert = true
  let userNotifications: NotificationInfo[] = []

  if (commandInfos) {
    const insertFragmentCommand = commandInfos.find((x) => x.command === "INSERT_FRAGMENT")

    if (isTypename("AllowedCommand")(insertFragmentCommand)) {
      disableInsert = false
    }

    const notificationTexts = isTypename("AllowedCommand")(insertFragmentCommand)
      ? insertFragmentCommand.warnings
      : insertFragmentCommand?.reasons

    if (notificationTexts && notificationTexts.length > 0) {
      userNotifications = notificationTexts
        .map((reason) => {
          const notificationText = reason.toLocaleLowerCase()
          if (!Object.keys(keys.message.fragmentPoolBlade).includes(notificationText)) return

          const notificationKey = notificationText as keyof typeof keys.message.fragmentPoolBlade
          const localizedText = i18n.t(keys.message.fragmentPoolBlade[notificationKey])

          return {
            title: "",
            description: localizedText,
            severity: disableInsert ? "error" : "warning",
            disableInsert,
          }
        })
        .filter((notification): notification is NotificationInfo => !!notification)
    }
  }

  return userNotifications
}

function getFragmentElement(data: InsertFragmentCommandQuery | undefined): string {
  const treeNode = data?.tree?.nodes[0]
  if (!isTypename("ST4TreeNode")(treeNode)) return ""

  if (!treeNode.node || !isTypename("ST4Node")(treeNode.node)) return ""

  const metaData = treeNode.node.metadata
  return metaData.find((x) => x.systemName === "Content.FragmentElement")?.value ?? ""
}

function isOperationAllowed(fragmentType: string, allowedOperations: string[]) {
  if (!allowedOperations || !allowedOperations.find((x) => x === getOperationNameFor(fragmentType))) return false

  return true
}

function getOperationNameFor(fragmentType: string) {
  if (!fragmentType) return "insert-fragment"

  switch (fragmentType) {
    case "table-container":
    case "safety":
    case "image-container":
    case "li":
      return `insert-structure-fragment-${fragmentType.toLocaleLowerCase()}`
    case "condition":
    case "instruction":
    case "intermediateresult":
    case "result":
      return `insert-structure-fragment-procedural-instruction`
    case "safety_condition":
    case "safety_instruction":
    case "safety_intermediateresult":
    case "safety_result":
      return `insert-structure-fragment-measure`
    default:
      break
  }
}
