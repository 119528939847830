import styled from "styled-components"
import React from "react"
import { Table, Spin } from "antd"
import type { TableProps } from "antd/lib/table"
import { Empty } from "antd"
import { ContentWidthLimiting, NodeHeader, NodeWrapper, useIsNodeSelected } from "./utils"
import { usePreviewContentModel } from "../../contentModel"
import type { NodeRenderProps } from "./types"
import { renderCell, Entry } from "@st4/content-tools"

const StyledTable = styled(Table as (props: TableProps<Record<string, Entry>>) => JSX.Element)`
  .ant-table-cell:first-child {
    background-color: #fafafa;
    font-weight: 500;
  }
  .ant-table-cell {
    min-width: 10em;
  }
  .ant-table-sticky-scroll {
    display: none;
  }
  & .ant-table-tbody > tr > td {
    white-space: pre;
  }
`

export function VariableTableNode({ node, treeNode }: NodeRenderProps) {
  const previewContentModel = usePreviewContentModel()
  const tableSource = previewContentModel.state === "ready" ? previewContentModel.variableTableById.get(node.id) : null

  const selected = useIsNodeSelected(treeNode)
  if (!tableSource || tableSource.dataSource.length === 0)
    return (
      <NodeWrapper selected={selected}>
        <NodeHeader node={treeNode} icon={treeNode.icon} title={node.label} />
        <Spin spinning={tableSource == null} delay={500}>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </Spin>
      </NodeWrapper>
    )

  const columns = (tableSource?.columns ?? []).map((column) => ({
    ...column,
    render: renderCell.bind(null, tableSource.rows),
  }))

  return (
    <ContentWidthLimiting>
      <NodeWrapper selected={selected}>
        <NodeHeader node={treeNode} icon={treeNode.icon} title={node.label} />
        <StyledTable
          rowKey={(r) => r.rowTitle.rowIndex}
          dataSource={tableSource?.dataSource}
          columns={columns}
          scroll={{ x: true }}
          sticky
          pagination={{ hideOnSinglePage: true }}
        />
      </NodeWrapper>
    </ContentWidthLimiting>
  )
}
