import { defineTask, defineScope } from "@st4/tasks"
import { keys } from "@st4/ui-strings"
import React from "react"
import { Translate } from "@st4/i18n"
import { showSearchResultScreen } from "./screens"
import { Regular } from "@st4/icons"

export const showSearchResult = defineTask({
  name: "showSearchResult",
  displayName: <Translate>{keys.label.search.taskDefinition.showSearchResult.displayName}</Translate>,
  icon: Regular.FileMagnifyingGlass,
  singleton: true,
  resumable: false,
  manuallyStartable: false,
  initialScope: "showSearchResult",
  scopes: {
    showSearchResult: defineScope({
      displayName: "",
      transitions: {
        "start-->showSearchResult": {
          type: "start",
        },
      },
      screens: {
        showSearchResult: showSearchResultScreen,
      },
    }),
  },
})
