import type { Resolvers } from "@apollo/client"

export default {
  TextComment: {
    persistanceState: (obj: any) => (!obj.persistanceState ? "SERVER" : obj.persistanceState),
    persistanceAction: (obj: any) => obj.persistanceAction || null,
  },
  BlockComment: {
    persistanceState: (obj: any) => (!obj.persistanceState ? "SERVER" : obj.persistanceState),
    persistanceAction: (obj: any) => obj.persistanceAction || null,
  },
} as Resolvers
