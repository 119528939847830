import React from "react"
import styled from "styled-components"
import { Icon } from "react-icons-kit"
import { globe } from "react-icons-kit/feather/globe"
import { useState } from "react"
import { box } from "react-icons-kit/feather/box"
import { PersonInfo } from "../widgets/personInfo"
import { LoadingPlaceholder } from "@schema/styled-ui"
import { useTranslation } from "react-i18next"
import { keys } from "@st4/ui-strings"
import { useNavigationContext } from "../navigationContext"
import type { ReactProp } from "../../types"
import type { LinkMetadataQuery } from "../../graphql/applicationQueries/query.hooks"
import { useLinkMetadataQuery } from "../../graphql/applicationQueries/query.hooks"
import type { ExtractByTypeName } from "@st4/graphql"
import { isTypename } from "@st4/graphql"

const LinkText = styled.a`
  color: ${({ theme }) => theme.greys["400"]};
`

const ICON_SIZE = 16
const LinkTypeIcon = styled(Icon)`
  flex: 0;
`

const MetaDataContainer = styled.div`
  min-height: 150px;
  width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
`

const ErrorContainer = styled(MetaDataContainer)`
  text-align: center;
`

const TeaserImage = styled.img`
  width: 100%;
  position: relative;
  height: auto;
`

const IconImage = styled.img`
  max-height: 20px;
  width: auto;
  margin-right: 5px;
  flex: 0 0 auto;
`

const MetadataTitle = styled.a`
  display: block;
  flex: 1;
  font-size: ${({ theme }) => theme.token.fontSizeXL}px;
  font-weight: bold;
  text-decoration: none;
  color: ${({ theme }) => theme.greys["600"]};
  cursor: pointer;
`

const MetadataLink = styled.span`
  display: block;
  flex: 1;
  font-size: ${({ theme }) => theme.token.fontSizeXL}px;
  font-weight: bold;
  text-decoration: none;
  color: ${({ theme }) => theme.greys["600"]};
  cursor: pointer;
`
MetadataLink.displayName = "MetadataLink"

const ErrorASCII = styled.div`
  color: ${({ theme }) => theme.notificationColors.error.normal};
  font-size: 50px;
  font-weight: bold;
`

function htmlDecode(input?: string | null) {
  if (!input) return ""
  const tmpContainer = document.createElement("textarea")
  tmpContainer.innerHTML = input
  return tmpContainer.innerText
}

function HttpTeaserData({
  data,
  loading,
}: {
  data: Partial<ExtractByTypeName<"ExternalSite", NonNullable<LinkMetadataQuery["node"]>>["teaserData"]> & {
    target: string
  }
  loading: boolean
}) {
  return loading ? (
    <LinkMetaContainerLoading />
  ) : data.status === "ONLINE" ? (
    <>
      <LinkMetaContainer
        image={data.imageUrl || ""}
        icon={data.iconUrl || ""}
        title={htmlDecode(data.title || "")}
        summary={htmlDecode(data.summary || "")}
        target={data.target}
      />
    </>
  ) : (
    <>
      <LinkMetaContainerError status={data.status ?? "UNKNOWN"} target={data.target} />
    </>
  )
}

function ST4TeaserData({ data }: { data: ReactProp<typeof ST4LinkMetaContainer, "node"> & { label?: string | null } }) {
  return <ST4LinkMetaContainer title={htmlDecode(data.label)} node={data} />
}

export type HTTPLinkData = {
  url: string
}

export function HttpLinkMetadataPopup(props: { linkData: HTTPLinkData }) {
  const { loading, data } = useLinkMetadataQuery({
    variables: { id: props.linkData.url },
  })
  const node = data?.node
  const teaserData = { ...(isTypename("ExternalSite")(node) ? node.teaserData : {}), target: props.linkData.url }
  return <HttpTeaserData loading={loading} data={teaserData} />
}

export function ST4LinkMetadataPopup({ linkNode: teaserData }: { linkNode: ReactProp<typeof ST4TeaserData, "data"> }) {
  return <ST4TeaserData data={teaserData} />
}

type LinkMetaContainerProps = {
  title: string
  image: string
  icon: string
  summary: string
  target: string
}

function LinkMetaContainerLoading() {
  return (
    <>
      <TeaserImageContainer displayImage={true}>
        <LoadingPlaceholder width="150" height="150" />
      </TeaserImageContainer>
      <MetaDataContainer>
        <LoadingPlaceholder width="350" height="1.618em" />
        <LoadingPlaceholder width="500" height="100" />
        <LoadingPlaceholder width="200" height="1em" />
      </MetaDataContainer>
    </>
  )
}

const TeaserImageContainer = styled.div<{ displayImage: boolean }>`
  width: ${({ displayImage }) => (displayImage ? "150px" : 0)};
  position: relative;
  height: 150px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  display: flex;
  padding-left: 10px;
`

function LinkMetaContainer(props: LinkMetaContainerProps) {
  const { title, image, icon, summary, target } = props
  const [displayImage, setDisplayImage] = useState(false)
  const { t } = useTranslation()
  return (
    <div style={{ display: "flex" }}>
      {image ? (
        <TeaserImageContainer displayImage={displayImage}>
          <TeaserImage src={image} onError={() => setDisplayImage(false)} onLoad={() => setDisplayImage(true)} />
        </TeaserImageContainer>
      ) : null}
      <MetaDataContainer>
        <div style={{ display: "flex", flexDirection: "row", width: "100%", alignItems: "center" }}>
          {icon ? <IconImage src={icon} /> : null}
          <MetadataTitle
            href={target}
            target="_blank"
            title={t(keys.description.hyperlink.opensInNewWindow)}
            rel="noopener noreferrer"
          >
            {title}
          </MetadataTitle>
          <LinkTypeIcon size={ICON_SIZE} icon={globe} />
        </div>
        <div>{summary}</div>
        <LinkText
          target="_blank"
          rel="noopener noreferrer"
          href={target}
          title={t(keys.description.hyperlink.opensInNewWindow)}
        >
          {target}
        </LinkText>
      </MetaDataContainer>
    </div>
  )
}

function ST4LinkMetaContainer(props: {
  title: string
  node: ReactProp<typeof PersonInfo, "node"> & { id: string; aspectId: string }
}) {
  const { title, node } = props
  const { onNavigate } = useNavigationContext()
  return (
    <>
      <MetaDataContainer>
        <div style={{ display: "flex", flexDirection: "row", width: "100%", alignItems: "center" }}>
          <MetadataLink onClick={() => onNavigate({ type: "node", nodeId: node.id, aspectId: node.aspectId })}>
            {title}
          </MetadataLink>
          <LinkTypeIcon size={ICON_SIZE} icon={box} />
        </div>
        <PersonInfo node={node} />
      </MetaDataContainer>
    </>
  )
}

function LinkMetaContainerError({ status, target }: { status: string; target: string }) {
  return (
    <ErrorContainer>
      <ErrorASCII>:(</ErrorASCII>
      <LinkTypeIcon size={ICON_SIZE} icon={globe} />
      <h2>{status}</h2>
      <LinkText target="_blank" rel="noopener noreferrer" href={target}>
        {target}
      </LinkText>
    </ErrorContainer>
  )
}
