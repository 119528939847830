import * as React from "react"
import { SVGProps } from "react"

const SvgGlobePointer = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1em" height="1em" {...props}>
    <path d="M303.6 415.6C283 456.8 263.4 464 256 464c-3.1 0-8.3-1.3-14.8-5.7l-15.3 52c9.9 1.2 19.9 1.8 30.1 1.8 141.4 0 256-114.6 256-256S397.4 0 256 0 0 114.6 0 256c0 10.2.6 20.2 1.8 30.1l46.9-13.8c-.4-5.4-.6-10.8-.6-16.3 0-16.5 1.9-32.6 5.6-48h76.7c-1.3 13.3-2 27-2.2 41l48.4-14.2c.4-9.1 1.1-18 2-26.7h155c1.6 15.3 2.5 31.4 2.5 48s-.9 32.7-2.5 48h-47l-14.1 48h53.2c-5.6 24.4-13.2 45.9-22 63.6zM208.4 96.4C229 55.2 248.6 48 256 48s27 7.2 47.6 48.4c8.8 17.7 16.4 39.2 22 63.6H186.4c5.6-24.4 13.2-45.9 22-63.6zM384 256c0-16.4-.8-32.5-2.2-48h76.7c3.6 15.4 5.6 31.5 5.6 48s-1.9 32.6-5.6 48h-76.7c1.5-15.5 2.2-31.6 2.2-48zm-9.3-96c-7.3-35.9-18.5-67.7-32.6-93.4 42.3 19.3 77 52.3 98.4 93.4h-65.8zm-303.3 0c21.4-41.1 56.1-74.1 98.4-93.4-14.1 25.6-25.3 57.5-32.6 93.4H71.4zm303.3 192h65.9c-21.4 41.1-56.1 74.1-98.4 93.4 14.1-25.6 25.3-57.5 32.6-93.4zM39 308.5c-13.9 4.1-15.6 23.2-2.6 29.7l57.3 28.7c1.3.7 2.6 1.5 3.7 2.5l-88 88c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l88-88c1 1.1 1.9 2.3 2.5 3.7l28.7 57.3c6.5 13 25.6 11.3 29.7-2.6l60.2-204.8c3.6-12.1-7.7-23.4-19.9-19.9L39 308.5z" />
  </svg>
)

export default SvgGlobePointer
