import * as React from "react"
import { SVGProps } from "react"

const SvgBan = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1em" height="1em" {...props}>
    <path d="M385.1 419.1 92.9 126.9C64.8 162.3 48 207.2 48 256c0 114.9 93.1 208 208 208 48.8 0 93.7-16.8 129.1-44.9zm33.9-33.9c28.2-35.5 45-80.4 45-129.2 0-114.9-93.1-208-208-208-48.8 0-93.7 16.8-129.1 44.9l292.2 292.2zM0 256a256 256 0 1 1 512 0 256 256 0 1 1-512 0z" />
  </svg>
)

export default SvgBan
