import React from "react"
import { InputRef } from "antd"

export const useIsHorizontalOverflow = (ref: React.RefObject<HTMLElement>) => {
  const [isHorizontalOverflow, setIsHorizontalOverflow] = React.useState(false)

  React.useLayoutEffect(() => {
    const { current } = ref
    if (!current) return

    const trigger = () => {
      const hasOverflow = current.scrollWidth > current.clientWidth

      setIsHorizontalOverflow(hasOverflow)
    }

    if (current) {
      trigger()
    }
  }, [ref, ref?.current?.scrollWidth, ref?.current?.clientWidth])

  return isHorizontalOverflow
}

export function isHorizontalInputOverflow(ref: React.RefObject<InputRef>) {
  if (!ref?.current?.input) return false
  return ref.current.input.scrollWidth > ref.current.input.offsetWidth
}
