import styled, { css } from "styled-components"
import { darken } from "polished"

// prettier-ignore
const ListItem_deprecated = styled.div`  
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    background-color: ${props => props.selected ? darken(0.1, props.theme.backgroundColor) : "inherit"};
    border: 1px solid ${props => props.selected ? darken(0.1, props.theme.backgroundColor) : "inherit"};
    ${({ startNewGroup }) => startNewGroup ? css`
        &::before {
            content: " ";
            position: absolute;
            top: 0;
            display: block;
            background-color: ${props => props.theme.separatorColor};
            height: 1px;
            left: 10%;
            width: 75%;
        }` : ""
    }
`

export default ListItem_deprecated
