import styled from "styled-components"

// prettier-ignore
const Toolbar_deprecated = styled.div`
    display: flex;
    flex-direction: row;
    line-height: 1.42857;
    align-items: center;
    box-sizing: content-box; 
`
export default Toolbar_deprecated
