import * as React from "react"
import { SVGProps } from "react"

const SvgSt4AddVariantRegular = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="1em" height="1em" {...props}>
    <path d="M154.9 250.3c0 8.8 7.2 16 16 16h48v48c0 8.8 7.2 16 16 16s16-7.2 16-16v-48h48c8.8 0 16-7.2 16-16s-7.2-16-16-16h-48v-48c0-8.8-7.2-16-16-16s-16 7.2-16 16v48h-48c-8.8 0-16 7.2-16 16zM400 32H250.5c-17 0-33.3 6.7-45.3 18.7l-176 176c-25 25-25 65.5 0 90.5l133.5 133.5c25 25 65.5 25 90.5 0l176-176c12.1-12 18.8-28.2 18.8-45.2V80c0-26.5-21.5-48-48-48zm0 197.5c0 4.2-1.7 8.3-4.7 11.3l-176 176c-6.2 6.2-16.4 6.2-22.6 0L63.2 283.3c-6.2-6.2-6.2-16.4 0-22.6l176-176c3-3 7.1-4.7 11.3-4.7H400v149.5z" />
  </svg>
)

export default SvgSt4AddVariantRegular
