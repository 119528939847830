import * as React from "react"
import { SVGProps } from "react"

const SvgSt4RemovelinkRegular = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" width="1em" height="1em" {...props}>
    <path d="M506.4 243c-73.8 0-133.6 59.8-133.6 133.6s59.8 133.6 133.6 133.6S640 450.4 640 376.6 580.2 243 506.4 243zm41.3 157.1c4.9 4.9 4.9 12.8 0 17.7s-12.7 4.9-17.6.1l-23.6-23.6-23.6 23.6c-4.9 4.9-12.8 4.9-17.7 0s-4.9-12.8 0-17.7l23.6-23.6-23.7-23.6c-4.9-4.9-4.9-12.8 0-17.7s12.8-4.9 17.7 0l23.5 23.5 23.6-23.6c4.9-4.9 12.8-4.9 17.7 0s4.9 12.8 0 17.7L524 376.5l23.7 23.6zM409.4 98.8l6.1-5.4c38-33.2 95.2-31.3 130.9 4.4 28.6 28.6 35.3 71 20.1 106 3.2 1.1 6.3 2.4 9.5 3.7 11.8 5 22.9 11.1 33.2 18.3 24.6-53.2 15-118.3-28.9-162.2-53.5-53.5-139.4-56.4-196.4-6.5l-6.1 5.4c-10 8.7-11 23.9-2.3 33.9s23.9 11.1 33.9 2.4zm-88.3 277.7c0-4.2.1-8.3.4-12.4-8.7-4.4-16.8-10.3-24-17.5-35.7-35.7-37.6-92.9-4.4-130.9l4.7-5.4c8.7-10 7.7-25.1-2.3-33.9s-25.1-7.7-33.9 2.3l-4.7 5.4c-49.8 57-46.9 142.9 6.6 196.4 17.9 17.9 39.4 30.1 62.1 36.6-3-13.2-4.5-26.8-4.5-40.6zM207 165.4c37.4-37.4 98.1-37.4 135.6 0 23.8 23.8 32.6 57.2 25.9 88.1 2-2.3 4.2-4.5 6.3-6.6 12.8-12.8 27.1-23.4 42.8-31.9-3.9-30.6-17.6-60.2-40.9-83.6-56.2-56.2-147.3-56.2-203.5 0L59.7 244.8C3.5 301 3.5 392.1 59.7 448.2c53.6 53.6 139.5 56.4 196.5 6.5l6.1-5.4c10-8.7 11-23.9 2.3-33.9s-23.9-11-33.9-2.3l-6.1 5.4c-38 33.2-95.2 31.3-130.9-4.4-37.4-37.4-37.4-98.1 0-135.6L207 165.4z" />
  </svg>
)

export default SvgSt4RemovelinkRegular
