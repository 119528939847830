import { keys } from "@st4/ui-strings"
import React from "react"
import { Translate } from "@st4/i18n"
import { defineTask } from "@st4/tasks"
import { informationScope, projectsScope } from "./scopes"
import { Light } from "@st4/icons"

export const explore = defineTask({
  name: "explore",
  displayName: <Translate>{keys.label.explore.header}</Translate>,
  icon: Light.Compass,
  singleton: true,
  resumable: false,
  manuallyStartable: true,
  initialScope: "information",
  scopes: {
    information: informationScope,
    projects: projectsScope,
  },
})
