import type { Node as XASTNode, Position as NodePosition } from "unist"
// import { Xml } from "unist"

export const TYPES = {
  Root: "root",
  Element: "element",
  Text: "text",
  ProcessingInstruction: "PROCESSING_INSTRUCTION",
  Comment: "comment",
  Entity: "ENTITY",
  Unknown: "UNKOWN",
}

function map(fn: (x: Node) => XASTNode, nodeList: NodeListOf<ChildNode>) {
  return [].slice.call(nodeList).map((node) => fn(node))
}

export function Node(xmlNode: Node, data?: XASTNode["data"]): XASTNode {
  switch (xmlNode.nodeType) {
    case xmlNode.DOCUMENT_NODE:
    case xmlNode.ELEMENT_NODE:
      return Parent(xmlNode as Element, data)
    case xmlNode.TEXT_NODE:
    case xmlNode.CDATA_SECTION_NODE:
      return Literal(xmlNode as CDATASection, data)
    default:
      return {
        type: nodeType(xmlNode),
        position: Position(xmlNode as NodeWithPosition),
        data: { ...data },
        // _ref: new Xml(xmlNode),
      }
  }
}

interface NodeWithPosition extends Node {
  lineNumber: number
  columnNumber: number
}

interface CDATASectionWithPosition extends CDATASection {
  lineNumber: number
  columnNumber: number
}

interface ElementWithPosition extends Element {
  lineNumber: number
  columnNumber: number
}

export function Position(xmlNode: NodeWithPosition | CDATASectionWithPosition): NodePosition {
  return {
    start: {
      line: xmlNode.lineNumber,
      column: xmlNode.columnNumber,
      offset: 0,
    },
    end: {
      line: 0,
      column: 0,
      offset: 0,
    },
    indent: [0],
  }
}

function nodeType(xmlNode: Node) {
  switch (xmlNode.nodeType) {
    case xmlNode.DOCUMENT_NODE:
      return TYPES.Root
    case xmlNode.ELEMENT_NODE:
      return TYPES.Element
    case xmlNode.TEXT_NODE:
    case xmlNode.CDATA_SECTION_NODE:
      return TYPES.Text
    case xmlNode.PROCESSING_INSTRUCTION_NODE:
      return TYPES.ProcessingInstruction
    case xmlNode.ENTITY_NODE:
      return TYPES.Entity
    case xmlNode.COMMENT_NODE:
      return TYPES.Comment
    default:
      return TYPES.Unknown
  }
}

function properties(xmlNode: Element) {
  if (!xmlNode.attributes) return {}
  const properties: { [name: string]: string } = {}

  for (let i = 0; i < xmlNode.attributes.length; i++) {
    const { nodeName, nodeValue } = xmlNode.attributes[i]
    properties[nodeName] = nodeValue || ""
  }
  return properties
}

export function Parent(xmlNode: Element, data: XASTNode["data"]): XASTNode {
  return {
    type: nodeType(xmlNode),
    tagName: xmlNode.tagName,
    properties: properties(xmlNode),
    position: Position(xmlNode as ElementWithPosition),
    data: { ...data },
    children: map(Node, xmlNode.childNodes),
    // _ref: new Xml(xmlNode),
  }
}

export function Literal(xmlNode: CDATASection, data: XASTNode["data"]) {
  return {
    type: nodeType(xmlNode),
    // tagName: xmlNode.nodeName,
    position: Position(xmlNode as CDATASectionWithPosition),
    data: { ...data },
    value: xmlNode.data,
    // _ref: new Xml(xmlNode),
  }
}
