import React, { useMemo } from "react"
import type { Node } from "unist"
import { useMappingContext } from "../mappingContext"
import { renderXAST } from "../XASTRenderer"
import { useNode, useTreeNode } from "../../contentModel/nodeContext"
import { usePreviewContentModel } from "../../contentModel"

type XASTViewerProps = {
  /**
   * The xast to render.
   */
  xast: Node
}

/**
 * Renders a xast.
 * @param props
 */
export function XASTViewer({ xast }: XASTViewerProps) {
  const treeNode = useTreeNode()
  const node = useNode()
  const previewContentModel = usePreviewContentModel()
  const mapping = useMappingContext()

  const content = useMemo(() => {
    // Assumption: This never happens because preview only renders content when contentModel is ready
    if (previewContentModel.state != "ready")
      throw Error("previewContentModel must be ready when node components are rendered")

    const treeNodesById = previewContentModel.treeNodesById
    return renderXAST(xast, mapping, xast, node, treeNodesById, treeNode)
  }, [xast, mapping, node, treeNode, previewContentModel])
  return <>{content}</>
}
