import { useCallback } from "react"
import { usePreviewContentModel } from "../contentModel"
import { TreeNode, useTreeNode } from "../contentModel/nodeContext"

/**
 * Get function that allows getting the treeNode for a given st4Node.
 * The treeNode will be searched within the current treenode context
 */
export function useSt4ToTreeNode() {
  const contentModel = usePreviewContentModel()
  const contextTreeNode = useTreeNode()

  const finder = useCallback(
    (st4Node: { id: string } | null | undefined) => {
      if (contentModel.state !== "ready") return undefined
      const { treeNodesById } = contentModel
      return st4Node ? findSt4NodeInTree(treeNodesById, contextTreeNode!, st4Node) : undefined
    },
    [contentModel, contextTreeNode],
  )

  return finder
}

export function findSt4NodeInTree(
  treeNodeById: Map<string, TreeNode>,
  contextTreeNode: TreeNode,
  st4Node: { id: string },
) {
  return bfsFindFirst(treeNodeById, contextTreeNode, (t) => t.node.id === st4Node.id)
}

function bfsFindFirst(treeNodeById: Map<string, TreeNode>, treeNode: TreeNode, match: (treeNode: TreeNode) => boolean) {
  const queue = [treeNode]

  while (queue.length > 0) {
    // When length is positive, shift will certainly not return undefined
    const n = queue.shift()
    if (!n) continue
    if (match(n)) return n

    if (!n.children || n.children.length === 0) continue

    const childrenObjects = n.children.flatMap((child) => treeNodeById.get(child) ?? [])
    queue.push(...childrenObjects)
  }
}
