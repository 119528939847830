import * as React from "react"
import { SVGProps } from "react"

const SvgSt4ReferencedDocumentRegular = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1em" height="1em" {...props}>
    <path d="M242.4 437.1c0-41.4 33.5-74.9 74.9-74.9h30c8.3 0 15 6.7 15 15s-6.7 15-15 15h-30c-24.8 0-44.9 20.1-44.9 44.9s20.1 44.9 44.9 44.9h30c8.3 0 15 6.7 15 15s-6.7 15-15 15h-30c-41.4 0-74.9-33.5-74.9-74.9zm269.6 0c0 41.4-33.5 74.9-74.9 74.9h-30c-8.3 0-15-6.7-15-15s6.7-15 15-15h30c24.8 0 44.9-20.1 44.9-44.9s-20.1-44.9-44.9-44.9h-30c-8.3 0-15-6.7-15-15s6.7-15 15-15h30c41.4 0 74.9 33.5 74.9 74.9zm-179.7-15h89.9c8.3 0 15 6.7 15 15s-6.7 15-15 15h-89.9c-8.3 0-15-6.7-15-15s6.7-15 15-15zM201.1 464H128c-8.8 0-16-7.2-16-16V64c0-8.8 7.2-16 16-16h160v80c0 17.7 14.3 32 32 32h80v157.7h36.6c3.8 0 7.6.2 11.4.5V154.5c0-17-6.7-33.3-18.7-45.3l-90.6-90.5C326.7 6.7 310.5 0 293.5 0H128C92.7 0 64 28.7 64 64v384c0 35.3 28.7 64 64 64h96.9c-11.6-14.1-19.7-30.4-23.8-48z" />
  </svg>
)

export default SvgSt4ReferencedDocumentRegular
