import React, { useState, PropsWithChildren, useCallback } from "react"
import styled from "styled-components"
import { Icon } from "react-icons-kit"
import { box } from "react-icons-kit/feather/box"
import { externalLink } from "react-icons-kit/feather/externalLink"
import { ST4LinkMetadataPopup, HttpLinkMetadataPopup } from "./linkMetadata"
import { useNode } from "../../contentModel/nodeContext"
import { alertTriangle } from "react-icons-kit/feather/alertTriangle"
import { useTranslation } from "react-i18next"
import { keys } from "@st4/ui-strings"
import { isST4NodeWithContent, ST4NodeWithContent } from "../../graphql/applicationQueries"
import type { Node } from "unist"
import { useNavigationContext } from "../navigationContext"
import { Popover } from "antd"
import { isTypename } from "@st4/graphql"

const ICON_SIZE = 14

const LinkIcon = styled(Icon)`
  position: relative;
  top: -2px;
  margin-right: 2px;
`

export const StyledLinkText = styled.span<{ underline?: boolean }>`
  padding-left: 3px;
  padding-right: 3px;
  display: inline;
  cursor: pointer;
  background: inherit;
  border: none;
  color: ${(p) => p.theme.primaryColor};
  border-bottom: ${({ underline, theme }) =>
    underline === undefined || underline ? `1px solid ${theme.primaryColor}` : null};
`

const BrokenLink = styled(StyledLinkText as any)`
  color: ${(p) => p.theme.notificationColors.warning.darker};
  border-bottom: 1px solid ${({ theme }) => theme.notificationColors.warning.darker};
`

export function HttpLink(props: { ast: Node; children: React.ReactChild }) {
  const targetAddress = props.ast.attributes?.["href"]?.value ?? ""

  const link = (
    <StyledLinkText>
      <LinkIcon size={ICON_SIZE} icon={externalLink} />
      {props.children}
    </StyledLinkText>
  )

  if (/^https?:\/\//.test(targetAddress))
    return (
      <Popover placement="bottomLeft" content={<HttpLinkMetadataPopup linkData={{ url: targetAddress }} />}>
        {link}
      </Popover>
    )
  else return link
}

type LinkOptions = {
  linkid?: { value: string }
  dynamic?: { value: string }
}

function getNodeLink(node: ST4NodeWithContent, linkOptions?: LinkOptions) {
  if (!isST4NodeWithContent("TextContent", "TextGroupContent")(node)) return { current: null, isDynamic: false }
  const textContent = node.content
  const links = textContent.links
  const linkId = linkOptions?.linkid?.value
  const isDynamic = linkOptions?.dynamic && linkOptions?.dynamic.value == "true"
  const currentLink = links && links.find((lnk) => lnk?.ref === linkId)
  return {
    current: currentLink || null,
    isDynamic,
  }
}

interface ST4LinkProps {
  ast: Node
}

export function ST4Link(props: PropsWithChildren<ST4LinkProps>) {
  const [linkModalOpen, setLinkModalOpen] = useState(false)
  const node = useNode()
  const { t } = useTranslation()
  const { current, isDynamic } = getNodeLink(node, props.ast.attributes)
  const { onNavigate } = useNavigationContext()
  const handleClick = useCallback(
    function handleClick(_: React.PointerEvent<HTMLSpanElement>) {
      if (linkModalOpen && current && current.node && isTypename("ST4Node")(current.node)) {
        onNavigate({ type: "node", nodeId: current.node.id, aspectId: current.node.aspectId })
      }
    },
    [current, linkModalOpen, onNavigate],
  )

  if (!current || !current.node || !isTypename("ST4Node")(current.node)) {
    return (
      <BrokenLink>
        <LinkIcon size={ICON_SIZE} icon={alertTriangle} />
        {props.children} ({t(keys.label.hyperlink.broken)})
      </BrokenLink>
    )
  }

  return (
    <Popover
      onOpenChange={(visible) => setLinkModalOpen(visible)}
      placement="bottomLeft"
      content={<ST4LinkMetadataPopup linkNode={current.node} />}
    >
      <StyledLinkText onPointerUp={handleClick}>
        <LinkIcon size={ICON_SIZE} icon={box} />
        {isDynamic ? current && current.node.label : props.children}
      </StyledLinkText>
    </Popover>
  )
}
