import React from "react"
import { Collapse, Descriptions } from "antd"
import { groupBy as _groupBy } from "@schema/utils"
import styled from "styled-components"

const { Panel } = Collapse

// Extract all property keys that resolve to a string value
type StringTest<Type> = { [Property in keyof Type]: Type[Property] extends string ? Property : never }
// Evaluate conditional types
type StringProps<T> = StringTest<T>[keyof T]

const StyledCollapse = styled(Collapse)`
  width: 100%;
  border-radius: 0;
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
  }

  // Trick to increase specificity
  &&& .ant-descriptions-item-label {
    // Align label with the header of the collapse panel
    padding: 0.5em 0.5em 0.5em calc(0.5em + 24px);
  }

  &&& .ant-descriptions-item-content {
    padding: 0 0.5em;
  }

  .ant-collapse-item > .ant-collapse-header {
    padding: 0.5em;
    color: ${({ theme: { token } }) => token.colorText};
  }

  .ant-collapse-header {
    font-weight: 600;
    background: ${({ theme: { token } }) => token.colorBgElevated};
    border-bottom: 1px solid ${({ theme: { token } }) => token.colorBorderSecondary};
  }

  .ant-collapse-content,
  .ant-descriptions-item-label {
    background: ${({ theme: { token } }) => token.colorBgElevated};
    border-color: transparent;
  }

  & .ant-collapse-item {
    border-bottom: 1px solid ${({ theme: { token } }) => token.colorBorderSecondary};
  }

  .ant-input {
    text-overflow: ellipsis;
  }

  .ant-input[disabled] {
    text-overflow: ellipsis;
  }

  &&& .ant-descriptions-view > table {
    table-layout: fixed;
  }
`

type GroupListProps<T> = {
  items: T[]
  groupBy: ((item: T) => string) | StringProps<T>
  renderItem: (item: T) => React.ReactNode
  expandedKeys?: string[]
  onExpand?: (expandedKeys: string[]) => void
}

/**
 * Display Data in a grouped manner
 * The groups will be sorted in ascending lexical order
 * The items in the groups will be ordered by their original order in the items array
 * GroupList.Item is a wrapper you can use for your items for sensible styling
 * @param items
 * @param renderItem render an item as a React Component
 * @param groupBy Function assigning a string to each item which is used for Grouping, alternatively the name of a string
 *                Property of the Item
 * @param expandedKeys include if you want to control expanded keys
 * @param onExpand function providing all expanded keys on user expand
 */
export function GroupList<K extends PropertyKey, T extends Record<K, T[K]>>({
  groupBy,
  items,
  renderItem,
  expandedKeys,
  onExpand,
}: GroupListProps<T>) {
  const groupSelector = typeof groupBy === "function" ? groupBy : (item: T) => item[groupBy]
  const grouped = _groupBy(groupSelector, items)
  const sorted = [...grouped].sort(([g1name], [g2name]) => g1name.localeCompare(g2name))
  const sections: Array<[string, T[]]> = sorted.map(([header, items]: [string, T[]]) => [header, items])
  const defaulExpandedKey = sections.length > 0 ? [sections[0][0]] : []

  function handleOnChange(key: string | string[]) {
    if (!onExpand) return

    if (typeof key === "string") onExpand([key])
    else {
      onExpand(key)
    }
  }

  return (
    <StyledCollapse defaultActiveKey={expandedKeys ?? defaulExpandedKey} onChange={handleOnChange}>
      {sections.map(([header, items]) => (
        <Panel key={header} header={header}>
          <StyledDescription bordered size="small" column={1}>
            {items.map((item) => renderItem(item))}
          </StyledDescription>
        </Panel>
      ))}
    </StyledCollapse>
  )
}

type GroupListItemProps = {
  children: React.ReactNode
  label: React.ReactNode
}

const StyledDescription = styled(Descriptions)`
  .ant-descriptions-view {
    border: 0;
  }
`

const GroupListItem = styled(Descriptions.Item)<GroupListItemProps>``

GroupList.Item = GroupListItem
