import React from "react"
import { Card, Row, Col } from "antd"

import LoginBackground from "./LoginBackground"
import type { LayoutProps } from "./types"
import styled from "styled-components"

const Logo = styled.div<{ src: string }>`
  background: linear-gradient(41deg, rgba(0, 65, 125, 1) 0%, rgba(0, 65, 125, 1) 30%, rgba(4, 37, 74, 1) 100%);
  height: 55px;
  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background: url(${(p) => p.src});
    background-size: 80%;
    background-position: center;
    background-repeat: no-repeat;
  }
`

export function Layout({ children, logoSrc }: React.PropsWithChildren<LayoutProps>) {
  return (
    <>
      <LoginBackground />
      <Row justify="center" align="middle" style={{ height: "100vh" }}>
        <Col>
          <Card
            cover={logoSrc && <Logo src={logoSrc} />}
            style={{ width: "300px", boxShadow: "0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)" }}
          >
            {children}
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default Layout
