import React from "react"
import TextInput_deprecated from "./TextInput_deprecated"
import { dateFormatInputField, dayjs } from "@schema/utils"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { lighten } from "polished"
import styled from "styled-components"
import UnstyledDayPicker from "react-day-picker"
import { boxedInputStyles } from "@st4/theme-manager"
import classNames from "classnames"

const Wrapper = styled.div`
  position: relative;
  & ${TextInput_deprecated} input {
    text-indent: 24px;
  }
`

const DayPicker = styled(UnstyledDayPicker)`
  /* DayPicker styles */
  display: inline-block;
  ${boxedInputStyles}

  ${Wrapper}.invalid & {
    border: 1px solid ${(props) => props.theme.warningColor};
    box-shadow: 0 0 2px ${(props) => props.theme.warningColor};
  }

  .DayPicker-wrapper {
    position: relative;
    flex-direction: row;
    padding-bottom: 1em;
    user-select: none;
  }

  .DayPicker-Months {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .DayPicker-Month {
    display: table;
    margin: 0 1em;
    margin-top: 1em;
    border-spacing: 0;
    border-collapse: collapse;
    user-select: none;
  }

  .DayPicker-NavButton {
    position: absolute;
    top: 1em;
    right: 1.5em;
    left: auto;

    display: inline-block;
    margin-top: 2px;
    width: 1.25em;
    height: 1.25em;
    background-position: center;
    background-size: 50%;
    background-repeat: no-repeat;
    color: #8b9898;
    cursor: pointer;
  }

  .DayPicker-NavButton:hover {
    opacity: 0.8;
  }

  .DayPicker-NavButton--prev {
    margin-right: 1.5em;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAVVJREFUWAnN2G0KgjAYwPHpGfRkaZeqvgQaK+hY3SUHrk1YzNLay/OiEFp92I+/Mp2F2Mh2lLISWnflFjzH263RQjzMZ19wgs73ez0o1WmtW+dgA01VxrE3p6l2GLsnBy1VYQOtVSEH/atCCgqpQgKKqYIOiq2CBkqtggLKqQIKgqgCBjpJ2Y5CdJ+zrT9A7HHSTA1dxUdHgzCqJIEwq0SDsKsEg6iqBIEoq/wEcVRZBXFV+QJxV5mBtlDFB5VjYTaGZ2sf4R9PM7U9ZU+lLuaetPP/5Die3ToO1+u+MKtHs06qODB2zBnI/jBd4MPQm1VkY79Tb18gB+C62FdBFsZR6yeIo1YQiLJWMIiqVjQIu1YSCLNWFgijVjYIuhYYCKoWKAiiFgoopxYaKLUWOii2FgkophYp6F3r42W5A9s9OcgNvva8xQaysKXlFytoqdYmQH6tF3toSUo0INq9AAAAAElFTkSuQmCC");
  }

  .DayPicker-NavButton--next {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAXRJREFUWAnN119ugjAcwPHWzJ1gnmxzB/BBE0n24m4xfNkTaOL7wOtsl3AXMMb+Vjaa1BG00N8fSEibPpAP3xAKKs2yjzTPH9RAjhEo9WzPr/Vm8zgE0+gXATAxxuxtqeJ9t5tIwv5AtQAApsfT6TPdbp+kUBcgVwvO51KqVhMkXKsVJFXrOkigVhCIs1Y4iKlWZxB1rX4gwlpRIIpa8SDkWmggrFq4IIRaJKCYWnSgnrXIQV1r8YD+1Vrn+bReagysIFfLABRt31v8oBu1xEBttfRbltmfjgEcWh9snUS2kNdBK6WN1vrOWxObWsz+fjxevsxmB1GQDfINWiev83nhaoiB/CoOU438oPrhXS0WpQ9xc1ZQWxWHqUYe0I0qrKCQKjygDlXIQV2r0IF6ViEBxVTBBSFUQQNhVYkHIVeJAtkNsbQ7c1LtzP6FsObhb2rCKv7NBIGoq4SDmKoEgTirXAcJVGkFSVVpgoSrXICGUMUH/QBZNSUy5XWUhwAAAABJRU5ErkJggg==");
  }

  .DayPicker-NavButton--interactionDisabled {
    display: none;
  }

  .DayPicker-Caption {
    display: table-caption;
    margin-bottom: 0.5em;
    padding: 0 0.5em;
    text-align: left;
  }

  .DayPicker-Caption > div {
    font-weight: 500;
    font-size: 1.15em;
  }

  .DayPicker-Weekdays {
    display: table-header-group;
    margin-top: 1em;
  }

  .DayPicker-WeekdaysRow {
    display: table-row;
  }

  .DayPicker-Weekday {
    display: table-cell;
    padding: 0.5em;
    color: #8b9898;
    text-align: center;
    font-size: 0.875em;
  }

  .DayPicker-Weekday abbr[title] {
    border-bottom: none;
    text-decoration: none;
  }

  .DayPicker-Body {
    display: table-row-group;
  }

  .DayPicker-Week {
    display: table-row;
  }

  .DayPicker-Day {
    display: table-cell;
    padding: 0.5em;
    border-radius: 50%;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
  }

  .DayPicker-WeekNumber {
    display: table-cell;
    padding: 0.5em;
    min-width: 1em;
    border-right: 1px solid #eaecec;
    color: #8b9898;
    vertical-align: middle;
    text-align: right;
    font-size: 0.75em;
    cursor: pointer;
  }

  .DayPicker--interactionDisabled .DayPicker-Day {
    cursor: default;
  }

  .DayPicker-Footer {
    padding-top: 0.5em;
  }

  .DayPicker-TodayButton {
    border: none;
    background-color: transparent;
    background-image: none;
    box-shadow: none;
    color: #4a90e2;
    font-size: 0.875em;
    cursor: pointer;
  }

  /* Default modifiers */

  .DayPicker-Day--today {
    color: ${({ theme }) => theme.secondaryColor};
    font-weight: 700;
  }

  .DayPicker-Day--outside {
    color: #8b9898;
    cursor: default;
  }

  .DayPicker-Day--disabled {
    color: #dce0e0;
    cursor: not-allowed;
  }

  /* Example modifiers */
  .DayPicker-Day--sunday {
    background-color: #f7f8f8;
  }

  .DayPicker-Day--sunday:not(.DayPicker-Day--today) {
    color: #dce0e0;
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    position: relative;
    background-color: ${({ theme }) => theme.primaryColor};
    color: ${({ theme }) => theme.primaryAccentColor};
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
    background-color: ${({ theme }) => lighten(0.2, theme.primaryColor)};
  }

  .DayPicker:not(.DayPicker--interactionDisabled)
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background-color: #f0f8ff;
  }
`

class TextInputDate_deprecated extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedDate: props.val._data ? dayjs(props.val._data).toDate() : null,
    }
    this.inputRef = React.createRef()
    this.handleDateSelection = this.handleDateSelection.bind(this)
  }
  handleDateSelection(day, { selected, disabled }) {
    if (disabled) return
    let selectedDay = !selected ? day : null
    this.inputRef.current.value = selectedDay ? dayjs(selectedDay).format(dateFormatInputField) : ""
    this.props.onChange({ target: this.inputRef.current })
    this.setState({ selectedDate: selectedDay })
  }
  render() {
    const { maxDate, minDate, name, onChange } = this.props
    const min = minDate ? dayjs(minDate).toDate() : null
    const max = maxDate ? dayjs(maxDate).toDate() : null
    let initialMonth = new Date()
    if (this.state.selectedDate) initialMonth = this.state.selectedDate
    else if (min || max) {
      if (max < new Date()) initialMonth = min || max
      if (min > new Date()) initialMonth = min
    }

    return (
      <Wrapper className={classNames(this.props.className, { invalid: this.props.invalidInput })}>
        <input type="hidden" name={name} id={name} onChange={(e) => onChange(e)} ref={this.inputRef} />
        <DayPicker
          selectedDays={this.state.selectedDate}
          onDayClick={this.handleDateSelection}
          initialMonth={initialMonth}
          disabledDays={{
            before: min,
            after: max,
          }}
          showOutsideDays
          fromMonth={min}
          toMonth={max}
          months={dayjs.localeData().months()}
          weekdaysLong={dayjs.localeData().weekdays()}
          weekdaysShort={dayjs.localeData().weekdaysShort()}
          firstDayOfWeek={dayjs.localeData().firstDayOfWeek()}
        />
      </Wrapper>
    )
  }
}

TextInputDate_deprecated.propTypes = {
  className: PropTypes.string,
  defaultValue: PropTypes.shape({
    _data: PropTypes.string,
    _display: PropTypes.string,
  }),
  val: PropTypes.shape({
    _data: PropTypes.string,
    _display: PropTypes.string,
  }),
  onChange: PropTypes.func,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  onKeyPress: PropTypes.func,
  pattern: PropTypes.string,
  placeholder: PropTypes.string,
  readonly: PropTypes.bool,
  label: PropTypes.string,
}

export default withTranslation()(TextInputDate_deprecated)
