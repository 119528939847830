import { StringMap, TOptions } from "i18next"
import React from "react"
import { TFuncKey, useTranslation } from "react-i18next"

type TranslateProps = {
  children: TFuncKey
} & TOptions<StringMap>

export function Translate(props: TranslateProps) {
  const { t } = useTranslation()
  // HTML-Elemente, die die Größe 0x0 haben werden von Ranorex nicht korrekt identifiziert.
  return (
    <>
      <span data-testid={props.children} style={{ minHeight: "1px" }}></span>
      {t(props.children, props)}
    </>
  )
}
