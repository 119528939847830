import React from "react"
import { Alert } from "@schema/styled-ui"
import { Translate } from "@st4/i18n"

type AlertErrorProps = {
  messageKey?: string
  message?: string
}

export function AlertError(props: AlertErrorProps) {
  const { message, messageKey } = props

  return (
    <div style={{ paddingLeft: "8px", paddingRight: "8px" }}>
      <Alert isError>
        {!messageKey ? (
          <span data-testid="CreateNewNodeForm-system-job-failure">{message}</span>
        ) : (
          <Translate>{messageKey}</Translate>
        )}
      </Alert>
    </div>
  )
}
