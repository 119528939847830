import React from "react"
import ContentView from "./ContentView"
import { ContentProvider, ContentProviderProps } from "./ContentProvider"

export type ContentProps = ContentProviderProps

export function Content(props: ContentProps) {
  return (
    <ContentProvider {...props}>
      <ContentView />
    </ContentProvider>
  )
}
