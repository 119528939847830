export function isString(value: any): value is string {
  return typeof value === "string"
}

export function notEmpty<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined
}

function identity<T>(v: unknown): T {
  return v as T
}

/**
 * Creates a `Map<string, T>` for the passed array
 * @param array The values to add to the map
 * @param keyFn A function to select the key of the map entry
 * @param valueFn A function to select the value of the map entry
 */
export function mapFrom<E, T = E>(
  array: Array<E>,
  keyFn: (e: E) => string,
  valueFn: (e: E) => T | undefined = identity,
): Map<string, T> {
  const map = new Map<string, T>()
  array.forEach((entry) => {
    const v = valueFn(entry)
    if (v) {
      map.set(keyFn(entry), v)
    }
  })
  return map
}

/**
 * Removes the scheme part of an URI
 * @param uri The uri from wich the scheme should be removed
 * @example
 * scheme:part  -> part
 * noscheme     -> noscheme
 */
export function removeURIScheme(uri: string) {
  const parsed = new URL(uri)
  return parsed.href.replace(parsed.protocol, "")
}

/**
 * Removes leading/trailing characters from a string.
 * @param trimChar The character to remove from start and end of input
 * @param input The string from wich the trimChar should be removed
 * @example
 * trim(".", "..test..") -> "test"
 */
export function trim(trimChar: string, input: string) {
  const escape = "+*?^$\\.[]{}()|/".indexOf(trimChar) > -1 ? "\\" : ""
  const regex = new RegExp(`^${escape}${trimChar}?(.*?)${escape}${trimChar}?$`)
  return input.replace(regex, "$1")
}

/**
 * Allows multiple Refs to be merged (for example from forwardRef and useRef)
 * @param refs
 */
export function mergeRefs<TRef>(...refs: Array<React.MutableRefObject<TRef> | React.RefCallback<TRef> | null>) {
  const filteredRefs = refs.filter(Boolean)
  if (!filteredRefs.length) return null
  return (inst: TRef) => {
    for (const ref of filteredRefs) {
      if (typeof ref === "function") {
        ref(inst)
      } else if (ref) {
        ref.current = inst
      }
    }
  }
}

/**
 * Creates a filter for arrays to select distinct values only.
 * @param selector The selector to identify distinct values
 * @returns A function to pass into Array.filter()
 */
export function distinctBy<TItem>(selector: (item: TItem) => any) {
  return (currentItem: TItem, idx: number, arr: Array<TItem>) => {
    return arr.findIndex((x) => selector(x) === selector(currentItem)) === idx
  }
}

export { scrollElementVisible, useScrollElementVisible } from "./scrollElementVisible"
export { formatRelative as formatDateRelative } from "./dates"
export * from "./hooks"
