import React from "react"
import { Image } from "antd"

export function FullscreenImageModal({ src, onClosed }: { src: string; onClosed: () => void }) {
  return (
    <Image
      wrapperStyle={{ display: "none" }}
      src={src}
      preview={{
        visible: true,
        onVisibleChange: (newState) => {
          if (!newState) onClosed()
        },
      }}
    ></Image>
  )
}
