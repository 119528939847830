import React from "react"
import { formatDateRelative } from "../../utilities"
import { Skeleton } from "antd"
import styled from "styled-components"

const DateDisplay = styled.div`
  width: 100%;
  text-align: left;
  font-weight: 300;
  font-size: ${({ theme }) => theme.token.fontSizeSM}px;
  color: ${({ theme: { greys } }) => greys["400"]};
`

const Author = styled.div`
  font-weight: 300;
  color: ${({ theme: { greys } }) => greys["700"]};
`

const Content = styled.div`
  margin-top: 1em;
`

const Type = styled.div`
  font-weight: 300;
  font-size: ${({ theme }) => theme.token.fontSizeSM}px;
  color: ${({ theme: { greys } }) => greys["400"]};
  margin-top: -0.5em;
  margin-bottom: 0.5em;
`
Type.displayName = "Type"

const AnnotationContent = styled.div<{ highlight: boolean }>`
  box-sizing: border-box;
  width: 100%;
  padding: 20px 20px 0 20px;
  color: ${({ theme: { greys } }) => greys["900"]};
  background: ${({ highlight, theme }) => (highlight ? theme.preview.annotations.preColor.background : "white")};
  display: flex;
  flex-direction: column;
  /* grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-row-gap: 20px; */
`

export const AnnotationReply = styled.div`
  ${AnnotationContent} {
    grid-row-gap: 5px;
  }
`

export function AnnotationDisplay(
  props: React.PropsWithChildren<{
    author?: string
    date?: string
    highlight?: boolean
    type: string
    onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  }>,
) {
  return (
    <AnnotationContent onClick={props.onClick} highlight={!!props.highlight}>
      <Type>{props.type}</Type>
      {props.author && <Author>{props.author}</Author>}
      {props.date && <DateDisplay>{formatDateRelative(props.date)}</DateDisplay>}
      <Content>{props.children}</Content>
    </AnnotationContent>
  )
}
export function AnnotationDisplaySkeleton() {
  return (
    <AnnotationContent highlight={false}>
      <Type>
        <Skeleton paragraph={false} title={{ width: "25%" }} />
      </Type>
      <Author>
        <Skeleton paragraph={false} title={{ width: "30%" }} />
      </Author>
      <DateDisplay>
        <Skeleton paragraph={false} title={{ width: "20%" }} />
      </DateDisplay>
      <Content>
        <Skeleton title={false} />
      </Content>
    </AnnotationContent>
  )
}
