import { makeVar } from "@apollo/client"
import React, { useCallback, useEffect, useMemo } from "react"
import { usePreviewContentModel } from "../contentModel"
import { mapFrom } from "../utilities"
import throttle from "lodash.throttle"

/**
 * Contains the value of the current data-scroll-target at the top of the screen.
 */
export const SCROLL_VAR_NODE_ID_TOP_POS = makeVar<ContentTarget | null>(null)

function findMostVisibleNode(
  bestMatch: [string, IntersectionObserverEntry] | null,
  cantidate: [string, IntersectionObserverEntry],
) {
  const [_, currEntry] = cantidate
  //node not in viewport -ignore
  if (currEntry.intersectionRatio <= 0) {
    return bestMatch
  }

  //node start is above viewport, node end is below viewport -consider match
  if (
    currEntry.boundingClientRect.top < currEntry.intersectionRect.top &&
    currEntry.boundingClientRect.bottom > currEntry.intersectionRect.bottom
  ) {
    return cantidate
  }

  const buffer = 15
  //node start is above viewport -ignore
  if (currEntry.boundingClientRect.top + buffer < currEntry.intersectionRect.top) {
    return bestMatch
  }

  //node visible in viewport -consider match
  if (!bestMatch) {
    return cantidate
  }
  const [__, prevEntry] = bestMatch

  //node start is above current match -consider match
  if (currEntry.intersectionRect.top < prevEntry.intersectionRect.top) {
    return cantidate
  }

  return bestMatch
}

/**
 * Observe which element with a data-scroll-target attribute is most visible inside the rootRef.
 * Sets the value inside the SCROLL_VAR_NODE_ID_TOP_POS reactive Variable
 * @param rootRef The container in which the scroll-position should be tracked
 * @param deps Dependencies to refresh the observer (for example due to rerender)
 */
export function useNodePosObserver(rootRef: React.RefObject<HTMLElement>, deps?: React.DependencyList) {
  // Setting the reactive variable is throttled to a couple seconds because its change results in a change in the screen state which causes large rerenders.
  // Without out this 50% of the CPU-Time when scrolling is caused by these rerenders
  const previewContentModel = usePreviewContentModel()
  const setTargetVariable = useCallback((target: ContentTarget) => {
    return SCROLL_VAR_NODE_ID_TOP_POS(target)
  }, [])
  const throttledSetTargetVariable = useMemo(() => throttle(setTargetVariable, 5000), [setTargetVariable])

  useEffect(() => {
    if (previewContentModel.state !== "ready") return

    const refs = document.querySelectorAll('.NodesContainer [data-scroll-target]:not([data-scroll-target=""]')
    const visibleStack = new Map<string, IntersectionObserverEntry>()

    const intersectionObserver = new IntersectionObserver(
      (entries) => {
        const connected = entries.filter((e) => e.target.isConnected)

        connected.forEach((entry) => {
          const id = (entry.target as HTMLElement).dataset.scrollTarget
          if (!id) return
          visibleStack.set(id, entry)
        })

        const firstVisibleNode = [...visibleStack.entries()].reduce(findMostVisibleNode, null)
        if (!firstVisibleNode) return

        const topLevelNodesById = mapFrom(previewContentModel.treeNodes, (t) => t.id)

        let topLevelParentNode: string = firstVisibleNode[0]
        while (previewContentModel.parentById.has(topLevelParentNode) && !topLevelNodesById.has(topLevelParentNode))
          topLevelParentNode = previewContentModel.parentById.get(topLevelParentNode)!.id

        // We actually found a topLevelNode and didnt just reach the end of the parentChain
        if (topLevelNodesById.has(topLevelParentNode)) {
          const scrollTarget: ContentTarget = {
            outer: topLevelParentNode,
            // first visible node might have been a top Level one. In this case the inner value is not necessary
            inner: topLevelParentNode !== firstVisibleNode[0] ? firstVisibleNode[0] : undefined,
          }
          if (
            SCROLL_VAR_NODE_ID_TOP_POS()?.inner !== scrollTarget.inner ||
            SCROLL_VAR_NODE_ID_TOP_POS()?.outer !== scrollTarget.outer
          ) {
            throttledSetTargetVariable(scrollTarget)
          }
        }
      },
      { root: rootRef.current, threshold: [...Array(100).keys()].map((x) => x / 100) },
    )

    refs.forEach((ref) => {
      ref && intersectionObserver.observe(ref)
    })
    return () => intersectionObserver.disconnect()
  }, deps)
}

function isScrollable(ele: HTMLElement) {
  const hasScrollableContent = ele.clientHeight > 0 && ele.scrollHeight - 2 > ele.clientHeight

  const overflowYStyle = window.getComputedStyle(ele).overflowY
  const isOverflowHidden = overflowYStyle.indexOf("hidden") !== -1

  return hasScrollableContent && !isOverflowHidden
}

const getScrollableParent = function (element: HTMLElement | null): HTMLElement {
  if (!element || element == document.body) return document.body
  if (isScrollable(element)) return element
  return getScrollableParent(element.parentElement)
}

function scrollIntoView(element: HTMLElement, behaviour: ScrollBehavior = "instant", scrollElementToTop = false) {
  const scrollableParent = getScrollableParent(element)
  const parentBox = scrollableParent.getBoundingClientRect()
  const elementBox = element.getBoundingClientRect()

  // is it already visible? (Top has to be in the upper 80% of the viewport)
  if (
    !scrollElementToTop &&
    elementBox.top < parentBox.bottom - parentBox.height * 0.2 &&
    elementBox.top > parentBox.top
  )
    return

  const topOffset = elementBox.top - parentBox.top
  scrollableParent.scrollBy({ top: topOffset, behavior: behaviour })
}

function scrollToTargetElement(id: string): boolean {
  const element = document.querySelector<HTMLElement>(`[data-scroll-target*="${id}"]`)
  if (element) {
    scrollIntoView(element)
    return true
  } else {
    return false
  }
}

// These might be better of in a "non global" scope. Maybe a state in a ContextProvider
const CURRENT_CONTENT_TARGET_OUTER = makeVar<string | null>(null)
export const CURRENT_CONTENT_TARGET_INNER = makeVar<string | null>(null)
const CURRENT_COMMENT_TARGET = makeVar<string | null>(null)

export type ContentTarget = {
  // TreeNode-id
  outer: string
  // identifier that is in data-scroll-target
  inner?: string
}
type ScrollToContentFunction = (target: ContentTarget) => void
export function useScrollToContent(): ScrollToContentFunction {
  return (target) => {
    CURRENT_CONTENT_TARGET_OUTER(target.outer)
    CURRENT_CONTENT_TARGET_INNER(target.inner)
  }
}

export function useContentScroll(scrollToOuter: (id: string) => void): void {
  useEffect(() => {
    let active = true
    watcher(CURRENT_CONTENT_TARGET_OUTER())
    function watcher(target: string | null) {
      if (!active) return
      if (target) scrollToOuter(target)
      CURRENT_CONTENT_TARGET_OUTER(null)

      CURRENT_CONTENT_TARGET_OUTER.onNextChange(watcher)
    }
    CURRENT_CONTENT_TARGET_OUTER.onNextChange(watcher)
    return () => {
      active = false
    }
  }, [scrollToOuter])

  useEffect(() => {
    const callback = () => {
      const innerScrollTarget = CURRENT_CONTENT_TARGET_INNER()
      if (innerScrollTarget && scrollToTargetElement(innerScrollTarget)) CURRENT_CONTENT_TARGET_INNER(null)
    }
    // // run once immediately
    // callback()
    const intervalHandle = window.setInterval(callback, 250)
    return () => window.clearInterval(intervalHandle)
  }, [])
}

export type CommentTarget = {
  //CommentNode-id
  id: string
}
type ScrollToCommentFunction = (target: CommentTarget) => void
export function useScrollToComment(): ScrollToCommentFunction {
  return (target) => {
    CURRENT_COMMENT_TARGET(target.id)
  }
}

export function useVirtualizedCommentScrollWatcher(scrollToOuter: (id: string) => void) {
  useEffect(() => {
    let active = true
    watcher(CURRENT_COMMENT_TARGET())
    function watcher(target: string | null) {
      if (!active) return
      if (target) {
        scrollToOuter(target)
      }
      CURRENT_COMMENT_TARGET.onNextChange(watcher)
    }
    CURRENT_COMMENT_TARGET.onNextChange(watcher)
    return () => {
      active = false
    }
  }, [scrollToOuter])
}
