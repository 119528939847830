import * as React from "react"
import { SVGProps } from "react"

const SvgSt4IntegratefragmentLight = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1em" height="1em" {...props}>
    <path d="M224.1 474.4H96.6c-15.3 0-30.6-12.8-30.6-30.6V61.2c0-15.3 12.8-30.6 30.6-30.6h125v112.2c0 28.1 23 48.5 48.5 48.5h112.2v33.2c12.8 2.6 23 7.7 33.2 12.8v-76.5c0-12.8-5.1-23-15.3-33.2L285.3 12.8C277.7 5.1 264.9 0 252.2 0H96.6C60.9 0 32.8 28.1 32.8 63.8v380c0 35.7 28.1 63.8 63.8 63.8h163.2c-15.3-10.3-25.5-20.5-35.7-33.2zm33.2-441.2c2.6 2.6 5.1 2.6 7.7 5.1L379.7 153s2.6 2.6 5.1 7.7H272.6c-7.7 0-15.3-7.7-15.3-15.3V33.2zm-68.9 344.3c0-30.6 7.7-58.7 23-81.6l-76.5-28.1 89.3-30.6 33.2 12.8c12.8-7.7 25.5-15.3 38.3-20.4l-61.2-23c-5.1-2.6-12.8-2.6-20.4 0L117 239.7c-12.8 5.1-20.4 17.9-20.4 30.6v122.4c0 12.8 7.7 25.5 20.4 33.2l96.9 35.7c-15.3-25.5-25.5-53.5-25.5-84.1zm252.5-94.4c-53.6-53.6-135.2-53.6-188.7 0s-53.6 135.2 0 188.7 135.2 53.6 188.7 0c51-53.5 51-135.1 0-188.7zM423.1 454c-40.8 40.8-109.7 40.8-150.5 0s-40.8-109.7 0-150.5 109.7-40.8 150.5 0 40.8 107.1 0 150.5zM305.7 316.3c-5.1-5.1-12.8-5.1-17.9 0s-5.1 12.8 0 17.9l40.8 40.8-40.8 40.8c-5.1 5.1-5.1 12.8 0 17.9s12.8 5.1 17.9 0l40.8-40.8 40.8 40.8c5.1 5.1 12.8 5.1 17.9 0s5.1-12.8 0-17.9L364.4 375l40.8-40.8c5.1-5.1 5.1-12.8 0-17.9s-12.8-5.1-17.9 0l-40.8 40.8-40.8-40.8z" />
  </svg>
)

export default SvgSt4IntegratefragmentLight
