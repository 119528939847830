import { LOGIN_STATE_VAR, logout } from "./utils"
import type { AuthData, UserAuthorizationResult } from "./types"
import { useReactiveVar } from "@apollo/client"
import { Result, sendADFSLoginRequest, useLoginRequest } from "./requests"
import { useCallback } from "react"
import { useCheckLoginStateLazyQuery } from "./query.hooks"

function validateResult(result: Result) {
  switch (result.status) {
    case "Ok": {
      const sessionData = result.sessionData
      LOGIN_STATE_VAR({ state: "loggedIn", sessionData })
      break
    }
    case "Error":
      LOGIN_STATE_VAR({ state: "loginFailed", message: result.message, cause: result.cause })
      break
  }
}

function setRunning() {
  LOGIN_STATE_VAR({ state: "checking" })
}

export function useAuthentication(): UserAuthorizationResult {
  const state = useReactiveVar(LOGIN_STATE_VAR)
  const sendLoginRequest = useLoginRequest()
  const login = async (
    username: string,
    password: string,
    language: string,
    license: AuthData["license"],
    forceLogin = false,
  ) => {
    setRunning()
    const result = await sendLoginRequest({ username, password, language, license, forceLogin })
    validateResult(result)
  }

  const loginADFS = async (adfsResult: Parameters<typeof sendADFSLoginRequest>[0]) => {
    setRunning()
    const result = await sendADFSLoginRequest(adfsResult)
    validateResult(result)
  }
  const logoutMemo = useCallback(logout, [])
  const [initialCheckLogin, checkLogin] = useCheckLoginStateLazyQuery({ fetchPolicy: "no-cache" })
  if (state.state == "loggedIn" && !checkLogin.called) {
    initialCheckLogin()
  }
  if (checkLogin.data?.me.__typename && state.state == "loggedIn") {
    checkLogin.startPolling(5 * 60 * 1000)
  } else {
    checkLogin.stopPolling()
  }

  return [state, { login, loginADFS, logout: logoutMemo }]
}
