import React from "react"
import type { PropertyEditor, SelectionPropertyValue } from "../types"
import { useUpdatePropertyMutation, UpdatePropertyMutationVariables } from "../../query.hooks"
import { isTypename } from "@st4/graphql"
import { SelectionEditor } from "~/ui/editors/selectioneditor"
import { Icon, Regular } from "@st4/icons"

function getInputValue(
  propertyEditor: PropertyEditor<SelectionPropertyValue>,
  value: string,
): UpdatePropertyMutationVariables["input"] {
  const input = {
    variantName: propertyEditor.variantName,
    displayName: propertyEditor.displayName,
    id: propertyEditor.id,
    node: { id: propertyEditor.node.id },
    origin: propertyEditor.origin,
    value: {
      singleSelectionValue: value,
    },
  }

  return input
}

export function PropertySelectionEditor({
  propertyEditor,
  languageId,
  refetchProperties,
  refetchOnSave,
}: {
  propertyEditor: PropertyEditor<SelectionPropertyValue>
  languageId: string
  refetchProperties: () => void
  refetchOnSave?: string[]
}) {
  const { value, isReadonly } = propertyEditor

  const [updateProperty, { data, loading, error }] = useUpdatePropertyMutation({
    variables: {
      input: { ...propertyEditor, value: { singleSelectionValue: propertyEditor.value?.selectedValue } },
    },
    refetchQueries: refetchOnSave,
  })

  const submitHandler = async (selected: string) => {
    updateProperty({
      variables: {
        input: getInputValue(propertyEditor, selected),
        languageId: languageId,
      },
      onCompleted: (_) => {
        refetchProperties()
      },
    })
  }

  const suffixIcon = loading ? <Icon component={Regular.SpinnerThird} /> : null
  let errorMessage: string | undefined
  if (error) {
    errorMessage = error.message
  }
  if (isTypename("Error")(data?.updateProperty)) {
    errorMessage = data?.updateProperty?.message
  }

  return (
    <SelectionEditor
      onSubmit={submitHandler}
      isRemovable={value?.deletable}
      isReadonly={isReadonly}
      selectedValue={value?.selectedValue ?? ""}
      suffixIcon={suffixIcon}
      availableValues={value?.selectableValues ?? []}
      errorMessage={errorMessage}
    />
  )
}
