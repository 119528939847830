import { css, StyledProps } from "styled-components"
import { ThemeManagerTheme } from "./quanosTheme"

export function accentColor(props: StyledProps<{ flat?: boolean; primary?: boolean; secondary?: boolean }>) {
  if (props.flat && (props.primary || props.secondary)) return baseColor(props)
  if (props.primary) return props.theme.primaryAccentColor
  else if (props.secondary) return props.theme.secondaryAccentColor
  else return props.theme.primaryColor
}

export function baseColor(props: StyledProps<{ primary?: boolean; secondary?: boolean }>) {
  if (props.primary) return props.theme.primaryColor
  else if (props.secondary) return props.theme.secondaryColor
  else return props.theme.primaryAccentColor
}

export const boxedInputStyles = css`
  border: 1px solid #ddd;
  background-color: #fff;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 0.5em;
`

// prettier-ignore
export const highlight = `
    transition: background-color 0.3s ease-in-out;
    &:hover {
        background: #f5f5f5;
    }
`

// prettier-ignore
export const ripple = `
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);

  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    background-image: radial-gradient(circle, #000 10%, transparent 10.01%);
    background-repeat: no-repeat;
    background-position: 50%;
    transform: scale(10,10);
    opacity: 0;
    transition: transform .5s, opacity 1s;
  }

  &:active:after {
    transform: scale(0,0);
    opacity: .2;
    transition: 0s;
  }
`

export const centeredIcon = {
  position: "fixed",
  left: "50%",
  top: "50%",
  marginLeft: "-25px",
  marginTop: "-35px",
}

import { GlobalToken } from "antd"

declare module "styled-components" {
  export interface DefaultTheme extends ThemeManagerTheme {
    token: GlobalToken
  }
}

export { ThemeProvider } from "./ThemeProvider"

import { default as defaultTheme } from "./quanosTheme"
export type { ThemeManagerTheme }

import ThemeManager from "./themeManager"
ThemeManager.register("defaultTheme", defaultTheme)
export { defaultTheme }
export default ThemeManager

export * from "./utilities"
