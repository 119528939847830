import type { UpdateCommentMutation, UpdateCommentMutationVariables } from "./query.hooks"
import { useUpdateCommentMutation } from "./query.hooks"
import type { BlockComment, ExtractByTypeName, St4Node, TextComment } from "@st4/graphql"
import { isTypename } from "@st4/graphql"
import type { TextContentForCommentFragment } from "../CommentList/query.hooks"
import { TextContentForCommentFragmentDoc } from "../CommentList/query.hooks"
import { ReplaceCommentInCommentNode } from "./utilities"

function createInputType(comment: CommentToUpdate): UpdateCommentMutationVariables | null {
  if (!comment.node) return null
  const content = comment.node.content

  const commentData = {
    aspectSelectorId: comment.node.aspectId,
    nodeId: comment.node.id,
    historyNumber: content?.historyNumber ?? 0,
    commentKey: comment.commentKey,
  }
  return {
    commentTypeInput: { ...commentData, commentType: comment.commentType },
    commentStateInput: { ...commentData, commentState: comment.state },
    commentTextInput: { ...commentData, commentText: comment.value },
  }
}

function createOptimisticResponse(comment: CommentToUpdate): UpdateCommentMutation {
  const state = {
    persistanceState: "LOCAL" as const,
    persistanceAction: "CHANGECOMMENT" as const,
  }
  const commentWithState = { ...comment, ...state }
  const result = { result: commentWithState, __typename: "CommentSuccess" as const }
  return {
    updateCommentType: result,
    updateCommentState: result,
    updateCommentText: result,
    __typename: "Mutation",
  }
}

function mergeResultComments(comments: UpdateCommentMutation) {
  if (isTypename("Error")(comments.updateCommentText)) return null
  if (isTypename("Error")(comments.updateCommentState)) return null
  if (isTypename("Error")(comments.updateCommentType)) return null

  return {
    ...comments.updateCommentText.result,
    state: comments.updateCommentState.result.state,
    commentType: comments.updateCommentType.result.commentType,
  }
}

type CommentToUpdate = (Omit<TextComment, "node"> | Omit<BlockComment, "node">) & {
  node: Pick<St4Node, "id" | "aspectId" | "__typename"> & {
    content: Pick<
      ExtractByTypeName<"TextContent" | "TextGroupContent", NonNullable<St4Node["content"]>>,
      "historyNumber" | "id" | "__typename"
    >
  }
}
export function useUpdateCommentAndSaveLocal() {
  const [updateCommentStateMutation, updateCommentStateResult] = useUpdateCommentMutation()
  const patchedMutation = (comment: CommentToUpdate) => {
    const existingComment = comment
    const input = createInputType(comment)
    if (!input || !existingComment) return Promise.reject()
    const optimisticResult = createOptimisticResponse(comment)
    return updateCommentStateMutation({
      variables: input,
      optimisticResponse: optimisticResult,
      update: (cache, { data }) => {
        if (!data) return
        const contentCacheId = cache.identify({ __typename: "TextContent", id: input.commentTextInput.nodeId })
        const contentFromCache = cache.readFragment<TextContentForCommentFragment>({
          id: contentCacheId,
          fragment: TextContentForCommentFragmentDoc,
          fragmentName: "TextContentForComment",
        })
        if (!contentFromCache) return

        // Assumption: All three mutation done in this mutation modify the same comment
        const commentIdToUpdate = `${input.commentTextInput.nodeId}_${input.commentTextInput.historyNumber}_${input.commentTextInput.aspectSelectorId}_${input.commentTextInput.commentKey}`
        const commentNodeCacheKey = cache.identify({ __typename: "CommentNode", id: commentIdToUpdate })
        if (!commentNodeCacheKey) return

        const mergedResult = mergeResultComments(data)
        if (!mergedResult) {
          console.log("error", optimisticResult, mergeResultComments(optimisticResult))
          const erroneousComment = {
            ...mergeResultComments(optimisticResult),
            persistanceState: "ERROR",
            persistanceAction: "CHANGECOMMENT",
          }
          ReplaceCommentInCommentNode(cache, commentNodeCacheKey, erroneousComment as any)
          return
        }
        ReplaceCommentInCommentNode(cache, commentNodeCacheKey, mergedResult)
      },
    })
  }
  return [patchedMutation, updateCommentStateResult] as [typeof patchedMutation, typeof updateCommentStateResult]
}
