import * as React from "react"
import { SVGProps } from "react"

const SvgSt4Buttoninsertlink = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1em" height="1em" {...props}>
    <path d="M256 0a256 256 0 1 0 0 512 256 256 0 1 0 0-512zm-31.4 472h-30c-41.4 0-74.9-33.5-74.9-74.9s33.5-74.9 74.9-74.9h30c8.3 0 15 6.7 15 15s-6.7 15-15 15h-30c-24.8 0-44.9 20.1-44.9 44.9s20.1 44.9 44.9 44.9h30c8.3 0 15 6.7 15 15s-6.7 15-15 15zm-15-59.9c-8.3 0-15-6.7-15-15s6.7-15 15-15h89.9c8.3 0 15 6.7 15 15s-6.7 15-15 15h-89.9zM314.4 472h-30c-8.3 0-15-6.7-15-15s6.7-15 15-15h30c24.8 0 44.9-20.1 44.9-44.9s-20.1-44.9-44.9-44.9h-30c-8.3 0-15-6.7-15-15s6.7-15 15-15h30c41.4 0 74.9 33.5 74.9 74.9S355.8 472 314.4 472zm97.2-189.4H306.9V177.7H202.1v104.9H97.3V177.7h104.8V73h104.8v104.7h104.7v104.9z" />
  </svg>
)

export default SvgSt4Buttoninsertlink
