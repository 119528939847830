import type { RefObject } from "react"
import type { ST4NodeWithContentName } from "../graphql/applicationQueries"
import type { Node } from "unist"
import { PickWithTypename } from "@st4/graphql"
import { PartialByName } from "../types"

export type SelectionData = {
  node: Node
  contentNode: PartialByName<
    PickWithTypename<ST4NodeWithContentName<"TextContent" | "TextGroupContent">, "id" | "aspectId" | "content">,
    "content"
  >
  marker: RefObject<HTMLElement>
  boundingRect: DOMRect | null
  offset: number
}

export enum ProgressState {
  NoSelection = "NoSelection",
  Ongoing = "Ongoing",
  Complete = "Complete",
}

export type NoTextSelection = {
  progress: ProgressState.NoSelection
}

export type OngingTextSelection = {
  start: SelectionData
  end: SelectionData
  origin: SelectionData
  progress: ProgressState.Ongoing
  selectionAllowed: boolean
  selectionDirection: "forward" | "backward"
}
export type CompleteTextSelection = {
  start: SelectionData
  end: SelectionData
  origin: SelectionData
  selectionAllowed: boolean
  progress: ProgressState.Complete
}
export type TextSelection = OngingTextSelection | CompleteTextSelection | NoTextSelection
