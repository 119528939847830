import React, { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { StyledAlert } from "../StyledAlert"

type NotificationBannerProps = {
  /**
   * The state for which the notification banner should be shown.
   * Controls the icon and the color of the banner
   */
  state: "success" | "info" | "warning" | "error"
  /**
   * The title of the notification.
   * All notification banners have a title, which should be short and descriptive.
   */
  title: React.ReactNode
  /**
   * The description to show the user.
   * The notification banner should contain enough information
   * to help the user understand the current or next steps.
   */
  description?: React.ReactNode
  /**
   * Gets called when the Banner is closed via its X button.
   */
  onClose?: () => void
}

/**
 * Notification banners provide feedback on the status and activity in a blade.
 * @param props {NotificationBannerProps}
 * @see [Zero Height Doku](https://zeroheight.com/92538ba17/p/3679bd-notification-banner)
 */
export function NotificationBanner(props: NotificationBannerProps) {
  const [visible, setVisible] = useState(true)
  useEffect(() => {
    if (!visible && props.onClose) {
      props.onClose()
    }
  }, [visible, props.onClose, props])
  const onClose = useCallback(() => setVisible(false), [])

  return (
    <StyledAlert
      type={props.state}
      banner
      message={props.title}
      description={props.description}
      closable
      afterClose={() => onClose()}
    />
  )
}
