import { isTypename } from "@st4/graphql";
import { getMetadata } from "./metadata";
export function getContentInfosFromPart(part, metaData) {
    var ref, ref1, ref2, ref3;
    if (!part) return;
    if (!isTypename("TextContent")((ref = part.contentNode) === null || ref === void 0 ? void 0 : ref.content) && !isTypename("TextGroupContent")((ref1 = part.contentNode) === null || ref1 === void 0 ? void 0 : ref1.content)) return;
    var _ref, _xml;
    var partInfo = {
        id: (_ref = part.ref) !== null && _ref !== void 0 ? _ref : "",
        type: isTypename("TextContent")((ref2 = part.contentNode) === null || ref2 === void 0 ? void 0 : ref2.content) ? "Part" : "PartGroup",
        xml: (_xml = (ref3 = part.contentNode) === null || ref3 === void 0 ? void 0 : ref3.content.xml) !== null && _xml !== void 0 ? _xml : ""
    };
    var contentMetadata = getMetadata(part, metaData);
    return {
        part: partInfo,
        partImages: contentMetadata === null || contentMetadata === void 0 ? void 0 : contentMetadata.contentImageInfos
    };
}
