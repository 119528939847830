import React, { useState } from "react"
import styled from "styled-components"
import { keys } from "@st4/ui-strings"
import { useTranslation } from "react-i18next"
import { usePreviewContentModel } from "../../contentModel"
import { useCommentActionsHook } from "../commentActions"
import { Button as AntdButton, Popconfirm, Select, Tooltip } from "antd"
import type { TFunction } from "i18next"
import { CommentInfoFieldsFragment } from "./query.hooks"
import { isTypename } from "@st4/graphql"
import { ValuesOfObject } from "../../types"
import { Icon, Regular, Light } from "@st4/icons"

const CONFIRM_MESSAGES = {
  RemoveComment: keys.message.confirm.delete,
}

const STATE_ICONS = {
  accepted: <Icon component={Regular.Check} style={{ color: "green" }} aria-label="check" />,
  rejected: <Icon component={Regular.XmarkLarge} style={{ color: "red" }} aria-label="close" />,
}

const ACTION_ICONS = {
  RemoveComment: <Icon component={Regular.Trash} aria-label="delete" />,
  ReplyToComment: <Icon component={Regular.CommentPlus} aria-label="add-reply" />,
  EditComment: <Icon component={Light.Pen} aria-label="edit" />,
}

const BUTTON_LABEL_KEYS = {
  RemoveComment: keys.button.preview.comment.delete,
  ReplyToComment: keys.button.preview.comment.reply,
  EditComment: keys.button.preview.comment.edit,
}

const ActionToolbar = styled.div`
  display: flex;
  border-bottom: 1px solid #efefef;
  width: 100%;
  .ant-select {
    color: ${({ theme }) => theme.greys[400]};
  }
  &&&&& .ant-select-selection-item,
  &&&&& .ant-select-selection-placeholder {
    font-size: 16px;
    opacity: 1;
    line-height: 30px;
  }
`

function getButtonLabel(t: TFunction, actionName?: string | null) {
  if (actionName && Object.hasOwnProperty.call(BUTTON_LABEL_KEYS, actionName))
    return t(BUTTON_LABEL_KEYS[actionName as keyof typeof BUTTON_LABEL_KEYS])
  return actionName
}

const Button = styled(AntdButton)`
  color: ${({ theme }) => theme.greys[400]};
`

type ConfirmButtonProps = {
  title: string
  confirmMessage?: string
  onClick: () => void
  icon: React.ReactNode
  disabled?: boolean
}

export const ConfirmButton = (props: ConfirmButtonProps) => {
  const [visible, setVisible] = useState(false)
  function confirm() {
    setVisible(false)
    props.onClick()
  }
  function cancel() {
    setVisible(false)
  }
  function handleVisibleChange() {
    if (props.confirmMessage) {
      setVisible(true)
    } else {
      confirm()
    }
  }
  return (
    <Popconfirm
      title={props.confirmMessage}
      open={visible}
      onOpenChange={handleVisibleChange}
      onConfirm={confirm}
      onCancel={cancel}
    >
      <Tooltip placement="bottom" title={props.title} mouseEnterDelay={0.5}>
        <Button type="text" icon={props.icon} disabled={props.disabled} />
      </Tooltip>
    </Popconfirm>
  )
}

type AnnotationActionsProps = {
  annotation: CommentInfoFieldsFragment
  /** Callbck to execute the action. Gets called if `useCommentActionsHook` doesn't contain an approriate action.*/
  onAction?: { [actionKey: string]: () => unknown }
  additionalControls?: React.ReactNode[]
}

export function AnnotationActions(props: AnnotationActionsProps) {
  const { t } = useTranslation()
  const previewContentModel = usePreviewContentModel()
  const { annotation } = props
  const commentActions = useCommentActionsHook()
  const [dropdownOpen, setDropdownOpen] = useState(false)
  if (previewContentModel.state != "ready") return null

  const availableActions = new Map<string, ValuesOfObject<ReturnType<typeof useCommentActionsHook>>>()
  for (const k of Object.keys(commentActions)) {
    const actionKey = k as keyof ReturnType<typeof useCommentActionsHook>
    availableActions.set(actionKey, commentActions[actionKey])
  }

  const onCommentActionClick = async (actionKey: string) => {
    const actionFromHook = availableActions.get(actionKey)
    if (actionFromHook && annotation && isTypename("TextContent", "TextGroupContent")(annotation.node.content)) {
      const actionPromise = actionFromHook({
        ...annotation,
        node: { ...annotation.node, content: annotation.node.content },
      })
      const result = await actionPromise
      if (result.data && isTypename("Error")(result.data.comment) && result.data.comment.message) {
        console.error(result.data.comment.message)
      }
    } else if (props.onAction && !!props.onAction[actionKey]) {
      props.onAction[actionKey]()
    } else {
      console.warn(`No action found for key ${actionKey}!`)
    }
  }

  const onStateSelect = (value: any) => {
    if (annotation && isTypename("TextContent", "TextGroupContent")(annotation.node.content)) {
      commentActions["UpdateComment"]({
        ...annotation,
        node: { ...annotation.node, content: annotation.node.content },
        state: value || null,
      })
    }
  }

  const onDropdown = (open: boolean) => {
    setDropdownOpen(open)
  }

  const getLabelForState = (state: string | null): string => {
    const modelledState = previewContentModel.commentModel.availableStates.find((avail) => avail.value === state)
    return modelledState?.label || ""
  }

  return (
    <ActionToolbar onClick={(ev) => ev.stopPropagation()}>
      <Tooltip
        placement="left"
        title={!annotation.state ? t(keys.label.comment.stateSelection) : getLabelForState(annotation.state)}
        mouseEnterDelay={0.5}
      >
        <Select
          style={{ minWidth: 40 }}
          onChange={onStateSelect}
          value={annotation.state || undefined}
          bordered={false}
          disabled={!annotation.actions.find(({ name }) => name === "UpdateCommentState")?.enabled}
          allowClear={!!annotation.state}
          placeholder={
            <>
              <Icon component={Regular.Flag} /> {dropdownOpen ? t(keys.label.comment.stateSelection) : ""}
            </>
          }
          onDropdownVisibleChange={onDropdown}
        >
          {previewContentModel.commentModel.availableStates.map((stateModel) => {
            const icon = STATE_ICONS[stateModel.value as keyof typeof STATE_ICONS]
            return (
              <Select.Option key={stateModel.value} value={stateModel.value}>
                {icon ? icon : null} {dropdownOpen ? stateModel.label : ""}
              </Select.Option>
            )
          })}
        </Select>
      </Tooltip>
      {annotation.actions
        .filter(({ enabled, name }) => name !== "UpdateCommentState" && enabled)
        .map((action) => {
          const icon = ACTION_ICONS[action.name as keyof typeof ACTION_ICONS]
          const confirmMessage = t(CONFIRM_MESSAGES[action.name as keyof typeof CONFIRM_MESSAGES])
          const buttonLabel = getButtonLabel(t, action.name)
          return (
            <ConfirmButton
              key={action.name}
              title={buttonLabel ?? ""}
              icon={icon}
              confirmMessage={confirmMessage}
              onClick={() => onCommentActionClick(action.name as string)}
            />
          )
        })}
      {props.additionalControls}
    </ActionToolbar>
  )
}
