import React, { useEffect, useMemo } from "react"
import { ContentGrid } from "@st4/ui"
import type { GenericScreenDefitinition } from "../definition/screen"
import { getComponent } from "@st4/customize-host"
import type { DefaultMessages, GenericBladeDefinition } from "../definition/blade"
import { Message, MessageHub } from "@st4/message-hub"
import { ScopeDefinition } from "../definition/scope"
import type { ScreenData } from "../definition/screen"
import { TransitionOptions } from "../definition/transitionOptions"
import { notEmpty } from "@st4/graphql"
import { TRANSITIONINFOVAR } from "../hooks/useMenuTranstionOptions"
import { TaskBlade } from "../components/TaskBlade"
import { TransitionKey } from "../definition/transition"

export type TransitionInfo<
  TOptions extends TransitionOptions = TransitionOptions,
  TTransitions extends ScopeDefinition["transitions"] = ScopeDefinition["transitions"],
> = {
  followTransition: (transition: TransitionKey, additionalData?: Record<string, unknown>) => Promise<void>
  transitions: { name: keyof TTransitions; options: TOptions }[]
}

function isBladeTransition(
  transition: TransitionInfo["transitions"][number],
): transition is TransitionInfo<Extract<TransitionOptions, { type: "blade" }>>["transitions"][number] {
  return transition.options.type === "blade"
}

type ScreenProps = {
  /** The definition of the screen */
  definition: GenericScreenDefitinition
  /** The instance data for the screens initial render (rehydration) */
  data: ScreenData
  /** Transitions valid for this screen */
  transitionInfo?: TransitionInfo
  /** MessageHubs for all Blades */
  messageHubs: Record<string, MessageHub<Message>>
}

export function getBladeComponents(definition?: GenericScreenDefitinition) {
  return new Map(definition?.blades.map((ref) => [ref.name, getComponent(ref.component) as GenericBladeDefinition]))
}

export const Screen = function Screen(props: ScreenProps) {
  const { definition, data, messageHubs } = props

  const bladeDefs = useMemo(() => getBladeComponents(definition), [definition])

  const bladeTransitionInfoMap = useMemo(() => {
    return new Map(
      definition.blades
        .map(({ name }) => {
          if (!props.transitionInfo) return null
          const transitionsInBlade = props.transitionInfo.transitions
            .filter(isBladeTransition)
            .filter((t) => t.options.blade === name)

          return [
            name,
            {
              followTransition: props.transitionInfo.followTransition,
              transitions: transitionsInBlade,
            },
          ] as const
        })
        .filter(notEmpty),
    )
  }, [definition.blades, props.transitionInfo])

  useEffect(() => {
    TRANSITIONINFOVAR(props.transitionInfo)
  }, [props.transitionInfo])

  function createBladeElement(name: string, collapsed: boolean, fixed?: boolean) {
    const bladeReference = definition.blades.find((b) => b.name == name)
    const Component = bladeDefs.get(name)
    if (!bladeReference || !Component) return null // TODO: Komponente existiert nicht. Zumindest loggen.
    const fnProps = bladeReference.props
    const projectedProps = (fnProps ? fnProps(data.states[name], data) : data.states[name]) ?? {}

    if (typeof projectedProps !== "object") return null

    const size = typeof Component.size === "function" ? Component.size(projectedProps) : Component.size

    const bladeMessageHub = messageHubs[name]
    if (!bladeMessageHub) {
      throw new Error(`MessageHub for ${name} missing in passed 'messageHubs'!`)
    }

    let bladeProps
    if (bladeReference.temporary) {
      bladeProps = {
        temporary: true as const,
        size: size ?? { S: 6, M: 6, L: 6 },
      } as const
    } else if (fixed) {
      bladeProps = {
        fixed: true as const,
        size: size ?? { S: 3, M: 3, L: 3 },
      } as const
    } else {
      bladeProps = {
        collapsed,
        size: size ?? { S: 6, M: 6, L: 6 },
      } as const
    }
    const bladeTransitionInfo = bladeTransitionInfoMap.get(name)
    return {
      size: bladeProps.size,
      component: (
        <TaskBlade
          name={bladeReference.name}
          messageHub={bladeMessageHub}
          bladeProps={bladeProps}
          componentName={bladeReference.component}
          transitionInfo={bladeTransitionInfo}
          bladeDefinition={Component}
          key={bladeReference.name}
          projectedProps={projectedProps}
        />
      ),
    }
  }
  const renderedBlades = data.blades.map((b) => createBladeElement(b.name, b.collapsed ?? false)?.component)
  const fixedBlade = data.fixedBlade ? createBladeElement(data.fixedBlade, false, true) : null

  /*if (!taskDefinition.singleton)
    sendInsight({
      event: "useTrack",
      properties: { name: taskDefinition.name, blades: data.blades.map((b) => b.name).join(", ") },
    })
  */

  const fixedColumns = fixedBlade?.size

  return (
    <ContentGrid
      fixedBlades={[fixedBlade?.component]}
      scrollBlades={renderedBlades}
      fixedBladesTotalSize={fixedColumns}
    />
  )
}

export function bladeManagementReducer(state: ScreenData, message: DefaultMessages & { sender: string }): ScreenData {
  const { sender } = message
  switch (message.action) {
    case "collapseChange":
      return {
        ...state,
        blades: [...state.blades.map((b) => (b.name === sender ? { ...b, collapsed: message.payload } : b))],
      }
    case "closeTemporaryBlade":
      return {
        ...state,
        blades: state.blades.filter((b) => b.name !== sender),
      }
    default:
      return state
  }
}
