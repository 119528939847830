import React from "react"
import { Result as AntDResult, Skeleton, Typography } from "antd"

const { Paragraph, Text } = Typography

type ResultProps = {
  error?: { message: string }
  errorReason?: string
  loading: boolean
  children: React.ReactNode
}

export function Result({ error, errorReason, loading, children }: ResultProps) {
  if (error) {
    return (
      <AntDResult status="error" subTitle={errorReason} title="Sorry, something went wrong...">
        <Paragraph>
          <Text strong style={{ fontSize: 16 }}>
            The server answered:
          </Text>
        </Paragraph>
        <Paragraph>{error.message}</Paragraph>
      </AntDResult>
    )
  }

  if (loading) {
    return (
      <div style={{ width: "100%", padding: 30 }}>
        <Skeleton active />
      </div>
    )
  }

  return <>{children}</>
}
