import React, { useCallback, useEffect, useState } from "react"
import { Space } from "antd"
import { QuickSearchBox, SearchOptionsButton } from "@st4/search"
import { useLocation, useNavigate } from "react-router"
import { useLanguage } from "@st4/settings"
import { useCreateTaskMutation } from "@st4/tasks"
import { areSearchOptionsEqual, loadSearchOptions, saveSearchOptions, saveSearchTerm } from "@st4/search"

const onSearchOptionsChanged = "onSearchOptionsChanged"
const searchResultPage = "/task/showSearchResult"

export function SearchArea() {
  const language = useLanguage()
  const location = useLocation()
  const nav = useNavigate()
  const [createTask] = useCreateTaskMutation()
  const [searchOptions, setSearchOptions] = useState(loadSearchOptions(language.languageId))
  const searchOptionsChanged = useCallback(() => {
    const newSearchOptions = loadSearchOptions(language.languageId)
    if (!areSearchOptionsEqual(searchOptions, newSearchOptions)) setSearchOptions(newSearchOptions)
  }, [language.languageId])
  useEffect(() => {
    addEventListener(onSearchOptionsChanged, searchOptionsChanged)
    return () => removeEventListener(onSearchOptionsChanged, searchOptionsChanged)
  }, [language.languageId])

  const showSearchOptions = location.pathname !== searchResultPage

  const quickSearchbox = (
    <QuickSearchBox
      onSearch={(searchTerm: string) => {
        saveSearchTerm(searchTerm)
        createTask({ variables: { taskdefinitionname: "showSearchResult" } }).then((res) => {
          if (res.data?.createTask.__typename === "TaskContext") nav(`/task/${res.data?.createTask.id}`)
          // Todo: Fehlerbehandlung
        })
      }}
    />
  )

  return showSearchOptions ? (
    <Space direction={"horizontal"} size={0}>
      <SearchOptionsButton searchOptions={searchOptions} onSaveOptions={(options) => saveSearchOptions(options)} />
      {quickSearchbox}
    </Space>
  ) : (
    quickSearchbox
  )
}
