import { Tag } from "antd"
import styled from "styled-components"
import { setLightness } from "polished"

const foregroundColor = setLightness(0.3)
const backgroundColor = setLightness(0.95)

export const StyledTag = styled(Tag)<{ $isInherited: boolean; $taxColor: string }>`
  font-size: inherit;
  line-height: inherit;
  color: ${({ $taxColor }) => foregroundColor($taxColor)};
  background: ${({ $taxColor }) => backgroundColor($taxColor)};
  white-space: pre-line;
`
