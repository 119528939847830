import React from "react"
import { Typography } from "antd"
import { TFunction } from "react-i18next"
import { keys } from "@st4/ui-strings"
import { getResourceByName } from "../../helpers"
import { baseTestId } from "../SearchFacetsList"

export function clearString(text: string) {
  return text.replace(/[^A-Za-z0-9]+/g, "")
}

export function getRefinementId(facetKey: string, itemKey: string) {
  return `${facetKey}-${itemKey}`
}

export function getLabel(labelInResponse: string, translate: TFunction<"translation">): string {
  const translateKey = getResourceByName(keys.label.search.component.searchFacetsList.facetItemLabels, labelInResponse)
  return translateKey?.length ? translate(translateKey) : labelInResponse
}

export function FacetWrapper(props: { testId: string; title: string; children: React.ReactNode | React.ReactNode[] }) {
  return (
    <div data-testid={`${baseTestId}-${props.testId}`} style={{ margin: "8px" }}>
      <FacetTitle testId={props.testId} title={props.title} />
      {props.children}
    </div>
  )
}

function FacetTitle(props: { testId: string; title: string }) {
  return (
    <Typography.Title data-testid={`${baseTestId}-${props.testId}-title`} level={5}>
      {props.title}
    </Typography.Title>
  )
}
