import styled, { useTheme } from "styled-components"
import { BladeSize, Columns } from "./types"
import React from "react"
import { ScrollEdgeState, useScrollEdgeState } from "./scrollEdgeState"

const MAX_COLUMNS: Columns = 24

type ContentGridProps = {
  /**
   * Contains the blades shown inside the fixed area.
   */
  fixedBlades: React.ReactNode[]
  /**
   * Contains all blades shown inside the scrollable area.
   */
  scrollBlades: React.ReactNode[]
  /**
   * The size of the fixed area.
   */
  fixedBladesTotalSize?: BladeSize
}

function getColumnSize(size?: Columns) {
  return (MAX_COLUMNS - (size ?? 0)) as Columns
}

/**
 * Places the passed blades inside a grid with base width of 24 columns.
 * Extends to the right if the widths of the blades exceed 24 columns.
 * @param param0 {ContentGridProps}
 * @returns
 */
export function ContentGrid({ fixedBlades, scrollBlades, fixedBladesTotalSize }: ContentGridProps) {
  const { scrollContainerRef, scrollEdgeState } = useScrollEdgeState()
  const remainingScreenColumns: BladeSize = {
    S: getColumnSize(fixedBladesTotalSize?.S),
    M: getColumnSize(fixedBladesTotalSize?.M),
    L: getColumnSize(fixedBladesTotalSize?.L),
  }

  return (
    <OuterGrid fixedAreaScreenColumns={fixedBladesTotalSize}>
      <InnerGrid as={"aside"} gridScreenColumns={fixedBladesTotalSize}>
        {fixedBlades}
      </InnerGrid>
      <div style={{ position: "relative", display: "grid" }}>
        <InnerGridWithShadow
          gridScreenColumns={remainingScreenColumns}
          ref={scrollContainerRef}
          scrollState={scrollEdgeState}
        >
          {scrollBlades}
        </InnerGridWithShadow>
      </div>
    </OuterGrid>
  )
}

const OuterGrid = styled.div<{ fixedAreaScreenColumns?: BladeSize }>`
  --grid-gap: 10px;

  padding-right: var(--grid-gap);
  height: 100%;
  display: grid;
  gap: var(--grid-gap);
  @media (width <= ${({ theme: { token } }) => token.screenLGMax}px) {
    grid-template-columns: ${({ fixedAreaScreenColumns }) =>
      `${fixedAreaScreenColumns?.S ?? 0}fr ${getColumnSize(fixedAreaScreenColumns?.S)}fr`};
  }
  @media (${({ theme: { token } }) => token.screenLGMax}px < width <= ${({ theme: { token } }) =>
      token.screenXXLMin}px) {
    grid-template-columns: ${({ fixedAreaScreenColumns }) =>
      `${fixedAreaScreenColumns?.M ?? 0}fr ${getColumnSize(fixedAreaScreenColumns?.M)}fr`};
  }
  @media (${({ theme: { token } }) => token.screenXXLMin}px < width) {
    grid-template-columns: ${({ fixedAreaScreenColumns }) =>
      `${fixedAreaScreenColumns?.L ?? 0}fr ${getColumnSize(fixedAreaScreenColumns?.L)}fr`};
  }
`

const InnerGrid = styled.div<{ gridScreenColumns?: BladeSize }>`
  overflow: auto;
  display: grid;
  grid-auto-flow: column;
  gap: var(--grid-gap);
  @media (width <= ${({ theme: { token } }) => token.screenLGMax}px) {
    --grid-screen-columns: ${({ gridScreenColumns }) => gridScreenColumns?.S ?? 0};
  }
  @media (${({ theme: { token } }) => token.screenLGMax}px < width <= ${({ theme: { token } }) =>
      token.screenXXLMin}px) {
    --grid-screen-columns: ${({ gridScreenColumns }) => gridScreenColumns?.M ?? 0};
  }
  @media (${({ theme: { token } }) => token.screenXXLMin}px < width) {
    --grid-screen-columns: ${({ gridScreenColumns }) => gridScreenColumns?.L ?? 0};
  }

  /* Split up the area available to the grid into equal sized columns
     Example: There is a single fixed blade with 5 columns at the current screen size.
     The Grid for the fixed blades will split its space into 5 columns
     The Grid for the scrollable blades will split its space into 19 columns 
     100% in this calculation references width of context box of this grid, not the width of the parent */

  grid-auto-columns: calc(((100% - (var(--grid-screen-columns) - 1) * var(--grid-gap))) / var(--grid-screen-columns));
`
const InnerGridWithShadow = styled(InnerGrid)<{ scrollState: ScrollEdgeState }>`
  &:before {
    content: "";

    // Absolute positioning refers to containing block created by position: relative div parent of this grid
    // Absolute positioning within grid without separate parent does not work with scrolling correctly
    position: absolute;
    inset: 0;

    // If not set Elements within the grid are not interactable because the box of this element overlaps everything
    pointer-events: none;

    z-index: 1;
    background-image: radial-gradient(farthest-side at 0% 50%, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%),
      radial-gradient(farthest-side at 100% 50%, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%);
    background-size:
      ${({ scrollState }) => (scrollState.isScrolledToLeft ? 0 : "20px")} 100%,
      ${({ scrollState }) => (scrollState.isScrolledToRight ? 0 : "20px")} 100%;
    background-repeat: no-repeat;
    background-position: left, right;

    transition: background-size 0.3s;
  }
`
