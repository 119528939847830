export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[]
  }
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    ActionCommandParameter: [
      "ActionCommandDictionaryParameter",
      "ActionCommandFieldPagesParameter",
      "ActionCommandListParameter",
      "ActionCommandStringParameter",
    ],
    AuthProvider: ["AzureADAuthProvider"],
    AuthenticationResult: ["AuthenticationError", "AuthenticationSuccess"],
    BooleanResult: ["BooleanSuccess", "Error"],
    CloseEditorSessionResult: ["CloseEditorSessionSuccess", "Error"],
    CommandExecutionResult: [
      "CommandExecutionFailure",
      "CommandExecutionPatternContextSuccess",
      "CommandExecutionSuccess",
    ],
    CommandInfo: ["AllowedCommand", "ForbiddenCommand"],
    CommentResult: ["CommentSuccess", "Error"],
    Content: [
      "LexiconContent",
      "LexiconEntryContent",
      "MediaContent",
      "MediaGroupContent",
      "ProjectContent",
      "TermContent",
      "TextContent",
      "TextGroupContent",
      "VariableTableContent",
    ],
    CopyTreesResponse: ["Error", "FinishedJobResponse"],
    CreateNewNodeJobResponse: [
      "AskRequiredMetadataOnCreateNewNodeCommandJobResponse",
      "CanceledJobResponse",
      "CreateNewNodeCommandJobResponse",
      "CreateNewNodeFinishedJobResponse",
      "FailedJobResponse",
    ],
    CreateReuseLinkResult: ["CreateReuseLinkSuccess", "Error"],
    CreateVariantFilterResult: ["CreateVariantFilterSuccess", "Error"],
    DeleteNodesResponse: ["AskDeleteNodesJobResponse", "DeleteNodesErrorJobResponse", "Error", "FinishedJobResponse"],
    EditorSessionResult: ["EditorSessionError", "EditorSessionSuccess"],
    FilterAttributesQueryResult: ["Error", "FilterAttributes"],
    FlowData: ["FlowStreamValueData", "FlowStreamValuesData", "FlowValueData", "FlowValuesData"],
    FlowInteractiveResult: ["FlowError", "FlowInteractionRequest", "FlowSuccess"],
    FlowResult: ["FlowError", "FlowSuccess"],
    GeneratedPasswordResult: ["Error", "GeneratedPasswordSuccess"],
    GetOntologyInfoResult: ["Error", "OntologyInfoSuccess"],
    IComment: ["BlockComment", "TextComment"],
    IError: ["Error", "TaxonomyTreeInfoError", "TaxonomyUpdateError"],
    IGraphicContent: ["MediaContent", "MediaGroupContent"],
    IMessage: ["ErrorMessage", "WarningMessage"],
    INode: ["CommentNode", "ExternalSite", "ST4Class", "ST4Node", "ST4TreeNode"],
    IRef: ["GenericRef", "NodeRef"],
    ITextContent: ["TextContent", "TextGroupContent"],
    LicenseEntriesResult: ["Error", "LicenseEntriesSuccess"],
    LicenseEntry: ["License", "LicenseVariant"],
    LicenseEntryResult: ["Error", "LicenseEntrySuccess"],
    MediaInfo: ["AnimationMediaInfo", "ImageMediaInfo", "NoMediaInfo", "VectorGraphicsMediaInfo", "VideoMediaInfo"],
    PropertiesResult: ["Error", "PropertyListSuccess"],
    PropertyUpdateResult: ["Error", "PropertyUpdateSuccess"],
    PropertyValue: [
      "DateTimePropertyValue",
      "DecimalPropertyValue",
      "DoublePropertyValue",
      "IntPropertyValue",
      "MultiSelectionPropertyValue",
      "MultiStringPropertyValue",
      "SelectionPropertyValue",
      "StringPropertyValue",
      "TaxonomyPropertyValue",
    ],
    RemoveReuseLinksResult: ["Error", "RemoveReuseLinksSuccess"],
    SaveEditorNodeResult: ["SaveEditorNodeFailure", "SaveEditorNodeSuccess"],
    TaskContextResult: ["TaskContext", "TaskContextError", "TaskContextErrorWithoutInstance"],
    TaxonomyTreeInfoResult: ["TaxonomyTreeInfoError", "TaxonomyTreeInfoSuccess"],
    TaxonomyUpdateResult: ["TaxonomyUpdateError", "TaxonomyUpdateSuccess"],
    UserAccountResult: ["Error", "UserAccountSuccess"],
    UserAccountsResult: ["Error", "UserAccountsSuccess"],
    UserClaimsResult: ["Error", "UserClaimsSuccess"],
    UserGroupResult: ["Error", "UserGroupSuccess"],
    UserGroupsResult: ["Error", "UserGroupsSuccess"],
  },
}
export default result
