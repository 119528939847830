import * as React from "react"
import { SVGProps } from "react"

const SvgSt4DataNodeRegular = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1em" height="1em" {...props}>
    <path d="M356.3 230.7v20.6c0 20.6-46.3 36-100.3 36s-100.3-15.4-100.3-36v-20.6c0-20.6 46.3-36 100.3-36s100.3 15.4 100.3 36zm-23.1 61.7c10.3-2.6 18-7.7 25.7-12.9v46.3c0 20.6-46.3 36-100.3 36s-100.3-15.4-100.3-36v-46.3c7.7 5.1 15.4 10.3 25.7 12.9 20.6 7.7 46.3 10.3 77.2 10.3s51.4-2.6 72-10.3zm-177.5 59.2c7.7 5.1 15.4 10.3 25.7 12.9 20.6 7.7 46.3 10.3 77.2 10.3s56.6-5.1 77.2-10.3c10.3-2.6 18-7.7 25.7-12.9v38.6c0 20.6-46.3 36-100.3 36s-100.3-15.4-100.3-36v-38.6h-5.2zm273.6-242.4-90.6-90.5C326.7 6.7 310.5 0 293.5 0H128C92.7 0 64 28.7 64 64v384c0 35.3 28.7 64 64 64h256c35.3 0 64-28.7 64-64V154.5c0-17-6.7-33.3-18.7-45.3zM400 448c0 8.8-7.2 16-16 16H128c-8.8 0-16-7.2-16-16V64c0-8.8 7.2-16 16-16h160v80c0 17.7 14.3 32 32 32h80v288z" />
  </svg>
)

export default SvgSt4DataNodeRegular
