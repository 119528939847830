import React from "react"
import { Segmented } from "antd"
import { TaskDefinition } from "../definition/task"
import styled from "styled-components"

type ScopeSwitcherProps = {
  task: TaskDefinition
  currentScope: string
  changeScope: (scope: string) => void
}

const Switcher = styled(Segmented)`
  .ant-segmented-item-selected {
    background-color: transparent;
    box-shadow: none;
  }
  .ant-segmented-item {
    background-color: transparent;
    box-shadow: none;
  }
  .ant-segmented-item-label {
    color: ${({ theme: { token } }) => token.colorTextSecondary};
    padding-left: 12px;
    padding-right: 12px;
  }
  .ant-segmented-item-selected .ant-segmented-item-label {
    color: ${({ theme: { token } }) => token.colorText};
    font-weight: 600;
  }
  .ant-segmented-thumb {
    background-color: transparent;
    box-shadow: none;
  }
`

export function ScopeSwitcher({ task, currentScope, changeScope }: ScopeSwitcherProps) {
  function onChange(value: string) {
    changeScope(value)
  }

  const options = React.useMemo(
    () =>
      Object.keys(task.scopes).map((scopeName) => ({
        label: task.scopes[scopeName]?.displayName,
        value: scopeName,
      })),
    [task.scopes],
  )
  if (options.length <= 1) return null
  return (
    <Switcher options={options} defaultValue={currentScope} onChange={(val) => onChange(val.valueOf() as string)} />
  )
}
