import styled from "styled-components"
import PropTypes from "prop-types"

// prettier-ignore
const Container_deprecated = styled.div`
  box-sizing: border-box;
  background: ${props => props.theme.backgroundColor};
  width: 100vw;
  height: 100vh;
  margin: auto;
  display: flex;
  flex-direction: column;
  ${props=> props.centered && `
   flex-direction: column;
   align-items: center;
   justify-content: center;`
   }
  ${props => props.withBackgroundImage && 
    `background-image: url(background.jpg);
     background-size: cover;`
  }
`
Container_deprecated.propTypes = {
  withBackgroundImage: PropTypes.bool,
  centered: PropTypes.bool,
}

export default Container_deprecated
