import { Table } from "antd"
import { ContentWidthLimiting, NodeHeader, NodeWrapper, useIsNodeSelected } from "./utils"
import React from "react"
import { keys } from "@st4/ui-strings"
import { useTranslation } from "react-i18next"

import type { NodeRenderProps } from "./types"

export function DataNode({ node, treeNode }: NodeRenderProps) {
  const { t } = useTranslation()
  const selected = useIsNodeSelected(treeNode)
  const contentNode = node
  const metaData = contentNode.metadata

  return (
    <ContentWidthLimiting>
      <NodeWrapper selected={selected}>
        <NodeHeader icon={treeNode.icon} title={contentNode.label} node={treeNode} />

        <Table
          dataSource={metaData}
          columns={[
            {
              title: t(keys.label.general.systemName),
              dataIndex: "systemName",
              key: "systemName",
              render: (text) => {
                return <div style={{ wordBreak: "break-word" }}>{insertSoftHyphenInChunks(text)}</div>
              },
            },
            {
              title: t(keys.label.general.name),
              dataIndex: "label",
              key: "label",
            },
            {
              title: t(keys.label.general.value),
              dataIndex: "value",
              key: "value",
            },
          ]}
          pagination={{ hideOnSinglePage: true }}
        />
      </NodeWrapper>
    </ContentWidthLimiting>
  )
}

function insertSoftHyphenInChunks(text: string) {
  const hideSoftHyphenStyle = {
    fontSize: "0",
    color: "transparent",
  }

  const chunks = text.split(".")

  const hyphenatedChunks = chunks.map((chunk, index) => {
    const isNotLastChunk = index < chunks.length - 1

    return (
      <span key={index}>
        {chunk}
        {isNotLastChunk && "."}
        {isNotLastChunk && <span style={hideSoftHyphenStyle}>&shy;</span>}
      </span>
    )
  })

  return hyphenatedChunks
}
