import React from "react"
import { Blade, BladeDefinition } from "@st4/tasks"
import { QuanosBlueButton } from "~/ui/quanosbutton"
import { Translate } from "@st4/i18n"
import { SearchFacets_SearchTerm, SearchFacets_NoSearchTerm } from "./components"
import { SearchBladeProps } from "./searchResultBlade"
import { SearchRefinement, loadPageSize, loadSearchOptions, loadSearchTerm } from "@st4/search"
import { useLanguage } from "@st4/settings"
import { sendInsight } from "@st4/insight"
import { keys } from "@st4/ui-strings"

export const baseTestId = "blade-searchFacets"

type SearchFacetsBladeMessages = {
  action: "onSearchRefined"
  payload: SearchRefinement[]
}

/*
 * MU, 06.10.2023
 * SearchFacetsBlade doesn't use props "searchTerm", "searchOptions" and "pageSize" anymore.
 * It takes the values from the according items from the localStorage and the sessionStorage instead.
 * Props are kept to don't break any other current implementation accidentally.
 */

export const SearchFacetsBlade: BladeDefinition<SearchBladeProps, SearchFacetsBladeMessages> =
  function SearchFacetsBlade(props) {
    const language = useLanguage()
    const pageSize = loadPageSize()
    const searchTerm = loadSearchTerm()
    const searchOptions = loadSearchOptions(language.languageId)

    return (
      <>
        <Blade.Toolbar>
          <QuanosBlueButton
            data-testid={`${baseTestId}-button-clearRefinements`}
            disabled={!props.searchRefinements?.length}
            onClick={() => searchRefinementChangedHandler([])}
          >
            <Translate>{keys.button.search.blade.searchFacetsBlade.clearRefinements}</Translate>
          </QuanosBlueButton>
        </Blade.Toolbar>
        <Blade.Content style={{ overflowX: "hidden" }}>
          {searchTerm ? (
            <SearchFacets_SearchTerm
              searchTerm={searchTerm}
              searchRefinements={props.searchRefinements || []}
              searchOptions={searchOptions}
              page={props.page || 1}
              pageSize={pageSize}
              onSearchRefinementsChanged={searchRefinementChangedHandler}
            />
          ) : (
            <SearchFacets_NoSearchTerm />
          )}
        </Blade.Content>
      </>
    )

    function searchRefinementChangedHandler(newRefinements: SearchRefinement[]) {
      try {
        sendInsight({ event: "useSearchRefinement" })
      } catch {
        /* Ignore error from heap.io */
      }
      props.sendMessage({ action: "onSearchRefined", payload: newRefinements })
    }
  }

SearchFacetsBlade.title = <Translate>{keys.label.search.blade.searchFacetsBlade.title}</Translate>
SearchFacetsBlade.size = { S: 8, M: 5, L: 4 }
