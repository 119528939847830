import * as React from "react"
import { SVGProps } from "react"

const SvgSt4EditVariantLight = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="1em" height="1em" {...props}>
    <path d="M429.3 280c12-12 18.7-28.2 18.7-45.2V85.3c0-26.5-21.5-48-48-48H250.5c-17 0-33.2 6.7-45.2 18.8L29.3 232c-25 25-25 65.6 0 90.6L162.7 456c25 25 65.6 25 90.6 0l176-176zM230.6 433.4c-12.5 12.5-32.7 12.5-45.2 0L51.9 299.9c-12.5-12.5-12.5-32.7 0-45.2l176-176c6-6 15-9.4 22.6-9.4H400c8.8 0 16 7.2 16 16v149.5c0 7.6-3.4 16.6-9.4 22.6l-176 176zm130.2-251.2c1.2-13.1-3.5-25.7-12.7-34.9l-8.3-9c-7.6-7.6-17.5-12.8-28.2-13.8-13.2-1.2-25.9 3.4-35.2 12.6L152.2 261.3c-2.2 2.2-3.8 5-4.4 8.1l-12.2 60.8c-2.2 11.1 7.6 20.9 18.8 18.7l61.3-12.1c3.1-.6 5.9-2.1 8.2-4.3L347 210.3c7.5-7.7 12.8-17.5 13.8-28.1zM204.6 306.5l-33 6.6 6.6-32.7 86.9-86.2 26.4 26.2-86.9 86.1zm109.5-108.7-26.4-26.2 11.3-11.2c4.7-4.6 12.3-4.7 17 0l9.4 9.3c4.7 4.6 4.7 12.2 0 16.8l-11.3 11.3z" />
  </svg>
)

export default SvgSt4EditVariantLight
