import classNames from "classnames"
import React from "react"
import * as R from "ramda"
import styled, { css } from "styled-components"
import PropTypes from "prop-types"
import { ripple, boxedInputStyles } from "@st4/theme-manager"
import { SelectMulti } from "../SelectMulti"

export function Option(props) {
  if (props.value === null || props.value === undefined) return null
  return (
    <option className={classNames(props.className, "Select--Option")} value={props.value._data}>
      {props.value._display}
    </option>
  )
}

export function NullOption(props) {
  return <Option className="Select--NullOption" value={{ _data: null, _display: "" }} />
}

// prettier-ignore
const Select_deprecated = styled(function(props) {
  if (props.multi) {
    return <SelectMulti {...props} />
  }
  var { options = [], allowNull = true, style, labelStyle, readonly } = props
  const defaultValue = props.defaultValue ? props.defaultValue._data : undefined
  if (!options.length && readonly && defaultValue) {
    options = [
      {
        value: props.defaultValue
      }
    ]
  }
  return (
    <label className={classNames(props.className, "Select", {'invalid':props.invalidInput})}>
      <div className={classNames("Select--label")} style={labelStyle || {}}>
        {props.label}
      </div>
      <select
        className={classNames("Select--select")}
        {...R.pick(["onChange", "name"])(props)}
        defaultValue={defaultValue}
        disabled={props.readonly}
        style={style || {}}>
        {allowNull ? <NullOption /> : null}
        {options.map((option, idx) => {
          if(option === null || option.value === null)
          throw new Error(`Invalid options`)
          return (
          <Option {...option} id={props.id} key={idx} />
        )})}
      </select>
    </label>
  )
})`
  & .Select--select {
    display: block;
    flex: 1;
    width: 100%;
    outline: none;
    ${props =>
      props.accented
        ? boxedInputStyles
        : css`
            border: none;
            border-bottom: 1px solid #ddd;
            background-color: transparent;
          `};
  }
  & .Select--select:focus {
    border: 1px solid ${props => (props.accented ? props.theme.primaryColor : "transparent")};
    ${props => (props.accented ? "" : "border-bottom: 1px solid #555")};
    ${props => props.accented && "box-shadow: 0 0 2px " + props.theme.primaryColor + ";"}
  }
  & .Select--select option {
    ${ripple}
    padding: 0.25em;
  }
  
  & .Select--select option:checked {
    font-weight: bold;
  }

  &.invalid .Select--select{    
    border: 1px solid ${props => props.theme.warningColor};
    box-shadow: 0 0 2px ${props => props.theme.warningColor};
  }
`

Select_deprecated.propTypes = {
  className: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
    }),
  ).isRequired,
  defaultValue: PropTypes.any,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  allowNull: PropTypes.bool,
  multi: PropTypes.bool,
  readonly: PropTypes.bool,
  style: PropTypes.any,
  labelStyle: PropTypes.any,
}
Select_deprecated.displayName = "Select"
export default Select_deprecated
