import * as React from "react"
import { SVGProps } from "react"

const SvgArrowLeftToLine = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="1em" height="1em" {...props}>
    <path d="M0 424c0 13.3 10.7 24 24 24s24-10.7 24-24V88c0-13.3-10.7-24-24-24S0 74.7 0 88v336zm135.6-185.5c-4.8 4.5-7.6 10.9-7.6 17.5s2.7 12.9 7.6 17.5l136 128c9.7 9.1 24.8 8.6 33.9-1s8.6-24.8-1-33.9l-92-86.6H424c13.3 0 24-10.7 24-24s-10.7-24-24-24H212.5l91.9-86.5c9.7-9.1 10.1-24.3 1-33.9s-24.3-10.1-33.9-1l-136 128z" />
  </svg>
)

export default SvgArrowLeftToLine
