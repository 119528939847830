import React, { useEffect, useMemo, useState } from "react"
import { Blade, BladeDefinition } from "@st4/tasks"
import { NavigationTreeViewletWithState } from "~/components/navigationtree"
import { useAspectId } from "@st4/settings"
import { keys } from "@st4/ui-strings"
import { Translate } from "@st4/i18n"
import { QuanosBlueButton } from "~/ui/quanosbutton"
import { isTypename } from "~/../../../packages/st4-graphql/lib"
import { NotificationInfo } from "~/components/treeActions/types"
import { PoolBladeNotifications } from "~/components/treeActions/PoolBladeNotifications"
import { v4 as uuidv4 } from "uuid"
import { i18n } from "@schema/utils"
import { Empty, Select } from "antd"
import { MetaRefElement } from "./types"
import { PropertiesViewlet } from "~/components/properties"
import useGetMetaRefData from "./util/getMetaRefData"
import {
  Container,
  HorizontalContainer,
  PoolContainer,
  TreeContainer,
  SelectMetaRefContainer,
  VerticalLine,
  ActionContainer,
} from "./util/styles"

type MetaRefPoolTreeBladeProps = {
  variant: string
  selectedNode?: {
    id: string
    classHierarchy: string[]
  }
  allowedOperations: string[]
}

type Messages =
  | {
      action: "onNodeSelected"
      payload:
        | {
            id: string
            classHierarchy: string[]
          }
        | undefined
    }
  | {
      action: "onClose"
      payload: any
    }
  | {
      action: "insertMetaRef"
      payload: MetaRefElement
    }

export const MetaRefPoolTreeBlade: BladeDefinition<MetaRefPoolTreeBladeProps, Messages> = function TreeBladeWithContext(
  props,
) {
  const aspectId = useAspectId()
  const [notifications, setNotifications] = useState<NotificationInfo[]>([])
  const [selectedMetaRef, setSelectedMetaRef] = useState<string>("")
  const [expandedPropertySections, setExpandedPropertySections] = useState([""])

  const selectedNode = props.selectedNode
  const isDynamicSelected = isDynamicMetaRefSelected(selectedNode)

  const metaRefData = useGetMetaRefData({ selectedNode, isDynamicSelected })

  const metaRefDataForSelect = useMemo(
    () =>
      metaRefData?.metaRefs
        ?.map((metaRef) => {
          return { label: metaRef.label, value: metaRef.name }
        })
        .sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase())),
    [metaRefData?.metaRefs],
  )

  useEffect(() => {
    if (!metaRefDataForSelect || metaRefDataForSelect.length === 0) {
      setSelectedMetaRef("")
      return
    }

    if (!metaRefDataForSelect.find((x) => x.value === selectedMetaRef))
      setSelectedMetaRef(metaRefDataForSelect[0].value)
  }, [metaRefDataForSelect])

  useEffect(() => {
    const notificationInfo = getNotificationInfo(props.allowedOperations, props.selectedNode?.id ?? "")
    if (notificationInfo) setNotifications([...notificationInfo])
  }, [props.allowedOperations, props.selectedNode?.id, setNotifications])

  const hasNotificationDisablingInsert = notifications.some((notification) => notification.disableInsert)

  return (
    <>
      <Blade.Content>
        <Container>
          <HorizontalContainer>
            <PoolContainer>
              <TreeContainer>
                <NavigationTreeViewletWithState
                  onSelect={(node) =>
                    props.sendMessage({
                      action: "onNodeSelected",
                      payload: node
                        ? {
                            id: node.id,
                            classHierarchy: isTypename("ST4Node")(node.node)
                              ? node.node.nodeClass?.classHierarchy ?? []
                              : [],
                          }
                        : undefined,
                    })
                  }
                  variant={props.variant}
                  aspectId={aspectId}
                  selected={props.selectedNode?.id}
                  includeNodeClassHierarchy
                  fetchPolicy="cache-and-network"
                />
              </TreeContainer>
              <Translate>{keys.label.metaRefPoolBlade.metaRef}</Translate>
              <SelectMetaRefContainer>
                <Select
                  style={{ width: "100%" }}
                  data-testid="metaRefPool:metaref"
                  value={selectedMetaRef}
                  options={metaRefDataForSelect || []}
                  dropdownMatchSelectWidth={false}
                  onChange={setSelectedMetaRef}
                  loading={metaRefData?.loading}
                />
              </SelectMetaRefContainer>
            </PoolContainer>
            <VerticalLine />
            <ActionContainer>
              {selectedNode && !isDynamicSelected ? (
                <PropertiesViewlet
                  key={"metaRefPool.properties" + selectedNode?.id ?? ""}
                  variant="Metadata"
                  aspectId={aspectId}
                  selectedNode={selectedNode.id ?? ""}
                  readOnly
                  expandedCategories={expandedPropertySections}
                  onExpand={(expandedSections) => {
                    setExpandedPropertySections(expandedSections)
                  }}
                />
              ) : (
                <Empty />
              )}
            </ActionContainer>
          </HorizontalContainer>
        </Container>
      </Blade.Content>
      <PoolBladeNotifications notifications={notifications} />
      <Blade.Actionbar>
        <div />
        <QuanosBlueButton
          disabled={hasNotificationDisablingInsert || !selectedMetaRef || !metaRefData?.metaRefs}
          onClick={() => {
            const metaRefElement = metaRefData?.metaRefs?.find((x) => x.name === selectedMetaRef)
            if (!metaRefElement) return

            props.sendMessage({
              action: "insertMetaRef",
              payload: metaRefElement,
            })
          }}
        >
          <Translate>{keys.button.blade.insert}</Translate>
        </QuanosBlueButton>
      </Blade.Actionbar>
    </>
  )
}

MetaRefPoolTreeBlade.title = <Translate>{keys.label.metaRefPoolBlade.title}</Translate>
MetaRefPoolTreeBlade.size = { S: 18, M: 13, L: 9 }
MetaRefPoolTreeBlade.reducer = function (previousState, message) {
  switch (message.action) {
    case "onNodeSelected":
      return { ...previousState, selectedNode: message.payload }
  }

  return previousState
}

function getNotificationInfo(allowedOperations: string[], selectedNodeId: string): NotificationInfo[] {
  if (allowedOperations && allowedOperations.find((x) => x === "insert-metaref")) return []

  return [
    {
      id: uuidv4(),
      nodeId: selectedNodeId,
      title: "",
      description: i18n.t(keys.message.metaRefPoolBlade.not_possible).toString(),
      severity: "error",
      disableInsert: true,
    },
  ]
}

function isDynamicMetaRefSelected(selectedNode?: { id: string; classHierarchy: string[] }) {
  if (!selectedNode?.id) return false

  //We got an id but no class hierarchy so dynamic metarefs are selected
  if (selectedNode.classHierarchy.length === 0) return true

  return false
}
