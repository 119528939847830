import classNames from "classnames"
import React, { Component } from "react"
import { Button_deprecated, ActionSheet_deprecated } from "../../index"
import PropTypes from "prop-types"

class ActionButton_deprecated extends Component {
  defaultProps = {
    optionName: (o) => o,
  }
  handleClick = (ev) => {
    ActionSheet_deprecated.show(
      {
        theme: this.props.theme,
        options: this.props.options.map(this.props.optionName),
        contextElement: this._ref,
      },
      this.handleAction,
    )
  }
  handleAction = (action) => {
    let option = this.props.options[action]
    this.props.onAction(option, this.props.item)
  }
  render() {
    return (
      <Button_deprecated
        className={classNames(this.props.className, "ActionButton")}
        {...this.props}
        innerRef={(b) => (this._ref = b)}
        flat
        round
        onClick={this.handleClick}
      >
        {this.props.children}
      </Button_deprecated>
    )
  }
}

ActionButton_deprecated.propTypes = {
  optionName: PropTypes.func,
  options: PropTypes.arrayOf(),
  onAction: PropTypes.func,
  item: PropTypes.any,
  className: PropTypes.string,
}

export default ActionButton_deprecated
