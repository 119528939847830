export default {
	"button": {
		"ap": {
			"changeGroupAssignments": {
				"addUsersToGroups": "ユーザーをグループに追加",
				"cancel": "キャンセル",
				"removeUsersFromGroups": "ユーザーをグループから削除"
			},
			"changePassword": {
				"cancel": "キャンセル",
				"changePassword": "パスワードを変更"
			},
			"groupForm": {
				"cancel": "キャンセル",
				"create": "ユーザーグループを作成"
			},
			"groupOverview": {
				"addNewGroup": "新規ユーザーグループを作成",
				"deleteGroup": "ユーザーグループを削除",
				"undeleteGroup": "ユーザーグループの削除を取り消し"
			},
			"userDetails": {
				"cancel": "キャンセル",
				"create": "新規ユーザーを作成",
				"save": "変更を保存"
			},
			"userOverview": {
				"addNewUser": "新規ユーザーを作成",
				"addUsersToGroups": "選択したユーザーをグループに追加",
				"changeMyPassword": "パスワードを変更",
				"deleteUser": "ユーザーを削除",
				"deleteUsers": "選択したユーザーを削除",
				"disableUser": "ユーザーを無効化",
				"disableUsers": "選択したユーザーを無効化",
				"editProfile": "ユーザーを編集",
				"enableUser": "ユーザーを有効化",
				"enableUsers": "選択したユーザーを有効化",
				"removeUsersFromGroups": "選択したユーザーをグループから削除",
				"resetPassword": "パスワードをリセット",
				"setMeAbsent": "自分を離席中に設定",
				"setMePresent": "自分を在席中に設定",
				"setUserAbsent": "ユーザーを離席中に設定",
				"setUserPresent": "ユーザーを在席中に設定",
				"undeleteUser": "ユーザーの削除を取り消し",
				"undeleteUsers": "選択したユーザーの削除を取り消し"
			}
		},
		"copyTrees": "コピー",
		"createContentLinks": "コンテンツのリンクを作成",
		"dashboard": {
			"myAssignedTasks": {
				"listItem": {
					"tooltip": {
						"destroy": "追跡を削除"
					}
				}
			}
		},
		"deleteNode": {
			"start": "ごみ箱に移動"
		},
		"general": {
			"close": "閉じる"
		},
		"removeReuseLinks": "再利用リンクを削除",
		"reuseTrees": "再利用",
		"search": {
			"blade": {
				"searchFacetsBlade": {
					"clearRefinements": "ファセットフィルターを消去"
				},
				"searchOptionsBlade": {
					"reset": "検索オプションをリセット"
				}
			}
		},
		"cut": "切り取りeiden",
		"paste": "貼り付け"
	},
	"description": {
		"createNewNode": {
			"start": "コンテンツを作成..."
		},
		"search": {
			"component": {
				"searchResultPagination": {
					"totalText": "約{{totalCount}}件中{{rangeStart}}～{{rangeEnd}}件"
				}
			}
		},
		"status": {
			"pageNotFound": "リクエストされたページが見つかりません（404）。"
		},
		"task": {
			"deleted": "追跡が削除されました。",
			"loading": "追跡を読み込み中です。",
			"notFound": "追跡が存在しません。"
		}
	},
	"label": {
		"account": {
			"flyoutTitle": "アカウント: {{userName}}"
		},
		"ap": {
			"blade": {
				"changeGroupAssignments": {
					"displayName": "グループの割り当てを変更"
				},
				"changePassword": {
					"displayName": "パスワードを変更"
				},
				"createGroup": {
					"displayName": "新規ユーザーグループを作成"
				},
				"groupOverview": {
					"displayName": "グループの概要"
				},
				"userClaimsOverview": {
					"displayName": "ユーザーの請求"
				},
				"userDetails": {
					"displayName": "ユーザー"
				},
				"userOverview": {
					"displayName": "ユーザーの概要"
				}
			},
			"changeGroupAssignments": {
				"field": {
					"groups": {
						"add": "新規グループの割り当て",
						"remove": "削除するグループの割り当て"
					}
				}
			},
			"changePassword": {
				"field": {
					"newPassword": "新しいパスワード",
					"newPasswordRetyped": "新しいパスワードを再入力"
				}
			},
			"groupForm": {
				"field": {
					"groupName": "グループ名"
				}
			},
			"groupOverview": {
				"groupIsDeleted": "削除済み",
				"searchForGroup": "名前で検索",
				"withDeleted": "削除済みのグループを表示"
			},
			"task": {
				"productionManagement": {
					"displayName": "生成を管理"
				},
				"userManagement": {
					"displayName": "ユーザーを管理"
				}
			},
			"userClaims": {
				"field": {
					"type": "名前",
					"value": "値"
				}
			},
			"userDetails": {
				"editUser": "ユーザーを編集",
				"field": {
					"created": "作成日",
					"deputies": "代理人",
					"description": "説明",
					"disabled": "無効",
					"displayLanguage": "表示言語",
					"displayName": "表示名",
					"email": "メールアドレス",
					"groups": "グループのメンバーシップ",
					"initialPassword": "初期パスワード",
					"isAbsent": "離席中",
					"isDeleted": "削除済み",
					"modified": "変更日",
					"userName": "ユーザー名"
				},
				"showClaims": "ユーザーの請求を表示"
			},
			"userOverview": {
				"deselectAllUsers": "ユーザーの選択をクリア",
				"filterByGroupMembership": "グループのメンバーシップでフィルタリング",
				"searchForUser": "メールアドレスの名前で検索",
				"selectAllUsers": "すべてのユーザーを選択",
				"selectedUserCount": "{{all}}人中{{selected}}人のユーザーを選択",
				"userCount": "{{count}}人のユーザー",
				"userIsAbsent": "離席中",
				"userIsDeleted": "削除済み",
				"userIsDisabled": "無効",
				"userType": {
					"ad": "Active Directoryユーザー",
					"db": "データベースユーザー"
				},
				"withDeleted": "削除されたユーザーを表示"
			}
		},
		"createNewNodeForm": {
			"error": {
				"configId": "タイプ"
			},
			"loading": {
				"configId": "タイプ"
			}
		},
		"dashboard": {
			"myAssignedTasks": {
				"listItem": {
					"dueDate": "期日: {{dueDate}}",
					"state": "処理ステータス: {{state}}"
				},
				"listTitle": "マイ追跡"
			}
		},
		"fragmentPoolBlade": {
			"listType": "リストのタイプを選択",
			"title": "参照フラグメント"
		},
		"imagetype": {
			"img_50": "固定幅、狭い",
			"img_80": "固定幅、広い",
			"img_fixed_column": "テキスト領域のグラフィック",
			"img_fixed_textarea": "タイプエリアのグラフィック",
			"img_free": "段落内のグラフィック",
			"img_margin": "マージン領域のグラフィック",
			"img_original": "元の幅を使用"
		},
		"listtype": {
			"ordered": "番号付きリスト",
			"secondLevel": "第2レベルのリスト"
		},
		"properties": {
			"inherited": "継承済み"
		},
		"resourceTree": {
			"graphicLabel": "グラフィックラベル",
			"graphicOptions": "グラフィック設定"
		},
		"safety": {
			"severity": "危険レベル",
			"symbols": "記号",
			"type": "危険のタイプ"
		},
		"safetyPoolBlade": {
			"title": "安全に関するメッセージを挿入"
		},
		"screens": {
			"information": "情報"
		},
		"search": {
			"blade": {
				"searchFacetsBlade": {
					"title": "ファセットフィルター"
				},
				"searchOptionsBlade": {
					"title": "検索オプション"
				},
				"searchResultBlade": {
					"title": "検索結果",
					"titleWithSearchTerm": "「{{searchTerm}}」の検索結果"
				}
			},
			"component": {
				"quickSearchBox": "何をお探しですか?",
				"searchFacetsList": {
					"facetItemLabels": {
						"from": "自",
						"lastWeek": "直近7日間",
						"me": "自分",
						"other": "その他",
						"today": "今日",
						"until": "期限",
						"yesterday": "昨日"
					},
					"facetLabels": {
						"nodeClass_facet": "ノードクラス",
						"node_md_facet": "最終変更者",
						"node_mdd_facet": "最終変更日"
					}
				},
				"searchOptionsForm": {
					"searchKey": {
						"filetype": "ファイルタイプ",
						"imgContent": "画像コンテンツ"
					},
					"searchKeys": "検索するデータを制限する",
					"searchLanguages": "検索する言語を制限する",
					"searchVersion": {
						"all": "すべてのバージョン",
						"approved": "最後にリリースしたバージョン",
						"latest": "最新のバージョン",
						"latestApproved": "最新のバージョンと最後にリリースしたバージョン"
					},
					"searchVersions": "検索するバージョンを制限する",
					"select": {
						"other": "その他"
					}
				},
				"searchOptionsSwitch": "検索オプション"
			}
		},
		"structure": {
			"header": "構造",
			"source": {
				"title": "ソース"
			},
			"target": {
				"title": "ターゲット"
			}
		}
	},
	"message": {
		"ap": {
			"error": {
				"userClaims": {
					"noPermissions": "ユーザーの請求を表示する権限がありません。"
				},
				"userDetails": {
					"noUserGiven": "ユーザーが指定されていません"
				},
				"userOverview": {
					"noDisabledUsersSelected": "選択したユーザーの中に無効なユーザーはいません。",
					"noEnabledUsersSelected": "選択したユーザーの中に有効なユーザーはいません。"
				}
			},
			"hasUnsavedChanges": {
				"body": "最新の変更は保存されていません。変更内容は失われます。",
				"question": "ユーザープロフィールの編集をキャンセルしてよろしいですか?",
				"title": "未保存の変更"
			},
			"info": {
				"userClaims": {
					"noUserClaimsFound": "ユーザー{{userName}}の請求が見つかりません。"
				}
			},
			"success": {
				"groupCreated": "ユーザーグループは正常に作成されました",
				"groupDeleted": "ユーザーグループは正常に削除されました",
				"groupUndeleted": "削除の取り消しは正常に完了しました",
				"multiUsersDeleted": "ユーザーは正常に削除されました",
				"multiUsersDisabled": "ユーザーは正常に無効化されました",
				"multiUsersEnabled": "ユーザーは正常に有効化されました",
				"multiUsersUndeleted": "ユーザー削除の取り消しは正常に完了しました",
				"passwordChanged": "パスワードは正常に変更されました",
				"setAbsent": "正常に離席中に保存されました",
				"setPresent": "正常に在席中に保存されました",
				"singleUserDeleted": "ユーザーは正常に削除されました",
				"singleUserDisabled": "ユーザーは正常に無効化されました",
				"singleUserEnabled": "ユーザーは正常に有効化されました",
				"singleUserUndeleted": "ユーザー削除の取り消しは正常に完了しました",
				"userCreated": "新規ユーザーは正常に作成されました",
				"userUpdated": "ユーザーへの変更は正常に保存されました",
				"usersAddedToGroups": "ユーザーのグループへの追加は正常に完了しました",
				"usersRemovedFromGroups": "ユーザーのグループからの削除は正常に完了しました"
			},
			"validationError": {
				"GroupNameRequired": "「グループ名」フィールドは必須です",
				"NoGroupSelected": "グループが選択されていません"
			}
		},
		"createNewNode": {
			"class": {
				"docuManagerOnly": "このタイプのコンテンツはST4 DocuManagerでのみ作成できます",
				"permissionDenied": "ここでは、このタイプのコンテンツを作成できません"
			}
		},
		"dashboard": {
			"myAssignedTasks": {
				"error": {
					"loading": "割り当てられたトラックの読み込み中にエラーが発生しました。"
				},
				"info": {
					"noTasks": "現在アクティブなトラックはありません。"
				}
			},
			"welcome": {
				"title": "ようこそ"
			}
		},
		"editor": {
			"canEditCheck": {
				"error": {
					"AspectNotEditingLanguage": "現在の言語ではコンテンツを編集できません。コンテンツは別の言語で作成されています。翻訳ワークフローを使用せずにコンテンツを編集するには、SCHEMA ST4 PowerまたはAuthor Clientに切り替えてください。",
					"ContentLinked": "ここは再利用コンテンツです。そのため、コンテンツを直接編集することはできません。このコンテンツを編集するには、SCHEMA ST4 PowerまたはAuthor Clientに切り替えてください。",
					"InternalError": "不具合が発生しました。後でもう一度やり直してください。",
					"NodeDeleted": "コンテンツを開こうとしましたが、このコンテンツは完全に削除されています。コンテンツおよび必要に応じてエディターを閉じてください。",
					"NodeLocked": "このコンテンツは現在「{{0}}」が編集中です。後でもう一度やり直してください。",
					"NodeReleasedCurrentAspect": "このコンテンツは現在の言語でリリースされています。新しいバージョンを編集するには、SCHEMA ST4 PowerまたはAuthor Clientに切り替えてください。それによりこのコンテンツを編集できます。",
					"NodeReleasedOtherAspect": "このコンテンツは別の言語でリリースされています。新しいバージョンを編集するには、SCHEMA ST4 PowerまたはAuthor Clientに切り替えてください。それによりこのコンテンツを編集できます。",
					"NodeReused": "ここは再利用コンテンツです。そのため、コンテンツを直接編集することはできません。このコンテンツを編集するには、SCHEMA ST4 PowerまたはAuthor Clientに切り替えてください。",
					"PermissionDenied": "このコンテンツは編集できません。必要な権限がありません。"
				},
				"warning": {
					"NodeInTranslation": "このコンテンツは現在翻訳中です。コンテンツを変更すると、再度翻訳する必要があります。"
				}
			}
		},
		"fragmentPoolBlade": {
			"empty": "フラグメントにコンテンツがありません。",
			"empty_group": "グループノードが選択されています。そのため、グループノードにコンテンツが関連付けられておらず、挿入できません。",
			"no_fragment": "フラグメントを選択してください。",
			"not_possible_fragment": "この時点では選択したフラグメントを挿入できません。コンテンツの構造とフラグメントが適合しません。",
			"not_possible_structure_fragment": "この時点では選択した構造を挿入できません。コンテンツの構造と構造フラグメントのタイプが適合しません。",
			"recursion": "このフラグメントを挿入すると無限ループが発生します。",
			"rights": "選択したフラグメントを挿入する権限がありません。"
		},
		"resourcePoolBlade": {
			"not_possible": "この時点ではグラフィックを挿入できません。代わりに、段落内部にカーソルを配置してください。"
		},
		"safetyPool": {
			"not_possible": "この時点では安全に関するメッセージを挿入できません。コンテンツの構造と安全に関するメッセージが適合しません。"
		},
		"search": {
			"error": {
				"corruptResponse": "ST4の検索中にエラーが発生しました。「データなし」 - 「GraphQL APIからエラーもデータも受信しませんでした。」",
				"fromException": "ST4の検索中にエラーが発生しました。 \"{{errorName}}\" - \"{{errorMessage}}\""
			},
			"info": {
				"searchingForTerm": "ST4で「{{searchTerm}}」を検索しています..."
			},
			"warning": {
				"noSearchFacets": "ST4で「{{searchTerm}}」を検索しましたが、ヒットするフィルターファセットがありませんでした。",
				"noSearchResult": "ST4で「{{searchTerm}}」を検索しましたが、ヒットするアイテムがありませんでした。"
			}
		},
		"tree": {
			"dnd": {
				"tryAgain": "問題が発生しました。もう一度やり直してください。"
			}
		},
		"warning": {
			"editorSave": {
				"invalidLinkRemovalForTextModuleCausedInvalidXML": "ID {{0}}を持つフラグメントへの参照を解決できませんでした。再利用を自動で削除できませんでした。コンテンツを閉じる前にフラグメントを削除してください。",
				"linkForResourceCouldNotBeCreated": "ID {{0}}を持つリソースへのリンクを解決できませんでした。そのため、参照先はコンテンツを閉じるときに削除されます。",
				"linkForTextModuleCouldNotBeCreated": "ID {{0}}を持つフラグメントへの参照を解決できませんでした。そのため、再利用先はコンテンツを閉じるときに削除されます。"
			}
		}
	}
}