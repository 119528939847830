import { keys } from "@st4/ui-strings"
import { Empty } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"
import { ImageDescriptions, StyledCard } from "./ImageDescriptions"
import { ExtractByTypeName } from "@st4/graphql"
import { ST4NodeWithContentName } from "../../../graphql/applicationQueries"
import { ImageDisplayContainer } from "./ImageMedia"
import { Icon, Regular } from "@st4/icons"

type NoMediaProps = {
  title: React.ReactNode
  mediaInfo: ExtractByTypeName<"NoMediaInfo", NonNullable<ST4NodeWithContentName<"MediaContent">["content"]["media"]>>
  mediaNode: Parameters<typeof ImageDescriptions>[0]["mediaNode"]
}

export function NoMedia({ title, mediaNode }: NoMediaProps) {
  const { t } = useTranslation()

  const cover = (
    <ImageDisplayContainer>
      <Empty
        image={<Icon component={Regular.Ban} style={{ fontSize: "65px", color: "#666", paddingTop: "25px" }} />}
        description={t(keys.message.preview.resourceNode.noContent)}
      />
    </ImageDisplayContainer>
  )

  return (
    <StyledCard
      cover={cover}
      $isGroup={false}
      $isCommented={false}
      $isCommentFocused={false}
      $isCommentPrefocused={false}
    >
      {<ImageDescriptions mediaNode={mediaNode} title={title} />}
    </StyledCard>
  )
}
