export function handleGetConfigInfos(config) {
    var safetyInfos = config.contentInfo.safetyInfo;
    return {
        status: 200,
        body: {
            safety: {
                icons: safetyInfos.icons,
                safetyTypes: safetyInfos.safetyTypes,
                severityLevels: safetyInfos.severityLevels,
                safetyImageInfo: safetyInfos.safetyImageInfo
            },
            languageXml: config.contentInfo.languageXml,
            commentModel: config.contentInfo.commentModel,
            inlineStyles: config.contentInfo.inlineStyles,
            settings: config.settings
        }
    };
}
