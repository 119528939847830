import LogoSVG from "./images/Logo.svg"
import DotsSVG from "./images/dots.svg"
import styled from "styled-components"

const Logo = styled.div`
  background: url(${LogoSVG});
  background-repeat: no-repeat;
  width: 127px;
  height: 42px;
  background-position: left center;
  background-size: cover;
`
export const LogoSmall = styled(Logo)`
  width: 42px;
`

export const DotsLeft = styled.div`
  background: url(${DotsSVG});
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  background-position: left bottom;
  background-size: 90%;
`

export default Logo
