import React, { useState } from "react"
import { Tree } from "antd"
import { FacetWrapper, clearString, getLabel, getRefinementId } from "./Utils"
import { TaxonomyNode } from "./TaxonomyFacet"
import { useTranslation, TFunction } from "react-i18next"
import { baseTestId } from "../SearchFacetsList"
import { SearchFacetItem } from "../../types"

type NodeClassFacetProps = {
  facetKey: string
  title: string
  availableValues?: SearchFacetItem[] | null
  selectedValues?: React.Key[]
  onSubmit: (selectedValues: React.Key[]) => void
}

export function NodeClassFacet(props: NodeClassFacetProps) {
  const { t } = useTranslation()
  const [selectedValues, setSelectedValues] = useState(props.selectedValues || [])

  // Do some magic to control which tree nodes are expanded
  const noOtherNodes = (item: SearchFacetItem) => item.label != "other"
  const toRefinementId = (item: SearchFacetItem) => getRefinementId(props.facetKey, item.value)
  const getNodes = (items: SearchFacetItem[] | null): SearchFacetItem[] =>
    items?.length ? [...items, ...getNodes(items.flatMap((item) => item.items || []))] : []
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>(
    getNodes(props.availableValues?.filter(noOtherNodes) || []).map(toRefinementId) || Array<React.Key>(),
  )

  const testId = "nodeClass"
  return (
    <FacetWrapper testId={testId} title={props.title}>
      <Tree
        treeData={toTreeData(props.facetKey, props.availableValues ? props.availableValues : null, t)}
        titleRender={(node) => (
          <span
            data-testid={`${baseTestId}-nodeClass-${clearString(props.title)}-node-${clearString(
              node.title?.toString() || "",
            )}`}
          >
            {node.title}
          </span>
        )}
        expandedKeys={expandedKeys}
        multiple
        checkable
        checkStrictly={true} // don't mark parent nodes as partially selected, if a child node is selected
        checkedKeys={selectedValues}
        selectedKeys={selectedValues}
        onExpand={(expandedKeys, _) => setExpandedKeys(expandedKeys)}
        onCheck={(checkedKeys) => selectHandler(Array.isArray(checkedKeys) ? checkedKeys : checkedKeys.checked)}
        onSelect={(selectedKeys) => selectHandler(selectedKeys)}
      />
    </FacetWrapper>
  )

  function selectHandler(selectedKeys: React.Key[]) {
    setSelectedValues(selectedKeys)
    props.onSubmit(selectedKeys)
  }
}

function toTreeData(
  facetKey: string,
  items: SearchFacetItem[] | null,
  translate: TFunction<"translation">,
  orderByLabel?: boolean,
): TaxonomyNode[] | undefined {
  const other = items?.find((item) => item.label == "other")
  let treeData = !items
    ? []
    : items
        .filter((item) => !other || item.label != other.label)
        .map((item) => ({
          key: getRefinementId(facetKey, item.value),
          title: `${getLabel(item.label, translate)} (${item.count})`,
          children: toTreeData(facetKey, item.items, translate),
        }))
  if (orderByLabel) treeData = treeData.sort((a, b) => (a.title <= b.title ? -1 : 1))
  if (other)
    treeData.push({
      key: getRefinementId(facetKey, other.value),
      title: `${getLabel(other.label, translate)} (${other.count})`,
      children: toTreeData(facetKey, other.items, translate, true),
    })

  return treeData
}
