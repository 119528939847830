import React from "react"
import styled, { keyframes } from "styled-components"

const loadingAnim = keyframes`
0% {
  x: -50%
}
100% {
  x: 100%
}
`

const LoadingRect = styled.rect`
  animation: ${loadingAnim} infinite ${() => (1 + Math.ceil(Math.random() * 5)) * 400}ms linear;
`

const LoadingRectBack = styled.rect`
  fill: ${({ theme }) => theme.greys["200"]};
`

const ThemedGradientStop = styled.stop`
  stop-color: ${({ theme }) => theme.greys["100"]};
`

type LoadingPlaceholderProps = {
  width: number | string
  height: number | string
}

let _globalPlaceholderCounter = 0

export function LoadingPlaceholder(props: LoadingPlaceholderProps) {
  const id = `grad${_globalPlaceholderCounter++}`
  return (
    <svg width={props.width} height={props.height}>
      <defs>
        <linearGradient id={id} x1="0%" y1="0%" x2="100%" y2="0%">
          <ThemedGradientStop offset="0%" stopOpacity="0" />
          <ThemedGradientStop offset="30%" stopOpacity="0.5" />
          <ThemedGradientStop offset="70%" stopOpacity="0.5" />
          <ThemedGradientStop offset="100%" stopOpacity="0" />
        </linearGradient>
      </defs>
      <LoadingRectBack x="0" y="0" width={props.width} height={props.height} strokeWidth="0" />
      <LoadingRect x="0" y="0" width="33%" height={props.height} fill={`url(#${id})`} strokeWidth="0" />
    </svg>
  )
}
