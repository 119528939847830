import * as Types from "@st4/graphql"

import { gql } from "@apollo/client"
import * as Apollo from "@apollo/client"
const defaultOptions = {} as const
export type CreateAuthenticationTokenMutationVariables = Types.Exact<{
  loginData: Types.LoginDataInput
}>

export type CreateAuthenticationTokenMutation = {
  __typename: "Mutation"
  createAuthenticationToken:
    | { __typename: "AuthenticationError"; message: string; cause: Types.AuthenticationErrorCause }
    | {
        __typename: "AuthenticationSuccess"
        authenticationToken: string
        displayName: string
        userName: string
        loginName: string
        languageCode: string
        guiAspectId: any
      }
}

export type CheckLoginStateQueryVariables = Types.Exact<{ [key: string]: never }>

export type CheckLoginStateQuery = { __typename: "Query"; me: { __typename: "User" } }

export const CreateAuthenticationTokenDocument = gql`
  mutation CreateAuthenticationToken($loginData: LoginDataInput!) {
    createAuthenticationToken: createAuthenticationTokenV2(loginData: $loginData) {
      ... on AuthenticationSuccess {
        authenticationToken
        displayName
        userName
        loginName
        languageCode
        guiAspectId
      }
      ... on AuthenticationError {
        message
        cause
      }
      __typename
    }
  }
`
export type CreateAuthenticationTokenMutationFn = Apollo.MutationFunction<
  CreateAuthenticationTokenMutation,
  CreateAuthenticationTokenMutationVariables
>

/**
 * __useCreateAuthenticationTokenMutation__
 *
 * To run a mutation, you first call `useCreateAuthenticationTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAuthenticationTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAuthenticationTokenMutation, { data, loading, error }] = useCreateAuthenticationTokenMutation({
 *   variables: {
 *      loginData: // value for 'loginData'
 *   },
 * });
 */
export function useCreateAuthenticationTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAuthenticationTokenMutation,
    CreateAuthenticationTokenMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateAuthenticationTokenMutation, CreateAuthenticationTokenMutationVariables>(
    CreateAuthenticationTokenDocument,
    options,
  )
}
export type CreateAuthenticationTokenMutationHookResult = ReturnType<typeof useCreateAuthenticationTokenMutation>
export type CreateAuthenticationTokenMutationResult = Apollo.MutationResult<CreateAuthenticationTokenMutation>
export type CreateAuthenticationTokenMutationOptions = Apollo.BaseMutationOptions<
  CreateAuthenticationTokenMutation,
  CreateAuthenticationTokenMutationVariables
>
export const CheckLoginStateDocument = gql`
  query CheckLoginState {
    me {
      __typename
    }
  }
`

/**
 * __useCheckLoginStateQuery__
 *
 * To run a query within a React component, call `useCheckLoginStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckLoginStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckLoginStateQuery({
 *   variables: {
 *   },
 * });
 */
export function useCheckLoginStateQuery(
  baseOptions?: Apollo.QueryHookOptions<CheckLoginStateQuery, CheckLoginStateQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CheckLoginStateQuery, CheckLoginStateQueryVariables>(CheckLoginStateDocument, options)
}
export function useCheckLoginStateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CheckLoginStateQuery, CheckLoginStateQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CheckLoginStateQuery, CheckLoginStateQueryVariables>(CheckLoginStateDocument, options)
}
export type CheckLoginStateQueryHookResult = ReturnType<typeof useCheckLoginStateQuery>
export type CheckLoginStateLazyQueryHookResult = ReturnType<typeof useCheckLoginStateLazyQuery>
export type CheckLoginStateQueryResult = Apollo.QueryResult<CheckLoginStateQuery, CheckLoginStateQueryVariables>
