import { EditMode } from "@st4/content-tools"
import { Tooltip } from "@st4/ui"
import React, { ReactNode, useState } from "react"
import styled, { css } from "styled-components"
import { useIsNodeSelected } from "../../utils"
import { TreeNode } from "../../../../contentModel/nodeContext"
import { keys } from "@st4/ui-strings"
import { Icon, Light, Regular } from "@st4/icons"

type NodeInlineEditorButtonsProps = {
  isError: boolean
  treeNode: TreeNode
  handleOpenEditor: (editMode: EditMode) => void
  handleCloseEditor: () => void
  children: ReactNode
}

export function NodeInlineEditorButton({
  isError,
  treeNode,
  handleOpenEditor,
  handleCloseEditor,
  children,
}: NodeInlineEditorButtonsProps) {
  const selected = useIsNodeSelected(treeNode)
  const [nodeHovered, setNodeHovered] = useState(false)

  let usedIcon = <Icon component={Light.Pen} />
  if (isError) usedIcon = <Icon component={Regular.XmarkLarge} />

  return (
    <NodeInlineWrapper
      hovered={nodeHovered}
      selected={selected}
      error={isError}
      onMouseEnter={() => {
        setNodeHovered(true)
      }}
      onMouseLeave={() => {
        setNodeHovered(false)
      }}
    >
      <div style={{ flex: 1 }}>{children}</div>
      <NodeEditorButtonWrapper hovered={nodeHovered} error={isError}>
        {(nodeHovered || isError) && (
          <Tooltip
            translationKey={isError ? keys.button.general.closemessage : keys.button.general.inlineEdit}
            placement="right"
          >
            <NodeEditorIconWrapper onClick={() => (isError ? handleCloseEditor() : handleOpenEditor("inplace"))}>
              {usedIcon}
            </NodeEditorIconWrapper>
          </Tooltip>
        )}
      </NodeEditorButtonWrapper>
    </NodeInlineWrapper>
  )
}

const NodeInlineWrapper = styled.div<{ selected?: boolean; hovered?: boolean; error?: boolean }>`
  display: flex;
  width: 100%;
  flex-direction: row;
  margin-top: -1px;
  border-radius: 1px;
  ${({ theme, error }) =>
    error
      ? css`
          border: 1px solid ${theme.softOrange};
        `
      : css``}
  ${({ theme, selected, error }) =>
    selected && !error
      ? css`
          border: 1px solid ${theme.preview.nodeSelection.selectionColor};
        `
      : css``}
  ${({ theme, hovered, selected, error }) =>
    hovered && !selected && !error
      ? css`
          border: 1px solid ${theme.greys[300]};
        `
      : css``}
  ${({ theme, hovered, selected, error }) =>
    !hovered && !selected && !error
      ? css`
          border: 1px solid ${theme.white};
        `
      : css``}
`

const NodeEditorButtonWrapper = styled.div<{ hovered?: boolean; error?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 24px;
  cursor: pointer;
  ${({ theme, error }) =>
    error
      ? css`
          background-color: ${theme.softOrange};
        `
      : css``}
  ${({ theme, hovered, error }) =>
    hovered && !error
      ? css`
          background-color: ${theme.greys[300]};
        `
      : css``}
  ${({ theme, hovered, error }) =>
    !hovered && !error
      ? css`
          background-color: ${theme.white};
        `
      : css``}
`

const NodeEditorIconWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
