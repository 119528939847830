import { TaskData, TaskDefinition } from "../definition/task"

const migrations = new Map<string, (data: unknown, taskDef: TaskDefinition) => TaskData>()

/**
 * Add an migration for the given task.
 * @param taskDefName The name of the task definition for which this migration should run.
 * @param migration A function accepting previous data and returning the new task data.
 */
export function addTaskDataMigration(
  taskDefName: TaskDefinition["name"],
  migration: (data: unknown, taskDef: TaskDefinition) => TaskData,
) {
  migrations.set(taskDefName, migration)
}

/**
 * Allows to migrate previous task data to new task data.
 * @param data The previous task data
 * @param taskDef The task definition of the state to migrate
 * @returns The task data as it should be for the current version
 */
export function migrate(data: unknown, taskDef: TaskDefinition): TaskData {
  const migration = migrations.get(taskDef.name)
  if (migration) {
    console.info(`Executing migration for ${taskDef.name}.`)
    return migration(data, taskDef)
  }
  throw new Error(`No migration found for ${taskDef.name}.`)
}
