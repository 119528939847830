export default {
	"button": {
		"auth": {
			"login": "Login",
			"loginAgain": "Se connecter de nouveau",
			"logout": "Déconnecter"
		},
		"general": {
			"back": "Précédent",
			"cancel": "Annuler",
			"delete": "Supprimer",
			"edit": "Éditer",
			"exportData": "Exporter les données",
			"next": "Suivant",
			"no": "Non",
			"ok": "OK",
			"open": "Ouvrir",
			"reload": "Mettre à jour",
			"save": "Enregistrer",
			"saveAndLoginAgain": "Enregistrer et se connecter de nouveau",
			"showLess": "Afficher moins",
			"showMore": "Afficher plus",
			"yes": "Oui",
			"changeSelection": "Modifier sélection",
			"choose": "Sélectionner",
			"close": "Fermer",
			"closemessage": "Fermer le message",
			"saveandclose": "Enregistrer et fermer",
			"select": "Sélectionner"
		},
		"navigate": {
			"tree": {
				"levelDown": "Naviguer un niveau vers le bas",
				"levelUp": "Naviguer un niveau vers le haut",
				"toRoot": "Naviguer vers l’élément racine"
			}
		},
		"preview": {
			"comment": {
				"add": "Créer commentaire",
				"allRepliesHide": "Réduire toutes les réponses",
				"allRepliesShow": "Développer toutes les réponses",
				"delete": "Supprimer commentaire",
				"edit": "Éditer commentaire",
				"reply": "Répondre au commentaire"
			},
			"structure": "Structure"
		},
		"workflow": {
			"backToList": "Retour à la liste des tâches",
			"backToTask": "Retour à la tâche",
			"bringWindowToFront": "Fenêtre au premier plan",
			"executeAction": "Exécuter action"
		},
		"ap": {
			"changeGroupAssignments": {
				"addUsersToGroups": "Ajouter utilisateur à groupes",
				"cancel": "Annuler",
				"removeUsersFromGroups": "Supprimer utilisateur de groupes"
			},
			"changePassword": {
				"cancel": "Annuler",
				"changePassword": "Modifier le mot de passe"
			},
			"groupForm": {
				"cancel": "Annuler",
				"create": "Créer groupe d’utilisateurs"
			},
			"groupOverview": {
				"addNewGroup": "Créer nouveau groupe d’utilisateurs",
				"deleteGroup": "Supprimer groupe d’utilisateurs",
				"undeleteGroup": "Annuler la suppression"
			},
			"userDetails": {
				"cancel": "Annuler",
				"create": "Créer utilisateur",
				"save": "Enregistrer les modifications"
			},
			"userOverview": {
				"addNewUser": "Créer nouvel utilisateur",
				"addUsersToGroups": "Ajouter utilisateurs sélectionnés à groupes",
				"changeMyPassword": "Modifier le mot de passe",
				"deleteUser": "Supprimer utilisateur",
				"deleteUsers": "Supprimer utilisateurs sélectionnés",
				"disableUser": "Désactiver utilisateur",
				"disableUsers": "Désactiver utilisateurs sélectionnés",
				"editProfile": "Éditer utilisateur",
				"enableUser": "Activer utilisateur",
				"enableUsers": "Activer utilisateurs sélectionnés",
				"removeUsersFromGroups": "Supprimer utilisateurs sélectionnés de groupes",
				"resetPassword": "Rétablir le mot de passe",
				"setMeAbsent": "Me définir comme absent",
				"setMePresent": "Me définir comme présent",
				"setUserAbsent": "Définir utilisateur comme absent",
				"setUserPresent": "Définir utilisateur comme présent",
				"undeleteUser": "Annuler la suppression",
				"undeleteUsers": "Annuler la suppression pour utilisateurs sélectionnés"
			}
		},
		"blade": {
			"close": "Fermer",
			"insert": "Insérer",
			"nextStep": "Prochaine étape"
		},
		"copyTrees": "Copier",
		"createContentLinks": "Créer une liaison de contenu",
		"dashboard": {
			"myAssignedTasks": {
				"listItem": {
					"tooltip": {
						"destroy": "Supprimer track"
					}
				}
			}
		},
		"deleteNode": {
			"start": "Déplacer dans la corbeille"
		},
		"removeReuseLinks": "Supprimer la réutilisation",
		"reuseTrees": "Réutiliser",
		"search": {
			"blade": {
				"searchFacetsBlade": {
					"clearRefinements": "Supprimer filtre de facettes"
				},
				"searchOptionsBlade": {
					"reset": "Rétablir options de la recherche"
				}
			}
		},
		"cut": "Couper",
		"paste": "Insérer"
	},
	"description": {
		"hyperlink": {
			"opensInNewWindow": "Ouvre le lien dans un nouvel onglet"
		},
		"image": {
			"width": {
				"50": "Largeur fixe, étroit",
				"80": "Largeur fixe, large",
				"fixedColumn": "Graphique dans colonne de texte",
				"fixedTextarea": "Graphique dans surface d’impression entière",
				"margin": "Graphique dans colonne de marge",
				"original": "Adopter taille du graphique"
			}
		},
		"preview_legacy": {
			"selectedTextConfirm": "Commenter la sélection de texte"
		},
		"workflow": {
			"action": "Actions qui peuvent être exécutées à cette tâche Workflow",
			"historyTab": "L’historique de modification de cette tâche Workflow",
			"overviewTab": "Vue d’ensemble des valeurs de champ actuelles de cette tâche de Workflow"
		},
		"createNewNode": {
			"start": "Créer contenu..."
		},
		"createNewNodeForm": {
			"configId": {
				"error": "Impossible de charger une sélection de types pour les données actuelles !"
			},
			"formTitle": "Créer contenu",
			"permisson": {
				"error": "Vous ne possédez pas le droit de créer du nouveau contenu ici."
			}
		},
		"createNewNodeVisualizer": {
			"error": {
				"commandNotImplemented": "Aucune surface n’est implémentée pour la commande \"{{commandName}}\" !"
			},
			"inProgress": {
				"createNode": "Création de contenu en cours..."
			}
		},
		"search": {
			"component": {
				"searchResultPagination": {
					"totalText": "{{rangeStart}}-{{rangeEnd}} sur environ {{totalCount}} Résultats"
				}
			}
		},
		"status": {
			"pageNotFound": "Page demandée introuvable (404)."
		},
		"task": {
			"deleted": "Le track a été supprimé.",
			"loading": "Track en cours de chargement.",
			"notFound": "Le track n’existe pas."
		}
	},
	"label": {
		"auth": {
			"loggedOut": "Déconnecté",
			"password": "Mot de passe",
			"username": "Nom de l’utilisateur"
		},
		"comment": {
			"comment": "Commentaire",
			"comment_one": "Commentaire",
			"comment_other": "Commentaires",
			"context": "Contexte de commentaires",
			"placeholder": {
				"reply": "Composer une réponse...",
				"selection": "Commenter la sélection..."
			},
			"replyTo": "Réponse à :",
			"resizecommentsview": "Mettre à l’échelle le mode commentaires",
			"selectedText": "Texte sélectionné : \"{{selection}}\"",
			"stateSelection": "Sélectionner état",
			"typeSelection": "Sélectionner type"
		},
		"general": {
			"ascending": "Ordre croissant",
			"aspect": "Aspect",
			"check": "Vérifier",
			"chooseFile": "Sélectionner le fichier",
			"creationTime": "Date de création",
			"dates": "Données",
			"descending": "Ordre décroissant",
			"details": "Détails",
			"field": "Champ",
			"field_one": "Champ",
			"field_other": "Champs",
			"fileFormat_other": "Formats de fichier",
			"filter": "Filtre",
			"history": "Historique",
			"id": "Id",
			"ignore": "Ignorer",
			"indexentry": {
				"_": "Entrée d’index",
				"key": "Entrée",
				"sort": "Clé de tri",
				"subEntryKey": "Sous-entrée"
			},
			"loading": "charge !",
			"modificationTime": "Date de modification",
			"name": "Nom",
			"no": "non",
			"noValue": "Vide",
			"node": "Nœud",
			"node_one": "Nœud",
			"node_other": "Nœuds",
			"overview": "Vue d’ensemble",
			"people": "Personnes",
			"reuses": "Utilisations",
			"scaling": "Échelle",
			"selectOrDropFile": "Sélectionnez un fichier ou glissez-déposez le fichier ici.",
			"settings": "Réglages",
			"status": "État",
			"systemName": "Nom de système",
			"taxonomy": "Taxonomie",
			"title": "Titre",
			"type": "Type",
			"ui": "Interface utilisateur",
			"value": "Valeur",
			"yes": "oui",
			"misc": "Divers",
			"pleaseSelect": "Sélectionner",
			"search": "Recherche",
			"searchEmpty": "La recherche n’a pas donné de résultats.",
			"sortBy": "Trier"
		},
		"generic": {
			"addToSelection": "Ajouter",
			"available": "Disponible",
			"enterText": "Entrer texte...",
			"language": "Langue",
			"removeFromSelection": "Supprimer",
			"selection": "Sélection",
			"unknown": "Inconnu",
			"contentLanguage": "Langue de contenu",
			"guiLanguage": "Langue d’affichage",
			"saved": "Enregistré"
		},
		"hyperlink": {
			"broken": "Lien endommagé"
		},
		"nodeproperties": "Propriétés du nœud",
		"operation": {
			"syncviews": "Synchroniser affichages"
		},
		"preview": {
			"answerCount": "Une réponse",
			"answerCount_one": "Une réponse",
			"answerCount_other": "{{count}} réponses",
			"emphasis": "Style : {{displayName}}",
			"exifCount": "{{count}} éléments",
			"imageDimensions": "Taille",
			"imageSlot": {
				"original": "Graphique original",
				"print": "Format d’impression",
				"web": "Format Web"
			},
			"informationpool": "Pool d’informations",
			"layout": "Mise en page",
			"lexicon": {
				"glossaryEntry": "Entrée de glossaire",
				"grammaticalGender": "Genre grammatical",
				"subjectField": "Domaine",
				"term": "Dénomination",
				"tooltipHeadline": "Entrée de lexique de \"{{lexiconName}}\"",
				"validity": "État"
			},
			"metaref": {
				"dynamicReference": "Référence contextuelle à la métadonnée \"{{metadataName}}\"",
				"languageHeadline": "Référence à fichier de langue",
				"notFound": "Impossible de résoudre référence !",
				"ontologyHeadline": "Référence à la taxonomie <0>{{label}}</0>",
				"staticHeadline": "Référence à la métadonnée <0>{{label}}</0>"
			},
			"pagebreak": "Saut de page",
			"table": {
				"border": "Cadre",
				"border_framed": "Cadre",
				"border_header": "En-tête",
				"border_legend": "Tableau de légende",
				"border_noframe": "Sans cadre",
				"scaling": "Mise à l’échelle du tableau",
				"scaling_fixed": "Met le tableau à l’échelle de la largeur de la zone de texte.",
				"scaling_scaled": "Met le tableau à l’échelle selon les largeurs des colonnes de tableau."
			},
			"imageLayoutType": "Type de mise en page",
			"marginText": "Texte dans colonne de marge"
		},
		"projects": {
			"headline": "Projets"
		},
		"warning": {
			"error": "Erreur",
			"information": "Information",
			"notice": "Avis",
			"warning": "Avertissement",
			"question": "Question"
		},
		"widget": {
			"createdBy": "Créé par :",
			"lastChangeBy": "Dernière modification de :",
			"reusetitle": "Utilisations de \"{{nodeLabel}}\""
		},
		"widgets": {
			"contentreuses": "Liaisons de contenu",
			"directreuses": "Utilisations directes",
			"indirectreuses": "Utilisations indirectes",
			"reuses": "Réutilisations"
		},
		"workflow": {
			"action_other": "Actions",
			"activity": "Activité",
			"addFieldValue": "Ajouter valeur de champ",
			"askForSignatureDialog": "Signer la validation",
			"assignee": "Utilisateur",
			"assignee_one": "Utilisateur",
			"assignee_other": "Utilisateurs",
			"confirmInput": "Confirmer entrée :",
			"createDate": "Créée le",
			"customColumDefinition_other": "Définitions des colonnes",
			"dueDate": "Date de fin",
			"fieldValueOriginal": "Valeur d’origine",
			"fieldValueUpdated": "Valeur mise à jour",
			"initiator": "Donneur d’ordre",
			"lastChangedDate": "Modifié le",
			"linkedNodes": "Nœuds connectés",
			"modelName": "Workflow",
			"noNodeSelected": "Aucun nœud sélectionné",
			"parent": "Workflow parent",
			"releaseDetails": "Détails de validation",
			"releaseType": "Type de validation",
			"selectNode": "Sélectionner le nœud",
			"startDate": "Date de départ",
			"subWorkflow_other": "Sous-Workflows",
			"subtreeRelease": "Validation du sous-arbre",
			"urlOpened": "Lien ouvert"
		},
		"account": {
			"flyoutTitle": "Compte d’utilisateur : {{userName}}"
		},
		"ap": {
			"blade": {
				"changeGroupAssignments": {
					"displayName": "Modifier affectations de groupes"
				},
				"changePassword": {
					"displayName": "Modifier le mot de passe"
				},
				"createGroup": {
					"displayName": "Créer nouveau groupe d’utilisateurs"
				},
				"groupOverview": {
					"displayName": "Vue d’ensemble des groupes"
				},
				"userClaimsOverview": {
					"displayName": "Claims d’utilisateur"
				},
				"userDetails": {
					"displayName": "Utilisateurs"
				},
				"userOverview": {
					"displayName": "Vue d’ensemble des utilisateurs"
				}
			},
			"changeGroupAssignments": {
				"field": {
					"groups": {
						"add": "Nouvelles affectations de groupes",
						"remove": "Affectations de groupes à supprimer"
					}
				}
			},
			"changePassword": {
				"field": {
					"newPassword": "Nouveau mot de passe",
					"newPasswordRetyped": "Répéter le nouveau mot de passe"
				}
			},
			"groupForm": {
				"field": {
					"groupName": "Nom de groupe"
				}
			},
			"groupOverview": {
				"groupIsDeleted": "Supprimé",
				"searchForGroup": "Rechercher nom",
				"withDeleted": "Afficher les groupes d’utilisateurs supprimés"
			},
			"task": {
				"productionManagement": {
					"displayName": "Gérer productions"
				},
				"userManagement": {
					"displayName": "Gérer utilisateurs"
				}
			},
			"userClaims": {
				"field": {
					"type": "Nom",
					"value": "Valeur"
				}
			},
			"userDetails": {
				"editUser": "Éditer utilisateur",
				"field": {
					"created": "Date de création",
					"deputies": "Représentant",
					"description": "Description",
					"disabled": "Désactivé",
					"displayLanguage": "Langue d’affichage",
					"displayName": "Nom d’affichage",
					"groups": "Appartenance aux groupes",
					"initialPassword": "Mot de passe initial",
					"isAbsent": "Absent",
					"isDeleted": "Supprimé",
					"modified": "Date de modification",
					"userName": "Nom de l’utilisateur"
				},
				"showClaims": "Afficher les claims d’utilisateur"
			},
			"userOverview": {
				"deselectAllUsers": "Supprimer la sélection d’utilisateurs",
				"filterByGroupMembership": "Filtrer selon appartenance aux groupes",
				"selectAllUsers": "Sélectionner tous les utilisateurs",
				"selectedUserCount": "Sélectionné {{selected}} sur {{all}} utilisateurs",
				"userCount": "{{count}} utilisateurs",
				"userIsAbsent": "Absent",
				"userIsDeleted": "Supprimé",
				"userIsDisabled": "Désactivé",
				"userType": {
					"ad": "Utilisateur Active Directory",
					"db": "Utilisateur de la base de données"
				},
				"withDeleted": "Afficher les utilisateurs supprimés"
			}
		},
		"askRequiredMetadataForm": {
			"requiredMetadata": "Métadonnées obligatoires"
		},
		"chooseProject": {
			"title": "Sélectionner projet"
		},
		"content": {
			"advancedEditor": "Ouvrir dans l’éditeur"
		},
		"contentBlade": {
			"title": "Contenus"
		},
		"createNewNodeForm": {
			"RelativeInsertionPosition": "Position",
			"configId": "Type",
			"error": {
				"configId": "Type"
			},
			"loading": {
				"configId": "Type"
			},
			"title": "Titre"
		},
		"crossReferencePoolBlade": {
			"title": "Insérer référence croisée"
		},
		"dashboard": {
			"myAssignedTasks": {
				"listItem": {
					"dueDate": "Date d’échéance : {{dueDate}}",
					"state": "État d’édition : {{state}}"
				},
				"listTitle": "Mes tracks"
			}
		},
		"explore": {
			"header": "Free Mode"
		},
		"filter": {
			"all": "Toutes les métadonnées",
			"filled": "Métadonnées remplies",
			"unfilled": "Métadonnées non remplies"
		},
		"fragmentPoolBlade": {
			"listType": "Sélectionner le type de liste",
			"title": "Référencer fragment"
		},
		"help": {
			"link": {
				"privacy": {
					"title": "Protection des données"
				}
			}
		},
		"imagetype": {
			"img_50": "Largeur fixe, étroit",
			"img_80": "Largeur fixe, large",
			"img_fixed_column": "Graphique dans colonne de texte",
			"img_fixed_textarea": "Graphique dans surface d’impression entière",
			"img_free": "Graphique en ligne",
			"img_margin": "Graphique dans colonne de marge",
			"img_original": "Appliquer taille du graphique"
		},
		"listtype": {
			"ordered": "Liste de numérotation",
			"secondLevel": "Liste à puces de deuxième niveau",
			"unordered": "Liste à puces de premier niveau"
		},
		"properties": {
			"_": "Métadonnées",
			"inherited": "hérité",
			"readOnlyAccess": "Accès lecture",
			"search": {
				"titles": "Rechercher titres de métadonnées"
			}
		},
		"relativeInsertionPosition": {
			"AFTER": "Après le contenu sélectionné",
			"AS_CHILD": "Comme contenu enfant",
			"BEFORE": "Avant le contenu sélectionné"
		},
		"releasestate": {
			"isImplicitlyReleased": "Validation indirecte"
		},
		"resourcePoolBlade": {
			"title": "Référencer ressource"
		},
		"resourceTree": {
			"graphicLabel": "Nom du graphique",
			"graphicOptions": "Options graphique",
			"graphicType": "Sélectionner type de graphique"
		},
		"safety": {
			"severity": "Niveau de danger",
			"symbols": "Symbole",
			"type": "Type de danger"
		},
		"safetyPoolBlade": {
			"title": "Insérer avertissement"
		},
		"screens": {
			"information": "Informations"
		},
		"search": {
			"blade": {
				"searchFacetsBlade": {
					"title": "Filtre de facettes"
				},
				"searchOptionsBlade": {
					"title": "Options de la recherche"
				},
				"searchResultBlade": {
					"title": "Résultat de la recherche",
					"titleWithSearchTerm": "Résultat de la recherche pour \"{{searchTerm}}\""
				}
			},
			"component": {
				"quickSearchBox": "Que recherchez-vous ?",
				"searchFacetsList": {
					"facetItemLabels": {
						"from": "De",
						"lastWeek": "Derniers 7 jours",
						"me": "Me",
						"other": "Autres",
						"today": "Aujourd’hui",
						"until": "Jusqu’à",
						"yesterday": "Hier"
					},
					"facetLabels": {
						"nodeClass_facet": "Classe de nœud",
						"node_md_facet": "Dernière édition de",
						"node_mdd_facet": "Dernière édition le"
					}
				},
				"searchOptionsForm": {
					"searchKey": {
						"filetype": "Type de fichier",
						"imgContent": "Contenus image"
					},
					"searchKeys": "Limiter les données à parcourir",
					"searchLanguages": "Limiter les langues à parcourir",
					"searchVersion": {
						"all": "Toutes les versions",
						"approved": "Dernière version avec aspect rédactionnel validé",
						"latest": "Dernière version",
						"latestApproved": "Dernière et dernière version avec aspect rédactionnel validé"
					},
					"searchVersions": "Limiter les versions à parcourir",
					"select": {
						"other": "Autres"
					}
				},
				"searchOptionsSwitch": "Options de la recherche"
			}
		},
		"sort": {
			"alphanumeric": "Alphabétique",
			"default": "Catégorisé",
			"descendingalphanumeric": "Ordre alphabétique décroissant",
			"properties": {
				"default": "Standard"
			}
		},
		"structure": {
			"header": "Structurer",
			"source": {
				"title": "Source"
			},
			"target": {
				"title": "Cible"
			}
		},
		"tasks": {
			"activeTasks": "Tracks actifs",
			"closeTask": "Supprimer track"
		}
	},
	"message": {
		"auth": {
			"loggedOut": "Vous vous êtes déconnecté."
		},
		"confirm": {
			"cancel": "Souhaitez-vous vraiment annuler ?",
			"delete": "Vraiment supprimer ?"
		},
		"error": {
			"addComment": "Erreur lors de l’enregistrement du commentaire.",
			"auth": {
				"generic": "Une erreur est survenue lors de la connexion. Veuillez vous adresser à votre administrateur.",
				"licenseLimit": "Limite de licences atteinte",
				"wrongPassword": "Nom d’utilisateur ou mot de passe non valide"
			},
			"changeCommentState": "Erreur lors de la modification de l’état.",
			"commentLegacy": {
				"errorInlineNotCommentable": "Impossible de commenter des sélections chevauchant des éléments.",
				"errorInlineOverlap": "Le texte sélectionné chevauche avec un commentaire en ligne existant !",
				"errorRights": "Impossible de créer le commentaire. Vous ne disposez pas de droits pour modifier des données."
			},
			"generic": "Une erreur est survenue !",
			"loadingindirectreuses": "Une erreur est survenue lors du chargement des réutilisations indirectes.",
			"noPreview": "Aperçu non disponible",
			"nodeRendering": "Erreur lors du rendu du nœud !",
			"removeComment": "Erreur lors de la suppression du commentaire.",
			"workflow": {
				"commandNotImplemented": "Action {{action}} non disponible en cette application."
			},
			"inputNotDecimal": "L’entrée n'est pas une virgule flottante valide.",
			"inputNotDouble": "L’entrée n'est pas une virgule flottante valide.",
			"inputNotInt": "L’entrée n’est pas un nombre entier.",
			"noLink": "Lien avec étiquette {{linkId}} introuvable.",
			"nodeLocked": "Cet objet est actuellement édité par \"{{lockedBy}}\". Veuillez réessayer plus tard.",
			"ontologyLinksChanged": "Une métadonnée du contenu a été modifiée entre-temps. Chargez la page de nouveau et réessayez.",
			"permissionDenied": "Vous n’avez pas assez de droits pour effectuer cette action."
		},
		"general": {
			"andMore": "et d’autres",
			"andMore_neutral": "et {{count}} autre",
			"andMore_neutral_one": "et {{count}} autre",
			"andMore_neutral_other": "et {{count}} autres",
			"loadingindirectreuses": "Utilisations indirectes chargées..."
		},
		"info": {
			"changeLanguageRequiresLogin": "Modifier la langue de l’interface utilisateur nécessite que vous vous connectez de nouveau.",
			"olddata": "Les données affichées peuvent être obsolètes."
		},
		"preview": {
			"comments": {
				"confirmInputAbort": "Souhaitez-vous annuler la saisie du commentaire ?\r\nSi vous cliquez \"Oui\", votre commentaire entré sera perdu.",
				"emptyCollection": "Aucun commentaire disponible."
			},
			"emphasisNotFound": "Style \"{{type}} n’est pas défini.",
			"metaref": {
				"dynamicReferenceNotFound": "Référence contextuelle à la métadonnée \"{{metadataName}}\" introuvable sur le nœud {{node}}.",
				"ontologyNotSelected": "Aucune taxonomie du type {{taxonomyName}} a été assignée au nœud {{node}}."
			},
			"previewLimit": "Le nombre maximum de nœuds a été atteint",
			"resourceNode": {
				"noContent": "Il manque du contenu ici."
			}
		},
		"validation": {
			"emptyRow": "Lignes vides non autorisées !",
			"fileType": "Ce type de fichier n’est pas autorisé. Autorisé : {0}",
			"filesizeToLarge": "Le fichier est trop grand. Taille de fichier maximale : {0} Mo",
			"format": "Format d’entrée erroné",
			"maxCount": "Nombre maximal : {0}, nombre actuel : {1}",
			"maxDate": "Dernière date possible : {0} (UTC)",
			"maxLength": "Longueur de texte maximale : {0}, longueur de texte actuelle : {1}",
			"maxValue": "Valeur maximale : {0}",
			"minCount": "Nombre minimal : {0}, nombre actuel : {1}",
			"minDate": "Première date possible : {0} (UTC)",
			"minLength": "Longueur de texte minimale : {0}, longueur de texte actuelle : {1}",
			"minValue": "Valeur minimale : {0}",
			"passwordConfirmed": "La confirmation ne correspond pas au mot de passe !",
			"required": "Champ obligatoire",
			"xml": "XML non valide",
			"auth": {
				"missingPassword": "Entrez votre mot de passe",
				"missingUsername": "Entrez votre nom d’utilisateur"
			},
			"dateFormat": "Représentation de date non valide. Utilisez la représentation AAAA-MM-JJ.",
			"numberDouble": "{{value}} n'est pas une virgule flottante valide. Utilisez un séparateur décimal."
		},
		"workflow": {
			"askForSignature": "Entrer le nom d’utilisateur et le mot de passe pour signer la validation",
			"changeGeneric": "Modifications au Workflow",
			"changedField": "Modification au champ \"{{fieldName}}\"",
			"changedField_one": "Modification au champ \"{{fieldName}}\"",
			"changedField_other": "Modification aux champs [{{count}}]",
			"dataOutdated": "Cette tâche de Workflow a été modifiée entre-temps. Impossible d’exécuter la transition.",
			"historyModifiedBy": "{{user}} a effectué des modifications au Workflow.",
			"instanceChangedNow": "La tâche vient d’être éditée par {{lastModifiedUser}}. Vos modifications seront perdues.",
			"instanceChangedOrInvisible": "L’état de la tâche a été modifié et elle n’est plus visible.",
			"invalidField_other": "Impossible de poursuivre en raison de valeurs de champ non valides",
			"noTasks": "Pas de tâches en cours",
			"transactionCancel": "Souhaitez-vous vraiment annuler cette transition ? Les données de formulaire entrées ne seront pas enregistrées.",
			"urlOpenedInWindow": "Le lien vers \"{{url}}\" a été ouvert dans une nouvelle fenêtre."
		},
		"alert": {
			"noContent": "Aucun contenu à afficher !"
		},
		"ap": {
			"error": {
				"userClaims": {
					"noPermissions": "Vous ne disposez pas de droits suffisants pour voir les claims d’utilisateur !"
				},
				"userDetails": {
					"noUserGiven": "Aucun utilisateur passé"
				},
				"userOverview": {
					"noDisabledUsersSelected": "Aucun des utilisateurs sélectionnés n’est désactivé !",
					"noEnabledUsersSelected": "Aucun des utilisateurs sélectionnés n’est activé !"
				}
			},
			"hasUnsavedChanges": {
				"body": "Vos dernières modifications ne sont pas enregistrées et seront perdues.",
				"question": "Êtes-vous sûr de vouloir annuler l’édition des profils d’utilisateur ?",
				"title": "Modifications non enregistrées"
			},
			"info": {
				"userClaims": {
					"noUserClaimsFound": "Aucun claim de l’utilisateur {{userName}} n’a été trouvé !"
				}
			},
			"success": {
				"groupCreated": "Groupe d’utilisateurs créé avec succès",
				"groupDeleted": "Groupe d’utilisateurs supprimé avec succès",
				"groupUndeleted": "Suppression annulée avec succès",
				"multiUsersDeleted": "Utilisateur supprimé avec succès",
				"multiUsersDisabled": "Utilisateur désactivé avec succès",
				"multiUsersEnabled": "Utilisateur activé avec succès",
				"multiUsersUndeleted": "Suppressions annulées avec succès",
				"passwordChanged": "Mot de passe modifié avec succès",
				"setAbsent": "Absence enregistrée avec succès",
				"setPresent": "Présence enregistrée avec succès",
				"singleUserDeleted": "Utilisateur supprimé avec succès",
				"singleUserDisabled": "Utilisateur désactivé avec succès",
				"singleUserEnabled": "Utilisateur activé avec succès",
				"singleUserUndeleted": "Suppression annulée avec succès",
				"userCreated": "Nouvel utilisateur créé avec succès",
				"userUpdated": "Modifications à l’utilisateur enregistrées avec succès",
				"usersAddedToGroups": "Utilisateur ajouté à groupes avec succès",
				"usersRemovedFromGroups": "Utilisateur supprimé de groupes avec succès"
			},
			"validationError": {
				"GroupNameRequired": "\"Nom de groupe\" est un champ obligatoire",
				"NoGroupSelected": "Aucun groupe sélectionné"
			}
		},
		"chooseProject": {
			"projectFolderSelected": "Veuillez sélectionner un projet, pas un dossier."
		},
		"createNewNode": {
			"class": {
				"docuManagerOnly": "Un contenu de ce type ne peut être crée que dans ST4 DocuManager",
				"permissionDenied": "Vous n’avez pas le droit de créer un contenu de ce type ici"
			}
		},
		"createNewNodeForm": {
			"configId": {
				"error": "Aucune sélection disponible !"
			}
		},
		"createNewNodeVisualizer": {
			"error": {
				"commandNotImplemented": "Non implémenté"
			},
			"question": {
				"switchContext": "Si vous changez le lieu cible pour le nouveau contenu, vos modifications précédentes seront perdues.\r\n    Souhaitez-vous quand-même changer ?"
			}
		},
		"dashboard": {
			"myAssignedTasks": {
				"error": {
					"loading": "Erreur lors du chargement des tracks attribués à vous."
				},
				"info": {
					"noTasks": "Actuellement vous n’avez pas de tracks actifs."
				}
			},
			"welcome": {
				"title": "Bienvenue"
			}
		},
		"editor": {
			"canEditCheck": {
				"error": {
					"AspectNotEditingLanguage": "Vous ne pouvez pas éditer le contenu dans la langue actuelle. Le contenu a été créé dans une autre langue. \r\nPassez au SCHEMA ST4 Power ou Author Client pour pouvoir éditer les contenus sans workflow de traduction.",
					"ContentLinked": "Le contenu est réutilisé à cet endroit. Vous ne pouvez donc pas éditer le contenu directement.\r\nPassez au SCHEMA ST4 Power ou Author Client pour éditer ce contenu.",
					"InternalError": "Malheureusement, cela n’a pas marché. Réessayez plus tard.",
					"NodeDeleted": "Vous avez essayé d’ouvrir un contenu. Mais ce contenu a déjà été supprimé définitivement.\r\nFermez le message d’erreur et l’éditeur, si besoin.",
					"NodeLocked": "Ce contenu est actuellement édité par „{{0}}“. Réessayez plus tard.",
					"NodeReleasedCurrentAspect": "Ce contenu est validé dans la langue actuelle. \r\nPassez au SCHEMA ST4 Power ou Author Client pour créer une nouvelle version. Vous pouvez ensuite éditer ce contenu.",
					"NodeReleasedOtherAspect": "Ce contenu est validé dans une autre langue. \r\nPassez au SCHEMA ST4 Power ou Author Client pour créer une nouvelle version. Vous pouvez ensuite éditer ce contenu.",
					"NodeReused": "Le contenu est réutilisé à cet endroit. Vous ne pouvez donc pas éditer le contenu directement. \r\nPassez au SCHEMA ST4 Power ou Author Client pour éditer ce contenu.",
					"PermissionDenied": "Vous ne pouvez pas éditer ce contenu. Vous n’avez pas les droits nécessaires."
				},
				"warning": {
					"NodeInTranslation": "Ce contenu est en cours de traduction.\r\nSi vous modifiez le contenu, vous devez le traduire de nouveau."
				}
			}
		},
		"fragmentPoolBlade": {
			"empty_group": "Vous avez sélectionné un nœud groupe.\r\nComme aucun contenu n’est affecté au nœud groupe, vous ne pouvez pas l’insérer.",
			"no_fragment": "Sélectionnez un fragment.",
			"not_possible_fragment": "Vous ne pouvez pas insérer le fragment sélectionné à cet endroit.\r\nLa structure du contenu et le fragment ne sont pas compatibles.",
			"not_possible_structure_fragment": "Vous ne pouvez pas insérer le fragment de structure sélectionné à cet endroit. \r\nLa structure du contenu et le type du fragment de structure ne sont pas compatibles.",
			"recursion": "Insérer ce fragment créerait une boucle sans fin.",
			"rights": "Il vous manque les droits pour insérer le fragment sélectionné."
		},
		"properties": {
			"valueInherited": "Valeur héritée du nœud parent."
		},
		"resourcePoolBlade": {
			"folder": "Sélectionnez une ressource, pas un dossier.",
			"noslot": "La ressource sélectionnée ne contient pas de graphique.",
			"not_possible": "Vous ne pouvez pas insérer de graphique à cet endroit.\r\nÀ la place, placez le curseur dans un paragraphe.",
			"rights": "Il vous manque les droits pour insérer la ressource sélectionnée.",
			"thumbnail": "Il n’y a pas de vignette pour la ressource sélectionnée."
		},
		"safetyPool": {
			"not_possible": "Vous ne pouvez pas insérer d’avertissement à cet endroit. \r\nLa structure du contenu et l’avertissement ne sont pas compatibles."
		},
		"search": {
			"error": {
				"fromException": "Erreur lors de la recherche dans ST4 : \"{{errorName}}\" - \"{{errorMessage}}\""
			},
			"info": {
				"searchingForTerm": "Recherche \"{{searchTerm}}\" dans ST4 ..."
			},
			"warning": {
				"noSearchFacets": "La recherche de \"{{searchTerm}}\" dans ST4 n’a trouvé aucun filtre de facettes !",
				"noSearchResult": "La recherche de \"{{searchTerm}}\" dans ST4 n’a trouvé aucun résultat !"
			}
		},
		"tasks": {
			"confirmClose": "Souhaitez-vous vraiment supprimer ce track ?"
		},
		"tree": {
			"dnd": {
				"tryAgain": "Malheureusement, quelque chose n’a pas marché. Veuillez réessayer."
			}
		},
		"warning": {
			"editorSave": {
				"floatingLinkExists": {
					"_": "Il existe déjà une référence dynamique à la version actuelle de la ressource {{0}}. Vous ne pouvez donc pas créer une référence fixe à la version sélectionnée de la ressource. À la place, SCHEMA ST4 référence la version actuelle de la ressource de nouveau."
				},
				"frozenAndFloatingLink": {
					"_": "Vous essayez de créer une référence fixe ainsi qu’une référence dynamique à la ressource {{0}}. Ceci n’est pas possible. À la place, une référence à une version fixe est créée."
				},
				"frozenLinkExists": {
					"_": "Une version fixe de la ressource {{0}} est déjà référencée. Vous ne pouvez donc pas créer une référence à la version actuelle. À la place, SCHEMA ST4 référence de nouveau la version fixe."
				},
				"invalidLinkRemovalForTextModuleCausedInvalidXML": "Le renvoi au fragment avec l’ID {{0}} n’a pas pu être résolu. Impossible de supprimer automatiquement la réutilisation.\r\n    Veuillez supprimer le fragment avant de fermer le contenu.",
				"linkForResourceCouldNotBeCreated": "Le renvoi à la ressource avec l’ID {{0}} n’a pas pu être résolu. Donc la référence est supprimée quand vous fermez le contenu.",
				"linkForTextModuleCouldNotBeCreated": "Le renvoi au fragment avec l’ID {{0}} n’a pas pu être résolu. Donc la réutilisation est supprimée quand vous fermez le contenu."
			}
		}
	}
}