import { useCallback } from "react"
import { useTaxonomyTreeQuery, useUpdateTaxonomyMutation } from "./query.hooks"
import { isTypename } from "@st4/graphql"
import { buildTree } from "./buildTree"
import type { TaxTreeNode } from "./types"

export function useTaxonomyEditor(
  taxonomyRootIds: string[],
  languageId: string,
  selectLeavesOnly: boolean,
  currentNodeId: string,
  refetchProperties: () => void,
  refetchOnSave?: string[],
  linkClassId?: string,
) {
  const {
    taxTree,
    loading: taxTreeLoading,
    filterCallback,
    error: taxTreeError,
    refreshTaxTree,
  } = useTaxonomyTree(taxonomyRootIds, languageId, selectLeavesOnly, currentNodeId)

  const [update, { loading: updateLoading, error: updateError, data: updateData }] = useUpdateTaxonomy(
    currentNodeId,
    languageId,
    refetchProperties,
    refetchOnSave,
    linkClassId,
  )

  return {
    taxTree,
    taxTreeLoading,
    filterCallback,
    taxTreeError,
    refreshTaxTree,
    update,
    updateLoading,
    updateError,
    updateData,
  }
}

function useTaxonomyTree(
  taxonomyRootIds: string[],
  languageId: string,
  selectLeavesOnly: boolean,
  currentNodeId?: string,
) {
  // TODO: The following replacement of strings should be removed. Currently the tree field on the graphql
  //       schema doesn't support StObjects as selected nodes.
  const taxRoots = taxonomyRootIds.map((r) => r.replace("x-stobject:", "x-pattern-context:/"))
  const requestVariables = {
    taxonomyTreeRequest: {
      taxonomyRoots: taxRoots,
      languageId,
      nodesToCheckForLock: [currentNodeId],
    },
  }
  const { data, loading, refetch } = useTaxonomyTreeQuery({
    variables: requestVariables,
  })

  function refreshTaxTree() {
    refetch(requestVariables)
  }

  const tree = isTypename("TaxonomyTreeInfoSuccess")(data?.taxonomyTree) ? data?.taxonomyTree.result : null
  const st4Nodes = tree?.nodes.filter(isSt4TreeNode) as undefined | TaxTreeNode[] //somehow the Typeguard Filter doesn't work.
  const taxTree = st4Nodes && buildTree(taxRoots, st4Nodes, selectLeavesOnly)

  const filterCallback = useCallback(
    (inputValue: string, node?: { value?: number | string }) => {
      if (!st4Nodes) return false
      const id = node?.value
      if (!id) return false
      const st4node = st4Nodes.find((n) => n.node?.id === id)
      if (!st4node) return false
      return !!st4node.node?.label?.toLowerCase().includes(inputValue.toLowerCase())
    },
    [st4Nodes],
  )

  const error = isTypename("TaxonomyTreeInfoError")(data?.taxonomyTree) ? data?.taxonomyTree : null

  return { taxTree, loading, filterCallback, error, refreshTaxTree }
}

function useUpdateTaxonomy(
  nodeId: string,
  languageId: string,
  refetchProperties: () => void,
  refetchOnSave?: string[],
  linkClassId?: string,
) {
  const [mutate, mutationResult] = useUpdateTaxonomyMutation()
  const updateTax = (checkedNodeIds: string[], previousSelection: string[]) =>
    mutate({
      variables: {
        input: {
          idOfLinkClassToUse: linkClassId,
          previousSelectionIds: previousSelection,
          sourceNodeIds: [nodeId],
          checkedNodeIds,
        },
        languageId,
      },
      refetchQueries: refetchOnSave,
      onCompleted: (_) => {
        refetchProperties()
      },
    })

  return [updateTax, mutationResult] as [typeof updateTax, typeof mutationResult]
}

function isSt4TreeNode(node: { __typename: string }): node is TaxTreeNode {
  return isTypename("ST4TreeNode")(node)
}
