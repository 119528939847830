import React, { useLayoutEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { keys } from "@st4/ui-strings"
import { UnstlyedPositionableModal } from "@schema/styled-ui"
import styled from "styled-components"
import { useResizeObserver } from "./resizeObserverHook"
import { AddCommentInput } from "./AddCommentInput"
import { CompleteTextSelection, ProgressState } from "../../selection/types"
import { useSelectionContext } from "../../selection"
import { usePreviewContentModel } from "../../contentModel"
import { useCommentFactory } from "../commentActions"
import { Alert } from "antd"

const Modal = styled.div`
  border: 1px solid ${({ theme }) => theme.greys[200]};
  padding: 0;
  margin: 0;
  min-width: 450px;
  max-width: 66ch;
  word-break: break-word;
  background: white;
  border-radius: 14px;
  box-shadow: ${({ theme }) => theme.shadows["+2"]};
  transform: translateX(-50%);
`

function useEditorPopupSizing(
  popup: React.RefObject<HTMLElement>,
  anchorPoints: DOMRectReadOnly[],
  textSelection: CompleteTextSelection,
): { x: number; y: number; maxHeight: number } {
  const popupDimensions = useResizeObserver(popup.current)
  const popupPadding = 15 //some space between the viewport borders and the popup

  // Anchor Points are only updated when textSelection changes (where this method is called) but BoundingRect changes
  // on every scroll. This causes the filtering below to fail. So we only update the selection coords when anchors change
  const selectionTop = useMemo(
    () => textSelection.start.marker.current?.getBoundingClientRect().top || 0,
    [textSelection.start.marker.current],
  )
  const selectionBottom = useMemo(
    () => textSelection.end.marker.current?.getBoundingClientRect().bottom || 0,
    [textSelection.end.marker.current],
  )

  // Only consider anchors within the actual selection the mouse dragging created, anchor Points could be outside
  // when node is used multiple times
  let filteredAnchorPoints = anchorPoints
  if (anchorPoints.length > 2) {
    filteredAnchorPoints = anchorPoints.filter(
      (anchor) => anchor.top >= selectionTop && anchor.bottom <= selectionBottom,
    )
  }

  const minTop = Math.min(...filteredAnchorPoints.map((ap) => ap.top))
  const maxBottom = Math.max(...filteredAnchorPoints.map((ap) => ap.bottom))
  const spaceAbove = minTop
  const spaceBelow = window.document.body.clientHeight - maxBottom

  const anchorValues = filteredAnchorPoints.map((ap) => ap.left).reduce((p, c) => p + c, 0)
  const centerOfAnchors = anchorValues / filteredAnchorPoints.length
  const lowerHorizontalClamp = popupDimensions.width / 2 + popupPadding
  const upperHorizontalClamp = window.document.body.clientWidth - popupPadding - popupDimensions.width / 2
  const xPos = Math.max(Math.min(centerOfAnchors, upperHorizontalClamp), lowerHorizontalClamp)
  if (spaceAbove > spaceBelow - popupPadding && popupDimensions.height >= spaceBelow - popupPadding) {
    return {
      x: xPos,
      y: minTop - popupDimensions.height,
      maxHeight: spaceAbove - popupPadding,
    }
  }

  return {
    x: xPos,
    y: maxBottom,
    maxHeight: spaceBelow - popupPadding,
  }
}

type CommentEditorProps = Record<string, unknown>

function CommentEditor({ children }: React.PropsWithChildren<CommentEditorProps>) {
  const { currentTextSelection, dispatchUnselect } = useSelectionContext()
  return (
    <>
      {children}
      {currentTextSelection.progress === ProgressState.Complete && (
        <PositioningEditor currentTextSelection={currentTextSelection} unselect={dispatchUnselect} />
      )}
    </>
  )
}

function PositioningEditor({
  currentTextSelection,
  unselect,
}: {
  currentTextSelection: CompleteTextSelection
  unselect: () => void
}) {
  const { t } = useTranslation()
  const [currentText, setCurrentText] = useState("")
  const popupRef = useRef<HTMLDivElement>(null)

  // I've tried for about 6 hours and could'n get a ref passed to sliceTextAtSplitPoints, which would be available as the currentTextSelection.end.marker 💩
  // const startMarker = currentTextSelection.start.marker
  // const endMarker = currentTextSelection.start.marker

  const [anchorPoints, setAnchorPoints] = useState<DOMRect[]>([])
  useLayoutEffect(() => {
    const markers = window.document.querySelectorAll(".selectionStartMarker,.selectionEndMarker").values()
    let ap = Array.from(markers, (value) => value.getBoundingClientRect() /* whatever */)
    /* If no synthetic selectionMarker elements are found - use the recorded boundingRects in currentSelection */
    if (ap.length === 0) {
      const br = currentTextSelection.start.boundingRect || currentTextSelection.end.boundingRect
      ap = br ? [br] : []
    }
    setAnchorPoints(ap)
  }, [currentTextSelection])
  const popupPosition = useEditorPopupSizing(popupRef, anchorPoints, currentTextSelection)
  const previewContentModel = usePreviewContentModel()
  const { createComment } = useCommentFactory()

  if (previewContentModel.state !== "ready") return null
  const { username, displayname, commentColor } = previewContentModel.user

  const onClose = () => {
    unselect()
  }

  const confirmText = t(keys.message.preview.comments.confirmInputAbort)
  return (
    <UnstlyedPositionableModal
      positionX={popupPosition.x + "px"}
      positionY={popupPosition.y + "px"}
      confirmMessage={currentText.length > 0 ? confirmText : undefined}
      confirmButtons={{ ok: t(keys.button.general.yes), cancel: t(keys.button.general.no) }}
      onClose={onClose}
    >
      <Modal ref={popupRef}>
        {!currentTextSelection.selectionAllowed ? (
          <Alert banner message={t(keys.message.error.commentLegacy.errorInlineNotCommentable)} />
        ) : (
          <AddCommentInput.WithTypes
            maxHeight={popupPosition.maxHeight}
            onTextChange={(text) => {
              setCurrentText(text)
            }}
            onSave={(text, type) => {
              const comment: Parameters<typeof createComment>[0] = {
                commentKey: `${username}_99`, //placeholder... TODO: CorrectValue?,
                persistanceState: "LOCAL" as const,
                node: currentTextSelection.start.contentNode,
                value: text,
                startLocation: currentTextSelection.start.offset,
                endLocation: currentTextSelection.end.offset,
                color: commentColor,
                commentType: type || "",
                state: "",
                authorDisplayName: displayname,
                authorUserName: username,
                __typename: "TextComment",
                actions: [],
              }
              createComment(comment)
              //unselect immediately, don't wait for promise-resolution
              unselect()
            }}
          />
        )}
      </Modal>
    </UnstlyedPositionableModal>
  )
}

export { CommentEditor }
