import * as React from "react"
import { SVGProps } from "react"

const SvgSt4SubheadingRegular = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="1em" height="1em" {...props}>
    <path d="M409 248H39c-21.5 0-39-17.5-39-39v-18c0-21.5 17.5-39 39-39h370c21.5 0 39 17.5 39 39v18c0 21.5-17.5 39-39 39zm15-160H24C10.7 88 0 77.3 0 64s10.7-24 24-24h400c13.3 0 24 10.7 24 24s-10.7 24-24 24zM296 360H24c-13.3 0-24-10.7-24-24s10.7-24 24-24h272c13.3 0 24 10.7 24 24s-10.7 24-24 24zm128 112H24c-13.3 0-24-10.7-24-24s10.7-24 24-24h400c13.3 0 24 10.7 24 24s-10.7 24-24 24z" />
  </svg>
)

export default SvgSt4SubheadingRegular
