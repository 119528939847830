import { Box, BoxProps } from "./structure"
import { Typography } from "antd"
import React from "react"

export function Heading({ level, children, ...boxProps }: { level?: 1 | 2; children: React.ReactNode } & BoxProps) {
  return (
    <Typography.Title level={level}>
      <Box {...boxProps}>{children}</Box>
    </Typography.Title>
  )
}
