import React from "react"

import { Descriptions as AntDescriptions } from "antd"
import type { DescriptionsProps as AntDescriptionsProps } from "antd"
import type { ReactNode } from "react"
import type { CSSProperties } from "react"

type DataSourceItem = {
  /**
   * The label of the description.
   */
  label: ReactNode
  /**
   * The content of the description.
   */
  content: ReactNode
  /**
   * A key for the item inside the enumeration.
   * (optional) If ommitted the string representation of `label` is used.
   */
  key?: string
}

type DescriptionsProps = Omit<AntDescriptionsProps, "children"> & {
  /**
   * An array of items to display in the description component.
   */
  dataSource: DataSourceItem[]
  /** Styles to apply to every item. */
  itemStyle?: CSSProperties
}

/**
 * Expands the [AntD Descriptions](https://ant.design/components/descriptions) to
 * accept an data array instead of children.
 * @param props
 */
export function Descriptions(props: DescriptionsProps) {
  const { dataSource, ...antProps } = props
  return (
    <AntDescriptions {...antProps}>
      {dataSource.map((data) => (
        <AntDescriptions.Item label={data.label} key={data.key ?? data.label?.toString()} style={props.itemStyle}>
          {data.content}
        </AntDescriptions.Item>
      ))}
    </AntDescriptions>
  )
}
