import React, { useState } from "react"
import { CoreNavigationTreeViewletProps, CreateButtonProps, MeatballMenuProps } from "./types"
import { NavigationTreeViewletWithQuery } from "./navigationtreeviewletwithquery"
import { St4TreeNodeFragment } from "./query.hooks"

type NavigationTreeViewletWithStateProps = Omit<CoreNavigationTreeViewletProps, "onSelect"> &
  MeatballMenuProps &
  CreateButtonProps & {
    /** as long as the selected node exists in the tree the path to it will automatically be added to the expanded nodes
     * this property should instead contain all nodes that you want to be initially expanded in addition to the path to the selected node (if any)
     **/
    initialExpandedNodes?: string[]
    onSelect: (node: Pick<St4TreeNodeFragment, "id" | "node"> | null) => void
  }

export const NavigationTreeViewletWithState = (props: NavigationTreeViewletWithStateProps) => {
  const [expandedNodes, setExpandedNodes] = useState<string[]>(
    props.initialExpandedNodes ? props.initialExpandedNodes : [],
  )

  function handleOnExpandChange(expandedNodeId: string, isExpanded: boolean) {
    if (isExpanded) {
      setExpandedNodes([...expandedNodes, expandedNodeId])
    } else {
      const filteredNodes = Array.from(expandedNodes.filter((t) => t !== expandedNodeId))
      setExpandedNodes(filteredNodes)

      if (props.selected && props.onSelect && props.selected.startsWith(expandedNodeId)) props.onSelect(null)
    }
  }

  return (
    <NavigationTreeViewletWithQuery
      ignoreIcons={false}
      {...props}
      onSelect={props.onSelect}
      expandedNodes={expandedNodes}
      onExpandChange={handleOnExpandChange}
      onAllExpandedNodesChanged={setExpandedNodes}
      includeNodeClassHierarchy={props.includeNodeClassHierarchy ?? false}
    />
  )
}
