import * as React from "react"
import { SVGProps } from "react"

const SvgSt4StructureFragmentGroupRegular = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1em" height="1em" {...props}>
    <path d="M380.9 45.8 267.6 2.2c-8.7-2.9-14.6-2.9-23.3 0L131.1 45.8c-11.6 2.9-20.3 14.6-20.3 29v139.4c0 11.6 8.7 23.3 20.3 29l113.2 43.6c8.7 2.9 14.6 2.9 23.3 0l113.2-43.6c11.6-2.9 20.3-14.6 20.3-29V74.9c.1-11.6-8.6-23.3-20.2-29.1zM229.7 242.2l-75.8-28.1c-2.6-2.6-2.6-2.6-2.6-5.1l.3-99.7 78.1 31.3v101.6zm26.1-139.6-84-33.2 81.8-31h2.2l83.9 31zm101.9 111.5-75.7 28V140.7l78-31.2.3 99.5c0 2.5 0 2.5-2.6 5.1zM250.1 349.6c1 .8 3 1.2 5.9 1.2 2 0 4-.4 5.9-1.2L452 268.1c-4.6-6.9-5.5-16.1-1.5-24 6-11.9 20.4-16.7 32.2-10.8l9.1 4.6c12.4 6.2 20.2 18.8 20.2 32.6 0 14.7-8.7 27.9-22.1 33.6l-209.1 89.6c-8.7 3.4-16.3 5.1-24.8 5.1s-17-1.7-24.8-5.1L22.8 304.5C9 298.6 0 285 0 269.9c0-12.1 5.9-23.5 15.8-30.6l10.3-7.4c10.8-7.7 25.8-5.2 33.5 5.6 6.6 9.3 5.7 21.7-2.4 29.9l192.9 82.2zm-190.6 1c6.6 9.3 5.7 21.7-2.4 29.9l193 82.3c1 .8 3 1.2 5.9 1.2 2 0 4-.4 5.9-1.2L452 381.3c-4.6-6.9-5.5-16.1-1.5-24 6-11.9 20.4-16.7 32.2-10.8l9.1 4.6c12.4 6.2 20.2 18.8 20.2 32.6 0 14.7-8.7 27.9-22.1 33.6l-209.1 89.6c-8.7 3.4-16.3 5.1-24.8 5.1s-17-1.7-24.8-5.1L22.8 417.6C9 411.7 0 398.1 0 383c0-12.1 5.9-23.5 15.8-30.6l10.3-7.4c10.7-7.8 25.7-5.3 33.4 5.6z" />
  </svg>
)

export default SvgSt4StructureFragmentGroupRegular
