import { useState, useEffect, useRef, useCallback } from "react"
import { CreateNewNodeJobResponse } from "../../../query.hooks.types"
import {
  useStartCreateNewNodeJobInteractive,
  useStartCheckRequiredMetadataOnNodeJobInteractive,
  useContinueCheckRequiredMetadataOnNodeJobInteractive,
  isAskRequiredMetadataDialogCommandJobResponse,
  isFinishedJobResponse,
  isCanceledJobResponse,
  isFailedJobResponse,
} from "../../../query.hooks.utils"
import type { ActionHandlerInfo } from "../../types"
import type { StepSaveBaseDataInputValues } from "../types"

type ProcessStepInfo = {
  contextNodeId?: string
  response?: CreateNewNodeJobResponse | null
  stepSaveBaseDataInputValues?: StepSaveBaseDataInputValues
  closeSilent?: boolean
  closeHandler?: ((notify: boolean) => void) | null
}

export function useCreateNodeProcess(props: {
  contextNodeId: string
  onActionsChanged: (info: ActionHandlerInfo) => void
  onJobStarted?: (jobId: string) => void
  onJobCompleted?: (jobId: string) => void
}) {
  const { contextNodeId, onActionsChanged, onJobStarted, onJobCompleted } = props

  const [activeResponse, setActiveResponse] = useState<CreateNewNodeJobResponse | null>(null)
  const [shownContextSwitchApplyDialog, setShownContextSwitchApplyDialog] = useState<boolean>(false)
  const activeProcessStepInfo = useRef<ProcessStepInfo>({
    response: activeResponse,
  })

  const handleStepSaveBaseDataInputValuesChanged = useCallback((inputValues: StepSaveBaseDataInputValues) => {
    activeProcessStepInfo.current.stepSaveBaseDataInputValues = inputValues
  }, [])

  const handleActionsChanged = useCallback(
    (info: ActionHandlerInfo) => {
      activeProcessStepInfo.current.closeHandler = info.onClose
      activeProcessStepInfo.current.closeSilent = info.closeSilent
      onActionsChanged(info)
    },
    [onActionsChanged],
  )

  const handleContextSwitchApplyDialogCancel = useCallback(() => {
    setShownContextSwitchApplyDialog(false)
  }, [setShownContextSwitchApplyDialog])

  // Handle all create new node completion steps (show dialog, finished, canceled).
  const handleCreateNewNodeMutationCompleted = useCallback(
    (jobResponse: CreateNewNodeJobResponse) => {
      // If active response is undefined and the completed mutation returned a jobId notify job started
      if (!activeProcessStepInfo.current.response) {
        onJobStarted?.call(undefined, jobResponse.jobId)
      }

      activeProcessStepInfo.current.response = jobResponse
      setActiveResponse(jobResponse)

      if (isFinishedJobResponse(jobResponse)) {
        switch (jobResponse.jobType) {
          case "CreateNewNodeJob":
            checkRequiredMetadata(jobResponse.nodeId)
            break
          default:
            handleJobCompleted(jobResponse)
            break
        }
      } else if (isAskRequiredMetadataDialogCommandJobResponse(jobResponse)) {
        finishAskRequiredMetadataInit(jobResponse.jobId)
      } else if (isCanceledJobResponse(jobResponse)) {
        handleJobCompleted(jobResponse)
      }
    },
    [onJobStarted, setActiveResponse, onJobCompleted],
  )

  const createNewNode = useStartCreateNewNodeJobInteractive(handleCreateNewNodeMutationCompleted)
  const checkRequiredMetadata = useStartCheckRequiredMetadataOnNodeJobInteractive(handleCreateNewNodeMutationCompleted)
  const finishAskRequiredMetadataInit = useContinueCheckRequiredMetadataOnNodeJobInteractive()

  const handleContextSwitchApplyDialogApply = useCallback(() => {
    const closeHandler = activeProcessStepInfo.current.closeHandler
    activeProcessStepInfo.current.closeHandler = null
    closeHandler && closeHandler(false)
    setActiveResponse(null)
    setShownContextSwitchApplyDialog(false)
    startProcess(contextNodeId)
  }, [setActiveResponse, createNewNode, setShownContextSwitchApplyDialog, contextNodeId])

  function startProcess(nodeId: string) {
    // Start a new process and reset info to new context node
    activeProcessStepInfo.current = { contextNodeId: nodeId }
    createNewNode(nodeId, "InformationPool")
  }

  const handleJobCompleted = useCallback(
    (jobResponse: CreateNewNodeJobResponse) => {
      console.log("event job completed")
      activeProcessStepInfo.current = {}
      onJobCompleted && onJobCompleted(jobResponse.jobId)
    },
    [onJobCompleted],
  )

  // On change of the context node: Ask user if he wants to change
  useEffect(() => {
    if (!activeProcessStepInfo.current.contextNodeId || activeProcessStepInfo.current.contextNodeId == contextNodeId)
      return

    if (activeProcessStepInfo.current.closeSilent || isFailedJobResponse(activeProcessStepInfo.current.response)) {
      handleContextSwitchApplyDialogApply()
      return
    }

    setShownContextSwitchApplyDialog(true)
    handleActionsChanged({
      onClose: activeProcessStepInfo.current.closeHandler ?? undefined,
      onCancel: handleContextSwitchApplyDialogCancel,
      onFinish: handleContextSwitchApplyDialogApply,
    })
  }, [contextNodeId])

  // On initial load: Start process
  // On final unmounting of the component: Ensure current close handler is executed.
  useEffect(() => {
    startProcess(contextNodeId)

    return () => {
      activeProcessStepInfo.current.response = null
      if (activeProcessStepInfo.current.closeHandler) {
        const closeHandler = activeProcessStepInfo.current.closeHandler
        activeProcessStepInfo.current.closeHandler = null
        closeHandler(false)
      }
    }
  }, [])

  // On change of active response: Check if response is failed response and setup cancel button
  const handleFailedCancel = useCallback(
    () => activeResponse && handleJobCompleted(activeResponse),
    [activeResponse, handleJobCompleted],
  )
  useEffect(() => {
    if (activeResponse !== null && isFailedJobResponse(activeResponse))
      onActionsChanged({ onCancel: handleFailedCancel })
  }, [handleFailedCancel])

  return {
    activeResponse,
    shownContextSwitchApplyDialog,
    stepSaveBaseDataInputValues: activeProcessStepInfo.current.stepSaveBaseDataInputValues,
    handleStepSaveBaseDataInputValuesChanged,
    handleActionsChanged,
    handleCreateNewNodeMutationCompleted,
  }
}
