import { ContentBlade } from "~/blades/contentBlade"
import { BladeReference, defineScreen } from "@st4/tasks"
import { PropertiesBlade } from "~/blades/propertiesBlade"
import { ActionBlade, ActionInfo } from "~/blades/actionBlade"
import { TreeBlade } from "~/blades/treeBlade"

const refetchOnEditorSave = ["Properties", "NavigationTree", "getContent"]
const refetchOnPropertiesSave = ["NavigationTree"]
export const exploreScreen = defineScreen<
  [
    BladeReference<"navigationTree", typeof TreeBlade>,
    BladeReference<"content", typeof ContentBlade>,
    BladeReference<"properties", typeof PropertiesBlade>,
    BladeReference<"actions", typeof ActionBlade>,
  ],
  {
    nodeAddInfo: ActionInfo | null
  }
>({
  name: "explore",
  icon: "TaskExplore",
  blades: [
    {
      name: "navigationTree",
      component: "TreeBlade",
    },
    {
      name: "content",
      component: "ContentBlade",
      props: (contentBladeState, taskState) => ({
        ...contentBladeState,
        rootNodeId: taskState.states.navigationTree.selectedNode,
        readonly: false,
        refetchOnEditorSave,
      }),
    },
    {
      name: "properties",
      component: "PropertiesBlade",
      props: (propertiesBladeState, taskState) => ({
        ...propertiesBladeState,
        nodeId: taskState.states.content?.selection?.treeNodeId ?? taskState.states.navigationTree?.selectedNode,
      }),
    },
    {
      name: "actions",
      component: "ActionBlade",
      temporary: true,
      props: (_, taskState) => ({ actionInfo: taskState.states.screen?.nodeAddInfo ?? null }),
    },
  ],
  initialContextValues: {
    fixedBlade: "navigationTree",
    blades: [{ name: "content" }, { name: "properties" }],
    states: {
      navigationTree: {
        variant: "InformationPool",
        hideNodeAdd: false,
        expanded: [],
      },
      actions: { actionInfo: null },
      content: {
        editedNodes: [],
        readonly: false,
        selection: null,
      },
      properties: {
        refetchOnSave: refetchOnPropertiesSave,
      },
      screen: {
        nodeAddInfo: null,
      },
    },
  },
  reducer(state, message, helper) {
    switch (message.action) {
      case "navigationTree:onNodeSelected":
        return helper.modifyState((states) => {
          delete states.content.selection
        })
      case "navigationTree:onNodeRemoved":
        if (message.payload === state.states.navigationTree.selectedNode)
          return helper.modifyState((states) => {
            delete states.navigationTree.selectedNode
          })
        else return state
      case "actions:setInfo":
        return helper.hideBlade("actions").modifyState((states) => {
          const createNodeContext = state.states.screen.nodeAddInfo?.contextNode
          if (createNodeContext && !states.navigationTree.expanded.includes(createNodeContext.id)) {
            states.navigationTree.expanded.push(createNodeContext.id)
          }

          states.screen.nodeAddInfo = message.payload
        })
      case "navigationTree:onNodeAdd":
        return helper
          .showBlade("actions")
          .moveBlade("actions", "Start")
          .modifyState((states) => {
            states.screen.nodeAddInfo = {
              name: "create-new-node",
              contextNode: message.payload,
            }
          })
      default:
        return state
    }
  },
})
