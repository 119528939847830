import React, { useCallback } from "react"
import styled, { css } from "styled-components"
import { keys } from "@st4/ui-strings"
import { Empty } from "antd"
import { Annotation, AnnotationPropsWithReplies } from "./annotation"
import { useScrollToFocusedComment } from "../annotationFocusState"
import { St4TreeNode } from "@st4/graphql"
import { CommentNodeFragment } from "./query.hooks"
import { AnnotationDisplaySkeleton } from "./AnnotationDisplay"
import { Translate } from "@st4/i18n"

const faultyStyle = css`
  background-color: ${({ theme }) => theme.preview.annotations.errorColor["100"]};
  border: 1px solid ${({ theme }) => theme.preview.annotations.errorColor["400"]};
`

const focusedStyle = css`
  background-color: ${({ theme }) => theme.white};
  border: 1px solid ${({ theme }) => theme.preview.annotations.focusColor.border};
  box-shadow: ${({ theme }) => theme.shadows["+2"]};
`

const prefocusedStyle = css`
  background-color: ${({ theme }) => theme.white};
  border: 1px solid ${({ theme }) => theme.preview.annotations.preColor.border};
  box-shadow: ${({ theme }) => theme.shadows["+2"]};
  cursor: pointer;
`

const defaultStyle = css`
  background-color: ${({ theme }) => theme.greys["100"]};
  border: 1px solid ${({ theme }) => theme.greys["200"]};
`

export const AnnotationCard = styled.div<{
  focused?: boolean
  prefocused?: boolean
  faulty?: boolean
  pendingDeletion?: boolean
}>`
  ${({ focused, prefocused, faulty }) =>
    faulty ? faultyStyle : focused ? focusedStyle : prefocused ? prefocusedStyle : defaultStyle};
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  margin: 10px;
  width: calc(100% - 20px);
  position: relative;
  opacity: ${({ pendingDeletion }) => pendingDeletion && 0.5};
  transition: all ${({ theme }) => theme.preview.annotations.animationDuration} ease-out;
`

type HighlightedAnnotationProps = {
  treeNode: Pick<St4TreeNode, "id">
  commentNode: Required<CommentNodeFragment>
  replies: Required<CommentNodeFragment>[]
  toggleReplyExpansion: (commentKey: string) => void
  expandedReplies: Map<string, boolean>
}

export function HighlightedAnnotation(props: HighlightedAnnotationProps) {
  const { replies, toggleReplyExpansion, expandedReplies, commentNode, treeNode } = props
  const cardId = commentNode.id
  const [, { isFocused, isPrefocused }, { setFocusedAnnotations, setPrefocusedAnnotations }] =
    useScrollToFocusedComment([cardId])
  const onShowRepliesClick = useCallback(() => toggleReplyExpansion(cardId), [cardId, toggleReplyExpansion])

  return (
    <AnnotationCard
      aria-current={isFocused}
      className={"annotationCard"}
      focused={isFocused}
      faulty={commentNode.comment.persistanceState === "ERROR"}
      pendingDeletion={
        commentNode.comment.persistanceAction === "REMOVE" && commentNode.comment.persistanceState === "LOCAL"
      }
      prefocused={isPrefocused}
      onMouseOver={(e) => {
        e.stopPropagation()
        e.preventDefault()
        !isFocused && setPrefocusedAnnotations([{ comment: commentNode, treeNode }])
      }}
      onMouseOut={(e) => {
        e.stopPropagation()
        e.preventDefault()
        isPrefocused && setPrefocusedAnnotations([])
      }}
      onClick={(ev) =>
        !ev.currentTarget.classList.contains("ActionToolbar") &&
        setFocusedAnnotations([{ comment: commentNode, treeNode }])
      }
    >
      <Annotation
        annotation={commentNode}
        replies={replies}
        onShowRepliesClick={onShowRepliesClick}
        showReplies={!!expandedReplies.get(cardId)}
        isFocused={isFocused}
      />
    </AnnotationCard>
  )
}

export function LoadingAnnotations() {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <LoadingAnnotation />
      <LoadingAnnotation />
      <LoadingAnnotation />
    </div>
  )
}

export function EmptyAnnotations() {
  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div style={{ flex: 1 }}>
        <Empty description={<Translate>{keys.message.preview.comments.emptyCollection}</Translate>} />
      </div>
    </div>
  )
}

function LoadingAnnotation() {
  return (
    <AnnotationCard>
      <div>
        <AnnotationDisplaySkeleton />
      </div>
    </AnnotationCard>
  )
}
