import { useCallback } from "react"
import { useClipboard } from "@st4/clipboard"
import { ContextMenuOperationWrapper } from "../types"

export function useCopyOperationWrapper(): ContextMenuOperationWrapper {
  const { setData } = useClipboard()
  const handleMenuItemClick = useCallback(
    (nodeId: string) => {
      setData({ command: "copy", data: nodeId })
    },
    [setData],
  )

  return {
    queryOperationAllowed: async () => true,
    handleMenuItemClick,
  }
}
