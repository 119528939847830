import React from "react"
import { Row, Col } from "antd"

type ToolbarProps = {
  children: React.ReactNode
  gutter?: number
}

export function Toolbar({ children, gutter }: ToolbarProps) {
  return (
    <Row gutter={gutter} align="middle" justify="space-between" style={{ height: "100%" }} wrap={false}>
      {React.Children.map(children, (c) =>
        !c ? null : (
          <Col>
            <Row>{c}</Row>
          </Col>
        ),
      )}
    </Row>
  )
}
