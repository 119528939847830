import { Spin } from "antd"
import React, { useState, SetStateAction } from "react"
import { Icon, Regular } from "@st4/icons"

import styled from "styled-components"
import { notEmpty } from "../typescriptUtilites"

export type Node = {
  id: string
  icon: {
    default: {
      uri: string
    }
  }
}

export type Tree = {
  roots: Array<string>
  nodes: Array<TreeNode>
}

export type TreeNode = {
  id: string
  node: Node
  children?: string[]
  label: string
  icon: {
    default: {
      uri: string
    }
  }
}

type TreeViewProps = {
  name: string
  data: Tree | any
  roots: string[]
  baseTestId?: string
  active?: string
  onClick: (id: string) => void
  onToggle?: (selectedNodes: string[]) => void
}

const SELECTED_COLOR = "#0f7ee4" // currently chosen from ST4 client to make tree more similar

const StyledTreeView = styled.div``

type TreeContextHook = {
  isToggled: (id: string) => boolean
  toggle: (id: string) => void
  active: string | null
  setActive: React.Dispatch<SetStateAction<string | null>>
  getNodes: (ids: string[]) => TreeNode[]
}

function useTreeContext({
  initialActive,
  data,
  onToggle,
}: {
  initialActive: string | null
  data: Tree
  onToggle?: (selectedNodes: string[]) => void | undefined
}): TreeContextHook {
  const nodeMap = createNodeMap(data)
  const [toggledSet, setToggledSet] = useState(getInitialToggledSet())
  const [active, setActive] = useState<string | null>(initialActive || null)

  function getInitialToggledSet() {
    const tempSet = new Set<string>()
    data.nodes.map((n) => {
      //If a node has loaded child nodes it is toggled open
      if (getNodes(n.children || []).length > 0) {
        tempSet.add(n.id)
      }
    })
    return tempSet
  }

  function getNodes(ids: string[]) {
    return ids.map((i) => nodeMap.get(i)).filter(notEmpty)
  }

  function isToggled(id: string) {
    return toggledSet.has(id)
  }

  function expandNode(id: string) {
    const setCopy = new Set([...toggledSet, id])
    setToggledSet(setCopy)
    if (onToggle !== undefined) {
      let openNodeIds = Array.from(setCopy)
      const openNodes = getNodes(openNodeIds)
      openNodes.map((n) => (openNodeIds = openNodeIds.concat(n.children !== undefined ? n.children : [])))
      onToggle(openNodeIds)
    }
  }

  function collapseNode(id: string) {
    const setCopy = new Set([...toggledSet])
    setCopy.delete(id)
    setToggledSet(setCopy)
    if (onToggle !== undefined) {
      let openNodeIds = Array.from(setCopy)
      const openNodes = getNodes(openNodeIds)
      openNodes.map((n) => (openNodeIds = openNodeIds.concat(n.children !== undefined ? n.children : [])))
      onToggle(openNodeIds)
    }
  }

  function toggle(id: string) {
    if (toggledSet.has(id)) {
      collapseNode(id)
    } else {
      expandNode(id)
    }
  }

  return {
    isToggled,
    toggle,
    active,
    setActive,
    getNodes,
  }
}

export default function AnimatedTreeView(props: TreeViewProps) {
  const treecontext = useTreeContext({
    initialActive: props.active || null,
    data: props.data,
    onToggle: props.onToggle,
  })
  const { getNodes, isToggled } = treecontext

  return (
    <StyledTreeView data-testid={props.baseTestId + "-TreeView"}>
      {getNodes(props.roots).map((n) => {
        return (
          <TreeViewNode
            open={isToggled(n.id)}
            onClick={props.onClick}
            key={n.id}
            id={n.id}
            label={n.label}
            icon={n.icon ? n.icon.default.uri : ""}
            childNodes={getNodes(n.children || [])}
            treeContext={treecontext}
            childrenCount={n.children?.length || 0}
            hasChildren={n.children !== undefined && n.children.length > 0}
            baseTestId={props.baseTestId + "-TreeView"}
          >
            {n.children ?? []}
          </TreeViewNode>
        )
      })}
    </StyledTreeView>
  )
}

type TreeViewNodeProps = {
  id: string
  label: string
  icon?: string
  childNodes: TreeNode[]
  open: boolean
  onClick: (id: string) => void
  treeContext: TreeContextHook
  childrenCount: number
  children: string[]
  hasChildren: boolean
  loading?: boolean
  baseTestId?: string
}

type TreeViewNodeContentProps = {
  id: string
  label: string
  icon?: string
  isselected: boolean
  onClick: (id: string) => void
}

const StyledTreeViewNode = styled.div``
const ChildNode = styled.div``

const TreeIconCollapsed = (props: any) => (
  <svg {...props} viewBox="0 0 16 16">
    <g>
      <path
        fill="#A6A6A6"
        d="M11.875,7.875l-6.047,6.77l-2.051,-1.832l4.411,-4.938l-4.411,-4.938l2.051,-1.832l6.047,6.77Z"
      />
    </g>
  </svg>
)

const TreeIconExpanded = (props: any) => (
  <svg {...props} viewBox="0 0 16 16">
    <g>
      <path
        fill="#404040"
        d="M14.645,5.828l-6.77,6.047l-6.77,-6.047l1.832,-2.051l4.938,4.411l4.938,-4.411l1.832,2.051Z"
      />
    </g>
  </svg>
)

const toggleStyle = {
  width: "12px",
  height: "12px",
  marginLeft: 10,
  cursor: "pointer",
  verticalAlign: "middle",
  userSelect: "none",
}

const StyledChildren = styled.div`
  margin-left: 22px;
`

const StyledHeader = styled.div`
  display: flex;
  white-space: nowrap;
  vertical-align: middle;
  line-height: 1.6em;

  span {
    cursor: pointer;
  }
`

export const NodeIcon = styled.img`
  user-selectable: none;
  margin: 0px;
`

const StyledNodeSpan = styled.span<{ isselected?: boolean }>`
  white-space: nowrap;
  padding-right: 10px;
  border-radius: 1px;
  color: ${({ isselected }) => (isselected ? "white" : "inherit")};
  background-color: ${({ isselected }) => (isselected ? SELECTED_COLOR : "transparent")};
  line-height: 24px;
  margin: 0px;
  min-width: 100%;
`

function TreeViewNodeContent(props: TreeViewNodeContentProps) {
  const { id, label, icon, isselected, onClick } = props
  return (
    <StyledNodeSpan isselected={isselected}>
      <span onClick={() => onClick(id)}>
        {icon ? <NodeIcon style={{ marginBottom: "2px", marginLeft: "5px" }} src={icon} /> : null} {label}
      </span>
    </StyledNodeSpan>
  )
}

const TreeViewNode = React.memo(function TreeViewNode(props: TreeViewNodeProps) {
  const { isToggled, toggle, getNodes, active, setActive } = props.treeContext

  function handleClick(id: string) {
    setActive(props.id)
    if (props.onClick) {
      props.onClick(id)
    }
  }

  const isselected = active === props.id

  return (
    <StyledTreeViewNode>
      <StyledHeader data-id={props.id} data-testid={props.baseTestId + "-TreeNode-" + props.id}>
        {props.hasChildren ? (
          <span
            onClick={() => {
              toggle(props.id)
            }}
          >
            {isToggled(props.id) ? <TreeIconExpanded style={toggleStyle} /> : <TreeIconCollapsed style={toggleStyle} />}
          </span>
        ) : (
          <span>
            {props.loading ? (
              <Spin size="small" delay={500} indicator={<Icon component={Regular.SpinnerThird} />} />
            ) : (
              <TreeIconCollapsed style={{ ...toggleStyle, visibility: "hidden" }} />
            )}
          </span>
        )}
        <TreeViewNodeContent
          id={props.id}
          label={props.label}
          icon={props.icon}
          isselected={isselected}
          onClick={() => handleClick(props.id)}
        />
      </StyledHeader>
      {props.hasChildren ? (
        <StyledChildren>
          <TreeChildren {...props} isToggled={isToggled} getNodes={getNodes} />
        </StyledChildren>
      ) : null}
    </StyledTreeViewNode>
  )
})

type TreeChildrenProps = TreeViewNodeProps & Pick<TreeContextHook, "getNodes" | "isToggled">

function TreeChildren(props: TreeChildrenProps) {
  if (!props.open) return null
  if (props.hasChildren && props.childNodes.length == 0) {
    return (
      <>
        {props.children.map((id) => {
          return (
            <ChildNode key={id}>
              <TreeViewNode
                open={props.isToggled(id)}
                onClick={props.onClick}
                key={"TVN-" + id}
                id={id}
                label={""}
                childNodes={[]}
                treeContext={props.treeContext}
                hasChildren={false}
                loading={true}
                childrenCount={0}
                baseTestId={props.baseTestId}
              >
                {[]}
              </TreeViewNode>
            </ChildNode>
          )
        })}
      </>
    )
  } else {
    return (
      <>
        {(props.open ? props.childNodes : []).map((c) => (
          <ChildNode key={c.node ? c.id : ""}>
            <TreeViewNode
              open={props.isToggled(c.id)}
              onClick={props.onClick}
              key={"TVN-" + c.id}
              id={c.node ? c.id : ""}
              label={c.label || " -- "}
              icon={c.icon?.default.uri}
              childNodes={props.getNodes(c.children || [])}
              treeContext={props.treeContext}
              hasChildren={c.children !== undefined && c.children.length > 0}
              childrenCount={c.children?.length || 0}
              baseTestId={props.baseTestId}
            >
              {c.children ?? []}
            </TreeViewNode>
          </ChildNode>
        ))}
      </>
    )
  }
}

function createNodeMap(data: Partial<Tree>): Map<string, TreeNode> {
  const map = new Map<string, TreeNode>()
  const nodes = data.nodes || []
  nodes.forEach((n) => map.set(n.id, n))
  return map
}
