import "./node-polyfills.js"
import React from "react"
import ReactDOM from "react-dom"
import { AuthenticationProvider, LOGIN_STATE_VAR } from "@st4/authentication"
import { GraphQLProvider } from "@st4/graphql"

import { BrowserRouter as Router } from "react-router-dom"
import ThemeManager, { defaultTheme, ThemeProvider } from "@st4/theme-manager"
import { integrate, bootstrapRegistries } from "@st4/customize-host"

import { InsightLoader } from "@st4/insight"
import App from "./App"
import { LoginLogo } from "./ui/logos"
import { createGlobalStyle } from "styled-components"

// import "antd/dist/antd.variable.min.css"
import "antd/dist/reset.css"
import { App as AntdApp } from "antd"
import { Resolvers, TypePolicies } from "@apollo/client"

import { clientResolvers as previewClientResolvers, typePolicies as previewTypePolicies } from "@st4/content"
import { typePolicies as taskTypePolicies } from "@st4/tasks"
import { registerBlades } from "~/blades"
import { registerTaskDefinitions } from "~/taskdefinitions"
import { ConfigurationProvider } from "~/components/configurationprovider"
import { mergeDeep } from "@apollo/client/utilities"

const theme = {
  ...defaultTheme,
}

ThemeManager.register("defaultTheme", theme)

integrate()
bootstrapRegistries()
registerBlades()
registerTaskDefinitions()

const GlobalThemeStyles = createGlobalStyle`
  * {
    scrollbar-width: thin;
  }

  &::-webkit-scrollbar {
    width: 8px;
    border-radius: 4px;
    height: 8px;
    background-color: hsl(0deg 0% 0% / 4%);
  }

  &::-webkit-scrollbar:hover {
    background-color: hsl(0deg 0% 0% / 7%);
  }

  &::-webkit-scrollbar-thumb {
    background-color: hsl(0deg 0% 0% / 20%);
    border-radius: 4px;
  }
`
const typePolicies = mergeDeep<TypePolicies[]>(previewTypePolicies, taskTypePolicies, {
  ConfigurationModel: {
    merge: true,
  },
  Property: {
    keyFields: false,
  },
  PropertyEditor: {
    keyFields: false,
  },
  SelectedFilterAttribute: {
    keyFields: false,
  },
  SelectedTextFilterAttribute: {
    keyFields: false,
  },
  //Singleton of actual user, therefore has no own id
  UserEditorConfiguration: {
    keyFields: ["__typename"],
  },
  //ST4TreeNodes can be used in several trees with different variants so we don´t want them to cache-normalize
  ST4TreeNode: {
    keyFields: false,
  },
  // The typeresolvers read function didn't work correctly.
  // After the initial read the signelton tasks, returned by this read function, where stored inside the cache as well.
  // I Couldn't reproduce this issue within a clean project and with the release date approaching fast I just created wrappers for the GraphQL requests.
  // User: {
  //   fields: {
  //     runningTasks: {
  //       read(cachedValue = [], {cache}) {
  //         const runningSingletons = runningSingletonTasks()
  //         const merged = [...cachedValue, ...runningSingletons]
  //         return merged
  //       },
  //     },
  //   },
  // },
})

const clientResolvers: Resolvers = {
  // Client resolvers are marked obsolete!!!
  ...previewClientResolvers,
}

window
  .loadPlugins(window.providedPlugins)
  .then(() => {
    ReactDOM.render(
      <React.StrictMode>
        <GlobalThemeStyles />
        <ThemeProvider>
          <GraphQLProvider
            typePolicies={typePolicies}
            clientResolvers={clientResolvers}
            getAuthId={() => {
              const loginState = LOGIN_STATE_VAR()
              return loginState.state === "loggedIn" ? loginState.sessionData.authId : null
            }}
            onUnauthenticatedError={() => LOGIN_STATE_VAR({ state: "loggedOut" })}
          >
            <AuthenticationProvider language={"en"} logo={<LoginLogo />} license="ST4.WebAuthor">
              <ConfigurationProvider>
                <AntdApp>
                  <InsightLoader />
                  <Router>
                    <App />
                  </Router>
                </AntdApp>
              </ConfigurationProvider>
            </AuthenticationProvider>
          </GraphQLProvider>
        </ThemeProvider>
      </React.StrictMode>,
      document.getElementById("root"),
    )
  })
  .catch((e: any) => {
    ReactDOM.render(
      <React.StrictMode>
        <div>
          <h1>Could not load plugins...</h1>
          <p>{JSON.stringify(e)}</p>
        </div>
      </React.StrictMode>,
      document.getElementById("root"),
    )
  })
