import React from "react"
import { LanguageSettings } from "./languageSettings"
import styled from "styled-components"

export * from "./query.hooks"
export * from "./language"
export { SETTINGS_VAR, useSettings, useSettingsQuery } from "./settingsState"

export { useAppTokenSettings, isLoading as isAppTokenLoading, isReady as isAppTokenReady } from "./appTokenSettings"

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

const Main = styled.main`
  flex: 1;
  padding: 1em;
`

const Footer = styled.div`
  flex: 0;
  justify-self: end;
  padding: 1em;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.greys["400"]};
`

export function Settings() {
  return (
    <Wrapper>
      <Main>
        <LanguageSettings />
      </Main>
      <Footer>
        {window.CI_PIPELINE_ID}-{window.CI_PIPELINE_CREATED_AT}
        <br />
        {window.CI_PACKAGE_VERSION}
      </Footer>
    </Wrapper>
  )
}
