import { makeVar, useReactiveVar } from "@apollo/client"

export type ClipboardData =
  | {
      command: "cut" | "copy"
      data: unknown
    }
  | undefined

const clipboardVar = makeVar<ClipboardData>(undefined)

export type GetData = () => ClipboardData
export type SetData = (data: ClipboardData) => void

export type ClipboardWithFunctions = {
  /**
   * Data currently in the clipboard. Data may be undefined, if the clipboard is empty.
   */
  data: ClipboardData
  /**
   * Gets the data in the clipboard. Result may be undefined, if the clipboard is empty.
   */
  getData: GetData
  /**
   * Set data to the clipboard. Set to undefined to clear the clipboard.
   */
  setData: SetData
}

function getData() {
  return clipboardVar()
}
function setData(data: ClipboardData) {
  clipboardVar(data)
}

export function useClipboard(): ClipboardWithFunctions {
  const data = useReactiveVar(clipboardVar)

  return {
    data,
    getData,
    setData,
  }
}
