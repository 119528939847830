import * as React from "react"
import { SVGProps } from "react"

const SvgSt4AddVariantLight = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="1em" height="1em" {...props}>
    <path d="M429.3 280c12-12 18.7-28.2 18.7-45.2V85.3c0-26.5-21.5-48-48-48H250.5c-17 0-33.2 6.7-45.2 18.8L29.3 232c-25 25-25 65.6 0 90.6L162.7 456c25 25 65.6 25 90.6 0l176-176zM230.6 433.4c-12.5 12.5-32.7 12.5-45.2 0L51.9 299.9c-12.5-12.5-12.5-32.7 0-45.2l176-176c6-6 15-9.4 22.6-9.4H400c8.8 0 16 7.2 16 16v149.5c0 7.6-3.4 16.6-9.4 22.6l-176 176zm-75.7-183.1c0 8.8 7.2 16 16 16h48v48c0 8.8 7.2 16 16 16s16-7.2 16-16v-48h48c8.8 0 16-7.2 16-16s-7.2-16-16-16h-48v-48c0-8.8-7.2-16-16-16s-16 7.2-16 16v48h-48c-8.8 0-16 7.2-16 16z" />
  </svg>
)

export default SvgSt4AddVariantLight
