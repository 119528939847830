function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) {
    try {
        var info = gen[key](arg);
        var value = info.value;
    } catch (error) {
        reject(error);
        return;
    }
    if (info.done) {
        resolve(value);
    } else {
        Promise.resolve(value).then(_next, _throw);
    }
}
function _asyncToGenerator(fn) {
    return function() {
        var self = this, args = arguments;
        return new Promise(function(resolve, reject) {
            var gen = fn.apply(self, args);
            function _next(value) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value);
            }
            function _throw(err) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err);
            }
            _next(undefined);
        });
    };
}
var __generator = this && this.__generator || function(thisArg, body) {
    var f, y, t, g, _ = {
        label: 0,
        sent: function() {
            if (t[0] & 1) throw t[1];
            return t[1];
        },
        trys: [],
        ops: []
    };
    return g = {
        next: verb(0),
        "throw": verb(1),
        "return": verb(2)
    }, typeof Symbol === "function" && (g[Symbol.iterator] = function() {
        return this;
    }), g;
    function verb(n) {
        return function(v) {
            return step([
                n,
                v
            ]);
        };
    }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while(_)try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [
                op[0] & 2,
                t.value
            ];
            switch(op[0]){
                case 0:
                case 1:
                    t = op;
                    break;
                case 4:
                    _.label++;
                    return {
                        value: op[1],
                        done: false
                    };
                case 5:
                    _.label++;
                    y = op[1];
                    op = [
                        0
                    ];
                    continue;
                case 7:
                    op = _.ops.pop();
                    _.trys.pop();
                    continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                        _ = 0;
                        continue;
                    }
                    if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
                        _.label = op[1];
                        break;
                    }
                    if (op[0] === 6 && _.label < t[1]) {
                        _.label = t[1];
                        t = op;
                        break;
                    }
                    if (t && _.label < t[2]) {
                        _.label = t[2];
                        _.ops.push(op);
                        break;
                    }
                    if (t[2]) _.ops.pop();
                    _.trys.pop();
                    continue;
            }
            op = body.call(thisArg, _);
        } catch (e) {
            op = [
                6,
                e
            ];
            y = 0;
        } finally{
            f = t = 0;
        }
        if (op[0] & 5) throw op[1];
        return {
            value: op[0] ? op[1] : void 0,
            done: true
        };
    }
};
import { isTypename } from "@st4/graphql";
export function handleSaveDocument(data, context) {
    return _handleSaveDocument.apply(this, arguments);
}
function _handleSaveDocument() {
    _handleSaveDocument = _asyncToGenerator(function(data, context) {
        var status, ref, ref1;
        return __generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    return [
                        4,
                        saveDocument(data, context)
                    ];
                case 1:
                    status = _state.sent();
                    if (status.warnings.length > 0 && status.reasons.length === 0) {
                        ;
                        (ref = status.warnings) === null || ref === void 0 ? void 0 : ref.filter(function(warning) {
                            return warning.messageKey;
                        }).forEach(function(warning) {
                            console.log("Save editor with warning: ", warning);
                            var _messageArgs;
                            var message = {
                                action: "sendNotificationsOnSave",
                                payload: {
                                    messageKey: warning.messageKey,
                                    messageArgs: (_messageArgs = warning.messageArgs) !== null && _messageArgs !== void 0 ? _messageArgs : [],
                                    severity: "warning"
                                }
                            };
                            context.sendMessage(message);
                        });
                    } else if (status.reasons.length > 0) {
                        ;
                        (ref1 = status.reasons) === null || ref1 === void 0 ? void 0 : ref1.filter(function(error) {
                            return error.messageKey;
                        }).forEach(function(error) {
                            console.log("Save editor failed with error: ", error);
                            var _messageArgs;
                            var message = {
                                action: "sendNotificationsOnSave",
                                payload: {
                                    messageKey: error.messageKey,
                                    messageArgs: (_messageArgs = error.messageArgs) !== null && _messageArgs !== void 0 ? _messageArgs : [],
                                    severity: "error"
                                }
                            };
                            context.sendMessage(message);
                        });
                    }
                    return [
                        2,
                        {
                            status: status.status,
                            body: {}
                        }
                    ];
            }
        });
    });
    return _handleSaveDocument.apply(this, arguments);
}
function saveDocument(data, context) {
    return _saveDocument.apply(this, arguments);
}
function _saveDocument() {
    _saveDocument = _asyncToGenerator(function(data, context) {
        var ref, ref1, ref2, ref3, ref4, result, ref5, _reasons, reasons, _warnings, warnings;
        return __generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    return [
                        4,
                        context.saveEditorNode({
                            variables: {
                                selectedNode: context.nodeId,
                                languageId: context.languageId,
                                xml: data.metadata.body.content,
                                newVersionLimit: (ref4 = (ref = context.editorSession) === null || ref === void 0 ? void 0 : ref.newVersionLimit) !== null && ref4 !== void 0 ? ref4 : "",
                                isAutoSave: !!data.metadata.body.autosave
                            }
                        })
                    ];
                case 1:
                    result = _state.sent();
                    return [
                        4,
                        context.onEditorSaved()
                    ];
                case 2:
                    _state.sent();
                    if (isTypename("SaveEditorNodeFailure")((ref1 = result.data) === null || ref1 === void 0 ? void 0 : ref1.saveEditorNodeContent)) {
                        ;
                        ;
                        reasons = (_reasons = (ref5 = result.data) === null || ref5 === void 0 ? void 0 : ref5.saveEditorNodeContent.reasons) !== null && _reasons !== void 0 ? _reasons : [];
                        return [
                            2,
                            {
                                status: 403,
                                reasons: reasons,
                                warnings: []
                            }
                        ];
                    }
                    if (isTypename("Error")((ref2 = result.data) === null || ref2 === void 0 ? void 0 : ref2.saveEditorNodeContent)) return [
                        2,
                        {
                            status: 500,
                            warnings: [],
                            reasons: []
                        }
                    ];
                    warnings = (_warnings = (ref3 = result.data) === null || ref3 === void 0 ? void 0 : ref3.saveEditorNodeContent.result.warnings) !== null && _warnings !== void 0 ? _warnings : [];
                    return [
                        2,
                        {
                            status: 200,
                            warnings: warnings,
                            reasons: []
                        }
                    ];
            }
        });
    });
    return _saveDocument.apply(this, arguments);
}
