import React, { useState } from "react"
import styled from "styled-components"
import {
  IndentationSpacer,
  NodeCheckbox,
  NodeContent,
  NodeExpandToggle,
  NodeSelectionSpan,
  StyledHeader,
  TreeViewNodeProps,
} from "../base"
import { NodeButtons } from "../base/nodeButtons"
import { CanDropInformation, DropInsertionType } from "../types"
import { DraggableSpan } from "./draggablespan"
import { DragHandle } from "./draghandle"
import { DragItemView, DragItemViewProps } from "./dragitemview"

export type DraggableTreeViewNodeProps = {
  canDrag: boolean
  isDraggedItem: boolean
  isDraggedOverItem: boolean
  onIsDraggingChange: (isDragging: boolean) => void
  dropInsertionType: DropInsertionType | null
} & TreeViewNodeProps &
  Omit<DragItemViewProps, "draggable" | "node" | "children">

export const DraggableTreeViewNode = React.memo(
  function DraggableTreeViewNode(props: DraggableTreeViewNodeProps) {
    const {
      item,
      isDraggedItem,
      isDraggedOverItem,
      canDrag,
      onIsDraggingChange,
      isSelected,
      meatballMenu,
      createButton,
      onHoverChange,
      isParentHovered,
    } = props
    const [isHover, setIsHover] = useState(false)
    const classnames = []
    if (isDraggedItem) classnames.push("dragged")
    if (isDraggedOverItem) classnames.push("draggedOver")

    return (
      <StyledTreeViewNode className={"TreeViewNode " + classnames.join(" ")} aria-level={item.indentation + 1}>
        <NodeSelectionSpan
          isSelected={isSelected}
          isHover={isHover}
          isParentHovered={isParentHovered}
          onHoverChange={setIsHover}
          style={{ display: "flex", flexDirection: "row" }}
        >
          <IndentationSpacer indentation={item.indentation} style={{ flexGrow: 0 }} />
          <DragItemView {...props} draggable={canDrag} node={item}>
            <span>{canDrag && <DragHandle onMouseDownChanged={onIsDraggingChange} />}</span>
            <StyledHeader data-id={item.node.id} style={{ flexGrow: 1 }}>
              <StyledTreeViewItem dropInsertionType={props.dropInsertionType}>
                <DraggableSpan onMouseDownChanged={onIsDraggingChange}>
                  <NodeExpandToggle onHover={onHoverChange} {...props} />
                  <NodeCheckbox {...props} />
                  <NodeContent
                    dropInsertionType={props.dropInsertionType}
                    id={item.node.id}
                    label={item.node.label}
                    icon={item.node.icon ?? ""}
                    onClick={() => props.onClick(item.node.id)}
                  />
                </DraggableSpan>
                <NodeButtons
                  node={item.node}
                  createButton={createButton}
                  meatballMenu={meatballMenu}
                  isHover={isHover}
                  isSelected={isSelected}
                />
              </StyledTreeViewItem>
            </StyledHeader>
          </DragItemView>
        </NodeSelectionSpan>
      </StyledTreeViewNode>
    )
  },
  (prevProps, nextProps) => {
    return (
      prevProps.checkable === nextProps.checkable &&
      prevProps.isChecked === nextProps.isChecked &&
      prevProps.isSelected === nextProps.isSelected &&
      prevProps.isExpanded === nextProps.isExpanded &&
      prevProps.onExpandChange === nextProps.onExpandChange &&
      prevProps.item.hasChildren === nextProps.item.hasChildren &&
      prevProps.item.node.label === nextProps.item.node.label &&
      prevProps.item.node.icon == nextProps.item.node.icon &&
      prevProps.canDrag === nextProps.canDrag &&
      isCanDropInformationEqual(prevProps.canDropInformation, nextProps.canDropInformation) &&
      prevProps.isDraggedItem === nextProps.isDraggedItem &&
      prevProps.isDraggedOverItem === nextProps.isDraggedOverItem &&
      prevProps.dropInsertionType === nextProps.dropInsertionType &&
      prevProps.meatballMenu?.dropdownRenderForNode === nextProps.meatballMenu?.dropdownRenderForNode &&
      prevProps.createButton?.onClick === nextProps.createButton?.onClick &&
      prevProps.onHoverChange === nextProps.onHoverChange &&
      prevProps.isParentHovered === nextProps.isParentHovered
    )
  },
)

function isCanDropInformationEqual(a: CanDropInformation, b: CanDropInformation) {
  if (a.type !== b.type) return false

  if ((a.type === "loaded" || a.type === "requestingUpdate" || a.type === "updating") && b.type === a.type)
    return a.allowedItems === b.allowedItems && a.queriedItems === b.queriedItems
  else if (a.type === "loading" && b.type === a.type) return a.queriedItems === b.queriedItems
  else return true
}

// TODO: Has ".TreeViewNode" the desired effect?
const StyledTreeViewNode = styled.div`
  &.dragged .TreeViewNode {
    outline: 1px solid blue;
    opacity: 0.4;
  }

  &.draggedOver .TreeViewNode {
    outline: 1px solid transparent;
  }
`

const StyledTreeViewItem = styled.span<{ dropInsertionType: DropInsertionType | null }>`
  border-top: ${(p) =>
    p.dropInsertionType === "INSERT_AS_PREVIOUS_SIBLING" ? "2px solid blue" : "2px solid transparent"};
  border-bottom: ${(p) =>
    p.dropInsertionType === "INSERT_AS_NEXT_SIBLING" ? "2px solid blue" : "2px solid transparent"};
  display: grid;
  grid: auto / 1fr auto;
  justify-content: space-between;
`
