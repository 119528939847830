import * as React from "react"
import { SVGProps } from "react"

const SvgSt4IndexentryRegular = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1em" height="1em" {...props}>
    <path d="M488 160c13.3 0 24-10.7 24-24V88c0-13.3-10.7-24-24-24s-24 10.7-24 24v48c0 13.3 10.7 24 24 24zm0 128c13.3 0 24-10.7 24-24v-48c0-13.3-10.7-24-24-24s-24 10.7-24 24v48c0 13.3 10.7 24 24 24zm0 128c13.3 0 24-10.7 24-24v-48c0-13.3-10.7-24-24-24s-24 10.7-24 24v48c0 13.3 10.7 24 24 24zM368 48c8.8 0 16 7.2 16 16v384c0 8.8-7.2 16-16 16H96c-8.8 0-16-7.2-16-16V64c0-8.8 7.2-16 16-16h272m0-48H96C60.7 0 32 28.7 32 64v384c0 35.3 28.7 64 64 64h272c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64z" />
  </svg>
)

export default SvgSt4IndexentryRegular
