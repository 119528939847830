import * as React from "react"
import { SVGProps } from "react"

const SvgDownFromDottedLine = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="1em" height="1em" {...props}>
    <path d="M222.2 447.2c.5.5 1.1.8 1.8.8s1.4-.3 1.8-.8l124.4-131.9c1.2-1.2 1.8-2.9 1.8-4.6 0-3.7-3-6.7-6.7-6.7H288c-8.8 0-16-7.2-16-16V168c0-4.4-3.6-8-8-8h-80c-4.4 0-8 3.6-8 8v120c0 8.8-7.2 16-16 16h-57.3c-3.7 0-6.7 3-6.7 6.7 0 1.7.7 3.3 1.8 4.6l124.4 131.9zM224 480c-9.5 0-18.6-3.9-25.1-10.8l-124.4-132C67.8 330 64 320.5 64 310.7c0-21.4 17.3-38.7 38.7-38.7H144V168c0-22.1 17.9-40 40-40h80c22.1 0 40 17.9 40 40v104h41.3c21.4 0 38.7 17.3 38.7 38.7 0 9.9-3.8 19.4-10.5 26.5l-124.4 132c-6.5 6.9-15.6 10.8-25.1 10.8zM32 88a24 24 0 1 1 0-48 24 24 0 1 1 0 48zm384 0a24 24 0 1 1 0-48 24 24 0 1 1 0 48zM104 64a24 24 0 1 1 48 0 24 24 0 1 1-48 0zm120 24a24 24 0 1 1 0-48 24 24 0 1 1 0 48zm72-24a24 24 0 1 1 48 0 24 24 0 1 1-48 0z" />
  </svg>
)

export default SvgDownFromDottedLine
