import { HolderOutlined } from "@ant-design/icons"
import React from "react"

type DragHandleProps = {
  onMouseDownChanged: (mouseIsPressed: boolean) => void
}

export function DragHandle({ onMouseDownChanged }: DragHandleProps) {
  return (
    <>
      <HolderOutlined
        className="drag-handle"
        onMouseDown={() => onMouseDownChanged(true)}
        onMouseUp={() => onMouseDownChanged(false)}
        style={{ verticalAlign: "center" }}
      />
    </>
  )
}
