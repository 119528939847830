import React, { useState, useEffect } from "react"

type DelayedRenderProps = {
  timeout?: number
}

// This component has the effect of a "delayed rendering". It'll render your passed children only, if the passed timeout elapsed.
export function DelayedRender({ children, timeout }: React.PropsWithChildren<DelayedRenderProps>) {
  const [displayComponent, changeDisplay] = useState(false)

  useEffect(() => {
    if (!timeout) {
      changeDisplay(true)
      return
    }

    const timerHandle = window.setTimeout(() => {
      changeDisplay(true)
    }, timeout)

    return function cleanUp() {
      window.clearTimeout(timerHandle)
    }
  }, [timeout])

  return displayComponent ? <>{children}</> : null
}
