import { isTypename } from "@st4/graphql"
import React from "react"
import { isST4NodeWithContent } from "../../../graphql/types"
import { usePreviewContentModel } from "../../../contentModel"
import { CommentCollectionProvider } from "../../annotationCollection"
import { NodeHeading } from "../../contentComponents/NodeHeading"
import { useMappingContext } from "../../mappingContext"
import NodePreview from "../../NodePreview"
import type { NodeRenderProps } from "../types"
import { NodeWrapper, useIsNodeSelected } from "../utils"

export const ReadonlyTextNode = React.forwardRef<HTMLDivElement, NodeRenderProps>(function ReadonlyTextNode(
  { treeNode, node }: NodeRenderProps,
  ref,
) {
  const contentModel = usePreviewContentModel()
  const comments = (isST4NodeWithContent("TextContent", "TextGroupContent")(node) && node.content.comments) || []

  if (!isTypename("TextContent", "TextGroupContent")(node.content)) throw new Error("Unexpected Content type in Node")

  function onClick() {
    if (treeNode && contentModel.state === "ready" && contentModel.onNodeClick) {
      const realTreeNode = contentModel.treeNodesById.get(treeNode.id)
      if (realTreeNode) {
        contentModel.onNodeClick(realTreeNode)
      }
    }
  }

  const mappingFromContext = useMappingContext()

  const selected = useIsNodeSelected(treeNode)

  return (
    <div className="ReadonlyTextNode" ref={ref}>
      <CommentCollectionProvider comments={comments}>
        <NodeWrapper selected={selected}>
          <NodeHeading level={1} onClick={onClick}>
            {node.label}
          </NodeHeading>
          <NodePreview mapping={mappingFromContext} node={node} treeNode={treeNode} />
        </NodeWrapper>
      </CommentCollectionProvider>
    </div>
  )
})
