import React from "react"
import { Switch } from "antd"
import { useTranslation } from "react-i18next"
import { keys } from "@st4/ui-strings"

type SearchOptionsSwitchProps = {
  checked?: boolean
  onChange: (checked: boolean) => void
}

export function SearchOptionsSwitch(props: SearchOptionsSwitchProps) {
  const { t } = useTranslation()
  const id = "searchOptionsSwitch"
  return (
    <div>
      <Switch data-testid={`${id}-switch`} checked={props.checked} onChange={(checked) => props.onChange(checked)} />
      <span data-testid={`${id}-label`} style={{ margin: "8px" }}>
        {t(keys.label.search.component.searchOptionsSwitch)}
      </span>
    </div>
  )
}
