import React, { useCallback, useState } from "react"
import { Blade, BladeDefinition } from "@st4/tasks"
import { NavigationTreeViewletWithState } from "~/components/navigationtree"
import { useAspectId } from "@st4/settings"
import { keys } from "@st4/ui-strings"
import { Translate } from "@st4/i18n"
import { QuanosBlueButton } from "~/ui/quanosbutton"
import { isTypename } from "~/../../../packages/st4-graphql/lib"
import styled from "styled-components"
import { FragmentTreeActionBar } from "~/components/treeActions/fragmentAction"
import { NotificationInfo } from "~/components/treeActions/types"
import { PoolBladeNotifications } from "~/components/treeActions/PoolBladeNotifications"

type FragmentPoolTreeBladeProps = {
  variant: string
  selectedNode?: {
    id: string
    classHierarchy: string[]
  }
  contextNode: string
  allowedOperations: string[]
}

type Messages =
  | {
      action: "onNodeSelected"
      payload:
        | {
            id: string
            classHierarchy: string[]
          }
        | undefined
    }
  | {
      action: "onClose"
      payload: any
    }
  | {
      action: "insertFragment"
      payload: {
        fragmentId: string
        fragmentType: string
        listType: string
      }
    }

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const FragmentPoolTreeBlade: BladeDefinition<FragmentPoolTreeBladeProps, Messages> =
  function TreeBladeWithContext(props) {
    const aspectId = useAspectId()
    const [notifications, setInternalNotifications] = useState<NotificationInfo[]>([])
    const [insertFragmentMessage, setInternalInsertFragmentMessage] = useState<Messages>({
      action: "insertFragment",
      payload: {
        fragmentId: props.selectedNode?.id ?? "",
        fragmentType: "",
        listType: "",
      },
    })
    const [noDataAvailable, setInternalNoDataAvailable] = useState<boolean>(false)

    const setNotifications = useCallback((newNotifications: NotificationInfo[]) => {
      setInternalNotifications([...newNotifications])
    }, [])

    const setInsertFragmentMessage = useCallback((insertMessage: Messages) => {
      setInternalInsertFragmentMessage(insertMessage)
    }, [])

    const setNoDataAvailable = useCallback((noDataAvailable: boolean) => {
      setInternalNoDataAvailable(noDataAvailable)
    }, [])

    const hasNotificationDisablingInsert = notifications.some((notification) => notification.disableInsert)

    return (
      <>
        <Blade.Content>
          <Container>
            <div style={{ flex: 1, overflowY: "auto" }}>
              <NavigationTreeViewletWithState
                onSelect={(node) =>
                  props.sendMessage({
                    action: "onNodeSelected",
                    payload: node
                      ? {
                          id: node.id,
                          classHierarchy: isTypename("ST4Node")(node.node)
                            ? node.node.nodeClass?.classHierarchy ?? []
                            : [],
                        }
                      : undefined,
                  })
                }
                variant={props.variant}
                aspectId={aspectId}
                selected={props.selectedNode?.id}
                includeNodeClassHierarchy
                fetchPolicy="cache-and-network"
              />
            </div>
            <FragmentTreeActionBar
              selectedNode={props.selectedNode}
              contextNode={props.contextNode}
              aspectId={aspectId}
              variant={props.variant}
              allowedOperations={props.allowedOperations}
              setNotifications={setNotifications}
              setInsertFragmentMessage={setInsertFragmentMessage}
              setNoDataAvailable={setNoDataAvailable}
            />
          </Container>
        </Blade.Content>
        <PoolBladeNotifications notifications={notifications} />
        <Blade.Actionbar>
          <div />
          <QuanosBlueButton
            disabled={hasNotificationDisablingInsert || noDataAvailable || !props.selectedNode?.id}
            onClick={() => {
              props.sendMessage(insertFragmentMessage)
            }}
          >
            <Translate>{keys.button.blade.insert}</Translate>
          </QuanosBlueButton>
        </Blade.Actionbar>
      </>
    )
  }
FragmentPoolTreeBlade.title = <Translate>{keys.label.fragmentPoolBlade.title}</Translate>
FragmentPoolTreeBlade.size = { S: 5, M: 5, L: 5 }
FragmentPoolTreeBlade.reducer = function (previousState, message) {
  switch (message.action) {
    case "onNodeSelected":
      return { ...previousState, selectedNode: message.payload }
  }

  return previousState
}
