import React from "react"
import MediaQuery from "react-responsive"

/**
 * Displays the props.children only if the screen is smaller than 800px.
 * If props.children is a function, it'll be called with the first
 * parameter indicating if the condition is statistied (match)
 * @param {*} props
 */
export const SmallScreen_deprecated = (props) => (
  <MediaQuery {...props} maxWidth={800}>
    {props.children}
  </MediaQuery>
)

/**
 * Displays the props.children only if the screen is larger than 800px.
 * If props.children is a function, it'll be called with the first
 * parameter indicating if the condition is statistied (match)
 * @param {*} props
 */
export const LargeScreen_deprecated = (props) => (
  <MediaQuery {...props} minWidth={800}>
    {props.children}
  </MediaQuery>
)
