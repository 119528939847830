import React from "react"
import { ApolloProvider } from "@apollo/client/react"
import { ApolloClient, from, split, TypePolicies } from "@apollo/client"
import { createHttpLink } from "@apollo/client/link/http"
import { setContext } from "@apollo/client/link/context"
import { InMemoryCache } from "@apollo/client/cache"
import { onError } from "@apollo/client/link/error"
import typeDefs from "./clientSchema"
import { default as possibleTypesFile } from "./possibleTypes"
export * from "./schema"

export const possibleTypes = possibleTypesFile.possibleTypes
import { totp } from "otplib"
import { BatchHttpLink } from "@apollo/client/link/batch-http"

function getApolloCache(typePolicies: TypePolicies, possibleTypes: any) {
  return new InMemoryCache({
    possibleTypes,
    typePolicies,
  })
}

function getApolloLink(getAuthId: () => string | null | undefined, onUnauthenticatedError: () => void) {
  const httpLink = createHttpLink({ uri: "/graphql" })
  const batchHttpLink = new BatchHttpLink({ uri: "/graphql", batchInterval: 500, batchDebounce: true })

  const authLink = setContext((_, { headers }) => {
    const authId = getAuthId()
    const challenge = authId ? totp.generate(authId) : null
    return {
      headers: {
        ...headers,
        "x-auth-id": authId || "",
        "x-auth-challenge": challenge,
      },
    }
  })
  const errorLink = onError(({ networkError, graphQLErrors }) => {
    let userNotAuthorized = false
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) => {
        if (message === "The current user is not authorized to access this resource.") {
          userNotAuthorized = true
        }
      })
    }
    if (((networkError as any) && (networkError as any).statusCode === 401) || userNotAuthorized) {
      onUnauthenticatedError()
      window.location.reload()
    }
  })

  const batchingSplitLink = split((op) => op.getContext()?.batch === true, batchHttpLink, httpLink)
  return from([errorLink, authLink, batchingSplitLink])
}

export function getClient(
  typePolicies: TypePolicies,
  clientResolvers: any,
  getAuthId: () => string | null | undefined,
  onUnauthenticatedError: () => void,
) {
  const link = getApolloLink(getAuthId, onUnauthenticatedError)
  const cache = getApolloCache(typePolicies, possibleTypes)
  const client = new ApolloClient({
    link,
    cache,
    typeDefs,
    resolvers: clientResolvers,
    connectToDevTools: true,
  })
  return client
}

export type GraphQLProviderProps = {
  children: React.ReactNode
  typePolicies: TypePolicies
  clientResolvers: any
  getAuthId: () => string | null | undefined
  onUnauthenticatedError: () => void
}

export function GraphQLProvider({
  children,
  typePolicies,
  clientResolvers,
  getAuthId,
  onUnauthenticatedError,
}: GraphQLProviderProps) {
  return (
    <ApolloProvider client={getClient(typePolicies, clientResolvers, getAuthId, onUnauthenticatedError)}>
      {children}
    </ApolloProvider>
  )
}
export * from "./types"
