import * as Types from "@st4/graphql"

import { gql } from "@apollo/client"
import * as Apollo from "@apollo/client"
const defaultOptions = {} as const
export type ImageByTreeResourceTreeBladeQueryVariables = Types.Exact<{
  selectedNode: Types.Scalars["StPatternContextId"]
  aspectId: Types.Scalars["AspectSelectorId"]
  variant: Types.Scalars["String"]
}>

export type ImageByTreeResourceTreeBladeQuery = {
  __typename: "Query"
  tree?: {
    __typename: "ST4Tree"
    nodes: Array<{
      __typename: "ST4TreeNode"
      id: string
      node?: {
        __typename: "ST4Node"
        id: string
        nodeClass: { __typename: "ST4Class"; classHierarchy: Array<string> }
        content?:
          | { __typename: "LexiconContent" }
          | { __typename: "LexiconEntryContent" }
          | {
              __typename: "MediaContent"
              media?:
                | {
                    __typename: "AnimationMediaInfo"
                    id: string
                    thumbnail?: string | null
                    resourceId?: string | null
                  }
                | {
                    __typename: "ImageMediaInfo"
                    id: string
                    src?: string | null
                    thumbnail?: string | null
                    resourceId?: string | null
                  }
                | { __typename: "NoMediaInfo"; id: string }
                | {
                    __typename: "VectorGraphicsMediaInfo"
                    id: string
                    svg?: string | null
                    thumbnail?: string | null
                    resourceId?: string | null
                  }
                | { __typename: "VideoMediaInfo"; id: string; thumbnail?: string | null; resourceId?: string | null }
                | null
            }
          | {
              __typename: "MediaGroupContent"
              media?:
                | {
                    __typename: "AnimationMediaInfo"
                    id: string
                    thumbnail?: string | null
                    resourceId?: string | null
                  }
                | {
                    __typename: "ImageMediaInfo"
                    id: string
                    src?: string | null
                    thumbnail?: string | null
                    resourceId?: string | null
                  }
                | { __typename: "NoMediaInfo"; id: string }
                | {
                    __typename: "VectorGraphicsMediaInfo"
                    id: string
                    svg?: string | null
                    thumbnail?: string | null
                    resourceId?: string | null
                  }
                | { __typename: "VideoMediaInfo"; id: string; thumbnail?: string | null; resourceId?: string | null }
                | null
            }
          | { __typename: "ProjectContent" }
          | { __typename: "TermContent" }
          | { __typename: "TextContent" }
          | { __typename: "TextGroupContent" }
          | { __typename: "VariableTableContent" }
          | null
      } | null
    }>
  } | null
}

export type InsertMediaCommandResultQueryVariables = Types.Exact<{
  selectedNode: Types.Scalars["StPatternContextId"]
  aspectId: Types.Scalars["AspectSelectorId"]
}>

export type InsertMediaCommandResultQuery = {
  __typename: "Query"
  commands: Array<
    | { __typename: "AllowedCommand"; command: Types.CommandName; warnings?: Array<string> | null }
    | { __typename: "ForbiddenCommand"; command: Types.CommandName; reasons?: Array<string> | null }
  >
}

export const ImageByTreeResourceTreeBladeDocument = gql`
  query ImageByTreeResourceTreeBlade(
    $selectedNode: StPatternContextId!
    $aspectId: AspectSelectorId!
    $variant: String!
  ) {
    tree(selectedNodes: [$selectedNode], languageId: $aspectId, variant: $variant) {
      nodes {
        id
        node {
          id
          nodeClass {
            classHierarchy
          }
          content {
            ... on IGraphicContent {
              media {
                ... on ImageMediaInfo {
                  id
                  src
                  thumbnail
                  resourceId
                }
                ... on VectorGraphicsMediaInfo {
                  id
                  svg
                  thumbnail
                  resourceId
                }
                ... on AnimationMediaInfo {
                  id
                  thumbnail
                  resourceId
                }
                ... on VideoMediaInfo {
                  id
                  thumbnail
                  resourceId
                }
                ... on NoMediaInfo {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`

/**
 * __useImageByTreeResourceTreeBladeQuery__
 *
 * To run a query within a React component, call `useImageByTreeResourceTreeBladeQuery` and pass it any options that fit your needs.
 * When your component renders, `useImageByTreeResourceTreeBladeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImageByTreeResourceTreeBladeQuery({
 *   variables: {
 *      selectedNode: // value for 'selectedNode'
 *      aspectId: // value for 'aspectId'
 *      variant: // value for 'variant'
 *   },
 * });
 */
export function useImageByTreeResourceTreeBladeQuery(
  baseOptions: Apollo.QueryHookOptions<ImageByTreeResourceTreeBladeQuery, ImageByTreeResourceTreeBladeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ImageByTreeResourceTreeBladeQuery, ImageByTreeResourceTreeBladeQueryVariables>(
    ImageByTreeResourceTreeBladeDocument,
    options,
  )
}
export function useImageByTreeResourceTreeBladeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ImageByTreeResourceTreeBladeQuery,
    ImageByTreeResourceTreeBladeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ImageByTreeResourceTreeBladeQuery, ImageByTreeResourceTreeBladeQueryVariables>(
    ImageByTreeResourceTreeBladeDocument,
    options,
  )
}
export type ImageByTreeResourceTreeBladeQueryHookResult = ReturnType<typeof useImageByTreeResourceTreeBladeQuery>
export type ImageByTreeResourceTreeBladeLazyQueryHookResult = ReturnType<
  typeof useImageByTreeResourceTreeBladeLazyQuery
>
export type ImageByTreeResourceTreeBladeQueryResult = Apollo.QueryResult<
  ImageByTreeResourceTreeBladeQuery,
  ImageByTreeResourceTreeBladeQueryVariables
>
export const InsertMediaCommandResultDocument = gql`
  query InsertMediaCommandResult($selectedNode: StPatternContextId!, $aspectId: AspectSelectorId!) {
    commands(input: [{ command: INSERT_MEDIA }], context: [{ source: [$selectedNode] }], languageId: $aspectId) {
      ... on AllowedCommand {
        command
        warnings
      }
      ... on ForbiddenCommand {
        command
        reasons
      }
    }
  }
`

/**
 * __useInsertMediaCommandResultQuery__
 *
 * To run a query within a React component, call `useInsertMediaCommandResultQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsertMediaCommandResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsertMediaCommandResultQuery({
 *   variables: {
 *      selectedNode: // value for 'selectedNode'
 *      aspectId: // value for 'aspectId'
 *   },
 * });
 */
export function useInsertMediaCommandResultQuery(
  baseOptions: Apollo.QueryHookOptions<InsertMediaCommandResultQuery, InsertMediaCommandResultQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<InsertMediaCommandResultQuery, InsertMediaCommandResultQueryVariables>(
    InsertMediaCommandResultDocument,
    options,
  )
}
export function useInsertMediaCommandResultLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InsertMediaCommandResultQuery, InsertMediaCommandResultQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<InsertMediaCommandResultQuery, InsertMediaCommandResultQueryVariables>(
    InsertMediaCommandResultDocument,
    options,
  )
}
export type InsertMediaCommandResultQueryHookResult = ReturnType<typeof useInsertMediaCommandResultQuery>
export type InsertMediaCommandResultLazyQueryHookResult = ReturnType<typeof useInsertMediaCommandResultLazyQuery>
export type InsertMediaCommandResultQueryResult = Apollo.QueryResult<
  InsertMediaCommandResultQuery,
  InsertMediaCommandResultQueryVariables
>
