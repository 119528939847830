import { useCallback, useEffect, useRef, useState } from "react"

/**
 * Typeguard for {@link Error}
 * @param error The object which might be an {@link Error}.
 * @returns Boolean indicating wether e is an {@link Error} or not
 */
export function isError(error: unknown): error is Error {
  return error instanceof Error
}

type AsyncHandlerState<TResult> =
  | {
      /** Indicates if promise is still running. */
      loading: false
      /** Indicates if async function was called. */
      triggered: true
      /** The result if the promised resolved. */
      result?: TResult
      /** The error if the promise rejected. */
      error?: Error | string | true
    }
  | {
      /** Indicates if promise is still running. */ loading: true
      /** Indicates if async function was called. */ triggered: true
    }
  | {
      /** Indicates if promise is still running. */ loading: false
      /** Indicates if async function was called. */ triggered: false
    }

type PromiseResult<TPromise> = TPromise extends Promise<infer TReturn> ? TReturn : never

type UseAsyncHandlerResult<TAsyncFn extends (...args: any[]) => Promise<PromiseResult<ReturnType<TAsyncFn>>>> = [
  /** The state of the promise */
  AsyncHandlerState<PromiseResult<ReturnType<TAsyncFn>>>,
  /** The trigger for the async function */
  (...args: Parameters<TAsyncFn>) => void,
]

/**
 * Wrap an asnyc funciton to use as react state.
 * @param fn The function to wrap
 * @returns {UseAsyncHandlerResult}
 */
export function useAsyncHandler<TAsyncFn extends (...args: any[]) => Promise<PromiseResult<ReturnType<TAsyncFn>>>>(
  fn?: TAsyncFn,
): UseAsyncHandlerResult<TAsyncFn> {
  const unmounted = useRef(false)
  const [executionState, setExecutionState] = useState<AsyncHandlerState<PromiseResult<ReturnType<TAsyncFn>>>>({
    loading: false,
    triggered: false,
  })

  const execute = useCallback(
    (...args: Parameters<TAsyncFn>) => {
      setExecutionState({ loading: true, triggered: true })
      fn?.(...args)
        .then((result) => !unmounted.current && setExecutionState({ loading: false, triggered: true, result }))
        .catch((e) => {
          if (unmounted.current) return
          if (isError(e)) setExecutionState({ loading: false, triggered: true, error: e })
          else if (typeof e === "string") setExecutionState({ loading: false, triggered: true, error: e })
          else setExecutionState({ loading: false, triggered: true, error: true })
        })
    },
    [fn],
  )

  useEffect(() => {
    return () => {
      unmounted.current = true
    }
  }, [])
  return [executionState, execute]
}
