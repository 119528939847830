import * as React from "react"
import { SVGProps } from "react"

const SvgUser = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="1em" height="1em" {...props}>
    <path d="M320 128a96 96 0 1 0-192 0 96 96 0 1 0 192 0zm-224 0a128 128 0 1 1 256 0 128 128 0 1 1-256 0zM32 480h384c-1.2-79.7-66.2-144-146.3-144h-91.4c-80 0-145 64.3-146.3 144zm-32 2.3C0 383.8 79.8 304 178.3 304h91.4c98.5 0 178.3 79.8 178.3 178.3 0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3z" />
  </svg>
)

export default SvgUser
