import React, { useEffect, useMemo } from "react"
import { keys } from "@st4/ui-strings"
import { i18n } from "@schema/utils"
import { Translate } from "@st4/i18n"
import { isTypename } from "@st4/graphql"
import {
  ImageByTreeResourceTreeBladeQuery,
  InsertMediaCommandResultQuery,
  useImageByTreeResourceTreeBladeQuery,
  useInsertMediaCommandResultQuery,
} from "./query.hooks"
import { Checkbox, Select } from "antd"
import styled from "styled-components"
import { graphicType } from "./types"
import { NotificationInfo } from "../types"
import { v4 as uuidv4 } from "uuid"

type ResourceTreeDataRequest = {
  selectedNode: string
  aspectId: string
  variant: string
  ignoreIcons: boolean
  includeNodeClassHierarchy: boolean
}

type ResourceTreeActionBarProps = {
  selectedNode?: {
    id: string
    classHierarchy: string[]
  }
  aspectId: string
  variant: string
  allowedOperations: string[]
  setNotifications: (notifications: NotificationInfo[]) => void
  setNoDataAvailable: (noDataAvailable: boolean) => void
  setInsertGraphicMessage: (insertMessage: Messages) => void
}

type Messages =
  | {
      action: "onNodeSelected"
      payload: {
        id: string
        classHierarchy: string[]
      }
    }
  | {
      action: "onClose"
      payload: any
    }
  | {
      action: "insertImage"
      payload: {
        imageId: string
        imageType?: graphicType
        insertLegendTable: boolean
      }
    }

const Container = styled.div`
  padding: 24px;
  flex: 0;
  border-top: 1px solid #ecedee;
`

const ImageSelectionContainer = styled.div`
  display: flex;
`

const FormfieldsContainer = styled.div`
  flex: 2;
  margin-left: 16px;
`

const ThumbnailContainer = styled.div`
  flex: 1;
  align-items: center;
  justify-content: center;
  display: flex;
`

const Thumbnail = styled.img`
  flex: none;
  height: 124px;
  width: 124px;
  object-fit: contain;
  margin: 0 auto;
`

const ThumbnailEmpty = styled.div`
  flex: none;
  height: 124px;
  width: 124px;
  object-fit: contain;
  margin: 0 auto;
  background: #ccc;
`

export function ResourceTreeActionBar(props: ResourceTreeActionBarProps) {
  const {
    selectedNode,
    allowedOperations,
    aspectId,
    variant,
    setNotifications,
    setNoDataAvailable,
    setInsertGraphicMessage,
  } = props

  const request: ResourceTreeDataRequest = {
    selectedNode: selectedNode?.id ?? "",
    aspectId: aspectId!,
    ignoreIcons: true,
    variant: variant,
    includeNodeClassHierarchy: true,
  }

  const { data, loading } = useImageByTreeResourceTreeBladeQuery({
    variables: request,
    fetchPolicy: "cache-and-network",
    errorPolicy: "all",
    skip: !selectedNode || selectedNode.classHierarchy.at(-1) === "ResourceFolder",
  })

  const currentNodeId = getCurrentNodeId(data)

  const { data: commandData, loading: commandLoading } = useInsertMediaCommandResultQuery({
    variables: { selectedNode: currentNodeId, aspectId: aspectId! },
    fetchPolicy: "no-cache",
    skip: !currentNodeId,
  })

  const notifications = useMemo(() => {
    return getNotificationInfo({
      data,
      commandData,
      selectedNode,
      allowedOperations,
    })
  }, [allowedOperations, commandData, data, selectedNode])

  useEffect(() => {
    if (notifications) setNotifications(notifications)
  }, [notifications, setNotifications, selectedNode])

  useEffect(() => {
    const noDataAvailable = loading || commandLoading || !data
    setNoDataAvailable(noDataAvailable)
  }, [commandLoading, data, loading, setNoDataAvailable])

  const thumbnail = getThumbnail(data)

  const graphicTypesList = [
    { value: "img_original", label: <Translate>{keys.label.imagetype.img_original}</Translate> },
    { value: "img_fixed_column", label: <Translate>{keys.label.imagetype.img_fixed_column}</Translate> },
    { value: "img_fixed_textarea", label: <Translate>{keys.label.imagetype.img_fixed_textarea}</Translate> },
    { value: "img_margin", label: <Translate>{keys.label.imagetype.img_margin}</Translate> },
    { value: "img_50", label: <Translate>{keys.label.imagetype.img_50}</Translate> },
    { value: "img_80", label: <Translate>{keys.label.imagetype.img_80}</Translate> },
    { value: "img_free", label: <Translate>{keys.label.imagetype.img_free}</Translate> },
  ]

  const [graphicType, setGraphicType] = React.useState<graphicType>("img_original")

  const [lastNodeSelected, setLastNodeSelected] = React.useState("")

  const [insertLegendTable, setInsertLegendTable] = React.useState(false)

  if (selectedNode && lastNodeSelected != selectedNode?.id) {
    setLastNodeSelected(selectedNode.id)
  }

  const handleGraphicTypeChange = (value: graphicType) => {
    setGraphicType(value)
  }

  useEffect(() => {
    const insertImageMsg: Messages = {
      action: "insertImage",
      payload: {
        imageId: selectedNode?.id ?? "",
        imageType: graphicType,
        insertLegendTable: insertLegendTable,
      },
    }
    setInsertGraphicMessage(insertImageMsg)
  }, [graphicType, selectedNode?.id, setInsertGraphicMessage, insertLegendTable])

  const handleInsertLegendTableChange = () => {
    setInsertLegendTable(!insertLegendTable)
  }

  return (
    <>
      <Container>
        <ImageSelectionContainer>
          <ThumbnailContainer data-testid="resourcetree:image:preview">
            {thumbnail ? <Thumbnail src={thumbnail} alt="" /> : <ThumbnailEmpty />}
          </ThumbnailContainer>
          <FormfieldsContainer>
            <fieldset style={{ marginTop: "2em" }}>
              <div>
                <Translate>{keys.label.resourceTree.graphicType}</Translate>
              </div>
              <Select
                data-testid="resourcetree:image:type:selection"
                style={{ width: "100%" }}
                value={graphicType}
                options={graphicTypesList}
                onChange={handleGraphicTypeChange}
                dropdownMatchSelectWidth={false}
              />
            </fieldset>
            <Checkbox style={{ marginTop: "3px" }} onChange={handleInsertLegendTableChange}>
              <Translate>{keys.label.resourceTree.insertLegendTable}</Translate>
            </Checkbox>
          </FormfieldsContainer>
        </ImageSelectionContainer>
      </Container>
    </>
  )
}

type getNotificationInfoProps = {
  data: ImageByTreeResourceTreeBladeQuery | undefined
  commandData: InsertMediaCommandResultQuery | undefined
  selectedNode?: {
    id: string
    classHierarchy: string[]
  }
  allowedOperations: string[]
}

function getCurrentNodeId(data: ImageByTreeResourceTreeBladeQuery | undefined) {
  return data?.tree?.nodes.find((x) => x.node?.nodeClass.classHierarchy.at(-1) !== "GraficGroup")?.id
}

function getNotificationInfo(props: getNotificationInfoProps): NotificationInfo[] {
  if (props.selectedNode?.classHierarchy.at(-1) === "ResourceFolder") {
    return [
      {
        id: uuidv4(),
        nodeId: props.selectedNode?.id ?? "",
        title: "",
        description: i18n.t(keys.message.resourcePoolBlade.folder).toString(),
        severity: "info",
        disableInsert: true,
      },
    ]
  }

  if (!isOperationAllowed(props.allowedOperations)) {
    return [
      {
        id: uuidv4(),
        nodeId: props.selectedNode?.id ?? "",
        title: "",
        description: i18n.t(keys.message.resourcePoolBlade.not_possible).toString(),
        severity: "error",
        disableInsert: true,
      },
    ]
  }

  if (props.selectedNode?.classHierarchy.at(-1) === "GraficGroup" && !props.commandData)
    return [
      {
        id: uuidv4(),
        nodeId: props.selectedNode?.id ?? "",
        title: "",
        description: i18n.t(keys.message.resourcePoolBlade.noslot).toString(),
        severity: "error",
        disableInsert: true,
      },
    ]

  const commandInfo = props.commandData?.commands
  let disableInsert = true
  let userNotifications: NotificationInfo[] = []

  if (commandInfo) {
    const insertMediaCommand = commandInfo.find((x) => x.command === "INSERT_MEDIA")

    if (isTypename("AllowedCommand")(insertMediaCommand)) {
      disableInsert = false
    }

    const notificationTexts = isTypename("AllowedCommand")(insertMediaCommand)
      ? insertMediaCommand.warnings
      : insertMediaCommand?.reasons

    if (notificationTexts && notificationTexts.length > 0) {
      userNotifications = notificationTexts
        .map((reason) => {
          const notificationText = reason.toLocaleLowerCase()
          if (!Object.keys(keys.message.resourcePoolBlade).includes(notificationText)) return

          const notificationKey = notificationText as keyof typeof keys.message.resourcePoolBlade
          const localizedText = i18n.t(keys.message.resourcePoolBlade[notificationKey]).toString()

          return {
            title: "",
            description: localizedText,
            severity: disableInsert ? "error" : "warning",
            disableInsert,
          }
        })
        .filter((notification): notification is NotificationInfo => !!notification)
    }
  }

  return userNotifications
}

function isOperationAllowed(allowedOperations: string[]) {
  if (!allowedOperations || !allowedOperations.find((x) => x === "insert-image")) return false

  return true
}

function getThumbnail(data: ImageByTreeResourceTreeBladeQuery | undefined): string | undefined {
  const treeNode = data?.tree?.nodes[0]
  if (!isTypename("ST4TreeNode")(treeNode)) return undefined

  if (!treeNode.node || !isTypename("ST4Node")(treeNode.node)) return undefined

  const content = treeNode.node.content
  if (!(isTypename("MediaContent")(content) || isTypename("MediaGroupContent")(content))) return undefined

  if (
    isTypename("ImageMediaInfo")(content.media) ||
    isTypename("VectorGraphicsMediaInfo")(content.media) ||
    isTypename("AnimationMediaInfo")(content.media) ||
    isTypename("VideoMediaInfo")(content.media)
  ) {
    return content.media.thumbnail ?? undefined
  }
}
