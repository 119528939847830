import { SearchFacetFragment, SearchFacetItemFragment } from "../graphql/query.hooks"

export type SearchFacet = {
  key: string
  title: string
  type: number
  count: number
  items: SearchFacetItem[] | null
}

export type SearchFacetItem = {
  label: string
  value: string
  count: number
  items: SearchFacetItem[] | null
}

export function fromGraphQLSearchFacet(searchFacet: SearchFacetFragment): SearchFacet {
  return {
    key: searchFacet.key,
    title: searchFacet.title,
    type: searchFacet.type,
    count: searchFacet.count,
    items:
      !!searchFacet.items && searchFacet.items.length > 0 ? searchFacet.items.map(fromGraphQLSearchFacetItem) : null,
  } as SearchFacet
}

function fromGraphQLSearchFacetItem(searchFacetItem: SearchFacetItemFragment): SearchFacetItem {
  return {
    label: searchFacetItem.label,
    value: searchFacetItem.value,
    count: searchFacetItem.count,
    items:
      !!searchFacetItem.items && searchFacetItem.items.length > 0
        ? searchFacetItem.items.map(fromGraphQLSearchFacetItem)
        : null,
  } as SearchFacetItem
}
