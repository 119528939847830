import * as React from "react"
import { SVGProps } from "react"

const SvgSt4InsertembeddingLight = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1em" height="1em" {...props}>
    <path d="M242.4 437.1c0-41.4 33.5-74.9 74.9-74.9h30c8.3 0 15 6.7 15 15s-6.7 15-15 15h-30c-24.8 0-44.9 20.1-44.9 44.9s20.1 44.9 44.9 44.9h30c8.3 0 15 6.7 15 15s-6.7 15-15 15h-30c-41.4 0-74.9-33.5-74.9-74.9zm269.6 0c0 41.4-33.5 74.9-74.9 74.9h-30c-8.3 0-15-6.7-15-15s6.7-15 15-15h30c24.8 0 44.9-20.1 44.9-44.9s-20.1-44.9-44.9-44.9h-30c-8.3 0-15-6.7-15-15s6.7-15 15-15h30c41.4 0 74.9 33.5 74.9 74.9zm-179.7-15h89.9c8.3 0 15 6.7 15 15s-6.7 15-15 15h-89.9c-8.3 0-15-6.7-15-15s6.7-15 15-15zM222 478.5h-94.6c-15.4 0-30.9-12.9-30.9-30.9V61.7c0-15.4 12.9-30.9 30.9-30.9h126V144c0 28.3 23.2 48.9 48.9 48.9h113.2v141.8h33.4V162.1c0-12.9-5.1-23.2-15.4-33.4L317.7 12.9C310 5.1 297.2 0 284.3 0H127.4c-36 0-64.3 28.3-64.3 64.3v383.3c0 36 28.3 64.3 64.3 64.3h116.8c-9.6-9.4-17.2-20.8-22.2-33.4zm67.4-445.1c2.6 2.6 5.1 2.6 7.7 5.1l115.8 115.8s2.6 2.6 5.1 7.7H304.9c-7.7 0-15.4-7.7-15.4-15.4V33.4h-.1zm-74.1 409.7V431c0-38.8 23-72.3 56.1-87.6v-14.2l79.7-28.3v33.7h33.4v-59.5c0-12.9-7.7-25.7-20.6-30.9l-97.7-36c-5.1-2.6-12.9-2.6-20.6 0L148 241.8c-12.9 5.1-20.6 18-20.6 30.9v123.5c0 12.9 7.7 25.7 20.6 33.4l68.1 25.1c-.5-3.8-.8-7.7-.8-11.6zM256 239.2l87.5 30.9L256 301l-87.5-30.9 87.5-30.9z" />
  </svg>
)

export default SvgSt4InsertembeddingLight
