import React, { PropsWithChildren } from "react"
import styled, { css } from "styled-components"
import { Box, Block, Structure, pageLayout, Node, Label } from "./structure"
import { Text } from "../../selection/Text"
import { XastRendererProps } from "../XASTRenderer"
import { useTranslation } from "react-i18next"
import { Inline } from "./Inline"
import { keys } from "@st4/ui-strings"

export const InlineBox = styled(Box)`
  display: inline;
`
export const ImageContainer = styled.figure`
  margin-left: 0px;
  margin-right: 0px;
`

const LineBreakContainer = styled.div`
  display: inline-block;
  padding: 0 1ex;
  color: ${({ theme }) => theme.greys[400]};
`
export function LineBreak() {
  return (
    <>
      <LineBreakContainer>{String.fromCharCode(8626)}</LineBreakContainer>
      <br />
    </>
  )
}

const TabContainer = styled.div`
  display: inline-block;
  padding: 0 1ex;
  color: ${({ theme }) => theme.greys[400]};
`
export function Tab() {
  return (
    <>
      <TabContainer>{String.fromCharCode(11134)}</TabContainer>
    </>
  )
}

const PageBreakContainer = styled.div`
  display: flex;
  color: ${({ theme }) => theme.greys[400]};
  margin: 1em 0;
  & .line {
    flex: 1;
    hr {
      margin-top: 0.7em;
      border: 0;
      border-top: 1px solid ${({ theme }) => theme.greys[300]};
    }
  }
  & .text {
    padding: 0 2ex;
    flex: 0 0 auto;
  }
`
export function PageBreak() {
  const { t } = useTranslation()
  return (
    <PageBreakContainer>
      <div className="line">
        <hr />
      </div>
      <div className="text">{t(keys.label.preview.pagebreak)}</div>
      <div className="line">
        <hr />
      </div>
    </PageBreakContainer>
  )
}

export const LeftMargin = styled.div`
  font-weight: bold;
  ${pageLayout.left}
`

export const Section = styled(Block)`
  background: white;
  position: relative;
  padding-top: ${pageLayout.pageBorderWidth};
  padding-bottom: ${pageLayout.pageBorderWidth};
  ${pageLayout.right}
  & & {
    border: 0;
    padding-left: 0;
    padding-right: 0;
  }
`

export { Heading } from "./Heading"
export { TextBlock } from "./textblock"

export const Link = styled(Inline)`
  display: inline;
  cursor: pointer;
  background: inherit;
  border: none;
  color: ${(p) => p.theme.primaryColor};
  border-bottom: 1px solid ${(p) => p.theme.primaryColor};
`

export const PartDisplay = styled(Box)`
  background: white;
  position: relative;
`

export { NodePage } from "./NodePage"
export * from "./lists"
export * from "./tables"
export { IndexEntryMapper } from "./IndexEntry"
export { Block, Structure, Text, Node, Label }
export { SafetyInstruction, SafetyInstructionMapper, EmbeddedSafetyInstruction } from "./safetyinstructions"
export { ImageDisplay, InlineImage, ImageTitle } from "./image/index"
export { Inline, InlineWithAttributes } from "./Inline"
export { XASTViewer } from "./XASTViewer"
export {
  ProceduralInstructions,
  ProceduralInstructionsMapper,
  SingleProceduralInstructionItem,
} from "./ProceduralInstruction"
export { EmphasisElement, EmphasisDisplay } from "./Emphasis"
export { Code } from "./Code"
export * from "./links"
export { Variable } from "./Variable"
export { LexiconLink } from "./LexiconLink"
export { MetaRef } from "./metaRef"

const StyledTree = styled.div``
const StyledTreeChildren = styled.div`
  margin-left: 1em;
`
const StyledTreeLabel = styled.div``

export const Tree = (props: PropsWithChildren<XastRendererProps>) => {
  return (
    <StyledTree>
      <StyledTreeLabel>&lt;{props.xml.nodeName}&gt;</StyledTreeLabel>
      <StyledTreeChildren>{props.children}</StyledTreeChildren>
      <StyledTreeLabel>&lt;/{props.xml.nodeName}&gt;</StyledTreeLabel>
    </StyledTree>
  )
}
