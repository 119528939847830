import { notEmpty } from "../utilities"

export type Rect = {
  left: number
  top: number
  width: number
  height: number
  right: number
  bottom: number
}

function getRectanglesRelativeToElement(parentRect: DOMRect, clientrect: DOMRect) {
  const left = clientrect.left - parentRect.left
  const top = clientrect.top - parentRect.top
  const rect = {
    left,
    top,
    width: clientrect.width,
    height: clientrect.height,
    right: left + clientrect.width,
    bottom: top + clientrect.height,
  }
  return rect
}

export function calculateGlyphMap(container: HTMLElement, text: string, startOffset: number) {
  const glyphs: { character: string; offset: number; clientRect: Rect; index: number }[] = []
  let childNodeOffset = startOffset
  container.childNodes.forEach((node, key, parent) => {
    const textNode = node.childNodes[0]
    const textInNode = textNode ? textNode.nodeValue : null
    if (!textInNode) {
      return
    }

    const clientRectInRelationToParent = getRectanglesRelativeToElement.bind(null, container.getBoundingClientRect())

    const characters = textInNode.split("")

    const glyphsToAdd = characters
      .map((glyph, idx, all) => {
        if (idx >= all.length) return null
        const range = document.createRange()

        try {
          range.setStart(textNode, idx)
          range.setEnd(textNode, idx + 1)
        } catch (e) {
          console.warn(e)
          return null
        }

        const clientRects = range.getBoundingClientRect()
        const relativeClientRect = clientRectInRelationToParent(clientRects)
        return {
          index: idx,
          character: glyph,
          offset: idx + childNodeOffset,
          clientRect: relativeClientRect,
        }
      })
      .filter(notEmpty)
    glyphs.push(...glyphsToAdd)
    childNodeOffset += textInNode.length
  })

  return glyphs
}
