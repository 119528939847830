import type { CommentNode } from "@st4/graphql"
import React, { createContext, useContext, useMemo, useState } from "react"
import type { TreeNode } from "../../contentModel/nodeContext"
import { notEmpty } from "../../utilities"
import { useScrollToComment, useScrollToContent } from "../Scrolling"
import type { AnnotationFocusStateContextProps } from "./types"

const AnnotationFocusStateContext = createContext<AnnotationFocusStateContextProps>({
  setFocusedAnnotations: () => {},
  focusedAnnotations: [],
  focusedNodes: [],
  setFocusedNodes: () => {},
  setPrefocusedAnnotations: () => {},
  prefocusedAnnotations: [],
})

/**
 * Provides a context which holds information about currently focused comments
 * @param param0 Props
 */
export function FocusStateContextProvider({ children }: React.PropsWithChildren<Record<string, unknown>>) {
  const [focusedAnnotations, setFocusedAnnotations] = useState<string[]>([])
  const [focusedNodes, setFocusedNodes] = useState<Pick<TreeNode, "id">[]>([])
  const [prefocusedAnnotations, setPrefocusedAnnotations] = useState<string[]>([])

  const scrollToContentCallback = useScrollToContent()
  const scrollToCommentCallback = useScrollToComment()

  function scrollToComment(commentId: string, treeNodeId: string) {
    scrollToCommentCallback({ id: commentId })
    scrollToContentCallback({ outer: treeNodeId, inner: `${commentId}` })
  }

  const commentFocusState = useMemo(
    () => ({
      focusedNodes,
      prefocusedAnnotations,
      focusedAnnotations,
      setFocusedNodes: (nodes: Pick<TreeNode, "id">[]) => {
        scrollToContentCallback({ outer: nodes[0].id })
        setFocusedNodes(nodes)
      },
      setPrefocusedAnnotations: (nodes: { comment: Pick<CommentNode, "id">; treeNode: Pick<TreeNode, "id"> }[]) =>
        setPrefocusedAnnotations(nodes.map((n) => n.comment.id)),
      setFocusedAnnotations: (nodes: { comment: Pick<CommentNode, "id">; treeNode: Pick<TreeNode, "id"> }[]) => {
        setFocusedNodes(() => nodes.map((n) => n.treeNode).filter(notEmpty))
        setFocusedAnnotations(() => nodes.map((n) => n.comment.id))
        scrollToComment(nodes[0].comment.id, nodes[0].treeNode.id)
      },
    }),
    [focusedAnnotations, prefocusedAnnotations, focusedNodes],
  )

  return (
    <AnnotationFocusStateContext.Provider value={commentFocusState}>{children}</AnnotationFocusStateContext.Provider>
  )
}

/**
 * Provides access to currently selected comments
 */
export function useAnnotationFocusStateContext() {
  return useContext(AnnotationFocusStateContext)
}
