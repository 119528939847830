import { useCallback } from "react"
import { ContextMenuOperationWrapper, ContextMenuOperationWrapperProps } from "../types"
import { JobState, useDeleteTreeAllowedLazy, useMoveToRecycleBinJob } from "./moveToRecycleBinJobFacade"
import { useCacheOperations } from "../cacheOperation"
import { useTranslation } from "react-i18next"
import { keys } from "@st4/ui-strings"

export function useMoveToRecycleBinOperationWrapper({
  onOperationSuccess,
  showNotification,
  showConfirm,
  showConfirmableNotification,
}: ContextMenuOperationWrapperProps): ContextMenuOperationWrapper {
  const { t: translate } = useTranslation()
  const queryDeleteTreeAllowed = useDeleteTreeAllowedLazy()
  const { startJob, confirmError, confirmJob, cancelJob } = useMoveToRecycleBinJob()
  const { removeNodeFromCachedTreeQueries, evictContentOfNode, refetchPreviewOutline } = useCacheOperations()

  const confirmOrAbort = useCallback(
    (userConfirmed: boolean, jobState: JobState, currentNodeId: string) => {
      if (jobState.state !== "question") throw "state of job is misaligned"

      if (userConfirmed) {
        removeNodeFromCachedTreeQueries(currentNodeId ?? "")

        if (onOperationSuccess) onOperationSuccess(currentNodeId ?? "")

        confirmJob(jobState.response.jobId).then((value) => {
          if (value?.state === "error") {
            showNotification("error", translate(keys.label.warning.error), value.error.message)
          }
          evictContentOfNode(currentNodeId ?? "")
          refetchPreviewOutline()
        })
      } else {
        cancelJob(jobState.response.jobId)
      }
    },
    [
      removeNodeFromCachedTreeQueries,
      evictContentOfNode,
      refetchPreviewOutline,
      onOperationSuccess,
      confirmJob,
      showNotification,
      translate,
      cancelJob,
    ],
  )

  const confirmErrorMessage = useCallback(
    (jobState: JobState) => {
      confirmError(jobState.state === "jobError" ? jobState.response.jobId : "")
    },
    [confirmError],
  )

  const handleMenuItemClick = useCallback(
    (nodeId: string) => {
      startJob([nodeId]).then((value) => {
        if (value.state === "question") {
          showConfirm("question", value.response.title, value.response.message).then((userConfirmed) =>
            confirmOrAbort(userConfirmed, value, nodeId),
          )
        } else if (value.state === "jobError") {
          showConfirmableNotification(value.response.title, value.response.message).then((_) =>
            confirmErrorMessage(value),
          )
        } else if (value.state === "error") {
          showNotification("error", translate(keys.label.warning.error), value.error.message)
        }
      })
    },
    [
      startJob,
      confirmOrAbort,
      confirmErrorMessage,
      translate,
      showConfirm,
      showConfirmableNotification,
      showNotification,
    ],
  )

  return {
    queryOperationAllowed: queryDeleteTreeAllowed,
    handleMenuItemClick,
  }
}
