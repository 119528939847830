import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import { de, en, fr, ja } from "@st4/ui-strings"

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en,
      de,
      fr,
      ja,
    },
    cache: [],
    detection: {
      // Used for SlimClient embedded in DocuWeb
      // DocuWeb will set this cookie and embedded Slimclient will use this
      // Embedded SlimClient won't change language dynamically after login
      lookupCookie: "__guiLang",
      // Don't persist language to localStorage or similar
      // Instead the correct language is requested from server on each reload
      // LoginPage always uses detected browser language
      caches: [],
      // In DocuWeb Cookie the languageCode is wrapped in ". This needs to be removed to be consistent with other values
      convertDetectedLanguage: (lng: string) => lng.replace(/"/g, ""),
    },
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
      formatSeparator: ",",
      format: function (value, format, lng) {
        if (format === "uppercase") return value.toUpperCase()
        return value
      },
    },
    react: {
      useSuspense: false,
      wait: true,
    },
  })

export default i18n
