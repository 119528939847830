import React, { useMemo } from "react"
import { Button, Space, Spin } from "antd"
import { TransitionOptions } from "../definition/transitionOptions"
import { isError, useAsyncHandler } from "../hooks/useAsyncHandler"
import { TransitionInfo } from "../taskcontext/Screen"
import { NotificationBanner, Blade } from "@st4/ui"

type TransitionActionsProps = {
  transitionInfo: TransitionInfo<Extract<TransitionOptions, { type: "blade" }>>
}

export function TransitionActionbar(props: TransitionActionsProps) {
  const [transitionState, followTransition] = useAsyncHandler(props.transitionInfo?.followTransition)

  const hasError = !transitionState.loading && transitionState.triggered && transitionState.error

  const btns = useMemo(
    () =>
      props.transitionInfo.transitions.map((trans) => ({
        ...trans.options,
        key: trans.name,
        onClick() {
          followTransition(trans.name)
        },
      })),
    [followTransition, props.transitionInfo.transitions],
  )

  if (hasError)
    return (
      <NotificationBanner
        state="error"
        title="Cannot Follow Transition" // TODO: Fehlermeldung so i.O.?
        description={isError(transitionState.error) ? transitionState.error.message : transitionState.error}
      />
    )
  else
    return (
      <Spin delay={250} spinning={transitionState.loading}>
        <Blade.Actionbar>
          <Actionbar buttons={btns} />
        </Blade.Actionbar>
      </Spin>
    )
}

type ActionbarProps = {
  buttons: {
    key: string
    label: React.ReactNode
    icon?: React.ReactNode
    onClick: () => void
    primary?: boolean
    align?: "left" | "right"
  }[]
}

export function Actionbar({ buttons }: ActionbarProps) {
  const leftButtons = buttons.filter((t) => t.align === "left")
  const rightButtons = buttons.filter((t) => t.align !== "left")

  return (
    <>
      <Space>
        {leftButtons.map((btn) => (
          <Button icon={btn.icon} type={btn.primary ? "primary" : "default"} key={btn.key} onClick={btn.onClick}>
            {btn.label}
          </Button>
        ))}
        &nbsp;{/* <Space> doesn't render if empty, happens without left buttons */}
      </Space>
      <Space>
        {rightButtons.map((btn) => (
          <Button icon={btn.icon} type={btn.primary ? "primary" : "default"} key={btn.key} onClick={btn.onClick}>
            {btn.label}
          </Button>
        ))}
      </Space>
    </>
  )
}
