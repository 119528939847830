import * as Types from "@st4/graphql"

import { gql } from "@apollo/client"
import * as Apollo from "@apollo/client"
const defaultOptions = {} as const
export type CurrentUserQueryVariables = Types.Exact<{ [key: string]: never }>

export type CurrentUserQuery = {
  __typename: "Query"
  me: {
    __typename: "User"
    account?:
      | { __typename: "Error"; message: string }
      | {
          __typename: "UserAccountSuccess"
          result: {
            __typename: "UserAccount"
            type: string
            userName: any
            displayName: string
            displayLanguage: string
            isAbsent: boolean
            disabled: any
            isDeleted: boolean
            groups: Array<{ __typename: "UserGroup"; groupName: string }>
          }
        }
      | null
  }
}

export const CurrentUserDocument = gql`
  query CurrentUser {
    me {
      account {
        ... on UserAccountSuccess {
          result {
            type
            userName
            displayName
            displayLanguage
            isAbsent
            disabled
            isDeleted
            groups {
              groupName
            }
          }
        }
        ... on IError {
          message
        }
      }
    }
  }
`

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(
  baseOptions?: Apollo.QueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options)
}
export function useCurrentUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options)
}
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>
export type CurrentUserQueryResult = Apollo.QueryResult<CurrentUserQuery, CurrentUserQueryVariables>
