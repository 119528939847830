import React from "react"
import styled from "styled-components"
import { Layout } from "antd"

type ScreenProps = {
  children: React.ReactNode
}

const StyledScreen = styled(Layout)`
  height: 100vh;
  width: 100vw;
`

export function Screen({ children }: ScreenProps) {
  return <StyledScreen>{children}</StyledScreen>
}
