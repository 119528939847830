import { ApolloError } from "@apollo/client"
import React, { useEffect } from "react"
import { IMessage } from "@st4/graphql"
import { useTranslation } from "react-i18next"
import { getEditCheckMessageKey } from "./messageHelpers/getEditCheckMessageKey"
import { createTranslationArgs } from "@st4/ui-strings"
import { MessageHub, MessageTypeFromHub } from "@st4/message-hub"
import { ContentMessage } from "../../../../types"

type EditorErrorViewProps = {
  sessionError: IMessage[]
  messageHub: MessageHub<Extract<ContentMessage, { action: "saveAndCloseEditor" | "editingFinished" }>>
  error: ApolloError | undefined
}

export function EditorErrorView({ sessionError, error, messageHub }: EditorErrorViewProps) {
  const { t } = useTranslation()

  //Reply to saveAndCloseEditor message with editing finished, because Fonto isn't there to answer.
  useEffect(() => {
    function fontoPostMessageCallback(msg: MessageTypeFromHub<typeof messageHub>) {
      if (msg.action === "saveAndCloseEditor") {
        messageHub.sendMessage({
          action: "editingFinished",
          payload: {
            inplace: false,
            selection: {
              nodeId: msg.payload.nodeId,
              treeNodeId: "",
            },
          },
        })
      }
    }
    messageHub.observe(fontoPostMessageCallback)
    return () => {
      messageHub.unobserve(fontoPostMessageCallback)
    }
  }, [messageHub])

  if (error)
    return (
      <div
        style={{
          width: "100%",
          padding: "30px 10px 30px 30px",
          background: "white",
          fontWeight: "bold",
          margin: "7px 0px 0px 0px",
        }}
      >
        <div>{error.message}</div>
      </div>
    )

  //Log until we can display errors to the user
  sessionError.forEach((sessionErr) => console.log(sessionErr?.messageKey, sessionErr?.message))
  const sessionErrorString = sessionError
    .map((err) =>
      t(getEditCheckMessageKey(err.messageKey), createTranslationArgs(err.messageArgs?.map((arg) => arg.value) ?? [])),
    )
    .join("\n")
  return (
    <div
      style={{
        width: "100%",
        padding: "30px 10px 30px 30px",
        background: "white",
        whiteSpace: "pre-wrap",
        fontWeight: "bold",
      }}
    >
      <div>{sessionErrorString}</div>
    </div>
  )
}
