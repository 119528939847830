import React, { useMemo } from "react"
import { ContentWidthLimiting, NodeHeader, NodeWrapper, useIsNodeSelected } from "./utils"
import { Descriptions } from "@schema/styled-ui"
import { isST4NodeWithContent } from "../../graphql/applicationQueries"
import styled from "styled-components"
import { Col, Image, Row, Table } from "antd"
import { XASTViewer } from "../contentComponents"
import { useTranslation } from "react-i18next"
import { notEmpty } from "../../utilities"
import { keys } from "@st4/ui-strings"
import { GetMediaSource } from "@st4/content-tools"
import type { NodeRenderProps } from "./types"
import { isTypename } from "@st4/graphql"

const DescriptionText = styled.div`
  padding: 0 8px;
  margin-bottom: 8px;
`

const ImageCol = styled(Col)`
  display: flex;
  max-width: 15vw;
  justify-content: center;
  align-items: center;
  padding-left: 16px;
`

export function LexiconNode({ node, treeNode }: NodeRenderProps) {
  const { t } = useTranslation()
  const selected = useIsNodeSelected(treeNode)
  if (!isST4NodeWithContent("LexiconEntryContent")(node)) throw new Error("Unexpected Content type in Node")
  const lexiconContent = node.content
  const additionalInformation = useMemo(
    () =>
      [
        lexiconContent.subject
          ? { label: t(keys.label.preview.lexicon.subjectField), content: lexiconContent.subject }
          : null,
        lexiconContent.sortKey
          ? { label: t(keys.label.general.indexentry.sort), content: lexiconContent.sortKey }
          : null,
      ].filter(notEmpty),
    [lexiconContent.subject, lexiconContent.sortKey, t],
  )
  const description = lexiconContent.descriptionXast
    ? JSON.parse(lexiconContent.descriptionXast).children[0].children[0]
    : null
  const imageData =
    isTypename("ST4Node")(lexiconContent.image) && isTypename("MediaContent")(lexiconContent.image.content)
      ? lexiconContent.image.content?.media
      : undefined
  const imageSrc = imageData && GetMediaSource(imageData)
  const termNodes = lexiconContent.terms
  const terms = termNodes
    .filter(isTypename("ST4Node"))
    .filter(isST4NodeWithContent("TermContent"))
    .map((n) => ({
      label: n.label,
      validity: n.content.validity,
      gramaticalGender: n.content.gramaticalGender,
      editLanguage: n.content.editLanguage,
    }))

  return (
    <ContentWidthLimiting>
      <NodeWrapper selected={selected}>
        <NodeHeader icon={treeNode.icon} title={node.label} node={treeNode} />
        <Row wrap={false} style={{ maxWidth: "50vw" }}>
          <Col flex={4}>
            {!!description && (
              <DescriptionText>
                <XASTViewer xast={description} />
              </DescriptionText>
            )}
            {!!additionalInformation.length && (
              <div style={{ marginBottom: 16 }}>
                <Descriptions
                  column={1}
                  size="small"
                  bordered
                  dataSource={additionalInformation}
                  itemStyle={{ padding: 8 }}
                />
              </div>
            )}
            <div>
              {!!terms.length && (
                <Table
                  size="small"
                  bordered
                  dataSource={terms}
                  pagination={{ hideOnSinglePage: true, pageSize: 5 }}
                  columns={[
                    { title: t(keys.label.preview.lexicon.term), dataIndex: "label", key: "label" },
                    { title: t(keys.label.preview.lexicon.validity), dataIndex: "validity", key: "validity" },
                    {
                      title: t(keys.label.preview.lexicon.grammaticalGender),
                      dataIndex: "gramaticalGender",
                      key: "gramaticalGender",
                    },
                    {
                      title: t(keys.label.generic.language),
                      dataIndex: "editLanguage",
                      key: "editLanguage",
                    },
                  ]}
                />
              )}
            </div>
          </Col>
          {!isTypename("NoMediaInfo")(imageData) && imageSrc && (
            <ImageCol flex={1}>
              <Image
                src={imageSrc}
                preview={false}
                placeholder={<Image preview={false} src={imageData?.thumbnail ?? ""} width="100%" />}
              />
            </ImageCol>
          )}
        </Row>
      </NodeWrapper>
    </ContentWidthLimiting>
  )
}
