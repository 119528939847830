import React, { useEffect, useState } from "react"
import { Input } from "@st4/ui"
import { Icon, Light } from "@st4/icons"

type StringEditorProps = {
  value?: string
  isReadonly?: boolean
  suffixIcon?: React.ReactNode
  onSubmit: (value: string) => void
  errorMessage?: string
}

export function StringEditor({ value, isReadonly, suffixIcon, onSubmit, errorMessage }: StringEditorProps) {
  const originalValue = value ?? ""
  const [inputValue, setInputValue] = useState(value ?? "")

  const dirty = value != inputValue

  useEffect(() => {
    setInputValue(value ?? "")
  }, [value])

  function handleKeyup(ev: React.KeyboardEvent<HTMLInputElement>) {
    if (ev.key === "Escape") {
      setInputValue(originalValue)
    }
  }

  const submitHandler = (value: string) => {
    if (dirty) onSubmit(value)
  }

  if (!suffixIcon && !isReadonly) {
    suffixIcon = <Icon component={Light.Pen} />
  }

  return (
    <Input
      dirty={dirty}
      readonly={isReadonly}
      value={inputValue}
      onChange={(e) => setInputValue(e.target.value)}
      onSubmit={() => submitHandler(inputValue)}
      suffixIcon={suffixIcon}
      errorMessage={errorMessage}
      onKeyUp={handleKeyup}
    />
  )
}

export function FallbackEditor(props: { displayValue: string }) {
  return <Input readonly value={props.displayValue ?? ""} />
}
