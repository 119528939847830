import * as React from "react"
import { SVGProps } from "react"

const SvgSt4FileMessageQuestionLight = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1em" height="1em" {...props}>
    <path d="M63 64.3C63 28.3 91.3 0 127.4 0h156.9c12.9 0 25.7 5.1 33.4 12.9l115.8 115.8c10.3 10.3 15.4 20.6 15.4 33.4v285.6c0 36-28.3 64.3-64.3 64.3H127.4c-36 0-64.3-28.3-64.3-64.3V64.3zM415.5 193H302.3c-25.7 0-48.9-20.6-48.9-48.9V30.9h-126c-18 0-30.9 15.4-30.9 30.9v385.9c0 18 15.4 30.9 30.9 30.9h257.3c18 0 30.9-15.4 30.9-30.9V193zm-2.6-38.6L297.2 38.6c-2.6-2.6-5.1-2.6-7.7-5.1v113.2c0 7.7 7.7 15.4 15.4 15.4h113.2c-2.6-5.2-5.2-7.7-5.2-7.7zm-285.5 84.9c0-18 15.4-33.4 30.9-33.4h193c18 0 33.4 15.4 33.4 33.4v144.1c0 18-15.4 30.9-30.9 30.9h-72L220 460.5c-2.6 2.6-5.1 2.6-7.7 2.6s-5.1-5.1-5.1-7.7v-41.2h-48.9c-18 0-30.9-15.4-30.9-30.9v-144zm84.9 25.7c-2.6 7.7 0 12.9 7.7 15.4s12.9 0 15.4-7.7c0-2.6 2.6-2.6 2.6-2.6h28.3c5.1 0 7.7 2.6 7.7 7.7 0 2.6-2.6 5.1-2.6 7.7l-23.2 12.9c-2.6 2.6-5.1 5.1-5.1 10.3v7.7c0 7.7 5.1 12.9 12.9 12.9 7.7 0 12.9-5.1 12.9-12.9l15.4-10.3c10.3-5.1 15.4-15.4 15.4-28.3 0-18-15.4-30.9-30.9-30.9H238c-10.3.1-20.6 7.8-25.7 18.1zm59.1 92.6c0-7.7-7.7-15.4-15.4-15.4s-15.4 7.7-15.4 15.4S248.3 373 256 373s15.4-5.1 15.4-15.4z" />
  </svg>
)

export default SvgSt4FileMessageQuestionLight
