import Skeleton from "antd/lib/skeleton"
import React from "react"

type LoadingViewProps = {
  initialHeight: number
}

export function LoadingView({ initialHeight }: LoadingViewProps) {
  return (
    <div style={{ width: 270, height: initialHeight, padding: 30 }} role="loadingIndicator">
      <Skeleton active />
    </div>
  )
}
