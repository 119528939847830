import { makeVar, useReactiveVar } from "@apollo/client"

const OPEN_EDITOR_SESSIONS = makeVar<string[]>([])

function addEditorSession(treeNodeId: string) {
  const currentSessions = OPEN_EDITOR_SESSIONS()
  if (!currentSessions.includes(treeNodeId)) OPEN_EDITOR_SESSIONS([...currentSessions, treeNodeId])
}

function removeEditorSession(treeNodeId: string) {
  const currentSessions = OPEN_EDITOR_SESSIONS()
  if (currentSessions.includes(treeNodeId)) OPEN_EDITOR_SESSIONS([...currentSessions.filter((i) => i !== treeNodeId)])
}

export function useIsEditorSessionOpen(treeNodeId: string) {
  const currentSessions = useReactiveVar(OPEN_EDITOR_SESSIONS)
  return currentSessions.includes(treeNodeId)
}

export async function withOpenEditorSession(action: () => Promise<boolean>, treeNodeId: string) {
  addEditorSession(treeNodeId)
  const response = await action()
  removeEditorSession(treeNodeId)
}
