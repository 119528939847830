import * as React from "react"
import { SVGProps } from "react"

const SvgBooks = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1em" height="1em" {...props}>
    <path d="M48 416v48h64v-48H48zm88 89.6c-7.1 4.1-15.3 6.4-24 6.4H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h64c8.7 0 16.9 2.3 24 6.4 7.1-4.1 15.3-6.4 24-6.4h64c20.6 0 38.1 12.9 45 31.1 5.6-6.1 12.9-10.7 21.4-13l59.5-16.5c24.7-6.8 50.1 8.3 56.7 33.8l18 69.2 6 23.2 61.8 238.3 6 23.2 11.9 46c6.6 25.5-8 51.7-32.7 58.5L418 510.3c-24.7 6.8-50.1-8.3-56.7-33.8l-18-69.2-6-23.2-61.7-238.2-3.6-13.7V464c0 26.5-21.5 48-48 48h-64c-8.7 0-16.9-2.3-24-6.4zm24-41.6h64v-48h-64v48zM112 48H48v48h64V48zm0 96H48v224h64V144zm48-48h64V48h-64v48zm64 272V144h-64v224h64zm216.1-12.3-55.8-215-56.5 15.6 55.8 215 56.5-15.6zm-44.4 62.1 11.9 45.7 56.4-15.6v-.4l-11.7-45.2-56.5 15.6zm-79.9-308 56.5-15.6-11.9-45.7L304 64.1v.4l11.7 45.2z" />
  </svg>
)

export default SvgBooks
