import React from "react"
import styled from "styled-components"

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`

const ListItem = styled.li`
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: auto 1fr;
`
ListItem.displayName = "ListItem"

const BulletPointContainer = styled.span`
  & > * {
    vertical-align: text-top;
  }
  display: inline-block;
  padding-right: 1ch;
`

const ListItemContainer = styled.span`
  display: inline-block;
  overflow: hidden;
  width: 100%;
`

interface ListWithCustomBulletPointsProps {
  listItems: Array<{ key?: string } & any>
  bulletPoint: React.ReactNode
}

export function ListWithCustomBulletPoints({ listItems, bulletPoint }: ListWithCustomBulletPointsProps) {
  return (
    <List>
      {listItems.map((listItem, idx) => (
        <ListItem key={listItem.key || idx}>
          <BulletPointContainer>{bulletPoint}</BulletPointContainer>
          <ListItemContainer>{listItem}</ListItemContainer>
        </ListItem>
      ))}
    </List>
  )
}
