import React from "react"
import { Heading, TextBlock } from "./contentComponents"

import { Skeleton } from "antd"
import {
  NodeActionsContainerPlaceholder,
  NodeWithNodeActionsContainerWrapper,
  NodeWrapper,
} from "./nodeComponents/utils"
import type { TreeNode } from "../contentModel/nodeContext"

export function LoadingNodePreview(props: { treeNode?: TreeNode; title?: string; active?: boolean }) {
  const nodeClassHierarchy = props.treeNode?.node.nodeClass.classHierarchy
  const isTextElement = nodeClassHierarchy?.some((hierarchy) => hierarchy === "TextFolder" || hierarchy === "TextNode")

  if (nodeClassHierarchy && isTextElement) {
    return (
      <NodeWithNodeActionsContainerWrapper>
        <NodeActionsContainerPlaceholder />
        <NodeWrapper
          style={{ width: "100%" }}
          data-node-id={props.treeNode?.node?.id}
          data-scroll-target={props.treeNode?.id ?? ""}
        >
          <Heading level={1}>
            {props.title ?? <Skeleton title={true} paragraph={false} active={props.active} />}
          </Heading>
          <TextBlock>
            <Skeleton title={false} paragraph={{ rows: 5 }} active={props.active} />
          </TextBlock>
        </NodeWrapper>
      </NodeWithNodeActionsContainerWrapper>
    )
  }

  return (
    <NodeWrapper data-node-id={props.treeNode?.node?.id} data-scroll-target={props.treeNode?.id ?? ""}>
      <Heading level={1}>{props.title ?? <Skeleton title={true} paragraph={false} active={props.active} />}</Heading>
      <TextBlock>
        <Skeleton title={false} paragraph={{ rows: 5 }} active={props.active} />
      </TextBlock>
    </NodeWrapper>
  )
}
