import type { RemoveCommentMutation, RemoveCommentMutationHookResult, RemoveCommentMutationResult } from "./query.hooks"
import { useRemoveCommentMutation } from "./query.hooks"
import { isTypename, RemoveCommentInput } from "@st4/graphql"
import {
  CommentNodeFragment,
  CommentNodeFragmentDoc,
  TextContentForCommentFragment,
  TextContentForCommentFragmentDoc,
} from "../CommentList/query.hooks"
import { useApolloClient } from "@apollo/client"
import { ReplaceCommentInCommentNode } from "./utilities"

/**
 * Creates an optimistic result for the addInlineComment mutation based on its input.
 * @param commentToAdd the input to the comment add location
 * @param node the node to which the comment is added
 */
function createOptimisticResult(
  commentToRemove: RemoveCommentInput,
  apolloCache: ReturnType<typeof useApolloClient>["cache"],
): RemoveCommentMutation {
  const commentIdToRemove = `${commentToRemove.nodeId}_${commentToRemove.historyNumber}_${commentToRemove.aspectSelectorId}_${commentToRemove.commentKey}`
  const cacheKey = apolloCache.identify({ __typename: "CommentNode", id: commentIdToRemove })
  const commentNodeFromCache = apolloCache.readFragment<CommentNodeFragment>({
    id: cacheKey,
    fragment: CommentNodeFragmentDoc,
    fragmentName: "CommentNode",
  })
  if (!commentNodeFromCache) {
    return {
      comment: { message: "Couldn't find comment to delete", __typename: "Error" as const },
      __typename: "Mutation" as const,
    }
  }
  const newComment = {
    ...commentNodeFromCache.comment,
    persistanceAction: "REMOVE" as const,
    persistanceState: "LOCAL" as const,
  }

  const result = {
    comment: {
      result: newComment,
      __typename: "CommentSuccess" as const,
    },
    __typename: "Mutation" as const,
  }
  return result
}

export function useRemoveCommentAndSaveLocal(): [
  (input: RemoveCommentInput) => ReturnType<RemoveCommentMutationHookResult[0]>,
  RemoveCommentMutationResult,
] {
  const [removeCommentMutation, removeCommentResult] = useRemoveCommentMutation()
  const { cache: apolloCache } = useApolloClient()
  const patchedMutation = (input: RemoveCommentInput) => {
    const optimisticResult = createOptimisticResult(input, apolloCache)
    return removeCommentMutation({
      variables: {
        input,
      },
      optimisticResponse: optimisticResult,
      update: (cache, { data }) => {
        if (!data) return
        const contentCacheId = cache.identify({ __typename: "TextContent", id: input.nodeId })
        const contentFromCache = cache.readFragment<TextContentForCommentFragment>({
          id: contentCacheId,
          fragment: TextContentForCommentFragmentDoc,
          fragmentName: "TextContentForComment",
        })
        if (!contentFromCache) return

        const commentIdToRemove = `${input.nodeId}_${input.historyNumber}_${input.aspectSelectorId}_${input.commentKey}`
        const commentNodeCacheKey = cache.identify({ __typename: "CommentNode", id: commentIdToRemove })
        if (!commentNodeCacheKey) return

        const existingCommentNode = contentFromCache.comments.find((n) => cache.identify(n) === commentNodeCacheKey)
        if (!existingCommentNode) return
        if (isTypename("Error")(data.comment)) {
          console.error("An error occurred while removing the comment!", data.comment.message)
          const erroneousComment = {
            ...optimisticResult,
            persistanceState: "ERROR",
            persistanceAction: "REMOVE",
          }
          ReplaceCommentInCommentNode(cache, commentNodeCacheKey, erroneousComment as any)
          return
        }
        if (data.comment.result.persistanceState !== "LOCAL") {
          // Comment was set to LOCAL REMOVE before to allow desaturated display
          // Now that mutation was successful it can be deleted entirely

          // The same content with the same id and therefore same content-xml origin
          // can be in a normal node and a group. The comment needs
          // to be deleted in both.
          for (const cacheId of [
            contentCacheId,
            cache.identify({ __typename: "TextGroupContent", id: input.nodeId }),
          ]) {
            cache.modify({
              id: cacheId,
              fields: {
                comments(existingCommentRefs) {
                  return existingCommentRefs.filter((n: Parameters<typeof cache.identify>[0]) => {
                    return commentNodeCacheKey !== cache.identify(n)
                  })
                },
              },
            })
          }
        }
      },
    })
  }
  return [patchedMutation, removeCommentResult]
}
