import React from "react"
import { Collapse, Descriptions, Spin } from "antd"
import { useTranslation } from "react-i18next"
import { keys } from "@st4/ui-strings"
import { usePreviewParameterContext } from "../PreviewParameterContext"
import { PropertyItemFragment, useNodeMetadataQuery } from "./query.hooks"
import { isTypename } from "@st4/graphql"
import { mapFrom } from "../../utilities"
import type { TreeNode } from "../../contentModel/nodeContext"

const { Panel } = Collapse

type NodeMetadataProps = {
  node: TreeNode
}

export function NodeMetadata({ node: treeNode }: NodeMetadataProps) {
  const { languageId } = usePreviewParameterContext()
  const { data, loading } = useNodeMetadataQuery({ variables: { languageId: languageId, treeNodeId: treeNode.id } })
  const { t } = useTranslation()

  if (loading || !data?.node) {
    return <Spin />
  }

  if (!isTypename("ST4TreeNode")(data.node)) {
    return null
  }
  if (!isTypename("PropertyListSuccess")(data.node.properties)) {
    return null
  }

  const propertyNameSet = mapFrom(
    data.node.properties.result,
    (prop) => prop.editor.id,
    (prop) => prop.displayValue,
  )

  const taxonomies = Taxonomies({ properties: data.node.properties.result })

  return (
    <Collapse defaultActiveKey="properties" style={{ overflow: "auto" }}>
      <Panel header={t(keys.label.nodeproperties)} key="properties">
        <Descriptions bordered size={"small"} column={1}>
          <Descriptions.Item label={t(keys.label.general.title)}>{data.node.label}</Descriptions.Item>
          <Descriptions.Item label={t(keys.label.widget.createdBy)}>{propertyNameSet.get("creator")}</Descriptions.Item>
          <Descriptions.Item label={t(keys.label.general.creationTime)}>
            {propertyNameSet.get("roundtripCreationTime") || ""}
          </Descriptions.Item>
          <Descriptions.Item label={t(keys.label.widget.lastChangeBy)}>
            {propertyNameSet.get("contentModificator")}
          </Descriptions.Item>
          <Descriptions.Item label={t(keys.label.general.modificationTime)}>
            {propertyNameSet.get("roundtripContentModificationTime") || ""}
          </Descriptions.Item>
          <Descriptions.Item label={t(keys.label.comment.comment)}>
            {propertyNameSet.get("nodeComment")}
          </Descriptions.Item>
        </Descriptions>
      </Panel>
      <Panel header={t(keys.label.general.taxonomy)} key="taxonomy" collapsible={taxonomies ? "header" : "disabled"}>
        {taxonomies}
      </Panel>
    </Collapse>
  )
}

type TaxonomiesProps = {
  properties: PropertyItemFragment[]
}

function Taxonomies({ properties }: TaxonomiesProps) {
  const taxonomies = properties
    .filter((prop) => prop.editor.id.includes("ObjectToOntologyLink"))
    .filter((ontology) => ontology.displayValue.length)
  // If one node is in one taxonomy multiple times the values are ; seperated
  const getValuesForList = (tax: string) =>
    tax
      .split(";")
      .map((val) => val.trim())
      .filter((val) => val !== "")

  if (!taxonomies.length) return null

  return (
    <Descriptions bordered size="small" column={1}>
      {taxonomies.map((tax) => (
        <Descriptions.Item key={tax.editor.id} label={tax.displayName}>
          <ul>
            {getValuesForList(tax.displayValue).map((value) => (
              <li key={value}>{value}</li>
            ))}
          </ul>
        </Descriptions.Item>
      ))}
    </Descriptions>
  )
}
