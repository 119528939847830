import * as Types from "@st4/graphql"

import { gql } from "@apollo/client"
import { CommentInfoFieldsFragmentDoc } from "../CommentList/query.hooks"
import * as Apollo from "@apollo/client"
const defaultOptions = {} as const
export type AddInlineCommentToNodeMutationVariables = Types.Exact<{
  input: Types.AddInlineCommentInput
}>

export type AddInlineCommentToNodeMutation = {
  __typename: "Mutation"
  comment:
    | {
        __typename: "CommentSuccess"
        result:
          | {
              __typename: "BlockComment"
              location: number
              value?: string | null
              authorDisplayName?: string | null
              authorUserName?: string | null
              commentKey: string
              editDate?: any | null
              modificationVersion?: number | null
              replyToKey?: string | null
              sortOrder?: number | null
              commentType?: string | null
              state?: string | null
              color: string
              persistanceState?: Types.PersistanceState | null
              persistanceAction?: Types.PersistanceAction | null
              node: {
                __typename: "ST4Node"
                aspectId: any
                id: string
                content?:
                  | { __typename: "LexiconContent" }
                  | { __typename: "LexiconEntryContent" }
                  | { __typename: "MediaContent" }
                  | { __typename: "MediaGroupContent" }
                  | { __typename: "ProjectContent" }
                  | { __typename: "TermContent" }
                  | { __typename: "TextContent"; id: string; historyNumber: number }
                  | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                  | { __typename: "VariableTableContent" }
                  | null
              }
              actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
            }
          | {
              __typename: "TextComment"
              endLocation: number
              startLocation?: number | null
              value?: string | null
              authorDisplayName?: string | null
              authorUserName?: string | null
              commentKey: string
              editDate?: any | null
              modificationVersion?: number | null
              replyToKey?: string | null
              sortOrder?: number | null
              commentType?: string | null
              state?: string | null
              color: string
              persistanceState?: Types.PersistanceState | null
              persistanceAction?: Types.PersistanceAction | null
              node: {
                __typename: "ST4Node"
                aspectId: any
                id: string
                content?:
                  | { __typename: "LexiconContent" }
                  | { __typename: "LexiconEntryContent" }
                  | { __typename: "MediaContent" }
                  | { __typename: "MediaGroupContent" }
                  | { __typename: "ProjectContent" }
                  | { __typename: "TermContent" }
                  | { __typename: "TextContent"; id: string; historyNumber: number }
                  | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                  | { __typename: "VariableTableContent" }
                  | null
              }
              actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
            }
      }
    | { __typename: "Error"; message: string }
}

export type RemoveCommentMutationVariables = Types.Exact<{
  input: Types.RemoveCommentInput
}>

export type RemoveCommentMutation = {
  __typename: "Mutation"
  comment:
    | {
        __typename: "CommentSuccess"
        result:
          | {
              __typename: "BlockComment"
              location: number
              value?: string | null
              authorDisplayName?: string | null
              authorUserName?: string | null
              commentKey: string
              editDate?: any | null
              modificationVersion?: number | null
              replyToKey?: string | null
              sortOrder?: number | null
              commentType?: string | null
              state?: string | null
              color: string
              persistanceState?: Types.PersistanceState | null
              persistanceAction?: Types.PersistanceAction | null
              node: {
                __typename: "ST4Node"
                aspectId: any
                id: string
                content?:
                  | { __typename: "LexiconContent" }
                  | { __typename: "LexiconEntryContent" }
                  | { __typename: "MediaContent" }
                  | { __typename: "MediaGroupContent" }
                  | { __typename: "ProjectContent" }
                  | { __typename: "TermContent" }
                  | { __typename: "TextContent"; id: string; historyNumber: number }
                  | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                  | { __typename: "VariableTableContent" }
                  | null
              }
              actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
            }
          | {
              __typename: "TextComment"
              endLocation: number
              startLocation?: number | null
              value?: string | null
              authorDisplayName?: string | null
              authorUserName?: string | null
              commentKey: string
              editDate?: any | null
              modificationVersion?: number | null
              replyToKey?: string | null
              sortOrder?: number | null
              commentType?: string | null
              state?: string | null
              color: string
              persistanceState?: Types.PersistanceState | null
              persistanceAction?: Types.PersistanceAction | null
              node: {
                __typename: "ST4Node"
                aspectId: any
                id: string
                content?:
                  | { __typename: "LexiconContent" }
                  | { __typename: "LexiconEntryContent" }
                  | { __typename: "MediaContent" }
                  | { __typename: "MediaGroupContent" }
                  | { __typename: "ProjectContent" }
                  | { __typename: "TermContent" }
                  | { __typename: "TextContent"; id: string; historyNumber: number }
                  | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                  | { __typename: "VariableTableContent" }
                  | null
              }
              actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
            }
      }
    | { __typename: "Error"; message: string }
}

export type ReplyToCommentMutationVariables = Types.Exact<{
  input: Types.AddReplyCommentInput
}>

export type ReplyToCommentMutation = {
  __typename: "Mutation"
  comment:
    | {
        __typename: "CommentSuccess"
        result:
          | {
              __typename: "BlockComment"
              location: number
              value?: string | null
              authorDisplayName?: string | null
              authorUserName?: string | null
              commentKey: string
              editDate?: any | null
              modificationVersion?: number | null
              replyToKey?: string | null
              sortOrder?: number | null
              commentType?: string | null
              state?: string | null
              color: string
              persistanceState?: Types.PersistanceState | null
              persistanceAction?: Types.PersistanceAction | null
              node: {
                __typename: "ST4Node"
                aspectId: any
                id: string
                content?:
                  | { __typename: "LexiconContent" }
                  | { __typename: "LexiconEntryContent" }
                  | { __typename: "MediaContent" }
                  | { __typename: "MediaGroupContent" }
                  | { __typename: "ProjectContent" }
                  | { __typename: "TermContent" }
                  | { __typename: "TextContent"; id: string; historyNumber: number }
                  | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                  | { __typename: "VariableTableContent" }
                  | null
              }
              actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
            }
          | {
              __typename: "TextComment"
              endLocation: number
              startLocation?: number | null
              value?: string | null
              authorDisplayName?: string | null
              authorUserName?: string | null
              commentKey: string
              editDate?: any | null
              modificationVersion?: number | null
              replyToKey?: string | null
              sortOrder?: number | null
              commentType?: string | null
              state?: string | null
              color: string
              persistanceState?: Types.PersistanceState | null
              persistanceAction?: Types.PersistanceAction | null
              node: {
                __typename: "ST4Node"
                aspectId: any
                id: string
                content?:
                  | { __typename: "LexiconContent" }
                  | { __typename: "LexiconEntryContent" }
                  | { __typename: "MediaContent" }
                  | { __typename: "MediaGroupContent" }
                  | { __typename: "ProjectContent" }
                  | { __typename: "TermContent" }
                  | { __typename: "TextContent"; id: string; historyNumber: number }
                  | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                  | { __typename: "VariableTableContent" }
                  | null
              }
              actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
            }
      }
    | { __typename: "Error"; message: string }
}

export type UpdateCommentMutationVariables = Types.Exact<{
  commentTextInput: Types.UpdateCommentTextInput
  commentStateInput: Types.UpdateCommentStateInput
  commentTypeInput: Types.UpdateCommentTypeInput
}>

export type UpdateCommentMutation = {
  __typename: "Mutation"
  updateCommentState:
    | {
        __typename: "CommentSuccess"
        result:
          | {
              __typename: "BlockComment"
              location: number
              value?: string | null
              authorDisplayName?: string | null
              authorUserName?: string | null
              commentKey: string
              editDate?: any | null
              modificationVersion?: number | null
              replyToKey?: string | null
              sortOrder?: number | null
              commentType?: string | null
              state?: string | null
              color: string
              persistanceState?: Types.PersistanceState | null
              persistanceAction?: Types.PersistanceAction | null
              node: {
                __typename: "ST4Node"
                aspectId: any
                id: string
                content?:
                  | { __typename: "LexiconContent" }
                  | { __typename: "LexiconEntryContent" }
                  | { __typename: "MediaContent" }
                  | { __typename: "MediaGroupContent" }
                  | { __typename: "ProjectContent" }
                  | { __typename: "TermContent" }
                  | { __typename: "TextContent"; id: string; historyNumber: number }
                  | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                  | { __typename: "VariableTableContent" }
                  | null
              }
              actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
            }
          | {
              __typename: "TextComment"
              endLocation: number
              startLocation?: number | null
              value?: string | null
              authorDisplayName?: string | null
              authorUserName?: string | null
              commentKey: string
              editDate?: any | null
              modificationVersion?: number | null
              replyToKey?: string | null
              sortOrder?: number | null
              commentType?: string | null
              state?: string | null
              color: string
              persistanceState?: Types.PersistanceState | null
              persistanceAction?: Types.PersistanceAction | null
              node: {
                __typename: "ST4Node"
                aspectId: any
                id: string
                content?:
                  | { __typename: "LexiconContent" }
                  | { __typename: "LexiconEntryContent" }
                  | { __typename: "MediaContent" }
                  | { __typename: "MediaGroupContent" }
                  | { __typename: "ProjectContent" }
                  | { __typename: "TermContent" }
                  | { __typename: "TextContent"; id: string; historyNumber: number }
                  | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                  | { __typename: "VariableTableContent" }
                  | null
              }
              actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
            }
      }
    | { __typename: "Error"; message: string }
  updateCommentType:
    | {
        __typename: "CommentSuccess"
        result:
          | {
              __typename: "BlockComment"
              location: number
              value?: string | null
              authorDisplayName?: string | null
              authorUserName?: string | null
              commentKey: string
              editDate?: any | null
              modificationVersion?: number | null
              replyToKey?: string | null
              sortOrder?: number | null
              commentType?: string | null
              state?: string | null
              color: string
              persistanceState?: Types.PersistanceState | null
              persistanceAction?: Types.PersistanceAction | null
              node: {
                __typename: "ST4Node"
                aspectId: any
                id: string
                content?:
                  | { __typename: "LexiconContent" }
                  | { __typename: "LexiconEntryContent" }
                  | { __typename: "MediaContent" }
                  | { __typename: "MediaGroupContent" }
                  | { __typename: "ProjectContent" }
                  | { __typename: "TermContent" }
                  | { __typename: "TextContent"; id: string; historyNumber: number }
                  | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                  | { __typename: "VariableTableContent" }
                  | null
              }
              actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
            }
          | {
              __typename: "TextComment"
              endLocation: number
              startLocation?: number | null
              value?: string | null
              authorDisplayName?: string | null
              authorUserName?: string | null
              commentKey: string
              editDate?: any | null
              modificationVersion?: number | null
              replyToKey?: string | null
              sortOrder?: number | null
              commentType?: string | null
              state?: string | null
              color: string
              persistanceState?: Types.PersistanceState | null
              persistanceAction?: Types.PersistanceAction | null
              node: {
                __typename: "ST4Node"
                aspectId: any
                id: string
                content?:
                  | { __typename: "LexiconContent" }
                  | { __typename: "LexiconEntryContent" }
                  | { __typename: "MediaContent" }
                  | { __typename: "MediaGroupContent" }
                  | { __typename: "ProjectContent" }
                  | { __typename: "TermContent" }
                  | { __typename: "TextContent"; id: string; historyNumber: number }
                  | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                  | { __typename: "VariableTableContent" }
                  | null
              }
              actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
            }
      }
    | { __typename: "Error"; message: string }
  updateCommentText:
    | {
        __typename: "CommentSuccess"
        result:
          | {
              __typename: "BlockComment"
              location: number
              value?: string | null
              authorDisplayName?: string | null
              authorUserName?: string | null
              commentKey: string
              editDate?: any | null
              modificationVersion?: number | null
              replyToKey?: string | null
              sortOrder?: number | null
              commentType?: string | null
              state?: string | null
              color: string
              persistanceState?: Types.PersistanceState | null
              persistanceAction?: Types.PersistanceAction | null
              node: {
                __typename: "ST4Node"
                aspectId: any
                id: string
                content?:
                  | { __typename: "LexiconContent" }
                  | { __typename: "LexiconEntryContent" }
                  | { __typename: "MediaContent" }
                  | { __typename: "MediaGroupContent" }
                  | { __typename: "ProjectContent" }
                  | { __typename: "TermContent" }
                  | { __typename: "TextContent"; id: string; historyNumber: number }
                  | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                  | { __typename: "VariableTableContent" }
                  | null
              }
              actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
            }
          | {
              __typename: "TextComment"
              endLocation: number
              startLocation?: number | null
              value?: string | null
              authorDisplayName?: string | null
              authorUserName?: string | null
              commentKey: string
              editDate?: any | null
              modificationVersion?: number | null
              replyToKey?: string | null
              sortOrder?: number | null
              commentType?: string | null
              state?: string | null
              color: string
              persistanceState?: Types.PersistanceState | null
              persistanceAction?: Types.PersistanceAction | null
              node: {
                __typename: "ST4Node"
                aspectId: any
                id: string
                content?:
                  | { __typename: "LexiconContent" }
                  | { __typename: "LexiconEntryContent" }
                  | { __typename: "MediaContent" }
                  | { __typename: "MediaGroupContent" }
                  | { __typename: "ProjectContent" }
                  | { __typename: "TermContent" }
                  | { __typename: "TextContent"; id: string; historyNumber: number }
                  | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                  | { __typename: "VariableTableContent" }
                  | null
              }
              actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
            }
      }
    | { __typename: "Error"; message: string }
}

export type UpdateCommentStateMutationVariables = Types.Exact<{
  input: Types.UpdateCommentStateInput
}>

export type UpdateCommentStateMutation = {
  __typename: "Mutation"
  comment:
    | {
        __typename: "CommentSuccess"
        result:
          | {
              __typename: "BlockComment"
              location: number
              value?: string | null
              authorDisplayName?: string | null
              authorUserName?: string | null
              commentKey: string
              editDate?: any | null
              modificationVersion?: number | null
              replyToKey?: string | null
              sortOrder?: number | null
              commentType?: string | null
              state?: string | null
              color: string
              persistanceState?: Types.PersistanceState | null
              persistanceAction?: Types.PersistanceAction | null
              node: {
                __typename: "ST4Node"
                aspectId: any
                id: string
                content?:
                  | { __typename: "LexiconContent" }
                  | { __typename: "LexiconEntryContent" }
                  | { __typename: "MediaContent" }
                  | { __typename: "MediaGroupContent" }
                  | { __typename: "ProjectContent" }
                  | { __typename: "TermContent" }
                  | { __typename: "TextContent"; id: string; historyNumber: number }
                  | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                  | { __typename: "VariableTableContent" }
                  | null
              }
              actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
            }
          | {
              __typename: "TextComment"
              endLocation: number
              startLocation?: number | null
              value?: string | null
              authorDisplayName?: string | null
              authorUserName?: string | null
              commentKey: string
              editDate?: any | null
              modificationVersion?: number | null
              replyToKey?: string | null
              sortOrder?: number | null
              commentType?: string | null
              state?: string | null
              color: string
              persistanceState?: Types.PersistanceState | null
              persistanceAction?: Types.PersistanceAction | null
              node: {
                __typename: "ST4Node"
                aspectId: any
                id: string
                content?:
                  | { __typename: "LexiconContent" }
                  | { __typename: "LexiconEntryContent" }
                  | { __typename: "MediaContent" }
                  | { __typename: "MediaGroupContent" }
                  | { __typename: "ProjectContent" }
                  | { __typename: "TermContent" }
                  | { __typename: "TextContent"; id: string; historyNumber: number }
                  | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                  | { __typename: "VariableTableContent" }
                  | null
              }
              actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
            }
      }
    | { __typename: "Error"; message: string }
}

export type UpdateCommentErrorFragment = { __typename: "Error"; message: string }

export const UpdateCommentErrorFragmentDoc = gql`
  fragment UpdateCommentError on Error {
    message
  }
`
export const AddInlineCommentToNodeDocument = gql`
  mutation AddInlineCommentToNode($input: AddInlineCommentInput!) {
    comment: addInlineCommentToNode(input: $input) {
      ...UpdateCommentError
      ... on CommentSuccess {
        result {
          ...CommentInfoFields
        }
      }
    }
  }
  ${UpdateCommentErrorFragmentDoc}
  ${CommentInfoFieldsFragmentDoc}
`
export type AddInlineCommentToNodeMutationFn = Apollo.MutationFunction<
  AddInlineCommentToNodeMutation,
  AddInlineCommentToNodeMutationVariables
>

/**
 * __useAddInlineCommentToNodeMutation__
 *
 * To run a mutation, you first call `useAddInlineCommentToNodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddInlineCommentToNodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addInlineCommentToNodeMutation, { data, loading, error }] = useAddInlineCommentToNodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddInlineCommentToNodeMutation(
  baseOptions?: Apollo.MutationHookOptions<AddInlineCommentToNodeMutation, AddInlineCommentToNodeMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<AddInlineCommentToNodeMutation, AddInlineCommentToNodeMutationVariables>(
    AddInlineCommentToNodeDocument,
    options,
  )
}
export type AddInlineCommentToNodeMutationHookResult = ReturnType<typeof useAddInlineCommentToNodeMutation>
export type AddInlineCommentToNodeMutationResult = Apollo.MutationResult<AddInlineCommentToNodeMutation>
export type AddInlineCommentToNodeMutationOptions = Apollo.BaseMutationOptions<
  AddInlineCommentToNodeMutation,
  AddInlineCommentToNodeMutationVariables
>
export const RemoveCommentDocument = gql`
  mutation RemoveComment($input: RemoveCommentInput!) {
    comment: removeComment(input: $input) {
      ...UpdateCommentError
      ... on CommentSuccess {
        result {
          ...CommentInfoFields
        }
      }
    }
  }
  ${UpdateCommentErrorFragmentDoc}
  ${CommentInfoFieldsFragmentDoc}
`
export type RemoveCommentMutationFn = Apollo.MutationFunction<RemoveCommentMutation, RemoveCommentMutationVariables>

/**
 * __useRemoveCommentMutation__
 *
 * To run a mutation, you first call `useRemoveCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCommentMutation, { data, loading, error }] = useRemoveCommentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveCommentMutation, RemoveCommentMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RemoveCommentMutation, RemoveCommentMutationVariables>(RemoveCommentDocument, options)
}
export type RemoveCommentMutationHookResult = ReturnType<typeof useRemoveCommentMutation>
export type RemoveCommentMutationResult = Apollo.MutationResult<RemoveCommentMutation>
export type RemoveCommentMutationOptions = Apollo.BaseMutationOptions<
  RemoveCommentMutation,
  RemoveCommentMutationVariables
>
export const ReplyToCommentDocument = gql`
  mutation ReplyToComment($input: AddReplyCommentInput!) {
    comment: addReplyToComment(input: $input) {
      ...UpdateCommentError
      ... on CommentSuccess {
        result {
          ...CommentInfoFields
        }
      }
    }
  }
  ${UpdateCommentErrorFragmentDoc}
  ${CommentInfoFieldsFragmentDoc}
`
export type ReplyToCommentMutationFn = Apollo.MutationFunction<ReplyToCommentMutation, ReplyToCommentMutationVariables>

/**
 * __useReplyToCommentMutation__
 *
 * To run a mutation, you first call `useReplyToCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReplyToCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [replyToCommentMutation, { data, loading, error }] = useReplyToCommentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReplyToCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<ReplyToCommentMutation, ReplyToCommentMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ReplyToCommentMutation, ReplyToCommentMutationVariables>(ReplyToCommentDocument, options)
}
export type ReplyToCommentMutationHookResult = ReturnType<typeof useReplyToCommentMutation>
export type ReplyToCommentMutationResult = Apollo.MutationResult<ReplyToCommentMutation>
export type ReplyToCommentMutationOptions = Apollo.BaseMutationOptions<
  ReplyToCommentMutation,
  ReplyToCommentMutationVariables
>
export const UpdateCommentDocument = gql`
  mutation UpdateComment(
    $commentTextInput: UpdateCommentTextInput!
    $commentStateInput: UpdateCommentStateInput!
    $commentTypeInput: UpdateCommentTypeInput!
  ) {
    updateCommentState(input: $commentStateInput) {
      ... on CommentSuccess {
        result {
          ...CommentInfoFields
        }
      }
      ...UpdateCommentError
    }
    updateCommentType(input: $commentTypeInput) {
      ... on CommentSuccess {
        result {
          ...CommentInfoFields
        }
      }
      ...UpdateCommentError
    }
    updateCommentText(input: $commentTextInput) {
      ... on CommentSuccess {
        result {
          ...CommentInfoFields
        }
      }
      ...UpdateCommentError
    }
  }
  ${CommentInfoFieldsFragmentDoc}
  ${UpdateCommentErrorFragmentDoc}
`
export type UpdateCommentMutationFn = Apollo.MutationFunction<UpdateCommentMutation, UpdateCommentMutationVariables>

/**
 * __useUpdateCommentMutation__
 *
 * To run a mutation, you first call `useUpdateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCommentMutation, { data, loading, error }] = useUpdateCommentMutation({
 *   variables: {
 *      commentTextInput: // value for 'commentTextInput'
 *      commentStateInput: // value for 'commentStateInput'
 *      commentTypeInput: // value for 'commentTypeInput'
 *   },
 * });
 */
export function useUpdateCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateCommentMutation, UpdateCommentMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateCommentMutation, UpdateCommentMutationVariables>(UpdateCommentDocument, options)
}
export type UpdateCommentMutationHookResult = ReturnType<typeof useUpdateCommentMutation>
export type UpdateCommentMutationResult = Apollo.MutationResult<UpdateCommentMutation>
export type UpdateCommentMutationOptions = Apollo.BaseMutationOptions<
  UpdateCommentMutation,
  UpdateCommentMutationVariables
>
export const UpdateCommentStateDocument = gql`
  mutation UpdateCommentState($input: UpdateCommentStateInput!) {
    comment: updateCommentState(input: $input) {
      ... on CommentSuccess {
        result {
          ...CommentInfoFields
        }
      }
      ...UpdateCommentError
    }
  }
  ${CommentInfoFieldsFragmentDoc}
  ${UpdateCommentErrorFragmentDoc}
`
export type UpdateCommentStateMutationFn = Apollo.MutationFunction<
  UpdateCommentStateMutation,
  UpdateCommentStateMutationVariables
>

/**
 * __useUpdateCommentStateMutation__
 *
 * To run a mutation, you first call `useUpdateCommentStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommentStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCommentStateMutation, { data, loading, error }] = useUpdateCommentStateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCommentStateMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateCommentStateMutation, UpdateCommentStateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateCommentStateMutation, UpdateCommentStateMutationVariables>(
    UpdateCommentStateDocument,
    options,
  )
}
export type UpdateCommentStateMutationHookResult = ReturnType<typeof useUpdateCommentStateMutation>
export type UpdateCommentStateMutationResult = Apollo.MutationResult<UpdateCommentStateMutation>
export type UpdateCommentStateMutationOptions = Apollo.BaseMutationOptions<
  UpdateCommentStateMutation,
  UpdateCommentStateMutationVariables
>
