import React from "react"

type DragHandleProps = {
  onMouseDownChanged: (mouseIsPressed: boolean) => void
  children: React.ReactNode
}

export function DraggableSpan({ onMouseDownChanged, children }: DragHandleProps) {
  return (
    <span
      onMouseDown={() => onMouseDownChanged(true)}
      onMouseUp={() => onMouseDownChanged(false)}
      style={{ display: "grid", grid: "auto / auto 1fr auto" }}
    >
      {children}
    </span>
  )
}
