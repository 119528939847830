import React from "react"
import styled, { keyframes } from "styled-components"

const Container = styled.div``

const widthAnimation = keyframes`
    from { left: -200px; width: 0%;}
    50% { width: 30%;}
    70% { width: 70%;}
    80% { left: 50%;}
    95% { left: 120%;}
    to { left: 100%;}
`

const Loader = styled.div`
  height: 4px;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: #ddd;
  &:before {
    display: block;
    position: absolute;
    content: "";
    left: -200px;
    width: 200px;
    height: 4px;
    background-color: #2980b9;
    animation: ${widthAnimation} 2s linear infinite;
  }
`

export function LoadingIndicator({ className = "" }) {
  return (
    <Container className={className}>
      <Loader />
    </Container>
  )
}
