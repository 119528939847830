import React, { PropsWithChildren } from "react"
import styled from "styled-components"
import { useTreeNode } from "../../contentModel/nodeContext"
import type { XastRendererProps } from "../XASTRenderer"
import { isEmbeddedSt4NodeWithContentFragment } from "../../contentModel/types"

type _NodePageProps = {
  className?: string
} & Partial<XastRendererProps>

const Wrapper = styled.div`
  position: relative;

  &.child {
    position: relative;
    margin-left: -1em;
    margin-right: -1em;
    margin-bottom: 1em;
    padding: 0 1em;
    border: 1px dashed ${({ theme }) => theme.greys[300]};
  }

  &.child.group {
    ${({ theme }) => theme.preview.groupNodeHighlight};
  }
`

export function NodePage({ children, ast }: PropsWithChildren<_NodePageProps>) {
  const treeNode = useTreeNode()
  const node = treeNode.node

  const isRoot = !isEmbeddedSt4NodeWithContentFragment(node) || !node.parent // parent Kommt vom TextContentState
  const isTextGroup = ast?.data?.fragmentGroupKey

  return <Wrapper className={`${isRoot ? "root" : "child"} ${isTextGroup ? "group" : ""}`}>{children}</Wrapper>
}
