import React from "react"
import styled from "styled-components"
import { Rect } from "./glyphpositions"
import { ProgressState, TextSelection } from "./types"
import type { AnnotationFocusStateContextProps } from "../components/annotationFocusState"
import { useScrollToFocusedComment } from "../components/annotationFocusState"
import { isTypename, TextComment } from "@st4/graphql"
import { useTreeNode } from "../contentModel/nodeContext"
import { CommentNode } from "../components/annotationCollection"

type Distances = { topLeft: number; topRight: number; bottomLeft: number; bottomRight: number }

type Split = SelectionSplit | CommentSplit
type SelectionSplit = { start: number; end: number; type: "Selection" }
type CommentSplit = { start: number; end: number; type: "Comment"; comment: CommentNode }
type Position = { start: number; location: number }
export type GlyphTuple = { character: string; offset: number; index: number; clientRect: Rect }

export const NormalText = styled.span``

export const SelectedText = styled.span<{ selecting: ProgressState; commentAllowed: boolean }>`
  background: ${({ theme, selecting, commentAllowed }) =>
    commentAllowed
      ? theme.preview.selection.primaryColor[selecting == ProgressState.Ongoing ? "100" : "200"]
      : theme.preview.selection.noCommentColor["200"]};
  border-radius: 4px 4px 4px 4px/ 4px 4px 8px 8px;
  user-select: none;
`

export const CommentTextWrapper = styled.span<{ error: boolean; focused: boolean; prefocused: boolean }>`
  cursor: pointer;
  background: ${({ theme, error, focused, prefocused }) =>
    error
      ? theme.preview.annotations.errorColor["400"]
      : focused
      ? theme.preview.annotations.focusColor.background
      : prefocused
      ? theme.preview.annotations.preColor.background
      : theme.preview.annotations.primaryColor["200"]};
  border-radius: 4px 4px 4px 4px/ 4px 4px 8px 8px;
  user-select: none;
`

type CommentTextProps = {
  error: boolean
  commentId: string
  comment: Pick<CommentNode, "id">
} & React.DOMAttributes<HTMLSpanElement>

function CommentText({ error, commentId, comment, children, ...rest }: React.PropsWithChildren<CommentTextProps>) {
  const [scrollTargetProps, { isFocused, isPrefocused }, { setFocusedAnnotations, setPrefocusedAnnotations }] =
    useScrollToFocusedComment([commentId])
  const treeNode = useTreeNode()
  return (
    <CommentTextWrapper
      {...rest}
      {...scrollTargetProps}
      onClick={(e) => {
        // Propagation is stopped so that only comment is selected and no other actions happen
        // This prevents warnings to collapse when clicking comment for example
        e.preventDefault()
        e.stopPropagation()
        setFocusedAnnotations([{ comment, treeNode }])
      }}
      onPointerEnter={(e) => {
        e.preventDefault()
        e.stopPropagation()
        setPrefocusedAnnotations([{ comment, treeNode }])
      }}
      onPointerLeave={(e) => {
        e.preventDefault()
        e.stopPropagation()
        setPrefocusedAnnotations([])
      }}
      error={error}
      focused={isFocused}
      prefocused={isPrefocused}
    >
      {children}
    </CommentTextWrapper>
  )
}

function findSplitpoint(mouseX: number, mouseY: number, glyphMap: GlyphTuple[]) {
  const glyphsWithDeltas = glyphMap.map((glyph) => {
    const calcDistance = (x2: number, y2: number) => Math.sqrt(Math.pow(mouseX - x2, 2) + Math.pow(mouseY - y2, 2))
    const rect = glyph.clientRect
    const topLeft = calcDistance(rect.left, rect.top)
    const topRight = calcDistance(rect.right, rect.top)
    const bottomLeft = calcDistance(rect.left, rect.bottom)
    const bottomRight = calcDistance(rect.right, rect.bottom)

    const inside = rect.left < mouseX && rect.right > mouseX && rect.top < mouseY && rect.bottom > mouseY
    const sameLine = rect.top < mouseY && rect.bottom > mouseY

    return {
      ...glyph,
      distances: {
        // Distanz vom Cursor zur jeweiligen Ecke des Zeichens
        topLeft,
        topRight,
        bottomLeft,
        bottomRight,
      },
      inside,
      sameLine,
    }
  })

  const glyphWithSmallestDelta = glyphsWithDeltas.reduce((prev, curr) => {
    if (!curr.sameLine) return prev
    const prevMinVal = getMinDelta(prev.distances) // geringste Distanz des Cursors zum zuletzt ausgewählten Zeichen
    const currMinVal = getMinDelta(curr.distances) // geringste Distanz des C. zum aktuellen Zeichen

    const nearestGlyph = prevMinVal < currMinVal ? prev : curr // ist das aktuelle Zeichen näher am Cursor
    return nearestGlyph
  })

  return {
    glyphWithSmallestDelta,
    glyphUnderCursor: glyphsWithDeltas.find((g) => g.inside),
  }
}

const getMinDelta = (distances: Distances) => Math.min(distances.topRight, distances.bottomRight)

function getRelativeCoordinates(evt: React.PointerEvent | PointerEvent, container: HTMLElement): [number, number] {
  const clientRect = container.getBoundingClientRect()
  const x = evt.clientX - clientRect.left
  const y = evt.clientY - clientRect.top
  return [x, y]
}

export function findSplitpointByEvent(
  evt: React.PointerEvent | PointerEvent,
  container: HTMLElement,
  glyphMap: GlyphTuple[],
) {
  const [x, y] = getRelativeCoordinates(evt, container)
  return findSplitpoint(x, y, glyphMap)
}

export function splitText(
  nodeOffset: Position,
  currentTextSelection: TextSelection,
  comments: CommentNode[],
  node: { id: string },
) {
  const splits: Split[] = []
  let selectionStart = -1
  let selectionEnd = -1
  const nodeStart = nodeOffset.start
  const nodeEnd = nodeOffset.location

  if (
    (currentTextSelection.progress == ProgressState.Ongoing ||
      currentTextSelection.progress == ProgressState.Complete) &&
    currentTextSelection.start.contentNode.id == node.id
  ) {
    selectionStart = currentTextSelection.start.offset
    selectionEnd = currentTextSelection.end.offset
    if (selectionStart <= nodeEnd && selectionEnd > nodeStart) {
      splits.push({
        start: Math.max(selectionStart, nodeStart) - nodeStart,
        end: Math.min(selectionEnd, nodeEnd) - nodeStart,
        type: "Selection",
      })
    }
  }
  comments.map((commentNode) => {
    const comment = commentNode.comment
    if (
      !isTypename("TextComment")(comment) ||
      !comment.startLocation ||
      comment.startLocation >= nodeEnd ||
      comment.endLocation < nodeStart
    ) {
      return
    }
    let splitStart = Math.max(comment.startLocation, nodeStart) - nodeStart
    let splitEnd = Math.min(comment.endLocation, nodeEnd) - nodeStart
    if (currentTextSelection) {
      if (comment.startLocation >= selectionStart && comment.endLocation <= selectionEnd) {
        return //A comment that lies completely within the text selection will be ignored
      }
      if (comment.startLocation < selectionStart && comment.endLocation > selectionEnd) {
        //If the selection lies within a comment, the comment will be split in two parts
        const firstSplitStart = splitStart
        const firstSplitEnd = selectionStart - nodeStart
        splits.push({ start: firstSplitStart, end: firstSplitEnd, type: "Comment", comment: commentNode })
        splitStart = selectionEnd - nodeStart
      } else if (
        comment.startLocation >= selectionStart &&
        comment.startLocation <= selectionEnd &&
        comment.endLocation > selectionEnd
      ) {
        splitStart = selectionEnd - nodeStart
      } else if (
        comment.startLocation < selectionStart &&
        comment.endLocation >= selectionStart &&
        comment.endLocation <= selectionEnd
      ) {
        splitEnd = selectionStart - nodeStart
      }
    }
    splits.push({ start: splitStart, end: splitEnd, type: "Comment", comment: commentNode })
  })

  splits.sort((a, b) => {
    return a.start - b.start
  })
  return splits
}

export function isCommentingAllowed(
  nodeOffset: Position,
  selectionStart: number,
  selectionEnd: number,
  comments: Array<CommentNode>,
) {
  let commentAllowedAtSelection = !(selectionStart < nodeOffset.start || selectionEnd > nodeOffset.location)
  comments.map((commentNode) => {
    const comment = commentNode.comment
    if (!isTypename("TextComment")(comment)) return
    commentAllowedAtSelection =
      commentAllowedAtSelection &&
      !(
        ((comment.startLocation ?? 0) >= selectionStart &&
          (comment.startLocation ?? 0) <= selectionEnd &&
          comment.endLocation > selectionEnd) ||
        ((comment.startLocation ?? 0) < selectionStart &&
          comment.endLocation >= selectionStart &&
          comment.endLocation <= selectionEnd)
      )
  })
  return commentAllowedAtSelection
}

export function sliceTextAtSplitPoints(
  splits: Split[],
  children: string,
  commentAllowedAtSelection: boolean,
  textSelectionProgress: ProgressState,
  keyPrefix: string,
) {
  let textLeft = children
  const textElements: JSX.Element[] = [] //The elements that will be displayed
  let currentOffset = 0

  splits.map((split, idx) => {
    const textToSlice = textLeft
    if (textToSlice === null || textToSlice === undefined || textToSlice.length <= 0) {
      return null
    }
    const splitStart = split.start - currentOffset
    const splitEnd = split.end - currentOffset
    textLeft = textToSlice.slice(splitEnd)
    const precedingText = textToSlice.slice(0, splitStart)
    if (precedingText)
      textElements.push(<NormalText key={keyPrefix + "split_" + precedingText}>{precedingText}</NormalText>)
    const keyString = keyPrefix + "split_" + textToSlice.slice(splitStart, splitEnd)
    switch (split.type) {
      case "Comment":
        textElements.push(
          <CommentText
            commentId={split.comment.id}
            comment={split.comment}
            error={split.comment.comment.persistanceState === "ERROR"}
            key={keyString}
          >
            {textToSlice.slice(splitStart, splitEnd)}
          </CommentText>,
        )
        break
      case "Selection":
        //Always set end marker. Comment box spawning needs this so that it can calculate which pair of markers is in
        //its original selection
        textElements.push(<span key="selectionStartMarker" className="selectionStartMarker" />) //place Startmarker if selection start. Condition might be to simple. I've tried for about 6 hours and could'n get a ref passed to here, which would be available as the currentTextSelection.start.marker (In the SelectionContext) 💩
        textElements.push(
          <SelectedText selecting={textSelectionProgress} commentAllowed={commentAllowedAtSelection} key={keyString}>
            {textToSlice.slice(splitStart, splitEnd)}
          </SelectedText>,
        )
        // No condition again. See start marker
        textElements.push(<span key="selectionEndMarker" className="selectionEndMarker" />) // place Endmarker if selection end. Condition might be to simple. I've tried for about 6 hours and could'n get a ref passed to here, which would be available as the currentTextSelection.end.marker (In the SelectionContext) 💩
        break
      default:
        textElements.push(<NormalText key={keyString}>{textToSlice.slice(splitStart, splitEnd)}</NormalText>)
    }
    currentOffset = split.end
  })
  const remainingText = children.slice(splits[splits.length - 1].end)
  if (remainingText)
    textElements.push(<NormalText key={keyPrefix + "split_" + remainingText}>{remainingText}</NormalText>)
  return <>{textElements}</>
}
