import * as React from "react"
import { SVGProps } from "react"

const SvgSt4InsertglossaryRegular = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1em" height="1em" {...props}>
    <path d="M242.4 437.1c0-41.4 33.5-74.9 74.9-74.9h30c8.3 0 15 6.7 15 15s-6.7 15-15 15h-30c-24.8 0-44.9 20.1-44.9 44.9s20.1 44.9 44.9 44.9h30c8.3 0 15 6.7 15 15s-6.7 15-15 15h-30c-41.4 0-74.9-33.5-74.9-74.9zm269.6 0c0 41.4-33.5 74.9-74.9 74.9h-30c-8.3 0-15-6.7-15-15s6.7-15 15-15h30c24.8 0 44.9-20.1 44.9-44.9s-20.1-44.9-44.9-44.9h-30c-8.3 0-15-6.7-15-15s6.7-15 15-15h30c41.4 0 74.9 33.5 74.9 74.9zm-179.7-15h89.9c8.3 0 15 6.7 15 15s-6.7 15-15 15h-89.9c-8.3 0-15-6.7-15-15s6.7-15 15-15zm-116 41.9h-41.4v-48h40.3c3.1-17.3 10-33.7 20.3-48H175V144h64v219.4c2.9-3.6 5.9-7.1 9.2-10.4 11.3-11.3 24.4-20 38.8-25.9V132.2l3.6 13.7 44.6 172.3h49.6l-42-161.9 56.5-15.6 46.1 177.5h6.7c15.7 0 31 3 45.2 8.8l-51.7-199.2-6-23.2-18-69.2c-6.6-25.5-32-40.6-56.7-33.8l-59.5 16.5c-8.5 2.3-15.8 6.9-21.4 13C277 12.9 259.5 0 238.9 0h-64c-8.7 0-16.9 2.3-24 6.4-7.1-4.1-15.3-6.4-24-6.4h-64c-26.5 0-48 21.5-48 48v416c0 26.5 21.5 48 48 48h64c8.7 0 16.9-2.3 24-6.4 7.1 4.1 15.3 6.4 24 6.4h64.9c-11.5-14.1-19.5-30.5-23.5-48zM318.9 64.1l56.4-15.6 11.9 45.7-56.5 15.6-.1-.1-11.7-45.2v-.4zM174.9 48h64v48h-64V48zm-48 416h-64v-48h64v48zm0-96h-64V144h64v224zm0-272h-64V48h64v48z" />
  </svg>
)

export default SvgSt4InsertglossaryRegular
