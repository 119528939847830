export const PrimaryPalette = {
  brightRed: "#ee5340",
  brightViolet: "#A744ED",
  darkBlue: "#00417d",
  limeGreen: "#08E7A1",
  softOrange: "#EFA434",
}

export const SecondaryPalette = {
  lightGreyishBlue: "#f3f5f8",
  black: "#000000",
  softBlue: "#519ae7",
}

export const BluePalette = {
  darkBlue: PrimaryPalette.darkBlue,
  darkerBlue: "#003463",
  veryDarkBlue: "#04254a",
  regularBlue: "#0070d4",
  softBlue: "#519ae7",
  softerBlue: "#2875CD",
  lightBlue: "#8BBBEF",
  lighterBlue: "#6EAAEB",
  subtleBlue: "#A8CCF3",
  verySubtleBlue: "#DBEEFF",
  barelyBlue: "#E2EEFB",
}

export const BluePaletteRange = [
  BluePalette.softBlue,
  BluePalette.regularBlue,
  BluePalette.darkBlue,
  BluePalette.veryDarkBlue,
]

export const SpotColors = {
  SoftOrange: {
    softOrange: PrimaryPalette.softOrange,
    autumnBlaze: "#C77D10",
    bronzeBrown: "#80510A",
    brightOrange: "#FFF1DB",
  },
  LimeGreen: {
    limeGreen: PrimaryPalette.limeGreen,
    mintLeaf: "#06B67F",
    deepMint: "#059D6D",
  },
  BrightViolet: {
    brightViolet: PrimaryPalette.brightViolet,
    passionFlower: "#8A34C4",
    ultraViolet: "#8214D0",
  },
}

export const GreyScale = {
  midnightBlue: "#001C36",
  tuna: "#353C42",
  shuttleGrey: "#5E6B76",
  osloGrey: "#818B94",
  silkGrey: "#B1B3B5",
  silverSand: "#BABEC2",
  silver: "#C9CCCF",
  lightGrey: "#DADCDE",
  harpGrey: "#ECEDEE",
  porcelainGrey: "#ECEEEF",
  ghostWhite: "#F8F9FA",
  snowGrey: "#F8FAFB",
}

export const StateColors = {
  Errors: {
    error: "#DB2828",
    errorDarker: "#B31515",
    errorDark: "#780000",
    errorBG: "#FFE3E3",
  },
  Warning: {
    warning: "#FBBD08",
    warningDarker: "#DBA300",
    warningDark: "#B38500",
    warningBG: "#FFF5D9",
  },
  Success: {
    success: "#60A966",
    successDarker: "#418547",
    successDark: "#245C28",
    successBG: "#D3F5D5",
  },
  Question: {
    questionBG: BluePalette.lightBlue,
    question: BluePalette.darkerBlue,
  },
}

export const GreenPaletteRange = [
  SpotColors.LimeGreen.limeGreen,
  SpotColors.LimeGreen.mintLeaf,
  SpotColors.LimeGreen.deepMint,
]

export const VioletPaletteRange = [
  SpotColors.BrightViolet.brightViolet,
  SpotColors.BrightViolet.passionFlower,
  SpotColors.BrightViolet.ultraViolet,
]

export const OrangePaletteRange = [
  SpotColors.SoftOrange.softOrange,
  SpotColors.SoftOrange.autumnBlaze,
  SpotColors.SoftOrange.bronzeBrown,
  SpotColors.SoftOrange.brightOrange,
]

export const GreyPaletteRange = [
  GreyScale.midnightBlue,
  GreyScale.tuna,
  GreyScale.shuttleGrey,
  GreyScale.osloGrey,
  GreyScale.silkGrey,
  GreyScale.silverSand,
  GreyScale.silver,
  GreyScale.lightGrey,
  GreyScale.harpGrey,
  GreyScale.porcelainGrey,
  GreyScale.ghostWhite,
  GreyScale.snowGrey,
]

export const ErrorsPaletteRange = [
  StateColors.Errors.error,
  StateColors.Errors.errorDarker,
  StateColors.Errors.errorDark,
  StateColors.Errors.errorBG,
]

export const WarningPaletteRange = [
  StateColors.Warning.warning,
  StateColors.Warning.warningDarker,
  StateColors.Warning.warningDark,
  StateColors.Warning.warningBG,
]

export const SuccessPaletteRange = [
  StateColors.Success.success,
  StateColors.Success.successDarker,
  StateColors.Success.successDark,
  StateColors.Success.successBG,
]

export default {
  palettes: {
    primary: PrimaryPalette,
    secondary: SecondaryPalette,
    blue: BluePalette,
    green: SpotColors.LimeGreen,
    violet: SpotColors.BrightViolet,
    orange: SpotColors.SoftOrange,
    grey: GreyScale,
    error: StateColors.Errors,
    warning: StateColors.Warning,
    success: StateColors.Success,
    question: StateColors.Question,
  },
  ranges: {
    blue: BluePaletteRange,
    green: GreenPaletteRange,
    violet: VioletPaletteRange,
    orange: OrangePaletteRange,
    grey: GreyPaletteRange,
  },
}
