import * as Types from "@st4/graphql"

import { gql } from "@apollo/client"
import { CommentNodeFragmentDoc } from "../../components/CommentList/query.hooks"
import * as Apollo from "@apollo/client"
const defaultOptions = {} as const
export type SingleNodeQueryVariables = Types.Exact<{
  id: Types.Scalars["ID"]
  languageId: Types.Scalars["AspectSelectorId"]
  reviewVariant: Types.Scalars["String"]
}>

export type SingleNodeQuery = {
  __typename: "Query"
  node?:
    | { __typename: "CommentNode"; id: string }
    | { __typename: "ExternalSite"; id: string }
    | { __typename: "ST4Class"; id: string }
    | {
        __typename: "ST4Node"
        id: string
        label?: string | null
        aspectId: any
        nodeClass: { __typename: "ST4Class"; classHierarchy: Array<string> }
        metadata: Array<{
          __typename: "MetaDataEntry"
          label?: string | null
          systemName: string
          value?: string | null
          values: Array<string>
        }>
        properties?: {
          __typename: "ContentPropertyConnection"
          totalCount: number
          edges?: Array<{
            __typename: "ContentPropertyEdge"
            node?: {
              __typename: "ContentProperty"
              name: string
              displayName: string
              value?: any | null
              typeOfValue: Types.ContentPropertyValueType
            } | null
          } | null> | null
        } | null
        content?:
          | { __typename: "LexiconContent" }
          | {
              __typename: "LexiconEntryContent"
              descriptionXast?: string | null
              subject?: string | null
              sortKey?: string | null
              lexicon:
                | { __typename: "CommentNode"; id: string }
                | { __typename: "ExternalSite"; id: string }
                | { __typename: "ST4Class"; id: string }
                | { __typename: "ST4Node"; label?: string | null; id: string }
                | { __typename: "ST4TreeNode"; id: string }
              terms: Array<
                | { __typename: "CommentNode"; id: string }
                | { __typename: "ExternalSite"; id: string }
                | { __typename: "ST4Class"; id: string }
                | {
                    __typename: "ST4Node"
                    id: string
                    label?: string | null
                    aspectId: any
                    content?:
                      | { __typename: "LexiconContent" }
                      | { __typename: "LexiconEntryContent" }
                      | { __typename: "MediaContent" }
                      | { __typename: "MediaGroupContent" }
                      | { __typename: "ProjectContent" }
                      | {
                          __typename: "TermContent"
                          gramaticalGender?: string | null
                          validity?: string | null
                          editLanguage?: string | null
                        }
                      | { __typename: "TextContent" }
                      | { __typename: "TextGroupContent" }
                      | { __typename: "VariableTableContent" }
                      | null
                  }
                | { __typename: "ST4TreeNode"; id: string }
              >
              image?:
                | { __typename: "CommentNode"; id: string }
                | { __typename: "ExternalSite"; id: string }
                | { __typename: "ST4Class"; id: string }
                | {
                    __typename: "ST4Node"
                    aspectId: any
                    id: string
                    content?:
                      | { __typename: "LexiconContent" }
                      | { __typename: "LexiconEntryContent" }
                      | {
                          __typename: "MediaContent"
                          media?:
                            | {
                                __typename: "AnimationMediaInfo"
                                thumbnail?: string | null
                                mediaType?: Types.MediaType | null
                              }
                            | {
                                __typename: "ImageMediaInfo"
                                src?: string | null
                                thumbnail?: string | null
                                mediaType?: Types.MediaType | null
                              }
                            | { __typename: "NoMediaInfo" }
                            | {
                                __typename: "VectorGraphicsMediaInfo"
                                svg?: string | null
                                thumbnail?: string | null
                                mediaType?: Types.MediaType | null
                              }
                            | {
                                __typename: "VideoMediaInfo"
                                thumbnail?: string | null
                                mediaType?: Types.MediaType | null
                              }
                            | null
                        }
                      | { __typename: "MediaGroupContent" }
                      | { __typename: "ProjectContent" }
                      | { __typename: "TermContent" }
                      | { __typename: "TextContent" }
                      | { __typename: "TextGroupContent" }
                      | { __typename: "VariableTableContent" }
                      | null
                  }
                | { __typename: "ST4TreeNode"; id: string }
                | null
            }
          | {
              __typename: "MediaContent"
              media?:
                | {
                    __typename: "AnimationMediaInfo"
                    id: string
                    resourceId?: string | null
                    thumbnail?: string | null
                    width?: number | null
                    height?: number | null
                    mediaType?: Types.MediaType | null
                  }
                | {
                    __typename: "ImageMediaInfo"
                    id: string
                    resourceId?: string | null
                    thumbnail?: string | null
                    width?: number | null
                    height?: number | null
                    resolution?: number | null
                    colorDepth?: number | null
                    size?: number | null
                    mime?: string | null
                    extension?: string | null
                    allowCaching?: boolean | null
                    mediaType?: Types.MediaType | null
                    src?: string | null
                    language?: string | null
                  }
                | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                | {
                    __typename: "VectorGraphicsMediaInfo"
                    id: string
                    resourceId?: string | null
                    thumbnail?: string | null
                    width?: number | null
                    height?: number | null
                    allowCaching?: boolean | null
                    mediaType?: Types.MediaType | null
                    language?: string | null
                    labels: Array<{
                      __typename: "VectorGraphicsLabel"
                      id?: string | null
                      sequence?: number | null
                      autoValue?: string | null
                      text?: string | null
                      linkType?: Types.VectorGraphicsLabelLinkType | null
                      link?: string | null
                      ref?:
                        | {
                            __typename: "GenericRef"
                            id: string
                            owner?:
                              | { __typename: "CommentNode" }
                              | { __typename: "ExternalSite" }
                              | { __typename: "ST4Class" }
                              | { __typename: "ST4Node"; id: string }
                              | { __typename: "ST4TreeNode" }
                              | null
                            target?:
                              | { __typename: "CommentNode" }
                              | {
                                  __typename: "ExternalSite"
                                  id: string
                                  teaserData?: {
                                    __typename: "ExternalLinkTeaserData"
                                    title?: string | null
                                    summary?: string | null
                                    iconUrl?: string | null
                                    imageUrl?: string | null
                                    status?: Types.LinkTargetStatus | null
                                  } | null
                                }
                              | { __typename: "ST4Class" }
                              | {
                                  __typename: "ST4Node"
                                  id: string
                                  aspectId: any
                                  label?: string | null
                                  properties?: {
                                    __typename: "ContentPropertyConnection"
                                    edges?: Array<{
                                      __typename: "ContentPropertyEdge"
                                      node?: {
                                        __typename: "ContentProperty"
                                        name: string
                                        value?: any | null
                                        displayName: string
                                      } | null
                                    } | null> | null
                                  } | null
                                }
                              | { __typename: "ST4TreeNode" }
                              | null
                          }
                        | {
                            __typename: "NodeRef"
                            id: string
                            owner?:
                              | { __typename: "CommentNode" }
                              | { __typename: "ExternalSite" }
                              | { __typename: "ST4Class" }
                              | { __typename: "ST4Node"; id: string }
                              | { __typename: "ST4TreeNode" }
                              | null
                            target?:
                              | { __typename: "CommentNode" }
                              | {
                                  __typename: "ExternalSite"
                                  id: string
                                  teaserData?: {
                                    __typename: "ExternalLinkTeaserData"
                                    title?: string | null
                                    summary?: string | null
                                    iconUrl?: string | null
                                    imageUrl?: string | null
                                    status?: Types.LinkTargetStatus | null
                                  } | null
                                }
                              | { __typename: "ST4Class" }
                              | {
                                  __typename: "ST4Node"
                                  id: string
                                  aspectId: any
                                  label?: string | null
                                  properties?: {
                                    __typename: "ContentPropertyConnection"
                                    edges?: Array<{
                                      __typename: "ContentPropertyEdge"
                                      node?: {
                                        __typename: "ContentProperty"
                                        name: string
                                        value?: any | null
                                        displayName: string
                                      } | null
                                    } | null> | null
                                  } | null
                                }
                              | { __typename: "ST4TreeNode" }
                              | null
                          }
                        | null
                    }>
                  }
                | {
                    __typename: "VideoMediaInfo"
                    id: string
                    resourceId?: string | null
                    thumbnail?: string | null
                    width?: number | null
                    height?: number | null
                    mediaType?: Types.MediaType | null
                  }
                | null
            }
          | {
              __typename: "MediaGroupContent"
              children: Array<{
                __typename: "NodeRef"
                id: string
                target?:
                  | { __typename: "CommentNode"; id: string }
                  | { __typename: "ExternalSite"; id: string }
                  | { __typename: "ST4Class"; id: string }
                  | {
                      __typename: "ST4Node"
                      id: string
                      aspectId: any
                      content?:
                        | { __typename: "LexiconContent" }
                        | { __typename: "LexiconEntryContent" }
                        | {
                            __typename: "MediaContent"
                            media?:
                              | {
                                  __typename: "AnimationMediaInfo"
                                  id: string
                                  resourceId?: string | null
                                  thumbnail?: string | null
                                  width?: number | null
                                  height?: number | null
                                  mediaType?: Types.MediaType | null
                                }
                              | {
                                  __typename: "ImageMediaInfo"
                                  id: string
                                  resourceId?: string | null
                                  thumbnail?: string | null
                                  width?: number | null
                                  height?: number | null
                                  resolution?: number | null
                                  colorDepth?: number | null
                                  size?: number | null
                                  mime?: string | null
                                  extension?: string | null
                                  allowCaching?: boolean | null
                                  mediaType?: Types.MediaType | null
                                  src?: string | null
                                  language?: string | null
                                }
                              | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                              | {
                                  __typename: "VectorGraphicsMediaInfo"
                                  id: string
                                  resourceId?: string | null
                                  thumbnail?: string | null
                                  width?: number | null
                                  height?: number | null
                                  allowCaching?: boolean | null
                                  mediaType?: Types.MediaType | null
                                  language?: string | null
                                  labels: Array<{
                                    __typename: "VectorGraphicsLabel"
                                    id?: string | null
                                    sequence?: number | null
                                    autoValue?: string | null
                                    text?: string | null
                                    linkType?: Types.VectorGraphicsLabelLinkType | null
                                    link?: string | null
                                    ref?:
                                      | {
                                          __typename: "GenericRef"
                                          id: string
                                          owner?:
                                            | { __typename: "CommentNode" }
                                            | { __typename: "ExternalSite" }
                                            | { __typename: "ST4Class" }
                                            | { __typename: "ST4Node"; id: string }
                                            | { __typename: "ST4TreeNode" }
                                            | null
                                          target?:
                                            | { __typename: "CommentNode" }
                                            | {
                                                __typename: "ExternalSite"
                                                id: string
                                                teaserData?: {
                                                  __typename: "ExternalLinkTeaserData"
                                                  title?: string | null
                                                  summary?: string | null
                                                  iconUrl?: string | null
                                                  imageUrl?: string | null
                                                  status?: Types.LinkTargetStatus | null
                                                } | null
                                              }
                                            | { __typename: "ST4Class" }
                                            | {
                                                __typename: "ST4Node"
                                                id: string
                                                aspectId: any
                                                label?: string | null
                                                properties?: {
                                                  __typename: "ContentPropertyConnection"
                                                  edges?: Array<{
                                                    __typename: "ContentPropertyEdge"
                                                    node?: {
                                                      __typename: "ContentProperty"
                                                      name: string
                                                      value?: any | null
                                                      displayName: string
                                                    } | null
                                                  } | null> | null
                                                } | null
                                              }
                                            | { __typename: "ST4TreeNode" }
                                            | null
                                        }
                                      | {
                                          __typename: "NodeRef"
                                          id: string
                                          owner?:
                                            | { __typename: "CommentNode" }
                                            | { __typename: "ExternalSite" }
                                            | { __typename: "ST4Class" }
                                            | { __typename: "ST4Node"; id: string }
                                            | { __typename: "ST4TreeNode" }
                                            | null
                                          target?:
                                            | { __typename: "CommentNode" }
                                            | {
                                                __typename: "ExternalSite"
                                                id: string
                                                teaserData?: {
                                                  __typename: "ExternalLinkTeaserData"
                                                  title?: string | null
                                                  summary?: string | null
                                                  iconUrl?: string | null
                                                  imageUrl?: string | null
                                                  status?: Types.LinkTargetStatus | null
                                                } | null
                                              }
                                            | { __typename: "ST4Class" }
                                            | {
                                                __typename: "ST4Node"
                                                id: string
                                                aspectId: any
                                                label?: string | null
                                                properties?: {
                                                  __typename: "ContentPropertyConnection"
                                                  edges?: Array<{
                                                    __typename: "ContentPropertyEdge"
                                                    node?: {
                                                      __typename: "ContentProperty"
                                                      name: string
                                                      value?: any | null
                                                      displayName: string
                                                    } | null
                                                  } | null> | null
                                                } | null
                                              }
                                            | { __typename: "ST4TreeNode" }
                                            | null
                                        }
                                      | null
                                  }>
                                }
                              | {
                                  __typename: "VideoMediaInfo"
                                  id: string
                                  resourceId?: string | null
                                  thumbnail?: string | null
                                  width?: number | null
                                  height?: number | null
                                  mediaType?: Types.MediaType | null
                                }
                              | null
                          }
                        | {
                            __typename: "MediaGroupContent"
                            media?:
                              | {
                                  __typename: "AnimationMediaInfo"
                                  id: string
                                  resourceId?: string | null
                                  thumbnail?: string | null
                                  width?: number | null
                                  height?: number | null
                                  mediaType?: Types.MediaType | null
                                }
                              | {
                                  __typename: "ImageMediaInfo"
                                  id: string
                                  resourceId?: string | null
                                  thumbnail?: string | null
                                  width?: number | null
                                  height?: number | null
                                  resolution?: number | null
                                  colorDepth?: number | null
                                  size?: number | null
                                  mime?: string | null
                                  extension?: string | null
                                  allowCaching?: boolean | null
                                  mediaType?: Types.MediaType | null
                                  src?: string | null
                                  language?: string | null
                                }
                              | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                              | {
                                  __typename: "VectorGraphicsMediaInfo"
                                  id: string
                                  resourceId?: string | null
                                  thumbnail?: string | null
                                  width?: number | null
                                  height?: number | null
                                  allowCaching?: boolean | null
                                  mediaType?: Types.MediaType | null
                                  language?: string | null
                                  labels: Array<{
                                    __typename: "VectorGraphicsLabel"
                                    id?: string | null
                                    sequence?: number | null
                                    autoValue?: string | null
                                    text?: string | null
                                    linkType?: Types.VectorGraphicsLabelLinkType | null
                                    link?: string | null
                                    ref?:
                                      | {
                                          __typename: "GenericRef"
                                          id: string
                                          owner?:
                                            | { __typename: "CommentNode" }
                                            | { __typename: "ExternalSite" }
                                            | { __typename: "ST4Class" }
                                            | { __typename: "ST4Node"; id: string }
                                            | { __typename: "ST4TreeNode" }
                                            | null
                                          target?:
                                            | { __typename: "CommentNode" }
                                            | {
                                                __typename: "ExternalSite"
                                                id: string
                                                teaserData?: {
                                                  __typename: "ExternalLinkTeaserData"
                                                  title?: string | null
                                                  summary?: string | null
                                                  iconUrl?: string | null
                                                  imageUrl?: string | null
                                                  status?: Types.LinkTargetStatus | null
                                                } | null
                                              }
                                            | { __typename: "ST4Class" }
                                            | {
                                                __typename: "ST4Node"
                                                id: string
                                                aspectId: any
                                                label?: string | null
                                                properties?: {
                                                  __typename: "ContentPropertyConnection"
                                                  edges?: Array<{
                                                    __typename: "ContentPropertyEdge"
                                                    node?: {
                                                      __typename: "ContentProperty"
                                                      name: string
                                                      value?: any | null
                                                      displayName: string
                                                    } | null
                                                  } | null> | null
                                                } | null
                                              }
                                            | { __typename: "ST4TreeNode" }
                                            | null
                                        }
                                      | {
                                          __typename: "NodeRef"
                                          id: string
                                          owner?:
                                            | { __typename: "CommentNode" }
                                            | { __typename: "ExternalSite" }
                                            | { __typename: "ST4Class" }
                                            | { __typename: "ST4Node"; id: string }
                                            | { __typename: "ST4TreeNode" }
                                            | null
                                          target?:
                                            | { __typename: "CommentNode" }
                                            | {
                                                __typename: "ExternalSite"
                                                id: string
                                                teaserData?: {
                                                  __typename: "ExternalLinkTeaserData"
                                                  title?: string | null
                                                  summary?: string | null
                                                  iconUrl?: string | null
                                                  imageUrl?: string | null
                                                  status?: Types.LinkTargetStatus | null
                                                } | null
                                              }
                                            | { __typename: "ST4Class" }
                                            | {
                                                __typename: "ST4Node"
                                                id: string
                                                aspectId: any
                                                label?: string | null
                                                properties?: {
                                                  __typename: "ContentPropertyConnection"
                                                  edges?: Array<{
                                                    __typename: "ContentPropertyEdge"
                                                    node?: {
                                                      __typename: "ContentProperty"
                                                      name: string
                                                      value?: any | null
                                                      displayName: string
                                                    } | null
                                                  } | null> | null
                                                } | null
                                              }
                                            | { __typename: "ST4TreeNode" }
                                            | null
                                        }
                                      | null
                                  }>
                                }
                              | {
                                  __typename: "VideoMediaInfo"
                                  id: string
                                  resourceId?: string | null
                                  thumbnail?: string | null
                                  width?: number | null
                                  height?: number | null
                                  mediaType?: Types.MediaType | null
                                }
                              | null
                          }
                        | { __typename: "ProjectContent" }
                        | { __typename: "TermContent" }
                        | { __typename: "TextContent" }
                        | { __typename: "TextGroupContent" }
                        | { __typename: "VariableTableContent" }
                        | null
                    }
                  | { __typename: "ST4TreeNode"; id: string }
                  | null
              }>
              media?:
                | {
                    __typename: "AnimationMediaInfo"
                    id: string
                    resourceId?: string | null
                    thumbnail?: string | null
                    width?: number | null
                    height?: number | null
                    mediaType?: Types.MediaType | null
                  }
                | {
                    __typename: "ImageMediaInfo"
                    id: string
                    resourceId?: string | null
                    thumbnail?: string | null
                    width?: number | null
                    height?: number | null
                    resolution?: number | null
                    colorDepth?: number | null
                    size?: number | null
                    mime?: string | null
                    extension?: string | null
                    allowCaching?: boolean | null
                    mediaType?: Types.MediaType | null
                    src?: string | null
                    language?: string | null
                  }
                | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                | {
                    __typename: "VectorGraphicsMediaInfo"
                    id: string
                    resourceId?: string | null
                    thumbnail?: string | null
                    width?: number | null
                    height?: number | null
                    allowCaching?: boolean | null
                    mediaType?: Types.MediaType | null
                    language?: string | null
                    labels: Array<{
                      __typename: "VectorGraphicsLabel"
                      id?: string | null
                      sequence?: number | null
                      autoValue?: string | null
                      text?: string | null
                      linkType?: Types.VectorGraphicsLabelLinkType | null
                      link?: string | null
                      ref?:
                        | {
                            __typename: "GenericRef"
                            id: string
                            owner?:
                              | { __typename: "CommentNode" }
                              | { __typename: "ExternalSite" }
                              | { __typename: "ST4Class" }
                              | { __typename: "ST4Node"; id: string }
                              | { __typename: "ST4TreeNode" }
                              | null
                            target?:
                              | { __typename: "CommentNode" }
                              | {
                                  __typename: "ExternalSite"
                                  id: string
                                  teaserData?: {
                                    __typename: "ExternalLinkTeaserData"
                                    title?: string | null
                                    summary?: string | null
                                    iconUrl?: string | null
                                    imageUrl?: string | null
                                    status?: Types.LinkTargetStatus | null
                                  } | null
                                }
                              | { __typename: "ST4Class" }
                              | {
                                  __typename: "ST4Node"
                                  id: string
                                  aspectId: any
                                  label?: string | null
                                  properties?: {
                                    __typename: "ContentPropertyConnection"
                                    edges?: Array<{
                                      __typename: "ContentPropertyEdge"
                                      node?: {
                                        __typename: "ContentProperty"
                                        name: string
                                        value?: any | null
                                        displayName: string
                                      } | null
                                    } | null> | null
                                  } | null
                                }
                              | { __typename: "ST4TreeNode" }
                              | null
                          }
                        | {
                            __typename: "NodeRef"
                            id: string
                            owner?:
                              | { __typename: "CommentNode" }
                              | { __typename: "ExternalSite" }
                              | { __typename: "ST4Class" }
                              | { __typename: "ST4Node"; id: string }
                              | { __typename: "ST4TreeNode" }
                              | null
                            target?:
                              | { __typename: "CommentNode" }
                              | {
                                  __typename: "ExternalSite"
                                  id: string
                                  teaserData?: {
                                    __typename: "ExternalLinkTeaserData"
                                    title?: string | null
                                    summary?: string | null
                                    iconUrl?: string | null
                                    imageUrl?: string | null
                                    status?: Types.LinkTargetStatus | null
                                  } | null
                                }
                              | { __typename: "ST4Class" }
                              | {
                                  __typename: "ST4Node"
                                  id: string
                                  aspectId: any
                                  label?: string | null
                                  properties?: {
                                    __typename: "ContentPropertyConnection"
                                    edges?: Array<{
                                      __typename: "ContentPropertyEdge"
                                      node?: {
                                        __typename: "ContentProperty"
                                        name: string
                                        value?: any | null
                                        displayName: string
                                      } | null
                                    } | null> | null
                                  } | null
                                }
                              | { __typename: "ST4TreeNode" }
                              | null
                          }
                        | null
                    }>
                  }
                | {
                    __typename: "VideoMediaInfo"
                    id: string
                    resourceId?: string | null
                    thumbnail?: string | null
                    width?: number | null
                    height?: number | null
                    mediaType?: Types.MediaType | null
                  }
                | null
            }
          | { __typename: "ProjectContent" }
          | {
              __typename: "TermContent"
              gramaticalGender?: string | null
              validity?: string | null
              editLanguage?: string | null
            }
          | {
              __typename: "TextContent"
              id: string
              xast?: string | null
              historyNumber: number
              fragments: Array<{
                __typename: "TextContentFragment"
                compoundId: string
                contentNode: {
                  __typename: "ST4Node"
                  label?: string | null
                  id: string
                  aspectId: any
                  content?:
                    | { __typename: "LexiconContent" }
                    | { __typename: "LexiconEntryContent" }
                    | { __typename: "MediaContent" }
                    | { __typename: "MediaGroupContent" }
                    | { __typename: "ProjectContent" }
                    | { __typename: "TermContent" }
                    | {
                        __typename: "TextContent"
                        id: string
                        xast?: string | null
                        historyNumber: number
                        parts: Array<{
                          __typename: "TextContentPart"
                          ref: string
                          contentNode: {
                            __typename: "ST4Node"
                            id: string
                            aspectId: any
                            content?:
                              | { __typename: "LexiconContent" }
                              | { __typename: "LexiconEntryContent" }
                              | { __typename: "MediaContent" }
                              | { __typename: "MediaGroupContent" }
                              | { __typename: "ProjectContent" }
                              | { __typename: "TermContent" }
                              | {
                                  __typename: "TextContent"
                                  id: string
                                  xast?: string | null
                                  historyNumber: number
                                  links?: Array<{
                                    __typename: "TextContentLink"
                                    ref?: string | null
                                    node?:
                                      | { __typename: "CommentNode"; id: string }
                                      | { __typename: "ExternalSite"; id: string }
                                      | { __typename: "ST4Class"; id: string }
                                      | {
                                          __typename: "ST4Node"
                                          id: string
                                          aspectId: any
                                          label?: string | null
                                          properties?: {
                                            __typename: "ContentPropertyConnection"
                                            totalCount: number
                                            edges?: Array<{
                                              __typename: "ContentPropertyEdge"
                                              node?: {
                                                __typename: "ContentProperty"
                                                typeOfValue: Types.ContentPropertyValueType
                                                name: string
                                                value?: any | null
                                                displayName: string
                                              } | null
                                            } | null> | null
                                          } | null
                                        }
                                      | { __typename: "ST4TreeNode"; id: string }
                                      | null
                                  } | null> | null
                                  images: Array<{
                                    __typename: "NodeRef"
                                    id: string
                                    target?:
                                      | { __typename: "CommentNode"; id: string }
                                      | { __typename: "ExternalSite"; id: string }
                                      | { __typename: "ST4Class"; id: string }
                                      | {
                                          __typename: "ST4Node"
                                          aspectId: any
                                          id: string
                                          releaseState: {
                                            __typename: "ReleaseStateValue"
                                            displayName: string
                                            state: Types.ReleaseState
                                            isImplicitlyReleased: boolean
                                          }
                                          content?:
                                            | { __typename: "LexiconContent" }
                                            | { __typename: "LexiconEntryContent" }
                                            | {
                                                __typename: "MediaContent"
                                                media?:
                                                  | {
                                                      __typename: "AnimationMediaInfo"
                                                      id: string
                                                      resourceId?: string | null
                                                      thumbnail?: string | null
                                                      width?: number | null
                                                      height?: number | null
                                                      mediaType?: Types.MediaType | null
                                                    }
                                                  | {
                                                      __typename: "ImageMediaInfo"
                                                      id: string
                                                      resourceId?: string | null
                                                      thumbnail?: string | null
                                                      width?: number | null
                                                      height?: number | null
                                                      resolution?: number | null
                                                      colorDepth?: number | null
                                                      size?: number | null
                                                      mime?: string | null
                                                      extension?: string | null
                                                      allowCaching?: boolean | null
                                                      mediaType?: Types.MediaType | null
                                                      src?: string | null
                                                      language?: string | null
                                                    }
                                                  | {
                                                      __typename: "NoMediaInfo"
                                                      id: string
                                                      mediaType?: Types.MediaType | null
                                                    }
                                                  | {
                                                      __typename: "VectorGraphicsMediaInfo"
                                                      id: string
                                                      resourceId?: string | null
                                                      thumbnail?: string | null
                                                      width?: number | null
                                                      height?: number | null
                                                      allowCaching?: boolean | null
                                                      mediaType?: Types.MediaType | null
                                                      language?: string | null
                                                      labels: Array<{
                                                        __typename: "VectorGraphicsLabel"
                                                        id?: string | null
                                                        sequence?: number | null
                                                        autoValue?: string | null
                                                        text?: string | null
                                                        linkType?: Types.VectorGraphicsLabelLinkType | null
                                                        link?: string | null
                                                        ref?:
                                                          | {
                                                              __typename: "GenericRef"
                                                              id: string
                                                              owner?:
                                                                | { __typename: "CommentNode" }
                                                                | { __typename: "ExternalSite" }
                                                                | { __typename: "ST4Class" }
                                                                | { __typename: "ST4Node"; id: string }
                                                                | { __typename: "ST4TreeNode" }
                                                                | null
                                                              target?:
                                                                | { __typename: "CommentNode" }
                                                                | {
                                                                    __typename: "ExternalSite"
                                                                    id: string
                                                                    teaserData?: {
                                                                      __typename: "ExternalLinkTeaserData"
                                                                      title?: string | null
                                                                      summary?: string | null
                                                                      iconUrl?: string | null
                                                                      imageUrl?: string | null
                                                                      status?: Types.LinkTargetStatus | null
                                                                    } | null
                                                                  }
                                                                | { __typename: "ST4Class" }
                                                                | {
                                                                    __typename: "ST4Node"
                                                                    id: string
                                                                    aspectId: any
                                                                    label?: string | null
                                                                    properties?: {
                                                                      __typename: "ContentPropertyConnection"
                                                                      edges?: Array<{
                                                                        __typename: "ContentPropertyEdge"
                                                                        node?: {
                                                                          __typename: "ContentProperty"
                                                                          name: string
                                                                          value?: any | null
                                                                          displayName: string
                                                                        } | null
                                                                      } | null> | null
                                                                    } | null
                                                                  }
                                                                | { __typename: "ST4TreeNode" }
                                                                | null
                                                            }
                                                          | {
                                                              __typename: "NodeRef"
                                                              id: string
                                                              owner?:
                                                                | { __typename: "CommentNode" }
                                                                | { __typename: "ExternalSite" }
                                                                | { __typename: "ST4Class" }
                                                                | { __typename: "ST4Node"; id: string }
                                                                | { __typename: "ST4TreeNode" }
                                                                | null
                                                              target?:
                                                                | { __typename: "CommentNode" }
                                                                | {
                                                                    __typename: "ExternalSite"
                                                                    id: string
                                                                    teaserData?: {
                                                                      __typename: "ExternalLinkTeaserData"
                                                                      title?: string | null
                                                                      summary?: string | null
                                                                      iconUrl?: string | null
                                                                      imageUrl?: string | null
                                                                      status?: Types.LinkTargetStatus | null
                                                                    } | null
                                                                  }
                                                                | { __typename: "ST4Class" }
                                                                | {
                                                                    __typename: "ST4Node"
                                                                    id: string
                                                                    aspectId: any
                                                                    label?: string | null
                                                                    properties?: {
                                                                      __typename: "ContentPropertyConnection"
                                                                      edges?: Array<{
                                                                        __typename: "ContentPropertyEdge"
                                                                        node?: {
                                                                          __typename: "ContentProperty"
                                                                          name: string
                                                                          value?: any | null
                                                                          displayName: string
                                                                        } | null
                                                                      } | null> | null
                                                                    } | null
                                                                  }
                                                                | { __typename: "ST4TreeNode" }
                                                                | null
                                                            }
                                                          | null
                                                      }>
                                                    }
                                                  | {
                                                      __typename: "VideoMediaInfo"
                                                      id: string
                                                      resourceId?: string | null
                                                      thumbnail?: string | null
                                                      width?: number | null
                                                      height?: number | null
                                                      mediaType?: Types.MediaType | null
                                                    }
                                                  | null
                                              }
                                            | {
                                                __typename: "MediaGroupContent"
                                                children: Array<{
                                                  __typename: "NodeRef"
                                                  id: string
                                                  target?:
                                                    | { __typename: "CommentNode"; id: string }
                                                    | { __typename: "ExternalSite"; id: string }
                                                    | { __typename: "ST4Class"; id: string }
                                                    | {
                                                        __typename: "ST4Node"
                                                        id: string
                                                        aspectId: any
                                                        content?:
                                                          | { __typename: "LexiconContent" }
                                                          | { __typename: "LexiconEntryContent" }
                                                          | {
                                                              __typename: "MediaContent"
                                                              media?:
                                                                | {
                                                                    __typename: "AnimationMediaInfo"
                                                                    id: string
                                                                    resourceId?: string | null
                                                                    thumbnail?: string | null
                                                                    width?: number | null
                                                                    height?: number | null
                                                                    mediaType?: Types.MediaType | null
                                                                  }
                                                                | {
                                                                    __typename: "ImageMediaInfo"
                                                                    id: string
                                                                    resourceId?: string | null
                                                                    thumbnail?: string | null
                                                                    width?: number | null
                                                                    height?: number | null
                                                                    resolution?: number | null
                                                                    colorDepth?: number | null
                                                                    size?: number | null
                                                                    mime?: string | null
                                                                    extension?: string | null
                                                                    allowCaching?: boolean | null
                                                                    mediaType?: Types.MediaType | null
                                                                    src?: string | null
                                                                    language?: string | null
                                                                  }
                                                                | {
                                                                    __typename: "NoMediaInfo"
                                                                    id: string
                                                                    mediaType?: Types.MediaType | null
                                                                  }
                                                                | {
                                                                    __typename: "VectorGraphicsMediaInfo"
                                                                    id: string
                                                                    resourceId?: string | null
                                                                    thumbnail?: string | null
                                                                    width?: number | null
                                                                    height?: number | null
                                                                    allowCaching?: boolean | null
                                                                    mediaType?: Types.MediaType | null
                                                                    language?: string | null
                                                                    labels: Array<{
                                                                      __typename: "VectorGraphicsLabel"
                                                                      id?: string | null
                                                                      sequence?: number | null
                                                                      autoValue?: string | null
                                                                      text?: string | null
                                                                      linkType?: Types.VectorGraphicsLabelLinkType | null
                                                                      link?: string | null
                                                                      ref?:
                                                                        | {
                                                                            __typename: "GenericRef"
                                                                            id: string
                                                                            owner?:
                                                                              | { __typename: "CommentNode" }
                                                                              | { __typename: "ExternalSite" }
                                                                              | { __typename: "ST4Class" }
                                                                              | { __typename: "ST4Node"; id: string }
                                                                              | { __typename: "ST4TreeNode" }
                                                                              | null
                                                                            target?:
                                                                              | { __typename: "CommentNode" }
                                                                              | {
                                                                                  __typename: "ExternalSite"
                                                                                  id: string
                                                                                  teaserData?: {
                                                                                    __typename: "ExternalLinkTeaserData"
                                                                                    title?: string | null
                                                                                    summary?: string | null
                                                                                    iconUrl?: string | null
                                                                                    imageUrl?: string | null
                                                                                    status?: Types.LinkTargetStatus | null
                                                                                  } | null
                                                                                }
                                                                              | { __typename: "ST4Class" }
                                                                              | {
                                                                                  __typename: "ST4Node"
                                                                                  id: string
                                                                                  aspectId: any
                                                                                  label?: string | null
                                                                                  properties?: {
                                                                                    __typename: "ContentPropertyConnection"
                                                                                    edges?: Array<{
                                                                                      __typename: "ContentPropertyEdge"
                                                                                      node?: {
                                                                                        __typename: "ContentProperty"
                                                                                        name: string
                                                                                        value?: any | null
                                                                                        displayName: string
                                                                                      } | null
                                                                                    } | null> | null
                                                                                  } | null
                                                                                }
                                                                              | { __typename: "ST4TreeNode" }
                                                                              | null
                                                                          }
                                                                        | {
                                                                            __typename: "NodeRef"
                                                                            id: string
                                                                            owner?:
                                                                              | { __typename: "CommentNode" }
                                                                              | { __typename: "ExternalSite" }
                                                                              | { __typename: "ST4Class" }
                                                                              | { __typename: "ST4Node"; id: string }
                                                                              | { __typename: "ST4TreeNode" }
                                                                              | null
                                                                            target?:
                                                                              | { __typename: "CommentNode" }
                                                                              | {
                                                                                  __typename: "ExternalSite"
                                                                                  id: string
                                                                                  teaserData?: {
                                                                                    __typename: "ExternalLinkTeaserData"
                                                                                    title?: string | null
                                                                                    summary?: string | null
                                                                                    iconUrl?: string | null
                                                                                    imageUrl?: string | null
                                                                                    status?: Types.LinkTargetStatus | null
                                                                                  } | null
                                                                                }
                                                                              | { __typename: "ST4Class" }
                                                                              | {
                                                                                  __typename: "ST4Node"
                                                                                  id: string
                                                                                  aspectId: any
                                                                                  label?: string | null
                                                                                  properties?: {
                                                                                    __typename: "ContentPropertyConnection"
                                                                                    edges?: Array<{
                                                                                      __typename: "ContentPropertyEdge"
                                                                                      node?: {
                                                                                        __typename: "ContentProperty"
                                                                                        name: string
                                                                                        value?: any | null
                                                                                        displayName: string
                                                                                      } | null
                                                                                    } | null> | null
                                                                                  } | null
                                                                                }
                                                                              | { __typename: "ST4TreeNode" }
                                                                              | null
                                                                          }
                                                                        | null
                                                                    }>
                                                                  }
                                                                | {
                                                                    __typename: "VideoMediaInfo"
                                                                    id: string
                                                                    resourceId?: string | null
                                                                    thumbnail?: string | null
                                                                    width?: number | null
                                                                    height?: number | null
                                                                    mediaType?: Types.MediaType | null
                                                                  }
                                                                | null
                                                            }
                                                          | {
                                                              __typename: "MediaGroupContent"
                                                              media?:
                                                                | {
                                                                    __typename: "AnimationMediaInfo"
                                                                    id: string
                                                                    resourceId?: string | null
                                                                    thumbnail?: string | null
                                                                    width?: number | null
                                                                    height?: number | null
                                                                    mediaType?: Types.MediaType | null
                                                                  }
                                                                | {
                                                                    __typename: "ImageMediaInfo"
                                                                    id: string
                                                                    resourceId?: string | null
                                                                    thumbnail?: string | null
                                                                    width?: number | null
                                                                    height?: number | null
                                                                    resolution?: number | null
                                                                    colorDepth?: number | null
                                                                    size?: number | null
                                                                    mime?: string | null
                                                                    extension?: string | null
                                                                    allowCaching?: boolean | null
                                                                    mediaType?: Types.MediaType | null
                                                                    src?: string | null
                                                                    language?: string | null
                                                                  }
                                                                | {
                                                                    __typename: "NoMediaInfo"
                                                                    id: string
                                                                    mediaType?: Types.MediaType | null
                                                                  }
                                                                | {
                                                                    __typename: "VectorGraphicsMediaInfo"
                                                                    id: string
                                                                    resourceId?: string | null
                                                                    thumbnail?: string | null
                                                                    width?: number | null
                                                                    height?: number | null
                                                                    allowCaching?: boolean | null
                                                                    mediaType?: Types.MediaType | null
                                                                    language?: string | null
                                                                    labels: Array<{
                                                                      __typename: "VectorGraphicsLabel"
                                                                      id?: string | null
                                                                      sequence?: number | null
                                                                      autoValue?: string | null
                                                                      text?: string | null
                                                                      linkType?: Types.VectorGraphicsLabelLinkType | null
                                                                      link?: string | null
                                                                      ref?:
                                                                        | {
                                                                            __typename: "GenericRef"
                                                                            id: string
                                                                            owner?:
                                                                              | { __typename: "CommentNode" }
                                                                              | { __typename: "ExternalSite" }
                                                                              | { __typename: "ST4Class" }
                                                                              | { __typename: "ST4Node"; id: string }
                                                                              | { __typename: "ST4TreeNode" }
                                                                              | null
                                                                            target?:
                                                                              | { __typename: "CommentNode" }
                                                                              | {
                                                                                  __typename: "ExternalSite"
                                                                                  id: string
                                                                                  teaserData?: {
                                                                                    __typename: "ExternalLinkTeaserData"
                                                                                    title?: string | null
                                                                                    summary?: string | null
                                                                                    iconUrl?: string | null
                                                                                    imageUrl?: string | null
                                                                                    status?: Types.LinkTargetStatus | null
                                                                                  } | null
                                                                                }
                                                                              | { __typename: "ST4Class" }
                                                                              | {
                                                                                  __typename: "ST4Node"
                                                                                  id: string
                                                                                  aspectId: any
                                                                                  label?: string | null
                                                                                  properties?: {
                                                                                    __typename: "ContentPropertyConnection"
                                                                                    edges?: Array<{
                                                                                      __typename: "ContentPropertyEdge"
                                                                                      node?: {
                                                                                        __typename: "ContentProperty"
                                                                                        name: string
                                                                                        value?: any | null
                                                                                        displayName: string
                                                                                      } | null
                                                                                    } | null> | null
                                                                                  } | null
                                                                                }
                                                                              | { __typename: "ST4TreeNode" }
                                                                              | null
                                                                          }
                                                                        | {
                                                                            __typename: "NodeRef"
                                                                            id: string
                                                                            owner?:
                                                                              | { __typename: "CommentNode" }
                                                                              | { __typename: "ExternalSite" }
                                                                              | { __typename: "ST4Class" }
                                                                              | { __typename: "ST4Node"; id: string }
                                                                              | { __typename: "ST4TreeNode" }
                                                                              | null
                                                                            target?:
                                                                              | { __typename: "CommentNode" }
                                                                              | {
                                                                                  __typename: "ExternalSite"
                                                                                  id: string
                                                                                  teaserData?: {
                                                                                    __typename: "ExternalLinkTeaserData"
                                                                                    title?: string | null
                                                                                    summary?: string | null
                                                                                    iconUrl?: string | null
                                                                                    imageUrl?: string | null
                                                                                    status?: Types.LinkTargetStatus | null
                                                                                  } | null
                                                                                }
                                                                              | { __typename: "ST4Class" }
                                                                              | {
                                                                                  __typename: "ST4Node"
                                                                                  id: string
                                                                                  aspectId: any
                                                                                  label?: string | null
                                                                                  properties?: {
                                                                                    __typename: "ContentPropertyConnection"
                                                                                    edges?: Array<{
                                                                                      __typename: "ContentPropertyEdge"
                                                                                      node?: {
                                                                                        __typename: "ContentProperty"
                                                                                        name: string
                                                                                        value?: any | null
                                                                                        displayName: string
                                                                                      } | null
                                                                                    } | null> | null
                                                                                  } | null
                                                                                }
                                                                              | { __typename: "ST4TreeNode" }
                                                                              | null
                                                                          }
                                                                        | null
                                                                    }>
                                                                  }
                                                                | {
                                                                    __typename: "VideoMediaInfo"
                                                                    id: string
                                                                    resourceId?: string | null
                                                                    thumbnail?: string | null
                                                                    width?: number | null
                                                                    height?: number | null
                                                                    mediaType?: Types.MediaType | null
                                                                  }
                                                                | null
                                                            }
                                                          | { __typename: "ProjectContent" }
                                                          | { __typename: "TermContent" }
                                                          | { __typename: "TextContent" }
                                                          | { __typename: "TextGroupContent" }
                                                          | { __typename: "VariableTableContent" }
                                                          | null
                                                      }
                                                    | { __typename: "ST4TreeNode"; id: string }
                                                    | null
                                                }>
                                                media?:
                                                  | {
                                                      __typename: "AnimationMediaInfo"
                                                      id: string
                                                      resourceId?: string | null
                                                      thumbnail?: string | null
                                                      width?: number | null
                                                      height?: number | null
                                                      mediaType?: Types.MediaType | null
                                                    }
                                                  | {
                                                      __typename: "ImageMediaInfo"
                                                      id: string
                                                      resourceId?: string | null
                                                      thumbnail?: string | null
                                                      width?: number | null
                                                      height?: number | null
                                                      resolution?: number | null
                                                      colorDepth?: number | null
                                                      size?: number | null
                                                      mime?: string | null
                                                      extension?: string | null
                                                      allowCaching?: boolean | null
                                                      mediaType?: Types.MediaType | null
                                                      src?: string | null
                                                      language?: string | null
                                                    }
                                                  | {
                                                      __typename: "NoMediaInfo"
                                                      id: string
                                                      mediaType?: Types.MediaType | null
                                                    }
                                                  | {
                                                      __typename: "VectorGraphicsMediaInfo"
                                                      id: string
                                                      resourceId?: string | null
                                                      thumbnail?: string | null
                                                      width?: number | null
                                                      height?: number | null
                                                      allowCaching?: boolean | null
                                                      mediaType?: Types.MediaType | null
                                                      language?: string | null
                                                      labels: Array<{
                                                        __typename: "VectorGraphicsLabel"
                                                        id?: string | null
                                                        sequence?: number | null
                                                        autoValue?: string | null
                                                        text?: string | null
                                                        linkType?: Types.VectorGraphicsLabelLinkType | null
                                                        link?: string | null
                                                        ref?:
                                                          | {
                                                              __typename: "GenericRef"
                                                              id: string
                                                              owner?:
                                                                | { __typename: "CommentNode" }
                                                                | { __typename: "ExternalSite" }
                                                                | { __typename: "ST4Class" }
                                                                | { __typename: "ST4Node"; id: string }
                                                                | { __typename: "ST4TreeNode" }
                                                                | null
                                                              target?:
                                                                | { __typename: "CommentNode" }
                                                                | {
                                                                    __typename: "ExternalSite"
                                                                    id: string
                                                                    teaserData?: {
                                                                      __typename: "ExternalLinkTeaserData"
                                                                      title?: string | null
                                                                      summary?: string | null
                                                                      iconUrl?: string | null
                                                                      imageUrl?: string | null
                                                                      status?: Types.LinkTargetStatus | null
                                                                    } | null
                                                                  }
                                                                | { __typename: "ST4Class" }
                                                                | {
                                                                    __typename: "ST4Node"
                                                                    id: string
                                                                    aspectId: any
                                                                    label?: string | null
                                                                    properties?: {
                                                                      __typename: "ContentPropertyConnection"
                                                                      edges?: Array<{
                                                                        __typename: "ContentPropertyEdge"
                                                                        node?: {
                                                                          __typename: "ContentProperty"
                                                                          name: string
                                                                          value?: any | null
                                                                          displayName: string
                                                                        } | null
                                                                      } | null> | null
                                                                    } | null
                                                                  }
                                                                | { __typename: "ST4TreeNode" }
                                                                | null
                                                            }
                                                          | {
                                                              __typename: "NodeRef"
                                                              id: string
                                                              owner?:
                                                                | { __typename: "CommentNode" }
                                                                | { __typename: "ExternalSite" }
                                                                | { __typename: "ST4Class" }
                                                                | { __typename: "ST4Node"; id: string }
                                                                | { __typename: "ST4TreeNode" }
                                                                | null
                                                              target?:
                                                                | { __typename: "CommentNode" }
                                                                | {
                                                                    __typename: "ExternalSite"
                                                                    id: string
                                                                    teaserData?: {
                                                                      __typename: "ExternalLinkTeaserData"
                                                                      title?: string | null
                                                                      summary?: string | null
                                                                      iconUrl?: string | null
                                                                      imageUrl?: string | null
                                                                      status?: Types.LinkTargetStatus | null
                                                                    } | null
                                                                  }
                                                                | { __typename: "ST4Class" }
                                                                | {
                                                                    __typename: "ST4Node"
                                                                    id: string
                                                                    aspectId: any
                                                                    label?: string | null
                                                                    properties?: {
                                                                      __typename: "ContentPropertyConnection"
                                                                      edges?: Array<{
                                                                        __typename: "ContentPropertyEdge"
                                                                        node?: {
                                                                          __typename: "ContentProperty"
                                                                          name: string
                                                                          value?: any | null
                                                                          displayName: string
                                                                        } | null
                                                                      } | null> | null
                                                                    } | null
                                                                  }
                                                                | { __typename: "ST4TreeNode" }
                                                                | null
                                                            }
                                                          | null
                                                      }>
                                                    }
                                                  | {
                                                      __typename: "VideoMediaInfo"
                                                      id: string
                                                      resourceId?: string | null
                                                      thumbnail?: string | null
                                                      width?: number | null
                                                      height?: number | null
                                                      mediaType?: Types.MediaType | null
                                                    }
                                                  | null
                                              }
                                            | { __typename: "ProjectContent" }
                                            | { __typename: "TermContent" }
                                            | { __typename: "TextContent" }
                                            | { __typename: "TextGroupContent" }
                                            | { __typename: "VariableTableContent" }
                                            | null
                                        }
                                      | { __typename: "ST4TreeNode"; id: string }
                                      | null
                                  }>
                                  variableTables?: Array<{
                                    __typename: "VariableTableReference"
                                    ref?: string | null
                                    node?:
                                      | { __typename: "CommentNode" }
                                      | { __typename: "ExternalSite" }
                                      | { __typename: "ST4Class" }
                                      | {
                                          __typename: "ST4Node"
                                          id: string
                                          label?: string | null
                                          aspectId: any
                                          content?:
                                            | { __typename: "LexiconContent" }
                                            | { __typename: "LexiconEntryContent" }
                                            | { __typename: "MediaContent" }
                                            | { __typename: "MediaGroupContent" }
                                            | { __typename: "ProjectContent" }
                                            | { __typename: "TermContent" }
                                            | { __typename: "TextContent" }
                                            | { __typename: "TextGroupContent" }
                                            | {
                                                __typename: "VariableTableContent"
                                                xml?: string | null
                                                valueSets: Array<{
                                                  __typename: "ValueSet"
                                                  name: string
                                                  valueSetId: string
                                                }>
                                                variables: Array<{
                                                  __typename: "Variable"
                                                  name: string
                                                  variableId: string
                                                  values: Array<string>
                                                }>
                                              }
                                            | null
                                        }
                                      | { __typename: "ST4TreeNode" }
                                      | null
                                  } | null> | null
                                  linkedDataNodes: Array<{
                                    __typename: "NodeRef"
                                    id: string
                                    target?:
                                      | { __typename: "CommentNode"; id: string }
                                      | { __typename: "ExternalSite"; id: string }
                                      | { __typename: "ST4Class"; id: string }
                                      | {
                                          __typename: "ST4Node"
                                          label?: string | null
                                          id: string
                                          metadata: Array<{
                                            __typename: "MetaDataEntry"
                                            label?: string | null
                                            systemName: string
                                            value?: string | null
                                            values: Array<string>
                                            users: Array<string>
                                          }>
                                          linkedOntologyNodes: Array<
                                            | {
                                                __typename: "GenericRef"
                                                target?:
                                                  | { __typename: "CommentNode" }
                                                  | { __typename: "ExternalSite" }
                                                  | { __typename: "ST4Class" }
                                                  | { __typename: "ST4Node"; id: string; label?: string | null }
                                                  | { __typename: "ST4TreeNode" }
                                                  | null
                                              }
                                            | {
                                                __typename: "NodeRef"
                                                linkClassName: string
                                                class?: { __typename: "ST4Class"; label?: string | null } | null
                                                target?:
                                                  | { __typename: "CommentNode" }
                                                  | { __typename: "ExternalSite" }
                                                  | { __typename: "ST4Class" }
                                                  | { __typename: "ST4Node"; id: string; label?: string | null }
                                                  | { __typename: "ST4TreeNode" }
                                                  | null
                                              }
                                          >
                                        }
                                      | { __typename: "ST4TreeNode"; id: string }
                                      | null
                                  }>
                                  lexiconEntries?: Array<{
                                    __typename: "NodeRef"
                                    id: string
                                    target?:
                                      | { __typename: "CommentNode"; id: string }
                                      | { __typename: "ExternalSite"; id: string }
                                      | { __typename: "ST4Class"; id: string }
                                      | {
                                          __typename: "ST4Node"
                                          aspectId: any
                                          label?: string | null
                                          id: string
                                          content?:
                                            | { __typename: "LexiconContent" }
                                            | {
                                                __typename: "LexiconEntryContent"
                                                descriptionXast?: string | null
                                                subject?: string | null
                                                sortKey?: string | null
                                                lexicon:
                                                  | { __typename: "CommentNode"; id: string }
                                                  | { __typename: "ExternalSite"; id: string }
                                                  | { __typename: "ST4Class"; id: string }
                                                  | { __typename: "ST4Node"; label?: string | null; id: string }
                                                  | { __typename: "ST4TreeNode"; id: string }
                                                terms: Array<
                                                  | { __typename: "CommentNode"; id: string }
                                                  | { __typename: "ExternalSite"; id: string }
                                                  | { __typename: "ST4Class"; id: string }
                                                  | {
                                                      __typename: "ST4Node"
                                                      id: string
                                                      label?: string | null
                                                      aspectId: any
                                                      content?:
                                                        | { __typename: "LexiconContent" }
                                                        | { __typename: "LexiconEntryContent" }
                                                        | { __typename: "MediaContent" }
                                                        | { __typename: "MediaGroupContent" }
                                                        | { __typename: "ProjectContent" }
                                                        | {
                                                            __typename: "TermContent"
                                                            gramaticalGender?: string | null
                                                            validity?: string | null
                                                            editLanguage?: string | null
                                                          }
                                                        | { __typename: "TextContent" }
                                                        | { __typename: "TextGroupContent" }
                                                        | { __typename: "VariableTableContent" }
                                                        | null
                                                    }
                                                  | { __typename: "ST4TreeNode"; id: string }
                                                >
                                                image?:
                                                  | { __typename: "CommentNode"; id: string }
                                                  | { __typename: "ExternalSite"; id: string }
                                                  | { __typename: "ST4Class"; id: string }
                                                  | {
                                                      __typename: "ST4Node"
                                                      aspectId: any
                                                      id: string
                                                      content?:
                                                        | { __typename: "LexiconContent" }
                                                        | { __typename: "LexiconEntryContent" }
                                                        | {
                                                            __typename: "MediaContent"
                                                            media?:
                                                              | {
                                                                  __typename: "AnimationMediaInfo"
                                                                  thumbnail?: string | null
                                                                  mediaType?: Types.MediaType | null
                                                                }
                                                              | {
                                                                  __typename: "ImageMediaInfo"
                                                                  src?: string | null
                                                                  thumbnail?: string | null
                                                                  mediaType?: Types.MediaType | null
                                                                }
                                                              | { __typename: "NoMediaInfo" }
                                                              | {
                                                                  __typename: "VectorGraphicsMediaInfo"
                                                                  svg?: string | null
                                                                  thumbnail?: string | null
                                                                  mediaType?: Types.MediaType | null
                                                                }
                                                              | {
                                                                  __typename: "VideoMediaInfo"
                                                                  thumbnail?: string | null
                                                                  mediaType?: Types.MediaType | null
                                                                }
                                                              | null
                                                          }
                                                        | { __typename: "MediaGroupContent" }
                                                        | { __typename: "ProjectContent" }
                                                        | { __typename: "TermContent" }
                                                        | { __typename: "TextContent" }
                                                        | { __typename: "TextGroupContent" }
                                                        | { __typename: "VariableTableContent" }
                                                        | null
                                                    }
                                                  | { __typename: "ST4TreeNode"; id: string }
                                                  | null
                                              }
                                            | { __typename: "MediaContent" }
                                            | { __typename: "MediaGroupContent" }
                                            | { __typename: "ProjectContent" }
                                            | { __typename: "TermContent" }
                                            | { __typename: "TextContent" }
                                            | { __typename: "TextGroupContent" }
                                            | { __typename: "VariableTableContent" }
                                            | null
                                        }
                                      | { __typename: "ST4TreeNode"; id: string }
                                      | null
                                  } | null> | null
                                  comments: Array<{
                                    __typename: "CommentNode"
                                    id: string
                                    comment:
                                      | {
                                          __typename: "BlockComment"
                                          location: number
                                          value?: string | null
                                          authorDisplayName?: string | null
                                          authorUserName?: string | null
                                          commentKey: string
                                          editDate?: any | null
                                          modificationVersion?: number | null
                                          replyToKey?: string | null
                                          sortOrder?: number | null
                                          commentType?: string | null
                                          state?: string | null
                                          color: string
                                          persistanceState?: Types.PersistanceState | null
                                          persistanceAction?: Types.PersistanceAction | null
                                          node: {
                                            __typename: "ST4Node"
                                            aspectId: any
                                            id: string
                                            content?:
                                              | { __typename: "LexiconContent" }
                                              | { __typename: "LexiconEntryContent" }
                                              | { __typename: "MediaContent" }
                                              | { __typename: "MediaGroupContent" }
                                              | { __typename: "ProjectContent" }
                                              | { __typename: "TermContent" }
                                              | { __typename: "TextContent"; id: string; historyNumber: number }
                                              | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                                              | { __typename: "VariableTableContent" }
                                              | null
                                          }
                                          actions: Array<{
                                            __typename: "Action"
                                            name?: string | null
                                            enabled?: boolean | null
                                          }>
                                        }
                                      | {
                                          __typename: "TextComment"
                                          endLocation: number
                                          startLocation?: number | null
                                          value?: string | null
                                          authorDisplayName?: string | null
                                          authorUserName?: string | null
                                          commentKey: string
                                          editDate?: any | null
                                          modificationVersion?: number | null
                                          replyToKey?: string | null
                                          sortOrder?: number | null
                                          commentType?: string | null
                                          state?: string | null
                                          color: string
                                          persistanceState?: Types.PersistanceState | null
                                          persistanceAction?: Types.PersistanceAction | null
                                          node: {
                                            __typename: "ST4Node"
                                            aspectId: any
                                            id: string
                                            content?:
                                              | { __typename: "LexiconContent" }
                                              | { __typename: "LexiconEntryContent" }
                                              | { __typename: "MediaContent" }
                                              | { __typename: "MediaGroupContent" }
                                              | { __typename: "ProjectContent" }
                                              | { __typename: "TermContent" }
                                              | { __typename: "TextContent"; id: string; historyNumber: number }
                                              | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                                              | { __typename: "VariableTableContent" }
                                              | null
                                          }
                                          actions: Array<{
                                            __typename: "Action"
                                            name?: string | null
                                            enabled?: boolean | null
                                          }>
                                        }
                                  }>
                                }
                              | {
                                  __typename: "TextGroupContent"
                                  currentNodeId?: any | null
                                  groupNodeId: any
                                  id: string
                                  xast?: string | null
                                  historyNumber: number
                                  children: Array<{
                                    __typename: "NodeRef"
                                    id: string
                                    target?:
                                      | { __typename: "CommentNode"; id: string }
                                      | { __typename: "ExternalSite"; id: string }
                                      | { __typename: "ST4Class"; id: string }
                                      | { __typename: "ST4Node"; label?: string | null; aspectId: any; id: string }
                                      | { __typename: "ST4TreeNode"; id: string }
                                      | null
                                  }>
                                  links?: Array<{
                                    __typename: "TextContentLink"
                                    ref?: string | null
                                    node?:
                                      | { __typename: "CommentNode"; id: string }
                                      | { __typename: "ExternalSite"; id: string }
                                      | { __typename: "ST4Class"; id: string }
                                      | {
                                          __typename: "ST4Node"
                                          id: string
                                          aspectId: any
                                          label?: string | null
                                          properties?: {
                                            __typename: "ContentPropertyConnection"
                                            totalCount: number
                                            edges?: Array<{
                                              __typename: "ContentPropertyEdge"
                                              node?: {
                                                __typename: "ContentProperty"
                                                typeOfValue: Types.ContentPropertyValueType
                                                name: string
                                                value?: any | null
                                                displayName: string
                                              } | null
                                            } | null> | null
                                          } | null
                                        }
                                      | { __typename: "ST4TreeNode"; id: string }
                                      | null
                                  } | null> | null
                                  images: Array<{
                                    __typename: "NodeRef"
                                    id: string
                                    target?:
                                      | { __typename: "CommentNode"; id: string }
                                      | { __typename: "ExternalSite"; id: string }
                                      | { __typename: "ST4Class"; id: string }
                                      | {
                                          __typename: "ST4Node"
                                          aspectId: any
                                          id: string
                                          releaseState: {
                                            __typename: "ReleaseStateValue"
                                            displayName: string
                                            state: Types.ReleaseState
                                            isImplicitlyReleased: boolean
                                          }
                                          content?:
                                            | { __typename: "LexiconContent" }
                                            | { __typename: "LexiconEntryContent" }
                                            | {
                                                __typename: "MediaContent"
                                                media?:
                                                  | {
                                                      __typename: "AnimationMediaInfo"
                                                      id: string
                                                      resourceId?: string | null
                                                      thumbnail?: string | null
                                                      width?: number | null
                                                      height?: number | null
                                                      mediaType?: Types.MediaType | null
                                                    }
                                                  | {
                                                      __typename: "ImageMediaInfo"
                                                      id: string
                                                      resourceId?: string | null
                                                      thumbnail?: string | null
                                                      width?: number | null
                                                      height?: number | null
                                                      resolution?: number | null
                                                      colorDepth?: number | null
                                                      size?: number | null
                                                      mime?: string | null
                                                      extension?: string | null
                                                      allowCaching?: boolean | null
                                                      mediaType?: Types.MediaType | null
                                                      src?: string | null
                                                      language?: string | null
                                                    }
                                                  | {
                                                      __typename: "NoMediaInfo"
                                                      id: string
                                                      mediaType?: Types.MediaType | null
                                                    }
                                                  | {
                                                      __typename: "VectorGraphicsMediaInfo"
                                                      id: string
                                                      resourceId?: string | null
                                                      thumbnail?: string | null
                                                      width?: number | null
                                                      height?: number | null
                                                      allowCaching?: boolean | null
                                                      mediaType?: Types.MediaType | null
                                                      language?: string | null
                                                      labels: Array<{
                                                        __typename: "VectorGraphicsLabel"
                                                        id?: string | null
                                                        sequence?: number | null
                                                        autoValue?: string | null
                                                        text?: string | null
                                                        linkType?: Types.VectorGraphicsLabelLinkType | null
                                                        link?: string | null
                                                        ref?:
                                                          | {
                                                              __typename: "GenericRef"
                                                              id: string
                                                              owner?:
                                                                | { __typename: "CommentNode" }
                                                                | { __typename: "ExternalSite" }
                                                                | { __typename: "ST4Class" }
                                                                | { __typename: "ST4Node"; id: string }
                                                                | { __typename: "ST4TreeNode" }
                                                                | null
                                                              target?:
                                                                | { __typename: "CommentNode" }
                                                                | {
                                                                    __typename: "ExternalSite"
                                                                    id: string
                                                                    teaserData?: {
                                                                      __typename: "ExternalLinkTeaserData"
                                                                      title?: string | null
                                                                      summary?: string | null
                                                                      iconUrl?: string | null
                                                                      imageUrl?: string | null
                                                                      status?: Types.LinkTargetStatus | null
                                                                    } | null
                                                                  }
                                                                | { __typename: "ST4Class" }
                                                                | {
                                                                    __typename: "ST4Node"
                                                                    id: string
                                                                    aspectId: any
                                                                    label?: string | null
                                                                    properties?: {
                                                                      __typename: "ContentPropertyConnection"
                                                                      edges?: Array<{
                                                                        __typename: "ContentPropertyEdge"
                                                                        node?: {
                                                                          __typename: "ContentProperty"
                                                                          name: string
                                                                          value?: any | null
                                                                          displayName: string
                                                                        } | null
                                                                      } | null> | null
                                                                    } | null
                                                                  }
                                                                | { __typename: "ST4TreeNode" }
                                                                | null
                                                            }
                                                          | {
                                                              __typename: "NodeRef"
                                                              id: string
                                                              owner?:
                                                                | { __typename: "CommentNode" }
                                                                | { __typename: "ExternalSite" }
                                                                | { __typename: "ST4Class" }
                                                                | { __typename: "ST4Node"; id: string }
                                                                | { __typename: "ST4TreeNode" }
                                                                | null
                                                              target?:
                                                                | { __typename: "CommentNode" }
                                                                | {
                                                                    __typename: "ExternalSite"
                                                                    id: string
                                                                    teaserData?: {
                                                                      __typename: "ExternalLinkTeaserData"
                                                                      title?: string | null
                                                                      summary?: string | null
                                                                      iconUrl?: string | null
                                                                      imageUrl?: string | null
                                                                      status?: Types.LinkTargetStatus | null
                                                                    } | null
                                                                  }
                                                                | { __typename: "ST4Class" }
                                                                | {
                                                                    __typename: "ST4Node"
                                                                    id: string
                                                                    aspectId: any
                                                                    label?: string | null
                                                                    properties?: {
                                                                      __typename: "ContentPropertyConnection"
                                                                      edges?: Array<{
                                                                        __typename: "ContentPropertyEdge"
                                                                        node?: {
                                                                          __typename: "ContentProperty"
                                                                          name: string
                                                                          value?: any | null
                                                                          displayName: string
                                                                        } | null
                                                                      } | null> | null
                                                                    } | null
                                                                  }
                                                                | { __typename: "ST4TreeNode" }
                                                                | null
                                                            }
                                                          | null
                                                      }>
                                                    }
                                                  | {
                                                      __typename: "VideoMediaInfo"
                                                      id: string
                                                      resourceId?: string | null
                                                      thumbnail?: string | null
                                                      width?: number | null
                                                      height?: number | null
                                                      mediaType?: Types.MediaType | null
                                                    }
                                                  | null
                                              }
                                            | {
                                                __typename: "MediaGroupContent"
                                                children: Array<{
                                                  __typename: "NodeRef"
                                                  id: string
                                                  target?:
                                                    | { __typename: "CommentNode"; id: string }
                                                    | { __typename: "ExternalSite"; id: string }
                                                    | { __typename: "ST4Class"; id: string }
                                                    | {
                                                        __typename: "ST4Node"
                                                        id: string
                                                        aspectId: any
                                                        content?:
                                                          | { __typename: "LexiconContent" }
                                                          | { __typename: "LexiconEntryContent" }
                                                          | {
                                                              __typename: "MediaContent"
                                                              media?:
                                                                | {
                                                                    __typename: "AnimationMediaInfo"
                                                                    id: string
                                                                    resourceId?: string | null
                                                                    thumbnail?: string | null
                                                                    width?: number | null
                                                                    height?: number | null
                                                                    mediaType?: Types.MediaType | null
                                                                  }
                                                                | {
                                                                    __typename: "ImageMediaInfo"
                                                                    id: string
                                                                    resourceId?: string | null
                                                                    thumbnail?: string | null
                                                                    width?: number | null
                                                                    height?: number | null
                                                                    resolution?: number | null
                                                                    colorDepth?: number | null
                                                                    size?: number | null
                                                                    mime?: string | null
                                                                    extension?: string | null
                                                                    allowCaching?: boolean | null
                                                                    mediaType?: Types.MediaType | null
                                                                    src?: string | null
                                                                    language?: string | null
                                                                  }
                                                                | {
                                                                    __typename: "NoMediaInfo"
                                                                    id: string
                                                                    mediaType?: Types.MediaType | null
                                                                  }
                                                                | {
                                                                    __typename: "VectorGraphicsMediaInfo"
                                                                    id: string
                                                                    resourceId?: string | null
                                                                    thumbnail?: string | null
                                                                    width?: number | null
                                                                    height?: number | null
                                                                    allowCaching?: boolean | null
                                                                    mediaType?: Types.MediaType | null
                                                                    language?: string | null
                                                                    labels: Array<{
                                                                      __typename: "VectorGraphicsLabel"
                                                                      id?: string | null
                                                                      sequence?: number | null
                                                                      autoValue?: string | null
                                                                      text?: string | null
                                                                      linkType?: Types.VectorGraphicsLabelLinkType | null
                                                                      link?: string | null
                                                                      ref?:
                                                                        | {
                                                                            __typename: "GenericRef"
                                                                            id: string
                                                                            owner?:
                                                                              | { __typename: "CommentNode" }
                                                                              | { __typename: "ExternalSite" }
                                                                              | { __typename: "ST4Class" }
                                                                              | { __typename: "ST4Node"; id: string }
                                                                              | { __typename: "ST4TreeNode" }
                                                                              | null
                                                                            target?:
                                                                              | { __typename: "CommentNode" }
                                                                              | {
                                                                                  __typename: "ExternalSite"
                                                                                  id: string
                                                                                  teaserData?: {
                                                                                    __typename: "ExternalLinkTeaserData"
                                                                                    title?: string | null
                                                                                    summary?: string | null
                                                                                    iconUrl?: string | null
                                                                                    imageUrl?: string | null
                                                                                    status?: Types.LinkTargetStatus | null
                                                                                  } | null
                                                                                }
                                                                              | { __typename: "ST4Class" }
                                                                              | {
                                                                                  __typename: "ST4Node"
                                                                                  id: string
                                                                                  aspectId: any
                                                                                  label?: string | null
                                                                                  properties?: {
                                                                                    __typename: "ContentPropertyConnection"
                                                                                    edges?: Array<{
                                                                                      __typename: "ContentPropertyEdge"
                                                                                      node?: {
                                                                                        __typename: "ContentProperty"
                                                                                        name: string
                                                                                        value?: any | null
                                                                                        displayName: string
                                                                                      } | null
                                                                                    } | null> | null
                                                                                  } | null
                                                                                }
                                                                              | { __typename: "ST4TreeNode" }
                                                                              | null
                                                                          }
                                                                        | {
                                                                            __typename: "NodeRef"
                                                                            id: string
                                                                            owner?:
                                                                              | { __typename: "CommentNode" }
                                                                              | { __typename: "ExternalSite" }
                                                                              | { __typename: "ST4Class" }
                                                                              | { __typename: "ST4Node"; id: string }
                                                                              | { __typename: "ST4TreeNode" }
                                                                              | null
                                                                            target?:
                                                                              | { __typename: "CommentNode" }
                                                                              | {
                                                                                  __typename: "ExternalSite"
                                                                                  id: string
                                                                                  teaserData?: {
                                                                                    __typename: "ExternalLinkTeaserData"
                                                                                    title?: string | null
                                                                                    summary?: string | null
                                                                                    iconUrl?: string | null
                                                                                    imageUrl?: string | null
                                                                                    status?: Types.LinkTargetStatus | null
                                                                                  } | null
                                                                                }
                                                                              | { __typename: "ST4Class" }
                                                                              | {
                                                                                  __typename: "ST4Node"
                                                                                  id: string
                                                                                  aspectId: any
                                                                                  label?: string | null
                                                                                  properties?: {
                                                                                    __typename: "ContentPropertyConnection"
                                                                                    edges?: Array<{
                                                                                      __typename: "ContentPropertyEdge"
                                                                                      node?: {
                                                                                        __typename: "ContentProperty"
                                                                                        name: string
                                                                                        value?: any | null
                                                                                        displayName: string
                                                                                      } | null
                                                                                    } | null> | null
                                                                                  } | null
                                                                                }
                                                                              | { __typename: "ST4TreeNode" }
                                                                              | null
                                                                          }
                                                                        | null
                                                                    }>
                                                                  }
                                                                | {
                                                                    __typename: "VideoMediaInfo"
                                                                    id: string
                                                                    resourceId?: string | null
                                                                    thumbnail?: string | null
                                                                    width?: number | null
                                                                    height?: number | null
                                                                    mediaType?: Types.MediaType | null
                                                                  }
                                                                | null
                                                            }
                                                          | {
                                                              __typename: "MediaGroupContent"
                                                              media?:
                                                                | {
                                                                    __typename: "AnimationMediaInfo"
                                                                    id: string
                                                                    resourceId?: string | null
                                                                    thumbnail?: string | null
                                                                    width?: number | null
                                                                    height?: number | null
                                                                    mediaType?: Types.MediaType | null
                                                                  }
                                                                | {
                                                                    __typename: "ImageMediaInfo"
                                                                    id: string
                                                                    resourceId?: string | null
                                                                    thumbnail?: string | null
                                                                    width?: number | null
                                                                    height?: number | null
                                                                    resolution?: number | null
                                                                    colorDepth?: number | null
                                                                    size?: number | null
                                                                    mime?: string | null
                                                                    extension?: string | null
                                                                    allowCaching?: boolean | null
                                                                    mediaType?: Types.MediaType | null
                                                                    src?: string | null
                                                                    language?: string | null
                                                                  }
                                                                | {
                                                                    __typename: "NoMediaInfo"
                                                                    id: string
                                                                    mediaType?: Types.MediaType | null
                                                                  }
                                                                | {
                                                                    __typename: "VectorGraphicsMediaInfo"
                                                                    id: string
                                                                    resourceId?: string | null
                                                                    thumbnail?: string | null
                                                                    width?: number | null
                                                                    height?: number | null
                                                                    allowCaching?: boolean | null
                                                                    mediaType?: Types.MediaType | null
                                                                    language?: string | null
                                                                    labels: Array<{
                                                                      __typename: "VectorGraphicsLabel"
                                                                      id?: string | null
                                                                      sequence?: number | null
                                                                      autoValue?: string | null
                                                                      text?: string | null
                                                                      linkType?: Types.VectorGraphicsLabelLinkType | null
                                                                      link?: string | null
                                                                      ref?:
                                                                        | {
                                                                            __typename: "GenericRef"
                                                                            id: string
                                                                            owner?:
                                                                              | { __typename: "CommentNode" }
                                                                              | { __typename: "ExternalSite" }
                                                                              | { __typename: "ST4Class" }
                                                                              | { __typename: "ST4Node"; id: string }
                                                                              | { __typename: "ST4TreeNode" }
                                                                              | null
                                                                            target?:
                                                                              | { __typename: "CommentNode" }
                                                                              | {
                                                                                  __typename: "ExternalSite"
                                                                                  id: string
                                                                                  teaserData?: {
                                                                                    __typename: "ExternalLinkTeaserData"
                                                                                    title?: string | null
                                                                                    summary?: string | null
                                                                                    iconUrl?: string | null
                                                                                    imageUrl?: string | null
                                                                                    status?: Types.LinkTargetStatus | null
                                                                                  } | null
                                                                                }
                                                                              | { __typename: "ST4Class" }
                                                                              | {
                                                                                  __typename: "ST4Node"
                                                                                  id: string
                                                                                  aspectId: any
                                                                                  label?: string | null
                                                                                  properties?: {
                                                                                    __typename: "ContentPropertyConnection"
                                                                                    edges?: Array<{
                                                                                      __typename: "ContentPropertyEdge"
                                                                                      node?: {
                                                                                        __typename: "ContentProperty"
                                                                                        name: string
                                                                                        value?: any | null
                                                                                        displayName: string
                                                                                      } | null
                                                                                    } | null> | null
                                                                                  } | null
                                                                                }
                                                                              | { __typename: "ST4TreeNode" }
                                                                              | null
                                                                          }
                                                                        | {
                                                                            __typename: "NodeRef"
                                                                            id: string
                                                                            owner?:
                                                                              | { __typename: "CommentNode" }
                                                                              | { __typename: "ExternalSite" }
                                                                              | { __typename: "ST4Class" }
                                                                              | { __typename: "ST4Node"; id: string }
                                                                              | { __typename: "ST4TreeNode" }
                                                                              | null
                                                                            target?:
                                                                              | { __typename: "CommentNode" }
                                                                              | {
                                                                                  __typename: "ExternalSite"
                                                                                  id: string
                                                                                  teaserData?: {
                                                                                    __typename: "ExternalLinkTeaserData"
                                                                                    title?: string | null
                                                                                    summary?: string | null
                                                                                    iconUrl?: string | null
                                                                                    imageUrl?: string | null
                                                                                    status?: Types.LinkTargetStatus | null
                                                                                  } | null
                                                                                }
                                                                              | { __typename: "ST4Class" }
                                                                              | {
                                                                                  __typename: "ST4Node"
                                                                                  id: string
                                                                                  aspectId: any
                                                                                  label?: string | null
                                                                                  properties?: {
                                                                                    __typename: "ContentPropertyConnection"
                                                                                    edges?: Array<{
                                                                                      __typename: "ContentPropertyEdge"
                                                                                      node?: {
                                                                                        __typename: "ContentProperty"
                                                                                        name: string
                                                                                        value?: any | null
                                                                                        displayName: string
                                                                                      } | null
                                                                                    } | null> | null
                                                                                  } | null
                                                                                }
                                                                              | { __typename: "ST4TreeNode" }
                                                                              | null
                                                                          }
                                                                        | null
                                                                    }>
                                                                  }
                                                                | {
                                                                    __typename: "VideoMediaInfo"
                                                                    id: string
                                                                    resourceId?: string | null
                                                                    thumbnail?: string | null
                                                                    width?: number | null
                                                                    height?: number | null
                                                                    mediaType?: Types.MediaType | null
                                                                  }
                                                                | null
                                                            }
                                                          | { __typename: "ProjectContent" }
                                                          | { __typename: "TermContent" }
                                                          | { __typename: "TextContent" }
                                                          | { __typename: "TextGroupContent" }
                                                          | { __typename: "VariableTableContent" }
                                                          | null
                                                      }
                                                    | { __typename: "ST4TreeNode"; id: string }
                                                    | null
                                                }>
                                                media?:
                                                  | {
                                                      __typename: "AnimationMediaInfo"
                                                      id: string
                                                      resourceId?: string | null
                                                      thumbnail?: string | null
                                                      width?: number | null
                                                      height?: number | null
                                                      mediaType?: Types.MediaType | null
                                                    }
                                                  | {
                                                      __typename: "ImageMediaInfo"
                                                      id: string
                                                      resourceId?: string | null
                                                      thumbnail?: string | null
                                                      width?: number | null
                                                      height?: number | null
                                                      resolution?: number | null
                                                      colorDepth?: number | null
                                                      size?: number | null
                                                      mime?: string | null
                                                      extension?: string | null
                                                      allowCaching?: boolean | null
                                                      mediaType?: Types.MediaType | null
                                                      src?: string | null
                                                      language?: string | null
                                                    }
                                                  | {
                                                      __typename: "NoMediaInfo"
                                                      id: string
                                                      mediaType?: Types.MediaType | null
                                                    }
                                                  | {
                                                      __typename: "VectorGraphicsMediaInfo"
                                                      id: string
                                                      resourceId?: string | null
                                                      thumbnail?: string | null
                                                      width?: number | null
                                                      height?: number | null
                                                      allowCaching?: boolean | null
                                                      mediaType?: Types.MediaType | null
                                                      language?: string | null
                                                      labels: Array<{
                                                        __typename: "VectorGraphicsLabel"
                                                        id?: string | null
                                                        sequence?: number | null
                                                        autoValue?: string | null
                                                        text?: string | null
                                                        linkType?: Types.VectorGraphicsLabelLinkType | null
                                                        link?: string | null
                                                        ref?:
                                                          | {
                                                              __typename: "GenericRef"
                                                              id: string
                                                              owner?:
                                                                | { __typename: "CommentNode" }
                                                                | { __typename: "ExternalSite" }
                                                                | { __typename: "ST4Class" }
                                                                | { __typename: "ST4Node"; id: string }
                                                                | { __typename: "ST4TreeNode" }
                                                                | null
                                                              target?:
                                                                | { __typename: "CommentNode" }
                                                                | {
                                                                    __typename: "ExternalSite"
                                                                    id: string
                                                                    teaserData?: {
                                                                      __typename: "ExternalLinkTeaserData"
                                                                      title?: string | null
                                                                      summary?: string | null
                                                                      iconUrl?: string | null
                                                                      imageUrl?: string | null
                                                                      status?: Types.LinkTargetStatus | null
                                                                    } | null
                                                                  }
                                                                | { __typename: "ST4Class" }
                                                                | {
                                                                    __typename: "ST4Node"
                                                                    id: string
                                                                    aspectId: any
                                                                    label?: string | null
                                                                    properties?: {
                                                                      __typename: "ContentPropertyConnection"
                                                                      edges?: Array<{
                                                                        __typename: "ContentPropertyEdge"
                                                                        node?: {
                                                                          __typename: "ContentProperty"
                                                                          name: string
                                                                          value?: any | null
                                                                          displayName: string
                                                                        } | null
                                                                      } | null> | null
                                                                    } | null
                                                                  }
                                                                | { __typename: "ST4TreeNode" }
                                                                | null
                                                            }
                                                          | {
                                                              __typename: "NodeRef"
                                                              id: string
                                                              owner?:
                                                                | { __typename: "CommentNode" }
                                                                | { __typename: "ExternalSite" }
                                                                | { __typename: "ST4Class" }
                                                                | { __typename: "ST4Node"; id: string }
                                                                | { __typename: "ST4TreeNode" }
                                                                | null
                                                              target?:
                                                                | { __typename: "CommentNode" }
                                                                | {
                                                                    __typename: "ExternalSite"
                                                                    id: string
                                                                    teaserData?: {
                                                                      __typename: "ExternalLinkTeaserData"
                                                                      title?: string | null
                                                                      summary?: string | null
                                                                      iconUrl?: string | null
                                                                      imageUrl?: string | null
                                                                      status?: Types.LinkTargetStatus | null
                                                                    } | null
                                                                  }
                                                                | { __typename: "ST4Class" }
                                                                | {
                                                                    __typename: "ST4Node"
                                                                    id: string
                                                                    aspectId: any
                                                                    label?: string | null
                                                                    properties?: {
                                                                      __typename: "ContentPropertyConnection"
                                                                      edges?: Array<{
                                                                        __typename: "ContentPropertyEdge"
                                                                        node?: {
                                                                          __typename: "ContentProperty"
                                                                          name: string
                                                                          value?: any | null
                                                                          displayName: string
                                                                        } | null
                                                                      } | null> | null
                                                                    } | null
                                                                  }
                                                                | { __typename: "ST4TreeNode" }
                                                                | null
                                                            }
                                                          | null
                                                      }>
                                                    }
                                                  | {
                                                      __typename: "VideoMediaInfo"
                                                      id: string
                                                      resourceId?: string | null
                                                      thumbnail?: string | null
                                                      width?: number | null
                                                      height?: number | null
                                                      mediaType?: Types.MediaType | null
                                                    }
                                                  | null
                                              }
                                            | { __typename: "ProjectContent" }
                                            | { __typename: "TermContent" }
                                            | { __typename: "TextContent" }
                                            | { __typename: "TextGroupContent" }
                                            | { __typename: "VariableTableContent" }
                                            | null
                                        }
                                      | { __typename: "ST4TreeNode"; id: string }
                                      | null
                                  }>
                                  variableTables?: Array<{
                                    __typename: "VariableTableReference"
                                    ref?: string | null
                                    node?:
                                      | { __typename: "CommentNode" }
                                      | { __typename: "ExternalSite" }
                                      | { __typename: "ST4Class" }
                                      | {
                                          __typename: "ST4Node"
                                          id: string
                                          label?: string | null
                                          aspectId: any
                                          content?:
                                            | { __typename: "LexiconContent" }
                                            | { __typename: "LexiconEntryContent" }
                                            | { __typename: "MediaContent" }
                                            | { __typename: "MediaGroupContent" }
                                            | { __typename: "ProjectContent" }
                                            | { __typename: "TermContent" }
                                            | { __typename: "TextContent" }
                                            | { __typename: "TextGroupContent" }
                                            | {
                                                __typename: "VariableTableContent"
                                                xml?: string | null
                                                valueSets: Array<{
                                                  __typename: "ValueSet"
                                                  name: string
                                                  valueSetId: string
                                                }>
                                                variables: Array<{
                                                  __typename: "Variable"
                                                  name: string
                                                  variableId: string
                                                  values: Array<string>
                                                }>
                                              }
                                            | null
                                        }
                                      | { __typename: "ST4TreeNode" }
                                      | null
                                  } | null> | null
                                  linkedDataNodes: Array<{
                                    __typename: "NodeRef"
                                    id: string
                                    target?:
                                      | { __typename: "CommentNode"; id: string }
                                      | { __typename: "ExternalSite"; id: string }
                                      | { __typename: "ST4Class"; id: string }
                                      | {
                                          __typename: "ST4Node"
                                          label?: string | null
                                          id: string
                                          metadata: Array<{
                                            __typename: "MetaDataEntry"
                                            label?: string | null
                                            systemName: string
                                            value?: string | null
                                            values: Array<string>
                                            users: Array<string>
                                          }>
                                          linkedOntologyNodes: Array<
                                            | {
                                                __typename: "GenericRef"
                                                target?:
                                                  | { __typename: "CommentNode" }
                                                  | { __typename: "ExternalSite" }
                                                  | { __typename: "ST4Class" }
                                                  | { __typename: "ST4Node"; id: string; label?: string | null }
                                                  | { __typename: "ST4TreeNode" }
                                                  | null
                                              }
                                            | {
                                                __typename: "NodeRef"
                                                linkClassName: string
                                                class?: { __typename: "ST4Class"; label?: string | null } | null
                                                target?:
                                                  | { __typename: "CommentNode" }
                                                  | { __typename: "ExternalSite" }
                                                  | { __typename: "ST4Class" }
                                                  | { __typename: "ST4Node"; id: string; label?: string | null }
                                                  | { __typename: "ST4TreeNode" }
                                                  | null
                                              }
                                          >
                                        }
                                      | { __typename: "ST4TreeNode"; id: string }
                                      | null
                                  }>
                                  lexiconEntries?: Array<{
                                    __typename: "NodeRef"
                                    id: string
                                    target?:
                                      | { __typename: "CommentNode"; id: string }
                                      | { __typename: "ExternalSite"; id: string }
                                      | { __typename: "ST4Class"; id: string }
                                      | {
                                          __typename: "ST4Node"
                                          aspectId: any
                                          label?: string | null
                                          id: string
                                          content?:
                                            | { __typename: "LexiconContent" }
                                            | {
                                                __typename: "LexiconEntryContent"
                                                descriptionXast?: string | null
                                                subject?: string | null
                                                sortKey?: string | null
                                                lexicon:
                                                  | { __typename: "CommentNode"; id: string }
                                                  | { __typename: "ExternalSite"; id: string }
                                                  | { __typename: "ST4Class"; id: string }
                                                  | { __typename: "ST4Node"; label?: string | null; id: string }
                                                  | { __typename: "ST4TreeNode"; id: string }
                                                terms: Array<
                                                  | { __typename: "CommentNode"; id: string }
                                                  | { __typename: "ExternalSite"; id: string }
                                                  | { __typename: "ST4Class"; id: string }
                                                  | {
                                                      __typename: "ST4Node"
                                                      id: string
                                                      label?: string | null
                                                      aspectId: any
                                                      content?:
                                                        | { __typename: "LexiconContent" }
                                                        | { __typename: "LexiconEntryContent" }
                                                        | { __typename: "MediaContent" }
                                                        | { __typename: "MediaGroupContent" }
                                                        | { __typename: "ProjectContent" }
                                                        | {
                                                            __typename: "TermContent"
                                                            gramaticalGender?: string | null
                                                            validity?: string | null
                                                            editLanguage?: string | null
                                                          }
                                                        | { __typename: "TextContent" }
                                                        | { __typename: "TextGroupContent" }
                                                        | { __typename: "VariableTableContent" }
                                                        | null
                                                    }
                                                  | { __typename: "ST4TreeNode"; id: string }
                                                >
                                                image?:
                                                  | { __typename: "CommentNode"; id: string }
                                                  | { __typename: "ExternalSite"; id: string }
                                                  | { __typename: "ST4Class"; id: string }
                                                  | {
                                                      __typename: "ST4Node"
                                                      aspectId: any
                                                      id: string
                                                      content?:
                                                        | { __typename: "LexiconContent" }
                                                        | { __typename: "LexiconEntryContent" }
                                                        | {
                                                            __typename: "MediaContent"
                                                            media?:
                                                              | {
                                                                  __typename: "AnimationMediaInfo"
                                                                  thumbnail?: string | null
                                                                  mediaType?: Types.MediaType | null
                                                                }
                                                              | {
                                                                  __typename: "ImageMediaInfo"
                                                                  src?: string | null
                                                                  thumbnail?: string | null
                                                                  mediaType?: Types.MediaType | null
                                                                }
                                                              | { __typename: "NoMediaInfo" }
                                                              | {
                                                                  __typename: "VectorGraphicsMediaInfo"
                                                                  svg?: string | null
                                                                  thumbnail?: string | null
                                                                  mediaType?: Types.MediaType | null
                                                                }
                                                              | {
                                                                  __typename: "VideoMediaInfo"
                                                                  thumbnail?: string | null
                                                                  mediaType?: Types.MediaType | null
                                                                }
                                                              | null
                                                          }
                                                        | { __typename: "MediaGroupContent" }
                                                        | { __typename: "ProjectContent" }
                                                        | { __typename: "TermContent" }
                                                        | { __typename: "TextContent" }
                                                        | { __typename: "TextGroupContent" }
                                                        | { __typename: "VariableTableContent" }
                                                        | null
                                                    }
                                                  | { __typename: "ST4TreeNode"; id: string }
                                                  | null
                                              }
                                            | { __typename: "MediaContent" }
                                            | { __typename: "MediaGroupContent" }
                                            | { __typename: "ProjectContent" }
                                            | { __typename: "TermContent" }
                                            | { __typename: "TextContent" }
                                            | { __typename: "TextGroupContent" }
                                            | { __typename: "VariableTableContent" }
                                            | null
                                        }
                                      | { __typename: "ST4TreeNode"; id: string }
                                      | null
                                  } | null> | null
                                  comments: Array<{
                                    __typename: "CommentNode"
                                    id: string
                                    comment:
                                      | {
                                          __typename: "BlockComment"
                                          location: number
                                          value?: string | null
                                          authorDisplayName?: string | null
                                          authorUserName?: string | null
                                          commentKey: string
                                          editDate?: any | null
                                          modificationVersion?: number | null
                                          replyToKey?: string | null
                                          sortOrder?: number | null
                                          commentType?: string | null
                                          state?: string | null
                                          color: string
                                          persistanceState?: Types.PersistanceState | null
                                          persistanceAction?: Types.PersistanceAction | null
                                          node: {
                                            __typename: "ST4Node"
                                            aspectId: any
                                            id: string
                                            content?:
                                              | { __typename: "LexiconContent" }
                                              | { __typename: "LexiconEntryContent" }
                                              | { __typename: "MediaContent" }
                                              | { __typename: "MediaGroupContent" }
                                              | { __typename: "ProjectContent" }
                                              | { __typename: "TermContent" }
                                              | { __typename: "TextContent"; id: string; historyNumber: number }
                                              | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                                              | { __typename: "VariableTableContent" }
                                              | null
                                          }
                                          actions: Array<{
                                            __typename: "Action"
                                            name?: string | null
                                            enabled?: boolean | null
                                          }>
                                        }
                                      | {
                                          __typename: "TextComment"
                                          endLocation: number
                                          startLocation?: number | null
                                          value?: string | null
                                          authorDisplayName?: string | null
                                          authorUserName?: string | null
                                          commentKey: string
                                          editDate?: any | null
                                          modificationVersion?: number | null
                                          replyToKey?: string | null
                                          sortOrder?: number | null
                                          commentType?: string | null
                                          state?: string | null
                                          color: string
                                          persistanceState?: Types.PersistanceState | null
                                          persistanceAction?: Types.PersistanceAction | null
                                          node: {
                                            __typename: "ST4Node"
                                            aspectId: any
                                            id: string
                                            content?:
                                              | { __typename: "LexiconContent" }
                                              | { __typename: "LexiconEntryContent" }
                                              | { __typename: "MediaContent" }
                                              | { __typename: "MediaGroupContent" }
                                              | { __typename: "ProjectContent" }
                                              | { __typename: "TermContent" }
                                              | { __typename: "TextContent"; id: string; historyNumber: number }
                                              | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                                              | { __typename: "VariableTableContent" }
                                              | null
                                          }
                                          actions: Array<{
                                            __typename: "Action"
                                            name?: string | null
                                            enabled?: boolean | null
                                          }>
                                        }
                                  }>
                                }
                              | { __typename: "VariableTableContent" }
                              | null
                          }
                        }>
                        links?: Array<{
                          __typename: "TextContentLink"
                          ref?: string | null
                          node?:
                            | { __typename: "CommentNode"; id: string }
                            | { __typename: "ExternalSite"; id: string }
                            | { __typename: "ST4Class"; id: string }
                            | {
                                __typename: "ST4Node"
                                id: string
                                aspectId: any
                                label?: string | null
                                properties?: {
                                  __typename: "ContentPropertyConnection"
                                  totalCount: number
                                  edges?: Array<{
                                    __typename: "ContentPropertyEdge"
                                    node?: {
                                      __typename: "ContentProperty"
                                      typeOfValue: Types.ContentPropertyValueType
                                      name: string
                                      value?: any | null
                                      displayName: string
                                    } | null
                                  } | null> | null
                                } | null
                              }
                            | { __typename: "ST4TreeNode"; id: string }
                            | null
                        } | null> | null
                        images: Array<{
                          __typename: "NodeRef"
                          id: string
                          target?:
                            | { __typename: "CommentNode"; id: string }
                            | { __typename: "ExternalSite"; id: string }
                            | { __typename: "ST4Class"; id: string }
                            | {
                                __typename: "ST4Node"
                                aspectId: any
                                id: string
                                releaseState: {
                                  __typename: "ReleaseStateValue"
                                  displayName: string
                                  state: Types.ReleaseState
                                  isImplicitlyReleased: boolean
                                }
                                content?:
                                  | { __typename: "LexiconContent" }
                                  | { __typename: "LexiconEntryContent" }
                                  | {
                                      __typename: "MediaContent"
                                      media?:
                                        | {
                                            __typename: "AnimationMediaInfo"
                                            id: string
                                            resourceId?: string | null
                                            thumbnail?: string | null
                                            width?: number | null
                                            height?: number | null
                                            mediaType?: Types.MediaType | null
                                          }
                                        | {
                                            __typename: "ImageMediaInfo"
                                            id: string
                                            resourceId?: string | null
                                            thumbnail?: string | null
                                            width?: number | null
                                            height?: number | null
                                            resolution?: number | null
                                            colorDepth?: number | null
                                            size?: number | null
                                            mime?: string | null
                                            extension?: string | null
                                            allowCaching?: boolean | null
                                            mediaType?: Types.MediaType | null
                                            src?: string | null
                                            language?: string | null
                                          }
                                        | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                                        | {
                                            __typename: "VectorGraphicsMediaInfo"
                                            id: string
                                            resourceId?: string | null
                                            thumbnail?: string | null
                                            width?: number | null
                                            height?: number | null
                                            allowCaching?: boolean | null
                                            mediaType?: Types.MediaType | null
                                            language?: string | null
                                            labels: Array<{
                                              __typename: "VectorGraphicsLabel"
                                              id?: string | null
                                              sequence?: number | null
                                              autoValue?: string | null
                                              text?: string | null
                                              linkType?: Types.VectorGraphicsLabelLinkType | null
                                              link?: string | null
                                              ref?:
                                                | {
                                                    __typename: "GenericRef"
                                                    id: string
                                                    owner?:
                                                      | { __typename: "CommentNode" }
                                                      | { __typename: "ExternalSite" }
                                                      | { __typename: "ST4Class" }
                                                      | { __typename: "ST4Node"; id: string }
                                                      | { __typename: "ST4TreeNode" }
                                                      | null
                                                    target?:
                                                      | { __typename: "CommentNode" }
                                                      | {
                                                          __typename: "ExternalSite"
                                                          id: string
                                                          teaserData?: {
                                                            __typename: "ExternalLinkTeaserData"
                                                            title?: string | null
                                                            summary?: string | null
                                                            iconUrl?: string | null
                                                            imageUrl?: string | null
                                                            status?: Types.LinkTargetStatus | null
                                                          } | null
                                                        }
                                                      | { __typename: "ST4Class" }
                                                      | {
                                                          __typename: "ST4Node"
                                                          id: string
                                                          aspectId: any
                                                          label?: string | null
                                                          properties?: {
                                                            __typename: "ContentPropertyConnection"
                                                            edges?: Array<{
                                                              __typename: "ContentPropertyEdge"
                                                              node?: {
                                                                __typename: "ContentProperty"
                                                                name: string
                                                                value?: any | null
                                                                displayName: string
                                                              } | null
                                                            } | null> | null
                                                          } | null
                                                        }
                                                      | { __typename: "ST4TreeNode" }
                                                      | null
                                                  }
                                                | {
                                                    __typename: "NodeRef"
                                                    id: string
                                                    owner?:
                                                      | { __typename: "CommentNode" }
                                                      | { __typename: "ExternalSite" }
                                                      | { __typename: "ST4Class" }
                                                      | { __typename: "ST4Node"; id: string }
                                                      | { __typename: "ST4TreeNode" }
                                                      | null
                                                    target?:
                                                      | { __typename: "CommentNode" }
                                                      | {
                                                          __typename: "ExternalSite"
                                                          id: string
                                                          teaserData?: {
                                                            __typename: "ExternalLinkTeaserData"
                                                            title?: string | null
                                                            summary?: string | null
                                                            iconUrl?: string | null
                                                            imageUrl?: string | null
                                                            status?: Types.LinkTargetStatus | null
                                                          } | null
                                                        }
                                                      | { __typename: "ST4Class" }
                                                      | {
                                                          __typename: "ST4Node"
                                                          id: string
                                                          aspectId: any
                                                          label?: string | null
                                                          properties?: {
                                                            __typename: "ContentPropertyConnection"
                                                            edges?: Array<{
                                                              __typename: "ContentPropertyEdge"
                                                              node?: {
                                                                __typename: "ContentProperty"
                                                                name: string
                                                                value?: any | null
                                                                displayName: string
                                                              } | null
                                                            } | null> | null
                                                          } | null
                                                        }
                                                      | { __typename: "ST4TreeNode" }
                                                      | null
                                                  }
                                                | null
                                            }>
                                          }
                                        | {
                                            __typename: "VideoMediaInfo"
                                            id: string
                                            resourceId?: string | null
                                            thumbnail?: string | null
                                            width?: number | null
                                            height?: number | null
                                            mediaType?: Types.MediaType | null
                                          }
                                        | null
                                    }
                                  | {
                                      __typename: "MediaGroupContent"
                                      children: Array<{
                                        __typename: "NodeRef"
                                        id: string
                                        target?:
                                          | { __typename: "CommentNode"; id: string }
                                          | { __typename: "ExternalSite"; id: string }
                                          | { __typename: "ST4Class"; id: string }
                                          | {
                                              __typename: "ST4Node"
                                              id: string
                                              aspectId: any
                                              content?:
                                                | { __typename: "LexiconContent" }
                                                | { __typename: "LexiconEntryContent" }
                                                | {
                                                    __typename: "MediaContent"
                                                    media?:
                                                      | {
                                                          __typename: "AnimationMediaInfo"
                                                          id: string
                                                          resourceId?: string | null
                                                          thumbnail?: string | null
                                                          width?: number | null
                                                          height?: number | null
                                                          mediaType?: Types.MediaType | null
                                                        }
                                                      | {
                                                          __typename: "ImageMediaInfo"
                                                          id: string
                                                          resourceId?: string | null
                                                          thumbnail?: string | null
                                                          width?: number | null
                                                          height?: number | null
                                                          resolution?: number | null
                                                          colorDepth?: number | null
                                                          size?: number | null
                                                          mime?: string | null
                                                          extension?: string | null
                                                          allowCaching?: boolean | null
                                                          mediaType?: Types.MediaType | null
                                                          src?: string | null
                                                          language?: string | null
                                                        }
                                                      | {
                                                          __typename: "NoMediaInfo"
                                                          id: string
                                                          mediaType?: Types.MediaType | null
                                                        }
                                                      | {
                                                          __typename: "VectorGraphicsMediaInfo"
                                                          id: string
                                                          resourceId?: string | null
                                                          thumbnail?: string | null
                                                          width?: number | null
                                                          height?: number | null
                                                          allowCaching?: boolean | null
                                                          mediaType?: Types.MediaType | null
                                                          language?: string | null
                                                          labels: Array<{
                                                            __typename: "VectorGraphicsLabel"
                                                            id?: string | null
                                                            sequence?: number | null
                                                            autoValue?: string | null
                                                            text?: string | null
                                                            linkType?: Types.VectorGraphicsLabelLinkType | null
                                                            link?: string | null
                                                            ref?:
                                                              | {
                                                                  __typename: "GenericRef"
                                                                  id: string
                                                                  owner?:
                                                                    | { __typename: "CommentNode" }
                                                                    | { __typename: "ExternalSite" }
                                                                    | { __typename: "ST4Class" }
                                                                    | { __typename: "ST4Node"; id: string }
                                                                    | { __typename: "ST4TreeNode" }
                                                                    | null
                                                                  target?:
                                                                    | { __typename: "CommentNode" }
                                                                    | {
                                                                        __typename: "ExternalSite"
                                                                        id: string
                                                                        teaserData?: {
                                                                          __typename: "ExternalLinkTeaserData"
                                                                          title?: string | null
                                                                          summary?: string | null
                                                                          iconUrl?: string | null
                                                                          imageUrl?: string | null
                                                                          status?: Types.LinkTargetStatus | null
                                                                        } | null
                                                                      }
                                                                    | { __typename: "ST4Class" }
                                                                    | {
                                                                        __typename: "ST4Node"
                                                                        id: string
                                                                        aspectId: any
                                                                        label?: string | null
                                                                        properties?: {
                                                                          __typename: "ContentPropertyConnection"
                                                                          edges?: Array<{
                                                                            __typename: "ContentPropertyEdge"
                                                                            node?: {
                                                                              __typename: "ContentProperty"
                                                                              name: string
                                                                              value?: any | null
                                                                              displayName: string
                                                                            } | null
                                                                          } | null> | null
                                                                        } | null
                                                                      }
                                                                    | { __typename: "ST4TreeNode" }
                                                                    | null
                                                                }
                                                              | {
                                                                  __typename: "NodeRef"
                                                                  id: string
                                                                  owner?:
                                                                    | { __typename: "CommentNode" }
                                                                    | { __typename: "ExternalSite" }
                                                                    | { __typename: "ST4Class" }
                                                                    | { __typename: "ST4Node"; id: string }
                                                                    | { __typename: "ST4TreeNode" }
                                                                    | null
                                                                  target?:
                                                                    | { __typename: "CommentNode" }
                                                                    | {
                                                                        __typename: "ExternalSite"
                                                                        id: string
                                                                        teaserData?: {
                                                                          __typename: "ExternalLinkTeaserData"
                                                                          title?: string | null
                                                                          summary?: string | null
                                                                          iconUrl?: string | null
                                                                          imageUrl?: string | null
                                                                          status?: Types.LinkTargetStatus | null
                                                                        } | null
                                                                      }
                                                                    | { __typename: "ST4Class" }
                                                                    | {
                                                                        __typename: "ST4Node"
                                                                        id: string
                                                                        aspectId: any
                                                                        label?: string | null
                                                                        properties?: {
                                                                          __typename: "ContentPropertyConnection"
                                                                          edges?: Array<{
                                                                            __typename: "ContentPropertyEdge"
                                                                            node?: {
                                                                              __typename: "ContentProperty"
                                                                              name: string
                                                                              value?: any | null
                                                                              displayName: string
                                                                            } | null
                                                                          } | null> | null
                                                                        } | null
                                                                      }
                                                                    | { __typename: "ST4TreeNode" }
                                                                    | null
                                                                }
                                                              | null
                                                          }>
                                                        }
                                                      | {
                                                          __typename: "VideoMediaInfo"
                                                          id: string
                                                          resourceId?: string | null
                                                          thumbnail?: string | null
                                                          width?: number | null
                                                          height?: number | null
                                                          mediaType?: Types.MediaType | null
                                                        }
                                                      | null
                                                  }
                                                | {
                                                    __typename: "MediaGroupContent"
                                                    media?:
                                                      | {
                                                          __typename: "AnimationMediaInfo"
                                                          id: string
                                                          resourceId?: string | null
                                                          thumbnail?: string | null
                                                          width?: number | null
                                                          height?: number | null
                                                          mediaType?: Types.MediaType | null
                                                        }
                                                      | {
                                                          __typename: "ImageMediaInfo"
                                                          id: string
                                                          resourceId?: string | null
                                                          thumbnail?: string | null
                                                          width?: number | null
                                                          height?: number | null
                                                          resolution?: number | null
                                                          colorDepth?: number | null
                                                          size?: number | null
                                                          mime?: string | null
                                                          extension?: string | null
                                                          allowCaching?: boolean | null
                                                          mediaType?: Types.MediaType | null
                                                          src?: string | null
                                                          language?: string | null
                                                        }
                                                      | {
                                                          __typename: "NoMediaInfo"
                                                          id: string
                                                          mediaType?: Types.MediaType | null
                                                        }
                                                      | {
                                                          __typename: "VectorGraphicsMediaInfo"
                                                          id: string
                                                          resourceId?: string | null
                                                          thumbnail?: string | null
                                                          width?: number | null
                                                          height?: number | null
                                                          allowCaching?: boolean | null
                                                          mediaType?: Types.MediaType | null
                                                          language?: string | null
                                                          labels: Array<{
                                                            __typename: "VectorGraphicsLabel"
                                                            id?: string | null
                                                            sequence?: number | null
                                                            autoValue?: string | null
                                                            text?: string | null
                                                            linkType?: Types.VectorGraphicsLabelLinkType | null
                                                            link?: string | null
                                                            ref?:
                                                              | {
                                                                  __typename: "GenericRef"
                                                                  id: string
                                                                  owner?:
                                                                    | { __typename: "CommentNode" }
                                                                    | { __typename: "ExternalSite" }
                                                                    | { __typename: "ST4Class" }
                                                                    | { __typename: "ST4Node"; id: string }
                                                                    | { __typename: "ST4TreeNode" }
                                                                    | null
                                                                  target?:
                                                                    | { __typename: "CommentNode" }
                                                                    | {
                                                                        __typename: "ExternalSite"
                                                                        id: string
                                                                        teaserData?: {
                                                                          __typename: "ExternalLinkTeaserData"
                                                                          title?: string | null
                                                                          summary?: string | null
                                                                          iconUrl?: string | null
                                                                          imageUrl?: string | null
                                                                          status?: Types.LinkTargetStatus | null
                                                                        } | null
                                                                      }
                                                                    | { __typename: "ST4Class" }
                                                                    | {
                                                                        __typename: "ST4Node"
                                                                        id: string
                                                                        aspectId: any
                                                                        label?: string | null
                                                                        properties?: {
                                                                          __typename: "ContentPropertyConnection"
                                                                          edges?: Array<{
                                                                            __typename: "ContentPropertyEdge"
                                                                            node?: {
                                                                              __typename: "ContentProperty"
                                                                              name: string
                                                                              value?: any | null
                                                                              displayName: string
                                                                            } | null
                                                                          } | null> | null
                                                                        } | null
                                                                      }
                                                                    | { __typename: "ST4TreeNode" }
                                                                    | null
                                                                }
                                                              | {
                                                                  __typename: "NodeRef"
                                                                  id: string
                                                                  owner?:
                                                                    | { __typename: "CommentNode" }
                                                                    | { __typename: "ExternalSite" }
                                                                    | { __typename: "ST4Class" }
                                                                    | { __typename: "ST4Node"; id: string }
                                                                    | { __typename: "ST4TreeNode" }
                                                                    | null
                                                                  target?:
                                                                    | { __typename: "CommentNode" }
                                                                    | {
                                                                        __typename: "ExternalSite"
                                                                        id: string
                                                                        teaserData?: {
                                                                          __typename: "ExternalLinkTeaserData"
                                                                          title?: string | null
                                                                          summary?: string | null
                                                                          iconUrl?: string | null
                                                                          imageUrl?: string | null
                                                                          status?: Types.LinkTargetStatus | null
                                                                        } | null
                                                                      }
                                                                    | { __typename: "ST4Class" }
                                                                    | {
                                                                        __typename: "ST4Node"
                                                                        id: string
                                                                        aspectId: any
                                                                        label?: string | null
                                                                        properties?: {
                                                                          __typename: "ContentPropertyConnection"
                                                                          edges?: Array<{
                                                                            __typename: "ContentPropertyEdge"
                                                                            node?: {
                                                                              __typename: "ContentProperty"
                                                                              name: string
                                                                              value?: any | null
                                                                              displayName: string
                                                                            } | null
                                                                          } | null> | null
                                                                        } | null
                                                                      }
                                                                    | { __typename: "ST4TreeNode" }
                                                                    | null
                                                                }
                                                              | null
                                                          }>
                                                        }
                                                      | {
                                                          __typename: "VideoMediaInfo"
                                                          id: string
                                                          resourceId?: string | null
                                                          thumbnail?: string | null
                                                          width?: number | null
                                                          height?: number | null
                                                          mediaType?: Types.MediaType | null
                                                        }
                                                      | null
                                                  }
                                                | { __typename: "ProjectContent" }
                                                | { __typename: "TermContent" }
                                                | { __typename: "TextContent" }
                                                | { __typename: "TextGroupContent" }
                                                | { __typename: "VariableTableContent" }
                                                | null
                                            }
                                          | { __typename: "ST4TreeNode"; id: string }
                                          | null
                                      }>
                                      media?:
                                        | {
                                            __typename: "AnimationMediaInfo"
                                            id: string
                                            resourceId?: string | null
                                            thumbnail?: string | null
                                            width?: number | null
                                            height?: number | null
                                            mediaType?: Types.MediaType | null
                                          }
                                        | {
                                            __typename: "ImageMediaInfo"
                                            id: string
                                            resourceId?: string | null
                                            thumbnail?: string | null
                                            width?: number | null
                                            height?: number | null
                                            resolution?: number | null
                                            colorDepth?: number | null
                                            size?: number | null
                                            mime?: string | null
                                            extension?: string | null
                                            allowCaching?: boolean | null
                                            mediaType?: Types.MediaType | null
                                            src?: string | null
                                            language?: string | null
                                          }
                                        | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                                        | {
                                            __typename: "VectorGraphicsMediaInfo"
                                            id: string
                                            resourceId?: string | null
                                            thumbnail?: string | null
                                            width?: number | null
                                            height?: number | null
                                            allowCaching?: boolean | null
                                            mediaType?: Types.MediaType | null
                                            language?: string | null
                                            labels: Array<{
                                              __typename: "VectorGraphicsLabel"
                                              id?: string | null
                                              sequence?: number | null
                                              autoValue?: string | null
                                              text?: string | null
                                              linkType?: Types.VectorGraphicsLabelLinkType | null
                                              link?: string | null
                                              ref?:
                                                | {
                                                    __typename: "GenericRef"
                                                    id: string
                                                    owner?:
                                                      | { __typename: "CommentNode" }
                                                      | { __typename: "ExternalSite" }
                                                      | { __typename: "ST4Class" }
                                                      | { __typename: "ST4Node"; id: string }
                                                      | { __typename: "ST4TreeNode" }
                                                      | null
                                                    target?:
                                                      | { __typename: "CommentNode" }
                                                      | {
                                                          __typename: "ExternalSite"
                                                          id: string
                                                          teaserData?: {
                                                            __typename: "ExternalLinkTeaserData"
                                                            title?: string | null
                                                            summary?: string | null
                                                            iconUrl?: string | null
                                                            imageUrl?: string | null
                                                            status?: Types.LinkTargetStatus | null
                                                          } | null
                                                        }
                                                      | { __typename: "ST4Class" }
                                                      | {
                                                          __typename: "ST4Node"
                                                          id: string
                                                          aspectId: any
                                                          label?: string | null
                                                          properties?: {
                                                            __typename: "ContentPropertyConnection"
                                                            edges?: Array<{
                                                              __typename: "ContentPropertyEdge"
                                                              node?: {
                                                                __typename: "ContentProperty"
                                                                name: string
                                                                value?: any | null
                                                                displayName: string
                                                              } | null
                                                            } | null> | null
                                                          } | null
                                                        }
                                                      | { __typename: "ST4TreeNode" }
                                                      | null
                                                  }
                                                | {
                                                    __typename: "NodeRef"
                                                    id: string
                                                    owner?:
                                                      | { __typename: "CommentNode" }
                                                      | { __typename: "ExternalSite" }
                                                      | { __typename: "ST4Class" }
                                                      | { __typename: "ST4Node"; id: string }
                                                      | { __typename: "ST4TreeNode" }
                                                      | null
                                                    target?:
                                                      | { __typename: "CommentNode" }
                                                      | {
                                                          __typename: "ExternalSite"
                                                          id: string
                                                          teaserData?: {
                                                            __typename: "ExternalLinkTeaserData"
                                                            title?: string | null
                                                            summary?: string | null
                                                            iconUrl?: string | null
                                                            imageUrl?: string | null
                                                            status?: Types.LinkTargetStatus | null
                                                          } | null
                                                        }
                                                      | { __typename: "ST4Class" }
                                                      | {
                                                          __typename: "ST4Node"
                                                          id: string
                                                          aspectId: any
                                                          label?: string | null
                                                          properties?: {
                                                            __typename: "ContentPropertyConnection"
                                                            edges?: Array<{
                                                              __typename: "ContentPropertyEdge"
                                                              node?: {
                                                                __typename: "ContentProperty"
                                                                name: string
                                                                value?: any | null
                                                                displayName: string
                                                              } | null
                                                            } | null> | null
                                                          } | null
                                                        }
                                                      | { __typename: "ST4TreeNode" }
                                                      | null
                                                  }
                                                | null
                                            }>
                                          }
                                        | {
                                            __typename: "VideoMediaInfo"
                                            id: string
                                            resourceId?: string | null
                                            thumbnail?: string | null
                                            width?: number | null
                                            height?: number | null
                                            mediaType?: Types.MediaType | null
                                          }
                                        | null
                                    }
                                  | { __typename: "ProjectContent" }
                                  | { __typename: "TermContent" }
                                  | { __typename: "TextContent" }
                                  | { __typename: "TextGroupContent" }
                                  | { __typename: "VariableTableContent" }
                                  | null
                              }
                            | { __typename: "ST4TreeNode"; id: string }
                            | null
                        }>
                        variableTables?: Array<{
                          __typename: "VariableTableReference"
                          ref?: string | null
                          node?:
                            | { __typename: "CommentNode" }
                            | { __typename: "ExternalSite" }
                            | { __typename: "ST4Class" }
                            | {
                                __typename: "ST4Node"
                                id: string
                                label?: string | null
                                aspectId: any
                                content?:
                                  | { __typename: "LexiconContent" }
                                  | { __typename: "LexiconEntryContent" }
                                  | { __typename: "MediaContent" }
                                  | { __typename: "MediaGroupContent" }
                                  | { __typename: "ProjectContent" }
                                  | { __typename: "TermContent" }
                                  | { __typename: "TextContent" }
                                  | { __typename: "TextGroupContent" }
                                  | {
                                      __typename: "VariableTableContent"
                                      xml?: string | null
                                      valueSets: Array<{ __typename: "ValueSet"; name: string; valueSetId: string }>
                                      variables: Array<{
                                        __typename: "Variable"
                                        name: string
                                        variableId: string
                                        values: Array<string>
                                      }>
                                    }
                                  | null
                              }
                            | { __typename: "ST4TreeNode" }
                            | null
                        } | null> | null
                        linkedDataNodes: Array<{
                          __typename: "NodeRef"
                          id: string
                          target?:
                            | { __typename: "CommentNode"; id: string }
                            | { __typename: "ExternalSite"; id: string }
                            | { __typename: "ST4Class"; id: string }
                            | {
                                __typename: "ST4Node"
                                label?: string | null
                                id: string
                                metadata: Array<{
                                  __typename: "MetaDataEntry"
                                  label?: string | null
                                  systemName: string
                                  value?: string | null
                                  values: Array<string>
                                  users: Array<string>
                                }>
                                linkedOntologyNodes: Array<
                                  | {
                                      __typename: "GenericRef"
                                      target?:
                                        | { __typename: "CommentNode" }
                                        | { __typename: "ExternalSite" }
                                        | { __typename: "ST4Class" }
                                        | { __typename: "ST4Node"; id: string; label?: string | null }
                                        | { __typename: "ST4TreeNode" }
                                        | null
                                    }
                                  | {
                                      __typename: "NodeRef"
                                      linkClassName: string
                                      class?: { __typename: "ST4Class"; label?: string | null } | null
                                      target?:
                                        | { __typename: "CommentNode" }
                                        | { __typename: "ExternalSite" }
                                        | { __typename: "ST4Class" }
                                        | { __typename: "ST4Node"; id: string; label?: string | null }
                                        | { __typename: "ST4TreeNode" }
                                        | null
                                    }
                                >
                              }
                            | { __typename: "ST4TreeNode"; id: string }
                            | null
                        }>
                        lexiconEntries?: Array<{
                          __typename: "NodeRef"
                          id: string
                          target?:
                            | { __typename: "CommentNode"; id: string }
                            | { __typename: "ExternalSite"; id: string }
                            | { __typename: "ST4Class"; id: string }
                            | {
                                __typename: "ST4Node"
                                aspectId: any
                                label?: string | null
                                id: string
                                content?:
                                  | { __typename: "LexiconContent" }
                                  | {
                                      __typename: "LexiconEntryContent"
                                      descriptionXast?: string | null
                                      subject?: string | null
                                      sortKey?: string | null
                                      lexicon:
                                        | { __typename: "CommentNode"; id: string }
                                        | { __typename: "ExternalSite"; id: string }
                                        | { __typename: "ST4Class"; id: string }
                                        | { __typename: "ST4Node"; label?: string | null; id: string }
                                        | { __typename: "ST4TreeNode"; id: string }
                                      terms: Array<
                                        | { __typename: "CommentNode"; id: string }
                                        | { __typename: "ExternalSite"; id: string }
                                        | { __typename: "ST4Class"; id: string }
                                        | {
                                            __typename: "ST4Node"
                                            id: string
                                            label?: string | null
                                            aspectId: any
                                            content?:
                                              | { __typename: "LexiconContent" }
                                              | { __typename: "LexiconEntryContent" }
                                              | { __typename: "MediaContent" }
                                              | { __typename: "MediaGroupContent" }
                                              | { __typename: "ProjectContent" }
                                              | {
                                                  __typename: "TermContent"
                                                  gramaticalGender?: string | null
                                                  validity?: string | null
                                                  editLanguage?: string | null
                                                }
                                              | { __typename: "TextContent" }
                                              | { __typename: "TextGroupContent" }
                                              | { __typename: "VariableTableContent" }
                                              | null
                                          }
                                        | { __typename: "ST4TreeNode"; id: string }
                                      >
                                      image?:
                                        | { __typename: "CommentNode"; id: string }
                                        | { __typename: "ExternalSite"; id: string }
                                        | { __typename: "ST4Class"; id: string }
                                        | {
                                            __typename: "ST4Node"
                                            aspectId: any
                                            id: string
                                            content?:
                                              | { __typename: "LexiconContent" }
                                              | { __typename: "LexiconEntryContent" }
                                              | {
                                                  __typename: "MediaContent"
                                                  media?:
                                                    | {
                                                        __typename: "AnimationMediaInfo"
                                                        thumbnail?: string | null
                                                        mediaType?: Types.MediaType | null
                                                      }
                                                    | {
                                                        __typename: "ImageMediaInfo"
                                                        src?: string | null
                                                        thumbnail?: string | null
                                                        mediaType?: Types.MediaType | null
                                                      }
                                                    | { __typename: "NoMediaInfo" }
                                                    | {
                                                        __typename: "VectorGraphicsMediaInfo"
                                                        svg?: string | null
                                                        thumbnail?: string | null
                                                        mediaType?: Types.MediaType | null
                                                      }
                                                    | {
                                                        __typename: "VideoMediaInfo"
                                                        thumbnail?: string | null
                                                        mediaType?: Types.MediaType | null
                                                      }
                                                    | null
                                                }
                                              | { __typename: "MediaGroupContent" }
                                              | { __typename: "ProjectContent" }
                                              | { __typename: "TermContent" }
                                              | { __typename: "TextContent" }
                                              | { __typename: "TextGroupContent" }
                                              | { __typename: "VariableTableContent" }
                                              | null
                                          }
                                        | { __typename: "ST4TreeNode"; id: string }
                                        | null
                                    }
                                  | { __typename: "MediaContent" }
                                  | { __typename: "MediaGroupContent" }
                                  | { __typename: "ProjectContent" }
                                  | { __typename: "TermContent" }
                                  | { __typename: "TextContent" }
                                  | { __typename: "TextGroupContent" }
                                  | { __typename: "VariableTableContent" }
                                  | null
                              }
                            | { __typename: "ST4TreeNode"; id: string }
                            | null
                        } | null> | null
                        comments: Array<{
                          __typename: "CommentNode"
                          id: string
                          comment:
                            | {
                                __typename: "BlockComment"
                                location: number
                                value?: string | null
                                authorDisplayName?: string | null
                                authorUserName?: string | null
                                commentKey: string
                                editDate?: any | null
                                modificationVersion?: number | null
                                replyToKey?: string | null
                                sortOrder?: number | null
                                commentType?: string | null
                                state?: string | null
                                color: string
                                persistanceState?: Types.PersistanceState | null
                                persistanceAction?: Types.PersistanceAction | null
                                node: {
                                  __typename: "ST4Node"
                                  aspectId: any
                                  id: string
                                  content?:
                                    | { __typename: "LexiconContent" }
                                    | { __typename: "LexiconEntryContent" }
                                    | { __typename: "MediaContent" }
                                    | { __typename: "MediaGroupContent" }
                                    | { __typename: "ProjectContent" }
                                    | { __typename: "TermContent" }
                                    | { __typename: "TextContent"; id: string; historyNumber: number }
                                    | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                                    | { __typename: "VariableTableContent" }
                                    | null
                                }
                                actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
                              }
                            | {
                                __typename: "TextComment"
                                endLocation: number
                                startLocation?: number | null
                                value?: string | null
                                authorDisplayName?: string | null
                                authorUserName?: string | null
                                commentKey: string
                                editDate?: any | null
                                modificationVersion?: number | null
                                replyToKey?: string | null
                                sortOrder?: number | null
                                commentType?: string | null
                                state?: string | null
                                color: string
                                persistanceState?: Types.PersistanceState | null
                                persistanceAction?: Types.PersistanceAction | null
                                node: {
                                  __typename: "ST4Node"
                                  aspectId: any
                                  id: string
                                  content?:
                                    | { __typename: "LexiconContent" }
                                    | { __typename: "LexiconEntryContent" }
                                    | { __typename: "MediaContent" }
                                    | { __typename: "MediaGroupContent" }
                                    | { __typename: "ProjectContent" }
                                    | { __typename: "TermContent" }
                                    | { __typename: "TextContent"; id: string; historyNumber: number }
                                    | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                                    | { __typename: "VariableTableContent" }
                                    | null
                                }
                                actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
                              }
                        }>
                      }
                    | {
                        __typename: "TextGroupContent"
                        currentNodeId?: any | null
                        groupNodeId: any
                        id: string
                        xast?: string | null
                        historyNumber: number
                        parts: Array<{
                          __typename: "TextContentPart"
                          ref: string
                          contentNode: {
                            __typename: "ST4Node"
                            id: string
                            aspectId: any
                            content?:
                              | { __typename: "LexiconContent" }
                              | { __typename: "LexiconEntryContent" }
                              | { __typename: "MediaContent" }
                              | { __typename: "MediaGroupContent" }
                              | { __typename: "ProjectContent" }
                              | { __typename: "TermContent" }
                              | {
                                  __typename: "TextContent"
                                  id: string
                                  xast?: string | null
                                  historyNumber: number
                                  links?: Array<{
                                    __typename: "TextContentLink"
                                    ref?: string | null
                                    node?:
                                      | { __typename: "CommentNode"; id: string }
                                      | { __typename: "ExternalSite"; id: string }
                                      | { __typename: "ST4Class"; id: string }
                                      | {
                                          __typename: "ST4Node"
                                          id: string
                                          aspectId: any
                                          label?: string | null
                                          properties?: {
                                            __typename: "ContentPropertyConnection"
                                            totalCount: number
                                            edges?: Array<{
                                              __typename: "ContentPropertyEdge"
                                              node?: {
                                                __typename: "ContentProperty"
                                                typeOfValue: Types.ContentPropertyValueType
                                                name: string
                                                value?: any | null
                                                displayName: string
                                              } | null
                                            } | null> | null
                                          } | null
                                        }
                                      | { __typename: "ST4TreeNode"; id: string }
                                      | null
                                  } | null> | null
                                  images: Array<{
                                    __typename: "NodeRef"
                                    id: string
                                    target?:
                                      | { __typename: "CommentNode"; id: string }
                                      | { __typename: "ExternalSite"; id: string }
                                      | { __typename: "ST4Class"; id: string }
                                      | {
                                          __typename: "ST4Node"
                                          aspectId: any
                                          id: string
                                          releaseState: {
                                            __typename: "ReleaseStateValue"
                                            displayName: string
                                            state: Types.ReleaseState
                                            isImplicitlyReleased: boolean
                                          }
                                          content?:
                                            | { __typename: "LexiconContent" }
                                            | { __typename: "LexiconEntryContent" }
                                            | {
                                                __typename: "MediaContent"
                                                media?:
                                                  | {
                                                      __typename: "AnimationMediaInfo"
                                                      id: string
                                                      resourceId?: string | null
                                                      thumbnail?: string | null
                                                      width?: number | null
                                                      height?: number | null
                                                      mediaType?: Types.MediaType | null
                                                    }
                                                  | {
                                                      __typename: "ImageMediaInfo"
                                                      id: string
                                                      resourceId?: string | null
                                                      thumbnail?: string | null
                                                      width?: number | null
                                                      height?: number | null
                                                      resolution?: number | null
                                                      colorDepth?: number | null
                                                      size?: number | null
                                                      mime?: string | null
                                                      extension?: string | null
                                                      allowCaching?: boolean | null
                                                      mediaType?: Types.MediaType | null
                                                      src?: string | null
                                                      language?: string | null
                                                    }
                                                  | {
                                                      __typename: "NoMediaInfo"
                                                      id: string
                                                      mediaType?: Types.MediaType | null
                                                    }
                                                  | {
                                                      __typename: "VectorGraphicsMediaInfo"
                                                      id: string
                                                      resourceId?: string | null
                                                      thumbnail?: string | null
                                                      width?: number | null
                                                      height?: number | null
                                                      allowCaching?: boolean | null
                                                      mediaType?: Types.MediaType | null
                                                      language?: string | null
                                                      labels: Array<{
                                                        __typename: "VectorGraphicsLabel"
                                                        id?: string | null
                                                        sequence?: number | null
                                                        autoValue?: string | null
                                                        text?: string | null
                                                        linkType?: Types.VectorGraphicsLabelLinkType | null
                                                        link?: string | null
                                                        ref?:
                                                          | {
                                                              __typename: "GenericRef"
                                                              id: string
                                                              owner?:
                                                                | { __typename: "CommentNode" }
                                                                | { __typename: "ExternalSite" }
                                                                | { __typename: "ST4Class" }
                                                                | { __typename: "ST4Node"; id: string }
                                                                | { __typename: "ST4TreeNode" }
                                                                | null
                                                              target?:
                                                                | { __typename: "CommentNode" }
                                                                | {
                                                                    __typename: "ExternalSite"
                                                                    id: string
                                                                    teaserData?: {
                                                                      __typename: "ExternalLinkTeaserData"
                                                                      title?: string | null
                                                                      summary?: string | null
                                                                      iconUrl?: string | null
                                                                      imageUrl?: string | null
                                                                      status?: Types.LinkTargetStatus | null
                                                                    } | null
                                                                  }
                                                                | { __typename: "ST4Class" }
                                                                | {
                                                                    __typename: "ST4Node"
                                                                    id: string
                                                                    aspectId: any
                                                                    label?: string | null
                                                                    properties?: {
                                                                      __typename: "ContentPropertyConnection"
                                                                      edges?: Array<{
                                                                        __typename: "ContentPropertyEdge"
                                                                        node?: {
                                                                          __typename: "ContentProperty"
                                                                          name: string
                                                                          value?: any | null
                                                                          displayName: string
                                                                        } | null
                                                                      } | null> | null
                                                                    } | null
                                                                  }
                                                                | { __typename: "ST4TreeNode" }
                                                                | null
                                                            }
                                                          | {
                                                              __typename: "NodeRef"
                                                              id: string
                                                              owner?:
                                                                | { __typename: "CommentNode" }
                                                                | { __typename: "ExternalSite" }
                                                                | { __typename: "ST4Class" }
                                                                | { __typename: "ST4Node"; id: string }
                                                                | { __typename: "ST4TreeNode" }
                                                                | null
                                                              target?:
                                                                | { __typename: "CommentNode" }
                                                                | {
                                                                    __typename: "ExternalSite"
                                                                    id: string
                                                                    teaserData?: {
                                                                      __typename: "ExternalLinkTeaserData"
                                                                      title?: string | null
                                                                      summary?: string | null
                                                                      iconUrl?: string | null
                                                                      imageUrl?: string | null
                                                                      status?: Types.LinkTargetStatus | null
                                                                    } | null
                                                                  }
                                                                | { __typename: "ST4Class" }
                                                                | {
                                                                    __typename: "ST4Node"
                                                                    id: string
                                                                    aspectId: any
                                                                    label?: string | null
                                                                    properties?: {
                                                                      __typename: "ContentPropertyConnection"
                                                                      edges?: Array<{
                                                                        __typename: "ContentPropertyEdge"
                                                                        node?: {
                                                                          __typename: "ContentProperty"
                                                                          name: string
                                                                          value?: any | null
                                                                          displayName: string
                                                                        } | null
                                                                      } | null> | null
                                                                    } | null
                                                                  }
                                                                | { __typename: "ST4TreeNode" }
                                                                | null
                                                            }
                                                          | null
                                                      }>
                                                    }
                                                  | {
                                                      __typename: "VideoMediaInfo"
                                                      id: string
                                                      resourceId?: string | null
                                                      thumbnail?: string | null
                                                      width?: number | null
                                                      height?: number | null
                                                      mediaType?: Types.MediaType | null
                                                    }
                                                  | null
                                              }
                                            | {
                                                __typename: "MediaGroupContent"
                                                children: Array<{
                                                  __typename: "NodeRef"
                                                  id: string
                                                  target?:
                                                    | { __typename: "CommentNode"; id: string }
                                                    | { __typename: "ExternalSite"; id: string }
                                                    | { __typename: "ST4Class"; id: string }
                                                    | {
                                                        __typename: "ST4Node"
                                                        id: string
                                                        aspectId: any
                                                        content?:
                                                          | { __typename: "LexiconContent" }
                                                          | { __typename: "LexiconEntryContent" }
                                                          | {
                                                              __typename: "MediaContent"
                                                              media?:
                                                                | {
                                                                    __typename: "AnimationMediaInfo"
                                                                    id: string
                                                                    resourceId?: string | null
                                                                    thumbnail?: string | null
                                                                    width?: number | null
                                                                    height?: number | null
                                                                    mediaType?: Types.MediaType | null
                                                                  }
                                                                | {
                                                                    __typename: "ImageMediaInfo"
                                                                    id: string
                                                                    resourceId?: string | null
                                                                    thumbnail?: string | null
                                                                    width?: number | null
                                                                    height?: number | null
                                                                    resolution?: number | null
                                                                    colorDepth?: number | null
                                                                    size?: number | null
                                                                    mime?: string | null
                                                                    extension?: string | null
                                                                    allowCaching?: boolean | null
                                                                    mediaType?: Types.MediaType | null
                                                                    src?: string | null
                                                                    language?: string | null
                                                                  }
                                                                | {
                                                                    __typename: "NoMediaInfo"
                                                                    id: string
                                                                    mediaType?: Types.MediaType | null
                                                                  }
                                                                | {
                                                                    __typename: "VectorGraphicsMediaInfo"
                                                                    id: string
                                                                    resourceId?: string | null
                                                                    thumbnail?: string | null
                                                                    width?: number | null
                                                                    height?: number | null
                                                                    allowCaching?: boolean | null
                                                                    mediaType?: Types.MediaType | null
                                                                    language?: string | null
                                                                    labels: Array<{
                                                                      __typename: "VectorGraphicsLabel"
                                                                      id?: string | null
                                                                      sequence?: number | null
                                                                      autoValue?: string | null
                                                                      text?: string | null
                                                                      linkType?: Types.VectorGraphicsLabelLinkType | null
                                                                      link?: string | null
                                                                      ref?:
                                                                        | {
                                                                            __typename: "GenericRef"
                                                                            id: string
                                                                            owner?:
                                                                              | { __typename: "CommentNode" }
                                                                              | { __typename: "ExternalSite" }
                                                                              | { __typename: "ST4Class" }
                                                                              | { __typename: "ST4Node"; id: string }
                                                                              | { __typename: "ST4TreeNode" }
                                                                              | null
                                                                            target?:
                                                                              | { __typename: "CommentNode" }
                                                                              | {
                                                                                  __typename: "ExternalSite"
                                                                                  id: string
                                                                                  teaserData?: {
                                                                                    __typename: "ExternalLinkTeaserData"
                                                                                    title?: string | null
                                                                                    summary?: string | null
                                                                                    iconUrl?: string | null
                                                                                    imageUrl?: string | null
                                                                                    status?: Types.LinkTargetStatus | null
                                                                                  } | null
                                                                                }
                                                                              | { __typename: "ST4Class" }
                                                                              | {
                                                                                  __typename: "ST4Node"
                                                                                  id: string
                                                                                  aspectId: any
                                                                                  label?: string | null
                                                                                  properties?: {
                                                                                    __typename: "ContentPropertyConnection"
                                                                                    edges?: Array<{
                                                                                      __typename: "ContentPropertyEdge"
                                                                                      node?: {
                                                                                        __typename: "ContentProperty"
                                                                                        name: string
                                                                                        value?: any | null
                                                                                        displayName: string
                                                                                      } | null
                                                                                    } | null> | null
                                                                                  } | null
                                                                                }
                                                                              | { __typename: "ST4TreeNode" }
                                                                              | null
                                                                          }
                                                                        | {
                                                                            __typename: "NodeRef"
                                                                            id: string
                                                                            owner?:
                                                                              | { __typename: "CommentNode" }
                                                                              | { __typename: "ExternalSite" }
                                                                              | { __typename: "ST4Class" }
                                                                              | { __typename: "ST4Node"; id: string }
                                                                              | { __typename: "ST4TreeNode" }
                                                                              | null
                                                                            target?:
                                                                              | { __typename: "CommentNode" }
                                                                              | {
                                                                                  __typename: "ExternalSite"
                                                                                  id: string
                                                                                  teaserData?: {
                                                                                    __typename: "ExternalLinkTeaserData"
                                                                                    title?: string | null
                                                                                    summary?: string | null
                                                                                    iconUrl?: string | null
                                                                                    imageUrl?: string | null
                                                                                    status?: Types.LinkTargetStatus | null
                                                                                  } | null
                                                                                }
                                                                              | { __typename: "ST4Class" }
                                                                              | {
                                                                                  __typename: "ST4Node"
                                                                                  id: string
                                                                                  aspectId: any
                                                                                  label?: string | null
                                                                                  properties?: {
                                                                                    __typename: "ContentPropertyConnection"
                                                                                    edges?: Array<{
                                                                                      __typename: "ContentPropertyEdge"
                                                                                      node?: {
                                                                                        __typename: "ContentProperty"
                                                                                        name: string
                                                                                        value?: any | null
                                                                                        displayName: string
                                                                                      } | null
                                                                                    } | null> | null
                                                                                  } | null
                                                                                }
                                                                              | { __typename: "ST4TreeNode" }
                                                                              | null
                                                                          }
                                                                        | null
                                                                    }>
                                                                  }
                                                                | {
                                                                    __typename: "VideoMediaInfo"
                                                                    id: string
                                                                    resourceId?: string | null
                                                                    thumbnail?: string | null
                                                                    width?: number | null
                                                                    height?: number | null
                                                                    mediaType?: Types.MediaType | null
                                                                  }
                                                                | null
                                                            }
                                                          | {
                                                              __typename: "MediaGroupContent"
                                                              media?:
                                                                | {
                                                                    __typename: "AnimationMediaInfo"
                                                                    id: string
                                                                    resourceId?: string | null
                                                                    thumbnail?: string | null
                                                                    width?: number | null
                                                                    height?: number | null
                                                                    mediaType?: Types.MediaType | null
                                                                  }
                                                                | {
                                                                    __typename: "ImageMediaInfo"
                                                                    id: string
                                                                    resourceId?: string | null
                                                                    thumbnail?: string | null
                                                                    width?: number | null
                                                                    height?: number | null
                                                                    resolution?: number | null
                                                                    colorDepth?: number | null
                                                                    size?: number | null
                                                                    mime?: string | null
                                                                    extension?: string | null
                                                                    allowCaching?: boolean | null
                                                                    mediaType?: Types.MediaType | null
                                                                    src?: string | null
                                                                    language?: string | null
                                                                  }
                                                                | {
                                                                    __typename: "NoMediaInfo"
                                                                    id: string
                                                                    mediaType?: Types.MediaType | null
                                                                  }
                                                                | {
                                                                    __typename: "VectorGraphicsMediaInfo"
                                                                    id: string
                                                                    resourceId?: string | null
                                                                    thumbnail?: string | null
                                                                    width?: number | null
                                                                    height?: number | null
                                                                    allowCaching?: boolean | null
                                                                    mediaType?: Types.MediaType | null
                                                                    language?: string | null
                                                                    labels: Array<{
                                                                      __typename: "VectorGraphicsLabel"
                                                                      id?: string | null
                                                                      sequence?: number | null
                                                                      autoValue?: string | null
                                                                      text?: string | null
                                                                      linkType?: Types.VectorGraphicsLabelLinkType | null
                                                                      link?: string | null
                                                                      ref?:
                                                                        | {
                                                                            __typename: "GenericRef"
                                                                            id: string
                                                                            owner?:
                                                                              | { __typename: "CommentNode" }
                                                                              | { __typename: "ExternalSite" }
                                                                              | { __typename: "ST4Class" }
                                                                              | { __typename: "ST4Node"; id: string }
                                                                              | { __typename: "ST4TreeNode" }
                                                                              | null
                                                                            target?:
                                                                              | { __typename: "CommentNode" }
                                                                              | {
                                                                                  __typename: "ExternalSite"
                                                                                  id: string
                                                                                  teaserData?: {
                                                                                    __typename: "ExternalLinkTeaserData"
                                                                                    title?: string | null
                                                                                    summary?: string | null
                                                                                    iconUrl?: string | null
                                                                                    imageUrl?: string | null
                                                                                    status?: Types.LinkTargetStatus | null
                                                                                  } | null
                                                                                }
                                                                              | { __typename: "ST4Class" }
                                                                              | {
                                                                                  __typename: "ST4Node"
                                                                                  id: string
                                                                                  aspectId: any
                                                                                  label?: string | null
                                                                                  properties?: {
                                                                                    __typename: "ContentPropertyConnection"
                                                                                    edges?: Array<{
                                                                                      __typename: "ContentPropertyEdge"
                                                                                      node?: {
                                                                                        __typename: "ContentProperty"
                                                                                        name: string
                                                                                        value?: any | null
                                                                                        displayName: string
                                                                                      } | null
                                                                                    } | null> | null
                                                                                  } | null
                                                                                }
                                                                              | { __typename: "ST4TreeNode" }
                                                                              | null
                                                                          }
                                                                        | {
                                                                            __typename: "NodeRef"
                                                                            id: string
                                                                            owner?:
                                                                              | { __typename: "CommentNode" }
                                                                              | { __typename: "ExternalSite" }
                                                                              | { __typename: "ST4Class" }
                                                                              | { __typename: "ST4Node"; id: string }
                                                                              | { __typename: "ST4TreeNode" }
                                                                              | null
                                                                            target?:
                                                                              | { __typename: "CommentNode" }
                                                                              | {
                                                                                  __typename: "ExternalSite"
                                                                                  id: string
                                                                                  teaserData?: {
                                                                                    __typename: "ExternalLinkTeaserData"
                                                                                    title?: string | null
                                                                                    summary?: string | null
                                                                                    iconUrl?: string | null
                                                                                    imageUrl?: string | null
                                                                                    status?: Types.LinkTargetStatus | null
                                                                                  } | null
                                                                                }
                                                                              | { __typename: "ST4Class" }
                                                                              | {
                                                                                  __typename: "ST4Node"
                                                                                  id: string
                                                                                  aspectId: any
                                                                                  label?: string | null
                                                                                  properties?: {
                                                                                    __typename: "ContentPropertyConnection"
                                                                                    edges?: Array<{
                                                                                      __typename: "ContentPropertyEdge"
                                                                                      node?: {
                                                                                        __typename: "ContentProperty"
                                                                                        name: string
                                                                                        value?: any | null
                                                                                        displayName: string
                                                                                      } | null
                                                                                    } | null> | null
                                                                                  } | null
                                                                                }
                                                                              | { __typename: "ST4TreeNode" }
                                                                              | null
                                                                          }
                                                                        | null
                                                                    }>
                                                                  }
                                                                | {
                                                                    __typename: "VideoMediaInfo"
                                                                    id: string
                                                                    resourceId?: string | null
                                                                    thumbnail?: string | null
                                                                    width?: number | null
                                                                    height?: number | null
                                                                    mediaType?: Types.MediaType | null
                                                                  }
                                                                | null
                                                            }
                                                          | { __typename: "ProjectContent" }
                                                          | { __typename: "TermContent" }
                                                          | { __typename: "TextContent" }
                                                          | { __typename: "TextGroupContent" }
                                                          | { __typename: "VariableTableContent" }
                                                          | null
                                                      }
                                                    | { __typename: "ST4TreeNode"; id: string }
                                                    | null
                                                }>
                                                media?:
                                                  | {
                                                      __typename: "AnimationMediaInfo"
                                                      id: string
                                                      resourceId?: string | null
                                                      thumbnail?: string | null
                                                      width?: number | null
                                                      height?: number | null
                                                      mediaType?: Types.MediaType | null
                                                    }
                                                  | {
                                                      __typename: "ImageMediaInfo"
                                                      id: string
                                                      resourceId?: string | null
                                                      thumbnail?: string | null
                                                      width?: number | null
                                                      height?: number | null
                                                      resolution?: number | null
                                                      colorDepth?: number | null
                                                      size?: number | null
                                                      mime?: string | null
                                                      extension?: string | null
                                                      allowCaching?: boolean | null
                                                      mediaType?: Types.MediaType | null
                                                      src?: string | null
                                                      language?: string | null
                                                    }
                                                  | {
                                                      __typename: "NoMediaInfo"
                                                      id: string
                                                      mediaType?: Types.MediaType | null
                                                    }
                                                  | {
                                                      __typename: "VectorGraphicsMediaInfo"
                                                      id: string
                                                      resourceId?: string | null
                                                      thumbnail?: string | null
                                                      width?: number | null
                                                      height?: number | null
                                                      allowCaching?: boolean | null
                                                      mediaType?: Types.MediaType | null
                                                      language?: string | null
                                                      labels: Array<{
                                                        __typename: "VectorGraphicsLabel"
                                                        id?: string | null
                                                        sequence?: number | null
                                                        autoValue?: string | null
                                                        text?: string | null
                                                        linkType?: Types.VectorGraphicsLabelLinkType | null
                                                        link?: string | null
                                                        ref?:
                                                          | {
                                                              __typename: "GenericRef"
                                                              id: string
                                                              owner?:
                                                                | { __typename: "CommentNode" }
                                                                | { __typename: "ExternalSite" }
                                                                | { __typename: "ST4Class" }
                                                                | { __typename: "ST4Node"; id: string }
                                                                | { __typename: "ST4TreeNode" }
                                                                | null
                                                              target?:
                                                                | { __typename: "CommentNode" }
                                                                | {
                                                                    __typename: "ExternalSite"
                                                                    id: string
                                                                    teaserData?: {
                                                                      __typename: "ExternalLinkTeaserData"
                                                                      title?: string | null
                                                                      summary?: string | null
                                                                      iconUrl?: string | null
                                                                      imageUrl?: string | null
                                                                      status?: Types.LinkTargetStatus | null
                                                                    } | null
                                                                  }
                                                                | { __typename: "ST4Class" }
                                                                | {
                                                                    __typename: "ST4Node"
                                                                    id: string
                                                                    aspectId: any
                                                                    label?: string | null
                                                                    properties?: {
                                                                      __typename: "ContentPropertyConnection"
                                                                      edges?: Array<{
                                                                        __typename: "ContentPropertyEdge"
                                                                        node?: {
                                                                          __typename: "ContentProperty"
                                                                          name: string
                                                                          value?: any | null
                                                                          displayName: string
                                                                        } | null
                                                                      } | null> | null
                                                                    } | null
                                                                  }
                                                                | { __typename: "ST4TreeNode" }
                                                                | null
                                                            }
                                                          | {
                                                              __typename: "NodeRef"
                                                              id: string
                                                              owner?:
                                                                | { __typename: "CommentNode" }
                                                                | { __typename: "ExternalSite" }
                                                                | { __typename: "ST4Class" }
                                                                | { __typename: "ST4Node"; id: string }
                                                                | { __typename: "ST4TreeNode" }
                                                                | null
                                                              target?:
                                                                | { __typename: "CommentNode" }
                                                                | {
                                                                    __typename: "ExternalSite"
                                                                    id: string
                                                                    teaserData?: {
                                                                      __typename: "ExternalLinkTeaserData"
                                                                      title?: string | null
                                                                      summary?: string | null
                                                                      iconUrl?: string | null
                                                                      imageUrl?: string | null
                                                                      status?: Types.LinkTargetStatus | null
                                                                    } | null
                                                                  }
                                                                | { __typename: "ST4Class" }
                                                                | {
                                                                    __typename: "ST4Node"
                                                                    id: string
                                                                    aspectId: any
                                                                    label?: string | null
                                                                    properties?: {
                                                                      __typename: "ContentPropertyConnection"
                                                                      edges?: Array<{
                                                                        __typename: "ContentPropertyEdge"
                                                                        node?: {
                                                                          __typename: "ContentProperty"
                                                                          name: string
                                                                          value?: any | null
                                                                          displayName: string
                                                                        } | null
                                                                      } | null> | null
                                                                    } | null
                                                                  }
                                                                | { __typename: "ST4TreeNode" }
                                                                | null
                                                            }
                                                          | null
                                                      }>
                                                    }
                                                  | {
                                                      __typename: "VideoMediaInfo"
                                                      id: string
                                                      resourceId?: string | null
                                                      thumbnail?: string | null
                                                      width?: number | null
                                                      height?: number | null
                                                      mediaType?: Types.MediaType | null
                                                    }
                                                  | null
                                              }
                                            | { __typename: "ProjectContent" }
                                            | { __typename: "TermContent" }
                                            | { __typename: "TextContent" }
                                            | { __typename: "TextGroupContent" }
                                            | { __typename: "VariableTableContent" }
                                            | null
                                        }
                                      | { __typename: "ST4TreeNode"; id: string }
                                      | null
                                  }>
                                  variableTables?: Array<{
                                    __typename: "VariableTableReference"
                                    ref?: string | null
                                    node?:
                                      | { __typename: "CommentNode" }
                                      | { __typename: "ExternalSite" }
                                      | { __typename: "ST4Class" }
                                      | {
                                          __typename: "ST4Node"
                                          id: string
                                          label?: string | null
                                          aspectId: any
                                          content?:
                                            | { __typename: "LexiconContent" }
                                            | { __typename: "LexiconEntryContent" }
                                            | { __typename: "MediaContent" }
                                            | { __typename: "MediaGroupContent" }
                                            | { __typename: "ProjectContent" }
                                            | { __typename: "TermContent" }
                                            | { __typename: "TextContent" }
                                            | { __typename: "TextGroupContent" }
                                            | {
                                                __typename: "VariableTableContent"
                                                xml?: string | null
                                                valueSets: Array<{
                                                  __typename: "ValueSet"
                                                  name: string
                                                  valueSetId: string
                                                }>
                                                variables: Array<{
                                                  __typename: "Variable"
                                                  name: string
                                                  variableId: string
                                                  values: Array<string>
                                                }>
                                              }
                                            | null
                                        }
                                      | { __typename: "ST4TreeNode" }
                                      | null
                                  } | null> | null
                                  linkedDataNodes: Array<{
                                    __typename: "NodeRef"
                                    id: string
                                    target?:
                                      | { __typename: "CommentNode"; id: string }
                                      | { __typename: "ExternalSite"; id: string }
                                      | { __typename: "ST4Class"; id: string }
                                      | {
                                          __typename: "ST4Node"
                                          label?: string | null
                                          id: string
                                          metadata: Array<{
                                            __typename: "MetaDataEntry"
                                            label?: string | null
                                            systemName: string
                                            value?: string | null
                                            values: Array<string>
                                            users: Array<string>
                                          }>
                                          linkedOntologyNodes: Array<
                                            | {
                                                __typename: "GenericRef"
                                                target?:
                                                  | { __typename: "CommentNode" }
                                                  | { __typename: "ExternalSite" }
                                                  | { __typename: "ST4Class" }
                                                  | { __typename: "ST4Node"; id: string; label?: string | null }
                                                  | { __typename: "ST4TreeNode" }
                                                  | null
                                              }
                                            | {
                                                __typename: "NodeRef"
                                                linkClassName: string
                                                class?: { __typename: "ST4Class"; label?: string | null } | null
                                                target?:
                                                  | { __typename: "CommentNode" }
                                                  | { __typename: "ExternalSite" }
                                                  | { __typename: "ST4Class" }
                                                  | { __typename: "ST4Node"; id: string; label?: string | null }
                                                  | { __typename: "ST4TreeNode" }
                                                  | null
                                              }
                                          >
                                        }
                                      | { __typename: "ST4TreeNode"; id: string }
                                      | null
                                  }>
                                  lexiconEntries?: Array<{
                                    __typename: "NodeRef"
                                    id: string
                                    target?:
                                      | { __typename: "CommentNode"; id: string }
                                      | { __typename: "ExternalSite"; id: string }
                                      | { __typename: "ST4Class"; id: string }
                                      | {
                                          __typename: "ST4Node"
                                          aspectId: any
                                          label?: string | null
                                          id: string
                                          content?:
                                            | { __typename: "LexiconContent" }
                                            | {
                                                __typename: "LexiconEntryContent"
                                                descriptionXast?: string | null
                                                subject?: string | null
                                                sortKey?: string | null
                                                lexicon:
                                                  | { __typename: "CommentNode"; id: string }
                                                  | { __typename: "ExternalSite"; id: string }
                                                  | { __typename: "ST4Class"; id: string }
                                                  | { __typename: "ST4Node"; label?: string | null; id: string }
                                                  | { __typename: "ST4TreeNode"; id: string }
                                                terms: Array<
                                                  | { __typename: "CommentNode"; id: string }
                                                  | { __typename: "ExternalSite"; id: string }
                                                  | { __typename: "ST4Class"; id: string }
                                                  | {
                                                      __typename: "ST4Node"
                                                      id: string
                                                      label?: string | null
                                                      aspectId: any
                                                      content?:
                                                        | { __typename: "LexiconContent" }
                                                        | { __typename: "LexiconEntryContent" }
                                                        | { __typename: "MediaContent" }
                                                        | { __typename: "MediaGroupContent" }
                                                        | { __typename: "ProjectContent" }
                                                        | {
                                                            __typename: "TermContent"
                                                            gramaticalGender?: string | null
                                                            validity?: string | null
                                                            editLanguage?: string | null
                                                          }
                                                        | { __typename: "TextContent" }
                                                        | { __typename: "TextGroupContent" }
                                                        | { __typename: "VariableTableContent" }
                                                        | null
                                                    }
                                                  | { __typename: "ST4TreeNode"; id: string }
                                                >
                                                image?:
                                                  | { __typename: "CommentNode"; id: string }
                                                  | { __typename: "ExternalSite"; id: string }
                                                  | { __typename: "ST4Class"; id: string }
                                                  | {
                                                      __typename: "ST4Node"
                                                      aspectId: any
                                                      id: string
                                                      content?:
                                                        | { __typename: "LexiconContent" }
                                                        | { __typename: "LexiconEntryContent" }
                                                        | {
                                                            __typename: "MediaContent"
                                                            media?:
                                                              | {
                                                                  __typename: "AnimationMediaInfo"
                                                                  thumbnail?: string | null
                                                                  mediaType?: Types.MediaType | null
                                                                }
                                                              | {
                                                                  __typename: "ImageMediaInfo"
                                                                  src?: string | null
                                                                  thumbnail?: string | null
                                                                  mediaType?: Types.MediaType | null
                                                                }
                                                              | { __typename: "NoMediaInfo" }
                                                              | {
                                                                  __typename: "VectorGraphicsMediaInfo"
                                                                  svg?: string | null
                                                                  thumbnail?: string | null
                                                                  mediaType?: Types.MediaType | null
                                                                }
                                                              | {
                                                                  __typename: "VideoMediaInfo"
                                                                  thumbnail?: string | null
                                                                  mediaType?: Types.MediaType | null
                                                                }
                                                              | null
                                                          }
                                                        | { __typename: "MediaGroupContent" }
                                                        | { __typename: "ProjectContent" }
                                                        | { __typename: "TermContent" }
                                                        | { __typename: "TextContent" }
                                                        | { __typename: "TextGroupContent" }
                                                        | { __typename: "VariableTableContent" }
                                                        | null
                                                    }
                                                  | { __typename: "ST4TreeNode"; id: string }
                                                  | null
                                              }
                                            | { __typename: "MediaContent" }
                                            | { __typename: "MediaGroupContent" }
                                            | { __typename: "ProjectContent" }
                                            | { __typename: "TermContent" }
                                            | { __typename: "TextContent" }
                                            | { __typename: "TextGroupContent" }
                                            | { __typename: "VariableTableContent" }
                                            | null
                                        }
                                      | { __typename: "ST4TreeNode"; id: string }
                                      | null
                                  } | null> | null
                                  comments: Array<{
                                    __typename: "CommentNode"
                                    id: string
                                    comment:
                                      | {
                                          __typename: "BlockComment"
                                          location: number
                                          value?: string | null
                                          authorDisplayName?: string | null
                                          authorUserName?: string | null
                                          commentKey: string
                                          editDate?: any | null
                                          modificationVersion?: number | null
                                          replyToKey?: string | null
                                          sortOrder?: number | null
                                          commentType?: string | null
                                          state?: string | null
                                          color: string
                                          persistanceState?: Types.PersistanceState | null
                                          persistanceAction?: Types.PersistanceAction | null
                                          node: {
                                            __typename: "ST4Node"
                                            aspectId: any
                                            id: string
                                            content?:
                                              | { __typename: "LexiconContent" }
                                              | { __typename: "LexiconEntryContent" }
                                              | { __typename: "MediaContent" }
                                              | { __typename: "MediaGroupContent" }
                                              | { __typename: "ProjectContent" }
                                              | { __typename: "TermContent" }
                                              | { __typename: "TextContent"; id: string; historyNumber: number }
                                              | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                                              | { __typename: "VariableTableContent" }
                                              | null
                                          }
                                          actions: Array<{
                                            __typename: "Action"
                                            name?: string | null
                                            enabled?: boolean | null
                                          }>
                                        }
                                      | {
                                          __typename: "TextComment"
                                          endLocation: number
                                          startLocation?: number | null
                                          value?: string | null
                                          authorDisplayName?: string | null
                                          authorUserName?: string | null
                                          commentKey: string
                                          editDate?: any | null
                                          modificationVersion?: number | null
                                          replyToKey?: string | null
                                          sortOrder?: number | null
                                          commentType?: string | null
                                          state?: string | null
                                          color: string
                                          persistanceState?: Types.PersistanceState | null
                                          persistanceAction?: Types.PersistanceAction | null
                                          node: {
                                            __typename: "ST4Node"
                                            aspectId: any
                                            id: string
                                            content?:
                                              | { __typename: "LexiconContent" }
                                              | { __typename: "LexiconEntryContent" }
                                              | { __typename: "MediaContent" }
                                              | { __typename: "MediaGroupContent" }
                                              | { __typename: "ProjectContent" }
                                              | { __typename: "TermContent" }
                                              | { __typename: "TextContent"; id: string; historyNumber: number }
                                              | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                                              | { __typename: "VariableTableContent" }
                                              | null
                                          }
                                          actions: Array<{
                                            __typename: "Action"
                                            name?: string | null
                                            enabled?: boolean | null
                                          }>
                                        }
                                  }>
                                }
                              | {
                                  __typename: "TextGroupContent"
                                  currentNodeId?: any | null
                                  groupNodeId: any
                                  id: string
                                  xast?: string | null
                                  historyNumber: number
                                  children: Array<{
                                    __typename: "NodeRef"
                                    id: string
                                    target?:
                                      | { __typename: "CommentNode"; id: string }
                                      | { __typename: "ExternalSite"; id: string }
                                      | { __typename: "ST4Class"; id: string }
                                      | { __typename: "ST4Node"; label?: string | null; aspectId: any; id: string }
                                      | { __typename: "ST4TreeNode"; id: string }
                                      | null
                                  }>
                                  links?: Array<{
                                    __typename: "TextContentLink"
                                    ref?: string | null
                                    node?:
                                      | { __typename: "CommentNode"; id: string }
                                      | { __typename: "ExternalSite"; id: string }
                                      | { __typename: "ST4Class"; id: string }
                                      | {
                                          __typename: "ST4Node"
                                          id: string
                                          aspectId: any
                                          label?: string | null
                                          properties?: {
                                            __typename: "ContentPropertyConnection"
                                            totalCount: number
                                            edges?: Array<{
                                              __typename: "ContentPropertyEdge"
                                              node?: {
                                                __typename: "ContentProperty"
                                                typeOfValue: Types.ContentPropertyValueType
                                                name: string
                                                value?: any | null
                                                displayName: string
                                              } | null
                                            } | null> | null
                                          } | null
                                        }
                                      | { __typename: "ST4TreeNode"; id: string }
                                      | null
                                  } | null> | null
                                  images: Array<{
                                    __typename: "NodeRef"
                                    id: string
                                    target?:
                                      | { __typename: "CommentNode"; id: string }
                                      | { __typename: "ExternalSite"; id: string }
                                      | { __typename: "ST4Class"; id: string }
                                      | {
                                          __typename: "ST4Node"
                                          aspectId: any
                                          id: string
                                          releaseState: {
                                            __typename: "ReleaseStateValue"
                                            displayName: string
                                            state: Types.ReleaseState
                                            isImplicitlyReleased: boolean
                                          }
                                          content?:
                                            | { __typename: "LexiconContent" }
                                            | { __typename: "LexiconEntryContent" }
                                            | {
                                                __typename: "MediaContent"
                                                media?:
                                                  | {
                                                      __typename: "AnimationMediaInfo"
                                                      id: string
                                                      resourceId?: string | null
                                                      thumbnail?: string | null
                                                      width?: number | null
                                                      height?: number | null
                                                      mediaType?: Types.MediaType | null
                                                    }
                                                  | {
                                                      __typename: "ImageMediaInfo"
                                                      id: string
                                                      resourceId?: string | null
                                                      thumbnail?: string | null
                                                      width?: number | null
                                                      height?: number | null
                                                      resolution?: number | null
                                                      colorDepth?: number | null
                                                      size?: number | null
                                                      mime?: string | null
                                                      extension?: string | null
                                                      allowCaching?: boolean | null
                                                      mediaType?: Types.MediaType | null
                                                      src?: string | null
                                                      language?: string | null
                                                    }
                                                  | {
                                                      __typename: "NoMediaInfo"
                                                      id: string
                                                      mediaType?: Types.MediaType | null
                                                    }
                                                  | {
                                                      __typename: "VectorGraphicsMediaInfo"
                                                      id: string
                                                      resourceId?: string | null
                                                      thumbnail?: string | null
                                                      width?: number | null
                                                      height?: number | null
                                                      allowCaching?: boolean | null
                                                      mediaType?: Types.MediaType | null
                                                      language?: string | null
                                                      labels: Array<{
                                                        __typename: "VectorGraphicsLabel"
                                                        id?: string | null
                                                        sequence?: number | null
                                                        autoValue?: string | null
                                                        text?: string | null
                                                        linkType?: Types.VectorGraphicsLabelLinkType | null
                                                        link?: string | null
                                                        ref?:
                                                          | {
                                                              __typename: "GenericRef"
                                                              id: string
                                                              owner?:
                                                                | { __typename: "CommentNode" }
                                                                | { __typename: "ExternalSite" }
                                                                | { __typename: "ST4Class" }
                                                                | { __typename: "ST4Node"; id: string }
                                                                | { __typename: "ST4TreeNode" }
                                                                | null
                                                              target?:
                                                                | { __typename: "CommentNode" }
                                                                | {
                                                                    __typename: "ExternalSite"
                                                                    id: string
                                                                    teaserData?: {
                                                                      __typename: "ExternalLinkTeaserData"
                                                                      title?: string | null
                                                                      summary?: string | null
                                                                      iconUrl?: string | null
                                                                      imageUrl?: string | null
                                                                      status?: Types.LinkTargetStatus | null
                                                                    } | null
                                                                  }
                                                                | { __typename: "ST4Class" }
                                                                | {
                                                                    __typename: "ST4Node"
                                                                    id: string
                                                                    aspectId: any
                                                                    label?: string | null
                                                                    properties?: {
                                                                      __typename: "ContentPropertyConnection"
                                                                      edges?: Array<{
                                                                        __typename: "ContentPropertyEdge"
                                                                        node?: {
                                                                          __typename: "ContentProperty"
                                                                          name: string
                                                                          value?: any | null
                                                                          displayName: string
                                                                        } | null
                                                                      } | null> | null
                                                                    } | null
                                                                  }
                                                                | { __typename: "ST4TreeNode" }
                                                                | null
                                                            }
                                                          | {
                                                              __typename: "NodeRef"
                                                              id: string
                                                              owner?:
                                                                | { __typename: "CommentNode" }
                                                                | { __typename: "ExternalSite" }
                                                                | { __typename: "ST4Class" }
                                                                | { __typename: "ST4Node"; id: string }
                                                                | { __typename: "ST4TreeNode" }
                                                                | null
                                                              target?:
                                                                | { __typename: "CommentNode" }
                                                                | {
                                                                    __typename: "ExternalSite"
                                                                    id: string
                                                                    teaserData?: {
                                                                      __typename: "ExternalLinkTeaserData"
                                                                      title?: string | null
                                                                      summary?: string | null
                                                                      iconUrl?: string | null
                                                                      imageUrl?: string | null
                                                                      status?: Types.LinkTargetStatus | null
                                                                    } | null
                                                                  }
                                                                | { __typename: "ST4Class" }
                                                                | {
                                                                    __typename: "ST4Node"
                                                                    id: string
                                                                    aspectId: any
                                                                    label?: string | null
                                                                    properties?: {
                                                                      __typename: "ContentPropertyConnection"
                                                                      edges?: Array<{
                                                                        __typename: "ContentPropertyEdge"
                                                                        node?: {
                                                                          __typename: "ContentProperty"
                                                                          name: string
                                                                          value?: any | null
                                                                          displayName: string
                                                                        } | null
                                                                      } | null> | null
                                                                    } | null
                                                                  }
                                                                | { __typename: "ST4TreeNode" }
                                                                | null
                                                            }
                                                          | null
                                                      }>
                                                    }
                                                  | {
                                                      __typename: "VideoMediaInfo"
                                                      id: string
                                                      resourceId?: string | null
                                                      thumbnail?: string | null
                                                      width?: number | null
                                                      height?: number | null
                                                      mediaType?: Types.MediaType | null
                                                    }
                                                  | null
                                              }
                                            | {
                                                __typename: "MediaGroupContent"
                                                children: Array<{
                                                  __typename: "NodeRef"
                                                  id: string
                                                  target?:
                                                    | { __typename: "CommentNode"; id: string }
                                                    | { __typename: "ExternalSite"; id: string }
                                                    | { __typename: "ST4Class"; id: string }
                                                    | {
                                                        __typename: "ST4Node"
                                                        id: string
                                                        aspectId: any
                                                        content?:
                                                          | { __typename: "LexiconContent" }
                                                          | { __typename: "LexiconEntryContent" }
                                                          | {
                                                              __typename: "MediaContent"
                                                              media?:
                                                                | {
                                                                    __typename: "AnimationMediaInfo"
                                                                    id: string
                                                                    resourceId?: string | null
                                                                    thumbnail?: string | null
                                                                    width?: number | null
                                                                    height?: number | null
                                                                    mediaType?: Types.MediaType | null
                                                                  }
                                                                | {
                                                                    __typename: "ImageMediaInfo"
                                                                    id: string
                                                                    resourceId?: string | null
                                                                    thumbnail?: string | null
                                                                    width?: number | null
                                                                    height?: number | null
                                                                    resolution?: number | null
                                                                    colorDepth?: number | null
                                                                    size?: number | null
                                                                    mime?: string | null
                                                                    extension?: string | null
                                                                    allowCaching?: boolean | null
                                                                    mediaType?: Types.MediaType | null
                                                                    src?: string | null
                                                                    language?: string | null
                                                                  }
                                                                | {
                                                                    __typename: "NoMediaInfo"
                                                                    id: string
                                                                    mediaType?: Types.MediaType | null
                                                                  }
                                                                | {
                                                                    __typename: "VectorGraphicsMediaInfo"
                                                                    id: string
                                                                    resourceId?: string | null
                                                                    thumbnail?: string | null
                                                                    width?: number | null
                                                                    height?: number | null
                                                                    allowCaching?: boolean | null
                                                                    mediaType?: Types.MediaType | null
                                                                    language?: string | null
                                                                    labels: Array<{
                                                                      __typename: "VectorGraphicsLabel"
                                                                      id?: string | null
                                                                      sequence?: number | null
                                                                      autoValue?: string | null
                                                                      text?: string | null
                                                                      linkType?: Types.VectorGraphicsLabelLinkType | null
                                                                      link?: string | null
                                                                      ref?:
                                                                        | {
                                                                            __typename: "GenericRef"
                                                                            id: string
                                                                            owner?:
                                                                              | { __typename: "CommentNode" }
                                                                              | { __typename: "ExternalSite" }
                                                                              | { __typename: "ST4Class" }
                                                                              | { __typename: "ST4Node"; id: string }
                                                                              | { __typename: "ST4TreeNode" }
                                                                              | null
                                                                            target?:
                                                                              | { __typename: "CommentNode" }
                                                                              | {
                                                                                  __typename: "ExternalSite"
                                                                                  id: string
                                                                                  teaserData?: {
                                                                                    __typename: "ExternalLinkTeaserData"
                                                                                    title?: string | null
                                                                                    summary?: string | null
                                                                                    iconUrl?: string | null
                                                                                    imageUrl?: string | null
                                                                                    status?: Types.LinkTargetStatus | null
                                                                                  } | null
                                                                                }
                                                                              | { __typename: "ST4Class" }
                                                                              | {
                                                                                  __typename: "ST4Node"
                                                                                  id: string
                                                                                  aspectId: any
                                                                                  label?: string | null
                                                                                  properties?: {
                                                                                    __typename: "ContentPropertyConnection"
                                                                                    edges?: Array<{
                                                                                      __typename: "ContentPropertyEdge"
                                                                                      node?: {
                                                                                        __typename: "ContentProperty"
                                                                                        name: string
                                                                                        value?: any | null
                                                                                        displayName: string
                                                                                      } | null
                                                                                    } | null> | null
                                                                                  } | null
                                                                                }
                                                                              | { __typename: "ST4TreeNode" }
                                                                              | null
                                                                          }
                                                                        | {
                                                                            __typename: "NodeRef"
                                                                            id: string
                                                                            owner?:
                                                                              | { __typename: "CommentNode" }
                                                                              | { __typename: "ExternalSite" }
                                                                              | { __typename: "ST4Class" }
                                                                              | { __typename: "ST4Node"; id: string }
                                                                              | { __typename: "ST4TreeNode" }
                                                                              | null
                                                                            target?:
                                                                              | { __typename: "CommentNode" }
                                                                              | {
                                                                                  __typename: "ExternalSite"
                                                                                  id: string
                                                                                  teaserData?: {
                                                                                    __typename: "ExternalLinkTeaserData"
                                                                                    title?: string | null
                                                                                    summary?: string | null
                                                                                    iconUrl?: string | null
                                                                                    imageUrl?: string | null
                                                                                    status?: Types.LinkTargetStatus | null
                                                                                  } | null
                                                                                }
                                                                              | { __typename: "ST4Class" }
                                                                              | {
                                                                                  __typename: "ST4Node"
                                                                                  id: string
                                                                                  aspectId: any
                                                                                  label?: string | null
                                                                                  properties?: {
                                                                                    __typename: "ContentPropertyConnection"
                                                                                    edges?: Array<{
                                                                                      __typename: "ContentPropertyEdge"
                                                                                      node?: {
                                                                                        __typename: "ContentProperty"
                                                                                        name: string
                                                                                        value?: any | null
                                                                                        displayName: string
                                                                                      } | null
                                                                                    } | null> | null
                                                                                  } | null
                                                                                }
                                                                              | { __typename: "ST4TreeNode" }
                                                                              | null
                                                                          }
                                                                        | null
                                                                    }>
                                                                  }
                                                                | {
                                                                    __typename: "VideoMediaInfo"
                                                                    id: string
                                                                    resourceId?: string | null
                                                                    thumbnail?: string | null
                                                                    width?: number | null
                                                                    height?: number | null
                                                                    mediaType?: Types.MediaType | null
                                                                  }
                                                                | null
                                                            }
                                                          | {
                                                              __typename: "MediaGroupContent"
                                                              media?:
                                                                | {
                                                                    __typename: "AnimationMediaInfo"
                                                                    id: string
                                                                    resourceId?: string | null
                                                                    thumbnail?: string | null
                                                                    width?: number | null
                                                                    height?: number | null
                                                                    mediaType?: Types.MediaType | null
                                                                  }
                                                                | {
                                                                    __typename: "ImageMediaInfo"
                                                                    id: string
                                                                    resourceId?: string | null
                                                                    thumbnail?: string | null
                                                                    width?: number | null
                                                                    height?: number | null
                                                                    resolution?: number | null
                                                                    colorDepth?: number | null
                                                                    size?: number | null
                                                                    mime?: string | null
                                                                    extension?: string | null
                                                                    allowCaching?: boolean | null
                                                                    mediaType?: Types.MediaType | null
                                                                    src?: string | null
                                                                    language?: string | null
                                                                  }
                                                                | {
                                                                    __typename: "NoMediaInfo"
                                                                    id: string
                                                                    mediaType?: Types.MediaType | null
                                                                  }
                                                                | {
                                                                    __typename: "VectorGraphicsMediaInfo"
                                                                    id: string
                                                                    resourceId?: string | null
                                                                    thumbnail?: string | null
                                                                    width?: number | null
                                                                    height?: number | null
                                                                    allowCaching?: boolean | null
                                                                    mediaType?: Types.MediaType | null
                                                                    language?: string | null
                                                                    labels: Array<{
                                                                      __typename: "VectorGraphicsLabel"
                                                                      id?: string | null
                                                                      sequence?: number | null
                                                                      autoValue?: string | null
                                                                      text?: string | null
                                                                      linkType?: Types.VectorGraphicsLabelLinkType | null
                                                                      link?: string | null
                                                                      ref?:
                                                                        | {
                                                                            __typename: "GenericRef"
                                                                            id: string
                                                                            owner?:
                                                                              | { __typename: "CommentNode" }
                                                                              | { __typename: "ExternalSite" }
                                                                              | { __typename: "ST4Class" }
                                                                              | { __typename: "ST4Node"; id: string }
                                                                              | { __typename: "ST4TreeNode" }
                                                                              | null
                                                                            target?:
                                                                              | { __typename: "CommentNode" }
                                                                              | {
                                                                                  __typename: "ExternalSite"
                                                                                  id: string
                                                                                  teaserData?: {
                                                                                    __typename: "ExternalLinkTeaserData"
                                                                                    title?: string | null
                                                                                    summary?: string | null
                                                                                    iconUrl?: string | null
                                                                                    imageUrl?: string | null
                                                                                    status?: Types.LinkTargetStatus | null
                                                                                  } | null
                                                                                }
                                                                              | { __typename: "ST4Class" }
                                                                              | {
                                                                                  __typename: "ST4Node"
                                                                                  id: string
                                                                                  aspectId: any
                                                                                  label?: string | null
                                                                                  properties?: {
                                                                                    __typename: "ContentPropertyConnection"
                                                                                    edges?: Array<{
                                                                                      __typename: "ContentPropertyEdge"
                                                                                      node?: {
                                                                                        __typename: "ContentProperty"
                                                                                        name: string
                                                                                        value?: any | null
                                                                                        displayName: string
                                                                                      } | null
                                                                                    } | null> | null
                                                                                  } | null
                                                                                }
                                                                              | { __typename: "ST4TreeNode" }
                                                                              | null
                                                                          }
                                                                        | {
                                                                            __typename: "NodeRef"
                                                                            id: string
                                                                            owner?:
                                                                              | { __typename: "CommentNode" }
                                                                              | { __typename: "ExternalSite" }
                                                                              | { __typename: "ST4Class" }
                                                                              | { __typename: "ST4Node"; id: string }
                                                                              | { __typename: "ST4TreeNode" }
                                                                              | null
                                                                            target?:
                                                                              | { __typename: "CommentNode" }
                                                                              | {
                                                                                  __typename: "ExternalSite"
                                                                                  id: string
                                                                                  teaserData?: {
                                                                                    __typename: "ExternalLinkTeaserData"
                                                                                    title?: string | null
                                                                                    summary?: string | null
                                                                                    iconUrl?: string | null
                                                                                    imageUrl?: string | null
                                                                                    status?: Types.LinkTargetStatus | null
                                                                                  } | null
                                                                                }
                                                                              | { __typename: "ST4Class" }
                                                                              | {
                                                                                  __typename: "ST4Node"
                                                                                  id: string
                                                                                  aspectId: any
                                                                                  label?: string | null
                                                                                  properties?: {
                                                                                    __typename: "ContentPropertyConnection"
                                                                                    edges?: Array<{
                                                                                      __typename: "ContentPropertyEdge"
                                                                                      node?: {
                                                                                        __typename: "ContentProperty"
                                                                                        name: string
                                                                                        value?: any | null
                                                                                        displayName: string
                                                                                      } | null
                                                                                    } | null> | null
                                                                                  } | null
                                                                                }
                                                                              | { __typename: "ST4TreeNode" }
                                                                              | null
                                                                          }
                                                                        | null
                                                                    }>
                                                                  }
                                                                | {
                                                                    __typename: "VideoMediaInfo"
                                                                    id: string
                                                                    resourceId?: string | null
                                                                    thumbnail?: string | null
                                                                    width?: number | null
                                                                    height?: number | null
                                                                    mediaType?: Types.MediaType | null
                                                                  }
                                                                | null
                                                            }
                                                          | { __typename: "ProjectContent" }
                                                          | { __typename: "TermContent" }
                                                          | { __typename: "TextContent" }
                                                          | { __typename: "TextGroupContent" }
                                                          | { __typename: "VariableTableContent" }
                                                          | null
                                                      }
                                                    | { __typename: "ST4TreeNode"; id: string }
                                                    | null
                                                }>
                                                media?:
                                                  | {
                                                      __typename: "AnimationMediaInfo"
                                                      id: string
                                                      resourceId?: string | null
                                                      thumbnail?: string | null
                                                      width?: number | null
                                                      height?: number | null
                                                      mediaType?: Types.MediaType | null
                                                    }
                                                  | {
                                                      __typename: "ImageMediaInfo"
                                                      id: string
                                                      resourceId?: string | null
                                                      thumbnail?: string | null
                                                      width?: number | null
                                                      height?: number | null
                                                      resolution?: number | null
                                                      colorDepth?: number | null
                                                      size?: number | null
                                                      mime?: string | null
                                                      extension?: string | null
                                                      allowCaching?: boolean | null
                                                      mediaType?: Types.MediaType | null
                                                      src?: string | null
                                                      language?: string | null
                                                    }
                                                  | {
                                                      __typename: "NoMediaInfo"
                                                      id: string
                                                      mediaType?: Types.MediaType | null
                                                    }
                                                  | {
                                                      __typename: "VectorGraphicsMediaInfo"
                                                      id: string
                                                      resourceId?: string | null
                                                      thumbnail?: string | null
                                                      width?: number | null
                                                      height?: number | null
                                                      allowCaching?: boolean | null
                                                      mediaType?: Types.MediaType | null
                                                      language?: string | null
                                                      labels: Array<{
                                                        __typename: "VectorGraphicsLabel"
                                                        id?: string | null
                                                        sequence?: number | null
                                                        autoValue?: string | null
                                                        text?: string | null
                                                        linkType?: Types.VectorGraphicsLabelLinkType | null
                                                        link?: string | null
                                                        ref?:
                                                          | {
                                                              __typename: "GenericRef"
                                                              id: string
                                                              owner?:
                                                                | { __typename: "CommentNode" }
                                                                | { __typename: "ExternalSite" }
                                                                | { __typename: "ST4Class" }
                                                                | { __typename: "ST4Node"; id: string }
                                                                | { __typename: "ST4TreeNode" }
                                                                | null
                                                              target?:
                                                                | { __typename: "CommentNode" }
                                                                | {
                                                                    __typename: "ExternalSite"
                                                                    id: string
                                                                    teaserData?: {
                                                                      __typename: "ExternalLinkTeaserData"
                                                                      title?: string | null
                                                                      summary?: string | null
                                                                      iconUrl?: string | null
                                                                      imageUrl?: string | null
                                                                      status?: Types.LinkTargetStatus | null
                                                                    } | null
                                                                  }
                                                                | { __typename: "ST4Class" }
                                                                | {
                                                                    __typename: "ST4Node"
                                                                    id: string
                                                                    aspectId: any
                                                                    label?: string | null
                                                                    properties?: {
                                                                      __typename: "ContentPropertyConnection"
                                                                      edges?: Array<{
                                                                        __typename: "ContentPropertyEdge"
                                                                        node?: {
                                                                          __typename: "ContentProperty"
                                                                          name: string
                                                                          value?: any | null
                                                                          displayName: string
                                                                        } | null
                                                                      } | null> | null
                                                                    } | null
                                                                  }
                                                                | { __typename: "ST4TreeNode" }
                                                                | null
                                                            }
                                                          | {
                                                              __typename: "NodeRef"
                                                              id: string
                                                              owner?:
                                                                | { __typename: "CommentNode" }
                                                                | { __typename: "ExternalSite" }
                                                                | { __typename: "ST4Class" }
                                                                | { __typename: "ST4Node"; id: string }
                                                                | { __typename: "ST4TreeNode" }
                                                                | null
                                                              target?:
                                                                | { __typename: "CommentNode" }
                                                                | {
                                                                    __typename: "ExternalSite"
                                                                    id: string
                                                                    teaserData?: {
                                                                      __typename: "ExternalLinkTeaserData"
                                                                      title?: string | null
                                                                      summary?: string | null
                                                                      iconUrl?: string | null
                                                                      imageUrl?: string | null
                                                                      status?: Types.LinkTargetStatus | null
                                                                    } | null
                                                                  }
                                                                | { __typename: "ST4Class" }
                                                                | {
                                                                    __typename: "ST4Node"
                                                                    id: string
                                                                    aspectId: any
                                                                    label?: string | null
                                                                    properties?: {
                                                                      __typename: "ContentPropertyConnection"
                                                                      edges?: Array<{
                                                                        __typename: "ContentPropertyEdge"
                                                                        node?: {
                                                                          __typename: "ContentProperty"
                                                                          name: string
                                                                          value?: any | null
                                                                          displayName: string
                                                                        } | null
                                                                      } | null> | null
                                                                    } | null
                                                                  }
                                                                | { __typename: "ST4TreeNode" }
                                                                | null
                                                            }
                                                          | null
                                                      }>
                                                    }
                                                  | {
                                                      __typename: "VideoMediaInfo"
                                                      id: string
                                                      resourceId?: string | null
                                                      thumbnail?: string | null
                                                      width?: number | null
                                                      height?: number | null
                                                      mediaType?: Types.MediaType | null
                                                    }
                                                  | null
                                              }
                                            | { __typename: "ProjectContent" }
                                            | { __typename: "TermContent" }
                                            | { __typename: "TextContent" }
                                            | { __typename: "TextGroupContent" }
                                            | { __typename: "VariableTableContent" }
                                            | null
                                        }
                                      | { __typename: "ST4TreeNode"; id: string }
                                      | null
                                  }>
                                  variableTables?: Array<{
                                    __typename: "VariableTableReference"
                                    ref?: string | null
                                    node?:
                                      | { __typename: "CommentNode" }
                                      | { __typename: "ExternalSite" }
                                      | { __typename: "ST4Class" }
                                      | {
                                          __typename: "ST4Node"
                                          id: string
                                          label?: string | null
                                          aspectId: any
                                          content?:
                                            | { __typename: "LexiconContent" }
                                            | { __typename: "LexiconEntryContent" }
                                            | { __typename: "MediaContent" }
                                            | { __typename: "MediaGroupContent" }
                                            | { __typename: "ProjectContent" }
                                            | { __typename: "TermContent" }
                                            | { __typename: "TextContent" }
                                            | { __typename: "TextGroupContent" }
                                            | {
                                                __typename: "VariableTableContent"
                                                xml?: string | null
                                                valueSets: Array<{
                                                  __typename: "ValueSet"
                                                  name: string
                                                  valueSetId: string
                                                }>
                                                variables: Array<{
                                                  __typename: "Variable"
                                                  name: string
                                                  variableId: string
                                                  values: Array<string>
                                                }>
                                              }
                                            | null
                                        }
                                      | { __typename: "ST4TreeNode" }
                                      | null
                                  } | null> | null
                                  linkedDataNodes: Array<{
                                    __typename: "NodeRef"
                                    id: string
                                    target?:
                                      | { __typename: "CommentNode"; id: string }
                                      | { __typename: "ExternalSite"; id: string }
                                      | { __typename: "ST4Class"; id: string }
                                      | {
                                          __typename: "ST4Node"
                                          label?: string | null
                                          id: string
                                          metadata: Array<{
                                            __typename: "MetaDataEntry"
                                            label?: string | null
                                            systemName: string
                                            value?: string | null
                                            values: Array<string>
                                            users: Array<string>
                                          }>
                                          linkedOntologyNodes: Array<
                                            | {
                                                __typename: "GenericRef"
                                                target?:
                                                  | { __typename: "CommentNode" }
                                                  | { __typename: "ExternalSite" }
                                                  | { __typename: "ST4Class" }
                                                  | { __typename: "ST4Node"; id: string; label?: string | null }
                                                  | { __typename: "ST4TreeNode" }
                                                  | null
                                              }
                                            | {
                                                __typename: "NodeRef"
                                                linkClassName: string
                                                class?: { __typename: "ST4Class"; label?: string | null } | null
                                                target?:
                                                  | { __typename: "CommentNode" }
                                                  | { __typename: "ExternalSite" }
                                                  | { __typename: "ST4Class" }
                                                  | { __typename: "ST4Node"; id: string; label?: string | null }
                                                  | { __typename: "ST4TreeNode" }
                                                  | null
                                              }
                                          >
                                        }
                                      | { __typename: "ST4TreeNode"; id: string }
                                      | null
                                  }>
                                  lexiconEntries?: Array<{
                                    __typename: "NodeRef"
                                    id: string
                                    target?:
                                      | { __typename: "CommentNode"; id: string }
                                      | { __typename: "ExternalSite"; id: string }
                                      | { __typename: "ST4Class"; id: string }
                                      | {
                                          __typename: "ST4Node"
                                          aspectId: any
                                          label?: string | null
                                          id: string
                                          content?:
                                            | { __typename: "LexiconContent" }
                                            | {
                                                __typename: "LexiconEntryContent"
                                                descriptionXast?: string | null
                                                subject?: string | null
                                                sortKey?: string | null
                                                lexicon:
                                                  | { __typename: "CommentNode"; id: string }
                                                  | { __typename: "ExternalSite"; id: string }
                                                  | { __typename: "ST4Class"; id: string }
                                                  | { __typename: "ST4Node"; label?: string | null; id: string }
                                                  | { __typename: "ST4TreeNode"; id: string }
                                                terms: Array<
                                                  | { __typename: "CommentNode"; id: string }
                                                  | { __typename: "ExternalSite"; id: string }
                                                  | { __typename: "ST4Class"; id: string }
                                                  | {
                                                      __typename: "ST4Node"
                                                      id: string
                                                      label?: string | null
                                                      aspectId: any
                                                      content?:
                                                        | { __typename: "LexiconContent" }
                                                        | { __typename: "LexiconEntryContent" }
                                                        | { __typename: "MediaContent" }
                                                        | { __typename: "MediaGroupContent" }
                                                        | { __typename: "ProjectContent" }
                                                        | {
                                                            __typename: "TermContent"
                                                            gramaticalGender?: string | null
                                                            validity?: string | null
                                                            editLanguage?: string | null
                                                          }
                                                        | { __typename: "TextContent" }
                                                        | { __typename: "TextGroupContent" }
                                                        | { __typename: "VariableTableContent" }
                                                        | null
                                                    }
                                                  | { __typename: "ST4TreeNode"; id: string }
                                                >
                                                image?:
                                                  | { __typename: "CommentNode"; id: string }
                                                  | { __typename: "ExternalSite"; id: string }
                                                  | { __typename: "ST4Class"; id: string }
                                                  | {
                                                      __typename: "ST4Node"
                                                      aspectId: any
                                                      id: string
                                                      content?:
                                                        | { __typename: "LexiconContent" }
                                                        | { __typename: "LexiconEntryContent" }
                                                        | {
                                                            __typename: "MediaContent"
                                                            media?:
                                                              | {
                                                                  __typename: "AnimationMediaInfo"
                                                                  thumbnail?: string | null
                                                                  mediaType?: Types.MediaType | null
                                                                }
                                                              | {
                                                                  __typename: "ImageMediaInfo"
                                                                  src?: string | null
                                                                  thumbnail?: string | null
                                                                  mediaType?: Types.MediaType | null
                                                                }
                                                              | { __typename: "NoMediaInfo" }
                                                              | {
                                                                  __typename: "VectorGraphicsMediaInfo"
                                                                  svg?: string | null
                                                                  thumbnail?: string | null
                                                                  mediaType?: Types.MediaType | null
                                                                }
                                                              | {
                                                                  __typename: "VideoMediaInfo"
                                                                  thumbnail?: string | null
                                                                  mediaType?: Types.MediaType | null
                                                                }
                                                              | null
                                                          }
                                                        | { __typename: "MediaGroupContent" }
                                                        | { __typename: "ProjectContent" }
                                                        | { __typename: "TermContent" }
                                                        | { __typename: "TextContent" }
                                                        | { __typename: "TextGroupContent" }
                                                        | { __typename: "VariableTableContent" }
                                                        | null
                                                    }
                                                  | { __typename: "ST4TreeNode"; id: string }
                                                  | null
                                              }
                                            | { __typename: "MediaContent" }
                                            | { __typename: "MediaGroupContent" }
                                            | { __typename: "ProjectContent" }
                                            | { __typename: "TermContent" }
                                            | { __typename: "TextContent" }
                                            | { __typename: "TextGroupContent" }
                                            | { __typename: "VariableTableContent" }
                                            | null
                                        }
                                      | { __typename: "ST4TreeNode"; id: string }
                                      | null
                                  } | null> | null
                                  comments: Array<{
                                    __typename: "CommentNode"
                                    id: string
                                    comment:
                                      | {
                                          __typename: "BlockComment"
                                          location: number
                                          value?: string | null
                                          authorDisplayName?: string | null
                                          authorUserName?: string | null
                                          commentKey: string
                                          editDate?: any | null
                                          modificationVersion?: number | null
                                          replyToKey?: string | null
                                          sortOrder?: number | null
                                          commentType?: string | null
                                          state?: string | null
                                          color: string
                                          persistanceState?: Types.PersistanceState | null
                                          persistanceAction?: Types.PersistanceAction | null
                                          node: {
                                            __typename: "ST4Node"
                                            aspectId: any
                                            id: string
                                            content?:
                                              | { __typename: "LexiconContent" }
                                              | { __typename: "LexiconEntryContent" }
                                              | { __typename: "MediaContent" }
                                              | { __typename: "MediaGroupContent" }
                                              | { __typename: "ProjectContent" }
                                              | { __typename: "TermContent" }
                                              | { __typename: "TextContent"; id: string; historyNumber: number }
                                              | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                                              | { __typename: "VariableTableContent" }
                                              | null
                                          }
                                          actions: Array<{
                                            __typename: "Action"
                                            name?: string | null
                                            enabled?: boolean | null
                                          }>
                                        }
                                      | {
                                          __typename: "TextComment"
                                          endLocation: number
                                          startLocation?: number | null
                                          value?: string | null
                                          authorDisplayName?: string | null
                                          authorUserName?: string | null
                                          commentKey: string
                                          editDate?: any | null
                                          modificationVersion?: number | null
                                          replyToKey?: string | null
                                          sortOrder?: number | null
                                          commentType?: string | null
                                          state?: string | null
                                          color: string
                                          persistanceState?: Types.PersistanceState | null
                                          persistanceAction?: Types.PersistanceAction | null
                                          node: {
                                            __typename: "ST4Node"
                                            aspectId: any
                                            id: string
                                            content?:
                                              | { __typename: "LexiconContent" }
                                              | { __typename: "LexiconEntryContent" }
                                              | { __typename: "MediaContent" }
                                              | { __typename: "MediaGroupContent" }
                                              | { __typename: "ProjectContent" }
                                              | { __typename: "TermContent" }
                                              | { __typename: "TextContent"; id: string; historyNumber: number }
                                              | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                                              | { __typename: "VariableTableContent" }
                                              | null
                                          }
                                          actions: Array<{
                                            __typename: "Action"
                                            name?: string | null
                                            enabled?: boolean | null
                                          }>
                                        }
                                  }>
                                }
                              | { __typename: "VariableTableContent" }
                              | null
                          }
                        }>
                        children: Array<{
                          __typename: "NodeRef"
                          id: string
                          target?:
                            | { __typename: "CommentNode"; id: string }
                            | { __typename: "ExternalSite"; id: string }
                            | { __typename: "ST4Class"; id: string }
                            | { __typename: "ST4Node"; label?: string | null; aspectId: any; id: string }
                            | { __typename: "ST4TreeNode"; id: string }
                            | null
                        }>
                        links?: Array<{
                          __typename: "TextContentLink"
                          ref?: string | null
                          node?:
                            | { __typename: "CommentNode"; id: string }
                            | { __typename: "ExternalSite"; id: string }
                            | { __typename: "ST4Class"; id: string }
                            | {
                                __typename: "ST4Node"
                                id: string
                                aspectId: any
                                label?: string | null
                                properties?: {
                                  __typename: "ContentPropertyConnection"
                                  totalCount: number
                                  edges?: Array<{
                                    __typename: "ContentPropertyEdge"
                                    node?: {
                                      __typename: "ContentProperty"
                                      typeOfValue: Types.ContentPropertyValueType
                                      name: string
                                      value?: any | null
                                      displayName: string
                                    } | null
                                  } | null> | null
                                } | null
                              }
                            | { __typename: "ST4TreeNode"; id: string }
                            | null
                        } | null> | null
                        images: Array<{
                          __typename: "NodeRef"
                          id: string
                          target?:
                            | { __typename: "CommentNode"; id: string }
                            | { __typename: "ExternalSite"; id: string }
                            | { __typename: "ST4Class"; id: string }
                            | {
                                __typename: "ST4Node"
                                aspectId: any
                                id: string
                                releaseState: {
                                  __typename: "ReleaseStateValue"
                                  displayName: string
                                  state: Types.ReleaseState
                                  isImplicitlyReleased: boolean
                                }
                                content?:
                                  | { __typename: "LexiconContent" }
                                  | { __typename: "LexiconEntryContent" }
                                  | {
                                      __typename: "MediaContent"
                                      media?:
                                        | {
                                            __typename: "AnimationMediaInfo"
                                            id: string
                                            resourceId?: string | null
                                            thumbnail?: string | null
                                            width?: number | null
                                            height?: number | null
                                            mediaType?: Types.MediaType | null
                                          }
                                        | {
                                            __typename: "ImageMediaInfo"
                                            id: string
                                            resourceId?: string | null
                                            thumbnail?: string | null
                                            width?: number | null
                                            height?: number | null
                                            resolution?: number | null
                                            colorDepth?: number | null
                                            size?: number | null
                                            mime?: string | null
                                            extension?: string | null
                                            allowCaching?: boolean | null
                                            mediaType?: Types.MediaType | null
                                            src?: string | null
                                            language?: string | null
                                          }
                                        | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                                        | {
                                            __typename: "VectorGraphicsMediaInfo"
                                            id: string
                                            resourceId?: string | null
                                            thumbnail?: string | null
                                            width?: number | null
                                            height?: number | null
                                            allowCaching?: boolean | null
                                            mediaType?: Types.MediaType | null
                                            language?: string | null
                                            labels: Array<{
                                              __typename: "VectorGraphicsLabel"
                                              id?: string | null
                                              sequence?: number | null
                                              autoValue?: string | null
                                              text?: string | null
                                              linkType?: Types.VectorGraphicsLabelLinkType | null
                                              link?: string | null
                                              ref?:
                                                | {
                                                    __typename: "GenericRef"
                                                    id: string
                                                    owner?:
                                                      | { __typename: "CommentNode" }
                                                      | { __typename: "ExternalSite" }
                                                      | { __typename: "ST4Class" }
                                                      | { __typename: "ST4Node"; id: string }
                                                      | { __typename: "ST4TreeNode" }
                                                      | null
                                                    target?:
                                                      | { __typename: "CommentNode" }
                                                      | {
                                                          __typename: "ExternalSite"
                                                          id: string
                                                          teaserData?: {
                                                            __typename: "ExternalLinkTeaserData"
                                                            title?: string | null
                                                            summary?: string | null
                                                            iconUrl?: string | null
                                                            imageUrl?: string | null
                                                            status?: Types.LinkTargetStatus | null
                                                          } | null
                                                        }
                                                      | { __typename: "ST4Class" }
                                                      | {
                                                          __typename: "ST4Node"
                                                          id: string
                                                          aspectId: any
                                                          label?: string | null
                                                          properties?: {
                                                            __typename: "ContentPropertyConnection"
                                                            edges?: Array<{
                                                              __typename: "ContentPropertyEdge"
                                                              node?: {
                                                                __typename: "ContentProperty"
                                                                name: string
                                                                value?: any | null
                                                                displayName: string
                                                              } | null
                                                            } | null> | null
                                                          } | null
                                                        }
                                                      | { __typename: "ST4TreeNode" }
                                                      | null
                                                  }
                                                | {
                                                    __typename: "NodeRef"
                                                    id: string
                                                    owner?:
                                                      | { __typename: "CommentNode" }
                                                      | { __typename: "ExternalSite" }
                                                      | { __typename: "ST4Class" }
                                                      | { __typename: "ST4Node"; id: string }
                                                      | { __typename: "ST4TreeNode" }
                                                      | null
                                                    target?:
                                                      | { __typename: "CommentNode" }
                                                      | {
                                                          __typename: "ExternalSite"
                                                          id: string
                                                          teaserData?: {
                                                            __typename: "ExternalLinkTeaserData"
                                                            title?: string | null
                                                            summary?: string | null
                                                            iconUrl?: string | null
                                                            imageUrl?: string | null
                                                            status?: Types.LinkTargetStatus | null
                                                          } | null
                                                        }
                                                      | { __typename: "ST4Class" }
                                                      | {
                                                          __typename: "ST4Node"
                                                          id: string
                                                          aspectId: any
                                                          label?: string | null
                                                          properties?: {
                                                            __typename: "ContentPropertyConnection"
                                                            edges?: Array<{
                                                              __typename: "ContentPropertyEdge"
                                                              node?: {
                                                                __typename: "ContentProperty"
                                                                name: string
                                                                value?: any | null
                                                                displayName: string
                                                              } | null
                                                            } | null> | null
                                                          } | null
                                                        }
                                                      | { __typename: "ST4TreeNode" }
                                                      | null
                                                  }
                                                | null
                                            }>
                                          }
                                        | {
                                            __typename: "VideoMediaInfo"
                                            id: string
                                            resourceId?: string | null
                                            thumbnail?: string | null
                                            width?: number | null
                                            height?: number | null
                                            mediaType?: Types.MediaType | null
                                          }
                                        | null
                                    }
                                  | {
                                      __typename: "MediaGroupContent"
                                      children: Array<{
                                        __typename: "NodeRef"
                                        id: string
                                        target?:
                                          | { __typename: "CommentNode"; id: string }
                                          | { __typename: "ExternalSite"; id: string }
                                          | { __typename: "ST4Class"; id: string }
                                          | {
                                              __typename: "ST4Node"
                                              id: string
                                              aspectId: any
                                              content?:
                                                | { __typename: "LexiconContent" }
                                                | { __typename: "LexiconEntryContent" }
                                                | {
                                                    __typename: "MediaContent"
                                                    media?:
                                                      | {
                                                          __typename: "AnimationMediaInfo"
                                                          id: string
                                                          resourceId?: string | null
                                                          thumbnail?: string | null
                                                          width?: number | null
                                                          height?: number | null
                                                          mediaType?: Types.MediaType | null
                                                        }
                                                      | {
                                                          __typename: "ImageMediaInfo"
                                                          id: string
                                                          resourceId?: string | null
                                                          thumbnail?: string | null
                                                          width?: number | null
                                                          height?: number | null
                                                          resolution?: number | null
                                                          colorDepth?: number | null
                                                          size?: number | null
                                                          mime?: string | null
                                                          extension?: string | null
                                                          allowCaching?: boolean | null
                                                          mediaType?: Types.MediaType | null
                                                          src?: string | null
                                                          language?: string | null
                                                        }
                                                      | {
                                                          __typename: "NoMediaInfo"
                                                          id: string
                                                          mediaType?: Types.MediaType | null
                                                        }
                                                      | {
                                                          __typename: "VectorGraphicsMediaInfo"
                                                          id: string
                                                          resourceId?: string | null
                                                          thumbnail?: string | null
                                                          width?: number | null
                                                          height?: number | null
                                                          allowCaching?: boolean | null
                                                          mediaType?: Types.MediaType | null
                                                          language?: string | null
                                                          labels: Array<{
                                                            __typename: "VectorGraphicsLabel"
                                                            id?: string | null
                                                            sequence?: number | null
                                                            autoValue?: string | null
                                                            text?: string | null
                                                            linkType?: Types.VectorGraphicsLabelLinkType | null
                                                            link?: string | null
                                                            ref?:
                                                              | {
                                                                  __typename: "GenericRef"
                                                                  id: string
                                                                  owner?:
                                                                    | { __typename: "CommentNode" }
                                                                    | { __typename: "ExternalSite" }
                                                                    | { __typename: "ST4Class" }
                                                                    | { __typename: "ST4Node"; id: string }
                                                                    | { __typename: "ST4TreeNode" }
                                                                    | null
                                                                  target?:
                                                                    | { __typename: "CommentNode" }
                                                                    | {
                                                                        __typename: "ExternalSite"
                                                                        id: string
                                                                        teaserData?: {
                                                                          __typename: "ExternalLinkTeaserData"
                                                                          title?: string | null
                                                                          summary?: string | null
                                                                          iconUrl?: string | null
                                                                          imageUrl?: string | null
                                                                          status?: Types.LinkTargetStatus | null
                                                                        } | null
                                                                      }
                                                                    | { __typename: "ST4Class" }
                                                                    | {
                                                                        __typename: "ST4Node"
                                                                        id: string
                                                                        aspectId: any
                                                                        label?: string | null
                                                                        properties?: {
                                                                          __typename: "ContentPropertyConnection"
                                                                          edges?: Array<{
                                                                            __typename: "ContentPropertyEdge"
                                                                            node?: {
                                                                              __typename: "ContentProperty"
                                                                              name: string
                                                                              value?: any | null
                                                                              displayName: string
                                                                            } | null
                                                                          } | null> | null
                                                                        } | null
                                                                      }
                                                                    | { __typename: "ST4TreeNode" }
                                                                    | null
                                                                }
                                                              | {
                                                                  __typename: "NodeRef"
                                                                  id: string
                                                                  owner?:
                                                                    | { __typename: "CommentNode" }
                                                                    | { __typename: "ExternalSite" }
                                                                    | { __typename: "ST4Class" }
                                                                    | { __typename: "ST4Node"; id: string }
                                                                    | { __typename: "ST4TreeNode" }
                                                                    | null
                                                                  target?:
                                                                    | { __typename: "CommentNode" }
                                                                    | {
                                                                        __typename: "ExternalSite"
                                                                        id: string
                                                                        teaserData?: {
                                                                          __typename: "ExternalLinkTeaserData"
                                                                          title?: string | null
                                                                          summary?: string | null
                                                                          iconUrl?: string | null
                                                                          imageUrl?: string | null
                                                                          status?: Types.LinkTargetStatus | null
                                                                        } | null
                                                                      }
                                                                    | { __typename: "ST4Class" }
                                                                    | {
                                                                        __typename: "ST4Node"
                                                                        id: string
                                                                        aspectId: any
                                                                        label?: string | null
                                                                        properties?: {
                                                                          __typename: "ContentPropertyConnection"
                                                                          edges?: Array<{
                                                                            __typename: "ContentPropertyEdge"
                                                                            node?: {
                                                                              __typename: "ContentProperty"
                                                                              name: string
                                                                              value?: any | null
                                                                              displayName: string
                                                                            } | null
                                                                          } | null> | null
                                                                        } | null
                                                                      }
                                                                    | { __typename: "ST4TreeNode" }
                                                                    | null
                                                                }
                                                              | null
                                                          }>
                                                        }
                                                      | {
                                                          __typename: "VideoMediaInfo"
                                                          id: string
                                                          resourceId?: string | null
                                                          thumbnail?: string | null
                                                          width?: number | null
                                                          height?: number | null
                                                          mediaType?: Types.MediaType | null
                                                        }
                                                      | null
                                                  }
                                                | {
                                                    __typename: "MediaGroupContent"
                                                    media?:
                                                      | {
                                                          __typename: "AnimationMediaInfo"
                                                          id: string
                                                          resourceId?: string | null
                                                          thumbnail?: string | null
                                                          width?: number | null
                                                          height?: number | null
                                                          mediaType?: Types.MediaType | null
                                                        }
                                                      | {
                                                          __typename: "ImageMediaInfo"
                                                          id: string
                                                          resourceId?: string | null
                                                          thumbnail?: string | null
                                                          width?: number | null
                                                          height?: number | null
                                                          resolution?: number | null
                                                          colorDepth?: number | null
                                                          size?: number | null
                                                          mime?: string | null
                                                          extension?: string | null
                                                          allowCaching?: boolean | null
                                                          mediaType?: Types.MediaType | null
                                                          src?: string | null
                                                          language?: string | null
                                                        }
                                                      | {
                                                          __typename: "NoMediaInfo"
                                                          id: string
                                                          mediaType?: Types.MediaType | null
                                                        }
                                                      | {
                                                          __typename: "VectorGraphicsMediaInfo"
                                                          id: string
                                                          resourceId?: string | null
                                                          thumbnail?: string | null
                                                          width?: number | null
                                                          height?: number | null
                                                          allowCaching?: boolean | null
                                                          mediaType?: Types.MediaType | null
                                                          language?: string | null
                                                          labels: Array<{
                                                            __typename: "VectorGraphicsLabel"
                                                            id?: string | null
                                                            sequence?: number | null
                                                            autoValue?: string | null
                                                            text?: string | null
                                                            linkType?: Types.VectorGraphicsLabelLinkType | null
                                                            link?: string | null
                                                            ref?:
                                                              | {
                                                                  __typename: "GenericRef"
                                                                  id: string
                                                                  owner?:
                                                                    | { __typename: "CommentNode" }
                                                                    | { __typename: "ExternalSite" }
                                                                    | { __typename: "ST4Class" }
                                                                    | { __typename: "ST4Node"; id: string }
                                                                    | { __typename: "ST4TreeNode" }
                                                                    | null
                                                                  target?:
                                                                    | { __typename: "CommentNode" }
                                                                    | {
                                                                        __typename: "ExternalSite"
                                                                        id: string
                                                                        teaserData?: {
                                                                          __typename: "ExternalLinkTeaserData"
                                                                          title?: string | null
                                                                          summary?: string | null
                                                                          iconUrl?: string | null
                                                                          imageUrl?: string | null
                                                                          status?: Types.LinkTargetStatus | null
                                                                        } | null
                                                                      }
                                                                    | { __typename: "ST4Class" }
                                                                    | {
                                                                        __typename: "ST4Node"
                                                                        id: string
                                                                        aspectId: any
                                                                        label?: string | null
                                                                        properties?: {
                                                                          __typename: "ContentPropertyConnection"
                                                                          edges?: Array<{
                                                                            __typename: "ContentPropertyEdge"
                                                                            node?: {
                                                                              __typename: "ContentProperty"
                                                                              name: string
                                                                              value?: any | null
                                                                              displayName: string
                                                                            } | null
                                                                          } | null> | null
                                                                        } | null
                                                                      }
                                                                    | { __typename: "ST4TreeNode" }
                                                                    | null
                                                                }
                                                              | {
                                                                  __typename: "NodeRef"
                                                                  id: string
                                                                  owner?:
                                                                    | { __typename: "CommentNode" }
                                                                    | { __typename: "ExternalSite" }
                                                                    | { __typename: "ST4Class" }
                                                                    | { __typename: "ST4Node"; id: string }
                                                                    | { __typename: "ST4TreeNode" }
                                                                    | null
                                                                  target?:
                                                                    | { __typename: "CommentNode" }
                                                                    | {
                                                                        __typename: "ExternalSite"
                                                                        id: string
                                                                        teaserData?: {
                                                                          __typename: "ExternalLinkTeaserData"
                                                                          title?: string | null
                                                                          summary?: string | null
                                                                          iconUrl?: string | null
                                                                          imageUrl?: string | null
                                                                          status?: Types.LinkTargetStatus | null
                                                                        } | null
                                                                      }
                                                                    | { __typename: "ST4Class" }
                                                                    | {
                                                                        __typename: "ST4Node"
                                                                        id: string
                                                                        aspectId: any
                                                                        label?: string | null
                                                                        properties?: {
                                                                          __typename: "ContentPropertyConnection"
                                                                          edges?: Array<{
                                                                            __typename: "ContentPropertyEdge"
                                                                            node?: {
                                                                              __typename: "ContentProperty"
                                                                              name: string
                                                                              value?: any | null
                                                                              displayName: string
                                                                            } | null
                                                                          } | null> | null
                                                                        } | null
                                                                      }
                                                                    | { __typename: "ST4TreeNode" }
                                                                    | null
                                                                }
                                                              | null
                                                          }>
                                                        }
                                                      | {
                                                          __typename: "VideoMediaInfo"
                                                          id: string
                                                          resourceId?: string | null
                                                          thumbnail?: string | null
                                                          width?: number | null
                                                          height?: number | null
                                                          mediaType?: Types.MediaType | null
                                                        }
                                                      | null
                                                  }
                                                | { __typename: "ProjectContent" }
                                                | { __typename: "TermContent" }
                                                | { __typename: "TextContent" }
                                                | { __typename: "TextGroupContent" }
                                                | { __typename: "VariableTableContent" }
                                                | null
                                            }
                                          | { __typename: "ST4TreeNode"; id: string }
                                          | null
                                      }>
                                      media?:
                                        | {
                                            __typename: "AnimationMediaInfo"
                                            id: string
                                            resourceId?: string | null
                                            thumbnail?: string | null
                                            width?: number | null
                                            height?: number | null
                                            mediaType?: Types.MediaType | null
                                          }
                                        | {
                                            __typename: "ImageMediaInfo"
                                            id: string
                                            resourceId?: string | null
                                            thumbnail?: string | null
                                            width?: number | null
                                            height?: number | null
                                            resolution?: number | null
                                            colorDepth?: number | null
                                            size?: number | null
                                            mime?: string | null
                                            extension?: string | null
                                            allowCaching?: boolean | null
                                            mediaType?: Types.MediaType | null
                                            src?: string | null
                                            language?: string | null
                                          }
                                        | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                                        | {
                                            __typename: "VectorGraphicsMediaInfo"
                                            id: string
                                            resourceId?: string | null
                                            thumbnail?: string | null
                                            width?: number | null
                                            height?: number | null
                                            allowCaching?: boolean | null
                                            mediaType?: Types.MediaType | null
                                            language?: string | null
                                            labels: Array<{
                                              __typename: "VectorGraphicsLabel"
                                              id?: string | null
                                              sequence?: number | null
                                              autoValue?: string | null
                                              text?: string | null
                                              linkType?: Types.VectorGraphicsLabelLinkType | null
                                              link?: string | null
                                              ref?:
                                                | {
                                                    __typename: "GenericRef"
                                                    id: string
                                                    owner?:
                                                      | { __typename: "CommentNode" }
                                                      | { __typename: "ExternalSite" }
                                                      | { __typename: "ST4Class" }
                                                      | { __typename: "ST4Node"; id: string }
                                                      | { __typename: "ST4TreeNode" }
                                                      | null
                                                    target?:
                                                      | { __typename: "CommentNode" }
                                                      | {
                                                          __typename: "ExternalSite"
                                                          id: string
                                                          teaserData?: {
                                                            __typename: "ExternalLinkTeaserData"
                                                            title?: string | null
                                                            summary?: string | null
                                                            iconUrl?: string | null
                                                            imageUrl?: string | null
                                                            status?: Types.LinkTargetStatus | null
                                                          } | null
                                                        }
                                                      | { __typename: "ST4Class" }
                                                      | {
                                                          __typename: "ST4Node"
                                                          id: string
                                                          aspectId: any
                                                          label?: string | null
                                                          properties?: {
                                                            __typename: "ContentPropertyConnection"
                                                            edges?: Array<{
                                                              __typename: "ContentPropertyEdge"
                                                              node?: {
                                                                __typename: "ContentProperty"
                                                                name: string
                                                                value?: any | null
                                                                displayName: string
                                                              } | null
                                                            } | null> | null
                                                          } | null
                                                        }
                                                      | { __typename: "ST4TreeNode" }
                                                      | null
                                                  }
                                                | {
                                                    __typename: "NodeRef"
                                                    id: string
                                                    owner?:
                                                      | { __typename: "CommentNode" }
                                                      | { __typename: "ExternalSite" }
                                                      | { __typename: "ST4Class" }
                                                      | { __typename: "ST4Node"; id: string }
                                                      | { __typename: "ST4TreeNode" }
                                                      | null
                                                    target?:
                                                      | { __typename: "CommentNode" }
                                                      | {
                                                          __typename: "ExternalSite"
                                                          id: string
                                                          teaserData?: {
                                                            __typename: "ExternalLinkTeaserData"
                                                            title?: string | null
                                                            summary?: string | null
                                                            iconUrl?: string | null
                                                            imageUrl?: string | null
                                                            status?: Types.LinkTargetStatus | null
                                                          } | null
                                                        }
                                                      | { __typename: "ST4Class" }
                                                      | {
                                                          __typename: "ST4Node"
                                                          id: string
                                                          aspectId: any
                                                          label?: string | null
                                                          properties?: {
                                                            __typename: "ContentPropertyConnection"
                                                            edges?: Array<{
                                                              __typename: "ContentPropertyEdge"
                                                              node?: {
                                                                __typename: "ContentProperty"
                                                                name: string
                                                                value?: any | null
                                                                displayName: string
                                                              } | null
                                                            } | null> | null
                                                          } | null
                                                        }
                                                      | { __typename: "ST4TreeNode" }
                                                      | null
                                                  }
                                                | null
                                            }>
                                          }
                                        | {
                                            __typename: "VideoMediaInfo"
                                            id: string
                                            resourceId?: string | null
                                            thumbnail?: string | null
                                            width?: number | null
                                            height?: number | null
                                            mediaType?: Types.MediaType | null
                                          }
                                        | null
                                    }
                                  | { __typename: "ProjectContent" }
                                  | { __typename: "TermContent" }
                                  | { __typename: "TextContent" }
                                  | { __typename: "TextGroupContent" }
                                  | { __typename: "VariableTableContent" }
                                  | null
                              }
                            | { __typename: "ST4TreeNode"; id: string }
                            | null
                        }>
                        variableTables?: Array<{
                          __typename: "VariableTableReference"
                          ref?: string | null
                          node?:
                            | { __typename: "CommentNode" }
                            | { __typename: "ExternalSite" }
                            | { __typename: "ST4Class" }
                            | {
                                __typename: "ST4Node"
                                id: string
                                label?: string | null
                                aspectId: any
                                content?:
                                  | { __typename: "LexiconContent" }
                                  | { __typename: "LexiconEntryContent" }
                                  | { __typename: "MediaContent" }
                                  | { __typename: "MediaGroupContent" }
                                  | { __typename: "ProjectContent" }
                                  | { __typename: "TermContent" }
                                  | { __typename: "TextContent" }
                                  | { __typename: "TextGroupContent" }
                                  | {
                                      __typename: "VariableTableContent"
                                      xml?: string | null
                                      valueSets: Array<{ __typename: "ValueSet"; name: string; valueSetId: string }>
                                      variables: Array<{
                                        __typename: "Variable"
                                        name: string
                                        variableId: string
                                        values: Array<string>
                                      }>
                                    }
                                  | null
                              }
                            | { __typename: "ST4TreeNode" }
                            | null
                        } | null> | null
                        linkedDataNodes: Array<{
                          __typename: "NodeRef"
                          id: string
                          target?:
                            | { __typename: "CommentNode"; id: string }
                            | { __typename: "ExternalSite"; id: string }
                            | { __typename: "ST4Class"; id: string }
                            | {
                                __typename: "ST4Node"
                                label?: string | null
                                id: string
                                metadata: Array<{
                                  __typename: "MetaDataEntry"
                                  label?: string | null
                                  systemName: string
                                  value?: string | null
                                  values: Array<string>
                                  users: Array<string>
                                }>
                                linkedOntologyNodes: Array<
                                  | {
                                      __typename: "GenericRef"
                                      target?:
                                        | { __typename: "CommentNode" }
                                        | { __typename: "ExternalSite" }
                                        | { __typename: "ST4Class" }
                                        | { __typename: "ST4Node"; id: string; label?: string | null }
                                        | { __typename: "ST4TreeNode" }
                                        | null
                                    }
                                  | {
                                      __typename: "NodeRef"
                                      linkClassName: string
                                      class?: { __typename: "ST4Class"; label?: string | null } | null
                                      target?:
                                        | { __typename: "CommentNode" }
                                        | { __typename: "ExternalSite" }
                                        | { __typename: "ST4Class" }
                                        | { __typename: "ST4Node"; id: string; label?: string | null }
                                        | { __typename: "ST4TreeNode" }
                                        | null
                                    }
                                >
                              }
                            | { __typename: "ST4TreeNode"; id: string }
                            | null
                        }>
                        lexiconEntries?: Array<{
                          __typename: "NodeRef"
                          id: string
                          target?:
                            | { __typename: "CommentNode"; id: string }
                            | { __typename: "ExternalSite"; id: string }
                            | { __typename: "ST4Class"; id: string }
                            | {
                                __typename: "ST4Node"
                                aspectId: any
                                label?: string | null
                                id: string
                                content?:
                                  | { __typename: "LexiconContent" }
                                  | {
                                      __typename: "LexiconEntryContent"
                                      descriptionXast?: string | null
                                      subject?: string | null
                                      sortKey?: string | null
                                      lexicon:
                                        | { __typename: "CommentNode"; id: string }
                                        | { __typename: "ExternalSite"; id: string }
                                        | { __typename: "ST4Class"; id: string }
                                        | { __typename: "ST4Node"; label?: string | null; id: string }
                                        | { __typename: "ST4TreeNode"; id: string }
                                      terms: Array<
                                        | { __typename: "CommentNode"; id: string }
                                        | { __typename: "ExternalSite"; id: string }
                                        | { __typename: "ST4Class"; id: string }
                                        | {
                                            __typename: "ST4Node"
                                            id: string
                                            label?: string | null
                                            aspectId: any
                                            content?:
                                              | { __typename: "LexiconContent" }
                                              | { __typename: "LexiconEntryContent" }
                                              | { __typename: "MediaContent" }
                                              | { __typename: "MediaGroupContent" }
                                              | { __typename: "ProjectContent" }
                                              | {
                                                  __typename: "TermContent"
                                                  gramaticalGender?: string | null
                                                  validity?: string | null
                                                  editLanguage?: string | null
                                                }
                                              | { __typename: "TextContent" }
                                              | { __typename: "TextGroupContent" }
                                              | { __typename: "VariableTableContent" }
                                              | null
                                          }
                                        | { __typename: "ST4TreeNode"; id: string }
                                      >
                                      image?:
                                        | { __typename: "CommentNode"; id: string }
                                        | { __typename: "ExternalSite"; id: string }
                                        | { __typename: "ST4Class"; id: string }
                                        | {
                                            __typename: "ST4Node"
                                            aspectId: any
                                            id: string
                                            content?:
                                              | { __typename: "LexiconContent" }
                                              | { __typename: "LexiconEntryContent" }
                                              | {
                                                  __typename: "MediaContent"
                                                  media?:
                                                    | {
                                                        __typename: "AnimationMediaInfo"
                                                        thumbnail?: string | null
                                                        mediaType?: Types.MediaType | null
                                                      }
                                                    | {
                                                        __typename: "ImageMediaInfo"
                                                        src?: string | null
                                                        thumbnail?: string | null
                                                        mediaType?: Types.MediaType | null
                                                      }
                                                    | { __typename: "NoMediaInfo" }
                                                    | {
                                                        __typename: "VectorGraphicsMediaInfo"
                                                        svg?: string | null
                                                        thumbnail?: string | null
                                                        mediaType?: Types.MediaType | null
                                                      }
                                                    | {
                                                        __typename: "VideoMediaInfo"
                                                        thumbnail?: string | null
                                                        mediaType?: Types.MediaType | null
                                                      }
                                                    | null
                                                }
                                              | { __typename: "MediaGroupContent" }
                                              | { __typename: "ProjectContent" }
                                              | { __typename: "TermContent" }
                                              | { __typename: "TextContent" }
                                              | { __typename: "TextGroupContent" }
                                              | { __typename: "VariableTableContent" }
                                              | null
                                          }
                                        | { __typename: "ST4TreeNode"; id: string }
                                        | null
                                    }
                                  | { __typename: "MediaContent" }
                                  | { __typename: "MediaGroupContent" }
                                  | { __typename: "ProjectContent" }
                                  | { __typename: "TermContent" }
                                  | { __typename: "TextContent" }
                                  | { __typename: "TextGroupContent" }
                                  | { __typename: "VariableTableContent" }
                                  | null
                              }
                            | { __typename: "ST4TreeNode"; id: string }
                            | null
                        } | null> | null
                        comments: Array<{
                          __typename: "CommentNode"
                          id: string
                          comment:
                            | {
                                __typename: "BlockComment"
                                location: number
                                value?: string | null
                                authorDisplayName?: string | null
                                authorUserName?: string | null
                                commentKey: string
                                editDate?: any | null
                                modificationVersion?: number | null
                                replyToKey?: string | null
                                sortOrder?: number | null
                                commentType?: string | null
                                state?: string | null
                                color: string
                                persistanceState?: Types.PersistanceState | null
                                persistanceAction?: Types.PersistanceAction | null
                                node: {
                                  __typename: "ST4Node"
                                  aspectId: any
                                  id: string
                                  content?:
                                    | { __typename: "LexiconContent" }
                                    | { __typename: "LexiconEntryContent" }
                                    | { __typename: "MediaContent" }
                                    | { __typename: "MediaGroupContent" }
                                    | { __typename: "ProjectContent" }
                                    | { __typename: "TermContent" }
                                    | { __typename: "TextContent"; id: string; historyNumber: number }
                                    | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                                    | { __typename: "VariableTableContent" }
                                    | null
                                }
                                actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
                              }
                            | {
                                __typename: "TextComment"
                                endLocation: number
                                startLocation?: number | null
                                value?: string | null
                                authorDisplayName?: string | null
                                authorUserName?: string | null
                                commentKey: string
                                editDate?: any | null
                                modificationVersion?: number | null
                                replyToKey?: string | null
                                sortOrder?: number | null
                                commentType?: string | null
                                state?: string | null
                                color: string
                                persistanceState?: Types.PersistanceState | null
                                persistanceAction?: Types.PersistanceAction | null
                                node: {
                                  __typename: "ST4Node"
                                  aspectId: any
                                  id: string
                                  content?:
                                    | { __typename: "LexiconContent" }
                                    | { __typename: "LexiconEntryContent" }
                                    | { __typename: "MediaContent" }
                                    | { __typename: "MediaGroupContent" }
                                    | { __typename: "ProjectContent" }
                                    | { __typename: "TermContent" }
                                    | { __typename: "TextContent"; id: string; historyNumber: number }
                                    | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                                    | { __typename: "VariableTableContent" }
                                    | null
                                }
                                actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
                              }
                        }>
                      }
                    | { __typename: "VariableTableContent" }
                    | null
                }
              }>
              parts: Array<{
                __typename: "TextContentPart"
                ref: string
                contentNode: {
                  __typename: "ST4Node"
                  id: string
                  aspectId: any
                  content?:
                    | { __typename: "LexiconContent" }
                    | { __typename: "LexiconEntryContent" }
                    | { __typename: "MediaContent" }
                    | { __typename: "MediaGroupContent" }
                    | { __typename: "ProjectContent" }
                    | { __typename: "TermContent" }
                    | {
                        __typename: "TextContent"
                        id: string
                        xast?: string | null
                        historyNumber: number
                        links?: Array<{
                          __typename: "TextContentLink"
                          ref?: string | null
                          node?:
                            | { __typename: "CommentNode"; id: string }
                            | { __typename: "ExternalSite"; id: string }
                            | { __typename: "ST4Class"; id: string }
                            | {
                                __typename: "ST4Node"
                                id: string
                                aspectId: any
                                label?: string | null
                                properties?: {
                                  __typename: "ContentPropertyConnection"
                                  totalCount: number
                                  edges?: Array<{
                                    __typename: "ContentPropertyEdge"
                                    node?: {
                                      __typename: "ContentProperty"
                                      typeOfValue: Types.ContentPropertyValueType
                                      name: string
                                      value?: any | null
                                      displayName: string
                                    } | null
                                  } | null> | null
                                } | null
                              }
                            | { __typename: "ST4TreeNode"; id: string }
                            | null
                        } | null> | null
                        images: Array<{
                          __typename: "NodeRef"
                          id: string
                          target?:
                            | { __typename: "CommentNode"; id: string }
                            | { __typename: "ExternalSite"; id: string }
                            | { __typename: "ST4Class"; id: string }
                            | {
                                __typename: "ST4Node"
                                aspectId: any
                                id: string
                                releaseState: {
                                  __typename: "ReleaseStateValue"
                                  displayName: string
                                  state: Types.ReleaseState
                                  isImplicitlyReleased: boolean
                                }
                                content?:
                                  | { __typename: "LexiconContent" }
                                  | { __typename: "LexiconEntryContent" }
                                  | {
                                      __typename: "MediaContent"
                                      media?:
                                        | {
                                            __typename: "AnimationMediaInfo"
                                            id: string
                                            resourceId?: string | null
                                            thumbnail?: string | null
                                            width?: number | null
                                            height?: number | null
                                            mediaType?: Types.MediaType | null
                                          }
                                        | {
                                            __typename: "ImageMediaInfo"
                                            id: string
                                            resourceId?: string | null
                                            thumbnail?: string | null
                                            width?: number | null
                                            height?: number | null
                                            resolution?: number | null
                                            colorDepth?: number | null
                                            size?: number | null
                                            mime?: string | null
                                            extension?: string | null
                                            allowCaching?: boolean | null
                                            mediaType?: Types.MediaType | null
                                            src?: string | null
                                            language?: string | null
                                          }
                                        | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                                        | {
                                            __typename: "VectorGraphicsMediaInfo"
                                            id: string
                                            resourceId?: string | null
                                            thumbnail?: string | null
                                            width?: number | null
                                            height?: number | null
                                            allowCaching?: boolean | null
                                            mediaType?: Types.MediaType | null
                                            language?: string | null
                                            labels: Array<{
                                              __typename: "VectorGraphicsLabel"
                                              id?: string | null
                                              sequence?: number | null
                                              autoValue?: string | null
                                              text?: string | null
                                              linkType?: Types.VectorGraphicsLabelLinkType | null
                                              link?: string | null
                                              ref?:
                                                | {
                                                    __typename: "GenericRef"
                                                    id: string
                                                    owner?:
                                                      | { __typename: "CommentNode" }
                                                      | { __typename: "ExternalSite" }
                                                      | { __typename: "ST4Class" }
                                                      | { __typename: "ST4Node"; id: string }
                                                      | { __typename: "ST4TreeNode" }
                                                      | null
                                                    target?:
                                                      | { __typename: "CommentNode" }
                                                      | {
                                                          __typename: "ExternalSite"
                                                          id: string
                                                          teaserData?: {
                                                            __typename: "ExternalLinkTeaserData"
                                                            title?: string | null
                                                            summary?: string | null
                                                            iconUrl?: string | null
                                                            imageUrl?: string | null
                                                            status?: Types.LinkTargetStatus | null
                                                          } | null
                                                        }
                                                      | { __typename: "ST4Class" }
                                                      | {
                                                          __typename: "ST4Node"
                                                          id: string
                                                          aspectId: any
                                                          label?: string | null
                                                          properties?: {
                                                            __typename: "ContentPropertyConnection"
                                                            edges?: Array<{
                                                              __typename: "ContentPropertyEdge"
                                                              node?: {
                                                                __typename: "ContentProperty"
                                                                name: string
                                                                value?: any | null
                                                                displayName: string
                                                              } | null
                                                            } | null> | null
                                                          } | null
                                                        }
                                                      | { __typename: "ST4TreeNode" }
                                                      | null
                                                  }
                                                | {
                                                    __typename: "NodeRef"
                                                    id: string
                                                    owner?:
                                                      | { __typename: "CommentNode" }
                                                      | { __typename: "ExternalSite" }
                                                      | { __typename: "ST4Class" }
                                                      | { __typename: "ST4Node"; id: string }
                                                      | { __typename: "ST4TreeNode" }
                                                      | null
                                                    target?:
                                                      | { __typename: "CommentNode" }
                                                      | {
                                                          __typename: "ExternalSite"
                                                          id: string
                                                          teaserData?: {
                                                            __typename: "ExternalLinkTeaserData"
                                                            title?: string | null
                                                            summary?: string | null
                                                            iconUrl?: string | null
                                                            imageUrl?: string | null
                                                            status?: Types.LinkTargetStatus | null
                                                          } | null
                                                        }
                                                      | { __typename: "ST4Class" }
                                                      | {
                                                          __typename: "ST4Node"
                                                          id: string
                                                          aspectId: any
                                                          label?: string | null
                                                          properties?: {
                                                            __typename: "ContentPropertyConnection"
                                                            edges?: Array<{
                                                              __typename: "ContentPropertyEdge"
                                                              node?: {
                                                                __typename: "ContentProperty"
                                                                name: string
                                                                value?: any | null
                                                                displayName: string
                                                              } | null
                                                            } | null> | null
                                                          } | null
                                                        }
                                                      | { __typename: "ST4TreeNode" }
                                                      | null
                                                  }
                                                | null
                                            }>
                                          }
                                        | {
                                            __typename: "VideoMediaInfo"
                                            id: string
                                            resourceId?: string | null
                                            thumbnail?: string | null
                                            width?: number | null
                                            height?: number | null
                                            mediaType?: Types.MediaType | null
                                          }
                                        | null
                                    }
                                  | {
                                      __typename: "MediaGroupContent"
                                      children: Array<{
                                        __typename: "NodeRef"
                                        id: string
                                        target?:
                                          | { __typename: "CommentNode"; id: string }
                                          | { __typename: "ExternalSite"; id: string }
                                          | { __typename: "ST4Class"; id: string }
                                          | {
                                              __typename: "ST4Node"
                                              id: string
                                              aspectId: any
                                              content?:
                                                | { __typename: "LexiconContent" }
                                                | { __typename: "LexiconEntryContent" }
                                                | {
                                                    __typename: "MediaContent"
                                                    media?:
                                                      | {
                                                          __typename: "AnimationMediaInfo"
                                                          id: string
                                                          resourceId?: string | null
                                                          thumbnail?: string | null
                                                          width?: number | null
                                                          height?: number | null
                                                          mediaType?: Types.MediaType | null
                                                        }
                                                      | {
                                                          __typename: "ImageMediaInfo"
                                                          id: string
                                                          resourceId?: string | null
                                                          thumbnail?: string | null
                                                          width?: number | null
                                                          height?: number | null
                                                          resolution?: number | null
                                                          colorDepth?: number | null
                                                          size?: number | null
                                                          mime?: string | null
                                                          extension?: string | null
                                                          allowCaching?: boolean | null
                                                          mediaType?: Types.MediaType | null
                                                          src?: string | null
                                                          language?: string | null
                                                        }
                                                      | {
                                                          __typename: "NoMediaInfo"
                                                          id: string
                                                          mediaType?: Types.MediaType | null
                                                        }
                                                      | {
                                                          __typename: "VectorGraphicsMediaInfo"
                                                          id: string
                                                          resourceId?: string | null
                                                          thumbnail?: string | null
                                                          width?: number | null
                                                          height?: number | null
                                                          allowCaching?: boolean | null
                                                          mediaType?: Types.MediaType | null
                                                          language?: string | null
                                                          labels: Array<{
                                                            __typename: "VectorGraphicsLabel"
                                                            id?: string | null
                                                            sequence?: number | null
                                                            autoValue?: string | null
                                                            text?: string | null
                                                            linkType?: Types.VectorGraphicsLabelLinkType | null
                                                            link?: string | null
                                                            ref?:
                                                              | {
                                                                  __typename: "GenericRef"
                                                                  id: string
                                                                  owner?:
                                                                    | { __typename: "CommentNode" }
                                                                    | { __typename: "ExternalSite" }
                                                                    | { __typename: "ST4Class" }
                                                                    | { __typename: "ST4Node"; id: string }
                                                                    | { __typename: "ST4TreeNode" }
                                                                    | null
                                                                  target?:
                                                                    | { __typename: "CommentNode" }
                                                                    | {
                                                                        __typename: "ExternalSite"
                                                                        id: string
                                                                        teaserData?: {
                                                                          __typename: "ExternalLinkTeaserData"
                                                                          title?: string | null
                                                                          summary?: string | null
                                                                          iconUrl?: string | null
                                                                          imageUrl?: string | null
                                                                          status?: Types.LinkTargetStatus | null
                                                                        } | null
                                                                      }
                                                                    | { __typename: "ST4Class" }
                                                                    | {
                                                                        __typename: "ST4Node"
                                                                        id: string
                                                                        aspectId: any
                                                                        label?: string | null
                                                                        properties?: {
                                                                          __typename: "ContentPropertyConnection"
                                                                          edges?: Array<{
                                                                            __typename: "ContentPropertyEdge"
                                                                            node?: {
                                                                              __typename: "ContentProperty"
                                                                              name: string
                                                                              value?: any | null
                                                                              displayName: string
                                                                            } | null
                                                                          } | null> | null
                                                                        } | null
                                                                      }
                                                                    | { __typename: "ST4TreeNode" }
                                                                    | null
                                                                }
                                                              | {
                                                                  __typename: "NodeRef"
                                                                  id: string
                                                                  owner?:
                                                                    | { __typename: "CommentNode" }
                                                                    | { __typename: "ExternalSite" }
                                                                    | { __typename: "ST4Class" }
                                                                    | { __typename: "ST4Node"; id: string }
                                                                    | { __typename: "ST4TreeNode" }
                                                                    | null
                                                                  target?:
                                                                    | { __typename: "CommentNode" }
                                                                    | {
                                                                        __typename: "ExternalSite"
                                                                        id: string
                                                                        teaserData?: {
                                                                          __typename: "ExternalLinkTeaserData"
                                                                          title?: string | null
                                                                          summary?: string | null
                                                                          iconUrl?: string | null
                                                                          imageUrl?: string | null
                                                                          status?: Types.LinkTargetStatus | null
                                                                        } | null
                                                                      }
                                                                    | { __typename: "ST4Class" }
                                                                    | {
                                                                        __typename: "ST4Node"
                                                                        id: string
                                                                        aspectId: any
                                                                        label?: string | null
                                                                        properties?: {
                                                                          __typename: "ContentPropertyConnection"
                                                                          edges?: Array<{
                                                                            __typename: "ContentPropertyEdge"
                                                                            node?: {
                                                                              __typename: "ContentProperty"
                                                                              name: string
                                                                              value?: any | null
                                                                              displayName: string
                                                                            } | null
                                                                          } | null> | null
                                                                        } | null
                                                                      }
                                                                    | { __typename: "ST4TreeNode" }
                                                                    | null
                                                                }
                                                              | null
                                                          }>
                                                        }
                                                      | {
                                                          __typename: "VideoMediaInfo"
                                                          id: string
                                                          resourceId?: string | null
                                                          thumbnail?: string | null
                                                          width?: number | null
                                                          height?: number | null
                                                          mediaType?: Types.MediaType | null
                                                        }
                                                      | null
                                                  }
                                                | {
                                                    __typename: "MediaGroupContent"
                                                    media?:
                                                      | {
                                                          __typename: "AnimationMediaInfo"
                                                          id: string
                                                          resourceId?: string | null
                                                          thumbnail?: string | null
                                                          width?: number | null
                                                          height?: number | null
                                                          mediaType?: Types.MediaType | null
                                                        }
                                                      | {
                                                          __typename: "ImageMediaInfo"
                                                          id: string
                                                          resourceId?: string | null
                                                          thumbnail?: string | null
                                                          width?: number | null
                                                          height?: number | null
                                                          resolution?: number | null
                                                          colorDepth?: number | null
                                                          size?: number | null
                                                          mime?: string | null
                                                          extension?: string | null
                                                          allowCaching?: boolean | null
                                                          mediaType?: Types.MediaType | null
                                                          src?: string | null
                                                          language?: string | null
                                                        }
                                                      | {
                                                          __typename: "NoMediaInfo"
                                                          id: string
                                                          mediaType?: Types.MediaType | null
                                                        }
                                                      | {
                                                          __typename: "VectorGraphicsMediaInfo"
                                                          id: string
                                                          resourceId?: string | null
                                                          thumbnail?: string | null
                                                          width?: number | null
                                                          height?: number | null
                                                          allowCaching?: boolean | null
                                                          mediaType?: Types.MediaType | null
                                                          language?: string | null
                                                          labels: Array<{
                                                            __typename: "VectorGraphicsLabel"
                                                            id?: string | null
                                                            sequence?: number | null
                                                            autoValue?: string | null
                                                            text?: string | null
                                                            linkType?: Types.VectorGraphicsLabelLinkType | null
                                                            link?: string | null
                                                            ref?:
                                                              | {
                                                                  __typename: "GenericRef"
                                                                  id: string
                                                                  owner?:
                                                                    | { __typename: "CommentNode" }
                                                                    | { __typename: "ExternalSite" }
                                                                    | { __typename: "ST4Class" }
                                                                    | { __typename: "ST4Node"; id: string }
                                                                    | { __typename: "ST4TreeNode" }
                                                                    | null
                                                                  target?:
                                                                    | { __typename: "CommentNode" }
                                                                    | {
                                                                        __typename: "ExternalSite"
                                                                        id: string
                                                                        teaserData?: {
                                                                          __typename: "ExternalLinkTeaserData"
                                                                          title?: string | null
                                                                          summary?: string | null
                                                                          iconUrl?: string | null
                                                                          imageUrl?: string | null
                                                                          status?: Types.LinkTargetStatus | null
                                                                        } | null
                                                                      }
                                                                    | { __typename: "ST4Class" }
                                                                    | {
                                                                        __typename: "ST4Node"
                                                                        id: string
                                                                        aspectId: any
                                                                        label?: string | null
                                                                        properties?: {
                                                                          __typename: "ContentPropertyConnection"
                                                                          edges?: Array<{
                                                                            __typename: "ContentPropertyEdge"
                                                                            node?: {
                                                                              __typename: "ContentProperty"
                                                                              name: string
                                                                              value?: any | null
                                                                              displayName: string
                                                                            } | null
                                                                          } | null> | null
                                                                        } | null
                                                                      }
                                                                    | { __typename: "ST4TreeNode" }
                                                                    | null
                                                                }
                                                              | {
                                                                  __typename: "NodeRef"
                                                                  id: string
                                                                  owner?:
                                                                    | { __typename: "CommentNode" }
                                                                    | { __typename: "ExternalSite" }
                                                                    | { __typename: "ST4Class" }
                                                                    | { __typename: "ST4Node"; id: string }
                                                                    | { __typename: "ST4TreeNode" }
                                                                    | null
                                                                  target?:
                                                                    | { __typename: "CommentNode" }
                                                                    | {
                                                                        __typename: "ExternalSite"
                                                                        id: string
                                                                        teaserData?: {
                                                                          __typename: "ExternalLinkTeaserData"
                                                                          title?: string | null
                                                                          summary?: string | null
                                                                          iconUrl?: string | null
                                                                          imageUrl?: string | null
                                                                          status?: Types.LinkTargetStatus | null
                                                                        } | null
                                                                      }
                                                                    | { __typename: "ST4Class" }
                                                                    | {
                                                                        __typename: "ST4Node"
                                                                        id: string
                                                                        aspectId: any
                                                                        label?: string | null
                                                                        properties?: {
                                                                          __typename: "ContentPropertyConnection"
                                                                          edges?: Array<{
                                                                            __typename: "ContentPropertyEdge"
                                                                            node?: {
                                                                              __typename: "ContentProperty"
                                                                              name: string
                                                                              value?: any | null
                                                                              displayName: string
                                                                            } | null
                                                                          } | null> | null
                                                                        } | null
                                                                      }
                                                                    | { __typename: "ST4TreeNode" }
                                                                    | null
                                                                }
                                                              | null
                                                          }>
                                                        }
                                                      | {
                                                          __typename: "VideoMediaInfo"
                                                          id: string
                                                          resourceId?: string | null
                                                          thumbnail?: string | null
                                                          width?: number | null
                                                          height?: number | null
                                                          mediaType?: Types.MediaType | null
                                                        }
                                                      | null
                                                  }
                                                | { __typename: "ProjectContent" }
                                                | { __typename: "TermContent" }
                                                | { __typename: "TextContent" }
                                                | { __typename: "TextGroupContent" }
                                                | { __typename: "VariableTableContent" }
                                                | null
                                            }
                                          | { __typename: "ST4TreeNode"; id: string }
                                          | null
                                      }>
                                      media?:
                                        | {
                                            __typename: "AnimationMediaInfo"
                                            id: string
                                            resourceId?: string | null
                                            thumbnail?: string | null
                                            width?: number | null
                                            height?: number | null
                                            mediaType?: Types.MediaType | null
                                          }
                                        | {
                                            __typename: "ImageMediaInfo"
                                            id: string
                                            resourceId?: string | null
                                            thumbnail?: string | null
                                            width?: number | null
                                            height?: number | null
                                            resolution?: number | null
                                            colorDepth?: number | null
                                            size?: number | null
                                            mime?: string | null
                                            extension?: string | null
                                            allowCaching?: boolean | null
                                            mediaType?: Types.MediaType | null
                                            src?: string | null
                                            language?: string | null
                                          }
                                        | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                                        | {
                                            __typename: "VectorGraphicsMediaInfo"
                                            id: string
                                            resourceId?: string | null
                                            thumbnail?: string | null
                                            width?: number | null
                                            height?: number | null
                                            allowCaching?: boolean | null
                                            mediaType?: Types.MediaType | null
                                            language?: string | null
                                            labels: Array<{
                                              __typename: "VectorGraphicsLabel"
                                              id?: string | null
                                              sequence?: number | null
                                              autoValue?: string | null
                                              text?: string | null
                                              linkType?: Types.VectorGraphicsLabelLinkType | null
                                              link?: string | null
                                              ref?:
                                                | {
                                                    __typename: "GenericRef"
                                                    id: string
                                                    owner?:
                                                      | { __typename: "CommentNode" }
                                                      | { __typename: "ExternalSite" }
                                                      | { __typename: "ST4Class" }
                                                      | { __typename: "ST4Node"; id: string }
                                                      | { __typename: "ST4TreeNode" }
                                                      | null
                                                    target?:
                                                      | { __typename: "CommentNode" }
                                                      | {
                                                          __typename: "ExternalSite"
                                                          id: string
                                                          teaserData?: {
                                                            __typename: "ExternalLinkTeaserData"
                                                            title?: string | null
                                                            summary?: string | null
                                                            iconUrl?: string | null
                                                            imageUrl?: string | null
                                                            status?: Types.LinkTargetStatus | null
                                                          } | null
                                                        }
                                                      | { __typename: "ST4Class" }
                                                      | {
                                                          __typename: "ST4Node"
                                                          id: string
                                                          aspectId: any
                                                          label?: string | null
                                                          properties?: {
                                                            __typename: "ContentPropertyConnection"
                                                            edges?: Array<{
                                                              __typename: "ContentPropertyEdge"
                                                              node?: {
                                                                __typename: "ContentProperty"
                                                                name: string
                                                                value?: any | null
                                                                displayName: string
                                                              } | null
                                                            } | null> | null
                                                          } | null
                                                        }
                                                      | { __typename: "ST4TreeNode" }
                                                      | null
                                                  }
                                                | {
                                                    __typename: "NodeRef"
                                                    id: string
                                                    owner?:
                                                      | { __typename: "CommentNode" }
                                                      | { __typename: "ExternalSite" }
                                                      | { __typename: "ST4Class" }
                                                      | { __typename: "ST4Node"; id: string }
                                                      | { __typename: "ST4TreeNode" }
                                                      | null
                                                    target?:
                                                      | { __typename: "CommentNode" }
                                                      | {
                                                          __typename: "ExternalSite"
                                                          id: string
                                                          teaserData?: {
                                                            __typename: "ExternalLinkTeaserData"
                                                            title?: string | null
                                                            summary?: string | null
                                                            iconUrl?: string | null
                                                            imageUrl?: string | null
                                                            status?: Types.LinkTargetStatus | null
                                                          } | null
                                                        }
                                                      | { __typename: "ST4Class" }
                                                      | {
                                                          __typename: "ST4Node"
                                                          id: string
                                                          aspectId: any
                                                          label?: string | null
                                                          properties?: {
                                                            __typename: "ContentPropertyConnection"
                                                            edges?: Array<{
                                                              __typename: "ContentPropertyEdge"
                                                              node?: {
                                                                __typename: "ContentProperty"
                                                                name: string
                                                                value?: any | null
                                                                displayName: string
                                                              } | null
                                                            } | null> | null
                                                          } | null
                                                        }
                                                      | { __typename: "ST4TreeNode" }
                                                      | null
                                                  }
                                                | null
                                            }>
                                          }
                                        | {
                                            __typename: "VideoMediaInfo"
                                            id: string
                                            resourceId?: string | null
                                            thumbnail?: string | null
                                            width?: number | null
                                            height?: number | null
                                            mediaType?: Types.MediaType | null
                                          }
                                        | null
                                    }
                                  | { __typename: "ProjectContent" }
                                  | { __typename: "TermContent" }
                                  | { __typename: "TextContent" }
                                  | { __typename: "TextGroupContent" }
                                  | { __typename: "VariableTableContent" }
                                  | null
                              }
                            | { __typename: "ST4TreeNode"; id: string }
                            | null
                        }>
                        variableTables?: Array<{
                          __typename: "VariableTableReference"
                          ref?: string | null
                          node?:
                            | { __typename: "CommentNode" }
                            | { __typename: "ExternalSite" }
                            | { __typename: "ST4Class" }
                            | {
                                __typename: "ST4Node"
                                id: string
                                label?: string | null
                                aspectId: any
                                content?:
                                  | { __typename: "LexiconContent" }
                                  | { __typename: "LexiconEntryContent" }
                                  | { __typename: "MediaContent" }
                                  | { __typename: "MediaGroupContent" }
                                  | { __typename: "ProjectContent" }
                                  | { __typename: "TermContent" }
                                  | { __typename: "TextContent" }
                                  | { __typename: "TextGroupContent" }
                                  | {
                                      __typename: "VariableTableContent"
                                      xml?: string | null
                                      valueSets: Array<{ __typename: "ValueSet"; name: string; valueSetId: string }>
                                      variables: Array<{
                                        __typename: "Variable"
                                        name: string
                                        variableId: string
                                        values: Array<string>
                                      }>
                                    }
                                  | null
                              }
                            | { __typename: "ST4TreeNode" }
                            | null
                        } | null> | null
                        linkedDataNodes: Array<{
                          __typename: "NodeRef"
                          id: string
                          target?:
                            | { __typename: "CommentNode"; id: string }
                            | { __typename: "ExternalSite"; id: string }
                            | { __typename: "ST4Class"; id: string }
                            | {
                                __typename: "ST4Node"
                                label?: string | null
                                id: string
                                metadata: Array<{
                                  __typename: "MetaDataEntry"
                                  label?: string | null
                                  systemName: string
                                  value?: string | null
                                  values: Array<string>
                                  users: Array<string>
                                }>
                                linkedOntologyNodes: Array<
                                  | {
                                      __typename: "GenericRef"
                                      target?:
                                        | { __typename: "CommentNode" }
                                        | { __typename: "ExternalSite" }
                                        | { __typename: "ST4Class" }
                                        | { __typename: "ST4Node"; id: string; label?: string | null }
                                        | { __typename: "ST4TreeNode" }
                                        | null
                                    }
                                  | {
                                      __typename: "NodeRef"
                                      linkClassName: string
                                      class?: { __typename: "ST4Class"; label?: string | null } | null
                                      target?:
                                        | { __typename: "CommentNode" }
                                        | { __typename: "ExternalSite" }
                                        | { __typename: "ST4Class" }
                                        | { __typename: "ST4Node"; id: string; label?: string | null }
                                        | { __typename: "ST4TreeNode" }
                                        | null
                                    }
                                >
                              }
                            | { __typename: "ST4TreeNode"; id: string }
                            | null
                        }>
                        lexiconEntries?: Array<{
                          __typename: "NodeRef"
                          id: string
                          target?:
                            | { __typename: "CommentNode"; id: string }
                            | { __typename: "ExternalSite"; id: string }
                            | { __typename: "ST4Class"; id: string }
                            | {
                                __typename: "ST4Node"
                                aspectId: any
                                label?: string | null
                                id: string
                                content?:
                                  | { __typename: "LexiconContent" }
                                  | {
                                      __typename: "LexiconEntryContent"
                                      descriptionXast?: string | null
                                      subject?: string | null
                                      sortKey?: string | null
                                      lexicon:
                                        | { __typename: "CommentNode"; id: string }
                                        | { __typename: "ExternalSite"; id: string }
                                        | { __typename: "ST4Class"; id: string }
                                        | { __typename: "ST4Node"; label?: string | null; id: string }
                                        | { __typename: "ST4TreeNode"; id: string }
                                      terms: Array<
                                        | { __typename: "CommentNode"; id: string }
                                        | { __typename: "ExternalSite"; id: string }
                                        | { __typename: "ST4Class"; id: string }
                                        | {
                                            __typename: "ST4Node"
                                            id: string
                                            label?: string | null
                                            aspectId: any
                                            content?:
                                              | { __typename: "LexiconContent" }
                                              | { __typename: "LexiconEntryContent" }
                                              | { __typename: "MediaContent" }
                                              | { __typename: "MediaGroupContent" }
                                              | { __typename: "ProjectContent" }
                                              | {
                                                  __typename: "TermContent"
                                                  gramaticalGender?: string | null
                                                  validity?: string | null
                                                  editLanguage?: string | null
                                                }
                                              | { __typename: "TextContent" }
                                              | { __typename: "TextGroupContent" }
                                              | { __typename: "VariableTableContent" }
                                              | null
                                          }
                                        | { __typename: "ST4TreeNode"; id: string }
                                      >
                                      image?:
                                        | { __typename: "CommentNode"; id: string }
                                        | { __typename: "ExternalSite"; id: string }
                                        | { __typename: "ST4Class"; id: string }
                                        | {
                                            __typename: "ST4Node"
                                            aspectId: any
                                            id: string
                                            content?:
                                              | { __typename: "LexiconContent" }
                                              | { __typename: "LexiconEntryContent" }
                                              | {
                                                  __typename: "MediaContent"
                                                  media?:
                                                    | {
                                                        __typename: "AnimationMediaInfo"
                                                        thumbnail?: string | null
                                                        mediaType?: Types.MediaType | null
                                                      }
                                                    | {
                                                        __typename: "ImageMediaInfo"
                                                        src?: string | null
                                                        thumbnail?: string | null
                                                        mediaType?: Types.MediaType | null
                                                      }
                                                    | { __typename: "NoMediaInfo" }
                                                    | {
                                                        __typename: "VectorGraphicsMediaInfo"
                                                        svg?: string | null
                                                        thumbnail?: string | null
                                                        mediaType?: Types.MediaType | null
                                                      }
                                                    | {
                                                        __typename: "VideoMediaInfo"
                                                        thumbnail?: string | null
                                                        mediaType?: Types.MediaType | null
                                                      }
                                                    | null
                                                }
                                              | { __typename: "MediaGroupContent" }
                                              | { __typename: "ProjectContent" }
                                              | { __typename: "TermContent" }
                                              | { __typename: "TextContent" }
                                              | { __typename: "TextGroupContent" }
                                              | { __typename: "VariableTableContent" }
                                              | null
                                          }
                                        | { __typename: "ST4TreeNode"; id: string }
                                        | null
                                    }
                                  | { __typename: "MediaContent" }
                                  | { __typename: "MediaGroupContent" }
                                  | { __typename: "ProjectContent" }
                                  | { __typename: "TermContent" }
                                  | { __typename: "TextContent" }
                                  | { __typename: "TextGroupContent" }
                                  | { __typename: "VariableTableContent" }
                                  | null
                              }
                            | { __typename: "ST4TreeNode"; id: string }
                            | null
                        } | null> | null
                        comments: Array<{
                          __typename: "CommentNode"
                          id: string
                          comment:
                            | {
                                __typename: "BlockComment"
                                location: number
                                value?: string | null
                                authorDisplayName?: string | null
                                authorUserName?: string | null
                                commentKey: string
                                editDate?: any | null
                                modificationVersion?: number | null
                                replyToKey?: string | null
                                sortOrder?: number | null
                                commentType?: string | null
                                state?: string | null
                                color: string
                                persistanceState?: Types.PersistanceState | null
                                persistanceAction?: Types.PersistanceAction | null
                                node: {
                                  __typename: "ST4Node"
                                  aspectId: any
                                  id: string
                                  content?:
                                    | { __typename: "LexiconContent" }
                                    | { __typename: "LexiconEntryContent" }
                                    | { __typename: "MediaContent" }
                                    | { __typename: "MediaGroupContent" }
                                    | { __typename: "ProjectContent" }
                                    | { __typename: "TermContent" }
                                    | { __typename: "TextContent"; id: string; historyNumber: number }
                                    | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                                    | { __typename: "VariableTableContent" }
                                    | null
                                }
                                actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
                              }
                            | {
                                __typename: "TextComment"
                                endLocation: number
                                startLocation?: number | null
                                value?: string | null
                                authorDisplayName?: string | null
                                authorUserName?: string | null
                                commentKey: string
                                editDate?: any | null
                                modificationVersion?: number | null
                                replyToKey?: string | null
                                sortOrder?: number | null
                                commentType?: string | null
                                state?: string | null
                                color: string
                                persistanceState?: Types.PersistanceState | null
                                persistanceAction?: Types.PersistanceAction | null
                                node: {
                                  __typename: "ST4Node"
                                  aspectId: any
                                  id: string
                                  content?:
                                    | { __typename: "LexiconContent" }
                                    | { __typename: "LexiconEntryContent" }
                                    | { __typename: "MediaContent" }
                                    | { __typename: "MediaGroupContent" }
                                    | { __typename: "ProjectContent" }
                                    | { __typename: "TermContent" }
                                    | { __typename: "TextContent"; id: string; historyNumber: number }
                                    | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                                    | { __typename: "VariableTableContent" }
                                    | null
                                }
                                actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
                              }
                        }>
                      }
                    | {
                        __typename: "TextGroupContent"
                        currentNodeId?: any | null
                        groupNodeId: any
                        id: string
                        xast?: string | null
                        historyNumber: number
                        children: Array<{
                          __typename: "NodeRef"
                          id: string
                          target?:
                            | { __typename: "CommentNode"; id: string }
                            | { __typename: "ExternalSite"; id: string }
                            | { __typename: "ST4Class"; id: string }
                            | { __typename: "ST4Node"; label?: string | null; aspectId: any; id: string }
                            | { __typename: "ST4TreeNode"; id: string }
                            | null
                        }>
                        links?: Array<{
                          __typename: "TextContentLink"
                          ref?: string | null
                          node?:
                            | { __typename: "CommentNode"; id: string }
                            | { __typename: "ExternalSite"; id: string }
                            | { __typename: "ST4Class"; id: string }
                            | {
                                __typename: "ST4Node"
                                id: string
                                aspectId: any
                                label?: string | null
                                properties?: {
                                  __typename: "ContentPropertyConnection"
                                  totalCount: number
                                  edges?: Array<{
                                    __typename: "ContentPropertyEdge"
                                    node?: {
                                      __typename: "ContentProperty"
                                      typeOfValue: Types.ContentPropertyValueType
                                      name: string
                                      value?: any | null
                                      displayName: string
                                    } | null
                                  } | null> | null
                                } | null
                              }
                            | { __typename: "ST4TreeNode"; id: string }
                            | null
                        } | null> | null
                        images: Array<{
                          __typename: "NodeRef"
                          id: string
                          target?:
                            | { __typename: "CommentNode"; id: string }
                            | { __typename: "ExternalSite"; id: string }
                            | { __typename: "ST4Class"; id: string }
                            | {
                                __typename: "ST4Node"
                                aspectId: any
                                id: string
                                releaseState: {
                                  __typename: "ReleaseStateValue"
                                  displayName: string
                                  state: Types.ReleaseState
                                  isImplicitlyReleased: boolean
                                }
                                content?:
                                  | { __typename: "LexiconContent" }
                                  | { __typename: "LexiconEntryContent" }
                                  | {
                                      __typename: "MediaContent"
                                      media?:
                                        | {
                                            __typename: "AnimationMediaInfo"
                                            id: string
                                            resourceId?: string | null
                                            thumbnail?: string | null
                                            width?: number | null
                                            height?: number | null
                                            mediaType?: Types.MediaType | null
                                          }
                                        | {
                                            __typename: "ImageMediaInfo"
                                            id: string
                                            resourceId?: string | null
                                            thumbnail?: string | null
                                            width?: number | null
                                            height?: number | null
                                            resolution?: number | null
                                            colorDepth?: number | null
                                            size?: number | null
                                            mime?: string | null
                                            extension?: string | null
                                            allowCaching?: boolean | null
                                            mediaType?: Types.MediaType | null
                                            src?: string | null
                                            language?: string | null
                                          }
                                        | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                                        | {
                                            __typename: "VectorGraphicsMediaInfo"
                                            id: string
                                            resourceId?: string | null
                                            thumbnail?: string | null
                                            width?: number | null
                                            height?: number | null
                                            allowCaching?: boolean | null
                                            mediaType?: Types.MediaType | null
                                            language?: string | null
                                            labels: Array<{
                                              __typename: "VectorGraphicsLabel"
                                              id?: string | null
                                              sequence?: number | null
                                              autoValue?: string | null
                                              text?: string | null
                                              linkType?: Types.VectorGraphicsLabelLinkType | null
                                              link?: string | null
                                              ref?:
                                                | {
                                                    __typename: "GenericRef"
                                                    id: string
                                                    owner?:
                                                      | { __typename: "CommentNode" }
                                                      | { __typename: "ExternalSite" }
                                                      | { __typename: "ST4Class" }
                                                      | { __typename: "ST4Node"; id: string }
                                                      | { __typename: "ST4TreeNode" }
                                                      | null
                                                    target?:
                                                      | { __typename: "CommentNode" }
                                                      | {
                                                          __typename: "ExternalSite"
                                                          id: string
                                                          teaserData?: {
                                                            __typename: "ExternalLinkTeaserData"
                                                            title?: string | null
                                                            summary?: string | null
                                                            iconUrl?: string | null
                                                            imageUrl?: string | null
                                                            status?: Types.LinkTargetStatus | null
                                                          } | null
                                                        }
                                                      | { __typename: "ST4Class" }
                                                      | {
                                                          __typename: "ST4Node"
                                                          id: string
                                                          aspectId: any
                                                          label?: string | null
                                                          properties?: {
                                                            __typename: "ContentPropertyConnection"
                                                            edges?: Array<{
                                                              __typename: "ContentPropertyEdge"
                                                              node?: {
                                                                __typename: "ContentProperty"
                                                                name: string
                                                                value?: any | null
                                                                displayName: string
                                                              } | null
                                                            } | null> | null
                                                          } | null
                                                        }
                                                      | { __typename: "ST4TreeNode" }
                                                      | null
                                                  }
                                                | {
                                                    __typename: "NodeRef"
                                                    id: string
                                                    owner?:
                                                      | { __typename: "CommentNode" }
                                                      | { __typename: "ExternalSite" }
                                                      | { __typename: "ST4Class" }
                                                      | { __typename: "ST4Node"; id: string }
                                                      | { __typename: "ST4TreeNode" }
                                                      | null
                                                    target?:
                                                      | { __typename: "CommentNode" }
                                                      | {
                                                          __typename: "ExternalSite"
                                                          id: string
                                                          teaserData?: {
                                                            __typename: "ExternalLinkTeaserData"
                                                            title?: string | null
                                                            summary?: string | null
                                                            iconUrl?: string | null
                                                            imageUrl?: string | null
                                                            status?: Types.LinkTargetStatus | null
                                                          } | null
                                                        }
                                                      | { __typename: "ST4Class" }
                                                      | {
                                                          __typename: "ST4Node"
                                                          id: string
                                                          aspectId: any
                                                          label?: string | null
                                                          properties?: {
                                                            __typename: "ContentPropertyConnection"
                                                            edges?: Array<{
                                                              __typename: "ContentPropertyEdge"
                                                              node?: {
                                                                __typename: "ContentProperty"
                                                                name: string
                                                                value?: any | null
                                                                displayName: string
                                                              } | null
                                                            } | null> | null
                                                          } | null
                                                        }
                                                      | { __typename: "ST4TreeNode" }
                                                      | null
                                                  }
                                                | null
                                            }>
                                          }
                                        | {
                                            __typename: "VideoMediaInfo"
                                            id: string
                                            resourceId?: string | null
                                            thumbnail?: string | null
                                            width?: number | null
                                            height?: number | null
                                            mediaType?: Types.MediaType | null
                                          }
                                        | null
                                    }
                                  | {
                                      __typename: "MediaGroupContent"
                                      children: Array<{
                                        __typename: "NodeRef"
                                        id: string
                                        target?:
                                          | { __typename: "CommentNode"; id: string }
                                          | { __typename: "ExternalSite"; id: string }
                                          | { __typename: "ST4Class"; id: string }
                                          | {
                                              __typename: "ST4Node"
                                              id: string
                                              aspectId: any
                                              content?:
                                                | { __typename: "LexiconContent" }
                                                | { __typename: "LexiconEntryContent" }
                                                | {
                                                    __typename: "MediaContent"
                                                    media?:
                                                      | {
                                                          __typename: "AnimationMediaInfo"
                                                          id: string
                                                          resourceId?: string | null
                                                          thumbnail?: string | null
                                                          width?: number | null
                                                          height?: number | null
                                                          mediaType?: Types.MediaType | null
                                                        }
                                                      | {
                                                          __typename: "ImageMediaInfo"
                                                          id: string
                                                          resourceId?: string | null
                                                          thumbnail?: string | null
                                                          width?: number | null
                                                          height?: number | null
                                                          resolution?: number | null
                                                          colorDepth?: number | null
                                                          size?: number | null
                                                          mime?: string | null
                                                          extension?: string | null
                                                          allowCaching?: boolean | null
                                                          mediaType?: Types.MediaType | null
                                                          src?: string | null
                                                          language?: string | null
                                                        }
                                                      | {
                                                          __typename: "NoMediaInfo"
                                                          id: string
                                                          mediaType?: Types.MediaType | null
                                                        }
                                                      | {
                                                          __typename: "VectorGraphicsMediaInfo"
                                                          id: string
                                                          resourceId?: string | null
                                                          thumbnail?: string | null
                                                          width?: number | null
                                                          height?: number | null
                                                          allowCaching?: boolean | null
                                                          mediaType?: Types.MediaType | null
                                                          language?: string | null
                                                          labels: Array<{
                                                            __typename: "VectorGraphicsLabel"
                                                            id?: string | null
                                                            sequence?: number | null
                                                            autoValue?: string | null
                                                            text?: string | null
                                                            linkType?: Types.VectorGraphicsLabelLinkType | null
                                                            link?: string | null
                                                            ref?:
                                                              | {
                                                                  __typename: "GenericRef"
                                                                  id: string
                                                                  owner?:
                                                                    | { __typename: "CommentNode" }
                                                                    | { __typename: "ExternalSite" }
                                                                    | { __typename: "ST4Class" }
                                                                    | { __typename: "ST4Node"; id: string }
                                                                    | { __typename: "ST4TreeNode" }
                                                                    | null
                                                                  target?:
                                                                    | { __typename: "CommentNode" }
                                                                    | {
                                                                        __typename: "ExternalSite"
                                                                        id: string
                                                                        teaserData?: {
                                                                          __typename: "ExternalLinkTeaserData"
                                                                          title?: string | null
                                                                          summary?: string | null
                                                                          iconUrl?: string | null
                                                                          imageUrl?: string | null
                                                                          status?: Types.LinkTargetStatus | null
                                                                        } | null
                                                                      }
                                                                    | { __typename: "ST4Class" }
                                                                    | {
                                                                        __typename: "ST4Node"
                                                                        id: string
                                                                        aspectId: any
                                                                        label?: string | null
                                                                        properties?: {
                                                                          __typename: "ContentPropertyConnection"
                                                                          edges?: Array<{
                                                                            __typename: "ContentPropertyEdge"
                                                                            node?: {
                                                                              __typename: "ContentProperty"
                                                                              name: string
                                                                              value?: any | null
                                                                              displayName: string
                                                                            } | null
                                                                          } | null> | null
                                                                        } | null
                                                                      }
                                                                    | { __typename: "ST4TreeNode" }
                                                                    | null
                                                                }
                                                              | {
                                                                  __typename: "NodeRef"
                                                                  id: string
                                                                  owner?:
                                                                    | { __typename: "CommentNode" }
                                                                    | { __typename: "ExternalSite" }
                                                                    | { __typename: "ST4Class" }
                                                                    | { __typename: "ST4Node"; id: string }
                                                                    | { __typename: "ST4TreeNode" }
                                                                    | null
                                                                  target?:
                                                                    | { __typename: "CommentNode" }
                                                                    | {
                                                                        __typename: "ExternalSite"
                                                                        id: string
                                                                        teaserData?: {
                                                                          __typename: "ExternalLinkTeaserData"
                                                                          title?: string | null
                                                                          summary?: string | null
                                                                          iconUrl?: string | null
                                                                          imageUrl?: string | null
                                                                          status?: Types.LinkTargetStatus | null
                                                                        } | null
                                                                      }
                                                                    | { __typename: "ST4Class" }
                                                                    | {
                                                                        __typename: "ST4Node"
                                                                        id: string
                                                                        aspectId: any
                                                                        label?: string | null
                                                                        properties?: {
                                                                          __typename: "ContentPropertyConnection"
                                                                          edges?: Array<{
                                                                            __typename: "ContentPropertyEdge"
                                                                            node?: {
                                                                              __typename: "ContentProperty"
                                                                              name: string
                                                                              value?: any | null
                                                                              displayName: string
                                                                            } | null
                                                                          } | null> | null
                                                                        } | null
                                                                      }
                                                                    | { __typename: "ST4TreeNode" }
                                                                    | null
                                                                }
                                                              | null
                                                          }>
                                                        }
                                                      | {
                                                          __typename: "VideoMediaInfo"
                                                          id: string
                                                          resourceId?: string | null
                                                          thumbnail?: string | null
                                                          width?: number | null
                                                          height?: number | null
                                                          mediaType?: Types.MediaType | null
                                                        }
                                                      | null
                                                  }
                                                | {
                                                    __typename: "MediaGroupContent"
                                                    media?:
                                                      | {
                                                          __typename: "AnimationMediaInfo"
                                                          id: string
                                                          resourceId?: string | null
                                                          thumbnail?: string | null
                                                          width?: number | null
                                                          height?: number | null
                                                          mediaType?: Types.MediaType | null
                                                        }
                                                      | {
                                                          __typename: "ImageMediaInfo"
                                                          id: string
                                                          resourceId?: string | null
                                                          thumbnail?: string | null
                                                          width?: number | null
                                                          height?: number | null
                                                          resolution?: number | null
                                                          colorDepth?: number | null
                                                          size?: number | null
                                                          mime?: string | null
                                                          extension?: string | null
                                                          allowCaching?: boolean | null
                                                          mediaType?: Types.MediaType | null
                                                          src?: string | null
                                                          language?: string | null
                                                        }
                                                      | {
                                                          __typename: "NoMediaInfo"
                                                          id: string
                                                          mediaType?: Types.MediaType | null
                                                        }
                                                      | {
                                                          __typename: "VectorGraphicsMediaInfo"
                                                          id: string
                                                          resourceId?: string | null
                                                          thumbnail?: string | null
                                                          width?: number | null
                                                          height?: number | null
                                                          allowCaching?: boolean | null
                                                          mediaType?: Types.MediaType | null
                                                          language?: string | null
                                                          labels: Array<{
                                                            __typename: "VectorGraphicsLabel"
                                                            id?: string | null
                                                            sequence?: number | null
                                                            autoValue?: string | null
                                                            text?: string | null
                                                            linkType?: Types.VectorGraphicsLabelLinkType | null
                                                            link?: string | null
                                                            ref?:
                                                              | {
                                                                  __typename: "GenericRef"
                                                                  id: string
                                                                  owner?:
                                                                    | { __typename: "CommentNode" }
                                                                    | { __typename: "ExternalSite" }
                                                                    | { __typename: "ST4Class" }
                                                                    | { __typename: "ST4Node"; id: string }
                                                                    | { __typename: "ST4TreeNode" }
                                                                    | null
                                                                  target?:
                                                                    | { __typename: "CommentNode" }
                                                                    | {
                                                                        __typename: "ExternalSite"
                                                                        id: string
                                                                        teaserData?: {
                                                                          __typename: "ExternalLinkTeaserData"
                                                                          title?: string | null
                                                                          summary?: string | null
                                                                          iconUrl?: string | null
                                                                          imageUrl?: string | null
                                                                          status?: Types.LinkTargetStatus | null
                                                                        } | null
                                                                      }
                                                                    | { __typename: "ST4Class" }
                                                                    | {
                                                                        __typename: "ST4Node"
                                                                        id: string
                                                                        aspectId: any
                                                                        label?: string | null
                                                                        properties?: {
                                                                          __typename: "ContentPropertyConnection"
                                                                          edges?: Array<{
                                                                            __typename: "ContentPropertyEdge"
                                                                            node?: {
                                                                              __typename: "ContentProperty"
                                                                              name: string
                                                                              value?: any | null
                                                                              displayName: string
                                                                            } | null
                                                                          } | null> | null
                                                                        } | null
                                                                      }
                                                                    | { __typename: "ST4TreeNode" }
                                                                    | null
                                                                }
                                                              | {
                                                                  __typename: "NodeRef"
                                                                  id: string
                                                                  owner?:
                                                                    | { __typename: "CommentNode" }
                                                                    | { __typename: "ExternalSite" }
                                                                    | { __typename: "ST4Class" }
                                                                    | { __typename: "ST4Node"; id: string }
                                                                    | { __typename: "ST4TreeNode" }
                                                                    | null
                                                                  target?:
                                                                    | { __typename: "CommentNode" }
                                                                    | {
                                                                        __typename: "ExternalSite"
                                                                        id: string
                                                                        teaserData?: {
                                                                          __typename: "ExternalLinkTeaserData"
                                                                          title?: string | null
                                                                          summary?: string | null
                                                                          iconUrl?: string | null
                                                                          imageUrl?: string | null
                                                                          status?: Types.LinkTargetStatus | null
                                                                        } | null
                                                                      }
                                                                    | { __typename: "ST4Class" }
                                                                    | {
                                                                        __typename: "ST4Node"
                                                                        id: string
                                                                        aspectId: any
                                                                        label?: string | null
                                                                        properties?: {
                                                                          __typename: "ContentPropertyConnection"
                                                                          edges?: Array<{
                                                                            __typename: "ContentPropertyEdge"
                                                                            node?: {
                                                                              __typename: "ContentProperty"
                                                                              name: string
                                                                              value?: any | null
                                                                              displayName: string
                                                                            } | null
                                                                          } | null> | null
                                                                        } | null
                                                                      }
                                                                    | { __typename: "ST4TreeNode" }
                                                                    | null
                                                                }
                                                              | null
                                                          }>
                                                        }
                                                      | {
                                                          __typename: "VideoMediaInfo"
                                                          id: string
                                                          resourceId?: string | null
                                                          thumbnail?: string | null
                                                          width?: number | null
                                                          height?: number | null
                                                          mediaType?: Types.MediaType | null
                                                        }
                                                      | null
                                                  }
                                                | { __typename: "ProjectContent" }
                                                | { __typename: "TermContent" }
                                                | { __typename: "TextContent" }
                                                | { __typename: "TextGroupContent" }
                                                | { __typename: "VariableTableContent" }
                                                | null
                                            }
                                          | { __typename: "ST4TreeNode"; id: string }
                                          | null
                                      }>
                                      media?:
                                        | {
                                            __typename: "AnimationMediaInfo"
                                            id: string
                                            resourceId?: string | null
                                            thumbnail?: string | null
                                            width?: number | null
                                            height?: number | null
                                            mediaType?: Types.MediaType | null
                                          }
                                        | {
                                            __typename: "ImageMediaInfo"
                                            id: string
                                            resourceId?: string | null
                                            thumbnail?: string | null
                                            width?: number | null
                                            height?: number | null
                                            resolution?: number | null
                                            colorDepth?: number | null
                                            size?: number | null
                                            mime?: string | null
                                            extension?: string | null
                                            allowCaching?: boolean | null
                                            mediaType?: Types.MediaType | null
                                            src?: string | null
                                            language?: string | null
                                          }
                                        | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                                        | {
                                            __typename: "VectorGraphicsMediaInfo"
                                            id: string
                                            resourceId?: string | null
                                            thumbnail?: string | null
                                            width?: number | null
                                            height?: number | null
                                            allowCaching?: boolean | null
                                            mediaType?: Types.MediaType | null
                                            language?: string | null
                                            labels: Array<{
                                              __typename: "VectorGraphicsLabel"
                                              id?: string | null
                                              sequence?: number | null
                                              autoValue?: string | null
                                              text?: string | null
                                              linkType?: Types.VectorGraphicsLabelLinkType | null
                                              link?: string | null
                                              ref?:
                                                | {
                                                    __typename: "GenericRef"
                                                    id: string
                                                    owner?:
                                                      | { __typename: "CommentNode" }
                                                      | { __typename: "ExternalSite" }
                                                      | { __typename: "ST4Class" }
                                                      | { __typename: "ST4Node"; id: string }
                                                      | { __typename: "ST4TreeNode" }
                                                      | null
                                                    target?:
                                                      | { __typename: "CommentNode" }
                                                      | {
                                                          __typename: "ExternalSite"
                                                          id: string
                                                          teaserData?: {
                                                            __typename: "ExternalLinkTeaserData"
                                                            title?: string | null
                                                            summary?: string | null
                                                            iconUrl?: string | null
                                                            imageUrl?: string | null
                                                            status?: Types.LinkTargetStatus | null
                                                          } | null
                                                        }
                                                      | { __typename: "ST4Class" }
                                                      | {
                                                          __typename: "ST4Node"
                                                          id: string
                                                          aspectId: any
                                                          label?: string | null
                                                          properties?: {
                                                            __typename: "ContentPropertyConnection"
                                                            edges?: Array<{
                                                              __typename: "ContentPropertyEdge"
                                                              node?: {
                                                                __typename: "ContentProperty"
                                                                name: string
                                                                value?: any | null
                                                                displayName: string
                                                              } | null
                                                            } | null> | null
                                                          } | null
                                                        }
                                                      | { __typename: "ST4TreeNode" }
                                                      | null
                                                  }
                                                | {
                                                    __typename: "NodeRef"
                                                    id: string
                                                    owner?:
                                                      | { __typename: "CommentNode" }
                                                      | { __typename: "ExternalSite" }
                                                      | { __typename: "ST4Class" }
                                                      | { __typename: "ST4Node"; id: string }
                                                      | { __typename: "ST4TreeNode" }
                                                      | null
                                                    target?:
                                                      | { __typename: "CommentNode" }
                                                      | {
                                                          __typename: "ExternalSite"
                                                          id: string
                                                          teaserData?: {
                                                            __typename: "ExternalLinkTeaserData"
                                                            title?: string | null
                                                            summary?: string | null
                                                            iconUrl?: string | null
                                                            imageUrl?: string | null
                                                            status?: Types.LinkTargetStatus | null
                                                          } | null
                                                        }
                                                      | { __typename: "ST4Class" }
                                                      | {
                                                          __typename: "ST4Node"
                                                          id: string
                                                          aspectId: any
                                                          label?: string | null
                                                          properties?: {
                                                            __typename: "ContentPropertyConnection"
                                                            edges?: Array<{
                                                              __typename: "ContentPropertyEdge"
                                                              node?: {
                                                                __typename: "ContentProperty"
                                                                name: string
                                                                value?: any | null
                                                                displayName: string
                                                              } | null
                                                            } | null> | null
                                                          } | null
                                                        }
                                                      | { __typename: "ST4TreeNode" }
                                                      | null
                                                  }
                                                | null
                                            }>
                                          }
                                        | {
                                            __typename: "VideoMediaInfo"
                                            id: string
                                            resourceId?: string | null
                                            thumbnail?: string | null
                                            width?: number | null
                                            height?: number | null
                                            mediaType?: Types.MediaType | null
                                          }
                                        | null
                                    }
                                  | { __typename: "ProjectContent" }
                                  | { __typename: "TermContent" }
                                  | { __typename: "TextContent" }
                                  | { __typename: "TextGroupContent" }
                                  | { __typename: "VariableTableContent" }
                                  | null
                              }
                            | { __typename: "ST4TreeNode"; id: string }
                            | null
                        }>
                        variableTables?: Array<{
                          __typename: "VariableTableReference"
                          ref?: string | null
                          node?:
                            | { __typename: "CommentNode" }
                            | { __typename: "ExternalSite" }
                            | { __typename: "ST4Class" }
                            | {
                                __typename: "ST4Node"
                                id: string
                                label?: string | null
                                aspectId: any
                                content?:
                                  | { __typename: "LexiconContent" }
                                  | { __typename: "LexiconEntryContent" }
                                  | { __typename: "MediaContent" }
                                  | { __typename: "MediaGroupContent" }
                                  | { __typename: "ProjectContent" }
                                  | { __typename: "TermContent" }
                                  | { __typename: "TextContent" }
                                  | { __typename: "TextGroupContent" }
                                  | {
                                      __typename: "VariableTableContent"
                                      xml?: string | null
                                      valueSets: Array<{ __typename: "ValueSet"; name: string; valueSetId: string }>
                                      variables: Array<{
                                        __typename: "Variable"
                                        name: string
                                        variableId: string
                                        values: Array<string>
                                      }>
                                    }
                                  | null
                              }
                            | { __typename: "ST4TreeNode" }
                            | null
                        } | null> | null
                        linkedDataNodes: Array<{
                          __typename: "NodeRef"
                          id: string
                          target?:
                            | { __typename: "CommentNode"; id: string }
                            | { __typename: "ExternalSite"; id: string }
                            | { __typename: "ST4Class"; id: string }
                            | {
                                __typename: "ST4Node"
                                label?: string | null
                                id: string
                                metadata: Array<{
                                  __typename: "MetaDataEntry"
                                  label?: string | null
                                  systemName: string
                                  value?: string | null
                                  values: Array<string>
                                  users: Array<string>
                                }>
                                linkedOntologyNodes: Array<
                                  | {
                                      __typename: "GenericRef"
                                      target?:
                                        | { __typename: "CommentNode" }
                                        | { __typename: "ExternalSite" }
                                        | { __typename: "ST4Class" }
                                        | { __typename: "ST4Node"; id: string; label?: string | null }
                                        | { __typename: "ST4TreeNode" }
                                        | null
                                    }
                                  | {
                                      __typename: "NodeRef"
                                      linkClassName: string
                                      class?: { __typename: "ST4Class"; label?: string | null } | null
                                      target?:
                                        | { __typename: "CommentNode" }
                                        | { __typename: "ExternalSite" }
                                        | { __typename: "ST4Class" }
                                        | { __typename: "ST4Node"; id: string; label?: string | null }
                                        | { __typename: "ST4TreeNode" }
                                        | null
                                    }
                                >
                              }
                            | { __typename: "ST4TreeNode"; id: string }
                            | null
                        }>
                        lexiconEntries?: Array<{
                          __typename: "NodeRef"
                          id: string
                          target?:
                            | { __typename: "CommentNode"; id: string }
                            | { __typename: "ExternalSite"; id: string }
                            | { __typename: "ST4Class"; id: string }
                            | {
                                __typename: "ST4Node"
                                aspectId: any
                                label?: string | null
                                id: string
                                content?:
                                  | { __typename: "LexiconContent" }
                                  | {
                                      __typename: "LexiconEntryContent"
                                      descriptionXast?: string | null
                                      subject?: string | null
                                      sortKey?: string | null
                                      lexicon:
                                        | { __typename: "CommentNode"; id: string }
                                        | { __typename: "ExternalSite"; id: string }
                                        | { __typename: "ST4Class"; id: string }
                                        | { __typename: "ST4Node"; label?: string | null; id: string }
                                        | { __typename: "ST4TreeNode"; id: string }
                                      terms: Array<
                                        | { __typename: "CommentNode"; id: string }
                                        | { __typename: "ExternalSite"; id: string }
                                        | { __typename: "ST4Class"; id: string }
                                        | {
                                            __typename: "ST4Node"
                                            id: string
                                            label?: string | null
                                            aspectId: any
                                            content?:
                                              | { __typename: "LexiconContent" }
                                              | { __typename: "LexiconEntryContent" }
                                              | { __typename: "MediaContent" }
                                              | { __typename: "MediaGroupContent" }
                                              | { __typename: "ProjectContent" }
                                              | {
                                                  __typename: "TermContent"
                                                  gramaticalGender?: string | null
                                                  validity?: string | null
                                                  editLanguage?: string | null
                                                }
                                              | { __typename: "TextContent" }
                                              | { __typename: "TextGroupContent" }
                                              | { __typename: "VariableTableContent" }
                                              | null
                                          }
                                        | { __typename: "ST4TreeNode"; id: string }
                                      >
                                      image?:
                                        | { __typename: "CommentNode"; id: string }
                                        | { __typename: "ExternalSite"; id: string }
                                        | { __typename: "ST4Class"; id: string }
                                        | {
                                            __typename: "ST4Node"
                                            aspectId: any
                                            id: string
                                            content?:
                                              | { __typename: "LexiconContent" }
                                              | { __typename: "LexiconEntryContent" }
                                              | {
                                                  __typename: "MediaContent"
                                                  media?:
                                                    | {
                                                        __typename: "AnimationMediaInfo"
                                                        thumbnail?: string | null
                                                        mediaType?: Types.MediaType | null
                                                      }
                                                    | {
                                                        __typename: "ImageMediaInfo"
                                                        src?: string | null
                                                        thumbnail?: string | null
                                                        mediaType?: Types.MediaType | null
                                                      }
                                                    | { __typename: "NoMediaInfo" }
                                                    | {
                                                        __typename: "VectorGraphicsMediaInfo"
                                                        svg?: string | null
                                                        thumbnail?: string | null
                                                        mediaType?: Types.MediaType | null
                                                      }
                                                    | {
                                                        __typename: "VideoMediaInfo"
                                                        thumbnail?: string | null
                                                        mediaType?: Types.MediaType | null
                                                      }
                                                    | null
                                                }
                                              | { __typename: "MediaGroupContent" }
                                              | { __typename: "ProjectContent" }
                                              | { __typename: "TermContent" }
                                              | { __typename: "TextContent" }
                                              | { __typename: "TextGroupContent" }
                                              | { __typename: "VariableTableContent" }
                                              | null
                                          }
                                        | { __typename: "ST4TreeNode"; id: string }
                                        | null
                                    }
                                  | { __typename: "MediaContent" }
                                  | { __typename: "MediaGroupContent" }
                                  | { __typename: "ProjectContent" }
                                  | { __typename: "TermContent" }
                                  | { __typename: "TextContent" }
                                  | { __typename: "TextGroupContent" }
                                  | { __typename: "VariableTableContent" }
                                  | null
                              }
                            | { __typename: "ST4TreeNode"; id: string }
                            | null
                        } | null> | null
                        comments: Array<{
                          __typename: "CommentNode"
                          id: string
                          comment:
                            | {
                                __typename: "BlockComment"
                                location: number
                                value?: string | null
                                authorDisplayName?: string | null
                                authorUserName?: string | null
                                commentKey: string
                                editDate?: any | null
                                modificationVersion?: number | null
                                replyToKey?: string | null
                                sortOrder?: number | null
                                commentType?: string | null
                                state?: string | null
                                color: string
                                persistanceState?: Types.PersistanceState | null
                                persistanceAction?: Types.PersistanceAction | null
                                node: {
                                  __typename: "ST4Node"
                                  aspectId: any
                                  id: string
                                  content?:
                                    | { __typename: "LexiconContent" }
                                    | { __typename: "LexiconEntryContent" }
                                    | { __typename: "MediaContent" }
                                    | { __typename: "MediaGroupContent" }
                                    | { __typename: "ProjectContent" }
                                    | { __typename: "TermContent" }
                                    | { __typename: "TextContent"; id: string; historyNumber: number }
                                    | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                                    | { __typename: "VariableTableContent" }
                                    | null
                                }
                                actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
                              }
                            | {
                                __typename: "TextComment"
                                endLocation: number
                                startLocation?: number | null
                                value?: string | null
                                authorDisplayName?: string | null
                                authorUserName?: string | null
                                commentKey: string
                                editDate?: any | null
                                modificationVersion?: number | null
                                replyToKey?: string | null
                                sortOrder?: number | null
                                commentType?: string | null
                                state?: string | null
                                color: string
                                persistanceState?: Types.PersistanceState | null
                                persistanceAction?: Types.PersistanceAction | null
                                node: {
                                  __typename: "ST4Node"
                                  aspectId: any
                                  id: string
                                  content?:
                                    | { __typename: "LexiconContent" }
                                    | { __typename: "LexiconEntryContent" }
                                    | { __typename: "MediaContent" }
                                    | { __typename: "MediaGroupContent" }
                                    | { __typename: "ProjectContent" }
                                    | { __typename: "TermContent" }
                                    | { __typename: "TextContent"; id: string; historyNumber: number }
                                    | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                                    | { __typename: "VariableTableContent" }
                                    | null
                                }
                                actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
                              }
                        }>
                      }
                    | { __typename: "VariableTableContent" }
                    | null
                }
              }>
              links?: Array<{
                __typename: "TextContentLink"
                ref?: string | null
                node?:
                  | { __typename: "CommentNode"; id: string }
                  | { __typename: "ExternalSite"; id: string }
                  | { __typename: "ST4Class"; id: string }
                  | {
                      __typename: "ST4Node"
                      id: string
                      aspectId: any
                      label?: string | null
                      properties?: {
                        __typename: "ContentPropertyConnection"
                        totalCount: number
                        edges?: Array<{
                          __typename: "ContentPropertyEdge"
                          node?: {
                            __typename: "ContentProperty"
                            typeOfValue: Types.ContentPropertyValueType
                            name: string
                            value?: any | null
                            displayName: string
                          } | null
                        } | null> | null
                      } | null
                    }
                  | { __typename: "ST4TreeNode"; id: string }
                  | null
              } | null> | null
              images: Array<{
                __typename: "NodeRef"
                id: string
                target?:
                  | { __typename: "CommentNode"; id: string }
                  | { __typename: "ExternalSite"; id: string }
                  | { __typename: "ST4Class"; id: string }
                  | {
                      __typename: "ST4Node"
                      aspectId: any
                      id: string
                      releaseState: {
                        __typename: "ReleaseStateValue"
                        displayName: string
                        state: Types.ReleaseState
                        isImplicitlyReleased: boolean
                      }
                      content?:
                        | { __typename: "LexiconContent" }
                        | { __typename: "LexiconEntryContent" }
                        | {
                            __typename: "MediaContent"
                            media?:
                              | {
                                  __typename: "AnimationMediaInfo"
                                  id: string
                                  resourceId?: string | null
                                  thumbnail?: string | null
                                  width?: number | null
                                  height?: number | null
                                  mediaType?: Types.MediaType | null
                                }
                              | {
                                  __typename: "ImageMediaInfo"
                                  id: string
                                  resourceId?: string | null
                                  thumbnail?: string | null
                                  width?: number | null
                                  height?: number | null
                                  resolution?: number | null
                                  colorDepth?: number | null
                                  size?: number | null
                                  mime?: string | null
                                  extension?: string | null
                                  allowCaching?: boolean | null
                                  mediaType?: Types.MediaType | null
                                  src?: string | null
                                  language?: string | null
                                }
                              | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                              | {
                                  __typename: "VectorGraphicsMediaInfo"
                                  id: string
                                  resourceId?: string | null
                                  thumbnail?: string | null
                                  width?: number | null
                                  height?: number | null
                                  allowCaching?: boolean | null
                                  mediaType?: Types.MediaType | null
                                  language?: string | null
                                  labels: Array<{
                                    __typename: "VectorGraphicsLabel"
                                    id?: string | null
                                    sequence?: number | null
                                    autoValue?: string | null
                                    text?: string | null
                                    linkType?: Types.VectorGraphicsLabelLinkType | null
                                    link?: string | null
                                    ref?:
                                      | {
                                          __typename: "GenericRef"
                                          id: string
                                          owner?:
                                            | { __typename: "CommentNode" }
                                            | { __typename: "ExternalSite" }
                                            | { __typename: "ST4Class" }
                                            | { __typename: "ST4Node"; id: string }
                                            | { __typename: "ST4TreeNode" }
                                            | null
                                          target?:
                                            | { __typename: "CommentNode" }
                                            | {
                                                __typename: "ExternalSite"
                                                id: string
                                                teaserData?: {
                                                  __typename: "ExternalLinkTeaserData"
                                                  title?: string | null
                                                  summary?: string | null
                                                  iconUrl?: string | null
                                                  imageUrl?: string | null
                                                  status?: Types.LinkTargetStatus | null
                                                } | null
                                              }
                                            | { __typename: "ST4Class" }
                                            | {
                                                __typename: "ST4Node"
                                                id: string
                                                aspectId: any
                                                label?: string | null
                                                properties?: {
                                                  __typename: "ContentPropertyConnection"
                                                  edges?: Array<{
                                                    __typename: "ContentPropertyEdge"
                                                    node?: {
                                                      __typename: "ContentProperty"
                                                      name: string
                                                      value?: any | null
                                                      displayName: string
                                                    } | null
                                                  } | null> | null
                                                } | null
                                              }
                                            | { __typename: "ST4TreeNode" }
                                            | null
                                        }
                                      | {
                                          __typename: "NodeRef"
                                          id: string
                                          owner?:
                                            | { __typename: "CommentNode" }
                                            | { __typename: "ExternalSite" }
                                            | { __typename: "ST4Class" }
                                            | { __typename: "ST4Node"; id: string }
                                            | { __typename: "ST4TreeNode" }
                                            | null
                                          target?:
                                            | { __typename: "CommentNode" }
                                            | {
                                                __typename: "ExternalSite"
                                                id: string
                                                teaserData?: {
                                                  __typename: "ExternalLinkTeaserData"
                                                  title?: string | null
                                                  summary?: string | null
                                                  iconUrl?: string | null
                                                  imageUrl?: string | null
                                                  status?: Types.LinkTargetStatus | null
                                                } | null
                                              }
                                            | { __typename: "ST4Class" }
                                            | {
                                                __typename: "ST4Node"
                                                id: string
                                                aspectId: any
                                                label?: string | null
                                                properties?: {
                                                  __typename: "ContentPropertyConnection"
                                                  edges?: Array<{
                                                    __typename: "ContentPropertyEdge"
                                                    node?: {
                                                      __typename: "ContentProperty"
                                                      name: string
                                                      value?: any | null
                                                      displayName: string
                                                    } | null
                                                  } | null> | null
                                                } | null
                                              }
                                            | { __typename: "ST4TreeNode" }
                                            | null
                                        }
                                      | null
                                  }>
                                }
                              | {
                                  __typename: "VideoMediaInfo"
                                  id: string
                                  resourceId?: string | null
                                  thumbnail?: string | null
                                  width?: number | null
                                  height?: number | null
                                  mediaType?: Types.MediaType | null
                                }
                              | null
                          }
                        | {
                            __typename: "MediaGroupContent"
                            children: Array<{
                              __typename: "NodeRef"
                              id: string
                              target?:
                                | { __typename: "CommentNode"; id: string }
                                | { __typename: "ExternalSite"; id: string }
                                | { __typename: "ST4Class"; id: string }
                                | {
                                    __typename: "ST4Node"
                                    id: string
                                    aspectId: any
                                    content?:
                                      | { __typename: "LexiconContent" }
                                      | { __typename: "LexiconEntryContent" }
                                      | {
                                          __typename: "MediaContent"
                                          media?:
                                            | {
                                                __typename: "AnimationMediaInfo"
                                                id: string
                                                resourceId?: string | null
                                                thumbnail?: string | null
                                                width?: number | null
                                                height?: number | null
                                                mediaType?: Types.MediaType | null
                                              }
                                            | {
                                                __typename: "ImageMediaInfo"
                                                id: string
                                                resourceId?: string | null
                                                thumbnail?: string | null
                                                width?: number | null
                                                height?: number | null
                                                resolution?: number | null
                                                colorDepth?: number | null
                                                size?: number | null
                                                mime?: string | null
                                                extension?: string | null
                                                allowCaching?: boolean | null
                                                mediaType?: Types.MediaType | null
                                                src?: string | null
                                                language?: string | null
                                              }
                                            | {
                                                __typename: "NoMediaInfo"
                                                id: string
                                                mediaType?: Types.MediaType | null
                                              }
                                            | {
                                                __typename: "VectorGraphicsMediaInfo"
                                                id: string
                                                resourceId?: string | null
                                                thumbnail?: string | null
                                                width?: number | null
                                                height?: number | null
                                                allowCaching?: boolean | null
                                                mediaType?: Types.MediaType | null
                                                language?: string | null
                                                labels: Array<{
                                                  __typename: "VectorGraphicsLabel"
                                                  id?: string | null
                                                  sequence?: number | null
                                                  autoValue?: string | null
                                                  text?: string | null
                                                  linkType?: Types.VectorGraphicsLabelLinkType | null
                                                  link?: string | null
                                                  ref?:
                                                    | {
                                                        __typename: "GenericRef"
                                                        id: string
                                                        owner?:
                                                          | { __typename: "CommentNode" }
                                                          | { __typename: "ExternalSite" }
                                                          | { __typename: "ST4Class" }
                                                          | { __typename: "ST4Node"; id: string }
                                                          | { __typename: "ST4TreeNode" }
                                                          | null
                                                        target?:
                                                          | { __typename: "CommentNode" }
                                                          | {
                                                              __typename: "ExternalSite"
                                                              id: string
                                                              teaserData?: {
                                                                __typename: "ExternalLinkTeaserData"
                                                                title?: string | null
                                                                summary?: string | null
                                                                iconUrl?: string | null
                                                                imageUrl?: string | null
                                                                status?: Types.LinkTargetStatus | null
                                                              } | null
                                                            }
                                                          | { __typename: "ST4Class" }
                                                          | {
                                                              __typename: "ST4Node"
                                                              id: string
                                                              aspectId: any
                                                              label?: string | null
                                                              properties?: {
                                                                __typename: "ContentPropertyConnection"
                                                                edges?: Array<{
                                                                  __typename: "ContentPropertyEdge"
                                                                  node?: {
                                                                    __typename: "ContentProperty"
                                                                    name: string
                                                                    value?: any | null
                                                                    displayName: string
                                                                  } | null
                                                                } | null> | null
                                                              } | null
                                                            }
                                                          | { __typename: "ST4TreeNode" }
                                                          | null
                                                      }
                                                    | {
                                                        __typename: "NodeRef"
                                                        id: string
                                                        owner?:
                                                          | { __typename: "CommentNode" }
                                                          | { __typename: "ExternalSite" }
                                                          | { __typename: "ST4Class" }
                                                          | { __typename: "ST4Node"; id: string }
                                                          | { __typename: "ST4TreeNode" }
                                                          | null
                                                        target?:
                                                          | { __typename: "CommentNode" }
                                                          | {
                                                              __typename: "ExternalSite"
                                                              id: string
                                                              teaserData?: {
                                                                __typename: "ExternalLinkTeaserData"
                                                                title?: string | null
                                                                summary?: string | null
                                                                iconUrl?: string | null
                                                                imageUrl?: string | null
                                                                status?: Types.LinkTargetStatus | null
                                                              } | null
                                                            }
                                                          | { __typename: "ST4Class" }
                                                          | {
                                                              __typename: "ST4Node"
                                                              id: string
                                                              aspectId: any
                                                              label?: string | null
                                                              properties?: {
                                                                __typename: "ContentPropertyConnection"
                                                                edges?: Array<{
                                                                  __typename: "ContentPropertyEdge"
                                                                  node?: {
                                                                    __typename: "ContentProperty"
                                                                    name: string
                                                                    value?: any | null
                                                                    displayName: string
                                                                  } | null
                                                                } | null> | null
                                                              } | null
                                                            }
                                                          | { __typename: "ST4TreeNode" }
                                                          | null
                                                      }
                                                    | null
                                                }>
                                              }
                                            | {
                                                __typename: "VideoMediaInfo"
                                                id: string
                                                resourceId?: string | null
                                                thumbnail?: string | null
                                                width?: number | null
                                                height?: number | null
                                                mediaType?: Types.MediaType | null
                                              }
                                            | null
                                        }
                                      | {
                                          __typename: "MediaGroupContent"
                                          media?:
                                            | {
                                                __typename: "AnimationMediaInfo"
                                                id: string
                                                resourceId?: string | null
                                                thumbnail?: string | null
                                                width?: number | null
                                                height?: number | null
                                                mediaType?: Types.MediaType | null
                                              }
                                            | {
                                                __typename: "ImageMediaInfo"
                                                id: string
                                                resourceId?: string | null
                                                thumbnail?: string | null
                                                width?: number | null
                                                height?: number | null
                                                resolution?: number | null
                                                colorDepth?: number | null
                                                size?: number | null
                                                mime?: string | null
                                                extension?: string | null
                                                allowCaching?: boolean | null
                                                mediaType?: Types.MediaType | null
                                                src?: string | null
                                                language?: string | null
                                              }
                                            | {
                                                __typename: "NoMediaInfo"
                                                id: string
                                                mediaType?: Types.MediaType | null
                                              }
                                            | {
                                                __typename: "VectorGraphicsMediaInfo"
                                                id: string
                                                resourceId?: string | null
                                                thumbnail?: string | null
                                                width?: number | null
                                                height?: number | null
                                                allowCaching?: boolean | null
                                                mediaType?: Types.MediaType | null
                                                language?: string | null
                                                labels: Array<{
                                                  __typename: "VectorGraphicsLabel"
                                                  id?: string | null
                                                  sequence?: number | null
                                                  autoValue?: string | null
                                                  text?: string | null
                                                  linkType?: Types.VectorGraphicsLabelLinkType | null
                                                  link?: string | null
                                                  ref?:
                                                    | {
                                                        __typename: "GenericRef"
                                                        id: string
                                                        owner?:
                                                          | { __typename: "CommentNode" }
                                                          | { __typename: "ExternalSite" }
                                                          | { __typename: "ST4Class" }
                                                          | { __typename: "ST4Node"; id: string }
                                                          | { __typename: "ST4TreeNode" }
                                                          | null
                                                        target?:
                                                          | { __typename: "CommentNode" }
                                                          | {
                                                              __typename: "ExternalSite"
                                                              id: string
                                                              teaserData?: {
                                                                __typename: "ExternalLinkTeaserData"
                                                                title?: string | null
                                                                summary?: string | null
                                                                iconUrl?: string | null
                                                                imageUrl?: string | null
                                                                status?: Types.LinkTargetStatus | null
                                                              } | null
                                                            }
                                                          | { __typename: "ST4Class" }
                                                          | {
                                                              __typename: "ST4Node"
                                                              id: string
                                                              aspectId: any
                                                              label?: string | null
                                                              properties?: {
                                                                __typename: "ContentPropertyConnection"
                                                                edges?: Array<{
                                                                  __typename: "ContentPropertyEdge"
                                                                  node?: {
                                                                    __typename: "ContentProperty"
                                                                    name: string
                                                                    value?: any | null
                                                                    displayName: string
                                                                  } | null
                                                                } | null> | null
                                                              } | null
                                                            }
                                                          | { __typename: "ST4TreeNode" }
                                                          | null
                                                      }
                                                    | {
                                                        __typename: "NodeRef"
                                                        id: string
                                                        owner?:
                                                          | { __typename: "CommentNode" }
                                                          | { __typename: "ExternalSite" }
                                                          | { __typename: "ST4Class" }
                                                          | { __typename: "ST4Node"; id: string }
                                                          | { __typename: "ST4TreeNode" }
                                                          | null
                                                        target?:
                                                          | { __typename: "CommentNode" }
                                                          | {
                                                              __typename: "ExternalSite"
                                                              id: string
                                                              teaserData?: {
                                                                __typename: "ExternalLinkTeaserData"
                                                                title?: string | null
                                                                summary?: string | null
                                                                iconUrl?: string | null
                                                                imageUrl?: string | null
                                                                status?: Types.LinkTargetStatus | null
                                                              } | null
                                                            }
                                                          | { __typename: "ST4Class" }
                                                          | {
                                                              __typename: "ST4Node"
                                                              id: string
                                                              aspectId: any
                                                              label?: string | null
                                                              properties?: {
                                                                __typename: "ContentPropertyConnection"
                                                                edges?: Array<{
                                                                  __typename: "ContentPropertyEdge"
                                                                  node?: {
                                                                    __typename: "ContentProperty"
                                                                    name: string
                                                                    value?: any | null
                                                                    displayName: string
                                                                  } | null
                                                                } | null> | null
                                                              } | null
                                                            }
                                                          | { __typename: "ST4TreeNode" }
                                                          | null
                                                      }
                                                    | null
                                                }>
                                              }
                                            | {
                                                __typename: "VideoMediaInfo"
                                                id: string
                                                resourceId?: string | null
                                                thumbnail?: string | null
                                                width?: number | null
                                                height?: number | null
                                                mediaType?: Types.MediaType | null
                                              }
                                            | null
                                        }
                                      | { __typename: "ProjectContent" }
                                      | { __typename: "TermContent" }
                                      | { __typename: "TextContent" }
                                      | { __typename: "TextGroupContent" }
                                      | { __typename: "VariableTableContent" }
                                      | null
                                  }
                                | { __typename: "ST4TreeNode"; id: string }
                                | null
                            }>
                            media?:
                              | {
                                  __typename: "AnimationMediaInfo"
                                  id: string
                                  resourceId?: string | null
                                  thumbnail?: string | null
                                  width?: number | null
                                  height?: number | null
                                  mediaType?: Types.MediaType | null
                                }
                              | {
                                  __typename: "ImageMediaInfo"
                                  id: string
                                  resourceId?: string | null
                                  thumbnail?: string | null
                                  width?: number | null
                                  height?: number | null
                                  resolution?: number | null
                                  colorDepth?: number | null
                                  size?: number | null
                                  mime?: string | null
                                  extension?: string | null
                                  allowCaching?: boolean | null
                                  mediaType?: Types.MediaType | null
                                  src?: string | null
                                  language?: string | null
                                }
                              | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                              | {
                                  __typename: "VectorGraphicsMediaInfo"
                                  id: string
                                  resourceId?: string | null
                                  thumbnail?: string | null
                                  width?: number | null
                                  height?: number | null
                                  allowCaching?: boolean | null
                                  mediaType?: Types.MediaType | null
                                  language?: string | null
                                  labels: Array<{
                                    __typename: "VectorGraphicsLabel"
                                    id?: string | null
                                    sequence?: number | null
                                    autoValue?: string | null
                                    text?: string | null
                                    linkType?: Types.VectorGraphicsLabelLinkType | null
                                    link?: string | null
                                    ref?:
                                      | {
                                          __typename: "GenericRef"
                                          id: string
                                          owner?:
                                            | { __typename: "CommentNode" }
                                            | { __typename: "ExternalSite" }
                                            | { __typename: "ST4Class" }
                                            | { __typename: "ST4Node"; id: string }
                                            | { __typename: "ST4TreeNode" }
                                            | null
                                          target?:
                                            | { __typename: "CommentNode" }
                                            | {
                                                __typename: "ExternalSite"
                                                id: string
                                                teaserData?: {
                                                  __typename: "ExternalLinkTeaserData"
                                                  title?: string | null
                                                  summary?: string | null
                                                  iconUrl?: string | null
                                                  imageUrl?: string | null
                                                  status?: Types.LinkTargetStatus | null
                                                } | null
                                              }
                                            | { __typename: "ST4Class" }
                                            | {
                                                __typename: "ST4Node"
                                                id: string
                                                aspectId: any
                                                label?: string | null
                                                properties?: {
                                                  __typename: "ContentPropertyConnection"
                                                  edges?: Array<{
                                                    __typename: "ContentPropertyEdge"
                                                    node?: {
                                                      __typename: "ContentProperty"
                                                      name: string
                                                      value?: any | null
                                                      displayName: string
                                                    } | null
                                                  } | null> | null
                                                } | null
                                              }
                                            | { __typename: "ST4TreeNode" }
                                            | null
                                        }
                                      | {
                                          __typename: "NodeRef"
                                          id: string
                                          owner?:
                                            | { __typename: "CommentNode" }
                                            | { __typename: "ExternalSite" }
                                            | { __typename: "ST4Class" }
                                            | { __typename: "ST4Node"; id: string }
                                            | { __typename: "ST4TreeNode" }
                                            | null
                                          target?:
                                            | { __typename: "CommentNode" }
                                            | {
                                                __typename: "ExternalSite"
                                                id: string
                                                teaserData?: {
                                                  __typename: "ExternalLinkTeaserData"
                                                  title?: string | null
                                                  summary?: string | null
                                                  iconUrl?: string | null
                                                  imageUrl?: string | null
                                                  status?: Types.LinkTargetStatus | null
                                                } | null
                                              }
                                            | { __typename: "ST4Class" }
                                            | {
                                                __typename: "ST4Node"
                                                id: string
                                                aspectId: any
                                                label?: string | null
                                                properties?: {
                                                  __typename: "ContentPropertyConnection"
                                                  edges?: Array<{
                                                    __typename: "ContentPropertyEdge"
                                                    node?: {
                                                      __typename: "ContentProperty"
                                                      name: string
                                                      value?: any | null
                                                      displayName: string
                                                    } | null
                                                  } | null> | null
                                                } | null
                                              }
                                            | { __typename: "ST4TreeNode" }
                                            | null
                                        }
                                      | null
                                  }>
                                }
                              | {
                                  __typename: "VideoMediaInfo"
                                  id: string
                                  resourceId?: string | null
                                  thumbnail?: string | null
                                  width?: number | null
                                  height?: number | null
                                  mediaType?: Types.MediaType | null
                                }
                              | null
                          }
                        | { __typename: "ProjectContent" }
                        | { __typename: "TermContent" }
                        | { __typename: "TextContent" }
                        | { __typename: "TextGroupContent" }
                        | { __typename: "VariableTableContent" }
                        | null
                    }
                  | { __typename: "ST4TreeNode"; id: string }
                  | null
              }>
              variableTables?: Array<{
                __typename: "VariableTableReference"
                ref?: string | null
                node?:
                  | { __typename: "CommentNode" }
                  | { __typename: "ExternalSite" }
                  | { __typename: "ST4Class" }
                  | {
                      __typename: "ST4Node"
                      id: string
                      label?: string | null
                      aspectId: any
                      content?:
                        | { __typename: "LexiconContent" }
                        | { __typename: "LexiconEntryContent" }
                        | { __typename: "MediaContent" }
                        | { __typename: "MediaGroupContent" }
                        | { __typename: "ProjectContent" }
                        | { __typename: "TermContent" }
                        | { __typename: "TextContent" }
                        | { __typename: "TextGroupContent" }
                        | {
                            __typename: "VariableTableContent"
                            xml?: string | null
                            valueSets: Array<{ __typename: "ValueSet"; name: string; valueSetId: string }>
                            variables: Array<{
                              __typename: "Variable"
                              name: string
                              variableId: string
                              values: Array<string>
                            }>
                          }
                        | null
                    }
                  | { __typename: "ST4TreeNode" }
                  | null
              } | null> | null
              linkedDataNodes: Array<{
                __typename: "NodeRef"
                id: string
                target?:
                  | { __typename: "CommentNode"; id: string }
                  | { __typename: "ExternalSite"; id: string }
                  | { __typename: "ST4Class"; id: string }
                  | {
                      __typename: "ST4Node"
                      label?: string | null
                      id: string
                      metadata: Array<{
                        __typename: "MetaDataEntry"
                        label?: string | null
                        systemName: string
                        value?: string | null
                        values: Array<string>
                        users: Array<string>
                      }>
                      linkedOntologyNodes: Array<
                        | {
                            __typename: "GenericRef"
                            target?:
                              | { __typename: "CommentNode" }
                              | { __typename: "ExternalSite" }
                              | { __typename: "ST4Class" }
                              | { __typename: "ST4Node"; id: string; label?: string | null }
                              | { __typename: "ST4TreeNode" }
                              | null
                          }
                        | {
                            __typename: "NodeRef"
                            linkClassName: string
                            class?: { __typename: "ST4Class"; label?: string | null } | null
                            target?:
                              | { __typename: "CommentNode" }
                              | { __typename: "ExternalSite" }
                              | { __typename: "ST4Class" }
                              | { __typename: "ST4Node"; id: string; label?: string | null }
                              | { __typename: "ST4TreeNode" }
                              | null
                          }
                      >
                    }
                  | { __typename: "ST4TreeNode"; id: string }
                  | null
              }>
              lexiconEntries?: Array<{
                __typename: "NodeRef"
                id: string
                target?:
                  | { __typename: "CommentNode"; id: string }
                  | { __typename: "ExternalSite"; id: string }
                  | { __typename: "ST4Class"; id: string }
                  | {
                      __typename: "ST4Node"
                      aspectId: any
                      label?: string | null
                      id: string
                      content?:
                        | { __typename: "LexiconContent" }
                        | {
                            __typename: "LexiconEntryContent"
                            descriptionXast?: string | null
                            subject?: string | null
                            sortKey?: string | null
                            lexicon:
                              | { __typename: "CommentNode"; id: string }
                              | { __typename: "ExternalSite"; id: string }
                              | { __typename: "ST4Class"; id: string }
                              | { __typename: "ST4Node"; label?: string | null; id: string }
                              | { __typename: "ST4TreeNode"; id: string }
                            terms: Array<
                              | { __typename: "CommentNode"; id: string }
                              | { __typename: "ExternalSite"; id: string }
                              | { __typename: "ST4Class"; id: string }
                              | {
                                  __typename: "ST4Node"
                                  id: string
                                  label?: string | null
                                  aspectId: any
                                  content?:
                                    | { __typename: "LexiconContent" }
                                    | { __typename: "LexiconEntryContent" }
                                    | { __typename: "MediaContent" }
                                    | { __typename: "MediaGroupContent" }
                                    | { __typename: "ProjectContent" }
                                    | {
                                        __typename: "TermContent"
                                        gramaticalGender?: string | null
                                        validity?: string | null
                                        editLanguage?: string | null
                                      }
                                    | { __typename: "TextContent" }
                                    | { __typename: "TextGroupContent" }
                                    | { __typename: "VariableTableContent" }
                                    | null
                                }
                              | { __typename: "ST4TreeNode"; id: string }
                            >
                            image?:
                              | { __typename: "CommentNode"; id: string }
                              | { __typename: "ExternalSite"; id: string }
                              | { __typename: "ST4Class"; id: string }
                              | {
                                  __typename: "ST4Node"
                                  aspectId: any
                                  id: string
                                  content?:
                                    | { __typename: "LexiconContent" }
                                    | { __typename: "LexiconEntryContent" }
                                    | {
                                        __typename: "MediaContent"
                                        media?:
                                          | {
                                              __typename: "AnimationMediaInfo"
                                              thumbnail?: string | null
                                              mediaType?: Types.MediaType | null
                                            }
                                          | {
                                              __typename: "ImageMediaInfo"
                                              src?: string | null
                                              thumbnail?: string | null
                                              mediaType?: Types.MediaType | null
                                            }
                                          | { __typename: "NoMediaInfo" }
                                          | {
                                              __typename: "VectorGraphicsMediaInfo"
                                              svg?: string | null
                                              thumbnail?: string | null
                                              mediaType?: Types.MediaType | null
                                            }
                                          | {
                                              __typename: "VideoMediaInfo"
                                              thumbnail?: string | null
                                              mediaType?: Types.MediaType | null
                                            }
                                          | null
                                      }
                                    | { __typename: "MediaGroupContent" }
                                    | { __typename: "ProjectContent" }
                                    | { __typename: "TermContent" }
                                    | { __typename: "TextContent" }
                                    | { __typename: "TextGroupContent" }
                                    | { __typename: "VariableTableContent" }
                                    | null
                                }
                              | { __typename: "ST4TreeNode"; id: string }
                              | null
                          }
                        | { __typename: "MediaContent" }
                        | { __typename: "MediaGroupContent" }
                        | { __typename: "ProjectContent" }
                        | { __typename: "TermContent" }
                        | { __typename: "TextContent" }
                        | { __typename: "TextGroupContent" }
                        | { __typename: "VariableTableContent" }
                        | null
                    }
                  | { __typename: "ST4TreeNode"; id: string }
                  | null
              } | null> | null
              comments: Array<{
                __typename: "CommentNode"
                id: string
                comment:
                  | {
                      __typename: "BlockComment"
                      location: number
                      value?: string | null
                      authorDisplayName?: string | null
                      authorUserName?: string | null
                      commentKey: string
                      editDate?: any | null
                      modificationVersion?: number | null
                      replyToKey?: string | null
                      sortOrder?: number | null
                      commentType?: string | null
                      state?: string | null
                      color: string
                      persistanceState?: Types.PersistanceState | null
                      persistanceAction?: Types.PersistanceAction | null
                      node: {
                        __typename: "ST4Node"
                        aspectId: any
                        id: string
                        content?:
                          | { __typename: "LexiconContent" }
                          | { __typename: "LexiconEntryContent" }
                          | { __typename: "MediaContent" }
                          | { __typename: "MediaGroupContent" }
                          | { __typename: "ProjectContent" }
                          | { __typename: "TermContent" }
                          | { __typename: "TextContent"; id: string; historyNumber: number }
                          | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                          | { __typename: "VariableTableContent" }
                          | null
                      }
                      actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
                    }
                  | {
                      __typename: "TextComment"
                      endLocation: number
                      startLocation?: number | null
                      value?: string | null
                      authorDisplayName?: string | null
                      authorUserName?: string | null
                      commentKey: string
                      editDate?: any | null
                      modificationVersion?: number | null
                      replyToKey?: string | null
                      sortOrder?: number | null
                      commentType?: string | null
                      state?: string | null
                      color: string
                      persistanceState?: Types.PersistanceState | null
                      persistanceAction?: Types.PersistanceAction | null
                      node: {
                        __typename: "ST4Node"
                        aspectId: any
                        id: string
                        content?:
                          | { __typename: "LexiconContent" }
                          | { __typename: "LexiconEntryContent" }
                          | { __typename: "MediaContent" }
                          | { __typename: "MediaGroupContent" }
                          | { __typename: "ProjectContent" }
                          | { __typename: "TermContent" }
                          | { __typename: "TextContent"; id: string; historyNumber: number }
                          | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                          | { __typename: "VariableTableContent" }
                          | null
                      }
                      actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
                    }
              }>
            }
          | {
              __typename: "TextGroupContent"
              currentNodeId?: any | null
              groupNodeId: any
              id: string
              xast?: string | null
              historyNumber: number
              fragments: Array<{
                __typename: "TextContentFragment"
                compoundId: string
                contentNode: {
                  __typename: "ST4Node"
                  label?: string | null
                  id: string
                  aspectId: any
                  content?:
                    | { __typename: "LexiconContent" }
                    | { __typename: "LexiconEntryContent" }
                    | { __typename: "MediaContent" }
                    | { __typename: "MediaGroupContent" }
                    | { __typename: "ProjectContent" }
                    | { __typename: "TermContent" }
                    | {
                        __typename: "TextContent"
                        id: string
                        xast?: string | null
                        historyNumber: number
                        parts: Array<{
                          __typename: "TextContentPart"
                          ref: string
                          contentNode: {
                            __typename: "ST4Node"
                            id: string
                            aspectId: any
                            content?:
                              | { __typename: "LexiconContent" }
                              | { __typename: "LexiconEntryContent" }
                              | { __typename: "MediaContent" }
                              | { __typename: "MediaGroupContent" }
                              | { __typename: "ProjectContent" }
                              | { __typename: "TermContent" }
                              | {
                                  __typename: "TextContent"
                                  id: string
                                  xast?: string | null
                                  historyNumber: number
                                  links?: Array<{
                                    __typename: "TextContentLink"
                                    ref?: string | null
                                    node?:
                                      | { __typename: "CommentNode"; id: string }
                                      | { __typename: "ExternalSite"; id: string }
                                      | { __typename: "ST4Class"; id: string }
                                      | {
                                          __typename: "ST4Node"
                                          id: string
                                          aspectId: any
                                          label?: string | null
                                          properties?: {
                                            __typename: "ContentPropertyConnection"
                                            totalCount: number
                                            edges?: Array<{
                                              __typename: "ContentPropertyEdge"
                                              node?: {
                                                __typename: "ContentProperty"
                                                typeOfValue: Types.ContentPropertyValueType
                                                name: string
                                                value?: any | null
                                                displayName: string
                                              } | null
                                            } | null> | null
                                          } | null
                                        }
                                      | { __typename: "ST4TreeNode"; id: string }
                                      | null
                                  } | null> | null
                                  images: Array<{
                                    __typename: "NodeRef"
                                    id: string
                                    target?:
                                      | { __typename: "CommentNode"; id: string }
                                      | { __typename: "ExternalSite"; id: string }
                                      | { __typename: "ST4Class"; id: string }
                                      | {
                                          __typename: "ST4Node"
                                          aspectId: any
                                          id: string
                                          releaseState: {
                                            __typename: "ReleaseStateValue"
                                            displayName: string
                                            state: Types.ReleaseState
                                            isImplicitlyReleased: boolean
                                          }
                                          content?:
                                            | { __typename: "LexiconContent" }
                                            | { __typename: "LexiconEntryContent" }
                                            | {
                                                __typename: "MediaContent"
                                                media?:
                                                  | {
                                                      __typename: "AnimationMediaInfo"
                                                      id: string
                                                      resourceId?: string | null
                                                      thumbnail?: string | null
                                                      width?: number | null
                                                      height?: number | null
                                                      mediaType?: Types.MediaType | null
                                                    }
                                                  | {
                                                      __typename: "ImageMediaInfo"
                                                      id: string
                                                      resourceId?: string | null
                                                      thumbnail?: string | null
                                                      width?: number | null
                                                      height?: number | null
                                                      resolution?: number | null
                                                      colorDepth?: number | null
                                                      size?: number | null
                                                      mime?: string | null
                                                      extension?: string | null
                                                      allowCaching?: boolean | null
                                                      mediaType?: Types.MediaType | null
                                                      src?: string | null
                                                      language?: string | null
                                                    }
                                                  | {
                                                      __typename: "NoMediaInfo"
                                                      id: string
                                                      mediaType?: Types.MediaType | null
                                                    }
                                                  | {
                                                      __typename: "VectorGraphicsMediaInfo"
                                                      id: string
                                                      resourceId?: string | null
                                                      thumbnail?: string | null
                                                      width?: number | null
                                                      height?: number | null
                                                      allowCaching?: boolean | null
                                                      mediaType?: Types.MediaType | null
                                                      language?: string | null
                                                      labels: Array<{
                                                        __typename: "VectorGraphicsLabel"
                                                        id?: string | null
                                                        sequence?: number | null
                                                        autoValue?: string | null
                                                        text?: string | null
                                                        linkType?: Types.VectorGraphicsLabelLinkType | null
                                                        link?: string | null
                                                        ref?:
                                                          | {
                                                              __typename: "GenericRef"
                                                              id: string
                                                              owner?:
                                                                | { __typename: "CommentNode" }
                                                                | { __typename: "ExternalSite" }
                                                                | { __typename: "ST4Class" }
                                                                | { __typename: "ST4Node"; id: string }
                                                                | { __typename: "ST4TreeNode" }
                                                                | null
                                                              target?:
                                                                | { __typename: "CommentNode" }
                                                                | {
                                                                    __typename: "ExternalSite"
                                                                    id: string
                                                                    teaserData?: {
                                                                      __typename: "ExternalLinkTeaserData"
                                                                      title?: string | null
                                                                      summary?: string | null
                                                                      iconUrl?: string | null
                                                                      imageUrl?: string | null
                                                                      status?: Types.LinkTargetStatus | null
                                                                    } | null
                                                                  }
                                                                | { __typename: "ST4Class" }
                                                                | {
                                                                    __typename: "ST4Node"
                                                                    id: string
                                                                    aspectId: any
                                                                    label?: string | null
                                                                    properties?: {
                                                                      __typename: "ContentPropertyConnection"
                                                                      edges?: Array<{
                                                                        __typename: "ContentPropertyEdge"
                                                                        node?: {
                                                                          __typename: "ContentProperty"
                                                                          name: string
                                                                          value?: any | null
                                                                          displayName: string
                                                                        } | null
                                                                      } | null> | null
                                                                    } | null
                                                                  }
                                                                | { __typename: "ST4TreeNode" }
                                                                | null
                                                            }
                                                          | {
                                                              __typename: "NodeRef"
                                                              id: string
                                                              owner?:
                                                                | { __typename: "CommentNode" }
                                                                | { __typename: "ExternalSite" }
                                                                | { __typename: "ST4Class" }
                                                                | { __typename: "ST4Node"; id: string }
                                                                | { __typename: "ST4TreeNode" }
                                                                | null
                                                              target?:
                                                                | { __typename: "CommentNode" }
                                                                | {
                                                                    __typename: "ExternalSite"
                                                                    id: string
                                                                    teaserData?: {
                                                                      __typename: "ExternalLinkTeaserData"
                                                                      title?: string | null
                                                                      summary?: string | null
                                                                      iconUrl?: string | null
                                                                      imageUrl?: string | null
                                                                      status?: Types.LinkTargetStatus | null
                                                                    } | null
                                                                  }
                                                                | { __typename: "ST4Class" }
                                                                | {
                                                                    __typename: "ST4Node"
                                                                    id: string
                                                                    aspectId: any
                                                                    label?: string | null
                                                                    properties?: {
                                                                      __typename: "ContentPropertyConnection"
                                                                      edges?: Array<{
                                                                        __typename: "ContentPropertyEdge"
                                                                        node?: {
                                                                          __typename: "ContentProperty"
                                                                          name: string
                                                                          value?: any | null
                                                                          displayName: string
                                                                        } | null
                                                                      } | null> | null
                                                                    } | null
                                                                  }
                                                                | { __typename: "ST4TreeNode" }
                                                                | null
                                                            }
                                                          | null
                                                      }>
                                                    }
                                                  | {
                                                      __typename: "VideoMediaInfo"
                                                      id: string
                                                      resourceId?: string | null
                                                      thumbnail?: string | null
                                                      width?: number | null
                                                      height?: number | null
                                                      mediaType?: Types.MediaType | null
                                                    }
                                                  | null
                                              }
                                            | {
                                                __typename: "MediaGroupContent"
                                                children: Array<{
                                                  __typename: "NodeRef"
                                                  id: string
                                                  target?:
                                                    | { __typename: "CommentNode"; id: string }
                                                    | { __typename: "ExternalSite"; id: string }
                                                    | { __typename: "ST4Class"; id: string }
                                                    | {
                                                        __typename: "ST4Node"
                                                        id: string
                                                        aspectId: any
                                                        content?:
                                                          | { __typename: "LexiconContent" }
                                                          | { __typename: "LexiconEntryContent" }
                                                          | {
                                                              __typename: "MediaContent"
                                                              media?:
                                                                | {
                                                                    __typename: "AnimationMediaInfo"
                                                                    id: string
                                                                    resourceId?: string | null
                                                                    thumbnail?: string | null
                                                                    width?: number | null
                                                                    height?: number | null
                                                                    mediaType?: Types.MediaType | null
                                                                  }
                                                                | {
                                                                    __typename: "ImageMediaInfo"
                                                                    id: string
                                                                    resourceId?: string | null
                                                                    thumbnail?: string | null
                                                                    width?: number | null
                                                                    height?: number | null
                                                                    resolution?: number | null
                                                                    colorDepth?: number | null
                                                                    size?: number | null
                                                                    mime?: string | null
                                                                    extension?: string | null
                                                                    allowCaching?: boolean | null
                                                                    mediaType?: Types.MediaType | null
                                                                    src?: string | null
                                                                    language?: string | null
                                                                  }
                                                                | {
                                                                    __typename: "NoMediaInfo"
                                                                    id: string
                                                                    mediaType?: Types.MediaType | null
                                                                  }
                                                                | {
                                                                    __typename: "VectorGraphicsMediaInfo"
                                                                    id: string
                                                                    resourceId?: string | null
                                                                    thumbnail?: string | null
                                                                    width?: number | null
                                                                    height?: number | null
                                                                    allowCaching?: boolean | null
                                                                    mediaType?: Types.MediaType | null
                                                                    language?: string | null
                                                                    labels: Array<{
                                                                      __typename: "VectorGraphicsLabel"
                                                                      id?: string | null
                                                                      sequence?: number | null
                                                                      autoValue?: string | null
                                                                      text?: string | null
                                                                      linkType?: Types.VectorGraphicsLabelLinkType | null
                                                                      link?: string | null
                                                                      ref?:
                                                                        | {
                                                                            __typename: "GenericRef"
                                                                            id: string
                                                                            owner?:
                                                                              | { __typename: "CommentNode" }
                                                                              | { __typename: "ExternalSite" }
                                                                              | { __typename: "ST4Class" }
                                                                              | { __typename: "ST4Node"; id: string }
                                                                              | { __typename: "ST4TreeNode" }
                                                                              | null
                                                                            target?:
                                                                              | { __typename: "CommentNode" }
                                                                              | {
                                                                                  __typename: "ExternalSite"
                                                                                  id: string
                                                                                  teaserData?: {
                                                                                    __typename: "ExternalLinkTeaserData"
                                                                                    title?: string | null
                                                                                    summary?: string | null
                                                                                    iconUrl?: string | null
                                                                                    imageUrl?: string | null
                                                                                    status?: Types.LinkTargetStatus | null
                                                                                  } | null
                                                                                }
                                                                              | { __typename: "ST4Class" }
                                                                              | {
                                                                                  __typename: "ST4Node"
                                                                                  id: string
                                                                                  aspectId: any
                                                                                  label?: string | null
                                                                                  properties?: {
                                                                                    __typename: "ContentPropertyConnection"
                                                                                    edges?: Array<{
                                                                                      __typename: "ContentPropertyEdge"
                                                                                      node?: {
                                                                                        __typename: "ContentProperty"
                                                                                        name: string
                                                                                        value?: any | null
                                                                                        displayName: string
                                                                                      } | null
                                                                                    } | null> | null
                                                                                  } | null
                                                                                }
                                                                              | { __typename: "ST4TreeNode" }
                                                                              | null
                                                                          }
                                                                        | {
                                                                            __typename: "NodeRef"
                                                                            id: string
                                                                            owner?:
                                                                              | { __typename: "CommentNode" }
                                                                              | { __typename: "ExternalSite" }
                                                                              | { __typename: "ST4Class" }
                                                                              | { __typename: "ST4Node"; id: string }
                                                                              | { __typename: "ST4TreeNode" }
                                                                              | null
                                                                            target?:
                                                                              | { __typename: "CommentNode" }
                                                                              | {
                                                                                  __typename: "ExternalSite"
                                                                                  id: string
                                                                                  teaserData?: {
                                                                                    __typename: "ExternalLinkTeaserData"
                                                                                    title?: string | null
                                                                                    summary?: string | null
                                                                                    iconUrl?: string | null
                                                                                    imageUrl?: string | null
                                                                                    status?: Types.LinkTargetStatus | null
                                                                                  } | null
                                                                                }
                                                                              | { __typename: "ST4Class" }
                                                                              | {
                                                                                  __typename: "ST4Node"
                                                                                  id: string
                                                                                  aspectId: any
                                                                                  label?: string | null
                                                                                  properties?: {
                                                                                    __typename: "ContentPropertyConnection"
                                                                                    edges?: Array<{
                                                                                      __typename: "ContentPropertyEdge"
                                                                                      node?: {
                                                                                        __typename: "ContentProperty"
                                                                                        name: string
                                                                                        value?: any | null
                                                                                        displayName: string
                                                                                      } | null
                                                                                    } | null> | null
                                                                                  } | null
                                                                                }
                                                                              | { __typename: "ST4TreeNode" }
                                                                              | null
                                                                          }
                                                                        | null
                                                                    }>
                                                                  }
                                                                | {
                                                                    __typename: "VideoMediaInfo"
                                                                    id: string
                                                                    resourceId?: string | null
                                                                    thumbnail?: string | null
                                                                    width?: number | null
                                                                    height?: number | null
                                                                    mediaType?: Types.MediaType | null
                                                                  }
                                                                | null
                                                            }
                                                          | {
                                                              __typename: "MediaGroupContent"
                                                              media?:
                                                                | {
                                                                    __typename: "AnimationMediaInfo"
                                                                    id: string
                                                                    resourceId?: string | null
                                                                    thumbnail?: string | null
                                                                    width?: number | null
                                                                    height?: number | null
                                                                    mediaType?: Types.MediaType | null
                                                                  }
                                                                | {
                                                                    __typename: "ImageMediaInfo"
                                                                    id: string
                                                                    resourceId?: string | null
                                                                    thumbnail?: string | null
                                                                    width?: number | null
                                                                    height?: number | null
                                                                    resolution?: number | null
                                                                    colorDepth?: number | null
                                                                    size?: number | null
                                                                    mime?: string | null
                                                                    extension?: string | null
                                                                    allowCaching?: boolean | null
                                                                    mediaType?: Types.MediaType | null
                                                                    src?: string | null
                                                                    language?: string | null
                                                                  }
                                                                | {
                                                                    __typename: "NoMediaInfo"
                                                                    id: string
                                                                    mediaType?: Types.MediaType | null
                                                                  }
                                                                | {
                                                                    __typename: "VectorGraphicsMediaInfo"
                                                                    id: string
                                                                    resourceId?: string | null
                                                                    thumbnail?: string | null
                                                                    width?: number | null
                                                                    height?: number | null
                                                                    allowCaching?: boolean | null
                                                                    mediaType?: Types.MediaType | null
                                                                    language?: string | null
                                                                    labels: Array<{
                                                                      __typename: "VectorGraphicsLabel"
                                                                      id?: string | null
                                                                      sequence?: number | null
                                                                      autoValue?: string | null
                                                                      text?: string | null
                                                                      linkType?: Types.VectorGraphicsLabelLinkType | null
                                                                      link?: string | null
                                                                      ref?:
                                                                        | {
                                                                            __typename: "GenericRef"
                                                                            id: string
                                                                            owner?:
                                                                              | { __typename: "CommentNode" }
                                                                              | { __typename: "ExternalSite" }
                                                                              | { __typename: "ST4Class" }
                                                                              | { __typename: "ST4Node"; id: string }
                                                                              | { __typename: "ST4TreeNode" }
                                                                              | null
                                                                            target?:
                                                                              | { __typename: "CommentNode" }
                                                                              | {
                                                                                  __typename: "ExternalSite"
                                                                                  id: string
                                                                                  teaserData?: {
                                                                                    __typename: "ExternalLinkTeaserData"
                                                                                    title?: string | null
                                                                                    summary?: string | null
                                                                                    iconUrl?: string | null
                                                                                    imageUrl?: string | null
                                                                                    status?: Types.LinkTargetStatus | null
                                                                                  } | null
                                                                                }
                                                                              | { __typename: "ST4Class" }
                                                                              | {
                                                                                  __typename: "ST4Node"
                                                                                  id: string
                                                                                  aspectId: any
                                                                                  label?: string | null
                                                                                  properties?: {
                                                                                    __typename: "ContentPropertyConnection"
                                                                                    edges?: Array<{
                                                                                      __typename: "ContentPropertyEdge"
                                                                                      node?: {
                                                                                        __typename: "ContentProperty"
                                                                                        name: string
                                                                                        value?: any | null
                                                                                        displayName: string
                                                                                      } | null
                                                                                    } | null> | null
                                                                                  } | null
                                                                                }
                                                                              | { __typename: "ST4TreeNode" }
                                                                              | null
                                                                          }
                                                                        | {
                                                                            __typename: "NodeRef"
                                                                            id: string
                                                                            owner?:
                                                                              | { __typename: "CommentNode" }
                                                                              | { __typename: "ExternalSite" }
                                                                              | { __typename: "ST4Class" }
                                                                              | { __typename: "ST4Node"; id: string }
                                                                              | { __typename: "ST4TreeNode" }
                                                                              | null
                                                                            target?:
                                                                              | { __typename: "CommentNode" }
                                                                              | {
                                                                                  __typename: "ExternalSite"
                                                                                  id: string
                                                                                  teaserData?: {
                                                                                    __typename: "ExternalLinkTeaserData"
                                                                                    title?: string | null
                                                                                    summary?: string | null
                                                                                    iconUrl?: string | null
                                                                                    imageUrl?: string | null
                                                                                    status?: Types.LinkTargetStatus | null
                                                                                  } | null
                                                                                }
                                                                              | { __typename: "ST4Class" }
                                                                              | {
                                                                                  __typename: "ST4Node"
                                                                                  id: string
                                                                                  aspectId: any
                                                                                  label?: string | null
                                                                                  properties?: {
                                                                                    __typename: "ContentPropertyConnection"
                                                                                    edges?: Array<{
                                                                                      __typename: "ContentPropertyEdge"
                                                                                      node?: {
                                                                                        __typename: "ContentProperty"
                                                                                        name: string
                                                                                        value?: any | null
                                                                                        displayName: string
                                                                                      } | null
                                                                                    } | null> | null
                                                                                  } | null
                                                                                }
                                                                              | { __typename: "ST4TreeNode" }
                                                                              | null
                                                                          }
                                                                        | null
                                                                    }>
                                                                  }
                                                                | {
                                                                    __typename: "VideoMediaInfo"
                                                                    id: string
                                                                    resourceId?: string | null
                                                                    thumbnail?: string | null
                                                                    width?: number | null
                                                                    height?: number | null
                                                                    mediaType?: Types.MediaType | null
                                                                  }
                                                                | null
                                                            }
                                                          | { __typename: "ProjectContent" }
                                                          | { __typename: "TermContent" }
                                                          | { __typename: "TextContent" }
                                                          | { __typename: "TextGroupContent" }
                                                          | { __typename: "VariableTableContent" }
                                                          | null
                                                      }
                                                    | { __typename: "ST4TreeNode"; id: string }
                                                    | null
                                                }>
                                                media?:
                                                  | {
                                                      __typename: "AnimationMediaInfo"
                                                      id: string
                                                      resourceId?: string | null
                                                      thumbnail?: string | null
                                                      width?: number | null
                                                      height?: number | null
                                                      mediaType?: Types.MediaType | null
                                                    }
                                                  | {
                                                      __typename: "ImageMediaInfo"
                                                      id: string
                                                      resourceId?: string | null
                                                      thumbnail?: string | null
                                                      width?: number | null
                                                      height?: number | null
                                                      resolution?: number | null
                                                      colorDepth?: number | null
                                                      size?: number | null
                                                      mime?: string | null
                                                      extension?: string | null
                                                      allowCaching?: boolean | null
                                                      mediaType?: Types.MediaType | null
                                                      src?: string | null
                                                      language?: string | null
                                                    }
                                                  | {
                                                      __typename: "NoMediaInfo"
                                                      id: string
                                                      mediaType?: Types.MediaType | null
                                                    }
                                                  | {
                                                      __typename: "VectorGraphicsMediaInfo"
                                                      id: string
                                                      resourceId?: string | null
                                                      thumbnail?: string | null
                                                      width?: number | null
                                                      height?: number | null
                                                      allowCaching?: boolean | null
                                                      mediaType?: Types.MediaType | null
                                                      language?: string | null
                                                      labels: Array<{
                                                        __typename: "VectorGraphicsLabel"
                                                        id?: string | null
                                                        sequence?: number | null
                                                        autoValue?: string | null
                                                        text?: string | null
                                                        linkType?: Types.VectorGraphicsLabelLinkType | null
                                                        link?: string | null
                                                        ref?:
                                                          | {
                                                              __typename: "GenericRef"
                                                              id: string
                                                              owner?:
                                                                | { __typename: "CommentNode" }
                                                                | { __typename: "ExternalSite" }
                                                                | { __typename: "ST4Class" }
                                                                | { __typename: "ST4Node"; id: string }
                                                                | { __typename: "ST4TreeNode" }
                                                                | null
                                                              target?:
                                                                | { __typename: "CommentNode" }
                                                                | {
                                                                    __typename: "ExternalSite"
                                                                    id: string
                                                                    teaserData?: {
                                                                      __typename: "ExternalLinkTeaserData"
                                                                      title?: string | null
                                                                      summary?: string | null
                                                                      iconUrl?: string | null
                                                                      imageUrl?: string | null
                                                                      status?: Types.LinkTargetStatus | null
                                                                    } | null
                                                                  }
                                                                | { __typename: "ST4Class" }
                                                                | {
                                                                    __typename: "ST4Node"
                                                                    id: string
                                                                    aspectId: any
                                                                    label?: string | null
                                                                    properties?: {
                                                                      __typename: "ContentPropertyConnection"
                                                                      edges?: Array<{
                                                                        __typename: "ContentPropertyEdge"
                                                                        node?: {
                                                                          __typename: "ContentProperty"
                                                                          name: string
                                                                          value?: any | null
                                                                          displayName: string
                                                                        } | null
                                                                      } | null> | null
                                                                    } | null
                                                                  }
                                                                | { __typename: "ST4TreeNode" }
                                                                | null
                                                            }
                                                          | {
                                                              __typename: "NodeRef"
                                                              id: string
                                                              owner?:
                                                                | { __typename: "CommentNode" }
                                                                | { __typename: "ExternalSite" }
                                                                | { __typename: "ST4Class" }
                                                                | { __typename: "ST4Node"; id: string }
                                                                | { __typename: "ST4TreeNode" }
                                                                | null
                                                              target?:
                                                                | { __typename: "CommentNode" }
                                                                | {
                                                                    __typename: "ExternalSite"
                                                                    id: string
                                                                    teaserData?: {
                                                                      __typename: "ExternalLinkTeaserData"
                                                                      title?: string | null
                                                                      summary?: string | null
                                                                      iconUrl?: string | null
                                                                      imageUrl?: string | null
                                                                      status?: Types.LinkTargetStatus | null
                                                                    } | null
                                                                  }
                                                                | { __typename: "ST4Class" }
                                                                | {
                                                                    __typename: "ST4Node"
                                                                    id: string
                                                                    aspectId: any
                                                                    label?: string | null
                                                                    properties?: {
                                                                      __typename: "ContentPropertyConnection"
                                                                      edges?: Array<{
                                                                        __typename: "ContentPropertyEdge"
                                                                        node?: {
                                                                          __typename: "ContentProperty"
                                                                          name: string
                                                                          value?: any | null
                                                                          displayName: string
                                                                        } | null
                                                                      } | null> | null
                                                                    } | null
                                                                  }
                                                                | { __typename: "ST4TreeNode" }
                                                                | null
                                                            }
                                                          | null
                                                      }>
                                                    }
                                                  | {
                                                      __typename: "VideoMediaInfo"
                                                      id: string
                                                      resourceId?: string | null
                                                      thumbnail?: string | null
                                                      width?: number | null
                                                      height?: number | null
                                                      mediaType?: Types.MediaType | null
                                                    }
                                                  | null
                                              }
                                            | { __typename: "ProjectContent" }
                                            | { __typename: "TermContent" }
                                            | { __typename: "TextContent" }
                                            | { __typename: "TextGroupContent" }
                                            | { __typename: "VariableTableContent" }
                                            | null
                                        }
                                      | { __typename: "ST4TreeNode"; id: string }
                                      | null
                                  }>
                                  variableTables?: Array<{
                                    __typename: "VariableTableReference"
                                    ref?: string | null
                                    node?:
                                      | { __typename: "CommentNode" }
                                      | { __typename: "ExternalSite" }
                                      | { __typename: "ST4Class" }
                                      | {
                                          __typename: "ST4Node"
                                          id: string
                                          label?: string | null
                                          aspectId: any
                                          content?:
                                            | { __typename: "LexiconContent" }
                                            | { __typename: "LexiconEntryContent" }
                                            | { __typename: "MediaContent" }
                                            | { __typename: "MediaGroupContent" }
                                            | { __typename: "ProjectContent" }
                                            | { __typename: "TermContent" }
                                            | { __typename: "TextContent" }
                                            | { __typename: "TextGroupContent" }
                                            | {
                                                __typename: "VariableTableContent"
                                                xml?: string | null
                                                valueSets: Array<{
                                                  __typename: "ValueSet"
                                                  name: string
                                                  valueSetId: string
                                                }>
                                                variables: Array<{
                                                  __typename: "Variable"
                                                  name: string
                                                  variableId: string
                                                  values: Array<string>
                                                }>
                                              }
                                            | null
                                        }
                                      | { __typename: "ST4TreeNode" }
                                      | null
                                  } | null> | null
                                  linkedDataNodes: Array<{
                                    __typename: "NodeRef"
                                    id: string
                                    target?:
                                      | { __typename: "CommentNode"; id: string }
                                      | { __typename: "ExternalSite"; id: string }
                                      | { __typename: "ST4Class"; id: string }
                                      | {
                                          __typename: "ST4Node"
                                          label?: string | null
                                          id: string
                                          metadata: Array<{
                                            __typename: "MetaDataEntry"
                                            label?: string | null
                                            systemName: string
                                            value?: string | null
                                            values: Array<string>
                                            users: Array<string>
                                          }>
                                          linkedOntologyNodes: Array<
                                            | {
                                                __typename: "GenericRef"
                                                target?:
                                                  | { __typename: "CommentNode" }
                                                  | { __typename: "ExternalSite" }
                                                  | { __typename: "ST4Class" }
                                                  | { __typename: "ST4Node"; id: string; label?: string | null }
                                                  | { __typename: "ST4TreeNode" }
                                                  | null
                                              }
                                            | {
                                                __typename: "NodeRef"
                                                linkClassName: string
                                                class?: { __typename: "ST4Class"; label?: string | null } | null
                                                target?:
                                                  | { __typename: "CommentNode" }
                                                  | { __typename: "ExternalSite" }
                                                  | { __typename: "ST4Class" }
                                                  | { __typename: "ST4Node"; id: string; label?: string | null }
                                                  | { __typename: "ST4TreeNode" }
                                                  | null
                                              }
                                          >
                                        }
                                      | { __typename: "ST4TreeNode"; id: string }
                                      | null
                                  }>
                                  lexiconEntries?: Array<{
                                    __typename: "NodeRef"
                                    id: string
                                    target?:
                                      | { __typename: "CommentNode"; id: string }
                                      | { __typename: "ExternalSite"; id: string }
                                      | { __typename: "ST4Class"; id: string }
                                      | {
                                          __typename: "ST4Node"
                                          aspectId: any
                                          label?: string | null
                                          id: string
                                          content?:
                                            | { __typename: "LexiconContent" }
                                            | {
                                                __typename: "LexiconEntryContent"
                                                descriptionXast?: string | null
                                                subject?: string | null
                                                sortKey?: string | null
                                                lexicon:
                                                  | { __typename: "CommentNode"; id: string }
                                                  | { __typename: "ExternalSite"; id: string }
                                                  | { __typename: "ST4Class"; id: string }
                                                  | { __typename: "ST4Node"; label?: string | null; id: string }
                                                  | { __typename: "ST4TreeNode"; id: string }
                                                terms: Array<
                                                  | { __typename: "CommentNode"; id: string }
                                                  | { __typename: "ExternalSite"; id: string }
                                                  | { __typename: "ST4Class"; id: string }
                                                  | {
                                                      __typename: "ST4Node"
                                                      id: string
                                                      label?: string | null
                                                      aspectId: any
                                                      content?:
                                                        | { __typename: "LexiconContent" }
                                                        | { __typename: "LexiconEntryContent" }
                                                        | { __typename: "MediaContent" }
                                                        | { __typename: "MediaGroupContent" }
                                                        | { __typename: "ProjectContent" }
                                                        | {
                                                            __typename: "TermContent"
                                                            gramaticalGender?: string | null
                                                            validity?: string | null
                                                            editLanguage?: string | null
                                                          }
                                                        | { __typename: "TextContent" }
                                                        | { __typename: "TextGroupContent" }
                                                        | { __typename: "VariableTableContent" }
                                                        | null
                                                    }
                                                  | { __typename: "ST4TreeNode"; id: string }
                                                >
                                                image?:
                                                  | { __typename: "CommentNode"; id: string }
                                                  | { __typename: "ExternalSite"; id: string }
                                                  | { __typename: "ST4Class"; id: string }
                                                  | {
                                                      __typename: "ST4Node"
                                                      aspectId: any
                                                      id: string
                                                      content?:
                                                        | { __typename: "LexiconContent" }
                                                        | { __typename: "LexiconEntryContent" }
                                                        | {
                                                            __typename: "MediaContent"
                                                            media?:
                                                              | {
                                                                  __typename: "AnimationMediaInfo"
                                                                  thumbnail?: string | null
                                                                  mediaType?: Types.MediaType | null
                                                                }
                                                              | {
                                                                  __typename: "ImageMediaInfo"
                                                                  src?: string | null
                                                                  thumbnail?: string | null
                                                                  mediaType?: Types.MediaType | null
                                                                }
                                                              | { __typename: "NoMediaInfo" }
                                                              | {
                                                                  __typename: "VectorGraphicsMediaInfo"
                                                                  svg?: string | null
                                                                  thumbnail?: string | null
                                                                  mediaType?: Types.MediaType | null
                                                                }
                                                              | {
                                                                  __typename: "VideoMediaInfo"
                                                                  thumbnail?: string | null
                                                                  mediaType?: Types.MediaType | null
                                                                }
                                                              | null
                                                          }
                                                        | { __typename: "MediaGroupContent" }
                                                        | { __typename: "ProjectContent" }
                                                        | { __typename: "TermContent" }
                                                        | { __typename: "TextContent" }
                                                        | { __typename: "TextGroupContent" }
                                                        | { __typename: "VariableTableContent" }
                                                        | null
                                                    }
                                                  | { __typename: "ST4TreeNode"; id: string }
                                                  | null
                                              }
                                            | { __typename: "MediaContent" }
                                            | { __typename: "MediaGroupContent" }
                                            | { __typename: "ProjectContent" }
                                            | { __typename: "TermContent" }
                                            | { __typename: "TextContent" }
                                            | { __typename: "TextGroupContent" }
                                            | { __typename: "VariableTableContent" }
                                            | null
                                        }
                                      | { __typename: "ST4TreeNode"; id: string }
                                      | null
                                  } | null> | null
                                  comments: Array<{
                                    __typename: "CommentNode"
                                    id: string
                                    comment:
                                      | {
                                          __typename: "BlockComment"
                                          location: number
                                          value?: string | null
                                          authorDisplayName?: string | null
                                          authorUserName?: string | null
                                          commentKey: string
                                          editDate?: any | null
                                          modificationVersion?: number | null
                                          replyToKey?: string | null
                                          sortOrder?: number | null
                                          commentType?: string | null
                                          state?: string | null
                                          color: string
                                          persistanceState?: Types.PersistanceState | null
                                          persistanceAction?: Types.PersistanceAction | null
                                          node: {
                                            __typename: "ST4Node"
                                            aspectId: any
                                            id: string
                                            content?:
                                              | { __typename: "LexiconContent" }
                                              | { __typename: "LexiconEntryContent" }
                                              | { __typename: "MediaContent" }
                                              | { __typename: "MediaGroupContent" }
                                              | { __typename: "ProjectContent" }
                                              | { __typename: "TermContent" }
                                              | { __typename: "TextContent"; id: string; historyNumber: number }
                                              | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                                              | { __typename: "VariableTableContent" }
                                              | null
                                          }
                                          actions: Array<{
                                            __typename: "Action"
                                            name?: string | null
                                            enabled?: boolean | null
                                          }>
                                        }
                                      | {
                                          __typename: "TextComment"
                                          endLocation: number
                                          startLocation?: number | null
                                          value?: string | null
                                          authorDisplayName?: string | null
                                          authorUserName?: string | null
                                          commentKey: string
                                          editDate?: any | null
                                          modificationVersion?: number | null
                                          replyToKey?: string | null
                                          sortOrder?: number | null
                                          commentType?: string | null
                                          state?: string | null
                                          color: string
                                          persistanceState?: Types.PersistanceState | null
                                          persistanceAction?: Types.PersistanceAction | null
                                          node: {
                                            __typename: "ST4Node"
                                            aspectId: any
                                            id: string
                                            content?:
                                              | { __typename: "LexiconContent" }
                                              | { __typename: "LexiconEntryContent" }
                                              | { __typename: "MediaContent" }
                                              | { __typename: "MediaGroupContent" }
                                              | { __typename: "ProjectContent" }
                                              | { __typename: "TermContent" }
                                              | { __typename: "TextContent"; id: string; historyNumber: number }
                                              | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                                              | { __typename: "VariableTableContent" }
                                              | null
                                          }
                                          actions: Array<{
                                            __typename: "Action"
                                            name?: string | null
                                            enabled?: boolean | null
                                          }>
                                        }
                                  }>
                                }
                              | {
                                  __typename: "TextGroupContent"
                                  currentNodeId?: any | null
                                  groupNodeId: any
                                  id: string
                                  xast?: string | null
                                  historyNumber: number
                                  children: Array<{
                                    __typename: "NodeRef"
                                    id: string
                                    target?:
                                      | { __typename: "CommentNode"; id: string }
                                      | { __typename: "ExternalSite"; id: string }
                                      | { __typename: "ST4Class"; id: string }
                                      | { __typename: "ST4Node"; label?: string | null; aspectId: any; id: string }
                                      | { __typename: "ST4TreeNode"; id: string }
                                      | null
                                  }>
                                  links?: Array<{
                                    __typename: "TextContentLink"
                                    ref?: string | null
                                    node?:
                                      | { __typename: "CommentNode"; id: string }
                                      | { __typename: "ExternalSite"; id: string }
                                      | { __typename: "ST4Class"; id: string }
                                      | {
                                          __typename: "ST4Node"
                                          id: string
                                          aspectId: any
                                          label?: string | null
                                          properties?: {
                                            __typename: "ContentPropertyConnection"
                                            totalCount: number
                                            edges?: Array<{
                                              __typename: "ContentPropertyEdge"
                                              node?: {
                                                __typename: "ContentProperty"
                                                typeOfValue: Types.ContentPropertyValueType
                                                name: string
                                                value?: any | null
                                                displayName: string
                                              } | null
                                            } | null> | null
                                          } | null
                                        }
                                      | { __typename: "ST4TreeNode"; id: string }
                                      | null
                                  } | null> | null
                                  images: Array<{
                                    __typename: "NodeRef"
                                    id: string
                                    target?:
                                      | { __typename: "CommentNode"; id: string }
                                      | { __typename: "ExternalSite"; id: string }
                                      | { __typename: "ST4Class"; id: string }
                                      | {
                                          __typename: "ST4Node"
                                          aspectId: any
                                          id: string
                                          releaseState: {
                                            __typename: "ReleaseStateValue"
                                            displayName: string
                                            state: Types.ReleaseState
                                            isImplicitlyReleased: boolean
                                          }
                                          content?:
                                            | { __typename: "LexiconContent" }
                                            | { __typename: "LexiconEntryContent" }
                                            | {
                                                __typename: "MediaContent"
                                                media?:
                                                  | {
                                                      __typename: "AnimationMediaInfo"
                                                      id: string
                                                      resourceId?: string | null
                                                      thumbnail?: string | null
                                                      width?: number | null
                                                      height?: number | null
                                                      mediaType?: Types.MediaType | null
                                                    }
                                                  | {
                                                      __typename: "ImageMediaInfo"
                                                      id: string
                                                      resourceId?: string | null
                                                      thumbnail?: string | null
                                                      width?: number | null
                                                      height?: number | null
                                                      resolution?: number | null
                                                      colorDepth?: number | null
                                                      size?: number | null
                                                      mime?: string | null
                                                      extension?: string | null
                                                      allowCaching?: boolean | null
                                                      mediaType?: Types.MediaType | null
                                                      src?: string | null
                                                      language?: string | null
                                                    }
                                                  | {
                                                      __typename: "NoMediaInfo"
                                                      id: string
                                                      mediaType?: Types.MediaType | null
                                                    }
                                                  | {
                                                      __typename: "VectorGraphicsMediaInfo"
                                                      id: string
                                                      resourceId?: string | null
                                                      thumbnail?: string | null
                                                      width?: number | null
                                                      height?: number | null
                                                      allowCaching?: boolean | null
                                                      mediaType?: Types.MediaType | null
                                                      language?: string | null
                                                      labels: Array<{
                                                        __typename: "VectorGraphicsLabel"
                                                        id?: string | null
                                                        sequence?: number | null
                                                        autoValue?: string | null
                                                        text?: string | null
                                                        linkType?: Types.VectorGraphicsLabelLinkType | null
                                                        link?: string | null
                                                        ref?:
                                                          | {
                                                              __typename: "GenericRef"
                                                              id: string
                                                              owner?:
                                                                | { __typename: "CommentNode" }
                                                                | { __typename: "ExternalSite" }
                                                                | { __typename: "ST4Class" }
                                                                | { __typename: "ST4Node"; id: string }
                                                                | { __typename: "ST4TreeNode" }
                                                                | null
                                                              target?:
                                                                | { __typename: "CommentNode" }
                                                                | {
                                                                    __typename: "ExternalSite"
                                                                    id: string
                                                                    teaserData?: {
                                                                      __typename: "ExternalLinkTeaserData"
                                                                      title?: string | null
                                                                      summary?: string | null
                                                                      iconUrl?: string | null
                                                                      imageUrl?: string | null
                                                                      status?: Types.LinkTargetStatus | null
                                                                    } | null
                                                                  }
                                                                | { __typename: "ST4Class" }
                                                                | {
                                                                    __typename: "ST4Node"
                                                                    id: string
                                                                    aspectId: any
                                                                    label?: string | null
                                                                    properties?: {
                                                                      __typename: "ContentPropertyConnection"
                                                                      edges?: Array<{
                                                                        __typename: "ContentPropertyEdge"
                                                                        node?: {
                                                                          __typename: "ContentProperty"
                                                                          name: string
                                                                          value?: any | null
                                                                          displayName: string
                                                                        } | null
                                                                      } | null> | null
                                                                    } | null
                                                                  }
                                                                | { __typename: "ST4TreeNode" }
                                                                | null
                                                            }
                                                          | {
                                                              __typename: "NodeRef"
                                                              id: string
                                                              owner?:
                                                                | { __typename: "CommentNode" }
                                                                | { __typename: "ExternalSite" }
                                                                | { __typename: "ST4Class" }
                                                                | { __typename: "ST4Node"; id: string }
                                                                | { __typename: "ST4TreeNode" }
                                                                | null
                                                              target?:
                                                                | { __typename: "CommentNode" }
                                                                | {
                                                                    __typename: "ExternalSite"
                                                                    id: string
                                                                    teaserData?: {
                                                                      __typename: "ExternalLinkTeaserData"
                                                                      title?: string | null
                                                                      summary?: string | null
                                                                      iconUrl?: string | null
                                                                      imageUrl?: string | null
                                                                      status?: Types.LinkTargetStatus | null
                                                                    } | null
                                                                  }
                                                                | { __typename: "ST4Class" }
                                                                | {
                                                                    __typename: "ST4Node"
                                                                    id: string
                                                                    aspectId: any
                                                                    label?: string | null
                                                                    properties?: {
                                                                      __typename: "ContentPropertyConnection"
                                                                      edges?: Array<{
                                                                        __typename: "ContentPropertyEdge"
                                                                        node?: {
                                                                          __typename: "ContentProperty"
                                                                          name: string
                                                                          value?: any | null
                                                                          displayName: string
                                                                        } | null
                                                                      } | null> | null
                                                                    } | null
                                                                  }
                                                                | { __typename: "ST4TreeNode" }
                                                                | null
                                                            }
                                                          | null
                                                      }>
                                                    }
                                                  | {
                                                      __typename: "VideoMediaInfo"
                                                      id: string
                                                      resourceId?: string | null
                                                      thumbnail?: string | null
                                                      width?: number | null
                                                      height?: number | null
                                                      mediaType?: Types.MediaType | null
                                                    }
                                                  | null
                                              }
                                            | {
                                                __typename: "MediaGroupContent"
                                                children: Array<{
                                                  __typename: "NodeRef"
                                                  id: string
                                                  target?:
                                                    | { __typename: "CommentNode"; id: string }
                                                    | { __typename: "ExternalSite"; id: string }
                                                    | { __typename: "ST4Class"; id: string }
                                                    | {
                                                        __typename: "ST4Node"
                                                        id: string
                                                        aspectId: any
                                                        content?:
                                                          | { __typename: "LexiconContent" }
                                                          | { __typename: "LexiconEntryContent" }
                                                          | {
                                                              __typename: "MediaContent"
                                                              media?:
                                                                | {
                                                                    __typename: "AnimationMediaInfo"
                                                                    id: string
                                                                    resourceId?: string | null
                                                                    thumbnail?: string | null
                                                                    width?: number | null
                                                                    height?: number | null
                                                                    mediaType?: Types.MediaType | null
                                                                  }
                                                                | {
                                                                    __typename: "ImageMediaInfo"
                                                                    id: string
                                                                    resourceId?: string | null
                                                                    thumbnail?: string | null
                                                                    width?: number | null
                                                                    height?: number | null
                                                                    resolution?: number | null
                                                                    colorDepth?: number | null
                                                                    size?: number | null
                                                                    mime?: string | null
                                                                    extension?: string | null
                                                                    allowCaching?: boolean | null
                                                                    mediaType?: Types.MediaType | null
                                                                    src?: string | null
                                                                    language?: string | null
                                                                  }
                                                                | {
                                                                    __typename: "NoMediaInfo"
                                                                    id: string
                                                                    mediaType?: Types.MediaType | null
                                                                  }
                                                                | {
                                                                    __typename: "VectorGraphicsMediaInfo"
                                                                    id: string
                                                                    resourceId?: string | null
                                                                    thumbnail?: string | null
                                                                    width?: number | null
                                                                    height?: number | null
                                                                    allowCaching?: boolean | null
                                                                    mediaType?: Types.MediaType | null
                                                                    language?: string | null
                                                                    labels: Array<{
                                                                      __typename: "VectorGraphicsLabel"
                                                                      id?: string | null
                                                                      sequence?: number | null
                                                                      autoValue?: string | null
                                                                      text?: string | null
                                                                      linkType?: Types.VectorGraphicsLabelLinkType | null
                                                                      link?: string | null
                                                                      ref?:
                                                                        | {
                                                                            __typename: "GenericRef"
                                                                            id: string
                                                                            owner?:
                                                                              | { __typename: "CommentNode" }
                                                                              | { __typename: "ExternalSite" }
                                                                              | { __typename: "ST4Class" }
                                                                              | { __typename: "ST4Node"; id: string }
                                                                              | { __typename: "ST4TreeNode" }
                                                                              | null
                                                                            target?:
                                                                              | { __typename: "CommentNode" }
                                                                              | {
                                                                                  __typename: "ExternalSite"
                                                                                  id: string
                                                                                  teaserData?: {
                                                                                    __typename: "ExternalLinkTeaserData"
                                                                                    title?: string | null
                                                                                    summary?: string | null
                                                                                    iconUrl?: string | null
                                                                                    imageUrl?: string | null
                                                                                    status?: Types.LinkTargetStatus | null
                                                                                  } | null
                                                                                }
                                                                              | { __typename: "ST4Class" }
                                                                              | {
                                                                                  __typename: "ST4Node"
                                                                                  id: string
                                                                                  aspectId: any
                                                                                  label?: string | null
                                                                                  properties?: {
                                                                                    __typename: "ContentPropertyConnection"
                                                                                    edges?: Array<{
                                                                                      __typename: "ContentPropertyEdge"
                                                                                      node?: {
                                                                                        __typename: "ContentProperty"
                                                                                        name: string
                                                                                        value?: any | null
                                                                                        displayName: string
                                                                                      } | null
                                                                                    } | null> | null
                                                                                  } | null
                                                                                }
                                                                              | { __typename: "ST4TreeNode" }
                                                                              | null
                                                                          }
                                                                        | {
                                                                            __typename: "NodeRef"
                                                                            id: string
                                                                            owner?:
                                                                              | { __typename: "CommentNode" }
                                                                              | { __typename: "ExternalSite" }
                                                                              | { __typename: "ST4Class" }
                                                                              | { __typename: "ST4Node"; id: string }
                                                                              | { __typename: "ST4TreeNode" }
                                                                              | null
                                                                            target?:
                                                                              | { __typename: "CommentNode" }
                                                                              | {
                                                                                  __typename: "ExternalSite"
                                                                                  id: string
                                                                                  teaserData?: {
                                                                                    __typename: "ExternalLinkTeaserData"
                                                                                    title?: string | null
                                                                                    summary?: string | null
                                                                                    iconUrl?: string | null
                                                                                    imageUrl?: string | null
                                                                                    status?: Types.LinkTargetStatus | null
                                                                                  } | null
                                                                                }
                                                                              | { __typename: "ST4Class" }
                                                                              | {
                                                                                  __typename: "ST4Node"
                                                                                  id: string
                                                                                  aspectId: any
                                                                                  label?: string | null
                                                                                  properties?: {
                                                                                    __typename: "ContentPropertyConnection"
                                                                                    edges?: Array<{
                                                                                      __typename: "ContentPropertyEdge"
                                                                                      node?: {
                                                                                        __typename: "ContentProperty"
                                                                                        name: string
                                                                                        value?: any | null
                                                                                        displayName: string
                                                                                      } | null
                                                                                    } | null> | null
                                                                                  } | null
                                                                                }
                                                                              | { __typename: "ST4TreeNode" }
                                                                              | null
                                                                          }
                                                                        | null
                                                                    }>
                                                                  }
                                                                | {
                                                                    __typename: "VideoMediaInfo"
                                                                    id: string
                                                                    resourceId?: string | null
                                                                    thumbnail?: string | null
                                                                    width?: number | null
                                                                    height?: number | null
                                                                    mediaType?: Types.MediaType | null
                                                                  }
                                                                | null
                                                            }
                                                          | {
                                                              __typename: "MediaGroupContent"
                                                              media?:
                                                                | {
                                                                    __typename: "AnimationMediaInfo"
                                                                    id: string
                                                                    resourceId?: string | null
                                                                    thumbnail?: string | null
                                                                    width?: number | null
                                                                    height?: number | null
                                                                    mediaType?: Types.MediaType | null
                                                                  }
                                                                | {
                                                                    __typename: "ImageMediaInfo"
                                                                    id: string
                                                                    resourceId?: string | null
                                                                    thumbnail?: string | null
                                                                    width?: number | null
                                                                    height?: number | null
                                                                    resolution?: number | null
                                                                    colorDepth?: number | null
                                                                    size?: number | null
                                                                    mime?: string | null
                                                                    extension?: string | null
                                                                    allowCaching?: boolean | null
                                                                    mediaType?: Types.MediaType | null
                                                                    src?: string | null
                                                                    language?: string | null
                                                                  }
                                                                | {
                                                                    __typename: "NoMediaInfo"
                                                                    id: string
                                                                    mediaType?: Types.MediaType | null
                                                                  }
                                                                | {
                                                                    __typename: "VectorGraphicsMediaInfo"
                                                                    id: string
                                                                    resourceId?: string | null
                                                                    thumbnail?: string | null
                                                                    width?: number | null
                                                                    height?: number | null
                                                                    allowCaching?: boolean | null
                                                                    mediaType?: Types.MediaType | null
                                                                    language?: string | null
                                                                    labels: Array<{
                                                                      __typename: "VectorGraphicsLabel"
                                                                      id?: string | null
                                                                      sequence?: number | null
                                                                      autoValue?: string | null
                                                                      text?: string | null
                                                                      linkType?: Types.VectorGraphicsLabelLinkType | null
                                                                      link?: string | null
                                                                      ref?:
                                                                        | {
                                                                            __typename: "GenericRef"
                                                                            id: string
                                                                            owner?:
                                                                              | { __typename: "CommentNode" }
                                                                              | { __typename: "ExternalSite" }
                                                                              | { __typename: "ST4Class" }
                                                                              | { __typename: "ST4Node"; id: string }
                                                                              | { __typename: "ST4TreeNode" }
                                                                              | null
                                                                            target?:
                                                                              | { __typename: "CommentNode" }
                                                                              | {
                                                                                  __typename: "ExternalSite"
                                                                                  id: string
                                                                                  teaserData?: {
                                                                                    __typename: "ExternalLinkTeaserData"
                                                                                    title?: string | null
                                                                                    summary?: string | null
                                                                                    iconUrl?: string | null
                                                                                    imageUrl?: string | null
                                                                                    status?: Types.LinkTargetStatus | null
                                                                                  } | null
                                                                                }
                                                                              | { __typename: "ST4Class" }
                                                                              | {
                                                                                  __typename: "ST4Node"
                                                                                  id: string
                                                                                  aspectId: any
                                                                                  label?: string | null
                                                                                  properties?: {
                                                                                    __typename: "ContentPropertyConnection"
                                                                                    edges?: Array<{
                                                                                      __typename: "ContentPropertyEdge"
                                                                                      node?: {
                                                                                        __typename: "ContentProperty"
                                                                                        name: string
                                                                                        value?: any | null
                                                                                        displayName: string
                                                                                      } | null
                                                                                    } | null> | null
                                                                                  } | null
                                                                                }
                                                                              | { __typename: "ST4TreeNode" }
                                                                              | null
                                                                          }
                                                                        | {
                                                                            __typename: "NodeRef"
                                                                            id: string
                                                                            owner?:
                                                                              | { __typename: "CommentNode" }
                                                                              | { __typename: "ExternalSite" }
                                                                              | { __typename: "ST4Class" }
                                                                              | { __typename: "ST4Node"; id: string }
                                                                              | { __typename: "ST4TreeNode" }
                                                                              | null
                                                                            target?:
                                                                              | { __typename: "CommentNode" }
                                                                              | {
                                                                                  __typename: "ExternalSite"
                                                                                  id: string
                                                                                  teaserData?: {
                                                                                    __typename: "ExternalLinkTeaserData"
                                                                                    title?: string | null
                                                                                    summary?: string | null
                                                                                    iconUrl?: string | null
                                                                                    imageUrl?: string | null
                                                                                    status?: Types.LinkTargetStatus | null
                                                                                  } | null
                                                                                }
                                                                              | { __typename: "ST4Class" }
                                                                              | {
                                                                                  __typename: "ST4Node"
                                                                                  id: string
                                                                                  aspectId: any
                                                                                  label?: string | null
                                                                                  properties?: {
                                                                                    __typename: "ContentPropertyConnection"
                                                                                    edges?: Array<{
                                                                                      __typename: "ContentPropertyEdge"
                                                                                      node?: {
                                                                                        __typename: "ContentProperty"
                                                                                        name: string
                                                                                        value?: any | null
                                                                                        displayName: string
                                                                                      } | null
                                                                                    } | null> | null
                                                                                  } | null
                                                                                }
                                                                              | { __typename: "ST4TreeNode" }
                                                                              | null
                                                                          }
                                                                        | null
                                                                    }>
                                                                  }
                                                                | {
                                                                    __typename: "VideoMediaInfo"
                                                                    id: string
                                                                    resourceId?: string | null
                                                                    thumbnail?: string | null
                                                                    width?: number | null
                                                                    height?: number | null
                                                                    mediaType?: Types.MediaType | null
                                                                  }
                                                                | null
                                                            }
                                                          | { __typename: "ProjectContent" }
                                                          | { __typename: "TermContent" }
                                                          | { __typename: "TextContent" }
                                                          | { __typename: "TextGroupContent" }
                                                          | { __typename: "VariableTableContent" }
                                                          | null
                                                      }
                                                    | { __typename: "ST4TreeNode"; id: string }
                                                    | null
                                                }>
                                                media?:
                                                  | {
                                                      __typename: "AnimationMediaInfo"
                                                      id: string
                                                      resourceId?: string | null
                                                      thumbnail?: string | null
                                                      width?: number | null
                                                      height?: number | null
                                                      mediaType?: Types.MediaType | null
                                                    }
                                                  | {
                                                      __typename: "ImageMediaInfo"
                                                      id: string
                                                      resourceId?: string | null
                                                      thumbnail?: string | null
                                                      width?: number | null
                                                      height?: number | null
                                                      resolution?: number | null
                                                      colorDepth?: number | null
                                                      size?: number | null
                                                      mime?: string | null
                                                      extension?: string | null
                                                      allowCaching?: boolean | null
                                                      mediaType?: Types.MediaType | null
                                                      src?: string | null
                                                      language?: string | null
                                                    }
                                                  | {
                                                      __typename: "NoMediaInfo"
                                                      id: string
                                                      mediaType?: Types.MediaType | null
                                                    }
                                                  | {
                                                      __typename: "VectorGraphicsMediaInfo"
                                                      id: string
                                                      resourceId?: string | null
                                                      thumbnail?: string | null
                                                      width?: number | null
                                                      height?: number | null
                                                      allowCaching?: boolean | null
                                                      mediaType?: Types.MediaType | null
                                                      language?: string | null
                                                      labels: Array<{
                                                        __typename: "VectorGraphicsLabel"
                                                        id?: string | null
                                                        sequence?: number | null
                                                        autoValue?: string | null
                                                        text?: string | null
                                                        linkType?: Types.VectorGraphicsLabelLinkType | null
                                                        link?: string | null
                                                        ref?:
                                                          | {
                                                              __typename: "GenericRef"
                                                              id: string
                                                              owner?:
                                                                | { __typename: "CommentNode" }
                                                                | { __typename: "ExternalSite" }
                                                                | { __typename: "ST4Class" }
                                                                | { __typename: "ST4Node"; id: string }
                                                                | { __typename: "ST4TreeNode" }
                                                                | null
                                                              target?:
                                                                | { __typename: "CommentNode" }
                                                                | {
                                                                    __typename: "ExternalSite"
                                                                    id: string
                                                                    teaserData?: {
                                                                      __typename: "ExternalLinkTeaserData"
                                                                      title?: string | null
                                                                      summary?: string | null
                                                                      iconUrl?: string | null
                                                                      imageUrl?: string | null
                                                                      status?: Types.LinkTargetStatus | null
                                                                    } | null
                                                                  }
                                                                | { __typename: "ST4Class" }
                                                                | {
                                                                    __typename: "ST4Node"
                                                                    id: string
                                                                    aspectId: any
                                                                    label?: string | null
                                                                    properties?: {
                                                                      __typename: "ContentPropertyConnection"
                                                                      edges?: Array<{
                                                                        __typename: "ContentPropertyEdge"
                                                                        node?: {
                                                                          __typename: "ContentProperty"
                                                                          name: string
                                                                          value?: any | null
                                                                          displayName: string
                                                                        } | null
                                                                      } | null> | null
                                                                    } | null
                                                                  }
                                                                | { __typename: "ST4TreeNode" }
                                                                | null
                                                            }
                                                          | {
                                                              __typename: "NodeRef"
                                                              id: string
                                                              owner?:
                                                                | { __typename: "CommentNode" }
                                                                | { __typename: "ExternalSite" }
                                                                | { __typename: "ST4Class" }
                                                                | { __typename: "ST4Node"; id: string }
                                                                | { __typename: "ST4TreeNode" }
                                                                | null
                                                              target?:
                                                                | { __typename: "CommentNode" }
                                                                | {
                                                                    __typename: "ExternalSite"
                                                                    id: string
                                                                    teaserData?: {
                                                                      __typename: "ExternalLinkTeaserData"
                                                                      title?: string | null
                                                                      summary?: string | null
                                                                      iconUrl?: string | null
                                                                      imageUrl?: string | null
                                                                      status?: Types.LinkTargetStatus | null
                                                                    } | null
                                                                  }
                                                                | { __typename: "ST4Class" }
                                                                | {
                                                                    __typename: "ST4Node"
                                                                    id: string
                                                                    aspectId: any
                                                                    label?: string | null
                                                                    properties?: {
                                                                      __typename: "ContentPropertyConnection"
                                                                      edges?: Array<{
                                                                        __typename: "ContentPropertyEdge"
                                                                        node?: {
                                                                          __typename: "ContentProperty"
                                                                          name: string
                                                                          value?: any | null
                                                                          displayName: string
                                                                        } | null
                                                                      } | null> | null
                                                                    } | null
                                                                  }
                                                                | { __typename: "ST4TreeNode" }
                                                                | null
                                                            }
                                                          | null
                                                      }>
                                                    }
                                                  | {
                                                      __typename: "VideoMediaInfo"
                                                      id: string
                                                      resourceId?: string | null
                                                      thumbnail?: string | null
                                                      width?: number | null
                                                      height?: number | null
                                                      mediaType?: Types.MediaType | null
                                                    }
                                                  | null
                                              }
                                            | { __typename: "ProjectContent" }
                                            | { __typename: "TermContent" }
                                            | { __typename: "TextContent" }
                                            | { __typename: "TextGroupContent" }
                                            | { __typename: "VariableTableContent" }
                                            | null
                                        }
                                      | { __typename: "ST4TreeNode"; id: string }
                                      | null
                                  }>
                                  variableTables?: Array<{
                                    __typename: "VariableTableReference"
                                    ref?: string | null
                                    node?:
                                      | { __typename: "CommentNode" }
                                      | { __typename: "ExternalSite" }
                                      | { __typename: "ST4Class" }
                                      | {
                                          __typename: "ST4Node"
                                          id: string
                                          label?: string | null
                                          aspectId: any
                                          content?:
                                            | { __typename: "LexiconContent" }
                                            | { __typename: "LexiconEntryContent" }
                                            | { __typename: "MediaContent" }
                                            | { __typename: "MediaGroupContent" }
                                            | { __typename: "ProjectContent" }
                                            | { __typename: "TermContent" }
                                            | { __typename: "TextContent" }
                                            | { __typename: "TextGroupContent" }
                                            | {
                                                __typename: "VariableTableContent"
                                                xml?: string | null
                                                valueSets: Array<{
                                                  __typename: "ValueSet"
                                                  name: string
                                                  valueSetId: string
                                                }>
                                                variables: Array<{
                                                  __typename: "Variable"
                                                  name: string
                                                  variableId: string
                                                  values: Array<string>
                                                }>
                                              }
                                            | null
                                        }
                                      | { __typename: "ST4TreeNode" }
                                      | null
                                  } | null> | null
                                  linkedDataNodes: Array<{
                                    __typename: "NodeRef"
                                    id: string
                                    target?:
                                      | { __typename: "CommentNode"; id: string }
                                      | { __typename: "ExternalSite"; id: string }
                                      | { __typename: "ST4Class"; id: string }
                                      | {
                                          __typename: "ST4Node"
                                          label?: string | null
                                          id: string
                                          metadata: Array<{
                                            __typename: "MetaDataEntry"
                                            label?: string | null
                                            systemName: string
                                            value?: string | null
                                            values: Array<string>
                                            users: Array<string>
                                          }>
                                          linkedOntologyNodes: Array<
                                            | {
                                                __typename: "GenericRef"
                                                target?:
                                                  | { __typename: "CommentNode" }
                                                  | { __typename: "ExternalSite" }
                                                  | { __typename: "ST4Class" }
                                                  | { __typename: "ST4Node"; id: string; label?: string | null }
                                                  | { __typename: "ST4TreeNode" }
                                                  | null
                                              }
                                            | {
                                                __typename: "NodeRef"
                                                linkClassName: string
                                                class?: { __typename: "ST4Class"; label?: string | null } | null
                                                target?:
                                                  | { __typename: "CommentNode" }
                                                  | { __typename: "ExternalSite" }
                                                  | { __typename: "ST4Class" }
                                                  | { __typename: "ST4Node"; id: string; label?: string | null }
                                                  | { __typename: "ST4TreeNode" }
                                                  | null
                                              }
                                          >
                                        }
                                      | { __typename: "ST4TreeNode"; id: string }
                                      | null
                                  }>
                                  lexiconEntries?: Array<{
                                    __typename: "NodeRef"
                                    id: string
                                    target?:
                                      | { __typename: "CommentNode"; id: string }
                                      | { __typename: "ExternalSite"; id: string }
                                      | { __typename: "ST4Class"; id: string }
                                      | {
                                          __typename: "ST4Node"
                                          aspectId: any
                                          label?: string | null
                                          id: string
                                          content?:
                                            | { __typename: "LexiconContent" }
                                            | {
                                                __typename: "LexiconEntryContent"
                                                descriptionXast?: string | null
                                                subject?: string | null
                                                sortKey?: string | null
                                                lexicon:
                                                  | { __typename: "CommentNode"; id: string }
                                                  | { __typename: "ExternalSite"; id: string }
                                                  | { __typename: "ST4Class"; id: string }
                                                  | { __typename: "ST4Node"; label?: string | null; id: string }
                                                  | { __typename: "ST4TreeNode"; id: string }
                                                terms: Array<
                                                  | { __typename: "CommentNode"; id: string }
                                                  | { __typename: "ExternalSite"; id: string }
                                                  | { __typename: "ST4Class"; id: string }
                                                  | {
                                                      __typename: "ST4Node"
                                                      id: string
                                                      label?: string | null
                                                      aspectId: any
                                                      content?:
                                                        | { __typename: "LexiconContent" }
                                                        | { __typename: "LexiconEntryContent" }
                                                        | { __typename: "MediaContent" }
                                                        | { __typename: "MediaGroupContent" }
                                                        | { __typename: "ProjectContent" }
                                                        | {
                                                            __typename: "TermContent"
                                                            gramaticalGender?: string | null
                                                            validity?: string | null
                                                            editLanguage?: string | null
                                                          }
                                                        | { __typename: "TextContent" }
                                                        | { __typename: "TextGroupContent" }
                                                        | { __typename: "VariableTableContent" }
                                                        | null
                                                    }
                                                  | { __typename: "ST4TreeNode"; id: string }
                                                >
                                                image?:
                                                  | { __typename: "CommentNode"; id: string }
                                                  | { __typename: "ExternalSite"; id: string }
                                                  | { __typename: "ST4Class"; id: string }
                                                  | {
                                                      __typename: "ST4Node"
                                                      aspectId: any
                                                      id: string
                                                      content?:
                                                        | { __typename: "LexiconContent" }
                                                        | { __typename: "LexiconEntryContent" }
                                                        | {
                                                            __typename: "MediaContent"
                                                            media?:
                                                              | {
                                                                  __typename: "AnimationMediaInfo"
                                                                  thumbnail?: string | null
                                                                  mediaType?: Types.MediaType | null
                                                                }
                                                              | {
                                                                  __typename: "ImageMediaInfo"
                                                                  src?: string | null
                                                                  thumbnail?: string | null
                                                                  mediaType?: Types.MediaType | null
                                                                }
                                                              | { __typename: "NoMediaInfo" }
                                                              | {
                                                                  __typename: "VectorGraphicsMediaInfo"
                                                                  svg?: string | null
                                                                  thumbnail?: string | null
                                                                  mediaType?: Types.MediaType | null
                                                                }
                                                              | {
                                                                  __typename: "VideoMediaInfo"
                                                                  thumbnail?: string | null
                                                                  mediaType?: Types.MediaType | null
                                                                }
                                                              | null
                                                          }
                                                        | { __typename: "MediaGroupContent" }
                                                        | { __typename: "ProjectContent" }
                                                        | { __typename: "TermContent" }
                                                        | { __typename: "TextContent" }
                                                        | { __typename: "TextGroupContent" }
                                                        | { __typename: "VariableTableContent" }
                                                        | null
                                                    }
                                                  | { __typename: "ST4TreeNode"; id: string }
                                                  | null
                                              }
                                            | { __typename: "MediaContent" }
                                            | { __typename: "MediaGroupContent" }
                                            | { __typename: "ProjectContent" }
                                            | { __typename: "TermContent" }
                                            | { __typename: "TextContent" }
                                            | { __typename: "TextGroupContent" }
                                            | { __typename: "VariableTableContent" }
                                            | null
                                        }
                                      | { __typename: "ST4TreeNode"; id: string }
                                      | null
                                  } | null> | null
                                  comments: Array<{
                                    __typename: "CommentNode"
                                    id: string
                                    comment:
                                      | {
                                          __typename: "BlockComment"
                                          location: number
                                          value?: string | null
                                          authorDisplayName?: string | null
                                          authorUserName?: string | null
                                          commentKey: string
                                          editDate?: any | null
                                          modificationVersion?: number | null
                                          replyToKey?: string | null
                                          sortOrder?: number | null
                                          commentType?: string | null
                                          state?: string | null
                                          color: string
                                          persistanceState?: Types.PersistanceState | null
                                          persistanceAction?: Types.PersistanceAction | null
                                          node: {
                                            __typename: "ST4Node"
                                            aspectId: any
                                            id: string
                                            content?:
                                              | { __typename: "LexiconContent" }
                                              | { __typename: "LexiconEntryContent" }
                                              | { __typename: "MediaContent" }
                                              | { __typename: "MediaGroupContent" }
                                              | { __typename: "ProjectContent" }
                                              | { __typename: "TermContent" }
                                              | { __typename: "TextContent"; id: string; historyNumber: number }
                                              | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                                              | { __typename: "VariableTableContent" }
                                              | null
                                          }
                                          actions: Array<{
                                            __typename: "Action"
                                            name?: string | null
                                            enabled?: boolean | null
                                          }>
                                        }
                                      | {
                                          __typename: "TextComment"
                                          endLocation: number
                                          startLocation?: number | null
                                          value?: string | null
                                          authorDisplayName?: string | null
                                          authorUserName?: string | null
                                          commentKey: string
                                          editDate?: any | null
                                          modificationVersion?: number | null
                                          replyToKey?: string | null
                                          sortOrder?: number | null
                                          commentType?: string | null
                                          state?: string | null
                                          color: string
                                          persistanceState?: Types.PersistanceState | null
                                          persistanceAction?: Types.PersistanceAction | null
                                          node: {
                                            __typename: "ST4Node"
                                            aspectId: any
                                            id: string
                                            content?:
                                              | { __typename: "LexiconContent" }
                                              | { __typename: "LexiconEntryContent" }
                                              | { __typename: "MediaContent" }
                                              | { __typename: "MediaGroupContent" }
                                              | { __typename: "ProjectContent" }
                                              | { __typename: "TermContent" }
                                              | { __typename: "TextContent"; id: string; historyNumber: number }
                                              | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                                              | { __typename: "VariableTableContent" }
                                              | null
                                          }
                                          actions: Array<{
                                            __typename: "Action"
                                            name?: string | null
                                            enabled?: boolean | null
                                          }>
                                        }
                                  }>
                                }
                              | { __typename: "VariableTableContent" }
                              | null
                          }
                        }>
                        links?: Array<{
                          __typename: "TextContentLink"
                          ref?: string | null
                          node?:
                            | { __typename: "CommentNode"; id: string }
                            | { __typename: "ExternalSite"; id: string }
                            | { __typename: "ST4Class"; id: string }
                            | {
                                __typename: "ST4Node"
                                id: string
                                aspectId: any
                                label?: string | null
                                properties?: {
                                  __typename: "ContentPropertyConnection"
                                  totalCount: number
                                  edges?: Array<{
                                    __typename: "ContentPropertyEdge"
                                    node?: {
                                      __typename: "ContentProperty"
                                      typeOfValue: Types.ContentPropertyValueType
                                      name: string
                                      value?: any | null
                                      displayName: string
                                    } | null
                                  } | null> | null
                                } | null
                              }
                            | { __typename: "ST4TreeNode"; id: string }
                            | null
                        } | null> | null
                        images: Array<{
                          __typename: "NodeRef"
                          id: string
                          target?:
                            | { __typename: "CommentNode"; id: string }
                            | { __typename: "ExternalSite"; id: string }
                            | { __typename: "ST4Class"; id: string }
                            | {
                                __typename: "ST4Node"
                                aspectId: any
                                id: string
                                releaseState: {
                                  __typename: "ReleaseStateValue"
                                  displayName: string
                                  state: Types.ReleaseState
                                  isImplicitlyReleased: boolean
                                }
                                content?:
                                  | { __typename: "LexiconContent" }
                                  | { __typename: "LexiconEntryContent" }
                                  | {
                                      __typename: "MediaContent"
                                      media?:
                                        | {
                                            __typename: "AnimationMediaInfo"
                                            id: string
                                            resourceId?: string | null
                                            thumbnail?: string | null
                                            width?: number | null
                                            height?: number | null
                                            mediaType?: Types.MediaType | null
                                          }
                                        | {
                                            __typename: "ImageMediaInfo"
                                            id: string
                                            resourceId?: string | null
                                            thumbnail?: string | null
                                            width?: number | null
                                            height?: number | null
                                            resolution?: number | null
                                            colorDepth?: number | null
                                            size?: number | null
                                            mime?: string | null
                                            extension?: string | null
                                            allowCaching?: boolean | null
                                            mediaType?: Types.MediaType | null
                                            src?: string | null
                                            language?: string | null
                                          }
                                        | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                                        | {
                                            __typename: "VectorGraphicsMediaInfo"
                                            id: string
                                            resourceId?: string | null
                                            thumbnail?: string | null
                                            width?: number | null
                                            height?: number | null
                                            allowCaching?: boolean | null
                                            mediaType?: Types.MediaType | null
                                            language?: string | null
                                            labels: Array<{
                                              __typename: "VectorGraphicsLabel"
                                              id?: string | null
                                              sequence?: number | null
                                              autoValue?: string | null
                                              text?: string | null
                                              linkType?: Types.VectorGraphicsLabelLinkType | null
                                              link?: string | null
                                              ref?:
                                                | {
                                                    __typename: "GenericRef"
                                                    id: string
                                                    owner?:
                                                      | { __typename: "CommentNode" }
                                                      | { __typename: "ExternalSite" }
                                                      | { __typename: "ST4Class" }
                                                      | { __typename: "ST4Node"; id: string }
                                                      | { __typename: "ST4TreeNode" }
                                                      | null
                                                    target?:
                                                      | { __typename: "CommentNode" }
                                                      | {
                                                          __typename: "ExternalSite"
                                                          id: string
                                                          teaserData?: {
                                                            __typename: "ExternalLinkTeaserData"
                                                            title?: string | null
                                                            summary?: string | null
                                                            iconUrl?: string | null
                                                            imageUrl?: string | null
                                                            status?: Types.LinkTargetStatus | null
                                                          } | null
                                                        }
                                                      | { __typename: "ST4Class" }
                                                      | {
                                                          __typename: "ST4Node"
                                                          id: string
                                                          aspectId: any
                                                          label?: string | null
                                                          properties?: {
                                                            __typename: "ContentPropertyConnection"
                                                            edges?: Array<{
                                                              __typename: "ContentPropertyEdge"
                                                              node?: {
                                                                __typename: "ContentProperty"
                                                                name: string
                                                                value?: any | null
                                                                displayName: string
                                                              } | null
                                                            } | null> | null
                                                          } | null
                                                        }
                                                      | { __typename: "ST4TreeNode" }
                                                      | null
                                                  }
                                                | {
                                                    __typename: "NodeRef"
                                                    id: string
                                                    owner?:
                                                      | { __typename: "CommentNode" }
                                                      | { __typename: "ExternalSite" }
                                                      | { __typename: "ST4Class" }
                                                      | { __typename: "ST4Node"; id: string }
                                                      | { __typename: "ST4TreeNode" }
                                                      | null
                                                    target?:
                                                      | { __typename: "CommentNode" }
                                                      | {
                                                          __typename: "ExternalSite"
                                                          id: string
                                                          teaserData?: {
                                                            __typename: "ExternalLinkTeaserData"
                                                            title?: string | null
                                                            summary?: string | null
                                                            iconUrl?: string | null
                                                            imageUrl?: string | null
                                                            status?: Types.LinkTargetStatus | null
                                                          } | null
                                                        }
                                                      | { __typename: "ST4Class" }
                                                      | {
                                                          __typename: "ST4Node"
                                                          id: string
                                                          aspectId: any
                                                          label?: string | null
                                                          properties?: {
                                                            __typename: "ContentPropertyConnection"
                                                            edges?: Array<{
                                                              __typename: "ContentPropertyEdge"
                                                              node?: {
                                                                __typename: "ContentProperty"
                                                                name: string
                                                                value?: any | null
                                                                displayName: string
                                                              } | null
                                                            } | null> | null
                                                          } | null
                                                        }
                                                      | { __typename: "ST4TreeNode" }
                                                      | null
                                                  }
                                                | null
                                            }>
                                          }
                                        | {
                                            __typename: "VideoMediaInfo"
                                            id: string
                                            resourceId?: string | null
                                            thumbnail?: string | null
                                            width?: number | null
                                            height?: number | null
                                            mediaType?: Types.MediaType | null
                                          }
                                        | null
                                    }
                                  | {
                                      __typename: "MediaGroupContent"
                                      children: Array<{
                                        __typename: "NodeRef"
                                        id: string
                                        target?:
                                          | { __typename: "CommentNode"; id: string }
                                          | { __typename: "ExternalSite"; id: string }
                                          | { __typename: "ST4Class"; id: string }
                                          | {
                                              __typename: "ST4Node"
                                              id: string
                                              aspectId: any
                                              content?:
                                                | { __typename: "LexiconContent" }
                                                | { __typename: "LexiconEntryContent" }
                                                | {
                                                    __typename: "MediaContent"
                                                    media?:
                                                      | {
                                                          __typename: "AnimationMediaInfo"
                                                          id: string
                                                          resourceId?: string | null
                                                          thumbnail?: string | null
                                                          width?: number | null
                                                          height?: number | null
                                                          mediaType?: Types.MediaType | null
                                                        }
                                                      | {
                                                          __typename: "ImageMediaInfo"
                                                          id: string
                                                          resourceId?: string | null
                                                          thumbnail?: string | null
                                                          width?: number | null
                                                          height?: number | null
                                                          resolution?: number | null
                                                          colorDepth?: number | null
                                                          size?: number | null
                                                          mime?: string | null
                                                          extension?: string | null
                                                          allowCaching?: boolean | null
                                                          mediaType?: Types.MediaType | null
                                                          src?: string | null
                                                          language?: string | null
                                                        }
                                                      | {
                                                          __typename: "NoMediaInfo"
                                                          id: string
                                                          mediaType?: Types.MediaType | null
                                                        }
                                                      | {
                                                          __typename: "VectorGraphicsMediaInfo"
                                                          id: string
                                                          resourceId?: string | null
                                                          thumbnail?: string | null
                                                          width?: number | null
                                                          height?: number | null
                                                          allowCaching?: boolean | null
                                                          mediaType?: Types.MediaType | null
                                                          language?: string | null
                                                          labels: Array<{
                                                            __typename: "VectorGraphicsLabel"
                                                            id?: string | null
                                                            sequence?: number | null
                                                            autoValue?: string | null
                                                            text?: string | null
                                                            linkType?: Types.VectorGraphicsLabelLinkType | null
                                                            link?: string | null
                                                            ref?:
                                                              | {
                                                                  __typename: "GenericRef"
                                                                  id: string
                                                                  owner?:
                                                                    | { __typename: "CommentNode" }
                                                                    | { __typename: "ExternalSite" }
                                                                    | { __typename: "ST4Class" }
                                                                    | { __typename: "ST4Node"; id: string }
                                                                    | { __typename: "ST4TreeNode" }
                                                                    | null
                                                                  target?:
                                                                    | { __typename: "CommentNode" }
                                                                    | {
                                                                        __typename: "ExternalSite"
                                                                        id: string
                                                                        teaserData?: {
                                                                          __typename: "ExternalLinkTeaserData"
                                                                          title?: string | null
                                                                          summary?: string | null
                                                                          iconUrl?: string | null
                                                                          imageUrl?: string | null
                                                                          status?: Types.LinkTargetStatus | null
                                                                        } | null
                                                                      }
                                                                    | { __typename: "ST4Class" }
                                                                    | {
                                                                        __typename: "ST4Node"
                                                                        id: string
                                                                        aspectId: any
                                                                        label?: string | null
                                                                        properties?: {
                                                                          __typename: "ContentPropertyConnection"
                                                                          edges?: Array<{
                                                                            __typename: "ContentPropertyEdge"
                                                                            node?: {
                                                                              __typename: "ContentProperty"
                                                                              name: string
                                                                              value?: any | null
                                                                              displayName: string
                                                                            } | null
                                                                          } | null> | null
                                                                        } | null
                                                                      }
                                                                    | { __typename: "ST4TreeNode" }
                                                                    | null
                                                                }
                                                              | {
                                                                  __typename: "NodeRef"
                                                                  id: string
                                                                  owner?:
                                                                    | { __typename: "CommentNode" }
                                                                    | { __typename: "ExternalSite" }
                                                                    | { __typename: "ST4Class" }
                                                                    | { __typename: "ST4Node"; id: string }
                                                                    | { __typename: "ST4TreeNode" }
                                                                    | null
                                                                  target?:
                                                                    | { __typename: "CommentNode" }
                                                                    | {
                                                                        __typename: "ExternalSite"
                                                                        id: string
                                                                        teaserData?: {
                                                                          __typename: "ExternalLinkTeaserData"
                                                                          title?: string | null
                                                                          summary?: string | null
                                                                          iconUrl?: string | null
                                                                          imageUrl?: string | null
                                                                          status?: Types.LinkTargetStatus | null
                                                                        } | null
                                                                      }
                                                                    | { __typename: "ST4Class" }
                                                                    | {
                                                                        __typename: "ST4Node"
                                                                        id: string
                                                                        aspectId: any
                                                                        label?: string | null
                                                                        properties?: {
                                                                          __typename: "ContentPropertyConnection"
                                                                          edges?: Array<{
                                                                            __typename: "ContentPropertyEdge"
                                                                            node?: {
                                                                              __typename: "ContentProperty"
                                                                              name: string
                                                                              value?: any | null
                                                                              displayName: string
                                                                            } | null
                                                                          } | null> | null
                                                                        } | null
                                                                      }
                                                                    | { __typename: "ST4TreeNode" }
                                                                    | null
                                                                }
                                                              | null
                                                          }>
                                                        }
                                                      | {
                                                          __typename: "VideoMediaInfo"
                                                          id: string
                                                          resourceId?: string | null
                                                          thumbnail?: string | null
                                                          width?: number | null
                                                          height?: number | null
                                                          mediaType?: Types.MediaType | null
                                                        }
                                                      | null
                                                  }
                                                | {
                                                    __typename: "MediaGroupContent"
                                                    media?:
                                                      | {
                                                          __typename: "AnimationMediaInfo"
                                                          id: string
                                                          resourceId?: string | null
                                                          thumbnail?: string | null
                                                          width?: number | null
                                                          height?: number | null
                                                          mediaType?: Types.MediaType | null
                                                        }
                                                      | {
                                                          __typename: "ImageMediaInfo"
                                                          id: string
                                                          resourceId?: string | null
                                                          thumbnail?: string | null
                                                          width?: number | null
                                                          height?: number | null
                                                          resolution?: number | null
                                                          colorDepth?: number | null
                                                          size?: number | null
                                                          mime?: string | null
                                                          extension?: string | null
                                                          allowCaching?: boolean | null
                                                          mediaType?: Types.MediaType | null
                                                          src?: string | null
                                                          language?: string | null
                                                        }
                                                      | {
                                                          __typename: "NoMediaInfo"
                                                          id: string
                                                          mediaType?: Types.MediaType | null
                                                        }
                                                      | {
                                                          __typename: "VectorGraphicsMediaInfo"
                                                          id: string
                                                          resourceId?: string | null
                                                          thumbnail?: string | null
                                                          width?: number | null
                                                          height?: number | null
                                                          allowCaching?: boolean | null
                                                          mediaType?: Types.MediaType | null
                                                          language?: string | null
                                                          labels: Array<{
                                                            __typename: "VectorGraphicsLabel"
                                                            id?: string | null
                                                            sequence?: number | null
                                                            autoValue?: string | null
                                                            text?: string | null
                                                            linkType?: Types.VectorGraphicsLabelLinkType | null
                                                            link?: string | null
                                                            ref?:
                                                              | {
                                                                  __typename: "GenericRef"
                                                                  id: string
                                                                  owner?:
                                                                    | { __typename: "CommentNode" }
                                                                    | { __typename: "ExternalSite" }
                                                                    | { __typename: "ST4Class" }
                                                                    | { __typename: "ST4Node"; id: string }
                                                                    | { __typename: "ST4TreeNode" }
                                                                    | null
                                                                  target?:
                                                                    | { __typename: "CommentNode" }
                                                                    | {
                                                                        __typename: "ExternalSite"
                                                                        id: string
                                                                        teaserData?: {
                                                                          __typename: "ExternalLinkTeaserData"
                                                                          title?: string | null
                                                                          summary?: string | null
                                                                          iconUrl?: string | null
                                                                          imageUrl?: string | null
                                                                          status?: Types.LinkTargetStatus | null
                                                                        } | null
                                                                      }
                                                                    | { __typename: "ST4Class" }
                                                                    | {
                                                                        __typename: "ST4Node"
                                                                        id: string
                                                                        aspectId: any
                                                                        label?: string | null
                                                                        properties?: {
                                                                          __typename: "ContentPropertyConnection"
                                                                          edges?: Array<{
                                                                            __typename: "ContentPropertyEdge"
                                                                            node?: {
                                                                              __typename: "ContentProperty"
                                                                              name: string
                                                                              value?: any | null
                                                                              displayName: string
                                                                            } | null
                                                                          } | null> | null
                                                                        } | null
                                                                      }
                                                                    | { __typename: "ST4TreeNode" }
                                                                    | null
                                                                }
                                                              | {
                                                                  __typename: "NodeRef"
                                                                  id: string
                                                                  owner?:
                                                                    | { __typename: "CommentNode" }
                                                                    | { __typename: "ExternalSite" }
                                                                    | { __typename: "ST4Class" }
                                                                    | { __typename: "ST4Node"; id: string }
                                                                    | { __typename: "ST4TreeNode" }
                                                                    | null
                                                                  target?:
                                                                    | { __typename: "CommentNode" }
                                                                    | {
                                                                        __typename: "ExternalSite"
                                                                        id: string
                                                                        teaserData?: {
                                                                          __typename: "ExternalLinkTeaserData"
                                                                          title?: string | null
                                                                          summary?: string | null
                                                                          iconUrl?: string | null
                                                                          imageUrl?: string | null
                                                                          status?: Types.LinkTargetStatus | null
                                                                        } | null
                                                                      }
                                                                    | { __typename: "ST4Class" }
                                                                    | {
                                                                        __typename: "ST4Node"
                                                                        id: string
                                                                        aspectId: any
                                                                        label?: string | null
                                                                        properties?: {
                                                                          __typename: "ContentPropertyConnection"
                                                                          edges?: Array<{
                                                                            __typename: "ContentPropertyEdge"
                                                                            node?: {
                                                                              __typename: "ContentProperty"
                                                                              name: string
                                                                              value?: any | null
                                                                              displayName: string
                                                                            } | null
                                                                          } | null> | null
                                                                        } | null
                                                                      }
                                                                    | { __typename: "ST4TreeNode" }
                                                                    | null
                                                                }
                                                              | null
                                                          }>
                                                        }
                                                      | {
                                                          __typename: "VideoMediaInfo"
                                                          id: string
                                                          resourceId?: string | null
                                                          thumbnail?: string | null
                                                          width?: number | null
                                                          height?: number | null
                                                          mediaType?: Types.MediaType | null
                                                        }
                                                      | null
                                                  }
                                                | { __typename: "ProjectContent" }
                                                | { __typename: "TermContent" }
                                                | { __typename: "TextContent" }
                                                | { __typename: "TextGroupContent" }
                                                | { __typename: "VariableTableContent" }
                                                | null
                                            }
                                          | { __typename: "ST4TreeNode"; id: string }
                                          | null
                                      }>
                                      media?:
                                        | {
                                            __typename: "AnimationMediaInfo"
                                            id: string
                                            resourceId?: string | null
                                            thumbnail?: string | null
                                            width?: number | null
                                            height?: number | null
                                            mediaType?: Types.MediaType | null
                                          }
                                        | {
                                            __typename: "ImageMediaInfo"
                                            id: string
                                            resourceId?: string | null
                                            thumbnail?: string | null
                                            width?: number | null
                                            height?: number | null
                                            resolution?: number | null
                                            colorDepth?: number | null
                                            size?: number | null
                                            mime?: string | null
                                            extension?: string | null
                                            allowCaching?: boolean | null
                                            mediaType?: Types.MediaType | null
                                            src?: string | null
                                            language?: string | null
                                          }
                                        | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                                        | {
                                            __typename: "VectorGraphicsMediaInfo"
                                            id: string
                                            resourceId?: string | null
                                            thumbnail?: string | null
                                            width?: number | null
                                            height?: number | null
                                            allowCaching?: boolean | null
                                            mediaType?: Types.MediaType | null
                                            language?: string | null
                                            labels: Array<{
                                              __typename: "VectorGraphicsLabel"
                                              id?: string | null
                                              sequence?: number | null
                                              autoValue?: string | null
                                              text?: string | null
                                              linkType?: Types.VectorGraphicsLabelLinkType | null
                                              link?: string | null
                                              ref?:
                                                | {
                                                    __typename: "GenericRef"
                                                    id: string
                                                    owner?:
                                                      | { __typename: "CommentNode" }
                                                      | { __typename: "ExternalSite" }
                                                      | { __typename: "ST4Class" }
                                                      | { __typename: "ST4Node"; id: string }
                                                      | { __typename: "ST4TreeNode" }
                                                      | null
                                                    target?:
                                                      | { __typename: "CommentNode" }
                                                      | {
                                                          __typename: "ExternalSite"
                                                          id: string
                                                          teaserData?: {
                                                            __typename: "ExternalLinkTeaserData"
                                                            title?: string | null
                                                            summary?: string | null
                                                            iconUrl?: string | null
                                                            imageUrl?: string | null
                                                            status?: Types.LinkTargetStatus | null
                                                          } | null
                                                        }
                                                      | { __typename: "ST4Class" }
                                                      | {
                                                          __typename: "ST4Node"
                                                          id: string
                                                          aspectId: any
                                                          label?: string | null
                                                          properties?: {
                                                            __typename: "ContentPropertyConnection"
                                                            edges?: Array<{
                                                              __typename: "ContentPropertyEdge"
                                                              node?: {
                                                                __typename: "ContentProperty"
                                                                name: string
                                                                value?: any | null
                                                                displayName: string
                                                              } | null
                                                            } | null> | null
                                                          } | null
                                                        }
                                                      | { __typename: "ST4TreeNode" }
                                                      | null
                                                  }
                                                | {
                                                    __typename: "NodeRef"
                                                    id: string
                                                    owner?:
                                                      | { __typename: "CommentNode" }
                                                      | { __typename: "ExternalSite" }
                                                      | { __typename: "ST4Class" }
                                                      | { __typename: "ST4Node"; id: string }
                                                      | { __typename: "ST4TreeNode" }
                                                      | null
                                                    target?:
                                                      | { __typename: "CommentNode" }
                                                      | {
                                                          __typename: "ExternalSite"
                                                          id: string
                                                          teaserData?: {
                                                            __typename: "ExternalLinkTeaserData"
                                                            title?: string | null
                                                            summary?: string | null
                                                            iconUrl?: string | null
                                                            imageUrl?: string | null
                                                            status?: Types.LinkTargetStatus | null
                                                          } | null
                                                        }
                                                      | { __typename: "ST4Class" }
                                                      | {
                                                          __typename: "ST4Node"
                                                          id: string
                                                          aspectId: any
                                                          label?: string | null
                                                          properties?: {
                                                            __typename: "ContentPropertyConnection"
                                                            edges?: Array<{
                                                              __typename: "ContentPropertyEdge"
                                                              node?: {
                                                                __typename: "ContentProperty"
                                                                name: string
                                                                value?: any | null
                                                                displayName: string
                                                              } | null
                                                            } | null> | null
                                                          } | null
                                                        }
                                                      | { __typename: "ST4TreeNode" }
                                                      | null
                                                  }
                                                | null
                                            }>
                                          }
                                        | {
                                            __typename: "VideoMediaInfo"
                                            id: string
                                            resourceId?: string | null
                                            thumbnail?: string | null
                                            width?: number | null
                                            height?: number | null
                                            mediaType?: Types.MediaType | null
                                          }
                                        | null
                                    }
                                  | { __typename: "ProjectContent" }
                                  | { __typename: "TermContent" }
                                  | { __typename: "TextContent" }
                                  | { __typename: "TextGroupContent" }
                                  | { __typename: "VariableTableContent" }
                                  | null
                              }
                            | { __typename: "ST4TreeNode"; id: string }
                            | null
                        }>
                        variableTables?: Array<{
                          __typename: "VariableTableReference"
                          ref?: string | null
                          node?:
                            | { __typename: "CommentNode" }
                            | { __typename: "ExternalSite" }
                            | { __typename: "ST4Class" }
                            | {
                                __typename: "ST4Node"
                                id: string
                                label?: string | null
                                aspectId: any
                                content?:
                                  | { __typename: "LexiconContent" }
                                  | { __typename: "LexiconEntryContent" }
                                  | { __typename: "MediaContent" }
                                  | { __typename: "MediaGroupContent" }
                                  | { __typename: "ProjectContent" }
                                  | { __typename: "TermContent" }
                                  | { __typename: "TextContent" }
                                  | { __typename: "TextGroupContent" }
                                  | {
                                      __typename: "VariableTableContent"
                                      xml?: string | null
                                      valueSets: Array<{ __typename: "ValueSet"; name: string; valueSetId: string }>
                                      variables: Array<{
                                        __typename: "Variable"
                                        name: string
                                        variableId: string
                                        values: Array<string>
                                      }>
                                    }
                                  | null
                              }
                            | { __typename: "ST4TreeNode" }
                            | null
                        } | null> | null
                        linkedDataNodes: Array<{
                          __typename: "NodeRef"
                          id: string
                          target?:
                            | { __typename: "CommentNode"; id: string }
                            | { __typename: "ExternalSite"; id: string }
                            | { __typename: "ST4Class"; id: string }
                            | {
                                __typename: "ST4Node"
                                label?: string | null
                                id: string
                                metadata: Array<{
                                  __typename: "MetaDataEntry"
                                  label?: string | null
                                  systemName: string
                                  value?: string | null
                                  values: Array<string>
                                  users: Array<string>
                                }>
                                linkedOntologyNodes: Array<
                                  | {
                                      __typename: "GenericRef"
                                      target?:
                                        | { __typename: "CommentNode" }
                                        | { __typename: "ExternalSite" }
                                        | { __typename: "ST4Class" }
                                        | { __typename: "ST4Node"; id: string; label?: string | null }
                                        | { __typename: "ST4TreeNode" }
                                        | null
                                    }
                                  | {
                                      __typename: "NodeRef"
                                      linkClassName: string
                                      class?: { __typename: "ST4Class"; label?: string | null } | null
                                      target?:
                                        | { __typename: "CommentNode" }
                                        | { __typename: "ExternalSite" }
                                        | { __typename: "ST4Class" }
                                        | { __typename: "ST4Node"; id: string; label?: string | null }
                                        | { __typename: "ST4TreeNode" }
                                        | null
                                    }
                                >
                              }
                            | { __typename: "ST4TreeNode"; id: string }
                            | null
                        }>
                        lexiconEntries?: Array<{
                          __typename: "NodeRef"
                          id: string
                          target?:
                            | { __typename: "CommentNode"; id: string }
                            | { __typename: "ExternalSite"; id: string }
                            | { __typename: "ST4Class"; id: string }
                            | {
                                __typename: "ST4Node"
                                aspectId: any
                                label?: string | null
                                id: string
                                content?:
                                  | { __typename: "LexiconContent" }
                                  | {
                                      __typename: "LexiconEntryContent"
                                      descriptionXast?: string | null
                                      subject?: string | null
                                      sortKey?: string | null
                                      lexicon:
                                        | { __typename: "CommentNode"; id: string }
                                        | { __typename: "ExternalSite"; id: string }
                                        | { __typename: "ST4Class"; id: string }
                                        | { __typename: "ST4Node"; label?: string | null; id: string }
                                        | { __typename: "ST4TreeNode"; id: string }
                                      terms: Array<
                                        | { __typename: "CommentNode"; id: string }
                                        | { __typename: "ExternalSite"; id: string }
                                        | { __typename: "ST4Class"; id: string }
                                        | {
                                            __typename: "ST4Node"
                                            id: string
                                            label?: string | null
                                            aspectId: any
                                            content?:
                                              | { __typename: "LexiconContent" }
                                              | { __typename: "LexiconEntryContent" }
                                              | { __typename: "MediaContent" }
                                              | { __typename: "MediaGroupContent" }
                                              | { __typename: "ProjectContent" }
                                              | {
                                                  __typename: "TermContent"
                                                  gramaticalGender?: string | null
                                                  validity?: string | null
                                                  editLanguage?: string | null
                                                }
                                              | { __typename: "TextContent" }
                                              | { __typename: "TextGroupContent" }
                                              | { __typename: "VariableTableContent" }
                                              | null
                                          }
                                        | { __typename: "ST4TreeNode"; id: string }
                                      >
                                      image?:
                                        | { __typename: "CommentNode"; id: string }
                                        | { __typename: "ExternalSite"; id: string }
                                        | { __typename: "ST4Class"; id: string }
                                        | {
                                            __typename: "ST4Node"
                                            aspectId: any
                                            id: string
                                            content?:
                                              | { __typename: "LexiconContent" }
                                              | { __typename: "LexiconEntryContent" }
                                              | {
                                                  __typename: "MediaContent"
                                                  media?:
                                                    | {
                                                        __typename: "AnimationMediaInfo"
                                                        thumbnail?: string | null
                                                        mediaType?: Types.MediaType | null
                                                      }
                                                    | {
                                                        __typename: "ImageMediaInfo"
                                                        src?: string | null
                                                        thumbnail?: string | null
                                                        mediaType?: Types.MediaType | null
                                                      }
                                                    | { __typename: "NoMediaInfo" }
                                                    | {
                                                        __typename: "VectorGraphicsMediaInfo"
                                                        svg?: string | null
                                                        thumbnail?: string | null
                                                        mediaType?: Types.MediaType | null
                                                      }
                                                    | {
                                                        __typename: "VideoMediaInfo"
                                                        thumbnail?: string | null
                                                        mediaType?: Types.MediaType | null
                                                      }
                                                    | null
                                                }
                                              | { __typename: "MediaGroupContent" }
                                              | { __typename: "ProjectContent" }
                                              | { __typename: "TermContent" }
                                              | { __typename: "TextContent" }
                                              | { __typename: "TextGroupContent" }
                                              | { __typename: "VariableTableContent" }
                                              | null
                                          }
                                        | { __typename: "ST4TreeNode"; id: string }
                                        | null
                                    }
                                  | { __typename: "MediaContent" }
                                  | { __typename: "MediaGroupContent" }
                                  | { __typename: "ProjectContent" }
                                  | { __typename: "TermContent" }
                                  | { __typename: "TextContent" }
                                  | { __typename: "TextGroupContent" }
                                  | { __typename: "VariableTableContent" }
                                  | null
                              }
                            | { __typename: "ST4TreeNode"; id: string }
                            | null
                        } | null> | null
                        comments: Array<{
                          __typename: "CommentNode"
                          id: string
                          comment:
                            | {
                                __typename: "BlockComment"
                                location: number
                                value?: string | null
                                authorDisplayName?: string | null
                                authorUserName?: string | null
                                commentKey: string
                                editDate?: any | null
                                modificationVersion?: number | null
                                replyToKey?: string | null
                                sortOrder?: number | null
                                commentType?: string | null
                                state?: string | null
                                color: string
                                persistanceState?: Types.PersistanceState | null
                                persistanceAction?: Types.PersistanceAction | null
                                node: {
                                  __typename: "ST4Node"
                                  aspectId: any
                                  id: string
                                  content?:
                                    | { __typename: "LexiconContent" }
                                    | { __typename: "LexiconEntryContent" }
                                    | { __typename: "MediaContent" }
                                    | { __typename: "MediaGroupContent" }
                                    | { __typename: "ProjectContent" }
                                    | { __typename: "TermContent" }
                                    | { __typename: "TextContent"; id: string; historyNumber: number }
                                    | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                                    | { __typename: "VariableTableContent" }
                                    | null
                                }
                                actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
                              }
                            | {
                                __typename: "TextComment"
                                endLocation: number
                                startLocation?: number | null
                                value?: string | null
                                authorDisplayName?: string | null
                                authorUserName?: string | null
                                commentKey: string
                                editDate?: any | null
                                modificationVersion?: number | null
                                replyToKey?: string | null
                                sortOrder?: number | null
                                commentType?: string | null
                                state?: string | null
                                color: string
                                persistanceState?: Types.PersistanceState | null
                                persistanceAction?: Types.PersistanceAction | null
                                node: {
                                  __typename: "ST4Node"
                                  aspectId: any
                                  id: string
                                  content?:
                                    | { __typename: "LexiconContent" }
                                    | { __typename: "LexiconEntryContent" }
                                    | { __typename: "MediaContent" }
                                    | { __typename: "MediaGroupContent" }
                                    | { __typename: "ProjectContent" }
                                    | { __typename: "TermContent" }
                                    | { __typename: "TextContent"; id: string; historyNumber: number }
                                    | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                                    | { __typename: "VariableTableContent" }
                                    | null
                                }
                                actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
                              }
                        }>
                      }
                    | {
                        __typename: "TextGroupContent"
                        currentNodeId?: any | null
                        groupNodeId: any
                        id: string
                        xast?: string | null
                        historyNumber: number
                        parts: Array<{
                          __typename: "TextContentPart"
                          ref: string
                          contentNode: {
                            __typename: "ST4Node"
                            id: string
                            aspectId: any
                            content?:
                              | { __typename: "LexiconContent" }
                              | { __typename: "LexiconEntryContent" }
                              | { __typename: "MediaContent" }
                              | { __typename: "MediaGroupContent" }
                              | { __typename: "ProjectContent" }
                              | { __typename: "TermContent" }
                              | {
                                  __typename: "TextContent"
                                  id: string
                                  xast?: string | null
                                  historyNumber: number
                                  links?: Array<{
                                    __typename: "TextContentLink"
                                    ref?: string | null
                                    node?:
                                      | { __typename: "CommentNode"; id: string }
                                      | { __typename: "ExternalSite"; id: string }
                                      | { __typename: "ST4Class"; id: string }
                                      | {
                                          __typename: "ST4Node"
                                          id: string
                                          aspectId: any
                                          label?: string | null
                                          properties?: {
                                            __typename: "ContentPropertyConnection"
                                            totalCount: number
                                            edges?: Array<{
                                              __typename: "ContentPropertyEdge"
                                              node?: {
                                                __typename: "ContentProperty"
                                                typeOfValue: Types.ContentPropertyValueType
                                                name: string
                                                value?: any | null
                                                displayName: string
                                              } | null
                                            } | null> | null
                                          } | null
                                        }
                                      | { __typename: "ST4TreeNode"; id: string }
                                      | null
                                  } | null> | null
                                  images: Array<{
                                    __typename: "NodeRef"
                                    id: string
                                    target?:
                                      | { __typename: "CommentNode"; id: string }
                                      | { __typename: "ExternalSite"; id: string }
                                      | { __typename: "ST4Class"; id: string }
                                      | {
                                          __typename: "ST4Node"
                                          aspectId: any
                                          id: string
                                          releaseState: {
                                            __typename: "ReleaseStateValue"
                                            displayName: string
                                            state: Types.ReleaseState
                                            isImplicitlyReleased: boolean
                                          }
                                          content?:
                                            | { __typename: "LexiconContent" }
                                            | { __typename: "LexiconEntryContent" }
                                            | {
                                                __typename: "MediaContent"
                                                media?:
                                                  | {
                                                      __typename: "AnimationMediaInfo"
                                                      id: string
                                                      resourceId?: string | null
                                                      thumbnail?: string | null
                                                      width?: number | null
                                                      height?: number | null
                                                      mediaType?: Types.MediaType | null
                                                    }
                                                  | {
                                                      __typename: "ImageMediaInfo"
                                                      id: string
                                                      resourceId?: string | null
                                                      thumbnail?: string | null
                                                      width?: number | null
                                                      height?: number | null
                                                      resolution?: number | null
                                                      colorDepth?: number | null
                                                      size?: number | null
                                                      mime?: string | null
                                                      extension?: string | null
                                                      allowCaching?: boolean | null
                                                      mediaType?: Types.MediaType | null
                                                      src?: string | null
                                                      language?: string | null
                                                    }
                                                  | {
                                                      __typename: "NoMediaInfo"
                                                      id: string
                                                      mediaType?: Types.MediaType | null
                                                    }
                                                  | {
                                                      __typename: "VectorGraphicsMediaInfo"
                                                      id: string
                                                      resourceId?: string | null
                                                      thumbnail?: string | null
                                                      width?: number | null
                                                      height?: number | null
                                                      allowCaching?: boolean | null
                                                      mediaType?: Types.MediaType | null
                                                      language?: string | null
                                                      labels: Array<{
                                                        __typename: "VectorGraphicsLabel"
                                                        id?: string | null
                                                        sequence?: number | null
                                                        autoValue?: string | null
                                                        text?: string | null
                                                        linkType?: Types.VectorGraphicsLabelLinkType | null
                                                        link?: string | null
                                                        ref?:
                                                          | {
                                                              __typename: "GenericRef"
                                                              id: string
                                                              owner?:
                                                                | { __typename: "CommentNode" }
                                                                | { __typename: "ExternalSite" }
                                                                | { __typename: "ST4Class" }
                                                                | { __typename: "ST4Node"; id: string }
                                                                | { __typename: "ST4TreeNode" }
                                                                | null
                                                              target?:
                                                                | { __typename: "CommentNode" }
                                                                | {
                                                                    __typename: "ExternalSite"
                                                                    id: string
                                                                    teaserData?: {
                                                                      __typename: "ExternalLinkTeaserData"
                                                                      title?: string | null
                                                                      summary?: string | null
                                                                      iconUrl?: string | null
                                                                      imageUrl?: string | null
                                                                      status?: Types.LinkTargetStatus | null
                                                                    } | null
                                                                  }
                                                                | { __typename: "ST4Class" }
                                                                | {
                                                                    __typename: "ST4Node"
                                                                    id: string
                                                                    aspectId: any
                                                                    label?: string | null
                                                                    properties?: {
                                                                      __typename: "ContentPropertyConnection"
                                                                      edges?: Array<{
                                                                        __typename: "ContentPropertyEdge"
                                                                        node?: {
                                                                          __typename: "ContentProperty"
                                                                          name: string
                                                                          value?: any | null
                                                                          displayName: string
                                                                        } | null
                                                                      } | null> | null
                                                                    } | null
                                                                  }
                                                                | { __typename: "ST4TreeNode" }
                                                                | null
                                                            }
                                                          | {
                                                              __typename: "NodeRef"
                                                              id: string
                                                              owner?:
                                                                | { __typename: "CommentNode" }
                                                                | { __typename: "ExternalSite" }
                                                                | { __typename: "ST4Class" }
                                                                | { __typename: "ST4Node"; id: string }
                                                                | { __typename: "ST4TreeNode" }
                                                                | null
                                                              target?:
                                                                | { __typename: "CommentNode" }
                                                                | {
                                                                    __typename: "ExternalSite"
                                                                    id: string
                                                                    teaserData?: {
                                                                      __typename: "ExternalLinkTeaserData"
                                                                      title?: string | null
                                                                      summary?: string | null
                                                                      iconUrl?: string | null
                                                                      imageUrl?: string | null
                                                                      status?: Types.LinkTargetStatus | null
                                                                    } | null
                                                                  }
                                                                | { __typename: "ST4Class" }
                                                                | {
                                                                    __typename: "ST4Node"
                                                                    id: string
                                                                    aspectId: any
                                                                    label?: string | null
                                                                    properties?: {
                                                                      __typename: "ContentPropertyConnection"
                                                                      edges?: Array<{
                                                                        __typename: "ContentPropertyEdge"
                                                                        node?: {
                                                                          __typename: "ContentProperty"
                                                                          name: string
                                                                          value?: any | null
                                                                          displayName: string
                                                                        } | null
                                                                      } | null> | null
                                                                    } | null
                                                                  }
                                                                | { __typename: "ST4TreeNode" }
                                                                | null
                                                            }
                                                          | null
                                                      }>
                                                    }
                                                  | {
                                                      __typename: "VideoMediaInfo"
                                                      id: string
                                                      resourceId?: string | null
                                                      thumbnail?: string | null
                                                      width?: number | null
                                                      height?: number | null
                                                      mediaType?: Types.MediaType | null
                                                    }
                                                  | null
                                              }
                                            | {
                                                __typename: "MediaGroupContent"
                                                children: Array<{
                                                  __typename: "NodeRef"
                                                  id: string
                                                  target?:
                                                    | { __typename: "CommentNode"; id: string }
                                                    | { __typename: "ExternalSite"; id: string }
                                                    | { __typename: "ST4Class"; id: string }
                                                    | {
                                                        __typename: "ST4Node"
                                                        id: string
                                                        aspectId: any
                                                        content?:
                                                          | { __typename: "LexiconContent" }
                                                          | { __typename: "LexiconEntryContent" }
                                                          | {
                                                              __typename: "MediaContent"
                                                              media?:
                                                                | {
                                                                    __typename: "AnimationMediaInfo"
                                                                    id: string
                                                                    resourceId?: string | null
                                                                    thumbnail?: string | null
                                                                    width?: number | null
                                                                    height?: number | null
                                                                    mediaType?: Types.MediaType | null
                                                                  }
                                                                | {
                                                                    __typename: "ImageMediaInfo"
                                                                    id: string
                                                                    resourceId?: string | null
                                                                    thumbnail?: string | null
                                                                    width?: number | null
                                                                    height?: number | null
                                                                    resolution?: number | null
                                                                    colorDepth?: number | null
                                                                    size?: number | null
                                                                    mime?: string | null
                                                                    extension?: string | null
                                                                    allowCaching?: boolean | null
                                                                    mediaType?: Types.MediaType | null
                                                                    src?: string | null
                                                                    language?: string | null
                                                                  }
                                                                | {
                                                                    __typename: "NoMediaInfo"
                                                                    id: string
                                                                    mediaType?: Types.MediaType | null
                                                                  }
                                                                | {
                                                                    __typename: "VectorGraphicsMediaInfo"
                                                                    id: string
                                                                    resourceId?: string | null
                                                                    thumbnail?: string | null
                                                                    width?: number | null
                                                                    height?: number | null
                                                                    allowCaching?: boolean | null
                                                                    mediaType?: Types.MediaType | null
                                                                    language?: string | null
                                                                    labels: Array<{
                                                                      __typename: "VectorGraphicsLabel"
                                                                      id?: string | null
                                                                      sequence?: number | null
                                                                      autoValue?: string | null
                                                                      text?: string | null
                                                                      linkType?: Types.VectorGraphicsLabelLinkType | null
                                                                      link?: string | null
                                                                      ref?:
                                                                        | {
                                                                            __typename: "GenericRef"
                                                                            id: string
                                                                            owner?:
                                                                              | { __typename: "CommentNode" }
                                                                              | { __typename: "ExternalSite" }
                                                                              | { __typename: "ST4Class" }
                                                                              | { __typename: "ST4Node"; id: string }
                                                                              | { __typename: "ST4TreeNode" }
                                                                              | null
                                                                            target?:
                                                                              | { __typename: "CommentNode" }
                                                                              | {
                                                                                  __typename: "ExternalSite"
                                                                                  id: string
                                                                                  teaserData?: {
                                                                                    __typename: "ExternalLinkTeaserData"
                                                                                    title?: string | null
                                                                                    summary?: string | null
                                                                                    iconUrl?: string | null
                                                                                    imageUrl?: string | null
                                                                                    status?: Types.LinkTargetStatus | null
                                                                                  } | null
                                                                                }
                                                                              | { __typename: "ST4Class" }
                                                                              | {
                                                                                  __typename: "ST4Node"
                                                                                  id: string
                                                                                  aspectId: any
                                                                                  label?: string | null
                                                                                  properties?: {
                                                                                    __typename: "ContentPropertyConnection"
                                                                                    edges?: Array<{
                                                                                      __typename: "ContentPropertyEdge"
                                                                                      node?: {
                                                                                        __typename: "ContentProperty"
                                                                                        name: string
                                                                                        value?: any | null
                                                                                        displayName: string
                                                                                      } | null
                                                                                    } | null> | null
                                                                                  } | null
                                                                                }
                                                                              | { __typename: "ST4TreeNode" }
                                                                              | null
                                                                          }
                                                                        | {
                                                                            __typename: "NodeRef"
                                                                            id: string
                                                                            owner?:
                                                                              | { __typename: "CommentNode" }
                                                                              | { __typename: "ExternalSite" }
                                                                              | { __typename: "ST4Class" }
                                                                              | { __typename: "ST4Node"; id: string }
                                                                              | { __typename: "ST4TreeNode" }
                                                                              | null
                                                                            target?:
                                                                              | { __typename: "CommentNode" }
                                                                              | {
                                                                                  __typename: "ExternalSite"
                                                                                  id: string
                                                                                  teaserData?: {
                                                                                    __typename: "ExternalLinkTeaserData"
                                                                                    title?: string | null
                                                                                    summary?: string | null
                                                                                    iconUrl?: string | null
                                                                                    imageUrl?: string | null
                                                                                    status?: Types.LinkTargetStatus | null
                                                                                  } | null
                                                                                }
                                                                              | { __typename: "ST4Class" }
                                                                              | {
                                                                                  __typename: "ST4Node"
                                                                                  id: string
                                                                                  aspectId: any
                                                                                  label?: string | null
                                                                                  properties?: {
                                                                                    __typename: "ContentPropertyConnection"
                                                                                    edges?: Array<{
                                                                                      __typename: "ContentPropertyEdge"
                                                                                      node?: {
                                                                                        __typename: "ContentProperty"
                                                                                        name: string
                                                                                        value?: any | null
                                                                                        displayName: string
                                                                                      } | null
                                                                                    } | null> | null
                                                                                  } | null
                                                                                }
                                                                              | { __typename: "ST4TreeNode" }
                                                                              | null
                                                                          }
                                                                        | null
                                                                    }>
                                                                  }
                                                                | {
                                                                    __typename: "VideoMediaInfo"
                                                                    id: string
                                                                    resourceId?: string | null
                                                                    thumbnail?: string | null
                                                                    width?: number | null
                                                                    height?: number | null
                                                                    mediaType?: Types.MediaType | null
                                                                  }
                                                                | null
                                                            }
                                                          | {
                                                              __typename: "MediaGroupContent"
                                                              media?:
                                                                | {
                                                                    __typename: "AnimationMediaInfo"
                                                                    id: string
                                                                    resourceId?: string | null
                                                                    thumbnail?: string | null
                                                                    width?: number | null
                                                                    height?: number | null
                                                                    mediaType?: Types.MediaType | null
                                                                  }
                                                                | {
                                                                    __typename: "ImageMediaInfo"
                                                                    id: string
                                                                    resourceId?: string | null
                                                                    thumbnail?: string | null
                                                                    width?: number | null
                                                                    height?: number | null
                                                                    resolution?: number | null
                                                                    colorDepth?: number | null
                                                                    size?: number | null
                                                                    mime?: string | null
                                                                    extension?: string | null
                                                                    allowCaching?: boolean | null
                                                                    mediaType?: Types.MediaType | null
                                                                    src?: string | null
                                                                    language?: string | null
                                                                  }
                                                                | {
                                                                    __typename: "NoMediaInfo"
                                                                    id: string
                                                                    mediaType?: Types.MediaType | null
                                                                  }
                                                                | {
                                                                    __typename: "VectorGraphicsMediaInfo"
                                                                    id: string
                                                                    resourceId?: string | null
                                                                    thumbnail?: string | null
                                                                    width?: number | null
                                                                    height?: number | null
                                                                    allowCaching?: boolean | null
                                                                    mediaType?: Types.MediaType | null
                                                                    language?: string | null
                                                                    labels: Array<{
                                                                      __typename: "VectorGraphicsLabel"
                                                                      id?: string | null
                                                                      sequence?: number | null
                                                                      autoValue?: string | null
                                                                      text?: string | null
                                                                      linkType?: Types.VectorGraphicsLabelLinkType | null
                                                                      link?: string | null
                                                                      ref?:
                                                                        | {
                                                                            __typename: "GenericRef"
                                                                            id: string
                                                                            owner?:
                                                                              | { __typename: "CommentNode" }
                                                                              | { __typename: "ExternalSite" }
                                                                              | { __typename: "ST4Class" }
                                                                              | { __typename: "ST4Node"; id: string }
                                                                              | { __typename: "ST4TreeNode" }
                                                                              | null
                                                                            target?:
                                                                              | { __typename: "CommentNode" }
                                                                              | {
                                                                                  __typename: "ExternalSite"
                                                                                  id: string
                                                                                  teaserData?: {
                                                                                    __typename: "ExternalLinkTeaserData"
                                                                                    title?: string | null
                                                                                    summary?: string | null
                                                                                    iconUrl?: string | null
                                                                                    imageUrl?: string | null
                                                                                    status?: Types.LinkTargetStatus | null
                                                                                  } | null
                                                                                }
                                                                              | { __typename: "ST4Class" }
                                                                              | {
                                                                                  __typename: "ST4Node"
                                                                                  id: string
                                                                                  aspectId: any
                                                                                  label?: string | null
                                                                                  properties?: {
                                                                                    __typename: "ContentPropertyConnection"
                                                                                    edges?: Array<{
                                                                                      __typename: "ContentPropertyEdge"
                                                                                      node?: {
                                                                                        __typename: "ContentProperty"
                                                                                        name: string
                                                                                        value?: any | null
                                                                                        displayName: string
                                                                                      } | null
                                                                                    } | null> | null
                                                                                  } | null
                                                                                }
                                                                              | { __typename: "ST4TreeNode" }
                                                                              | null
                                                                          }
                                                                        | {
                                                                            __typename: "NodeRef"
                                                                            id: string
                                                                            owner?:
                                                                              | { __typename: "CommentNode" }
                                                                              | { __typename: "ExternalSite" }
                                                                              | { __typename: "ST4Class" }
                                                                              | { __typename: "ST4Node"; id: string }
                                                                              | { __typename: "ST4TreeNode" }
                                                                              | null
                                                                            target?:
                                                                              | { __typename: "CommentNode" }
                                                                              | {
                                                                                  __typename: "ExternalSite"
                                                                                  id: string
                                                                                  teaserData?: {
                                                                                    __typename: "ExternalLinkTeaserData"
                                                                                    title?: string | null
                                                                                    summary?: string | null
                                                                                    iconUrl?: string | null
                                                                                    imageUrl?: string | null
                                                                                    status?: Types.LinkTargetStatus | null
                                                                                  } | null
                                                                                }
                                                                              | { __typename: "ST4Class" }
                                                                              | {
                                                                                  __typename: "ST4Node"
                                                                                  id: string
                                                                                  aspectId: any
                                                                                  label?: string | null
                                                                                  properties?: {
                                                                                    __typename: "ContentPropertyConnection"
                                                                                    edges?: Array<{
                                                                                      __typename: "ContentPropertyEdge"
                                                                                      node?: {
                                                                                        __typename: "ContentProperty"
                                                                                        name: string
                                                                                        value?: any | null
                                                                                        displayName: string
                                                                                      } | null
                                                                                    } | null> | null
                                                                                  } | null
                                                                                }
                                                                              | { __typename: "ST4TreeNode" }
                                                                              | null
                                                                          }
                                                                        | null
                                                                    }>
                                                                  }
                                                                | {
                                                                    __typename: "VideoMediaInfo"
                                                                    id: string
                                                                    resourceId?: string | null
                                                                    thumbnail?: string | null
                                                                    width?: number | null
                                                                    height?: number | null
                                                                    mediaType?: Types.MediaType | null
                                                                  }
                                                                | null
                                                            }
                                                          | { __typename: "ProjectContent" }
                                                          | { __typename: "TermContent" }
                                                          | { __typename: "TextContent" }
                                                          | { __typename: "TextGroupContent" }
                                                          | { __typename: "VariableTableContent" }
                                                          | null
                                                      }
                                                    | { __typename: "ST4TreeNode"; id: string }
                                                    | null
                                                }>
                                                media?:
                                                  | {
                                                      __typename: "AnimationMediaInfo"
                                                      id: string
                                                      resourceId?: string | null
                                                      thumbnail?: string | null
                                                      width?: number | null
                                                      height?: number | null
                                                      mediaType?: Types.MediaType | null
                                                    }
                                                  | {
                                                      __typename: "ImageMediaInfo"
                                                      id: string
                                                      resourceId?: string | null
                                                      thumbnail?: string | null
                                                      width?: number | null
                                                      height?: number | null
                                                      resolution?: number | null
                                                      colorDepth?: number | null
                                                      size?: number | null
                                                      mime?: string | null
                                                      extension?: string | null
                                                      allowCaching?: boolean | null
                                                      mediaType?: Types.MediaType | null
                                                      src?: string | null
                                                      language?: string | null
                                                    }
                                                  | {
                                                      __typename: "NoMediaInfo"
                                                      id: string
                                                      mediaType?: Types.MediaType | null
                                                    }
                                                  | {
                                                      __typename: "VectorGraphicsMediaInfo"
                                                      id: string
                                                      resourceId?: string | null
                                                      thumbnail?: string | null
                                                      width?: number | null
                                                      height?: number | null
                                                      allowCaching?: boolean | null
                                                      mediaType?: Types.MediaType | null
                                                      language?: string | null
                                                      labels: Array<{
                                                        __typename: "VectorGraphicsLabel"
                                                        id?: string | null
                                                        sequence?: number | null
                                                        autoValue?: string | null
                                                        text?: string | null
                                                        linkType?: Types.VectorGraphicsLabelLinkType | null
                                                        link?: string | null
                                                        ref?:
                                                          | {
                                                              __typename: "GenericRef"
                                                              id: string
                                                              owner?:
                                                                | { __typename: "CommentNode" }
                                                                | { __typename: "ExternalSite" }
                                                                | { __typename: "ST4Class" }
                                                                | { __typename: "ST4Node"; id: string }
                                                                | { __typename: "ST4TreeNode" }
                                                                | null
                                                              target?:
                                                                | { __typename: "CommentNode" }
                                                                | {
                                                                    __typename: "ExternalSite"
                                                                    id: string
                                                                    teaserData?: {
                                                                      __typename: "ExternalLinkTeaserData"
                                                                      title?: string | null
                                                                      summary?: string | null
                                                                      iconUrl?: string | null
                                                                      imageUrl?: string | null
                                                                      status?: Types.LinkTargetStatus | null
                                                                    } | null
                                                                  }
                                                                | { __typename: "ST4Class" }
                                                                | {
                                                                    __typename: "ST4Node"
                                                                    id: string
                                                                    aspectId: any
                                                                    label?: string | null
                                                                    properties?: {
                                                                      __typename: "ContentPropertyConnection"
                                                                      edges?: Array<{
                                                                        __typename: "ContentPropertyEdge"
                                                                        node?: {
                                                                          __typename: "ContentProperty"
                                                                          name: string
                                                                          value?: any | null
                                                                          displayName: string
                                                                        } | null
                                                                      } | null> | null
                                                                    } | null
                                                                  }
                                                                | { __typename: "ST4TreeNode" }
                                                                | null
                                                            }
                                                          | {
                                                              __typename: "NodeRef"
                                                              id: string
                                                              owner?:
                                                                | { __typename: "CommentNode" }
                                                                | { __typename: "ExternalSite" }
                                                                | { __typename: "ST4Class" }
                                                                | { __typename: "ST4Node"; id: string }
                                                                | { __typename: "ST4TreeNode" }
                                                                | null
                                                              target?:
                                                                | { __typename: "CommentNode" }
                                                                | {
                                                                    __typename: "ExternalSite"
                                                                    id: string
                                                                    teaserData?: {
                                                                      __typename: "ExternalLinkTeaserData"
                                                                      title?: string | null
                                                                      summary?: string | null
                                                                      iconUrl?: string | null
                                                                      imageUrl?: string | null
                                                                      status?: Types.LinkTargetStatus | null
                                                                    } | null
                                                                  }
                                                                | { __typename: "ST4Class" }
                                                                | {
                                                                    __typename: "ST4Node"
                                                                    id: string
                                                                    aspectId: any
                                                                    label?: string | null
                                                                    properties?: {
                                                                      __typename: "ContentPropertyConnection"
                                                                      edges?: Array<{
                                                                        __typename: "ContentPropertyEdge"
                                                                        node?: {
                                                                          __typename: "ContentProperty"
                                                                          name: string
                                                                          value?: any | null
                                                                          displayName: string
                                                                        } | null
                                                                      } | null> | null
                                                                    } | null
                                                                  }
                                                                | { __typename: "ST4TreeNode" }
                                                                | null
                                                            }
                                                          | null
                                                      }>
                                                    }
                                                  | {
                                                      __typename: "VideoMediaInfo"
                                                      id: string
                                                      resourceId?: string | null
                                                      thumbnail?: string | null
                                                      width?: number | null
                                                      height?: number | null
                                                      mediaType?: Types.MediaType | null
                                                    }
                                                  | null
                                              }
                                            | { __typename: "ProjectContent" }
                                            | { __typename: "TermContent" }
                                            | { __typename: "TextContent" }
                                            | { __typename: "TextGroupContent" }
                                            | { __typename: "VariableTableContent" }
                                            | null
                                        }
                                      | { __typename: "ST4TreeNode"; id: string }
                                      | null
                                  }>
                                  variableTables?: Array<{
                                    __typename: "VariableTableReference"
                                    ref?: string | null
                                    node?:
                                      | { __typename: "CommentNode" }
                                      | { __typename: "ExternalSite" }
                                      | { __typename: "ST4Class" }
                                      | {
                                          __typename: "ST4Node"
                                          id: string
                                          label?: string | null
                                          aspectId: any
                                          content?:
                                            | { __typename: "LexiconContent" }
                                            | { __typename: "LexiconEntryContent" }
                                            | { __typename: "MediaContent" }
                                            | { __typename: "MediaGroupContent" }
                                            | { __typename: "ProjectContent" }
                                            | { __typename: "TermContent" }
                                            | { __typename: "TextContent" }
                                            | { __typename: "TextGroupContent" }
                                            | {
                                                __typename: "VariableTableContent"
                                                xml?: string | null
                                                valueSets: Array<{
                                                  __typename: "ValueSet"
                                                  name: string
                                                  valueSetId: string
                                                }>
                                                variables: Array<{
                                                  __typename: "Variable"
                                                  name: string
                                                  variableId: string
                                                  values: Array<string>
                                                }>
                                              }
                                            | null
                                        }
                                      | { __typename: "ST4TreeNode" }
                                      | null
                                  } | null> | null
                                  linkedDataNodes: Array<{
                                    __typename: "NodeRef"
                                    id: string
                                    target?:
                                      | { __typename: "CommentNode"; id: string }
                                      | { __typename: "ExternalSite"; id: string }
                                      | { __typename: "ST4Class"; id: string }
                                      | {
                                          __typename: "ST4Node"
                                          label?: string | null
                                          id: string
                                          metadata: Array<{
                                            __typename: "MetaDataEntry"
                                            label?: string | null
                                            systemName: string
                                            value?: string | null
                                            values: Array<string>
                                            users: Array<string>
                                          }>
                                          linkedOntologyNodes: Array<
                                            | {
                                                __typename: "GenericRef"
                                                target?:
                                                  | { __typename: "CommentNode" }
                                                  | { __typename: "ExternalSite" }
                                                  | { __typename: "ST4Class" }
                                                  | { __typename: "ST4Node"; id: string; label?: string | null }
                                                  | { __typename: "ST4TreeNode" }
                                                  | null
                                              }
                                            | {
                                                __typename: "NodeRef"
                                                linkClassName: string
                                                class?: { __typename: "ST4Class"; label?: string | null } | null
                                                target?:
                                                  | { __typename: "CommentNode" }
                                                  | { __typename: "ExternalSite" }
                                                  | { __typename: "ST4Class" }
                                                  | { __typename: "ST4Node"; id: string; label?: string | null }
                                                  | { __typename: "ST4TreeNode" }
                                                  | null
                                              }
                                          >
                                        }
                                      | { __typename: "ST4TreeNode"; id: string }
                                      | null
                                  }>
                                  lexiconEntries?: Array<{
                                    __typename: "NodeRef"
                                    id: string
                                    target?:
                                      | { __typename: "CommentNode"; id: string }
                                      | { __typename: "ExternalSite"; id: string }
                                      | { __typename: "ST4Class"; id: string }
                                      | {
                                          __typename: "ST4Node"
                                          aspectId: any
                                          label?: string | null
                                          id: string
                                          content?:
                                            | { __typename: "LexiconContent" }
                                            | {
                                                __typename: "LexiconEntryContent"
                                                descriptionXast?: string | null
                                                subject?: string | null
                                                sortKey?: string | null
                                                lexicon:
                                                  | { __typename: "CommentNode"; id: string }
                                                  | { __typename: "ExternalSite"; id: string }
                                                  | { __typename: "ST4Class"; id: string }
                                                  | { __typename: "ST4Node"; label?: string | null; id: string }
                                                  | { __typename: "ST4TreeNode"; id: string }
                                                terms: Array<
                                                  | { __typename: "CommentNode"; id: string }
                                                  | { __typename: "ExternalSite"; id: string }
                                                  | { __typename: "ST4Class"; id: string }
                                                  | {
                                                      __typename: "ST4Node"
                                                      id: string
                                                      label?: string | null
                                                      aspectId: any
                                                      content?:
                                                        | { __typename: "LexiconContent" }
                                                        | { __typename: "LexiconEntryContent" }
                                                        | { __typename: "MediaContent" }
                                                        | { __typename: "MediaGroupContent" }
                                                        | { __typename: "ProjectContent" }
                                                        | {
                                                            __typename: "TermContent"
                                                            gramaticalGender?: string | null
                                                            validity?: string | null
                                                            editLanguage?: string | null
                                                          }
                                                        | { __typename: "TextContent" }
                                                        | { __typename: "TextGroupContent" }
                                                        | { __typename: "VariableTableContent" }
                                                        | null
                                                    }
                                                  | { __typename: "ST4TreeNode"; id: string }
                                                >
                                                image?:
                                                  | { __typename: "CommentNode"; id: string }
                                                  | { __typename: "ExternalSite"; id: string }
                                                  | { __typename: "ST4Class"; id: string }
                                                  | {
                                                      __typename: "ST4Node"
                                                      aspectId: any
                                                      id: string
                                                      content?:
                                                        | { __typename: "LexiconContent" }
                                                        | { __typename: "LexiconEntryContent" }
                                                        | {
                                                            __typename: "MediaContent"
                                                            media?:
                                                              | {
                                                                  __typename: "AnimationMediaInfo"
                                                                  thumbnail?: string | null
                                                                  mediaType?: Types.MediaType | null
                                                                }
                                                              | {
                                                                  __typename: "ImageMediaInfo"
                                                                  src?: string | null
                                                                  thumbnail?: string | null
                                                                  mediaType?: Types.MediaType | null
                                                                }
                                                              | { __typename: "NoMediaInfo" }
                                                              | {
                                                                  __typename: "VectorGraphicsMediaInfo"
                                                                  svg?: string | null
                                                                  thumbnail?: string | null
                                                                  mediaType?: Types.MediaType | null
                                                                }
                                                              | {
                                                                  __typename: "VideoMediaInfo"
                                                                  thumbnail?: string | null
                                                                  mediaType?: Types.MediaType | null
                                                                }
                                                              | null
                                                          }
                                                        | { __typename: "MediaGroupContent" }
                                                        | { __typename: "ProjectContent" }
                                                        | { __typename: "TermContent" }
                                                        | { __typename: "TextContent" }
                                                        | { __typename: "TextGroupContent" }
                                                        | { __typename: "VariableTableContent" }
                                                        | null
                                                    }
                                                  | { __typename: "ST4TreeNode"; id: string }
                                                  | null
                                              }
                                            | { __typename: "MediaContent" }
                                            | { __typename: "MediaGroupContent" }
                                            | { __typename: "ProjectContent" }
                                            | { __typename: "TermContent" }
                                            | { __typename: "TextContent" }
                                            | { __typename: "TextGroupContent" }
                                            | { __typename: "VariableTableContent" }
                                            | null
                                        }
                                      | { __typename: "ST4TreeNode"; id: string }
                                      | null
                                  } | null> | null
                                  comments: Array<{
                                    __typename: "CommentNode"
                                    id: string
                                    comment:
                                      | {
                                          __typename: "BlockComment"
                                          location: number
                                          value?: string | null
                                          authorDisplayName?: string | null
                                          authorUserName?: string | null
                                          commentKey: string
                                          editDate?: any | null
                                          modificationVersion?: number | null
                                          replyToKey?: string | null
                                          sortOrder?: number | null
                                          commentType?: string | null
                                          state?: string | null
                                          color: string
                                          persistanceState?: Types.PersistanceState | null
                                          persistanceAction?: Types.PersistanceAction | null
                                          node: {
                                            __typename: "ST4Node"
                                            aspectId: any
                                            id: string
                                            content?:
                                              | { __typename: "LexiconContent" }
                                              | { __typename: "LexiconEntryContent" }
                                              | { __typename: "MediaContent" }
                                              | { __typename: "MediaGroupContent" }
                                              | { __typename: "ProjectContent" }
                                              | { __typename: "TermContent" }
                                              | { __typename: "TextContent"; id: string; historyNumber: number }
                                              | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                                              | { __typename: "VariableTableContent" }
                                              | null
                                          }
                                          actions: Array<{
                                            __typename: "Action"
                                            name?: string | null
                                            enabled?: boolean | null
                                          }>
                                        }
                                      | {
                                          __typename: "TextComment"
                                          endLocation: number
                                          startLocation?: number | null
                                          value?: string | null
                                          authorDisplayName?: string | null
                                          authorUserName?: string | null
                                          commentKey: string
                                          editDate?: any | null
                                          modificationVersion?: number | null
                                          replyToKey?: string | null
                                          sortOrder?: number | null
                                          commentType?: string | null
                                          state?: string | null
                                          color: string
                                          persistanceState?: Types.PersistanceState | null
                                          persistanceAction?: Types.PersistanceAction | null
                                          node: {
                                            __typename: "ST4Node"
                                            aspectId: any
                                            id: string
                                            content?:
                                              | { __typename: "LexiconContent" }
                                              | { __typename: "LexiconEntryContent" }
                                              | { __typename: "MediaContent" }
                                              | { __typename: "MediaGroupContent" }
                                              | { __typename: "ProjectContent" }
                                              | { __typename: "TermContent" }
                                              | { __typename: "TextContent"; id: string; historyNumber: number }
                                              | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                                              | { __typename: "VariableTableContent" }
                                              | null
                                          }
                                          actions: Array<{
                                            __typename: "Action"
                                            name?: string | null
                                            enabled?: boolean | null
                                          }>
                                        }
                                  }>
                                }
                              | {
                                  __typename: "TextGroupContent"
                                  currentNodeId?: any | null
                                  groupNodeId: any
                                  id: string
                                  xast?: string | null
                                  historyNumber: number
                                  children: Array<{
                                    __typename: "NodeRef"
                                    id: string
                                    target?:
                                      | { __typename: "CommentNode"; id: string }
                                      | { __typename: "ExternalSite"; id: string }
                                      | { __typename: "ST4Class"; id: string }
                                      | { __typename: "ST4Node"; label?: string | null; aspectId: any; id: string }
                                      | { __typename: "ST4TreeNode"; id: string }
                                      | null
                                  }>
                                  links?: Array<{
                                    __typename: "TextContentLink"
                                    ref?: string | null
                                    node?:
                                      | { __typename: "CommentNode"; id: string }
                                      | { __typename: "ExternalSite"; id: string }
                                      | { __typename: "ST4Class"; id: string }
                                      | {
                                          __typename: "ST4Node"
                                          id: string
                                          aspectId: any
                                          label?: string | null
                                          properties?: {
                                            __typename: "ContentPropertyConnection"
                                            totalCount: number
                                            edges?: Array<{
                                              __typename: "ContentPropertyEdge"
                                              node?: {
                                                __typename: "ContentProperty"
                                                typeOfValue: Types.ContentPropertyValueType
                                                name: string
                                                value?: any | null
                                                displayName: string
                                              } | null
                                            } | null> | null
                                          } | null
                                        }
                                      | { __typename: "ST4TreeNode"; id: string }
                                      | null
                                  } | null> | null
                                  images: Array<{
                                    __typename: "NodeRef"
                                    id: string
                                    target?:
                                      | { __typename: "CommentNode"; id: string }
                                      | { __typename: "ExternalSite"; id: string }
                                      | { __typename: "ST4Class"; id: string }
                                      | {
                                          __typename: "ST4Node"
                                          aspectId: any
                                          id: string
                                          releaseState: {
                                            __typename: "ReleaseStateValue"
                                            displayName: string
                                            state: Types.ReleaseState
                                            isImplicitlyReleased: boolean
                                          }
                                          content?:
                                            | { __typename: "LexiconContent" }
                                            | { __typename: "LexiconEntryContent" }
                                            | {
                                                __typename: "MediaContent"
                                                media?:
                                                  | {
                                                      __typename: "AnimationMediaInfo"
                                                      id: string
                                                      resourceId?: string | null
                                                      thumbnail?: string | null
                                                      width?: number | null
                                                      height?: number | null
                                                      mediaType?: Types.MediaType | null
                                                    }
                                                  | {
                                                      __typename: "ImageMediaInfo"
                                                      id: string
                                                      resourceId?: string | null
                                                      thumbnail?: string | null
                                                      width?: number | null
                                                      height?: number | null
                                                      resolution?: number | null
                                                      colorDepth?: number | null
                                                      size?: number | null
                                                      mime?: string | null
                                                      extension?: string | null
                                                      allowCaching?: boolean | null
                                                      mediaType?: Types.MediaType | null
                                                      src?: string | null
                                                      language?: string | null
                                                    }
                                                  | {
                                                      __typename: "NoMediaInfo"
                                                      id: string
                                                      mediaType?: Types.MediaType | null
                                                    }
                                                  | {
                                                      __typename: "VectorGraphicsMediaInfo"
                                                      id: string
                                                      resourceId?: string | null
                                                      thumbnail?: string | null
                                                      width?: number | null
                                                      height?: number | null
                                                      allowCaching?: boolean | null
                                                      mediaType?: Types.MediaType | null
                                                      language?: string | null
                                                      labels: Array<{
                                                        __typename: "VectorGraphicsLabel"
                                                        id?: string | null
                                                        sequence?: number | null
                                                        autoValue?: string | null
                                                        text?: string | null
                                                        linkType?: Types.VectorGraphicsLabelLinkType | null
                                                        link?: string | null
                                                        ref?:
                                                          | {
                                                              __typename: "GenericRef"
                                                              id: string
                                                              owner?:
                                                                | { __typename: "CommentNode" }
                                                                | { __typename: "ExternalSite" }
                                                                | { __typename: "ST4Class" }
                                                                | { __typename: "ST4Node"; id: string }
                                                                | { __typename: "ST4TreeNode" }
                                                                | null
                                                              target?:
                                                                | { __typename: "CommentNode" }
                                                                | {
                                                                    __typename: "ExternalSite"
                                                                    id: string
                                                                    teaserData?: {
                                                                      __typename: "ExternalLinkTeaserData"
                                                                      title?: string | null
                                                                      summary?: string | null
                                                                      iconUrl?: string | null
                                                                      imageUrl?: string | null
                                                                      status?: Types.LinkTargetStatus | null
                                                                    } | null
                                                                  }
                                                                | { __typename: "ST4Class" }
                                                                | {
                                                                    __typename: "ST4Node"
                                                                    id: string
                                                                    aspectId: any
                                                                    label?: string | null
                                                                    properties?: {
                                                                      __typename: "ContentPropertyConnection"
                                                                      edges?: Array<{
                                                                        __typename: "ContentPropertyEdge"
                                                                        node?: {
                                                                          __typename: "ContentProperty"
                                                                          name: string
                                                                          value?: any | null
                                                                          displayName: string
                                                                        } | null
                                                                      } | null> | null
                                                                    } | null
                                                                  }
                                                                | { __typename: "ST4TreeNode" }
                                                                | null
                                                            }
                                                          | {
                                                              __typename: "NodeRef"
                                                              id: string
                                                              owner?:
                                                                | { __typename: "CommentNode" }
                                                                | { __typename: "ExternalSite" }
                                                                | { __typename: "ST4Class" }
                                                                | { __typename: "ST4Node"; id: string }
                                                                | { __typename: "ST4TreeNode" }
                                                                | null
                                                              target?:
                                                                | { __typename: "CommentNode" }
                                                                | {
                                                                    __typename: "ExternalSite"
                                                                    id: string
                                                                    teaserData?: {
                                                                      __typename: "ExternalLinkTeaserData"
                                                                      title?: string | null
                                                                      summary?: string | null
                                                                      iconUrl?: string | null
                                                                      imageUrl?: string | null
                                                                      status?: Types.LinkTargetStatus | null
                                                                    } | null
                                                                  }
                                                                | { __typename: "ST4Class" }
                                                                | {
                                                                    __typename: "ST4Node"
                                                                    id: string
                                                                    aspectId: any
                                                                    label?: string | null
                                                                    properties?: {
                                                                      __typename: "ContentPropertyConnection"
                                                                      edges?: Array<{
                                                                        __typename: "ContentPropertyEdge"
                                                                        node?: {
                                                                          __typename: "ContentProperty"
                                                                          name: string
                                                                          value?: any | null
                                                                          displayName: string
                                                                        } | null
                                                                      } | null> | null
                                                                    } | null
                                                                  }
                                                                | { __typename: "ST4TreeNode" }
                                                                | null
                                                            }
                                                          | null
                                                      }>
                                                    }
                                                  | {
                                                      __typename: "VideoMediaInfo"
                                                      id: string
                                                      resourceId?: string | null
                                                      thumbnail?: string | null
                                                      width?: number | null
                                                      height?: number | null
                                                      mediaType?: Types.MediaType | null
                                                    }
                                                  | null
                                              }
                                            | {
                                                __typename: "MediaGroupContent"
                                                children: Array<{
                                                  __typename: "NodeRef"
                                                  id: string
                                                  target?:
                                                    | { __typename: "CommentNode"; id: string }
                                                    | { __typename: "ExternalSite"; id: string }
                                                    | { __typename: "ST4Class"; id: string }
                                                    | {
                                                        __typename: "ST4Node"
                                                        id: string
                                                        aspectId: any
                                                        content?:
                                                          | { __typename: "LexiconContent" }
                                                          | { __typename: "LexiconEntryContent" }
                                                          | {
                                                              __typename: "MediaContent"
                                                              media?:
                                                                | {
                                                                    __typename: "AnimationMediaInfo"
                                                                    id: string
                                                                    resourceId?: string | null
                                                                    thumbnail?: string | null
                                                                    width?: number | null
                                                                    height?: number | null
                                                                    mediaType?: Types.MediaType | null
                                                                  }
                                                                | {
                                                                    __typename: "ImageMediaInfo"
                                                                    id: string
                                                                    resourceId?: string | null
                                                                    thumbnail?: string | null
                                                                    width?: number | null
                                                                    height?: number | null
                                                                    resolution?: number | null
                                                                    colorDepth?: number | null
                                                                    size?: number | null
                                                                    mime?: string | null
                                                                    extension?: string | null
                                                                    allowCaching?: boolean | null
                                                                    mediaType?: Types.MediaType | null
                                                                    src?: string | null
                                                                    language?: string | null
                                                                  }
                                                                | {
                                                                    __typename: "NoMediaInfo"
                                                                    id: string
                                                                    mediaType?: Types.MediaType | null
                                                                  }
                                                                | {
                                                                    __typename: "VectorGraphicsMediaInfo"
                                                                    id: string
                                                                    resourceId?: string | null
                                                                    thumbnail?: string | null
                                                                    width?: number | null
                                                                    height?: number | null
                                                                    allowCaching?: boolean | null
                                                                    mediaType?: Types.MediaType | null
                                                                    language?: string | null
                                                                    labels: Array<{
                                                                      __typename: "VectorGraphicsLabel"
                                                                      id?: string | null
                                                                      sequence?: number | null
                                                                      autoValue?: string | null
                                                                      text?: string | null
                                                                      linkType?: Types.VectorGraphicsLabelLinkType | null
                                                                      link?: string | null
                                                                      ref?:
                                                                        | {
                                                                            __typename: "GenericRef"
                                                                            id: string
                                                                            owner?:
                                                                              | { __typename: "CommentNode" }
                                                                              | { __typename: "ExternalSite" }
                                                                              | { __typename: "ST4Class" }
                                                                              | { __typename: "ST4Node"; id: string }
                                                                              | { __typename: "ST4TreeNode" }
                                                                              | null
                                                                            target?:
                                                                              | { __typename: "CommentNode" }
                                                                              | {
                                                                                  __typename: "ExternalSite"
                                                                                  id: string
                                                                                  teaserData?: {
                                                                                    __typename: "ExternalLinkTeaserData"
                                                                                    title?: string | null
                                                                                    summary?: string | null
                                                                                    iconUrl?: string | null
                                                                                    imageUrl?: string | null
                                                                                    status?: Types.LinkTargetStatus | null
                                                                                  } | null
                                                                                }
                                                                              | { __typename: "ST4Class" }
                                                                              | {
                                                                                  __typename: "ST4Node"
                                                                                  id: string
                                                                                  aspectId: any
                                                                                  label?: string | null
                                                                                  properties?: {
                                                                                    __typename: "ContentPropertyConnection"
                                                                                    edges?: Array<{
                                                                                      __typename: "ContentPropertyEdge"
                                                                                      node?: {
                                                                                        __typename: "ContentProperty"
                                                                                        name: string
                                                                                        value?: any | null
                                                                                        displayName: string
                                                                                      } | null
                                                                                    } | null> | null
                                                                                  } | null
                                                                                }
                                                                              | { __typename: "ST4TreeNode" }
                                                                              | null
                                                                          }
                                                                        | {
                                                                            __typename: "NodeRef"
                                                                            id: string
                                                                            owner?:
                                                                              | { __typename: "CommentNode" }
                                                                              | { __typename: "ExternalSite" }
                                                                              | { __typename: "ST4Class" }
                                                                              | { __typename: "ST4Node"; id: string }
                                                                              | { __typename: "ST4TreeNode" }
                                                                              | null
                                                                            target?:
                                                                              | { __typename: "CommentNode" }
                                                                              | {
                                                                                  __typename: "ExternalSite"
                                                                                  id: string
                                                                                  teaserData?: {
                                                                                    __typename: "ExternalLinkTeaserData"
                                                                                    title?: string | null
                                                                                    summary?: string | null
                                                                                    iconUrl?: string | null
                                                                                    imageUrl?: string | null
                                                                                    status?: Types.LinkTargetStatus | null
                                                                                  } | null
                                                                                }
                                                                              | { __typename: "ST4Class" }
                                                                              | {
                                                                                  __typename: "ST4Node"
                                                                                  id: string
                                                                                  aspectId: any
                                                                                  label?: string | null
                                                                                  properties?: {
                                                                                    __typename: "ContentPropertyConnection"
                                                                                    edges?: Array<{
                                                                                      __typename: "ContentPropertyEdge"
                                                                                      node?: {
                                                                                        __typename: "ContentProperty"
                                                                                        name: string
                                                                                        value?: any | null
                                                                                        displayName: string
                                                                                      } | null
                                                                                    } | null> | null
                                                                                  } | null
                                                                                }
                                                                              | { __typename: "ST4TreeNode" }
                                                                              | null
                                                                          }
                                                                        | null
                                                                    }>
                                                                  }
                                                                | {
                                                                    __typename: "VideoMediaInfo"
                                                                    id: string
                                                                    resourceId?: string | null
                                                                    thumbnail?: string | null
                                                                    width?: number | null
                                                                    height?: number | null
                                                                    mediaType?: Types.MediaType | null
                                                                  }
                                                                | null
                                                            }
                                                          | {
                                                              __typename: "MediaGroupContent"
                                                              media?:
                                                                | {
                                                                    __typename: "AnimationMediaInfo"
                                                                    id: string
                                                                    resourceId?: string | null
                                                                    thumbnail?: string | null
                                                                    width?: number | null
                                                                    height?: number | null
                                                                    mediaType?: Types.MediaType | null
                                                                  }
                                                                | {
                                                                    __typename: "ImageMediaInfo"
                                                                    id: string
                                                                    resourceId?: string | null
                                                                    thumbnail?: string | null
                                                                    width?: number | null
                                                                    height?: number | null
                                                                    resolution?: number | null
                                                                    colorDepth?: number | null
                                                                    size?: number | null
                                                                    mime?: string | null
                                                                    extension?: string | null
                                                                    allowCaching?: boolean | null
                                                                    mediaType?: Types.MediaType | null
                                                                    src?: string | null
                                                                    language?: string | null
                                                                  }
                                                                | {
                                                                    __typename: "NoMediaInfo"
                                                                    id: string
                                                                    mediaType?: Types.MediaType | null
                                                                  }
                                                                | {
                                                                    __typename: "VectorGraphicsMediaInfo"
                                                                    id: string
                                                                    resourceId?: string | null
                                                                    thumbnail?: string | null
                                                                    width?: number | null
                                                                    height?: number | null
                                                                    allowCaching?: boolean | null
                                                                    mediaType?: Types.MediaType | null
                                                                    language?: string | null
                                                                    labels: Array<{
                                                                      __typename: "VectorGraphicsLabel"
                                                                      id?: string | null
                                                                      sequence?: number | null
                                                                      autoValue?: string | null
                                                                      text?: string | null
                                                                      linkType?: Types.VectorGraphicsLabelLinkType | null
                                                                      link?: string | null
                                                                      ref?:
                                                                        | {
                                                                            __typename: "GenericRef"
                                                                            id: string
                                                                            owner?:
                                                                              | { __typename: "CommentNode" }
                                                                              | { __typename: "ExternalSite" }
                                                                              | { __typename: "ST4Class" }
                                                                              | { __typename: "ST4Node"; id: string }
                                                                              | { __typename: "ST4TreeNode" }
                                                                              | null
                                                                            target?:
                                                                              | { __typename: "CommentNode" }
                                                                              | {
                                                                                  __typename: "ExternalSite"
                                                                                  id: string
                                                                                  teaserData?: {
                                                                                    __typename: "ExternalLinkTeaserData"
                                                                                    title?: string | null
                                                                                    summary?: string | null
                                                                                    iconUrl?: string | null
                                                                                    imageUrl?: string | null
                                                                                    status?: Types.LinkTargetStatus | null
                                                                                  } | null
                                                                                }
                                                                              | { __typename: "ST4Class" }
                                                                              | {
                                                                                  __typename: "ST4Node"
                                                                                  id: string
                                                                                  aspectId: any
                                                                                  label?: string | null
                                                                                  properties?: {
                                                                                    __typename: "ContentPropertyConnection"
                                                                                    edges?: Array<{
                                                                                      __typename: "ContentPropertyEdge"
                                                                                      node?: {
                                                                                        __typename: "ContentProperty"
                                                                                        name: string
                                                                                        value?: any | null
                                                                                        displayName: string
                                                                                      } | null
                                                                                    } | null> | null
                                                                                  } | null
                                                                                }
                                                                              | { __typename: "ST4TreeNode" }
                                                                              | null
                                                                          }
                                                                        | {
                                                                            __typename: "NodeRef"
                                                                            id: string
                                                                            owner?:
                                                                              | { __typename: "CommentNode" }
                                                                              | { __typename: "ExternalSite" }
                                                                              | { __typename: "ST4Class" }
                                                                              | { __typename: "ST4Node"; id: string }
                                                                              | { __typename: "ST4TreeNode" }
                                                                              | null
                                                                            target?:
                                                                              | { __typename: "CommentNode" }
                                                                              | {
                                                                                  __typename: "ExternalSite"
                                                                                  id: string
                                                                                  teaserData?: {
                                                                                    __typename: "ExternalLinkTeaserData"
                                                                                    title?: string | null
                                                                                    summary?: string | null
                                                                                    iconUrl?: string | null
                                                                                    imageUrl?: string | null
                                                                                    status?: Types.LinkTargetStatus | null
                                                                                  } | null
                                                                                }
                                                                              | { __typename: "ST4Class" }
                                                                              | {
                                                                                  __typename: "ST4Node"
                                                                                  id: string
                                                                                  aspectId: any
                                                                                  label?: string | null
                                                                                  properties?: {
                                                                                    __typename: "ContentPropertyConnection"
                                                                                    edges?: Array<{
                                                                                      __typename: "ContentPropertyEdge"
                                                                                      node?: {
                                                                                        __typename: "ContentProperty"
                                                                                        name: string
                                                                                        value?: any | null
                                                                                        displayName: string
                                                                                      } | null
                                                                                    } | null> | null
                                                                                  } | null
                                                                                }
                                                                              | { __typename: "ST4TreeNode" }
                                                                              | null
                                                                          }
                                                                        | null
                                                                    }>
                                                                  }
                                                                | {
                                                                    __typename: "VideoMediaInfo"
                                                                    id: string
                                                                    resourceId?: string | null
                                                                    thumbnail?: string | null
                                                                    width?: number | null
                                                                    height?: number | null
                                                                    mediaType?: Types.MediaType | null
                                                                  }
                                                                | null
                                                            }
                                                          | { __typename: "ProjectContent" }
                                                          | { __typename: "TermContent" }
                                                          | { __typename: "TextContent" }
                                                          | { __typename: "TextGroupContent" }
                                                          | { __typename: "VariableTableContent" }
                                                          | null
                                                      }
                                                    | { __typename: "ST4TreeNode"; id: string }
                                                    | null
                                                }>
                                                media?:
                                                  | {
                                                      __typename: "AnimationMediaInfo"
                                                      id: string
                                                      resourceId?: string | null
                                                      thumbnail?: string | null
                                                      width?: number | null
                                                      height?: number | null
                                                      mediaType?: Types.MediaType | null
                                                    }
                                                  | {
                                                      __typename: "ImageMediaInfo"
                                                      id: string
                                                      resourceId?: string | null
                                                      thumbnail?: string | null
                                                      width?: number | null
                                                      height?: number | null
                                                      resolution?: number | null
                                                      colorDepth?: number | null
                                                      size?: number | null
                                                      mime?: string | null
                                                      extension?: string | null
                                                      allowCaching?: boolean | null
                                                      mediaType?: Types.MediaType | null
                                                      src?: string | null
                                                      language?: string | null
                                                    }
                                                  | {
                                                      __typename: "NoMediaInfo"
                                                      id: string
                                                      mediaType?: Types.MediaType | null
                                                    }
                                                  | {
                                                      __typename: "VectorGraphicsMediaInfo"
                                                      id: string
                                                      resourceId?: string | null
                                                      thumbnail?: string | null
                                                      width?: number | null
                                                      height?: number | null
                                                      allowCaching?: boolean | null
                                                      mediaType?: Types.MediaType | null
                                                      language?: string | null
                                                      labels: Array<{
                                                        __typename: "VectorGraphicsLabel"
                                                        id?: string | null
                                                        sequence?: number | null
                                                        autoValue?: string | null
                                                        text?: string | null
                                                        linkType?: Types.VectorGraphicsLabelLinkType | null
                                                        link?: string | null
                                                        ref?:
                                                          | {
                                                              __typename: "GenericRef"
                                                              id: string
                                                              owner?:
                                                                | { __typename: "CommentNode" }
                                                                | { __typename: "ExternalSite" }
                                                                | { __typename: "ST4Class" }
                                                                | { __typename: "ST4Node"; id: string }
                                                                | { __typename: "ST4TreeNode" }
                                                                | null
                                                              target?:
                                                                | { __typename: "CommentNode" }
                                                                | {
                                                                    __typename: "ExternalSite"
                                                                    id: string
                                                                    teaserData?: {
                                                                      __typename: "ExternalLinkTeaserData"
                                                                      title?: string | null
                                                                      summary?: string | null
                                                                      iconUrl?: string | null
                                                                      imageUrl?: string | null
                                                                      status?: Types.LinkTargetStatus | null
                                                                    } | null
                                                                  }
                                                                | { __typename: "ST4Class" }
                                                                | {
                                                                    __typename: "ST4Node"
                                                                    id: string
                                                                    aspectId: any
                                                                    label?: string | null
                                                                    properties?: {
                                                                      __typename: "ContentPropertyConnection"
                                                                      edges?: Array<{
                                                                        __typename: "ContentPropertyEdge"
                                                                        node?: {
                                                                          __typename: "ContentProperty"
                                                                          name: string
                                                                          value?: any | null
                                                                          displayName: string
                                                                        } | null
                                                                      } | null> | null
                                                                    } | null
                                                                  }
                                                                | { __typename: "ST4TreeNode" }
                                                                | null
                                                            }
                                                          | {
                                                              __typename: "NodeRef"
                                                              id: string
                                                              owner?:
                                                                | { __typename: "CommentNode" }
                                                                | { __typename: "ExternalSite" }
                                                                | { __typename: "ST4Class" }
                                                                | { __typename: "ST4Node"; id: string }
                                                                | { __typename: "ST4TreeNode" }
                                                                | null
                                                              target?:
                                                                | { __typename: "CommentNode" }
                                                                | {
                                                                    __typename: "ExternalSite"
                                                                    id: string
                                                                    teaserData?: {
                                                                      __typename: "ExternalLinkTeaserData"
                                                                      title?: string | null
                                                                      summary?: string | null
                                                                      iconUrl?: string | null
                                                                      imageUrl?: string | null
                                                                      status?: Types.LinkTargetStatus | null
                                                                    } | null
                                                                  }
                                                                | { __typename: "ST4Class" }
                                                                | {
                                                                    __typename: "ST4Node"
                                                                    id: string
                                                                    aspectId: any
                                                                    label?: string | null
                                                                    properties?: {
                                                                      __typename: "ContentPropertyConnection"
                                                                      edges?: Array<{
                                                                        __typename: "ContentPropertyEdge"
                                                                        node?: {
                                                                          __typename: "ContentProperty"
                                                                          name: string
                                                                          value?: any | null
                                                                          displayName: string
                                                                        } | null
                                                                      } | null> | null
                                                                    } | null
                                                                  }
                                                                | { __typename: "ST4TreeNode" }
                                                                | null
                                                            }
                                                          | null
                                                      }>
                                                    }
                                                  | {
                                                      __typename: "VideoMediaInfo"
                                                      id: string
                                                      resourceId?: string | null
                                                      thumbnail?: string | null
                                                      width?: number | null
                                                      height?: number | null
                                                      mediaType?: Types.MediaType | null
                                                    }
                                                  | null
                                              }
                                            | { __typename: "ProjectContent" }
                                            | { __typename: "TermContent" }
                                            | { __typename: "TextContent" }
                                            | { __typename: "TextGroupContent" }
                                            | { __typename: "VariableTableContent" }
                                            | null
                                        }
                                      | { __typename: "ST4TreeNode"; id: string }
                                      | null
                                  }>
                                  variableTables?: Array<{
                                    __typename: "VariableTableReference"
                                    ref?: string | null
                                    node?:
                                      | { __typename: "CommentNode" }
                                      | { __typename: "ExternalSite" }
                                      | { __typename: "ST4Class" }
                                      | {
                                          __typename: "ST4Node"
                                          id: string
                                          label?: string | null
                                          aspectId: any
                                          content?:
                                            | { __typename: "LexiconContent" }
                                            | { __typename: "LexiconEntryContent" }
                                            | { __typename: "MediaContent" }
                                            | { __typename: "MediaGroupContent" }
                                            | { __typename: "ProjectContent" }
                                            | { __typename: "TermContent" }
                                            | { __typename: "TextContent" }
                                            | { __typename: "TextGroupContent" }
                                            | {
                                                __typename: "VariableTableContent"
                                                xml?: string | null
                                                valueSets: Array<{
                                                  __typename: "ValueSet"
                                                  name: string
                                                  valueSetId: string
                                                }>
                                                variables: Array<{
                                                  __typename: "Variable"
                                                  name: string
                                                  variableId: string
                                                  values: Array<string>
                                                }>
                                              }
                                            | null
                                        }
                                      | { __typename: "ST4TreeNode" }
                                      | null
                                  } | null> | null
                                  linkedDataNodes: Array<{
                                    __typename: "NodeRef"
                                    id: string
                                    target?:
                                      | { __typename: "CommentNode"; id: string }
                                      | { __typename: "ExternalSite"; id: string }
                                      | { __typename: "ST4Class"; id: string }
                                      | {
                                          __typename: "ST4Node"
                                          label?: string | null
                                          id: string
                                          metadata: Array<{
                                            __typename: "MetaDataEntry"
                                            label?: string | null
                                            systemName: string
                                            value?: string | null
                                            values: Array<string>
                                            users: Array<string>
                                          }>
                                          linkedOntologyNodes: Array<
                                            | {
                                                __typename: "GenericRef"
                                                target?:
                                                  | { __typename: "CommentNode" }
                                                  | { __typename: "ExternalSite" }
                                                  | { __typename: "ST4Class" }
                                                  | { __typename: "ST4Node"; id: string; label?: string | null }
                                                  | { __typename: "ST4TreeNode" }
                                                  | null
                                              }
                                            | {
                                                __typename: "NodeRef"
                                                linkClassName: string
                                                class?: { __typename: "ST4Class"; label?: string | null } | null
                                                target?:
                                                  | { __typename: "CommentNode" }
                                                  | { __typename: "ExternalSite" }
                                                  | { __typename: "ST4Class" }
                                                  | { __typename: "ST4Node"; id: string; label?: string | null }
                                                  | { __typename: "ST4TreeNode" }
                                                  | null
                                              }
                                          >
                                        }
                                      | { __typename: "ST4TreeNode"; id: string }
                                      | null
                                  }>
                                  lexiconEntries?: Array<{
                                    __typename: "NodeRef"
                                    id: string
                                    target?:
                                      | { __typename: "CommentNode"; id: string }
                                      | { __typename: "ExternalSite"; id: string }
                                      | { __typename: "ST4Class"; id: string }
                                      | {
                                          __typename: "ST4Node"
                                          aspectId: any
                                          label?: string | null
                                          id: string
                                          content?:
                                            | { __typename: "LexiconContent" }
                                            | {
                                                __typename: "LexiconEntryContent"
                                                descriptionXast?: string | null
                                                subject?: string | null
                                                sortKey?: string | null
                                                lexicon:
                                                  | { __typename: "CommentNode"; id: string }
                                                  | { __typename: "ExternalSite"; id: string }
                                                  | { __typename: "ST4Class"; id: string }
                                                  | { __typename: "ST4Node"; label?: string | null; id: string }
                                                  | { __typename: "ST4TreeNode"; id: string }
                                                terms: Array<
                                                  | { __typename: "CommentNode"; id: string }
                                                  | { __typename: "ExternalSite"; id: string }
                                                  | { __typename: "ST4Class"; id: string }
                                                  | {
                                                      __typename: "ST4Node"
                                                      id: string
                                                      label?: string | null
                                                      aspectId: any
                                                      content?:
                                                        | { __typename: "LexiconContent" }
                                                        | { __typename: "LexiconEntryContent" }
                                                        | { __typename: "MediaContent" }
                                                        | { __typename: "MediaGroupContent" }
                                                        | { __typename: "ProjectContent" }
                                                        | {
                                                            __typename: "TermContent"
                                                            gramaticalGender?: string | null
                                                            validity?: string | null
                                                            editLanguage?: string | null
                                                          }
                                                        | { __typename: "TextContent" }
                                                        | { __typename: "TextGroupContent" }
                                                        | { __typename: "VariableTableContent" }
                                                        | null
                                                    }
                                                  | { __typename: "ST4TreeNode"; id: string }
                                                >
                                                image?:
                                                  | { __typename: "CommentNode"; id: string }
                                                  | { __typename: "ExternalSite"; id: string }
                                                  | { __typename: "ST4Class"; id: string }
                                                  | {
                                                      __typename: "ST4Node"
                                                      aspectId: any
                                                      id: string
                                                      content?:
                                                        | { __typename: "LexiconContent" }
                                                        | { __typename: "LexiconEntryContent" }
                                                        | {
                                                            __typename: "MediaContent"
                                                            media?:
                                                              | {
                                                                  __typename: "AnimationMediaInfo"
                                                                  thumbnail?: string | null
                                                                  mediaType?: Types.MediaType | null
                                                                }
                                                              | {
                                                                  __typename: "ImageMediaInfo"
                                                                  src?: string | null
                                                                  thumbnail?: string | null
                                                                  mediaType?: Types.MediaType | null
                                                                }
                                                              | { __typename: "NoMediaInfo" }
                                                              | {
                                                                  __typename: "VectorGraphicsMediaInfo"
                                                                  svg?: string | null
                                                                  thumbnail?: string | null
                                                                  mediaType?: Types.MediaType | null
                                                                }
                                                              | {
                                                                  __typename: "VideoMediaInfo"
                                                                  thumbnail?: string | null
                                                                  mediaType?: Types.MediaType | null
                                                                }
                                                              | null
                                                          }
                                                        | { __typename: "MediaGroupContent" }
                                                        | { __typename: "ProjectContent" }
                                                        | { __typename: "TermContent" }
                                                        | { __typename: "TextContent" }
                                                        | { __typename: "TextGroupContent" }
                                                        | { __typename: "VariableTableContent" }
                                                        | null
                                                    }
                                                  | { __typename: "ST4TreeNode"; id: string }
                                                  | null
                                              }
                                            | { __typename: "MediaContent" }
                                            | { __typename: "MediaGroupContent" }
                                            | { __typename: "ProjectContent" }
                                            | { __typename: "TermContent" }
                                            | { __typename: "TextContent" }
                                            | { __typename: "TextGroupContent" }
                                            | { __typename: "VariableTableContent" }
                                            | null
                                        }
                                      | { __typename: "ST4TreeNode"; id: string }
                                      | null
                                  } | null> | null
                                  comments: Array<{
                                    __typename: "CommentNode"
                                    id: string
                                    comment:
                                      | {
                                          __typename: "BlockComment"
                                          location: number
                                          value?: string | null
                                          authorDisplayName?: string | null
                                          authorUserName?: string | null
                                          commentKey: string
                                          editDate?: any | null
                                          modificationVersion?: number | null
                                          replyToKey?: string | null
                                          sortOrder?: number | null
                                          commentType?: string | null
                                          state?: string | null
                                          color: string
                                          persistanceState?: Types.PersistanceState | null
                                          persistanceAction?: Types.PersistanceAction | null
                                          node: {
                                            __typename: "ST4Node"
                                            aspectId: any
                                            id: string
                                            content?:
                                              | { __typename: "LexiconContent" }
                                              | { __typename: "LexiconEntryContent" }
                                              | { __typename: "MediaContent" }
                                              | { __typename: "MediaGroupContent" }
                                              | { __typename: "ProjectContent" }
                                              | { __typename: "TermContent" }
                                              | { __typename: "TextContent"; id: string; historyNumber: number }
                                              | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                                              | { __typename: "VariableTableContent" }
                                              | null
                                          }
                                          actions: Array<{
                                            __typename: "Action"
                                            name?: string | null
                                            enabled?: boolean | null
                                          }>
                                        }
                                      | {
                                          __typename: "TextComment"
                                          endLocation: number
                                          startLocation?: number | null
                                          value?: string | null
                                          authorDisplayName?: string | null
                                          authorUserName?: string | null
                                          commentKey: string
                                          editDate?: any | null
                                          modificationVersion?: number | null
                                          replyToKey?: string | null
                                          sortOrder?: number | null
                                          commentType?: string | null
                                          state?: string | null
                                          color: string
                                          persistanceState?: Types.PersistanceState | null
                                          persistanceAction?: Types.PersistanceAction | null
                                          node: {
                                            __typename: "ST4Node"
                                            aspectId: any
                                            id: string
                                            content?:
                                              | { __typename: "LexiconContent" }
                                              | { __typename: "LexiconEntryContent" }
                                              | { __typename: "MediaContent" }
                                              | { __typename: "MediaGroupContent" }
                                              | { __typename: "ProjectContent" }
                                              | { __typename: "TermContent" }
                                              | { __typename: "TextContent"; id: string; historyNumber: number }
                                              | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                                              | { __typename: "VariableTableContent" }
                                              | null
                                          }
                                          actions: Array<{
                                            __typename: "Action"
                                            name?: string | null
                                            enabled?: boolean | null
                                          }>
                                        }
                                  }>
                                }
                              | { __typename: "VariableTableContent" }
                              | null
                          }
                        }>
                        children: Array<{
                          __typename: "NodeRef"
                          id: string
                          target?:
                            | { __typename: "CommentNode"; id: string }
                            | { __typename: "ExternalSite"; id: string }
                            | { __typename: "ST4Class"; id: string }
                            | { __typename: "ST4Node"; label?: string | null; aspectId: any; id: string }
                            | { __typename: "ST4TreeNode"; id: string }
                            | null
                        }>
                        links?: Array<{
                          __typename: "TextContentLink"
                          ref?: string | null
                          node?:
                            | { __typename: "CommentNode"; id: string }
                            | { __typename: "ExternalSite"; id: string }
                            | { __typename: "ST4Class"; id: string }
                            | {
                                __typename: "ST4Node"
                                id: string
                                aspectId: any
                                label?: string | null
                                properties?: {
                                  __typename: "ContentPropertyConnection"
                                  totalCount: number
                                  edges?: Array<{
                                    __typename: "ContentPropertyEdge"
                                    node?: {
                                      __typename: "ContentProperty"
                                      typeOfValue: Types.ContentPropertyValueType
                                      name: string
                                      value?: any | null
                                      displayName: string
                                    } | null
                                  } | null> | null
                                } | null
                              }
                            | { __typename: "ST4TreeNode"; id: string }
                            | null
                        } | null> | null
                        images: Array<{
                          __typename: "NodeRef"
                          id: string
                          target?:
                            | { __typename: "CommentNode"; id: string }
                            | { __typename: "ExternalSite"; id: string }
                            | { __typename: "ST4Class"; id: string }
                            | {
                                __typename: "ST4Node"
                                aspectId: any
                                id: string
                                releaseState: {
                                  __typename: "ReleaseStateValue"
                                  displayName: string
                                  state: Types.ReleaseState
                                  isImplicitlyReleased: boolean
                                }
                                content?:
                                  | { __typename: "LexiconContent" }
                                  | { __typename: "LexiconEntryContent" }
                                  | {
                                      __typename: "MediaContent"
                                      media?:
                                        | {
                                            __typename: "AnimationMediaInfo"
                                            id: string
                                            resourceId?: string | null
                                            thumbnail?: string | null
                                            width?: number | null
                                            height?: number | null
                                            mediaType?: Types.MediaType | null
                                          }
                                        | {
                                            __typename: "ImageMediaInfo"
                                            id: string
                                            resourceId?: string | null
                                            thumbnail?: string | null
                                            width?: number | null
                                            height?: number | null
                                            resolution?: number | null
                                            colorDepth?: number | null
                                            size?: number | null
                                            mime?: string | null
                                            extension?: string | null
                                            allowCaching?: boolean | null
                                            mediaType?: Types.MediaType | null
                                            src?: string | null
                                            language?: string | null
                                          }
                                        | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                                        | {
                                            __typename: "VectorGraphicsMediaInfo"
                                            id: string
                                            resourceId?: string | null
                                            thumbnail?: string | null
                                            width?: number | null
                                            height?: number | null
                                            allowCaching?: boolean | null
                                            mediaType?: Types.MediaType | null
                                            language?: string | null
                                            labels: Array<{
                                              __typename: "VectorGraphicsLabel"
                                              id?: string | null
                                              sequence?: number | null
                                              autoValue?: string | null
                                              text?: string | null
                                              linkType?: Types.VectorGraphicsLabelLinkType | null
                                              link?: string | null
                                              ref?:
                                                | {
                                                    __typename: "GenericRef"
                                                    id: string
                                                    owner?:
                                                      | { __typename: "CommentNode" }
                                                      | { __typename: "ExternalSite" }
                                                      | { __typename: "ST4Class" }
                                                      | { __typename: "ST4Node"; id: string }
                                                      | { __typename: "ST4TreeNode" }
                                                      | null
                                                    target?:
                                                      | { __typename: "CommentNode" }
                                                      | {
                                                          __typename: "ExternalSite"
                                                          id: string
                                                          teaserData?: {
                                                            __typename: "ExternalLinkTeaserData"
                                                            title?: string | null
                                                            summary?: string | null
                                                            iconUrl?: string | null
                                                            imageUrl?: string | null
                                                            status?: Types.LinkTargetStatus | null
                                                          } | null
                                                        }
                                                      | { __typename: "ST4Class" }
                                                      | {
                                                          __typename: "ST4Node"
                                                          id: string
                                                          aspectId: any
                                                          label?: string | null
                                                          properties?: {
                                                            __typename: "ContentPropertyConnection"
                                                            edges?: Array<{
                                                              __typename: "ContentPropertyEdge"
                                                              node?: {
                                                                __typename: "ContentProperty"
                                                                name: string
                                                                value?: any | null
                                                                displayName: string
                                                              } | null
                                                            } | null> | null
                                                          } | null
                                                        }
                                                      | { __typename: "ST4TreeNode" }
                                                      | null
                                                  }
                                                | {
                                                    __typename: "NodeRef"
                                                    id: string
                                                    owner?:
                                                      | { __typename: "CommentNode" }
                                                      | { __typename: "ExternalSite" }
                                                      | { __typename: "ST4Class" }
                                                      | { __typename: "ST4Node"; id: string }
                                                      | { __typename: "ST4TreeNode" }
                                                      | null
                                                    target?:
                                                      | { __typename: "CommentNode" }
                                                      | {
                                                          __typename: "ExternalSite"
                                                          id: string
                                                          teaserData?: {
                                                            __typename: "ExternalLinkTeaserData"
                                                            title?: string | null
                                                            summary?: string | null
                                                            iconUrl?: string | null
                                                            imageUrl?: string | null
                                                            status?: Types.LinkTargetStatus | null
                                                          } | null
                                                        }
                                                      | { __typename: "ST4Class" }
                                                      | {
                                                          __typename: "ST4Node"
                                                          id: string
                                                          aspectId: any
                                                          label?: string | null
                                                          properties?: {
                                                            __typename: "ContentPropertyConnection"
                                                            edges?: Array<{
                                                              __typename: "ContentPropertyEdge"
                                                              node?: {
                                                                __typename: "ContentProperty"
                                                                name: string
                                                                value?: any | null
                                                                displayName: string
                                                              } | null
                                                            } | null> | null
                                                          } | null
                                                        }
                                                      | { __typename: "ST4TreeNode" }
                                                      | null
                                                  }
                                                | null
                                            }>
                                          }
                                        | {
                                            __typename: "VideoMediaInfo"
                                            id: string
                                            resourceId?: string | null
                                            thumbnail?: string | null
                                            width?: number | null
                                            height?: number | null
                                            mediaType?: Types.MediaType | null
                                          }
                                        | null
                                    }
                                  | {
                                      __typename: "MediaGroupContent"
                                      children: Array<{
                                        __typename: "NodeRef"
                                        id: string
                                        target?:
                                          | { __typename: "CommentNode"; id: string }
                                          | { __typename: "ExternalSite"; id: string }
                                          | { __typename: "ST4Class"; id: string }
                                          | {
                                              __typename: "ST4Node"
                                              id: string
                                              aspectId: any
                                              content?:
                                                | { __typename: "LexiconContent" }
                                                | { __typename: "LexiconEntryContent" }
                                                | {
                                                    __typename: "MediaContent"
                                                    media?:
                                                      | {
                                                          __typename: "AnimationMediaInfo"
                                                          id: string
                                                          resourceId?: string | null
                                                          thumbnail?: string | null
                                                          width?: number | null
                                                          height?: number | null
                                                          mediaType?: Types.MediaType | null
                                                        }
                                                      | {
                                                          __typename: "ImageMediaInfo"
                                                          id: string
                                                          resourceId?: string | null
                                                          thumbnail?: string | null
                                                          width?: number | null
                                                          height?: number | null
                                                          resolution?: number | null
                                                          colorDepth?: number | null
                                                          size?: number | null
                                                          mime?: string | null
                                                          extension?: string | null
                                                          allowCaching?: boolean | null
                                                          mediaType?: Types.MediaType | null
                                                          src?: string | null
                                                          language?: string | null
                                                        }
                                                      | {
                                                          __typename: "NoMediaInfo"
                                                          id: string
                                                          mediaType?: Types.MediaType | null
                                                        }
                                                      | {
                                                          __typename: "VectorGraphicsMediaInfo"
                                                          id: string
                                                          resourceId?: string | null
                                                          thumbnail?: string | null
                                                          width?: number | null
                                                          height?: number | null
                                                          allowCaching?: boolean | null
                                                          mediaType?: Types.MediaType | null
                                                          language?: string | null
                                                          labels: Array<{
                                                            __typename: "VectorGraphicsLabel"
                                                            id?: string | null
                                                            sequence?: number | null
                                                            autoValue?: string | null
                                                            text?: string | null
                                                            linkType?: Types.VectorGraphicsLabelLinkType | null
                                                            link?: string | null
                                                            ref?:
                                                              | {
                                                                  __typename: "GenericRef"
                                                                  id: string
                                                                  owner?:
                                                                    | { __typename: "CommentNode" }
                                                                    | { __typename: "ExternalSite" }
                                                                    | { __typename: "ST4Class" }
                                                                    | { __typename: "ST4Node"; id: string }
                                                                    | { __typename: "ST4TreeNode" }
                                                                    | null
                                                                  target?:
                                                                    | { __typename: "CommentNode" }
                                                                    | {
                                                                        __typename: "ExternalSite"
                                                                        id: string
                                                                        teaserData?: {
                                                                          __typename: "ExternalLinkTeaserData"
                                                                          title?: string | null
                                                                          summary?: string | null
                                                                          iconUrl?: string | null
                                                                          imageUrl?: string | null
                                                                          status?: Types.LinkTargetStatus | null
                                                                        } | null
                                                                      }
                                                                    | { __typename: "ST4Class" }
                                                                    | {
                                                                        __typename: "ST4Node"
                                                                        id: string
                                                                        aspectId: any
                                                                        label?: string | null
                                                                        properties?: {
                                                                          __typename: "ContentPropertyConnection"
                                                                          edges?: Array<{
                                                                            __typename: "ContentPropertyEdge"
                                                                            node?: {
                                                                              __typename: "ContentProperty"
                                                                              name: string
                                                                              value?: any | null
                                                                              displayName: string
                                                                            } | null
                                                                          } | null> | null
                                                                        } | null
                                                                      }
                                                                    | { __typename: "ST4TreeNode" }
                                                                    | null
                                                                }
                                                              | {
                                                                  __typename: "NodeRef"
                                                                  id: string
                                                                  owner?:
                                                                    | { __typename: "CommentNode" }
                                                                    | { __typename: "ExternalSite" }
                                                                    | { __typename: "ST4Class" }
                                                                    | { __typename: "ST4Node"; id: string }
                                                                    | { __typename: "ST4TreeNode" }
                                                                    | null
                                                                  target?:
                                                                    | { __typename: "CommentNode" }
                                                                    | {
                                                                        __typename: "ExternalSite"
                                                                        id: string
                                                                        teaserData?: {
                                                                          __typename: "ExternalLinkTeaserData"
                                                                          title?: string | null
                                                                          summary?: string | null
                                                                          iconUrl?: string | null
                                                                          imageUrl?: string | null
                                                                          status?: Types.LinkTargetStatus | null
                                                                        } | null
                                                                      }
                                                                    | { __typename: "ST4Class" }
                                                                    | {
                                                                        __typename: "ST4Node"
                                                                        id: string
                                                                        aspectId: any
                                                                        label?: string | null
                                                                        properties?: {
                                                                          __typename: "ContentPropertyConnection"
                                                                          edges?: Array<{
                                                                            __typename: "ContentPropertyEdge"
                                                                            node?: {
                                                                              __typename: "ContentProperty"
                                                                              name: string
                                                                              value?: any | null
                                                                              displayName: string
                                                                            } | null
                                                                          } | null> | null
                                                                        } | null
                                                                      }
                                                                    | { __typename: "ST4TreeNode" }
                                                                    | null
                                                                }
                                                              | null
                                                          }>
                                                        }
                                                      | {
                                                          __typename: "VideoMediaInfo"
                                                          id: string
                                                          resourceId?: string | null
                                                          thumbnail?: string | null
                                                          width?: number | null
                                                          height?: number | null
                                                          mediaType?: Types.MediaType | null
                                                        }
                                                      | null
                                                  }
                                                | {
                                                    __typename: "MediaGroupContent"
                                                    media?:
                                                      | {
                                                          __typename: "AnimationMediaInfo"
                                                          id: string
                                                          resourceId?: string | null
                                                          thumbnail?: string | null
                                                          width?: number | null
                                                          height?: number | null
                                                          mediaType?: Types.MediaType | null
                                                        }
                                                      | {
                                                          __typename: "ImageMediaInfo"
                                                          id: string
                                                          resourceId?: string | null
                                                          thumbnail?: string | null
                                                          width?: number | null
                                                          height?: number | null
                                                          resolution?: number | null
                                                          colorDepth?: number | null
                                                          size?: number | null
                                                          mime?: string | null
                                                          extension?: string | null
                                                          allowCaching?: boolean | null
                                                          mediaType?: Types.MediaType | null
                                                          src?: string | null
                                                          language?: string | null
                                                        }
                                                      | {
                                                          __typename: "NoMediaInfo"
                                                          id: string
                                                          mediaType?: Types.MediaType | null
                                                        }
                                                      | {
                                                          __typename: "VectorGraphicsMediaInfo"
                                                          id: string
                                                          resourceId?: string | null
                                                          thumbnail?: string | null
                                                          width?: number | null
                                                          height?: number | null
                                                          allowCaching?: boolean | null
                                                          mediaType?: Types.MediaType | null
                                                          language?: string | null
                                                          labels: Array<{
                                                            __typename: "VectorGraphicsLabel"
                                                            id?: string | null
                                                            sequence?: number | null
                                                            autoValue?: string | null
                                                            text?: string | null
                                                            linkType?: Types.VectorGraphicsLabelLinkType | null
                                                            link?: string | null
                                                            ref?:
                                                              | {
                                                                  __typename: "GenericRef"
                                                                  id: string
                                                                  owner?:
                                                                    | { __typename: "CommentNode" }
                                                                    | { __typename: "ExternalSite" }
                                                                    | { __typename: "ST4Class" }
                                                                    | { __typename: "ST4Node"; id: string }
                                                                    | { __typename: "ST4TreeNode" }
                                                                    | null
                                                                  target?:
                                                                    | { __typename: "CommentNode" }
                                                                    | {
                                                                        __typename: "ExternalSite"
                                                                        id: string
                                                                        teaserData?: {
                                                                          __typename: "ExternalLinkTeaserData"
                                                                          title?: string | null
                                                                          summary?: string | null
                                                                          iconUrl?: string | null
                                                                          imageUrl?: string | null
                                                                          status?: Types.LinkTargetStatus | null
                                                                        } | null
                                                                      }
                                                                    | { __typename: "ST4Class" }
                                                                    | {
                                                                        __typename: "ST4Node"
                                                                        id: string
                                                                        aspectId: any
                                                                        label?: string | null
                                                                        properties?: {
                                                                          __typename: "ContentPropertyConnection"
                                                                          edges?: Array<{
                                                                            __typename: "ContentPropertyEdge"
                                                                            node?: {
                                                                              __typename: "ContentProperty"
                                                                              name: string
                                                                              value?: any | null
                                                                              displayName: string
                                                                            } | null
                                                                          } | null> | null
                                                                        } | null
                                                                      }
                                                                    | { __typename: "ST4TreeNode" }
                                                                    | null
                                                                }
                                                              | {
                                                                  __typename: "NodeRef"
                                                                  id: string
                                                                  owner?:
                                                                    | { __typename: "CommentNode" }
                                                                    | { __typename: "ExternalSite" }
                                                                    | { __typename: "ST4Class" }
                                                                    | { __typename: "ST4Node"; id: string }
                                                                    | { __typename: "ST4TreeNode" }
                                                                    | null
                                                                  target?:
                                                                    | { __typename: "CommentNode" }
                                                                    | {
                                                                        __typename: "ExternalSite"
                                                                        id: string
                                                                        teaserData?: {
                                                                          __typename: "ExternalLinkTeaserData"
                                                                          title?: string | null
                                                                          summary?: string | null
                                                                          iconUrl?: string | null
                                                                          imageUrl?: string | null
                                                                          status?: Types.LinkTargetStatus | null
                                                                        } | null
                                                                      }
                                                                    | { __typename: "ST4Class" }
                                                                    | {
                                                                        __typename: "ST4Node"
                                                                        id: string
                                                                        aspectId: any
                                                                        label?: string | null
                                                                        properties?: {
                                                                          __typename: "ContentPropertyConnection"
                                                                          edges?: Array<{
                                                                            __typename: "ContentPropertyEdge"
                                                                            node?: {
                                                                              __typename: "ContentProperty"
                                                                              name: string
                                                                              value?: any | null
                                                                              displayName: string
                                                                            } | null
                                                                          } | null> | null
                                                                        } | null
                                                                      }
                                                                    | { __typename: "ST4TreeNode" }
                                                                    | null
                                                                }
                                                              | null
                                                          }>
                                                        }
                                                      | {
                                                          __typename: "VideoMediaInfo"
                                                          id: string
                                                          resourceId?: string | null
                                                          thumbnail?: string | null
                                                          width?: number | null
                                                          height?: number | null
                                                          mediaType?: Types.MediaType | null
                                                        }
                                                      | null
                                                  }
                                                | { __typename: "ProjectContent" }
                                                | { __typename: "TermContent" }
                                                | { __typename: "TextContent" }
                                                | { __typename: "TextGroupContent" }
                                                | { __typename: "VariableTableContent" }
                                                | null
                                            }
                                          | { __typename: "ST4TreeNode"; id: string }
                                          | null
                                      }>
                                      media?:
                                        | {
                                            __typename: "AnimationMediaInfo"
                                            id: string
                                            resourceId?: string | null
                                            thumbnail?: string | null
                                            width?: number | null
                                            height?: number | null
                                            mediaType?: Types.MediaType | null
                                          }
                                        | {
                                            __typename: "ImageMediaInfo"
                                            id: string
                                            resourceId?: string | null
                                            thumbnail?: string | null
                                            width?: number | null
                                            height?: number | null
                                            resolution?: number | null
                                            colorDepth?: number | null
                                            size?: number | null
                                            mime?: string | null
                                            extension?: string | null
                                            allowCaching?: boolean | null
                                            mediaType?: Types.MediaType | null
                                            src?: string | null
                                            language?: string | null
                                          }
                                        | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                                        | {
                                            __typename: "VectorGraphicsMediaInfo"
                                            id: string
                                            resourceId?: string | null
                                            thumbnail?: string | null
                                            width?: number | null
                                            height?: number | null
                                            allowCaching?: boolean | null
                                            mediaType?: Types.MediaType | null
                                            language?: string | null
                                            labels: Array<{
                                              __typename: "VectorGraphicsLabel"
                                              id?: string | null
                                              sequence?: number | null
                                              autoValue?: string | null
                                              text?: string | null
                                              linkType?: Types.VectorGraphicsLabelLinkType | null
                                              link?: string | null
                                              ref?:
                                                | {
                                                    __typename: "GenericRef"
                                                    id: string
                                                    owner?:
                                                      | { __typename: "CommentNode" }
                                                      | { __typename: "ExternalSite" }
                                                      | { __typename: "ST4Class" }
                                                      | { __typename: "ST4Node"; id: string }
                                                      | { __typename: "ST4TreeNode" }
                                                      | null
                                                    target?:
                                                      | { __typename: "CommentNode" }
                                                      | {
                                                          __typename: "ExternalSite"
                                                          id: string
                                                          teaserData?: {
                                                            __typename: "ExternalLinkTeaserData"
                                                            title?: string | null
                                                            summary?: string | null
                                                            iconUrl?: string | null
                                                            imageUrl?: string | null
                                                            status?: Types.LinkTargetStatus | null
                                                          } | null
                                                        }
                                                      | { __typename: "ST4Class" }
                                                      | {
                                                          __typename: "ST4Node"
                                                          id: string
                                                          aspectId: any
                                                          label?: string | null
                                                          properties?: {
                                                            __typename: "ContentPropertyConnection"
                                                            edges?: Array<{
                                                              __typename: "ContentPropertyEdge"
                                                              node?: {
                                                                __typename: "ContentProperty"
                                                                name: string
                                                                value?: any | null
                                                                displayName: string
                                                              } | null
                                                            } | null> | null
                                                          } | null
                                                        }
                                                      | { __typename: "ST4TreeNode" }
                                                      | null
                                                  }
                                                | {
                                                    __typename: "NodeRef"
                                                    id: string
                                                    owner?:
                                                      | { __typename: "CommentNode" }
                                                      | { __typename: "ExternalSite" }
                                                      | { __typename: "ST4Class" }
                                                      | { __typename: "ST4Node"; id: string }
                                                      | { __typename: "ST4TreeNode" }
                                                      | null
                                                    target?:
                                                      | { __typename: "CommentNode" }
                                                      | {
                                                          __typename: "ExternalSite"
                                                          id: string
                                                          teaserData?: {
                                                            __typename: "ExternalLinkTeaserData"
                                                            title?: string | null
                                                            summary?: string | null
                                                            iconUrl?: string | null
                                                            imageUrl?: string | null
                                                            status?: Types.LinkTargetStatus | null
                                                          } | null
                                                        }
                                                      | { __typename: "ST4Class" }
                                                      | {
                                                          __typename: "ST4Node"
                                                          id: string
                                                          aspectId: any
                                                          label?: string | null
                                                          properties?: {
                                                            __typename: "ContentPropertyConnection"
                                                            edges?: Array<{
                                                              __typename: "ContentPropertyEdge"
                                                              node?: {
                                                                __typename: "ContentProperty"
                                                                name: string
                                                                value?: any | null
                                                                displayName: string
                                                              } | null
                                                            } | null> | null
                                                          } | null
                                                        }
                                                      | { __typename: "ST4TreeNode" }
                                                      | null
                                                  }
                                                | null
                                            }>
                                          }
                                        | {
                                            __typename: "VideoMediaInfo"
                                            id: string
                                            resourceId?: string | null
                                            thumbnail?: string | null
                                            width?: number | null
                                            height?: number | null
                                            mediaType?: Types.MediaType | null
                                          }
                                        | null
                                    }
                                  | { __typename: "ProjectContent" }
                                  | { __typename: "TermContent" }
                                  | { __typename: "TextContent" }
                                  | { __typename: "TextGroupContent" }
                                  | { __typename: "VariableTableContent" }
                                  | null
                              }
                            | { __typename: "ST4TreeNode"; id: string }
                            | null
                        }>
                        variableTables?: Array<{
                          __typename: "VariableTableReference"
                          ref?: string | null
                          node?:
                            | { __typename: "CommentNode" }
                            | { __typename: "ExternalSite" }
                            | { __typename: "ST4Class" }
                            | {
                                __typename: "ST4Node"
                                id: string
                                label?: string | null
                                aspectId: any
                                content?:
                                  | { __typename: "LexiconContent" }
                                  | { __typename: "LexiconEntryContent" }
                                  | { __typename: "MediaContent" }
                                  | { __typename: "MediaGroupContent" }
                                  | { __typename: "ProjectContent" }
                                  | { __typename: "TermContent" }
                                  | { __typename: "TextContent" }
                                  | { __typename: "TextGroupContent" }
                                  | {
                                      __typename: "VariableTableContent"
                                      xml?: string | null
                                      valueSets: Array<{ __typename: "ValueSet"; name: string; valueSetId: string }>
                                      variables: Array<{
                                        __typename: "Variable"
                                        name: string
                                        variableId: string
                                        values: Array<string>
                                      }>
                                    }
                                  | null
                              }
                            | { __typename: "ST4TreeNode" }
                            | null
                        } | null> | null
                        linkedDataNodes: Array<{
                          __typename: "NodeRef"
                          id: string
                          target?:
                            | { __typename: "CommentNode"; id: string }
                            | { __typename: "ExternalSite"; id: string }
                            | { __typename: "ST4Class"; id: string }
                            | {
                                __typename: "ST4Node"
                                label?: string | null
                                id: string
                                metadata: Array<{
                                  __typename: "MetaDataEntry"
                                  label?: string | null
                                  systemName: string
                                  value?: string | null
                                  values: Array<string>
                                  users: Array<string>
                                }>
                                linkedOntologyNodes: Array<
                                  | {
                                      __typename: "GenericRef"
                                      target?:
                                        | { __typename: "CommentNode" }
                                        | { __typename: "ExternalSite" }
                                        | { __typename: "ST4Class" }
                                        | { __typename: "ST4Node"; id: string; label?: string | null }
                                        | { __typename: "ST4TreeNode" }
                                        | null
                                    }
                                  | {
                                      __typename: "NodeRef"
                                      linkClassName: string
                                      class?: { __typename: "ST4Class"; label?: string | null } | null
                                      target?:
                                        | { __typename: "CommentNode" }
                                        | { __typename: "ExternalSite" }
                                        | { __typename: "ST4Class" }
                                        | { __typename: "ST4Node"; id: string; label?: string | null }
                                        | { __typename: "ST4TreeNode" }
                                        | null
                                    }
                                >
                              }
                            | { __typename: "ST4TreeNode"; id: string }
                            | null
                        }>
                        lexiconEntries?: Array<{
                          __typename: "NodeRef"
                          id: string
                          target?:
                            | { __typename: "CommentNode"; id: string }
                            | { __typename: "ExternalSite"; id: string }
                            | { __typename: "ST4Class"; id: string }
                            | {
                                __typename: "ST4Node"
                                aspectId: any
                                label?: string | null
                                id: string
                                content?:
                                  | { __typename: "LexiconContent" }
                                  | {
                                      __typename: "LexiconEntryContent"
                                      descriptionXast?: string | null
                                      subject?: string | null
                                      sortKey?: string | null
                                      lexicon:
                                        | { __typename: "CommentNode"; id: string }
                                        | { __typename: "ExternalSite"; id: string }
                                        | { __typename: "ST4Class"; id: string }
                                        | { __typename: "ST4Node"; label?: string | null; id: string }
                                        | { __typename: "ST4TreeNode"; id: string }
                                      terms: Array<
                                        | { __typename: "CommentNode"; id: string }
                                        | { __typename: "ExternalSite"; id: string }
                                        | { __typename: "ST4Class"; id: string }
                                        | {
                                            __typename: "ST4Node"
                                            id: string
                                            label?: string | null
                                            aspectId: any
                                            content?:
                                              | { __typename: "LexiconContent" }
                                              | { __typename: "LexiconEntryContent" }
                                              | { __typename: "MediaContent" }
                                              | { __typename: "MediaGroupContent" }
                                              | { __typename: "ProjectContent" }
                                              | {
                                                  __typename: "TermContent"
                                                  gramaticalGender?: string | null
                                                  validity?: string | null
                                                  editLanguage?: string | null
                                                }
                                              | { __typename: "TextContent" }
                                              | { __typename: "TextGroupContent" }
                                              | { __typename: "VariableTableContent" }
                                              | null
                                          }
                                        | { __typename: "ST4TreeNode"; id: string }
                                      >
                                      image?:
                                        | { __typename: "CommentNode"; id: string }
                                        | { __typename: "ExternalSite"; id: string }
                                        | { __typename: "ST4Class"; id: string }
                                        | {
                                            __typename: "ST4Node"
                                            aspectId: any
                                            id: string
                                            content?:
                                              | { __typename: "LexiconContent" }
                                              | { __typename: "LexiconEntryContent" }
                                              | {
                                                  __typename: "MediaContent"
                                                  media?:
                                                    | {
                                                        __typename: "AnimationMediaInfo"
                                                        thumbnail?: string | null
                                                        mediaType?: Types.MediaType | null
                                                      }
                                                    | {
                                                        __typename: "ImageMediaInfo"
                                                        src?: string | null
                                                        thumbnail?: string | null
                                                        mediaType?: Types.MediaType | null
                                                      }
                                                    | { __typename: "NoMediaInfo" }
                                                    | {
                                                        __typename: "VectorGraphicsMediaInfo"
                                                        svg?: string | null
                                                        thumbnail?: string | null
                                                        mediaType?: Types.MediaType | null
                                                      }
                                                    | {
                                                        __typename: "VideoMediaInfo"
                                                        thumbnail?: string | null
                                                        mediaType?: Types.MediaType | null
                                                      }
                                                    | null
                                                }
                                              | { __typename: "MediaGroupContent" }
                                              | { __typename: "ProjectContent" }
                                              | { __typename: "TermContent" }
                                              | { __typename: "TextContent" }
                                              | { __typename: "TextGroupContent" }
                                              | { __typename: "VariableTableContent" }
                                              | null
                                          }
                                        | { __typename: "ST4TreeNode"; id: string }
                                        | null
                                    }
                                  | { __typename: "MediaContent" }
                                  | { __typename: "MediaGroupContent" }
                                  | { __typename: "ProjectContent" }
                                  | { __typename: "TermContent" }
                                  | { __typename: "TextContent" }
                                  | { __typename: "TextGroupContent" }
                                  | { __typename: "VariableTableContent" }
                                  | null
                              }
                            | { __typename: "ST4TreeNode"; id: string }
                            | null
                        } | null> | null
                        comments: Array<{
                          __typename: "CommentNode"
                          id: string
                          comment:
                            | {
                                __typename: "BlockComment"
                                location: number
                                value?: string | null
                                authorDisplayName?: string | null
                                authorUserName?: string | null
                                commentKey: string
                                editDate?: any | null
                                modificationVersion?: number | null
                                replyToKey?: string | null
                                sortOrder?: number | null
                                commentType?: string | null
                                state?: string | null
                                color: string
                                persistanceState?: Types.PersistanceState | null
                                persistanceAction?: Types.PersistanceAction | null
                                node: {
                                  __typename: "ST4Node"
                                  aspectId: any
                                  id: string
                                  content?:
                                    | { __typename: "LexiconContent" }
                                    | { __typename: "LexiconEntryContent" }
                                    | { __typename: "MediaContent" }
                                    | { __typename: "MediaGroupContent" }
                                    | { __typename: "ProjectContent" }
                                    | { __typename: "TermContent" }
                                    | { __typename: "TextContent"; id: string; historyNumber: number }
                                    | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                                    | { __typename: "VariableTableContent" }
                                    | null
                                }
                                actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
                              }
                            | {
                                __typename: "TextComment"
                                endLocation: number
                                startLocation?: number | null
                                value?: string | null
                                authorDisplayName?: string | null
                                authorUserName?: string | null
                                commentKey: string
                                editDate?: any | null
                                modificationVersion?: number | null
                                replyToKey?: string | null
                                sortOrder?: number | null
                                commentType?: string | null
                                state?: string | null
                                color: string
                                persistanceState?: Types.PersistanceState | null
                                persistanceAction?: Types.PersistanceAction | null
                                node: {
                                  __typename: "ST4Node"
                                  aspectId: any
                                  id: string
                                  content?:
                                    | { __typename: "LexiconContent" }
                                    | { __typename: "LexiconEntryContent" }
                                    | { __typename: "MediaContent" }
                                    | { __typename: "MediaGroupContent" }
                                    | { __typename: "ProjectContent" }
                                    | { __typename: "TermContent" }
                                    | { __typename: "TextContent"; id: string; historyNumber: number }
                                    | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                                    | { __typename: "VariableTableContent" }
                                    | null
                                }
                                actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
                              }
                        }>
                      }
                    | { __typename: "VariableTableContent" }
                    | null
                }
              }>
              parts: Array<{
                __typename: "TextContentPart"
                ref: string
                contentNode: {
                  __typename: "ST4Node"
                  id: string
                  aspectId: any
                  content?:
                    | { __typename: "LexiconContent" }
                    | { __typename: "LexiconEntryContent" }
                    | { __typename: "MediaContent" }
                    | { __typename: "MediaGroupContent" }
                    | { __typename: "ProjectContent" }
                    | { __typename: "TermContent" }
                    | {
                        __typename: "TextContent"
                        id: string
                        xast?: string | null
                        historyNumber: number
                        links?: Array<{
                          __typename: "TextContentLink"
                          ref?: string | null
                          node?:
                            | { __typename: "CommentNode"; id: string }
                            | { __typename: "ExternalSite"; id: string }
                            | { __typename: "ST4Class"; id: string }
                            | {
                                __typename: "ST4Node"
                                id: string
                                aspectId: any
                                label?: string | null
                                properties?: {
                                  __typename: "ContentPropertyConnection"
                                  totalCount: number
                                  edges?: Array<{
                                    __typename: "ContentPropertyEdge"
                                    node?: {
                                      __typename: "ContentProperty"
                                      typeOfValue: Types.ContentPropertyValueType
                                      name: string
                                      value?: any | null
                                      displayName: string
                                    } | null
                                  } | null> | null
                                } | null
                              }
                            | { __typename: "ST4TreeNode"; id: string }
                            | null
                        } | null> | null
                        images: Array<{
                          __typename: "NodeRef"
                          id: string
                          target?:
                            | { __typename: "CommentNode"; id: string }
                            | { __typename: "ExternalSite"; id: string }
                            | { __typename: "ST4Class"; id: string }
                            | {
                                __typename: "ST4Node"
                                aspectId: any
                                id: string
                                releaseState: {
                                  __typename: "ReleaseStateValue"
                                  displayName: string
                                  state: Types.ReleaseState
                                  isImplicitlyReleased: boolean
                                }
                                content?:
                                  | { __typename: "LexiconContent" }
                                  | { __typename: "LexiconEntryContent" }
                                  | {
                                      __typename: "MediaContent"
                                      media?:
                                        | {
                                            __typename: "AnimationMediaInfo"
                                            id: string
                                            resourceId?: string | null
                                            thumbnail?: string | null
                                            width?: number | null
                                            height?: number | null
                                            mediaType?: Types.MediaType | null
                                          }
                                        | {
                                            __typename: "ImageMediaInfo"
                                            id: string
                                            resourceId?: string | null
                                            thumbnail?: string | null
                                            width?: number | null
                                            height?: number | null
                                            resolution?: number | null
                                            colorDepth?: number | null
                                            size?: number | null
                                            mime?: string | null
                                            extension?: string | null
                                            allowCaching?: boolean | null
                                            mediaType?: Types.MediaType | null
                                            src?: string | null
                                            language?: string | null
                                          }
                                        | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                                        | {
                                            __typename: "VectorGraphicsMediaInfo"
                                            id: string
                                            resourceId?: string | null
                                            thumbnail?: string | null
                                            width?: number | null
                                            height?: number | null
                                            allowCaching?: boolean | null
                                            mediaType?: Types.MediaType | null
                                            language?: string | null
                                            labels: Array<{
                                              __typename: "VectorGraphicsLabel"
                                              id?: string | null
                                              sequence?: number | null
                                              autoValue?: string | null
                                              text?: string | null
                                              linkType?: Types.VectorGraphicsLabelLinkType | null
                                              link?: string | null
                                              ref?:
                                                | {
                                                    __typename: "GenericRef"
                                                    id: string
                                                    owner?:
                                                      | { __typename: "CommentNode" }
                                                      | { __typename: "ExternalSite" }
                                                      | { __typename: "ST4Class" }
                                                      | { __typename: "ST4Node"; id: string }
                                                      | { __typename: "ST4TreeNode" }
                                                      | null
                                                    target?:
                                                      | { __typename: "CommentNode" }
                                                      | {
                                                          __typename: "ExternalSite"
                                                          id: string
                                                          teaserData?: {
                                                            __typename: "ExternalLinkTeaserData"
                                                            title?: string | null
                                                            summary?: string | null
                                                            iconUrl?: string | null
                                                            imageUrl?: string | null
                                                            status?: Types.LinkTargetStatus | null
                                                          } | null
                                                        }
                                                      | { __typename: "ST4Class" }
                                                      | {
                                                          __typename: "ST4Node"
                                                          id: string
                                                          aspectId: any
                                                          label?: string | null
                                                          properties?: {
                                                            __typename: "ContentPropertyConnection"
                                                            edges?: Array<{
                                                              __typename: "ContentPropertyEdge"
                                                              node?: {
                                                                __typename: "ContentProperty"
                                                                name: string
                                                                value?: any | null
                                                                displayName: string
                                                              } | null
                                                            } | null> | null
                                                          } | null
                                                        }
                                                      | { __typename: "ST4TreeNode" }
                                                      | null
                                                  }
                                                | {
                                                    __typename: "NodeRef"
                                                    id: string
                                                    owner?:
                                                      | { __typename: "CommentNode" }
                                                      | { __typename: "ExternalSite" }
                                                      | { __typename: "ST4Class" }
                                                      | { __typename: "ST4Node"; id: string }
                                                      | { __typename: "ST4TreeNode" }
                                                      | null
                                                    target?:
                                                      | { __typename: "CommentNode" }
                                                      | {
                                                          __typename: "ExternalSite"
                                                          id: string
                                                          teaserData?: {
                                                            __typename: "ExternalLinkTeaserData"
                                                            title?: string | null
                                                            summary?: string | null
                                                            iconUrl?: string | null
                                                            imageUrl?: string | null
                                                            status?: Types.LinkTargetStatus | null
                                                          } | null
                                                        }
                                                      | { __typename: "ST4Class" }
                                                      | {
                                                          __typename: "ST4Node"
                                                          id: string
                                                          aspectId: any
                                                          label?: string | null
                                                          properties?: {
                                                            __typename: "ContentPropertyConnection"
                                                            edges?: Array<{
                                                              __typename: "ContentPropertyEdge"
                                                              node?: {
                                                                __typename: "ContentProperty"
                                                                name: string
                                                                value?: any | null
                                                                displayName: string
                                                              } | null
                                                            } | null> | null
                                                          } | null
                                                        }
                                                      | { __typename: "ST4TreeNode" }
                                                      | null
                                                  }
                                                | null
                                            }>
                                          }
                                        | {
                                            __typename: "VideoMediaInfo"
                                            id: string
                                            resourceId?: string | null
                                            thumbnail?: string | null
                                            width?: number | null
                                            height?: number | null
                                            mediaType?: Types.MediaType | null
                                          }
                                        | null
                                    }
                                  | {
                                      __typename: "MediaGroupContent"
                                      children: Array<{
                                        __typename: "NodeRef"
                                        id: string
                                        target?:
                                          | { __typename: "CommentNode"; id: string }
                                          | { __typename: "ExternalSite"; id: string }
                                          | { __typename: "ST4Class"; id: string }
                                          | {
                                              __typename: "ST4Node"
                                              id: string
                                              aspectId: any
                                              content?:
                                                | { __typename: "LexiconContent" }
                                                | { __typename: "LexiconEntryContent" }
                                                | {
                                                    __typename: "MediaContent"
                                                    media?:
                                                      | {
                                                          __typename: "AnimationMediaInfo"
                                                          id: string
                                                          resourceId?: string | null
                                                          thumbnail?: string | null
                                                          width?: number | null
                                                          height?: number | null
                                                          mediaType?: Types.MediaType | null
                                                        }
                                                      | {
                                                          __typename: "ImageMediaInfo"
                                                          id: string
                                                          resourceId?: string | null
                                                          thumbnail?: string | null
                                                          width?: number | null
                                                          height?: number | null
                                                          resolution?: number | null
                                                          colorDepth?: number | null
                                                          size?: number | null
                                                          mime?: string | null
                                                          extension?: string | null
                                                          allowCaching?: boolean | null
                                                          mediaType?: Types.MediaType | null
                                                          src?: string | null
                                                          language?: string | null
                                                        }
                                                      | {
                                                          __typename: "NoMediaInfo"
                                                          id: string
                                                          mediaType?: Types.MediaType | null
                                                        }
                                                      | {
                                                          __typename: "VectorGraphicsMediaInfo"
                                                          id: string
                                                          resourceId?: string | null
                                                          thumbnail?: string | null
                                                          width?: number | null
                                                          height?: number | null
                                                          allowCaching?: boolean | null
                                                          mediaType?: Types.MediaType | null
                                                          language?: string | null
                                                          labels: Array<{
                                                            __typename: "VectorGraphicsLabel"
                                                            id?: string | null
                                                            sequence?: number | null
                                                            autoValue?: string | null
                                                            text?: string | null
                                                            linkType?: Types.VectorGraphicsLabelLinkType | null
                                                            link?: string | null
                                                            ref?:
                                                              | {
                                                                  __typename: "GenericRef"
                                                                  id: string
                                                                  owner?:
                                                                    | { __typename: "CommentNode" }
                                                                    | { __typename: "ExternalSite" }
                                                                    | { __typename: "ST4Class" }
                                                                    | { __typename: "ST4Node"; id: string }
                                                                    | { __typename: "ST4TreeNode" }
                                                                    | null
                                                                  target?:
                                                                    | { __typename: "CommentNode" }
                                                                    | {
                                                                        __typename: "ExternalSite"
                                                                        id: string
                                                                        teaserData?: {
                                                                          __typename: "ExternalLinkTeaserData"
                                                                          title?: string | null
                                                                          summary?: string | null
                                                                          iconUrl?: string | null
                                                                          imageUrl?: string | null
                                                                          status?: Types.LinkTargetStatus | null
                                                                        } | null
                                                                      }
                                                                    | { __typename: "ST4Class" }
                                                                    | {
                                                                        __typename: "ST4Node"
                                                                        id: string
                                                                        aspectId: any
                                                                        label?: string | null
                                                                        properties?: {
                                                                          __typename: "ContentPropertyConnection"
                                                                          edges?: Array<{
                                                                            __typename: "ContentPropertyEdge"
                                                                            node?: {
                                                                              __typename: "ContentProperty"
                                                                              name: string
                                                                              value?: any | null
                                                                              displayName: string
                                                                            } | null
                                                                          } | null> | null
                                                                        } | null
                                                                      }
                                                                    | { __typename: "ST4TreeNode" }
                                                                    | null
                                                                }
                                                              | {
                                                                  __typename: "NodeRef"
                                                                  id: string
                                                                  owner?:
                                                                    | { __typename: "CommentNode" }
                                                                    | { __typename: "ExternalSite" }
                                                                    | { __typename: "ST4Class" }
                                                                    | { __typename: "ST4Node"; id: string }
                                                                    | { __typename: "ST4TreeNode" }
                                                                    | null
                                                                  target?:
                                                                    | { __typename: "CommentNode" }
                                                                    | {
                                                                        __typename: "ExternalSite"
                                                                        id: string
                                                                        teaserData?: {
                                                                          __typename: "ExternalLinkTeaserData"
                                                                          title?: string | null
                                                                          summary?: string | null
                                                                          iconUrl?: string | null
                                                                          imageUrl?: string | null
                                                                          status?: Types.LinkTargetStatus | null
                                                                        } | null
                                                                      }
                                                                    | { __typename: "ST4Class" }
                                                                    | {
                                                                        __typename: "ST4Node"
                                                                        id: string
                                                                        aspectId: any
                                                                        label?: string | null
                                                                        properties?: {
                                                                          __typename: "ContentPropertyConnection"
                                                                          edges?: Array<{
                                                                            __typename: "ContentPropertyEdge"
                                                                            node?: {
                                                                              __typename: "ContentProperty"
                                                                              name: string
                                                                              value?: any | null
                                                                              displayName: string
                                                                            } | null
                                                                          } | null> | null
                                                                        } | null
                                                                      }
                                                                    | { __typename: "ST4TreeNode" }
                                                                    | null
                                                                }
                                                              | null
                                                          }>
                                                        }
                                                      | {
                                                          __typename: "VideoMediaInfo"
                                                          id: string
                                                          resourceId?: string | null
                                                          thumbnail?: string | null
                                                          width?: number | null
                                                          height?: number | null
                                                          mediaType?: Types.MediaType | null
                                                        }
                                                      | null
                                                  }
                                                | {
                                                    __typename: "MediaGroupContent"
                                                    media?:
                                                      | {
                                                          __typename: "AnimationMediaInfo"
                                                          id: string
                                                          resourceId?: string | null
                                                          thumbnail?: string | null
                                                          width?: number | null
                                                          height?: number | null
                                                          mediaType?: Types.MediaType | null
                                                        }
                                                      | {
                                                          __typename: "ImageMediaInfo"
                                                          id: string
                                                          resourceId?: string | null
                                                          thumbnail?: string | null
                                                          width?: number | null
                                                          height?: number | null
                                                          resolution?: number | null
                                                          colorDepth?: number | null
                                                          size?: number | null
                                                          mime?: string | null
                                                          extension?: string | null
                                                          allowCaching?: boolean | null
                                                          mediaType?: Types.MediaType | null
                                                          src?: string | null
                                                          language?: string | null
                                                        }
                                                      | {
                                                          __typename: "NoMediaInfo"
                                                          id: string
                                                          mediaType?: Types.MediaType | null
                                                        }
                                                      | {
                                                          __typename: "VectorGraphicsMediaInfo"
                                                          id: string
                                                          resourceId?: string | null
                                                          thumbnail?: string | null
                                                          width?: number | null
                                                          height?: number | null
                                                          allowCaching?: boolean | null
                                                          mediaType?: Types.MediaType | null
                                                          language?: string | null
                                                          labels: Array<{
                                                            __typename: "VectorGraphicsLabel"
                                                            id?: string | null
                                                            sequence?: number | null
                                                            autoValue?: string | null
                                                            text?: string | null
                                                            linkType?: Types.VectorGraphicsLabelLinkType | null
                                                            link?: string | null
                                                            ref?:
                                                              | {
                                                                  __typename: "GenericRef"
                                                                  id: string
                                                                  owner?:
                                                                    | { __typename: "CommentNode" }
                                                                    | { __typename: "ExternalSite" }
                                                                    | { __typename: "ST4Class" }
                                                                    | { __typename: "ST4Node"; id: string }
                                                                    | { __typename: "ST4TreeNode" }
                                                                    | null
                                                                  target?:
                                                                    | { __typename: "CommentNode" }
                                                                    | {
                                                                        __typename: "ExternalSite"
                                                                        id: string
                                                                        teaserData?: {
                                                                          __typename: "ExternalLinkTeaserData"
                                                                          title?: string | null
                                                                          summary?: string | null
                                                                          iconUrl?: string | null
                                                                          imageUrl?: string | null
                                                                          status?: Types.LinkTargetStatus | null
                                                                        } | null
                                                                      }
                                                                    | { __typename: "ST4Class" }
                                                                    | {
                                                                        __typename: "ST4Node"
                                                                        id: string
                                                                        aspectId: any
                                                                        label?: string | null
                                                                        properties?: {
                                                                          __typename: "ContentPropertyConnection"
                                                                          edges?: Array<{
                                                                            __typename: "ContentPropertyEdge"
                                                                            node?: {
                                                                              __typename: "ContentProperty"
                                                                              name: string
                                                                              value?: any | null
                                                                              displayName: string
                                                                            } | null
                                                                          } | null> | null
                                                                        } | null
                                                                      }
                                                                    | { __typename: "ST4TreeNode" }
                                                                    | null
                                                                }
                                                              | {
                                                                  __typename: "NodeRef"
                                                                  id: string
                                                                  owner?:
                                                                    | { __typename: "CommentNode" }
                                                                    | { __typename: "ExternalSite" }
                                                                    | { __typename: "ST4Class" }
                                                                    | { __typename: "ST4Node"; id: string }
                                                                    | { __typename: "ST4TreeNode" }
                                                                    | null
                                                                  target?:
                                                                    | { __typename: "CommentNode" }
                                                                    | {
                                                                        __typename: "ExternalSite"
                                                                        id: string
                                                                        teaserData?: {
                                                                          __typename: "ExternalLinkTeaserData"
                                                                          title?: string | null
                                                                          summary?: string | null
                                                                          iconUrl?: string | null
                                                                          imageUrl?: string | null
                                                                          status?: Types.LinkTargetStatus | null
                                                                        } | null
                                                                      }
                                                                    | { __typename: "ST4Class" }
                                                                    | {
                                                                        __typename: "ST4Node"
                                                                        id: string
                                                                        aspectId: any
                                                                        label?: string | null
                                                                        properties?: {
                                                                          __typename: "ContentPropertyConnection"
                                                                          edges?: Array<{
                                                                            __typename: "ContentPropertyEdge"
                                                                            node?: {
                                                                              __typename: "ContentProperty"
                                                                              name: string
                                                                              value?: any | null
                                                                              displayName: string
                                                                            } | null
                                                                          } | null> | null
                                                                        } | null
                                                                      }
                                                                    | { __typename: "ST4TreeNode" }
                                                                    | null
                                                                }
                                                              | null
                                                          }>
                                                        }
                                                      | {
                                                          __typename: "VideoMediaInfo"
                                                          id: string
                                                          resourceId?: string | null
                                                          thumbnail?: string | null
                                                          width?: number | null
                                                          height?: number | null
                                                          mediaType?: Types.MediaType | null
                                                        }
                                                      | null
                                                  }
                                                | { __typename: "ProjectContent" }
                                                | { __typename: "TermContent" }
                                                | { __typename: "TextContent" }
                                                | { __typename: "TextGroupContent" }
                                                | { __typename: "VariableTableContent" }
                                                | null
                                            }
                                          | { __typename: "ST4TreeNode"; id: string }
                                          | null
                                      }>
                                      media?:
                                        | {
                                            __typename: "AnimationMediaInfo"
                                            id: string
                                            resourceId?: string | null
                                            thumbnail?: string | null
                                            width?: number | null
                                            height?: number | null
                                            mediaType?: Types.MediaType | null
                                          }
                                        | {
                                            __typename: "ImageMediaInfo"
                                            id: string
                                            resourceId?: string | null
                                            thumbnail?: string | null
                                            width?: number | null
                                            height?: number | null
                                            resolution?: number | null
                                            colorDepth?: number | null
                                            size?: number | null
                                            mime?: string | null
                                            extension?: string | null
                                            allowCaching?: boolean | null
                                            mediaType?: Types.MediaType | null
                                            src?: string | null
                                            language?: string | null
                                          }
                                        | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                                        | {
                                            __typename: "VectorGraphicsMediaInfo"
                                            id: string
                                            resourceId?: string | null
                                            thumbnail?: string | null
                                            width?: number | null
                                            height?: number | null
                                            allowCaching?: boolean | null
                                            mediaType?: Types.MediaType | null
                                            language?: string | null
                                            labels: Array<{
                                              __typename: "VectorGraphicsLabel"
                                              id?: string | null
                                              sequence?: number | null
                                              autoValue?: string | null
                                              text?: string | null
                                              linkType?: Types.VectorGraphicsLabelLinkType | null
                                              link?: string | null
                                              ref?:
                                                | {
                                                    __typename: "GenericRef"
                                                    id: string
                                                    owner?:
                                                      | { __typename: "CommentNode" }
                                                      | { __typename: "ExternalSite" }
                                                      | { __typename: "ST4Class" }
                                                      | { __typename: "ST4Node"; id: string }
                                                      | { __typename: "ST4TreeNode" }
                                                      | null
                                                    target?:
                                                      | { __typename: "CommentNode" }
                                                      | {
                                                          __typename: "ExternalSite"
                                                          id: string
                                                          teaserData?: {
                                                            __typename: "ExternalLinkTeaserData"
                                                            title?: string | null
                                                            summary?: string | null
                                                            iconUrl?: string | null
                                                            imageUrl?: string | null
                                                            status?: Types.LinkTargetStatus | null
                                                          } | null
                                                        }
                                                      | { __typename: "ST4Class" }
                                                      | {
                                                          __typename: "ST4Node"
                                                          id: string
                                                          aspectId: any
                                                          label?: string | null
                                                          properties?: {
                                                            __typename: "ContentPropertyConnection"
                                                            edges?: Array<{
                                                              __typename: "ContentPropertyEdge"
                                                              node?: {
                                                                __typename: "ContentProperty"
                                                                name: string
                                                                value?: any | null
                                                                displayName: string
                                                              } | null
                                                            } | null> | null
                                                          } | null
                                                        }
                                                      | { __typename: "ST4TreeNode" }
                                                      | null
                                                  }
                                                | {
                                                    __typename: "NodeRef"
                                                    id: string
                                                    owner?:
                                                      | { __typename: "CommentNode" }
                                                      | { __typename: "ExternalSite" }
                                                      | { __typename: "ST4Class" }
                                                      | { __typename: "ST4Node"; id: string }
                                                      | { __typename: "ST4TreeNode" }
                                                      | null
                                                    target?:
                                                      | { __typename: "CommentNode" }
                                                      | {
                                                          __typename: "ExternalSite"
                                                          id: string
                                                          teaserData?: {
                                                            __typename: "ExternalLinkTeaserData"
                                                            title?: string | null
                                                            summary?: string | null
                                                            iconUrl?: string | null
                                                            imageUrl?: string | null
                                                            status?: Types.LinkTargetStatus | null
                                                          } | null
                                                        }
                                                      | { __typename: "ST4Class" }
                                                      | {
                                                          __typename: "ST4Node"
                                                          id: string
                                                          aspectId: any
                                                          label?: string | null
                                                          properties?: {
                                                            __typename: "ContentPropertyConnection"
                                                            edges?: Array<{
                                                              __typename: "ContentPropertyEdge"
                                                              node?: {
                                                                __typename: "ContentProperty"
                                                                name: string
                                                                value?: any | null
                                                                displayName: string
                                                              } | null
                                                            } | null> | null
                                                          } | null
                                                        }
                                                      | { __typename: "ST4TreeNode" }
                                                      | null
                                                  }
                                                | null
                                            }>
                                          }
                                        | {
                                            __typename: "VideoMediaInfo"
                                            id: string
                                            resourceId?: string | null
                                            thumbnail?: string | null
                                            width?: number | null
                                            height?: number | null
                                            mediaType?: Types.MediaType | null
                                          }
                                        | null
                                    }
                                  | { __typename: "ProjectContent" }
                                  | { __typename: "TermContent" }
                                  | { __typename: "TextContent" }
                                  | { __typename: "TextGroupContent" }
                                  | { __typename: "VariableTableContent" }
                                  | null
                              }
                            | { __typename: "ST4TreeNode"; id: string }
                            | null
                        }>
                        variableTables?: Array<{
                          __typename: "VariableTableReference"
                          ref?: string | null
                          node?:
                            | { __typename: "CommentNode" }
                            | { __typename: "ExternalSite" }
                            | { __typename: "ST4Class" }
                            | {
                                __typename: "ST4Node"
                                id: string
                                label?: string | null
                                aspectId: any
                                content?:
                                  | { __typename: "LexiconContent" }
                                  | { __typename: "LexiconEntryContent" }
                                  | { __typename: "MediaContent" }
                                  | { __typename: "MediaGroupContent" }
                                  | { __typename: "ProjectContent" }
                                  | { __typename: "TermContent" }
                                  | { __typename: "TextContent" }
                                  | { __typename: "TextGroupContent" }
                                  | {
                                      __typename: "VariableTableContent"
                                      xml?: string | null
                                      valueSets: Array<{ __typename: "ValueSet"; name: string; valueSetId: string }>
                                      variables: Array<{
                                        __typename: "Variable"
                                        name: string
                                        variableId: string
                                        values: Array<string>
                                      }>
                                    }
                                  | null
                              }
                            | { __typename: "ST4TreeNode" }
                            | null
                        } | null> | null
                        linkedDataNodes: Array<{
                          __typename: "NodeRef"
                          id: string
                          target?:
                            | { __typename: "CommentNode"; id: string }
                            | { __typename: "ExternalSite"; id: string }
                            | { __typename: "ST4Class"; id: string }
                            | {
                                __typename: "ST4Node"
                                label?: string | null
                                id: string
                                metadata: Array<{
                                  __typename: "MetaDataEntry"
                                  label?: string | null
                                  systemName: string
                                  value?: string | null
                                  values: Array<string>
                                  users: Array<string>
                                }>
                                linkedOntologyNodes: Array<
                                  | {
                                      __typename: "GenericRef"
                                      target?:
                                        | { __typename: "CommentNode" }
                                        | { __typename: "ExternalSite" }
                                        | { __typename: "ST4Class" }
                                        | { __typename: "ST4Node"; id: string; label?: string | null }
                                        | { __typename: "ST4TreeNode" }
                                        | null
                                    }
                                  | {
                                      __typename: "NodeRef"
                                      linkClassName: string
                                      class?: { __typename: "ST4Class"; label?: string | null } | null
                                      target?:
                                        | { __typename: "CommentNode" }
                                        | { __typename: "ExternalSite" }
                                        | { __typename: "ST4Class" }
                                        | { __typename: "ST4Node"; id: string; label?: string | null }
                                        | { __typename: "ST4TreeNode" }
                                        | null
                                    }
                                >
                              }
                            | { __typename: "ST4TreeNode"; id: string }
                            | null
                        }>
                        lexiconEntries?: Array<{
                          __typename: "NodeRef"
                          id: string
                          target?:
                            | { __typename: "CommentNode"; id: string }
                            | { __typename: "ExternalSite"; id: string }
                            | { __typename: "ST4Class"; id: string }
                            | {
                                __typename: "ST4Node"
                                aspectId: any
                                label?: string | null
                                id: string
                                content?:
                                  | { __typename: "LexiconContent" }
                                  | {
                                      __typename: "LexiconEntryContent"
                                      descriptionXast?: string | null
                                      subject?: string | null
                                      sortKey?: string | null
                                      lexicon:
                                        | { __typename: "CommentNode"; id: string }
                                        | { __typename: "ExternalSite"; id: string }
                                        | { __typename: "ST4Class"; id: string }
                                        | { __typename: "ST4Node"; label?: string | null; id: string }
                                        | { __typename: "ST4TreeNode"; id: string }
                                      terms: Array<
                                        | { __typename: "CommentNode"; id: string }
                                        | { __typename: "ExternalSite"; id: string }
                                        | { __typename: "ST4Class"; id: string }
                                        | {
                                            __typename: "ST4Node"
                                            id: string
                                            label?: string | null
                                            aspectId: any
                                            content?:
                                              | { __typename: "LexiconContent" }
                                              | { __typename: "LexiconEntryContent" }
                                              | { __typename: "MediaContent" }
                                              | { __typename: "MediaGroupContent" }
                                              | { __typename: "ProjectContent" }
                                              | {
                                                  __typename: "TermContent"
                                                  gramaticalGender?: string | null
                                                  validity?: string | null
                                                  editLanguage?: string | null
                                                }
                                              | { __typename: "TextContent" }
                                              | { __typename: "TextGroupContent" }
                                              | { __typename: "VariableTableContent" }
                                              | null
                                          }
                                        | { __typename: "ST4TreeNode"; id: string }
                                      >
                                      image?:
                                        | { __typename: "CommentNode"; id: string }
                                        | { __typename: "ExternalSite"; id: string }
                                        | { __typename: "ST4Class"; id: string }
                                        | {
                                            __typename: "ST4Node"
                                            aspectId: any
                                            id: string
                                            content?:
                                              | { __typename: "LexiconContent" }
                                              | { __typename: "LexiconEntryContent" }
                                              | {
                                                  __typename: "MediaContent"
                                                  media?:
                                                    | {
                                                        __typename: "AnimationMediaInfo"
                                                        thumbnail?: string | null
                                                        mediaType?: Types.MediaType | null
                                                      }
                                                    | {
                                                        __typename: "ImageMediaInfo"
                                                        src?: string | null
                                                        thumbnail?: string | null
                                                        mediaType?: Types.MediaType | null
                                                      }
                                                    | { __typename: "NoMediaInfo" }
                                                    | {
                                                        __typename: "VectorGraphicsMediaInfo"
                                                        svg?: string | null
                                                        thumbnail?: string | null
                                                        mediaType?: Types.MediaType | null
                                                      }
                                                    | {
                                                        __typename: "VideoMediaInfo"
                                                        thumbnail?: string | null
                                                        mediaType?: Types.MediaType | null
                                                      }
                                                    | null
                                                }
                                              | { __typename: "MediaGroupContent" }
                                              | { __typename: "ProjectContent" }
                                              | { __typename: "TermContent" }
                                              | { __typename: "TextContent" }
                                              | { __typename: "TextGroupContent" }
                                              | { __typename: "VariableTableContent" }
                                              | null
                                          }
                                        | { __typename: "ST4TreeNode"; id: string }
                                        | null
                                    }
                                  | { __typename: "MediaContent" }
                                  | { __typename: "MediaGroupContent" }
                                  | { __typename: "ProjectContent" }
                                  | { __typename: "TermContent" }
                                  | { __typename: "TextContent" }
                                  | { __typename: "TextGroupContent" }
                                  | { __typename: "VariableTableContent" }
                                  | null
                              }
                            | { __typename: "ST4TreeNode"; id: string }
                            | null
                        } | null> | null
                        comments: Array<{
                          __typename: "CommentNode"
                          id: string
                          comment:
                            | {
                                __typename: "BlockComment"
                                location: number
                                value?: string | null
                                authorDisplayName?: string | null
                                authorUserName?: string | null
                                commentKey: string
                                editDate?: any | null
                                modificationVersion?: number | null
                                replyToKey?: string | null
                                sortOrder?: number | null
                                commentType?: string | null
                                state?: string | null
                                color: string
                                persistanceState?: Types.PersistanceState | null
                                persistanceAction?: Types.PersistanceAction | null
                                node: {
                                  __typename: "ST4Node"
                                  aspectId: any
                                  id: string
                                  content?:
                                    | { __typename: "LexiconContent" }
                                    | { __typename: "LexiconEntryContent" }
                                    | { __typename: "MediaContent" }
                                    | { __typename: "MediaGroupContent" }
                                    | { __typename: "ProjectContent" }
                                    | { __typename: "TermContent" }
                                    | { __typename: "TextContent"; id: string; historyNumber: number }
                                    | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                                    | { __typename: "VariableTableContent" }
                                    | null
                                }
                                actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
                              }
                            | {
                                __typename: "TextComment"
                                endLocation: number
                                startLocation?: number | null
                                value?: string | null
                                authorDisplayName?: string | null
                                authorUserName?: string | null
                                commentKey: string
                                editDate?: any | null
                                modificationVersion?: number | null
                                replyToKey?: string | null
                                sortOrder?: number | null
                                commentType?: string | null
                                state?: string | null
                                color: string
                                persistanceState?: Types.PersistanceState | null
                                persistanceAction?: Types.PersistanceAction | null
                                node: {
                                  __typename: "ST4Node"
                                  aspectId: any
                                  id: string
                                  content?:
                                    | { __typename: "LexiconContent" }
                                    | { __typename: "LexiconEntryContent" }
                                    | { __typename: "MediaContent" }
                                    | { __typename: "MediaGroupContent" }
                                    | { __typename: "ProjectContent" }
                                    | { __typename: "TermContent" }
                                    | { __typename: "TextContent"; id: string; historyNumber: number }
                                    | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                                    | { __typename: "VariableTableContent" }
                                    | null
                                }
                                actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
                              }
                        }>
                      }
                    | {
                        __typename: "TextGroupContent"
                        currentNodeId?: any | null
                        groupNodeId: any
                        id: string
                        xast?: string | null
                        historyNumber: number
                        children: Array<{
                          __typename: "NodeRef"
                          id: string
                          target?:
                            | { __typename: "CommentNode"; id: string }
                            | { __typename: "ExternalSite"; id: string }
                            | { __typename: "ST4Class"; id: string }
                            | { __typename: "ST4Node"; label?: string | null; aspectId: any; id: string }
                            | { __typename: "ST4TreeNode"; id: string }
                            | null
                        }>
                        links?: Array<{
                          __typename: "TextContentLink"
                          ref?: string | null
                          node?:
                            | { __typename: "CommentNode"; id: string }
                            | { __typename: "ExternalSite"; id: string }
                            | { __typename: "ST4Class"; id: string }
                            | {
                                __typename: "ST4Node"
                                id: string
                                aspectId: any
                                label?: string | null
                                properties?: {
                                  __typename: "ContentPropertyConnection"
                                  totalCount: number
                                  edges?: Array<{
                                    __typename: "ContentPropertyEdge"
                                    node?: {
                                      __typename: "ContentProperty"
                                      typeOfValue: Types.ContentPropertyValueType
                                      name: string
                                      value?: any | null
                                      displayName: string
                                    } | null
                                  } | null> | null
                                } | null
                              }
                            | { __typename: "ST4TreeNode"; id: string }
                            | null
                        } | null> | null
                        images: Array<{
                          __typename: "NodeRef"
                          id: string
                          target?:
                            | { __typename: "CommentNode"; id: string }
                            | { __typename: "ExternalSite"; id: string }
                            | { __typename: "ST4Class"; id: string }
                            | {
                                __typename: "ST4Node"
                                aspectId: any
                                id: string
                                releaseState: {
                                  __typename: "ReleaseStateValue"
                                  displayName: string
                                  state: Types.ReleaseState
                                  isImplicitlyReleased: boolean
                                }
                                content?:
                                  | { __typename: "LexiconContent" }
                                  | { __typename: "LexiconEntryContent" }
                                  | {
                                      __typename: "MediaContent"
                                      media?:
                                        | {
                                            __typename: "AnimationMediaInfo"
                                            id: string
                                            resourceId?: string | null
                                            thumbnail?: string | null
                                            width?: number | null
                                            height?: number | null
                                            mediaType?: Types.MediaType | null
                                          }
                                        | {
                                            __typename: "ImageMediaInfo"
                                            id: string
                                            resourceId?: string | null
                                            thumbnail?: string | null
                                            width?: number | null
                                            height?: number | null
                                            resolution?: number | null
                                            colorDepth?: number | null
                                            size?: number | null
                                            mime?: string | null
                                            extension?: string | null
                                            allowCaching?: boolean | null
                                            mediaType?: Types.MediaType | null
                                            src?: string | null
                                            language?: string | null
                                          }
                                        | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                                        | {
                                            __typename: "VectorGraphicsMediaInfo"
                                            id: string
                                            resourceId?: string | null
                                            thumbnail?: string | null
                                            width?: number | null
                                            height?: number | null
                                            allowCaching?: boolean | null
                                            mediaType?: Types.MediaType | null
                                            language?: string | null
                                            labels: Array<{
                                              __typename: "VectorGraphicsLabel"
                                              id?: string | null
                                              sequence?: number | null
                                              autoValue?: string | null
                                              text?: string | null
                                              linkType?: Types.VectorGraphicsLabelLinkType | null
                                              link?: string | null
                                              ref?:
                                                | {
                                                    __typename: "GenericRef"
                                                    id: string
                                                    owner?:
                                                      | { __typename: "CommentNode" }
                                                      | { __typename: "ExternalSite" }
                                                      | { __typename: "ST4Class" }
                                                      | { __typename: "ST4Node"; id: string }
                                                      | { __typename: "ST4TreeNode" }
                                                      | null
                                                    target?:
                                                      | { __typename: "CommentNode" }
                                                      | {
                                                          __typename: "ExternalSite"
                                                          id: string
                                                          teaserData?: {
                                                            __typename: "ExternalLinkTeaserData"
                                                            title?: string | null
                                                            summary?: string | null
                                                            iconUrl?: string | null
                                                            imageUrl?: string | null
                                                            status?: Types.LinkTargetStatus | null
                                                          } | null
                                                        }
                                                      | { __typename: "ST4Class" }
                                                      | {
                                                          __typename: "ST4Node"
                                                          id: string
                                                          aspectId: any
                                                          label?: string | null
                                                          properties?: {
                                                            __typename: "ContentPropertyConnection"
                                                            edges?: Array<{
                                                              __typename: "ContentPropertyEdge"
                                                              node?: {
                                                                __typename: "ContentProperty"
                                                                name: string
                                                                value?: any | null
                                                                displayName: string
                                                              } | null
                                                            } | null> | null
                                                          } | null
                                                        }
                                                      | { __typename: "ST4TreeNode" }
                                                      | null
                                                  }
                                                | {
                                                    __typename: "NodeRef"
                                                    id: string
                                                    owner?:
                                                      | { __typename: "CommentNode" }
                                                      | { __typename: "ExternalSite" }
                                                      | { __typename: "ST4Class" }
                                                      | { __typename: "ST4Node"; id: string }
                                                      | { __typename: "ST4TreeNode" }
                                                      | null
                                                    target?:
                                                      | { __typename: "CommentNode" }
                                                      | {
                                                          __typename: "ExternalSite"
                                                          id: string
                                                          teaserData?: {
                                                            __typename: "ExternalLinkTeaserData"
                                                            title?: string | null
                                                            summary?: string | null
                                                            iconUrl?: string | null
                                                            imageUrl?: string | null
                                                            status?: Types.LinkTargetStatus | null
                                                          } | null
                                                        }
                                                      | { __typename: "ST4Class" }
                                                      | {
                                                          __typename: "ST4Node"
                                                          id: string
                                                          aspectId: any
                                                          label?: string | null
                                                          properties?: {
                                                            __typename: "ContentPropertyConnection"
                                                            edges?: Array<{
                                                              __typename: "ContentPropertyEdge"
                                                              node?: {
                                                                __typename: "ContentProperty"
                                                                name: string
                                                                value?: any | null
                                                                displayName: string
                                                              } | null
                                                            } | null> | null
                                                          } | null
                                                        }
                                                      | { __typename: "ST4TreeNode" }
                                                      | null
                                                  }
                                                | null
                                            }>
                                          }
                                        | {
                                            __typename: "VideoMediaInfo"
                                            id: string
                                            resourceId?: string | null
                                            thumbnail?: string | null
                                            width?: number | null
                                            height?: number | null
                                            mediaType?: Types.MediaType | null
                                          }
                                        | null
                                    }
                                  | {
                                      __typename: "MediaGroupContent"
                                      children: Array<{
                                        __typename: "NodeRef"
                                        id: string
                                        target?:
                                          | { __typename: "CommentNode"; id: string }
                                          | { __typename: "ExternalSite"; id: string }
                                          | { __typename: "ST4Class"; id: string }
                                          | {
                                              __typename: "ST4Node"
                                              id: string
                                              aspectId: any
                                              content?:
                                                | { __typename: "LexiconContent" }
                                                | { __typename: "LexiconEntryContent" }
                                                | {
                                                    __typename: "MediaContent"
                                                    media?:
                                                      | {
                                                          __typename: "AnimationMediaInfo"
                                                          id: string
                                                          resourceId?: string | null
                                                          thumbnail?: string | null
                                                          width?: number | null
                                                          height?: number | null
                                                          mediaType?: Types.MediaType | null
                                                        }
                                                      | {
                                                          __typename: "ImageMediaInfo"
                                                          id: string
                                                          resourceId?: string | null
                                                          thumbnail?: string | null
                                                          width?: number | null
                                                          height?: number | null
                                                          resolution?: number | null
                                                          colorDepth?: number | null
                                                          size?: number | null
                                                          mime?: string | null
                                                          extension?: string | null
                                                          allowCaching?: boolean | null
                                                          mediaType?: Types.MediaType | null
                                                          src?: string | null
                                                          language?: string | null
                                                        }
                                                      | {
                                                          __typename: "NoMediaInfo"
                                                          id: string
                                                          mediaType?: Types.MediaType | null
                                                        }
                                                      | {
                                                          __typename: "VectorGraphicsMediaInfo"
                                                          id: string
                                                          resourceId?: string | null
                                                          thumbnail?: string | null
                                                          width?: number | null
                                                          height?: number | null
                                                          allowCaching?: boolean | null
                                                          mediaType?: Types.MediaType | null
                                                          language?: string | null
                                                          labels: Array<{
                                                            __typename: "VectorGraphicsLabel"
                                                            id?: string | null
                                                            sequence?: number | null
                                                            autoValue?: string | null
                                                            text?: string | null
                                                            linkType?: Types.VectorGraphicsLabelLinkType | null
                                                            link?: string | null
                                                            ref?:
                                                              | {
                                                                  __typename: "GenericRef"
                                                                  id: string
                                                                  owner?:
                                                                    | { __typename: "CommentNode" }
                                                                    | { __typename: "ExternalSite" }
                                                                    | { __typename: "ST4Class" }
                                                                    | { __typename: "ST4Node"; id: string }
                                                                    | { __typename: "ST4TreeNode" }
                                                                    | null
                                                                  target?:
                                                                    | { __typename: "CommentNode" }
                                                                    | {
                                                                        __typename: "ExternalSite"
                                                                        id: string
                                                                        teaserData?: {
                                                                          __typename: "ExternalLinkTeaserData"
                                                                          title?: string | null
                                                                          summary?: string | null
                                                                          iconUrl?: string | null
                                                                          imageUrl?: string | null
                                                                          status?: Types.LinkTargetStatus | null
                                                                        } | null
                                                                      }
                                                                    | { __typename: "ST4Class" }
                                                                    | {
                                                                        __typename: "ST4Node"
                                                                        id: string
                                                                        aspectId: any
                                                                        label?: string | null
                                                                        properties?: {
                                                                          __typename: "ContentPropertyConnection"
                                                                          edges?: Array<{
                                                                            __typename: "ContentPropertyEdge"
                                                                            node?: {
                                                                              __typename: "ContentProperty"
                                                                              name: string
                                                                              value?: any | null
                                                                              displayName: string
                                                                            } | null
                                                                          } | null> | null
                                                                        } | null
                                                                      }
                                                                    | { __typename: "ST4TreeNode" }
                                                                    | null
                                                                }
                                                              | {
                                                                  __typename: "NodeRef"
                                                                  id: string
                                                                  owner?:
                                                                    | { __typename: "CommentNode" }
                                                                    | { __typename: "ExternalSite" }
                                                                    | { __typename: "ST4Class" }
                                                                    | { __typename: "ST4Node"; id: string }
                                                                    | { __typename: "ST4TreeNode" }
                                                                    | null
                                                                  target?:
                                                                    | { __typename: "CommentNode" }
                                                                    | {
                                                                        __typename: "ExternalSite"
                                                                        id: string
                                                                        teaserData?: {
                                                                          __typename: "ExternalLinkTeaserData"
                                                                          title?: string | null
                                                                          summary?: string | null
                                                                          iconUrl?: string | null
                                                                          imageUrl?: string | null
                                                                          status?: Types.LinkTargetStatus | null
                                                                        } | null
                                                                      }
                                                                    | { __typename: "ST4Class" }
                                                                    | {
                                                                        __typename: "ST4Node"
                                                                        id: string
                                                                        aspectId: any
                                                                        label?: string | null
                                                                        properties?: {
                                                                          __typename: "ContentPropertyConnection"
                                                                          edges?: Array<{
                                                                            __typename: "ContentPropertyEdge"
                                                                            node?: {
                                                                              __typename: "ContentProperty"
                                                                              name: string
                                                                              value?: any | null
                                                                              displayName: string
                                                                            } | null
                                                                          } | null> | null
                                                                        } | null
                                                                      }
                                                                    | { __typename: "ST4TreeNode" }
                                                                    | null
                                                                }
                                                              | null
                                                          }>
                                                        }
                                                      | {
                                                          __typename: "VideoMediaInfo"
                                                          id: string
                                                          resourceId?: string | null
                                                          thumbnail?: string | null
                                                          width?: number | null
                                                          height?: number | null
                                                          mediaType?: Types.MediaType | null
                                                        }
                                                      | null
                                                  }
                                                | {
                                                    __typename: "MediaGroupContent"
                                                    media?:
                                                      | {
                                                          __typename: "AnimationMediaInfo"
                                                          id: string
                                                          resourceId?: string | null
                                                          thumbnail?: string | null
                                                          width?: number | null
                                                          height?: number | null
                                                          mediaType?: Types.MediaType | null
                                                        }
                                                      | {
                                                          __typename: "ImageMediaInfo"
                                                          id: string
                                                          resourceId?: string | null
                                                          thumbnail?: string | null
                                                          width?: number | null
                                                          height?: number | null
                                                          resolution?: number | null
                                                          colorDepth?: number | null
                                                          size?: number | null
                                                          mime?: string | null
                                                          extension?: string | null
                                                          allowCaching?: boolean | null
                                                          mediaType?: Types.MediaType | null
                                                          src?: string | null
                                                          language?: string | null
                                                        }
                                                      | {
                                                          __typename: "NoMediaInfo"
                                                          id: string
                                                          mediaType?: Types.MediaType | null
                                                        }
                                                      | {
                                                          __typename: "VectorGraphicsMediaInfo"
                                                          id: string
                                                          resourceId?: string | null
                                                          thumbnail?: string | null
                                                          width?: number | null
                                                          height?: number | null
                                                          allowCaching?: boolean | null
                                                          mediaType?: Types.MediaType | null
                                                          language?: string | null
                                                          labels: Array<{
                                                            __typename: "VectorGraphicsLabel"
                                                            id?: string | null
                                                            sequence?: number | null
                                                            autoValue?: string | null
                                                            text?: string | null
                                                            linkType?: Types.VectorGraphicsLabelLinkType | null
                                                            link?: string | null
                                                            ref?:
                                                              | {
                                                                  __typename: "GenericRef"
                                                                  id: string
                                                                  owner?:
                                                                    | { __typename: "CommentNode" }
                                                                    | { __typename: "ExternalSite" }
                                                                    | { __typename: "ST4Class" }
                                                                    | { __typename: "ST4Node"; id: string }
                                                                    | { __typename: "ST4TreeNode" }
                                                                    | null
                                                                  target?:
                                                                    | { __typename: "CommentNode" }
                                                                    | {
                                                                        __typename: "ExternalSite"
                                                                        id: string
                                                                        teaserData?: {
                                                                          __typename: "ExternalLinkTeaserData"
                                                                          title?: string | null
                                                                          summary?: string | null
                                                                          iconUrl?: string | null
                                                                          imageUrl?: string | null
                                                                          status?: Types.LinkTargetStatus | null
                                                                        } | null
                                                                      }
                                                                    | { __typename: "ST4Class" }
                                                                    | {
                                                                        __typename: "ST4Node"
                                                                        id: string
                                                                        aspectId: any
                                                                        label?: string | null
                                                                        properties?: {
                                                                          __typename: "ContentPropertyConnection"
                                                                          edges?: Array<{
                                                                            __typename: "ContentPropertyEdge"
                                                                            node?: {
                                                                              __typename: "ContentProperty"
                                                                              name: string
                                                                              value?: any | null
                                                                              displayName: string
                                                                            } | null
                                                                          } | null> | null
                                                                        } | null
                                                                      }
                                                                    | { __typename: "ST4TreeNode" }
                                                                    | null
                                                                }
                                                              | {
                                                                  __typename: "NodeRef"
                                                                  id: string
                                                                  owner?:
                                                                    | { __typename: "CommentNode" }
                                                                    | { __typename: "ExternalSite" }
                                                                    | { __typename: "ST4Class" }
                                                                    | { __typename: "ST4Node"; id: string }
                                                                    | { __typename: "ST4TreeNode" }
                                                                    | null
                                                                  target?:
                                                                    | { __typename: "CommentNode" }
                                                                    | {
                                                                        __typename: "ExternalSite"
                                                                        id: string
                                                                        teaserData?: {
                                                                          __typename: "ExternalLinkTeaserData"
                                                                          title?: string | null
                                                                          summary?: string | null
                                                                          iconUrl?: string | null
                                                                          imageUrl?: string | null
                                                                          status?: Types.LinkTargetStatus | null
                                                                        } | null
                                                                      }
                                                                    | { __typename: "ST4Class" }
                                                                    | {
                                                                        __typename: "ST4Node"
                                                                        id: string
                                                                        aspectId: any
                                                                        label?: string | null
                                                                        properties?: {
                                                                          __typename: "ContentPropertyConnection"
                                                                          edges?: Array<{
                                                                            __typename: "ContentPropertyEdge"
                                                                            node?: {
                                                                              __typename: "ContentProperty"
                                                                              name: string
                                                                              value?: any | null
                                                                              displayName: string
                                                                            } | null
                                                                          } | null> | null
                                                                        } | null
                                                                      }
                                                                    | { __typename: "ST4TreeNode" }
                                                                    | null
                                                                }
                                                              | null
                                                          }>
                                                        }
                                                      | {
                                                          __typename: "VideoMediaInfo"
                                                          id: string
                                                          resourceId?: string | null
                                                          thumbnail?: string | null
                                                          width?: number | null
                                                          height?: number | null
                                                          mediaType?: Types.MediaType | null
                                                        }
                                                      | null
                                                  }
                                                | { __typename: "ProjectContent" }
                                                | { __typename: "TermContent" }
                                                | { __typename: "TextContent" }
                                                | { __typename: "TextGroupContent" }
                                                | { __typename: "VariableTableContent" }
                                                | null
                                            }
                                          | { __typename: "ST4TreeNode"; id: string }
                                          | null
                                      }>
                                      media?:
                                        | {
                                            __typename: "AnimationMediaInfo"
                                            id: string
                                            resourceId?: string | null
                                            thumbnail?: string | null
                                            width?: number | null
                                            height?: number | null
                                            mediaType?: Types.MediaType | null
                                          }
                                        | {
                                            __typename: "ImageMediaInfo"
                                            id: string
                                            resourceId?: string | null
                                            thumbnail?: string | null
                                            width?: number | null
                                            height?: number | null
                                            resolution?: number | null
                                            colorDepth?: number | null
                                            size?: number | null
                                            mime?: string | null
                                            extension?: string | null
                                            allowCaching?: boolean | null
                                            mediaType?: Types.MediaType | null
                                            src?: string | null
                                            language?: string | null
                                          }
                                        | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                                        | {
                                            __typename: "VectorGraphicsMediaInfo"
                                            id: string
                                            resourceId?: string | null
                                            thumbnail?: string | null
                                            width?: number | null
                                            height?: number | null
                                            allowCaching?: boolean | null
                                            mediaType?: Types.MediaType | null
                                            language?: string | null
                                            labels: Array<{
                                              __typename: "VectorGraphicsLabel"
                                              id?: string | null
                                              sequence?: number | null
                                              autoValue?: string | null
                                              text?: string | null
                                              linkType?: Types.VectorGraphicsLabelLinkType | null
                                              link?: string | null
                                              ref?:
                                                | {
                                                    __typename: "GenericRef"
                                                    id: string
                                                    owner?:
                                                      | { __typename: "CommentNode" }
                                                      | { __typename: "ExternalSite" }
                                                      | { __typename: "ST4Class" }
                                                      | { __typename: "ST4Node"; id: string }
                                                      | { __typename: "ST4TreeNode" }
                                                      | null
                                                    target?:
                                                      | { __typename: "CommentNode" }
                                                      | {
                                                          __typename: "ExternalSite"
                                                          id: string
                                                          teaserData?: {
                                                            __typename: "ExternalLinkTeaserData"
                                                            title?: string | null
                                                            summary?: string | null
                                                            iconUrl?: string | null
                                                            imageUrl?: string | null
                                                            status?: Types.LinkTargetStatus | null
                                                          } | null
                                                        }
                                                      | { __typename: "ST4Class" }
                                                      | {
                                                          __typename: "ST4Node"
                                                          id: string
                                                          aspectId: any
                                                          label?: string | null
                                                          properties?: {
                                                            __typename: "ContentPropertyConnection"
                                                            edges?: Array<{
                                                              __typename: "ContentPropertyEdge"
                                                              node?: {
                                                                __typename: "ContentProperty"
                                                                name: string
                                                                value?: any | null
                                                                displayName: string
                                                              } | null
                                                            } | null> | null
                                                          } | null
                                                        }
                                                      | { __typename: "ST4TreeNode" }
                                                      | null
                                                  }
                                                | {
                                                    __typename: "NodeRef"
                                                    id: string
                                                    owner?:
                                                      | { __typename: "CommentNode" }
                                                      | { __typename: "ExternalSite" }
                                                      | { __typename: "ST4Class" }
                                                      | { __typename: "ST4Node"; id: string }
                                                      | { __typename: "ST4TreeNode" }
                                                      | null
                                                    target?:
                                                      | { __typename: "CommentNode" }
                                                      | {
                                                          __typename: "ExternalSite"
                                                          id: string
                                                          teaserData?: {
                                                            __typename: "ExternalLinkTeaserData"
                                                            title?: string | null
                                                            summary?: string | null
                                                            iconUrl?: string | null
                                                            imageUrl?: string | null
                                                            status?: Types.LinkTargetStatus | null
                                                          } | null
                                                        }
                                                      | { __typename: "ST4Class" }
                                                      | {
                                                          __typename: "ST4Node"
                                                          id: string
                                                          aspectId: any
                                                          label?: string | null
                                                          properties?: {
                                                            __typename: "ContentPropertyConnection"
                                                            edges?: Array<{
                                                              __typename: "ContentPropertyEdge"
                                                              node?: {
                                                                __typename: "ContentProperty"
                                                                name: string
                                                                value?: any | null
                                                                displayName: string
                                                              } | null
                                                            } | null> | null
                                                          } | null
                                                        }
                                                      | { __typename: "ST4TreeNode" }
                                                      | null
                                                  }
                                                | null
                                            }>
                                          }
                                        | {
                                            __typename: "VideoMediaInfo"
                                            id: string
                                            resourceId?: string | null
                                            thumbnail?: string | null
                                            width?: number | null
                                            height?: number | null
                                            mediaType?: Types.MediaType | null
                                          }
                                        | null
                                    }
                                  | { __typename: "ProjectContent" }
                                  | { __typename: "TermContent" }
                                  | { __typename: "TextContent" }
                                  | { __typename: "TextGroupContent" }
                                  | { __typename: "VariableTableContent" }
                                  | null
                              }
                            | { __typename: "ST4TreeNode"; id: string }
                            | null
                        }>
                        variableTables?: Array<{
                          __typename: "VariableTableReference"
                          ref?: string | null
                          node?:
                            | { __typename: "CommentNode" }
                            | { __typename: "ExternalSite" }
                            | { __typename: "ST4Class" }
                            | {
                                __typename: "ST4Node"
                                id: string
                                label?: string | null
                                aspectId: any
                                content?:
                                  | { __typename: "LexiconContent" }
                                  | { __typename: "LexiconEntryContent" }
                                  | { __typename: "MediaContent" }
                                  | { __typename: "MediaGroupContent" }
                                  | { __typename: "ProjectContent" }
                                  | { __typename: "TermContent" }
                                  | { __typename: "TextContent" }
                                  | { __typename: "TextGroupContent" }
                                  | {
                                      __typename: "VariableTableContent"
                                      xml?: string | null
                                      valueSets: Array<{ __typename: "ValueSet"; name: string; valueSetId: string }>
                                      variables: Array<{
                                        __typename: "Variable"
                                        name: string
                                        variableId: string
                                        values: Array<string>
                                      }>
                                    }
                                  | null
                              }
                            | { __typename: "ST4TreeNode" }
                            | null
                        } | null> | null
                        linkedDataNodes: Array<{
                          __typename: "NodeRef"
                          id: string
                          target?:
                            | { __typename: "CommentNode"; id: string }
                            | { __typename: "ExternalSite"; id: string }
                            | { __typename: "ST4Class"; id: string }
                            | {
                                __typename: "ST4Node"
                                label?: string | null
                                id: string
                                metadata: Array<{
                                  __typename: "MetaDataEntry"
                                  label?: string | null
                                  systemName: string
                                  value?: string | null
                                  values: Array<string>
                                  users: Array<string>
                                }>
                                linkedOntologyNodes: Array<
                                  | {
                                      __typename: "GenericRef"
                                      target?:
                                        | { __typename: "CommentNode" }
                                        | { __typename: "ExternalSite" }
                                        | { __typename: "ST4Class" }
                                        | { __typename: "ST4Node"; id: string; label?: string | null }
                                        | { __typename: "ST4TreeNode" }
                                        | null
                                    }
                                  | {
                                      __typename: "NodeRef"
                                      linkClassName: string
                                      class?: { __typename: "ST4Class"; label?: string | null } | null
                                      target?:
                                        | { __typename: "CommentNode" }
                                        | { __typename: "ExternalSite" }
                                        | { __typename: "ST4Class" }
                                        | { __typename: "ST4Node"; id: string; label?: string | null }
                                        | { __typename: "ST4TreeNode" }
                                        | null
                                    }
                                >
                              }
                            | { __typename: "ST4TreeNode"; id: string }
                            | null
                        }>
                        lexiconEntries?: Array<{
                          __typename: "NodeRef"
                          id: string
                          target?:
                            | { __typename: "CommentNode"; id: string }
                            | { __typename: "ExternalSite"; id: string }
                            | { __typename: "ST4Class"; id: string }
                            | {
                                __typename: "ST4Node"
                                aspectId: any
                                label?: string | null
                                id: string
                                content?:
                                  | { __typename: "LexiconContent" }
                                  | {
                                      __typename: "LexiconEntryContent"
                                      descriptionXast?: string | null
                                      subject?: string | null
                                      sortKey?: string | null
                                      lexicon:
                                        | { __typename: "CommentNode"; id: string }
                                        | { __typename: "ExternalSite"; id: string }
                                        | { __typename: "ST4Class"; id: string }
                                        | { __typename: "ST4Node"; label?: string | null; id: string }
                                        | { __typename: "ST4TreeNode"; id: string }
                                      terms: Array<
                                        | { __typename: "CommentNode"; id: string }
                                        | { __typename: "ExternalSite"; id: string }
                                        | { __typename: "ST4Class"; id: string }
                                        | {
                                            __typename: "ST4Node"
                                            id: string
                                            label?: string | null
                                            aspectId: any
                                            content?:
                                              | { __typename: "LexiconContent" }
                                              | { __typename: "LexiconEntryContent" }
                                              | { __typename: "MediaContent" }
                                              | { __typename: "MediaGroupContent" }
                                              | { __typename: "ProjectContent" }
                                              | {
                                                  __typename: "TermContent"
                                                  gramaticalGender?: string | null
                                                  validity?: string | null
                                                  editLanguage?: string | null
                                                }
                                              | { __typename: "TextContent" }
                                              | { __typename: "TextGroupContent" }
                                              | { __typename: "VariableTableContent" }
                                              | null
                                          }
                                        | { __typename: "ST4TreeNode"; id: string }
                                      >
                                      image?:
                                        | { __typename: "CommentNode"; id: string }
                                        | { __typename: "ExternalSite"; id: string }
                                        | { __typename: "ST4Class"; id: string }
                                        | {
                                            __typename: "ST4Node"
                                            aspectId: any
                                            id: string
                                            content?:
                                              | { __typename: "LexiconContent" }
                                              | { __typename: "LexiconEntryContent" }
                                              | {
                                                  __typename: "MediaContent"
                                                  media?:
                                                    | {
                                                        __typename: "AnimationMediaInfo"
                                                        thumbnail?: string | null
                                                        mediaType?: Types.MediaType | null
                                                      }
                                                    | {
                                                        __typename: "ImageMediaInfo"
                                                        src?: string | null
                                                        thumbnail?: string | null
                                                        mediaType?: Types.MediaType | null
                                                      }
                                                    | { __typename: "NoMediaInfo" }
                                                    | {
                                                        __typename: "VectorGraphicsMediaInfo"
                                                        svg?: string | null
                                                        thumbnail?: string | null
                                                        mediaType?: Types.MediaType | null
                                                      }
                                                    | {
                                                        __typename: "VideoMediaInfo"
                                                        thumbnail?: string | null
                                                        mediaType?: Types.MediaType | null
                                                      }
                                                    | null
                                                }
                                              | { __typename: "MediaGroupContent" }
                                              | { __typename: "ProjectContent" }
                                              | { __typename: "TermContent" }
                                              | { __typename: "TextContent" }
                                              | { __typename: "TextGroupContent" }
                                              | { __typename: "VariableTableContent" }
                                              | null
                                          }
                                        | { __typename: "ST4TreeNode"; id: string }
                                        | null
                                    }
                                  | { __typename: "MediaContent" }
                                  | { __typename: "MediaGroupContent" }
                                  | { __typename: "ProjectContent" }
                                  | { __typename: "TermContent" }
                                  | { __typename: "TextContent" }
                                  | { __typename: "TextGroupContent" }
                                  | { __typename: "VariableTableContent" }
                                  | null
                              }
                            | { __typename: "ST4TreeNode"; id: string }
                            | null
                        } | null> | null
                        comments: Array<{
                          __typename: "CommentNode"
                          id: string
                          comment:
                            | {
                                __typename: "BlockComment"
                                location: number
                                value?: string | null
                                authorDisplayName?: string | null
                                authorUserName?: string | null
                                commentKey: string
                                editDate?: any | null
                                modificationVersion?: number | null
                                replyToKey?: string | null
                                sortOrder?: number | null
                                commentType?: string | null
                                state?: string | null
                                color: string
                                persistanceState?: Types.PersistanceState | null
                                persistanceAction?: Types.PersistanceAction | null
                                node: {
                                  __typename: "ST4Node"
                                  aspectId: any
                                  id: string
                                  content?:
                                    | { __typename: "LexiconContent" }
                                    | { __typename: "LexiconEntryContent" }
                                    | { __typename: "MediaContent" }
                                    | { __typename: "MediaGroupContent" }
                                    | { __typename: "ProjectContent" }
                                    | { __typename: "TermContent" }
                                    | { __typename: "TextContent"; id: string; historyNumber: number }
                                    | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                                    | { __typename: "VariableTableContent" }
                                    | null
                                }
                                actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
                              }
                            | {
                                __typename: "TextComment"
                                endLocation: number
                                startLocation?: number | null
                                value?: string | null
                                authorDisplayName?: string | null
                                authorUserName?: string | null
                                commentKey: string
                                editDate?: any | null
                                modificationVersion?: number | null
                                replyToKey?: string | null
                                sortOrder?: number | null
                                commentType?: string | null
                                state?: string | null
                                color: string
                                persistanceState?: Types.PersistanceState | null
                                persistanceAction?: Types.PersistanceAction | null
                                node: {
                                  __typename: "ST4Node"
                                  aspectId: any
                                  id: string
                                  content?:
                                    | { __typename: "LexiconContent" }
                                    | { __typename: "LexiconEntryContent" }
                                    | { __typename: "MediaContent" }
                                    | { __typename: "MediaGroupContent" }
                                    | { __typename: "ProjectContent" }
                                    | { __typename: "TermContent" }
                                    | { __typename: "TextContent"; id: string; historyNumber: number }
                                    | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                                    | { __typename: "VariableTableContent" }
                                    | null
                                }
                                actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
                              }
                        }>
                      }
                    | { __typename: "VariableTableContent" }
                    | null
                }
              }>
              children: Array<{
                __typename: "NodeRef"
                id: string
                target?:
                  | { __typename: "CommentNode"; id: string }
                  | { __typename: "ExternalSite"; id: string }
                  | { __typename: "ST4Class"; id: string }
                  | { __typename: "ST4Node"; label?: string | null; aspectId: any; id: string }
                  | { __typename: "ST4TreeNode"; id: string }
                  | null
              }>
              links?: Array<{
                __typename: "TextContentLink"
                ref?: string | null
                node?:
                  | { __typename: "CommentNode"; id: string }
                  | { __typename: "ExternalSite"; id: string }
                  | { __typename: "ST4Class"; id: string }
                  | {
                      __typename: "ST4Node"
                      id: string
                      aspectId: any
                      label?: string | null
                      properties?: {
                        __typename: "ContentPropertyConnection"
                        totalCount: number
                        edges?: Array<{
                          __typename: "ContentPropertyEdge"
                          node?: {
                            __typename: "ContentProperty"
                            typeOfValue: Types.ContentPropertyValueType
                            name: string
                            value?: any | null
                            displayName: string
                          } | null
                        } | null> | null
                      } | null
                    }
                  | { __typename: "ST4TreeNode"; id: string }
                  | null
              } | null> | null
              images: Array<{
                __typename: "NodeRef"
                id: string
                target?:
                  | { __typename: "CommentNode"; id: string }
                  | { __typename: "ExternalSite"; id: string }
                  | { __typename: "ST4Class"; id: string }
                  | {
                      __typename: "ST4Node"
                      aspectId: any
                      id: string
                      releaseState: {
                        __typename: "ReleaseStateValue"
                        displayName: string
                        state: Types.ReleaseState
                        isImplicitlyReleased: boolean
                      }
                      content?:
                        | { __typename: "LexiconContent" }
                        | { __typename: "LexiconEntryContent" }
                        | {
                            __typename: "MediaContent"
                            media?:
                              | {
                                  __typename: "AnimationMediaInfo"
                                  id: string
                                  resourceId?: string | null
                                  thumbnail?: string | null
                                  width?: number | null
                                  height?: number | null
                                  mediaType?: Types.MediaType | null
                                }
                              | {
                                  __typename: "ImageMediaInfo"
                                  id: string
                                  resourceId?: string | null
                                  thumbnail?: string | null
                                  width?: number | null
                                  height?: number | null
                                  resolution?: number | null
                                  colorDepth?: number | null
                                  size?: number | null
                                  mime?: string | null
                                  extension?: string | null
                                  allowCaching?: boolean | null
                                  mediaType?: Types.MediaType | null
                                  src?: string | null
                                  language?: string | null
                                }
                              | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                              | {
                                  __typename: "VectorGraphicsMediaInfo"
                                  id: string
                                  resourceId?: string | null
                                  thumbnail?: string | null
                                  width?: number | null
                                  height?: number | null
                                  allowCaching?: boolean | null
                                  mediaType?: Types.MediaType | null
                                  language?: string | null
                                  labels: Array<{
                                    __typename: "VectorGraphicsLabel"
                                    id?: string | null
                                    sequence?: number | null
                                    autoValue?: string | null
                                    text?: string | null
                                    linkType?: Types.VectorGraphicsLabelLinkType | null
                                    link?: string | null
                                    ref?:
                                      | {
                                          __typename: "GenericRef"
                                          id: string
                                          owner?:
                                            | { __typename: "CommentNode" }
                                            | { __typename: "ExternalSite" }
                                            | { __typename: "ST4Class" }
                                            | { __typename: "ST4Node"; id: string }
                                            | { __typename: "ST4TreeNode" }
                                            | null
                                          target?:
                                            | { __typename: "CommentNode" }
                                            | {
                                                __typename: "ExternalSite"
                                                id: string
                                                teaserData?: {
                                                  __typename: "ExternalLinkTeaserData"
                                                  title?: string | null
                                                  summary?: string | null
                                                  iconUrl?: string | null
                                                  imageUrl?: string | null
                                                  status?: Types.LinkTargetStatus | null
                                                } | null
                                              }
                                            | { __typename: "ST4Class" }
                                            | {
                                                __typename: "ST4Node"
                                                id: string
                                                aspectId: any
                                                label?: string | null
                                                properties?: {
                                                  __typename: "ContentPropertyConnection"
                                                  edges?: Array<{
                                                    __typename: "ContentPropertyEdge"
                                                    node?: {
                                                      __typename: "ContentProperty"
                                                      name: string
                                                      value?: any | null
                                                      displayName: string
                                                    } | null
                                                  } | null> | null
                                                } | null
                                              }
                                            | { __typename: "ST4TreeNode" }
                                            | null
                                        }
                                      | {
                                          __typename: "NodeRef"
                                          id: string
                                          owner?:
                                            | { __typename: "CommentNode" }
                                            | { __typename: "ExternalSite" }
                                            | { __typename: "ST4Class" }
                                            | { __typename: "ST4Node"; id: string }
                                            | { __typename: "ST4TreeNode" }
                                            | null
                                          target?:
                                            | { __typename: "CommentNode" }
                                            | {
                                                __typename: "ExternalSite"
                                                id: string
                                                teaserData?: {
                                                  __typename: "ExternalLinkTeaserData"
                                                  title?: string | null
                                                  summary?: string | null
                                                  iconUrl?: string | null
                                                  imageUrl?: string | null
                                                  status?: Types.LinkTargetStatus | null
                                                } | null
                                              }
                                            | { __typename: "ST4Class" }
                                            | {
                                                __typename: "ST4Node"
                                                id: string
                                                aspectId: any
                                                label?: string | null
                                                properties?: {
                                                  __typename: "ContentPropertyConnection"
                                                  edges?: Array<{
                                                    __typename: "ContentPropertyEdge"
                                                    node?: {
                                                      __typename: "ContentProperty"
                                                      name: string
                                                      value?: any | null
                                                      displayName: string
                                                    } | null
                                                  } | null> | null
                                                } | null
                                              }
                                            | { __typename: "ST4TreeNode" }
                                            | null
                                        }
                                      | null
                                  }>
                                }
                              | {
                                  __typename: "VideoMediaInfo"
                                  id: string
                                  resourceId?: string | null
                                  thumbnail?: string | null
                                  width?: number | null
                                  height?: number | null
                                  mediaType?: Types.MediaType | null
                                }
                              | null
                          }
                        | {
                            __typename: "MediaGroupContent"
                            children: Array<{
                              __typename: "NodeRef"
                              id: string
                              target?:
                                | { __typename: "CommentNode"; id: string }
                                | { __typename: "ExternalSite"; id: string }
                                | { __typename: "ST4Class"; id: string }
                                | {
                                    __typename: "ST4Node"
                                    id: string
                                    aspectId: any
                                    content?:
                                      | { __typename: "LexiconContent" }
                                      | { __typename: "LexiconEntryContent" }
                                      | {
                                          __typename: "MediaContent"
                                          media?:
                                            | {
                                                __typename: "AnimationMediaInfo"
                                                id: string
                                                resourceId?: string | null
                                                thumbnail?: string | null
                                                width?: number | null
                                                height?: number | null
                                                mediaType?: Types.MediaType | null
                                              }
                                            | {
                                                __typename: "ImageMediaInfo"
                                                id: string
                                                resourceId?: string | null
                                                thumbnail?: string | null
                                                width?: number | null
                                                height?: number | null
                                                resolution?: number | null
                                                colorDepth?: number | null
                                                size?: number | null
                                                mime?: string | null
                                                extension?: string | null
                                                allowCaching?: boolean | null
                                                mediaType?: Types.MediaType | null
                                                src?: string | null
                                                language?: string | null
                                              }
                                            | {
                                                __typename: "NoMediaInfo"
                                                id: string
                                                mediaType?: Types.MediaType | null
                                              }
                                            | {
                                                __typename: "VectorGraphicsMediaInfo"
                                                id: string
                                                resourceId?: string | null
                                                thumbnail?: string | null
                                                width?: number | null
                                                height?: number | null
                                                allowCaching?: boolean | null
                                                mediaType?: Types.MediaType | null
                                                language?: string | null
                                                labels: Array<{
                                                  __typename: "VectorGraphicsLabel"
                                                  id?: string | null
                                                  sequence?: number | null
                                                  autoValue?: string | null
                                                  text?: string | null
                                                  linkType?: Types.VectorGraphicsLabelLinkType | null
                                                  link?: string | null
                                                  ref?:
                                                    | {
                                                        __typename: "GenericRef"
                                                        id: string
                                                        owner?:
                                                          | { __typename: "CommentNode" }
                                                          | { __typename: "ExternalSite" }
                                                          | { __typename: "ST4Class" }
                                                          | { __typename: "ST4Node"; id: string }
                                                          | { __typename: "ST4TreeNode" }
                                                          | null
                                                        target?:
                                                          | { __typename: "CommentNode" }
                                                          | {
                                                              __typename: "ExternalSite"
                                                              id: string
                                                              teaserData?: {
                                                                __typename: "ExternalLinkTeaserData"
                                                                title?: string | null
                                                                summary?: string | null
                                                                iconUrl?: string | null
                                                                imageUrl?: string | null
                                                                status?: Types.LinkTargetStatus | null
                                                              } | null
                                                            }
                                                          | { __typename: "ST4Class" }
                                                          | {
                                                              __typename: "ST4Node"
                                                              id: string
                                                              aspectId: any
                                                              label?: string | null
                                                              properties?: {
                                                                __typename: "ContentPropertyConnection"
                                                                edges?: Array<{
                                                                  __typename: "ContentPropertyEdge"
                                                                  node?: {
                                                                    __typename: "ContentProperty"
                                                                    name: string
                                                                    value?: any | null
                                                                    displayName: string
                                                                  } | null
                                                                } | null> | null
                                                              } | null
                                                            }
                                                          | { __typename: "ST4TreeNode" }
                                                          | null
                                                      }
                                                    | {
                                                        __typename: "NodeRef"
                                                        id: string
                                                        owner?:
                                                          | { __typename: "CommentNode" }
                                                          | { __typename: "ExternalSite" }
                                                          | { __typename: "ST4Class" }
                                                          | { __typename: "ST4Node"; id: string }
                                                          | { __typename: "ST4TreeNode" }
                                                          | null
                                                        target?:
                                                          | { __typename: "CommentNode" }
                                                          | {
                                                              __typename: "ExternalSite"
                                                              id: string
                                                              teaserData?: {
                                                                __typename: "ExternalLinkTeaserData"
                                                                title?: string | null
                                                                summary?: string | null
                                                                iconUrl?: string | null
                                                                imageUrl?: string | null
                                                                status?: Types.LinkTargetStatus | null
                                                              } | null
                                                            }
                                                          | { __typename: "ST4Class" }
                                                          | {
                                                              __typename: "ST4Node"
                                                              id: string
                                                              aspectId: any
                                                              label?: string | null
                                                              properties?: {
                                                                __typename: "ContentPropertyConnection"
                                                                edges?: Array<{
                                                                  __typename: "ContentPropertyEdge"
                                                                  node?: {
                                                                    __typename: "ContentProperty"
                                                                    name: string
                                                                    value?: any | null
                                                                    displayName: string
                                                                  } | null
                                                                } | null> | null
                                                              } | null
                                                            }
                                                          | { __typename: "ST4TreeNode" }
                                                          | null
                                                      }
                                                    | null
                                                }>
                                              }
                                            | {
                                                __typename: "VideoMediaInfo"
                                                id: string
                                                resourceId?: string | null
                                                thumbnail?: string | null
                                                width?: number | null
                                                height?: number | null
                                                mediaType?: Types.MediaType | null
                                              }
                                            | null
                                        }
                                      | {
                                          __typename: "MediaGroupContent"
                                          media?:
                                            | {
                                                __typename: "AnimationMediaInfo"
                                                id: string
                                                resourceId?: string | null
                                                thumbnail?: string | null
                                                width?: number | null
                                                height?: number | null
                                                mediaType?: Types.MediaType | null
                                              }
                                            | {
                                                __typename: "ImageMediaInfo"
                                                id: string
                                                resourceId?: string | null
                                                thumbnail?: string | null
                                                width?: number | null
                                                height?: number | null
                                                resolution?: number | null
                                                colorDepth?: number | null
                                                size?: number | null
                                                mime?: string | null
                                                extension?: string | null
                                                allowCaching?: boolean | null
                                                mediaType?: Types.MediaType | null
                                                src?: string | null
                                                language?: string | null
                                              }
                                            | {
                                                __typename: "NoMediaInfo"
                                                id: string
                                                mediaType?: Types.MediaType | null
                                              }
                                            | {
                                                __typename: "VectorGraphicsMediaInfo"
                                                id: string
                                                resourceId?: string | null
                                                thumbnail?: string | null
                                                width?: number | null
                                                height?: number | null
                                                allowCaching?: boolean | null
                                                mediaType?: Types.MediaType | null
                                                language?: string | null
                                                labels: Array<{
                                                  __typename: "VectorGraphicsLabel"
                                                  id?: string | null
                                                  sequence?: number | null
                                                  autoValue?: string | null
                                                  text?: string | null
                                                  linkType?: Types.VectorGraphicsLabelLinkType | null
                                                  link?: string | null
                                                  ref?:
                                                    | {
                                                        __typename: "GenericRef"
                                                        id: string
                                                        owner?:
                                                          | { __typename: "CommentNode" }
                                                          | { __typename: "ExternalSite" }
                                                          | { __typename: "ST4Class" }
                                                          | { __typename: "ST4Node"; id: string }
                                                          | { __typename: "ST4TreeNode" }
                                                          | null
                                                        target?:
                                                          | { __typename: "CommentNode" }
                                                          | {
                                                              __typename: "ExternalSite"
                                                              id: string
                                                              teaserData?: {
                                                                __typename: "ExternalLinkTeaserData"
                                                                title?: string | null
                                                                summary?: string | null
                                                                iconUrl?: string | null
                                                                imageUrl?: string | null
                                                                status?: Types.LinkTargetStatus | null
                                                              } | null
                                                            }
                                                          | { __typename: "ST4Class" }
                                                          | {
                                                              __typename: "ST4Node"
                                                              id: string
                                                              aspectId: any
                                                              label?: string | null
                                                              properties?: {
                                                                __typename: "ContentPropertyConnection"
                                                                edges?: Array<{
                                                                  __typename: "ContentPropertyEdge"
                                                                  node?: {
                                                                    __typename: "ContentProperty"
                                                                    name: string
                                                                    value?: any | null
                                                                    displayName: string
                                                                  } | null
                                                                } | null> | null
                                                              } | null
                                                            }
                                                          | { __typename: "ST4TreeNode" }
                                                          | null
                                                      }
                                                    | {
                                                        __typename: "NodeRef"
                                                        id: string
                                                        owner?:
                                                          | { __typename: "CommentNode" }
                                                          | { __typename: "ExternalSite" }
                                                          | { __typename: "ST4Class" }
                                                          | { __typename: "ST4Node"; id: string }
                                                          | { __typename: "ST4TreeNode" }
                                                          | null
                                                        target?:
                                                          | { __typename: "CommentNode" }
                                                          | {
                                                              __typename: "ExternalSite"
                                                              id: string
                                                              teaserData?: {
                                                                __typename: "ExternalLinkTeaserData"
                                                                title?: string | null
                                                                summary?: string | null
                                                                iconUrl?: string | null
                                                                imageUrl?: string | null
                                                                status?: Types.LinkTargetStatus | null
                                                              } | null
                                                            }
                                                          | { __typename: "ST4Class" }
                                                          | {
                                                              __typename: "ST4Node"
                                                              id: string
                                                              aspectId: any
                                                              label?: string | null
                                                              properties?: {
                                                                __typename: "ContentPropertyConnection"
                                                                edges?: Array<{
                                                                  __typename: "ContentPropertyEdge"
                                                                  node?: {
                                                                    __typename: "ContentProperty"
                                                                    name: string
                                                                    value?: any | null
                                                                    displayName: string
                                                                  } | null
                                                                } | null> | null
                                                              } | null
                                                            }
                                                          | { __typename: "ST4TreeNode" }
                                                          | null
                                                      }
                                                    | null
                                                }>
                                              }
                                            | {
                                                __typename: "VideoMediaInfo"
                                                id: string
                                                resourceId?: string | null
                                                thumbnail?: string | null
                                                width?: number | null
                                                height?: number | null
                                                mediaType?: Types.MediaType | null
                                              }
                                            | null
                                        }
                                      | { __typename: "ProjectContent" }
                                      | { __typename: "TermContent" }
                                      | { __typename: "TextContent" }
                                      | { __typename: "TextGroupContent" }
                                      | { __typename: "VariableTableContent" }
                                      | null
                                  }
                                | { __typename: "ST4TreeNode"; id: string }
                                | null
                            }>
                            media?:
                              | {
                                  __typename: "AnimationMediaInfo"
                                  id: string
                                  resourceId?: string | null
                                  thumbnail?: string | null
                                  width?: number | null
                                  height?: number | null
                                  mediaType?: Types.MediaType | null
                                }
                              | {
                                  __typename: "ImageMediaInfo"
                                  id: string
                                  resourceId?: string | null
                                  thumbnail?: string | null
                                  width?: number | null
                                  height?: number | null
                                  resolution?: number | null
                                  colorDepth?: number | null
                                  size?: number | null
                                  mime?: string | null
                                  extension?: string | null
                                  allowCaching?: boolean | null
                                  mediaType?: Types.MediaType | null
                                  src?: string | null
                                  language?: string | null
                                }
                              | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                              | {
                                  __typename: "VectorGraphicsMediaInfo"
                                  id: string
                                  resourceId?: string | null
                                  thumbnail?: string | null
                                  width?: number | null
                                  height?: number | null
                                  allowCaching?: boolean | null
                                  mediaType?: Types.MediaType | null
                                  language?: string | null
                                  labels: Array<{
                                    __typename: "VectorGraphicsLabel"
                                    id?: string | null
                                    sequence?: number | null
                                    autoValue?: string | null
                                    text?: string | null
                                    linkType?: Types.VectorGraphicsLabelLinkType | null
                                    link?: string | null
                                    ref?:
                                      | {
                                          __typename: "GenericRef"
                                          id: string
                                          owner?:
                                            | { __typename: "CommentNode" }
                                            | { __typename: "ExternalSite" }
                                            | { __typename: "ST4Class" }
                                            | { __typename: "ST4Node"; id: string }
                                            | { __typename: "ST4TreeNode" }
                                            | null
                                          target?:
                                            | { __typename: "CommentNode" }
                                            | {
                                                __typename: "ExternalSite"
                                                id: string
                                                teaserData?: {
                                                  __typename: "ExternalLinkTeaserData"
                                                  title?: string | null
                                                  summary?: string | null
                                                  iconUrl?: string | null
                                                  imageUrl?: string | null
                                                  status?: Types.LinkTargetStatus | null
                                                } | null
                                              }
                                            | { __typename: "ST4Class" }
                                            | {
                                                __typename: "ST4Node"
                                                id: string
                                                aspectId: any
                                                label?: string | null
                                                properties?: {
                                                  __typename: "ContentPropertyConnection"
                                                  edges?: Array<{
                                                    __typename: "ContentPropertyEdge"
                                                    node?: {
                                                      __typename: "ContentProperty"
                                                      name: string
                                                      value?: any | null
                                                      displayName: string
                                                    } | null
                                                  } | null> | null
                                                } | null
                                              }
                                            | { __typename: "ST4TreeNode" }
                                            | null
                                        }
                                      | {
                                          __typename: "NodeRef"
                                          id: string
                                          owner?:
                                            | { __typename: "CommentNode" }
                                            | { __typename: "ExternalSite" }
                                            | { __typename: "ST4Class" }
                                            | { __typename: "ST4Node"; id: string }
                                            | { __typename: "ST4TreeNode" }
                                            | null
                                          target?:
                                            | { __typename: "CommentNode" }
                                            | {
                                                __typename: "ExternalSite"
                                                id: string
                                                teaserData?: {
                                                  __typename: "ExternalLinkTeaserData"
                                                  title?: string | null
                                                  summary?: string | null
                                                  iconUrl?: string | null
                                                  imageUrl?: string | null
                                                  status?: Types.LinkTargetStatus | null
                                                } | null
                                              }
                                            | { __typename: "ST4Class" }
                                            | {
                                                __typename: "ST4Node"
                                                id: string
                                                aspectId: any
                                                label?: string | null
                                                properties?: {
                                                  __typename: "ContentPropertyConnection"
                                                  edges?: Array<{
                                                    __typename: "ContentPropertyEdge"
                                                    node?: {
                                                      __typename: "ContentProperty"
                                                      name: string
                                                      value?: any | null
                                                      displayName: string
                                                    } | null
                                                  } | null> | null
                                                } | null
                                              }
                                            | { __typename: "ST4TreeNode" }
                                            | null
                                        }
                                      | null
                                  }>
                                }
                              | {
                                  __typename: "VideoMediaInfo"
                                  id: string
                                  resourceId?: string | null
                                  thumbnail?: string | null
                                  width?: number | null
                                  height?: number | null
                                  mediaType?: Types.MediaType | null
                                }
                              | null
                          }
                        | { __typename: "ProjectContent" }
                        | { __typename: "TermContent" }
                        | { __typename: "TextContent" }
                        | { __typename: "TextGroupContent" }
                        | { __typename: "VariableTableContent" }
                        | null
                    }
                  | { __typename: "ST4TreeNode"; id: string }
                  | null
              }>
              variableTables?: Array<{
                __typename: "VariableTableReference"
                ref?: string | null
                node?:
                  | { __typename: "CommentNode" }
                  | { __typename: "ExternalSite" }
                  | { __typename: "ST4Class" }
                  | {
                      __typename: "ST4Node"
                      id: string
                      label?: string | null
                      aspectId: any
                      content?:
                        | { __typename: "LexiconContent" }
                        | { __typename: "LexiconEntryContent" }
                        | { __typename: "MediaContent" }
                        | { __typename: "MediaGroupContent" }
                        | { __typename: "ProjectContent" }
                        | { __typename: "TermContent" }
                        | { __typename: "TextContent" }
                        | { __typename: "TextGroupContent" }
                        | {
                            __typename: "VariableTableContent"
                            xml?: string | null
                            valueSets: Array<{ __typename: "ValueSet"; name: string; valueSetId: string }>
                            variables: Array<{
                              __typename: "Variable"
                              name: string
                              variableId: string
                              values: Array<string>
                            }>
                          }
                        | null
                    }
                  | { __typename: "ST4TreeNode" }
                  | null
              } | null> | null
              linkedDataNodes: Array<{
                __typename: "NodeRef"
                id: string
                target?:
                  | { __typename: "CommentNode"; id: string }
                  | { __typename: "ExternalSite"; id: string }
                  | { __typename: "ST4Class"; id: string }
                  | {
                      __typename: "ST4Node"
                      label?: string | null
                      id: string
                      metadata: Array<{
                        __typename: "MetaDataEntry"
                        label?: string | null
                        systemName: string
                        value?: string | null
                        values: Array<string>
                        users: Array<string>
                      }>
                      linkedOntologyNodes: Array<
                        | {
                            __typename: "GenericRef"
                            target?:
                              | { __typename: "CommentNode" }
                              | { __typename: "ExternalSite" }
                              | { __typename: "ST4Class" }
                              | { __typename: "ST4Node"; id: string; label?: string | null }
                              | { __typename: "ST4TreeNode" }
                              | null
                          }
                        | {
                            __typename: "NodeRef"
                            linkClassName: string
                            class?: { __typename: "ST4Class"; label?: string | null } | null
                            target?:
                              | { __typename: "CommentNode" }
                              | { __typename: "ExternalSite" }
                              | { __typename: "ST4Class" }
                              | { __typename: "ST4Node"; id: string; label?: string | null }
                              | { __typename: "ST4TreeNode" }
                              | null
                          }
                      >
                    }
                  | { __typename: "ST4TreeNode"; id: string }
                  | null
              }>
              lexiconEntries?: Array<{
                __typename: "NodeRef"
                id: string
                target?:
                  | { __typename: "CommentNode"; id: string }
                  | { __typename: "ExternalSite"; id: string }
                  | { __typename: "ST4Class"; id: string }
                  | {
                      __typename: "ST4Node"
                      aspectId: any
                      label?: string | null
                      id: string
                      content?:
                        | { __typename: "LexiconContent" }
                        | {
                            __typename: "LexiconEntryContent"
                            descriptionXast?: string | null
                            subject?: string | null
                            sortKey?: string | null
                            lexicon:
                              | { __typename: "CommentNode"; id: string }
                              | { __typename: "ExternalSite"; id: string }
                              | { __typename: "ST4Class"; id: string }
                              | { __typename: "ST4Node"; label?: string | null; id: string }
                              | { __typename: "ST4TreeNode"; id: string }
                            terms: Array<
                              | { __typename: "CommentNode"; id: string }
                              | { __typename: "ExternalSite"; id: string }
                              | { __typename: "ST4Class"; id: string }
                              | {
                                  __typename: "ST4Node"
                                  id: string
                                  label?: string | null
                                  aspectId: any
                                  content?:
                                    | { __typename: "LexiconContent" }
                                    | { __typename: "LexiconEntryContent" }
                                    | { __typename: "MediaContent" }
                                    | { __typename: "MediaGroupContent" }
                                    | { __typename: "ProjectContent" }
                                    | {
                                        __typename: "TermContent"
                                        gramaticalGender?: string | null
                                        validity?: string | null
                                        editLanguage?: string | null
                                      }
                                    | { __typename: "TextContent" }
                                    | { __typename: "TextGroupContent" }
                                    | { __typename: "VariableTableContent" }
                                    | null
                                }
                              | { __typename: "ST4TreeNode"; id: string }
                            >
                            image?:
                              | { __typename: "CommentNode"; id: string }
                              | { __typename: "ExternalSite"; id: string }
                              | { __typename: "ST4Class"; id: string }
                              | {
                                  __typename: "ST4Node"
                                  aspectId: any
                                  id: string
                                  content?:
                                    | { __typename: "LexiconContent" }
                                    | { __typename: "LexiconEntryContent" }
                                    | {
                                        __typename: "MediaContent"
                                        media?:
                                          | {
                                              __typename: "AnimationMediaInfo"
                                              thumbnail?: string | null
                                              mediaType?: Types.MediaType | null
                                            }
                                          | {
                                              __typename: "ImageMediaInfo"
                                              src?: string | null
                                              thumbnail?: string | null
                                              mediaType?: Types.MediaType | null
                                            }
                                          | { __typename: "NoMediaInfo" }
                                          | {
                                              __typename: "VectorGraphicsMediaInfo"
                                              svg?: string | null
                                              thumbnail?: string | null
                                              mediaType?: Types.MediaType | null
                                            }
                                          | {
                                              __typename: "VideoMediaInfo"
                                              thumbnail?: string | null
                                              mediaType?: Types.MediaType | null
                                            }
                                          | null
                                      }
                                    | { __typename: "MediaGroupContent" }
                                    | { __typename: "ProjectContent" }
                                    | { __typename: "TermContent" }
                                    | { __typename: "TextContent" }
                                    | { __typename: "TextGroupContent" }
                                    | { __typename: "VariableTableContent" }
                                    | null
                                }
                              | { __typename: "ST4TreeNode"; id: string }
                              | null
                          }
                        | { __typename: "MediaContent" }
                        | { __typename: "MediaGroupContent" }
                        | { __typename: "ProjectContent" }
                        | { __typename: "TermContent" }
                        | { __typename: "TextContent" }
                        | { __typename: "TextGroupContent" }
                        | { __typename: "VariableTableContent" }
                        | null
                    }
                  | { __typename: "ST4TreeNode"; id: string }
                  | null
              } | null> | null
              comments: Array<{
                __typename: "CommentNode"
                id: string
                comment:
                  | {
                      __typename: "BlockComment"
                      location: number
                      value?: string | null
                      authorDisplayName?: string | null
                      authorUserName?: string | null
                      commentKey: string
                      editDate?: any | null
                      modificationVersion?: number | null
                      replyToKey?: string | null
                      sortOrder?: number | null
                      commentType?: string | null
                      state?: string | null
                      color: string
                      persistanceState?: Types.PersistanceState | null
                      persistanceAction?: Types.PersistanceAction | null
                      node: {
                        __typename: "ST4Node"
                        aspectId: any
                        id: string
                        content?:
                          | { __typename: "LexiconContent" }
                          | { __typename: "LexiconEntryContent" }
                          | { __typename: "MediaContent" }
                          | { __typename: "MediaGroupContent" }
                          | { __typename: "ProjectContent" }
                          | { __typename: "TermContent" }
                          | { __typename: "TextContent"; id: string; historyNumber: number }
                          | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                          | { __typename: "VariableTableContent" }
                          | null
                      }
                      actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
                    }
                  | {
                      __typename: "TextComment"
                      endLocation: number
                      startLocation?: number | null
                      value?: string | null
                      authorDisplayName?: string | null
                      authorUserName?: string | null
                      commentKey: string
                      editDate?: any | null
                      modificationVersion?: number | null
                      replyToKey?: string | null
                      sortOrder?: number | null
                      commentType?: string | null
                      state?: string | null
                      color: string
                      persistanceState?: Types.PersistanceState | null
                      persistanceAction?: Types.PersistanceAction | null
                      node: {
                        __typename: "ST4Node"
                        aspectId: any
                        id: string
                        content?:
                          | { __typename: "LexiconContent" }
                          | { __typename: "LexiconEntryContent" }
                          | { __typename: "MediaContent" }
                          | { __typename: "MediaGroupContent" }
                          | { __typename: "ProjectContent" }
                          | { __typename: "TermContent" }
                          | { __typename: "TextContent"; id: string; historyNumber: number }
                          | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                          | { __typename: "VariableTableContent" }
                          | null
                      }
                      actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
                    }
              }>
            }
          | {
              __typename: "VariableTableContent"
              xml?: string | null
              valueSets: Array<{ __typename: "ValueSet"; name: string; valueSetId: string }>
              variables: Array<{ __typename: "Variable"; name: string; variableId: string; values: Array<string> }>
            }
          | null
        releaseState: {
          __typename: "ReleaseStateValue"
          displayName: string
          state: Types.ReleaseState
          isImplicitlyReleased: boolean
        }
      }
    | { __typename: "ST4TreeNode"; id: string }
    | null
}

export type LinkMetadataQueryVariables = Types.Exact<{
  id: Types.Scalars["ID"]
}>

export type LinkMetadataQuery = {
  __typename: "Query"
  node?:
    | { __typename: "CommentNode"; id: string }
    | {
        __typename: "ExternalSite"
        id: string
        teaserData?: {
          __typename: "ExternalLinkTeaserData"
          title?: string | null
          summary?: string | null
          iconUrl?: string | null
          imageUrl?: string | null
          status?: Types.LinkTargetStatus | null
        } | null
      }
    | { __typename: "ST4Class"; id: string }
    | { __typename: "ST4Node"; id: string }
    | { __typename: "ST4TreeNode"; id: string }
    | null
}

export type St4NodeWithContentFragment = {
  __typename: "ST4Node"
  id: string
  label?: string | null
  aspectId: any
  nodeClass: { __typename: "ST4Class"; classHierarchy: Array<string> }
  metadata: Array<{
    __typename: "MetaDataEntry"
    label?: string | null
    systemName: string
    value?: string | null
    values: Array<string>
  }>
  properties?: {
    __typename: "ContentPropertyConnection"
    totalCount: number
    edges?: Array<{
      __typename: "ContentPropertyEdge"
      node?: {
        __typename: "ContentProperty"
        name: string
        displayName: string
        value?: any | null
        typeOfValue: Types.ContentPropertyValueType
      } | null
    } | null> | null
  } | null
  content?:
    | { __typename: "LexiconContent" }
    | {
        __typename: "LexiconEntryContent"
        descriptionXast?: string | null
        subject?: string | null
        sortKey?: string | null
        lexicon:
          | { __typename: "CommentNode"; id: string }
          | { __typename: "ExternalSite"; id: string }
          | { __typename: "ST4Class"; id: string }
          | { __typename: "ST4Node"; label?: string | null; id: string }
          | { __typename: "ST4TreeNode"; id: string }
        terms: Array<
          | { __typename: "CommentNode"; id: string }
          | { __typename: "ExternalSite"; id: string }
          | { __typename: "ST4Class"; id: string }
          | {
              __typename: "ST4Node"
              id: string
              label?: string | null
              aspectId: any
              content?:
                | { __typename: "LexiconContent" }
                | { __typename: "LexiconEntryContent" }
                | { __typename: "MediaContent" }
                | { __typename: "MediaGroupContent" }
                | { __typename: "ProjectContent" }
                | {
                    __typename: "TermContent"
                    gramaticalGender?: string | null
                    validity?: string | null
                    editLanguage?: string | null
                  }
                | { __typename: "TextContent" }
                | { __typename: "TextGroupContent" }
                | { __typename: "VariableTableContent" }
                | null
            }
          | { __typename: "ST4TreeNode"; id: string }
        >
        image?:
          | { __typename: "CommentNode"; id: string }
          | { __typename: "ExternalSite"; id: string }
          | { __typename: "ST4Class"; id: string }
          | {
              __typename: "ST4Node"
              aspectId: any
              id: string
              content?:
                | { __typename: "LexiconContent" }
                | { __typename: "LexiconEntryContent" }
                | {
                    __typename: "MediaContent"
                    media?:
                      | {
                          __typename: "AnimationMediaInfo"
                          thumbnail?: string | null
                          mediaType?: Types.MediaType | null
                        }
                      | {
                          __typename: "ImageMediaInfo"
                          src?: string | null
                          thumbnail?: string | null
                          mediaType?: Types.MediaType | null
                        }
                      | { __typename: "NoMediaInfo" }
                      | {
                          __typename: "VectorGraphicsMediaInfo"
                          svg?: string | null
                          thumbnail?: string | null
                          mediaType?: Types.MediaType | null
                        }
                      | { __typename: "VideoMediaInfo"; thumbnail?: string | null; mediaType?: Types.MediaType | null }
                      | null
                  }
                | { __typename: "MediaGroupContent" }
                | { __typename: "ProjectContent" }
                | { __typename: "TermContent" }
                | { __typename: "TextContent" }
                | { __typename: "TextGroupContent" }
                | { __typename: "VariableTableContent" }
                | null
            }
          | { __typename: "ST4TreeNode"; id: string }
          | null
      }
    | {
        __typename: "MediaContent"
        media?:
          | {
              __typename: "AnimationMediaInfo"
              id: string
              resourceId?: string | null
              thumbnail?: string | null
              width?: number | null
              height?: number | null
              mediaType?: Types.MediaType | null
            }
          | {
              __typename: "ImageMediaInfo"
              id: string
              resourceId?: string | null
              thumbnail?: string | null
              width?: number | null
              height?: number | null
              resolution?: number | null
              colorDepth?: number | null
              size?: number | null
              mime?: string | null
              extension?: string | null
              allowCaching?: boolean | null
              mediaType?: Types.MediaType | null
              src?: string | null
              language?: string | null
            }
          | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
          | {
              __typename: "VectorGraphicsMediaInfo"
              id: string
              resourceId?: string | null
              thumbnail?: string | null
              width?: number | null
              height?: number | null
              allowCaching?: boolean | null
              mediaType?: Types.MediaType | null
              language?: string | null
              labels: Array<{
                __typename: "VectorGraphicsLabel"
                id?: string | null
                sequence?: number | null
                autoValue?: string | null
                text?: string | null
                linkType?: Types.VectorGraphicsLabelLinkType | null
                link?: string | null
                ref?:
                  | {
                      __typename: "GenericRef"
                      id: string
                      owner?:
                        | { __typename: "CommentNode" }
                        | { __typename: "ExternalSite" }
                        | { __typename: "ST4Class" }
                        | { __typename: "ST4Node"; id: string }
                        | { __typename: "ST4TreeNode" }
                        | null
                      target?:
                        | { __typename: "CommentNode" }
                        | {
                            __typename: "ExternalSite"
                            id: string
                            teaserData?: {
                              __typename: "ExternalLinkTeaserData"
                              title?: string | null
                              summary?: string | null
                              iconUrl?: string | null
                              imageUrl?: string | null
                              status?: Types.LinkTargetStatus | null
                            } | null
                          }
                        | { __typename: "ST4Class" }
                        | {
                            __typename: "ST4Node"
                            id: string
                            aspectId: any
                            label?: string | null
                            properties?: {
                              __typename: "ContentPropertyConnection"
                              edges?: Array<{
                                __typename: "ContentPropertyEdge"
                                node?: {
                                  __typename: "ContentProperty"
                                  name: string
                                  value?: any | null
                                  displayName: string
                                } | null
                              } | null> | null
                            } | null
                          }
                        | { __typename: "ST4TreeNode" }
                        | null
                    }
                  | {
                      __typename: "NodeRef"
                      id: string
                      owner?:
                        | { __typename: "CommentNode" }
                        | { __typename: "ExternalSite" }
                        | { __typename: "ST4Class" }
                        | { __typename: "ST4Node"; id: string }
                        | { __typename: "ST4TreeNode" }
                        | null
                      target?:
                        | { __typename: "CommentNode" }
                        | {
                            __typename: "ExternalSite"
                            id: string
                            teaserData?: {
                              __typename: "ExternalLinkTeaserData"
                              title?: string | null
                              summary?: string | null
                              iconUrl?: string | null
                              imageUrl?: string | null
                              status?: Types.LinkTargetStatus | null
                            } | null
                          }
                        | { __typename: "ST4Class" }
                        | {
                            __typename: "ST4Node"
                            id: string
                            aspectId: any
                            label?: string | null
                            properties?: {
                              __typename: "ContentPropertyConnection"
                              edges?: Array<{
                                __typename: "ContentPropertyEdge"
                                node?: {
                                  __typename: "ContentProperty"
                                  name: string
                                  value?: any | null
                                  displayName: string
                                } | null
                              } | null> | null
                            } | null
                          }
                        | { __typename: "ST4TreeNode" }
                        | null
                    }
                  | null
              }>
            }
          | {
              __typename: "VideoMediaInfo"
              id: string
              resourceId?: string | null
              thumbnail?: string | null
              width?: number | null
              height?: number | null
              mediaType?: Types.MediaType | null
            }
          | null
      }
    | {
        __typename: "MediaGroupContent"
        children: Array<{
          __typename: "NodeRef"
          id: string
          target?:
            | { __typename: "CommentNode"; id: string }
            | { __typename: "ExternalSite"; id: string }
            | { __typename: "ST4Class"; id: string }
            | {
                __typename: "ST4Node"
                id: string
                aspectId: any
                content?:
                  | { __typename: "LexiconContent" }
                  | { __typename: "LexiconEntryContent" }
                  | {
                      __typename: "MediaContent"
                      media?:
                        | {
                            __typename: "AnimationMediaInfo"
                            id: string
                            resourceId?: string | null
                            thumbnail?: string | null
                            width?: number | null
                            height?: number | null
                            mediaType?: Types.MediaType | null
                          }
                        | {
                            __typename: "ImageMediaInfo"
                            id: string
                            resourceId?: string | null
                            thumbnail?: string | null
                            width?: number | null
                            height?: number | null
                            resolution?: number | null
                            colorDepth?: number | null
                            size?: number | null
                            mime?: string | null
                            extension?: string | null
                            allowCaching?: boolean | null
                            mediaType?: Types.MediaType | null
                            src?: string | null
                            language?: string | null
                          }
                        | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                        | {
                            __typename: "VectorGraphicsMediaInfo"
                            id: string
                            resourceId?: string | null
                            thumbnail?: string | null
                            width?: number | null
                            height?: number | null
                            allowCaching?: boolean | null
                            mediaType?: Types.MediaType | null
                            language?: string | null
                            labels: Array<{
                              __typename: "VectorGraphicsLabel"
                              id?: string | null
                              sequence?: number | null
                              autoValue?: string | null
                              text?: string | null
                              linkType?: Types.VectorGraphicsLabelLinkType | null
                              link?: string | null
                              ref?:
                                | {
                                    __typename: "GenericRef"
                                    id: string
                                    owner?:
                                      | { __typename: "CommentNode" }
                                      | { __typename: "ExternalSite" }
                                      | { __typename: "ST4Class" }
                                      | { __typename: "ST4Node"; id: string }
                                      | { __typename: "ST4TreeNode" }
                                      | null
                                    target?:
                                      | { __typename: "CommentNode" }
                                      | {
                                          __typename: "ExternalSite"
                                          id: string
                                          teaserData?: {
                                            __typename: "ExternalLinkTeaserData"
                                            title?: string | null
                                            summary?: string | null
                                            iconUrl?: string | null
                                            imageUrl?: string | null
                                            status?: Types.LinkTargetStatus | null
                                          } | null
                                        }
                                      | { __typename: "ST4Class" }
                                      | {
                                          __typename: "ST4Node"
                                          id: string
                                          aspectId: any
                                          label?: string | null
                                          properties?: {
                                            __typename: "ContentPropertyConnection"
                                            edges?: Array<{
                                              __typename: "ContentPropertyEdge"
                                              node?: {
                                                __typename: "ContentProperty"
                                                name: string
                                                value?: any | null
                                                displayName: string
                                              } | null
                                            } | null> | null
                                          } | null
                                        }
                                      | { __typename: "ST4TreeNode" }
                                      | null
                                  }
                                | {
                                    __typename: "NodeRef"
                                    id: string
                                    owner?:
                                      | { __typename: "CommentNode" }
                                      | { __typename: "ExternalSite" }
                                      | { __typename: "ST4Class" }
                                      | { __typename: "ST4Node"; id: string }
                                      | { __typename: "ST4TreeNode" }
                                      | null
                                    target?:
                                      | { __typename: "CommentNode" }
                                      | {
                                          __typename: "ExternalSite"
                                          id: string
                                          teaserData?: {
                                            __typename: "ExternalLinkTeaserData"
                                            title?: string | null
                                            summary?: string | null
                                            iconUrl?: string | null
                                            imageUrl?: string | null
                                            status?: Types.LinkTargetStatus | null
                                          } | null
                                        }
                                      | { __typename: "ST4Class" }
                                      | {
                                          __typename: "ST4Node"
                                          id: string
                                          aspectId: any
                                          label?: string | null
                                          properties?: {
                                            __typename: "ContentPropertyConnection"
                                            edges?: Array<{
                                              __typename: "ContentPropertyEdge"
                                              node?: {
                                                __typename: "ContentProperty"
                                                name: string
                                                value?: any | null
                                                displayName: string
                                              } | null
                                            } | null> | null
                                          } | null
                                        }
                                      | { __typename: "ST4TreeNode" }
                                      | null
                                  }
                                | null
                            }>
                          }
                        | {
                            __typename: "VideoMediaInfo"
                            id: string
                            resourceId?: string | null
                            thumbnail?: string | null
                            width?: number | null
                            height?: number | null
                            mediaType?: Types.MediaType | null
                          }
                        | null
                    }
                  | {
                      __typename: "MediaGroupContent"
                      media?:
                        | {
                            __typename: "AnimationMediaInfo"
                            id: string
                            resourceId?: string | null
                            thumbnail?: string | null
                            width?: number | null
                            height?: number | null
                            mediaType?: Types.MediaType | null
                          }
                        | {
                            __typename: "ImageMediaInfo"
                            id: string
                            resourceId?: string | null
                            thumbnail?: string | null
                            width?: number | null
                            height?: number | null
                            resolution?: number | null
                            colorDepth?: number | null
                            size?: number | null
                            mime?: string | null
                            extension?: string | null
                            allowCaching?: boolean | null
                            mediaType?: Types.MediaType | null
                            src?: string | null
                            language?: string | null
                          }
                        | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                        | {
                            __typename: "VectorGraphicsMediaInfo"
                            id: string
                            resourceId?: string | null
                            thumbnail?: string | null
                            width?: number | null
                            height?: number | null
                            allowCaching?: boolean | null
                            mediaType?: Types.MediaType | null
                            language?: string | null
                            labels: Array<{
                              __typename: "VectorGraphicsLabel"
                              id?: string | null
                              sequence?: number | null
                              autoValue?: string | null
                              text?: string | null
                              linkType?: Types.VectorGraphicsLabelLinkType | null
                              link?: string | null
                              ref?:
                                | {
                                    __typename: "GenericRef"
                                    id: string
                                    owner?:
                                      | { __typename: "CommentNode" }
                                      | { __typename: "ExternalSite" }
                                      | { __typename: "ST4Class" }
                                      | { __typename: "ST4Node"; id: string }
                                      | { __typename: "ST4TreeNode" }
                                      | null
                                    target?:
                                      | { __typename: "CommentNode" }
                                      | {
                                          __typename: "ExternalSite"
                                          id: string
                                          teaserData?: {
                                            __typename: "ExternalLinkTeaserData"
                                            title?: string | null
                                            summary?: string | null
                                            iconUrl?: string | null
                                            imageUrl?: string | null
                                            status?: Types.LinkTargetStatus | null
                                          } | null
                                        }
                                      | { __typename: "ST4Class" }
                                      | {
                                          __typename: "ST4Node"
                                          id: string
                                          aspectId: any
                                          label?: string | null
                                          properties?: {
                                            __typename: "ContentPropertyConnection"
                                            edges?: Array<{
                                              __typename: "ContentPropertyEdge"
                                              node?: {
                                                __typename: "ContentProperty"
                                                name: string
                                                value?: any | null
                                                displayName: string
                                              } | null
                                            } | null> | null
                                          } | null
                                        }
                                      | { __typename: "ST4TreeNode" }
                                      | null
                                  }
                                | {
                                    __typename: "NodeRef"
                                    id: string
                                    owner?:
                                      | { __typename: "CommentNode" }
                                      | { __typename: "ExternalSite" }
                                      | { __typename: "ST4Class" }
                                      | { __typename: "ST4Node"; id: string }
                                      | { __typename: "ST4TreeNode" }
                                      | null
                                    target?:
                                      | { __typename: "CommentNode" }
                                      | {
                                          __typename: "ExternalSite"
                                          id: string
                                          teaserData?: {
                                            __typename: "ExternalLinkTeaserData"
                                            title?: string | null
                                            summary?: string | null
                                            iconUrl?: string | null
                                            imageUrl?: string | null
                                            status?: Types.LinkTargetStatus | null
                                          } | null
                                        }
                                      | { __typename: "ST4Class" }
                                      | {
                                          __typename: "ST4Node"
                                          id: string
                                          aspectId: any
                                          label?: string | null
                                          properties?: {
                                            __typename: "ContentPropertyConnection"
                                            edges?: Array<{
                                              __typename: "ContentPropertyEdge"
                                              node?: {
                                                __typename: "ContentProperty"
                                                name: string
                                                value?: any | null
                                                displayName: string
                                              } | null
                                            } | null> | null
                                          } | null
                                        }
                                      | { __typename: "ST4TreeNode" }
                                      | null
                                  }
                                | null
                            }>
                          }
                        | {
                            __typename: "VideoMediaInfo"
                            id: string
                            resourceId?: string | null
                            thumbnail?: string | null
                            width?: number | null
                            height?: number | null
                            mediaType?: Types.MediaType | null
                          }
                        | null
                    }
                  | { __typename: "ProjectContent" }
                  | { __typename: "TermContent" }
                  | { __typename: "TextContent" }
                  | { __typename: "TextGroupContent" }
                  | { __typename: "VariableTableContent" }
                  | null
              }
            | { __typename: "ST4TreeNode"; id: string }
            | null
        }>
        media?:
          | {
              __typename: "AnimationMediaInfo"
              id: string
              resourceId?: string | null
              thumbnail?: string | null
              width?: number | null
              height?: number | null
              mediaType?: Types.MediaType | null
            }
          | {
              __typename: "ImageMediaInfo"
              id: string
              resourceId?: string | null
              thumbnail?: string | null
              width?: number | null
              height?: number | null
              resolution?: number | null
              colorDepth?: number | null
              size?: number | null
              mime?: string | null
              extension?: string | null
              allowCaching?: boolean | null
              mediaType?: Types.MediaType | null
              src?: string | null
              language?: string | null
            }
          | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
          | {
              __typename: "VectorGraphicsMediaInfo"
              id: string
              resourceId?: string | null
              thumbnail?: string | null
              width?: number | null
              height?: number | null
              allowCaching?: boolean | null
              mediaType?: Types.MediaType | null
              language?: string | null
              labels: Array<{
                __typename: "VectorGraphicsLabel"
                id?: string | null
                sequence?: number | null
                autoValue?: string | null
                text?: string | null
                linkType?: Types.VectorGraphicsLabelLinkType | null
                link?: string | null
                ref?:
                  | {
                      __typename: "GenericRef"
                      id: string
                      owner?:
                        | { __typename: "CommentNode" }
                        | { __typename: "ExternalSite" }
                        | { __typename: "ST4Class" }
                        | { __typename: "ST4Node"; id: string }
                        | { __typename: "ST4TreeNode" }
                        | null
                      target?:
                        | { __typename: "CommentNode" }
                        | {
                            __typename: "ExternalSite"
                            id: string
                            teaserData?: {
                              __typename: "ExternalLinkTeaserData"
                              title?: string | null
                              summary?: string | null
                              iconUrl?: string | null
                              imageUrl?: string | null
                              status?: Types.LinkTargetStatus | null
                            } | null
                          }
                        | { __typename: "ST4Class" }
                        | {
                            __typename: "ST4Node"
                            id: string
                            aspectId: any
                            label?: string | null
                            properties?: {
                              __typename: "ContentPropertyConnection"
                              edges?: Array<{
                                __typename: "ContentPropertyEdge"
                                node?: {
                                  __typename: "ContentProperty"
                                  name: string
                                  value?: any | null
                                  displayName: string
                                } | null
                              } | null> | null
                            } | null
                          }
                        | { __typename: "ST4TreeNode" }
                        | null
                    }
                  | {
                      __typename: "NodeRef"
                      id: string
                      owner?:
                        | { __typename: "CommentNode" }
                        | { __typename: "ExternalSite" }
                        | { __typename: "ST4Class" }
                        | { __typename: "ST4Node"; id: string }
                        | { __typename: "ST4TreeNode" }
                        | null
                      target?:
                        | { __typename: "CommentNode" }
                        | {
                            __typename: "ExternalSite"
                            id: string
                            teaserData?: {
                              __typename: "ExternalLinkTeaserData"
                              title?: string | null
                              summary?: string | null
                              iconUrl?: string | null
                              imageUrl?: string | null
                              status?: Types.LinkTargetStatus | null
                            } | null
                          }
                        | { __typename: "ST4Class" }
                        | {
                            __typename: "ST4Node"
                            id: string
                            aspectId: any
                            label?: string | null
                            properties?: {
                              __typename: "ContentPropertyConnection"
                              edges?: Array<{
                                __typename: "ContentPropertyEdge"
                                node?: {
                                  __typename: "ContentProperty"
                                  name: string
                                  value?: any | null
                                  displayName: string
                                } | null
                              } | null> | null
                            } | null
                          }
                        | { __typename: "ST4TreeNode" }
                        | null
                    }
                  | null
              }>
            }
          | {
              __typename: "VideoMediaInfo"
              id: string
              resourceId?: string | null
              thumbnail?: string | null
              width?: number | null
              height?: number | null
              mediaType?: Types.MediaType | null
            }
          | null
      }
    | { __typename: "ProjectContent" }
    | {
        __typename: "TermContent"
        gramaticalGender?: string | null
        validity?: string | null
        editLanguage?: string | null
      }
    | {
        __typename: "TextContent"
        id: string
        xast?: string | null
        historyNumber: number
        fragments: Array<{
          __typename: "TextContentFragment"
          compoundId: string
          contentNode: {
            __typename: "ST4Node"
            label?: string | null
            id: string
            aspectId: any
            content?:
              | { __typename: "LexiconContent" }
              | { __typename: "LexiconEntryContent" }
              | { __typename: "MediaContent" }
              | { __typename: "MediaGroupContent" }
              | { __typename: "ProjectContent" }
              | { __typename: "TermContent" }
              | {
                  __typename: "TextContent"
                  id: string
                  xast?: string | null
                  historyNumber: number
                  parts: Array<{
                    __typename: "TextContentPart"
                    ref: string
                    contentNode: {
                      __typename: "ST4Node"
                      id: string
                      aspectId: any
                      content?:
                        | { __typename: "LexiconContent" }
                        | { __typename: "LexiconEntryContent" }
                        | { __typename: "MediaContent" }
                        | { __typename: "MediaGroupContent" }
                        | { __typename: "ProjectContent" }
                        | { __typename: "TermContent" }
                        | {
                            __typename: "TextContent"
                            id: string
                            xast?: string | null
                            historyNumber: number
                            links?: Array<{
                              __typename: "TextContentLink"
                              ref?: string | null
                              node?:
                                | { __typename: "CommentNode"; id: string }
                                | { __typename: "ExternalSite"; id: string }
                                | { __typename: "ST4Class"; id: string }
                                | {
                                    __typename: "ST4Node"
                                    id: string
                                    aspectId: any
                                    label?: string | null
                                    properties?: {
                                      __typename: "ContentPropertyConnection"
                                      totalCount: number
                                      edges?: Array<{
                                        __typename: "ContentPropertyEdge"
                                        node?: {
                                          __typename: "ContentProperty"
                                          typeOfValue: Types.ContentPropertyValueType
                                          name: string
                                          value?: any | null
                                          displayName: string
                                        } | null
                                      } | null> | null
                                    } | null
                                  }
                                | { __typename: "ST4TreeNode"; id: string }
                                | null
                            } | null> | null
                            images: Array<{
                              __typename: "NodeRef"
                              id: string
                              target?:
                                | { __typename: "CommentNode"; id: string }
                                | { __typename: "ExternalSite"; id: string }
                                | { __typename: "ST4Class"; id: string }
                                | {
                                    __typename: "ST4Node"
                                    aspectId: any
                                    id: string
                                    releaseState: {
                                      __typename: "ReleaseStateValue"
                                      displayName: string
                                      state: Types.ReleaseState
                                      isImplicitlyReleased: boolean
                                    }
                                    content?:
                                      | { __typename: "LexiconContent" }
                                      | { __typename: "LexiconEntryContent" }
                                      | {
                                          __typename: "MediaContent"
                                          media?:
                                            | {
                                                __typename: "AnimationMediaInfo"
                                                id: string
                                                resourceId?: string | null
                                                thumbnail?: string | null
                                                width?: number | null
                                                height?: number | null
                                                mediaType?: Types.MediaType | null
                                              }
                                            | {
                                                __typename: "ImageMediaInfo"
                                                id: string
                                                resourceId?: string | null
                                                thumbnail?: string | null
                                                width?: number | null
                                                height?: number | null
                                                resolution?: number | null
                                                colorDepth?: number | null
                                                size?: number | null
                                                mime?: string | null
                                                extension?: string | null
                                                allowCaching?: boolean | null
                                                mediaType?: Types.MediaType | null
                                                src?: string | null
                                                language?: string | null
                                              }
                                            | {
                                                __typename: "NoMediaInfo"
                                                id: string
                                                mediaType?: Types.MediaType | null
                                              }
                                            | {
                                                __typename: "VectorGraphicsMediaInfo"
                                                id: string
                                                resourceId?: string | null
                                                thumbnail?: string | null
                                                width?: number | null
                                                height?: number | null
                                                allowCaching?: boolean | null
                                                mediaType?: Types.MediaType | null
                                                language?: string | null
                                                labels: Array<{
                                                  __typename: "VectorGraphicsLabel"
                                                  id?: string | null
                                                  sequence?: number | null
                                                  autoValue?: string | null
                                                  text?: string | null
                                                  linkType?: Types.VectorGraphicsLabelLinkType | null
                                                  link?: string | null
                                                  ref?:
                                                    | {
                                                        __typename: "GenericRef"
                                                        id: string
                                                        owner?:
                                                          | { __typename: "CommentNode" }
                                                          | { __typename: "ExternalSite" }
                                                          | { __typename: "ST4Class" }
                                                          | { __typename: "ST4Node"; id: string }
                                                          | { __typename: "ST4TreeNode" }
                                                          | null
                                                        target?:
                                                          | { __typename: "CommentNode" }
                                                          | {
                                                              __typename: "ExternalSite"
                                                              id: string
                                                              teaserData?: {
                                                                __typename: "ExternalLinkTeaserData"
                                                                title?: string | null
                                                                summary?: string | null
                                                                iconUrl?: string | null
                                                                imageUrl?: string | null
                                                                status?: Types.LinkTargetStatus | null
                                                              } | null
                                                            }
                                                          | { __typename: "ST4Class" }
                                                          | {
                                                              __typename: "ST4Node"
                                                              id: string
                                                              aspectId: any
                                                              label?: string | null
                                                              properties?: {
                                                                __typename: "ContentPropertyConnection"
                                                                edges?: Array<{
                                                                  __typename: "ContentPropertyEdge"
                                                                  node?: {
                                                                    __typename: "ContentProperty"
                                                                    name: string
                                                                    value?: any | null
                                                                    displayName: string
                                                                  } | null
                                                                } | null> | null
                                                              } | null
                                                            }
                                                          | { __typename: "ST4TreeNode" }
                                                          | null
                                                      }
                                                    | {
                                                        __typename: "NodeRef"
                                                        id: string
                                                        owner?:
                                                          | { __typename: "CommentNode" }
                                                          | { __typename: "ExternalSite" }
                                                          | { __typename: "ST4Class" }
                                                          | { __typename: "ST4Node"; id: string }
                                                          | { __typename: "ST4TreeNode" }
                                                          | null
                                                        target?:
                                                          | { __typename: "CommentNode" }
                                                          | {
                                                              __typename: "ExternalSite"
                                                              id: string
                                                              teaserData?: {
                                                                __typename: "ExternalLinkTeaserData"
                                                                title?: string | null
                                                                summary?: string | null
                                                                iconUrl?: string | null
                                                                imageUrl?: string | null
                                                                status?: Types.LinkTargetStatus | null
                                                              } | null
                                                            }
                                                          | { __typename: "ST4Class" }
                                                          | {
                                                              __typename: "ST4Node"
                                                              id: string
                                                              aspectId: any
                                                              label?: string | null
                                                              properties?: {
                                                                __typename: "ContentPropertyConnection"
                                                                edges?: Array<{
                                                                  __typename: "ContentPropertyEdge"
                                                                  node?: {
                                                                    __typename: "ContentProperty"
                                                                    name: string
                                                                    value?: any | null
                                                                    displayName: string
                                                                  } | null
                                                                } | null> | null
                                                              } | null
                                                            }
                                                          | { __typename: "ST4TreeNode" }
                                                          | null
                                                      }
                                                    | null
                                                }>
                                              }
                                            | {
                                                __typename: "VideoMediaInfo"
                                                id: string
                                                resourceId?: string | null
                                                thumbnail?: string | null
                                                width?: number | null
                                                height?: number | null
                                                mediaType?: Types.MediaType | null
                                              }
                                            | null
                                        }
                                      | {
                                          __typename: "MediaGroupContent"
                                          children: Array<{
                                            __typename: "NodeRef"
                                            id: string
                                            target?:
                                              | { __typename: "CommentNode"; id: string }
                                              | { __typename: "ExternalSite"; id: string }
                                              | { __typename: "ST4Class"; id: string }
                                              | {
                                                  __typename: "ST4Node"
                                                  id: string
                                                  aspectId: any
                                                  content?:
                                                    | { __typename: "LexiconContent" }
                                                    | { __typename: "LexiconEntryContent" }
                                                    | {
                                                        __typename: "MediaContent"
                                                        media?:
                                                          | {
                                                              __typename: "AnimationMediaInfo"
                                                              id: string
                                                              resourceId?: string | null
                                                              thumbnail?: string | null
                                                              width?: number | null
                                                              height?: number | null
                                                              mediaType?: Types.MediaType | null
                                                            }
                                                          | {
                                                              __typename: "ImageMediaInfo"
                                                              id: string
                                                              resourceId?: string | null
                                                              thumbnail?: string | null
                                                              width?: number | null
                                                              height?: number | null
                                                              resolution?: number | null
                                                              colorDepth?: number | null
                                                              size?: number | null
                                                              mime?: string | null
                                                              extension?: string | null
                                                              allowCaching?: boolean | null
                                                              mediaType?: Types.MediaType | null
                                                              src?: string | null
                                                              language?: string | null
                                                            }
                                                          | {
                                                              __typename: "NoMediaInfo"
                                                              id: string
                                                              mediaType?: Types.MediaType | null
                                                            }
                                                          | {
                                                              __typename: "VectorGraphicsMediaInfo"
                                                              id: string
                                                              resourceId?: string | null
                                                              thumbnail?: string | null
                                                              width?: number | null
                                                              height?: number | null
                                                              allowCaching?: boolean | null
                                                              mediaType?: Types.MediaType | null
                                                              language?: string | null
                                                              labels: Array<{
                                                                __typename: "VectorGraphicsLabel"
                                                                id?: string | null
                                                                sequence?: number | null
                                                                autoValue?: string | null
                                                                text?: string | null
                                                                linkType?: Types.VectorGraphicsLabelLinkType | null
                                                                link?: string | null
                                                                ref?:
                                                                  | {
                                                                      __typename: "GenericRef"
                                                                      id: string
                                                                      owner?:
                                                                        | { __typename: "CommentNode" }
                                                                        | { __typename: "ExternalSite" }
                                                                        | { __typename: "ST4Class" }
                                                                        | { __typename: "ST4Node"; id: string }
                                                                        | { __typename: "ST4TreeNode" }
                                                                        | null
                                                                      target?:
                                                                        | { __typename: "CommentNode" }
                                                                        | {
                                                                            __typename: "ExternalSite"
                                                                            id: string
                                                                            teaserData?: {
                                                                              __typename: "ExternalLinkTeaserData"
                                                                              title?: string | null
                                                                              summary?: string | null
                                                                              iconUrl?: string | null
                                                                              imageUrl?: string | null
                                                                              status?: Types.LinkTargetStatus | null
                                                                            } | null
                                                                          }
                                                                        | { __typename: "ST4Class" }
                                                                        | {
                                                                            __typename: "ST4Node"
                                                                            id: string
                                                                            aspectId: any
                                                                            label?: string | null
                                                                            properties?: {
                                                                              __typename: "ContentPropertyConnection"
                                                                              edges?: Array<{
                                                                                __typename: "ContentPropertyEdge"
                                                                                node?: {
                                                                                  __typename: "ContentProperty"
                                                                                  name: string
                                                                                  value?: any | null
                                                                                  displayName: string
                                                                                } | null
                                                                              } | null> | null
                                                                            } | null
                                                                          }
                                                                        | { __typename: "ST4TreeNode" }
                                                                        | null
                                                                    }
                                                                  | {
                                                                      __typename: "NodeRef"
                                                                      id: string
                                                                      owner?:
                                                                        | { __typename: "CommentNode" }
                                                                        | { __typename: "ExternalSite" }
                                                                        | { __typename: "ST4Class" }
                                                                        | { __typename: "ST4Node"; id: string }
                                                                        | { __typename: "ST4TreeNode" }
                                                                        | null
                                                                      target?:
                                                                        | { __typename: "CommentNode" }
                                                                        | {
                                                                            __typename: "ExternalSite"
                                                                            id: string
                                                                            teaserData?: {
                                                                              __typename: "ExternalLinkTeaserData"
                                                                              title?: string | null
                                                                              summary?: string | null
                                                                              iconUrl?: string | null
                                                                              imageUrl?: string | null
                                                                              status?: Types.LinkTargetStatus | null
                                                                            } | null
                                                                          }
                                                                        | { __typename: "ST4Class" }
                                                                        | {
                                                                            __typename: "ST4Node"
                                                                            id: string
                                                                            aspectId: any
                                                                            label?: string | null
                                                                            properties?: {
                                                                              __typename: "ContentPropertyConnection"
                                                                              edges?: Array<{
                                                                                __typename: "ContentPropertyEdge"
                                                                                node?: {
                                                                                  __typename: "ContentProperty"
                                                                                  name: string
                                                                                  value?: any | null
                                                                                  displayName: string
                                                                                } | null
                                                                              } | null> | null
                                                                            } | null
                                                                          }
                                                                        | { __typename: "ST4TreeNode" }
                                                                        | null
                                                                    }
                                                                  | null
                                                              }>
                                                            }
                                                          | {
                                                              __typename: "VideoMediaInfo"
                                                              id: string
                                                              resourceId?: string | null
                                                              thumbnail?: string | null
                                                              width?: number | null
                                                              height?: number | null
                                                              mediaType?: Types.MediaType | null
                                                            }
                                                          | null
                                                      }
                                                    | {
                                                        __typename: "MediaGroupContent"
                                                        media?:
                                                          | {
                                                              __typename: "AnimationMediaInfo"
                                                              id: string
                                                              resourceId?: string | null
                                                              thumbnail?: string | null
                                                              width?: number | null
                                                              height?: number | null
                                                              mediaType?: Types.MediaType | null
                                                            }
                                                          | {
                                                              __typename: "ImageMediaInfo"
                                                              id: string
                                                              resourceId?: string | null
                                                              thumbnail?: string | null
                                                              width?: number | null
                                                              height?: number | null
                                                              resolution?: number | null
                                                              colorDepth?: number | null
                                                              size?: number | null
                                                              mime?: string | null
                                                              extension?: string | null
                                                              allowCaching?: boolean | null
                                                              mediaType?: Types.MediaType | null
                                                              src?: string | null
                                                              language?: string | null
                                                            }
                                                          | {
                                                              __typename: "NoMediaInfo"
                                                              id: string
                                                              mediaType?: Types.MediaType | null
                                                            }
                                                          | {
                                                              __typename: "VectorGraphicsMediaInfo"
                                                              id: string
                                                              resourceId?: string | null
                                                              thumbnail?: string | null
                                                              width?: number | null
                                                              height?: number | null
                                                              allowCaching?: boolean | null
                                                              mediaType?: Types.MediaType | null
                                                              language?: string | null
                                                              labels: Array<{
                                                                __typename: "VectorGraphicsLabel"
                                                                id?: string | null
                                                                sequence?: number | null
                                                                autoValue?: string | null
                                                                text?: string | null
                                                                linkType?: Types.VectorGraphicsLabelLinkType | null
                                                                link?: string | null
                                                                ref?:
                                                                  | {
                                                                      __typename: "GenericRef"
                                                                      id: string
                                                                      owner?:
                                                                        | { __typename: "CommentNode" }
                                                                        | { __typename: "ExternalSite" }
                                                                        | { __typename: "ST4Class" }
                                                                        | { __typename: "ST4Node"; id: string }
                                                                        | { __typename: "ST4TreeNode" }
                                                                        | null
                                                                      target?:
                                                                        | { __typename: "CommentNode" }
                                                                        | {
                                                                            __typename: "ExternalSite"
                                                                            id: string
                                                                            teaserData?: {
                                                                              __typename: "ExternalLinkTeaserData"
                                                                              title?: string | null
                                                                              summary?: string | null
                                                                              iconUrl?: string | null
                                                                              imageUrl?: string | null
                                                                              status?: Types.LinkTargetStatus | null
                                                                            } | null
                                                                          }
                                                                        | { __typename: "ST4Class" }
                                                                        | {
                                                                            __typename: "ST4Node"
                                                                            id: string
                                                                            aspectId: any
                                                                            label?: string | null
                                                                            properties?: {
                                                                              __typename: "ContentPropertyConnection"
                                                                              edges?: Array<{
                                                                                __typename: "ContentPropertyEdge"
                                                                                node?: {
                                                                                  __typename: "ContentProperty"
                                                                                  name: string
                                                                                  value?: any | null
                                                                                  displayName: string
                                                                                } | null
                                                                              } | null> | null
                                                                            } | null
                                                                          }
                                                                        | { __typename: "ST4TreeNode" }
                                                                        | null
                                                                    }
                                                                  | {
                                                                      __typename: "NodeRef"
                                                                      id: string
                                                                      owner?:
                                                                        | { __typename: "CommentNode" }
                                                                        | { __typename: "ExternalSite" }
                                                                        | { __typename: "ST4Class" }
                                                                        | { __typename: "ST4Node"; id: string }
                                                                        | { __typename: "ST4TreeNode" }
                                                                        | null
                                                                      target?:
                                                                        | { __typename: "CommentNode" }
                                                                        | {
                                                                            __typename: "ExternalSite"
                                                                            id: string
                                                                            teaserData?: {
                                                                              __typename: "ExternalLinkTeaserData"
                                                                              title?: string | null
                                                                              summary?: string | null
                                                                              iconUrl?: string | null
                                                                              imageUrl?: string | null
                                                                              status?: Types.LinkTargetStatus | null
                                                                            } | null
                                                                          }
                                                                        | { __typename: "ST4Class" }
                                                                        | {
                                                                            __typename: "ST4Node"
                                                                            id: string
                                                                            aspectId: any
                                                                            label?: string | null
                                                                            properties?: {
                                                                              __typename: "ContentPropertyConnection"
                                                                              edges?: Array<{
                                                                                __typename: "ContentPropertyEdge"
                                                                                node?: {
                                                                                  __typename: "ContentProperty"
                                                                                  name: string
                                                                                  value?: any | null
                                                                                  displayName: string
                                                                                } | null
                                                                              } | null> | null
                                                                            } | null
                                                                          }
                                                                        | { __typename: "ST4TreeNode" }
                                                                        | null
                                                                    }
                                                                  | null
                                                              }>
                                                            }
                                                          | {
                                                              __typename: "VideoMediaInfo"
                                                              id: string
                                                              resourceId?: string | null
                                                              thumbnail?: string | null
                                                              width?: number | null
                                                              height?: number | null
                                                              mediaType?: Types.MediaType | null
                                                            }
                                                          | null
                                                      }
                                                    | { __typename: "ProjectContent" }
                                                    | { __typename: "TermContent" }
                                                    | { __typename: "TextContent" }
                                                    | { __typename: "TextGroupContent" }
                                                    | { __typename: "VariableTableContent" }
                                                    | null
                                                }
                                              | { __typename: "ST4TreeNode"; id: string }
                                              | null
                                          }>
                                          media?:
                                            | {
                                                __typename: "AnimationMediaInfo"
                                                id: string
                                                resourceId?: string | null
                                                thumbnail?: string | null
                                                width?: number | null
                                                height?: number | null
                                                mediaType?: Types.MediaType | null
                                              }
                                            | {
                                                __typename: "ImageMediaInfo"
                                                id: string
                                                resourceId?: string | null
                                                thumbnail?: string | null
                                                width?: number | null
                                                height?: number | null
                                                resolution?: number | null
                                                colorDepth?: number | null
                                                size?: number | null
                                                mime?: string | null
                                                extension?: string | null
                                                allowCaching?: boolean | null
                                                mediaType?: Types.MediaType | null
                                                src?: string | null
                                                language?: string | null
                                              }
                                            | {
                                                __typename: "NoMediaInfo"
                                                id: string
                                                mediaType?: Types.MediaType | null
                                              }
                                            | {
                                                __typename: "VectorGraphicsMediaInfo"
                                                id: string
                                                resourceId?: string | null
                                                thumbnail?: string | null
                                                width?: number | null
                                                height?: number | null
                                                allowCaching?: boolean | null
                                                mediaType?: Types.MediaType | null
                                                language?: string | null
                                                labels: Array<{
                                                  __typename: "VectorGraphicsLabel"
                                                  id?: string | null
                                                  sequence?: number | null
                                                  autoValue?: string | null
                                                  text?: string | null
                                                  linkType?: Types.VectorGraphicsLabelLinkType | null
                                                  link?: string | null
                                                  ref?:
                                                    | {
                                                        __typename: "GenericRef"
                                                        id: string
                                                        owner?:
                                                          | { __typename: "CommentNode" }
                                                          | { __typename: "ExternalSite" }
                                                          | { __typename: "ST4Class" }
                                                          | { __typename: "ST4Node"; id: string }
                                                          | { __typename: "ST4TreeNode" }
                                                          | null
                                                        target?:
                                                          | { __typename: "CommentNode" }
                                                          | {
                                                              __typename: "ExternalSite"
                                                              id: string
                                                              teaserData?: {
                                                                __typename: "ExternalLinkTeaserData"
                                                                title?: string | null
                                                                summary?: string | null
                                                                iconUrl?: string | null
                                                                imageUrl?: string | null
                                                                status?: Types.LinkTargetStatus | null
                                                              } | null
                                                            }
                                                          | { __typename: "ST4Class" }
                                                          | {
                                                              __typename: "ST4Node"
                                                              id: string
                                                              aspectId: any
                                                              label?: string | null
                                                              properties?: {
                                                                __typename: "ContentPropertyConnection"
                                                                edges?: Array<{
                                                                  __typename: "ContentPropertyEdge"
                                                                  node?: {
                                                                    __typename: "ContentProperty"
                                                                    name: string
                                                                    value?: any | null
                                                                    displayName: string
                                                                  } | null
                                                                } | null> | null
                                                              } | null
                                                            }
                                                          | { __typename: "ST4TreeNode" }
                                                          | null
                                                      }
                                                    | {
                                                        __typename: "NodeRef"
                                                        id: string
                                                        owner?:
                                                          | { __typename: "CommentNode" }
                                                          | { __typename: "ExternalSite" }
                                                          | { __typename: "ST4Class" }
                                                          | { __typename: "ST4Node"; id: string }
                                                          | { __typename: "ST4TreeNode" }
                                                          | null
                                                        target?:
                                                          | { __typename: "CommentNode" }
                                                          | {
                                                              __typename: "ExternalSite"
                                                              id: string
                                                              teaserData?: {
                                                                __typename: "ExternalLinkTeaserData"
                                                                title?: string | null
                                                                summary?: string | null
                                                                iconUrl?: string | null
                                                                imageUrl?: string | null
                                                                status?: Types.LinkTargetStatus | null
                                                              } | null
                                                            }
                                                          | { __typename: "ST4Class" }
                                                          | {
                                                              __typename: "ST4Node"
                                                              id: string
                                                              aspectId: any
                                                              label?: string | null
                                                              properties?: {
                                                                __typename: "ContentPropertyConnection"
                                                                edges?: Array<{
                                                                  __typename: "ContentPropertyEdge"
                                                                  node?: {
                                                                    __typename: "ContentProperty"
                                                                    name: string
                                                                    value?: any | null
                                                                    displayName: string
                                                                  } | null
                                                                } | null> | null
                                                              } | null
                                                            }
                                                          | { __typename: "ST4TreeNode" }
                                                          | null
                                                      }
                                                    | null
                                                }>
                                              }
                                            | {
                                                __typename: "VideoMediaInfo"
                                                id: string
                                                resourceId?: string | null
                                                thumbnail?: string | null
                                                width?: number | null
                                                height?: number | null
                                                mediaType?: Types.MediaType | null
                                              }
                                            | null
                                        }
                                      | { __typename: "ProjectContent" }
                                      | { __typename: "TermContent" }
                                      | { __typename: "TextContent" }
                                      | { __typename: "TextGroupContent" }
                                      | { __typename: "VariableTableContent" }
                                      | null
                                  }
                                | { __typename: "ST4TreeNode"; id: string }
                                | null
                            }>
                            variableTables?: Array<{
                              __typename: "VariableTableReference"
                              ref?: string | null
                              node?:
                                | { __typename: "CommentNode" }
                                | { __typename: "ExternalSite" }
                                | { __typename: "ST4Class" }
                                | {
                                    __typename: "ST4Node"
                                    id: string
                                    label?: string | null
                                    aspectId: any
                                    content?:
                                      | { __typename: "LexiconContent" }
                                      | { __typename: "LexiconEntryContent" }
                                      | { __typename: "MediaContent" }
                                      | { __typename: "MediaGroupContent" }
                                      | { __typename: "ProjectContent" }
                                      | { __typename: "TermContent" }
                                      | { __typename: "TextContent" }
                                      | { __typename: "TextGroupContent" }
                                      | {
                                          __typename: "VariableTableContent"
                                          xml?: string | null
                                          valueSets: Array<{ __typename: "ValueSet"; name: string; valueSetId: string }>
                                          variables: Array<{
                                            __typename: "Variable"
                                            name: string
                                            variableId: string
                                            values: Array<string>
                                          }>
                                        }
                                      | null
                                  }
                                | { __typename: "ST4TreeNode" }
                                | null
                            } | null> | null
                            linkedDataNodes: Array<{
                              __typename: "NodeRef"
                              id: string
                              target?:
                                | { __typename: "CommentNode"; id: string }
                                | { __typename: "ExternalSite"; id: string }
                                | { __typename: "ST4Class"; id: string }
                                | {
                                    __typename: "ST4Node"
                                    label?: string | null
                                    id: string
                                    metadata: Array<{
                                      __typename: "MetaDataEntry"
                                      label?: string | null
                                      systemName: string
                                      value?: string | null
                                      values: Array<string>
                                      users: Array<string>
                                    }>
                                    linkedOntologyNodes: Array<
                                      | {
                                          __typename: "GenericRef"
                                          target?:
                                            | { __typename: "CommentNode" }
                                            | { __typename: "ExternalSite" }
                                            | { __typename: "ST4Class" }
                                            | { __typename: "ST4Node"; id: string; label?: string | null }
                                            | { __typename: "ST4TreeNode" }
                                            | null
                                        }
                                      | {
                                          __typename: "NodeRef"
                                          linkClassName: string
                                          class?: { __typename: "ST4Class"; label?: string | null } | null
                                          target?:
                                            | { __typename: "CommentNode" }
                                            | { __typename: "ExternalSite" }
                                            | { __typename: "ST4Class" }
                                            | { __typename: "ST4Node"; id: string; label?: string | null }
                                            | { __typename: "ST4TreeNode" }
                                            | null
                                        }
                                    >
                                  }
                                | { __typename: "ST4TreeNode"; id: string }
                                | null
                            }>
                            lexiconEntries?: Array<{
                              __typename: "NodeRef"
                              id: string
                              target?:
                                | { __typename: "CommentNode"; id: string }
                                | { __typename: "ExternalSite"; id: string }
                                | { __typename: "ST4Class"; id: string }
                                | {
                                    __typename: "ST4Node"
                                    aspectId: any
                                    label?: string | null
                                    id: string
                                    content?:
                                      | { __typename: "LexiconContent" }
                                      | {
                                          __typename: "LexiconEntryContent"
                                          descriptionXast?: string | null
                                          subject?: string | null
                                          sortKey?: string | null
                                          lexicon:
                                            | { __typename: "CommentNode"; id: string }
                                            | { __typename: "ExternalSite"; id: string }
                                            | { __typename: "ST4Class"; id: string }
                                            | { __typename: "ST4Node"; label?: string | null; id: string }
                                            | { __typename: "ST4TreeNode"; id: string }
                                          terms: Array<
                                            | { __typename: "CommentNode"; id: string }
                                            | { __typename: "ExternalSite"; id: string }
                                            | { __typename: "ST4Class"; id: string }
                                            | {
                                                __typename: "ST4Node"
                                                id: string
                                                label?: string | null
                                                aspectId: any
                                                content?:
                                                  | { __typename: "LexiconContent" }
                                                  | { __typename: "LexiconEntryContent" }
                                                  | { __typename: "MediaContent" }
                                                  | { __typename: "MediaGroupContent" }
                                                  | { __typename: "ProjectContent" }
                                                  | {
                                                      __typename: "TermContent"
                                                      gramaticalGender?: string | null
                                                      validity?: string | null
                                                      editLanguage?: string | null
                                                    }
                                                  | { __typename: "TextContent" }
                                                  | { __typename: "TextGroupContent" }
                                                  | { __typename: "VariableTableContent" }
                                                  | null
                                              }
                                            | { __typename: "ST4TreeNode"; id: string }
                                          >
                                          image?:
                                            | { __typename: "CommentNode"; id: string }
                                            | { __typename: "ExternalSite"; id: string }
                                            | { __typename: "ST4Class"; id: string }
                                            | {
                                                __typename: "ST4Node"
                                                aspectId: any
                                                id: string
                                                content?:
                                                  | { __typename: "LexiconContent" }
                                                  | { __typename: "LexiconEntryContent" }
                                                  | {
                                                      __typename: "MediaContent"
                                                      media?:
                                                        | {
                                                            __typename: "AnimationMediaInfo"
                                                            thumbnail?: string | null
                                                            mediaType?: Types.MediaType | null
                                                          }
                                                        | {
                                                            __typename: "ImageMediaInfo"
                                                            src?: string | null
                                                            thumbnail?: string | null
                                                            mediaType?: Types.MediaType | null
                                                          }
                                                        | { __typename: "NoMediaInfo" }
                                                        | {
                                                            __typename: "VectorGraphicsMediaInfo"
                                                            svg?: string | null
                                                            thumbnail?: string | null
                                                            mediaType?: Types.MediaType | null
                                                          }
                                                        | {
                                                            __typename: "VideoMediaInfo"
                                                            thumbnail?: string | null
                                                            mediaType?: Types.MediaType | null
                                                          }
                                                        | null
                                                    }
                                                  | { __typename: "MediaGroupContent" }
                                                  | { __typename: "ProjectContent" }
                                                  | { __typename: "TermContent" }
                                                  | { __typename: "TextContent" }
                                                  | { __typename: "TextGroupContent" }
                                                  | { __typename: "VariableTableContent" }
                                                  | null
                                              }
                                            | { __typename: "ST4TreeNode"; id: string }
                                            | null
                                        }
                                      | { __typename: "MediaContent" }
                                      | { __typename: "MediaGroupContent" }
                                      | { __typename: "ProjectContent" }
                                      | { __typename: "TermContent" }
                                      | { __typename: "TextContent" }
                                      | { __typename: "TextGroupContent" }
                                      | { __typename: "VariableTableContent" }
                                      | null
                                  }
                                | { __typename: "ST4TreeNode"; id: string }
                                | null
                            } | null> | null
                            comments: Array<{
                              __typename: "CommentNode"
                              id: string
                              comment:
                                | {
                                    __typename: "BlockComment"
                                    location: number
                                    value?: string | null
                                    authorDisplayName?: string | null
                                    authorUserName?: string | null
                                    commentKey: string
                                    editDate?: any | null
                                    modificationVersion?: number | null
                                    replyToKey?: string | null
                                    sortOrder?: number | null
                                    commentType?: string | null
                                    state?: string | null
                                    color: string
                                    persistanceState?: Types.PersistanceState | null
                                    persistanceAction?: Types.PersistanceAction | null
                                    node: {
                                      __typename: "ST4Node"
                                      aspectId: any
                                      id: string
                                      content?:
                                        | { __typename: "LexiconContent" }
                                        | { __typename: "LexiconEntryContent" }
                                        | { __typename: "MediaContent" }
                                        | { __typename: "MediaGroupContent" }
                                        | { __typename: "ProjectContent" }
                                        | { __typename: "TermContent" }
                                        | { __typename: "TextContent"; id: string; historyNumber: number }
                                        | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                                        | { __typename: "VariableTableContent" }
                                        | null
                                    }
                                    actions: Array<{
                                      __typename: "Action"
                                      name?: string | null
                                      enabled?: boolean | null
                                    }>
                                  }
                                | {
                                    __typename: "TextComment"
                                    endLocation: number
                                    startLocation?: number | null
                                    value?: string | null
                                    authorDisplayName?: string | null
                                    authorUserName?: string | null
                                    commentKey: string
                                    editDate?: any | null
                                    modificationVersion?: number | null
                                    replyToKey?: string | null
                                    sortOrder?: number | null
                                    commentType?: string | null
                                    state?: string | null
                                    color: string
                                    persistanceState?: Types.PersistanceState | null
                                    persistanceAction?: Types.PersistanceAction | null
                                    node: {
                                      __typename: "ST4Node"
                                      aspectId: any
                                      id: string
                                      content?:
                                        | { __typename: "LexiconContent" }
                                        | { __typename: "LexiconEntryContent" }
                                        | { __typename: "MediaContent" }
                                        | { __typename: "MediaGroupContent" }
                                        | { __typename: "ProjectContent" }
                                        | { __typename: "TermContent" }
                                        | { __typename: "TextContent"; id: string; historyNumber: number }
                                        | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                                        | { __typename: "VariableTableContent" }
                                        | null
                                    }
                                    actions: Array<{
                                      __typename: "Action"
                                      name?: string | null
                                      enabled?: boolean | null
                                    }>
                                  }
                            }>
                          }
                        | {
                            __typename: "TextGroupContent"
                            currentNodeId?: any | null
                            groupNodeId: any
                            id: string
                            xast?: string | null
                            historyNumber: number
                            children: Array<{
                              __typename: "NodeRef"
                              id: string
                              target?:
                                | { __typename: "CommentNode"; id: string }
                                | { __typename: "ExternalSite"; id: string }
                                | { __typename: "ST4Class"; id: string }
                                | { __typename: "ST4Node"; label?: string | null; aspectId: any; id: string }
                                | { __typename: "ST4TreeNode"; id: string }
                                | null
                            }>
                            links?: Array<{
                              __typename: "TextContentLink"
                              ref?: string | null
                              node?:
                                | { __typename: "CommentNode"; id: string }
                                | { __typename: "ExternalSite"; id: string }
                                | { __typename: "ST4Class"; id: string }
                                | {
                                    __typename: "ST4Node"
                                    id: string
                                    aspectId: any
                                    label?: string | null
                                    properties?: {
                                      __typename: "ContentPropertyConnection"
                                      totalCount: number
                                      edges?: Array<{
                                        __typename: "ContentPropertyEdge"
                                        node?: {
                                          __typename: "ContentProperty"
                                          typeOfValue: Types.ContentPropertyValueType
                                          name: string
                                          value?: any | null
                                          displayName: string
                                        } | null
                                      } | null> | null
                                    } | null
                                  }
                                | { __typename: "ST4TreeNode"; id: string }
                                | null
                            } | null> | null
                            images: Array<{
                              __typename: "NodeRef"
                              id: string
                              target?:
                                | { __typename: "CommentNode"; id: string }
                                | { __typename: "ExternalSite"; id: string }
                                | { __typename: "ST4Class"; id: string }
                                | {
                                    __typename: "ST4Node"
                                    aspectId: any
                                    id: string
                                    releaseState: {
                                      __typename: "ReleaseStateValue"
                                      displayName: string
                                      state: Types.ReleaseState
                                      isImplicitlyReleased: boolean
                                    }
                                    content?:
                                      | { __typename: "LexiconContent" }
                                      | { __typename: "LexiconEntryContent" }
                                      | {
                                          __typename: "MediaContent"
                                          media?:
                                            | {
                                                __typename: "AnimationMediaInfo"
                                                id: string
                                                resourceId?: string | null
                                                thumbnail?: string | null
                                                width?: number | null
                                                height?: number | null
                                                mediaType?: Types.MediaType | null
                                              }
                                            | {
                                                __typename: "ImageMediaInfo"
                                                id: string
                                                resourceId?: string | null
                                                thumbnail?: string | null
                                                width?: number | null
                                                height?: number | null
                                                resolution?: number | null
                                                colorDepth?: number | null
                                                size?: number | null
                                                mime?: string | null
                                                extension?: string | null
                                                allowCaching?: boolean | null
                                                mediaType?: Types.MediaType | null
                                                src?: string | null
                                                language?: string | null
                                              }
                                            | {
                                                __typename: "NoMediaInfo"
                                                id: string
                                                mediaType?: Types.MediaType | null
                                              }
                                            | {
                                                __typename: "VectorGraphicsMediaInfo"
                                                id: string
                                                resourceId?: string | null
                                                thumbnail?: string | null
                                                width?: number | null
                                                height?: number | null
                                                allowCaching?: boolean | null
                                                mediaType?: Types.MediaType | null
                                                language?: string | null
                                                labels: Array<{
                                                  __typename: "VectorGraphicsLabel"
                                                  id?: string | null
                                                  sequence?: number | null
                                                  autoValue?: string | null
                                                  text?: string | null
                                                  linkType?: Types.VectorGraphicsLabelLinkType | null
                                                  link?: string | null
                                                  ref?:
                                                    | {
                                                        __typename: "GenericRef"
                                                        id: string
                                                        owner?:
                                                          | { __typename: "CommentNode" }
                                                          | { __typename: "ExternalSite" }
                                                          | { __typename: "ST4Class" }
                                                          | { __typename: "ST4Node"; id: string }
                                                          | { __typename: "ST4TreeNode" }
                                                          | null
                                                        target?:
                                                          | { __typename: "CommentNode" }
                                                          | {
                                                              __typename: "ExternalSite"
                                                              id: string
                                                              teaserData?: {
                                                                __typename: "ExternalLinkTeaserData"
                                                                title?: string | null
                                                                summary?: string | null
                                                                iconUrl?: string | null
                                                                imageUrl?: string | null
                                                                status?: Types.LinkTargetStatus | null
                                                              } | null
                                                            }
                                                          | { __typename: "ST4Class" }
                                                          | {
                                                              __typename: "ST4Node"
                                                              id: string
                                                              aspectId: any
                                                              label?: string | null
                                                              properties?: {
                                                                __typename: "ContentPropertyConnection"
                                                                edges?: Array<{
                                                                  __typename: "ContentPropertyEdge"
                                                                  node?: {
                                                                    __typename: "ContentProperty"
                                                                    name: string
                                                                    value?: any | null
                                                                    displayName: string
                                                                  } | null
                                                                } | null> | null
                                                              } | null
                                                            }
                                                          | { __typename: "ST4TreeNode" }
                                                          | null
                                                      }
                                                    | {
                                                        __typename: "NodeRef"
                                                        id: string
                                                        owner?:
                                                          | { __typename: "CommentNode" }
                                                          | { __typename: "ExternalSite" }
                                                          | { __typename: "ST4Class" }
                                                          | { __typename: "ST4Node"; id: string }
                                                          | { __typename: "ST4TreeNode" }
                                                          | null
                                                        target?:
                                                          | { __typename: "CommentNode" }
                                                          | {
                                                              __typename: "ExternalSite"
                                                              id: string
                                                              teaserData?: {
                                                                __typename: "ExternalLinkTeaserData"
                                                                title?: string | null
                                                                summary?: string | null
                                                                iconUrl?: string | null
                                                                imageUrl?: string | null
                                                                status?: Types.LinkTargetStatus | null
                                                              } | null
                                                            }
                                                          | { __typename: "ST4Class" }
                                                          | {
                                                              __typename: "ST4Node"
                                                              id: string
                                                              aspectId: any
                                                              label?: string | null
                                                              properties?: {
                                                                __typename: "ContentPropertyConnection"
                                                                edges?: Array<{
                                                                  __typename: "ContentPropertyEdge"
                                                                  node?: {
                                                                    __typename: "ContentProperty"
                                                                    name: string
                                                                    value?: any | null
                                                                    displayName: string
                                                                  } | null
                                                                } | null> | null
                                                              } | null
                                                            }
                                                          | { __typename: "ST4TreeNode" }
                                                          | null
                                                      }
                                                    | null
                                                }>
                                              }
                                            | {
                                                __typename: "VideoMediaInfo"
                                                id: string
                                                resourceId?: string | null
                                                thumbnail?: string | null
                                                width?: number | null
                                                height?: number | null
                                                mediaType?: Types.MediaType | null
                                              }
                                            | null
                                        }
                                      | {
                                          __typename: "MediaGroupContent"
                                          children: Array<{
                                            __typename: "NodeRef"
                                            id: string
                                            target?:
                                              | { __typename: "CommentNode"; id: string }
                                              | { __typename: "ExternalSite"; id: string }
                                              | { __typename: "ST4Class"; id: string }
                                              | {
                                                  __typename: "ST4Node"
                                                  id: string
                                                  aspectId: any
                                                  content?:
                                                    | { __typename: "LexiconContent" }
                                                    | { __typename: "LexiconEntryContent" }
                                                    | {
                                                        __typename: "MediaContent"
                                                        media?:
                                                          | {
                                                              __typename: "AnimationMediaInfo"
                                                              id: string
                                                              resourceId?: string | null
                                                              thumbnail?: string | null
                                                              width?: number | null
                                                              height?: number | null
                                                              mediaType?: Types.MediaType | null
                                                            }
                                                          | {
                                                              __typename: "ImageMediaInfo"
                                                              id: string
                                                              resourceId?: string | null
                                                              thumbnail?: string | null
                                                              width?: number | null
                                                              height?: number | null
                                                              resolution?: number | null
                                                              colorDepth?: number | null
                                                              size?: number | null
                                                              mime?: string | null
                                                              extension?: string | null
                                                              allowCaching?: boolean | null
                                                              mediaType?: Types.MediaType | null
                                                              src?: string | null
                                                              language?: string | null
                                                            }
                                                          | {
                                                              __typename: "NoMediaInfo"
                                                              id: string
                                                              mediaType?: Types.MediaType | null
                                                            }
                                                          | {
                                                              __typename: "VectorGraphicsMediaInfo"
                                                              id: string
                                                              resourceId?: string | null
                                                              thumbnail?: string | null
                                                              width?: number | null
                                                              height?: number | null
                                                              allowCaching?: boolean | null
                                                              mediaType?: Types.MediaType | null
                                                              language?: string | null
                                                              labels: Array<{
                                                                __typename: "VectorGraphicsLabel"
                                                                id?: string | null
                                                                sequence?: number | null
                                                                autoValue?: string | null
                                                                text?: string | null
                                                                linkType?: Types.VectorGraphicsLabelLinkType | null
                                                                link?: string | null
                                                                ref?:
                                                                  | {
                                                                      __typename: "GenericRef"
                                                                      id: string
                                                                      owner?:
                                                                        | { __typename: "CommentNode" }
                                                                        | { __typename: "ExternalSite" }
                                                                        | { __typename: "ST4Class" }
                                                                        | { __typename: "ST4Node"; id: string }
                                                                        | { __typename: "ST4TreeNode" }
                                                                        | null
                                                                      target?:
                                                                        | { __typename: "CommentNode" }
                                                                        | {
                                                                            __typename: "ExternalSite"
                                                                            id: string
                                                                            teaserData?: {
                                                                              __typename: "ExternalLinkTeaserData"
                                                                              title?: string | null
                                                                              summary?: string | null
                                                                              iconUrl?: string | null
                                                                              imageUrl?: string | null
                                                                              status?: Types.LinkTargetStatus | null
                                                                            } | null
                                                                          }
                                                                        | { __typename: "ST4Class" }
                                                                        | {
                                                                            __typename: "ST4Node"
                                                                            id: string
                                                                            aspectId: any
                                                                            label?: string | null
                                                                            properties?: {
                                                                              __typename: "ContentPropertyConnection"
                                                                              edges?: Array<{
                                                                                __typename: "ContentPropertyEdge"
                                                                                node?: {
                                                                                  __typename: "ContentProperty"
                                                                                  name: string
                                                                                  value?: any | null
                                                                                  displayName: string
                                                                                } | null
                                                                              } | null> | null
                                                                            } | null
                                                                          }
                                                                        | { __typename: "ST4TreeNode" }
                                                                        | null
                                                                    }
                                                                  | {
                                                                      __typename: "NodeRef"
                                                                      id: string
                                                                      owner?:
                                                                        | { __typename: "CommentNode" }
                                                                        | { __typename: "ExternalSite" }
                                                                        | { __typename: "ST4Class" }
                                                                        | { __typename: "ST4Node"; id: string }
                                                                        | { __typename: "ST4TreeNode" }
                                                                        | null
                                                                      target?:
                                                                        | { __typename: "CommentNode" }
                                                                        | {
                                                                            __typename: "ExternalSite"
                                                                            id: string
                                                                            teaserData?: {
                                                                              __typename: "ExternalLinkTeaserData"
                                                                              title?: string | null
                                                                              summary?: string | null
                                                                              iconUrl?: string | null
                                                                              imageUrl?: string | null
                                                                              status?: Types.LinkTargetStatus | null
                                                                            } | null
                                                                          }
                                                                        | { __typename: "ST4Class" }
                                                                        | {
                                                                            __typename: "ST4Node"
                                                                            id: string
                                                                            aspectId: any
                                                                            label?: string | null
                                                                            properties?: {
                                                                              __typename: "ContentPropertyConnection"
                                                                              edges?: Array<{
                                                                                __typename: "ContentPropertyEdge"
                                                                                node?: {
                                                                                  __typename: "ContentProperty"
                                                                                  name: string
                                                                                  value?: any | null
                                                                                  displayName: string
                                                                                } | null
                                                                              } | null> | null
                                                                            } | null
                                                                          }
                                                                        | { __typename: "ST4TreeNode" }
                                                                        | null
                                                                    }
                                                                  | null
                                                              }>
                                                            }
                                                          | {
                                                              __typename: "VideoMediaInfo"
                                                              id: string
                                                              resourceId?: string | null
                                                              thumbnail?: string | null
                                                              width?: number | null
                                                              height?: number | null
                                                              mediaType?: Types.MediaType | null
                                                            }
                                                          | null
                                                      }
                                                    | {
                                                        __typename: "MediaGroupContent"
                                                        media?:
                                                          | {
                                                              __typename: "AnimationMediaInfo"
                                                              id: string
                                                              resourceId?: string | null
                                                              thumbnail?: string | null
                                                              width?: number | null
                                                              height?: number | null
                                                              mediaType?: Types.MediaType | null
                                                            }
                                                          | {
                                                              __typename: "ImageMediaInfo"
                                                              id: string
                                                              resourceId?: string | null
                                                              thumbnail?: string | null
                                                              width?: number | null
                                                              height?: number | null
                                                              resolution?: number | null
                                                              colorDepth?: number | null
                                                              size?: number | null
                                                              mime?: string | null
                                                              extension?: string | null
                                                              allowCaching?: boolean | null
                                                              mediaType?: Types.MediaType | null
                                                              src?: string | null
                                                              language?: string | null
                                                            }
                                                          | {
                                                              __typename: "NoMediaInfo"
                                                              id: string
                                                              mediaType?: Types.MediaType | null
                                                            }
                                                          | {
                                                              __typename: "VectorGraphicsMediaInfo"
                                                              id: string
                                                              resourceId?: string | null
                                                              thumbnail?: string | null
                                                              width?: number | null
                                                              height?: number | null
                                                              allowCaching?: boolean | null
                                                              mediaType?: Types.MediaType | null
                                                              language?: string | null
                                                              labels: Array<{
                                                                __typename: "VectorGraphicsLabel"
                                                                id?: string | null
                                                                sequence?: number | null
                                                                autoValue?: string | null
                                                                text?: string | null
                                                                linkType?: Types.VectorGraphicsLabelLinkType | null
                                                                link?: string | null
                                                                ref?:
                                                                  | {
                                                                      __typename: "GenericRef"
                                                                      id: string
                                                                      owner?:
                                                                        | { __typename: "CommentNode" }
                                                                        | { __typename: "ExternalSite" }
                                                                        | { __typename: "ST4Class" }
                                                                        | { __typename: "ST4Node"; id: string }
                                                                        | { __typename: "ST4TreeNode" }
                                                                        | null
                                                                      target?:
                                                                        | { __typename: "CommentNode" }
                                                                        | {
                                                                            __typename: "ExternalSite"
                                                                            id: string
                                                                            teaserData?: {
                                                                              __typename: "ExternalLinkTeaserData"
                                                                              title?: string | null
                                                                              summary?: string | null
                                                                              iconUrl?: string | null
                                                                              imageUrl?: string | null
                                                                              status?: Types.LinkTargetStatus | null
                                                                            } | null
                                                                          }
                                                                        | { __typename: "ST4Class" }
                                                                        | {
                                                                            __typename: "ST4Node"
                                                                            id: string
                                                                            aspectId: any
                                                                            label?: string | null
                                                                            properties?: {
                                                                              __typename: "ContentPropertyConnection"
                                                                              edges?: Array<{
                                                                                __typename: "ContentPropertyEdge"
                                                                                node?: {
                                                                                  __typename: "ContentProperty"
                                                                                  name: string
                                                                                  value?: any | null
                                                                                  displayName: string
                                                                                } | null
                                                                              } | null> | null
                                                                            } | null
                                                                          }
                                                                        | { __typename: "ST4TreeNode" }
                                                                        | null
                                                                    }
                                                                  | {
                                                                      __typename: "NodeRef"
                                                                      id: string
                                                                      owner?:
                                                                        | { __typename: "CommentNode" }
                                                                        | { __typename: "ExternalSite" }
                                                                        | { __typename: "ST4Class" }
                                                                        | { __typename: "ST4Node"; id: string }
                                                                        | { __typename: "ST4TreeNode" }
                                                                        | null
                                                                      target?:
                                                                        | { __typename: "CommentNode" }
                                                                        | {
                                                                            __typename: "ExternalSite"
                                                                            id: string
                                                                            teaserData?: {
                                                                              __typename: "ExternalLinkTeaserData"
                                                                              title?: string | null
                                                                              summary?: string | null
                                                                              iconUrl?: string | null
                                                                              imageUrl?: string | null
                                                                              status?: Types.LinkTargetStatus | null
                                                                            } | null
                                                                          }
                                                                        | { __typename: "ST4Class" }
                                                                        | {
                                                                            __typename: "ST4Node"
                                                                            id: string
                                                                            aspectId: any
                                                                            label?: string | null
                                                                            properties?: {
                                                                              __typename: "ContentPropertyConnection"
                                                                              edges?: Array<{
                                                                                __typename: "ContentPropertyEdge"
                                                                                node?: {
                                                                                  __typename: "ContentProperty"
                                                                                  name: string
                                                                                  value?: any | null
                                                                                  displayName: string
                                                                                } | null
                                                                              } | null> | null
                                                                            } | null
                                                                          }
                                                                        | { __typename: "ST4TreeNode" }
                                                                        | null
                                                                    }
                                                                  | null
                                                              }>
                                                            }
                                                          | {
                                                              __typename: "VideoMediaInfo"
                                                              id: string
                                                              resourceId?: string | null
                                                              thumbnail?: string | null
                                                              width?: number | null
                                                              height?: number | null
                                                              mediaType?: Types.MediaType | null
                                                            }
                                                          | null
                                                      }
                                                    | { __typename: "ProjectContent" }
                                                    | { __typename: "TermContent" }
                                                    | { __typename: "TextContent" }
                                                    | { __typename: "TextGroupContent" }
                                                    | { __typename: "VariableTableContent" }
                                                    | null
                                                }
                                              | { __typename: "ST4TreeNode"; id: string }
                                              | null
                                          }>
                                          media?:
                                            | {
                                                __typename: "AnimationMediaInfo"
                                                id: string
                                                resourceId?: string | null
                                                thumbnail?: string | null
                                                width?: number | null
                                                height?: number | null
                                                mediaType?: Types.MediaType | null
                                              }
                                            | {
                                                __typename: "ImageMediaInfo"
                                                id: string
                                                resourceId?: string | null
                                                thumbnail?: string | null
                                                width?: number | null
                                                height?: number | null
                                                resolution?: number | null
                                                colorDepth?: number | null
                                                size?: number | null
                                                mime?: string | null
                                                extension?: string | null
                                                allowCaching?: boolean | null
                                                mediaType?: Types.MediaType | null
                                                src?: string | null
                                                language?: string | null
                                              }
                                            | {
                                                __typename: "NoMediaInfo"
                                                id: string
                                                mediaType?: Types.MediaType | null
                                              }
                                            | {
                                                __typename: "VectorGraphicsMediaInfo"
                                                id: string
                                                resourceId?: string | null
                                                thumbnail?: string | null
                                                width?: number | null
                                                height?: number | null
                                                allowCaching?: boolean | null
                                                mediaType?: Types.MediaType | null
                                                language?: string | null
                                                labels: Array<{
                                                  __typename: "VectorGraphicsLabel"
                                                  id?: string | null
                                                  sequence?: number | null
                                                  autoValue?: string | null
                                                  text?: string | null
                                                  linkType?: Types.VectorGraphicsLabelLinkType | null
                                                  link?: string | null
                                                  ref?:
                                                    | {
                                                        __typename: "GenericRef"
                                                        id: string
                                                        owner?:
                                                          | { __typename: "CommentNode" }
                                                          | { __typename: "ExternalSite" }
                                                          | { __typename: "ST4Class" }
                                                          | { __typename: "ST4Node"; id: string }
                                                          | { __typename: "ST4TreeNode" }
                                                          | null
                                                        target?:
                                                          | { __typename: "CommentNode" }
                                                          | {
                                                              __typename: "ExternalSite"
                                                              id: string
                                                              teaserData?: {
                                                                __typename: "ExternalLinkTeaserData"
                                                                title?: string | null
                                                                summary?: string | null
                                                                iconUrl?: string | null
                                                                imageUrl?: string | null
                                                                status?: Types.LinkTargetStatus | null
                                                              } | null
                                                            }
                                                          | { __typename: "ST4Class" }
                                                          | {
                                                              __typename: "ST4Node"
                                                              id: string
                                                              aspectId: any
                                                              label?: string | null
                                                              properties?: {
                                                                __typename: "ContentPropertyConnection"
                                                                edges?: Array<{
                                                                  __typename: "ContentPropertyEdge"
                                                                  node?: {
                                                                    __typename: "ContentProperty"
                                                                    name: string
                                                                    value?: any | null
                                                                    displayName: string
                                                                  } | null
                                                                } | null> | null
                                                              } | null
                                                            }
                                                          | { __typename: "ST4TreeNode" }
                                                          | null
                                                      }
                                                    | {
                                                        __typename: "NodeRef"
                                                        id: string
                                                        owner?:
                                                          | { __typename: "CommentNode" }
                                                          | { __typename: "ExternalSite" }
                                                          | { __typename: "ST4Class" }
                                                          | { __typename: "ST4Node"; id: string }
                                                          | { __typename: "ST4TreeNode" }
                                                          | null
                                                        target?:
                                                          | { __typename: "CommentNode" }
                                                          | {
                                                              __typename: "ExternalSite"
                                                              id: string
                                                              teaserData?: {
                                                                __typename: "ExternalLinkTeaserData"
                                                                title?: string | null
                                                                summary?: string | null
                                                                iconUrl?: string | null
                                                                imageUrl?: string | null
                                                                status?: Types.LinkTargetStatus | null
                                                              } | null
                                                            }
                                                          | { __typename: "ST4Class" }
                                                          | {
                                                              __typename: "ST4Node"
                                                              id: string
                                                              aspectId: any
                                                              label?: string | null
                                                              properties?: {
                                                                __typename: "ContentPropertyConnection"
                                                                edges?: Array<{
                                                                  __typename: "ContentPropertyEdge"
                                                                  node?: {
                                                                    __typename: "ContentProperty"
                                                                    name: string
                                                                    value?: any | null
                                                                    displayName: string
                                                                  } | null
                                                                } | null> | null
                                                              } | null
                                                            }
                                                          | { __typename: "ST4TreeNode" }
                                                          | null
                                                      }
                                                    | null
                                                }>
                                              }
                                            | {
                                                __typename: "VideoMediaInfo"
                                                id: string
                                                resourceId?: string | null
                                                thumbnail?: string | null
                                                width?: number | null
                                                height?: number | null
                                                mediaType?: Types.MediaType | null
                                              }
                                            | null
                                        }
                                      | { __typename: "ProjectContent" }
                                      | { __typename: "TermContent" }
                                      | { __typename: "TextContent" }
                                      | { __typename: "TextGroupContent" }
                                      | { __typename: "VariableTableContent" }
                                      | null
                                  }
                                | { __typename: "ST4TreeNode"; id: string }
                                | null
                            }>
                            variableTables?: Array<{
                              __typename: "VariableTableReference"
                              ref?: string | null
                              node?:
                                | { __typename: "CommentNode" }
                                | { __typename: "ExternalSite" }
                                | { __typename: "ST4Class" }
                                | {
                                    __typename: "ST4Node"
                                    id: string
                                    label?: string | null
                                    aspectId: any
                                    content?:
                                      | { __typename: "LexiconContent" }
                                      | { __typename: "LexiconEntryContent" }
                                      | { __typename: "MediaContent" }
                                      | { __typename: "MediaGroupContent" }
                                      | { __typename: "ProjectContent" }
                                      | { __typename: "TermContent" }
                                      | { __typename: "TextContent" }
                                      | { __typename: "TextGroupContent" }
                                      | {
                                          __typename: "VariableTableContent"
                                          xml?: string | null
                                          valueSets: Array<{ __typename: "ValueSet"; name: string; valueSetId: string }>
                                          variables: Array<{
                                            __typename: "Variable"
                                            name: string
                                            variableId: string
                                            values: Array<string>
                                          }>
                                        }
                                      | null
                                  }
                                | { __typename: "ST4TreeNode" }
                                | null
                            } | null> | null
                            linkedDataNodes: Array<{
                              __typename: "NodeRef"
                              id: string
                              target?:
                                | { __typename: "CommentNode"; id: string }
                                | { __typename: "ExternalSite"; id: string }
                                | { __typename: "ST4Class"; id: string }
                                | {
                                    __typename: "ST4Node"
                                    label?: string | null
                                    id: string
                                    metadata: Array<{
                                      __typename: "MetaDataEntry"
                                      label?: string | null
                                      systemName: string
                                      value?: string | null
                                      values: Array<string>
                                      users: Array<string>
                                    }>
                                    linkedOntologyNodes: Array<
                                      | {
                                          __typename: "GenericRef"
                                          target?:
                                            | { __typename: "CommentNode" }
                                            | { __typename: "ExternalSite" }
                                            | { __typename: "ST4Class" }
                                            | { __typename: "ST4Node"; id: string; label?: string | null }
                                            | { __typename: "ST4TreeNode" }
                                            | null
                                        }
                                      | {
                                          __typename: "NodeRef"
                                          linkClassName: string
                                          class?: { __typename: "ST4Class"; label?: string | null } | null
                                          target?:
                                            | { __typename: "CommentNode" }
                                            | { __typename: "ExternalSite" }
                                            | { __typename: "ST4Class" }
                                            | { __typename: "ST4Node"; id: string; label?: string | null }
                                            | { __typename: "ST4TreeNode" }
                                            | null
                                        }
                                    >
                                  }
                                | { __typename: "ST4TreeNode"; id: string }
                                | null
                            }>
                            lexiconEntries?: Array<{
                              __typename: "NodeRef"
                              id: string
                              target?:
                                | { __typename: "CommentNode"; id: string }
                                | { __typename: "ExternalSite"; id: string }
                                | { __typename: "ST4Class"; id: string }
                                | {
                                    __typename: "ST4Node"
                                    aspectId: any
                                    label?: string | null
                                    id: string
                                    content?:
                                      | { __typename: "LexiconContent" }
                                      | {
                                          __typename: "LexiconEntryContent"
                                          descriptionXast?: string | null
                                          subject?: string | null
                                          sortKey?: string | null
                                          lexicon:
                                            | { __typename: "CommentNode"; id: string }
                                            | { __typename: "ExternalSite"; id: string }
                                            | { __typename: "ST4Class"; id: string }
                                            | { __typename: "ST4Node"; label?: string | null; id: string }
                                            | { __typename: "ST4TreeNode"; id: string }
                                          terms: Array<
                                            | { __typename: "CommentNode"; id: string }
                                            | { __typename: "ExternalSite"; id: string }
                                            | { __typename: "ST4Class"; id: string }
                                            | {
                                                __typename: "ST4Node"
                                                id: string
                                                label?: string | null
                                                aspectId: any
                                                content?:
                                                  | { __typename: "LexiconContent" }
                                                  | { __typename: "LexiconEntryContent" }
                                                  | { __typename: "MediaContent" }
                                                  | { __typename: "MediaGroupContent" }
                                                  | { __typename: "ProjectContent" }
                                                  | {
                                                      __typename: "TermContent"
                                                      gramaticalGender?: string | null
                                                      validity?: string | null
                                                      editLanguage?: string | null
                                                    }
                                                  | { __typename: "TextContent" }
                                                  | { __typename: "TextGroupContent" }
                                                  | { __typename: "VariableTableContent" }
                                                  | null
                                              }
                                            | { __typename: "ST4TreeNode"; id: string }
                                          >
                                          image?:
                                            | { __typename: "CommentNode"; id: string }
                                            | { __typename: "ExternalSite"; id: string }
                                            | { __typename: "ST4Class"; id: string }
                                            | {
                                                __typename: "ST4Node"
                                                aspectId: any
                                                id: string
                                                content?:
                                                  | { __typename: "LexiconContent" }
                                                  | { __typename: "LexiconEntryContent" }
                                                  | {
                                                      __typename: "MediaContent"
                                                      media?:
                                                        | {
                                                            __typename: "AnimationMediaInfo"
                                                            thumbnail?: string | null
                                                            mediaType?: Types.MediaType | null
                                                          }
                                                        | {
                                                            __typename: "ImageMediaInfo"
                                                            src?: string | null
                                                            thumbnail?: string | null
                                                            mediaType?: Types.MediaType | null
                                                          }
                                                        | { __typename: "NoMediaInfo" }
                                                        | {
                                                            __typename: "VectorGraphicsMediaInfo"
                                                            svg?: string | null
                                                            thumbnail?: string | null
                                                            mediaType?: Types.MediaType | null
                                                          }
                                                        | {
                                                            __typename: "VideoMediaInfo"
                                                            thumbnail?: string | null
                                                            mediaType?: Types.MediaType | null
                                                          }
                                                        | null
                                                    }
                                                  | { __typename: "MediaGroupContent" }
                                                  | { __typename: "ProjectContent" }
                                                  | { __typename: "TermContent" }
                                                  | { __typename: "TextContent" }
                                                  | { __typename: "TextGroupContent" }
                                                  | { __typename: "VariableTableContent" }
                                                  | null
                                              }
                                            | { __typename: "ST4TreeNode"; id: string }
                                            | null
                                        }
                                      | { __typename: "MediaContent" }
                                      | { __typename: "MediaGroupContent" }
                                      | { __typename: "ProjectContent" }
                                      | { __typename: "TermContent" }
                                      | { __typename: "TextContent" }
                                      | { __typename: "TextGroupContent" }
                                      | { __typename: "VariableTableContent" }
                                      | null
                                  }
                                | { __typename: "ST4TreeNode"; id: string }
                                | null
                            } | null> | null
                            comments: Array<{
                              __typename: "CommentNode"
                              id: string
                              comment:
                                | {
                                    __typename: "BlockComment"
                                    location: number
                                    value?: string | null
                                    authorDisplayName?: string | null
                                    authorUserName?: string | null
                                    commentKey: string
                                    editDate?: any | null
                                    modificationVersion?: number | null
                                    replyToKey?: string | null
                                    sortOrder?: number | null
                                    commentType?: string | null
                                    state?: string | null
                                    color: string
                                    persistanceState?: Types.PersistanceState | null
                                    persistanceAction?: Types.PersistanceAction | null
                                    node: {
                                      __typename: "ST4Node"
                                      aspectId: any
                                      id: string
                                      content?:
                                        | { __typename: "LexiconContent" }
                                        | { __typename: "LexiconEntryContent" }
                                        | { __typename: "MediaContent" }
                                        | { __typename: "MediaGroupContent" }
                                        | { __typename: "ProjectContent" }
                                        | { __typename: "TermContent" }
                                        | { __typename: "TextContent"; id: string; historyNumber: number }
                                        | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                                        | { __typename: "VariableTableContent" }
                                        | null
                                    }
                                    actions: Array<{
                                      __typename: "Action"
                                      name?: string | null
                                      enabled?: boolean | null
                                    }>
                                  }
                                | {
                                    __typename: "TextComment"
                                    endLocation: number
                                    startLocation?: number | null
                                    value?: string | null
                                    authorDisplayName?: string | null
                                    authorUserName?: string | null
                                    commentKey: string
                                    editDate?: any | null
                                    modificationVersion?: number | null
                                    replyToKey?: string | null
                                    sortOrder?: number | null
                                    commentType?: string | null
                                    state?: string | null
                                    color: string
                                    persistanceState?: Types.PersistanceState | null
                                    persistanceAction?: Types.PersistanceAction | null
                                    node: {
                                      __typename: "ST4Node"
                                      aspectId: any
                                      id: string
                                      content?:
                                        | { __typename: "LexiconContent" }
                                        | { __typename: "LexiconEntryContent" }
                                        | { __typename: "MediaContent" }
                                        | { __typename: "MediaGroupContent" }
                                        | { __typename: "ProjectContent" }
                                        | { __typename: "TermContent" }
                                        | { __typename: "TextContent"; id: string; historyNumber: number }
                                        | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                                        | { __typename: "VariableTableContent" }
                                        | null
                                    }
                                    actions: Array<{
                                      __typename: "Action"
                                      name?: string | null
                                      enabled?: boolean | null
                                    }>
                                  }
                            }>
                          }
                        | { __typename: "VariableTableContent" }
                        | null
                    }
                  }>
                  links?: Array<{
                    __typename: "TextContentLink"
                    ref?: string | null
                    node?:
                      | { __typename: "CommentNode"; id: string }
                      | { __typename: "ExternalSite"; id: string }
                      | { __typename: "ST4Class"; id: string }
                      | {
                          __typename: "ST4Node"
                          id: string
                          aspectId: any
                          label?: string | null
                          properties?: {
                            __typename: "ContentPropertyConnection"
                            totalCount: number
                            edges?: Array<{
                              __typename: "ContentPropertyEdge"
                              node?: {
                                __typename: "ContentProperty"
                                typeOfValue: Types.ContentPropertyValueType
                                name: string
                                value?: any | null
                                displayName: string
                              } | null
                            } | null> | null
                          } | null
                        }
                      | { __typename: "ST4TreeNode"; id: string }
                      | null
                  } | null> | null
                  images: Array<{
                    __typename: "NodeRef"
                    id: string
                    target?:
                      | { __typename: "CommentNode"; id: string }
                      | { __typename: "ExternalSite"; id: string }
                      | { __typename: "ST4Class"; id: string }
                      | {
                          __typename: "ST4Node"
                          aspectId: any
                          id: string
                          releaseState: {
                            __typename: "ReleaseStateValue"
                            displayName: string
                            state: Types.ReleaseState
                            isImplicitlyReleased: boolean
                          }
                          content?:
                            | { __typename: "LexiconContent" }
                            | { __typename: "LexiconEntryContent" }
                            | {
                                __typename: "MediaContent"
                                media?:
                                  | {
                                      __typename: "AnimationMediaInfo"
                                      id: string
                                      resourceId?: string | null
                                      thumbnail?: string | null
                                      width?: number | null
                                      height?: number | null
                                      mediaType?: Types.MediaType | null
                                    }
                                  | {
                                      __typename: "ImageMediaInfo"
                                      id: string
                                      resourceId?: string | null
                                      thumbnail?: string | null
                                      width?: number | null
                                      height?: number | null
                                      resolution?: number | null
                                      colorDepth?: number | null
                                      size?: number | null
                                      mime?: string | null
                                      extension?: string | null
                                      allowCaching?: boolean | null
                                      mediaType?: Types.MediaType | null
                                      src?: string | null
                                      language?: string | null
                                    }
                                  | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                                  | {
                                      __typename: "VectorGraphicsMediaInfo"
                                      id: string
                                      resourceId?: string | null
                                      thumbnail?: string | null
                                      width?: number | null
                                      height?: number | null
                                      allowCaching?: boolean | null
                                      mediaType?: Types.MediaType | null
                                      language?: string | null
                                      labels: Array<{
                                        __typename: "VectorGraphicsLabel"
                                        id?: string | null
                                        sequence?: number | null
                                        autoValue?: string | null
                                        text?: string | null
                                        linkType?: Types.VectorGraphicsLabelLinkType | null
                                        link?: string | null
                                        ref?:
                                          | {
                                              __typename: "GenericRef"
                                              id: string
                                              owner?:
                                                | { __typename: "CommentNode" }
                                                | { __typename: "ExternalSite" }
                                                | { __typename: "ST4Class" }
                                                | { __typename: "ST4Node"; id: string }
                                                | { __typename: "ST4TreeNode" }
                                                | null
                                              target?:
                                                | { __typename: "CommentNode" }
                                                | {
                                                    __typename: "ExternalSite"
                                                    id: string
                                                    teaserData?: {
                                                      __typename: "ExternalLinkTeaserData"
                                                      title?: string | null
                                                      summary?: string | null
                                                      iconUrl?: string | null
                                                      imageUrl?: string | null
                                                      status?: Types.LinkTargetStatus | null
                                                    } | null
                                                  }
                                                | { __typename: "ST4Class" }
                                                | {
                                                    __typename: "ST4Node"
                                                    id: string
                                                    aspectId: any
                                                    label?: string | null
                                                    properties?: {
                                                      __typename: "ContentPropertyConnection"
                                                      edges?: Array<{
                                                        __typename: "ContentPropertyEdge"
                                                        node?: {
                                                          __typename: "ContentProperty"
                                                          name: string
                                                          value?: any | null
                                                          displayName: string
                                                        } | null
                                                      } | null> | null
                                                    } | null
                                                  }
                                                | { __typename: "ST4TreeNode" }
                                                | null
                                            }
                                          | {
                                              __typename: "NodeRef"
                                              id: string
                                              owner?:
                                                | { __typename: "CommentNode" }
                                                | { __typename: "ExternalSite" }
                                                | { __typename: "ST4Class" }
                                                | { __typename: "ST4Node"; id: string }
                                                | { __typename: "ST4TreeNode" }
                                                | null
                                              target?:
                                                | { __typename: "CommentNode" }
                                                | {
                                                    __typename: "ExternalSite"
                                                    id: string
                                                    teaserData?: {
                                                      __typename: "ExternalLinkTeaserData"
                                                      title?: string | null
                                                      summary?: string | null
                                                      iconUrl?: string | null
                                                      imageUrl?: string | null
                                                      status?: Types.LinkTargetStatus | null
                                                    } | null
                                                  }
                                                | { __typename: "ST4Class" }
                                                | {
                                                    __typename: "ST4Node"
                                                    id: string
                                                    aspectId: any
                                                    label?: string | null
                                                    properties?: {
                                                      __typename: "ContentPropertyConnection"
                                                      edges?: Array<{
                                                        __typename: "ContentPropertyEdge"
                                                        node?: {
                                                          __typename: "ContentProperty"
                                                          name: string
                                                          value?: any | null
                                                          displayName: string
                                                        } | null
                                                      } | null> | null
                                                    } | null
                                                  }
                                                | { __typename: "ST4TreeNode" }
                                                | null
                                            }
                                          | null
                                      }>
                                    }
                                  | {
                                      __typename: "VideoMediaInfo"
                                      id: string
                                      resourceId?: string | null
                                      thumbnail?: string | null
                                      width?: number | null
                                      height?: number | null
                                      mediaType?: Types.MediaType | null
                                    }
                                  | null
                              }
                            | {
                                __typename: "MediaGroupContent"
                                children: Array<{
                                  __typename: "NodeRef"
                                  id: string
                                  target?:
                                    | { __typename: "CommentNode"; id: string }
                                    | { __typename: "ExternalSite"; id: string }
                                    | { __typename: "ST4Class"; id: string }
                                    | {
                                        __typename: "ST4Node"
                                        id: string
                                        aspectId: any
                                        content?:
                                          | { __typename: "LexiconContent" }
                                          | { __typename: "LexiconEntryContent" }
                                          | {
                                              __typename: "MediaContent"
                                              media?:
                                                | {
                                                    __typename: "AnimationMediaInfo"
                                                    id: string
                                                    resourceId?: string | null
                                                    thumbnail?: string | null
                                                    width?: number | null
                                                    height?: number | null
                                                    mediaType?: Types.MediaType | null
                                                  }
                                                | {
                                                    __typename: "ImageMediaInfo"
                                                    id: string
                                                    resourceId?: string | null
                                                    thumbnail?: string | null
                                                    width?: number | null
                                                    height?: number | null
                                                    resolution?: number | null
                                                    colorDepth?: number | null
                                                    size?: number | null
                                                    mime?: string | null
                                                    extension?: string | null
                                                    allowCaching?: boolean | null
                                                    mediaType?: Types.MediaType | null
                                                    src?: string | null
                                                    language?: string | null
                                                  }
                                                | {
                                                    __typename: "NoMediaInfo"
                                                    id: string
                                                    mediaType?: Types.MediaType | null
                                                  }
                                                | {
                                                    __typename: "VectorGraphicsMediaInfo"
                                                    id: string
                                                    resourceId?: string | null
                                                    thumbnail?: string | null
                                                    width?: number | null
                                                    height?: number | null
                                                    allowCaching?: boolean | null
                                                    mediaType?: Types.MediaType | null
                                                    language?: string | null
                                                    labels: Array<{
                                                      __typename: "VectorGraphicsLabel"
                                                      id?: string | null
                                                      sequence?: number | null
                                                      autoValue?: string | null
                                                      text?: string | null
                                                      linkType?: Types.VectorGraphicsLabelLinkType | null
                                                      link?: string | null
                                                      ref?:
                                                        | {
                                                            __typename: "GenericRef"
                                                            id: string
                                                            owner?:
                                                              | { __typename: "CommentNode" }
                                                              | { __typename: "ExternalSite" }
                                                              | { __typename: "ST4Class" }
                                                              | { __typename: "ST4Node"; id: string }
                                                              | { __typename: "ST4TreeNode" }
                                                              | null
                                                            target?:
                                                              | { __typename: "CommentNode" }
                                                              | {
                                                                  __typename: "ExternalSite"
                                                                  id: string
                                                                  teaserData?: {
                                                                    __typename: "ExternalLinkTeaserData"
                                                                    title?: string | null
                                                                    summary?: string | null
                                                                    iconUrl?: string | null
                                                                    imageUrl?: string | null
                                                                    status?: Types.LinkTargetStatus | null
                                                                  } | null
                                                                }
                                                              | { __typename: "ST4Class" }
                                                              | {
                                                                  __typename: "ST4Node"
                                                                  id: string
                                                                  aspectId: any
                                                                  label?: string | null
                                                                  properties?: {
                                                                    __typename: "ContentPropertyConnection"
                                                                    edges?: Array<{
                                                                      __typename: "ContentPropertyEdge"
                                                                      node?: {
                                                                        __typename: "ContentProperty"
                                                                        name: string
                                                                        value?: any | null
                                                                        displayName: string
                                                                      } | null
                                                                    } | null> | null
                                                                  } | null
                                                                }
                                                              | { __typename: "ST4TreeNode" }
                                                              | null
                                                          }
                                                        | {
                                                            __typename: "NodeRef"
                                                            id: string
                                                            owner?:
                                                              | { __typename: "CommentNode" }
                                                              | { __typename: "ExternalSite" }
                                                              | { __typename: "ST4Class" }
                                                              | { __typename: "ST4Node"; id: string }
                                                              | { __typename: "ST4TreeNode" }
                                                              | null
                                                            target?:
                                                              | { __typename: "CommentNode" }
                                                              | {
                                                                  __typename: "ExternalSite"
                                                                  id: string
                                                                  teaserData?: {
                                                                    __typename: "ExternalLinkTeaserData"
                                                                    title?: string | null
                                                                    summary?: string | null
                                                                    iconUrl?: string | null
                                                                    imageUrl?: string | null
                                                                    status?: Types.LinkTargetStatus | null
                                                                  } | null
                                                                }
                                                              | { __typename: "ST4Class" }
                                                              | {
                                                                  __typename: "ST4Node"
                                                                  id: string
                                                                  aspectId: any
                                                                  label?: string | null
                                                                  properties?: {
                                                                    __typename: "ContentPropertyConnection"
                                                                    edges?: Array<{
                                                                      __typename: "ContentPropertyEdge"
                                                                      node?: {
                                                                        __typename: "ContentProperty"
                                                                        name: string
                                                                        value?: any | null
                                                                        displayName: string
                                                                      } | null
                                                                    } | null> | null
                                                                  } | null
                                                                }
                                                              | { __typename: "ST4TreeNode" }
                                                              | null
                                                          }
                                                        | null
                                                    }>
                                                  }
                                                | {
                                                    __typename: "VideoMediaInfo"
                                                    id: string
                                                    resourceId?: string | null
                                                    thumbnail?: string | null
                                                    width?: number | null
                                                    height?: number | null
                                                    mediaType?: Types.MediaType | null
                                                  }
                                                | null
                                            }
                                          | {
                                              __typename: "MediaGroupContent"
                                              media?:
                                                | {
                                                    __typename: "AnimationMediaInfo"
                                                    id: string
                                                    resourceId?: string | null
                                                    thumbnail?: string | null
                                                    width?: number | null
                                                    height?: number | null
                                                    mediaType?: Types.MediaType | null
                                                  }
                                                | {
                                                    __typename: "ImageMediaInfo"
                                                    id: string
                                                    resourceId?: string | null
                                                    thumbnail?: string | null
                                                    width?: number | null
                                                    height?: number | null
                                                    resolution?: number | null
                                                    colorDepth?: number | null
                                                    size?: number | null
                                                    mime?: string | null
                                                    extension?: string | null
                                                    allowCaching?: boolean | null
                                                    mediaType?: Types.MediaType | null
                                                    src?: string | null
                                                    language?: string | null
                                                  }
                                                | {
                                                    __typename: "NoMediaInfo"
                                                    id: string
                                                    mediaType?: Types.MediaType | null
                                                  }
                                                | {
                                                    __typename: "VectorGraphicsMediaInfo"
                                                    id: string
                                                    resourceId?: string | null
                                                    thumbnail?: string | null
                                                    width?: number | null
                                                    height?: number | null
                                                    allowCaching?: boolean | null
                                                    mediaType?: Types.MediaType | null
                                                    language?: string | null
                                                    labels: Array<{
                                                      __typename: "VectorGraphicsLabel"
                                                      id?: string | null
                                                      sequence?: number | null
                                                      autoValue?: string | null
                                                      text?: string | null
                                                      linkType?: Types.VectorGraphicsLabelLinkType | null
                                                      link?: string | null
                                                      ref?:
                                                        | {
                                                            __typename: "GenericRef"
                                                            id: string
                                                            owner?:
                                                              | { __typename: "CommentNode" }
                                                              | { __typename: "ExternalSite" }
                                                              | { __typename: "ST4Class" }
                                                              | { __typename: "ST4Node"; id: string }
                                                              | { __typename: "ST4TreeNode" }
                                                              | null
                                                            target?:
                                                              | { __typename: "CommentNode" }
                                                              | {
                                                                  __typename: "ExternalSite"
                                                                  id: string
                                                                  teaserData?: {
                                                                    __typename: "ExternalLinkTeaserData"
                                                                    title?: string | null
                                                                    summary?: string | null
                                                                    iconUrl?: string | null
                                                                    imageUrl?: string | null
                                                                    status?: Types.LinkTargetStatus | null
                                                                  } | null
                                                                }
                                                              | { __typename: "ST4Class" }
                                                              | {
                                                                  __typename: "ST4Node"
                                                                  id: string
                                                                  aspectId: any
                                                                  label?: string | null
                                                                  properties?: {
                                                                    __typename: "ContentPropertyConnection"
                                                                    edges?: Array<{
                                                                      __typename: "ContentPropertyEdge"
                                                                      node?: {
                                                                        __typename: "ContentProperty"
                                                                        name: string
                                                                        value?: any | null
                                                                        displayName: string
                                                                      } | null
                                                                    } | null> | null
                                                                  } | null
                                                                }
                                                              | { __typename: "ST4TreeNode" }
                                                              | null
                                                          }
                                                        | {
                                                            __typename: "NodeRef"
                                                            id: string
                                                            owner?:
                                                              | { __typename: "CommentNode" }
                                                              | { __typename: "ExternalSite" }
                                                              | { __typename: "ST4Class" }
                                                              | { __typename: "ST4Node"; id: string }
                                                              | { __typename: "ST4TreeNode" }
                                                              | null
                                                            target?:
                                                              | { __typename: "CommentNode" }
                                                              | {
                                                                  __typename: "ExternalSite"
                                                                  id: string
                                                                  teaserData?: {
                                                                    __typename: "ExternalLinkTeaserData"
                                                                    title?: string | null
                                                                    summary?: string | null
                                                                    iconUrl?: string | null
                                                                    imageUrl?: string | null
                                                                    status?: Types.LinkTargetStatus | null
                                                                  } | null
                                                                }
                                                              | { __typename: "ST4Class" }
                                                              | {
                                                                  __typename: "ST4Node"
                                                                  id: string
                                                                  aspectId: any
                                                                  label?: string | null
                                                                  properties?: {
                                                                    __typename: "ContentPropertyConnection"
                                                                    edges?: Array<{
                                                                      __typename: "ContentPropertyEdge"
                                                                      node?: {
                                                                        __typename: "ContentProperty"
                                                                        name: string
                                                                        value?: any | null
                                                                        displayName: string
                                                                      } | null
                                                                    } | null> | null
                                                                  } | null
                                                                }
                                                              | { __typename: "ST4TreeNode" }
                                                              | null
                                                          }
                                                        | null
                                                    }>
                                                  }
                                                | {
                                                    __typename: "VideoMediaInfo"
                                                    id: string
                                                    resourceId?: string | null
                                                    thumbnail?: string | null
                                                    width?: number | null
                                                    height?: number | null
                                                    mediaType?: Types.MediaType | null
                                                  }
                                                | null
                                            }
                                          | { __typename: "ProjectContent" }
                                          | { __typename: "TermContent" }
                                          | { __typename: "TextContent" }
                                          | { __typename: "TextGroupContent" }
                                          | { __typename: "VariableTableContent" }
                                          | null
                                      }
                                    | { __typename: "ST4TreeNode"; id: string }
                                    | null
                                }>
                                media?:
                                  | {
                                      __typename: "AnimationMediaInfo"
                                      id: string
                                      resourceId?: string | null
                                      thumbnail?: string | null
                                      width?: number | null
                                      height?: number | null
                                      mediaType?: Types.MediaType | null
                                    }
                                  | {
                                      __typename: "ImageMediaInfo"
                                      id: string
                                      resourceId?: string | null
                                      thumbnail?: string | null
                                      width?: number | null
                                      height?: number | null
                                      resolution?: number | null
                                      colorDepth?: number | null
                                      size?: number | null
                                      mime?: string | null
                                      extension?: string | null
                                      allowCaching?: boolean | null
                                      mediaType?: Types.MediaType | null
                                      src?: string | null
                                      language?: string | null
                                    }
                                  | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                                  | {
                                      __typename: "VectorGraphicsMediaInfo"
                                      id: string
                                      resourceId?: string | null
                                      thumbnail?: string | null
                                      width?: number | null
                                      height?: number | null
                                      allowCaching?: boolean | null
                                      mediaType?: Types.MediaType | null
                                      language?: string | null
                                      labels: Array<{
                                        __typename: "VectorGraphicsLabel"
                                        id?: string | null
                                        sequence?: number | null
                                        autoValue?: string | null
                                        text?: string | null
                                        linkType?: Types.VectorGraphicsLabelLinkType | null
                                        link?: string | null
                                        ref?:
                                          | {
                                              __typename: "GenericRef"
                                              id: string
                                              owner?:
                                                | { __typename: "CommentNode" }
                                                | { __typename: "ExternalSite" }
                                                | { __typename: "ST4Class" }
                                                | { __typename: "ST4Node"; id: string }
                                                | { __typename: "ST4TreeNode" }
                                                | null
                                              target?:
                                                | { __typename: "CommentNode" }
                                                | {
                                                    __typename: "ExternalSite"
                                                    id: string
                                                    teaserData?: {
                                                      __typename: "ExternalLinkTeaserData"
                                                      title?: string | null
                                                      summary?: string | null
                                                      iconUrl?: string | null
                                                      imageUrl?: string | null
                                                      status?: Types.LinkTargetStatus | null
                                                    } | null
                                                  }
                                                | { __typename: "ST4Class" }
                                                | {
                                                    __typename: "ST4Node"
                                                    id: string
                                                    aspectId: any
                                                    label?: string | null
                                                    properties?: {
                                                      __typename: "ContentPropertyConnection"
                                                      edges?: Array<{
                                                        __typename: "ContentPropertyEdge"
                                                        node?: {
                                                          __typename: "ContentProperty"
                                                          name: string
                                                          value?: any | null
                                                          displayName: string
                                                        } | null
                                                      } | null> | null
                                                    } | null
                                                  }
                                                | { __typename: "ST4TreeNode" }
                                                | null
                                            }
                                          | {
                                              __typename: "NodeRef"
                                              id: string
                                              owner?:
                                                | { __typename: "CommentNode" }
                                                | { __typename: "ExternalSite" }
                                                | { __typename: "ST4Class" }
                                                | { __typename: "ST4Node"; id: string }
                                                | { __typename: "ST4TreeNode" }
                                                | null
                                              target?:
                                                | { __typename: "CommentNode" }
                                                | {
                                                    __typename: "ExternalSite"
                                                    id: string
                                                    teaserData?: {
                                                      __typename: "ExternalLinkTeaserData"
                                                      title?: string | null
                                                      summary?: string | null
                                                      iconUrl?: string | null
                                                      imageUrl?: string | null
                                                      status?: Types.LinkTargetStatus | null
                                                    } | null
                                                  }
                                                | { __typename: "ST4Class" }
                                                | {
                                                    __typename: "ST4Node"
                                                    id: string
                                                    aspectId: any
                                                    label?: string | null
                                                    properties?: {
                                                      __typename: "ContentPropertyConnection"
                                                      edges?: Array<{
                                                        __typename: "ContentPropertyEdge"
                                                        node?: {
                                                          __typename: "ContentProperty"
                                                          name: string
                                                          value?: any | null
                                                          displayName: string
                                                        } | null
                                                      } | null> | null
                                                    } | null
                                                  }
                                                | { __typename: "ST4TreeNode" }
                                                | null
                                            }
                                          | null
                                      }>
                                    }
                                  | {
                                      __typename: "VideoMediaInfo"
                                      id: string
                                      resourceId?: string | null
                                      thumbnail?: string | null
                                      width?: number | null
                                      height?: number | null
                                      mediaType?: Types.MediaType | null
                                    }
                                  | null
                              }
                            | { __typename: "ProjectContent" }
                            | { __typename: "TermContent" }
                            | { __typename: "TextContent" }
                            | { __typename: "TextGroupContent" }
                            | { __typename: "VariableTableContent" }
                            | null
                        }
                      | { __typename: "ST4TreeNode"; id: string }
                      | null
                  }>
                  variableTables?: Array<{
                    __typename: "VariableTableReference"
                    ref?: string | null
                    node?:
                      | { __typename: "CommentNode" }
                      | { __typename: "ExternalSite" }
                      | { __typename: "ST4Class" }
                      | {
                          __typename: "ST4Node"
                          id: string
                          label?: string | null
                          aspectId: any
                          content?:
                            | { __typename: "LexiconContent" }
                            | { __typename: "LexiconEntryContent" }
                            | { __typename: "MediaContent" }
                            | { __typename: "MediaGroupContent" }
                            | { __typename: "ProjectContent" }
                            | { __typename: "TermContent" }
                            | { __typename: "TextContent" }
                            | { __typename: "TextGroupContent" }
                            | {
                                __typename: "VariableTableContent"
                                xml?: string | null
                                valueSets: Array<{ __typename: "ValueSet"; name: string; valueSetId: string }>
                                variables: Array<{
                                  __typename: "Variable"
                                  name: string
                                  variableId: string
                                  values: Array<string>
                                }>
                              }
                            | null
                        }
                      | { __typename: "ST4TreeNode" }
                      | null
                  } | null> | null
                  linkedDataNodes: Array<{
                    __typename: "NodeRef"
                    id: string
                    target?:
                      | { __typename: "CommentNode"; id: string }
                      | { __typename: "ExternalSite"; id: string }
                      | { __typename: "ST4Class"; id: string }
                      | {
                          __typename: "ST4Node"
                          label?: string | null
                          id: string
                          metadata: Array<{
                            __typename: "MetaDataEntry"
                            label?: string | null
                            systemName: string
                            value?: string | null
                            values: Array<string>
                            users: Array<string>
                          }>
                          linkedOntologyNodes: Array<
                            | {
                                __typename: "GenericRef"
                                target?:
                                  | { __typename: "CommentNode" }
                                  | { __typename: "ExternalSite" }
                                  | { __typename: "ST4Class" }
                                  | { __typename: "ST4Node"; id: string; label?: string | null }
                                  | { __typename: "ST4TreeNode" }
                                  | null
                              }
                            | {
                                __typename: "NodeRef"
                                linkClassName: string
                                class?: { __typename: "ST4Class"; label?: string | null } | null
                                target?:
                                  | { __typename: "CommentNode" }
                                  | { __typename: "ExternalSite" }
                                  | { __typename: "ST4Class" }
                                  | { __typename: "ST4Node"; id: string; label?: string | null }
                                  | { __typename: "ST4TreeNode" }
                                  | null
                              }
                          >
                        }
                      | { __typename: "ST4TreeNode"; id: string }
                      | null
                  }>
                  lexiconEntries?: Array<{
                    __typename: "NodeRef"
                    id: string
                    target?:
                      | { __typename: "CommentNode"; id: string }
                      | { __typename: "ExternalSite"; id: string }
                      | { __typename: "ST4Class"; id: string }
                      | {
                          __typename: "ST4Node"
                          aspectId: any
                          label?: string | null
                          id: string
                          content?:
                            | { __typename: "LexiconContent" }
                            | {
                                __typename: "LexiconEntryContent"
                                descriptionXast?: string | null
                                subject?: string | null
                                sortKey?: string | null
                                lexicon:
                                  | { __typename: "CommentNode"; id: string }
                                  | { __typename: "ExternalSite"; id: string }
                                  | { __typename: "ST4Class"; id: string }
                                  | { __typename: "ST4Node"; label?: string | null; id: string }
                                  | { __typename: "ST4TreeNode"; id: string }
                                terms: Array<
                                  | { __typename: "CommentNode"; id: string }
                                  | { __typename: "ExternalSite"; id: string }
                                  | { __typename: "ST4Class"; id: string }
                                  | {
                                      __typename: "ST4Node"
                                      id: string
                                      label?: string | null
                                      aspectId: any
                                      content?:
                                        | { __typename: "LexiconContent" }
                                        | { __typename: "LexiconEntryContent" }
                                        | { __typename: "MediaContent" }
                                        | { __typename: "MediaGroupContent" }
                                        | { __typename: "ProjectContent" }
                                        | {
                                            __typename: "TermContent"
                                            gramaticalGender?: string | null
                                            validity?: string | null
                                            editLanguage?: string | null
                                          }
                                        | { __typename: "TextContent" }
                                        | { __typename: "TextGroupContent" }
                                        | { __typename: "VariableTableContent" }
                                        | null
                                    }
                                  | { __typename: "ST4TreeNode"; id: string }
                                >
                                image?:
                                  | { __typename: "CommentNode"; id: string }
                                  | { __typename: "ExternalSite"; id: string }
                                  | { __typename: "ST4Class"; id: string }
                                  | {
                                      __typename: "ST4Node"
                                      aspectId: any
                                      id: string
                                      content?:
                                        | { __typename: "LexiconContent" }
                                        | { __typename: "LexiconEntryContent" }
                                        | {
                                            __typename: "MediaContent"
                                            media?:
                                              | {
                                                  __typename: "AnimationMediaInfo"
                                                  thumbnail?: string | null
                                                  mediaType?: Types.MediaType | null
                                                }
                                              | {
                                                  __typename: "ImageMediaInfo"
                                                  src?: string | null
                                                  thumbnail?: string | null
                                                  mediaType?: Types.MediaType | null
                                                }
                                              | { __typename: "NoMediaInfo" }
                                              | {
                                                  __typename: "VectorGraphicsMediaInfo"
                                                  svg?: string | null
                                                  thumbnail?: string | null
                                                  mediaType?: Types.MediaType | null
                                                }
                                              | {
                                                  __typename: "VideoMediaInfo"
                                                  thumbnail?: string | null
                                                  mediaType?: Types.MediaType | null
                                                }
                                              | null
                                          }
                                        | { __typename: "MediaGroupContent" }
                                        | { __typename: "ProjectContent" }
                                        | { __typename: "TermContent" }
                                        | { __typename: "TextContent" }
                                        | { __typename: "TextGroupContent" }
                                        | { __typename: "VariableTableContent" }
                                        | null
                                    }
                                  | { __typename: "ST4TreeNode"; id: string }
                                  | null
                              }
                            | { __typename: "MediaContent" }
                            | { __typename: "MediaGroupContent" }
                            | { __typename: "ProjectContent" }
                            | { __typename: "TermContent" }
                            | { __typename: "TextContent" }
                            | { __typename: "TextGroupContent" }
                            | { __typename: "VariableTableContent" }
                            | null
                        }
                      | { __typename: "ST4TreeNode"; id: string }
                      | null
                  } | null> | null
                  comments: Array<{
                    __typename: "CommentNode"
                    id: string
                    comment:
                      | {
                          __typename: "BlockComment"
                          location: number
                          value?: string | null
                          authorDisplayName?: string | null
                          authorUserName?: string | null
                          commentKey: string
                          editDate?: any | null
                          modificationVersion?: number | null
                          replyToKey?: string | null
                          sortOrder?: number | null
                          commentType?: string | null
                          state?: string | null
                          color: string
                          persistanceState?: Types.PersistanceState | null
                          persistanceAction?: Types.PersistanceAction | null
                          node: {
                            __typename: "ST4Node"
                            aspectId: any
                            id: string
                            content?:
                              | { __typename: "LexiconContent" }
                              | { __typename: "LexiconEntryContent" }
                              | { __typename: "MediaContent" }
                              | { __typename: "MediaGroupContent" }
                              | { __typename: "ProjectContent" }
                              | { __typename: "TermContent" }
                              | { __typename: "TextContent"; id: string; historyNumber: number }
                              | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                              | { __typename: "VariableTableContent" }
                              | null
                          }
                          actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
                        }
                      | {
                          __typename: "TextComment"
                          endLocation: number
                          startLocation?: number | null
                          value?: string | null
                          authorDisplayName?: string | null
                          authorUserName?: string | null
                          commentKey: string
                          editDate?: any | null
                          modificationVersion?: number | null
                          replyToKey?: string | null
                          sortOrder?: number | null
                          commentType?: string | null
                          state?: string | null
                          color: string
                          persistanceState?: Types.PersistanceState | null
                          persistanceAction?: Types.PersistanceAction | null
                          node: {
                            __typename: "ST4Node"
                            aspectId: any
                            id: string
                            content?:
                              | { __typename: "LexiconContent" }
                              | { __typename: "LexiconEntryContent" }
                              | { __typename: "MediaContent" }
                              | { __typename: "MediaGroupContent" }
                              | { __typename: "ProjectContent" }
                              | { __typename: "TermContent" }
                              | { __typename: "TextContent"; id: string; historyNumber: number }
                              | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                              | { __typename: "VariableTableContent" }
                              | null
                          }
                          actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
                        }
                  }>
                }
              | {
                  __typename: "TextGroupContent"
                  currentNodeId?: any | null
                  groupNodeId: any
                  id: string
                  xast?: string | null
                  historyNumber: number
                  parts: Array<{
                    __typename: "TextContentPart"
                    ref: string
                    contentNode: {
                      __typename: "ST4Node"
                      id: string
                      aspectId: any
                      content?:
                        | { __typename: "LexiconContent" }
                        | { __typename: "LexiconEntryContent" }
                        | { __typename: "MediaContent" }
                        | { __typename: "MediaGroupContent" }
                        | { __typename: "ProjectContent" }
                        | { __typename: "TermContent" }
                        | {
                            __typename: "TextContent"
                            id: string
                            xast?: string | null
                            historyNumber: number
                            links?: Array<{
                              __typename: "TextContentLink"
                              ref?: string | null
                              node?:
                                | { __typename: "CommentNode"; id: string }
                                | { __typename: "ExternalSite"; id: string }
                                | { __typename: "ST4Class"; id: string }
                                | {
                                    __typename: "ST4Node"
                                    id: string
                                    aspectId: any
                                    label?: string | null
                                    properties?: {
                                      __typename: "ContentPropertyConnection"
                                      totalCount: number
                                      edges?: Array<{
                                        __typename: "ContentPropertyEdge"
                                        node?: {
                                          __typename: "ContentProperty"
                                          typeOfValue: Types.ContentPropertyValueType
                                          name: string
                                          value?: any | null
                                          displayName: string
                                        } | null
                                      } | null> | null
                                    } | null
                                  }
                                | { __typename: "ST4TreeNode"; id: string }
                                | null
                            } | null> | null
                            images: Array<{
                              __typename: "NodeRef"
                              id: string
                              target?:
                                | { __typename: "CommentNode"; id: string }
                                | { __typename: "ExternalSite"; id: string }
                                | { __typename: "ST4Class"; id: string }
                                | {
                                    __typename: "ST4Node"
                                    aspectId: any
                                    id: string
                                    releaseState: {
                                      __typename: "ReleaseStateValue"
                                      displayName: string
                                      state: Types.ReleaseState
                                      isImplicitlyReleased: boolean
                                    }
                                    content?:
                                      | { __typename: "LexiconContent" }
                                      | { __typename: "LexiconEntryContent" }
                                      | {
                                          __typename: "MediaContent"
                                          media?:
                                            | {
                                                __typename: "AnimationMediaInfo"
                                                id: string
                                                resourceId?: string | null
                                                thumbnail?: string | null
                                                width?: number | null
                                                height?: number | null
                                                mediaType?: Types.MediaType | null
                                              }
                                            | {
                                                __typename: "ImageMediaInfo"
                                                id: string
                                                resourceId?: string | null
                                                thumbnail?: string | null
                                                width?: number | null
                                                height?: number | null
                                                resolution?: number | null
                                                colorDepth?: number | null
                                                size?: number | null
                                                mime?: string | null
                                                extension?: string | null
                                                allowCaching?: boolean | null
                                                mediaType?: Types.MediaType | null
                                                src?: string | null
                                                language?: string | null
                                              }
                                            | {
                                                __typename: "NoMediaInfo"
                                                id: string
                                                mediaType?: Types.MediaType | null
                                              }
                                            | {
                                                __typename: "VectorGraphicsMediaInfo"
                                                id: string
                                                resourceId?: string | null
                                                thumbnail?: string | null
                                                width?: number | null
                                                height?: number | null
                                                allowCaching?: boolean | null
                                                mediaType?: Types.MediaType | null
                                                language?: string | null
                                                labels: Array<{
                                                  __typename: "VectorGraphicsLabel"
                                                  id?: string | null
                                                  sequence?: number | null
                                                  autoValue?: string | null
                                                  text?: string | null
                                                  linkType?: Types.VectorGraphicsLabelLinkType | null
                                                  link?: string | null
                                                  ref?:
                                                    | {
                                                        __typename: "GenericRef"
                                                        id: string
                                                        owner?:
                                                          | { __typename: "CommentNode" }
                                                          | { __typename: "ExternalSite" }
                                                          | { __typename: "ST4Class" }
                                                          | { __typename: "ST4Node"; id: string }
                                                          | { __typename: "ST4TreeNode" }
                                                          | null
                                                        target?:
                                                          | { __typename: "CommentNode" }
                                                          | {
                                                              __typename: "ExternalSite"
                                                              id: string
                                                              teaserData?: {
                                                                __typename: "ExternalLinkTeaserData"
                                                                title?: string | null
                                                                summary?: string | null
                                                                iconUrl?: string | null
                                                                imageUrl?: string | null
                                                                status?: Types.LinkTargetStatus | null
                                                              } | null
                                                            }
                                                          | { __typename: "ST4Class" }
                                                          | {
                                                              __typename: "ST4Node"
                                                              id: string
                                                              aspectId: any
                                                              label?: string | null
                                                              properties?: {
                                                                __typename: "ContentPropertyConnection"
                                                                edges?: Array<{
                                                                  __typename: "ContentPropertyEdge"
                                                                  node?: {
                                                                    __typename: "ContentProperty"
                                                                    name: string
                                                                    value?: any | null
                                                                    displayName: string
                                                                  } | null
                                                                } | null> | null
                                                              } | null
                                                            }
                                                          | { __typename: "ST4TreeNode" }
                                                          | null
                                                      }
                                                    | {
                                                        __typename: "NodeRef"
                                                        id: string
                                                        owner?:
                                                          | { __typename: "CommentNode" }
                                                          | { __typename: "ExternalSite" }
                                                          | { __typename: "ST4Class" }
                                                          | { __typename: "ST4Node"; id: string }
                                                          | { __typename: "ST4TreeNode" }
                                                          | null
                                                        target?:
                                                          | { __typename: "CommentNode" }
                                                          | {
                                                              __typename: "ExternalSite"
                                                              id: string
                                                              teaserData?: {
                                                                __typename: "ExternalLinkTeaserData"
                                                                title?: string | null
                                                                summary?: string | null
                                                                iconUrl?: string | null
                                                                imageUrl?: string | null
                                                                status?: Types.LinkTargetStatus | null
                                                              } | null
                                                            }
                                                          | { __typename: "ST4Class" }
                                                          | {
                                                              __typename: "ST4Node"
                                                              id: string
                                                              aspectId: any
                                                              label?: string | null
                                                              properties?: {
                                                                __typename: "ContentPropertyConnection"
                                                                edges?: Array<{
                                                                  __typename: "ContentPropertyEdge"
                                                                  node?: {
                                                                    __typename: "ContentProperty"
                                                                    name: string
                                                                    value?: any | null
                                                                    displayName: string
                                                                  } | null
                                                                } | null> | null
                                                              } | null
                                                            }
                                                          | { __typename: "ST4TreeNode" }
                                                          | null
                                                      }
                                                    | null
                                                }>
                                              }
                                            | {
                                                __typename: "VideoMediaInfo"
                                                id: string
                                                resourceId?: string | null
                                                thumbnail?: string | null
                                                width?: number | null
                                                height?: number | null
                                                mediaType?: Types.MediaType | null
                                              }
                                            | null
                                        }
                                      | {
                                          __typename: "MediaGroupContent"
                                          children: Array<{
                                            __typename: "NodeRef"
                                            id: string
                                            target?:
                                              | { __typename: "CommentNode"; id: string }
                                              | { __typename: "ExternalSite"; id: string }
                                              | { __typename: "ST4Class"; id: string }
                                              | {
                                                  __typename: "ST4Node"
                                                  id: string
                                                  aspectId: any
                                                  content?:
                                                    | { __typename: "LexiconContent" }
                                                    | { __typename: "LexiconEntryContent" }
                                                    | {
                                                        __typename: "MediaContent"
                                                        media?:
                                                          | {
                                                              __typename: "AnimationMediaInfo"
                                                              id: string
                                                              resourceId?: string | null
                                                              thumbnail?: string | null
                                                              width?: number | null
                                                              height?: number | null
                                                              mediaType?: Types.MediaType | null
                                                            }
                                                          | {
                                                              __typename: "ImageMediaInfo"
                                                              id: string
                                                              resourceId?: string | null
                                                              thumbnail?: string | null
                                                              width?: number | null
                                                              height?: number | null
                                                              resolution?: number | null
                                                              colorDepth?: number | null
                                                              size?: number | null
                                                              mime?: string | null
                                                              extension?: string | null
                                                              allowCaching?: boolean | null
                                                              mediaType?: Types.MediaType | null
                                                              src?: string | null
                                                              language?: string | null
                                                            }
                                                          | {
                                                              __typename: "NoMediaInfo"
                                                              id: string
                                                              mediaType?: Types.MediaType | null
                                                            }
                                                          | {
                                                              __typename: "VectorGraphicsMediaInfo"
                                                              id: string
                                                              resourceId?: string | null
                                                              thumbnail?: string | null
                                                              width?: number | null
                                                              height?: number | null
                                                              allowCaching?: boolean | null
                                                              mediaType?: Types.MediaType | null
                                                              language?: string | null
                                                              labels: Array<{
                                                                __typename: "VectorGraphicsLabel"
                                                                id?: string | null
                                                                sequence?: number | null
                                                                autoValue?: string | null
                                                                text?: string | null
                                                                linkType?: Types.VectorGraphicsLabelLinkType | null
                                                                link?: string | null
                                                                ref?:
                                                                  | {
                                                                      __typename: "GenericRef"
                                                                      id: string
                                                                      owner?:
                                                                        | { __typename: "CommentNode" }
                                                                        | { __typename: "ExternalSite" }
                                                                        | { __typename: "ST4Class" }
                                                                        | { __typename: "ST4Node"; id: string }
                                                                        | { __typename: "ST4TreeNode" }
                                                                        | null
                                                                      target?:
                                                                        | { __typename: "CommentNode" }
                                                                        | {
                                                                            __typename: "ExternalSite"
                                                                            id: string
                                                                            teaserData?: {
                                                                              __typename: "ExternalLinkTeaserData"
                                                                              title?: string | null
                                                                              summary?: string | null
                                                                              iconUrl?: string | null
                                                                              imageUrl?: string | null
                                                                              status?: Types.LinkTargetStatus | null
                                                                            } | null
                                                                          }
                                                                        | { __typename: "ST4Class" }
                                                                        | {
                                                                            __typename: "ST4Node"
                                                                            id: string
                                                                            aspectId: any
                                                                            label?: string | null
                                                                            properties?: {
                                                                              __typename: "ContentPropertyConnection"
                                                                              edges?: Array<{
                                                                                __typename: "ContentPropertyEdge"
                                                                                node?: {
                                                                                  __typename: "ContentProperty"
                                                                                  name: string
                                                                                  value?: any | null
                                                                                  displayName: string
                                                                                } | null
                                                                              } | null> | null
                                                                            } | null
                                                                          }
                                                                        | { __typename: "ST4TreeNode" }
                                                                        | null
                                                                    }
                                                                  | {
                                                                      __typename: "NodeRef"
                                                                      id: string
                                                                      owner?:
                                                                        | { __typename: "CommentNode" }
                                                                        | { __typename: "ExternalSite" }
                                                                        | { __typename: "ST4Class" }
                                                                        | { __typename: "ST4Node"; id: string }
                                                                        | { __typename: "ST4TreeNode" }
                                                                        | null
                                                                      target?:
                                                                        | { __typename: "CommentNode" }
                                                                        | {
                                                                            __typename: "ExternalSite"
                                                                            id: string
                                                                            teaserData?: {
                                                                              __typename: "ExternalLinkTeaserData"
                                                                              title?: string | null
                                                                              summary?: string | null
                                                                              iconUrl?: string | null
                                                                              imageUrl?: string | null
                                                                              status?: Types.LinkTargetStatus | null
                                                                            } | null
                                                                          }
                                                                        | { __typename: "ST4Class" }
                                                                        | {
                                                                            __typename: "ST4Node"
                                                                            id: string
                                                                            aspectId: any
                                                                            label?: string | null
                                                                            properties?: {
                                                                              __typename: "ContentPropertyConnection"
                                                                              edges?: Array<{
                                                                                __typename: "ContentPropertyEdge"
                                                                                node?: {
                                                                                  __typename: "ContentProperty"
                                                                                  name: string
                                                                                  value?: any | null
                                                                                  displayName: string
                                                                                } | null
                                                                              } | null> | null
                                                                            } | null
                                                                          }
                                                                        | { __typename: "ST4TreeNode" }
                                                                        | null
                                                                    }
                                                                  | null
                                                              }>
                                                            }
                                                          | {
                                                              __typename: "VideoMediaInfo"
                                                              id: string
                                                              resourceId?: string | null
                                                              thumbnail?: string | null
                                                              width?: number | null
                                                              height?: number | null
                                                              mediaType?: Types.MediaType | null
                                                            }
                                                          | null
                                                      }
                                                    | {
                                                        __typename: "MediaGroupContent"
                                                        media?:
                                                          | {
                                                              __typename: "AnimationMediaInfo"
                                                              id: string
                                                              resourceId?: string | null
                                                              thumbnail?: string | null
                                                              width?: number | null
                                                              height?: number | null
                                                              mediaType?: Types.MediaType | null
                                                            }
                                                          | {
                                                              __typename: "ImageMediaInfo"
                                                              id: string
                                                              resourceId?: string | null
                                                              thumbnail?: string | null
                                                              width?: number | null
                                                              height?: number | null
                                                              resolution?: number | null
                                                              colorDepth?: number | null
                                                              size?: number | null
                                                              mime?: string | null
                                                              extension?: string | null
                                                              allowCaching?: boolean | null
                                                              mediaType?: Types.MediaType | null
                                                              src?: string | null
                                                              language?: string | null
                                                            }
                                                          | {
                                                              __typename: "NoMediaInfo"
                                                              id: string
                                                              mediaType?: Types.MediaType | null
                                                            }
                                                          | {
                                                              __typename: "VectorGraphicsMediaInfo"
                                                              id: string
                                                              resourceId?: string | null
                                                              thumbnail?: string | null
                                                              width?: number | null
                                                              height?: number | null
                                                              allowCaching?: boolean | null
                                                              mediaType?: Types.MediaType | null
                                                              language?: string | null
                                                              labels: Array<{
                                                                __typename: "VectorGraphicsLabel"
                                                                id?: string | null
                                                                sequence?: number | null
                                                                autoValue?: string | null
                                                                text?: string | null
                                                                linkType?: Types.VectorGraphicsLabelLinkType | null
                                                                link?: string | null
                                                                ref?:
                                                                  | {
                                                                      __typename: "GenericRef"
                                                                      id: string
                                                                      owner?:
                                                                        | { __typename: "CommentNode" }
                                                                        | { __typename: "ExternalSite" }
                                                                        | { __typename: "ST4Class" }
                                                                        | { __typename: "ST4Node"; id: string }
                                                                        | { __typename: "ST4TreeNode" }
                                                                        | null
                                                                      target?:
                                                                        | { __typename: "CommentNode" }
                                                                        | {
                                                                            __typename: "ExternalSite"
                                                                            id: string
                                                                            teaserData?: {
                                                                              __typename: "ExternalLinkTeaserData"
                                                                              title?: string | null
                                                                              summary?: string | null
                                                                              iconUrl?: string | null
                                                                              imageUrl?: string | null
                                                                              status?: Types.LinkTargetStatus | null
                                                                            } | null
                                                                          }
                                                                        | { __typename: "ST4Class" }
                                                                        | {
                                                                            __typename: "ST4Node"
                                                                            id: string
                                                                            aspectId: any
                                                                            label?: string | null
                                                                            properties?: {
                                                                              __typename: "ContentPropertyConnection"
                                                                              edges?: Array<{
                                                                                __typename: "ContentPropertyEdge"
                                                                                node?: {
                                                                                  __typename: "ContentProperty"
                                                                                  name: string
                                                                                  value?: any | null
                                                                                  displayName: string
                                                                                } | null
                                                                              } | null> | null
                                                                            } | null
                                                                          }
                                                                        | { __typename: "ST4TreeNode" }
                                                                        | null
                                                                    }
                                                                  | {
                                                                      __typename: "NodeRef"
                                                                      id: string
                                                                      owner?:
                                                                        | { __typename: "CommentNode" }
                                                                        | { __typename: "ExternalSite" }
                                                                        | { __typename: "ST4Class" }
                                                                        | { __typename: "ST4Node"; id: string }
                                                                        | { __typename: "ST4TreeNode" }
                                                                        | null
                                                                      target?:
                                                                        | { __typename: "CommentNode" }
                                                                        | {
                                                                            __typename: "ExternalSite"
                                                                            id: string
                                                                            teaserData?: {
                                                                              __typename: "ExternalLinkTeaserData"
                                                                              title?: string | null
                                                                              summary?: string | null
                                                                              iconUrl?: string | null
                                                                              imageUrl?: string | null
                                                                              status?: Types.LinkTargetStatus | null
                                                                            } | null
                                                                          }
                                                                        | { __typename: "ST4Class" }
                                                                        | {
                                                                            __typename: "ST4Node"
                                                                            id: string
                                                                            aspectId: any
                                                                            label?: string | null
                                                                            properties?: {
                                                                              __typename: "ContentPropertyConnection"
                                                                              edges?: Array<{
                                                                                __typename: "ContentPropertyEdge"
                                                                                node?: {
                                                                                  __typename: "ContentProperty"
                                                                                  name: string
                                                                                  value?: any | null
                                                                                  displayName: string
                                                                                } | null
                                                                              } | null> | null
                                                                            } | null
                                                                          }
                                                                        | { __typename: "ST4TreeNode" }
                                                                        | null
                                                                    }
                                                                  | null
                                                              }>
                                                            }
                                                          | {
                                                              __typename: "VideoMediaInfo"
                                                              id: string
                                                              resourceId?: string | null
                                                              thumbnail?: string | null
                                                              width?: number | null
                                                              height?: number | null
                                                              mediaType?: Types.MediaType | null
                                                            }
                                                          | null
                                                      }
                                                    | { __typename: "ProjectContent" }
                                                    | { __typename: "TermContent" }
                                                    | { __typename: "TextContent" }
                                                    | { __typename: "TextGroupContent" }
                                                    | { __typename: "VariableTableContent" }
                                                    | null
                                                }
                                              | { __typename: "ST4TreeNode"; id: string }
                                              | null
                                          }>
                                          media?:
                                            | {
                                                __typename: "AnimationMediaInfo"
                                                id: string
                                                resourceId?: string | null
                                                thumbnail?: string | null
                                                width?: number | null
                                                height?: number | null
                                                mediaType?: Types.MediaType | null
                                              }
                                            | {
                                                __typename: "ImageMediaInfo"
                                                id: string
                                                resourceId?: string | null
                                                thumbnail?: string | null
                                                width?: number | null
                                                height?: number | null
                                                resolution?: number | null
                                                colorDepth?: number | null
                                                size?: number | null
                                                mime?: string | null
                                                extension?: string | null
                                                allowCaching?: boolean | null
                                                mediaType?: Types.MediaType | null
                                                src?: string | null
                                                language?: string | null
                                              }
                                            | {
                                                __typename: "NoMediaInfo"
                                                id: string
                                                mediaType?: Types.MediaType | null
                                              }
                                            | {
                                                __typename: "VectorGraphicsMediaInfo"
                                                id: string
                                                resourceId?: string | null
                                                thumbnail?: string | null
                                                width?: number | null
                                                height?: number | null
                                                allowCaching?: boolean | null
                                                mediaType?: Types.MediaType | null
                                                language?: string | null
                                                labels: Array<{
                                                  __typename: "VectorGraphicsLabel"
                                                  id?: string | null
                                                  sequence?: number | null
                                                  autoValue?: string | null
                                                  text?: string | null
                                                  linkType?: Types.VectorGraphicsLabelLinkType | null
                                                  link?: string | null
                                                  ref?:
                                                    | {
                                                        __typename: "GenericRef"
                                                        id: string
                                                        owner?:
                                                          | { __typename: "CommentNode" }
                                                          | { __typename: "ExternalSite" }
                                                          | { __typename: "ST4Class" }
                                                          | { __typename: "ST4Node"; id: string }
                                                          | { __typename: "ST4TreeNode" }
                                                          | null
                                                        target?:
                                                          | { __typename: "CommentNode" }
                                                          | {
                                                              __typename: "ExternalSite"
                                                              id: string
                                                              teaserData?: {
                                                                __typename: "ExternalLinkTeaserData"
                                                                title?: string | null
                                                                summary?: string | null
                                                                iconUrl?: string | null
                                                                imageUrl?: string | null
                                                                status?: Types.LinkTargetStatus | null
                                                              } | null
                                                            }
                                                          | { __typename: "ST4Class" }
                                                          | {
                                                              __typename: "ST4Node"
                                                              id: string
                                                              aspectId: any
                                                              label?: string | null
                                                              properties?: {
                                                                __typename: "ContentPropertyConnection"
                                                                edges?: Array<{
                                                                  __typename: "ContentPropertyEdge"
                                                                  node?: {
                                                                    __typename: "ContentProperty"
                                                                    name: string
                                                                    value?: any | null
                                                                    displayName: string
                                                                  } | null
                                                                } | null> | null
                                                              } | null
                                                            }
                                                          | { __typename: "ST4TreeNode" }
                                                          | null
                                                      }
                                                    | {
                                                        __typename: "NodeRef"
                                                        id: string
                                                        owner?:
                                                          | { __typename: "CommentNode" }
                                                          | { __typename: "ExternalSite" }
                                                          | { __typename: "ST4Class" }
                                                          | { __typename: "ST4Node"; id: string }
                                                          | { __typename: "ST4TreeNode" }
                                                          | null
                                                        target?:
                                                          | { __typename: "CommentNode" }
                                                          | {
                                                              __typename: "ExternalSite"
                                                              id: string
                                                              teaserData?: {
                                                                __typename: "ExternalLinkTeaserData"
                                                                title?: string | null
                                                                summary?: string | null
                                                                iconUrl?: string | null
                                                                imageUrl?: string | null
                                                                status?: Types.LinkTargetStatus | null
                                                              } | null
                                                            }
                                                          | { __typename: "ST4Class" }
                                                          | {
                                                              __typename: "ST4Node"
                                                              id: string
                                                              aspectId: any
                                                              label?: string | null
                                                              properties?: {
                                                                __typename: "ContentPropertyConnection"
                                                                edges?: Array<{
                                                                  __typename: "ContentPropertyEdge"
                                                                  node?: {
                                                                    __typename: "ContentProperty"
                                                                    name: string
                                                                    value?: any | null
                                                                    displayName: string
                                                                  } | null
                                                                } | null> | null
                                                              } | null
                                                            }
                                                          | { __typename: "ST4TreeNode" }
                                                          | null
                                                      }
                                                    | null
                                                }>
                                              }
                                            | {
                                                __typename: "VideoMediaInfo"
                                                id: string
                                                resourceId?: string | null
                                                thumbnail?: string | null
                                                width?: number | null
                                                height?: number | null
                                                mediaType?: Types.MediaType | null
                                              }
                                            | null
                                        }
                                      | { __typename: "ProjectContent" }
                                      | { __typename: "TermContent" }
                                      | { __typename: "TextContent" }
                                      | { __typename: "TextGroupContent" }
                                      | { __typename: "VariableTableContent" }
                                      | null
                                  }
                                | { __typename: "ST4TreeNode"; id: string }
                                | null
                            }>
                            variableTables?: Array<{
                              __typename: "VariableTableReference"
                              ref?: string | null
                              node?:
                                | { __typename: "CommentNode" }
                                | { __typename: "ExternalSite" }
                                | { __typename: "ST4Class" }
                                | {
                                    __typename: "ST4Node"
                                    id: string
                                    label?: string | null
                                    aspectId: any
                                    content?:
                                      | { __typename: "LexiconContent" }
                                      | { __typename: "LexiconEntryContent" }
                                      | { __typename: "MediaContent" }
                                      | { __typename: "MediaGroupContent" }
                                      | { __typename: "ProjectContent" }
                                      | { __typename: "TermContent" }
                                      | { __typename: "TextContent" }
                                      | { __typename: "TextGroupContent" }
                                      | {
                                          __typename: "VariableTableContent"
                                          xml?: string | null
                                          valueSets: Array<{ __typename: "ValueSet"; name: string; valueSetId: string }>
                                          variables: Array<{
                                            __typename: "Variable"
                                            name: string
                                            variableId: string
                                            values: Array<string>
                                          }>
                                        }
                                      | null
                                  }
                                | { __typename: "ST4TreeNode" }
                                | null
                            } | null> | null
                            linkedDataNodes: Array<{
                              __typename: "NodeRef"
                              id: string
                              target?:
                                | { __typename: "CommentNode"; id: string }
                                | { __typename: "ExternalSite"; id: string }
                                | { __typename: "ST4Class"; id: string }
                                | {
                                    __typename: "ST4Node"
                                    label?: string | null
                                    id: string
                                    metadata: Array<{
                                      __typename: "MetaDataEntry"
                                      label?: string | null
                                      systemName: string
                                      value?: string | null
                                      values: Array<string>
                                      users: Array<string>
                                    }>
                                    linkedOntologyNodes: Array<
                                      | {
                                          __typename: "GenericRef"
                                          target?:
                                            | { __typename: "CommentNode" }
                                            | { __typename: "ExternalSite" }
                                            | { __typename: "ST4Class" }
                                            | { __typename: "ST4Node"; id: string; label?: string | null }
                                            | { __typename: "ST4TreeNode" }
                                            | null
                                        }
                                      | {
                                          __typename: "NodeRef"
                                          linkClassName: string
                                          class?: { __typename: "ST4Class"; label?: string | null } | null
                                          target?:
                                            | { __typename: "CommentNode" }
                                            | { __typename: "ExternalSite" }
                                            | { __typename: "ST4Class" }
                                            | { __typename: "ST4Node"; id: string; label?: string | null }
                                            | { __typename: "ST4TreeNode" }
                                            | null
                                        }
                                    >
                                  }
                                | { __typename: "ST4TreeNode"; id: string }
                                | null
                            }>
                            lexiconEntries?: Array<{
                              __typename: "NodeRef"
                              id: string
                              target?:
                                | { __typename: "CommentNode"; id: string }
                                | { __typename: "ExternalSite"; id: string }
                                | { __typename: "ST4Class"; id: string }
                                | {
                                    __typename: "ST4Node"
                                    aspectId: any
                                    label?: string | null
                                    id: string
                                    content?:
                                      | { __typename: "LexiconContent" }
                                      | {
                                          __typename: "LexiconEntryContent"
                                          descriptionXast?: string | null
                                          subject?: string | null
                                          sortKey?: string | null
                                          lexicon:
                                            | { __typename: "CommentNode"; id: string }
                                            | { __typename: "ExternalSite"; id: string }
                                            | { __typename: "ST4Class"; id: string }
                                            | { __typename: "ST4Node"; label?: string | null; id: string }
                                            | { __typename: "ST4TreeNode"; id: string }
                                          terms: Array<
                                            | { __typename: "CommentNode"; id: string }
                                            | { __typename: "ExternalSite"; id: string }
                                            | { __typename: "ST4Class"; id: string }
                                            | {
                                                __typename: "ST4Node"
                                                id: string
                                                label?: string | null
                                                aspectId: any
                                                content?:
                                                  | { __typename: "LexiconContent" }
                                                  | { __typename: "LexiconEntryContent" }
                                                  | { __typename: "MediaContent" }
                                                  | { __typename: "MediaGroupContent" }
                                                  | { __typename: "ProjectContent" }
                                                  | {
                                                      __typename: "TermContent"
                                                      gramaticalGender?: string | null
                                                      validity?: string | null
                                                      editLanguage?: string | null
                                                    }
                                                  | { __typename: "TextContent" }
                                                  | { __typename: "TextGroupContent" }
                                                  | { __typename: "VariableTableContent" }
                                                  | null
                                              }
                                            | { __typename: "ST4TreeNode"; id: string }
                                          >
                                          image?:
                                            | { __typename: "CommentNode"; id: string }
                                            | { __typename: "ExternalSite"; id: string }
                                            | { __typename: "ST4Class"; id: string }
                                            | {
                                                __typename: "ST4Node"
                                                aspectId: any
                                                id: string
                                                content?:
                                                  | { __typename: "LexiconContent" }
                                                  | { __typename: "LexiconEntryContent" }
                                                  | {
                                                      __typename: "MediaContent"
                                                      media?:
                                                        | {
                                                            __typename: "AnimationMediaInfo"
                                                            thumbnail?: string | null
                                                            mediaType?: Types.MediaType | null
                                                          }
                                                        | {
                                                            __typename: "ImageMediaInfo"
                                                            src?: string | null
                                                            thumbnail?: string | null
                                                            mediaType?: Types.MediaType | null
                                                          }
                                                        | { __typename: "NoMediaInfo" }
                                                        | {
                                                            __typename: "VectorGraphicsMediaInfo"
                                                            svg?: string | null
                                                            thumbnail?: string | null
                                                            mediaType?: Types.MediaType | null
                                                          }
                                                        | {
                                                            __typename: "VideoMediaInfo"
                                                            thumbnail?: string | null
                                                            mediaType?: Types.MediaType | null
                                                          }
                                                        | null
                                                    }
                                                  | { __typename: "MediaGroupContent" }
                                                  | { __typename: "ProjectContent" }
                                                  | { __typename: "TermContent" }
                                                  | { __typename: "TextContent" }
                                                  | { __typename: "TextGroupContent" }
                                                  | { __typename: "VariableTableContent" }
                                                  | null
                                              }
                                            | { __typename: "ST4TreeNode"; id: string }
                                            | null
                                        }
                                      | { __typename: "MediaContent" }
                                      | { __typename: "MediaGroupContent" }
                                      | { __typename: "ProjectContent" }
                                      | { __typename: "TermContent" }
                                      | { __typename: "TextContent" }
                                      | { __typename: "TextGroupContent" }
                                      | { __typename: "VariableTableContent" }
                                      | null
                                  }
                                | { __typename: "ST4TreeNode"; id: string }
                                | null
                            } | null> | null
                            comments: Array<{
                              __typename: "CommentNode"
                              id: string
                              comment:
                                | {
                                    __typename: "BlockComment"
                                    location: number
                                    value?: string | null
                                    authorDisplayName?: string | null
                                    authorUserName?: string | null
                                    commentKey: string
                                    editDate?: any | null
                                    modificationVersion?: number | null
                                    replyToKey?: string | null
                                    sortOrder?: number | null
                                    commentType?: string | null
                                    state?: string | null
                                    color: string
                                    persistanceState?: Types.PersistanceState | null
                                    persistanceAction?: Types.PersistanceAction | null
                                    node: {
                                      __typename: "ST4Node"
                                      aspectId: any
                                      id: string
                                      content?:
                                        | { __typename: "LexiconContent" }
                                        | { __typename: "LexiconEntryContent" }
                                        | { __typename: "MediaContent" }
                                        | { __typename: "MediaGroupContent" }
                                        | { __typename: "ProjectContent" }
                                        | { __typename: "TermContent" }
                                        | { __typename: "TextContent"; id: string; historyNumber: number }
                                        | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                                        | { __typename: "VariableTableContent" }
                                        | null
                                    }
                                    actions: Array<{
                                      __typename: "Action"
                                      name?: string | null
                                      enabled?: boolean | null
                                    }>
                                  }
                                | {
                                    __typename: "TextComment"
                                    endLocation: number
                                    startLocation?: number | null
                                    value?: string | null
                                    authorDisplayName?: string | null
                                    authorUserName?: string | null
                                    commentKey: string
                                    editDate?: any | null
                                    modificationVersion?: number | null
                                    replyToKey?: string | null
                                    sortOrder?: number | null
                                    commentType?: string | null
                                    state?: string | null
                                    color: string
                                    persistanceState?: Types.PersistanceState | null
                                    persistanceAction?: Types.PersistanceAction | null
                                    node: {
                                      __typename: "ST4Node"
                                      aspectId: any
                                      id: string
                                      content?:
                                        | { __typename: "LexiconContent" }
                                        | { __typename: "LexiconEntryContent" }
                                        | { __typename: "MediaContent" }
                                        | { __typename: "MediaGroupContent" }
                                        | { __typename: "ProjectContent" }
                                        | { __typename: "TermContent" }
                                        | { __typename: "TextContent"; id: string; historyNumber: number }
                                        | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                                        | { __typename: "VariableTableContent" }
                                        | null
                                    }
                                    actions: Array<{
                                      __typename: "Action"
                                      name?: string | null
                                      enabled?: boolean | null
                                    }>
                                  }
                            }>
                          }
                        | {
                            __typename: "TextGroupContent"
                            currentNodeId?: any | null
                            groupNodeId: any
                            id: string
                            xast?: string | null
                            historyNumber: number
                            children: Array<{
                              __typename: "NodeRef"
                              id: string
                              target?:
                                | { __typename: "CommentNode"; id: string }
                                | { __typename: "ExternalSite"; id: string }
                                | { __typename: "ST4Class"; id: string }
                                | { __typename: "ST4Node"; label?: string | null; aspectId: any; id: string }
                                | { __typename: "ST4TreeNode"; id: string }
                                | null
                            }>
                            links?: Array<{
                              __typename: "TextContentLink"
                              ref?: string | null
                              node?:
                                | { __typename: "CommentNode"; id: string }
                                | { __typename: "ExternalSite"; id: string }
                                | { __typename: "ST4Class"; id: string }
                                | {
                                    __typename: "ST4Node"
                                    id: string
                                    aspectId: any
                                    label?: string | null
                                    properties?: {
                                      __typename: "ContentPropertyConnection"
                                      totalCount: number
                                      edges?: Array<{
                                        __typename: "ContentPropertyEdge"
                                        node?: {
                                          __typename: "ContentProperty"
                                          typeOfValue: Types.ContentPropertyValueType
                                          name: string
                                          value?: any | null
                                          displayName: string
                                        } | null
                                      } | null> | null
                                    } | null
                                  }
                                | { __typename: "ST4TreeNode"; id: string }
                                | null
                            } | null> | null
                            images: Array<{
                              __typename: "NodeRef"
                              id: string
                              target?:
                                | { __typename: "CommentNode"; id: string }
                                | { __typename: "ExternalSite"; id: string }
                                | { __typename: "ST4Class"; id: string }
                                | {
                                    __typename: "ST4Node"
                                    aspectId: any
                                    id: string
                                    releaseState: {
                                      __typename: "ReleaseStateValue"
                                      displayName: string
                                      state: Types.ReleaseState
                                      isImplicitlyReleased: boolean
                                    }
                                    content?:
                                      | { __typename: "LexiconContent" }
                                      | { __typename: "LexiconEntryContent" }
                                      | {
                                          __typename: "MediaContent"
                                          media?:
                                            | {
                                                __typename: "AnimationMediaInfo"
                                                id: string
                                                resourceId?: string | null
                                                thumbnail?: string | null
                                                width?: number | null
                                                height?: number | null
                                                mediaType?: Types.MediaType | null
                                              }
                                            | {
                                                __typename: "ImageMediaInfo"
                                                id: string
                                                resourceId?: string | null
                                                thumbnail?: string | null
                                                width?: number | null
                                                height?: number | null
                                                resolution?: number | null
                                                colorDepth?: number | null
                                                size?: number | null
                                                mime?: string | null
                                                extension?: string | null
                                                allowCaching?: boolean | null
                                                mediaType?: Types.MediaType | null
                                                src?: string | null
                                                language?: string | null
                                              }
                                            | {
                                                __typename: "NoMediaInfo"
                                                id: string
                                                mediaType?: Types.MediaType | null
                                              }
                                            | {
                                                __typename: "VectorGraphicsMediaInfo"
                                                id: string
                                                resourceId?: string | null
                                                thumbnail?: string | null
                                                width?: number | null
                                                height?: number | null
                                                allowCaching?: boolean | null
                                                mediaType?: Types.MediaType | null
                                                language?: string | null
                                                labels: Array<{
                                                  __typename: "VectorGraphicsLabel"
                                                  id?: string | null
                                                  sequence?: number | null
                                                  autoValue?: string | null
                                                  text?: string | null
                                                  linkType?: Types.VectorGraphicsLabelLinkType | null
                                                  link?: string | null
                                                  ref?:
                                                    | {
                                                        __typename: "GenericRef"
                                                        id: string
                                                        owner?:
                                                          | { __typename: "CommentNode" }
                                                          | { __typename: "ExternalSite" }
                                                          | { __typename: "ST4Class" }
                                                          | { __typename: "ST4Node"; id: string }
                                                          | { __typename: "ST4TreeNode" }
                                                          | null
                                                        target?:
                                                          | { __typename: "CommentNode" }
                                                          | {
                                                              __typename: "ExternalSite"
                                                              id: string
                                                              teaserData?: {
                                                                __typename: "ExternalLinkTeaserData"
                                                                title?: string | null
                                                                summary?: string | null
                                                                iconUrl?: string | null
                                                                imageUrl?: string | null
                                                                status?: Types.LinkTargetStatus | null
                                                              } | null
                                                            }
                                                          | { __typename: "ST4Class" }
                                                          | {
                                                              __typename: "ST4Node"
                                                              id: string
                                                              aspectId: any
                                                              label?: string | null
                                                              properties?: {
                                                                __typename: "ContentPropertyConnection"
                                                                edges?: Array<{
                                                                  __typename: "ContentPropertyEdge"
                                                                  node?: {
                                                                    __typename: "ContentProperty"
                                                                    name: string
                                                                    value?: any | null
                                                                    displayName: string
                                                                  } | null
                                                                } | null> | null
                                                              } | null
                                                            }
                                                          | { __typename: "ST4TreeNode" }
                                                          | null
                                                      }
                                                    | {
                                                        __typename: "NodeRef"
                                                        id: string
                                                        owner?:
                                                          | { __typename: "CommentNode" }
                                                          | { __typename: "ExternalSite" }
                                                          | { __typename: "ST4Class" }
                                                          | { __typename: "ST4Node"; id: string }
                                                          | { __typename: "ST4TreeNode" }
                                                          | null
                                                        target?:
                                                          | { __typename: "CommentNode" }
                                                          | {
                                                              __typename: "ExternalSite"
                                                              id: string
                                                              teaserData?: {
                                                                __typename: "ExternalLinkTeaserData"
                                                                title?: string | null
                                                                summary?: string | null
                                                                iconUrl?: string | null
                                                                imageUrl?: string | null
                                                                status?: Types.LinkTargetStatus | null
                                                              } | null
                                                            }
                                                          | { __typename: "ST4Class" }
                                                          | {
                                                              __typename: "ST4Node"
                                                              id: string
                                                              aspectId: any
                                                              label?: string | null
                                                              properties?: {
                                                                __typename: "ContentPropertyConnection"
                                                                edges?: Array<{
                                                                  __typename: "ContentPropertyEdge"
                                                                  node?: {
                                                                    __typename: "ContentProperty"
                                                                    name: string
                                                                    value?: any | null
                                                                    displayName: string
                                                                  } | null
                                                                } | null> | null
                                                              } | null
                                                            }
                                                          | { __typename: "ST4TreeNode" }
                                                          | null
                                                      }
                                                    | null
                                                }>
                                              }
                                            | {
                                                __typename: "VideoMediaInfo"
                                                id: string
                                                resourceId?: string | null
                                                thumbnail?: string | null
                                                width?: number | null
                                                height?: number | null
                                                mediaType?: Types.MediaType | null
                                              }
                                            | null
                                        }
                                      | {
                                          __typename: "MediaGroupContent"
                                          children: Array<{
                                            __typename: "NodeRef"
                                            id: string
                                            target?:
                                              | { __typename: "CommentNode"; id: string }
                                              | { __typename: "ExternalSite"; id: string }
                                              | { __typename: "ST4Class"; id: string }
                                              | {
                                                  __typename: "ST4Node"
                                                  id: string
                                                  aspectId: any
                                                  content?:
                                                    | { __typename: "LexiconContent" }
                                                    | { __typename: "LexiconEntryContent" }
                                                    | {
                                                        __typename: "MediaContent"
                                                        media?:
                                                          | {
                                                              __typename: "AnimationMediaInfo"
                                                              id: string
                                                              resourceId?: string | null
                                                              thumbnail?: string | null
                                                              width?: number | null
                                                              height?: number | null
                                                              mediaType?: Types.MediaType | null
                                                            }
                                                          | {
                                                              __typename: "ImageMediaInfo"
                                                              id: string
                                                              resourceId?: string | null
                                                              thumbnail?: string | null
                                                              width?: number | null
                                                              height?: number | null
                                                              resolution?: number | null
                                                              colorDepth?: number | null
                                                              size?: number | null
                                                              mime?: string | null
                                                              extension?: string | null
                                                              allowCaching?: boolean | null
                                                              mediaType?: Types.MediaType | null
                                                              src?: string | null
                                                              language?: string | null
                                                            }
                                                          | {
                                                              __typename: "NoMediaInfo"
                                                              id: string
                                                              mediaType?: Types.MediaType | null
                                                            }
                                                          | {
                                                              __typename: "VectorGraphicsMediaInfo"
                                                              id: string
                                                              resourceId?: string | null
                                                              thumbnail?: string | null
                                                              width?: number | null
                                                              height?: number | null
                                                              allowCaching?: boolean | null
                                                              mediaType?: Types.MediaType | null
                                                              language?: string | null
                                                              labels: Array<{
                                                                __typename: "VectorGraphicsLabel"
                                                                id?: string | null
                                                                sequence?: number | null
                                                                autoValue?: string | null
                                                                text?: string | null
                                                                linkType?: Types.VectorGraphicsLabelLinkType | null
                                                                link?: string | null
                                                                ref?:
                                                                  | {
                                                                      __typename: "GenericRef"
                                                                      id: string
                                                                      owner?:
                                                                        | { __typename: "CommentNode" }
                                                                        | { __typename: "ExternalSite" }
                                                                        | { __typename: "ST4Class" }
                                                                        | { __typename: "ST4Node"; id: string }
                                                                        | { __typename: "ST4TreeNode" }
                                                                        | null
                                                                      target?:
                                                                        | { __typename: "CommentNode" }
                                                                        | {
                                                                            __typename: "ExternalSite"
                                                                            id: string
                                                                            teaserData?: {
                                                                              __typename: "ExternalLinkTeaserData"
                                                                              title?: string | null
                                                                              summary?: string | null
                                                                              iconUrl?: string | null
                                                                              imageUrl?: string | null
                                                                              status?: Types.LinkTargetStatus | null
                                                                            } | null
                                                                          }
                                                                        | { __typename: "ST4Class" }
                                                                        | {
                                                                            __typename: "ST4Node"
                                                                            id: string
                                                                            aspectId: any
                                                                            label?: string | null
                                                                            properties?: {
                                                                              __typename: "ContentPropertyConnection"
                                                                              edges?: Array<{
                                                                                __typename: "ContentPropertyEdge"
                                                                                node?: {
                                                                                  __typename: "ContentProperty"
                                                                                  name: string
                                                                                  value?: any | null
                                                                                  displayName: string
                                                                                } | null
                                                                              } | null> | null
                                                                            } | null
                                                                          }
                                                                        | { __typename: "ST4TreeNode" }
                                                                        | null
                                                                    }
                                                                  | {
                                                                      __typename: "NodeRef"
                                                                      id: string
                                                                      owner?:
                                                                        | { __typename: "CommentNode" }
                                                                        | { __typename: "ExternalSite" }
                                                                        | { __typename: "ST4Class" }
                                                                        | { __typename: "ST4Node"; id: string }
                                                                        | { __typename: "ST4TreeNode" }
                                                                        | null
                                                                      target?:
                                                                        | { __typename: "CommentNode" }
                                                                        | {
                                                                            __typename: "ExternalSite"
                                                                            id: string
                                                                            teaserData?: {
                                                                              __typename: "ExternalLinkTeaserData"
                                                                              title?: string | null
                                                                              summary?: string | null
                                                                              iconUrl?: string | null
                                                                              imageUrl?: string | null
                                                                              status?: Types.LinkTargetStatus | null
                                                                            } | null
                                                                          }
                                                                        | { __typename: "ST4Class" }
                                                                        | {
                                                                            __typename: "ST4Node"
                                                                            id: string
                                                                            aspectId: any
                                                                            label?: string | null
                                                                            properties?: {
                                                                              __typename: "ContentPropertyConnection"
                                                                              edges?: Array<{
                                                                                __typename: "ContentPropertyEdge"
                                                                                node?: {
                                                                                  __typename: "ContentProperty"
                                                                                  name: string
                                                                                  value?: any | null
                                                                                  displayName: string
                                                                                } | null
                                                                              } | null> | null
                                                                            } | null
                                                                          }
                                                                        | { __typename: "ST4TreeNode" }
                                                                        | null
                                                                    }
                                                                  | null
                                                              }>
                                                            }
                                                          | {
                                                              __typename: "VideoMediaInfo"
                                                              id: string
                                                              resourceId?: string | null
                                                              thumbnail?: string | null
                                                              width?: number | null
                                                              height?: number | null
                                                              mediaType?: Types.MediaType | null
                                                            }
                                                          | null
                                                      }
                                                    | {
                                                        __typename: "MediaGroupContent"
                                                        media?:
                                                          | {
                                                              __typename: "AnimationMediaInfo"
                                                              id: string
                                                              resourceId?: string | null
                                                              thumbnail?: string | null
                                                              width?: number | null
                                                              height?: number | null
                                                              mediaType?: Types.MediaType | null
                                                            }
                                                          | {
                                                              __typename: "ImageMediaInfo"
                                                              id: string
                                                              resourceId?: string | null
                                                              thumbnail?: string | null
                                                              width?: number | null
                                                              height?: number | null
                                                              resolution?: number | null
                                                              colorDepth?: number | null
                                                              size?: number | null
                                                              mime?: string | null
                                                              extension?: string | null
                                                              allowCaching?: boolean | null
                                                              mediaType?: Types.MediaType | null
                                                              src?: string | null
                                                              language?: string | null
                                                            }
                                                          | {
                                                              __typename: "NoMediaInfo"
                                                              id: string
                                                              mediaType?: Types.MediaType | null
                                                            }
                                                          | {
                                                              __typename: "VectorGraphicsMediaInfo"
                                                              id: string
                                                              resourceId?: string | null
                                                              thumbnail?: string | null
                                                              width?: number | null
                                                              height?: number | null
                                                              allowCaching?: boolean | null
                                                              mediaType?: Types.MediaType | null
                                                              language?: string | null
                                                              labels: Array<{
                                                                __typename: "VectorGraphicsLabel"
                                                                id?: string | null
                                                                sequence?: number | null
                                                                autoValue?: string | null
                                                                text?: string | null
                                                                linkType?: Types.VectorGraphicsLabelLinkType | null
                                                                link?: string | null
                                                                ref?:
                                                                  | {
                                                                      __typename: "GenericRef"
                                                                      id: string
                                                                      owner?:
                                                                        | { __typename: "CommentNode" }
                                                                        | { __typename: "ExternalSite" }
                                                                        | { __typename: "ST4Class" }
                                                                        | { __typename: "ST4Node"; id: string }
                                                                        | { __typename: "ST4TreeNode" }
                                                                        | null
                                                                      target?:
                                                                        | { __typename: "CommentNode" }
                                                                        | {
                                                                            __typename: "ExternalSite"
                                                                            id: string
                                                                            teaserData?: {
                                                                              __typename: "ExternalLinkTeaserData"
                                                                              title?: string | null
                                                                              summary?: string | null
                                                                              iconUrl?: string | null
                                                                              imageUrl?: string | null
                                                                              status?: Types.LinkTargetStatus | null
                                                                            } | null
                                                                          }
                                                                        | { __typename: "ST4Class" }
                                                                        | {
                                                                            __typename: "ST4Node"
                                                                            id: string
                                                                            aspectId: any
                                                                            label?: string | null
                                                                            properties?: {
                                                                              __typename: "ContentPropertyConnection"
                                                                              edges?: Array<{
                                                                                __typename: "ContentPropertyEdge"
                                                                                node?: {
                                                                                  __typename: "ContentProperty"
                                                                                  name: string
                                                                                  value?: any | null
                                                                                  displayName: string
                                                                                } | null
                                                                              } | null> | null
                                                                            } | null
                                                                          }
                                                                        | { __typename: "ST4TreeNode" }
                                                                        | null
                                                                    }
                                                                  | {
                                                                      __typename: "NodeRef"
                                                                      id: string
                                                                      owner?:
                                                                        | { __typename: "CommentNode" }
                                                                        | { __typename: "ExternalSite" }
                                                                        | { __typename: "ST4Class" }
                                                                        | { __typename: "ST4Node"; id: string }
                                                                        | { __typename: "ST4TreeNode" }
                                                                        | null
                                                                      target?:
                                                                        | { __typename: "CommentNode" }
                                                                        | {
                                                                            __typename: "ExternalSite"
                                                                            id: string
                                                                            teaserData?: {
                                                                              __typename: "ExternalLinkTeaserData"
                                                                              title?: string | null
                                                                              summary?: string | null
                                                                              iconUrl?: string | null
                                                                              imageUrl?: string | null
                                                                              status?: Types.LinkTargetStatus | null
                                                                            } | null
                                                                          }
                                                                        | { __typename: "ST4Class" }
                                                                        | {
                                                                            __typename: "ST4Node"
                                                                            id: string
                                                                            aspectId: any
                                                                            label?: string | null
                                                                            properties?: {
                                                                              __typename: "ContentPropertyConnection"
                                                                              edges?: Array<{
                                                                                __typename: "ContentPropertyEdge"
                                                                                node?: {
                                                                                  __typename: "ContentProperty"
                                                                                  name: string
                                                                                  value?: any | null
                                                                                  displayName: string
                                                                                } | null
                                                                              } | null> | null
                                                                            } | null
                                                                          }
                                                                        | { __typename: "ST4TreeNode" }
                                                                        | null
                                                                    }
                                                                  | null
                                                              }>
                                                            }
                                                          | {
                                                              __typename: "VideoMediaInfo"
                                                              id: string
                                                              resourceId?: string | null
                                                              thumbnail?: string | null
                                                              width?: number | null
                                                              height?: number | null
                                                              mediaType?: Types.MediaType | null
                                                            }
                                                          | null
                                                      }
                                                    | { __typename: "ProjectContent" }
                                                    | { __typename: "TermContent" }
                                                    | { __typename: "TextContent" }
                                                    | { __typename: "TextGroupContent" }
                                                    | { __typename: "VariableTableContent" }
                                                    | null
                                                }
                                              | { __typename: "ST4TreeNode"; id: string }
                                              | null
                                          }>
                                          media?:
                                            | {
                                                __typename: "AnimationMediaInfo"
                                                id: string
                                                resourceId?: string | null
                                                thumbnail?: string | null
                                                width?: number | null
                                                height?: number | null
                                                mediaType?: Types.MediaType | null
                                              }
                                            | {
                                                __typename: "ImageMediaInfo"
                                                id: string
                                                resourceId?: string | null
                                                thumbnail?: string | null
                                                width?: number | null
                                                height?: number | null
                                                resolution?: number | null
                                                colorDepth?: number | null
                                                size?: number | null
                                                mime?: string | null
                                                extension?: string | null
                                                allowCaching?: boolean | null
                                                mediaType?: Types.MediaType | null
                                                src?: string | null
                                                language?: string | null
                                              }
                                            | {
                                                __typename: "NoMediaInfo"
                                                id: string
                                                mediaType?: Types.MediaType | null
                                              }
                                            | {
                                                __typename: "VectorGraphicsMediaInfo"
                                                id: string
                                                resourceId?: string | null
                                                thumbnail?: string | null
                                                width?: number | null
                                                height?: number | null
                                                allowCaching?: boolean | null
                                                mediaType?: Types.MediaType | null
                                                language?: string | null
                                                labels: Array<{
                                                  __typename: "VectorGraphicsLabel"
                                                  id?: string | null
                                                  sequence?: number | null
                                                  autoValue?: string | null
                                                  text?: string | null
                                                  linkType?: Types.VectorGraphicsLabelLinkType | null
                                                  link?: string | null
                                                  ref?:
                                                    | {
                                                        __typename: "GenericRef"
                                                        id: string
                                                        owner?:
                                                          | { __typename: "CommentNode" }
                                                          | { __typename: "ExternalSite" }
                                                          | { __typename: "ST4Class" }
                                                          | { __typename: "ST4Node"; id: string }
                                                          | { __typename: "ST4TreeNode" }
                                                          | null
                                                        target?:
                                                          | { __typename: "CommentNode" }
                                                          | {
                                                              __typename: "ExternalSite"
                                                              id: string
                                                              teaserData?: {
                                                                __typename: "ExternalLinkTeaserData"
                                                                title?: string | null
                                                                summary?: string | null
                                                                iconUrl?: string | null
                                                                imageUrl?: string | null
                                                                status?: Types.LinkTargetStatus | null
                                                              } | null
                                                            }
                                                          | { __typename: "ST4Class" }
                                                          | {
                                                              __typename: "ST4Node"
                                                              id: string
                                                              aspectId: any
                                                              label?: string | null
                                                              properties?: {
                                                                __typename: "ContentPropertyConnection"
                                                                edges?: Array<{
                                                                  __typename: "ContentPropertyEdge"
                                                                  node?: {
                                                                    __typename: "ContentProperty"
                                                                    name: string
                                                                    value?: any | null
                                                                    displayName: string
                                                                  } | null
                                                                } | null> | null
                                                              } | null
                                                            }
                                                          | { __typename: "ST4TreeNode" }
                                                          | null
                                                      }
                                                    | {
                                                        __typename: "NodeRef"
                                                        id: string
                                                        owner?:
                                                          | { __typename: "CommentNode" }
                                                          | { __typename: "ExternalSite" }
                                                          | { __typename: "ST4Class" }
                                                          | { __typename: "ST4Node"; id: string }
                                                          | { __typename: "ST4TreeNode" }
                                                          | null
                                                        target?:
                                                          | { __typename: "CommentNode" }
                                                          | {
                                                              __typename: "ExternalSite"
                                                              id: string
                                                              teaserData?: {
                                                                __typename: "ExternalLinkTeaserData"
                                                                title?: string | null
                                                                summary?: string | null
                                                                iconUrl?: string | null
                                                                imageUrl?: string | null
                                                                status?: Types.LinkTargetStatus | null
                                                              } | null
                                                            }
                                                          | { __typename: "ST4Class" }
                                                          | {
                                                              __typename: "ST4Node"
                                                              id: string
                                                              aspectId: any
                                                              label?: string | null
                                                              properties?: {
                                                                __typename: "ContentPropertyConnection"
                                                                edges?: Array<{
                                                                  __typename: "ContentPropertyEdge"
                                                                  node?: {
                                                                    __typename: "ContentProperty"
                                                                    name: string
                                                                    value?: any | null
                                                                    displayName: string
                                                                  } | null
                                                                } | null> | null
                                                              } | null
                                                            }
                                                          | { __typename: "ST4TreeNode" }
                                                          | null
                                                      }
                                                    | null
                                                }>
                                              }
                                            | {
                                                __typename: "VideoMediaInfo"
                                                id: string
                                                resourceId?: string | null
                                                thumbnail?: string | null
                                                width?: number | null
                                                height?: number | null
                                                mediaType?: Types.MediaType | null
                                              }
                                            | null
                                        }
                                      | { __typename: "ProjectContent" }
                                      | { __typename: "TermContent" }
                                      | { __typename: "TextContent" }
                                      | { __typename: "TextGroupContent" }
                                      | { __typename: "VariableTableContent" }
                                      | null
                                  }
                                | { __typename: "ST4TreeNode"; id: string }
                                | null
                            }>
                            variableTables?: Array<{
                              __typename: "VariableTableReference"
                              ref?: string | null
                              node?:
                                | { __typename: "CommentNode" }
                                | { __typename: "ExternalSite" }
                                | { __typename: "ST4Class" }
                                | {
                                    __typename: "ST4Node"
                                    id: string
                                    label?: string | null
                                    aspectId: any
                                    content?:
                                      | { __typename: "LexiconContent" }
                                      | { __typename: "LexiconEntryContent" }
                                      | { __typename: "MediaContent" }
                                      | { __typename: "MediaGroupContent" }
                                      | { __typename: "ProjectContent" }
                                      | { __typename: "TermContent" }
                                      | { __typename: "TextContent" }
                                      | { __typename: "TextGroupContent" }
                                      | {
                                          __typename: "VariableTableContent"
                                          xml?: string | null
                                          valueSets: Array<{ __typename: "ValueSet"; name: string; valueSetId: string }>
                                          variables: Array<{
                                            __typename: "Variable"
                                            name: string
                                            variableId: string
                                            values: Array<string>
                                          }>
                                        }
                                      | null
                                  }
                                | { __typename: "ST4TreeNode" }
                                | null
                            } | null> | null
                            linkedDataNodes: Array<{
                              __typename: "NodeRef"
                              id: string
                              target?:
                                | { __typename: "CommentNode"; id: string }
                                | { __typename: "ExternalSite"; id: string }
                                | { __typename: "ST4Class"; id: string }
                                | {
                                    __typename: "ST4Node"
                                    label?: string | null
                                    id: string
                                    metadata: Array<{
                                      __typename: "MetaDataEntry"
                                      label?: string | null
                                      systemName: string
                                      value?: string | null
                                      values: Array<string>
                                      users: Array<string>
                                    }>
                                    linkedOntologyNodes: Array<
                                      | {
                                          __typename: "GenericRef"
                                          target?:
                                            | { __typename: "CommentNode" }
                                            | { __typename: "ExternalSite" }
                                            | { __typename: "ST4Class" }
                                            | { __typename: "ST4Node"; id: string; label?: string | null }
                                            | { __typename: "ST4TreeNode" }
                                            | null
                                        }
                                      | {
                                          __typename: "NodeRef"
                                          linkClassName: string
                                          class?: { __typename: "ST4Class"; label?: string | null } | null
                                          target?:
                                            | { __typename: "CommentNode" }
                                            | { __typename: "ExternalSite" }
                                            | { __typename: "ST4Class" }
                                            | { __typename: "ST4Node"; id: string; label?: string | null }
                                            | { __typename: "ST4TreeNode" }
                                            | null
                                        }
                                    >
                                  }
                                | { __typename: "ST4TreeNode"; id: string }
                                | null
                            }>
                            lexiconEntries?: Array<{
                              __typename: "NodeRef"
                              id: string
                              target?:
                                | { __typename: "CommentNode"; id: string }
                                | { __typename: "ExternalSite"; id: string }
                                | { __typename: "ST4Class"; id: string }
                                | {
                                    __typename: "ST4Node"
                                    aspectId: any
                                    label?: string | null
                                    id: string
                                    content?:
                                      | { __typename: "LexiconContent" }
                                      | {
                                          __typename: "LexiconEntryContent"
                                          descriptionXast?: string | null
                                          subject?: string | null
                                          sortKey?: string | null
                                          lexicon:
                                            | { __typename: "CommentNode"; id: string }
                                            | { __typename: "ExternalSite"; id: string }
                                            | { __typename: "ST4Class"; id: string }
                                            | { __typename: "ST4Node"; label?: string | null; id: string }
                                            | { __typename: "ST4TreeNode"; id: string }
                                          terms: Array<
                                            | { __typename: "CommentNode"; id: string }
                                            | { __typename: "ExternalSite"; id: string }
                                            | { __typename: "ST4Class"; id: string }
                                            | {
                                                __typename: "ST4Node"
                                                id: string
                                                label?: string | null
                                                aspectId: any
                                                content?:
                                                  | { __typename: "LexiconContent" }
                                                  | { __typename: "LexiconEntryContent" }
                                                  | { __typename: "MediaContent" }
                                                  | { __typename: "MediaGroupContent" }
                                                  | { __typename: "ProjectContent" }
                                                  | {
                                                      __typename: "TermContent"
                                                      gramaticalGender?: string | null
                                                      validity?: string | null
                                                      editLanguage?: string | null
                                                    }
                                                  | { __typename: "TextContent" }
                                                  | { __typename: "TextGroupContent" }
                                                  | { __typename: "VariableTableContent" }
                                                  | null
                                              }
                                            | { __typename: "ST4TreeNode"; id: string }
                                          >
                                          image?:
                                            | { __typename: "CommentNode"; id: string }
                                            | { __typename: "ExternalSite"; id: string }
                                            | { __typename: "ST4Class"; id: string }
                                            | {
                                                __typename: "ST4Node"
                                                aspectId: any
                                                id: string
                                                content?:
                                                  | { __typename: "LexiconContent" }
                                                  | { __typename: "LexiconEntryContent" }
                                                  | {
                                                      __typename: "MediaContent"
                                                      media?:
                                                        | {
                                                            __typename: "AnimationMediaInfo"
                                                            thumbnail?: string | null
                                                            mediaType?: Types.MediaType | null
                                                          }
                                                        | {
                                                            __typename: "ImageMediaInfo"
                                                            src?: string | null
                                                            thumbnail?: string | null
                                                            mediaType?: Types.MediaType | null
                                                          }
                                                        | { __typename: "NoMediaInfo" }
                                                        | {
                                                            __typename: "VectorGraphicsMediaInfo"
                                                            svg?: string | null
                                                            thumbnail?: string | null
                                                            mediaType?: Types.MediaType | null
                                                          }
                                                        | {
                                                            __typename: "VideoMediaInfo"
                                                            thumbnail?: string | null
                                                            mediaType?: Types.MediaType | null
                                                          }
                                                        | null
                                                    }
                                                  | { __typename: "MediaGroupContent" }
                                                  | { __typename: "ProjectContent" }
                                                  | { __typename: "TermContent" }
                                                  | { __typename: "TextContent" }
                                                  | { __typename: "TextGroupContent" }
                                                  | { __typename: "VariableTableContent" }
                                                  | null
                                              }
                                            | { __typename: "ST4TreeNode"; id: string }
                                            | null
                                        }
                                      | { __typename: "MediaContent" }
                                      | { __typename: "MediaGroupContent" }
                                      | { __typename: "ProjectContent" }
                                      | { __typename: "TermContent" }
                                      | { __typename: "TextContent" }
                                      | { __typename: "TextGroupContent" }
                                      | { __typename: "VariableTableContent" }
                                      | null
                                  }
                                | { __typename: "ST4TreeNode"; id: string }
                                | null
                            } | null> | null
                            comments: Array<{
                              __typename: "CommentNode"
                              id: string
                              comment:
                                | {
                                    __typename: "BlockComment"
                                    location: number
                                    value?: string | null
                                    authorDisplayName?: string | null
                                    authorUserName?: string | null
                                    commentKey: string
                                    editDate?: any | null
                                    modificationVersion?: number | null
                                    replyToKey?: string | null
                                    sortOrder?: number | null
                                    commentType?: string | null
                                    state?: string | null
                                    color: string
                                    persistanceState?: Types.PersistanceState | null
                                    persistanceAction?: Types.PersistanceAction | null
                                    node: {
                                      __typename: "ST4Node"
                                      aspectId: any
                                      id: string
                                      content?:
                                        | { __typename: "LexiconContent" }
                                        | { __typename: "LexiconEntryContent" }
                                        | { __typename: "MediaContent" }
                                        | { __typename: "MediaGroupContent" }
                                        | { __typename: "ProjectContent" }
                                        | { __typename: "TermContent" }
                                        | { __typename: "TextContent"; id: string; historyNumber: number }
                                        | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                                        | { __typename: "VariableTableContent" }
                                        | null
                                    }
                                    actions: Array<{
                                      __typename: "Action"
                                      name?: string | null
                                      enabled?: boolean | null
                                    }>
                                  }
                                | {
                                    __typename: "TextComment"
                                    endLocation: number
                                    startLocation?: number | null
                                    value?: string | null
                                    authorDisplayName?: string | null
                                    authorUserName?: string | null
                                    commentKey: string
                                    editDate?: any | null
                                    modificationVersion?: number | null
                                    replyToKey?: string | null
                                    sortOrder?: number | null
                                    commentType?: string | null
                                    state?: string | null
                                    color: string
                                    persistanceState?: Types.PersistanceState | null
                                    persistanceAction?: Types.PersistanceAction | null
                                    node: {
                                      __typename: "ST4Node"
                                      aspectId: any
                                      id: string
                                      content?:
                                        | { __typename: "LexiconContent" }
                                        | { __typename: "LexiconEntryContent" }
                                        | { __typename: "MediaContent" }
                                        | { __typename: "MediaGroupContent" }
                                        | { __typename: "ProjectContent" }
                                        | { __typename: "TermContent" }
                                        | { __typename: "TextContent"; id: string; historyNumber: number }
                                        | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                                        | { __typename: "VariableTableContent" }
                                        | null
                                    }
                                    actions: Array<{
                                      __typename: "Action"
                                      name?: string | null
                                      enabled?: boolean | null
                                    }>
                                  }
                            }>
                          }
                        | { __typename: "VariableTableContent" }
                        | null
                    }
                  }>
                  children: Array<{
                    __typename: "NodeRef"
                    id: string
                    target?:
                      | { __typename: "CommentNode"; id: string }
                      | { __typename: "ExternalSite"; id: string }
                      | { __typename: "ST4Class"; id: string }
                      | { __typename: "ST4Node"; label?: string | null; aspectId: any; id: string }
                      | { __typename: "ST4TreeNode"; id: string }
                      | null
                  }>
                  links?: Array<{
                    __typename: "TextContentLink"
                    ref?: string | null
                    node?:
                      | { __typename: "CommentNode"; id: string }
                      | { __typename: "ExternalSite"; id: string }
                      | { __typename: "ST4Class"; id: string }
                      | {
                          __typename: "ST4Node"
                          id: string
                          aspectId: any
                          label?: string | null
                          properties?: {
                            __typename: "ContentPropertyConnection"
                            totalCount: number
                            edges?: Array<{
                              __typename: "ContentPropertyEdge"
                              node?: {
                                __typename: "ContentProperty"
                                typeOfValue: Types.ContentPropertyValueType
                                name: string
                                value?: any | null
                                displayName: string
                              } | null
                            } | null> | null
                          } | null
                        }
                      | { __typename: "ST4TreeNode"; id: string }
                      | null
                  } | null> | null
                  images: Array<{
                    __typename: "NodeRef"
                    id: string
                    target?:
                      | { __typename: "CommentNode"; id: string }
                      | { __typename: "ExternalSite"; id: string }
                      | { __typename: "ST4Class"; id: string }
                      | {
                          __typename: "ST4Node"
                          aspectId: any
                          id: string
                          releaseState: {
                            __typename: "ReleaseStateValue"
                            displayName: string
                            state: Types.ReleaseState
                            isImplicitlyReleased: boolean
                          }
                          content?:
                            | { __typename: "LexiconContent" }
                            | { __typename: "LexiconEntryContent" }
                            | {
                                __typename: "MediaContent"
                                media?:
                                  | {
                                      __typename: "AnimationMediaInfo"
                                      id: string
                                      resourceId?: string | null
                                      thumbnail?: string | null
                                      width?: number | null
                                      height?: number | null
                                      mediaType?: Types.MediaType | null
                                    }
                                  | {
                                      __typename: "ImageMediaInfo"
                                      id: string
                                      resourceId?: string | null
                                      thumbnail?: string | null
                                      width?: number | null
                                      height?: number | null
                                      resolution?: number | null
                                      colorDepth?: number | null
                                      size?: number | null
                                      mime?: string | null
                                      extension?: string | null
                                      allowCaching?: boolean | null
                                      mediaType?: Types.MediaType | null
                                      src?: string | null
                                      language?: string | null
                                    }
                                  | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                                  | {
                                      __typename: "VectorGraphicsMediaInfo"
                                      id: string
                                      resourceId?: string | null
                                      thumbnail?: string | null
                                      width?: number | null
                                      height?: number | null
                                      allowCaching?: boolean | null
                                      mediaType?: Types.MediaType | null
                                      language?: string | null
                                      labels: Array<{
                                        __typename: "VectorGraphicsLabel"
                                        id?: string | null
                                        sequence?: number | null
                                        autoValue?: string | null
                                        text?: string | null
                                        linkType?: Types.VectorGraphicsLabelLinkType | null
                                        link?: string | null
                                        ref?:
                                          | {
                                              __typename: "GenericRef"
                                              id: string
                                              owner?:
                                                | { __typename: "CommentNode" }
                                                | { __typename: "ExternalSite" }
                                                | { __typename: "ST4Class" }
                                                | { __typename: "ST4Node"; id: string }
                                                | { __typename: "ST4TreeNode" }
                                                | null
                                              target?:
                                                | { __typename: "CommentNode" }
                                                | {
                                                    __typename: "ExternalSite"
                                                    id: string
                                                    teaserData?: {
                                                      __typename: "ExternalLinkTeaserData"
                                                      title?: string | null
                                                      summary?: string | null
                                                      iconUrl?: string | null
                                                      imageUrl?: string | null
                                                      status?: Types.LinkTargetStatus | null
                                                    } | null
                                                  }
                                                | { __typename: "ST4Class" }
                                                | {
                                                    __typename: "ST4Node"
                                                    id: string
                                                    aspectId: any
                                                    label?: string | null
                                                    properties?: {
                                                      __typename: "ContentPropertyConnection"
                                                      edges?: Array<{
                                                        __typename: "ContentPropertyEdge"
                                                        node?: {
                                                          __typename: "ContentProperty"
                                                          name: string
                                                          value?: any | null
                                                          displayName: string
                                                        } | null
                                                      } | null> | null
                                                    } | null
                                                  }
                                                | { __typename: "ST4TreeNode" }
                                                | null
                                            }
                                          | {
                                              __typename: "NodeRef"
                                              id: string
                                              owner?:
                                                | { __typename: "CommentNode" }
                                                | { __typename: "ExternalSite" }
                                                | { __typename: "ST4Class" }
                                                | { __typename: "ST4Node"; id: string }
                                                | { __typename: "ST4TreeNode" }
                                                | null
                                              target?:
                                                | { __typename: "CommentNode" }
                                                | {
                                                    __typename: "ExternalSite"
                                                    id: string
                                                    teaserData?: {
                                                      __typename: "ExternalLinkTeaserData"
                                                      title?: string | null
                                                      summary?: string | null
                                                      iconUrl?: string | null
                                                      imageUrl?: string | null
                                                      status?: Types.LinkTargetStatus | null
                                                    } | null
                                                  }
                                                | { __typename: "ST4Class" }
                                                | {
                                                    __typename: "ST4Node"
                                                    id: string
                                                    aspectId: any
                                                    label?: string | null
                                                    properties?: {
                                                      __typename: "ContentPropertyConnection"
                                                      edges?: Array<{
                                                        __typename: "ContentPropertyEdge"
                                                        node?: {
                                                          __typename: "ContentProperty"
                                                          name: string
                                                          value?: any | null
                                                          displayName: string
                                                        } | null
                                                      } | null> | null
                                                    } | null
                                                  }
                                                | { __typename: "ST4TreeNode" }
                                                | null
                                            }
                                          | null
                                      }>
                                    }
                                  | {
                                      __typename: "VideoMediaInfo"
                                      id: string
                                      resourceId?: string | null
                                      thumbnail?: string | null
                                      width?: number | null
                                      height?: number | null
                                      mediaType?: Types.MediaType | null
                                    }
                                  | null
                              }
                            | {
                                __typename: "MediaGroupContent"
                                children: Array<{
                                  __typename: "NodeRef"
                                  id: string
                                  target?:
                                    | { __typename: "CommentNode"; id: string }
                                    | { __typename: "ExternalSite"; id: string }
                                    | { __typename: "ST4Class"; id: string }
                                    | {
                                        __typename: "ST4Node"
                                        id: string
                                        aspectId: any
                                        content?:
                                          | { __typename: "LexiconContent" }
                                          | { __typename: "LexiconEntryContent" }
                                          | {
                                              __typename: "MediaContent"
                                              media?:
                                                | {
                                                    __typename: "AnimationMediaInfo"
                                                    id: string
                                                    resourceId?: string | null
                                                    thumbnail?: string | null
                                                    width?: number | null
                                                    height?: number | null
                                                    mediaType?: Types.MediaType | null
                                                  }
                                                | {
                                                    __typename: "ImageMediaInfo"
                                                    id: string
                                                    resourceId?: string | null
                                                    thumbnail?: string | null
                                                    width?: number | null
                                                    height?: number | null
                                                    resolution?: number | null
                                                    colorDepth?: number | null
                                                    size?: number | null
                                                    mime?: string | null
                                                    extension?: string | null
                                                    allowCaching?: boolean | null
                                                    mediaType?: Types.MediaType | null
                                                    src?: string | null
                                                    language?: string | null
                                                  }
                                                | {
                                                    __typename: "NoMediaInfo"
                                                    id: string
                                                    mediaType?: Types.MediaType | null
                                                  }
                                                | {
                                                    __typename: "VectorGraphicsMediaInfo"
                                                    id: string
                                                    resourceId?: string | null
                                                    thumbnail?: string | null
                                                    width?: number | null
                                                    height?: number | null
                                                    allowCaching?: boolean | null
                                                    mediaType?: Types.MediaType | null
                                                    language?: string | null
                                                    labels: Array<{
                                                      __typename: "VectorGraphicsLabel"
                                                      id?: string | null
                                                      sequence?: number | null
                                                      autoValue?: string | null
                                                      text?: string | null
                                                      linkType?: Types.VectorGraphicsLabelLinkType | null
                                                      link?: string | null
                                                      ref?:
                                                        | {
                                                            __typename: "GenericRef"
                                                            id: string
                                                            owner?:
                                                              | { __typename: "CommentNode" }
                                                              | { __typename: "ExternalSite" }
                                                              | { __typename: "ST4Class" }
                                                              | { __typename: "ST4Node"; id: string }
                                                              | { __typename: "ST4TreeNode" }
                                                              | null
                                                            target?:
                                                              | { __typename: "CommentNode" }
                                                              | {
                                                                  __typename: "ExternalSite"
                                                                  id: string
                                                                  teaserData?: {
                                                                    __typename: "ExternalLinkTeaserData"
                                                                    title?: string | null
                                                                    summary?: string | null
                                                                    iconUrl?: string | null
                                                                    imageUrl?: string | null
                                                                    status?: Types.LinkTargetStatus | null
                                                                  } | null
                                                                }
                                                              | { __typename: "ST4Class" }
                                                              | {
                                                                  __typename: "ST4Node"
                                                                  id: string
                                                                  aspectId: any
                                                                  label?: string | null
                                                                  properties?: {
                                                                    __typename: "ContentPropertyConnection"
                                                                    edges?: Array<{
                                                                      __typename: "ContentPropertyEdge"
                                                                      node?: {
                                                                        __typename: "ContentProperty"
                                                                        name: string
                                                                        value?: any | null
                                                                        displayName: string
                                                                      } | null
                                                                    } | null> | null
                                                                  } | null
                                                                }
                                                              | { __typename: "ST4TreeNode" }
                                                              | null
                                                          }
                                                        | {
                                                            __typename: "NodeRef"
                                                            id: string
                                                            owner?:
                                                              | { __typename: "CommentNode" }
                                                              | { __typename: "ExternalSite" }
                                                              | { __typename: "ST4Class" }
                                                              | { __typename: "ST4Node"; id: string }
                                                              | { __typename: "ST4TreeNode" }
                                                              | null
                                                            target?:
                                                              | { __typename: "CommentNode" }
                                                              | {
                                                                  __typename: "ExternalSite"
                                                                  id: string
                                                                  teaserData?: {
                                                                    __typename: "ExternalLinkTeaserData"
                                                                    title?: string | null
                                                                    summary?: string | null
                                                                    iconUrl?: string | null
                                                                    imageUrl?: string | null
                                                                    status?: Types.LinkTargetStatus | null
                                                                  } | null
                                                                }
                                                              | { __typename: "ST4Class" }
                                                              | {
                                                                  __typename: "ST4Node"
                                                                  id: string
                                                                  aspectId: any
                                                                  label?: string | null
                                                                  properties?: {
                                                                    __typename: "ContentPropertyConnection"
                                                                    edges?: Array<{
                                                                      __typename: "ContentPropertyEdge"
                                                                      node?: {
                                                                        __typename: "ContentProperty"
                                                                        name: string
                                                                        value?: any | null
                                                                        displayName: string
                                                                      } | null
                                                                    } | null> | null
                                                                  } | null
                                                                }
                                                              | { __typename: "ST4TreeNode" }
                                                              | null
                                                          }
                                                        | null
                                                    }>
                                                  }
                                                | {
                                                    __typename: "VideoMediaInfo"
                                                    id: string
                                                    resourceId?: string | null
                                                    thumbnail?: string | null
                                                    width?: number | null
                                                    height?: number | null
                                                    mediaType?: Types.MediaType | null
                                                  }
                                                | null
                                            }
                                          | {
                                              __typename: "MediaGroupContent"
                                              media?:
                                                | {
                                                    __typename: "AnimationMediaInfo"
                                                    id: string
                                                    resourceId?: string | null
                                                    thumbnail?: string | null
                                                    width?: number | null
                                                    height?: number | null
                                                    mediaType?: Types.MediaType | null
                                                  }
                                                | {
                                                    __typename: "ImageMediaInfo"
                                                    id: string
                                                    resourceId?: string | null
                                                    thumbnail?: string | null
                                                    width?: number | null
                                                    height?: number | null
                                                    resolution?: number | null
                                                    colorDepth?: number | null
                                                    size?: number | null
                                                    mime?: string | null
                                                    extension?: string | null
                                                    allowCaching?: boolean | null
                                                    mediaType?: Types.MediaType | null
                                                    src?: string | null
                                                    language?: string | null
                                                  }
                                                | {
                                                    __typename: "NoMediaInfo"
                                                    id: string
                                                    mediaType?: Types.MediaType | null
                                                  }
                                                | {
                                                    __typename: "VectorGraphicsMediaInfo"
                                                    id: string
                                                    resourceId?: string | null
                                                    thumbnail?: string | null
                                                    width?: number | null
                                                    height?: number | null
                                                    allowCaching?: boolean | null
                                                    mediaType?: Types.MediaType | null
                                                    language?: string | null
                                                    labels: Array<{
                                                      __typename: "VectorGraphicsLabel"
                                                      id?: string | null
                                                      sequence?: number | null
                                                      autoValue?: string | null
                                                      text?: string | null
                                                      linkType?: Types.VectorGraphicsLabelLinkType | null
                                                      link?: string | null
                                                      ref?:
                                                        | {
                                                            __typename: "GenericRef"
                                                            id: string
                                                            owner?:
                                                              | { __typename: "CommentNode" }
                                                              | { __typename: "ExternalSite" }
                                                              | { __typename: "ST4Class" }
                                                              | { __typename: "ST4Node"; id: string }
                                                              | { __typename: "ST4TreeNode" }
                                                              | null
                                                            target?:
                                                              | { __typename: "CommentNode" }
                                                              | {
                                                                  __typename: "ExternalSite"
                                                                  id: string
                                                                  teaserData?: {
                                                                    __typename: "ExternalLinkTeaserData"
                                                                    title?: string | null
                                                                    summary?: string | null
                                                                    iconUrl?: string | null
                                                                    imageUrl?: string | null
                                                                    status?: Types.LinkTargetStatus | null
                                                                  } | null
                                                                }
                                                              | { __typename: "ST4Class" }
                                                              | {
                                                                  __typename: "ST4Node"
                                                                  id: string
                                                                  aspectId: any
                                                                  label?: string | null
                                                                  properties?: {
                                                                    __typename: "ContentPropertyConnection"
                                                                    edges?: Array<{
                                                                      __typename: "ContentPropertyEdge"
                                                                      node?: {
                                                                        __typename: "ContentProperty"
                                                                        name: string
                                                                        value?: any | null
                                                                        displayName: string
                                                                      } | null
                                                                    } | null> | null
                                                                  } | null
                                                                }
                                                              | { __typename: "ST4TreeNode" }
                                                              | null
                                                          }
                                                        | {
                                                            __typename: "NodeRef"
                                                            id: string
                                                            owner?:
                                                              | { __typename: "CommentNode" }
                                                              | { __typename: "ExternalSite" }
                                                              | { __typename: "ST4Class" }
                                                              | { __typename: "ST4Node"; id: string }
                                                              | { __typename: "ST4TreeNode" }
                                                              | null
                                                            target?:
                                                              | { __typename: "CommentNode" }
                                                              | {
                                                                  __typename: "ExternalSite"
                                                                  id: string
                                                                  teaserData?: {
                                                                    __typename: "ExternalLinkTeaserData"
                                                                    title?: string | null
                                                                    summary?: string | null
                                                                    iconUrl?: string | null
                                                                    imageUrl?: string | null
                                                                    status?: Types.LinkTargetStatus | null
                                                                  } | null
                                                                }
                                                              | { __typename: "ST4Class" }
                                                              | {
                                                                  __typename: "ST4Node"
                                                                  id: string
                                                                  aspectId: any
                                                                  label?: string | null
                                                                  properties?: {
                                                                    __typename: "ContentPropertyConnection"
                                                                    edges?: Array<{
                                                                      __typename: "ContentPropertyEdge"
                                                                      node?: {
                                                                        __typename: "ContentProperty"
                                                                        name: string
                                                                        value?: any | null
                                                                        displayName: string
                                                                      } | null
                                                                    } | null> | null
                                                                  } | null
                                                                }
                                                              | { __typename: "ST4TreeNode" }
                                                              | null
                                                          }
                                                        | null
                                                    }>
                                                  }
                                                | {
                                                    __typename: "VideoMediaInfo"
                                                    id: string
                                                    resourceId?: string | null
                                                    thumbnail?: string | null
                                                    width?: number | null
                                                    height?: number | null
                                                    mediaType?: Types.MediaType | null
                                                  }
                                                | null
                                            }
                                          | { __typename: "ProjectContent" }
                                          | { __typename: "TermContent" }
                                          | { __typename: "TextContent" }
                                          | { __typename: "TextGroupContent" }
                                          | { __typename: "VariableTableContent" }
                                          | null
                                      }
                                    | { __typename: "ST4TreeNode"; id: string }
                                    | null
                                }>
                                media?:
                                  | {
                                      __typename: "AnimationMediaInfo"
                                      id: string
                                      resourceId?: string | null
                                      thumbnail?: string | null
                                      width?: number | null
                                      height?: number | null
                                      mediaType?: Types.MediaType | null
                                    }
                                  | {
                                      __typename: "ImageMediaInfo"
                                      id: string
                                      resourceId?: string | null
                                      thumbnail?: string | null
                                      width?: number | null
                                      height?: number | null
                                      resolution?: number | null
                                      colorDepth?: number | null
                                      size?: number | null
                                      mime?: string | null
                                      extension?: string | null
                                      allowCaching?: boolean | null
                                      mediaType?: Types.MediaType | null
                                      src?: string | null
                                      language?: string | null
                                    }
                                  | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                                  | {
                                      __typename: "VectorGraphicsMediaInfo"
                                      id: string
                                      resourceId?: string | null
                                      thumbnail?: string | null
                                      width?: number | null
                                      height?: number | null
                                      allowCaching?: boolean | null
                                      mediaType?: Types.MediaType | null
                                      language?: string | null
                                      labels: Array<{
                                        __typename: "VectorGraphicsLabel"
                                        id?: string | null
                                        sequence?: number | null
                                        autoValue?: string | null
                                        text?: string | null
                                        linkType?: Types.VectorGraphicsLabelLinkType | null
                                        link?: string | null
                                        ref?:
                                          | {
                                              __typename: "GenericRef"
                                              id: string
                                              owner?:
                                                | { __typename: "CommentNode" }
                                                | { __typename: "ExternalSite" }
                                                | { __typename: "ST4Class" }
                                                | { __typename: "ST4Node"; id: string }
                                                | { __typename: "ST4TreeNode" }
                                                | null
                                              target?:
                                                | { __typename: "CommentNode" }
                                                | {
                                                    __typename: "ExternalSite"
                                                    id: string
                                                    teaserData?: {
                                                      __typename: "ExternalLinkTeaserData"
                                                      title?: string | null
                                                      summary?: string | null
                                                      iconUrl?: string | null
                                                      imageUrl?: string | null
                                                      status?: Types.LinkTargetStatus | null
                                                    } | null
                                                  }
                                                | { __typename: "ST4Class" }
                                                | {
                                                    __typename: "ST4Node"
                                                    id: string
                                                    aspectId: any
                                                    label?: string | null
                                                    properties?: {
                                                      __typename: "ContentPropertyConnection"
                                                      edges?: Array<{
                                                        __typename: "ContentPropertyEdge"
                                                        node?: {
                                                          __typename: "ContentProperty"
                                                          name: string
                                                          value?: any | null
                                                          displayName: string
                                                        } | null
                                                      } | null> | null
                                                    } | null
                                                  }
                                                | { __typename: "ST4TreeNode" }
                                                | null
                                            }
                                          | {
                                              __typename: "NodeRef"
                                              id: string
                                              owner?:
                                                | { __typename: "CommentNode" }
                                                | { __typename: "ExternalSite" }
                                                | { __typename: "ST4Class" }
                                                | { __typename: "ST4Node"; id: string }
                                                | { __typename: "ST4TreeNode" }
                                                | null
                                              target?:
                                                | { __typename: "CommentNode" }
                                                | {
                                                    __typename: "ExternalSite"
                                                    id: string
                                                    teaserData?: {
                                                      __typename: "ExternalLinkTeaserData"
                                                      title?: string | null
                                                      summary?: string | null
                                                      iconUrl?: string | null
                                                      imageUrl?: string | null
                                                      status?: Types.LinkTargetStatus | null
                                                    } | null
                                                  }
                                                | { __typename: "ST4Class" }
                                                | {
                                                    __typename: "ST4Node"
                                                    id: string
                                                    aspectId: any
                                                    label?: string | null
                                                    properties?: {
                                                      __typename: "ContentPropertyConnection"
                                                      edges?: Array<{
                                                        __typename: "ContentPropertyEdge"
                                                        node?: {
                                                          __typename: "ContentProperty"
                                                          name: string
                                                          value?: any | null
                                                          displayName: string
                                                        } | null
                                                      } | null> | null
                                                    } | null
                                                  }
                                                | { __typename: "ST4TreeNode" }
                                                | null
                                            }
                                          | null
                                      }>
                                    }
                                  | {
                                      __typename: "VideoMediaInfo"
                                      id: string
                                      resourceId?: string | null
                                      thumbnail?: string | null
                                      width?: number | null
                                      height?: number | null
                                      mediaType?: Types.MediaType | null
                                    }
                                  | null
                              }
                            | { __typename: "ProjectContent" }
                            | { __typename: "TermContent" }
                            | { __typename: "TextContent" }
                            | { __typename: "TextGroupContent" }
                            | { __typename: "VariableTableContent" }
                            | null
                        }
                      | { __typename: "ST4TreeNode"; id: string }
                      | null
                  }>
                  variableTables?: Array<{
                    __typename: "VariableTableReference"
                    ref?: string | null
                    node?:
                      | { __typename: "CommentNode" }
                      | { __typename: "ExternalSite" }
                      | { __typename: "ST4Class" }
                      | {
                          __typename: "ST4Node"
                          id: string
                          label?: string | null
                          aspectId: any
                          content?:
                            | { __typename: "LexiconContent" }
                            | { __typename: "LexiconEntryContent" }
                            | { __typename: "MediaContent" }
                            | { __typename: "MediaGroupContent" }
                            | { __typename: "ProjectContent" }
                            | { __typename: "TermContent" }
                            | { __typename: "TextContent" }
                            | { __typename: "TextGroupContent" }
                            | {
                                __typename: "VariableTableContent"
                                xml?: string | null
                                valueSets: Array<{ __typename: "ValueSet"; name: string; valueSetId: string }>
                                variables: Array<{
                                  __typename: "Variable"
                                  name: string
                                  variableId: string
                                  values: Array<string>
                                }>
                              }
                            | null
                        }
                      | { __typename: "ST4TreeNode" }
                      | null
                  } | null> | null
                  linkedDataNodes: Array<{
                    __typename: "NodeRef"
                    id: string
                    target?:
                      | { __typename: "CommentNode"; id: string }
                      | { __typename: "ExternalSite"; id: string }
                      | { __typename: "ST4Class"; id: string }
                      | {
                          __typename: "ST4Node"
                          label?: string | null
                          id: string
                          metadata: Array<{
                            __typename: "MetaDataEntry"
                            label?: string | null
                            systemName: string
                            value?: string | null
                            values: Array<string>
                            users: Array<string>
                          }>
                          linkedOntologyNodes: Array<
                            | {
                                __typename: "GenericRef"
                                target?:
                                  | { __typename: "CommentNode" }
                                  | { __typename: "ExternalSite" }
                                  | { __typename: "ST4Class" }
                                  | { __typename: "ST4Node"; id: string; label?: string | null }
                                  | { __typename: "ST4TreeNode" }
                                  | null
                              }
                            | {
                                __typename: "NodeRef"
                                linkClassName: string
                                class?: { __typename: "ST4Class"; label?: string | null } | null
                                target?:
                                  | { __typename: "CommentNode" }
                                  | { __typename: "ExternalSite" }
                                  | { __typename: "ST4Class" }
                                  | { __typename: "ST4Node"; id: string; label?: string | null }
                                  | { __typename: "ST4TreeNode" }
                                  | null
                              }
                          >
                        }
                      | { __typename: "ST4TreeNode"; id: string }
                      | null
                  }>
                  lexiconEntries?: Array<{
                    __typename: "NodeRef"
                    id: string
                    target?:
                      | { __typename: "CommentNode"; id: string }
                      | { __typename: "ExternalSite"; id: string }
                      | { __typename: "ST4Class"; id: string }
                      | {
                          __typename: "ST4Node"
                          aspectId: any
                          label?: string | null
                          id: string
                          content?:
                            | { __typename: "LexiconContent" }
                            | {
                                __typename: "LexiconEntryContent"
                                descriptionXast?: string | null
                                subject?: string | null
                                sortKey?: string | null
                                lexicon:
                                  | { __typename: "CommentNode"; id: string }
                                  | { __typename: "ExternalSite"; id: string }
                                  | { __typename: "ST4Class"; id: string }
                                  | { __typename: "ST4Node"; label?: string | null; id: string }
                                  | { __typename: "ST4TreeNode"; id: string }
                                terms: Array<
                                  | { __typename: "CommentNode"; id: string }
                                  | { __typename: "ExternalSite"; id: string }
                                  | { __typename: "ST4Class"; id: string }
                                  | {
                                      __typename: "ST4Node"
                                      id: string
                                      label?: string | null
                                      aspectId: any
                                      content?:
                                        | { __typename: "LexiconContent" }
                                        | { __typename: "LexiconEntryContent" }
                                        | { __typename: "MediaContent" }
                                        | { __typename: "MediaGroupContent" }
                                        | { __typename: "ProjectContent" }
                                        | {
                                            __typename: "TermContent"
                                            gramaticalGender?: string | null
                                            validity?: string | null
                                            editLanguage?: string | null
                                          }
                                        | { __typename: "TextContent" }
                                        | { __typename: "TextGroupContent" }
                                        | { __typename: "VariableTableContent" }
                                        | null
                                    }
                                  | { __typename: "ST4TreeNode"; id: string }
                                >
                                image?:
                                  | { __typename: "CommentNode"; id: string }
                                  | { __typename: "ExternalSite"; id: string }
                                  | { __typename: "ST4Class"; id: string }
                                  | {
                                      __typename: "ST4Node"
                                      aspectId: any
                                      id: string
                                      content?:
                                        | { __typename: "LexiconContent" }
                                        | { __typename: "LexiconEntryContent" }
                                        | {
                                            __typename: "MediaContent"
                                            media?:
                                              | {
                                                  __typename: "AnimationMediaInfo"
                                                  thumbnail?: string | null
                                                  mediaType?: Types.MediaType | null
                                                }
                                              | {
                                                  __typename: "ImageMediaInfo"
                                                  src?: string | null
                                                  thumbnail?: string | null
                                                  mediaType?: Types.MediaType | null
                                                }
                                              | { __typename: "NoMediaInfo" }
                                              | {
                                                  __typename: "VectorGraphicsMediaInfo"
                                                  svg?: string | null
                                                  thumbnail?: string | null
                                                  mediaType?: Types.MediaType | null
                                                }
                                              | {
                                                  __typename: "VideoMediaInfo"
                                                  thumbnail?: string | null
                                                  mediaType?: Types.MediaType | null
                                                }
                                              | null
                                          }
                                        | { __typename: "MediaGroupContent" }
                                        | { __typename: "ProjectContent" }
                                        | { __typename: "TermContent" }
                                        | { __typename: "TextContent" }
                                        | { __typename: "TextGroupContent" }
                                        | { __typename: "VariableTableContent" }
                                        | null
                                    }
                                  | { __typename: "ST4TreeNode"; id: string }
                                  | null
                              }
                            | { __typename: "MediaContent" }
                            | { __typename: "MediaGroupContent" }
                            | { __typename: "ProjectContent" }
                            | { __typename: "TermContent" }
                            | { __typename: "TextContent" }
                            | { __typename: "TextGroupContent" }
                            | { __typename: "VariableTableContent" }
                            | null
                        }
                      | { __typename: "ST4TreeNode"; id: string }
                      | null
                  } | null> | null
                  comments: Array<{
                    __typename: "CommentNode"
                    id: string
                    comment:
                      | {
                          __typename: "BlockComment"
                          location: number
                          value?: string | null
                          authorDisplayName?: string | null
                          authorUserName?: string | null
                          commentKey: string
                          editDate?: any | null
                          modificationVersion?: number | null
                          replyToKey?: string | null
                          sortOrder?: number | null
                          commentType?: string | null
                          state?: string | null
                          color: string
                          persistanceState?: Types.PersistanceState | null
                          persistanceAction?: Types.PersistanceAction | null
                          node: {
                            __typename: "ST4Node"
                            aspectId: any
                            id: string
                            content?:
                              | { __typename: "LexiconContent" }
                              | { __typename: "LexiconEntryContent" }
                              | { __typename: "MediaContent" }
                              | { __typename: "MediaGroupContent" }
                              | { __typename: "ProjectContent" }
                              | { __typename: "TermContent" }
                              | { __typename: "TextContent"; id: string; historyNumber: number }
                              | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                              | { __typename: "VariableTableContent" }
                              | null
                          }
                          actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
                        }
                      | {
                          __typename: "TextComment"
                          endLocation: number
                          startLocation?: number | null
                          value?: string | null
                          authorDisplayName?: string | null
                          authorUserName?: string | null
                          commentKey: string
                          editDate?: any | null
                          modificationVersion?: number | null
                          replyToKey?: string | null
                          sortOrder?: number | null
                          commentType?: string | null
                          state?: string | null
                          color: string
                          persistanceState?: Types.PersistanceState | null
                          persistanceAction?: Types.PersistanceAction | null
                          node: {
                            __typename: "ST4Node"
                            aspectId: any
                            id: string
                            content?:
                              | { __typename: "LexiconContent" }
                              | { __typename: "LexiconEntryContent" }
                              | { __typename: "MediaContent" }
                              | { __typename: "MediaGroupContent" }
                              | { __typename: "ProjectContent" }
                              | { __typename: "TermContent" }
                              | { __typename: "TextContent"; id: string; historyNumber: number }
                              | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                              | { __typename: "VariableTableContent" }
                              | null
                          }
                          actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
                        }
                  }>
                }
              | { __typename: "VariableTableContent" }
              | null
          }
        }>
        parts: Array<{
          __typename: "TextContentPart"
          ref: string
          contentNode: {
            __typename: "ST4Node"
            id: string
            aspectId: any
            content?:
              | { __typename: "LexiconContent" }
              | { __typename: "LexiconEntryContent" }
              | { __typename: "MediaContent" }
              | { __typename: "MediaGroupContent" }
              | { __typename: "ProjectContent" }
              | { __typename: "TermContent" }
              | {
                  __typename: "TextContent"
                  id: string
                  xast?: string | null
                  historyNumber: number
                  links?: Array<{
                    __typename: "TextContentLink"
                    ref?: string | null
                    node?:
                      | { __typename: "CommentNode"; id: string }
                      | { __typename: "ExternalSite"; id: string }
                      | { __typename: "ST4Class"; id: string }
                      | {
                          __typename: "ST4Node"
                          id: string
                          aspectId: any
                          label?: string | null
                          properties?: {
                            __typename: "ContentPropertyConnection"
                            totalCount: number
                            edges?: Array<{
                              __typename: "ContentPropertyEdge"
                              node?: {
                                __typename: "ContentProperty"
                                typeOfValue: Types.ContentPropertyValueType
                                name: string
                                value?: any | null
                                displayName: string
                              } | null
                            } | null> | null
                          } | null
                        }
                      | { __typename: "ST4TreeNode"; id: string }
                      | null
                  } | null> | null
                  images: Array<{
                    __typename: "NodeRef"
                    id: string
                    target?:
                      | { __typename: "CommentNode"; id: string }
                      | { __typename: "ExternalSite"; id: string }
                      | { __typename: "ST4Class"; id: string }
                      | {
                          __typename: "ST4Node"
                          aspectId: any
                          id: string
                          releaseState: {
                            __typename: "ReleaseStateValue"
                            displayName: string
                            state: Types.ReleaseState
                            isImplicitlyReleased: boolean
                          }
                          content?:
                            | { __typename: "LexiconContent" }
                            | { __typename: "LexiconEntryContent" }
                            | {
                                __typename: "MediaContent"
                                media?:
                                  | {
                                      __typename: "AnimationMediaInfo"
                                      id: string
                                      resourceId?: string | null
                                      thumbnail?: string | null
                                      width?: number | null
                                      height?: number | null
                                      mediaType?: Types.MediaType | null
                                    }
                                  | {
                                      __typename: "ImageMediaInfo"
                                      id: string
                                      resourceId?: string | null
                                      thumbnail?: string | null
                                      width?: number | null
                                      height?: number | null
                                      resolution?: number | null
                                      colorDepth?: number | null
                                      size?: number | null
                                      mime?: string | null
                                      extension?: string | null
                                      allowCaching?: boolean | null
                                      mediaType?: Types.MediaType | null
                                      src?: string | null
                                      language?: string | null
                                    }
                                  | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                                  | {
                                      __typename: "VectorGraphicsMediaInfo"
                                      id: string
                                      resourceId?: string | null
                                      thumbnail?: string | null
                                      width?: number | null
                                      height?: number | null
                                      allowCaching?: boolean | null
                                      mediaType?: Types.MediaType | null
                                      language?: string | null
                                      labels: Array<{
                                        __typename: "VectorGraphicsLabel"
                                        id?: string | null
                                        sequence?: number | null
                                        autoValue?: string | null
                                        text?: string | null
                                        linkType?: Types.VectorGraphicsLabelLinkType | null
                                        link?: string | null
                                        ref?:
                                          | {
                                              __typename: "GenericRef"
                                              id: string
                                              owner?:
                                                | { __typename: "CommentNode" }
                                                | { __typename: "ExternalSite" }
                                                | { __typename: "ST4Class" }
                                                | { __typename: "ST4Node"; id: string }
                                                | { __typename: "ST4TreeNode" }
                                                | null
                                              target?:
                                                | { __typename: "CommentNode" }
                                                | {
                                                    __typename: "ExternalSite"
                                                    id: string
                                                    teaserData?: {
                                                      __typename: "ExternalLinkTeaserData"
                                                      title?: string | null
                                                      summary?: string | null
                                                      iconUrl?: string | null
                                                      imageUrl?: string | null
                                                      status?: Types.LinkTargetStatus | null
                                                    } | null
                                                  }
                                                | { __typename: "ST4Class" }
                                                | {
                                                    __typename: "ST4Node"
                                                    id: string
                                                    aspectId: any
                                                    label?: string | null
                                                    properties?: {
                                                      __typename: "ContentPropertyConnection"
                                                      edges?: Array<{
                                                        __typename: "ContentPropertyEdge"
                                                        node?: {
                                                          __typename: "ContentProperty"
                                                          name: string
                                                          value?: any | null
                                                          displayName: string
                                                        } | null
                                                      } | null> | null
                                                    } | null
                                                  }
                                                | { __typename: "ST4TreeNode" }
                                                | null
                                            }
                                          | {
                                              __typename: "NodeRef"
                                              id: string
                                              owner?:
                                                | { __typename: "CommentNode" }
                                                | { __typename: "ExternalSite" }
                                                | { __typename: "ST4Class" }
                                                | { __typename: "ST4Node"; id: string }
                                                | { __typename: "ST4TreeNode" }
                                                | null
                                              target?:
                                                | { __typename: "CommentNode" }
                                                | {
                                                    __typename: "ExternalSite"
                                                    id: string
                                                    teaserData?: {
                                                      __typename: "ExternalLinkTeaserData"
                                                      title?: string | null
                                                      summary?: string | null
                                                      iconUrl?: string | null
                                                      imageUrl?: string | null
                                                      status?: Types.LinkTargetStatus | null
                                                    } | null
                                                  }
                                                | { __typename: "ST4Class" }
                                                | {
                                                    __typename: "ST4Node"
                                                    id: string
                                                    aspectId: any
                                                    label?: string | null
                                                    properties?: {
                                                      __typename: "ContentPropertyConnection"
                                                      edges?: Array<{
                                                        __typename: "ContentPropertyEdge"
                                                        node?: {
                                                          __typename: "ContentProperty"
                                                          name: string
                                                          value?: any | null
                                                          displayName: string
                                                        } | null
                                                      } | null> | null
                                                    } | null
                                                  }
                                                | { __typename: "ST4TreeNode" }
                                                | null
                                            }
                                          | null
                                      }>
                                    }
                                  | {
                                      __typename: "VideoMediaInfo"
                                      id: string
                                      resourceId?: string | null
                                      thumbnail?: string | null
                                      width?: number | null
                                      height?: number | null
                                      mediaType?: Types.MediaType | null
                                    }
                                  | null
                              }
                            | {
                                __typename: "MediaGroupContent"
                                children: Array<{
                                  __typename: "NodeRef"
                                  id: string
                                  target?:
                                    | { __typename: "CommentNode"; id: string }
                                    | { __typename: "ExternalSite"; id: string }
                                    | { __typename: "ST4Class"; id: string }
                                    | {
                                        __typename: "ST4Node"
                                        id: string
                                        aspectId: any
                                        content?:
                                          | { __typename: "LexiconContent" }
                                          | { __typename: "LexiconEntryContent" }
                                          | {
                                              __typename: "MediaContent"
                                              media?:
                                                | {
                                                    __typename: "AnimationMediaInfo"
                                                    id: string
                                                    resourceId?: string | null
                                                    thumbnail?: string | null
                                                    width?: number | null
                                                    height?: number | null
                                                    mediaType?: Types.MediaType | null
                                                  }
                                                | {
                                                    __typename: "ImageMediaInfo"
                                                    id: string
                                                    resourceId?: string | null
                                                    thumbnail?: string | null
                                                    width?: number | null
                                                    height?: number | null
                                                    resolution?: number | null
                                                    colorDepth?: number | null
                                                    size?: number | null
                                                    mime?: string | null
                                                    extension?: string | null
                                                    allowCaching?: boolean | null
                                                    mediaType?: Types.MediaType | null
                                                    src?: string | null
                                                    language?: string | null
                                                  }
                                                | {
                                                    __typename: "NoMediaInfo"
                                                    id: string
                                                    mediaType?: Types.MediaType | null
                                                  }
                                                | {
                                                    __typename: "VectorGraphicsMediaInfo"
                                                    id: string
                                                    resourceId?: string | null
                                                    thumbnail?: string | null
                                                    width?: number | null
                                                    height?: number | null
                                                    allowCaching?: boolean | null
                                                    mediaType?: Types.MediaType | null
                                                    language?: string | null
                                                    labels: Array<{
                                                      __typename: "VectorGraphicsLabel"
                                                      id?: string | null
                                                      sequence?: number | null
                                                      autoValue?: string | null
                                                      text?: string | null
                                                      linkType?: Types.VectorGraphicsLabelLinkType | null
                                                      link?: string | null
                                                      ref?:
                                                        | {
                                                            __typename: "GenericRef"
                                                            id: string
                                                            owner?:
                                                              | { __typename: "CommentNode" }
                                                              | { __typename: "ExternalSite" }
                                                              | { __typename: "ST4Class" }
                                                              | { __typename: "ST4Node"; id: string }
                                                              | { __typename: "ST4TreeNode" }
                                                              | null
                                                            target?:
                                                              | { __typename: "CommentNode" }
                                                              | {
                                                                  __typename: "ExternalSite"
                                                                  id: string
                                                                  teaserData?: {
                                                                    __typename: "ExternalLinkTeaserData"
                                                                    title?: string | null
                                                                    summary?: string | null
                                                                    iconUrl?: string | null
                                                                    imageUrl?: string | null
                                                                    status?: Types.LinkTargetStatus | null
                                                                  } | null
                                                                }
                                                              | { __typename: "ST4Class" }
                                                              | {
                                                                  __typename: "ST4Node"
                                                                  id: string
                                                                  aspectId: any
                                                                  label?: string | null
                                                                  properties?: {
                                                                    __typename: "ContentPropertyConnection"
                                                                    edges?: Array<{
                                                                      __typename: "ContentPropertyEdge"
                                                                      node?: {
                                                                        __typename: "ContentProperty"
                                                                        name: string
                                                                        value?: any | null
                                                                        displayName: string
                                                                      } | null
                                                                    } | null> | null
                                                                  } | null
                                                                }
                                                              | { __typename: "ST4TreeNode" }
                                                              | null
                                                          }
                                                        | {
                                                            __typename: "NodeRef"
                                                            id: string
                                                            owner?:
                                                              | { __typename: "CommentNode" }
                                                              | { __typename: "ExternalSite" }
                                                              | { __typename: "ST4Class" }
                                                              | { __typename: "ST4Node"; id: string }
                                                              | { __typename: "ST4TreeNode" }
                                                              | null
                                                            target?:
                                                              | { __typename: "CommentNode" }
                                                              | {
                                                                  __typename: "ExternalSite"
                                                                  id: string
                                                                  teaserData?: {
                                                                    __typename: "ExternalLinkTeaserData"
                                                                    title?: string | null
                                                                    summary?: string | null
                                                                    iconUrl?: string | null
                                                                    imageUrl?: string | null
                                                                    status?: Types.LinkTargetStatus | null
                                                                  } | null
                                                                }
                                                              | { __typename: "ST4Class" }
                                                              | {
                                                                  __typename: "ST4Node"
                                                                  id: string
                                                                  aspectId: any
                                                                  label?: string | null
                                                                  properties?: {
                                                                    __typename: "ContentPropertyConnection"
                                                                    edges?: Array<{
                                                                      __typename: "ContentPropertyEdge"
                                                                      node?: {
                                                                        __typename: "ContentProperty"
                                                                        name: string
                                                                        value?: any | null
                                                                        displayName: string
                                                                      } | null
                                                                    } | null> | null
                                                                  } | null
                                                                }
                                                              | { __typename: "ST4TreeNode" }
                                                              | null
                                                          }
                                                        | null
                                                    }>
                                                  }
                                                | {
                                                    __typename: "VideoMediaInfo"
                                                    id: string
                                                    resourceId?: string | null
                                                    thumbnail?: string | null
                                                    width?: number | null
                                                    height?: number | null
                                                    mediaType?: Types.MediaType | null
                                                  }
                                                | null
                                            }
                                          | {
                                              __typename: "MediaGroupContent"
                                              media?:
                                                | {
                                                    __typename: "AnimationMediaInfo"
                                                    id: string
                                                    resourceId?: string | null
                                                    thumbnail?: string | null
                                                    width?: number | null
                                                    height?: number | null
                                                    mediaType?: Types.MediaType | null
                                                  }
                                                | {
                                                    __typename: "ImageMediaInfo"
                                                    id: string
                                                    resourceId?: string | null
                                                    thumbnail?: string | null
                                                    width?: number | null
                                                    height?: number | null
                                                    resolution?: number | null
                                                    colorDepth?: number | null
                                                    size?: number | null
                                                    mime?: string | null
                                                    extension?: string | null
                                                    allowCaching?: boolean | null
                                                    mediaType?: Types.MediaType | null
                                                    src?: string | null
                                                    language?: string | null
                                                  }
                                                | {
                                                    __typename: "NoMediaInfo"
                                                    id: string
                                                    mediaType?: Types.MediaType | null
                                                  }
                                                | {
                                                    __typename: "VectorGraphicsMediaInfo"
                                                    id: string
                                                    resourceId?: string | null
                                                    thumbnail?: string | null
                                                    width?: number | null
                                                    height?: number | null
                                                    allowCaching?: boolean | null
                                                    mediaType?: Types.MediaType | null
                                                    language?: string | null
                                                    labels: Array<{
                                                      __typename: "VectorGraphicsLabel"
                                                      id?: string | null
                                                      sequence?: number | null
                                                      autoValue?: string | null
                                                      text?: string | null
                                                      linkType?: Types.VectorGraphicsLabelLinkType | null
                                                      link?: string | null
                                                      ref?:
                                                        | {
                                                            __typename: "GenericRef"
                                                            id: string
                                                            owner?:
                                                              | { __typename: "CommentNode" }
                                                              | { __typename: "ExternalSite" }
                                                              | { __typename: "ST4Class" }
                                                              | { __typename: "ST4Node"; id: string }
                                                              | { __typename: "ST4TreeNode" }
                                                              | null
                                                            target?:
                                                              | { __typename: "CommentNode" }
                                                              | {
                                                                  __typename: "ExternalSite"
                                                                  id: string
                                                                  teaserData?: {
                                                                    __typename: "ExternalLinkTeaserData"
                                                                    title?: string | null
                                                                    summary?: string | null
                                                                    iconUrl?: string | null
                                                                    imageUrl?: string | null
                                                                    status?: Types.LinkTargetStatus | null
                                                                  } | null
                                                                }
                                                              | { __typename: "ST4Class" }
                                                              | {
                                                                  __typename: "ST4Node"
                                                                  id: string
                                                                  aspectId: any
                                                                  label?: string | null
                                                                  properties?: {
                                                                    __typename: "ContentPropertyConnection"
                                                                    edges?: Array<{
                                                                      __typename: "ContentPropertyEdge"
                                                                      node?: {
                                                                        __typename: "ContentProperty"
                                                                        name: string
                                                                        value?: any | null
                                                                        displayName: string
                                                                      } | null
                                                                    } | null> | null
                                                                  } | null
                                                                }
                                                              | { __typename: "ST4TreeNode" }
                                                              | null
                                                          }
                                                        | {
                                                            __typename: "NodeRef"
                                                            id: string
                                                            owner?:
                                                              | { __typename: "CommentNode" }
                                                              | { __typename: "ExternalSite" }
                                                              | { __typename: "ST4Class" }
                                                              | { __typename: "ST4Node"; id: string }
                                                              | { __typename: "ST4TreeNode" }
                                                              | null
                                                            target?:
                                                              | { __typename: "CommentNode" }
                                                              | {
                                                                  __typename: "ExternalSite"
                                                                  id: string
                                                                  teaserData?: {
                                                                    __typename: "ExternalLinkTeaserData"
                                                                    title?: string | null
                                                                    summary?: string | null
                                                                    iconUrl?: string | null
                                                                    imageUrl?: string | null
                                                                    status?: Types.LinkTargetStatus | null
                                                                  } | null
                                                                }
                                                              | { __typename: "ST4Class" }
                                                              | {
                                                                  __typename: "ST4Node"
                                                                  id: string
                                                                  aspectId: any
                                                                  label?: string | null
                                                                  properties?: {
                                                                    __typename: "ContentPropertyConnection"
                                                                    edges?: Array<{
                                                                      __typename: "ContentPropertyEdge"
                                                                      node?: {
                                                                        __typename: "ContentProperty"
                                                                        name: string
                                                                        value?: any | null
                                                                        displayName: string
                                                                      } | null
                                                                    } | null> | null
                                                                  } | null
                                                                }
                                                              | { __typename: "ST4TreeNode" }
                                                              | null
                                                          }
                                                        | null
                                                    }>
                                                  }
                                                | {
                                                    __typename: "VideoMediaInfo"
                                                    id: string
                                                    resourceId?: string | null
                                                    thumbnail?: string | null
                                                    width?: number | null
                                                    height?: number | null
                                                    mediaType?: Types.MediaType | null
                                                  }
                                                | null
                                            }
                                          | { __typename: "ProjectContent" }
                                          | { __typename: "TermContent" }
                                          | { __typename: "TextContent" }
                                          | { __typename: "TextGroupContent" }
                                          | { __typename: "VariableTableContent" }
                                          | null
                                      }
                                    | { __typename: "ST4TreeNode"; id: string }
                                    | null
                                }>
                                media?:
                                  | {
                                      __typename: "AnimationMediaInfo"
                                      id: string
                                      resourceId?: string | null
                                      thumbnail?: string | null
                                      width?: number | null
                                      height?: number | null
                                      mediaType?: Types.MediaType | null
                                    }
                                  | {
                                      __typename: "ImageMediaInfo"
                                      id: string
                                      resourceId?: string | null
                                      thumbnail?: string | null
                                      width?: number | null
                                      height?: number | null
                                      resolution?: number | null
                                      colorDepth?: number | null
                                      size?: number | null
                                      mime?: string | null
                                      extension?: string | null
                                      allowCaching?: boolean | null
                                      mediaType?: Types.MediaType | null
                                      src?: string | null
                                      language?: string | null
                                    }
                                  | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                                  | {
                                      __typename: "VectorGraphicsMediaInfo"
                                      id: string
                                      resourceId?: string | null
                                      thumbnail?: string | null
                                      width?: number | null
                                      height?: number | null
                                      allowCaching?: boolean | null
                                      mediaType?: Types.MediaType | null
                                      language?: string | null
                                      labels: Array<{
                                        __typename: "VectorGraphicsLabel"
                                        id?: string | null
                                        sequence?: number | null
                                        autoValue?: string | null
                                        text?: string | null
                                        linkType?: Types.VectorGraphicsLabelLinkType | null
                                        link?: string | null
                                        ref?:
                                          | {
                                              __typename: "GenericRef"
                                              id: string
                                              owner?:
                                                | { __typename: "CommentNode" }
                                                | { __typename: "ExternalSite" }
                                                | { __typename: "ST4Class" }
                                                | { __typename: "ST4Node"; id: string }
                                                | { __typename: "ST4TreeNode" }
                                                | null
                                              target?:
                                                | { __typename: "CommentNode" }
                                                | {
                                                    __typename: "ExternalSite"
                                                    id: string
                                                    teaserData?: {
                                                      __typename: "ExternalLinkTeaserData"
                                                      title?: string | null
                                                      summary?: string | null
                                                      iconUrl?: string | null
                                                      imageUrl?: string | null
                                                      status?: Types.LinkTargetStatus | null
                                                    } | null
                                                  }
                                                | { __typename: "ST4Class" }
                                                | {
                                                    __typename: "ST4Node"
                                                    id: string
                                                    aspectId: any
                                                    label?: string | null
                                                    properties?: {
                                                      __typename: "ContentPropertyConnection"
                                                      edges?: Array<{
                                                        __typename: "ContentPropertyEdge"
                                                        node?: {
                                                          __typename: "ContentProperty"
                                                          name: string
                                                          value?: any | null
                                                          displayName: string
                                                        } | null
                                                      } | null> | null
                                                    } | null
                                                  }
                                                | { __typename: "ST4TreeNode" }
                                                | null
                                            }
                                          | {
                                              __typename: "NodeRef"
                                              id: string
                                              owner?:
                                                | { __typename: "CommentNode" }
                                                | { __typename: "ExternalSite" }
                                                | { __typename: "ST4Class" }
                                                | { __typename: "ST4Node"; id: string }
                                                | { __typename: "ST4TreeNode" }
                                                | null
                                              target?:
                                                | { __typename: "CommentNode" }
                                                | {
                                                    __typename: "ExternalSite"
                                                    id: string
                                                    teaserData?: {
                                                      __typename: "ExternalLinkTeaserData"
                                                      title?: string | null
                                                      summary?: string | null
                                                      iconUrl?: string | null
                                                      imageUrl?: string | null
                                                      status?: Types.LinkTargetStatus | null
                                                    } | null
                                                  }
                                                | { __typename: "ST4Class" }
                                                | {
                                                    __typename: "ST4Node"
                                                    id: string
                                                    aspectId: any
                                                    label?: string | null
                                                    properties?: {
                                                      __typename: "ContentPropertyConnection"
                                                      edges?: Array<{
                                                        __typename: "ContentPropertyEdge"
                                                        node?: {
                                                          __typename: "ContentProperty"
                                                          name: string
                                                          value?: any | null
                                                          displayName: string
                                                        } | null
                                                      } | null> | null
                                                    } | null
                                                  }
                                                | { __typename: "ST4TreeNode" }
                                                | null
                                            }
                                          | null
                                      }>
                                    }
                                  | {
                                      __typename: "VideoMediaInfo"
                                      id: string
                                      resourceId?: string | null
                                      thumbnail?: string | null
                                      width?: number | null
                                      height?: number | null
                                      mediaType?: Types.MediaType | null
                                    }
                                  | null
                              }
                            | { __typename: "ProjectContent" }
                            | { __typename: "TermContent" }
                            | { __typename: "TextContent" }
                            | { __typename: "TextGroupContent" }
                            | { __typename: "VariableTableContent" }
                            | null
                        }
                      | { __typename: "ST4TreeNode"; id: string }
                      | null
                  }>
                  variableTables?: Array<{
                    __typename: "VariableTableReference"
                    ref?: string | null
                    node?:
                      | { __typename: "CommentNode" }
                      | { __typename: "ExternalSite" }
                      | { __typename: "ST4Class" }
                      | {
                          __typename: "ST4Node"
                          id: string
                          label?: string | null
                          aspectId: any
                          content?:
                            | { __typename: "LexiconContent" }
                            | { __typename: "LexiconEntryContent" }
                            | { __typename: "MediaContent" }
                            | { __typename: "MediaGroupContent" }
                            | { __typename: "ProjectContent" }
                            | { __typename: "TermContent" }
                            | { __typename: "TextContent" }
                            | { __typename: "TextGroupContent" }
                            | {
                                __typename: "VariableTableContent"
                                xml?: string | null
                                valueSets: Array<{ __typename: "ValueSet"; name: string; valueSetId: string }>
                                variables: Array<{
                                  __typename: "Variable"
                                  name: string
                                  variableId: string
                                  values: Array<string>
                                }>
                              }
                            | null
                        }
                      | { __typename: "ST4TreeNode" }
                      | null
                  } | null> | null
                  linkedDataNodes: Array<{
                    __typename: "NodeRef"
                    id: string
                    target?:
                      | { __typename: "CommentNode"; id: string }
                      | { __typename: "ExternalSite"; id: string }
                      | { __typename: "ST4Class"; id: string }
                      | {
                          __typename: "ST4Node"
                          label?: string | null
                          id: string
                          metadata: Array<{
                            __typename: "MetaDataEntry"
                            label?: string | null
                            systemName: string
                            value?: string | null
                            values: Array<string>
                            users: Array<string>
                          }>
                          linkedOntologyNodes: Array<
                            | {
                                __typename: "GenericRef"
                                target?:
                                  | { __typename: "CommentNode" }
                                  | { __typename: "ExternalSite" }
                                  | { __typename: "ST4Class" }
                                  | { __typename: "ST4Node"; id: string; label?: string | null }
                                  | { __typename: "ST4TreeNode" }
                                  | null
                              }
                            | {
                                __typename: "NodeRef"
                                linkClassName: string
                                class?: { __typename: "ST4Class"; label?: string | null } | null
                                target?:
                                  | { __typename: "CommentNode" }
                                  | { __typename: "ExternalSite" }
                                  | { __typename: "ST4Class" }
                                  | { __typename: "ST4Node"; id: string; label?: string | null }
                                  | { __typename: "ST4TreeNode" }
                                  | null
                              }
                          >
                        }
                      | { __typename: "ST4TreeNode"; id: string }
                      | null
                  }>
                  lexiconEntries?: Array<{
                    __typename: "NodeRef"
                    id: string
                    target?:
                      | { __typename: "CommentNode"; id: string }
                      | { __typename: "ExternalSite"; id: string }
                      | { __typename: "ST4Class"; id: string }
                      | {
                          __typename: "ST4Node"
                          aspectId: any
                          label?: string | null
                          id: string
                          content?:
                            | { __typename: "LexiconContent" }
                            | {
                                __typename: "LexiconEntryContent"
                                descriptionXast?: string | null
                                subject?: string | null
                                sortKey?: string | null
                                lexicon:
                                  | { __typename: "CommentNode"; id: string }
                                  | { __typename: "ExternalSite"; id: string }
                                  | { __typename: "ST4Class"; id: string }
                                  | { __typename: "ST4Node"; label?: string | null; id: string }
                                  | { __typename: "ST4TreeNode"; id: string }
                                terms: Array<
                                  | { __typename: "CommentNode"; id: string }
                                  | { __typename: "ExternalSite"; id: string }
                                  | { __typename: "ST4Class"; id: string }
                                  | {
                                      __typename: "ST4Node"
                                      id: string
                                      label?: string | null
                                      aspectId: any
                                      content?:
                                        | { __typename: "LexiconContent" }
                                        | { __typename: "LexiconEntryContent" }
                                        | { __typename: "MediaContent" }
                                        | { __typename: "MediaGroupContent" }
                                        | { __typename: "ProjectContent" }
                                        | {
                                            __typename: "TermContent"
                                            gramaticalGender?: string | null
                                            validity?: string | null
                                            editLanguage?: string | null
                                          }
                                        | { __typename: "TextContent" }
                                        | { __typename: "TextGroupContent" }
                                        | { __typename: "VariableTableContent" }
                                        | null
                                    }
                                  | { __typename: "ST4TreeNode"; id: string }
                                >
                                image?:
                                  | { __typename: "CommentNode"; id: string }
                                  | { __typename: "ExternalSite"; id: string }
                                  | { __typename: "ST4Class"; id: string }
                                  | {
                                      __typename: "ST4Node"
                                      aspectId: any
                                      id: string
                                      content?:
                                        | { __typename: "LexiconContent" }
                                        | { __typename: "LexiconEntryContent" }
                                        | {
                                            __typename: "MediaContent"
                                            media?:
                                              | {
                                                  __typename: "AnimationMediaInfo"
                                                  thumbnail?: string | null
                                                  mediaType?: Types.MediaType | null
                                                }
                                              | {
                                                  __typename: "ImageMediaInfo"
                                                  src?: string | null
                                                  thumbnail?: string | null
                                                  mediaType?: Types.MediaType | null
                                                }
                                              | { __typename: "NoMediaInfo" }
                                              | {
                                                  __typename: "VectorGraphicsMediaInfo"
                                                  svg?: string | null
                                                  thumbnail?: string | null
                                                  mediaType?: Types.MediaType | null
                                                }
                                              | {
                                                  __typename: "VideoMediaInfo"
                                                  thumbnail?: string | null
                                                  mediaType?: Types.MediaType | null
                                                }
                                              | null
                                          }
                                        | { __typename: "MediaGroupContent" }
                                        | { __typename: "ProjectContent" }
                                        | { __typename: "TermContent" }
                                        | { __typename: "TextContent" }
                                        | { __typename: "TextGroupContent" }
                                        | { __typename: "VariableTableContent" }
                                        | null
                                    }
                                  | { __typename: "ST4TreeNode"; id: string }
                                  | null
                              }
                            | { __typename: "MediaContent" }
                            | { __typename: "MediaGroupContent" }
                            | { __typename: "ProjectContent" }
                            | { __typename: "TermContent" }
                            | { __typename: "TextContent" }
                            | { __typename: "TextGroupContent" }
                            | { __typename: "VariableTableContent" }
                            | null
                        }
                      | { __typename: "ST4TreeNode"; id: string }
                      | null
                  } | null> | null
                  comments: Array<{
                    __typename: "CommentNode"
                    id: string
                    comment:
                      | {
                          __typename: "BlockComment"
                          location: number
                          value?: string | null
                          authorDisplayName?: string | null
                          authorUserName?: string | null
                          commentKey: string
                          editDate?: any | null
                          modificationVersion?: number | null
                          replyToKey?: string | null
                          sortOrder?: number | null
                          commentType?: string | null
                          state?: string | null
                          color: string
                          persistanceState?: Types.PersistanceState | null
                          persistanceAction?: Types.PersistanceAction | null
                          node: {
                            __typename: "ST4Node"
                            aspectId: any
                            id: string
                            content?:
                              | { __typename: "LexiconContent" }
                              | { __typename: "LexiconEntryContent" }
                              | { __typename: "MediaContent" }
                              | { __typename: "MediaGroupContent" }
                              | { __typename: "ProjectContent" }
                              | { __typename: "TermContent" }
                              | { __typename: "TextContent"; id: string; historyNumber: number }
                              | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                              | { __typename: "VariableTableContent" }
                              | null
                          }
                          actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
                        }
                      | {
                          __typename: "TextComment"
                          endLocation: number
                          startLocation?: number | null
                          value?: string | null
                          authorDisplayName?: string | null
                          authorUserName?: string | null
                          commentKey: string
                          editDate?: any | null
                          modificationVersion?: number | null
                          replyToKey?: string | null
                          sortOrder?: number | null
                          commentType?: string | null
                          state?: string | null
                          color: string
                          persistanceState?: Types.PersistanceState | null
                          persistanceAction?: Types.PersistanceAction | null
                          node: {
                            __typename: "ST4Node"
                            aspectId: any
                            id: string
                            content?:
                              | { __typename: "LexiconContent" }
                              | { __typename: "LexiconEntryContent" }
                              | { __typename: "MediaContent" }
                              | { __typename: "MediaGroupContent" }
                              | { __typename: "ProjectContent" }
                              | { __typename: "TermContent" }
                              | { __typename: "TextContent"; id: string; historyNumber: number }
                              | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                              | { __typename: "VariableTableContent" }
                              | null
                          }
                          actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
                        }
                  }>
                }
              | {
                  __typename: "TextGroupContent"
                  currentNodeId?: any | null
                  groupNodeId: any
                  id: string
                  xast?: string | null
                  historyNumber: number
                  children: Array<{
                    __typename: "NodeRef"
                    id: string
                    target?:
                      | { __typename: "CommentNode"; id: string }
                      | { __typename: "ExternalSite"; id: string }
                      | { __typename: "ST4Class"; id: string }
                      | { __typename: "ST4Node"; label?: string | null; aspectId: any; id: string }
                      | { __typename: "ST4TreeNode"; id: string }
                      | null
                  }>
                  links?: Array<{
                    __typename: "TextContentLink"
                    ref?: string | null
                    node?:
                      | { __typename: "CommentNode"; id: string }
                      | { __typename: "ExternalSite"; id: string }
                      | { __typename: "ST4Class"; id: string }
                      | {
                          __typename: "ST4Node"
                          id: string
                          aspectId: any
                          label?: string | null
                          properties?: {
                            __typename: "ContentPropertyConnection"
                            totalCount: number
                            edges?: Array<{
                              __typename: "ContentPropertyEdge"
                              node?: {
                                __typename: "ContentProperty"
                                typeOfValue: Types.ContentPropertyValueType
                                name: string
                                value?: any | null
                                displayName: string
                              } | null
                            } | null> | null
                          } | null
                        }
                      | { __typename: "ST4TreeNode"; id: string }
                      | null
                  } | null> | null
                  images: Array<{
                    __typename: "NodeRef"
                    id: string
                    target?:
                      | { __typename: "CommentNode"; id: string }
                      | { __typename: "ExternalSite"; id: string }
                      | { __typename: "ST4Class"; id: string }
                      | {
                          __typename: "ST4Node"
                          aspectId: any
                          id: string
                          releaseState: {
                            __typename: "ReleaseStateValue"
                            displayName: string
                            state: Types.ReleaseState
                            isImplicitlyReleased: boolean
                          }
                          content?:
                            | { __typename: "LexiconContent" }
                            | { __typename: "LexiconEntryContent" }
                            | {
                                __typename: "MediaContent"
                                media?:
                                  | {
                                      __typename: "AnimationMediaInfo"
                                      id: string
                                      resourceId?: string | null
                                      thumbnail?: string | null
                                      width?: number | null
                                      height?: number | null
                                      mediaType?: Types.MediaType | null
                                    }
                                  | {
                                      __typename: "ImageMediaInfo"
                                      id: string
                                      resourceId?: string | null
                                      thumbnail?: string | null
                                      width?: number | null
                                      height?: number | null
                                      resolution?: number | null
                                      colorDepth?: number | null
                                      size?: number | null
                                      mime?: string | null
                                      extension?: string | null
                                      allowCaching?: boolean | null
                                      mediaType?: Types.MediaType | null
                                      src?: string | null
                                      language?: string | null
                                    }
                                  | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                                  | {
                                      __typename: "VectorGraphicsMediaInfo"
                                      id: string
                                      resourceId?: string | null
                                      thumbnail?: string | null
                                      width?: number | null
                                      height?: number | null
                                      allowCaching?: boolean | null
                                      mediaType?: Types.MediaType | null
                                      language?: string | null
                                      labels: Array<{
                                        __typename: "VectorGraphicsLabel"
                                        id?: string | null
                                        sequence?: number | null
                                        autoValue?: string | null
                                        text?: string | null
                                        linkType?: Types.VectorGraphicsLabelLinkType | null
                                        link?: string | null
                                        ref?:
                                          | {
                                              __typename: "GenericRef"
                                              id: string
                                              owner?:
                                                | { __typename: "CommentNode" }
                                                | { __typename: "ExternalSite" }
                                                | { __typename: "ST4Class" }
                                                | { __typename: "ST4Node"; id: string }
                                                | { __typename: "ST4TreeNode" }
                                                | null
                                              target?:
                                                | { __typename: "CommentNode" }
                                                | {
                                                    __typename: "ExternalSite"
                                                    id: string
                                                    teaserData?: {
                                                      __typename: "ExternalLinkTeaserData"
                                                      title?: string | null
                                                      summary?: string | null
                                                      iconUrl?: string | null
                                                      imageUrl?: string | null
                                                      status?: Types.LinkTargetStatus | null
                                                    } | null
                                                  }
                                                | { __typename: "ST4Class" }
                                                | {
                                                    __typename: "ST4Node"
                                                    id: string
                                                    aspectId: any
                                                    label?: string | null
                                                    properties?: {
                                                      __typename: "ContentPropertyConnection"
                                                      edges?: Array<{
                                                        __typename: "ContentPropertyEdge"
                                                        node?: {
                                                          __typename: "ContentProperty"
                                                          name: string
                                                          value?: any | null
                                                          displayName: string
                                                        } | null
                                                      } | null> | null
                                                    } | null
                                                  }
                                                | { __typename: "ST4TreeNode" }
                                                | null
                                            }
                                          | {
                                              __typename: "NodeRef"
                                              id: string
                                              owner?:
                                                | { __typename: "CommentNode" }
                                                | { __typename: "ExternalSite" }
                                                | { __typename: "ST4Class" }
                                                | { __typename: "ST4Node"; id: string }
                                                | { __typename: "ST4TreeNode" }
                                                | null
                                              target?:
                                                | { __typename: "CommentNode" }
                                                | {
                                                    __typename: "ExternalSite"
                                                    id: string
                                                    teaserData?: {
                                                      __typename: "ExternalLinkTeaserData"
                                                      title?: string | null
                                                      summary?: string | null
                                                      iconUrl?: string | null
                                                      imageUrl?: string | null
                                                      status?: Types.LinkTargetStatus | null
                                                    } | null
                                                  }
                                                | { __typename: "ST4Class" }
                                                | {
                                                    __typename: "ST4Node"
                                                    id: string
                                                    aspectId: any
                                                    label?: string | null
                                                    properties?: {
                                                      __typename: "ContentPropertyConnection"
                                                      edges?: Array<{
                                                        __typename: "ContentPropertyEdge"
                                                        node?: {
                                                          __typename: "ContentProperty"
                                                          name: string
                                                          value?: any | null
                                                          displayName: string
                                                        } | null
                                                      } | null> | null
                                                    } | null
                                                  }
                                                | { __typename: "ST4TreeNode" }
                                                | null
                                            }
                                          | null
                                      }>
                                    }
                                  | {
                                      __typename: "VideoMediaInfo"
                                      id: string
                                      resourceId?: string | null
                                      thumbnail?: string | null
                                      width?: number | null
                                      height?: number | null
                                      mediaType?: Types.MediaType | null
                                    }
                                  | null
                              }
                            | {
                                __typename: "MediaGroupContent"
                                children: Array<{
                                  __typename: "NodeRef"
                                  id: string
                                  target?:
                                    | { __typename: "CommentNode"; id: string }
                                    | { __typename: "ExternalSite"; id: string }
                                    | { __typename: "ST4Class"; id: string }
                                    | {
                                        __typename: "ST4Node"
                                        id: string
                                        aspectId: any
                                        content?:
                                          | { __typename: "LexiconContent" }
                                          | { __typename: "LexiconEntryContent" }
                                          | {
                                              __typename: "MediaContent"
                                              media?:
                                                | {
                                                    __typename: "AnimationMediaInfo"
                                                    id: string
                                                    resourceId?: string | null
                                                    thumbnail?: string | null
                                                    width?: number | null
                                                    height?: number | null
                                                    mediaType?: Types.MediaType | null
                                                  }
                                                | {
                                                    __typename: "ImageMediaInfo"
                                                    id: string
                                                    resourceId?: string | null
                                                    thumbnail?: string | null
                                                    width?: number | null
                                                    height?: number | null
                                                    resolution?: number | null
                                                    colorDepth?: number | null
                                                    size?: number | null
                                                    mime?: string | null
                                                    extension?: string | null
                                                    allowCaching?: boolean | null
                                                    mediaType?: Types.MediaType | null
                                                    src?: string | null
                                                    language?: string | null
                                                  }
                                                | {
                                                    __typename: "NoMediaInfo"
                                                    id: string
                                                    mediaType?: Types.MediaType | null
                                                  }
                                                | {
                                                    __typename: "VectorGraphicsMediaInfo"
                                                    id: string
                                                    resourceId?: string | null
                                                    thumbnail?: string | null
                                                    width?: number | null
                                                    height?: number | null
                                                    allowCaching?: boolean | null
                                                    mediaType?: Types.MediaType | null
                                                    language?: string | null
                                                    labels: Array<{
                                                      __typename: "VectorGraphicsLabel"
                                                      id?: string | null
                                                      sequence?: number | null
                                                      autoValue?: string | null
                                                      text?: string | null
                                                      linkType?: Types.VectorGraphicsLabelLinkType | null
                                                      link?: string | null
                                                      ref?:
                                                        | {
                                                            __typename: "GenericRef"
                                                            id: string
                                                            owner?:
                                                              | { __typename: "CommentNode" }
                                                              | { __typename: "ExternalSite" }
                                                              | { __typename: "ST4Class" }
                                                              | { __typename: "ST4Node"; id: string }
                                                              | { __typename: "ST4TreeNode" }
                                                              | null
                                                            target?:
                                                              | { __typename: "CommentNode" }
                                                              | {
                                                                  __typename: "ExternalSite"
                                                                  id: string
                                                                  teaserData?: {
                                                                    __typename: "ExternalLinkTeaserData"
                                                                    title?: string | null
                                                                    summary?: string | null
                                                                    iconUrl?: string | null
                                                                    imageUrl?: string | null
                                                                    status?: Types.LinkTargetStatus | null
                                                                  } | null
                                                                }
                                                              | { __typename: "ST4Class" }
                                                              | {
                                                                  __typename: "ST4Node"
                                                                  id: string
                                                                  aspectId: any
                                                                  label?: string | null
                                                                  properties?: {
                                                                    __typename: "ContentPropertyConnection"
                                                                    edges?: Array<{
                                                                      __typename: "ContentPropertyEdge"
                                                                      node?: {
                                                                        __typename: "ContentProperty"
                                                                        name: string
                                                                        value?: any | null
                                                                        displayName: string
                                                                      } | null
                                                                    } | null> | null
                                                                  } | null
                                                                }
                                                              | { __typename: "ST4TreeNode" }
                                                              | null
                                                          }
                                                        | {
                                                            __typename: "NodeRef"
                                                            id: string
                                                            owner?:
                                                              | { __typename: "CommentNode" }
                                                              | { __typename: "ExternalSite" }
                                                              | { __typename: "ST4Class" }
                                                              | { __typename: "ST4Node"; id: string }
                                                              | { __typename: "ST4TreeNode" }
                                                              | null
                                                            target?:
                                                              | { __typename: "CommentNode" }
                                                              | {
                                                                  __typename: "ExternalSite"
                                                                  id: string
                                                                  teaserData?: {
                                                                    __typename: "ExternalLinkTeaserData"
                                                                    title?: string | null
                                                                    summary?: string | null
                                                                    iconUrl?: string | null
                                                                    imageUrl?: string | null
                                                                    status?: Types.LinkTargetStatus | null
                                                                  } | null
                                                                }
                                                              | { __typename: "ST4Class" }
                                                              | {
                                                                  __typename: "ST4Node"
                                                                  id: string
                                                                  aspectId: any
                                                                  label?: string | null
                                                                  properties?: {
                                                                    __typename: "ContentPropertyConnection"
                                                                    edges?: Array<{
                                                                      __typename: "ContentPropertyEdge"
                                                                      node?: {
                                                                        __typename: "ContentProperty"
                                                                        name: string
                                                                        value?: any | null
                                                                        displayName: string
                                                                      } | null
                                                                    } | null> | null
                                                                  } | null
                                                                }
                                                              | { __typename: "ST4TreeNode" }
                                                              | null
                                                          }
                                                        | null
                                                    }>
                                                  }
                                                | {
                                                    __typename: "VideoMediaInfo"
                                                    id: string
                                                    resourceId?: string | null
                                                    thumbnail?: string | null
                                                    width?: number | null
                                                    height?: number | null
                                                    mediaType?: Types.MediaType | null
                                                  }
                                                | null
                                            }
                                          | {
                                              __typename: "MediaGroupContent"
                                              media?:
                                                | {
                                                    __typename: "AnimationMediaInfo"
                                                    id: string
                                                    resourceId?: string | null
                                                    thumbnail?: string | null
                                                    width?: number | null
                                                    height?: number | null
                                                    mediaType?: Types.MediaType | null
                                                  }
                                                | {
                                                    __typename: "ImageMediaInfo"
                                                    id: string
                                                    resourceId?: string | null
                                                    thumbnail?: string | null
                                                    width?: number | null
                                                    height?: number | null
                                                    resolution?: number | null
                                                    colorDepth?: number | null
                                                    size?: number | null
                                                    mime?: string | null
                                                    extension?: string | null
                                                    allowCaching?: boolean | null
                                                    mediaType?: Types.MediaType | null
                                                    src?: string | null
                                                    language?: string | null
                                                  }
                                                | {
                                                    __typename: "NoMediaInfo"
                                                    id: string
                                                    mediaType?: Types.MediaType | null
                                                  }
                                                | {
                                                    __typename: "VectorGraphicsMediaInfo"
                                                    id: string
                                                    resourceId?: string | null
                                                    thumbnail?: string | null
                                                    width?: number | null
                                                    height?: number | null
                                                    allowCaching?: boolean | null
                                                    mediaType?: Types.MediaType | null
                                                    language?: string | null
                                                    labels: Array<{
                                                      __typename: "VectorGraphicsLabel"
                                                      id?: string | null
                                                      sequence?: number | null
                                                      autoValue?: string | null
                                                      text?: string | null
                                                      linkType?: Types.VectorGraphicsLabelLinkType | null
                                                      link?: string | null
                                                      ref?:
                                                        | {
                                                            __typename: "GenericRef"
                                                            id: string
                                                            owner?:
                                                              | { __typename: "CommentNode" }
                                                              | { __typename: "ExternalSite" }
                                                              | { __typename: "ST4Class" }
                                                              | { __typename: "ST4Node"; id: string }
                                                              | { __typename: "ST4TreeNode" }
                                                              | null
                                                            target?:
                                                              | { __typename: "CommentNode" }
                                                              | {
                                                                  __typename: "ExternalSite"
                                                                  id: string
                                                                  teaserData?: {
                                                                    __typename: "ExternalLinkTeaserData"
                                                                    title?: string | null
                                                                    summary?: string | null
                                                                    iconUrl?: string | null
                                                                    imageUrl?: string | null
                                                                    status?: Types.LinkTargetStatus | null
                                                                  } | null
                                                                }
                                                              | { __typename: "ST4Class" }
                                                              | {
                                                                  __typename: "ST4Node"
                                                                  id: string
                                                                  aspectId: any
                                                                  label?: string | null
                                                                  properties?: {
                                                                    __typename: "ContentPropertyConnection"
                                                                    edges?: Array<{
                                                                      __typename: "ContentPropertyEdge"
                                                                      node?: {
                                                                        __typename: "ContentProperty"
                                                                        name: string
                                                                        value?: any | null
                                                                        displayName: string
                                                                      } | null
                                                                    } | null> | null
                                                                  } | null
                                                                }
                                                              | { __typename: "ST4TreeNode" }
                                                              | null
                                                          }
                                                        | {
                                                            __typename: "NodeRef"
                                                            id: string
                                                            owner?:
                                                              | { __typename: "CommentNode" }
                                                              | { __typename: "ExternalSite" }
                                                              | { __typename: "ST4Class" }
                                                              | { __typename: "ST4Node"; id: string }
                                                              | { __typename: "ST4TreeNode" }
                                                              | null
                                                            target?:
                                                              | { __typename: "CommentNode" }
                                                              | {
                                                                  __typename: "ExternalSite"
                                                                  id: string
                                                                  teaserData?: {
                                                                    __typename: "ExternalLinkTeaserData"
                                                                    title?: string | null
                                                                    summary?: string | null
                                                                    iconUrl?: string | null
                                                                    imageUrl?: string | null
                                                                    status?: Types.LinkTargetStatus | null
                                                                  } | null
                                                                }
                                                              | { __typename: "ST4Class" }
                                                              | {
                                                                  __typename: "ST4Node"
                                                                  id: string
                                                                  aspectId: any
                                                                  label?: string | null
                                                                  properties?: {
                                                                    __typename: "ContentPropertyConnection"
                                                                    edges?: Array<{
                                                                      __typename: "ContentPropertyEdge"
                                                                      node?: {
                                                                        __typename: "ContentProperty"
                                                                        name: string
                                                                        value?: any | null
                                                                        displayName: string
                                                                      } | null
                                                                    } | null> | null
                                                                  } | null
                                                                }
                                                              | { __typename: "ST4TreeNode" }
                                                              | null
                                                          }
                                                        | null
                                                    }>
                                                  }
                                                | {
                                                    __typename: "VideoMediaInfo"
                                                    id: string
                                                    resourceId?: string | null
                                                    thumbnail?: string | null
                                                    width?: number | null
                                                    height?: number | null
                                                    mediaType?: Types.MediaType | null
                                                  }
                                                | null
                                            }
                                          | { __typename: "ProjectContent" }
                                          | { __typename: "TermContent" }
                                          | { __typename: "TextContent" }
                                          | { __typename: "TextGroupContent" }
                                          | { __typename: "VariableTableContent" }
                                          | null
                                      }
                                    | { __typename: "ST4TreeNode"; id: string }
                                    | null
                                }>
                                media?:
                                  | {
                                      __typename: "AnimationMediaInfo"
                                      id: string
                                      resourceId?: string | null
                                      thumbnail?: string | null
                                      width?: number | null
                                      height?: number | null
                                      mediaType?: Types.MediaType | null
                                    }
                                  | {
                                      __typename: "ImageMediaInfo"
                                      id: string
                                      resourceId?: string | null
                                      thumbnail?: string | null
                                      width?: number | null
                                      height?: number | null
                                      resolution?: number | null
                                      colorDepth?: number | null
                                      size?: number | null
                                      mime?: string | null
                                      extension?: string | null
                                      allowCaching?: boolean | null
                                      mediaType?: Types.MediaType | null
                                      src?: string | null
                                      language?: string | null
                                    }
                                  | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                                  | {
                                      __typename: "VectorGraphicsMediaInfo"
                                      id: string
                                      resourceId?: string | null
                                      thumbnail?: string | null
                                      width?: number | null
                                      height?: number | null
                                      allowCaching?: boolean | null
                                      mediaType?: Types.MediaType | null
                                      language?: string | null
                                      labels: Array<{
                                        __typename: "VectorGraphicsLabel"
                                        id?: string | null
                                        sequence?: number | null
                                        autoValue?: string | null
                                        text?: string | null
                                        linkType?: Types.VectorGraphicsLabelLinkType | null
                                        link?: string | null
                                        ref?:
                                          | {
                                              __typename: "GenericRef"
                                              id: string
                                              owner?:
                                                | { __typename: "CommentNode" }
                                                | { __typename: "ExternalSite" }
                                                | { __typename: "ST4Class" }
                                                | { __typename: "ST4Node"; id: string }
                                                | { __typename: "ST4TreeNode" }
                                                | null
                                              target?:
                                                | { __typename: "CommentNode" }
                                                | {
                                                    __typename: "ExternalSite"
                                                    id: string
                                                    teaserData?: {
                                                      __typename: "ExternalLinkTeaserData"
                                                      title?: string | null
                                                      summary?: string | null
                                                      iconUrl?: string | null
                                                      imageUrl?: string | null
                                                      status?: Types.LinkTargetStatus | null
                                                    } | null
                                                  }
                                                | { __typename: "ST4Class" }
                                                | {
                                                    __typename: "ST4Node"
                                                    id: string
                                                    aspectId: any
                                                    label?: string | null
                                                    properties?: {
                                                      __typename: "ContentPropertyConnection"
                                                      edges?: Array<{
                                                        __typename: "ContentPropertyEdge"
                                                        node?: {
                                                          __typename: "ContentProperty"
                                                          name: string
                                                          value?: any | null
                                                          displayName: string
                                                        } | null
                                                      } | null> | null
                                                    } | null
                                                  }
                                                | { __typename: "ST4TreeNode" }
                                                | null
                                            }
                                          | {
                                              __typename: "NodeRef"
                                              id: string
                                              owner?:
                                                | { __typename: "CommentNode" }
                                                | { __typename: "ExternalSite" }
                                                | { __typename: "ST4Class" }
                                                | { __typename: "ST4Node"; id: string }
                                                | { __typename: "ST4TreeNode" }
                                                | null
                                              target?:
                                                | { __typename: "CommentNode" }
                                                | {
                                                    __typename: "ExternalSite"
                                                    id: string
                                                    teaserData?: {
                                                      __typename: "ExternalLinkTeaserData"
                                                      title?: string | null
                                                      summary?: string | null
                                                      iconUrl?: string | null
                                                      imageUrl?: string | null
                                                      status?: Types.LinkTargetStatus | null
                                                    } | null
                                                  }
                                                | { __typename: "ST4Class" }
                                                | {
                                                    __typename: "ST4Node"
                                                    id: string
                                                    aspectId: any
                                                    label?: string | null
                                                    properties?: {
                                                      __typename: "ContentPropertyConnection"
                                                      edges?: Array<{
                                                        __typename: "ContentPropertyEdge"
                                                        node?: {
                                                          __typename: "ContentProperty"
                                                          name: string
                                                          value?: any | null
                                                          displayName: string
                                                        } | null
                                                      } | null> | null
                                                    } | null
                                                  }
                                                | { __typename: "ST4TreeNode" }
                                                | null
                                            }
                                          | null
                                      }>
                                    }
                                  | {
                                      __typename: "VideoMediaInfo"
                                      id: string
                                      resourceId?: string | null
                                      thumbnail?: string | null
                                      width?: number | null
                                      height?: number | null
                                      mediaType?: Types.MediaType | null
                                    }
                                  | null
                              }
                            | { __typename: "ProjectContent" }
                            | { __typename: "TermContent" }
                            | { __typename: "TextContent" }
                            | { __typename: "TextGroupContent" }
                            | { __typename: "VariableTableContent" }
                            | null
                        }
                      | { __typename: "ST4TreeNode"; id: string }
                      | null
                  }>
                  variableTables?: Array<{
                    __typename: "VariableTableReference"
                    ref?: string | null
                    node?:
                      | { __typename: "CommentNode" }
                      | { __typename: "ExternalSite" }
                      | { __typename: "ST4Class" }
                      | {
                          __typename: "ST4Node"
                          id: string
                          label?: string | null
                          aspectId: any
                          content?:
                            | { __typename: "LexiconContent" }
                            | { __typename: "LexiconEntryContent" }
                            | { __typename: "MediaContent" }
                            | { __typename: "MediaGroupContent" }
                            | { __typename: "ProjectContent" }
                            | { __typename: "TermContent" }
                            | { __typename: "TextContent" }
                            | { __typename: "TextGroupContent" }
                            | {
                                __typename: "VariableTableContent"
                                xml?: string | null
                                valueSets: Array<{ __typename: "ValueSet"; name: string; valueSetId: string }>
                                variables: Array<{
                                  __typename: "Variable"
                                  name: string
                                  variableId: string
                                  values: Array<string>
                                }>
                              }
                            | null
                        }
                      | { __typename: "ST4TreeNode" }
                      | null
                  } | null> | null
                  linkedDataNodes: Array<{
                    __typename: "NodeRef"
                    id: string
                    target?:
                      | { __typename: "CommentNode"; id: string }
                      | { __typename: "ExternalSite"; id: string }
                      | { __typename: "ST4Class"; id: string }
                      | {
                          __typename: "ST4Node"
                          label?: string | null
                          id: string
                          metadata: Array<{
                            __typename: "MetaDataEntry"
                            label?: string | null
                            systemName: string
                            value?: string | null
                            values: Array<string>
                            users: Array<string>
                          }>
                          linkedOntologyNodes: Array<
                            | {
                                __typename: "GenericRef"
                                target?:
                                  | { __typename: "CommentNode" }
                                  | { __typename: "ExternalSite" }
                                  | { __typename: "ST4Class" }
                                  | { __typename: "ST4Node"; id: string; label?: string | null }
                                  | { __typename: "ST4TreeNode" }
                                  | null
                              }
                            | {
                                __typename: "NodeRef"
                                linkClassName: string
                                class?: { __typename: "ST4Class"; label?: string | null } | null
                                target?:
                                  | { __typename: "CommentNode" }
                                  | { __typename: "ExternalSite" }
                                  | { __typename: "ST4Class" }
                                  | { __typename: "ST4Node"; id: string; label?: string | null }
                                  | { __typename: "ST4TreeNode" }
                                  | null
                              }
                          >
                        }
                      | { __typename: "ST4TreeNode"; id: string }
                      | null
                  }>
                  lexiconEntries?: Array<{
                    __typename: "NodeRef"
                    id: string
                    target?:
                      | { __typename: "CommentNode"; id: string }
                      | { __typename: "ExternalSite"; id: string }
                      | { __typename: "ST4Class"; id: string }
                      | {
                          __typename: "ST4Node"
                          aspectId: any
                          label?: string | null
                          id: string
                          content?:
                            | { __typename: "LexiconContent" }
                            | {
                                __typename: "LexiconEntryContent"
                                descriptionXast?: string | null
                                subject?: string | null
                                sortKey?: string | null
                                lexicon:
                                  | { __typename: "CommentNode"; id: string }
                                  | { __typename: "ExternalSite"; id: string }
                                  | { __typename: "ST4Class"; id: string }
                                  | { __typename: "ST4Node"; label?: string | null; id: string }
                                  | { __typename: "ST4TreeNode"; id: string }
                                terms: Array<
                                  | { __typename: "CommentNode"; id: string }
                                  | { __typename: "ExternalSite"; id: string }
                                  | { __typename: "ST4Class"; id: string }
                                  | {
                                      __typename: "ST4Node"
                                      id: string
                                      label?: string | null
                                      aspectId: any
                                      content?:
                                        | { __typename: "LexiconContent" }
                                        | { __typename: "LexiconEntryContent" }
                                        | { __typename: "MediaContent" }
                                        | { __typename: "MediaGroupContent" }
                                        | { __typename: "ProjectContent" }
                                        | {
                                            __typename: "TermContent"
                                            gramaticalGender?: string | null
                                            validity?: string | null
                                            editLanguage?: string | null
                                          }
                                        | { __typename: "TextContent" }
                                        | { __typename: "TextGroupContent" }
                                        | { __typename: "VariableTableContent" }
                                        | null
                                    }
                                  | { __typename: "ST4TreeNode"; id: string }
                                >
                                image?:
                                  | { __typename: "CommentNode"; id: string }
                                  | { __typename: "ExternalSite"; id: string }
                                  | { __typename: "ST4Class"; id: string }
                                  | {
                                      __typename: "ST4Node"
                                      aspectId: any
                                      id: string
                                      content?:
                                        | { __typename: "LexiconContent" }
                                        | { __typename: "LexiconEntryContent" }
                                        | {
                                            __typename: "MediaContent"
                                            media?:
                                              | {
                                                  __typename: "AnimationMediaInfo"
                                                  thumbnail?: string | null
                                                  mediaType?: Types.MediaType | null
                                                }
                                              | {
                                                  __typename: "ImageMediaInfo"
                                                  src?: string | null
                                                  thumbnail?: string | null
                                                  mediaType?: Types.MediaType | null
                                                }
                                              | { __typename: "NoMediaInfo" }
                                              | {
                                                  __typename: "VectorGraphicsMediaInfo"
                                                  svg?: string | null
                                                  thumbnail?: string | null
                                                  mediaType?: Types.MediaType | null
                                                }
                                              | {
                                                  __typename: "VideoMediaInfo"
                                                  thumbnail?: string | null
                                                  mediaType?: Types.MediaType | null
                                                }
                                              | null
                                          }
                                        | { __typename: "MediaGroupContent" }
                                        | { __typename: "ProjectContent" }
                                        | { __typename: "TermContent" }
                                        | { __typename: "TextContent" }
                                        | { __typename: "TextGroupContent" }
                                        | { __typename: "VariableTableContent" }
                                        | null
                                    }
                                  | { __typename: "ST4TreeNode"; id: string }
                                  | null
                              }
                            | { __typename: "MediaContent" }
                            | { __typename: "MediaGroupContent" }
                            | { __typename: "ProjectContent" }
                            | { __typename: "TermContent" }
                            | { __typename: "TextContent" }
                            | { __typename: "TextGroupContent" }
                            | { __typename: "VariableTableContent" }
                            | null
                        }
                      | { __typename: "ST4TreeNode"; id: string }
                      | null
                  } | null> | null
                  comments: Array<{
                    __typename: "CommentNode"
                    id: string
                    comment:
                      | {
                          __typename: "BlockComment"
                          location: number
                          value?: string | null
                          authorDisplayName?: string | null
                          authorUserName?: string | null
                          commentKey: string
                          editDate?: any | null
                          modificationVersion?: number | null
                          replyToKey?: string | null
                          sortOrder?: number | null
                          commentType?: string | null
                          state?: string | null
                          color: string
                          persistanceState?: Types.PersistanceState | null
                          persistanceAction?: Types.PersistanceAction | null
                          node: {
                            __typename: "ST4Node"
                            aspectId: any
                            id: string
                            content?:
                              | { __typename: "LexiconContent" }
                              | { __typename: "LexiconEntryContent" }
                              | { __typename: "MediaContent" }
                              | { __typename: "MediaGroupContent" }
                              | { __typename: "ProjectContent" }
                              | { __typename: "TermContent" }
                              | { __typename: "TextContent"; id: string; historyNumber: number }
                              | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                              | { __typename: "VariableTableContent" }
                              | null
                          }
                          actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
                        }
                      | {
                          __typename: "TextComment"
                          endLocation: number
                          startLocation?: number | null
                          value?: string | null
                          authorDisplayName?: string | null
                          authorUserName?: string | null
                          commentKey: string
                          editDate?: any | null
                          modificationVersion?: number | null
                          replyToKey?: string | null
                          sortOrder?: number | null
                          commentType?: string | null
                          state?: string | null
                          color: string
                          persistanceState?: Types.PersistanceState | null
                          persistanceAction?: Types.PersistanceAction | null
                          node: {
                            __typename: "ST4Node"
                            aspectId: any
                            id: string
                            content?:
                              | { __typename: "LexiconContent" }
                              | { __typename: "LexiconEntryContent" }
                              | { __typename: "MediaContent" }
                              | { __typename: "MediaGroupContent" }
                              | { __typename: "ProjectContent" }
                              | { __typename: "TermContent" }
                              | { __typename: "TextContent"; id: string; historyNumber: number }
                              | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                              | { __typename: "VariableTableContent" }
                              | null
                          }
                          actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
                        }
                  }>
                }
              | { __typename: "VariableTableContent" }
              | null
          }
        }>
        links?: Array<{
          __typename: "TextContentLink"
          ref?: string | null
          node?:
            | { __typename: "CommentNode"; id: string }
            | { __typename: "ExternalSite"; id: string }
            | { __typename: "ST4Class"; id: string }
            | {
                __typename: "ST4Node"
                id: string
                aspectId: any
                label?: string | null
                properties?: {
                  __typename: "ContentPropertyConnection"
                  totalCount: number
                  edges?: Array<{
                    __typename: "ContentPropertyEdge"
                    node?: {
                      __typename: "ContentProperty"
                      typeOfValue: Types.ContentPropertyValueType
                      name: string
                      value?: any | null
                      displayName: string
                    } | null
                  } | null> | null
                } | null
              }
            | { __typename: "ST4TreeNode"; id: string }
            | null
        } | null> | null
        images: Array<{
          __typename: "NodeRef"
          id: string
          target?:
            | { __typename: "CommentNode"; id: string }
            | { __typename: "ExternalSite"; id: string }
            | { __typename: "ST4Class"; id: string }
            | {
                __typename: "ST4Node"
                aspectId: any
                id: string
                releaseState: {
                  __typename: "ReleaseStateValue"
                  displayName: string
                  state: Types.ReleaseState
                  isImplicitlyReleased: boolean
                }
                content?:
                  | { __typename: "LexiconContent" }
                  | { __typename: "LexiconEntryContent" }
                  | {
                      __typename: "MediaContent"
                      media?:
                        | {
                            __typename: "AnimationMediaInfo"
                            id: string
                            resourceId?: string | null
                            thumbnail?: string | null
                            width?: number | null
                            height?: number | null
                            mediaType?: Types.MediaType | null
                          }
                        | {
                            __typename: "ImageMediaInfo"
                            id: string
                            resourceId?: string | null
                            thumbnail?: string | null
                            width?: number | null
                            height?: number | null
                            resolution?: number | null
                            colorDepth?: number | null
                            size?: number | null
                            mime?: string | null
                            extension?: string | null
                            allowCaching?: boolean | null
                            mediaType?: Types.MediaType | null
                            src?: string | null
                            language?: string | null
                          }
                        | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                        | {
                            __typename: "VectorGraphicsMediaInfo"
                            id: string
                            resourceId?: string | null
                            thumbnail?: string | null
                            width?: number | null
                            height?: number | null
                            allowCaching?: boolean | null
                            mediaType?: Types.MediaType | null
                            language?: string | null
                            labels: Array<{
                              __typename: "VectorGraphicsLabel"
                              id?: string | null
                              sequence?: number | null
                              autoValue?: string | null
                              text?: string | null
                              linkType?: Types.VectorGraphicsLabelLinkType | null
                              link?: string | null
                              ref?:
                                | {
                                    __typename: "GenericRef"
                                    id: string
                                    owner?:
                                      | { __typename: "CommentNode" }
                                      | { __typename: "ExternalSite" }
                                      | { __typename: "ST4Class" }
                                      | { __typename: "ST4Node"; id: string }
                                      | { __typename: "ST4TreeNode" }
                                      | null
                                    target?:
                                      | { __typename: "CommentNode" }
                                      | {
                                          __typename: "ExternalSite"
                                          id: string
                                          teaserData?: {
                                            __typename: "ExternalLinkTeaserData"
                                            title?: string | null
                                            summary?: string | null
                                            iconUrl?: string | null
                                            imageUrl?: string | null
                                            status?: Types.LinkTargetStatus | null
                                          } | null
                                        }
                                      | { __typename: "ST4Class" }
                                      | {
                                          __typename: "ST4Node"
                                          id: string
                                          aspectId: any
                                          label?: string | null
                                          properties?: {
                                            __typename: "ContentPropertyConnection"
                                            edges?: Array<{
                                              __typename: "ContentPropertyEdge"
                                              node?: {
                                                __typename: "ContentProperty"
                                                name: string
                                                value?: any | null
                                                displayName: string
                                              } | null
                                            } | null> | null
                                          } | null
                                        }
                                      | { __typename: "ST4TreeNode" }
                                      | null
                                  }
                                | {
                                    __typename: "NodeRef"
                                    id: string
                                    owner?:
                                      | { __typename: "CommentNode" }
                                      | { __typename: "ExternalSite" }
                                      | { __typename: "ST4Class" }
                                      | { __typename: "ST4Node"; id: string }
                                      | { __typename: "ST4TreeNode" }
                                      | null
                                    target?:
                                      | { __typename: "CommentNode" }
                                      | {
                                          __typename: "ExternalSite"
                                          id: string
                                          teaserData?: {
                                            __typename: "ExternalLinkTeaserData"
                                            title?: string | null
                                            summary?: string | null
                                            iconUrl?: string | null
                                            imageUrl?: string | null
                                            status?: Types.LinkTargetStatus | null
                                          } | null
                                        }
                                      | { __typename: "ST4Class" }
                                      | {
                                          __typename: "ST4Node"
                                          id: string
                                          aspectId: any
                                          label?: string | null
                                          properties?: {
                                            __typename: "ContentPropertyConnection"
                                            edges?: Array<{
                                              __typename: "ContentPropertyEdge"
                                              node?: {
                                                __typename: "ContentProperty"
                                                name: string
                                                value?: any | null
                                                displayName: string
                                              } | null
                                            } | null> | null
                                          } | null
                                        }
                                      | { __typename: "ST4TreeNode" }
                                      | null
                                  }
                                | null
                            }>
                          }
                        | {
                            __typename: "VideoMediaInfo"
                            id: string
                            resourceId?: string | null
                            thumbnail?: string | null
                            width?: number | null
                            height?: number | null
                            mediaType?: Types.MediaType | null
                          }
                        | null
                    }
                  | {
                      __typename: "MediaGroupContent"
                      children: Array<{
                        __typename: "NodeRef"
                        id: string
                        target?:
                          | { __typename: "CommentNode"; id: string }
                          | { __typename: "ExternalSite"; id: string }
                          | { __typename: "ST4Class"; id: string }
                          | {
                              __typename: "ST4Node"
                              id: string
                              aspectId: any
                              content?:
                                | { __typename: "LexiconContent" }
                                | { __typename: "LexiconEntryContent" }
                                | {
                                    __typename: "MediaContent"
                                    media?:
                                      | {
                                          __typename: "AnimationMediaInfo"
                                          id: string
                                          resourceId?: string | null
                                          thumbnail?: string | null
                                          width?: number | null
                                          height?: number | null
                                          mediaType?: Types.MediaType | null
                                        }
                                      | {
                                          __typename: "ImageMediaInfo"
                                          id: string
                                          resourceId?: string | null
                                          thumbnail?: string | null
                                          width?: number | null
                                          height?: number | null
                                          resolution?: number | null
                                          colorDepth?: number | null
                                          size?: number | null
                                          mime?: string | null
                                          extension?: string | null
                                          allowCaching?: boolean | null
                                          mediaType?: Types.MediaType | null
                                          src?: string | null
                                          language?: string | null
                                        }
                                      | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                                      | {
                                          __typename: "VectorGraphicsMediaInfo"
                                          id: string
                                          resourceId?: string | null
                                          thumbnail?: string | null
                                          width?: number | null
                                          height?: number | null
                                          allowCaching?: boolean | null
                                          mediaType?: Types.MediaType | null
                                          language?: string | null
                                          labels: Array<{
                                            __typename: "VectorGraphicsLabel"
                                            id?: string | null
                                            sequence?: number | null
                                            autoValue?: string | null
                                            text?: string | null
                                            linkType?: Types.VectorGraphicsLabelLinkType | null
                                            link?: string | null
                                            ref?:
                                              | {
                                                  __typename: "GenericRef"
                                                  id: string
                                                  owner?:
                                                    | { __typename: "CommentNode" }
                                                    | { __typename: "ExternalSite" }
                                                    | { __typename: "ST4Class" }
                                                    | { __typename: "ST4Node"; id: string }
                                                    | { __typename: "ST4TreeNode" }
                                                    | null
                                                  target?:
                                                    | { __typename: "CommentNode" }
                                                    | {
                                                        __typename: "ExternalSite"
                                                        id: string
                                                        teaserData?: {
                                                          __typename: "ExternalLinkTeaserData"
                                                          title?: string | null
                                                          summary?: string | null
                                                          iconUrl?: string | null
                                                          imageUrl?: string | null
                                                          status?: Types.LinkTargetStatus | null
                                                        } | null
                                                      }
                                                    | { __typename: "ST4Class" }
                                                    | {
                                                        __typename: "ST4Node"
                                                        id: string
                                                        aspectId: any
                                                        label?: string | null
                                                        properties?: {
                                                          __typename: "ContentPropertyConnection"
                                                          edges?: Array<{
                                                            __typename: "ContentPropertyEdge"
                                                            node?: {
                                                              __typename: "ContentProperty"
                                                              name: string
                                                              value?: any | null
                                                              displayName: string
                                                            } | null
                                                          } | null> | null
                                                        } | null
                                                      }
                                                    | { __typename: "ST4TreeNode" }
                                                    | null
                                                }
                                              | {
                                                  __typename: "NodeRef"
                                                  id: string
                                                  owner?:
                                                    | { __typename: "CommentNode" }
                                                    | { __typename: "ExternalSite" }
                                                    | { __typename: "ST4Class" }
                                                    | { __typename: "ST4Node"; id: string }
                                                    | { __typename: "ST4TreeNode" }
                                                    | null
                                                  target?:
                                                    | { __typename: "CommentNode" }
                                                    | {
                                                        __typename: "ExternalSite"
                                                        id: string
                                                        teaserData?: {
                                                          __typename: "ExternalLinkTeaserData"
                                                          title?: string | null
                                                          summary?: string | null
                                                          iconUrl?: string | null
                                                          imageUrl?: string | null
                                                          status?: Types.LinkTargetStatus | null
                                                        } | null
                                                      }
                                                    | { __typename: "ST4Class" }
                                                    | {
                                                        __typename: "ST4Node"
                                                        id: string
                                                        aspectId: any
                                                        label?: string | null
                                                        properties?: {
                                                          __typename: "ContentPropertyConnection"
                                                          edges?: Array<{
                                                            __typename: "ContentPropertyEdge"
                                                            node?: {
                                                              __typename: "ContentProperty"
                                                              name: string
                                                              value?: any | null
                                                              displayName: string
                                                            } | null
                                                          } | null> | null
                                                        } | null
                                                      }
                                                    | { __typename: "ST4TreeNode" }
                                                    | null
                                                }
                                              | null
                                          }>
                                        }
                                      | {
                                          __typename: "VideoMediaInfo"
                                          id: string
                                          resourceId?: string | null
                                          thumbnail?: string | null
                                          width?: number | null
                                          height?: number | null
                                          mediaType?: Types.MediaType | null
                                        }
                                      | null
                                  }
                                | {
                                    __typename: "MediaGroupContent"
                                    media?:
                                      | {
                                          __typename: "AnimationMediaInfo"
                                          id: string
                                          resourceId?: string | null
                                          thumbnail?: string | null
                                          width?: number | null
                                          height?: number | null
                                          mediaType?: Types.MediaType | null
                                        }
                                      | {
                                          __typename: "ImageMediaInfo"
                                          id: string
                                          resourceId?: string | null
                                          thumbnail?: string | null
                                          width?: number | null
                                          height?: number | null
                                          resolution?: number | null
                                          colorDepth?: number | null
                                          size?: number | null
                                          mime?: string | null
                                          extension?: string | null
                                          allowCaching?: boolean | null
                                          mediaType?: Types.MediaType | null
                                          src?: string | null
                                          language?: string | null
                                        }
                                      | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                                      | {
                                          __typename: "VectorGraphicsMediaInfo"
                                          id: string
                                          resourceId?: string | null
                                          thumbnail?: string | null
                                          width?: number | null
                                          height?: number | null
                                          allowCaching?: boolean | null
                                          mediaType?: Types.MediaType | null
                                          language?: string | null
                                          labels: Array<{
                                            __typename: "VectorGraphicsLabel"
                                            id?: string | null
                                            sequence?: number | null
                                            autoValue?: string | null
                                            text?: string | null
                                            linkType?: Types.VectorGraphicsLabelLinkType | null
                                            link?: string | null
                                            ref?:
                                              | {
                                                  __typename: "GenericRef"
                                                  id: string
                                                  owner?:
                                                    | { __typename: "CommentNode" }
                                                    | { __typename: "ExternalSite" }
                                                    | { __typename: "ST4Class" }
                                                    | { __typename: "ST4Node"; id: string }
                                                    | { __typename: "ST4TreeNode" }
                                                    | null
                                                  target?:
                                                    | { __typename: "CommentNode" }
                                                    | {
                                                        __typename: "ExternalSite"
                                                        id: string
                                                        teaserData?: {
                                                          __typename: "ExternalLinkTeaserData"
                                                          title?: string | null
                                                          summary?: string | null
                                                          iconUrl?: string | null
                                                          imageUrl?: string | null
                                                          status?: Types.LinkTargetStatus | null
                                                        } | null
                                                      }
                                                    | { __typename: "ST4Class" }
                                                    | {
                                                        __typename: "ST4Node"
                                                        id: string
                                                        aspectId: any
                                                        label?: string | null
                                                        properties?: {
                                                          __typename: "ContentPropertyConnection"
                                                          edges?: Array<{
                                                            __typename: "ContentPropertyEdge"
                                                            node?: {
                                                              __typename: "ContentProperty"
                                                              name: string
                                                              value?: any | null
                                                              displayName: string
                                                            } | null
                                                          } | null> | null
                                                        } | null
                                                      }
                                                    | { __typename: "ST4TreeNode" }
                                                    | null
                                                }
                                              | {
                                                  __typename: "NodeRef"
                                                  id: string
                                                  owner?:
                                                    | { __typename: "CommentNode" }
                                                    | { __typename: "ExternalSite" }
                                                    | { __typename: "ST4Class" }
                                                    | { __typename: "ST4Node"; id: string }
                                                    | { __typename: "ST4TreeNode" }
                                                    | null
                                                  target?:
                                                    | { __typename: "CommentNode" }
                                                    | {
                                                        __typename: "ExternalSite"
                                                        id: string
                                                        teaserData?: {
                                                          __typename: "ExternalLinkTeaserData"
                                                          title?: string | null
                                                          summary?: string | null
                                                          iconUrl?: string | null
                                                          imageUrl?: string | null
                                                          status?: Types.LinkTargetStatus | null
                                                        } | null
                                                      }
                                                    | { __typename: "ST4Class" }
                                                    | {
                                                        __typename: "ST4Node"
                                                        id: string
                                                        aspectId: any
                                                        label?: string | null
                                                        properties?: {
                                                          __typename: "ContentPropertyConnection"
                                                          edges?: Array<{
                                                            __typename: "ContentPropertyEdge"
                                                            node?: {
                                                              __typename: "ContentProperty"
                                                              name: string
                                                              value?: any | null
                                                              displayName: string
                                                            } | null
                                                          } | null> | null
                                                        } | null
                                                      }
                                                    | { __typename: "ST4TreeNode" }
                                                    | null
                                                }
                                              | null
                                          }>
                                        }
                                      | {
                                          __typename: "VideoMediaInfo"
                                          id: string
                                          resourceId?: string | null
                                          thumbnail?: string | null
                                          width?: number | null
                                          height?: number | null
                                          mediaType?: Types.MediaType | null
                                        }
                                      | null
                                  }
                                | { __typename: "ProjectContent" }
                                | { __typename: "TermContent" }
                                | { __typename: "TextContent" }
                                | { __typename: "TextGroupContent" }
                                | { __typename: "VariableTableContent" }
                                | null
                            }
                          | { __typename: "ST4TreeNode"; id: string }
                          | null
                      }>
                      media?:
                        | {
                            __typename: "AnimationMediaInfo"
                            id: string
                            resourceId?: string | null
                            thumbnail?: string | null
                            width?: number | null
                            height?: number | null
                            mediaType?: Types.MediaType | null
                          }
                        | {
                            __typename: "ImageMediaInfo"
                            id: string
                            resourceId?: string | null
                            thumbnail?: string | null
                            width?: number | null
                            height?: number | null
                            resolution?: number | null
                            colorDepth?: number | null
                            size?: number | null
                            mime?: string | null
                            extension?: string | null
                            allowCaching?: boolean | null
                            mediaType?: Types.MediaType | null
                            src?: string | null
                            language?: string | null
                          }
                        | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                        | {
                            __typename: "VectorGraphicsMediaInfo"
                            id: string
                            resourceId?: string | null
                            thumbnail?: string | null
                            width?: number | null
                            height?: number | null
                            allowCaching?: boolean | null
                            mediaType?: Types.MediaType | null
                            language?: string | null
                            labels: Array<{
                              __typename: "VectorGraphicsLabel"
                              id?: string | null
                              sequence?: number | null
                              autoValue?: string | null
                              text?: string | null
                              linkType?: Types.VectorGraphicsLabelLinkType | null
                              link?: string | null
                              ref?:
                                | {
                                    __typename: "GenericRef"
                                    id: string
                                    owner?:
                                      | { __typename: "CommentNode" }
                                      | { __typename: "ExternalSite" }
                                      | { __typename: "ST4Class" }
                                      | { __typename: "ST4Node"; id: string }
                                      | { __typename: "ST4TreeNode" }
                                      | null
                                    target?:
                                      | { __typename: "CommentNode" }
                                      | {
                                          __typename: "ExternalSite"
                                          id: string
                                          teaserData?: {
                                            __typename: "ExternalLinkTeaserData"
                                            title?: string | null
                                            summary?: string | null
                                            iconUrl?: string | null
                                            imageUrl?: string | null
                                            status?: Types.LinkTargetStatus | null
                                          } | null
                                        }
                                      | { __typename: "ST4Class" }
                                      | {
                                          __typename: "ST4Node"
                                          id: string
                                          aspectId: any
                                          label?: string | null
                                          properties?: {
                                            __typename: "ContentPropertyConnection"
                                            edges?: Array<{
                                              __typename: "ContentPropertyEdge"
                                              node?: {
                                                __typename: "ContentProperty"
                                                name: string
                                                value?: any | null
                                                displayName: string
                                              } | null
                                            } | null> | null
                                          } | null
                                        }
                                      | { __typename: "ST4TreeNode" }
                                      | null
                                  }
                                | {
                                    __typename: "NodeRef"
                                    id: string
                                    owner?:
                                      | { __typename: "CommentNode" }
                                      | { __typename: "ExternalSite" }
                                      | { __typename: "ST4Class" }
                                      | { __typename: "ST4Node"; id: string }
                                      | { __typename: "ST4TreeNode" }
                                      | null
                                    target?:
                                      | { __typename: "CommentNode" }
                                      | {
                                          __typename: "ExternalSite"
                                          id: string
                                          teaserData?: {
                                            __typename: "ExternalLinkTeaserData"
                                            title?: string | null
                                            summary?: string | null
                                            iconUrl?: string | null
                                            imageUrl?: string | null
                                            status?: Types.LinkTargetStatus | null
                                          } | null
                                        }
                                      | { __typename: "ST4Class" }
                                      | {
                                          __typename: "ST4Node"
                                          id: string
                                          aspectId: any
                                          label?: string | null
                                          properties?: {
                                            __typename: "ContentPropertyConnection"
                                            edges?: Array<{
                                              __typename: "ContentPropertyEdge"
                                              node?: {
                                                __typename: "ContentProperty"
                                                name: string
                                                value?: any | null
                                                displayName: string
                                              } | null
                                            } | null> | null
                                          } | null
                                        }
                                      | { __typename: "ST4TreeNode" }
                                      | null
                                  }
                                | null
                            }>
                          }
                        | {
                            __typename: "VideoMediaInfo"
                            id: string
                            resourceId?: string | null
                            thumbnail?: string | null
                            width?: number | null
                            height?: number | null
                            mediaType?: Types.MediaType | null
                          }
                        | null
                    }
                  | { __typename: "ProjectContent" }
                  | { __typename: "TermContent" }
                  | { __typename: "TextContent" }
                  | { __typename: "TextGroupContent" }
                  | { __typename: "VariableTableContent" }
                  | null
              }
            | { __typename: "ST4TreeNode"; id: string }
            | null
        }>
        variableTables?: Array<{
          __typename: "VariableTableReference"
          ref?: string | null
          node?:
            | { __typename: "CommentNode" }
            | { __typename: "ExternalSite" }
            | { __typename: "ST4Class" }
            | {
                __typename: "ST4Node"
                id: string
                label?: string | null
                aspectId: any
                content?:
                  | { __typename: "LexiconContent" }
                  | { __typename: "LexiconEntryContent" }
                  | { __typename: "MediaContent" }
                  | { __typename: "MediaGroupContent" }
                  | { __typename: "ProjectContent" }
                  | { __typename: "TermContent" }
                  | { __typename: "TextContent" }
                  | { __typename: "TextGroupContent" }
                  | {
                      __typename: "VariableTableContent"
                      xml?: string | null
                      valueSets: Array<{ __typename: "ValueSet"; name: string; valueSetId: string }>
                      variables: Array<{
                        __typename: "Variable"
                        name: string
                        variableId: string
                        values: Array<string>
                      }>
                    }
                  | null
              }
            | { __typename: "ST4TreeNode" }
            | null
        } | null> | null
        linkedDataNodes: Array<{
          __typename: "NodeRef"
          id: string
          target?:
            | { __typename: "CommentNode"; id: string }
            | { __typename: "ExternalSite"; id: string }
            | { __typename: "ST4Class"; id: string }
            | {
                __typename: "ST4Node"
                label?: string | null
                id: string
                metadata: Array<{
                  __typename: "MetaDataEntry"
                  label?: string | null
                  systemName: string
                  value?: string | null
                  values: Array<string>
                  users: Array<string>
                }>
                linkedOntologyNodes: Array<
                  | {
                      __typename: "GenericRef"
                      target?:
                        | { __typename: "CommentNode" }
                        | { __typename: "ExternalSite" }
                        | { __typename: "ST4Class" }
                        | { __typename: "ST4Node"; id: string; label?: string | null }
                        | { __typename: "ST4TreeNode" }
                        | null
                    }
                  | {
                      __typename: "NodeRef"
                      linkClassName: string
                      class?: { __typename: "ST4Class"; label?: string | null } | null
                      target?:
                        | { __typename: "CommentNode" }
                        | { __typename: "ExternalSite" }
                        | { __typename: "ST4Class" }
                        | { __typename: "ST4Node"; id: string; label?: string | null }
                        | { __typename: "ST4TreeNode" }
                        | null
                    }
                >
              }
            | { __typename: "ST4TreeNode"; id: string }
            | null
        }>
        lexiconEntries?: Array<{
          __typename: "NodeRef"
          id: string
          target?:
            | { __typename: "CommentNode"; id: string }
            | { __typename: "ExternalSite"; id: string }
            | { __typename: "ST4Class"; id: string }
            | {
                __typename: "ST4Node"
                aspectId: any
                label?: string | null
                id: string
                content?:
                  | { __typename: "LexiconContent" }
                  | {
                      __typename: "LexiconEntryContent"
                      descriptionXast?: string | null
                      subject?: string | null
                      sortKey?: string | null
                      lexicon:
                        | { __typename: "CommentNode"; id: string }
                        | { __typename: "ExternalSite"; id: string }
                        | { __typename: "ST4Class"; id: string }
                        | { __typename: "ST4Node"; label?: string | null; id: string }
                        | { __typename: "ST4TreeNode"; id: string }
                      terms: Array<
                        | { __typename: "CommentNode"; id: string }
                        | { __typename: "ExternalSite"; id: string }
                        | { __typename: "ST4Class"; id: string }
                        | {
                            __typename: "ST4Node"
                            id: string
                            label?: string | null
                            aspectId: any
                            content?:
                              | { __typename: "LexiconContent" }
                              | { __typename: "LexiconEntryContent" }
                              | { __typename: "MediaContent" }
                              | { __typename: "MediaGroupContent" }
                              | { __typename: "ProjectContent" }
                              | {
                                  __typename: "TermContent"
                                  gramaticalGender?: string | null
                                  validity?: string | null
                                  editLanguage?: string | null
                                }
                              | { __typename: "TextContent" }
                              | { __typename: "TextGroupContent" }
                              | { __typename: "VariableTableContent" }
                              | null
                          }
                        | { __typename: "ST4TreeNode"; id: string }
                      >
                      image?:
                        | { __typename: "CommentNode"; id: string }
                        | { __typename: "ExternalSite"; id: string }
                        | { __typename: "ST4Class"; id: string }
                        | {
                            __typename: "ST4Node"
                            aspectId: any
                            id: string
                            content?:
                              | { __typename: "LexiconContent" }
                              | { __typename: "LexiconEntryContent" }
                              | {
                                  __typename: "MediaContent"
                                  media?:
                                    | {
                                        __typename: "AnimationMediaInfo"
                                        thumbnail?: string | null
                                        mediaType?: Types.MediaType | null
                                      }
                                    | {
                                        __typename: "ImageMediaInfo"
                                        src?: string | null
                                        thumbnail?: string | null
                                        mediaType?: Types.MediaType | null
                                      }
                                    | { __typename: "NoMediaInfo" }
                                    | {
                                        __typename: "VectorGraphicsMediaInfo"
                                        svg?: string | null
                                        thumbnail?: string | null
                                        mediaType?: Types.MediaType | null
                                      }
                                    | {
                                        __typename: "VideoMediaInfo"
                                        thumbnail?: string | null
                                        mediaType?: Types.MediaType | null
                                      }
                                    | null
                                }
                              | { __typename: "MediaGroupContent" }
                              | { __typename: "ProjectContent" }
                              | { __typename: "TermContent" }
                              | { __typename: "TextContent" }
                              | { __typename: "TextGroupContent" }
                              | { __typename: "VariableTableContent" }
                              | null
                          }
                        | { __typename: "ST4TreeNode"; id: string }
                        | null
                    }
                  | { __typename: "MediaContent" }
                  | { __typename: "MediaGroupContent" }
                  | { __typename: "ProjectContent" }
                  | { __typename: "TermContent" }
                  | { __typename: "TextContent" }
                  | { __typename: "TextGroupContent" }
                  | { __typename: "VariableTableContent" }
                  | null
              }
            | { __typename: "ST4TreeNode"; id: string }
            | null
        } | null> | null
        comments: Array<{
          __typename: "CommentNode"
          id: string
          comment:
            | {
                __typename: "BlockComment"
                location: number
                value?: string | null
                authorDisplayName?: string | null
                authorUserName?: string | null
                commentKey: string
                editDate?: any | null
                modificationVersion?: number | null
                replyToKey?: string | null
                sortOrder?: number | null
                commentType?: string | null
                state?: string | null
                color: string
                persistanceState?: Types.PersistanceState | null
                persistanceAction?: Types.PersistanceAction | null
                node: {
                  __typename: "ST4Node"
                  aspectId: any
                  id: string
                  content?:
                    | { __typename: "LexiconContent" }
                    | { __typename: "LexiconEntryContent" }
                    | { __typename: "MediaContent" }
                    | { __typename: "MediaGroupContent" }
                    | { __typename: "ProjectContent" }
                    | { __typename: "TermContent" }
                    | { __typename: "TextContent"; id: string; historyNumber: number }
                    | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                    | { __typename: "VariableTableContent" }
                    | null
                }
                actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
              }
            | {
                __typename: "TextComment"
                endLocation: number
                startLocation?: number | null
                value?: string | null
                authorDisplayName?: string | null
                authorUserName?: string | null
                commentKey: string
                editDate?: any | null
                modificationVersion?: number | null
                replyToKey?: string | null
                sortOrder?: number | null
                commentType?: string | null
                state?: string | null
                color: string
                persistanceState?: Types.PersistanceState | null
                persistanceAction?: Types.PersistanceAction | null
                node: {
                  __typename: "ST4Node"
                  aspectId: any
                  id: string
                  content?:
                    | { __typename: "LexiconContent" }
                    | { __typename: "LexiconEntryContent" }
                    | { __typename: "MediaContent" }
                    | { __typename: "MediaGroupContent" }
                    | { __typename: "ProjectContent" }
                    | { __typename: "TermContent" }
                    | { __typename: "TextContent"; id: string; historyNumber: number }
                    | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                    | { __typename: "VariableTableContent" }
                    | null
                }
                actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
              }
        }>
      }
    | {
        __typename: "TextGroupContent"
        currentNodeId?: any | null
        groupNodeId: any
        id: string
        xast?: string | null
        historyNumber: number
        fragments: Array<{
          __typename: "TextContentFragment"
          compoundId: string
          contentNode: {
            __typename: "ST4Node"
            label?: string | null
            id: string
            aspectId: any
            content?:
              | { __typename: "LexiconContent" }
              | { __typename: "LexiconEntryContent" }
              | { __typename: "MediaContent" }
              | { __typename: "MediaGroupContent" }
              | { __typename: "ProjectContent" }
              | { __typename: "TermContent" }
              | {
                  __typename: "TextContent"
                  id: string
                  xast?: string | null
                  historyNumber: number
                  parts: Array<{
                    __typename: "TextContentPart"
                    ref: string
                    contentNode: {
                      __typename: "ST4Node"
                      id: string
                      aspectId: any
                      content?:
                        | { __typename: "LexiconContent" }
                        | { __typename: "LexiconEntryContent" }
                        | { __typename: "MediaContent" }
                        | { __typename: "MediaGroupContent" }
                        | { __typename: "ProjectContent" }
                        | { __typename: "TermContent" }
                        | {
                            __typename: "TextContent"
                            id: string
                            xast?: string | null
                            historyNumber: number
                            links?: Array<{
                              __typename: "TextContentLink"
                              ref?: string | null
                              node?:
                                | { __typename: "CommentNode"; id: string }
                                | { __typename: "ExternalSite"; id: string }
                                | { __typename: "ST4Class"; id: string }
                                | {
                                    __typename: "ST4Node"
                                    id: string
                                    aspectId: any
                                    label?: string | null
                                    properties?: {
                                      __typename: "ContentPropertyConnection"
                                      totalCount: number
                                      edges?: Array<{
                                        __typename: "ContentPropertyEdge"
                                        node?: {
                                          __typename: "ContentProperty"
                                          typeOfValue: Types.ContentPropertyValueType
                                          name: string
                                          value?: any | null
                                          displayName: string
                                        } | null
                                      } | null> | null
                                    } | null
                                  }
                                | { __typename: "ST4TreeNode"; id: string }
                                | null
                            } | null> | null
                            images: Array<{
                              __typename: "NodeRef"
                              id: string
                              target?:
                                | { __typename: "CommentNode"; id: string }
                                | { __typename: "ExternalSite"; id: string }
                                | { __typename: "ST4Class"; id: string }
                                | {
                                    __typename: "ST4Node"
                                    aspectId: any
                                    id: string
                                    releaseState: {
                                      __typename: "ReleaseStateValue"
                                      displayName: string
                                      state: Types.ReleaseState
                                      isImplicitlyReleased: boolean
                                    }
                                    content?:
                                      | { __typename: "LexiconContent" }
                                      | { __typename: "LexiconEntryContent" }
                                      | {
                                          __typename: "MediaContent"
                                          media?:
                                            | {
                                                __typename: "AnimationMediaInfo"
                                                id: string
                                                resourceId?: string | null
                                                thumbnail?: string | null
                                                width?: number | null
                                                height?: number | null
                                                mediaType?: Types.MediaType | null
                                              }
                                            | {
                                                __typename: "ImageMediaInfo"
                                                id: string
                                                resourceId?: string | null
                                                thumbnail?: string | null
                                                width?: number | null
                                                height?: number | null
                                                resolution?: number | null
                                                colorDepth?: number | null
                                                size?: number | null
                                                mime?: string | null
                                                extension?: string | null
                                                allowCaching?: boolean | null
                                                mediaType?: Types.MediaType | null
                                                src?: string | null
                                                language?: string | null
                                              }
                                            | {
                                                __typename: "NoMediaInfo"
                                                id: string
                                                mediaType?: Types.MediaType | null
                                              }
                                            | {
                                                __typename: "VectorGraphicsMediaInfo"
                                                id: string
                                                resourceId?: string | null
                                                thumbnail?: string | null
                                                width?: number | null
                                                height?: number | null
                                                allowCaching?: boolean | null
                                                mediaType?: Types.MediaType | null
                                                language?: string | null
                                                labels: Array<{
                                                  __typename: "VectorGraphicsLabel"
                                                  id?: string | null
                                                  sequence?: number | null
                                                  autoValue?: string | null
                                                  text?: string | null
                                                  linkType?: Types.VectorGraphicsLabelLinkType | null
                                                  link?: string | null
                                                  ref?:
                                                    | {
                                                        __typename: "GenericRef"
                                                        id: string
                                                        owner?:
                                                          | { __typename: "CommentNode" }
                                                          | { __typename: "ExternalSite" }
                                                          | { __typename: "ST4Class" }
                                                          | { __typename: "ST4Node"; id: string }
                                                          | { __typename: "ST4TreeNode" }
                                                          | null
                                                        target?:
                                                          | { __typename: "CommentNode" }
                                                          | {
                                                              __typename: "ExternalSite"
                                                              id: string
                                                              teaserData?: {
                                                                __typename: "ExternalLinkTeaserData"
                                                                title?: string | null
                                                                summary?: string | null
                                                                iconUrl?: string | null
                                                                imageUrl?: string | null
                                                                status?: Types.LinkTargetStatus | null
                                                              } | null
                                                            }
                                                          | { __typename: "ST4Class" }
                                                          | {
                                                              __typename: "ST4Node"
                                                              id: string
                                                              aspectId: any
                                                              label?: string | null
                                                              properties?: {
                                                                __typename: "ContentPropertyConnection"
                                                                edges?: Array<{
                                                                  __typename: "ContentPropertyEdge"
                                                                  node?: {
                                                                    __typename: "ContentProperty"
                                                                    name: string
                                                                    value?: any | null
                                                                    displayName: string
                                                                  } | null
                                                                } | null> | null
                                                              } | null
                                                            }
                                                          | { __typename: "ST4TreeNode" }
                                                          | null
                                                      }
                                                    | {
                                                        __typename: "NodeRef"
                                                        id: string
                                                        owner?:
                                                          | { __typename: "CommentNode" }
                                                          | { __typename: "ExternalSite" }
                                                          | { __typename: "ST4Class" }
                                                          | { __typename: "ST4Node"; id: string }
                                                          | { __typename: "ST4TreeNode" }
                                                          | null
                                                        target?:
                                                          | { __typename: "CommentNode" }
                                                          | {
                                                              __typename: "ExternalSite"
                                                              id: string
                                                              teaserData?: {
                                                                __typename: "ExternalLinkTeaserData"
                                                                title?: string | null
                                                                summary?: string | null
                                                                iconUrl?: string | null
                                                                imageUrl?: string | null
                                                                status?: Types.LinkTargetStatus | null
                                                              } | null
                                                            }
                                                          | { __typename: "ST4Class" }
                                                          | {
                                                              __typename: "ST4Node"
                                                              id: string
                                                              aspectId: any
                                                              label?: string | null
                                                              properties?: {
                                                                __typename: "ContentPropertyConnection"
                                                                edges?: Array<{
                                                                  __typename: "ContentPropertyEdge"
                                                                  node?: {
                                                                    __typename: "ContentProperty"
                                                                    name: string
                                                                    value?: any | null
                                                                    displayName: string
                                                                  } | null
                                                                } | null> | null
                                                              } | null
                                                            }
                                                          | { __typename: "ST4TreeNode" }
                                                          | null
                                                      }
                                                    | null
                                                }>
                                              }
                                            | {
                                                __typename: "VideoMediaInfo"
                                                id: string
                                                resourceId?: string | null
                                                thumbnail?: string | null
                                                width?: number | null
                                                height?: number | null
                                                mediaType?: Types.MediaType | null
                                              }
                                            | null
                                        }
                                      | {
                                          __typename: "MediaGroupContent"
                                          children: Array<{
                                            __typename: "NodeRef"
                                            id: string
                                            target?:
                                              | { __typename: "CommentNode"; id: string }
                                              | { __typename: "ExternalSite"; id: string }
                                              | { __typename: "ST4Class"; id: string }
                                              | {
                                                  __typename: "ST4Node"
                                                  id: string
                                                  aspectId: any
                                                  content?:
                                                    | { __typename: "LexiconContent" }
                                                    | { __typename: "LexiconEntryContent" }
                                                    | {
                                                        __typename: "MediaContent"
                                                        media?:
                                                          | {
                                                              __typename: "AnimationMediaInfo"
                                                              id: string
                                                              resourceId?: string | null
                                                              thumbnail?: string | null
                                                              width?: number | null
                                                              height?: number | null
                                                              mediaType?: Types.MediaType | null
                                                            }
                                                          | {
                                                              __typename: "ImageMediaInfo"
                                                              id: string
                                                              resourceId?: string | null
                                                              thumbnail?: string | null
                                                              width?: number | null
                                                              height?: number | null
                                                              resolution?: number | null
                                                              colorDepth?: number | null
                                                              size?: number | null
                                                              mime?: string | null
                                                              extension?: string | null
                                                              allowCaching?: boolean | null
                                                              mediaType?: Types.MediaType | null
                                                              src?: string | null
                                                              language?: string | null
                                                            }
                                                          | {
                                                              __typename: "NoMediaInfo"
                                                              id: string
                                                              mediaType?: Types.MediaType | null
                                                            }
                                                          | {
                                                              __typename: "VectorGraphicsMediaInfo"
                                                              id: string
                                                              resourceId?: string | null
                                                              thumbnail?: string | null
                                                              width?: number | null
                                                              height?: number | null
                                                              allowCaching?: boolean | null
                                                              mediaType?: Types.MediaType | null
                                                              language?: string | null
                                                              labels: Array<{
                                                                __typename: "VectorGraphicsLabel"
                                                                id?: string | null
                                                                sequence?: number | null
                                                                autoValue?: string | null
                                                                text?: string | null
                                                                linkType?: Types.VectorGraphicsLabelLinkType | null
                                                                link?: string | null
                                                                ref?:
                                                                  | {
                                                                      __typename: "GenericRef"
                                                                      id: string
                                                                      owner?:
                                                                        | { __typename: "CommentNode" }
                                                                        | { __typename: "ExternalSite" }
                                                                        | { __typename: "ST4Class" }
                                                                        | { __typename: "ST4Node"; id: string }
                                                                        | { __typename: "ST4TreeNode" }
                                                                        | null
                                                                      target?:
                                                                        | { __typename: "CommentNode" }
                                                                        | {
                                                                            __typename: "ExternalSite"
                                                                            id: string
                                                                            teaserData?: {
                                                                              __typename: "ExternalLinkTeaserData"
                                                                              title?: string | null
                                                                              summary?: string | null
                                                                              iconUrl?: string | null
                                                                              imageUrl?: string | null
                                                                              status?: Types.LinkTargetStatus | null
                                                                            } | null
                                                                          }
                                                                        | { __typename: "ST4Class" }
                                                                        | {
                                                                            __typename: "ST4Node"
                                                                            id: string
                                                                            aspectId: any
                                                                            label?: string | null
                                                                            properties?: {
                                                                              __typename: "ContentPropertyConnection"
                                                                              edges?: Array<{
                                                                                __typename: "ContentPropertyEdge"
                                                                                node?: {
                                                                                  __typename: "ContentProperty"
                                                                                  name: string
                                                                                  value?: any | null
                                                                                  displayName: string
                                                                                } | null
                                                                              } | null> | null
                                                                            } | null
                                                                          }
                                                                        | { __typename: "ST4TreeNode" }
                                                                        | null
                                                                    }
                                                                  | {
                                                                      __typename: "NodeRef"
                                                                      id: string
                                                                      owner?:
                                                                        | { __typename: "CommentNode" }
                                                                        | { __typename: "ExternalSite" }
                                                                        | { __typename: "ST4Class" }
                                                                        | { __typename: "ST4Node"; id: string }
                                                                        | { __typename: "ST4TreeNode" }
                                                                        | null
                                                                      target?:
                                                                        | { __typename: "CommentNode" }
                                                                        | {
                                                                            __typename: "ExternalSite"
                                                                            id: string
                                                                            teaserData?: {
                                                                              __typename: "ExternalLinkTeaserData"
                                                                              title?: string | null
                                                                              summary?: string | null
                                                                              iconUrl?: string | null
                                                                              imageUrl?: string | null
                                                                              status?: Types.LinkTargetStatus | null
                                                                            } | null
                                                                          }
                                                                        | { __typename: "ST4Class" }
                                                                        | {
                                                                            __typename: "ST4Node"
                                                                            id: string
                                                                            aspectId: any
                                                                            label?: string | null
                                                                            properties?: {
                                                                              __typename: "ContentPropertyConnection"
                                                                              edges?: Array<{
                                                                                __typename: "ContentPropertyEdge"
                                                                                node?: {
                                                                                  __typename: "ContentProperty"
                                                                                  name: string
                                                                                  value?: any | null
                                                                                  displayName: string
                                                                                } | null
                                                                              } | null> | null
                                                                            } | null
                                                                          }
                                                                        | { __typename: "ST4TreeNode" }
                                                                        | null
                                                                    }
                                                                  | null
                                                              }>
                                                            }
                                                          | {
                                                              __typename: "VideoMediaInfo"
                                                              id: string
                                                              resourceId?: string | null
                                                              thumbnail?: string | null
                                                              width?: number | null
                                                              height?: number | null
                                                              mediaType?: Types.MediaType | null
                                                            }
                                                          | null
                                                      }
                                                    | {
                                                        __typename: "MediaGroupContent"
                                                        media?:
                                                          | {
                                                              __typename: "AnimationMediaInfo"
                                                              id: string
                                                              resourceId?: string | null
                                                              thumbnail?: string | null
                                                              width?: number | null
                                                              height?: number | null
                                                              mediaType?: Types.MediaType | null
                                                            }
                                                          | {
                                                              __typename: "ImageMediaInfo"
                                                              id: string
                                                              resourceId?: string | null
                                                              thumbnail?: string | null
                                                              width?: number | null
                                                              height?: number | null
                                                              resolution?: number | null
                                                              colorDepth?: number | null
                                                              size?: number | null
                                                              mime?: string | null
                                                              extension?: string | null
                                                              allowCaching?: boolean | null
                                                              mediaType?: Types.MediaType | null
                                                              src?: string | null
                                                              language?: string | null
                                                            }
                                                          | {
                                                              __typename: "NoMediaInfo"
                                                              id: string
                                                              mediaType?: Types.MediaType | null
                                                            }
                                                          | {
                                                              __typename: "VectorGraphicsMediaInfo"
                                                              id: string
                                                              resourceId?: string | null
                                                              thumbnail?: string | null
                                                              width?: number | null
                                                              height?: number | null
                                                              allowCaching?: boolean | null
                                                              mediaType?: Types.MediaType | null
                                                              language?: string | null
                                                              labels: Array<{
                                                                __typename: "VectorGraphicsLabel"
                                                                id?: string | null
                                                                sequence?: number | null
                                                                autoValue?: string | null
                                                                text?: string | null
                                                                linkType?: Types.VectorGraphicsLabelLinkType | null
                                                                link?: string | null
                                                                ref?:
                                                                  | {
                                                                      __typename: "GenericRef"
                                                                      id: string
                                                                      owner?:
                                                                        | { __typename: "CommentNode" }
                                                                        | { __typename: "ExternalSite" }
                                                                        | { __typename: "ST4Class" }
                                                                        | { __typename: "ST4Node"; id: string }
                                                                        | { __typename: "ST4TreeNode" }
                                                                        | null
                                                                      target?:
                                                                        | { __typename: "CommentNode" }
                                                                        | {
                                                                            __typename: "ExternalSite"
                                                                            id: string
                                                                            teaserData?: {
                                                                              __typename: "ExternalLinkTeaserData"
                                                                              title?: string | null
                                                                              summary?: string | null
                                                                              iconUrl?: string | null
                                                                              imageUrl?: string | null
                                                                              status?: Types.LinkTargetStatus | null
                                                                            } | null
                                                                          }
                                                                        | { __typename: "ST4Class" }
                                                                        | {
                                                                            __typename: "ST4Node"
                                                                            id: string
                                                                            aspectId: any
                                                                            label?: string | null
                                                                            properties?: {
                                                                              __typename: "ContentPropertyConnection"
                                                                              edges?: Array<{
                                                                                __typename: "ContentPropertyEdge"
                                                                                node?: {
                                                                                  __typename: "ContentProperty"
                                                                                  name: string
                                                                                  value?: any | null
                                                                                  displayName: string
                                                                                } | null
                                                                              } | null> | null
                                                                            } | null
                                                                          }
                                                                        | { __typename: "ST4TreeNode" }
                                                                        | null
                                                                    }
                                                                  | {
                                                                      __typename: "NodeRef"
                                                                      id: string
                                                                      owner?:
                                                                        | { __typename: "CommentNode" }
                                                                        | { __typename: "ExternalSite" }
                                                                        | { __typename: "ST4Class" }
                                                                        | { __typename: "ST4Node"; id: string }
                                                                        | { __typename: "ST4TreeNode" }
                                                                        | null
                                                                      target?:
                                                                        | { __typename: "CommentNode" }
                                                                        | {
                                                                            __typename: "ExternalSite"
                                                                            id: string
                                                                            teaserData?: {
                                                                              __typename: "ExternalLinkTeaserData"
                                                                              title?: string | null
                                                                              summary?: string | null
                                                                              iconUrl?: string | null
                                                                              imageUrl?: string | null
                                                                              status?: Types.LinkTargetStatus | null
                                                                            } | null
                                                                          }
                                                                        | { __typename: "ST4Class" }
                                                                        | {
                                                                            __typename: "ST4Node"
                                                                            id: string
                                                                            aspectId: any
                                                                            label?: string | null
                                                                            properties?: {
                                                                              __typename: "ContentPropertyConnection"
                                                                              edges?: Array<{
                                                                                __typename: "ContentPropertyEdge"
                                                                                node?: {
                                                                                  __typename: "ContentProperty"
                                                                                  name: string
                                                                                  value?: any | null
                                                                                  displayName: string
                                                                                } | null
                                                                              } | null> | null
                                                                            } | null
                                                                          }
                                                                        | { __typename: "ST4TreeNode" }
                                                                        | null
                                                                    }
                                                                  | null
                                                              }>
                                                            }
                                                          | {
                                                              __typename: "VideoMediaInfo"
                                                              id: string
                                                              resourceId?: string | null
                                                              thumbnail?: string | null
                                                              width?: number | null
                                                              height?: number | null
                                                              mediaType?: Types.MediaType | null
                                                            }
                                                          | null
                                                      }
                                                    | { __typename: "ProjectContent" }
                                                    | { __typename: "TermContent" }
                                                    | { __typename: "TextContent" }
                                                    | { __typename: "TextGroupContent" }
                                                    | { __typename: "VariableTableContent" }
                                                    | null
                                                }
                                              | { __typename: "ST4TreeNode"; id: string }
                                              | null
                                          }>
                                          media?:
                                            | {
                                                __typename: "AnimationMediaInfo"
                                                id: string
                                                resourceId?: string | null
                                                thumbnail?: string | null
                                                width?: number | null
                                                height?: number | null
                                                mediaType?: Types.MediaType | null
                                              }
                                            | {
                                                __typename: "ImageMediaInfo"
                                                id: string
                                                resourceId?: string | null
                                                thumbnail?: string | null
                                                width?: number | null
                                                height?: number | null
                                                resolution?: number | null
                                                colorDepth?: number | null
                                                size?: number | null
                                                mime?: string | null
                                                extension?: string | null
                                                allowCaching?: boolean | null
                                                mediaType?: Types.MediaType | null
                                                src?: string | null
                                                language?: string | null
                                              }
                                            | {
                                                __typename: "NoMediaInfo"
                                                id: string
                                                mediaType?: Types.MediaType | null
                                              }
                                            | {
                                                __typename: "VectorGraphicsMediaInfo"
                                                id: string
                                                resourceId?: string | null
                                                thumbnail?: string | null
                                                width?: number | null
                                                height?: number | null
                                                allowCaching?: boolean | null
                                                mediaType?: Types.MediaType | null
                                                language?: string | null
                                                labels: Array<{
                                                  __typename: "VectorGraphicsLabel"
                                                  id?: string | null
                                                  sequence?: number | null
                                                  autoValue?: string | null
                                                  text?: string | null
                                                  linkType?: Types.VectorGraphicsLabelLinkType | null
                                                  link?: string | null
                                                  ref?:
                                                    | {
                                                        __typename: "GenericRef"
                                                        id: string
                                                        owner?:
                                                          | { __typename: "CommentNode" }
                                                          | { __typename: "ExternalSite" }
                                                          | { __typename: "ST4Class" }
                                                          | { __typename: "ST4Node"; id: string }
                                                          | { __typename: "ST4TreeNode" }
                                                          | null
                                                        target?:
                                                          | { __typename: "CommentNode" }
                                                          | {
                                                              __typename: "ExternalSite"
                                                              id: string
                                                              teaserData?: {
                                                                __typename: "ExternalLinkTeaserData"
                                                                title?: string | null
                                                                summary?: string | null
                                                                iconUrl?: string | null
                                                                imageUrl?: string | null
                                                                status?: Types.LinkTargetStatus | null
                                                              } | null
                                                            }
                                                          | { __typename: "ST4Class" }
                                                          | {
                                                              __typename: "ST4Node"
                                                              id: string
                                                              aspectId: any
                                                              label?: string | null
                                                              properties?: {
                                                                __typename: "ContentPropertyConnection"
                                                                edges?: Array<{
                                                                  __typename: "ContentPropertyEdge"
                                                                  node?: {
                                                                    __typename: "ContentProperty"
                                                                    name: string
                                                                    value?: any | null
                                                                    displayName: string
                                                                  } | null
                                                                } | null> | null
                                                              } | null
                                                            }
                                                          | { __typename: "ST4TreeNode" }
                                                          | null
                                                      }
                                                    | {
                                                        __typename: "NodeRef"
                                                        id: string
                                                        owner?:
                                                          | { __typename: "CommentNode" }
                                                          | { __typename: "ExternalSite" }
                                                          | { __typename: "ST4Class" }
                                                          | { __typename: "ST4Node"; id: string }
                                                          | { __typename: "ST4TreeNode" }
                                                          | null
                                                        target?:
                                                          | { __typename: "CommentNode" }
                                                          | {
                                                              __typename: "ExternalSite"
                                                              id: string
                                                              teaserData?: {
                                                                __typename: "ExternalLinkTeaserData"
                                                                title?: string | null
                                                                summary?: string | null
                                                                iconUrl?: string | null
                                                                imageUrl?: string | null
                                                                status?: Types.LinkTargetStatus | null
                                                              } | null
                                                            }
                                                          | { __typename: "ST4Class" }
                                                          | {
                                                              __typename: "ST4Node"
                                                              id: string
                                                              aspectId: any
                                                              label?: string | null
                                                              properties?: {
                                                                __typename: "ContentPropertyConnection"
                                                                edges?: Array<{
                                                                  __typename: "ContentPropertyEdge"
                                                                  node?: {
                                                                    __typename: "ContentProperty"
                                                                    name: string
                                                                    value?: any | null
                                                                    displayName: string
                                                                  } | null
                                                                } | null> | null
                                                              } | null
                                                            }
                                                          | { __typename: "ST4TreeNode" }
                                                          | null
                                                      }
                                                    | null
                                                }>
                                              }
                                            | {
                                                __typename: "VideoMediaInfo"
                                                id: string
                                                resourceId?: string | null
                                                thumbnail?: string | null
                                                width?: number | null
                                                height?: number | null
                                                mediaType?: Types.MediaType | null
                                              }
                                            | null
                                        }
                                      | { __typename: "ProjectContent" }
                                      | { __typename: "TermContent" }
                                      | { __typename: "TextContent" }
                                      | { __typename: "TextGroupContent" }
                                      | { __typename: "VariableTableContent" }
                                      | null
                                  }
                                | { __typename: "ST4TreeNode"; id: string }
                                | null
                            }>
                            variableTables?: Array<{
                              __typename: "VariableTableReference"
                              ref?: string | null
                              node?:
                                | { __typename: "CommentNode" }
                                | { __typename: "ExternalSite" }
                                | { __typename: "ST4Class" }
                                | {
                                    __typename: "ST4Node"
                                    id: string
                                    label?: string | null
                                    aspectId: any
                                    content?:
                                      | { __typename: "LexiconContent" }
                                      | { __typename: "LexiconEntryContent" }
                                      | { __typename: "MediaContent" }
                                      | { __typename: "MediaGroupContent" }
                                      | { __typename: "ProjectContent" }
                                      | { __typename: "TermContent" }
                                      | { __typename: "TextContent" }
                                      | { __typename: "TextGroupContent" }
                                      | {
                                          __typename: "VariableTableContent"
                                          xml?: string | null
                                          valueSets: Array<{ __typename: "ValueSet"; name: string; valueSetId: string }>
                                          variables: Array<{
                                            __typename: "Variable"
                                            name: string
                                            variableId: string
                                            values: Array<string>
                                          }>
                                        }
                                      | null
                                  }
                                | { __typename: "ST4TreeNode" }
                                | null
                            } | null> | null
                            linkedDataNodes: Array<{
                              __typename: "NodeRef"
                              id: string
                              target?:
                                | { __typename: "CommentNode"; id: string }
                                | { __typename: "ExternalSite"; id: string }
                                | { __typename: "ST4Class"; id: string }
                                | {
                                    __typename: "ST4Node"
                                    label?: string | null
                                    id: string
                                    metadata: Array<{
                                      __typename: "MetaDataEntry"
                                      label?: string | null
                                      systemName: string
                                      value?: string | null
                                      values: Array<string>
                                      users: Array<string>
                                    }>
                                    linkedOntologyNodes: Array<
                                      | {
                                          __typename: "GenericRef"
                                          target?:
                                            | { __typename: "CommentNode" }
                                            | { __typename: "ExternalSite" }
                                            | { __typename: "ST4Class" }
                                            | { __typename: "ST4Node"; id: string; label?: string | null }
                                            | { __typename: "ST4TreeNode" }
                                            | null
                                        }
                                      | {
                                          __typename: "NodeRef"
                                          linkClassName: string
                                          class?: { __typename: "ST4Class"; label?: string | null } | null
                                          target?:
                                            | { __typename: "CommentNode" }
                                            | { __typename: "ExternalSite" }
                                            | { __typename: "ST4Class" }
                                            | { __typename: "ST4Node"; id: string; label?: string | null }
                                            | { __typename: "ST4TreeNode" }
                                            | null
                                        }
                                    >
                                  }
                                | { __typename: "ST4TreeNode"; id: string }
                                | null
                            }>
                            lexiconEntries?: Array<{
                              __typename: "NodeRef"
                              id: string
                              target?:
                                | { __typename: "CommentNode"; id: string }
                                | { __typename: "ExternalSite"; id: string }
                                | { __typename: "ST4Class"; id: string }
                                | {
                                    __typename: "ST4Node"
                                    aspectId: any
                                    label?: string | null
                                    id: string
                                    content?:
                                      | { __typename: "LexiconContent" }
                                      | {
                                          __typename: "LexiconEntryContent"
                                          descriptionXast?: string | null
                                          subject?: string | null
                                          sortKey?: string | null
                                          lexicon:
                                            | { __typename: "CommentNode"; id: string }
                                            | { __typename: "ExternalSite"; id: string }
                                            | { __typename: "ST4Class"; id: string }
                                            | { __typename: "ST4Node"; label?: string | null; id: string }
                                            | { __typename: "ST4TreeNode"; id: string }
                                          terms: Array<
                                            | { __typename: "CommentNode"; id: string }
                                            | { __typename: "ExternalSite"; id: string }
                                            | { __typename: "ST4Class"; id: string }
                                            | {
                                                __typename: "ST4Node"
                                                id: string
                                                label?: string | null
                                                aspectId: any
                                                content?:
                                                  | { __typename: "LexiconContent" }
                                                  | { __typename: "LexiconEntryContent" }
                                                  | { __typename: "MediaContent" }
                                                  | { __typename: "MediaGroupContent" }
                                                  | { __typename: "ProjectContent" }
                                                  | {
                                                      __typename: "TermContent"
                                                      gramaticalGender?: string | null
                                                      validity?: string | null
                                                      editLanguage?: string | null
                                                    }
                                                  | { __typename: "TextContent" }
                                                  | { __typename: "TextGroupContent" }
                                                  | { __typename: "VariableTableContent" }
                                                  | null
                                              }
                                            | { __typename: "ST4TreeNode"; id: string }
                                          >
                                          image?:
                                            | { __typename: "CommentNode"; id: string }
                                            | { __typename: "ExternalSite"; id: string }
                                            | { __typename: "ST4Class"; id: string }
                                            | {
                                                __typename: "ST4Node"
                                                aspectId: any
                                                id: string
                                                content?:
                                                  | { __typename: "LexiconContent" }
                                                  | { __typename: "LexiconEntryContent" }
                                                  | {
                                                      __typename: "MediaContent"
                                                      media?:
                                                        | {
                                                            __typename: "AnimationMediaInfo"
                                                            thumbnail?: string | null
                                                            mediaType?: Types.MediaType | null
                                                          }
                                                        | {
                                                            __typename: "ImageMediaInfo"
                                                            src?: string | null
                                                            thumbnail?: string | null
                                                            mediaType?: Types.MediaType | null
                                                          }
                                                        | { __typename: "NoMediaInfo" }
                                                        | {
                                                            __typename: "VectorGraphicsMediaInfo"
                                                            svg?: string | null
                                                            thumbnail?: string | null
                                                            mediaType?: Types.MediaType | null
                                                          }
                                                        | {
                                                            __typename: "VideoMediaInfo"
                                                            thumbnail?: string | null
                                                            mediaType?: Types.MediaType | null
                                                          }
                                                        | null
                                                    }
                                                  | { __typename: "MediaGroupContent" }
                                                  | { __typename: "ProjectContent" }
                                                  | { __typename: "TermContent" }
                                                  | { __typename: "TextContent" }
                                                  | { __typename: "TextGroupContent" }
                                                  | { __typename: "VariableTableContent" }
                                                  | null
                                              }
                                            | { __typename: "ST4TreeNode"; id: string }
                                            | null
                                        }
                                      | { __typename: "MediaContent" }
                                      | { __typename: "MediaGroupContent" }
                                      | { __typename: "ProjectContent" }
                                      | { __typename: "TermContent" }
                                      | { __typename: "TextContent" }
                                      | { __typename: "TextGroupContent" }
                                      | { __typename: "VariableTableContent" }
                                      | null
                                  }
                                | { __typename: "ST4TreeNode"; id: string }
                                | null
                            } | null> | null
                            comments: Array<{
                              __typename: "CommentNode"
                              id: string
                              comment:
                                | {
                                    __typename: "BlockComment"
                                    location: number
                                    value?: string | null
                                    authorDisplayName?: string | null
                                    authorUserName?: string | null
                                    commentKey: string
                                    editDate?: any | null
                                    modificationVersion?: number | null
                                    replyToKey?: string | null
                                    sortOrder?: number | null
                                    commentType?: string | null
                                    state?: string | null
                                    color: string
                                    persistanceState?: Types.PersistanceState | null
                                    persistanceAction?: Types.PersistanceAction | null
                                    node: {
                                      __typename: "ST4Node"
                                      aspectId: any
                                      id: string
                                      content?:
                                        | { __typename: "LexiconContent" }
                                        | { __typename: "LexiconEntryContent" }
                                        | { __typename: "MediaContent" }
                                        | { __typename: "MediaGroupContent" }
                                        | { __typename: "ProjectContent" }
                                        | { __typename: "TermContent" }
                                        | { __typename: "TextContent"; id: string; historyNumber: number }
                                        | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                                        | { __typename: "VariableTableContent" }
                                        | null
                                    }
                                    actions: Array<{
                                      __typename: "Action"
                                      name?: string | null
                                      enabled?: boolean | null
                                    }>
                                  }
                                | {
                                    __typename: "TextComment"
                                    endLocation: number
                                    startLocation?: number | null
                                    value?: string | null
                                    authorDisplayName?: string | null
                                    authorUserName?: string | null
                                    commentKey: string
                                    editDate?: any | null
                                    modificationVersion?: number | null
                                    replyToKey?: string | null
                                    sortOrder?: number | null
                                    commentType?: string | null
                                    state?: string | null
                                    color: string
                                    persistanceState?: Types.PersistanceState | null
                                    persistanceAction?: Types.PersistanceAction | null
                                    node: {
                                      __typename: "ST4Node"
                                      aspectId: any
                                      id: string
                                      content?:
                                        | { __typename: "LexiconContent" }
                                        | { __typename: "LexiconEntryContent" }
                                        | { __typename: "MediaContent" }
                                        | { __typename: "MediaGroupContent" }
                                        | { __typename: "ProjectContent" }
                                        | { __typename: "TermContent" }
                                        | { __typename: "TextContent"; id: string; historyNumber: number }
                                        | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                                        | { __typename: "VariableTableContent" }
                                        | null
                                    }
                                    actions: Array<{
                                      __typename: "Action"
                                      name?: string | null
                                      enabled?: boolean | null
                                    }>
                                  }
                            }>
                          }
                        | {
                            __typename: "TextGroupContent"
                            currentNodeId?: any | null
                            groupNodeId: any
                            id: string
                            xast?: string | null
                            historyNumber: number
                            children: Array<{
                              __typename: "NodeRef"
                              id: string
                              target?:
                                | { __typename: "CommentNode"; id: string }
                                | { __typename: "ExternalSite"; id: string }
                                | { __typename: "ST4Class"; id: string }
                                | { __typename: "ST4Node"; label?: string | null; aspectId: any; id: string }
                                | { __typename: "ST4TreeNode"; id: string }
                                | null
                            }>
                            links?: Array<{
                              __typename: "TextContentLink"
                              ref?: string | null
                              node?:
                                | { __typename: "CommentNode"; id: string }
                                | { __typename: "ExternalSite"; id: string }
                                | { __typename: "ST4Class"; id: string }
                                | {
                                    __typename: "ST4Node"
                                    id: string
                                    aspectId: any
                                    label?: string | null
                                    properties?: {
                                      __typename: "ContentPropertyConnection"
                                      totalCount: number
                                      edges?: Array<{
                                        __typename: "ContentPropertyEdge"
                                        node?: {
                                          __typename: "ContentProperty"
                                          typeOfValue: Types.ContentPropertyValueType
                                          name: string
                                          value?: any | null
                                          displayName: string
                                        } | null
                                      } | null> | null
                                    } | null
                                  }
                                | { __typename: "ST4TreeNode"; id: string }
                                | null
                            } | null> | null
                            images: Array<{
                              __typename: "NodeRef"
                              id: string
                              target?:
                                | { __typename: "CommentNode"; id: string }
                                | { __typename: "ExternalSite"; id: string }
                                | { __typename: "ST4Class"; id: string }
                                | {
                                    __typename: "ST4Node"
                                    aspectId: any
                                    id: string
                                    releaseState: {
                                      __typename: "ReleaseStateValue"
                                      displayName: string
                                      state: Types.ReleaseState
                                      isImplicitlyReleased: boolean
                                    }
                                    content?:
                                      | { __typename: "LexiconContent" }
                                      | { __typename: "LexiconEntryContent" }
                                      | {
                                          __typename: "MediaContent"
                                          media?:
                                            | {
                                                __typename: "AnimationMediaInfo"
                                                id: string
                                                resourceId?: string | null
                                                thumbnail?: string | null
                                                width?: number | null
                                                height?: number | null
                                                mediaType?: Types.MediaType | null
                                              }
                                            | {
                                                __typename: "ImageMediaInfo"
                                                id: string
                                                resourceId?: string | null
                                                thumbnail?: string | null
                                                width?: number | null
                                                height?: number | null
                                                resolution?: number | null
                                                colorDepth?: number | null
                                                size?: number | null
                                                mime?: string | null
                                                extension?: string | null
                                                allowCaching?: boolean | null
                                                mediaType?: Types.MediaType | null
                                                src?: string | null
                                                language?: string | null
                                              }
                                            | {
                                                __typename: "NoMediaInfo"
                                                id: string
                                                mediaType?: Types.MediaType | null
                                              }
                                            | {
                                                __typename: "VectorGraphicsMediaInfo"
                                                id: string
                                                resourceId?: string | null
                                                thumbnail?: string | null
                                                width?: number | null
                                                height?: number | null
                                                allowCaching?: boolean | null
                                                mediaType?: Types.MediaType | null
                                                language?: string | null
                                                labels: Array<{
                                                  __typename: "VectorGraphicsLabel"
                                                  id?: string | null
                                                  sequence?: number | null
                                                  autoValue?: string | null
                                                  text?: string | null
                                                  linkType?: Types.VectorGraphicsLabelLinkType | null
                                                  link?: string | null
                                                  ref?:
                                                    | {
                                                        __typename: "GenericRef"
                                                        id: string
                                                        owner?:
                                                          | { __typename: "CommentNode" }
                                                          | { __typename: "ExternalSite" }
                                                          | { __typename: "ST4Class" }
                                                          | { __typename: "ST4Node"; id: string }
                                                          | { __typename: "ST4TreeNode" }
                                                          | null
                                                        target?:
                                                          | { __typename: "CommentNode" }
                                                          | {
                                                              __typename: "ExternalSite"
                                                              id: string
                                                              teaserData?: {
                                                                __typename: "ExternalLinkTeaserData"
                                                                title?: string | null
                                                                summary?: string | null
                                                                iconUrl?: string | null
                                                                imageUrl?: string | null
                                                                status?: Types.LinkTargetStatus | null
                                                              } | null
                                                            }
                                                          | { __typename: "ST4Class" }
                                                          | {
                                                              __typename: "ST4Node"
                                                              id: string
                                                              aspectId: any
                                                              label?: string | null
                                                              properties?: {
                                                                __typename: "ContentPropertyConnection"
                                                                edges?: Array<{
                                                                  __typename: "ContentPropertyEdge"
                                                                  node?: {
                                                                    __typename: "ContentProperty"
                                                                    name: string
                                                                    value?: any | null
                                                                    displayName: string
                                                                  } | null
                                                                } | null> | null
                                                              } | null
                                                            }
                                                          | { __typename: "ST4TreeNode" }
                                                          | null
                                                      }
                                                    | {
                                                        __typename: "NodeRef"
                                                        id: string
                                                        owner?:
                                                          | { __typename: "CommentNode" }
                                                          | { __typename: "ExternalSite" }
                                                          | { __typename: "ST4Class" }
                                                          | { __typename: "ST4Node"; id: string }
                                                          | { __typename: "ST4TreeNode" }
                                                          | null
                                                        target?:
                                                          | { __typename: "CommentNode" }
                                                          | {
                                                              __typename: "ExternalSite"
                                                              id: string
                                                              teaserData?: {
                                                                __typename: "ExternalLinkTeaserData"
                                                                title?: string | null
                                                                summary?: string | null
                                                                iconUrl?: string | null
                                                                imageUrl?: string | null
                                                                status?: Types.LinkTargetStatus | null
                                                              } | null
                                                            }
                                                          | { __typename: "ST4Class" }
                                                          | {
                                                              __typename: "ST4Node"
                                                              id: string
                                                              aspectId: any
                                                              label?: string | null
                                                              properties?: {
                                                                __typename: "ContentPropertyConnection"
                                                                edges?: Array<{
                                                                  __typename: "ContentPropertyEdge"
                                                                  node?: {
                                                                    __typename: "ContentProperty"
                                                                    name: string
                                                                    value?: any | null
                                                                    displayName: string
                                                                  } | null
                                                                } | null> | null
                                                              } | null
                                                            }
                                                          | { __typename: "ST4TreeNode" }
                                                          | null
                                                      }
                                                    | null
                                                }>
                                              }
                                            | {
                                                __typename: "VideoMediaInfo"
                                                id: string
                                                resourceId?: string | null
                                                thumbnail?: string | null
                                                width?: number | null
                                                height?: number | null
                                                mediaType?: Types.MediaType | null
                                              }
                                            | null
                                        }
                                      | {
                                          __typename: "MediaGroupContent"
                                          children: Array<{
                                            __typename: "NodeRef"
                                            id: string
                                            target?:
                                              | { __typename: "CommentNode"; id: string }
                                              | { __typename: "ExternalSite"; id: string }
                                              | { __typename: "ST4Class"; id: string }
                                              | {
                                                  __typename: "ST4Node"
                                                  id: string
                                                  aspectId: any
                                                  content?:
                                                    | { __typename: "LexiconContent" }
                                                    | { __typename: "LexiconEntryContent" }
                                                    | {
                                                        __typename: "MediaContent"
                                                        media?:
                                                          | {
                                                              __typename: "AnimationMediaInfo"
                                                              id: string
                                                              resourceId?: string | null
                                                              thumbnail?: string | null
                                                              width?: number | null
                                                              height?: number | null
                                                              mediaType?: Types.MediaType | null
                                                            }
                                                          | {
                                                              __typename: "ImageMediaInfo"
                                                              id: string
                                                              resourceId?: string | null
                                                              thumbnail?: string | null
                                                              width?: number | null
                                                              height?: number | null
                                                              resolution?: number | null
                                                              colorDepth?: number | null
                                                              size?: number | null
                                                              mime?: string | null
                                                              extension?: string | null
                                                              allowCaching?: boolean | null
                                                              mediaType?: Types.MediaType | null
                                                              src?: string | null
                                                              language?: string | null
                                                            }
                                                          | {
                                                              __typename: "NoMediaInfo"
                                                              id: string
                                                              mediaType?: Types.MediaType | null
                                                            }
                                                          | {
                                                              __typename: "VectorGraphicsMediaInfo"
                                                              id: string
                                                              resourceId?: string | null
                                                              thumbnail?: string | null
                                                              width?: number | null
                                                              height?: number | null
                                                              allowCaching?: boolean | null
                                                              mediaType?: Types.MediaType | null
                                                              language?: string | null
                                                              labels: Array<{
                                                                __typename: "VectorGraphicsLabel"
                                                                id?: string | null
                                                                sequence?: number | null
                                                                autoValue?: string | null
                                                                text?: string | null
                                                                linkType?: Types.VectorGraphicsLabelLinkType | null
                                                                link?: string | null
                                                                ref?:
                                                                  | {
                                                                      __typename: "GenericRef"
                                                                      id: string
                                                                      owner?:
                                                                        | { __typename: "CommentNode" }
                                                                        | { __typename: "ExternalSite" }
                                                                        | { __typename: "ST4Class" }
                                                                        | { __typename: "ST4Node"; id: string }
                                                                        | { __typename: "ST4TreeNode" }
                                                                        | null
                                                                      target?:
                                                                        | { __typename: "CommentNode" }
                                                                        | {
                                                                            __typename: "ExternalSite"
                                                                            id: string
                                                                            teaserData?: {
                                                                              __typename: "ExternalLinkTeaserData"
                                                                              title?: string | null
                                                                              summary?: string | null
                                                                              iconUrl?: string | null
                                                                              imageUrl?: string | null
                                                                              status?: Types.LinkTargetStatus | null
                                                                            } | null
                                                                          }
                                                                        | { __typename: "ST4Class" }
                                                                        | {
                                                                            __typename: "ST4Node"
                                                                            id: string
                                                                            aspectId: any
                                                                            label?: string | null
                                                                            properties?: {
                                                                              __typename: "ContentPropertyConnection"
                                                                              edges?: Array<{
                                                                                __typename: "ContentPropertyEdge"
                                                                                node?: {
                                                                                  __typename: "ContentProperty"
                                                                                  name: string
                                                                                  value?: any | null
                                                                                  displayName: string
                                                                                } | null
                                                                              } | null> | null
                                                                            } | null
                                                                          }
                                                                        | { __typename: "ST4TreeNode" }
                                                                        | null
                                                                    }
                                                                  | {
                                                                      __typename: "NodeRef"
                                                                      id: string
                                                                      owner?:
                                                                        | { __typename: "CommentNode" }
                                                                        | { __typename: "ExternalSite" }
                                                                        | { __typename: "ST4Class" }
                                                                        | { __typename: "ST4Node"; id: string }
                                                                        | { __typename: "ST4TreeNode" }
                                                                        | null
                                                                      target?:
                                                                        | { __typename: "CommentNode" }
                                                                        | {
                                                                            __typename: "ExternalSite"
                                                                            id: string
                                                                            teaserData?: {
                                                                              __typename: "ExternalLinkTeaserData"
                                                                              title?: string | null
                                                                              summary?: string | null
                                                                              iconUrl?: string | null
                                                                              imageUrl?: string | null
                                                                              status?: Types.LinkTargetStatus | null
                                                                            } | null
                                                                          }
                                                                        | { __typename: "ST4Class" }
                                                                        | {
                                                                            __typename: "ST4Node"
                                                                            id: string
                                                                            aspectId: any
                                                                            label?: string | null
                                                                            properties?: {
                                                                              __typename: "ContentPropertyConnection"
                                                                              edges?: Array<{
                                                                                __typename: "ContentPropertyEdge"
                                                                                node?: {
                                                                                  __typename: "ContentProperty"
                                                                                  name: string
                                                                                  value?: any | null
                                                                                  displayName: string
                                                                                } | null
                                                                              } | null> | null
                                                                            } | null
                                                                          }
                                                                        | { __typename: "ST4TreeNode" }
                                                                        | null
                                                                    }
                                                                  | null
                                                              }>
                                                            }
                                                          | {
                                                              __typename: "VideoMediaInfo"
                                                              id: string
                                                              resourceId?: string | null
                                                              thumbnail?: string | null
                                                              width?: number | null
                                                              height?: number | null
                                                              mediaType?: Types.MediaType | null
                                                            }
                                                          | null
                                                      }
                                                    | {
                                                        __typename: "MediaGroupContent"
                                                        media?:
                                                          | {
                                                              __typename: "AnimationMediaInfo"
                                                              id: string
                                                              resourceId?: string | null
                                                              thumbnail?: string | null
                                                              width?: number | null
                                                              height?: number | null
                                                              mediaType?: Types.MediaType | null
                                                            }
                                                          | {
                                                              __typename: "ImageMediaInfo"
                                                              id: string
                                                              resourceId?: string | null
                                                              thumbnail?: string | null
                                                              width?: number | null
                                                              height?: number | null
                                                              resolution?: number | null
                                                              colorDepth?: number | null
                                                              size?: number | null
                                                              mime?: string | null
                                                              extension?: string | null
                                                              allowCaching?: boolean | null
                                                              mediaType?: Types.MediaType | null
                                                              src?: string | null
                                                              language?: string | null
                                                            }
                                                          | {
                                                              __typename: "NoMediaInfo"
                                                              id: string
                                                              mediaType?: Types.MediaType | null
                                                            }
                                                          | {
                                                              __typename: "VectorGraphicsMediaInfo"
                                                              id: string
                                                              resourceId?: string | null
                                                              thumbnail?: string | null
                                                              width?: number | null
                                                              height?: number | null
                                                              allowCaching?: boolean | null
                                                              mediaType?: Types.MediaType | null
                                                              language?: string | null
                                                              labels: Array<{
                                                                __typename: "VectorGraphicsLabel"
                                                                id?: string | null
                                                                sequence?: number | null
                                                                autoValue?: string | null
                                                                text?: string | null
                                                                linkType?: Types.VectorGraphicsLabelLinkType | null
                                                                link?: string | null
                                                                ref?:
                                                                  | {
                                                                      __typename: "GenericRef"
                                                                      id: string
                                                                      owner?:
                                                                        | { __typename: "CommentNode" }
                                                                        | { __typename: "ExternalSite" }
                                                                        | { __typename: "ST4Class" }
                                                                        | { __typename: "ST4Node"; id: string }
                                                                        | { __typename: "ST4TreeNode" }
                                                                        | null
                                                                      target?:
                                                                        | { __typename: "CommentNode" }
                                                                        | {
                                                                            __typename: "ExternalSite"
                                                                            id: string
                                                                            teaserData?: {
                                                                              __typename: "ExternalLinkTeaserData"
                                                                              title?: string | null
                                                                              summary?: string | null
                                                                              iconUrl?: string | null
                                                                              imageUrl?: string | null
                                                                              status?: Types.LinkTargetStatus | null
                                                                            } | null
                                                                          }
                                                                        | { __typename: "ST4Class" }
                                                                        | {
                                                                            __typename: "ST4Node"
                                                                            id: string
                                                                            aspectId: any
                                                                            label?: string | null
                                                                            properties?: {
                                                                              __typename: "ContentPropertyConnection"
                                                                              edges?: Array<{
                                                                                __typename: "ContentPropertyEdge"
                                                                                node?: {
                                                                                  __typename: "ContentProperty"
                                                                                  name: string
                                                                                  value?: any | null
                                                                                  displayName: string
                                                                                } | null
                                                                              } | null> | null
                                                                            } | null
                                                                          }
                                                                        | { __typename: "ST4TreeNode" }
                                                                        | null
                                                                    }
                                                                  | {
                                                                      __typename: "NodeRef"
                                                                      id: string
                                                                      owner?:
                                                                        | { __typename: "CommentNode" }
                                                                        | { __typename: "ExternalSite" }
                                                                        | { __typename: "ST4Class" }
                                                                        | { __typename: "ST4Node"; id: string }
                                                                        | { __typename: "ST4TreeNode" }
                                                                        | null
                                                                      target?:
                                                                        | { __typename: "CommentNode" }
                                                                        | {
                                                                            __typename: "ExternalSite"
                                                                            id: string
                                                                            teaserData?: {
                                                                              __typename: "ExternalLinkTeaserData"
                                                                              title?: string | null
                                                                              summary?: string | null
                                                                              iconUrl?: string | null
                                                                              imageUrl?: string | null
                                                                              status?: Types.LinkTargetStatus | null
                                                                            } | null
                                                                          }
                                                                        | { __typename: "ST4Class" }
                                                                        | {
                                                                            __typename: "ST4Node"
                                                                            id: string
                                                                            aspectId: any
                                                                            label?: string | null
                                                                            properties?: {
                                                                              __typename: "ContentPropertyConnection"
                                                                              edges?: Array<{
                                                                                __typename: "ContentPropertyEdge"
                                                                                node?: {
                                                                                  __typename: "ContentProperty"
                                                                                  name: string
                                                                                  value?: any | null
                                                                                  displayName: string
                                                                                } | null
                                                                              } | null> | null
                                                                            } | null
                                                                          }
                                                                        | { __typename: "ST4TreeNode" }
                                                                        | null
                                                                    }
                                                                  | null
                                                              }>
                                                            }
                                                          | {
                                                              __typename: "VideoMediaInfo"
                                                              id: string
                                                              resourceId?: string | null
                                                              thumbnail?: string | null
                                                              width?: number | null
                                                              height?: number | null
                                                              mediaType?: Types.MediaType | null
                                                            }
                                                          | null
                                                      }
                                                    | { __typename: "ProjectContent" }
                                                    | { __typename: "TermContent" }
                                                    | { __typename: "TextContent" }
                                                    | { __typename: "TextGroupContent" }
                                                    | { __typename: "VariableTableContent" }
                                                    | null
                                                }
                                              | { __typename: "ST4TreeNode"; id: string }
                                              | null
                                          }>
                                          media?:
                                            | {
                                                __typename: "AnimationMediaInfo"
                                                id: string
                                                resourceId?: string | null
                                                thumbnail?: string | null
                                                width?: number | null
                                                height?: number | null
                                                mediaType?: Types.MediaType | null
                                              }
                                            | {
                                                __typename: "ImageMediaInfo"
                                                id: string
                                                resourceId?: string | null
                                                thumbnail?: string | null
                                                width?: number | null
                                                height?: number | null
                                                resolution?: number | null
                                                colorDepth?: number | null
                                                size?: number | null
                                                mime?: string | null
                                                extension?: string | null
                                                allowCaching?: boolean | null
                                                mediaType?: Types.MediaType | null
                                                src?: string | null
                                                language?: string | null
                                              }
                                            | {
                                                __typename: "NoMediaInfo"
                                                id: string
                                                mediaType?: Types.MediaType | null
                                              }
                                            | {
                                                __typename: "VectorGraphicsMediaInfo"
                                                id: string
                                                resourceId?: string | null
                                                thumbnail?: string | null
                                                width?: number | null
                                                height?: number | null
                                                allowCaching?: boolean | null
                                                mediaType?: Types.MediaType | null
                                                language?: string | null
                                                labels: Array<{
                                                  __typename: "VectorGraphicsLabel"
                                                  id?: string | null
                                                  sequence?: number | null
                                                  autoValue?: string | null
                                                  text?: string | null
                                                  linkType?: Types.VectorGraphicsLabelLinkType | null
                                                  link?: string | null
                                                  ref?:
                                                    | {
                                                        __typename: "GenericRef"
                                                        id: string
                                                        owner?:
                                                          | { __typename: "CommentNode" }
                                                          | { __typename: "ExternalSite" }
                                                          | { __typename: "ST4Class" }
                                                          | { __typename: "ST4Node"; id: string }
                                                          | { __typename: "ST4TreeNode" }
                                                          | null
                                                        target?:
                                                          | { __typename: "CommentNode" }
                                                          | {
                                                              __typename: "ExternalSite"
                                                              id: string
                                                              teaserData?: {
                                                                __typename: "ExternalLinkTeaserData"
                                                                title?: string | null
                                                                summary?: string | null
                                                                iconUrl?: string | null
                                                                imageUrl?: string | null
                                                                status?: Types.LinkTargetStatus | null
                                                              } | null
                                                            }
                                                          | { __typename: "ST4Class" }
                                                          | {
                                                              __typename: "ST4Node"
                                                              id: string
                                                              aspectId: any
                                                              label?: string | null
                                                              properties?: {
                                                                __typename: "ContentPropertyConnection"
                                                                edges?: Array<{
                                                                  __typename: "ContentPropertyEdge"
                                                                  node?: {
                                                                    __typename: "ContentProperty"
                                                                    name: string
                                                                    value?: any | null
                                                                    displayName: string
                                                                  } | null
                                                                } | null> | null
                                                              } | null
                                                            }
                                                          | { __typename: "ST4TreeNode" }
                                                          | null
                                                      }
                                                    | {
                                                        __typename: "NodeRef"
                                                        id: string
                                                        owner?:
                                                          | { __typename: "CommentNode" }
                                                          | { __typename: "ExternalSite" }
                                                          | { __typename: "ST4Class" }
                                                          | { __typename: "ST4Node"; id: string }
                                                          | { __typename: "ST4TreeNode" }
                                                          | null
                                                        target?:
                                                          | { __typename: "CommentNode" }
                                                          | {
                                                              __typename: "ExternalSite"
                                                              id: string
                                                              teaserData?: {
                                                                __typename: "ExternalLinkTeaserData"
                                                                title?: string | null
                                                                summary?: string | null
                                                                iconUrl?: string | null
                                                                imageUrl?: string | null
                                                                status?: Types.LinkTargetStatus | null
                                                              } | null
                                                            }
                                                          | { __typename: "ST4Class" }
                                                          | {
                                                              __typename: "ST4Node"
                                                              id: string
                                                              aspectId: any
                                                              label?: string | null
                                                              properties?: {
                                                                __typename: "ContentPropertyConnection"
                                                                edges?: Array<{
                                                                  __typename: "ContentPropertyEdge"
                                                                  node?: {
                                                                    __typename: "ContentProperty"
                                                                    name: string
                                                                    value?: any | null
                                                                    displayName: string
                                                                  } | null
                                                                } | null> | null
                                                              } | null
                                                            }
                                                          | { __typename: "ST4TreeNode" }
                                                          | null
                                                      }
                                                    | null
                                                }>
                                              }
                                            | {
                                                __typename: "VideoMediaInfo"
                                                id: string
                                                resourceId?: string | null
                                                thumbnail?: string | null
                                                width?: number | null
                                                height?: number | null
                                                mediaType?: Types.MediaType | null
                                              }
                                            | null
                                        }
                                      | { __typename: "ProjectContent" }
                                      | { __typename: "TermContent" }
                                      | { __typename: "TextContent" }
                                      | { __typename: "TextGroupContent" }
                                      | { __typename: "VariableTableContent" }
                                      | null
                                  }
                                | { __typename: "ST4TreeNode"; id: string }
                                | null
                            }>
                            variableTables?: Array<{
                              __typename: "VariableTableReference"
                              ref?: string | null
                              node?:
                                | { __typename: "CommentNode" }
                                | { __typename: "ExternalSite" }
                                | { __typename: "ST4Class" }
                                | {
                                    __typename: "ST4Node"
                                    id: string
                                    label?: string | null
                                    aspectId: any
                                    content?:
                                      | { __typename: "LexiconContent" }
                                      | { __typename: "LexiconEntryContent" }
                                      | { __typename: "MediaContent" }
                                      | { __typename: "MediaGroupContent" }
                                      | { __typename: "ProjectContent" }
                                      | { __typename: "TermContent" }
                                      | { __typename: "TextContent" }
                                      | { __typename: "TextGroupContent" }
                                      | {
                                          __typename: "VariableTableContent"
                                          xml?: string | null
                                          valueSets: Array<{ __typename: "ValueSet"; name: string; valueSetId: string }>
                                          variables: Array<{
                                            __typename: "Variable"
                                            name: string
                                            variableId: string
                                            values: Array<string>
                                          }>
                                        }
                                      | null
                                  }
                                | { __typename: "ST4TreeNode" }
                                | null
                            } | null> | null
                            linkedDataNodes: Array<{
                              __typename: "NodeRef"
                              id: string
                              target?:
                                | { __typename: "CommentNode"; id: string }
                                | { __typename: "ExternalSite"; id: string }
                                | { __typename: "ST4Class"; id: string }
                                | {
                                    __typename: "ST4Node"
                                    label?: string | null
                                    id: string
                                    metadata: Array<{
                                      __typename: "MetaDataEntry"
                                      label?: string | null
                                      systemName: string
                                      value?: string | null
                                      values: Array<string>
                                      users: Array<string>
                                    }>
                                    linkedOntologyNodes: Array<
                                      | {
                                          __typename: "GenericRef"
                                          target?:
                                            | { __typename: "CommentNode" }
                                            | { __typename: "ExternalSite" }
                                            | { __typename: "ST4Class" }
                                            | { __typename: "ST4Node"; id: string; label?: string | null }
                                            | { __typename: "ST4TreeNode" }
                                            | null
                                        }
                                      | {
                                          __typename: "NodeRef"
                                          linkClassName: string
                                          class?: { __typename: "ST4Class"; label?: string | null } | null
                                          target?:
                                            | { __typename: "CommentNode" }
                                            | { __typename: "ExternalSite" }
                                            | { __typename: "ST4Class" }
                                            | { __typename: "ST4Node"; id: string; label?: string | null }
                                            | { __typename: "ST4TreeNode" }
                                            | null
                                        }
                                    >
                                  }
                                | { __typename: "ST4TreeNode"; id: string }
                                | null
                            }>
                            lexiconEntries?: Array<{
                              __typename: "NodeRef"
                              id: string
                              target?:
                                | { __typename: "CommentNode"; id: string }
                                | { __typename: "ExternalSite"; id: string }
                                | { __typename: "ST4Class"; id: string }
                                | {
                                    __typename: "ST4Node"
                                    aspectId: any
                                    label?: string | null
                                    id: string
                                    content?:
                                      | { __typename: "LexiconContent" }
                                      | {
                                          __typename: "LexiconEntryContent"
                                          descriptionXast?: string | null
                                          subject?: string | null
                                          sortKey?: string | null
                                          lexicon:
                                            | { __typename: "CommentNode"; id: string }
                                            | { __typename: "ExternalSite"; id: string }
                                            | { __typename: "ST4Class"; id: string }
                                            | { __typename: "ST4Node"; label?: string | null; id: string }
                                            | { __typename: "ST4TreeNode"; id: string }
                                          terms: Array<
                                            | { __typename: "CommentNode"; id: string }
                                            | { __typename: "ExternalSite"; id: string }
                                            | { __typename: "ST4Class"; id: string }
                                            | {
                                                __typename: "ST4Node"
                                                id: string
                                                label?: string | null
                                                aspectId: any
                                                content?:
                                                  | { __typename: "LexiconContent" }
                                                  | { __typename: "LexiconEntryContent" }
                                                  | { __typename: "MediaContent" }
                                                  | { __typename: "MediaGroupContent" }
                                                  | { __typename: "ProjectContent" }
                                                  | {
                                                      __typename: "TermContent"
                                                      gramaticalGender?: string | null
                                                      validity?: string | null
                                                      editLanguage?: string | null
                                                    }
                                                  | { __typename: "TextContent" }
                                                  | { __typename: "TextGroupContent" }
                                                  | { __typename: "VariableTableContent" }
                                                  | null
                                              }
                                            | { __typename: "ST4TreeNode"; id: string }
                                          >
                                          image?:
                                            | { __typename: "CommentNode"; id: string }
                                            | { __typename: "ExternalSite"; id: string }
                                            | { __typename: "ST4Class"; id: string }
                                            | {
                                                __typename: "ST4Node"
                                                aspectId: any
                                                id: string
                                                content?:
                                                  | { __typename: "LexiconContent" }
                                                  | { __typename: "LexiconEntryContent" }
                                                  | {
                                                      __typename: "MediaContent"
                                                      media?:
                                                        | {
                                                            __typename: "AnimationMediaInfo"
                                                            thumbnail?: string | null
                                                            mediaType?: Types.MediaType | null
                                                          }
                                                        | {
                                                            __typename: "ImageMediaInfo"
                                                            src?: string | null
                                                            thumbnail?: string | null
                                                            mediaType?: Types.MediaType | null
                                                          }
                                                        | { __typename: "NoMediaInfo" }
                                                        | {
                                                            __typename: "VectorGraphicsMediaInfo"
                                                            svg?: string | null
                                                            thumbnail?: string | null
                                                            mediaType?: Types.MediaType | null
                                                          }
                                                        | {
                                                            __typename: "VideoMediaInfo"
                                                            thumbnail?: string | null
                                                            mediaType?: Types.MediaType | null
                                                          }
                                                        | null
                                                    }
                                                  | { __typename: "MediaGroupContent" }
                                                  | { __typename: "ProjectContent" }
                                                  | { __typename: "TermContent" }
                                                  | { __typename: "TextContent" }
                                                  | { __typename: "TextGroupContent" }
                                                  | { __typename: "VariableTableContent" }
                                                  | null
                                              }
                                            | { __typename: "ST4TreeNode"; id: string }
                                            | null
                                        }
                                      | { __typename: "MediaContent" }
                                      | { __typename: "MediaGroupContent" }
                                      | { __typename: "ProjectContent" }
                                      | { __typename: "TermContent" }
                                      | { __typename: "TextContent" }
                                      | { __typename: "TextGroupContent" }
                                      | { __typename: "VariableTableContent" }
                                      | null
                                  }
                                | { __typename: "ST4TreeNode"; id: string }
                                | null
                            } | null> | null
                            comments: Array<{
                              __typename: "CommentNode"
                              id: string
                              comment:
                                | {
                                    __typename: "BlockComment"
                                    location: number
                                    value?: string | null
                                    authorDisplayName?: string | null
                                    authorUserName?: string | null
                                    commentKey: string
                                    editDate?: any | null
                                    modificationVersion?: number | null
                                    replyToKey?: string | null
                                    sortOrder?: number | null
                                    commentType?: string | null
                                    state?: string | null
                                    color: string
                                    persistanceState?: Types.PersistanceState | null
                                    persistanceAction?: Types.PersistanceAction | null
                                    node: {
                                      __typename: "ST4Node"
                                      aspectId: any
                                      id: string
                                      content?:
                                        | { __typename: "LexiconContent" }
                                        | { __typename: "LexiconEntryContent" }
                                        | { __typename: "MediaContent" }
                                        | { __typename: "MediaGroupContent" }
                                        | { __typename: "ProjectContent" }
                                        | { __typename: "TermContent" }
                                        | { __typename: "TextContent"; id: string; historyNumber: number }
                                        | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                                        | { __typename: "VariableTableContent" }
                                        | null
                                    }
                                    actions: Array<{
                                      __typename: "Action"
                                      name?: string | null
                                      enabled?: boolean | null
                                    }>
                                  }
                                | {
                                    __typename: "TextComment"
                                    endLocation: number
                                    startLocation?: number | null
                                    value?: string | null
                                    authorDisplayName?: string | null
                                    authorUserName?: string | null
                                    commentKey: string
                                    editDate?: any | null
                                    modificationVersion?: number | null
                                    replyToKey?: string | null
                                    sortOrder?: number | null
                                    commentType?: string | null
                                    state?: string | null
                                    color: string
                                    persistanceState?: Types.PersistanceState | null
                                    persistanceAction?: Types.PersistanceAction | null
                                    node: {
                                      __typename: "ST4Node"
                                      aspectId: any
                                      id: string
                                      content?:
                                        | { __typename: "LexiconContent" }
                                        | { __typename: "LexiconEntryContent" }
                                        | { __typename: "MediaContent" }
                                        | { __typename: "MediaGroupContent" }
                                        | { __typename: "ProjectContent" }
                                        | { __typename: "TermContent" }
                                        | { __typename: "TextContent"; id: string; historyNumber: number }
                                        | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                                        | { __typename: "VariableTableContent" }
                                        | null
                                    }
                                    actions: Array<{
                                      __typename: "Action"
                                      name?: string | null
                                      enabled?: boolean | null
                                    }>
                                  }
                            }>
                          }
                        | { __typename: "VariableTableContent" }
                        | null
                    }
                  }>
                  links?: Array<{
                    __typename: "TextContentLink"
                    ref?: string | null
                    node?:
                      | { __typename: "CommentNode"; id: string }
                      | { __typename: "ExternalSite"; id: string }
                      | { __typename: "ST4Class"; id: string }
                      | {
                          __typename: "ST4Node"
                          id: string
                          aspectId: any
                          label?: string | null
                          properties?: {
                            __typename: "ContentPropertyConnection"
                            totalCount: number
                            edges?: Array<{
                              __typename: "ContentPropertyEdge"
                              node?: {
                                __typename: "ContentProperty"
                                typeOfValue: Types.ContentPropertyValueType
                                name: string
                                value?: any | null
                                displayName: string
                              } | null
                            } | null> | null
                          } | null
                        }
                      | { __typename: "ST4TreeNode"; id: string }
                      | null
                  } | null> | null
                  images: Array<{
                    __typename: "NodeRef"
                    id: string
                    target?:
                      | { __typename: "CommentNode"; id: string }
                      | { __typename: "ExternalSite"; id: string }
                      | { __typename: "ST4Class"; id: string }
                      | {
                          __typename: "ST4Node"
                          aspectId: any
                          id: string
                          releaseState: {
                            __typename: "ReleaseStateValue"
                            displayName: string
                            state: Types.ReleaseState
                            isImplicitlyReleased: boolean
                          }
                          content?:
                            | { __typename: "LexiconContent" }
                            | { __typename: "LexiconEntryContent" }
                            | {
                                __typename: "MediaContent"
                                media?:
                                  | {
                                      __typename: "AnimationMediaInfo"
                                      id: string
                                      resourceId?: string | null
                                      thumbnail?: string | null
                                      width?: number | null
                                      height?: number | null
                                      mediaType?: Types.MediaType | null
                                    }
                                  | {
                                      __typename: "ImageMediaInfo"
                                      id: string
                                      resourceId?: string | null
                                      thumbnail?: string | null
                                      width?: number | null
                                      height?: number | null
                                      resolution?: number | null
                                      colorDepth?: number | null
                                      size?: number | null
                                      mime?: string | null
                                      extension?: string | null
                                      allowCaching?: boolean | null
                                      mediaType?: Types.MediaType | null
                                      src?: string | null
                                      language?: string | null
                                    }
                                  | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                                  | {
                                      __typename: "VectorGraphicsMediaInfo"
                                      id: string
                                      resourceId?: string | null
                                      thumbnail?: string | null
                                      width?: number | null
                                      height?: number | null
                                      allowCaching?: boolean | null
                                      mediaType?: Types.MediaType | null
                                      language?: string | null
                                      labels: Array<{
                                        __typename: "VectorGraphicsLabel"
                                        id?: string | null
                                        sequence?: number | null
                                        autoValue?: string | null
                                        text?: string | null
                                        linkType?: Types.VectorGraphicsLabelLinkType | null
                                        link?: string | null
                                        ref?:
                                          | {
                                              __typename: "GenericRef"
                                              id: string
                                              owner?:
                                                | { __typename: "CommentNode" }
                                                | { __typename: "ExternalSite" }
                                                | { __typename: "ST4Class" }
                                                | { __typename: "ST4Node"; id: string }
                                                | { __typename: "ST4TreeNode" }
                                                | null
                                              target?:
                                                | { __typename: "CommentNode" }
                                                | {
                                                    __typename: "ExternalSite"
                                                    id: string
                                                    teaserData?: {
                                                      __typename: "ExternalLinkTeaserData"
                                                      title?: string | null
                                                      summary?: string | null
                                                      iconUrl?: string | null
                                                      imageUrl?: string | null
                                                      status?: Types.LinkTargetStatus | null
                                                    } | null
                                                  }
                                                | { __typename: "ST4Class" }
                                                | {
                                                    __typename: "ST4Node"
                                                    id: string
                                                    aspectId: any
                                                    label?: string | null
                                                    properties?: {
                                                      __typename: "ContentPropertyConnection"
                                                      edges?: Array<{
                                                        __typename: "ContentPropertyEdge"
                                                        node?: {
                                                          __typename: "ContentProperty"
                                                          name: string
                                                          value?: any | null
                                                          displayName: string
                                                        } | null
                                                      } | null> | null
                                                    } | null
                                                  }
                                                | { __typename: "ST4TreeNode" }
                                                | null
                                            }
                                          | {
                                              __typename: "NodeRef"
                                              id: string
                                              owner?:
                                                | { __typename: "CommentNode" }
                                                | { __typename: "ExternalSite" }
                                                | { __typename: "ST4Class" }
                                                | { __typename: "ST4Node"; id: string }
                                                | { __typename: "ST4TreeNode" }
                                                | null
                                              target?:
                                                | { __typename: "CommentNode" }
                                                | {
                                                    __typename: "ExternalSite"
                                                    id: string
                                                    teaserData?: {
                                                      __typename: "ExternalLinkTeaserData"
                                                      title?: string | null
                                                      summary?: string | null
                                                      iconUrl?: string | null
                                                      imageUrl?: string | null
                                                      status?: Types.LinkTargetStatus | null
                                                    } | null
                                                  }
                                                | { __typename: "ST4Class" }
                                                | {
                                                    __typename: "ST4Node"
                                                    id: string
                                                    aspectId: any
                                                    label?: string | null
                                                    properties?: {
                                                      __typename: "ContentPropertyConnection"
                                                      edges?: Array<{
                                                        __typename: "ContentPropertyEdge"
                                                        node?: {
                                                          __typename: "ContentProperty"
                                                          name: string
                                                          value?: any | null
                                                          displayName: string
                                                        } | null
                                                      } | null> | null
                                                    } | null
                                                  }
                                                | { __typename: "ST4TreeNode" }
                                                | null
                                            }
                                          | null
                                      }>
                                    }
                                  | {
                                      __typename: "VideoMediaInfo"
                                      id: string
                                      resourceId?: string | null
                                      thumbnail?: string | null
                                      width?: number | null
                                      height?: number | null
                                      mediaType?: Types.MediaType | null
                                    }
                                  | null
                              }
                            | {
                                __typename: "MediaGroupContent"
                                children: Array<{
                                  __typename: "NodeRef"
                                  id: string
                                  target?:
                                    | { __typename: "CommentNode"; id: string }
                                    | { __typename: "ExternalSite"; id: string }
                                    | { __typename: "ST4Class"; id: string }
                                    | {
                                        __typename: "ST4Node"
                                        id: string
                                        aspectId: any
                                        content?:
                                          | { __typename: "LexiconContent" }
                                          | { __typename: "LexiconEntryContent" }
                                          | {
                                              __typename: "MediaContent"
                                              media?:
                                                | {
                                                    __typename: "AnimationMediaInfo"
                                                    id: string
                                                    resourceId?: string | null
                                                    thumbnail?: string | null
                                                    width?: number | null
                                                    height?: number | null
                                                    mediaType?: Types.MediaType | null
                                                  }
                                                | {
                                                    __typename: "ImageMediaInfo"
                                                    id: string
                                                    resourceId?: string | null
                                                    thumbnail?: string | null
                                                    width?: number | null
                                                    height?: number | null
                                                    resolution?: number | null
                                                    colorDepth?: number | null
                                                    size?: number | null
                                                    mime?: string | null
                                                    extension?: string | null
                                                    allowCaching?: boolean | null
                                                    mediaType?: Types.MediaType | null
                                                    src?: string | null
                                                    language?: string | null
                                                  }
                                                | {
                                                    __typename: "NoMediaInfo"
                                                    id: string
                                                    mediaType?: Types.MediaType | null
                                                  }
                                                | {
                                                    __typename: "VectorGraphicsMediaInfo"
                                                    id: string
                                                    resourceId?: string | null
                                                    thumbnail?: string | null
                                                    width?: number | null
                                                    height?: number | null
                                                    allowCaching?: boolean | null
                                                    mediaType?: Types.MediaType | null
                                                    language?: string | null
                                                    labels: Array<{
                                                      __typename: "VectorGraphicsLabel"
                                                      id?: string | null
                                                      sequence?: number | null
                                                      autoValue?: string | null
                                                      text?: string | null
                                                      linkType?: Types.VectorGraphicsLabelLinkType | null
                                                      link?: string | null
                                                      ref?:
                                                        | {
                                                            __typename: "GenericRef"
                                                            id: string
                                                            owner?:
                                                              | { __typename: "CommentNode" }
                                                              | { __typename: "ExternalSite" }
                                                              | { __typename: "ST4Class" }
                                                              | { __typename: "ST4Node"; id: string }
                                                              | { __typename: "ST4TreeNode" }
                                                              | null
                                                            target?:
                                                              | { __typename: "CommentNode" }
                                                              | {
                                                                  __typename: "ExternalSite"
                                                                  id: string
                                                                  teaserData?: {
                                                                    __typename: "ExternalLinkTeaserData"
                                                                    title?: string | null
                                                                    summary?: string | null
                                                                    iconUrl?: string | null
                                                                    imageUrl?: string | null
                                                                    status?: Types.LinkTargetStatus | null
                                                                  } | null
                                                                }
                                                              | { __typename: "ST4Class" }
                                                              | {
                                                                  __typename: "ST4Node"
                                                                  id: string
                                                                  aspectId: any
                                                                  label?: string | null
                                                                  properties?: {
                                                                    __typename: "ContentPropertyConnection"
                                                                    edges?: Array<{
                                                                      __typename: "ContentPropertyEdge"
                                                                      node?: {
                                                                        __typename: "ContentProperty"
                                                                        name: string
                                                                        value?: any | null
                                                                        displayName: string
                                                                      } | null
                                                                    } | null> | null
                                                                  } | null
                                                                }
                                                              | { __typename: "ST4TreeNode" }
                                                              | null
                                                          }
                                                        | {
                                                            __typename: "NodeRef"
                                                            id: string
                                                            owner?:
                                                              | { __typename: "CommentNode" }
                                                              | { __typename: "ExternalSite" }
                                                              | { __typename: "ST4Class" }
                                                              | { __typename: "ST4Node"; id: string }
                                                              | { __typename: "ST4TreeNode" }
                                                              | null
                                                            target?:
                                                              | { __typename: "CommentNode" }
                                                              | {
                                                                  __typename: "ExternalSite"
                                                                  id: string
                                                                  teaserData?: {
                                                                    __typename: "ExternalLinkTeaserData"
                                                                    title?: string | null
                                                                    summary?: string | null
                                                                    iconUrl?: string | null
                                                                    imageUrl?: string | null
                                                                    status?: Types.LinkTargetStatus | null
                                                                  } | null
                                                                }
                                                              | { __typename: "ST4Class" }
                                                              | {
                                                                  __typename: "ST4Node"
                                                                  id: string
                                                                  aspectId: any
                                                                  label?: string | null
                                                                  properties?: {
                                                                    __typename: "ContentPropertyConnection"
                                                                    edges?: Array<{
                                                                      __typename: "ContentPropertyEdge"
                                                                      node?: {
                                                                        __typename: "ContentProperty"
                                                                        name: string
                                                                        value?: any | null
                                                                        displayName: string
                                                                      } | null
                                                                    } | null> | null
                                                                  } | null
                                                                }
                                                              | { __typename: "ST4TreeNode" }
                                                              | null
                                                          }
                                                        | null
                                                    }>
                                                  }
                                                | {
                                                    __typename: "VideoMediaInfo"
                                                    id: string
                                                    resourceId?: string | null
                                                    thumbnail?: string | null
                                                    width?: number | null
                                                    height?: number | null
                                                    mediaType?: Types.MediaType | null
                                                  }
                                                | null
                                            }
                                          | {
                                              __typename: "MediaGroupContent"
                                              media?:
                                                | {
                                                    __typename: "AnimationMediaInfo"
                                                    id: string
                                                    resourceId?: string | null
                                                    thumbnail?: string | null
                                                    width?: number | null
                                                    height?: number | null
                                                    mediaType?: Types.MediaType | null
                                                  }
                                                | {
                                                    __typename: "ImageMediaInfo"
                                                    id: string
                                                    resourceId?: string | null
                                                    thumbnail?: string | null
                                                    width?: number | null
                                                    height?: number | null
                                                    resolution?: number | null
                                                    colorDepth?: number | null
                                                    size?: number | null
                                                    mime?: string | null
                                                    extension?: string | null
                                                    allowCaching?: boolean | null
                                                    mediaType?: Types.MediaType | null
                                                    src?: string | null
                                                    language?: string | null
                                                  }
                                                | {
                                                    __typename: "NoMediaInfo"
                                                    id: string
                                                    mediaType?: Types.MediaType | null
                                                  }
                                                | {
                                                    __typename: "VectorGraphicsMediaInfo"
                                                    id: string
                                                    resourceId?: string | null
                                                    thumbnail?: string | null
                                                    width?: number | null
                                                    height?: number | null
                                                    allowCaching?: boolean | null
                                                    mediaType?: Types.MediaType | null
                                                    language?: string | null
                                                    labels: Array<{
                                                      __typename: "VectorGraphicsLabel"
                                                      id?: string | null
                                                      sequence?: number | null
                                                      autoValue?: string | null
                                                      text?: string | null
                                                      linkType?: Types.VectorGraphicsLabelLinkType | null
                                                      link?: string | null
                                                      ref?:
                                                        | {
                                                            __typename: "GenericRef"
                                                            id: string
                                                            owner?:
                                                              | { __typename: "CommentNode" }
                                                              | { __typename: "ExternalSite" }
                                                              | { __typename: "ST4Class" }
                                                              | { __typename: "ST4Node"; id: string }
                                                              | { __typename: "ST4TreeNode" }
                                                              | null
                                                            target?:
                                                              | { __typename: "CommentNode" }
                                                              | {
                                                                  __typename: "ExternalSite"
                                                                  id: string
                                                                  teaserData?: {
                                                                    __typename: "ExternalLinkTeaserData"
                                                                    title?: string | null
                                                                    summary?: string | null
                                                                    iconUrl?: string | null
                                                                    imageUrl?: string | null
                                                                    status?: Types.LinkTargetStatus | null
                                                                  } | null
                                                                }
                                                              | { __typename: "ST4Class" }
                                                              | {
                                                                  __typename: "ST4Node"
                                                                  id: string
                                                                  aspectId: any
                                                                  label?: string | null
                                                                  properties?: {
                                                                    __typename: "ContentPropertyConnection"
                                                                    edges?: Array<{
                                                                      __typename: "ContentPropertyEdge"
                                                                      node?: {
                                                                        __typename: "ContentProperty"
                                                                        name: string
                                                                        value?: any | null
                                                                        displayName: string
                                                                      } | null
                                                                    } | null> | null
                                                                  } | null
                                                                }
                                                              | { __typename: "ST4TreeNode" }
                                                              | null
                                                          }
                                                        | {
                                                            __typename: "NodeRef"
                                                            id: string
                                                            owner?:
                                                              | { __typename: "CommentNode" }
                                                              | { __typename: "ExternalSite" }
                                                              | { __typename: "ST4Class" }
                                                              | { __typename: "ST4Node"; id: string }
                                                              | { __typename: "ST4TreeNode" }
                                                              | null
                                                            target?:
                                                              | { __typename: "CommentNode" }
                                                              | {
                                                                  __typename: "ExternalSite"
                                                                  id: string
                                                                  teaserData?: {
                                                                    __typename: "ExternalLinkTeaserData"
                                                                    title?: string | null
                                                                    summary?: string | null
                                                                    iconUrl?: string | null
                                                                    imageUrl?: string | null
                                                                    status?: Types.LinkTargetStatus | null
                                                                  } | null
                                                                }
                                                              | { __typename: "ST4Class" }
                                                              | {
                                                                  __typename: "ST4Node"
                                                                  id: string
                                                                  aspectId: any
                                                                  label?: string | null
                                                                  properties?: {
                                                                    __typename: "ContentPropertyConnection"
                                                                    edges?: Array<{
                                                                      __typename: "ContentPropertyEdge"
                                                                      node?: {
                                                                        __typename: "ContentProperty"
                                                                        name: string
                                                                        value?: any | null
                                                                        displayName: string
                                                                      } | null
                                                                    } | null> | null
                                                                  } | null
                                                                }
                                                              | { __typename: "ST4TreeNode" }
                                                              | null
                                                          }
                                                        | null
                                                    }>
                                                  }
                                                | {
                                                    __typename: "VideoMediaInfo"
                                                    id: string
                                                    resourceId?: string | null
                                                    thumbnail?: string | null
                                                    width?: number | null
                                                    height?: number | null
                                                    mediaType?: Types.MediaType | null
                                                  }
                                                | null
                                            }
                                          | { __typename: "ProjectContent" }
                                          | { __typename: "TermContent" }
                                          | { __typename: "TextContent" }
                                          | { __typename: "TextGroupContent" }
                                          | { __typename: "VariableTableContent" }
                                          | null
                                      }
                                    | { __typename: "ST4TreeNode"; id: string }
                                    | null
                                }>
                                media?:
                                  | {
                                      __typename: "AnimationMediaInfo"
                                      id: string
                                      resourceId?: string | null
                                      thumbnail?: string | null
                                      width?: number | null
                                      height?: number | null
                                      mediaType?: Types.MediaType | null
                                    }
                                  | {
                                      __typename: "ImageMediaInfo"
                                      id: string
                                      resourceId?: string | null
                                      thumbnail?: string | null
                                      width?: number | null
                                      height?: number | null
                                      resolution?: number | null
                                      colorDepth?: number | null
                                      size?: number | null
                                      mime?: string | null
                                      extension?: string | null
                                      allowCaching?: boolean | null
                                      mediaType?: Types.MediaType | null
                                      src?: string | null
                                      language?: string | null
                                    }
                                  | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                                  | {
                                      __typename: "VectorGraphicsMediaInfo"
                                      id: string
                                      resourceId?: string | null
                                      thumbnail?: string | null
                                      width?: number | null
                                      height?: number | null
                                      allowCaching?: boolean | null
                                      mediaType?: Types.MediaType | null
                                      language?: string | null
                                      labels: Array<{
                                        __typename: "VectorGraphicsLabel"
                                        id?: string | null
                                        sequence?: number | null
                                        autoValue?: string | null
                                        text?: string | null
                                        linkType?: Types.VectorGraphicsLabelLinkType | null
                                        link?: string | null
                                        ref?:
                                          | {
                                              __typename: "GenericRef"
                                              id: string
                                              owner?:
                                                | { __typename: "CommentNode" }
                                                | { __typename: "ExternalSite" }
                                                | { __typename: "ST4Class" }
                                                | { __typename: "ST4Node"; id: string }
                                                | { __typename: "ST4TreeNode" }
                                                | null
                                              target?:
                                                | { __typename: "CommentNode" }
                                                | {
                                                    __typename: "ExternalSite"
                                                    id: string
                                                    teaserData?: {
                                                      __typename: "ExternalLinkTeaserData"
                                                      title?: string | null
                                                      summary?: string | null
                                                      iconUrl?: string | null
                                                      imageUrl?: string | null
                                                      status?: Types.LinkTargetStatus | null
                                                    } | null
                                                  }
                                                | { __typename: "ST4Class" }
                                                | {
                                                    __typename: "ST4Node"
                                                    id: string
                                                    aspectId: any
                                                    label?: string | null
                                                    properties?: {
                                                      __typename: "ContentPropertyConnection"
                                                      edges?: Array<{
                                                        __typename: "ContentPropertyEdge"
                                                        node?: {
                                                          __typename: "ContentProperty"
                                                          name: string
                                                          value?: any | null
                                                          displayName: string
                                                        } | null
                                                      } | null> | null
                                                    } | null
                                                  }
                                                | { __typename: "ST4TreeNode" }
                                                | null
                                            }
                                          | {
                                              __typename: "NodeRef"
                                              id: string
                                              owner?:
                                                | { __typename: "CommentNode" }
                                                | { __typename: "ExternalSite" }
                                                | { __typename: "ST4Class" }
                                                | { __typename: "ST4Node"; id: string }
                                                | { __typename: "ST4TreeNode" }
                                                | null
                                              target?:
                                                | { __typename: "CommentNode" }
                                                | {
                                                    __typename: "ExternalSite"
                                                    id: string
                                                    teaserData?: {
                                                      __typename: "ExternalLinkTeaserData"
                                                      title?: string | null
                                                      summary?: string | null
                                                      iconUrl?: string | null
                                                      imageUrl?: string | null
                                                      status?: Types.LinkTargetStatus | null
                                                    } | null
                                                  }
                                                | { __typename: "ST4Class" }
                                                | {
                                                    __typename: "ST4Node"
                                                    id: string
                                                    aspectId: any
                                                    label?: string | null
                                                    properties?: {
                                                      __typename: "ContentPropertyConnection"
                                                      edges?: Array<{
                                                        __typename: "ContentPropertyEdge"
                                                        node?: {
                                                          __typename: "ContentProperty"
                                                          name: string
                                                          value?: any | null
                                                          displayName: string
                                                        } | null
                                                      } | null> | null
                                                    } | null
                                                  }
                                                | { __typename: "ST4TreeNode" }
                                                | null
                                            }
                                          | null
                                      }>
                                    }
                                  | {
                                      __typename: "VideoMediaInfo"
                                      id: string
                                      resourceId?: string | null
                                      thumbnail?: string | null
                                      width?: number | null
                                      height?: number | null
                                      mediaType?: Types.MediaType | null
                                    }
                                  | null
                              }
                            | { __typename: "ProjectContent" }
                            | { __typename: "TermContent" }
                            | { __typename: "TextContent" }
                            | { __typename: "TextGroupContent" }
                            | { __typename: "VariableTableContent" }
                            | null
                        }
                      | { __typename: "ST4TreeNode"; id: string }
                      | null
                  }>
                  variableTables?: Array<{
                    __typename: "VariableTableReference"
                    ref?: string | null
                    node?:
                      | { __typename: "CommentNode" }
                      | { __typename: "ExternalSite" }
                      | { __typename: "ST4Class" }
                      | {
                          __typename: "ST4Node"
                          id: string
                          label?: string | null
                          aspectId: any
                          content?:
                            | { __typename: "LexiconContent" }
                            | { __typename: "LexiconEntryContent" }
                            | { __typename: "MediaContent" }
                            | { __typename: "MediaGroupContent" }
                            | { __typename: "ProjectContent" }
                            | { __typename: "TermContent" }
                            | { __typename: "TextContent" }
                            | { __typename: "TextGroupContent" }
                            | {
                                __typename: "VariableTableContent"
                                xml?: string | null
                                valueSets: Array<{ __typename: "ValueSet"; name: string; valueSetId: string }>
                                variables: Array<{
                                  __typename: "Variable"
                                  name: string
                                  variableId: string
                                  values: Array<string>
                                }>
                              }
                            | null
                        }
                      | { __typename: "ST4TreeNode" }
                      | null
                  } | null> | null
                  linkedDataNodes: Array<{
                    __typename: "NodeRef"
                    id: string
                    target?:
                      | { __typename: "CommentNode"; id: string }
                      | { __typename: "ExternalSite"; id: string }
                      | { __typename: "ST4Class"; id: string }
                      | {
                          __typename: "ST4Node"
                          label?: string | null
                          id: string
                          metadata: Array<{
                            __typename: "MetaDataEntry"
                            label?: string | null
                            systemName: string
                            value?: string | null
                            values: Array<string>
                            users: Array<string>
                          }>
                          linkedOntologyNodes: Array<
                            | {
                                __typename: "GenericRef"
                                target?:
                                  | { __typename: "CommentNode" }
                                  | { __typename: "ExternalSite" }
                                  | { __typename: "ST4Class" }
                                  | { __typename: "ST4Node"; id: string; label?: string | null }
                                  | { __typename: "ST4TreeNode" }
                                  | null
                              }
                            | {
                                __typename: "NodeRef"
                                linkClassName: string
                                class?: { __typename: "ST4Class"; label?: string | null } | null
                                target?:
                                  | { __typename: "CommentNode" }
                                  | { __typename: "ExternalSite" }
                                  | { __typename: "ST4Class" }
                                  | { __typename: "ST4Node"; id: string; label?: string | null }
                                  | { __typename: "ST4TreeNode" }
                                  | null
                              }
                          >
                        }
                      | { __typename: "ST4TreeNode"; id: string }
                      | null
                  }>
                  lexiconEntries?: Array<{
                    __typename: "NodeRef"
                    id: string
                    target?:
                      | { __typename: "CommentNode"; id: string }
                      | { __typename: "ExternalSite"; id: string }
                      | { __typename: "ST4Class"; id: string }
                      | {
                          __typename: "ST4Node"
                          aspectId: any
                          label?: string | null
                          id: string
                          content?:
                            | { __typename: "LexiconContent" }
                            | {
                                __typename: "LexiconEntryContent"
                                descriptionXast?: string | null
                                subject?: string | null
                                sortKey?: string | null
                                lexicon:
                                  | { __typename: "CommentNode"; id: string }
                                  | { __typename: "ExternalSite"; id: string }
                                  | { __typename: "ST4Class"; id: string }
                                  | { __typename: "ST4Node"; label?: string | null; id: string }
                                  | { __typename: "ST4TreeNode"; id: string }
                                terms: Array<
                                  | { __typename: "CommentNode"; id: string }
                                  | { __typename: "ExternalSite"; id: string }
                                  | { __typename: "ST4Class"; id: string }
                                  | {
                                      __typename: "ST4Node"
                                      id: string
                                      label?: string | null
                                      aspectId: any
                                      content?:
                                        | { __typename: "LexiconContent" }
                                        | { __typename: "LexiconEntryContent" }
                                        | { __typename: "MediaContent" }
                                        | { __typename: "MediaGroupContent" }
                                        | { __typename: "ProjectContent" }
                                        | {
                                            __typename: "TermContent"
                                            gramaticalGender?: string | null
                                            validity?: string | null
                                            editLanguage?: string | null
                                          }
                                        | { __typename: "TextContent" }
                                        | { __typename: "TextGroupContent" }
                                        | { __typename: "VariableTableContent" }
                                        | null
                                    }
                                  | { __typename: "ST4TreeNode"; id: string }
                                >
                                image?:
                                  | { __typename: "CommentNode"; id: string }
                                  | { __typename: "ExternalSite"; id: string }
                                  | { __typename: "ST4Class"; id: string }
                                  | {
                                      __typename: "ST4Node"
                                      aspectId: any
                                      id: string
                                      content?:
                                        | { __typename: "LexiconContent" }
                                        | { __typename: "LexiconEntryContent" }
                                        | {
                                            __typename: "MediaContent"
                                            media?:
                                              | {
                                                  __typename: "AnimationMediaInfo"
                                                  thumbnail?: string | null
                                                  mediaType?: Types.MediaType | null
                                                }
                                              | {
                                                  __typename: "ImageMediaInfo"
                                                  src?: string | null
                                                  thumbnail?: string | null
                                                  mediaType?: Types.MediaType | null
                                                }
                                              | { __typename: "NoMediaInfo" }
                                              | {
                                                  __typename: "VectorGraphicsMediaInfo"
                                                  svg?: string | null
                                                  thumbnail?: string | null
                                                  mediaType?: Types.MediaType | null
                                                }
                                              | {
                                                  __typename: "VideoMediaInfo"
                                                  thumbnail?: string | null
                                                  mediaType?: Types.MediaType | null
                                                }
                                              | null
                                          }
                                        | { __typename: "MediaGroupContent" }
                                        | { __typename: "ProjectContent" }
                                        | { __typename: "TermContent" }
                                        | { __typename: "TextContent" }
                                        | { __typename: "TextGroupContent" }
                                        | { __typename: "VariableTableContent" }
                                        | null
                                    }
                                  | { __typename: "ST4TreeNode"; id: string }
                                  | null
                              }
                            | { __typename: "MediaContent" }
                            | { __typename: "MediaGroupContent" }
                            | { __typename: "ProjectContent" }
                            | { __typename: "TermContent" }
                            | { __typename: "TextContent" }
                            | { __typename: "TextGroupContent" }
                            | { __typename: "VariableTableContent" }
                            | null
                        }
                      | { __typename: "ST4TreeNode"; id: string }
                      | null
                  } | null> | null
                  comments: Array<{
                    __typename: "CommentNode"
                    id: string
                    comment:
                      | {
                          __typename: "BlockComment"
                          location: number
                          value?: string | null
                          authorDisplayName?: string | null
                          authorUserName?: string | null
                          commentKey: string
                          editDate?: any | null
                          modificationVersion?: number | null
                          replyToKey?: string | null
                          sortOrder?: number | null
                          commentType?: string | null
                          state?: string | null
                          color: string
                          persistanceState?: Types.PersistanceState | null
                          persistanceAction?: Types.PersistanceAction | null
                          node: {
                            __typename: "ST4Node"
                            aspectId: any
                            id: string
                            content?:
                              | { __typename: "LexiconContent" }
                              | { __typename: "LexiconEntryContent" }
                              | { __typename: "MediaContent" }
                              | { __typename: "MediaGroupContent" }
                              | { __typename: "ProjectContent" }
                              | { __typename: "TermContent" }
                              | { __typename: "TextContent"; id: string; historyNumber: number }
                              | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                              | { __typename: "VariableTableContent" }
                              | null
                          }
                          actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
                        }
                      | {
                          __typename: "TextComment"
                          endLocation: number
                          startLocation?: number | null
                          value?: string | null
                          authorDisplayName?: string | null
                          authorUserName?: string | null
                          commentKey: string
                          editDate?: any | null
                          modificationVersion?: number | null
                          replyToKey?: string | null
                          sortOrder?: number | null
                          commentType?: string | null
                          state?: string | null
                          color: string
                          persistanceState?: Types.PersistanceState | null
                          persistanceAction?: Types.PersistanceAction | null
                          node: {
                            __typename: "ST4Node"
                            aspectId: any
                            id: string
                            content?:
                              | { __typename: "LexiconContent" }
                              | { __typename: "LexiconEntryContent" }
                              | { __typename: "MediaContent" }
                              | { __typename: "MediaGroupContent" }
                              | { __typename: "ProjectContent" }
                              | { __typename: "TermContent" }
                              | { __typename: "TextContent"; id: string; historyNumber: number }
                              | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                              | { __typename: "VariableTableContent" }
                              | null
                          }
                          actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
                        }
                  }>
                }
              | {
                  __typename: "TextGroupContent"
                  currentNodeId?: any | null
                  groupNodeId: any
                  id: string
                  xast?: string | null
                  historyNumber: number
                  parts: Array<{
                    __typename: "TextContentPart"
                    ref: string
                    contentNode: {
                      __typename: "ST4Node"
                      id: string
                      aspectId: any
                      content?:
                        | { __typename: "LexiconContent" }
                        | { __typename: "LexiconEntryContent" }
                        | { __typename: "MediaContent" }
                        | { __typename: "MediaGroupContent" }
                        | { __typename: "ProjectContent" }
                        | { __typename: "TermContent" }
                        | {
                            __typename: "TextContent"
                            id: string
                            xast?: string | null
                            historyNumber: number
                            links?: Array<{
                              __typename: "TextContentLink"
                              ref?: string | null
                              node?:
                                | { __typename: "CommentNode"; id: string }
                                | { __typename: "ExternalSite"; id: string }
                                | { __typename: "ST4Class"; id: string }
                                | {
                                    __typename: "ST4Node"
                                    id: string
                                    aspectId: any
                                    label?: string | null
                                    properties?: {
                                      __typename: "ContentPropertyConnection"
                                      totalCount: number
                                      edges?: Array<{
                                        __typename: "ContentPropertyEdge"
                                        node?: {
                                          __typename: "ContentProperty"
                                          typeOfValue: Types.ContentPropertyValueType
                                          name: string
                                          value?: any | null
                                          displayName: string
                                        } | null
                                      } | null> | null
                                    } | null
                                  }
                                | { __typename: "ST4TreeNode"; id: string }
                                | null
                            } | null> | null
                            images: Array<{
                              __typename: "NodeRef"
                              id: string
                              target?:
                                | { __typename: "CommentNode"; id: string }
                                | { __typename: "ExternalSite"; id: string }
                                | { __typename: "ST4Class"; id: string }
                                | {
                                    __typename: "ST4Node"
                                    aspectId: any
                                    id: string
                                    releaseState: {
                                      __typename: "ReleaseStateValue"
                                      displayName: string
                                      state: Types.ReleaseState
                                      isImplicitlyReleased: boolean
                                    }
                                    content?:
                                      | { __typename: "LexiconContent" }
                                      | { __typename: "LexiconEntryContent" }
                                      | {
                                          __typename: "MediaContent"
                                          media?:
                                            | {
                                                __typename: "AnimationMediaInfo"
                                                id: string
                                                resourceId?: string | null
                                                thumbnail?: string | null
                                                width?: number | null
                                                height?: number | null
                                                mediaType?: Types.MediaType | null
                                              }
                                            | {
                                                __typename: "ImageMediaInfo"
                                                id: string
                                                resourceId?: string | null
                                                thumbnail?: string | null
                                                width?: number | null
                                                height?: number | null
                                                resolution?: number | null
                                                colorDepth?: number | null
                                                size?: number | null
                                                mime?: string | null
                                                extension?: string | null
                                                allowCaching?: boolean | null
                                                mediaType?: Types.MediaType | null
                                                src?: string | null
                                                language?: string | null
                                              }
                                            | {
                                                __typename: "NoMediaInfo"
                                                id: string
                                                mediaType?: Types.MediaType | null
                                              }
                                            | {
                                                __typename: "VectorGraphicsMediaInfo"
                                                id: string
                                                resourceId?: string | null
                                                thumbnail?: string | null
                                                width?: number | null
                                                height?: number | null
                                                allowCaching?: boolean | null
                                                mediaType?: Types.MediaType | null
                                                language?: string | null
                                                labels: Array<{
                                                  __typename: "VectorGraphicsLabel"
                                                  id?: string | null
                                                  sequence?: number | null
                                                  autoValue?: string | null
                                                  text?: string | null
                                                  linkType?: Types.VectorGraphicsLabelLinkType | null
                                                  link?: string | null
                                                  ref?:
                                                    | {
                                                        __typename: "GenericRef"
                                                        id: string
                                                        owner?:
                                                          | { __typename: "CommentNode" }
                                                          | { __typename: "ExternalSite" }
                                                          | { __typename: "ST4Class" }
                                                          | { __typename: "ST4Node"; id: string }
                                                          | { __typename: "ST4TreeNode" }
                                                          | null
                                                        target?:
                                                          | { __typename: "CommentNode" }
                                                          | {
                                                              __typename: "ExternalSite"
                                                              id: string
                                                              teaserData?: {
                                                                __typename: "ExternalLinkTeaserData"
                                                                title?: string | null
                                                                summary?: string | null
                                                                iconUrl?: string | null
                                                                imageUrl?: string | null
                                                                status?: Types.LinkTargetStatus | null
                                                              } | null
                                                            }
                                                          | { __typename: "ST4Class" }
                                                          | {
                                                              __typename: "ST4Node"
                                                              id: string
                                                              aspectId: any
                                                              label?: string | null
                                                              properties?: {
                                                                __typename: "ContentPropertyConnection"
                                                                edges?: Array<{
                                                                  __typename: "ContentPropertyEdge"
                                                                  node?: {
                                                                    __typename: "ContentProperty"
                                                                    name: string
                                                                    value?: any | null
                                                                    displayName: string
                                                                  } | null
                                                                } | null> | null
                                                              } | null
                                                            }
                                                          | { __typename: "ST4TreeNode" }
                                                          | null
                                                      }
                                                    | {
                                                        __typename: "NodeRef"
                                                        id: string
                                                        owner?:
                                                          | { __typename: "CommentNode" }
                                                          | { __typename: "ExternalSite" }
                                                          | { __typename: "ST4Class" }
                                                          | { __typename: "ST4Node"; id: string }
                                                          | { __typename: "ST4TreeNode" }
                                                          | null
                                                        target?:
                                                          | { __typename: "CommentNode" }
                                                          | {
                                                              __typename: "ExternalSite"
                                                              id: string
                                                              teaserData?: {
                                                                __typename: "ExternalLinkTeaserData"
                                                                title?: string | null
                                                                summary?: string | null
                                                                iconUrl?: string | null
                                                                imageUrl?: string | null
                                                                status?: Types.LinkTargetStatus | null
                                                              } | null
                                                            }
                                                          | { __typename: "ST4Class" }
                                                          | {
                                                              __typename: "ST4Node"
                                                              id: string
                                                              aspectId: any
                                                              label?: string | null
                                                              properties?: {
                                                                __typename: "ContentPropertyConnection"
                                                                edges?: Array<{
                                                                  __typename: "ContentPropertyEdge"
                                                                  node?: {
                                                                    __typename: "ContentProperty"
                                                                    name: string
                                                                    value?: any | null
                                                                    displayName: string
                                                                  } | null
                                                                } | null> | null
                                                              } | null
                                                            }
                                                          | { __typename: "ST4TreeNode" }
                                                          | null
                                                      }
                                                    | null
                                                }>
                                              }
                                            | {
                                                __typename: "VideoMediaInfo"
                                                id: string
                                                resourceId?: string | null
                                                thumbnail?: string | null
                                                width?: number | null
                                                height?: number | null
                                                mediaType?: Types.MediaType | null
                                              }
                                            | null
                                        }
                                      | {
                                          __typename: "MediaGroupContent"
                                          children: Array<{
                                            __typename: "NodeRef"
                                            id: string
                                            target?:
                                              | { __typename: "CommentNode"; id: string }
                                              | { __typename: "ExternalSite"; id: string }
                                              | { __typename: "ST4Class"; id: string }
                                              | {
                                                  __typename: "ST4Node"
                                                  id: string
                                                  aspectId: any
                                                  content?:
                                                    | { __typename: "LexiconContent" }
                                                    | { __typename: "LexiconEntryContent" }
                                                    | {
                                                        __typename: "MediaContent"
                                                        media?:
                                                          | {
                                                              __typename: "AnimationMediaInfo"
                                                              id: string
                                                              resourceId?: string | null
                                                              thumbnail?: string | null
                                                              width?: number | null
                                                              height?: number | null
                                                              mediaType?: Types.MediaType | null
                                                            }
                                                          | {
                                                              __typename: "ImageMediaInfo"
                                                              id: string
                                                              resourceId?: string | null
                                                              thumbnail?: string | null
                                                              width?: number | null
                                                              height?: number | null
                                                              resolution?: number | null
                                                              colorDepth?: number | null
                                                              size?: number | null
                                                              mime?: string | null
                                                              extension?: string | null
                                                              allowCaching?: boolean | null
                                                              mediaType?: Types.MediaType | null
                                                              src?: string | null
                                                              language?: string | null
                                                            }
                                                          | {
                                                              __typename: "NoMediaInfo"
                                                              id: string
                                                              mediaType?: Types.MediaType | null
                                                            }
                                                          | {
                                                              __typename: "VectorGraphicsMediaInfo"
                                                              id: string
                                                              resourceId?: string | null
                                                              thumbnail?: string | null
                                                              width?: number | null
                                                              height?: number | null
                                                              allowCaching?: boolean | null
                                                              mediaType?: Types.MediaType | null
                                                              language?: string | null
                                                              labels: Array<{
                                                                __typename: "VectorGraphicsLabel"
                                                                id?: string | null
                                                                sequence?: number | null
                                                                autoValue?: string | null
                                                                text?: string | null
                                                                linkType?: Types.VectorGraphicsLabelLinkType | null
                                                                link?: string | null
                                                                ref?:
                                                                  | {
                                                                      __typename: "GenericRef"
                                                                      id: string
                                                                      owner?:
                                                                        | { __typename: "CommentNode" }
                                                                        | { __typename: "ExternalSite" }
                                                                        | { __typename: "ST4Class" }
                                                                        | { __typename: "ST4Node"; id: string }
                                                                        | { __typename: "ST4TreeNode" }
                                                                        | null
                                                                      target?:
                                                                        | { __typename: "CommentNode" }
                                                                        | {
                                                                            __typename: "ExternalSite"
                                                                            id: string
                                                                            teaserData?: {
                                                                              __typename: "ExternalLinkTeaserData"
                                                                              title?: string | null
                                                                              summary?: string | null
                                                                              iconUrl?: string | null
                                                                              imageUrl?: string | null
                                                                              status?: Types.LinkTargetStatus | null
                                                                            } | null
                                                                          }
                                                                        | { __typename: "ST4Class" }
                                                                        | {
                                                                            __typename: "ST4Node"
                                                                            id: string
                                                                            aspectId: any
                                                                            label?: string | null
                                                                            properties?: {
                                                                              __typename: "ContentPropertyConnection"
                                                                              edges?: Array<{
                                                                                __typename: "ContentPropertyEdge"
                                                                                node?: {
                                                                                  __typename: "ContentProperty"
                                                                                  name: string
                                                                                  value?: any | null
                                                                                  displayName: string
                                                                                } | null
                                                                              } | null> | null
                                                                            } | null
                                                                          }
                                                                        | { __typename: "ST4TreeNode" }
                                                                        | null
                                                                    }
                                                                  | {
                                                                      __typename: "NodeRef"
                                                                      id: string
                                                                      owner?:
                                                                        | { __typename: "CommentNode" }
                                                                        | { __typename: "ExternalSite" }
                                                                        | { __typename: "ST4Class" }
                                                                        | { __typename: "ST4Node"; id: string }
                                                                        | { __typename: "ST4TreeNode" }
                                                                        | null
                                                                      target?:
                                                                        | { __typename: "CommentNode" }
                                                                        | {
                                                                            __typename: "ExternalSite"
                                                                            id: string
                                                                            teaserData?: {
                                                                              __typename: "ExternalLinkTeaserData"
                                                                              title?: string | null
                                                                              summary?: string | null
                                                                              iconUrl?: string | null
                                                                              imageUrl?: string | null
                                                                              status?: Types.LinkTargetStatus | null
                                                                            } | null
                                                                          }
                                                                        | { __typename: "ST4Class" }
                                                                        | {
                                                                            __typename: "ST4Node"
                                                                            id: string
                                                                            aspectId: any
                                                                            label?: string | null
                                                                            properties?: {
                                                                              __typename: "ContentPropertyConnection"
                                                                              edges?: Array<{
                                                                                __typename: "ContentPropertyEdge"
                                                                                node?: {
                                                                                  __typename: "ContentProperty"
                                                                                  name: string
                                                                                  value?: any | null
                                                                                  displayName: string
                                                                                } | null
                                                                              } | null> | null
                                                                            } | null
                                                                          }
                                                                        | { __typename: "ST4TreeNode" }
                                                                        | null
                                                                    }
                                                                  | null
                                                              }>
                                                            }
                                                          | {
                                                              __typename: "VideoMediaInfo"
                                                              id: string
                                                              resourceId?: string | null
                                                              thumbnail?: string | null
                                                              width?: number | null
                                                              height?: number | null
                                                              mediaType?: Types.MediaType | null
                                                            }
                                                          | null
                                                      }
                                                    | {
                                                        __typename: "MediaGroupContent"
                                                        media?:
                                                          | {
                                                              __typename: "AnimationMediaInfo"
                                                              id: string
                                                              resourceId?: string | null
                                                              thumbnail?: string | null
                                                              width?: number | null
                                                              height?: number | null
                                                              mediaType?: Types.MediaType | null
                                                            }
                                                          | {
                                                              __typename: "ImageMediaInfo"
                                                              id: string
                                                              resourceId?: string | null
                                                              thumbnail?: string | null
                                                              width?: number | null
                                                              height?: number | null
                                                              resolution?: number | null
                                                              colorDepth?: number | null
                                                              size?: number | null
                                                              mime?: string | null
                                                              extension?: string | null
                                                              allowCaching?: boolean | null
                                                              mediaType?: Types.MediaType | null
                                                              src?: string | null
                                                              language?: string | null
                                                            }
                                                          | {
                                                              __typename: "NoMediaInfo"
                                                              id: string
                                                              mediaType?: Types.MediaType | null
                                                            }
                                                          | {
                                                              __typename: "VectorGraphicsMediaInfo"
                                                              id: string
                                                              resourceId?: string | null
                                                              thumbnail?: string | null
                                                              width?: number | null
                                                              height?: number | null
                                                              allowCaching?: boolean | null
                                                              mediaType?: Types.MediaType | null
                                                              language?: string | null
                                                              labels: Array<{
                                                                __typename: "VectorGraphicsLabel"
                                                                id?: string | null
                                                                sequence?: number | null
                                                                autoValue?: string | null
                                                                text?: string | null
                                                                linkType?: Types.VectorGraphicsLabelLinkType | null
                                                                link?: string | null
                                                                ref?:
                                                                  | {
                                                                      __typename: "GenericRef"
                                                                      id: string
                                                                      owner?:
                                                                        | { __typename: "CommentNode" }
                                                                        | { __typename: "ExternalSite" }
                                                                        | { __typename: "ST4Class" }
                                                                        | { __typename: "ST4Node"; id: string }
                                                                        | { __typename: "ST4TreeNode" }
                                                                        | null
                                                                      target?:
                                                                        | { __typename: "CommentNode" }
                                                                        | {
                                                                            __typename: "ExternalSite"
                                                                            id: string
                                                                            teaserData?: {
                                                                              __typename: "ExternalLinkTeaserData"
                                                                              title?: string | null
                                                                              summary?: string | null
                                                                              iconUrl?: string | null
                                                                              imageUrl?: string | null
                                                                              status?: Types.LinkTargetStatus | null
                                                                            } | null
                                                                          }
                                                                        | { __typename: "ST4Class" }
                                                                        | {
                                                                            __typename: "ST4Node"
                                                                            id: string
                                                                            aspectId: any
                                                                            label?: string | null
                                                                            properties?: {
                                                                              __typename: "ContentPropertyConnection"
                                                                              edges?: Array<{
                                                                                __typename: "ContentPropertyEdge"
                                                                                node?: {
                                                                                  __typename: "ContentProperty"
                                                                                  name: string
                                                                                  value?: any | null
                                                                                  displayName: string
                                                                                } | null
                                                                              } | null> | null
                                                                            } | null
                                                                          }
                                                                        | { __typename: "ST4TreeNode" }
                                                                        | null
                                                                    }
                                                                  | {
                                                                      __typename: "NodeRef"
                                                                      id: string
                                                                      owner?:
                                                                        | { __typename: "CommentNode" }
                                                                        | { __typename: "ExternalSite" }
                                                                        | { __typename: "ST4Class" }
                                                                        | { __typename: "ST4Node"; id: string }
                                                                        | { __typename: "ST4TreeNode" }
                                                                        | null
                                                                      target?:
                                                                        | { __typename: "CommentNode" }
                                                                        | {
                                                                            __typename: "ExternalSite"
                                                                            id: string
                                                                            teaserData?: {
                                                                              __typename: "ExternalLinkTeaserData"
                                                                              title?: string | null
                                                                              summary?: string | null
                                                                              iconUrl?: string | null
                                                                              imageUrl?: string | null
                                                                              status?: Types.LinkTargetStatus | null
                                                                            } | null
                                                                          }
                                                                        | { __typename: "ST4Class" }
                                                                        | {
                                                                            __typename: "ST4Node"
                                                                            id: string
                                                                            aspectId: any
                                                                            label?: string | null
                                                                            properties?: {
                                                                              __typename: "ContentPropertyConnection"
                                                                              edges?: Array<{
                                                                                __typename: "ContentPropertyEdge"
                                                                                node?: {
                                                                                  __typename: "ContentProperty"
                                                                                  name: string
                                                                                  value?: any | null
                                                                                  displayName: string
                                                                                } | null
                                                                              } | null> | null
                                                                            } | null
                                                                          }
                                                                        | { __typename: "ST4TreeNode" }
                                                                        | null
                                                                    }
                                                                  | null
                                                              }>
                                                            }
                                                          | {
                                                              __typename: "VideoMediaInfo"
                                                              id: string
                                                              resourceId?: string | null
                                                              thumbnail?: string | null
                                                              width?: number | null
                                                              height?: number | null
                                                              mediaType?: Types.MediaType | null
                                                            }
                                                          | null
                                                      }
                                                    | { __typename: "ProjectContent" }
                                                    | { __typename: "TermContent" }
                                                    | { __typename: "TextContent" }
                                                    | { __typename: "TextGroupContent" }
                                                    | { __typename: "VariableTableContent" }
                                                    | null
                                                }
                                              | { __typename: "ST4TreeNode"; id: string }
                                              | null
                                          }>
                                          media?:
                                            | {
                                                __typename: "AnimationMediaInfo"
                                                id: string
                                                resourceId?: string | null
                                                thumbnail?: string | null
                                                width?: number | null
                                                height?: number | null
                                                mediaType?: Types.MediaType | null
                                              }
                                            | {
                                                __typename: "ImageMediaInfo"
                                                id: string
                                                resourceId?: string | null
                                                thumbnail?: string | null
                                                width?: number | null
                                                height?: number | null
                                                resolution?: number | null
                                                colorDepth?: number | null
                                                size?: number | null
                                                mime?: string | null
                                                extension?: string | null
                                                allowCaching?: boolean | null
                                                mediaType?: Types.MediaType | null
                                                src?: string | null
                                                language?: string | null
                                              }
                                            | {
                                                __typename: "NoMediaInfo"
                                                id: string
                                                mediaType?: Types.MediaType | null
                                              }
                                            | {
                                                __typename: "VectorGraphicsMediaInfo"
                                                id: string
                                                resourceId?: string | null
                                                thumbnail?: string | null
                                                width?: number | null
                                                height?: number | null
                                                allowCaching?: boolean | null
                                                mediaType?: Types.MediaType | null
                                                language?: string | null
                                                labels: Array<{
                                                  __typename: "VectorGraphicsLabel"
                                                  id?: string | null
                                                  sequence?: number | null
                                                  autoValue?: string | null
                                                  text?: string | null
                                                  linkType?: Types.VectorGraphicsLabelLinkType | null
                                                  link?: string | null
                                                  ref?:
                                                    | {
                                                        __typename: "GenericRef"
                                                        id: string
                                                        owner?:
                                                          | { __typename: "CommentNode" }
                                                          | { __typename: "ExternalSite" }
                                                          | { __typename: "ST4Class" }
                                                          | { __typename: "ST4Node"; id: string }
                                                          | { __typename: "ST4TreeNode" }
                                                          | null
                                                        target?:
                                                          | { __typename: "CommentNode" }
                                                          | {
                                                              __typename: "ExternalSite"
                                                              id: string
                                                              teaserData?: {
                                                                __typename: "ExternalLinkTeaserData"
                                                                title?: string | null
                                                                summary?: string | null
                                                                iconUrl?: string | null
                                                                imageUrl?: string | null
                                                                status?: Types.LinkTargetStatus | null
                                                              } | null
                                                            }
                                                          | { __typename: "ST4Class" }
                                                          | {
                                                              __typename: "ST4Node"
                                                              id: string
                                                              aspectId: any
                                                              label?: string | null
                                                              properties?: {
                                                                __typename: "ContentPropertyConnection"
                                                                edges?: Array<{
                                                                  __typename: "ContentPropertyEdge"
                                                                  node?: {
                                                                    __typename: "ContentProperty"
                                                                    name: string
                                                                    value?: any | null
                                                                    displayName: string
                                                                  } | null
                                                                } | null> | null
                                                              } | null
                                                            }
                                                          | { __typename: "ST4TreeNode" }
                                                          | null
                                                      }
                                                    | {
                                                        __typename: "NodeRef"
                                                        id: string
                                                        owner?:
                                                          | { __typename: "CommentNode" }
                                                          | { __typename: "ExternalSite" }
                                                          | { __typename: "ST4Class" }
                                                          | { __typename: "ST4Node"; id: string }
                                                          | { __typename: "ST4TreeNode" }
                                                          | null
                                                        target?:
                                                          | { __typename: "CommentNode" }
                                                          | {
                                                              __typename: "ExternalSite"
                                                              id: string
                                                              teaserData?: {
                                                                __typename: "ExternalLinkTeaserData"
                                                                title?: string | null
                                                                summary?: string | null
                                                                iconUrl?: string | null
                                                                imageUrl?: string | null
                                                                status?: Types.LinkTargetStatus | null
                                                              } | null
                                                            }
                                                          | { __typename: "ST4Class" }
                                                          | {
                                                              __typename: "ST4Node"
                                                              id: string
                                                              aspectId: any
                                                              label?: string | null
                                                              properties?: {
                                                                __typename: "ContentPropertyConnection"
                                                                edges?: Array<{
                                                                  __typename: "ContentPropertyEdge"
                                                                  node?: {
                                                                    __typename: "ContentProperty"
                                                                    name: string
                                                                    value?: any | null
                                                                    displayName: string
                                                                  } | null
                                                                } | null> | null
                                                              } | null
                                                            }
                                                          | { __typename: "ST4TreeNode" }
                                                          | null
                                                      }
                                                    | null
                                                }>
                                              }
                                            | {
                                                __typename: "VideoMediaInfo"
                                                id: string
                                                resourceId?: string | null
                                                thumbnail?: string | null
                                                width?: number | null
                                                height?: number | null
                                                mediaType?: Types.MediaType | null
                                              }
                                            | null
                                        }
                                      | { __typename: "ProjectContent" }
                                      | { __typename: "TermContent" }
                                      | { __typename: "TextContent" }
                                      | { __typename: "TextGroupContent" }
                                      | { __typename: "VariableTableContent" }
                                      | null
                                  }
                                | { __typename: "ST4TreeNode"; id: string }
                                | null
                            }>
                            variableTables?: Array<{
                              __typename: "VariableTableReference"
                              ref?: string | null
                              node?:
                                | { __typename: "CommentNode" }
                                | { __typename: "ExternalSite" }
                                | { __typename: "ST4Class" }
                                | {
                                    __typename: "ST4Node"
                                    id: string
                                    label?: string | null
                                    aspectId: any
                                    content?:
                                      | { __typename: "LexiconContent" }
                                      | { __typename: "LexiconEntryContent" }
                                      | { __typename: "MediaContent" }
                                      | { __typename: "MediaGroupContent" }
                                      | { __typename: "ProjectContent" }
                                      | { __typename: "TermContent" }
                                      | { __typename: "TextContent" }
                                      | { __typename: "TextGroupContent" }
                                      | {
                                          __typename: "VariableTableContent"
                                          xml?: string | null
                                          valueSets: Array<{ __typename: "ValueSet"; name: string; valueSetId: string }>
                                          variables: Array<{
                                            __typename: "Variable"
                                            name: string
                                            variableId: string
                                            values: Array<string>
                                          }>
                                        }
                                      | null
                                  }
                                | { __typename: "ST4TreeNode" }
                                | null
                            } | null> | null
                            linkedDataNodes: Array<{
                              __typename: "NodeRef"
                              id: string
                              target?:
                                | { __typename: "CommentNode"; id: string }
                                | { __typename: "ExternalSite"; id: string }
                                | { __typename: "ST4Class"; id: string }
                                | {
                                    __typename: "ST4Node"
                                    label?: string | null
                                    id: string
                                    metadata: Array<{
                                      __typename: "MetaDataEntry"
                                      label?: string | null
                                      systemName: string
                                      value?: string | null
                                      values: Array<string>
                                      users: Array<string>
                                    }>
                                    linkedOntologyNodes: Array<
                                      | {
                                          __typename: "GenericRef"
                                          target?:
                                            | { __typename: "CommentNode" }
                                            | { __typename: "ExternalSite" }
                                            | { __typename: "ST4Class" }
                                            | { __typename: "ST4Node"; id: string; label?: string | null }
                                            | { __typename: "ST4TreeNode" }
                                            | null
                                        }
                                      | {
                                          __typename: "NodeRef"
                                          linkClassName: string
                                          class?: { __typename: "ST4Class"; label?: string | null } | null
                                          target?:
                                            | { __typename: "CommentNode" }
                                            | { __typename: "ExternalSite" }
                                            | { __typename: "ST4Class" }
                                            | { __typename: "ST4Node"; id: string; label?: string | null }
                                            | { __typename: "ST4TreeNode" }
                                            | null
                                        }
                                    >
                                  }
                                | { __typename: "ST4TreeNode"; id: string }
                                | null
                            }>
                            lexiconEntries?: Array<{
                              __typename: "NodeRef"
                              id: string
                              target?:
                                | { __typename: "CommentNode"; id: string }
                                | { __typename: "ExternalSite"; id: string }
                                | { __typename: "ST4Class"; id: string }
                                | {
                                    __typename: "ST4Node"
                                    aspectId: any
                                    label?: string | null
                                    id: string
                                    content?:
                                      | { __typename: "LexiconContent" }
                                      | {
                                          __typename: "LexiconEntryContent"
                                          descriptionXast?: string | null
                                          subject?: string | null
                                          sortKey?: string | null
                                          lexicon:
                                            | { __typename: "CommentNode"; id: string }
                                            | { __typename: "ExternalSite"; id: string }
                                            | { __typename: "ST4Class"; id: string }
                                            | { __typename: "ST4Node"; label?: string | null; id: string }
                                            | { __typename: "ST4TreeNode"; id: string }
                                          terms: Array<
                                            | { __typename: "CommentNode"; id: string }
                                            | { __typename: "ExternalSite"; id: string }
                                            | { __typename: "ST4Class"; id: string }
                                            | {
                                                __typename: "ST4Node"
                                                id: string
                                                label?: string | null
                                                aspectId: any
                                                content?:
                                                  | { __typename: "LexiconContent" }
                                                  | { __typename: "LexiconEntryContent" }
                                                  | { __typename: "MediaContent" }
                                                  | { __typename: "MediaGroupContent" }
                                                  | { __typename: "ProjectContent" }
                                                  | {
                                                      __typename: "TermContent"
                                                      gramaticalGender?: string | null
                                                      validity?: string | null
                                                      editLanguage?: string | null
                                                    }
                                                  | { __typename: "TextContent" }
                                                  | { __typename: "TextGroupContent" }
                                                  | { __typename: "VariableTableContent" }
                                                  | null
                                              }
                                            | { __typename: "ST4TreeNode"; id: string }
                                          >
                                          image?:
                                            | { __typename: "CommentNode"; id: string }
                                            | { __typename: "ExternalSite"; id: string }
                                            | { __typename: "ST4Class"; id: string }
                                            | {
                                                __typename: "ST4Node"
                                                aspectId: any
                                                id: string
                                                content?:
                                                  | { __typename: "LexiconContent" }
                                                  | { __typename: "LexiconEntryContent" }
                                                  | {
                                                      __typename: "MediaContent"
                                                      media?:
                                                        | {
                                                            __typename: "AnimationMediaInfo"
                                                            thumbnail?: string | null
                                                            mediaType?: Types.MediaType | null
                                                          }
                                                        | {
                                                            __typename: "ImageMediaInfo"
                                                            src?: string | null
                                                            thumbnail?: string | null
                                                            mediaType?: Types.MediaType | null
                                                          }
                                                        | { __typename: "NoMediaInfo" }
                                                        | {
                                                            __typename: "VectorGraphicsMediaInfo"
                                                            svg?: string | null
                                                            thumbnail?: string | null
                                                            mediaType?: Types.MediaType | null
                                                          }
                                                        | {
                                                            __typename: "VideoMediaInfo"
                                                            thumbnail?: string | null
                                                            mediaType?: Types.MediaType | null
                                                          }
                                                        | null
                                                    }
                                                  | { __typename: "MediaGroupContent" }
                                                  | { __typename: "ProjectContent" }
                                                  | { __typename: "TermContent" }
                                                  | { __typename: "TextContent" }
                                                  | { __typename: "TextGroupContent" }
                                                  | { __typename: "VariableTableContent" }
                                                  | null
                                              }
                                            | { __typename: "ST4TreeNode"; id: string }
                                            | null
                                        }
                                      | { __typename: "MediaContent" }
                                      | { __typename: "MediaGroupContent" }
                                      | { __typename: "ProjectContent" }
                                      | { __typename: "TermContent" }
                                      | { __typename: "TextContent" }
                                      | { __typename: "TextGroupContent" }
                                      | { __typename: "VariableTableContent" }
                                      | null
                                  }
                                | { __typename: "ST4TreeNode"; id: string }
                                | null
                            } | null> | null
                            comments: Array<{
                              __typename: "CommentNode"
                              id: string
                              comment:
                                | {
                                    __typename: "BlockComment"
                                    location: number
                                    value?: string | null
                                    authorDisplayName?: string | null
                                    authorUserName?: string | null
                                    commentKey: string
                                    editDate?: any | null
                                    modificationVersion?: number | null
                                    replyToKey?: string | null
                                    sortOrder?: number | null
                                    commentType?: string | null
                                    state?: string | null
                                    color: string
                                    persistanceState?: Types.PersistanceState | null
                                    persistanceAction?: Types.PersistanceAction | null
                                    node: {
                                      __typename: "ST4Node"
                                      aspectId: any
                                      id: string
                                      content?:
                                        | { __typename: "LexiconContent" }
                                        | { __typename: "LexiconEntryContent" }
                                        | { __typename: "MediaContent" }
                                        | { __typename: "MediaGroupContent" }
                                        | { __typename: "ProjectContent" }
                                        | { __typename: "TermContent" }
                                        | { __typename: "TextContent"; id: string; historyNumber: number }
                                        | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                                        | { __typename: "VariableTableContent" }
                                        | null
                                    }
                                    actions: Array<{
                                      __typename: "Action"
                                      name?: string | null
                                      enabled?: boolean | null
                                    }>
                                  }
                                | {
                                    __typename: "TextComment"
                                    endLocation: number
                                    startLocation?: number | null
                                    value?: string | null
                                    authorDisplayName?: string | null
                                    authorUserName?: string | null
                                    commentKey: string
                                    editDate?: any | null
                                    modificationVersion?: number | null
                                    replyToKey?: string | null
                                    sortOrder?: number | null
                                    commentType?: string | null
                                    state?: string | null
                                    color: string
                                    persistanceState?: Types.PersistanceState | null
                                    persistanceAction?: Types.PersistanceAction | null
                                    node: {
                                      __typename: "ST4Node"
                                      aspectId: any
                                      id: string
                                      content?:
                                        | { __typename: "LexiconContent" }
                                        | { __typename: "LexiconEntryContent" }
                                        | { __typename: "MediaContent" }
                                        | { __typename: "MediaGroupContent" }
                                        | { __typename: "ProjectContent" }
                                        | { __typename: "TermContent" }
                                        | { __typename: "TextContent"; id: string; historyNumber: number }
                                        | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                                        | { __typename: "VariableTableContent" }
                                        | null
                                    }
                                    actions: Array<{
                                      __typename: "Action"
                                      name?: string | null
                                      enabled?: boolean | null
                                    }>
                                  }
                            }>
                          }
                        | {
                            __typename: "TextGroupContent"
                            currentNodeId?: any | null
                            groupNodeId: any
                            id: string
                            xast?: string | null
                            historyNumber: number
                            children: Array<{
                              __typename: "NodeRef"
                              id: string
                              target?:
                                | { __typename: "CommentNode"; id: string }
                                | { __typename: "ExternalSite"; id: string }
                                | { __typename: "ST4Class"; id: string }
                                | { __typename: "ST4Node"; label?: string | null; aspectId: any; id: string }
                                | { __typename: "ST4TreeNode"; id: string }
                                | null
                            }>
                            links?: Array<{
                              __typename: "TextContentLink"
                              ref?: string | null
                              node?:
                                | { __typename: "CommentNode"; id: string }
                                | { __typename: "ExternalSite"; id: string }
                                | { __typename: "ST4Class"; id: string }
                                | {
                                    __typename: "ST4Node"
                                    id: string
                                    aspectId: any
                                    label?: string | null
                                    properties?: {
                                      __typename: "ContentPropertyConnection"
                                      totalCount: number
                                      edges?: Array<{
                                        __typename: "ContentPropertyEdge"
                                        node?: {
                                          __typename: "ContentProperty"
                                          typeOfValue: Types.ContentPropertyValueType
                                          name: string
                                          value?: any | null
                                          displayName: string
                                        } | null
                                      } | null> | null
                                    } | null
                                  }
                                | { __typename: "ST4TreeNode"; id: string }
                                | null
                            } | null> | null
                            images: Array<{
                              __typename: "NodeRef"
                              id: string
                              target?:
                                | { __typename: "CommentNode"; id: string }
                                | { __typename: "ExternalSite"; id: string }
                                | { __typename: "ST4Class"; id: string }
                                | {
                                    __typename: "ST4Node"
                                    aspectId: any
                                    id: string
                                    releaseState: {
                                      __typename: "ReleaseStateValue"
                                      displayName: string
                                      state: Types.ReleaseState
                                      isImplicitlyReleased: boolean
                                    }
                                    content?:
                                      | { __typename: "LexiconContent" }
                                      | { __typename: "LexiconEntryContent" }
                                      | {
                                          __typename: "MediaContent"
                                          media?:
                                            | {
                                                __typename: "AnimationMediaInfo"
                                                id: string
                                                resourceId?: string | null
                                                thumbnail?: string | null
                                                width?: number | null
                                                height?: number | null
                                                mediaType?: Types.MediaType | null
                                              }
                                            | {
                                                __typename: "ImageMediaInfo"
                                                id: string
                                                resourceId?: string | null
                                                thumbnail?: string | null
                                                width?: number | null
                                                height?: number | null
                                                resolution?: number | null
                                                colorDepth?: number | null
                                                size?: number | null
                                                mime?: string | null
                                                extension?: string | null
                                                allowCaching?: boolean | null
                                                mediaType?: Types.MediaType | null
                                                src?: string | null
                                                language?: string | null
                                              }
                                            | {
                                                __typename: "NoMediaInfo"
                                                id: string
                                                mediaType?: Types.MediaType | null
                                              }
                                            | {
                                                __typename: "VectorGraphicsMediaInfo"
                                                id: string
                                                resourceId?: string | null
                                                thumbnail?: string | null
                                                width?: number | null
                                                height?: number | null
                                                allowCaching?: boolean | null
                                                mediaType?: Types.MediaType | null
                                                language?: string | null
                                                labels: Array<{
                                                  __typename: "VectorGraphicsLabel"
                                                  id?: string | null
                                                  sequence?: number | null
                                                  autoValue?: string | null
                                                  text?: string | null
                                                  linkType?: Types.VectorGraphicsLabelLinkType | null
                                                  link?: string | null
                                                  ref?:
                                                    | {
                                                        __typename: "GenericRef"
                                                        id: string
                                                        owner?:
                                                          | { __typename: "CommentNode" }
                                                          | { __typename: "ExternalSite" }
                                                          | { __typename: "ST4Class" }
                                                          | { __typename: "ST4Node"; id: string }
                                                          | { __typename: "ST4TreeNode" }
                                                          | null
                                                        target?:
                                                          | { __typename: "CommentNode" }
                                                          | {
                                                              __typename: "ExternalSite"
                                                              id: string
                                                              teaserData?: {
                                                                __typename: "ExternalLinkTeaserData"
                                                                title?: string | null
                                                                summary?: string | null
                                                                iconUrl?: string | null
                                                                imageUrl?: string | null
                                                                status?: Types.LinkTargetStatus | null
                                                              } | null
                                                            }
                                                          | { __typename: "ST4Class" }
                                                          | {
                                                              __typename: "ST4Node"
                                                              id: string
                                                              aspectId: any
                                                              label?: string | null
                                                              properties?: {
                                                                __typename: "ContentPropertyConnection"
                                                                edges?: Array<{
                                                                  __typename: "ContentPropertyEdge"
                                                                  node?: {
                                                                    __typename: "ContentProperty"
                                                                    name: string
                                                                    value?: any | null
                                                                    displayName: string
                                                                  } | null
                                                                } | null> | null
                                                              } | null
                                                            }
                                                          | { __typename: "ST4TreeNode" }
                                                          | null
                                                      }
                                                    | {
                                                        __typename: "NodeRef"
                                                        id: string
                                                        owner?:
                                                          | { __typename: "CommentNode" }
                                                          | { __typename: "ExternalSite" }
                                                          | { __typename: "ST4Class" }
                                                          | { __typename: "ST4Node"; id: string }
                                                          | { __typename: "ST4TreeNode" }
                                                          | null
                                                        target?:
                                                          | { __typename: "CommentNode" }
                                                          | {
                                                              __typename: "ExternalSite"
                                                              id: string
                                                              teaserData?: {
                                                                __typename: "ExternalLinkTeaserData"
                                                                title?: string | null
                                                                summary?: string | null
                                                                iconUrl?: string | null
                                                                imageUrl?: string | null
                                                                status?: Types.LinkTargetStatus | null
                                                              } | null
                                                            }
                                                          | { __typename: "ST4Class" }
                                                          | {
                                                              __typename: "ST4Node"
                                                              id: string
                                                              aspectId: any
                                                              label?: string | null
                                                              properties?: {
                                                                __typename: "ContentPropertyConnection"
                                                                edges?: Array<{
                                                                  __typename: "ContentPropertyEdge"
                                                                  node?: {
                                                                    __typename: "ContentProperty"
                                                                    name: string
                                                                    value?: any | null
                                                                    displayName: string
                                                                  } | null
                                                                } | null> | null
                                                              } | null
                                                            }
                                                          | { __typename: "ST4TreeNode" }
                                                          | null
                                                      }
                                                    | null
                                                }>
                                              }
                                            | {
                                                __typename: "VideoMediaInfo"
                                                id: string
                                                resourceId?: string | null
                                                thumbnail?: string | null
                                                width?: number | null
                                                height?: number | null
                                                mediaType?: Types.MediaType | null
                                              }
                                            | null
                                        }
                                      | {
                                          __typename: "MediaGroupContent"
                                          children: Array<{
                                            __typename: "NodeRef"
                                            id: string
                                            target?:
                                              | { __typename: "CommentNode"; id: string }
                                              | { __typename: "ExternalSite"; id: string }
                                              | { __typename: "ST4Class"; id: string }
                                              | {
                                                  __typename: "ST4Node"
                                                  id: string
                                                  aspectId: any
                                                  content?:
                                                    | { __typename: "LexiconContent" }
                                                    | { __typename: "LexiconEntryContent" }
                                                    | {
                                                        __typename: "MediaContent"
                                                        media?:
                                                          | {
                                                              __typename: "AnimationMediaInfo"
                                                              id: string
                                                              resourceId?: string | null
                                                              thumbnail?: string | null
                                                              width?: number | null
                                                              height?: number | null
                                                              mediaType?: Types.MediaType | null
                                                            }
                                                          | {
                                                              __typename: "ImageMediaInfo"
                                                              id: string
                                                              resourceId?: string | null
                                                              thumbnail?: string | null
                                                              width?: number | null
                                                              height?: number | null
                                                              resolution?: number | null
                                                              colorDepth?: number | null
                                                              size?: number | null
                                                              mime?: string | null
                                                              extension?: string | null
                                                              allowCaching?: boolean | null
                                                              mediaType?: Types.MediaType | null
                                                              src?: string | null
                                                              language?: string | null
                                                            }
                                                          | {
                                                              __typename: "NoMediaInfo"
                                                              id: string
                                                              mediaType?: Types.MediaType | null
                                                            }
                                                          | {
                                                              __typename: "VectorGraphicsMediaInfo"
                                                              id: string
                                                              resourceId?: string | null
                                                              thumbnail?: string | null
                                                              width?: number | null
                                                              height?: number | null
                                                              allowCaching?: boolean | null
                                                              mediaType?: Types.MediaType | null
                                                              language?: string | null
                                                              labels: Array<{
                                                                __typename: "VectorGraphicsLabel"
                                                                id?: string | null
                                                                sequence?: number | null
                                                                autoValue?: string | null
                                                                text?: string | null
                                                                linkType?: Types.VectorGraphicsLabelLinkType | null
                                                                link?: string | null
                                                                ref?:
                                                                  | {
                                                                      __typename: "GenericRef"
                                                                      id: string
                                                                      owner?:
                                                                        | { __typename: "CommentNode" }
                                                                        | { __typename: "ExternalSite" }
                                                                        | { __typename: "ST4Class" }
                                                                        | { __typename: "ST4Node"; id: string }
                                                                        | { __typename: "ST4TreeNode" }
                                                                        | null
                                                                      target?:
                                                                        | { __typename: "CommentNode" }
                                                                        | {
                                                                            __typename: "ExternalSite"
                                                                            id: string
                                                                            teaserData?: {
                                                                              __typename: "ExternalLinkTeaserData"
                                                                              title?: string | null
                                                                              summary?: string | null
                                                                              iconUrl?: string | null
                                                                              imageUrl?: string | null
                                                                              status?: Types.LinkTargetStatus | null
                                                                            } | null
                                                                          }
                                                                        | { __typename: "ST4Class" }
                                                                        | {
                                                                            __typename: "ST4Node"
                                                                            id: string
                                                                            aspectId: any
                                                                            label?: string | null
                                                                            properties?: {
                                                                              __typename: "ContentPropertyConnection"
                                                                              edges?: Array<{
                                                                                __typename: "ContentPropertyEdge"
                                                                                node?: {
                                                                                  __typename: "ContentProperty"
                                                                                  name: string
                                                                                  value?: any | null
                                                                                  displayName: string
                                                                                } | null
                                                                              } | null> | null
                                                                            } | null
                                                                          }
                                                                        | { __typename: "ST4TreeNode" }
                                                                        | null
                                                                    }
                                                                  | {
                                                                      __typename: "NodeRef"
                                                                      id: string
                                                                      owner?:
                                                                        | { __typename: "CommentNode" }
                                                                        | { __typename: "ExternalSite" }
                                                                        | { __typename: "ST4Class" }
                                                                        | { __typename: "ST4Node"; id: string }
                                                                        | { __typename: "ST4TreeNode" }
                                                                        | null
                                                                      target?:
                                                                        | { __typename: "CommentNode" }
                                                                        | {
                                                                            __typename: "ExternalSite"
                                                                            id: string
                                                                            teaserData?: {
                                                                              __typename: "ExternalLinkTeaserData"
                                                                              title?: string | null
                                                                              summary?: string | null
                                                                              iconUrl?: string | null
                                                                              imageUrl?: string | null
                                                                              status?: Types.LinkTargetStatus | null
                                                                            } | null
                                                                          }
                                                                        | { __typename: "ST4Class" }
                                                                        | {
                                                                            __typename: "ST4Node"
                                                                            id: string
                                                                            aspectId: any
                                                                            label?: string | null
                                                                            properties?: {
                                                                              __typename: "ContentPropertyConnection"
                                                                              edges?: Array<{
                                                                                __typename: "ContentPropertyEdge"
                                                                                node?: {
                                                                                  __typename: "ContentProperty"
                                                                                  name: string
                                                                                  value?: any | null
                                                                                  displayName: string
                                                                                } | null
                                                                              } | null> | null
                                                                            } | null
                                                                          }
                                                                        | { __typename: "ST4TreeNode" }
                                                                        | null
                                                                    }
                                                                  | null
                                                              }>
                                                            }
                                                          | {
                                                              __typename: "VideoMediaInfo"
                                                              id: string
                                                              resourceId?: string | null
                                                              thumbnail?: string | null
                                                              width?: number | null
                                                              height?: number | null
                                                              mediaType?: Types.MediaType | null
                                                            }
                                                          | null
                                                      }
                                                    | {
                                                        __typename: "MediaGroupContent"
                                                        media?:
                                                          | {
                                                              __typename: "AnimationMediaInfo"
                                                              id: string
                                                              resourceId?: string | null
                                                              thumbnail?: string | null
                                                              width?: number | null
                                                              height?: number | null
                                                              mediaType?: Types.MediaType | null
                                                            }
                                                          | {
                                                              __typename: "ImageMediaInfo"
                                                              id: string
                                                              resourceId?: string | null
                                                              thumbnail?: string | null
                                                              width?: number | null
                                                              height?: number | null
                                                              resolution?: number | null
                                                              colorDepth?: number | null
                                                              size?: number | null
                                                              mime?: string | null
                                                              extension?: string | null
                                                              allowCaching?: boolean | null
                                                              mediaType?: Types.MediaType | null
                                                              src?: string | null
                                                              language?: string | null
                                                            }
                                                          | {
                                                              __typename: "NoMediaInfo"
                                                              id: string
                                                              mediaType?: Types.MediaType | null
                                                            }
                                                          | {
                                                              __typename: "VectorGraphicsMediaInfo"
                                                              id: string
                                                              resourceId?: string | null
                                                              thumbnail?: string | null
                                                              width?: number | null
                                                              height?: number | null
                                                              allowCaching?: boolean | null
                                                              mediaType?: Types.MediaType | null
                                                              language?: string | null
                                                              labels: Array<{
                                                                __typename: "VectorGraphicsLabel"
                                                                id?: string | null
                                                                sequence?: number | null
                                                                autoValue?: string | null
                                                                text?: string | null
                                                                linkType?: Types.VectorGraphicsLabelLinkType | null
                                                                link?: string | null
                                                                ref?:
                                                                  | {
                                                                      __typename: "GenericRef"
                                                                      id: string
                                                                      owner?:
                                                                        | { __typename: "CommentNode" }
                                                                        | { __typename: "ExternalSite" }
                                                                        | { __typename: "ST4Class" }
                                                                        | { __typename: "ST4Node"; id: string }
                                                                        | { __typename: "ST4TreeNode" }
                                                                        | null
                                                                      target?:
                                                                        | { __typename: "CommentNode" }
                                                                        | {
                                                                            __typename: "ExternalSite"
                                                                            id: string
                                                                            teaserData?: {
                                                                              __typename: "ExternalLinkTeaserData"
                                                                              title?: string | null
                                                                              summary?: string | null
                                                                              iconUrl?: string | null
                                                                              imageUrl?: string | null
                                                                              status?: Types.LinkTargetStatus | null
                                                                            } | null
                                                                          }
                                                                        | { __typename: "ST4Class" }
                                                                        | {
                                                                            __typename: "ST4Node"
                                                                            id: string
                                                                            aspectId: any
                                                                            label?: string | null
                                                                            properties?: {
                                                                              __typename: "ContentPropertyConnection"
                                                                              edges?: Array<{
                                                                                __typename: "ContentPropertyEdge"
                                                                                node?: {
                                                                                  __typename: "ContentProperty"
                                                                                  name: string
                                                                                  value?: any | null
                                                                                  displayName: string
                                                                                } | null
                                                                              } | null> | null
                                                                            } | null
                                                                          }
                                                                        | { __typename: "ST4TreeNode" }
                                                                        | null
                                                                    }
                                                                  | {
                                                                      __typename: "NodeRef"
                                                                      id: string
                                                                      owner?:
                                                                        | { __typename: "CommentNode" }
                                                                        | { __typename: "ExternalSite" }
                                                                        | { __typename: "ST4Class" }
                                                                        | { __typename: "ST4Node"; id: string }
                                                                        | { __typename: "ST4TreeNode" }
                                                                        | null
                                                                      target?:
                                                                        | { __typename: "CommentNode" }
                                                                        | {
                                                                            __typename: "ExternalSite"
                                                                            id: string
                                                                            teaserData?: {
                                                                              __typename: "ExternalLinkTeaserData"
                                                                              title?: string | null
                                                                              summary?: string | null
                                                                              iconUrl?: string | null
                                                                              imageUrl?: string | null
                                                                              status?: Types.LinkTargetStatus | null
                                                                            } | null
                                                                          }
                                                                        | { __typename: "ST4Class" }
                                                                        | {
                                                                            __typename: "ST4Node"
                                                                            id: string
                                                                            aspectId: any
                                                                            label?: string | null
                                                                            properties?: {
                                                                              __typename: "ContentPropertyConnection"
                                                                              edges?: Array<{
                                                                                __typename: "ContentPropertyEdge"
                                                                                node?: {
                                                                                  __typename: "ContentProperty"
                                                                                  name: string
                                                                                  value?: any | null
                                                                                  displayName: string
                                                                                } | null
                                                                              } | null> | null
                                                                            } | null
                                                                          }
                                                                        | { __typename: "ST4TreeNode" }
                                                                        | null
                                                                    }
                                                                  | null
                                                              }>
                                                            }
                                                          | {
                                                              __typename: "VideoMediaInfo"
                                                              id: string
                                                              resourceId?: string | null
                                                              thumbnail?: string | null
                                                              width?: number | null
                                                              height?: number | null
                                                              mediaType?: Types.MediaType | null
                                                            }
                                                          | null
                                                      }
                                                    | { __typename: "ProjectContent" }
                                                    | { __typename: "TermContent" }
                                                    | { __typename: "TextContent" }
                                                    | { __typename: "TextGroupContent" }
                                                    | { __typename: "VariableTableContent" }
                                                    | null
                                                }
                                              | { __typename: "ST4TreeNode"; id: string }
                                              | null
                                          }>
                                          media?:
                                            | {
                                                __typename: "AnimationMediaInfo"
                                                id: string
                                                resourceId?: string | null
                                                thumbnail?: string | null
                                                width?: number | null
                                                height?: number | null
                                                mediaType?: Types.MediaType | null
                                              }
                                            | {
                                                __typename: "ImageMediaInfo"
                                                id: string
                                                resourceId?: string | null
                                                thumbnail?: string | null
                                                width?: number | null
                                                height?: number | null
                                                resolution?: number | null
                                                colorDepth?: number | null
                                                size?: number | null
                                                mime?: string | null
                                                extension?: string | null
                                                allowCaching?: boolean | null
                                                mediaType?: Types.MediaType | null
                                                src?: string | null
                                                language?: string | null
                                              }
                                            | {
                                                __typename: "NoMediaInfo"
                                                id: string
                                                mediaType?: Types.MediaType | null
                                              }
                                            | {
                                                __typename: "VectorGraphicsMediaInfo"
                                                id: string
                                                resourceId?: string | null
                                                thumbnail?: string | null
                                                width?: number | null
                                                height?: number | null
                                                allowCaching?: boolean | null
                                                mediaType?: Types.MediaType | null
                                                language?: string | null
                                                labels: Array<{
                                                  __typename: "VectorGraphicsLabel"
                                                  id?: string | null
                                                  sequence?: number | null
                                                  autoValue?: string | null
                                                  text?: string | null
                                                  linkType?: Types.VectorGraphicsLabelLinkType | null
                                                  link?: string | null
                                                  ref?:
                                                    | {
                                                        __typename: "GenericRef"
                                                        id: string
                                                        owner?:
                                                          | { __typename: "CommentNode" }
                                                          | { __typename: "ExternalSite" }
                                                          | { __typename: "ST4Class" }
                                                          | { __typename: "ST4Node"; id: string }
                                                          | { __typename: "ST4TreeNode" }
                                                          | null
                                                        target?:
                                                          | { __typename: "CommentNode" }
                                                          | {
                                                              __typename: "ExternalSite"
                                                              id: string
                                                              teaserData?: {
                                                                __typename: "ExternalLinkTeaserData"
                                                                title?: string | null
                                                                summary?: string | null
                                                                iconUrl?: string | null
                                                                imageUrl?: string | null
                                                                status?: Types.LinkTargetStatus | null
                                                              } | null
                                                            }
                                                          | { __typename: "ST4Class" }
                                                          | {
                                                              __typename: "ST4Node"
                                                              id: string
                                                              aspectId: any
                                                              label?: string | null
                                                              properties?: {
                                                                __typename: "ContentPropertyConnection"
                                                                edges?: Array<{
                                                                  __typename: "ContentPropertyEdge"
                                                                  node?: {
                                                                    __typename: "ContentProperty"
                                                                    name: string
                                                                    value?: any | null
                                                                    displayName: string
                                                                  } | null
                                                                } | null> | null
                                                              } | null
                                                            }
                                                          | { __typename: "ST4TreeNode" }
                                                          | null
                                                      }
                                                    | {
                                                        __typename: "NodeRef"
                                                        id: string
                                                        owner?:
                                                          | { __typename: "CommentNode" }
                                                          | { __typename: "ExternalSite" }
                                                          | { __typename: "ST4Class" }
                                                          | { __typename: "ST4Node"; id: string }
                                                          | { __typename: "ST4TreeNode" }
                                                          | null
                                                        target?:
                                                          | { __typename: "CommentNode" }
                                                          | {
                                                              __typename: "ExternalSite"
                                                              id: string
                                                              teaserData?: {
                                                                __typename: "ExternalLinkTeaserData"
                                                                title?: string | null
                                                                summary?: string | null
                                                                iconUrl?: string | null
                                                                imageUrl?: string | null
                                                                status?: Types.LinkTargetStatus | null
                                                              } | null
                                                            }
                                                          | { __typename: "ST4Class" }
                                                          | {
                                                              __typename: "ST4Node"
                                                              id: string
                                                              aspectId: any
                                                              label?: string | null
                                                              properties?: {
                                                                __typename: "ContentPropertyConnection"
                                                                edges?: Array<{
                                                                  __typename: "ContentPropertyEdge"
                                                                  node?: {
                                                                    __typename: "ContentProperty"
                                                                    name: string
                                                                    value?: any | null
                                                                    displayName: string
                                                                  } | null
                                                                } | null> | null
                                                              } | null
                                                            }
                                                          | { __typename: "ST4TreeNode" }
                                                          | null
                                                      }
                                                    | null
                                                }>
                                              }
                                            | {
                                                __typename: "VideoMediaInfo"
                                                id: string
                                                resourceId?: string | null
                                                thumbnail?: string | null
                                                width?: number | null
                                                height?: number | null
                                                mediaType?: Types.MediaType | null
                                              }
                                            | null
                                        }
                                      | { __typename: "ProjectContent" }
                                      | { __typename: "TermContent" }
                                      | { __typename: "TextContent" }
                                      | { __typename: "TextGroupContent" }
                                      | { __typename: "VariableTableContent" }
                                      | null
                                  }
                                | { __typename: "ST4TreeNode"; id: string }
                                | null
                            }>
                            variableTables?: Array<{
                              __typename: "VariableTableReference"
                              ref?: string | null
                              node?:
                                | { __typename: "CommentNode" }
                                | { __typename: "ExternalSite" }
                                | { __typename: "ST4Class" }
                                | {
                                    __typename: "ST4Node"
                                    id: string
                                    label?: string | null
                                    aspectId: any
                                    content?:
                                      | { __typename: "LexiconContent" }
                                      | { __typename: "LexiconEntryContent" }
                                      | { __typename: "MediaContent" }
                                      | { __typename: "MediaGroupContent" }
                                      | { __typename: "ProjectContent" }
                                      | { __typename: "TermContent" }
                                      | { __typename: "TextContent" }
                                      | { __typename: "TextGroupContent" }
                                      | {
                                          __typename: "VariableTableContent"
                                          xml?: string | null
                                          valueSets: Array<{ __typename: "ValueSet"; name: string; valueSetId: string }>
                                          variables: Array<{
                                            __typename: "Variable"
                                            name: string
                                            variableId: string
                                            values: Array<string>
                                          }>
                                        }
                                      | null
                                  }
                                | { __typename: "ST4TreeNode" }
                                | null
                            } | null> | null
                            linkedDataNodes: Array<{
                              __typename: "NodeRef"
                              id: string
                              target?:
                                | { __typename: "CommentNode"; id: string }
                                | { __typename: "ExternalSite"; id: string }
                                | { __typename: "ST4Class"; id: string }
                                | {
                                    __typename: "ST4Node"
                                    label?: string | null
                                    id: string
                                    metadata: Array<{
                                      __typename: "MetaDataEntry"
                                      label?: string | null
                                      systemName: string
                                      value?: string | null
                                      values: Array<string>
                                      users: Array<string>
                                    }>
                                    linkedOntologyNodes: Array<
                                      | {
                                          __typename: "GenericRef"
                                          target?:
                                            | { __typename: "CommentNode" }
                                            | { __typename: "ExternalSite" }
                                            | { __typename: "ST4Class" }
                                            | { __typename: "ST4Node"; id: string; label?: string | null }
                                            | { __typename: "ST4TreeNode" }
                                            | null
                                        }
                                      | {
                                          __typename: "NodeRef"
                                          linkClassName: string
                                          class?: { __typename: "ST4Class"; label?: string | null } | null
                                          target?:
                                            | { __typename: "CommentNode" }
                                            | { __typename: "ExternalSite" }
                                            | { __typename: "ST4Class" }
                                            | { __typename: "ST4Node"; id: string; label?: string | null }
                                            | { __typename: "ST4TreeNode" }
                                            | null
                                        }
                                    >
                                  }
                                | { __typename: "ST4TreeNode"; id: string }
                                | null
                            }>
                            lexiconEntries?: Array<{
                              __typename: "NodeRef"
                              id: string
                              target?:
                                | { __typename: "CommentNode"; id: string }
                                | { __typename: "ExternalSite"; id: string }
                                | { __typename: "ST4Class"; id: string }
                                | {
                                    __typename: "ST4Node"
                                    aspectId: any
                                    label?: string | null
                                    id: string
                                    content?:
                                      | { __typename: "LexiconContent" }
                                      | {
                                          __typename: "LexiconEntryContent"
                                          descriptionXast?: string | null
                                          subject?: string | null
                                          sortKey?: string | null
                                          lexicon:
                                            | { __typename: "CommentNode"; id: string }
                                            | { __typename: "ExternalSite"; id: string }
                                            | { __typename: "ST4Class"; id: string }
                                            | { __typename: "ST4Node"; label?: string | null; id: string }
                                            | { __typename: "ST4TreeNode"; id: string }
                                          terms: Array<
                                            | { __typename: "CommentNode"; id: string }
                                            | { __typename: "ExternalSite"; id: string }
                                            | { __typename: "ST4Class"; id: string }
                                            | {
                                                __typename: "ST4Node"
                                                id: string
                                                label?: string | null
                                                aspectId: any
                                                content?:
                                                  | { __typename: "LexiconContent" }
                                                  | { __typename: "LexiconEntryContent" }
                                                  | { __typename: "MediaContent" }
                                                  | { __typename: "MediaGroupContent" }
                                                  | { __typename: "ProjectContent" }
                                                  | {
                                                      __typename: "TermContent"
                                                      gramaticalGender?: string | null
                                                      validity?: string | null
                                                      editLanguage?: string | null
                                                    }
                                                  | { __typename: "TextContent" }
                                                  | { __typename: "TextGroupContent" }
                                                  | { __typename: "VariableTableContent" }
                                                  | null
                                              }
                                            | { __typename: "ST4TreeNode"; id: string }
                                          >
                                          image?:
                                            | { __typename: "CommentNode"; id: string }
                                            | { __typename: "ExternalSite"; id: string }
                                            | { __typename: "ST4Class"; id: string }
                                            | {
                                                __typename: "ST4Node"
                                                aspectId: any
                                                id: string
                                                content?:
                                                  | { __typename: "LexiconContent" }
                                                  | { __typename: "LexiconEntryContent" }
                                                  | {
                                                      __typename: "MediaContent"
                                                      media?:
                                                        | {
                                                            __typename: "AnimationMediaInfo"
                                                            thumbnail?: string | null
                                                            mediaType?: Types.MediaType | null
                                                          }
                                                        | {
                                                            __typename: "ImageMediaInfo"
                                                            src?: string | null
                                                            thumbnail?: string | null
                                                            mediaType?: Types.MediaType | null
                                                          }
                                                        | { __typename: "NoMediaInfo" }
                                                        | {
                                                            __typename: "VectorGraphicsMediaInfo"
                                                            svg?: string | null
                                                            thumbnail?: string | null
                                                            mediaType?: Types.MediaType | null
                                                          }
                                                        | {
                                                            __typename: "VideoMediaInfo"
                                                            thumbnail?: string | null
                                                            mediaType?: Types.MediaType | null
                                                          }
                                                        | null
                                                    }
                                                  | { __typename: "MediaGroupContent" }
                                                  | { __typename: "ProjectContent" }
                                                  | { __typename: "TermContent" }
                                                  | { __typename: "TextContent" }
                                                  | { __typename: "TextGroupContent" }
                                                  | { __typename: "VariableTableContent" }
                                                  | null
                                              }
                                            | { __typename: "ST4TreeNode"; id: string }
                                            | null
                                        }
                                      | { __typename: "MediaContent" }
                                      | { __typename: "MediaGroupContent" }
                                      | { __typename: "ProjectContent" }
                                      | { __typename: "TermContent" }
                                      | { __typename: "TextContent" }
                                      | { __typename: "TextGroupContent" }
                                      | { __typename: "VariableTableContent" }
                                      | null
                                  }
                                | { __typename: "ST4TreeNode"; id: string }
                                | null
                            } | null> | null
                            comments: Array<{
                              __typename: "CommentNode"
                              id: string
                              comment:
                                | {
                                    __typename: "BlockComment"
                                    location: number
                                    value?: string | null
                                    authorDisplayName?: string | null
                                    authorUserName?: string | null
                                    commentKey: string
                                    editDate?: any | null
                                    modificationVersion?: number | null
                                    replyToKey?: string | null
                                    sortOrder?: number | null
                                    commentType?: string | null
                                    state?: string | null
                                    color: string
                                    persistanceState?: Types.PersistanceState | null
                                    persistanceAction?: Types.PersistanceAction | null
                                    node: {
                                      __typename: "ST4Node"
                                      aspectId: any
                                      id: string
                                      content?:
                                        | { __typename: "LexiconContent" }
                                        | { __typename: "LexiconEntryContent" }
                                        | { __typename: "MediaContent" }
                                        | { __typename: "MediaGroupContent" }
                                        | { __typename: "ProjectContent" }
                                        | { __typename: "TermContent" }
                                        | { __typename: "TextContent"; id: string; historyNumber: number }
                                        | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                                        | { __typename: "VariableTableContent" }
                                        | null
                                    }
                                    actions: Array<{
                                      __typename: "Action"
                                      name?: string | null
                                      enabled?: boolean | null
                                    }>
                                  }
                                | {
                                    __typename: "TextComment"
                                    endLocation: number
                                    startLocation?: number | null
                                    value?: string | null
                                    authorDisplayName?: string | null
                                    authorUserName?: string | null
                                    commentKey: string
                                    editDate?: any | null
                                    modificationVersion?: number | null
                                    replyToKey?: string | null
                                    sortOrder?: number | null
                                    commentType?: string | null
                                    state?: string | null
                                    color: string
                                    persistanceState?: Types.PersistanceState | null
                                    persistanceAction?: Types.PersistanceAction | null
                                    node: {
                                      __typename: "ST4Node"
                                      aspectId: any
                                      id: string
                                      content?:
                                        | { __typename: "LexiconContent" }
                                        | { __typename: "LexiconEntryContent" }
                                        | { __typename: "MediaContent" }
                                        | { __typename: "MediaGroupContent" }
                                        | { __typename: "ProjectContent" }
                                        | { __typename: "TermContent" }
                                        | { __typename: "TextContent"; id: string; historyNumber: number }
                                        | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                                        | { __typename: "VariableTableContent" }
                                        | null
                                    }
                                    actions: Array<{
                                      __typename: "Action"
                                      name?: string | null
                                      enabled?: boolean | null
                                    }>
                                  }
                            }>
                          }
                        | { __typename: "VariableTableContent" }
                        | null
                    }
                  }>
                  children: Array<{
                    __typename: "NodeRef"
                    id: string
                    target?:
                      | { __typename: "CommentNode"; id: string }
                      | { __typename: "ExternalSite"; id: string }
                      | { __typename: "ST4Class"; id: string }
                      | { __typename: "ST4Node"; label?: string | null; aspectId: any; id: string }
                      | { __typename: "ST4TreeNode"; id: string }
                      | null
                  }>
                  links?: Array<{
                    __typename: "TextContentLink"
                    ref?: string | null
                    node?:
                      | { __typename: "CommentNode"; id: string }
                      | { __typename: "ExternalSite"; id: string }
                      | { __typename: "ST4Class"; id: string }
                      | {
                          __typename: "ST4Node"
                          id: string
                          aspectId: any
                          label?: string | null
                          properties?: {
                            __typename: "ContentPropertyConnection"
                            totalCount: number
                            edges?: Array<{
                              __typename: "ContentPropertyEdge"
                              node?: {
                                __typename: "ContentProperty"
                                typeOfValue: Types.ContentPropertyValueType
                                name: string
                                value?: any | null
                                displayName: string
                              } | null
                            } | null> | null
                          } | null
                        }
                      | { __typename: "ST4TreeNode"; id: string }
                      | null
                  } | null> | null
                  images: Array<{
                    __typename: "NodeRef"
                    id: string
                    target?:
                      | { __typename: "CommentNode"; id: string }
                      | { __typename: "ExternalSite"; id: string }
                      | { __typename: "ST4Class"; id: string }
                      | {
                          __typename: "ST4Node"
                          aspectId: any
                          id: string
                          releaseState: {
                            __typename: "ReleaseStateValue"
                            displayName: string
                            state: Types.ReleaseState
                            isImplicitlyReleased: boolean
                          }
                          content?:
                            | { __typename: "LexiconContent" }
                            | { __typename: "LexiconEntryContent" }
                            | {
                                __typename: "MediaContent"
                                media?:
                                  | {
                                      __typename: "AnimationMediaInfo"
                                      id: string
                                      resourceId?: string | null
                                      thumbnail?: string | null
                                      width?: number | null
                                      height?: number | null
                                      mediaType?: Types.MediaType | null
                                    }
                                  | {
                                      __typename: "ImageMediaInfo"
                                      id: string
                                      resourceId?: string | null
                                      thumbnail?: string | null
                                      width?: number | null
                                      height?: number | null
                                      resolution?: number | null
                                      colorDepth?: number | null
                                      size?: number | null
                                      mime?: string | null
                                      extension?: string | null
                                      allowCaching?: boolean | null
                                      mediaType?: Types.MediaType | null
                                      src?: string | null
                                      language?: string | null
                                    }
                                  | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                                  | {
                                      __typename: "VectorGraphicsMediaInfo"
                                      id: string
                                      resourceId?: string | null
                                      thumbnail?: string | null
                                      width?: number | null
                                      height?: number | null
                                      allowCaching?: boolean | null
                                      mediaType?: Types.MediaType | null
                                      language?: string | null
                                      labels: Array<{
                                        __typename: "VectorGraphicsLabel"
                                        id?: string | null
                                        sequence?: number | null
                                        autoValue?: string | null
                                        text?: string | null
                                        linkType?: Types.VectorGraphicsLabelLinkType | null
                                        link?: string | null
                                        ref?:
                                          | {
                                              __typename: "GenericRef"
                                              id: string
                                              owner?:
                                                | { __typename: "CommentNode" }
                                                | { __typename: "ExternalSite" }
                                                | { __typename: "ST4Class" }
                                                | { __typename: "ST4Node"; id: string }
                                                | { __typename: "ST4TreeNode" }
                                                | null
                                              target?:
                                                | { __typename: "CommentNode" }
                                                | {
                                                    __typename: "ExternalSite"
                                                    id: string
                                                    teaserData?: {
                                                      __typename: "ExternalLinkTeaserData"
                                                      title?: string | null
                                                      summary?: string | null
                                                      iconUrl?: string | null
                                                      imageUrl?: string | null
                                                      status?: Types.LinkTargetStatus | null
                                                    } | null
                                                  }
                                                | { __typename: "ST4Class" }
                                                | {
                                                    __typename: "ST4Node"
                                                    id: string
                                                    aspectId: any
                                                    label?: string | null
                                                    properties?: {
                                                      __typename: "ContentPropertyConnection"
                                                      edges?: Array<{
                                                        __typename: "ContentPropertyEdge"
                                                        node?: {
                                                          __typename: "ContentProperty"
                                                          name: string
                                                          value?: any | null
                                                          displayName: string
                                                        } | null
                                                      } | null> | null
                                                    } | null
                                                  }
                                                | { __typename: "ST4TreeNode" }
                                                | null
                                            }
                                          | {
                                              __typename: "NodeRef"
                                              id: string
                                              owner?:
                                                | { __typename: "CommentNode" }
                                                | { __typename: "ExternalSite" }
                                                | { __typename: "ST4Class" }
                                                | { __typename: "ST4Node"; id: string }
                                                | { __typename: "ST4TreeNode" }
                                                | null
                                              target?:
                                                | { __typename: "CommentNode" }
                                                | {
                                                    __typename: "ExternalSite"
                                                    id: string
                                                    teaserData?: {
                                                      __typename: "ExternalLinkTeaserData"
                                                      title?: string | null
                                                      summary?: string | null
                                                      iconUrl?: string | null
                                                      imageUrl?: string | null
                                                      status?: Types.LinkTargetStatus | null
                                                    } | null
                                                  }
                                                | { __typename: "ST4Class" }
                                                | {
                                                    __typename: "ST4Node"
                                                    id: string
                                                    aspectId: any
                                                    label?: string | null
                                                    properties?: {
                                                      __typename: "ContentPropertyConnection"
                                                      edges?: Array<{
                                                        __typename: "ContentPropertyEdge"
                                                        node?: {
                                                          __typename: "ContentProperty"
                                                          name: string
                                                          value?: any | null
                                                          displayName: string
                                                        } | null
                                                      } | null> | null
                                                    } | null
                                                  }
                                                | { __typename: "ST4TreeNode" }
                                                | null
                                            }
                                          | null
                                      }>
                                    }
                                  | {
                                      __typename: "VideoMediaInfo"
                                      id: string
                                      resourceId?: string | null
                                      thumbnail?: string | null
                                      width?: number | null
                                      height?: number | null
                                      mediaType?: Types.MediaType | null
                                    }
                                  | null
                              }
                            | {
                                __typename: "MediaGroupContent"
                                children: Array<{
                                  __typename: "NodeRef"
                                  id: string
                                  target?:
                                    | { __typename: "CommentNode"; id: string }
                                    | { __typename: "ExternalSite"; id: string }
                                    | { __typename: "ST4Class"; id: string }
                                    | {
                                        __typename: "ST4Node"
                                        id: string
                                        aspectId: any
                                        content?:
                                          | { __typename: "LexiconContent" }
                                          | { __typename: "LexiconEntryContent" }
                                          | {
                                              __typename: "MediaContent"
                                              media?:
                                                | {
                                                    __typename: "AnimationMediaInfo"
                                                    id: string
                                                    resourceId?: string | null
                                                    thumbnail?: string | null
                                                    width?: number | null
                                                    height?: number | null
                                                    mediaType?: Types.MediaType | null
                                                  }
                                                | {
                                                    __typename: "ImageMediaInfo"
                                                    id: string
                                                    resourceId?: string | null
                                                    thumbnail?: string | null
                                                    width?: number | null
                                                    height?: number | null
                                                    resolution?: number | null
                                                    colorDepth?: number | null
                                                    size?: number | null
                                                    mime?: string | null
                                                    extension?: string | null
                                                    allowCaching?: boolean | null
                                                    mediaType?: Types.MediaType | null
                                                    src?: string | null
                                                    language?: string | null
                                                  }
                                                | {
                                                    __typename: "NoMediaInfo"
                                                    id: string
                                                    mediaType?: Types.MediaType | null
                                                  }
                                                | {
                                                    __typename: "VectorGraphicsMediaInfo"
                                                    id: string
                                                    resourceId?: string | null
                                                    thumbnail?: string | null
                                                    width?: number | null
                                                    height?: number | null
                                                    allowCaching?: boolean | null
                                                    mediaType?: Types.MediaType | null
                                                    language?: string | null
                                                    labels: Array<{
                                                      __typename: "VectorGraphicsLabel"
                                                      id?: string | null
                                                      sequence?: number | null
                                                      autoValue?: string | null
                                                      text?: string | null
                                                      linkType?: Types.VectorGraphicsLabelLinkType | null
                                                      link?: string | null
                                                      ref?:
                                                        | {
                                                            __typename: "GenericRef"
                                                            id: string
                                                            owner?:
                                                              | { __typename: "CommentNode" }
                                                              | { __typename: "ExternalSite" }
                                                              | { __typename: "ST4Class" }
                                                              | { __typename: "ST4Node"; id: string }
                                                              | { __typename: "ST4TreeNode" }
                                                              | null
                                                            target?:
                                                              | { __typename: "CommentNode" }
                                                              | {
                                                                  __typename: "ExternalSite"
                                                                  id: string
                                                                  teaserData?: {
                                                                    __typename: "ExternalLinkTeaserData"
                                                                    title?: string | null
                                                                    summary?: string | null
                                                                    iconUrl?: string | null
                                                                    imageUrl?: string | null
                                                                    status?: Types.LinkTargetStatus | null
                                                                  } | null
                                                                }
                                                              | { __typename: "ST4Class" }
                                                              | {
                                                                  __typename: "ST4Node"
                                                                  id: string
                                                                  aspectId: any
                                                                  label?: string | null
                                                                  properties?: {
                                                                    __typename: "ContentPropertyConnection"
                                                                    edges?: Array<{
                                                                      __typename: "ContentPropertyEdge"
                                                                      node?: {
                                                                        __typename: "ContentProperty"
                                                                        name: string
                                                                        value?: any | null
                                                                        displayName: string
                                                                      } | null
                                                                    } | null> | null
                                                                  } | null
                                                                }
                                                              | { __typename: "ST4TreeNode" }
                                                              | null
                                                          }
                                                        | {
                                                            __typename: "NodeRef"
                                                            id: string
                                                            owner?:
                                                              | { __typename: "CommentNode" }
                                                              | { __typename: "ExternalSite" }
                                                              | { __typename: "ST4Class" }
                                                              | { __typename: "ST4Node"; id: string }
                                                              | { __typename: "ST4TreeNode" }
                                                              | null
                                                            target?:
                                                              | { __typename: "CommentNode" }
                                                              | {
                                                                  __typename: "ExternalSite"
                                                                  id: string
                                                                  teaserData?: {
                                                                    __typename: "ExternalLinkTeaserData"
                                                                    title?: string | null
                                                                    summary?: string | null
                                                                    iconUrl?: string | null
                                                                    imageUrl?: string | null
                                                                    status?: Types.LinkTargetStatus | null
                                                                  } | null
                                                                }
                                                              | { __typename: "ST4Class" }
                                                              | {
                                                                  __typename: "ST4Node"
                                                                  id: string
                                                                  aspectId: any
                                                                  label?: string | null
                                                                  properties?: {
                                                                    __typename: "ContentPropertyConnection"
                                                                    edges?: Array<{
                                                                      __typename: "ContentPropertyEdge"
                                                                      node?: {
                                                                        __typename: "ContentProperty"
                                                                        name: string
                                                                        value?: any | null
                                                                        displayName: string
                                                                      } | null
                                                                    } | null> | null
                                                                  } | null
                                                                }
                                                              | { __typename: "ST4TreeNode" }
                                                              | null
                                                          }
                                                        | null
                                                    }>
                                                  }
                                                | {
                                                    __typename: "VideoMediaInfo"
                                                    id: string
                                                    resourceId?: string | null
                                                    thumbnail?: string | null
                                                    width?: number | null
                                                    height?: number | null
                                                    mediaType?: Types.MediaType | null
                                                  }
                                                | null
                                            }
                                          | {
                                              __typename: "MediaGroupContent"
                                              media?:
                                                | {
                                                    __typename: "AnimationMediaInfo"
                                                    id: string
                                                    resourceId?: string | null
                                                    thumbnail?: string | null
                                                    width?: number | null
                                                    height?: number | null
                                                    mediaType?: Types.MediaType | null
                                                  }
                                                | {
                                                    __typename: "ImageMediaInfo"
                                                    id: string
                                                    resourceId?: string | null
                                                    thumbnail?: string | null
                                                    width?: number | null
                                                    height?: number | null
                                                    resolution?: number | null
                                                    colorDepth?: number | null
                                                    size?: number | null
                                                    mime?: string | null
                                                    extension?: string | null
                                                    allowCaching?: boolean | null
                                                    mediaType?: Types.MediaType | null
                                                    src?: string | null
                                                    language?: string | null
                                                  }
                                                | {
                                                    __typename: "NoMediaInfo"
                                                    id: string
                                                    mediaType?: Types.MediaType | null
                                                  }
                                                | {
                                                    __typename: "VectorGraphicsMediaInfo"
                                                    id: string
                                                    resourceId?: string | null
                                                    thumbnail?: string | null
                                                    width?: number | null
                                                    height?: number | null
                                                    allowCaching?: boolean | null
                                                    mediaType?: Types.MediaType | null
                                                    language?: string | null
                                                    labels: Array<{
                                                      __typename: "VectorGraphicsLabel"
                                                      id?: string | null
                                                      sequence?: number | null
                                                      autoValue?: string | null
                                                      text?: string | null
                                                      linkType?: Types.VectorGraphicsLabelLinkType | null
                                                      link?: string | null
                                                      ref?:
                                                        | {
                                                            __typename: "GenericRef"
                                                            id: string
                                                            owner?:
                                                              | { __typename: "CommentNode" }
                                                              | { __typename: "ExternalSite" }
                                                              | { __typename: "ST4Class" }
                                                              | { __typename: "ST4Node"; id: string }
                                                              | { __typename: "ST4TreeNode" }
                                                              | null
                                                            target?:
                                                              | { __typename: "CommentNode" }
                                                              | {
                                                                  __typename: "ExternalSite"
                                                                  id: string
                                                                  teaserData?: {
                                                                    __typename: "ExternalLinkTeaserData"
                                                                    title?: string | null
                                                                    summary?: string | null
                                                                    iconUrl?: string | null
                                                                    imageUrl?: string | null
                                                                    status?: Types.LinkTargetStatus | null
                                                                  } | null
                                                                }
                                                              | { __typename: "ST4Class" }
                                                              | {
                                                                  __typename: "ST4Node"
                                                                  id: string
                                                                  aspectId: any
                                                                  label?: string | null
                                                                  properties?: {
                                                                    __typename: "ContentPropertyConnection"
                                                                    edges?: Array<{
                                                                      __typename: "ContentPropertyEdge"
                                                                      node?: {
                                                                        __typename: "ContentProperty"
                                                                        name: string
                                                                        value?: any | null
                                                                        displayName: string
                                                                      } | null
                                                                    } | null> | null
                                                                  } | null
                                                                }
                                                              | { __typename: "ST4TreeNode" }
                                                              | null
                                                          }
                                                        | {
                                                            __typename: "NodeRef"
                                                            id: string
                                                            owner?:
                                                              | { __typename: "CommentNode" }
                                                              | { __typename: "ExternalSite" }
                                                              | { __typename: "ST4Class" }
                                                              | { __typename: "ST4Node"; id: string }
                                                              | { __typename: "ST4TreeNode" }
                                                              | null
                                                            target?:
                                                              | { __typename: "CommentNode" }
                                                              | {
                                                                  __typename: "ExternalSite"
                                                                  id: string
                                                                  teaserData?: {
                                                                    __typename: "ExternalLinkTeaserData"
                                                                    title?: string | null
                                                                    summary?: string | null
                                                                    iconUrl?: string | null
                                                                    imageUrl?: string | null
                                                                    status?: Types.LinkTargetStatus | null
                                                                  } | null
                                                                }
                                                              | { __typename: "ST4Class" }
                                                              | {
                                                                  __typename: "ST4Node"
                                                                  id: string
                                                                  aspectId: any
                                                                  label?: string | null
                                                                  properties?: {
                                                                    __typename: "ContentPropertyConnection"
                                                                    edges?: Array<{
                                                                      __typename: "ContentPropertyEdge"
                                                                      node?: {
                                                                        __typename: "ContentProperty"
                                                                        name: string
                                                                        value?: any | null
                                                                        displayName: string
                                                                      } | null
                                                                    } | null> | null
                                                                  } | null
                                                                }
                                                              | { __typename: "ST4TreeNode" }
                                                              | null
                                                          }
                                                        | null
                                                    }>
                                                  }
                                                | {
                                                    __typename: "VideoMediaInfo"
                                                    id: string
                                                    resourceId?: string | null
                                                    thumbnail?: string | null
                                                    width?: number | null
                                                    height?: number | null
                                                    mediaType?: Types.MediaType | null
                                                  }
                                                | null
                                            }
                                          | { __typename: "ProjectContent" }
                                          | { __typename: "TermContent" }
                                          | { __typename: "TextContent" }
                                          | { __typename: "TextGroupContent" }
                                          | { __typename: "VariableTableContent" }
                                          | null
                                      }
                                    | { __typename: "ST4TreeNode"; id: string }
                                    | null
                                }>
                                media?:
                                  | {
                                      __typename: "AnimationMediaInfo"
                                      id: string
                                      resourceId?: string | null
                                      thumbnail?: string | null
                                      width?: number | null
                                      height?: number | null
                                      mediaType?: Types.MediaType | null
                                    }
                                  | {
                                      __typename: "ImageMediaInfo"
                                      id: string
                                      resourceId?: string | null
                                      thumbnail?: string | null
                                      width?: number | null
                                      height?: number | null
                                      resolution?: number | null
                                      colorDepth?: number | null
                                      size?: number | null
                                      mime?: string | null
                                      extension?: string | null
                                      allowCaching?: boolean | null
                                      mediaType?: Types.MediaType | null
                                      src?: string | null
                                      language?: string | null
                                    }
                                  | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                                  | {
                                      __typename: "VectorGraphicsMediaInfo"
                                      id: string
                                      resourceId?: string | null
                                      thumbnail?: string | null
                                      width?: number | null
                                      height?: number | null
                                      allowCaching?: boolean | null
                                      mediaType?: Types.MediaType | null
                                      language?: string | null
                                      labels: Array<{
                                        __typename: "VectorGraphicsLabel"
                                        id?: string | null
                                        sequence?: number | null
                                        autoValue?: string | null
                                        text?: string | null
                                        linkType?: Types.VectorGraphicsLabelLinkType | null
                                        link?: string | null
                                        ref?:
                                          | {
                                              __typename: "GenericRef"
                                              id: string
                                              owner?:
                                                | { __typename: "CommentNode" }
                                                | { __typename: "ExternalSite" }
                                                | { __typename: "ST4Class" }
                                                | { __typename: "ST4Node"; id: string }
                                                | { __typename: "ST4TreeNode" }
                                                | null
                                              target?:
                                                | { __typename: "CommentNode" }
                                                | {
                                                    __typename: "ExternalSite"
                                                    id: string
                                                    teaserData?: {
                                                      __typename: "ExternalLinkTeaserData"
                                                      title?: string | null
                                                      summary?: string | null
                                                      iconUrl?: string | null
                                                      imageUrl?: string | null
                                                      status?: Types.LinkTargetStatus | null
                                                    } | null
                                                  }
                                                | { __typename: "ST4Class" }
                                                | {
                                                    __typename: "ST4Node"
                                                    id: string
                                                    aspectId: any
                                                    label?: string | null
                                                    properties?: {
                                                      __typename: "ContentPropertyConnection"
                                                      edges?: Array<{
                                                        __typename: "ContentPropertyEdge"
                                                        node?: {
                                                          __typename: "ContentProperty"
                                                          name: string
                                                          value?: any | null
                                                          displayName: string
                                                        } | null
                                                      } | null> | null
                                                    } | null
                                                  }
                                                | { __typename: "ST4TreeNode" }
                                                | null
                                            }
                                          | {
                                              __typename: "NodeRef"
                                              id: string
                                              owner?:
                                                | { __typename: "CommentNode" }
                                                | { __typename: "ExternalSite" }
                                                | { __typename: "ST4Class" }
                                                | { __typename: "ST4Node"; id: string }
                                                | { __typename: "ST4TreeNode" }
                                                | null
                                              target?:
                                                | { __typename: "CommentNode" }
                                                | {
                                                    __typename: "ExternalSite"
                                                    id: string
                                                    teaserData?: {
                                                      __typename: "ExternalLinkTeaserData"
                                                      title?: string | null
                                                      summary?: string | null
                                                      iconUrl?: string | null
                                                      imageUrl?: string | null
                                                      status?: Types.LinkTargetStatus | null
                                                    } | null
                                                  }
                                                | { __typename: "ST4Class" }
                                                | {
                                                    __typename: "ST4Node"
                                                    id: string
                                                    aspectId: any
                                                    label?: string | null
                                                    properties?: {
                                                      __typename: "ContentPropertyConnection"
                                                      edges?: Array<{
                                                        __typename: "ContentPropertyEdge"
                                                        node?: {
                                                          __typename: "ContentProperty"
                                                          name: string
                                                          value?: any | null
                                                          displayName: string
                                                        } | null
                                                      } | null> | null
                                                    } | null
                                                  }
                                                | { __typename: "ST4TreeNode" }
                                                | null
                                            }
                                          | null
                                      }>
                                    }
                                  | {
                                      __typename: "VideoMediaInfo"
                                      id: string
                                      resourceId?: string | null
                                      thumbnail?: string | null
                                      width?: number | null
                                      height?: number | null
                                      mediaType?: Types.MediaType | null
                                    }
                                  | null
                              }
                            | { __typename: "ProjectContent" }
                            | { __typename: "TermContent" }
                            | { __typename: "TextContent" }
                            | { __typename: "TextGroupContent" }
                            | { __typename: "VariableTableContent" }
                            | null
                        }
                      | { __typename: "ST4TreeNode"; id: string }
                      | null
                  }>
                  variableTables?: Array<{
                    __typename: "VariableTableReference"
                    ref?: string | null
                    node?:
                      | { __typename: "CommentNode" }
                      | { __typename: "ExternalSite" }
                      | { __typename: "ST4Class" }
                      | {
                          __typename: "ST4Node"
                          id: string
                          label?: string | null
                          aspectId: any
                          content?:
                            | { __typename: "LexiconContent" }
                            | { __typename: "LexiconEntryContent" }
                            | { __typename: "MediaContent" }
                            | { __typename: "MediaGroupContent" }
                            | { __typename: "ProjectContent" }
                            | { __typename: "TermContent" }
                            | { __typename: "TextContent" }
                            | { __typename: "TextGroupContent" }
                            | {
                                __typename: "VariableTableContent"
                                xml?: string | null
                                valueSets: Array<{ __typename: "ValueSet"; name: string; valueSetId: string }>
                                variables: Array<{
                                  __typename: "Variable"
                                  name: string
                                  variableId: string
                                  values: Array<string>
                                }>
                              }
                            | null
                        }
                      | { __typename: "ST4TreeNode" }
                      | null
                  } | null> | null
                  linkedDataNodes: Array<{
                    __typename: "NodeRef"
                    id: string
                    target?:
                      | { __typename: "CommentNode"; id: string }
                      | { __typename: "ExternalSite"; id: string }
                      | { __typename: "ST4Class"; id: string }
                      | {
                          __typename: "ST4Node"
                          label?: string | null
                          id: string
                          metadata: Array<{
                            __typename: "MetaDataEntry"
                            label?: string | null
                            systemName: string
                            value?: string | null
                            values: Array<string>
                            users: Array<string>
                          }>
                          linkedOntologyNodes: Array<
                            | {
                                __typename: "GenericRef"
                                target?:
                                  | { __typename: "CommentNode" }
                                  | { __typename: "ExternalSite" }
                                  | { __typename: "ST4Class" }
                                  | { __typename: "ST4Node"; id: string; label?: string | null }
                                  | { __typename: "ST4TreeNode" }
                                  | null
                              }
                            | {
                                __typename: "NodeRef"
                                linkClassName: string
                                class?: { __typename: "ST4Class"; label?: string | null } | null
                                target?:
                                  | { __typename: "CommentNode" }
                                  | { __typename: "ExternalSite" }
                                  | { __typename: "ST4Class" }
                                  | { __typename: "ST4Node"; id: string; label?: string | null }
                                  | { __typename: "ST4TreeNode" }
                                  | null
                              }
                          >
                        }
                      | { __typename: "ST4TreeNode"; id: string }
                      | null
                  }>
                  lexiconEntries?: Array<{
                    __typename: "NodeRef"
                    id: string
                    target?:
                      | { __typename: "CommentNode"; id: string }
                      | { __typename: "ExternalSite"; id: string }
                      | { __typename: "ST4Class"; id: string }
                      | {
                          __typename: "ST4Node"
                          aspectId: any
                          label?: string | null
                          id: string
                          content?:
                            | { __typename: "LexiconContent" }
                            | {
                                __typename: "LexiconEntryContent"
                                descriptionXast?: string | null
                                subject?: string | null
                                sortKey?: string | null
                                lexicon:
                                  | { __typename: "CommentNode"; id: string }
                                  | { __typename: "ExternalSite"; id: string }
                                  | { __typename: "ST4Class"; id: string }
                                  | { __typename: "ST4Node"; label?: string | null; id: string }
                                  | { __typename: "ST4TreeNode"; id: string }
                                terms: Array<
                                  | { __typename: "CommentNode"; id: string }
                                  | { __typename: "ExternalSite"; id: string }
                                  | { __typename: "ST4Class"; id: string }
                                  | {
                                      __typename: "ST4Node"
                                      id: string
                                      label?: string | null
                                      aspectId: any
                                      content?:
                                        | { __typename: "LexiconContent" }
                                        | { __typename: "LexiconEntryContent" }
                                        | { __typename: "MediaContent" }
                                        | { __typename: "MediaGroupContent" }
                                        | { __typename: "ProjectContent" }
                                        | {
                                            __typename: "TermContent"
                                            gramaticalGender?: string | null
                                            validity?: string | null
                                            editLanguage?: string | null
                                          }
                                        | { __typename: "TextContent" }
                                        | { __typename: "TextGroupContent" }
                                        | { __typename: "VariableTableContent" }
                                        | null
                                    }
                                  | { __typename: "ST4TreeNode"; id: string }
                                >
                                image?:
                                  | { __typename: "CommentNode"; id: string }
                                  | { __typename: "ExternalSite"; id: string }
                                  | { __typename: "ST4Class"; id: string }
                                  | {
                                      __typename: "ST4Node"
                                      aspectId: any
                                      id: string
                                      content?:
                                        | { __typename: "LexiconContent" }
                                        | { __typename: "LexiconEntryContent" }
                                        | {
                                            __typename: "MediaContent"
                                            media?:
                                              | {
                                                  __typename: "AnimationMediaInfo"
                                                  thumbnail?: string | null
                                                  mediaType?: Types.MediaType | null
                                                }
                                              | {
                                                  __typename: "ImageMediaInfo"
                                                  src?: string | null
                                                  thumbnail?: string | null
                                                  mediaType?: Types.MediaType | null
                                                }
                                              | { __typename: "NoMediaInfo" }
                                              | {
                                                  __typename: "VectorGraphicsMediaInfo"
                                                  svg?: string | null
                                                  thumbnail?: string | null
                                                  mediaType?: Types.MediaType | null
                                                }
                                              | {
                                                  __typename: "VideoMediaInfo"
                                                  thumbnail?: string | null
                                                  mediaType?: Types.MediaType | null
                                                }
                                              | null
                                          }
                                        | { __typename: "MediaGroupContent" }
                                        | { __typename: "ProjectContent" }
                                        | { __typename: "TermContent" }
                                        | { __typename: "TextContent" }
                                        | { __typename: "TextGroupContent" }
                                        | { __typename: "VariableTableContent" }
                                        | null
                                    }
                                  | { __typename: "ST4TreeNode"; id: string }
                                  | null
                              }
                            | { __typename: "MediaContent" }
                            | { __typename: "MediaGroupContent" }
                            | { __typename: "ProjectContent" }
                            | { __typename: "TermContent" }
                            | { __typename: "TextContent" }
                            | { __typename: "TextGroupContent" }
                            | { __typename: "VariableTableContent" }
                            | null
                        }
                      | { __typename: "ST4TreeNode"; id: string }
                      | null
                  } | null> | null
                  comments: Array<{
                    __typename: "CommentNode"
                    id: string
                    comment:
                      | {
                          __typename: "BlockComment"
                          location: number
                          value?: string | null
                          authorDisplayName?: string | null
                          authorUserName?: string | null
                          commentKey: string
                          editDate?: any | null
                          modificationVersion?: number | null
                          replyToKey?: string | null
                          sortOrder?: number | null
                          commentType?: string | null
                          state?: string | null
                          color: string
                          persistanceState?: Types.PersistanceState | null
                          persistanceAction?: Types.PersistanceAction | null
                          node: {
                            __typename: "ST4Node"
                            aspectId: any
                            id: string
                            content?:
                              | { __typename: "LexiconContent" }
                              | { __typename: "LexiconEntryContent" }
                              | { __typename: "MediaContent" }
                              | { __typename: "MediaGroupContent" }
                              | { __typename: "ProjectContent" }
                              | { __typename: "TermContent" }
                              | { __typename: "TextContent"; id: string; historyNumber: number }
                              | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                              | { __typename: "VariableTableContent" }
                              | null
                          }
                          actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
                        }
                      | {
                          __typename: "TextComment"
                          endLocation: number
                          startLocation?: number | null
                          value?: string | null
                          authorDisplayName?: string | null
                          authorUserName?: string | null
                          commentKey: string
                          editDate?: any | null
                          modificationVersion?: number | null
                          replyToKey?: string | null
                          sortOrder?: number | null
                          commentType?: string | null
                          state?: string | null
                          color: string
                          persistanceState?: Types.PersistanceState | null
                          persistanceAction?: Types.PersistanceAction | null
                          node: {
                            __typename: "ST4Node"
                            aspectId: any
                            id: string
                            content?:
                              | { __typename: "LexiconContent" }
                              | { __typename: "LexiconEntryContent" }
                              | { __typename: "MediaContent" }
                              | { __typename: "MediaGroupContent" }
                              | { __typename: "ProjectContent" }
                              | { __typename: "TermContent" }
                              | { __typename: "TextContent"; id: string; historyNumber: number }
                              | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                              | { __typename: "VariableTableContent" }
                              | null
                          }
                          actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
                        }
                  }>
                }
              | { __typename: "VariableTableContent" }
              | null
          }
        }>
        parts: Array<{
          __typename: "TextContentPart"
          ref: string
          contentNode: {
            __typename: "ST4Node"
            id: string
            aspectId: any
            content?:
              | { __typename: "LexiconContent" }
              | { __typename: "LexiconEntryContent" }
              | { __typename: "MediaContent" }
              | { __typename: "MediaGroupContent" }
              | { __typename: "ProjectContent" }
              | { __typename: "TermContent" }
              | {
                  __typename: "TextContent"
                  id: string
                  xast?: string | null
                  historyNumber: number
                  links?: Array<{
                    __typename: "TextContentLink"
                    ref?: string | null
                    node?:
                      | { __typename: "CommentNode"; id: string }
                      | { __typename: "ExternalSite"; id: string }
                      | { __typename: "ST4Class"; id: string }
                      | {
                          __typename: "ST4Node"
                          id: string
                          aspectId: any
                          label?: string | null
                          properties?: {
                            __typename: "ContentPropertyConnection"
                            totalCount: number
                            edges?: Array<{
                              __typename: "ContentPropertyEdge"
                              node?: {
                                __typename: "ContentProperty"
                                typeOfValue: Types.ContentPropertyValueType
                                name: string
                                value?: any | null
                                displayName: string
                              } | null
                            } | null> | null
                          } | null
                        }
                      | { __typename: "ST4TreeNode"; id: string }
                      | null
                  } | null> | null
                  images: Array<{
                    __typename: "NodeRef"
                    id: string
                    target?:
                      | { __typename: "CommentNode"; id: string }
                      | { __typename: "ExternalSite"; id: string }
                      | { __typename: "ST4Class"; id: string }
                      | {
                          __typename: "ST4Node"
                          aspectId: any
                          id: string
                          releaseState: {
                            __typename: "ReleaseStateValue"
                            displayName: string
                            state: Types.ReleaseState
                            isImplicitlyReleased: boolean
                          }
                          content?:
                            | { __typename: "LexiconContent" }
                            | { __typename: "LexiconEntryContent" }
                            | {
                                __typename: "MediaContent"
                                media?:
                                  | {
                                      __typename: "AnimationMediaInfo"
                                      id: string
                                      resourceId?: string | null
                                      thumbnail?: string | null
                                      width?: number | null
                                      height?: number | null
                                      mediaType?: Types.MediaType | null
                                    }
                                  | {
                                      __typename: "ImageMediaInfo"
                                      id: string
                                      resourceId?: string | null
                                      thumbnail?: string | null
                                      width?: number | null
                                      height?: number | null
                                      resolution?: number | null
                                      colorDepth?: number | null
                                      size?: number | null
                                      mime?: string | null
                                      extension?: string | null
                                      allowCaching?: boolean | null
                                      mediaType?: Types.MediaType | null
                                      src?: string | null
                                      language?: string | null
                                    }
                                  | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                                  | {
                                      __typename: "VectorGraphicsMediaInfo"
                                      id: string
                                      resourceId?: string | null
                                      thumbnail?: string | null
                                      width?: number | null
                                      height?: number | null
                                      allowCaching?: boolean | null
                                      mediaType?: Types.MediaType | null
                                      language?: string | null
                                      labels: Array<{
                                        __typename: "VectorGraphicsLabel"
                                        id?: string | null
                                        sequence?: number | null
                                        autoValue?: string | null
                                        text?: string | null
                                        linkType?: Types.VectorGraphicsLabelLinkType | null
                                        link?: string | null
                                        ref?:
                                          | {
                                              __typename: "GenericRef"
                                              id: string
                                              owner?:
                                                | { __typename: "CommentNode" }
                                                | { __typename: "ExternalSite" }
                                                | { __typename: "ST4Class" }
                                                | { __typename: "ST4Node"; id: string }
                                                | { __typename: "ST4TreeNode" }
                                                | null
                                              target?:
                                                | { __typename: "CommentNode" }
                                                | {
                                                    __typename: "ExternalSite"
                                                    id: string
                                                    teaserData?: {
                                                      __typename: "ExternalLinkTeaserData"
                                                      title?: string | null
                                                      summary?: string | null
                                                      iconUrl?: string | null
                                                      imageUrl?: string | null
                                                      status?: Types.LinkTargetStatus | null
                                                    } | null
                                                  }
                                                | { __typename: "ST4Class" }
                                                | {
                                                    __typename: "ST4Node"
                                                    id: string
                                                    aspectId: any
                                                    label?: string | null
                                                    properties?: {
                                                      __typename: "ContentPropertyConnection"
                                                      edges?: Array<{
                                                        __typename: "ContentPropertyEdge"
                                                        node?: {
                                                          __typename: "ContentProperty"
                                                          name: string
                                                          value?: any | null
                                                          displayName: string
                                                        } | null
                                                      } | null> | null
                                                    } | null
                                                  }
                                                | { __typename: "ST4TreeNode" }
                                                | null
                                            }
                                          | {
                                              __typename: "NodeRef"
                                              id: string
                                              owner?:
                                                | { __typename: "CommentNode" }
                                                | { __typename: "ExternalSite" }
                                                | { __typename: "ST4Class" }
                                                | { __typename: "ST4Node"; id: string }
                                                | { __typename: "ST4TreeNode" }
                                                | null
                                              target?:
                                                | { __typename: "CommentNode" }
                                                | {
                                                    __typename: "ExternalSite"
                                                    id: string
                                                    teaserData?: {
                                                      __typename: "ExternalLinkTeaserData"
                                                      title?: string | null
                                                      summary?: string | null
                                                      iconUrl?: string | null
                                                      imageUrl?: string | null
                                                      status?: Types.LinkTargetStatus | null
                                                    } | null
                                                  }
                                                | { __typename: "ST4Class" }
                                                | {
                                                    __typename: "ST4Node"
                                                    id: string
                                                    aspectId: any
                                                    label?: string | null
                                                    properties?: {
                                                      __typename: "ContentPropertyConnection"
                                                      edges?: Array<{
                                                        __typename: "ContentPropertyEdge"
                                                        node?: {
                                                          __typename: "ContentProperty"
                                                          name: string
                                                          value?: any | null
                                                          displayName: string
                                                        } | null
                                                      } | null> | null
                                                    } | null
                                                  }
                                                | { __typename: "ST4TreeNode" }
                                                | null
                                            }
                                          | null
                                      }>
                                    }
                                  | {
                                      __typename: "VideoMediaInfo"
                                      id: string
                                      resourceId?: string | null
                                      thumbnail?: string | null
                                      width?: number | null
                                      height?: number | null
                                      mediaType?: Types.MediaType | null
                                    }
                                  | null
                              }
                            | {
                                __typename: "MediaGroupContent"
                                children: Array<{
                                  __typename: "NodeRef"
                                  id: string
                                  target?:
                                    | { __typename: "CommentNode"; id: string }
                                    | { __typename: "ExternalSite"; id: string }
                                    | { __typename: "ST4Class"; id: string }
                                    | {
                                        __typename: "ST4Node"
                                        id: string
                                        aspectId: any
                                        content?:
                                          | { __typename: "LexiconContent" }
                                          | { __typename: "LexiconEntryContent" }
                                          | {
                                              __typename: "MediaContent"
                                              media?:
                                                | {
                                                    __typename: "AnimationMediaInfo"
                                                    id: string
                                                    resourceId?: string | null
                                                    thumbnail?: string | null
                                                    width?: number | null
                                                    height?: number | null
                                                    mediaType?: Types.MediaType | null
                                                  }
                                                | {
                                                    __typename: "ImageMediaInfo"
                                                    id: string
                                                    resourceId?: string | null
                                                    thumbnail?: string | null
                                                    width?: number | null
                                                    height?: number | null
                                                    resolution?: number | null
                                                    colorDepth?: number | null
                                                    size?: number | null
                                                    mime?: string | null
                                                    extension?: string | null
                                                    allowCaching?: boolean | null
                                                    mediaType?: Types.MediaType | null
                                                    src?: string | null
                                                    language?: string | null
                                                  }
                                                | {
                                                    __typename: "NoMediaInfo"
                                                    id: string
                                                    mediaType?: Types.MediaType | null
                                                  }
                                                | {
                                                    __typename: "VectorGraphicsMediaInfo"
                                                    id: string
                                                    resourceId?: string | null
                                                    thumbnail?: string | null
                                                    width?: number | null
                                                    height?: number | null
                                                    allowCaching?: boolean | null
                                                    mediaType?: Types.MediaType | null
                                                    language?: string | null
                                                    labels: Array<{
                                                      __typename: "VectorGraphicsLabel"
                                                      id?: string | null
                                                      sequence?: number | null
                                                      autoValue?: string | null
                                                      text?: string | null
                                                      linkType?: Types.VectorGraphicsLabelLinkType | null
                                                      link?: string | null
                                                      ref?:
                                                        | {
                                                            __typename: "GenericRef"
                                                            id: string
                                                            owner?:
                                                              | { __typename: "CommentNode" }
                                                              | { __typename: "ExternalSite" }
                                                              | { __typename: "ST4Class" }
                                                              | { __typename: "ST4Node"; id: string }
                                                              | { __typename: "ST4TreeNode" }
                                                              | null
                                                            target?:
                                                              | { __typename: "CommentNode" }
                                                              | {
                                                                  __typename: "ExternalSite"
                                                                  id: string
                                                                  teaserData?: {
                                                                    __typename: "ExternalLinkTeaserData"
                                                                    title?: string | null
                                                                    summary?: string | null
                                                                    iconUrl?: string | null
                                                                    imageUrl?: string | null
                                                                    status?: Types.LinkTargetStatus | null
                                                                  } | null
                                                                }
                                                              | { __typename: "ST4Class" }
                                                              | {
                                                                  __typename: "ST4Node"
                                                                  id: string
                                                                  aspectId: any
                                                                  label?: string | null
                                                                  properties?: {
                                                                    __typename: "ContentPropertyConnection"
                                                                    edges?: Array<{
                                                                      __typename: "ContentPropertyEdge"
                                                                      node?: {
                                                                        __typename: "ContentProperty"
                                                                        name: string
                                                                        value?: any | null
                                                                        displayName: string
                                                                      } | null
                                                                    } | null> | null
                                                                  } | null
                                                                }
                                                              | { __typename: "ST4TreeNode" }
                                                              | null
                                                          }
                                                        | {
                                                            __typename: "NodeRef"
                                                            id: string
                                                            owner?:
                                                              | { __typename: "CommentNode" }
                                                              | { __typename: "ExternalSite" }
                                                              | { __typename: "ST4Class" }
                                                              | { __typename: "ST4Node"; id: string }
                                                              | { __typename: "ST4TreeNode" }
                                                              | null
                                                            target?:
                                                              | { __typename: "CommentNode" }
                                                              | {
                                                                  __typename: "ExternalSite"
                                                                  id: string
                                                                  teaserData?: {
                                                                    __typename: "ExternalLinkTeaserData"
                                                                    title?: string | null
                                                                    summary?: string | null
                                                                    iconUrl?: string | null
                                                                    imageUrl?: string | null
                                                                    status?: Types.LinkTargetStatus | null
                                                                  } | null
                                                                }
                                                              | { __typename: "ST4Class" }
                                                              | {
                                                                  __typename: "ST4Node"
                                                                  id: string
                                                                  aspectId: any
                                                                  label?: string | null
                                                                  properties?: {
                                                                    __typename: "ContentPropertyConnection"
                                                                    edges?: Array<{
                                                                      __typename: "ContentPropertyEdge"
                                                                      node?: {
                                                                        __typename: "ContentProperty"
                                                                        name: string
                                                                        value?: any | null
                                                                        displayName: string
                                                                      } | null
                                                                    } | null> | null
                                                                  } | null
                                                                }
                                                              | { __typename: "ST4TreeNode" }
                                                              | null
                                                          }
                                                        | null
                                                    }>
                                                  }
                                                | {
                                                    __typename: "VideoMediaInfo"
                                                    id: string
                                                    resourceId?: string | null
                                                    thumbnail?: string | null
                                                    width?: number | null
                                                    height?: number | null
                                                    mediaType?: Types.MediaType | null
                                                  }
                                                | null
                                            }
                                          | {
                                              __typename: "MediaGroupContent"
                                              media?:
                                                | {
                                                    __typename: "AnimationMediaInfo"
                                                    id: string
                                                    resourceId?: string | null
                                                    thumbnail?: string | null
                                                    width?: number | null
                                                    height?: number | null
                                                    mediaType?: Types.MediaType | null
                                                  }
                                                | {
                                                    __typename: "ImageMediaInfo"
                                                    id: string
                                                    resourceId?: string | null
                                                    thumbnail?: string | null
                                                    width?: number | null
                                                    height?: number | null
                                                    resolution?: number | null
                                                    colorDepth?: number | null
                                                    size?: number | null
                                                    mime?: string | null
                                                    extension?: string | null
                                                    allowCaching?: boolean | null
                                                    mediaType?: Types.MediaType | null
                                                    src?: string | null
                                                    language?: string | null
                                                  }
                                                | {
                                                    __typename: "NoMediaInfo"
                                                    id: string
                                                    mediaType?: Types.MediaType | null
                                                  }
                                                | {
                                                    __typename: "VectorGraphicsMediaInfo"
                                                    id: string
                                                    resourceId?: string | null
                                                    thumbnail?: string | null
                                                    width?: number | null
                                                    height?: number | null
                                                    allowCaching?: boolean | null
                                                    mediaType?: Types.MediaType | null
                                                    language?: string | null
                                                    labels: Array<{
                                                      __typename: "VectorGraphicsLabel"
                                                      id?: string | null
                                                      sequence?: number | null
                                                      autoValue?: string | null
                                                      text?: string | null
                                                      linkType?: Types.VectorGraphicsLabelLinkType | null
                                                      link?: string | null
                                                      ref?:
                                                        | {
                                                            __typename: "GenericRef"
                                                            id: string
                                                            owner?:
                                                              | { __typename: "CommentNode" }
                                                              | { __typename: "ExternalSite" }
                                                              | { __typename: "ST4Class" }
                                                              | { __typename: "ST4Node"; id: string }
                                                              | { __typename: "ST4TreeNode" }
                                                              | null
                                                            target?:
                                                              | { __typename: "CommentNode" }
                                                              | {
                                                                  __typename: "ExternalSite"
                                                                  id: string
                                                                  teaserData?: {
                                                                    __typename: "ExternalLinkTeaserData"
                                                                    title?: string | null
                                                                    summary?: string | null
                                                                    iconUrl?: string | null
                                                                    imageUrl?: string | null
                                                                    status?: Types.LinkTargetStatus | null
                                                                  } | null
                                                                }
                                                              | { __typename: "ST4Class" }
                                                              | {
                                                                  __typename: "ST4Node"
                                                                  id: string
                                                                  aspectId: any
                                                                  label?: string | null
                                                                  properties?: {
                                                                    __typename: "ContentPropertyConnection"
                                                                    edges?: Array<{
                                                                      __typename: "ContentPropertyEdge"
                                                                      node?: {
                                                                        __typename: "ContentProperty"
                                                                        name: string
                                                                        value?: any | null
                                                                        displayName: string
                                                                      } | null
                                                                    } | null> | null
                                                                  } | null
                                                                }
                                                              | { __typename: "ST4TreeNode" }
                                                              | null
                                                          }
                                                        | {
                                                            __typename: "NodeRef"
                                                            id: string
                                                            owner?:
                                                              | { __typename: "CommentNode" }
                                                              | { __typename: "ExternalSite" }
                                                              | { __typename: "ST4Class" }
                                                              | { __typename: "ST4Node"; id: string }
                                                              | { __typename: "ST4TreeNode" }
                                                              | null
                                                            target?:
                                                              | { __typename: "CommentNode" }
                                                              | {
                                                                  __typename: "ExternalSite"
                                                                  id: string
                                                                  teaserData?: {
                                                                    __typename: "ExternalLinkTeaserData"
                                                                    title?: string | null
                                                                    summary?: string | null
                                                                    iconUrl?: string | null
                                                                    imageUrl?: string | null
                                                                    status?: Types.LinkTargetStatus | null
                                                                  } | null
                                                                }
                                                              | { __typename: "ST4Class" }
                                                              | {
                                                                  __typename: "ST4Node"
                                                                  id: string
                                                                  aspectId: any
                                                                  label?: string | null
                                                                  properties?: {
                                                                    __typename: "ContentPropertyConnection"
                                                                    edges?: Array<{
                                                                      __typename: "ContentPropertyEdge"
                                                                      node?: {
                                                                        __typename: "ContentProperty"
                                                                        name: string
                                                                        value?: any | null
                                                                        displayName: string
                                                                      } | null
                                                                    } | null> | null
                                                                  } | null
                                                                }
                                                              | { __typename: "ST4TreeNode" }
                                                              | null
                                                          }
                                                        | null
                                                    }>
                                                  }
                                                | {
                                                    __typename: "VideoMediaInfo"
                                                    id: string
                                                    resourceId?: string | null
                                                    thumbnail?: string | null
                                                    width?: number | null
                                                    height?: number | null
                                                    mediaType?: Types.MediaType | null
                                                  }
                                                | null
                                            }
                                          | { __typename: "ProjectContent" }
                                          | { __typename: "TermContent" }
                                          | { __typename: "TextContent" }
                                          | { __typename: "TextGroupContent" }
                                          | { __typename: "VariableTableContent" }
                                          | null
                                      }
                                    | { __typename: "ST4TreeNode"; id: string }
                                    | null
                                }>
                                media?:
                                  | {
                                      __typename: "AnimationMediaInfo"
                                      id: string
                                      resourceId?: string | null
                                      thumbnail?: string | null
                                      width?: number | null
                                      height?: number | null
                                      mediaType?: Types.MediaType | null
                                    }
                                  | {
                                      __typename: "ImageMediaInfo"
                                      id: string
                                      resourceId?: string | null
                                      thumbnail?: string | null
                                      width?: number | null
                                      height?: number | null
                                      resolution?: number | null
                                      colorDepth?: number | null
                                      size?: number | null
                                      mime?: string | null
                                      extension?: string | null
                                      allowCaching?: boolean | null
                                      mediaType?: Types.MediaType | null
                                      src?: string | null
                                      language?: string | null
                                    }
                                  | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                                  | {
                                      __typename: "VectorGraphicsMediaInfo"
                                      id: string
                                      resourceId?: string | null
                                      thumbnail?: string | null
                                      width?: number | null
                                      height?: number | null
                                      allowCaching?: boolean | null
                                      mediaType?: Types.MediaType | null
                                      language?: string | null
                                      labels: Array<{
                                        __typename: "VectorGraphicsLabel"
                                        id?: string | null
                                        sequence?: number | null
                                        autoValue?: string | null
                                        text?: string | null
                                        linkType?: Types.VectorGraphicsLabelLinkType | null
                                        link?: string | null
                                        ref?:
                                          | {
                                              __typename: "GenericRef"
                                              id: string
                                              owner?:
                                                | { __typename: "CommentNode" }
                                                | { __typename: "ExternalSite" }
                                                | { __typename: "ST4Class" }
                                                | { __typename: "ST4Node"; id: string }
                                                | { __typename: "ST4TreeNode" }
                                                | null
                                              target?:
                                                | { __typename: "CommentNode" }
                                                | {
                                                    __typename: "ExternalSite"
                                                    id: string
                                                    teaserData?: {
                                                      __typename: "ExternalLinkTeaserData"
                                                      title?: string | null
                                                      summary?: string | null
                                                      iconUrl?: string | null
                                                      imageUrl?: string | null
                                                      status?: Types.LinkTargetStatus | null
                                                    } | null
                                                  }
                                                | { __typename: "ST4Class" }
                                                | {
                                                    __typename: "ST4Node"
                                                    id: string
                                                    aspectId: any
                                                    label?: string | null
                                                    properties?: {
                                                      __typename: "ContentPropertyConnection"
                                                      edges?: Array<{
                                                        __typename: "ContentPropertyEdge"
                                                        node?: {
                                                          __typename: "ContentProperty"
                                                          name: string
                                                          value?: any | null
                                                          displayName: string
                                                        } | null
                                                      } | null> | null
                                                    } | null
                                                  }
                                                | { __typename: "ST4TreeNode" }
                                                | null
                                            }
                                          | {
                                              __typename: "NodeRef"
                                              id: string
                                              owner?:
                                                | { __typename: "CommentNode" }
                                                | { __typename: "ExternalSite" }
                                                | { __typename: "ST4Class" }
                                                | { __typename: "ST4Node"; id: string }
                                                | { __typename: "ST4TreeNode" }
                                                | null
                                              target?:
                                                | { __typename: "CommentNode" }
                                                | {
                                                    __typename: "ExternalSite"
                                                    id: string
                                                    teaserData?: {
                                                      __typename: "ExternalLinkTeaserData"
                                                      title?: string | null
                                                      summary?: string | null
                                                      iconUrl?: string | null
                                                      imageUrl?: string | null
                                                      status?: Types.LinkTargetStatus | null
                                                    } | null
                                                  }
                                                | { __typename: "ST4Class" }
                                                | {
                                                    __typename: "ST4Node"
                                                    id: string
                                                    aspectId: any
                                                    label?: string | null
                                                    properties?: {
                                                      __typename: "ContentPropertyConnection"
                                                      edges?: Array<{
                                                        __typename: "ContentPropertyEdge"
                                                        node?: {
                                                          __typename: "ContentProperty"
                                                          name: string
                                                          value?: any | null
                                                          displayName: string
                                                        } | null
                                                      } | null> | null
                                                    } | null
                                                  }
                                                | { __typename: "ST4TreeNode" }
                                                | null
                                            }
                                          | null
                                      }>
                                    }
                                  | {
                                      __typename: "VideoMediaInfo"
                                      id: string
                                      resourceId?: string | null
                                      thumbnail?: string | null
                                      width?: number | null
                                      height?: number | null
                                      mediaType?: Types.MediaType | null
                                    }
                                  | null
                              }
                            | { __typename: "ProjectContent" }
                            | { __typename: "TermContent" }
                            | { __typename: "TextContent" }
                            | { __typename: "TextGroupContent" }
                            | { __typename: "VariableTableContent" }
                            | null
                        }
                      | { __typename: "ST4TreeNode"; id: string }
                      | null
                  }>
                  variableTables?: Array<{
                    __typename: "VariableTableReference"
                    ref?: string | null
                    node?:
                      | { __typename: "CommentNode" }
                      | { __typename: "ExternalSite" }
                      | { __typename: "ST4Class" }
                      | {
                          __typename: "ST4Node"
                          id: string
                          label?: string | null
                          aspectId: any
                          content?:
                            | { __typename: "LexiconContent" }
                            | { __typename: "LexiconEntryContent" }
                            | { __typename: "MediaContent" }
                            | { __typename: "MediaGroupContent" }
                            | { __typename: "ProjectContent" }
                            | { __typename: "TermContent" }
                            | { __typename: "TextContent" }
                            | { __typename: "TextGroupContent" }
                            | {
                                __typename: "VariableTableContent"
                                xml?: string | null
                                valueSets: Array<{ __typename: "ValueSet"; name: string; valueSetId: string }>
                                variables: Array<{
                                  __typename: "Variable"
                                  name: string
                                  variableId: string
                                  values: Array<string>
                                }>
                              }
                            | null
                        }
                      | { __typename: "ST4TreeNode" }
                      | null
                  } | null> | null
                  linkedDataNodes: Array<{
                    __typename: "NodeRef"
                    id: string
                    target?:
                      | { __typename: "CommentNode"; id: string }
                      | { __typename: "ExternalSite"; id: string }
                      | { __typename: "ST4Class"; id: string }
                      | {
                          __typename: "ST4Node"
                          label?: string | null
                          id: string
                          metadata: Array<{
                            __typename: "MetaDataEntry"
                            label?: string | null
                            systemName: string
                            value?: string | null
                            values: Array<string>
                            users: Array<string>
                          }>
                          linkedOntologyNodes: Array<
                            | {
                                __typename: "GenericRef"
                                target?:
                                  | { __typename: "CommentNode" }
                                  | { __typename: "ExternalSite" }
                                  | { __typename: "ST4Class" }
                                  | { __typename: "ST4Node"; id: string; label?: string | null }
                                  | { __typename: "ST4TreeNode" }
                                  | null
                              }
                            | {
                                __typename: "NodeRef"
                                linkClassName: string
                                class?: { __typename: "ST4Class"; label?: string | null } | null
                                target?:
                                  | { __typename: "CommentNode" }
                                  | { __typename: "ExternalSite" }
                                  | { __typename: "ST4Class" }
                                  | { __typename: "ST4Node"; id: string; label?: string | null }
                                  | { __typename: "ST4TreeNode" }
                                  | null
                              }
                          >
                        }
                      | { __typename: "ST4TreeNode"; id: string }
                      | null
                  }>
                  lexiconEntries?: Array<{
                    __typename: "NodeRef"
                    id: string
                    target?:
                      | { __typename: "CommentNode"; id: string }
                      | { __typename: "ExternalSite"; id: string }
                      | { __typename: "ST4Class"; id: string }
                      | {
                          __typename: "ST4Node"
                          aspectId: any
                          label?: string | null
                          id: string
                          content?:
                            | { __typename: "LexiconContent" }
                            | {
                                __typename: "LexiconEntryContent"
                                descriptionXast?: string | null
                                subject?: string | null
                                sortKey?: string | null
                                lexicon:
                                  | { __typename: "CommentNode"; id: string }
                                  | { __typename: "ExternalSite"; id: string }
                                  | { __typename: "ST4Class"; id: string }
                                  | { __typename: "ST4Node"; label?: string | null; id: string }
                                  | { __typename: "ST4TreeNode"; id: string }
                                terms: Array<
                                  | { __typename: "CommentNode"; id: string }
                                  | { __typename: "ExternalSite"; id: string }
                                  | { __typename: "ST4Class"; id: string }
                                  | {
                                      __typename: "ST4Node"
                                      id: string
                                      label?: string | null
                                      aspectId: any
                                      content?:
                                        | { __typename: "LexiconContent" }
                                        | { __typename: "LexiconEntryContent" }
                                        | { __typename: "MediaContent" }
                                        | { __typename: "MediaGroupContent" }
                                        | { __typename: "ProjectContent" }
                                        | {
                                            __typename: "TermContent"
                                            gramaticalGender?: string | null
                                            validity?: string | null
                                            editLanguage?: string | null
                                          }
                                        | { __typename: "TextContent" }
                                        | { __typename: "TextGroupContent" }
                                        | { __typename: "VariableTableContent" }
                                        | null
                                    }
                                  | { __typename: "ST4TreeNode"; id: string }
                                >
                                image?:
                                  | { __typename: "CommentNode"; id: string }
                                  | { __typename: "ExternalSite"; id: string }
                                  | { __typename: "ST4Class"; id: string }
                                  | {
                                      __typename: "ST4Node"
                                      aspectId: any
                                      id: string
                                      content?:
                                        | { __typename: "LexiconContent" }
                                        | { __typename: "LexiconEntryContent" }
                                        | {
                                            __typename: "MediaContent"
                                            media?:
                                              | {
                                                  __typename: "AnimationMediaInfo"
                                                  thumbnail?: string | null
                                                  mediaType?: Types.MediaType | null
                                                }
                                              | {
                                                  __typename: "ImageMediaInfo"
                                                  src?: string | null
                                                  thumbnail?: string | null
                                                  mediaType?: Types.MediaType | null
                                                }
                                              | { __typename: "NoMediaInfo" }
                                              | {
                                                  __typename: "VectorGraphicsMediaInfo"
                                                  svg?: string | null
                                                  thumbnail?: string | null
                                                  mediaType?: Types.MediaType | null
                                                }
                                              | {
                                                  __typename: "VideoMediaInfo"
                                                  thumbnail?: string | null
                                                  mediaType?: Types.MediaType | null
                                                }
                                              | null
                                          }
                                        | { __typename: "MediaGroupContent" }
                                        | { __typename: "ProjectContent" }
                                        | { __typename: "TermContent" }
                                        | { __typename: "TextContent" }
                                        | { __typename: "TextGroupContent" }
                                        | { __typename: "VariableTableContent" }
                                        | null
                                    }
                                  | { __typename: "ST4TreeNode"; id: string }
                                  | null
                              }
                            | { __typename: "MediaContent" }
                            | { __typename: "MediaGroupContent" }
                            | { __typename: "ProjectContent" }
                            | { __typename: "TermContent" }
                            | { __typename: "TextContent" }
                            | { __typename: "TextGroupContent" }
                            | { __typename: "VariableTableContent" }
                            | null
                        }
                      | { __typename: "ST4TreeNode"; id: string }
                      | null
                  } | null> | null
                  comments: Array<{
                    __typename: "CommentNode"
                    id: string
                    comment:
                      | {
                          __typename: "BlockComment"
                          location: number
                          value?: string | null
                          authorDisplayName?: string | null
                          authorUserName?: string | null
                          commentKey: string
                          editDate?: any | null
                          modificationVersion?: number | null
                          replyToKey?: string | null
                          sortOrder?: number | null
                          commentType?: string | null
                          state?: string | null
                          color: string
                          persistanceState?: Types.PersistanceState | null
                          persistanceAction?: Types.PersistanceAction | null
                          node: {
                            __typename: "ST4Node"
                            aspectId: any
                            id: string
                            content?:
                              | { __typename: "LexiconContent" }
                              | { __typename: "LexiconEntryContent" }
                              | { __typename: "MediaContent" }
                              | { __typename: "MediaGroupContent" }
                              | { __typename: "ProjectContent" }
                              | { __typename: "TermContent" }
                              | { __typename: "TextContent"; id: string; historyNumber: number }
                              | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                              | { __typename: "VariableTableContent" }
                              | null
                          }
                          actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
                        }
                      | {
                          __typename: "TextComment"
                          endLocation: number
                          startLocation?: number | null
                          value?: string | null
                          authorDisplayName?: string | null
                          authorUserName?: string | null
                          commentKey: string
                          editDate?: any | null
                          modificationVersion?: number | null
                          replyToKey?: string | null
                          sortOrder?: number | null
                          commentType?: string | null
                          state?: string | null
                          color: string
                          persistanceState?: Types.PersistanceState | null
                          persistanceAction?: Types.PersistanceAction | null
                          node: {
                            __typename: "ST4Node"
                            aspectId: any
                            id: string
                            content?:
                              | { __typename: "LexiconContent" }
                              | { __typename: "LexiconEntryContent" }
                              | { __typename: "MediaContent" }
                              | { __typename: "MediaGroupContent" }
                              | { __typename: "ProjectContent" }
                              | { __typename: "TermContent" }
                              | { __typename: "TextContent"; id: string; historyNumber: number }
                              | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                              | { __typename: "VariableTableContent" }
                              | null
                          }
                          actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
                        }
                  }>
                }
              | {
                  __typename: "TextGroupContent"
                  currentNodeId?: any | null
                  groupNodeId: any
                  id: string
                  xast?: string | null
                  historyNumber: number
                  children: Array<{
                    __typename: "NodeRef"
                    id: string
                    target?:
                      | { __typename: "CommentNode"; id: string }
                      | { __typename: "ExternalSite"; id: string }
                      | { __typename: "ST4Class"; id: string }
                      | { __typename: "ST4Node"; label?: string | null; aspectId: any; id: string }
                      | { __typename: "ST4TreeNode"; id: string }
                      | null
                  }>
                  links?: Array<{
                    __typename: "TextContentLink"
                    ref?: string | null
                    node?:
                      | { __typename: "CommentNode"; id: string }
                      | { __typename: "ExternalSite"; id: string }
                      | { __typename: "ST4Class"; id: string }
                      | {
                          __typename: "ST4Node"
                          id: string
                          aspectId: any
                          label?: string | null
                          properties?: {
                            __typename: "ContentPropertyConnection"
                            totalCount: number
                            edges?: Array<{
                              __typename: "ContentPropertyEdge"
                              node?: {
                                __typename: "ContentProperty"
                                typeOfValue: Types.ContentPropertyValueType
                                name: string
                                value?: any | null
                                displayName: string
                              } | null
                            } | null> | null
                          } | null
                        }
                      | { __typename: "ST4TreeNode"; id: string }
                      | null
                  } | null> | null
                  images: Array<{
                    __typename: "NodeRef"
                    id: string
                    target?:
                      | { __typename: "CommentNode"; id: string }
                      | { __typename: "ExternalSite"; id: string }
                      | { __typename: "ST4Class"; id: string }
                      | {
                          __typename: "ST4Node"
                          aspectId: any
                          id: string
                          releaseState: {
                            __typename: "ReleaseStateValue"
                            displayName: string
                            state: Types.ReleaseState
                            isImplicitlyReleased: boolean
                          }
                          content?:
                            | { __typename: "LexiconContent" }
                            | { __typename: "LexiconEntryContent" }
                            | {
                                __typename: "MediaContent"
                                media?:
                                  | {
                                      __typename: "AnimationMediaInfo"
                                      id: string
                                      resourceId?: string | null
                                      thumbnail?: string | null
                                      width?: number | null
                                      height?: number | null
                                      mediaType?: Types.MediaType | null
                                    }
                                  | {
                                      __typename: "ImageMediaInfo"
                                      id: string
                                      resourceId?: string | null
                                      thumbnail?: string | null
                                      width?: number | null
                                      height?: number | null
                                      resolution?: number | null
                                      colorDepth?: number | null
                                      size?: number | null
                                      mime?: string | null
                                      extension?: string | null
                                      allowCaching?: boolean | null
                                      mediaType?: Types.MediaType | null
                                      src?: string | null
                                      language?: string | null
                                    }
                                  | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                                  | {
                                      __typename: "VectorGraphicsMediaInfo"
                                      id: string
                                      resourceId?: string | null
                                      thumbnail?: string | null
                                      width?: number | null
                                      height?: number | null
                                      allowCaching?: boolean | null
                                      mediaType?: Types.MediaType | null
                                      language?: string | null
                                      labels: Array<{
                                        __typename: "VectorGraphicsLabel"
                                        id?: string | null
                                        sequence?: number | null
                                        autoValue?: string | null
                                        text?: string | null
                                        linkType?: Types.VectorGraphicsLabelLinkType | null
                                        link?: string | null
                                        ref?:
                                          | {
                                              __typename: "GenericRef"
                                              id: string
                                              owner?:
                                                | { __typename: "CommentNode" }
                                                | { __typename: "ExternalSite" }
                                                | { __typename: "ST4Class" }
                                                | { __typename: "ST4Node"; id: string }
                                                | { __typename: "ST4TreeNode" }
                                                | null
                                              target?:
                                                | { __typename: "CommentNode" }
                                                | {
                                                    __typename: "ExternalSite"
                                                    id: string
                                                    teaserData?: {
                                                      __typename: "ExternalLinkTeaserData"
                                                      title?: string | null
                                                      summary?: string | null
                                                      iconUrl?: string | null
                                                      imageUrl?: string | null
                                                      status?: Types.LinkTargetStatus | null
                                                    } | null
                                                  }
                                                | { __typename: "ST4Class" }
                                                | {
                                                    __typename: "ST4Node"
                                                    id: string
                                                    aspectId: any
                                                    label?: string | null
                                                    properties?: {
                                                      __typename: "ContentPropertyConnection"
                                                      edges?: Array<{
                                                        __typename: "ContentPropertyEdge"
                                                        node?: {
                                                          __typename: "ContentProperty"
                                                          name: string
                                                          value?: any | null
                                                          displayName: string
                                                        } | null
                                                      } | null> | null
                                                    } | null
                                                  }
                                                | { __typename: "ST4TreeNode" }
                                                | null
                                            }
                                          | {
                                              __typename: "NodeRef"
                                              id: string
                                              owner?:
                                                | { __typename: "CommentNode" }
                                                | { __typename: "ExternalSite" }
                                                | { __typename: "ST4Class" }
                                                | { __typename: "ST4Node"; id: string }
                                                | { __typename: "ST4TreeNode" }
                                                | null
                                              target?:
                                                | { __typename: "CommentNode" }
                                                | {
                                                    __typename: "ExternalSite"
                                                    id: string
                                                    teaserData?: {
                                                      __typename: "ExternalLinkTeaserData"
                                                      title?: string | null
                                                      summary?: string | null
                                                      iconUrl?: string | null
                                                      imageUrl?: string | null
                                                      status?: Types.LinkTargetStatus | null
                                                    } | null
                                                  }
                                                | { __typename: "ST4Class" }
                                                | {
                                                    __typename: "ST4Node"
                                                    id: string
                                                    aspectId: any
                                                    label?: string | null
                                                    properties?: {
                                                      __typename: "ContentPropertyConnection"
                                                      edges?: Array<{
                                                        __typename: "ContentPropertyEdge"
                                                        node?: {
                                                          __typename: "ContentProperty"
                                                          name: string
                                                          value?: any | null
                                                          displayName: string
                                                        } | null
                                                      } | null> | null
                                                    } | null
                                                  }
                                                | { __typename: "ST4TreeNode" }
                                                | null
                                            }
                                          | null
                                      }>
                                    }
                                  | {
                                      __typename: "VideoMediaInfo"
                                      id: string
                                      resourceId?: string | null
                                      thumbnail?: string | null
                                      width?: number | null
                                      height?: number | null
                                      mediaType?: Types.MediaType | null
                                    }
                                  | null
                              }
                            | {
                                __typename: "MediaGroupContent"
                                children: Array<{
                                  __typename: "NodeRef"
                                  id: string
                                  target?:
                                    | { __typename: "CommentNode"; id: string }
                                    | { __typename: "ExternalSite"; id: string }
                                    | { __typename: "ST4Class"; id: string }
                                    | {
                                        __typename: "ST4Node"
                                        id: string
                                        aspectId: any
                                        content?:
                                          | { __typename: "LexiconContent" }
                                          | { __typename: "LexiconEntryContent" }
                                          | {
                                              __typename: "MediaContent"
                                              media?:
                                                | {
                                                    __typename: "AnimationMediaInfo"
                                                    id: string
                                                    resourceId?: string | null
                                                    thumbnail?: string | null
                                                    width?: number | null
                                                    height?: number | null
                                                    mediaType?: Types.MediaType | null
                                                  }
                                                | {
                                                    __typename: "ImageMediaInfo"
                                                    id: string
                                                    resourceId?: string | null
                                                    thumbnail?: string | null
                                                    width?: number | null
                                                    height?: number | null
                                                    resolution?: number | null
                                                    colorDepth?: number | null
                                                    size?: number | null
                                                    mime?: string | null
                                                    extension?: string | null
                                                    allowCaching?: boolean | null
                                                    mediaType?: Types.MediaType | null
                                                    src?: string | null
                                                    language?: string | null
                                                  }
                                                | {
                                                    __typename: "NoMediaInfo"
                                                    id: string
                                                    mediaType?: Types.MediaType | null
                                                  }
                                                | {
                                                    __typename: "VectorGraphicsMediaInfo"
                                                    id: string
                                                    resourceId?: string | null
                                                    thumbnail?: string | null
                                                    width?: number | null
                                                    height?: number | null
                                                    allowCaching?: boolean | null
                                                    mediaType?: Types.MediaType | null
                                                    language?: string | null
                                                    labels: Array<{
                                                      __typename: "VectorGraphicsLabel"
                                                      id?: string | null
                                                      sequence?: number | null
                                                      autoValue?: string | null
                                                      text?: string | null
                                                      linkType?: Types.VectorGraphicsLabelLinkType | null
                                                      link?: string | null
                                                      ref?:
                                                        | {
                                                            __typename: "GenericRef"
                                                            id: string
                                                            owner?:
                                                              | { __typename: "CommentNode" }
                                                              | { __typename: "ExternalSite" }
                                                              | { __typename: "ST4Class" }
                                                              | { __typename: "ST4Node"; id: string }
                                                              | { __typename: "ST4TreeNode" }
                                                              | null
                                                            target?:
                                                              | { __typename: "CommentNode" }
                                                              | {
                                                                  __typename: "ExternalSite"
                                                                  id: string
                                                                  teaserData?: {
                                                                    __typename: "ExternalLinkTeaserData"
                                                                    title?: string | null
                                                                    summary?: string | null
                                                                    iconUrl?: string | null
                                                                    imageUrl?: string | null
                                                                    status?: Types.LinkTargetStatus | null
                                                                  } | null
                                                                }
                                                              | { __typename: "ST4Class" }
                                                              | {
                                                                  __typename: "ST4Node"
                                                                  id: string
                                                                  aspectId: any
                                                                  label?: string | null
                                                                  properties?: {
                                                                    __typename: "ContentPropertyConnection"
                                                                    edges?: Array<{
                                                                      __typename: "ContentPropertyEdge"
                                                                      node?: {
                                                                        __typename: "ContentProperty"
                                                                        name: string
                                                                        value?: any | null
                                                                        displayName: string
                                                                      } | null
                                                                    } | null> | null
                                                                  } | null
                                                                }
                                                              | { __typename: "ST4TreeNode" }
                                                              | null
                                                          }
                                                        | {
                                                            __typename: "NodeRef"
                                                            id: string
                                                            owner?:
                                                              | { __typename: "CommentNode" }
                                                              | { __typename: "ExternalSite" }
                                                              | { __typename: "ST4Class" }
                                                              | { __typename: "ST4Node"; id: string }
                                                              | { __typename: "ST4TreeNode" }
                                                              | null
                                                            target?:
                                                              | { __typename: "CommentNode" }
                                                              | {
                                                                  __typename: "ExternalSite"
                                                                  id: string
                                                                  teaserData?: {
                                                                    __typename: "ExternalLinkTeaserData"
                                                                    title?: string | null
                                                                    summary?: string | null
                                                                    iconUrl?: string | null
                                                                    imageUrl?: string | null
                                                                    status?: Types.LinkTargetStatus | null
                                                                  } | null
                                                                }
                                                              | { __typename: "ST4Class" }
                                                              | {
                                                                  __typename: "ST4Node"
                                                                  id: string
                                                                  aspectId: any
                                                                  label?: string | null
                                                                  properties?: {
                                                                    __typename: "ContentPropertyConnection"
                                                                    edges?: Array<{
                                                                      __typename: "ContentPropertyEdge"
                                                                      node?: {
                                                                        __typename: "ContentProperty"
                                                                        name: string
                                                                        value?: any | null
                                                                        displayName: string
                                                                      } | null
                                                                    } | null> | null
                                                                  } | null
                                                                }
                                                              | { __typename: "ST4TreeNode" }
                                                              | null
                                                          }
                                                        | null
                                                    }>
                                                  }
                                                | {
                                                    __typename: "VideoMediaInfo"
                                                    id: string
                                                    resourceId?: string | null
                                                    thumbnail?: string | null
                                                    width?: number | null
                                                    height?: number | null
                                                    mediaType?: Types.MediaType | null
                                                  }
                                                | null
                                            }
                                          | {
                                              __typename: "MediaGroupContent"
                                              media?:
                                                | {
                                                    __typename: "AnimationMediaInfo"
                                                    id: string
                                                    resourceId?: string | null
                                                    thumbnail?: string | null
                                                    width?: number | null
                                                    height?: number | null
                                                    mediaType?: Types.MediaType | null
                                                  }
                                                | {
                                                    __typename: "ImageMediaInfo"
                                                    id: string
                                                    resourceId?: string | null
                                                    thumbnail?: string | null
                                                    width?: number | null
                                                    height?: number | null
                                                    resolution?: number | null
                                                    colorDepth?: number | null
                                                    size?: number | null
                                                    mime?: string | null
                                                    extension?: string | null
                                                    allowCaching?: boolean | null
                                                    mediaType?: Types.MediaType | null
                                                    src?: string | null
                                                    language?: string | null
                                                  }
                                                | {
                                                    __typename: "NoMediaInfo"
                                                    id: string
                                                    mediaType?: Types.MediaType | null
                                                  }
                                                | {
                                                    __typename: "VectorGraphicsMediaInfo"
                                                    id: string
                                                    resourceId?: string | null
                                                    thumbnail?: string | null
                                                    width?: number | null
                                                    height?: number | null
                                                    allowCaching?: boolean | null
                                                    mediaType?: Types.MediaType | null
                                                    language?: string | null
                                                    labels: Array<{
                                                      __typename: "VectorGraphicsLabel"
                                                      id?: string | null
                                                      sequence?: number | null
                                                      autoValue?: string | null
                                                      text?: string | null
                                                      linkType?: Types.VectorGraphicsLabelLinkType | null
                                                      link?: string | null
                                                      ref?:
                                                        | {
                                                            __typename: "GenericRef"
                                                            id: string
                                                            owner?:
                                                              | { __typename: "CommentNode" }
                                                              | { __typename: "ExternalSite" }
                                                              | { __typename: "ST4Class" }
                                                              | { __typename: "ST4Node"; id: string }
                                                              | { __typename: "ST4TreeNode" }
                                                              | null
                                                            target?:
                                                              | { __typename: "CommentNode" }
                                                              | {
                                                                  __typename: "ExternalSite"
                                                                  id: string
                                                                  teaserData?: {
                                                                    __typename: "ExternalLinkTeaserData"
                                                                    title?: string | null
                                                                    summary?: string | null
                                                                    iconUrl?: string | null
                                                                    imageUrl?: string | null
                                                                    status?: Types.LinkTargetStatus | null
                                                                  } | null
                                                                }
                                                              | { __typename: "ST4Class" }
                                                              | {
                                                                  __typename: "ST4Node"
                                                                  id: string
                                                                  aspectId: any
                                                                  label?: string | null
                                                                  properties?: {
                                                                    __typename: "ContentPropertyConnection"
                                                                    edges?: Array<{
                                                                      __typename: "ContentPropertyEdge"
                                                                      node?: {
                                                                        __typename: "ContentProperty"
                                                                        name: string
                                                                        value?: any | null
                                                                        displayName: string
                                                                      } | null
                                                                    } | null> | null
                                                                  } | null
                                                                }
                                                              | { __typename: "ST4TreeNode" }
                                                              | null
                                                          }
                                                        | {
                                                            __typename: "NodeRef"
                                                            id: string
                                                            owner?:
                                                              | { __typename: "CommentNode" }
                                                              | { __typename: "ExternalSite" }
                                                              | { __typename: "ST4Class" }
                                                              | { __typename: "ST4Node"; id: string }
                                                              | { __typename: "ST4TreeNode" }
                                                              | null
                                                            target?:
                                                              | { __typename: "CommentNode" }
                                                              | {
                                                                  __typename: "ExternalSite"
                                                                  id: string
                                                                  teaserData?: {
                                                                    __typename: "ExternalLinkTeaserData"
                                                                    title?: string | null
                                                                    summary?: string | null
                                                                    iconUrl?: string | null
                                                                    imageUrl?: string | null
                                                                    status?: Types.LinkTargetStatus | null
                                                                  } | null
                                                                }
                                                              | { __typename: "ST4Class" }
                                                              | {
                                                                  __typename: "ST4Node"
                                                                  id: string
                                                                  aspectId: any
                                                                  label?: string | null
                                                                  properties?: {
                                                                    __typename: "ContentPropertyConnection"
                                                                    edges?: Array<{
                                                                      __typename: "ContentPropertyEdge"
                                                                      node?: {
                                                                        __typename: "ContentProperty"
                                                                        name: string
                                                                        value?: any | null
                                                                        displayName: string
                                                                      } | null
                                                                    } | null> | null
                                                                  } | null
                                                                }
                                                              | { __typename: "ST4TreeNode" }
                                                              | null
                                                          }
                                                        | null
                                                    }>
                                                  }
                                                | {
                                                    __typename: "VideoMediaInfo"
                                                    id: string
                                                    resourceId?: string | null
                                                    thumbnail?: string | null
                                                    width?: number | null
                                                    height?: number | null
                                                    mediaType?: Types.MediaType | null
                                                  }
                                                | null
                                            }
                                          | { __typename: "ProjectContent" }
                                          | { __typename: "TermContent" }
                                          | { __typename: "TextContent" }
                                          | { __typename: "TextGroupContent" }
                                          | { __typename: "VariableTableContent" }
                                          | null
                                      }
                                    | { __typename: "ST4TreeNode"; id: string }
                                    | null
                                }>
                                media?:
                                  | {
                                      __typename: "AnimationMediaInfo"
                                      id: string
                                      resourceId?: string | null
                                      thumbnail?: string | null
                                      width?: number | null
                                      height?: number | null
                                      mediaType?: Types.MediaType | null
                                    }
                                  | {
                                      __typename: "ImageMediaInfo"
                                      id: string
                                      resourceId?: string | null
                                      thumbnail?: string | null
                                      width?: number | null
                                      height?: number | null
                                      resolution?: number | null
                                      colorDepth?: number | null
                                      size?: number | null
                                      mime?: string | null
                                      extension?: string | null
                                      allowCaching?: boolean | null
                                      mediaType?: Types.MediaType | null
                                      src?: string | null
                                      language?: string | null
                                    }
                                  | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                                  | {
                                      __typename: "VectorGraphicsMediaInfo"
                                      id: string
                                      resourceId?: string | null
                                      thumbnail?: string | null
                                      width?: number | null
                                      height?: number | null
                                      allowCaching?: boolean | null
                                      mediaType?: Types.MediaType | null
                                      language?: string | null
                                      labels: Array<{
                                        __typename: "VectorGraphicsLabel"
                                        id?: string | null
                                        sequence?: number | null
                                        autoValue?: string | null
                                        text?: string | null
                                        linkType?: Types.VectorGraphicsLabelLinkType | null
                                        link?: string | null
                                        ref?:
                                          | {
                                              __typename: "GenericRef"
                                              id: string
                                              owner?:
                                                | { __typename: "CommentNode" }
                                                | { __typename: "ExternalSite" }
                                                | { __typename: "ST4Class" }
                                                | { __typename: "ST4Node"; id: string }
                                                | { __typename: "ST4TreeNode" }
                                                | null
                                              target?:
                                                | { __typename: "CommentNode" }
                                                | {
                                                    __typename: "ExternalSite"
                                                    id: string
                                                    teaserData?: {
                                                      __typename: "ExternalLinkTeaserData"
                                                      title?: string | null
                                                      summary?: string | null
                                                      iconUrl?: string | null
                                                      imageUrl?: string | null
                                                      status?: Types.LinkTargetStatus | null
                                                    } | null
                                                  }
                                                | { __typename: "ST4Class" }
                                                | {
                                                    __typename: "ST4Node"
                                                    id: string
                                                    aspectId: any
                                                    label?: string | null
                                                    properties?: {
                                                      __typename: "ContentPropertyConnection"
                                                      edges?: Array<{
                                                        __typename: "ContentPropertyEdge"
                                                        node?: {
                                                          __typename: "ContentProperty"
                                                          name: string
                                                          value?: any | null
                                                          displayName: string
                                                        } | null
                                                      } | null> | null
                                                    } | null
                                                  }
                                                | { __typename: "ST4TreeNode" }
                                                | null
                                            }
                                          | {
                                              __typename: "NodeRef"
                                              id: string
                                              owner?:
                                                | { __typename: "CommentNode" }
                                                | { __typename: "ExternalSite" }
                                                | { __typename: "ST4Class" }
                                                | { __typename: "ST4Node"; id: string }
                                                | { __typename: "ST4TreeNode" }
                                                | null
                                              target?:
                                                | { __typename: "CommentNode" }
                                                | {
                                                    __typename: "ExternalSite"
                                                    id: string
                                                    teaserData?: {
                                                      __typename: "ExternalLinkTeaserData"
                                                      title?: string | null
                                                      summary?: string | null
                                                      iconUrl?: string | null
                                                      imageUrl?: string | null
                                                      status?: Types.LinkTargetStatus | null
                                                    } | null
                                                  }
                                                | { __typename: "ST4Class" }
                                                | {
                                                    __typename: "ST4Node"
                                                    id: string
                                                    aspectId: any
                                                    label?: string | null
                                                    properties?: {
                                                      __typename: "ContentPropertyConnection"
                                                      edges?: Array<{
                                                        __typename: "ContentPropertyEdge"
                                                        node?: {
                                                          __typename: "ContentProperty"
                                                          name: string
                                                          value?: any | null
                                                          displayName: string
                                                        } | null
                                                      } | null> | null
                                                    } | null
                                                  }
                                                | { __typename: "ST4TreeNode" }
                                                | null
                                            }
                                          | null
                                      }>
                                    }
                                  | {
                                      __typename: "VideoMediaInfo"
                                      id: string
                                      resourceId?: string | null
                                      thumbnail?: string | null
                                      width?: number | null
                                      height?: number | null
                                      mediaType?: Types.MediaType | null
                                    }
                                  | null
                              }
                            | { __typename: "ProjectContent" }
                            | { __typename: "TermContent" }
                            | { __typename: "TextContent" }
                            | { __typename: "TextGroupContent" }
                            | { __typename: "VariableTableContent" }
                            | null
                        }
                      | { __typename: "ST4TreeNode"; id: string }
                      | null
                  }>
                  variableTables?: Array<{
                    __typename: "VariableTableReference"
                    ref?: string | null
                    node?:
                      | { __typename: "CommentNode" }
                      | { __typename: "ExternalSite" }
                      | { __typename: "ST4Class" }
                      | {
                          __typename: "ST4Node"
                          id: string
                          label?: string | null
                          aspectId: any
                          content?:
                            | { __typename: "LexiconContent" }
                            | { __typename: "LexiconEntryContent" }
                            | { __typename: "MediaContent" }
                            | { __typename: "MediaGroupContent" }
                            | { __typename: "ProjectContent" }
                            | { __typename: "TermContent" }
                            | { __typename: "TextContent" }
                            | { __typename: "TextGroupContent" }
                            | {
                                __typename: "VariableTableContent"
                                xml?: string | null
                                valueSets: Array<{ __typename: "ValueSet"; name: string; valueSetId: string }>
                                variables: Array<{
                                  __typename: "Variable"
                                  name: string
                                  variableId: string
                                  values: Array<string>
                                }>
                              }
                            | null
                        }
                      | { __typename: "ST4TreeNode" }
                      | null
                  } | null> | null
                  linkedDataNodes: Array<{
                    __typename: "NodeRef"
                    id: string
                    target?:
                      | { __typename: "CommentNode"; id: string }
                      | { __typename: "ExternalSite"; id: string }
                      | { __typename: "ST4Class"; id: string }
                      | {
                          __typename: "ST4Node"
                          label?: string | null
                          id: string
                          metadata: Array<{
                            __typename: "MetaDataEntry"
                            label?: string | null
                            systemName: string
                            value?: string | null
                            values: Array<string>
                            users: Array<string>
                          }>
                          linkedOntologyNodes: Array<
                            | {
                                __typename: "GenericRef"
                                target?:
                                  | { __typename: "CommentNode" }
                                  | { __typename: "ExternalSite" }
                                  | { __typename: "ST4Class" }
                                  | { __typename: "ST4Node"; id: string; label?: string | null }
                                  | { __typename: "ST4TreeNode" }
                                  | null
                              }
                            | {
                                __typename: "NodeRef"
                                linkClassName: string
                                class?: { __typename: "ST4Class"; label?: string | null } | null
                                target?:
                                  | { __typename: "CommentNode" }
                                  | { __typename: "ExternalSite" }
                                  | { __typename: "ST4Class" }
                                  | { __typename: "ST4Node"; id: string; label?: string | null }
                                  | { __typename: "ST4TreeNode" }
                                  | null
                              }
                          >
                        }
                      | { __typename: "ST4TreeNode"; id: string }
                      | null
                  }>
                  lexiconEntries?: Array<{
                    __typename: "NodeRef"
                    id: string
                    target?:
                      | { __typename: "CommentNode"; id: string }
                      | { __typename: "ExternalSite"; id: string }
                      | { __typename: "ST4Class"; id: string }
                      | {
                          __typename: "ST4Node"
                          aspectId: any
                          label?: string | null
                          id: string
                          content?:
                            | { __typename: "LexiconContent" }
                            | {
                                __typename: "LexiconEntryContent"
                                descriptionXast?: string | null
                                subject?: string | null
                                sortKey?: string | null
                                lexicon:
                                  | { __typename: "CommentNode"; id: string }
                                  | { __typename: "ExternalSite"; id: string }
                                  | { __typename: "ST4Class"; id: string }
                                  | { __typename: "ST4Node"; label?: string | null; id: string }
                                  | { __typename: "ST4TreeNode"; id: string }
                                terms: Array<
                                  | { __typename: "CommentNode"; id: string }
                                  | { __typename: "ExternalSite"; id: string }
                                  | { __typename: "ST4Class"; id: string }
                                  | {
                                      __typename: "ST4Node"
                                      id: string
                                      label?: string | null
                                      aspectId: any
                                      content?:
                                        | { __typename: "LexiconContent" }
                                        | { __typename: "LexiconEntryContent" }
                                        | { __typename: "MediaContent" }
                                        | { __typename: "MediaGroupContent" }
                                        | { __typename: "ProjectContent" }
                                        | {
                                            __typename: "TermContent"
                                            gramaticalGender?: string | null
                                            validity?: string | null
                                            editLanguage?: string | null
                                          }
                                        | { __typename: "TextContent" }
                                        | { __typename: "TextGroupContent" }
                                        | { __typename: "VariableTableContent" }
                                        | null
                                    }
                                  | { __typename: "ST4TreeNode"; id: string }
                                >
                                image?:
                                  | { __typename: "CommentNode"; id: string }
                                  | { __typename: "ExternalSite"; id: string }
                                  | { __typename: "ST4Class"; id: string }
                                  | {
                                      __typename: "ST4Node"
                                      aspectId: any
                                      id: string
                                      content?:
                                        | { __typename: "LexiconContent" }
                                        | { __typename: "LexiconEntryContent" }
                                        | {
                                            __typename: "MediaContent"
                                            media?:
                                              | {
                                                  __typename: "AnimationMediaInfo"
                                                  thumbnail?: string | null
                                                  mediaType?: Types.MediaType | null
                                                }
                                              | {
                                                  __typename: "ImageMediaInfo"
                                                  src?: string | null
                                                  thumbnail?: string | null
                                                  mediaType?: Types.MediaType | null
                                                }
                                              | { __typename: "NoMediaInfo" }
                                              | {
                                                  __typename: "VectorGraphicsMediaInfo"
                                                  svg?: string | null
                                                  thumbnail?: string | null
                                                  mediaType?: Types.MediaType | null
                                                }
                                              | {
                                                  __typename: "VideoMediaInfo"
                                                  thumbnail?: string | null
                                                  mediaType?: Types.MediaType | null
                                                }
                                              | null
                                          }
                                        | { __typename: "MediaGroupContent" }
                                        | { __typename: "ProjectContent" }
                                        | { __typename: "TermContent" }
                                        | { __typename: "TextContent" }
                                        | { __typename: "TextGroupContent" }
                                        | { __typename: "VariableTableContent" }
                                        | null
                                    }
                                  | { __typename: "ST4TreeNode"; id: string }
                                  | null
                              }
                            | { __typename: "MediaContent" }
                            | { __typename: "MediaGroupContent" }
                            | { __typename: "ProjectContent" }
                            | { __typename: "TermContent" }
                            | { __typename: "TextContent" }
                            | { __typename: "TextGroupContent" }
                            | { __typename: "VariableTableContent" }
                            | null
                        }
                      | { __typename: "ST4TreeNode"; id: string }
                      | null
                  } | null> | null
                  comments: Array<{
                    __typename: "CommentNode"
                    id: string
                    comment:
                      | {
                          __typename: "BlockComment"
                          location: number
                          value?: string | null
                          authorDisplayName?: string | null
                          authorUserName?: string | null
                          commentKey: string
                          editDate?: any | null
                          modificationVersion?: number | null
                          replyToKey?: string | null
                          sortOrder?: number | null
                          commentType?: string | null
                          state?: string | null
                          color: string
                          persistanceState?: Types.PersistanceState | null
                          persistanceAction?: Types.PersistanceAction | null
                          node: {
                            __typename: "ST4Node"
                            aspectId: any
                            id: string
                            content?:
                              | { __typename: "LexiconContent" }
                              | { __typename: "LexiconEntryContent" }
                              | { __typename: "MediaContent" }
                              | { __typename: "MediaGroupContent" }
                              | { __typename: "ProjectContent" }
                              | { __typename: "TermContent" }
                              | { __typename: "TextContent"; id: string; historyNumber: number }
                              | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                              | { __typename: "VariableTableContent" }
                              | null
                          }
                          actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
                        }
                      | {
                          __typename: "TextComment"
                          endLocation: number
                          startLocation?: number | null
                          value?: string | null
                          authorDisplayName?: string | null
                          authorUserName?: string | null
                          commentKey: string
                          editDate?: any | null
                          modificationVersion?: number | null
                          replyToKey?: string | null
                          sortOrder?: number | null
                          commentType?: string | null
                          state?: string | null
                          color: string
                          persistanceState?: Types.PersistanceState | null
                          persistanceAction?: Types.PersistanceAction | null
                          node: {
                            __typename: "ST4Node"
                            aspectId: any
                            id: string
                            content?:
                              | { __typename: "LexiconContent" }
                              | { __typename: "LexiconEntryContent" }
                              | { __typename: "MediaContent" }
                              | { __typename: "MediaGroupContent" }
                              | { __typename: "ProjectContent" }
                              | { __typename: "TermContent" }
                              | { __typename: "TextContent"; id: string; historyNumber: number }
                              | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                              | { __typename: "VariableTableContent" }
                              | null
                          }
                          actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
                        }
                  }>
                }
              | { __typename: "VariableTableContent" }
              | null
          }
        }>
        children: Array<{
          __typename: "NodeRef"
          id: string
          target?:
            | { __typename: "CommentNode"; id: string }
            | { __typename: "ExternalSite"; id: string }
            | { __typename: "ST4Class"; id: string }
            | { __typename: "ST4Node"; label?: string | null; aspectId: any; id: string }
            | { __typename: "ST4TreeNode"; id: string }
            | null
        }>
        links?: Array<{
          __typename: "TextContentLink"
          ref?: string | null
          node?:
            | { __typename: "CommentNode"; id: string }
            | { __typename: "ExternalSite"; id: string }
            | { __typename: "ST4Class"; id: string }
            | {
                __typename: "ST4Node"
                id: string
                aspectId: any
                label?: string | null
                properties?: {
                  __typename: "ContentPropertyConnection"
                  totalCount: number
                  edges?: Array<{
                    __typename: "ContentPropertyEdge"
                    node?: {
                      __typename: "ContentProperty"
                      typeOfValue: Types.ContentPropertyValueType
                      name: string
                      value?: any | null
                      displayName: string
                    } | null
                  } | null> | null
                } | null
              }
            | { __typename: "ST4TreeNode"; id: string }
            | null
        } | null> | null
        images: Array<{
          __typename: "NodeRef"
          id: string
          target?:
            | { __typename: "CommentNode"; id: string }
            | { __typename: "ExternalSite"; id: string }
            | { __typename: "ST4Class"; id: string }
            | {
                __typename: "ST4Node"
                aspectId: any
                id: string
                releaseState: {
                  __typename: "ReleaseStateValue"
                  displayName: string
                  state: Types.ReleaseState
                  isImplicitlyReleased: boolean
                }
                content?:
                  | { __typename: "LexiconContent" }
                  | { __typename: "LexiconEntryContent" }
                  | {
                      __typename: "MediaContent"
                      media?:
                        | {
                            __typename: "AnimationMediaInfo"
                            id: string
                            resourceId?: string | null
                            thumbnail?: string | null
                            width?: number | null
                            height?: number | null
                            mediaType?: Types.MediaType | null
                          }
                        | {
                            __typename: "ImageMediaInfo"
                            id: string
                            resourceId?: string | null
                            thumbnail?: string | null
                            width?: number | null
                            height?: number | null
                            resolution?: number | null
                            colorDepth?: number | null
                            size?: number | null
                            mime?: string | null
                            extension?: string | null
                            allowCaching?: boolean | null
                            mediaType?: Types.MediaType | null
                            src?: string | null
                            language?: string | null
                          }
                        | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                        | {
                            __typename: "VectorGraphicsMediaInfo"
                            id: string
                            resourceId?: string | null
                            thumbnail?: string | null
                            width?: number | null
                            height?: number | null
                            allowCaching?: boolean | null
                            mediaType?: Types.MediaType | null
                            language?: string | null
                            labels: Array<{
                              __typename: "VectorGraphicsLabel"
                              id?: string | null
                              sequence?: number | null
                              autoValue?: string | null
                              text?: string | null
                              linkType?: Types.VectorGraphicsLabelLinkType | null
                              link?: string | null
                              ref?:
                                | {
                                    __typename: "GenericRef"
                                    id: string
                                    owner?:
                                      | { __typename: "CommentNode" }
                                      | { __typename: "ExternalSite" }
                                      | { __typename: "ST4Class" }
                                      | { __typename: "ST4Node"; id: string }
                                      | { __typename: "ST4TreeNode" }
                                      | null
                                    target?:
                                      | { __typename: "CommentNode" }
                                      | {
                                          __typename: "ExternalSite"
                                          id: string
                                          teaserData?: {
                                            __typename: "ExternalLinkTeaserData"
                                            title?: string | null
                                            summary?: string | null
                                            iconUrl?: string | null
                                            imageUrl?: string | null
                                            status?: Types.LinkTargetStatus | null
                                          } | null
                                        }
                                      | { __typename: "ST4Class" }
                                      | {
                                          __typename: "ST4Node"
                                          id: string
                                          aspectId: any
                                          label?: string | null
                                          properties?: {
                                            __typename: "ContentPropertyConnection"
                                            edges?: Array<{
                                              __typename: "ContentPropertyEdge"
                                              node?: {
                                                __typename: "ContentProperty"
                                                name: string
                                                value?: any | null
                                                displayName: string
                                              } | null
                                            } | null> | null
                                          } | null
                                        }
                                      | { __typename: "ST4TreeNode" }
                                      | null
                                  }
                                | {
                                    __typename: "NodeRef"
                                    id: string
                                    owner?:
                                      | { __typename: "CommentNode" }
                                      | { __typename: "ExternalSite" }
                                      | { __typename: "ST4Class" }
                                      | { __typename: "ST4Node"; id: string }
                                      | { __typename: "ST4TreeNode" }
                                      | null
                                    target?:
                                      | { __typename: "CommentNode" }
                                      | {
                                          __typename: "ExternalSite"
                                          id: string
                                          teaserData?: {
                                            __typename: "ExternalLinkTeaserData"
                                            title?: string | null
                                            summary?: string | null
                                            iconUrl?: string | null
                                            imageUrl?: string | null
                                            status?: Types.LinkTargetStatus | null
                                          } | null
                                        }
                                      | { __typename: "ST4Class" }
                                      | {
                                          __typename: "ST4Node"
                                          id: string
                                          aspectId: any
                                          label?: string | null
                                          properties?: {
                                            __typename: "ContentPropertyConnection"
                                            edges?: Array<{
                                              __typename: "ContentPropertyEdge"
                                              node?: {
                                                __typename: "ContentProperty"
                                                name: string
                                                value?: any | null
                                                displayName: string
                                              } | null
                                            } | null> | null
                                          } | null
                                        }
                                      | { __typename: "ST4TreeNode" }
                                      | null
                                  }
                                | null
                            }>
                          }
                        | {
                            __typename: "VideoMediaInfo"
                            id: string
                            resourceId?: string | null
                            thumbnail?: string | null
                            width?: number | null
                            height?: number | null
                            mediaType?: Types.MediaType | null
                          }
                        | null
                    }
                  | {
                      __typename: "MediaGroupContent"
                      children: Array<{
                        __typename: "NodeRef"
                        id: string
                        target?:
                          | { __typename: "CommentNode"; id: string }
                          | { __typename: "ExternalSite"; id: string }
                          | { __typename: "ST4Class"; id: string }
                          | {
                              __typename: "ST4Node"
                              id: string
                              aspectId: any
                              content?:
                                | { __typename: "LexiconContent" }
                                | { __typename: "LexiconEntryContent" }
                                | {
                                    __typename: "MediaContent"
                                    media?:
                                      | {
                                          __typename: "AnimationMediaInfo"
                                          id: string
                                          resourceId?: string | null
                                          thumbnail?: string | null
                                          width?: number | null
                                          height?: number | null
                                          mediaType?: Types.MediaType | null
                                        }
                                      | {
                                          __typename: "ImageMediaInfo"
                                          id: string
                                          resourceId?: string | null
                                          thumbnail?: string | null
                                          width?: number | null
                                          height?: number | null
                                          resolution?: number | null
                                          colorDepth?: number | null
                                          size?: number | null
                                          mime?: string | null
                                          extension?: string | null
                                          allowCaching?: boolean | null
                                          mediaType?: Types.MediaType | null
                                          src?: string | null
                                          language?: string | null
                                        }
                                      | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                                      | {
                                          __typename: "VectorGraphicsMediaInfo"
                                          id: string
                                          resourceId?: string | null
                                          thumbnail?: string | null
                                          width?: number | null
                                          height?: number | null
                                          allowCaching?: boolean | null
                                          mediaType?: Types.MediaType | null
                                          language?: string | null
                                          labels: Array<{
                                            __typename: "VectorGraphicsLabel"
                                            id?: string | null
                                            sequence?: number | null
                                            autoValue?: string | null
                                            text?: string | null
                                            linkType?: Types.VectorGraphicsLabelLinkType | null
                                            link?: string | null
                                            ref?:
                                              | {
                                                  __typename: "GenericRef"
                                                  id: string
                                                  owner?:
                                                    | { __typename: "CommentNode" }
                                                    | { __typename: "ExternalSite" }
                                                    | { __typename: "ST4Class" }
                                                    | { __typename: "ST4Node"; id: string }
                                                    | { __typename: "ST4TreeNode" }
                                                    | null
                                                  target?:
                                                    | { __typename: "CommentNode" }
                                                    | {
                                                        __typename: "ExternalSite"
                                                        id: string
                                                        teaserData?: {
                                                          __typename: "ExternalLinkTeaserData"
                                                          title?: string | null
                                                          summary?: string | null
                                                          iconUrl?: string | null
                                                          imageUrl?: string | null
                                                          status?: Types.LinkTargetStatus | null
                                                        } | null
                                                      }
                                                    | { __typename: "ST4Class" }
                                                    | {
                                                        __typename: "ST4Node"
                                                        id: string
                                                        aspectId: any
                                                        label?: string | null
                                                        properties?: {
                                                          __typename: "ContentPropertyConnection"
                                                          edges?: Array<{
                                                            __typename: "ContentPropertyEdge"
                                                            node?: {
                                                              __typename: "ContentProperty"
                                                              name: string
                                                              value?: any | null
                                                              displayName: string
                                                            } | null
                                                          } | null> | null
                                                        } | null
                                                      }
                                                    | { __typename: "ST4TreeNode" }
                                                    | null
                                                }
                                              | {
                                                  __typename: "NodeRef"
                                                  id: string
                                                  owner?:
                                                    | { __typename: "CommentNode" }
                                                    | { __typename: "ExternalSite" }
                                                    | { __typename: "ST4Class" }
                                                    | { __typename: "ST4Node"; id: string }
                                                    | { __typename: "ST4TreeNode" }
                                                    | null
                                                  target?:
                                                    | { __typename: "CommentNode" }
                                                    | {
                                                        __typename: "ExternalSite"
                                                        id: string
                                                        teaserData?: {
                                                          __typename: "ExternalLinkTeaserData"
                                                          title?: string | null
                                                          summary?: string | null
                                                          iconUrl?: string | null
                                                          imageUrl?: string | null
                                                          status?: Types.LinkTargetStatus | null
                                                        } | null
                                                      }
                                                    | { __typename: "ST4Class" }
                                                    | {
                                                        __typename: "ST4Node"
                                                        id: string
                                                        aspectId: any
                                                        label?: string | null
                                                        properties?: {
                                                          __typename: "ContentPropertyConnection"
                                                          edges?: Array<{
                                                            __typename: "ContentPropertyEdge"
                                                            node?: {
                                                              __typename: "ContentProperty"
                                                              name: string
                                                              value?: any | null
                                                              displayName: string
                                                            } | null
                                                          } | null> | null
                                                        } | null
                                                      }
                                                    | { __typename: "ST4TreeNode" }
                                                    | null
                                                }
                                              | null
                                          }>
                                        }
                                      | {
                                          __typename: "VideoMediaInfo"
                                          id: string
                                          resourceId?: string | null
                                          thumbnail?: string | null
                                          width?: number | null
                                          height?: number | null
                                          mediaType?: Types.MediaType | null
                                        }
                                      | null
                                  }
                                | {
                                    __typename: "MediaGroupContent"
                                    media?:
                                      | {
                                          __typename: "AnimationMediaInfo"
                                          id: string
                                          resourceId?: string | null
                                          thumbnail?: string | null
                                          width?: number | null
                                          height?: number | null
                                          mediaType?: Types.MediaType | null
                                        }
                                      | {
                                          __typename: "ImageMediaInfo"
                                          id: string
                                          resourceId?: string | null
                                          thumbnail?: string | null
                                          width?: number | null
                                          height?: number | null
                                          resolution?: number | null
                                          colorDepth?: number | null
                                          size?: number | null
                                          mime?: string | null
                                          extension?: string | null
                                          allowCaching?: boolean | null
                                          mediaType?: Types.MediaType | null
                                          src?: string | null
                                          language?: string | null
                                        }
                                      | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                                      | {
                                          __typename: "VectorGraphicsMediaInfo"
                                          id: string
                                          resourceId?: string | null
                                          thumbnail?: string | null
                                          width?: number | null
                                          height?: number | null
                                          allowCaching?: boolean | null
                                          mediaType?: Types.MediaType | null
                                          language?: string | null
                                          labels: Array<{
                                            __typename: "VectorGraphicsLabel"
                                            id?: string | null
                                            sequence?: number | null
                                            autoValue?: string | null
                                            text?: string | null
                                            linkType?: Types.VectorGraphicsLabelLinkType | null
                                            link?: string | null
                                            ref?:
                                              | {
                                                  __typename: "GenericRef"
                                                  id: string
                                                  owner?:
                                                    | { __typename: "CommentNode" }
                                                    | { __typename: "ExternalSite" }
                                                    | { __typename: "ST4Class" }
                                                    | { __typename: "ST4Node"; id: string }
                                                    | { __typename: "ST4TreeNode" }
                                                    | null
                                                  target?:
                                                    | { __typename: "CommentNode" }
                                                    | {
                                                        __typename: "ExternalSite"
                                                        id: string
                                                        teaserData?: {
                                                          __typename: "ExternalLinkTeaserData"
                                                          title?: string | null
                                                          summary?: string | null
                                                          iconUrl?: string | null
                                                          imageUrl?: string | null
                                                          status?: Types.LinkTargetStatus | null
                                                        } | null
                                                      }
                                                    | { __typename: "ST4Class" }
                                                    | {
                                                        __typename: "ST4Node"
                                                        id: string
                                                        aspectId: any
                                                        label?: string | null
                                                        properties?: {
                                                          __typename: "ContentPropertyConnection"
                                                          edges?: Array<{
                                                            __typename: "ContentPropertyEdge"
                                                            node?: {
                                                              __typename: "ContentProperty"
                                                              name: string
                                                              value?: any | null
                                                              displayName: string
                                                            } | null
                                                          } | null> | null
                                                        } | null
                                                      }
                                                    | { __typename: "ST4TreeNode" }
                                                    | null
                                                }
                                              | {
                                                  __typename: "NodeRef"
                                                  id: string
                                                  owner?:
                                                    | { __typename: "CommentNode" }
                                                    | { __typename: "ExternalSite" }
                                                    | { __typename: "ST4Class" }
                                                    | { __typename: "ST4Node"; id: string }
                                                    | { __typename: "ST4TreeNode" }
                                                    | null
                                                  target?:
                                                    | { __typename: "CommentNode" }
                                                    | {
                                                        __typename: "ExternalSite"
                                                        id: string
                                                        teaserData?: {
                                                          __typename: "ExternalLinkTeaserData"
                                                          title?: string | null
                                                          summary?: string | null
                                                          iconUrl?: string | null
                                                          imageUrl?: string | null
                                                          status?: Types.LinkTargetStatus | null
                                                        } | null
                                                      }
                                                    | { __typename: "ST4Class" }
                                                    | {
                                                        __typename: "ST4Node"
                                                        id: string
                                                        aspectId: any
                                                        label?: string | null
                                                        properties?: {
                                                          __typename: "ContentPropertyConnection"
                                                          edges?: Array<{
                                                            __typename: "ContentPropertyEdge"
                                                            node?: {
                                                              __typename: "ContentProperty"
                                                              name: string
                                                              value?: any | null
                                                              displayName: string
                                                            } | null
                                                          } | null> | null
                                                        } | null
                                                      }
                                                    | { __typename: "ST4TreeNode" }
                                                    | null
                                                }
                                              | null
                                          }>
                                        }
                                      | {
                                          __typename: "VideoMediaInfo"
                                          id: string
                                          resourceId?: string | null
                                          thumbnail?: string | null
                                          width?: number | null
                                          height?: number | null
                                          mediaType?: Types.MediaType | null
                                        }
                                      | null
                                  }
                                | { __typename: "ProjectContent" }
                                | { __typename: "TermContent" }
                                | { __typename: "TextContent" }
                                | { __typename: "TextGroupContent" }
                                | { __typename: "VariableTableContent" }
                                | null
                            }
                          | { __typename: "ST4TreeNode"; id: string }
                          | null
                      }>
                      media?:
                        | {
                            __typename: "AnimationMediaInfo"
                            id: string
                            resourceId?: string | null
                            thumbnail?: string | null
                            width?: number | null
                            height?: number | null
                            mediaType?: Types.MediaType | null
                          }
                        | {
                            __typename: "ImageMediaInfo"
                            id: string
                            resourceId?: string | null
                            thumbnail?: string | null
                            width?: number | null
                            height?: number | null
                            resolution?: number | null
                            colorDepth?: number | null
                            size?: number | null
                            mime?: string | null
                            extension?: string | null
                            allowCaching?: boolean | null
                            mediaType?: Types.MediaType | null
                            src?: string | null
                            language?: string | null
                          }
                        | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                        | {
                            __typename: "VectorGraphicsMediaInfo"
                            id: string
                            resourceId?: string | null
                            thumbnail?: string | null
                            width?: number | null
                            height?: number | null
                            allowCaching?: boolean | null
                            mediaType?: Types.MediaType | null
                            language?: string | null
                            labels: Array<{
                              __typename: "VectorGraphicsLabel"
                              id?: string | null
                              sequence?: number | null
                              autoValue?: string | null
                              text?: string | null
                              linkType?: Types.VectorGraphicsLabelLinkType | null
                              link?: string | null
                              ref?:
                                | {
                                    __typename: "GenericRef"
                                    id: string
                                    owner?:
                                      | { __typename: "CommentNode" }
                                      | { __typename: "ExternalSite" }
                                      | { __typename: "ST4Class" }
                                      | { __typename: "ST4Node"; id: string }
                                      | { __typename: "ST4TreeNode" }
                                      | null
                                    target?:
                                      | { __typename: "CommentNode" }
                                      | {
                                          __typename: "ExternalSite"
                                          id: string
                                          teaserData?: {
                                            __typename: "ExternalLinkTeaserData"
                                            title?: string | null
                                            summary?: string | null
                                            iconUrl?: string | null
                                            imageUrl?: string | null
                                            status?: Types.LinkTargetStatus | null
                                          } | null
                                        }
                                      | { __typename: "ST4Class" }
                                      | {
                                          __typename: "ST4Node"
                                          id: string
                                          aspectId: any
                                          label?: string | null
                                          properties?: {
                                            __typename: "ContentPropertyConnection"
                                            edges?: Array<{
                                              __typename: "ContentPropertyEdge"
                                              node?: {
                                                __typename: "ContentProperty"
                                                name: string
                                                value?: any | null
                                                displayName: string
                                              } | null
                                            } | null> | null
                                          } | null
                                        }
                                      | { __typename: "ST4TreeNode" }
                                      | null
                                  }
                                | {
                                    __typename: "NodeRef"
                                    id: string
                                    owner?:
                                      | { __typename: "CommentNode" }
                                      | { __typename: "ExternalSite" }
                                      | { __typename: "ST4Class" }
                                      | { __typename: "ST4Node"; id: string }
                                      | { __typename: "ST4TreeNode" }
                                      | null
                                    target?:
                                      | { __typename: "CommentNode" }
                                      | {
                                          __typename: "ExternalSite"
                                          id: string
                                          teaserData?: {
                                            __typename: "ExternalLinkTeaserData"
                                            title?: string | null
                                            summary?: string | null
                                            iconUrl?: string | null
                                            imageUrl?: string | null
                                            status?: Types.LinkTargetStatus | null
                                          } | null
                                        }
                                      | { __typename: "ST4Class" }
                                      | {
                                          __typename: "ST4Node"
                                          id: string
                                          aspectId: any
                                          label?: string | null
                                          properties?: {
                                            __typename: "ContentPropertyConnection"
                                            edges?: Array<{
                                              __typename: "ContentPropertyEdge"
                                              node?: {
                                                __typename: "ContentProperty"
                                                name: string
                                                value?: any | null
                                                displayName: string
                                              } | null
                                            } | null> | null
                                          } | null
                                        }
                                      | { __typename: "ST4TreeNode" }
                                      | null
                                  }
                                | null
                            }>
                          }
                        | {
                            __typename: "VideoMediaInfo"
                            id: string
                            resourceId?: string | null
                            thumbnail?: string | null
                            width?: number | null
                            height?: number | null
                            mediaType?: Types.MediaType | null
                          }
                        | null
                    }
                  | { __typename: "ProjectContent" }
                  | { __typename: "TermContent" }
                  | { __typename: "TextContent" }
                  | { __typename: "TextGroupContent" }
                  | { __typename: "VariableTableContent" }
                  | null
              }
            | { __typename: "ST4TreeNode"; id: string }
            | null
        }>
        variableTables?: Array<{
          __typename: "VariableTableReference"
          ref?: string | null
          node?:
            | { __typename: "CommentNode" }
            | { __typename: "ExternalSite" }
            | { __typename: "ST4Class" }
            | {
                __typename: "ST4Node"
                id: string
                label?: string | null
                aspectId: any
                content?:
                  | { __typename: "LexiconContent" }
                  | { __typename: "LexiconEntryContent" }
                  | { __typename: "MediaContent" }
                  | { __typename: "MediaGroupContent" }
                  | { __typename: "ProjectContent" }
                  | { __typename: "TermContent" }
                  | { __typename: "TextContent" }
                  | { __typename: "TextGroupContent" }
                  | {
                      __typename: "VariableTableContent"
                      xml?: string | null
                      valueSets: Array<{ __typename: "ValueSet"; name: string; valueSetId: string }>
                      variables: Array<{
                        __typename: "Variable"
                        name: string
                        variableId: string
                        values: Array<string>
                      }>
                    }
                  | null
              }
            | { __typename: "ST4TreeNode" }
            | null
        } | null> | null
        linkedDataNodes: Array<{
          __typename: "NodeRef"
          id: string
          target?:
            | { __typename: "CommentNode"; id: string }
            | { __typename: "ExternalSite"; id: string }
            | { __typename: "ST4Class"; id: string }
            | {
                __typename: "ST4Node"
                label?: string | null
                id: string
                metadata: Array<{
                  __typename: "MetaDataEntry"
                  label?: string | null
                  systemName: string
                  value?: string | null
                  values: Array<string>
                  users: Array<string>
                }>
                linkedOntologyNodes: Array<
                  | {
                      __typename: "GenericRef"
                      target?:
                        | { __typename: "CommentNode" }
                        | { __typename: "ExternalSite" }
                        | { __typename: "ST4Class" }
                        | { __typename: "ST4Node"; id: string; label?: string | null }
                        | { __typename: "ST4TreeNode" }
                        | null
                    }
                  | {
                      __typename: "NodeRef"
                      linkClassName: string
                      class?: { __typename: "ST4Class"; label?: string | null } | null
                      target?:
                        | { __typename: "CommentNode" }
                        | { __typename: "ExternalSite" }
                        | { __typename: "ST4Class" }
                        | { __typename: "ST4Node"; id: string; label?: string | null }
                        | { __typename: "ST4TreeNode" }
                        | null
                    }
                >
              }
            | { __typename: "ST4TreeNode"; id: string }
            | null
        }>
        lexiconEntries?: Array<{
          __typename: "NodeRef"
          id: string
          target?:
            | { __typename: "CommentNode"; id: string }
            | { __typename: "ExternalSite"; id: string }
            | { __typename: "ST4Class"; id: string }
            | {
                __typename: "ST4Node"
                aspectId: any
                label?: string | null
                id: string
                content?:
                  | { __typename: "LexiconContent" }
                  | {
                      __typename: "LexiconEntryContent"
                      descriptionXast?: string | null
                      subject?: string | null
                      sortKey?: string | null
                      lexicon:
                        | { __typename: "CommentNode"; id: string }
                        | { __typename: "ExternalSite"; id: string }
                        | { __typename: "ST4Class"; id: string }
                        | { __typename: "ST4Node"; label?: string | null; id: string }
                        | { __typename: "ST4TreeNode"; id: string }
                      terms: Array<
                        | { __typename: "CommentNode"; id: string }
                        | { __typename: "ExternalSite"; id: string }
                        | { __typename: "ST4Class"; id: string }
                        | {
                            __typename: "ST4Node"
                            id: string
                            label?: string | null
                            aspectId: any
                            content?:
                              | { __typename: "LexiconContent" }
                              | { __typename: "LexiconEntryContent" }
                              | { __typename: "MediaContent" }
                              | { __typename: "MediaGroupContent" }
                              | { __typename: "ProjectContent" }
                              | {
                                  __typename: "TermContent"
                                  gramaticalGender?: string | null
                                  validity?: string | null
                                  editLanguage?: string | null
                                }
                              | { __typename: "TextContent" }
                              | { __typename: "TextGroupContent" }
                              | { __typename: "VariableTableContent" }
                              | null
                          }
                        | { __typename: "ST4TreeNode"; id: string }
                      >
                      image?:
                        | { __typename: "CommentNode"; id: string }
                        | { __typename: "ExternalSite"; id: string }
                        | { __typename: "ST4Class"; id: string }
                        | {
                            __typename: "ST4Node"
                            aspectId: any
                            id: string
                            content?:
                              | { __typename: "LexiconContent" }
                              | { __typename: "LexiconEntryContent" }
                              | {
                                  __typename: "MediaContent"
                                  media?:
                                    | {
                                        __typename: "AnimationMediaInfo"
                                        thumbnail?: string | null
                                        mediaType?: Types.MediaType | null
                                      }
                                    | {
                                        __typename: "ImageMediaInfo"
                                        src?: string | null
                                        thumbnail?: string | null
                                        mediaType?: Types.MediaType | null
                                      }
                                    | { __typename: "NoMediaInfo" }
                                    | {
                                        __typename: "VectorGraphicsMediaInfo"
                                        svg?: string | null
                                        thumbnail?: string | null
                                        mediaType?: Types.MediaType | null
                                      }
                                    | {
                                        __typename: "VideoMediaInfo"
                                        thumbnail?: string | null
                                        mediaType?: Types.MediaType | null
                                      }
                                    | null
                                }
                              | { __typename: "MediaGroupContent" }
                              | { __typename: "ProjectContent" }
                              | { __typename: "TermContent" }
                              | { __typename: "TextContent" }
                              | { __typename: "TextGroupContent" }
                              | { __typename: "VariableTableContent" }
                              | null
                          }
                        | { __typename: "ST4TreeNode"; id: string }
                        | null
                    }
                  | { __typename: "MediaContent" }
                  | { __typename: "MediaGroupContent" }
                  | { __typename: "ProjectContent" }
                  | { __typename: "TermContent" }
                  | { __typename: "TextContent" }
                  | { __typename: "TextGroupContent" }
                  | { __typename: "VariableTableContent" }
                  | null
              }
            | { __typename: "ST4TreeNode"; id: string }
            | null
        } | null> | null
        comments: Array<{
          __typename: "CommentNode"
          id: string
          comment:
            | {
                __typename: "BlockComment"
                location: number
                value?: string | null
                authorDisplayName?: string | null
                authorUserName?: string | null
                commentKey: string
                editDate?: any | null
                modificationVersion?: number | null
                replyToKey?: string | null
                sortOrder?: number | null
                commentType?: string | null
                state?: string | null
                color: string
                persistanceState?: Types.PersistanceState | null
                persistanceAction?: Types.PersistanceAction | null
                node: {
                  __typename: "ST4Node"
                  aspectId: any
                  id: string
                  content?:
                    | { __typename: "LexiconContent" }
                    | { __typename: "LexiconEntryContent" }
                    | { __typename: "MediaContent" }
                    | { __typename: "MediaGroupContent" }
                    | { __typename: "ProjectContent" }
                    | { __typename: "TermContent" }
                    | { __typename: "TextContent"; id: string; historyNumber: number }
                    | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                    | { __typename: "VariableTableContent" }
                    | null
                }
                actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
              }
            | {
                __typename: "TextComment"
                endLocation: number
                startLocation?: number | null
                value?: string | null
                authorDisplayName?: string | null
                authorUserName?: string | null
                commentKey: string
                editDate?: any | null
                modificationVersion?: number | null
                replyToKey?: string | null
                sortOrder?: number | null
                commentType?: string | null
                state?: string | null
                color: string
                persistanceState?: Types.PersistanceState | null
                persistanceAction?: Types.PersistanceAction | null
                node: {
                  __typename: "ST4Node"
                  aspectId: any
                  id: string
                  content?:
                    | { __typename: "LexiconContent" }
                    | { __typename: "LexiconEntryContent" }
                    | { __typename: "MediaContent" }
                    | { __typename: "MediaGroupContent" }
                    | { __typename: "ProjectContent" }
                    | { __typename: "TermContent" }
                    | { __typename: "TextContent"; id: string; historyNumber: number }
                    | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                    | { __typename: "VariableTableContent" }
                    | null
                }
                actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
              }
        }>
      }
    | {
        __typename: "VariableTableContent"
        xml?: string | null
        valueSets: Array<{ __typename: "ValueSet"; name: string; valueSetId: string }>
        variables: Array<{ __typename: "Variable"; name: string; variableId: string; values: Array<string> }>
      }
    | null
  releaseState: {
    __typename: "ReleaseStateValue"
    displayName: string
    state: Types.ReleaseState
    isImplicitlyReleased: boolean
  }
}

export type VariableTableContentFragment = {
  __typename: "VariableTableContent"
  xml?: string | null
  valueSets: Array<{ __typename: "ValueSet"; name: string; valueSetId: string }>
  variables: Array<{ __typename: "Variable"; name: string; variableId: string; values: Array<string> }>
}

export type InternalLinkTeaserFragment = {
  __typename: "TextContentLink"
  ref?: string | null
  node?:
    | { __typename: "CommentNode"; id: string }
    | { __typename: "ExternalSite"; id: string }
    | { __typename: "ST4Class"; id: string }
    | {
        __typename: "ST4Node"
        id: string
        aspectId: any
        label?: string | null
        properties?: {
          __typename: "ContentPropertyConnection"
          totalCount: number
          edges?: Array<{
            __typename: "ContentPropertyEdge"
            node?: {
              __typename: "ContentProperty"
              typeOfValue: Types.ContentPropertyValueType
              name: string
              value?: any | null
              displayName: string
            } | null
          } | null> | null
        } | null
      }
    | { __typename: "ST4TreeNode"; id: string }
    | null
}

export type ITextContentPreview_TextContent_Fragment = {
  __typename: "TextContent"
  id: string
  xast?: string | null
  historyNumber: number
  links?: Array<{
    __typename: "TextContentLink"
    ref?: string | null
    node?:
      | { __typename: "CommentNode"; id: string }
      | { __typename: "ExternalSite"; id: string }
      | { __typename: "ST4Class"; id: string }
      | {
          __typename: "ST4Node"
          id: string
          aspectId: any
          label?: string | null
          properties?: {
            __typename: "ContentPropertyConnection"
            totalCount: number
            edges?: Array<{
              __typename: "ContentPropertyEdge"
              node?: {
                __typename: "ContentProperty"
                typeOfValue: Types.ContentPropertyValueType
                name: string
                value?: any | null
                displayName: string
              } | null
            } | null> | null
          } | null
        }
      | { __typename: "ST4TreeNode"; id: string }
      | null
  } | null> | null
  images: Array<{
    __typename: "NodeRef"
    id: string
    target?:
      | { __typename: "CommentNode"; id: string }
      | { __typename: "ExternalSite"; id: string }
      | { __typename: "ST4Class"; id: string }
      | {
          __typename: "ST4Node"
          aspectId: any
          id: string
          releaseState: {
            __typename: "ReleaseStateValue"
            displayName: string
            state: Types.ReleaseState
            isImplicitlyReleased: boolean
          }
          content?:
            | { __typename: "LexiconContent" }
            | { __typename: "LexiconEntryContent" }
            | {
                __typename: "MediaContent"
                media?:
                  | {
                      __typename: "AnimationMediaInfo"
                      id: string
                      resourceId?: string | null
                      thumbnail?: string | null
                      width?: number | null
                      height?: number | null
                      mediaType?: Types.MediaType | null
                    }
                  | {
                      __typename: "ImageMediaInfo"
                      id: string
                      resourceId?: string | null
                      thumbnail?: string | null
                      width?: number | null
                      height?: number | null
                      resolution?: number | null
                      colorDepth?: number | null
                      size?: number | null
                      mime?: string | null
                      extension?: string | null
                      allowCaching?: boolean | null
                      mediaType?: Types.MediaType | null
                      src?: string | null
                      language?: string | null
                    }
                  | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                  | {
                      __typename: "VectorGraphicsMediaInfo"
                      id: string
                      resourceId?: string | null
                      thumbnail?: string | null
                      width?: number | null
                      height?: number | null
                      allowCaching?: boolean | null
                      mediaType?: Types.MediaType | null
                      language?: string | null
                      labels: Array<{
                        __typename: "VectorGraphicsLabel"
                        id?: string | null
                        sequence?: number | null
                        autoValue?: string | null
                        text?: string | null
                        linkType?: Types.VectorGraphicsLabelLinkType | null
                        link?: string | null
                        ref?:
                          | {
                              __typename: "GenericRef"
                              id: string
                              owner?:
                                | { __typename: "CommentNode" }
                                | { __typename: "ExternalSite" }
                                | { __typename: "ST4Class" }
                                | { __typename: "ST4Node"; id: string }
                                | { __typename: "ST4TreeNode" }
                                | null
                              target?:
                                | { __typename: "CommentNode" }
                                | {
                                    __typename: "ExternalSite"
                                    id: string
                                    teaserData?: {
                                      __typename: "ExternalLinkTeaserData"
                                      title?: string | null
                                      summary?: string | null
                                      iconUrl?: string | null
                                      imageUrl?: string | null
                                      status?: Types.LinkTargetStatus | null
                                    } | null
                                  }
                                | { __typename: "ST4Class" }
                                | {
                                    __typename: "ST4Node"
                                    id: string
                                    aspectId: any
                                    label?: string | null
                                    properties?: {
                                      __typename: "ContentPropertyConnection"
                                      edges?: Array<{
                                        __typename: "ContentPropertyEdge"
                                        node?: {
                                          __typename: "ContentProperty"
                                          name: string
                                          value?: any | null
                                          displayName: string
                                        } | null
                                      } | null> | null
                                    } | null
                                  }
                                | { __typename: "ST4TreeNode" }
                                | null
                            }
                          | {
                              __typename: "NodeRef"
                              id: string
                              owner?:
                                | { __typename: "CommentNode" }
                                | { __typename: "ExternalSite" }
                                | { __typename: "ST4Class" }
                                | { __typename: "ST4Node"; id: string }
                                | { __typename: "ST4TreeNode" }
                                | null
                              target?:
                                | { __typename: "CommentNode" }
                                | {
                                    __typename: "ExternalSite"
                                    id: string
                                    teaserData?: {
                                      __typename: "ExternalLinkTeaserData"
                                      title?: string | null
                                      summary?: string | null
                                      iconUrl?: string | null
                                      imageUrl?: string | null
                                      status?: Types.LinkTargetStatus | null
                                    } | null
                                  }
                                | { __typename: "ST4Class" }
                                | {
                                    __typename: "ST4Node"
                                    id: string
                                    aspectId: any
                                    label?: string | null
                                    properties?: {
                                      __typename: "ContentPropertyConnection"
                                      edges?: Array<{
                                        __typename: "ContentPropertyEdge"
                                        node?: {
                                          __typename: "ContentProperty"
                                          name: string
                                          value?: any | null
                                          displayName: string
                                        } | null
                                      } | null> | null
                                    } | null
                                  }
                                | { __typename: "ST4TreeNode" }
                                | null
                            }
                          | null
                      }>
                    }
                  | {
                      __typename: "VideoMediaInfo"
                      id: string
                      resourceId?: string | null
                      thumbnail?: string | null
                      width?: number | null
                      height?: number | null
                      mediaType?: Types.MediaType | null
                    }
                  | null
              }
            | {
                __typename: "MediaGroupContent"
                children: Array<{
                  __typename: "NodeRef"
                  id: string
                  target?:
                    | { __typename: "CommentNode"; id: string }
                    | { __typename: "ExternalSite"; id: string }
                    | { __typename: "ST4Class"; id: string }
                    | {
                        __typename: "ST4Node"
                        id: string
                        aspectId: any
                        content?:
                          | { __typename: "LexiconContent" }
                          | { __typename: "LexiconEntryContent" }
                          | {
                              __typename: "MediaContent"
                              media?:
                                | {
                                    __typename: "AnimationMediaInfo"
                                    id: string
                                    resourceId?: string | null
                                    thumbnail?: string | null
                                    width?: number | null
                                    height?: number | null
                                    mediaType?: Types.MediaType | null
                                  }
                                | {
                                    __typename: "ImageMediaInfo"
                                    id: string
                                    resourceId?: string | null
                                    thumbnail?: string | null
                                    width?: number | null
                                    height?: number | null
                                    resolution?: number | null
                                    colorDepth?: number | null
                                    size?: number | null
                                    mime?: string | null
                                    extension?: string | null
                                    allowCaching?: boolean | null
                                    mediaType?: Types.MediaType | null
                                    src?: string | null
                                    language?: string | null
                                  }
                                | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                                | {
                                    __typename: "VectorGraphicsMediaInfo"
                                    id: string
                                    resourceId?: string | null
                                    thumbnail?: string | null
                                    width?: number | null
                                    height?: number | null
                                    allowCaching?: boolean | null
                                    mediaType?: Types.MediaType | null
                                    language?: string | null
                                    labels: Array<{
                                      __typename: "VectorGraphicsLabel"
                                      id?: string | null
                                      sequence?: number | null
                                      autoValue?: string | null
                                      text?: string | null
                                      linkType?: Types.VectorGraphicsLabelLinkType | null
                                      link?: string | null
                                      ref?:
                                        | {
                                            __typename: "GenericRef"
                                            id: string
                                            owner?:
                                              | { __typename: "CommentNode" }
                                              | { __typename: "ExternalSite" }
                                              | { __typename: "ST4Class" }
                                              | { __typename: "ST4Node"; id: string }
                                              | { __typename: "ST4TreeNode" }
                                              | null
                                            target?:
                                              | { __typename: "CommentNode" }
                                              | {
                                                  __typename: "ExternalSite"
                                                  id: string
                                                  teaserData?: {
                                                    __typename: "ExternalLinkTeaserData"
                                                    title?: string | null
                                                    summary?: string | null
                                                    iconUrl?: string | null
                                                    imageUrl?: string | null
                                                    status?: Types.LinkTargetStatus | null
                                                  } | null
                                                }
                                              | { __typename: "ST4Class" }
                                              | {
                                                  __typename: "ST4Node"
                                                  id: string
                                                  aspectId: any
                                                  label?: string | null
                                                  properties?: {
                                                    __typename: "ContentPropertyConnection"
                                                    edges?: Array<{
                                                      __typename: "ContentPropertyEdge"
                                                      node?: {
                                                        __typename: "ContentProperty"
                                                        name: string
                                                        value?: any | null
                                                        displayName: string
                                                      } | null
                                                    } | null> | null
                                                  } | null
                                                }
                                              | { __typename: "ST4TreeNode" }
                                              | null
                                          }
                                        | {
                                            __typename: "NodeRef"
                                            id: string
                                            owner?:
                                              | { __typename: "CommentNode" }
                                              | { __typename: "ExternalSite" }
                                              | { __typename: "ST4Class" }
                                              | { __typename: "ST4Node"; id: string }
                                              | { __typename: "ST4TreeNode" }
                                              | null
                                            target?:
                                              | { __typename: "CommentNode" }
                                              | {
                                                  __typename: "ExternalSite"
                                                  id: string
                                                  teaserData?: {
                                                    __typename: "ExternalLinkTeaserData"
                                                    title?: string | null
                                                    summary?: string | null
                                                    iconUrl?: string | null
                                                    imageUrl?: string | null
                                                    status?: Types.LinkTargetStatus | null
                                                  } | null
                                                }
                                              | { __typename: "ST4Class" }
                                              | {
                                                  __typename: "ST4Node"
                                                  id: string
                                                  aspectId: any
                                                  label?: string | null
                                                  properties?: {
                                                    __typename: "ContentPropertyConnection"
                                                    edges?: Array<{
                                                      __typename: "ContentPropertyEdge"
                                                      node?: {
                                                        __typename: "ContentProperty"
                                                        name: string
                                                        value?: any | null
                                                        displayName: string
                                                      } | null
                                                    } | null> | null
                                                  } | null
                                                }
                                              | { __typename: "ST4TreeNode" }
                                              | null
                                          }
                                        | null
                                    }>
                                  }
                                | {
                                    __typename: "VideoMediaInfo"
                                    id: string
                                    resourceId?: string | null
                                    thumbnail?: string | null
                                    width?: number | null
                                    height?: number | null
                                    mediaType?: Types.MediaType | null
                                  }
                                | null
                            }
                          | {
                              __typename: "MediaGroupContent"
                              media?:
                                | {
                                    __typename: "AnimationMediaInfo"
                                    id: string
                                    resourceId?: string | null
                                    thumbnail?: string | null
                                    width?: number | null
                                    height?: number | null
                                    mediaType?: Types.MediaType | null
                                  }
                                | {
                                    __typename: "ImageMediaInfo"
                                    id: string
                                    resourceId?: string | null
                                    thumbnail?: string | null
                                    width?: number | null
                                    height?: number | null
                                    resolution?: number | null
                                    colorDepth?: number | null
                                    size?: number | null
                                    mime?: string | null
                                    extension?: string | null
                                    allowCaching?: boolean | null
                                    mediaType?: Types.MediaType | null
                                    src?: string | null
                                    language?: string | null
                                  }
                                | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                                | {
                                    __typename: "VectorGraphicsMediaInfo"
                                    id: string
                                    resourceId?: string | null
                                    thumbnail?: string | null
                                    width?: number | null
                                    height?: number | null
                                    allowCaching?: boolean | null
                                    mediaType?: Types.MediaType | null
                                    language?: string | null
                                    labels: Array<{
                                      __typename: "VectorGraphicsLabel"
                                      id?: string | null
                                      sequence?: number | null
                                      autoValue?: string | null
                                      text?: string | null
                                      linkType?: Types.VectorGraphicsLabelLinkType | null
                                      link?: string | null
                                      ref?:
                                        | {
                                            __typename: "GenericRef"
                                            id: string
                                            owner?:
                                              | { __typename: "CommentNode" }
                                              | { __typename: "ExternalSite" }
                                              | { __typename: "ST4Class" }
                                              | { __typename: "ST4Node"; id: string }
                                              | { __typename: "ST4TreeNode" }
                                              | null
                                            target?:
                                              | { __typename: "CommentNode" }
                                              | {
                                                  __typename: "ExternalSite"
                                                  id: string
                                                  teaserData?: {
                                                    __typename: "ExternalLinkTeaserData"
                                                    title?: string | null
                                                    summary?: string | null
                                                    iconUrl?: string | null
                                                    imageUrl?: string | null
                                                    status?: Types.LinkTargetStatus | null
                                                  } | null
                                                }
                                              | { __typename: "ST4Class" }
                                              | {
                                                  __typename: "ST4Node"
                                                  id: string
                                                  aspectId: any
                                                  label?: string | null
                                                  properties?: {
                                                    __typename: "ContentPropertyConnection"
                                                    edges?: Array<{
                                                      __typename: "ContentPropertyEdge"
                                                      node?: {
                                                        __typename: "ContentProperty"
                                                        name: string
                                                        value?: any | null
                                                        displayName: string
                                                      } | null
                                                    } | null> | null
                                                  } | null
                                                }
                                              | { __typename: "ST4TreeNode" }
                                              | null
                                          }
                                        | {
                                            __typename: "NodeRef"
                                            id: string
                                            owner?:
                                              | { __typename: "CommentNode" }
                                              | { __typename: "ExternalSite" }
                                              | { __typename: "ST4Class" }
                                              | { __typename: "ST4Node"; id: string }
                                              | { __typename: "ST4TreeNode" }
                                              | null
                                            target?:
                                              | { __typename: "CommentNode" }
                                              | {
                                                  __typename: "ExternalSite"
                                                  id: string
                                                  teaserData?: {
                                                    __typename: "ExternalLinkTeaserData"
                                                    title?: string | null
                                                    summary?: string | null
                                                    iconUrl?: string | null
                                                    imageUrl?: string | null
                                                    status?: Types.LinkTargetStatus | null
                                                  } | null
                                                }
                                              | { __typename: "ST4Class" }
                                              | {
                                                  __typename: "ST4Node"
                                                  id: string
                                                  aspectId: any
                                                  label?: string | null
                                                  properties?: {
                                                    __typename: "ContentPropertyConnection"
                                                    edges?: Array<{
                                                      __typename: "ContentPropertyEdge"
                                                      node?: {
                                                        __typename: "ContentProperty"
                                                        name: string
                                                        value?: any | null
                                                        displayName: string
                                                      } | null
                                                    } | null> | null
                                                  } | null
                                                }
                                              | { __typename: "ST4TreeNode" }
                                              | null
                                          }
                                        | null
                                    }>
                                  }
                                | {
                                    __typename: "VideoMediaInfo"
                                    id: string
                                    resourceId?: string | null
                                    thumbnail?: string | null
                                    width?: number | null
                                    height?: number | null
                                    mediaType?: Types.MediaType | null
                                  }
                                | null
                            }
                          | { __typename: "ProjectContent" }
                          | { __typename: "TermContent" }
                          | { __typename: "TextContent" }
                          | { __typename: "TextGroupContent" }
                          | { __typename: "VariableTableContent" }
                          | null
                      }
                    | { __typename: "ST4TreeNode"; id: string }
                    | null
                }>
                media?:
                  | {
                      __typename: "AnimationMediaInfo"
                      id: string
                      resourceId?: string | null
                      thumbnail?: string | null
                      width?: number | null
                      height?: number | null
                      mediaType?: Types.MediaType | null
                    }
                  | {
                      __typename: "ImageMediaInfo"
                      id: string
                      resourceId?: string | null
                      thumbnail?: string | null
                      width?: number | null
                      height?: number | null
                      resolution?: number | null
                      colorDepth?: number | null
                      size?: number | null
                      mime?: string | null
                      extension?: string | null
                      allowCaching?: boolean | null
                      mediaType?: Types.MediaType | null
                      src?: string | null
                      language?: string | null
                    }
                  | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                  | {
                      __typename: "VectorGraphicsMediaInfo"
                      id: string
                      resourceId?: string | null
                      thumbnail?: string | null
                      width?: number | null
                      height?: number | null
                      allowCaching?: boolean | null
                      mediaType?: Types.MediaType | null
                      language?: string | null
                      labels: Array<{
                        __typename: "VectorGraphicsLabel"
                        id?: string | null
                        sequence?: number | null
                        autoValue?: string | null
                        text?: string | null
                        linkType?: Types.VectorGraphicsLabelLinkType | null
                        link?: string | null
                        ref?:
                          | {
                              __typename: "GenericRef"
                              id: string
                              owner?:
                                | { __typename: "CommentNode" }
                                | { __typename: "ExternalSite" }
                                | { __typename: "ST4Class" }
                                | { __typename: "ST4Node"; id: string }
                                | { __typename: "ST4TreeNode" }
                                | null
                              target?:
                                | { __typename: "CommentNode" }
                                | {
                                    __typename: "ExternalSite"
                                    id: string
                                    teaserData?: {
                                      __typename: "ExternalLinkTeaserData"
                                      title?: string | null
                                      summary?: string | null
                                      iconUrl?: string | null
                                      imageUrl?: string | null
                                      status?: Types.LinkTargetStatus | null
                                    } | null
                                  }
                                | { __typename: "ST4Class" }
                                | {
                                    __typename: "ST4Node"
                                    id: string
                                    aspectId: any
                                    label?: string | null
                                    properties?: {
                                      __typename: "ContentPropertyConnection"
                                      edges?: Array<{
                                        __typename: "ContentPropertyEdge"
                                        node?: {
                                          __typename: "ContentProperty"
                                          name: string
                                          value?: any | null
                                          displayName: string
                                        } | null
                                      } | null> | null
                                    } | null
                                  }
                                | { __typename: "ST4TreeNode" }
                                | null
                            }
                          | {
                              __typename: "NodeRef"
                              id: string
                              owner?:
                                | { __typename: "CommentNode" }
                                | { __typename: "ExternalSite" }
                                | { __typename: "ST4Class" }
                                | { __typename: "ST4Node"; id: string }
                                | { __typename: "ST4TreeNode" }
                                | null
                              target?:
                                | { __typename: "CommentNode" }
                                | {
                                    __typename: "ExternalSite"
                                    id: string
                                    teaserData?: {
                                      __typename: "ExternalLinkTeaserData"
                                      title?: string | null
                                      summary?: string | null
                                      iconUrl?: string | null
                                      imageUrl?: string | null
                                      status?: Types.LinkTargetStatus | null
                                    } | null
                                  }
                                | { __typename: "ST4Class" }
                                | {
                                    __typename: "ST4Node"
                                    id: string
                                    aspectId: any
                                    label?: string | null
                                    properties?: {
                                      __typename: "ContentPropertyConnection"
                                      edges?: Array<{
                                        __typename: "ContentPropertyEdge"
                                        node?: {
                                          __typename: "ContentProperty"
                                          name: string
                                          value?: any | null
                                          displayName: string
                                        } | null
                                      } | null> | null
                                    } | null
                                  }
                                | { __typename: "ST4TreeNode" }
                                | null
                            }
                          | null
                      }>
                    }
                  | {
                      __typename: "VideoMediaInfo"
                      id: string
                      resourceId?: string | null
                      thumbnail?: string | null
                      width?: number | null
                      height?: number | null
                      mediaType?: Types.MediaType | null
                    }
                  | null
              }
            | { __typename: "ProjectContent" }
            | { __typename: "TermContent" }
            | { __typename: "TextContent" }
            | { __typename: "TextGroupContent" }
            | { __typename: "VariableTableContent" }
            | null
        }
      | { __typename: "ST4TreeNode"; id: string }
      | null
  }>
  variableTables?: Array<{
    __typename: "VariableTableReference"
    ref?: string | null
    node?:
      | { __typename: "CommentNode" }
      | { __typename: "ExternalSite" }
      | { __typename: "ST4Class" }
      | {
          __typename: "ST4Node"
          id: string
          label?: string | null
          aspectId: any
          content?:
            | { __typename: "LexiconContent" }
            | { __typename: "LexiconEntryContent" }
            | { __typename: "MediaContent" }
            | { __typename: "MediaGroupContent" }
            | { __typename: "ProjectContent" }
            | { __typename: "TermContent" }
            | { __typename: "TextContent" }
            | { __typename: "TextGroupContent" }
            | {
                __typename: "VariableTableContent"
                xml?: string | null
                valueSets: Array<{ __typename: "ValueSet"; name: string; valueSetId: string }>
                variables: Array<{ __typename: "Variable"; name: string; variableId: string; values: Array<string> }>
              }
            | null
        }
      | { __typename: "ST4TreeNode" }
      | null
  } | null> | null
  linkedDataNodes: Array<{
    __typename: "NodeRef"
    id: string
    target?:
      | { __typename: "CommentNode"; id: string }
      | { __typename: "ExternalSite"; id: string }
      | { __typename: "ST4Class"; id: string }
      | {
          __typename: "ST4Node"
          label?: string | null
          id: string
          metadata: Array<{
            __typename: "MetaDataEntry"
            label?: string | null
            systemName: string
            value?: string | null
            values: Array<string>
            users: Array<string>
          }>
          linkedOntologyNodes: Array<
            | {
                __typename: "GenericRef"
                target?:
                  | { __typename: "CommentNode" }
                  | { __typename: "ExternalSite" }
                  | { __typename: "ST4Class" }
                  | { __typename: "ST4Node"; id: string; label?: string | null }
                  | { __typename: "ST4TreeNode" }
                  | null
              }
            | {
                __typename: "NodeRef"
                linkClassName: string
                class?: { __typename: "ST4Class"; label?: string | null } | null
                target?:
                  | { __typename: "CommentNode" }
                  | { __typename: "ExternalSite" }
                  | { __typename: "ST4Class" }
                  | { __typename: "ST4Node"; id: string; label?: string | null }
                  | { __typename: "ST4TreeNode" }
                  | null
              }
          >
        }
      | { __typename: "ST4TreeNode"; id: string }
      | null
  }>
  lexiconEntries?: Array<{
    __typename: "NodeRef"
    id: string
    target?:
      | { __typename: "CommentNode"; id: string }
      | { __typename: "ExternalSite"; id: string }
      | { __typename: "ST4Class"; id: string }
      | {
          __typename: "ST4Node"
          aspectId: any
          label?: string | null
          id: string
          content?:
            | { __typename: "LexiconContent" }
            | {
                __typename: "LexiconEntryContent"
                descriptionXast?: string | null
                subject?: string | null
                sortKey?: string | null
                lexicon:
                  | { __typename: "CommentNode"; id: string }
                  | { __typename: "ExternalSite"; id: string }
                  | { __typename: "ST4Class"; id: string }
                  | { __typename: "ST4Node"; label?: string | null; id: string }
                  | { __typename: "ST4TreeNode"; id: string }
                terms: Array<
                  | { __typename: "CommentNode"; id: string }
                  | { __typename: "ExternalSite"; id: string }
                  | { __typename: "ST4Class"; id: string }
                  | {
                      __typename: "ST4Node"
                      id: string
                      label?: string | null
                      aspectId: any
                      content?:
                        | { __typename: "LexiconContent" }
                        | { __typename: "LexiconEntryContent" }
                        | { __typename: "MediaContent" }
                        | { __typename: "MediaGroupContent" }
                        | { __typename: "ProjectContent" }
                        | {
                            __typename: "TermContent"
                            gramaticalGender?: string | null
                            validity?: string | null
                            editLanguage?: string | null
                          }
                        | { __typename: "TextContent" }
                        | { __typename: "TextGroupContent" }
                        | { __typename: "VariableTableContent" }
                        | null
                    }
                  | { __typename: "ST4TreeNode"; id: string }
                >
                image?:
                  | { __typename: "CommentNode"; id: string }
                  | { __typename: "ExternalSite"; id: string }
                  | { __typename: "ST4Class"; id: string }
                  | {
                      __typename: "ST4Node"
                      aspectId: any
                      id: string
                      content?:
                        | { __typename: "LexiconContent" }
                        | { __typename: "LexiconEntryContent" }
                        | {
                            __typename: "MediaContent"
                            media?:
                              | {
                                  __typename: "AnimationMediaInfo"
                                  thumbnail?: string | null
                                  mediaType?: Types.MediaType | null
                                }
                              | {
                                  __typename: "ImageMediaInfo"
                                  src?: string | null
                                  thumbnail?: string | null
                                  mediaType?: Types.MediaType | null
                                }
                              | { __typename: "NoMediaInfo" }
                              | {
                                  __typename: "VectorGraphicsMediaInfo"
                                  svg?: string | null
                                  thumbnail?: string | null
                                  mediaType?: Types.MediaType | null
                                }
                              | {
                                  __typename: "VideoMediaInfo"
                                  thumbnail?: string | null
                                  mediaType?: Types.MediaType | null
                                }
                              | null
                          }
                        | { __typename: "MediaGroupContent" }
                        | { __typename: "ProjectContent" }
                        | { __typename: "TermContent" }
                        | { __typename: "TextContent" }
                        | { __typename: "TextGroupContent" }
                        | { __typename: "VariableTableContent" }
                        | null
                    }
                  | { __typename: "ST4TreeNode"; id: string }
                  | null
              }
            | { __typename: "MediaContent" }
            | { __typename: "MediaGroupContent" }
            | { __typename: "ProjectContent" }
            | { __typename: "TermContent" }
            | { __typename: "TextContent" }
            | { __typename: "TextGroupContent" }
            | { __typename: "VariableTableContent" }
            | null
        }
      | { __typename: "ST4TreeNode"; id: string }
      | null
  } | null> | null
  comments: Array<{
    __typename: "CommentNode"
    id: string
    comment:
      | {
          __typename: "BlockComment"
          location: number
          value?: string | null
          authorDisplayName?: string | null
          authorUserName?: string | null
          commentKey: string
          editDate?: any | null
          modificationVersion?: number | null
          replyToKey?: string | null
          sortOrder?: number | null
          commentType?: string | null
          state?: string | null
          color: string
          persistanceState?: Types.PersistanceState | null
          persistanceAction?: Types.PersistanceAction | null
          node: {
            __typename: "ST4Node"
            aspectId: any
            id: string
            content?:
              | { __typename: "LexiconContent" }
              | { __typename: "LexiconEntryContent" }
              | { __typename: "MediaContent" }
              | { __typename: "MediaGroupContent" }
              | { __typename: "ProjectContent" }
              | { __typename: "TermContent" }
              | { __typename: "TextContent"; id: string; historyNumber: number }
              | { __typename: "TextGroupContent"; id: string; historyNumber: number }
              | { __typename: "VariableTableContent" }
              | null
          }
          actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
        }
      | {
          __typename: "TextComment"
          endLocation: number
          startLocation?: number | null
          value?: string | null
          authorDisplayName?: string | null
          authorUserName?: string | null
          commentKey: string
          editDate?: any | null
          modificationVersion?: number | null
          replyToKey?: string | null
          sortOrder?: number | null
          commentType?: string | null
          state?: string | null
          color: string
          persistanceState?: Types.PersistanceState | null
          persistanceAction?: Types.PersistanceAction | null
          node: {
            __typename: "ST4Node"
            aspectId: any
            id: string
            content?:
              | { __typename: "LexiconContent" }
              | { __typename: "LexiconEntryContent" }
              | { __typename: "MediaContent" }
              | { __typename: "MediaGroupContent" }
              | { __typename: "ProjectContent" }
              | { __typename: "TermContent" }
              | { __typename: "TextContent"; id: string; historyNumber: number }
              | { __typename: "TextGroupContent"; id: string; historyNumber: number }
              | { __typename: "VariableTableContent" }
              | null
          }
          actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
        }
  }>
}

export type ITextContentPreview_TextGroupContent_Fragment = {
  __typename: "TextGroupContent"
  currentNodeId?: any | null
  groupNodeId: any
  id: string
  xast?: string | null
  historyNumber: number
  children: Array<{
    __typename: "NodeRef"
    id: string
    target?:
      | { __typename: "CommentNode"; id: string }
      | { __typename: "ExternalSite"; id: string }
      | { __typename: "ST4Class"; id: string }
      | { __typename: "ST4Node"; label?: string | null; aspectId: any; id: string }
      | { __typename: "ST4TreeNode"; id: string }
      | null
  }>
  links?: Array<{
    __typename: "TextContentLink"
    ref?: string | null
    node?:
      | { __typename: "CommentNode"; id: string }
      | { __typename: "ExternalSite"; id: string }
      | { __typename: "ST4Class"; id: string }
      | {
          __typename: "ST4Node"
          id: string
          aspectId: any
          label?: string | null
          properties?: {
            __typename: "ContentPropertyConnection"
            totalCount: number
            edges?: Array<{
              __typename: "ContentPropertyEdge"
              node?: {
                __typename: "ContentProperty"
                typeOfValue: Types.ContentPropertyValueType
                name: string
                value?: any | null
                displayName: string
              } | null
            } | null> | null
          } | null
        }
      | { __typename: "ST4TreeNode"; id: string }
      | null
  } | null> | null
  images: Array<{
    __typename: "NodeRef"
    id: string
    target?:
      | { __typename: "CommentNode"; id: string }
      | { __typename: "ExternalSite"; id: string }
      | { __typename: "ST4Class"; id: string }
      | {
          __typename: "ST4Node"
          aspectId: any
          id: string
          releaseState: {
            __typename: "ReleaseStateValue"
            displayName: string
            state: Types.ReleaseState
            isImplicitlyReleased: boolean
          }
          content?:
            | { __typename: "LexiconContent" }
            | { __typename: "LexiconEntryContent" }
            | {
                __typename: "MediaContent"
                media?:
                  | {
                      __typename: "AnimationMediaInfo"
                      id: string
                      resourceId?: string | null
                      thumbnail?: string | null
                      width?: number | null
                      height?: number | null
                      mediaType?: Types.MediaType | null
                    }
                  | {
                      __typename: "ImageMediaInfo"
                      id: string
                      resourceId?: string | null
                      thumbnail?: string | null
                      width?: number | null
                      height?: number | null
                      resolution?: number | null
                      colorDepth?: number | null
                      size?: number | null
                      mime?: string | null
                      extension?: string | null
                      allowCaching?: boolean | null
                      mediaType?: Types.MediaType | null
                      src?: string | null
                      language?: string | null
                    }
                  | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                  | {
                      __typename: "VectorGraphicsMediaInfo"
                      id: string
                      resourceId?: string | null
                      thumbnail?: string | null
                      width?: number | null
                      height?: number | null
                      allowCaching?: boolean | null
                      mediaType?: Types.MediaType | null
                      language?: string | null
                      labels: Array<{
                        __typename: "VectorGraphicsLabel"
                        id?: string | null
                        sequence?: number | null
                        autoValue?: string | null
                        text?: string | null
                        linkType?: Types.VectorGraphicsLabelLinkType | null
                        link?: string | null
                        ref?:
                          | {
                              __typename: "GenericRef"
                              id: string
                              owner?:
                                | { __typename: "CommentNode" }
                                | { __typename: "ExternalSite" }
                                | { __typename: "ST4Class" }
                                | { __typename: "ST4Node"; id: string }
                                | { __typename: "ST4TreeNode" }
                                | null
                              target?:
                                | { __typename: "CommentNode" }
                                | {
                                    __typename: "ExternalSite"
                                    id: string
                                    teaserData?: {
                                      __typename: "ExternalLinkTeaserData"
                                      title?: string | null
                                      summary?: string | null
                                      iconUrl?: string | null
                                      imageUrl?: string | null
                                      status?: Types.LinkTargetStatus | null
                                    } | null
                                  }
                                | { __typename: "ST4Class" }
                                | {
                                    __typename: "ST4Node"
                                    id: string
                                    aspectId: any
                                    label?: string | null
                                    properties?: {
                                      __typename: "ContentPropertyConnection"
                                      edges?: Array<{
                                        __typename: "ContentPropertyEdge"
                                        node?: {
                                          __typename: "ContentProperty"
                                          name: string
                                          value?: any | null
                                          displayName: string
                                        } | null
                                      } | null> | null
                                    } | null
                                  }
                                | { __typename: "ST4TreeNode" }
                                | null
                            }
                          | {
                              __typename: "NodeRef"
                              id: string
                              owner?:
                                | { __typename: "CommentNode" }
                                | { __typename: "ExternalSite" }
                                | { __typename: "ST4Class" }
                                | { __typename: "ST4Node"; id: string }
                                | { __typename: "ST4TreeNode" }
                                | null
                              target?:
                                | { __typename: "CommentNode" }
                                | {
                                    __typename: "ExternalSite"
                                    id: string
                                    teaserData?: {
                                      __typename: "ExternalLinkTeaserData"
                                      title?: string | null
                                      summary?: string | null
                                      iconUrl?: string | null
                                      imageUrl?: string | null
                                      status?: Types.LinkTargetStatus | null
                                    } | null
                                  }
                                | { __typename: "ST4Class" }
                                | {
                                    __typename: "ST4Node"
                                    id: string
                                    aspectId: any
                                    label?: string | null
                                    properties?: {
                                      __typename: "ContentPropertyConnection"
                                      edges?: Array<{
                                        __typename: "ContentPropertyEdge"
                                        node?: {
                                          __typename: "ContentProperty"
                                          name: string
                                          value?: any | null
                                          displayName: string
                                        } | null
                                      } | null> | null
                                    } | null
                                  }
                                | { __typename: "ST4TreeNode" }
                                | null
                            }
                          | null
                      }>
                    }
                  | {
                      __typename: "VideoMediaInfo"
                      id: string
                      resourceId?: string | null
                      thumbnail?: string | null
                      width?: number | null
                      height?: number | null
                      mediaType?: Types.MediaType | null
                    }
                  | null
              }
            | {
                __typename: "MediaGroupContent"
                children: Array<{
                  __typename: "NodeRef"
                  id: string
                  target?:
                    | { __typename: "CommentNode"; id: string }
                    | { __typename: "ExternalSite"; id: string }
                    | { __typename: "ST4Class"; id: string }
                    | {
                        __typename: "ST4Node"
                        id: string
                        aspectId: any
                        content?:
                          | { __typename: "LexiconContent" }
                          | { __typename: "LexiconEntryContent" }
                          | {
                              __typename: "MediaContent"
                              media?:
                                | {
                                    __typename: "AnimationMediaInfo"
                                    id: string
                                    resourceId?: string | null
                                    thumbnail?: string | null
                                    width?: number | null
                                    height?: number | null
                                    mediaType?: Types.MediaType | null
                                  }
                                | {
                                    __typename: "ImageMediaInfo"
                                    id: string
                                    resourceId?: string | null
                                    thumbnail?: string | null
                                    width?: number | null
                                    height?: number | null
                                    resolution?: number | null
                                    colorDepth?: number | null
                                    size?: number | null
                                    mime?: string | null
                                    extension?: string | null
                                    allowCaching?: boolean | null
                                    mediaType?: Types.MediaType | null
                                    src?: string | null
                                    language?: string | null
                                  }
                                | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                                | {
                                    __typename: "VectorGraphicsMediaInfo"
                                    id: string
                                    resourceId?: string | null
                                    thumbnail?: string | null
                                    width?: number | null
                                    height?: number | null
                                    allowCaching?: boolean | null
                                    mediaType?: Types.MediaType | null
                                    language?: string | null
                                    labels: Array<{
                                      __typename: "VectorGraphicsLabel"
                                      id?: string | null
                                      sequence?: number | null
                                      autoValue?: string | null
                                      text?: string | null
                                      linkType?: Types.VectorGraphicsLabelLinkType | null
                                      link?: string | null
                                      ref?:
                                        | {
                                            __typename: "GenericRef"
                                            id: string
                                            owner?:
                                              | { __typename: "CommentNode" }
                                              | { __typename: "ExternalSite" }
                                              | { __typename: "ST4Class" }
                                              | { __typename: "ST4Node"; id: string }
                                              | { __typename: "ST4TreeNode" }
                                              | null
                                            target?:
                                              | { __typename: "CommentNode" }
                                              | {
                                                  __typename: "ExternalSite"
                                                  id: string
                                                  teaserData?: {
                                                    __typename: "ExternalLinkTeaserData"
                                                    title?: string | null
                                                    summary?: string | null
                                                    iconUrl?: string | null
                                                    imageUrl?: string | null
                                                    status?: Types.LinkTargetStatus | null
                                                  } | null
                                                }
                                              | { __typename: "ST4Class" }
                                              | {
                                                  __typename: "ST4Node"
                                                  id: string
                                                  aspectId: any
                                                  label?: string | null
                                                  properties?: {
                                                    __typename: "ContentPropertyConnection"
                                                    edges?: Array<{
                                                      __typename: "ContentPropertyEdge"
                                                      node?: {
                                                        __typename: "ContentProperty"
                                                        name: string
                                                        value?: any | null
                                                        displayName: string
                                                      } | null
                                                    } | null> | null
                                                  } | null
                                                }
                                              | { __typename: "ST4TreeNode" }
                                              | null
                                          }
                                        | {
                                            __typename: "NodeRef"
                                            id: string
                                            owner?:
                                              | { __typename: "CommentNode" }
                                              | { __typename: "ExternalSite" }
                                              | { __typename: "ST4Class" }
                                              | { __typename: "ST4Node"; id: string }
                                              | { __typename: "ST4TreeNode" }
                                              | null
                                            target?:
                                              | { __typename: "CommentNode" }
                                              | {
                                                  __typename: "ExternalSite"
                                                  id: string
                                                  teaserData?: {
                                                    __typename: "ExternalLinkTeaserData"
                                                    title?: string | null
                                                    summary?: string | null
                                                    iconUrl?: string | null
                                                    imageUrl?: string | null
                                                    status?: Types.LinkTargetStatus | null
                                                  } | null
                                                }
                                              | { __typename: "ST4Class" }
                                              | {
                                                  __typename: "ST4Node"
                                                  id: string
                                                  aspectId: any
                                                  label?: string | null
                                                  properties?: {
                                                    __typename: "ContentPropertyConnection"
                                                    edges?: Array<{
                                                      __typename: "ContentPropertyEdge"
                                                      node?: {
                                                        __typename: "ContentProperty"
                                                        name: string
                                                        value?: any | null
                                                        displayName: string
                                                      } | null
                                                    } | null> | null
                                                  } | null
                                                }
                                              | { __typename: "ST4TreeNode" }
                                              | null
                                          }
                                        | null
                                    }>
                                  }
                                | {
                                    __typename: "VideoMediaInfo"
                                    id: string
                                    resourceId?: string | null
                                    thumbnail?: string | null
                                    width?: number | null
                                    height?: number | null
                                    mediaType?: Types.MediaType | null
                                  }
                                | null
                            }
                          | {
                              __typename: "MediaGroupContent"
                              media?:
                                | {
                                    __typename: "AnimationMediaInfo"
                                    id: string
                                    resourceId?: string | null
                                    thumbnail?: string | null
                                    width?: number | null
                                    height?: number | null
                                    mediaType?: Types.MediaType | null
                                  }
                                | {
                                    __typename: "ImageMediaInfo"
                                    id: string
                                    resourceId?: string | null
                                    thumbnail?: string | null
                                    width?: number | null
                                    height?: number | null
                                    resolution?: number | null
                                    colorDepth?: number | null
                                    size?: number | null
                                    mime?: string | null
                                    extension?: string | null
                                    allowCaching?: boolean | null
                                    mediaType?: Types.MediaType | null
                                    src?: string | null
                                    language?: string | null
                                  }
                                | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                                | {
                                    __typename: "VectorGraphicsMediaInfo"
                                    id: string
                                    resourceId?: string | null
                                    thumbnail?: string | null
                                    width?: number | null
                                    height?: number | null
                                    allowCaching?: boolean | null
                                    mediaType?: Types.MediaType | null
                                    language?: string | null
                                    labels: Array<{
                                      __typename: "VectorGraphicsLabel"
                                      id?: string | null
                                      sequence?: number | null
                                      autoValue?: string | null
                                      text?: string | null
                                      linkType?: Types.VectorGraphicsLabelLinkType | null
                                      link?: string | null
                                      ref?:
                                        | {
                                            __typename: "GenericRef"
                                            id: string
                                            owner?:
                                              | { __typename: "CommentNode" }
                                              | { __typename: "ExternalSite" }
                                              | { __typename: "ST4Class" }
                                              | { __typename: "ST4Node"; id: string }
                                              | { __typename: "ST4TreeNode" }
                                              | null
                                            target?:
                                              | { __typename: "CommentNode" }
                                              | {
                                                  __typename: "ExternalSite"
                                                  id: string
                                                  teaserData?: {
                                                    __typename: "ExternalLinkTeaserData"
                                                    title?: string | null
                                                    summary?: string | null
                                                    iconUrl?: string | null
                                                    imageUrl?: string | null
                                                    status?: Types.LinkTargetStatus | null
                                                  } | null
                                                }
                                              | { __typename: "ST4Class" }
                                              | {
                                                  __typename: "ST4Node"
                                                  id: string
                                                  aspectId: any
                                                  label?: string | null
                                                  properties?: {
                                                    __typename: "ContentPropertyConnection"
                                                    edges?: Array<{
                                                      __typename: "ContentPropertyEdge"
                                                      node?: {
                                                        __typename: "ContentProperty"
                                                        name: string
                                                        value?: any | null
                                                        displayName: string
                                                      } | null
                                                    } | null> | null
                                                  } | null
                                                }
                                              | { __typename: "ST4TreeNode" }
                                              | null
                                          }
                                        | {
                                            __typename: "NodeRef"
                                            id: string
                                            owner?:
                                              | { __typename: "CommentNode" }
                                              | { __typename: "ExternalSite" }
                                              | { __typename: "ST4Class" }
                                              | { __typename: "ST4Node"; id: string }
                                              | { __typename: "ST4TreeNode" }
                                              | null
                                            target?:
                                              | { __typename: "CommentNode" }
                                              | {
                                                  __typename: "ExternalSite"
                                                  id: string
                                                  teaserData?: {
                                                    __typename: "ExternalLinkTeaserData"
                                                    title?: string | null
                                                    summary?: string | null
                                                    iconUrl?: string | null
                                                    imageUrl?: string | null
                                                    status?: Types.LinkTargetStatus | null
                                                  } | null
                                                }
                                              | { __typename: "ST4Class" }
                                              | {
                                                  __typename: "ST4Node"
                                                  id: string
                                                  aspectId: any
                                                  label?: string | null
                                                  properties?: {
                                                    __typename: "ContentPropertyConnection"
                                                    edges?: Array<{
                                                      __typename: "ContentPropertyEdge"
                                                      node?: {
                                                        __typename: "ContentProperty"
                                                        name: string
                                                        value?: any | null
                                                        displayName: string
                                                      } | null
                                                    } | null> | null
                                                  } | null
                                                }
                                              | { __typename: "ST4TreeNode" }
                                              | null
                                          }
                                        | null
                                    }>
                                  }
                                | {
                                    __typename: "VideoMediaInfo"
                                    id: string
                                    resourceId?: string | null
                                    thumbnail?: string | null
                                    width?: number | null
                                    height?: number | null
                                    mediaType?: Types.MediaType | null
                                  }
                                | null
                            }
                          | { __typename: "ProjectContent" }
                          | { __typename: "TermContent" }
                          | { __typename: "TextContent" }
                          | { __typename: "TextGroupContent" }
                          | { __typename: "VariableTableContent" }
                          | null
                      }
                    | { __typename: "ST4TreeNode"; id: string }
                    | null
                }>
                media?:
                  | {
                      __typename: "AnimationMediaInfo"
                      id: string
                      resourceId?: string | null
                      thumbnail?: string | null
                      width?: number | null
                      height?: number | null
                      mediaType?: Types.MediaType | null
                    }
                  | {
                      __typename: "ImageMediaInfo"
                      id: string
                      resourceId?: string | null
                      thumbnail?: string | null
                      width?: number | null
                      height?: number | null
                      resolution?: number | null
                      colorDepth?: number | null
                      size?: number | null
                      mime?: string | null
                      extension?: string | null
                      allowCaching?: boolean | null
                      mediaType?: Types.MediaType | null
                      src?: string | null
                      language?: string | null
                    }
                  | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                  | {
                      __typename: "VectorGraphicsMediaInfo"
                      id: string
                      resourceId?: string | null
                      thumbnail?: string | null
                      width?: number | null
                      height?: number | null
                      allowCaching?: boolean | null
                      mediaType?: Types.MediaType | null
                      language?: string | null
                      labels: Array<{
                        __typename: "VectorGraphicsLabel"
                        id?: string | null
                        sequence?: number | null
                        autoValue?: string | null
                        text?: string | null
                        linkType?: Types.VectorGraphicsLabelLinkType | null
                        link?: string | null
                        ref?:
                          | {
                              __typename: "GenericRef"
                              id: string
                              owner?:
                                | { __typename: "CommentNode" }
                                | { __typename: "ExternalSite" }
                                | { __typename: "ST4Class" }
                                | { __typename: "ST4Node"; id: string }
                                | { __typename: "ST4TreeNode" }
                                | null
                              target?:
                                | { __typename: "CommentNode" }
                                | {
                                    __typename: "ExternalSite"
                                    id: string
                                    teaserData?: {
                                      __typename: "ExternalLinkTeaserData"
                                      title?: string | null
                                      summary?: string | null
                                      iconUrl?: string | null
                                      imageUrl?: string | null
                                      status?: Types.LinkTargetStatus | null
                                    } | null
                                  }
                                | { __typename: "ST4Class" }
                                | {
                                    __typename: "ST4Node"
                                    id: string
                                    aspectId: any
                                    label?: string | null
                                    properties?: {
                                      __typename: "ContentPropertyConnection"
                                      edges?: Array<{
                                        __typename: "ContentPropertyEdge"
                                        node?: {
                                          __typename: "ContentProperty"
                                          name: string
                                          value?: any | null
                                          displayName: string
                                        } | null
                                      } | null> | null
                                    } | null
                                  }
                                | { __typename: "ST4TreeNode" }
                                | null
                            }
                          | {
                              __typename: "NodeRef"
                              id: string
                              owner?:
                                | { __typename: "CommentNode" }
                                | { __typename: "ExternalSite" }
                                | { __typename: "ST4Class" }
                                | { __typename: "ST4Node"; id: string }
                                | { __typename: "ST4TreeNode" }
                                | null
                              target?:
                                | { __typename: "CommentNode" }
                                | {
                                    __typename: "ExternalSite"
                                    id: string
                                    teaserData?: {
                                      __typename: "ExternalLinkTeaserData"
                                      title?: string | null
                                      summary?: string | null
                                      iconUrl?: string | null
                                      imageUrl?: string | null
                                      status?: Types.LinkTargetStatus | null
                                    } | null
                                  }
                                | { __typename: "ST4Class" }
                                | {
                                    __typename: "ST4Node"
                                    id: string
                                    aspectId: any
                                    label?: string | null
                                    properties?: {
                                      __typename: "ContentPropertyConnection"
                                      edges?: Array<{
                                        __typename: "ContentPropertyEdge"
                                        node?: {
                                          __typename: "ContentProperty"
                                          name: string
                                          value?: any | null
                                          displayName: string
                                        } | null
                                      } | null> | null
                                    } | null
                                  }
                                | { __typename: "ST4TreeNode" }
                                | null
                            }
                          | null
                      }>
                    }
                  | {
                      __typename: "VideoMediaInfo"
                      id: string
                      resourceId?: string | null
                      thumbnail?: string | null
                      width?: number | null
                      height?: number | null
                      mediaType?: Types.MediaType | null
                    }
                  | null
              }
            | { __typename: "ProjectContent" }
            | { __typename: "TermContent" }
            | { __typename: "TextContent" }
            | { __typename: "TextGroupContent" }
            | { __typename: "VariableTableContent" }
            | null
        }
      | { __typename: "ST4TreeNode"; id: string }
      | null
  }>
  variableTables?: Array<{
    __typename: "VariableTableReference"
    ref?: string | null
    node?:
      | { __typename: "CommentNode" }
      | { __typename: "ExternalSite" }
      | { __typename: "ST4Class" }
      | {
          __typename: "ST4Node"
          id: string
          label?: string | null
          aspectId: any
          content?:
            | { __typename: "LexiconContent" }
            | { __typename: "LexiconEntryContent" }
            | { __typename: "MediaContent" }
            | { __typename: "MediaGroupContent" }
            | { __typename: "ProjectContent" }
            | { __typename: "TermContent" }
            | { __typename: "TextContent" }
            | { __typename: "TextGroupContent" }
            | {
                __typename: "VariableTableContent"
                xml?: string | null
                valueSets: Array<{ __typename: "ValueSet"; name: string; valueSetId: string }>
                variables: Array<{ __typename: "Variable"; name: string; variableId: string; values: Array<string> }>
              }
            | null
        }
      | { __typename: "ST4TreeNode" }
      | null
  } | null> | null
  linkedDataNodes: Array<{
    __typename: "NodeRef"
    id: string
    target?:
      | { __typename: "CommentNode"; id: string }
      | { __typename: "ExternalSite"; id: string }
      | { __typename: "ST4Class"; id: string }
      | {
          __typename: "ST4Node"
          label?: string | null
          id: string
          metadata: Array<{
            __typename: "MetaDataEntry"
            label?: string | null
            systemName: string
            value?: string | null
            values: Array<string>
            users: Array<string>
          }>
          linkedOntologyNodes: Array<
            | {
                __typename: "GenericRef"
                target?:
                  | { __typename: "CommentNode" }
                  | { __typename: "ExternalSite" }
                  | { __typename: "ST4Class" }
                  | { __typename: "ST4Node"; id: string; label?: string | null }
                  | { __typename: "ST4TreeNode" }
                  | null
              }
            | {
                __typename: "NodeRef"
                linkClassName: string
                class?: { __typename: "ST4Class"; label?: string | null } | null
                target?:
                  | { __typename: "CommentNode" }
                  | { __typename: "ExternalSite" }
                  | { __typename: "ST4Class" }
                  | { __typename: "ST4Node"; id: string; label?: string | null }
                  | { __typename: "ST4TreeNode" }
                  | null
              }
          >
        }
      | { __typename: "ST4TreeNode"; id: string }
      | null
  }>
  lexiconEntries?: Array<{
    __typename: "NodeRef"
    id: string
    target?:
      | { __typename: "CommentNode"; id: string }
      | { __typename: "ExternalSite"; id: string }
      | { __typename: "ST4Class"; id: string }
      | {
          __typename: "ST4Node"
          aspectId: any
          label?: string | null
          id: string
          content?:
            | { __typename: "LexiconContent" }
            | {
                __typename: "LexiconEntryContent"
                descriptionXast?: string | null
                subject?: string | null
                sortKey?: string | null
                lexicon:
                  | { __typename: "CommentNode"; id: string }
                  | { __typename: "ExternalSite"; id: string }
                  | { __typename: "ST4Class"; id: string }
                  | { __typename: "ST4Node"; label?: string | null; id: string }
                  | { __typename: "ST4TreeNode"; id: string }
                terms: Array<
                  | { __typename: "CommentNode"; id: string }
                  | { __typename: "ExternalSite"; id: string }
                  | { __typename: "ST4Class"; id: string }
                  | {
                      __typename: "ST4Node"
                      id: string
                      label?: string | null
                      aspectId: any
                      content?:
                        | { __typename: "LexiconContent" }
                        | { __typename: "LexiconEntryContent" }
                        | { __typename: "MediaContent" }
                        | { __typename: "MediaGroupContent" }
                        | { __typename: "ProjectContent" }
                        | {
                            __typename: "TermContent"
                            gramaticalGender?: string | null
                            validity?: string | null
                            editLanguage?: string | null
                          }
                        | { __typename: "TextContent" }
                        | { __typename: "TextGroupContent" }
                        | { __typename: "VariableTableContent" }
                        | null
                    }
                  | { __typename: "ST4TreeNode"; id: string }
                >
                image?:
                  | { __typename: "CommentNode"; id: string }
                  | { __typename: "ExternalSite"; id: string }
                  | { __typename: "ST4Class"; id: string }
                  | {
                      __typename: "ST4Node"
                      aspectId: any
                      id: string
                      content?:
                        | { __typename: "LexiconContent" }
                        | { __typename: "LexiconEntryContent" }
                        | {
                            __typename: "MediaContent"
                            media?:
                              | {
                                  __typename: "AnimationMediaInfo"
                                  thumbnail?: string | null
                                  mediaType?: Types.MediaType | null
                                }
                              | {
                                  __typename: "ImageMediaInfo"
                                  src?: string | null
                                  thumbnail?: string | null
                                  mediaType?: Types.MediaType | null
                                }
                              | { __typename: "NoMediaInfo" }
                              | {
                                  __typename: "VectorGraphicsMediaInfo"
                                  svg?: string | null
                                  thumbnail?: string | null
                                  mediaType?: Types.MediaType | null
                                }
                              | {
                                  __typename: "VideoMediaInfo"
                                  thumbnail?: string | null
                                  mediaType?: Types.MediaType | null
                                }
                              | null
                          }
                        | { __typename: "MediaGroupContent" }
                        | { __typename: "ProjectContent" }
                        | { __typename: "TermContent" }
                        | { __typename: "TextContent" }
                        | { __typename: "TextGroupContent" }
                        | { __typename: "VariableTableContent" }
                        | null
                    }
                  | { __typename: "ST4TreeNode"; id: string }
                  | null
              }
            | { __typename: "MediaContent" }
            | { __typename: "MediaGroupContent" }
            | { __typename: "ProjectContent" }
            | { __typename: "TermContent" }
            | { __typename: "TextContent" }
            | { __typename: "TextGroupContent" }
            | { __typename: "VariableTableContent" }
            | null
        }
      | { __typename: "ST4TreeNode"; id: string }
      | null
  } | null> | null
  comments: Array<{
    __typename: "CommentNode"
    id: string
    comment:
      | {
          __typename: "BlockComment"
          location: number
          value?: string | null
          authorDisplayName?: string | null
          authorUserName?: string | null
          commentKey: string
          editDate?: any | null
          modificationVersion?: number | null
          replyToKey?: string | null
          sortOrder?: number | null
          commentType?: string | null
          state?: string | null
          color: string
          persistanceState?: Types.PersistanceState | null
          persistanceAction?: Types.PersistanceAction | null
          node: {
            __typename: "ST4Node"
            aspectId: any
            id: string
            content?:
              | { __typename: "LexiconContent" }
              | { __typename: "LexiconEntryContent" }
              | { __typename: "MediaContent" }
              | { __typename: "MediaGroupContent" }
              | { __typename: "ProjectContent" }
              | { __typename: "TermContent" }
              | { __typename: "TextContent"; id: string; historyNumber: number }
              | { __typename: "TextGroupContent"; id: string; historyNumber: number }
              | { __typename: "VariableTableContent" }
              | null
          }
          actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
        }
      | {
          __typename: "TextComment"
          endLocation: number
          startLocation?: number | null
          value?: string | null
          authorDisplayName?: string | null
          authorUserName?: string | null
          commentKey: string
          editDate?: any | null
          modificationVersion?: number | null
          replyToKey?: string | null
          sortOrder?: number | null
          commentType?: string | null
          state?: string | null
          color: string
          persistanceState?: Types.PersistanceState | null
          persistanceAction?: Types.PersistanceAction | null
          node: {
            __typename: "ST4Node"
            aspectId: any
            id: string
            content?:
              | { __typename: "LexiconContent" }
              | { __typename: "LexiconEntryContent" }
              | { __typename: "MediaContent" }
              | { __typename: "MediaGroupContent" }
              | { __typename: "ProjectContent" }
              | { __typename: "TermContent" }
              | { __typename: "TextContent"; id: string; historyNumber: number }
              | { __typename: "TextGroupContent"; id: string; historyNumber: number }
              | { __typename: "VariableTableContent" }
              | null
          }
          actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
        }
  }>
}

export type ITextContentPreviewFragment =
  | ITextContentPreview_TextContent_Fragment
  | ITextContentPreview_TextGroupContent_Fragment

export type St4NodeTeaserInfoFragment = {
  __typename: "ST4Node"
  id: string
  aspectId: any
  label?: string | null
  properties?: {
    __typename: "ContentPropertyConnection"
    edges?: Array<{
      __typename: "ContentPropertyEdge"
      node?: { __typename: "ContentProperty"; name: string; value?: any | null; displayName: string } | null
    } | null> | null
  } | null
}

export type ImageData_MediaContent_Fragment = {
  __typename: "MediaContent"
  media?:
    | {
        __typename: "AnimationMediaInfo"
        id: string
        resourceId?: string | null
        thumbnail?: string | null
        width?: number | null
        height?: number | null
        mediaType?: Types.MediaType | null
      }
    | {
        __typename: "ImageMediaInfo"
        id: string
        resourceId?: string | null
        thumbnail?: string | null
        width?: number | null
        height?: number | null
        resolution?: number | null
        colorDepth?: number | null
        size?: number | null
        mime?: string | null
        extension?: string | null
        allowCaching?: boolean | null
        mediaType?: Types.MediaType | null
        src?: string | null
        language?: string | null
      }
    | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
    | {
        __typename: "VectorGraphicsMediaInfo"
        id: string
        resourceId?: string | null
        thumbnail?: string | null
        width?: number | null
        height?: number | null
        allowCaching?: boolean | null
        mediaType?: Types.MediaType | null
        language?: string | null
        labels: Array<{
          __typename: "VectorGraphicsLabel"
          id?: string | null
          sequence?: number | null
          autoValue?: string | null
          text?: string | null
          linkType?: Types.VectorGraphicsLabelLinkType | null
          link?: string | null
          ref?:
            | {
                __typename: "GenericRef"
                id: string
                owner?:
                  | { __typename: "CommentNode" }
                  | { __typename: "ExternalSite" }
                  | { __typename: "ST4Class" }
                  | { __typename: "ST4Node"; id: string }
                  | { __typename: "ST4TreeNode" }
                  | null
                target?:
                  | { __typename: "CommentNode" }
                  | {
                      __typename: "ExternalSite"
                      id: string
                      teaserData?: {
                        __typename: "ExternalLinkTeaserData"
                        title?: string | null
                        summary?: string | null
                        iconUrl?: string | null
                        imageUrl?: string | null
                        status?: Types.LinkTargetStatus | null
                      } | null
                    }
                  | { __typename: "ST4Class" }
                  | {
                      __typename: "ST4Node"
                      id: string
                      aspectId: any
                      label?: string | null
                      properties?: {
                        __typename: "ContentPropertyConnection"
                        edges?: Array<{
                          __typename: "ContentPropertyEdge"
                          node?: {
                            __typename: "ContentProperty"
                            name: string
                            value?: any | null
                            displayName: string
                          } | null
                        } | null> | null
                      } | null
                    }
                  | { __typename: "ST4TreeNode" }
                  | null
              }
            | {
                __typename: "NodeRef"
                id: string
                owner?:
                  | { __typename: "CommentNode" }
                  | { __typename: "ExternalSite" }
                  | { __typename: "ST4Class" }
                  | { __typename: "ST4Node"; id: string }
                  | { __typename: "ST4TreeNode" }
                  | null
                target?:
                  | { __typename: "CommentNode" }
                  | {
                      __typename: "ExternalSite"
                      id: string
                      teaserData?: {
                        __typename: "ExternalLinkTeaserData"
                        title?: string | null
                        summary?: string | null
                        iconUrl?: string | null
                        imageUrl?: string | null
                        status?: Types.LinkTargetStatus | null
                      } | null
                    }
                  | { __typename: "ST4Class" }
                  | {
                      __typename: "ST4Node"
                      id: string
                      aspectId: any
                      label?: string | null
                      properties?: {
                        __typename: "ContentPropertyConnection"
                        edges?: Array<{
                          __typename: "ContentPropertyEdge"
                          node?: {
                            __typename: "ContentProperty"
                            name: string
                            value?: any | null
                            displayName: string
                          } | null
                        } | null> | null
                      } | null
                    }
                  | { __typename: "ST4TreeNode" }
                  | null
              }
            | null
        }>
      }
    | {
        __typename: "VideoMediaInfo"
        id: string
        resourceId?: string | null
        thumbnail?: string | null
        width?: number | null
        height?: number | null
        mediaType?: Types.MediaType | null
      }
    | null
}

export type ImageData_MediaGroupContent_Fragment = {
  __typename: "MediaGroupContent"
  children: Array<{
    __typename: "NodeRef"
    id: string
    target?:
      | { __typename: "CommentNode"; id: string }
      | { __typename: "ExternalSite"; id: string }
      | { __typename: "ST4Class"; id: string }
      | {
          __typename: "ST4Node"
          id: string
          aspectId: any
          content?:
            | { __typename: "LexiconContent" }
            | { __typename: "LexiconEntryContent" }
            | {
                __typename: "MediaContent"
                media?:
                  | {
                      __typename: "AnimationMediaInfo"
                      id: string
                      resourceId?: string | null
                      thumbnail?: string | null
                      width?: number | null
                      height?: number | null
                      mediaType?: Types.MediaType | null
                    }
                  | {
                      __typename: "ImageMediaInfo"
                      id: string
                      resourceId?: string | null
                      thumbnail?: string | null
                      width?: number | null
                      height?: number | null
                      resolution?: number | null
                      colorDepth?: number | null
                      size?: number | null
                      mime?: string | null
                      extension?: string | null
                      allowCaching?: boolean | null
                      mediaType?: Types.MediaType | null
                      src?: string | null
                      language?: string | null
                    }
                  | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                  | {
                      __typename: "VectorGraphicsMediaInfo"
                      id: string
                      resourceId?: string | null
                      thumbnail?: string | null
                      width?: number | null
                      height?: number | null
                      allowCaching?: boolean | null
                      mediaType?: Types.MediaType | null
                      language?: string | null
                      labels: Array<{
                        __typename: "VectorGraphicsLabel"
                        id?: string | null
                        sequence?: number | null
                        autoValue?: string | null
                        text?: string | null
                        linkType?: Types.VectorGraphicsLabelLinkType | null
                        link?: string | null
                        ref?:
                          | {
                              __typename: "GenericRef"
                              id: string
                              owner?:
                                | { __typename: "CommentNode" }
                                | { __typename: "ExternalSite" }
                                | { __typename: "ST4Class" }
                                | { __typename: "ST4Node"; id: string }
                                | { __typename: "ST4TreeNode" }
                                | null
                              target?:
                                | { __typename: "CommentNode" }
                                | {
                                    __typename: "ExternalSite"
                                    id: string
                                    teaserData?: {
                                      __typename: "ExternalLinkTeaserData"
                                      title?: string | null
                                      summary?: string | null
                                      iconUrl?: string | null
                                      imageUrl?: string | null
                                      status?: Types.LinkTargetStatus | null
                                    } | null
                                  }
                                | { __typename: "ST4Class" }
                                | {
                                    __typename: "ST4Node"
                                    id: string
                                    aspectId: any
                                    label?: string | null
                                    properties?: {
                                      __typename: "ContentPropertyConnection"
                                      edges?: Array<{
                                        __typename: "ContentPropertyEdge"
                                        node?: {
                                          __typename: "ContentProperty"
                                          name: string
                                          value?: any | null
                                          displayName: string
                                        } | null
                                      } | null> | null
                                    } | null
                                  }
                                | { __typename: "ST4TreeNode" }
                                | null
                            }
                          | {
                              __typename: "NodeRef"
                              id: string
                              owner?:
                                | { __typename: "CommentNode" }
                                | { __typename: "ExternalSite" }
                                | { __typename: "ST4Class" }
                                | { __typename: "ST4Node"; id: string }
                                | { __typename: "ST4TreeNode" }
                                | null
                              target?:
                                | { __typename: "CommentNode" }
                                | {
                                    __typename: "ExternalSite"
                                    id: string
                                    teaserData?: {
                                      __typename: "ExternalLinkTeaserData"
                                      title?: string | null
                                      summary?: string | null
                                      iconUrl?: string | null
                                      imageUrl?: string | null
                                      status?: Types.LinkTargetStatus | null
                                    } | null
                                  }
                                | { __typename: "ST4Class" }
                                | {
                                    __typename: "ST4Node"
                                    id: string
                                    aspectId: any
                                    label?: string | null
                                    properties?: {
                                      __typename: "ContentPropertyConnection"
                                      edges?: Array<{
                                        __typename: "ContentPropertyEdge"
                                        node?: {
                                          __typename: "ContentProperty"
                                          name: string
                                          value?: any | null
                                          displayName: string
                                        } | null
                                      } | null> | null
                                    } | null
                                  }
                                | { __typename: "ST4TreeNode" }
                                | null
                            }
                          | null
                      }>
                    }
                  | {
                      __typename: "VideoMediaInfo"
                      id: string
                      resourceId?: string | null
                      thumbnail?: string | null
                      width?: number | null
                      height?: number | null
                      mediaType?: Types.MediaType | null
                    }
                  | null
              }
            | {
                __typename: "MediaGroupContent"
                media?:
                  | {
                      __typename: "AnimationMediaInfo"
                      id: string
                      resourceId?: string | null
                      thumbnail?: string | null
                      width?: number | null
                      height?: number | null
                      mediaType?: Types.MediaType | null
                    }
                  | {
                      __typename: "ImageMediaInfo"
                      id: string
                      resourceId?: string | null
                      thumbnail?: string | null
                      width?: number | null
                      height?: number | null
                      resolution?: number | null
                      colorDepth?: number | null
                      size?: number | null
                      mime?: string | null
                      extension?: string | null
                      allowCaching?: boolean | null
                      mediaType?: Types.MediaType | null
                      src?: string | null
                      language?: string | null
                    }
                  | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                  | {
                      __typename: "VectorGraphicsMediaInfo"
                      id: string
                      resourceId?: string | null
                      thumbnail?: string | null
                      width?: number | null
                      height?: number | null
                      allowCaching?: boolean | null
                      mediaType?: Types.MediaType | null
                      language?: string | null
                      labels: Array<{
                        __typename: "VectorGraphicsLabel"
                        id?: string | null
                        sequence?: number | null
                        autoValue?: string | null
                        text?: string | null
                        linkType?: Types.VectorGraphicsLabelLinkType | null
                        link?: string | null
                        ref?:
                          | {
                              __typename: "GenericRef"
                              id: string
                              owner?:
                                | { __typename: "CommentNode" }
                                | { __typename: "ExternalSite" }
                                | { __typename: "ST4Class" }
                                | { __typename: "ST4Node"; id: string }
                                | { __typename: "ST4TreeNode" }
                                | null
                              target?:
                                | { __typename: "CommentNode" }
                                | {
                                    __typename: "ExternalSite"
                                    id: string
                                    teaserData?: {
                                      __typename: "ExternalLinkTeaserData"
                                      title?: string | null
                                      summary?: string | null
                                      iconUrl?: string | null
                                      imageUrl?: string | null
                                      status?: Types.LinkTargetStatus | null
                                    } | null
                                  }
                                | { __typename: "ST4Class" }
                                | {
                                    __typename: "ST4Node"
                                    id: string
                                    aspectId: any
                                    label?: string | null
                                    properties?: {
                                      __typename: "ContentPropertyConnection"
                                      edges?: Array<{
                                        __typename: "ContentPropertyEdge"
                                        node?: {
                                          __typename: "ContentProperty"
                                          name: string
                                          value?: any | null
                                          displayName: string
                                        } | null
                                      } | null> | null
                                    } | null
                                  }
                                | { __typename: "ST4TreeNode" }
                                | null
                            }
                          | {
                              __typename: "NodeRef"
                              id: string
                              owner?:
                                | { __typename: "CommentNode" }
                                | { __typename: "ExternalSite" }
                                | { __typename: "ST4Class" }
                                | { __typename: "ST4Node"; id: string }
                                | { __typename: "ST4TreeNode" }
                                | null
                              target?:
                                | { __typename: "CommentNode" }
                                | {
                                    __typename: "ExternalSite"
                                    id: string
                                    teaserData?: {
                                      __typename: "ExternalLinkTeaserData"
                                      title?: string | null
                                      summary?: string | null
                                      iconUrl?: string | null
                                      imageUrl?: string | null
                                      status?: Types.LinkTargetStatus | null
                                    } | null
                                  }
                                | { __typename: "ST4Class" }
                                | {
                                    __typename: "ST4Node"
                                    id: string
                                    aspectId: any
                                    label?: string | null
                                    properties?: {
                                      __typename: "ContentPropertyConnection"
                                      edges?: Array<{
                                        __typename: "ContentPropertyEdge"
                                        node?: {
                                          __typename: "ContentProperty"
                                          name: string
                                          value?: any | null
                                          displayName: string
                                        } | null
                                      } | null> | null
                                    } | null
                                  }
                                | { __typename: "ST4TreeNode" }
                                | null
                            }
                          | null
                      }>
                    }
                  | {
                      __typename: "VideoMediaInfo"
                      id: string
                      resourceId?: string | null
                      thumbnail?: string | null
                      width?: number | null
                      height?: number | null
                      mediaType?: Types.MediaType | null
                    }
                  | null
              }
            | { __typename: "ProjectContent" }
            | { __typename: "TermContent" }
            | { __typename: "TextContent" }
            | { __typename: "TextGroupContent" }
            | { __typename: "VariableTableContent" }
            | null
        }
      | { __typename: "ST4TreeNode"; id: string }
      | null
  }>
  media?:
    | {
        __typename: "AnimationMediaInfo"
        id: string
        resourceId?: string | null
        thumbnail?: string | null
        width?: number | null
        height?: number | null
        mediaType?: Types.MediaType | null
      }
    | {
        __typename: "ImageMediaInfo"
        id: string
        resourceId?: string | null
        thumbnail?: string | null
        width?: number | null
        height?: number | null
        resolution?: number | null
        colorDepth?: number | null
        size?: number | null
        mime?: string | null
        extension?: string | null
        allowCaching?: boolean | null
        mediaType?: Types.MediaType | null
        src?: string | null
        language?: string | null
      }
    | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
    | {
        __typename: "VectorGraphicsMediaInfo"
        id: string
        resourceId?: string | null
        thumbnail?: string | null
        width?: number | null
        height?: number | null
        allowCaching?: boolean | null
        mediaType?: Types.MediaType | null
        language?: string | null
        labels: Array<{
          __typename: "VectorGraphicsLabel"
          id?: string | null
          sequence?: number | null
          autoValue?: string | null
          text?: string | null
          linkType?: Types.VectorGraphicsLabelLinkType | null
          link?: string | null
          ref?:
            | {
                __typename: "GenericRef"
                id: string
                owner?:
                  | { __typename: "CommentNode" }
                  | { __typename: "ExternalSite" }
                  | { __typename: "ST4Class" }
                  | { __typename: "ST4Node"; id: string }
                  | { __typename: "ST4TreeNode" }
                  | null
                target?:
                  | { __typename: "CommentNode" }
                  | {
                      __typename: "ExternalSite"
                      id: string
                      teaserData?: {
                        __typename: "ExternalLinkTeaserData"
                        title?: string | null
                        summary?: string | null
                        iconUrl?: string | null
                        imageUrl?: string | null
                        status?: Types.LinkTargetStatus | null
                      } | null
                    }
                  | { __typename: "ST4Class" }
                  | {
                      __typename: "ST4Node"
                      id: string
                      aspectId: any
                      label?: string | null
                      properties?: {
                        __typename: "ContentPropertyConnection"
                        edges?: Array<{
                          __typename: "ContentPropertyEdge"
                          node?: {
                            __typename: "ContentProperty"
                            name: string
                            value?: any | null
                            displayName: string
                          } | null
                        } | null> | null
                      } | null
                    }
                  | { __typename: "ST4TreeNode" }
                  | null
              }
            | {
                __typename: "NodeRef"
                id: string
                owner?:
                  | { __typename: "CommentNode" }
                  | { __typename: "ExternalSite" }
                  | { __typename: "ST4Class" }
                  | { __typename: "ST4Node"; id: string }
                  | { __typename: "ST4TreeNode" }
                  | null
                target?:
                  | { __typename: "CommentNode" }
                  | {
                      __typename: "ExternalSite"
                      id: string
                      teaserData?: {
                        __typename: "ExternalLinkTeaserData"
                        title?: string | null
                        summary?: string | null
                        iconUrl?: string | null
                        imageUrl?: string | null
                        status?: Types.LinkTargetStatus | null
                      } | null
                    }
                  | { __typename: "ST4Class" }
                  | {
                      __typename: "ST4Node"
                      id: string
                      aspectId: any
                      label?: string | null
                      properties?: {
                        __typename: "ContentPropertyConnection"
                        edges?: Array<{
                          __typename: "ContentPropertyEdge"
                          node?: {
                            __typename: "ContentProperty"
                            name: string
                            value?: any | null
                            displayName: string
                          } | null
                        } | null> | null
                      } | null
                    }
                  | { __typename: "ST4TreeNode" }
                  | null
              }
            | null
        }>
      }
    | {
        __typename: "VideoMediaInfo"
        id: string
        resourceId?: string | null
        thumbnail?: string | null
        width?: number | null
        height?: number | null
        mediaType?: Types.MediaType | null
      }
    | null
}

export type ImageDataFragment = ImageData_MediaContent_Fragment | ImageData_MediaGroupContent_Fragment

export type MediaInfoData_AnimationMediaInfo_Fragment = {
  __typename: "AnimationMediaInfo"
  id: string
  resourceId?: string | null
  thumbnail?: string | null
  width?: number | null
  height?: number | null
  mediaType?: Types.MediaType | null
}

export type MediaInfoData_ImageMediaInfo_Fragment = {
  __typename: "ImageMediaInfo"
  id: string
  resourceId?: string | null
  thumbnail?: string | null
  width?: number | null
  height?: number | null
  resolution?: number | null
  colorDepth?: number | null
  size?: number | null
  mime?: string | null
  extension?: string | null
  allowCaching?: boolean | null
  mediaType?: Types.MediaType | null
  src?: string | null
  language?: string | null
}

export type MediaInfoData_NoMediaInfo_Fragment = {
  __typename: "NoMediaInfo"
  id: string
  mediaType?: Types.MediaType | null
}

export type MediaInfoData_VectorGraphicsMediaInfo_Fragment = {
  __typename: "VectorGraphicsMediaInfo"
  id: string
  resourceId?: string | null
  thumbnail?: string | null
  width?: number | null
  height?: number | null
  allowCaching?: boolean | null
  mediaType?: Types.MediaType | null
  language?: string | null
  labels: Array<{
    __typename: "VectorGraphicsLabel"
    id?: string | null
    sequence?: number | null
    autoValue?: string | null
    text?: string | null
    linkType?: Types.VectorGraphicsLabelLinkType | null
    link?: string | null
    ref?:
      | {
          __typename: "GenericRef"
          id: string
          owner?:
            | { __typename: "CommentNode" }
            | { __typename: "ExternalSite" }
            | { __typename: "ST4Class" }
            | { __typename: "ST4Node"; id: string }
            | { __typename: "ST4TreeNode" }
            | null
          target?:
            | { __typename: "CommentNode" }
            | {
                __typename: "ExternalSite"
                id: string
                teaserData?: {
                  __typename: "ExternalLinkTeaserData"
                  title?: string | null
                  summary?: string | null
                  iconUrl?: string | null
                  imageUrl?: string | null
                  status?: Types.LinkTargetStatus | null
                } | null
              }
            | { __typename: "ST4Class" }
            | {
                __typename: "ST4Node"
                id: string
                aspectId: any
                label?: string | null
                properties?: {
                  __typename: "ContentPropertyConnection"
                  edges?: Array<{
                    __typename: "ContentPropertyEdge"
                    node?: {
                      __typename: "ContentProperty"
                      name: string
                      value?: any | null
                      displayName: string
                    } | null
                  } | null> | null
                } | null
              }
            | { __typename: "ST4TreeNode" }
            | null
        }
      | {
          __typename: "NodeRef"
          id: string
          owner?:
            | { __typename: "CommentNode" }
            | { __typename: "ExternalSite" }
            | { __typename: "ST4Class" }
            | { __typename: "ST4Node"; id: string }
            | { __typename: "ST4TreeNode" }
            | null
          target?:
            | { __typename: "CommentNode" }
            | {
                __typename: "ExternalSite"
                id: string
                teaserData?: {
                  __typename: "ExternalLinkTeaserData"
                  title?: string | null
                  summary?: string | null
                  iconUrl?: string | null
                  imageUrl?: string | null
                  status?: Types.LinkTargetStatus | null
                } | null
              }
            | { __typename: "ST4Class" }
            | {
                __typename: "ST4Node"
                id: string
                aspectId: any
                label?: string | null
                properties?: {
                  __typename: "ContentPropertyConnection"
                  edges?: Array<{
                    __typename: "ContentPropertyEdge"
                    node?: {
                      __typename: "ContentProperty"
                      name: string
                      value?: any | null
                      displayName: string
                    } | null
                  } | null> | null
                } | null
              }
            | { __typename: "ST4TreeNode" }
            | null
        }
      | null
  }>
}

export type MediaInfoData_VideoMediaInfo_Fragment = {
  __typename: "VideoMediaInfo"
  id: string
  resourceId?: string | null
  thumbnail?: string | null
  width?: number | null
  height?: number | null
  mediaType?: Types.MediaType | null
}

export type MediaInfoDataFragment =
  | MediaInfoData_AnimationMediaInfo_Fragment
  | MediaInfoData_ImageMediaInfo_Fragment
  | MediaInfoData_NoMediaInfo_Fragment
  | MediaInfoData_VectorGraphicsMediaInfo_Fragment
  | MediaInfoData_VideoMediaInfo_Fragment

export type VectorGraphicsFragment = {
  __typename: "VectorGraphicsMediaInfo"
  id: string
  resourceId?: string | null
  thumbnail?: string | null
  width?: number | null
  height?: number | null
  allowCaching?: boolean | null
  mediaType?: Types.MediaType | null
  language?: string | null
  labels: Array<{
    __typename: "VectorGraphicsLabel"
    id?: string | null
    sequence?: number | null
    autoValue?: string | null
    text?: string | null
    linkType?: Types.VectorGraphicsLabelLinkType | null
    link?: string | null
    ref?:
      | {
          __typename: "GenericRef"
          id: string
          owner?:
            | { __typename: "CommentNode" }
            | { __typename: "ExternalSite" }
            | { __typename: "ST4Class" }
            | { __typename: "ST4Node"; id: string }
            | { __typename: "ST4TreeNode" }
            | null
          target?:
            | { __typename: "CommentNode" }
            | {
                __typename: "ExternalSite"
                id: string
                teaserData?: {
                  __typename: "ExternalLinkTeaserData"
                  title?: string | null
                  summary?: string | null
                  iconUrl?: string | null
                  imageUrl?: string | null
                  status?: Types.LinkTargetStatus | null
                } | null
              }
            | { __typename: "ST4Class" }
            | {
                __typename: "ST4Node"
                id: string
                aspectId: any
                label?: string | null
                properties?: {
                  __typename: "ContentPropertyConnection"
                  edges?: Array<{
                    __typename: "ContentPropertyEdge"
                    node?: {
                      __typename: "ContentProperty"
                      name: string
                      value?: any | null
                      displayName: string
                    } | null
                  } | null> | null
                } | null
              }
            | { __typename: "ST4TreeNode" }
            | null
        }
      | {
          __typename: "NodeRef"
          id: string
          owner?:
            | { __typename: "CommentNode" }
            | { __typename: "ExternalSite" }
            | { __typename: "ST4Class" }
            | { __typename: "ST4Node"; id: string }
            | { __typename: "ST4TreeNode" }
            | null
          target?:
            | { __typename: "CommentNode" }
            | {
                __typename: "ExternalSite"
                id: string
                teaserData?: {
                  __typename: "ExternalLinkTeaserData"
                  title?: string | null
                  summary?: string | null
                  iconUrl?: string | null
                  imageUrl?: string | null
                  status?: Types.LinkTargetStatus | null
                } | null
              }
            | { __typename: "ST4Class" }
            | {
                __typename: "ST4Node"
                id: string
                aspectId: any
                label?: string | null
                properties?: {
                  __typename: "ContentPropertyConnection"
                  edges?: Array<{
                    __typename: "ContentPropertyEdge"
                    node?: {
                      __typename: "ContentProperty"
                      name: string
                      value?: any | null
                      displayName: string
                    } | null
                  } | null> | null
                } | null
              }
            | { __typename: "ST4TreeNode" }
            | null
        }
      | null
  }>
}

export type FragmentContents_TextContent_Fragment = {
  __typename: "TextContent"
  id: string
  xast?: string | null
  historyNumber: number
  parts: Array<{
    __typename: "TextContentPart"
    ref: string
    contentNode: {
      __typename: "ST4Node"
      id: string
      aspectId: any
      content?:
        | { __typename: "LexiconContent" }
        | { __typename: "LexiconEntryContent" }
        | { __typename: "MediaContent" }
        | { __typename: "MediaGroupContent" }
        | { __typename: "ProjectContent" }
        | { __typename: "TermContent" }
        | {
            __typename: "TextContent"
            id: string
            xast?: string | null
            historyNumber: number
            links?: Array<{
              __typename: "TextContentLink"
              ref?: string | null
              node?:
                | { __typename: "CommentNode"; id: string }
                | { __typename: "ExternalSite"; id: string }
                | { __typename: "ST4Class"; id: string }
                | {
                    __typename: "ST4Node"
                    id: string
                    aspectId: any
                    label?: string | null
                    properties?: {
                      __typename: "ContentPropertyConnection"
                      totalCount: number
                      edges?: Array<{
                        __typename: "ContentPropertyEdge"
                        node?: {
                          __typename: "ContentProperty"
                          typeOfValue: Types.ContentPropertyValueType
                          name: string
                          value?: any | null
                          displayName: string
                        } | null
                      } | null> | null
                    } | null
                  }
                | { __typename: "ST4TreeNode"; id: string }
                | null
            } | null> | null
            images: Array<{
              __typename: "NodeRef"
              id: string
              target?:
                | { __typename: "CommentNode"; id: string }
                | { __typename: "ExternalSite"; id: string }
                | { __typename: "ST4Class"; id: string }
                | {
                    __typename: "ST4Node"
                    aspectId: any
                    id: string
                    releaseState: {
                      __typename: "ReleaseStateValue"
                      displayName: string
                      state: Types.ReleaseState
                      isImplicitlyReleased: boolean
                    }
                    content?:
                      | { __typename: "LexiconContent" }
                      | { __typename: "LexiconEntryContent" }
                      | {
                          __typename: "MediaContent"
                          media?:
                            | {
                                __typename: "AnimationMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                mediaType?: Types.MediaType | null
                              }
                            | {
                                __typename: "ImageMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                resolution?: number | null
                                colorDepth?: number | null
                                size?: number | null
                                mime?: string | null
                                extension?: string | null
                                allowCaching?: boolean | null
                                mediaType?: Types.MediaType | null
                                src?: string | null
                                language?: string | null
                              }
                            | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                            | {
                                __typename: "VectorGraphicsMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                allowCaching?: boolean | null
                                mediaType?: Types.MediaType | null
                                language?: string | null
                                labels: Array<{
                                  __typename: "VectorGraphicsLabel"
                                  id?: string | null
                                  sequence?: number | null
                                  autoValue?: string | null
                                  text?: string | null
                                  linkType?: Types.VectorGraphicsLabelLinkType | null
                                  link?: string | null
                                  ref?:
                                    | {
                                        __typename: "GenericRef"
                                        id: string
                                        owner?:
                                          | { __typename: "CommentNode" }
                                          | { __typename: "ExternalSite" }
                                          | { __typename: "ST4Class" }
                                          | { __typename: "ST4Node"; id: string }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                        target?:
                                          | { __typename: "CommentNode" }
                                          | {
                                              __typename: "ExternalSite"
                                              id: string
                                              teaserData?: {
                                                __typename: "ExternalLinkTeaserData"
                                                title?: string | null
                                                summary?: string | null
                                                iconUrl?: string | null
                                                imageUrl?: string | null
                                                status?: Types.LinkTargetStatus | null
                                              } | null
                                            }
                                          | { __typename: "ST4Class" }
                                          | {
                                              __typename: "ST4Node"
                                              id: string
                                              aspectId: any
                                              label?: string | null
                                              properties?: {
                                                __typename: "ContentPropertyConnection"
                                                edges?: Array<{
                                                  __typename: "ContentPropertyEdge"
                                                  node?: {
                                                    __typename: "ContentProperty"
                                                    name: string
                                                    value?: any | null
                                                    displayName: string
                                                  } | null
                                                } | null> | null
                                              } | null
                                            }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                      }
                                    | {
                                        __typename: "NodeRef"
                                        id: string
                                        owner?:
                                          | { __typename: "CommentNode" }
                                          | { __typename: "ExternalSite" }
                                          | { __typename: "ST4Class" }
                                          | { __typename: "ST4Node"; id: string }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                        target?:
                                          | { __typename: "CommentNode" }
                                          | {
                                              __typename: "ExternalSite"
                                              id: string
                                              teaserData?: {
                                                __typename: "ExternalLinkTeaserData"
                                                title?: string | null
                                                summary?: string | null
                                                iconUrl?: string | null
                                                imageUrl?: string | null
                                                status?: Types.LinkTargetStatus | null
                                              } | null
                                            }
                                          | { __typename: "ST4Class" }
                                          | {
                                              __typename: "ST4Node"
                                              id: string
                                              aspectId: any
                                              label?: string | null
                                              properties?: {
                                                __typename: "ContentPropertyConnection"
                                                edges?: Array<{
                                                  __typename: "ContentPropertyEdge"
                                                  node?: {
                                                    __typename: "ContentProperty"
                                                    name: string
                                                    value?: any | null
                                                    displayName: string
                                                  } | null
                                                } | null> | null
                                              } | null
                                            }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                      }
                                    | null
                                }>
                              }
                            | {
                                __typename: "VideoMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                mediaType?: Types.MediaType | null
                              }
                            | null
                        }
                      | {
                          __typename: "MediaGroupContent"
                          children: Array<{
                            __typename: "NodeRef"
                            id: string
                            target?:
                              | { __typename: "CommentNode"; id: string }
                              | { __typename: "ExternalSite"; id: string }
                              | { __typename: "ST4Class"; id: string }
                              | {
                                  __typename: "ST4Node"
                                  id: string
                                  aspectId: any
                                  content?:
                                    | { __typename: "LexiconContent" }
                                    | { __typename: "LexiconEntryContent" }
                                    | {
                                        __typename: "MediaContent"
                                        media?:
                                          | {
                                              __typename: "AnimationMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              mediaType?: Types.MediaType | null
                                            }
                                          | {
                                              __typename: "ImageMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              resolution?: number | null
                                              colorDepth?: number | null
                                              size?: number | null
                                              mime?: string | null
                                              extension?: string | null
                                              allowCaching?: boolean | null
                                              mediaType?: Types.MediaType | null
                                              src?: string | null
                                              language?: string | null
                                            }
                                          | {
                                              __typename: "NoMediaInfo"
                                              id: string
                                              mediaType?: Types.MediaType | null
                                            }
                                          | {
                                              __typename: "VectorGraphicsMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              allowCaching?: boolean | null
                                              mediaType?: Types.MediaType | null
                                              language?: string | null
                                              labels: Array<{
                                                __typename: "VectorGraphicsLabel"
                                                id?: string | null
                                                sequence?: number | null
                                                autoValue?: string | null
                                                text?: string | null
                                                linkType?: Types.VectorGraphicsLabelLinkType | null
                                                link?: string | null
                                                ref?:
                                                  | {
                                                      __typename: "GenericRef"
                                                      id: string
                                                      owner?:
                                                        | { __typename: "CommentNode" }
                                                        | { __typename: "ExternalSite" }
                                                        | { __typename: "ST4Class" }
                                                        | { __typename: "ST4Node"; id: string }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                      target?:
                                                        | { __typename: "CommentNode" }
                                                        | {
                                                            __typename: "ExternalSite"
                                                            id: string
                                                            teaserData?: {
                                                              __typename: "ExternalLinkTeaserData"
                                                              title?: string | null
                                                              summary?: string | null
                                                              iconUrl?: string | null
                                                              imageUrl?: string | null
                                                              status?: Types.LinkTargetStatus | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4Class" }
                                                        | {
                                                            __typename: "ST4Node"
                                                            id: string
                                                            aspectId: any
                                                            label?: string | null
                                                            properties?: {
                                                              __typename: "ContentPropertyConnection"
                                                              edges?: Array<{
                                                                __typename: "ContentPropertyEdge"
                                                                node?: {
                                                                  __typename: "ContentProperty"
                                                                  name: string
                                                                  value?: any | null
                                                                  displayName: string
                                                                } | null
                                                              } | null> | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                    }
                                                  | {
                                                      __typename: "NodeRef"
                                                      id: string
                                                      owner?:
                                                        | { __typename: "CommentNode" }
                                                        | { __typename: "ExternalSite" }
                                                        | { __typename: "ST4Class" }
                                                        | { __typename: "ST4Node"; id: string }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                      target?:
                                                        | { __typename: "CommentNode" }
                                                        | {
                                                            __typename: "ExternalSite"
                                                            id: string
                                                            teaserData?: {
                                                              __typename: "ExternalLinkTeaserData"
                                                              title?: string | null
                                                              summary?: string | null
                                                              iconUrl?: string | null
                                                              imageUrl?: string | null
                                                              status?: Types.LinkTargetStatus | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4Class" }
                                                        | {
                                                            __typename: "ST4Node"
                                                            id: string
                                                            aspectId: any
                                                            label?: string | null
                                                            properties?: {
                                                              __typename: "ContentPropertyConnection"
                                                              edges?: Array<{
                                                                __typename: "ContentPropertyEdge"
                                                                node?: {
                                                                  __typename: "ContentProperty"
                                                                  name: string
                                                                  value?: any | null
                                                                  displayName: string
                                                                } | null
                                                              } | null> | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                    }
                                                  | null
                                              }>
                                            }
                                          | {
                                              __typename: "VideoMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              mediaType?: Types.MediaType | null
                                            }
                                          | null
                                      }
                                    | {
                                        __typename: "MediaGroupContent"
                                        media?:
                                          | {
                                              __typename: "AnimationMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              mediaType?: Types.MediaType | null
                                            }
                                          | {
                                              __typename: "ImageMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              resolution?: number | null
                                              colorDepth?: number | null
                                              size?: number | null
                                              mime?: string | null
                                              extension?: string | null
                                              allowCaching?: boolean | null
                                              mediaType?: Types.MediaType | null
                                              src?: string | null
                                              language?: string | null
                                            }
                                          | {
                                              __typename: "NoMediaInfo"
                                              id: string
                                              mediaType?: Types.MediaType | null
                                            }
                                          | {
                                              __typename: "VectorGraphicsMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              allowCaching?: boolean | null
                                              mediaType?: Types.MediaType | null
                                              language?: string | null
                                              labels: Array<{
                                                __typename: "VectorGraphicsLabel"
                                                id?: string | null
                                                sequence?: number | null
                                                autoValue?: string | null
                                                text?: string | null
                                                linkType?: Types.VectorGraphicsLabelLinkType | null
                                                link?: string | null
                                                ref?:
                                                  | {
                                                      __typename: "GenericRef"
                                                      id: string
                                                      owner?:
                                                        | { __typename: "CommentNode" }
                                                        | { __typename: "ExternalSite" }
                                                        | { __typename: "ST4Class" }
                                                        | { __typename: "ST4Node"; id: string }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                      target?:
                                                        | { __typename: "CommentNode" }
                                                        | {
                                                            __typename: "ExternalSite"
                                                            id: string
                                                            teaserData?: {
                                                              __typename: "ExternalLinkTeaserData"
                                                              title?: string | null
                                                              summary?: string | null
                                                              iconUrl?: string | null
                                                              imageUrl?: string | null
                                                              status?: Types.LinkTargetStatus | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4Class" }
                                                        | {
                                                            __typename: "ST4Node"
                                                            id: string
                                                            aspectId: any
                                                            label?: string | null
                                                            properties?: {
                                                              __typename: "ContentPropertyConnection"
                                                              edges?: Array<{
                                                                __typename: "ContentPropertyEdge"
                                                                node?: {
                                                                  __typename: "ContentProperty"
                                                                  name: string
                                                                  value?: any | null
                                                                  displayName: string
                                                                } | null
                                                              } | null> | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                    }
                                                  | {
                                                      __typename: "NodeRef"
                                                      id: string
                                                      owner?:
                                                        | { __typename: "CommentNode" }
                                                        | { __typename: "ExternalSite" }
                                                        | { __typename: "ST4Class" }
                                                        | { __typename: "ST4Node"; id: string }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                      target?:
                                                        | { __typename: "CommentNode" }
                                                        | {
                                                            __typename: "ExternalSite"
                                                            id: string
                                                            teaserData?: {
                                                              __typename: "ExternalLinkTeaserData"
                                                              title?: string | null
                                                              summary?: string | null
                                                              iconUrl?: string | null
                                                              imageUrl?: string | null
                                                              status?: Types.LinkTargetStatus | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4Class" }
                                                        | {
                                                            __typename: "ST4Node"
                                                            id: string
                                                            aspectId: any
                                                            label?: string | null
                                                            properties?: {
                                                              __typename: "ContentPropertyConnection"
                                                              edges?: Array<{
                                                                __typename: "ContentPropertyEdge"
                                                                node?: {
                                                                  __typename: "ContentProperty"
                                                                  name: string
                                                                  value?: any | null
                                                                  displayName: string
                                                                } | null
                                                              } | null> | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                    }
                                                  | null
                                              }>
                                            }
                                          | {
                                              __typename: "VideoMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              mediaType?: Types.MediaType | null
                                            }
                                          | null
                                      }
                                    | { __typename: "ProjectContent" }
                                    | { __typename: "TermContent" }
                                    | { __typename: "TextContent" }
                                    | { __typename: "TextGroupContent" }
                                    | { __typename: "VariableTableContent" }
                                    | null
                                }
                              | { __typename: "ST4TreeNode"; id: string }
                              | null
                          }>
                          media?:
                            | {
                                __typename: "AnimationMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                mediaType?: Types.MediaType | null
                              }
                            | {
                                __typename: "ImageMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                resolution?: number | null
                                colorDepth?: number | null
                                size?: number | null
                                mime?: string | null
                                extension?: string | null
                                allowCaching?: boolean | null
                                mediaType?: Types.MediaType | null
                                src?: string | null
                                language?: string | null
                              }
                            | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                            | {
                                __typename: "VectorGraphicsMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                allowCaching?: boolean | null
                                mediaType?: Types.MediaType | null
                                language?: string | null
                                labels: Array<{
                                  __typename: "VectorGraphicsLabel"
                                  id?: string | null
                                  sequence?: number | null
                                  autoValue?: string | null
                                  text?: string | null
                                  linkType?: Types.VectorGraphicsLabelLinkType | null
                                  link?: string | null
                                  ref?:
                                    | {
                                        __typename: "GenericRef"
                                        id: string
                                        owner?:
                                          | { __typename: "CommentNode" }
                                          | { __typename: "ExternalSite" }
                                          | { __typename: "ST4Class" }
                                          | { __typename: "ST4Node"; id: string }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                        target?:
                                          | { __typename: "CommentNode" }
                                          | {
                                              __typename: "ExternalSite"
                                              id: string
                                              teaserData?: {
                                                __typename: "ExternalLinkTeaserData"
                                                title?: string | null
                                                summary?: string | null
                                                iconUrl?: string | null
                                                imageUrl?: string | null
                                                status?: Types.LinkTargetStatus | null
                                              } | null
                                            }
                                          | { __typename: "ST4Class" }
                                          | {
                                              __typename: "ST4Node"
                                              id: string
                                              aspectId: any
                                              label?: string | null
                                              properties?: {
                                                __typename: "ContentPropertyConnection"
                                                edges?: Array<{
                                                  __typename: "ContentPropertyEdge"
                                                  node?: {
                                                    __typename: "ContentProperty"
                                                    name: string
                                                    value?: any | null
                                                    displayName: string
                                                  } | null
                                                } | null> | null
                                              } | null
                                            }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                      }
                                    | {
                                        __typename: "NodeRef"
                                        id: string
                                        owner?:
                                          | { __typename: "CommentNode" }
                                          | { __typename: "ExternalSite" }
                                          | { __typename: "ST4Class" }
                                          | { __typename: "ST4Node"; id: string }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                        target?:
                                          | { __typename: "CommentNode" }
                                          | {
                                              __typename: "ExternalSite"
                                              id: string
                                              teaserData?: {
                                                __typename: "ExternalLinkTeaserData"
                                                title?: string | null
                                                summary?: string | null
                                                iconUrl?: string | null
                                                imageUrl?: string | null
                                                status?: Types.LinkTargetStatus | null
                                              } | null
                                            }
                                          | { __typename: "ST4Class" }
                                          | {
                                              __typename: "ST4Node"
                                              id: string
                                              aspectId: any
                                              label?: string | null
                                              properties?: {
                                                __typename: "ContentPropertyConnection"
                                                edges?: Array<{
                                                  __typename: "ContentPropertyEdge"
                                                  node?: {
                                                    __typename: "ContentProperty"
                                                    name: string
                                                    value?: any | null
                                                    displayName: string
                                                  } | null
                                                } | null> | null
                                              } | null
                                            }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                      }
                                    | null
                                }>
                              }
                            | {
                                __typename: "VideoMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                mediaType?: Types.MediaType | null
                              }
                            | null
                        }
                      | { __typename: "ProjectContent" }
                      | { __typename: "TermContent" }
                      | { __typename: "TextContent" }
                      | { __typename: "TextGroupContent" }
                      | { __typename: "VariableTableContent" }
                      | null
                  }
                | { __typename: "ST4TreeNode"; id: string }
                | null
            }>
            variableTables?: Array<{
              __typename: "VariableTableReference"
              ref?: string | null
              node?:
                | { __typename: "CommentNode" }
                | { __typename: "ExternalSite" }
                | { __typename: "ST4Class" }
                | {
                    __typename: "ST4Node"
                    id: string
                    label?: string | null
                    aspectId: any
                    content?:
                      | { __typename: "LexiconContent" }
                      | { __typename: "LexiconEntryContent" }
                      | { __typename: "MediaContent" }
                      | { __typename: "MediaGroupContent" }
                      | { __typename: "ProjectContent" }
                      | { __typename: "TermContent" }
                      | { __typename: "TextContent" }
                      | { __typename: "TextGroupContent" }
                      | {
                          __typename: "VariableTableContent"
                          xml?: string | null
                          valueSets: Array<{ __typename: "ValueSet"; name: string; valueSetId: string }>
                          variables: Array<{
                            __typename: "Variable"
                            name: string
                            variableId: string
                            values: Array<string>
                          }>
                        }
                      | null
                  }
                | { __typename: "ST4TreeNode" }
                | null
            } | null> | null
            linkedDataNodes: Array<{
              __typename: "NodeRef"
              id: string
              target?:
                | { __typename: "CommentNode"; id: string }
                | { __typename: "ExternalSite"; id: string }
                | { __typename: "ST4Class"; id: string }
                | {
                    __typename: "ST4Node"
                    label?: string | null
                    id: string
                    metadata: Array<{
                      __typename: "MetaDataEntry"
                      label?: string | null
                      systemName: string
                      value?: string | null
                      values: Array<string>
                      users: Array<string>
                    }>
                    linkedOntologyNodes: Array<
                      | {
                          __typename: "GenericRef"
                          target?:
                            | { __typename: "CommentNode" }
                            | { __typename: "ExternalSite" }
                            | { __typename: "ST4Class" }
                            | { __typename: "ST4Node"; id: string; label?: string | null }
                            | { __typename: "ST4TreeNode" }
                            | null
                        }
                      | {
                          __typename: "NodeRef"
                          linkClassName: string
                          class?: { __typename: "ST4Class"; label?: string | null } | null
                          target?:
                            | { __typename: "CommentNode" }
                            | { __typename: "ExternalSite" }
                            | { __typename: "ST4Class" }
                            | { __typename: "ST4Node"; id: string; label?: string | null }
                            | { __typename: "ST4TreeNode" }
                            | null
                        }
                    >
                  }
                | { __typename: "ST4TreeNode"; id: string }
                | null
            }>
            lexiconEntries?: Array<{
              __typename: "NodeRef"
              id: string
              target?:
                | { __typename: "CommentNode"; id: string }
                | { __typename: "ExternalSite"; id: string }
                | { __typename: "ST4Class"; id: string }
                | {
                    __typename: "ST4Node"
                    aspectId: any
                    label?: string | null
                    id: string
                    content?:
                      | { __typename: "LexiconContent" }
                      | {
                          __typename: "LexiconEntryContent"
                          descriptionXast?: string | null
                          subject?: string | null
                          sortKey?: string | null
                          lexicon:
                            | { __typename: "CommentNode"; id: string }
                            | { __typename: "ExternalSite"; id: string }
                            | { __typename: "ST4Class"; id: string }
                            | { __typename: "ST4Node"; label?: string | null; id: string }
                            | { __typename: "ST4TreeNode"; id: string }
                          terms: Array<
                            | { __typename: "CommentNode"; id: string }
                            | { __typename: "ExternalSite"; id: string }
                            | { __typename: "ST4Class"; id: string }
                            | {
                                __typename: "ST4Node"
                                id: string
                                label?: string | null
                                aspectId: any
                                content?:
                                  | { __typename: "LexiconContent" }
                                  | { __typename: "LexiconEntryContent" }
                                  | { __typename: "MediaContent" }
                                  | { __typename: "MediaGroupContent" }
                                  | { __typename: "ProjectContent" }
                                  | {
                                      __typename: "TermContent"
                                      gramaticalGender?: string | null
                                      validity?: string | null
                                      editLanguage?: string | null
                                    }
                                  | { __typename: "TextContent" }
                                  | { __typename: "TextGroupContent" }
                                  | { __typename: "VariableTableContent" }
                                  | null
                              }
                            | { __typename: "ST4TreeNode"; id: string }
                          >
                          image?:
                            | { __typename: "CommentNode"; id: string }
                            | { __typename: "ExternalSite"; id: string }
                            | { __typename: "ST4Class"; id: string }
                            | {
                                __typename: "ST4Node"
                                aspectId: any
                                id: string
                                content?:
                                  | { __typename: "LexiconContent" }
                                  | { __typename: "LexiconEntryContent" }
                                  | {
                                      __typename: "MediaContent"
                                      media?:
                                        | {
                                            __typename: "AnimationMediaInfo"
                                            thumbnail?: string | null
                                            mediaType?: Types.MediaType | null
                                          }
                                        | {
                                            __typename: "ImageMediaInfo"
                                            src?: string | null
                                            thumbnail?: string | null
                                            mediaType?: Types.MediaType | null
                                          }
                                        | { __typename: "NoMediaInfo" }
                                        | {
                                            __typename: "VectorGraphicsMediaInfo"
                                            svg?: string | null
                                            thumbnail?: string | null
                                            mediaType?: Types.MediaType | null
                                          }
                                        | {
                                            __typename: "VideoMediaInfo"
                                            thumbnail?: string | null
                                            mediaType?: Types.MediaType | null
                                          }
                                        | null
                                    }
                                  | { __typename: "MediaGroupContent" }
                                  | { __typename: "ProjectContent" }
                                  | { __typename: "TermContent" }
                                  | { __typename: "TextContent" }
                                  | { __typename: "TextGroupContent" }
                                  | { __typename: "VariableTableContent" }
                                  | null
                              }
                            | { __typename: "ST4TreeNode"; id: string }
                            | null
                        }
                      | { __typename: "MediaContent" }
                      | { __typename: "MediaGroupContent" }
                      | { __typename: "ProjectContent" }
                      | { __typename: "TermContent" }
                      | { __typename: "TextContent" }
                      | { __typename: "TextGroupContent" }
                      | { __typename: "VariableTableContent" }
                      | null
                  }
                | { __typename: "ST4TreeNode"; id: string }
                | null
            } | null> | null
            comments: Array<{
              __typename: "CommentNode"
              id: string
              comment:
                | {
                    __typename: "BlockComment"
                    location: number
                    value?: string | null
                    authorDisplayName?: string | null
                    authorUserName?: string | null
                    commentKey: string
                    editDate?: any | null
                    modificationVersion?: number | null
                    replyToKey?: string | null
                    sortOrder?: number | null
                    commentType?: string | null
                    state?: string | null
                    color: string
                    persistanceState?: Types.PersistanceState | null
                    persistanceAction?: Types.PersistanceAction | null
                    node: {
                      __typename: "ST4Node"
                      aspectId: any
                      id: string
                      content?:
                        | { __typename: "LexiconContent" }
                        | { __typename: "LexiconEntryContent" }
                        | { __typename: "MediaContent" }
                        | { __typename: "MediaGroupContent" }
                        | { __typename: "ProjectContent" }
                        | { __typename: "TermContent" }
                        | { __typename: "TextContent"; id: string; historyNumber: number }
                        | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                        | { __typename: "VariableTableContent" }
                        | null
                    }
                    actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
                  }
                | {
                    __typename: "TextComment"
                    endLocation: number
                    startLocation?: number | null
                    value?: string | null
                    authorDisplayName?: string | null
                    authorUserName?: string | null
                    commentKey: string
                    editDate?: any | null
                    modificationVersion?: number | null
                    replyToKey?: string | null
                    sortOrder?: number | null
                    commentType?: string | null
                    state?: string | null
                    color: string
                    persistanceState?: Types.PersistanceState | null
                    persistanceAction?: Types.PersistanceAction | null
                    node: {
                      __typename: "ST4Node"
                      aspectId: any
                      id: string
                      content?:
                        | { __typename: "LexiconContent" }
                        | { __typename: "LexiconEntryContent" }
                        | { __typename: "MediaContent" }
                        | { __typename: "MediaGroupContent" }
                        | { __typename: "ProjectContent" }
                        | { __typename: "TermContent" }
                        | { __typename: "TextContent"; id: string; historyNumber: number }
                        | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                        | { __typename: "VariableTableContent" }
                        | null
                    }
                    actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
                  }
            }>
          }
        | {
            __typename: "TextGroupContent"
            currentNodeId?: any | null
            groupNodeId: any
            id: string
            xast?: string | null
            historyNumber: number
            children: Array<{
              __typename: "NodeRef"
              id: string
              target?:
                | { __typename: "CommentNode"; id: string }
                | { __typename: "ExternalSite"; id: string }
                | { __typename: "ST4Class"; id: string }
                | { __typename: "ST4Node"; label?: string | null; aspectId: any; id: string }
                | { __typename: "ST4TreeNode"; id: string }
                | null
            }>
            links?: Array<{
              __typename: "TextContentLink"
              ref?: string | null
              node?:
                | { __typename: "CommentNode"; id: string }
                | { __typename: "ExternalSite"; id: string }
                | { __typename: "ST4Class"; id: string }
                | {
                    __typename: "ST4Node"
                    id: string
                    aspectId: any
                    label?: string | null
                    properties?: {
                      __typename: "ContentPropertyConnection"
                      totalCount: number
                      edges?: Array<{
                        __typename: "ContentPropertyEdge"
                        node?: {
                          __typename: "ContentProperty"
                          typeOfValue: Types.ContentPropertyValueType
                          name: string
                          value?: any | null
                          displayName: string
                        } | null
                      } | null> | null
                    } | null
                  }
                | { __typename: "ST4TreeNode"; id: string }
                | null
            } | null> | null
            images: Array<{
              __typename: "NodeRef"
              id: string
              target?:
                | { __typename: "CommentNode"; id: string }
                | { __typename: "ExternalSite"; id: string }
                | { __typename: "ST4Class"; id: string }
                | {
                    __typename: "ST4Node"
                    aspectId: any
                    id: string
                    releaseState: {
                      __typename: "ReleaseStateValue"
                      displayName: string
                      state: Types.ReleaseState
                      isImplicitlyReleased: boolean
                    }
                    content?:
                      | { __typename: "LexiconContent" }
                      | { __typename: "LexiconEntryContent" }
                      | {
                          __typename: "MediaContent"
                          media?:
                            | {
                                __typename: "AnimationMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                mediaType?: Types.MediaType | null
                              }
                            | {
                                __typename: "ImageMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                resolution?: number | null
                                colorDepth?: number | null
                                size?: number | null
                                mime?: string | null
                                extension?: string | null
                                allowCaching?: boolean | null
                                mediaType?: Types.MediaType | null
                                src?: string | null
                                language?: string | null
                              }
                            | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                            | {
                                __typename: "VectorGraphicsMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                allowCaching?: boolean | null
                                mediaType?: Types.MediaType | null
                                language?: string | null
                                labels: Array<{
                                  __typename: "VectorGraphicsLabel"
                                  id?: string | null
                                  sequence?: number | null
                                  autoValue?: string | null
                                  text?: string | null
                                  linkType?: Types.VectorGraphicsLabelLinkType | null
                                  link?: string | null
                                  ref?:
                                    | {
                                        __typename: "GenericRef"
                                        id: string
                                        owner?:
                                          | { __typename: "CommentNode" }
                                          | { __typename: "ExternalSite" }
                                          | { __typename: "ST4Class" }
                                          | { __typename: "ST4Node"; id: string }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                        target?:
                                          | { __typename: "CommentNode" }
                                          | {
                                              __typename: "ExternalSite"
                                              id: string
                                              teaserData?: {
                                                __typename: "ExternalLinkTeaserData"
                                                title?: string | null
                                                summary?: string | null
                                                iconUrl?: string | null
                                                imageUrl?: string | null
                                                status?: Types.LinkTargetStatus | null
                                              } | null
                                            }
                                          | { __typename: "ST4Class" }
                                          | {
                                              __typename: "ST4Node"
                                              id: string
                                              aspectId: any
                                              label?: string | null
                                              properties?: {
                                                __typename: "ContentPropertyConnection"
                                                edges?: Array<{
                                                  __typename: "ContentPropertyEdge"
                                                  node?: {
                                                    __typename: "ContentProperty"
                                                    name: string
                                                    value?: any | null
                                                    displayName: string
                                                  } | null
                                                } | null> | null
                                              } | null
                                            }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                      }
                                    | {
                                        __typename: "NodeRef"
                                        id: string
                                        owner?:
                                          | { __typename: "CommentNode" }
                                          | { __typename: "ExternalSite" }
                                          | { __typename: "ST4Class" }
                                          | { __typename: "ST4Node"; id: string }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                        target?:
                                          | { __typename: "CommentNode" }
                                          | {
                                              __typename: "ExternalSite"
                                              id: string
                                              teaserData?: {
                                                __typename: "ExternalLinkTeaserData"
                                                title?: string | null
                                                summary?: string | null
                                                iconUrl?: string | null
                                                imageUrl?: string | null
                                                status?: Types.LinkTargetStatus | null
                                              } | null
                                            }
                                          | { __typename: "ST4Class" }
                                          | {
                                              __typename: "ST4Node"
                                              id: string
                                              aspectId: any
                                              label?: string | null
                                              properties?: {
                                                __typename: "ContentPropertyConnection"
                                                edges?: Array<{
                                                  __typename: "ContentPropertyEdge"
                                                  node?: {
                                                    __typename: "ContentProperty"
                                                    name: string
                                                    value?: any | null
                                                    displayName: string
                                                  } | null
                                                } | null> | null
                                              } | null
                                            }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                      }
                                    | null
                                }>
                              }
                            | {
                                __typename: "VideoMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                mediaType?: Types.MediaType | null
                              }
                            | null
                        }
                      | {
                          __typename: "MediaGroupContent"
                          children: Array<{
                            __typename: "NodeRef"
                            id: string
                            target?:
                              | { __typename: "CommentNode"; id: string }
                              | { __typename: "ExternalSite"; id: string }
                              | { __typename: "ST4Class"; id: string }
                              | {
                                  __typename: "ST4Node"
                                  id: string
                                  aspectId: any
                                  content?:
                                    | { __typename: "LexiconContent" }
                                    | { __typename: "LexiconEntryContent" }
                                    | {
                                        __typename: "MediaContent"
                                        media?:
                                          | {
                                              __typename: "AnimationMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              mediaType?: Types.MediaType | null
                                            }
                                          | {
                                              __typename: "ImageMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              resolution?: number | null
                                              colorDepth?: number | null
                                              size?: number | null
                                              mime?: string | null
                                              extension?: string | null
                                              allowCaching?: boolean | null
                                              mediaType?: Types.MediaType | null
                                              src?: string | null
                                              language?: string | null
                                            }
                                          | {
                                              __typename: "NoMediaInfo"
                                              id: string
                                              mediaType?: Types.MediaType | null
                                            }
                                          | {
                                              __typename: "VectorGraphicsMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              allowCaching?: boolean | null
                                              mediaType?: Types.MediaType | null
                                              language?: string | null
                                              labels: Array<{
                                                __typename: "VectorGraphicsLabel"
                                                id?: string | null
                                                sequence?: number | null
                                                autoValue?: string | null
                                                text?: string | null
                                                linkType?: Types.VectorGraphicsLabelLinkType | null
                                                link?: string | null
                                                ref?:
                                                  | {
                                                      __typename: "GenericRef"
                                                      id: string
                                                      owner?:
                                                        | { __typename: "CommentNode" }
                                                        | { __typename: "ExternalSite" }
                                                        | { __typename: "ST4Class" }
                                                        | { __typename: "ST4Node"; id: string }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                      target?:
                                                        | { __typename: "CommentNode" }
                                                        | {
                                                            __typename: "ExternalSite"
                                                            id: string
                                                            teaserData?: {
                                                              __typename: "ExternalLinkTeaserData"
                                                              title?: string | null
                                                              summary?: string | null
                                                              iconUrl?: string | null
                                                              imageUrl?: string | null
                                                              status?: Types.LinkTargetStatus | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4Class" }
                                                        | {
                                                            __typename: "ST4Node"
                                                            id: string
                                                            aspectId: any
                                                            label?: string | null
                                                            properties?: {
                                                              __typename: "ContentPropertyConnection"
                                                              edges?: Array<{
                                                                __typename: "ContentPropertyEdge"
                                                                node?: {
                                                                  __typename: "ContentProperty"
                                                                  name: string
                                                                  value?: any | null
                                                                  displayName: string
                                                                } | null
                                                              } | null> | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                    }
                                                  | {
                                                      __typename: "NodeRef"
                                                      id: string
                                                      owner?:
                                                        | { __typename: "CommentNode" }
                                                        | { __typename: "ExternalSite" }
                                                        | { __typename: "ST4Class" }
                                                        | { __typename: "ST4Node"; id: string }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                      target?:
                                                        | { __typename: "CommentNode" }
                                                        | {
                                                            __typename: "ExternalSite"
                                                            id: string
                                                            teaserData?: {
                                                              __typename: "ExternalLinkTeaserData"
                                                              title?: string | null
                                                              summary?: string | null
                                                              iconUrl?: string | null
                                                              imageUrl?: string | null
                                                              status?: Types.LinkTargetStatus | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4Class" }
                                                        | {
                                                            __typename: "ST4Node"
                                                            id: string
                                                            aspectId: any
                                                            label?: string | null
                                                            properties?: {
                                                              __typename: "ContentPropertyConnection"
                                                              edges?: Array<{
                                                                __typename: "ContentPropertyEdge"
                                                                node?: {
                                                                  __typename: "ContentProperty"
                                                                  name: string
                                                                  value?: any | null
                                                                  displayName: string
                                                                } | null
                                                              } | null> | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                    }
                                                  | null
                                              }>
                                            }
                                          | {
                                              __typename: "VideoMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              mediaType?: Types.MediaType | null
                                            }
                                          | null
                                      }
                                    | {
                                        __typename: "MediaGroupContent"
                                        media?:
                                          | {
                                              __typename: "AnimationMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              mediaType?: Types.MediaType | null
                                            }
                                          | {
                                              __typename: "ImageMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              resolution?: number | null
                                              colorDepth?: number | null
                                              size?: number | null
                                              mime?: string | null
                                              extension?: string | null
                                              allowCaching?: boolean | null
                                              mediaType?: Types.MediaType | null
                                              src?: string | null
                                              language?: string | null
                                            }
                                          | {
                                              __typename: "NoMediaInfo"
                                              id: string
                                              mediaType?: Types.MediaType | null
                                            }
                                          | {
                                              __typename: "VectorGraphicsMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              allowCaching?: boolean | null
                                              mediaType?: Types.MediaType | null
                                              language?: string | null
                                              labels: Array<{
                                                __typename: "VectorGraphicsLabel"
                                                id?: string | null
                                                sequence?: number | null
                                                autoValue?: string | null
                                                text?: string | null
                                                linkType?: Types.VectorGraphicsLabelLinkType | null
                                                link?: string | null
                                                ref?:
                                                  | {
                                                      __typename: "GenericRef"
                                                      id: string
                                                      owner?:
                                                        | { __typename: "CommentNode" }
                                                        | { __typename: "ExternalSite" }
                                                        | { __typename: "ST4Class" }
                                                        | { __typename: "ST4Node"; id: string }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                      target?:
                                                        | { __typename: "CommentNode" }
                                                        | {
                                                            __typename: "ExternalSite"
                                                            id: string
                                                            teaserData?: {
                                                              __typename: "ExternalLinkTeaserData"
                                                              title?: string | null
                                                              summary?: string | null
                                                              iconUrl?: string | null
                                                              imageUrl?: string | null
                                                              status?: Types.LinkTargetStatus | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4Class" }
                                                        | {
                                                            __typename: "ST4Node"
                                                            id: string
                                                            aspectId: any
                                                            label?: string | null
                                                            properties?: {
                                                              __typename: "ContentPropertyConnection"
                                                              edges?: Array<{
                                                                __typename: "ContentPropertyEdge"
                                                                node?: {
                                                                  __typename: "ContentProperty"
                                                                  name: string
                                                                  value?: any | null
                                                                  displayName: string
                                                                } | null
                                                              } | null> | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                    }
                                                  | {
                                                      __typename: "NodeRef"
                                                      id: string
                                                      owner?:
                                                        | { __typename: "CommentNode" }
                                                        | { __typename: "ExternalSite" }
                                                        | { __typename: "ST4Class" }
                                                        | { __typename: "ST4Node"; id: string }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                      target?:
                                                        | { __typename: "CommentNode" }
                                                        | {
                                                            __typename: "ExternalSite"
                                                            id: string
                                                            teaserData?: {
                                                              __typename: "ExternalLinkTeaserData"
                                                              title?: string | null
                                                              summary?: string | null
                                                              iconUrl?: string | null
                                                              imageUrl?: string | null
                                                              status?: Types.LinkTargetStatus | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4Class" }
                                                        | {
                                                            __typename: "ST4Node"
                                                            id: string
                                                            aspectId: any
                                                            label?: string | null
                                                            properties?: {
                                                              __typename: "ContentPropertyConnection"
                                                              edges?: Array<{
                                                                __typename: "ContentPropertyEdge"
                                                                node?: {
                                                                  __typename: "ContentProperty"
                                                                  name: string
                                                                  value?: any | null
                                                                  displayName: string
                                                                } | null
                                                              } | null> | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                    }
                                                  | null
                                              }>
                                            }
                                          | {
                                              __typename: "VideoMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              mediaType?: Types.MediaType | null
                                            }
                                          | null
                                      }
                                    | { __typename: "ProjectContent" }
                                    | { __typename: "TermContent" }
                                    | { __typename: "TextContent" }
                                    | { __typename: "TextGroupContent" }
                                    | { __typename: "VariableTableContent" }
                                    | null
                                }
                              | { __typename: "ST4TreeNode"; id: string }
                              | null
                          }>
                          media?:
                            | {
                                __typename: "AnimationMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                mediaType?: Types.MediaType | null
                              }
                            | {
                                __typename: "ImageMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                resolution?: number | null
                                colorDepth?: number | null
                                size?: number | null
                                mime?: string | null
                                extension?: string | null
                                allowCaching?: boolean | null
                                mediaType?: Types.MediaType | null
                                src?: string | null
                                language?: string | null
                              }
                            | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                            | {
                                __typename: "VectorGraphicsMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                allowCaching?: boolean | null
                                mediaType?: Types.MediaType | null
                                language?: string | null
                                labels: Array<{
                                  __typename: "VectorGraphicsLabel"
                                  id?: string | null
                                  sequence?: number | null
                                  autoValue?: string | null
                                  text?: string | null
                                  linkType?: Types.VectorGraphicsLabelLinkType | null
                                  link?: string | null
                                  ref?:
                                    | {
                                        __typename: "GenericRef"
                                        id: string
                                        owner?:
                                          | { __typename: "CommentNode" }
                                          | { __typename: "ExternalSite" }
                                          | { __typename: "ST4Class" }
                                          | { __typename: "ST4Node"; id: string }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                        target?:
                                          | { __typename: "CommentNode" }
                                          | {
                                              __typename: "ExternalSite"
                                              id: string
                                              teaserData?: {
                                                __typename: "ExternalLinkTeaserData"
                                                title?: string | null
                                                summary?: string | null
                                                iconUrl?: string | null
                                                imageUrl?: string | null
                                                status?: Types.LinkTargetStatus | null
                                              } | null
                                            }
                                          | { __typename: "ST4Class" }
                                          | {
                                              __typename: "ST4Node"
                                              id: string
                                              aspectId: any
                                              label?: string | null
                                              properties?: {
                                                __typename: "ContentPropertyConnection"
                                                edges?: Array<{
                                                  __typename: "ContentPropertyEdge"
                                                  node?: {
                                                    __typename: "ContentProperty"
                                                    name: string
                                                    value?: any | null
                                                    displayName: string
                                                  } | null
                                                } | null> | null
                                              } | null
                                            }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                      }
                                    | {
                                        __typename: "NodeRef"
                                        id: string
                                        owner?:
                                          | { __typename: "CommentNode" }
                                          | { __typename: "ExternalSite" }
                                          | { __typename: "ST4Class" }
                                          | { __typename: "ST4Node"; id: string }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                        target?:
                                          | { __typename: "CommentNode" }
                                          | {
                                              __typename: "ExternalSite"
                                              id: string
                                              teaserData?: {
                                                __typename: "ExternalLinkTeaserData"
                                                title?: string | null
                                                summary?: string | null
                                                iconUrl?: string | null
                                                imageUrl?: string | null
                                                status?: Types.LinkTargetStatus | null
                                              } | null
                                            }
                                          | { __typename: "ST4Class" }
                                          | {
                                              __typename: "ST4Node"
                                              id: string
                                              aspectId: any
                                              label?: string | null
                                              properties?: {
                                                __typename: "ContentPropertyConnection"
                                                edges?: Array<{
                                                  __typename: "ContentPropertyEdge"
                                                  node?: {
                                                    __typename: "ContentProperty"
                                                    name: string
                                                    value?: any | null
                                                    displayName: string
                                                  } | null
                                                } | null> | null
                                              } | null
                                            }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                      }
                                    | null
                                }>
                              }
                            | {
                                __typename: "VideoMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                mediaType?: Types.MediaType | null
                              }
                            | null
                        }
                      | { __typename: "ProjectContent" }
                      | { __typename: "TermContent" }
                      | { __typename: "TextContent" }
                      | { __typename: "TextGroupContent" }
                      | { __typename: "VariableTableContent" }
                      | null
                  }
                | { __typename: "ST4TreeNode"; id: string }
                | null
            }>
            variableTables?: Array<{
              __typename: "VariableTableReference"
              ref?: string | null
              node?:
                | { __typename: "CommentNode" }
                | { __typename: "ExternalSite" }
                | { __typename: "ST4Class" }
                | {
                    __typename: "ST4Node"
                    id: string
                    label?: string | null
                    aspectId: any
                    content?:
                      | { __typename: "LexiconContent" }
                      | { __typename: "LexiconEntryContent" }
                      | { __typename: "MediaContent" }
                      | { __typename: "MediaGroupContent" }
                      | { __typename: "ProjectContent" }
                      | { __typename: "TermContent" }
                      | { __typename: "TextContent" }
                      | { __typename: "TextGroupContent" }
                      | {
                          __typename: "VariableTableContent"
                          xml?: string | null
                          valueSets: Array<{ __typename: "ValueSet"; name: string; valueSetId: string }>
                          variables: Array<{
                            __typename: "Variable"
                            name: string
                            variableId: string
                            values: Array<string>
                          }>
                        }
                      | null
                  }
                | { __typename: "ST4TreeNode" }
                | null
            } | null> | null
            linkedDataNodes: Array<{
              __typename: "NodeRef"
              id: string
              target?:
                | { __typename: "CommentNode"; id: string }
                | { __typename: "ExternalSite"; id: string }
                | { __typename: "ST4Class"; id: string }
                | {
                    __typename: "ST4Node"
                    label?: string | null
                    id: string
                    metadata: Array<{
                      __typename: "MetaDataEntry"
                      label?: string | null
                      systemName: string
                      value?: string | null
                      values: Array<string>
                      users: Array<string>
                    }>
                    linkedOntologyNodes: Array<
                      | {
                          __typename: "GenericRef"
                          target?:
                            | { __typename: "CommentNode" }
                            | { __typename: "ExternalSite" }
                            | { __typename: "ST4Class" }
                            | { __typename: "ST4Node"; id: string; label?: string | null }
                            | { __typename: "ST4TreeNode" }
                            | null
                        }
                      | {
                          __typename: "NodeRef"
                          linkClassName: string
                          class?: { __typename: "ST4Class"; label?: string | null } | null
                          target?:
                            | { __typename: "CommentNode" }
                            | { __typename: "ExternalSite" }
                            | { __typename: "ST4Class" }
                            | { __typename: "ST4Node"; id: string; label?: string | null }
                            | { __typename: "ST4TreeNode" }
                            | null
                        }
                    >
                  }
                | { __typename: "ST4TreeNode"; id: string }
                | null
            }>
            lexiconEntries?: Array<{
              __typename: "NodeRef"
              id: string
              target?:
                | { __typename: "CommentNode"; id: string }
                | { __typename: "ExternalSite"; id: string }
                | { __typename: "ST4Class"; id: string }
                | {
                    __typename: "ST4Node"
                    aspectId: any
                    label?: string | null
                    id: string
                    content?:
                      | { __typename: "LexiconContent" }
                      | {
                          __typename: "LexiconEntryContent"
                          descriptionXast?: string | null
                          subject?: string | null
                          sortKey?: string | null
                          lexicon:
                            | { __typename: "CommentNode"; id: string }
                            | { __typename: "ExternalSite"; id: string }
                            | { __typename: "ST4Class"; id: string }
                            | { __typename: "ST4Node"; label?: string | null; id: string }
                            | { __typename: "ST4TreeNode"; id: string }
                          terms: Array<
                            | { __typename: "CommentNode"; id: string }
                            | { __typename: "ExternalSite"; id: string }
                            | { __typename: "ST4Class"; id: string }
                            | {
                                __typename: "ST4Node"
                                id: string
                                label?: string | null
                                aspectId: any
                                content?:
                                  | { __typename: "LexiconContent" }
                                  | { __typename: "LexiconEntryContent" }
                                  | { __typename: "MediaContent" }
                                  | { __typename: "MediaGroupContent" }
                                  | { __typename: "ProjectContent" }
                                  | {
                                      __typename: "TermContent"
                                      gramaticalGender?: string | null
                                      validity?: string | null
                                      editLanguage?: string | null
                                    }
                                  | { __typename: "TextContent" }
                                  | { __typename: "TextGroupContent" }
                                  | { __typename: "VariableTableContent" }
                                  | null
                              }
                            | { __typename: "ST4TreeNode"; id: string }
                          >
                          image?:
                            | { __typename: "CommentNode"; id: string }
                            | { __typename: "ExternalSite"; id: string }
                            | { __typename: "ST4Class"; id: string }
                            | {
                                __typename: "ST4Node"
                                aspectId: any
                                id: string
                                content?:
                                  | { __typename: "LexiconContent" }
                                  | { __typename: "LexiconEntryContent" }
                                  | {
                                      __typename: "MediaContent"
                                      media?:
                                        | {
                                            __typename: "AnimationMediaInfo"
                                            thumbnail?: string | null
                                            mediaType?: Types.MediaType | null
                                          }
                                        | {
                                            __typename: "ImageMediaInfo"
                                            src?: string | null
                                            thumbnail?: string | null
                                            mediaType?: Types.MediaType | null
                                          }
                                        | { __typename: "NoMediaInfo" }
                                        | {
                                            __typename: "VectorGraphicsMediaInfo"
                                            svg?: string | null
                                            thumbnail?: string | null
                                            mediaType?: Types.MediaType | null
                                          }
                                        | {
                                            __typename: "VideoMediaInfo"
                                            thumbnail?: string | null
                                            mediaType?: Types.MediaType | null
                                          }
                                        | null
                                    }
                                  | { __typename: "MediaGroupContent" }
                                  | { __typename: "ProjectContent" }
                                  | { __typename: "TermContent" }
                                  | { __typename: "TextContent" }
                                  | { __typename: "TextGroupContent" }
                                  | { __typename: "VariableTableContent" }
                                  | null
                              }
                            | { __typename: "ST4TreeNode"; id: string }
                            | null
                        }
                      | { __typename: "MediaContent" }
                      | { __typename: "MediaGroupContent" }
                      | { __typename: "ProjectContent" }
                      | { __typename: "TermContent" }
                      | { __typename: "TextContent" }
                      | { __typename: "TextGroupContent" }
                      | { __typename: "VariableTableContent" }
                      | null
                  }
                | { __typename: "ST4TreeNode"; id: string }
                | null
            } | null> | null
            comments: Array<{
              __typename: "CommentNode"
              id: string
              comment:
                | {
                    __typename: "BlockComment"
                    location: number
                    value?: string | null
                    authorDisplayName?: string | null
                    authorUserName?: string | null
                    commentKey: string
                    editDate?: any | null
                    modificationVersion?: number | null
                    replyToKey?: string | null
                    sortOrder?: number | null
                    commentType?: string | null
                    state?: string | null
                    color: string
                    persistanceState?: Types.PersistanceState | null
                    persistanceAction?: Types.PersistanceAction | null
                    node: {
                      __typename: "ST4Node"
                      aspectId: any
                      id: string
                      content?:
                        | { __typename: "LexiconContent" }
                        | { __typename: "LexiconEntryContent" }
                        | { __typename: "MediaContent" }
                        | { __typename: "MediaGroupContent" }
                        | { __typename: "ProjectContent" }
                        | { __typename: "TermContent" }
                        | { __typename: "TextContent"; id: string; historyNumber: number }
                        | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                        | { __typename: "VariableTableContent" }
                        | null
                    }
                    actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
                  }
                | {
                    __typename: "TextComment"
                    endLocation: number
                    startLocation?: number | null
                    value?: string | null
                    authorDisplayName?: string | null
                    authorUserName?: string | null
                    commentKey: string
                    editDate?: any | null
                    modificationVersion?: number | null
                    replyToKey?: string | null
                    sortOrder?: number | null
                    commentType?: string | null
                    state?: string | null
                    color: string
                    persistanceState?: Types.PersistanceState | null
                    persistanceAction?: Types.PersistanceAction | null
                    node: {
                      __typename: "ST4Node"
                      aspectId: any
                      id: string
                      content?:
                        | { __typename: "LexiconContent" }
                        | { __typename: "LexiconEntryContent" }
                        | { __typename: "MediaContent" }
                        | { __typename: "MediaGroupContent" }
                        | { __typename: "ProjectContent" }
                        | { __typename: "TermContent" }
                        | { __typename: "TextContent"; id: string; historyNumber: number }
                        | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                        | { __typename: "VariableTableContent" }
                        | null
                    }
                    actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
                  }
            }>
          }
        | { __typename: "VariableTableContent" }
        | null
    }
  }>
  links?: Array<{
    __typename: "TextContentLink"
    ref?: string | null
    node?:
      | { __typename: "CommentNode"; id: string }
      | { __typename: "ExternalSite"; id: string }
      | { __typename: "ST4Class"; id: string }
      | {
          __typename: "ST4Node"
          id: string
          aspectId: any
          label?: string | null
          properties?: {
            __typename: "ContentPropertyConnection"
            totalCount: number
            edges?: Array<{
              __typename: "ContentPropertyEdge"
              node?: {
                __typename: "ContentProperty"
                typeOfValue: Types.ContentPropertyValueType
                name: string
                value?: any | null
                displayName: string
              } | null
            } | null> | null
          } | null
        }
      | { __typename: "ST4TreeNode"; id: string }
      | null
  } | null> | null
  images: Array<{
    __typename: "NodeRef"
    id: string
    target?:
      | { __typename: "CommentNode"; id: string }
      | { __typename: "ExternalSite"; id: string }
      | { __typename: "ST4Class"; id: string }
      | {
          __typename: "ST4Node"
          aspectId: any
          id: string
          releaseState: {
            __typename: "ReleaseStateValue"
            displayName: string
            state: Types.ReleaseState
            isImplicitlyReleased: boolean
          }
          content?:
            | { __typename: "LexiconContent" }
            | { __typename: "LexiconEntryContent" }
            | {
                __typename: "MediaContent"
                media?:
                  | {
                      __typename: "AnimationMediaInfo"
                      id: string
                      resourceId?: string | null
                      thumbnail?: string | null
                      width?: number | null
                      height?: number | null
                      mediaType?: Types.MediaType | null
                    }
                  | {
                      __typename: "ImageMediaInfo"
                      id: string
                      resourceId?: string | null
                      thumbnail?: string | null
                      width?: number | null
                      height?: number | null
                      resolution?: number | null
                      colorDepth?: number | null
                      size?: number | null
                      mime?: string | null
                      extension?: string | null
                      allowCaching?: boolean | null
                      mediaType?: Types.MediaType | null
                      src?: string | null
                      language?: string | null
                    }
                  | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                  | {
                      __typename: "VectorGraphicsMediaInfo"
                      id: string
                      resourceId?: string | null
                      thumbnail?: string | null
                      width?: number | null
                      height?: number | null
                      allowCaching?: boolean | null
                      mediaType?: Types.MediaType | null
                      language?: string | null
                      labels: Array<{
                        __typename: "VectorGraphicsLabel"
                        id?: string | null
                        sequence?: number | null
                        autoValue?: string | null
                        text?: string | null
                        linkType?: Types.VectorGraphicsLabelLinkType | null
                        link?: string | null
                        ref?:
                          | {
                              __typename: "GenericRef"
                              id: string
                              owner?:
                                | { __typename: "CommentNode" }
                                | { __typename: "ExternalSite" }
                                | { __typename: "ST4Class" }
                                | { __typename: "ST4Node"; id: string }
                                | { __typename: "ST4TreeNode" }
                                | null
                              target?:
                                | { __typename: "CommentNode" }
                                | {
                                    __typename: "ExternalSite"
                                    id: string
                                    teaserData?: {
                                      __typename: "ExternalLinkTeaserData"
                                      title?: string | null
                                      summary?: string | null
                                      iconUrl?: string | null
                                      imageUrl?: string | null
                                      status?: Types.LinkTargetStatus | null
                                    } | null
                                  }
                                | { __typename: "ST4Class" }
                                | {
                                    __typename: "ST4Node"
                                    id: string
                                    aspectId: any
                                    label?: string | null
                                    properties?: {
                                      __typename: "ContentPropertyConnection"
                                      edges?: Array<{
                                        __typename: "ContentPropertyEdge"
                                        node?: {
                                          __typename: "ContentProperty"
                                          name: string
                                          value?: any | null
                                          displayName: string
                                        } | null
                                      } | null> | null
                                    } | null
                                  }
                                | { __typename: "ST4TreeNode" }
                                | null
                            }
                          | {
                              __typename: "NodeRef"
                              id: string
                              owner?:
                                | { __typename: "CommentNode" }
                                | { __typename: "ExternalSite" }
                                | { __typename: "ST4Class" }
                                | { __typename: "ST4Node"; id: string }
                                | { __typename: "ST4TreeNode" }
                                | null
                              target?:
                                | { __typename: "CommentNode" }
                                | {
                                    __typename: "ExternalSite"
                                    id: string
                                    teaserData?: {
                                      __typename: "ExternalLinkTeaserData"
                                      title?: string | null
                                      summary?: string | null
                                      iconUrl?: string | null
                                      imageUrl?: string | null
                                      status?: Types.LinkTargetStatus | null
                                    } | null
                                  }
                                | { __typename: "ST4Class" }
                                | {
                                    __typename: "ST4Node"
                                    id: string
                                    aspectId: any
                                    label?: string | null
                                    properties?: {
                                      __typename: "ContentPropertyConnection"
                                      edges?: Array<{
                                        __typename: "ContentPropertyEdge"
                                        node?: {
                                          __typename: "ContentProperty"
                                          name: string
                                          value?: any | null
                                          displayName: string
                                        } | null
                                      } | null> | null
                                    } | null
                                  }
                                | { __typename: "ST4TreeNode" }
                                | null
                            }
                          | null
                      }>
                    }
                  | {
                      __typename: "VideoMediaInfo"
                      id: string
                      resourceId?: string | null
                      thumbnail?: string | null
                      width?: number | null
                      height?: number | null
                      mediaType?: Types.MediaType | null
                    }
                  | null
              }
            | {
                __typename: "MediaGroupContent"
                children: Array<{
                  __typename: "NodeRef"
                  id: string
                  target?:
                    | { __typename: "CommentNode"; id: string }
                    | { __typename: "ExternalSite"; id: string }
                    | { __typename: "ST4Class"; id: string }
                    | {
                        __typename: "ST4Node"
                        id: string
                        aspectId: any
                        content?:
                          | { __typename: "LexiconContent" }
                          | { __typename: "LexiconEntryContent" }
                          | {
                              __typename: "MediaContent"
                              media?:
                                | {
                                    __typename: "AnimationMediaInfo"
                                    id: string
                                    resourceId?: string | null
                                    thumbnail?: string | null
                                    width?: number | null
                                    height?: number | null
                                    mediaType?: Types.MediaType | null
                                  }
                                | {
                                    __typename: "ImageMediaInfo"
                                    id: string
                                    resourceId?: string | null
                                    thumbnail?: string | null
                                    width?: number | null
                                    height?: number | null
                                    resolution?: number | null
                                    colorDepth?: number | null
                                    size?: number | null
                                    mime?: string | null
                                    extension?: string | null
                                    allowCaching?: boolean | null
                                    mediaType?: Types.MediaType | null
                                    src?: string | null
                                    language?: string | null
                                  }
                                | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                                | {
                                    __typename: "VectorGraphicsMediaInfo"
                                    id: string
                                    resourceId?: string | null
                                    thumbnail?: string | null
                                    width?: number | null
                                    height?: number | null
                                    allowCaching?: boolean | null
                                    mediaType?: Types.MediaType | null
                                    language?: string | null
                                    labels: Array<{
                                      __typename: "VectorGraphicsLabel"
                                      id?: string | null
                                      sequence?: number | null
                                      autoValue?: string | null
                                      text?: string | null
                                      linkType?: Types.VectorGraphicsLabelLinkType | null
                                      link?: string | null
                                      ref?:
                                        | {
                                            __typename: "GenericRef"
                                            id: string
                                            owner?:
                                              | { __typename: "CommentNode" }
                                              | { __typename: "ExternalSite" }
                                              | { __typename: "ST4Class" }
                                              | { __typename: "ST4Node"; id: string }
                                              | { __typename: "ST4TreeNode" }
                                              | null
                                            target?:
                                              | { __typename: "CommentNode" }
                                              | {
                                                  __typename: "ExternalSite"
                                                  id: string
                                                  teaserData?: {
                                                    __typename: "ExternalLinkTeaserData"
                                                    title?: string | null
                                                    summary?: string | null
                                                    iconUrl?: string | null
                                                    imageUrl?: string | null
                                                    status?: Types.LinkTargetStatus | null
                                                  } | null
                                                }
                                              | { __typename: "ST4Class" }
                                              | {
                                                  __typename: "ST4Node"
                                                  id: string
                                                  aspectId: any
                                                  label?: string | null
                                                  properties?: {
                                                    __typename: "ContentPropertyConnection"
                                                    edges?: Array<{
                                                      __typename: "ContentPropertyEdge"
                                                      node?: {
                                                        __typename: "ContentProperty"
                                                        name: string
                                                        value?: any | null
                                                        displayName: string
                                                      } | null
                                                    } | null> | null
                                                  } | null
                                                }
                                              | { __typename: "ST4TreeNode" }
                                              | null
                                          }
                                        | {
                                            __typename: "NodeRef"
                                            id: string
                                            owner?:
                                              | { __typename: "CommentNode" }
                                              | { __typename: "ExternalSite" }
                                              | { __typename: "ST4Class" }
                                              | { __typename: "ST4Node"; id: string }
                                              | { __typename: "ST4TreeNode" }
                                              | null
                                            target?:
                                              | { __typename: "CommentNode" }
                                              | {
                                                  __typename: "ExternalSite"
                                                  id: string
                                                  teaserData?: {
                                                    __typename: "ExternalLinkTeaserData"
                                                    title?: string | null
                                                    summary?: string | null
                                                    iconUrl?: string | null
                                                    imageUrl?: string | null
                                                    status?: Types.LinkTargetStatus | null
                                                  } | null
                                                }
                                              | { __typename: "ST4Class" }
                                              | {
                                                  __typename: "ST4Node"
                                                  id: string
                                                  aspectId: any
                                                  label?: string | null
                                                  properties?: {
                                                    __typename: "ContentPropertyConnection"
                                                    edges?: Array<{
                                                      __typename: "ContentPropertyEdge"
                                                      node?: {
                                                        __typename: "ContentProperty"
                                                        name: string
                                                        value?: any | null
                                                        displayName: string
                                                      } | null
                                                    } | null> | null
                                                  } | null
                                                }
                                              | { __typename: "ST4TreeNode" }
                                              | null
                                          }
                                        | null
                                    }>
                                  }
                                | {
                                    __typename: "VideoMediaInfo"
                                    id: string
                                    resourceId?: string | null
                                    thumbnail?: string | null
                                    width?: number | null
                                    height?: number | null
                                    mediaType?: Types.MediaType | null
                                  }
                                | null
                            }
                          | {
                              __typename: "MediaGroupContent"
                              media?:
                                | {
                                    __typename: "AnimationMediaInfo"
                                    id: string
                                    resourceId?: string | null
                                    thumbnail?: string | null
                                    width?: number | null
                                    height?: number | null
                                    mediaType?: Types.MediaType | null
                                  }
                                | {
                                    __typename: "ImageMediaInfo"
                                    id: string
                                    resourceId?: string | null
                                    thumbnail?: string | null
                                    width?: number | null
                                    height?: number | null
                                    resolution?: number | null
                                    colorDepth?: number | null
                                    size?: number | null
                                    mime?: string | null
                                    extension?: string | null
                                    allowCaching?: boolean | null
                                    mediaType?: Types.MediaType | null
                                    src?: string | null
                                    language?: string | null
                                  }
                                | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                                | {
                                    __typename: "VectorGraphicsMediaInfo"
                                    id: string
                                    resourceId?: string | null
                                    thumbnail?: string | null
                                    width?: number | null
                                    height?: number | null
                                    allowCaching?: boolean | null
                                    mediaType?: Types.MediaType | null
                                    language?: string | null
                                    labels: Array<{
                                      __typename: "VectorGraphicsLabel"
                                      id?: string | null
                                      sequence?: number | null
                                      autoValue?: string | null
                                      text?: string | null
                                      linkType?: Types.VectorGraphicsLabelLinkType | null
                                      link?: string | null
                                      ref?:
                                        | {
                                            __typename: "GenericRef"
                                            id: string
                                            owner?:
                                              | { __typename: "CommentNode" }
                                              | { __typename: "ExternalSite" }
                                              | { __typename: "ST4Class" }
                                              | { __typename: "ST4Node"; id: string }
                                              | { __typename: "ST4TreeNode" }
                                              | null
                                            target?:
                                              | { __typename: "CommentNode" }
                                              | {
                                                  __typename: "ExternalSite"
                                                  id: string
                                                  teaserData?: {
                                                    __typename: "ExternalLinkTeaserData"
                                                    title?: string | null
                                                    summary?: string | null
                                                    iconUrl?: string | null
                                                    imageUrl?: string | null
                                                    status?: Types.LinkTargetStatus | null
                                                  } | null
                                                }
                                              | { __typename: "ST4Class" }
                                              | {
                                                  __typename: "ST4Node"
                                                  id: string
                                                  aspectId: any
                                                  label?: string | null
                                                  properties?: {
                                                    __typename: "ContentPropertyConnection"
                                                    edges?: Array<{
                                                      __typename: "ContentPropertyEdge"
                                                      node?: {
                                                        __typename: "ContentProperty"
                                                        name: string
                                                        value?: any | null
                                                        displayName: string
                                                      } | null
                                                    } | null> | null
                                                  } | null
                                                }
                                              | { __typename: "ST4TreeNode" }
                                              | null
                                          }
                                        | {
                                            __typename: "NodeRef"
                                            id: string
                                            owner?:
                                              | { __typename: "CommentNode" }
                                              | { __typename: "ExternalSite" }
                                              | { __typename: "ST4Class" }
                                              | { __typename: "ST4Node"; id: string }
                                              | { __typename: "ST4TreeNode" }
                                              | null
                                            target?:
                                              | { __typename: "CommentNode" }
                                              | {
                                                  __typename: "ExternalSite"
                                                  id: string
                                                  teaserData?: {
                                                    __typename: "ExternalLinkTeaserData"
                                                    title?: string | null
                                                    summary?: string | null
                                                    iconUrl?: string | null
                                                    imageUrl?: string | null
                                                    status?: Types.LinkTargetStatus | null
                                                  } | null
                                                }
                                              | { __typename: "ST4Class" }
                                              | {
                                                  __typename: "ST4Node"
                                                  id: string
                                                  aspectId: any
                                                  label?: string | null
                                                  properties?: {
                                                    __typename: "ContentPropertyConnection"
                                                    edges?: Array<{
                                                      __typename: "ContentPropertyEdge"
                                                      node?: {
                                                        __typename: "ContentProperty"
                                                        name: string
                                                        value?: any | null
                                                        displayName: string
                                                      } | null
                                                    } | null> | null
                                                  } | null
                                                }
                                              | { __typename: "ST4TreeNode" }
                                              | null
                                          }
                                        | null
                                    }>
                                  }
                                | {
                                    __typename: "VideoMediaInfo"
                                    id: string
                                    resourceId?: string | null
                                    thumbnail?: string | null
                                    width?: number | null
                                    height?: number | null
                                    mediaType?: Types.MediaType | null
                                  }
                                | null
                            }
                          | { __typename: "ProjectContent" }
                          | { __typename: "TermContent" }
                          | { __typename: "TextContent" }
                          | { __typename: "TextGroupContent" }
                          | { __typename: "VariableTableContent" }
                          | null
                      }
                    | { __typename: "ST4TreeNode"; id: string }
                    | null
                }>
                media?:
                  | {
                      __typename: "AnimationMediaInfo"
                      id: string
                      resourceId?: string | null
                      thumbnail?: string | null
                      width?: number | null
                      height?: number | null
                      mediaType?: Types.MediaType | null
                    }
                  | {
                      __typename: "ImageMediaInfo"
                      id: string
                      resourceId?: string | null
                      thumbnail?: string | null
                      width?: number | null
                      height?: number | null
                      resolution?: number | null
                      colorDepth?: number | null
                      size?: number | null
                      mime?: string | null
                      extension?: string | null
                      allowCaching?: boolean | null
                      mediaType?: Types.MediaType | null
                      src?: string | null
                      language?: string | null
                    }
                  | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                  | {
                      __typename: "VectorGraphicsMediaInfo"
                      id: string
                      resourceId?: string | null
                      thumbnail?: string | null
                      width?: number | null
                      height?: number | null
                      allowCaching?: boolean | null
                      mediaType?: Types.MediaType | null
                      language?: string | null
                      labels: Array<{
                        __typename: "VectorGraphicsLabel"
                        id?: string | null
                        sequence?: number | null
                        autoValue?: string | null
                        text?: string | null
                        linkType?: Types.VectorGraphicsLabelLinkType | null
                        link?: string | null
                        ref?:
                          | {
                              __typename: "GenericRef"
                              id: string
                              owner?:
                                | { __typename: "CommentNode" }
                                | { __typename: "ExternalSite" }
                                | { __typename: "ST4Class" }
                                | { __typename: "ST4Node"; id: string }
                                | { __typename: "ST4TreeNode" }
                                | null
                              target?:
                                | { __typename: "CommentNode" }
                                | {
                                    __typename: "ExternalSite"
                                    id: string
                                    teaserData?: {
                                      __typename: "ExternalLinkTeaserData"
                                      title?: string | null
                                      summary?: string | null
                                      iconUrl?: string | null
                                      imageUrl?: string | null
                                      status?: Types.LinkTargetStatus | null
                                    } | null
                                  }
                                | { __typename: "ST4Class" }
                                | {
                                    __typename: "ST4Node"
                                    id: string
                                    aspectId: any
                                    label?: string | null
                                    properties?: {
                                      __typename: "ContentPropertyConnection"
                                      edges?: Array<{
                                        __typename: "ContentPropertyEdge"
                                        node?: {
                                          __typename: "ContentProperty"
                                          name: string
                                          value?: any | null
                                          displayName: string
                                        } | null
                                      } | null> | null
                                    } | null
                                  }
                                | { __typename: "ST4TreeNode" }
                                | null
                            }
                          | {
                              __typename: "NodeRef"
                              id: string
                              owner?:
                                | { __typename: "CommentNode" }
                                | { __typename: "ExternalSite" }
                                | { __typename: "ST4Class" }
                                | { __typename: "ST4Node"; id: string }
                                | { __typename: "ST4TreeNode" }
                                | null
                              target?:
                                | { __typename: "CommentNode" }
                                | {
                                    __typename: "ExternalSite"
                                    id: string
                                    teaserData?: {
                                      __typename: "ExternalLinkTeaserData"
                                      title?: string | null
                                      summary?: string | null
                                      iconUrl?: string | null
                                      imageUrl?: string | null
                                      status?: Types.LinkTargetStatus | null
                                    } | null
                                  }
                                | { __typename: "ST4Class" }
                                | {
                                    __typename: "ST4Node"
                                    id: string
                                    aspectId: any
                                    label?: string | null
                                    properties?: {
                                      __typename: "ContentPropertyConnection"
                                      edges?: Array<{
                                        __typename: "ContentPropertyEdge"
                                        node?: {
                                          __typename: "ContentProperty"
                                          name: string
                                          value?: any | null
                                          displayName: string
                                        } | null
                                      } | null> | null
                                    } | null
                                  }
                                | { __typename: "ST4TreeNode" }
                                | null
                            }
                          | null
                      }>
                    }
                  | {
                      __typename: "VideoMediaInfo"
                      id: string
                      resourceId?: string | null
                      thumbnail?: string | null
                      width?: number | null
                      height?: number | null
                      mediaType?: Types.MediaType | null
                    }
                  | null
              }
            | { __typename: "ProjectContent" }
            | { __typename: "TermContent" }
            | { __typename: "TextContent" }
            | { __typename: "TextGroupContent" }
            | { __typename: "VariableTableContent" }
            | null
        }
      | { __typename: "ST4TreeNode"; id: string }
      | null
  }>
  variableTables?: Array<{
    __typename: "VariableTableReference"
    ref?: string | null
    node?:
      | { __typename: "CommentNode" }
      | { __typename: "ExternalSite" }
      | { __typename: "ST4Class" }
      | {
          __typename: "ST4Node"
          id: string
          label?: string | null
          aspectId: any
          content?:
            | { __typename: "LexiconContent" }
            | { __typename: "LexiconEntryContent" }
            | { __typename: "MediaContent" }
            | { __typename: "MediaGroupContent" }
            | { __typename: "ProjectContent" }
            | { __typename: "TermContent" }
            | { __typename: "TextContent" }
            | { __typename: "TextGroupContent" }
            | {
                __typename: "VariableTableContent"
                xml?: string | null
                valueSets: Array<{ __typename: "ValueSet"; name: string; valueSetId: string }>
                variables: Array<{ __typename: "Variable"; name: string; variableId: string; values: Array<string> }>
              }
            | null
        }
      | { __typename: "ST4TreeNode" }
      | null
  } | null> | null
  linkedDataNodes: Array<{
    __typename: "NodeRef"
    id: string
    target?:
      | { __typename: "CommentNode"; id: string }
      | { __typename: "ExternalSite"; id: string }
      | { __typename: "ST4Class"; id: string }
      | {
          __typename: "ST4Node"
          label?: string | null
          id: string
          metadata: Array<{
            __typename: "MetaDataEntry"
            label?: string | null
            systemName: string
            value?: string | null
            values: Array<string>
            users: Array<string>
          }>
          linkedOntologyNodes: Array<
            | {
                __typename: "GenericRef"
                target?:
                  | { __typename: "CommentNode" }
                  | { __typename: "ExternalSite" }
                  | { __typename: "ST4Class" }
                  | { __typename: "ST4Node"; id: string; label?: string | null }
                  | { __typename: "ST4TreeNode" }
                  | null
              }
            | {
                __typename: "NodeRef"
                linkClassName: string
                class?: { __typename: "ST4Class"; label?: string | null } | null
                target?:
                  | { __typename: "CommentNode" }
                  | { __typename: "ExternalSite" }
                  | { __typename: "ST4Class" }
                  | { __typename: "ST4Node"; id: string; label?: string | null }
                  | { __typename: "ST4TreeNode" }
                  | null
              }
          >
        }
      | { __typename: "ST4TreeNode"; id: string }
      | null
  }>
  lexiconEntries?: Array<{
    __typename: "NodeRef"
    id: string
    target?:
      | { __typename: "CommentNode"; id: string }
      | { __typename: "ExternalSite"; id: string }
      | { __typename: "ST4Class"; id: string }
      | {
          __typename: "ST4Node"
          aspectId: any
          label?: string | null
          id: string
          content?:
            | { __typename: "LexiconContent" }
            | {
                __typename: "LexiconEntryContent"
                descriptionXast?: string | null
                subject?: string | null
                sortKey?: string | null
                lexicon:
                  | { __typename: "CommentNode"; id: string }
                  | { __typename: "ExternalSite"; id: string }
                  | { __typename: "ST4Class"; id: string }
                  | { __typename: "ST4Node"; label?: string | null; id: string }
                  | { __typename: "ST4TreeNode"; id: string }
                terms: Array<
                  | { __typename: "CommentNode"; id: string }
                  | { __typename: "ExternalSite"; id: string }
                  | { __typename: "ST4Class"; id: string }
                  | {
                      __typename: "ST4Node"
                      id: string
                      label?: string | null
                      aspectId: any
                      content?:
                        | { __typename: "LexiconContent" }
                        | { __typename: "LexiconEntryContent" }
                        | { __typename: "MediaContent" }
                        | { __typename: "MediaGroupContent" }
                        | { __typename: "ProjectContent" }
                        | {
                            __typename: "TermContent"
                            gramaticalGender?: string | null
                            validity?: string | null
                            editLanguage?: string | null
                          }
                        | { __typename: "TextContent" }
                        | { __typename: "TextGroupContent" }
                        | { __typename: "VariableTableContent" }
                        | null
                    }
                  | { __typename: "ST4TreeNode"; id: string }
                >
                image?:
                  | { __typename: "CommentNode"; id: string }
                  | { __typename: "ExternalSite"; id: string }
                  | { __typename: "ST4Class"; id: string }
                  | {
                      __typename: "ST4Node"
                      aspectId: any
                      id: string
                      content?:
                        | { __typename: "LexiconContent" }
                        | { __typename: "LexiconEntryContent" }
                        | {
                            __typename: "MediaContent"
                            media?:
                              | {
                                  __typename: "AnimationMediaInfo"
                                  thumbnail?: string | null
                                  mediaType?: Types.MediaType | null
                                }
                              | {
                                  __typename: "ImageMediaInfo"
                                  src?: string | null
                                  thumbnail?: string | null
                                  mediaType?: Types.MediaType | null
                                }
                              | { __typename: "NoMediaInfo" }
                              | {
                                  __typename: "VectorGraphicsMediaInfo"
                                  svg?: string | null
                                  thumbnail?: string | null
                                  mediaType?: Types.MediaType | null
                                }
                              | {
                                  __typename: "VideoMediaInfo"
                                  thumbnail?: string | null
                                  mediaType?: Types.MediaType | null
                                }
                              | null
                          }
                        | { __typename: "MediaGroupContent" }
                        | { __typename: "ProjectContent" }
                        | { __typename: "TermContent" }
                        | { __typename: "TextContent" }
                        | { __typename: "TextGroupContent" }
                        | { __typename: "VariableTableContent" }
                        | null
                    }
                  | { __typename: "ST4TreeNode"; id: string }
                  | null
              }
            | { __typename: "MediaContent" }
            | { __typename: "MediaGroupContent" }
            | { __typename: "ProjectContent" }
            | { __typename: "TermContent" }
            | { __typename: "TextContent" }
            | { __typename: "TextGroupContent" }
            | { __typename: "VariableTableContent" }
            | null
        }
      | { __typename: "ST4TreeNode"; id: string }
      | null
  } | null> | null
  comments: Array<{
    __typename: "CommentNode"
    id: string
    comment:
      | {
          __typename: "BlockComment"
          location: number
          value?: string | null
          authorDisplayName?: string | null
          authorUserName?: string | null
          commentKey: string
          editDate?: any | null
          modificationVersion?: number | null
          replyToKey?: string | null
          sortOrder?: number | null
          commentType?: string | null
          state?: string | null
          color: string
          persistanceState?: Types.PersistanceState | null
          persistanceAction?: Types.PersistanceAction | null
          node: {
            __typename: "ST4Node"
            aspectId: any
            id: string
            content?:
              | { __typename: "LexiconContent" }
              | { __typename: "LexiconEntryContent" }
              | { __typename: "MediaContent" }
              | { __typename: "MediaGroupContent" }
              | { __typename: "ProjectContent" }
              | { __typename: "TermContent" }
              | { __typename: "TextContent"; id: string; historyNumber: number }
              | { __typename: "TextGroupContent"; id: string; historyNumber: number }
              | { __typename: "VariableTableContent" }
              | null
          }
          actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
        }
      | {
          __typename: "TextComment"
          endLocation: number
          startLocation?: number | null
          value?: string | null
          authorDisplayName?: string | null
          authorUserName?: string | null
          commentKey: string
          editDate?: any | null
          modificationVersion?: number | null
          replyToKey?: string | null
          sortOrder?: number | null
          commentType?: string | null
          state?: string | null
          color: string
          persistanceState?: Types.PersistanceState | null
          persistanceAction?: Types.PersistanceAction | null
          node: {
            __typename: "ST4Node"
            aspectId: any
            id: string
            content?:
              | { __typename: "LexiconContent" }
              | { __typename: "LexiconEntryContent" }
              | { __typename: "MediaContent" }
              | { __typename: "MediaGroupContent" }
              | { __typename: "ProjectContent" }
              | { __typename: "TermContent" }
              | { __typename: "TextContent"; id: string; historyNumber: number }
              | { __typename: "TextGroupContent"; id: string; historyNumber: number }
              | { __typename: "VariableTableContent" }
              | null
          }
          actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
        }
  }>
}

export type FragmentContents_TextGroupContent_Fragment = {
  __typename: "TextGroupContent"
  currentNodeId?: any | null
  groupNodeId: any
  id: string
  xast?: string | null
  historyNumber: number
  parts: Array<{
    __typename: "TextContentPart"
    ref: string
    contentNode: {
      __typename: "ST4Node"
      id: string
      aspectId: any
      content?:
        | { __typename: "LexiconContent" }
        | { __typename: "LexiconEntryContent" }
        | { __typename: "MediaContent" }
        | { __typename: "MediaGroupContent" }
        | { __typename: "ProjectContent" }
        | { __typename: "TermContent" }
        | {
            __typename: "TextContent"
            id: string
            xast?: string | null
            historyNumber: number
            links?: Array<{
              __typename: "TextContentLink"
              ref?: string | null
              node?:
                | { __typename: "CommentNode"; id: string }
                | { __typename: "ExternalSite"; id: string }
                | { __typename: "ST4Class"; id: string }
                | {
                    __typename: "ST4Node"
                    id: string
                    aspectId: any
                    label?: string | null
                    properties?: {
                      __typename: "ContentPropertyConnection"
                      totalCount: number
                      edges?: Array<{
                        __typename: "ContentPropertyEdge"
                        node?: {
                          __typename: "ContentProperty"
                          typeOfValue: Types.ContentPropertyValueType
                          name: string
                          value?: any | null
                          displayName: string
                        } | null
                      } | null> | null
                    } | null
                  }
                | { __typename: "ST4TreeNode"; id: string }
                | null
            } | null> | null
            images: Array<{
              __typename: "NodeRef"
              id: string
              target?:
                | { __typename: "CommentNode"; id: string }
                | { __typename: "ExternalSite"; id: string }
                | { __typename: "ST4Class"; id: string }
                | {
                    __typename: "ST4Node"
                    aspectId: any
                    id: string
                    releaseState: {
                      __typename: "ReleaseStateValue"
                      displayName: string
                      state: Types.ReleaseState
                      isImplicitlyReleased: boolean
                    }
                    content?:
                      | { __typename: "LexiconContent" }
                      | { __typename: "LexiconEntryContent" }
                      | {
                          __typename: "MediaContent"
                          media?:
                            | {
                                __typename: "AnimationMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                mediaType?: Types.MediaType | null
                              }
                            | {
                                __typename: "ImageMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                resolution?: number | null
                                colorDepth?: number | null
                                size?: number | null
                                mime?: string | null
                                extension?: string | null
                                allowCaching?: boolean | null
                                mediaType?: Types.MediaType | null
                                src?: string | null
                                language?: string | null
                              }
                            | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                            | {
                                __typename: "VectorGraphicsMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                allowCaching?: boolean | null
                                mediaType?: Types.MediaType | null
                                language?: string | null
                                labels: Array<{
                                  __typename: "VectorGraphicsLabel"
                                  id?: string | null
                                  sequence?: number | null
                                  autoValue?: string | null
                                  text?: string | null
                                  linkType?: Types.VectorGraphicsLabelLinkType | null
                                  link?: string | null
                                  ref?:
                                    | {
                                        __typename: "GenericRef"
                                        id: string
                                        owner?:
                                          | { __typename: "CommentNode" }
                                          | { __typename: "ExternalSite" }
                                          | { __typename: "ST4Class" }
                                          | { __typename: "ST4Node"; id: string }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                        target?:
                                          | { __typename: "CommentNode" }
                                          | {
                                              __typename: "ExternalSite"
                                              id: string
                                              teaserData?: {
                                                __typename: "ExternalLinkTeaserData"
                                                title?: string | null
                                                summary?: string | null
                                                iconUrl?: string | null
                                                imageUrl?: string | null
                                                status?: Types.LinkTargetStatus | null
                                              } | null
                                            }
                                          | { __typename: "ST4Class" }
                                          | {
                                              __typename: "ST4Node"
                                              id: string
                                              aspectId: any
                                              label?: string | null
                                              properties?: {
                                                __typename: "ContentPropertyConnection"
                                                edges?: Array<{
                                                  __typename: "ContentPropertyEdge"
                                                  node?: {
                                                    __typename: "ContentProperty"
                                                    name: string
                                                    value?: any | null
                                                    displayName: string
                                                  } | null
                                                } | null> | null
                                              } | null
                                            }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                      }
                                    | {
                                        __typename: "NodeRef"
                                        id: string
                                        owner?:
                                          | { __typename: "CommentNode" }
                                          | { __typename: "ExternalSite" }
                                          | { __typename: "ST4Class" }
                                          | { __typename: "ST4Node"; id: string }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                        target?:
                                          | { __typename: "CommentNode" }
                                          | {
                                              __typename: "ExternalSite"
                                              id: string
                                              teaserData?: {
                                                __typename: "ExternalLinkTeaserData"
                                                title?: string | null
                                                summary?: string | null
                                                iconUrl?: string | null
                                                imageUrl?: string | null
                                                status?: Types.LinkTargetStatus | null
                                              } | null
                                            }
                                          | { __typename: "ST4Class" }
                                          | {
                                              __typename: "ST4Node"
                                              id: string
                                              aspectId: any
                                              label?: string | null
                                              properties?: {
                                                __typename: "ContentPropertyConnection"
                                                edges?: Array<{
                                                  __typename: "ContentPropertyEdge"
                                                  node?: {
                                                    __typename: "ContentProperty"
                                                    name: string
                                                    value?: any | null
                                                    displayName: string
                                                  } | null
                                                } | null> | null
                                              } | null
                                            }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                      }
                                    | null
                                }>
                              }
                            | {
                                __typename: "VideoMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                mediaType?: Types.MediaType | null
                              }
                            | null
                        }
                      | {
                          __typename: "MediaGroupContent"
                          children: Array<{
                            __typename: "NodeRef"
                            id: string
                            target?:
                              | { __typename: "CommentNode"; id: string }
                              | { __typename: "ExternalSite"; id: string }
                              | { __typename: "ST4Class"; id: string }
                              | {
                                  __typename: "ST4Node"
                                  id: string
                                  aspectId: any
                                  content?:
                                    | { __typename: "LexiconContent" }
                                    | { __typename: "LexiconEntryContent" }
                                    | {
                                        __typename: "MediaContent"
                                        media?:
                                          | {
                                              __typename: "AnimationMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              mediaType?: Types.MediaType | null
                                            }
                                          | {
                                              __typename: "ImageMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              resolution?: number | null
                                              colorDepth?: number | null
                                              size?: number | null
                                              mime?: string | null
                                              extension?: string | null
                                              allowCaching?: boolean | null
                                              mediaType?: Types.MediaType | null
                                              src?: string | null
                                              language?: string | null
                                            }
                                          | {
                                              __typename: "NoMediaInfo"
                                              id: string
                                              mediaType?: Types.MediaType | null
                                            }
                                          | {
                                              __typename: "VectorGraphicsMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              allowCaching?: boolean | null
                                              mediaType?: Types.MediaType | null
                                              language?: string | null
                                              labels: Array<{
                                                __typename: "VectorGraphicsLabel"
                                                id?: string | null
                                                sequence?: number | null
                                                autoValue?: string | null
                                                text?: string | null
                                                linkType?: Types.VectorGraphicsLabelLinkType | null
                                                link?: string | null
                                                ref?:
                                                  | {
                                                      __typename: "GenericRef"
                                                      id: string
                                                      owner?:
                                                        | { __typename: "CommentNode" }
                                                        | { __typename: "ExternalSite" }
                                                        | { __typename: "ST4Class" }
                                                        | { __typename: "ST4Node"; id: string }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                      target?:
                                                        | { __typename: "CommentNode" }
                                                        | {
                                                            __typename: "ExternalSite"
                                                            id: string
                                                            teaserData?: {
                                                              __typename: "ExternalLinkTeaserData"
                                                              title?: string | null
                                                              summary?: string | null
                                                              iconUrl?: string | null
                                                              imageUrl?: string | null
                                                              status?: Types.LinkTargetStatus | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4Class" }
                                                        | {
                                                            __typename: "ST4Node"
                                                            id: string
                                                            aspectId: any
                                                            label?: string | null
                                                            properties?: {
                                                              __typename: "ContentPropertyConnection"
                                                              edges?: Array<{
                                                                __typename: "ContentPropertyEdge"
                                                                node?: {
                                                                  __typename: "ContentProperty"
                                                                  name: string
                                                                  value?: any | null
                                                                  displayName: string
                                                                } | null
                                                              } | null> | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                    }
                                                  | {
                                                      __typename: "NodeRef"
                                                      id: string
                                                      owner?:
                                                        | { __typename: "CommentNode" }
                                                        | { __typename: "ExternalSite" }
                                                        | { __typename: "ST4Class" }
                                                        | { __typename: "ST4Node"; id: string }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                      target?:
                                                        | { __typename: "CommentNode" }
                                                        | {
                                                            __typename: "ExternalSite"
                                                            id: string
                                                            teaserData?: {
                                                              __typename: "ExternalLinkTeaserData"
                                                              title?: string | null
                                                              summary?: string | null
                                                              iconUrl?: string | null
                                                              imageUrl?: string | null
                                                              status?: Types.LinkTargetStatus | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4Class" }
                                                        | {
                                                            __typename: "ST4Node"
                                                            id: string
                                                            aspectId: any
                                                            label?: string | null
                                                            properties?: {
                                                              __typename: "ContentPropertyConnection"
                                                              edges?: Array<{
                                                                __typename: "ContentPropertyEdge"
                                                                node?: {
                                                                  __typename: "ContentProperty"
                                                                  name: string
                                                                  value?: any | null
                                                                  displayName: string
                                                                } | null
                                                              } | null> | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                    }
                                                  | null
                                              }>
                                            }
                                          | {
                                              __typename: "VideoMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              mediaType?: Types.MediaType | null
                                            }
                                          | null
                                      }
                                    | {
                                        __typename: "MediaGroupContent"
                                        media?:
                                          | {
                                              __typename: "AnimationMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              mediaType?: Types.MediaType | null
                                            }
                                          | {
                                              __typename: "ImageMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              resolution?: number | null
                                              colorDepth?: number | null
                                              size?: number | null
                                              mime?: string | null
                                              extension?: string | null
                                              allowCaching?: boolean | null
                                              mediaType?: Types.MediaType | null
                                              src?: string | null
                                              language?: string | null
                                            }
                                          | {
                                              __typename: "NoMediaInfo"
                                              id: string
                                              mediaType?: Types.MediaType | null
                                            }
                                          | {
                                              __typename: "VectorGraphicsMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              allowCaching?: boolean | null
                                              mediaType?: Types.MediaType | null
                                              language?: string | null
                                              labels: Array<{
                                                __typename: "VectorGraphicsLabel"
                                                id?: string | null
                                                sequence?: number | null
                                                autoValue?: string | null
                                                text?: string | null
                                                linkType?: Types.VectorGraphicsLabelLinkType | null
                                                link?: string | null
                                                ref?:
                                                  | {
                                                      __typename: "GenericRef"
                                                      id: string
                                                      owner?:
                                                        | { __typename: "CommentNode" }
                                                        | { __typename: "ExternalSite" }
                                                        | { __typename: "ST4Class" }
                                                        | { __typename: "ST4Node"; id: string }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                      target?:
                                                        | { __typename: "CommentNode" }
                                                        | {
                                                            __typename: "ExternalSite"
                                                            id: string
                                                            teaserData?: {
                                                              __typename: "ExternalLinkTeaserData"
                                                              title?: string | null
                                                              summary?: string | null
                                                              iconUrl?: string | null
                                                              imageUrl?: string | null
                                                              status?: Types.LinkTargetStatus | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4Class" }
                                                        | {
                                                            __typename: "ST4Node"
                                                            id: string
                                                            aspectId: any
                                                            label?: string | null
                                                            properties?: {
                                                              __typename: "ContentPropertyConnection"
                                                              edges?: Array<{
                                                                __typename: "ContentPropertyEdge"
                                                                node?: {
                                                                  __typename: "ContentProperty"
                                                                  name: string
                                                                  value?: any | null
                                                                  displayName: string
                                                                } | null
                                                              } | null> | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                    }
                                                  | {
                                                      __typename: "NodeRef"
                                                      id: string
                                                      owner?:
                                                        | { __typename: "CommentNode" }
                                                        | { __typename: "ExternalSite" }
                                                        | { __typename: "ST4Class" }
                                                        | { __typename: "ST4Node"; id: string }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                      target?:
                                                        | { __typename: "CommentNode" }
                                                        | {
                                                            __typename: "ExternalSite"
                                                            id: string
                                                            teaserData?: {
                                                              __typename: "ExternalLinkTeaserData"
                                                              title?: string | null
                                                              summary?: string | null
                                                              iconUrl?: string | null
                                                              imageUrl?: string | null
                                                              status?: Types.LinkTargetStatus | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4Class" }
                                                        | {
                                                            __typename: "ST4Node"
                                                            id: string
                                                            aspectId: any
                                                            label?: string | null
                                                            properties?: {
                                                              __typename: "ContentPropertyConnection"
                                                              edges?: Array<{
                                                                __typename: "ContentPropertyEdge"
                                                                node?: {
                                                                  __typename: "ContentProperty"
                                                                  name: string
                                                                  value?: any | null
                                                                  displayName: string
                                                                } | null
                                                              } | null> | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                    }
                                                  | null
                                              }>
                                            }
                                          | {
                                              __typename: "VideoMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              mediaType?: Types.MediaType | null
                                            }
                                          | null
                                      }
                                    | { __typename: "ProjectContent" }
                                    | { __typename: "TermContent" }
                                    | { __typename: "TextContent" }
                                    | { __typename: "TextGroupContent" }
                                    | { __typename: "VariableTableContent" }
                                    | null
                                }
                              | { __typename: "ST4TreeNode"; id: string }
                              | null
                          }>
                          media?:
                            | {
                                __typename: "AnimationMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                mediaType?: Types.MediaType | null
                              }
                            | {
                                __typename: "ImageMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                resolution?: number | null
                                colorDepth?: number | null
                                size?: number | null
                                mime?: string | null
                                extension?: string | null
                                allowCaching?: boolean | null
                                mediaType?: Types.MediaType | null
                                src?: string | null
                                language?: string | null
                              }
                            | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                            | {
                                __typename: "VectorGraphicsMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                allowCaching?: boolean | null
                                mediaType?: Types.MediaType | null
                                language?: string | null
                                labels: Array<{
                                  __typename: "VectorGraphicsLabel"
                                  id?: string | null
                                  sequence?: number | null
                                  autoValue?: string | null
                                  text?: string | null
                                  linkType?: Types.VectorGraphicsLabelLinkType | null
                                  link?: string | null
                                  ref?:
                                    | {
                                        __typename: "GenericRef"
                                        id: string
                                        owner?:
                                          | { __typename: "CommentNode" }
                                          | { __typename: "ExternalSite" }
                                          | { __typename: "ST4Class" }
                                          | { __typename: "ST4Node"; id: string }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                        target?:
                                          | { __typename: "CommentNode" }
                                          | {
                                              __typename: "ExternalSite"
                                              id: string
                                              teaserData?: {
                                                __typename: "ExternalLinkTeaserData"
                                                title?: string | null
                                                summary?: string | null
                                                iconUrl?: string | null
                                                imageUrl?: string | null
                                                status?: Types.LinkTargetStatus | null
                                              } | null
                                            }
                                          | { __typename: "ST4Class" }
                                          | {
                                              __typename: "ST4Node"
                                              id: string
                                              aspectId: any
                                              label?: string | null
                                              properties?: {
                                                __typename: "ContentPropertyConnection"
                                                edges?: Array<{
                                                  __typename: "ContentPropertyEdge"
                                                  node?: {
                                                    __typename: "ContentProperty"
                                                    name: string
                                                    value?: any | null
                                                    displayName: string
                                                  } | null
                                                } | null> | null
                                              } | null
                                            }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                      }
                                    | {
                                        __typename: "NodeRef"
                                        id: string
                                        owner?:
                                          | { __typename: "CommentNode" }
                                          | { __typename: "ExternalSite" }
                                          | { __typename: "ST4Class" }
                                          | { __typename: "ST4Node"; id: string }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                        target?:
                                          | { __typename: "CommentNode" }
                                          | {
                                              __typename: "ExternalSite"
                                              id: string
                                              teaserData?: {
                                                __typename: "ExternalLinkTeaserData"
                                                title?: string | null
                                                summary?: string | null
                                                iconUrl?: string | null
                                                imageUrl?: string | null
                                                status?: Types.LinkTargetStatus | null
                                              } | null
                                            }
                                          | { __typename: "ST4Class" }
                                          | {
                                              __typename: "ST4Node"
                                              id: string
                                              aspectId: any
                                              label?: string | null
                                              properties?: {
                                                __typename: "ContentPropertyConnection"
                                                edges?: Array<{
                                                  __typename: "ContentPropertyEdge"
                                                  node?: {
                                                    __typename: "ContentProperty"
                                                    name: string
                                                    value?: any | null
                                                    displayName: string
                                                  } | null
                                                } | null> | null
                                              } | null
                                            }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                      }
                                    | null
                                }>
                              }
                            | {
                                __typename: "VideoMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                mediaType?: Types.MediaType | null
                              }
                            | null
                        }
                      | { __typename: "ProjectContent" }
                      | { __typename: "TermContent" }
                      | { __typename: "TextContent" }
                      | { __typename: "TextGroupContent" }
                      | { __typename: "VariableTableContent" }
                      | null
                  }
                | { __typename: "ST4TreeNode"; id: string }
                | null
            }>
            variableTables?: Array<{
              __typename: "VariableTableReference"
              ref?: string | null
              node?:
                | { __typename: "CommentNode" }
                | { __typename: "ExternalSite" }
                | { __typename: "ST4Class" }
                | {
                    __typename: "ST4Node"
                    id: string
                    label?: string | null
                    aspectId: any
                    content?:
                      | { __typename: "LexiconContent" }
                      | { __typename: "LexiconEntryContent" }
                      | { __typename: "MediaContent" }
                      | { __typename: "MediaGroupContent" }
                      | { __typename: "ProjectContent" }
                      | { __typename: "TermContent" }
                      | { __typename: "TextContent" }
                      | { __typename: "TextGroupContent" }
                      | {
                          __typename: "VariableTableContent"
                          xml?: string | null
                          valueSets: Array<{ __typename: "ValueSet"; name: string; valueSetId: string }>
                          variables: Array<{
                            __typename: "Variable"
                            name: string
                            variableId: string
                            values: Array<string>
                          }>
                        }
                      | null
                  }
                | { __typename: "ST4TreeNode" }
                | null
            } | null> | null
            linkedDataNodes: Array<{
              __typename: "NodeRef"
              id: string
              target?:
                | { __typename: "CommentNode"; id: string }
                | { __typename: "ExternalSite"; id: string }
                | { __typename: "ST4Class"; id: string }
                | {
                    __typename: "ST4Node"
                    label?: string | null
                    id: string
                    metadata: Array<{
                      __typename: "MetaDataEntry"
                      label?: string | null
                      systemName: string
                      value?: string | null
                      values: Array<string>
                      users: Array<string>
                    }>
                    linkedOntologyNodes: Array<
                      | {
                          __typename: "GenericRef"
                          target?:
                            | { __typename: "CommentNode" }
                            | { __typename: "ExternalSite" }
                            | { __typename: "ST4Class" }
                            | { __typename: "ST4Node"; id: string; label?: string | null }
                            | { __typename: "ST4TreeNode" }
                            | null
                        }
                      | {
                          __typename: "NodeRef"
                          linkClassName: string
                          class?: { __typename: "ST4Class"; label?: string | null } | null
                          target?:
                            | { __typename: "CommentNode" }
                            | { __typename: "ExternalSite" }
                            | { __typename: "ST4Class" }
                            | { __typename: "ST4Node"; id: string; label?: string | null }
                            | { __typename: "ST4TreeNode" }
                            | null
                        }
                    >
                  }
                | { __typename: "ST4TreeNode"; id: string }
                | null
            }>
            lexiconEntries?: Array<{
              __typename: "NodeRef"
              id: string
              target?:
                | { __typename: "CommentNode"; id: string }
                | { __typename: "ExternalSite"; id: string }
                | { __typename: "ST4Class"; id: string }
                | {
                    __typename: "ST4Node"
                    aspectId: any
                    label?: string | null
                    id: string
                    content?:
                      | { __typename: "LexiconContent" }
                      | {
                          __typename: "LexiconEntryContent"
                          descriptionXast?: string | null
                          subject?: string | null
                          sortKey?: string | null
                          lexicon:
                            | { __typename: "CommentNode"; id: string }
                            | { __typename: "ExternalSite"; id: string }
                            | { __typename: "ST4Class"; id: string }
                            | { __typename: "ST4Node"; label?: string | null; id: string }
                            | { __typename: "ST4TreeNode"; id: string }
                          terms: Array<
                            | { __typename: "CommentNode"; id: string }
                            | { __typename: "ExternalSite"; id: string }
                            | { __typename: "ST4Class"; id: string }
                            | {
                                __typename: "ST4Node"
                                id: string
                                label?: string | null
                                aspectId: any
                                content?:
                                  | { __typename: "LexiconContent" }
                                  | { __typename: "LexiconEntryContent" }
                                  | { __typename: "MediaContent" }
                                  | { __typename: "MediaGroupContent" }
                                  | { __typename: "ProjectContent" }
                                  | {
                                      __typename: "TermContent"
                                      gramaticalGender?: string | null
                                      validity?: string | null
                                      editLanguage?: string | null
                                    }
                                  | { __typename: "TextContent" }
                                  | { __typename: "TextGroupContent" }
                                  | { __typename: "VariableTableContent" }
                                  | null
                              }
                            | { __typename: "ST4TreeNode"; id: string }
                          >
                          image?:
                            | { __typename: "CommentNode"; id: string }
                            | { __typename: "ExternalSite"; id: string }
                            | { __typename: "ST4Class"; id: string }
                            | {
                                __typename: "ST4Node"
                                aspectId: any
                                id: string
                                content?:
                                  | { __typename: "LexiconContent" }
                                  | { __typename: "LexiconEntryContent" }
                                  | {
                                      __typename: "MediaContent"
                                      media?:
                                        | {
                                            __typename: "AnimationMediaInfo"
                                            thumbnail?: string | null
                                            mediaType?: Types.MediaType | null
                                          }
                                        | {
                                            __typename: "ImageMediaInfo"
                                            src?: string | null
                                            thumbnail?: string | null
                                            mediaType?: Types.MediaType | null
                                          }
                                        | { __typename: "NoMediaInfo" }
                                        | {
                                            __typename: "VectorGraphicsMediaInfo"
                                            svg?: string | null
                                            thumbnail?: string | null
                                            mediaType?: Types.MediaType | null
                                          }
                                        | {
                                            __typename: "VideoMediaInfo"
                                            thumbnail?: string | null
                                            mediaType?: Types.MediaType | null
                                          }
                                        | null
                                    }
                                  | { __typename: "MediaGroupContent" }
                                  | { __typename: "ProjectContent" }
                                  | { __typename: "TermContent" }
                                  | { __typename: "TextContent" }
                                  | { __typename: "TextGroupContent" }
                                  | { __typename: "VariableTableContent" }
                                  | null
                              }
                            | { __typename: "ST4TreeNode"; id: string }
                            | null
                        }
                      | { __typename: "MediaContent" }
                      | { __typename: "MediaGroupContent" }
                      | { __typename: "ProjectContent" }
                      | { __typename: "TermContent" }
                      | { __typename: "TextContent" }
                      | { __typename: "TextGroupContent" }
                      | { __typename: "VariableTableContent" }
                      | null
                  }
                | { __typename: "ST4TreeNode"; id: string }
                | null
            } | null> | null
            comments: Array<{
              __typename: "CommentNode"
              id: string
              comment:
                | {
                    __typename: "BlockComment"
                    location: number
                    value?: string | null
                    authorDisplayName?: string | null
                    authorUserName?: string | null
                    commentKey: string
                    editDate?: any | null
                    modificationVersion?: number | null
                    replyToKey?: string | null
                    sortOrder?: number | null
                    commentType?: string | null
                    state?: string | null
                    color: string
                    persistanceState?: Types.PersistanceState | null
                    persistanceAction?: Types.PersistanceAction | null
                    node: {
                      __typename: "ST4Node"
                      aspectId: any
                      id: string
                      content?:
                        | { __typename: "LexiconContent" }
                        | { __typename: "LexiconEntryContent" }
                        | { __typename: "MediaContent" }
                        | { __typename: "MediaGroupContent" }
                        | { __typename: "ProjectContent" }
                        | { __typename: "TermContent" }
                        | { __typename: "TextContent"; id: string; historyNumber: number }
                        | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                        | { __typename: "VariableTableContent" }
                        | null
                    }
                    actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
                  }
                | {
                    __typename: "TextComment"
                    endLocation: number
                    startLocation?: number | null
                    value?: string | null
                    authorDisplayName?: string | null
                    authorUserName?: string | null
                    commentKey: string
                    editDate?: any | null
                    modificationVersion?: number | null
                    replyToKey?: string | null
                    sortOrder?: number | null
                    commentType?: string | null
                    state?: string | null
                    color: string
                    persistanceState?: Types.PersistanceState | null
                    persistanceAction?: Types.PersistanceAction | null
                    node: {
                      __typename: "ST4Node"
                      aspectId: any
                      id: string
                      content?:
                        | { __typename: "LexiconContent" }
                        | { __typename: "LexiconEntryContent" }
                        | { __typename: "MediaContent" }
                        | { __typename: "MediaGroupContent" }
                        | { __typename: "ProjectContent" }
                        | { __typename: "TermContent" }
                        | { __typename: "TextContent"; id: string; historyNumber: number }
                        | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                        | { __typename: "VariableTableContent" }
                        | null
                    }
                    actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
                  }
            }>
          }
        | {
            __typename: "TextGroupContent"
            currentNodeId?: any | null
            groupNodeId: any
            id: string
            xast?: string | null
            historyNumber: number
            children: Array<{
              __typename: "NodeRef"
              id: string
              target?:
                | { __typename: "CommentNode"; id: string }
                | { __typename: "ExternalSite"; id: string }
                | { __typename: "ST4Class"; id: string }
                | { __typename: "ST4Node"; label?: string | null; aspectId: any; id: string }
                | { __typename: "ST4TreeNode"; id: string }
                | null
            }>
            links?: Array<{
              __typename: "TextContentLink"
              ref?: string | null
              node?:
                | { __typename: "CommentNode"; id: string }
                | { __typename: "ExternalSite"; id: string }
                | { __typename: "ST4Class"; id: string }
                | {
                    __typename: "ST4Node"
                    id: string
                    aspectId: any
                    label?: string | null
                    properties?: {
                      __typename: "ContentPropertyConnection"
                      totalCount: number
                      edges?: Array<{
                        __typename: "ContentPropertyEdge"
                        node?: {
                          __typename: "ContentProperty"
                          typeOfValue: Types.ContentPropertyValueType
                          name: string
                          value?: any | null
                          displayName: string
                        } | null
                      } | null> | null
                    } | null
                  }
                | { __typename: "ST4TreeNode"; id: string }
                | null
            } | null> | null
            images: Array<{
              __typename: "NodeRef"
              id: string
              target?:
                | { __typename: "CommentNode"; id: string }
                | { __typename: "ExternalSite"; id: string }
                | { __typename: "ST4Class"; id: string }
                | {
                    __typename: "ST4Node"
                    aspectId: any
                    id: string
                    releaseState: {
                      __typename: "ReleaseStateValue"
                      displayName: string
                      state: Types.ReleaseState
                      isImplicitlyReleased: boolean
                    }
                    content?:
                      | { __typename: "LexiconContent" }
                      | { __typename: "LexiconEntryContent" }
                      | {
                          __typename: "MediaContent"
                          media?:
                            | {
                                __typename: "AnimationMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                mediaType?: Types.MediaType | null
                              }
                            | {
                                __typename: "ImageMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                resolution?: number | null
                                colorDepth?: number | null
                                size?: number | null
                                mime?: string | null
                                extension?: string | null
                                allowCaching?: boolean | null
                                mediaType?: Types.MediaType | null
                                src?: string | null
                                language?: string | null
                              }
                            | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                            | {
                                __typename: "VectorGraphicsMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                allowCaching?: boolean | null
                                mediaType?: Types.MediaType | null
                                language?: string | null
                                labels: Array<{
                                  __typename: "VectorGraphicsLabel"
                                  id?: string | null
                                  sequence?: number | null
                                  autoValue?: string | null
                                  text?: string | null
                                  linkType?: Types.VectorGraphicsLabelLinkType | null
                                  link?: string | null
                                  ref?:
                                    | {
                                        __typename: "GenericRef"
                                        id: string
                                        owner?:
                                          | { __typename: "CommentNode" }
                                          | { __typename: "ExternalSite" }
                                          | { __typename: "ST4Class" }
                                          | { __typename: "ST4Node"; id: string }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                        target?:
                                          | { __typename: "CommentNode" }
                                          | {
                                              __typename: "ExternalSite"
                                              id: string
                                              teaserData?: {
                                                __typename: "ExternalLinkTeaserData"
                                                title?: string | null
                                                summary?: string | null
                                                iconUrl?: string | null
                                                imageUrl?: string | null
                                                status?: Types.LinkTargetStatus | null
                                              } | null
                                            }
                                          | { __typename: "ST4Class" }
                                          | {
                                              __typename: "ST4Node"
                                              id: string
                                              aspectId: any
                                              label?: string | null
                                              properties?: {
                                                __typename: "ContentPropertyConnection"
                                                edges?: Array<{
                                                  __typename: "ContentPropertyEdge"
                                                  node?: {
                                                    __typename: "ContentProperty"
                                                    name: string
                                                    value?: any | null
                                                    displayName: string
                                                  } | null
                                                } | null> | null
                                              } | null
                                            }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                      }
                                    | {
                                        __typename: "NodeRef"
                                        id: string
                                        owner?:
                                          | { __typename: "CommentNode" }
                                          | { __typename: "ExternalSite" }
                                          | { __typename: "ST4Class" }
                                          | { __typename: "ST4Node"; id: string }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                        target?:
                                          | { __typename: "CommentNode" }
                                          | {
                                              __typename: "ExternalSite"
                                              id: string
                                              teaserData?: {
                                                __typename: "ExternalLinkTeaserData"
                                                title?: string | null
                                                summary?: string | null
                                                iconUrl?: string | null
                                                imageUrl?: string | null
                                                status?: Types.LinkTargetStatus | null
                                              } | null
                                            }
                                          | { __typename: "ST4Class" }
                                          | {
                                              __typename: "ST4Node"
                                              id: string
                                              aspectId: any
                                              label?: string | null
                                              properties?: {
                                                __typename: "ContentPropertyConnection"
                                                edges?: Array<{
                                                  __typename: "ContentPropertyEdge"
                                                  node?: {
                                                    __typename: "ContentProperty"
                                                    name: string
                                                    value?: any | null
                                                    displayName: string
                                                  } | null
                                                } | null> | null
                                              } | null
                                            }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                      }
                                    | null
                                }>
                              }
                            | {
                                __typename: "VideoMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                mediaType?: Types.MediaType | null
                              }
                            | null
                        }
                      | {
                          __typename: "MediaGroupContent"
                          children: Array<{
                            __typename: "NodeRef"
                            id: string
                            target?:
                              | { __typename: "CommentNode"; id: string }
                              | { __typename: "ExternalSite"; id: string }
                              | { __typename: "ST4Class"; id: string }
                              | {
                                  __typename: "ST4Node"
                                  id: string
                                  aspectId: any
                                  content?:
                                    | { __typename: "LexiconContent" }
                                    | { __typename: "LexiconEntryContent" }
                                    | {
                                        __typename: "MediaContent"
                                        media?:
                                          | {
                                              __typename: "AnimationMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              mediaType?: Types.MediaType | null
                                            }
                                          | {
                                              __typename: "ImageMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              resolution?: number | null
                                              colorDepth?: number | null
                                              size?: number | null
                                              mime?: string | null
                                              extension?: string | null
                                              allowCaching?: boolean | null
                                              mediaType?: Types.MediaType | null
                                              src?: string | null
                                              language?: string | null
                                            }
                                          | {
                                              __typename: "NoMediaInfo"
                                              id: string
                                              mediaType?: Types.MediaType | null
                                            }
                                          | {
                                              __typename: "VectorGraphicsMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              allowCaching?: boolean | null
                                              mediaType?: Types.MediaType | null
                                              language?: string | null
                                              labels: Array<{
                                                __typename: "VectorGraphicsLabel"
                                                id?: string | null
                                                sequence?: number | null
                                                autoValue?: string | null
                                                text?: string | null
                                                linkType?: Types.VectorGraphicsLabelLinkType | null
                                                link?: string | null
                                                ref?:
                                                  | {
                                                      __typename: "GenericRef"
                                                      id: string
                                                      owner?:
                                                        | { __typename: "CommentNode" }
                                                        | { __typename: "ExternalSite" }
                                                        | { __typename: "ST4Class" }
                                                        | { __typename: "ST4Node"; id: string }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                      target?:
                                                        | { __typename: "CommentNode" }
                                                        | {
                                                            __typename: "ExternalSite"
                                                            id: string
                                                            teaserData?: {
                                                              __typename: "ExternalLinkTeaserData"
                                                              title?: string | null
                                                              summary?: string | null
                                                              iconUrl?: string | null
                                                              imageUrl?: string | null
                                                              status?: Types.LinkTargetStatus | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4Class" }
                                                        | {
                                                            __typename: "ST4Node"
                                                            id: string
                                                            aspectId: any
                                                            label?: string | null
                                                            properties?: {
                                                              __typename: "ContentPropertyConnection"
                                                              edges?: Array<{
                                                                __typename: "ContentPropertyEdge"
                                                                node?: {
                                                                  __typename: "ContentProperty"
                                                                  name: string
                                                                  value?: any | null
                                                                  displayName: string
                                                                } | null
                                                              } | null> | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                    }
                                                  | {
                                                      __typename: "NodeRef"
                                                      id: string
                                                      owner?:
                                                        | { __typename: "CommentNode" }
                                                        | { __typename: "ExternalSite" }
                                                        | { __typename: "ST4Class" }
                                                        | { __typename: "ST4Node"; id: string }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                      target?:
                                                        | { __typename: "CommentNode" }
                                                        | {
                                                            __typename: "ExternalSite"
                                                            id: string
                                                            teaserData?: {
                                                              __typename: "ExternalLinkTeaserData"
                                                              title?: string | null
                                                              summary?: string | null
                                                              iconUrl?: string | null
                                                              imageUrl?: string | null
                                                              status?: Types.LinkTargetStatus | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4Class" }
                                                        | {
                                                            __typename: "ST4Node"
                                                            id: string
                                                            aspectId: any
                                                            label?: string | null
                                                            properties?: {
                                                              __typename: "ContentPropertyConnection"
                                                              edges?: Array<{
                                                                __typename: "ContentPropertyEdge"
                                                                node?: {
                                                                  __typename: "ContentProperty"
                                                                  name: string
                                                                  value?: any | null
                                                                  displayName: string
                                                                } | null
                                                              } | null> | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                    }
                                                  | null
                                              }>
                                            }
                                          | {
                                              __typename: "VideoMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              mediaType?: Types.MediaType | null
                                            }
                                          | null
                                      }
                                    | {
                                        __typename: "MediaGroupContent"
                                        media?:
                                          | {
                                              __typename: "AnimationMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              mediaType?: Types.MediaType | null
                                            }
                                          | {
                                              __typename: "ImageMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              resolution?: number | null
                                              colorDepth?: number | null
                                              size?: number | null
                                              mime?: string | null
                                              extension?: string | null
                                              allowCaching?: boolean | null
                                              mediaType?: Types.MediaType | null
                                              src?: string | null
                                              language?: string | null
                                            }
                                          | {
                                              __typename: "NoMediaInfo"
                                              id: string
                                              mediaType?: Types.MediaType | null
                                            }
                                          | {
                                              __typename: "VectorGraphicsMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              allowCaching?: boolean | null
                                              mediaType?: Types.MediaType | null
                                              language?: string | null
                                              labels: Array<{
                                                __typename: "VectorGraphicsLabel"
                                                id?: string | null
                                                sequence?: number | null
                                                autoValue?: string | null
                                                text?: string | null
                                                linkType?: Types.VectorGraphicsLabelLinkType | null
                                                link?: string | null
                                                ref?:
                                                  | {
                                                      __typename: "GenericRef"
                                                      id: string
                                                      owner?:
                                                        | { __typename: "CommentNode" }
                                                        | { __typename: "ExternalSite" }
                                                        | { __typename: "ST4Class" }
                                                        | { __typename: "ST4Node"; id: string }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                      target?:
                                                        | { __typename: "CommentNode" }
                                                        | {
                                                            __typename: "ExternalSite"
                                                            id: string
                                                            teaserData?: {
                                                              __typename: "ExternalLinkTeaserData"
                                                              title?: string | null
                                                              summary?: string | null
                                                              iconUrl?: string | null
                                                              imageUrl?: string | null
                                                              status?: Types.LinkTargetStatus | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4Class" }
                                                        | {
                                                            __typename: "ST4Node"
                                                            id: string
                                                            aspectId: any
                                                            label?: string | null
                                                            properties?: {
                                                              __typename: "ContentPropertyConnection"
                                                              edges?: Array<{
                                                                __typename: "ContentPropertyEdge"
                                                                node?: {
                                                                  __typename: "ContentProperty"
                                                                  name: string
                                                                  value?: any | null
                                                                  displayName: string
                                                                } | null
                                                              } | null> | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                    }
                                                  | {
                                                      __typename: "NodeRef"
                                                      id: string
                                                      owner?:
                                                        | { __typename: "CommentNode" }
                                                        | { __typename: "ExternalSite" }
                                                        | { __typename: "ST4Class" }
                                                        | { __typename: "ST4Node"; id: string }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                      target?:
                                                        | { __typename: "CommentNode" }
                                                        | {
                                                            __typename: "ExternalSite"
                                                            id: string
                                                            teaserData?: {
                                                              __typename: "ExternalLinkTeaserData"
                                                              title?: string | null
                                                              summary?: string | null
                                                              iconUrl?: string | null
                                                              imageUrl?: string | null
                                                              status?: Types.LinkTargetStatus | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4Class" }
                                                        | {
                                                            __typename: "ST4Node"
                                                            id: string
                                                            aspectId: any
                                                            label?: string | null
                                                            properties?: {
                                                              __typename: "ContentPropertyConnection"
                                                              edges?: Array<{
                                                                __typename: "ContentPropertyEdge"
                                                                node?: {
                                                                  __typename: "ContentProperty"
                                                                  name: string
                                                                  value?: any | null
                                                                  displayName: string
                                                                } | null
                                                              } | null> | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                    }
                                                  | null
                                              }>
                                            }
                                          | {
                                              __typename: "VideoMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              mediaType?: Types.MediaType | null
                                            }
                                          | null
                                      }
                                    | { __typename: "ProjectContent" }
                                    | { __typename: "TermContent" }
                                    | { __typename: "TextContent" }
                                    | { __typename: "TextGroupContent" }
                                    | { __typename: "VariableTableContent" }
                                    | null
                                }
                              | { __typename: "ST4TreeNode"; id: string }
                              | null
                          }>
                          media?:
                            | {
                                __typename: "AnimationMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                mediaType?: Types.MediaType | null
                              }
                            | {
                                __typename: "ImageMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                resolution?: number | null
                                colorDepth?: number | null
                                size?: number | null
                                mime?: string | null
                                extension?: string | null
                                allowCaching?: boolean | null
                                mediaType?: Types.MediaType | null
                                src?: string | null
                                language?: string | null
                              }
                            | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                            | {
                                __typename: "VectorGraphicsMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                allowCaching?: boolean | null
                                mediaType?: Types.MediaType | null
                                language?: string | null
                                labels: Array<{
                                  __typename: "VectorGraphicsLabel"
                                  id?: string | null
                                  sequence?: number | null
                                  autoValue?: string | null
                                  text?: string | null
                                  linkType?: Types.VectorGraphicsLabelLinkType | null
                                  link?: string | null
                                  ref?:
                                    | {
                                        __typename: "GenericRef"
                                        id: string
                                        owner?:
                                          | { __typename: "CommentNode" }
                                          | { __typename: "ExternalSite" }
                                          | { __typename: "ST4Class" }
                                          | { __typename: "ST4Node"; id: string }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                        target?:
                                          | { __typename: "CommentNode" }
                                          | {
                                              __typename: "ExternalSite"
                                              id: string
                                              teaserData?: {
                                                __typename: "ExternalLinkTeaserData"
                                                title?: string | null
                                                summary?: string | null
                                                iconUrl?: string | null
                                                imageUrl?: string | null
                                                status?: Types.LinkTargetStatus | null
                                              } | null
                                            }
                                          | { __typename: "ST4Class" }
                                          | {
                                              __typename: "ST4Node"
                                              id: string
                                              aspectId: any
                                              label?: string | null
                                              properties?: {
                                                __typename: "ContentPropertyConnection"
                                                edges?: Array<{
                                                  __typename: "ContentPropertyEdge"
                                                  node?: {
                                                    __typename: "ContentProperty"
                                                    name: string
                                                    value?: any | null
                                                    displayName: string
                                                  } | null
                                                } | null> | null
                                              } | null
                                            }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                      }
                                    | {
                                        __typename: "NodeRef"
                                        id: string
                                        owner?:
                                          | { __typename: "CommentNode" }
                                          | { __typename: "ExternalSite" }
                                          | { __typename: "ST4Class" }
                                          | { __typename: "ST4Node"; id: string }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                        target?:
                                          | { __typename: "CommentNode" }
                                          | {
                                              __typename: "ExternalSite"
                                              id: string
                                              teaserData?: {
                                                __typename: "ExternalLinkTeaserData"
                                                title?: string | null
                                                summary?: string | null
                                                iconUrl?: string | null
                                                imageUrl?: string | null
                                                status?: Types.LinkTargetStatus | null
                                              } | null
                                            }
                                          | { __typename: "ST4Class" }
                                          | {
                                              __typename: "ST4Node"
                                              id: string
                                              aspectId: any
                                              label?: string | null
                                              properties?: {
                                                __typename: "ContentPropertyConnection"
                                                edges?: Array<{
                                                  __typename: "ContentPropertyEdge"
                                                  node?: {
                                                    __typename: "ContentProperty"
                                                    name: string
                                                    value?: any | null
                                                    displayName: string
                                                  } | null
                                                } | null> | null
                                              } | null
                                            }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                      }
                                    | null
                                }>
                              }
                            | {
                                __typename: "VideoMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                mediaType?: Types.MediaType | null
                              }
                            | null
                        }
                      | { __typename: "ProjectContent" }
                      | { __typename: "TermContent" }
                      | { __typename: "TextContent" }
                      | { __typename: "TextGroupContent" }
                      | { __typename: "VariableTableContent" }
                      | null
                  }
                | { __typename: "ST4TreeNode"; id: string }
                | null
            }>
            variableTables?: Array<{
              __typename: "VariableTableReference"
              ref?: string | null
              node?:
                | { __typename: "CommentNode" }
                | { __typename: "ExternalSite" }
                | { __typename: "ST4Class" }
                | {
                    __typename: "ST4Node"
                    id: string
                    label?: string | null
                    aspectId: any
                    content?:
                      | { __typename: "LexiconContent" }
                      | { __typename: "LexiconEntryContent" }
                      | { __typename: "MediaContent" }
                      | { __typename: "MediaGroupContent" }
                      | { __typename: "ProjectContent" }
                      | { __typename: "TermContent" }
                      | { __typename: "TextContent" }
                      | { __typename: "TextGroupContent" }
                      | {
                          __typename: "VariableTableContent"
                          xml?: string | null
                          valueSets: Array<{ __typename: "ValueSet"; name: string; valueSetId: string }>
                          variables: Array<{
                            __typename: "Variable"
                            name: string
                            variableId: string
                            values: Array<string>
                          }>
                        }
                      | null
                  }
                | { __typename: "ST4TreeNode" }
                | null
            } | null> | null
            linkedDataNodes: Array<{
              __typename: "NodeRef"
              id: string
              target?:
                | { __typename: "CommentNode"; id: string }
                | { __typename: "ExternalSite"; id: string }
                | { __typename: "ST4Class"; id: string }
                | {
                    __typename: "ST4Node"
                    label?: string | null
                    id: string
                    metadata: Array<{
                      __typename: "MetaDataEntry"
                      label?: string | null
                      systemName: string
                      value?: string | null
                      values: Array<string>
                      users: Array<string>
                    }>
                    linkedOntologyNodes: Array<
                      | {
                          __typename: "GenericRef"
                          target?:
                            | { __typename: "CommentNode" }
                            | { __typename: "ExternalSite" }
                            | { __typename: "ST4Class" }
                            | { __typename: "ST4Node"; id: string; label?: string | null }
                            | { __typename: "ST4TreeNode" }
                            | null
                        }
                      | {
                          __typename: "NodeRef"
                          linkClassName: string
                          class?: { __typename: "ST4Class"; label?: string | null } | null
                          target?:
                            | { __typename: "CommentNode" }
                            | { __typename: "ExternalSite" }
                            | { __typename: "ST4Class" }
                            | { __typename: "ST4Node"; id: string; label?: string | null }
                            | { __typename: "ST4TreeNode" }
                            | null
                        }
                    >
                  }
                | { __typename: "ST4TreeNode"; id: string }
                | null
            }>
            lexiconEntries?: Array<{
              __typename: "NodeRef"
              id: string
              target?:
                | { __typename: "CommentNode"; id: string }
                | { __typename: "ExternalSite"; id: string }
                | { __typename: "ST4Class"; id: string }
                | {
                    __typename: "ST4Node"
                    aspectId: any
                    label?: string | null
                    id: string
                    content?:
                      | { __typename: "LexiconContent" }
                      | {
                          __typename: "LexiconEntryContent"
                          descriptionXast?: string | null
                          subject?: string | null
                          sortKey?: string | null
                          lexicon:
                            | { __typename: "CommentNode"; id: string }
                            | { __typename: "ExternalSite"; id: string }
                            | { __typename: "ST4Class"; id: string }
                            | { __typename: "ST4Node"; label?: string | null; id: string }
                            | { __typename: "ST4TreeNode"; id: string }
                          terms: Array<
                            | { __typename: "CommentNode"; id: string }
                            | { __typename: "ExternalSite"; id: string }
                            | { __typename: "ST4Class"; id: string }
                            | {
                                __typename: "ST4Node"
                                id: string
                                label?: string | null
                                aspectId: any
                                content?:
                                  | { __typename: "LexiconContent" }
                                  | { __typename: "LexiconEntryContent" }
                                  | { __typename: "MediaContent" }
                                  | { __typename: "MediaGroupContent" }
                                  | { __typename: "ProjectContent" }
                                  | {
                                      __typename: "TermContent"
                                      gramaticalGender?: string | null
                                      validity?: string | null
                                      editLanguage?: string | null
                                    }
                                  | { __typename: "TextContent" }
                                  | { __typename: "TextGroupContent" }
                                  | { __typename: "VariableTableContent" }
                                  | null
                              }
                            | { __typename: "ST4TreeNode"; id: string }
                          >
                          image?:
                            | { __typename: "CommentNode"; id: string }
                            | { __typename: "ExternalSite"; id: string }
                            | { __typename: "ST4Class"; id: string }
                            | {
                                __typename: "ST4Node"
                                aspectId: any
                                id: string
                                content?:
                                  | { __typename: "LexiconContent" }
                                  | { __typename: "LexiconEntryContent" }
                                  | {
                                      __typename: "MediaContent"
                                      media?:
                                        | {
                                            __typename: "AnimationMediaInfo"
                                            thumbnail?: string | null
                                            mediaType?: Types.MediaType | null
                                          }
                                        | {
                                            __typename: "ImageMediaInfo"
                                            src?: string | null
                                            thumbnail?: string | null
                                            mediaType?: Types.MediaType | null
                                          }
                                        | { __typename: "NoMediaInfo" }
                                        | {
                                            __typename: "VectorGraphicsMediaInfo"
                                            svg?: string | null
                                            thumbnail?: string | null
                                            mediaType?: Types.MediaType | null
                                          }
                                        | {
                                            __typename: "VideoMediaInfo"
                                            thumbnail?: string | null
                                            mediaType?: Types.MediaType | null
                                          }
                                        | null
                                    }
                                  | { __typename: "MediaGroupContent" }
                                  | { __typename: "ProjectContent" }
                                  | { __typename: "TermContent" }
                                  | { __typename: "TextContent" }
                                  | { __typename: "TextGroupContent" }
                                  | { __typename: "VariableTableContent" }
                                  | null
                              }
                            | { __typename: "ST4TreeNode"; id: string }
                            | null
                        }
                      | { __typename: "MediaContent" }
                      | { __typename: "MediaGroupContent" }
                      | { __typename: "ProjectContent" }
                      | { __typename: "TermContent" }
                      | { __typename: "TextContent" }
                      | { __typename: "TextGroupContent" }
                      | { __typename: "VariableTableContent" }
                      | null
                  }
                | { __typename: "ST4TreeNode"; id: string }
                | null
            } | null> | null
            comments: Array<{
              __typename: "CommentNode"
              id: string
              comment:
                | {
                    __typename: "BlockComment"
                    location: number
                    value?: string | null
                    authorDisplayName?: string | null
                    authorUserName?: string | null
                    commentKey: string
                    editDate?: any | null
                    modificationVersion?: number | null
                    replyToKey?: string | null
                    sortOrder?: number | null
                    commentType?: string | null
                    state?: string | null
                    color: string
                    persistanceState?: Types.PersistanceState | null
                    persistanceAction?: Types.PersistanceAction | null
                    node: {
                      __typename: "ST4Node"
                      aspectId: any
                      id: string
                      content?:
                        | { __typename: "LexiconContent" }
                        | { __typename: "LexiconEntryContent" }
                        | { __typename: "MediaContent" }
                        | { __typename: "MediaGroupContent" }
                        | { __typename: "ProjectContent" }
                        | { __typename: "TermContent" }
                        | { __typename: "TextContent"; id: string; historyNumber: number }
                        | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                        | { __typename: "VariableTableContent" }
                        | null
                    }
                    actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
                  }
                | {
                    __typename: "TextComment"
                    endLocation: number
                    startLocation?: number | null
                    value?: string | null
                    authorDisplayName?: string | null
                    authorUserName?: string | null
                    commentKey: string
                    editDate?: any | null
                    modificationVersion?: number | null
                    replyToKey?: string | null
                    sortOrder?: number | null
                    commentType?: string | null
                    state?: string | null
                    color: string
                    persistanceState?: Types.PersistanceState | null
                    persistanceAction?: Types.PersistanceAction | null
                    node: {
                      __typename: "ST4Node"
                      aspectId: any
                      id: string
                      content?:
                        | { __typename: "LexiconContent" }
                        | { __typename: "LexiconEntryContent" }
                        | { __typename: "MediaContent" }
                        | { __typename: "MediaGroupContent" }
                        | { __typename: "ProjectContent" }
                        | { __typename: "TermContent" }
                        | { __typename: "TextContent"; id: string; historyNumber: number }
                        | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                        | { __typename: "VariableTableContent" }
                        | null
                    }
                    actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
                  }
            }>
          }
        | { __typename: "VariableTableContent" }
        | null
    }
  }>
  children: Array<{
    __typename: "NodeRef"
    id: string
    target?:
      | { __typename: "CommentNode"; id: string }
      | { __typename: "ExternalSite"; id: string }
      | { __typename: "ST4Class"; id: string }
      | { __typename: "ST4Node"; label?: string | null; aspectId: any; id: string }
      | { __typename: "ST4TreeNode"; id: string }
      | null
  }>
  links?: Array<{
    __typename: "TextContentLink"
    ref?: string | null
    node?:
      | { __typename: "CommentNode"; id: string }
      | { __typename: "ExternalSite"; id: string }
      | { __typename: "ST4Class"; id: string }
      | {
          __typename: "ST4Node"
          id: string
          aspectId: any
          label?: string | null
          properties?: {
            __typename: "ContentPropertyConnection"
            totalCount: number
            edges?: Array<{
              __typename: "ContentPropertyEdge"
              node?: {
                __typename: "ContentProperty"
                typeOfValue: Types.ContentPropertyValueType
                name: string
                value?: any | null
                displayName: string
              } | null
            } | null> | null
          } | null
        }
      | { __typename: "ST4TreeNode"; id: string }
      | null
  } | null> | null
  images: Array<{
    __typename: "NodeRef"
    id: string
    target?:
      | { __typename: "CommentNode"; id: string }
      | { __typename: "ExternalSite"; id: string }
      | { __typename: "ST4Class"; id: string }
      | {
          __typename: "ST4Node"
          aspectId: any
          id: string
          releaseState: {
            __typename: "ReleaseStateValue"
            displayName: string
            state: Types.ReleaseState
            isImplicitlyReleased: boolean
          }
          content?:
            | { __typename: "LexiconContent" }
            | { __typename: "LexiconEntryContent" }
            | {
                __typename: "MediaContent"
                media?:
                  | {
                      __typename: "AnimationMediaInfo"
                      id: string
                      resourceId?: string | null
                      thumbnail?: string | null
                      width?: number | null
                      height?: number | null
                      mediaType?: Types.MediaType | null
                    }
                  | {
                      __typename: "ImageMediaInfo"
                      id: string
                      resourceId?: string | null
                      thumbnail?: string | null
                      width?: number | null
                      height?: number | null
                      resolution?: number | null
                      colorDepth?: number | null
                      size?: number | null
                      mime?: string | null
                      extension?: string | null
                      allowCaching?: boolean | null
                      mediaType?: Types.MediaType | null
                      src?: string | null
                      language?: string | null
                    }
                  | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                  | {
                      __typename: "VectorGraphicsMediaInfo"
                      id: string
                      resourceId?: string | null
                      thumbnail?: string | null
                      width?: number | null
                      height?: number | null
                      allowCaching?: boolean | null
                      mediaType?: Types.MediaType | null
                      language?: string | null
                      labels: Array<{
                        __typename: "VectorGraphicsLabel"
                        id?: string | null
                        sequence?: number | null
                        autoValue?: string | null
                        text?: string | null
                        linkType?: Types.VectorGraphicsLabelLinkType | null
                        link?: string | null
                        ref?:
                          | {
                              __typename: "GenericRef"
                              id: string
                              owner?:
                                | { __typename: "CommentNode" }
                                | { __typename: "ExternalSite" }
                                | { __typename: "ST4Class" }
                                | { __typename: "ST4Node"; id: string }
                                | { __typename: "ST4TreeNode" }
                                | null
                              target?:
                                | { __typename: "CommentNode" }
                                | {
                                    __typename: "ExternalSite"
                                    id: string
                                    teaserData?: {
                                      __typename: "ExternalLinkTeaserData"
                                      title?: string | null
                                      summary?: string | null
                                      iconUrl?: string | null
                                      imageUrl?: string | null
                                      status?: Types.LinkTargetStatus | null
                                    } | null
                                  }
                                | { __typename: "ST4Class" }
                                | {
                                    __typename: "ST4Node"
                                    id: string
                                    aspectId: any
                                    label?: string | null
                                    properties?: {
                                      __typename: "ContentPropertyConnection"
                                      edges?: Array<{
                                        __typename: "ContentPropertyEdge"
                                        node?: {
                                          __typename: "ContentProperty"
                                          name: string
                                          value?: any | null
                                          displayName: string
                                        } | null
                                      } | null> | null
                                    } | null
                                  }
                                | { __typename: "ST4TreeNode" }
                                | null
                            }
                          | {
                              __typename: "NodeRef"
                              id: string
                              owner?:
                                | { __typename: "CommentNode" }
                                | { __typename: "ExternalSite" }
                                | { __typename: "ST4Class" }
                                | { __typename: "ST4Node"; id: string }
                                | { __typename: "ST4TreeNode" }
                                | null
                              target?:
                                | { __typename: "CommentNode" }
                                | {
                                    __typename: "ExternalSite"
                                    id: string
                                    teaserData?: {
                                      __typename: "ExternalLinkTeaserData"
                                      title?: string | null
                                      summary?: string | null
                                      iconUrl?: string | null
                                      imageUrl?: string | null
                                      status?: Types.LinkTargetStatus | null
                                    } | null
                                  }
                                | { __typename: "ST4Class" }
                                | {
                                    __typename: "ST4Node"
                                    id: string
                                    aspectId: any
                                    label?: string | null
                                    properties?: {
                                      __typename: "ContentPropertyConnection"
                                      edges?: Array<{
                                        __typename: "ContentPropertyEdge"
                                        node?: {
                                          __typename: "ContentProperty"
                                          name: string
                                          value?: any | null
                                          displayName: string
                                        } | null
                                      } | null> | null
                                    } | null
                                  }
                                | { __typename: "ST4TreeNode" }
                                | null
                            }
                          | null
                      }>
                    }
                  | {
                      __typename: "VideoMediaInfo"
                      id: string
                      resourceId?: string | null
                      thumbnail?: string | null
                      width?: number | null
                      height?: number | null
                      mediaType?: Types.MediaType | null
                    }
                  | null
              }
            | {
                __typename: "MediaGroupContent"
                children: Array<{
                  __typename: "NodeRef"
                  id: string
                  target?:
                    | { __typename: "CommentNode"; id: string }
                    | { __typename: "ExternalSite"; id: string }
                    | { __typename: "ST4Class"; id: string }
                    | {
                        __typename: "ST4Node"
                        id: string
                        aspectId: any
                        content?:
                          | { __typename: "LexiconContent" }
                          | { __typename: "LexiconEntryContent" }
                          | {
                              __typename: "MediaContent"
                              media?:
                                | {
                                    __typename: "AnimationMediaInfo"
                                    id: string
                                    resourceId?: string | null
                                    thumbnail?: string | null
                                    width?: number | null
                                    height?: number | null
                                    mediaType?: Types.MediaType | null
                                  }
                                | {
                                    __typename: "ImageMediaInfo"
                                    id: string
                                    resourceId?: string | null
                                    thumbnail?: string | null
                                    width?: number | null
                                    height?: number | null
                                    resolution?: number | null
                                    colorDepth?: number | null
                                    size?: number | null
                                    mime?: string | null
                                    extension?: string | null
                                    allowCaching?: boolean | null
                                    mediaType?: Types.MediaType | null
                                    src?: string | null
                                    language?: string | null
                                  }
                                | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                                | {
                                    __typename: "VectorGraphicsMediaInfo"
                                    id: string
                                    resourceId?: string | null
                                    thumbnail?: string | null
                                    width?: number | null
                                    height?: number | null
                                    allowCaching?: boolean | null
                                    mediaType?: Types.MediaType | null
                                    language?: string | null
                                    labels: Array<{
                                      __typename: "VectorGraphicsLabel"
                                      id?: string | null
                                      sequence?: number | null
                                      autoValue?: string | null
                                      text?: string | null
                                      linkType?: Types.VectorGraphicsLabelLinkType | null
                                      link?: string | null
                                      ref?:
                                        | {
                                            __typename: "GenericRef"
                                            id: string
                                            owner?:
                                              | { __typename: "CommentNode" }
                                              | { __typename: "ExternalSite" }
                                              | { __typename: "ST4Class" }
                                              | { __typename: "ST4Node"; id: string }
                                              | { __typename: "ST4TreeNode" }
                                              | null
                                            target?:
                                              | { __typename: "CommentNode" }
                                              | {
                                                  __typename: "ExternalSite"
                                                  id: string
                                                  teaserData?: {
                                                    __typename: "ExternalLinkTeaserData"
                                                    title?: string | null
                                                    summary?: string | null
                                                    iconUrl?: string | null
                                                    imageUrl?: string | null
                                                    status?: Types.LinkTargetStatus | null
                                                  } | null
                                                }
                                              | { __typename: "ST4Class" }
                                              | {
                                                  __typename: "ST4Node"
                                                  id: string
                                                  aspectId: any
                                                  label?: string | null
                                                  properties?: {
                                                    __typename: "ContentPropertyConnection"
                                                    edges?: Array<{
                                                      __typename: "ContentPropertyEdge"
                                                      node?: {
                                                        __typename: "ContentProperty"
                                                        name: string
                                                        value?: any | null
                                                        displayName: string
                                                      } | null
                                                    } | null> | null
                                                  } | null
                                                }
                                              | { __typename: "ST4TreeNode" }
                                              | null
                                          }
                                        | {
                                            __typename: "NodeRef"
                                            id: string
                                            owner?:
                                              | { __typename: "CommentNode" }
                                              | { __typename: "ExternalSite" }
                                              | { __typename: "ST4Class" }
                                              | { __typename: "ST4Node"; id: string }
                                              | { __typename: "ST4TreeNode" }
                                              | null
                                            target?:
                                              | { __typename: "CommentNode" }
                                              | {
                                                  __typename: "ExternalSite"
                                                  id: string
                                                  teaserData?: {
                                                    __typename: "ExternalLinkTeaserData"
                                                    title?: string | null
                                                    summary?: string | null
                                                    iconUrl?: string | null
                                                    imageUrl?: string | null
                                                    status?: Types.LinkTargetStatus | null
                                                  } | null
                                                }
                                              | { __typename: "ST4Class" }
                                              | {
                                                  __typename: "ST4Node"
                                                  id: string
                                                  aspectId: any
                                                  label?: string | null
                                                  properties?: {
                                                    __typename: "ContentPropertyConnection"
                                                    edges?: Array<{
                                                      __typename: "ContentPropertyEdge"
                                                      node?: {
                                                        __typename: "ContentProperty"
                                                        name: string
                                                        value?: any | null
                                                        displayName: string
                                                      } | null
                                                    } | null> | null
                                                  } | null
                                                }
                                              | { __typename: "ST4TreeNode" }
                                              | null
                                          }
                                        | null
                                    }>
                                  }
                                | {
                                    __typename: "VideoMediaInfo"
                                    id: string
                                    resourceId?: string | null
                                    thumbnail?: string | null
                                    width?: number | null
                                    height?: number | null
                                    mediaType?: Types.MediaType | null
                                  }
                                | null
                            }
                          | {
                              __typename: "MediaGroupContent"
                              media?:
                                | {
                                    __typename: "AnimationMediaInfo"
                                    id: string
                                    resourceId?: string | null
                                    thumbnail?: string | null
                                    width?: number | null
                                    height?: number | null
                                    mediaType?: Types.MediaType | null
                                  }
                                | {
                                    __typename: "ImageMediaInfo"
                                    id: string
                                    resourceId?: string | null
                                    thumbnail?: string | null
                                    width?: number | null
                                    height?: number | null
                                    resolution?: number | null
                                    colorDepth?: number | null
                                    size?: number | null
                                    mime?: string | null
                                    extension?: string | null
                                    allowCaching?: boolean | null
                                    mediaType?: Types.MediaType | null
                                    src?: string | null
                                    language?: string | null
                                  }
                                | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                                | {
                                    __typename: "VectorGraphicsMediaInfo"
                                    id: string
                                    resourceId?: string | null
                                    thumbnail?: string | null
                                    width?: number | null
                                    height?: number | null
                                    allowCaching?: boolean | null
                                    mediaType?: Types.MediaType | null
                                    language?: string | null
                                    labels: Array<{
                                      __typename: "VectorGraphicsLabel"
                                      id?: string | null
                                      sequence?: number | null
                                      autoValue?: string | null
                                      text?: string | null
                                      linkType?: Types.VectorGraphicsLabelLinkType | null
                                      link?: string | null
                                      ref?:
                                        | {
                                            __typename: "GenericRef"
                                            id: string
                                            owner?:
                                              | { __typename: "CommentNode" }
                                              | { __typename: "ExternalSite" }
                                              | { __typename: "ST4Class" }
                                              | { __typename: "ST4Node"; id: string }
                                              | { __typename: "ST4TreeNode" }
                                              | null
                                            target?:
                                              | { __typename: "CommentNode" }
                                              | {
                                                  __typename: "ExternalSite"
                                                  id: string
                                                  teaserData?: {
                                                    __typename: "ExternalLinkTeaserData"
                                                    title?: string | null
                                                    summary?: string | null
                                                    iconUrl?: string | null
                                                    imageUrl?: string | null
                                                    status?: Types.LinkTargetStatus | null
                                                  } | null
                                                }
                                              | { __typename: "ST4Class" }
                                              | {
                                                  __typename: "ST4Node"
                                                  id: string
                                                  aspectId: any
                                                  label?: string | null
                                                  properties?: {
                                                    __typename: "ContentPropertyConnection"
                                                    edges?: Array<{
                                                      __typename: "ContentPropertyEdge"
                                                      node?: {
                                                        __typename: "ContentProperty"
                                                        name: string
                                                        value?: any | null
                                                        displayName: string
                                                      } | null
                                                    } | null> | null
                                                  } | null
                                                }
                                              | { __typename: "ST4TreeNode" }
                                              | null
                                          }
                                        | {
                                            __typename: "NodeRef"
                                            id: string
                                            owner?:
                                              | { __typename: "CommentNode" }
                                              | { __typename: "ExternalSite" }
                                              | { __typename: "ST4Class" }
                                              | { __typename: "ST4Node"; id: string }
                                              | { __typename: "ST4TreeNode" }
                                              | null
                                            target?:
                                              | { __typename: "CommentNode" }
                                              | {
                                                  __typename: "ExternalSite"
                                                  id: string
                                                  teaserData?: {
                                                    __typename: "ExternalLinkTeaserData"
                                                    title?: string | null
                                                    summary?: string | null
                                                    iconUrl?: string | null
                                                    imageUrl?: string | null
                                                    status?: Types.LinkTargetStatus | null
                                                  } | null
                                                }
                                              | { __typename: "ST4Class" }
                                              | {
                                                  __typename: "ST4Node"
                                                  id: string
                                                  aspectId: any
                                                  label?: string | null
                                                  properties?: {
                                                    __typename: "ContentPropertyConnection"
                                                    edges?: Array<{
                                                      __typename: "ContentPropertyEdge"
                                                      node?: {
                                                        __typename: "ContentProperty"
                                                        name: string
                                                        value?: any | null
                                                        displayName: string
                                                      } | null
                                                    } | null> | null
                                                  } | null
                                                }
                                              | { __typename: "ST4TreeNode" }
                                              | null
                                          }
                                        | null
                                    }>
                                  }
                                | {
                                    __typename: "VideoMediaInfo"
                                    id: string
                                    resourceId?: string | null
                                    thumbnail?: string | null
                                    width?: number | null
                                    height?: number | null
                                    mediaType?: Types.MediaType | null
                                  }
                                | null
                            }
                          | { __typename: "ProjectContent" }
                          | { __typename: "TermContent" }
                          | { __typename: "TextContent" }
                          | { __typename: "TextGroupContent" }
                          | { __typename: "VariableTableContent" }
                          | null
                      }
                    | { __typename: "ST4TreeNode"; id: string }
                    | null
                }>
                media?:
                  | {
                      __typename: "AnimationMediaInfo"
                      id: string
                      resourceId?: string | null
                      thumbnail?: string | null
                      width?: number | null
                      height?: number | null
                      mediaType?: Types.MediaType | null
                    }
                  | {
                      __typename: "ImageMediaInfo"
                      id: string
                      resourceId?: string | null
                      thumbnail?: string | null
                      width?: number | null
                      height?: number | null
                      resolution?: number | null
                      colorDepth?: number | null
                      size?: number | null
                      mime?: string | null
                      extension?: string | null
                      allowCaching?: boolean | null
                      mediaType?: Types.MediaType | null
                      src?: string | null
                      language?: string | null
                    }
                  | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                  | {
                      __typename: "VectorGraphicsMediaInfo"
                      id: string
                      resourceId?: string | null
                      thumbnail?: string | null
                      width?: number | null
                      height?: number | null
                      allowCaching?: boolean | null
                      mediaType?: Types.MediaType | null
                      language?: string | null
                      labels: Array<{
                        __typename: "VectorGraphicsLabel"
                        id?: string | null
                        sequence?: number | null
                        autoValue?: string | null
                        text?: string | null
                        linkType?: Types.VectorGraphicsLabelLinkType | null
                        link?: string | null
                        ref?:
                          | {
                              __typename: "GenericRef"
                              id: string
                              owner?:
                                | { __typename: "CommentNode" }
                                | { __typename: "ExternalSite" }
                                | { __typename: "ST4Class" }
                                | { __typename: "ST4Node"; id: string }
                                | { __typename: "ST4TreeNode" }
                                | null
                              target?:
                                | { __typename: "CommentNode" }
                                | {
                                    __typename: "ExternalSite"
                                    id: string
                                    teaserData?: {
                                      __typename: "ExternalLinkTeaserData"
                                      title?: string | null
                                      summary?: string | null
                                      iconUrl?: string | null
                                      imageUrl?: string | null
                                      status?: Types.LinkTargetStatus | null
                                    } | null
                                  }
                                | { __typename: "ST4Class" }
                                | {
                                    __typename: "ST4Node"
                                    id: string
                                    aspectId: any
                                    label?: string | null
                                    properties?: {
                                      __typename: "ContentPropertyConnection"
                                      edges?: Array<{
                                        __typename: "ContentPropertyEdge"
                                        node?: {
                                          __typename: "ContentProperty"
                                          name: string
                                          value?: any | null
                                          displayName: string
                                        } | null
                                      } | null> | null
                                    } | null
                                  }
                                | { __typename: "ST4TreeNode" }
                                | null
                            }
                          | {
                              __typename: "NodeRef"
                              id: string
                              owner?:
                                | { __typename: "CommentNode" }
                                | { __typename: "ExternalSite" }
                                | { __typename: "ST4Class" }
                                | { __typename: "ST4Node"; id: string }
                                | { __typename: "ST4TreeNode" }
                                | null
                              target?:
                                | { __typename: "CommentNode" }
                                | {
                                    __typename: "ExternalSite"
                                    id: string
                                    teaserData?: {
                                      __typename: "ExternalLinkTeaserData"
                                      title?: string | null
                                      summary?: string | null
                                      iconUrl?: string | null
                                      imageUrl?: string | null
                                      status?: Types.LinkTargetStatus | null
                                    } | null
                                  }
                                | { __typename: "ST4Class" }
                                | {
                                    __typename: "ST4Node"
                                    id: string
                                    aspectId: any
                                    label?: string | null
                                    properties?: {
                                      __typename: "ContentPropertyConnection"
                                      edges?: Array<{
                                        __typename: "ContentPropertyEdge"
                                        node?: {
                                          __typename: "ContentProperty"
                                          name: string
                                          value?: any | null
                                          displayName: string
                                        } | null
                                      } | null> | null
                                    } | null
                                  }
                                | { __typename: "ST4TreeNode" }
                                | null
                            }
                          | null
                      }>
                    }
                  | {
                      __typename: "VideoMediaInfo"
                      id: string
                      resourceId?: string | null
                      thumbnail?: string | null
                      width?: number | null
                      height?: number | null
                      mediaType?: Types.MediaType | null
                    }
                  | null
              }
            | { __typename: "ProjectContent" }
            | { __typename: "TermContent" }
            | { __typename: "TextContent" }
            | { __typename: "TextGroupContent" }
            | { __typename: "VariableTableContent" }
            | null
        }
      | { __typename: "ST4TreeNode"; id: string }
      | null
  }>
  variableTables?: Array<{
    __typename: "VariableTableReference"
    ref?: string | null
    node?:
      | { __typename: "CommentNode" }
      | { __typename: "ExternalSite" }
      | { __typename: "ST4Class" }
      | {
          __typename: "ST4Node"
          id: string
          label?: string | null
          aspectId: any
          content?:
            | { __typename: "LexiconContent" }
            | { __typename: "LexiconEntryContent" }
            | { __typename: "MediaContent" }
            | { __typename: "MediaGroupContent" }
            | { __typename: "ProjectContent" }
            | { __typename: "TermContent" }
            | { __typename: "TextContent" }
            | { __typename: "TextGroupContent" }
            | {
                __typename: "VariableTableContent"
                xml?: string | null
                valueSets: Array<{ __typename: "ValueSet"; name: string; valueSetId: string }>
                variables: Array<{ __typename: "Variable"; name: string; variableId: string; values: Array<string> }>
              }
            | null
        }
      | { __typename: "ST4TreeNode" }
      | null
  } | null> | null
  linkedDataNodes: Array<{
    __typename: "NodeRef"
    id: string
    target?:
      | { __typename: "CommentNode"; id: string }
      | { __typename: "ExternalSite"; id: string }
      | { __typename: "ST4Class"; id: string }
      | {
          __typename: "ST4Node"
          label?: string | null
          id: string
          metadata: Array<{
            __typename: "MetaDataEntry"
            label?: string | null
            systemName: string
            value?: string | null
            values: Array<string>
            users: Array<string>
          }>
          linkedOntologyNodes: Array<
            | {
                __typename: "GenericRef"
                target?:
                  | { __typename: "CommentNode" }
                  | { __typename: "ExternalSite" }
                  | { __typename: "ST4Class" }
                  | { __typename: "ST4Node"; id: string; label?: string | null }
                  | { __typename: "ST4TreeNode" }
                  | null
              }
            | {
                __typename: "NodeRef"
                linkClassName: string
                class?: { __typename: "ST4Class"; label?: string | null } | null
                target?:
                  | { __typename: "CommentNode" }
                  | { __typename: "ExternalSite" }
                  | { __typename: "ST4Class" }
                  | { __typename: "ST4Node"; id: string; label?: string | null }
                  | { __typename: "ST4TreeNode" }
                  | null
              }
          >
        }
      | { __typename: "ST4TreeNode"; id: string }
      | null
  }>
  lexiconEntries?: Array<{
    __typename: "NodeRef"
    id: string
    target?:
      | { __typename: "CommentNode"; id: string }
      | { __typename: "ExternalSite"; id: string }
      | { __typename: "ST4Class"; id: string }
      | {
          __typename: "ST4Node"
          aspectId: any
          label?: string | null
          id: string
          content?:
            | { __typename: "LexiconContent" }
            | {
                __typename: "LexiconEntryContent"
                descriptionXast?: string | null
                subject?: string | null
                sortKey?: string | null
                lexicon:
                  | { __typename: "CommentNode"; id: string }
                  | { __typename: "ExternalSite"; id: string }
                  | { __typename: "ST4Class"; id: string }
                  | { __typename: "ST4Node"; label?: string | null; id: string }
                  | { __typename: "ST4TreeNode"; id: string }
                terms: Array<
                  | { __typename: "CommentNode"; id: string }
                  | { __typename: "ExternalSite"; id: string }
                  | { __typename: "ST4Class"; id: string }
                  | {
                      __typename: "ST4Node"
                      id: string
                      label?: string | null
                      aspectId: any
                      content?:
                        | { __typename: "LexiconContent" }
                        | { __typename: "LexiconEntryContent" }
                        | { __typename: "MediaContent" }
                        | { __typename: "MediaGroupContent" }
                        | { __typename: "ProjectContent" }
                        | {
                            __typename: "TermContent"
                            gramaticalGender?: string | null
                            validity?: string | null
                            editLanguage?: string | null
                          }
                        | { __typename: "TextContent" }
                        | { __typename: "TextGroupContent" }
                        | { __typename: "VariableTableContent" }
                        | null
                    }
                  | { __typename: "ST4TreeNode"; id: string }
                >
                image?:
                  | { __typename: "CommentNode"; id: string }
                  | { __typename: "ExternalSite"; id: string }
                  | { __typename: "ST4Class"; id: string }
                  | {
                      __typename: "ST4Node"
                      aspectId: any
                      id: string
                      content?:
                        | { __typename: "LexiconContent" }
                        | { __typename: "LexiconEntryContent" }
                        | {
                            __typename: "MediaContent"
                            media?:
                              | {
                                  __typename: "AnimationMediaInfo"
                                  thumbnail?: string | null
                                  mediaType?: Types.MediaType | null
                                }
                              | {
                                  __typename: "ImageMediaInfo"
                                  src?: string | null
                                  thumbnail?: string | null
                                  mediaType?: Types.MediaType | null
                                }
                              | { __typename: "NoMediaInfo" }
                              | {
                                  __typename: "VectorGraphicsMediaInfo"
                                  svg?: string | null
                                  thumbnail?: string | null
                                  mediaType?: Types.MediaType | null
                                }
                              | {
                                  __typename: "VideoMediaInfo"
                                  thumbnail?: string | null
                                  mediaType?: Types.MediaType | null
                                }
                              | null
                          }
                        | { __typename: "MediaGroupContent" }
                        | { __typename: "ProjectContent" }
                        | { __typename: "TermContent" }
                        | { __typename: "TextContent" }
                        | { __typename: "TextGroupContent" }
                        | { __typename: "VariableTableContent" }
                        | null
                    }
                  | { __typename: "ST4TreeNode"; id: string }
                  | null
              }
            | { __typename: "MediaContent" }
            | { __typename: "MediaGroupContent" }
            | { __typename: "ProjectContent" }
            | { __typename: "TermContent" }
            | { __typename: "TextContent" }
            | { __typename: "TextGroupContent" }
            | { __typename: "VariableTableContent" }
            | null
        }
      | { __typename: "ST4TreeNode"; id: string }
      | null
  } | null> | null
  comments: Array<{
    __typename: "CommentNode"
    id: string
    comment:
      | {
          __typename: "BlockComment"
          location: number
          value?: string | null
          authorDisplayName?: string | null
          authorUserName?: string | null
          commentKey: string
          editDate?: any | null
          modificationVersion?: number | null
          replyToKey?: string | null
          sortOrder?: number | null
          commentType?: string | null
          state?: string | null
          color: string
          persistanceState?: Types.PersistanceState | null
          persistanceAction?: Types.PersistanceAction | null
          node: {
            __typename: "ST4Node"
            aspectId: any
            id: string
            content?:
              | { __typename: "LexiconContent" }
              | { __typename: "LexiconEntryContent" }
              | { __typename: "MediaContent" }
              | { __typename: "MediaGroupContent" }
              | { __typename: "ProjectContent" }
              | { __typename: "TermContent" }
              | { __typename: "TextContent"; id: string; historyNumber: number }
              | { __typename: "TextGroupContent"; id: string; historyNumber: number }
              | { __typename: "VariableTableContent" }
              | null
          }
          actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
        }
      | {
          __typename: "TextComment"
          endLocation: number
          startLocation?: number | null
          value?: string | null
          authorDisplayName?: string | null
          authorUserName?: string | null
          commentKey: string
          editDate?: any | null
          modificationVersion?: number | null
          replyToKey?: string | null
          sortOrder?: number | null
          commentType?: string | null
          state?: string | null
          color: string
          persistanceState?: Types.PersistanceState | null
          persistanceAction?: Types.PersistanceAction | null
          node: {
            __typename: "ST4Node"
            aspectId: any
            id: string
            content?:
              | { __typename: "LexiconContent" }
              | { __typename: "LexiconEntryContent" }
              | { __typename: "MediaContent" }
              | { __typename: "MediaGroupContent" }
              | { __typename: "ProjectContent" }
              | { __typename: "TermContent" }
              | { __typename: "TextContent"; id: string; historyNumber: number }
              | { __typename: "TextGroupContent"; id: string; historyNumber: number }
              | { __typename: "VariableTableContent" }
              | null
          }
          actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
        }
  }>
}

export type FragmentContentsFragment =
  | FragmentContents_TextContent_Fragment
  | FragmentContents_TextGroupContent_Fragment

export type TextContentWithFragments_TextContent_Fragment = {
  __typename: "TextContent"
  id: string
  xast?: string | null
  historyNumber: number
  fragments: Array<{
    __typename: "TextContentFragment"
    compoundId: string
    contentNode: {
      __typename: "ST4Node"
      label?: string | null
      id: string
      aspectId: any
      content?:
        | { __typename: "LexiconContent" }
        | { __typename: "LexiconEntryContent" }
        | { __typename: "MediaContent" }
        | { __typename: "MediaGroupContent" }
        | { __typename: "ProjectContent" }
        | { __typename: "TermContent" }
        | {
            __typename: "TextContent"
            id: string
            xast?: string | null
            historyNumber: number
            parts: Array<{
              __typename: "TextContentPart"
              ref: string
              contentNode: {
                __typename: "ST4Node"
                id: string
                aspectId: any
                content?:
                  | { __typename: "LexiconContent" }
                  | { __typename: "LexiconEntryContent" }
                  | { __typename: "MediaContent" }
                  | { __typename: "MediaGroupContent" }
                  | { __typename: "ProjectContent" }
                  | { __typename: "TermContent" }
                  | {
                      __typename: "TextContent"
                      id: string
                      xast?: string | null
                      historyNumber: number
                      links?: Array<{
                        __typename: "TextContentLink"
                        ref?: string | null
                        node?:
                          | { __typename: "CommentNode"; id: string }
                          | { __typename: "ExternalSite"; id: string }
                          | { __typename: "ST4Class"; id: string }
                          | {
                              __typename: "ST4Node"
                              id: string
                              aspectId: any
                              label?: string | null
                              properties?: {
                                __typename: "ContentPropertyConnection"
                                totalCount: number
                                edges?: Array<{
                                  __typename: "ContentPropertyEdge"
                                  node?: {
                                    __typename: "ContentProperty"
                                    typeOfValue: Types.ContentPropertyValueType
                                    name: string
                                    value?: any | null
                                    displayName: string
                                  } | null
                                } | null> | null
                              } | null
                            }
                          | { __typename: "ST4TreeNode"; id: string }
                          | null
                      } | null> | null
                      images: Array<{
                        __typename: "NodeRef"
                        id: string
                        target?:
                          | { __typename: "CommentNode"; id: string }
                          | { __typename: "ExternalSite"; id: string }
                          | { __typename: "ST4Class"; id: string }
                          | {
                              __typename: "ST4Node"
                              aspectId: any
                              id: string
                              releaseState: {
                                __typename: "ReleaseStateValue"
                                displayName: string
                                state: Types.ReleaseState
                                isImplicitlyReleased: boolean
                              }
                              content?:
                                | { __typename: "LexiconContent" }
                                | { __typename: "LexiconEntryContent" }
                                | {
                                    __typename: "MediaContent"
                                    media?:
                                      | {
                                          __typename: "AnimationMediaInfo"
                                          id: string
                                          resourceId?: string | null
                                          thumbnail?: string | null
                                          width?: number | null
                                          height?: number | null
                                          mediaType?: Types.MediaType | null
                                        }
                                      | {
                                          __typename: "ImageMediaInfo"
                                          id: string
                                          resourceId?: string | null
                                          thumbnail?: string | null
                                          width?: number | null
                                          height?: number | null
                                          resolution?: number | null
                                          colorDepth?: number | null
                                          size?: number | null
                                          mime?: string | null
                                          extension?: string | null
                                          allowCaching?: boolean | null
                                          mediaType?: Types.MediaType | null
                                          src?: string | null
                                          language?: string | null
                                        }
                                      | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                                      | {
                                          __typename: "VectorGraphicsMediaInfo"
                                          id: string
                                          resourceId?: string | null
                                          thumbnail?: string | null
                                          width?: number | null
                                          height?: number | null
                                          allowCaching?: boolean | null
                                          mediaType?: Types.MediaType | null
                                          language?: string | null
                                          labels: Array<{
                                            __typename: "VectorGraphicsLabel"
                                            id?: string | null
                                            sequence?: number | null
                                            autoValue?: string | null
                                            text?: string | null
                                            linkType?: Types.VectorGraphicsLabelLinkType | null
                                            link?: string | null
                                            ref?:
                                              | {
                                                  __typename: "GenericRef"
                                                  id: string
                                                  owner?:
                                                    | { __typename: "CommentNode" }
                                                    | { __typename: "ExternalSite" }
                                                    | { __typename: "ST4Class" }
                                                    | { __typename: "ST4Node"; id: string }
                                                    | { __typename: "ST4TreeNode" }
                                                    | null
                                                  target?:
                                                    | { __typename: "CommentNode" }
                                                    | {
                                                        __typename: "ExternalSite"
                                                        id: string
                                                        teaserData?: {
                                                          __typename: "ExternalLinkTeaserData"
                                                          title?: string | null
                                                          summary?: string | null
                                                          iconUrl?: string | null
                                                          imageUrl?: string | null
                                                          status?: Types.LinkTargetStatus | null
                                                        } | null
                                                      }
                                                    | { __typename: "ST4Class" }
                                                    | {
                                                        __typename: "ST4Node"
                                                        id: string
                                                        aspectId: any
                                                        label?: string | null
                                                        properties?: {
                                                          __typename: "ContentPropertyConnection"
                                                          edges?: Array<{
                                                            __typename: "ContentPropertyEdge"
                                                            node?: {
                                                              __typename: "ContentProperty"
                                                              name: string
                                                              value?: any | null
                                                              displayName: string
                                                            } | null
                                                          } | null> | null
                                                        } | null
                                                      }
                                                    | { __typename: "ST4TreeNode" }
                                                    | null
                                                }
                                              | {
                                                  __typename: "NodeRef"
                                                  id: string
                                                  owner?:
                                                    | { __typename: "CommentNode" }
                                                    | { __typename: "ExternalSite" }
                                                    | { __typename: "ST4Class" }
                                                    | { __typename: "ST4Node"; id: string }
                                                    | { __typename: "ST4TreeNode" }
                                                    | null
                                                  target?:
                                                    | { __typename: "CommentNode" }
                                                    | {
                                                        __typename: "ExternalSite"
                                                        id: string
                                                        teaserData?: {
                                                          __typename: "ExternalLinkTeaserData"
                                                          title?: string | null
                                                          summary?: string | null
                                                          iconUrl?: string | null
                                                          imageUrl?: string | null
                                                          status?: Types.LinkTargetStatus | null
                                                        } | null
                                                      }
                                                    | { __typename: "ST4Class" }
                                                    | {
                                                        __typename: "ST4Node"
                                                        id: string
                                                        aspectId: any
                                                        label?: string | null
                                                        properties?: {
                                                          __typename: "ContentPropertyConnection"
                                                          edges?: Array<{
                                                            __typename: "ContentPropertyEdge"
                                                            node?: {
                                                              __typename: "ContentProperty"
                                                              name: string
                                                              value?: any | null
                                                              displayName: string
                                                            } | null
                                                          } | null> | null
                                                        } | null
                                                      }
                                                    | { __typename: "ST4TreeNode" }
                                                    | null
                                                }
                                              | null
                                          }>
                                        }
                                      | {
                                          __typename: "VideoMediaInfo"
                                          id: string
                                          resourceId?: string | null
                                          thumbnail?: string | null
                                          width?: number | null
                                          height?: number | null
                                          mediaType?: Types.MediaType | null
                                        }
                                      | null
                                  }
                                | {
                                    __typename: "MediaGroupContent"
                                    children: Array<{
                                      __typename: "NodeRef"
                                      id: string
                                      target?:
                                        | { __typename: "CommentNode"; id: string }
                                        | { __typename: "ExternalSite"; id: string }
                                        | { __typename: "ST4Class"; id: string }
                                        | {
                                            __typename: "ST4Node"
                                            id: string
                                            aspectId: any
                                            content?:
                                              | { __typename: "LexiconContent" }
                                              | { __typename: "LexiconEntryContent" }
                                              | {
                                                  __typename: "MediaContent"
                                                  media?:
                                                    | {
                                                        __typename: "AnimationMediaInfo"
                                                        id: string
                                                        resourceId?: string | null
                                                        thumbnail?: string | null
                                                        width?: number | null
                                                        height?: number | null
                                                        mediaType?: Types.MediaType | null
                                                      }
                                                    | {
                                                        __typename: "ImageMediaInfo"
                                                        id: string
                                                        resourceId?: string | null
                                                        thumbnail?: string | null
                                                        width?: number | null
                                                        height?: number | null
                                                        resolution?: number | null
                                                        colorDepth?: number | null
                                                        size?: number | null
                                                        mime?: string | null
                                                        extension?: string | null
                                                        allowCaching?: boolean | null
                                                        mediaType?: Types.MediaType | null
                                                        src?: string | null
                                                        language?: string | null
                                                      }
                                                    | {
                                                        __typename: "NoMediaInfo"
                                                        id: string
                                                        mediaType?: Types.MediaType | null
                                                      }
                                                    | {
                                                        __typename: "VectorGraphicsMediaInfo"
                                                        id: string
                                                        resourceId?: string | null
                                                        thumbnail?: string | null
                                                        width?: number | null
                                                        height?: number | null
                                                        allowCaching?: boolean | null
                                                        mediaType?: Types.MediaType | null
                                                        language?: string | null
                                                        labels: Array<{
                                                          __typename: "VectorGraphicsLabel"
                                                          id?: string | null
                                                          sequence?: number | null
                                                          autoValue?: string | null
                                                          text?: string | null
                                                          linkType?: Types.VectorGraphicsLabelLinkType | null
                                                          link?: string | null
                                                          ref?:
                                                            | {
                                                                __typename: "GenericRef"
                                                                id: string
                                                                owner?:
                                                                  | { __typename: "CommentNode" }
                                                                  | { __typename: "ExternalSite" }
                                                                  | { __typename: "ST4Class" }
                                                                  | { __typename: "ST4Node"; id: string }
                                                                  | { __typename: "ST4TreeNode" }
                                                                  | null
                                                                target?:
                                                                  | { __typename: "CommentNode" }
                                                                  | {
                                                                      __typename: "ExternalSite"
                                                                      id: string
                                                                      teaserData?: {
                                                                        __typename: "ExternalLinkTeaserData"
                                                                        title?: string | null
                                                                        summary?: string | null
                                                                        iconUrl?: string | null
                                                                        imageUrl?: string | null
                                                                        status?: Types.LinkTargetStatus | null
                                                                      } | null
                                                                    }
                                                                  | { __typename: "ST4Class" }
                                                                  | {
                                                                      __typename: "ST4Node"
                                                                      id: string
                                                                      aspectId: any
                                                                      label?: string | null
                                                                      properties?: {
                                                                        __typename: "ContentPropertyConnection"
                                                                        edges?: Array<{
                                                                          __typename: "ContentPropertyEdge"
                                                                          node?: {
                                                                            __typename: "ContentProperty"
                                                                            name: string
                                                                            value?: any | null
                                                                            displayName: string
                                                                          } | null
                                                                        } | null> | null
                                                                      } | null
                                                                    }
                                                                  | { __typename: "ST4TreeNode" }
                                                                  | null
                                                              }
                                                            | {
                                                                __typename: "NodeRef"
                                                                id: string
                                                                owner?:
                                                                  | { __typename: "CommentNode" }
                                                                  | { __typename: "ExternalSite" }
                                                                  | { __typename: "ST4Class" }
                                                                  | { __typename: "ST4Node"; id: string }
                                                                  | { __typename: "ST4TreeNode" }
                                                                  | null
                                                                target?:
                                                                  | { __typename: "CommentNode" }
                                                                  | {
                                                                      __typename: "ExternalSite"
                                                                      id: string
                                                                      teaserData?: {
                                                                        __typename: "ExternalLinkTeaserData"
                                                                        title?: string | null
                                                                        summary?: string | null
                                                                        iconUrl?: string | null
                                                                        imageUrl?: string | null
                                                                        status?: Types.LinkTargetStatus | null
                                                                      } | null
                                                                    }
                                                                  | { __typename: "ST4Class" }
                                                                  | {
                                                                      __typename: "ST4Node"
                                                                      id: string
                                                                      aspectId: any
                                                                      label?: string | null
                                                                      properties?: {
                                                                        __typename: "ContentPropertyConnection"
                                                                        edges?: Array<{
                                                                          __typename: "ContentPropertyEdge"
                                                                          node?: {
                                                                            __typename: "ContentProperty"
                                                                            name: string
                                                                            value?: any | null
                                                                            displayName: string
                                                                          } | null
                                                                        } | null> | null
                                                                      } | null
                                                                    }
                                                                  | { __typename: "ST4TreeNode" }
                                                                  | null
                                                              }
                                                            | null
                                                        }>
                                                      }
                                                    | {
                                                        __typename: "VideoMediaInfo"
                                                        id: string
                                                        resourceId?: string | null
                                                        thumbnail?: string | null
                                                        width?: number | null
                                                        height?: number | null
                                                        mediaType?: Types.MediaType | null
                                                      }
                                                    | null
                                                }
                                              | {
                                                  __typename: "MediaGroupContent"
                                                  media?:
                                                    | {
                                                        __typename: "AnimationMediaInfo"
                                                        id: string
                                                        resourceId?: string | null
                                                        thumbnail?: string | null
                                                        width?: number | null
                                                        height?: number | null
                                                        mediaType?: Types.MediaType | null
                                                      }
                                                    | {
                                                        __typename: "ImageMediaInfo"
                                                        id: string
                                                        resourceId?: string | null
                                                        thumbnail?: string | null
                                                        width?: number | null
                                                        height?: number | null
                                                        resolution?: number | null
                                                        colorDepth?: number | null
                                                        size?: number | null
                                                        mime?: string | null
                                                        extension?: string | null
                                                        allowCaching?: boolean | null
                                                        mediaType?: Types.MediaType | null
                                                        src?: string | null
                                                        language?: string | null
                                                      }
                                                    | {
                                                        __typename: "NoMediaInfo"
                                                        id: string
                                                        mediaType?: Types.MediaType | null
                                                      }
                                                    | {
                                                        __typename: "VectorGraphicsMediaInfo"
                                                        id: string
                                                        resourceId?: string | null
                                                        thumbnail?: string | null
                                                        width?: number | null
                                                        height?: number | null
                                                        allowCaching?: boolean | null
                                                        mediaType?: Types.MediaType | null
                                                        language?: string | null
                                                        labels: Array<{
                                                          __typename: "VectorGraphicsLabel"
                                                          id?: string | null
                                                          sequence?: number | null
                                                          autoValue?: string | null
                                                          text?: string | null
                                                          linkType?: Types.VectorGraphicsLabelLinkType | null
                                                          link?: string | null
                                                          ref?:
                                                            | {
                                                                __typename: "GenericRef"
                                                                id: string
                                                                owner?:
                                                                  | { __typename: "CommentNode" }
                                                                  | { __typename: "ExternalSite" }
                                                                  | { __typename: "ST4Class" }
                                                                  | { __typename: "ST4Node"; id: string }
                                                                  | { __typename: "ST4TreeNode" }
                                                                  | null
                                                                target?:
                                                                  | { __typename: "CommentNode" }
                                                                  | {
                                                                      __typename: "ExternalSite"
                                                                      id: string
                                                                      teaserData?: {
                                                                        __typename: "ExternalLinkTeaserData"
                                                                        title?: string | null
                                                                        summary?: string | null
                                                                        iconUrl?: string | null
                                                                        imageUrl?: string | null
                                                                        status?: Types.LinkTargetStatus | null
                                                                      } | null
                                                                    }
                                                                  | { __typename: "ST4Class" }
                                                                  | {
                                                                      __typename: "ST4Node"
                                                                      id: string
                                                                      aspectId: any
                                                                      label?: string | null
                                                                      properties?: {
                                                                        __typename: "ContentPropertyConnection"
                                                                        edges?: Array<{
                                                                          __typename: "ContentPropertyEdge"
                                                                          node?: {
                                                                            __typename: "ContentProperty"
                                                                            name: string
                                                                            value?: any | null
                                                                            displayName: string
                                                                          } | null
                                                                        } | null> | null
                                                                      } | null
                                                                    }
                                                                  | { __typename: "ST4TreeNode" }
                                                                  | null
                                                              }
                                                            | {
                                                                __typename: "NodeRef"
                                                                id: string
                                                                owner?:
                                                                  | { __typename: "CommentNode" }
                                                                  | { __typename: "ExternalSite" }
                                                                  | { __typename: "ST4Class" }
                                                                  | { __typename: "ST4Node"; id: string }
                                                                  | { __typename: "ST4TreeNode" }
                                                                  | null
                                                                target?:
                                                                  | { __typename: "CommentNode" }
                                                                  | {
                                                                      __typename: "ExternalSite"
                                                                      id: string
                                                                      teaserData?: {
                                                                        __typename: "ExternalLinkTeaserData"
                                                                        title?: string | null
                                                                        summary?: string | null
                                                                        iconUrl?: string | null
                                                                        imageUrl?: string | null
                                                                        status?: Types.LinkTargetStatus | null
                                                                      } | null
                                                                    }
                                                                  | { __typename: "ST4Class" }
                                                                  | {
                                                                      __typename: "ST4Node"
                                                                      id: string
                                                                      aspectId: any
                                                                      label?: string | null
                                                                      properties?: {
                                                                        __typename: "ContentPropertyConnection"
                                                                        edges?: Array<{
                                                                          __typename: "ContentPropertyEdge"
                                                                          node?: {
                                                                            __typename: "ContentProperty"
                                                                            name: string
                                                                            value?: any | null
                                                                            displayName: string
                                                                          } | null
                                                                        } | null> | null
                                                                      } | null
                                                                    }
                                                                  | { __typename: "ST4TreeNode" }
                                                                  | null
                                                              }
                                                            | null
                                                        }>
                                                      }
                                                    | {
                                                        __typename: "VideoMediaInfo"
                                                        id: string
                                                        resourceId?: string | null
                                                        thumbnail?: string | null
                                                        width?: number | null
                                                        height?: number | null
                                                        mediaType?: Types.MediaType | null
                                                      }
                                                    | null
                                                }
                                              | { __typename: "ProjectContent" }
                                              | { __typename: "TermContent" }
                                              | { __typename: "TextContent" }
                                              | { __typename: "TextGroupContent" }
                                              | { __typename: "VariableTableContent" }
                                              | null
                                          }
                                        | { __typename: "ST4TreeNode"; id: string }
                                        | null
                                    }>
                                    media?:
                                      | {
                                          __typename: "AnimationMediaInfo"
                                          id: string
                                          resourceId?: string | null
                                          thumbnail?: string | null
                                          width?: number | null
                                          height?: number | null
                                          mediaType?: Types.MediaType | null
                                        }
                                      | {
                                          __typename: "ImageMediaInfo"
                                          id: string
                                          resourceId?: string | null
                                          thumbnail?: string | null
                                          width?: number | null
                                          height?: number | null
                                          resolution?: number | null
                                          colorDepth?: number | null
                                          size?: number | null
                                          mime?: string | null
                                          extension?: string | null
                                          allowCaching?: boolean | null
                                          mediaType?: Types.MediaType | null
                                          src?: string | null
                                          language?: string | null
                                        }
                                      | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                                      | {
                                          __typename: "VectorGraphicsMediaInfo"
                                          id: string
                                          resourceId?: string | null
                                          thumbnail?: string | null
                                          width?: number | null
                                          height?: number | null
                                          allowCaching?: boolean | null
                                          mediaType?: Types.MediaType | null
                                          language?: string | null
                                          labels: Array<{
                                            __typename: "VectorGraphicsLabel"
                                            id?: string | null
                                            sequence?: number | null
                                            autoValue?: string | null
                                            text?: string | null
                                            linkType?: Types.VectorGraphicsLabelLinkType | null
                                            link?: string | null
                                            ref?:
                                              | {
                                                  __typename: "GenericRef"
                                                  id: string
                                                  owner?:
                                                    | { __typename: "CommentNode" }
                                                    | { __typename: "ExternalSite" }
                                                    | { __typename: "ST4Class" }
                                                    | { __typename: "ST4Node"; id: string }
                                                    | { __typename: "ST4TreeNode" }
                                                    | null
                                                  target?:
                                                    | { __typename: "CommentNode" }
                                                    | {
                                                        __typename: "ExternalSite"
                                                        id: string
                                                        teaserData?: {
                                                          __typename: "ExternalLinkTeaserData"
                                                          title?: string | null
                                                          summary?: string | null
                                                          iconUrl?: string | null
                                                          imageUrl?: string | null
                                                          status?: Types.LinkTargetStatus | null
                                                        } | null
                                                      }
                                                    | { __typename: "ST4Class" }
                                                    | {
                                                        __typename: "ST4Node"
                                                        id: string
                                                        aspectId: any
                                                        label?: string | null
                                                        properties?: {
                                                          __typename: "ContentPropertyConnection"
                                                          edges?: Array<{
                                                            __typename: "ContentPropertyEdge"
                                                            node?: {
                                                              __typename: "ContentProperty"
                                                              name: string
                                                              value?: any | null
                                                              displayName: string
                                                            } | null
                                                          } | null> | null
                                                        } | null
                                                      }
                                                    | { __typename: "ST4TreeNode" }
                                                    | null
                                                }
                                              | {
                                                  __typename: "NodeRef"
                                                  id: string
                                                  owner?:
                                                    | { __typename: "CommentNode" }
                                                    | { __typename: "ExternalSite" }
                                                    | { __typename: "ST4Class" }
                                                    | { __typename: "ST4Node"; id: string }
                                                    | { __typename: "ST4TreeNode" }
                                                    | null
                                                  target?:
                                                    | { __typename: "CommentNode" }
                                                    | {
                                                        __typename: "ExternalSite"
                                                        id: string
                                                        teaserData?: {
                                                          __typename: "ExternalLinkTeaserData"
                                                          title?: string | null
                                                          summary?: string | null
                                                          iconUrl?: string | null
                                                          imageUrl?: string | null
                                                          status?: Types.LinkTargetStatus | null
                                                        } | null
                                                      }
                                                    | { __typename: "ST4Class" }
                                                    | {
                                                        __typename: "ST4Node"
                                                        id: string
                                                        aspectId: any
                                                        label?: string | null
                                                        properties?: {
                                                          __typename: "ContentPropertyConnection"
                                                          edges?: Array<{
                                                            __typename: "ContentPropertyEdge"
                                                            node?: {
                                                              __typename: "ContentProperty"
                                                              name: string
                                                              value?: any | null
                                                              displayName: string
                                                            } | null
                                                          } | null> | null
                                                        } | null
                                                      }
                                                    | { __typename: "ST4TreeNode" }
                                                    | null
                                                }
                                              | null
                                          }>
                                        }
                                      | {
                                          __typename: "VideoMediaInfo"
                                          id: string
                                          resourceId?: string | null
                                          thumbnail?: string | null
                                          width?: number | null
                                          height?: number | null
                                          mediaType?: Types.MediaType | null
                                        }
                                      | null
                                  }
                                | { __typename: "ProjectContent" }
                                | { __typename: "TermContent" }
                                | { __typename: "TextContent" }
                                | { __typename: "TextGroupContent" }
                                | { __typename: "VariableTableContent" }
                                | null
                            }
                          | { __typename: "ST4TreeNode"; id: string }
                          | null
                      }>
                      variableTables?: Array<{
                        __typename: "VariableTableReference"
                        ref?: string | null
                        node?:
                          | { __typename: "CommentNode" }
                          | { __typename: "ExternalSite" }
                          | { __typename: "ST4Class" }
                          | {
                              __typename: "ST4Node"
                              id: string
                              label?: string | null
                              aspectId: any
                              content?:
                                | { __typename: "LexiconContent" }
                                | { __typename: "LexiconEntryContent" }
                                | { __typename: "MediaContent" }
                                | { __typename: "MediaGroupContent" }
                                | { __typename: "ProjectContent" }
                                | { __typename: "TermContent" }
                                | { __typename: "TextContent" }
                                | { __typename: "TextGroupContent" }
                                | {
                                    __typename: "VariableTableContent"
                                    xml?: string | null
                                    valueSets: Array<{ __typename: "ValueSet"; name: string; valueSetId: string }>
                                    variables: Array<{
                                      __typename: "Variable"
                                      name: string
                                      variableId: string
                                      values: Array<string>
                                    }>
                                  }
                                | null
                            }
                          | { __typename: "ST4TreeNode" }
                          | null
                      } | null> | null
                      linkedDataNodes: Array<{
                        __typename: "NodeRef"
                        id: string
                        target?:
                          | { __typename: "CommentNode"; id: string }
                          | { __typename: "ExternalSite"; id: string }
                          | { __typename: "ST4Class"; id: string }
                          | {
                              __typename: "ST4Node"
                              label?: string | null
                              id: string
                              metadata: Array<{
                                __typename: "MetaDataEntry"
                                label?: string | null
                                systemName: string
                                value?: string | null
                                values: Array<string>
                                users: Array<string>
                              }>
                              linkedOntologyNodes: Array<
                                | {
                                    __typename: "GenericRef"
                                    target?:
                                      | { __typename: "CommentNode" }
                                      | { __typename: "ExternalSite" }
                                      | { __typename: "ST4Class" }
                                      | { __typename: "ST4Node"; id: string; label?: string | null }
                                      | { __typename: "ST4TreeNode" }
                                      | null
                                  }
                                | {
                                    __typename: "NodeRef"
                                    linkClassName: string
                                    class?: { __typename: "ST4Class"; label?: string | null } | null
                                    target?:
                                      | { __typename: "CommentNode" }
                                      | { __typename: "ExternalSite" }
                                      | { __typename: "ST4Class" }
                                      | { __typename: "ST4Node"; id: string; label?: string | null }
                                      | { __typename: "ST4TreeNode" }
                                      | null
                                  }
                              >
                            }
                          | { __typename: "ST4TreeNode"; id: string }
                          | null
                      }>
                      lexiconEntries?: Array<{
                        __typename: "NodeRef"
                        id: string
                        target?:
                          | { __typename: "CommentNode"; id: string }
                          | { __typename: "ExternalSite"; id: string }
                          | { __typename: "ST4Class"; id: string }
                          | {
                              __typename: "ST4Node"
                              aspectId: any
                              label?: string | null
                              id: string
                              content?:
                                | { __typename: "LexiconContent" }
                                | {
                                    __typename: "LexiconEntryContent"
                                    descriptionXast?: string | null
                                    subject?: string | null
                                    sortKey?: string | null
                                    lexicon:
                                      | { __typename: "CommentNode"; id: string }
                                      | { __typename: "ExternalSite"; id: string }
                                      | { __typename: "ST4Class"; id: string }
                                      | { __typename: "ST4Node"; label?: string | null; id: string }
                                      | { __typename: "ST4TreeNode"; id: string }
                                    terms: Array<
                                      | { __typename: "CommentNode"; id: string }
                                      | { __typename: "ExternalSite"; id: string }
                                      | { __typename: "ST4Class"; id: string }
                                      | {
                                          __typename: "ST4Node"
                                          id: string
                                          label?: string | null
                                          aspectId: any
                                          content?:
                                            | { __typename: "LexiconContent" }
                                            | { __typename: "LexiconEntryContent" }
                                            | { __typename: "MediaContent" }
                                            | { __typename: "MediaGroupContent" }
                                            | { __typename: "ProjectContent" }
                                            | {
                                                __typename: "TermContent"
                                                gramaticalGender?: string | null
                                                validity?: string | null
                                                editLanguage?: string | null
                                              }
                                            | { __typename: "TextContent" }
                                            | { __typename: "TextGroupContent" }
                                            | { __typename: "VariableTableContent" }
                                            | null
                                        }
                                      | { __typename: "ST4TreeNode"; id: string }
                                    >
                                    image?:
                                      | { __typename: "CommentNode"; id: string }
                                      | { __typename: "ExternalSite"; id: string }
                                      | { __typename: "ST4Class"; id: string }
                                      | {
                                          __typename: "ST4Node"
                                          aspectId: any
                                          id: string
                                          content?:
                                            | { __typename: "LexiconContent" }
                                            | { __typename: "LexiconEntryContent" }
                                            | {
                                                __typename: "MediaContent"
                                                media?:
                                                  | {
                                                      __typename: "AnimationMediaInfo"
                                                      thumbnail?: string | null
                                                      mediaType?: Types.MediaType | null
                                                    }
                                                  | {
                                                      __typename: "ImageMediaInfo"
                                                      src?: string | null
                                                      thumbnail?: string | null
                                                      mediaType?: Types.MediaType | null
                                                    }
                                                  | { __typename: "NoMediaInfo" }
                                                  | {
                                                      __typename: "VectorGraphicsMediaInfo"
                                                      svg?: string | null
                                                      thumbnail?: string | null
                                                      mediaType?: Types.MediaType | null
                                                    }
                                                  | {
                                                      __typename: "VideoMediaInfo"
                                                      thumbnail?: string | null
                                                      mediaType?: Types.MediaType | null
                                                    }
                                                  | null
                                              }
                                            | { __typename: "MediaGroupContent" }
                                            | { __typename: "ProjectContent" }
                                            | { __typename: "TermContent" }
                                            | { __typename: "TextContent" }
                                            | { __typename: "TextGroupContent" }
                                            | { __typename: "VariableTableContent" }
                                            | null
                                        }
                                      | { __typename: "ST4TreeNode"; id: string }
                                      | null
                                  }
                                | { __typename: "MediaContent" }
                                | { __typename: "MediaGroupContent" }
                                | { __typename: "ProjectContent" }
                                | { __typename: "TermContent" }
                                | { __typename: "TextContent" }
                                | { __typename: "TextGroupContent" }
                                | { __typename: "VariableTableContent" }
                                | null
                            }
                          | { __typename: "ST4TreeNode"; id: string }
                          | null
                      } | null> | null
                      comments: Array<{
                        __typename: "CommentNode"
                        id: string
                        comment:
                          | {
                              __typename: "BlockComment"
                              location: number
                              value?: string | null
                              authorDisplayName?: string | null
                              authorUserName?: string | null
                              commentKey: string
                              editDate?: any | null
                              modificationVersion?: number | null
                              replyToKey?: string | null
                              sortOrder?: number | null
                              commentType?: string | null
                              state?: string | null
                              color: string
                              persistanceState?: Types.PersistanceState | null
                              persistanceAction?: Types.PersistanceAction | null
                              node: {
                                __typename: "ST4Node"
                                aspectId: any
                                id: string
                                content?:
                                  | { __typename: "LexiconContent" }
                                  | { __typename: "LexiconEntryContent" }
                                  | { __typename: "MediaContent" }
                                  | { __typename: "MediaGroupContent" }
                                  | { __typename: "ProjectContent" }
                                  | { __typename: "TermContent" }
                                  | { __typename: "TextContent"; id: string; historyNumber: number }
                                  | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                                  | { __typename: "VariableTableContent" }
                                  | null
                              }
                              actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
                            }
                          | {
                              __typename: "TextComment"
                              endLocation: number
                              startLocation?: number | null
                              value?: string | null
                              authorDisplayName?: string | null
                              authorUserName?: string | null
                              commentKey: string
                              editDate?: any | null
                              modificationVersion?: number | null
                              replyToKey?: string | null
                              sortOrder?: number | null
                              commentType?: string | null
                              state?: string | null
                              color: string
                              persistanceState?: Types.PersistanceState | null
                              persistanceAction?: Types.PersistanceAction | null
                              node: {
                                __typename: "ST4Node"
                                aspectId: any
                                id: string
                                content?:
                                  | { __typename: "LexiconContent" }
                                  | { __typename: "LexiconEntryContent" }
                                  | { __typename: "MediaContent" }
                                  | { __typename: "MediaGroupContent" }
                                  | { __typename: "ProjectContent" }
                                  | { __typename: "TermContent" }
                                  | { __typename: "TextContent"; id: string; historyNumber: number }
                                  | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                                  | { __typename: "VariableTableContent" }
                                  | null
                              }
                              actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
                            }
                      }>
                    }
                  | {
                      __typename: "TextGroupContent"
                      currentNodeId?: any | null
                      groupNodeId: any
                      id: string
                      xast?: string | null
                      historyNumber: number
                      children: Array<{
                        __typename: "NodeRef"
                        id: string
                        target?:
                          | { __typename: "CommentNode"; id: string }
                          | { __typename: "ExternalSite"; id: string }
                          | { __typename: "ST4Class"; id: string }
                          | { __typename: "ST4Node"; label?: string | null; aspectId: any; id: string }
                          | { __typename: "ST4TreeNode"; id: string }
                          | null
                      }>
                      links?: Array<{
                        __typename: "TextContentLink"
                        ref?: string | null
                        node?:
                          | { __typename: "CommentNode"; id: string }
                          | { __typename: "ExternalSite"; id: string }
                          | { __typename: "ST4Class"; id: string }
                          | {
                              __typename: "ST4Node"
                              id: string
                              aspectId: any
                              label?: string | null
                              properties?: {
                                __typename: "ContentPropertyConnection"
                                totalCount: number
                                edges?: Array<{
                                  __typename: "ContentPropertyEdge"
                                  node?: {
                                    __typename: "ContentProperty"
                                    typeOfValue: Types.ContentPropertyValueType
                                    name: string
                                    value?: any | null
                                    displayName: string
                                  } | null
                                } | null> | null
                              } | null
                            }
                          | { __typename: "ST4TreeNode"; id: string }
                          | null
                      } | null> | null
                      images: Array<{
                        __typename: "NodeRef"
                        id: string
                        target?:
                          | { __typename: "CommentNode"; id: string }
                          | { __typename: "ExternalSite"; id: string }
                          | { __typename: "ST4Class"; id: string }
                          | {
                              __typename: "ST4Node"
                              aspectId: any
                              id: string
                              releaseState: {
                                __typename: "ReleaseStateValue"
                                displayName: string
                                state: Types.ReleaseState
                                isImplicitlyReleased: boolean
                              }
                              content?:
                                | { __typename: "LexiconContent" }
                                | { __typename: "LexiconEntryContent" }
                                | {
                                    __typename: "MediaContent"
                                    media?:
                                      | {
                                          __typename: "AnimationMediaInfo"
                                          id: string
                                          resourceId?: string | null
                                          thumbnail?: string | null
                                          width?: number | null
                                          height?: number | null
                                          mediaType?: Types.MediaType | null
                                        }
                                      | {
                                          __typename: "ImageMediaInfo"
                                          id: string
                                          resourceId?: string | null
                                          thumbnail?: string | null
                                          width?: number | null
                                          height?: number | null
                                          resolution?: number | null
                                          colorDepth?: number | null
                                          size?: number | null
                                          mime?: string | null
                                          extension?: string | null
                                          allowCaching?: boolean | null
                                          mediaType?: Types.MediaType | null
                                          src?: string | null
                                          language?: string | null
                                        }
                                      | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                                      | {
                                          __typename: "VectorGraphicsMediaInfo"
                                          id: string
                                          resourceId?: string | null
                                          thumbnail?: string | null
                                          width?: number | null
                                          height?: number | null
                                          allowCaching?: boolean | null
                                          mediaType?: Types.MediaType | null
                                          language?: string | null
                                          labels: Array<{
                                            __typename: "VectorGraphicsLabel"
                                            id?: string | null
                                            sequence?: number | null
                                            autoValue?: string | null
                                            text?: string | null
                                            linkType?: Types.VectorGraphicsLabelLinkType | null
                                            link?: string | null
                                            ref?:
                                              | {
                                                  __typename: "GenericRef"
                                                  id: string
                                                  owner?:
                                                    | { __typename: "CommentNode" }
                                                    | { __typename: "ExternalSite" }
                                                    | { __typename: "ST4Class" }
                                                    | { __typename: "ST4Node"; id: string }
                                                    | { __typename: "ST4TreeNode" }
                                                    | null
                                                  target?:
                                                    | { __typename: "CommentNode" }
                                                    | {
                                                        __typename: "ExternalSite"
                                                        id: string
                                                        teaserData?: {
                                                          __typename: "ExternalLinkTeaserData"
                                                          title?: string | null
                                                          summary?: string | null
                                                          iconUrl?: string | null
                                                          imageUrl?: string | null
                                                          status?: Types.LinkTargetStatus | null
                                                        } | null
                                                      }
                                                    | { __typename: "ST4Class" }
                                                    | {
                                                        __typename: "ST4Node"
                                                        id: string
                                                        aspectId: any
                                                        label?: string | null
                                                        properties?: {
                                                          __typename: "ContentPropertyConnection"
                                                          edges?: Array<{
                                                            __typename: "ContentPropertyEdge"
                                                            node?: {
                                                              __typename: "ContentProperty"
                                                              name: string
                                                              value?: any | null
                                                              displayName: string
                                                            } | null
                                                          } | null> | null
                                                        } | null
                                                      }
                                                    | { __typename: "ST4TreeNode" }
                                                    | null
                                                }
                                              | {
                                                  __typename: "NodeRef"
                                                  id: string
                                                  owner?:
                                                    | { __typename: "CommentNode" }
                                                    | { __typename: "ExternalSite" }
                                                    | { __typename: "ST4Class" }
                                                    | { __typename: "ST4Node"; id: string }
                                                    | { __typename: "ST4TreeNode" }
                                                    | null
                                                  target?:
                                                    | { __typename: "CommentNode" }
                                                    | {
                                                        __typename: "ExternalSite"
                                                        id: string
                                                        teaserData?: {
                                                          __typename: "ExternalLinkTeaserData"
                                                          title?: string | null
                                                          summary?: string | null
                                                          iconUrl?: string | null
                                                          imageUrl?: string | null
                                                          status?: Types.LinkTargetStatus | null
                                                        } | null
                                                      }
                                                    | { __typename: "ST4Class" }
                                                    | {
                                                        __typename: "ST4Node"
                                                        id: string
                                                        aspectId: any
                                                        label?: string | null
                                                        properties?: {
                                                          __typename: "ContentPropertyConnection"
                                                          edges?: Array<{
                                                            __typename: "ContentPropertyEdge"
                                                            node?: {
                                                              __typename: "ContentProperty"
                                                              name: string
                                                              value?: any | null
                                                              displayName: string
                                                            } | null
                                                          } | null> | null
                                                        } | null
                                                      }
                                                    | { __typename: "ST4TreeNode" }
                                                    | null
                                                }
                                              | null
                                          }>
                                        }
                                      | {
                                          __typename: "VideoMediaInfo"
                                          id: string
                                          resourceId?: string | null
                                          thumbnail?: string | null
                                          width?: number | null
                                          height?: number | null
                                          mediaType?: Types.MediaType | null
                                        }
                                      | null
                                  }
                                | {
                                    __typename: "MediaGroupContent"
                                    children: Array<{
                                      __typename: "NodeRef"
                                      id: string
                                      target?:
                                        | { __typename: "CommentNode"; id: string }
                                        | { __typename: "ExternalSite"; id: string }
                                        | { __typename: "ST4Class"; id: string }
                                        | {
                                            __typename: "ST4Node"
                                            id: string
                                            aspectId: any
                                            content?:
                                              | { __typename: "LexiconContent" }
                                              | { __typename: "LexiconEntryContent" }
                                              | {
                                                  __typename: "MediaContent"
                                                  media?:
                                                    | {
                                                        __typename: "AnimationMediaInfo"
                                                        id: string
                                                        resourceId?: string | null
                                                        thumbnail?: string | null
                                                        width?: number | null
                                                        height?: number | null
                                                        mediaType?: Types.MediaType | null
                                                      }
                                                    | {
                                                        __typename: "ImageMediaInfo"
                                                        id: string
                                                        resourceId?: string | null
                                                        thumbnail?: string | null
                                                        width?: number | null
                                                        height?: number | null
                                                        resolution?: number | null
                                                        colorDepth?: number | null
                                                        size?: number | null
                                                        mime?: string | null
                                                        extension?: string | null
                                                        allowCaching?: boolean | null
                                                        mediaType?: Types.MediaType | null
                                                        src?: string | null
                                                        language?: string | null
                                                      }
                                                    | {
                                                        __typename: "NoMediaInfo"
                                                        id: string
                                                        mediaType?: Types.MediaType | null
                                                      }
                                                    | {
                                                        __typename: "VectorGraphicsMediaInfo"
                                                        id: string
                                                        resourceId?: string | null
                                                        thumbnail?: string | null
                                                        width?: number | null
                                                        height?: number | null
                                                        allowCaching?: boolean | null
                                                        mediaType?: Types.MediaType | null
                                                        language?: string | null
                                                        labels: Array<{
                                                          __typename: "VectorGraphicsLabel"
                                                          id?: string | null
                                                          sequence?: number | null
                                                          autoValue?: string | null
                                                          text?: string | null
                                                          linkType?: Types.VectorGraphicsLabelLinkType | null
                                                          link?: string | null
                                                          ref?:
                                                            | {
                                                                __typename: "GenericRef"
                                                                id: string
                                                                owner?:
                                                                  | { __typename: "CommentNode" }
                                                                  | { __typename: "ExternalSite" }
                                                                  | { __typename: "ST4Class" }
                                                                  | { __typename: "ST4Node"; id: string }
                                                                  | { __typename: "ST4TreeNode" }
                                                                  | null
                                                                target?:
                                                                  | { __typename: "CommentNode" }
                                                                  | {
                                                                      __typename: "ExternalSite"
                                                                      id: string
                                                                      teaserData?: {
                                                                        __typename: "ExternalLinkTeaserData"
                                                                        title?: string | null
                                                                        summary?: string | null
                                                                        iconUrl?: string | null
                                                                        imageUrl?: string | null
                                                                        status?: Types.LinkTargetStatus | null
                                                                      } | null
                                                                    }
                                                                  | { __typename: "ST4Class" }
                                                                  | {
                                                                      __typename: "ST4Node"
                                                                      id: string
                                                                      aspectId: any
                                                                      label?: string | null
                                                                      properties?: {
                                                                        __typename: "ContentPropertyConnection"
                                                                        edges?: Array<{
                                                                          __typename: "ContentPropertyEdge"
                                                                          node?: {
                                                                            __typename: "ContentProperty"
                                                                            name: string
                                                                            value?: any | null
                                                                            displayName: string
                                                                          } | null
                                                                        } | null> | null
                                                                      } | null
                                                                    }
                                                                  | { __typename: "ST4TreeNode" }
                                                                  | null
                                                              }
                                                            | {
                                                                __typename: "NodeRef"
                                                                id: string
                                                                owner?:
                                                                  | { __typename: "CommentNode" }
                                                                  | { __typename: "ExternalSite" }
                                                                  | { __typename: "ST4Class" }
                                                                  | { __typename: "ST4Node"; id: string }
                                                                  | { __typename: "ST4TreeNode" }
                                                                  | null
                                                                target?:
                                                                  | { __typename: "CommentNode" }
                                                                  | {
                                                                      __typename: "ExternalSite"
                                                                      id: string
                                                                      teaserData?: {
                                                                        __typename: "ExternalLinkTeaserData"
                                                                        title?: string | null
                                                                        summary?: string | null
                                                                        iconUrl?: string | null
                                                                        imageUrl?: string | null
                                                                        status?: Types.LinkTargetStatus | null
                                                                      } | null
                                                                    }
                                                                  | { __typename: "ST4Class" }
                                                                  | {
                                                                      __typename: "ST4Node"
                                                                      id: string
                                                                      aspectId: any
                                                                      label?: string | null
                                                                      properties?: {
                                                                        __typename: "ContentPropertyConnection"
                                                                        edges?: Array<{
                                                                          __typename: "ContentPropertyEdge"
                                                                          node?: {
                                                                            __typename: "ContentProperty"
                                                                            name: string
                                                                            value?: any | null
                                                                            displayName: string
                                                                          } | null
                                                                        } | null> | null
                                                                      } | null
                                                                    }
                                                                  | { __typename: "ST4TreeNode" }
                                                                  | null
                                                              }
                                                            | null
                                                        }>
                                                      }
                                                    | {
                                                        __typename: "VideoMediaInfo"
                                                        id: string
                                                        resourceId?: string | null
                                                        thumbnail?: string | null
                                                        width?: number | null
                                                        height?: number | null
                                                        mediaType?: Types.MediaType | null
                                                      }
                                                    | null
                                                }
                                              | {
                                                  __typename: "MediaGroupContent"
                                                  media?:
                                                    | {
                                                        __typename: "AnimationMediaInfo"
                                                        id: string
                                                        resourceId?: string | null
                                                        thumbnail?: string | null
                                                        width?: number | null
                                                        height?: number | null
                                                        mediaType?: Types.MediaType | null
                                                      }
                                                    | {
                                                        __typename: "ImageMediaInfo"
                                                        id: string
                                                        resourceId?: string | null
                                                        thumbnail?: string | null
                                                        width?: number | null
                                                        height?: number | null
                                                        resolution?: number | null
                                                        colorDepth?: number | null
                                                        size?: number | null
                                                        mime?: string | null
                                                        extension?: string | null
                                                        allowCaching?: boolean | null
                                                        mediaType?: Types.MediaType | null
                                                        src?: string | null
                                                        language?: string | null
                                                      }
                                                    | {
                                                        __typename: "NoMediaInfo"
                                                        id: string
                                                        mediaType?: Types.MediaType | null
                                                      }
                                                    | {
                                                        __typename: "VectorGraphicsMediaInfo"
                                                        id: string
                                                        resourceId?: string | null
                                                        thumbnail?: string | null
                                                        width?: number | null
                                                        height?: number | null
                                                        allowCaching?: boolean | null
                                                        mediaType?: Types.MediaType | null
                                                        language?: string | null
                                                        labels: Array<{
                                                          __typename: "VectorGraphicsLabel"
                                                          id?: string | null
                                                          sequence?: number | null
                                                          autoValue?: string | null
                                                          text?: string | null
                                                          linkType?: Types.VectorGraphicsLabelLinkType | null
                                                          link?: string | null
                                                          ref?:
                                                            | {
                                                                __typename: "GenericRef"
                                                                id: string
                                                                owner?:
                                                                  | { __typename: "CommentNode" }
                                                                  | { __typename: "ExternalSite" }
                                                                  | { __typename: "ST4Class" }
                                                                  | { __typename: "ST4Node"; id: string }
                                                                  | { __typename: "ST4TreeNode" }
                                                                  | null
                                                                target?:
                                                                  | { __typename: "CommentNode" }
                                                                  | {
                                                                      __typename: "ExternalSite"
                                                                      id: string
                                                                      teaserData?: {
                                                                        __typename: "ExternalLinkTeaserData"
                                                                        title?: string | null
                                                                        summary?: string | null
                                                                        iconUrl?: string | null
                                                                        imageUrl?: string | null
                                                                        status?: Types.LinkTargetStatus | null
                                                                      } | null
                                                                    }
                                                                  | { __typename: "ST4Class" }
                                                                  | {
                                                                      __typename: "ST4Node"
                                                                      id: string
                                                                      aspectId: any
                                                                      label?: string | null
                                                                      properties?: {
                                                                        __typename: "ContentPropertyConnection"
                                                                        edges?: Array<{
                                                                          __typename: "ContentPropertyEdge"
                                                                          node?: {
                                                                            __typename: "ContentProperty"
                                                                            name: string
                                                                            value?: any | null
                                                                            displayName: string
                                                                          } | null
                                                                        } | null> | null
                                                                      } | null
                                                                    }
                                                                  | { __typename: "ST4TreeNode" }
                                                                  | null
                                                              }
                                                            | {
                                                                __typename: "NodeRef"
                                                                id: string
                                                                owner?:
                                                                  | { __typename: "CommentNode" }
                                                                  | { __typename: "ExternalSite" }
                                                                  | { __typename: "ST4Class" }
                                                                  | { __typename: "ST4Node"; id: string }
                                                                  | { __typename: "ST4TreeNode" }
                                                                  | null
                                                                target?:
                                                                  | { __typename: "CommentNode" }
                                                                  | {
                                                                      __typename: "ExternalSite"
                                                                      id: string
                                                                      teaserData?: {
                                                                        __typename: "ExternalLinkTeaserData"
                                                                        title?: string | null
                                                                        summary?: string | null
                                                                        iconUrl?: string | null
                                                                        imageUrl?: string | null
                                                                        status?: Types.LinkTargetStatus | null
                                                                      } | null
                                                                    }
                                                                  | { __typename: "ST4Class" }
                                                                  | {
                                                                      __typename: "ST4Node"
                                                                      id: string
                                                                      aspectId: any
                                                                      label?: string | null
                                                                      properties?: {
                                                                        __typename: "ContentPropertyConnection"
                                                                        edges?: Array<{
                                                                          __typename: "ContentPropertyEdge"
                                                                          node?: {
                                                                            __typename: "ContentProperty"
                                                                            name: string
                                                                            value?: any | null
                                                                            displayName: string
                                                                          } | null
                                                                        } | null> | null
                                                                      } | null
                                                                    }
                                                                  | { __typename: "ST4TreeNode" }
                                                                  | null
                                                              }
                                                            | null
                                                        }>
                                                      }
                                                    | {
                                                        __typename: "VideoMediaInfo"
                                                        id: string
                                                        resourceId?: string | null
                                                        thumbnail?: string | null
                                                        width?: number | null
                                                        height?: number | null
                                                        mediaType?: Types.MediaType | null
                                                      }
                                                    | null
                                                }
                                              | { __typename: "ProjectContent" }
                                              | { __typename: "TermContent" }
                                              | { __typename: "TextContent" }
                                              | { __typename: "TextGroupContent" }
                                              | { __typename: "VariableTableContent" }
                                              | null
                                          }
                                        | { __typename: "ST4TreeNode"; id: string }
                                        | null
                                    }>
                                    media?:
                                      | {
                                          __typename: "AnimationMediaInfo"
                                          id: string
                                          resourceId?: string | null
                                          thumbnail?: string | null
                                          width?: number | null
                                          height?: number | null
                                          mediaType?: Types.MediaType | null
                                        }
                                      | {
                                          __typename: "ImageMediaInfo"
                                          id: string
                                          resourceId?: string | null
                                          thumbnail?: string | null
                                          width?: number | null
                                          height?: number | null
                                          resolution?: number | null
                                          colorDepth?: number | null
                                          size?: number | null
                                          mime?: string | null
                                          extension?: string | null
                                          allowCaching?: boolean | null
                                          mediaType?: Types.MediaType | null
                                          src?: string | null
                                          language?: string | null
                                        }
                                      | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                                      | {
                                          __typename: "VectorGraphicsMediaInfo"
                                          id: string
                                          resourceId?: string | null
                                          thumbnail?: string | null
                                          width?: number | null
                                          height?: number | null
                                          allowCaching?: boolean | null
                                          mediaType?: Types.MediaType | null
                                          language?: string | null
                                          labels: Array<{
                                            __typename: "VectorGraphicsLabel"
                                            id?: string | null
                                            sequence?: number | null
                                            autoValue?: string | null
                                            text?: string | null
                                            linkType?: Types.VectorGraphicsLabelLinkType | null
                                            link?: string | null
                                            ref?:
                                              | {
                                                  __typename: "GenericRef"
                                                  id: string
                                                  owner?:
                                                    | { __typename: "CommentNode" }
                                                    | { __typename: "ExternalSite" }
                                                    | { __typename: "ST4Class" }
                                                    | { __typename: "ST4Node"; id: string }
                                                    | { __typename: "ST4TreeNode" }
                                                    | null
                                                  target?:
                                                    | { __typename: "CommentNode" }
                                                    | {
                                                        __typename: "ExternalSite"
                                                        id: string
                                                        teaserData?: {
                                                          __typename: "ExternalLinkTeaserData"
                                                          title?: string | null
                                                          summary?: string | null
                                                          iconUrl?: string | null
                                                          imageUrl?: string | null
                                                          status?: Types.LinkTargetStatus | null
                                                        } | null
                                                      }
                                                    | { __typename: "ST4Class" }
                                                    | {
                                                        __typename: "ST4Node"
                                                        id: string
                                                        aspectId: any
                                                        label?: string | null
                                                        properties?: {
                                                          __typename: "ContentPropertyConnection"
                                                          edges?: Array<{
                                                            __typename: "ContentPropertyEdge"
                                                            node?: {
                                                              __typename: "ContentProperty"
                                                              name: string
                                                              value?: any | null
                                                              displayName: string
                                                            } | null
                                                          } | null> | null
                                                        } | null
                                                      }
                                                    | { __typename: "ST4TreeNode" }
                                                    | null
                                                }
                                              | {
                                                  __typename: "NodeRef"
                                                  id: string
                                                  owner?:
                                                    | { __typename: "CommentNode" }
                                                    | { __typename: "ExternalSite" }
                                                    | { __typename: "ST4Class" }
                                                    | { __typename: "ST4Node"; id: string }
                                                    | { __typename: "ST4TreeNode" }
                                                    | null
                                                  target?:
                                                    | { __typename: "CommentNode" }
                                                    | {
                                                        __typename: "ExternalSite"
                                                        id: string
                                                        teaserData?: {
                                                          __typename: "ExternalLinkTeaserData"
                                                          title?: string | null
                                                          summary?: string | null
                                                          iconUrl?: string | null
                                                          imageUrl?: string | null
                                                          status?: Types.LinkTargetStatus | null
                                                        } | null
                                                      }
                                                    | { __typename: "ST4Class" }
                                                    | {
                                                        __typename: "ST4Node"
                                                        id: string
                                                        aspectId: any
                                                        label?: string | null
                                                        properties?: {
                                                          __typename: "ContentPropertyConnection"
                                                          edges?: Array<{
                                                            __typename: "ContentPropertyEdge"
                                                            node?: {
                                                              __typename: "ContentProperty"
                                                              name: string
                                                              value?: any | null
                                                              displayName: string
                                                            } | null
                                                          } | null> | null
                                                        } | null
                                                      }
                                                    | { __typename: "ST4TreeNode" }
                                                    | null
                                                }
                                              | null
                                          }>
                                        }
                                      | {
                                          __typename: "VideoMediaInfo"
                                          id: string
                                          resourceId?: string | null
                                          thumbnail?: string | null
                                          width?: number | null
                                          height?: number | null
                                          mediaType?: Types.MediaType | null
                                        }
                                      | null
                                  }
                                | { __typename: "ProjectContent" }
                                | { __typename: "TermContent" }
                                | { __typename: "TextContent" }
                                | { __typename: "TextGroupContent" }
                                | { __typename: "VariableTableContent" }
                                | null
                            }
                          | { __typename: "ST4TreeNode"; id: string }
                          | null
                      }>
                      variableTables?: Array<{
                        __typename: "VariableTableReference"
                        ref?: string | null
                        node?:
                          | { __typename: "CommentNode" }
                          | { __typename: "ExternalSite" }
                          | { __typename: "ST4Class" }
                          | {
                              __typename: "ST4Node"
                              id: string
                              label?: string | null
                              aspectId: any
                              content?:
                                | { __typename: "LexiconContent" }
                                | { __typename: "LexiconEntryContent" }
                                | { __typename: "MediaContent" }
                                | { __typename: "MediaGroupContent" }
                                | { __typename: "ProjectContent" }
                                | { __typename: "TermContent" }
                                | { __typename: "TextContent" }
                                | { __typename: "TextGroupContent" }
                                | {
                                    __typename: "VariableTableContent"
                                    xml?: string | null
                                    valueSets: Array<{ __typename: "ValueSet"; name: string; valueSetId: string }>
                                    variables: Array<{
                                      __typename: "Variable"
                                      name: string
                                      variableId: string
                                      values: Array<string>
                                    }>
                                  }
                                | null
                            }
                          | { __typename: "ST4TreeNode" }
                          | null
                      } | null> | null
                      linkedDataNodes: Array<{
                        __typename: "NodeRef"
                        id: string
                        target?:
                          | { __typename: "CommentNode"; id: string }
                          | { __typename: "ExternalSite"; id: string }
                          | { __typename: "ST4Class"; id: string }
                          | {
                              __typename: "ST4Node"
                              label?: string | null
                              id: string
                              metadata: Array<{
                                __typename: "MetaDataEntry"
                                label?: string | null
                                systemName: string
                                value?: string | null
                                values: Array<string>
                                users: Array<string>
                              }>
                              linkedOntologyNodes: Array<
                                | {
                                    __typename: "GenericRef"
                                    target?:
                                      | { __typename: "CommentNode" }
                                      | { __typename: "ExternalSite" }
                                      | { __typename: "ST4Class" }
                                      | { __typename: "ST4Node"; id: string; label?: string | null }
                                      | { __typename: "ST4TreeNode" }
                                      | null
                                  }
                                | {
                                    __typename: "NodeRef"
                                    linkClassName: string
                                    class?: { __typename: "ST4Class"; label?: string | null } | null
                                    target?:
                                      | { __typename: "CommentNode" }
                                      | { __typename: "ExternalSite" }
                                      | { __typename: "ST4Class" }
                                      | { __typename: "ST4Node"; id: string; label?: string | null }
                                      | { __typename: "ST4TreeNode" }
                                      | null
                                  }
                              >
                            }
                          | { __typename: "ST4TreeNode"; id: string }
                          | null
                      }>
                      lexiconEntries?: Array<{
                        __typename: "NodeRef"
                        id: string
                        target?:
                          | { __typename: "CommentNode"; id: string }
                          | { __typename: "ExternalSite"; id: string }
                          | { __typename: "ST4Class"; id: string }
                          | {
                              __typename: "ST4Node"
                              aspectId: any
                              label?: string | null
                              id: string
                              content?:
                                | { __typename: "LexiconContent" }
                                | {
                                    __typename: "LexiconEntryContent"
                                    descriptionXast?: string | null
                                    subject?: string | null
                                    sortKey?: string | null
                                    lexicon:
                                      | { __typename: "CommentNode"; id: string }
                                      | { __typename: "ExternalSite"; id: string }
                                      | { __typename: "ST4Class"; id: string }
                                      | { __typename: "ST4Node"; label?: string | null; id: string }
                                      | { __typename: "ST4TreeNode"; id: string }
                                    terms: Array<
                                      | { __typename: "CommentNode"; id: string }
                                      | { __typename: "ExternalSite"; id: string }
                                      | { __typename: "ST4Class"; id: string }
                                      | {
                                          __typename: "ST4Node"
                                          id: string
                                          label?: string | null
                                          aspectId: any
                                          content?:
                                            | { __typename: "LexiconContent" }
                                            | { __typename: "LexiconEntryContent" }
                                            | { __typename: "MediaContent" }
                                            | { __typename: "MediaGroupContent" }
                                            | { __typename: "ProjectContent" }
                                            | {
                                                __typename: "TermContent"
                                                gramaticalGender?: string | null
                                                validity?: string | null
                                                editLanguage?: string | null
                                              }
                                            | { __typename: "TextContent" }
                                            | { __typename: "TextGroupContent" }
                                            | { __typename: "VariableTableContent" }
                                            | null
                                        }
                                      | { __typename: "ST4TreeNode"; id: string }
                                    >
                                    image?:
                                      | { __typename: "CommentNode"; id: string }
                                      | { __typename: "ExternalSite"; id: string }
                                      | { __typename: "ST4Class"; id: string }
                                      | {
                                          __typename: "ST4Node"
                                          aspectId: any
                                          id: string
                                          content?:
                                            | { __typename: "LexiconContent" }
                                            | { __typename: "LexiconEntryContent" }
                                            | {
                                                __typename: "MediaContent"
                                                media?:
                                                  | {
                                                      __typename: "AnimationMediaInfo"
                                                      thumbnail?: string | null
                                                      mediaType?: Types.MediaType | null
                                                    }
                                                  | {
                                                      __typename: "ImageMediaInfo"
                                                      src?: string | null
                                                      thumbnail?: string | null
                                                      mediaType?: Types.MediaType | null
                                                    }
                                                  | { __typename: "NoMediaInfo" }
                                                  | {
                                                      __typename: "VectorGraphicsMediaInfo"
                                                      svg?: string | null
                                                      thumbnail?: string | null
                                                      mediaType?: Types.MediaType | null
                                                    }
                                                  | {
                                                      __typename: "VideoMediaInfo"
                                                      thumbnail?: string | null
                                                      mediaType?: Types.MediaType | null
                                                    }
                                                  | null
                                              }
                                            | { __typename: "MediaGroupContent" }
                                            | { __typename: "ProjectContent" }
                                            | { __typename: "TermContent" }
                                            | { __typename: "TextContent" }
                                            | { __typename: "TextGroupContent" }
                                            | { __typename: "VariableTableContent" }
                                            | null
                                        }
                                      | { __typename: "ST4TreeNode"; id: string }
                                      | null
                                  }
                                | { __typename: "MediaContent" }
                                | { __typename: "MediaGroupContent" }
                                | { __typename: "ProjectContent" }
                                | { __typename: "TermContent" }
                                | { __typename: "TextContent" }
                                | { __typename: "TextGroupContent" }
                                | { __typename: "VariableTableContent" }
                                | null
                            }
                          | { __typename: "ST4TreeNode"; id: string }
                          | null
                      } | null> | null
                      comments: Array<{
                        __typename: "CommentNode"
                        id: string
                        comment:
                          | {
                              __typename: "BlockComment"
                              location: number
                              value?: string | null
                              authorDisplayName?: string | null
                              authorUserName?: string | null
                              commentKey: string
                              editDate?: any | null
                              modificationVersion?: number | null
                              replyToKey?: string | null
                              sortOrder?: number | null
                              commentType?: string | null
                              state?: string | null
                              color: string
                              persistanceState?: Types.PersistanceState | null
                              persistanceAction?: Types.PersistanceAction | null
                              node: {
                                __typename: "ST4Node"
                                aspectId: any
                                id: string
                                content?:
                                  | { __typename: "LexiconContent" }
                                  | { __typename: "LexiconEntryContent" }
                                  | { __typename: "MediaContent" }
                                  | { __typename: "MediaGroupContent" }
                                  | { __typename: "ProjectContent" }
                                  | { __typename: "TermContent" }
                                  | { __typename: "TextContent"; id: string; historyNumber: number }
                                  | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                                  | { __typename: "VariableTableContent" }
                                  | null
                              }
                              actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
                            }
                          | {
                              __typename: "TextComment"
                              endLocation: number
                              startLocation?: number | null
                              value?: string | null
                              authorDisplayName?: string | null
                              authorUserName?: string | null
                              commentKey: string
                              editDate?: any | null
                              modificationVersion?: number | null
                              replyToKey?: string | null
                              sortOrder?: number | null
                              commentType?: string | null
                              state?: string | null
                              color: string
                              persistanceState?: Types.PersistanceState | null
                              persistanceAction?: Types.PersistanceAction | null
                              node: {
                                __typename: "ST4Node"
                                aspectId: any
                                id: string
                                content?:
                                  | { __typename: "LexiconContent" }
                                  | { __typename: "LexiconEntryContent" }
                                  | { __typename: "MediaContent" }
                                  | { __typename: "MediaGroupContent" }
                                  | { __typename: "ProjectContent" }
                                  | { __typename: "TermContent" }
                                  | { __typename: "TextContent"; id: string; historyNumber: number }
                                  | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                                  | { __typename: "VariableTableContent" }
                                  | null
                              }
                              actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
                            }
                      }>
                    }
                  | { __typename: "VariableTableContent" }
                  | null
              }
            }>
            links?: Array<{
              __typename: "TextContentLink"
              ref?: string | null
              node?:
                | { __typename: "CommentNode"; id: string }
                | { __typename: "ExternalSite"; id: string }
                | { __typename: "ST4Class"; id: string }
                | {
                    __typename: "ST4Node"
                    id: string
                    aspectId: any
                    label?: string | null
                    properties?: {
                      __typename: "ContentPropertyConnection"
                      totalCount: number
                      edges?: Array<{
                        __typename: "ContentPropertyEdge"
                        node?: {
                          __typename: "ContentProperty"
                          typeOfValue: Types.ContentPropertyValueType
                          name: string
                          value?: any | null
                          displayName: string
                        } | null
                      } | null> | null
                    } | null
                  }
                | { __typename: "ST4TreeNode"; id: string }
                | null
            } | null> | null
            images: Array<{
              __typename: "NodeRef"
              id: string
              target?:
                | { __typename: "CommentNode"; id: string }
                | { __typename: "ExternalSite"; id: string }
                | { __typename: "ST4Class"; id: string }
                | {
                    __typename: "ST4Node"
                    aspectId: any
                    id: string
                    releaseState: {
                      __typename: "ReleaseStateValue"
                      displayName: string
                      state: Types.ReleaseState
                      isImplicitlyReleased: boolean
                    }
                    content?:
                      | { __typename: "LexiconContent" }
                      | { __typename: "LexiconEntryContent" }
                      | {
                          __typename: "MediaContent"
                          media?:
                            | {
                                __typename: "AnimationMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                mediaType?: Types.MediaType | null
                              }
                            | {
                                __typename: "ImageMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                resolution?: number | null
                                colorDepth?: number | null
                                size?: number | null
                                mime?: string | null
                                extension?: string | null
                                allowCaching?: boolean | null
                                mediaType?: Types.MediaType | null
                                src?: string | null
                                language?: string | null
                              }
                            | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                            | {
                                __typename: "VectorGraphicsMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                allowCaching?: boolean | null
                                mediaType?: Types.MediaType | null
                                language?: string | null
                                labels: Array<{
                                  __typename: "VectorGraphicsLabel"
                                  id?: string | null
                                  sequence?: number | null
                                  autoValue?: string | null
                                  text?: string | null
                                  linkType?: Types.VectorGraphicsLabelLinkType | null
                                  link?: string | null
                                  ref?:
                                    | {
                                        __typename: "GenericRef"
                                        id: string
                                        owner?:
                                          | { __typename: "CommentNode" }
                                          | { __typename: "ExternalSite" }
                                          | { __typename: "ST4Class" }
                                          | { __typename: "ST4Node"; id: string }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                        target?:
                                          | { __typename: "CommentNode" }
                                          | {
                                              __typename: "ExternalSite"
                                              id: string
                                              teaserData?: {
                                                __typename: "ExternalLinkTeaserData"
                                                title?: string | null
                                                summary?: string | null
                                                iconUrl?: string | null
                                                imageUrl?: string | null
                                                status?: Types.LinkTargetStatus | null
                                              } | null
                                            }
                                          | { __typename: "ST4Class" }
                                          | {
                                              __typename: "ST4Node"
                                              id: string
                                              aspectId: any
                                              label?: string | null
                                              properties?: {
                                                __typename: "ContentPropertyConnection"
                                                edges?: Array<{
                                                  __typename: "ContentPropertyEdge"
                                                  node?: {
                                                    __typename: "ContentProperty"
                                                    name: string
                                                    value?: any | null
                                                    displayName: string
                                                  } | null
                                                } | null> | null
                                              } | null
                                            }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                      }
                                    | {
                                        __typename: "NodeRef"
                                        id: string
                                        owner?:
                                          | { __typename: "CommentNode" }
                                          | { __typename: "ExternalSite" }
                                          | { __typename: "ST4Class" }
                                          | { __typename: "ST4Node"; id: string }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                        target?:
                                          | { __typename: "CommentNode" }
                                          | {
                                              __typename: "ExternalSite"
                                              id: string
                                              teaserData?: {
                                                __typename: "ExternalLinkTeaserData"
                                                title?: string | null
                                                summary?: string | null
                                                iconUrl?: string | null
                                                imageUrl?: string | null
                                                status?: Types.LinkTargetStatus | null
                                              } | null
                                            }
                                          | { __typename: "ST4Class" }
                                          | {
                                              __typename: "ST4Node"
                                              id: string
                                              aspectId: any
                                              label?: string | null
                                              properties?: {
                                                __typename: "ContentPropertyConnection"
                                                edges?: Array<{
                                                  __typename: "ContentPropertyEdge"
                                                  node?: {
                                                    __typename: "ContentProperty"
                                                    name: string
                                                    value?: any | null
                                                    displayName: string
                                                  } | null
                                                } | null> | null
                                              } | null
                                            }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                      }
                                    | null
                                }>
                              }
                            | {
                                __typename: "VideoMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                mediaType?: Types.MediaType | null
                              }
                            | null
                        }
                      | {
                          __typename: "MediaGroupContent"
                          children: Array<{
                            __typename: "NodeRef"
                            id: string
                            target?:
                              | { __typename: "CommentNode"; id: string }
                              | { __typename: "ExternalSite"; id: string }
                              | { __typename: "ST4Class"; id: string }
                              | {
                                  __typename: "ST4Node"
                                  id: string
                                  aspectId: any
                                  content?:
                                    | { __typename: "LexiconContent" }
                                    | { __typename: "LexiconEntryContent" }
                                    | {
                                        __typename: "MediaContent"
                                        media?:
                                          | {
                                              __typename: "AnimationMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              mediaType?: Types.MediaType | null
                                            }
                                          | {
                                              __typename: "ImageMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              resolution?: number | null
                                              colorDepth?: number | null
                                              size?: number | null
                                              mime?: string | null
                                              extension?: string | null
                                              allowCaching?: boolean | null
                                              mediaType?: Types.MediaType | null
                                              src?: string | null
                                              language?: string | null
                                            }
                                          | {
                                              __typename: "NoMediaInfo"
                                              id: string
                                              mediaType?: Types.MediaType | null
                                            }
                                          | {
                                              __typename: "VectorGraphicsMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              allowCaching?: boolean | null
                                              mediaType?: Types.MediaType | null
                                              language?: string | null
                                              labels: Array<{
                                                __typename: "VectorGraphicsLabel"
                                                id?: string | null
                                                sequence?: number | null
                                                autoValue?: string | null
                                                text?: string | null
                                                linkType?: Types.VectorGraphicsLabelLinkType | null
                                                link?: string | null
                                                ref?:
                                                  | {
                                                      __typename: "GenericRef"
                                                      id: string
                                                      owner?:
                                                        | { __typename: "CommentNode" }
                                                        | { __typename: "ExternalSite" }
                                                        | { __typename: "ST4Class" }
                                                        | { __typename: "ST4Node"; id: string }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                      target?:
                                                        | { __typename: "CommentNode" }
                                                        | {
                                                            __typename: "ExternalSite"
                                                            id: string
                                                            teaserData?: {
                                                              __typename: "ExternalLinkTeaserData"
                                                              title?: string | null
                                                              summary?: string | null
                                                              iconUrl?: string | null
                                                              imageUrl?: string | null
                                                              status?: Types.LinkTargetStatus | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4Class" }
                                                        | {
                                                            __typename: "ST4Node"
                                                            id: string
                                                            aspectId: any
                                                            label?: string | null
                                                            properties?: {
                                                              __typename: "ContentPropertyConnection"
                                                              edges?: Array<{
                                                                __typename: "ContentPropertyEdge"
                                                                node?: {
                                                                  __typename: "ContentProperty"
                                                                  name: string
                                                                  value?: any | null
                                                                  displayName: string
                                                                } | null
                                                              } | null> | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                    }
                                                  | {
                                                      __typename: "NodeRef"
                                                      id: string
                                                      owner?:
                                                        | { __typename: "CommentNode" }
                                                        | { __typename: "ExternalSite" }
                                                        | { __typename: "ST4Class" }
                                                        | { __typename: "ST4Node"; id: string }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                      target?:
                                                        | { __typename: "CommentNode" }
                                                        | {
                                                            __typename: "ExternalSite"
                                                            id: string
                                                            teaserData?: {
                                                              __typename: "ExternalLinkTeaserData"
                                                              title?: string | null
                                                              summary?: string | null
                                                              iconUrl?: string | null
                                                              imageUrl?: string | null
                                                              status?: Types.LinkTargetStatus | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4Class" }
                                                        | {
                                                            __typename: "ST4Node"
                                                            id: string
                                                            aspectId: any
                                                            label?: string | null
                                                            properties?: {
                                                              __typename: "ContentPropertyConnection"
                                                              edges?: Array<{
                                                                __typename: "ContentPropertyEdge"
                                                                node?: {
                                                                  __typename: "ContentProperty"
                                                                  name: string
                                                                  value?: any | null
                                                                  displayName: string
                                                                } | null
                                                              } | null> | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                    }
                                                  | null
                                              }>
                                            }
                                          | {
                                              __typename: "VideoMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              mediaType?: Types.MediaType | null
                                            }
                                          | null
                                      }
                                    | {
                                        __typename: "MediaGroupContent"
                                        media?:
                                          | {
                                              __typename: "AnimationMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              mediaType?: Types.MediaType | null
                                            }
                                          | {
                                              __typename: "ImageMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              resolution?: number | null
                                              colorDepth?: number | null
                                              size?: number | null
                                              mime?: string | null
                                              extension?: string | null
                                              allowCaching?: boolean | null
                                              mediaType?: Types.MediaType | null
                                              src?: string | null
                                              language?: string | null
                                            }
                                          | {
                                              __typename: "NoMediaInfo"
                                              id: string
                                              mediaType?: Types.MediaType | null
                                            }
                                          | {
                                              __typename: "VectorGraphicsMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              allowCaching?: boolean | null
                                              mediaType?: Types.MediaType | null
                                              language?: string | null
                                              labels: Array<{
                                                __typename: "VectorGraphicsLabel"
                                                id?: string | null
                                                sequence?: number | null
                                                autoValue?: string | null
                                                text?: string | null
                                                linkType?: Types.VectorGraphicsLabelLinkType | null
                                                link?: string | null
                                                ref?:
                                                  | {
                                                      __typename: "GenericRef"
                                                      id: string
                                                      owner?:
                                                        | { __typename: "CommentNode" }
                                                        | { __typename: "ExternalSite" }
                                                        | { __typename: "ST4Class" }
                                                        | { __typename: "ST4Node"; id: string }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                      target?:
                                                        | { __typename: "CommentNode" }
                                                        | {
                                                            __typename: "ExternalSite"
                                                            id: string
                                                            teaserData?: {
                                                              __typename: "ExternalLinkTeaserData"
                                                              title?: string | null
                                                              summary?: string | null
                                                              iconUrl?: string | null
                                                              imageUrl?: string | null
                                                              status?: Types.LinkTargetStatus | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4Class" }
                                                        | {
                                                            __typename: "ST4Node"
                                                            id: string
                                                            aspectId: any
                                                            label?: string | null
                                                            properties?: {
                                                              __typename: "ContentPropertyConnection"
                                                              edges?: Array<{
                                                                __typename: "ContentPropertyEdge"
                                                                node?: {
                                                                  __typename: "ContentProperty"
                                                                  name: string
                                                                  value?: any | null
                                                                  displayName: string
                                                                } | null
                                                              } | null> | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                    }
                                                  | {
                                                      __typename: "NodeRef"
                                                      id: string
                                                      owner?:
                                                        | { __typename: "CommentNode" }
                                                        | { __typename: "ExternalSite" }
                                                        | { __typename: "ST4Class" }
                                                        | { __typename: "ST4Node"; id: string }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                      target?:
                                                        | { __typename: "CommentNode" }
                                                        | {
                                                            __typename: "ExternalSite"
                                                            id: string
                                                            teaserData?: {
                                                              __typename: "ExternalLinkTeaserData"
                                                              title?: string | null
                                                              summary?: string | null
                                                              iconUrl?: string | null
                                                              imageUrl?: string | null
                                                              status?: Types.LinkTargetStatus | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4Class" }
                                                        | {
                                                            __typename: "ST4Node"
                                                            id: string
                                                            aspectId: any
                                                            label?: string | null
                                                            properties?: {
                                                              __typename: "ContentPropertyConnection"
                                                              edges?: Array<{
                                                                __typename: "ContentPropertyEdge"
                                                                node?: {
                                                                  __typename: "ContentProperty"
                                                                  name: string
                                                                  value?: any | null
                                                                  displayName: string
                                                                } | null
                                                              } | null> | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                    }
                                                  | null
                                              }>
                                            }
                                          | {
                                              __typename: "VideoMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              mediaType?: Types.MediaType | null
                                            }
                                          | null
                                      }
                                    | { __typename: "ProjectContent" }
                                    | { __typename: "TermContent" }
                                    | { __typename: "TextContent" }
                                    | { __typename: "TextGroupContent" }
                                    | { __typename: "VariableTableContent" }
                                    | null
                                }
                              | { __typename: "ST4TreeNode"; id: string }
                              | null
                          }>
                          media?:
                            | {
                                __typename: "AnimationMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                mediaType?: Types.MediaType | null
                              }
                            | {
                                __typename: "ImageMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                resolution?: number | null
                                colorDepth?: number | null
                                size?: number | null
                                mime?: string | null
                                extension?: string | null
                                allowCaching?: boolean | null
                                mediaType?: Types.MediaType | null
                                src?: string | null
                                language?: string | null
                              }
                            | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                            | {
                                __typename: "VectorGraphicsMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                allowCaching?: boolean | null
                                mediaType?: Types.MediaType | null
                                language?: string | null
                                labels: Array<{
                                  __typename: "VectorGraphicsLabel"
                                  id?: string | null
                                  sequence?: number | null
                                  autoValue?: string | null
                                  text?: string | null
                                  linkType?: Types.VectorGraphicsLabelLinkType | null
                                  link?: string | null
                                  ref?:
                                    | {
                                        __typename: "GenericRef"
                                        id: string
                                        owner?:
                                          | { __typename: "CommentNode" }
                                          | { __typename: "ExternalSite" }
                                          | { __typename: "ST4Class" }
                                          | { __typename: "ST4Node"; id: string }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                        target?:
                                          | { __typename: "CommentNode" }
                                          | {
                                              __typename: "ExternalSite"
                                              id: string
                                              teaserData?: {
                                                __typename: "ExternalLinkTeaserData"
                                                title?: string | null
                                                summary?: string | null
                                                iconUrl?: string | null
                                                imageUrl?: string | null
                                                status?: Types.LinkTargetStatus | null
                                              } | null
                                            }
                                          | { __typename: "ST4Class" }
                                          | {
                                              __typename: "ST4Node"
                                              id: string
                                              aspectId: any
                                              label?: string | null
                                              properties?: {
                                                __typename: "ContentPropertyConnection"
                                                edges?: Array<{
                                                  __typename: "ContentPropertyEdge"
                                                  node?: {
                                                    __typename: "ContentProperty"
                                                    name: string
                                                    value?: any | null
                                                    displayName: string
                                                  } | null
                                                } | null> | null
                                              } | null
                                            }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                      }
                                    | {
                                        __typename: "NodeRef"
                                        id: string
                                        owner?:
                                          | { __typename: "CommentNode" }
                                          | { __typename: "ExternalSite" }
                                          | { __typename: "ST4Class" }
                                          | { __typename: "ST4Node"; id: string }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                        target?:
                                          | { __typename: "CommentNode" }
                                          | {
                                              __typename: "ExternalSite"
                                              id: string
                                              teaserData?: {
                                                __typename: "ExternalLinkTeaserData"
                                                title?: string | null
                                                summary?: string | null
                                                iconUrl?: string | null
                                                imageUrl?: string | null
                                                status?: Types.LinkTargetStatus | null
                                              } | null
                                            }
                                          | { __typename: "ST4Class" }
                                          | {
                                              __typename: "ST4Node"
                                              id: string
                                              aspectId: any
                                              label?: string | null
                                              properties?: {
                                                __typename: "ContentPropertyConnection"
                                                edges?: Array<{
                                                  __typename: "ContentPropertyEdge"
                                                  node?: {
                                                    __typename: "ContentProperty"
                                                    name: string
                                                    value?: any | null
                                                    displayName: string
                                                  } | null
                                                } | null> | null
                                              } | null
                                            }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                      }
                                    | null
                                }>
                              }
                            | {
                                __typename: "VideoMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                mediaType?: Types.MediaType | null
                              }
                            | null
                        }
                      | { __typename: "ProjectContent" }
                      | { __typename: "TermContent" }
                      | { __typename: "TextContent" }
                      | { __typename: "TextGroupContent" }
                      | { __typename: "VariableTableContent" }
                      | null
                  }
                | { __typename: "ST4TreeNode"; id: string }
                | null
            }>
            variableTables?: Array<{
              __typename: "VariableTableReference"
              ref?: string | null
              node?:
                | { __typename: "CommentNode" }
                | { __typename: "ExternalSite" }
                | { __typename: "ST4Class" }
                | {
                    __typename: "ST4Node"
                    id: string
                    label?: string | null
                    aspectId: any
                    content?:
                      | { __typename: "LexiconContent" }
                      | { __typename: "LexiconEntryContent" }
                      | { __typename: "MediaContent" }
                      | { __typename: "MediaGroupContent" }
                      | { __typename: "ProjectContent" }
                      | { __typename: "TermContent" }
                      | { __typename: "TextContent" }
                      | { __typename: "TextGroupContent" }
                      | {
                          __typename: "VariableTableContent"
                          xml?: string | null
                          valueSets: Array<{ __typename: "ValueSet"; name: string; valueSetId: string }>
                          variables: Array<{
                            __typename: "Variable"
                            name: string
                            variableId: string
                            values: Array<string>
                          }>
                        }
                      | null
                  }
                | { __typename: "ST4TreeNode" }
                | null
            } | null> | null
            linkedDataNodes: Array<{
              __typename: "NodeRef"
              id: string
              target?:
                | { __typename: "CommentNode"; id: string }
                | { __typename: "ExternalSite"; id: string }
                | { __typename: "ST4Class"; id: string }
                | {
                    __typename: "ST4Node"
                    label?: string | null
                    id: string
                    metadata: Array<{
                      __typename: "MetaDataEntry"
                      label?: string | null
                      systemName: string
                      value?: string | null
                      values: Array<string>
                      users: Array<string>
                    }>
                    linkedOntologyNodes: Array<
                      | {
                          __typename: "GenericRef"
                          target?:
                            | { __typename: "CommentNode" }
                            | { __typename: "ExternalSite" }
                            | { __typename: "ST4Class" }
                            | { __typename: "ST4Node"; id: string; label?: string | null }
                            | { __typename: "ST4TreeNode" }
                            | null
                        }
                      | {
                          __typename: "NodeRef"
                          linkClassName: string
                          class?: { __typename: "ST4Class"; label?: string | null } | null
                          target?:
                            | { __typename: "CommentNode" }
                            | { __typename: "ExternalSite" }
                            | { __typename: "ST4Class" }
                            | { __typename: "ST4Node"; id: string; label?: string | null }
                            | { __typename: "ST4TreeNode" }
                            | null
                        }
                    >
                  }
                | { __typename: "ST4TreeNode"; id: string }
                | null
            }>
            lexiconEntries?: Array<{
              __typename: "NodeRef"
              id: string
              target?:
                | { __typename: "CommentNode"; id: string }
                | { __typename: "ExternalSite"; id: string }
                | { __typename: "ST4Class"; id: string }
                | {
                    __typename: "ST4Node"
                    aspectId: any
                    label?: string | null
                    id: string
                    content?:
                      | { __typename: "LexiconContent" }
                      | {
                          __typename: "LexiconEntryContent"
                          descriptionXast?: string | null
                          subject?: string | null
                          sortKey?: string | null
                          lexicon:
                            | { __typename: "CommentNode"; id: string }
                            | { __typename: "ExternalSite"; id: string }
                            | { __typename: "ST4Class"; id: string }
                            | { __typename: "ST4Node"; label?: string | null; id: string }
                            | { __typename: "ST4TreeNode"; id: string }
                          terms: Array<
                            | { __typename: "CommentNode"; id: string }
                            | { __typename: "ExternalSite"; id: string }
                            | { __typename: "ST4Class"; id: string }
                            | {
                                __typename: "ST4Node"
                                id: string
                                label?: string | null
                                aspectId: any
                                content?:
                                  | { __typename: "LexiconContent" }
                                  | { __typename: "LexiconEntryContent" }
                                  | { __typename: "MediaContent" }
                                  | { __typename: "MediaGroupContent" }
                                  | { __typename: "ProjectContent" }
                                  | {
                                      __typename: "TermContent"
                                      gramaticalGender?: string | null
                                      validity?: string | null
                                      editLanguage?: string | null
                                    }
                                  | { __typename: "TextContent" }
                                  | { __typename: "TextGroupContent" }
                                  | { __typename: "VariableTableContent" }
                                  | null
                              }
                            | { __typename: "ST4TreeNode"; id: string }
                          >
                          image?:
                            | { __typename: "CommentNode"; id: string }
                            | { __typename: "ExternalSite"; id: string }
                            | { __typename: "ST4Class"; id: string }
                            | {
                                __typename: "ST4Node"
                                aspectId: any
                                id: string
                                content?:
                                  | { __typename: "LexiconContent" }
                                  | { __typename: "LexiconEntryContent" }
                                  | {
                                      __typename: "MediaContent"
                                      media?:
                                        | {
                                            __typename: "AnimationMediaInfo"
                                            thumbnail?: string | null
                                            mediaType?: Types.MediaType | null
                                          }
                                        | {
                                            __typename: "ImageMediaInfo"
                                            src?: string | null
                                            thumbnail?: string | null
                                            mediaType?: Types.MediaType | null
                                          }
                                        | { __typename: "NoMediaInfo" }
                                        | {
                                            __typename: "VectorGraphicsMediaInfo"
                                            svg?: string | null
                                            thumbnail?: string | null
                                            mediaType?: Types.MediaType | null
                                          }
                                        | {
                                            __typename: "VideoMediaInfo"
                                            thumbnail?: string | null
                                            mediaType?: Types.MediaType | null
                                          }
                                        | null
                                    }
                                  | { __typename: "MediaGroupContent" }
                                  | { __typename: "ProjectContent" }
                                  | { __typename: "TermContent" }
                                  | { __typename: "TextContent" }
                                  | { __typename: "TextGroupContent" }
                                  | { __typename: "VariableTableContent" }
                                  | null
                              }
                            | { __typename: "ST4TreeNode"; id: string }
                            | null
                        }
                      | { __typename: "MediaContent" }
                      | { __typename: "MediaGroupContent" }
                      | { __typename: "ProjectContent" }
                      | { __typename: "TermContent" }
                      | { __typename: "TextContent" }
                      | { __typename: "TextGroupContent" }
                      | { __typename: "VariableTableContent" }
                      | null
                  }
                | { __typename: "ST4TreeNode"; id: string }
                | null
            } | null> | null
            comments: Array<{
              __typename: "CommentNode"
              id: string
              comment:
                | {
                    __typename: "BlockComment"
                    location: number
                    value?: string | null
                    authorDisplayName?: string | null
                    authorUserName?: string | null
                    commentKey: string
                    editDate?: any | null
                    modificationVersion?: number | null
                    replyToKey?: string | null
                    sortOrder?: number | null
                    commentType?: string | null
                    state?: string | null
                    color: string
                    persistanceState?: Types.PersistanceState | null
                    persistanceAction?: Types.PersistanceAction | null
                    node: {
                      __typename: "ST4Node"
                      aspectId: any
                      id: string
                      content?:
                        | { __typename: "LexiconContent" }
                        | { __typename: "LexiconEntryContent" }
                        | { __typename: "MediaContent" }
                        | { __typename: "MediaGroupContent" }
                        | { __typename: "ProjectContent" }
                        | { __typename: "TermContent" }
                        | { __typename: "TextContent"; id: string; historyNumber: number }
                        | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                        | { __typename: "VariableTableContent" }
                        | null
                    }
                    actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
                  }
                | {
                    __typename: "TextComment"
                    endLocation: number
                    startLocation?: number | null
                    value?: string | null
                    authorDisplayName?: string | null
                    authorUserName?: string | null
                    commentKey: string
                    editDate?: any | null
                    modificationVersion?: number | null
                    replyToKey?: string | null
                    sortOrder?: number | null
                    commentType?: string | null
                    state?: string | null
                    color: string
                    persistanceState?: Types.PersistanceState | null
                    persistanceAction?: Types.PersistanceAction | null
                    node: {
                      __typename: "ST4Node"
                      aspectId: any
                      id: string
                      content?:
                        | { __typename: "LexiconContent" }
                        | { __typename: "LexiconEntryContent" }
                        | { __typename: "MediaContent" }
                        | { __typename: "MediaGroupContent" }
                        | { __typename: "ProjectContent" }
                        | { __typename: "TermContent" }
                        | { __typename: "TextContent"; id: string; historyNumber: number }
                        | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                        | { __typename: "VariableTableContent" }
                        | null
                    }
                    actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
                  }
            }>
          }
        | {
            __typename: "TextGroupContent"
            currentNodeId?: any | null
            groupNodeId: any
            id: string
            xast?: string | null
            historyNumber: number
            parts: Array<{
              __typename: "TextContentPart"
              ref: string
              contentNode: {
                __typename: "ST4Node"
                id: string
                aspectId: any
                content?:
                  | { __typename: "LexiconContent" }
                  | { __typename: "LexiconEntryContent" }
                  | { __typename: "MediaContent" }
                  | { __typename: "MediaGroupContent" }
                  | { __typename: "ProjectContent" }
                  | { __typename: "TermContent" }
                  | {
                      __typename: "TextContent"
                      id: string
                      xast?: string | null
                      historyNumber: number
                      links?: Array<{
                        __typename: "TextContentLink"
                        ref?: string | null
                        node?:
                          | { __typename: "CommentNode"; id: string }
                          | { __typename: "ExternalSite"; id: string }
                          | { __typename: "ST4Class"; id: string }
                          | {
                              __typename: "ST4Node"
                              id: string
                              aspectId: any
                              label?: string | null
                              properties?: {
                                __typename: "ContentPropertyConnection"
                                totalCount: number
                                edges?: Array<{
                                  __typename: "ContentPropertyEdge"
                                  node?: {
                                    __typename: "ContentProperty"
                                    typeOfValue: Types.ContentPropertyValueType
                                    name: string
                                    value?: any | null
                                    displayName: string
                                  } | null
                                } | null> | null
                              } | null
                            }
                          | { __typename: "ST4TreeNode"; id: string }
                          | null
                      } | null> | null
                      images: Array<{
                        __typename: "NodeRef"
                        id: string
                        target?:
                          | { __typename: "CommentNode"; id: string }
                          | { __typename: "ExternalSite"; id: string }
                          | { __typename: "ST4Class"; id: string }
                          | {
                              __typename: "ST4Node"
                              aspectId: any
                              id: string
                              releaseState: {
                                __typename: "ReleaseStateValue"
                                displayName: string
                                state: Types.ReleaseState
                                isImplicitlyReleased: boolean
                              }
                              content?:
                                | { __typename: "LexiconContent" }
                                | { __typename: "LexiconEntryContent" }
                                | {
                                    __typename: "MediaContent"
                                    media?:
                                      | {
                                          __typename: "AnimationMediaInfo"
                                          id: string
                                          resourceId?: string | null
                                          thumbnail?: string | null
                                          width?: number | null
                                          height?: number | null
                                          mediaType?: Types.MediaType | null
                                        }
                                      | {
                                          __typename: "ImageMediaInfo"
                                          id: string
                                          resourceId?: string | null
                                          thumbnail?: string | null
                                          width?: number | null
                                          height?: number | null
                                          resolution?: number | null
                                          colorDepth?: number | null
                                          size?: number | null
                                          mime?: string | null
                                          extension?: string | null
                                          allowCaching?: boolean | null
                                          mediaType?: Types.MediaType | null
                                          src?: string | null
                                          language?: string | null
                                        }
                                      | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                                      | {
                                          __typename: "VectorGraphicsMediaInfo"
                                          id: string
                                          resourceId?: string | null
                                          thumbnail?: string | null
                                          width?: number | null
                                          height?: number | null
                                          allowCaching?: boolean | null
                                          mediaType?: Types.MediaType | null
                                          language?: string | null
                                          labels: Array<{
                                            __typename: "VectorGraphicsLabel"
                                            id?: string | null
                                            sequence?: number | null
                                            autoValue?: string | null
                                            text?: string | null
                                            linkType?: Types.VectorGraphicsLabelLinkType | null
                                            link?: string | null
                                            ref?:
                                              | {
                                                  __typename: "GenericRef"
                                                  id: string
                                                  owner?:
                                                    | { __typename: "CommentNode" }
                                                    | { __typename: "ExternalSite" }
                                                    | { __typename: "ST4Class" }
                                                    | { __typename: "ST4Node"; id: string }
                                                    | { __typename: "ST4TreeNode" }
                                                    | null
                                                  target?:
                                                    | { __typename: "CommentNode" }
                                                    | {
                                                        __typename: "ExternalSite"
                                                        id: string
                                                        teaserData?: {
                                                          __typename: "ExternalLinkTeaserData"
                                                          title?: string | null
                                                          summary?: string | null
                                                          iconUrl?: string | null
                                                          imageUrl?: string | null
                                                          status?: Types.LinkTargetStatus | null
                                                        } | null
                                                      }
                                                    | { __typename: "ST4Class" }
                                                    | {
                                                        __typename: "ST4Node"
                                                        id: string
                                                        aspectId: any
                                                        label?: string | null
                                                        properties?: {
                                                          __typename: "ContentPropertyConnection"
                                                          edges?: Array<{
                                                            __typename: "ContentPropertyEdge"
                                                            node?: {
                                                              __typename: "ContentProperty"
                                                              name: string
                                                              value?: any | null
                                                              displayName: string
                                                            } | null
                                                          } | null> | null
                                                        } | null
                                                      }
                                                    | { __typename: "ST4TreeNode" }
                                                    | null
                                                }
                                              | {
                                                  __typename: "NodeRef"
                                                  id: string
                                                  owner?:
                                                    | { __typename: "CommentNode" }
                                                    | { __typename: "ExternalSite" }
                                                    | { __typename: "ST4Class" }
                                                    | { __typename: "ST4Node"; id: string }
                                                    | { __typename: "ST4TreeNode" }
                                                    | null
                                                  target?:
                                                    | { __typename: "CommentNode" }
                                                    | {
                                                        __typename: "ExternalSite"
                                                        id: string
                                                        teaserData?: {
                                                          __typename: "ExternalLinkTeaserData"
                                                          title?: string | null
                                                          summary?: string | null
                                                          iconUrl?: string | null
                                                          imageUrl?: string | null
                                                          status?: Types.LinkTargetStatus | null
                                                        } | null
                                                      }
                                                    | { __typename: "ST4Class" }
                                                    | {
                                                        __typename: "ST4Node"
                                                        id: string
                                                        aspectId: any
                                                        label?: string | null
                                                        properties?: {
                                                          __typename: "ContentPropertyConnection"
                                                          edges?: Array<{
                                                            __typename: "ContentPropertyEdge"
                                                            node?: {
                                                              __typename: "ContentProperty"
                                                              name: string
                                                              value?: any | null
                                                              displayName: string
                                                            } | null
                                                          } | null> | null
                                                        } | null
                                                      }
                                                    | { __typename: "ST4TreeNode" }
                                                    | null
                                                }
                                              | null
                                          }>
                                        }
                                      | {
                                          __typename: "VideoMediaInfo"
                                          id: string
                                          resourceId?: string | null
                                          thumbnail?: string | null
                                          width?: number | null
                                          height?: number | null
                                          mediaType?: Types.MediaType | null
                                        }
                                      | null
                                  }
                                | {
                                    __typename: "MediaGroupContent"
                                    children: Array<{
                                      __typename: "NodeRef"
                                      id: string
                                      target?:
                                        | { __typename: "CommentNode"; id: string }
                                        | { __typename: "ExternalSite"; id: string }
                                        | { __typename: "ST4Class"; id: string }
                                        | {
                                            __typename: "ST4Node"
                                            id: string
                                            aspectId: any
                                            content?:
                                              | { __typename: "LexiconContent" }
                                              | { __typename: "LexiconEntryContent" }
                                              | {
                                                  __typename: "MediaContent"
                                                  media?:
                                                    | {
                                                        __typename: "AnimationMediaInfo"
                                                        id: string
                                                        resourceId?: string | null
                                                        thumbnail?: string | null
                                                        width?: number | null
                                                        height?: number | null
                                                        mediaType?: Types.MediaType | null
                                                      }
                                                    | {
                                                        __typename: "ImageMediaInfo"
                                                        id: string
                                                        resourceId?: string | null
                                                        thumbnail?: string | null
                                                        width?: number | null
                                                        height?: number | null
                                                        resolution?: number | null
                                                        colorDepth?: number | null
                                                        size?: number | null
                                                        mime?: string | null
                                                        extension?: string | null
                                                        allowCaching?: boolean | null
                                                        mediaType?: Types.MediaType | null
                                                        src?: string | null
                                                        language?: string | null
                                                      }
                                                    | {
                                                        __typename: "NoMediaInfo"
                                                        id: string
                                                        mediaType?: Types.MediaType | null
                                                      }
                                                    | {
                                                        __typename: "VectorGraphicsMediaInfo"
                                                        id: string
                                                        resourceId?: string | null
                                                        thumbnail?: string | null
                                                        width?: number | null
                                                        height?: number | null
                                                        allowCaching?: boolean | null
                                                        mediaType?: Types.MediaType | null
                                                        language?: string | null
                                                        labels: Array<{
                                                          __typename: "VectorGraphicsLabel"
                                                          id?: string | null
                                                          sequence?: number | null
                                                          autoValue?: string | null
                                                          text?: string | null
                                                          linkType?: Types.VectorGraphicsLabelLinkType | null
                                                          link?: string | null
                                                          ref?:
                                                            | {
                                                                __typename: "GenericRef"
                                                                id: string
                                                                owner?:
                                                                  | { __typename: "CommentNode" }
                                                                  | { __typename: "ExternalSite" }
                                                                  | { __typename: "ST4Class" }
                                                                  | { __typename: "ST4Node"; id: string }
                                                                  | { __typename: "ST4TreeNode" }
                                                                  | null
                                                                target?:
                                                                  | { __typename: "CommentNode" }
                                                                  | {
                                                                      __typename: "ExternalSite"
                                                                      id: string
                                                                      teaserData?: {
                                                                        __typename: "ExternalLinkTeaserData"
                                                                        title?: string | null
                                                                        summary?: string | null
                                                                        iconUrl?: string | null
                                                                        imageUrl?: string | null
                                                                        status?: Types.LinkTargetStatus | null
                                                                      } | null
                                                                    }
                                                                  | { __typename: "ST4Class" }
                                                                  | {
                                                                      __typename: "ST4Node"
                                                                      id: string
                                                                      aspectId: any
                                                                      label?: string | null
                                                                      properties?: {
                                                                        __typename: "ContentPropertyConnection"
                                                                        edges?: Array<{
                                                                          __typename: "ContentPropertyEdge"
                                                                          node?: {
                                                                            __typename: "ContentProperty"
                                                                            name: string
                                                                            value?: any | null
                                                                            displayName: string
                                                                          } | null
                                                                        } | null> | null
                                                                      } | null
                                                                    }
                                                                  | { __typename: "ST4TreeNode" }
                                                                  | null
                                                              }
                                                            | {
                                                                __typename: "NodeRef"
                                                                id: string
                                                                owner?:
                                                                  | { __typename: "CommentNode" }
                                                                  | { __typename: "ExternalSite" }
                                                                  | { __typename: "ST4Class" }
                                                                  | { __typename: "ST4Node"; id: string }
                                                                  | { __typename: "ST4TreeNode" }
                                                                  | null
                                                                target?:
                                                                  | { __typename: "CommentNode" }
                                                                  | {
                                                                      __typename: "ExternalSite"
                                                                      id: string
                                                                      teaserData?: {
                                                                        __typename: "ExternalLinkTeaserData"
                                                                        title?: string | null
                                                                        summary?: string | null
                                                                        iconUrl?: string | null
                                                                        imageUrl?: string | null
                                                                        status?: Types.LinkTargetStatus | null
                                                                      } | null
                                                                    }
                                                                  | { __typename: "ST4Class" }
                                                                  | {
                                                                      __typename: "ST4Node"
                                                                      id: string
                                                                      aspectId: any
                                                                      label?: string | null
                                                                      properties?: {
                                                                        __typename: "ContentPropertyConnection"
                                                                        edges?: Array<{
                                                                          __typename: "ContentPropertyEdge"
                                                                          node?: {
                                                                            __typename: "ContentProperty"
                                                                            name: string
                                                                            value?: any | null
                                                                            displayName: string
                                                                          } | null
                                                                        } | null> | null
                                                                      } | null
                                                                    }
                                                                  | { __typename: "ST4TreeNode" }
                                                                  | null
                                                              }
                                                            | null
                                                        }>
                                                      }
                                                    | {
                                                        __typename: "VideoMediaInfo"
                                                        id: string
                                                        resourceId?: string | null
                                                        thumbnail?: string | null
                                                        width?: number | null
                                                        height?: number | null
                                                        mediaType?: Types.MediaType | null
                                                      }
                                                    | null
                                                }
                                              | {
                                                  __typename: "MediaGroupContent"
                                                  media?:
                                                    | {
                                                        __typename: "AnimationMediaInfo"
                                                        id: string
                                                        resourceId?: string | null
                                                        thumbnail?: string | null
                                                        width?: number | null
                                                        height?: number | null
                                                        mediaType?: Types.MediaType | null
                                                      }
                                                    | {
                                                        __typename: "ImageMediaInfo"
                                                        id: string
                                                        resourceId?: string | null
                                                        thumbnail?: string | null
                                                        width?: number | null
                                                        height?: number | null
                                                        resolution?: number | null
                                                        colorDepth?: number | null
                                                        size?: number | null
                                                        mime?: string | null
                                                        extension?: string | null
                                                        allowCaching?: boolean | null
                                                        mediaType?: Types.MediaType | null
                                                        src?: string | null
                                                        language?: string | null
                                                      }
                                                    | {
                                                        __typename: "NoMediaInfo"
                                                        id: string
                                                        mediaType?: Types.MediaType | null
                                                      }
                                                    | {
                                                        __typename: "VectorGraphicsMediaInfo"
                                                        id: string
                                                        resourceId?: string | null
                                                        thumbnail?: string | null
                                                        width?: number | null
                                                        height?: number | null
                                                        allowCaching?: boolean | null
                                                        mediaType?: Types.MediaType | null
                                                        language?: string | null
                                                        labels: Array<{
                                                          __typename: "VectorGraphicsLabel"
                                                          id?: string | null
                                                          sequence?: number | null
                                                          autoValue?: string | null
                                                          text?: string | null
                                                          linkType?: Types.VectorGraphicsLabelLinkType | null
                                                          link?: string | null
                                                          ref?:
                                                            | {
                                                                __typename: "GenericRef"
                                                                id: string
                                                                owner?:
                                                                  | { __typename: "CommentNode" }
                                                                  | { __typename: "ExternalSite" }
                                                                  | { __typename: "ST4Class" }
                                                                  | { __typename: "ST4Node"; id: string }
                                                                  | { __typename: "ST4TreeNode" }
                                                                  | null
                                                                target?:
                                                                  | { __typename: "CommentNode" }
                                                                  | {
                                                                      __typename: "ExternalSite"
                                                                      id: string
                                                                      teaserData?: {
                                                                        __typename: "ExternalLinkTeaserData"
                                                                        title?: string | null
                                                                        summary?: string | null
                                                                        iconUrl?: string | null
                                                                        imageUrl?: string | null
                                                                        status?: Types.LinkTargetStatus | null
                                                                      } | null
                                                                    }
                                                                  | { __typename: "ST4Class" }
                                                                  | {
                                                                      __typename: "ST4Node"
                                                                      id: string
                                                                      aspectId: any
                                                                      label?: string | null
                                                                      properties?: {
                                                                        __typename: "ContentPropertyConnection"
                                                                        edges?: Array<{
                                                                          __typename: "ContentPropertyEdge"
                                                                          node?: {
                                                                            __typename: "ContentProperty"
                                                                            name: string
                                                                            value?: any | null
                                                                            displayName: string
                                                                          } | null
                                                                        } | null> | null
                                                                      } | null
                                                                    }
                                                                  | { __typename: "ST4TreeNode" }
                                                                  | null
                                                              }
                                                            | {
                                                                __typename: "NodeRef"
                                                                id: string
                                                                owner?:
                                                                  | { __typename: "CommentNode" }
                                                                  | { __typename: "ExternalSite" }
                                                                  | { __typename: "ST4Class" }
                                                                  | { __typename: "ST4Node"; id: string }
                                                                  | { __typename: "ST4TreeNode" }
                                                                  | null
                                                                target?:
                                                                  | { __typename: "CommentNode" }
                                                                  | {
                                                                      __typename: "ExternalSite"
                                                                      id: string
                                                                      teaserData?: {
                                                                        __typename: "ExternalLinkTeaserData"
                                                                        title?: string | null
                                                                        summary?: string | null
                                                                        iconUrl?: string | null
                                                                        imageUrl?: string | null
                                                                        status?: Types.LinkTargetStatus | null
                                                                      } | null
                                                                    }
                                                                  | { __typename: "ST4Class" }
                                                                  | {
                                                                      __typename: "ST4Node"
                                                                      id: string
                                                                      aspectId: any
                                                                      label?: string | null
                                                                      properties?: {
                                                                        __typename: "ContentPropertyConnection"
                                                                        edges?: Array<{
                                                                          __typename: "ContentPropertyEdge"
                                                                          node?: {
                                                                            __typename: "ContentProperty"
                                                                            name: string
                                                                            value?: any | null
                                                                            displayName: string
                                                                          } | null
                                                                        } | null> | null
                                                                      } | null
                                                                    }
                                                                  | { __typename: "ST4TreeNode" }
                                                                  | null
                                                              }
                                                            | null
                                                        }>
                                                      }
                                                    | {
                                                        __typename: "VideoMediaInfo"
                                                        id: string
                                                        resourceId?: string | null
                                                        thumbnail?: string | null
                                                        width?: number | null
                                                        height?: number | null
                                                        mediaType?: Types.MediaType | null
                                                      }
                                                    | null
                                                }
                                              | { __typename: "ProjectContent" }
                                              | { __typename: "TermContent" }
                                              | { __typename: "TextContent" }
                                              | { __typename: "TextGroupContent" }
                                              | { __typename: "VariableTableContent" }
                                              | null
                                          }
                                        | { __typename: "ST4TreeNode"; id: string }
                                        | null
                                    }>
                                    media?:
                                      | {
                                          __typename: "AnimationMediaInfo"
                                          id: string
                                          resourceId?: string | null
                                          thumbnail?: string | null
                                          width?: number | null
                                          height?: number | null
                                          mediaType?: Types.MediaType | null
                                        }
                                      | {
                                          __typename: "ImageMediaInfo"
                                          id: string
                                          resourceId?: string | null
                                          thumbnail?: string | null
                                          width?: number | null
                                          height?: number | null
                                          resolution?: number | null
                                          colorDepth?: number | null
                                          size?: number | null
                                          mime?: string | null
                                          extension?: string | null
                                          allowCaching?: boolean | null
                                          mediaType?: Types.MediaType | null
                                          src?: string | null
                                          language?: string | null
                                        }
                                      | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                                      | {
                                          __typename: "VectorGraphicsMediaInfo"
                                          id: string
                                          resourceId?: string | null
                                          thumbnail?: string | null
                                          width?: number | null
                                          height?: number | null
                                          allowCaching?: boolean | null
                                          mediaType?: Types.MediaType | null
                                          language?: string | null
                                          labels: Array<{
                                            __typename: "VectorGraphicsLabel"
                                            id?: string | null
                                            sequence?: number | null
                                            autoValue?: string | null
                                            text?: string | null
                                            linkType?: Types.VectorGraphicsLabelLinkType | null
                                            link?: string | null
                                            ref?:
                                              | {
                                                  __typename: "GenericRef"
                                                  id: string
                                                  owner?:
                                                    | { __typename: "CommentNode" }
                                                    | { __typename: "ExternalSite" }
                                                    | { __typename: "ST4Class" }
                                                    | { __typename: "ST4Node"; id: string }
                                                    | { __typename: "ST4TreeNode" }
                                                    | null
                                                  target?:
                                                    | { __typename: "CommentNode" }
                                                    | {
                                                        __typename: "ExternalSite"
                                                        id: string
                                                        teaserData?: {
                                                          __typename: "ExternalLinkTeaserData"
                                                          title?: string | null
                                                          summary?: string | null
                                                          iconUrl?: string | null
                                                          imageUrl?: string | null
                                                          status?: Types.LinkTargetStatus | null
                                                        } | null
                                                      }
                                                    | { __typename: "ST4Class" }
                                                    | {
                                                        __typename: "ST4Node"
                                                        id: string
                                                        aspectId: any
                                                        label?: string | null
                                                        properties?: {
                                                          __typename: "ContentPropertyConnection"
                                                          edges?: Array<{
                                                            __typename: "ContentPropertyEdge"
                                                            node?: {
                                                              __typename: "ContentProperty"
                                                              name: string
                                                              value?: any | null
                                                              displayName: string
                                                            } | null
                                                          } | null> | null
                                                        } | null
                                                      }
                                                    | { __typename: "ST4TreeNode" }
                                                    | null
                                                }
                                              | {
                                                  __typename: "NodeRef"
                                                  id: string
                                                  owner?:
                                                    | { __typename: "CommentNode" }
                                                    | { __typename: "ExternalSite" }
                                                    | { __typename: "ST4Class" }
                                                    | { __typename: "ST4Node"; id: string }
                                                    | { __typename: "ST4TreeNode" }
                                                    | null
                                                  target?:
                                                    | { __typename: "CommentNode" }
                                                    | {
                                                        __typename: "ExternalSite"
                                                        id: string
                                                        teaserData?: {
                                                          __typename: "ExternalLinkTeaserData"
                                                          title?: string | null
                                                          summary?: string | null
                                                          iconUrl?: string | null
                                                          imageUrl?: string | null
                                                          status?: Types.LinkTargetStatus | null
                                                        } | null
                                                      }
                                                    | { __typename: "ST4Class" }
                                                    | {
                                                        __typename: "ST4Node"
                                                        id: string
                                                        aspectId: any
                                                        label?: string | null
                                                        properties?: {
                                                          __typename: "ContentPropertyConnection"
                                                          edges?: Array<{
                                                            __typename: "ContentPropertyEdge"
                                                            node?: {
                                                              __typename: "ContentProperty"
                                                              name: string
                                                              value?: any | null
                                                              displayName: string
                                                            } | null
                                                          } | null> | null
                                                        } | null
                                                      }
                                                    | { __typename: "ST4TreeNode" }
                                                    | null
                                                }
                                              | null
                                          }>
                                        }
                                      | {
                                          __typename: "VideoMediaInfo"
                                          id: string
                                          resourceId?: string | null
                                          thumbnail?: string | null
                                          width?: number | null
                                          height?: number | null
                                          mediaType?: Types.MediaType | null
                                        }
                                      | null
                                  }
                                | { __typename: "ProjectContent" }
                                | { __typename: "TermContent" }
                                | { __typename: "TextContent" }
                                | { __typename: "TextGroupContent" }
                                | { __typename: "VariableTableContent" }
                                | null
                            }
                          | { __typename: "ST4TreeNode"; id: string }
                          | null
                      }>
                      variableTables?: Array<{
                        __typename: "VariableTableReference"
                        ref?: string | null
                        node?:
                          | { __typename: "CommentNode" }
                          | { __typename: "ExternalSite" }
                          | { __typename: "ST4Class" }
                          | {
                              __typename: "ST4Node"
                              id: string
                              label?: string | null
                              aspectId: any
                              content?:
                                | { __typename: "LexiconContent" }
                                | { __typename: "LexiconEntryContent" }
                                | { __typename: "MediaContent" }
                                | { __typename: "MediaGroupContent" }
                                | { __typename: "ProjectContent" }
                                | { __typename: "TermContent" }
                                | { __typename: "TextContent" }
                                | { __typename: "TextGroupContent" }
                                | {
                                    __typename: "VariableTableContent"
                                    xml?: string | null
                                    valueSets: Array<{ __typename: "ValueSet"; name: string; valueSetId: string }>
                                    variables: Array<{
                                      __typename: "Variable"
                                      name: string
                                      variableId: string
                                      values: Array<string>
                                    }>
                                  }
                                | null
                            }
                          | { __typename: "ST4TreeNode" }
                          | null
                      } | null> | null
                      linkedDataNodes: Array<{
                        __typename: "NodeRef"
                        id: string
                        target?:
                          | { __typename: "CommentNode"; id: string }
                          | { __typename: "ExternalSite"; id: string }
                          | { __typename: "ST4Class"; id: string }
                          | {
                              __typename: "ST4Node"
                              label?: string | null
                              id: string
                              metadata: Array<{
                                __typename: "MetaDataEntry"
                                label?: string | null
                                systemName: string
                                value?: string | null
                                values: Array<string>
                                users: Array<string>
                              }>
                              linkedOntologyNodes: Array<
                                | {
                                    __typename: "GenericRef"
                                    target?:
                                      | { __typename: "CommentNode" }
                                      | { __typename: "ExternalSite" }
                                      | { __typename: "ST4Class" }
                                      | { __typename: "ST4Node"; id: string; label?: string | null }
                                      | { __typename: "ST4TreeNode" }
                                      | null
                                  }
                                | {
                                    __typename: "NodeRef"
                                    linkClassName: string
                                    class?: { __typename: "ST4Class"; label?: string | null } | null
                                    target?:
                                      | { __typename: "CommentNode" }
                                      | { __typename: "ExternalSite" }
                                      | { __typename: "ST4Class" }
                                      | { __typename: "ST4Node"; id: string; label?: string | null }
                                      | { __typename: "ST4TreeNode" }
                                      | null
                                  }
                              >
                            }
                          | { __typename: "ST4TreeNode"; id: string }
                          | null
                      }>
                      lexiconEntries?: Array<{
                        __typename: "NodeRef"
                        id: string
                        target?:
                          | { __typename: "CommentNode"; id: string }
                          | { __typename: "ExternalSite"; id: string }
                          | { __typename: "ST4Class"; id: string }
                          | {
                              __typename: "ST4Node"
                              aspectId: any
                              label?: string | null
                              id: string
                              content?:
                                | { __typename: "LexiconContent" }
                                | {
                                    __typename: "LexiconEntryContent"
                                    descriptionXast?: string | null
                                    subject?: string | null
                                    sortKey?: string | null
                                    lexicon:
                                      | { __typename: "CommentNode"; id: string }
                                      | { __typename: "ExternalSite"; id: string }
                                      | { __typename: "ST4Class"; id: string }
                                      | { __typename: "ST4Node"; label?: string | null; id: string }
                                      | { __typename: "ST4TreeNode"; id: string }
                                    terms: Array<
                                      | { __typename: "CommentNode"; id: string }
                                      | { __typename: "ExternalSite"; id: string }
                                      | { __typename: "ST4Class"; id: string }
                                      | {
                                          __typename: "ST4Node"
                                          id: string
                                          label?: string | null
                                          aspectId: any
                                          content?:
                                            | { __typename: "LexiconContent" }
                                            | { __typename: "LexiconEntryContent" }
                                            | { __typename: "MediaContent" }
                                            | { __typename: "MediaGroupContent" }
                                            | { __typename: "ProjectContent" }
                                            | {
                                                __typename: "TermContent"
                                                gramaticalGender?: string | null
                                                validity?: string | null
                                                editLanguage?: string | null
                                              }
                                            | { __typename: "TextContent" }
                                            | { __typename: "TextGroupContent" }
                                            | { __typename: "VariableTableContent" }
                                            | null
                                        }
                                      | { __typename: "ST4TreeNode"; id: string }
                                    >
                                    image?:
                                      | { __typename: "CommentNode"; id: string }
                                      | { __typename: "ExternalSite"; id: string }
                                      | { __typename: "ST4Class"; id: string }
                                      | {
                                          __typename: "ST4Node"
                                          aspectId: any
                                          id: string
                                          content?:
                                            | { __typename: "LexiconContent" }
                                            | { __typename: "LexiconEntryContent" }
                                            | {
                                                __typename: "MediaContent"
                                                media?:
                                                  | {
                                                      __typename: "AnimationMediaInfo"
                                                      thumbnail?: string | null
                                                      mediaType?: Types.MediaType | null
                                                    }
                                                  | {
                                                      __typename: "ImageMediaInfo"
                                                      src?: string | null
                                                      thumbnail?: string | null
                                                      mediaType?: Types.MediaType | null
                                                    }
                                                  | { __typename: "NoMediaInfo" }
                                                  | {
                                                      __typename: "VectorGraphicsMediaInfo"
                                                      svg?: string | null
                                                      thumbnail?: string | null
                                                      mediaType?: Types.MediaType | null
                                                    }
                                                  | {
                                                      __typename: "VideoMediaInfo"
                                                      thumbnail?: string | null
                                                      mediaType?: Types.MediaType | null
                                                    }
                                                  | null
                                              }
                                            | { __typename: "MediaGroupContent" }
                                            | { __typename: "ProjectContent" }
                                            | { __typename: "TermContent" }
                                            | { __typename: "TextContent" }
                                            | { __typename: "TextGroupContent" }
                                            | { __typename: "VariableTableContent" }
                                            | null
                                        }
                                      | { __typename: "ST4TreeNode"; id: string }
                                      | null
                                  }
                                | { __typename: "MediaContent" }
                                | { __typename: "MediaGroupContent" }
                                | { __typename: "ProjectContent" }
                                | { __typename: "TermContent" }
                                | { __typename: "TextContent" }
                                | { __typename: "TextGroupContent" }
                                | { __typename: "VariableTableContent" }
                                | null
                            }
                          | { __typename: "ST4TreeNode"; id: string }
                          | null
                      } | null> | null
                      comments: Array<{
                        __typename: "CommentNode"
                        id: string
                        comment:
                          | {
                              __typename: "BlockComment"
                              location: number
                              value?: string | null
                              authorDisplayName?: string | null
                              authorUserName?: string | null
                              commentKey: string
                              editDate?: any | null
                              modificationVersion?: number | null
                              replyToKey?: string | null
                              sortOrder?: number | null
                              commentType?: string | null
                              state?: string | null
                              color: string
                              persistanceState?: Types.PersistanceState | null
                              persistanceAction?: Types.PersistanceAction | null
                              node: {
                                __typename: "ST4Node"
                                aspectId: any
                                id: string
                                content?:
                                  | { __typename: "LexiconContent" }
                                  | { __typename: "LexiconEntryContent" }
                                  | { __typename: "MediaContent" }
                                  | { __typename: "MediaGroupContent" }
                                  | { __typename: "ProjectContent" }
                                  | { __typename: "TermContent" }
                                  | { __typename: "TextContent"; id: string; historyNumber: number }
                                  | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                                  | { __typename: "VariableTableContent" }
                                  | null
                              }
                              actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
                            }
                          | {
                              __typename: "TextComment"
                              endLocation: number
                              startLocation?: number | null
                              value?: string | null
                              authorDisplayName?: string | null
                              authorUserName?: string | null
                              commentKey: string
                              editDate?: any | null
                              modificationVersion?: number | null
                              replyToKey?: string | null
                              sortOrder?: number | null
                              commentType?: string | null
                              state?: string | null
                              color: string
                              persistanceState?: Types.PersistanceState | null
                              persistanceAction?: Types.PersistanceAction | null
                              node: {
                                __typename: "ST4Node"
                                aspectId: any
                                id: string
                                content?:
                                  | { __typename: "LexiconContent" }
                                  | { __typename: "LexiconEntryContent" }
                                  | { __typename: "MediaContent" }
                                  | { __typename: "MediaGroupContent" }
                                  | { __typename: "ProjectContent" }
                                  | { __typename: "TermContent" }
                                  | { __typename: "TextContent"; id: string; historyNumber: number }
                                  | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                                  | { __typename: "VariableTableContent" }
                                  | null
                              }
                              actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
                            }
                      }>
                    }
                  | {
                      __typename: "TextGroupContent"
                      currentNodeId?: any | null
                      groupNodeId: any
                      id: string
                      xast?: string | null
                      historyNumber: number
                      children: Array<{
                        __typename: "NodeRef"
                        id: string
                        target?:
                          | { __typename: "CommentNode"; id: string }
                          | { __typename: "ExternalSite"; id: string }
                          | { __typename: "ST4Class"; id: string }
                          | { __typename: "ST4Node"; label?: string | null; aspectId: any; id: string }
                          | { __typename: "ST4TreeNode"; id: string }
                          | null
                      }>
                      links?: Array<{
                        __typename: "TextContentLink"
                        ref?: string | null
                        node?:
                          | { __typename: "CommentNode"; id: string }
                          | { __typename: "ExternalSite"; id: string }
                          | { __typename: "ST4Class"; id: string }
                          | {
                              __typename: "ST4Node"
                              id: string
                              aspectId: any
                              label?: string | null
                              properties?: {
                                __typename: "ContentPropertyConnection"
                                totalCount: number
                                edges?: Array<{
                                  __typename: "ContentPropertyEdge"
                                  node?: {
                                    __typename: "ContentProperty"
                                    typeOfValue: Types.ContentPropertyValueType
                                    name: string
                                    value?: any | null
                                    displayName: string
                                  } | null
                                } | null> | null
                              } | null
                            }
                          | { __typename: "ST4TreeNode"; id: string }
                          | null
                      } | null> | null
                      images: Array<{
                        __typename: "NodeRef"
                        id: string
                        target?:
                          | { __typename: "CommentNode"; id: string }
                          | { __typename: "ExternalSite"; id: string }
                          | { __typename: "ST4Class"; id: string }
                          | {
                              __typename: "ST4Node"
                              aspectId: any
                              id: string
                              releaseState: {
                                __typename: "ReleaseStateValue"
                                displayName: string
                                state: Types.ReleaseState
                                isImplicitlyReleased: boolean
                              }
                              content?:
                                | { __typename: "LexiconContent" }
                                | { __typename: "LexiconEntryContent" }
                                | {
                                    __typename: "MediaContent"
                                    media?:
                                      | {
                                          __typename: "AnimationMediaInfo"
                                          id: string
                                          resourceId?: string | null
                                          thumbnail?: string | null
                                          width?: number | null
                                          height?: number | null
                                          mediaType?: Types.MediaType | null
                                        }
                                      | {
                                          __typename: "ImageMediaInfo"
                                          id: string
                                          resourceId?: string | null
                                          thumbnail?: string | null
                                          width?: number | null
                                          height?: number | null
                                          resolution?: number | null
                                          colorDepth?: number | null
                                          size?: number | null
                                          mime?: string | null
                                          extension?: string | null
                                          allowCaching?: boolean | null
                                          mediaType?: Types.MediaType | null
                                          src?: string | null
                                          language?: string | null
                                        }
                                      | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                                      | {
                                          __typename: "VectorGraphicsMediaInfo"
                                          id: string
                                          resourceId?: string | null
                                          thumbnail?: string | null
                                          width?: number | null
                                          height?: number | null
                                          allowCaching?: boolean | null
                                          mediaType?: Types.MediaType | null
                                          language?: string | null
                                          labels: Array<{
                                            __typename: "VectorGraphicsLabel"
                                            id?: string | null
                                            sequence?: number | null
                                            autoValue?: string | null
                                            text?: string | null
                                            linkType?: Types.VectorGraphicsLabelLinkType | null
                                            link?: string | null
                                            ref?:
                                              | {
                                                  __typename: "GenericRef"
                                                  id: string
                                                  owner?:
                                                    | { __typename: "CommentNode" }
                                                    | { __typename: "ExternalSite" }
                                                    | { __typename: "ST4Class" }
                                                    | { __typename: "ST4Node"; id: string }
                                                    | { __typename: "ST4TreeNode" }
                                                    | null
                                                  target?:
                                                    | { __typename: "CommentNode" }
                                                    | {
                                                        __typename: "ExternalSite"
                                                        id: string
                                                        teaserData?: {
                                                          __typename: "ExternalLinkTeaserData"
                                                          title?: string | null
                                                          summary?: string | null
                                                          iconUrl?: string | null
                                                          imageUrl?: string | null
                                                          status?: Types.LinkTargetStatus | null
                                                        } | null
                                                      }
                                                    | { __typename: "ST4Class" }
                                                    | {
                                                        __typename: "ST4Node"
                                                        id: string
                                                        aspectId: any
                                                        label?: string | null
                                                        properties?: {
                                                          __typename: "ContentPropertyConnection"
                                                          edges?: Array<{
                                                            __typename: "ContentPropertyEdge"
                                                            node?: {
                                                              __typename: "ContentProperty"
                                                              name: string
                                                              value?: any | null
                                                              displayName: string
                                                            } | null
                                                          } | null> | null
                                                        } | null
                                                      }
                                                    | { __typename: "ST4TreeNode" }
                                                    | null
                                                }
                                              | {
                                                  __typename: "NodeRef"
                                                  id: string
                                                  owner?:
                                                    | { __typename: "CommentNode" }
                                                    | { __typename: "ExternalSite" }
                                                    | { __typename: "ST4Class" }
                                                    | { __typename: "ST4Node"; id: string }
                                                    | { __typename: "ST4TreeNode" }
                                                    | null
                                                  target?:
                                                    | { __typename: "CommentNode" }
                                                    | {
                                                        __typename: "ExternalSite"
                                                        id: string
                                                        teaserData?: {
                                                          __typename: "ExternalLinkTeaserData"
                                                          title?: string | null
                                                          summary?: string | null
                                                          iconUrl?: string | null
                                                          imageUrl?: string | null
                                                          status?: Types.LinkTargetStatus | null
                                                        } | null
                                                      }
                                                    | { __typename: "ST4Class" }
                                                    | {
                                                        __typename: "ST4Node"
                                                        id: string
                                                        aspectId: any
                                                        label?: string | null
                                                        properties?: {
                                                          __typename: "ContentPropertyConnection"
                                                          edges?: Array<{
                                                            __typename: "ContentPropertyEdge"
                                                            node?: {
                                                              __typename: "ContentProperty"
                                                              name: string
                                                              value?: any | null
                                                              displayName: string
                                                            } | null
                                                          } | null> | null
                                                        } | null
                                                      }
                                                    | { __typename: "ST4TreeNode" }
                                                    | null
                                                }
                                              | null
                                          }>
                                        }
                                      | {
                                          __typename: "VideoMediaInfo"
                                          id: string
                                          resourceId?: string | null
                                          thumbnail?: string | null
                                          width?: number | null
                                          height?: number | null
                                          mediaType?: Types.MediaType | null
                                        }
                                      | null
                                  }
                                | {
                                    __typename: "MediaGroupContent"
                                    children: Array<{
                                      __typename: "NodeRef"
                                      id: string
                                      target?:
                                        | { __typename: "CommentNode"; id: string }
                                        | { __typename: "ExternalSite"; id: string }
                                        | { __typename: "ST4Class"; id: string }
                                        | {
                                            __typename: "ST4Node"
                                            id: string
                                            aspectId: any
                                            content?:
                                              | { __typename: "LexiconContent" }
                                              | { __typename: "LexiconEntryContent" }
                                              | {
                                                  __typename: "MediaContent"
                                                  media?:
                                                    | {
                                                        __typename: "AnimationMediaInfo"
                                                        id: string
                                                        resourceId?: string | null
                                                        thumbnail?: string | null
                                                        width?: number | null
                                                        height?: number | null
                                                        mediaType?: Types.MediaType | null
                                                      }
                                                    | {
                                                        __typename: "ImageMediaInfo"
                                                        id: string
                                                        resourceId?: string | null
                                                        thumbnail?: string | null
                                                        width?: number | null
                                                        height?: number | null
                                                        resolution?: number | null
                                                        colorDepth?: number | null
                                                        size?: number | null
                                                        mime?: string | null
                                                        extension?: string | null
                                                        allowCaching?: boolean | null
                                                        mediaType?: Types.MediaType | null
                                                        src?: string | null
                                                        language?: string | null
                                                      }
                                                    | {
                                                        __typename: "NoMediaInfo"
                                                        id: string
                                                        mediaType?: Types.MediaType | null
                                                      }
                                                    | {
                                                        __typename: "VectorGraphicsMediaInfo"
                                                        id: string
                                                        resourceId?: string | null
                                                        thumbnail?: string | null
                                                        width?: number | null
                                                        height?: number | null
                                                        allowCaching?: boolean | null
                                                        mediaType?: Types.MediaType | null
                                                        language?: string | null
                                                        labels: Array<{
                                                          __typename: "VectorGraphicsLabel"
                                                          id?: string | null
                                                          sequence?: number | null
                                                          autoValue?: string | null
                                                          text?: string | null
                                                          linkType?: Types.VectorGraphicsLabelLinkType | null
                                                          link?: string | null
                                                          ref?:
                                                            | {
                                                                __typename: "GenericRef"
                                                                id: string
                                                                owner?:
                                                                  | { __typename: "CommentNode" }
                                                                  | { __typename: "ExternalSite" }
                                                                  | { __typename: "ST4Class" }
                                                                  | { __typename: "ST4Node"; id: string }
                                                                  | { __typename: "ST4TreeNode" }
                                                                  | null
                                                                target?:
                                                                  | { __typename: "CommentNode" }
                                                                  | {
                                                                      __typename: "ExternalSite"
                                                                      id: string
                                                                      teaserData?: {
                                                                        __typename: "ExternalLinkTeaserData"
                                                                        title?: string | null
                                                                        summary?: string | null
                                                                        iconUrl?: string | null
                                                                        imageUrl?: string | null
                                                                        status?: Types.LinkTargetStatus | null
                                                                      } | null
                                                                    }
                                                                  | { __typename: "ST4Class" }
                                                                  | {
                                                                      __typename: "ST4Node"
                                                                      id: string
                                                                      aspectId: any
                                                                      label?: string | null
                                                                      properties?: {
                                                                        __typename: "ContentPropertyConnection"
                                                                        edges?: Array<{
                                                                          __typename: "ContentPropertyEdge"
                                                                          node?: {
                                                                            __typename: "ContentProperty"
                                                                            name: string
                                                                            value?: any | null
                                                                            displayName: string
                                                                          } | null
                                                                        } | null> | null
                                                                      } | null
                                                                    }
                                                                  | { __typename: "ST4TreeNode" }
                                                                  | null
                                                              }
                                                            | {
                                                                __typename: "NodeRef"
                                                                id: string
                                                                owner?:
                                                                  | { __typename: "CommentNode" }
                                                                  | { __typename: "ExternalSite" }
                                                                  | { __typename: "ST4Class" }
                                                                  | { __typename: "ST4Node"; id: string }
                                                                  | { __typename: "ST4TreeNode" }
                                                                  | null
                                                                target?:
                                                                  | { __typename: "CommentNode" }
                                                                  | {
                                                                      __typename: "ExternalSite"
                                                                      id: string
                                                                      teaserData?: {
                                                                        __typename: "ExternalLinkTeaserData"
                                                                        title?: string | null
                                                                        summary?: string | null
                                                                        iconUrl?: string | null
                                                                        imageUrl?: string | null
                                                                        status?: Types.LinkTargetStatus | null
                                                                      } | null
                                                                    }
                                                                  | { __typename: "ST4Class" }
                                                                  | {
                                                                      __typename: "ST4Node"
                                                                      id: string
                                                                      aspectId: any
                                                                      label?: string | null
                                                                      properties?: {
                                                                        __typename: "ContentPropertyConnection"
                                                                        edges?: Array<{
                                                                          __typename: "ContentPropertyEdge"
                                                                          node?: {
                                                                            __typename: "ContentProperty"
                                                                            name: string
                                                                            value?: any | null
                                                                            displayName: string
                                                                          } | null
                                                                        } | null> | null
                                                                      } | null
                                                                    }
                                                                  | { __typename: "ST4TreeNode" }
                                                                  | null
                                                              }
                                                            | null
                                                        }>
                                                      }
                                                    | {
                                                        __typename: "VideoMediaInfo"
                                                        id: string
                                                        resourceId?: string | null
                                                        thumbnail?: string | null
                                                        width?: number | null
                                                        height?: number | null
                                                        mediaType?: Types.MediaType | null
                                                      }
                                                    | null
                                                }
                                              | {
                                                  __typename: "MediaGroupContent"
                                                  media?:
                                                    | {
                                                        __typename: "AnimationMediaInfo"
                                                        id: string
                                                        resourceId?: string | null
                                                        thumbnail?: string | null
                                                        width?: number | null
                                                        height?: number | null
                                                        mediaType?: Types.MediaType | null
                                                      }
                                                    | {
                                                        __typename: "ImageMediaInfo"
                                                        id: string
                                                        resourceId?: string | null
                                                        thumbnail?: string | null
                                                        width?: number | null
                                                        height?: number | null
                                                        resolution?: number | null
                                                        colorDepth?: number | null
                                                        size?: number | null
                                                        mime?: string | null
                                                        extension?: string | null
                                                        allowCaching?: boolean | null
                                                        mediaType?: Types.MediaType | null
                                                        src?: string | null
                                                        language?: string | null
                                                      }
                                                    | {
                                                        __typename: "NoMediaInfo"
                                                        id: string
                                                        mediaType?: Types.MediaType | null
                                                      }
                                                    | {
                                                        __typename: "VectorGraphicsMediaInfo"
                                                        id: string
                                                        resourceId?: string | null
                                                        thumbnail?: string | null
                                                        width?: number | null
                                                        height?: number | null
                                                        allowCaching?: boolean | null
                                                        mediaType?: Types.MediaType | null
                                                        language?: string | null
                                                        labels: Array<{
                                                          __typename: "VectorGraphicsLabel"
                                                          id?: string | null
                                                          sequence?: number | null
                                                          autoValue?: string | null
                                                          text?: string | null
                                                          linkType?: Types.VectorGraphicsLabelLinkType | null
                                                          link?: string | null
                                                          ref?:
                                                            | {
                                                                __typename: "GenericRef"
                                                                id: string
                                                                owner?:
                                                                  | { __typename: "CommentNode" }
                                                                  | { __typename: "ExternalSite" }
                                                                  | { __typename: "ST4Class" }
                                                                  | { __typename: "ST4Node"; id: string }
                                                                  | { __typename: "ST4TreeNode" }
                                                                  | null
                                                                target?:
                                                                  | { __typename: "CommentNode" }
                                                                  | {
                                                                      __typename: "ExternalSite"
                                                                      id: string
                                                                      teaserData?: {
                                                                        __typename: "ExternalLinkTeaserData"
                                                                        title?: string | null
                                                                        summary?: string | null
                                                                        iconUrl?: string | null
                                                                        imageUrl?: string | null
                                                                        status?: Types.LinkTargetStatus | null
                                                                      } | null
                                                                    }
                                                                  | { __typename: "ST4Class" }
                                                                  | {
                                                                      __typename: "ST4Node"
                                                                      id: string
                                                                      aspectId: any
                                                                      label?: string | null
                                                                      properties?: {
                                                                        __typename: "ContentPropertyConnection"
                                                                        edges?: Array<{
                                                                          __typename: "ContentPropertyEdge"
                                                                          node?: {
                                                                            __typename: "ContentProperty"
                                                                            name: string
                                                                            value?: any | null
                                                                            displayName: string
                                                                          } | null
                                                                        } | null> | null
                                                                      } | null
                                                                    }
                                                                  | { __typename: "ST4TreeNode" }
                                                                  | null
                                                              }
                                                            | {
                                                                __typename: "NodeRef"
                                                                id: string
                                                                owner?:
                                                                  | { __typename: "CommentNode" }
                                                                  | { __typename: "ExternalSite" }
                                                                  | { __typename: "ST4Class" }
                                                                  | { __typename: "ST4Node"; id: string }
                                                                  | { __typename: "ST4TreeNode" }
                                                                  | null
                                                                target?:
                                                                  | { __typename: "CommentNode" }
                                                                  | {
                                                                      __typename: "ExternalSite"
                                                                      id: string
                                                                      teaserData?: {
                                                                        __typename: "ExternalLinkTeaserData"
                                                                        title?: string | null
                                                                        summary?: string | null
                                                                        iconUrl?: string | null
                                                                        imageUrl?: string | null
                                                                        status?: Types.LinkTargetStatus | null
                                                                      } | null
                                                                    }
                                                                  | { __typename: "ST4Class" }
                                                                  | {
                                                                      __typename: "ST4Node"
                                                                      id: string
                                                                      aspectId: any
                                                                      label?: string | null
                                                                      properties?: {
                                                                        __typename: "ContentPropertyConnection"
                                                                        edges?: Array<{
                                                                          __typename: "ContentPropertyEdge"
                                                                          node?: {
                                                                            __typename: "ContentProperty"
                                                                            name: string
                                                                            value?: any | null
                                                                            displayName: string
                                                                          } | null
                                                                        } | null> | null
                                                                      } | null
                                                                    }
                                                                  | { __typename: "ST4TreeNode" }
                                                                  | null
                                                              }
                                                            | null
                                                        }>
                                                      }
                                                    | {
                                                        __typename: "VideoMediaInfo"
                                                        id: string
                                                        resourceId?: string | null
                                                        thumbnail?: string | null
                                                        width?: number | null
                                                        height?: number | null
                                                        mediaType?: Types.MediaType | null
                                                      }
                                                    | null
                                                }
                                              | { __typename: "ProjectContent" }
                                              | { __typename: "TermContent" }
                                              | { __typename: "TextContent" }
                                              | { __typename: "TextGroupContent" }
                                              | { __typename: "VariableTableContent" }
                                              | null
                                          }
                                        | { __typename: "ST4TreeNode"; id: string }
                                        | null
                                    }>
                                    media?:
                                      | {
                                          __typename: "AnimationMediaInfo"
                                          id: string
                                          resourceId?: string | null
                                          thumbnail?: string | null
                                          width?: number | null
                                          height?: number | null
                                          mediaType?: Types.MediaType | null
                                        }
                                      | {
                                          __typename: "ImageMediaInfo"
                                          id: string
                                          resourceId?: string | null
                                          thumbnail?: string | null
                                          width?: number | null
                                          height?: number | null
                                          resolution?: number | null
                                          colorDepth?: number | null
                                          size?: number | null
                                          mime?: string | null
                                          extension?: string | null
                                          allowCaching?: boolean | null
                                          mediaType?: Types.MediaType | null
                                          src?: string | null
                                          language?: string | null
                                        }
                                      | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                                      | {
                                          __typename: "VectorGraphicsMediaInfo"
                                          id: string
                                          resourceId?: string | null
                                          thumbnail?: string | null
                                          width?: number | null
                                          height?: number | null
                                          allowCaching?: boolean | null
                                          mediaType?: Types.MediaType | null
                                          language?: string | null
                                          labels: Array<{
                                            __typename: "VectorGraphicsLabel"
                                            id?: string | null
                                            sequence?: number | null
                                            autoValue?: string | null
                                            text?: string | null
                                            linkType?: Types.VectorGraphicsLabelLinkType | null
                                            link?: string | null
                                            ref?:
                                              | {
                                                  __typename: "GenericRef"
                                                  id: string
                                                  owner?:
                                                    | { __typename: "CommentNode" }
                                                    | { __typename: "ExternalSite" }
                                                    | { __typename: "ST4Class" }
                                                    | { __typename: "ST4Node"; id: string }
                                                    | { __typename: "ST4TreeNode" }
                                                    | null
                                                  target?:
                                                    | { __typename: "CommentNode" }
                                                    | {
                                                        __typename: "ExternalSite"
                                                        id: string
                                                        teaserData?: {
                                                          __typename: "ExternalLinkTeaserData"
                                                          title?: string | null
                                                          summary?: string | null
                                                          iconUrl?: string | null
                                                          imageUrl?: string | null
                                                          status?: Types.LinkTargetStatus | null
                                                        } | null
                                                      }
                                                    | { __typename: "ST4Class" }
                                                    | {
                                                        __typename: "ST4Node"
                                                        id: string
                                                        aspectId: any
                                                        label?: string | null
                                                        properties?: {
                                                          __typename: "ContentPropertyConnection"
                                                          edges?: Array<{
                                                            __typename: "ContentPropertyEdge"
                                                            node?: {
                                                              __typename: "ContentProperty"
                                                              name: string
                                                              value?: any | null
                                                              displayName: string
                                                            } | null
                                                          } | null> | null
                                                        } | null
                                                      }
                                                    | { __typename: "ST4TreeNode" }
                                                    | null
                                                }
                                              | {
                                                  __typename: "NodeRef"
                                                  id: string
                                                  owner?:
                                                    | { __typename: "CommentNode" }
                                                    | { __typename: "ExternalSite" }
                                                    | { __typename: "ST4Class" }
                                                    | { __typename: "ST4Node"; id: string }
                                                    | { __typename: "ST4TreeNode" }
                                                    | null
                                                  target?:
                                                    | { __typename: "CommentNode" }
                                                    | {
                                                        __typename: "ExternalSite"
                                                        id: string
                                                        teaserData?: {
                                                          __typename: "ExternalLinkTeaserData"
                                                          title?: string | null
                                                          summary?: string | null
                                                          iconUrl?: string | null
                                                          imageUrl?: string | null
                                                          status?: Types.LinkTargetStatus | null
                                                        } | null
                                                      }
                                                    | { __typename: "ST4Class" }
                                                    | {
                                                        __typename: "ST4Node"
                                                        id: string
                                                        aspectId: any
                                                        label?: string | null
                                                        properties?: {
                                                          __typename: "ContentPropertyConnection"
                                                          edges?: Array<{
                                                            __typename: "ContentPropertyEdge"
                                                            node?: {
                                                              __typename: "ContentProperty"
                                                              name: string
                                                              value?: any | null
                                                              displayName: string
                                                            } | null
                                                          } | null> | null
                                                        } | null
                                                      }
                                                    | { __typename: "ST4TreeNode" }
                                                    | null
                                                }
                                              | null
                                          }>
                                        }
                                      | {
                                          __typename: "VideoMediaInfo"
                                          id: string
                                          resourceId?: string | null
                                          thumbnail?: string | null
                                          width?: number | null
                                          height?: number | null
                                          mediaType?: Types.MediaType | null
                                        }
                                      | null
                                  }
                                | { __typename: "ProjectContent" }
                                | { __typename: "TermContent" }
                                | { __typename: "TextContent" }
                                | { __typename: "TextGroupContent" }
                                | { __typename: "VariableTableContent" }
                                | null
                            }
                          | { __typename: "ST4TreeNode"; id: string }
                          | null
                      }>
                      variableTables?: Array<{
                        __typename: "VariableTableReference"
                        ref?: string | null
                        node?:
                          | { __typename: "CommentNode" }
                          | { __typename: "ExternalSite" }
                          | { __typename: "ST4Class" }
                          | {
                              __typename: "ST4Node"
                              id: string
                              label?: string | null
                              aspectId: any
                              content?:
                                | { __typename: "LexiconContent" }
                                | { __typename: "LexiconEntryContent" }
                                | { __typename: "MediaContent" }
                                | { __typename: "MediaGroupContent" }
                                | { __typename: "ProjectContent" }
                                | { __typename: "TermContent" }
                                | { __typename: "TextContent" }
                                | { __typename: "TextGroupContent" }
                                | {
                                    __typename: "VariableTableContent"
                                    xml?: string | null
                                    valueSets: Array<{ __typename: "ValueSet"; name: string; valueSetId: string }>
                                    variables: Array<{
                                      __typename: "Variable"
                                      name: string
                                      variableId: string
                                      values: Array<string>
                                    }>
                                  }
                                | null
                            }
                          | { __typename: "ST4TreeNode" }
                          | null
                      } | null> | null
                      linkedDataNodes: Array<{
                        __typename: "NodeRef"
                        id: string
                        target?:
                          | { __typename: "CommentNode"; id: string }
                          | { __typename: "ExternalSite"; id: string }
                          | { __typename: "ST4Class"; id: string }
                          | {
                              __typename: "ST4Node"
                              label?: string | null
                              id: string
                              metadata: Array<{
                                __typename: "MetaDataEntry"
                                label?: string | null
                                systemName: string
                                value?: string | null
                                values: Array<string>
                                users: Array<string>
                              }>
                              linkedOntologyNodes: Array<
                                | {
                                    __typename: "GenericRef"
                                    target?:
                                      | { __typename: "CommentNode" }
                                      | { __typename: "ExternalSite" }
                                      | { __typename: "ST4Class" }
                                      | { __typename: "ST4Node"; id: string; label?: string | null }
                                      | { __typename: "ST4TreeNode" }
                                      | null
                                  }
                                | {
                                    __typename: "NodeRef"
                                    linkClassName: string
                                    class?: { __typename: "ST4Class"; label?: string | null } | null
                                    target?:
                                      | { __typename: "CommentNode" }
                                      | { __typename: "ExternalSite" }
                                      | { __typename: "ST4Class" }
                                      | { __typename: "ST4Node"; id: string; label?: string | null }
                                      | { __typename: "ST4TreeNode" }
                                      | null
                                  }
                              >
                            }
                          | { __typename: "ST4TreeNode"; id: string }
                          | null
                      }>
                      lexiconEntries?: Array<{
                        __typename: "NodeRef"
                        id: string
                        target?:
                          | { __typename: "CommentNode"; id: string }
                          | { __typename: "ExternalSite"; id: string }
                          | { __typename: "ST4Class"; id: string }
                          | {
                              __typename: "ST4Node"
                              aspectId: any
                              label?: string | null
                              id: string
                              content?:
                                | { __typename: "LexiconContent" }
                                | {
                                    __typename: "LexiconEntryContent"
                                    descriptionXast?: string | null
                                    subject?: string | null
                                    sortKey?: string | null
                                    lexicon:
                                      | { __typename: "CommentNode"; id: string }
                                      | { __typename: "ExternalSite"; id: string }
                                      | { __typename: "ST4Class"; id: string }
                                      | { __typename: "ST4Node"; label?: string | null; id: string }
                                      | { __typename: "ST4TreeNode"; id: string }
                                    terms: Array<
                                      | { __typename: "CommentNode"; id: string }
                                      | { __typename: "ExternalSite"; id: string }
                                      | { __typename: "ST4Class"; id: string }
                                      | {
                                          __typename: "ST4Node"
                                          id: string
                                          label?: string | null
                                          aspectId: any
                                          content?:
                                            | { __typename: "LexiconContent" }
                                            | { __typename: "LexiconEntryContent" }
                                            | { __typename: "MediaContent" }
                                            | { __typename: "MediaGroupContent" }
                                            | { __typename: "ProjectContent" }
                                            | {
                                                __typename: "TermContent"
                                                gramaticalGender?: string | null
                                                validity?: string | null
                                                editLanguage?: string | null
                                              }
                                            | { __typename: "TextContent" }
                                            | { __typename: "TextGroupContent" }
                                            | { __typename: "VariableTableContent" }
                                            | null
                                        }
                                      | { __typename: "ST4TreeNode"; id: string }
                                    >
                                    image?:
                                      | { __typename: "CommentNode"; id: string }
                                      | { __typename: "ExternalSite"; id: string }
                                      | { __typename: "ST4Class"; id: string }
                                      | {
                                          __typename: "ST4Node"
                                          aspectId: any
                                          id: string
                                          content?:
                                            | { __typename: "LexiconContent" }
                                            | { __typename: "LexiconEntryContent" }
                                            | {
                                                __typename: "MediaContent"
                                                media?:
                                                  | {
                                                      __typename: "AnimationMediaInfo"
                                                      thumbnail?: string | null
                                                      mediaType?: Types.MediaType | null
                                                    }
                                                  | {
                                                      __typename: "ImageMediaInfo"
                                                      src?: string | null
                                                      thumbnail?: string | null
                                                      mediaType?: Types.MediaType | null
                                                    }
                                                  | { __typename: "NoMediaInfo" }
                                                  | {
                                                      __typename: "VectorGraphicsMediaInfo"
                                                      svg?: string | null
                                                      thumbnail?: string | null
                                                      mediaType?: Types.MediaType | null
                                                    }
                                                  | {
                                                      __typename: "VideoMediaInfo"
                                                      thumbnail?: string | null
                                                      mediaType?: Types.MediaType | null
                                                    }
                                                  | null
                                              }
                                            | { __typename: "MediaGroupContent" }
                                            | { __typename: "ProjectContent" }
                                            | { __typename: "TermContent" }
                                            | { __typename: "TextContent" }
                                            | { __typename: "TextGroupContent" }
                                            | { __typename: "VariableTableContent" }
                                            | null
                                        }
                                      | { __typename: "ST4TreeNode"; id: string }
                                      | null
                                  }
                                | { __typename: "MediaContent" }
                                | { __typename: "MediaGroupContent" }
                                | { __typename: "ProjectContent" }
                                | { __typename: "TermContent" }
                                | { __typename: "TextContent" }
                                | { __typename: "TextGroupContent" }
                                | { __typename: "VariableTableContent" }
                                | null
                            }
                          | { __typename: "ST4TreeNode"; id: string }
                          | null
                      } | null> | null
                      comments: Array<{
                        __typename: "CommentNode"
                        id: string
                        comment:
                          | {
                              __typename: "BlockComment"
                              location: number
                              value?: string | null
                              authorDisplayName?: string | null
                              authorUserName?: string | null
                              commentKey: string
                              editDate?: any | null
                              modificationVersion?: number | null
                              replyToKey?: string | null
                              sortOrder?: number | null
                              commentType?: string | null
                              state?: string | null
                              color: string
                              persistanceState?: Types.PersistanceState | null
                              persistanceAction?: Types.PersistanceAction | null
                              node: {
                                __typename: "ST4Node"
                                aspectId: any
                                id: string
                                content?:
                                  | { __typename: "LexiconContent" }
                                  | { __typename: "LexiconEntryContent" }
                                  | { __typename: "MediaContent" }
                                  | { __typename: "MediaGroupContent" }
                                  | { __typename: "ProjectContent" }
                                  | { __typename: "TermContent" }
                                  | { __typename: "TextContent"; id: string; historyNumber: number }
                                  | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                                  | { __typename: "VariableTableContent" }
                                  | null
                              }
                              actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
                            }
                          | {
                              __typename: "TextComment"
                              endLocation: number
                              startLocation?: number | null
                              value?: string | null
                              authorDisplayName?: string | null
                              authorUserName?: string | null
                              commentKey: string
                              editDate?: any | null
                              modificationVersion?: number | null
                              replyToKey?: string | null
                              sortOrder?: number | null
                              commentType?: string | null
                              state?: string | null
                              color: string
                              persistanceState?: Types.PersistanceState | null
                              persistanceAction?: Types.PersistanceAction | null
                              node: {
                                __typename: "ST4Node"
                                aspectId: any
                                id: string
                                content?:
                                  | { __typename: "LexiconContent" }
                                  | { __typename: "LexiconEntryContent" }
                                  | { __typename: "MediaContent" }
                                  | { __typename: "MediaGroupContent" }
                                  | { __typename: "ProjectContent" }
                                  | { __typename: "TermContent" }
                                  | { __typename: "TextContent"; id: string; historyNumber: number }
                                  | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                                  | { __typename: "VariableTableContent" }
                                  | null
                              }
                              actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
                            }
                      }>
                    }
                  | { __typename: "VariableTableContent" }
                  | null
              }
            }>
            children: Array<{
              __typename: "NodeRef"
              id: string
              target?:
                | { __typename: "CommentNode"; id: string }
                | { __typename: "ExternalSite"; id: string }
                | { __typename: "ST4Class"; id: string }
                | { __typename: "ST4Node"; label?: string | null; aspectId: any; id: string }
                | { __typename: "ST4TreeNode"; id: string }
                | null
            }>
            links?: Array<{
              __typename: "TextContentLink"
              ref?: string | null
              node?:
                | { __typename: "CommentNode"; id: string }
                | { __typename: "ExternalSite"; id: string }
                | { __typename: "ST4Class"; id: string }
                | {
                    __typename: "ST4Node"
                    id: string
                    aspectId: any
                    label?: string | null
                    properties?: {
                      __typename: "ContentPropertyConnection"
                      totalCount: number
                      edges?: Array<{
                        __typename: "ContentPropertyEdge"
                        node?: {
                          __typename: "ContentProperty"
                          typeOfValue: Types.ContentPropertyValueType
                          name: string
                          value?: any | null
                          displayName: string
                        } | null
                      } | null> | null
                    } | null
                  }
                | { __typename: "ST4TreeNode"; id: string }
                | null
            } | null> | null
            images: Array<{
              __typename: "NodeRef"
              id: string
              target?:
                | { __typename: "CommentNode"; id: string }
                | { __typename: "ExternalSite"; id: string }
                | { __typename: "ST4Class"; id: string }
                | {
                    __typename: "ST4Node"
                    aspectId: any
                    id: string
                    releaseState: {
                      __typename: "ReleaseStateValue"
                      displayName: string
                      state: Types.ReleaseState
                      isImplicitlyReleased: boolean
                    }
                    content?:
                      | { __typename: "LexiconContent" }
                      | { __typename: "LexiconEntryContent" }
                      | {
                          __typename: "MediaContent"
                          media?:
                            | {
                                __typename: "AnimationMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                mediaType?: Types.MediaType | null
                              }
                            | {
                                __typename: "ImageMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                resolution?: number | null
                                colorDepth?: number | null
                                size?: number | null
                                mime?: string | null
                                extension?: string | null
                                allowCaching?: boolean | null
                                mediaType?: Types.MediaType | null
                                src?: string | null
                                language?: string | null
                              }
                            | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                            | {
                                __typename: "VectorGraphicsMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                allowCaching?: boolean | null
                                mediaType?: Types.MediaType | null
                                language?: string | null
                                labels: Array<{
                                  __typename: "VectorGraphicsLabel"
                                  id?: string | null
                                  sequence?: number | null
                                  autoValue?: string | null
                                  text?: string | null
                                  linkType?: Types.VectorGraphicsLabelLinkType | null
                                  link?: string | null
                                  ref?:
                                    | {
                                        __typename: "GenericRef"
                                        id: string
                                        owner?:
                                          | { __typename: "CommentNode" }
                                          | { __typename: "ExternalSite" }
                                          | { __typename: "ST4Class" }
                                          | { __typename: "ST4Node"; id: string }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                        target?:
                                          | { __typename: "CommentNode" }
                                          | {
                                              __typename: "ExternalSite"
                                              id: string
                                              teaserData?: {
                                                __typename: "ExternalLinkTeaserData"
                                                title?: string | null
                                                summary?: string | null
                                                iconUrl?: string | null
                                                imageUrl?: string | null
                                                status?: Types.LinkTargetStatus | null
                                              } | null
                                            }
                                          | { __typename: "ST4Class" }
                                          | {
                                              __typename: "ST4Node"
                                              id: string
                                              aspectId: any
                                              label?: string | null
                                              properties?: {
                                                __typename: "ContentPropertyConnection"
                                                edges?: Array<{
                                                  __typename: "ContentPropertyEdge"
                                                  node?: {
                                                    __typename: "ContentProperty"
                                                    name: string
                                                    value?: any | null
                                                    displayName: string
                                                  } | null
                                                } | null> | null
                                              } | null
                                            }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                      }
                                    | {
                                        __typename: "NodeRef"
                                        id: string
                                        owner?:
                                          | { __typename: "CommentNode" }
                                          | { __typename: "ExternalSite" }
                                          | { __typename: "ST4Class" }
                                          | { __typename: "ST4Node"; id: string }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                        target?:
                                          | { __typename: "CommentNode" }
                                          | {
                                              __typename: "ExternalSite"
                                              id: string
                                              teaserData?: {
                                                __typename: "ExternalLinkTeaserData"
                                                title?: string | null
                                                summary?: string | null
                                                iconUrl?: string | null
                                                imageUrl?: string | null
                                                status?: Types.LinkTargetStatus | null
                                              } | null
                                            }
                                          | { __typename: "ST4Class" }
                                          | {
                                              __typename: "ST4Node"
                                              id: string
                                              aspectId: any
                                              label?: string | null
                                              properties?: {
                                                __typename: "ContentPropertyConnection"
                                                edges?: Array<{
                                                  __typename: "ContentPropertyEdge"
                                                  node?: {
                                                    __typename: "ContentProperty"
                                                    name: string
                                                    value?: any | null
                                                    displayName: string
                                                  } | null
                                                } | null> | null
                                              } | null
                                            }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                      }
                                    | null
                                }>
                              }
                            | {
                                __typename: "VideoMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                mediaType?: Types.MediaType | null
                              }
                            | null
                        }
                      | {
                          __typename: "MediaGroupContent"
                          children: Array<{
                            __typename: "NodeRef"
                            id: string
                            target?:
                              | { __typename: "CommentNode"; id: string }
                              | { __typename: "ExternalSite"; id: string }
                              | { __typename: "ST4Class"; id: string }
                              | {
                                  __typename: "ST4Node"
                                  id: string
                                  aspectId: any
                                  content?:
                                    | { __typename: "LexiconContent" }
                                    | { __typename: "LexiconEntryContent" }
                                    | {
                                        __typename: "MediaContent"
                                        media?:
                                          | {
                                              __typename: "AnimationMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              mediaType?: Types.MediaType | null
                                            }
                                          | {
                                              __typename: "ImageMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              resolution?: number | null
                                              colorDepth?: number | null
                                              size?: number | null
                                              mime?: string | null
                                              extension?: string | null
                                              allowCaching?: boolean | null
                                              mediaType?: Types.MediaType | null
                                              src?: string | null
                                              language?: string | null
                                            }
                                          | {
                                              __typename: "NoMediaInfo"
                                              id: string
                                              mediaType?: Types.MediaType | null
                                            }
                                          | {
                                              __typename: "VectorGraphicsMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              allowCaching?: boolean | null
                                              mediaType?: Types.MediaType | null
                                              language?: string | null
                                              labels: Array<{
                                                __typename: "VectorGraphicsLabel"
                                                id?: string | null
                                                sequence?: number | null
                                                autoValue?: string | null
                                                text?: string | null
                                                linkType?: Types.VectorGraphicsLabelLinkType | null
                                                link?: string | null
                                                ref?:
                                                  | {
                                                      __typename: "GenericRef"
                                                      id: string
                                                      owner?:
                                                        | { __typename: "CommentNode" }
                                                        | { __typename: "ExternalSite" }
                                                        | { __typename: "ST4Class" }
                                                        | { __typename: "ST4Node"; id: string }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                      target?:
                                                        | { __typename: "CommentNode" }
                                                        | {
                                                            __typename: "ExternalSite"
                                                            id: string
                                                            teaserData?: {
                                                              __typename: "ExternalLinkTeaserData"
                                                              title?: string | null
                                                              summary?: string | null
                                                              iconUrl?: string | null
                                                              imageUrl?: string | null
                                                              status?: Types.LinkTargetStatus | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4Class" }
                                                        | {
                                                            __typename: "ST4Node"
                                                            id: string
                                                            aspectId: any
                                                            label?: string | null
                                                            properties?: {
                                                              __typename: "ContentPropertyConnection"
                                                              edges?: Array<{
                                                                __typename: "ContentPropertyEdge"
                                                                node?: {
                                                                  __typename: "ContentProperty"
                                                                  name: string
                                                                  value?: any | null
                                                                  displayName: string
                                                                } | null
                                                              } | null> | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                    }
                                                  | {
                                                      __typename: "NodeRef"
                                                      id: string
                                                      owner?:
                                                        | { __typename: "CommentNode" }
                                                        | { __typename: "ExternalSite" }
                                                        | { __typename: "ST4Class" }
                                                        | { __typename: "ST4Node"; id: string }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                      target?:
                                                        | { __typename: "CommentNode" }
                                                        | {
                                                            __typename: "ExternalSite"
                                                            id: string
                                                            teaserData?: {
                                                              __typename: "ExternalLinkTeaserData"
                                                              title?: string | null
                                                              summary?: string | null
                                                              iconUrl?: string | null
                                                              imageUrl?: string | null
                                                              status?: Types.LinkTargetStatus | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4Class" }
                                                        | {
                                                            __typename: "ST4Node"
                                                            id: string
                                                            aspectId: any
                                                            label?: string | null
                                                            properties?: {
                                                              __typename: "ContentPropertyConnection"
                                                              edges?: Array<{
                                                                __typename: "ContentPropertyEdge"
                                                                node?: {
                                                                  __typename: "ContentProperty"
                                                                  name: string
                                                                  value?: any | null
                                                                  displayName: string
                                                                } | null
                                                              } | null> | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                    }
                                                  | null
                                              }>
                                            }
                                          | {
                                              __typename: "VideoMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              mediaType?: Types.MediaType | null
                                            }
                                          | null
                                      }
                                    | {
                                        __typename: "MediaGroupContent"
                                        media?:
                                          | {
                                              __typename: "AnimationMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              mediaType?: Types.MediaType | null
                                            }
                                          | {
                                              __typename: "ImageMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              resolution?: number | null
                                              colorDepth?: number | null
                                              size?: number | null
                                              mime?: string | null
                                              extension?: string | null
                                              allowCaching?: boolean | null
                                              mediaType?: Types.MediaType | null
                                              src?: string | null
                                              language?: string | null
                                            }
                                          | {
                                              __typename: "NoMediaInfo"
                                              id: string
                                              mediaType?: Types.MediaType | null
                                            }
                                          | {
                                              __typename: "VectorGraphicsMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              allowCaching?: boolean | null
                                              mediaType?: Types.MediaType | null
                                              language?: string | null
                                              labels: Array<{
                                                __typename: "VectorGraphicsLabel"
                                                id?: string | null
                                                sequence?: number | null
                                                autoValue?: string | null
                                                text?: string | null
                                                linkType?: Types.VectorGraphicsLabelLinkType | null
                                                link?: string | null
                                                ref?:
                                                  | {
                                                      __typename: "GenericRef"
                                                      id: string
                                                      owner?:
                                                        | { __typename: "CommentNode" }
                                                        | { __typename: "ExternalSite" }
                                                        | { __typename: "ST4Class" }
                                                        | { __typename: "ST4Node"; id: string }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                      target?:
                                                        | { __typename: "CommentNode" }
                                                        | {
                                                            __typename: "ExternalSite"
                                                            id: string
                                                            teaserData?: {
                                                              __typename: "ExternalLinkTeaserData"
                                                              title?: string | null
                                                              summary?: string | null
                                                              iconUrl?: string | null
                                                              imageUrl?: string | null
                                                              status?: Types.LinkTargetStatus | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4Class" }
                                                        | {
                                                            __typename: "ST4Node"
                                                            id: string
                                                            aspectId: any
                                                            label?: string | null
                                                            properties?: {
                                                              __typename: "ContentPropertyConnection"
                                                              edges?: Array<{
                                                                __typename: "ContentPropertyEdge"
                                                                node?: {
                                                                  __typename: "ContentProperty"
                                                                  name: string
                                                                  value?: any | null
                                                                  displayName: string
                                                                } | null
                                                              } | null> | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                    }
                                                  | {
                                                      __typename: "NodeRef"
                                                      id: string
                                                      owner?:
                                                        | { __typename: "CommentNode" }
                                                        | { __typename: "ExternalSite" }
                                                        | { __typename: "ST4Class" }
                                                        | { __typename: "ST4Node"; id: string }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                      target?:
                                                        | { __typename: "CommentNode" }
                                                        | {
                                                            __typename: "ExternalSite"
                                                            id: string
                                                            teaserData?: {
                                                              __typename: "ExternalLinkTeaserData"
                                                              title?: string | null
                                                              summary?: string | null
                                                              iconUrl?: string | null
                                                              imageUrl?: string | null
                                                              status?: Types.LinkTargetStatus | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4Class" }
                                                        | {
                                                            __typename: "ST4Node"
                                                            id: string
                                                            aspectId: any
                                                            label?: string | null
                                                            properties?: {
                                                              __typename: "ContentPropertyConnection"
                                                              edges?: Array<{
                                                                __typename: "ContentPropertyEdge"
                                                                node?: {
                                                                  __typename: "ContentProperty"
                                                                  name: string
                                                                  value?: any | null
                                                                  displayName: string
                                                                } | null
                                                              } | null> | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                    }
                                                  | null
                                              }>
                                            }
                                          | {
                                              __typename: "VideoMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              mediaType?: Types.MediaType | null
                                            }
                                          | null
                                      }
                                    | { __typename: "ProjectContent" }
                                    | { __typename: "TermContent" }
                                    | { __typename: "TextContent" }
                                    | { __typename: "TextGroupContent" }
                                    | { __typename: "VariableTableContent" }
                                    | null
                                }
                              | { __typename: "ST4TreeNode"; id: string }
                              | null
                          }>
                          media?:
                            | {
                                __typename: "AnimationMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                mediaType?: Types.MediaType | null
                              }
                            | {
                                __typename: "ImageMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                resolution?: number | null
                                colorDepth?: number | null
                                size?: number | null
                                mime?: string | null
                                extension?: string | null
                                allowCaching?: boolean | null
                                mediaType?: Types.MediaType | null
                                src?: string | null
                                language?: string | null
                              }
                            | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                            | {
                                __typename: "VectorGraphicsMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                allowCaching?: boolean | null
                                mediaType?: Types.MediaType | null
                                language?: string | null
                                labels: Array<{
                                  __typename: "VectorGraphicsLabel"
                                  id?: string | null
                                  sequence?: number | null
                                  autoValue?: string | null
                                  text?: string | null
                                  linkType?: Types.VectorGraphicsLabelLinkType | null
                                  link?: string | null
                                  ref?:
                                    | {
                                        __typename: "GenericRef"
                                        id: string
                                        owner?:
                                          | { __typename: "CommentNode" }
                                          | { __typename: "ExternalSite" }
                                          | { __typename: "ST4Class" }
                                          | { __typename: "ST4Node"; id: string }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                        target?:
                                          | { __typename: "CommentNode" }
                                          | {
                                              __typename: "ExternalSite"
                                              id: string
                                              teaserData?: {
                                                __typename: "ExternalLinkTeaserData"
                                                title?: string | null
                                                summary?: string | null
                                                iconUrl?: string | null
                                                imageUrl?: string | null
                                                status?: Types.LinkTargetStatus | null
                                              } | null
                                            }
                                          | { __typename: "ST4Class" }
                                          | {
                                              __typename: "ST4Node"
                                              id: string
                                              aspectId: any
                                              label?: string | null
                                              properties?: {
                                                __typename: "ContentPropertyConnection"
                                                edges?: Array<{
                                                  __typename: "ContentPropertyEdge"
                                                  node?: {
                                                    __typename: "ContentProperty"
                                                    name: string
                                                    value?: any | null
                                                    displayName: string
                                                  } | null
                                                } | null> | null
                                              } | null
                                            }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                      }
                                    | {
                                        __typename: "NodeRef"
                                        id: string
                                        owner?:
                                          | { __typename: "CommentNode" }
                                          | { __typename: "ExternalSite" }
                                          | { __typename: "ST4Class" }
                                          | { __typename: "ST4Node"; id: string }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                        target?:
                                          | { __typename: "CommentNode" }
                                          | {
                                              __typename: "ExternalSite"
                                              id: string
                                              teaserData?: {
                                                __typename: "ExternalLinkTeaserData"
                                                title?: string | null
                                                summary?: string | null
                                                iconUrl?: string | null
                                                imageUrl?: string | null
                                                status?: Types.LinkTargetStatus | null
                                              } | null
                                            }
                                          | { __typename: "ST4Class" }
                                          | {
                                              __typename: "ST4Node"
                                              id: string
                                              aspectId: any
                                              label?: string | null
                                              properties?: {
                                                __typename: "ContentPropertyConnection"
                                                edges?: Array<{
                                                  __typename: "ContentPropertyEdge"
                                                  node?: {
                                                    __typename: "ContentProperty"
                                                    name: string
                                                    value?: any | null
                                                    displayName: string
                                                  } | null
                                                } | null> | null
                                              } | null
                                            }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                      }
                                    | null
                                }>
                              }
                            | {
                                __typename: "VideoMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                mediaType?: Types.MediaType | null
                              }
                            | null
                        }
                      | { __typename: "ProjectContent" }
                      | { __typename: "TermContent" }
                      | { __typename: "TextContent" }
                      | { __typename: "TextGroupContent" }
                      | { __typename: "VariableTableContent" }
                      | null
                  }
                | { __typename: "ST4TreeNode"; id: string }
                | null
            }>
            variableTables?: Array<{
              __typename: "VariableTableReference"
              ref?: string | null
              node?:
                | { __typename: "CommentNode" }
                | { __typename: "ExternalSite" }
                | { __typename: "ST4Class" }
                | {
                    __typename: "ST4Node"
                    id: string
                    label?: string | null
                    aspectId: any
                    content?:
                      | { __typename: "LexiconContent" }
                      | { __typename: "LexiconEntryContent" }
                      | { __typename: "MediaContent" }
                      | { __typename: "MediaGroupContent" }
                      | { __typename: "ProjectContent" }
                      | { __typename: "TermContent" }
                      | { __typename: "TextContent" }
                      | { __typename: "TextGroupContent" }
                      | {
                          __typename: "VariableTableContent"
                          xml?: string | null
                          valueSets: Array<{ __typename: "ValueSet"; name: string; valueSetId: string }>
                          variables: Array<{
                            __typename: "Variable"
                            name: string
                            variableId: string
                            values: Array<string>
                          }>
                        }
                      | null
                  }
                | { __typename: "ST4TreeNode" }
                | null
            } | null> | null
            linkedDataNodes: Array<{
              __typename: "NodeRef"
              id: string
              target?:
                | { __typename: "CommentNode"; id: string }
                | { __typename: "ExternalSite"; id: string }
                | { __typename: "ST4Class"; id: string }
                | {
                    __typename: "ST4Node"
                    label?: string | null
                    id: string
                    metadata: Array<{
                      __typename: "MetaDataEntry"
                      label?: string | null
                      systemName: string
                      value?: string | null
                      values: Array<string>
                      users: Array<string>
                    }>
                    linkedOntologyNodes: Array<
                      | {
                          __typename: "GenericRef"
                          target?:
                            | { __typename: "CommentNode" }
                            | { __typename: "ExternalSite" }
                            | { __typename: "ST4Class" }
                            | { __typename: "ST4Node"; id: string; label?: string | null }
                            | { __typename: "ST4TreeNode" }
                            | null
                        }
                      | {
                          __typename: "NodeRef"
                          linkClassName: string
                          class?: { __typename: "ST4Class"; label?: string | null } | null
                          target?:
                            | { __typename: "CommentNode" }
                            | { __typename: "ExternalSite" }
                            | { __typename: "ST4Class" }
                            | { __typename: "ST4Node"; id: string; label?: string | null }
                            | { __typename: "ST4TreeNode" }
                            | null
                        }
                    >
                  }
                | { __typename: "ST4TreeNode"; id: string }
                | null
            }>
            lexiconEntries?: Array<{
              __typename: "NodeRef"
              id: string
              target?:
                | { __typename: "CommentNode"; id: string }
                | { __typename: "ExternalSite"; id: string }
                | { __typename: "ST4Class"; id: string }
                | {
                    __typename: "ST4Node"
                    aspectId: any
                    label?: string | null
                    id: string
                    content?:
                      | { __typename: "LexiconContent" }
                      | {
                          __typename: "LexiconEntryContent"
                          descriptionXast?: string | null
                          subject?: string | null
                          sortKey?: string | null
                          lexicon:
                            | { __typename: "CommentNode"; id: string }
                            | { __typename: "ExternalSite"; id: string }
                            | { __typename: "ST4Class"; id: string }
                            | { __typename: "ST4Node"; label?: string | null; id: string }
                            | { __typename: "ST4TreeNode"; id: string }
                          terms: Array<
                            | { __typename: "CommentNode"; id: string }
                            | { __typename: "ExternalSite"; id: string }
                            | { __typename: "ST4Class"; id: string }
                            | {
                                __typename: "ST4Node"
                                id: string
                                label?: string | null
                                aspectId: any
                                content?:
                                  | { __typename: "LexiconContent" }
                                  | { __typename: "LexiconEntryContent" }
                                  | { __typename: "MediaContent" }
                                  | { __typename: "MediaGroupContent" }
                                  | { __typename: "ProjectContent" }
                                  | {
                                      __typename: "TermContent"
                                      gramaticalGender?: string | null
                                      validity?: string | null
                                      editLanguage?: string | null
                                    }
                                  | { __typename: "TextContent" }
                                  | { __typename: "TextGroupContent" }
                                  | { __typename: "VariableTableContent" }
                                  | null
                              }
                            | { __typename: "ST4TreeNode"; id: string }
                          >
                          image?:
                            | { __typename: "CommentNode"; id: string }
                            | { __typename: "ExternalSite"; id: string }
                            | { __typename: "ST4Class"; id: string }
                            | {
                                __typename: "ST4Node"
                                aspectId: any
                                id: string
                                content?:
                                  | { __typename: "LexiconContent" }
                                  | { __typename: "LexiconEntryContent" }
                                  | {
                                      __typename: "MediaContent"
                                      media?:
                                        | {
                                            __typename: "AnimationMediaInfo"
                                            thumbnail?: string | null
                                            mediaType?: Types.MediaType | null
                                          }
                                        | {
                                            __typename: "ImageMediaInfo"
                                            src?: string | null
                                            thumbnail?: string | null
                                            mediaType?: Types.MediaType | null
                                          }
                                        | { __typename: "NoMediaInfo" }
                                        | {
                                            __typename: "VectorGraphicsMediaInfo"
                                            svg?: string | null
                                            thumbnail?: string | null
                                            mediaType?: Types.MediaType | null
                                          }
                                        | {
                                            __typename: "VideoMediaInfo"
                                            thumbnail?: string | null
                                            mediaType?: Types.MediaType | null
                                          }
                                        | null
                                    }
                                  | { __typename: "MediaGroupContent" }
                                  | { __typename: "ProjectContent" }
                                  | { __typename: "TermContent" }
                                  | { __typename: "TextContent" }
                                  | { __typename: "TextGroupContent" }
                                  | { __typename: "VariableTableContent" }
                                  | null
                              }
                            | { __typename: "ST4TreeNode"; id: string }
                            | null
                        }
                      | { __typename: "MediaContent" }
                      | { __typename: "MediaGroupContent" }
                      | { __typename: "ProjectContent" }
                      | { __typename: "TermContent" }
                      | { __typename: "TextContent" }
                      | { __typename: "TextGroupContent" }
                      | { __typename: "VariableTableContent" }
                      | null
                  }
                | { __typename: "ST4TreeNode"; id: string }
                | null
            } | null> | null
            comments: Array<{
              __typename: "CommentNode"
              id: string
              comment:
                | {
                    __typename: "BlockComment"
                    location: number
                    value?: string | null
                    authorDisplayName?: string | null
                    authorUserName?: string | null
                    commentKey: string
                    editDate?: any | null
                    modificationVersion?: number | null
                    replyToKey?: string | null
                    sortOrder?: number | null
                    commentType?: string | null
                    state?: string | null
                    color: string
                    persistanceState?: Types.PersistanceState | null
                    persistanceAction?: Types.PersistanceAction | null
                    node: {
                      __typename: "ST4Node"
                      aspectId: any
                      id: string
                      content?:
                        | { __typename: "LexiconContent" }
                        | { __typename: "LexiconEntryContent" }
                        | { __typename: "MediaContent" }
                        | { __typename: "MediaGroupContent" }
                        | { __typename: "ProjectContent" }
                        | { __typename: "TermContent" }
                        | { __typename: "TextContent"; id: string; historyNumber: number }
                        | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                        | { __typename: "VariableTableContent" }
                        | null
                    }
                    actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
                  }
                | {
                    __typename: "TextComment"
                    endLocation: number
                    startLocation?: number | null
                    value?: string | null
                    authorDisplayName?: string | null
                    authorUserName?: string | null
                    commentKey: string
                    editDate?: any | null
                    modificationVersion?: number | null
                    replyToKey?: string | null
                    sortOrder?: number | null
                    commentType?: string | null
                    state?: string | null
                    color: string
                    persistanceState?: Types.PersistanceState | null
                    persistanceAction?: Types.PersistanceAction | null
                    node: {
                      __typename: "ST4Node"
                      aspectId: any
                      id: string
                      content?:
                        | { __typename: "LexiconContent" }
                        | { __typename: "LexiconEntryContent" }
                        | { __typename: "MediaContent" }
                        | { __typename: "MediaGroupContent" }
                        | { __typename: "ProjectContent" }
                        | { __typename: "TermContent" }
                        | { __typename: "TextContent"; id: string; historyNumber: number }
                        | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                        | { __typename: "VariableTableContent" }
                        | null
                    }
                    actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
                  }
            }>
          }
        | { __typename: "VariableTableContent" }
        | null
    }
  }>
  parts: Array<{
    __typename: "TextContentPart"
    ref: string
    contentNode: {
      __typename: "ST4Node"
      id: string
      aspectId: any
      content?:
        | { __typename: "LexiconContent" }
        | { __typename: "LexiconEntryContent" }
        | { __typename: "MediaContent" }
        | { __typename: "MediaGroupContent" }
        | { __typename: "ProjectContent" }
        | { __typename: "TermContent" }
        | {
            __typename: "TextContent"
            id: string
            xast?: string | null
            historyNumber: number
            links?: Array<{
              __typename: "TextContentLink"
              ref?: string | null
              node?:
                | { __typename: "CommentNode"; id: string }
                | { __typename: "ExternalSite"; id: string }
                | { __typename: "ST4Class"; id: string }
                | {
                    __typename: "ST4Node"
                    id: string
                    aspectId: any
                    label?: string | null
                    properties?: {
                      __typename: "ContentPropertyConnection"
                      totalCount: number
                      edges?: Array<{
                        __typename: "ContentPropertyEdge"
                        node?: {
                          __typename: "ContentProperty"
                          typeOfValue: Types.ContentPropertyValueType
                          name: string
                          value?: any | null
                          displayName: string
                        } | null
                      } | null> | null
                    } | null
                  }
                | { __typename: "ST4TreeNode"; id: string }
                | null
            } | null> | null
            images: Array<{
              __typename: "NodeRef"
              id: string
              target?:
                | { __typename: "CommentNode"; id: string }
                | { __typename: "ExternalSite"; id: string }
                | { __typename: "ST4Class"; id: string }
                | {
                    __typename: "ST4Node"
                    aspectId: any
                    id: string
                    releaseState: {
                      __typename: "ReleaseStateValue"
                      displayName: string
                      state: Types.ReleaseState
                      isImplicitlyReleased: boolean
                    }
                    content?:
                      | { __typename: "LexiconContent" }
                      | { __typename: "LexiconEntryContent" }
                      | {
                          __typename: "MediaContent"
                          media?:
                            | {
                                __typename: "AnimationMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                mediaType?: Types.MediaType | null
                              }
                            | {
                                __typename: "ImageMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                resolution?: number | null
                                colorDepth?: number | null
                                size?: number | null
                                mime?: string | null
                                extension?: string | null
                                allowCaching?: boolean | null
                                mediaType?: Types.MediaType | null
                                src?: string | null
                                language?: string | null
                              }
                            | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                            | {
                                __typename: "VectorGraphicsMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                allowCaching?: boolean | null
                                mediaType?: Types.MediaType | null
                                language?: string | null
                                labels: Array<{
                                  __typename: "VectorGraphicsLabel"
                                  id?: string | null
                                  sequence?: number | null
                                  autoValue?: string | null
                                  text?: string | null
                                  linkType?: Types.VectorGraphicsLabelLinkType | null
                                  link?: string | null
                                  ref?:
                                    | {
                                        __typename: "GenericRef"
                                        id: string
                                        owner?:
                                          | { __typename: "CommentNode" }
                                          | { __typename: "ExternalSite" }
                                          | { __typename: "ST4Class" }
                                          | { __typename: "ST4Node"; id: string }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                        target?:
                                          | { __typename: "CommentNode" }
                                          | {
                                              __typename: "ExternalSite"
                                              id: string
                                              teaserData?: {
                                                __typename: "ExternalLinkTeaserData"
                                                title?: string | null
                                                summary?: string | null
                                                iconUrl?: string | null
                                                imageUrl?: string | null
                                                status?: Types.LinkTargetStatus | null
                                              } | null
                                            }
                                          | { __typename: "ST4Class" }
                                          | {
                                              __typename: "ST4Node"
                                              id: string
                                              aspectId: any
                                              label?: string | null
                                              properties?: {
                                                __typename: "ContentPropertyConnection"
                                                edges?: Array<{
                                                  __typename: "ContentPropertyEdge"
                                                  node?: {
                                                    __typename: "ContentProperty"
                                                    name: string
                                                    value?: any | null
                                                    displayName: string
                                                  } | null
                                                } | null> | null
                                              } | null
                                            }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                      }
                                    | {
                                        __typename: "NodeRef"
                                        id: string
                                        owner?:
                                          | { __typename: "CommentNode" }
                                          | { __typename: "ExternalSite" }
                                          | { __typename: "ST4Class" }
                                          | { __typename: "ST4Node"; id: string }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                        target?:
                                          | { __typename: "CommentNode" }
                                          | {
                                              __typename: "ExternalSite"
                                              id: string
                                              teaserData?: {
                                                __typename: "ExternalLinkTeaserData"
                                                title?: string | null
                                                summary?: string | null
                                                iconUrl?: string | null
                                                imageUrl?: string | null
                                                status?: Types.LinkTargetStatus | null
                                              } | null
                                            }
                                          | { __typename: "ST4Class" }
                                          | {
                                              __typename: "ST4Node"
                                              id: string
                                              aspectId: any
                                              label?: string | null
                                              properties?: {
                                                __typename: "ContentPropertyConnection"
                                                edges?: Array<{
                                                  __typename: "ContentPropertyEdge"
                                                  node?: {
                                                    __typename: "ContentProperty"
                                                    name: string
                                                    value?: any | null
                                                    displayName: string
                                                  } | null
                                                } | null> | null
                                              } | null
                                            }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                      }
                                    | null
                                }>
                              }
                            | {
                                __typename: "VideoMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                mediaType?: Types.MediaType | null
                              }
                            | null
                        }
                      | {
                          __typename: "MediaGroupContent"
                          children: Array<{
                            __typename: "NodeRef"
                            id: string
                            target?:
                              | { __typename: "CommentNode"; id: string }
                              | { __typename: "ExternalSite"; id: string }
                              | { __typename: "ST4Class"; id: string }
                              | {
                                  __typename: "ST4Node"
                                  id: string
                                  aspectId: any
                                  content?:
                                    | { __typename: "LexiconContent" }
                                    | { __typename: "LexiconEntryContent" }
                                    | {
                                        __typename: "MediaContent"
                                        media?:
                                          | {
                                              __typename: "AnimationMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              mediaType?: Types.MediaType | null
                                            }
                                          | {
                                              __typename: "ImageMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              resolution?: number | null
                                              colorDepth?: number | null
                                              size?: number | null
                                              mime?: string | null
                                              extension?: string | null
                                              allowCaching?: boolean | null
                                              mediaType?: Types.MediaType | null
                                              src?: string | null
                                              language?: string | null
                                            }
                                          | {
                                              __typename: "NoMediaInfo"
                                              id: string
                                              mediaType?: Types.MediaType | null
                                            }
                                          | {
                                              __typename: "VectorGraphicsMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              allowCaching?: boolean | null
                                              mediaType?: Types.MediaType | null
                                              language?: string | null
                                              labels: Array<{
                                                __typename: "VectorGraphicsLabel"
                                                id?: string | null
                                                sequence?: number | null
                                                autoValue?: string | null
                                                text?: string | null
                                                linkType?: Types.VectorGraphicsLabelLinkType | null
                                                link?: string | null
                                                ref?:
                                                  | {
                                                      __typename: "GenericRef"
                                                      id: string
                                                      owner?:
                                                        | { __typename: "CommentNode" }
                                                        | { __typename: "ExternalSite" }
                                                        | { __typename: "ST4Class" }
                                                        | { __typename: "ST4Node"; id: string }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                      target?:
                                                        | { __typename: "CommentNode" }
                                                        | {
                                                            __typename: "ExternalSite"
                                                            id: string
                                                            teaserData?: {
                                                              __typename: "ExternalLinkTeaserData"
                                                              title?: string | null
                                                              summary?: string | null
                                                              iconUrl?: string | null
                                                              imageUrl?: string | null
                                                              status?: Types.LinkTargetStatus | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4Class" }
                                                        | {
                                                            __typename: "ST4Node"
                                                            id: string
                                                            aspectId: any
                                                            label?: string | null
                                                            properties?: {
                                                              __typename: "ContentPropertyConnection"
                                                              edges?: Array<{
                                                                __typename: "ContentPropertyEdge"
                                                                node?: {
                                                                  __typename: "ContentProperty"
                                                                  name: string
                                                                  value?: any | null
                                                                  displayName: string
                                                                } | null
                                                              } | null> | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                    }
                                                  | {
                                                      __typename: "NodeRef"
                                                      id: string
                                                      owner?:
                                                        | { __typename: "CommentNode" }
                                                        | { __typename: "ExternalSite" }
                                                        | { __typename: "ST4Class" }
                                                        | { __typename: "ST4Node"; id: string }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                      target?:
                                                        | { __typename: "CommentNode" }
                                                        | {
                                                            __typename: "ExternalSite"
                                                            id: string
                                                            teaserData?: {
                                                              __typename: "ExternalLinkTeaserData"
                                                              title?: string | null
                                                              summary?: string | null
                                                              iconUrl?: string | null
                                                              imageUrl?: string | null
                                                              status?: Types.LinkTargetStatus | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4Class" }
                                                        | {
                                                            __typename: "ST4Node"
                                                            id: string
                                                            aspectId: any
                                                            label?: string | null
                                                            properties?: {
                                                              __typename: "ContentPropertyConnection"
                                                              edges?: Array<{
                                                                __typename: "ContentPropertyEdge"
                                                                node?: {
                                                                  __typename: "ContentProperty"
                                                                  name: string
                                                                  value?: any | null
                                                                  displayName: string
                                                                } | null
                                                              } | null> | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                    }
                                                  | null
                                              }>
                                            }
                                          | {
                                              __typename: "VideoMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              mediaType?: Types.MediaType | null
                                            }
                                          | null
                                      }
                                    | {
                                        __typename: "MediaGroupContent"
                                        media?:
                                          | {
                                              __typename: "AnimationMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              mediaType?: Types.MediaType | null
                                            }
                                          | {
                                              __typename: "ImageMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              resolution?: number | null
                                              colorDepth?: number | null
                                              size?: number | null
                                              mime?: string | null
                                              extension?: string | null
                                              allowCaching?: boolean | null
                                              mediaType?: Types.MediaType | null
                                              src?: string | null
                                              language?: string | null
                                            }
                                          | {
                                              __typename: "NoMediaInfo"
                                              id: string
                                              mediaType?: Types.MediaType | null
                                            }
                                          | {
                                              __typename: "VectorGraphicsMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              allowCaching?: boolean | null
                                              mediaType?: Types.MediaType | null
                                              language?: string | null
                                              labels: Array<{
                                                __typename: "VectorGraphicsLabel"
                                                id?: string | null
                                                sequence?: number | null
                                                autoValue?: string | null
                                                text?: string | null
                                                linkType?: Types.VectorGraphicsLabelLinkType | null
                                                link?: string | null
                                                ref?:
                                                  | {
                                                      __typename: "GenericRef"
                                                      id: string
                                                      owner?:
                                                        | { __typename: "CommentNode" }
                                                        | { __typename: "ExternalSite" }
                                                        | { __typename: "ST4Class" }
                                                        | { __typename: "ST4Node"; id: string }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                      target?:
                                                        | { __typename: "CommentNode" }
                                                        | {
                                                            __typename: "ExternalSite"
                                                            id: string
                                                            teaserData?: {
                                                              __typename: "ExternalLinkTeaserData"
                                                              title?: string | null
                                                              summary?: string | null
                                                              iconUrl?: string | null
                                                              imageUrl?: string | null
                                                              status?: Types.LinkTargetStatus | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4Class" }
                                                        | {
                                                            __typename: "ST4Node"
                                                            id: string
                                                            aspectId: any
                                                            label?: string | null
                                                            properties?: {
                                                              __typename: "ContentPropertyConnection"
                                                              edges?: Array<{
                                                                __typename: "ContentPropertyEdge"
                                                                node?: {
                                                                  __typename: "ContentProperty"
                                                                  name: string
                                                                  value?: any | null
                                                                  displayName: string
                                                                } | null
                                                              } | null> | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                    }
                                                  | {
                                                      __typename: "NodeRef"
                                                      id: string
                                                      owner?:
                                                        | { __typename: "CommentNode" }
                                                        | { __typename: "ExternalSite" }
                                                        | { __typename: "ST4Class" }
                                                        | { __typename: "ST4Node"; id: string }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                      target?:
                                                        | { __typename: "CommentNode" }
                                                        | {
                                                            __typename: "ExternalSite"
                                                            id: string
                                                            teaserData?: {
                                                              __typename: "ExternalLinkTeaserData"
                                                              title?: string | null
                                                              summary?: string | null
                                                              iconUrl?: string | null
                                                              imageUrl?: string | null
                                                              status?: Types.LinkTargetStatus | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4Class" }
                                                        | {
                                                            __typename: "ST4Node"
                                                            id: string
                                                            aspectId: any
                                                            label?: string | null
                                                            properties?: {
                                                              __typename: "ContentPropertyConnection"
                                                              edges?: Array<{
                                                                __typename: "ContentPropertyEdge"
                                                                node?: {
                                                                  __typename: "ContentProperty"
                                                                  name: string
                                                                  value?: any | null
                                                                  displayName: string
                                                                } | null
                                                              } | null> | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                    }
                                                  | null
                                              }>
                                            }
                                          | {
                                              __typename: "VideoMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              mediaType?: Types.MediaType | null
                                            }
                                          | null
                                      }
                                    | { __typename: "ProjectContent" }
                                    | { __typename: "TermContent" }
                                    | { __typename: "TextContent" }
                                    | { __typename: "TextGroupContent" }
                                    | { __typename: "VariableTableContent" }
                                    | null
                                }
                              | { __typename: "ST4TreeNode"; id: string }
                              | null
                          }>
                          media?:
                            | {
                                __typename: "AnimationMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                mediaType?: Types.MediaType | null
                              }
                            | {
                                __typename: "ImageMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                resolution?: number | null
                                colorDepth?: number | null
                                size?: number | null
                                mime?: string | null
                                extension?: string | null
                                allowCaching?: boolean | null
                                mediaType?: Types.MediaType | null
                                src?: string | null
                                language?: string | null
                              }
                            | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                            | {
                                __typename: "VectorGraphicsMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                allowCaching?: boolean | null
                                mediaType?: Types.MediaType | null
                                language?: string | null
                                labels: Array<{
                                  __typename: "VectorGraphicsLabel"
                                  id?: string | null
                                  sequence?: number | null
                                  autoValue?: string | null
                                  text?: string | null
                                  linkType?: Types.VectorGraphicsLabelLinkType | null
                                  link?: string | null
                                  ref?:
                                    | {
                                        __typename: "GenericRef"
                                        id: string
                                        owner?:
                                          | { __typename: "CommentNode" }
                                          | { __typename: "ExternalSite" }
                                          | { __typename: "ST4Class" }
                                          | { __typename: "ST4Node"; id: string }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                        target?:
                                          | { __typename: "CommentNode" }
                                          | {
                                              __typename: "ExternalSite"
                                              id: string
                                              teaserData?: {
                                                __typename: "ExternalLinkTeaserData"
                                                title?: string | null
                                                summary?: string | null
                                                iconUrl?: string | null
                                                imageUrl?: string | null
                                                status?: Types.LinkTargetStatus | null
                                              } | null
                                            }
                                          | { __typename: "ST4Class" }
                                          | {
                                              __typename: "ST4Node"
                                              id: string
                                              aspectId: any
                                              label?: string | null
                                              properties?: {
                                                __typename: "ContentPropertyConnection"
                                                edges?: Array<{
                                                  __typename: "ContentPropertyEdge"
                                                  node?: {
                                                    __typename: "ContentProperty"
                                                    name: string
                                                    value?: any | null
                                                    displayName: string
                                                  } | null
                                                } | null> | null
                                              } | null
                                            }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                      }
                                    | {
                                        __typename: "NodeRef"
                                        id: string
                                        owner?:
                                          | { __typename: "CommentNode" }
                                          | { __typename: "ExternalSite" }
                                          | { __typename: "ST4Class" }
                                          | { __typename: "ST4Node"; id: string }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                        target?:
                                          | { __typename: "CommentNode" }
                                          | {
                                              __typename: "ExternalSite"
                                              id: string
                                              teaserData?: {
                                                __typename: "ExternalLinkTeaserData"
                                                title?: string | null
                                                summary?: string | null
                                                iconUrl?: string | null
                                                imageUrl?: string | null
                                                status?: Types.LinkTargetStatus | null
                                              } | null
                                            }
                                          | { __typename: "ST4Class" }
                                          | {
                                              __typename: "ST4Node"
                                              id: string
                                              aspectId: any
                                              label?: string | null
                                              properties?: {
                                                __typename: "ContentPropertyConnection"
                                                edges?: Array<{
                                                  __typename: "ContentPropertyEdge"
                                                  node?: {
                                                    __typename: "ContentProperty"
                                                    name: string
                                                    value?: any | null
                                                    displayName: string
                                                  } | null
                                                } | null> | null
                                              } | null
                                            }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                      }
                                    | null
                                }>
                              }
                            | {
                                __typename: "VideoMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                mediaType?: Types.MediaType | null
                              }
                            | null
                        }
                      | { __typename: "ProjectContent" }
                      | { __typename: "TermContent" }
                      | { __typename: "TextContent" }
                      | { __typename: "TextGroupContent" }
                      | { __typename: "VariableTableContent" }
                      | null
                  }
                | { __typename: "ST4TreeNode"; id: string }
                | null
            }>
            variableTables?: Array<{
              __typename: "VariableTableReference"
              ref?: string | null
              node?:
                | { __typename: "CommentNode" }
                | { __typename: "ExternalSite" }
                | { __typename: "ST4Class" }
                | {
                    __typename: "ST4Node"
                    id: string
                    label?: string | null
                    aspectId: any
                    content?:
                      | { __typename: "LexiconContent" }
                      | { __typename: "LexiconEntryContent" }
                      | { __typename: "MediaContent" }
                      | { __typename: "MediaGroupContent" }
                      | { __typename: "ProjectContent" }
                      | { __typename: "TermContent" }
                      | { __typename: "TextContent" }
                      | { __typename: "TextGroupContent" }
                      | {
                          __typename: "VariableTableContent"
                          xml?: string | null
                          valueSets: Array<{ __typename: "ValueSet"; name: string; valueSetId: string }>
                          variables: Array<{
                            __typename: "Variable"
                            name: string
                            variableId: string
                            values: Array<string>
                          }>
                        }
                      | null
                  }
                | { __typename: "ST4TreeNode" }
                | null
            } | null> | null
            linkedDataNodes: Array<{
              __typename: "NodeRef"
              id: string
              target?:
                | { __typename: "CommentNode"; id: string }
                | { __typename: "ExternalSite"; id: string }
                | { __typename: "ST4Class"; id: string }
                | {
                    __typename: "ST4Node"
                    label?: string | null
                    id: string
                    metadata: Array<{
                      __typename: "MetaDataEntry"
                      label?: string | null
                      systemName: string
                      value?: string | null
                      values: Array<string>
                      users: Array<string>
                    }>
                    linkedOntologyNodes: Array<
                      | {
                          __typename: "GenericRef"
                          target?:
                            | { __typename: "CommentNode" }
                            | { __typename: "ExternalSite" }
                            | { __typename: "ST4Class" }
                            | { __typename: "ST4Node"; id: string; label?: string | null }
                            | { __typename: "ST4TreeNode" }
                            | null
                        }
                      | {
                          __typename: "NodeRef"
                          linkClassName: string
                          class?: { __typename: "ST4Class"; label?: string | null } | null
                          target?:
                            | { __typename: "CommentNode" }
                            | { __typename: "ExternalSite" }
                            | { __typename: "ST4Class" }
                            | { __typename: "ST4Node"; id: string; label?: string | null }
                            | { __typename: "ST4TreeNode" }
                            | null
                        }
                    >
                  }
                | { __typename: "ST4TreeNode"; id: string }
                | null
            }>
            lexiconEntries?: Array<{
              __typename: "NodeRef"
              id: string
              target?:
                | { __typename: "CommentNode"; id: string }
                | { __typename: "ExternalSite"; id: string }
                | { __typename: "ST4Class"; id: string }
                | {
                    __typename: "ST4Node"
                    aspectId: any
                    label?: string | null
                    id: string
                    content?:
                      | { __typename: "LexiconContent" }
                      | {
                          __typename: "LexiconEntryContent"
                          descriptionXast?: string | null
                          subject?: string | null
                          sortKey?: string | null
                          lexicon:
                            | { __typename: "CommentNode"; id: string }
                            | { __typename: "ExternalSite"; id: string }
                            | { __typename: "ST4Class"; id: string }
                            | { __typename: "ST4Node"; label?: string | null; id: string }
                            | { __typename: "ST4TreeNode"; id: string }
                          terms: Array<
                            | { __typename: "CommentNode"; id: string }
                            | { __typename: "ExternalSite"; id: string }
                            | { __typename: "ST4Class"; id: string }
                            | {
                                __typename: "ST4Node"
                                id: string
                                label?: string | null
                                aspectId: any
                                content?:
                                  | { __typename: "LexiconContent" }
                                  | { __typename: "LexiconEntryContent" }
                                  | { __typename: "MediaContent" }
                                  | { __typename: "MediaGroupContent" }
                                  | { __typename: "ProjectContent" }
                                  | {
                                      __typename: "TermContent"
                                      gramaticalGender?: string | null
                                      validity?: string | null
                                      editLanguage?: string | null
                                    }
                                  | { __typename: "TextContent" }
                                  | { __typename: "TextGroupContent" }
                                  | { __typename: "VariableTableContent" }
                                  | null
                              }
                            | { __typename: "ST4TreeNode"; id: string }
                          >
                          image?:
                            | { __typename: "CommentNode"; id: string }
                            | { __typename: "ExternalSite"; id: string }
                            | { __typename: "ST4Class"; id: string }
                            | {
                                __typename: "ST4Node"
                                aspectId: any
                                id: string
                                content?:
                                  | { __typename: "LexiconContent" }
                                  | { __typename: "LexiconEntryContent" }
                                  | {
                                      __typename: "MediaContent"
                                      media?:
                                        | {
                                            __typename: "AnimationMediaInfo"
                                            thumbnail?: string | null
                                            mediaType?: Types.MediaType | null
                                          }
                                        | {
                                            __typename: "ImageMediaInfo"
                                            src?: string | null
                                            thumbnail?: string | null
                                            mediaType?: Types.MediaType | null
                                          }
                                        | { __typename: "NoMediaInfo" }
                                        | {
                                            __typename: "VectorGraphicsMediaInfo"
                                            svg?: string | null
                                            thumbnail?: string | null
                                            mediaType?: Types.MediaType | null
                                          }
                                        | {
                                            __typename: "VideoMediaInfo"
                                            thumbnail?: string | null
                                            mediaType?: Types.MediaType | null
                                          }
                                        | null
                                    }
                                  | { __typename: "MediaGroupContent" }
                                  | { __typename: "ProjectContent" }
                                  | { __typename: "TermContent" }
                                  | { __typename: "TextContent" }
                                  | { __typename: "TextGroupContent" }
                                  | { __typename: "VariableTableContent" }
                                  | null
                              }
                            | { __typename: "ST4TreeNode"; id: string }
                            | null
                        }
                      | { __typename: "MediaContent" }
                      | { __typename: "MediaGroupContent" }
                      | { __typename: "ProjectContent" }
                      | { __typename: "TermContent" }
                      | { __typename: "TextContent" }
                      | { __typename: "TextGroupContent" }
                      | { __typename: "VariableTableContent" }
                      | null
                  }
                | { __typename: "ST4TreeNode"; id: string }
                | null
            } | null> | null
            comments: Array<{
              __typename: "CommentNode"
              id: string
              comment:
                | {
                    __typename: "BlockComment"
                    location: number
                    value?: string | null
                    authorDisplayName?: string | null
                    authorUserName?: string | null
                    commentKey: string
                    editDate?: any | null
                    modificationVersion?: number | null
                    replyToKey?: string | null
                    sortOrder?: number | null
                    commentType?: string | null
                    state?: string | null
                    color: string
                    persistanceState?: Types.PersistanceState | null
                    persistanceAction?: Types.PersistanceAction | null
                    node: {
                      __typename: "ST4Node"
                      aspectId: any
                      id: string
                      content?:
                        | { __typename: "LexiconContent" }
                        | { __typename: "LexiconEntryContent" }
                        | { __typename: "MediaContent" }
                        | { __typename: "MediaGroupContent" }
                        | { __typename: "ProjectContent" }
                        | { __typename: "TermContent" }
                        | { __typename: "TextContent"; id: string; historyNumber: number }
                        | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                        | { __typename: "VariableTableContent" }
                        | null
                    }
                    actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
                  }
                | {
                    __typename: "TextComment"
                    endLocation: number
                    startLocation?: number | null
                    value?: string | null
                    authorDisplayName?: string | null
                    authorUserName?: string | null
                    commentKey: string
                    editDate?: any | null
                    modificationVersion?: number | null
                    replyToKey?: string | null
                    sortOrder?: number | null
                    commentType?: string | null
                    state?: string | null
                    color: string
                    persistanceState?: Types.PersistanceState | null
                    persistanceAction?: Types.PersistanceAction | null
                    node: {
                      __typename: "ST4Node"
                      aspectId: any
                      id: string
                      content?:
                        | { __typename: "LexiconContent" }
                        | { __typename: "LexiconEntryContent" }
                        | { __typename: "MediaContent" }
                        | { __typename: "MediaGroupContent" }
                        | { __typename: "ProjectContent" }
                        | { __typename: "TermContent" }
                        | { __typename: "TextContent"; id: string; historyNumber: number }
                        | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                        | { __typename: "VariableTableContent" }
                        | null
                    }
                    actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
                  }
            }>
          }
        | {
            __typename: "TextGroupContent"
            currentNodeId?: any | null
            groupNodeId: any
            id: string
            xast?: string | null
            historyNumber: number
            children: Array<{
              __typename: "NodeRef"
              id: string
              target?:
                | { __typename: "CommentNode"; id: string }
                | { __typename: "ExternalSite"; id: string }
                | { __typename: "ST4Class"; id: string }
                | { __typename: "ST4Node"; label?: string | null; aspectId: any; id: string }
                | { __typename: "ST4TreeNode"; id: string }
                | null
            }>
            links?: Array<{
              __typename: "TextContentLink"
              ref?: string | null
              node?:
                | { __typename: "CommentNode"; id: string }
                | { __typename: "ExternalSite"; id: string }
                | { __typename: "ST4Class"; id: string }
                | {
                    __typename: "ST4Node"
                    id: string
                    aspectId: any
                    label?: string | null
                    properties?: {
                      __typename: "ContentPropertyConnection"
                      totalCount: number
                      edges?: Array<{
                        __typename: "ContentPropertyEdge"
                        node?: {
                          __typename: "ContentProperty"
                          typeOfValue: Types.ContentPropertyValueType
                          name: string
                          value?: any | null
                          displayName: string
                        } | null
                      } | null> | null
                    } | null
                  }
                | { __typename: "ST4TreeNode"; id: string }
                | null
            } | null> | null
            images: Array<{
              __typename: "NodeRef"
              id: string
              target?:
                | { __typename: "CommentNode"; id: string }
                | { __typename: "ExternalSite"; id: string }
                | { __typename: "ST4Class"; id: string }
                | {
                    __typename: "ST4Node"
                    aspectId: any
                    id: string
                    releaseState: {
                      __typename: "ReleaseStateValue"
                      displayName: string
                      state: Types.ReleaseState
                      isImplicitlyReleased: boolean
                    }
                    content?:
                      | { __typename: "LexiconContent" }
                      | { __typename: "LexiconEntryContent" }
                      | {
                          __typename: "MediaContent"
                          media?:
                            | {
                                __typename: "AnimationMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                mediaType?: Types.MediaType | null
                              }
                            | {
                                __typename: "ImageMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                resolution?: number | null
                                colorDepth?: number | null
                                size?: number | null
                                mime?: string | null
                                extension?: string | null
                                allowCaching?: boolean | null
                                mediaType?: Types.MediaType | null
                                src?: string | null
                                language?: string | null
                              }
                            | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                            | {
                                __typename: "VectorGraphicsMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                allowCaching?: boolean | null
                                mediaType?: Types.MediaType | null
                                language?: string | null
                                labels: Array<{
                                  __typename: "VectorGraphicsLabel"
                                  id?: string | null
                                  sequence?: number | null
                                  autoValue?: string | null
                                  text?: string | null
                                  linkType?: Types.VectorGraphicsLabelLinkType | null
                                  link?: string | null
                                  ref?:
                                    | {
                                        __typename: "GenericRef"
                                        id: string
                                        owner?:
                                          | { __typename: "CommentNode" }
                                          | { __typename: "ExternalSite" }
                                          | { __typename: "ST4Class" }
                                          | { __typename: "ST4Node"; id: string }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                        target?:
                                          | { __typename: "CommentNode" }
                                          | {
                                              __typename: "ExternalSite"
                                              id: string
                                              teaserData?: {
                                                __typename: "ExternalLinkTeaserData"
                                                title?: string | null
                                                summary?: string | null
                                                iconUrl?: string | null
                                                imageUrl?: string | null
                                                status?: Types.LinkTargetStatus | null
                                              } | null
                                            }
                                          | { __typename: "ST4Class" }
                                          | {
                                              __typename: "ST4Node"
                                              id: string
                                              aspectId: any
                                              label?: string | null
                                              properties?: {
                                                __typename: "ContentPropertyConnection"
                                                edges?: Array<{
                                                  __typename: "ContentPropertyEdge"
                                                  node?: {
                                                    __typename: "ContentProperty"
                                                    name: string
                                                    value?: any | null
                                                    displayName: string
                                                  } | null
                                                } | null> | null
                                              } | null
                                            }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                      }
                                    | {
                                        __typename: "NodeRef"
                                        id: string
                                        owner?:
                                          | { __typename: "CommentNode" }
                                          | { __typename: "ExternalSite" }
                                          | { __typename: "ST4Class" }
                                          | { __typename: "ST4Node"; id: string }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                        target?:
                                          | { __typename: "CommentNode" }
                                          | {
                                              __typename: "ExternalSite"
                                              id: string
                                              teaserData?: {
                                                __typename: "ExternalLinkTeaserData"
                                                title?: string | null
                                                summary?: string | null
                                                iconUrl?: string | null
                                                imageUrl?: string | null
                                                status?: Types.LinkTargetStatus | null
                                              } | null
                                            }
                                          | { __typename: "ST4Class" }
                                          | {
                                              __typename: "ST4Node"
                                              id: string
                                              aspectId: any
                                              label?: string | null
                                              properties?: {
                                                __typename: "ContentPropertyConnection"
                                                edges?: Array<{
                                                  __typename: "ContentPropertyEdge"
                                                  node?: {
                                                    __typename: "ContentProperty"
                                                    name: string
                                                    value?: any | null
                                                    displayName: string
                                                  } | null
                                                } | null> | null
                                              } | null
                                            }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                      }
                                    | null
                                }>
                              }
                            | {
                                __typename: "VideoMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                mediaType?: Types.MediaType | null
                              }
                            | null
                        }
                      | {
                          __typename: "MediaGroupContent"
                          children: Array<{
                            __typename: "NodeRef"
                            id: string
                            target?:
                              | { __typename: "CommentNode"; id: string }
                              | { __typename: "ExternalSite"; id: string }
                              | { __typename: "ST4Class"; id: string }
                              | {
                                  __typename: "ST4Node"
                                  id: string
                                  aspectId: any
                                  content?:
                                    | { __typename: "LexiconContent" }
                                    | { __typename: "LexiconEntryContent" }
                                    | {
                                        __typename: "MediaContent"
                                        media?:
                                          | {
                                              __typename: "AnimationMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              mediaType?: Types.MediaType | null
                                            }
                                          | {
                                              __typename: "ImageMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              resolution?: number | null
                                              colorDepth?: number | null
                                              size?: number | null
                                              mime?: string | null
                                              extension?: string | null
                                              allowCaching?: boolean | null
                                              mediaType?: Types.MediaType | null
                                              src?: string | null
                                              language?: string | null
                                            }
                                          | {
                                              __typename: "NoMediaInfo"
                                              id: string
                                              mediaType?: Types.MediaType | null
                                            }
                                          | {
                                              __typename: "VectorGraphicsMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              allowCaching?: boolean | null
                                              mediaType?: Types.MediaType | null
                                              language?: string | null
                                              labels: Array<{
                                                __typename: "VectorGraphicsLabel"
                                                id?: string | null
                                                sequence?: number | null
                                                autoValue?: string | null
                                                text?: string | null
                                                linkType?: Types.VectorGraphicsLabelLinkType | null
                                                link?: string | null
                                                ref?:
                                                  | {
                                                      __typename: "GenericRef"
                                                      id: string
                                                      owner?:
                                                        | { __typename: "CommentNode" }
                                                        | { __typename: "ExternalSite" }
                                                        | { __typename: "ST4Class" }
                                                        | { __typename: "ST4Node"; id: string }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                      target?:
                                                        | { __typename: "CommentNode" }
                                                        | {
                                                            __typename: "ExternalSite"
                                                            id: string
                                                            teaserData?: {
                                                              __typename: "ExternalLinkTeaserData"
                                                              title?: string | null
                                                              summary?: string | null
                                                              iconUrl?: string | null
                                                              imageUrl?: string | null
                                                              status?: Types.LinkTargetStatus | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4Class" }
                                                        | {
                                                            __typename: "ST4Node"
                                                            id: string
                                                            aspectId: any
                                                            label?: string | null
                                                            properties?: {
                                                              __typename: "ContentPropertyConnection"
                                                              edges?: Array<{
                                                                __typename: "ContentPropertyEdge"
                                                                node?: {
                                                                  __typename: "ContentProperty"
                                                                  name: string
                                                                  value?: any | null
                                                                  displayName: string
                                                                } | null
                                                              } | null> | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                    }
                                                  | {
                                                      __typename: "NodeRef"
                                                      id: string
                                                      owner?:
                                                        | { __typename: "CommentNode" }
                                                        | { __typename: "ExternalSite" }
                                                        | { __typename: "ST4Class" }
                                                        | { __typename: "ST4Node"; id: string }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                      target?:
                                                        | { __typename: "CommentNode" }
                                                        | {
                                                            __typename: "ExternalSite"
                                                            id: string
                                                            teaserData?: {
                                                              __typename: "ExternalLinkTeaserData"
                                                              title?: string | null
                                                              summary?: string | null
                                                              iconUrl?: string | null
                                                              imageUrl?: string | null
                                                              status?: Types.LinkTargetStatus | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4Class" }
                                                        | {
                                                            __typename: "ST4Node"
                                                            id: string
                                                            aspectId: any
                                                            label?: string | null
                                                            properties?: {
                                                              __typename: "ContentPropertyConnection"
                                                              edges?: Array<{
                                                                __typename: "ContentPropertyEdge"
                                                                node?: {
                                                                  __typename: "ContentProperty"
                                                                  name: string
                                                                  value?: any | null
                                                                  displayName: string
                                                                } | null
                                                              } | null> | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                    }
                                                  | null
                                              }>
                                            }
                                          | {
                                              __typename: "VideoMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              mediaType?: Types.MediaType | null
                                            }
                                          | null
                                      }
                                    | {
                                        __typename: "MediaGroupContent"
                                        media?:
                                          | {
                                              __typename: "AnimationMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              mediaType?: Types.MediaType | null
                                            }
                                          | {
                                              __typename: "ImageMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              resolution?: number | null
                                              colorDepth?: number | null
                                              size?: number | null
                                              mime?: string | null
                                              extension?: string | null
                                              allowCaching?: boolean | null
                                              mediaType?: Types.MediaType | null
                                              src?: string | null
                                              language?: string | null
                                            }
                                          | {
                                              __typename: "NoMediaInfo"
                                              id: string
                                              mediaType?: Types.MediaType | null
                                            }
                                          | {
                                              __typename: "VectorGraphicsMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              allowCaching?: boolean | null
                                              mediaType?: Types.MediaType | null
                                              language?: string | null
                                              labels: Array<{
                                                __typename: "VectorGraphicsLabel"
                                                id?: string | null
                                                sequence?: number | null
                                                autoValue?: string | null
                                                text?: string | null
                                                linkType?: Types.VectorGraphicsLabelLinkType | null
                                                link?: string | null
                                                ref?:
                                                  | {
                                                      __typename: "GenericRef"
                                                      id: string
                                                      owner?:
                                                        | { __typename: "CommentNode" }
                                                        | { __typename: "ExternalSite" }
                                                        | { __typename: "ST4Class" }
                                                        | { __typename: "ST4Node"; id: string }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                      target?:
                                                        | { __typename: "CommentNode" }
                                                        | {
                                                            __typename: "ExternalSite"
                                                            id: string
                                                            teaserData?: {
                                                              __typename: "ExternalLinkTeaserData"
                                                              title?: string | null
                                                              summary?: string | null
                                                              iconUrl?: string | null
                                                              imageUrl?: string | null
                                                              status?: Types.LinkTargetStatus | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4Class" }
                                                        | {
                                                            __typename: "ST4Node"
                                                            id: string
                                                            aspectId: any
                                                            label?: string | null
                                                            properties?: {
                                                              __typename: "ContentPropertyConnection"
                                                              edges?: Array<{
                                                                __typename: "ContentPropertyEdge"
                                                                node?: {
                                                                  __typename: "ContentProperty"
                                                                  name: string
                                                                  value?: any | null
                                                                  displayName: string
                                                                } | null
                                                              } | null> | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                    }
                                                  | {
                                                      __typename: "NodeRef"
                                                      id: string
                                                      owner?:
                                                        | { __typename: "CommentNode" }
                                                        | { __typename: "ExternalSite" }
                                                        | { __typename: "ST4Class" }
                                                        | { __typename: "ST4Node"; id: string }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                      target?:
                                                        | { __typename: "CommentNode" }
                                                        | {
                                                            __typename: "ExternalSite"
                                                            id: string
                                                            teaserData?: {
                                                              __typename: "ExternalLinkTeaserData"
                                                              title?: string | null
                                                              summary?: string | null
                                                              iconUrl?: string | null
                                                              imageUrl?: string | null
                                                              status?: Types.LinkTargetStatus | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4Class" }
                                                        | {
                                                            __typename: "ST4Node"
                                                            id: string
                                                            aspectId: any
                                                            label?: string | null
                                                            properties?: {
                                                              __typename: "ContentPropertyConnection"
                                                              edges?: Array<{
                                                                __typename: "ContentPropertyEdge"
                                                                node?: {
                                                                  __typename: "ContentProperty"
                                                                  name: string
                                                                  value?: any | null
                                                                  displayName: string
                                                                } | null
                                                              } | null> | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                    }
                                                  | null
                                              }>
                                            }
                                          | {
                                              __typename: "VideoMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              mediaType?: Types.MediaType | null
                                            }
                                          | null
                                      }
                                    | { __typename: "ProjectContent" }
                                    | { __typename: "TermContent" }
                                    | { __typename: "TextContent" }
                                    | { __typename: "TextGroupContent" }
                                    | { __typename: "VariableTableContent" }
                                    | null
                                }
                              | { __typename: "ST4TreeNode"; id: string }
                              | null
                          }>
                          media?:
                            | {
                                __typename: "AnimationMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                mediaType?: Types.MediaType | null
                              }
                            | {
                                __typename: "ImageMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                resolution?: number | null
                                colorDepth?: number | null
                                size?: number | null
                                mime?: string | null
                                extension?: string | null
                                allowCaching?: boolean | null
                                mediaType?: Types.MediaType | null
                                src?: string | null
                                language?: string | null
                              }
                            | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                            | {
                                __typename: "VectorGraphicsMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                allowCaching?: boolean | null
                                mediaType?: Types.MediaType | null
                                language?: string | null
                                labels: Array<{
                                  __typename: "VectorGraphicsLabel"
                                  id?: string | null
                                  sequence?: number | null
                                  autoValue?: string | null
                                  text?: string | null
                                  linkType?: Types.VectorGraphicsLabelLinkType | null
                                  link?: string | null
                                  ref?:
                                    | {
                                        __typename: "GenericRef"
                                        id: string
                                        owner?:
                                          | { __typename: "CommentNode" }
                                          | { __typename: "ExternalSite" }
                                          | { __typename: "ST4Class" }
                                          | { __typename: "ST4Node"; id: string }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                        target?:
                                          | { __typename: "CommentNode" }
                                          | {
                                              __typename: "ExternalSite"
                                              id: string
                                              teaserData?: {
                                                __typename: "ExternalLinkTeaserData"
                                                title?: string | null
                                                summary?: string | null
                                                iconUrl?: string | null
                                                imageUrl?: string | null
                                                status?: Types.LinkTargetStatus | null
                                              } | null
                                            }
                                          | { __typename: "ST4Class" }
                                          | {
                                              __typename: "ST4Node"
                                              id: string
                                              aspectId: any
                                              label?: string | null
                                              properties?: {
                                                __typename: "ContentPropertyConnection"
                                                edges?: Array<{
                                                  __typename: "ContentPropertyEdge"
                                                  node?: {
                                                    __typename: "ContentProperty"
                                                    name: string
                                                    value?: any | null
                                                    displayName: string
                                                  } | null
                                                } | null> | null
                                              } | null
                                            }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                      }
                                    | {
                                        __typename: "NodeRef"
                                        id: string
                                        owner?:
                                          | { __typename: "CommentNode" }
                                          | { __typename: "ExternalSite" }
                                          | { __typename: "ST4Class" }
                                          | { __typename: "ST4Node"; id: string }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                        target?:
                                          | { __typename: "CommentNode" }
                                          | {
                                              __typename: "ExternalSite"
                                              id: string
                                              teaserData?: {
                                                __typename: "ExternalLinkTeaserData"
                                                title?: string | null
                                                summary?: string | null
                                                iconUrl?: string | null
                                                imageUrl?: string | null
                                                status?: Types.LinkTargetStatus | null
                                              } | null
                                            }
                                          | { __typename: "ST4Class" }
                                          | {
                                              __typename: "ST4Node"
                                              id: string
                                              aspectId: any
                                              label?: string | null
                                              properties?: {
                                                __typename: "ContentPropertyConnection"
                                                edges?: Array<{
                                                  __typename: "ContentPropertyEdge"
                                                  node?: {
                                                    __typename: "ContentProperty"
                                                    name: string
                                                    value?: any | null
                                                    displayName: string
                                                  } | null
                                                } | null> | null
                                              } | null
                                            }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                      }
                                    | null
                                }>
                              }
                            | {
                                __typename: "VideoMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                mediaType?: Types.MediaType | null
                              }
                            | null
                        }
                      | { __typename: "ProjectContent" }
                      | { __typename: "TermContent" }
                      | { __typename: "TextContent" }
                      | { __typename: "TextGroupContent" }
                      | { __typename: "VariableTableContent" }
                      | null
                  }
                | { __typename: "ST4TreeNode"; id: string }
                | null
            }>
            variableTables?: Array<{
              __typename: "VariableTableReference"
              ref?: string | null
              node?:
                | { __typename: "CommentNode" }
                | { __typename: "ExternalSite" }
                | { __typename: "ST4Class" }
                | {
                    __typename: "ST4Node"
                    id: string
                    label?: string | null
                    aspectId: any
                    content?:
                      | { __typename: "LexiconContent" }
                      | { __typename: "LexiconEntryContent" }
                      | { __typename: "MediaContent" }
                      | { __typename: "MediaGroupContent" }
                      | { __typename: "ProjectContent" }
                      | { __typename: "TermContent" }
                      | { __typename: "TextContent" }
                      | { __typename: "TextGroupContent" }
                      | {
                          __typename: "VariableTableContent"
                          xml?: string | null
                          valueSets: Array<{ __typename: "ValueSet"; name: string; valueSetId: string }>
                          variables: Array<{
                            __typename: "Variable"
                            name: string
                            variableId: string
                            values: Array<string>
                          }>
                        }
                      | null
                  }
                | { __typename: "ST4TreeNode" }
                | null
            } | null> | null
            linkedDataNodes: Array<{
              __typename: "NodeRef"
              id: string
              target?:
                | { __typename: "CommentNode"; id: string }
                | { __typename: "ExternalSite"; id: string }
                | { __typename: "ST4Class"; id: string }
                | {
                    __typename: "ST4Node"
                    label?: string | null
                    id: string
                    metadata: Array<{
                      __typename: "MetaDataEntry"
                      label?: string | null
                      systemName: string
                      value?: string | null
                      values: Array<string>
                      users: Array<string>
                    }>
                    linkedOntologyNodes: Array<
                      | {
                          __typename: "GenericRef"
                          target?:
                            | { __typename: "CommentNode" }
                            | { __typename: "ExternalSite" }
                            | { __typename: "ST4Class" }
                            | { __typename: "ST4Node"; id: string; label?: string | null }
                            | { __typename: "ST4TreeNode" }
                            | null
                        }
                      | {
                          __typename: "NodeRef"
                          linkClassName: string
                          class?: { __typename: "ST4Class"; label?: string | null } | null
                          target?:
                            | { __typename: "CommentNode" }
                            | { __typename: "ExternalSite" }
                            | { __typename: "ST4Class" }
                            | { __typename: "ST4Node"; id: string; label?: string | null }
                            | { __typename: "ST4TreeNode" }
                            | null
                        }
                    >
                  }
                | { __typename: "ST4TreeNode"; id: string }
                | null
            }>
            lexiconEntries?: Array<{
              __typename: "NodeRef"
              id: string
              target?:
                | { __typename: "CommentNode"; id: string }
                | { __typename: "ExternalSite"; id: string }
                | { __typename: "ST4Class"; id: string }
                | {
                    __typename: "ST4Node"
                    aspectId: any
                    label?: string | null
                    id: string
                    content?:
                      | { __typename: "LexiconContent" }
                      | {
                          __typename: "LexiconEntryContent"
                          descriptionXast?: string | null
                          subject?: string | null
                          sortKey?: string | null
                          lexicon:
                            | { __typename: "CommentNode"; id: string }
                            | { __typename: "ExternalSite"; id: string }
                            | { __typename: "ST4Class"; id: string }
                            | { __typename: "ST4Node"; label?: string | null; id: string }
                            | { __typename: "ST4TreeNode"; id: string }
                          terms: Array<
                            | { __typename: "CommentNode"; id: string }
                            | { __typename: "ExternalSite"; id: string }
                            | { __typename: "ST4Class"; id: string }
                            | {
                                __typename: "ST4Node"
                                id: string
                                label?: string | null
                                aspectId: any
                                content?:
                                  | { __typename: "LexiconContent" }
                                  | { __typename: "LexiconEntryContent" }
                                  | { __typename: "MediaContent" }
                                  | { __typename: "MediaGroupContent" }
                                  | { __typename: "ProjectContent" }
                                  | {
                                      __typename: "TermContent"
                                      gramaticalGender?: string | null
                                      validity?: string | null
                                      editLanguage?: string | null
                                    }
                                  | { __typename: "TextContent" }
                                  | { __typename: "TextGroupContent" }
                                  | { __typename: "VariableTableContent" }
                                  | null
                              }
                            | { __typename: "ST4TreeNode"; id: string }
                          >
                          image?:
                            | { __typename: "CommentNode"; id: string }
                            | { __typename: "ExternalSite"; id: string }
                            | { __typename: "ST4Class"; id: string }
                            | {
                                __typename: "ST4Node"
                                aspectId: any
                                id: string
                                content?:
                                  | { __typename: "LexiconContent" }
                                  | { __typename: "LexiconEntryContent" }
                                  | {
                                      __typename: "MediaContent"
                                      media?:
                                        | {
                                            __typename: "AnimationMediaInfo"
                                            thumbnail?: string | null
                                            mediaType?: Types.MediaType | null
                                          }
                                        | {
                                            __typename: "ImageMediaInfo"
                                            src?: string | null
                                            thumbnail?: string | null
                                            mediaType?: Types.MediaType | null
                                          }
                                        | { __typename: "NoMediaInfo" }
                                        | {
                                            __typename: "VectorGraphicsMediaInfo"
                                            svg?: string | null
                                            thumbnail?: string | null
                                            mediaType?: Types.MediaType | null
                                          }
                                        | {
                                            __typename: "VideoMediaInfo"
                                            thumbnail?: string | null
                                            mediaType?: Types.MediaType | null
                                          }
                                        | null
                                    }
                                  | { __typename: "MediaGroupContent" }
                                  | { __typename: "ProjectContent" }
                                  | { __typename: "TermContent" }
                                  | { __typename: "TextContent" }
                                  | { __typename: "TextGroupContent" }
                                  | { __typename: "VariableTableContent" }
                                  | null
                              }
                            | { __typename: "ST4TreeNode"; id: string }
                            | null
                        }
                      | { __typename: "MediaContent" }
                      | { __typename: "MediaGroupContent" }
                      | { __typename: "ProjectContent" }
                      | { __typename: "TermContent" }
                      | { __typename: "TextContent" }
                      | { __typename: "TextGroupContent" }
                      | { __typename: "VariableTableContent" }
                      | null
                  }
                | { __typename: "ST4TreeNode"; id: string }
                | null
            } | null> | null
            comments: Array<{
              __typename: "CommentNode"
              id: string
              comment:
                | {
                    __typename: "BlockComment"
                    location: number
                    value?: string | null
                    authorDisplayName?: string | null
                    authorUserName?: string | null
                    commentKey: string
                    editDate?: any | null
                    modificationVersion?: number | null
                    replyToKey?: string | null
                    sortOrder?: number | null
                    commentType?: string | null
                    state?: string | null
                    color: string
                    persistanceState?: Types.PersistanceState | null
                    persistanceAction?: Types.PersistanceAction | null
                    node: {
                      __typename: "ST4Node"
                      aspectId: any
                      id: string
                      content?:
                        | { __typename: "LexiconContent" }
                        | { __typename: "LexiconEntryContent" }
                        | { __typename: "MediaContent" }
                        | { __typename: "MediaGroupContent" }
                        | { __typename: "ProjectContent" }
                        | { __typename: "TermContent" }
                        | { __typename: "TextContent"; id: string; historyNumber: number }
                        | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                        | { __typename: "VariableTableContent" }
                        | null
                    }
                    actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
                  }
                | {
                    __typename: "TextComment"
                    endLocation: number
                    startLocation?: number | null
                    value?: string | null
                    authorDisplayName?: string | null
                    authorUserName?: string | null
                    commentKey: string
                    editDate?: any | null
                    modificationVersion?: number | null
                    replyToKey?: string | null
                    sortOrder?: number | null
                    commentType?: string | null
                    state?: string | null
                    color: string
                    persistanceState?: Types.PersistanceState | null
                    persistanceAction?: Types.PersistanceAction | null
                    node: {
                      __typename: "ST4Node"
                      aspectId: any
                      id: string
                      content?:
                        | { __typename: "LexiconContent" }
                        | { __typename: "LexiconEntryContent" }
                        | { __typename: "MediaContent" }
                        | { __typename: "MediaGroupContent" }
                        | { __typename: "ProjectContent" }
                        | { __typename: "TermContent" }
                        | { __typename: "TextContent"; id: string; historyNumber: number }
                        | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                        | { __typename: "VariableTableContent" }
                        | null
                    }
                    actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
                  }
            }>
          }
        | { __typename: "VariableTableContent" }
        | null
    }
  }>
  links?: Array<{
    __typename: "TextContentLink"
    ref?: string | null
    node?:
      | { __typename: "CommentNode"; id: string }
      | { __typename: "ExternalSite"; id: string }
      | { __typename: "ST4Class"; id: string }
      | {
          __typename: "ST4Node"
          id: string
          aspectId: any
          label?: string | null
          properties?: {
            __typename: "ContentPropertyConnection"
            totalCount: number
            edges?: Array<{
              __typename: "ContentPropertyEdge"
              node?: {
                __typename: "ContentProperty"
                typeOfValue: Types.ContentPropertyValueType
                name: string
                value?: any | null
                displayName: string
              } | null
            } | null> | null
          } | null
        }
      | { __typename: "ST4TreeNode"; id: string }
      | null
  } | null> | null
  images: Array<{
    __typename: "NodeRef"
    id: string
    target?:
      | { __typename: "CommentNode"; id: string }
      | { __typename: "ExternalSite"; id: string }
      | { __typename: "ST4Class"; id: string }
      | {
          __typename: "ST4Node"
          aspectId: any
          id: string
          releaseState: {
            __typename: "ReleaseStateValue"
            displayName: string
            state: Types.ReleaseState
            isImplicitlyReleased: boolean
          }
          content?:
            | { __typename: "LexiconContent" }
            | { __typename: "LexiconEntryContent" }
            | {
                __typename: "MediaContent"
                media?:
                  | {
                      __typename: "AnimationMediaInfo"
                      id: string
                      resourceId?: string | null
                      thumbnail?: string | null
                      width?: number | null
                      height?: number | null
                      mediaType?: Types.MediaType | null
                    }
                  | {
                      __typename: "ImageMediaInfo"
                      id: string
                      resourceId?: string | null
                      thumbnail?: string | null
                      width?: number | null
                      height?: number | null
                      resolution?: number | null
                      colorDepth?: number | null
                      size?: number | null
                      mime?: string | null
                      extension?: string | null
                      allowCaching?: boolean | null
                      mediaType?: Types.MediaType | null
                      src?: string | null
                      language?: string | null
                    }
                  | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                  | {
                      __typename: "VectorGraphicsMediaInfo"
                      id: string
                      resourceId?: string | null
                      thumbnail?: string | null
                      width?: number | null
                      height?: number | null
                      allowCaching?: boolean | null
                      mediaType?: Types.MediaType | null
                      language?: string | null
                      labels: Array<{
                        __typename: "VectorGraphicsLabel"
                        id?: string | null
                        sequence?: number | null
                        autoValue?: string | null
                        text?: string | null
                        linkType?: Types.VectorGraphicsLabelLinkType | null
                        link?: string | null
                        ref?:
                          | {
                              __typename: "GenericRef"
                              id: string
                              owner?:
                                | { __typename: "CommentNode" }
                                | { __typename: "ExternalSite" }
                                | { __typename: "ST4Class" }
                                | { __typename: "ST4Node"; id: string }
                                | { __typename: "ST4TreeNode" }
                                | null
                              target?:
                                | { __typename: "CommentNode" }
                                | {
                                    __typename: "ExternalSite"
                                    id: string
                                    teaserData?: {
                                      __typename: "ExternalLinkTeaserData"
                                      title?: string | null
                                      summary?: string | null
                                      iconUrl?: string | null
                                      imageUrl?: string | null
                                      status?: Types.LinkTargetStatus | null
                                    } | null
                                  }
                                | { __typename: "ST4Class" }
                                | {
                                    __typename: "ST4Node"
                                    id: string
                                    aspectId: any
                                    label?: string | null
                                    properties?: {
                                      __typename: "ContentPropertyConnection"
                                      edges?: Array<{
                                        __typename: "ContentPropertyEdge"
                                        node?: {
                                          __typename: "ContentProperty"
                                          name: string
                                          value?: any | null
                                          displayName: string
                                        } | null
                                      } | null> | null
                                    } | null
                                  }
                                | { __typename: "ST4TreeNode" }
                                | null
                            }
                          | {
                              __typename: "NodeRef"
                              id: string
                              owner?:
                                | { __typename: "CommentNode" }
                                | { __typename: "ExternalSite" }
                                | { __typename: "ST4Class" }
                                | { __typename: "ST4Node"; id: string }
                                | { __typename: "ST4TreeNode" }
                                | null
                              target?:
                                | { __typename: "CommentNode" }
                                | {
                                    __typename: "ExternalSite"
                                    id: string
                                    teaserData?: {
                                      __typename: "ExternalLinkTeaserData"
                                      title?: string | null
                                      summary?: string | null
                                      iconUrl?: string | null
                                      imageUrl?: string | null
                                      status?: Types.LinkTargetStatus | null
                                    } | null
                                  }
                                | { __typename: "ST4Class" }
                                | {
                                    __typename: "ST4Node"
                                    id: string
                                    aspectId: any
                                    label?: string | null
                                    properties?: {
                                      __typename: "ContentPropertyConnection"
                                      edges?: Array<{
                                        __typename: "ContentPropertyEdge"
                                        node?: {
                                          __typename: "ContentProperty"
                                          name: string
                                          value?: any | null
                                          displayName: string
                                        } | null
                                      } | null> | null
                                    } | null
                                  }
                                | { __typename: "ST4TreeNode" }
                                | null
                            }
                          | null
                      }>
                    }
                  | {
                      __typename: "VideoMediaInfo"
                      id: string
                      resourceId?: string | null
                      thumbnail?: string | null
                      width?: number | null
                      height?: number | null
                      mediaType?: Types.MediaType | null
                    }
                  | null
              }
            | {
                __typename: "MediaGroupContent"
                children: Array<{
                  __typename: "NodeRef"
                  id: string
                  target?:
                    | { __typename: "CommentNode"; id: string }
                    | { __typename: "ExternalSite"; id: string }
                    | { __typename: "ST4Class"; id: string }
                    | {
                        __typename: "ST4Node"
                        id: string
                        aspectId: any
                        content?:
                          | { __typename: "LexiconContent" }
                          | { __typename: "LexiconEntryContent" }
                          | {
                              __typename: "MediaContent"
                              media?:
                                | {
                                    __typename: "AnimationMediaInfo"
                                    id: string
                                    resourceId?: string | null
                                    thumbnail?: string | null
                                    width?: number | null
                                    height?: number | null
                                    mediaType?: Types.MediaType | null
                                  }
                                | {
                                    __typename: "ImageMediaInfo"
                                    id: string
                                    resourceId?: string | null
                                    thumbnail?: string | null
                                    width?: number | null
                                    height?: number | null
                                    resolution?: number | null
                                    colorDepth?: number | null
                                    size?: number | null
                                    mime?: string | null
                                    extension?: string | null
                                    allowCaching?: boolean | null
                                    mediaType?: Types.MediaType | null
                                    src?: string | null
                                    language?: string | null
                                  }
                                | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                                | {
                                    __typename: "VectorGraphicsMediaInfo"
                                    id: string
                                    resourceId?: string | null
                                    thumbnail?: string | null
                                    width?: number | null
                                    height?: number | null
                                    allowCaching?: boolean | null
                                    mediaType?: Types.MediaType | null
                                    language?: string | null
                                    labels: Array<{
                                      __typename: "VectorGraphicsLabel"
                                      id?: string | null
                                      sequence?: number | null
                                      autoValue?: string | null
                                      text?: string | null
                                      linkType?: Types.VectorGraphicsLabelLinkType | null
                                      link?: string | null
                                      ref?:
                                        | {
                                            __typename: "GenericRef"
                                            id: string
                                            owner?:
                                              | { __typename: "CommentNode" }
                                              | { __typename: "ExternalSite" }
                                              | { __typename: "ST4Class" }
                                              | { __typename: "ST4Node"; id: string }
                                              | { __typename: "ST4TreeNode" }
                                              | null
                                            target?:
                                              | { __typename: "CommentNode" }
                                              | {
                                                  __typename: "ExternalSite"
                                                  id: string
                                                  teaserData?: {
                                                    __typename: "ExternalLinkTeaserData"
                                                    title?: string | null
                                                    summary?: string | null
                                                    iconUrl?: string | null
                                                    imageUrl?: string | null
                                                    status?: Types.LinkTargetStatus | null
                                                  } | null
                                                }
                                              | { __typename: "ST4Class" }
                                              | {
                                                  __typename: "ST4Node"
                                                  id: string
                                                  aspectId: any
                                                  label?: string | null
                                                  properties?: {
                                                    __typename: "ContentPropertyConnection"
                                                    edges?: Array<{
                                                      __typename: "ContentPropertyEdge"
                                                      node?: {
                                                        __typename: "ContentProperty"
                                                        name: string
                                                        value?: any | null
                                                        displayName: string
                                                      } | null
                                                    } | null> | null
                                                  } | null
                                                }
                                              | { __typename: "ST4TreeNode" }
                                              | null
                                          }
                                        | {
                                            __typename: "NodeRef"
                                            id: string
                                            owner?:
                                              | { __typename: "CommentNode" }
                                              | { __typename: "ExternalSite" }
                                              | { __typename: "ST4Class" }
                                              | { __typename: "ST4Node"; id: string }
                                              | { __typename: "ST4TreeNode" }
                                              | null
                                            target?:
                                              | { __typename: "CommentNode" }
                                              | {
                                                  __typename: "ExternalSite"
                                                  id: string
                                                  teaserData?: {
                                                    __typename: "ExternalLinkTeaserData"
                                                    title?: string | null
                                                    summary?: string | null
                                                    iconUrl?: string | null
                                                    imageUrl?: string | null
                                                    status?: Types.LinkTargetStatus | null
                                                  } | null
                                                }
                                              | { __typename: "ST4Class" }
                                              | {
                                                  __typename: "ST4Node"
                                                  id: string
                                                  aspectId: any
                                                  label?: string | null
                                                  properties?: {
                                                    __typename: "ContentPropertyConnection"
                                                    edges?: Array<{
                                                      __typename: "ContentPropertyEdge"
                                                      node?: {
                                                        __typename: "ContentProperty"
                                                        name: string
                                                        value?: any | null
                                                        displayName: string
                                                      } | null
                                                    } | null> | null
                                                  } | null
                                                }
                                              | { __typename: "ST4TreeNode" }
                                              | null
                                          }
                                        | null
                                    }>
                                  }
                                | {
                                    __typename: "VideoMediaInfo"
                                    id: string
                                    resourceId?: string | null
                                    thumbnail?: string | null
                                    width?: number | null
                                    height?: number | null
                                    mediaType?: Types.MediaType | null
                                  }
                                | null
                            }
                          | {
                              __typename: "MediaGroupContent"
                              media?:
                                | {
                                    __typename: "AnimationMediaInfo"
                                    id: string
                                    resourceId?: string | null
                                    thumbnail?: string | null
                                    width?: number | null
                                    height?: number | null
                                    mediaType?: Types.MediaType | null
                                  }
                                | {
                                    __typename: "ImageMediaInfo"
                                    id: string
                                    resourceId?: string | null
                                    thumbnail?: string | null
                                    width?: number | null
                                    height?: number | null
                                    resolution?: number | null
                                    colorDepth?: number | null
                                    size?: number | null
                                    mime?: string | null
                                    extension?: string | null
                                    allowCaching?: boolean | null
                                    mediaType?: Types.MediaType | null
                                    src?: string | null
                                    language?: string | null
                                  }
                                | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                                | {
                                    __typename: "VectorGraphicsMediaInfo"
                                    id: string
                                    resourceId?: string | null
                                    thumbnail?: string | null
                                    width?: number | null
                                    height?: number | null
                                    allowCaching?: boolean | null
                                    mediaType?: Types.MediaType | null
                                    language?: string | null
                                    labels: Array<{
                                      __typename: "VectorGraphicsLabel"
                                      id?: string | null
                                      sequence?: number | null
                                      autoValue?: string | null
                                      text?: string | null
                                      linkType?: Types.VectorGraphicsLabelLinkType | null
                                      link?: string | null
                                      ref?:
                                        | {
                                            __typename: "GenericRef"
                                            id: string
                                            owner?:
                                              | { __typename: "CommentNode" }
                                              | { __typename: "ExternalSite" }
                                              | { __typename: "ST4Class" }
                                              | { __typename: "ST4Node"; id: string }
                                              | { __typename: "ST4TreeNode" }
                                              | null
                                            target?:
                                              | { __typename: "CommentNode" }
                                              | {
                                                  __typename: "ExternalSite"
                                                  id: string
                                                  teaserData?: {
                                                    __typename: "ExternalLinkTeaserData"
                                                    title?: string | null
                                                    summary?: string | null
                                                    iconUrl?: string | null
                                                    imageUrl?: string | null
                                                    status?: Types.LinkTargetStatus | null
                                                  } | null
                                                }
                                              | { __typename: "ST4Class" }
                                              | {
                                                  __typename: "ST4Node"
                                                  id: string
                                                  aspectId: any
                                                  label?: string | null
                                                  properties?: {
                                                    __typename: "ContentPropertyConnection"
                                                    edges?: Array<{
                                                      __typename: "ContentPropertyEdge"
                                                      node?: {
                                                        __typename: "ContentProperty"
                                                        name: string
                                                        value?: any | null
                                                        displayName: string
                                                      } | null
                                                    } | null> | null
                                                  } | null
                                                }
                                              | { __typename: "ST4TreeNode" }
                                              | null
                                          }
                                        | {
                                            __typename: "NodeRef"
                                            id: string
                                            owner?:
                                              | { __typename: "CommentNode" }
                                              | { __typename: "ExternalSite" }
                                              | { __typename: "ST4Class" }
                                              | { __typename: "ST4Node"; id: string }
                                              | { __typename: "ST4TreeNode" }
                                              | null
                                            target?:
                                              | { __typename: "CommentNode" }
                                              | {
                                                  __typename: "ExternalSite"
                                                  id: string
                                                  teaserData?: {
                                                    __typename: "ExternalLinkTeaserData"
                                                    title?: string | null
                                                    summary?: string | null
                                                    iconUrl?: string | null
                                                    imageUrl?: string | null
                                                    status?: Types.LinkTargetStatus | null
                                                  } | null
                                                }
                                              | { __typename: "ST4Class" }
                                              | {
                                                  __typename: "ST4Node"
                                                  id: string
                                                  aspectId: any
                                                  label?: string | null
                                                  properties?: {
                                                    __typename: "ContentPropertyConnection"
                                                    edges?: Array<{
                                                      __typename: "ContentPropertyEdge"
                                                      node?: {
                                                        __typename: "ContentProperty"
                                                        name: string
                                                        value?: any | null
                                                        displayName: string
                                                      } | null
                                                    } | null> | null
                                                  } | null
                                                }
                                              | { __typename: "ST4TreeNode" }
                                              | null
                                          }
                                        | null
                                    }>
                                  }
                                | {
                                    __typename: "VideoMediaInfo"
                                    id: string
                                    resourceId?: string | null
                                    thumbnail?: string | null
                                    width?: number | null
                                    height?: number | null
                                    mediaType?: Types.MediaType | null
                                  }
                                | null
                            }
                          | { __typename: "ProjectContent" }
                          | { __typename: "TermContent" }
                          | { __typename: "TextContent" }
                          | { __typename: "TextGroupContent" }
                          | { __typename: "VariableTableContent" }
                          | null
                      }
                    | { __typename: "ST4TreeNode"; id: string }
                    | null
                }>
                media?:
                  | {
                      __typename: "AnimationMediaInfo"
                      id: string
                      resourceId?: string | null
                      thumbnail?: string | null
                      width?: number | null
                      height?: number | null
                      mediaType?: Types.MediaType | null
                    }
                  | {
                      __typename: "ImageMediaInfo"
                      id: string
                      resourceId?: string | null
                      thumbnail?: string | null
                      width?: number | null
                      height?: number | null
                      resolution?: number | null
                      colorDepth?: number | null
                      size?: number | null
                      mime?: string | null
                      extension?: string | null
                      allowCaching?: boolean | null
                      mediaType?: Types.MediaType | null
                      src?: string | null
                      language?: string | null
                    }
                  | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                  | {
                      __typename: "VectorGraphicsMediaInfo"
                      id: string
                      resourceId?: string | null
                      thumbnail?: string | null
                      width?: number | null
                      height?: number | null
                      allowCaching?: boolean | null
                      mediaType?: Types.MediaType | null
                      language?: string | null
                      labels: Array<{
                        __typename: "VectorGraphicsLabel"
                        id?: string | null
                        sequence?: number | null
                        autoValue?: string | null
                        text?: string | null
                        linkType?: Types.VectorGraphicsLabelLinkType | null
                        link?: string | null
                        ref?:
                          | {
                              __typename: "GenericRef"
                              id: string
                              owner?:
                                | { __typename: "CommentNode" }
                                | { __typename: "ExternalSite" }
                                | { __typename: "ST4Class" }
                                | { __typename: "ST4Node"; id: string }
                                | { __typename: "ST4TreeNode" }
                                | null
                              target?:
                                | { __typename: "CommentNode" }
                                | {
                                    __typename: "ExternalSite"
                                    id: string
                                    teaserData?: {
                                      __typename: "ExternalLinkTeaserData"
                                      title?: string | null
                                      summary?: string | null
                                      iconUrl?: string | null
                                      imageUrl?: string | null
                                      status?: Types.LinkTargetStatus | null
                                    } | null
                                  }
                                | { __typename: "ST4Class" }
                                | {
                                    __typename: "ST4Node"
                                    id: string
                                    aspectId: any
                                    label?: string | null
                                    properties?: {
                                      __typename: "ContentPropertyConnection"
                                      edges?: Array<{
                                        __typename: "ContentPropertyEdge"
                                        node?: {
                                          __typename: "ContentProperty"
                                          name: string
                                          value?: any | null
                                          displayName: string
                                        } | null
                                      } | null> | null
                                    } | null
                                  }
                                | { __typename: "ST4TreeNode" }
                                | null
                            }
                          | {
                              __typename: "NodeRef"
                              id: string
                              owner?:
                                | { __typename: "CommentNode" }
                                | { __typename: "ExternalSite" }
                                | { __typename: "ST4Class" }
                                | { __typename: "ST4Node"; id: string }
                                | { __typename: "ST4TreeNode" }
                                | null
                              target?:
                                | { __typename: "CommentNode" }
                                | {
                                    __typename: "ExternalSite"
                                    id: string
                                    teaserData?: {
                                      __typename: "ExternalLinkTeaserData"
                                      title?: string | null
                                      summary?: string | null
                                      iconUrl?: string | null
                                      imageUrl?: string | null
                                      status?: Types.LinkTargetStatus | null
                                    } | null
                                  }
                                | { __typename: "ST4Class" }
                                | {
                                    __typename: "ST4Node"
                                    id: string
                                    aspectId: any
                                    label?: string | null
                                    properties?: {
                                      __typename: "ContentPropertyConnection"
                                      edges?: Array<{
                                        __typename: "ContentPropertyEdge"
                                        node?: {
                                          __typename: "ContentProperty"
                                          name: string
                                          value?: any | null
                                          displayName: string
                                        } | null
                                      } | null> | null
                                    } | null
                                  }
                                | { __typename: "ST4TreeNode" }
                                | null
                            }
                          | null
                      }>
                    }
                  | {
                      __typename: "VideoMediaInfo"
                      id: string
                      resourceId?: string | null
                      thumbnail?: string | null
                      width?: number | null
                      height?: number | null
                      mediaType?: Types.MediaType | null
                    }
                  | null
              }
            | { __typename: "ProjectContent" }
            | { __typename: "TermContent" }
            | { __typename: "TextContent" }
            | { __typename: "TextGroupContent" }
            | { __typename: "VariableTableContent" }
            | null
        }
      | { __typename: "ST4TreeNode"; id: string }
      | null
  }>
  variableTables?: Array<{
    __typename: "VariableTableReference"
    ref?: string | null
    node?:
      | { __typename: "CommentNode" }
      | { __typename: "ExternalSite" }
      | { __typename: "ST4Class" }
      | {
          __typename: "ST4Node"
          id: string
          label?: string | null
          aspectId: any
          content?:
            | { __typename: "LexiconContent" }
            | { __typename: "LexiconEntryContent" }
            | { __typename: "MediaContent" }
            | { __typename: "MediaGroupContent" }
            | { __typename: "ProjectContent" }
            | { __typename: "TermContent" }
            | { __typename: "TextContent" }
            | { __typename: "TextGroupContent" }
            | {
                __typename: "VariableTableContent"
                xml?: string | null
                valueSets: Array<{ __typename: "ValueSet"; name: string; valueSetId: string }>
                variables: Array<{ __typename: "Variable"; name: string; variableId: string; values: Array<string> }>
              }
            | null
        }
      | { __typename: "ST4TreeNode" }
      | null
  } | null> | null
  linkedDataNodes: Array<{
    __typename: "NodeRef"
    id: string
    target?:
      | { __typename: "CommentNode"; id: string }
      | { __typename: "ExternalSite"; id: string }
      | { __typename: "ST4Class"; id: string }
      | {
          __typename: "ST4Node"
          label?: string | null
          id: string
          metadata: Array<{
            __typename: "MetaDataEntry"
            label?: string | null
            systemName: string
            value?: string | null
            values: Array<string>
            users: Array<string>
          }>
          linkedOntologyNodes: Array<
            | {
                __typename: "GenericRef"
                target?:
                  | { __typename: "CommentNode" }
                  | { __typename: "ExternalSite" }
                  | { __typename: "ST4Class" }
                  | { __typename: "ST4Node"; id: string; label?: string | null }
                  | { __typename: "ST4TreeNode" }
                  | null
              }
            | {
                __typename: "NodeRef"
                linkClassName: string
                class?: { __typename: "ST4Class"; label?: string | null } | null
                target?:
                  | { __typename: "CommentNode" }
                  | { __typename: "ExternalSite" }
                  | { __typename: "ST4Class" }
                  | { __typename: "ST4Node"; id: string; label?: string | null }
                  | { __typename: "ST4TreeNode" }
                  | null
              }
          >
        }
      | { __typename: "ST4TreeNode"; id: string }
      | null
  }>
  lexiconEntries?: Array<{
    __typename: "NodeRef"
    id: string
    target?:
      | { __typename: "CommentNode"; id: string }
      | { __typename: "ExternalSite"; id: string }
      | { __typename: "ST4Class"; id: string }
      | {
          __typename: "ST4Node"
          aspectId: any
          label?: string | null
          id: string
          content?:
            | { __typename: "LexiconContent" }
            | {
                __typename: "LexiconEntryContent"
                descriptionXast?: string | null
                subject?: string | null
                sortKey?: string | null
                lexicon:
                  | { __typename: "CommentNode"; id: string }
                  | { __typename: "ExternalSite"; id: string }
                  | { __typename: "ST4Class"; id: string }
                  | { __typename: "ST4Node"; label?: string | null; id: string }
                  | { __typename: "ST4TreeNode"; id: string }
                terms: Array<
                  | { __typename: "CommentNode"; id: string }
                  | { __typename: "ExternalSite"; id: string }
                  | { __typename: "ST4Class"; id: string }
                  | {
                      __typename: "ST4Node"
                      id: string
                      label?: string | null
                      aspectId: any
                      content?:
                        | { __typename: "LexiconContent" }
                        | { __typename: "LexiconEntryContent" }
                        | { __typename: "MediaContent" }
                        | { __typename: "MediaGroupContent" }
                        | { __typename: "ProjectContent" }
                        | {
                            __typename: "TermContent"
                            gramaticalGender?: string | null
                            validity?: string | null
                            editLanguage?: string | null
                          }
                        | { __typename: "TextContent" }
                        | { __typename: "TextGroupContent" }
                        | { __typename: "VariableTableContent" }
                        | null
                    }
                  | { __typename: "ST4TreeNode"; id: string }
                >
                image?:
                  | { __typename: "CommentNode"; id: string }
                  | { __typename: "ExternalSite"; id: string }
                  | { __typename: "ST4Class"; id: string }
                  | {
                      __typename: "ST4Node"
                      aspectId: any
                      id: string
                      content?:
                        | { __typename: "LexiconContent" }
                        | { __typename: "LexiconEntryContent" }
                        | {
                            __typename: "MediaContent"
                            media?:
                              | {
                                  __typename: "AnimationMediaInfo"
                                  thumbnail?: string | null
                                  mediaType?: Types.MediaType | null
                                }
                              | {
                                  __typename: "ImageMediaInfo"
                                  src?: string | null
                                  thumbnail?: string | null
                                  mediaType?: Types.MediaType | null
                                }
                              | { __typename: "NoMediaInfo" }
                              | {
                                  __typename: "VectorGraphicsMediaInfo"
                                  svg?: string | null
                                  thumbnail?: string | null
                                  mediaType?: Types.MediaType | null
                                }
                              | {
                                  __typename: "VideoMediaInfo"
                                  thumbnail?: string | null
                                  mediaType?: Types.MediaType | null
                                }
                              | null
                          }
                        | { __typename: "MediaGroupContent" }
                        | { __typename: "ProjectContent" }
                        | { __typename: "TermContent" }
                        | { __typename: "TextContent" }
                        | { __typename: "TextGroupContent" }
                        | { __typename: "VariableTableContent" }
                        | null
                    }
                  | { __typename: "ST4TreeNode"; id: string }
                  | null
              }
            | { __typename: "MediaContent" }
            | { __typename: "MediaGroupContent" }
            | { __typename: "ProjectContent" }
            | { __typename: "TermContent" }
            | { __typename: "TextContent" }
            | { __typename: "TextGroupContent" }
            | { __typename: "VariableTableContent" }
            | null
        }
      | { __typename: "ST4TreeNode"; id: string }
      | null
  } | null> | null
  comments: Array<{
    __typename: "CommentNode"
    id: string
    comment:
      | {
          __typename: "BlockComment"
          location: number
          value?: string | null
          authorDisplayName?: string | null
          authorUserName?: string | null
          commentKey: string
          editDate?: any | null
          modificationVersion?: number | null
          replyToKey?: string | null
          sortOrder?: number | null
          commentType?: string | null
          state?: string | null
          color: string
          persistanceState?: Types.PersistanceState | null
          persistanceAction?: Types.PersistanceAction | null
          node: {
            __typename: "ST4Node"
            aspectId: any
            id: string
            content?:
              | { __typename: "LexiconContent" }
              | { __typename: "LexiconEntryContent" }
              | { __typename: "MediaContent" }
              | { __typename: "MediaGroupContent" }
              | { __typename: "ProjectContent" }
              | { __typename: "TermContent" }
              | { __typename: "TextContent"; id: string; historyNumber: number }
              | { __typename: "TextGroupContent"; id: string; historyNumber: number }
              | { __typename: "VariableTableContent" }
              | null
          }
          actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
        }
      | {
          __typename: "TextComment"
          endLocation: number
          startLocation?: number | null
          value?: string | null
          authorDisplayName?: string | null
          authorUserName?: string | null
          commentKey: string
          editDate?: any | null
          modificationVersion?: number | null
          replyToKey?: string | null
          sortOrder?: number | null
          commentType?: string | null
          state?: string | null
          color: string
          persistanceState?: Types.PersistanceState | null
          persistanceAction?: Types.PersistanceAction | null
          node: {
            __typename: "ST4Node"
            aspectId: any
            id: string
            content?:
              | { __typename: "LexiconContent" }
              | { __typename: "LexiconEntryContent" }
              | { __typename: "MediaContent" }
              | { __typename: "MediaGroupContent" }
              | { __typename: "ProjectContent" }
              | { __typename: "TermContent" }
              | { __typename: "TextContent"; id: string; historyNumber: number }
              | { __typename: "TextGroupContent"; id: string; historyNumber: number }
              | { __typename: "VariableTableContent" }
              | null
          }
          actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
        }
  }>
}

export type TextContentWithFragments_TextGroupContent_Fragment = {
  __typename: "TextGroupContent"
  currentNodeId?: any | null
  groupNodeId: any
  id: string
  xast?: string | null
  historyNumber: number
  fragments: Array<{
    __typename: "TextContentFragment"
    compoundId: string
    contentNode: {
      __typename: "ST4Node"
      label?: string | null
      id: string
      aspectId: any
      content?:
        | { __typename: "LexiconContent" }
        | { __typename: "LexiconEntryContent" }
        | { __typename: "MediaContent" }
        | { __typename: "MediaGroupContent" }
        | { __typename: "ProjectContent" }
        | { __typename: "TermContent" }
        | {
            __typename: "TextContent"
            id: string
            xast?: string | null
            historyNumber: number
            parts: Array<{
              __typename: "TextContentPart"
              ref: string
              contentNode: {
                __typename: "ST4Node"
                id: string
                aspectId: any
                content?:
                  | { __typename: "LexiconContent" }
                  | { __typename: "LexiconEntryContent" }
                  | { __typename: "MediaContent" }
                  | { __typename: "MediaGroupContent" }
                  | { __typename: "ProjectContent" }
                  | { __typename: "TermContent" }
                  | {
                      __typename: "TextContent"
                      id: string
                      xast?: string | null
                      historyNumber: number
                      links?: Array<{
                        __typename: "TextContentLink"
                        ref?: string | null
                        node?:
                          | { __typename: "CommentNode"; id: string }
                          | { __typename: "ExternalSite"; id: string }
                          | { __typename: "ST4Class"; id: string }
                          | {
                              __typename: "ST4Node"
                              id: string
                              aspectId: any
                              label?: string | null
                              properties?: {
                                __typename: "ContentPropertyConnection"
                                totalCount: number
                                edges?: Array<{
                                  __typename: "ContentPropertyEdge"
                                  node?: {
                                    __typename: "ContentProperty"
                                    typeOfValue: Types.ContentPropertyValueType
                                    name: string
                                    value?: any | null
                                    displayName: string
                                  } | null
                                } | null> | null
                              } | null
                            }
                          | { __typename: "ST4TreeNode"; id: string }
                          | null
                      } | null> | null
                      images: Array<{
                        __typename: "NodeRef"
                        id: string
                        target?:
                          | { __typename: "CommentNode"; id: string }
                          | { __typename: "ExternalSite"; id: string }
                          | { __typename: "ST4Class"; id: string }
                          | {
                              __typename: "ST4Node"
                              aspectId: any
                              id: string
                              releaseState: {
                                __typename: "ReleaseStateValue"
                                displayName: string
                                state: Types.ReleaseState
                                isImplicitlyReleased: boolean
                              }
                              content?:
                                | { __typename: "LexiconContent" }
                                | { __typename: "LexiconEntryContent" }
                                | {
                                    __typename: "MediaContent"
                                    media?:
                                      | {
                                          __typename: "AnimationMediaInfo"
                                          id: string
                                          resourceId?: string | null
                                          thumbnail?: string | null
                                          width?: number | null
                                          height?: number | null
                                          mediaType?: Types.MediaType | null
                                        }
                                      | {
                                          __typename: "ImageMediaInfo"
                                          id: string
                                          resourceId?: string | null
                                          thumbnail?: string | null
                                          width?: number | null
                                          height?: number | null
                                          resolution?: number | null
                                          colorDepth?: number | null
                                          size?: number | null
                                          mime?: string | null
                                          extension?: string | null
                                          allowCaching?: boolean | null
                                          mediaType?: Types.MediaType | null
                                          src?: string | null
                                          language?: string | null
                                        }
                                      | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                                      | {
                                          __typename: "VectorGraphicsMediaInfo"
                                          id: string
                                          resourceId?: string | null
                                          thumbnail?: string | null
                                          width?: number | null
                                          height?: number | null
                                          allowCaching?: boolean | null
                                          mediaType?: Types.MediaType | null
                                          language?: string | null
                                          labels: Array<{
                                            __typename: "VectorGraphicsLabel"
                                            id?: string | null
                                            sequence?: number | null
                                            autoValue?: string | null
                                            text?: string | null
                                            linkType?: Types.VectorGraphicsLabelLinkType | null
                                            link?: string | null
                                            ref?:
                                              | {
                                                  __typename: "GenericRef"
                                                  id: string
                                                  owner?:
                                                    | { __typename: "CommentNode" }
                                                    | { __typename: "ExternalSite" }
                                                    | { __typename: "ST4Class" }
                                                    | { __typename: "ST4Node"; id: string }
                                                    | { __typename: "ST4TreeNode" }
                                                    | null
                                                  target?:
                                                    | { __typename: "CommentNode" }
                                                    | {
                                                        __typename: "ExternalSite"
                                                        id: string
                                                        teaserData?: {
                                                          __typename: "ExternalLinkTeaserData"
                                                          title?: string | null
                                                          summary?: string | null
                                                          iconUrl?: string | null
                                                          imageUrl?: string | null
                                                          status?: Types.LinkTargetStatus | null
                                                        } | null
                                                      }
                                                    | { __typename: "ST4Class" }
                                                    | {
                                                        __typename: "ST4Node"
                                                        id: string
                                                        aspectId: any
                                                        label?: string | null
                                                        properties?: {
                                                          __typename: "ContentPropertyConnection"
                                                          edges?: Array<{
                                                            __typename: "ContentPropertyEdge"
                                                            node?: {
                                                              __typename: "ContentProperty"
                                                              name: string
                                                              value?: any | null
                                                              displayName: string
                                                            } | null
                                                          } | null> | null
                                                        } | null
                                                      }
                                                    | { __typename: "ST4TreeNode" }
                                                    | null
                                                }
                                              | {
                                                  __typename: "NodeRef"
                                                  id: string
                                                  owner?:
                                                    | { __typename: "CommentNode" }
                                                    | { __typename: "ExternalSite" }
                                                    | { __typename: "ST4Class" }
                                                    | { __typename: "ST4Node"; id: string }
                                                    | { __typename: "ST4TreeNode" }
                                                    | null
                                                  target?:
                                                    | { __typename: "CommentNode" }
                                                    | {
                                                        __typename: "ExternalSite"
                                                        id: string
                                                        teaserData?: {
                                                          __typename: "ExternalLinkTeaserData"
                                                          title?: string | null
                                                          summary?: string | null
                                                          iconUrl?: string | null
                                                          imageUrl?: string | null
                                                          status?: Types.LinkTargetStatus | null
                                                        } | null
                                                      }
                                                    | { __typename: "ST4Class" }
                                                    | {
                                                        __typename: "ST4Node"
                                                        id: string
                                                        aspectId: any
                                                        label?: string | null
                                                        properties?: {
                                                          __typename: "ContentPropertyConnection"
                                                          edges?: Array<{
                                                            __typename: "ContentPropertyEdge"
                                                            node?: {
                                                              __typename: "ContentProperty"
                                                              name: string
                                                              value?: any | null
                                                              displayName: string
                                                            } | null
                                                          } | null> | null
                                                        } | null
                                                      }
                                                    | { __typename: "ST4TreeNode" }
                                                    | null
                                                }
                                              | null
                                          }>
                                        }
                                      | {
                                          __typename: "VideoMediaInfo"
                                          id: string
                                          resourceId?: string | null
                                          thumbnail?: string | null
                                          width?: number | null
                                          height?: number | null
                                          mediaType?: Types.MediaType | null
                                        }
                                      | null
                                  }
                                | {
                                    __typename: "MediaGroupContent"
                                    children: Array<{
                                      __typename: "NodeRef"
                                      id: string
                                      target?:
                                        | { __typename: "CommentNode"; id: string }
                                        | { __typename: "ExternalSite"; id: string }
                                        | { __typename: "ST4Class"; id: string }
                                        | {
                                            __typename: "ST4Node"
                                            id: string
                                            aspectId: any
                                            content?:
                                              | { __typename: "LexiconContent" }
                                              | { __typename: "LexiconEntryContent" }
                                              | {
                                                  __typename: "MediaContent"
                                                  media?:
                                                    | {
                                                        __typename: "AnimationMediaInfo"
                                                        id: string
                                                        resourceId?: string | null
                                                        thumbnail?: string | null
                                                        width?: number | null
                                                        height?: number | null
                                                        mediaType?: Types.MediaType | null
                                                      }
                                                    | {
                                                        __typename: "ImageMediaInfo"
                                                        id: string
                                                        resourceId?: string | null
                                                        thumbnail?: string | null
                                                        width?: number | null
                                                        height?: number | null
                                                        resolution?: number | null
                                                        colorDepth?: number | null
                                                        size?: number | null
                                                        mime?: string | null
                                                        extension?: string | null
                                                        allowCaching?: boolean | null
                                                        mediaType?: Types.MediaType | null
                                                        src?: string | null
                                                        language?: string | null
                                                      }
                                                    | {
                                                        __typename: "NoMediaInfo"
                                                        id: string
                                                        mediaType?: Types.MediaType | null
                                                      }
                                                    | {
                                                        __typename: "VectorGraphicsMediaInfo"
                                                        id: string
                                                        resourceId?: string | null
                                                        thumbnail?: string | null
                                                        width?: number | null
                                                        height?: number | null
                                                        allowCaching?: boolean | null
                                                        mediaType?: Types.MediaType | null
                                                        language?: string | null
                                                        labels: Array<{
                                                          __typename: "VectorGraphicsLabel"
                                                          id?: string | null
                                                          sequence?: number | null
                                                          autoValue?: string | null
                                                          text?: string | null
                                                          linkType?: Types.VectorGraphicsLabelLinkType | null
                                                          link?: string | null
                                                          ref?:
                                                            | {
                                                                __typename: "GenericRef"
                                                                id: string
                                                                owner?:
                                                                  | { __typename: "CommentNode" }
                                                                  | { __typename: "ExternalSite" }
                                                                  | { __typename: "ST4Class" }
                                                                  | { __typename: "ST4Node"; id: string }
                                                                  | { __typename: "ST4TreeNode" }
                                                                  | null
                                                                target?:
                                                                  | { __typename: "CommentNode" }
                                                                  | {
                                                                      __typename: "ExternalSite"
                                                                      id: string
                                                                      teaserData?: {
                                                                        __typename: "ExternalLinkTeaserData"
                                                                        title?: string | null
                                                                        summary?: string | null
                                                                        iconUrl?: string | null
                                                                        imageUrl?: string | null
                                                                        status?: Types.LinkTargetStatus | null
                                                                      } | null
                                                                    }
                                                                  | { __typename: "ST4Class" }
                                                                  | {
                                                                      __typename: "ST4Node"
                                                                      id: string
                                                                      aspectId: any
                                                                      label?: string | null
                                                                      properties?: {
                                                                        __typename: "ContentPropertyConnection"
                                                                        edges?: Array<{
                                                                          __typename: "ContentPropertyEdge"
                                                                          node?: {
                                                                            __typename: "ContentProperty"
                                                                            name: string
                                                                            value?: any | null
                                                                            displayName: string
                                                                          } | null
                                                                        } | null> | null
                                                                      } | null
                                                                    }
                                                                  | { __typename: "ST4TreeNode" }
                                                                  | null
                                                              }
                                                            | {
                                                                __typename: "NodeRef"
                                                                id: string
                                                                owner?:
                                                                  | { __typename: "CommentNode" }
                                                                  | { __typename: "ExternalSite" }
                                                                  | { __typename: "ST4Class" }
                                                                  | { __typename: "ST4Node"; id: string }
                                                                  | { __typename: "ST4TreeNode" }
                                                                  | null
                                                                target?:
                                                                  | { __typename: "CommentNode" }
                                                                  | {
                                                                      __typename: "ExternalSite"
                                                                      id: string
                                                                      teaserData?: {
                                                                        __typename: "ExternalLinkTeaserData"
                                                                        title?: string | null
                                                                        summary?: string | null
                                                                        iconUrl?: string | null
                                                                        imageUrl?: string | null
                                                                        status?: Types.LinkTargetStatus | null
                                                                      } | null
                                                                    }
                                                                  | { __typename: "ST4Class" }
                                                                  | {
                                                                      __typename: "ST4Node"
                                                                      id: string
                                                                      aspectId: any
                                                                      label?: string | null
                                                                      properties?: {
                                                                        __typename: "ContentPropertyConnection"
                                                                        edges?: Array<{
                                                                          __typename: "ContentPropertyEdge"
                                                                          node?: {
                                                                            __typename: "ContentProperty"
                                                                            name: string
                                                                            value?: any | null
                                                                            displayName: string
                                                                          } | null
                                                                        } | null> | null
                                                                      } | null
                                                                    }
                                                                  | { __typename: "ST4TreeNode" }
                                                                  | null
                                                              }
                                                            | null
                                                        }>
                                                      }
                                                    | {
                                                        __typename: "VideoMediaInfo"
                                                        id: string
                                                        resourceId?: string | null
                                                        thumbnail?: string | null
                                                        width?: number | null
                                                        height?: number | null
                                                        mediaType?: Types.MediaType | null
                                                      }
                                                    | null
                                                }
                                              | {
                                                  __typename: "MediaGroupContent"
                                                  media?:
                                                    | {
                                                        __typename: "AnimationMediaInfo"
                                                        id: string
                                                        resourceId?: string | null
                                                        thumbnail?: string | null
                                                        width?: number | null
                                                        height?: number | null
                                                        mediaType?: Types.MediaType | null
                                                      }
                                                    | {
                                                        __typename: "ImageMediaInfo"
                                                        id: string
                                                        resourceId?: string | null
                                                        thumbnail?: string | null
                                                        width?: number | null
                                                        height?: number | null
                                                        resolution?: number | null
                                                        colorDepth?: number | null
                                                        size?: number | null
                                                        mime?: string | null
                                                        extension?: string | null
                                                        allowCaching?: boolean | null
                                                        mediaType?: Types.MediaType | null
                                                        src?: string | null
                                                        language?: string | null
                                                      }
                                                    | {
                                                        __typename: "NoMediaInfo"
                                                        id: string
                                                        mediaType?: Types.MediaType | null
                                                      }
                                                    | {
                                                        __typename: "VectorGraphicsMediaInfo"
                                                        id: string
                                                        resourceId?: string | null
                                                        thumbnail?: string | null
                                                        width?: number | null
                                                        height?: number | null
                                                        allowCaching?: boolean | null
                                                        mediaType?: Types.MediaType | null
                                                        language?: string | null
                                                        labels: Array<{
                                                          __typename: "VectorGraphicsLabel"
                                                          id?: string | null
                                                          sequence?: number | null
                                                          autoValue?: string | null
                                                          text?: string | null
                                                          linkType?: Types.VectorGraphicsLabelLinkType | null
                                                          link?: string | null
                                                          ref?:
                                                            | {
                                                                __typename: "GenericRef"
                                                                id: string
                                                                owner?:
                                                                  | { __typename: "CommentNode" }
                                                                  | { __typename: "ExternalSite" }
                                                                  | { __typename: "ST4Class" }
                                                                  | { __typename: "ST4Node"; id: string }
                                                                  | { __typename: "ST4TreeNode" }
                                                                  | null
                                                                target?:
                                                                  | { __typename: "CommentNode" }
                                                                  | {
                                                                      __typename: "ExternalSite"
                                                                      id: string
                                                                      teaserData?: {
                                                                        __typename: "ExternalLinkTeaserData"
                                                                        title?: string | null
                                                                        summary?: string | null
                                                                        iconUrl?: string | null
                                                                        imageUrl?: string | null
                                                                        status?: Types.LinkTargetStatus | null
                                                                      } | null
                                                                    }
                                                                  | { __typename: "ST4Class" }
                                                                  | {
                                                                      __typename: "ST4Node"
                                                                      id: string
                                                                      aspectId: any
                                                                      label?: string | null
                                                                      properties?: {
                                                                        __typename: "ContentPropertyConnection"
                                                                        edges?: Array<{
                                                                          __typename: "ContentPropertyEdge"
                                                                          node?: {
                                                                            __typename: "ContentProperty"
                                                                            name: string
                                                                            value?: any | null
                                                                            displayName: string
                                                                          } | null
                                                                        } | null> | null
                                                                      } | null
                                                                    }
                                                                  | { __typename: "ST4TreeNode" }
                                                                  | null
                                                              }
                                                            | {
                                                                __typename: "NodeRef"
                                                                id: string
                                                                owner?:
                                                                  | { __typename: "CommentNode" }
                                                                  | { __typename: "ExternalSite" }
                                                                  | { __typename: "ST4Class" }
                                                                  | { __typename: "ST4Node"; id: string }
                                                                  | { __typename: "ST4TreeNode" }
                                                                  | null
                                                                target?:
                                                                  | { __typename: "CommentNode" }
                                                                  | {
                                                                      __typename: "ExternalSite"
                                                                      id: string
                                                                      teaserData?: {
                                                                        __typename: "ExternalLinkTeaserData"
                                                                        title?: string | null
                                                                        summary?: string | null
                                                                        iconUrl?: string | null
                                                                        imageUrl?: string | null
                                                                        status?: Types.LinkTargetStatus | null
                                                                      } | null
                                                                    }
                                                                  | { __typename: "ST4Class" }
                                                                  | {
                                                                      __typename: "ST4Node"
                                                                      id: string
                                                                      aspectId: any
                                                                      label?: string | null
                                                                      properties?: {
                                                                        __typename: "ContentPropertyConnection"
                                                                        edges?: Array<{
                                                                          __typename: "ContentPropertyEdge"
                                                                          node?: {
                                                                            __typename: "ContentProperty"
                                                                            name: string
                                                                            value?: any | null
                                                                            displayName: string
                                                                          } | null
                                                                        } | null> | null
                                                                      } | null
                                                                    }
                                                                  | { __typename: "ST4TreeNode" }
                                                                  | null
                                                              }
                                                            | null
                                                        }>
                                                      }
                                                    | {
                                                        __typename: "VideoMediaInfo"
                                                        id: string
                                                        resourceId?: string | null
                                                        thumbnail?: string | null
                                                        width?: number | null
                                                        height?: number | null
                                                        mediaType?: Types.MediaType | null
                                                      }
                                                    | null
                                                }
                                              | { __typename: "ProjectContent" }
                                              | { __typename: "TermContent" }
                                              | { __typename: "TextContent" }
                                              | { __typename: "TextGroupContent" }
                                              | { __typename: "VariableTableContent" }
                                              | null
                                          }
                                        | { __typename: "ST4TreeNode"; id: string }
                                        | null
                                    }>
                                    media?:
                                      | {
                                          __typename: "AnimationMediaInfo"
                                          id: string
                                          resourceId?: string | null
                                          thumbnail?: string | null
                                          width?: number | null
                                          height?: number | null
                                          mediaType?: Types.MediaType | null
                                        }
                                      | {
                                          __typename: "ImageMediaInfo"
                                          id: string
                                          resourceId?: string | null
                                          thumbnail?: string | null
                                          width?: number | null
                                          height?: number | null
                                          resolution?: number | null
                                          colorDepth?: number | null
                                          size?: number | null
                                          mime?: string | null
                                          extension?: string | null
                                          allowCaching?: boolean | null
                                          mediaType?: Types.MediaType | null
                                          src?: string | null
                                          language?: string | null
                                        }
                                      | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                                      | {
                                          __typename: "VectorGraphicsMediaInfo"
                                          id: string
                                          resourceId?: string | null
                                          thumbnail?: string | null
                                          width?: number | null
                                          height?: number | null
                                          allowCaching?: boolean | null
                                          mediaType?: Types.MediaType | null
                                          language?: string | null
                                          labels: Array<{
                                            __typename: "VectorGraphicsLabel"
                                            id?: string | null
                                            sequence?: number | null
                                            autoValue?: string | null
                                            text?: string | null
                                            linkType?: Types.VectorGraphicsLabelLinkType | null
                                            link?: string | null
                                            ref?:
                                              | {
                                                  __typename: "GenericRef"
                                                  id: string
                                                  owner?:
                                                    | { __typename: "CommentNode" }
                                                    | { __typename: "ExternalSite" }
                                                    | { __typename: "ST4Class" }
                                                    | { __typename: "ST4Node"; id: string }
                                                    | { __typename: "ST4TreeNode" }
                                                    | null
                                                  target?:
                                                    | { __typename: "CommentNode" }
                                                    | {
                                                        __typename: "ExternalSite"
                                                        id: string
                                                        teaserData?: {
                                                          __typename: "ExternalLinkTeaserData"
                                                          title?: string | null
                                                          summary?: string | null
                                                          iconUrl?: string | null
                                                          imageUrl?: string | null
                                                          status?: Types.LinkTargetStatus | null
                                                        } | null
                                                      }
                                                    | { __typename: "ST4Class" }
                                                    | {
                                                        __typename: "ST4Node"
                                                        id: string
                                                        aspectId: any
                                                        label?: string | null
                                                        properties?: {
                                                          __typename: "ContentPropertyConnection"
                                                          edges?: Array<{
                                                            __typename: "ContentPropertyEdge"
                                                            node?: {
                                                              __typename: "ContentProperty"
                                                              name: string
                                                              value?: any | null
                                                              displayName: string
                                                            } | null
                                                          } | null> | null
                                                        } | null
                                                      }
                                                    | { __typename: "ST4TreeNode" }
                                                    | null
                                                }
                                              | {
                                                  __typename: "NodeRef"
                                                  id: string
                                                  owner?:
                                                    | { __typename: "CommentNode" }
                                                    | { __typename: "ExternalSite" }
                                                    | { __typename: "ST4Class" }
                                                    | { __typename: "ST4Node"; id: string }
                                                    | { __typename: "ST4TreeNode" }
                                                    | null
                                                  target?:
                                                    | { __typename: "CommentNode" }
                                                    | {
                                                        __typename: "ExternalSite"
                                                        id: string
                                                        teaserData?: {
                                                          __typename: "ExternalLinkTeaserData"
                                                          title?: string | null
                                                          summary?: string | null
                                                          iconUrl?: string | null
                                                          imageUrl?: string | null
                                                          status?: Types.LinkTargetStatus | null
                                                        } | null
                                                      }
                                                    | { __typename: "ST4Class" }
                                                    | {
                                                        __typename: "ST4Node"
                                                        id: string
                                                        aspectId: any
                                                        label?: string | null
                                                        properties?: {
                                                          __typename: "ContentPropertyConnection"
                                                          edges?: Array<{
                                                            __typename: "ContentPropertyEdge"
                                                            node?: {
                                                              __typename: "ContentProperty"
                                                              name: string
                                                              value?: any | null
                                                              displayName: string
                                                            } | null
                                                          } | null> | null
                                                        } | null
                                                      }
                                                    | { __typename: "ST4TreeNode" }
                                                    | null
                                                }
                                              | null
                                          }>
                                        }
                                      | {
                                          __typename: "VideoMediaInfo"
                                          id: string
                                          resourceId?: string | null
                                          thumbnail?: string | null
                                          width?: number | null
                                          height?: number | null
                                          mediaType?: Types.MediaType | null
                                        }
                                      | null
                                  }
                                | { __typename: "ProjectContent" }
                                | { __typename: "TermContent" }
                                | { __typename: "TextContent" }
                                | { __typename: "TextGroupContent" }
                                | { __typename: "VariableTableContent" }
                                | null
                            }
                          | { __typename: "ST4TreeNode"; id: string }
                          | null
                      }>
                      variableTables?: Array<{
                        __typename: "VariableTableReference"
                        ref?: string | null
                        node?:
                          | { __typename: "CommentNode" }
                          | { __typename: "ExternalSite" }
                          | { __typename: "ST4Class" }
                          | {
                              __typename: "ST4Node"
                              id: string
                              label?: string | null
                              aspectId: any
                              content?:
                                | { __typename: "LexiconContent" }
                                | { __typename: "LexiconEntryContent" }
                                | { __typename: "MediaContent" }
                                | { __typename: "MediaGroupContent" }
                                | { __typename: "ProjectContent" }
                                | { __typename: "TermContent" }
                                | { __typename: "TextContent" }
                                | { __typename: "TextGroupContent" }
                                | {
                                    __typename: "VariableTableContent"
                                    xml?: string | null
                                    valueSets: Array<{ __typename: "ValueSet"; name: string; valueSetId: string }>
                                    variables: Array<{
                                      __typename: "Variable"
                                      name: string
                                      variableId: string
                                      values: Array<string>
                                    }>
                                  }
                                | null
                            }
                          | { __typename: "ST4TreeNode" }
                          | null
                      } | null> | null
                      linkedDataNodes: Array<{
                        __typename: "NodeRef"
                        id: string
                        target?:
                          | { __typename: "CommentNode"; id: string }
                          | { __typename: "ExternalSite"; id: string }
                          | { __typename: "ST4Class"; id: string }
                          | {
                              __typename: "ST4Node"
                              label?: string | null
                              id: string
                              metadata: Array<{
                                __typename: "MetaDataEntry"
                                label?: string | null
                                systemName: string
                                value?: string | null
                                values: Array<string>
                                users: Array<string>
                              }>
                              linkedOntologyNodes: Array<
                                | {
                                    __typename: "GenericRef"
                                    target?:
                                      | { __typename: "CommentNode" }
                                      | { __typename: "ExternalSite" }
                                      | { __typename: "ST4Class" }
                                      | { __typename: "ST4Node"; id: string; label?: string | null }
                                      | { __typename: "ST4TreeNode" }
                                      | null
                                  }
                                | {
                                    __typename: "NodeRef"
                                    linkClassName: string
                                    class?: { __typename: "ST4Class"; label?: string | null } | null
                                    target?:
                                      | { __typename: "CommentNode" }
                                      | { __typename: "ExternalSite" }
                                      | { __typename: "ST4Class" }
                                      | { __typename: "ST4Node"; id: string; label?: string | null }
                                      | { __typename: "ST4TreeNode" }
                                      | null
                                  }
                              >
                            }
                          | { __typename: "ST4TreeNode"; id: string }
                          | null
                      }>
                      lexiconEntries?: Array<{
                        __typename: "NodeRef"
                        id: string
                        target?:
                          | { __typename: "CommentNode"; id: string }
                          | { __typename: "ExternalSite"; id: string }
                          | { __typename: "ST4Class"; id: string }
                          | {
                              __typename: "ST4Node"
                              aspectId: any
                              label?: string | null
                              id: string
                              content?:
                                | { __typename: "LexiconContent" }
                                | {
                                    __typename: "LexiconEntryContent"
                                    descriptionXast?: string | null
                                    subject?: string | null
                                    sortKey?: string | null
                                    lexicon:
                                      | { __typename: "CommentNode"; id: string }
                                      | { __typename: "ExternalSite"; id: string }
                                      | { __typename: "ST4Class"; id: string }
                                      | { __typename: "ST4Node"; label?: string | null; id: string }
                                      | { __typename: "ST4TreeNode"; id: string }
                                    terms: Array<
                                      | { __typename: "CommentNode"; id: string }
                                      | { __typename: "ExternalSite"; id: string }
                                      | { __typename: "ST4Class"; id: string }
                                      | {
                                          __typename: "ST4Node"
                                          id: string
                                          label?: string | null
                                          aspectId: any
                                          content?:
                                            | { __typename: "LexiconContent" }
                                            | { __typename: "LexiconEntryContent" }
                                            | { __typename: "MediaContent" }
                                            | { __typename: "MediaGroupContent" }
                                            | { __typename: "ProjectContent" }
                                            | {
                                                __typename: "TermContent"
                                                gramaticalGender?: string | null
                                                validity?: string | null
                                                editLanguage?: string | null
                                              }
                                            | { __typename: "TextContent" }
                                            | { __typename: "TextGroupContent" }
                                            | { __typename: "VariableTableContent" }
                                            | null
                                        }
                                      | { __typename: "ST4TreeNode"; id: string }
                                    >
                                    image?:
                                      | { __typename: "CommentNode"; id: string }
                                      | { __typename: "ExternalSite"; id: string }
                                      | { __typename: "ST4Class"; id: string }
                                      | {
                                          __typename: "ST4Node"
                                          aspectId: any
                                          id: string
                                          content?:
                                            | { __typename: "LexiconContent" }
                                            | { __typename: "LexiconEntryContent" }
                                            | {
                                                __typename: "MediaContent"
                                                media?:
                                                  | {
                                                      __typename: "AnimationMediaInfo"
                                                      thumbnail?: string | null
                                                      mediaType?: Types.MediaType | null
                                                    }
                                                  | {
                                                      __typename: "ImageMediaInfo"
                                                      src?: string | null
                                                      thumbnail?: string | null
                                                      mediaType?: Types.MediaType | null
                                                    }
                                                  | { __typename: "NoMediaInfo" }
                                                  | {
                                                      __typename: "VectorGraphicsMediaInfo"
                                                      svg?: string | null
                                                      thumbnail?: string | null
                                                      mediaType?: Types.MediaType | null
                                                    }
                                                  | {
                                                      __typename: "VideoMediaInfo"
                                                      thumbnail?: string | null
                                                      mediaType?: Types.MediaType | null
                                                    }
                                                  | null
                                              }
                                            | { __typename: "MediaGroupContent" }
                                            | { __typename: "ProjectContent" }
                                            | { __typename: "TermContent" }
                                            | { __typename: "TextContent" }
                                            | { __typename: "TextGroupContent" }
                                            | { __typename: "VariableTableContent" }
                                            | null
                                        }
                                      | { __typename: "ST4TreeNode"; id: string }
                                      | null
                                  }
                                | { __typename: "MediaContent" }
                                | { __typename: "MediaGroupContent" }
                                | { __typename: "ProjectContent" }
                                | { __typename: "TermContent" }
                                | { __typename: "TextContent" }
                                | { __typename: "TextGroupContent" }
                                | { __typename: "VariableTableContent" }
                                | null
                            }
                          | { __typename: "ST4TreeNode"; id: string }
                          | null
                      } | null> | null
                      comments: Array<{
                        __typename: "CommentNode"
                        id: string
                        comment:
                          | {
                              __typename: "BlockComment"
                              location: number
                              value?: string | null
                              authorDisplayName?: string | null
                              authorUserName?: string | null
                              commentKey: string
                              editDate?: any | null
                              modificationVersion?: number | null
                              replyToKey?: string | null
                              sortOrder?: number | null
                              commentType?: string | null
                              state?: string | null
                              color: string
                              persistanceState?: Types.PersistanceState | null
                              persistanceAction?: Types.PersistanceAction | null
                              node: {
                                __typename: "ST4Node"
                                aspectId: any
                                id: string
                                content?:
                                  | { __typename: "LexiconContent" }
                                  | { __typename: "LexiconEntryContent" }
                                  | { __typename: "MediaContent" }
                                  | { __typename: "MediaGroupContent" }
                                  | { __typename: "ProjectContent" }
                                  | { __typename: "TermContent" }
                                  | { __typename: "TextContent"; id: string; historyNumber: number }
                                  | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                                  | { __typename: "VariableTableContent" }
                                  | null
                              }
                              actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
                            }
                          | {
                              __typename: "TextComment"
                              endLocation: number
                              startLocation?: number | null
                              value?: string | null
                              authorDisplayName?: string | null
                              authorUserName?: string | null
                              commentKey: string
                              editDate?: any | null
                              modificationVersion?: number | null
                              replyToKey?: string | null
                              sortOrder?: number | null
                              commentType?: string | null
                              state?: string | null
                              color: string
                              persistanceState?: Types.PersistanceState | null
                              persistanceAction?: Types.PersistanceAction | null
                              node: {
                                __typename: "ST4Node"
                                aspectId: any
                                id: string
                                content?:
                                  | { __typename: "LexiconContent" }
                                  | { __typename: "LexiconEntryContent" }
                                  | { __typename: "MediaContent" }
                                  | { __typename: "MediaGroupContent" }
                                  | { __typename: "ProjectContent" }
                                  | { __typename: "TermContent" }
                                  | { __typename: "TextContent"; id: string; historyNumber: number }
                                  | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                                  | { __typename: "VariableTableContent" }
                                  | null
                              }
                              actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
                            }
                      }>
                    }
                  | {
                      __typename: "TextGroupContent"
                      currentNodeId?: any | null
                      groupNodeId: any
                      id: string
                      xast?: string | null
                      historyNumber: number
                      children: Array<{
                        __typename: "NodeRef"
                        id: string
                        target?:
                          | { __typename: "CommentNode"; id: string }
                          | { __typename: "ExternalSite"; id: string }
                          | { __typename: "ST4Class"; id: string }
                          | { __typename: "ST4Node"; label?: string | null; aspectId: any; id: string }
                          | { __typename: "ST4TreeNode"; id: string }
                          | null
                      }>
                      links?: Array<{
                        __typename: "TextContentLink"
                        ref?: string | null
                        node?:
                          | { __typename: "CommentNode"; id: string }
                          | { __typename: "ExternalSite"; id: string }
                          | { __typename: "ST4Class"; id: string }
                          | {
                              __typename: "ST4Node"
                              id: string
                              aspectId: any
                              label?: string | null
                              properties?: {
                                __typename: "ContentPropertyConnection"
                                totalCount: number
                                edges?: Array<{
                                  __typename: "ContentPropertyEdge"
                                  node?: {
                                    __typename: "ContentProperty"
                                    typeOfValue: Types.ContentPropertyValueType
                                    name: string
                                    value?: any | null
                                    displayName: string
                                  } | null
                                } | null> | null
                              } | null
                            }
                          | { __typename: "ST4TreeNode"; id: string }
                          | null
                      } | null> | null
                      images: Array<{
                        __typename: "NodeRef"
                        id: string
                        target?:
                          | { __typename: "CommentNode"; id: string }
                          | { __typename: "ExternalSite"; id: string }
                          | { __typename: "ST4Class"; id: string }
                          | {
                              __typename: "ST4Node"
                              aspectId: any
                              id: string
                              releaseState: {
                                __typename: "ReleaseStateValue"
                                displayName: string
                                state: Types.ReleaseState
                                isImplicitlyReleased: boolean
                              }
                              content?:
                                | { __typename: "LexiconContent" }
                                | { __typename: "LexiconEntryContent" }
                                | {
                                    __typename: "MediaContent"
                                    media?:
                                      | {
                                          __typename: "AnimationMediaInfo"
                                          id: string
                                          resourceId?: string | null
                                          thumbnail?: string | null
                                          width?: number | null
                                          height?: number | null
                                          mediaType?: Types.MediaType | null
                                        }
                                      | {
                                          __typename: "ImageMediaInfo"
                                          id: string
                                          resourceId?: string | null
                                          thumbnail?: string | null
                                          width?: number | null
                                          height?: number | null
                                          resolution?: number | null
                                          colorDepth?: number | null
                                          size?: number | null
                                          mime?: string | null
                                          extension?: string | null
                                          allowCaching?: boolean | null
                                          mediaType?: Types.MediaType | null
                                          src?: string | null
                                          language?: string | null
                                        }
                                      | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                                      | {
                                          __typename: "VectorGraphicsMediaInfo"
                                          id: string
                                          resourceId?: string | null
                                          thumbnail?: string | null
                                          width?: number | null
                                          height?: number | null
                                          allowCaching?: boolean | null
                                          mediaType?: Types.MediaType | null
                                          language?: string | null
                                          labels: Array<{
                                            __typename: "VectorGraphicsLabel"
                                            id?: string | null
                                            sequence?: number | null
                                            autoValue?: string | null
                                            text?: string | null
                                            linkType?: Types.VectorGraphicsLabelLinkType | null
                                            link?: string | null
                                            ref?:
                                              | {
                                                  __typename: "GenericRef"
                                                  id: string
                                                  owner?:
                                                    | { __typename: "CommentNode" }
                                                    | { __typename: "ExternalSite" }
                                                    | { __typename: "ST4Class" }
                                                    | { __typename: "ST4Node"; id: string }
                                                    | { __typename: "ST4TreeNode" }
                                                    | null
                                                  target?:
                                                    | { __typename: "CommentNode" }
                                                    | {
                                                        __typename: "ExternalSite"
                                                        id: string
                                                        teaserData?: {
                                                          __typename: "ExternalLinkTeaserData"
                                                          title?: string | null
                                                          summary?: string | null
                                                          iconUrl?: string | null
                                                          imageUrl?: string | null
                                                          status?: Types.LinkTargetStatus | null
                                                        } | null
                                                      }
                                                    | { __typename: "ST4Class" }
                                                    | {
                                                        __typename: "ST4Node"
                                                        id: string
                                                        aspectId: any
                                                        label?: string | null
                                                        properties?: {
                                                          __typename: "ContentPropertyConnection"
                                                          edges?: Array<{
                                                            __typename: "ContentPropertyEdge"
                                                            node?: {
                                                              __typename: "ContentProperty"
                                                              name: string
                                                              value?: any | null
                                                              displayName: string
                                                            } | null
                                                          } | null> | null
                                                        } | null
                                                      }
                                                    | { __typename: "ST4TreeNode" }
                                                    | null
                                                }
                                              | {
                                                  __typename: "NodeRef"
                                                  id: string
                                                  owner?:
                                                    | { __typename: "CommentNode" }
                                                    | { __typename: "ExternalSite" }
                                                    | { __typename: "ST4Class" }
                                                    | { __typename: "ST4Node"; id: string }
                                                    | { __typename: "ST4TreeNode" }
                                                    | null
                                                  target?:
                                                    | { __typename: "CommentNode" }
                                                    | {
                                                        __typename: "ExternalSite"
                                                        id: string
                                                        teaserData?: {
                                                          __typename: "ExternalLinkTeaserData"
                                                          title?: string | null
                                                          summary?: string | null
                                                          iconUrl?: string | null
                                                          imageUrl?: string | null
                                                          status?: Types.LinkTargetStatus | null
                                                        } | null
                                                      }
                                                    | { __typename: "ST4Class" }
                                                    | {
                                                        __typename: "ST4Node"
                                                        id: string
                                                        aspectId: any
                                                        label?: string | null
                                                        properties?: {
                                                          __typename: "ContentPropertyConnection"
                                                          edges?: Array<{
                                                            __typename: "ContentPropertyEdge"
                                                            node?: {
                                                              __typename: "ContentProperty"
                                                              name: string
                                                              value?: any | null
                                                              displayName: string
                                                            } | null
                                                          } | null> | null
                                                        } | null
                                                      }
                                                    | { __typename: "ST4TreeNode" }
                                                    | null
                                                }
                                              | null
                                          }>
                                        }
                                      | {
                                          __typename: "VideoMediaInfo"
                                          id: string
                                          resourceId?: string | null
                                          thumbnail?: string | null
                                          width?: number | null
                                          height?: number | null
                                          mediaType?: Types.MediaType | null
                                        }
                                      | null
                                  }
                                | {
                                    __typename: "MediaGroupContent"
                                    children: Array<{
                                      __typename: "NodeRef"
                                      id: string
                                      target?:
                                        | { __typename: "CommentNode"; id: string }
                                        | { __typename: "ExternalSite"; id: string }
                                        | { __typename: "ST4Class"; id: string }
                                        | {
                                            __typename: "ST4Node"
                                            id: string
                                            aspectId: any
                                            content?:
                                              | { __typename: "LexiconContent" }
                                              | { __typename: "LexiconEntryContent" }
                                              | {
                                                  __typename: "MediaContent"
                                                  media?:
                                                    | {
                                                        __typename: "AnimationMediaInfo"
                                                        id: string
                                                        resourceId?: string | null
                                                        thumbnail?: string | null
                                                        width?: number | null
                                                        height?: number | null
                                                        mediaType?: Types.MediaType | null
                                                      }
                                                    | {
                                                        __typename: "ImageMediaInfo"
                                                        id: string
                                                        resourceId?: string | null
                                                        thumbnail?: string | null
                                                        width?: number | null
                                                        height?: number | null
                                                        resolution?: number | null
                                                        colorDepth?: number | null
                                                        size?: number | null
                                                        mime?: string | null
                                                        extension?: string | null
                                                        allowCaching?: boolean | null
                                                        mediaType?: Types.MediaType | null
                                                        src?: string | null
                                                        language?: string | null
                                                      }
                                                    | {
                                                        __typename: "NoMediaInfo"
                                                        id: string
                                                        mediaType?: Types.MediaType | null
                                                      }
                                                    | {
                                                        __typename: "VectorGraphicsMediaInfo"
                                                        id: string
                                                        resourceId?: string | null
                                                        thumbnail?: string | null
                                                        width?: number | null
                                                        height?: number | null
                                                        allowCaching?: boolean | null
                                                        mediaType?: Types.MediaType | null
                                                        language?: string | null
                                                        labels: Array<{
                                                          __typename: "VectorGraphicsLabel"
                                                          id?: string | null
                                                          sequence?: number | null
                                                          autoValue?: string | null
                                                          text?: string | null
                                                          linkType?: Types.VectorGraphicsLabelLinkType | null
                                                          link?: string | null
                                                          ref?:
                                                            | {
                                                                __typename: "GenericRef"
                                                                id: string
                                                                owner?:
                                                                  | { __typename: "CommentNode" }
                                                                  | { __typename: "ExternalSite" }
                                                                  | { __typename: "ST4Class" }
                                                                  | { __typename: "ST4Node"; id: string }
                                                                  | { __typename: "ST4TreeNode" }
                                                                  | null
                                                                target?:
                                                                  | { __typename: "CommentNode" }
                                                                  | {
                                                                      __typename: "ExternalSite"
                                                                      id: string
                                                                      teaserData?: {
                                                                        __typename: "ExternalLinkTeaserData"
                                                                        title?: string | null
                                                                        summary?: string | null
                                                                        iconUrl?: string | null
                                                                        imageUrl?: string | null
                                                                        status?: Types.LinkTargetStatus | null
                                                                      } | null
                                                                    }
                                                                  | { __typename: "ST4Class" }
                                                                  | {
                                                                      __typename: "ST4Node"
                                                                      id: string
                                                                      aspectId: any
                                                                      label?: string | null
                                                                      properties?: {
                                                                        __typename: "ContentPropertyConnection"
                                                                        edges?: Array<{
                                                                          __typename: "ContentPropertyEdge"
                                                                          node?: {
                                                                            __typename: "ContentProperty"
                                                                            name: string
                                                                            value?: any | null
                                                                            displayName: string
                                                                          } | null
                                                                        } | null> | null
                                                                      } | null
                                                                    }
                                                                  | { __typename: "ST4TreeNode" }
                                                                  | null
                                                              }
                                                            | {
                                                                __typename: "NodeRef"
                                                                id: string
                                                                owner?:
                                                                  | { __typename: "CommentNode" }
                                                                  | { __typename: "ExternalSite" }
                                                                  | { __typename: "ST4Class" }
                                                                  | { __typename: "ST4Node"; id: string }
                                                                  | { __typename: "ST4TreeNode" }
                                                                  | null
                                                                target?:
                                                                  | { __typename: "CommentNode" }
                                                                  | {
                                                                      __typename: "ExternalSite"
                                                                      id: string
                                                                      teaserData?: {
                                                                        __typename: "ExternalLinkTeaserData"
                                                                        title?: string | null
                                                                        summary?: string | null
                                                                        iconUrl?: string | null
                                                                        imageUrl?: string | null
                                                                        status?: Types.LinkTargetStatus | null
                                                                      } | null
                                                                    }
                                                                  | { __typename: "ST4Class" }
                                                                  | {
                                                                      __typename: "ST4Node"
                                                                      id: string
                                                                      aspectId: any
                                                                      label?: string | null
                                                                      properties?: {
                                                                        __typename: "ContentPropertyConnection"
                                                                        edges?: Array<{
                                                                          __typename: "ContentPropertyEdge"
                                                                          node?: {
                                                                            __typename: "ContentProperty"
                                                                            name: string
                                                                            value?: any | null
                                                                            displayName: string
                                                                          } | null
                                                                        } | null> | null
                                                                      } | null
                                                                    }
                                                                  | { __typename: "ST4TreeNode" }
                                                                  | null
                                                              }
                                                            | null
                                                        }>
                                                      }
                                                    | {
                                                        __typename: "VideoMediaInfo"
                                                        id: string
                                                        resourceId?: string | null
                                                        thumbnail?: string | null
                                                        width?: number | null
                                                        height?: number | null
                                                        mediaType?: Types.MediaType | null
                                                      }
                                                    | null
                                                }
                                              | {
                                                  __typename: "MediaGroupContent"
                                                  media?:
                                                    | {
                                                        __typename: "AnimationMediaInfo"
                                                        id: string
                                                        resourceId?: string | null
                                                        thumbnail?: string | null
                                                        width?: number | null
                                                        height?: number | null
                                                        mediaType?: Types.MediaType | null
                                                      }
                                                    | {
                                                        __typename: "ImageMediaInfo"
                                                        id: string
                                                        resourceId?: string | null
                                                        thumbnail?: string | null
                                                        width?: number | null
                                                        height?: number | null
                                                        resolution?: number | null
                                                        colorDepth?: number | null
                                                        size?: number | null
                                                        mime?: string | null
                                                        extension?: string | null
                                                        allowCaching?: boolean | null
                                                        mediaType?: Types.MediaType | null
                                                        src?: string | null
                                                        language?: string | null
                                                      }
                                                    | {
                                                        __typename: "NoMediaInfo"
                                                        id: string
                                                        mediaType?: Types.MediaType | null
                                                      }
                                                    | {
                                                        __typename: "VectorGraphicsMediaInfo"
                                                        id: string
                                                        resourceId?: string | null
                                                        thumbnail?: string | null
                                                        width?: number | null
                                                        height?: number | null
                                                        allowCaching?: boolean | null
                                                        mediaType?: Types.MediaType | null
                                                        language?: string | null
                                                        labels: Array<{
                                                          __typename: "VectorGraphicsLabel"
                                                          id?: string | null
                                                          sequence?: number | null
                                                          autoValue?: string | null
                                                          text?: string | null
                                                          linkType?: Types.VectorGraphicsLabelLinkType | null
                                                          link?: string | null
                                                          ref?:
                                                            | {
                                                                __typename: "GenericRef"
                                                                id: string
                                                                owner?:
                                                                  | { __typename: "CommentNode" }
                                                                  | { __typename: "ExternalSite" }
                                                                  | { __typename: "ST4Class" }
                                                                  | { __typename: "ST4Node"; id: string }
                                                                  | { __typename: "ST4TreeNode" }
                                                                  | null
                                                                target?:
                                                                  | { __typename: "CommentNode" }
                                                                  | {
                                                                      __typename: "ExternalSite"
                                                                      id: string
                                                                      teaserData?: {
                                                                        __typename: "ExternalLinkTeaserData"
                                                                        title?: string | null
                                                                        summary?: string | null
                                                                        iconUrl?: string | null
                                                                        imageUrl?: string | null
                                                                        status?: Types.LinkTargetStatus | null
                                                                      } | null
                                                                    }
                                                                  | { __typename: "ST4Class" }
                                                                  | {
                                                                      __typename: "ST4Node"
                                                                      id: string
                                                                      aspectId: any
                                                                      label?: string | null
                                                                      properties?: {
                                                                        __typename: "ContentPropertyConnection"
                                                                        edges?: Array<{
                                                                          __typename: "ContentPropertyEdge"
                                                                          node?: {
                                                                            __typename: "ContentProperty"
                                                                            name: string
                                                                            value?: any | null
                                                                            displayName: string
                                                                          } | null
                                                                        } | null> | null
                                                                      } | null
                                                                    }
                                                                  | { __typename: "ST4TreeNode" }
                                                                  | null
                                                              }
                                                            | {
                                                                __typename: "NodeRef"
                                                                id: string
                                                                owner?:
                                                                  | { __typename: "CommentNode" }
                                                                  | { __typename: "ExternalSite" }
                                                                  | { __typename: "ST4Class" }
                                                                  | { __typename: "ST4Node"; id: string }
                                                                  | { __typename: "ST4TreeNode" }
                                                                  | null
                                                                target?:
                                                                  | { __typename: "CommentNode" }
                                                                  | {
                                                                      __typename: "ExternalSite"
                                                                      id: string
                                                                      teaserData?: {
                                                                        __typename: "ExternalLinkTeaserData"
                                                                        title?: string | null
                                                                        summary?: string | null
                                                                        iconUrl?: string | null
                                                                        imageUrl?: string | null
                                                                        status?: Types.LinkTargetStatus | null
                                                                      } | null
                                                                    }
                                                                  | { __typename: "ST4Class" }
                                                                  | {
                                                                      __typename: "ST4Node"
                                                                      id: string
                                                                      aspectId: any
                                                                      label?: string | null
                                                                      properties?: {
                                                                        __typename: "ContentPropertyConnection"
                                                                        edges?: Array<{
                                                                          __typename: "ContentPropertyEdge"
                                                                          node?: {
                                                                            __typename: "ContentProperty"
                                                                            name: string
                                                                            value?: any | null
                                                                            displayName: string
                                                                          } | null
                                                                        } | null> | null
                                                                      } | null
                                                                    }
                                                                  | { __typename: "ST4TreeNode" }
                                                                  | null
                                                              }
                                                            | null
                                                        }>
                                                      }
                                                    | {
                                                        __typename: "VideoMediaInfo"
                                                        id: string
                                                        resourceId?: string | null
                                                        thumbnail?: string | null
                                                        width?: number | null
                                                        height?: number | null
                                                        mediaType?: Types.MediaType | null
                                                      }
                                                    | null
                                                }
                                              | { __typename: "ProjectContent" }
                                              | { __typename: "TermContent" }
                                              | { __typename: "TextContent" }
                                              | { __typename: "TextGroupContent" }
                                              | { __typename: "VariableTableContent" }
                                              | null
                                          }
                                        | { __typename: "ST4TreeNode"; id: string }
                                        | null
                                    }>
                                    media?:
                                      | {
                                          __typename: "AnimationMediaInfo"
                                          id: string
                                          resourceId?: string | null
                                          thumbnail?: string | null
                                          width?: number | null
                                          height?: number | null
                                          mediaType?: Types.MediaType | null
                                        }
                                      | {
                                          __typename: "ImageMediaInfo"
                                          id: string
                                          resourceId?: string | null
                                          thumbnail?: string | null
                                          width?: number | null
                                          height?: number | null
                                          resolution?: number | null
                                          colorDepth?: number | null
                                          size?: number | null
                                          mime?: string | null
                                          extension?: string | null
                                          allowCaching?: boolean | null
                                          mediaType?: Types.MediaType | null
                                          src?: string | null
                                          language?: string | null
                                        }
                                      | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                                      | {
                                          __typename: "VectorGraphicsMediaInfo"
                                          id: string
                                          resourceId?: string | null
                                          thumbnail?: string | null
                                          width?: number | null
                                          height?: number | null
                                          allowCaching?: boolean | null
                                          mediaType?: Types.MediaType | null
                                          language?: string | null
                                          labels: Array<{
                                            __typename: "VectorGraphicsLabel"
                                            id?: string | null
                                            sequence?: number | null
                                            autoValue?: string | null
                                            text?: string | null
                                            linkType?: Types.VectorGraphicsLabelLinkType | null
                                            link?: string | null
                                            ref?:
                                              | {
                                                  __typename: "GenericRef"
                                                  id: string
                                                  owner?:
                                                    | { __typename: "CommentNode" }
                                                    | { __typename: "ExternalSite" }
                                                    | { __typename: "ST4Class" }
                                                    | { __typename: "ST4Node"; id: string }
                                                    | { __typename: "ST4TreeNode" }
                                                    | null
                                                  target?:
                                                    | { __typename: "CommentNode" }
                                                    | {
                                                        __typename: "ExternalSite"
                                                        id: string
                                                        teaserData?: {
                                                          __typename: "ExternalLinkTeaserData"
                                                          title?: string | null
                                                          summary?: string | null
                                                          iconUrl?: string | null
                                                          imageUrl?: string | null
                                                          status?: Types.LinkTargetStatus | null
                                                        } | null
                                                      }
                                                    | { __typename: "ST4Class" }
                                                    | {
                                                        __typename: "ST4Node"
                                                        id: string
                                                        aspectId: any
                                                        label?: string | null
                                                        properties?: {
                                                          __typename: "ContentPropertyConnection"
                                                          edges?: Array<{
                                                            __typename: "ContentPropertyEdge"
                                                            node?: {
                                                              __typename: "ContentProperty"
                                                              name: string
                                                              value?: any | null
                                                              displayName: string
                                                            } | null
                                                          } | null> | null
                                                        } | null
                                                      }
                                                    | { __typename: "ST4TreeNode" }
                                                    | null
                                                }
                                              | {
                                                  __typename: "NodeRef"
                                                  id: string
                                                  owner?:
                                                    | { __typename: "CommentNode" }
                                                    | { __typename: "ExternalSite" }
                                                    | { __typename: "ST4Class" }
                                                    | { __typename: "ST4Node"; id: string }
                                                    | { __typename: "ST4TreeNode" }
                                                    | null
                                                  target?:
                                                    | { __typename: "CommentNode" }
                                                    | {
                                                        __typename: "ExternalSite"
                                                        id: string
                                                        teaserData?: {
                                                          __typename: "ExternalLinkTeaserData"
                                                          title?: string | null
                                                          summary?: string | null
                                                          iconUrl?: string | null
                                                          imageUrl?: string | null
                                                          status?: Types.LinkTargetStatus | null
                                                        } | null
                                                      }
                                                    | { __typename: "ST4Class" }
                                                    | {
                                                        __typename: "ST4Node"
                                                        id: string
                                                        aspectId: any
                                                        label?: string | null
                                                        properties?: {
                                                          __typename: "ContentPropertyConnection"
                                                          edges?: Array<{
                                                            __typename: "ContentPropertyEdge"
                                                            node?: {
                                                              __typename: "ContentProperty"
                                                              name: string
                                                              value?: any | null
                                                              displayName: string
                                                            } | null
                                                          } | null> | null
                                                        } | null
                                                      }
                                                    | { __typename: "ST4TreeNode" }
                                                    | null
                                                }
                                              | null
                                          }>
                                        }
                                      | {
                                          __typename: "VideoMediaInfo"
                                          id: string
                                          resourceId?: string | null
                                          thumbnail?: string | null
                                          width?: number | null
                                          height?: number | null
                                          mediaType?: Types.MediaType | null
                                        }
                                      | null
                                  }
                                | { __typename: "ProjectContent" }
                                | { __typename: "TermContent" }
                                | { __typename: "TextContent" }
                                | { __typename: "TextGroupContent" }
                                | { __typename: "VariableTableContent" }
                                | null
                            }
                          | { __typename: "ST4TreeNode"; id: string }
                          | null
                      }>
                      variableTables?: Array<{
                        __typename: "VariableTableReference"
                        ref?: string | null
                        node?:
                          | { __typename: "CommentNode" }
                          | { __typename: "ExternalSite" }
                          | { __typename: "ST4Class" }
                          | {
                              __typename: "ST4Node"
                              id: string
                              label?: string | null
                              aspectId: any
                              content?:
                                | { __typename: "LexiconContent" }
                                | { __typename: "LexiconEntryContent" }
                                | { __typename: "MediaContent" }
                                | { __typename: "MediaGroupContent" }
                                | { __typename: "ProjectContent" }
                                | { __typename: "TermContent" }
                                | { __typename: "TextContent" }
                                | { __typename: "TextGroupContent" }
                                | {
                                    __typename: "VariableTableContent"
                                    xml?: string | null
                                    valueSets: Array<{ __typename: "ValueSet"; name: string; valueSetId: string }>
                                    variables: Array<{
                                      __typename: "Variable"
                                      name: string
                                      variableId: string
                                      values: Array<string>
                                    }>
                                  }
                                | null
                            }
                          | { __typename: "ST4TreeNode" }
                          | null
                      } | null> | null
                      linkedDataNodes: Array<{
                        __typename: "NodeRef"
                        id: string
                        target?:
                          | { __typename: "CommentNode"; id: string }
                          | { __typename: "ExternalSite"; id: string }
                          | { __typename: "ST4Class"; id: string }
                          | {
                              __typename: "ST4Node"
                              label?: string | null
                              id: string
                              metadata: Array<{
                                __typename: "MetaDataEntry"
                                label?: string | null
                                systemName: string
                                value?: string | null
                                values: Array<string>
                                users: Array<string>
                              }>
                              linkedOntologyNodes: Array<
                                | {
                                    __typename: "GenericRef"
                                    target?:
                                      | { __typename: "CommentNode" }
                                      | { __typename: "ExternalSite" }
                                      | { __typename: "ST4Class" }
                                      | { __typename: "ST4Node"; id: string; label?: string | null }
                                      | { __typename: "ST4TreeNode" }
                                      | null
                                  }
                                | {
                                    __typename: "NodeRef"
                                    linkClassName: string
                                    class?: { __typename: "ST4Class"; label?: string | null } | null
                                    target?:
                                      | { __typename: "CommentNode" }
                                      | { __typename: "ExternalSite" }
                                      | { __typename: "ST4Class" }
                                      | { __typename: "ST4Node"; id: string; label?: string | null }
                                      | { __typename: "ST4TreeNode" }
                                      | null
                                  }
                              >
                            }
                          | { __typename: "ST4TreeNode"; id: string }
                          | null
                      }>
                      lexiconEntries?: Array<{
                        __typename: "NodeRef"
                        id: string
                        target?:
                          | { __typename: "CommentNode"; id: string }
                          | { __typename: "ExternalSite"; id: string }
                          | { __typename: "ST4Class"; id: string }
                          | {
                              __typename: "ST4Node"
                              aspectId: any
                              label?: string | null
                              id: string
                              content?:
                                | { __typename: "LexiconContent" }
                                | {
                                    __typename: "LexiconEntryContent"
                                    descriptionXast?: string | null
                                    subject?: string | null
                                    sortKey?: string | null
                                    lexicon:
                                      | { __typename: "CommentNode"; id: string }
                                      | { __typename: "ExternalSite"; id: string }
                                      | { __typename: "ST4Class"; id: string }
                                      | { __typename: "ST4Node"; label?: string | null; id: string }
                                      | { __typename: "ST4TreeNode"; id: string }
                                    terms: Array<
                                      | { __typename: "CommentNode"; id: string }
                                      | { __typename: "ExternalSite"; id: string }
                                      | { __typename: "ST4Class"; id: string }
                                      | {
                                          __typename: "ST4Node"
                                          id: string
                                          label?: string | null
                                          aspectId: any
                                          content?:
                                            | { __typename: "LexiconContent" }
                                            | { __typename: "LexiconEntryContent" }
                                            | { __typename: "MediaContent" }
                                            | { __typename: "MediaGroupContent" }
                                            | { __typename: "ProjectContent" }
                                            | {
                                                __typename: "TermContent"
                                                gramaticalGender?: string | null
                                                validity?: string | null
                                                editLanguage?: string | null
                                              }
                                            | { __typename: "TextContent" }
                                            | { __typename: "TextGroupContent" }
                                            | { __typename: "VariableTableContent" }
                                            | null
                                        }
                                      | { __typename: "ST4TreeNode"; id: string }
                                    >
                                    image?:
                                      | { __typename: "CommentNode"; id: string }
                                      | { __typename: "ExternalSite"; id: string }
                                      | { __typename: "ST4Class"; id: string }
                                      | {
                                          __typename: "ST4Node"
                                          aspectId: any
                                          id: string
                                          content?:
                                            | { __typename: "LexiconContent" }
                                            | { __typename: "LexiconEntryContent" }
                                            | {
                                                __typename: "MediaContent"
                                                media?:
                                                  | {
                                                      __typename: "AnimationMediaInfo"
                                                      thumbnail?: string | null
                                                      mediaType?: Types.MediaType | null
                                                    }
                                                  | {
                                                      __typename: "ImageMediaInfo"
                                                      src?: string | null
                                                      thumbnail?: string | null
                                                      mediaType?: Types.MediaType | null
                                                    }
                                                  | { __typename: "NoMediaInfo" }
                                                  | {
                                                      __typename: "VectorGraphicsMediaInfo"
                                                      svg?: string | null
                                                      thumbnail?: string | null
                                                      mediaType?: Types.MediaType | null
                                                    }
                                                  | {
                                                      __typename: "VideoMediaInfo"
                                                      thumbnail?: string | null
                                                      mediaType?: Types.MediaType | null
                                                    }
                                                  | null
                                              }
                                            | { __typename: "MediaGroupContent" }
                                            | { __typename: "ProjectContent" }
                                            | { __typename: "TermContent" }
                                            | { __typename: "TextContent" }
                                            | { __typename: "TextGroupContent" }
                                            | { __typename: "VariableTableContent" }
                                            | null
                                        }
                                      | { __typename: "ST4TreeNode"; id: string }
                                      | null
                                  }
                                | { __typename: "MediaContent" }
                                | { __typename: "MediaGroupContent" }
                                | { __typename: "ProjectContent" }
                                | { __typename: "TermContent" }
                                | { __typename: "TextContent" }
                                | { __typename: "TextGroupContent" }
                                | { __typename: "VariableTableContent" }
                                | null
                            }
                          | { __typename: "ST4TreeNode"; id: string }
                          | null
                      } | null> | null
                      comments: Array<{
                        __typename: "CommentNode"
                        id: string
                        comment:
                          | {
                              __typename: "BlockComment"
                              location: number
                              value?: string | null
                              authorDisplayName?: string | null
                              authorUserName?: string | null
                              commentKey: string
                              editDate?: any | null
                              modificationVersion?: number | null
                              replyToKey?: string | null
                              sortOrder?: number | null
                              commentType?: string | null
                              state?: string | null
                              color: string
                              persistanceState?: Types.PersistanceState | null
                              persistanceAction?: Types.PersistanceAction | null
                              node: {
                                __typename: "ST4Node"
                                aspectId: any
                                id: string
                                content?:
                                  | { __typename: "LexiconContent" }
                                  | { __typename: "LexiconEntryContent" }
                                  | { __typename: "MediaContent" }
                                  | { __typename: "MediaGroupContent" }
                                  | { __typename: "ProjectContent" }
                                  | { __typename: "TermContent" }
                                  | { __typename: "TextContent"; id: string; historyNumber: number }
                                  | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                                  | { __typename: "VariableTableContent" }
                                  | null
                              }
                              actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
                            }
                          | {
                              __typename: "TextComment"
                              endLocation: number
                              startLocation?: number | null
                              value?: string | null
                              authorDisplayName?: string | null
                              authorUserName?: string | null
                              commentKey: string
                              editDate?: any | null
                              modificationVersion?: number | null
                              replyToKey?: string | null
                              sortOrder?: number | null
                              commentType?: string | null
                              state?: string | null
                              color: string
                              persistanceState?: Types.PersistanceState | null
                              persistanceAction?: Types.PersistanceAction | null
                              node: {
                                __typename: "ST4Node"
                                aspectId: any
                                id: string
                                content?:
                                  | { __typename: "LexiconContent" }
                                  | { __typename: "LexiconEntryContent" }
                                  | { __typename: "MediaContent" }
                                  | { __typename: "MediaGroupContent" }
                                  | { __typename: "ProjectContent" }
                                  | { __typename: "TermContent" }
                                  | { __typename: "TextContent"; id: string; historyNumber: number }
                                  | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                                  | { __typename: "VariableTableContent" }
                                  | null
                              }
                              actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
                            }
                      }>
                    }
                  | { __typename: "VariableTableContent" }
                  | null
              }
            }>
            links?: Array<{
              __typename: "TextContentLink"
              ref?: string | null
              node?:
                | { __typename: "CommentNode"; id: string }
                | { __typename: "ExternalSite"; id: string }
                | { __typename: "ST4Class"; id: string }
                | {
                    __typename: "ST4Node"
                    id: string
                    aspectId: any
                    label?: string | null
                    properties?: {
                      __typename: "ContentPropertyConnection"
                      totalCount: number
                      edges?: Array<{
                        __typename: "ContentPropertyEdge"
                        node?: {
                          __typename: "ContentProperty"
                          typeOfValue: Types.ContentPropertyValueType
                          name: string
                          value?: any | null
                          displayName: string
                        } | null
                      } | null> | null
                    } | null
                  }
                | { __typename: "ST4TreeNode"; id: string }
                | null
            } | null> | null
            images: Array<{
              __typename: "NodeRef"
              id: string
              target?:
                | { __typename: "CommentNode"; id: string }
                | { __typename: "ExternalSite"; id: string }
                | { __typename: "ST4Class"; id: string }
                | {
                    __typename: "ST4Node"
                    aspectId: any
                    id: string
                    releaseState: {
                      __typename: "ReleaseStateValue"
                      displayName: string
                      state: Types.ReleaseState
                      isImplicitlyReleased: boolean
                    }
                    content?:
                      | { __typename: "LexiconContent" }
                      | { __typename: "LexiconEntryContent" }
                      | {
                          __typename: "MediaContent"
                          media?:
                            | {
                                __typename: "AnimationMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                mediaType?: Types.MediaType | null
                              }
                            | {
                                __typename: "ImageMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                resolution?: number | null
                                colorDepth?: number | null
                                size?: number | null
                                mime?: string | null
                                extension?: string | null
                                allowCaching?: boolean | null
                                mediaType?: Types.MediaType | null
                                src?: string | null
                                language?: string | null
                              }
                            | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                            | {
                                __typename: "VectorGraphicsMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                allowCaching?: boolean | null
                                mediaType?: Types.MediaType | null
                                language?: string | null
                                labels: Array<{
                                  __typename: "VectorGraphicsLabel"
                                  id?: string | null
                                  sequence?: number | null
                                  autoValue?: string | null
                                  text?: string | null
                                  linkType?: Types.VectorGraphicsLabelLinkType | null
                                  link?: string | null
                                  ref?:
                                    | {
                                        __typename: "GenericRef"
                                        id: string
                                        owner?:
                                          | { __typename: "CommentNode" }
                                          | { __typename: "ExternalSite" }
                                          | { __typename: "ST4Class" }
                                          | { __typename: "ST4Node"; id: string }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                        target?:
                                          | { __typename: "CommentNode" }
                                          | {
                                              __typename: "ExternalSite"
                                              id: string
                                              teaserData?: {
                                                __typename: "ExternalLinkTeaserData"
                                                title?: string | null
                                                summary?: string | null
                                                iconUrl?: string | null
                                                imageUrl?: string | null
                                                status?: Types.LinkTargetStatus | null
                                              } | null
                                            }
                                          | { __typename: "ST4Class" }
                                          | {
                                              __typename: "ST4Node"
                                              id: string
                                              aspectId: any
                                              label?: string | null
                                              properties?: {
                                                __typename: "ContentPropertyConnection"
                                                edges?: Array<{
                                                  __typename: "ContentPropertyEdge"
                                                  node?: {
                                                    __typename: "ContentProperty"
                                                    name: string
                                                    value?: any | null
                                                    displayName: string
                                                  } | null
                                                } | null> | null
                                              } | null
                                            }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                      }
                                    | {
                                        __typename: "NodeRef"
                                        id: string
                                        owner?:
                                          | { __typename: "CommentNode" }
                                          | { __typename: "ExternalSite" }
                                          | { __typename: "ST4Class" }
                                          | { __typename: "ST4Node"; id: string }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                        target?:
                                          | { __typename: "CommentNode" }
                                          | {
                                              __typename: "ExternalSite"
                                              id: string
                                              teaserData?: {
                                                __typename: "ExternalLinkTeaserData"
                                                title?: string | null
                                                summary?: string | null
                                                iconUrl?: string | null
                                                imageUrl?: string | null
                                                status?: Types.LinkTargetStatus | null
                                              } | null
                                            }
                                          | { __typename: "ST4Class" }
                                          | {
                                              __typename: "ST4Node"
                                              id: string
                                              aspectId: any
                                              label?: string | null
                                              properties?: {
                                                __typename: "ContentPropertyConnection"
                                                edges?: Array<{
                                                  __typename: "ContentPropertyEdge"
                                                  node?: {
                                                    __typename: "ContentProperty"
                                                    name: string
                                                    value?: any | null
                                                    displayName: string
                                                  } | null
                                                } | null> | null
                                              } | null
                                            }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                      }
                                    | null
                                }>
                              }
                            | {
                                __typename: "VideoMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                mediaType?: Types.MediaType | null
                              }
                            | null
                        }
                      | {
                          __typename: "MediaGroupContent"
                          children: Array<{
                            __typename: "NodeRef"
                            id: string
                            target?:
                              | { __typename: "CommentNode"; id: string }
                              | { __typename: "ExternalSite"; id: string }
                              | { __typename: "ST4Class"; id: string }
                              | {
                                  __typename: "ST4Node"
                                  id: string
                                  aspectId: any
                                  content?:
                                    | { __typename: "LexiconContent" }
                                    | { __typename: "LexiconEntryContent" }
                                    | {
                                        __typename: "MediaContent"
                                        media?:
                                          | {
                                              __typename: "AnimationMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              mediaType?: Types.MediaType | null
                                            }
                                          | {
                                              __typename: "ImageMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              resolution?: number | null
                                              colorDepth?: number | null
                                              size?: number | null
                                              mime?: string | null
                                              extension?: string | null
                                              allowCaching?: boolean | null
                                              mediaType?: Types.MediaType | null
                                              src?: string | null
                                              language?: string | null
                                            }
                                          | {
                                              __typename: "NoMediaInfo"
                                              id: string
                                              mediaType?: Types.MediaType | null
                                            }
                                          | {
                                              __typename: "VectorGraphicsMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              allowCaching?: boolean | null
                                              mediaType?: Types.MediaType | null
                                              language?: string | null
                                              labels: Array<{
                                                __typename: "VectorGraphicsLabel"
                                                id?: string | null
                                                sequence?: number | null
                                                autoValue?: string | null
                                                text?: string | null
                                                linkType?: Types.VectorGraphicsLabelLinkType | null
                                                link?: string | null
                                                ref?:
                                                  | {
                                                      __typename: "GenericRef"
                                                      id: string
                                                      owner?:
                                                        | { __typename: "CommentNode" }
                                                        | { __typename: "ExternalSite" }
                                                        | { __typename: "ST4Class" }
                                                        | { __typename: "ST4Node"; id: string }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                      target?:
                                                        | { __typename: "CommentNode" }
                                                        | {
                                                            __typename: "ExternalSite"
                                                            id: string
                                                            teaserData?: {
                                                              __typename: "ExternalLinkTeaserData"
                                                              title?: string | null
                                                              summary?: string | null
                                                              iconUrl?: string | null
                                                              imageUrl?: string | null
                                                              status?: Types.LinkTargetStatus | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4Class" }
                                                        | {
                                                            __typename: "ST4Node"
                                                            id: string
                                                            aspectId: any
                                                            label?: string | null
                                                            properties?: {
                                                              __typename: "ContentPropertyConnection"
                                                              edges?: Array<{
                                                                __typename: "ContentPropertyEdge"
                                                                node?: {
                                                                  __typename: "ContentProperty"
                                                                  name: string
                                                                  value?: any | null
                                                                  displayName: string
                                                                } | null
                                                              } | null> | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                    }
                                                  | {
                                                      __typename: "NodeRef"
                                                      id: string
                                                      owner?:
                                                        | { __typename: "CommentNode" }
                                                        | { __typename: "ExternalSite" }
                                                        | { __typename: "ST4Class" }
                                                        | { __typename: "ST4Node"; id: string }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                      target?:
                                                        | { __typename: "CommentNode" }
                                                        | {
                                                            __typename: "ExternalSite"
                                                            id: string
                                                            teaserData?: {
                                                              __typename: "ExternalLinkTeaserData"
                                                              title?: string | null
                                                              summary?: string | null
                                                              iconUrl?: string | null
                                                              imageUrl?: string | null
                                                              status?: Types.LinkTargetStatus | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4Class" }
                                                        | {
                                                            __typename: "ST4Node"
                                                            id: string
                                                            aspectId: any
                                                            label?: string | null
                                                            properties?: {
                                                              __typename: "ContentPropertyConnection"
                                                              edges?: Array<{
                                                                __typename: "ContentPropertyEdge"
                                                                node?: {
                                                                  __typename: "ContentProperty"
                                                                  name: string
                                                                  value?: any | null
                                                                  displayName: string
                                                                } | null
                                                              } | null> | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                    }
                                                  | null
                                              }>
                                            }
                                          | {
                                              __typename: "VideoMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              mediaType?: Types.MediaType | null
                                            }
                                          | null
                                      }
                                    | {
                                        __typename: "MediaGroupContent"
                                        media?:
                                          | {
                                              __typename: "AnimationMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              mediaType?: Types.MediaType | null
                                            }
                                          | {
                                              __typename: "ImageMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              resolution?: number | null
                                              colorDepth?: number | null
                                              size?: number | null
                                              mime?: string | null
                                              extension?: string | null
                                              allowCaching?: boolean | null
                                              mediaType?: Types.MediaType | null
                                              src?: string | null
                                              language?: string | null
                                            }
                                          | {
                                              __typename: "NoMediaInfo"
                                              id: string
                                              mediaType?: Types.MediaType | null
                                            }
                                          | {
                                              __typename: "VectorGraphicsMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              allowCaching?: boolean | null
                                              mediaType?: Types.MediaType | null
                                              language?: string | null
                                              labels: Array<{
                                                __typename: "VectorGraphicsLabel"
                                                id?: string | null
                                                sequence?: number | null
                                                autoValue?: string | null
                                                text?: string | null
                                                linkType?: Types.VectorGraphicsLabelLinkType | null
                                                link?: string | null
                                                ref?:
                                                  | {
                                                      __typename: "GenericRef"
                                                      id: string
                                                      owner?:
                                                        | { __typename: "CommentNode" }
                                                        | { __typename: "ExternalSite" }
                                                        | { __typename: "ST4Class" }
                                                        | { __typename: "ST4Node"; id: string }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                      target?:
                                                        | { __typename: "CommentNode" }
                                                        | {
                                                            __typename: "ExternalSite"
                                                            id: string
                                                            teaserData?: {
                                                              __typename: "ExternalLinkTeaserData"
                                                              title?: string | null
                                                              summary?: string | null
                                                              iconUrl?: string | null
                                                              imageUrl?: string | null
                                                              status?: Types.LinkTargetStatus | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4Class" }
                                                        | {
                                                            __typename: "ST4Node"
                                                            id: string
                                                            aspectId: any
                                                            label?: string | null
                                                            properties?: {
                                                              __typename: "ContentPropertyConnection"
                                                              edges?: Array<{
                                                                __typename: "ContentPropertyEdge"
                                                                node?: {
                                                                  __typename: "ContentProperty"
                                                                  name: string
                                                                  value?: any | null
                                                                  displayName: string
                                                                } | null
                                                              } | null> | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                    }
                                                  | {
                                                      __typename: "NodeRef"
                                                      id: string
                                                      owner?:
                                                        | { __typename: "CommentNode" }
                                                        | { __typename: "ExternalSite" }
                                                        | { __typename: "ST4Class" }
                                                        | { __typename: "ST4Node"; id: string }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                      target?:
                                                        | { __typename: "CommentNode" }
                                                        | {
                                                            __typename: "ExternalSite"
                                                            id: string
                                                            teaserData?: {
                                                              __typename: "ExternalLinkTeaserData"
                                                              title?: string | null
                                                              summary?: string | null
                                                              iconUrl?: string | null
                                                              imageUrl?: string | null
                                                              status?: Types.LinkTargetStatus | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4Class" }
                                                        | {
                                                            __typename: "ST4Node"
                                                            id: string
                                                            aspectId: any
                                                            label?: string | null
                                                            properties?: {
                                                              __typename: "ContentPropertyConnection"
                                                              edges?: Array<{
                                                                __typename: "ContentPropertyEdge"
                                                                node?: {
                                                                  __typename: "ContentProperty"
                                                                  name: string
                                                                  value?: any | null
                                                                  displayName: string
                                                                } | null
                                                              } | null> | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                    }
                                                  | null
                                              }>
                                            }
                                          | {
                                              __typename: "VideoMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              mediaType?: Types.MediaType | null
                                            }
                                          | null
                                      }
                                    | { __typename: "ProjectContent" }
                                    | { __typename: "TermContent" }
                                    | { __typename: "TextContent" }
                                    | { __typename: "TextGroupContent" }
                                    | { __typename: "VariableTableContent" }
                                    | null
                                }
                              | { __typename: "ST4TreeNode"; id: string }
                              | null
                          }>
                          media?:
                            | {
                                __typename: "AnimationMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                mediaType?: Types.MediaType | null
                              }
                            | {
                                __typename: "ImageMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                resolution?: number | null
                                colorDepth?: number | null
                                size?: number | null
                                mime?: string | null
                                extension?: string | null
                                allowCaching?: boolean | null
                                mediaType?: Types.MediaType | null
                                src?: string | null
                                language?: string | null
                              }
                            | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                            | {
                                __typename: "VectorGraphicsMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                allowCaching?: boolean | null
                                mediaType?: Types.MediaType | null
                                language?: string | null
                                labels: Array<{
                                  __typename: "VectorGraphicsLabel"
                                  id?: string | null
                                  sequence?: number | null
                                  autoValue?: string | null
                                  text?: string | null
                                  linkType?: Types.VectorGraphicsLabelLinkType | null
                                  link?: string | null
                                  ref?:
                                    | {
                                        __typename: "GenericRef"
                                        id: string
                                        owner?:
                                          | { __typename: "CommentNode" }
                                          | { __typename: "ExternalSite" }
                                          | { __typename: "ST4Class" }
                                          | { __typename: "ST4Node"; id: string }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                        target?:
                                          | { __typename: "CommentNode" }
                                          | {
                                              __typename: "ExternalSite"
                                              id: string
                                              teaserData?: {
                                                __typename: "ExternalLinkTeaserData"
                                                title?: string | null
                                                summary?: string | null
                                                iconUrl?: string | null
                                                imageUrl?: string | null
                                                status?: Types.LinkTargetStatus | null
                                              } | null
                                            }
                                          | { __typename: "ST4Class" }
                                          | {
                                              __typename: "ST4Node"
                                              id: string
                                              aspectId: any
                                              label?: string | null
                                              properties?: {
                                                __typename: "ContentPropertyConnection"
                                                edges?: Array<{
                                                  __typename: "ContentPropertyEdge"
                                                  node?: {
                                                    __typename: "ContentProperty"
                                                    name: string
                                                    value?: any | null
                                                    displayName: string
                                                  } | null
                                                } | null> | null
                                              } | null
                                            }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                      }
                                    | {
                                        __typename: "NodeRef"
                                        id: string
                                        owner?:
                                          | { __typename: "CommentNode" }
                                          | { __typename: "ExternalSite" }
                                          | { __typename: "ST4Class" }
                                          | { __typename: "ST4Node"; id: string }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                        target?:
                                          | { __typename: "CommentNode" }
                                          | {
                                              __typename: "ExternalSite"
                                              id: string
                                              teaserData?: {
                                                __typename: "ExternalLinkTeaserData"
                                                title?: string | null
                                                summary?: string | null
                                                iconUrl?: string | null
                                                imageUrl?: string | null
                                                status?: Types.LinkTargetStatus | null
                                              } | null
                                            }
                                          | { __typename: "ST4Class" }
                                          | {
                                              __typename: "ST4Node"
                                              id: string
                                              aspectId: any
                                              label?: string | null
                                              properties?: {
                                                __typename: "ContentPropertyConnection"
                                                edges?: Array<{
                                                  __typename: "ContentPropertyEdge"
                                                  node?: {
                                                    __typename: "ContentProperty"
                                                    name: string
                                                    value?: any | null
                                                    displayName: string
                                                  } | null
                                                } | null> | null
                                              } | null
                                            }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                      }
                                    | null
                                }>
                              }
                            | {
                                __typename: "VideoMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                mediaType?: Types.MediaType | null
                              }
                            | null
                        }
                      | { __typename: "ProjectContent" }
                      | { __typename: "TermContent" }
                      | { __typename: "TextContent" }
                      | { __typename: "TextGroupContent" }
                      | { __typename: "VariableTableContent" }
                      | null
                  }
                | { __typename: "ST4TreeNode"; id: string }
                | null
            }>
            variableTables?: Array<{
              __typename: "VariableTableReference"
              ref?: string | null
              node?:
                | { __typename: "CommentNode" }
                | { __typename: "ExternalSite" }
                | { __typename: "ST4Class" }
                | {
                    __typename: "ST4Node"
                    id: string
                    label?: string | null
                    aspectId: any
                    content?:
                      | { __typename: "LexiconContent" }
                      | { __typename: "LexiconEntryContent" }
                      | { __typename: "MediaContent" }
                      | { __typename: "MediaGroupContent" }
                      | { __typename: "ProjectContent" }
                      | { __typename: "TermContent" }
                      | { __typename: "TextContent" }
                      | { __typename: "TextGroupContent" }
                      | {
                          __typename: "VariableTableContent"
                          xml?: string | null
                          valueSets: Array<{ __typename: "ValueSet"; name: string; valueSetId: string }>
                          variables: Array<{
                            __typename: "Variable"
                            name: string
                            variableId: string
                            values: Array<string>
                          }>
                        }
                      | null
                  }
                | { __typename: "ST4TreeNode" }
                | null
            } | null> | null
            linkedDataNodes: Array<{
              __typename: "NodeRef"
              id: string
              target?:
                | { __typename: "CommentNode"; id: string }
                | { __typename: "ExternalSite"; id: string }
                | { __typename: "ST4Class"; id: string }
                | {
                    __typename: "ST4Node"
                    label?: string | null
                    id: string
                    metadata: Array<{
                      __typename: "MetaDataEntry"
                      label?: string | null
                      systemName: string
                      value?: string | null
                      values: Array<string>
                      users: Array<string>
                    }>
                    linkedOntologyNodes: Array<
                      | {
                          __typename: "GenericRef"
                          target?:
                            | { __typename: "CommentNode" }
                            | { __typename: "ExternalSite" }
                            | { __typename: "ST4Class" }
                            | { __typename: "ST4Node"; id: string; label?: string | null }
                            | { __typename: "ST4TreeNode" }
                            | null
                        }
                      | {
                          __typename: "NodeRef"
                          linkClassName: string
                          class?: { __typename: "ST4Class"; label?: string | null } | null
                          target?:
                            | { __typename: "CommentNode" }
                            | { __typename: "ExternalSite" }
                            | { __typename: "ST4Class" }
                            | { __typename: "ST4Node"; id: string; label?: string | null }
                            | { __typename: "ST4TreeNode" }
                            | null
                        }
                    >
                  }
                | { __typename: "ST4TreeNode"; id: string }
                | null
            }>
            lexiconEntries?: Array<{
              __typename: "NodeRef"
              id: string
              target?:
                | { __typename: "CommentNode"; id: string }
                | { __typename: "ExternalSite"; id: string }
                | { __typename: "ST4Class"; id: string }
                | {
                    __typename: "ST4Node"
                    aspectId: any
                    label?: string | null
                    id: string
                    content?:
                      | { __typename: "LexiconContent" }
                      | {
                          __typename: "LexiconEntryContent"
                          descriptionXast?: string | null
                          subject?: string | null
                          sortKey?: string | null
                          lexicon:
                            | { __typename: "CommentNode"; id: string }
                            | { __typename: "ExternalSite"; id: string }
                            | { __typename: "ST4Class"; id: string }
                            | { __typename: "ST4Node"; label?: string | null; id: string }
                            | { __typename: "ST4TreeNode"; id: string }
                          terms: Array<
                            | { __typename: "CommentNode"; id: string }
                            | { __typename: "ExternalSite"; id: string }
                            | { __typename: "ST4Class"; id: string }
                            | {
                                __typename: "ST4Node"
                                id: string
                                label?: string | null
                                aspectId: any
                                content?:
                                  | { __typename: "LexiconContent" }
                                  | { __typename: "LexiconEntryContent" }
                                  | { __typename: "MediaContent" }
                                  | { __typename: "MediaGroupContent" }
                                  | { __typename: "ProjectContent" }
                                  | {
                                      __typename: "TermContent"
                                      gramaticalGender?: string | null
                                      validity?: string | null
                                      editLanguage?: string | null
                                    }
                                  | { __typename: "TextContent" }
                                  | { __typename: "TextGroupContent" }
                                  | { __typename: "VariableTableContent" }
                                  | null
                              }
                            | { __typename: "ST4TreeNode"; id: string }
                          >
                          image?:
                            | { __typename: "CommentNode"; id: string }
                            | { __typename: "ExternalSite"; id: string }
                            | { __typename: "ST4Class"; id: string }
                            | {
                                __typename: "ST4Node"
                                aspectId: any
                                id: string
                                content?:
                                  | { __typename: "LexiconContent" }
                                  | { __typename: "LexiconEntryContent" }
                                  | {
                                      __typename: "MediaContent"
                                      media?:
                                        | {
                                            __typename: "AnimationMediaInfo"
                                            thumbnail?: string | null
                                            mediaType?: Types.MediaType | null
                                          }
                                        | {
                                            __typename: "ImageMediaInfo"
                                            src?: string | null
                                            thumbnail?: string | null
                                            mediaType?: Types.MediaType | null
                                          }
                                        | { __typename: "NoMediaInfo" }
                                        | {
                                            __typename: "VectorGraphicsMediaInfo"
                                            svg?: string | null
                                            thumbnail?: string | null
                                            mediaType?: Types.MediaType | null
                                          }
                                        | {
                                            __typename: "VideoMediaInfo"
                                            thumbnail?: string | null
                                            mediaType?: Types.MediaType | null
                                          }
                                        | null
                                    }
                                  | { __typename: "MediaGroupContent" }
                                  | { __typename: "ProjectContent" }
                                  | { __typename: "TermContent" }
                                  | { __typename: "TextContent" }
                                  | { __typename: "TextGroupContent" }
                                  | { __typename: "VariableTableContent" }
                                  | null
                              }
                            | { __typename: "ST4TreeNode"; id: string }
                            | null
                        }
                      | { __typename: "MediaContent" }
                      | { __typename: "MediaGroupContent" }
                      | { __typename: "ProjectContent" }
                      | { __typename: "TermContent" }
                      | { __typename: "TextContent" }
                      | { __typename: "TextGroupContent" }
                      | { __typename: "VariableTableContent" }
                      | null
                  }
                | { __typename: "ST4TreeNode"; id: string }
                | null
            } | null> | null
            comments: Array<{
              __typename: "CommentNode"
              id: string
              comment:
                | {
                    __typename: "BlockComment"
                    location: number
                    value?: string | null
                    authorDisplayName?: string | null
                    authorUserName?: string | null
                    commentKey: string
                    editDate?: any | null
                    modificationVersion?: number | null
                    replyToKey?: string | null
                    sortOrder?: number | null
                    commentType?: string | null
                    state?: string | null
                    color: string
                    persistanceState?: Types.PersistanceState | null
                    persistanceAction?: Types.PersistanceAction | null
                    node: {
                      __typename: "ST4Node"
                      aspectId: any
                      id: string
                      content?:
                        | { __typename: "LexiconContent" }
                        | { __typename: "LexiconEntryContent" }
                        | { __typename: "MediaContent" }
                        | { __typename: "MediaGroupContent" }
                        | { __typename: "ProjectContent" }
                        | { __typename: "TermContent" }
                        | { __typename: "TextContent"; id: string; historyNumber: number }
                        | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                        | { __typename: "VariableTableContent" }
                        | null
                    }
                    actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
                  }
                | {
                    __typename: "TextComment"
                    endLocation: number
                    startLocation?: number | null
                    value?: string | null
                    authorDisplayName?: string | null
                    authorUserName?: string | null
                    commentKey: string
                    editDate?: any | null
                    modificationVersion?: number | null
                    replyToKey?: string | null
                    sortOrder?: number | null
                    commentType?: string | null
                    state?: string | null
                    color: string
                    persistanceState?: Types.PersistanceState | null
                    persistanceAction?: Types.PersistanceAction | null
                    node: {
                      __typename: "ST4Node"
                      aspectId: any
                      id: string
                      content?:
                        | { __typename: "LexiconContent" }
                        | { __typename: "LexiconEntryContent" }
                        | { __typename: "MediaContent" }
                        | { __typename: "MediaGroupContent" }
                        | { __typename: "ProjectContent" }
                        | { __typename: "TermContent" }
                        | { __typename: "TextContent"; id: string; historyNumber: number }
                        | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                        | { __typename: "VariableTableContent" }
                        | null
                    }
                    actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
                  }
            }>
          }
        | {
            __typename: "TextGroupContent"
            currentNodeId?: any | null
            groupNodeId: any
            id: string
            xast?: string | null
            historyNumber: number
            parts: Array<{
              __typename: "TextContentPart"
              ref: string
              contentNode: {
                __typename: "ST4Node"
                id: string
                aspectId: any
                content?:
                  | { __typename: "LexiconContent" }
                  | { __typename: "LexiconEntryContent" }
                  | { __typename: "MediaContent" }
                  | { __typename: "MediaGroupContent" }
                  | { __typename: "ProjectContent" }
                  | { __typename: "TermContent" }
                  | {
                      __typename: "TextContent"
                      id: string
                      xast?: string | null
                      historyNumber: number
                      links?: Array<{
                        __typename: "TextContentLink"
                        ref?: string | null
                        node?:
                          | { __typename: "CommentNode"; id: string }
                          | { __typename: "ExternalSite"; id: string }
                          | { __typename: "ST4Class"; id: string }
                          | {
                              __typename: "ST4Node"
                              id: string
                              aspectId: any
                              label?: string | null
                              properties?: {
                                __typename: "ContentPropertyConnection"
                                totalCount: number
                                edges?: Array<{
                                  __typename: "ContentPropertyEdge"
                                  node?: {
                                    __typename: "ContentProperty"
                                    typeOfValue: Types.ContentPropertyValueType
                                    name: string
                                    value?: any | null
                                    displayName: string
                                  } | null
                                } | null> | null
                              } | null
                            }
                          | { __typename: "ST4TreeNode"; id: string }
                          | null
                      } | null> | null
                      images: Array<{
                        __typename: "NodeRef"
                        id: string
                        target?:
                          | { __typename: "CommentNode"; id: string }
                          | { __typename: "ExternalSite"; id: string }
                          | { __typename: "ST4Class"; id: string }
                          | {
                              __typename: "ST4Node"
                              aspectId: any
                              id: string
                              releaseState: {
                                __typename: "ReleaseStateValue"
                                displayName: string
                                state: Types.ReleaseState
                                isImplicitlyReleased: boolean
                              }
                              content?:
                                | { __typename: "LexiconContent" }
                                | { __typename: "LexiconEntryContent" }
                                | {
                                    __typename: "MediaContent"
                                    media?:
                                      | {
                                          __typename: "AnimationMediaInfo"
                                          id: string
                                          resourceId?: string | null
                                          thumbnail?: string | null
                                          width?: number | null
                                          height?: number | null
                                          mediaType?: Types.MediaType | null
                                        }
                                      | {
                                          __typename: "ImageMediaInfo"
                                          id: string
                                          resourceId?: string | null
                                          thumbnail?: string | null
                                          width?: number | null
                                          height?: number | null
                                          resolution?: number | null
                                          colorDepth?: number | null
                                          size?: number | null
                                          mime?: string | null
                                          extension?: string | null
                                          allowCaching?: boolean | null
                                          mediaType?: Types.MediaType | null
                                          src?: string | null
                                          language?: string | null
                                        }
                                      | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                                      | {
                                          __typename: "VectorGraphicsMediaInfo"
                                          id: string
                                          resourceId?: string | null
                                          thumbnail?: string | null
                                          width?: number | null
                                          height?: number | null
                                          allowCaching?: boolean | null
                                          mediaType?: Types.MediaType | null
                                          language?: string | null
                                          labels: Array<{
                                            __typename: "VectorGraphicsLabel"
                                            id?: string | null
                                            sequence?: number | null
                                            autoValue?: string | null
                                            text?: string | null
                                            linkType?: Types.VectorGraphicsLabelLinkType | null
                                            link?: string | null
                                            ref?:
                                              | {
                                                  __typename: "GenericRef"
                                                  id: string
                                                  owner?:
                                                    | { __typename: "CommentNode" }
                                                    | { __typename: "ExternalSite" }
                                                    | { __typename: "ST4Class" }
                                                    | { __typename: "ST4Node"; id: string }
                                                    | { __typename: "ST4TreeNode" }
                                                    | null
                                                  target?:
                                                    | { __typename: "CommentNode" }
                                                    | {
                                                        __typename: "ExternalSite"
                                                        id: string
                                                        teaserData?: {
                                                          __typename: "ExternalLinkTeaserData"
                                                          title?: string | null
                                                          summary?: string | null
                                                          iconUrl?: string | null
                                                          imageUrl?: string | null
                                                          status?: Types.LinkTargetStatus | null
                                                        } | null
                                                      }
                                                    | { __typename: "ST4Class" }
                                                    | {
                                                        __typename: "ST4Node"
                                                        id: string
                                                        aspectId: any
                                                        label?: string | null
                                                        properties?: {
                                                          __typename: "ContentPropertyConnection"
                                                          edges?: Array<{
                                                            __typename: "ContentPropertyEdge"
                                                            node?: {
                                                              __typename: "ContentProperty"
                                                              name: string
                                                              value?: any | null
                                                              displayName: string
                                                            } | null
                                                          } | null> | null
                                                        } | null
                                                      }
                                                    | { __typename: "ST4TreeNode" }
                                                    | null
                                                }
                                              | {
                                                  __typename: "NodeRef"
                                                  id: string
                                                  owner?:
                                                    | { __typename: "CommentNode" }
                                                    | { __typename: "ExternalSite" }
                                                    | { __typename: "ST4Class" }
                                                    | { __typename: "ST4Node"; id: string }
                                                    | { __typename: "ST4TreeNode" }
                                                    | null
                                                  target?:
                                                    | { __typename: "CommentNode" }
                                                    | {
                                                        __typename: "ExternalSite"
                                                        id: string
                                                        teaserData?: {
                                                          __typename: "ExternalLinkTeaserData"
                                                          title?: string | null
                                                          summary?: string | null
                                                          iconUrl?: string | null
                                                          imageUrl?: string | null
                                                          status?: Types.LinkTargetStatus | null
                                                        } | null
                                                      }
                                                    | { __typename: "ST4Class" }
                                                    | {
                                                        __typename: "ST4Node"
                                                        id: string
                                                        aspectId: any
                                                        label?: string | null
                                                        properties?: {
                                                          __typename: "ContentPropertyConnection"
                                                          edges?: Array<{
                                                            __typename: "ContentPropertyEdge"
                                                            node?: {
                                                              __typename: "ContentProperty"
                                                              name: string
                                                              value?: any | null
                                                              displayName: string
                                                            } | null
                                                          } | null> | null
                                                        } | null
                                                      }
                                                    | { __typename: "ST4TreeNode" }
                                                    | null
                                                }
                                              | null
                                          }>
                                        }
                                      | {
                                          __typename: "VideoMediaInfo"
                                          id: string
                                          resourceId?: string | null
                                          thumbnail?: string | null
                                          width?: number | null
                                          height?: number | null
                                          mediaType?: Types.MediaType | null
                                        }
                                      | null
                                  }
                                | {
                                    __typename: "MediaGroupContent"
                                    children: Array<{
                                      __typename: "NodeRef"
                                      id: string
                                      target?:
                                        | { __typename: "CommentNode"; id: string }
                                        | { __typename: "ExternalSite"; id: string }
                                        | { __typename: "ST4Class"; id: string }
                                        | {
                                            __typename: "ST4Node"
                                            id: string
                                            aspectId: any
                                            content?:
                                              | { __typename: "LexiconContent" }
                                              | { __typename: "LexiconEntryContent" }
                                              | {
                                                  __typename: "MediaContent"
                                                  media?:
                                                    | {
                                                        __typename: "AnimationMediaInfo"
                                                        id: string
                                                        resourceId?: string | null
                                                        thumbnail?: string | null
                                                        width?: number | null
                                                        height?: number | null
                                                        mediaType?: Types.MediaType | null
                                                      }
                                                    | {
                                                        __typename: "ImageMediaInfo"
                                                        id: string
                                                        resourceId?: string | null
                                                        thumbnail?: string | null
                                                        width?: number | null
                                                        height?: number | null
                                                        resolution?: number | null
                                                        colorDepth?: number | null
                                                        size?: number | null
                                                        mime?: string | null
                                                        extension?: string | null
                                                        allowCaching?: boolean | null
                                                        mediaType?: Types.MediaType | null
                                                        src?: string | null
                                                        language?: string | null
                                                      }
                                                    | {
                                                        __typename: "NoMediaInfo"
                                                        id: string
                                                        mediaType?: Types.MediaType | null
                                                      }
                                                    | {
                                                        __typename: "VectorGraphicsMediaInfo"
                                                        id: string
                                                        resourceId?: string | null
                                                        thumbnail?: string | null
                                                        width?: number | null
                                                        height?: number | null
                                                        allowCaching?: boolean | null
                                                        mediaType?: Types.MediaType | null
                                                        language?: string | null
                                                        labels: Array<{
                                                          __typename: "VectorGraphicsLabel"
                                                          id?: string | null
                                                          sequence?: number | null
                                                          autoValue?: string | null
                                                          text?: string | null
                                                          linkType?: Types.VectorGraphicsLabelLinkType | null
                                                          link?: string | null
                                                          ref?:
                                                            | {
                                                                __typename: "GenericRef"
                                                                id: string
                                                                owner?:
                                                                  | { __typename: "CommentNode" }
                                                                  | { __typename: "ExternalSite" }
                                                                  | { __typename: "ST4Class" }
                                                                  | { __typename: "ST4Node"; id: string }
                                                                  | { __typename: "ST4TreeNode" }
                                                                  | null
                                                                target?:
                                                                  | { __typename: "CommentNode" }
                                                                  | {
                                                                      __typename: "ExternalSite"
                                                                      id: string
                                                                      teaserData?: {
                                                                        __typename: "ExternalLinkTeaserData"
                                                                        title?: string | null
                                                                        summary?: string | null
                                                                        iconUrl?: string | null
                                                                        imageUrl?: string | null
                                                                        status?: Types.LinkTargetStatus | null
                                                                      } | null
                                                                    }
                                                                  | { __typename: "ST4Class" }
                                                                  | {
                                                                      __typename: "ST4Node"
                                                                      id: string
                                                                      aspectId: any
                                                                      label?: string | null
                                                                      properties?: {
                                                                        __typename: "ContentPropertyConnection"
                                                                        edges?: Array<{
                                                                          __typename: "ContentPropertyEdge"
                                                                          node?: {
                                                                            __typename: "ContentProperty"
                                                                            name: string
                                                                            value?: any | null
                                                                            displayName: string
                                                                          } | null
                                                                        } | null> | null
                                                                      } | null
                                                                    }
                                                                  | { __typename: "ST4TreeNode" }
                                                                  | null
                                                              }
                                                            | {
                                                                __typename: "NodeRef"
                                                                id: string
                                                                owner?:
                                                                  | { __typename: "CommentNode" }
                                                                  | { __typename: "ExternalSite" }
                                                                  | { __typename: "ST4Class" }
                                                                  | { __typename: "ST4Node"; id: string }
                                                                  | { __typename: "ST4TreeNode" }
                                                                  | null
                                                                target?:
                                                                  | { __typename: "CommentNode" }
                                                                  | {
                                                                      __typename: "ExternalSite"
                                                                      id: string
                                                                      teaserData?: {
                                                                        __typename: "ExternalLinkTeaserData"
                                                                        title?: string | null
                                                                        summary?: string | null
                                                                        iconUrl?: string | null
                                                                        imageUrl?: string | null
                                                                        status?: Types.LinkTargetStatus | null
                                                                      } | null
                                                                    }
                                                                  | { __typename: "ST4Class" }
                                                                  | {
                                                                      __typename: "ST4Node"
                                                                      id: string
                                                                      aspectId: any
                                                                      label?: string | null
                                                                      properties?: {
                                                                        __typename: "ContentPropertyConnection"
                                                                        edges?: Array<{
                                                                          __typename: "ContentPropertyEdge"
                                                                          node?: {
                                                                            __typename: "ContentProperty"
                                                                            name: string
                                                                            value?: any | null
                                                                            displayName: string
                                                                          } | null
                                                                        } | null> | null
                                                                      } | null
                                                                    }
                                                                  | { __typename: "ST4TreeNode" }
                                                                  | null
                                                              }
                                                            | null
                                                        }>
                                                      }
                                                    | {
                                                        __typename: "VideoMediaInfo"
                                                        id: string
                                                        resourceId?: string | null
                                                        thumbnail?: string | null
                                                        width?: number | null
                                                        height?: number | null
                                                        mediaType?: Types.MediaType | null
                                                      }
                                                    | null
                                                }
                                              | {
                                                  __typename: "MediaGroupContent"
                                                  media?:
                                                    | {
                                                        __typename: "AnimationMediaInfo"
                                                        id: string
                                                        resourceId?: string | null
                                                        thumbnail?: string | null
                                                        width?: number | null
                                                        height?: number | null
                                                        mediaType?: Types.MediaType | null
                                                      }
                                                    | {
                                                        __typename: "ImageMediaInfo"
                                                        id: string
                                                        resourceId?: string | null
                                                        thumbnail?: string | null
                                                        width?: number | null
                                                        height?: number | null
                                                        resolution?: number | null
                                                        colorDepth?: number | null
                                                        size?: number | null
                                                        mime?: string | null
                                                        extension?: string | null
                                                        allowCaching?: boolean | null
                                                        mediaType?: Types.MediaType | null
                                                        src?: string | null
                                                        language?: string | null
                                                      }
                                                    | {
                                                        __typename: "NoMediaInfo"
                                                        id: string
                                                        mediaType?: Types.MediaType | null
                                                      }
                                                    | {
                                                        __typename: "VectorGraphicsMediaInfo"
                                                        id: string
                                                        resourceId?: string | null
                                                        thumbnail?: string | null
                                                        width?: number | null
                                                        height?: number | null
                                                        allowCaching?: boolean | null
                                                        mediaType?: Types.MediaType | null
                                                        language?: string | null
                                                        labels: Array<{
                                                          __typename: "VectorGraphicsLabel"
                                                          id?: string | null
                                                          sequence?: number | null
                                                          autoValue?: string | null
                                                          text?: string | null
                                                          linkType?: Types.VectorGraphicsLabelLinkType | null
                                                          link?: string | null
                                                          ref?:
                                                            | {
                                                                __typename: "GenericRef"
                                                                id: string
                                                                owner?:
                                                                  | { __typename: "CommentNode" }
                                                                  | { __typename: "ExternalSite" }
                                                                  | { __typename: "ST4Class" }
                                                                  | { __typename: "ST4Node"; id: string }
                                                                  | { __typename: "ST4TreeNode" }
                                                                  | null
                                                                target?:
                                                                  | { __typename: "CommentNode" }
                                                                  | {
                                                                      __typename: "ExternalSite"
                                                                      id: string
                                                                      teaserData?: {
                                                                        __typename: "ExternalLinkTeaserData"
                                                                        title?: string | null
                                                                        summary?: string | null
                                                                        iconUrl?: string | null
                                                                        imageUrl?: string | null
                                                                        status?: Types.LinkTargetStatus | null
                                                                      } | null
                                                                    }
                                                                  | { __typename: "ST4Class" }
                                                                  | {
                                                                      __typename: "ST4Node"
                                                                      id: string
                                                                      aspectId: any
                                                                      label?: string | null
                                                                      properties?: {
                                                                        __typename: "ContentPropertyConnection"
                                                                        edges?: Array<{
                                                                          __typename: "ContentPropertyEdge"
                                                                          node?: {
                                                                            __typename: "ContentProperty"
                                                                            name: string
                                                                            value?: any | null
                                                                            displayName: string
                                                                          } | null
                                                                        } | null> | null
                                                                      } | null
                                                                    }
                                                                  | { __typename: "ST4TreeNode" }
                                                                  | null
                                                              }
                                                            | {
                                                                __typename: "NodeRef"
                                                                id: string
                                                                owner?:
                                                                  | { __typename: "CommentNode" }
                                                                  | { __typename: "ExternalSite" }
                                                                  | { __typename: "ST4Class" }
                                                                  | { __typename: "ST4Node"; id: string }
                                                                  | { __typename: "ST4TreeNode" }
                                                                  | null
                                                                target?:
                                                                  | { __typename: "CommentNode" }
                                                                  | {
                                                                      __typename: "ExternalSite"
                                                                      id: string
                                                                      teaserData?: {
                                                                        __typename: "ExternalLinkTeaserData"
                                                                        title?: string | null
                                                                        summary?: string | null
                                                                        iconUrl?: string | null
                                                                        imageUrl?: string | null
                                                                        status?: Types.LinkTargetStatus | null
                                                                      } | null
                                                                    }
                                                                  | { __typename: "ST4Class" }
                                                                  | {
                                                                      __typename: "ST4Node"
                                                                      id: string
                                                                      aspectId: any
                                                                      label?: string | null
                                                                      properties?: {
                                                                        __typename: "ContentPropertyConnection"
                                                                        edges?: Array<{
                                                                          __typename: "ContentPropertyEdge"
                                                                          node?: {
                                                                            __typename: "ContentProperty"
                                                                            name: string
                                                                            value?: any | null
                                                                            displayName: string
                                                                          } | null
                                                                        } | null> | null
                                                                      } | null
                                                                    }
                                                                  | { __typename: "ST4TreeNode" }
                                                                  | null
                                                              }
                                                            | null
                                                        }>
                                                      }
                                                    | {
                                                        __typename: "VideoMediaInfo"
                                                        id: string
                                                        resourceId?: string | null
                                                        thumbnail?: string | null
                                                        width?: number | null
                                                        height?: number | null
                                                        mediaType?: Types.MediaType | null
                                                      }
                                                    | null
                                                }
                                              | { __typename: "ProjectContent" }
                                              | { __typename: "TermContent" }
                                              | { __typename: "TextContent" }
                                              | { __typename: "TextGroupContent" }
                                              | { __typename: "VariableTableContent" }
                                              | null
                                          }
                                        | { __typename: "ST4TreeNode"; id: string }
                                        | null
                                    }>
                                    media?:
                                      | {
                                          __typename: "AnimationMediaInfo"
                                          id: string
                                          resourceId?: string | null
                                          thumbnail?: string | null
                                          width?: number | null
                                          height?: number | null
                                          mediaType?: Types.MediaType | null
                                        }
                                      | {
                                          __typename: "ImageMediaInfo"
                                          id: string
                                          resourceId?: string | null
                                          thumbnail?: string | null
                                          width?: number | null
                                          height?: number | null
                                          resolution?: number | null
                                          colorDepth?: number | null
                                          size?: number | null
                                          mime?: string | null
                                          extension?: string | null
                                          allowCaching?: boolean | null
                                          mediaType?: Types.MediaType | null
                                          src?: string | null
                                          language?: string | null
                                        }
                                      | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                                      | {
                                          __typename: "VectorGraphicsMediaInfo"
                                          id: string
                                          resourceId?: string | null
                                          thumbnail?: string | null
                                          width?: number | null
                                          height?: number | null
                                          allowCaching?: boolean | null
                                          mediaType?: Types.MediaType | null
                                          language?: string | null
                                          labels: Array<{
                                            __typename: "VectorGraphicsLabel"
                                            id?: string | null
                                            sequence?: number | null
                                            autoValue?: string | null
                                            text?: string | null
                                            linkType?: Types.VectorGraphicsLabelLinkType | null
                                            link?: string | null
                                            ref?:
                                              | {
                                                  __typename: "GenericRef"
                                                  id: string
                                                  owner?:
                                                    | { __typename: "CommentNode" }
                                                    | { __typename: "ExternalSite" }
                                                    | { __typename: "ST4Class" }
                                                    | { __typename: "ST4Node"; id: string }
                                                    | { __typename: "ST4TreeNode" }
                                                    | null
                                                  target?:
                                                    | { __typename: "CommentNode" }
                                                    | {
                                                        __typename: "ExternalSite"
                                                        id: string
                                                        teaserData?: {
                                                          __typename: "ExternalLinkTeaserData"
                                                          title?: string | null
                                                          summary?: string | null
                                                          iconUrl?: string | null
                                                          imageUrl?: string | null
                                                          status?: Types.LinkTargetStatus | null
                                                        } | null
                                                      }
                                                    | { __typename: "ST4Class" }
                                                    | {
                                                        __typename: "ST4Node"
                                                        id: string
                                                        aspectId: any
                                                        label?: string | null
                                                        properties?: {
                                                          __typename: "ContentPropertyConnection"
                                                          edges?: Array<{
                                                            __typename: "ContentPropertyEdge"
                                                            node?: {
                                                              __typename: "ContentProperty"
                                                              name: string
                                                              value?: any | null
                                                              displayName: string
                                                            } | null
                                                          } | null> | null
                                                        } | null
                                                      }
                                                    | { __typename: "ST4TreeNode" }
                                                    | null
                                                }
                                              | {
                                                  __typename: "NodeRef"
                                                  id: string
                                                  owner?:
                                                    | { __typename: "CommentNode" }
                                                    | { __typename: "ExternalSite" }
                                                    | { __typename: "ST4Class" }
                                                    | { __typename: "ST4Node"; id: string }
                                                    | { __typename: "ST4TreeNode" }
                                                    | null
                                                  target?:
                                                    | { __typename: "CommentNode" }
                                                    | {
                                                        __typename: "ExternalSite"
                                                        id: string
                                                        teaserData?: {
                                                          __typename: "ExternalLinkTeaserData"
                                                          title?: string | null
                                                          summary?: string | null
                                                          iconUrl?: string | null
                                                          imageUrl?: string | null
                                                          status?: Types.LinkTargetStatus | null
                                                        } | null
                                                      }
                                                    | { __typename: "ST4Class" }
                                                    | {
                                                        __typename: "ST4Node"
                                                        id: string
                                                        aspectId: any
                                                        label?: string | null
                                                        properties?: {
                                                          __typename: "ContentPropertyConnection"
                                                          edges?: Array<{
                                                            __typename: "ContentPropertyEdge"
                                                            node?: {
                                                              __typename: "ContentProperty"
                                                              name: string
                                                              value?: any | null
                                                              displayName: string
                                                            } | null
                                                          } | null> | null
                                                        } | null
                                                      }
                                                    | { __typename: "ST4TreeNode" }
                                                    | null
                                                }
                                              | null
                                          }>
                                        }
                                      | {
                                          __typename: "VideoMediaInfo"
                                          id: string
                                          resourceId?: string | null
                                          thumbnail?: string | null
                                          width?: number | null
                                          height?: number | null
                                          mediaType?: Types.MediaType | null
                                        }
                                      | null
                                  }
                                | { __typename: "ProjectContent" }
                                | { __typename: "TermContent" }
                                | { __typename: "TextContent" }
                                | { __typename: "TextGroupContent" }
                                | { __typename: "VariableTableContent" }
                                | null
                            }
                          | { __typename: "ST4TreeNode"; id: string }
                          | null
                      }>
                      variableTables?: Array<{
                        __typename: "VariableTableReference"
                        ref?: string | null
                        node?:
                          | { __typename: "CommentNode" }
                          | { __typename: "ExternalSite" }
                          | { __typename: "ST4Class" }
                          | {
                              __typename: "ST4Node"
                              id: string
                              label?: string | null
                              aspectId: any
                              content?:
                                | { __typename: "LexiconContent" }
                                | { __typename: "LexiconEntryContent" }
                                | { __typename: "MediaContent" }
                                | { __typename: "MediaGroupContent" }
                                | { __typename: "ProjectContent" }
                                | { __typename: "TermContent" }
                                | { __typename: "TextContent" }
                                | { __typename: "TextGroupContent" }
                                | {
                                    __typename: "VariableTableContent"
                                    xml?: string | null
                                    valueSets: Array<{ __typename: "ValueSet"; name: string; valueSetId: string }>
                                    variables: Array<{
                                      __typename: "Variable"
                                      name: string
                                      variableId: string
                                      values: Array<string>
                                    }>
                                  }
                                | null
                            }
                          | { __typename: "ST4TreeNode" }
                          | null
                      } | null> | null
                      linkedDataNodes: Array<{
                        __typename: "NodeRef"
                        id: string
                        target?:
                          | { __typename: "CommentNode"; id: string }
                          | { __typename: "ExternalSite"; id: string }
                          | { __typename: "ST4Class"; id: string }
                          | {
                              __typename: "ST4Node"
                              label?: string | null
                              id: string
                              metadata: Array<{
                                __typename: "MetaDataEntry"
                                label?: string | null
                                systemName: string
                                value?: string | null
                                values: Array<string>
                                users: Array<string>
                              }>
                              linkedOntologyNodes: Array<
                                | {
                                    __typename: "GenericRef"
                                    target?:
                                      | { __typename: "CommentNode" }
                                      | { __typename: "ExternalSite" }
                                      | { __typename: "ST4Class" }
                                      | { __typename: "ST4Node"; id: string; label?: string | null }
                                      | { __typename: "ST4TreeNode" }
                                      | null
                                  }
                                | {
                                    __typename: "NodeRef"
                                    linkClassName: string
                                    class?: { __typename: "ST4Class"; label?: string | null } | null
                                    target?:
                                      | { __typename: "CommentNode" }
                                      | { __typename: "ExternalSite" }
                                      | { __typename: "ST4Class" }
                                      | { __typename: "ST4Node"; id: string; label?: string | null }
                                      | { __typename: "ST4TreeNode" }
                                      | null
                                  }
                              >
                            }
                          | { __typename: "ST4TreeNode"; id: string }
                          | null
                      }>
                      lexiconEntries?: Array<{
                        __typename: "NodeRef"
                        id: string
                        target?:
                          | { __typename: "CommentNode"; id: string }
                          | { __typename: "ExternalSite"; id: string }
                          | { __typename: "ST4Class"; id: string }
                          | {
                              __typename: "ST4Node"
                              aspectId: any
                              label?: string | null
                              id: string
                              content?:
                                | { __typename: "LexiconContent" }
                                | {
                                    __typename: "LexiconEntryContent"
                                    descriptionXast?: string | null
                                    subject?: string | null
                                    sortKey?: string | null
                                    lexicon:
                                      | { __typename: "CommentNode"; id: string }
                                      | { __typename: "ExternalSite"; id: string }
                                      | { __typename: "ST4Class"; id: string }
                                      | { __typename: "ST4Node"; label?: string | null; id: string }
                                      | { __typename: "ST4TreeNode"; id: string }
                                    terms: Array<
                                      | { __typename: "CommentNode"; id: string }
                                      | { __typename: "ExternalSite"; id: string }
                                      | { __typename: "ST4Class"; id: string }
                                      | {
                                          __typename: "ST4Node"
                                          id: string
                                          label?: string | null
                                          aspectId: any
                                          content?:
                                            | { __typename: "LexiconContent" }
                                            | { __typename: "LexiconEntryContent" }
                                            | { __typename: "MediaContent" }
                                            | { __typename: "MediaGroupContent" }
                                            | { __typename: "ProjectContent" }
                                            | {
                                                __typename: "TermContent"
                                                gramaticalGender?: string | null
                                                validity?: string | null
                                                editLanguage?: string | null
                                              }
                                            | { __typename: "TextContent" }
                                            | { __typename: "TextGroupContent" }
                                            | { __typename: "VariableTableContent" }
                                            | null
                                        }
                                      | { __typename: "ST4TreeNode"; id: string }
                                    >
                                    image?:
                                      | { __typename: "CommentNode"; id: string }
                                      | { __typename: "ExternalSite"; id: string }
                                      | { __typename: "ST4Class"; id: string }
                                      | {
                                          __typename: "ST4Node"
                                          aspectId: any
                                          id: string
                                          content?:
                                            | { __typename: "LexiconContent" }
                                            | { __typename: "LexiconEntryContent" }
                                            | {
                                                __typename: "MediaContent"
                                                media?:
                                                  | {
                                                      __typename: "AnimationMediaInfo"
                                                      thumbnail?: string | null
                                                      mediaType?: Types.MediaType | null
                                                    }
                                                  | {
                                                      __typename: "ImageMediaInfo"
                                                      src?: string | null
                                                      thumbnail?: string | null
                                                      mediaType?: Types.MediaType | null
                                                    }
                                                  | { __typename: "NoMediaInfo" }
                                                  | {
                                                      __typename: "VectorGraphicsMediaInfo"
                                                      svg?: string | null
                                                      thumbnail?: string | null
                                                      mediaType?: Types.MediaType | null
                                                    }
                                                  | {
                                                      __typename: "VideoMediaInfo"
                                                      thumbnail?: string | null
                                                      mediaType?: Types.MediaType | null
                                                    }
                                                  | null
                                              }
                                            | { __typename: "MediaGroupContent" }
                                            | { __typename: "ProjectContent" }
                                            | { __typename: "TermContent" }
                                            | { __typename: "TextContent" }
                                            | { __typename: "TextGroupContent" }
                                            | { __typename: "VariableTableContent" }
                                            | null
                                        }
                                      | { __typename: "ST4TreeNode"; id: string }
                                      | null
                                  }
                                | { __typename: "MediaContent" }
                                | { __typename: "MediaGroupContent" }
                                | { __typename: "ProjectContent" }
                                | { __typename: "TermContent" }
                                | { __typename: "TextContent" }
                                | { __typename: "TextGroupContent" }
                                | { __typename: "VariableTableContent" }
                                | null
                            }
                          | { __typename: "ST4TreeNode"; id: string }
                          | null
                      } | null> | null
                      comments: Array<{
                        __typename: "CommentNode"
                        id: string
                        comment:
                          | {
                              __typename: "BlockComment"
                              location: number
                              value?: string | null
                              authorDisplayName?: string | null
                              authorUserName?: string | null
                              commentKey: string
                              editDate?: any | null
                              modificationVersion?: number | null
                              replyToKey?: string | null
                              sortOrder?: number | null
                              commentType?: string | null
                              state?: string | null
                              color: string
                              persistanceState?: Types.PersistanceState | null
                              persistanceAction?: Types.PersistanceAction | null
                              node: {
                                __typename: "ST4Node"
                                aspectId: any
                                id: string
                                content?:
                                  | { __typename: "LexiconContent" }
                                  | { __typename: "LexiconEntryContent" }
                                  | { __typename: "MediaContent" }
                                  | { __typename: "MediaGroupContent" }
                                  | { __typename: "ProjectContent" }
                                  | { __typename: "TermContent" }
                                  | { __typename: "TextContent"; id: string; historyNumber: number }
                                  | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                                  | { __typename: "VariableTableContent" }
                                  | null
                              }
                              actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
                            }
                          | {
                              __typename: "TextComment"
                              endLocation: number
                              startLocation?: number | null
                              value?: string | null
                              authorDisplayName?: string | null
                              authorUserName?: string | null
                              commentKey: string
                              editDate?: any | null
                              modificationVersion?: number | null
                              replyToKey?: string | null
                              sortOrder?: number | null
                              commentType?: string | null
                              state?: string | null
                              color: string
                              persistanceState?: Types.PersistanceState | null
                              persistanceAction?: Types.PersistanceAction | null
                              node: {
                                __typename: "ST4Node"
                                aspectId: any
                                id: string
                                content?:
                                  | { __typename: "LexiconContent" }
                                  | { __typename: "LexiconEntryContent" }
                                  | { __typename: "MediaContent" }
                                  | { __typename: "MediaGroupContent" }
                                  | { __typename: "ProjectContent" }
                                  | { __typename: "TermContent" }
                                  | { __typename: "TextContent"; id: string; historyNumber: number }
                                  | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                                  | { __typename: "VariableTableContent" }
                                  | null
                              }
                              actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
                            }
                      }>
                    }
                  | {
                      __typename: "TextGroupContent"
                      currentNodeId?: any | null
                      groupNodeId: any
                      id: string
                      xast?: string | null
                      historyNumber: number
                      children: Array<{
                        __typename: "NodeRef"
                        id: string
                        target?:
                          | { __typename: "CommentNode"; id: string }
                          | { __typename: "ExternalSite"; id: string }
                          | { __typename: "ST4Class"; id: string }
                          | { __typename: "ST4Node"; label?: string | null; aspectId: any; id: string }
                          | { __typename: "ST4TreeNode"; id: string }
                          | null
                      }>
                      links?: Array<{
                        __typename: "TextContentLink"
                        ref?: string | null
                        node?:
                          | { __typename: "CommentNode"; id: string }
                          | { __typename: "ExternalSite"; id: string }
                          | { __typename: "ST4Class"; id: string }
                          | {
                              __typename: "ST4Node"
                              id: string
                              aspectId: any
                              label?: string | null
                              properties?: {
                                __typename: "ContentPropertyConnection"
                                totalCount: number
                                edges?: Array<{
                                  __typename: "ContentPropertyEdge"
                                  node?: {
                                    __typename: "ContentProperty"
                                    typeOfValue: Types.ContentPropertyValueType
                                    name: string
                                    value?: any | null
                                    displayName: string
                                  } | null
                                } | null> | null
                              } | null
                            }
                          | { __typename: "ST4TreeNode"; id: string }
                          | null
                      } | null> | null
                      images: Array<{
                        __typename: "NodeRef"
                        id: string
                        target?:
                          | { __typename: "CommentNode"; id: string }
                          | { __typename: "ExternalSite"; id: string }
                          | { __typename: "ST4Class"; id: string }
                          | {
                              __typename: "ST4Node"
                              aspectId: any
                              id: string
                              releaseState: {
                                __typename: "ReleaseStateValue"
                                displayName: string
                                state: Types.ReleaseState
                                isImplicitlyReleased: boolean
                              }
                              content?:
                                | { __typename: "LexiconContent" }
                                | { __typename: "LexiconEntryContent" }
                                | {
                                    __typename: "MediaContent"
                                    media?:
                                      | {
                                          __typename: "AnimationMediaInfo"
                                          id: string
                                          resourceId?: string | null
                                          thumbnail?: string | null
                                          width?: number | null
                                          height?: number | null
                                          mediaType?: Types.MediaType | null
                                        }
                                      | {
                                          __typename: "ImageMediaInfo"
                                          id: string
                                          resourceId?: string | null
                                          thumbnail?: string | null
                                          width?: number | null
                                          height?: number | null
                                          resolution?: number | null
                                          colorDepth?: number | null
                                          size?: number | null
                                          mime?: string | null
                                          extension?: string | null
                                          allowCaching?: boolean | null
                                          mediaType?: Types.MediaType | null
                                          src?: string | null
                                          language?: string | null
                                        }
                                      | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                                      | {
                                          __typename: "VectorGraphicsMediaInfo"
                                          id: string
                                          resourceId?: string | null
                                          thumbnail?: string | null
                                          width?: number | null
                                          height?: number | null
                                          allowCaching?: boolean | null
                                          mediaType?: Types.MediaType | null
                                          language?: string | null
                                          labels: Array<{
                                            __typename: "VectorGraphicsLabel"
                                            id?: string | null
                                            sequence?: number | null
                                            autoValue?: string | null
                                            text?: string | null
                                            linkType?: Types.VectorGraphicsLabelLinkType | null
                                            link?: string | null
                                            ref?:
                                              | {
                                                  __typename: "GenericRef"
                                                  id: string
                                                  owner?:
                                                    | { __typename: "CommentNode" }
                                                    | { __typename: "ExternalSite" }
                                                    | { __typename: "ST4Class" }
                                                    | { __typename: "ST4Node"; id: string }
                                                    | { __typename: "ST4TreeNode" }
                                                    | null
                                                  target?:
                                                    | { __typename: "CommentNode" }
                                                    | {
                                                        __typename: "ExternalSite"
                                                        id: string
                                                        teaserData?: {
                                                          __typename: "ExternalLinkTeaserData"
                                                          title?: string | null
                                                          summary?: string | null
                                                          iconUrl?: string | null
                                                          imageUrl?: string | null
                                                          status?: Types.LinkTargetStatus | null
                                                        } | null
                                                      }
                                                    | { __typename: "ST4Class" }
                                                    | {
                                                        __typename: "ST4Node"
                                                        id: string
                                                        aspectId: any
                                                        label?: string | null
                                                        properties?: {
                                                          __typename: "ContentPropertyConnection"
                                                          edges?: Array<{
                                                            __typename: "ContentPropertyEdge"
                                                            node?: {
                                                              __typename: "ContentProperty"
                                                              name: string
                                                              value?: any | null
                                                              displayName: string
                                                            } | null
                                                          } | null> | null
                                                        } | null
                                                      }
                                                    | { __typename: "ST4TreeNode" }
                                                    | null
                                                }
                                              | {
                                                  __typename: "NodeRef"
                                                  id: string
                                                  owner?:
                                                    | { __typename: "CommentNode" }
                                                    | { __typename: "ExternalSite" }
                                                    | { __typename: "ST4Class" }
                                                    | { __typename: "ST4Node"; id: string }
                                                    | { __typename: "ST4TreeNode" }
                                                    | null
                                                  target?:
                                                    | { __typename: "CommentNode" }
                                                    | {
                                                        __typename: "ExternalSite"
                                                        id: string
                                                        teaserData?: {
                                                          __typename: "ExternalLinkTeaserData"
                                                          title?: string | null
                                                          summary?: string | null
                                                          iconUrl?: string | null
                                                          imageUrl?: string | null
                                                          status?: Types.LinkTargetStatus | null
                                                        } | null
                                                      }
                                                    | { __typename: "ST4Class" }
                                                    | {
                                                        __typename: "ST4Node"
                                                        id: string
                                                        aspectId: any
                                                        label?: string | null
                                                        properties?: {
                                                          __typename: "ContentPropertyConnection"
                                                          edges?: Array<{
                                                            __typename: "ContentPropertyEdge"
                                                            node?: {
                                                              __typename: "ContentProperty"
                                                              name: string
                                                              value?: any | null
                                                              displayName: string
                                                            } | null
                                                          } | null> | null
                                                        } | null
                                                      }
                                                    | { __typename: "ST4TreeNode" }
                                                    | null
                                                }
                                              | null
                                          }>
                                        }
                                      | {
                                          __typename: "VideoMediaInfo"
                                          id: string
                                          resourceId?: string | null
                                          thumbnail?: string | null
                                          width?: number | null
                                          height?: number | null
                                          mediaType?: Types.MediaType | null
                                        }
                                      | null
                                  }
                                | {
                                    __typename: "MediaGroupContent"
                                    children: Array<{
                                      __typename: "NodeRef"
                                      id: string
                                      target?:
                                        | { __typename: "CommentNode"; id: string }
                                        | { __typename: "ExternalSite"; id: string }
                                        | { __typename: "ST4Class"; id: string }
                                        | {
                                            __typename: "ST4Node"
                                            id: string
                                            aspectId: any
                                            content?:
                                              | { __typename: "LexiconContent" }
                                              | { __typename: "LexiconEntryContent" }
                                              | {
                                                  __typename: "MediaContent"
                                                  media?:
                                                    | {
                                                        __typename: "AnimationMediaInfo"
                                                        id: string
                                                        resourceId?: string | null
                                                        thumbnail?: string | null
                                                        width?: number | null
                                                        height?: number | null
                                                        mediaType?: Types.MediaType | null
                                                      }
                                                    | {
                                                        __typename: "ImageMediaInfo"
                                                        id: string
                                                        resourceId?: string | null
                                                        thumbnail?: string | null
                                                        width?: number | null
                                                        height?: number | null
                                                        resolution?: number | null
                                                        colorDepth?: number | null
                                                        size?: number | null
                                                        mime?: string | null
                                                        extension?: string | null
                                                        allowCaching?: boolean | null
                                                        mediaType?: Types.MediaType | null
                                                        src?: string | null
                                                        language?: string | null
                                                      }
                                                    | {
                                                        __typename: "NoMediaInfo"
                                                        id: string
                                                        mediaType?: Types.MediaType | null
                                                      }
                                                    | {
                                                        __typename: "VectorGraphicsMediaInfo"
                                                        id: string
                                                        resourceId?: string | null
                                                        thumbnail?: string | null
                                                        width?: number | null
                                                        height?: number | null
                                                        allowCaching?: boolean | null
                                                        mediaType?: Types.MediaType | null
                                                        language?: string | null
                                                        labels: Array<{
                                                          __typename: "VectorGraphicsLabel"
                                                          id?: string | null
                                                          sequence?: number | null
                                                          autoValue?: string | null
                                                          text?: string | null
                                                          linkType?: Types.VectorGraphicsLabelLinkType | null
                                                          link?: string | null
                                                          ref?:
                                                            | {
                                                                __typename: "GenericRef"
                                                                id: string
                                                                owner?:
                                                                  | { __typename: "CommentNode" }
                                                                  | { __typename: "ExternalSite" }
                                                                  | { __typename: "ST4Class" }
                                                                  | { __typename: "ST4Node"; id: string }
                                                                  | { __typename: "ST4TreeNode" }
                                                                  | null
                                                                target?:
                                                                  | { __typename: "CommentNode" }
                                                                  | {
                                                                      __typename: "ExternalSite"
                                                                      id: string
                                                                      teaserData?: {
                                                                        __typename: "ExternalLinkTeaserData"
                                                                        title?: string | null
                                                                        summary?: string | null
                                                                        iconUrl?: string | null
                                                                        imageUrl?: string | null
                                                                        status?: Types.LinkTargetStatus | null
                                                                      } | null
                                                                    }
                                                                  | { __typename: "ST4Class" }
                                                                  | {
                                                                      __typename: "ST4Node"
                                                                      id: string
                                                                      aspectId: any
                                                                      label?: string | null
                                                                      properties?: {
                                                                        __typename: "ContentPropertyConnection"
                                                                        edges?: Array<{
                                                                          __typename: "ContentPropertyEdge"
                                                                          node?: {
                                                                            __typename: "ContentProperty"
                                                                            name: string
                                                                            value?: any | null
                                                                            displayName: string
                                                                          } | null
                                                                        } | null> | null
                                                                      } | null
                                                                    }
                                                                  | { __typename: "ST4TreeNode" }
                                                                  | null
                                                              }
                                                            | {
                                                                __typename: "NodeRef"
                                                                id: string
                                                                owner?:
                                                                  | { __typename: "CommentNode" }
                                                                  | { __typename: "ExternalSite" }
                                                                  | { __typename: "ST4Class" }
                                                                  | { __typename: "ST4Node"; id: string }
                                                                  | { __typename: "ST4TreeNode" }
                                                                  | null
                                                                target?:
                                                                  | { __typename: "CommentNode" }
                                                                  | {
                                                                      __typename: "ExternalSite"
                                                                      id: string
                                                                      teaserData?: {
                                                                        __typename: "ExternalLinkTeaserData"
                                                                        title?: string | null
                                                                        summary?: string | null
                                                                        iconUrl?: string | null
                                                                        imageUrl?: string | null
                                                                        status?: Types.LinkTargetStatus | null
                                                                      } | null
                                                                    }
                                                                  | { __typename: "ST4Class" }
                                                                  | {
                                                                      __typename: "ST4Node"
                                                                      id: string
                                                                      aspectId: any
                                                                      label?: string | null
                                                                      properties?: {
                                                                        __typename: "ContentPropertyConnection"
                                                                        edges?: Array<{
                                                                          __typename: "ContentPropertyEdge"
                                                                          node?: {
                                                                            __typename: "ContentProperty"
                                                                            name: string
                                                                            value?: any | null
                                                                            displayName: string
                                                                          } | null
                                                                        } | null> | null
                                                                      } | null
                                                                    }
                                                                  | { __typename: "ST4TreeNode" }
                                                                  | null
                                                              }
                                                            | null
                                                        }>
                                                      }
                                                    | {
                                                        __typename: "VideoMediaInfo"
                                                        id: string
                                                        resourceId?: string | null
                                                        thumbnail?: string | null
                                                        width?: number | null
                                                        height?: number | null
                                                        mediaType?: Types.MediaType | null
                                                      }
                                                    | null
                                                }
                                              | {
                                                  __typename: "MediaGroupContent"
                                                  media?:
                                                    | {
                                                        __typename: "AnimationMediaInfo"
                                                        id: string
                                                        resourceId?: string | null
                                                        thumbnail?: string | null
                                                        width?: number | null
                                                        height?: number | null
                                                        mediaType?: Types.MediaType | null
                                                      }
                                                    | {
                                                        __typename: "ImageMediaInfo"
                                                        id: string
                                                        resourceId?: string | null
                                                        thumbnail?: string | null
                                                        width?: number | null
                                                        height?: number | null
                                                        resolution?: number | null
                                                        colorDepth?: number | null
                                                        size?: number | null
                                                        mime?: string | null
                                                        extension?: string | null
                                                        allowCaching?: boolean | null
                                                        mediaType?: Types.MediaType | null
                                                        src?: string | null
                                                        language?: string | null
                                                      }
                                                    | {
                                                        __typename: "NoMediaInfo"
                                                        id: string
                                                        mediaType?: Types.MediaType | null
                                                      }
                                                    | {
                                                        __typename: "VectorGraphicsMediaInfo"
                                                        id: string
                                                        resourceId?: string | null
                                                        thumbnail?: string | null
                                                        width?: number | null
                                                        height?: number | null
                                                        allowCaching?: boolean | null
                                                        mediaType?: Types.MediaType | null
                                                        language?: string | null
                                                        labels: Array<{
                                                          __typename: "VectorGraphicsLabel"
                                                          id?: string | null
                                                          sequence?: number | null
                                                          autoValue?: string | null
                                                          text?: string | null
                                                          linkType?: Types.VectorGraphicsLabelLinkType | null
                                                          link?: string | null
                                                          ref?:
                                                            | {
                                                                __typename: "GenericRef"
                                                                id: string
                                                                owner?:
                                                                  | { __typename: "CommentNode" }
                                                                  | { __typename: "ExternalSite" }
                                                                  | { __typename: "ST4Class" }
                                                                  | { __typename: "ST4Node"; id: string }
                                                                  | { __typename: "ST4TreeNode" }
                                                                  | null
                                                                target?:
                                                                  | { __typename: "CommentNode" }
                                                                  | {
                                                                      __typename: "ExternalSite"
                                                                      id: string
                                                                      teaserData?: {
                                                                        __typename: "ExternalLinkTeaserData"
                                                                        title?: string | null
                                                                        summary?: string | null
                                                                        iconUrl?: string | null
                                                                        imageUrl?: string | null
                                                                        status?: Types.LinkTargetStatus | null
                                                                      } | null
                                                                    }
                                                                  | { __typename: "ST4Class" }
                                                                  | {
                                                                      __typename: "ST4Node"
                                                                      id: string
                                                                      aspectId: any
                                                                      label?: string | null
                                                                      properties?: {
                                                                        __typename: "ContentPropertyConnection"
                                                                        edges?: Array<{
                                                                          __typename: "ContentPropertyEdge"
                                                                          node?: {
                                                                            __typename: "ContentProperty"
                                                                            name: string
                                                                            value?: any | null
                                                                            displayName: string
                                                                          } | null
                                                                        } | null> | null
                                                                      } | null
                                                                    }
                                                                  | { __typename: "ST4TreeNode" }
                                                                  | null
                                                              }
                                                            | {
                                                                __typename: "NodeRef"
                                                                id: string
                                                                owner?:
                                                                  | { __typename: "CommentNode" }
                                                                  | { __typename: "ExternalSite" }
                                                                  | { __typename: "ST4Class" }
                                                                  | { __typename: "ST4Node"; id: string }
                                                                  | { __typename: "ST4TreeNode" }
                                                                  | null
                                                                target?:
                                                                  | { __typename: "CommentNode" }
                                                                  | {
                                                                      __typename: "ExternalSite"
                                                                      id: string
                                                                      teaserData?: {
                                                                        __typename: "ExternalLinkTeaserData"
                                                                        title?: string | null
                                                                        summary?: string | null
                                                                        iconUrl?: string | null
                                                                        imageUrl?: string | null
                                                                        status?: Types.LinkTargetStatus | null
                                                                      } | null
                                                                    }
                                                                  | { __typename: "ST4Class" }
                                                                  | {
                                                                      __typename: "ST4Node"
                                                                      id: string
                                                                      aspectId: any
                                                                      label?: string | null
                                                                      properties?: {
                                                                        __typename: "ContentPropertyConnection"
                                                                        edges?: Array<{
                                                                          __typename: "ContentPropertyEdge"
                                                                          node?: {
                                                                            __typename: "ContentProperty"
                                                                            name: string
                                                                            value?: any | null
                                                                            displayName: string
                                                                          } | null
                                                                        } | null> | null
                                                                      } | null
                                                                    }
                                                                  | { __typename: "ST4TreeNode" }
                                                                  | null
                                                              }
                                                            | null
                                                        }>
                                                      }
                                                    | {
                                                        __typename: "VideoMediaInfo"
                                                        id: string
                                                        resourceId?: string | null
                                                        thumbnail?: string | null
                                                        width?: number | null
                                                        height?: number | null
                                                        mediaType?: Types.MediaType | null
                                                      }
                                                    | null
                                                }
                                              | { __typename: "ProjectContent" }
                                              | { __typename: "TermContent" }
                                              | { __typename: "TextContent" }
                                              | { __typename: "TextGroupContent" }
                                              | { __typename: "VariableTableContent" }
                                              | null
                                          }
                                        | { __typename: "ST4TreeNode"; id: string }
                                        | null
                                    }>
                                    media?:
                                      | {
                                          __typename: "AnimationMediaInfo"
                                          id: string
                                          resourceId?: string | null
                                          thumbnail?: string | null
                                          width?: number | null
                                          height?: number | null
                                          mediaType?: Types.MediaType | null
                                        }
                                      | {
                                          __typename: "ImageMediaInfo"
                                          id: string
                                          resourceId?: string | null
                                          thumbnail?: string | null
                                          width?: number | null
                                          height?: number | null
                                          resolution?: number | null
                                          colorDepth?: number | null
                                          size?: number | null
                                          mime?: string | null
                                          extension?: string | null
                                          allowCaching?: boolean | null
                                          mediaType?: Types.MediaType | null
                                          src?: string | null
                                          language?: string | null
                                        }
                                      | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                                      | {
                                          __typename: "VectorGraphicsMediaInfo"
                                          id: string
                                          resourceId?: string | null
                                          thumbnail?: string | null
                                          width?: number | null
                                          height?: number | null
                                          allowCaching?: boolean | null
                                          mediaType?: Types.MediaType | null
                                          language?: string | null
                                          labels: Array<{
                                            __typename: "VectorGraphicsLabel"
                                            id?: string | null
                                            sequence?: number | null
                                            autoValue?: string | null
                                            text?: string | null
                                            linkType?: Types.VectorGraphicsLabelLinkType | null
                                            link?: string | null
                                            ref?:
                                              | {
                                                  __typename: "GenericRef"
                                                  id: string
                                                  owner?:
                                                    | { __typename: "CommentNode" }
                                                    | { __typename: "ExternalSite" }
                                                    | { __typename: "ST4Class" }
                                                    | { __typename: "ST4Node"; id: string }
                                                    | { __typename: "ST4TreeNode" }
                                                    | null
                                                  target?:
                                                    | { __typename: "CommentNode" }
                                                    | {
                                                        __typename: "ExternalSite"
                                                        id: string
                                                        teaserData?: {
                                                          __typename: "ExternalLinkTeaserData"
                                                          title?: string | null
                                                          summary?: string | null
                                                          iconUrl?: string | null
                                                          imageUrl?: string | null
                                                          status?: Types.LinkTargetStatus | null
                                                        } | null
                                                      }
                                                    | { __typename: "ST4Class" }
                                                    | {
                                                        __typename: "ST4Node"
                                                        id: string
                                                        aspectId: any
                                                        label?: string | null
                                                        properties?: {
                                                          __typename: "ContentPropertyConnection"
                                                          edges?: Array<{
                                                            __typename: "ContentPropertyEdge"
                                                            node?: {
                                                              __typename: "ContentProperty"
                                                              name: string
                                                              value?: any | null
                                                              displayName: string
                                                            } | null
                                                          } | null> | null
                                                        } | null
                                                      }
                                                    | { __typename: "ST4TreeNode" }
                                                    | null
                                                }
                                              | {
                                                  __typename: "NodeRef"
                                                  id: string
                                                  owner?:
                                                    | { __typename: "CommentNode" }
                                                    | { __typename: "ExternalSite" }
                                                    | { __typename: "ST4Class" }
                                                    | { __typename: "ST4Node"; id: string }
                                                    | { __typename: "ST4TreeNode" }
                                                    | null
                                                  target?:
                                                    | { __typename: "CommentNode" }
                                                    | {
                                                        __typename: "ExternalSite"
                                                        id: string
                                                        teaserData?: {
                                                          __typename: "ExternalLinkTeaserData"
                                                          title?: string | null
                                                          summary?: string | null
                                                          iconUrl?: string | null
                                                          imageUrl?: string | null
                                                          status?: Types.LinkTargetStatus | null
                                                        } | null
                                                      }
                                                    | { __typename: "ST4Class" }
                                                    | {
                                                        __typename: "ST4Node"
                                                        id: string
                                                        aspectId: any
                                                        label?: string | null
                                                        properties?: {
                                                          __typename: "ContentPropertyConnection"
                                                          edges?: Array<{
                                                            __typename: "ContentPropertyEdge"
                                                            node?: {
                                                              __typename: "ContentProperty"
                                                              name: string
                                                              value?: any | null
                                                              displayName: string
                                                            } | null
                                                          } | null> | null
                                                        } | null
                                                      }
                                                    | { __typename: "ST4TreeNode" }
                                                    | null
                                                }
                                              | null
                                          }>
                                        }
                                      | {
                                          __typename: "VideoMediaInfo"
                                          id: string
                                          resourceId?: string | null
                                          thumbnail?: string | null
                                          width?: number | null
                                          height?: number | null
                                          mediaType?: Types.MediaType | null
                                        }
                                      | null
                                  }
                                | { __typename: "ProjectContent" }
                                | { __typename: "TermContent" }
                                | { __typename: "TextContent" }
                                | { __typename: "TextGroupContent" }
                                | { __typename: "VariableTableContent" }
                                | null
                            }
                          | { __typename: "ST4TreeNode"; id: string }
                          | null
                      }>
                      variableTables?: Array<{
                        __typename: "VariableTableReference"
                        ref?: string | null
                        node?:
                          | { __typename: "CommentNode" }
                          | { __typename: "ExternalSite" }
                          | { __typename: "ST4Class" }
                          | {
                              __typename: "ST4Node"
                              id: string
                              label?: string | null
                              aspectId: any
                              content?:
                                | { __typename: "LexiconContent" }
                                | { __typename: "LexiconEntryContent" }
                                | { __typename: "MediaContent" }
                                | { __typename: "MediaGroupContent" }
                                | { __typename: "ProjectContent" }
                                | { __typename: "TermContent" }
                                | { __typename: "TextContent" }
                                | { __typename: "TextGroupContent" }
                                | {
                                    __typename: "VariableTableContent"
                                    xml?: string | null
                                    valueSets: Array<{ __typename: "ValueSet"; name: string; valueSetId: string }>
                                    variables: Array<{
                                      __typename: "Variable"
                                      name: string
                                      variableId: string
                                      values: Array<string>
                                    }>
                                  }
                                | null
                            }
                          | { __typename: "ST4TreeNode" }
                          | null
                      } | null> | null
                      linkedDataNodes: Array<{
                        __typename: "NodeRef"
                        id: string
                        target?:
                          | { __typename: "CommentNode"; id: string }
                          | { __typename: "ExternalSite"; id: string }
                          | { __typename: "ST4Class"; id: string }
                          | {
                              __typename: "ST4Node"
                              label?: string | null
                              id: string
                              metadata: Array<{
                                __typename: "MetaDataEntry"
                                label?: string | null
                                systemName: string
                                value?: string | null
                                values: Array<string>
                                users: Array<string>
                              }>
                              linkedOntologyNodes: Array<
                                | {
                                    __typename: "GenericRef"
                                    target?:
                                      | { __typename: "CommentNode" }
                                      | { __typename: "ExternalSite" }
                                      | { __typename: "ST4Class" }
                                      | { __typename: "ST4Node"; id: string; label?: string | null }
                                      | { __typename: "ST4TreeNode" }
                                      | null
                                  }
                                | {
                                    __typename: "NodeRef"
                                    linkClassName: string
                                    class?: { __typename: "ST4Class"; label?: string | null } | null
                                    target?:
                                      | { __typename: "CommentNode" }
                                      | { __typename: "ExternalSite" }
                                      | { __typename: "ST4Class" }
                                      | { __typename: "ST4Node"; id: string; label?: string | null }
                                      | { __typename: "ST4TreeNode" }
                                      | null
                                  }
                              >
                            }
                          | { __typename: "ST4TreeNode"; id: string }
                          | null
                      }>
                      lexiconEntries?: Array<{
                        __typename: "NodeRef"
                        id: string
                        target?:
                          | { __typename: "CommentNode"; id: string }
                          | { __typename: "ExternalSite"; id: string }
                          | { __typename: "ST4Class"; id: string }
                          | {
                              __typename: "ST4Node"
                              aspectId: any
                              label?: string | null
                              id: string
                              content?:
                                | { __typename: "LexiconContent" }
                                | {
                                    __typename: "LexiconEntryContent"
                                    descriptionXast?: string | null
                                    subject?: string | null
                                    sortKey?: string | null
                                    lexicon:
                                      | { __typename: "CommentNode"; id: string }
                                      | { __typename: "ExternalSite"; id: string }
                                      | { __typename: "ST4Class"; id: string }
                                      | { __typename: "ST4Node"; label?: string | null; id: string }
                                      | { __typename: "ST4TreeNode"; id: string }
                                    terms: Array<
                                      | { __typename: "CommentNode"; id: string }
                                      | { __typename: "ExternalSite"; id: string }
                                      | { __typename: "ST4Class"; id: string }
                                      | {
                                          __typename: "ST4Node"
                                          id: string
                                          label?: string | null
                                          aspectId: any
                                          content?:
                                            | { __typename: "LexiconContent" }
                                            | { __typename: "LexiconEntryContent" }
                                            | { __typename: "MediaContent" }
                                            | { __typename: "MediaGroupContent" }
                                            | { __typename: "ProjectContent" }
                                            | {
                                                __typename: "TermContent"
                                                gramaticalGender?: string | null
                                                validity?: string | null
                                                editLanguage?: string | null
                                              }
                                            | { __typename: "TextContent" }
                                            | { __typename: "TextGroupContent" }
                                            | { __typename: "VariableTableContent" }
                                            | null
                                        }
                                      | { __typename: "ST4TreeNode"; id: string }
                                    >
                                    image?:
                                      | { __typename: "CommentNode"; id: string }
                                      | { __typename: "ExternalSite"; id: string }
                                      | { __typename: "ST4Class"; id: string }
                                      | {
                                          __typename: "ST4Node"
                                          aspectId: any
                                          id: string
                                          content?:
                                            | { __typename: "LexiconContent" }
                                            | { __typename: "LexiconEntryContent" }
                                            | {
                                                __typename: "MediaContent"
                                                media?:
                                                  | {
                                                      __typename: "AnimationMediaInfo"
                                                      thumbnail?: string | null
                                                      mediaType?: Types.MediaType | null
                                                    }
                                                  | {
                                                      __typename: "ImageMediaInfo"
                                                      src?: string | null
                                                      thumbnail?: string | null
                                                      mediaType?: Types.MediaType | null
                                                    }
                                                  | { __typename: "NoMediaInfo" }
                                                  | {
                                                      __typename: "VectorGraphicsMediaInfo"
                                                      svg?: string | null
                                                      thumbnail?: string | null
                                                      mediaType?: Types.MediaType | null
                                                    }
                                                  | {
                                                      __typename: "VideoMediaInfo"
                                                      thumbnail?: string | null
                                                      mediaType?: Types.MediaType | null
                                                    }
                                                  | null
                                              }
                                            | { __typename: "MediaGroupContent" }
                                            | { __typename: "ProjectContent" }
                                            | { __typename: "TermContent" }
                                            | { __typename: "TextContent" }
                                            | { __typename: "TextGroupContent" }
                                            | { __typename: "VariableTableContent" }
                                            | null
                                        }
                                      | { __typename: "ST4TreeNode"; id: string }
                                      | null
                                  }
                                | { __typename: "MediaContent" }
                                | { __typename: "MediaGroupContent" }
                                | { __typename: "ProjectContent" }
                                | { __typename: "TermContent" }
                                | { __typename: "TextContent" }
                                | { __typename: "TextGroupContent" }
                                | { __typename: "VariableTableContent" }
                                | null
                            }
                          | { __typename: "ST4TreeNode"; id: string }
                          | null
                      } | null> | null
                      comments: Array<{
                        __typename: "CommentNode"
                        id: string
                        comment:
                          | {
                              __typename: "BlockComment"
                              location: number
                              value?: string | null
                              authorDisplayName?: string | null
                              authorUserName?: string | null
                              commentKey: string
                              editDate?: any | null
                              modificationVersion?: number | null
                              replyToKey?: string | null
                              sortOrder?: number | null
                              commentType?: string | null
                              state?: string | null
                              color: string
                              persistanceState?: Types.PersistanceState | null
                              persistanceAction?: Types.PersistanceAction | null
                              node: {
                                __typename: "ST4Node"
                                aspectId: any
                                id: string
                                content?:
                                  | { __typename: "LexiconContent" }
                                  | { __typename: "LexiconEntryContent" }
                                  | { __typename: "MediaContent" }
                                  | { __typename: "MediaGroupContent" }
                                  | { __typename: "ProjectContent" }
                                  | { __typename: "TermContent" }
                                  | { __typename: "TextContent"; id: string; historyNumber: number }
                                  | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                                  | { __typename: "VariableTableContent" }
                                  | null
                              }
                              actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
                            }
                          | {
                              __typename: "TextComment"
                              endLocation: number
                              startLocation?: number | null
                              value?: string | null
                              authorDisplayName?: string | null
                              authorUserName?: string | null
                              commentKey: string
                              editDate?: any | null
                              modificationVersion?: number | null
                              replyToKey?: string | null
                              sortOrder?: number | null
                              commentType?: string | null
                              state?: string | null
                              color: string
                              persistanceState?: Types.PersistanceState | null
                              persistanceAction?: Types.PersistanceAction | null
                              node: {
                                __typename: "ST4Node"
                                aspectId: any
                                id: string
                                content?:
                                  | { __typename: "LexiconContent" }
                                  | { __typename: "LexiconEntryContent" }
                                  | { __typename: "MediaContent" }
                                  | { __typename: "MediaGroupContent" }
                                  | { __typename: "ProjectContent" }
                                  | { __typename: "TermContent" }
                                  | { __typename: "TextContent"; id: string; historyNumber: number }
                                  | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                                  | { __typename: "VariableTableContent" }
                                  | null
                              }
                              actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
                            }
                      }>
                    }
                  | { __typename: "VariableTableContent" }
                  | null
              }
            }>
            children: Array<{
              __typename: "NodeRef"
              id: string
              target?:
                | { __typename: "CommentNode"; id: string }
                | { __typename: "ExternalSite"; id: string }
                | { __typename: "ST4Class"; id: string }
                | { __typename: "ST4Node"; label?: string | null; aspectId: any; id: string }
                | { __typename: "ST4TreeNode"; id: string }
                | null
            }>
            links?: Array<{
              __typename: "TextContentLink"
              ref?: string | null
              node?:
                | { __typename: "CommentNode"; id: string }
                | { __typename: "ExternalSite"; id: string }
                | { __typename: "ST4Class"; id: string }
                | {
                    __typename: "ST4Node"
                    id: string
                    aspectId: any
                    label?: string | null
                    properties?: {
                      __typename: "ContentPropertyConnection"
                      totalCount: number
                      edges?: Array<{
                        __typename: "ContentPropertyEdge"
                        node?: {
                          __typename: "ContentProperty"
                          typeOfValue: Types.ContentPropertyValueType
                          name: string
                          value?: any | null
                          displayName: string
                        } | null
                      } | null> | null
                    } | null
                  }
                | { __typename: "ST4TreeNode"; id: string }
                | null
            } | null> | null
            images: Array<{
              __typename: "NodeRef"
              id: string
              target?:
                | { __typename: "CommentNode"; id: string }
                | { __typename: "ExternalSite"; id: string }
                | { __typename: "ST4Class"; id: string }
                | {
                    __typename: "ST4Node"
                    aspectId: any
                    id: string
                    releaseState: {
                      __typename: "ReleaseStateValue"
                      displayName: string
                      state: Types.ReleaseState
                      isImplicitlyReleased: boolean
                    }
                    content?:
                      | { __typename: "LexiconContent" }
                      | { __typename: "LexiconEntryContent" }
                      | {
                          __typename: "MediaContent"
                          media?:
                            | {
                                __typename: "AnimationMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                mediaType?: Types.MediaType | null
                              }
                            | {
                                __typename: "ImageMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                resolution?: number | null
                                colorDepth?: number | null
                                size?: number | null
                                mime?: string | null
                                extension?: string | null
                                allowCaching?: boolean | null
                                mediaType?: Types.MediaType | null
                                src?: string | null
                                language?: string | null
                              }
                            | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                            | {
                                __typename: "VectorGraphicsMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                allowCaching?: boolean | null
                                mediaType?: Types.MediaType | null
                                language?: string | null
                                labels: Array<{
                                  __typename: "VectorGraphicsLabel"
                                  id?: string | null
                                  sequence?: number | null
                                  autoValue?: string | null
                                  text?: string | null
                                  linkType?: Types.VectorGraphicsLabelLinkType | null
                                  link?: string | null
                                  ref?:
                                    | {
                                        __typename: "GenericRef"
                                        id: string
                                        owner?:
                                          | { __typename: "CommentNode" }
                                          | { __typename: "ExternalSite" }
                                          | { __typename: "ST4Class" }
                                          | { __typename: "ST4Node"; id: string }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                        target?:
                                          | { __typename: "CommentNode" }
                                          | {
                                              __typename: "ExternalSite"
                                              id: string
                                              teaserData?: {
                                                __typename: "ExternalLinkTeaserData"
                                                title?: string | null
                                                summary?: string | null
                                                iconUrl?: string | null
                                                imageUrl?: string | null
                                                status?: Types.LinkTargetStatus | null
                                              } | null
                                            }
                                          | { __typename: "ST4Class" }
                                          | {
                                              __typename: "ST4Node"
                                              id: string
                                              aspectId: any
                                              label?: string | null
                                              properties?: {
                                                __typename: "ContentPropertyConnection"
                                                edges?: Array<{
                                                  __typename: "ContentPropertyEdge"
                                                  node?: {
                                                    __typename: "ContentProperty"
                                                    name: string
                                                    value?: any | null
                                                    displayName: string
                                                  } | null
                                                } | null> | null
                                              } | null
                                            }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                      }
                                    | {
                                        __typename: "NodeRef"
                                        id: string
                                        owner?:
                                          | { __typename: "CommentNode" }
                                          | { __typename: "ExternalSite" }
                                          | { __typename: "ST4Class" }
                                          | { __typename: "ST4Node"; id: string }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                        target?:
                                          | { __typename: "CommentNode" }
                                          | {
                                              __typename: "ExternalSite"
                                              id: string
                                              teaserData?: {
                                                __typename: "ExternalLinkTeaserData"
                                                title?: string | null
                                                summary?: string | null
                                                iconUrl?: string | null
                                                imageUrl?: string | null
                                                status?: Types.LinkTargetStatus | null
                                              } | null
                                            }
                                          | { __typename: "ST4Class" }
                                          | {
                                              __typename: "ST4Node"
                                              id: string
                                              aspectId: any
                                              label?: string | null
                                              properties?: {
                                                __typename: "ContentPropertyConnection"
                                                edges?: Array<{
                                                  __typename: "ContentPropertyEdge"
                                                  node?: {
                                                    __typename: "ContentProperty"
                                                    name: string
                                                    value?: any | null
                                                    displayName: string
                                                  } | null
                                                } | null> | null
                                              } | null
                                            }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                      }
                                    | null
                                }>
                              }
                            | {
                                __typename: "VideoMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                mediaType?: Types.MediaType | null
                              }
                            | null
                        }
                      | {
                          __typename: "MediaGroupContent"
                          children: Array<{
                            __typename: "NodeRef"
                            id: string
                            target?:
                              | { __typename: "CommentNode"; id: string }
                              | { __typename: "ExternalSite"; id: string }
                              | { __typename: "ST4Class"; id: string }
                              | {
                                  __typename: "ST4Node"
                                  id: string
                                  aspectId: any
                                  content?:
                                    | { __typename: "LexiconContent" }
                                    | { __typename: "LexiconEntryContent" }
                                    | {
                                        __typename: "MediaContent"
                                        media?:
                                          | {
                                              __typename: "AnimationMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              mediaType?: Types.MediaType | null
                                            }
                                          | {
                                              __typename: "ImageMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              resolution?: number | null
                                              colorDepth?: number | null
                                              size?: number | null
                                              mime?: string | null
                                              extension?: string | null
                                              allowCaching?: boolean | null
                                              mediaType?: Types.MediaType | null
                                              src?: string | null
                                              language?: string | null
                                            }
                                          | {
                                              __typename: "NoMediaInfo"
                                              id: string
                                              mediaType?: Types.MediaType | null
                                            }
                                          | {
                                              __typename: "VectorGraphicsMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              allowCaching?: boolean | null
                                              mediaType?: Types.MediaType | null
                                              language?: string | null
                                              labels: Array<{
                                                __typename: "VectorGraphicsLabel"
                                                id?: string | null
                                                sequence?: number | null
                                                autoValue?: string | null
                                                text?: string | null
                                                linkType?: Types.VectorGraphicsLabelLinkType | null
                                                link?: string | null
                                                ref?:
                                                  | {
                                                      __typename: "GenericRef"
                                                      id: string
                                                      owner?:
                                                        | { __typename: "CommentNode" }
                                                        | { __typename: "ExternalSite" }
                                                        | { __typename: "ST4Class" }
                                                        | { __typename: "ST4Node"; id: string }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                      target?:
                                                        | { __typename: "CommentNode" }
                                                        | {
                                                            __typename: "ExternalSite"
                                                            id: string
                                                            teaserData?: {
                                                              __typename: "ExternalLinkTeaserData"
                                                              title?: string | null
                                                              summary?: string | null
                                                              iconUrl?: string | null
                                                              imageUrl?: string | null
                                                              status?: Types.LinkTargetStatus | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4Class" }
                                                        | {
                                                            __typename: "ST4Node"
                                                            id: string
                                                            aspectId: any
                                                            label?: string | null
                                                            properties?: {
                                                              __typename: "ContentPropertyConnection"
                                                              edges?: Array<{
                                                                __typename: "ContentPropertyEdge"
                                                                node?: {
                                                                  __typename: "ContentProperty"
                                                                  name: string
                                                                  value?: any | null
                                                                  displayName: string
                                                                } | null
                                                              } | null> | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                    }
                                                  | {
                                                      __typename: "NodeRef"
                                                      id: string
                                                      owner?:
                                                        | { __typename: "CommentNode" }
                                                        | { __typename: "ExternalSite" }
                                                        | { __typename: "ST4Class" }
                                                        | { __typename: "ST4Node"; id: string }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                      target?:
                                                        | { __typename: "CommentNode" }
                                                        | {
                                                            __typename: "ExternalSite"
                                                            id: string
                                                            teaserData?: {
                                                              __typename: "ExternalLinkTeaserData"
                                                              title?: string | null
                                                              summary?: string | null
                                                              iconUrl?: string | null
                                                              imageUrl?: string | null
                                                              status?: Types.LinkTargetStatus | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4Class" }
                                                        | {
                                                            __typename: "ST4Node"
                                                            id: string
                                                            aspectId: any
                                                            label?: string | null
                                                            properties?: {
                                                              __typename: "ContentPropertyConnection"
                                                              edges?: Array<{
                                                                __typename: "ContentPropertyEdge"
                                                                node?: {
                                                                  __typename: "ContentProperty"
                                                                  name: string
                                                                  value?: any | null
                                                                  displayName: string
                                                                } | null
                                                              } | null> | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                    }
                                                  | null
                                              }>
                                            }
                                          | {
                                              __typename: "VideoMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              mediaType?: Types.MediaType | null
                                            }
                                          | null
                                      }
                                    | {
                                        __typename: "MediaGroupContent"
                                        media?:
                                          | {
                                              __typename: "AnimationMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              mediaType?: Types.MediaType | null
                                            }
                                          | {
                                              __typename: "ImageMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              resolution?: number | null
                                              colorDepth?: number | null
                                              size?: number | null
                                              mime?: string | null
                                              extension?: string | null
                                              allowCaching?: boolean | null
                                              mediaType?: Types.MediaType | null
                                              src?: string | null
                                              language?: string | null
                                            }
                                          | {
                                              __typename: "NoMediaInfo"
                                              id: string
                                              mediaType?: Types.MediaType | null
                                            }
                                          | {
                                              __typename: "VectorGraphicsMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              allowCaching?: boolean | null
                                              mediaType?: Types.MediaType | null
                                              language?: string | null
                                              labels: Array<{
                                                __typename: "VectorGraphicsLabel"
                                                id?: string | null
                                                sequence?: number | null
                                                autoValue?: string | null
                                                text?: string | null
                                                linkType?: Types.VectorGraphicsLabelLinkType | null
                                                link?: string | null
                                                ref?:
                                                  | {
                                                      __typename: "GenericRef"
                                                      id: string
                                                      owner?:
                                                        | { __typename: "CommentNode" }
                                                        | { __typename: "ExternalSite" }
                                                        | { __typename: "ST4Class" }
                                                        | { __typename: "ST4Node"; id: string }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                      target?:
                                                        | { __typename: "CommentNode" }
                                                        | {
                                                            __typename: "ExternalSite"
                                                            id: string
                                                            teaserData?: {
                                                              __typename: "ExternalLinkTeaserData"
                                                              title?: string | null
                                                              summary?: string | null
                                                              iconUrl?: string | null
                                                              imageUrl?: string | null
                                                              status?: Types.LinkTargetStatus | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4Class" }
                                                        | {
                                                            __typename: "ST4Node"
                                                            id: string
                                                            aspectId: any
                                                            label?: string | null
                                                            properties?: {
                                                              __typename: "ContentPropertyConnection"
                                                              edges?: Array<{
                                                                __typename: "ContentPropertyEdge"
                                                                node?: {
                                                                  __typename: "ContentProperty"
                                                                  name: string
                                                                  value?: any | null
                                                                  displayName: string
                                                                } | null
                                                              } | null> | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                    }
                                                  | {
                                                      __typename: "NodeRef"
                                                      id: string
                                                      owner?:
                                                        | { __typename: "CommentNode" }
                                                        | { __typename: "ExternalSite" }
                                                        | { __typename: "ST4Class" }
                                                        | { __typename: "ST4Node"; id: string }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                      target?:
                                                        | { __typename: "CommentNode" }
                                                        | {
                                                            __typename: "ExternalSite"
                                                            id: string
                                                            teaserData?: {
                                                              __typename: "ExternalLinkTeaserData"
                                                              title?: string | null
                                                              summary?: string | null
                                                              iconUrl?: string | null
                                                              imageUrl?: string | null
                                                              status?: Types.LinkTargetStatus | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4Class" }
                                                        | {
                                                            __typename: "ST4Node"
                                                            id: string
                                                            aspectId: any
                                                            label?: string | null
                                                            properties?: {
                                                              __typename: "ContentPropertyConnection"
                                                              edges?: Array<{
                                                                __typename: "ContentPropertyEdge"
                                                                node?: {
                                                                  __typename: "ContentProperty"
                                                                  name: string
                                                                  value?: any | null
                                                                  displayName: string
                                                                } | null
                                                              } | null> | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                    }
                                                  | null
                                              }>
                                            }
                                          | {
                                              __typename: "VideoMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              mediaType?: Types.MediaType | null
                                            }
                                          | null
                                      }
                                    | { __typename: "ProjectContent" }
                                    | { __typename: "TermContent" }
                                    | { __typename: "TextContent" }
                                    | { __typename: "TextGroupContent" }
                                    | { __typename: "VariableTableContent" }
                                    | null
                                }
                              | { __typename: "ST4TreeNode"; id: string }
                              | null
                          }>
                          media?:
                            | {
                                __typename: "AnimationMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                mediaType?: Types.MediaType | null
                              }
                            | {
                                __typename: "ImageMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                resolution?: number | null
                                colorDepth?: number | null
                                size?: number | null
                                mime?: string | null
                                extension?: string | null
                                allowCaching?: boolean | null
                                mediaType?: Types.MediaType | null
                                src?: string | null
                                language?: string | null
                              }
                            | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                            | {
                                __typename: "VectorGraphicsMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                allowCaching?: boolean | null
                                mediaType?: Types.MediaType | null
                                language?: string | null
                                labels: Array<{
                                  __typename: "VectorGraphicsLabel"
                                  id?: string | null
                                  sequence?: number | null
                                  autoValue?: string | null
                                  text?: string | null
                                  linkType?: Types.VectorGraphicsLabelLinkType | null
                                  link?: string | null
                                  ref?:
                                    | {
                                        __typename: "GenericRef"
                                        id: string
                                        owner?:
                                          | { __typename: "CommentNode" }
                                          | { __typename: "ExternalSite" }
                                          | { __typename: "ST4Class" }
                                          | { __typename: "ST4Node"; id: string }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                        target?:
                                          | { __typename: "CommentNode" }
                                          | {
                                              __typename: "ExternalSite"
                                              id: string
                                              teaserData?: {
                                                __typename: "ExternalLinkTeaserData"
                                                title?: string | null
                                                summary?: string | null
                                                iconUrl?: string | null
                                                imageUrl?: string | null
                                                status?: Types.LinkTargetStatus | null
                                              } | null
                                            }
                                          | { __typename: "ST4Class" }
                                          | {
                                              __typename: "ST4Node"
                                              id: string
                                              aspectId: any
                                              label?: string | null
                                              properties?: {
                                                __typename: "ContentPropertyConnection"
                                                edges?: Array<{
                                                  __typename: "ContentPropertyEdge"
                                                  node?: {
                                                    __typename: "ContentProperty"
                                                    name: string
                                                    value?: any | null
                                                    displayName: string
                                                  } | null
                                                } | null> | null
                                              } | null
                                            }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                      }
                                    | {
                                        __typename: "NodeRef"
                                        id: string
                                        owner?:
                                          | { __typename: "CommentNode" }
                                          | { __typename: "ExternalSite" }
                                          | { __typename: "ST4Class" }
                                          | { __typename: "ST4Node"; id: string }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                        target?:
                                          | { __typename: "CommentNode" }
                                          | {
                                              __typename: "ExternalSite"
                                              id: string
                                              teaserData?: {
                                                __typename: "ExternalLinkTeaserData"
                                                title?: string | null
                                                summary?: string | null
                                                iconUrl?: string | null
                                                imageUrl?: string | null
                                                status?: Types.LinkTargetStatus | null
                                              } | null
                                            }
                                          | { __typename: "ST4Class" }
                                          | {
                                              __typename: "ST4Node"
                                              id: string
                                              aspectId: any
                                              label?: string | null
                                              properties?: {
                                                __typename: "ContentPropertyConnection"
                                                edges?: Array<{
                                                  __typename: "ContentPropertyEdge"
                                                  node?: {
                                                    __typename: "ContentProperty"
                                                    name: string
                                                    value?: any | null
                                                    displayName: string
                                                  } | null
                                                } | null> | null
                                              } | null
                                            }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                      }
                                    | null
                                }>
                              }
                            | {
                                __typename: "VideoMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                mediaType?: Types.MediaType | null
                              }
                            | null
                        }
                      | { __typename: "ProjectContent" }
                      | { __typename: "TermContent" }
                      | { __typename: "TextContent" }
                      | { __typename: "TextGroupContent" }
                      | { __typename: "VariableTableContent" }
                      | null
                  }
                | { __typename: "ST4TreeNode"; id: string }
                | null
            }>
            variableTables?: Array<{
              __typename: "VariableTableReference"
              ref?: string | null
              node?:
                | { __typename: "CommentNode" }
                | { __typename: "ExternalSite" }
                | { __typename: "ST4Class" }
                | {
                    __typename: "ST4Node"
                    id: string
                    label?: string | null
                    aspectId: any
                    content?:
                      | { __typename: "LexiconContent" }
                      | { __typename: "LexiconEntryContent" }
                      | { __typename: "MediaContent" }
                      | { __typename: "MediaGroupContent" }
                      | { __typename: "ProjectContent" }
                      | { __typename: "TermContent" }
                      | { __typename: "TextContent" }
                      | { __typename: "TextGroupContent" }
                      | {
                          __typename: "VariableTableContent"
                          xml?: string | null
                          valueSets: Array<{ __typename: "ValueSet"; name: string; valueSetId: string }>
                          variables: Array<{
                            __typename: "Variable"
                            name: string
                            variableId: string
                            values: Array<string>
                          }>
                        }
                      | null
                  }
                | { __typename: "ST4TreeNode" }
                | null
            } | null> | null
            linkedDataNodes: Array<{
              __typename: "NodeRef"
              id: string
              target?:
                | { __typename: "CommentNode"; id: string }
                | { __typename: "ExternalSite"; id: string }
                | { __typename: "ST4Class"; id: string }
                | {
                    __typename: "ST4Node"
                    label?: string | null
                    id: string
                    metadata: Array<{
                      __typename: "MetaDataEntry"
                      label?: string | null
                      systemName: string
                      value?: string | null
                      values: Array<string>
                      users: Array<string>
                    }>
                    linkedOntologyNodes: Array<
                      | {
                          __typename: "GenericRef"
                          target?:
                            | { __typename: "CommentNode" }
                            | { __typename: "ExternalSite" }
                            | { __typename: "ST4Class" }
                            | { __typename: "ST4Node"; id: string; label?: string | null }
                            | { __typename: "ST4TreeNode" }
                            | null
                        }
                      | {
                          __typename: "NodeRef"
                          linkClassName: string
                          class?: { __typename: "ST4Class"; label?: string | null } | null
                          target?:
                            | { __typename: "CommentNode" }
                            | { __typename: "ExternalSite" }
                            | { __typename: "ST4Class" }
                            | { __typename: "ST4Node"; id: string; label?: string | null }
                            | { __typename: "ST4TreeNode" }
                            | null
                        }
                    >
                  }
                | { __typename: "ST4TreeNode"; id: string }
                | null
            }>
            lexiconEntries?: Array<{
              __typename: "NodeRef"
              id: string
              target?:
                | { __typename: "CommentNode"; id: string }
                | { __typename: "ExternalSite"; id: string }
                | { __typename: "ST4Class"; id: string }
                | {
                    __typename: "ST4Node"
                    aspectId: any
                    label?: string | null
                    id: string
                    content?:
                      | { __typename: "LexiconContent" }
                      | {
                          __typename: "LexiconEntryContent"
                          descriptionXast?: string | null
                          subject?: string | null
                          sortKey?: string | null
                          lexicon:
                            | { __typename: "CommentNode"; id: string }
                            | { __typename: "ExternalSite"; id: string }
                            | { __typename: "ST4Class"; id: string }
                            | { __typename: "ST4Node"; label?: string | null; id: string }
                            | { __typename: "ST4TreeNode"; id: string }
                          terms: Array<
                            | { __typename: "CommentNode"; id: string }
                            | { __typename: "ExternalSite"; id: string }
                            | { __typename: "ST4Class"; id: string }
                            | {
                                __typename: "ST4Node"
                                id: string
                                label?: string | null
                                aspectId: any
                                content?:
                                  | { __typename: "LexiconContent" }
                                  | { __typename: "LexiconEntryContent" }
                                  | { __typename: "MediaContent" }
                                  | { __typename: "MediaGroupContent" }
                                  | { __typename: "ProjectContent" }
                                  | {
                                      __typename: "TermContent"
                                      gramaticalGender?: string | null
                                      validity?: string | null
                                      editLanguage?: string | null
                                    }
                                  | { __typename: "TextContent" }
                                  | { __typename: "TextGroupContent" }
                                  | { __typename: "VariableTableContent" }
                                  | null
                              }
                            | { __typename: "ST4TreeNode"; id: string }
                          >
                          image?:
                            | { __typename: "CommentNode"; id: string }
                            | { __typename: "ExternalSite"; id: string }
                            | { __typename: "ST4Class"; id: string }
                            | {
                                __typename: "ST4Node"
                                aspectId: any
                                id: string
                                content?:
                                  | { __typename: "LexiconContent" }
                                  | { __typename: "LexiconEntryContent" }
                                  | {
                                      __typename: "MediaContent"
                                      media?:
                                        | {
                                            __typename: "AnimationMediaInfo"
                                            thumbnail?: string | null
                                            mediaType?: Types.MediaType | null
                                          }
                                        | {
                                            __typename: "ImageMediaInfo"
                                            src?: string | null
                                            thumbnail?: string | null
                                            mediaType?: Types.MediaType | null
                                          }
                                        | { __typename: "NoMediaInfo" }
                                        | {
                                            __typename: "VectorGraphicsMediaInfo"
                                            svg?: string | null
                                            thumbnail?: string | null
                                            mediaType?: Types.MediaType | null
                                          }
                                        | {
                                            __typename: "VideoMediaInfo"
                                            thumbnail?: string | null
                                            mediaType?: Types.MediaType | null
                                          }
                                        | null
                                    }
                                  | { __typename: "MediaGroupContent" }
                                  | { __typename: "ProjectContent" }
                                  | { __typename: "TermContent" }
                                  | { __typename: "TextContent" }
                                  | { __typename: "TextGroupContent" }
                                  | { __typename: "VariableTableContent" }
                                  | null
                              }
                            | { __typename: "ST4TreeNode"; id: string }
                            | null
                        }
                      | { __typename: "MediaContent" }
                      | { __typename: "MediaGroupContent" }
                      | { __typename: "ProjectContent" }
                      | { __typename: "TermContent" }
                      | { __typename: "TextContent" }
                      | { __typename: "TextGroupContent" }
                      | { __typename: "VariableTableContent" }
                      | null
                  }
                | { __typename: "ST4TreeNode"; id: string }
                | null
            } | null> | null
            comments: Array<{
              __typename: "CommentNode"
              id: string
              comment:
                | {
                    __typename: "BlockComment"
                    location: number
                    value?: string | null
                    authorDisplayName?: string | null
                    authorUserName?: string | null
                    commentKey: string
                    editDate?: any | null
                    modificationVersion?: number | null
                    replyToKey?: string | null
                    sortOrder?: number | null
                    commentType?: string | null
                    state?: string | null
                    color: string
                    persistanceState?: Types.PersistanceState | null
                    persistanceAction?: Types.PersistanceAction | null
                    node: {
                      __typename: "ST4Node"
                      aspectId: any
                      id: string
                      content?:
                        | { __typename: "LexiconContent" }
                        | { __typename: "LexiconEntryContent" }
                        | { __typename: "MediaContent" }
                        | { __typename: "MediaGroupContent" }
                        | { __typename: "ProjectContent" }
                        | { __typename: "TermContent" }
                        | { __typename: "TextContent"; id: string; historyNumber: number }
                        | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                        | { __typename: "VariableTableContent" }
                        | null
                    }
                    actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
                  }
                | {
                    __typename: "TextComment"
                    endLocation: number
                    startLocation?: number | null
                    value?: string | null
                    authorDisplayName?: string | null
                    authorUserName?: string | null
                    commentKey: string
                    editDate?: any | null
                    modificationVersion?: number | null
                    replyToKey?: string | null
                    sortOrder?: number | null
                    commentType?: string | null
                    state?: string | null
                    color: string
                    persistanceState?: Types.PersistanceState | null
                    persistanceAction?: Types.PersistanceAction | null
                    node: {
                      __typename: "ST4Node"
                      aspectId: any
                      id: string
                      content?:
                        | { __typename: "LexiconContent" }
                        | { __typename: "LexiconEntryContent" }
                        | { __typename: "MediaContent" }
                        | { __typename: "MediaGroupContent" }
                        | { __typename: "ProjectContent" }
                        | { __typename: "TermContent" }
                        | { __typename: "TextContent"; id: string; historyNumber: number }
                        | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                        | { __typename: "VariableTableContent" }
                        | null
                    }
                    actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
                  }
            }>
          }
        | { __typename: "VariableTableContent" }
        | null
    }
  }>
  parts: Array<{
    __typename: "TextContentPart"
    ref: string
    contentNode: {
      __typename: "ST4Node"
      id: string
      aspectId: any
      content?:
        | { __typename: "LexiconContent" }
        | { __typename: "LexiconEntryContent" }
        | { __typename: "MediaContent" }
        | { __typename: "MediaGroupContent" }
        | { __typename: "ProjectContent" }
        | { __typename: "TermContent" }
        | {
            __typename: "TextContent"
            id: string
            xast?: string | null
            historyNumber: number
            links?: Array<{
              __typename: "TextContentLink"
              ref?: string | null
              node?:
                | { __typename: "CommentNode"; id: string }
                | { __typename: "ExternalSite"; id: string }
                | { __typename: "ST4Class"; id: string }
                | {
                    __typename: "ST4Node"
                    id: string
                    aspectId: any
                    label?: string | null
                    properties?: {
                      __typename: "ContentPropertyConnection"
                      totalCount: number
                      edges?: Array<{
                        __typename: "ContentPropertyEdge"
                        node?: {
                          __typename: "ContentProperty"
                          typeOfValue: Types.ContentPropertyValueType
                          name: string
                          value?: any | null
                          displayName: string
                        } | null
                      } | null> | null
                    } | null
                  }
                | { __typename: "ST4TreeNode"; id: string }
                | null
            } | null> | null
            images: Array<{
              __typename: "NodeRef"
              id: string
              target?:
                | { __typename: "CommentNode"; id: string }
                | { __typename: "ExternalSite"; id: string }
                | { __typename: "ST4Class"; id: string }
                | {
                    __typename: "ST4Node"
                    aspectId: any
                    id: string
                    releaseState: {
                      __typename: "ReleaseStateValue"
                      displayName: string
                      state: Types.ReleaseState
                      isImplicitlyReleased: boolean
                    }
                    content?:
                      | { __typename: "LexiconContent" }
                      | { __typename: "LexiconEntryContent" }
                      | {
                          __typename: "MediaContent"
                          media?:
                            | {
                                __typename: "AnimationMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                mediaType?: Types.MediaType | null
                              }
                            | {
                                __typename: "ImageMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                resolution?: number | null
                                colorDepth?: number | null
                                size?: number | null
                                mime?: string | null
                                extension?: string | null
                                allowCaching?: boolean | null
                                mediaType?: Types.MediaType | null
                                src?: string | null
                                language?: string | null
                              }
                            | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                            | {
                                __typename: "VectorGraphicsMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                allowCaching?: boolean | null
                                mediaType?: Types.MediaType | null
                                language?: string | null
                                labels: Array<{
                                  __typename: "VectorGraphicsLabel"
                                  id?: string | null
                                  sequence?: number | null
                                  autoValue?: string | null
                                  text?: string | null
                                  linkType?: Types.VectorGraphicsLabelLinkType | null
                                  link?: string | null
                                  ref?:
                                    | {
                                        __typename: "GenericRef"
                                        id: string
                                        owner?:
                                          | { __typename: "CommentNode" }
                                          | { __typename: "ExternalSite" }
                                          | { __typename: "ST4Class" }
                                          | { __typename: "ST4Node"; id: string }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                        target?:
                                          | { __typename: "CommentNode" }
                                          | {
                                              __typename: "ExternalSite"
                                              id: string
                                              teaserData?: {
                                                __typename: "ExternalLinkTeaserData"
                                                title?: string | null
                                                summary?: string | null
                                                iconUrl?: string | null
                                                imageUrl?: string | null
                                                status?: Types.LinkTargetStatus | null
                                              } | null
                                            }
                                          | { __typename: "ST4Class" }
                                          | {
                                              __typename: "ST4Node"
                                              id: string
                                              aspectId: any
                                              label?: string | null
                                              properties?: {
                                                __typename: "ContentPropertyConnection"
                                                edges?: Array<{
                                                  __typename: "ContentPropertyEdge"
                                                  node?: {
                                                    __typename: "ContentProperty"
                                                    name: string
                                                    value?: any | null
                                                    displayName: string
                                                  } | null
                                                } | null> | null
                                              } | null
                                            }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                      }
                                    | {
                                        __typename: "NodeRef"
                                        id: string
                                        owner?:
                                          | { __typename: "CommentNode" }
                                          | { __typename: "ExternalSite" }
                                          | { __typename: "ST4Class" }
                                          | { __typename: "ST4Node"; id: string }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                        target?:
                                          | { __typename: "CommentNode" }
                                          | {
                                              __typename: "ExternalSite"
                                              id: string
                                              teaserData?: {
                                                __typename: "ExternalLinkTeaserData"
                                                title?: string | null
                                                summary?: string | null
                                                iconUrl?: string | null
                                                imageUrl?: string | null
                                                status?: Types.LinkTargetStatus | null
                                              } | null
                                            }
                                          | { __typename: "ST4Class" }
                                          | {
                                              __typename: "ST4Node"
                                              id: string
                                              aspectId: any
                                              label?: string | null
                                              properties?: {
                                                __typename: "ContentPropertyConnection"
                                                edges?: Array<{
                                                  __typename: "ContentPropertyEdge"
                                                  node?: {
                                                    __typename: "ContentProperty"
                                                    name: string
                                                    value?: any | null
                                                    displayName: string
                                                  } | null
                                                } | null> | null
                                              } | null
                                            }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                      }
                                    | null
                                }>
                              }
                            | {
                                __typename: "VideoMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                mediaType?: Types.MediaType | null
                              }
                            | null
                        }
                      | {
                          __typename: "MediaGroupContent"
                          children: Array<{
                            __typename: "NodeRef"
                            id: string
                            target?:
                              | { __typename: "CommentNode"; id: string }
                              | { __typename: "ExternalSite"; id: string }
                              | { __typename: "ST4Class"; id: string }
                              | {
                                  __typename: "ST4Node"
                                  id: string
                                  aspectId: any
                                  content?:
                                    | { __typename: "LexiconContent" }
                                    | { __typename: "LexiconEntryContent" }
                                    | {
                                        __typename: "MediaContent"
                                        media?:
                                          | {
                                              __typename: "AnimationMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              mediaType?: Types.MediaType | null
                                            }
                                          | {
                                              __typename: "ImageMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              resolution?: number | null
                                              colorDepth?: number | null
                                              size?: number | null
                                              mime?: string | null
                                              extension?: string | null
                                              allowCaching?: boolean | null
                                              mediaType?: Types.MediaType | null
                                              src?: string | null
                                              language?: string | null
                                            }
                                          | {
                                              __typename: "NoMediaInfo"
                                              id: string
                                              mediaType?: Types.MediaType | null
                                            }
                                          | {
                                              __typename: "VectorGraphicsMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              allowCaching?: boolean | null
                                              mediaType?: Types.MediaType | null
                                              language?: string | null
                                              labels: Array<{
                                                __typename: "VectorGraphicsLabel"
                                                id?: string | null
                                                sequence?: number | null
                                                autoValue?: string | null
                                                text?: string | null
                                                linkType?: Types.VectorGraphicsLabelLinkType | null
                                                link?: string | null
                                                ref?:
                                                  | {
                                                      __typename: "GenericRef"
                                                      id: string
                                                      owner?:
                                                        | { __typename: "CommentNode" }
                                                        | { __typename: "ExternalSite" }
                                                        | { __typename: "ST4Class" }
                                                        | { __typename: "ST4Node"; id: string }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                      target?:
                                                        | { __typename: "CommentNode" }
                                                        | {
                                                            __typename: "ExternalSite"
                                                            id: string
                                                            teaserData?: {
                                                              __typename: "ExternalLinkTeaserData"
                                                              title?: string | null
                                                              summary?: string | null
                                                              iconUrl?: string | null
                                                              imageUrl?: string | null
                                                              status?: Types.LinkTargetStatus | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4Class" }
                                                        | {
                                                            __typename: "ST4Node"
                                                            id: string
                                                            aspectId: any
                                                            label?: string | null
                                                            properties?: {
                                                              __typename: "ContentPropertyConnection"
                                                              edges?: Array<{
                                                                __typename: "ContentPropertyEdge"
                                                                node?: {
                                                                  __typename: "ContentProperty"
                                                                  name: string
                                                                  value?: any | null
                                                                  displayName: string
                                                                } | null
                                                              } | null> | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                    }
                                                  | {
                                                      __typename: "NodeRef"
                                                      id: string
                                                      owner?:
                                                        | { __typename: "CommentNode" }
                                                        | { __typename: "ExternalSite" }
                                                        | { __typename: "ST4Class" }
                                                        | { __typename: "ST4Node"; id: string }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                      target?:
                                                        | { __typename: "CommentNode" }
                                                        | {
                                                            __typename: "ExternalSite"
                                                            id: string
                                                            teaserData?: {
                                                              __typename: "ExternalLinkTeaserData"
                                                              title?: string | null
                                                              summary?: string | null
                                                              iconUrl?: string | null
                                                              imageUrl?: string | null
                                                              status?: Types.LinkTargetStatus | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4Class" }
                                                        | {
                                                            __typename: "ST4Node"
                                                            id: string
                                                            aspectId: any
                                                            label?: string | null
                                                            properties?: {
                                                              __typename: "ContentPropertyConnection"
                                                              edges?: Array<{
                                                                __typename: "ContentPropertyEdge"
                                                                node?: {
                                                                  __typename: "ContentProperty"
                                                                  name: string
                                                                  value?: any | null
                                                                  displayName: string
                                                                } | null
                                                              } | null> | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                    }
                                                  | null
                                              }>
                                            }
                                          | {
                                              __typename: "VideoMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              mediaType?: Types.MediaType | null
                                            }
                                          | null
                                      }
                                    | {
                                        __typename: "MediaGroupContent"
                                        media?:
                                          | {
                                              __typename: "AnimationMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              mediaType?: Types.MediaType | null
                                            }
                                          | {
                                              __typename: "ImageMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              resolution?: number | null
                                              colorDepth?: number | null
                                              size?: number | null
                                              mime?: string | null
                                              extension?: string | null
                                              allowCaching?: boolean | null
                                              mediaType?: Types.MediaType | null
                                              src?: string | null
                                              language?: string | null
                                            }
                                          | {
                                              __typename: "NoMediaInfo"
                                              id: string
                                              mediaType?: Types.MediaType | null
                                            }
                                          | {
                                              __typename: "VectorGraphicsMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              allowCaching?: boolean | null
                                              mediaType?: Types.MediaType | null
                                              language?: string | null
                                              labels: Array<{
                                                __typename: "VectorGraphicsLabel"
                                                id?: string | null
                                                sequence?: number | null
                                                autoValue?: string | null
                                                text?: string | null
                                                linkType?: Types.VectorGraphicsLabelLinkType | null
                                                link?: string | null
                                                ref?:
                                                  | {
                                                      __typename: "GenericRef"
                                                      id: string
                                                      owner?:
                                                        | { __typename: "CommentNode" }
                                                        | { __typename: "ExternalSite" }
                                                        | { __typename: "ST4Class" }
                                                        | { __typename: "ST4Node"; id: string }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                      target?:
                                                        | { __typename: "CommentNode" }
                                                        | {
                                                            __typename: "ExternalSite"
                                                            id: string
                                                            teaserData?: {
                                                              __typename: "ExternalLinkTeaserData"
                                                              title?: string | null
                                                              summary?: string | null
                                                              iconUrl?: string | null
                                                              imageUrl?: string | null
                                                              status?: Types.LinkTargetStatus | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4Class" }
                                                        | {
                                                            __typename: "ST4Node"
                                                            id: string
                                                            aspectId: any
                                                            label?: string | null
                                                            properties?: {
                                                              __typename: "ContentPropertyConnection"
                                                              edges?: Array<{
                                                                __typename: "ContentPropertyEdge"
                                                                node?: {
                                                                  __typename: "ContentProperty"
                                                                  name: string
                                                                  value?: any | null
                                                                  displayName: string
                                                                } | null
                                                              } | null> | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                    }
                                                  | {
                                                      __typename: "NodeRef"
                                                      id: string
                                                      owner?:
                                                        | { __typename: "CommentNode" }
                                                        | { __typename: "ExternalSite" }
                                                        | { __typename: "ST4Class" }
                                                        | { __typename: "ST4Node"; id: string }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                      target?:
                                                        | { __typename: "CommentNode" }
                                                        | {
                                                            __typename: "ExternalSite"
                                                            id: string
                                                            teaserData?: {
                                                              __typename: "ExternalLinkTeaserData"
                                                              title?: string | null
                                                              summary?: string | null
                                                              iconUrl?: string | null
                                                              imageUrl?: string | null
                                                              status?: Types.LinkTargetStatus | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4Class" }
                                                        | {
                                                            __typename: "ST4Node"
                                                            id: string
                                                            aspectId: any
                                                            label?: string | null
                                                            properties?: {
                                                              __typename: "ContentPropertyConnection"
                                                              edges?: Array<{
                                                                __typename: "ContentPropertyEdge"
                                                                node?: {
                                                                  __typename: "ContentProperty"
                                                                  name: string
                                                                  value?: any | null
                                                                  displayName: string
                                                                } | null
                                                              } | null> | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                    }
                                                  | null
                                              }>
                                            }
                                          | {
                                              __typename: "VideoMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              mediaType?: Types.MediaType | null
                                            }
                                          | null
                                      }
                                    | { __typename: "ProjectContent" }
                                    | { __typename: "TermContent" }
                                    | { __typename: "TextContent" }
                                    | { __typename: "TextGroupContent" }
                                    | { __typename: "VariableTableContent" }
                                    | null
                                }
                              | { __typename: "ST4TreeNode"; id: string }
                              | null
                          }>
                          media?:
                            | {
                                __typename: "AnimationMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                mediaType?: Types.MediaType | null
                              }
                            | {
                                __typename: "ImageMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                resolution?: number | null
                                colorDepth?: number | null
                                size?: number | null
                                mime?: string | null
                                extension?: string | null
                                allowCaching?: boolean | null
                                mediaType?: Types.MediaType | null
                                src?: string | null
                                language?: string | null
                              }
                            | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                            | {
                                __typename: "VectorGraphicsMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                allowCaching?: boolean | null
                                mediaType?: Types.MediaType | null
                                language?: string | null
                                labels: Array<{
                                  __typename: "VectorGraphicsLabel"
                                  id?: string | null
                                  sequence?: number | null
                                  autoValue?: string | null
                                  text?: string | null
                                  linkType?: Types.VectorGraphicsLabelLinkType | null
                                  link?: string | null
                                  ref?:
                                    | {
                                        __typename: "GenericRef"
                                        id: string
                                        owner?:
                                          | { __typename: "CommentNode" }
                                          | { __typename: "ExternalSite" }
                                          | { __typename: "ST4Class" }
                                          | { __typename: "ST4Node"; id: string }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                        target?:
                                          | { __typename: "CommentNode" }
                                          | {
                                              __typename: "ExternalSite"
                                              id: string
                                              teaserData?: {
                                                __typename: "ExternalLinkTeaserData"
                                                title?: string | null
                                                summary?: string | null
                                                iconUrl?: string | null
                                                imageUrl?: string | null
                                                status?: Types.LinkTargetStatus | null
                                              } | null
                                            }
                                          | { __typename: "ST4Class" }
                                          | {
                                              __typename: "ST4Node"
                                              id: string
                                              aspectId: any
                                              label?: string | null
                                              properties?: {
                                                __typename: "ContentPropertyConnection"
                                                edges?: Array<{
                                                  __typename: "ContentPropertyEdge"
                                                  node?: {
                                                    __typename: "ContentProperty"
                                                    name: string
                                                    value?: any | null
                                                    displayName: string
                                                  } | null
                                                } | null> | null
                                              } | null
                                            }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                      }
                                    | {
                                        __typename: "NodeRef"
                                        id: string
                                        owner?:
                                          | { __typename: "CommentNode" }
                                          | { __typename: "ExternalSite" }
                                          | { __typename: "ST4Class" }
                                          | { __typename: "ST4Node"; id: string }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                        target?:
                                          | { __typename: "CommentNode" }
                                          | {
                                              __typename: "ExternalSite"
                                              id: string
                                              teaserData?: {
                                                __typename: "ExternalLinkTeaserData"
                                                title?: string | null
                                                summary?: string | null
                                                iconUrl?: string | null
                                                imageUrl?: string | null
                                                status?: Types.LinkTargetStatus | null
                                              } | null
                                            }
                                          | { __typename: "ST4Class" }
                                          | {
                                              __typename: "ST4Node"
                                              id: string
                                              aspectId: any
                                              label?: string | null
                                              properties?: {
                                                __typename: "ContentPropertyConnection"
                                                edges?: Array<{
                                                  __typename: "ContentPropertyEdge"
                                                  node?: {
                                                    __typename: "ContentProperty"
                                                    name: string
                                                    value?: any | null
                                                    displayName: string
                                                  } | null
                                                } | null> | null
                                              } | null
                                            }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                      }
                                    | null
                                }>
                              }
                            | {
                                __typename: "VideoMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                mediaType?: Types.MediaType | null
                              }
                            | null
                        }
                      | { __typename: "ProjectContent" }
                      | { __typename: "TermContent" }
                      | { __typename: "TextContent" }
                      | { __typename: "TextGroupContent" }
                      | { __typename: "VariableTableContent" }
                      | null
                  }
                | { __typename: "ST4TreeNode"; id: string }
                | null
            }>
            variableTables?: Array<{
              __typename: "VariableTableReference"
              ref?: string | null
              node?:
                | { __typename: "CommentNode" }
                | { __typename: "ExternalSite" }
                | { __typename: "ST4Class" }
                | {
                    __typename: "ST4Node"
                    id: string
                    label?: string | null
                    aspectId: any
                    content?:
                      | { __typename: "LexiconContent" }
                      | { __typename: "LexiconEntryContent" }
                      | { __typename: "MediaContent" }
                      | { __typename: "MediaGroupContent" }
                      | { __typename: "ProjectContent" }
                      | { __typename: "TermContent" }
                      | { __typename: "TextContent" }
                      | { __typename: "TextGroupContent" }
                      | {
                          __typename: "VariableTableContent"
                          xml?: string | null
                          valueSets: Array<{ __typename: "ValueSet"; name: string; valueSetId: string }>
                          variables: Array<{
                            __typename: "Variable"
                            name: string
                            variableId: string
                            values: Array<string>
                          }>
                        }
                      | null
                  }
                | { __typename: "ST4TreeNode" }
                | null
            } | null> | null
            linkedDataNodes: Array<{
              __typename: "NodeRef"
              id: string
              target?:
                | { __typename: "CommentNode"; id: string }
                | { __typename: "ExternalSite"; id: string }
                | { __typename: "ST4Class"; id: string }
                | {
                    __typename: "ST4Node"
                    label?: string | null
                    id: string
                    metadata: Array<{
                      __typename: "MetaDataEntry"
                      label?: string | null
                      systemName: string
                      value?: string | null
                      values: Array<string>
                      users: Array<string>
                    }>
                    linkedOntologyNodes: Array<
                      | {
                          __typename: "GenericRef"
                          target?:
                            | { __typename: "CommentNode" }
                            | { __typename: "ExternalSite" }
                            | { __typename: "ST4Class" }
                            | { __typename: "ST4Node"; id: string; label?: string | null }
                            | { __typename: "ST4TreeNode" }
                            | null
                        }
                      | {
                          __typename: "NodeRef"
                          linkClassName: string
                          class?: { __typename: "ST4Class"; label?: string | null } | null
                          target?:
                            | { __typename: "CommentNode" }
                            | { __typename: "ExternalSite" }
                            | { __typename: "ST4Class" }
                            | { __typename: "ST4Node"; id: string; label?: string | null }
                            | { __typename: "ST4TreeNode" }
                            | null
                        }
                    >
                  }
                | { __typename: "ST4TreeNode"; id: string }
                | null
            }>
            lexiconEntries?: Array<{
              __typename: "NodeRef"
              id: string
              target?:
                | { __typename: "CommentNode"; id: string }
                | { __typename: "ExternalSite"; id: string }
                | { __typename: "ST4Class"; id: string }
                | {
                    __typename: "ST4Node"
                    aspectId: any
                    label?: string | null
                    id: string
                    content?:
                      | { __typename: "LexiconContent" }
                      | {
                          __typename: "LexiconEntryContent"
                          descriptionXast?: string | null
                          subject?: string | null
                          sortKey?: string | null
                          lexicon:
                            | { __typename: "CommentNode"; id: string }
                            | { __typename: "ExternalSite"; id: string }
                            | { __typename: "ST4Class"; id: string }
                            | { __typename: "ST4Node"; label?: string | null; id: string }
                            | { __typename: "ST4TreeNode"; id: string }
                          terms: Array<
                            | { __typename: "CommentNode"; id: string }
                            | { __typename: "ExternalSite"; id: string }
                            | { __typename: "ST4Class"; id: string }
                            | {
                                __typename: "ST4Node"
                                id: string
                                label?: string | null
                                aspectId: any
                                content?:
                                  | { __typename: "LexiconContent" }
                                  | { __typename: "LexiconEntryContent" }
                                  | { __typename: "MediaContent" }
                                  | { __typename: "MediaGroupContent" }
                                  | { __typename: "ProjectContent" }
                                  | {
                                      __typename: "TermContent"
                                      gramaticalGender?: string | null
                                      validity?: string | null
                                      editLanguage?: string | null
                                    }
                                  | { __typename: "TextContent" }
                                  | { __typename: "TextGroupContent" }
                                  | { __typename: "VariableTableContent" }
                                  | null
                              }
                            | { __typename: "ST4TreeNode"; id: string }
                          >
                          image?:
                            | { __typename: "CommentNode"; id: string }
                            | { __typename: "ExternalSite"; id: string }
                            | { __typename: "ST4Class"; id: string }
                            | {
                                __typename: "ST4Node"
                                aspectId: any
                                id: string
                                content?:
                                  | { __typename: "LexiconContent" }
                                  | { __typename: "LexiconEntryContent" }
                                  | {
                                      __typename: "MediaContent"
                                      media?:
                                        | {
                                            __typename: "AnimationMediaInfo"
                                            thumbnail?: string | null
                                            mediaType?: Types.MediaType | null
                                          }
                                        | {
                                            __typename: "ImageMediaInfo"
                                            src?: string | null
                                            thumbnail?: string | null
                                            mediaType?: Types.MediaType | null
                                          }
                                        | { __typename: "NoMediaInfo" }
                                        | {
                                            __typename: "VectorGraphicsMediaInfo"
                                            svg?: string | null
                                            thumbnail?: string | null
                                            mediaType?: Types.MediaType | null
                                          }
                                        | {
                                            __typename: "VideoMediaInfo"
                                            thumbnail?: string | null
                                            mediaType?: Types.MediaType | null
                                          }
                                        | null
                                    }
                                  | { __typename: "MediaGroupContent" }
                                  | { __typename: "ProjectContent" }
                                  | { __typename: "TermContent" }
                                  | { __typename: "TextContent" }
                                  | { __typename: "TextGroupContent" }
                                  | { __typename: "VariableTableContent" }
                                  | null
                              }
                            | { __typename: "ST4TreeNode"; id: string }
                            | null
                        }
                      | { __typename: "MediaContent" }
                      | { __typename: "MediaGroupContent" }
                      | { __typename: "ProjectContent" }
                      | { __typename: "TermContent" }
                      | { __typename: "TextContent" }
                      | { __typename: "TextGroupContent" }
                      | { __typename: "VariableTableContent" }
                      | null
                  }
                | { __typename: "ST4TreeNode"; id: string }
                | null
            } | null> | null
            comments: Array<{
              __typename: "CommentNode"
              id: string
              comment:
                | {
                    __typename: "BlockComment"
                    location: number
                    value?: string | null
                    authorDisplayName?: string | null
                    authorUserName?: string | null
                    commentKey: string
                    editDate?: any | null
                    modificationVersion?: number | null
                    replyToKey?: string | null
                    sortOrder?: number | null
                    commentType?: string | null
                    state?: string | null
                    color: string
                    persistanceState?: Types.PersistanceState | null
                    persistanceAction?: Types.PersistanceAction | null
                    node: {
                      __typename: "ST4Node"
                      aspectId: any
                      id: string
                      content?:
                        | { __typename: "LexiconContent" }
                        | { __typename: "LexiconEntryContent" }
                        | { __typename: "MediaContent" }
                        | { __typename: "MediaGroupContent" }
                        | { __typename: "ProjectContent" }
                        | { __typename: "TermContent" }
                        | { __typename: "TextContent"; id: string; historyNumber: number }
                        | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                        | { __typename: "VariableTableContent" }
                        | null
                    }
                    actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
                  }
                | {
                    __typename: "TextComment"
                    endLocation: number
                    startLocation?: number | null
                    value?: string | null
                    authorDisplayName?: string | null
                    authorUserName?: string | null
                    commentKey: string
                    editDate?: any | null
                    modificationVersion?: number | null
                    replyToKey?: string | null
                    sortOrder?: number | null
                    commentType?: string | null
                    state?: string | null
                    color: string
                    persistanceState?: Types.PersistanceState | null
                    persistanceAction?: Types.PersistanceAction | null
                    node: {
                      __typename: "ST4Node"
                      aspectId: any
                      id: string
                      content?:
                        | { __typename: "LexiconContent" }
                        | { __typename: "LexiconEntryContent" }
                        | { __typename: "MediaContent" }
                        | { __typename: "MediaGroupContent" }
                        | { __typename: "ProjectContent" }
                        | { __typename: "TermContent" }
                        | { __typename: "TextContent"; id: string; historyNumber: number }
                        | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                        | { __typename: "VariableTableContent" }
                        | null
                    }
                    actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
                  }
            }>
          }
        | {
            __typename: "TextGroupContent"
            currentNodeId?: any | null
            groupNodeId: any
            id: string
            xast?: string | null
            historyNumber: number
            children: Array<{
              __typename: "NodeRef"
              id: string
              target?:
                | { __typename: "CommentNode"; id: string }
                | { __typename: "ExternalSite"; id: string }
                | { __typename: "ST4Class"; id: string }
                | { __typename: "ST4Node"; label?: string | null; aspectId: any; id: string }
                | { __typename: "ST4TreeNode"; id: string }
                | null
            }>
            links?: Array<{
              __typename: "TextContentLink"
              ref?: string | null
              node?:
                | { __typename: "CommentNode"; id: string }
                | { __typename: "ExternalSite"; id: string }
                | { __typename: "ST4Class"; id: string }
                | {
                    __typename: "ST4Node"
                    id: string
                    aspectId: any
                    label?: string | null
                    properties?: {
                      __typename: "ContentPropertyConnection"
                      totalCount: number
                      edges?: Array<{
                        __typename: "ContentPropertyEdge"
                        node?: {
                          __typename: "ContentProperty"
                          typeOfValue: Types.ContentPropertyValueType
                          name: string
                          value?: any | null
                          displayName: string
                        } | null
                      } | null> | null
                    } | null
                  }
                | { __typename: "ST4TreeNode"; id: string }
                | null
            } | null> | null
            images: Array<{
              __typename: "NodeRef"
              id: string
              target?:
                | { __typename: "CommentNode"; id: string }
                | { __typename: "ExternalSite"; id: string }
                | { __typename: "ST4Class"; id: string }
                | {
                    __typename: "ST4Node"
                    aspectId: any
                    id: string
                    releaseState: {
                      __typename: "ReleaseStateValue"
                      displayName: string
                      state: Types.ReleaseState
                      isImplicitlyReleased: boolean
                    }
                    content?:
                      | { __typename: "LexiconContent" }
                      | { __typename: "LexiconEntryContent" }
                      | {
                          __typename: "MediaContent"
                          media?:
                            | {
                                __typename: "AnimationMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                mediaType?: Types.MediaType | null
                              }
                            | {
                                __typename: "ImageMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                resolution?: number | null
                                colorDepth?: number | null
                                size?: number | null
                                mime?: string | null
                                extension?: string | null
                                allowCaching?: boolean | null
                                mediaType?: Types.MediaType | null
                                src?: string | null
                                language?: string | null
                              }
                            | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                            | {
                                __typename: "VectorGraphicsMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                allowCaching?: boolean | null
                                mediaType?: Types.MediaType | null
                                language?: string | null
                                labels: Array<{
                                  __typename: "VectorGraphicsLabel"
                                  id?: string | null
                                  sequence?: number | null
                                  autoValue?: string | null
                                  text?: string | null
                                  linkType?: Types.VectorGraphicsLabelLinkType | null
                                  link?: string | null
                                  ref?:
                                    | {
                                        __typename: "GenericRef"
                                        id: string
                                        owner?:
                                          | { __typename: "CommentNode" }
                                          | { __typename: "ExternalSite" }
                                          | { __typename: "ST4Class" }
                                          | { __typename: "ST4Node"; id: string }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                        target?:
                                          | { __typename: "CommentNode" }
                                          | {
                                              __typename: "ExternalSite"
                                              id: string
                                              teaserData?: {
                                                __typename: "ExternalLinkTeaserData"
                                                title?: string | null
                                                summary?: string | null
                                                iconUrl?: string | null
                                                imageUrl?: string | null
                                                status?: Types.LinkTargetStatus | null
                                              } | null
                                            }
                                          | { __typename: "ST4Class" }
                                          | {
                                              __typename: "ST4Node"
                                              id: string
                                              aspectId: any
                                              label?: string | null
                                              properties?: {
                                                __typename: "ContentPropertyConnection"
                                                edges?: Array<{
                                                  __typename: "ContentPropertyEdge"
                                                  node?: {
                                                    __typename: "ContentProperty"
                                                    name: string
                                                    value?: any | null
                                                    displayName: string
                                                  } | null
                                                } | null> | null
                                              } | null
                                            }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                      }
                                    | {
                                        __typename: "NodeRef"
                                        id: string
                                        owner?:
                                          | { __typename: "CommentNode" }
                                          | { __typename: "ExternalSite" }
                                          | { __typename: "ST4Class" }
                                          | { __typename: "ST4Node"; id: string }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                        target?:
                                          | { __typename: "CommentNode" }
                                          | {
                                              __typename: "ExternalSite"
                                              id: string
                                              teaserData?: {
                                                __typename: "ExternalLinkTeaserData"
                                                title?: string | null
                                                summary?: string | null
                                                iconUrl?: string | null
                                                imageUrl?: string | null
                                                status?: Types.LinkTargetStatus | null
                                              } | null
                                            }
                                          | { __typename: "ST4Class" }
                                          | {
                                              __typename: "ST4Node"
                                              id: string
                                              aspectId: any
                                              label?: string | null
                                              properties?: {
                                                __typename: "ContentPropertyConnection"
                                                edges?: Array<{
                                                  __typename: "ContentPropertyEdge"
                                                  node?: {
                                                    __typename: "ContentProperty"
                                                    name: string
                                                    value?: any | null
                                                    displayName: string
                                                  } | null
                                                } | null> | null
                                              } | null
                                            }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                      }
                                    | null
                                }>
                              }
                            | {
                                __typename: "VideoMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                mediaType?: Types.MediaType | null
                              }
                            | null
                        }
                      | {
                          __typename: "MediaGroupContent"
                          children: Array<{
                            __typename: "NodeRef"
                            id: string
                            target?:
                              | { __typename: "CommentNode"; id: string }
                              | { __typename: "ExternalSite"; id: string }
                              | { __typename: "ST4Class"; id: string }
                              | {
                                  __typename: "ST4Node"
                                  id: string
                                  aspectId: any
                                  content?:
                                    | { __typename: "LexiconContent" }
                                    | { __typename: "LexiconEntryContent" }
                                    | {
                                        __typename: "MediaContent"
                                        media?:
                                          | {
                                              __typename: "AnimationMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              mediaType?: Types.MediaType | null
                                            }
                                          | {
                                              __typename: "ImageMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              resolution?: number | null
                                              colorDepth?: number | null
                                              size?: number | null
                                              mime?: string | null
                                              extension?: string | null
                                              allowCaching?: boolean | null
                                              mediaType?: Types.MediaType | null
                                              src?: string | null
                                              language?: string | null
                                            }
                                          | {
                                              __typename: "NoMediaInfo"
                                              id: string
                                              mediaType?: Types.MediaType | null
                                            }
                                          | {
                                              __typename: "VectorGraphicsMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              allowCaching?: boolean | null
                                              mediaType?: Types.MediaType | null
                                              language?: string | null
                                              labels: Array<{
                                                __typename: "VectorGraphicsLabel"
                                                id?: string | null
                                                sequence?: number | null
                                                autoValue?: string | null
                                                text?: string | null
                                                linkType?: Types.VectorGraphicsLabelLinkType | null
                                                link?: string | null
                                                ref?:
                                                  | {
                                                      __typename: "GenericRef"
                                                      id: string
                                                      owner?:
                                                        | { __typename: "CommentNode" }
                                                        | { __typename: "ExternalSite" }
                                                        | { __typename: "ST4Class" }
                                                        | { __typename: "ST4Node"; id: string }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                      target?:
                                                        | { __typename: "CommentNode" }
                                                        | {
                                                            __typename: "ExternalSite"
                                                            id: string
                                                            teaserData?: {
                                                              __typename: "ExternalLinkTeaserData"
                                                              title?: string | null
                                                              summary?: string | null
                                                              iconUrl?: string | null
                                                              imageUrl?: string | null
                                                              status?: Types.LinkTargetStatus | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4Class" }
                                                        | {
                                                            __typename: "ST4Node"
                                                            id: string
                                                            aspectId: any
                                                            label?: string | null
                                                            properties?: {
                                                              __typename: "ContentPropertyConnection"
                                                              edges?: Array<{
                                                                __typename: "ContentPropertyEdge"
                                                                node?: {
                                                                  __typename: "ContentProperty"
                                                                  name: string
                                                                  value?: any | null
                                                                  displayName: string
                                                                } | null
                                                              } | null> | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                    }
                                                  | {
                                                      __typename: "NodeRef"
                                                      id: string
                                                      owner?:
                                                        | { __typename: "CommentNode" }
                                                        | { __typename: "ExternalSite" }
                                                        | { __typename: "ST4Class" }
                                                        | { __typename: "ST4Node"; id: string }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                      target?:
                                                        | { __typename: "CommentNode" }
                                                        | {
                                                            __typename: "ExternalSite"
                                                            id: string
                                                            teaserData?: {
                                                              __typename: "ExternalLinkTeaserData"
                                                              title?: string | null
                                                              summary?: string | null
                                                              iconUrl?: string | null
                                                              imageUrl?: string | null
                                                              status?: Types.LinkTargetStatus | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4Class" }
                                                        | {
                                                            __typename: "ST4Node"
                                                            id: string
                                                            aspectId: any
                                                            label?: string | null
                                                            properties?: {
                                                              __typename: "ContentPropertyConnection"
                                                              edges?: Array<{
                                                                __typename: "ContentPropertyEdge"
                                                                node?: {
                                                                  __typename: "ContentProperty"
                                                                  name: string
                                                                  value?: any | null
                                                                  displayName: string
                                                                } | null
                                                              } | null> | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                    }
                                                  | null
                                              }>
                                            }
                                          | {
                                              __typename: "VideoMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              mediaType?: Types.MediaType | null
                                            }
                                          | null
                                      }
                                    | {
                                        __typename: "MediaGroupContent"
                                        media?:
                                          | {
                                              __typename: "AnimationMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              mediaType?: Types.MediaType | null
                                            }
                                          | {
                                              __typename: "ImageMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              resolution?: number | null
                                              colorDepth?: number | null
                                              size?: number | null
                                              mime?: string | null
                                              extension?: string | null
                                              allowCaching?: boolean | null
                                              mediaType?: Types.MediaType | null
                                              src?: string | null
                                              language?: string | null
                                            }
                                          | {
                                              __typename: "NoMediaInfo"
                                              id: string
                                              mediaType?: Types.MediaType | null
                                            }
                                          | {
                                              __typename: "VectorGraphicsMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              allowCaching?: boolean | null
                                              mediaType?: Types.MediaType | null
                                              language?: string | null
                                              labels: Array<{
                                                __typename: "VectorGraphicsLabel"
                                                id?: string | null
                                                sequence?: number | null
                                                autoValue?: string | null
                                                text?: string | null
                                                linkType?: Types.VectorGraphicsLabelLinkType | null
                                                link?: string | null
                                                ref?:
                                                  | {
                                                      __typename: "GenericRef"
                                                      id: string
                                                      owner?:
                                                        | { __typename: "CommentNode" }
                                                        | { __typename: "ExternalSite" }
                                                        | { __typename: "ST4Class" }
                                                        | { __typename: "ST4Node"; id: string }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                      target?:
                                                        | { __typename: "CommentNode" }
                                                        | {
                                                            __typename: "ExternalSite"
                                                            id: string
                                                            teaserData?: {
                                                              __typename: "ExternalLinkTeaserData"
                                                              title?: string | null
                                                              summary?: string | null
                                                              iconUrl?: string | null
                                                              imageUrl?: string | null
                                                              status?: Types.LinkTargetStatus | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4Class" }
                                                        | {
                                                            __typename: "ST4Node"
                                                            id: string
                                                            aspectId: any
                                                            label?: string | null
                                                            properties?: {
                                                              __typename: "ContentPropertyConnection"
                                                              edges?: Array<{
                                                                __typename: "ContentPropertyEdge"
                                                                node?: {
                                                                  __typename: "ContentProperty"
                                                                  name: string
                                                                  value?: any | null
                                                                  displayName: string
                                                                } | null
                                                              } | null> | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                    }
                                                  | {
                                                      __typename: "NodeRef"
                                                      id: string
                                                      owner?:
                                                        | { __typename: "CommentNode" }
                                                        | { __typename: "ExternalSite" }
                                                        | { __typename: "ST4Class" }
                                                        | { __typename: "ST4Node"; id: string }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                      target?:
                                                        | { __typename: "CommentNode" }
                                                        | {
                                                            __typename: "ExternalSite"
                                                            id: string
                                                            teaserData?: {
                                                              __typename: "ExternalLinkTeaserData"
                                                              title?: string | null
                                                              summary?: string | null
                                                              iconUrl?: string | null
                                                              imageUrl?: string | null
                                                              status?: Types.LinkTargetStatus | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4Class" }
                                                        | {
                                                            __typename: "ST4Node"
                                                            id: string
                                                            aspectId: any
                                                            label?: string | null
                                                            properties?: {
                                                              __typename: "ContentPropertyConnection"
                                                              edges?: Array<{
                                                                __typename: "ContentPropertyEdge"
                                                                node?: {
                                                                  __typename: "ContentProperty"
                                                                  name: string
                                                                  value?: any | null
                                                                  displayName: string
                                                                } | null
                                                              } | null> | null
                                                            } | null
                                                          }
                                                        | { __typename: "ST4TreeNode" }
                                                        | null
                                                    }
                                                  | null
                                              }>
                                            }
                                          | {
                                              __typename: "VideoMediaInfo"
                                              id: string
                                              resourceId?: string | null
                                              thumbnail?: string | null
                                              width?: number | null
                                              height?: number | null
                                              mediaType?: Types.MediaType | null
                                            }
                                          | null
                                      }
                                    | { __typename: "ProjectContent" }
                                    | { __typename: "TermContent" }
                                    | { __typename: "TextContent" }
                                    | { __typename: "TextGroupContent" }
                                    | { __typename: "VariableTableContent" }
                                    | null
                                }
                              | { __typename: "ST4TreeNode"; id: string }
                              | null
                          }>
                          media?:
                            | {
                                __typename: "AnimationMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                mediaType?: Types.MediaType | null
                              }
                            | {
                                __typename: "ImageMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                resolution?: number | null
                                colorDepth?: number | null
                                size?: number | null
                                mime?: string | null
                                extension?: string | null
                                allowCaching?: boolean | null
                                mediaType?: Types.MediaType | null
                                src?: string | null
                                language?: string | null
                              }
                            | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                            | {
                                __typename: "VectorGraphicsMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                allowCaching?: boolean | null
                                mediaType?: Types.MediaType | null
                                language?: string | null
                                labels: Array<{
                                  __typename: "VectorGraphicsLabel"
                                  id?: string | null
                                  sequence?: number | null
                                  autoValue?: string | null
                                  text?: string | null
                                  linkType?: Types.VectorGraphicsLabelLinkType | null
                                  link?: string | null
                                  ref?:
                                    | {
                                        __typename: "GenericRef"
                                        id: string
                                        owner?:
                                          | { __typename: "CommentNode" }
                                          | { __typename: "ExternalSite" }
                                          | { __typename: "ST4Class" }
                                          | { __typename: "ST4Node"; id: string }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                        target?:
                                          | { __typename: "CommentNode" }
                                          | {
                                              __typename: "ExternalSite"
                                              id: string
                                              teaserData?: {
                                                __typename: "ExternalLinkTeaserData"
                                                title?: string | null
                                                summary?: string | null
                                                iconUrl?: string | null
                                                imageUrl?: string | null
                                                status?: Types.LinkTargetStatus | null
                                              } | null
                                            }
                                          | { __typename: "ST4Class" }
                                          | {
                                              __typename: "ST4Node"
                                              id: string
                                              aspectId: any
                                              label?: string | null
                                              properties?: {
                                                __typename: "ContentPropertyConnection"
                                                edges?: Array<{
                                                  __typename: "ContentPropertyEdge"
                                                  node?: {
                                                    __typename: "ContentProperty"
                                                    name: string
                                                    value?: any | null
                                                    displayName: string
                                                  } | null
                                                } | null> | null
                                              } | null
                                            }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                      }
                                    | {
                                        __typename: "NodeRef"
                                        id: string
                                        owner?:
                                          | { __typename: "CommentNode" }
                                          | { __typename: "ExternalSite" }
                                          | { __typename: "ST4Class" }
                                          | { __typename: "ST4Node"; id: string }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                        target?:
                                          | { __typename: "CommentNode" }
                                          | {
                                              __typename: "ExternalSite"
                                              id: string
                                              teaserData?: {
                                                __typename: "ExternalLinkTeaserData"
                                                title?: string | null
                                                summary?: string | null
                                                iconUrl?: string | null
                                                imageUrl?: string | null
                                                status?: Types.LinkTargetStatus | null
                                              } | null
                                            }
                                          | { __typename: "ST4Class" }
                                          | {
                                              __typename: "ST4Node"
                                              id: string
                                              aspectId: any
                                              label?: string | null
                                              properties?: {
                                                __typename: "ContentPropertyConnection"
                                                edges?: Array<{
                                                  __typename: "ContentPropertyEdge"
                                                  node?: {
                                                    __typename: "ContentProperty"
                                                    name: string
                                                    value?: any | null
                                                    displayName: string
                                                  } | null
                                                } | null> | null
                                              } | null
                                            }
                                          | { __typename: "ST4TreeNode" }
                                          | null
                                      }
                                    | null
                                }>
                              }
                            | {
                                __typename: "VideoMediaInfo"
                                id: string
                                resourceId?: string | null
                                thumbnail?: string | null
                                width?: number | null
                                height?: number | null
                                mediaType?: Types.MediaType | null
                              }
                            | null
                        }
                      | { __typename: "ProjectContent" }
                      | { __typename: "TermContent" }
                      | { __typename: "TextContent" }
                      | { __typename: "TextGroupContent" }
                      | { __typename: "VariableTableContent" }
                      | null
                  }
                | { __typename: "ST4TreeNode"; id: string }
                | null
            }>
            variableTables?: Array<{
              __typename: "VariableTableReference"
              ref?: string | null
              node?:
                | { __typename: "CommentNode" }
                | { __typename: "ExternalSite" }
                | { __typename: "ST4Class" }
                | {
                    __typename: "ST4Node"
                    id: string
                    label?: string | null
                    aspectId: any
                    content?:
                      | { __typename: "LexiconContent" }
                      | { __typename: "LexiconEntryContent" }
                      | { __typename: "MediaContent" }
                      | { __typename: "MediaGroupContent" }
                      | { __typename: "ProjectContent" }
                      | { __typename: "TermContent" }
                      | { __typename: "TextContent" }
                      | { __typename: "TextGroupContent" }
                      | {
                          __typename: "VariableTableContent"
                          xml?: string | null
                          valueSets: Array<{ __typename: "ValueSet"; name: string; valueSetId: string }>
                          variables: Array<{
                            __typename: "Variable"
                            name: string
                            variableId: string
                            values: Array<string>
                          }>
                        }
                      | null
                  }
                | { __typename: "ST4TreeNode" }
                | null
            } | null> | null
            linkedDataNodes: Array<{
              __typename: "NodeRef"
              id: string
              target?:
                | { __typename: "CommentNode"; id: string }
                | { __typename: "ExternalSite"; id: string }
                | { __typename: "ST4Class"; id: string }
                | {
                    __typename: "ST4Node"
                    label?: string | null
                    id: string
                    metadata: Array<{
                      __typename: "MetaDataEntry"
                      label?: string | null
                      systemName: string
                      value?: string | null
                      values: Array<string>
                      users: Array<string>
                    }>
                    linkedOntologyNodes: Array<
                      | {
                          __typename: "GenericRef"
                          target?:
                            | { __typename: "CommentNode" }
                            | { __typename: "ExternalSite" }
                            | { __typename: "ST4Class" }
                            | { __typename: "ST4Node"; id: string; label?: string | null }
                            | { __typename: "ST4TreeNode" }
                            | null
                        }
                      | {
                          __typename: "NodeRef"
                          linkClassName: string
                          class?: { __typename: "ST4Class"; label?: string | null } | null
                          target?:
                            | { __typename: "CommentNode" }
                            | { __typename: "ExternalSite" }
                            | { __typename: "ST4Class" }
                            | { __typename: "ST4Node"; id: string; label?: string | null }
                            | { __typename: "ST4TreeNode" }
                            | null
                        }
                    >
                  }
                | { __typename: "ST4TreeNode"; id: string }
                | null
            }>
            lexiconEntries?: Array<{
              __typename: "NodeRef"
              id: string
              target?:
                | { __typename: "CommentNode"; id: string }
                | { __typename: "ExternalSite"; id: string }
                | { __typename: "ST4Class"; id: string }
                | {
                    __typename: "ST4Node"
                    aspectId: any
                    label?: string | null
                    id: string
                    content?:
                      | { __typename: "LexiconContent" }
                      | {
                          __typename: "LexiconEntryContent"
                          descriptionXast?: string | null
                          subject?: string | null
                          sortKey?: string | null
                          lexicon:
                            | { __typename: "CommentNode"; id: string }
                            | { __typename: "ExternalSite"; id: string }
                            | { __typename: "ST4Class"; id: string }
                            | { __typename: "ST4Node"; label?: string | null; id: string }
                            | { __typename: "ST4TreeNode"; id: string }
                          terms: Array<
                            | { __typename: "CommentNode"; id: string }
                            | { __typename: "ExternalSite"; id: string }
                            | { __typename: "ST4Class"; id: string }
                            | {
                                __typename: "ST4Node"
                                id: string
                                label?: string | null
                                aspectId: any
                                content?:
                                  | { __typename: "LexiconContent" }
                                  | { __typename: "LexiconEntryContent" }
                                  | { __typename: "MediaContent" }
                                  | { __typename: "MediaGroupContent" }
                                  | { __typename: "ProjectContent" }
                                  | {
                                      __typename: "TermContent"
                                      gramaticalGender?: string | null
                                      validity?: string | null
                                      editLanguage?: string | null
                                    }
                                  | { __typename: "TextContent" }
                                  | { __typename: "TextGroupContent" }
                                  | { __typename: "VariableTableContent" }
                                  | null
                              }
                            | { __typename: "ST4TreeNode"; id: string }
                          >
                          image?:
                            | { __typename: "CommentNode"; id: string }
                            | { __typename: "ExternalSite"; id: string }
                            | { __typename: "ST4Class"; id: string }
                            | {
                                __typename: "ST4Node"
                                aspectId: any
                                id: string
                                content?:
                                  | { __typename: "LexiconContent" }
                                  | { __typename: "LexiconEntryContent" }
                                  | {
                                      __typename: "MediaContent"
                                      media?:
                                        | {
                                            __typename: "AnimationMediaInfo"
                                            thumbnail?: string | null
                                            mediaType?: Types.MediaType | null
                                          }
                                        | {
                                            __typename: "ImageMediaInfo"
                                            src?: string | null
                                            thumbnail?: string | null
                                            mediaType?: Types.MediaType | null
                                          }
                                        | { __typename: "NoMediaInfo" }
                                        | {
                                            __typename: "VectorGraphicsMediaInfo"
                                            svg?: string | null
                                            thumbnail?: string | null
                                            mediaType?: Types.MediaType | null
                                          }
                                        | {
                                            __typename: "VideoMediaInfo"
                                            thumbnail?: string | null
                                            mediaType?: Types.MediaType | null
                                          }
                                        | null
                                    }
                                  | { __typename: "MediaGroupContent" }
                                  | { __typename: "ProjectContent" }
                                  | { __typename: "TermContent" }
                                  | { __typename: "TextContent" }
                                  | { __typename: "TextGroupContent" }
                                  | { __typename: "VariableTableContent" }
                                  | null
                              }
                            | { __typename: "ST4TreeNode"; id: string }
                            | null
                        }
                      | { __typename: "MediaContent" }
                      | { __typename: "MediaGroupContent" }
                      | { __typename: "ProjectContent" }
                      | { __typename: "TermContent" }
                      | { __typename: "TextContent" }
                      | { __typename: "TextGroupContent" }
                      | { __typename: "VariableTableContent" }
                      | null
                  }
                | { __typename: "ST4TreeNode"; id: string }
                | null
            } | null> | null
            comments: Array<{
              __typename: "CommentNode"
              id: string
              comment:
                | {
                    __typename: "BlockComment"
                    location: number
                    value?: string | null
                    authorDisplayName?: string | null
                    authorUserName?: string | null
                    commentKey: string
                    editDate?: any | null
                    modificationVersion?: number | null
                    replyToKey?: string | null
                    sortOrder?: number | null
                    commentType?: string | null
                    state?: string | null
                    color: string
                    persistanceState?: Types.PersistanceState | null
                    persistanceAction?: Types.PersistanceAction | null
                    node: {
                      __typename: "ST4Node"
                      aspectId: any
                      id: string
                      content?:
                        | { __typename: "LexiconContent" }
                        | { __typename: "LexiconEntryContent" }
                        | { __typename: "MediaContent" }
                        | { __typename: "MediaGroupContent" }
                        | { __typename: "ProjectContent" }
                        | { __typename: "TermContent" }
                        | { __typename: "TextContent"; id: string; historyNumber: number }
                        | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                        | { __typename: "VariableTableContent" }
                        | null
                    }
                    actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
                  }
                | {
                    __typename: "TextComment"
                    endLocation: number
                    startLocation?: number | null
                    value?: string | null
                    authorDisplayName?: string | null
                    authorUserName?: string | null
                    commentKey: string
                    editDate?: any | null
                    modificationVersion?: number | null
                    replyToKey?: string | null
                    sortOrder?: number | null
                    commentType?: string | null
                    state?: string | null
                    color: string
                    persistanceState?: Types.PersistanceState | null
                    persistanceAction?: Types.PersistanceAction | null
                    node: {
                      __typename: "ST4Node"
                      aspectId: any
                      id: string
                      content?:
                        | { __typename: "LexiconContent" }
                        | { __typename: "LexiconEntryContent" }
                        | { __typename: "MediaContent" }
                        | { __typename: "MediaGroupContent" }
                        | { __typename: "ProjectContent" }
                        | { __typename: "TermContent" }
                        | { __typename: "TextContent"; id: string; historyNumber: number }
                        | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                        | { __typename: "VariableTableContent" }
                        | null
                    }
                    actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
                  }
            }>
          }
        | { __typename: "VariableTableContent" }
        | null
    }
  }>
  children: Array<{
    __typename: "NodeRef"
    id: string
    target?:
      | { __typename: "CommentNode"; id: string }
      | { __typename: "ExternalSite"; id: string }
      | { __typename: "ST4Class"; id: string }
      | { __typename: "ST4Node"; label?: string | null; aspectId: any; id: string }
      | { __typename: "ST4TreeNode"; id: string }
      | null
  }>
  links?: Array<{
    __typename: "TextContentLink"
    ref?: string | null
    node?:
      | { __typename: "CommentNode"; id: string }
      | { __typename: "ExternalSite"; id: string }
      | { __typename: "ST4Class"; id: string }
      | {
          __typename: "ST4Node"
          id: string
          aspectId: any
          label?: string | null
          properties?: {
            __typename: "ContentPropertyConnection"
            totalCount: number
            edges?: Array<{
              __typename: "ContentPropertyEdge"
              node?: {
                __typename: "ContentProperty"
                typeOfValue: Types.ContentPropertyValueType
                name: string
                value?: any | null
                displayName: string
              } | null
            } | null> | null
          } | null
        }
      | { __typename: "ST4TreeNode"; id: string }
      | null
  } | null> | null
  images: Array<{
    __typename: "NodeRef"
    id: string
    target?:
      | { __typename: "CommentNode"; id: string }
      | { __typename: "ExternalSite"; id: string }
      | { __typename: "ST4Class"; id: string }
      | {
          __typename: "ST4Node"
          aspectId: any
          id: string
          releaseState: {
            __typename: "ReleaseStateValue"
            displayName: string
            state: Types.ReleaseState
            isImplicitlyReleased: boolean
          }
          content?:
            | { __typename: "LexiconContent" }
            | { __typename: "LexiconEntryContent" }
            | {
                __typename: "MediaContent"
                media?:
                  | {
                      __typename: "AnimationMediaInfo"
                      id: string
                      resourceId?: string | null
                      thumbnail?: string | null
                      width?: number | null
                      height?: number | null
                      mediaType?: Types.MediaType | null
                    }
                  | {
                      __typename: "ImageMediaInfo"
                      id: string
                      resourceId?: string | null
                      thumbnail?: string | null
                      width?: number | null
                      height?: number | null
                      resolution?: number | null
                      colorDepth?: number | null
                      size?: number | null
                      mime?: string | null
                      extension?: string | null
                      allowCaching?: boolean | null
                      mediaType?: Types.MediaType | null
                      src?: string | null
                      language?: string | null
                    }
                  | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                  | {
                      __typename: "VectorGraphicsMediaInfo"
                      id: string
                      resourceId?: string | null
                      thumbnail?: string | null
                      width?: number | null
                      height?: number | null
                      allowCaching?: boolean | null
                      mediaType?: Types.MediaType | null
                      language?: string | null
                      labels: Array<{
                        __typename: "VectorGraphicsLabel"
                        id?: string | null
                        sequence?: number | null
                        autoValue?: string | null
                        text?: string | null
                        linkType?: Types.VectorGraphicsLabelLinkType | null
                        link?: string | null
                        ref?:
                          | {
                              __typename: "GenericRef"
                              id: string
                              owner?:
                                | { __typename: "CommentNode" }
                                | { __typename: "ExternalSite" }
                                | { __typename: "ST4Class" }
                                | { __typename: "ST4Node"; id: string }
                                | { __typename: "ST4TreeNode" }
                                | null
                              target?:
                                | { __typename: "CommentNode" }
                                | {
                                    __typename: "ExternalSite"
                                    id: string
                                    teaserData?: {
                                      __typename: "ExternalLinkTeaserData"
                                      title?: string | null
                                      summary?: string | null
                                      iconUrl?: string | null
                                      imageUrl?: string | null
                                      status?: Types.LinkTargetStatus | null
                                    } | null
                                  }
                                | { __typename: "ST4Class" }
                                | {
                                    __typename: "ST4Node"
                                    id: string
                                    aspectId: any
                                    label?: string | null
                                    properties?: {
                                      __typename: "ContentPropertyConnection"
                                      edges?: Array<{
                                        __typename: "ContentPropertyEdge"
                                        node?: {
                                          __typename: "ContentProperty"
                                          name: string
                                          value?: any | null
                                          displayName: string
                                        } | null
                                      } | null> | null
                                    } | null
                                  }
                                | { __typename: "ST4TreeNode" }
                                | null
                            }
                          | {
                              __typename: "NodeRef"
                              id: string
                              owner?:
                                | { __typename: "CommentNode" }
                                | { __typename: "ExternalSite" }
                                | { __typename: "ST4Class" }
                                | { __typename: "ST4Node"; id: string }
                                | { __typename: "ST4TreeNode" }
                                | null
                              target?:
                                | { __typename: "CommentNode" }
                                | {
                                    __typename: "ExternalSite"
                                    id: string
                                    teaserData?: {
                                      __typename: "ExternalLinkTeaserData"
                                      title?: string | null
                                      summary?: string | null
                                      iconUrl?: string | null
                                      imageUrl?: string | null
                                      status?: Types.LinkTargetStatus | null
                                    } | null
                                  }
                                | { __typename: "ST4Class" }
                                | {
                                    __typename: "ST4Node"
                                    id: string
                                    aspectId: any
                                    label?: string | null
                                    properties?: {
                                      __typename: "ContentPropertyConnection"
                                      edges?: Array<{
                                        __typename: "ContentPropertyEdge"
                                        node?: {
                                          __typename: "ContentProperty"
                                          name: string
                                          value?: any | null
                                          displayName: string
                                        } | null
                                      } | null> | null
                                    } | null
                                  }
                                | { __typename: "ST4TreeNode" }
                                | null
                            }
                          | null
                      }>
                    }
                  | {
                      __typename: "VideoMediaInfo"
                      id: string
                      resourceId?: string | null
                      thumbnail?: string | null
                      width?: number | null
                      height?: number | null
                      mediaType?: Types.MediaType | null
                    }
                  | null
              }
            | {
                __typename: "MediaGroupContent"
                children: Array<{
                  __typename: "NodeRef"
                  id: string
                  target?:
                    | { __typename: "CommentNode"; id: string }
                    | { __typename: "ExternalSite"; id: string }
                    | { __typename: "ST4Class"; id: string }
                    | {
                        __typename: "ST4Node"
                        id: string
                        aspectId: any
                        content?:
                          | { __typename: "LexiconContent" }
                          | { __typename: "LexiconEntryContent" }
                          | {
                              __typename: "MediaContent"
                              media?:
                                | {
                                    __typename: "AnimationMediaInfo"
                                    id: string
                                    resourceId?: string | null
                                    thumbnail?: string | null
                                    width?: number | null
                                    height?: number | null
                                    mediaType?: Types.MediaType | null
                                  }
                                | {
                                    __typename: "ImageMediaInfo"
                                    id: string
                                    resourceId?: string | null
                                    thumbnail?: string | null
                                    width?: number | null
                                    height?: number | null
                                    resolution?: number | null
                                    colorDepth?: number | null
                                    size?: number | null
                                    mime?: string | null
                                    extension?: string | null
                                    allowCaching?: boolean | null
                                    mediaType?: Types.MediaType | null
                                    src?: string | null
                                    language?: string | null
                                  }
                                | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                                | {
                                    __typename: "VectorGraphicsMediaInfo"
                                    id: string
                                    resourceId?: string | null
                                    thumbnail?: string | null
                                    width?: number | null
                                    height?: number | null
                                    allowCaching?: boolean | null
                                    mediaType?: Types.MediaType | null
                                    language?: string | null
                                    labels: Array<{
                                      __typename: "VectorGraphicsLabel"
                                      id?: string | null
                                      sequence?: number | null
                                      autoValue?: string | null
                                      text?: string | null
                                      linkType?: Types.VectorGraphicsLabelLinkType | null
                                      link?: string | null
                                      ref?:
                                        | {
                                            __typename: "GenericRef"
                                            id: string
                                            owner?:
                                              | { __typename: "CommentNode" }
                                              | { __typename: "ExternalSite" }
                                              | { __typename: "ST4Class" }
                                              | { __typename: "ST4Node"; id: string }
                                              | { __typename: "ST4TreeNode" }
                                              | null
                                            target?:
                                              | { __typename: "CommentNode" }
                                              | {
                                                  __typename: "ExternalSite"
                                                  id: string
                                                  teaserData?: {
                                                    __typename: "ExternalLinkTeaserData"
                                                    title?: string | null
                                                    summary?: string | null
                                                    iconUrl?: string | null
                                                    imageUrl?: string | null
                                                    status?: Types.LinkTargetStatus | null
                                                  } | null
                                                }
                                              | { __typename: "ST4Class" }
                                              | {
                                                  __typename: "ST4Node"
                                                  id: string
                                                  aspectId: any
                                                  label?: string | null
                                                  properties?: {
                                                    __typename: "ContentPropertyConnection"
                                                    edges?: Array<{
                                                      __typename: "ContentPropertyEdge"
                                                      node?: {
                                                        __typename: "ContentProperty"
                                                        name: string
                                                        value?: any | null
                                                        displayName: string
                                                      } | null
                                                    } | null> | null
                                                  } | null
                                                }
                                              | { __typename: "ST4TreeNode" }
                                              | null
                                          }
                                        | {
                                            __typename: "NodeRef"
                                            id: string
                                            owner?:
                                              | { __typename: "CommentNode" }
                                              | { __typename: "ExternalSite" }
                                              | { __typename: "ST4Class" }
                                              | { __typename: "ST4Node"; id: string }
                                              | { __typename: "ST4TreeNode" }
                                              | null
                                            target?:
                                              | { __typename: "CommentNode" }
                                              | {
                                                  __typename: "ExternalSite"
                                                  id: string
                                                  teaserData?: {
                                                    __typename: "ExternalLinkTeaserData"
                                                    title?: string | null
                                                    summary?: string | null
                                                    iconUrl?: string | null
                                                    imageUrl?: string | null
                                                    status?: Types.LinkTargetStatus | null
                                                  } | null
                                                }
                                              | { __typename: "ST4Class" }
                                              | {
                                                  __typename: "ST4Node"
                                                  id: string
                                                  aspectId: any
                                                  label?: string | null
                                                  properties?: {
                                                    __typename: "ContentPropertyConnection"
                                                    edges?: Array<{
                                                      __typename: "ContentPropertyEdge"
                                                      node?: {
                                                        __typename: "ContentProperty"
                                                        name: string
                                                        value?: any | null
                                                        displayName: string
                                                      } | null
                                                    } | null> | null
                                                  } | null
                                                }
                                              | { __typename: "ST4TreeNode" }
                                              | null
                                          }
                                        | null
                                    }>
                                  }
                                | {
                                    __typename: "VideoMediaInfo"
                                    id: string
                                    resourceId?: string | null
                                    thumbnail?: string | null
                                    width?: number | null
                                    height?: number | null
                                    mediaType?: Types.MediaType | null
                                  }
                                | null
                            }
                          | {
                              __typename: "MediaGroupContent"
                              media?:
                                | {
                                    __typename: "AnimationMediaInfo"
                                    id: string
                                    resourceId?: string | null
                                    thumbnail?: string | null
                                    width?: number | null
                                    height?: number | null
                                    mediaType?: Types.MediaType | null
                                  }
                                | {
                                    __typename: "ImageMediaInfo"
                                    id: string
                                    resourceId?: string | null
                                    thumbnail?: string | null
                                    width?: number | null
                                    height?: number | null
                                    resolution?: number | null
                                    colorDepth?: number | null
                                    size?: number | null
                                    mime?: string | null
                                    extension?: string | null
                                    allowCaching?: boolean | null
                                    mediaType?: Types.MediaType | null
                                    src?: string | null
                                    language?: string | null
                                  }
                                | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                                | {
                                    __typename: "VectorGraphicsMediaInfo"
                                    id: string
                                    resourceId?: string | null
                                    thumbnail?: string | null
                                    width?: number | null
                                    height?: number | null
                                    allowCaching?: boolean | null
                                    mediaType?: Types.MediaType | null
                                    language?: string | null
                                    labels: Array<{
                                      __typename: "VectorGraphicsLabel"
                                      id?: string | null
                                      sequence?: number | null
                                      autoValue?: string | null
                                      text?: string | null
                                      linkType?: Types.VectorGraphicsLabelLinkType | null
                                      link?: string | null
                                      ref?:
                                        | {
                                            __typename: "GenericRef"
                                            id: string
                                            owner?:
                                              | { __typename: "CommentNode" }
                                              | { __typename: "ExternalSite" }
                                              | { __typename: "ST4Class" }
                                              | { __typename: "ST4Node"; id: string }
                                              | { __typename: "ST4TreeNode" }
                                              | null
                                            target?:
                                              | { __typename: "CommentNode" }
                                              | {
                                                  __typename: "ExternalSite"
                                                  id: string
                                                  teaserData?: {
                                                    __typename: "ExternalLinkTeaserData"
                                                    title?: string | null
                                                    summary?: string | null
                                                    iconUrl?: string | null
                                                    imageUrl?: string | null
                                                    status?: Types.LinkTargetStatus | null
                                                  } | null
                                                }
                                              | { __typename: "ST4Class" }
                                              | {
                                                  __typename: "ST4Node"
                                                  id: string
                                                  aspectId: any
                                                  label?: string | null
                                                  properties?: {
                                                    __typename: "ContentPropertyConnection"
                                                    edges?: Array<{
                                                      __typename: "ContentPropertyEdge"
                                                      node?: {
                                                        __typename: "ContentProperty"
                                                        name: string
                                                        value?: any | null
                                                        displayName: string
                                                      } | null
                                                    } | null> | null
                                                  } | null
                                                }
                                              | { __typename: "ST4TreeNode" }
                                              | null
                                          }
                                        | {
                                            __typename: "NodeRef"
                                            id: string
                                            owner?:
                                              | { __typename: "CommentNode" }
                                              | { __typename: "ExternalSite" }
                                              | { __typename: "ST4Class" }
                                              | { __typename: "ST4Node"; id: string }
                                              | { __typename: "ST4TreeNode" }
                                              | null
                                            target?:
                                              | { __typename: "CommentNode" }
                                              | {
                                                  __typename: "ExternalSite"
                                                  id: string
                                                  teaserData?: {
                                                    __typename: "ExternalLinkTeaserData"
                                                    title?: string | null
                                                    summary?: string | null
                                                    iconUrl?: string | null
                                                    imageUrl?: string | null
                                                    status?: Types.LinkTargetStatus | null
                                                  } | null
                                                }
                                              | { __typename: "ST4Class" }
                                              | {
                                                  __typename: "ST4Node"
                                                  id: string
                                                  aspectId: any
                                                  label?: string | null
                                                  properties?: {
                                                    __typename: "ContentPropertyConnection"
                                                    edges?: Array<{
                                                      __typename: "ContentPropertyEdge"
                                                      node?: {
                                                        __typename: "ContentProperty"
                                                        name: string
                                                        value?: any | null
                                                        displayName: string
                                                      } | null
                                                    } | null> | null
                                                  } | null
                                                }
                                              | { __typename: "ST4TreeNode" }
                                              | null
                                          }
                                        | null
                                    }>
                                  }
                                | {
                                    __typename: "VideoMediaInfo"
                                    id: string
                                    resourceId?: string | null
                                    thumbnail?: string | null
                                    width?: number | null
                                    height?: number | null
                                    mediaType?: Types.MediaType | null
                                  }
                                | null
                            }
                          | { __typename: "ProjectContent" }
                          | { __typename: "TermContent" }
                          | { __typename: "TextContent" }
                          | { __typename: "TextGroupContent" }
                          | { __typename: "VariableTableContent" }
                          | null
                      }
                    | { __typename: "ST4TreeNode"; id: string }
                    | null
                }>
                media?:
                  | {
                      __typename: "AnimationMediaInfo"
                      id: string
                      resourceId?: string | null
                      thumbnail?: string | null
                      width?: number | null
                      height?: number | null
                      mediaType?: Types.MediaType | null
                    }
                  | {
                      __typename: "ImageMediaInfo"
                      id: string
                      resourceId?: string | null
                      thumbnail?: string | null
                      width?: number | null
                      height?: number | null
                      resolution?: number | null
                      colorDepth?: number | null
                      size?: number | null
                      mime?: string | null
                      extension?: string | null
                      allowCaching?: boolean | null
                      mediaType?: Types.MediaType | null
                      src?: string | null
                      language?: string | null
                    }
                  | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                  | {
                      __typename: "VectorGraphicsMediaInfo"
                      id: string
                      resourceId?: string | null
                      thumbnail?: string | null
                      width?: number | null
                      height?: number | null
                      allowCaching?: boolean | null
                      mediaType?: Types.MediaType | null
                      language?: string | null
                      labels: Array<{
                        __typename: "VectorGraphicsLabel"
                        id?: string | null
                        sequence?: number | null
                        autoValue?: string | null
                        text?: string | null
                        linkType?: Types.VectorGraphicsLabelLinkType | null
                        link?: string | null
                        ref?:
                          | {
                              __typename: "GenericRef"
                              id: string
                              owner?:
                                | { __typename: "CommentNode" }
                                | { __typename: "ExternalSite" }
                                | { __typename: "ST4Class" }
                                | { __typename: "ST4Node"; id: string }
                                | { __typename: "ST4TreeNode" }
                                | null
                              target?:
                                | { __typename: "CommentNode" }
                                | {
                                    __typename: "ExternalSite"
                                    id: string
                                    teaserData?: {
                                      __typename: "ExternalLinkTeaserData"
                                      title?: string | null
                                      summary?: string | null
                                      iconUrl?: string | null
                                      imageUrl?: string | null
                                      status?: Types.LinkTargetStatus | null
                                    } | null
                                  }
                                | { __typename: "ST4Class" }
                                | {
                                    __typename: "ST4Node"
                                    id: string
                                    aspectId: any
                                    label?: string | null
                                    properties?: {
                                      __typename: "ContentPropertyConnection"
                                      edges?: Array<{
                                        __typename: "ContentPropertyEdge"
                                        node?: {
                                          __typename: "ContentProperty"
                                          name: string
                                          value?: any | null
                                          displayName: string
                                        } | null
                                      } | null> | null
                                    } | null
                                  }
                                | { __typename: "ST4TreeNode" }
                                | null
                            }
                          | {
                              __typename: "NodeRef"
                              id: string
                              owner?:
                                | { __typename: "CommentNode" }
                                | { __typename: "ExternalSite" }
                                | { __typename: "ST4Class" }
                                | { __typename: "ST4Node"; id: string }
                                | { __typename: "ST4TreeNode" }
                                | null
                              target?:
                                | { __typename: "CommentNode" }
                                | {
                                    __typename: "ExternalSite"
                                    id: string
                                    teaserData?: {
                                      __typename: "ExternalLinkTeaserData"
                                      title?: string | null
                                      summary?: string | null
                                      iconUrl?: string | null
                                      imageUrl?: string | null
                                      status?: Types.LinkTargetStatus | null
                                    } | null
                                  }
                                | { __typename: "ST4Class" }
                                | {
                                    __typename: "ST4Node"
                                    id: string
                                    aspectId: any
                                    label?: string | null
                                    properties?: {
                                      __typename: "ContentPropertyConnection"
                                      edges?: Array<{
                                        __typename: "ContentPropertyEdge"
                                        node?: {
                                          __typename: "ContentProperty"
                                          name: string
                                          value?: any | null
                                          displayName: string
                                        } | null
                                      } | null> | null
                                    } | null
                                  }
                                | { __typename: "ST4TreeNode" }
                                | null
                            }
                          | null
                      }>
                    }
                  | {
                      __typename: "VideoMediaInfo"
                      id: string
                      resourceId?: string | null
                      thumbnail?: string | null
                      width?: number | null
                      height?: number | null
                      mediaType?: Types.MediaType | null
                    }
                  | null
              }
            | { __typename: "ProjectContent" }
            | { __typename: "TermContent" }
            | { __typename: "TextContent" }
            | { __typename: "TextGroupContent" }
            | { __typename: "VariableTableContent" }
            | null
        }
      | { __typename: "ST4TreeNode"; id: string }
      | null
  }>
  variableTables?: Array<{
    __typename: "VariableTableReference"
    ref?: string | null
    node?:
      | { __typename: "CommentNode" }
      | { __typename: "ExternalSite" }
      | { __typename: "ST4Class" }
      | {
          __typename: "ST4Node"
          id: string
          label?: string | null
          aspectId: any
          content?:
            | { __typename: "LexiconContent" }
            | { __typename: "LexiconEntryContent" }
            | { __typename: "MediaContent" }
            | { __typename: "MediaGroupContent" }
            | { __typename: "ProjectContent" }
            | { __typename: "TermContent" }
            | { __typename: "TextContent" }
            | { __typename: "TextGroupContent" }
            | {
                __typename: "VariableTableContent"
                xml?: string | null
                valueSets: Array<{ __typename: "ValueSet"; name: string; valueSetId: string }>
                variables: Array<{ __typename: "Variable"; name: string; variableId: string; values: Array<string> }>
              }
            | null
        }
      | { __typename: "ST4TreeNode" }
      | null
  } | null> | null
  linkedDataNodes: Array<{
    __typename: "NodeRef"
    id: string
    target?:
      | { __typename: "CommentNode"; id: string }
      | { __typename: "ExternalSite"; id: string }
      | { __typename: "ST4Class"; id: string }
      | {
          __typename: "ST4Node"
          label?: string | null
          id: string
          metadata: Array<{
            __typename: "MetaDataEntry"
            label?: string | null
            systemName: string
            value?: string | null
            values: Array<string>
            users: Array<string>
          }>
          linkedOntologyNodes: Array<
            | {
                __typename: "GenericRef"
                target?:
                  | { __typename: "CommentNode" }
                  | { __typename: "ExternalSite" }
                  | { __typename: "ST4Class" }
                  | { __typename: "ST4Node"; id: string; label?: string | null }
                  | { __typename: "ST4TreeNode" }
                  | null
              }
            | {
                __typename: "NodeRef"
                linkClassName: string
                class?: { __typename: "ST4Class"; label?: string | null } | null
                target?:
                  | { __typename: "CommentNode" }
                  | { __typename: "ExternalSite" }
                  | { __typename: "ST4Class" }
                  | { __typename: "ST4Node"; id: string; label?: string | null }
                  | { __typename: "ST4TreeNode" }
                  | null
              }
          >
        }
      | { __typename: "ST4TreeNode"; id: string }
      | null
  }>
  lexiconEntries?: Array<{
    __typename: "NodeRef"
    id: string
    target?:
      | { __typename: "CommentNode"; id: string }
      | { __typename: "ExternalSite"; id: string }
      | { __typename: "ST4Class"; id: string }
      | {
          __typename: "ST4Node"
          aspectId: any
          label?: string | null
          id: string
          content?:
            | { __typename: "LexiconContent" }
            | {
                __typename: "LexiconEntryContent"
                descriptionXast?: string | null
                subject?: string | null
                sortKey?: string | null
                lexicon:
                  | { __typename: "CommentNode"; id: string }
                  | { __typename: "ExternalSite"; id: string }
                  | { __typename: "ST4Class"; id: string }
                  | { __typename: "ST4Node"; label?: string | null; id: string }
                  | { __typename: "ST4TreeNode"; id: string }
                terms: Array<
                  | { __typename: "CommentNode"; id: string }
                  | { __typename: "ExternalSite"; id: string }
                  | { __typename: "ST4Class"; id: string }
                  | {
                      __typename: "ST4Node"
                      id: string
                      label?: string | null
                      aspectId: any
                      content?:
                        | { __typename: "LexiconContent" }
                        | { __typename: "LexiconEntryContent" }
                        | { __typename: "MediaContent" }
                        | { __typename: "MediaGroupContent" }
                        | { __typename: "ProjectContent" }
                        | {
                            __typename: "TermContent"
                            gramaticalGender?: string | null
                            validity?: string | null
                            editLanguage?: string | null
                          }
                        | { __typename: "TextContent" }
                        | { __typename: "TextGroupContent" }
                        | { __typename: "VariableTableContent" }
                        | null
                    }
                  | { __typename: "ST4TreeNode"; id: string }
                >
                image?:
                  | { __typename: "CommentNode"; id: string }
                  | { __typename: "ExternalSite"; id: string }
                  | { __typename: "ST4Class"; id: string }
                  | {
                      __typename: "ST4Node"
                      aspectId: any
                      id: string
                      content?:
                        | { __typename: "LexiconContent" }
                        | { __typename: "LexiconEntryContent" }
                        | {
                            __typename: "MediaContent"
                            media?:
                              | {
                                  __typename: "AnimationMediaInfo"
                                  thumbnail?: string | null
                                  mediaType?: Types.MediaType | null
                                }
                              | {
                                  __typename: "ImageMediaInfo"
                                  src?: string | null
                                  thumbnail?: string | null
                                  mediaType?: Types.MediaType | null
                                }
                              | { __typename: "NoMediaInfo" }
                              | {
                                  __typename: "VectorGraphicsMediaInfo"
                                  svg?: string | null
                                  thumbnail?: string | null
                                  mediaType?: Types.MediaType | null
                                }
                              | {
                                  __typename: "VideoMediaInfo"
                                  thumbnail?: string | null
                                  mediaType?: Types.MediaType | null
                                }
                              | null
                          }
                        | { __typename: "MediaGroupContent" }
                        | { __typename: "ProjectContent" }
                        | { __typename: "TermContent" }
                        | { __typename: "TextContent" }
                        | { __typename: "TextGroupContent" }
                        | { __typename: "VariableTableContent" }
                        | null
                    }
                  | { __typename: "ST4TreeNode"; id: string }
                  | null
              }
            | { __typename: "MediaContent" }
            | { __typename: "MediaGroupContent" }
            | { __typename: "ProjectContent" }
            | { __typename: "TermContent" }
            | { __typename: "TextContent" }
            | { __typename: "TextGroupContent" }
            | { __typename: "VariableTableContent" }
            | null
        }
      | { __typename: "ST4TreeNode"; id: string }
      | null
  } | null> | null
  comments: Array<{
    __typename: "CommentNode"
    id: string
    comment:
      | {
          __typename: "BlockComment"
          location: number
          value?: string | null
          authorDisplayName?: string | null
          authorUserName?: string | null
          commentKey: string
          editDate?: any | null
          modificationVersion?: number | null
          replyToKey?: string | null
          sortOrder?: number | null
          commentType?: string | null
          state?: string | null
          color: string
          persistanceState?: Types.PersistanceState | null
          persistanceAction?: Types.PersistanceAction | null
          node: {
            __typename: "ST4Node"
            aspectId: any
            id: string
            content?:
              | { __typename: "LexiconContent" }
              | { __typename: "LexiconEntryContent" }
              | { __typename: "MediaContent" }
              | { __typename: "MediaGroupContent" }
              | { __typename: "ProjectContent" }
              | { __typename: "TermContent" }
              | { __typename: "TextContent"; id: string; historyNumber: number }
              | { __typename: "TextGroupContent"; id: string; historyNumber: number }
              | { __typename: "VariableTableContent" }
              | null
          }
          actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
        }
      | {
          __typename: "TextComment"
          endLocation: number
          startLocation?: number | null
          value?: string | null
          authorDisplayName?: string | null
          authorUserName?: string | null
          commentKey: string
          editDate?: any | null
          modificationVersion?: number | null
          replyToKey?: string | null
          sortOrder?: number | null
          commentType?: string | null
          state?: string | null
          color: string
          persistanceState?: Types.PersistanceState | null
          persistanceAction?: Types.PersistanceAction | null
          node: {
            __typename: "ST4Node"
            aspectId: any
            id: string
            content?:
              | { __typename: "LexiconContent" }
              | { __typename: "LexiconEntryContent" }
              | { __typename: "MediaContent" }
              | { __typename: "MediaGroupContent" }
              | { __typename: "ProjectContent" }
              | { __typename: "TermContent" }
              | { __typename: "TextContent"; id: string; historyNumber: number }
              | { __typename: "TextGroupContent"; id: string; historyNumber: number }
              | { __typename: "VariableTableContent" }
              | null
          }
          actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
        }
  }>
}

export type TextContentWithFragmentsFragment =
  | TextContentWithFragments_TextContent_Fragment
  | TextContentWithFragments_TextGroupContent_Fragment

export type PartsContentsFragment = {
  __typename: "TextContentPart"
  ref: string
  contentNode: {
    __typename: "ST4Node"
    id: string
    aspectId: any
    content?:
      | { __typename: "LexiconContent" }
      | { __typename: "LexiconEntryContent" }
      | { __typename: "MediaContent" }
      | { __typename: "MediaGroupContent" }
      | { __typename: "ProjectContent" }
      | { __typename: "TermContent" }
      | {
          __typename: "TextContent"
          id: string
          xast?: string | null
          historyNumber: number
          links?: Array<{
            __typename: "TextContentLink"
            ref?: string | null
            node?:
              | { __typename: "CommentNode"; id: string }
              | { __typename: "ExternalSite"; id: string }
              | { __typename: "ST4Class"; id: string }
              | {
                  __typename: "ST4Node"
                  id: string
                  aspectId: any
                  label?: string | null
                  properties?: {
                    __typename: "ContentPropertyConnection"
                    totalCount: number
                    edges?: Array<{
                      __typename: "ContentPropertyEdge"
                      node?: {
                        __typename: "ContentProperty"
                        typeOfValue: Types.ContentPropertyValueType
                        name: string
                        value?: any | null
                        displayName: string
                      } | null
                    } | null> | null
                  } | null
                }
              | { __typename: "ST4TreeNode"; id: string }
              | null
          } | null> | null
          images: Array<{
            __typename: "NodeRef"
            id: string
            target?:
              | { __typename: "CommentNode"; id: string }
              | { __typename: "ExternalSite"; id: string }
              | { __typename: "ST4Class"; id: string }
              | {
                  __typename: "ST4Node"
                  aspectId: any
                  id: string
                  releaseState: {
                    __typename: "ReleaseStateValue"
                    displayName: string
                    state: Types.ReleaseState
                    isImplicitlyReleased: boolean
                  }
                  content?:
                    | { __typename: "LexiconContent" }
                    | { __typename: "LexiconEntryContent" }
                    | {
                        __typename: "MediaContent"
                        media?:
                          | {
                              __typename: "AnimationMediaInfo"
                              id: string
                              resourceId?: string | null
                              thumbnail?: string | null
                              width?: number | null
                              height?: number | null
                              mediaType?: Types.MediaType | null
                            }
                          | {
                              __typename: "ImageMediaInfo"
                              id: string
                              resourceId?: string | null
                              thumbnail?: string | null
                              width?: number | null
                              height?: number | null
                              resolution?: number | null
                              colorDepth?: number | null
                              size?: number | null
                              mime?: string | null
                              extension?: string | null
                              allowCaching?: boolean | null
                              mediaType?: Types.MediaType | null
                              src?: string | null
                              language?: string | null
                            }
                          | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                          | {
                              __typename: "VectorGraphicsMediaInfo"
                              id: string
                              resourceId?: string | null
                              thumbnail?: string | null
                              width?: number | null
                              height?: number | null
                              allowCaching?: boolean | null
                              mediaType?: Types.MediaType | null
                              language?: string | null
                              labels: Array<{
                                __typename: "VectorGraphicsLabel"
                                id?: string | null
                                sequence?: number | null
                                autoValue?: string | null
                                text?: string | null
                                linkType?: Types.VectorGraphicsLabelLinkType | null
                                link?: string | null
                                ref?:
                                  | {
                                      __typename: "GenericRef"
                                      id: string
                                      owner?:
                                        | { __typename: "CommentNode" }
                                        | { __typename: "ExternalSite" }
                                        | { __typename: "ST4Class" }
                                        | { __typename: "ST4Node"; id: string }
                                        | { __typename: "ST4TreeNode" }
                                        | null
                                      target?:
                                        | { __typename: "CommentNode" }
                                        | {
                                            __typename: "ExternalSite"
                                            id: string
                                            teaserData?: {
                                              __typename: "ExternalLinkTeaserData"
                                              title?: string | null
                                              summary?: string | null
                                              iconUrl?: string | null
                                              imageUrl?: string | null
                                              status?: Types.LinkTargetStatus | null
                                            } | null
                                          }
                                        | { __typename: "ST4Class" }
                                        | {
                                            __typename: "ST4Node"
                                            id: string
                                            aspectId: any
                                            label?: string | null
                                            properties?: {
                                              __typename: "ContentPropertyConnection"
                                              edges?: Array<{
                                                __typename: "ContentPropertyEdge"
                                                node?: {
                                                  __typename: "ContentProperty"
                                                  name: string
                                                  value?: any | null
                                                  displayName: string
                                                } | null
                                              } | null> | null
                                            } | null
                                          }
                                        | { __typename: "ST4TreeNode" }
                                        | null
                                    }
                                  | {
                                      __typename: "NodeRef"
                                      id: string
                                      owner?:
                                        | { __typename: "CommentNode" }
                                        | { __typename: "ExternalSite" }
                                        | { __typename: "ST4Class" }
                                        | { __typename: "ST4Node"; id: string }
                                        | { __typename: "ST4TreeNode" }
                                        | null
                                      target?:
                                        | { __typename: "CommentNode" }
                                        | {
                                            __typename: "ExternalSite"
                                            id: string
                                            teaserData?: {
                                              __typename: "ExternalLinkTeaserData"
                                              title?: string | null
                                              summary?: string | null
                                              iconUrl?: string | null
                                              imageUrl?: string | null
                                              status?: Types.LinkTargetStatus | null
                                            } | null
                                          }
                                        | { __typename: "ST4Class" }
                                        | {
                                            __typename: "ST4Node"
                                            id: string
                                            aspectId: any
                                            label?: string | null
                                            properties?: {
                                              __typename: "ContentPropertyConnection"
                                              edges?: Array<{
                                                __typename: "ContentPropertyEdge"
                                                node?: {
                                                  __typename: "ContentProperty"
                                                  name: string
                                                  value?: any | null
                                                  displayName: string
                                                } | null
                                              } | null> | null
                                            } | null
                                          }
                                        | { __typename: "ST4TreeNode" }
                                        | null
                                    }
                                  | null
                              }>
                            }
                          | {
                              __typename: "VideoMediaInfo"
                              id: string
                              resourceId?: string | null
                              thumbnail?: string | null
                              width?: number | null
                              height?: number | null
                              mediaType?: Types.MediaType | null
                            }
                          | null
                      }
                    | {
                        __typename: "MediaGroupContent"
                        children: Array<{
                          __typename: "NodeRef"
                          id: string
                          target?:
                            | { __typename: "CommentNode"; id: string }
                            | { __typename: "ExternalSite"; id: string }
                            | { __typename: "ST4Class"; id: string }
                            | {
                                __typename: "ST4Node"
                                id: string
                                aspectId: any
                                content?:
                                  | { __typename: "LexiconContent" }
                                  | { __typename: "LexiconEntryContent" }
                                  | {
                                      __typename: "MediaContent"
                                      media?:
                                        | {
                                            __typename: "AnimationMediaInfo"
                                            id: string
                                            resourceId?: string | null
                                            thumbnail?: string | null
                                            width?: number | null
                                            height?: number | null
                                            mediaType?: Types.MediaType | null
                                          }
                                        | {
                                            __typename: "ImageMediaInfo"
                                            id: string
                                            resourceId?: string | null
                                            thumbnail?: string | null
                                            width?: number | null
                                            height?: number | null
                                            resolution?: number | null
                                            colorDepth?: number | null
                                            size?: number | null
                                            mime?: string | null
                                            extension?: string | null
                                            allowCaching?: boolean | null
                                            mediaType?: Types.MediaType | null
                                            src?: string | null
                                            language?: string | null
                                          }
                                        | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                                        | {
                                            __typename: "VectorGraphicsMediaInfo"
                                            id: string
                                            resourceId?: string | null
                                            thumbnail?: string | null
                                            width?: number | null
                                            height?: number | null
                                            allowCaching?: boolean | null
                                            mediaType?: Types.MediaType | null
                                            language?: string | null
                                            labels: Array<{
                                              __typename: "VectorGraphicsLabel"
                                              id?: string | null
                                              sequence?: number | null
                                              autoValue?: string | null
                                              text?: string | null
                                              linkType?: Types.VectorGraphicsLabelLinkType | null
                                              link?: string | null
                                              ref?:
                                                | {
                                                    __typename: "GenericRef"
                                                    id: string
                                                    owner?:
                                                      | { __typename: "CommentNode" }
                                                      | { __typename: "ExternalSite" }
                                                      | { __typename: "ST4Class" }
                                                      | { __typename: "ST4Node"; id: string }
                                                      | { __typename: "ST4TreeNode" }
                                                      | null
                                                    target?:
                                                      | { __typename: "CommentNode" }
                                                      | {
                                                          __typename: "ExternalSite"
                                                          id: string
                                                          teaserData?: {
                                                            __typename: "ExternalLinkTeaserData"
                                                            title?: string | null
                                                            summary?: string | null
                                                            iconUrl?: string | null
                                                            imageUrl?: string | null
                                                            status?: Types.LinkTargetStatus | null
                                                          } | null
                                                        }
                                                      | { __typename: "ST4Class" }
                                                      | {
                                                          __typename: "ST4Node"
                                                          id: string
                                                          aspectId: any
                                                          label?: string | null
                                                          properties?: {
                                                            __typename: "ContentPropertyConnection"
                                                            edges?: Array<{
                                                              __typename: "ContentPropertyEdge"
                                                              node?: {
                                                                __typename: "ContentProperty"
                                                                name: string
                                                                value?: any | null
                                                                displayName: string
                                                              } | null
                                                            } | null> | null
                                                          } | null
                                                        }
                                                      | { __typename: "ST4TreeNode" }
                                                      | null
                                                  }
                                                | {
                                                    __typename: "NodeRef"
                                                    id: string
                                                    owner?:
                                                      | { __typename: "CommentNode" }
                                                      | { __typename: "ExternalSite" }
                                                      | { __typename: "ST4Class" }
                                                      | { __typename: "ST4Node"; id: string }
                                                      | { __typename: "ST4TreeNode" }
                                                      | null
                                                    target?:
                                                      | { __typename: "CommentNode" }
                                                      | {
                                                          __typename: "ExternalSite"
                                                          id: string
                                                          teaserData?: {
                                                            __typename: "ExternalLinkTeaserData"
                                                            title?: string | null
                                                            summary?: string | null
                                                            iconUrl?: string | null
                                                            imageUrl?: string | null
                                                            status?: Types.LinkTargetStatus | null
                                                          } | null
                                                        }
                                                      | { __typename: "ST4Class" }
                                                      | {
                                                          __typename: "ST4Node"
                                                          id: string
                                                          aspectId: any
                                                          label?: string | null
                                                          properties?: {
                                                            __typename: "ContentPropertyConnection"
                                                            edges?: Array<{
                                                              __typename: "ContentPropertyEdge"
                                                              node?: {
                                                                __typename: "ContentProperty"
                                                                name: string
                                                                value?: any | null
                                                                displayName: string
                                                              } | null
                                                            } | null> | null
                                                          } | null
                                                        }
                                                      | { __typename: "ST4TreeNode" }
                                                      | null
                                                  }
                                                | null
                                            }>
                                          }
                                        | {
                                            __typename: "VideoMediaInfo"
                                            id: string
                                            resourceId?: string | null
                                            thumbnail?: string | null
                                            width?: number | null
                                            height?: number | null
                                            mediaType?: Types.MediaType | null
                                          }
                                        | null
                                    }
                                  | {
                                      __typename: "MediaGroupContent"
                                      media?:
                                        | {
                                            __typename: "AnimationMediaInfo"
                                            id: string
                                            resourceId?: string | null
                                            thumbnail?: string | null
                                            width?: number | null
                                            height?: number | null
                                            mediaType?: Types.MediaType | null
                                          }
                                        | {
                                            __typename: "ImageMediaInfo"
                                            id: string
                                            resourceId?: string | null
                                            thumbnail?: string | null
                                            width?: number | null
                                            height?: number | null
                                            resolution?: number | null
                                            colorDepth?: number | null
                                            size?: number | null
                                            mime?: string | null
                                            extension?: string | null
                                            allowCaching?: boolean | null
                                            mediaType?: Types.MediaType | null
                                            src?: string | null
                                            language?: string | null
                                          }
                                        | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                                        | {
                                            __typename: "VectorGraphicsMediaInfo"
                                            id: string
                                            resourceId?: string | null
                                            thumbnail?: string | null
                                            width?: number | null
                                            height?: number | null
                                            allowCaching?: boolean | null
                                            mediaType?: Types.MediaType | null
                                            language?: string | null
                                            labels: Array<{
                                              __typename: "VectorGraphicsLabel"
                                              id?: string | null
                                              sequence?: number | null
                                              autoValue?: string | null
                                              text?: string | null
                                              linkType?: Types.VectorGraphicsLabelLinkType | null
                                              link?: string | null
                                              ref?:
                                                | {
                                                    __typename: "GenericRef"
                                                    id: string
                                                    owner?:
                                                      | { __typename: "CommentNode" }
                                                      | { __typename: "ExternalSite" }
                                                      | { __typename: "ST4Class" }
                                                      | { __typename: "ST4Node"; id: string }
                                                      | { __typename: "ST4TreeNode" }
                                                      | null
                                                    target?:
                                                      | { __typename: "CommentNode" }
                                                      | {
                                                          __typename: "ExternalSite"
                                                          id: string
                                                          teaserData?: {
                                                            __typename: "ExternalLinkTeaserData"
                                                            title?: string | null
                                                            summary?: string | null
                                                            iconUrl?: string | null
                                                            imageUrl?: string | null
                                                            status?: Types.LinkTargetStatus | null
                                                          } | null
                                                        }
                                                      | { __typename: "ST4Class" }
                                                      | {
                                                          __typename: "ST4Node"
                                                          id: string
                                                          aspectId: any
                                                          label?: string | null
                                                          properties?: {
                                                            __typename: "ContentPropertyConnection"
                                                            edges?: Array<{
                                                              __typename: "ContentPropertyEdge"
                                                              node?: {
                                                                __typename: "ContentProperty"
                                                                name: string
                                                                value?: any | null
                                                                displayName: string
                                                              } | null
                                                            } | null> | null
                                                          } | null
                                                        }
                                                      | { __typename: "ST4TreeNode" }
                                                      | null
                                                  }
                                                | {
                                                    __typename: "NodeRef"
                                                    id: string
                                                    owner?:
                                                      | { __typename: "CommentNode" }
                                                      | { __typename: "ExternalSite" }
                                                      | { __typename: "ST4Class" }
                                                      | { __typename: "ST4Node"; id: string }
                                                      | { __typename: "ST4TreeNode" }
                                                      | null
                                                    target?:
                                                      | { __typename: "CommentNode" }
                                                      | {
                                                          __typename: "ExternalSite"
                                                          id: string
                                                          teaserData?: {
                                                            __typename: "ExternalLinkTeaserData"
                                                            title?: string | null
                                                            summary?: string | null
                                                            iconUrl?: string | null
                                                            imageUrl?: string | null
                                                            status?: Types.LinkTargetStatus | null
                                                          } | null
                                                        }
                                                      | { __typename: "ST4Class" }
                                                      | {
                                                          __typename: "ST4Node"
                                                          id: string
                                                          aspectId: any
                                                          label?: string | null
                                                          properties?: {
                                                            __typename: "ContentPropertyConnection"
                                                            edges?: Array<{
                                                              __typename: "ContentPropertyEdge"
                                                              node?: {
                                                                __typename: "ContentProperty"
                                                                name: string
                                                                value?: any | null
                                                                displayName: string
                                                              } | null
                                                            } | null> | null
                                                          } | null
                                                        }
                                                      | { __typename: "ST4TreeNode" }
                                                      | null
                                                  }
                                                | null
                                            }>
                                          }
                                        | {
                                            __typename: "VideoMediaInfo"
                                            id: string
                                            resourceId?: string | null
                                            thumbnail?: string | null
                                            width?: number | null
                                            height?: number | null
                                            mediaType?: Types.MediaType | null
                                          }
                                        | null
                                    }
                                  | { __typename: "ProjectContent" }
                                  | { __typename: "TermContent" }
                                  | { __typename: "TextContent" }
                                  | { __typename: "TextGroupContent" }
                                  | { __typename: "VariableTableContent" }
                                  | null
                              }
                            | { __typename: "ST4TreeNode"; id: string }
                            | null
                        }>
                        media?:
                          | {
                              __typename: "AnimationMediaInfo"
                              id: string
                              resourceId?: string | null
                              thumbnail?: string | null
                              width?: number | null
                              height?: number | null
                              mediaType?: Types.MediaType | null
                            }
                          | {
                              __typename: "ImageMediaInfo"
                              id: string
                              resourceId?: string | null
                              thumbnail?: string | null
                              width?: number | null
                              height?: number | null
                              resolution?: number | null
                              colorDepth?: number | null
                              size?: number | null
                              mime?: string | null
                              extension?: string | null
                              allowCaching?: boolean | null
                              mediaType?: Types.MediaType | null
                              src?: string | null
                              language?: string | null
                            }
                          | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                          | {
                              __typename: "VectorGraphicsMediaInfo"
                              id: string
                              resourceId?: string | null
                              thumbnail?: string | null
                              width?: number | null
                              height?: number | null
                              allowCaching?: boolean | null
                              mediaType?: Types.MediaType | null
                              language?: string | null
                              labels: Array<{
                                __typename: "VectorGraphicsLabel"
                                id?: string | null
                                sequence?: number | null
                                autoValue?: string | null
                                text?: string | null
                                linkType?: Types.VectorGraphicsLabelLinkType | null
                                link?: string | null
                                ref?:
                                  | {
                                      __typename: "GenericRef"
                                      id: string
                                      owner?:
                                        | { __typename: "CommentNode" }
                                        | { __typename: "ExternalSite" }
                                        | { __typename: "ST4Class" }
                                        | { __typename: "ST4Node"; id: string }
                                        | { __typename: "ST4TreeNode" }
                                        | null
                                      target?:
                                        | { __typename: "CommentNode" }
                                        | {
                                            __typename: "ExternalSite"
                                            id: string
                                            teaserData?: {
                                              __typename: "ExternalLinkTeaserData"
                                              title?: string | null
                                              summary?: string | null
                                              iconUrl?: string | null
                                              imageUrl?: string | null
                                              status?: Types.LinkTargetStatus | null
                                            } | null
                                          }
                                        | { __typename: "ST4Class" }
                                        | {
                                            __typename: "ST4Node"
                                            id: string
                                            aspectId: any
                                            label?: string | null
                                            properties?: {
                                              __typename: "ContentPropertyConnection"
                                              edges?: Array<{
                                                __typename: "ContentPropertyEdge"
                                                node?: {
                                                  __typename: "ContentProperty"
                                                  name: string
                                                  value?: any | null
                                                  displayName: string
                                                } | null
                                              } | null> | null
                                            } | null
                                          }
                                        | { __typename: "ST4TreeNode" }
                                        | null
                                    }
                                  | {
                                      __typename: "NodeRef"
                                      id: string
                                      owner?:
                                        | { __typename: "CommentNode" }
                                        | { __typename: "ExternalSite" }
                                        | { __typename: "ST4Class" }
                                        | { __typename: "ST4Node"; id: string }
                                        | { __typename: "ST4TreeNode" }
                                        | null
                                      target?:
                                        | { __typename: "CommentNode" }
                                        | {
                                            __typename: "ExternalSite"
                                            id: string
                                            teaserData?: {
                                              __typename: "ExternalLinkTeaserData"
                                              title?: string | null
                                              summary?: string | null
                                              iconUrl?: string | null
                                              imageUrl?: string | null
                                              status?: Types.LinkTargetStatus | null
                                            } | null
                                          }
                                        | { __typename: "ST4Class" }
                                        | {
                                            __typename: "ST4Node"
                                            id: string
                                            aspectId: any
                                            label?: string | null
                                            properties?: {
                                              __typename: "ContentPropertyConnection"
                                              edges?: Array<{
                                                __typename: "ContentPropertyEdge"
                                                node?: {
                                                  __typename: "ContentProperty"
                                                  name: string
                                                  value?: any | null
                                                  displayName: string
                                                } | null
                                              } | null> | null
                                            } | null
                                          }
                                        | { __typename: "ST4TreeNode" }
                                        | null
                                    }
                                  | null
                              }>
                            }
                          | {
                              __typename: "VideoMediaInfo"
                              id: string
                              resourceId?: string | null
                              thumbnail?: string | null
                              width?: number | null
                              height?: number | null
                              mediaType?: Types.MediaType | null
                            }
                          | null
                      }
                    | { __typename: "ProjectContent" }
                    | { __typename: "TermContent" }
                    | { __typename: "TextContent" }
                    | { __typename: "TextGroupContent" }
                    | { __typename: "VariableTableContent" }
                    | null
                }
              | { __typename: "ST4TreeNode"; id: string }
              | null
          }>
          variableTables?: Array<{
            __typename: "VariableTableReference"
            ref?: string | null
            node?:
              | { __typename: "CommentNode" }
              | { __typename: "ExternalSite" }
              | { __typename: "ST4Class" }
              | {
                  __typename: "ST4Node"
                  id: string
                  label?: string | null
                  aspectId: any
                  content?:
                    | { __typename: "LexiconContent" }
                    | { __typename: "LexiconEntryContent" }
                    | { __typename: "MediaContent" }
                    | { __typename: "MediaGroupContent" }
                    | { __typename: "ProjectContent" }
                    | { __typename: "TermContent" }
                    | { __typename: "TextContent" }
                    | { __typename: "TextGroupContent" }
                    | {
                        __typename: "VariableTableContent"
                        xml?: string | null
                        valueSets: Array<{ __typename: "ValueSet"; name: string; valueSetId: string }>
                        variables: Array<{
                          __typename: "Variable"
                          name: string
                          variableId: string
                          values: Array<string>
                        }>
                      }
                    | null
                }
              | { __typename: "ST4TreeNode" }
              | null
          } | null> | null
          linkedDataNodes: Array<{
            __typename: "NodeRef"
            id: string
            target?:
              | { __typename: "CommentNode"; id: string }
              | { __typename: "ExternalSite"; id: string }
              | { __typename: "ST4Class"; id: string }
              | {
                  __typename: "ST4Node"
                  label?: string | null
                  id: string
                  metadata: Array<{
                    __typename: "MetaDataEntry"
                    label?: string | null
                    systemName: string
                    value?: string | null
                    values: Array<string>
                    users: Array<string>
                  }>
                  linkedOntologyNodes: Array<
                    | {
                        __typename: "GenericRef"
                        target?:
                          | { __typename: "CommentNode" }
                          | { __typename: "ExternalSite" }
                          | { __typename: "ST4Class" }
                          | { __typename: "ST4Node"; id: string; label?: string | null }
                          | { __typename: "ST4TreeNode" }
                          | null
                      }
                    | {
                        __typename: "NodeRef"
                        linkClassName: string
                        class?: { __typename: "ST4Class"; label?: string | null } | null
                        target?:
                          | { __typename: "CommentNode" }
                          | { __typename: "ExternalSite" }
                          | { __typename: "ST4Class" }
                          | { __typename: "ST4Node"; id: string; label?: string | null }
                          | { __typename: "ST4TreeNode" }
                          | null
                      }
                  >
                }
              | { __typename: "ST4TreeNode"; id: string }
              | null
          }>
          lexiconEntries?: Array<{
            __typename: "NodeRef"
            id: string
            target?:
              | { __typename: "CommentNode"; id: string }
              | { __typename: "ExternalSite"; id: string }
              | { __typename: "ST4Class"; id: string }
              | {
                  __typename: "ST4Node"
                  aspectId: any
                  label?: string | null
                  id: string
                  content?:
                    | { __typename: "LexiconContent" }
                    | {
                        __typename: "LexiconEntryContent"
                        descriptionXast?: string | null
                        subject?: string | null
                        sortKey?: string | null
                        lexicon:
                          | { __typename: "CommentNode"; id: string }
                          | { __typename: "ExternalSite"; id: string }
                          | { __typename: "ST4Class"; id: string }
                          | { __typename: "ST4Node"; label?: string | null; id: string }
                          | { __typename: "ST4TreeNode"; id: string }
                        terms: Array<
                          | { __typename: "CommentNode"; id: string }
                          | { __typename: "ExternalSite"; id: string }
                          | { __typename: "ST4Class"; id: string }
                          | {
                              __typename: "ST4Node"
                              id: string
                              label?: string | null
                              aspectId: any
                              content?:
                                | { __typename: "LexiconContent" }
                                | { __typename: "LexiconEntryContent" }
                                | { __typename: "MediaContent" }
                                | { __typename: "MediaGroupContent" }
                                | { __typename: "ProjectContent" }
                                | {
                                    __typename: "TermContent"
                                    gramaticalGender?: string | null
                                    validity?: string | null
                                    editLanguage?: string | null
                                  }
                                | { __typename: "TextContent" }
                                | { __typename: "TextGroupContent" }
                                | { __typename: "VariableTableContent" }
                                | null
                            }
                          | { __typename: "ST4TreeNode"; id: string }
                        >
                        image?:
                          | { __typename: "CommentNode"; id: string }
                          | { __typename: "ExternalSite"; id: string }
                          | { __typename: "ST4Class"; id: string }
                          | {
                              __typename: "ST4Node"
                              aspectId: any
                              id: string
                              content?:
                                | { __typename: "LexiconContent" }
                                | { __typename: "LexiconEntryContent" }
                                | {
                                    __typename: "MediaContent"
                                    media?:
                                      | {
                                          __typename: "AnimationMediaInfo"
                                          thumbnail?: string | null
                                          mediaType?: Types.MediaType | null
                                        }
                                      | {
                                          __typename: "ImageMediaInfo"
                                          src?: string | null
                                          thumbnail?: string | null
                                          mediaType?: Types.MediaType | null
                                        }
                                      | { __typename: "NoMediaInfo" }
                                      | {
                                          __typename: "VectorGraphicsMediaInfo"
                                          svg?: string | null
                                          thumbnail?: string | null
                                          mediaType?: Types.MediaType | null
                                        }
                                      | {
                                          __typename: "VideoMediaInfo"
                                          thumbnail?: string | null
                                          mediaType?: Types.MediaType | null
                                        }
                                      | null
                                  }
                                | { __typename: "MediaGroupContent" }
                                | { __typename: "ProjectContent" }
                                | { __typename: "TermContent" }
                                | { __typename: "TextContent" }
                                | { __typename: "TextGroupContent" }
                                | { __typename: "VariableTableContent" }
                                | null
                            }
                          | { __typename: "ST4TreeNode"; id: string }
                          | null
                      }
                    | { __typename: "MediaContent" }
                    | { __typename: "MediaGroupContent" }
                    | { __typename: "ProjectContent" }
                    | { __typename: "TermContent" }
                    | { __typename: "TextContent" }
                    | { __typename: "TextGroupContent" }
                    | { __typename: "VariableTableContent" }
                    | null
                }
              | { __typename: "ST4TreeNode"; id: string }
              | null
          } | null> | null
          comments: Array<{
            __typename: "CommentNode"
            id: string
            comment:
              | {
                  __typename: "BlockComment"
                  location: number
                  value?: string | null
                  authorDisplayName?: string | null
                  authorUserName?: string | null
                  commentKey: string
                  editDate?: any | null
                  modificationVersion?: number | null
                  replyToKey?: string | null
                  sortOrder?: number | null
                  commentType?: string | null
                  state?: string | null
                  color: string
                  persistanceState?: Types.PersistanceState | null
                  persistanceAction?: Types.PersistanceAction | null
                  node: {
                    __typename: "ST4Node"
                    aspectId: any
                    id: string
                    content?:
                      | { __typename: "LexiconContent" }
                      | { __typename: "LexiconEntryContent" }
                      | { __typename: "MediaContent" }
                      | { __typename: "MediaGroupContent" }
                      | { __typename: "ProjectContent" }
                      | { __typename: "TermContent" }
                      | { __typename: "TextContent"; id: string; historyNumber: number }
                      | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                      | { __typename: "VariableTableContent" }
                      | null
                  }
                  actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
                }
              | {
                  __typename: "TextComment"
                  endLocation: number
                  startLocation?: number | null
                  value?: string | null
                  authorDisplayName?: string | null
                  authorUserName?: string | null
                  commentKey: string
                  editDate?: any | null
                  modificationVersion?: number | null
                  replyToKey?: string | null
                  sortOrder?: number | null
                  commentType?: string | null
                  state?: string | null
                  color: string
                  persistanceState?: Types.PersistanceState | null
                  persistanceAction?: Types.PersistanceAction | null
                  node: {
                    __typename: "ST4Node"
                    aspectId: any
                    id: string
                    content?:
                      | { __typename: "LexiconContent" }
                      | { __typename: "LexiconEntryContent" }
                      | { __typename: "MediaContent" }
                      | { __typename: "MediaGroupContent" }
                      | { __typename: "ProjectContent" }
                      | { __typename: "TermContent" }
                      | { __typename: "TextContent"; id: string; historyNumber: number }
                      | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                      | { __typename: "VariableTableContent" }
                      | null
                  }
                  actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
                }
          }>
        }
      | {
          __typename: "TextGroupContent"
          currentNodeId?: any | null
          groupNodeId: any
          id: string
          xast?: string | null
          historyNumber: number
          children: Array<{
            __typename: "NodeRef"
            id: string
            target?:
              | { __typename: "CommentNode"; id: string }
              | { __typename: "ExternalSite"; id: string }
              | { __typename: "ST4Class"; id: string }
              | { __typename: "ST4Node"; label?: string | null; aspectId: any; id: string }
              | { __typename: "ST4TreeNode"; id: string }
              | null
          }>
          links?: Array<{
            __typename: "TextContentLink"
            ref?: string | null
            node?:
              | { __typename: "CommentNode"; id: string }
              | { __typename: "ExternalSite"; id: string }
              | { __typename: "ST4Class"; id: string }
              | {
                  __typename: "ST4Node"
                  id: string
                  aspectId: any
                  label?: string | null
                  properties?: {
                    __typename: "ContentPropertyConnection"
                    totalCount: number
                    edges?: Array<{
                      __typename: "ContentPropertyEdge"
                      node?: {
                        __typename: "ContentProperty"
                        typeOfValue: Types.ContentPropertyValueType
                        name: string
                        value?: any | null
                        displayName: string
                      } | null
                    } | null> | null
                  } | null
                }
              | { __typename: "ST4TreeNode"; id: string }
              | null
          } | null> | null
          images: Array<{
            __typename: "NodeRef"
            id: string
            target?:
              | { __typename: "CommentNode"; id: string }
              | { __typename: "ExternalSite"; id: string }
              | { __typename: "ST4Class"; id: string }
              | {
                  __typename: "ST4Node"
                  aspectId: any
                  id: string
                  releaseState: {
                    __typename: "ReleaseStateValue"
                    displayName: string
                    state: Types.ReleaseState
                    isImplicitlyReleased: boolean
                  }
                  content?:
                    | { __typename: "LexiconContent" }
                    | { __typename: "LexiconEntryContent" }
                    | {
                        __typename: "MediaContent"
                        media?:
                          | {
                              __typename: "AnimationMediaInfo"
                              id: string
                              resourceId?: string | null
                              thumbnail?: string | null
                              width?: number | null
                              height?: number | null
                              mediaType?: Types.MediaType | null
                            }
                          | {
                              __typename: "ImageMediaInfo"
                              id: string
                              resourceId?: string | null
                              thumbnail?: string | null
                              width?: number | null
                              height?: number | null
                              resolution?: number | null
                              colorDepth?: number | null
                              size?: number | null
                              mime?: string | null
                              extension?: string | null
                              allowCaching?: boolean | null
                              mediaType?: Types.MediaType | null
                              src?: string | null
                              language?: string | null
                            }
                          | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                          | {
                              __typename: "VectorGraphicsMediaInfo"
                              id: string
                              resourceId?: string | null
                              thumbnail?: string | null
                              width?: number | null
                              height?: number | null
                              allowCaching?: boolean | null
                              mediaType?: Types.MediaType | null
                              language?: string | null
                              labels: Array<{
                                __typename: "VectorGraphicsLabel"
                                id?: string | null
                                sequence?: number | null
                                autoValue?: string | null
                                text?: string | null
                                linkType?: Types.VectorGraphicsLabelLinkType | null
                                link?: string | null
                                ref?:
                                  | {
                                      __typename: "GenericRef"
                                      id: string
                                      owner?:
                                        | { __typename: "CommentNode" }
                                        | { __typename: "ExternalSite" }
                                        | { __typename: "ST4Class" }
                                        | { __typename: "ST4Node"; id: string }
                                        | { __typename: "ST4TreeNode" }
                                        | null
                                      target?:
                                        | { __typename: "CommentNode" }
                                        | {
                                            __typename: "ExternalSite"
                                            id: string
                                            teaserData?: {
                                              __typename: "ExternalLinkTeaserData"
                                              title?: string | null
                                              summary?: string | null
                                              iconUrl?: string | null
                                              imageUrl?: string | null
                                              status?: Types.LinkTargetStatus | null
                                            } | null
                                          }
                                        | { __typename: "ST4Class" }
                                        | {
                                            __typename: "ST4Node"
                                            id: string
                                            aspectId: any
                                            label?: string | null
                                            properties?: {
                                              __typename: "ContentPropertyConnection"
                                              edges?: Array<{
                                                __typename: "ContentPropertyEdge"
                                                node?: {
                                                  __typename: "ContentProperty"
                                                  name: string
                                                  value?: any | null
                                                  displayName: string
                                                } | null
                                              } | null> | null
                                            } | null
                                          }
                                        | { __typename: "ST4TreeNode" }
                                        | null
                                    }
                                  | {
                                      __typename: "NodeRef"
                                      id: string
                                      owner?:
                                        | { __typename: "CommentNode" }
                                        | { __typename: "ExternalSite" }
                                        | { __typename: "ST4Class" }
                                        | { __typename: "ST4Node"; id: string }
                                        | { __typename: "ST4TreeNode" }
                                        | null
                                      target?:
                                        | { __typename: "CommentNode" }
                                        | {
                                            __typename: "ExternalSite"
                                            id: string
                                            teaserData?: {
                                              __typename: "ExternalLinkTeaserData"
                                              title?: string | null
                                              summary?: string | null
                                              iconUrl?: string | null
                                              imageUrl?: string | null
                                              status?: Types.LinkTargetStatus | null
                                            } | null
                                          }
                                        | { __typename: "ST4Class" }
                                        | {
                                            __typename: "ST4Node"
                                            id: string
                                            aspectId: any
                                            label?: string | null
                                            properties?: {
                                              __typename: "ContentPropertyConnection"
                                              edges?: Array<{
                                                __typename: "ContentPropertyEdge"
                                                node?: {
                                                  __typename: "ContentProperty"
                                                  name: string
                                                  value?: any | null
                                                  displayName: string
                                                } | null
                                              } | null> | null
                                            } | null
                                          }
                                        | { __typename: "ST4TreeNode" }
                                        | null
                                    }
                                  | null
                              }>
                            }
                          | {
                              __typename: "VideoMediaInfo"
                              id: string
                              resourceId?: string | null
                              thumbnail?: string | null
                              width?: number | null
                              height?: number | null
                              mediaType?: Types.MediaType | null
                            }
                          | null
                      }
                    | {
                        __typename: "MediaGroupContent"
                        children: Array<{
                          __typename: "NodeRef"
                          id: string
                          target?:
                            | { __typename: "CommentNode"; id: string }
                            | { __typename: "ExternalSite"; id: string }
                            | { __typename: "ST4Class"; id: string }
                            | {
                                __typename: "ST4Node"
                                id: string
                                aspectId: any
                                content?:
                                  | { __typename: "LexiconContent" }
                                  | { __typename: "LexiconEntryContent" }
                                  | {
                                      __typename: "MediaContent"
                                      media?:
                                        | {
                                            __typename: "AnimationMediaInfo"
                                            id: string
                                            resourceId?: string | null
                                            thumbnail?: string | null
                                            width?: number | null
                                            height?: number | null
                                            mediaType?: Types.MediaType | null
                                          }
                                        | {
                                            __typename: "ImageMediaInfo"
                                            id: string
                                            resourceId?: string | null
                                            thumbnail?: string | null
                                            width?: number | null
                                            height?: number | null
                                            resolution?: number | null
                                            colorDepth?: number | null
                                            size?: number | null
                                            mime?: string | null
                                            extension?: string | null
                                            allowCaching?: boolean | null
                                            mediaType?: Types.MediaType | null
                                            src?: string | null
                                            language?: string | null
                                          }
                                        | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                                        | {
                                            __typename: "VectorGraphicsMediaInfo"
                                            id: string
                                            resourceId?: string | null
                                            thumbnail?: string | null
                                            width?: number | null
                                            height?: number | null
                                            allowCaching?: boolean | null
                                            mediaType?: Types.MediaType | null
                                            language?: string | null
                                            labels: Array<{
                                              __typename: "VectorGraphicsLabel"
                                              id?: string | null
                                              sequence?: number | null
                                              autoValue?: string | null
                                              text?: string | null
                                              linkType?: Types.VectorGraphicsLabelLinkType | null
                                              link?: string | null
                                              ref?:
                                                | {
                                                    __typename: "GenericRef"
                                                    id: string
                                                    owner?:
                                                      | { __typename: "CommentNode" }
                                                      | { __typename: "ExternalSite" }
                                                      | { __typename: "ST4Class" }
                                                      | { __typename: "ST4Node"; id: string }
                                                      | { __typename: "ST4TreeNode" }
                                                      | null
                                                    target?:
                                                      | { __typename: "CommentNode" }
                                                      | {
                                                          __typename: "ExternalSite"
                                                          id: string
                                                          teaserData?: {
                                                            __typename: "ExternalLinkTeaserData"
                                                            title?: string | null
                                                            summary?: string | null
                                                            iconUrl?: string | null
                                                            imageUrl?: string | null
                                                            status?: Types.LinkTargetStatus | null
                                                          } | null
                                                        }
                                                      | { __typename: "ST4Class" }
                                                      | {
                                                          __typename: "ST4Node"
                                                          id: string
                                                          aspectId: any
                                                          label?: string | null
                                                          properties?: {
                                                            __typename: "ContentPropertyConnection"
                                                            edges?: Array<{
                                                              __typename: "ContentPropertyEdge"
                                                              node?: {
                                                                __typename: "ContentProperty"
                                                                name: string
                                                                value?: any | null
                                                                displayName: string
                                                              } | null
                                                            } | null> | null
                                                          } | null
                                                        }
                                                      | { __typename: "ST4TreeNode" }
                                                      | null
                                                  }
                                                | {
                                                    __typename: "NodeRef"
                                                    id: string
                                                    owner?:
                                                      | { __typename: "CommentNode" }
                                                      | { __typename: "ExternalSite" }
                                                      | { __typename: "ST4Class" }
                                                      | { __typename: "ST4Node"; id: string }
                                                      | { __typename: "ST4TreeNode" }
                                                      | null
                                                    target?:
                                                      | { __typename: "CommentNode" }
                                                      | {
                                                          __typename: "ExternalSite"
                                                          id: string
                                                          teaserData?: {
                                                            __typename: "ExternalLinkTeaserData"
                                                            title?: string | null
                                                            summary?: string | null
                                                            iconUrl?: string | null
                                                            imageUrl?: string | null
                                                            status?: Types.LinkTargetStatus | null
                                                          } | null
                                                        }
                                                      | { __typename: "ST4Class" }
                                                      | {
                                                          __typename: "ST4Node"
                                                          id: string
                                                          aspectId: any
                                                          label?: string | null
                                                          properties?: {
                                                            __typename: "ContentPropertyConnection"
                                                            edges?: Array<{
                                                              __typename: "ContentPropertyEdge"
                                                              node?: {
                                                                __typename: "ContentProperty"
                                                                name: string
                                                                value?: any | null
                                                                displayName: string
                                                              } | null
                                                            } | null> | null
                                                          } | null
                                                        }
                                                      | { __typename: "ST4TreeNode" }
                                                      | null
                                                  }
                                                | null
                                            }>
                                          }
                                        | {
                                            __typename: "VideoMediaInfo"
                                            id: string
                                            resourceId?: string | null
                                            thumbnail?: string | null
                                            width?: number | null
                                            height?: number | null
                                            mediaType?: Types.MediaType | null
                                          }
                                        | null
                                    }
                                  | {
                                      __typename: "MediaGroupContent"
                                      media?:
                                        | {
                                            __typename: "AnimationMediaInfo"
                                            id: string
                                            resourceId?: string | null
                                            thumbnail?: string | null
                                            width?: number | null
                                            height?: number | null
                                            mediaType?: Types.MediaType | null
                                          }
                                        | {
                                            __typename: "ImageMediaInfo"
                                            id: string
                                            resourceId?: string | null
                                            thumbnail?: string | null
                                            width?: number | null
                                            height?: number | null
                                            resolution?: number | null
                                            colorDepth?: number | null
                                            size?: number | null
                                            mime?: string | null
                                            extension?: string | null
                                            allowCaching?: boolean | null
                                            mediaType?: Types.MediaType | null
                                            src?: string | null
                                            language?: string | null
                                          }
                                        | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                                        | {
                                            __typename: "VectorGraphicsMediaInfo"
                                            id: string
                                            resourceId?: string | null
                                            thumbnail?: string | null
                                            width?: number | null
                                            height?: number | null
                                            allowCaching?: boolean | null
                                            mediaType?: Types.MediaType | null
                                            language?: string | null
                                            labels: Array<{
                                              __typename: "VectorGraphicsLabel"
                                              id?: string | null
                                              sequence?: number | null
                                              autoValue?: string | null
                                              text?: string | null
                                              linkType?: Types.VectorGraphicsLabelLinkType | null
                                              link?: string | null
                                              ref?:
                                                | {
                                                    __typename: "GenericRef"
                                                    id: string
                                                    owner?:
                                                      | { __typename: "CommentNode" }
                                                      | { __typename: "ExternalSite" }
                                                      | { __typename: "ST4Class" }
                                                      | { __typename: "ST4Node"; id: string }
                                                      | { __typename: "ST4TreeNode" }
                                                      | null
                                                    target?:
                                                      | { __typename: "CommentNode" }
                                                      | {
                                                          __typename: "ExternalSite"
                                                          id: string
                                                          teaserData?: {
                                                            __typename: "ExternalLinkTeaserData"
                                                            title?: string | null
                                                            summary?: string | null
                                                            iconUrl?: string | null
                                                            imageUrl?: string | null
                                                            status?: Types.LinkTargetStatus | null
                                                          } | null
                                                        }
                                                      | { __typename: "ST4Class" }
                                                      | {
                                                          __typename: "ST4Node"
                                                          id: string
                                                          aspectId: any
                                                          label?: string | null
                                                          properties?: {
                                                            __typename: "ContentPropertyConnection"
                                                            edges?: Array<{
                                                              __typename: "ContentPropertyEdge"
                                                              node?: {
                                                                __typename: "ContentProperty"
                                                                name: string
                                                                value?: any | null
                                                                displayName: string
                                                              } | null
                                                            } | null> | null
                                                          } | null
                                                        }
                                                      | { __typename: "ST4TreeNode" }
                                                      | null
                                                  }
                                                | {
                                                    __typename: "NodeRef"
                                                    id: string
                                                    owner?:
                                                      | { __typename: "CommentNode" }
                                                      | { __typename: "ExternalSite" }
                                                      | { __typename: "ST4Class" }
                                                      | { __typename: "ST4Node"; id: string }
                                                      | { __typename: "ST4TreeNode" }
                                                      | null
                                                    target?:
                                                      | { __typename: "CommentNode" }
                                                      | {
                                                          __typename: "ExternalSite"
                                                          id: string
                                                          teaserData?: {
                                                            __typename: "ExternalLinkTeaserData"
                                                            title?: string | null
                                                            summary?: string | null
                                                            iconUrl?: string | null
                                                            imageUrl?: string | null
                                                            status?: Types.LinkTargetStatus | null
                                                          } | null
                                                        }
                                                      | { __typename: "ST4Class" }
                                                      | {
                                                          __typename: "ST4Node"
                                                          id: string
                                                          aspectId: any
                                                          label?: string | null
                                                          properties?: {
                                                            __typename: "ContentPropertyConnection"
                                                            edges?: Array<{
                                                              __typename: "ContentPropertyEdge"
                                                              node?: {
                                                                __typename: "ContentProperty"
                                                                name: string
                                                                value?: any | null
                                                                displayName: string
                                                              } | null
                                                            } | null> | null
                                                          } | null
                                                        }
                                                      | { __typename: "ST4TreeNode" }
                                                      | null
                                                  }
                                                | null
                                            }>
                                          }
                                        | {
                                            __typename: "VideoMediaInfo"
                                            id: string
                                            resourceId?: string | null
                                            thumbnail?: string | null
                                            width?: number | null
                                            height?: number | null
                                            mediaType?: Types.MediaType | null
                                          }
                                        | null
                                    }
                                  | { __typename: "ProjectContent" }
                                  | { __typename: "TermContent" }
                                  | { __typename: "TextContent" }
                                  | { __typename: "TextGroupContent" }
                                  | { __typename: "VariableTableContent" }
                                  | null
                              }
                            | { __typename: "ST4TreeNode"; id: string }
                            | null
                        }>
                        media?:
                          | {
                              __typename: "AnimationMediaInfo"
                              id: string
                              resourceId?: string | null
                              thumbnail?: string | null
                              width?: number | null
                              height?: number | null
                              mediaType?: Types.MediaType | null
                            }
                          | {
                              __typename: "ImageMediaInfo"
                              id: string
                              resourceId?: string | null
                              thumbnail?: string | null
                              width?: number | null
                              height?: number | null
                              resolution?: number | null
                              colorDepth?: number | null
                              size?: number | null
                              mime?: string | null
                              extension?: string | null
                              allowCaching?: boolean | null
                              mediaType?: Types.MediaType | null
                              src?: string | null
                              language?: string | null
                            }
                          | { __typename: "NoMediaInfo"; id: string; mediaType?: Types.MediaType | null }
                          | {
                              __typename: "VectorGraphicsMediaInfo"
                              id: string
                              resourceId?: string | null
                              thumbnail?: string | null
                              width?: number | null
                              height?: number | null
                              allowCaching?: boolean | null
                              mediaType?: Types.MediaType | null
                              language?: string | null
                              labels: Array<{
                                __typename: "VectorGraphicsLabel"
                                id?: string | null
                                sequence?: number | null
                                autoValue?: string | null
                                text?: string | null
                                linkType?: Types.VectorGraphicsLabelLinkType | null
                                link?: string | null
                                ref?:
                                  | {
                                      __typename: "GenericRef"
                                      id: string
                                      owner?:
                                        | { __typename: "CommentNode" }
                                        | { __typename: "ExternalSite" }
                                        | { __typename: "ST4Class" }
                                        | { __typename: "ST4Node"; id: string }
                                        | { __typename: "ST4TreeNode" }
                                        | null
                                      target?:
                                        | { __typename: "CommentNode" }
                                        | {
                                            __typename: "ExternalSite"
                                            id: string
                                            teaserData?: {
                                              __typename: "ExternalLinkTeaserData"
                                              title?: string | null
                                              summary?: string | null
                                              iconUrl?: string | null
                                              imageUrl?: string | null
                                              status?: Types.LinkTargetStatus | null
                                            } | null
                                          }
                                        | { __typename: "ST4Class" }
                                        | {
                                            __typename: "ST4Node"
                                            id: string
                                            aspectId: any
                                            label?: string | null
                                            properties?: {
                                              __typename: "ContentPropertyConnection"
                                              edges?: Array<{
                                                __typename: "ContentPropertyEdge"
                                                node?: {
                                                  __typename: "ContentProperty"
                                                  name: string
                                                  value?: any | null
                                                  displayName: string
                                                } | null
                                              } | null> | null
                                            } | null
                                          }
                                        | { __typename: "ST4TreeNode" }
                                        | null
                                    }
                                  | {
                                      __typename: "NodeRef"
                                      id: string
                                      owner?:
                                        | { __typename: "CommentNode" }
                                        | { __typename: "ExternalSite" }
                                        | { __typename: "ST4Class" }
                                        | { __typename: "ST4Node"; id: string }
                                        | { __typename: "ST4TreeNode" }
                                        | null
                                      target?:
                                        | { __typename: "CommentNode" }
                                        | {
                                            __typename: "ExternalSite"
                                            id: string
                                            teaserData?: {
                                              __typename: "ExternalLinkTeaserData"
                                              title?: string | null
                                              summary?: string | null
                                              iconUrl?: string | null
                                              imageUrl?: string | null
                                              status?: Types.LinkTargetStatus | null
                                            } | null
                                          }
                                        | { __typename: "ST4Class" }
                                        | {
                                            __typename: "ST4Node"
                                            id: string
                                            aspectId: any
                                            label?: string | null
                                            properties?: {
                                              __typename: "ContentPropertyConnection"
                                              edges?: Array<{
                                                __typename: "ContentPropertyEdge"
                                                node?: {
                                                  __typename: "ContentProperty"
                                                  name: string
                                                  value?: any | null
                                                  displayName: string
                                                } | null
                                              } | null> | null
                                            } | null
                                          }
                                        | { __typename: "ST4TreeNode" }
                                        | null
                                    }
                                  | null
                              }>
                            }
                          | {
                              __typename: "VideoMediaInfo"
                              id: string
                              resourceId?: string | null
                              thumbnail?: string | null
                              width?: number | null
                              height?: number | null
                              mediaType?: Types.MediaType | null
                            }
                          | null
                      }
                    | { __typename: "ProjectContent" }
                    | { __typename: "TermContent" }
                    | { __typename: "TextContent" }
                    | { __typename: "TextGroupContent" }
                    | { __typename: "VariableTableContent" }
                    | null
                }
              | { __typename: "ST4TreeNode"; id: string }
              | null
          }>
          variableTables?: Array<{
            __typename: "VariableTableReference"
            ref?: string | null
            node?:
              | { __typename: "CommentNode" }
              | { __typename: "ExternalSite" }
              | { __typename: "ST4Class" }
              | {
                  __typename: "ST4Node"
                  id: string
                  label?: string | null
                  aspectId: any
                  content?:
                    | { __typename: "LexiconContent" }
                    | { __typename: "LexiconEntryContent" }
                    | { __typename: "MediaContent" }
                    | { __typename: "MediaGroupContent" }
                    | { __typename: "ProjectContent" }
                    | { __typename: "TermContent" }
                    | { __typename: "TextContent" }
                    | { __typename: "TextGroupContent" }
                    | {
                        __typename: "VariableTableContent"
                        xml?: string | null
                        valueSets: Array<{ __typename: "ValueSet"; name: string; valueSetId: string }>
                        variables: Array<{
                          __typename: "Variable"
                          name: string
                          variableId: string
                          values: Array<string>
                        }>
                      }
                    | null
                }
              | { __typename: "ST4TreeNode" }
              | null
          } | null> | null
          linkedDataNodes: Array<{
            __typename: "NodeRef"
            id: string
            target?:
              | { __typename: "CommentNode"; id: string }
              | { __typename: "ExternalSite"; id: string }
              | { __typename: "ST4Class"; id: string }
              | {
                  __typename: "ST4Node"
                  label?: string | null
                  id: string
                  metadata: Array<{
                    __typename: "MetaDataEntry"
                    label?: string | null
                    systemName: string
                    value?: string | null
                    values: Array<string>
                    users: Array<string>
                  }>
                  linkedOntologyNodes: Array<
                    | {
                        __typename: "GenericRef"
                        target?:
                          | { __typename: "CommentNode" }
                          | { __typename: "ExternalSite" }
                          | { __typename: "ST4Class" }
                          | { __typename: "ST4Node"; id: string; label?: string | null }
                          | { __typename: "ST4TreeNode" }
                          | null
                      }
                    | {
                        __typename: "NodeRef"
                        linkClassName: string
                        class?: { __typename: "ST4Class"; label?: string | null } | null
                        target?:
                          | { __typename: "CommentNode" }
                          | { __typename: "ExternalSite" }
                          | { __typename: "ST4Class" }
                          | { __typename: "ST4Node"; id: string; label?: string | null }
                          | { __typename: "ST4TreeNode" }
                          | null
                      }
                  >
                }
              | { __typename: "ST4TreeNode"; id: string }
              | null
          }>
          lexiconEntries?: Array<{
            __typename: "NodeRef"
            id: string
            target?:
              | { __typename: "CommentNode"; id: string }
              | { __typename: "ExternalSite"; id: string }
              | { __typename: "ST4Class"; id: string }
              | {
                  __typename: "ST4Node"
                  aspectId: any
                  label?: string | null
                  id: string
                  content?:
                    | { __typename: "LexiconContent" }
                    | {
                        __typename: "LexiconEntryContent"
                        descriptionXast?: string | null
                        subject?: string | null
                        sortKey?: string | null
                        lexicon:
                          | { __typename: "CommentNode"; id: string }
                          | { __typename: "ExternalSite"; id: string }
                          | { __typename: "ST4Class"; id: string }
                          | { __typename: "ST4Node"; label?: string | null; id: string }
                          | { __typename: "ST4TreeNode"; id: string }
                        terms: Array<
                          | { __typename: "CommentNode"; id: string }
                          | { __typename: "ExternalSite"; id: string }
                          | { __typename: "ST4Class"; id: string }
                          | {
                              __typename: "ST4Node"
                              id: string
                              label?: string | null
                              aspectId: any
                              content?:
                                | { __typename: "LexiconContent" }
                                | { __typename: "LexiconEntryContent" }
                                | { __typename: "MediaContent" }
                                | { __typename: "MediaGroupContent" }
                                | { __typename: "ProjectContent" }
                                | {
                                    __typename: "TermContent"
                                    gramaticalGender?: string | null
                                    validity?: string | null
                                    editLanguage?: string | null
                                  }
                                | { __typename: "TextContent" }
                                | { __typename: "TextGroupContent" }
                                | { __typename: "VariableTableContent" }
                                | null
                            }
                          | { __typename: "ST4TreeNode"; id: string }
                        >
                        image?:
                          | { __typename: "CommentNode"; id: string }
                          | { __typename: "ExternalSite"; id: string }
                          | { __typename: "ST4Class"; id: string }
                          | {
                              __typename: "ST4Node"
                              aspectId: any
                              id: string
                              content?:
                                | { __typename: "LexiconContent" }
                                | { __typename: "LexiconEntryContent" }
                                | {
                                    __typename: "MediaContent"
                                    media?:
                                      | {
                                          __typename: "AnimationMediaInfo"
                                          thumbnail?: string | null
                                          mediaType?: Types.MediaType | null
                                        }
                                      | {
                                          __typename: "ImageMediaInfo"
                                          src?: string | null
                                          thumbnail?: string | null
                                          mediaType?: Types.MediaType | null
                                        }
                                      | { __typename: "NoMediaInfo" }
                                      | {
                                          __typename: "VectorGraphicsMediaInfo"
                                          svg?: string | null
                                          thumbnail?: string | null
                                          mediaType?: Types.MediaType | null
                                        }
                                      | {
                                          __typename: "VideoMediaInfo"
                                          thumbnail?: string | null
                                          mediaType?: Types.MediaType | null
                                        }
                                      | null
                                  }
                                | { __typename: "MediaGroupContent" }
                                | { __typename: "ProjectContent" }
                                | { __typename: "TermContent" }
                                | { __typename: "TextContent" }
                                | { __typename: "TextGroupContent" }
                                | { __typename: "VariableTableContent" }
                                | null
                            }
                          | { __typename: "ST4TreeNode"; id: string }
                          | null
                      }
                    | { __typename: "MediaContent" }
                    | { __typename: "MediaGroupContent" }
                    | { __typename: "ProjectContent" }
                    | { __typename: "TermContent" }
                    | { __typename: "TextContent" }
                    | { __typename: "TextGroupContent" }
                    | { __typename: "VariableTableContent" }
                    | null
                }
              | { __typename: "ST4TreeNode"; id: string }
              | null
          } | null> | null
          comments: Array<{
            __typename: "CommentNode"
            id: string
            comment:
              | {
                  __typename: "BlockComment"
                  location: number
                  value?: string | null
                  authorDisplayName?: string | null
                  authorUserName?: string | null
                  commentKey: string
                  editDate?: any | null
                  modificationVersion?: number | null
                  replyToKey?: string | null
                  sortOrder?: number | null
                  commentType?: string | null
                  state?: string | null
                  color: string
                  persistanceState?: Types.PersistanceState | null
                  persistanceAction?: Types.PersistanceAction | null
                  node: {
                    __typename: "ST4Node"
                    aspectId: any
                    id: string
                    content?:
                      | { __typename: "LexiconContent" }
                      | { __typename: "LexiconEntryContent" }
                      | { __typename: "MediaContent" }
                      | { __typename: "MediaGroupContent" }
                      | { __typename: "ProjectContent" }
                      | { __typename: "TermContent" }
                      | { __typename: "TextContent"; id: string; historyNumber: number }
                      | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                      | { __typename: "VariableTableContent" }
                      | null
                  }
                  actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
                }
              | {
                  __typename: "TextComment"
                  endLocation: number
                  startLocation?: number | null
                  value?: string | null
                  authorDisplayName?: string | null
                  authorUserName?: string | null
                  commentKey: string
                  editDate?: any | null
                  modificationVersion?: number | null
                  replyToKey?: string | null
                  sortOrder?: number | null
                  commentType?: string | null
                  state?: string | null
                  color: string
                  persistanceState?: Types.PersistanceState | null
                  persistanceAction?: Types.PersistanceAction | null
                  node: {
                    __typename: "ST4Node"
                    aspectId: any
                    id: string
                    content?:
                      | { __typename: "LexiconContent" }
                      | { __typename: "LexiconEntryContent" }
                      | { __typename: "MediaContent" }
                      | { __typename: "MediaGroupContent" }
                      | { __typename: "ProjectContent" }
                      | { __typename: "TermContent" }
                      | { __typename: "TextContent"; id: string; historyNumber: number }
                      | { __typename: "TextGroupContent"; id: string; historyNumber: number }
                      | { __typename: "VariableTableContent" }
                      | null
                  }
                  actions: Array<{ __typename: "Action"; name?: string | null; enabled?: boolean | null }>
                }
          }>
        }
      | { __typename: "VariableTableContent" }
      | null
  }
}

export type ContentNodeTeaserInfoFragment = {
  __typename: "ST4Node"
  aspectId: any
  label?: string | null
  properties?: {
    __typename: "ContentPropertyConnection"
    totalCount: number
    edges?: Array<{
      __typename: "ContentPropertyEdge"
      node?: {
        __typename: "ContentProperty"
        typeOfValue: Types.ContentPropertyValueType
        name: string
        value?: any | null
        displayName: string
      } | null
    } | null> | null
  } | null
}

export type LexiconTermContentFragment = {
  __typename: "TermContent"
  gramaticalGender?: string | null
  validity?: string | null
  editLanguage?: string | null
}

export type LexiconEntryDataFragment = {
  __typename: "LexiconEntryContent"
  descriptionXast?: string | null
  subject?: string | null
  sortKey?: string | null
  lexicon:
    | { __typename: "CommentNode"; id: string }
    | { __typename: "ExternalSite"; id: string }
    | { __typename: "ST4Class"; id: string }
    | { __typename: "ST4Node"; label?: string | null; id: string }
    | { __typename: "ST4TreeNode"; id: string }
  terms: Array<
    | { __typename: "CommentNode"; id: string }
    | { __typename: "ExternalSite"; id: string }
    | { __typename: "ST4Class"; id: string }
    | {
        __typename: "ST4Node"
        id: string
        label?: string | null
        aspectId: any
        content?:
          | { __typename: "LexiconContent" }
          | { __typename: "LexiconEntryContent" }
          | { __typename: "MediaContent" }
          | { __typename: "MediaGroupContent" }
          | { __typename: "ProjectContent" }
          | {
              __typename: "TermContent"
              gramaticalGender?: string | null
              validity?: string | null
              editLanguage?: string | null
            }
          | { __typename: "TextContent" }
          | { __typename: "TextGroupContent" }
          | { __typename: "VariableTableContent" }
          | null
      }
    | { __typename: "ST4TreeNode"; id: string }
  >
  image?:
    | { __typename: "CommentNode"; id: string }
    | { __typename: "ExternalSite"; id: string }
    | { __typename: "ST4Class"; id: string }
    | {
        __typename: "ST4Node"
        aspectId: any
        id: string
        content?:
          | { __typename: "LexiconContent" }
          | { __typename: "LexiconEntryContent" }
          | {
              __typename: "MediaContent"
              media?:
                | { __typename: "AnimationMediaInfo"; thumbnail?: string | null; mediaType?: Types.MediaType | null }
                | {
                    __typename: "ImageMediaInfo"
                    src?: string | null
                    thumbnail?: string | null
                    mediaType?: Types.MediaType | null
                  }
                | { __typename: "NoMediaInfo" }
                | {
                    __typename: "VectorGraphicsMediaInfo"
                    svg?: string | null
                    thumbnail?: string | null
                    mediaType?: Types.MediaType | null
                  }
                | { __typename: "VideoMediaInfo"; thumbnail?: string | null; mediaType?: Types.MediaType | null }
                | null
            }
          | { __typename: "MediaGroupContent" }
          | { __typename: "ProjectContent" }
          | { __typename: "TermContent" }
          | { __typename: "TextContent" }
          | { __typename: "TextGroupContent" }
          | { __typename: "VariableTableContent" }
          | null
      }
    | { __typename: "ST4TreeNode"; id: string }
    | null
}

export type SafetyConfigurationFragment = {
  __typename: "SafetyModel"
  safetyTypes: Array<{
    __typename: "SafetyType"
    id?: string | null
    displayName?: string | null
    icons: Array<{
      __typename: "SafetyIcon"
      iconName?: string | null
      use?: string | null
      contentNode?: {
        __typename: "ST4Node"
        id: string
        aspectId: any
        content?:
          | { __typename: "LexiconContent" }
          | { __typename: "LexiconEntryContent" }
          | {
              __typename: "MediaContent"
              media?:
                | { __typename: "AnimationMediaInfo"; thumbnail?: string | null }
                | { __typename: "ImageMediaInfo"; src?: string | null; thumbnail?: string | null }
                | { __typename: "NoMediaInfo" }
                | {
                    __typename: "VectorGraphicsMediaInfo"
                    svg?: string | null
                    thumbnail?: string | null
                    resourceId?: string | null
                  }
                | { __typename: "VideoMediaInfo"; thumbnail?: string | null }
                | null
            }
          | { __typename: "MediaGroupContent" }
          | { __typename: "ProjectContent" }
          | { __typename: "TermContent" }
          | { __typename: "TextContent" }
          | { __typename: "TextGroupContent" }
          | { __typename: "VariableTableContent" }
          | null
      } | null
    }>
  }>
  severityLevels: Array<{ __typename: "SeverityLevel"; id?: string | null; displayName?: string | null }>
}

export const St4NodeTeaserInfoFragmentDoc = gql`
  fragment ST4NodeTeaserInfo on ST4Node {
    id
    aspectId
    label
    properties(variant: $reviewVariant) {
      edges {
        node {
          name
          value
          displayName
        }
      }
    }
  }
`
export const VectorGraphicsFragmentDoc = gql`
  fragment VectorGraphics on VectorGraphicsMediaInfo {
    id
    resourceId
    thumbnail
    width
    height
    allowCaching
    mediaType
    labels {
      id
      sequence
      autoValue
      text
      linkType
      link
      ref {
        id
        owner {
          ... on ST4Node {
            id
          }
        }
        target {
          ...ST4NodeTeaserInfo
          ... on ExternalSite {
            id
            teaserData {
              title
              summary
              iconUrl
              imageUrl
              status
            }
          }
        }
      }
    }
    language
  }
  ${St4NodeTeaserInfoFragmentDoc}
`
export const MediaInfoDataFragmentDoc = gql`
  fragment MediaInfoData on MediaInfo {
    ... on ImageMediaInfo {
      id
      resourceId
      thumbnail
      width
      height
      resolution
      colorDepth
      size
      mime
      extension
      allowCaching
      mediaType
      src
      language
      __typename
    }
    ...VectorGraphics
    ... on AnimationMediaInfo {
      id
      resourceId
      thumbnail
      width
      height
      mediaType
    }
    ... on VideoMediaInfo {
      id
      resourceId
      thumbnail
      width
      height
      mediaType
    }
    ... on NoMediaInfo {
      id
      mediaType
    }
    __typename
  }
  ${VectorGraphicsFragmentDoc}
`
export const ImageDataFragmentDoc = gql`
  fragment ImageData on IGraphicContent {
    media {
      ...MediaInfoData
    }
    ... on MediaGroupContent {
      children {
        id
        target {
          id
          ... on ST4Node {
            id
            aspectId
            content {
              ... on IGraphicContent {
                media {
                  ...MediaInfoData
                }
              }
            }
          }
        }
      }
    }
  }
  ${MediaInfoDataFragmentDoc}
`
export const ContentNodeTeaserInfoFragmentDoc = gql`
  fragment ContentNodeTeaserInfo on ST4Node {
    aspectId
    label
    properties(variant: $reviewVariant) {
      totalCount
      edges {
        node {
          typeOfValue
          name
          value
          displayName
        }
      }
    }
  }
`
export const InternalLinkTeaserFragmentDoc = gql`
  fragment InternalLinkTeaser on TextContentLink {
    ref
    node {
      id
      ...ContentNodeTeaserInfo
    }
  }
  ${ContentNodeTeaserInfoFragmentDoc}
`
export const VariableTableContentFragmentDoc = gql`
  fragment VariableTableContent on VariableTableContent {
    xml
    valueSets {
      name
      valueSetId
    }
    variables {
      name
      variableId
      values
    }
  }
`
export const LexiconTermContentFragmentDoc = gql`
  fragment LexiconTermContent on TermContent {
    gramaticalGender
    validity
    editLanguage
  }
`
export const LexiconEntryDataFragmentDoc = gql`
  fragment LexiconEntryData on LexiconEntryContent {
    descriptionXast
    subject
    sortKey
    lexicon {
      id
      ... on ST4Node {
        label
      }
    }
    terms {
      id
      ... on ST4Node {
        id
        label
        aspectId
        content {
          ...LexiconTermContent
        }
      }
    }
    image {
      id
      ... on ST4Node {
        aspectId
        content {
          ... on MediaContent {
            media {
              ... on ImageMediaInfo {
                src
                thumbnail
                mediaType
              }
              ... on VectorGraphicsMediaInfo {
                svg
                thumbnail
                mediaType
              }
              ... on AnimationMediaInfo {
                thumbnail
                mediaType
              }
              ... on VideoMediaInfo {
                thumbnail
                mediaType
              }
            }
          }
        }
      }
    }
  }
  ${LexiconTermContentFragmentDoc}
`
export const ITextContentPreviewFragmentDoc = gql`
  fragment ITextContentPreview on ITextContent {
    id
    xast
    historyNumber
    links {
      ...InternalLinkTeaser
    }
    ... on TextGroupContent {
      currentNodeId
      groupNodeId
      children {
        id
        target {
          id
          ... on ST4Node {
            label
            aspectId
          }
        }
      }
    }
    images {
      id
      target {
        id
        ... on ST4Node {
          aspectId
          releaseState {
            displayName
            state
            isImplicitlyReleased
          }
          content {
            ...ImageData
          }
        }
      }
    }
    variableTables {
      ref
      node {
        ... on ST4Node {
          id
          label
          aspectId
          content {
            ...VariableTableContent
          }
        }
      }
    }
    linkedDataNodes {
      id
      target {
        id
        ... on ST4Node {
          label
          metadata(users: ["Referable"], systemNames: ["Title"]) {
            label
            systemName
            value
            values
            users
          }
          linkedOntologyNodes: leavingLinks(linkClass: ["ObjectToOntologyLink"]) {
            ... on NodeRef {
              linkClassName
              class {
                label
              }
            }
            target {
              ... on ST4Node {
                id
                label
              }
            }
          }
        }
      }
    }
    lexiconEntries {
      id
      target {
        id
        ... on ST4Node {
          aspectId
          label
          content {
            ...LexiconEntryData
          }
        }
      }
    }
    comments {
      ...CommentNode
    }
  }
  ${InternalLinkTeaserFragmentDoc}
  ${ImageDataFragmentDoc}
  ${VariableTableContentFragmentDoc}
  ${LexiconEntryDataFragmentDoc}
  ${CommentNodeFragmentDoc}
`
export const PartsContentsFragmentDoc = gql`
  fragment PartsContents on TextContentPart {
    ref
    contentNode {
      id
      aspectId
      content {
        ...ITextContentPreview
      }
      __typename
    }
  }
  ${ITextContentPreviewFragmentDoc}
`
export const FragmentContentsFragmentDoc = gql`
  fragment FragmentContents on ITextContent {
    __typename
    ...ITextContentPreview
    parts {
      ...PartsContents
      __typename
    }
  }
  ${ITextContentPreviewFragmentDoc}
  ${PartsContentsFragmentDoc}
`
export const TextContentWithFragmentsFragmentDoc = gql`
  fragment TextContentWithFragments on ITextContent {
    ...ITextContentPreview
    fragments {
      compoundId
      contentNode {
        label
        id
        aspectId
        content {
          ...FragmentContents
        }
      }
    }
    parts {
      ...PartsContents
    }
  }
  ${ITextContentPreviewFragmentDoc}
  ${FragmentContentsFragmentDoc}
  ${PartsContentsFragmentDoc}
`
export const St4NodeWithContentFragmentDoc = gql`
  fragment St4NodeWithContent on ST4Node {
    id
    label
    nodeClass {
      classHierarchy
    }
    metadata(users: ["Referable"]) {
      label
      systemName
      value
      values
    }
    aspectId
    properties(variant: $reviewVariant) {
      totalCount
      edges {
        node {
          name
          displayName
          value
          typeOfValue
        }
      }
    }
    content {
      ...ImageData
      ...TextContentWithFragments
      ...LexiconEntryData
      ...LexiconTermContent
      ...VariableTableContent
    }
    releaseState {
      displayName
      state
      isImplicitlyReleased
    }
  }
  ${ImageDataFragmentDoc}
  ${TextContentWithFragmentsFragmentDoc}
  ${LexiconEntryDataFragmentDoc}
  ${LexiconTermContentFragmentDoc}
  ${VariableTableContentFragmentDoc}
`
export const SafetyConfigurationFragmentDoc = gql`
  fragment SafetyConfiguration on SafetyModel {
    safetyTypes {
      id
      displayName
      icons {
        contentNode {
          id
          aspectId
          content {
            ... on MediaContent {
              media {
                ... on ImageMediaInfo {
                  src
                  thumbnail
                }
                ... on VectorGraphicsMediaInfo {
                  svg
                  thumbnail
                  resourceId
                }
                ... on AnimationMediaInfo {
                  thumbnail
                }
                ... on VideoMediaInfo {
                  thumbnail
                }
              }
            }
          }
        }
        iconName
        use
      }
    }
    severityLevels {
      id
      displayName
    }
  }
`
export const SingleNodeDocument = gql`
  query SingleNode($id: ID!, $languageId: AspectSelectorId!, $reviewVariant: String!) {
    node(id: $id, languageId: $languageId) {
      id
      ...St4NodeWithContent
      __typename
    }
  }
  ${St4NodeWithContentFragmentDoc}
`

/**
 * __useSingleNodeQuery__
 *
 * To run a query within a React component, call `useSingleNodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useSingleNodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSingleNodeQuery({
 *   variables: {
 *      id: // value for 'id'
 *      languageId: // value for 'languageId'
 *      reviewVariant: // value for 'reviewVariant'
 *   },
 * });
 */
export function useSingleNodeQuery(baseOptions: Apollo.QueryHookOptions<SingleNodeQuery, SingleNodeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SingleNodeQuery, SingleNodeQueryVariables>(SingleNodeDocument, options)
}
export function useSingleNodeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SingleNodeQuery, SingleNodeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SingleNodeQuery, SingleNodeQueryVariables>(SingleNodeDocument, options)
}
export type SingleNodeQueryHookResult = ReturnType<typeof useSingleNodeQuery>
export type SingleNodeLazyQueryHookResult = ReturnType<typeof useSingleNodeLazyQuery>
export type SingleNodeQueryResult = Apollo.QueryResult<SingleNodeQuery, SingleNodeQueryVariables>
export const LinkMetadataDocument = gql`
  query LinkMetadata($id: ID!) {
    node(id: $id) {
      id
      __typename
      ... on ExternalSite {
        teaserData {
          title
          summary
          iconUrl
          imageUrl
          status
        }
      }
    }
  }
`

/**
 * __useLinkMetadataQuery__
 *
 * To run a query within a React component, call `useLinkMetadataQuery` and pass it any options that fit your needs.
 * When your component renders, `useLinkMetadataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLinkMetadataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLinkMetadataQuery(
  baseOptions: Apollo.QueryHookOptions<LinkMetadataQuery, LinkMetadataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<LinkMetadataQuery, LinkMetadataQueryVariables>(LinkMetadataDocument, options)
}
export function useLinkMetadataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LinkMetadataQuery, LinkMetadataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<LinkMetadataQuery, LinkMetadataQueryVariables>(LinkMetadataDocument, options)
}
export type LinkMetadataQueryHookResult = ReturnType<typeof useLinkMetadataQuery>
export type LinkMetadataLazyQueryHookResult = ReturnType<typeof useLinkMetadataLazyQuery>
export type LinkMetadataQueryResult = Apollo.QueryResult<LinkMetadataQuery, LinkMetadataQueryVariables>
