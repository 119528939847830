import React, { useState } from "react"
import { Select } from "antd"
import { FacetWrapper, getLabel, getRefinementId } from "./Utils"
import { CheckboxFacetItem } from "./CheckboxFacetItem"
import { SearchFacetItem } from "../../types"
import { useTranslation } from "react-i18next"
import { keys } from "@st4/ui-strings"
import { baseTestId } from "../SearchFacetsList"

type ModifiedByFacetProps = {
  facetKey: string
  title: string
  availableValues?: SearchFacetItem[] | null
  selectedValue?: React.Key
  onSubmit: (selectedValue?: React.Key) => void
}

const meId = "me"

export function ModifiedByFacet(props: ModifiedByFacetProps) {
  const [selectedValue, setSelectedValue] = useState(props.selectedValue)

  function selectHandler(selectedValue?: React.Key) {
    setSelectedValue(selectedValue)
    props.onSubmit(selectedValue)
  }

  const testId = "modifiedBy"

  const me = props.availableValues?.find((value) => value.label == meId)
  const them = props.availableValues?.filter((value) => value.label != meId)

  return (
    <FacetWrapper testId={testId} title={props.title}>
      <MeFacetItem
        testId={`${baseTestId}-${testId}-me`}
        facetKey={props.facetKey}
        item={me}
        selectedValue={selectedValue}
        onClear={selectHandler}
        onSelect={selectHandler}
      />
      <ThemFacetItems
        testId={`${baseTestId}-${testId}-them`}
        facetKey={props.facetKey}
        items={them}
        meValue={me?.value}
        selectedValue={selectedValue}
        onClear={selectHandler}
        onSelect={selectHandler}
      />
    </FacetWrapper>
  )
}

function MeFacetItem(props: {
  testId: string
  facetKey: string
  item?: SearchFacetItem
  selectedValue?: React.Key
  onClear: () => void
  onSelect: (key: React.Key) => void
}) {
  const { t } = useTranslation()
  if (!props.item) return <></>

  const meTitle = t(keys.label.search.component.searchFacetsList.facetItemLabels.me)
  const meValue = getRefinementId(props.facetKey, props.item.value)
  return (
    <CheckboxFacetItem
      testId={props.testId}
      title={`${meTitle} (${props.item.count})`}
      value={meValue}
      selectedValue={props.selectedValue}
      onClear={props.onClear}
      onSelect={props.onSelect}
    />
  )
}

function ThemFacetItems(props: {
  testId: string
  facetKey: string
  items?: SearchFacetItem[]
  meValue: React.Key | undefined
  selectedValue?: React.Key
  onClear: () => void
  onSelect: (key: React.Key) => void
}) {
  const { t } = useTranslation()
  if (!props.items?.length) return <></>

  const meValue = !props.meValue ? undefined : getRefinementId(props.facetKey, props.meValue.toString())
  return (
    <Select
      data-testid={`${props.testId}-select`}
      style={{ width: "100%" }}
      disabled={!!props.selectedValue && !!props.meValue && props.selectedValue == meValue}
      allowClear={!!props.selectedValue && (!props.meValue || props.selectedValue != meValue)}
      options={props.items.map((item) => ({
        label: `${getLabel(item.label, t)} (${item.count})`,
        value: getRefinementId(props.facetKey, item.value),
      }))}
      placeholder={t(keys.label.general.pleaseSelect)}
      value={props.selectedValue == props.meValue ? undefined : props.selectedValue}
      onClear={props.onClear}
      onSelect={props.onSelect}
    />
  )
}
