import { usePreviewContentModel } from "../../contentModel"
import { useMemo } from "react"

/**
 * Returns data of the commentmodel.
 */
export function useCommentModel() {
  const previewContentModel = usePreviewContentModel()
  const empty = useMemo(() => ({ availableTypes: [], availableStates: [] }), [])
  if (previewContentModel.state !== "ready") return empty
  return previewContentModel.commentModel
}
