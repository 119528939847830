import { isST4NodeWithContent, ST4NodeWithContent } from "../../graphql/applicationQueries"
import { ExtractByTypeName, isTypename, notEmpty } from "@st4/graphql"
import {
  CommentInfoFieldsFragment,
  CommentNodeFragment,
  CommentsForNodesQuery,
  useCommentsForNodesQuery,
} from "./query.hooks"
import { usePreviewContentModel, useSelectedTextNodeState } from "../../contentModel"
import { usePreviewParameterContext } from "../PreviewParameterContext"
import { useTreeNodeFilter } from "../FilteringTreeNodeWrapper"
import { useMemo } from "react"
import { distinctBy } from "../../utilities"

function getCommentsWithRepliesFromNode(node: ST4NodeWithContent, treeNodeId: string) {
  if (!isST4NodeWithContent("TextContent", "TextGroupContent")(node)) return []
  const comments = node.content.comments

  return comments
    .filter((c) => /*Toplevel Comments only*/ !c.comment.replyToKey)
    .map((c) => ({
      type: "ContentComment" as const,
      treeNodeId,
      commentNode: c,
      replies: comments
        .reduce((agg, possibleChild) => {
          if (
            possibleChild.comment.replyToKey == c.comment.commentKey ||
            agg.some(({ comment: { commentKey } }) => commentKey == possibleChild.comment.replyToKey)
          ) {
            agg.push(possibleChild)
          }
          return agg
        }, new Array<CommentNodeFragment>())
        .sort(sortCommentsByLocation),
    }))
}

function getNodeAnnotation(
  node: ExtractByTypeName<"ST4TreeNode", NonNullable<CommentsForNodesQuery["nodes"][number]>>["node"],
  treeNodeId: string,
) {
  if (!isTypename("ST4Node")(node)) return null
  const nodeCommentProperty = node?.properties?.edges?.filter(notEmpty).find(({ node }) => node?.name === "nodeComment")
  const nodeComment = nodeCommentProperty?.node
  if (!nodeComment?.value?.length) {
    return null
  }
  return {
    type: "NodeComment" as const,
    nodeId: node.id,
    treeNodeId,
    value: nodeComment.value,
    commentType: nodeComment.displayName,
  }
}

type TextComment = ReturnType<typeof getCommentsWithRepliesFromNode>[number]
type NodeAnnotation = NonNullable<ReturnType<typeof getNodeAnnotation>>

type Comments = TextComment | NodeAnnotation

export function useComments() {
  const previewContentModel = usePreviewContentModel()
  const previewParameterContext = usePreviewParameterContext()
  const treeNodeFilter = useTreeNodeFilter()
  const { nodes } = useSelectedTextNodeState(
    previewContentModel.state == "ready" ? previewContentModel : { treeNodes: [] },
  )
  const { data, loading } = useCommentsForNodesQuery({
    variables: {
      includeNodeComments: true,
      nodeIds: nodes.map((n) => n.id),
      aspectId: previewParameterContext.languageId,
    },
  })

  const allComments = useMemo(() => {
    if (!data?.nodes) {
      return []
    }

    return data.nodes.flatMap((treeNode) => {
      if (!isTypename("ST4TreeNode")(treeNode) || !isTypename("ST4Node")(treeNode.node)) return []

      const st4Node = treeNode.node
      const content = st4Node.content

      // Comments of node and all fragments and parts indexed by their ST4Node Id
      const nodeAnnotation = getNodeAnnotation(st4Node, treeNode.id)
      if (!isTypename("TextContent", "TextGroupContent")(content)) return nodeAnnotation ? [nodeAnnotation] : []
      const commentsInNode = getCommentsWithRepliesFromNode(st4Node, treeNode.id)
      const importedComments = nodeAnnotation ? [nodeAnnotation, ...commentsInNode] : commentsInNode

      if (content.fragments) {
        content.fragments
          .map((f) => f?.contentNode)
          .filter(notEmpty)
          .forEach((n) => importedComments.push(...getCommentsWithRepliesFromNode(n, treeNode.id)))
      }
      if (content.parts) {
        content.parts
          .map((p) => p?.contentNode)
          .filter(notEmpty)
          .forEach((n) => importedComments.push(...getCommentsWithRepliesFromNode(n, treeNode.id)))
      }

      if (isTypename("TextGroupContent")(content)) {
        const groupChild = content.children
          .map((c) => c.target)
          .filter(isTypename("ST4Node"))
          .find((x) => {
            if (previewContentModel.state !== "ready") return true
            const treeNode = previewContentModel.treeNodesByNodeId.get(x.id)
            if (!treeNode) return true
            return treeNodeFilter(treeNode.id).visible
          })
        if (groupChild) {
          // Filter displayed Group Node
          importedComments.push(...getCommentsWithRepliesFromNode(groupChild, treeNode.id))
          const childNodeContent = groupChild.content
          if (!isTypename("TextContent")(childNodeContent) && !isTypename("TextGroupContent")(childNodeContent))
            return []

          if (childNodeContent.fragments) {
            childNodeContent.fragments
              .map((f) => f?.contentNode)
              .filter(notEmpty)
              .forEach((n) => importedComments.push(...getCommentsWithRepliesFromNode(n, treeNode.id)))
          }
          if (childNodeContent.parts) {
            childNodeContent.parts
              .map((p) => p?.contentNode)
              .filter(notEmpty)
              .forEach((n) => importedComments.push(...getCommentsWithRepliesFromNode(n, treeNode.id)))
          }
        }
      }
      return importedComments
    })
  }, [data, previewContentModel, treeNodeFilter])

  const commentsForList = useMemo<Comments[]>(() => {
    //remove doublicates. Could happen for reused Nodes

    const distinctComments = allComments
      .filter(distinctBy((x) => (x.type === "ContentComment" ? x.commentNode.id : x.nodeId)))
      .sort((a, b) =>
        // only sort text-comments. Keep node annotations in same order
        a.type === "ContentComment" && b.type === "ContentComment"
          ? sortCommentsByLocation(a.commentNode, b.commentNode)
          : 0,
      )
    return distinctComments
  }, [allComments])
  const textComments = useMemo(() => allComments.filter(isTextComment), [allComments])
  return {
    textComments,
    loading,
    commentsForList,
  }
}

function isTextComment(c: Comments): c is TextComment {
  return c.type === "ContentComment"
}

function sortCommentsByLocation(a: CommentNodeFragment, b: CommentNodeFragment) {
  const isSameContentNode = a.comment.node?.id === b.comment.node?.id
  if (!isSameContentNode) return 0 //keep sorting of content nodes
  const getLocation = (comment: CommentInfoFieldsFragment) =>
    isTypename("TextComment")(comment)
      ? comment.startLocation
      : isTypename("BlockComment")(comment)
      ? comment.location
      : 0
  const first = getLocation(a.comment) ?? 0
  const second = getLocation(b.comment) ?? 0
  return first - second
}
