import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

// prettier-ignore
const Title = styled.h2`
    display: flex;
    box-sizing: border-box;
    color: rgba(0,0,0,.87);
    font-size: ${props => props.theme.token.fontSize}px;
    line-height: 1.42857;
    margin: 0;
    font-weight: 400;
    letter-spacing: 0;
`

// prettier-ignore
const SubTitle = styled.h3`
    color: rgba(0, 0, 0, 0.541176);
    font-size: ${props => props.theme.token.fontSize}px;
    line-height: 1.42857;
`

// prettier-ignore
const CardTitle_deprecated = styled(function(props) {
  return (<div className={`CardTitle ${props.className}`}>
    <Title className="CardTitle--Title">
      { props.icon && (<>
        { props.icon }
        &nbsp;
        </>) }
      {props.title}
    </Title>
    { props.subtitle ? <SubTitle className="CardTitle--SubTitle">{props.subtitle}</SubTitle> : null }
  </div>)
})`
  padding: 16px;
  padding-top: 24px;    
`

CardTitle_deprecated.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  icon: PropTypes.any,
}

export default CardTitle_deprecated
