import {
  SettingsDocument,
  useSettingsQuery,
  useSaveContentLanguageMutation,
  useSaveGuiLanguageMutation,
} from "./query.hooks"
import { Select, Spin, Form, Alert, Space, Skeleton } from "antd"
import React, { useEffect, useState } from "react"
import { keys } from "@st4/ui-strings"
import { useApolloClient } from "@apollo/client"
import ThemeManager from "@st4/theme-manager"
import styled from "styled-components"
import { Translate } from "@st4/i18n"
import { Icon, Light } from "@st4/icons"

const { Option } = Select

type LoadingState = {
  state: "loading"
}
type ReadyState = {
  state: "ready"
  contentLanguageOptions: { label: string; value: string }[]
  guiLanguageOptions: { label: string; value: string }[]
  currentContentLanguage: { label: string; value: string } | null
  currentGuiLanguage: { label: string; value: string } | null
}

type State = LoadingState | ReadyState

function useApolloCacheClean() {
  const client = useApolloClient()
  // Temporary® Solution so all queries are rerun with new language.
  // Normally providing a new value for a useQuery variable will already trigger a rerun of the query
  // Inside the preview rerunning the main query overwrites some ST4Nodes in cache.
  // Changing the language twice in a single session will then lead to outdated values being used
  return () => client.resetStore()
}

function useLanguageSettings(): State {
  const { data, loading } = useSettingsQuery()

  if (!data || loading) return { state: "loading" }

  // Assumption: Currently selected language is available in languageList
  const languageList = data.configuration.languageIdList
    .slice()
    .sort((a, b) => a.languageName.localeCompare(b.languageName))

  const currentContentLanguage = languageList.find(
    (lang) => lang.language == data.userConfiguration.contentLanguageCode,
  )

  const guiLanguages = data.configuration.guiLanguages.map(({ value, label }) => ({
    label: label,
    value: value.split("-")[0],
  }))

  const currentGuiLanguage = guiLanguages.find((gl) => gl.value === data.userConfiguration.guiLanguageCode)

  return {
    state: "ready",
    currentContentLanguage: currentContentLanguage
      ? { label: currentContentLanguage?.languageName, value: currentContentLanguage?.language }
      : null,
    currentGuiLanguage: currentGuiLanguage ?? null,
    contentLanguageOptions: languageList.map((value) => ({ label: value.languageName, value: value.language })),
    guiLanguageOptions: guiLanguages,
  }
}

type MutationTypeLanguageSelector =
  | ReturnType<typeof useSaveContentLanguageMutation>
  | ReturnType<typeof useSaveGuiLanguageMutation>

function GenericLanguageSelector(props: {
  mutation: MutationTypeLanguageSelector
  languageChanged?: (newLanguage: string) => void
  current: { label: string; value: string } | null
  options: { label: string; value: string }[]
}) {
  const { current, options, mutation, languageChanged } = props

  const [setContentLanguage, { loading, data, error }] = mutation
  return (
    <>
      <Space direction="horizontal">
        <Select
          showSearch
          style={{ width: "15em" }}
          onChange={(value: string) =>
            setContentLanguage({ variables: { languageCode: value }, refetchQueries: [SettingsDocument] }).then(
              () => languageChanged?.(value),
            )
          }
          defaultValue={current?.value}
        >
          {options.map(({ label, value }) => (
            <Option value={value} key={value}>
              {label}
            </Option>
          ))}
        </Select>
        {loading ? (
          <Spin delay={500} />
        ) : error ? (
          <InlineMessage timeout={3000} error={error?.message} />
        ) : (
          data && <InlineMessage success timeout={3000} key={JSON.stringify(data)} />
        )}
      </Space>
    </>
  )
}
function ContentLanguageSelector(props: { languageChanged?: (newLanguage: string) => void }) {
  const mutation = useSaveContentLanguageMutation()
  const languageOptions = useLanguageSettings()
  if (languageOptions.state == "loading") {
    return <Skeleton />
  }
  return (
    <GenericLanguageSelector
      current={languageOptions.currentContentLanguage}
      options={languageOptions.contentLanguageOptions}
      mutation={mutation}
      languageChanged={props.languageChanged}
    />
  )
}

function GuiLanguageSelector(props: { languageChanged?: (newLanguage: string) => void }) {
  const mutation = useSaveGuiLanguageMutation()
  const languageOptions = useLanguageSettings()
  if (languageOptions.state == "loading") {
    return <Skeleton />
  }
  return (
    <GenericLanguageSelector
      current={languageOptions.currentGuiLanguage}
      options={languageOptions.guiLanguageOptions}
      mutation={mutation}
      languageChanged={props.languageChanged}
    />
  )
}

export function LanguageSettings() {
  const state = useLanguageSettings()
  if (state.state == "loading") return <Spin delay={500} />
  return <LanguageSettingsForm state={state}></LanguageSettingsForm>
}

export function LanguageSettingsForm({ state }: { state: ReadyState }) {
  const cleanCache = useApolloCacheClean()
  const [guiLanguageChanged, setGuiLanguageChanged] = useState(false)
  return (
    <Form
      labelCol={{
        span: 6,
      }}
      wrapperCol={{
        span: 8,
      }}
    >
      <Form.Item label={<Translate>{keys.label.generic.contentLanguage}</Translate>}>
        <StyledFormItem>
          <ContentLanguageSelector languageChanged={() => cleanCache()} />
        </StyledFormItem>
      </Form.Item>
      <Form.Item label={<Translate>{keys.label.generic.guiLanguage}</Translate>}>
        <StyledFormItem>
          <GuiLanguageSelector
            languageChanged={() => {
              cleanCache()
              setGuiLanguageChanged(true)
            }}
          />
        </StyledFormItem>
        {guiLanguageChanged && (
          <Alert
            showIcon
            message={<Translate>{keys.message.warning.guiLanguage}</Translate>}
            type="info"
            style={{ marginTop: "1em" }}
          />
        )}
      </Form.Item>
    </Form>
  )
}

const BaseWrapper = styled.div`
  transition: all 300ms ease-in;
  display: inline;
`

type InlineMessageProps = {
  timeout?: number
} & (SuccessInlineMessage | ErrorInlineMessage)

type SuccessInlineMessage = {
  success: true
}
type ErrorInlineMessage = {
  error: string
}

function InlineMessage(props: InlineMessageProps) {
  const { timeout } = props
  const [visible, setVisible] = useState(false)
  useEffect(() => {
    if (!timeout) return
    const handle = window.setTimeout(() => setVisible(false), timeout)
    return () => window.clearTimeout(handle)
  }, [])
  useEffect(() => {
    const handle = window.requestAnimationFrame(() => setVisible(true))
    return () => window.cancelAnimationFrame(handle)
  }, [])

  return (
    <BaseWrapper style={{ opacity: visible ? 1 : 0 }}>
      <Icon component={Light.CircleCheck} style={{ color: ThemeManager.quanosColors.palettes.success.success }}></Icon>{" "}
      <Translate>{keys.label.generic.saved}</Translate>
    </BaseWrapper>
  )
}

const StyledFormItem = styled.div`
  .ant-select-show-search.ant-select,
  .ant-select-show-search.ant-select .ant-select-selector {
    cursor: pointer;
  }
  .ant-select-show-search.ant-select.ant-select-open,
  .ant-select-show-search.ant-select.ant-select-open .ant-select-selector {
    cursor: text;
  }
`
