import { ResourcePoolTreeBlade } from "~/blades/resourcePoolTreeBlade"
import { FragmentPoolTreeBlade } from "~/blades/fragmentPoolTreeBlade"
import { CrossReferencePoolBlade } from "~/blades/crossReferencePoolBlade"
import { VariablePoolTreeBlade } from "~/blades/variablePoolTreeBlade"
import { BladeReference, defineScreen } from "@st4/tasks"
import { EditorBlade } from "~/blades/editorBlade"
import { SafetyPoolBlade } from "~/blades/safetyPoolBlade"
import { MetaRefPoolTreeBlade } from "~/blades/metaRefPoolTreeBlade"

const refetchOnEditorSave = ["Properties", "NavigationTree", "getContent"]

export const editScreen = defineScreen<
  [
    BladeReference<"editor", typeof EditorBlade>,
    BladeReference<"resourcePoolTree", typeof ResourcePoolTreeBlade>,
    BladeReference<"fragmentPoolTree", typeof FragmentPoolTreeBlade>,
    BladeReference<"safetyPool", typeof SafetyPoolBlade>,
    BladeReference<"crossReferencePool", typeof CrossReferencePoolBlade>,
    BladeReference<"variablePoolTree", typeof VariablePoolTreeBlade>,
    BladeReference<"metaRefPool", typeof MetaRefPoolTreeBlade>,
  ],
  {
    insertImageInfo: {
      contextNodeId: string
    } | null
    insertFragmentInfo: {
      contextNodeId: string
    } | null
    insertCrossReferenceInfo: {
      contextNodeId: string
    } | null
    insertSafetyInfo: {
      contextNodeId: string
    } | null
    insertVariableInfo: {
      contextNodeId: string
    } | null
    insertMetaRefInfo: {
      contextNodeId: string
    } | null
    editModeInfo: {
      contextNodeId: string
      elementName: string
      elementProperties: { key: string; value: any }[] | null
    } | null
  }
>({
  name: "explore",
  icon: "TaskExplore",
  blades: [
    {
      name: "editor",
      component: "EditorBlade",
      props: (contentBladeState) => ({
        ...contentBladeState,
        refetchOnEditorSave,
      }),
    },
    {
      name: "resourcePoolTree",
      component: "ResourcePoolTreeBlade",
      temporary: true,
    },
    {
      name: "fragmentPoolTree",
      component: "FragmentPoolTreeBlade",
      temporary: true,
    },
    {
      name: "safetyPool",
      component: "SafetyPoolBlade",
      temporary: true,
    },
    {
      name: "crossReferencePool",
      component: "CrossReferencePoolBlade",
      temporary: true,
    },
    {
      name: "variablePoolTree",
      component: "VariablePoolTreeBlade",
      temporary: true,
    },
    {
      name: "metaRefPool",
      component: "MetaRefPoolTreeBlade",
      temporary: true,
    },
  ],
  initialContextValues: {
    blades: [{ name: "editor" }],
    states: {
      editor: {
        editedNodes: [],
        selection: null,
        size: { S: 24, M: 18, L: 12 },
      },
      resourcePoolTree: { variant: "ResourcePool", allowedOperations: [] },
      fragmentPoolTree: { variant: "FragmentPool", contextNode: "", allowedOperations: [] },
      crossReferencePool: { variant: "InformationPool", contextNode: "", allowedOperations: [] },
      safetyPool: { allowedOperations: [], editElementProperties: null },
      variablePoolTree: { variant: "VariablePool", allowedOperations: [] },
      metaRefPool: { variant: "MetaDataPool", allowedOperations: [] },
      screen: {
        insertImageInfo: null,
        insertFragmentInfo: null,
        insertCrossReferenceInfo: null,
        insertSafetyInfo: null,
        insertVariableInfo: null,
        insertMetaRefInfo: null,
        editModeInfo: null,
      },
    },
  },
  reducer(state, message, helper) {
    switch (message.action) {
      case "editor:sendAllowedOperations": {
        return helper
          .modifyState((states) => {
            states.fragmentPoolTree.allowedOperations = message.payload.operationNames
            states.safetyPool.allowedOperations = message.payload.operationNames
            states.resourcePoolTree.allowedOperations = message.payload.operationNames
            states.crossReferencePool.allowedOperations = message.payload.operationNames
            states.variablePoolTree.allowedOperations = message.payload.operationNames
            states.metaRefPool.allowedOperations = message.payload.operationNames
          })
          .create()
      }
      case "editor:openResourcePool": {
        return helper
          .showBlade("resourcePoolTree")
          .moveBlade("resourcePoolTree", "After", "editor")
          .modifyState((states) => {
            states.screen.insertImageInfo = { contextNodeId: message.payload.contextNodeId }
          })
          .create()
      }
      case "editor:openFragmentPool": {
        return helper
          .showBlade("fragmentPoolTree")
          .moveBlade("fragmentPoolTree", "After", "editor")
          .modifyState((states) => {
            states.fragmentPoolTree.contextNode = message.payload.contextNodeId
            states.screen.insertFragmentInfo = { contextNodeId: message.payload.contextNodeId }
          })
          .create()
      }
      case "editor:openCrossReferencePool": {
        return helper
          .showBlade("crossReferencePool")
          .moveBlade("crossReferencePool", "After", "editor")
          .modifyState((states) => {
            states.screen.insertCrossReferenceInfo = { contextNodeId: message.payload.contextNodeId }
            states.crossReferencePool.contextNode = message.payload.contextNodeId
          })
          .create()
      }
      case "editor:openSafetyPool": {
        return helper
          .showBlade("safetyPool")
          .moveBlade("safetyPool", "After", "editor")
          .modifyState((states) => {
            states.screen.insertSafetyInfo = { contextNodeId: message.payload.contextNodeId }
            states.safetyPool.editElementProperties = null
          })
          .create()
      }
      case "editor:openVariablePool": {
        return helper
          .showBlade("variablePoolTree")
          .moveBlade("variablePoolTree", "After", "editor")
          .modifyState((states) => {
            states.screen.insertVariableInfo = { contextNodeId: message.payload.contextNodeId }
          })
          .create()
      }
      case "editor:openMetaRefPool": {
        return helper
          .showBlade("metaRefPool")
          .moveBlade("metaRefPool", "After", "editor")
          .modifyState((states) => {
            states.screen.insertMetaRefInfo = { contextNodeId: message.payload.contextNodeId }
          })
          .create()
      }
      case "editor:startEditForCmsElement": {
        return helper
          .modifyState((states) => {
            const editElementInfo = message.payload.editElementInfo
            states.screen.editModeInfo = {
              contextNodeId: editElementInfo.nodeId,
              elementName: editElementInfo.elementName,
              elementProperties: editElementInfo.elementProperties,
            }

            if (editElementInfo.elementName === "safety") {
              // addOrMoveBladeAfterBlade("safetyPool", "editor", draft.blades)
              states.safetyPool.editElementProperties = editElementInfo.elementProperties
            }
          })
          .showBlade("safetyPool")
          .onlyIf(message.payload.editElementInfo.elementName === "safety")
          .moveBlade("safetyPool", "After", "editor")
      }
      case "editor:endEditForCmsElement": {
        return helper
          .modifyState((states) => {
            const editModeData = states.screen.editModeInfo
            if (!editModeData) return

            if (editModeData.elementName === "safety") {
              states.screen.insertSafetyInfo = null
              states.safetyPool.editElementProperties = null
            }

            states.screen.editModeInfo = null
          })
          .hideBlade("safetyPool")
          .onlyIf(state.states.screen.editModeInfo?.elementName === "safety")
      }
      case "resourcePoolTree:onClose": {
        return helper.hideBlade("resourcePoolTree").modifyState((states) => {
          states.screen.insertImageInfo = null
        })
      }
      case "fragmentPoolTree:onClose": {
        return helper.hideBlade("fragmentPoolTree").modifyState((states) => {
          states.screen.insertFragmentInfo = null
        })
      }
      case "crossReferencePool:onClose": {
        return helper.hideBlade("crossReferencePool").modifyState((states) => {
          states.screen.insertCrossReferenceInfo = null
          states.crossReferencePool.contextNode = ""
        })
      }
      case "safetyPool:closeTemporaryBlade": {
        return helper.modifyState((states) => {
          states.screen.insertSafetyInfo = null
          states.safetyPool.editElementProperties = null
        })
      }

      case "variablePoolTree:onClose": {
        return helper.hideBlade("variablePoolTree").modifyState((states) => {
          states.screen.insertVariableInfo = null
        })
      }
      case "metaRefPool:onClose": {
        return helper.hideBlade("metaRefPool").modifyState((states) => {
          states.screen.insertMetaRefInfo = null
        })
      }
      default:
        return state
    }
  },
  observe: {
    async resourcePoolTree(message, messageHubs, state) {
      if (message.action === "insertImage") {
        if (!state.states.screen.insertImageInfo) throw new Error("Image info is null!")

        messageHubs.editor.sendMessage({
          action: message.action,
          payload: { ...message.payload, nodeId: state.states.screen.insertImageInfo.contextNodeId },
        })
      }
    },
    async fragmentPoolTree(message, messageHubs, state) {
      if (message.action === "insertFragment") {
        if (!state.states.screen.insertFragmentInfo) throw new Error("Fragment info is null!")

        messageHubs.editor.sendMessage({
          action: message.action,
          payload: { ...message.payload, nodeId: state.states.screen.insertFragmentInfo.contextNodeId },
        })
      }
    },
    async crossReferencePool(message, messageHubs, state) {
      if (message.action === "insertCrossReference") {
        if (!state.states.screen.insertCrossReferenceInfo) throw new Error("Cross Reference info is null!")

        messageHubs.editor.sendMessage({
          action: message.action,
          payload: { ...message.payload, nodeId: state.states.screen.insertCrossReferenceInfo.contextNodeId },
        })
      }
    },
    async safetyPool(message, messageHubs, state) {
      if (message.action === "insertSafety") {
        if (!state.states.screen.insertSafetyInfo) throw new Error("Safety info is null!")

        messageHubs.editor.sendMessage({
          action: message.action,
          payload: { ...message.payload, nodeId: state.states.screen.insertSafetyInfo.contextNodeId },
        })
      }
      if (message.action === "updateSafety") {
        if (!state.states.screen.editModeInfo) throw new Error("Edit mode info is null!")

        messageHubs.editor.sendMessage({
          action: message.action,
          payload: { ...message.payload, nodeId: state.states.screen.editModeInfo.contextNodeId },
        })
      }
      if (message.action === "closeTemporaryBlade") {
        if (state.states.screen.editModeInfo?.elementName === "safety") {
          messageHubs.editor.sendMessage({
            action: "endEditForCmsElement",
            payload: { nodeId: state.states.screen.editModeInfo.contextNodeId },
          })
        }
      }
    },
    async variablePoolTree(message, messageHubs, state) {
      if (message.action === "insertVariable") {
        if (!state.states.screen.insertVariableInfo) throw new Error("Variable info is null!")

        messageHubs.editor.sendMessage({
          action: message.action,
          payload: { ...message.payload, nodeId: state.states.screen.insertVariableInfo.contextNodeId },
        })
      }
    },
    async metaRefPool(message, messageHubs, state) {
      if (message.action === "insertMetaRef") {
        if (!state.states.screen.insertMetaRefInfo) throw new Error("Metaref info is null!")

        messageHubs.editor.sendMessage({
          action: message.action,
          payload: { ...message.payload, nodeId: state.states.screen.insertMetaRefInfo.contextNodeId },
        })
      }
    },
  },
})
