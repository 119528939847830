import * as React from "react"
import { SVGProps } from "react"

const SvgFileMagnifyingGlass = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" width="1em" height="1em" {...props}>
    <path d="M240 320a64 64 0 1 0-128 0 64 64 0 1 0 128 0zm-8.4 78.3C215.9 409.4 196.7 416 176 416c-53 0-96-43-96-96s43-96 96-96 96 43 96 96c0 20.7-6.6 39.9-17.7 55.6l45.1 45.1c6.2 6.2 6.2 16.4 0 22.6s-16.4 6.2-22.6 0l-45.1-45.1zM352 448V192H240c-26.5 0-48-21.5-48-48V32H64c-17.7 0-32 14.3-32 32v384c0 17.7 14.3 32 32 32h256c17.7 0 32-14.3 32-32zm-.5-288c-.7-2.8-2.1-5.4-4.2-7.4L231.4 36.7c-2.1-2.1-4.6-3.5-7.4-4.2V144c0 8.8 7.2 16 16 16h111.5zM0 64C0 28.7 28.7 0 64 0h156.1C232.8 0 245 5.1 254 14.1l115.9 115.8c9 9 14.1 21.2 14.1 33.9V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64z" />
  </svg>
)

export default SvgFileMagnifyingGlass
