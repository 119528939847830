import React, { ReactNode, useReducer, useEffect } from "react"
import { Dropdown, DropDownProps, Menu } from "antd"
import { Icon, Regular } from "@st4/icons"

type IconSelectorProps = {
  icon: ReactNode
  iconSelected?: ReactNode
  /**
   * A list of value, key tupels
   */
  values: [string, string][]
  /**
   * To set the container of the dropdown menu. The default is to create a div element in body, but you can reset it to the scrolling area and make a relative reposition.
   */
  getPopupContainer?: () => HTMLElement

  /**
   * `true` if only a single value can be selected
   */
  single?: boolean
  /**
   * Callback gets called when the selected items change.
   */
  onItemSelected: (selectedValues: string[]) => void
}

export function IconSelector({ values, icon, getPopupContainer, onItemSelected, iconSelected }: IconSelectorProps) {
  const [selectedValues, toggleSelectedValues] = useReducer((state: Set<string>, action: { key: string }) => {
    if (state.has(action.key)) return new Set([...state].filter((v) => v !== action.key))
    return new Set([...state, action.key])
  }, new Set<string>())

  useEffect(() => {
    onItemSelected && onItemSelected([...selectedValues])
  }, [selectedValues])

  const menu: DropDownProps["menu"] = {
    selectedKeys: Array.from(selectedValues),
    onClick: (menuInfo) => toggleSelectedValues({ key: menuInfo.key as string }),
    items: values.map(([v, l]) => ({
      key: v,
      label: l,
      icon: (
        <Icon
          component={Regular.Check}
          style={{ color: "green", visibility: selectedValues.has(v) ? `visible` : `hidden` }}
        />
      ),
    })),
  }

  return (
    <Dropdown trigger={["click"]} getPopupContainer={getPopupContainer} menu={menu} placement="bottomRight">
      {selectedValues.size && iconSelected ? iconSelected : icon}
    </Dropdown>
  )
}

export default IconSelector
