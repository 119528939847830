import { useEffect, useRef, useState } from "react"

export type ScrollEdgeState = {
  isScrolledToLeft: boolean
  isScrolledToRight: boolean
}

export function useScrollEdgeState() {
  const scrollContainerRef = useRef<HTMLDivElement>(null)
  const [scrollEdgeState, setScrollEdgeState] = useState({ isScrolledToLeft: true, isScrolledToRight: true })

  function updateScrollEdgeState() {
    if (scrollContainerRef.current) {
      const updatedState = calculateElementScrollEdgeState(scrollContainerRef.current)
      setScrollEdgeState((prevState) => {
        if (
          prevState.isScrolledToLeft === updatedState.isScrolledToLeft &&
          prevState.isScrolledToRight === updatedState.isScrolledToRight
        )
          return prevState
        return updatedState
      })
    }
  }

  useEffect(() => {
    if (scrollContainerRef.current) {
      // Because scroll event only gets called after first scroll input the scroll state needs to be calculated
      // manually once after rendering to the DOM
      updateScrollEdgeState()
      scrollContainerRef.current?.addEventListener("scroll", updateScrollEdgeState)

      return () => {
        scrollContainerRef.current?.removeEventListener("scroll", updateScrollEdgeState)
      }
    }
  })

  return { scrollContainerRef, scrollEdgeState: scrollEdgeState }
}
function calculateElementScrollEdgeState(elem: HTMLElement): ScrollEdgeState {
  return {
    isScrolledToLeft: elem.scrollLeft == 0,
    isScrolledToRight: elem.scrollWidth - elem.clientWidth == elem.scrollLeft,
  }
}
