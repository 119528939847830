import * as React from "react"
import { SVGProps } from "react"

const SvgTableColumns = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1em" height="1em" {...props}>
    <path d="M48 416V160h184v272H64c-8.8 0-16-7.2-16-16zm232 16V160h184v256c0 8.8-7.2 16-16 16H280zM64 32C28.7 32 0 60.7 0 96v320c0 35.3 28.7 64 64 64h384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64z" />
  </svg>
)

export default SvgTableColumns
