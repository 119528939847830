import classNames from "classnames"
import React from "react"
import styled from "styled-components"
import { ripple, highlight } from "@st4/theme-manager"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"

// prettier-ignore
const TouchableHighlight_deprecated = styled(function(props) {
  return (
    <div
      className={classNames(props.className, "TouchableHighlight")}
      onClick={props.onClick}
      style={props.style}
    >
      {"to" in props && props.to
        ? <Link to={props.to} className="TouchableHighlight--Link">
            {props.children}
          </Link>
        : props.children}
    </div>
  )
})`
  cursor: pointer;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  ${ripple}
  &:hover {
      ${highlight}
  }

  line-height: 1.42857;

  a:visited, a:link, a:hover, a:active {
    text-decoration: none;
    color: inherit;
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    ${ripple}
  }
`

TouchableHighlight_deprecated.propTypes = {
  onClick: PropTypes.func,
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      pathname: PropTypes.string,
      state: PropTypes.object,
    }),
  ]),
  className: PropTypes.string,
  style: PropTypes.object,
}

export default TouchableHighlight_deprecated
