/**
 * Typeguard to check if a given object is of the graphql type
 * @param typenames The types for witch to check
 * @returns a function that will validate if the object is of the given type
 * @example
 * ```ts
 * if(isTypename("GraphQLType")(myObject)){
 *  // myObject has field `__typename: "GraphQLType"`
 * }
 * else {
 *  // myObject is of different type
 * }
 * ```
 * also allows multiple values:
 * ```ts
 * if(isTypename("Type1", "Type2")(myObject)){
 *  // myObject has either `__typename: "Type1"` or `__typename: "Type2"`
 * }
 * else {
 *  // myObject is of different type
 * }
 * ```
 */
export const isTypename =
  <T extends string>(...typenames: T[]) =>
  <N extends { __typename: string }>(node: N | null | undefined): node is Extract<N, { __typename: T }> => {
    return typenames.some((typename) => node?.__typename === typename)
  }
// Mit dieser Funktion hat man ein Autocomplete für die Typen, wenn man es z.B. in [].filter(isTypename) hineinreicht,
// allerdings passt der TypeGuard nicht, wenn man die Funktion direkt aufruft: isTypename("type")(obj)
// export function isTypename<TTypes extends { __typename: string }, TKeys extends TTypes["__typename"]>(...typenames: TKeys[]) {
//   return (node: TTypes | null | undefined): node is Extract<TTypes, { __typename: TKeys }> => {
//     return typenames.some((typename) => node?.__typename === typename)
//   }
// }

/**
 * Typeguard which checks if a value is neither `null` nor `undefined`
 * @param value the value to check
 * @example
 * ```ts
 * if(notEmpty(obj)){
 *  // obj is neither null nor undefined
 * }
 * myArray.filter(notEmpty) // all null or undefined values are removed from myArray
 * ```
 */
export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined
}

/**
 * Checks whether an object has a field `__typename`
 */
export function hasTypename<T>(obj: unknown): obj is WithTypename<T> {
  return Object.prototype.hasOwnProperty.call(obj, "__typename")
}
/**
 * Extracts a given type form a union based on it's `__typename`
 */
export type ExtractByTypeName<Name extends T["__typename"], T extends { __typename: string | undefined }> = T extends {
  __typename: Name
}
  ? T
  : never

export type WithTypename<T> = T & { __typename: string }

/**
 * Transform an union of graphql objects to an union of their `__typename`s
 */
export type Typenames<T extends { __typename: string }> = T["__typename"]

/**
 * Same as {@link https://www.typescriptlang.org/docs/handbook/utility-types.html#picktype-keys Pick<T, K extends keyof T>} except it includes the `__typename` for GraphQL-Types.
 *
 */
export type PickWithTypename<T extends { __typename: string }, K extends keyof T> = Pick<T, K | "__typename">
