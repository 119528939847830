import * as React from "react"
import { SVGProps } from "react"

const SvgArrowUpLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" width="1em" height="1em" {...props}>
    <path d="M56 96c-13.3 0-24 10.7-24 24v240c0 13.3 10.7 24 24 24s24-10.7 24-24V177.9L311 409c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-231-231H296c13.3 0 24-10.7 24-24s-10.7-24-24-24H56z" />
  </svg>
)

export default SvgArrowUpLeft
