import { useState, useEffect, useCallback, useRef } from "react"
import type { CreateNewNodeJobResponse } from "../../../query.hooks.types"
import {
  useSelectionOfCreateNewNodeConfigInfo,
  useContinueCreateNewNodeJobInteractiveWithUserApply,
} from "../../../query.hooks.utils"
import { useStepSaveBaseDataHandler } from "./StepSaveBaseDataHandler"
import type { StepSaveBaseDataInputValues } from "../types"
import type { ActionHandlerInfo } from "../../types"

export function useStepSaveBaseData(props: {
  jobId: string
  contextNodeId: string
  hideTitle: boolean
  hideNodeClassSelection: boolean
  cachedConfigId?: string | null
  onInputChanged: (inputValues: StepSaveBaseDataInputValues) => void
  onActionsChanged: (info: ActionHandlerInfo) => void
  onMutationCompleted: (result: CreateNewNodeJobResponse) => void
}) {
  const {
    jobId,
    contextNodeId,
    hideTitle,
    hideNodeClassSelection,
    cachedConfigId,
    onInputChanged,
    onActionsChanged,
    onMutationCompleted,
  } = props

  const [configId, setConfigId] = useState<string | null | undefined>(cachedConfigId)
  const inputValues = useRef<StepSaveBaseDataInputValues>({
    title: "",
    configId,
  })
  const apply = useContinueCreateNewNodeJobInteractiveWithUserApply(onMutationCompleted)
  const { data, loading, error } = useSelectionOfCreateNewNodeConfigInfo(contextNodeId)
  const defaultTitle =
    data?.selectionOfCreateNewNodeConfigInfo.items.find((item) => item.id === configId)?.defaultTitle ?? ""

  const { handleClose } = useStepSaveBaseDataHandler(props)

  const handleFinish = useCallback(() => {
    if (!inputValues.current.configId) return
    const newNodeTitle = inputValues.current.title.length > 0 ? inputValues.current.title : defaultTitle
    apply(jobId, hideTitle, hideNodeClassSelection, newNodeTitle, inputValues.current.configId)
  }, [jobId, hideTitle, hideNodeClassSelection, apply, defaultTitle])

  // On change of selection data: Check initial configId selection and setup action buttons
  useEffect(() => {
    function isFinalStep() {
      if (!data) return true

      const { items, selectedItem } = data.selectionOfCreateNewNodeConfigInfo
      const actualConfigId = configId ?? selectedItem?.id
      const actualItem = items.find((i) => i.id == actualConfigId)
      return !actualItem || !actualItem.hasRequiredMetadata
    }

    if (loading) return

    if (
      !error &&
      data &&
      (!configId || !data.selectionOfCreateNewNodeConfigInfo.items.map((i) => i.id).includes(configId))
    ) {
      // Load initial configId if not set
      inputValues.current.configId = data.selectionOfCreateNewNodeConfigInfo.selectedItem?.id
      setConfigId(inputValues.current.configId)
    }

    let actionHandlerInfo: ActionHandlerInfo = { onClose: handleClose }
    // Show next or finish action only if all mandatory data are set
    if (!error && inputValues.current.configId)
      actionHandlerInfo = isFinalStep()
        ? { ...actionHandlerInfo, onFinish: handleFinish }
        : { ...actionHandlerInfo, onNext: handleFinish }

    onActionsChanged(actionHandlerInfo)
  }, [configId, data, loading, error, handleClose, handleFinish, onActionsChanged])

  const handleTitleChange = useCallback(
    (newTitle: string) => {
      inputValues.current.title = newTitle
      onInputChanged(inputValues.current)
    },
    [onInputChanged],
  )

  const handleConfigIdChange = useCallback(
    (newConfigId: string) => {
      inputValues.current.configId = newConfigId
      setConfigId(newConfigId)
      onInputChanged(inputValues.current)
    },
    [onInputChanged],
  )

  return {
    loading,
    data,
    error,
    defaultTitle,
    configId,
    handleFinish,
    handleTitleChange,
    handleConfigIdChange,
  }
}
