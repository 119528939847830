import React from "react"
import { Alert } from "@schema/styled-ui"
import { useTranslation } from "react-i18next"
import { keys } from "@st4/ui-strings"
import { baseTestId } from "../searchFacetsBlade"

export function SearchFacets_NoSearchTerm() {
  const { t } = useTranslation()
  return (
    <div style={{ paddingLeft: "8px", paddingRight: "8px" }}>
      <Alert isWarning>
        <span data-testid={`${baseTestId}-warning-noSearchTerm`}>{t(keys.message.search.warning.noSearchTerm)}</span>
      </Alert>
    </div>
  )
}
