import React from "react"
import styled from "styled-components"
import { visitParents } from "unist-util-visit-parents"
import { XASTViewer } from "../contentComponents/XASTViewer"
import { ProceduralInstructions } from "./ProceduralInstruction"
import type { ProceduralInstructionsProps } from "./ProceduralInstruction"
import { usePreviewContentModel } from "../../contentModel"
import { Icon } from "react-icons-kit"
import { alertTriangle } from "react-icons-kit/feather/alertTriangle"
import { blockCommentBackgroundColor } from "./image/ImageDescriptions"
import { useScrollToFocusedComment } from "../annotationFocusState"
import { Box } from "./structure"
import { GetMediaSource } from "@st4/content-tools"
import { XastRendererProps } from "../XASTRenderer"
import type { Node } from "unist"
import { isST4NodeWithContent } from "../../graphql/types"
import { notEmpty } from "@st4/graphql"
import { useTreeNode } from "../../contentModel/nodeContext"

type Severity = "danger" | "warning" | "caution" | "notice"

function isSeverity(severity?: string): severity is Severity {
  if (!severity) return false
  return ["danger", "warning", "caution", "notice"].includes(severity)
}

type SafetyInstructionProps = {
  severity: Severity
  causes: Node[]
  consequences: Node[]
  icons: string[]
  proceduralInstructions: ProceduralInstructionsProps
  ast: Node
}

function getSeverityColor(severity: Severity, additional?: "_body" | "_headline" | "_border"): SeverityExtended {
  if (!additional) return severity
  return `${severity}${additional}`
}

const Safety = styled.div<{
  severity: Severity
  isCommented: boolean
  isCommentPrefocused: boolean
  isCommentFocused: boolean
}>`
  border: 1px solid ${({ theme, severity }) => theme.safetyInstructions.colors[getSeverityColor(severity, "_border")]};
  border-radius: 3px;
  background: ${({ theme, isCommented, isCommentPrefocused, isCommentFocused }) =>
    blockCommentBackgroundColor(isCommented, isCommentPrefocused, isCommentFocused, theme)};
  display: grid;
  grid-template-columns: 123px 1fr;
  position: relative;

  margin-top: 1em;
  margin-bottom: 1em;
  max-height: none;

  .icons {
    grid-row: 2;
    grid-column: 1;
    width: 110px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid
      ${({ theme, severity }) => theme.safetyInstructions.colors[getSeverityColor(severity, "_border")]};
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        margin: 0;
        padding: 0;
      }
      img {
        margin: 0;
        padding: 5px;
        display: block;
      }
    }
  }

  .content {
    grid-row: 2;
    grid-column: 2;
    padding: 0 0.5em;

    .cause {
      color: ${({ theme }) => theme.greys["700"]};
      font-weight: bold;
      ${Box} {
        margin: 0.5em 0;
      }
    }

    .consequence {
      margin: 0.5em 0;
    }
  }
`

type SeverityExtended =
  | Severity
  | "danger_body"
  | "warning_body"
  | "caution_body"
  | "notice_body"
  | "danger_headline"
  | "warning_headline"
  | "caution_headline"
  | "notice_headline"
  | "danger_border"
  | "warning_border"
  | "caution_border"
  | "notice_border"

const Header = styled.div<{ severity: Severity }>`
  text-transform: uppercase;
  border: 1px solid ${({ theme, severity }) => theme.safetyInstructions.colors[getSeverityColor(severity)]};
  border-bottom: 1px solid
    ${({ theme, severity }) => theme.safetyInstructions.colors[getSeverityColor(severity, "_border")]};
  border-radius: 3px 3px 0 0;
  margin: -1px;
  padding: 0.2em 0.5em;
  background: ${({ theme, severity }) => theme.safetyInstructions.colors[severity]};
  font-weight: bold;
  color: ${({ theme, severity }) => theme.safetyInstructions.colors[getSeverityColor(severity, "_headline")]};
  grid-column: 1 / span 2;
`

const WarningIcon = styled.img`
  max-width: 100%;
`
export function SafetyInstruction(props: React.PropsWithChildren<SafetyInstructionProps>) {
  const { severity, causes, consequences, icons, children, proceduralInstructions, ast } = props

  const previewContentModel = usePreviewContentModel()

  const hasConditions = !!proceduralInstructions.conditions.length
  const hasInstructions = !!proceduralInstructions.instructions.length
  const hasResults = !!proceduralInstructions.results.length
  const displayMeasures = hasConditions || hasInstructions || hasResults
  const displayConsequences = consequences.length > 0
  const displayCauses = causes.length > 0
  const comments = ast.data?.blockcomments ?? []
  const treeNode = useTreeNode()
  const [scrollTargetProps, { isFocused, isPrefocused }, { setFocusedAnnotations, setPrefocusedAnnotations }] =
    useScrollToFocusedComment(comments.map(({ comment: { id } }) => id))
  const isCommented = comments.length > 0
  const isCommentHighlighted = isPrefocused || isFocused
  if (previewContentModel.state != "ready") return null

  return (
    <Safety
      {...scrollTargetProps}
      isCommented={isCommented}
      isCommentFocused={isFocused}
      isCommentPrefocused={isPrefocused}
      severity={severity}
      onClick={() => isCommented && setFocusedAnnotations(comments.map(({ comment }) => ({ comment, treeNode })))}
      onPointerOver={() => {
        isCommented &&
          !isCommentHighlighted &&
          setPrefocusedAnnotations(comments.map(({ comment }) => ({ comment, treeNode })))
      }}
      onPointerLeave={() => {
        isCommented && isCommentHighlighted && setPrefocusedAnnotations([])
      }}
    >
      <Header className="header" severity={severity}>
        <Icon icon={alertTriangle} style={{ position: "relative", top: "-1px", marginRight: "0.5ch" }} />
        {previewContentModel.languageXmlValues.get(severity) ?? severity}
      </Header>
      <div className="icons">
        <ul>
          {icons.map((icon, idx) => (
            <li key={idx}>
              <WarningIcon src={icon} alt="" />
            </li>
          ))}
        </ul>
      </div>
      <div className="content">
        <div style={{ position: "relative", height: "100%" }}>
          {displayCauses && (
            <div className="causes">
              {causes.map((cause, idx) => (
                <div className="cause" key={idx}>
                  <XASTViewer xast={cause} />
                </div>
              ))}
            </div>
          )}
          {displayConsequences && (
            <div className="consequences">
              {consequences.map((consequence, idx) => (
                <div className="consequence" key={idx}>
                  <XASTViewer xast={consequence} />
                </div>
              ))}
            </div>
          )}
          {displayMeasures && (
            <div className="measures">
              <ProceduralInstructions {...proceduralInstructions} />
            </div>
          )}
          {children}
        </div>
      </div>
    </Safety>
  )
}

function useSafetyTypeConfiguration(type?: string) {
  const previewContentModel = usePreviewContentModel()
  if (previewContentModel.state != "ready") return null

  const safetyTypes = previewContentModel.configuration.safetyModel.safetyTypes
  const safetyTypeData = safetyTypes.find((t) => t.id == type)
  return safetyTypeData
}

const EmbeddedSafetySeverity = styled.span`
  font-weight: bold;
`

export function EmbeddedSafetyInstruction(props: React.PropsWithChildren<{ ast: Node }>) {
  const previewContentModel = usePreviewContentModel()
  if (previewContentModel.state != "ready") return null
  const severityAttribute = props.ast.attributes?.["severity"]?.value || ""
  return (
    <EmbeddedSafetySeverity>
      {previewContentModel.languageXmlValues.get(`embedded.${severityAttribute}`) ?? severityAttribute} {props.children}
    </EmbeddedSafetySeverity>
  )
}

export function SafetyInstructionMapper(props: XastRendererProps) {
  const { ast } = props
  let coll = {
    causes: new Array<Node>(),
    consequences: new Array<Node>(),
    proceduralInstructions: {
      conditions: new Array<Node>(),
      instructions: new Array<Node>(),
      results: new Array<Node>(),
    },
  }

  const optionalIconNames = new Array<string>()

  visitParents(ast, "element", (node: Node) => {
    const currentElement = node
    if (!currentElement) return
    const safetyType = currentElement.tagName
    switch (safetyType) {
      case "cause":
        coll = { ...coll, causes: [...coll.causes, currentElement] }
        break
      case "consequence":
        coll = { ...coll, consequences: [...coll.consequences, currentElement] }
        break
      case "safety_condition": {
        const condValue = currentElement
        if (condValue)
          coll = {
            ...coll,
            proceduralInstructions: {
              ...coll.proceduralInstructions,
              conditions: [...coll.proceduralInstructions.conditions, condValue],
            },
          }
        break
      }
      case "safety_instruction":
      case "safety_intermediateresult": {
        const instValue = currentElement
        if (instValue)
          coll = {
            ...coll,
            proceduralInstructions: {
              ...coll.proceduralInstructions,
              instructions: [...coll.proceduralInstructions.instructions, instValue],
            },
          }
        break
      }
      case "safety_result": {
        const resValue = currentElement
        if (resValue)
          coll = {
            ...coll,
            proceduralInstructions: {
              ...coll.proceduralInstructions,
              results: [...coll.proceduralInstructions.results, resValue],
            },
          }
        break
      }
      case "safetyicon":
        if (currentElement.attributes?.name?.value) optionalIconNames.push(currentElement.attributes.name.value)
        break
    }
  })

  const safetyTypeData = useSafetyTypeConfiguration(ast.attributes?.type?.value)
  const icons =
    safetyTypeData?.icons
      .map((icon) => {
        if (
          shouldIconBeUsed(icon, optionalIconNames) &&
          icon.contentNode &&
          isST4NodeWithContent("MediaContent", "MediaGroupContent")(icon.contentNode) &&
          icon.contentNode.content?.media
        )
          return GetMediaSource(icon.contentNode.content.media)
      })
      .filter(notEmpty) ?? []

  const severity =
    ast.attributes?.severity?.value && isSeverity(ast.attributes?.severity?.value)
      ? ast.attributes.severity.value
      : ("notice" as const)

  return <SafetyInstruction {...coll} ast={ast} severity={severity} icons={icons} />
}

function shouldIconBeUsed(icon: { use?: string | null; iconName?: string | null }, optionalIconNames: string[]) {
  return icon.use === "required" || (icon.iconName && optionalIconNames.includes(icon.iconName))
}
