import React, { useMemo } from "react"
import { Checkbox, Form, Radio, Select } from "antd"
import { InfoCircleOutlined, SelectOutlined } from "@ant-design/icons"
import { SearchOptions, SearchVersion } from "../../types"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { keys } from "@st4/ui-strings"
import { getResourceByName } from "../../helpers"
import { Icon, Regular } from "@st4/icons"

const checkboxDataValueNames = ["title", "content", "content.comments"]

type FormOption = {
  name: string
  label: string
  value: string
  selected: boolean
  control: "checkbox" | "select"
}

type SearchOptionsFormProps = {
  availableDataValues: { internalName: string; displayName: string; isUsedByDefault: boolean }[]
  availableLanguages: { id: any; code: string; name: string }[]
  selectedUserLanguage: any
  selectedSearchOptions?: SearchOptions
  onSearchKeysChanged: (newSearchKeys: string[]) => void
  onSearchVersionChanged: (newSearchVersion: SearchVersion) => void
  onSearchLanguagesChanged: (newSearchLanguages: any[]) => void
}

export function SearchOptionsForm(props: SearchOptionsFormProps) {
  const { t } = useTranslation()

  const dataValues = useMemo(prepareDataValues, [props.selectedSearchOptions?.searchKeys])
  const languages = useMemo(prepareLanguages, [props.selectedSearchOptions?.searchLanguages])

  const helpIcon = <Icon component={Regular.CircleInfo} />

  return (
    <Form
      data-testid={"searchOptionsForm"}
      name={"searchOptionsForm"}
      style={{ margin: "8px" }}
      initialValues={props.selectedSearchOptions}
      layout={"vertical"}
    >
      {!!props.availableDataValues.length && (
        <Form.Item
          data-testid={"searchOptionsForm-searchKeys"}
          name={"searchOptionsForm-searchKeys"}
          label={
            <StyledLabel data-testid={"searchOptionsForm-searchKeys-label"}>
              {t(keys.label.search.component.searchOptionsForm.searchKeys)}
            </StyledLabel>
          }
          //tooltip={{ icon: helpIcon, title: t(keys.description.search.component.searchOptionsForm.searchKeys) }}
        >
          <FormSelect
            name="searchableDataValues"
            options={dataValues}
            onChange={(options) =>
              searchKeysChangedHandler(options.filter((opt) => opt.selected).map((opt) => opt.name))
            }
          />
        </Form.Item>
      )}
      <Form.Item
        data-testid={"searchOptionsForm-searchVersion"}
        name={"searchOptionsForm-searchVersion"}
        label={
          <StyledLabel data-testid={"searchOptionsForm-searchVersion-label"}>
            {t(keys.label.search.component.searchOptionsForm.searchVersions)}
          </StyledLabel>
        }
        //tooltip={{ icon: helpIcon, title: t(keys.description.search.component.searchOptionsForm.searchVersions) }}
      >
        <FormRadioGroup
          name={"searchVersion"}
          selected={props.selectedSearchOptions?.searchVersion || "latest"}
          values={[
            { label: t(keys.label.search.component.searchOptionsForm.searchVersion.latest), value: "latest" },
            { label: t(keys.label.search.component.searchOptionsForm.searchVersion.approved), value: "approved" },
            {
              label: t(keys.label.search.component.searchOptionsForm.searchVersion.latestApproved),
              value: "latest-approved",
            },
            { label: t(keys.label.search.component.searchOptionsForm.searchVersion.all), value: "all" },
          ]}
          onChange={(newSearchVersion) => searchVersionChangedHandler(newSearchVersion)}
        />
      </Form.Item>
      {!!props.availableLanguages.length && (
        <Form.Item
          data-testid={"searchOptionsForm-searchLanguages"}
          name={"searchOptionsForm-searchLanguages"}
          label={
            <StyledLabel data-testid={"searchOptionsForm-searchLanguages-label"}>
              {t(keys.label.search.component.searchOptionsForm.searchLanguages)}
            </StyledLabel>
          }
          //tooltip={{ icon: helpIcon, title: t(keys.description.search.component.searchOptionsForm.searchLanguages) }}
        >
          <FormSelect
            name="searchableLanguages"
            options={languages}
            onChange={(options) =>
              searchLanguagesChangedHandler(options.filter((opt) => opt.selected).map((sel) => sel.value))
            }
          />
        </Form.Item>
      )}
    </Form>
  )

  function prepareDataValues(): FormOption[] {
    const selectedDataValues = props.selectedSearchOptions?.searchKeys
    // use map to remove duplicate data values
    const dataValueMap = new Map<string, FormOption & { sortLabel: string }>()
    for (const dataValue of props.availableDataValues)
      if (!dataValueMap.has(dataValue.internalName)) {
        let displayName = dataValue.displayName
        if (!displayName.length) {
          // fetch display name from resource "label.search.component.searchOptionsForm.searcKey.[internal field name]"
          displayName = t(
            getResourceByName(keys.label.search.component.searchOptionsForm.searchKey, dataValue.internalName),
          )
        }
        if (!displayName.length) {
          displayName = `[${dataValue.internalName}]`
        }

        dataValueMap.set(dataValue.internalName, {
          name: dataValue.internalName,
          label: displayName,
          sortLabel: displayName.charAt(0) === "[" ? displayName.slice(1, -1) : displayName,
          value: dataValue.internalName,
          selected: selectedDataValues
            ? selectedDataValues.includes(dataValue.internalName)
            : dataValue.isUsedByDefault,
          control: checkboxDataValueNames.includes(dataValue.internalName.toLowerCase()) ? "checkbox" : "select",
        })
      }
    const allDataValues = Array.from(dataValueMap.values())

    const indexOf = (arr: string[], val: string) => arr.indexOf(val.toLowerCase())
    return [
      ...allDataValues
        .filter((dv) => dv.control == "checkbox")
        .sort((a, b) => indexOf(checkboxDataValueNames, a.name) - indexOf(checkboxDataValueNames, b.name)),
      ...allDataValues.filter((dv) => dv.control == "select").sort((a, b) => a.sortLabel.localeCompare(b.sortLabel)),
    ]
  }

  function prepareLanguages(): FormOption[] {
    const selectedLanguages = props.selectedSearchOptions?.searchLanguages
    function isSelectedByDefault(language: { id: any; code: string }) {
      return language.code.toLowerCase() == "all" || language.id === props.selectedUserLanguage
    }

    const checkboxesOnly = props.availableLanguages.length <= 5
    const allLanguages = props.availableLanguages.map(
      (lang) =>
        ({
          name: lang.code,
          label: lang.name,
          value: lang.id?.toString() || "",
          selected: selectedLanguages ? selectedLanguages?.includes(lang.id) : isSelectedByDefault(lang),
          control: checkboxesOnly || isSelectedByDefault(lang) ? "checkbox" : "select",
        }) as FormOption,
    )

    return [
      ...allLanguages.filter((l) => l.control == "checkbox").sort((a, b) => a.label.localeCompare(b.label)),
      ...allLanguages.filter((l) => l.control == "select").sort((a, b) => a.label.localeCompare(b.label)),
    ]
  }

  function searchKeysChangedHandler(selectedSearchKeys: string[]) {
    props.onSearchKeysChanged(selectedSearchKeys)
  }

  function searchVersionChangedHandler(selectedSearchVersion: SearchVersion) {
    props.onSearchVersionChanged(selectedSearchVersion)
  }

  function searchLanguagesChangedHandler(selectedLanguageIds: any[]) {
    props.onSearchLanguagesChanged(selectedLanguageIds)
  }
}

type FormRadioGroupProps<TValue extends string> = {
  name: string
  selected: TValue
  values: { label: string; value: TValue }[]
  onChange: (newValue: TValue) => void
}

function FormRadioGroup<TValue extends string>(props: FormRadioGroupProps<TValue>) {
  const id = `searchOptionsForm-radio-${props.name}`
  return (
    <RadioGroupContainer>
      <Radio.Group
        id={id}
        data-testid={id}
        value={props.selected}
        onChange={(e) => props.onChange(e.target.value as TValue)}
      >
        {props.values.map((item) => {
          const radioId = `${id}-${item.value}`
          return (
            <CheckboxListContainer key={radioId} data-testid={radioId}>
              <Radio name={radioId} value={item.value}>
                {item.label}
              </Radio>
            </CheckboxListContainer>
          )
        })}
      </Radio.Group>
    </RadioGroupContainer>
  )
}

type FormSelectProps = {
  name: string
  options?: FormOption[]
  onChange: (options: FormOption[]) => void
}

function FormSelect(props: FormSelectProps) {
  const { t } = useTranslation()

  const checkboxOptions = props.options?.filter((opt) => opt.control == "checkbox")
  const selectOptions = props.options?.filter((opt) => opt.control == "select")
  const selectId = `select-${props.name}`
  return (
    <StyledFormSelect>
      {checkboxOptions?.map((opt) => {
        const checkboxId = `checkbox-${props.name}-${opt.name}`
        return (
          <CheckboxListContainer key={checkboxId}>
            <Checkbox
              data-testid={checkboxId}
              checked={opt.selected}
              onChange={(e) => {
                const newCheckboxOptions = !checkboxOptions
                  ? []
                  : checkboxOptions.reduce((newArray, currentItem) => {
                      return [
                        ...newArray,
                        {
                          ...currentItem,
                          selected: currentItem.name == opt.name ? e.target.checked : currentItem.selected,
                        },
                      ]
                    }, new Array<FormOption>())
                props.onChange([...newCheckboxOptions, ...(!selectOptions ? [] : selectOptions)])
              }}
            >
              {opt.label}
            </Checkbox>
          </CheckboxListContainer>
        )
      })}
      {!!selectOptions?.length && (
        <Form.Item
          style={{ marginTop: "8px", marginBottom: "8px" }}
          label={t(keys.label.search.component.searchOptionsForm.select.other)}
        >
          <Select
            id={selectId}
            data-testid={selectId}
            mode="multiple"
            allowClear
            options={selectOptions}
            value={selectOptions?.filter((opt) => opt.selected)}
            optionFilterProp={"label"}
            optionLabelProp={"label"}
            onChange={(_, options) => {
              const selected = (Array.isArray(options) ? options : [options]).map((opt) => opt.name)
              const newSelectOptions = !selectOptions
                ? []
                : selectOptions.reduce((newArray, currentItem) => {
                    return [...newArray, { ...currentItem, selected: selected.includes(currentItem.name) }]
                  }, new Array<FormOption>())
              props.onChange([...(!checkboxOptions ? [] : checkboxOptions), ...newSelectOptions])
            }}
          />
        </Form.Item>
      )}
    </StyledFormSelect>
  )
}

const StyledLabel = styled.span`
  font-size: 1.1em;
  font-weight: bold;
`

const StyledFormSelect = styled.div`
  margin-left: 8px;
  margin-right: 8px;
`

const CheckboxListContainer = styled.div`
  margin-top: 8px;
  margin-bottom: 8px;
`

const RadioGroupContainer = styled.div`
  margin: 8px;
`
