import styled from "styled-components"

export const TaskHeader = styled.div`
  height: 42px;
  margin-left: 12px;
  margin-right: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const TaskTitle = styled.h1`
  margin: 0;
  font-size: 1.25em;
  font-weight: bold;
`

export * from "./ScopeSwitcher"
export * from "./Actionbar"
