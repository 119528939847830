import styled from "styled-components"
import { Alert } from "antd"

// The purpose of this is to allow action buttons to be below all text and to customize spacing
// In AntD the text wraps around the buttons instead.
// Both NotificationBanner and ConfirmBanner use this to ensure they have consistent layout
export const StyledAlert = styled(Alert)`
  padding: ${({ theme }) => theme.token.paddingXS}px;

  display: grid;
  gap: ${({ theme }) => theme.token.paddingXS}px;
  grid-template-areas:
    "icon content close"
    "actions actions actions";
  grid-template-columns: auto 1fr auto;
  grid-template-rows: 1fr auto;

  & .ant-alert-icon {
    grid-area: icon;
    margin-inline-end: 0;
    // Make icon appear centered compared to message text
    margin-block-start: 2px;
    font-size: 1.5em;
  }

  & .ant-alert-content {
    grid-area: content;
  }

  & .ant-alert-close-icon {
    grid-area: close;
    // Make icon appear centered compared to message text
    margin-block-start: 2px;
    font-size: 1.5em;
  }

  & .ant-alert-action {
    grid-area: actions;
    justify-self: end;
  }
`
