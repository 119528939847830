import * as React from "react"
import { SVGProps } from "react"

const SvgRectangleVerticalHistory = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width="1em" height="1em" {...props}>
    <path d="M256 32c-17.7 0-32 14.3-32 32v384c0 17.7 14.3 32 32 32h256c17.7 0 32-14.3 32-32V64c0-17.7-14.3-32-32-32H256zm-64 32c0-35.3 28.7-64 64-64h256c35.3 0 64 28.7 64 64v384c0 35.3-28.7 64-64 64H256c-35.3 0-64-28.7-64-64V64zm-96 0c0-8.8 7.2-16 16-16s16 7.2 16 16v384c0 8.8-7.2 16-16 16s-16-7.2-16-16V64zM0 112c0-8.8 7.2-16 16-16s16 7.2 16 16v288c0 8.8-7.2 16-16 16s-16-7.2-16-16V112z" />
  </svg>
)

export default SvgRectangleVerticalHistory
