import * as React from "react"
import { SVGProps } from "react"

const SvgSt4Buttonremovelink = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1em" height="1em" {...props}>
    <path d="M256 0a256 256 0 1 0 0 512 256 256 0 1 0 0-512zm69.1 466.2c-5.7 5.7-15 5.7-20.8 0L256 417.9l-48.3 48.3c-5.7 5.7-15 5.7-20.8 0-5.7-5.7-5.7-15 0-20.8l48.3-48.3-48.3-48.3c-5.7-5.7-5.7-15 0-20.8s15-5.7 20.8 0l48.3 48.3 48.3-48.3c5.7-5.7 15-5.7 20.8 0s5.7 15 0 20.8l-48.3 48.3 48.3 48.3c5.7 5.7 5.7 15 0 20.8zm86.5-183.6H306.9V177.7H202.1v104.8H97.3V177.7h104.8V73h104.8v104.7h104.8v104.9h-.1z" />
  </svg>
)

export default SvgSt4Buttonremovelink
