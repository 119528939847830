import type { CommentModel, User, ConfigurationModel, PageInfo } from "../graphql/types"
import type { NodeFilterFunction } from "../ast/types"
import type { Node } from "unist"
import type { VariableTable } from "@st4/content-tools"
import type { St4NodeWithContentFragment } from "../graphql/applicationQueries/query.hooks"
import type { TreeNode } from "./nodeContext"

export type PreviewPage = {
  index: number
  nodes: TreeNode[]
}

export type PreviewContentModel = ReadyPreviewContentModel | LoadingPreviewContentModel | EmptyPreviewContentModel

export type ReadyPreviewContentModel = {
  state: "ready"
  /** Aspect Id of current content */
  languageId: string
  contentPageInfo: PageInfo
  treeNodesById: Map<string, TreeNode>
  parentById: Map<string, TreeNode>
  previousNodeById: Map<string, TreeNode>
  validChildrenById: Map<string, string[]>
  variableTableById: Map<string, VariableTable>
  xastById: Map<string, Node>
  treeNodes: TreeNode[]
  commentModel: CommentModel
  user: User
  languageXmlValues: Map<string, string>
  configuration: ConfigurationModel
  contentOutlineTree: { nodes?: TreeNode[] }
  onNodeClick?: (node: TreeNode) => void
  /** Imports externally loaded nodes into the contentmodel. */
  importSt4Node: (node: St4NodeWithContentFragment, nodeFilter: NodeFilterFunction) => void
  /** Contains a Map of stobject-ids to treenode. For ST4Nodes displayed inside (for example fragments), the resulting TreeNode will be the one of the parent */
  treeNodesByNodeId: Map<string, TreeNode>
}

export type LoadingPreviewContentModel = {
  state: "loading"
}

export type EmptyPreviewContentModel = {
  state: "empty"
}

export const EmptyPreviewContentModel: PreviewContentModel = {
  state: "empty",
}

export type Option<T> = T | undefined

export type EmbeddedSt4NodeWithContentFragment = St4NodeWithContentFragment & { parent: St4NodeWithContentFragment }

export function isEmbeddedSt4NodeWithContentFragment(
  node: EmbeddedSt4NodeWithContentFragment | St4NodeWithContentFragment,
): node is EmbeddedSt4NodeWithContentFragment {
  return Object.hasOwn(node, "parent")
}
