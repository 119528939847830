import React, { useEffect, useMemo, useState } from "react"
import { Select, SelectProps } from "antd"
import { SelectOutlined } from "@ant-design/icons"
import styled from "styled-components"
import { NotificationPopover } from "@st4/ui"
import { WarningIcon } from "../warningIcon"

export type SelectionEditorProps = {
  selectedValue?: string
  availableValues: string[]
  isReadonly?: boolean
  isRemovable?: boolean
  onSubmit: (selected: string) => void
  errorMessage?: string
  /**
   * Override default placement of error popover to avoid popover hiding context
   */
  errorPlacement?: React.ComponentProps<typeof NotificationPopover>["placement"]
} & Pick<SelectProps, "suffixIcon">

export function SelectionEditor(props: SelectionEditorProps) {
  const { availableValues, selectedValue, onSubmit, isReadonly, isRemovable, errorMessage, errorPlacement } = props
  let { suffixIcon } = props
  const readonly = isReadonly ?? false
  const [selection, setSelection] = useState(selectedValue)
  const [isFocused, setIsFocused] = useState(false)

  const availableOptionValues = useMemo(() => {
    return availableValues.map((value) => convertToSelectOption(value, readonly))
  }, [availableValues, readonly])

  useEffect(() => {
    setSelection(selectedValue)
  }, [selectedValue])

  if (!suffixIcon && !readonly) suffixIcon = <SelectOutlined />

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (value: any) => {
    setSelection(value)
    onSubmit(value)
  }

  const hasError = !!errorMessage
  return (
    <NotificationPopover
      hideIcon={true}
      open={hasError && isFocused}
      state={"error"}
      placement={errorPlacement ?? "top"}
      content={errorMessage}
    >
      <StyledSelect
        status={hasError ? "error" : ""}
        suffixIcon={hasError ? <WarningIcon /> : suffixIcon ?? " " /*/ TODO: Fontawesome Icon umbau*/}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        value={selection}
        onChange={handleChange}
        allowClear={isRemovable && !readonly}
      >
        {availableOptionValues}
      </StyledSelect>
    </NotificationPopover>
  )
}

function convertToSelectOption(value: string, isReadonly: boolean) {
  return (
    <Select.Option data-testid={"option"} key={value} disabled={isReadonly} label={value}>
      {value}
    </Select.Option>
  )
}

const StyledSelect = styled(Select)`
  background-color: "white";
  width: 100%;
  .ant-select {
    background-color: inherit;
    width: inherit;
  }
`
