import React from "react"
import styled from "styled-components"
import { useNode, useTreeNode } from "../../../contentModel/nodeContext"
import { InlineWithAttributes } from "../Inline"
import type { XastRendererProps } from "../../XASTRenderer"
import { Trans, useTranslation } from "react-i18next"
import { keys } from "@st4/ui-strings"
import { MetaRefDisplay } from "./metaRefDisplay"
import { usePreviewContentModel } from "../../../contentModel"
import { useSt4ToTreeNode } from "../../../utilities/treeUtilities"
import { FilteringTreeNodeWrapper } from "../../FilteringTreeNodeWrapper"
import { isST4NodeWithContent } from "../../../graphql/types"
import { isTypename } from "@st4/graphql"
import { useMetaRefsQuery } from "./query.hooks"

const Underlined = styled.span`
  border-bottom: 1px solid ${({ theme }) => theme.greys[300]};
`

type StaticMetaRefProps = {
  linkId: string
  name: string
}

type LanguageMetaRefProps = {
  name: string
}

type DynamicMetaRefProps = {
  name: string
}

function useLinkedDataNode(linkId: string) {
  const node = useNode()
  if (!isST4NodeWithContent("TextContent", "TextGroupContent")(node)) return
  const content = node.content
  const linkedNodes = content.linkedDataNodes
  return linkedNodes?.find((r) => r.id == linkId)
}

export function StaticMetaRef(props: StaticMetaRefProps) {
  const { t } = useTranslation()
  const linkedNode = useLinkedDataNode(props.linkId)
  const st4ToTreeNode = useSt4ToTreeNode()

  const st4TargetNode = linkedNode?.target
  if (!isTypename("ST4Node")(st4TargetNode)) return null
  const referedMetaData = st4TargetNode.metadata?.find((m) => m.systemName == props.name)
  const treeNode = st4ToTreeNode(st4TargetNode)
  if (!referedMetaData || !st4TargetNode || !treeNode) return null
  if (props.name == "Title") {
    // Special Case (╯°□°)╯︵ ┻━┻
    return (
      <FilteringTreeNodeWrapper treeNodeId={treeNode.id} isInline>
        <MetaRefDisplay>{st4TargetNode.label}</MetaRefDisplay>
      </FilteringTreeNodeWrapper>
    )
  }

  const hasNoValues = referedMetaData.values.length === 0
  const valueForDisplay = hasNoValues ? referedMetaData.label || props.name : referedMetaData.values.join("; ")

  return (
    <FilteringTreeNodeWrapper treeNodeId={treeNode.id} isInline>
      <MetaRefDisplay
        hasError={hasNoValues}
        tooltipHeadline={
          <Trans
            i18nKey={keys.label.preview.metaref.staticHeadline}
            values={{ label: referedMetaData.label }}
            components={[<Underlined key="underlined" />]}
          />
        }
        tooltipContent={
          <>
            {t(keys.label.general.node)}: {st4TargetNode.label} (<code>{referedMetaData.systemName}</code>)
          </>
        }
      >
        {valueForDisplay}
      </MetaRefDisplay>
    </FilteringTreeNodeWrapper>
  )
}

export function OntologyMetaRef(props: StaticMetaRefProps) {
  const { t } = useTranslation()
  const linkedNode = useLinkedDataNode(props.linkId)
  const st4ToTreeNode = useSt4ToTreeNode()

  const st4TargetNode = linkedNode?.target
  if (!isTypename("ST4Node")(st4TargetNode)) return null
  const referedMetaData = st4TargetNode.linkedOntologyNodes
    .filter(isTypename("NodeRef"))
    ?.filter((m) => m.linkClassName == props.name)
    ?.reverse()
  const treeNode = st4ToTreeNode(st4TargetNode)

  if (!referedMetaData || !linkedNode || !treeNode) {
    return null
  }

  if (!referedMetaData.length) {
    return (
      <MetaRefDisplay
        hasError
        tooltipHeadline={t(keys.label.preview.metaref.notFound)}
        tooltipContent={t(keys.message.preview.metaref.ontologyNotSelected, {
          node: st4TargetNode.label,
          taxonomyName: props.name,
        })}
      >
        {props.name}
      </MetaRefDisplay>
    )
  }

  return (
    <FilteringTreeNodeWrapper treeNodeId={treeNode.id} isInline>
      <MetaRefDisplay
        tooltipHeadline={
          <Trans
            i18nKey={keys.label.preview.metaref.ontologyHeadline}
            values={{ label: referedMetaData[0].class?.label }}
            components={[<Underlined key="underlined" />]}
          />
        }
        tooltipContent={
          <>
            {t(keys.label.general.node)}: {st4TargetNode.label}
          </>
        }
      >
        {referedMetaData
          .map((m) => m.target)
          .filter(isTypename("ST4Node"))
          .map((t) => t.label)
          .join("; ")}
      </MetaRefDisplay>
    </FilteringTreeNodeWrapper>
  )
}
export function LanguageMetaRef(props: LanguageMetaRefProps) {
  const { t } = useTranslation()
  const model = usePreviewContentModel()

  if (props.name === "emptyCell") {
    // Special case representing no content
    return null
  } else if (model.state === "ready" && model.languageXmlValues.has(props.name)) {
    return (
      <MetaRefDisplay tooltipHeadline={t(keys.label.preview.metaref.languageHeadline)} tooltipContent={props.name}>
        {model.languageXmlValues.get(props.name)}
      </MetaRefDisplay>
    )
  } else {
    // No Translation available
    return (
      <MetaRefDisplay
        hasError
        tooltipHeadline={t(keys.label.preview.metaref.languageHeadline)}
        tooltipContent={props.name}
      >
        {props.name}
      </MetaRefDisplay>
    )
  }
}

export function DynamicMetaRef(props: DynamicMetaRefProps) {
  const { t } = useTranslation()
  const treeNode = useTreeNode()
  const { data, loading, error } = useMetaRefsQuery({
    variables: { id: treeNode.id, languageId: treeNode.node.aspectId },
    fetchPolicy: "cache-first",
    context: {
      batch: true,
    },
  })

  if (loading || !data) return <MetaRefDisplay isLoading>{props.name}</MetaRefDisplay>

  if (!isTypename("ST4TreeNode")(data.node))
    throw new Error("DynamicMetaRef: Requested TreeNode but received something unexpected")

  const wanted = data.node.dynamicMetaRefs.find((ref) => ref.metaRefPath.id == props.name)
  const metadataDisplayNameWithFallback =
    wanted && wanted.metaRefPath.displayName ? wanted.metaRefPath.displayName : props.name

  if (!wanted?.value || error) {
    return (
      <MetaRefDisplay
        hasError
        tooltipHeadline={t(keys.label.preview.metaref.notFound)}
        tooltipContent={t(keys.message.preview.metaref.dynamicReferenceNotFound, {
          node: treeNode.node.label,
          metadataName: metadataDisplayNameWithFallback,
        })}
      >
        {metadataDisplayNameWithFallback}
      </MetaRefDisplay>
    )
  }
  const description = (
    <Trans
      i18nKey={keys.label.preview.metaref.dynamicReference}
      values={{ metadataName: metadataDisplayNameWithFallback }}
      components={[<Underlined key="underlined" />]}
    />
  )
  return <MetaRefDisplay tooltipContent={description}>{wanted.value}</MetaRefDisplay>
}

export function MetaRef(props: XastRendererProps) {
  const attributes = props.ast.attributes
  if (!attributes) throw new Error("Attributes missing on XAST!")
  const type = attributes["type"]
  switch (type?.value) {
    case "static":
      if (!attributes["linkid"]?.value || !attributes["name"]?.value) return <InlineWithAttributes {...props} />
      return <StaticMetaRef linkId={attributes["linkid"]?.value} name={attributes["name"]?.value} />
    case "ontology":
      if (!attributes["linkid"]?.value || !attributes["name"]?.value) return <InlineWithAttributes {...props} />
      return <OntologyMetaRef linkId={attributes["linkid"]?.value} name={attributes["name"]?.value} />
    case "language":
      if (!attributes["name"]?.value) return <InlineWithAttributes {...props} />
      return <LanguageMetaRef name={attributes["name"]?.value} />
    case "dynamic":
      if (!attributes["name"]?.value) return <InlineWithAttributes {...props} />
      return <DynamicMetaRef name={attributes["name"]?.value} />
    default:
      return <InlineWithAttributes {...props} />
  }
}
