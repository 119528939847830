export type HasDisplayName = {
  displayName: string
}

export function SortProperties<T extends HasDisplayName>(properties: T[], sortSelection: string) {
  const sortAlphaNum = (a: T, b: T) =>
    a.displayName.localeCompare(b.displayName, window.navigator.language, { numeric: true })
  const sortAlphaNumDescending = (a: T, b: T) =>
    b.displayName.localeCompare(a.displayName, window.navigator.language, { numeric: true })
  const sortedProperties = [...properties]
  switch (sortSelection) {
    case "alphanumeric":
      sortedProperties.sort(sortAlphaNum)
      break
    case "descending-alphanumeric":
      sortedProperties.sort(sortAlphaNumDescending)
      break
    default:
  }
  return sortedProperties
}
