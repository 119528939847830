import * as React from "react"
import { SVGProps } from "react"

const SvgCloud = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" width="1em" height="1em" {...props}>
    <path d="M410.8 134.2c-19.3 8.6-42 3.5-55.9-12.5C332.8 96.1 300.3 80 264 80c-66.3 0-120 53.7-120 120v.2c0 20.4-12.8 38.5-32 45.3-37.4 13.2-64 48.8-64 90.5 0 53 43 96 96 96h363.3c.6-.1 1.3-.1 1.9-.2 46.2-2.7 82.8-41 82.8-87.8 0-36-21.6-67.1-52.8-80.7-20.1-8.8-31.6-30-28.1-51.7.6-3.8.9-7.7.9-11.7 0-39.8-32.2-72-72-72-10.5 0-20.4 2.2-29.2 6.2zM512 479.8v.2H144C64.5 480 0 415.5 0 336c0-62.7 40.1-116 96-135.8v-.2c0-92.8 75.2-168 168-168 50.9 0 96.4 22.6 127.3 58.3C406.2 83.7 422.6 80 440 80c66.3 0 120 53.7 120 120 0 6.6-.5 13-1.5 19.3 48 21 81.5 68.9 81.5 124.7 0 72.4-56.6 131.6-128 135.8z" />
  </svg>
)

export default SvgCloud
