import React from "react"
import userflow from "userflow.js"
import { createHash } from "crypto"
import { useAppTokenSettings, isAppTokenReady } from "@st4/settings"

const appName = "userFlow"

type UserOnboardingProps = {
  languageCode: string
  userName?: string | null
}

export function UserOnboarding(props: UserOnboardingProps) {
  const settings = useAppTokenSettings(appName)
  const userFlowToken = isAppTokenReady(settings) ? settings.appTokens.find((t) => t.name == appName)?.token || "" : ""

  if (userFlowToken?.length) {
    if (!props.userName) {
      // no given user name --> no User Onboarding
      !!console && console.warn("Missing user name! Cannot provide User Onboarding!")
    } else if (!props.languageCode.length || props.languageCode.length < 2) {
      // no valid language --> no User Onboarding
      !!console &&
        console.warn(
          "Missing or invalid language '" +
            props.languageCode +
            "! Expected: 'de' or 'en'! Cannot provide User Onboarding!",
        )
    } else {
      try {
        const appVersion =
          !!window.CI_PACKAGE_VERSION && typeof window.CI_PACKAGE_VERSION === "string"
            ? window.CI_PACKAGE_VERSION.split(".", 3).join(".")
            : undefined
        const languageCode = props.languageCode.substring(0, 2)
        const userHash = createHash("sha256").update(props.userName).digest("hex")
        userflow.init(userFlowToken)
        userflow.identify(userHash, { language: languageCode, appVersion: appVersion })
      } catch (error) {
        !!console && console.error("Error initializing userflow.js", error)
        return <div data-testid={"userFlow-error"} />
      }
    }
  }
  return <div data-testid={`userFlow-${userFlowToken?.length ? "on" : "off"}`} />
}

//function showResourceCenter() {
//  if (userflow.isIdentified())
//    userflow.setResourceCenterLauncherHidden(false)
//}
//
//function hideResourceCenter() {
//  if (userflow.isIdentified())
//    userflow.setResourceCenterLauncherHidden(true)
//}
//
//function openResourceCenter() {
//  if (userflow.isIdentified())
//    userflow.openResourceCenter()
//}
//
//function closeResourceCenter() {
//  if (userflow.isIdentified())
//    userflow.closeResourceCenter()
//}
//
//function toggleResourceCenter() {
//  if (userflow.isIdentified()) {
//    const state = userflow.getResourceCenterState()
//    if (!!state) {
//      if (state.isOpen)
//        closeResourceCenter()
//      else
//        openResourceCenter()
//    }
//  }
//}
