import * as React from "react"
import { SVGProps } from "react"

const SvgQuanosQ = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 83 76" xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" width="1em" height="1em" {...props}>
    <path
      d="M37.966.5c20.239 0 36.671 16.432 36.671 36.671s-16.432 36.67-36.671 36.67S1.295 57.41 1.295 37.171 17.727.5 37.966.5Zm0 9.168c15.179 0 27.503 12.323 27.503 27.503 0 15.179-12.324 27.503-27.503 27.503S10.463 52.35 10.463 37.171c0-15.18 12.324-27.503 27.503-27.503Z"
      style={{
        fill: "currentColor",
        fillRule: "evenodd",
        clipRule: "evenodd",
        strokeLinejoin: "round",
        strokeMiterlimit: 2,
      }}
    />
    <circle
      cx={74.705}
      cy={67.5}
      r={7}
      style={{
        fill: "currentColor",
      }}
    />
  </svg>
)

export default SvgQuanosQ
