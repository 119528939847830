import React, { useCallback, useEffect, useState } from "react"
import type { NodeRenderProps } from "../types"
import type { EditorState, TextNodeState } from "./types"
import { useIsEditorSessionOpen } from "./editor/editorSessions"
import { useHandleEditorSession } from "./editor/useHandleEditorSession"
import { LoadingView } from "./editor/LoadingView"
import { NodeActionsContainer } from "./editor/NodeActionsContainer"
import { EditorErrorView } from "./editor/EditorErrorView"
import { hasErrorOpeningEditor } from "./utils"
import { EditorDataProvider } from "@st4/editor"
import { EditMode } from "@st4/content-tools"
import { PreviewParameterContext, ReadonlyPreviewParameterContext } from "../../PreviewParameterContext"
import useEditorState from "./editor/editorState"
import { WarningMessage } from "@st4/graphql"
import { createTranslationArgs } from "@st4/ui-strings"
import { notification } from "antd"
import { getEditCheckMessageKey } from "./editor/messageHelpers/getEditCheckMessageKey"
import { useTranslation } from "react-i18next"

type EditorTextNodeProps = {
  treeNode: NodeRenderProps["treeNode"]
  initialHeight: React.MutableRefObject<number>
  node: NodeRenderProps["node"]
  editMode: EditMode
  setIsEditorError: (isError: boolean) => void
  previewParameters: Exclude<PreviewParameterContext, ReadonlyPreviewParameterContext>
}

export function EditorTextNode({
  treeNode,
  initialHeight,
  node,
  editMode,
  setIsEditorError,
  previewParameters,
}: EditorTextNodeProps) {
  const isEditorInUse = useIsEditorSessionOpen(treeNode.id)
  const [warnings, setInternalWarnings] = useState<WarningMessage[]>([])
  const { languageId, refetchOnEditorSave, messageHub } = previewParameters

  const setWarnings = useCallback(
    (newNotifications: WarningMessage[]) => {
      if (editMode === "inplace") setInternalWarnings([...newNotifications])
    },
    [editMode],
  )

  const { loading, editorSession, sessionError, error } = useHandleEditorSession(
    treeNode.id,
    languageId,
    isEditorInUse,
    setWarnings,
    messageHub,
  )

  const { t } = useTranslation()
  const errorOpeningEditor = hasErrorOpeningEditor(sessionError, error)

  const editorState: EditorState = useEditorState({
    previewParameters: previewParameters,
    nodeId: node.id,
    treeNodeId: treeNode.id,
  })

  useEffect(() => {
    const inTranslationNotificationTime = 20
    const regularNotificationTime = 5

    if (editorState === "Running" && editMode === "inplace") {
      warnings
        .filter((warning) => warning.messageKey)
        .forEach((warning) => {
          console.log("Open editor with warning: ", warning)
          notification["warning"]({
            key: warning.messageKey,
            message: t(
              getEditCheckMessageKey(warning.messageKey),
              createTranslationArgs(warning.messageArgs?.map((arg) => arg.value) ?? []),
            ),
            description: warning.messageKey,
            style: { backgroundColor: "#FFF5D9" },
            duration: warning.messageKey == "EAC1601" ? inTranslationNotificationTime : regularNotificationTime,
          })
        })
    }

    if (editorState === "Error" || errorOpeningEditor) {
      setIsEditorError(true)
    }
  }, [editMode, editorState, setIsEditorError, errorOpeningEditor, t, warnings])

  if (isEditorInUse) return <LoadingView initialHeight={initialHeight.current} />

  if (loading || errorOpeningEditor || !editorSession) {
    const viewElement = errorOpeningEditor ? (
      <EditorErrorView sessionError={sessionError} error={error} messageHub={messageHub} />
    ) : (
      <LoadingView initialHeight={initialHeight.current} />
    )

    return (
      <NodeActionsContainer
        textNodeState="ERROR_INPLACE"
        previewParameters={previewParameters}
        treeNodeId={treeNode.id}
        nodeId={node.id}
      >
        {viewElement}
      </NodeActionsContainer>
    )
  }

  return (
    <NodeActionsContainer
      textNodeState={getTextNodeState(editorState, editMode)}
      previewParameters={previewParameters}
      treeNodeId={treeNode.id}
      nodeId={node.id}
    >
      <EditorDataProvider
        key={node.id}
        nodeId={node.id}
        rootNodeId={treeNode.id}
        languageId={languageId}
        initialHeight={initialHeight.current}
        editorSession={editorSession}
        onToggleAdvancedMode={() =>
          messageHub.sendMessage({
            //editor is openend and blade listens and follows a transition
            action: "editingStarted",
            payload: {
              inplace: false,
              selection: {
                nodeId: node.id,
                treeNodeId: treeNode.id,
              },
            },
          })
        }
        editMode={editMode}
        refetchOnEditorSave={refetchOnEditorSave}
        messageHub={messageHub}
      />
    </NodeActionsContainer>
  )
}

function getTextNodeState(editorState: EditorState, editMode: EditMode): TextNodeState {
  if (editorState === "Error") return editMode === "advanced" ? "ERROR_ADVANCED" : "ERROR_INPLACE"

  if (editMode === "advanced") return "EDIT"

  return "INPLACE_EDIT"
}
