import { useCallback } from "react"
import { useTranslation } from "react-i18next"
import { useClipboard } from "@st4/clipboard"
import { keys } from "@st4/ui-strings"
import { ContextMenuOperationWrapper, ContextMenuOperationWrapperProps } from "../types"
import { useCopyTreesAllowed, useCopyTreesJob } from "./copyJobFacade"
import { useMoveCommand } from "../../commands"

export function usePasteOperationWrapper({
  onOperationSuccess,
  showNotification,
}: ContextMenuOperationWrapperProps): ContextMenuOperationWrapper {
  const { t: translate } = useTranslation()
  const { getData, setData } = useClipboard()
  const copyTreesAllowed = useCopyTreesAllowed()
  const { queryCanMove, executeMove } = useMoveCommand()

  const { startJob } = useCopyTreesJob()

  const queryOperationAllowed = useCallback(
    async (nodeId: string) => {
      const data = getData()
      if (data === undefined) return false

      const sourceNodeId = data.data as string

      if (data.command === "copy") {
        return await copyTreesAllowed(sourceNodeId, nodeId)
      }
      if (data.command === "cut") {
        const result = await queryCanMove(sourceNodeId, nodeId, { relativePosition: "CHILD" })
        return result.type === "SUCCESS" && result.result
      }
      return false
    },
    [getData, copyTreesAllowed, queryCanMove],
  )

  const handleMenuItemClick = useCallback(
    async (nodeId: string) => {
      const data = getData()
      if (data === undefined) return
      const sourceNodeId = data.data as string

      if (data.command === "copy") {
        const result = await startJob(sourceNodeId, nodeId)
        if (typeof result === "string") {
          showNotification("error", translate(keys.label.warning.error), result)
        } else {
          onOperationSuccess(nodeId)
        }
        return
      }

      if (data.command === "cut") {
        const result = await executeMove(sourceNodeId, nodeId, { relativePosition: "CHILD" }, ["NavigationTree"])
        if (result.type === "UNKNOWN_ERROR") {
          showNotification("error", translate(keys.label.warning.error), result.messages.join("\r\n"))
        }
        if (result.type === "SUCCESS" && !result.result) {
          showNotification("warning", translate(keys.label.warning.warning), result.messages?.join("\r\r"))
        } else {
          onOperationSuccess(nodeId)
        }

        setData(undefined)
        return
      }

      throw `paste for command '${data.command}' is not implemented`
    },
    [getData, startJob, showNotification, translate, executeMove, setData],
  )

  return {
    queryOperationAllowed,
    handleMenuItemClick,
  }
}
