import React from "react"
import styled from "styled-components"
import { Box } from "./structure"
import type { ListstyleTypes } from "./lists"
import { List, ListItem } from "./lists"
import type { Node } from "unist"
import type { XastRendererProps } from "../XASTRenderer"
import { XASTViewer } from "./XASTViewer"

export function ProceduralInstructionsMapper(props: XastRendererProps) {
  const { ast } = props
  const getChildren = (tagName: string) =>
    ast.children?.find((n) => n.tagName == tagName)?.children?.filter((n) => n.nodeType !== "Whitespace") ?? []
  const conditions = getChildren("conditions")
  const instructions = getChildren("instructions")
  const results = getChildren("results")
  return <ProceduralInstructions conditions={conditions} instructions={instructions} results={results} ast={ast} />
}

export type ProceduralInstructionsProps = {
  /**
   * Contains all conditions
   */
  conditions: Node[]
  /**
   * Contains instructions and intermediate results.
   */
  instructions: Node[]
  /**
   * Contains all results
   */
  results: Node[]
}

const InstructionList = styled(List)`
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
`

const notMappedTags = new Set(["page-break", "safety", "image-container"])

const mapListStyleToTagName: { [tagName: string]: ListstyleTypes } = {
  condition: { placeholder: "\u2713" },
  instruction: "lower-latin",
  intermediateresult: { placeholder: "→" },
  result: { placeholder: "\u21A6" },
}

//Used when its a single structurefragment element
export function SingleProceduralInstructionItem(props: { ast: Node }) {
  const { ast } = props
  if (!ast.tagName) return null

  return (
    <InstructionList listStyle={mapListStyleToTagName[ast.tagName.replace("safety_", "")]}>
      <InstructionListItem instXast={ast} />
    </InstructionList>
  )
}

function InstructionListItem(props: { instXast: Node }) {
  const { instXast } = props
  if (!instXast.tagName || notMappedTags.has(instXast.tagName)) {
    // these elements are not part of the "list" structure
    return (
      <div>
        <XASTViewer xast={instXast} />
      </div>
    )
  }

  //using type:"root" to circumvent the content mapping (content mapping used when shown in single structure fragments)
  return (
    <ListItem>
      <div style={{ display: "inline-block" }}>
        <XASTViewer xast={{ ...instXast, type: "root" }}></XASTViewer>
      </div>
    </ListItem>
  )
}

export function ProceduralInstructions(props: ProceduralInstructionsProps & Partial<XastRendererProps>) {
  const { conditions, instructions, results, ...remainingProps } = props

  return (
    <Box {...remainingProps}>
      <InstructionList listStyle={mapListStyleToTagName.condition}>
        {conditions.map((cond) => (
          <InstructionListItem key={`${cond.tagName}_${cond.position?.start.offset}`} instXast={cond} />
        ))}
      </InstructionList>
      <InstructionList listStyle={mapListStyleToTagName.instruction}>
        {instructions.map((inst) => {
          if (inst.tagName?.endsWith("intermediateresult")) {
            // `endsWith` to also match `safety_intermediateresult`
            return (
              <InstructionList
                style={{ padding: "0px 0px 0px 1em" }}
                listStyle={mapListStyleToTagName.intermediateresult}
                key={`${inst.tagName}_${inst.position?.start.offset}`}
              >
                <InstructionListItem key={`${inst.tagName}_${inst.position?.start.offset}`} instXast={inst} />
              </InstructionList>
            )
          }
          return <InstructionListItem key={`${inst.tagName}_${inst.position?.start.offset}`} instXast={inst} />
        })}
      </InstructionList>
      <InstructionList listStyle={mapListStyleToTagName.result}>
        {results.map((result) => (
          <InstructionListItem key={`${result.tagName}_${result.position?.start.offset}`} instXast={result} />
        ))}
      </InstructionList>
    </Box>
  )
}
