import * as React from "react"
import { SVGProps } from "react"

const SvgClipboardCheck = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" width="1em" height="1em" {...props}>
    <path d="M320 64h-49.6C263 27.5 230.7 0 192 0s-71 27.5-78.4 64H64C28.7 64 0 92.7 0 128v320c0 35.3 28.7 64 64 64h256c35.3 0 64-28.7 64-64V128c0-35.3-28.7-64-64-64zM80 112v24c0 13.3 10.7 24 24 24h176c13.3 0 24-10.7 24-24v-24h16c8.8 0 16 7.2 16 16v320c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V128c0-8.8 7.2-16 16-16h16zm88-32a24 24 0 1 1 48 0 24 24 0 1 1-48 0zm121 187.6c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-89.7 89.7L129 287c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l53.3 53.3c4.5 4.5 10.6 7 17 7s12.5-2.5 17-7L289 267.6z" />
  </svg>
)

export default SvgClipboardCheck
