import { useCallback } from "react"
import { ContextMenuOperationWrapper, NodeChangingContextMenuOperationWrapperProps } from "../types"
import { useCreateVersion, useCreateVersionAllowed } from "./createversionfacade"
import { useTranslation } from "react-i18next"
import { keys } from "@st4/ui-strings"

export function useCreateVersionOperationWrapper({
  onOperationSuccess,
  showNotification,
}: NodeChangingContextMenuOperationWrapperProps): ContextMenuOperationWrapper {
  const { t: translate } = useTranslation()
  const queryCreateVersionAllowed = useCreateVersionAllowed()
  const { createVersion } = useCreateVersion()

  const handleMenuItemClick = useCallback(
    (nodeId: string) => {
      createVersion(nodeId).then((value) => {
        if (value.errorMessage) {
          showNotification("error", translate(keys.label.warning.error), value.errorMessage)
        }
        if (value.newNodeId) {
          onOperationSuccess(nodeId, value.newNodeId)
        }
      })
    },
    [showNotification, onOperationSuccess, createVersion, translate]
  )

  return {
    queryOperationAllowed: queryCreateVersionAllowed,
    handleMenuItemClick,
  }
}
