import styled from "styled-components"
import { lighten, darken } from "polished"

export const Link_deprecated = styled.a`
  color: ${({ theme, color }) => color || theme.primaryColor};
  text-decoration: none;
  border-bottom: 1px solid ${({ theme, color }) => lighten(0.3, color || theme.primaryColor)};
  transition: all 0.2s ease-out;
  display: inline-block;
  cursor: pointer;
  &:hover {
    color: ${({ theme, color }) => darken(0.2, color || theme.primaryColor)};
    border-bottom: 1px solid ${({ theme, color }) => darken(0.2, color || theme.primaryColor)};
  }
`
