import * as React from "react"
import { SVGProps } from "react"

const SvgFileBinary = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" width="1em" height="1em" {...props}>
    <path d="M64 464c-8.8 0-16-7.2-16-16V64c0-8.8 7.2-16 16-16h160v80c0 17.7 14.3 32 32 32h80v288c0 8.8-7.2 16-16 16H64zM64 0C28.7 0 0 28.7 0 64v384c0 35.3 28.7 64 64 64h256c35.3 0 64-28.7 64-64V154.5c0-17-6.7-33.3-18.7-45.3l-90.6-90.5C262.7 6.7 246.5 0 229.5 0H64zm64 240c-30.9 0-56 25.1-56 56v64c0 30.9 25.1 56 56 56h16c30.9 0 56-25.1 56-56v-64c0-30.9-25.1-56-56-56h-16zm-8 56c0-4.4 3.6-8 8-8h16c4.4 0 8 3.6 8 8v64c0 4.4-3.6 8-8 8h-16c-4.4 0-8-3.6-8-8v-64zm120-56c-13.3 0-24 10.7-24 24s10.7 24 24 24v80c-13.3 0-24 10.7-24 24s10.7 24 24 24h48c13.3 0 24-10.7 24-24s-10.7-24-24-24V264c0-13.3-10.7-24-24-24h-24z" />
  </svg>
)

export default SvgFileBinary
