import * as Types from "@st4/graphql"

import { gql } from "@apollo/client"
import * as Apollo from "@apollo/client"
const defaultOptions = {} as const
export type PropertiesQueryVariables = Types.Exact<{
  languageId: Types.Scalars["AspectSelectorId"]
  selectedNode: Types.Scalars["StPatternContextId"]
  variant: Types.Scalars["String"]
  filter?: Types.InputMaybe<Types.FilterType>
}>

export type PropertiesQuery = {
  __typename: "Query"
  tree?: {
    __typename: "ST4Tree"
    nodes: Array<{
      __typename: "ST4TreeNode"
      id: string
      label: string
      properties:
        | { __typename: "Error"; message: string }
        | {
            __typename: "PropertyListSuccess"
            result: Array<{
              __typename: "Property"
              category: string
              description: string
              displayName: string
              displayValue: string
              editor: {
                __typename: "PropertyEditor"
                id: string
                editorType: string
                displayName: string
                isReadonly: boolean
                origin: Types.PropertyOrigin
                variantName: string
                node: { __typename: "ST4TreeNode"; id: string; node?: { __typename: "ST4Node"; id: string } | null }
                value?:
                  | { __typename: "DateTimePropertyValue"; dateTimeValue?: any | null }
                  | { __typename: "DecimalPropertyValue"; decimalValue?: string | null }
                  | { __typename: "DoublePropertyValue"; doubleValue?: number | null }
                  | { __typename: "IntPropertyValue"; intValue?: number | null }
                  | {
                      __typename: "MultiSelectionPropertyValue"
                      deletable: boolean
                      selectedValues?: Array<string> | null
                      selectableValues?: Array<string> | null
                    }
                  | { __typename: "MultiStringPropertyValue"; stringValues?: Array<string> | null }
                  | {
                      __typename: "SelectionPropertyValue"
                      deletable: boolean
                      selectedValue?: string | null
                      selectableValues?: Array<string> | null
                    }
                  | { __typename: "StringPropertyValue"; stringValue?: string | null }
                  | {
                      __typename: "TaxonomyPropertyValue"
                      isInherited: boolean
                      selectLeavesOnly: boolean
                      color: string
                      taxonomyRootIds: Array<any>
                      linkClass: { __typename: "ST4Class"; id: string }
                      links: Array<{
                        __typename: "NodeRef"
                        id: string
                        target?:
                          | { __typename: "CommentNode" }
                          | { __typename: "ExternalSite" }
                          | { __typename: "ST4Class" }
                          | {
                              __typename: "ST4Node"
                              id: string
                              label?: string | null
                              nodeClass: { __typename: "ST4Class"; classHierarchy: Array<string> }
                            }
                          | { __typename: "ST4TreeNode" }
                          | null
                      }>
                    }
                  | null
              }
            }>
          }
    }>
  } | null
}

export type UpdatePropertyMutationVariables = Types.Exact<{
  input: Types.UpdatePropertyInput
  languageId?: Types.InputMaybe<Types.Scalars["AspectSelectorId"]>
}>

export type UpdatePropertyMutation = {
  __typename: "Mutation"
  updateProperty?:
    | { __typename: "Error"; message: string }
    | {
        __typename: "PropertyUpdateSuccess"
        result?: {
          __typename: "ST4TreeNode"
          node?: { __typename: "ST4Node"; id: string; label?: string | null } | null
        } | null
      }
    | null
}

export const PropertiesDocument = gql`
  query Properties(
    $languageId: AspectSelectorId!
    $selectedNode: StPatternContextId!
    $variant: String!
    $filter: FilterType
  ) {
    tree(languageId: $languageId, variant: "Preview", selectedNodes: [$selectedNode], limit: 1, order: CHILDREN_FIRST) {
      nodes {
        ... on ST4TreeNode {
          __typename
          id
          label
          properties(variantName: $variant, filterType: $filter) {
            __typename
            ... on PropertyListSuccess {
              __typename
              result {
                __typename
                category
                description
                displayName
                displayValue
                editor {
                  id
                  editorType
                  displayName
                  node {
                    id
                    node {
                      id
                    }
                  }
                  isReadonly
                  origin
                  variantName
                  value {
                    ... on IntPropertyValue {
                      intValue
                    }
                    ... on DoublePropertyValue {
                      doubleValue
                    }
                    ... on DecimalPropertyValue {
                      decimalValue
                    }
                    ... on DateTimePropertyValue {
                      dateTimeValue
                    }
                    ... on StringPropertyValue {
                      stringValue
                    }
                    ... on MultiSelectionPropertyValue {
                      deletable
                      selectedValues
                      selectableValues
                    }
                    ... on SelectionPropertyValue {
                      deletable
                      selectedValue
                      selectableValues
                    }
                    ... on MultiStringPropertyValue {
                      stringValues
                    }
                    ... on TaxonomyPropertyValue {
                      isInherited
                      selectLeavesOnly
                      linkClass {
                        id
                      }
                      color
                      taxonomyRootIds
                      links {
                        id
                        target {
                          ... on ST4Node {
                            id
                            label
                            nodeClass {
                              classHierarchy
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            ... on Error {
              __typename
              message
            }
          }
        }
      }
    }
  }
`

/**
 * __usePropertiesQuery__
 *
 * To run a query within a React component, call `usePropertiesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePropertiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePropertiesQuery({
 *   variables: {
 *      languageId: // value for 'languageId'
 *      selectedNode: // value for 'selectedNode'
 *      variant: // value for 'variant'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function usePropertiesQuery(baseOptions: Apollo.QueryHookOptions<PropertiesQuery, PropertiesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PropertiesQuery, PropertiesQueryVariables>(PropertiesDocument, options)
}
export function usePropertiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PropertiesQuery, PropertiesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PropertiesQuery, PropertiesQueryVariables>(PropertiesDocument, options)
}
export type PropertiesQueryHookResult = ReturnType<typeof usePropertiesQuery>
export type PropertiesLazyQueryHookResult = ReturnType<typeof usePropertiesLazyQuery>
export type PropertiesQueryResult = Apollo.QueryResult<PropertiesQuery, PropertiesQueryVariables>
export const UpdatePropertyDocument = gql`
  mutation UpdateProperty($input: UpdatePropertyInput!, $languageId: AspectSelectorId) {
    updateProperty(input: $input, languageId: $languageId) {
      ... on Error {
        message
      }
      ... on PropertyUpdateSuccess {
        result {
          node {
            ... on ST4Node {
              id
              label
            }
          }
        }
      }
    }
  }
`
export type UpdatePropertyMutationFn = Apollo.MutationFunction<UpdatePropertyMutation, UpdatePropertyMutationVariables>

/**
 * __useUpdatePropertyMutation__
 *
 * To run a mutation, you first call `useUpdatePropertyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePropertyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePropertyMutation, { data, loading, error }] = useUpdatePropertyMutation({
 *   variables: {
 *      input: // value for 'input'
 *      languageId: // value for 'languageId'
 *   },
 * });
 */
export function useUpdatePropertyMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdatePropertyMutation, UpdatePropertyMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdatePropertyMutation, UpdatePropertyMutationVariables>(UpdatePropertyDocument, options)
}
export type UpdatePropertyMutationHookResult = ReturnType<typeof useUpdatePropertyMutation>
export type UpdatePropertyMutationResult = Apollo.MutationResult<UpdatePropertyMutation>
export type UpdatePropertyMutationOptions = Apollo.BaseMutationOptions<
  UpdatePropertyMutation,
  UpdatePropertyMutationVariables
>
