import React, { useRef, useEffect, useState } from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { keys } from "@st4/ui-strings"

const TextArea = styled.span<{ maxHeight?: number; minHeight?: number }>`
  border: none;
  display: block;
  background-color: white;
  padding: 4px;
  width: 100%;
  height: 100%;
  overflow: hidden auto;
  resize: none;
  min-height: calc(14px + 1em); /*14px  is a best guess */
  line-height: 20px;
  max-height: ${({ maxHeight }) => (maxHeight ? `${maxHeight}px` : undefined)};
  box-sizing: border-box;
  :focus {
    outline: none !important;
  }
`

const PlaceholdText = styled.span`
  padding: 4px;
  line-height: 20px;
  color: ${({ theme }) => theme.greys[300]};
  position: absolute;
  pointer-events: none;
  top: 0;
`
const Wrapper = styled.div`
  position: relative;
`

type GrowingTextAreaProps = {
  /**
   * The maximum height of the textarea in pixel. The textarea will never get larger as this value.
   */
  maxHeight?: number
  /**
   * A callback which gets called on input. Takes the current value as a parameter.
   * @param {string} text - The text inside the textarea
   */
  changeCallback?: (text: string) => void
  /**
   * Initial value to display.
   */
  defaultValue?: string
  /**
   * A value to display als placeholder if the textarea is empty.
   */
  placeholder?: string
  /**
   * Takes an additional classname
   */
  className?: string
}

/**
 * A Textarea which grows according to its content.
 * @param {Object} props - The props
 * @param {string} props.defaultValue - Initial Value to display
 * @param {Funciton} props.changeCallback - A callback which gets called on input. Takes the current value as a parameter.
 * @param {string} props.placeholder - A value to display als placeholder if the textarea is empty.
 * @param {string} props.className - Takes an additional classname
 * @param {number} props.maxHeight - The maximum height of the textarea in pixel.
 */
export function GrowingTextArea({
  changeCallback,
  maxHeight,
  defaultValue,
  placeholder,
  className,
}: GrowingTextAreaProps) {
  const textDisplayRef = useRef<HTMLSpanElement>(null)
  const { t } = useTranslation()
  placeholder = placeholder || t(keys.label.generic.enterText)
  const [showPlaceholder, setshowPlaceholder] = useState(!defaultValue)

  useEffect(() => {
    if (!textDisplayRef.current) return
    textDisplayRef.current.addEventListener("keyup", textChange) //"input" not available on IE11
    textDisplayRef.current.focus()
    return () => textDisplayRef.current?.removeEventListener("keyup", textChange)
  }, [textDisplayRef.current])

  function textChange() {
    if (!textDisplayRef.current) {
      return
    }
    const text = textDisplayRef.current.innerText
    setshowPlaceholder(!text)
    if (changeCallback) {
      changeCallback(text)
    }
  }

  return (
    <Wrapper className={className}>
      {showPlaceholder && <PlaceholdText className="placehoder">{placeholder}</PlaceholdText>}
      <TextArea className="textarea" contentEditable role="textbox" ref={textDisplayRef} maxHeight={maxHeight}>
        {defaultValue}
      </TextArea>
    </Wrapper>
  )
}
