import * as React from "react"
import { SVGProps } from "react"

const SvgTaxOptLogOpAnd = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 19.5 19.5" {...props}>
    <defs>
      <style>
        {
          ".TaxOpt_LogOp_And_svg__cls-1{fill:none;stroke:#0050b3;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px}"
        }
      </style>
    </defs>
    <g id="TaxOpt_LogOp_And_svg__Komponente_35_1" data-name="Komponente 35 \u2013 1" transform="translate(.75 .75)">
      <g id="TaxOpt_LogOp_And_svg__Gruppe_4755" data-name="Gruppe 4755" transform="translate(-3 -3)">
        <path
          id="TaxOpt_LogOp_And_svg__Pfad_4624"
          data-name="Pfad 4624"
          className="TaxOpt_LogOp_And_svg__cls-1"
          d="M21 13.5v3M13.5 21h3"
        />
        <path
          id="TaxOpt_LogOp_And_svg__Pfad_4625"
          data-name="Pfad 4625"
          className="TaxOpt_LogOp_And_svg__cls-1"
          d="M16.5 9H9.6a.6.6 0 0 0-.6.6v6.9"
        />
        <path
          id="TaxOpt_LogOp_And_svg__Pfad_4626"
          data-name="Pfad 4626"
          className="TaxOpt_LogOp_And_svg__cls-1"
          d="M10.5 21h-.9a.6.6 0 0 1-.6-.6v-.9"
        />
        <path
          id="TaxOpt_LogOp_And_svg__Pfad_4627"
          data-name="Pfad 4627"
          className="TaxOpt_LogOp_And_svg__cls-1"
          d="M21 19.5v.9a.6.6 0 0 1-.6.6h-.9"
        />
        <path
          id="TaxOpt_LogOp_And_svg__Pfad_4628"
          data-name="Pfad 4628"
          className="TaxOpt_LogOp_And_svg__cls-1"
          d="M19.5 9h.9a.6.6 0 0 1 .6.6v.9"
        />
        <path
          id="TaxOpt_LogOp_And_svg__Pfad_4629"
          data-name="Pfad 4629"
          className="TaxOpt_LogOp_And_svg__cls-1"
          d="M3 10.5v-3M7.5 3h3"
        />
        <path
          id="TaxOpt_LogOp_And_svg__Pfad_4630"
          data-name="Pfad 4630"
          className="TaxOpt_LogOp_And_svg__cls-1"
          d="M7.5 15h6.9a.6.6 0 0 0 .6-.6V7.5"
        />
        <path
          id="TaxOpt_LogOp_And_svg__Pfad_4631"
          data-name="Pfad 4631"
          className="TaxOpt_LogOp_And_svg__cls-1"
          d="M4.5 15h-.9a.6.6 0 0 1-.6-.6v-.9"
        />
        <path
          id="TaxOpt_LogOp_And_svg__Pfad_4632"
          data-name="Pfad 4632"
          className="TaxOpt_LogOp_And_svg__cls-1"
          d="M3 4.5v-.9a.6.6 0 0 1 .6-.6h.9"
        />
        <path
          id="TaxOpt_LogOp_And_svg__Pfad_4633"
          data-name="Pfad 4633"
          className="TaxOpt_LogOp_And_svg__cls-1"
          d="M13.5 3h.9a.6.6 0 0 1 .6.6v.9"
        />
      </g>
    </g>
  </svg>
)

export default SvgTaxOptLogOpAnd
