import React from "react"
import styled from "styled-components"

const Dropdown_deprecated = styled(({ className, disabled, options, defaultValue = 0, onChange }) => {
  return (
    <select disabled={disabled} className={className} onChange={(ev) => onChange(options[ev.target.value])}>
      {options.map((o, ix) => {
        return (
          <option value={ix} key={o.label}>
            {o.label}
          </option>
        )
      })}
    </select>
  )
})`
  -mox-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  height: 2em;
  border: none;
  border-radius: 3px;
`

export default Dropdown_deprecated
