import React, { useCallback, useState } from "react"
import { Blade, BladeDefinition } from "@st4/tasks"
import { Alert, Empty } from "antd"
import { PropertiesViewlet } from "~/components/properties"
import { CreateNewNodeProcessVisualizer, ParentNodeVisualization } from "@st4/common-operations"
import { useAspectId } from "@st4/settings"
import { QuanosBlueButton } from "~/ui/quanosbutton"
import type { ActionHandlerInfo } from "@st4/common-operations"
import { Translate } from "@st4/i18n"
import { keys } from "@st4/ui-strings"
import type { TreeItem } from "@st4/ui"

export type ActionInfo = {
  name: string
  contextNode: TreeItem
}

function getRequiredPropertyEditorComponent(nodeId: string, aspectId: string) {
  return (
    <PropertiesViewlet
      selectedNode={nodeId.replace("x-stobject:", "x-pattern-context:/")}
      aspectId={aspectId}
      variant="SCHEMA.ST4.Client.Modules.RequiredMetadataDialog"
    />
  )
}

function useActiveComponent(
  info: ActionInfo | null,
  aspectId: string,
  resetInfo: () => void,
  onActionsChanged: (info: ActionHandlerInfo) => void,
) {
  const onJobCompleted = useCallback(() => resetInfo(), [resetInfo])

  if (info === null) return undefined

  switch (info.name) {
    case "create-new-node": {
      const createNewNodeInfo = info
      return (
        <CreateNewNodeProcessVisualizer
          contextNodeId={createNewNodeInfo.contextNode.id}
          getRequiredPropertyEditorComponent={(nodeId: string) => getRequiredPropertyEditorComponent(nodeId, aspectId)}
          onActionsChanged={onActionsChanged}
          onJobCompleted={onJobCompleted}
        />
      )
    }
    default:
      return (
        <Alert
          message="Nicht implementiert"
          description={"Für die Aktion " + info.name + " ist keine Komponente implementiert!"}
          type="error"
        />
      )
  }
}

function useActiveToolbar(info: ActionInfo | null) {
  if (info === null) return undefined
  switch (info.name) {
    case "create-new-node": {
      const createNewNodeInfo = info
      return <ParentNodeVisualization contextNode={createNewNodeInfo.contextNode} />
    }
    default:
      return (
        <Alert
          message="Nicht implementiert"
          description={"Für die Aktion " + info.name + " ist keine Komponente implementiert!"}
          type="error"
        />
      )
  }
}

type ActionBladeProps = { actionInfo: ActionInfo | null }

type Messages =
  | {
      action: "setInfo"
      payload: null
    }
  | {
      action: "bladeClosed"
      payload: boolean
    }

export const ActionBlade: BladeDefinition<ActionBladeProps, Messages> = function ActionBladeWithContext(props) {
  const { actionInfo, sendMessage } = props

  const aspectId = useAspectId()
  const [actionHandlerInfo, setActionHandlerInfo] = useState<ActionHandlerInfo>({})
  const resetInfo = useCallback(() => sendMessage({ action: "setInfo", payload: null }), [sendMessage])
  const onActionsChanged = useCallback((info) => setActionHandlerInfo(info), [setActionHandlerInfo])
  const activeToolbar = useActiveToolbar(actionInfo)
  const activeComponent = useActiveComponent(actionInfo, aspectId, resetInfo, onActionsChanged)

  return (
    <>
      {activeToolbar && <Blade.Toolbar>{activeToolbar}</Blade.Toolbar>}
      <Blade.Content>{activeComponent ?? <Empty />}</Blade.Content>
      <Blade.Actionbar>
        <div>
          {actionHandlerInfo.onCancel && (
            <QuanosBlueButton onClick={actionHandlerInfo.onCancel}>
              <Translate>{keys.button.general.cancel}</Translate>
            </QuanosBlueButton>
          )}
        </div>
        <div>
          {actionHandlerInfo.onFinish && (
            <QuanosBlueButton onClick={actionHandlerInfo.onFinish}>
              <Translate>{keys.button.general.ok}</Translate>
            </QuanosBlueButton>
          )}
          {actionHandlerInfo.onNext && (
            <QuanosBlueButton onClick={actionHandlerInfo.onNext}>
              <Translate>{keys.button.blade.nextStep}</Translate>
            </QuanosBlueButton>
          )}
        </div>
      </Blade.Actionbar>
    </>
  )
}
ActionBlade.title = <Translate>{keys.description.createNewNodeForm.formTitle}</Translate>
ActionBlade.size = { S: 5, M: 5, L: 5 }
