const WEBAUTHOR_SEARCH_SEARCHTERM = "WebAuthor_Search_SearchTerm"

export function loadSearchTerm() {
  const searchTerm = sessionStorage.getItem(WEBAUTHOR_SEARCH_SEARCHTERM)
  return searchTerm || undefined
}

export function saveSearchTerm(searchTerm: string) {
  sessionStorage.setItem(WEBAUTHOR_SEARCH_SEARCHTERM, searchTerm)
  dispatchEvent(new Event("onSearchTermChanged"))
}
