import React, { useState, useEffect, useRef, useMemo } from "react"
import { GrowingTextArea } from "@schema/styled-ui"
import { useTranslation } from "react-i18next"
import { keys } from "@st4/ui-strings"
import styled from "styled-components"
import { useResizeObserver } from "./resizeObserverHook"
import { useCommentModel } from "./hooks"
import { Button, Tooltip } from "antd"
import { SendOutlined } from "@ant-design/icons"
import IconSelector from "./IconSelector"
import { Icon, Regular, Solid } from "@st4/icons"

const EditorWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  gap: 5px;
  margin: 0 5px;
  // Needs to be explicitly set because the editor is used in a portal.
  // In that case styles are not inherited by another AntD component
  font-size: ${({ theme }) => theme.token.fontSize}px;
  color: ${({ theme }) => theme.token.colorText};
`

const IconContainer = styled.div`
  color: ${({ theme }) => theme.greys[500]};
`

type InlineInputProps = {
  /**
   * This callback gets executed when the save button is pressed.
   * @param {string} text - The content of the input.
   * @param {string} [type] - The coment type (e.g. modification_proposal)
   */
  onSave?: (text: string, type?: string) => void
  onTextChange?: (text: string) => void
  /**
   * The maximum height this container can occupy
   */
  maxHeight?: number
  /**
   * A additional class name.
   */
  className?: string
  /**
   * A map with available comment types (e.g. [modification_proposal]="Modification Proposal")
   */
  availableTypes?: [string, string][]
  /**
   * A placeholder to display if input is empty.
   */
  placeholder?: string
}

/**
 * An inline input with growing text area and save button
 * @param props - Props for the inline input
 */
export function AddCommentInput(props: InlineInputProps) {
  const [text, setText] = useState("")
  const [selectedType, setSelectedType] = useState("")
  const { t } = useTranslation()
  const { maxHeight, onSave, availableTypes } = props
  const [editorChromeHeight, setEditorChromeHeight] = useState(0)
  const containerRef = useRef<HTMLDivElement>(null)
  const size = useResizeObserver(containerRef.current)
  useEffect(() => {
    if (!containerRef.current) return
    const height = size.height
    const textarea = containerRef.current.querySelector(".textarea")
    if (!textarea) return
    const taheight = textarea.getBoundingClientRect().height
    setEditorChromeHeight(height - taheight)
  }, [containerRef.current, size])

  return (
    <EditorWrapper ref={containerRef} className={props.className}>
      <Tooltip mouseEnterDelay={0.5} title={t(keys.label.comment.typeSelection)}>
        <IconContainer>
          <IconSelector
            onItemSelected={(values) => setSelectedType(values[0] || "")}
            values={availableTypes || []}
            getPopupContainer={() => containerRef.current as HTMLElement}
            icon={<Icon component={Regular.Tag} style={{ fontSize: "18px" }} />}
            iconSelected={<Icon component={Solid.Tag} style={{ fontSize: "18px" }} />}
          />
        </IconContainer>
      </Tooltip>
      <GrowingTextArea
        className="textarea"
        placeholder={props.placeholder == undefined ? t(keys.label.comment.placeholder.selection) : props.placeholder}
        maxHeight={maxHeight && maxHeight - editorChromeHeight}
        changeCallback={(t) => {
          if (props.onTextChange) {
            props.onTextChange(t)
          }
          setText(t)
        }}
      />

      <Tooltip mouseEnterDelay={0.5} title={t(keys.button.general.save)}>
        <Button
          type="primary"
          size="small"
          shape="circle"
          disabled={!text}
          onClick={() => onSave && onSave(text, selectedType)}
          /* Icon is centered by default. Due to its shape it needs to be moved to actually look centered */
          icon={<SendOutlined style={{ position: "relative", left: 2 }} />}
        ></Button>
      </Tooltip>
    </EditorWrapper>
  )
}

/**
 * Displays a comment editor, but initializes the allowed types.
 * @param props
 */
AddCommentInput.WithTypes = function WithTypes(props: Omit<InlineInputProps, "availableTypes">) {
  const { t } = useTranslation()
  const { availableTypes } = useCommentModel()
  const availableCommentTypes: [string, string][] = useMemo(
    () => availableTypes.map((t) => [t.value, t.label]),
    [availableTypes],
  )
  return <AddCommentInput {...props} availableTypes={availableCommentTypes} />
}
