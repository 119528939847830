import * as Types from "@st4/graphql"

import { gql } from "@apollo/client"
import * as Apollo from "@apollo/client"
const defaultOptions = {} as const
export type RelatedTopicLinksForNodeQueryVariables = Types.Exact<{
  id: Types.Scalars["ID"]
  languageId: Types.Scalars["AspectSelectorId"]
  variant?: Types.InputMaybe<Types.Scalars["String"]>
}>

export type RelatedTopicLinksForNodeQuery = {
  __typename: "Query"
  node?:
    | { __typename: "CommentNode" }
    | { __typename: "ExternalSite" }
    | { __typename: "ST4Class" }
    | {
        __typename: "ST4Node"
        id: string
        leavingLinks: Array<
          | {
              __typename: "GenericRef"
              id: string
              target?:
                | { __typename: "CommentNode"; id: string }
                | { __typename: "ExternalSite"; id: string }
                | { __typename: "ST4Class"; id: string }
                | {
                    __typename: "ST4Node"
                    id: string
                    label?: string | null
                    icon?: {
                      __typename: "IconSet"
                      default?: { __typename: "TreeIconData"; uri?: string | null } | null
                    } | null
                  }
                | { __typename: "ST4TreeNode"; id: string }
                | null
            }
          | {
              __typename: "NodeRef"
              id: string
              target?:
                | { __typename: "CommentNode"; id: string }
                | { __typename: "ExternalSite"; id: string }
                | { __typename: "ST4Class"; id: string }
                | {
                    __typename: "ST4Node"
                    id: string
                    label?: string | null
                    icon?: {
                      __typename: "IconSet"
                      default?: { __typename: "TreeIconData"; uri?: string | null } | null
                    } | null
                  }
                | { __typename: "ST4TreeNode"; id: string }
                | null
            }
        >
      }
    | { __typename: "ST4TreeNode" }
    | null
}

export const RelatedTopicLinksForNodeDocument = gql`
  query RelatedTopicLinksForNode($id: ID!, $languageId: AspectSelectorId!, $variant: String) {
    node(id: $id, languageId: $languageId, variant: $variant) {
      ... on ST4Node {
        id
        leavingLinks(linkClass: "RelatedTopic") {
          id
          target {
            __typename
            id
            ... on ST4Node {
              id
              icon {
                default {
                  uri
                }
              }
              label
            }
          }
        }
      }
    }
  }
`

/**
 * __useRelatedTopicLinksForNodeQuery__
 *
 * To run a query within a React component, call `useRelatedTopicLinksForNodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useRelatedTopicLinksForNodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRelatedTopicLinksForNodeQuery({
 *   variables: {
 *      id: // value for 'id'
 *      languageId: // value for 'languageId'
 *      variant: // value for 'variant'
 *   },
 * });
 */
export function useRelatedTopicLinksForNodeQuery(
  baseOptions: Apollo.QueryHookOptions<RelatedTopicLinksForNodeQuery, RelatedTopicLinksForNodeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<RelatedTopicLinksForNodeQuery, RelatedTopicLinksForNodeQueryVariables>(
    RelatedTopicLinksForNodeDocument,
    options,
  )
}
export function useRelatedTopicLinksForNodeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RelatedTopicLinksForNodeQuery, RelatedTopicLinksForNodeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<RelatedTopicLinksForNodeQuery, RelatedTopicLinksForNodeQueryVariables>(
    RelatedTopicLinksForNodeDocument,
    options,
  )
}
export type RelatedTopicLinksForNodeQueryHookResult = ReturnType<typeof useRelatedTopicLinksForNodeQuery>
export type RelatedTopicLinksForNodeLazyQueryHookResult = ReturnType<typeof useRelatedTopicLinksForNodeLazyQuery>
export type RelatedTopicLinksForNodeQueryResult = Apollo.QueryResult<
  RelatedTopicLinksForNodeQuery,
  RelatedTopicLinksForNodeQueryVariables
>
