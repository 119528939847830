import { TreeDropInfo, TreeOperation, TreeState } from "@st4/ui"
import { keys } from "@st4/ui-strings"
import { useCallback } from "react"
import { RelativeInsertionPosition, useMoveCommand } from "../commands"

export function useDnd() {
  const { queryCanMoveAsChildForMultipleTargets, executeMove } = useMoveCommand()

  const canDropDraggedNodeOnTargets = useCallback(
    async (
      draggedNodeId: string,
      dropTargetsToValidate: string[],
      operation: TreeOperation,
      showError: (translatableMessageKey: string) => void,
    ) => {
      if (operation === "COPY_TREENODE") throw "copy operation is not implemented"

      const result = await queryCanMoveAsChildForMultipleTargets(draggedNodeId, dropTargetsToValidate)

      if (result.type === "UNKNOWN_ERROR") {
        handleError(["UNKNOWN_ERROR", ...result.messages], showError)
        return []
      }

      if (result.type === "INCORRECT_RESULT_COUNT") {
        handleError(["TRY_AGAIN"], showError)
        return []
      }

      const allowedNodes: string[] = []
      for (let index = 0; index < dropTargetsToValidate.length; index++) {
        if (result.result[index]) {
          allowedNodes.push(dropTargetsToValidate[index])
        }
      }
      return allowedNodes
    },
    [queryCanMoveAsChildForMultipleTargets],
  )

  const handleDrop = useCallback(
    async (
      dropInfo: TreeDropInfo,
      treeState: React.MutableRefObject<TreeState | undefined>,
      showError: (translatableMessageKey: string) => void,
    ) => {
      if (dropInfo.treeOperation === "COPY_TREENODE") throw "copy operation is not implemented"
      if (dropInfo.draggedItems.length > 1) throw "multi drag handling is not implemented"

      if (dropInfo.draggedItems.length !== 1) {
        handleError(["TRY_AGAIN"], showError)
        return
      }

      const result = await executeMove(
        dropInfo.draggedItems[0].id,
        dropInfo.draggedOverItem.id,
        createInsertionPosition(dropInfo, treeState),
        ["NavigationTree"],
      )

      if (result.type === "UNKNOWN_ERROR") {
        handleError(["UNKNOWN_ERROR", ...result.messages], showError)
        return
      }

      if (result.type === "SUCCESS" && !result.result) {
        handleError(result.messages ?? ["UNKNOWN_ERROR"], showError)
      }
    },
    [executeMove],
  )

  return { canDropDraggedNodeOnTargets, handleDrop }

  function handleError(errorInfoStack: string[], showError: (translatableMessageKey: string) => void) {
    console.log("Error in drag and drop component:", errorInfoStack)
    showError(keys.message.tree.dnd.tryAgain)
  }
}

function createInsertionPosition(
  dropInfo: TreeDropInfo,
  treeState: React.MutableRefObject<TreeState | undefined>,
): RelativeInsertionPosition {
  if (dropInfo.dropInsertionType === "INSERT_AS_DESCENDANT") return { relativePosition: "CHILD" }

  return {
    relativePosition: dropInfo.dropInsertionType === "INSERT_AS_NEXT_SIBLING" ? "AFTER" : "BEFORE",
    idOfParentOfTargetNode: getParentNodeOfDropTarget(dropInfo, treeState),
  }
}

function getParentNodeOfDropTarget(
  dropInfo: TreeDropInfo,
  treeState: React.MutableRefObject<TreeState | undefined>,
): string {
  if (dropInfo.dropInsertionType === "INSERT_AS_DESCENDANT") return dropInfo.draggedOverItem.id

  if (!treeState.current) throw "tree state is necessary when moving as sibling"

  const parent = treeState.current.findParent(dropInfo.draggedOverItem.id)
  if (!parent) throw "no parent found to move node to"

  return parent
}
