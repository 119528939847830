import * as Types from "@st4/graphql"

import { gql } from "@apollo/client"
import * as Apollo from "@apollo/client"
const defaultOptions = {} as const
export type VariableTableByVariablePoolQueryVariables = Types.Exact<{
  selectedNode: Types.Scalars["ID"]
  aspectId: Types.Scalars["AspectSelectorId"]
  variant: Types.Scalars["String"]
}>

export type VariableTableByVariablePoolQuery = {
  __typename: "Query"
  node?:
    | { __typename: "CommentNode"; id: string }
    | { __typename: "ExternalSite"; id: string }
    | { __typename: "ST4Class"; id: string }
    | { __typename: "ST4Node"; id: string }
    | {
        __typename: "ST4TreeNode"
        id: string
        node?: {
          __typename: "ST4Node"
          content?:
            | { __typename: "LexiconContent" }
            | { __typename: "LexiconEntryContent" }
            | { __typename: "MediaContent" }
            | { __typename: "MediaGroupContent" }
            | { __typename: "ProjectContent" }
            | { __typename: "TermContent" }
            | { __typename: "TextContent" }
            | { __typename: "TextGroupContent" }
            | {
                __typename: "VariableTableContent"
                valueSets: Array<{ __typename: "ValueSet"; name: string; valueSetId: string }>
                variables: Array<{ __typename: "Variable"; name: string; variableId: string; values: Array<string> }>
              }
            | null
        } | null
      }
    | null
}

export const VariableTableByVariablePoolDocument = gql`
  query VariableTableByVariablePool($selectedNode: ID!, $aspectId: AspectSelectorId!, $variant: String!) {
    node(id: $selectedNode, languageId: $aspectId, variant: $variant) {
      id
      ... on ST4TreeNode {
        node {
          content {
            ... on VariableTableContent {
              valueSets {
                name
                valueSetId
              }
              variables {
                name
                variableId
                values
              }
            }
          }
        }
      }
    }
  }
`

/**
 * __useVariableTableByVariablePoolQuery__
 *
 * To run a query within a React component, call `useVariableTableByVariablePoolQuery` and pass it any options that fit your needs.
 * When your component renders, `useVariableTableByVariablePoolQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVariableTableByVariablePoolQuery({
 *   variables: {
 *      selectedNode: // value for 'selectedNode'
 *      aspectId: // value for 'aspectId'
 *      variant: // value for 'variant'
 *   },
 * });
 */
export function useVariableTableByVariablePoolQuery(
  baseOptions: Apollo.QueryHookOptions<VariableTableByVariablePoolQuery, VariableTableByVariablePoolQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<VariableTableByVariablePoolQuery, VariableTableByVariablePoolQueryVariables>(
    VariableTableByVariablePoolDocument,
    options,
  )
}
export function useVariableTableByVariablePoolLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VariableTableByVariablePoolQuery,
    VariableTableByVariablePoolQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<VariableTableByVariablePoolQuery, VariableTableByVariablePoolQueryVariables>(
    VariableTableByVariablePoolDocument,
    options,
  )
}
export type VariableTableByVariablePoolQueryHookResult = ReturnType<typeof useVariableTableByVariablePoolQuery>
export type VariableTableByVariablePoolLazyQueryHookResult = ReturnType<typeof useVariableTableByVariablePoolLazyQuery>
export type VariableTableByVariablePoolQueryResult = Apollo.QueryResult<
  VariableTableByVariablePoolQuery,
  VariableTableByVariablePoolQueryVariables
>
