import * as React from "react"
import { SVGProps } from "react"

const SvgArrowDownToSquare = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1em" height="1em" {...props}>
    <path d="M385 233c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-71 71V24c0-13.3-10.7-24-24-24s-24 10.7-24 24v246.1l-71-71c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9L239 345c9.4 9.4 24.6 9.4 33.9 0L385 233zM72 32C32.2 32 0 64.2 0 104v336c0 39.8 32.2 72 72 72h368c39.8 0 72-32.2 72-72V104c0-39.8-32.2-72-72-72h-48c-13.3 0-24 10.7-24 24s10.7 24 24 24h48c13.3 0 24 10.7 24 24v336c0 13.3-10.7 24-24 24H72c-13.3 0-24-10.7-24-24V104c0-13.3 10.7-24 24-24h48c13.3 0 24-10.7 24-24s-10.7-24-24-24H72z" />
  </svg>
)

export default SvgArrowDownToSquare
