import React, { useState } from "react"
import { TreeItem } from ".."
import { CreateButton, MeatballMenuButton, TreeViewNodeProps } from "."

type NodeButtonsProps = Pick<TreeViewNodeProps, "isSelected" | "meatballMenu" | "createButton"> & {
  isHover: boolean
  node: TreeItem
}

export function NodeButtons({ node, isHover, isSelected, meatballMenu, createButton }: NodeButtonsProps) {
  const [isMeatballDropdownOpen, setIsMeatballDropdownOpen] = useState(false)

  return (
    <span style={{ display: "flex" }}>
      {meatballMenu && (isHover || isSelected || isMeatballDropdownOpen) && (
        <MeatballMenuButton
          nodeId={node.id}
          isDropdownOpen={isMeatballDropdownOpen}
          onDropdownOpenChange={setIsMeatballDropdownOpen}
          onMenuDisplay={meatballMenu.onMenuDisplay}
          onMenuItemClick={meatballMenu.onMenuItemClick}
          dropdownRenderForNode={meatballMenu.dropdownRenderForNode}
        />
      )}
      {createButton && (isHover || isSelected || isMeatballDropdownOpen) && (
        <CreateButton
          node={node}
          onClick={createButton.onClick}
          tooltipTitle={createButton.tooltipTitle}
          style={{ marginLeft: 2 }}
          iconStyle={isMeatballDropdownOpen && !isHover && !isSelected ? { visibility: "hidden" } : undefined}
        />
      )}
    </span>
  )
}
