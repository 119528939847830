import React, { PropsWithChildren, CSSProperties, SyntheticEvent } from "react"
import styled, { css } from "styled-components"
import * as widgets from "../widgets"
import { isString, notEmpty } from "../../utilities"
import { useScrollToFocusedComment } from "../annotationFocusState"
import type { ComplexComponentProps, StringComponentProps, XastRendererProps } from "../XASTRenderer"
import type { Node as XASTNode } from "unist"
import { useTranslation } from "react-i18next"
import { useTreeNode } from "../../contentModel/nodeContext"

export const Label = styled.div`
  font-size: 11px;
  color: #999;
`

// the void Close Tags are a combination of
// https://github.com/facebook/react/blob/b87aabdfe1b7461e7331abb3601d9e6bb27544bc/packages/react-dom/src/shared/omittedCloseTags.js
// and
// https://github.com/facebook/react/blob/b87aabdfe1b7461e7331abb3601d9e6bb27544bc/packages/react-dom/src/shared/voidElementTags.js
// they dont allow children and will throw an error!
const voidElementTags = {
  area: true,
  base: true,
  br: true,
  col: true,
  embed: true,
  hr: true,
  img: true,
  input: true,
  keygen: true,
  link: true,
  meta: true,
  param: true,
  source: true,
  track: true,
  wbr: true,
  menuitem: true,
} as Record<string, boolean>

type ReactRenderable = React.ComponentType | string

function Tag({
  component,
  ...props
}: PropsWithChildren<Omit<ComplexComponentProps<{ className: string; style?: CSSProperties }>, "key" | "as">>) {
  if (!component) throw new Error("Cannot Render passedComponent!")
  if (!isString(component)) {
    return React.createElement(component, props, props.children)
  } else {
    const { xml, ...restprops } = props
    return React.createElement(component, restprops, voidElementTags[component] ? null : restprops.children)
  }
}

interface _NodeProps extends ComplexComponentProps, StringComponentProps {
  className?: string
  style?: CSSProperties
}

function _Node(props: _NodeProps) {
  const component = props.component || "div"
  // values, described by Component.propTypes will be passed down from the mapping.
  const propTypeSet = new Set(component.propTypes)
  const restprops = component.propTypes
    ? Object.fromEntries(
        Object.entries(props).filter(([k]) => {
          propTypeSet.has(k)
        }),
      )
    : {}
  let labelComponent
  if (props.label) {
    if (isString(props.label)) {
      labelComponent = <Label>{props.label}</Label>
    } else if (props.translationKey) {
      labelComponent = (
        <Label>
          <Translation translationKey={props.translationKey} />
        </Label>
      )
    } else {
      labelComponent = <Label>props.xml.nodeName?.toUpperCase()</Label>
    }
  } else {
    labelComponent = null
  }

  return (
    <Tag
      component={component}
      xml={props.xml}
      ast={props.ast}
      className={`Node ${props.className}`}
      style={props.style}
      {...restprops}
    >
      {labelComponent}
      {props.children}
    </Tag>
  )
}

// One off Component for _Node because useTranslation() cannot be used conditionally and doesnt accept undefined
function Translation({ translationKey }: { translationKey: string }) {
  const { t } = useTranslation()
  const text = t(translationKey)
  return <> {text} </>
}

export const Node = styled(_Node)``

const SimpleBox = styled.div`
  position: relative;
`

const WidgetContainer = styled.aside`
  font-size: 1rem;
  font-weight: normal;
`
WidgetContainer.displayName = "WidgetContainer"

type WidgetNames = keyof typeof widgets

export type WidgetAreas = {
  widgetTopLeft?: React.ComponentType | WidgetNames
  widgetTopCenter?: React.ComponentType | WidgetNames
  widgetTopRight?: React.ComponentType | WidgetNames
  widgetMiddleLeft?: React.ComponentType | WidgetNames
  widgetMiddleRight?: React.ComponentType | WidgetNames
  widgetBottomLeft?: React.ComponentType | WidgetNames
  widgetBottomCenter?: React.ComponentType | WidgetNames
  widgetBottomRight?: React.ComponentType | WidgetNames
}

/* returns an Array with two elements.
  The first is the props arg without the widgets
  The second one is an array with the widgets in order top-left to bottom right
*/
function getWidgets<T extends WidgetAreas>(props: T) {
  const widgetAreaMapping: { [wa in keyof WidgetAreas]: string } = {
    widgetTopLeft: "tl",
    widgetTopCenter: "tc",
    widgetTopRight: "tr",
    widgetMiddleLeft: "cl",
    widgetMiddleRight: "cr",
    widgetBottomLeft: "fl",
    widgetBottomCenter: "fc",
    widgetBottomRight: "fr",
  }

  const widgetNamesOrComponents = (Object.keys(widgetAreaMapping) as Array<keyof WidgetAreas>)
    .map((areaName) => {
      const wdg = props[areaName] || null
      return [widgetAreaMapping[areaName], wdg] as [string, typeof wdg]
    })
    .filter(notEmpty)
  const widgetComponents = widgetNamesOrComponents.map((widgetNameOrElement) => {
    const cssAreaName = widgetNameOrElement[0]
    const widgetComp = widgetNameOrElement[1]
    let value
    if (isString(widgetComp)) value = widgets[widgetComp]
    else value = widgetComp as React.ComponentType
    return [cssAreaName, value] as [typeof cssAreaName, typeof value]
  })
  const exclusion = ["widgetProperties", ...Object.keys(widgetAreaMapping)]
  const propsWithoutWidgets = Object.fromEntries(Object.entries(props).filter(([k]) => !exclusion.includes(k)))
  return [propsWithoutWidgets, widgetComponents] as const
}

type UnstyledWidgetContainerProps = WidgetAreas &
  XastRendererProps & {
    className?: string
    innerClassName?: string
    widgetProperties?: Record<string, unknown>
  }

const UnstyledWidgetContainer = React.forwardRef(function UnstyledWidgetContainer(
  { className, innerClassName, ...props }: PropsWithChildren<UnstyledWidgetContainerProps>,
  ref: React.Ref<HTMLDivElement>,
) {
  const [restProps, widgetElements] = getWidgets(props)
  const { children, ...originalProps } = restProps
  const widgetsBeforeContent = widgetElements.slice(0, 4)
  const widgetsAfterContent = widgetElements.slice(4)
  return (
    <div className={className + " " + innerClassName}>
      {widgetsBeforeContent.map((widget, idx) => (
        <WidgetContainer key={idx} className={`widget-grid-area-${widget[0]}`}>
          {widget[1] && React.createElement(widget[1] as ReactRenderable, originalProps)}
        </WidgetContainer>
      ))}

      <div ref={ref} style={{ gridArea: "cc" }} {...restProps}></div>

      {widgetsAfterContent.map((widget, idx) => (
        <WidgetContainer key={idx} className={`widget-grid-area-${widget[0]}`}>
          {widget[1] && React.createElement(widget[1] as ReactRenderable, originalProps)}
        </WidgetContainer>
      ))}
    </div>
  )
})

const BoxWithWidgets = styled(UnstyledWidgetContainer)`
  display: grid;

  grid-template-columns: 1fr;
  grid-template-rows: 1fr;

  /* 
    "tl tc tr"
    "cl cc cr"
    "fl fc fr"
  */

  & .widget-grid-area-tl {
    grid-row: 1;
    grid-column: 1;
  }
  & .widget-grid-area-tc {
    grid-row: 1;
    grid-column: 2;
  }
  & .widget-grid-area-tr {
    grid-row: 1;
    grid-column: 3;
  }
  & .widget-grid-area-cl {
    grid-row: 2;
    grid-column: 1;
  }
  & .widget-grid-area-cc {
    grid-row: 2;
    grid-column: 2;
  }
  & .widget-grid-area-cr {
    grid-row: 2;
    grid-column: 3;
  }
  & .widget-grid-area-bl {
    grid-row: 3;
    grid-column: 1;
  }
  & .widget-grid-area-bc {
    grid-row: 3;
    grid-column: 2;
  }
  & .widget-grid-area-br {
    grid-row: 3;
    grid-column: 3;
  }
`

export type BoxProps = Partial<UnstyledWidgetContainerProps> & {
  withWidgets?: boolean
  className?: string
  ast?: XASTNode
} & Partial<JSX.IntrinsicElements["div"]>

function DynamicBoxWithoutRef(
  { withWidgets = false, className, ...props }: PropsWithChildren<BoxProps>,
  ref: React.Ref<HTMLDivElement>,
) {
  return withWidgets ? (
    <BoxWithWidgets ref={ref} {...props} innerClassName={className} />
  ) : (
    <SimpleBox ref={ref} className={className} {...props} />
  )
}
export const DynamicBox = React.forwardRef(DynamicBoxWithoutRef)

const BoxWithAnnotationHighlighting = styled(DynamicBox)<{
  $isAnnotated?: boolean
  $isFocused?: boolean
  $isPrefocused?: boolean
}>`
  margin-top: 1em;
  margin-bottom: 1em;
  ${({ $isAnnotated, $isFocused, $isPrefocused, theme }) => {
    return $isAnnotated
      ? css`
          background: ${$isFocused
            ? theme.preview.annotations.focusColor.background
            : $isPrefocused
            ? theme.preview.annotations.preColor.background
            : theme.preview.annotations.primaryColor["100"]};
          border: 1px solid
            ${$isFocused
              ? theme.preview.annotations.focusColor.border
              : $isPrefocused
              ? theme.preview.annotations.preColor.border
              : theme.preview.annotations.primaryColor["400"]};
          border-radius: 2px;
          padding-top: 0.5em;
          padding-bottom: 0.5em;
        `
      : null
  }}
`

function _Box(props: PropsWithChildren<BoxProps>) {
  try {
    const treeNode = useTreeNode()
    const currentComments = props.ast?.data?.blockcomments
    const commentIds = currentComments?.map(({ comment: { id } }) => id)
    /* eslint-disable react-hooks/rules-of-hooks */
    const [scrollTargetProps, { isFocused, isPrefocused }, { setPrefocusedAnnotations, setFocusedAnnotations }] =
      useScrollToFocusedComment(commentIds ?? [])
    const isCommented = !!currentComments?.length

    return (
      <BoxWithAnnotationHighlighting
        {...scrollTargetProps}
        {...props}
        onPointerOver={(e: SyntheticEvent<HTMLDivElement, PointerEvent>) => {
          e.stopPropagation()
          isCommented && setPrefocusedAnnotations(currentComments.map(({ comment }) => ({ comment, treeNode })))
        }}
        onPointerLeave={(e: SyntheticEvent<HTMLDivElement, PointerEvent>) => {
          {
            e.stopPropagation()
            isCommented && setPrefocusedAnnotations([])
          }
        }}
        onPointerDown={(e: SyntheticEvent<HTMLDivElement, PointerEvent>) => {
          e.stopPropagation()
          isCommented && setFocusedAnnotations(currentComments.map(({ comment }) => ({ comment, treeNode })))
        }}
        $isFocused={isFocused}
        $isPrefocused={isPrefocused}
        $isAnnotated={isCommented}
      >
        {props.children}
      </BoxWithAnnotationHighlighting>
    )
  } catch (e) {
    return <BoxWithAnnotationHighlighting {...props}>{props.children}</BoxWithAnnotationHighlighting>
  }
}
export const Box = styled(_Box)<PropsWithChildren<BoxProps>>`` //as styled component to allow referencing in other styled components

export const Block = styled(Box)`
  border: 1px solid #ddd;
  border-radius: 2px;
  padding: 1em;
  margin-bottom: 1em;
  margin-top: 1em;
  & ~ & {
    margin-top: 0;
  }
`

export const Structure = styled(Box)``

//const marginWidth = "18%"
const marginWidth = "0px"
const pageBorderWidth = "2.5em"
export const pageLayout = {
  marginWidth,
  pageBorderWidth,
  full: css`
    margin-left: calc(-1 * (${marginWidth} + 2.5em));
    width: calc(100% + (${marginWidth} + 2.5em));
  `,
  left: css`
    left: ${pageBorderWidth};
    position: absolute;
    /* Possible Solution:
    float: left;
    clear:left;
     */
    max-width: calc(${marginWidth} - ${pageBorderWidth});
  `,
  right: css`
    padding-left: calc(${marginWidth} + ${pageBorderWidth});
    padding-right: ${pageBorderWidth};
  `,
}
