export * from "./index.common"

export { default as i18n } from "./i18n"

export function getUsername() {
  return localStorage.getItem("SlimClientUsername")
}

export function setUsername(value: string) {
  localStorage.setItem("SlimClientUsername", value)
}

export function unsetUsername(value: string) {
  localStorage.removeItem("SlimClientUsername")
}

export function getUserDisplayname() {
  return localStorage.getItem("SlimClientUserDisplayname")
}

export function setUserDisplayname(value: string) {
  localStorage.setItem("SlimClientUserDisplayname", value)
}

export function unsetUserDisplayname() {
  localStorage.removeItem("SlimClientUserDisplayname")
}

export function getUserLoginName() {
  return localStorage.getItem("SlimClientUserLoginName")
}

export function setUserLoginName(value: string) {
  localStorage.setItem("SlimClientUserLoginName", value)
}

export function unsetUserLoginName() {
  localStorage.removeItem("SlimClientUserLoginName")
}

export { default as dayjs } from "./dayJsWithLocales"

/***
 * Items with identical groups (provided by selectGroup) are inserted into a single array
 * Items inside groups are in the order they were in the original array
 * @param selectGroup Function to select a string from the item to group by
 * @param array Items to be grouped
 */
export function groupBy<T>(selectGroup: (t: T) => string, array: T[]): Map<string, T[]> {
  const groupMap = new Map<string, T[]>()
  array.forEach((el) => {
    const key = selectGroup(el)
    groupMap.set(key, [...(groupMap.get(key) ?? []), el])
  })
  return groupMap
}
