import * as React from "react"
import { SVGProps } from "react"

const SvgSt4ComponentGroupLight = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1em" height="1em" {...props}>
    <path d="M79.1 219.8c2.6 7.7 0 17.9-7.7 20.5l-33.3 15.4 210.2 94.8c2.6 2.6 5.1 2.6 7.7 2.6s5.1 0 7.7-2.6l210.2-94.8-33.3-15.4c-7.7-2.6-12.8-12.8-7.7-20.5 2.6-7.7 12.8-12.8 20.5-7.7l41 17.9c10.3 5.1 17.9 15.4 17.9 25.6 0 10.3-7.7 20.5-17.9 25.6l-217.9 97.4c-5.1 2.6-12.8 5.1-20.5 5.1s-15.4-2.6-23.1-5.1L17.6 281.3C7.3 276.2-.3 265.9-.3 255.7c0-10.3 7.7-20.5 17.9-25.6l41-17.9c7.7-5.2 15.4-.1 20.5 7.6zm-41 164.1 210.2 94.8c2.6 2.6 5.1 2.6 7.7 2.6s5.1 0 7.7-2.6l210.2-94.8-33.3-15.4c-7.7-2.6-12.8-12.8-7.7-20.5 2.6-7.7 12.8-12.8 20.5-7.7l41 17.9c10.3 5.1 17.9 15.4 17.9 25.6 0 10.3-7.7 20.5-17.9 25.6l-217.9 97.4c-5.1 2.6-12.8 5.1-20.5 5.1s-15.4-2.6-23.1-5.1L17.6 409.5C7.3 404.4-.3 394.1-.3 383.9c0-10.3 7.7-20.5 17.9-25.6l41-17.9c7.7-2.6 17.9 0 20.5 7.7s0 17.9-7.7 20.5l-33.3 15.3zm207.6-382c7.7-2.6 15.4-2.6 20.5 0l97.4 35.9c12.8 2.6 20.5 15.4 20.5 28.2v123c0 12.8-7.7 25.6-20.5 30.8l-97.4 35.9c-7.7 2.6-15.4 2.6-20.5 0l-97.4-35.9c-12.8-5.1-20.5-17.9-20.5-30.8V66c0-12.8 7.7-25.6 20.5-30.8l97.4-33.3zM256 32.7l-87.2 30.8L256 94.2l87.2-30.8L256 32.7zm15.4 184.5 79.5-28.2V94.2l-79.5 28.2v94.8z" />
  </svg>
)

export default SvgSt4ComponentGroupLight
