import { AuthenticationErrorCause, isTypename } from "@st4/graphql"
import { keys } from "@st4/i18n"
import { useCreateAuthenticationTokenMutation } from "./query.hooks"
import { AuthData, SessionData, ADFSResult } from "./types"
import { getOrCreateDeviceID } from "./utils"

export type Result =
  | { status: "Ok"; sessionData: SessionData }
  | { status: "Error"; message: string; cause: AuthenticationErrorCause }

export function useLoginRequest() {
  const [mutation] = useCreateAuthenticationTokenMutation()
  async function login({ username, password, language, deviceId, license, forceLogin }: AuthData): Promise<Result> {
    if (!deviceId) {
      try {
        deviceId = await getOrCreateDeviceID()
      } catch (e) {
        console.log(`Error: Could not get DeviceId: ${(e as Error).toString()}`)
        deviceId = "123"
      }
    }
    try {
      const mutationResult = await mutation({
        variables: {
          loginData: {
            license,
            username,
            password,
            languageCode: language,
            deviceId,
            forceLogin,
          },
        },
      })
      const authResponse = mutationResult.data?.createAuthenticationToken
      if (!authResponse) {
        return { status: "Error", message: "Unable to login.", cause: "UNKNOWN" }
      }

      if (isTypename("AuthenticationSuccess")(authResponse)) {
        return {
          status: "Ok",
          sessionData: {
            authId: authResponse.authenticationToken,
            displayname: authResponse.displayName,
            loginName: authResponse.loginName,
            username: authResponse.userName,
            guiAspectId: authResponse.guiAspectId,
          },
        }
      } else {
        return {
          status: "Error",
          message: authResponse.message,
          cause: authResponse.cause,
        }
      }
    } catch (e) {
      return { status: "Error", message: (e as Error).toString(), cause: "UNKNOWN" }
    }
  }
  return login
}

export async function sendADFSLoginRequest(adfsResult: ADFSResult): Promise<Result> {
  try {
    const resp = await fetch("/api/authorize", {
      body: JSON.stringify({
        providerType: "adfs",
        token: adfsResult.wresult,
        language: adfsResult.wctx?.LanguageCode || "de-DE",
        deviceId: adfsResult.wctx?.DeviceId || "",
      }),
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })

    switch (resp.status) {
      case 200: {
        const data = (await resp.json()) as SessionData
        return { status: "Ok", sessionData: data }
      }
      case 401:
        return { status: "Error", message: keys.message.error.auth.wrongPassword, cause: "INVALID_CREDENTIALS" }
      case 403:
        return { status: "Error", message: keys.message.error.auth.licenseLimit, cause: "LICENSE_LIMIT_REACHED" }
      default:
        return { status: "Error", message: keys.message.error.auth.generic, cause: "UNKNOWN" }
    }
  } catch {
    return { status: "Error", message: keys.message.error.auth.generic, cause: "UNKNOWN" }
  }
}
