import React from "react"
import styled from "styled-components"
import { Tooltip } from "@schema/styled-ui"
import UnstyledEditIcon from "@schema/react-ionicons/components/MdTime"
import UnstyledCreateIcon from "@schema/react-ionicons/components/MdCreate"
import { useTranslation } from "react-i18next"
import { keys } from "@st4/ui-strings"
import { dateFnsLocale } from "../../utilities/dates"
import { format, formatDistanceToNow } from "date-fns"
import { notEmpty } from "@st4/graphql"

const Badge = styled.div`
  flex: 1;
  display: flex;
  cursor: default;
`

const Title = styled.span`
  display: block;
  font-size: ${({ theme }) => theme.token.fontSizeLG}px;
`

const Time = styled.span``

const Content = styled.div`
  flex: 1;
`

const Icon = styled.div`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
`

interface PersonBadgeProps {
  name?: string
  date?: string
  title: string
  icon?: React.ReactNode
}

//TODO: Besseren und allgemeineren Namen finden?
function PersonBadge({ name, date, title, icon }: PersonBadgeProps) {
  const parsedDate = new Date(date ? Date.parse(date) : new Date())
  const fromNow = formatDistanceToNow(parsedDate, { locale: dateFnsLocale(), addSuffix: true })
  return !name || !date || name === "" || date === "" ? (
    <Badge>
      {icon && <Icon>{icon}</Icon>}
      <Content>
        <Title>{title}</Title>
        <span>-/-</span>
      </Content>
    </Badge>
  ) : (
    <Badge>
      {icon && <Icon>{icon}</Icon>}
      <Content>
        <Title>{title}</Title>
        <Tooltip hint={format(parsedDate, "LLLL", { locale: dateFnsLocale() })}>
          {name} <Time>{fromNow}</Time>
        </Tooltip>
      </Content>
    </Badge>
  )
}

const EditIcon = styled(UnstyledEditIcon)`
  vertical-align: middle;
  margin-right: 0.5ch;
`

const CreateIcon = styled(UnstyledCreateIcon)`
  vertical-align: middle;
  margin-right: 0.5ch;
`

const ContentPersonInfo = styled.div`
  display: flex;
  flex: 0 0 auto;
  width: 100%;

  & > div {
    padding-right: 25px;
  }
`

export function PersonInfo({ node }: NodeProp) {
  return (
    <ContentPersonInfo>
      <AuthorBadge node={node} />
      <ModifierBadge node={node} />
    </ContentPersonInfo>
  )
}

type NodeProp = {
  node: {
    properties?: {
      edges?: Array<
        | {
            node?: {
              name?: string
              value?: string
            } | null
          }
        | undefined
        | null
      > | null
    } | null
  }
}

function AuthorBadge({ node }: NodeProp) {
  const { t } = useTranslation()
  const contentCreator = node.properties?.edges?.filter(notEmpty).find(({ node }) => node?.name == "creator")
  const roundtripContentCreationTime = node.properties?.edges
    ?.filter(notEmpty)
    .find(({ node }) => node?.name == "roundtripCreationTime")

  return (
    <PersonBadge
      icon={<CreateIcon color="grey" />}
      title={t(keys.label.widget.createdBy)}
      name={contentCreator?.node?.value}
      date={roundtripContentCreationTime?.node?.value}
    />
  )
}

function ModifierBadge({ node }: NodeProp) {
  const { t } = useTranslation()
  const contentModificator = node.properties?.edges
    ?.filter(notEmpty)
    .find(({ node }) => node?.name == "contentModificator")
  const roundtripContentModificationTime = node.properties?.edges
    ?.filter(notEmpty)
    .find(({ node }) => node?.name == "roundtripContentModificationTime")
  return (
    <PersonBadge
      icon={<EditIcon color="grey" />}
      title={t(keys.label.widget.lastChangeBy)}
      name={contentModificator?.node?.value}
      date={roundtripContentModificationTime?.node?.value}
    />
  )
}
