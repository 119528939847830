import * as React from "react"
import { SVGProps } from "react"

const SvgSt4CommentParagraphLight = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1em" height="1em" {...props}>
    <path d="M256 64C125.8 64 32 148.6 32 240c0 37.1 15.5 70.6 40 100 5.2 6.3 8.4 14.8 7.4 23.9-3.1 27-11.4 52.5-25.7 76.3-.5.9-1.1 1.8-1.6 2.6 11.1-2.9 22.2-7 32.7-11.5l6.4 14.7-6.4-14.7c17-7.4 33-16.7 48.4-27.4 8.5-5.9 19.4-7.5 29.2-4.2C193 410.1 224 416 256 416c130.2 0 224-84.6 224-176S386.2 64 256 64zM0 240C0 125.2 114.5 32 256 32s256 93.2 256 208-114.5 208-256 208c-36 0-70.5-6.7-103.8-17.9-.2-.1-.5 0-.7.1-16.9 11.7-34.7 22.1-53.9 30.5C73.6 471.1 44.7 480 16 480c-6.5 0-12.3-3.9-14.8-9.8s-1.1-12.8 3.4-17.4c8.1-8.2 15.2-18.2 21.7-29C38 404.2 45 383.2 47.6 360.7c0 0-.1-.1-.1-.2C19.6 327.1 0 286.6 0 240zm232.6-100.8h109.3c8.6 0 15.6 7 15.6 15.6s-7 15.6-15.6 15.6h-15.6v171.8c0 8.6-7 15.6-15.6 15.6s-15.6-7-15.6-15.6V170.4h-15.6v171.8c0 8.6-7 15.6-15.6 15.6s-15.6-7-15.6-15.6v-46.8h-15.6c-43.1 0-78.1-34.9-78.1-78.1s34.8-78.1 78-78.1z" />
  </svg>
)

export default SvgSt4CommentParagraphLight
