import * as React from "react"
import { SVGProps } from "react"

const SvgSt4StructureFragmentGroupLight = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1em" height="1em" {...props}>
    <path d="M79.4 219.5c2.6 7.7 0 17.9-7.7 20.5l-33.3 15.4 209.9 94.7c2.6 2.6 5.1 2.6 7.7 2.6s5.1 0 7.7-2.6l209.9-94.7-33.3-15.4c-7.7-2.6-12.8-12.8-7.7-20.5 2.6-7.7 12.8-12.8 20.5-7.7l41 17.9c10.2 5.1 17.9 15.4 17.9 25.6s-7.7 20.5-17.9 25.6l-217.6 97.3c-5.1 2.6-12.8 5.1-20.5 5.1s-15.4-2.6-23-5.1L17.9 281C7.7 275.8 0 265.6 0 255.4s7.7-20.5 17.9-25.6l41-17.9c7.7-5.2 15.3-.1 20.5 7.6zm-41 163.9 209.9 94.7c2.6 2.6 5.1 2.6 7.7 2.6s5.1 0 7.7-2.6l209.9-94.7-33.3-15.4c-7.7-2.6-12.8-12.8-7.7-20.5 2.6-7.7 12.8-12.8 20.5-7.7l41 17.9c10.2 5.1 17.9 15.4 17.9 25.6s-7.7 20.5-17.9 25.6l-217.6 97.3c-5.1 2.6-12.8 5.1-20.5 5.1s-15.4-2.6-23-5.1L17.9 409C7.7 403.8 0 393.6 0 383.4s7.7-20.5 17.9-25.6l41-17.9c7.7-2.6 17.9 0 20.5 7.7s0 17.9-7.7 20.5l-33.3 15.3zM245.8 1.9c7.7-2.6 12.8-2.6 20.5 0l99.8 38.4c10.2 5.1 17.9 15.4 17.9 25.6v122.9c0 12.8-7.7 23-17.9 25.6l-99.8 38.4c-7.7 2.6-12.8 2.6-20.5 0L146 214.4c-10.2-5.1-17.9-15.4-17.9-25.6V65.9c0-12.8 7.7-23 17.9-25.6l99.8-38.4zM258.6 25H256l-97.3 35.8L256 99.2l97.3-38.4L258.6 25zm-105 168.9 89.6 33.3V119.7L151 83.8v105c0 2.6 0 2.6 2.6 5.1zm115.2 33.3 89.6-33.3c2.6 0 2.6-2.6 2.6-2.6V83.8l-92.2 35.8v107.6z" />
  </svg>
)

export default SvgSt4StructureFragmentGroupLight
