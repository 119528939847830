import styled from "styled-components"
import Toolbar_deprecated from "./Toolbar_deprecated"

// prettier-ignore
const Footer_deprecated = styled(Toolbar_deprecated)`
  padding-top: 5px;
  padding-bottom: 5px;
  background: "#efefef"
  justify-content: space-around;
`

export default Footer_deprecated
