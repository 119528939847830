import React from "react"
import { Alert } from "@schema/styled-ui"
import { Translate } from "@st4/i18n"

type AlertQuestionProps = {
  messageKey: string
}

export function AlertQuestion(props: AlertQuestionProps) {
  const { messageKey } = props

  return (
    <div style={{ paddingLeft: "8px", paddingRight: "8px" }}>
      <Alert isQuestion>
        <Translate>{messageKey}</Translate>
      </Alert>
    </div>
  )
}
