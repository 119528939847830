export function handlePostOperationStates(data, context) {
    var operationStates = data.metadata.body;
    context.sendMessage({
        action: "sendAllowedOperations",
        payload: {
            operationNames: operationStates ? Object.keys(operationStates) : []
        }
    });
    return {
        status: 200,
        body: {}
    };
}
