import { useCallback } from "react"
import type { CreateNewNodeJobResponse } from "../../../query.hooks.types"
import { useContinueCreateNewNodeJobInteractiveWithUserCancel } from "../../../query.hooks.utils"

export function useStepSaveBaseDataHandler(props: {
  jobId: string
  onMutationCompleted: (result: CreateNewNodeJobResponse) => void
}) {
  const { jobId, onMutationCompleted } = props

  const cancel = useContinueCreateNewNodeJobInteractiveWithUserCancel(onMutationCompleted)
  const cancelWithoutNotification = useContinueCreateNewNodeJobInteractiveWithUserCancel()

  const handleCancel = useCallback(() => {
    cancel(jobId)
  }, [jobId])

  const handleClose = useCallback(
    (notify: boolean) => {
      if (notify) cancel(jobId)
      else cancelWithoutNotification(jobId)
    },
    [jobId],
  )

  return {
    handleCancel,
    handleClose,
  }
}
