import { Empty } from "antd"
import React, { useCallback, useEffect } from "react"
import { Blade, BladeSize, useMenuTranstionOptions, defineMemoizedBlade } from "@st4/tasks"
import { Content, ContentSelection, ContentMessage } from "@st4/content"
import { useAspectId } from "@st4/settings"
import { getHelpContext, setHelpContext, replaceHelpContext, setToPreviousHelpContext } from "~/hooks/helpContext"
import { Translate, keys } from "@st4/i18n"
import { Message } from "@st4/message-hub"
import { ContentTarget } from "@st4/content"
import { useEditorNotifications } from "../editorBlade/useEditorNotifications"
import { PoolBladeNotifications } from "~/components/treeActions/PoolBladeNotifications"

type ContentBladeMessage = ContentMessage

type ContentBladeProps = {
  rootNodeId?: string
  selection?: ContentSelection
  editedNodes: string[]
  readonly: boolean
  singleNodeMode?: boolean
  size?: BladeSize
  refetchOnEditorSave?: string[]
  initialScrollTarget?: Array<[string, ContentTarget]>
}

export const ContentBlade = defineMemoizedBlade<ContentBladeProps, ContentBladeMessage>(
  <Translate>{keys.label.contentBlade.title}</Translate>,
  function ContentBladeWithContext(props) {
    const aspectId = useAspectId()
    const { rootNodeId, readonly, editedNodes, singleNodeMode, selection, messageHub, initialScrollTarget } = props
    const currentHelpKey = getHelpContext()
    useEffect(() => {
      SetEditorHelp(currentHelpKey, readonly, editedNodes.length > 0, !!singleNodeMode)
    }, [currentHelpKey, editedNodes.length, readonly, singleNodeMode])

    const notifications = useEditorNotifications({ nodeId: selection?.nodeId ?? "", messageHub })
    const mappedInitialScrollTarget = new Map(initialScrollTarget)

    const mostVisibleNode = useCallback(
      (nodeId) => {
        messageHub.sendMessage({
          action: "scrollTargetChanged",
          payload: {
            scrollTarget: nodeId,
          },
        })
      },
      [messageHub],
    )

    const { followTransition, transitions } = useMenuTranstionOptions("editorMenu")

    useEffect(() => {
      const handleEditingStarted = (message: Message) => {
        if (message.action === "editingStarted" && !message.payload.inplace) {
          const nodeId = message.payload.selection.nodeId
          const treeNodeId = message.payload.selection.treeNodeId
          followTransition("explore-->edit", { nodeId, treeNodeId })
        }
      }
      messageHub.observe(handleEditingStarted)
      return () => {
        messageHub.unobserve(handleEditingStarted)
      }
    }, [messageHub, followTransition, selection])

    const nodeMenuItems = transitions.map((t) => ({
      label: t.options.label,
      key: t.name,
      icon: t.options.icon,
      onClick(nodeId: string, treeNodeId: string) {
        return followTransition(t.name, { nodeId, treeNodeId })
      },
    }))

    if (!rootNodeId) {
      return (
        <Blade.Content>
          <Empty />
        </Blade.Content>
      )
    } else {
      return (
        <>
          <Blade.Content>
            <Content
              key={rootNodeId + singleNodeMode}
              rootNodeId={rootNodeId}
              languageId={aspectId}
              mode="REVIEW"
              disableMetadataDrawer={true}
              collapsibleComments={!singleNodeMode}
              initialCommentsCollapsed={true}
              readonly={readonly || undefined}
              editedNodes={editedNodes}
              singleNodeMode={singleNodeMode}
              selection={selection ?? null}
              messageHub={messageHub}
              refetchOnEditorSave={props.refetchOnEditorSave}
              nodeContextMenu={nodeMenuItems}
              initialScrollTarget={mappedInitialScrollTarget?.get(rootNodeId)}
              onTopNodeChanged={mostVisibleNode}
            />
          </Blade.Content>
          <PoolBladeNotifications notifications={notifications} />
        </>
      )
    }
  },
)

ContentBlade.size = (props: ContentBladeProps) => {
  return props.size ?? { S: 18, M: 12, L: 9 }
}
ContentBlade.reducer = function reducer(previousState, message) {
  switch (message.action) {
    case "selectionChanged":
      return {
        ...previousState,
        selection: message.payload,
      }
    case "scrollTargetChanged":
      return {
        ...previousState,
        initialScrollTarget: setInitialScrollTarget(
          previousState.initialScrollTarget,
          previousState.rootNodeId ?? "",
          message.payload.scrollTarget,
        ),
      }
    case "editingStarted":
      if (previousState.readonly) return previousState
      return {
        ...previousState,
        singleNodeMode: !message.payload.inplace,
        selection: message.payload.selection,
        editedNodes: [...previousState.editedNodes, message.payload.selection?.nodeId],
        initialScrollTarget: setInitialScrollTarget(previousState.initialScrollTarget, previousState.rootNodeId ?? "", {
          outer: message.payload.selection?.treeNodeId,
        }),
      }
    case "editingFinished":
      return {
        ...previousState,
        singleNodeMode: false,
        selection: message.payload.selection,
        editedNodes:
          previousState.editedNodes.length === 1
            ? []
            : previousState.editedNodes.filter((id) => id != message.payload.selection?.nodeId),
      }
  }

  return previousState
}

function setInitialScrollTarget(
  prevInitialScrollTarget: Array<[string, ContentTarget]> | undefined,
  rootNodeId: string,
  scrollTarget: ContentTarget,
): Array<[string, ContentTarget]> {
  if (!prevInitialScrollTarget) {
    return [[rootNodeId, scrollTarget]]
  }
  const newInitialScrollTarget = [...prevInitialScrollTarget]
  const rootNodeIndex = newInitialScrollTarget.findIndex((target) => target[0] === rootNodeId)
  if (rootNodeIndex >= 0) {
    newInitialScrollTarget[rootNodeIndex] = [rootNodeId, scrollTarget]
  } else {
    newInitialScrollTarget.push([rootNodeId, scrollTarget])
  }
  return newInitialScrollTarget
}

function SetEditorHelp(currentHelpKey: string, readonly: boolean, nodesInEditMode: boolean, singleNodeMode: boolean) {
  const inplaceHelpKey = "inplaceEditor"
  const singleNodeHelpKey = "singleNodeEditor"
  if ([inplaceHelpKey, singleNodeHelpKey].includes(currentHelpKey)) {
    // current help key refers to any editor help
    if (readonly || !nodesInEditMode) setToPreviousHelpContext()
    else if (currentHelpKey === inplaceHelpKey && singleNodeMode) replaceHelpContext(singleNodeHelpKey)
    else if (currentHelpKey === singleNodeHelpKey && !singleNodeMode) replaceHelpContext(inplaceHelpKey)
  } else {
    // current help key doesn't refer to any editor help
    if (!readonly && nodesInEditMode) setHelpContext(singleNodeMode ? singleNodeHelpKey : inplaceHelpKey)
  }
}
