import * as React from "react"
import { SVGProps } from "react"

const SvgCircleArrowDown = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1em" height="1em" {...props}>
    <path d="M256 48a208 208 0 1 0 0 416 208 208 0 1 0 0-416zm0 464a256 256 0 1 1 0-512 256 256 0 1 1 0 512zm105-223-88 88c-9.4 9.4-24.6 9.4-33.9 0l-88-88c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47V152c0-13.3 10.7-24 24-24s24 10.7 24 24v150.1l47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
  </svg>
)

export default SvgCircleArrowDown
