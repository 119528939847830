import React from "react"
import type { PropertyEditor, MultiSelectionPropertyValue } from "../types"
import { useUpdatePropertyMutation, UpdatePropertyMutationVariables } from "../../query.hooks"
import { isTypename } from "@st4/graphql"
import { MultiSelectionEditor } from "~/ui/editors/multiSelectionEditor"
import { Icon, Regular } from "@st4/icons"

function getInputValue(
  propertyEditor: PropertyEditor<MultiSelectionPropertyValue>,
  value: string[],
): UpdatePropertyMutationVariables["input"] {
  const input = {
    variantName: propertyEditor.variantName,
    displayName: propertyEditor.displayName,
    id: propertyEditor.id,
    node: { id: propertyEditor.node.id },
    origin: propertyEditor.origin,
    value: {
      multiSelectionValue: value,
    },
  }

  return input
}

export function PropertyMultiSelectionEditor({
  propertyEditor,
  languageId,
  refetchProperties,
  refetchOnSave,
}: {
  propertyEditor: PropertyEditor<MultiSelectionPropertyValue>
  languageId: string
  refetchProperties: () => void
  refetchOnSave?: string[]
}) {
  const { value, isReadonly } = propertyEditor

  const [updateProperty, { data, loading, error }] = useUpdatePropertyMutation({
    variables: {
      input: { ...propertyEditor, value: { multiSelectionValue: propertyEditor.value?.selectedValues } },
    },
    refetchQueries: refetchOnSave,
  })

  const submitHandler = async (selection: string[]) => {
    updateProperty({
      variables: {
        input: getInputValue(propertyEditor, selection),
        languageId: languageId,
      },
      onCompleted: (_) => {
        refetchProperties()
      },
    })
  }
  let errorMessage: string | undefined

  const suffixIcon = loading ? <Icon component={Regular.SpinnerThird} /> : null
  if (error) {
    errorMessage = error.message
  }
  if (isTypename("Error")(data?.updateProperty)) {
    errorMessage = data?.updateProperty.message
  }

  return (
    <MultiSelectionEditor
      selectedValues={value?.selectedValues ?? []}
      availableValues={value?.selectableValues ?? []}
      isReadonly={isReadonly}
      isRemovable={value?.deletable}
      suffixIcon={suffixIcon}
      onSubmit={submitHandler}
      errorMessage={errorMessage}
    />
  )
}
