import * as Types from "@st4/graphql"

import { gql } from "@apollo/client"
import * as Apollo from "@apollo/client"
const defaultOptions = {} as const
export type TaxonomyTreeQueryVariables = Types.Exact<{
  taxonomyTreeRequest: Types.TaxonomyTreeRequest
}>

export type TaxonomyTreeQuery = {
  __typename: "Query"
  taxonomyTree:
    | {
        __typename: "TaxonomyTreeInfoError"
        message: string
        lockInfo?: {
          __typename: "LockInfo"
          guid: string
          lockedBy: string
          neededRight: string
          permissionDenied: boolean
        } | null
      }
    | {
        __typename: "TaxonomyTreeInfoSuccess"
        result: {
          __typename: "ST4Tree"
          nodes: Array<{
            __typename: "ST4TreeNode"
            id: string
            children: Array<string>
            node?: {
              __typename: "ST4Node"
              id: string
              label?: string | null
              nodeClass: { __typename: "ST4Class"; classHierarchy: Array<string> }
            } | null
          }>
        }
      }
}

export type TaxonomyNodeFragment = {
  __typename: "ST4Node"
  id: string
  label?: string | null
  nodeClass: { __typename: "ST4Class"; classHierarchy: Array<string> }
}

export type UpdateTaxonomyMutationVariables = Types.Exact<{
  input: Types.UpdateTaxonomyInput
  languageId?: Types.InputMaybe<Types.Scalars["AspectSelectorId"]>
}>

export type UpdateTaxonomyMutation = {
  __typename: "Mutation"
  updateTaxonomy?:
    | {
        __typename: "TaxonomyUpdateError"
        message: string
        wasModifiedByOtherProcess: boolean
        lockInfo?: {
          __typename: "LockInfo"
          guid: string
          lockedBy: string
          neededRight: string
          permissionDenied: boolean
        } | null
      }
    | {
        __typename: "TaxonomyUpdateSuccess"
        result: Array<{
          __typename: "ST4TreeNode"
          node?: { __typename: "ST4Node"; id: string; label?: string | null } | null
        }>
      }
    | null
}

export type OntologyInfoQueryVariables = Types.Exact<{
  input: Types.GetOntologyInfoInput
}>

export type OntologyInfoQuery = {
  __typename: "Query"
  ontologyInfo:
    | { __typename: "Error" }
    | { __typename: "OntologyInfoSuccess"; result: { __typename: "OntologyInfo"; multiSelect: boolean } }
}

export const TaxonomyNodeFragmentDoc = gql`
  fragment TaxonomyNode on ST4Node {
    id
    label
    nodeClass {
      classHierarchy
    }
  }
`
export const TaxonomyTreeDocument = gql`
  query TaxonomyTree($taxonomyTreeRequest: TaxonomyTreeRequest!) {
    taxonomyTree(input: $taxonomyTreeRequest) {
      ... on TaxonomyTreeInfoSuccess {
        result {
          nodes {
            id
            children
            node {
              ...TaxonomyNode
            }
          }
        }
      }
      ... on TaxonomyTreeInfoError {
        message
        lockInfo {
          guid
          lockedBy
          neededRight
          permissionDenied
        }
      }
    }
  }
  ${TaxonomyNodeFragmentDoc}
`

/**
 * __useTaxonomyTreeQuery__
 *
 * To run a query within a React component, call `useTaxonomyTreeQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaxonomyTreeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaxonomyTreeQuery({
 *   variables: {
 *      taxonomyTreeRequest: // value for 'taxonomyTreeRequest'
 *   },
 * });
 */
export function useTaxonomyTreeQuery(
  baseOptions: Apollo.QueryHookOptions<TaxonomyTreeQuery, TaxonomyTreeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<TaxonomyTreeQuery, TaxonomyTreeQueryVariables>(TaxonomyTreeDocument, options)
}
export function useTaxonomyTreeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TaxonomyTreeQuery, TaxonomyTreeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<TaxonomyTreeQuery, TaxonomyTreeQueryVariables>(TaxonomyTreeDocument, options)
}
export type TaxonomyTreeQueryHookResult = ReturnType<typeof useTaxonomyTreeQuery>
export type TaxonomyTreeLazyQueryHookResult = ReturnType<typeof useTaxonomyTreeLazyQuery>
export type TaxonomyTreeQueryResult = Apollo.QueryResult<TaxonomyTreeQuery, TaxonomyTreeQueryVariables>
export const UpdateTaxonomyDocument = gql`
  mutation UpdateTaxonomy($input: UpdateTaxonomyInput!, $languageId: AspectSelectorId) {
    updateTaxonomy(input: $input, languageId: $languageId) {
      ... on TaxonomyUpdateError {
        message
        lockInfo {
          guid
          lockedBy
          neededRight
          permissionDenied
        }
        wasModifiedByOtherProcess
      }
      ... on TaxonomyUpdateSuccess {
        result {
          node {
            ... on ST4Node {
              id
              label
            }
          }
        }
      }
    }
  }
`
export type UpdateTaxonomyMutationFn = Apollo.MutationFunction<UpdateTaxonomyMutation, UpdateTaxonomyMutationVariables>

/**
 * __useUpdateTaxonomyMutation__
 *
 * To run a mutation, you first call `useUpdateTaxonomyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaxonomyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaxonomyMutation, { data, loading, error }] = useUpdateTaxonomyMutation({
 *   variables: {
 *      input: // value for 'input'
 *      languageId: // value for 'languageId'
 *   },
 * });
 */
export function useUpdateTaxonomyMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateTaxonomyMutation, UpdateTaxonomyMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateTaxonomyMutation, UpdateTaxonomyMutationVariables>(UpdateTaxonomyDocument, options)
}
export type UpdateTaxonomyMutationHookResult = ReturnType<typeof useUpdateTaxonomyMutation>
export type UpdateTaxonomyMutationResult = Apollo.MutationResult<UpdateTaxonomyMutation>
export type UpdateTaxonomyMutationOptions = Apollo.BaseMutationOptions<
  UpdateTaxonomyMutation,
  UpdateTaxonomyMutationVariables
>
export const OntologyInfoDocument = gql`
  query OntologyInfo($input: GetOntologyInfoInput!) {
    ontologyInfo(input: $input) {
      __typename
      ... on OntologyInfoSuccess {
        __typename
        result {
          multiSelect
        }
      }
    }
  }
`

/**
 * __useOntologyInfoQuery__
 *
 * To run a query within a React component, call `useOntologyInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useOntologyInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOntologyInfoQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOntologyInfoQuery(
  baseOptions: Apollo.QueryHookOptions<OntologyInfoQuery, OntologyInfoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<OntologyInfoQuery, OntologyInfoQueryVariables>(OntologyInfoDocument, options)
}
export function useOntologyInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OntologyInfoQuery, OntologyInfoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<OntologyInfoQuery, OntologyInfoQueryVariables>(OntologyInfoDocument, options)
}
export type OntologyInfoQueryHookResult = ReturnType<typeof useOntologyInfoQuery>
export type OntologyInfoLazyQueryHookResult = ReturnType<typeof useOntologyInfoLazyQuery>
export type OntologyInfoQueryResult = Apollo.QueryResult<OntologyInfoQuery, OntologyInfoQueryVariables>
