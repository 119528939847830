import * as React from "react"
import { SVGProps } from "react"

const SvgRight = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="1em" height="1em" {...props}>
    <path d="M397.6 256 247.7 398.7c-.8.8-2 1.3-3.2 1.3-2.5 0-4.6-2-4.6-4.6V312c0-13.3-10.7-24-24-24H48v-64h168c13.3 0 24-10.7 24-24v-83.4c0-2.5 2-4.6 4.6-4.6 1.2 0 2.3.5 3.2 1.3L397.6 256zm-153 192c13.5 0 26.5-5.2 36.3-14.5l153.9-146.6c8.5-8.1 13.2-19.2 13.2-30.9s-4.8-22.8-13.2-30.9l-154-146.6c-9.7-9.3-22.7-14.5-36.2-14.5-29 0-52.6 23.5-52.6 52.6V176H48c-26.5 0-48 21.5-48 48v64c0 26.5 21.5 48 48 48h144v59.4c0 29 23.5 52.6 52.6 52.6z" />
  </svg>
)

export default SvgRight
