import * as React from "react"
import { SVGProps } from "react"

const SvgCloudArrowUp = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" width="1em" height="1em" {...props}>
    <path d="M354.9 121.7c13.8 16 36.5 21.1 55.9 12.5 8.9-3.9 18.7-6.2 29.2-6.2 39.8 0 72 32.2 72 72 0 4-.3 7.9-.9 11.7-3.5 21.6 8.1 42.9 28.1 51.7C570.4 276.9 592 308 592 344c0 46.8-36.6 85.2-82.8 87.8-.6 0-1.3.1-1.9.2H144c-53 0-96-43-96-96 0-41.7 26.6-77.3 64-90.5 19.2-6.8 32-24.9 32-45.3v-.2c0-66.3 53.7-120 120-120 36.3 0 68.8 16.1 90.9 41.7zM512 480v-.2c71.4-4.1 128-63.3 128-135.8 0-55.7-33.5-103.7-81.5-124.7 1-6.3 1.5-12.8 1.5-19.3 0-66.3-53.7-120-120-120-17.4 0-33.8 3.7-48.7 10.3C360.4 54.6 314.9 32 264 32c-92.8 0-168 75.2-168 168v.2C40.1 220 0 273.3 0 336c0 79.5 64.5 144 144 144h368zM223 255c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39V384c0 13.3 10.7 24 24 24s24-10.7 24-24V249.9l39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z" />
  </svg>
)

export default SvgCloudArrowUp
