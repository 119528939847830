import { Draft, Patch, applyPatches, enablePatches, produce, produceWithPatches } from "immer"
import { BladeReference, ScreenState } from "../definition/screen"
import { JsonObject } from "../json"

enablePatches()

export function initialize<
  TBlades extends BladeReference<string, any>[],
  TAdditionalScreenState extends JsonObject | undefined,
  TScreenState extends ScreenState<TBlades, TAdditionalScreenState>,
>(state: TScreenState): ReducerHelper<TBlades, TAdditionalScreenState, TScreenState> {
  let stateSave = { ...state }
  let inverseLastPatch: Patch[] | undefined

  function updatedBuilder([newState, _, inversePatch]: ReturnType<
    typeof produceWithPatches<TScreenState, (draft: TScreenState) => TScreenState>
  >) {
    stateSave = newState
    inverseLastPatch = inversePatch
    return helper
  }

  const helper: ReducerHelper<TBlades, TAdditionalScreenState, TScreenState> = {
    collapseBlade(bladeToCollapse) {
      const result = produceWithPatches(stateSave, (draft) => {
        const blade = draft.blades.find((b) => b.name === bladeToCollapse)
        if (blade) {
          blade.collapsed = true
        }
      })

      return updatedBuilder(result)
    },
    create() {
      return stateSave
    },
    expandBlade(bladeToExpand) {
      const result = produceWithPatches(stateSave, (draft) => {
        const blade = draft.blades.find((b) => b.name === bladeToExpand)
        if (blade) {
          blade.collapsed = false
        }
      })

      return updatedBuilder(result)
    },
    hideBlade(blade) {
      const result = produceWithPatches(stateSave, (draft) => {
        draft.blades = draft.blades.filter((b) => b.name !== blade)
      })

      return updatedBuilder(result)
    },
    hideBladesExcept(...blades) {
      const bladeSet = new Set(blades)
      const result = produceWithPatches(stateSave, (draft) => {
        draft.blades = draft.blades.filter((b) => bladeSet.has(b.name))
      })
      return updatedBuilder(result)
    },
    modifyState(recipe) {
      const stateResult = produce(stateSave.states, recipe)
      const result = produceWithPatches(stateSave, (draft) => {
        draft.states = stateResult as Draft<typeof stateResult>
      })
      return updatedBuilder(result)
    },
    moveBlade(bladeToMove, pos, referenceBlade?: TBlades[number]["name"]) {
      const result = produceWithPatches(stateSave, (draft) => {
        const bladeIdx = draft.blades.findIndex((b) => b.name === bladeToMove)
        const [blade] = draft.blades.splice(bladeIdx, 1)
        if (typeof pos === "number") {
          draft.blades.splice(pos, 0, blade)
        } else if (pos === "Start") {
          draft.blades.unshift(blade)
        } else if (pos === "End") {
          draft.blades.push(blade)
        } else {
          const referenceIndex = draft.blades.findIndex((b) => b.name === referenceBlade)
          if (pos === "Before") {
            draft.blades.splice(referenceIndex, 0, blade)
          } else {
            draft.blades.splice(referenceIndex + 1, 0, blade)
          }
        }
      })
      return updatedBuilder(result)
    },
    onlyIf(condition) {
      if (!condition && inverseLastPatch) {
        stateSave = applyPatches(stateSave, inverseLastPatch)
      }
      return helper
    },
    showBlade(bladeToInsert, initialstate) {
      const result = produceWithPatches(stateSave, (draft) => {
        const newBlade: (typeof draft.blades)[number] = {
          name: bladeToInsert as (typeof draft.blades)[number]["name"],
          ...initialstate,
        }
        if (draft.blades.every((b) => b.name !== bladeToInsert)) {
          draft.blades.push(newBlade)
        }
      })
      return updatedBuilder(result)
    },
  }
  return helper
}

export function isReducerHelper(result: object): result is ReducerHelper {
  return Object.hasOwn(result, "create")
}

export type ReducerHelper<
  TBlades extends BladeReference<string, any>[] = BladeReference<string, any>[],
  TAdditionalScreenState extends JsonObject | undefined = JsonObject | undefined,
  TScreenState extends ScreenState<TBlades, TAdditionalScreenState> = ScreenState<TBlades, TAdditionalScreenState>,
> = {
  /**
   * Create the state value based on chained instructions.
   * @returns The updated screenstate
   */
  create: () => TScreenState

  /**
   * Set the given blade to be collapsed. (Does nothing if the blade isn't rendered)
   * @param blade The name of the Blade to collapse.
   * @returns The helper instance for further chaining or creating.
   */
  collapseBlade: (blade: TBlades[number]["name"]) => ReducerHelper<TBlades, TAdditionalScreenState, TScreenState>

  /**
   * Set the given blade to be expanded. (Does nothing if the blade isn't rendered)
   * @param blade The name of the blade to expand.
   * @returns The helper instance for further chaining or creating.
   */
  expandBlade: (blade: TBlades[number]["name"]) => ReducerHelper<TBlades, TAdditionalScreenState, TScreenState>

  /**
   * Show the blade as last blade of the screen (if not already visible)
   * @param blade The name of the blade to insert.
   * @param init Optional initial values, only effective if blade wasn't already visible
   * @returns The helper instance for further chaining or creating.
   */
  showBlade: (
    blade: TBlades[number]["name"],
    init?: Partial<{ collapsed: boolean }>,
  ) => ReducerHelper<TBlades, TAdditionalScreenState, TScreenState>

  /**
   * Hide the given blade from the screen.
   * @param blade The blade to remove from the screen.
   * @returns The helper instance for further chaining or creating.
   */
  hideBlade: (blade: TBlades[number]["name"]) => ReducerHelper<TBlades, TAdditionalScreenState, TScreenState>

  /**
   * Hides all blades except the passed from the screen.
   * **Consider using different Screens instead.**
   * @param blade The blades to keep on the screen.
   * @returns The helper instance for further chaining or creating.
   */
  hideBladesExcept: (
    ...blades: TBlades[number]["name"][]
  ) => ReducerHelper<TBlades, TAdditionalScreenState, TScreenState>

  /**
   * Bind the previous step to only be executed if the condition is true.
   * @param condition If passed false, the previous step won't be applied to the state
   * @returns The helper instance for further chaining or creating.
   */
  onlyIf: (condition: boolean) => ReducerHelper<TBlades, TAdditionalScreenState, TScreenState>

  /**
   * Change the values of the screens state.
   * @see {@link https://immerjs.github.io/|Immer}
   * @param recipe A function which receives the `states` of the current screen as input. This input can be **mutated**. Those mutations will be reflected in the new state.
   * @returns The helper instance for further chaining or creating.
   */
  modifyState: (
    recipe: (
      draft: Draft<ScreenState<TBlades, TAdditionalScreenState>["states"]>,
    ) => Draft<ScreenState<TBlades, TAdditionalScreenState>["states"]> | void | undefined,
  ) => ReducerHelper<TBlades, TAdditionalScreenState, TScreenState>

  moveBlade: {
    /**
     * Move the given blade to the start or end of the blade list
     * @returns The helper instance for further chaining or creating.
     */
    (
      blade: TBlades[number]["name"],
      position: "Start" | "End",
    ): ReducerHelper<TBlades, TAdditionalScreenState, TScreenState>
    /**
     * Move the given blade before or after the reference blade
     * @returns The helper instance for further chaining or creating.
     */
    (
      blade: TBlades[number]["name"],
      position: "Before" | "After",
      referenceBlade: TBlades[number]["name"],
    ): ReducerHelper<TBlades, TAdditionalScreenState, TScreenState>
    /**
     * Move the given Blade to the given index
     * @returns The helper instance for further chaining or creating.
     */
    (blade: TBlades[number]["name"], index: number): ReducerHelper<TBlades, TAdditionalScreenState, TScreenState>
  }
}
