import { Message } from "@st4/message-hub"
import { useCallback, useEffect } from "react"
import { PreviewParameterContext, ReadonlyPreviewParameterContext } from "../../../PreviewParameterContext"
import { EditorState } from "../types"
import { useApolloClient, makeVar, useReactiveVar } from "@apollo/client"

type EditorStateCommunicationProps = {
  nodeId: string
  treeNodeId: string
  previewParameters: Exclude<PreviewParameterContext, ReadonlyPreviewParameterContext>
}

export const EDITORSTATES = makeVar(new Map<string, EditorState>())

export default function useEditorState({ nodeId, treeNodeId, previewParameters }: EditorStateCommunicationProps) {
  const apolloClient = useApolloClient()
  const { messageHub } = previewParameters

  const editorState = useReactiveVar(EDITORSTATES).get(nodeId) ?? "Opening"
  const setEditorState = useCallback(
    (newEditorState) => {
      const currentEditorStates = EDITORSTATES()
      EDITORSTATES(new Map(currentEditorStates.set(nodeId, newEditorState)))
    },
    [nodeId],
  )

  useEffect(() => {
    const currentEditorStates = EDITORSTATES()
    EDITORSTATES(new Map(currentEditorStates.set(nodeId, "Opening")))
  }, [nodeId])

  const handleEditorOpened = useCallback(() => {
    if (editorState === "Error") return

    setEditorState("Opening")
    document.getElementById(`fonto-${nodeId}`)?.focus()
  }, [editorState, nodeId, setEditorState])

  const handleEditorFinishedLoading = useCallback(() => {
    if (editorState === "Error") return

    setEditorState("Running")
  }, [editorState, setEditorState])

  const handleEditorError = useCallback(
    (isError: boolean) => {
      if (isError) setEditorState("Error")
      else setEditorState("Opening")
    },
    [setEditorState],
  )

  const handleEditorClosed = useCallback(
    (success: boolean) => {
      if (!success) return

      setEditorState("Closing")
      messageHub.sendMessage({
        action: "editingFinished",
        payload: {
          inplace: !previewParameters.singleNodeMode,
          selection: {
            nodeId: nodeId,
            treeNodeId: treeNodeId,
          },
        },
      })
    },
    [messageHub, nodeId, previewParameters.singleNodeMode, setEditorState, treeNodeId],
  )

  const handleEditorSaved = useCallback(() => {
    apolloClient.refetchQueries({
      updateCache: (cache) => cache.evict({ id: `ST4Node:${nodeId}`, fieldName: "content" }),
      optimistic: true,
    })
  }, [apolloClient, nodeId])

  useEffect(() => {
    function editorMessageCallback(msg: Message) {
      if (!msg.payload.nodeId || msg.payload.nodeId !== nodeId) return
      switch (msg.action) {
        case "editorOpened":
          handleEditorOpened()
          break
        case "editorFinishedLoading":
          handleEditorFinishedLoading()
          break
        case "editorSaved":
          handleEditorSaved()
          break
        case "editorClosed":
          handleEditorClosed(msg.payload.success)
          break
        case "editorError":
          handleEditorError(msg.payload.isError)
          break
        default:
          return
      }
    }
    messageHub.observe(editorMessageCallback)
    return () => {
      messageHub.unobserve(editorMessageCallback)
    }
  }, [
    handleEditorClosed,
    handleEditorError,
    handleEditorFinishedLoading,
    handleEditorOpened,
    handleEditorSaved,
    messageHub,
    nodeId,
  ])

  return editorState
}
