import { gql } from "@apollo/client"

export default gql`
  enum PersistanceAction {
    REMOVE
    EDIT
    ADD
    CHANGECOMMENT
    CHANGESTATE
  }

  enum PersistanceState {
    LOCAL
    SERVER
    ERROR
  }

  extend type IComment {
    persistanceState: PersistanceState
    persistanceAction: PersistanceAction
  }

  extend type BlockComment {
    persistanceState: PersistanceState
    persistanceAction: PersistanceAction
  }

  extend type TextComment {
    persistanceState: PersistanceState
    persistanceAction: PersistanceAction
  }
`
