import React, { useEffect, PropsWithChildren, useRef } from "react"
import { Modal as AntModal } from "antd"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { keys } from "@st4/ui-strings"
import { createPortal } from "react-dom"

const ModalBody = styled.div<{ positionX: string; positionY: string }>`
  position: fixed;
  left: 0;
  transform: translateX(${(props) => props.positionX}) translateY(${(props) => props.positionY});
  top: 0;
  z-index: 5;
`

const Content = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  flex-direction: row;
`
interface PositionableModalProps {
  className?: string
  onClose: () => void
  closeOnOutsideClick?: boolean
  positionX: string
  positionY: string
  forwardedRef?: React.RefObject<HTMLDivElement>
}
interface PositionableModalPropsWithCloseMessage {
  className?: string
  onClose: () => void
  closeOnOutsideClick?: boolean
  confirmMessage: string
  confirmButtons: { ok: string; cancel: string }
  positionX: string
  positionY: string
  forwardedRef?: React.RefObject<HTMLDivElement>
}

function hasCloseMessage(
  props: PositionableModalPropsWithCloseMessage | PositionableModalProps,
): props is PositionableModalPropsWithCloseMessage {
  return !!(props as PositionableModalPropsWithCloseMessage).confirmMessage
}

export function UnstlyedPositionableModal(
  props: React.PropsWithChildren<PositionableModalProps | PositionableModalPropsWithCloseMessage>,
) {
  const modalRef = useRef<HTMLDivElement>(null)
  const closing = useRef<boolean>(false)
  const { t } = useTranslation()
  function useOutsideEventHandler(ref?: React.RefObject<HTMLDivElement>) {
    function handleClickOutside(event: MouseEvent) {
      if (!closing.current && ref && ref.current && !ref.current.contains(event.target as Node)) {
        if (hasCloseMessage(props)) {
          closing.current = true
          AntModal.confirm({
            title: t(keys.label.warning.warning),
            content: <span style={{ whiteSpace: "pre-wrap" }}>{props.confirmMessage}</span>,
            okText: props.confirmButtons && props.confirmButtons.ok,
            cancelText: props.confirmButtons && props.confirmButtons.cancel,
            onOk() {
              props.onClose()
            },
            onCancel() {
              closing.current = false
            },
          })
        } else props.onClose()
      }
    }

    useEffect(() => {
      if (props.closeOnOutsideClick == undefined || props.closeOnOutsideClick) {
        document.addEventListener("mousedown", handleClickOutside)
        return () => {
          document.removeEventListener("mousedown", handleClickOutside)
        }
      }
    }, [props.onClose, props.closeOnOutsideClick])
  }

  useOutsideEventHandler(modalRef)

  return createPortal(
    <ModalBody
      className={props.className}
      positionX={props.positionX}
      positionY={props.positionY}
      ref={modalRef}
      onClick={(event) => event.stopPropagation()}
    >
      {props.children}
    </ModalBody>,
    document.body,
  )
}

const StyledModalBody = styled.div`
  border-radius: 5px;
  box-shadow: ${({ theme }) => theme.shadows["+3"]};
  background-color: ${({ theme }) => theme.backgroundColor};
  padding: 0.3rem;
`
export default function PositionableModal({ children, ...props }: PropsWithChildren<PositionableModalProps>) {
  return (
    <UnstlyedPositionableModal {...props}>
      <StyledModalBody>
        <Content>{children}</Content>
      </StyledModalBody>
    </UnstlyedPositionableModal>
  )
}
