import * as React from "react"
import { SVGProps } from "react"

const SvgEllipsisVertical = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512" width="1em" height="1em" {...props}>
    <path d="M64 368a48 48 0 1 0 0 96 48 48 0 1 0 0-96zm0-160a48 48 0 1 0 0 96 48 48 0 1 0 0-96zm48-112a48 48 0 1 0-96 0 48 48 0 1 0 96 0z" />
  </svg>
)

export default SvgEllipsisVertical
