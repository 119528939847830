import { transparentize } from "polished"
import QuanosColors from "./quanosColors"

const defaultTheme = {
  gutterWidth: "10px",
  gutterHeight: "6px",
  backgroundColor: QuanosColors.palettes.secondary.lightGreyishBlue,
  primaryColor: QuanosColors.palettes.primary.darkBlue,
  primaryAccentColor: "#fff",
  secondaryColor: QuanosColors.palettes.primary.brightRed,
  secondaryAccentColor: "#fff",
  disabledColor: "#8f8f8f",
  separatorColor: "#e7e7e7",
  warningColor: "#f44336",
  headers: true,
  appMargins: true,
  previewLinks: true,
  logo: "quanos_st4_slimclient.svg",
  background: "background.jpg",
  successColor: QuanosColors.palettes.success.success,
  errorColor: QuanosColors.palettes.error.error,

  /** WebApp */
  veryDarkBlue: QuanosColors.palettes.blue.veryDarkBlue,
  regularBlue: QuanosColors.palettes.blue.regularBlue,
  subtleBlue: QuanosColors.palettes.blue.subtleBlue,
  lightGreyishBlue: QuanosColors.palettes.secondary.lightGreyishBlue,
  softOrange: QuanosColors.palettes.primary.softOrange,
  harpGrey: QuanosColors.palettes.grey.harpGrey,
  warningBG: QuanosColors.palettes.warning.warningBG,
  warning: QuanosColors.palettes.warning.warning,
  question: QuanosColors.palettes.question.question,
  questionBG: QuanosColors.palettes.question.questionBG,
  verySubtleBlue: QuanosColors.palettes.blue.verySubtleBlue,
  midnightBlue: QuanosColors.palettes.grey.midnightBlue,
  darkBlue: QuanosColors.palettes.blue.darkBlue,
  lightGrey: QuanosColors.palettes.grey.lightGrey,
  snowGrey: QuanosColors.palettes.grey.snowGrey,
  tuna: QuanosColors.palettes.grey.tuna,
  shuttleGrey: QuanosColors.palettes.grey.shuttleGrey,
  silverSand: QuanosColors.palettes.grey.silverSand,
  silkGrey: QuanosColors.palettes.grey.silkGrey,
  barelyBlue: QuanosColors.palettes.blue.barelyBlue,
  ghostWhite: QuanosColors.palettes.grey.ghostWhite,
  success: QuanosColors.palettes.success.success,
  osloGrey: QuanosColors.palettes.grey.osloGrey,

  white: "hsl(0,0%,100%)",
  notificationColors: {
    information: {
      darker: "hsl(188, 31%, 58%)",
      normal: "hsl(185, 24%, 71%)",
      lighter: "",
    },
    warning: {
      darker: "hsl(18, 100%, 40%)",
      normal: "hsl(16, 100%, 50%)",
      lighter: "",
    },
    error: {
      darker: "hsl(354, 100%, 25%)",
      normal: "hsl(0, 100%, 40%)",
      lighter: "",
    },
    question: {
      darker: "hsl(255, 100%, 25%)",
      normal: "hsl(210, 100%, 40%)",
      lighter: "",
    },
  },
  safetyInstructions: {
    colors: {
      danger: "#ac010a",
      warning: "#ea8801",
      caution: "#ecd901",
      notice: "#023a75",
      danger_body: "hsl(0, 90%, 85%)",
      warning_body: "hsl(43, 90%, 85%)",
      caution_body: "hsl(58, 90%, 85%)",
      notice_body: "hsl(208, 64%, 85%)",
      danger_headline: "hsl(0, 0%, 95%)",
      warning_headline: "hsl(0, 0%, 23%)",
      caution_headline: "hsl(0, 0%, 23%)",
      notice_headline: "hsl(0, 0%, 95%)",
      danger_border: "hsl(357, 51%, 80%)",
      warning_border: "hsl(35, 83%, 84%)",
      caution_border: "hsl(55, 86%, 84%)",
      notice_border: "hsl(212, 29%, 77%)",
    },
  },
  greys: {
    "900": "hsl(198, 100%, 5%)",
    "800": "hsl(198, 30%, 14%)",
    "700": "hsl(198, 10%, 25%)",
    "600": "hsl(198, 5%, 36%)",
    "500": "hsl(198, 4%, 50%)",
    "400": "hsl(198, 10%, 65%)",
    "300": "hsl(198, 10%, 75%)",
    "200": "hsl(198, 10%, 90%)",
    "100": "hsl(198, 26%, 98.5%)",
  },
  shadows: {
    "+1": "0 1px 3px hsla(0, 0%, 0%, 0.2), 0 0px 1px hsla(0,0%,0%,0.3)",
    "+2": "0 4px 6px hsla(0, 0%, 0%, 0.2), 0 1px 3px hsla(0,0%,0%,0.1)",
    "+3": "0 5px 15px hsla(0, 0%, 0%, 0.2), 0 2px 4px hsla(0,0%,0%,0.3)",
    "+4": "0 10px 24px hsla(0, 0%, 0%, 0.2), 0 4px 10px hsla(0,0%,0%,0.3)",
    "+5": "0 15px 35px hsla(0, 0%, 0%, 0.2), 0 5px 10px hsla(0,0%,0%,0.3)",
  },
  preview: {
    annotations: {
      primaryColor: {
        "900": "hsl(31, 70%, 8%)",
        "800": "hsl(32, 55%, 18%)",
        "700": "hsl(33, 100%, 25%)",
        "600": "hsl(35, 100%, 35%)",
        "500": "hsl(37, 100%, 50%)",
        "400": "hsl(37, 100%, 65%)",
        "300": "hsl(40, 100%, 75%)",
        "200": "hsl(41, 95%, 80%)",
        "100": "hsl(41, 91%, 90%)",
      },
      focusColor: {
        get background() {
          return defaultTheme.preview.selection.primaryColor[200]
        },
        get border() {
          return defaultTheme.preview.selection.primaryColor[500]
        },
      },
      preColor: {
        get background() {
          return defaultTheme.preview.selection.primaryColor[100]
        },
        get border() {
          return defaultTheme.preview.selection.primaryColor[300]
        },
      },
      errorColor: {
        "900": "hsl(10, 70%, 8%)",
        "800": "hsl(11, 55%, 18%)",
        "700": "hsl(11, 100%, 25%)",
        "600": "hsl(12, 100%, 35%)",
        "500": "hsl(13, 100%, 50%)",
        "400": "hsl(13, 100%, 65%)",
        "300": "hsl(14, 100%, 75%)",
        "200": "hsl(15, 95%, 80%)",
        "100": "hsl(15, 91%, 90%)",
      },
      animationDuration: "300ms",
      status: {
        accepted: "hsl(130, 58%, 46%)",
        rejected: "hsl(0, 83%, 43%)",
      },
    },
    selection: {
      primaryColor: {
        "900": "hsl(190, 70%, 8%)",
        "800": "hsl(190, 55%, 18%)",
        "700": "hsl(191, 65%, 25%)",
        "600": "hsl(192, 75%, 35%)",
        "500": "hsl(193, 80%, 50%)",
        "400": "hsl(194, 100%, 65%)",
        "300": "hsl(196, 100%, 75%)",
        "200": "hsl(198, 95%, 80%)",
        "100": "hsl(200, 90%, 90%)",
      },
      noCommentColor: {
        "900": "hsl(190, 12%, 8%)",
        "800": "hsl(190, 12%, 18%)",
        "700": "hsl(191, 14%, 25%)",
        "600": "hsl(192, 16%, 35%)",
        "500": "hsl(193, 16%, 50%)",
        "400": "hsl(194, 22%, 65%)",
        "300": "hsl(196, 22%, 75%)",
        "200": "hsl(198, 20%, 80%)",
        "100": "hsl(200, 18%, 90%)",
      },
    },
    variableColor: QuanosColors.palettes.green.deepMint,
    nodeSelection: {
      selectionColor: QuanosColors.palettes.blue.softBlue,
    },
    lexiconColor: QuanosColors.palettes.violet.passionFlower,
    table: {
      borders: {
        get framed() {
          return `1px solid ${defaultTheme.greys[300]}`
        },
        get framed_head() {
          return `2px solid ${defaultTheme.greys[500]}`
        },
        noframe: "0px",
      },
      sizeDisplayBackground: transparentize(0.1, QuanosColors.palettes.orange.softOrange),
    },
    get groupNodeHighlight() {
      return `box-shadow: 2px 2px 0px 0px white, 3px 3px 0px 0px ${defaultTheme.greys[300]}, 5px 5px 0px 0px white,
      6px 6px 0px 0px ${defaultTheme.greys[300]};`
    },
    get groupNodeHighlightSelected() {
      return `box-shadow: 2px 2px 0px 0px white, 3px 3px 0px 0px ${defaultTheme.preview.nodeSelection.selectionColor}, 5px 5px 0px 0px white,
      6px 6px 0px 0px ${defaultTheme.preview.nodeSelection.selectionColor};`
    },
  },
  /** ENDE WEBAPP */
}

// declare module "styled-components" {
//   type QuanosTheme = typeof defaultTheme
//   export interface DefaultTheme extends QuanosTheme {}
// }

export type ThemeManagerTheme = typeof defaultTheme

export default defaultTheme
