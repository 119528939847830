import * as React from "react"
import { SVGProps } from "react"

const SvgFilter = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1em" height="1em" {...props}>
    <path d="M0 71.5C0 49.7 17.7 32 39.5 32h433c21.8 0 39.5 17.7 39.5 39.5 0 9.2-3.2 18.1-9.1 25.2L320 317.8v128.3c0 18.7-15.2 33.9-33.9 33.9-7.5 0-14.8-2.5-20.8-7.1l-61-47.4c-7.8-6.1-12.4-15.4-12.4-25.3v-82.4L9.1 96.7C3.2 89.6 0 80.7 0 71.5zM39.5 64c-4.2 0-7.5 3.4-7.5 7.5 0 1.8.6 3.4 1.7 4.8l186.6 225.5c2.4 2.9 3.7 6.5 3.7 10.2v88.2l61 47.4c.3.3.7.4 1.1.4 1 0 1.9-.8 1.9-1.9V312c0-3.7 1.3-7.3 3.7-10.2L478.3 76.3c1.1-1.3 1.7-3 1.7-4.8 0-4.2-3.4-7.5-7.5-7.5h-433z" />
  </svg>
)

export default SvgFilter
