import { useNode } from "../../../contentModel/nodeContext"
import { useNodeFilter } from "../../FilteringTreeNodeWrapper"
import { isTypename, notEmpty } from "@st4/graphql"
import { isST4NodeWithContent, ST4NodeWithContentName } from "../../../graphql/types"

type useMediaNodeWithAlternativesResult = {
  filteredResult: ST4NodeWithContentName<"MediaContent"> | null
  alternatives: ST4NodeWithContentName<"MediaContent">[]
}

/**
 * Returns the node of media based on its `linkId`
 * If the media is a group, node filtering is applied.
 * Additionally all alternative media of the group that were ignored due to filtering are returned
 * If media node (group or not) referenced by `linkId` is filtered out no alternatives will be provided
 */
export function useMediaNodeWithAlternatives(linkId: string): useMediaNodeWithAlternativesResult {
  // Base Assumption: Media Groups cannot be nested

  const noMediaFoundResult = { filteredResult: null, alternatives: [] }

  const node = useNode()
  const nodeFilter = useNodeFilter()

  if (!isST4NodeWithContent("TextContent", "TextGroupContent")(node)) return noMediaFoundResult

  const imageRef = node.content.images?.find((n) => n.id == linkId)
  if (!imageRef) return noMediaFoundResult
  const imageNode = imageRef.target
  if (!isTypename("ST4Node")(imageNode)) return noMediaFoundResult
  if (!nodeFilter(imageNode).visible) return noMediaFoundResult

  if (isST4NodeWithContent("MediaContent")(imageNode)) {
    return { filteredResult: imageNode, alternatives: [] }
  } else if (isST4NodeWithContent("MediaGroupContent")(imageNode)) {
    const childrenWithMediaContent = imageNode.content.children
      .map((ref) => ref.target)
      .filter(notEmpty)
      .filter(isTypename("ST4Node"))
      .filter(isST4NodeWithContent("MediaContent"))

    const filteredChild = childrenWithMediaContent.find((node) => nodeFilter(node).visible) ?? null
    const alternatives = childrenWithMediaContent.filter((node) => node !== filteredChild)

    return { filteredResult: filteredChild, alternatives }
  } else {
    return noMediaFoundResult
  }
}
