import React from "react"
import { useNodeNumbering } from "../../contentModel"
import { NodeRenderProps } from "./types"
import { LoadingNodePreview } from "../LoadingNodePreview"
import { ContentWidthLimiting } from "./utils"

export function LoadingNode({ treeNode }: NodeRenderProps) {
  const nodeNumbering = useNodeNumbering(treeNode.id)
  return (
    <ContentWidthLimiting>
      <LoadingNodePreview title={`${nodeNumbering.join(".")} ${treeNode.label}`} active={false} treeNode={treeNode} />
    </ContentWidthLimiting>
  )
}
