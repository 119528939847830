import * as React from "react"
import { SVGProps } from "react"

const SvgSt4KnotSameLevelLight = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1em" height="1em" {...props}>
    <path d="M232 224h160c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H232c-35.3 0-64 28.7-64 64v16h-32c-26.5 0-48-21.5-48-48V39c0-8.8-7.2-16-16-16s-16 7.2-16 16v281c0 44.2 35.8 80 80 80h32v16c0 35.3 28.7 64 64 64h160c35.3 0 64-28.7 64-64v-96c0-35.3-28.7-64-64-64H232c-35.3 0-64 28.7-64 64v48h-32c-26.5 0-48-21.5-48-48V128c13.4 10 30 16 48 16h32v16c0 35.3 28.7 64 64 64zM200 96c0-17.7 14.3-32 32-32h160c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32H232c-17.7 0-32-14.3-32-32V96zm0 224c0-17.7 14.3-32 32-32h160c17.7 0 32 14.3 32 32v96c0 17.7-14.3 32-32 32H232c-17.7 0-32-14.3-32-32v-96zm69.8 63.8h26.4v26.4c0 8.7 7.1 15.8 15.8 15.8s15.8-7 15.8-15.8v-26.4h26.4c8.7 0 15.8-7.1 15.8-15.8s-7-15.8-15.8-15.8h-26.4v-26.4c0-8.7-7.1-15.8-15.8-15.8s-15.8 7-15.8 15.8v26.4h-26.4c-8.7 0-15.8 7.1-15.8 15.8s7 15.8 15.8 15.8z" />
  </svg>
)

export default SvgSt4KnotSameLevelLight
