import React, { ComponentType, SVGProps } from "react"

type Enumerate<Length extends number, Acc extends number[] = []> = Acc["length"] extends Length
  ? Acc[number]
  : Enumerate<Length, [...Acc, Acc["length"]]>

type IntRange<Start extends number, Length extends number> = Exclude<Enumerate<Length>, Enumerate<Start>>

export type Columns = IntRange<1, 25>

/** Size of a blade in columns for difference screen sizes */
export type BladeSize = {
  /**
   * The size of the blade in columns on small screens.
   */
  S: Columns

  /**
   * The size of the blade in columns on medium screens.
   */
  M: Columns

  /**
   * The size of the blade in columns on large screens.
   */
  L: Columns
}

export type DefaultBladeProps = DataAttributes & {
  /**
   * The title, displayed in the blades header.
   */
  title: React.ReactNode
  /**
   * The content to display inside the blades main area.
   */
  children: React.ReactNode

  /**
   * The size of the Blade
   */
  size: BladeSize

  /**
   * @deprecated moved to DefaultBladeWithTemporaryBladeProps.onTemporaryBladeClose
   * Gets called if the close button is pressed.
   */
  onClose?: () => void
  /**
   * @deprecated moved to PersistentBladeProps
   * Gets called if the collapse button is pressed.
   */
  onCollapse?: () => void
}

export type PersistentBladeProps = (DefaultBladeProps | DefaultBladeWithTemporaryBladeProps) & {
  temporary?: never
  fixed?: never
  collapsed: boolean
  /**
   * Gets called if the collapse button is pressed.
   */
  onCollapse?: () => void
}

export type FixedBladeProps = (DefaultBladeProps | DefaultBladeWithTemporaryBladeProps) & {
  temporary?: never
  /**
   * Indicator if the current blade is in a fixed position.
   */
  fixed: true
  collapsed?: never
}

/**
 * @deprecated Not an indepent type anymore, but part of DefaultBladeWithTemporaryBladeProps
 */
type TemporaryBladeProps = DefaultBladeProps & {
  fixed?: never
  /**
   * Indicator if the current blade is temporary.
   */
  temporary: true
  collapsed?: never
}

export type DefaultBladeWithTemporaryBladeProps = DefaultBladeProps & {
  /**
   * The props for the temporary blade that belongs to this blade.
   */
  temporaryBladeProps: DefaultBladeProps & { icon?: ComponentType<SVGProps<SVGSVGElement>> }
  /**
   * Gets called if the close button of the temporary blade that belongs to this blade is pressed.
   */
  onTemporaryBladeClose?: () => void
}

export type DataAttributes = {
  /**
   * data-* props are passed down to the HTML-Element as data-attributes.
   */
  [data: `data-${string}`]: string
}

export type BladeProps = PersistentBladeProps | TemporaryBladeProps | FixedBladeProps

export type BladeWithTemporaryBladeProps = Extract<
  FixedBladeProps | PersistentBladeProps,
  DefaultBladeWithTemporaryBladeProps
>

export function isTemporaryBlade(props: BladeProps): props is TemporaryBladeProps {
  return !!props.temporary
}

export function isFixedBlade(props: BladeProps): props is FixedBladeProps {
  return !!props.fixed
}

export function isBladeWithTemporaryBlade(props: BladeProps): props is BladeWithTemporaryBladeProps {
  return Object.hasOwn(props, "temporaryBladeProps")
}

type DistributivePick<T, K extends keyof T> = T extends any ? Pick<T, K> : never

/**
 * Allows to select props which will not be supplied by your
 * blade implmementation and therefore need to be passed down
 * by the caller.
 */
export type CustomBladeProps<Keys extends keyof BladeProps> = DistributivePick<BladeProps, Keys>
