import React, { useCallback, useState } from "react"
import { Icon, Regular } from "@st4/icons"

import { useTheme } from "styled-components"
import { Tooltip } from "../../../tooltip"
import { Translate } from "@st4/i18n"
import { TreeItem } from "../../types"

export type CreateButtonProps = {
  style?: React.CSSProperties
  iconStyle?: React.CSSProperties
  node?: TreeItem
  onClick?: (node: TreeItem) => void
  /**
   * Can be a translatable key
   */
  tooltipTitle?: string
}

export function CreateButton(props: CreateButtonProps) {
  const { node, style, iconStyle, onClick, tooltipTitle } = props
  const theme = useTheme()
  const [isPressed, setIsPressed] = useState<boolean>(false)
  const [isHover, setIsHover] = useState<boolean>(false)
  const isDisabled = !node || !onClick

  const handleClick = useCallback(() => {
    if (!node?.id) throw "Unexpected missing node id"
    onClick?.(node)
  }, [onClick, node])

  return (
    <span
      style={{
        background: isPressed ? "rgba(0,0,0,0.05)" : "transparent",
        paddingLeft: "2px",
        paddingRight: "2px",
        ...style,
      }}
    >
      <span>
        <Icon
          component={Regular.Plus}
          aria-label={"button-create"}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onClick={handleClick}
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
          style={{
            fontSize: "14px",
            verticalAlign: "middle",
            marginLeft: "4px",
            marginRight: "4px",
            cursor: "pointer",
            color: isDisabled ? theme.silverSand : isHover ? theme.midnightBlue : theme.shuttleGrey,
            ...iconStyle,
          }}
        />
      </span>
    </span>
  )

  function handleMouseDown() {
    if (isDisabled) return
    setIsPressed(true)
  }

  function handleMouseUp() {
    if (isDisabled) return
    setIsPressed(false)
  }
}
