import * as React from "react"
import { SVGProps } from "react"

const SvgSt4DataNodeLight = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1em" height="1em" {...props}>
    <path d="M63 64.3C63 28.3 91.3 0 127.4 0h156.9c12.9 0 25.7 5.1 33.4 12.9l115.8 115.8c10.3 10.3 15.4 20.6 15.4 33.4v285.6c0 36-28.3 64.3-64.3 64.3H127.4c-36 0-64.3-28.3-64.3-64.3V64.3zM415.5 193H302.3c-25.7 0-48.9-20.6-48.9-48.9V30.9h-126c-18 0-30.9 15.4-30.9 30.9v385.9c0 18 15.4 30.9 30.9 30.9h257.3c18 0 30.9-15.4 30.9-30.9V193zm-2.6-38.6L297.2 38.6c-2.6-2.6-5.1-2.6-7.7-5.1v113.2c0 7.7 7.7 15.4 15.4 15.4h113.2c-2.6-5.2-5.2-7.7-5.2-7.7zm-59.1 100.3v20.6c0 20.6-46.3 36-100.3 36s-100.3-15.4-100.3-36v-20.6c0-20.6 46.3-36 100.3-36s100.3 15.4 100.3 36zm-23.2 61.8c10.3-2.6 18-7.7 25.7-12.9v46.3c0 20.6-46.3 36-100.3 36s-100.3-15.4-100.3-36v-46.3c7.7 5.1 15.4 10.3 25.7 12.9 20.6 7.7 46.3 10.3 77.2 10.3s51.4-2.6 72-10.3zm-177.5 59.1c7.7 5.1 15.4 10.3 25.7 12.9 20.6 7.7 46.3 10.3 77.2 10.3s56.6-5.1 77.2-10.3c10.3-2.6 18-7.7 25.7-12.9v38.6c0 20.6-46.3 36-100.3 36s-100.3-15.4-100.3-36v-38.6h-5.2z" />
  </svg>
)

export default SvgSt4DataNodeLight
