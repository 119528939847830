import * as React from "react"
import { SVGProps } from "react"

const SvgSt4Buttoninsertindexentry = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1em" height="1em" {...props}>
    <path d="M384 0H96C60.7 0 32 28.7 32 64v384c0 35.3 28.7 64 64 64h288c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64zm16 448c0 8.8-7.2 16-16 16H96c-8.8 0-16-7.2-16-16V64c0-8.8 7.2-16 16-16h288c8.8 0 16 7.2 16 16v384zM512 80c0-8.8-7.2-16-16-16s-16 7.2-16 16v64c0 8.8 7.2 16 16 16s16-7.2 16-16V80zm-16 112c-8.8 0-16 7.2-16 16v64c0 8.8 7.2 16 16 16s16-7.2 16-16v-64c0-8.8-7.2-16-16-16zm16 144c0-8.8-7.2-16-16-16s-16 7.2-16 16v64c0 8.8 7.2 16 16 16s16-7.2 16-16v-64zm-351.6-89.7c-8 0-14.5 6.5-14.5 14.5s6.5 14.5 14.5 14.5h65.1v65.1c0 8 6.5 14.5 14.5 14.5s14.5-6.5 14.5-14.5v-65.1h65.1c8 0 14.5-6.5 14.5-14.5s-6.5-14.5-14.5-14.5h-65.1v-65.1c0-8-6.5-14.5-14.5-14.5s-14.5 6.5-14.5 14.5v65.1h-65.1z" />
  </svg>
)

export default SvgSt4Buttoninsertindexentry
