import { MenuProps } from "antd"
import { Translate, keys } from "@st4/i18n"
import React, { useCallback } from "react"
import { useMoveToRecycleBinOperationWrapper } from "./movetorecyclebin"
import { useRemoveReuseLinkOperationWrapper } from "./removereuselink"
import { useCreateVersionOperationWrapper } from "./createversion"
import { useCopyOperationWrapper, useCutOperationWrapper, usePasteOperationWrapper } from "./cutcopypaste"
import { useConfiguredNotifications } from "./useConfiguredNotifications"

type MenuItem = Required<MenuProps>["items"][number]

const removeLinkMode = "removeLink"
const moveToRecycleBinMode = "deleteNode"
const createVersionMode = "createVersion"
const cutMode = "cut"
const copyMode = "copy"
const pasteMode = "paste"

export function useContextMenuOperations(
  onNodeRemoved?: (removedNodeId: string) => void,
  onNodePathChanged?: (oldNodeId: string, newNodeId: string) => void,
  onExpandChanged?: (nodeId: string, isExpanded: boolean) => void,
): {
  createMenuForNode(nodeId: string): Promise<MenuItem[] | undefined>
  handleMenuItemClick: (nodeId: string, menuKey: string) => void
} {
  const { showConfiguredConfirm, showConfiguredNotification, showConfiguredConfirmableNotification } =
    useConfiguredNotifications()

  const onNodeRemovedCallback = useCallback(
    (removedNodeId: string) => onNodeRemoved && onNodeRemoved(removedNodeId),
    [onNodeRemoved],
  )
  const onNodePathChangedCallback = useCallback(
    (oldNodeId: string, newNodeId: string) => onNodePathChanged && onNodePathChanged(oldNodeId, newNodeId),
    [onNodePathChanged],
  )
  const expandNodeCallback = useCallback(
    (nodeId: string) => onExpandChanged && onExpandChanged(nodeId, true),
    [onExpandChanged],
  )

  const moveToRecycleBinOperationWrapper = useMoveToRecycleBinOperationWrapper({
    onOperationSuccess: onNodeRemovedCallback,
    showConfirm: showConfiguredConfirm,
    showConfirmableNotification: showConfiguredConfirmableNotification,
    showNotification: showConfiguredNotification,
  })
  const removeLinkOperationWrapper = useRemoveReuseLinkOperationWrapper({
    onOperationSuccess: onNodeRemovedCallback,
    showConfirm: showConfiguredConfirm,
    showConfirmableNotification: showConfiguredConfirmableNotification,
    showNotification: showConfiguredNotification,
  })
  const createVersionOperationWrapper = useCreateVersionOperationWrapper({
    onOperationSuccess: onNodePathChangedCallback,
    showConfirm: showConfiguredConfirm,
    showConfirmableNotification: showConfiguredConfirmableNotification,
    showNotification: showConfiguredNotification,
  })
  const copyOperationWrapper = useCopyOperationWrapper()
  const cutOperationWrapper = useCutOperationWrapper()
  const pasteOperationWrapper = usePasteOperationWrapper({
    onOperationSuccess: expandNodeCallback,
    showConfirm: showConfiguredConfirm,
    showConfirmableNotification: showConfiguredConfirmableNotification,
    showNotification: showConfiguredNotification,
  })

  return {
    createMenuForNode, // useCallback is not used here, as it is currently irrelevant for re-renderings of DraggableTreeViewNode
    handleMenuItemClick, // useCallback is not used here, as it is currently irrelevant for re-renderings of DraggableTreeViewNode
  }

  async function createMenuForNode(nodeId: string): Promise<MenuItem[] | undefined> {
    const removeReuseLinksAllowed = await removeLinkOperationWrapper.queryOperationAllowed(nodeId)
    const moveToRecycleBinAllowed = await moveToRecycleBinOperationWrapper.queryOperationAllowed(nodeId)
    const createVersionAllowed = await createVersionOperationWrapper.queryOperationAllowed(nodeId)
    const pasteAllowed = await pasteOperationWrapper.queryOperationAllowed(nodeId)

    const items: MenuItem[] = [
      createMenuItem(<Translate>{keys.button.removeReuseLinks}</Translate>, removeLinkMode, !removeReuseLinksAllowed),
      createMenuItem(
        <Translate>{keys.button.deleteNode.start}</Translate>,
        moveToRecycleBinMode,
        !moveToRecycleBinAllowed,
      ),
      createMenuItem(<Translate>{keys.button.createVersion}</Translate>, createVersionMode, !createVersionAllowed),
      createMenuItem(<Translate>{keys.button.cut}</Translate>, cutMode, false),
      createMenuItem(<Translate>{keys.button.copyTrees}</Translate>, copyMode, false),
      createMenuItem(<Translate>{keys.button.paste}</Translate>, pasteMode, !pasteAllowed),
    ]

    return items
  }

  function handleMenuItemClick(nodeId: string, menuKey: string) {
    switch (menuKey) {
      case removeLinkMode:
        removeLinkOperationWrapper.handleMenuItemClick(nodeId)
        return

      case moveToRecycleBinMode:
        moveToRecycleBinOperationWrapper.handleMenuItemClick(nodeId)
        return

      case createVersionMode:
        createVersionOperationWrapper.handleMenuItemClick(nodeId)
        return

      case cutMode:
        cutOperationWrapper.handleMenuItemClick(nodeId)
        return

      case copyMode:
        copyOperationWrapper.handleMenuItemClick(nodeId)
        return

      case pasteMode:
        pasteOperationWrapper.handleMenuItemClick(nodeId)
        return
    }
  }
}

function createMenuItem(
  label: React.ReactNode,
  key?: React.Key | null,
  disabled?: boolean,
  icon?: React.ReactNode,
  children?: MenuItem[],
): MenuItem {
  return {
    key,
    disabled,
    icon,
    children,
    label,
  } as MenuItem
}
