import { useEffect, useCallback } from "react"
import { useStartFinalizeCreateNewNodeJob } from "../../../query.hooks.utils"
import type { CreateNewNodeJobResponse } from "../../../query.hooks.types"
import type { ActionHandlerInfo } from "../../types"

export function useStepAskRequiredMetadata(props: {
  nodeId: string
  getRequiredPropertyEditorComponent: (nodeId: string) => React.ReactElement
  onActionsChanged: (info: ActionHandlerInfo) => void
  onMutationCompleted: (result: CreateNewNodeJobResponse) => void
}) {
  const { nodeId, onActionsChanged, onMutationCompleted } = props

  const finalize = useStartFinalizeCreateNewNodeJob(onMutationCompleted)
  const finalizeWithoutNotification = useStartFinalizeCreateNewNodeJob()

  const handleFinish = useCallback(() => {
    finalize(nodeId, false)
  }, [nodeId])

  const handleCancel = useCallback(() => {
    console.log("cancel node:", nodeId)
    finalize(nodeId, true)
  }, [nodeId])

  const handleClose = useCallback(
    (notify: boolean) => {
      console.log("cancel node:", nodeId)
      if (notify) finalize(nodeId, true)
      else finalizeWithoutNotification(nodeId, true)
    },
    [nodeId],
  )

  // On change of the context node: Ask user if he wants to change
  useEffect(() => {
    onActionsChanged({ onFinish: handleFinish, onCancel: handleCancel, onClose: handleClose })
  }, [nodeId])
}
