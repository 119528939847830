import * as React from "react"
import { SVGProps } from "react"

const SvgSt4StructureFragmentRegular = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1em" height="1em" {...props}>
    <path d="m429.3 109.2-90.6-90.5C326.7 6.7 310.5 0 293.5 0H128C92.7 0 64 28.7 64 64v384c0 35.3 28.7 64 64 64h256c35.3 0 64-28.7 64-64V154.5c0-17-6.7-33.3-18.7-45.3zM400 448c0 8.8-7.2 16-16 16H128c-8.8 0-16-7.2-16-16V64c0-8.8 7.2-16 16-16h160v80c0 17.7 14.3 32 32 32h80v288zm-31.3-218.4L267 190.4c-7.8-2.6-13.1-2.6-20.9 0l-101.7 39.2c-10.4 2.6-18.3 13.1-18.3 26.1V381c0 10.4 7.8 20.9 18.3 26.1l101.7 39.2c7.8 2.6 13.1 2.6 20.9 0l101.7-39.2C379.1 404.4 387 394 387 381V255.8c0-10.4-7.9-20.9-18.3-26.2zM232.8 406.1l-68.1-25.2c-2.3-2.3-2.3-2.3-2.3-4.6l.3-89.6 70.2 28.1v91.3h-.1zm23.5-125.4-75.4-29.8 73.5-27.8h1.9l75.4 27.8-75.4 29.8zm91.6 100.2-68 25.2V315l70.1-28.1.3 89.5c0 2.2 0 2.2-2.4 4.5z" />
  </svg>
)

export default SvgSt4StructureFragmentRegular
