import { formatRelative as dateFnsFormatRelative } from "date-fns"
import { de, enGB, fr, ja } from "date-fns/locale"
import i18n from "i18next"

function getDate(date: string | number | Date) {
  if (typeof date === "string") return Date.parse(date)
  if (typeof date === "number") return new Date(date)
  return date
}

const langMap = new Map([
  ["de", de],
  ["en", enGB],
  ["fr", fr],
  ["ja", ja],
])

/**
 * Formats a time relative to a base. The used locale is taken from `i18n.language`
 * @param date the date to format
 * @param baseDate the base date for the relative format. The current date is used if omitted
 */
export function formatRelative(date: string | number | Date, baseDate?: string | number | Date) {
  const startDate = getDate(date)
  const relativeDate = getDate(baseDate || Date.now())

  return dateFnsFormatRelative(startDate, relativeDate, { locale: dateFnsLocale() })
}

export function dateFnsLocale() {
  const langCode = i18n.language || "en-GB"
  return langMap.get(langCode.substr(0, 2))
}
