import * as Types from "@st4/graphql"

import { gql } from "@apollo/client"
import * as Apollo from "@apollo/client"
const defaultOptions = {} as const
export type SearchResultBySearchTermQueryVariables = Types.Exact<{
  term: Types.Scalars["String"]
  language: Types.Scalars["AspectSelectorId"]
  keys?: Types.InputMaybe<Array<Types.Scalars["String"]> | Types.Scalars["String"]>
  refinements?: Types.InputMaybe<Array<Types.SearchRefinement> | Types.SearchRefinement>
  options?: Types.InputMaybe<Types.SearchQueryOptions>
}>

export type SearchResultBySearchTermQuery = {
  __typename: "Query"
  searchTerm: {
    __typename: "QueryResult"
    searchResult: {
      __typename: "SearchResult"
      resultCount: number
      items: Array<{
        __typename: "SearchResultItem"
        id: any
        treeNodeId?: any | null
        highlights: Array<string>
        node:
          | { __typename: "CommentNode" }
          | { __typename: "ExternalSite" }
          | { __typename: "ST4Class" }
          | {
              __typename: "ST4Node"
              title?: string | null
              icon?: {
                __typename: "IconSet"
                default?: { __typename: "TreeIconData"; uri?: string | null } | null
              } | null
              properties?: {
                __typename: "ContentPropertyConnection"
                edges?: Array<{
                  __typename: "ContentPropertyEdge"
                  node?: { __typename: "ContentProperty"; name: string; displayName: string; value?: any | null } | null
                } | null> | null
              } | null
            }
          | { __typename: "ST4TreeNode" }
      }>
    }
  }
}

export type SearchFacetsBySearchTermQueryVariables = Types.Exact<{
  term: Types.Scalars["String"]
  language: Types.Scalars["AspectSelectorId"]
  keys?: Types.InputMaybe<Array<Types.Scalars["String"]> | Types.Scalars["String"]>
  refinements?: Types.InputMaybe<Array<Types.SearchRefinement> | Types.SearchRefinement>
  options?: Types.InputMaybe<Types.SearchQueryOptions>
}>

export type SearchFacetsBySearchTermQuery = {
  __typename: "Query"
  searchTerm: {
    __typename: "QueryResult"
    searchFacets: Array<{
      __typename: "SearchFacet"
      type: number
      key: string
      title: string
      count: number
      items: Array<{
        __typename: "SearchFacetItem"
        label: string
        value: string
        count: number
        items?: Array<{
          __typename: "SearchFacetItem"
          label: string
          value: string
          count: number
          items?: Array<{
            __typename: "SearchFacetItem"
            label: string
            value: string
            count: number
            items?: Array<{
              __typename: "SearchFacetItem"
              label: string
              value: string
              count: number
              items?: Array<{
                __typename: "SearchFacetItem"
                label: string
                value: string
                count: number
                items?: Array<{
                  __typename: "SearchFacetItem"
                  label: string
                  value: string
                  count: number
                  items?: Array<{
                    __typename: "SearchFacetItem"
                    label: string
                    value: string
                    count: number
                    items?: Array<{
                      __typename: "SearchFacetItem"
                      label: string
                      value: string
                      count: number
                      items?: Array<{
                        __typename: "SearchFacetItem"
                        label: string
                        value: string
                        count: number
                        items?: Array<{
                          __typename: "SearchFacetItem"
                          label: string
                          value: string
                          count: number
                        }> | null
                      }> | null
                    }> | null
                  }> | null
                }> | null
              }> | null
            }> | null
          }> | null
        }> | null
      }>
    }>
  }
}

export type SearchResultItemFragment = {
  __typename: "SearchResultItem"
  id: any
  treeNodeId?: any | null
  highlights: Array<string>
  node:
    | { __typename: "CommentNode" }
    | { __typename: "ExternalSite" }
    | { __typename: "ST4Class" }
    | {
        __typename: "ST4Node"
        title?: string | null
        icon?: { __typename: "IconSet"; default?: { __typename: "TreeIconData"; uri?: string | null } | null } | null
        properties?: {
          __typename: "ContentPropertyConnection"
          edges?: Array<{
            __typename: "ContentPropertyEdge"
            node?: { __typename: "ContentProperty"; name: string; displayName: string; value?: any | null } | null
          } | null> | null
        } | null
      }
    | { __typename: "ST4TreeNode" }
}

export type SearchFacetFragment = {
  __typename: "SearchFacet"
  type: number
  key: string
  title: string
  count: number
  items: Array<{
    __typename: "SearchFacetItem"
    label: string
    value: string
    count: number
    items?: Array<{
      __typename: "SearchFacetItem"
      label: string
      value: string
      count: number
      items?: Array<{
        __typename: "SearchFacetItem"
        label: string
        value: string
        count: number
        items?: Array<{
          __typename: "SearchFacetItem"
          label: string
          value: string
          count: number
          items?: Array<{
            __typename: "SearchFacetItem"
            label: string
            value: string
            count: number
            items?: Array<{
              __typename: "SearchFacetItem"
              label: string
              value: string
              count: number
              items?: Array<{
                __typename: "SearchFacetItem"
                label: string
                value: string
                count: number
                items?: Array<{
                  __typename: "SearchFacetItem"
                  label: string
                  value: string
                  count: number
                  items?: Array<{
                    __typename: "SearchFacetItem"
                    label: string
                    value: string
                    count: number
                    items?: Array<{ __typename: "SearchFacetItem"; label: string; value: string; count: number }> | null
                  }> | null
                }> | null
              }> | null
            }> | null
          }> | null
        }> | null
      }> | null
    }> | null
  }>
}

export type SearchFacetItemFragment = {
  __typename: "SearchFacetItem"
  label: string
  value: string
  count: number
  items?: Array<{
    __typename: "SearchFacetItem"
    label: string
    value: string
    count: number
    items?: Array<{
      __typename: "SearchFacetItem"
      label: string
      value: string
      count: number
      items?: Array<{
        __typename: "SearchFacetItem"
        label: string
        value: string
        count: number
        items?: Array<{
          __typename: "SearchFacetItem"
          label: string
          value: string
          count: number
          items?: Array<{
            __typename: "SearchFacetItem"
            label: string
            value: string
            count: number
            items?: Array<{
              __typename: "SearchFacetItem"
              label: string
              value: string
              count: number
              items?: Array<{
                __typename: "SearchFacetItem"
                label: string
                value: string
                count: number
                items?: Array<{
                  __typename: "SearchFacetItem"
                  label: string
                  value: string
                  count: number
                  items?: Array<{ __typename: "SearchFacetItem"; label: string; value: string; count: number }> | null
                }> | null
              }> | null
            }> | null
          }> | null
        }> | null
      }> | null
    }> | null
  }> | null
}

export const SearchResultItemFragmentDoc = gql`
  fragment SearchResultItem on SearchResultItem {
    id
    treeNodeId
    highlights
    node {
      ... on ST4Node {
        title: label
        icon {
          default {
            uri
          }
        }
        properties(variant: "Metadata") {
          edges {
            node {
              name
              displayName
              value
            }
          }
        }
      }
    }
  }
`
export const SearchFacetItemFragmentDoc = gql`
  fragment SearchFacetItem on SearchFacetItem {
    label
    value
    count
    items {
      label
      value
      count
      items {
        label
        value
        count
        items {
          label
          value
          count
          items {
            label
            value
            count
            items {
              label
              value
              count
              items {
                label
                value
                count
                items {
                  label
                  value
                  count
                  items {
                    label
                    value
                    count
                    items {
                      label
                      value
                      count
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
export const SearchFacetFragmentDoc = gql`
  fragment SearchFacet on SearchFacet {
    type
    key
    title
    count
    items {
      ...SearchFacetItem
    }
  }
  ${SearchFacetItemFragmentDoc}
`
export const SearchResultBySearchTermDocument = gql`
  query SearchResultBySearchTerm(
    $term: String!
    $language: AspectSelectorId!
    $keys: [String!]
    $refinements: [SearchRefinement!]
    $options: SearchQueryOptions
  ) {
    searchTerm(
      query: { query: $term, languageId: $language, key: $keys, refinements: $refinements, options: $options }
    ) {
      searchResult {
        resultCount
        items {
          ...SearchResultItem
        }
      }
    }
  }
  ${SearchResultItemFragmentDoc}
`

/**
 * __useSearchResultBySearchTermQuery__
 *
 * To run a query within a React component, call `useSearchResultBySearchTermQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchResultBySearchTermQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchResultBySearchTermQuery({
 *   variables: {
 *      term: // value for 'term'
 *      language: // value for 'language'
 *      keys: // value for 'keys'
 *      refinements: // value for 'refinements'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useSearchResultBySearchTermQuery(
  baseOptions: Apollo.QueryHookOptions<SearchResultBySearchTermQuery, SearchResultBySearchTermQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SearchResultBySearchTermQuery, SearchResultBySearchTermQueryVariables>(
    SearchResultBySearchTermDocument,
    options,
  )
}
export function useSearchResultBySearchTermLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SearchResultBySearchTermQuery, SearchResultBySearchTermQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SearchResultBySearchTermQuery, SearchResultBySearchTermQueryVariables>(
    SearchResultBySearchTermDocument,
    options,
  )
}
export type SearchResultBySearchTermQueryHookResult = ReturnType<typeof useSearchResultBySearchTermQuery>
export type SearchResultBySearchTermLazyQueryHookResult = ReturnType<typeof useSearchResultBySearchTermLazyQuery>
export type SearchResultBySearchTermQueryResult = Apollo.QueryResult<
  SearchResultBySearchTermQuery,
  SearchResultBySearchTermQueryVariables
>
export const SearchFacetsBySearchTermDocument = gql`
  query SearchFacetsBySearchTerm(
    $term: String!
    $language: AspectSelectorId!
    $keys: [String!]
    $refinements: [SearchRefinement!]
    $options: SearchQueryOptions
  ) {
    searchTerm(
      query: { query: $term, languageId: $language, key: $keys, refinements: $refinements, options: $options }
    ) {
      searchFacets {
        ...SearchFacet
      }
    }
  }
  ${SearchFacetFragmentDoc}
`

/**
 * __useSearchFacetsBySearchTermQuery__
 *
 * To run a query within a React component, call `useSearchFacetsBySearchTermQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchFacetsBySearchTermQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchFacetsBySearchTermQuery({
 *   variables: {
 *      term: // value for 'term'
 *      language: // value for 'language'
 *      keys: // value for 'keys'
 *      refinements: // value for 'refinements'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useSearchFacetsBySearchTermQuery(
  baseOptions: Apollo.QueryHookOptions<SearchFacetsBySearchTermQuery, SearchFacetsBySearchTermQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SearchFacetsBySearchTermQuery, SearchFacetsBySearchTermQueryVariables>(
    SearchFacetsBySearchTermDocument,
    options,
  )
}
export function useSearchFacetsBySearchTermLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SearchFacetsBySearchTermQuery, SearchFacetsBySearchTermQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SearchFacetsBySearchTermQuery, SearchFacetsBySearchTermQueryVariables>(
    SearchFacetsBySearchTermDocument,
    options,
  )
}
export type SearchFacetsBySearchTermQueryHookResult = ReturnType<typeof useSearchFacetsBySearchTermQuery>
export type SearchFacetsBySearchTermLazyQueryHookResult = ReturnType<typeof useSearchFacetsBySearchTermLazyQuery>
export type SearchFacetsBySearchTermQueryResult = Apollo.QueryResult<
  SearchFacetsBySearchTermQuery,
  SearchFacetsBySearchTermQueryVariables
>
