import * as React from "react"
import { SVGProps } from "react"

const SvgSlidersSimple = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1em" height="1em" {...props}>
    <path d="M80 336a32 32 0 1 0 0 64 32 32 0 1 0 0-64zm76.3 8H488c13.3 0 24 10.7 24 24s-10.7 24-24 24H156.3c-10.2 32.5-40.5 56-76.3 56-44.2 0-80-35.8-80-80s35.8-80 80-80c35.8 0 66.1 23.5 76.3 56zM400 144a32 32 0 1 0 64 0 32 32 0 1 0-64 0zm-44.3-24c10.2-32.5 40.5-56 76.3-56 44.2 0 80 35.8 80 80s-35.8 80-80 80c-35.8 0-66.1-23.5-76.3-56H24c-13.3 0-24-10.7-24-24s10.7-24 24-24h331.7z" />
  </svg>
)

export default SvgSlidersSimple
