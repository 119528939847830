import React from "react"
import styled from "styled-components"
import { Toolbar_deprecated, Text_deprecated } from "../../index"
import PropTypes from "prop-types"
import ThemeManager from "@st4/theme-manager"
import MdRadioButtonOff from "@schema/react-ionicons/components/MdRadioButtonOff"
import MdRadioButtonOn from "@schema/react-ionicons/components/MdRadioButtonOn"
import MdArrowDropright from "@schema/react-ionicons/components/MdArrowDropright"

// prettier-ignore
const TimeLine_deprecated = styled(props => (
  <div className={`TimeLine ${props.className}`}>
    <Toolbar_deprecated style={{ justifyContent: "space-between" }}>
      <Text_deprecated>{props.startLabel}</Text_deprecated>
      <Text_deprecated>{props.transitionLabel}</Text_deprecated>
      <Text_deprecated>{props.endLabel}</Text_deprecated>
    </Toolbar_deprecated>
    <div
      className="TimeLine--states"
      style={{ display: "flex", width: "100%" }}
    >
      { props.startFilled ? <MdRadioButtonOn /> : <MdRadioButtonOff /> }
      <span style={{ flex: 1 }} className="line" />
      <MdArrowDropright size="22px" />
      <span style={{ flex: 1 }} className="line" />
      { props.endFilled ? <MdRadioButtonOn /> : <MdRadioButtonOff /> }
    </div>
  </div>
))`
  color: ${props => props.theme.primaryColor};
  .TimeLine--states {
    align-items: baseline;
  }
  .line {
    border-bottom: 2px solid ${props => props.theme.primaryColor};
    transform: translateY(-9px);       
  }

  padding-bottom: 1em;
`

TimeLine_deprecated.propTypes = {
  className: PropTypes.string,
  theme: PropTypes.object,
  startLabel: PropTypes.string,
  transitionLabel: PropTypes.string,
  endLabel: PropTypes.string,
  startFilled: PropTypes.bool,
  endFilled: PropTypes.bool,
}

export default TimeLine_deprecated
