import { ApolloError } from "@apollo/client"
import { IMessage } from "@st4/graphql"
import styled, { css } from "styled-components"

export const NodeHoverWrapper = styled.div<{ selected?: boolean; hovered?: boolean }>`
  margin-top: 7px;
  display: flex;
  flex-direction: row;
  border-radius: 1px;
  ${({ theme, selected }) =>
    selected
      ? css`
          border: 1px solid ${theme.preview.nodeSelection.selectionColor};
          margin-top: 6px;
        `
      : css``}
  ${({ theme, hovered, selected }) =>
    hovered && !selected
      ? css`
          border: 1px solid ${theme.greys[300]};
          margin-top: 6px;
        `
      : css``}
`

export const NodeEditorHoverButton = styled.div<{ hovered?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 40px;
  cursor: pointer;
  ${({ theme, hovered }) =>
    hovered
      ? css`
          background-color: ${theme.greys[300]};
        `
      : css`
          background-color: ${theme.white};
        `}
`

export function hasErrorOpeningEditor(sessionError: IMessage[] | undefined, error: ApolloError | undefined) {
  if (sessionError && sessionError.length > 0) return true

  if (error) return true

  return false
}
