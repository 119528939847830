import { isTypename } from "@st4/graphql";
import { getContentInfosFromFragment } from "./fragments";
import { getMetadataFromNode } from "./metadata";
import { getContentInfosFromPart } from "./parts";
export function extractSavedXml(data) {
    var test = data === null || data === void 0 ? void 0 : data.saveEditorNodeContent;
    if (!isTypename("SaveEditorNodeSuccess")(test) || !isTypename("SaveEditorNodeSuccessData")(test.result)) return;
    var content = test.result.savedNode.content;
    if (!isTypename("TextContent")(content) || !content.xml) return;
    return content.xml;
}
export function extractContentData(data) {
    var ref, ref1, ref2;
    var treeNode = data === null || data === void 0 ? void 0 : data.node;
    if (!isTypename("ST4TreeNode")(treeNode)) return;
    var node = treeNode.node;
    if (!isTypename("ST4Node")(node) || !isTypename("TextContent")(node.content) && !isTypename("TextGroupContent")(node.content) || !node.content.xml) return;
    var referenceInfos = [];
    var metaData = {};
    var imageInfos = [];
    var contentMetaData = getMetadataFromNode(node);
    if (contentMetaData && contentMetaData.contentImageInfos && contentMetaData.contentMetaData) {
        addImageInfosTo(imageInfos, contentMetaData.contentImageInfos);
        metaData = contentMetaData.contentMetaData;
    }
    (ref = node.content.fragments) === null || ref === void 0 ? void 0 : ref.map(function(fragment) {
        var fragmentContentInfo = getContentInfosFromFragment(fragment, metaData);
        if (fragmentContentInfo && !referenceInfos.find(function(x) {
            return x.id == fragmentContentInfo.fragment.id;
        })) referenceInfos.push(fragmentContentInfo.fragment);
        if (fragmentContentInfo && fragmentContentInfo.fragmentImages) addImageInfosTo(imageInfos, fragmentContentInfo.fragmentImages);
    });
    (ref1 = node.content.parts) === null || ref1 === void 0 ? void 0 : ref1.map(function(part) {
        var partContentInfo = getContentInfosFromPart(part, metaData);
        if (partContentInfo) {
            var existingInfoIndex = referenceInfos.findIndex(function(x) {
                return x.id == partContentInfo.part.id;
            });
            if (existingInfoIndex >= 0) referenceInfos[existingInfoIndex] = partContentInfo.part;
            else referenceInfos.push(partContentInfo.part);
            if (partContentInfo.partImages) addImageInfosTo(imageInfos, partContentInfo.partImages);
        }
    });
    (ref2 = treeNode.dynamicMetaRefs) === null || ref2 === void 0 ? void 0 : ref2.map(function(dynamicMetaRef) {
        var ref;
        if (!(dynamicMetaRef === null || dynamicMetaRef === void 0 ? void 0 : (ref = dynamicMetaRef.metaRefPath) === null || ref === void 0 ? void 0 : ref.id)) return;
        metaData[dynamicMetaRef.metaRefPath.id] = JSON.stringify(dynamicMetaRef);
    });
    return {
        nodeId: node.id,
        xml: node.content.xml,
        images: imageInfos,
        fragments: referenceInfos,
        metaData: metaData
    };
}
function addImageInfosTo(currentImages, newImages) {
    newImages.map(function(image) {
        addImageInfoTo(currentImages, image);
    });
}
function addImageInfoTo(currentImages, newImage) {
    if (!currentImages.find(function(x) {
        return x.id == newImage.id;
    })) currentImages.push(newImage);
}
